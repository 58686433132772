<h3>
  {{ linkTree.name | translate }}
</h3>
<ul *ngIf="stationOrCropzone === station" class="link-list">
  <li *ngFor="let node of linkTree.nodes">
    <a routerLinkActive="active"
       [routerLink]="['/station/' + (selectedStationId$ | async) + node.link]"
       [routerLinkActiveOptions]="{ exact: true, __change_detection_hack__: (selectedStationId$ | async) }">
      <i class="zmdi {{ node.iconClass }}"></i>
      {{ node.name | translate }}
    </a>
  </li>
</ul>
<ul *ngIf="stationOrCropzone === cropzone" class="link-list">
  <li *ngFor="let node of linkTree.nodes">
    <a  routerLinkActive="active"
        [routerLink]="['/cropzone/' + (selectedCropzoneId$ | async) + node.link]"
        [routerLinkActiveOptions]="{ exact: true, __change_detection_hack__: (selectedCropzoneId$ | async) }">
      <i class="zmdi {{ node.iconClass }}"></i>
      {{ node.name | translate }}
    </a>
  </li>
</ul>
<ul *ngIf="stationOrCropzone === ''" class="link-list">
  <li *ngFor="let node of linkTree.nodes">
    <a routerLinkActive="active"
       [routerLink]="[node.link]">
      <i class="zmdi {{ node.iconClass }}"></i>
      {{ node.name | translate }}
    </a>
  </li>
</ul>
