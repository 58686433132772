import {animate, AnimationTriggerMetadata, state, style, transition, trigger} from '@angular/animations';

const leftComponentsAnimation = animate('200ms ease-in-out');

export const STATION_DATA_CONTENT_STATE_FULL_WIDTH = 'STATION_DATA_CONTENT_STATE_FULL_WIDTH';
export const STATION_DATA_CONTENT_STATE_REDUCED_WIDTH = 'STATION_DATA_CONTENT_STATE_REDUCED_WIDTH';

export const CROPZONE_DATA_CONTENT_STATE_FULL_WIDTH = 'CROPZONE_DATA_CONTENT_STATE_FULL_WIDTH';
export const CROPZONE_DATA_CONTENT_STATE_REDUCED_WIDTH = 'CROPZONE_DATA_CONTENT_STATE_REDUCED_WIDTH';

export const stationDataContentAnimations: Array<AnimationTriggerMetadata> = [
  trigger('placeContent', [
    state(STATION_DATA_CONTENT_STATE_FULL_WIDTH, style({
      marginLeft: '0'
    })),
    state(STATION_DATA_CONTENT_STATE_REDUCED_WIDTH, style({
      marginLeft: '263px'
    })),
    state(CROPZONE_DATA_CONTENT_STATE_FULL_WIDTH, style({
      marginLeft: '0'
    })),
    state(CROPZONE_DATA_CONTENT_STATE_REDUCED_WIDTH, style({
      marginLeft: '263px'
    })),
    transition(
      `${STATION_DATA_CONTENT_STATE_REDUCED_WIDTH} => ${STATION_DATA_CONTENT_STATE_FULL_WIDTH}`,
      leftComponentsAnimation
    ),
    transition(
      `${STATION_DATA_CONTENT_STATE_FULL_WIDTH} => ${STATION_DATA_CONTENT_STATE_REDUCED_WIDTH}`,
      leftComponentsAnimation
    ),
    transition(
      `${CROPZONE_DATA_CONTENT_STATE_REDUCED_WIDTH} => ${CROPZONE_DATA_CONTENT_STATE_FULL_WIDTH}`,
      leftComponentsAnimation
    ),
    transition(
      `${CROPZONE_DATA_CONTENT_STATE_FULL_WIDTH} => ${CROPZONE_DATA_CONTENT_STATE_REDUCED_WIDTH}`,
      leftComponentsAnimation
    ),
  ]),
];

export const LEFT_SIDE_MENU_STATE_REDUCED_WIDTH = 'LEFT_SIDE_MENU_STATE_REDUCED_WIDTH';
export const LEFT_SIDE_MENU_STATE_FULL_WIDTH = 'LEFT_SIDE_MENU_STATE_FULL_WIDTH';

export const leftSideMenuContentAnimations: Array<AnimationTriggerMetadata> = [
  trigger('placeContent', [
    state(LEFT_SIDE_MENU_STATE_REDUCED_WIDTH, style({
      marginLeft: '0'
    })),
    state(LEFT_SIDE_MENU_STATE_FULL_WIDTH, style({
      marginLeft: '263px'
    })),
    transition(
      `${LEFT_SIDE_MENU_STATE_REDUCED_WIDTH} => ${LEFT_SIDE_MENU_STATE_FULL_WIDTH}`,
      leftComponentsAnimation
    ),
    transition(
      `${LEFT_SIDE_MENU_STATE_FULL_WIDTH} => ${LEFT_SIDE_MENU_STATE_REDUCED_WIDTH}`,
      leftComponentsAnimation
    ),
  ])
];

export const STATE_TREE_IS_DISPLAYED_FOR_FULL_WIDTH = 'STATE_TREE_IS_DISPLAYED_FOR_FULL_WIDTH';
export const STATE_TREE_IS_DISPLAYED_FOR_REDUCED_WIDTH = 'STATE_TREE_IS_DISPLAYED_FOR_REDUCED_WIDTH';
export const STATE_TREE_IS_HIDDEN = 'STATE_TREE_IS_HIDDEN';

export const treeAnimations: Array<AnimationTriggerMetadata> = [
  trigger('placeTree', [
    state(STATE_TREE_IS_DISPLAYED_FOR_FULL_WIDTH, style({
      transform: 'translate3d(0, 0, 0)'
    })),
    state(STATE_TREE_IS_DISPLAYED_FOR_REDUCED_WIDTH, style({
      transform: 'translate3d(30px, 0, 0)'
    })),
    state(STATE_TREE_IS_HIDDEN, style({
      transform: 'translate3d(-308px, 0, 0)'
    })),
    transition(`${STATE_TREE_IS_DISPLAYED_FOR_FULL_WIDTH} => ${STATE_TREE_IS_HIDDEN}`, leftComponentsAnimation),
    transition(`${STATE_TREE_IS_HIDDEN} => ${STATE_TREE_IS_DISPLAYED_FOR_FULL_WIDTH}`, leftComponentsAnimation),
    transition(`${STATE_TREE_IS_DISPLAYED_FOR_REDUCED_WIDTH} => ${STATE_TREE_IS_HIDDEN}`, leftComponentsAnimation),
    transition(`${STATE_TREE_IS_HIDDEN} => ${STATE_TREE_IS_DISPLAYED_FOR_REDUCED_WIDTH}`, leftComponentsAnimation),
  ]),
];

export const LEFT_BAR_STATE_IS_DISPLAYED = 'LEFT_BAR_STATE_IS_DISPLAYED';
export const LEFT_BAR_STATE_IS_HIDDEN = 'LEFT_BAR_STATE_IS_HIDDEN';

export const leftBarAnimations: Array<AnimationTriggerMetadata> = [
  trigger('toggleBar', [
    state(LEFT_BAR_STATE_IS_DISPLAYED, style({
      transform: 'translate3d(0, 0, 0)'
    })),
    state(LEFT_BAR_STATE_IS_HIDDEN, style({
      transform: 'translate3d(-100%, 0, 0)'
    })),
    transition(`${LEFT_BAR_STATE_IS_DISPLAYED} => ${LEFT_BAR_STATE_IS_HIDDEN}`, leftComponentsAnimation),
    transition(`${LEFT_BAR_STATE_IS_HIDDEN} => ${LEFT_BAR_STATE_IS_DISPLAYED}`, leftComponentsAnimation)
  ]),
];

export const HAMBURGER_STATE_LEFT_COMPONENTS_ARE_HIDDEN = 'HAMBURGER_STATE_LEFT_COMPONENTS_ARE_HIDDEN';
export const HAMBURGER_STATE_BAR_IS_DISPLAYED = 'HAMBURGER_STATE_BAR_IS_DISPLAYED';
export const HAMBURGER_STATE_BAR_AND_STATION_ARE_DISPLAYED = 'HAMBURGER_STATE_BAR_AND_STATION_ARE_DISPLAYED';

export const hamburgerAnimations: Array<AnimationTriggerMetadata> = [
  trigger('placeHamburger', [
    state(HAMBURGER_STATE_LEFT_COMPONENTS_ARE_HIDDEN, style({
      transform: 'translate3d(0, 0, 0)'
    })),
    state(HAMBURGER_STATE_BAR_IS_DISPLAYED, style({
      transform: 'translate3d(45px, 0, 0)'
    })),
    state(HAMBURGER_STATE_BAR_AND_STATION_ARE_DISPLAYED, style({
      transform: 'translate3d(308px, 0, 0)'
    })),
    transition(
      `${HAMBURGER_STATE_LEFT_COMPONENTS_ARE_HIDDEN} => ${HAMBURGER_STATE_BAR_AND_STATION_ARE_DISPLAYED}`,
      leftComponentsAnimation
    ),
    transition(
        `${HAMBURGER_STATE_LEFT_COMPONENTS_ARE_HIDDEN} => ${HAMBURGER_STATE_BAR_IS_DISPLAYED}`,
      leftComponentsAnimation
    ),
    transition(
      `${HAMBURGER_STATE_BAR_IS_DISPLAYED} => ${HAMBURGER_STATE_LEFT_COMPONENTS_ARE_HIDDEN}`,
      leftComponentsAnimation
    ),
    transition(
        `${HAMBURGER_STATE_BAR_IS_DISPLAYED} => ${HAMBURGER_STATE_BAR_AND_STATION_ARE_DISPLAYED}`,
      leftComponentsAnimation
    ),
    transition(
      `${HAMBURGER_STATE_BAR_AND_STATION_ARE_DISPLAYED} => ${HAMBURGER_STATE_LEFT_COMPONENTS_ARE_HIDDEN}`,
      leftComponentsAnimation
    ),
    transition(
        `${HAMBURGER_STATE_BAR_AND_STATION_ARE_DISPLAYED} => ${HAMBURGER_STATE_BAR_IS_DISPLAYED}`,
      leftComponentsAnimation
    ),
  ]),
];
