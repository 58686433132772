import {IOptions} from '../../shared/interfaces';

export enum TreeType {
  Sensors,
  Links,
  NestedLinks,
  Cards
}

export enum TreeContentType {
  StationData,
  SoilMoisture,
  StationSettings,
  CropzoneSettings,
  Irrimet,
  CropZoneSoilMoisture,
  CropZoneSatellite,
  CropZoneYieldPrediction,
  WeatherForecast,
  Disease,
  CropView,
  IScout,
  Tracker,
  AnimalProduction,
  WorkPlanning,
  UserApiServices,
  AccumulatorTool,
  SoilGuard,
  CropzoneSamples,
  Hydroponics,
  FrostProtection
}

export interface ITopologyItem {
  name: string;
  type: string;
  name_custom: string;
  expanded: boolean;
  sensors: ITopologySensorData[];
  nodes: ITopologyNode[];
}

export interface ITopologySensorData {
  groupId: string;
  group?: number;
  sensor: ITopologySensor;
}

export interface ITopologySensor  {
  name: string;
  ch?: number;
  color: string;
  group?: number;
  code?: number;
}

export interface ITopologyNode {
  name: string|number;
  name_custom?: string;
  sensors: ITopologySensorData[];
}

export interface ITreeStructure {
  type: TreeType;
  contentType: TreeContentType;
  profiles?: ITreeProfile;
  trees: Array<ITree>;
  treeSettings?: IStationProfileTreeSettings;
  itemsWithSettingsIcon?: string[];
}

export interface ITreeProfile {
  options: IOptions[];
  selectedOption: IOptions;
}

export interface ITree {
  id?: string|number;
  expanded?: boolean;
  type?: string;
  name: string;
  hasSettingsIcon: boolean;
  nodes?: INode[];
  subNodes?: ISubNode[];
}

export interface INode {
  id?: string | number;
  chart?: number;
  visible?: boolean;
  groupId?: string;
  group?: number;
  name: string;
  ch?: number;
  color?: string;
  series?: number;
  iconClass?: string;
  link?: string;
}

export interface ISubNode {
  id?: string | number;
  name: string | number;
  nodes: INode[];
  hasSettingsIcon?: boolean;
  iconClass?: string;
  link?: string;
}

export interface IStationProfileTreeSettings {
  disabledGroupIds: {
    [groupId: string]: Boolean
  };
}

export function buildMenuFromTreeSubNodes(subNode: ISubNode): Array<string> {
  return [subNode.link].concat(subNode.nodes.map(node => subNode.link + '/' + node.id));
}

export function buildMenuFromTreeNodes(rootPath: string, treeNodes: Array<ISubNode>): Array<string> {
  return treeNodes.reduce((paths, subNode) => {
    return paths.concat(buildMenuFromTreeSubNodes(subNode));
  }, [rootPath]);
}
