<div class="logging-and-transfer-modal">
    <div *ngIf="isIScout">
      <p>
        {{ 'In Scheduler you can select the hours at which you wish your iMETOS iSCOUT station to take a picture and send it ' +
        'to the server. As long as there are no changes saved, the device will work with the default factory settings: it ' +
        'will take pictures at 8:00, 12:00 and 16:00.' | translate }}
      </p>
      <p>
        {{ 'NOTE: Taking and sending a picture consumes a lot of energy, so decide wisely how many times per day you want to ' +
        'take and send a picture. During cold periods of the year, picture taking and sending is recommended only once per ' +
        'day.' | translate }}
      </p>
      <p>
        {{ 'NOTE: Sending a picture consumes a lot of transfer data. If a station sends three pictures per day, monthly use ' +
        'of data will be 1 GB. Not to exceed this data amount, there is a limitation of taking pictures three times per day.' | translate }}
      </p>
      <p>
        {{ 'When you finish the configuration, do not forget to save the new settings (click on "SAVE CONFIGURATION" ' +
        'button).' | translate }}
      </p>
    </div>
  
    <div *ngIf="isCropView">
      <p>
        {{ 'In Scheduler you can select the hours at which you wish your iMETOS CropVIEW station to take a picture and send ' +
        'it to the server. As long as there are no changes saved, the device will work with the default factory settings: it ' +
        'will take pictures at 8:00, 12:00 and 16:00.' | translate }}
      </p>
      <p>
        {{ 'NOTE: Taking and sending a picture consumes a lot of energy, so decide wisely how many times per day you want to ' +
        'take and send a picture. During cold periods of the year, picture taking and sending is recommended only once per ' +
        'day.' | translate }}
      </p>
      <p>
        {{ 'NOTE: Sending a picture consumes a lot of transfer data. If a station sends three pictures per day, monthly use ' +
        'of data will be 1 GB. Not to exceed this data amount, there is a limitation of taking pictures three times per day.' | translate }}
      </p>
      <p>
        {{ 'When you finish the configuration, do not forget to save the new settings (click on "SAVE CONFIGURATION" ' +
        'button).' | translate }}
      </p>
    </div>
  
    <div *ngIf="!isIScout && !isCropView && !isNbiot">
      <p>
        {{ 'Setup how your station is logging and sending data.' | translate }}
      </p>
      <p>
        {{ 'In the basic settings you can select different logging intervals (from 10 minutes to 2 hours). Logging interval ' +
        'means the time interval at which your station will read data from all the sensors and store it to its memory.' | translate }}
      </p>
      <p>
        {{ 'With scheduler you can select hours of the day (only full hours possible) at which stored data will be ' +
        'transferred to the server. You need to select at least one transmission per day. Shortening the transfer interval ' +
        'will increase the power consumption and the data transfer cost.' | translate }}
      </p>
      <div *ngIf="isAdvanced">
        <section class="advanced-options">{{ 'Advanced options' | translate}}</section>
        <p>
          {{ 'If you want to access advanced settings, click on „Advanced options“ button and new menus will appear with the '+
          'following options:' | translate }}
        </p>
        <ul>
          <li>{{ 'Frost Monitoring Mode: this feature enables to automatically set shorter transfer intervals (5 or 10 minutes ' +
                 'instead of 1 hour) during frost conditions - only when the temperature value goes below 5°C. This allows more ' +
                 'efficient and smarter management of the battery, increasing its performance, and provides live data of the key ' +
                 'frost parameters. Users will not have to worry about changing the settings from Fieldclimate, but everything ' +
                 'will be automatic!' | translate }}
          </li>
          <li>{{ 'Frost Monitoring Mode: Notifications will be automatically sent out when Frost Mode is enabled, and temperature ' +
            'value goes below 1°C or 33.8°F. This can be edited on the Station Settings → Notification subpage. A new notification ' +
            'will be automatically created and thresholds can be changed.' | translate }}
          </li>
          <li>{{ 'Fixed transfer interval: You can select interval at which stored data will be transmitted to the server. ' +
            'This setting will take precedence over the scheduler. Data can be send every 10, 15, 20, 30 or 60 minutes.' | translate }}
          </li>
          <li>{{ 'Station activity: Here you can select activity state of the station. You can either turn it completely ' +
            'off, by choosing “Station is switched off” in the drop-down menu or by selecting “Station is gathering, but not ' +
            'sending data” to put your station in a mode where it is only logging, but not transmitting the data. By default, ' +
            'the station is set to normal working mode and is gathering and sending data.' | translate }}
          </li>
          <li>{{ 'Rain monitoring: if it is enabled, the iMETOS device transfers the data at a fixed 15-minute interval ' +
            'while it is raining. By default, rain monitoring is off. This option is available only for the stations that ' +
            'have rain gauge sensor.' | translate }}
          </li>
          <li>{{ 'Water level monitoring: With this option enabled, station will switch to 15-minute transmitting interval ' +
            'if water level activity is recorded.' | translate }}
          </li>
        </ul>
      </div>
    </div>
  
    <div *ngIf="isNbiot">
      <p>
        {{ 'Setup how your station is logging and sending data.' | translate }}
      </p>
      <p>
        {{ 'In the basic settings you can select different logging intervals (from 10 minutes to 2 hours). Logging interval ' +
        'means the time interval at which your station will read data from all the sensors and store it to its memory.' | translate }}
      </p>
      <p>
        {{ 'Fixed transfer interval: You can select interval at which stored data will be transmitted to the server. ' +
        'This setting will take precedence over the scheduler. Data can be send every 10, 15, 20, 30 or 60 minutes.' | translate }}
      </p>
      <p>{{ 'Frost Monitoring Mode: this feature enables to automatically set shorter transfer intervals (5 or 10 minutes ' +
        'instead of 1 hour) during frost conditions - only when the temperature value goes below 5°C. This allows more ' +
        'efficient and smarter management of the battery, increasing its performance, and provides live data of the key ' +
        'frost parameters. Users will not have to worry about changing the settings from Fieldclimate, but everything ' +
        'will be automatic!' | translate }}
      </p>
      <p>{{ 'Frost Monitoring Mode: Notifications will be automatically sent out when Frost Mode is enabled, and temperature ' +
        'value goes below 1°C or 33.8°F. This can be edited on the Station Settings → Notification subpage. A new notification ' +
        'will be automatically created and thresholds can be changed.' | translate }}
      </p>
    </div>
  
    <div *ngIf="isMetosFrost">
      <p>
        <b>{{ 'Work modes and intervals for nMETOS FROST:' | translate }}</b>
      </p>
  
      <table style="width:100%">
        <tr>
          <td>{{ 'Measuring interval' | translate }}</td>
          <td>{{ '5 min' | translate }}</td>
          <td>{{ '1 min' | translate }}</td>
          <td>{{ '1 min' | translate }}</td>
          <td>{{ '1 min' | translate }}</td>
          <td>{{ '5 min' | translate }}</td>
        </tr>
        <tr>
          <td>{{ 'Logging interval' | translate }}</td>
          <td>{{ '10 min' | translate }}</td>
          <td>{{ '5 min' | translate }}</td>
          <td>{{ '1 min' | translate }}</td>
          <td>{{ '5 min' | translate }}</td>
          <td>{{ '10 min' | translate }}</td>
        </tr>
        <tr>
          <td>{{ 'Communication interval' | translate }}</td>
          <td>{{ '60 min' | translate }}</td>
          <td>{{ '15 min' | translate }}</td>
          <td>{{ '5 min' | translate }}</td>
          <td>{{ '15 min' | translate }}</td>
          <td>{{ '60 min' | translate }}</td>
        </tr>
        <tr>
          <td>{{ 'Wet bulb temp. value' | translate }}</td>
          <td>{{ '>=8°C' | translate }}</td>
          <td>{{ '=4°C<8°C' | translate }}</td>
          <td>{{ '<4°C>-6°C' | translate }}</td>
          <td>{{ '<=-6°C>-8°C' | translate }}</td>
          <td>{{ '<=-8°C' | translate }}</td>
        </tr>
      </table>
  
    </div>
</div>
