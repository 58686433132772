import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import { StoreModule } from '@ngrx/store';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { StationDataExportService } from '../../shared/services/export/station-data-export.service';
import { HydroponicsRoutingModule } from './hydroponics-routing.module';
import { HydroponicsComponent } from './hydroponics.component';
import { HydroponicsTreeComponent } from './hydroponics-tree/hydroponics-tree.component';
import { HydroponicsContentComponent } from './containers/hydroponics-content/hydroponics-content.component';
import { HydroponicsHeaderComponent } from './containers/hydroponics-header/hydroponics-header.component';
import { HydroponicsToolbarComponent } from './containers/hydroponics-toolbar/hydroponics-toolbar.component';
import { HydroponicsHelpModalComponent } from './containers/hydroponics-help-modal/hydroponics-help-modal.component';
import { HydroponicsFormComponent } from './containers/hydroponics-form/hydroponics-form.component';
import { HydroponicsTableComponent } from './containers/hydroponics-table/hydroponics-table.component';
import { HydroponicsChartsComponent } from './containers/hydroponics-charts/hydroponics-charts.component';
import { hydroponicsReducer } from './reducers/reducers';
import { HydroponicsTableDataComponent } from './containers/hydroponics-table-data/hydroponics-table-data.component';

@NgModule({
  imports: [
    CommonModule,
    HydroponicsRoutingModule,
    SharedModule,
    TranslateModule.forChild(),
    AgGridModule.withComponents([]),
    ChartModule,
    StoreModule.forFeature('hydroponics', hydroponicsReducer),
  ],
  declarations: [
    HydroponicsComponent,
    HydroponicsTreeComponent,
    HydroponicsContentComponent,
    HydroponicsHeaderComponent,
    HydroponicsToolbarComponent,
    HydroponicsHelpModalComponent,
    HydroponicsFormComponent,
    HydroponicsTableComponent,
    HydroponicsChartsComponent,
    HydroponicsTableDataComponent
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [ more, exporting ] }, // add as factory to your providers
    StationDataExportService
  ],
})
export class HydroponicsModule { }
