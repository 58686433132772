import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import * as fromNavigationStation from '../../../../core/reducers/navigation-station';
import { dateToUtcUnixTimestamp } from '../../../../shared/utils/dateFormat';
import {
  getTrackerData, setTrackerData,
  setTrackerError, setTrackerLoading, setTrackerPeriod
} from '../../actions/tracker';
import { IGetTrackerDataRequest, ITrackerPeriod } from './../../models/tracker.models';
import * as fromTracker from './../../reducers/index';

@Component({
  selector: 'app-tracker-toolbar',
  templateUrl: './tracker-toolbar.component.html',
  styleUrls: ['./tracker-toolbar.component.scss']
})
export class TrackerToolbarComponent implements OnInit, OnDestroy {
  public station: IStation;
  public fromStation: Date = null;
  public toStation: Date = null;
  public periodForm: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private initDestroy$: Subject<boolean> = new Subject<boolean>();
  private isDatepickerRequest: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private navigationStore: Store<fromNavigationStation.INavigationStationState>,
    private trackerStore: Store<fromTracker.ITrackerDataState>) {
  }

  private get period(): AbstractControl {
    return this.periodForm.get('period');
  }

  public get fromDatepicker(): AbstractControl {
    return this.period.get('fromDatepicker');
  }

  public get toDatepicker(): AbstractControl {
    return this.period.get('toDatepicker');
  }

  public get isLastDataMode(): AbstractControl {
    return this.period.get('isLastDataMode');
  }

  public get fromTo(): AbstractControl {
    return this.period.get('fromTo');
  }

  public get stationId(): AbstractControl {
    return this.period.get('stationId');
  }

  public get periodValue(): AbstractControl {
    return this.period.get('periodValue');
  }

  public ngOnInit(): void {
    this.periodForm = this.formBuilder.group({
      'period': this.formBuilder.group({
        'periodValue': ['2d', [Validators.required]],
        'fromTo': [null, [Validators.required]],
        'fromDatepicker': [null, [Validators.required]],
        'toDatepicker': [null, [Validators.required]],
        'stationId': ['', [Validators.required]],
        'isLastDataMode': [false, [Validators.required]]
      })
    });

    // this comes from the tracker/machinery connection
    // sets data from when the machinery was connected (useless)
    /*
    this.trackerStore.pipe(
      takeUntil(this.destroy$),
      select(fromTracker.selectSelectedConnection)
    ).subscribe(
      data => {
        if (data instanceof Object && data.trackerID) {

          this.isLastDataMode.patchValue(false);
          this.fromDatepicker.setValue(moment(data.fromDate).toDate());
          if (data.toDate) {
            this.toDatepicker.setValue(moment(data.toDate).toDate());
          } else {
            this.toDatepicker.setValue(moment().toDate());
          }
          this.refresh();
        }
      }
    );
    */

    this.trackerStore.pipe(
      takeUntil(this.initDestroy$),
      select(fromTracker.selectTrackerDataPeriod)
    ).subscribe((period: ITrackerPeriod) => {
      this.initDestroy$.next(true);
      this.periodForm.setValue({
        period: period
      });
    });

    this.subscribeToTreeAndSelectedStation();
  }

  public setDateRange(): void {
    if (this.isDatepickerRequest && !this.isLastDataMode.value) {
      this.fromTo.setValue({
        from: this.fromDatepicker.value,
        to: this.toDatepicker.value
      });
    }
  }

  private subscribeToTreeAndSelectedStation(): void {
    combineLatest([
      this.getSelectedStationObservable()
    ])
      .subscribe((result: Array<IStation>) => {
        const station: IStation = <IStation>result[0];
        this.fromStation = moment(station.dates.min_date).toDate();
        this.toStation = moment(station.dates.max_date).toDate();
        if (station.name.original === this.stationId.value) {
          return;
        }
        this.stationId.setValue(station.name.original);

        setTimeout(() => {
          if (!this.station || station.name.original !== this.station.name.original) {
            this.fromDatepicker.setValue(moment(this.toStation).subtract(1, 'day').toDate());
            this.toDatepicker.setValue(moment(this.toStation).toDate());
            this.fromTo.setValue({
              from: moment(this.toStation).subtract(2, 'day').toDate(),
              to: this.toStation
            });
          }
          const periodScope = localStorage.getItem('trackerPeriodScope');
          if (periodScope) {
            this.fromDatepicker.setValue(moment(new Date()).subtract(periodScope, 'day').toDate());
            this.toDatepicker.setValue(moment(new Date()).toDate());
            this.fromTo.setValue({
              from: moment(new Date()).subtract(periodScope, 'day').toDate(),
              to: new Date()
            });
          }
          this.refresh();
          this.station = station;
        });
      });
  }

  public refresh(): void {
    if (this.periodForm.invalid) {
      return;
    }
    this.isDatepickerRequest = !this.isLastDataMode.value;
    this.trackerStore.dispatch(setTrackerPeriod(this.periodForm.value));

    const request: IGetTrackerDataRequest = {
      from: dateToUtcUnixTimestamp(this.fromDatepicker.value),
      to: dateToUtcUnixTimestamp(this.toDatepicker.value),
      stationId: this.stationId.value
    };

    this.trackerStore.dispatch(setTrackerData([]));
    this.trackerStore.dispatch(setTrackerLoading(true));
    this.trackerStore.dispatch(setTrackerError(false));
    this.trackerStore.dispatch(getTrackerData(request));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.initDestroy$.next(true);
    this.initDestroy$.complete();
  }

  private getSelectedStationObservable(): Observable<IStation> {
    return this.navigationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((s: IStation) => !!s)
    );
  }
}
