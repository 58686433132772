import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { TrackerSettingsActionTypes } from '../actions/trackerSettings';

export interface ITrackerSettingsState {
  data: any;
  addedMachinery: any;
  isLoading: boolean;
  isError: boolean;
}

export const initialState: ITrackerSettingsState = {
  data: null,
  addedMachinery: null,
  isLoading: true,
  isError: false
};

export function reducer(state: any = initialState, action: IActionWithPayload): any {
  switch (action.type) {
    case TrackerSettingsActionTypes.SET_TRACKER:
      return <any>{
        ...state,
        data: action.payload
      };
    case TrackerSettingsActionTypes.SET_ADDED_MACHINERY:
      return <any>{
        ...state,
        addedMachinery: action.payload
      };
    case TrackerSettingsActionTypes.RESET_TRACKER_DATA:
      return <any>{
        ...state,
        data: null
      };
    default:
      return state;
  }
}


