import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ADD_REMOVE_MODAL_ID } from '../../../../../shared/constants';
import { ModalService } from '../../../../../shared/modal/services/modal.service';
import { IAddRemoveStation } from '../../../../models/stations';

@Component({
  selector: 'app-add-remove-station-form',
  templateUrl: './add-remove-station-form.component.html',
  styleUrls: ['./add-remove-station-form.component.scss']
})
export class AddRemoveStationFormComponent implements OnInit {
  @Input()
  public isAddForm: boolean = true;
  @Output()
  private submit: EventEmitter<IAddRemoveStation> = new EventEmitter<IAddRemoveStation>();

  public form: FormGroup;
  constructor(private fb: FormBuilder,
              private modalService: ModalService) { }

  public get id(): AbstractControl {
    return this.form.get('id');
  }

  public get key(): AbstractControl {
    return this.form.get('key');
  }

  public get customName(): AbstractControl {
    return this.form.get('name');
  }

  public get submitColor(): string {
    return this.isAddForm ? 'green' : 'red';
  }

  public get submitContent(): string {
    return this.isAddForm ? 'Add station' : 'Remove station';
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      'id': ['', [Validators.required]],
      'key': ['', [Validators.required]]
    });
    if (this.isAddForm) {
      this.form.addControl('name', this.fb.control(''));
    }
  }

  public submitEmit(): void {
    this.submit.emit(this.form.value);
    this.form.reset();
  }

  public close(): void {
    this.modalService.closeModal(ADD_REMOVE_MODAL_ID);
  }

}
