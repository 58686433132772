import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { stationDataContentAnimations } from '../../../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../../../core/services/left-components-toggler/left-components-toggler.service';
import { INode, ITree } from '../../../../services/tree/models';
import { setFrostProtectionTree, setFrostProtectionVisibility } from '../../actions/actions';
import { IFrostProtectionState, IFrostProtectionVisibility } from '../../models/models';
import {
  selectFrostProtectionTree,
  selectFrostProtectionVisibility
} from '../../selectors/selectors';

@Component({
  selector: 'app-frost-protection',
  templateUrl: './frost-protection.component.html',
  styleUrls: ['./frost-protection.component.scss'],
  animations: stationDataContentAnimations
})
export class FrostProtectionComponent implements OnInit, OnDestroy {

  public state$           : Observable<string>;
  public response         : any = null;
  public tree             : ITree;

  private visibility      : IFrostProtectionVisibility = null;
  private destroy$        : Subject<boolean> = new Subject<boolean>();

  constructor(
    private leftComponentsToggler   : LeftComponentsTogglerService,
    private frostProtectionStore    : Store<IFrostProtectionState>
  ) { }

  public ngOnInit(): void {
    this.state$ = this.leftComponentsToggler.getStationDataContentState();

    this.frostProtectionStore.pipe(
      takeUntil(this.destroy$),
      select(selectFrostProtectionTree),
    ).subscribe((tree: ITree) => {
      if (tree.nodes) {
        this.tree = tree;
      }
    });

    this.frostProtectionStore.pipe(
      takeUntil(this.destroy$),
      select(selectFrostProtectionVisibility),
    ).subscribe((visibility: IFrostProtectionVisibility) => {
      this.visibility = visibility;
    });
  }

  public onToggleSensor(node: INode): void {
    const selectedNode = this.tree.nodes.filter(n => n.ch === node.ch)[0];
    let nodeVisible = selectedNode.visible;
    nodeVisible = !nodeVisible;

    this.tree.nodes.filter(n => n.ch === node.ch)[0].visible = nodeVisible;
    this.frostProtectionStore.dispatch(setFrostProtectionTree(this.tree));
    this.frostProtectionStore.dispatch(setFrostProtectionVisibility({ch: selectedNode.ch, visible: nodeVisible}));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
