import { Component, Input } from '@angular/core';
import { ICharacteristic } from '../../models/models';

@Component({
  selector: 'app-station-information-table',
  templateUrl: './station-information-table.component.html',
  styleUrls: ['./station-information-table.component.scss']
})
export class StationInformationTableComponent {
  @Input()
  public characteristics: Array<ICharacteristic> = [];
  @Input()
  public thWidthProportion: number = 4;
  @Input()
  public tdWidthProportion: number = 8;
  @Input()
  public defaultText: string = '';
}
