<div class="row text-center">
  <div class="col-md-4">
    <div class="btn-group">
      <div
        class="btn btn-primary"
        mwlCalendarPreviousView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="viewDateChange.next(viewDate)"
        (click)="viewDateChangePrevious()">
        {{ 'Previous' | translate }}
      </div>
      <div
        class="btn btn-outline-secondary"
        mwlCalendarToday
        [(viewDate)]="viewDate"
        (viewDateChange)="viewDateChange.next(viewDate)"
        (click)="viewDateChangeToday()">
        {{ 'Today' | translate }}
      </div>
      <div
        class="btn btn-primary"
        mwlCalendarNextView
        [view]="view"
        [(viewDate)]="viewDate"
        (viewDateChange)="viewDateChange.next(viewDate)"
        (click)="viewDateChangeNext()">
        {{ 'Next' | translate }}
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <h3>
      {{ viewDate | calendarDate: view + 'ViewTitle':locale }}
    </h3>
  </div>
  <div class="col-md-4">
    <div class="btn-group">
      <div
        class="btn btn-primary"
        (click)="viewChange.emit('month')"
        [class.active]="view === 'month'">
        {{ 'Month' | translate }}
      </div>
      <div
        class="btn btn-primary"
        (click)="viewChange.emit('week')"
        [class.active]="view === 'week'">
        {{ 'Week' | translate }}
      </div>
      <div
        class="btn btn-primary"
        (click)="viewChange.emit('day')"
        [class.active]="view === 'day'">
        {{ 'Day' | translate }}
      </div>
    </div>
  </div>
</div>
<br/>
