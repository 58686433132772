import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { IDvSensorMap } from '../../models/davis';

@Component({
  selector: 'app-davis-sensor-map',
  templateUrl: './davis-sensor-map.component.html',
  styleUrls: ['./davis-sensor-map.component.scss']
})
export class DavisSensorMapComponent implements OnInit {

  public dialog: string;
  public modalId = generateId();

  @Input()
  public sensorMap: any;

  @Input()
  public stationName: string;

  @Output()
  public updateSensorMap: EventEmitter<IDvSensorMap> = new EventEmitter();

  @Output()
  public cancelSensorMap: EventEmitter<Boolean> = new EventEmitter();

  constructor(
    private modal: ModalService
  ) {

  }

  public ngOnInit(): void {
  }

  public saveSensorMap(): void {
    this.updateSensorMap.emit(this.sensorMap);
  }

  public cancelEditing(): void {
    this.cancelSensorMap.emit(true);
  }

  public openModal(dialog: string): void {
    this.dialog = dialog;
    this.modal.openModal(this.modalId);
  }
}
