export function getTimeString(time: number): string {
    let timestring = '';

    const hours = Math.floor(time / 3600);
    time -= hours * 3600;
    const minutes = Math.floor(time / 60);
    time -= minutes * 60;

    if (hours > 0) {
        timestring += hours + ' h ';
    }
    if (minutes > 0) {
        timestring += minutes + ' min ';
    }
    timestring += time + ' s';

    return timestring;
}
