import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IState } from '../models';

export const selector = createFeatureSelector<IState>('apiServices');

export const selectSettings = createSelector(
  selector,
  state => state.davis.settings
);

export const selectAssets = createSelector(
  selector,
  state => state.davis.assets
);

export const selectLicenses = createSelector(
  selector,
  state => state.davis.licenses
);

export const selectSensorMap = createSelector(
  selector,
  state => state.davis.sensorMap
);
