import {IActionWithPayload} from '../models/actionWithPayload';
import {DiseasesActionTypes} from '../actions/diseases';
import {IDisease} from '../models/diseases.models';

export interface IDiseases {
  diseases: Array<IDisease>;
}

const initialState: IDiseases = {
  diseases: null
};

export function reducer(state: IDiseases = initialState, action: IActionWithPayload ): IDiseases {
  switch (action.type) {

    case DiseasesActionTypes.SET_DISEASES: {
      return {
        ...state,
        diseases: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
