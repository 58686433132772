import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ICropAndScoutData } from '../../models/dashboard.models';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent {
  @ViewChild('imageEL', {static: true})
  public imageEl: ElementRef;
  @Input()
  public image: ICropAndScoutData;
  @Output()
  private close: EventEmitter<void> = new EventEmitter<void>();

  public transformOrigin: string = '';
  private zoom: number = 1;
  private _zoomLevel: string = 'scale(1)';

  public setZoomLevel(zoom: number): void {
    this.zoom = zoom;
    this._zoomLevel = `scale(${zoom})`;
  }

  public get zoomLevel(): string {
    return this._zoomLevel;
  }

  public mouseMove(event: any): void {
    const transformX: number =
      ((event.pageX - this.imageEl.nativeElement.getBoundingClientRect().left)
      / this.imageEl.nativeElement.offsetWidth) * 100;
    const transformY: number =
      ((event.pageY - this.imageEl.nativeElement.getBoundingClientRect().top - window.pageYOffset)
      / this.imageEl.nativeElement.offsetHeight) * 100;
    this.transformOrigin = `${transformX}% ${transformY}%`;
  }

  public mouseWheel(event: any): void {
    if (event.wheelDelta / 120 > 0 || event.detail < 0) {
      this.setZoomLevel(this.zoom >= 9 ? 10 : this.zoom + 1);
    } else {
      this.setZoomLevel(this.zoom <= 1 ? 1 : this.zoom - 1);
    }

  }

  public mouseOver(): void {
    this.setZoomLevel(1);
  }

  public mouseOut(): void {
    this.setZoomLevel(1);
  }

  public get imageType(): string {
    return this.image.url.includes('cropview') ? 'CropVIEW' : 'iScout';
  }

  public closeEmit(): void {
    this.close.emit();
  }

}
