import { Component } from '@angular/core';

@Component({
  selector: 'app-warnings-list-modal',
  templateUrl: './warnings-list-modal.component.html',
  styleUrls: ['./warnings-list-modal.component.scss']
})
export class WarningsListModalComponent {

}
