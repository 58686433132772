<app-form-layout>
  <ng-container>
    <div *ngIf="flag === 'waterlevel'"
         class="row ultrasonic-distance-settings-sketches__right-column">
      <img class="ultrasonic-distance-settings-sketches__img"
           src="../../assets/img/fwt/ultrasonic-sensor-elevation.jpg"
           alt="ultrasonic sensor elevation"
           width="85%">
    </div >
    <div *ngIf="flag === 'nmetosfwt'"
         class="row ultrasonic-distance-settings-sketches__right-column">
      <img class="ultrasonic-distance-settings-sketches__img"
           src="../../assets/img/fwt/fwt-elevation.jpg"
           alt="field water tube elevation"
           width="50%">
    </div >
  </ng-container>
</app-form-layout>
