<div #agmMap>
  <agm-map
    *ngIf="showMap && stationsLocal; else loading"
    [latitude]="lat"
    [longitude]="lng"
    [zoom]="zoom"
    [disableDefaultUI]="true"
    [zoomControl]="true"
    [scrollwheel]="true"
    [mapTypeControl]="true"
    [streetViewControl]="false"
    [fitBounds]="true"
    [minZoom]="3"
    [maxZoom]="17"
    [mapTypeId]="'hybrid'">

    <agm-marker
      *ngFor="let st of stationsLocal; let i = index"
      [latitude]="st.position?.geo.coordinates ? st.position.geo.coordinates[1] : 0"
      [longitude]="st.position?.geo.coordinates ? st.position.geo.coordinates[0] : 0 "
      [markerDraggable]="false"
      [iconUrl]="st.icon"
      [title]="(st.title | translate) + getMetaDate(st)"
      [visible]="st.hidden === false"
      [opacity]="st.opacity"
      [agmFitBounds]="true"
      (markerClick)="navigate(st.name.original)">
    </agm-marker>

  </agm-map>

  <ng-template #loading>
    <app-widget-info>
      <h4><i class="fa fa-cog fa-spin fa-fw"></i> {{ 'Please wait, loading data ...' | translate }}</h4>
    </app-widget-info>
  </ng-template>

</div>
