import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-save-emergency',
  templateUrl: './save-emergency.component.html',
  styleUrls: ['./save-emergency.component.scss']
})
export class SaveEmergencyComponent {
  @Output()
  private save      : EventEmitter<void> = new EventEmitter<void>();

  public saveEmergency(): void {
    this.save.emit();
  }

}
