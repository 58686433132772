<section *ngIf="!subDomain?.hideDashboardMap" class="widget-container">
  <app-chart-header [color]="widget.color" [title]="widget.title" [description]="widget.description"
    [isDraggable]="isEdit$ | async" [isClose]="isEdit$ | async" (remove)="removeCurrentWidget()" [isEditable]="false">
  </app-chart-header>

  <app-form-layout [size]="'dashboard'">
    <form [formGroup]="form" class="btn-settings-map" *ngIf="form">

      <app-custom-dropdown [formControl]="mode" class="widget-settings-form__dropdown" [options]="mapModes" [searchable]="false">
      </app-custom-dropdown>

    </form>
    <app-google-map [mapMode]="mapMode"></app-google-map>
  </app-form-layout>
</section>
