import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IPhonesSavePayload} from '../models/models';
import {IPhone} from '../../../core/models/stations';

export const enum WarningsPhonesActionTypes {
  SET_WARNINGS_PHONES = '[Warnings] SET_WARNINGS_PHONES',
  SET_WARNINGS_PHONE = '[Warnings] SET_WARNINGS_PHONE',
  UPDATE_WARNINGS_PHONE = '[Warnings] UPDATE_WARNINGS_PHONE',
  REMOVE_WARNINGS_PHONE = '[Warnings] REMOVE_WARNINGS_PHONE',
  SAVE_WARNINGS_PHONES = '[Warnings] SAVE_WARNINGS_PHONES'
}

export function saveWarningsPhones(save: IPhonesSavePayload): IActionWithPayload {
  return {type: WarningsPhonesActionTypes.SAVE_WARNINGS_PHONES, payload: save};
}

export function setWarningsPhone(phone: IPhone): IActionWithPayload {
  return {type: WarningsPhonesActionTypes.SET_WARNINGS_PHONE, payload: phone};
}

export function removeWarningsPhone(phoneKey: string): IActionWithPayload {
  return {type: WarningsPhonesActionTypes.REMOVE_WARNINGS_PHONE, payload: phoneKey};
}

export function updateWarningsPhone(phone: IPhone): IActionWithPayload {
  return {type: WarningsPhonesActionTypes.UPDATE_WARNINGS_PHONE, payload: phone};
}

export function setWarningsPhones(phones: IPhone[]): IActionWithPayload {
  return {type: WarningsPhonesActionTypes.SET_WARNINGS_PHONES, payload: phones};
}
