import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {SensorsAndNodeActionTypes} from '../actions/sensors-and-nodes';
import {INodes} from '../models/sensors-and-nodes';

export interface ISensorsAndNodesState {
  nodes: INodes;
  isLoading: boolean;
  isError: boolean;
  stationId: string;
}

export const initialState: ISensorsAndNodesState = {
  nodes: null,
  isLoading: false,
  isError: false,
  stationId: ''
};

export function reducer(state: ISensorsAndNodesState = initialState, action: IActionWithPayload ): ISensorsAndNodesState {
  switch (action.type) {
    case SensorsAndNodeActionTypes.SET_SENSORS_STATION: {
      return {
        ...state,
        stationId: action.payload
      };
    }
    case SensorsAndNodeActionTypes.TOGGLE_SENSORS_LOADING_ERROR: {
      return {
        ...state,
        isError: action.payload
      };
    }
    case SensorsAndNodeActionTypes.TOGGLE_SENSORS_LOADING: {
      return {
        ...state,
        isLoading: action.payload
      };
    }
    case SensorsAndNodeActionTypes.SET_NODES: {
      return {
        ...state,
        nodes: {
          ...action.payload
        }
      };
    }
    case SensorsAndNodeActionTypes.UPDATE_SENSOR: {
      return {
        ...state,
        nodes: {
          ...state.nodes,
          [action.payload.nodeId]: {
            ...state.nodes[action.payload.nodeId],
            sensors: {
              ...state.nodes[action.payload.nodeId].sensors,
              [action.payload.sensorId]: {
                ...state.nodes[action.payload.nodeId].sensors[action.payload.sensorId],
                ...action.payload.sensor
              }
            }
          }
        }
      };
    }
    case SensorsAndNodeActionTypes.UPDATE_NODE_NAME: {
      return {
        ...state,
        nodes: {
          ...state.nodes,
          [action.payload.nodeId]: {
            ...state.nodes[action.payload.nodeId],
            name: action.payload.name
          }
        }
      };
    }
    case SensorsAndNodeActionTypes.SET_NODE_CHANGED: {
      return {
        ...state,
        nodes: {
          ...state.nodes,
          [action.payload.nodeId]: {
            ...state.nodes[action.payload.nodeId],
            isChanged: action.payload.isChanged
          }
        }
      };
    }
    default: {
      return state;
    }
  }
}
