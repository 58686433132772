import * as fromWeatherForecast from './weather-forecast';
import * as fromWeatherForecastSettings from './weather-forecast-settings';
import * as fromWeatherForecastImage from './weather-forecast-image';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IWeatherForecastImageState, IWeatherForecastSettingsState, IWeatherForecastState, WEATHER_FORECAST_MODES} from '../models/models';
import {IOptions} from '../../../shared/interfaces';
import { ITreeSettingsState } from '../../../shared/tree/models/tree.models';
import { weatherForecastTreeReducer } from '../../../shared/tree/reducers/tree-settings';

interface IState {
  weatherForecast: IWeatherForecastState;
  weatherForecastSettings: IWeatherForecastSettingsState;
  weatherForecastImage: IWeatherForecastImageState;
  treeSettings: ITreeSettingsState;
}

export const reducers = {
  weatherForecast: fromWeatherForecast.reducer,
  weatherForecastSettings: fromWeatherForecastSettings.reducer,
  weatherForecastImage: fromWeatherForecastImage.reducer,
  treeSettings: weatherForecastTreeReducer
};

export const weatherForecast = createFeatureSelector<IState>('weatherForecast');

export const selectWeatherForecast = createSelector(
  weatherForecast,
  (weatherForecastState: IState) => weatherForecastState.weatherForecast
);

export const selectWeatherForecastModeName = createSelector(
  weatherForecast,
  (weatherForecastState: IState): string => {
    const selectedOption = WEATHER_FORECAST_MODES.find((option: IOptions): boolean => {
      return option.value === weatherForecastState.weatherForecast.mode;
    });
    return selectedOption ? selectedOption.content : null;
  }
);

export const selectWeatherForecastCharts = createSelector(
  weatherForecast,
  (weatherForecastState: IState): Array<any> => weatherForecastState.weatherForecast.chartsOptions
);

export const selectWeatherForecastGrid = createSelector(
  weatherForecast,
  (weatherForecastState: IState): {} => weatherForecastState.weatherForecast.grid
);

export const selectWeatherForecastIsLoading = createSelector(
  weatherForecast,
  (weatherForecastState: IState): boolean => weatherForecastState.weatherForecast.isLoading
);

export const selectWeatherForecastIsError = createSelector(
  weatherForecast,
  (weatherForecastState: IState): boolean => weatherForecastState.weatherForecast.isError
);

export const selectWeatherForecastImageExportInProgress = createSelector(
  weatherForecast,
  (weatherForecastState: IState): boolean => weatherForecastState.weatherForecastSettings.activity.imageExportInProgress
);

export const selectWeatherForecastSettings = createSelector(
  weatherForecast,
  (weatherForecastState: IState): IWeatherForecastSettingsState => weatherForecastState.weatherForecastSettings
);

export const selectWeatherForecastSettingsIsChartActive = createSelector(
  weatherForecast,
  (weatherForecastState: IState): boolean => weatherForecastState.weatherForecastSettings.activity.isChartActive
);

export const selectWeatherForecastSettingsIsTableActive = createSelector(
  weatherForecast,
  (weatherForecastState: IState): boolean => weatherForecastState.weatherForecastSettings.activity.isTableActive
);

export const selectWeatherForecastImage = createSelector(
  weatherForecast,
  (weatherForecastState: IState): string => weatherForecastState.weatherForecastImage.imageUrl
);

export const selectWeatherForecastImageLoading = createSelector(
  weatherForecast,
  (weatherForecastState: IState): boolean => weatherForecastState.weatherForecastImage.isLoading
);

export const selectWeatherForecastImageError = createSelector(
  weatherForecast,
  (weatherForecastState: IState): boolean => weatherForecastState.weatherForecastImage.isError
);

export const selectWeatherForecastLicenses = createSelector(
  weatherForecast,
  (weatherForecastState: IState): any => weatherForecastState.weatherForecast.weatherForecastLicenses
);

export const selectWeatherForecastWarning = createSelector(
  weatherForecast,
  (weatherForecastState: IState): any => weatherForecastState.weatherForecast.warning
);

export const selectWeatherForecastTreeSettings = createSelector(
  weatherForecast,
  (state: IState) => state.treeSettings
);
