<div class="widget-add-buttons">
  <hr>
  <div>
    <app-custom-button [content]="'Add widget'"
                       (click) = add.emit();
                       class="widget-add-buttons__add"></app-custom-button>
    <app-custom-button [content]="'Cancel'"
                       (click) = close.emit();
                       [color]="'orange'"></app-custom-button>
  </div>

  <div class="alert alert-warning widget-add-buttons__note">{{ 'Make sure you save the Dashboard when you are done!' | translate }}</div>
</div>
