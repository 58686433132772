import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { ICropZone } from './../../../core/models/cropzones';
import { INDVIVal, INdviStat } from './../models/index';

export const enum ActionTypes {
    GET_NDVI_STATS = '[CropZoneSat] GET_NDVI_STATS',
    SET_NDVI_STATS = '[CropZoneSat] SET_NDVI_STATS',
    GET_VISIBLE_NDVI_DATES = '[CropZoneSat] GET_VISIBLE_NDVI_DATES',
    SET_VISIBLE_NDVI_DATES = '[CropZoneSat] SET_VISIBLE_NDVI_DATES',
    SET_NDVI_VAL_RES_10 = '[CropZoneSat] SET_NDVI_VAL_RES_10',
    GET_NDVI_VAL_RES_10 = '[CropZoneSat] GET_NDVI_VAL_RES_10',
    ADD_VISIBLE_NDVI_VAL = '[CropZoneSat] ADD_VISIBLE_NDVI_VAL',
    GET_VISIBLE_NDVI_VALS = '[CropZoneSat] GET_VISIBLE_NDVI_VALS',
    SET_VISIBLE_NDVI_VALS = '[CropZoneSat] SET_VISIBLE_NDVI_VALS',
    SET_NO_DATA_ERROR_NDVI = '[CropZoneSat] SET_NO_DATA_ERROR_NDVI',
    GET_FITTING_CURVE_NDVI = '[CropZoneSat] GET_FITTING_CURVE_NDVI',
    SET_FITTING_CURVE_NDVI = '[CropZoneSat] SET_FITTING_CURVE_NDVI',
    SET_FITTING_CURVE_ERROR = '[CropZoneSat] SET_FITTING_CURVE_ERROR',
    GET_FITTING_CURVE_ERROR = '[CropZoneSat] GET_FITTING_CURVE_ERROR',
    DELETE_ALL_NDVI = '[CropZoneSat] DELETE_ALL_NDVI'
}

export function addVisibleNdviVal(cropZoneId: string, date: string, resolution: number): IAction {
    return { type: ActionTypes.ADD_VISIBLE_NDVI_VAL, payload: { cropZoneId, date, resolution}};
}

export function getNdviStats(cropZoneId: string, resolution: number): IAction {
    return { type: ActionTypes.GET_NDVI_STATS, payload: { cropZoneId, resolution } };
}

export function setNdviStats(cropZoneId: string, ndviStats: INdviStat[] | string): IAction {
    return { type: ActionTypes.SET_NDVI_STATS, payload: { cropZoneId, ndviStats } };
}

export function getVisibleNdviDates(cropZoneId: string, dates: string[], resolution: number): IAction {
    return { type: ActionTypes.GET_VISIBLE_NDVI_DATES, payload: { cropZoneId, dates, resolution } };
}

export function getVisibleNdviVals(cropzone: ICropZone, dates: string[], resolution: number): IAction {
    return { type: ActionTypes.GET_VISIBLE_NDVI_VALS, payload: { cropzone, dates, resolution } };
}

export function getNdviValRes10(cropZoneId: string, dates: string[]): IAction {
    return { type: ActionTypes.GET_NDVI_VAL_RES_10, payload: { cropZoneId, dates, resolution: 10 } };
}

export function setVisibleNdviDates(cropZoneId: string, ndviVals: INDVIVal[]): IAction {
    return { type: ActionTypes.SET_VISIBLE_NDVI_DATES, payload: { cropZoneId, ndviVals } };
}

export function setVisibleNdviVals(cropzone: ICropZone, ndviVals: INDVIVal[]): IAction {
    return { type: ActionTypes.SET_VISIBLE_NDVI_VALS, payload: { cropzone, ndviVals } };
}

export function setNdviValsRes10(cropZoneId: string, ndviVals: INDVIVal[]): IAction {
    return { type: ActionTypes.SET_NDVI_VAL_RES_10, payload: { cropZoneId, ndviVals}};
}

export function setNoDataErrorNdvi(cropZoneId: string, noDataError: boolean): IAction {
    return { type: ActionTypes.SET_NO_DATA_ERROR_NDVI, payload: { cropZoneId, noDataError }};
}

export function  getFittingCurveNdvi(cropZoneId: string, data: any): IAction {
    data = data.filter((item) => item.cloud.mean <= 0.05);
    return { type: ActionTypes.GET_FITTING_CURVE_NDVI, payload: { cropZoneId, data }};
}

export function  setFittingCurveNdvi(cropZoneId: string, data: any): IAction {
    return { type: ActionTypes.SET_FITTING_CURVE_NDVI, payload: { cropZoneId, data }};
}

export function deleteAllNdvi(cropZoneId: string): IAction {
    return { type: ActionTypes.DELETE_ALL_NDVI, payload: cropZoneId};
}
