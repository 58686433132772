<form class="settings-info-form"
      [formGroup]="form">

  <div class="settings-info-form__header">
    {{ 'Settings' | translate }}
  </div>

  <div class="settings-info-form__item">
    <app-form-select [options]="languages"
                     [icon]="'language'"
                     [type]="'border'"
                    [formControlName]="'language'"></app-form-select>
  </div>

  <div class="settings-info-form__item">
    <app-form-select [options]="metrics"
                     [icon]="'metric'"
                     [type]="'border'"
                    [formControlName]="'unit_system'"></app-form-select>
  </div>

  <div class="settings-info-form__metric"
       *ngIf="oldMetric !== metric.value">
    <strong>{{ 'Warning!' | translate }}</strong> {{ 'This change will affect sensor/node units marked as Default' | translate }}
  </div>
  
</form>
