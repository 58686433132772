import {IOrderSettingItem} from '../../../../components/search-widget/models/search-widget.models';


export const stationsOrderSettings: Array<IOrderSettingItem> = [
  {
    name: 'Id',
    orderField: 'original_name',
    default: true
  },
  {
    name: 'Custom name',
    orderField: 'custom_name',
  },
  {
    name: 'Communication',
    orderField: 'last_communication',
  },
];
