export const BackgroundImages: Array<string> = [
  '/assets/themes/default/assets/backgrounds/back1.jpg',
  '/assets/themes/default/assets/backgrounds/back2.jpg',
  '/assets/themes/default/assets/backgrounds/back3.jpg',
  '/assets/themes/default/assets/backgrounds/back4.jpg',
  '/assets/themes/default/assets/backgrounds/back5.jpg'
];

export const CustomBackgroundImages: any = {
  'telus': [
    '/assets/themes/telus/assets/backgrounds/back1.jpg',
    '/assets/themes/telus/assets/backgrounds/back2.jpg',
    '/assets/themes/telus/assets/backgrounds/back3.jpg',
    '/assets/themes/telus/assets/backgrounds/back4.jpg',
    '/assets/themes/telus/assets/backgrounds/back5.jpg',
    '/assets/themes/telus/assets/backgrounds/back6.jpg',
    '/assets/themes/telus/assets/backgrounds/back7.jpg',
    '/assets/themes/telus/assets/backgrounds/back8.jpg'
  ],
  'turfclimate': [
    '/assets/themes/turfclimate/assets/backgrounds/back1.jpg',
    '/assets/themes/turfclimate/assets/backgrounds/back2.jpg',
    '/assets/themes/turfclimate/assets/backgrounds/back3.jpg',
    '/assets/themes/turfclimate/assets/backgrounds/back4.jpg',
    '/assets/themes/turfclimate/assets/backgrounds/back5.jpg'
  ],
  'proglu': [
    '/assets/themes/proglu/assets/backgrounds/back1.jpg',
    '/assets/themes/proglu/assets/backgrounds/back2.jpg',
    '/assets/themes/proglu/assets/backgrounds/back3.jpg'
  ]
};
