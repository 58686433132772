import { Injectable } from '@angular/core';
import { ApiCallService } from '../../../services/api/api-call.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, tap, toArray } from 'rxjs/operators';
import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { setNotify } from '../../../core/actions/notify';
import { concat, of } from 'rxjs';
import {
  ActionTypes,
  setCropzoneSamples,
  setDualexSamples,
  setMobilabSamples,
  setSoilGuardSamples
} from '../actions/cropzone-missions.action';
import { SampleDataService } from '../services/sample-data.service';

@Injectable()
export class CropzoneSampleEffects {

  constructor(
    private api: ApiCallService,
    private sampleService: SampleDataService,
    private actions$: Actions
  ) {

  }

  @Effect()
  public getDualexByDate = this.actions$.pipe(
    ofType(ActionTypes.GET_DUALEX_DATES),
    switchMap<IAction, any>(({ payload: { cropzone, date } }) =>
      this.api.getDualexByCropzoneDate(cropzone, date).pipe(
        filter(({ returnCode }) => returnCode === 0),
        map(({ data }) => this.sampleService.groupSamples(data)),
        map(data => setDualexSamples(data)),
        catchError(() => of(
          setDualexSamples([]),
          setNotify('Sample data is currently unavailable')
        ))
      )
    )
  );

  @Effect()
  public getSoilGuardByDate = this.actions$.pipe(
    ofType(ActionTypes.GET_SOILGUARD_DATES),
    switchMap<IAction, any>(({ payload: { cropzone, date } }) =>
      this.api.getSoilGuardByCropzoneDate(cropzone, date).pipe(
        filter(({ returnCode }) => returnCode === 0),
        map(({ data }) => this.sampleService.groupSamples(data)),
        map(data => setSoilGuardSamples(data)),
        catchError(() => of(
          setSoilGuardSamples([]),
          setNotify('Sample data is currently unavailable')
        ))
      )
    )
  );


  @Effect()
  public getDualexById = this.actions$.pipe(
    ofType(ActionTypes.GET_DUALEX_LAST),
    switchMap<IAction, any>(({ payload: { cropzone, period } }) =>
      this.api.getDualexByCropzoneId(cropzone, period).pipe(
        filter(({ returnCode }) => returnCode === 0),
        map(({ data }) => setDualexSamples(data)),
        catchError(() => of(
          setDualexSamples([]),
          setNotify('Sample data is currently unavailable')
        ))
      )
    )
  );

  @Effect()
  public getSoilGuardById = this.actions$.pipe(
    ofType(ActionTypes.GET_SOILGUARD_LAST),
    switchMap<IAction, any>(({ payload: { cropzone, period } }) =>
      this.api.getSoilGuardByCropzoneId(cropzone, period).pipe(
        filter(({ returnCode }) => returnCode === 0),
        map(({ data }) => setSoilGuardSamples(data)),
        catchError(() => of(
          setSoilGuardSamples([]),
          setNotify('Sample data is currently unavailable')
        ))
      )
    )
  );

  @Effect()
  public getCompleteSamples = this.actions$.pipe(
    ofType(ActionTypes.GET_CROPZONE_SAMPLES),
    switchMap<any, any>(({ payload: { cropzone, date } }) =>
      concat(
        this.api.getSoilGuardByCropzoneDate(cropzone, date),
        this.api.getDualexByCropzoneDate(cropzone, date),
        this.api.getMobilabByCropzoneDate(cropzone, date)
      ).pipe(
        toArray(),
        map(_ => _
          .map(response => response.returnCode === 0 && response.data)
          .map(data => this.sampleService.groupSamples(data))
        ),
        map(([soilGuard, dualex, mobilab]) =>
          setCropzoneSamples({ soilGuard, dualex, mobilab })
        ),
        catchError(() => of(
          setCropzoneSamples({ soilGuard: [], dualex: [], mobilab: [] }),
          setNotify('Sample data is currently unavailable')
        ))
      )
    )
  );

  @Effect()
  public getMobilabByDate = this.actions$.pipe(
    ofType(ActionTypes.GET_MOBILAB_DATES),
    switchMap<IAction, any>(({ payload: { cropzone, date } }) =>
      this.api.getMobilabByCropzoneDate(cropzone, date).pipe(
        filter(({ returnCode }) => returnCode === 0),
        map(({ data }) => this.sampleService.groupMobilabSamples(data)),
        map((data) => setMobilabSamples(data)),
        catchError(() => of(
          setMobilabSamples([]),
          setNotify('Sample data is currently unavailable')
        ))
      )
    )
  );

  @Effect()
  public getMobilabById = this.actions$.pipe(
    ofType(ActionTypes.GET_MOBILAB_LAST),
    switchMap<IAction, any>(({ payload: { cropzone, period } }) =>
      this.api.getMobilabByCropzoneId(cropzone, period).pipe(
        filter(({ returnCode }) => returnCode === 0),
        map(({ data }) => this.sampleService.groupMobilabSamples(data)),
        map(data => setMobilabSamples(data)),
        catchError(() => of(
          setMobilabSamples([]),
          setNotify('Sample data is currently unavailable')
        ))
      )
    )
  );
}
