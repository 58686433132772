import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ISelectedSearchWidgetItem } from '../../../models/selectedSearchWidgetItem';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { Store } from '@ngrx/store';
import { IAccount } from '../../../reducers/account';
import { updateStarredStation } from '../../../actions/account';
import { IStation } from '../../../models/stations';

export interface ISearchWidgetItem {
  info_name: string;
  original_name: string;
  custom_name: string;
  forecast_licenses: boolean;
  model_licenses: boolean;
  last_communication: string;
  rights: string;
  starred: boolean;
}

@Component({
  selector: 'app-search-widget-list-item',
  templateUrl: './search-widget-list-item.component.html',
  styleUrls: ['./search-widget-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SearchWidgetListItemComponent implements OnInit, OnDestroy {
  @Input()
  public item           : ISearchWidgetItem;
  @Input()
  public isSelected     : boolean = false;
  @Input()
  public stations       : IStation[];
  @Output()
  public selectItem     : EventEmitter<ISelectedSearchWidgetItem> = new EventEmitter<ISelectedSearchWidgetItem>();
  public urlToNavigate  : string;
  private destroy$      : Subject<boolean> = new Subject<boolean>();

  constructor(private navigationService: NavigationService,
              private cd: ChangeDetectorRef,
              private router: Router,
              private accountStore: Store<IAccount>) {}

  public ngOnInit(): void {
    this.router.events.pipe(
      takeUntil(this.destroy$),
      filter(event => event instanceof NavigationEnd)
    ).subscribe((): void => {
      this.setUrlToNavigate();
    });
    this.setUrlToNavigate();
  }

  public emitSelectClick(originalName: string): void {
    this.selectItem.emit({original_name: originalName});
  }

  private setUrlToNavigate(): void {
    this.urlToNavigate = this.navigationService.prepareNavigateToUrl(this.item.original_name);
    this.cd.markForCheck();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public isVirtualDevice(): boolean {
    return this.item?.info_name === 'Virtual Station'
      || this.item?.info_name === 'Virtual Davis station'
      || this.item?.info_name === 'Virtual JD station';
  }

  public isCameraDevice(): boolean {
      return this.item?.info_name?.indexOf('iScout') !== -1
      || this.item?.info_name?.indexOf('CropView') !== -1;
  }

  public navigateUrl(event: Event): void {
    event.stopPropagation();
    this.router.navigate([this.urlToNavigate]);
    this.emitSelectClick(this.item.original_name);
  }

  public toggleFavorite(event: Event): void {
    event.stopPropagation();

    const newStations = this.stations.map((station: IStation) => {
      if (station.name.original === this.item.original_name) {
        return {...station,
          starred: !this.item.starred
        };
      }
      return station;
    });

    this.accountStore.dispatch(updateStarredStation(!this.item.starred, this.item.original_name, newStations));
  }
}
