import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CorrectPrecipitationDataComponent} from './containers/correct-precipitation-data/correct-precipitation-data.component';

const routes: Routes = [
  { path: '', component: CorrectPrecipitationDataComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class CorrectPrecipitationDataRoutingModule { }
