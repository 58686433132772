import { IPreviousYieldValues } from '../models/model';

export const TRANSLATED_VALUES: IPreviousYieldValues = {
    Canola: 'Rapeseed (canola)',
    Wheat: 'Wheat',
    Soy: 'Soya beans',
    Durum: 'Wheat (durum)',
    Barley: 'Barley',
    Potatoes: 'Potatoes',
    Maize: 'Maize',
    yield: 'Yield',
    measured: '(measured)',
    forecastAvg: '(forecast avg)',
    forecastRange: 'forecast range',
};
