import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/internal/operators';
import { takeUntil } from 'rxjs/operators';
import { ISelectedStationState } from '../../../../core/reducers/selectedStation';
import { LeftComponentsTogglerService } from '../../../../core/services/left-components-toggler/left-components-toggler.service';
import { INode, ITreeStructure } from '../../../../services/tree/models';
import { TreeService } from '../../../../services/tree/tree.service';
import { setFrostProtectionTree } from '../../actions/actions';
import { CHART_ATTRIBUTES } from '../../constants/constants';
import { IChartSeries, IFrostProtectionState, IFrostProtectionVisibility } from '../../models/models';
import { selectFrostProtectionResponse, selectFrostProtectionVisibility } from '../../selectors/selectors';

@Component({
  selector: 'app-frost-protection-tree',
  templateUrl: './frost-protection-tree.component.html',
  styleUrls: ['./frost-protection-tree.component.scss']
})
export class FrostProtectionTreeComponent implements OnInit, OnDestroy {

  private attributes          : IChartSeries = CHART_ATTRIBUTES;
  private visibility          : IFrostProtectionVisibility = null;
  private destroy$            : Subject<boolean> = new Subject<boolean>();

  @Output()
  public toggleSensorEmitter  : EventEmitter<INode> = new EventEmitter<INode>();
  public state$               : Observable<string>;
  public tree$                : BehaviorSubject<ITreeStructure>;
  public tree                 : ITreeStructure;

  constructor(
    private leftComponentsToggler : LeftComponentsTogglerService,
    private selectedStationStore  : Store<ISelectedStationState>,
    private frostProtectionStore  : Store<IFrostProtectionState>,
    private treeService           : TreeService,
  ) {

    this.frostProtectionStore.pipe(
      takeUntil(this.destroy$),
      select(selectFrostProtectionVisibility),
    ).subscribe((visibility: IFrostProtectionVisibility) => {
      this.visibility = visibility;
    });
  }

  public ngOnInit(): void {

    // set tree sensor colors
    this.frostProtectionStore.pipe(
      select(selectFrostProtectionResponse),
      takeUntil(this.destroy$),
      switchMap((response: any) => {
        if (response) {
          this.state$ = this.leftComponentsToggler.getStationDataContentState();
          this.tree$ = this.treeService.getFrostProtectionTreeStructure();
          return this.tree$;
        }
        return of(null);
      }),
    ).subscribe(tree => {
      if (tree && tree.trees.length) {
        this.tree = tree;
        if (tree.trees[0].nodes && tree.contentType === 20) {
        this.tree.trees[0].nodes.forEach((node, i) => {
          const group = 'group' + this.tree.trees[0].nodes[i].group;
          this.tree.trees[0].nodes[i].color = this.attributes[group].measurementColor;
        });
        this.frostProtectionStore.dispatch(setFrostProtectionTree(this.tree.trees[0]));
        }
      }
    });
  }

  public toggleSensor(node: INode): void {
    this.toggleSensorEmitter.emit(node);
  }

  public ngOnDestroy(): void {
    this.treeService.getFrostProtectionTreeStructure().next(null);
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
