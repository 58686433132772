import {IOptions} from '../../../shared/interfaces';

export const COMMUNICATION_MODES: Array<IOptions> = [
  { content: 'All events', value: '' },
  { content: 'Success', value: 'success' },
  { content: 'Resync', value: 'resync' },
  { content: 'No data', value: 'no_data' },
  { content: 'Firmware update', value: 'fw_update' },
  { content: 'APN update', value: 'apn_update' }
];
