import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-irrimet-calendar-help-text-modal',
  templateUrl: './irrimet-calendar-help-text-modal.component.html',
  styleUrls: ['./irrimet-calendar-help-text-modal.component.scss']
})
export class IrrimetCalendarHelpTextModalComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
