import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IIscoutSeason, IIscoutSeasonDetection } from '../../../models/iscout.models';

@Component({
  selector: 'app-iscout-season-detail-modal',
  templateUrl: './iscout-season-detail-modal.component.html',
  styleUrls: ['./iscout-season-detail-modal.component.scss']
})
export class IscoutSeasonDetailModalComponent {
  @Input()
  public selectedSeason: IIscoutSeason;

  @Output()
  public closeEmitter: EventEmitter<void> = new EventEmitter<void>();

  public handleClose(): void {
    this.closeEmitter.emit();
  }

  public get availableGlueBoards(): Array<IIscoutSeasonDetection> {
    if (this.selectedSeason && this.selectedSeason.detections) {
      return this.selectedSeason.detections;
    }
    return [];
  }

  public getInsectCount(detection: IIscoutSeasonDetection): Array<any> {
    return Object.keys(detection.total || {}).map((key) => ({
      label: key, value: detection.total[key]
    }));
  }
}
