import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { IStation } from '../../../../../core/models/stations';
import { selectNavigationStation } from '../../../../../core/reducers';
import { INavigationStationState } from '../../../../../core/reducers/navigation-station';
import { IDeleteIscoutSeasonBody } from '../../../../../shared/camera/models/camera';
import {
  getIscoutSeasons,
  removeIscoutSeason,
  saveIscoutSeason,
  selectIscoutSeason,
  unselectIscoutSeason,
  updateIscoutSeason
} from '../../../actions/iscout-seasons';
import {
  IIscoutSeason,
  IIscoutSeasonFormAction,
  IIscoutSeasonState,
  IscoutFormActionType
} from '../../../models/iscout.models';
import { selectIscoutIsLoading, selectIscoutSelectedSeason } from '../../../reducers';

@Component({
  selector: 'app-iscout-season-content',
  templateUrl: './iscout-season-content.component.html',
})
export class IscoutSeasonContentComponent implements OnInit, OnDestroy {
  public selectedSeason$: Observable<IIscoutSeasonFormAction>;
  public isLoading$: Observable<boolean>;
  public stationId: string;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private iscoutSeasonStore: Store<IIscoutSeasonState>,
              private navigationStationStore: Store<INavigationStationState>) {
  }

  public ngOnInit(): void {
    this.initStatusListener();
    this.initStationChangeObservable().subscribe((): void => {
      this.iscoutSeasonStore.dispatch(getIscoutSeasons(this.stationId));
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initStatusListener(): void {
    this.selectedSeason$ = this.iscoutSeasonStore.pipe(
      select(selectIscoutSelectedSeason)
    );

    this.isLoading$ = this.iscoutSeasonStore.pipe(
      select(selectIscoutIsLoading)
    );
  }

  private initStationChangeObservable(): Observable<IStation> {
    return this.navigationStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((station: IStation): boolean => !!station),
      tap((station: IStation): void => {
        if (this.stationId !== station.name.original) {
          this.iscoutSeasonStore.dispatch(unselectIscoutSeason());
        }
        this.stationId = station.name.original;
      })
    );
  }

  public selectSeasonCreate(): void {
    const formAction: IIscoutSeasonFormAction = {action: IscoutFormActionType.CREATE, season: null};
    this.iscoutSeasonStore.dispatch(selectIscoutSeason(formAction));
  }

  public selectSeasonUpdate(season: IIscoutSeason): void {
    const formAction: IIscoutSeasonFormAction = {action: IscoutFormActionType.UPDATE, season};
    this.iscoutSeasonStore.dispatch(selectIscoutSeason(formAction));
  }

  public handleSeasonSaved(season: IIscoutSeason): void {
    const action = saveIscoutSeason({...season, nm: this.stationId});
    this.iscoutSeasonStore.dispatch(action);
  }

  public handleSeasonUpdated(season: IIscoutSeason): void {
    const action = updateIscoutSeason({...season, nm: this.stationId});
    this.iscoutSeasonStore.dispatch(action);
  }

  public handleSeasonRemoved(event: IDeleteIscoutSeasonBody): void {
    this.iscoutSeasonStore.dispatch(removeIscoutSeason(event));
  }
}
