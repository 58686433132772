import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IStation} from '../../../core/models/stations';

export const enum NoteActionTypes {
  SET_NOTE = '[Config] SET_NOTE',
  SAVE_NOTE = '[Config] SAVE_NOTE',
}

export function setNote(name: string): IActionWithPayload {
  return { type: NoteActionTypes.SET_NOTE, payload: name };
}

export function saveNote(station: IStation): IActionWithPayload {
  return { type: NoteActionTypes.SAVE_NOTE, payload: station };
}

