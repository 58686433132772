<div [ngClass]="['form-select', 'select-container', type, size, width]">
  <ng-select
    [ngStyle]="width === 'width-auto' ? { width: getWidth() + 'px' } : {}"
    [items]="filteredOptions"
    bindLabel="content"
    bindValue="value"
    [searchable]="false"
    [clearable]="false"
    [(ngModel)]="value"
    (close)="resetDropdown()"
    (ngModelChange)="onChange()"
    (keydown.escape)="$event.stopImmediatePropagation()"
  >
    <ng-template ng-header-tmp *ngIf="searchable && options?.length > 13">
      <div class="input-container">
        <input
          type="text"
          (input)="filterOptions()"
          [(ngModel)]="searchTerm"
          class="form-control"
          [attr.placeholder]="placeholder | translate"
          (keydown.escape)="resetDropdown()"
        />
        <i class="zmdi zmdi-search"></i>
      </div>
    </ng-template>
    <ng-template ng-option-tmp ng-label-tmp let-item="item">
      {{ item.content | translate }}
    </ng-template>
  </ng-select>
</div>
