<div class="yield-prediction-slider">
    <section class="yield-prediction-slider_header">
        <section class="flex-row-direction space-between">
            <h2>{{ 'Fine Tuning' | translate }} </h2>
            <i class="zmdi zmdi-help help-icon" (click)="openModal()"></i>
        </section>
        <div class="row">
            <section class="col-md-6">
                <h5 class="mini-text mt-8">
                    {{ 'Drag the sliders on the left until maturity date and yield on the right match the location’s average expectation.'
                    | translate}}
                </h5>
            </section>
        </div>
    </section>

    <section class="flex-row-direction space-between">
        <section class="flex-column-direction col-md-6 p-0">
            <section class="col-md-6 p-0">
                <label for="customRange2" class="form-label">{{ 'Average maturity date adjustment' | translate }}</label>
                <section class="flex-row-direction space-between">
                    <p class="p-15">{{ 'Early' | translate }}</p>
                    <input #maturityRange class="my-20" [ngClass]="isDisabled ? 'disabled-slider' : 'slider'"
                        type="range" id="maturityRange" name="maturityRange"
                        [min]="gddMin" [max]="gddMax" [step]="gddStep" [value]="gddValue" [disabled]="isSliderDisabled()"
                        [ngStyle]="{'background-color': isSliderDisabled() ? 'rgb(230, 230, 230)' : '' }"
                        (change)="gddValue=$event.target.value; calculateAverage();" title="{{gddValue}}" />
                    &nbsp;<p class="p-15">{{ 'Late' | translate }}</p>
                </section>
            </section>
            <section class="col-md-6 p-0 mt-8">
                <label for="customRange3" class="form-label">{{ 'Average yield expectation adjustment' | translate }}</label>
                <section class="flex-row-direction space-between">
                    <p class="p-15">{{ 'Low' | translate }}</p>&nbsp;
                    <input #maturityYield class="my-20" [ngClass]="isDisabled ? 'disabled-slider' : 'slider'"
                        type="range" id="maturityYield" name="maturityYield"
                        [min]="wueMin" [max]="wueMax" [step]="wueStep" [value]="wueValue" [disabled]="isSliderDisabled()"
                        [ngStyle]="{'background-color': isSliderDisabled() ? 'rgb(230, 230, 230)' : '' }"
                        (change)="wueValue=$event.target.value; calculateAverage();" title="{{wueValue}}" />
                    &nbsp;<p class="p-15">{{ 'High' | translate }}</p>
                </section>
            </section>
        </section>
        <section class="flex-column-direction col-md-6">
            <section class="">
                <label for="customRange2" class="form-label mb-0 ">{{'Average maturity date' | translate }}</label>
                <h5 class="mini-text mt-2">
                    {{ 'At the crop zone\'s location (long-term temperature averages)' | translate }}</h5>
                <input disabled="true" class="grey" type="text" id="maturityInput" name="maturityInput" for="maturityRange" [value]="calculatedMaturityDate" />
                <span *ngIf="loading"><i class="fa fa-cog fa-spin fa-fw"></i></span>
            </section>
            <section class="mt-8">
                <label for="customRange2" class="form-label mb-0">{{ 'Average Yield' | translate }}</label>
                <h5 class="mini-text mt-2">
                    {{ 'At the crop zone\'s location (long-term rainfall averages)' | translate }}</h5>
                <input disabled="true" class="grey" type="text" id="yieldInput" name="yieldInput" for="maturityYield" [value]="calculatedYieldAverage" />
                <span *ngIf="loading"><i class="fa fa-cog fa-spin fa-fw"></i></span>
            </section>
        </section>
    </section>
    <section class="flex-reverse-direction content_end">
        <app-custom-button [color]="'red'" (click)="save()" [disabled]="isDisabled || (isSavingConfig$ | async)"
        [content]="'Save' | translate">
        <i *ngIf="isSavingConfig$ | async; else loadingIcon" class="fa fa-cog fa-spin fa-fw"></i>
        <ng-template #loadingIcon>
            <i class="fa fa-fw fa-refresh"></i>
        </ng-template>
        </app-custom-button>
    </section>
</div>

<app-info-modal [modalId]="modalId" [header]="'Yield Prediction Fine Tuning'">
    <app-yield-config-help-text-modal [yieldHelpText]="'slider'"></app-yield-config-help-text-modal>
</app-info-modal>