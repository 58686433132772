import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { filter, tap } from 'rxjs/operators';
/*import {IStation} from '../../../../core/models/stations';
import {ModalService} from '../../../../shared/modal/services/modal.service';*/
import { select, Store } from '@ngrx/store';
import { ColDef, GridOptions } from 'ag-grid';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IStation } from '../../core/models/stations';
import { selectNavigationStation } from '../../core/reducers';
import { INavigationStationState } from '../../core/reducers/navigation-station';
import { stationDataContentAnimations } from '../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../core/services/left-components-toggler/left-components-toggler.service';
import { ApiCallService } from '../../services/api/api-call.service';
import { ITreeStructure } from '../../services/tree/models';
import { TreeService } from '../../services/tree/tree.service';
import {
  getAddedMachinery,
  getAddedTrackers, getMachinery, resetMachineryDataStore, saveMachinery
} from './actions/machinery-settings';
import * as fromMachinerySettings from './reducers/index';

@Component({
  selector: 'app-machinery-settings',
  templateUrl: './machinery-settings.component.html',
  styleUrls: ['./machinery-settings.component.scss'],
  animations: stationDataContentAnimations
})
export class MachinerySettingsComponent implements OnInit, OnDestroy {
  @ViewChild('agGrid')
  public agGrid: any;

  public dataGridOptions: GridOptions = {};

  @Input()
  public hasWritePermission: boolean = false;

  @Input()
  public reduced          : boolean = false;

  @Input()
  public trackerID;

  @Output()
  public saved: EventEmitter<boolean> = new EventEmitter();

  public stationId: string;

  public machineryTypes  : any;
  private destroy$  : Subject<boolean> = new Subject<boolean>();

  public machineryModelOptions: any = [];
  public machineryPowerTypeOptions: any = [
    {value: 'kw', content: 'kw'},
    {value: 'hp', content: 'hp'}
  ];

  public machineryTrackerOptions: any = [];

  public machinerySelectForm: FormGroup = new FormGroup({
    selectedMachinery: new FormControl('')
  });

  public get serviceDateControl(): AbstractControl {
    return this.serviceForm.get('date');
  }

  public allMachinerys: any;

  public machineryForm: FormGroup = new FormGroup({
    machineryPlateNumber: new FormControl(),
    machineryFrameNumber: new FormControl(),
    machineryName: new FormControl(),
    machineryNewType: new FormControl(),
    machineryDescription: new FormControl(),
    machineryBrand: new FormControl(),
    machineryModel: new FormControl(),
    machineryPower: new FormControl(),
    machineryPowerType: new FormControl(),
    machineryPurchaseDate: new FormControl(),
    machineryLastTechicalDate: new FormControl(),
    machineryUpcomingTechnicalDate: new FormControl(),
    machineryServiceCurrentKm: new FormControl(),
    machineryServiceMaxtKm: new FormControl(),
    machineryServiceCurrentEngineH: new FormControl(),
    machineryServiceMaxEngineH: new FormControl(),
    machineryServiceMaxDays: new FormControl(),
    trackerID: new FormControl(),
  });

  public serviceForm: FormGroup = new FormGroup({
    date: new FormControl(moment().toDate()),
    service: new FormControl(),
    km:  new FormControl(),
    h:  new FormControl(),
    note: new FormControl()
  });

  public machineryReducedForm: FormGroup;

  public tree$                  : BehaviorSubject<ITreeStructure>;
  public state$                 : Observable<string>;

  public machineryAddNew: any = {
    machinery: false,
    brand: false,
    model: false
  };

  constructor(
    private treeService: TreeService,
    private leftComponentsToggler: LeftComponentsTogglerService,
    private machineryStore: Store<fromMachinerySettings.IMachineryDataState>,
    private navigationStationStore: Store<INavigationStationState>,
    private api: ApiCallService
  ) {
    this.machineryStore.dispatch(resetMachineryDataStore());
    this.setDataGridOptions([]);
  }

  public ngOnInit(): void {
    this.tree$ = this.treeService.getStationSettingsTreeStructure();
    this.state$ = this.leftComponentsToggler.getStationDataContentState();

    this.getStationChangeObservable().subscribe((): void => {
      this.machineryStore.dispatch(getAddedMachinery());
      this.machineryStore.dispatch(getAddedTrackers());
    });

    this.machineryStore.pipe(
      takeUntil(this.destroy$),
      select(fromMachinerySettings.selectMachinery)
    ).subscribe(data => {
      if (data instanceof Object) {
        const keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
          this.machineryForm.patchValue({[keys[i]]: data[keys[i]]});
        }
        if (!data['trackerID']) {
          this.machineryForm.patchValue({trackerID: ''});
        }
      }
    });

    this.machineryStore.pipe(
      takeUntil(this.destroy$),
      select(fromMachinerySettings.selectAddedMachinery)
    ).subscribe((data) => {
      if (data) {
        const array = data.slice();
        array.unshift({value: '', content: '-- New Machinery --'});
        this.allMachinerys = array;
      }
    });

    this.machineryStore.pipe(
      takeUntil(this.destroy$),
      select(fromMachinerySettings.selectAddedTrackers)
    ).subscribe((data) => {
      if (data) {
        const array = data;
        array.unshift({value: '', content: 'None'});
        this.machineryTrackerOptions = array;
      }
    });

    this.machineryStore.pipe(
      takeUntil(this.destroy$),
      select(fromMachinerySettings.selectLastAddedMachinery)
    ).subscribe(machineryID => {
      if (machineryID) {
        this.machinerySelectForm.setControl('selectedMachinery', new FormControl(machineryID));
      }
    });

    // Clears data on popup open
    if (this.reduced) {
      this.machineryForm.reset();
    }

    this.machineryReducedForm = new FormGroup({
      machineryPlateNumber: new FormControl(),
      machineryFrameNumber: new FormControl(),
      machineryName: new FormControl()
    });

    this.machinerySelectForm.valueChanges.subscribe(val => {
      if (val.selectedMachinery === '') {
        this.machineryForm.reset();
      } else {
        this.machineryStore.dispatch(getMachinery(val.selectedMachinery));
        this.updateServiceLogTable(val.selectedMachinery);
      }
    });
  }

  public updateServiceLogTable(id: string): void {
    this.api.getMachineryServiceLog(id).subscribe(
      res => {
        this.setDataGridOptions(res);
      }
    );
  }

  public setDataGridOptions(data: any[]): void {
    this.dataGridOptions = null;
    const rowData: Array<any> = [];
    const columnDefs: Array<ColDef> = [
      {
        headerName: 'Date Time',
        field: 'time',
        pinned: true,
        sort: 'asc',
        suppressMenu: true,
        suppressSorting: false
      },
      {
        headerName: 'Service',
        field: 'service',
        pinned: true,
        suppressMenu: true,
        suppressSorting: false
      },
      {
        headerName: 'Note',
        field: 'note',
        pinned: true,
        suppressMenu: true,
        suppressSorting: false
      }
    ];

    for (let i = 0; i < data.length; i++) {
      const row  = {
        time: data[i].date,
        service: data[i].service,
        note: data[i].note
      };
      rowData.push(row);
    }

    this.dataGridOptions = {
      columnDefs: columnDefs,
      rowData: rowData,
      enableColResize: true,
      enableSorting: true,
      rowHeight: 30,
      headerHeight: 28,
      rowSelection: 'single'
    };

    if (this.agGrid) {
      this.agGrid.api.setColumnDefs(columnDefs);
      this.agGrid.api.setRowData(rowData);
      this.agGrid.api.sizeColumnsToFit();
    }
  }

  private getStationChangeObservable(): Observable<IStation> {
    return this.navigationStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((station: IStation): boolean => !!station),
      tap((station: IStation): void => {
        this.stationId = station.name.original;
        this.machineryForm.reset();
      })
    );
  }

  public save(): void {
    this.saved.emit();

    this.machineryStore.dispatch(
      saveMachinery({
        stationId: this.machinerySelectForm.value.selectedMachinery,
        body: this.machineryForm.value
      })
    );
    setTimeout(() => {
      this.machineryStore.dispatch(getAddedMachinery());
      this.machineryStore.dispatch(getAddedTrackers());
      if (this.reduced) {
        this.machineryForm.reset();
      }
    }, 300);
  }

  public saveShort(): void {
    this.machineryStore.dispatch(
      saveMachinery({
        stationId: this.machinerySelectForm.value.selectedMachinery,
        body: this.machineryForm.value
      })
    );
    if (this.reduced) {
      this.machineryForm.reset();
    }
    this.saved.emit();
  }

  public saveLog(): void {
    if (this.machinerySelectForm.value.selectedMachinery && this.serviceForm.value.date && this.serviceForm.value.service) {
      const body = {
        'date': moment(this.serviceForm.value.date).unix(),
        'service': this.serviceForm.value.service,
        'km': this.serviceForm.value.km,
        'h': this.serviceForm.value.h,
        'note': this.serviceForm.value.note
      };
      this.api.saveMachineryServiceLog(this.machinerySelectForm.value.selectedMachinery, body).subscribe(
        res => {
          this.updateServiceLogTable(this.machinerySelectForm.value.selectedMachinery);
        }
      );
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
