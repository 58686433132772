import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
  getWidgetData,
  removeWidget,
  setWidgetError,
  setWidgetLoading,
  updateWidgetEditMode,
  updateWidgetSettings
} from '../../../actions/dashboard';
import { IDataRequest, ILoadWidgetData, IUpdateWidgetEditMode, IWidget } from '../../../models/dashboard.models';
import * as fromDashboard from '../../../reducers/dashboard';
import { selectEdit } from '../../../reducers/index';

@Component({
  selector: 'app-data-widget',
  templateUrl: './data-widget.component.html',
  styleUrls: ['./data-widget.component.scss']
})
export class DataWidgetComponent implements OnInit {
  @Input()
  public widget                      : IWidget;
  @Input()
  private widgetIndex                : number;
  @Input()
  private columnIndex                : number;
  @Input()
  private rowIndex                   : number;
  public isEdit$                     : Observable<boolean>;
  private loadWidgetData             : ILoadWidgetData;

  private splittedTitle               : string [];

  constructor(
    private dashboardStore: Store<fromDashboard.IDashboard>,
    private translations: TranslateService
  ) {}

  public ngOnInit(): void {
    this.isEdit$ = this.dashboardStore.pipe(select(selectEdit));

    if (!this.widget.loadData) {
      this.loadTableData();
    }
  }

  public removeCurrentWidget(): void {
    const path: Array<number> = [this.rowIndex, this.columnIndex, this.widgetIndex];
    this.dashboardStore.dispatch(removeWidget(path));
  }

  public loadTableData(): void {
    this.loadWidgetData = {
      url: '/ag-grid/' + this.widget.requests.data.endpoint.station
      + '/' + this.widget.requests.data.endpoint.group +
      '/last/' + this.widget.requests.data.endpoint.last,
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      profile: 'All sensors'
    };
    this.resetLoading();
    this.dashboardStore.dispatch(getWidgetData(this.loadWidgetData));
  }

  private resetLoading(): void {
    this.dashboardStore.dispatch(setWidgetLoading({
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      loading: true
    }));
    this.dashboardStore.dispatch(setWidgetError({
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      error: ''
    }));
  }

  public updateRequest(updateSettings: IDataRequest): void {
    this.dashboardStore.dispatch(updateWidgetSettings({
      type: 'data',
      updateSettings: updateSettings,
      path: [this.rowIndex, this.columnIndex, this.widgetIndex]
    }));
  }

  public editMode(editMode: boolean): void  {
    const updateSettings: IUpdateWidgetEditMode = {
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      type: 'data',
      isEdit: editMode,
    };
    this.dashboardStore.dispatch(updateWidgetEditMode(updateSettings));
  }
}
