import { IAnimalProductionRequest, IAnimalProductionWarningMessage } from '../models/animal-production.models';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { IAnimalProductionState } from '../models/animal-production.models';

export const enum AnimalProductionActionTypes {
  GET_ANIMAL_PRODUCTION_CHARTS = '[AnimalProduction] GET_ANIMAL_PRODUCTION_SERVICE',
  SET_ANIMAL_PRODUCTION_CHARTS = '[AnimalProduction] SET_ANIMAL_PRODUCTION_CHARTS',
  SET_ANIMAL_PRODUCTION_LOADING = '[AnimalProduction] SET_ANIMAL_PRODUCTION_LOADING',
  SET_ANIMAL_PRODUCTION_ERROR = '[AnimalProduction] SET_ANIMAL_PRODUCTION_ERROR',
  SET_ANIMAIL_PRODUCTION_WARNING = '[AnimalProduction] SET_ANIMAIL_PRODUCTION_WARNING',
  GET_ANIMAL_PRODUCTION_LICENSES = '[AnimalProduction] GET_ANIMAL_PRODUCTION_LICENSES',
  SET_ANIMAL_PRODUCTION_LICENSES = '[AnimalProduction] SET_ANIMAL_PRODUCTION_LICENSES'
}

export function getAnimalProductionCharts(data: IAnimalProductionRequest): IActionWithPayload {
  return {type: AnimalProductionActionTypes.GET_ANIMAL_PRODUCTION_CHARTS, payload: data};
}

export function setAnimalProductionCharts(data: IAnimalProductionState): IActionWithPayload {
  return {type: AnimalProductionActionTypes.SET_ANIMAL_PRODUCTION_CHARTS, payload: data};
}

export function setAnimalProductionLoading(status: boolean): IActionWithPayload {
  return {type: AnimalProductionActionTypes.SET_ANIMAL_PRODUCTION_LOADING, payload: status};
}

export function setAnimalProductionError(status: boolean): IActionWithPayload {
  return {type: AnimalProductionActionTypes.SET_ANIMAL_PRODUCTION_ERROR, payload: status};
}

export function getAnimalProductionLicenses(stationId: string): IActionWithPayload {
  return {type: AnimalProductionActionTypes.GET_ANIMAL_PRODUCTION_LICENSES, payload: stationId};
}

export function setAnimalProductionLicenses(licenses: any): IActionWithPayload {
  return {type: AnimalProductionActionTypes.SET_ANIMAL_PRODUCTION_LICENSES, payload: licenses};
}

export function setAnimalProductionWarning(warning: IAnimalProductionWarningMessage): IActionWithPayload {
  return { type: AnimalProductionActionTypes.SET_ANIMAIL_PRODUCTION_WARNING, payload: warning };
}
