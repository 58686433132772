import {ICropViewSettingsState, ICropViewState} from '../models/crop-view.models';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {CropViewActionTypes} from '../actions/crop-view';
import {CropViewSettingsActionTypes} from '../actions/crop-view-settings';

const initialState: ICropViewState = {
  pictures: {},
  isLoading: true,
  isError: false,
  firstDate: null,
  lastDate: null
};

export function reducer(state: ICropViewState = initialState, action: IActionWithPayload): ICropViewState {
  switch (action.type) {
    case CropViewActionTypes.SET_CROP_VIEW_PHOTOS:
      state.pictures[action.payload.camId] = action.payload.pictures;
      return <ICropViewState>{...state};
    case CropViewActionTypes.SET_CROP_VIEW_MEASUREMENT:
        for (let i = 0; i < state.pictures[action.payload.cam_id].length; i++) {
          if (state.pictures[action.payload.cam_id][i].pic_id === action.payload.pic_id) {
            state.pictures[action.payload.cam_id][i].rectangles = action.payload.rectangles;
            break;
          }
        }
        return <ICropViewState>{...state};
    case CropViewActionTypes.SET_CROP_VIEW_PHOTOS_LOADING:
      return <ICropViewState> {
        ...state,
        isLoading: action.payload
      };
    case CropViewActionTypes.SET_CROP_VIEW_PHOTOS_ERROR:
      return <ICropViewState> {
        ...state,
        isError: action.payload
      };
    case CropViewActionTypes.SET_CROP_VIEW_FIRST_DATE:
      return <ICropViewState>{
        ...state,
        firstDate: action.payload
      };
    case CropViewActionTypes.SET_CROP_VIEW_LAST_DATE:
      return <ICropViewState>{
        ...state,
        lastDate: action.payload
      };
    default:
      return state;
  }
}
