import {NgModule} from '@angular/core';
import {StationInformationComponent} from './station-information.component';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../../shared/shared.module';
import {CommonModule} from '@angular/common';
import {StationInformationRoutingModule} from './station-information-routing.module';
import { StationInformationContentComponent } from './containers/station-information-content/station-information-content.component';
import { StationInformationTableComponent } from './containers/station-information-table/station-information-table.component';

@NgModule({
  imports: [
    CommonModule,
    StationInformationRoutingModule,
    SharedModule,
    TranslateModule.forChild()
  ],
  declarations: [
    StationInformationComponent,
    StationInformationContentComponent,
    StationInformationTableComponent
  ]
})
export class StationInformationModule { }
