import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, pluck, takeUntil } from 'rxjs/operators';
import { IStation } from '../../core/models/stations';
import { selectNavigationStation } from '../../core/reducers';
import { INavigationStationState } from '../../core/reducers/navigation-station';
import {
  IClearMeasurementsPayload,
  IDeleteImagePayload,
  IPicture,
  ISaveMeasurementsPayload,
  IToolbarSettings,
  PhotoRequestType
} from '../camera/models/camera';
import { CAMERA_CLEAR_MEASUREMENTS_MODAL_ID } from '../constants';
import { ModalService } from '../modal/services/modal.service';

@Component({
  selector: 'app-selected-image-toolbar',
  templateUrl: './selected-image-toolbar.component.html',
  styleUrls: ['./selected-image-toolbar.component.scss']
})
export class SelectedImageToolbarComponent implements OnInit, OnDestroy {
  @Input()
  public modalId: string;
  @Input()
  public selectedImage: IPicture;
  @Input()
  public downloadFilenamePrefix: string;
  @Input()
  public toolbarSettings$: Observable<IToolbarSettings>;
  @Input()
  public hasWritePermission: boolean = false;
  @Input()
  public showRemovedMeasurementsEnabled: boolean = true;
  @Input()
  public clearMeasurementsEnabled: boolean = false;

  @Output()
  public saveMeasurementsEmitter: EventEmitter<ISaveMeasurementsPayload> = new EventEmitter<ISaveMeasurementsPayload>();
  @Output()
  public clearMeasurementsEmitter: EventEmitter<IClearMeasurementsPayload> = new EventEmitter<IClearMeasurementsPayload>();
  @Output()
  public deleteImageEmitter: EventEmitter<IDeleteImagePayload> = new EventEmitter<IDeleteImagePayload>();
  @Output()
  public toolbarChangeEmitter: EventEmitter<IToolbarSettings> = new EventEmitter<IToolbarSettings>();
  @Output()
  public nextImageEmitter: EventEmitter<IPicture> = new EventEmitter<IPicture>();
  @Output()
  public previousImageEmitter: EventEmitter<IPicture> = new EventEmitter<IPicture>();

  public clearMeasurementsModalId = CAMERA_CLEAR_MEASUREMENTS_MODAL_ID;
  public stationId: string;
  public form: FormGroup;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private formBuilder: FormBuilder,
              private modalService: ModalService,
              private navigationStationStore: Store<INavigationStationState>) {
  }

  private get activityControl(): AbstractControl {
    return this.form.get('activity');
  }

  public get areMeasurementsActiveControl(): AbstractControl {
    return this.activityControl.get('areMeasurementsActive');
  }

  public get isHelpActiveControl(): AbstractControl {
    return this.activityControl.get('isHelpActive');
  }

  public ngOnInit(): void {
    this.createForm();
    this.initToolbarSettings();
    this.initFormListener();
    this.initSelectedStationListener();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public downloadImage(): void {
    const link = document.createElement('a');
    const filename = this.selectedImage.url.split('/');
    link.download = this.downloadFilenamePrefix + filename[filename.length - 1];
    const canvasElement: HTMLCanvasElement = <HTMLCanvasElement>document.getElementsByTagName('canvas')[0];
    canvasElement.toBlob((blob: Blob): void => {
        link.href = (window.URL as { createObjectURL(blob): string }).createObjectURL(blob);
        link.click();
      },
      'image/png'
    );
  }

  public openModal(): void {
    this.modalService.openModal(this.modalId);
  }

  public closeModal(modalId: string): void {
    this.modalService.closeModal(modalId);
  }

  public deleteImage(): void {
    this.deleteImageEmitter.emit({
      deleteRequest: {
        stationId: this.stationId,
        cam_id: this.selectedImage.cam_id,
        pic_id: this.selectedImage.pic_id
      },
      refreshRequest: {
        type: PhotoRequestType.SINGLE_DATE_INTERVAL,
        stationId: this.stationId,
        date: moment(this.selectedImage.time).toISOString(),
        camIds: []
      }
    });
    this.modalService.closeModal(this.modalId);
  }

  public save(): void {
    this.saveMeasurementsEmitter.emit({
      stationId: this.stationId,
      body: {
        cam_id: this.selectedImage.cam_id,
        filename: this.selectedImage.filename,
        date: new Date(moment(this.selectedImage.time).toISOString()),
        rectangles: this.selectedImage.filteredRectangles || this.selectedImage.rectangles,
      }
    });
  }

  public clear(): void {
    this.modalService.openModal(this.clearMeasurementsModalId);
  }

  public clearConfirm(): void {
    this.clearMeasurementsEmitter.emit({
      stationId: this.stationId,
      body: {
        cam_id: this.selectedImage.cam_id,
        filename: this.selectedImage.filename
      }
    });
  }

  public displayNextPicture(): void {
    this.nextImageEmitter.emit(this.selectedImage);
  }

  public displayPreviousPicture(): void {
    this.previousImageEmitter.emit(this.selectedImage);
  }

  public getMeasurementsToggleTooltip(): string {
    return this.areMeasurementsActiveControl.value
      ? 'Hide deleted measurements'
      : 'Show deleted measurements';
  }

  public toggleMeasurements(): void {
    this.areMeasurementsActiveControl.setValue(!this.areMeasurementsActiveControl.value);
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      'activity': this.formBuilder.group({
        'areMeasurementsActive': [false, [Validators.required]],
        'isHelpActive': [null, [Validators.required]],
        'isSaveInfoActive': [null],
        'isUpdateInfoActive': [null],
      })
    });
  }

  private initToolbarSettings(): void {
    this.toolbarSettings$.subscribe((toolbarSettings: IToolbarSettings): void => {
      this.activityControl.setValue({
        ...toolbarSettings.activity,
        areMeasurementsActive: this.showRemovedMeasurementsEnabled
          ? toolbarSettings.activity.areMeasurementsActive
          : false
      });
    });
  }

  private initFormListener(): void {
    this.form.valueChanges.pipe(
      debounceTime(200),
      takeUntil(this.destroy$),
    ).subscribe((changes): void => {
      this.toolbarChangeEmitter.emit(<IToolbarSettings>changes);
    });
  }

  private initSelectedStationListener(): void {
    this.navigationStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((station: IStation): boolean => !!station),
      pluck('name', 'original'),
    ).subscribe((stationId: string): void => {
      this.stationId = stationId;
    });
  }
}
