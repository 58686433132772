import { IFrostProtectionState } from '../models/models';

export const initialState: IFrostProtectionState = {
  settings: {
    period: {
      periodScope         : 'raw',
      periodValue         : '2d',
      fromTo              : null,
      fromDatepicker      : null,
      toDatepicker        : null,
      stationId           : '',
      isLastDataMode      : true
    },
    activity: {
      isChartActive       : true,
      isTableActive       : true,
      isExportImageActive : false,
      isExportActive      : false
    }
  },
  dataLoading: {
    error: {
      isError             : false,
      errorMessage        : ''
    },
    isLoading             : true
  },
  tree: {
    id                    : 20,
    expanded              : true,
    type                  : 'FrostProtection',
    name                  : '',
    hasSettingsIcon       : false,
    nodes                 : null,
    subNodes              : null
  },
  response                : null,
  charts                  : [],
  visibility              : null
};

