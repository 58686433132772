import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {ProductActivationModule} from '../../../../modules/product-activation/product-activation.module';
import {SharedModule} from '../../../../shared/shared.module';
import {ProductLicenseComponent} from './product-license.component';
import {ProductLicenseActivationComponent} from './product-license-activation/product-license-activation.component';
import {ProductLicenseActivationFormComponent} from './product-license-activation/product-license-activation-form/product-license-activation-form.component';
import {ProductLicenseSelectorComponent} from './product-license-selector/product-license-selector.component';
import { ProductLicenseDuplicationTextComponent } from './product-license-duplication-text/product-license-duplication-text.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    ProductActivationModule
  ],
  exports: [
    ProductLicenseComponent,
    ProductLicenseSelectorComponent
  ],
  declarations: [
    ProductLicenseComponent,
    ProductLicenseActivationComponent,
    ProductLicenseActivationFormComponent,
    ProductLicenseSelectorComponent,
    ProductLicenseDuplicationTextComponent
  ]
})
export class ProductLicenseModule { }
