<div class="page-without-left-tree">
  <app-user-settings-header></app-user-settings-header>

  <app-account-settings></app-account-settings>

  <app-close-account></app-close-account>

  <app-other-settings></app-other-settings>

  <app-notifications *ngIf="!theme?.hideSubscriptions"></app-notifications>
</div>
