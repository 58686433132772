import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MainSocket } from '../../../services/sockets/main.socket';
import { ADD_REMOVE_MODAL_ID, ADD_REMOVE_VIRTUAL_STATION_MODAL_ID } from '../../../shared/constants';
import { ModalService } from '../../../shared/modal/services/modal.service';
import { getUser } from '../../actions/account';
import { setCropZone, setCropzoneRoute } from '../../actions/cropzones';
import { setNotify } from '../../actions/notify';
import { setSelectedCropZone } from '../../actions/selectedCropZone';
import { getCalibrationSettings, getSystemSensors } from '../../actions/system';
import { NotifyTypes } from '../../constants/notify';
import { ICropZone } from '../../models/cropzones';
import { INotify } from '../../models/notify';
import { IStation } from '../../models/stations';
import {
  selectCropzones,
  selectNotifications,
  selectNotify,
  selectSelectedCropZone,
  selectStations,
  selectUserData
} from '../../reducers';
import * as fromAccount from '../../reducers/account';
import { IAccount } from '../../reducers/account';
import * as fromCropzones from '../../reducers/cropzones';
import * as fromNotify from '../../reducers/notify';
import { INotifyState } from '../../reducers/notify';
import * as fromSelectedCropzone from '../../reducers/selectedCropZone';
import * as fromStations from '../../reducers/stations';
import { LeftComponentsTogglerService } from '../../services/left-components-toggler/left-components-toggler.service';
import { NavigationService } from '../../services/navigation/navigation.service';

declare var $: any;

@Component({
  selector: 'app-main-static',
  templateUrl: './main-static.component.html',
  styleUrls: ['./main-static.component.scss']
})
export class MainStaticComponent implements OnInit, OnDestroy {
  public stationsAreOpened: boolean = false;
  public cropzonesAreOpened: boolean = false;
  public isMenuEnabled: boolean = false;
  public addRemoveModalId: string = ADD_REMOVE_MODAL_ID;
  public addRemoveVirtualStationModalId: string = ADD_REMOVE_VIRTUAL_STATION_MODAL_ID;
  public selectedCropzone: ICropZone;
  public isNotificationsEnabled: boolean = false;
  public notificationCount: number = 0;

  public user$: Observable<IAccount>;
  public stations$: Observable<Array<IStation>>;
  public cropzones$: Observable<Array<ICropZone>>;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private userStore: Store<fromAccount.IAccount>,
    private translate: TranslateService,
    private stationsStore: Store<fromStations.IStations>,
    private cropzonesStore: Store<fromCropzones.ICropZones>,
    private notifyStore: Store<fromNotify.INotifyState>,
    private navigation: NavigationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private leftComponentsToggler: LeftComponentsTogglerService,
    private modalService: ModalService,
    private cropzoneStore: Store<fromCropzones.ICropZones>,
    private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    protected mainSocket: MainSocket,
    protected angularMessaging: AngularFireMessaging,
    private translations: TranslateService
  ) {

    this.userStore.dispatch(getUser());
    this.userStore.dispatch(getSystemSensors());
    this.userStore.dispatch(getCalibrationSettings());
  }

  private showNotify(notify: INotify): void {
    this.translate.get(notify.message)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((translateString: string) => {
        $.notify({
          title: translateString,
          message: ''
        }, {
          type: notify.type,
          delay: notify.delay || 3000,
          timer: notify.timer || 1000,
          offset: {
            x: 18,
            y: 15
          },
          placement: {
            from: 'bottom',
            to: 'top'
          }
        });
      });
  }

  private navigateToCurrentId(): void {
    const [path, id] = this.getPathAndIdFromRoute();

    if (path === 'cropzone') {
      this.navigation.changeCropzone(id);
    } else {
      this.navigation.changeStation(id);
    }
  }

  private initNotifySubscription(): void {
    this.notifyStore.pipe(
      takeUntil(this.destroy$),
      select(selectNotify),
      filter((state: INotifyState) => !!state.notify)
    ).subscribe((state: INotifyState) => {
      const notification = NotifyTypes.get(state.notify);
      if (notification) {
        this.translations.get(notification.message)
          .subscribe(translatedNotification => {
            notification.message = translatedNotification;
            this.showNotify(notification);
          });
      }
    });
  }

  public openAddRemoveModal(): void {
    this.modalService.openModal(this.addRemoveModalId);
  }

  public openAddRemoveVirtualStationModal(): void {
    this.modalService.openModal(this.addRemoveVirtualStationModalId);
  }

  public toggleUserMenu(isClose: boolean): void {
    if (isClose) {
      this.isMenuEnabled = false;
    } else {
      this.stationsAreOpened = false;
      this.isMenuEnabled = !this.isMenuEnabled;
    }
  }

  public toggleNotifications(isClose: boolean): void {
    if (isClose) {
      this.isNotificationsEnabled = false;
    } else {
      this.stationsAreOpened = false;
      this.isNotificationsEnabled = !this.isNotificationsEnabled;
    }
  }

  public ngOnInit(): void {

    this.navigateToCurrentId();
    this.router.events.pipe(
      takeUntil(this.destroy$),
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event): void => {
      if (this.getPathAndIdFromRoute()[0] === 'cropzone') {
        this.cropzonesStore.dispatch(setCropzoneRoute(event['url'].split('/')[event['url'].split('/').length - 1]));
      }
      this.navigateToCurrentId();
    });

    this.user$ = this.userStore.pipe(select(selectUserData));

    this.mainSocket.select('user.login').subscribe(result => {
      if (result) {
        this.mainSocket.listen('cropzone.watch');
      }
    });

    // hidden web notifications
    // if (Notification['permission'] !== 'granted') {
    //   this.angularMessaging.requestToken.pipe(
    //     catchError(() => of(null))
    //   ).subscribe(token => {
    //
    //     const currentToken = localStorage.getItem('cloud-messages-token');
    //
    //     if (token && currentToken !== token) {
    //       this.userStore.dispatch(saveUserWebToken({ type: 'web', value: token }));
    //       localStorage.setItem('cloud-messages-token', token);
    //     }
    //   });
    // }
    //
    // this.angularMessaging.messages.subscribe(({ notification }: any) =>
    //   new Notification(notification.title, {
    //     body: notification.body,
    //     tag: notification.tags
    //   })
    // );

    // get number of notifications
    this.userStore.pipe(
      select(selectNotifications),
      takeUntil(this.destroy$)
    ).subscribe((data: any) => {
      this.notificationCount = data.filter(item => !item.seen).length;
    });

    this.user$.pipe(
      takeUntil(this.destroy$),
    ).subscribe(() => this.mainSocket.authorize());

    this.mainSocket.select('reconnect').pipe(
      takeUntil(this.destroy$),
    ).subscribe(() => this.mainSocket.authorize());

    this.stations$ = this.stationsStore.pipe(
      select(selectStations),
      tap(() => this.navigateToCurrentId())
    );

    this.cropzones$ = this.cropzonesStore.pipe(
      select(selectCropzones),
      tap(() => this.navigateToCurrentId())
    );

    this.selectedCropzoneStore.pipe(
      takeUntil(this.destroy$),
      select(selectSelectedCropZone)
    ).subscribe((cropzone) => {
      this.selectedCropzone = cropzone;
    });

    this.cropzones$.pipe(
      takeUntil(this.destroy$),
      switchMap(() => this.mainSocket.watch('cropzone.update')),
      tap(() => this.notifyStore.dispatch(setNotify('Loading cropzone')))
    ).subscribe((cropzone: ICropZone) => {
      if (cropzone && cropzone.id) {

        this.cropzoneStore.dispatch(setCropZone(cropzone));

        if (
          this.selectedCropzone &&
          this.selectedCropzone.id === cropzone.id
        ) {

          this.selectedCropzoneStore.dispatch(setSelectedCropZone(cropzone));
        }
      }
    });

    this.initNotifySubscription();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  @HostListener('window:resize')
  public sizeChange(): void {
    this.leftComponentsToggler.handleSizeChange();
  }

  private getPathAndIdFromRoute(): [string, string] {
    const firstChild: ActivatedRoute = this.activatedRoute.firstChild;
    const path = firstChild.routeConfig.path;
    const id = firstChild.snapshot.params.hasOwnProperty('id')
      ? firstChild.snapshot.params.id
      : firstChild.firstChild.snapshot.params.id;
    return [path, id];
  }

  public openStations(): void {
    this.stationsAreOpened = !this.stationsAreOpened;
    this.cropzonesAreOpened = false;
  }

  public openCropzones(): void {
    this.cropzonesAreOpened = !this.cropzonesAreOpened;
    this.stationsAreOpened = false;
  }
}
