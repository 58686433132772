<section>
  <app-form-layout>
    <app-iscout-header
      [title]="'Glue Boards Management'"
      [enableHelpButton]="true"
      (openModal)="handleToggleHelp()"
    ></app-iscout-header>

    <div class="iscout-glue-board-list-table">
      <div class="iscout-glue-board-list__button">
        <app-custom-button [content]="'Add Glue Board'" (click)="handleGlueBoardAdd()">
          <i class="fa fa-fw fa-plus"></i>
        </app-custom-button>
      </div>

      <br style="clear: both">
    </div>

    <div class="iscout-glue-board-list-table table-responsive">
      <table class="iscout-glue-board-list-table__table">
        <thead class="iscout-glue-board-list-table__table__head">
        <tr>
          <th>{{ 'Started' | translate }}</th>
          <th>{{ 'Finished' | translate }}</th>
          <th style="width: 220px">{{ 'Target insects' | translate }}</th>
          <th>{{ 'Status' | translate }}</th>
          <th style="width: 240px"></th>
        </tr>
        </thead>
        <tbody class="iscout-glue-board-list-table__table__body">
        <ng-container *ngFor="let glueBoard of (glueBoardList$ | async)">
          <tr>
            <td>{{ glueBoard.from | date: 'yyyy-MM-dd HH:mm' }}</td>
            <td>{{ glueBoard.to | date: 'yyyy-MM-dd HH:mm' }}</td>
            <td>{{ renderGlueBoardTargets(glueBoard.target) }}</td>
            <td>
              <span class="label label-default" [ngClass]="getPlateStateClass(glueBoard.plate_state)">
                {{ getPlateStateLabel(glueBoard.plate_state) | translate }}
              </span>
            </td>
            <td style="float: right">
              <app-custom-button
                *ngIf="!glueBoard.to"
                [color]="'green'"
                [content]="'Exchange'"
                (click)="handleGlueBoardExchange(glueBoard)"
              >
                <i class="fa fa-exchange"></i>
              </app-custom-button>

              <app-custom-button
                *ngIf="glueBoard.to"
                [content]="'Remove'"
                [color]="'red'"
                (click)="handleGlueBoardRemove(glueBoard)"
              >
                <i class="fa fa-fw fa-trash"></i>
              </app-custom-button>

              <app-custom-button
                [content]="'Update'"
                (click)="handleGlueBoardEdit(glueBoard)"
              >
                <i class="fa fa-fw fa-pencil"></i>
              </app-custom-button>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
  </app-form-layout>

  <app-modal [modalId]="glueBoardRemoveModalId" size="sm">
    <app-iscout-glue-board-delete-modal
      (confirmEmitter)="handleGlueBoardRemoveConfirmation()"
      (cancelEmitter)="handleCloseModal(glueBoardRemoveModalId)"
    ></app-iscout-glue-board-delete-modal>
  </app-modal>

  <app-modal [modalId]="glueBoardExchangeModalId" size="sm">
    <app-iscout-glue-board-exchange-modal
      (confirmEmitter)="handleGlueBoardExchangeConfirmation()"
      (cancelEmitter)="handleCloseModal(glueBoardExchangeModalId)"
    ></app-iscout-glue-board-exchange-modal>
  </app-modal>

  <app-info-modal [modalId]="helpModalId"
                  [header]="(subDomain?.iScoutNames ? subDomain.iScoutNames : ('iScout' | translate)) + ' ' + ('Glue Boards' | translate)">
    <p>
      {{
      'That side helps you to manage your insect season.' | translate
      }}
    </p>

    <p>
      {{
      'ADD GLUE BOARD (blue button on the right side): you are able to work on that page also ' +
      'expose and define starting and finished date (when you insert and exchange glue boards ' +
      'in the trap) as well as define the insect list (Order level/Insect species) monitored on that ' +
      'glue board. Using that function and adding specific target insect on the glue board page will ' +
      'influence your detection page with the photos, only those insects that are added to target and ' +
      'are of interest will be seen and followed on your photos. All the others are handled as ' +
      '"not interested insects" and are hidden automatically.' | translate
      }}
    </p>
  </app-info-modal>
</section>
