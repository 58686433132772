import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GridOptions } from 'ag-grid';
import { DataGridOptions } from '../../../../shared/constants';
import { deepClone } from '../../../../shared/utils/deepClone';
import { suppressMenu } from '../../../../shared/utils/suppressMenu';

@Component({
  selector: 'app-forecast-table',
  templateUrl: './forecast-table.component.html',
  styleUrls: ['./forecast-table.component.scss']
})
export class ForecastTableComponent implements OnInit, OnChanges {
  @Input()
  public data             : any;
  @Input()
  public stationName      : string;
  @Input()
  public station          : string;
  public dataGridOptions  : GridOptions = deepClone(DataGridOptions);

  constructor(private cd: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.cd.detach();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.data !== undefined) {
      if (changes.data.currentValue !== changes.data.previousValue) {
        setTimeout(() => {
          if (this.data.headers && this.dataGridOptions.api) {
            this.data.headers = suppressMenu(this.data.headers);
            this.data.headers[0].unSortIcon = true;
            this.dataGridOptions.api.setColumnDefs(this.data.headers);
            this.dataGridOptions.api.setRowData(this.data.data);
          } else if (this.dataGridOptions.api) {
            this.dataGridOptions.api.setColumnDefs(null);
            this.dataGridOptions.api.setRowData(null);
          }
        }, 0);
        this.cd.detectChanges();
      }
    }
  }
}
