import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { deepClone } from '../../../../shared/utils/deepClone';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { saveConvertUnits } from '../../actions/warningsList';
import { IConvertUnit } from '../../models/models';
import { selectWarningsList } from '../../reducers';
import * as fromWarningsList from '../../reducers/warningsList';
import { IWarningsListState } from '../../reducers/warningsList';

@Component({
  selector: 'app-warnings-list',
  templateUrl: './warnings-list.component.html',
  styleUrls: ['./warnings-list.component.scss']
})
export class WarningsListComponent implements OnInit, OnDestroy {
  @Input()
  public station                : IStation;
  public warningsList           : IWarningsListState;
  public modalId                : string = generateId();
  private spaceReg              : RegExp = / /g;
  private destroy$              : Subject<boolean> = new Subject<boolean>();

  constructor(private warningsListStore: Store<fromWarningsList.IWarningsListState>,
              private modalService: ModalService) { }

  public ngOnInit(): void {
    this.warningsListStore.pipe(
      takeUntil(this.destroy$),
      select(selectWarningsList)
    ).subscribe((w: IWarningsListState) => {
      this.warningsList = w;
    });
  }

  public openModal(): void {
    this.modalService.openModal(this.modalId);
  }

  public save(): void {
    const s: IStation = deepClone(this.station);
    const preparedWarnings: string[] = this.prepareStationWarnings();
    s.warnings.sensors = preparedWarnings;
    this.warningsListStore.dispatch(saveConvertUnits(
      {
        station: s,
        units: this.prepareConvertUnits(),
        saveStation: {
          'warnings.sensors': preparedWarnings
        }
      }
    ));
  }

  public getListKeys(): string[] {
    return Object.keys(this.warningsList.warnings);
  }

  private prepareConvertUnits(): IConvertUnit[] {
    let preparedConvertUnits: IConvertUnit[] = [];
    this.getListKeys().forEach((k: string) => {
      if (this.warningsList.warnings[k].settings.min) {
        preparedConvertUnits = preparedConvertUnits.concat(this.prepareConvertUnitsHelp(k, 'min'));
      }
      if (this.warningsList.warnings[k].settings.max) {
        preparedConvertUnits = preparedConvertUnits.concat(this.prepareConvertUnitsHelp(k, 'max'));
      }
    });
    return preparedConvertUnits;
  }

  private prepareConvertUnitsHelp(k: string, name: string): IConvertUnit[] {
    return this.warningsList.warnings[k].settings[name]
      .replace(this.spaceReg, '')
      .split(',')
      .map((v: string) => this.prepareConvertUnitsHelpObject(k, v));
  }

  private prepareConvertUnitsHelpObject(k: string, v: string): IConvertUnit {
    return {
      code: `${this.warningsList.warnings[k].code}`,
      unit: this.warningsList.warnings[k].unit,
      value: v
    };
  }

  private prepareStationWarnings(): string[] {
    let preparedWarning: string[] = [];
    this.getListKeys().forEach((k: string) => {
      if (this.warningsList.warnings[k].settings.min) {
        preparedWarning = preparedWarning.concat(this.prepareStationWarningsHelp(k, 'min', 0));
      }
      if (this.warningsList.warnings[k].settings.max) {
        preparedWarning = preparedWarning.concat(this.prepareStationWarningsHelp(k, 'max', 1));
      }
    });
    return preparedWarning;
  }

  private prepareStationWarningsHelp(k: string, name: string, i: number): string[] {
    return this.warningsList.warnings[k].settings[name]
      .replace(this.spaceReg, '')
      .split(',')
      .map((v: string) => this.prepareStationWarningsHelpString(k, i, v));
  }

  private prepareStationWarningsHelpString(k: string, i: number, value: string): string {
    return `${this.warningsList.warnings[k].ch},${this.warningsList.warnings[k].code},${i},${value}`;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
