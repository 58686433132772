import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IStation } from '../../core/models/stations';
import { selectNavigationStation } from '../../core/reducers';
import { ISelectedStationState } from '../../core/reducers/selectedStation';
import { ITreeStructure } from '../../services/tree/models';
import { TreeService } from '../../services/tree/tree.service';
import { IStationSettingsPeriod } from '../../shared/interfaces';
import { setProfile, TreeSettingsActionTypes } from '../../shared/tree/actions/tree-settings';
import { ITreeSettingsState } from '../../shared/tree/models/tree.models';
import { checkCameras } from '../../shared/utils/checkCameras';
import { dateToUtcUnixTimestamp } from '../../shared/utils/dateFormat';
import { getStationDataProfiles } from './actions/station-data';
import { setStationCalculationEnabled } from './actions/station-data-settings';
import { IStationDataProfile } from './models/station-data.models';
import { selectStationDataProfiles, selectStationDataSettingsPeriod, selectTreeSettings } from './reducers';
import { IStationDataState } from './reducers/station-data';
import { IStationDataSettingsState } from './reducers/station-data-settings';

@Component({
  selector: 'app-station-data',
  templateUrl: './station-data.component.html',
  styleUrls: ['./station-data.component.scss']
})
export class StationDataComponent implements OnInit, OnDestroy {

  public tree$: Observable<ITreeStructure>;

  private stationId: string;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private stationDataStore: Store<IStationDataState>,
    private stationSettingsStore: Store<IStationDataSettingsState>,
    private selectedStationStore: Store<ISelectedStationState>,
    private treeSettingsStore: Store<ITreeSettingsState>,
    private treeService: TreeService
  ) {}

  public ngOnInit(): void {
    this.tree$ = this.treeService.getStationDataTreeStructure().pipe(
      takeUntil(this.destroy$)
    );
    this.selectedStationStore
      .pipe(
        takeUntil(this.destroy$),
        select(selectNavigationStation),
        filter((station: IStation): boolean => !!station)
      )
      .subscribe((station: IStation) => {
        this.stationId = station.name.original;
        this.stationSettingsStore.dispatch(setStationCalculationEnabled(!checkCameras(station.info.device_id)));
      });
    this.subscribeToStationSettingsStore();
    this.subscribeToStationDataAndTreeSettings();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.treeService.getStationDataTreeStructure().next(null);
  }

  private subscribeToStationSettingsStore(): void {
    this.stationSettingsStore
      .pipe(
        takeUntil(this.destroy$),
        select(selectStationDataSettingsPeriod),
        filter(d => !!d)
      )
      .subscribe((pageSettingsPeriod: IStationSettingsPeriod): void => {
        if (pageSettingsPeriod.isLastDataMode && pageSettingsPeriod.fromTo) {
          this.dispatchGetProfiles(pageSettingsPeriod.fromTo.from, pageSettingsPeriod.fromTo.to);
        } else if (pageSettingsPeriod.fromDatepicker && pageSettingsPeriod.toDatepicker) {
          this.dispatchGetProfiles(pageSettingsPeriod.fromDatepicker, pageSettingsPeriod.toDatepicker);
        }
      });
  }

  private subscribeToStationDataAndTreeSettings(): void {
    combineLatest([
      this.stationDataStore.pipe(
        takeUntil(this.destroy$),
        select(selectStationDataProfiles),
        filter((profiles: Array<IStationDataProfile>): boolean => !!profiles && profiles.length > 0)
      ),
      this.treeSettingsStore.pipe(
        select(selectTreeSettings),
        filter((treeSettings: ITreeSettingsState): boolean => !!treeSettings)
      )
    ]).subscribe((result: Array<Array<IStationDataProfile>|ITreeSettingsState>): void => {
      const profiles: Array<IStationDataProfile> = <Array<IStationDataProfile>>result[0];
      const profile = profiles[0];
      const treeSettings: ITreeSettingsState = <ITreeSettingsState>result[1];
      if (!treeSettings[this.stationId] || !treeSettings[this.stationId].selectedProfile) {
        this.treeSettingsStore.dispatch(setProfile({
          stationId: this.stationId,
          profile: profile.name
        }, TreeSettingsActionTypes.SET_STATION_DATA_PROFILE));
      }
    });
  }

  private dispatchGetProfiles(from: Date, to: Date): void {
    this.stationDataStore.dispatch(getStationDataProfiles({
      from: dateToUtcUnixTimestamp(from),
      to: dateToUtcUnixTimestamp(to),
      stationId: this.stationId,
    }));
  }
}
