import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import * as d3 from 'd3';
import { legendItems, ndviLegendItems } from '../consts';
import { defaultChartOptionsSatellite, maxLai } from './../consts/index';
import { Chart } from 'angular-highcharts';
import { ILegendItem } from '../models';
import { SerieNames } from '../consts/index';

@Injectable({
  providedIn: 'root',
})
export class CropZoneSatServiceService {
  private DEFAULT_CHART_OPTIONS = defaultChartOptionsSatellite;
  private allLaiItems: ILegendItem[] = legendItems;
  private allNdviItems: ILegendItem[] = ndviLegendItems;
  private legendItems: string[] = legendItems.map((item) => item.item);
  private ndviLegendItems: string[] = ndviLegendItems.map((item) => item.item);

  private previousSelectedLegendItem: string;
  private previousSelectedFeatures: any[];
  private detailedLaiVal: any;
  private featureAreas: any;


  public componentMethodCallSource = new Subject<any>();
  public componentMethodCalled$ = this.componentMethodCallSource.asObservable();
  public fillNdviColor(featureNdviProperty: number): string {
    let color: string;

    this.allNdviItems.forEach((item) => {
      if (!item.startValue && featureNdviProperty <  item.endValue) {
        color = item.color;
      } else {
        if (featureNdviProperty >  item.startValue && featureNdviProperty <= item.endValue) {
          color = item.color;
        }
      }
    });

    return color;
  }

  public fillColor(featureLaiProperty: number): string {
    let color: string;

    this.allLaiItems.forEach((item, index) => {
      if (index === 0 && featureLaiProperty > item.startValue) {
        color = item.color;
      }

      if (index !== 0) {
        if (!item.endValue) {
          if (item.startValue === featureLaiProperty) {
            color = item.color;
          }
        } else {
          if (featureLaiProperty > item.startValue && featureLaiProperty <= item.endValue) {
            color = item.color;
          }
        }
      }
    });

    return color;
  }

  public fillColorLegend(featureLaiProperty: any, type: string): string {
    const legendItemsForLegend = type === 'LAI' ? this.allLaiItems : this.allNdviItems;
    let color: string;

    legendItemsForLegend.forEach((item) => {
      if (item.item === featureLaiProperty) {
        color = item.color;
      }
    });

    if (color) {
      return color;
    }
  }

  public renderDetailedMap(sentinelValue: any, boundary: any, featureAreas: any, type: string): void {
    this.detailedLaiVal = sentinelValue;
    this.featureAreas = featureAreas;
    const self = this;
    self.previousSelectedLegendItem = null;
    self.previousSelectedFeatures = null;

    const path = d3.geoPath().projection(d3.geoMercator().fitSize([450, 450], sentinelValue));
    d3.select('#map').selectAll('*').remove();
    d3.select('#legend').selectAll('*').remove();
    d3.select('#tooltip').selectAll('*').remove();

    const svg = d3.select('#tooltip').append('svg')
      .attr('height', 45)
      .attr('width', 100);

    const tooltip = svg
      .append('rect')
      .attr('width', 100)
      .attr('height', 45)
      .attr('stroke', 'black')
      .attr('fill', '#DCDCDC')
      .attr('rx', 5)
      .attr('display', 'none');

    const tooltiptext = svg
      .append('text')
      .attr('width', 100)
      .attr('height', 15)
      .attr('display', 'none')
      .attr('font-size', '15px')
      .attr('text-anchor', 'middle')
      .attr('y', '20px')
      .attr('x', '50px');

      const tooltiptext2 = svg
      .append('text')
      .attr('width', 100)
      .attr('height', 15)
      .attr('display', 'none')
      .attr('font-size', '15px')
      .attr('text-anchor', 'middle')
      .attr('y', '37px')
      .attr('x', '50px');

    d3.select('#map')
      .classed('svgX', true)
      .attr('viewBox', '0  0 500 500')
      .append('g')
      .attr('align-baseline', 'center')
      .selectAll('path')
      .data(sentinelValue.features)
      .enter()
      .append('path')
      .attr('d', path)
      .attr('id', function (d, i): any { return 'testId' + i; })
      .style('fill', feature => {
        if (type === 'LAI') {
          return this.fillColor(feature['properties'].lai);
        } else if (type === 'NDVI') {
          return this.fillNdviColor(feature['properties'].ndvi);
        }
      })
      .style('stroke-width', 1)
      .style('stroke', 'lightgrey')
      .select('g')
      .data([boundary])
      .enter()
      .append('path')
      .attr('d', path)
      .style('stroke-width', 1)
      .style('stroke', 'darkgrey')
      .style('fill', 'rgba(0,0,0,0)');

    let size;
    let detailedLegendItems;

    if (type === 'LAI') {
      detailedLegendItems = this.legendItems;
      size = 500 / this.legendItems.length;
    } else if (type === 'NDVI') {
      detailedLegendItems = this.ndviLegendItems;
      size = 500 / this.ndviLegendItems.length;
    }

    function mousemove(): void {
      d3.select('#tooltipContainer').style('left', '395px');

      if (d3.mouse(this)[1] >= 450) {
        d3.select('#tooltipContainer').style('top', '450px');
      } else if (d3.mouse(this)[1] <= 7) {
        d3.select('#tooltipContainer').style('top', '7px');
      } else {
        d3.select('#tooltipContainer').style('top', d3.mouse(this)[1] + 'px');
      }
    }

    const rows = d3.select('#legend').selectAll('mydots')
      .data(detailedLegendItems)
      .enter().append('rect')
      .attr('id', function (d, i): any { return 'id' + i; })
      .on('mouseover', function (d): any {
        tooltip.attr('display', 'block');

        tooltiptext.attr('display', 'block');
        tooltiptext2.attr('display', 'block');

        adjustTooltipText(d, type);

        if (type === 'LAI') {
          if (self.detailedLaiVal.features.some(feature => self.convertLaiPropertyToLegendString(feature.properties.lai) === d)) {
            d3.select(this).style('cursor', 'pointer');
          } else {
            d3.select(this).style('cursor', 'default');
            d3.select(this).style('pointer-events', 'none');
          }
        } else if (type === 'NDVI') {
          if (self.detailedLaiVal.features.some(feature => self.convertNdviPropertyToLegendString(feature.properties.ndvi) === d)) {
            d3.select(this).style('cursor', 'pointer');
          } else {
            d3.select(this).style('cursor', 'default');
            d3.select(this).style('pointer-events', 'none');
          }
        }
      })
      .on('mousemove', mousemove)
      .on('mouseout', hideTooltip)
      .on('click', onLegendClick)
      .attr('x', 0)
      .attr('y', function (d, i): number {
        return i * size;
      })
      .attr('width', '100%')
      .attr('height', size)
      .style('fill', d => {
        return this.fillColorLegend(d, type);
      });

    d3.select('#legend').selectAll('myLabels')
      .data(detailedLegendItems)
      .enter()
      .append('text')
      .on('mouseover', function (d): any {
        adjustTooltipText(d, type);

        tooltip.attr('display', 'block');
        tooltiptext.attr('display', 'block');
        tooltiptext2.attr('display', 'block');

        if (type === 'LAI') {
          if (self.detailedLaiVal.features.some(feature => self.convertLaiPropertyToLegendString(feature.properties.lai) === d)) {
            d3.select(this).style('cursor', 'pointer');
          } else {
            d3.select(this).style('cursor', 'default');
            d3.select(this).style('pointer-events', 'none');
          }
        } else if (type === 'NDVI') {
          if (self.detailedLaiVal.features.some(feature => self.convertNdviPropertyToLegendString(feature.properties.ndvi) === d)) {
            d3.select(this).style('cursor', 'pointer');
          } else {
            d3.select(this).style('cursor', 'default');
            d3.select(this).style('pointer-events', 'none');
          }
        }
      })
      .on('mousemove', function (d): any {
        d3.select('#tooltipContainer').style('left', '395px');

      if (d3.event.layerY >= 450) {
        d3.select('#tooltipContainer').style('top', '450px');
      } else if (d3.event.layerY <= 7) {
        d3.select('#tooltipContainer').style('top', '7px');
      } else {
        d3.select('#tooltipContainer').style('top', d3.mouse(this)[1] + 'px');
      }
      })
      .on('mouseout', hideTooltip)
      .on('click', onLegendClick)
      .attr('font-weight', 'bold')
      .attr('x', '50%')
      .attr('y', function (d, i): number {
        return i * size + size / 2;
      })
      .style('fill', 'black')
      .text(function (d): any {
        if (type === 'LAI') {
          if (d !== 7) {
            return d;
          } else {
            return d + '<';
          }
        } else if (type === 'NDVI') {
          return d;
        }
      })
      .attr('text-anchor', 'middle')
      .style('alignment-baseline', 'middle');

    function adjustTooltipText(d, ValueType): void {
      let foundValue = false;
      if (ValueType === 'LAI') {
        const startValue: number = self.allLaiItems.filter(item => item.item === d)[0].startValue;
        const endValue: number = self.allLaiItems.filter(item => item.item === d)[0].endValue;

        let filtered: any[];
        if (d === self.allLaiItems[0].item) {
          filtered = self.featureAreas.filter(area => area.lai > startValue);
        } else if (!endValue) {
          filtered = self.featureAreas.filter(area => area.lai === startValue);
        } else {
          filtered = self.featureAreas.filter(area => area.lai > startValue && area.lai <= endValue);
        }

        if (filtered.length > 0) {
          foundValue = true;
          showTooltip(getAreaPercetageSums(filtered, 'LAI'));
        }
      } else if (ValueType === 'NDVI') {
        const startValue: number = self.allNdviItems.filter(item => item.item === d)[0].startValue;
        const endValue: number = self.allNdviItems.filter(item => item.item === d)[0].endValue;

        let filtered: any[];
        if (!startValue) {
          filtered = self.featureAreas.filter(area => area.ndvi < endValue);
        } else {
          filtered = self.featureAreas.filter( area =>
            area.ndvi > startValue && area.ndvi <= endValue
          );
        }

        if (filtered.length > 0) {
          foundValue = true;
          showTooltip(getAreaPercetageSums(filtered, 'NDVI'));
        }
      }

      if (!foundValue) {
        showTooltip({area: 0, percentage: 0});
      }
    }

    function getAreaPercetageSums(filteredArray: any[], valueType: string): Object {
      let betweenValues;
      let sums;

      if (valueType === 'LAI') {
        betweenValues = filteredArray.map(({lai, ...keepAttrs}) => keepAttrs);
      } else if (valueType === 'NDVI') {
        betweenValues = filteredArray.map(({ndvi, ...keepAttrs}) => keepAttrs);
      }

      if (betweenValues.length > 0) {
        sums = betweenValues.reduce(function(previousValue, currentValue): any {
          return {
            area: previousValue.area + currentValue.area,
            percentage: previousValue.percentage + currentValue.percentage
          };
        });
      }

      if (sums) {
        return sums;
      }
    }

    function showTooltip(feature): void {
      tooltiptext.text(feature.percentage.toFixed(2).toString() + '%');
      tooltiptext2.text(feature.area.toFixed(2).toString() + ' ha');

      tooltip.attr('display', 'block');
      tooltiptext.attr('display', 'block');
      tooltiptext2.attr('display', 'block');
      d3.select('#tooltipContainer').style('left', '395px');
    }

    function hideTooltip(): void {
      tooltip.attr('display', 'none');
      tooltiptext.attr('display', 'none');
      tooltiptext2.attr('display', 'none');
      d3.select('#tooltipContainer').style('left', '-100px');
    }

    function onLegendClick(d, i): void {
      self.componentMethodCallSource.next(d);

      if (
          // tslint:disable-next-line:max-line-length
          ( self.detailedLaiVal.features.some(feature => self.convertLaiPropertyToLegendString(feature.properties.lai) === d) && type === 'LAI') ||
          // tslint:disable-next-line:max-line-length
            self.detailedLaiVal.features.some(feature => self.convertNdviPropertyToLegendString(feature.properties.ndvi) === d) && type === 'NDVI'
        ) {
        const selectedFeatures: any[] = [];

        self.detailedLaiVal.features.forEach((feature, indexx) => {
          if (type === 'LAI') {
            if (self.convertLaiPropertyToLegendString(feature.properties.lai) === d) {
              selectedFeatures.push({feature: feature, index: indexx});
            }
          } else if (type === 'NDVI') {
            if (self.convertNdviPropertyToLegendString(feature.properties.ndvi) === d) {
              selectedFeatures.push({feature: feature, index: indexx});
            }
          }
        });

        if (self.previousSelectedLegendItem) {
          if (self.previousSelectedLegendItem === '#id' + i) {
            sameLegendItemClicked(i, type);
          } else {
            newLegendItemClicked(i, selectedFeatures, type);
          }
        } else {
          firstLegendItemClicked(i, selectedFeatures, type);
        }
        self.previousSelectedLegendItem = '#id' + i;
        self.previousSelectedFeatures = selectedFeatures;
      }
    }

    function sameLegendItemClicked(i, valueType): void {
      if (d3.select('#id' + i).style('stroke') === 'black') {
        d3.select(self.previousSelectedLegendItem).style('stroke', 'none');

        if (valueType === 'LAI') {
          d3.select(self.previousSelectedLegendItem).style('fill', self.fillColor(self.previousSelectedFeatures[0].feature.properties.lai));

          self.previousSelectedFeatures.forEach(feature => {
            d3.select('#testId' + feature.index).style('fill', self.fillColor(feature.feature.properties.lai));
          });
        } else if (valueType === 'NDVI') {
          // tslint:disable-next-line:max-line-length
          d3.select(self.previousSelectedLegendItem).style('fill', self.fillNdviColor(self.previousSelectedFeatures[0].feature.properties.ndvi));

          self.previousSelectedFeatures.forEach(feature => {
            d3.select('#testId' + feature.index).style('fill', self.fillNdviColor(feature.feature.properties.ndvi));
          });
        }
      } else {
        d3.select(self.previousSelectedLegendItem).style('stroke', 'black');

        if (valueType === 'LAI') {
          d3.select(self.previousSelectedLegendItem).style('fill', 'blue');
        } else if (valueType === 'NDVI') {
          d3.select(self.previousSelectedLegendItem).style('fill', 'blue');
        }

        self.previousSelectedFeatures.forEach((feature) => {
          if (valueType === 'LAI') {
            d3.select('#testId' + feature.index).style('fill', 'blue');
          } else if (valueType === 'NDVI') {
            d3.select('#testId' + feature.index).style('fill', 'blue');
          }
        });
      }
    }

    function newLegendItemClicked(i, selectedFeatures, valueType): void {
      d3.select(self.previousSelectedLegendItem).style('stroke', 'none');

      if (valueType === 'LAI') {
        d3.select(self.previousSelectedLegendItem).style('fill', self.fillColor(self.previousSelectedFeatures[0].feature.properties.lai));
      } else if (valueType === 'NDVI') {
        // tslint:disable-next-line:max-line-length
        d3.select(self.previousSelectedLegendItem).style('fill', self.fillNdviColor(self.previousSelectedFeatures[0].feature.properties.ndvi));
      }

      d3.select('#id' + i).style('stroke', 'black');

      if (valueType === 'LAI') {
        d3.select('#id' + i).style('fill', 'blue');

        selectedFeatures.forEach((feature) => {
          d3.select('#testId' + feature.index).style('fill', 'blue');
        });
      } else if (valueType === 'NDVI') {
        d3.select('#id' + i).style('fill', 'blue');

        selectedFeatures.forEach((feature) => {
          d3.select('#testId' + feature.index).style('fill', 'blue');
        });
      }

      if (valueType === 'LAI') {
        self.previousSelectedFeatures.forEach(feature => {
          d3.select('#testId' + feature.index).style('fill', self.fillColor(feature.feature.properties.lai));
        });
      } else if (valueType === 'NDVI') {
        self.previousSelectedFeatures.forEach(feature => {
          d3.select('#testId' + feature.index).style('fill', self.fillNdviColor(feature.feature.properties.ndvi));
        });
      }
    }

    function firstLegendItemClicked(i, selectedFeatures: any[], valueType): void {
      d3.select('#id' + i).style('stroke', 'black');

      if (valueType === 'LAI') {
        d3.select('#id' + i).style('fill', 'blue');

        selectedFeatures.forEach((feature) => {
          d3.select('#testId' + feature.index).style('fill', 'blue');
        });
      } else if (valueType === 'NDVI') {
        d3.select('#id' + i).style('fill', 'blue');

        selectedFeatures.forEach((feature) => {
          d3.select('#testId' + feature.index).style('fill', 'blue');
        });
      }
    }
  }

  public renderNdviLegend(index, width, height): void {
    height += 25;

    d3.select('#legendSVG' + index).selectAll('*').remove();
    const svg = d3.select('#legendSVG' + index).append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('y', 1);

    const size = height / this.ndviLegendItems.length;

    const myDots = svg.selectAll('mydots')
      .data( this.ndviLegendItems)
      .enter()
      .append('rect')
      .attr('x', '32.5%')
      .attr('y', function (d, i): number { return i * size; })
      .attr('width', '35%')
      .attr('height', size)
      .style('fill', d => {
        return this.fillColorLegend(d, 'NDVI');
      });

    const labels = svg.selectAll('mylabels')
      .data(this.ndviLegendItems)
      .enter()
      .append('text')
      .attr('font-weight', 'bold')
      .attr('x', '50%')
      .attr('y', function (d, i): number { return i * size + size / 2; })
      .style('fill', 'black')
      .text(function (d): any {
        return d;
      })
      .attr('font-size', '0.6vw')
      .attr('text-anchor', 'middle')
      .style('alignment-baseline', 'middle');
  }

  public renderLegend(index, width, height): void {
    height += 25;

    d3.select('#legendSVG' + index)
      .selectAll('*')
      .remove();
    const svg = d3
      .select('#legendSVG' + index)
      .append('svg')
      .attr('width', width)
      .attr('height', height);

    const size = height / this.legendItems.length;

    svg
      .selectAll('mydots')
      .data(this.legendItems)
      .enter()
      .append('rect')
      .attr('x', '32.5%')
      .attr('y', function (d, i): number {
        return i * size;
      })
      .attr('width', '35%')
      .attr('height', size)
      .style('fill', (d) => {
        return this.fillColorLegend(d, 'LAI');
      });

    svg
      .selectAll('mylabels')
      .data(this.legendItems)
      .enter()
      .append('text')
      .attr('font-weight', 'bold')
      .attr('x', '50%')
      .attr('y', function (d, i): number {
        return i * size + size / 2;
      })
      .style('fill', 'black')
      .text(function (d): any {
        return d;
      })
      .attr('text-anchor', 'middle')
      .style('alignment-baseline', 'middle');
  }

  public renderLai(
    item: any,
    boundary,
    index,
    width,
    height
  ): void {
    const laiVal = item;

    const path = d3
      .geoPath()
      .projection(d3.geoMercator().fitSize([width, height], laiVal));

    d3.select('#render' + index)
      .selectAll('*')
      .remove();

    d3.select('#render' + index)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .selectAll('path')
      .data(laiVal.features)
      .enter()
      .append('path')
      .attr('d', path)
      .style('fill', (feature) => {
        return this.fillColor(feature['properties'].lai);
      })
      .style('stroke-width', 1)
      .style('stroke', 'lightgrey')
      .select('g')
      .data([boundary])
      .enter()
      .append('path')
      .attr('d', path)
      .style('stroke-width', 1)
      .style('stroke', 'darkgrey')
      .style('fill', 'rgba(0,0,0,0)');
  }

  public renderNdvi(item: any, boundary, index, width, height): void {
    const ndviVal = item;

    const path = d3.geoPath().projection(d3.geoMercator().fitSize([width, height], ndviVal));

    d3.select('#render' + index).selectAll('*').remove();

    d3.select('#render' + index).append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .selectAll('path')
      .data(ndviVal.features)
      .enter()
      .append('path')
      .attr('d', path)
      .style('fill', feature => {
        return this.fillNdviColor(feature['properties'].ndvi);
      })
      .style('stroke-width', 1)
      .style('stroke', 'lightgrey')
      .select('g')
      .data([boundary])
      .enter()
      .append('path')
      .attr('d', path)
      .style('stroke-width', 1)
      .style('stroke', 'darkgrey')
      .style('fill', 'rgba(0,0,0,0)');
  }

  public renderBoundary(boundary, index, width, height): void {
    const path = d3
      .geoPath()
      .projection(d3.geoMercator().fitSize([width, height], boundary));

    d3.select('#render' + index)
      .selectAll('*')
      .remove();
    d3.select('#render' + index)
      .select('svg')
      .append('g')
      .selectAll('path')
      .data([boundary])
      .enter()
      .append('path')
      .attr('d', path)
      .style('fill', (feature) => {
        const t = Math.min(1, feature.properties.lai / maxLai);
        return d3.interpolateRdYlGn(t);
      })
      .style('stroke-width', 1)
      .style('stroke', 'darkgrey');
  }

  private convertLaiPropertyToLegendString(featureLaiProperty: number): string {
    let legendString: string;
    this.allLaiItems.forEach((item, index) => {
      if (index === 0) {
        legendString = item.item;
      }
      if (!item.endValue && index !== 0) {
        if ( featureLaiProperty === item.startValue) {
          legendString = item.item;
        }
      } else {
        if (featureLaiProperty >  item.startValue && featureLaiProperty <= item.endValue) {
          legendString = item.item;
        }
      }
    });

    return legendString;
  }

  private convertNdviPropertyToLegendString(featureNdviProperty: number): string {
    let legendString: string;

    if (featureNdviProperty < -0.2) {
      legendString = this.allNdviItems[this.allNdviItems.length].item;
    }

    const filteredItems = this.allNdviItems.filter((item) => featureNdviProperty > item.startValue && featureNdviProperty <= item.endValue);

    if (filteredItems.length > 0) {
      legendString = filteredItems[0].item;
    }

    return legendString;
  }

  public addNdviChart(charts: any[]): any[] {
    charts.push(new Chart({
      ...this.DEFAULT_CHART_OPTIONS,
      legend: {
        enabled: true
      },
      yAxis: [
        {
          title: {
            text: 'NDVI Viewer'
          },
          labels: {
            format: '{value:.1f}'
        }
        }
      ],
      xAxis: {
        plotLines: [],
        type: 'datetime'
      },
      series: [
        {
          type: 'line',
          name: SerieNames.MAX_NDVI,
          dashStyle: 'LongDash',
          data: [],
          color: 'grey',
          lineWidth: 1,
          pointPadding: 0,
          groupPadding: 0,
          borderWidth: 0,
          shadow: false,
          visible: false,
          legendIndex: 1,
          marker: {
            symbol: 'circle'
          },
          tooltip: {
            valueDecimals: 2
          }
        } as any,
        {
          type: 'line',
          name: SerieNames.MIN_NDVI,
          dashStyle: 'LongDash',
          data: [],
          color: 'grey',
          lineWidth: 1,
          pointPadding: 0,
          groupPadding: 0,
          borderWidth: 0,
          visible: false,
          legendIndex: 2,
          shadow: false,
          marker: {
            symbol: 'circle'
          },
          tooltip: {
            valueDecimals: 2
          }
        } as any,
        {
          type: 'line',
          name: SerieNames.MEAN_NDVI,
          data: [],
          color: '#ECECEC',
          lineWidth: 1,
          legendIndex: 0,
          pointPadding: 0,
          groupPadding: 0,
          borderWidth: 0,
          shadow: false,
          marker: {
            fillColor: 'darkgreen',
            symbol: 'circle',
            enabledThreshold: 0
          },
          tooltip: {
            valueDecimals: 2
          }
        },
        {
          showInLegend: false,
          visible: false,
          name: SerieNames.CLOUD_MARKERS,
          data: [],
          color: 'orange',
          lineWidth: 0,
          lineWidthPlus: 0,
          pointPadding: 0,
          groupPadding: 0,
          borderWidth: 0,
          legendIndex: 3,
          shadow: false,
          marker: {
            enabled: true,
            symbol: 'url(https://www.3clogic.com/hubfs/deplomentoptions_totalcloudicon.svg)',
            height: 16,
            width: 16
          },
          tooltip: {
            valueDecimals: 2,
            pointFormat: ''
          },
          states: {
            hover: {
                lineWidthPlus: 0
            }
        }
        } as any,
      ],
    }));

    return charts;
  }

  public addLaiChart(charts: any[]): any[] {
    charts.push(
      new Chart({
        ...this.DEFAULT_CHART_OPTIONS,
        legend: {
          enabled: true,
        },
        yAxis: [
          {
            title: {
              text: 'Biomass Viewer',
            },
            labels: {
              format: '{value:.1f}'
            }
          },
        ],
        xAxis: {
          plotLines: [],
          type: 'datetime',
        },
        series: [
          {
            type: 'line',
            name: SerieNames.MAX_BIOMASS,
            dashStyle: 'LongDash',
            data: [],
            color: 'grey',
            lineWidth: 1,
            pointPadding: 0,
            groupPadding: 0,
            borderWidth: 0,
            shadow: false,
            visible: false,
            legendIndex: 1,
            marker: {
              symbol: 'circle',
            },
            tooltip: {
              valueDecimals: 1,
            },
          } as any,
          {
            type: 'line',
            name: SerieNames.MIN_BIOMASS,
            dashStyle: 'LongDash',
            data: [],
            color: 'grey',
            lineWidth: 1,
            pointPadding: 0,
            groupPadding: 0,
            borderWidth: 0,
            legendIndex: 2,
            visible: false,
            shadow: false,
            marker: {
              symbol: 'circle',
            },
            tooltip: {
              valueDecimals: 1,
            },
          } as any,
          {
            type: 'line',
            name: SerieNames.MEAN_BIOMASS,
            data: [],
            color: '#ECECEC',
            lineWidth: 1.5,
            pointPadding: 0,
            legendIndex: 0,
            groupPadding: 0,
            borderWidth: 0,
            shadow: false,
            marker: {
              fillColor: 'darkgreen',
              symbol: 'circle',
              enabledThreshold: 0
            },
            tooltip: {
              valueDecimals: 1,
            },
          } as any,
          {
            showInLegend: false,
            visible: false,
            name: SerieNames.CLOUD_MARKERS,
            data: [],
            color: 'orange',
            lineWidth: 0,
            lineWidthPlus: 0,
            pointPadding: 0,
            groupPadding: 0,
            borderWidth: 0,
            legendIndex: 3,
            shadow: false,
            marker: {
              enabled: true,
              symbol:
                'url(../../../../../assets/img/cloudicon.svg)',
              height: 16,
              width: 16,
            },
            tooltip: {
              valueDecimals: 1,
              pointFormat: '',
            },
            states: {
              hover: {
                lineWidthPlus: 0,
              },
            },
          } as any,
        ],
      })
    );

    return charts;
  }
}
