<form [formGroup]="form"
      class="advanced-options col-md-6">
  <h4 class="advanced-options__header">{{ 'Advanced options' | translate }}</h4>

  <ng-container>
    <section *ngIf="deviceGroup?.frost && frostSensorOptions.length !== 0">
      <section class="advanced-options__control flex-row-direction space-between">
        <label class="advanced-options__label">{{ 'Frost monitoring' | translate}}</label>
        <label class="toggle">
          <input type="checkbox" [checked]="frostEnabled" (change)="changeFrostMonitoring($event)" [formControl]="frostMonitoringEnable">
          <span class="slider"></span>
          <span class="labels" data-on="Enabled" data-off="Disabled"></span>
        </label>
      </section>
      <section *ngIf="frostEnabled">
        <section class="alert alert-info advanced-options__alert">
          {{ 'Please select the sensor from the drop-down menu to enable short data transfer interval only during frost ' +
             'conditions to save power on device.' | translate }}
        </section>
        <section class="advanced-options__control">
          <app-form-select
          [formControl]="frostMonitoringSensorChannel"
          [options]="frostSensorOptions"
          (change)="changeFrostSensor($event)"
          [type]="'border'"
          [size]="'small'"
          ></app-form-select>
        </section>
      </section>
      <section class="alert alert-info advanced-options__alert">
          {{ 'In case of frost monitoring "Enabled" the fixed interval will be frozen at the chosen time of ' +
             'selection.' | translate }}
      </section>
    </section>

    <div *ngIf="deviceGroup?.rain" class="advanced-options__control flex-row-direction space-between">
      <label class="advanced-options__label">{{ 'Rain monitoring' | translate}}</label>
      <label class="toggle">
        <input type="checkbox" [checked]="rainMonitoring" (change)="changeRainMonitoring($event)" [formControl]="rainMonitoringControl">
        <span class="slider"></span>
        <span class="labels" data-on="Enabled" data-off="Disabled"></span>
      </label>
    </div>

    <div>
      <div *ngIf="deviceGroup?.network" class="advanced-options__control networkContainer">
        <label class="advanced-options__label">{{ 'Network registration timeout' | translate}}</label>
        <app-form-select [formControl]="networkRegistrationTimeoutControl"
                         [options]="networkRegistrationOptions"
                         [type]="'border'"
                         [size]="'small'"
        ></app-form-select>
      </div>
      <div class="alert alert-warning advanced-options__alert" *ngIf="networkRegistrationTimeoutControl.value > 240">
        {{ 'Long network registration timeout can significantly impact the product battery lifetime.' | translate }}
      </div>
    </div>

    <div class="advanced-options__control" *ngIf="deviceGroup?.stationActivity">
      <label class="advanced-options__label">{{ 'Station activity' | translate}}</label>
      <app-form-select [formControl]="stationActivityControl"
                       [options]="activityOptions"
                       [type]="'border'"
                       [size]="'small'"
      ></app-form-select>
    </div>
  </ng-container>
</form>
