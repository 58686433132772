<ng-container>
  <app-form-layout>
    <app-user-licenses-card-header [title]="'License details'"></app-user-licenses-card-header>

    <p class="license-expiry-info">
      <strong>{{ 'From' | translate }}:</strong>
      <span>{{ selectedLicense.from | date:'yyyy-MM-dd' }}</span>

      <strong>{{ 'To' | translate }}:</strong>
      <span>{{ selectedLicense.to | date:'yyyy-MM-dd' }}</span>
    </p>

    <div class="row license-details-info">
      <div class="col-sm-12 col-md-6">
        <h5>{{ 'Products' | translate }}</h5>

        <ul class="list-group scrollable-list-group">
          <li class="list-group-item" *ngFor="let product of selectedLicense.products">
            <p *ngIf="product.license_group === 'FarmView'" class="noMargin">{{ product.name | translate }}</p>
            <p *ngIf="product.license_group !== 'FarmView'" class="noMargin">{{ product.title | translate }}</p>
          </li>
        </ul>
      </div>

      <div class="col-sm-12 col-md-6">
        <ng-container *ngIf="hasFarmViewLicense(); else showDetectionsTitle">
          <h5>{{ 'Cropzones' | translate }}</h5>
        </ng-container>

        <ng-template #showDetectionsTitle>
          <ng-container *ngIf="hasIScoutMobileLicense(); else showStationsTitle">
            <h5>{{ 'Detections' | translate }}</h5>
          </ng-container>

          <ng-template #showStationsTitle>
            <h5 >{{ 'Stations' | translate }}</h5>
          </ng-template>
        </ng-template>

        <ul class="list-group scrollable-list-group">
          <li class="list-group-item" *ngFor="let station of selectedLicense.stations">
            {{ station }}
          </li>
        </ul>
      </div>
    </div>

    <div class="back-button-area">
      <app-custom-button
        [content]="'Back'"
        [color]="'red'"
        (click)="onUnselectClick()"
      ></app-custom-button>
    </div>
  </app-form-layout>
</ng-container>
