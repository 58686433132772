import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { IState } from '../models/xarvio';
import { ActionTypes } from '../actions/xarvio';

export const initialState: IState = {
  assets: {},
  sharingKey: null
};

export function reducer(state: IState = initialState, action: IAction): IState {
  switch (action.type) {
    case ActionTypes.SET_ASSETS:
      const assets = action.payload.reduce((result, item) => ({...result, [item.station_name]: item}), {});
      return { ...state, assets };
    case ActionTypes.SET_SHARING_KEY:
      return { ...state, sharingKey: action.payload };
    case ActionTypes.DISABLE_SYNC:
      return initialState;
    default:
      return state;
  }
}
