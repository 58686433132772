<div class="timezone-and-location-modal">
  <p>
    {{ 'If your device has a built-in GPS, location and time zone will be acquired automatically. Otherwise, you can ' +
    'define the location manually in three different ways:' | translate }}
  </p>
  <ul>
    <li>{{ 'Enter location (by address) into “location search” box and then click “GO!”.' | translate }}</li>
    <li>{{ 'Drag the marker on the map to your location.' | translate }}</li>
    <li>{{ 'Enter coordinates of the device location to “Longitude, Latitude, Elevation” boxes.' | translate }}</li>
  </ul>
  <p>
    {{ 'The timezone is automatically set if you drag the marker on the map or if you find the location from the ' +
    '“Location search” input. To manually enter the time zone, select the correct time zone from the drop-down list.' |
    translate }}
  </p>
  <p>
    {{ 'When you finish the configuration, do not forget to save the new settings (click on “SAVE LOCATION” button).' |
    translate }}
  </p>
</div>
