<div class="dropdown-container"
     (click)="toggleOpen()"
     appOutClick
     *ngIf="currentContent"
     (outClick)="close()">
  <app-custom-button [caret]="true"
                     [disabled]="disabled"
                     [width]="width"
                     [content]="staticContent || currentContent"
                     [color]="color">
  </app-custom-button>
    <div class="custom-dropdown" *ngIf="isOpen">
      <div class="input-container" *ngIf="isOpen && searchable && options?.length >= 10" (click)="stopPropagation($event)">
        <input
          type="text"
          (input)="filterOptions()"
          (click)="stopPropagation($event)"
          [(ngModel)]="searchTerm"
          class="form-control"
          [attr.placeholder]="placeholder | translate"
          (keydown.escape)="resetDropdown()">
        <i class="zmdi zmdi-search"></i>
      </div>
      <div class="options-container">
        <p *ngFor="let option of filteredOptions" (click)="onChange(option)">
          <span class="fa fa-check check"
                *ngIf="option.value === currentValue"></span>
          {{ option.content | translate }}
        </p>
      </div>
    </div>
</div>
