<div [formGroup]="form"
     class="remove-form">
  <div class="remove-form__fields">
    <div class="remove-form__fields__item">
        <h5 class="remove-form__fields__item__label">{{ 'Farm name' | translate }}</h5>
      <input
      [formControl]="farm"
      [typeahead]="farms$ | async"
      [typeaheadScrollable]="true"
      [typeaheadOptionsInScrollableView]="5"
      typeaheadOptionField="name"
      (typeaheadOnSelect)="onSelect($event)"
      class="remove-form__fields__item__input__padding"
      />
    </div>
    <div class="remove-form__fields__item">
      <h5 class="remove-form__fields__item__label">{{ 'Field name' | translate }}</h5>
      <input
      [formControl]="field"
      [typeahead]="fields$ | async"
      [typeaheadScrollable]="true"
      [typeaheadOptionsInScrollableView]="5"
      typeaheadOptionField="name"
      (typeaheadOnSelect)="onSelectField($event)"
      class="remove-form__fields__item__input__padding"
      />
    </div>
    <div class="remove-form__fields__item">
        <h5 class="remove-form__fields__item__label">{{ 'Cropzone' | translate }}</h5>
        <input
        [formControl]="cropzone"
        [typeahead]="cropzones$ | async"
        [typeaheadScrollable]="true"
        [typeaheadOptionsInScrollableView]="5"
        typeaheadOptionField="name"
        (typeaheadOnSelect)="onSelectCropzone($event)"
        class="remove-form__fields__item__input__padding"/>
    </div>
  </div>
  <div class="remove-form__buttons">
    <app-custom-button [content]="'Cancel'"
                       (click)="close()"></app-custom-button>
    <app-custom-button [color]="submitColor"
                       [disabled]="form.invalid"
                       (click)="submitEmit()"
                       [content]="submitContent"></app-custom-button>
  </div>
</div>
