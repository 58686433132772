<app-tree [tree$]="tree$"></app-tree>
<div class="body-view-content" [@placeContent]="state$ | async">
  <i (click)="openHelpModal()" class="zmdi zmdi-help help-icon"></i>
  <app-cropzone-page-header pageName="Calendar"></app-cropzone-page-header>
  <section class="alert alert-info flex-row-direction">
    <i class="icon-fc-rain-corrector" style="font-size: 20px;"></i>
    <section class="link-text" (click)="routeRainCorrector()">
      {{ 'Manually correct precipitation data.' | translate }}</section>
    <p>&nbsp;{{ 'A tool to correct data from your current rain source.' | translate }}</p>
  </section>
  <app-irrimet-calendar
  *ngIf="unitSystem"
    [unitSystem]="unitSystem"
    (modal)="openModal($event)"
    (editModal)="editModal($event)"
    (deleteRecurringModal)="deleteRecurringModal()"
    (deleteModal)="deleteModal()"
    (openEventListModal)="openEventListModal($event)">
  </app-irrimet-calendar>
</div>

<app-modal [modalId]="ADD_IRRIGATION_EVENT">
  <!-- headers -->
  <div>
    <!-- add irrigation header with option to add rain event -->
    <div *ngIf="newEventOrEditEvent && cropzone.manualRainGauge" class="modal-header">
      <div>
        <div [ngClass]="!rainDivOpen ? 'active' : 'notActive'"  (click)="openRainDiv(false)">
          <div>
            <h2>{{ 'Add new irrigation event' | translate }}</h2>
          </div>
        </div>
      </div>
      <div>
        <div [ngClass]="rainDivOpen ? 'active' : 'notActive'" (click)="openRainDiv(true)">
          <div>
            <h2>{{ 'Add new rain event' | translate }}</h2>
          </div>
        </div>
      </div>
    </div>

    <!-- add irrigation header without option to add rain event-->
    <div *ngIf="newEventOrEditEvent && !cropzone.manualRainGauge" class="modal-header-only">
      <div class="withoutManual">
        <h2>{{ 'Add new irrigation event' | translate }}</h2>
      </div>
    </div>

    <!-- edit irrigation header -->
    <div style="width: 100%;" *ngIf="!newEventOrEditEvent && irrigationOrRainEvent">
      <div class="modal-header editHeader">
        <div style="flex: 100%;">
          <h2>{{ 'Edit irrigation event' | translate }}</h2>
        </div>
      </div>
    </div>

    <!-- edit rain header -->
    <div style="width: 100%;" *ngIf="!newEventOrEditEvent && !irrigationOrRainEvent">
      <div class="modal-header editHeader">
        <div style="flex: 100%;">
          <h2>{{ 'Edit rain event' | translate }}</h2>
        </div>
      </div>
    </div>

    <!-- close button -->
    <div class="closeButton">
      <button type="button" class="close" aria-hidden="true" (click)="closeModal()"><i class="zmdi zmdi-close"></i></button>
    </div>
  </div>

  <div *ngIf="rainDivOpen" [formGroup]="rainForm">
    <div class="paddings">
      <div>
        <h5 class="inlineBlock">{{ 'Description (min. 3 letters)' | translate }}</h5>
        <button *ngIf="newEventOrEditEvent === false && editSingleEvent === true" (click)="deleteModal()" class="inlineBlock floatR">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
        <app-form-input
          [formControl]="rain_event_name"
          type="border"
          size="small"
          [isDisabled]="newEventOrEditEvent === false && editSingleEvent === false">
        </app-form-input>
      </div>
      <div class="row">
      </div>
      <div class="row">

        <div class="col-md-4 event-date-string">
          <h5 class="inlineBlock width45NoMarginB">{{ 'Date' | translate }}</h5>
          <p class="">{{ dateString }}</p>
        </div>

        <div class="col-md-4" id="event-time">
            <ngb-timepicker
              [seconds]="false"
              minuteStep="30"
              [formControl]="fromHourRain"
              class="font12">
            </ngb-timepicker>
            <p class="inlineBlock" style="margin-left: 8%">{{ "hour" | translate }}</p>
            <p class="inlineBlock" style="margin-left: 20%">{{ "min" | translate }}</p>
        </div>

        <div class="col-md-4 event-duration">
          <h5 *ngIf="unitSystem === 'metric'">{{ 'Total amount' | translate }} {{ '(mm)' | translate }} </h5>
          <h5 *ngIf="unitSystem === 'imperial'">{{ 'Total amount' | translate }} {{ '(in)' | translate }}</h5>
          <app-form-input [formControl]="amountRain" size="small" type="border">
          </app-form-input>
        </div>

      </div>
    </div>

    <div class="row paddingLR15">
      <app-custom-button
        *ngIf="newEventOrEditEvent === true"
        class="modalButton paddingB15"
        content="Save event"
        [disabled]="rainForm.invalid"
        color="green"
        (click)="submitRain()">
      </app-custom-button>
      <app-custom-button
        *ngIf="newEventOrEditEvent === false"
        class="modalButton paddingB15"
        content="Save event"
        [disabled]="rainForm.invalid"
        color="green"
        (click)="editRain()">
      </app-custom-button>
      <app-custom-button
        class="modalButton paddingB15"
        content="Back"
        (click)="closeAddModal()">
      </app-custom-button>
    </div>
  </div>

  <div [formGroup]="irrigationForm" *ngIf="!rainDivOpen">
    <!-- Non recurring event -->
    <div class="paddings" *ngIf="(newEventOrEditEvent === false || newEventOrEditEvent === true)">

      <div>
        <h5 class="inlineBlock">{{ 'Description (min. 3 letters)' | translate }}</h5>
        <button *ngIf="newEventOrEditEvent === false && editSingleEvent === true" (click)="deleteModal()" class="inlineBlock floatR">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
        <button *ngIf="newEventOrEditEvent === false && editSingleEvent === false" (click)="deleteRecurringModal()" class="inlineBlock floatR">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
        <app-form-input
          [formControl]="irrigation_name"
          type="border"
          size="small"
          [isDisabled]="newEventOrEditEvent === false && editSingleEvent === false">
        </app-form-input>
      </div>
      <div class="row">
        <div class="col-md-4">
          <h5>{{ 'Type' | translate }}</h5>
          <app-form-select
            type="border"
            [options]="irrigationTypes"
            size="small"
            [formControl]="irrigationType"
            [isDisabled]="newEventOrEditEvent === false && editSingleEvent === false">
          </app-form-select>
        </div>
        <div class="col-md-4">
          <h5 class="inlineBlock marginR10">{{ 'Efficacy' | translate }}</h5>

          <h5 class="inlineBlock"
              *ngIf="selectedIrrigationType === 'FLOOD' || selectedIrrigationType === 'FURROW'">
            (0.3 - 0.8)
          </h5>
          <h5 class="inlineBlock"
              *ngIf="selectedIrrigationType === 'PIVOT' || selectedIrrigationType === 'SPRINKLER'">
            (0.6 - 0.9)
          </h5>
          <h5 class="inlineBlock"
              *ngIf="selectedIrrigationType === 'MICRO_SPRINKLER' || selectedIrrigationType === 'DRIP'">
            (0.7 -
            0.95)</h5>
          <h5 class="inlineBlock" *ngIf="selectedIrrigationType === 'SUBSURFACE_DRIP'">(0.8 - 1)
          </h5>

          <app-form-input [formControl]="efficacy"
                          size="small"
                          type="border"
                          [isDisabled]="newEventOrEditEvent === false && editSingleEvent === false">
          </app-form-input>
        </div>
        <div class="col-md-4">
          <h5 *ngIf="unitSystem === 'metric'">{{ 'Total amount' | translate }} {{ '(mm)' | translate }} </h5>
          <h5 *ngIf="unitSystem === 'imperial'">{{ 'Total amount' | translate }} {{ '(in)' | translate }}</h5>
          <app-form-input [formControl]="amountIrrigation" size="small" type="border">
          </app-form-input>
        </div>
      </div>
      <div class="row">

        <div class="col-md-4 event-date-string">
          <h5 class="inlineBlock width45NoMarginB">{{ 'Start' | translate }}</h5>
          <p class="">{{ dateString }}</p>
        </div>

        <div class="col-md-4" id="event-time">
            <ngb-timepicker
              [seconds]="false"
              minuteStep="30"
              [formControl]="fromHourIrrigation"
              class="font12">
            </ngb-timepicker>
            <p class="inlineBlock" style="margin-left: 8%">{{ "hour" | translate }}</p>
            <p class="inlineBlock" style="margin-left: 20%">{{ "min" | translate }}</p>
        </div>

        <div class="col-md-4 event-duration">
          <h5 class="inlineBlock">{{ 'Duration (min)' | translate }}</h5>

          <app-form-input
            [formControl]="duration"
            type="border"
            size="small"
            [isDisabled]="newEventOrEditEvent === false && editSingleEvent === false">
          </app-form-input>

        </div>

      </div>

      <div class="row" *ngIf="newEventOrEditEvent === true">
        <div class="col-md-2">
          <h5>{{ 'Recurring event' | translate }}</h5>
        </div>
        <div class="col-md-6" *ngIf="recurringEvent === true">
          <h5>{{ 'Frequency' | translate }}</h5>
        </div>
        <div class="col-md-4" *ngIf="recurringEvent === true">
          <h5>{{ 'End of schedule' | translate }}</h5>
        </div>
      </div>

      <div class="row" *ngIf="newEventOrEditEvent === true">
        <div class="col-md-2">
          <app-yes-no-toggle
            noTab="true"
            [formControl]="toggle">
          </app-yes-no-toggle>
        </div>
        <div class="col-md-6" *ngIf="recurringEvent === true">
          <div class="row">
            <div class="col-md-5">
              <p>{{ 'Repeat every' | translate }}:</p>
            </div>
            <div class="col-md-3">
              <input class="frequency height29"
                     type="number"
                     min="1"
                     value="1"
                     [formControl]="frequency">
            </div>
            <div class="col-md-4 paddingL0">
              <app-form-select
                type="border"
                class="width150"
                [options]="daysWeeks"
                size="small"
                [formControl]="dayOrWeek">
              </app-form-select>
            </div>
          </div>
        </div>
        <div class="col-md-4 noPaddingLeft"
             *ngIf="recurringEvent === true">
          <app-custom-datepicker
            [isSmall]="true"
            [formControl]="endScheduleDate"
            [minDate]="date"
            [maxDate]="maxDate"
            class="noPaddingLeft">
          </app-custom-datepicker>
        </div>
      </div>

    </div>

    <div class="row paddingLR15">
      <app-custom-button
        *ngIf="newEventOrEditEvent === true"
        class="modalButton paddingB15"
        content="Save event"
        [disabled]="irrigationForm.invalid"
        color="green"
        (click)="submitIrrigation()">
      </app-custom-button>
      <app-custom-button
        *ngIf="newEventOrEditEvent === false"
        class="modalButton paddingB15"
        content="Save event"
        [disabled]="irrigationForm.invalid || hiddenSave"
        color="green"
        (click)="editIrrigation()">
      </app-custom-button>
      <app-custom-button
        class="modalButton paddingB15"
        content="Back"
        (click)="closeAddModal()">
      </app-custom-button>
    </div>
  </div>
</app-modal>

<app-modal [modalId]="DELETE_RECURRING" size="sm">
  <div class="modal-header dayHeader deleteRecurringHeader">
    <h2>{{ 'Delete recurring event' | translate }}</h2>
  </div>
  <div class="padding20">
    <div>
      <p>
        <input type="radio"
               id="thisOne"
               name="deleteRecurring"
               value="one"
               [formControl]="deleteRecurring">
        <label for="thisOne">{{ 'This one' | translate }}</label>
      </p>
      <p>
        <input type="radio"
               id="following"
               name="deleteRecurring"
               value="following"
               [formControl]="deleteRecurring">
        <label for="following">{{ 'This and following events' | translate }}</label>
      </p>
      <p>
        <input type="radio"
               id="allEvents"
               name="deleteRecurring"
               value="all"
               [formControl]="deleteRecurring">
        <label for="allEvents">{{ 'All events' | translate }}</label>
      </p>
    </div>
    <div class="textAlignR">
      <app-custom-button
        content="Cancel"
        (click)="closeConfirmationModal()"
        class="marginR10">
      </app-custom-button>
      <app-custom-button
        content="OK"
        (click)="deleteRecurringSubmit()">
      </app-custom-button>
    </div>
  </div>
</app-modal>


<app-modal [modalId]="DELETE_EVENT" size="sm">
  <div class="modal-header dayHeader">
    <h3>{{ 'Are you sure you want to delete this event?' | translate }}</h3>
  </div>
  <div class="padding20">
    <div>
      <p>
        <input type="radio"
               id="yes"
               name="deleteEvent"
               value="yes"
               [formControl]="deleteEvent">
        <label for="yes">{{ 'Yes' | translate }}</label>
      </p>

      <p>
        <input type="radio"
               id="no"
               name="deleteEvent"
               value="no"
               [formControl]="deleteEvent">
        <label for="no">{{ 'No' | translate }}</label>
      </p>
    </div>
    <div class="textAlignR">
      <app-custom-button
        content="Cancel"
        (click)="closeConfirmationEventModal()"
        class="marginR10">
      </app-custom-button>
      <app-custom-button
        content="OK"
        (click)="deleteSubmit()">
      </app-custom-button>
    </div>
  </div>
</app-modal>

<app-modal [modalId]="OPEN_DAY_EVENTS" size="sm">
  <div class="modal-header dayHeader">
    <div *ngIf="precipitationType === 'irrigation'">
      <h2 *ngIf="dayEvents.length === 1">{{'Irrigation event on' | translate }} {{eventDay | date: 'dd-MMM-yyyy'}}</h2>
      <h2 *ngIf="dayEvents.length > 1">{{ 'Irrigation events on' | translate }} {{eventDay | date: 'dd-MMM-yyyy'}}</h2>
    </div>
    <div *ngIf="precipitationType === 'manual'">
      <h2 *ngIf="dayEvents.length === 1">{{'Rain event on' | translate }} {{eventDay | date: 'dd-MMM-yyyy'}}</h2>
      <h2 *ngIf="dayEvents.length > 1">{{ 'Rain events on' | translate }} {{eventDay | date: 'dd-MMM-yyyy'}}</h2>
    </div>
  </div>
  <div class="padding20">
    <div *ngFor="let dayEvent of dayEvents" class="marginB10">
      <!-- left side -->
      <button *ngIf="!isSplitted"
              [ngClass]="dayEvent.event.eventType === 'irrigation' ? 'eventButton leftEventButton width85' : 'rainEventButton leftRainEventButton width85'"
              (click)="editModal(dayEvent)">
        {{dayEvent.event.title}}: {{metricOrImperial(dayEvent.event.amount, unitSystem)}} <p style="display: inline;" *ngIf="unitSystem === 'metric'">mm</p><p style="display: inline;" *ngIf="unitSystem === 'imperial'">in</p>
      </button>
      <button *ngIf="isSplitted"
              class="splitEventButton width85"
              (click)="editModal(dayEvent)">
        {{dayEvent.event.title}}: {{metricOrImperial(dayEvent.event.amount, unitSystem)}} <p style="display: inline;" *ngIf="unitSystem === 'metric'">mm</p><p style="display: inline;" *ngIf="unitSystem === 'imperial'">in</p>
      </button>

      <!-- right side -->
      <div *ngIf="!isSplitted" class="width15 inlineBlock">
        <button *ngIf="dayEvent.isSingleEvent === true"
          [ngClass]="dayEvent.event.eventType === 'irrigation' ? 'rightEventButton eventButton' : 'rightRainEventButton rainEventButton'"
          (click)="deleteModal2(dayEvent.event)">
            <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
        <button *ngIf="dayEvent.isSingleEvent === false"
                class="rightEventButton eventButton"
                (click)="deleteRecurringModal2(dayEvent.event)">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
      </div>
      <div *ngIf="isSplitted" class="width15 inlineBlock">
        <button *ngIf="dayEvent.isSingleEvent === true"
          class="splitEventButton rightSplit"
          (click)="deleteModal2(dayEvent.event)">
            <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
        <button *ngIf="dayEvent.isSingleEvent === false"
                class="splitEventButton rightSplit"
                (click)="deleteRecurringModal2(dayEvent.event)">
          <i class="fa fa-trash" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="textAlignR marginT15">
      <app-custom-button
        content="Back"
        color="red"
        (click)="closeDayEventsModal()">
      </app-custom-button>
    </div>
  </div>
</app-modal>

<app-info-modal [modalId]="modalId" [header]="'Rain and Irrigation Calendar'">
  <app-irrimet-calendar-help-text-modal></app-irrimet-calendar-help-text-modal>
</app-info-modal>
