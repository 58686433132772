import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Column, ColumnGroup, ExcelExportParams, GridOptions } from 'ag-grid';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { DataGridOptions, ExcelExportSettings } from '../../../../shared/constants';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';
import { IWorkPlanningSettingsState, IWorkPlanningState } from '../../models/work-planning.models';
import { selectWorkPlanningCharts, selectWorkPlanningGrid, selectWorkPlanningSettings, selectWorkPlanningSettingsIsTableActive } from '../../reducers';

@Component({
  selector: 'app-work-planning-table',
  templateUrl: './work-planning-table.component.html',
  styleUrls: ['./work-planning-table.component.scss']
})
export class WorkPlanningTableComponent implements OnInit {

  public forecastGrid       : any = {};
  public chartoptions       : any = [];
  public isTableActive$     : Observable<boolean>;
  public dataGridOptions    : GridOptions = {
    ...DataGridOptions,
    enableFilter: true
  };

  private mode              : string = 'plant-nutrition';
  private destroy$          : Subject<boolean> = new Subject<boolean>();
  private disabledGroupIds  : object = {};

  constructor(private workPlanningSettingsStore: Store<IWorkPlanningSettingsState>,
              private workPlanningStore: Store<IWorkPlanningState>,
              private navigationStationStore: Store<INavigationStationState>,
              private exportService: StationDataExportService) { }


  public ngOnInit(): void {
    this.isTableActive$ = this.workPlanningSettingsStore.pipe(
      select(selectWorkPlanningSettingsIsTableActive)
    );
    this.listenStationChange();
    this.listenWorkPlanningStore();
    this.listenExporting();
  }

  private listenStationChange(): void {
    this.navigationStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((selectedStation: IStation) => !!selectedStation),
      switchMap((selectedStation: IStation) => this.getWorkPlanningSettingsObservable(selectedStation))
    ).subscribe((disabledGroupIds: object) => {
      this.disabledGroupIds = disabledGroupIds;
      setTimeout(() => {
        this.toggleColumns();
      }, 0);
    });
  }

  private getWorkPlanningSettingsObservable(selectedStation: IStation): Observable<object> {
    return this.workPlanningSettingsStore.pipe(
      takeUntil(this.destroy$),
      select(selectWorkPlanningSettings),
      filter((workPlanningSettings: IWorkPlanningSettingsState): boolean => {
        return !!workPlanningSettings && !!workPlanningSettings.settings[selectedStation.name.original];
      }),
      tap((workPlanningSettings: IWorkPlanningSettingsState): void => {
        this.mode = workPlanningSettings.settings[selectedStation.name.original].selectedMode;
      }),
      map((workPlanningSettings: IWorkPlanningSettingsState): object => {
        return workPlanningSettings.settings[selectedStation.name.original].modes[this.mode].disabledGroupIds;
      })
    );
  }

  private listenWorkPlanningStore(): void {
    this.workPlanningStore.pipe(
      takeUntil(this.destroy$),
      select(selectWorkPlanningCharts),
      filter((chartOptions): boolean => !!chartOptions)
    ).subscribe((chartOptions): void => {
      this.chartoptions = chartOptions;
    });

    this.workPlanningStore.pipe(
      takeUntil(this.destroy$),
      select(selectWorkPlanningGrid),
      filter((grid): boolean => !!grid)
    ).subscribe((grid): void => {
      this.forecastGrid = this.appendOutput(grid, this.chartoptions);
      this.initTable(grid);
    });
  }

  private appendOutput(grid, chartOptons): any {
    const result = { ...grid };
    if (chartOptons[0].series[0].data[0].realValue !== undefined) {
      chartOptons[0].series[0].data.map((item, index) => {
        result.data[index] = {
          ...result.data[index],
          realValue: item.realValue
        };
      });
      if (!result.headers.find(item => item.field === 'realValue')) {
        result.headers.push({
          field: 'realValue',
          groupId: '',
          headerName: 'Output Value',
          suppressSorting: true
        });
      }
    }
    return result;
  }

  private toggleColumns(): void {
    if (!this.dataGridOptions.api) {
      return;
    }
    this.dataGridOptions.columnApi.getAllColumns().forEach(
      (c: Column) => {
        const isInvisible: string = Object.keys(this.disabledGroupIds).find((t) => c.getId().startsWith(t));
        if (!isInvisible && !c.isVisible()) {
          this.dataGridOptions.columnApi.setColumnVisible(c.getId(), true);
        }
      }
    );

    Object.keys(this.disabledGroupIds).forEach((key: string) => {
      const gr: ColumnGroup = this.dataGridOptions.columnApi.getColumnGroup(key);
      if (gr) {
        gr.getOriginalColumnGroup().getChildren().forEach((column) => {
          if (column.isVisible()) {
            this.dataGridOptions.columnApi.setColumnVisible(column.getId(), false);
          }
        });
      } else {
        this.dataGridOptions.columnApi.setColumnVisible(key, false);
      }
    });
  }

  private initTable(data: any): void {
    setTimeout(() => {
      if (data.headers && this.dataGridOptions.api) {
        this.forecastGrid.headers[0].unSortIcon = true;
        this.forecastGrid.headers = this.forecastGrid.headers.map((header: any) => {
          return {
            ...header,
            filter: 'agTextColumnFilter',
            menuTabs: ['filterMenuTab'],
            showTabs: false
          };
        });
        this.dataGridOptions.api.setSortModel({ colId: data.headers[0].field, sort: data.headers[0].sort });
        this.dataGridOptions.api.setColumnDefs(this.forecastGrid.headers);
        this.dataGridOptions.api.setRowData(this.forecastGrid.data);
        this.toggleColumns();
      } else if (this.dataGridOptions.api) {
        this.dataGridOptions.api.setColumnDefs(null);
        this.dataGridOptions.api.setRowData(null);
      }
    }, 0);
  }

  private listenExporting(): void {
    this.exportService.getExportXLS().pipe(
      takeUntil(this.destroy$)
    ).subscribe((id: string) => {
      this.exportXLS(id);
    });
  }

  private exportXLS(id: string): void {
    const params: ExcelExportParams = {
      ...ExcelExportSettings,
      fileName: `${id}_weather_forecast`,
    };

    this.dataGridOptions.api.exportDataAsExcel(params);
  }
}

