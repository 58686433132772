<div class="yield-config-help-modal">
  <section *ngIf="yieldHelpText === 'cropManagement'">
    <p>
      <b>{{ 'Crop:' | translate }}</b>
      {{ 'The crop grown in the cropzone.' | translate }}
    </p>
    <p>
      <b>{{ 'Sowing date:' | translate }}</b>
      {{ 'The date when the crop was sown.' | translate }}
    </p>
    <p>
      <b>{{ 'Expected harvest date:' | translate }}</b>
      {{ 'In a typical year, when would you expect this crop to be harvested?' | translate }}
    </p>
    <p>
      <b>{{ 'Note:' | translate }}</b>
      {{ 'If this cropzone was duplicated from another cropzone, make sure to change the settings accordingly. ' +
      'By default, settings from the original cropzone are copied and need to be updated manually.' | translate }}
    </p>
  </section>

  <section *ngIf="yieldHelpText === 'slider'">
    <p>
      {{ 'Fine tuning helps to improve Yield Prediction results. Default values are parameterized for ' +
      'the northern parts of North America. If your field is located at another region, it is crucial to ' +
      'fine tune the settings! Fine tuning enables the correct simulation of winter vs. spring crops ' +
      '(e.g. wheat), different maturity groups (e.g. soy, maize), and different yield potentials of crop varieties.' | translate }}
    </p>
    <p>
      {{ 'To fine tune the yield prediction settings for your specific location and crop variety, first make ' +
      'sure that you have selected the crop and sowing date correctly, and click save. Then, start fine tuning ' +
      'by dragging the sliders. Release the slider and wait a few moments for the average maturity and yield to ' +
      'update. Repeat this until the average maturity and yield match your expectation, then click save.' | translate }}
    </p>
    <p>
      {{ 'For best results, aim for average maturity date and average yield from the last 10 or 20 years, ' +
      'specifically for the considered location (or even field) and crop variety. Seasons with approximately ' +
      'average rain amount are a good starting point.' | translate }}
    </p>
    <p>
      <b>{{ 'Note:' | translate }}</b>
      {{ 'The values here are only averages to fine tune the model parameters for this specific environment ' +
      'and variety. The seasonal yield prediction, including rain forecast, is found on the Yield Prediction ' +
      'graph page (icon on the left).' | translate }}
    </p>
    <p>
      {{ 'The fine tuning is based on long term average precipitation which is automatically retrieved for ' +
      'the selected weather station. Other included variables are: The selected crop, sowing date, and soil ' +
      'texture. Initial soil moisture is assumed “normal (60%)”, independent from the settings, since the fine ' +
      'tuning is supposed to be based on long term averages.' | translate }}
    </p>
  </section>

  <section *ngIf="yieldHelpText === 'advanced'">
    <p>
      <b>{{ 'Best possible average yields:' | translate }}</b>
      {{ 'In a very good year (optimal water conditions, no pests/diseases), how high could your yield realistically be? ' +
      'In the Yield Prediction graph, this will cut off the prediction (upper limit). Note that this is pre-filled with a high ' +
      'value, thereby it will not limit the predicted yield in the default settings.' | translate }}
    </p>
    <p>
      <b>{{ 'Air temperature and rain source:' | translate }}</b>
      {{ 'The yield predictor requires a temperature and rain sensor installed near the cropzone (10 km / 6 mi radius). ' +
      'At least one of the selected stations must have an active Weather Forecast license, since Yield Prediction uses ' +
      'seasonal weather forecast and historical weather data.' | translate }}
    </p>
    <p>
      <b>{{ 'Soil texture:' | translate }}</b>
      {{ 'Select the prevailing soil texture within your cropzone. The list is based on USDA soil texture classification.' | translate }}
    </p>
    <p>
      <b>{{ 'Initial soil moisture:' | translate }}</b>
      {{ 'The yield predictor needs an estimate of actual available soil water at sowing. You can use ' +
      'your experience here; how much precipitation did you observe prior to sowing? You can also check the ' +
      'precipitation measurements of your nearest station. Also, try to factor in the effect of water use by ' +
      'the previous crop or cover crop. Note that Yield Prediction assumes an effective rooting depth of ' +
      '1 m / 3.3 ft. If the crop in the cropzone can be expected to grow roots and extract water much ' +
      'shallower or deeper, consider reducing or increasing initial soil moisture to compensate for that.' | translate }}
    </p>
    <p>
      <b>{{ 'Field capacity and wilting point:' | translate }}</b>
      {{ 'These are automatically estimated based on your selection of soil texture. Change these only if you know ' +
      'what you are doing! For instance, if you have a laboratory analysis of the soil texture within the cropzone, ' +
      'you can use these values.' | translate }}
    </p>
  </section>
  <p>
    <a href="https://metos.at/en/farmview-manual/#yield-prediction" target="_blank">{{ 'Click here' | translate }}</a>
    {{ 'to view the detailed Yield Prediction manual.' | translate}}
  </p>
</div>