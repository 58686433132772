import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { deepClone } from '../../../../shared/utils/deepClone';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { saveEmergency, setEmergency } from '../../actions/emergency';
import { IEmergencySaveAction } from '../../models/station-config.models';
import { selectEmergency } from '../../reducers';
import * as fromEmergency from '../../reducers/emergency';
import { IEmergencyState } from '../../reducers/emergency';

@Component({
  selector: 'app-emergency',
  templateUrl: './emergency.component.html',
  styleUrls: ['./emergency.component.scss']
})
export class EmergencyComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public station          : IStation;

  public emergencyState   : IEmergencyState;
  public modalId          : string = generateId();

  private destroy$        : Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store<fromEmergency.IEmergencyState>,
              private modalService: ModalService ) { }

  public ngOnInit(): void {
    this.store.pipe(
      takeUntil(this.destroy$),
      select(selectEmergency)
    ).subscribe((e: IEmergencyState) => {
      this.emergencyState = e;
    });
  }

  public openModal(): void {
    this.modalService.openModal(this.modalId);
  }

  public ngOnChanges(): void {
    this.store.dispatch(setEmergency(this.station.config.emergency_sms_number));
  }

  public saveEmergency(): void {
    const s: IStation = deepClone(this.station);
    s.config.emergency_sms_number = this.emergencyState.emergency;
    const save: IEmergencySaveAction = {
      station: s,
      emergency: {
        'config.emergency_sms_number': this.emergencyState.emergency
      }
    };
    this.store.dispatch(saveEmergency(save));
  }

  public changeEmergency(emergency: string): void {
    this.store.dispatch(setEmergency(emergency));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
