import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IDisease, IDiseaseModel } from '../../../../core/models/diseases.models';
import { IStation } from '../../../../core/models/stations';
import { selectDiseases, selectNavigationStation } from '../../../../core/reducers';
import { IDiseases } from '../../../../core/reducers/diseases';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { IStationTreeSettings, ITreeSettingsState } from '../../../../shared/tree/models/tree.models';
import { generateId } from '../../../dashboard/utils/makeWidget';
import * as moment from 'moment';
import { DiseaseDescription } from '../../constants/constants';
import { IDiseaseDescription } from '../../models/station-disease.models';
import { selectStationDiseaseSettingsPeriod, selectTreeSettings } from '../../reducers';
import * as fromDiseaseSettings from '../../reducers/station-disease-settings';

@Component({
  selector: 'app-station-disease-header',
  templateUrl: './station-disease-header.component.html',
  styleUrls: ['./station-disease-header.component.scss']
})
export class StationDiseaseHeaderComponent implements OnInit, OnDestroy {

  public period$                     : Observable<{from: string, to: string}>;
  public diseaseGroupName            : any = '';
  public diseaseName                 : any = '';
  public selectedProfile             : string = '';
  public readonly format             : string = 'yyyy-MM-dd HH:mm';
  public diseaseDescription          : IDiseaseDescription = DiseaseDescription;
  public modalId                     : string = generateId();
  public help                        : string = '';

  private destroy$                   : Subject<boolean> = new Subject<boolean>();
  private diseases                   : Array<IDisease>;

  constructor(private diseaseSettingsStore: Store<fromDiseaseSettings.IStationDiseaseSettingsState>,
              private treeSettingsStore: Store<ITreeSettingsState>,
              private navigationStore: Store<INavigationStationState>,
              private diseasesStore: Store<IDiseases>,
              private modal: ModalService) { }

  public ngOnInit(): void {
    this.period$ = this.diseaseSettingsStore.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      select(selectStationDiseaseSettingsPeriod),
      map(period => {
        if (period.fromTo) {
          moment().format('YYYY-MM-DD HH:mm');
          const periodFrom = moment(period.fromDatepicker).format('YYYY-MM-DD HH:mm');
          const periodTo = moment(period.fromTo.to).format('YYYY-MM-DD HH:mm');
          const fromDatepicker = moment(period.fromDatepicker).format('YYYY-MM-DD HH:mm');
          const toDatepicker = moment(period.toDatepicker).format('YYYY-MM-DD HH:mm');

          return {
            from: period.isLastDataMode ? periodFrom || fromDatepicker : fromDatepicker,
            to: period.isLastDataMode ? periodTo || toDatepicker : toDatepicker
          };
        }
      })
    );

    this.diseasesStore.pipe(
      takeUntil(this.destroy$),
      select(selectDiseases),
      tap((diseases: Array<IDisease>): void => {
        this.diseases = diseases;
      }),
      switchMap(() => this.getNavigationStationAndStationTreeSettingsObservable())
    ).subscribe((stationTreeSettings: IStationTreeSettings): void => {
      this.selectedProfile = stationTreeSettings.selectedProfile;
      this.diseaseGroupName = stationTreeSettings.selectedProfileGroup;
      this.setDiseaseName(stationTreeSettings.selectedProfile);
      this.setDiseaseHelp();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private getNavigationStationAndStationTreeSettingsObservable(): Observable<IStationTreeSettings> {
    return this.navigationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      switchMap((selectedStation: IStation) => this.getStationTreeSettingsObservable(selectedStation.name.original))
    );
  }

  private getStationTreeSettingsObservable(stationId: string): Observable<IStationTreeSettings> {
    return this.treeSettingsStore.pipe(
      select(selectTreeSettings),
      filter((treeSettings: ITreeSettingsState): boolean => !!treeSettings[stationId]),
      map((treeSettings: ITreeSettingsState): IStationTreeSettings => treeSettings[stationId])
    );
  }

  private setDiseaseName(diseaseId: string): void {
    const disease: IDisease = this.diseases.find((diseaseIter: IDisease): boolean => diseaseIter.group === this.diseaseGroupName);
    if (disease) {
      const diseaseModel: IDiseaseModel = disease.models.find((diseaseModelIter: IDiseaseModel): boolean => {
        return diseaseModelIter.key === diseaseId;
      });
      this.diseaseName = diseaseModel ? diseaseModel.name : '';
    }
  }

  private setDiseaseHelp(): void {
    this.help = this.diseaseDescription[this.selectedProfile] || '';
  }

  public openModal(): void {
    this.modal.openModal(this.modalId);
  }
}
