import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { stationDataContentAnimations } from '../../../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../../../core/services/left-components-toggler/left-components-toggler.service';
import { ITreeStructure } from '../../../../services/tree/models';
import { TreeService } from '../../../../services/tree/tree.service';

@Component({
  selector: 'app-station-disease',
  templateUrl: './station-disease.component.html',
  styleUrls: ['./station-disease.component.scss'],
  animations: stationDataContentAnimations
})

export class StationDiseaseComponent implements OnInit, OnDestroy {
  public state$           : Observable<string>;
  public tree$            : Observable<ITreeStructure>;

  constructor(private leftComponentsToggler: LeftComponentsTogglerService,
              private treeService: TreeService) {
  }

  public ngOnInit(): void {
    this.state$ = this.leftComponentsToggler.getStationDataContentState();
    this.tree$ = this.treeService.getDiseaseTreeStructure();
  }

  public ngOnDestroy(): void {
    this.treeService.getDiseaseTreeStructure().next(null);
  }

}
