import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  getProductActivationByKeyAndType,
  saveDavisIngestActivation,
  saveFarmViewActivation,
  setProductActivationConfigurationItem, setProductActivationKey
} from '../../../../../modules/product-activation/actions/product-activation';
import { getFeatureType, LicenseType } from '../../../../../modules/product-activation/constants/constants';
import {
  ILicenseActivationItem,
  IProductActivationState,
  IProductConfigurationItem,
  IValidLicenseMap
} from '../../../../../modules/product-activation/models/product-activation.models';
import { selectProductConfigurationItem } from '../../../../../modules/product-activation/reducers';
import { ISelectedLicense } from '../../../../models/licenses';

@Component({
  selector: 'app-product-license-activation',
  templateUrl: './product-license-activation.component.html',
  styleUrls: ['./product-license-activation.component.scss']
})
export class ProductLicenseActivationComponent implements OnInit {
  @Input()
  public licenseType: string;
  @Input()
  public validLicenses: IValidLicenseMap = {};
  @Output()
  public selectLicense = new EventEmitter<ISelectedLicense>();
  @Output()
  public cancel = new EventEmitter<void>();

  public productConfiguration$: Observable<IProductConfigurationItem>;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private productActivationStore: Store<IProductActivationState>) { }

  public ngOnInit(): void {
    this.productConfiguration$ = this.productActivationStore.pipe(
      takeUntil(this.destroy$),
      select(selectProductConfigurationItem)
    );
  }

  public findProductToActivate(productKey: string): void {
    const payload = { type: this.licenseType, productKey };
    this.productActivationStore.dispatch(getProductActivationByKeyAndType(
      productKey,
      getFeatureType(this.licenseType)
    ));
  }

  public handleDavisIngestActivation(event: ILicenseActivationItem): void {
    this.productActivationStore.dispatch(saveDavisIngestActivation(event));
    this.productActivationStore.dispatch(setProductActivationKey(''));
    this.productActivationStore.dispatch(setProductActivationConfigurationItem(null));
    this.selectLicense.emit({productKey: event.product_key});
  }

  public handleFarmViewActivation(event: ILicenseActivationItem): void {
    this.productActivationStore.dispatch(saveFarmViewActivation(event));
    this.productActivationStore.dispatch(setProductActivationKey(''));
    this.productActivationStore.dispatch(setProductActivationConfigurationItem(null));
    this.selectLicense.emit({productKey: event.product_key});
  }

  public handleCancel(): void {
    this.productActivationStore.dispatch(setProductActivationConfigurationItem(null));
    this.cancel.emit();
  }

  public isDavisIngest(): boolean {
    return this.licenseType === LicenseType.DAVIS_INGEST;
  }

  public isFarmView(): boolean {
    return this.licenseType === LicenseType.FARM_VIEW;
  }
}
