import { Injectable } from '@angular/core';
import { ApiCallService } from '../../../services/api/api-call.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ISumsSmState } from '../models';
import { Observable, of } from 'rxjs';
import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { ActionTypes, setSumsSmLoadingState, setSumsSmData } from '../actions';
import { tap, switchMap, catchError, debounceTime } from 'rxjs/operators';

@Injectable()
export class SumsSmEffects {

    constructor(
        private api: ApiCallService,
        private actions$: Actions,
        private store: Store<ISumsSmState>
    ) { }

    @Effect()
    public getData$: Observable<IAction> = this.actions$.pipe(
        ofType(ActionTypes.GET_SUMS_SM_DATA),
        debounceTime(200),
        tap(() => this.store.dispatch(setSumsSmLoadingState(true, false))),
        switchMap((action: IAction) => this.api.getCropZoneSoilMoistureSumsSm(action.payload.id, action.payload.params)
            .pipe(
                switchMap(data => [setSumsSmData(data), setSumsSmLoadingState(false, !data.length)]),
                catchError(() => of(setSumsSmLoadingState(false, true)))
            )
        )
    );

}
