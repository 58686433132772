import { IActionWithPayload } from './../../../core/models/actionWithPayload';
import { NodesActionTypes } from '../actions/nodes';

export interface INodeObject {
    stationId: string;
    nodesArray: Array<any>;
}

export interface INodesState {
    nodes: Array<INodeObject>;
}

export const initialState: INodesState = {
    nodes: []
};

export function reducer(state: INodesState = initialState, action: IActionWithPayload): INodesState {
    switch (action.type) {
        case NodesActionTypes.SET_NODES:
            const nodesObject: INodeObject = {
                stationId: action.payload.stationId,
                nodesArray: action.payload.nodes
            };

            const nodesState = state.nodes;
            nodesState.push(nodesObject);

            if (nodesState.length > 0) {
                return <INodesState>{
                    ...state,
                    nodes: nodesState
                };
            } else {
                return <INodesState>{
                    ...state,
                    nodes: state.nodes
                };
            }
        default:
            return state;
    }
}
