import { IPosition } from './../../../core/models/stations';
import * as L from 'leaflet';
import { LeafletZoom } from '../../../core/constants/leaflet';

export const DefaultCropzonePosition: IPosition = {
  geo: {
    coordinates: [
      47.2171,
      15.6230
    ]
  },
  altitude: 0,
  timezoneCode: ''
};

export const LeafletMapOptions: L.MapOptions = {
  layers: [
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: LeafletZoom.maxZoom,
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }),
  ],
  zoom: LeafletZoom.zoom,
  center: L.latLng([45, 15]),
  worldCopyJump: true,
};

export const LeafletDrawOptions: any = {
  position: 'topright',
  draw: {
    polyline: false,
    rectangle: false,
    marker: false,
    polygon: {
      icon: new L.DivIcon({
        iconSize: new L.Point(8, 8),
      }),
      allowIntersection: false,
      drawError: {
        color: 'red',
      message: '<strong>The drawn boundaries cannot intersect!',
      },
      shapeOptions: {
        color: 'red',
        marker: false,
      },
    },
    circle: {
      shapeOptions: {
        color: 'red',
        marker: false,
      },
    },
    circlemarker: false,
  },
};

export const FitBoundOptions: L.FitBoundsOptions = {
  paddingTopLeft: new L.Point(50, 50),
  paddingBottomRight: new L.Point(50, 50),
};
