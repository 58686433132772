import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IFromTo } from '../../../../shared/camera/models/camera';
import {
  setCameraDataCurrentDateString,
  setCameraDataIsChartExportInProgress,
  setCameraDataIsTableExportInProgress
} from '../../actions/camera-data';
import { StationCameraTypes } from '../../constants/camera-data';
import { ICameraDataState } from '../../models/camera-data';

@Component({
  selector: 'app-camera-data-toolbar',
  templateUrl: './camera-data-toolbar.component.html'
})
export class CameraDataToolbarComponent {
  @Input()
  public stationCameraType: string;
  @Input()
  public stationChangedListener$: Observable<string>;

  constructor(private cameraDataStore: Store<ICameraDataState>) {
  }

  public get isIScout(): boolean {
    return this.stationCameraType === StationCameraTypes.ISCOUT;
  }

  public get isCropView(): boolean {
    return this.stationCameraType !== StationCameraTypes.ISCOUT;
  }

  public handleChartExport(): void {
    this.cameraDataStore.dispatch(setCameraDataIsChartExportInProgress(true));
  }

  public handleTableExport(): void {
    this.cameraDataStore.dispatch(setCameraDataIsTableExportInProgress(true));
  }

  public handleRefresh(currentDate: string | IFromTo): void {
    this.cameraDataStore.dispatch(setCameraDataCurrentDateString(currentDate));
  }
}
