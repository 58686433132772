import { IEnvironment } from '../../../environments/interfaces/environment';
import { firebaseConfig, environmentConfig } from '../../../environments/environment.config';
import { languageOptions } from '../../../environments/languages';
import { themeConfig } from './theme.config';
import { displayRulesConfig } from '../default/theme.config';

export const environment: IEnvironment = {
  production: false,
  oauthUrl: 'https://oauth.tramthoitiet.vn/token',
  apiUrl: 'https://api.tramthoitiet.vn',
  socketUrl: 'https://sockets.tramthoitiet.vn',
  myJohnDeereUrl: environmentConfig.myJohnDeereUrl,
  clientId: environmentConfig.clientId,
  clientSecret: environmentConfig.clientSecret,
  gMapKey: 'AIzaSyDsN1EpI5lVtVvxk001Ar2QztPx7r0HbOc',
  gMapTZKey: environmentConfig.gMapTZKey,
  gMapEndPoint: environmentConfig.gMapEndPoint,
  debugPHP: false,
  displayRules: displayRulesConfig,
  theme: themeConfig,
  firebase: firebaseConfig,
  languages: languageOptions,
};
