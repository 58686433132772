<app-tree [tree$]="tree$"></app-tree>
<div class="station-sensors" [@placeContent]="state$ | async">
  <app-station-page-header [pageName]="'Sensors and nodes'"></app-station-page-header>
  <app-sensors-loading [isLoading]="isLoading$ | async"
                       [isError]="isError$ | async"></app-sensors-loading>
  <div #content>
    <ng-container *ngIf="stationId && nodes && !(isLoading$ | async)">
      <app-sensor-node *ngFor="let node of getNodesKeys()"
                       [stationId]="stationId"
                       [node]="nodes[node]"
                       [nodeCalibration]="nodeCalibration[node]"
                       [nodeKey]="node"></app-sensor-node>
    </ng-container>
  </div>
</div>
