import { Injectable } from '@angular/core';
import {ISensor} from '../../../shared/interfaces';
import {IWarningsList} from '../models/models';
import {WarningsSensorsGroups} from '../constants/constants';

@Injectable()
export class WarningsHelpService {

  public prepareSensors(res: any, warnings: string[]): IWarningsList {
    return (this.prepareWarnings(this.filterSensors(res), warnings));
  }

  private prepareWarningsList(sensors: ISensor[]) : IWarningsList {
    const preparedWarnings: IWarningsList = {};
    sensors.forEach((s: ISensor) => {
      const sensorPath: string = `${s.ch}_${s.code}`;
      preparedWarnings[sensorPath] = {
        ch: s.ch,
        code: s.code,
        unit: s.unit,
        name: s.name_custom || s.name,
        chain: Math.floor(s.ch / 2000) + 1,
        settings: {
          min: '',
          max: ''
        }
      };
    });
    return preparedWarnings;
  }

  private prepareWarnings(sensors: ISensor[], warnings: string[]): IWarningsList {
    const preparedWarnings: IWarningsList = this.prepareWarningsList(sensors);
    warnings.forEach((w: any) => {
      const warningArray: string[] = w.split(',');
      const sensor: ISensor = sensors.find((s: ISensor) => s.ch === +warningArray[0] && s.code === +warningArray[1]);
      if (sensor) {
        const sensorPath: string = `${sensor.ch}_${sensor.code}`;
        const settingsProperty: string = warningArray[2] === '0' ? 'min' : 'max';
        if (preparedWarnings[sensorPath].settings[settingsProperty]) {
          preparedWarnings[sensorPath].settings[settingsProperty] += `, ${warningArray[3]}`;
        } else {
          preparedWarnings[sensorPath].settings[settingsProperty] = warningArray[3];
        }
      }
    });
    return preparedWarnings;
  }

  private filterSensors(sensors: ISensor[]): ISensor[] {
    return sensors.filter((s: ISensor) => s.isActive && WarningsSensorsGroups.has(s.group));
  }

  constructor() { }
}
