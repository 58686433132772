<app-form-layout [shadow]="false">
  <div class="soil-guard-header" *ngIf="dates">
    <div>
      <h2>{{ title | translate }}</h2>
      <small>{{ deviceType | titlecase | translate}}</small>
    </div>
    <div class="soil-guard-header__right">
      <i class="zmdi zmdi-help" (click)="openModal()"></i>
    </div>
  </div>
</app-form-layout>

<app-info-modal [modalId]="stationSampleID" header="Sampling Device Data">
  <app-sample-modal></app-sample-modal>
</app-info-modal>
