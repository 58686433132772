import {ProductLicenseActionTypes} from '../actions/licenses';
import {IActionWithPayload} from '../models/actionWithPayload';
import {IProductLicense} from '../models/licenses';

export interface IProductLicenseState {
  activeLicenses: Array<IProductLicense>;
}

const initialState: IProductLicenseState = {
  activeLicenses: null
};

export function reducer(state: IProductLicenseState = initialState, action: IActionWithPayload): IProductLicenseState {
  switch (action.type) {
    case ProductLicenseActionTypes.SET_PRODUCT_LICENSES:
      return {
        ...state,
        activeLicenses: action.payload
      };
    default:
      return state;
  }
}
