import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {
  IGroupAndProfilePayload,
  IProfilePayload,
  IStationProfileBatchSettingPayload,
  IStationProfileSettingPayload,
  TreeSettingsActionTypes
} from '../actions/tree-settings';
import {TreeSettingsHelper} from './helpers/tree-settings.helper';
import {ITreeSettingsState} from '../models/tree.models';


const initialDataState: ITreeSettingsState = {};
const initialDiseaseState: ITreeSettingsState = {};
const initialSoilMoistureState: ITreeSettingsState = {};
const initialWorkplanningState: ITreeSettingsState = {};
const initialWeatherForecastState: ITreeSettingsState = {};
const initialAnimalProductionState: ITreeSettingsState = {};

export function stationDataTreeReducer(state: ITreeSettingsState = initialDataState, action: IActionWithPayload): ITreeSettingsState {
  let treeHelper: TreeSettingsHelper;
  switch (action.type) {
    case TreeSettingsActionTypes.SET_STATION_DATA_TREE_SETTINGS:
      const settings: IStationProfileSettingPayload = <IStationProfileSettingPayload>action.payload;
      treeHelper = new TreeSettingsHelper(state);
      if (treeHelper.stateHasDisabledGroupId(settings)) {
        treeHelper.enableGroupId(settings);
      } else {
        treeHelper.disableGroupId(settings);
      }
      return {...state};
    case TreeSettingsActionTypes.SET_STATION_DATA_BATCH_TREE_SETTINGS:
      const batchSetting: IStationProfileBatchSettingPayload = <IStationProfileBatchSettingPayload>action.payload;
      treeHelper = new TreeSettingsHelper(state);
      if (batchSetting.enable) {
        treeHelper.enableGroupIds(batchSetting);
      } else {
        treeHelper.disableGroupIds(batchSetting);
      }
      return {...state};
    case TreeSettingsActionTypes.SET_STATION_DATA_PROFILE:
      const profilePayload: IProfilePayload = <IProfilePayload> action.payload;
      if (!state[profilePayload.stationId]) {
        state[profilePayload.stationId] = {
          profileSettings: {
            [profilePayload.profile]: {
              disabledGroupIds: {}
            }
          },
          selectedProfile: profilePayload.profile
        };
      }
      if (!state[profilePayload.stationId].profileSettings[profilePayload.profile]) {
        state[profilePayload.stationId].profileSettings[profilePayload.profile] = {disabledGroupIds: {}};
      }
      state[profilePayload.stationId].selectedProfile = profilePayload.profile;
      return {...state};
    default:
      return state;
  }
}

export function stationDiseaseTreeReducer(state: ITreeSettingsState = initialDiseaseState, action: IActionWithPayload): ITreeSettingsState {
  switch (action.type) {
    case TreeSettingsActionTypes.SET_DISEASE_GROUP_AND_PROFILE:
      const groupAndProfilePayload: IGroupAndProfilePayload = <IGroupAndProfilePayload> action.payload;
      if (!state[groupAndProfilePayload.stationId]) {
        state[groupAndProfilePayload.stationId] = {
          profileSettings: {},
          selectedProfile: groupAndProfilePayload.profile
        };
      }
      state[groupAndProfilePayload.stationId].selectedProfile = groupAndProfilePayload.profile;
      state[groupAndProfilePayload.stationId].selectedProfileGroup = groupAndProfilePayload.group;
      return {...state};
    default:
      return state;
  }
}

export function soilMoistureTreeReducer(
  state: ITreeSettingsState = initialSoilMoistureState,
  action: IActionWithPayload
): ITreeSettingsState {
  let treeHelper: TreeSettingsHelper;
  switch (action.type) {
    case TreeSettingsActionTypes.SET_SOIL_MOISTURE_TREE_SETTINGS:
      const settings: IStationProfileSettingPayload = <IStationProfileSettingPayload>action.payload;
      treeHelper = new TreeSettingsHelper(state);
      if (treeHelper.stateHasDisabledGroupId(settings)) {
        treeHelper.enableGroupId(settings);
      } else {
        treeHelper.disableGroupId(settings);
      }
      return {...state};
    case TreeSettingsActionTypes.SET_SOIL_MOISTURE_BATCH_TREE_SETTINGS:
      const batchSetting: IStationProfileBatchSettingPayload = <IStationProfileBatchSettingPayload>action.payload;
      treeHelper = new TreeSettingsHelper(state);
      if (batchSetting.enable) {
        treeHelper.enableGroupIds(batchSetting);
      } else {
        treeHelper.disableGroupIds(batchSetting);
      }
      return {...state};
    case TreeSettingsActionTypes.SET_SOIL_MOISTURE_PROFILE:
      const profilePayload: IProfilePayload = <IProfilePayload> action.payload;
      if (!state[profilePayload.stationId]) {
        state[profilePayload.stationId] = {
          profileSettings: {
            [profilePayload.profile]: {
              disabledGroupIds: {}
            }
          },
          selectedProfile: profilePayload.profile
        };
      }
      if (!state[profilePayload.stationId].profileSettings[profilePayload.profile]) {
        state[profilePayload.stationId].profileSettings[profilePayload.profile] = {disabledGroupIds: {}};
      }
      state[profilePayload.stationId].selectedProfile = profilePayload.profile;
      return {...state};
    default:
      return state;
  }
}

export function workPlanningTreeReducer(
  state: ITreeSettingsState = initialWorkplanningState,
  action: IActionWithPayload): ITreeSettingsState {
  switch (action.type) {
    case TreeSettingsActionTypes.SET_WORK_PLANNING_GROUP_AND_PROFILE:
      const payload: IGroupAndProfilePayload = action.payload;
      if (!state[payload.stationId]) {
        state[payload.stationId] = {
          profileSettings: {},
          selectedProfile: payload.profile,
          selectedProfileGroup: payload.group,
        };
      } else {
        state[payload.stationId].selectedProfile = payload.profile;
        state[payload.stationId].selectedProfileGroup = payload.group;
      }
      return { ...state };
    default:
      return state;
  }
}

export function animalProductionTreeReducer(
  state: ITreeSettingsState = initialAnimalProductionState,
  action: IActionWithPayload): ITreeSettingsState {
  switch (action.type) {
    case TreeSettingsActionTypes.SET_ANIMAL_PRODUCTION_PROFILE:
      const payload: IProfilePayload = action.payload;
      if (!state[payload.stationId]) {
        state[payload.stationId] = {
          profileSettings: {},
          selectedProfile: payload.profile
        };
      } else {
        state[payload.stationId].selectedProfile = payload.profile;
      }
      return { ...state };
    default:
      return state;
  }
}

export function weatherForecastTreeReducer(
  state: ITreeSettingsState = initialWeatherForecastState,
  action: IActionWithPayload): ITreeSettingsState {
  switch (action.type) {
    case TreeSettingsActionTypes.SET_WEATHER_FORECAST_PROFILE:
      const payload: IGroupAndProfilePayload = action.payload;
      if (!state[payload.stationId]) {
        state[payload.stationId] = {
          profileSettings: {},
          selectedProfile: payload.profile,
          selectedProfileGroup: payload.group,
        };
      } else {
        state[payload.stationId].selectedProfile = payload.profile;
        state[payload.stationId].selectedProfileGroup = payload.group;
      }
      return { ...state };
    default:
      return state;
  }
}
