import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { LeftComponentsTogglerService } from '../../../../core/services/left-components-toggler/left-components-toggler.service';
import { NavigationService } from '../../../../core/services/navigation/navigation.service';
import { ITreeStructure } from '../../../../services/tree/models';
import { TreeService } from '../../../../services/tree/tree.service';
import { READ_AND_WRITE_PERMISSION, WRITE_PERMISSION } from '../../../../shared/constants';
import { IOptions } from '../../../../shared/interfaces';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { DefaultPeriodScope, PeriodValuesByScope } from '../../../station-data/constants/constants';

@Component({
  selector: 'app-correct-precipitation-data',
  templateUrl: './correct-precipitation-data.component.html',
  styleUrls: ['./correct-precipitation-data.component.scss'],
})
export class CorrectPrecipitationDataComponent implements OnInit, OnDestroy {

  public destroy$: Subject<boolean> = new Subject();
  public periodForm: FormGroup;
  private isDatepickerRequest: boolean = false;
  private profile: string;
  private initDestroy$: Subject<boolean> = new Subject<boolean>();
  @Output()
  // public openModal            : EventEmitter<void> = new EventEmitter();
  public RAIN_CORRECTOR_HELP_MODAL_ID: string = generateId();

  public station: IStation;
  public fromStation: Date = null;
  public toStation: Date = null;
  public periodScopeSelectItems: Array<IOptions> = Object.keys(PeriodValuesByScope).map((item: string) => ({
    content: item,
    value: item
  }));

  public periodValueSelectItems: Array<IOptions> = PeriodValuesByScope[DefaultPeriodScope];

  public tree$: Observable<ITreeStructure>;
  public state$: Observable<string>;
  public navigationStation: IStation;
  public cropzone: string = '';

  private navigationStation$: Observable<IStation> = this.navigationStationStore.pipe(
    takeUntil(this.destroy$),
    select(selectNavigationStation)
  );

  private readonly PERMISSION_DENIED_STATUS = 403;

  constructor(
    private treeService: TreeService,
    private leftComponentsToggler: LeftComponentsTogglerService,
    private navigationStationStore: Store<INavigationStationState>,
    private modalService: ModalService,
    private navigationService: NavigationService
  ) { }

  public ngOnInit(): void {
    this.tree$ = this.treeService.getStationSettingsTreeStructure().pipe(
      takeUntil(this.destroy$)
    );
    this.state$ = this.leftComponentsToggler.getStationDataContentState().pipe(
      takeUntil(this.destroy$)
    );

    this.navigationStation$.subscribe((station: IStation): void => {
      this.navigationStation = station;
    });
    this.cropzone = localStorage.getItem('routeCropzone');
  }

  public openModal(): void {
    this.modalService.openModal(this.RAIN_CORRECTOR_HELP_MODAL_ID);
  }

  public routeIrrimet(): void {
    this.navigationService.navigateToIrrimet(this.cropzone);
    localStorage.removeItem('routeCropzone');
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    localStorage.removeItem('routeCropzone');
  }

  public canDelete(): boolean {
    return this.navigationStation && [WRITE_PERMISSION, READ_AND_WRITE_PERMISSION].indexOf(this.navigationStation.rights) !== -1;
  }

}
