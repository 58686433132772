import { IDisplayRulesConfig } from '../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../environments/interfaces/theme';

export const themeConfig: IThemeConfig = {
  subDomain: 'adama',
  language: 'br',
  headerClass: 'white',
  src: '../../../assets/themes/adama/assets/images/logo.png',
  mainSrc: '../../../assets/themes/adama/assets/images/landing.png',
  width: '250',
  mainWidth: '168',
  mainMTop: '6',
  mainLeft: '8',
  loginLeft: '40',
  loginBottom: '10',
  registerBottom: '10',
  resetLeft: '20',
  resetBottom: '10',
  hideVersion: true,
  name: 'Adama',
  profileMenuSrc: '../../../assets/themes/default/assets/images/profile.png',
  faviconSrc: '../../../assets/themes/default/assets/favicon.ico',
  colorConfig: {
    '--search-widget-border': '#8bc34a',
    '--search-widget-label': '#8bc34a',
    '--search-widget-label-dark-green': '#8bc34a',
    '--search-widget-label-orange': '#f39224',
    '--search-widget-button': '#5cb85c',
    '--main-static': '#008366',
    '--main-static-orange': '#f39224',
    '--form-button': '#5cb85c',
    '--form-button-action': '#449d44',
  }
};

export const displayRulesConfig: IDisplayRulesConfig = {
  userMenu: false,
  farmViewMenu: false,
  leftBarMenu: {
    stationData: true,
    cropView: false,
    iScout: false,
    weatherForecast: true,
    workPlanning: false,
    animalProduction: false,
    accumulatorTool: true,
    frostProtection: true,
    diseaseModels: false,
    soilMoisture: false,
    hydroponics: false,
    tracker: false,
    soilGuard: true,
    settings: false,
  },
};
