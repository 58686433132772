import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { setNotify } from '../../../../core/actions/notify';
import { ICropZone } from '../../../../core/models/cropzones';
import * as fromNotify from '../../../../core/reducers/notify';
import { IOptions } from '../../../../shared/interfaces';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { deleteAllLai } from '../../../crop-zone-sat/actions';
import { deleteAllNdvi } from '../../../crop-zone-sat/actions/ndvi';
import { generateId } from '../../../dashboard/utils/makeWidget';
import * as fromCropzoneName from '../../reducers/cropzone-name';
import { ILaiState, INdviState } from './../../../crop-zone-sat/models/index';
import * as moment from 'moment';

@Component({
  selector: 'app-cropzone-data-form',
  templateUrl: './cropzone-data-form.component.html',
  styleUrls: ['./cropzone-data-form.component.scss']
})
export class CropzoneDataFormComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Output()
  private saveRenamedCropzone = new EventEmitter<ICropZone>();

  @Output()
  private hydrateCropzone = new EventEmitter();

  @Input()
  public set cropzoneInput(cropzone) {
    this.source = { content: '', value: '' };
    this.cropzone = cropzone;
  }

  @Input()
  private set cropzones(data) {
    if (data && data.length) {
      this.cropzonesData = data;
      this.transform();
    }
  }

  private cropzone: any;
  public cropzonesData: ICropZone[];
  public modalId: string = generateId();
  public renameForm: FormGroup;
  public maximumDate: Date;
  private alive$ = new Subject<boolean>();
  public options$: Observable<IOptions[]>;
  public source;

  constructor(
    private fb: FormBuilder,
    private notifyStore: Store<fromNotify.INotifyState>,
    private configStore: Store<fromCropzoneName.ICropzoneNameState>,
    private modalService: ModalService,
    private laiStore: Store<ILaiState>,
    private ndviStore: Store<INdviState>
  ) {

  }

  public ngOnInit(): void {
    if (this.cropzone.license_end_date) {
      this.maximumDate = moment(this.cropzone.license_end_date).toDate();
    } else {
      this.maximumDate = moment().add(1, 'year').toDate();
    }

    this.renameForm = this.fb.group({
      cropzoneName: [this.cropzone.name, [Validators.required, Validators.minLength(3)]],
      fieldName: [this.cropzone.field.name, [Validators.required, Validators.minLength(3)]],
      farmName: [this.cropzone.farm.name, [Validators.required, Validators.minLength(3)]],
      crop: [this.cropzone.crop_name, [Validators.required, Validators.minLength(3)]],
      from: [new Date(this.cropzone.from), []],
      to: [new Date(this.cropzone.to), []],
      copyFrom: [this.source, []]
    });
  }

  public ngAfterViewInit(): void {
    this.copyFrom.valueChanges.pipe(
      takeUntil(this.alive$),
      filter(data => data && data.content),
      map(data =>
        this.cropzonesData.find(cropzone => cropzone.id === data.content)
      )
    ).subscribe(data => this.hydrate(data));
  }

  public get cropzoneName(): AbstractControl {
    return this.renameForm.get('cropzoneName');
  }

  public get fieldName(): AbstractControl {
    return this.renameForm.get('fieldName');
  }

  public get farmName(): AbstractControl {
    return this.renameForm.get('farmName');
  }

  public get crop(): AbstractControl {
    return this.renameForm.get('crop');
  }

  public get from(): AbstractControl {
    return this.renameForm.get('from');
  }

  public get to(): AbstractControl {
    return this.renameForm.get('to');
  }

  public get copyFrom(): AbstractControl {
    return this.renameForm.get('copyFrom');
  }

  public emitSaveRename(): void {
    if (this.timeDifferenceDates(this.renameForm.value.from, this.renameForm.value.to) > 366) {
      this.notifyStore.dispatch(setNotify('Unable to save cropzone. The cultivation period is bigger than a year'));
    } else {
      this.laiStore.dispatch(deleteAllLai(this.cropzone.id));
      this.ndviStore.dispatch(deleteAllNdvi(this.cropzone.id));

      const renamedObject: ICropZone = {
        ...this.cropzone,
          name: this.renameForm.value.cropzoneName,
          crop_name: this.renameForm.value.crop,
          from: this.dateToAdjustedISO(this.renameForm.value.from),
          to: this.dateToAdjustedISO(this.renameForm.value.to),
      };

      this.saveRenamedCropzone.emit(renamedObject);
    }
  }

  public transform(): void {
    if (this.cropzonesData && this.cropzonesData.length) {
      this.options$ = of(
        this.cropzonesData
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(cropzone => ({
          content: cropzone.id,
          value: `${cropzone.name} / ${cropzone.field.name} / ${cropzone.farm.name}`
        }))
      );
    }
  }

  private hydrate(cropzone: ICropZone): void {
    if (cropzone && cropzone.name) {
      this.hydrateCropzone.emit({
        crop_name: cropzone.crop_name,
        from: cropzone.from,
        to: cropzone.to,
        boundary: cropzone.boundary,
        year: cropzone.year,
        soil: cropzone.soil,
        crop: cropzone.crop,
        rain_efficacies: cropzone.rain_efficacies,
        data_sources: cropzone.data_sources
      });
    }
  }

  public timeDifferenceDates(date1: Date, date2: Date): number {
    const temp = date2.getTime() - date1.getTime();
    return temp / (1000 * 60 * 60 * 24);
  }

  public dateToAdjustedISO(date: Date): string {
    let isoString: string = date.toISOString();
    isoString = isoString.substring(0, isoString.length - 5);
    isoString += '+00:00';
    return isoString;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.renameForm) {
      this.cropzoneName.setValue(this.cropzone.name);
      this.fieldName.setValue(this.cropzone.field.name);
      this.farmName.setValue(this.cropzone.farm.name);
      this.crop.setValue(this.cropzone.crop_name);
      this.from.setValue(new Date(this.cropzone.from));
      this.to.setValue(new Date(this.cropzone.to));
    }
  }

  public openModal(): void {
    this.modalService.openModal(this.modalId);
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }
}
