import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { saveDavisIngestActivation } from '../../actions/product-activation';
import {
  ILicenseActivationItem,
  IProductActivationState,
  IProductConfigurationItem,
  IValidLicense
} from '../../models/product-activation.models';

@Component({
  selector: 'app-product-activation-davis-ingest',
  templateUrl: './product-activation-davis-ingest.component.html',
  styleUrls: ['./product-activation-davis-ingest.component.scss']
})
export class ProductActivationDavisIngestComponent {
  @Input()
  public configuration: IProductConfigurationItem;
  @Input()
  public licensesOverlapped: IValidLicense[] = [];
  @Input()
  public emitActivation: boolean = false;
  @Input()
  public cancelable: boolean = false;
  @Output()
  public activateProduct: EventEmitter<ILicenseActivationItem> = new EventEmitter<ILicenseActivationItem>();
  @Output()
  public cancel: EventEmitter<void> = new EventEmitter<void>();

  public constructor(private productActivationStore: Store<IProductActivationState>) {}

  public handleActivateProduct(activation: ILicenseActivationItem): void {
    if (this.emitActivation) {
      this.activateProduct.emit(activation);
    } else {
      this.productActivationStore.dispatch(saveDavisIngestActivation(activation));
    }
  }

  public handleCancel(): void {
    this.cancel.emit();
  }
}
