import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ISettings } from '../../../../core/models/account';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { IHeaderData } from '../../../work-planning/models/work-planning.models';
import { HELP_TEXT, LOCALE_OPTIONS, SEVERITIES } from '../../constants/constants';
import { MapService } from '../../services/map.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import moment = require('moment');

@Component({
  selector: 'app-weather-forecast-precipitation-radar',
  templateUrl: './weather-forecast-precipitation-radar.component.html',
  styleUrls: ['./weather-forecast-precipitation-radar.component.scss']
})
export class WeatherForecastPrecipitationRadarComponent implements AfterViewChecked, OnDestroy {

  public header                 : IHeaderData = { title: 'Weather Maps', subtitle: '', help: HELP_TEXT};
  public modalId                : string = generateId();
  public url                    : string;
  public isLoading              : boolean = false;
  public unitSystem             : string = 'metric';
  public timezone               : string;
  public coordinates            : number[];
  public userSettings$          : Observable<ISettings>;
  public map                    : any;
  public marker                 : any;
  public popup                  : any;
  public warningsMap            : boolean = false;
  public location               : string;
  public warningsOpen           : boolean = true;
  public selectedWarningIndex   : number = 0;
  public languageIndex          : number;
  public currentTime            : string;
  public response               : any;
  public severity               : string;
  public startDate              : string;
  public endDate                : string;
  public startTime              : string;
  public endTime                : string;
  public status                 : string;

  private station               : IStation;
  private previousStationID     : string;
  private previousMapId         : string;
  private language              : string;
  private destroy$              : Subject<boolean> = new Subject<boolean>();

  @ViewChild('mapContainer')
  public mapContainer: ElementRef;

  constructor(
    private navigationStationStore    : Store<INavigationStationState>,
    private modal                     : ModalService,
    private mapService                : MapService,
    private router                    : Router,
    private cdr                       : ChangeDetectorRef,
    private http                      : HttpClient
  ) { }

  public ngAfterViewChecked(): void {

    this.navigationStationStore.pipe(
      select(selectNavigationStation),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe((station: IStation) => {
      if (station.name.original !== this.previousStationID) {
        this.station = station;

        // @ts-ignore
        if (this.marker) {
          this.marker.remove();
        }

        if (!this.map) {                                                        // if no map
          // build map
          const htmlElement = this.mapContainer.nativeElement as HTMLElement;
          this.map = this.mapService.buildMap(station, htmlElement);

          this.map.flyTo({center: station.position.geo.coordinates, zoom: 10, speed: 10});      // re-centering

          this.map.on('load', () => {
            this.map.resize();                                                  // forces map to resize
            // @ts-ignore
            this.plugin = this.mapService.addPlugin(station, this.map);         // add meteoblue plugin
            // @ts-ignore
            this.plugin.showTimeSelection();                                    // show timeSelection bar on load
            // @ts-ignore
            this.marker = new window.mapboxgl.Marker({                          // add marker
              color: '#FF0000',
              draggable: false,
            })
              .setLngLat(station.position.geo.coordinates)
              .addTo(this.map);
          });
        } else {                                                               // if map already exists
          // the plugin needs to be re-initialised for each selection, which means that the page needs to be reloaded => reload the page
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate([`/station/${station.name.original}/weather-forecast/precipitation-radar`]);

          // remove the map and plugin
          this.map.remove();
          this.map = null;
          // @ts-ignore
          this.plugin = null;
        }
        this.previousStationID = station.name.original;
      }
    });

    // @ts-ignore
    if (this.plugin) {
      // @ts-ignore
      this.plugin.on('menuchange', (e) => {
        this.warningsMap = e.map.id === 'warningsAndRisk';
        if (this.previousMapId !== e.map.id) {
          if (this.warningsMap) {
            this.warningsOpen = true;
            const url = `https://my.meteoblue.com/warnings/list?` +
              'apikey=d6325d692702&' +
              `lat=${this.station.position.geo.coordinates[1].toFixed(6)}&` +
              `lon=${this.station.position.geo.coordinates[0].toFixed(6)}`;
            this.http.get(url).subscribe(res => {
              this.response = res;
              this.getData();
            });
          }
        }
        this.previousMapId = e.map.id;
      });
    }

    this.cdr.detectChanges();
  }

  public onClose(): void {
    this.warningsOpen = false;
  }

  private getData(): void {
    this.currentTime = (new Date().toLocaleTimeString([], {
      timeZone: this.station.position.timezoneCode
    })).slice(0, -3);
    const currentDateTime = new Date().toLocaleString(['en-US'], {
      timeZone: this.station.position.timezoneCode,
    });

    if (this.response.results_count > 0) {
      const options = LOCALE_OPTIONS;
      options['timeZone'] = this.station.position.timezoneCode;

      const startDate = new Date(this.response.results[this.selectedWarningIndex].starts + '.000Z').toLocaleString(['en-US'], options);
      const splittedStartDate = startDate.split(',').map(str => str.trim());
      const endDate = new Date(this.response.results[this.selectedWarningIndex].expires + '.000Z').toLocaleString(['en-US'], options);
      const splittedEndDate = endDate.split(',').map(str => str.trim());
      const startDay = splittedStartDate[0];
      const endDay = splittedEndDate[0];

      this.severity = this.getSeverity();
      this.languageIndex = this.getLanguageIndex();

      const newStartDate = moment(startDate).format( 'YYYY-MM-DD HH:mm:ss');
      const newEndDate = moment(endDate).format( 'YYYY-MM-DD HH:mm:ss');
      const currentDate = moment(currentDateTime).format( 'YYYY-MM-DD HH:mm:ss');

      const checkStartDate = this.checkIfTodayTomorrowYesterday(newStartDate, currentDate);
      const checkEndDate = this.checkIfTodayTomorrowYesterday(newEndDate, currentDate);
      this.startDate = checkStartDate ? checkStartDate : startDay;
      this.endDate = checkEndDate ? checkEndDate : endDay;
      this.startTime = splittedStartDate[2];
      this.endTime = splittedEndDate[2];

      this.status = this.getStatus(newStartDate, currentDate);
    }
  }

  public onForward(): void {
    if (this.selectedWarningIndex < this.response.results_count - 1) {
      this.selectedWarningIndex++;
      this.getData();
    }
  }

  public onBackward(): void {
    if (this.selectedWarningIndex > 0) {
      this.selectedWarningIndex--;
      this.getData();
    }
  }

  private checkIfTodayTomorrowYesterday(d, currentDate): string {
    if (moment(d).isSame(moment(currentDate), 'day')) {
      return 'Today';
    } else if (moment(d).isSame(moment(currentDate).add(1, 'days'), 'day')) {
      return 'Tomorrow';
    } else if (moment(d).isSame(moment(currentDate).subtract(1, 'days'), 'day')) {
      return 'Yesterday';
    }
    return null;
  }

  private getStatus(start, current): string {
    return start <= current ? 'Now' : 'Upcoming';
  }

  private getSeverity(): string {
    return SEVERITIES[this.response.results[this.selectedWarningIndex].severity];
  }

  private getLanguageIndex(): number {
    let languageIndex = 0;
    this.response.results[this.selectedWarningIndex].info.forEach((language, i) => {
      if (language.language !== 'en-GB') {
        languageIndex = i;
      }
    });
    return languageIndex;
  }

  public openModal(): void {
    this.modal.openModal(this.modalId);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
