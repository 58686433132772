import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sensor-confirm-modal',
  templateUrl: './sensor-confirm-modal.component.html',
  styleUrls: ['./sensor-confirm-modal.component.scss']
})
export class SensorConfirmModalComponent {
  @Output()
  private close       : EventEmitter<void> = new EventEmitter<void>();
  @Output()
  private reset       : EventEmitter<void> = new EventEmitter<void>();

  public resetEmit(): void {
    this.reset.emit();
  }

  public closeEmit(): void {
    this.close.emit();
  }
}
