import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-ultrasonic-distance-settings-content',
  templateUrl: './ultrasonic-distance-settings-content.component.html',
  styleUrls: ['./ultrasonic-distance-settings-content.component.scss']
})
export class UltrasonicDistanceSettingsContentComponent implements OnInit, OnDestroy {

  private destroy$                  : Subject<boolean> = new Subject<boolean>();

  constructor( ) { }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
