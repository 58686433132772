import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sensors-loading',
  templateUrl: './sensors-loading.component.html'
})
export class SensorsLoadingComponent {
  @Input()
  public isError      : boolean;
  @Input()
  public isLoading    : boolean;
}
