<div class="page-without-left-tree padding-left15">
  <div class="cropzone-page-header">
    <h2 class="cropzone-page-header__name">{{ 'Cropzone Management' | translate }}</h2>
  </div>
  <div style="background-color: white; padding: 15px">

    <div class="row" style="margin-bottom: 15px;">
      <div class="col-md-4">
        <app-custom-button style="width: 100%; max-width: none !important;"
          [customCSS]="{ 'width': '100%', 'max-width': '100%' }" (click)="openAddCropzoneFieldFarmModal()"
          [content]="'+ Cropzone/Field/Farm'">
        </app-custom-button>
      </div>
      <div class="col-md-4">
        <app-search-input [placeholder]="'Search Input'" [formControl]="searchTermControl" (focusin)="focus$.next(true)"
          (focusout)="focus$.next(false)">
        </app-search-input>
      </div>
      <div class="col-md-4">
        <app-custom-button style="width: 100%; max-width: none !important;"
          [customCSS]="{ 'width': '100%', 'max-width': '100%' }"
          [content]="(showFinished$ | async) ? ('Hide inactive cropzones' | translate) : ('Show inactive cropzones' | translate)"
          [color]="(showFinished$ | async) ? 'orange' : 'green'" (click)="toggleShowFinishedCropzones()">
        </app-custom-button>
      </div>
    </div>

    <form [formGroup]="myForm">
    <ng-container *ngFor="let farmItem of (management$ | async); let firstFarm=first">
      <table style="width: 100%; margin-bottom: 15px;" (mouseleave)="exitHover()">
        <thead *ngIf="firstFarm">
          <!-- <tr> -->
            <td style="width: 14%; padding: 0px 15px;"></td>
            <td style="width: 14%; padding: 0px 15px;"></td>
            <td style="width: 14%; padding: 0px 15px;"></td>
            <td style="width: 8%; padding: 0px 15px;"></td>
            <td [attr.colspan]="2"  style="width: 26%; padding: 0px 15px; vertical-align: bottom;">
              <div>
                <span>{{ 'CULTIVATION PERIOD' | translate }}</span>
              </div>

            </td>
            <td [attr.colspan]="2" style="width: 14%; padding: 0px 15px; vertical-align: bottom;">
              <div>
                <span>{{ 'SUBSCRIPTION' | translate }}</span>
              </div>

            </td>
            <td style="width: 10%; vertical-align: bottom; padding: 0px !important" [ngClass]="eligibleClass">
              <div>
                <span>{{ 'RENEW' | translate }}</span>
              </div>

            </td>
          <!-- </tr> -->
        </thead>
        <thead *ngIf="!firstFarm">
          <td style="width: 14%; padding: 0px 15px;"></td>
            <td style="width: 14%; padding: 0px 15px;"></td>
            <td style="width: 14%; padding: 0px 15px;"></td>
            <td style="width: 8%; padding: 0px 15px;"></td>
            <td [attr.colspan]="2"  style="width: 26%;">
              <div>
              </div>

            </td>
            <td [attr.colspan]="2" style="width: 14%;">
              <div>
              </div>

            </td>
            <td style="width: 10%;"[ngClass]="eligibleClass">
              <div>
              </div>
            </td>
        </thead>
        <thead *ngIf="firstFarm">
          <td *ngFor="let header of headers" style="padding: 0px 15px;">
            <div>
              <span>{{header.name}}</span>
              <div *ngIf="header.hasButton" style="display: flex; align-items: center;">
                <button style="background: white; border: white; display: flex; padding-bottom: 4px;" (click)="sort(header.name)">
                  <i
                    [ngClass]="getIconClass(header.name) === 'ascending' ? 'ag-icon ag-icon-asc'
                    : getIconClass(header.name) === 'descending' ? 'ag-icon ag-icon-desc'
                    : 'ag-icon ag-icon-none'">
                  </i>
                </button>
              </div>
            </div>
          </td>
        </thead>
        <tbody>
          <ng-container *ngIf="farmItem.fields.length > 0">
            <ng-container *ngFor="let fieldItem of farmItem.fields; let firstField=first">

              <ng-container *ngIf="fieldItem.cropzones.length > 0">

                <ng-container *ngFor="let cropzoneItem of fieldItem.cropzones; let firstCrop=first; let i = index;">

                  <tr [ngClass]="isRecentlyDuplicated(cropzoneItem) ? 'duplicated' : ''" (mouseover)="startHover(fieldItem.field, i)">
                    <td>
                      <div style="display: flex; flex-direction: row; gap: 3px; width: 100%; align-items: center;">
                        <div>
                          <button *ngIf="firstCrop && firstField" (click)="openRemoveFarmConfirmationModal(farmItem)"><i
                            class="fa fa-trash"></i></button>
                        </div>
                        <div>
                          <button *ngIf="firstCrop && firstField && !farmItem.farm.isEditEnabled"
                          style="display: flex;"
                          (click)="openTest(farmItem)"><i class="zmdi zmdi-edit"></i></button>
                        </div>
                        <div style="display: flex; overflow: hidden; align-items: center;" [ngClass]="isRecentlyDuplicated(cropzoneItem) ? 'recentlyDuplicatedDiv' : 'spanDiv'">
                          <span class="spanClass" *ngIf="!farmItem.farm.isEditEnabled">
                            {{ firstCrop && firstField ? farmItem.farm.name   : '' }}</span>
                            <input *ngIf="firstCrop && firstField && farmItem.farm.isEditEnabled"
                              [formControl]="itemToEditFormControl" class="inputLineHeight">
                            <button *ngIf="firstCrop && firstField && farmItem.farm.isEditEnabled" (click)="back()"
                              style="display: flex;"
                              class="closeButton"><b><i class="zmdi zmdi-close closeCheckIcon"></i></b></button>
                            <button *ngIf="firstCrop && firstField && farmItem.farm.isEditEnabled"
                              (click)="submitFarmName(farmItem)" class="checkButton"><i
                                class="zmdi zmdi-check closeCheckIcon"></i></button>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div style="display: flex; flex-direction: row; gap: 3px; width: 100%; align-items: center;">
                        <div>
                          <button *ngIf="firstCrop" (click)="openRemoveFieldConfirmationModal(fieldItem)"><i
                            class="fa fa-trash"></i></button>
                        </div>
                        <div>
                          <button *ngIf="firstCrop && !fieldItem.field.isEditEnabled" (click)="openTest(fieldItem)"><i
                            class="zmdi zmdi-edit "></i></button>
                        </div>
                        <div style="display: flex; overflow: hidden; align-items: center;" [ngClass]="isRecentlyDuplicated(cropzoneItem) ? 'recentlyDuplicatedDiv' : 'spanDiv'">
                          <span class="spanClass" *ngIf="!fieldItem.field.isEditEnabled">
                            {{ firstCrop ? fieldItem.field.name   : '' }}
                          </span>
                          <input *ngIf="firstCrop && fieldItem.field.isEditEnabled" [formControl]="itemToEditFormControl"
                            class="inputLineHeight">
                          <button *ngIf="firstCrop && fieldItem.field.isEditEnabled" (click)="back()" class="closeButton"><i
                              class="zmdi zmdi-close closeCheckIcon"></i></button>
                          <button *ngIf="firstCrop && fieldItem.field.isEditEnabled" (click)="submitFieldName(fieldItem)"
                            style="display: flex;"
                            class="checkButton"><i class="zmdi zmdi-check closeCheckIcon"></i></button>
                        </div>
                      </div>
                    </td>
                    <td *ngIf="cropzoneItem">
                      <div style="display: flex; flex-direction: row; gap: 3px; width: 100%; align-items: center;">
                        <div>
                          <button (click)="openRemoveCropzoneConfirmationModal(cropzoneItem)" style="display: flex;"><i class="fa fa-trash"></i></button>
                        </div>
                        <div>
                          <button *ngIf="!cropzoneItem.isEditEnabled" (click)="openTest(cropzoneItem)"><i
                            class="zmdi zmdi-edit "></i></button>
                        </div>
                        <div style="display: flex; overflow: hidden; align-items: center;" [ngClass]="isRecentlyDuplicated(cropzoneItem) ? 'recentlyDuplicatedDiv' : 'spanDiv'">
                          <span (click)="goToCropzoneConfig(cropzoneItem)" *ngIf="!cropzoneItem.isEditEnabled"
                            class="cropzoneItemSpan spanClass">{{ cropzoneItem.name }}
                          </span>
                          <input *ngIf="cropzoneItem.isEditEnabled" [formControl]="itemToEditFormControl"
                            class="inputLineHeight">
                          <button *ngIf="cropzoneItem.isEditEnabled" (click)="back()" class="closeButton"><i
                              class="zmdi zmdi-close closeCheckIcon"></i></button>
                          <button *ngIf="cropzoneItem.isEditEnabled" (click)="submitCropzoneName(cropzoneItem)"
                            style="display: flex;"
                            class="checkButton"><i class="zmdi zmdi-check closeCheckIcon"></i></button>
                        </div>
                      </div>

                    </td>
                    <td>
                      <div>
                        <span>{{ cropzoneItem.crop_name ? cropzoneItem.crop_name : '' }}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{{ cropzoneItem.from ? cropzoneItem.from : '' }}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{{ cropzoneItem.to ? cropzoneItem.to : '' }}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{{ cropzoneItem.license_start_date ? cropzoneItem.license_start_date : '' }}</span>
                      </div>
                    </td>
                    <td>
                      <div [ngClass]="!cropzoneItem.isActiveLicense ? 'expired' : ''">
                        <span *ngIf="!cropzoneItem.isActiveLicense" style="color: red;">{{ cropzoneItem.license_end_date ? 'Expired' : '' }}</span><br *ngIf="!cropzoneItem.isActiveLicense">
                        <span>{{ cropzoneItem.license_end_date ? cropzoneItem.license_end_date : '' }}</span>  
                      </div>
                    </td>
                    <td style="text-align: center; vertical-align: middle" [ngClass]="eligibleClass">
                      <div class="checkboxContainer">
                        <input *ngIf="!cropzoneItem.isActiveLicense" type="checkbox" class="checkbox" [value]="cropzoneItem.id" (click)="onCheckChange($event)">
                        <div *ngIf="cropzoneItem.isActiveLicense && cropzoneItem.aboutToExpire">
                          <i class="fa fa-warning" (mouseenter)="switchTooltipVisible()" (mouseleave)="switchTooltipVisible()"></i>
                          <span *ngIf="showTooltip && cropzoneItem.daysUntilExpired !== 1 && hoveredField === cropzoneItem.field.id && hoveredIndex === i">
                            {{ 'Note: your license will expire in' | translate }} {{ cropzoneItem.daysUntilExpired }} {{ 'days. To renew it, please contact your METOS salesperson' | translate }}
                          </span>
                          <span *ngIf="showTooltip && cropzoneItem.daysUntilExpired === 1 && hoveredField === cropzoneItem.field.id && hoveredIndex === i">
                            {{ 'Note: your license will expire in' | translate }} {{ cropzoneItem.daysUntilExpired }} {{ 'day. To renew it, please contact your METOS salesperson' | translate }}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="fieldItem.cropzones.length === 0">
                <tr>
                  <td>
                    <div style="display: flex; flex-direction: row; gap: 3px; width: 100%; align-items: center;">
                      <div>
                        <button *ngIf="firstField" (click)="openRemoveFarmConfirmationModal(farmItem)" style="display: flex;"><i class="fa fa-trash"></i></button>
                      </div>
                      <div>
                        <button *ngIf="firstField && !farmItem.farm.isEditEnabled" (click)="openTest(farmItem)"><i
                          class="zmdi zmdi-edit "></i></button>
                      </div>
                      <div style="display: flex; overflow: hidden; align-items: center;" class="spanDiv">
                        <span class="spanClass" *ngIf="!farmItem.farm.isEditEnabled">{{ firstField ? farmItem.farm.name   : '' }}</span>
                        <input *ngIf="firstField && farmItem.farm.isEditEnabled" [formControl]="itemToEditFormControl"
                          class="inputLineHeight">
                        <button *ngIf="firstField && farmItem.farm.isEditEnabled" (click)="back()" class="closeButton"><i
                            class="zmdi zmdi-close closeCheckIcon"></i></button>
                        <button *ngIf="firstField && farmItem.farm.isEditEnabled" (click)="submitFarmName(farmItem)"
                          style="display: flex;"
                          class="checkButton"><i class="zmdi zmdi-check closeCheckIcon"></i></button>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div style="display: flex; flex-direction: row; gap: 3px; width: 100%; align-items: center;">
                      <div>
                        <button (click)="openRemoveFieldConfirmationModal(fieldItem)" style="display: flex;"><i class="fa fa-trash"></i></button>
                      </div>
                      <div>
                        <button *ngIf="!fieldItem.field.isEditEnabled" (click)="openTest(fieldItem)"><i
                          class="zmdi zmdi-edit "></i></button>
                      </div>
                      <div style="display: flex; overflow: hidden; align-items: center;" class="spanDiv">
                        <span class="spanClass" *ngIf="!fieldItem.field.isEditEnabled">{{ fieldItem.field.name }}</span>
                        <input *ngIf="fieldItem.field.isEditEnabled" [formControl]="itemToEditFormControl"
                          class="inputLineHeight">
                        <button *ngIf="fieldItem.field.isEditEnabled" (click)="back()" class="closeButton"><i
                            class="zmdi zmdi-close closeCheckIcon"></i></button>
                        <button *ngIf="fieldItem.field.isEditEnabled" (click)="submitFieldName(fieldItem)"
                          style="display: flex;"
                          class="checkButton"><i class="zmdi zmdi-check closeCheckIcon"></i></button>
                      </div>
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td [ngClass]="eligibleClass"></td>
                </tr>
              </ng-container>

            </ng-container>
          </ng-container>
          <ng-container *ngIf="farmItem.fields.length === 0">
            <tr>
              <td>
                <div style="display: flex; flex-direction: row; gap: 3px; width: 100%; align-items: center;">
                  <div>
                    <button (click)="openRemoveFarmConfirmationModal(farmItem)" style="display: flex;"><i class="fa fa-trash"></i></button>
                  </div>
                  <div>
                    <button *ngIf="!farmItem.farm.isEditEnabled" (click)="openTest(farmItem)"><i
                      class="zmdi zmdi-edit "></i></button>
                  </div>
                  <div style="display: flex; overflow: hidden; align-items: center;" class="spanDiv">
                    <span class="spanClass" *ngIf="!farmItem.farm.isEditEnabled">{{ farmItem.farm.name }}</span>
                    <input *ngIf="farmItem.farm.isEditEnabled" [formControl]="itemToEditFormControl"
                      class="inputLineHeight">
                    <button *ngIf="farmItem.farm.isEditEnabled" (click)="back()" class="closeButton"><i
                        class="zmdi zmdi-close closeCheckIcon"></i></button>
                    <button *ngIf="farmItem.farm.isEditEnabled" (click)="submitFarmName(farmItem)" class="checkButton"><i
                        class="zmdi zmdi-check closeCheckIcon"></i></button>
                  </div>
                </div>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td [ngClass]="eligibleClass"></td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ng-container>
    <div style="display: flex; flex-direction: row-reverse" *ngIf="(management$ | async) && (eligibleClass === '')">
      <div>
        <div style="margin-right: 10px; display: inline-block;"><app-custom-button [content]="selectContent" [color]="'blue'" (click)="selectAll(selectContent)"></app-custom-button></div>
        <div style="margin-right: 10px; display: inline-block;"><app-custom-button [disabled]="isDisabled()" [content]="'Renew'" [color]="'green'" (click)="renewCropzones()"></app-custom-button></div>
        <div style="display: inline-block;"><app-custom-button [content]="'Cancel'" [color]="'red'" (click)="cancelSelect()" [disabled]="isDisabled()"></app-custom-button></div>
      </div>
    </div>
    </form>
  </div>
</div>

<app-modal [modalId]="REMOVE_FARM_CONFIRMATION" size="sm">
  <div *ngIf="farmToDelete" class="modalContainer">
    <div class="alert alert-danger alertContainer">
      {{ 'You are about to delete the following farm:' | translate }} {{ farmToDelete.name }}.
      {{ 'All cropzones and fields from this farm will also be deleted. Do you still want to delete this farm?' | translate }}
    </div>
    <div class="textAlignRight">
      <app-custom-button [content]="'Back'" [color]="'green'"
        (click)="closeRemoveConfirmationModal(REMOVE_FARM_CONFIRMATION)" class="backButtonModal"></app-custom-button>
      <app-custom-button [content]="'Delete'" [color]="'red'" (click)="deleteFarm()"></app-custom-button>
    </div>
  </div>
</app-modal>

<app-modal [modalId]="REMOVE_FIELD_CONFIRMATION" size="sm">
  <div *ngIf="fieldToDelete" class="modalContainer">
    <div class="alert alert-danger alertContainer">
      {{ 'You are about to delete the following field:' | translate }} {{ fieldToDelete.name }}.
      {{ 'All cropzones from this field will also be deleted. Do you still want to delete this field?' | translate }}
    </div>
    <div class="textAlignRight">
      <app-custom-button [content]="'Back'" [color]="'green'"
        (click)="closeRemoveConfirmationModal(REMOVE_FIELD_CONFIRMATION)" class="backButtonModal"></app-custom-button>
      <app-custom-button [content]="'Delete'" [color]="'red'" (click)="deleteField()"></app-custom-button>
    </div>
  </div>
</app-modal>

<app-modal [modalId]="REMOVE_CROPZONE_CONFIRMATION" size="sm">
  <div *ngIf="cropzoneToDelete" class="modalContainer">
    <div class="alert alert-danger alertContainer">
      {{ 'You are about to delete the following cropzone:' | translate }} {{ cropzoneToDelete.name }}.
    </div>
    <div class="textAlignRight">
      <app-custom-button [content]="'Back'" [color]="'green'"
        (click)="closeRemoveConfirmationModal(REMOVE_CROPZONE_CONFIRMATION)" class="backButtonModal"></app-custom-button>
      <app-custom-button [content]="'Delete'" [color]="'red'" (click)="deleteCropzone(cropzoneToDelete)"></app-custom-button>
    </div>
  </div>
</app-modal>

<app-modal [modalId]="addCropZoneModalId">
  <app-add-cropzone (closeModalEmitter)="closeModal($event)"></app-add-cropzone>
</app-modal>

<app-modal [outClick]="false" [modalId]="renewCropzonesId"  [size]="'bigger'">
  <app-renew-cropzone-modal *ngIf="cropzonesToRenew" [cropzones]="cropzonesToRenew" (emitCancel)="cancelRenewCropzones()"></app-renew-cropzone-modal>
</app-modal>
