import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import * as fromNavigationStation from '../../../../core/reducers/navigation-station';
import { IOptions } from '../../../../shared/interfaces';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';
import { dateToUtcUnixTimestamp } from '../../../../shared/utils/dateFormat';
import {
  DefaultPeriodScopeFrostProtection,
  PeriodValuesByScope
} from '../../../station-data/constants/constants';
import * as fromStationSettings from '../../../station-data/reducers/station-data-settings';
import {
  getFrostProtectionResponse, setFrostProtectionChartActive, setFrostProtectionSettings, setFrostProtectionTableActive
} from '../../actions/actions';
import { IFrostProtectionRequest, IFrostProtectionSettings, IFrostProtectionState } from '../../models/models';
import {
  selectFrostProtectionResponse,
  selectFrostProtectionSettings
} from '../../selectors/selectors';

@Component({
  selector: 'app-frost-protection-toolbar',
  templateUrl: './frost-protection-toolbar.component.html',
  styleUrls: ['./frost-protection-toolbar.component.scss']
})
export class FrostProtectionToolbarComponent implements OnInit, OnDestroy {

  @Output()
  private exportChartImg          : EventEmitter<boolean> = new EventEmitter<boolean>();
  private isDatepickerRequest     : boolean = false;
  private destroy$                : Subject<boolean> = new Subject<boolean>();

  public station                  : IStation;
  public periodForm               : FormGroup;
  public fromStation              : Date = null;
  public toStation                : Date = null;
  public periodScopeSelectItems   : Array<IOptions> = [{
    content : DefaultPeriodScopeFrostProtection,
    value   : DefaultPeriodScopeFrostProtection
  }];
  public periodValueSelectItems   : Array<IOptions> = PeriodValuesByScope[DefaultPeriodScopeFrostProtection];
  public nowTime                  : Date | number | string;
  public nowTimeString            : string | Date;

  constructor(
    private formBuilder           : FormBuilder,
    private exportService         : StationDataExportService,
    private navigationStore       : Store<fromNavigationStation.INavigationStationState>,
    private stationSettingsStore  : Store<fromStationSettings.IStationDataSettingsState>,
    private frostProtectionStore  : Store<IFrostProtectionState>
  ) { }

  public ngOnInit(): void {
    this.periodForm = this.formBuilder.group({
      'period': this.formBuilder.group({
        'periodScope': ['raw', [Validators.required]],
        'periodValue': ['2d', [Validators.required]],
        'fromTo': [null, [Validators.required]],
        'fromDatepicker': [null, [Validators.required]],
        'toDatepicker': [null, [Validators.required]],
        'stationId': ['', [Validators.required]],
        'isLastDataMode': [true, [Validators.required]]
      }),
      'activity': this.formBuilder.group({
        'isChartActive': [true, [Validators.required]],
        'isTableActive': [true, [Validators.required]],
        'isExportImageActive': [false, [Validators.required]],
        'isExportActive': [false, [Validators.required]],
      })
    });

    this.frostProtectionStore.pipe(
      takeUntil(this.destroy$),
      select(selectFrostProtectionSettings)
    ).subscribe((settings: IFrostProtectionSettings) => {
      if (settings) {
        this.periodForm.setValue({
          period: settings.period,
          activity: settings.activity
        });
      }
    });

    this.periodValue.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged()
    ).subscribe(value => {
      this.setDateRange();
    });

    this.navigationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((s: IStation) => !!s)
    ).subscribe((station: IStation) => {
      this.stationId.setValue(station.name.original);
      this.fromStation = moment(station.dates.min_date).toDate();
      this.toStation = moment(station.dates.max_date).toDate();

      setTimeout(() => {
        if (!this.station ||  station.name.original !== this.station.name.original) {
          this.fromDatepicker.setValue(moment(this.toStation).subtract(2, 'day').toDate());
          this.toDatepicker.setValue(moment(this.toStation).toDate());
          this.setDateRange();
        }
        this.refresh();
        this.station = station;
      });
    });

    // set chart active
    this.isChartActive.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      debounceTime(10)
    ).subscribe((isActive: boolean) => {
      this.frostProtectionStore.dispatch(setFrostProtectionChartActive(isActive));
    });

    // set table active
    this.isTableActive.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      debounceTime(10)
    ).subscribe((isActive: boolean) => {
      this.frostProtectionStore.dispatch(setFrostProtectionTableActive(isActive));
    });

    // if new response, get the response and now time
    this.frostProtectionStore.pipe(
      select(selectFrostProtectionResponse),
      takeUntil(this.destroy$)
    ).subscribe((response: any) => {
      if (response) {
        this.nowTime = response.info.now * 1000;
        this.nowTimeString = moment(response.info.now * 1000).toDate();
        this.nowTimeString = moment(this.nowTime).format('DD/MM/YYYY').toString();
      }
    });
  }

  public refresh(): void {
    if (this.periodForm.invalid) {
      return;
    }
    this.isDatepickerRequest = !this.isLastDataMode.value;

    const request: IFrostProtectionRequest = {
      stationId: this.stationId.value,
      timePeriod: 'raw',
      from: null,
      to: null,
    };

    if (this.isLastDataMode.value) {
      request.from = dateToUtcUnixTimestamp(this.fromTo.value.from);
      request.to = dateToUtcUnixTimestamp(this.fromTo.value.to);
    } else {
      request.from = dateToUtcUnixTimestamp(this.fromDatepicker.value);
      request.to = dateToUtcUnixTimestamp(moment(this.nowTime).toDate());
    }

    this.frostProtectionStore.dispatch(setFrostProtectionSettings(this.periodForm.value));
    this.frostProtectionStore.dispatch(getFrostProtectionResponse(request));
  }

  public setDateRange(): void {
    if (this.isDatepickerRequest && !this.isLastDataMode.value) {
      this.fromTo.setValue({
        from: this.fromDatepicker.value,
        to: this.toDatepicker.value
      });
    } else {
      switch (this.periodValue.value) {
        case '24h': {
          this.setToValue(24, 'h');
          return;
        }
        case '2d': {
          this.setToValue(2, 'day');
          return;
        }
        case '7d': {
          this.setToValue(7, 'day');
          return;
        }
        case '10d': {
          this.setToValue(10, 'day');
          return;
        }
        case '14d': {
          this.setToValue(14, 'day');
          return;
        }
        case '30d': {
          this.setToValue(30, 'day');
          return;
        }
        default: {
          return;
        }
      }
    }

    this.refresh();
  }

  private setToValue(amount, unit): void {
    this.fromTo.setValue({
      from: moment(this.toStation).subtract(amount, unit).toDate(),
      to: this.toStation
    });
  }

  private get period(): AbstractControl {
    return this.periodForm.get('period');
  }

  private get activity(): AbstractControl {
    return this.periodForm.get('activity');
  }

  public get fromDatepicker(): AbstractControl {
    return this.period.get('fromDatepicker');
  }

  public get toDatepicker(): AbstractControl {
    return this.period.get('toDatepicker');
  }

  public get isTableActive(): AbstractControl {
    return this.activity.get('isTableActive');
  }

  public get isChartActive(): AbstractControl {
    return this.activity.get('isChartActive');
  }

  public get isLastDataMode(): AbstractControl {
    return this.period.get('isLastDataMode');
  }

  public get periodScope(): AbstractControl {
    return this.period.get('periodScope');
  }

  public get periodValue(): AbstractControl {
    return this.period.get('periodValue');
  }

  public get fromTo(): AbstractControl {
    return this.period.get('fromTo');
  }

  public get stationId(): AbstractControl {
    return this.period.get('stationId');
  }

  public get isExportImageActive(): AbstractControl {
    return this.activity.get('isExportImageActive');
  }

  public get isExportActive(): AbstractControl {
    return this.activity.get('isExportActive');
  }

  // export table
  public startExportXLS(): void {
    this.exportService.startExportXLS(this.stationId.value);
  }

  // export charts as image
  public startExportImage(): void {
    if (this.isExportImageActive.value) {
      return;
    } else {
      this.isExportImageActive.setValue(true);
    }

    this.exportChartImg.emit(true);
    setTimeout(() => {
      this.exportChartImg.emit(false);
      this.isExportImageActive.setValue(false);
    }, 500);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
