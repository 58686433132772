import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, takeWhile, map, filter } from 'rxjs/operators';
import { IStation } from '../../../../../../core/models/stations';
import { selectStations } from '../../../../../../core/reducers';
import * as fromStations from '../../../../../../core/reducers/stations';
import { IOptions } from '../../../../../../shared/interfaces';
import { WeatherForecast } from '../../../../constants/constants';
import { IForecastRequest } from '../../../../models/dashboard.models';
import { deepClone } from '../../../../../../shared/utils/deepClone';

@Component({
  selector: 'app-forecast-chart-settings',
  templateUrl: './forecast-chart-settings.component.html',
  styleUrls: ['./forecast-chart-settings.component.scss']
})
export class ForecastChartSettingsComponent implements OnInit, OnDestroy {

  @Input()
  private chartRequest: IForecastRequest;

  @Output()
  private updateRequest: EventEmitter<IForecastRequest> = new EventEmitter();

  @Output()
  private updateWidgetData: EventEmitter<void> = new EventEmitter();

  public allStations$: Observable<Array<IStation>>;
  public modeOptions: Array<IOptions>;
  private alive: boolean;
  public form: FormGroup;
  public currentMode = '';

  constructor(
    private stationsStore: Store<fromStations.IStations>,
    private formBuilder: FormBuilder
  ) {

  }

  public ngOnInit(): void {
    this.alive = true;
    this.modeOptions = WeatherForecast;
    this.allStations$ = this.stationsStore.pipe(
      select(selectStations),
      filter((stations: IStation[]) => !!stations && stations.length > 0),
      map((stations: IStation[]) => this.sortArray(stations))
    );
    this.initForm();
  }

  private sortArray(stationArray: IStation[]): IStation[] {
    const stations = deepClone(stationArray);
    const customNamedStations = stations.filter((station) => station.name.custom !== '');
    customNamedStations.sort((a, b) => {
      const nameA = a.name.custom.toLowerCase();
      const nameB = b.name.custom.toLowerCase();

      const isNumberA = !isNaN(Number(nameA));
      const isNumberB = !isNaN(Number(nameB));

      if (isNumberA && isNumberB) {
        return Number(nameA) - Number(nameB);
      } else if (isNumberA) {
        return -1;
      } else if (isNumberB) {
        return 1;
      } else {
        return nameA.localeCompare(nameB);
      }
    });

    const otherStations = stations.filter((station) => station.name.custom === '');
    otherStations.sort((a: IStation, b: IStation) => a.name.original.toLowerCase() < b.name.original.toLowerCase() ? -1 : 1);

    return customNamedStations.concat(otherStations);
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      'station': [this.chartRequest.endpoint.station],
      'profile': [this.chartRequest.endpoint.profile]
    });

    this.form.valueChanges.pipe(
      distinctUntilChanged(),
      takeWhile(() => this.alive)
    ).subscribe(() => this.changeRequest());
  }

  public changeRequest(): void {

    const values = this.form.value;

    const request = {
      endpoint: {
        station: values['station'],
        profile: values['profile']
      }
    };

    this.updateRequest.emit(request);
  }

  public submit(): void {
    this.updateWidgetData.emit();
  }

  public ngOnDestroy(): void {
    this.alive = false;
  }
}
