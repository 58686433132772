<app-chart-toolbar-layout>
  <form class="station-data-toolbar" (keydown.enter)="$event.preventDefault()" [formGroup]="periodForm">

    <div class="station-data-toolbar__left">
      <div class="station-data-toolbar__toggle station-data-toolbar__left__item">
        <app-custom-button color="dark-green"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'From - To mode' | translate"
                           [shadow]="false"
                           [radius]="'0 2px 2px 0'"
                           (click)="isLastDataMode.setValue(false)">
          <span class="fa fa-calendar"></span>
        </app-custom-button>
      </div>

      <ng-container>
        <app-custom-datepicker [isSmall]="true"
                               [minDate]="fromStation"
                               [maxDate]="toDatepicker.value"
                               [formControl]="fromDatepicker">
        </app-custom-datepicker>
        <app-custom-datepicker [isSmall]="true"
                               [minDate]="fromDatepicker.value"
                               class="station-data-toolbar__left__item"
                               [formControl]="toDatepicker">
        </app-custom-datepicker>
      </ng-container>
    </div>

    <div class="station-data-toolbar__right">
      <app-custom-button [color]="'red'"
                         class="station-data-toolbar__right__item"
                         (click)="refresh()"
                         [content]="'Refresh'">
        <i class="fa fa-fw fa-refresh"></i>
      </app-custom-button>
    </div>

  </form>
</app-chart-toolbar-layout>
