import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromSoilMoistureStations from './soilMoistureStations';
import * as fromNodes from './nodes';
import * as fromSensors from './sensors';

export interface INodeSoilMoisture {
    kind: string;
    node: any;
}

export interface IState {
    soilMoistureStations: fromSoilMoistureStations.ISoilMoistureStationsState;
    nodes: fromNodes.INodesState;
    sensors: fromSensors.ISensorsState;
}

export const reducers = {
    soilMoistureStations: fromSoilMoistureStations.reducer,
    nodes: fromNodes.reducer,
    sensors: fromSensors.reducer
};

export const soilMoistureConfig = createFeatureSelector<IState>('soil-moisture-config');

export const selectSoilMoistureStations = createSelector(
    soilMoistureConfig,
    (soilMoistureConfigState: IState) => soilMoistureConfigState.soilMoistureStations.soilMoistureStations
);

export const selectNodes = createSelector(
    soilMoistureConfig,
    (soilMoistureConfigState: IState) => soilMoistureConfigState.nodes.nodes
);

export const selectSensors = createSelector(
    soilMoistureConfig,
    (soilMoistureConfigState: IState) => {
        return soilMoistureConfigState.sensors.sensors;
    }
);

export const selectFilteredSensors = createSelector(
    soilMoistureConfig,
    (soilMoistureConfigState: IState) => soilMoistureConfigState.sensors.filteredSensors
);

export const selectFilteredSensorsByStationId = (stationId: string, node: INodeSoilMoisture) => createSelector(
    soilMoistureConfig,
    (soilMoistureConfigState: IState) => {
        // tslint:disable-next-line:max-line-length
        const sensorsFromStation: any = soilMoistureConfigState.sensors.filteredSensors.filter(sensor => sensor.stationId === stationId && sensor.node === node);
        if (sensorsFromStation.length > 0) {
            return sensorsFromStation[0].filteredSensors;
        } else {
            return [];
        }
    }
);

export const selectSensorListByStationId = (stationId: string, node: any, dataSource?: any) => createSelector(
    soilMoistureConfig,
    (soilMoistureConfigState: IState) => {
        const smSensors = soilMoistureConfigState.sensors.sensors;
        // tslint:disable
        if (!smSensors.some(element => element.stationId === stationId && element.node.node.toString() === node.node.toString())) {
            return [];
        } else {
            if (dataSource) {
                // tslint:disable
                const sensorArray: Array<any> = soilMoistureConfigState.sensors.sensors.filter(element => element.stationId === stationId && element.node.node.toString() === node.node.toString())[0].sensors;
                dataSource.source.layers.forEach(element => {
                    for (let i = 0; i < sensorArray.length; i++) {
                        if (sensorArray[i].ch === element.channel && sensorArray[i].code === element.code) {
                            sensorArray[i].refill_point = element.refill_point;
                            sensorArray[i].field_capacity = element.field_capacity;
                            sensorArray[i].depth = element.depth;
                            if (element.calibration_id) {
                                sensorArray[i].calibration_id = element.calibration_id;
                            }
                        }
                    }
                });
                return sensorArray;
            } else {
                const sensorArray: Array<any> = soilMoistureConfigState.sensors.sensors.filter(element => element.stationId === stationId && element.node.node.toString() === node.node.toString())[0].sensors;
                return sensorArray;
            }
        }
    }
);

export const selectNodesByStationId = (stationId: string) => createSelector(
    soilMoistureConfig,
    (soilMoistureConfigState: IState) => {
        const array: Array<any> = [];
        const nodeFromStation: fromNodes.INodeObject = soilMoistureConfigState.nodes.nodes.filter(element => element.stationId === stationId)[0];
        if (nodeFromStation !== undefined) {
            nodeFromStation.nodesArray.forEach(element => {
                array.push(element);
            });
        }

        return array;
    }
);
