<app-form-layout class="led-trap-settings">
  <app-card-header
    [helpButton]="true"
    [header]="'LED trap settings'"
    [description]="'Setup when LED rings are active'"
    (openModal)="openHelpModal()"
  ></app-card-header>

  <div class="led-trap-settings__content">
    <app-station-led-trap-settings
      [temperatureThreshold]="temperatureThreshold"
      (changeTemperatureThreshold)="changeTemperatureThreshold($event)"
    ></app-station-led-trap-settings>

    <app-station-led-trap-scheduler
      [scheduler]="schedulerMatrix"
      (changeScheduler)="changeScheduler($event)"
    ></app-station-led-trap-scheduler>
  </div>

  <div class="led-trap-settings__save-area">
    <hr class="led-trap-settings__save-area__hr">

    <div class="led-trap-settings__save-area__content">
      <app-custom-button
        class="led-trap-settings__save-area__button"
        [color]="'red'"
        [content]="'Save configuration'"
        (click)="save()"
      ></app-custom-button>
    </div>
  </div>
</app-form-layout>

<app-info-modal [modalId]="modalId" [header]="'LED trap settings help'">
  <p>
    {{
    'In Scheduler you can select the hours at which you wish your iMETOS iSCOUT station to activate the LED rings. ' +
    'As long as there are no changes saved, the device will work with the default factory settings: it ' +
    'will activate the LED ring at 20:00, 21:00 and 22:00.' | translate
    }}
  </p>

  <p>
    {{
    'When you finish the configuration, do not forget to save the new settings (click on "SAVE CONFIGURATION" ' +
    'button).' | translate
    }}
  </p>
</app-info-modal>
