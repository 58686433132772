import { Injectable } from '@angular/core';
import { DEFAULT_SENSOR_GROUPS } from '../constants/my-john-deere';
import { IAsset, IJDOrganization } from '../models/my-john-deere';
import { IStation } from '../../../core/models/stations';
import { ISensor, ISystemSensor } from '../../../shared/interfaces';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MyJohnDeereService {

  constructor(
    private translate: TranslateService
  ) {

  }


  public getAssetConfiguration(
    station: IStation,
    sensors: ISensor[],
    systemSensors: { [index: number]: ISystemSensor },
    organization: IJDOrganization
  ): Observable<any> {
    return from(sensors).pipe(
      mergeMap(sensor =>
        this.translate.get(sensor.name).pipe(
          map(name => (sensor.name = <string>name) && sensor)
        )
      ),
      toArray()
    ).pipe(
      map(data =>
        data.filter((sensor: ISensor) => sensor.isActive && DEFAULT_SENSOR_GROUPS.indexOf(sensor.group) >= 0)
          .sort((a: ISensor, b: ISensor) => DEFAULT_SENSOR_GROUPS.indexOf(a.group) - DEFAULT_SENSOR_GROUPS.indexOf(b.group))
          .reduce((config: IAsset, sensor: ISensor) => {
            if (!config.sensors.some((s) => s.group === sensor.group)) {
              config.sensors.push({
                ch: sensor.ch,
                code: sensor.code,
                unit: sensor.unit ? sensor.unit : '',
                name: sensor.name,
                custom_name: sensor.name_custom,
                group: sensor.group
              });
            }

            return config;
          }, {
            asset: '',
            station: station.name.original,
            organization: organization.id,
            sensors: []
          } as IAsset)
      )
    );
  }
}
