import { IDisplayRulesConfig } from '../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../environments/interfaces/theme';

export const themeConfig: IThemeConfig = {
  subDomain: 'vodafone-idea',
  language: 'en',
  headerClass: 'white',
  src: '../../../assets/themes/vodafone-idea/assets/images/logo.png',
  mainSrc: '../../../assets/themes/vodafone-idea/assets/images/landing.png',
  width: '700',
  mainWidth: '60',
  mainMTop: '12',
  mainLeft: '15',
  loginLeft: '15',
  registerBottom: '10',
  resetLeft: '20',
  resetBottom: '10',
  name: 'VodafoneIdea SmartAgri',
  profileMenuSrc: '../../../assets/themes/default/assets/images/profile.png',
  faviconSrc: '../../../assets/themes/default/assets/favicon.ico',
  colorConfig: {
      '--search-widget-border': '#eb9700',
      '--search-widget-label': '#e60000',
      '--search-widget-label-dark-green': '#e60000',
      '--search-widget-label-orange': '#f39224',
      '--search-widget-button': '#e40000DD',
      '--main-static': '#e60000',
      '--main-static-orange': '#f39224',
      '--form-button': '#e60000',
      '--form-button-action': '#4a4d4e',
    }
};

export const displayRulesConfig: IDisplayRulesConfig = {
  userMenu: true,
  farmViewMenu: true,
  leftBarMenu: {
    stationData: true,
    cropView: true,
    iScout: true,
    weatherForecast: true,
    workPlanning: true,
    animalProduction: true,
    accumulatorTool: true,
    frostProtection: true,
    diseaseModels: true,
    soilMoisture: true,
    hydroponics: true,
    tracker: true,
    soilGuard: true,
    settings: true,
  },
};
