import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IStation } from '../../core/models/stations';
import { selectNavigationStation, selectStations } from '../../core/reducers';
import { ISelectedStationState } from '../../core/reducers/selectedStation';
import * as fromStations from '../../core/reducers/stations';
import { StationTopologyLinkType } from '../../modules/station-config/constants/constants';
import { StationTypes } from '../constants';

@Component({
  selector: 'app-camera-topology',
  templateUrl: './camera-topology.component.html',
  styleUrls: ['./camera-topology.component.scss']
})
export class CameraTopologyComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public linkType: string;

  public selectedStation : IStation;
  public userStations    : IStation[];
  public camera1         : IStation;
  public camera2         : IStation;
  public controlUnit     : IStation;

  public stations$       : Observable<any>;
  private destroy$       : Subject<boolean> = new Subject<boolean>();

  public constructor(private stationsStore: Store<fromStations.IStations>,
                     private selectedStationStore: Store<ISelectedStationState>,
                     private router: Router) {
  }

  public ngOnInit(): void {
    this.stations$ = combineLatest([
      this.stationsStore.pipe(select(selectStations)),
      this.selectedStationStore.pipe(select(selectNavigationStation))
    ]).pipe(tap(([userStations, selectedStation]: Array<IStation | IStation[]>) => {
      this.selectedStation = <IStation>selectedStation;
      this.userStations = <IStation[]>userStations;
      this.initDeviceTopology(this.userStations);
    }));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.userStations) {
      this.initDeviceTopology(this.userStations);
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public initDeviceTopology(stations: IStation[]): void {
    const createFilter = (stationID) => (station: IStation) => station.name.original === stationID;
    if (stations && this.checkStationTopology()) {
      this.camera1 = stations.find(createFilter(this.selectedStation.topology.cam1));
      this.camera2 = stations.find(createFilter(this.selectedStation.topology.cam2));
      this.controlUnit = stations.find(createFilter(this.selectedStation.topology.ccu));
    }
  }

  public checkStationTopology(): boolean {
    return this.selectedStation && !!this.selectedStation.topology;
  }

  public isCamera1(): boolean {
    return this.selectedStation.topology.cam1 === undefined;
  }

  public isCamera2(): boolean {
    return this.selectedStation.topology.cam2 === undefined;
  }

  public isControlUnit(): boolean {
    return this.selectedStation.topology.ccu === undefined;
  }

  public hasLEDTrap(): boolean {
    return (this.selectedStation.topology.cam2 || '').match(/^07F/) !== null;
  }

  public navigateToOtherDevice(station: IStation): void {
    let targetUrl;
    if (this.linkType === StationTopologyLinkType.LINK_CONFIG) {
      targetUrl = `/station/${station.name.original}/config`;
    } else if (
      station.info.device_id === StationTypes.get('IScoutType')
      || station.info.device_id === StationTypes.get('IScoutMobileType')
    ) {
      targetUrl = `/station/${station.name.original}/iscout`;
    } else if (
      station.info.device_id === StationTypes.get('CropViewType1')
      || station.info.device_id === StationTypes.get('CropViewType2')
      || station.info.device_id === StationTypes.get('CropViewType3')
    ) {
      targetUrl = `/station/${station.name.original}/cropview`;
    } else {
      targetUrl = `/station/${station.name.original}/data`;
    }
    this.router.navigate([targetUrl]);
  }
}
