import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, Subject, timer } from 'rxjs';
import { debounce, debounceTime, filter, startWith, takeUntil } from 'rxjs/operators';
import { ISelectedSearchWidgetItem } from '../../models/selectedSearchWidgetItem';
import { ISearchWidgetItem } from './list-item/search-widget-list-item.component';
import { IOrderSettingItem, ISortReturnValuesSet } from './models/search-widget.models';
import { IStation } from '../../models/stations';

@Component({
  selector: 'app-search-widget',
  templateUrl: './search-widget.component.html',
  styleUrls: ['./search-widget.component.scss']
})
export class SearchWidgetComponent implements OnInit, OnDestroy {

  @Input()
  public orderSettings     : Array<IOrderSettingItem>;
  @Input()
  public searchPlaceholder : string;
  @Input()
  public items             : Array<ISearchWidgetItem> = [];
  @Input()
  public items$            : Observable<Array<ISearchWidgetItem>>;
  @Input()
  public stations          : IStation[];
  @Input()
  public isActive          : boolean;
  @Input()
  public selectedItem      : ISelectedSearchWidgetItem;

  @Output()
  public close             : EventEmitter<null> = new EventEmitter<null>();

  public searchTermControl      : FormControl = new FormControl('');

  public hover$ = new BehaviorSubject(true);
  public focus$ = new BehaviorSubject(false);

  public sort$                  : BehaviorSubject<void> = new BehaviorSubject<void>(null);
  public handledItems           : Array<ISearchWidgetItem>;
  public currentOrderSettingItem: IOrderSettingItem;
  public orderDirection         : string = 'asc';
  public sortValues             : ISortReturnValuesSet = {
    asc : {lower: 1, equal: 0, higher: -1},
    desc: {lower: -1, equal: 0, higher: 1},
  };

  private destroy$              : Subject<boolean> = new Subject();

  private sortStationsBy       : string;

  public ngOnInit(): void {
    this.initClosing();
    this.initResultList();
    this.sortStationsBy = localStorage.getItem('sortingStations');

    if (this.sortStationsBy) {
      this.currentOrderSettingItem = this.orderSettings.find(
        (orderSettingsItem: IOrderSettingItem) => orderSettingsItem.orderField === this.sortStationsBy);
    } else {
      this.currentOrderSettingItem = this.orderSettings.find(
        (orderSettingsItem: IOrderSettingItem) => orderSettingsItem.default);
    }
  }

  public setOrder(orderField: string): void {
    if (this.currentOrderSettingItem.orderField === orderField) {
      this.orderDirection = (this.orderDirection === 'asc') ? 'desc' : 'asc';
    } else {
      this.currentOrderSettingItem = this.orderSettings
        .find((orderSettingsItem: IOrderSettingItem) => orderSettingsItem.orderField === orderField);
      this.orderDirection = 'asc';
    }
    this.sort$.next(null);
  }

  public selectItem(selectedItem: ISelectedSearchWidgetItem): void {
    this.selectedItem = selectedItem;
  }


  public getDirection(orderSettingsItem: IOrderSettingItem): string {
    if (this.currentOrderSettingItem.orderField === orderSettingsItem.orderField) {
      return this.orderDirection;
    }

    return '';
  }

  public isSelected(item: ISearchWidgetItem): boolean {
    return this.selectedItem && this.selectedItem.original_name === item.original_name;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initClosing(): void {
    combineLatest([
      this.hover$,
      this.focus$
    ]).pipe(
      takeUntil(this.destroy$),
      debounce(() => timer(3000)),
      filter(([hover, focus]) => !hover && !focus)
    ).subscribe(() => this.close.emit());
  }

  private initResultList(): void {
    combineLatest([
      this.items$.pipe(
        takeUntil(this.destroy$),
        filter((items: Array<ISearchWidgetItem>): boolean => Array.isArray(items))
      ),
      this.searchTermControl.valueChanges.pipe(
        takeUntil(this.destroy$),
        startWith(''),
        debounceTime(400),
      ),
      this.sort$.pipe(takeUntil(this.destroy$))
    ]).subscribe((result: Array<Array<ISearchWidgetItem>|string|void>): void => {
      const items = <Array<ISearchWidgetItem>>result[0];
      const sortedArray = this.sortArray(this.filterArray(items));
      this.handledItems = this.sortStarred(sortedArray);
    });
  }

  private filterArray(items: Array<ISearchWidgetItem>): Array<ISearchWidgetItem> {
    const preparedSearchTerm = this.preparedSearchTerm;
    return  items.filter((item: ISearchWidgetItem): boolean => {
      const preparedOriginalName = this.prepareStringForSearch(item.original_name);
      const preparedCustomName = this.prepareStringForSearch(item.custom_name);
      return preparedOriginalName.includes(preparedSearchTerm) || preparedCustomName.includes(preparedSearchTerm);
    });
  }

  private get preparedSearchTerm(): string {
    return this.prepareStringForSearch(this.searchTermControl.value);
  }

  private sortArray(items: Array<ISearchWidgetItem>): Array<ISearchWidgetItem> {
      return [...items].sort((itemA: ISearchWidgetItem, itemB: ISearchWidgetItem): number => {
      const propertyA: string = itemA[this.currentOrderSettingItem.orderField].toLowerCase();
      const propertyB: string = itemB[this.currentOrderSettingItem.orderField].toLowerCase();

      if (propertyA > propertyB) {
        return this.sortValues[this.orderDirection].lower;
      }

      if (propertyA < propertyB) {
        return this.sortValues[this.orderDirection].higher;
      }

      if (propertyA === propertyB) {
        return 0;
      }

    });
  }

  private sortStarred(sortedArray: Array<ISearchWidgetItem>): Array<ISearchWidgetItem> {
    const starredItems = sortedArray.filter((item) => item.starred);
    const unStarredItems = sortedArray.filter((item) => !item.starred);
    return starredItems.concat(unStarredItems);
  }

  private prepareStringForSearch(initString: string): string {
    return initString.toLowerCase().split(' ').join('');
  }
}
