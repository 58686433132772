import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-john-deere-modal',
  templateUrl: './my-john-deere-modal.component.html',
  styleUrls: ['./my-john-deere-modal.component.scss']
})
export class MyJohnDeereModalComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {}

}
