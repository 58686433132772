import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserSettingsRoutingModule} from './user-settings-routing.module';
import {SharedModule} from '../../shared/shared.module';
import {UserSettingsComponent} from './containers/user-settings/user-settings.component';
import {UserSettingsHeaderComponent} from './containers/user-settings/user-settings-header/user-settings-header.component';
import {TranslateModule} from '@ngx-translate/core';
import {AccountSettingsComponent} from './containers/user-settings/account-settings/account-settings.component';
import {NotificationsComponent} from './containers/user-settings/notifications/notifications.component';
import {UserSettingsCardHeaderComponent} from './components/user-settings-card-header/user-settings-card-header.component';
import {OtherSettingsComponent} from './containers/user-settings/other-settings/other-settings.component';
import {AccountSettingsFormComponent}
  from './containers/user-settings/account-settings/account-settings-form/account-settings-form.component';
import {BasicInfoComponent} from './containers/user-settings/other-settings/basic-info/basic-info.component';
import {SettingsComponent} from './containers/user-settings/other-settings/settings/settings.component';
import {CompanyInfoComponent} from './containers/user-settings/other-settings/company-info/company-info.component';
import {AddressInfoComponent} from './containers/user-settings/other-settings/address-info/address-info.component';
import {NotificationsFormComponent} from './containers/user-settings/notifications/notifications-form/notifications-form.component';
import { CloseAccountComponent } from './containers/user-settings/close-account/close-account.component';
import { CloseAccountFormComponent } from './containers/user-settings/close-account/close-account-form/close-account-form.component';

@NgModule({
  imports: [
    CommonModule,
    UserSettingsRoutingModule,
    SharedModule,
    TranslateModule.forChild()
  ],
  declarations: [
    UserSettingsComponent,
    UserSettingsHeaderComponent,
    AccountSettingsComponent,
    NotificationsComponent,
    UserSettingsCardHeaderComponent,
    OtherSettingsComponent,
    AccountSettingsFormComponent,
    BasicInfoComponent,
    SettingsComponent,
    CompanyInfoComponent,
    AddressInfoComponent,
    NotificationsFormComponent,
    CloseAccountComponent,
    CloseAccountFormComponent]
})
export class UserSettingsModule { }
