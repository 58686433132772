import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {IUserLicenseTableHeaders} from '../../models/user-licenses.models';

@Injectable()
export class UserLicensesListResolver implements Resolve<IUserLicenseTableHeaders> {
  constructor(private translations: TranslateService) {}

  public resolve(): Observable<IUserLicenseTableHeaders> | Promise<IUserLicenseTableHeaders> | IUserLicenseTableHeaders {
    const translatedHeaders: IUserLicenseTableHeaders = {
      licensesHeader: 'Licenses',
      stationsHeader: 'Stations',
      cropzonesHeader: 'Cropzones',
      startHeader: 'Start',
      expiryHeader: 'Expiry',
    };

    Object.keys(translatedHeaders).forEach(key => {
      this.translations.get(translatedHeaders[key])
        .subscribe((translatedHeader) => {
          translatedHeaders[key] = translatedHeader;
        });
    });

    return translatedHeaders;
  }
}
