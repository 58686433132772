import { IOptions } from '../../../shared/interfaces';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { ICropZone } from '../../../core/models/cropzones';
import { IOptionsForDatasources } from '../../irrimet-config/actions/irrimet-config.action';

export interface IYieldConfig {
  datasourcesRainFull: Array<any>;
  rainOptions: Array<IOptions>;
  cropTypeOptions: Array<IOptions>;
  cropObjects: Array<any>;
  cropzoneId: string;
}

export const enum YieldConfigActionTypes {
  GET_DATASOURCES_RAIN = '[YieldConfig] GET_DATASOURCES_RAIN',
  SET_DATASOURCES_RAIN = '[YieldConfig] SET_DATASOURCES_RAIN',
  GET_DATASOURCES_AIRTEMPERATURE = '[YieldConfig] GET_DATASOURCES_AIRTEMPERATURE',
  SET_DATASOURCES_AIRTEMPERATURE = '[YieldConfig] SET_DATASOURCES_AIRTEMPERATURE',
  GET_CROPS_LAZY_LOADING = '[YieldConfig] GET_CROPS_LAZY_LOADING',
  SET_CROPS_LAZY_LOADING = '[YieldConfig] SET_CROPS_LAZY_LOADING',
  SET_YIELD_CONFIG_LOADING = '[YieldConfig] SET_YIELD_CONFIG_LOADING',
  UPDATE_CROPZONE = '[YieldConfig] UPDATE_CROPZONE',
  COLLECT_CONFIG_DATA = '[YieldConfig] COLLECT_CONFIG_DATA'
}

export function getDatasourcesRain(payload: IOptionsForDatasources): IActionWithPayload {
  return {type: YieldConfigActionTypes.GET_DATASOURCES_RAIN, payload: payload};
}

export function setDatasourcesRain(datasources: {}[]): IActionWithPayload {
  return {type: YieldConfigActionTypes.SET_DATASOURCES_RAIN, payload: datasources};
}

export function getDatasourcesAirTemperature(payload: IOptionsForDatasources): IActionWithPayload {
  return {type: YieldConfigActionTypes.GET_DATASOURCES_AIRTEMPERATURE, payload: payload};
}

export function setDatasourcesAirTemperature(datasources: {}[]): IActionWithPayload {
  return {type: YieldConfigActionTypes.SET_DATASOURCES_AIRTEMPERATURE, payload: datasources};
}

export function getCropsLazyLoading(): IActionWithPayload {
  return {type: YieldConfigActionTypes.GET_CROPS_LAZY_LOADING, payload: undefined};
}

export function setCropsLazyLoading(crops: {}[]): IActionWithPayload {
  return {type: YieldConfigActionTypes.SET_CROPS_LAZY_LOADING, payload: crops};
}

export function setYieldConfigLoading(status: boolean): IActionWithPayload {
  return {type: YieldConfigActionTypes.SET_YIELD_CONFIG_LOADING, payload: status};
}

export function updateCropzone(payload: ICropZone): IActionWithPayload {
  return { type: YieldConfigActionTypes.UPDATE_CROPZONE, payload };
}

export function collectConfigData(payload: boolean): IActionWithPayload {
  return { type: YieldConfigActionTypes.COLLECT_CONFIG_DATA, payload };
}
