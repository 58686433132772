import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { removeWidget } from '../../../actions/dashboard';
import { IWidget } from '../../../models/dashboard.models';
import * as fromDashboard from '../../../reducers/dashboard';
import { selectEdit } from '../../../reducers/index';

@Component({
  selector: 'app-stations-list-widget',
  templateUrl: './stations-list-widget.component.html',
  styleUrls: ['./stations-list-widget.component.scss']
})
export class StationsListWidgetComponent implements OnInit {
  @Input()
  public widget                    : IWidget;
  @Input()
  private widgetIndex               : number;
  @Input()
  private columnIndex               : number;
  @Input()
  private rowIndex                  : number;
  public isEdit$                    : Observable<boolean>;

  constructor(private dashboardStore: Store<fromDashboard.IDashboard>) {}

  public ngOnInit(): void {
    this.isEdit$ = this.dashboardStore.pipe(select(selectEdit));
  }

  public removeCurrentWidget(): void {
    const path: Array<number> = [this.rowIndex, this.columnIndex, this.widgetIndex];
    this.dashboardStore.dispatch(removeWidget(path));
  }

}
