<div class="tree-sensors-container">
  <div class="tree-body" *ngIf="tree$ | async">
    <ul class="main" *ngFor="let treeItem of tree.trees">
      <i (click)="treeItem.expanded=!treeItem.expanded"
         [ngClass]="{'triangle': true, 'fa-caret-down': treeItem.expanded, 'fa-caret-right': !treeItem.expanded}">
      </i>
      <i [class.fa-sitemap]="treeItem.type=='base'"
         class="type-icon">
      </i>
      <a>
        {{ treeItem.name }}
      </a>
      <i class="icomoon icon-checkbox-checked"></i>
      <div *ngIf="treeItem.expanded" class="tree-nodes">
        <ul [attr.class]="'submenu'">
          <li *ngFor="let leaf of treeItem.nodes"
              [class.active]="leaf.visible"
              (click)="toggleSensor(leaf)">
            <div class="color-marker" [ngStyle]="{'background-color': leaf.color}"></div>
            <a>
              {{leaf.name | translate}}
              <small *ngIf="leaf.ch">
                [{{leaf.ch}}]
              </small>
            </a>
          </li>
        </ul>
      </div>
    </ul>
  </div>
</div>




