import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HydroponicsComponent } from './hydroponics.component';
import { HydroponicsResolver } from './hydroponics.resolver.service';

const routes: Routes = [
  { path: '',     component: HydroponicsComponent,
                  resolve: { hydroponicsResolver: HydroponicsResolver}, },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
  providers: [HydroponicsResolver]
})
export class HydroponicsRoutingModule { }
