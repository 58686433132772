import { Options } from 'highcharts';
import { IOptions } from '../../../shared/interfaces';
import * as moment from 'moment';

export const DEFAULT_CHART_OPTIONS: Options = {
  title: {
    text: null
  },
  chart: {
    height: 400,
    marginLeft: 85,
    marginRight: 200,
    marginTop: 5,
    style: { fontFamily: 'Helvetica' },
    zoomType: 'x',
    alignTicks: false,
    resetZoomButton: {
      theme: {
        display: 'none'
      }
    }
  },
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  tooltip: {
    useHTML: true,
    xDateFormat: '%d/%m/%Y',
    shared: true
  },
  legend: {
    useHTML: true,
    labelFormatter: function (): string {
      return this.name;
    }
  },
  xAxis: {
    crosshair: true,
    gridLineWidth: 1,
    labels: { style: { fontSize: '12px' } },
    lineWidth: 2,
    type: 'datetime',
  },
  plotOptions: {
    series: {
      states: {
        hover: {
          enabled: false
        }
      },
      marker: {
        states: {
          hover: {
            enabled: false
          }
        }
      }
    }
  }
};

export const PERIODS: IOptions[] = [
  {
    content: 'entire cultivation period',
    value: Infinity
  },
  {
    content: '7 days',
    value: 7
  },
  {
    content: '14 days',
    value: 14
  },
  {
    content: '30 days',
    value: 30
  }
];

export const columnDefsWaterbalanceMetric: any[] = [
  // tslint:disable-next-line:max-line-length
  { headerName: 'Date', field: 'date', sort: 'desc', valueFormatter: (data) => moment(data.data.date).format('DD/MM/YYYY'), sortable: true, suppressMenu: true, width: 97, pinned: true },
  { headerName: 'Precipitation', field: 'precipitation', sortable: true, suppressMenu: true, width: 123,
      children: [{ headerName: '[mm]', field: 'precipitation', width: 123, suppressMenu: true}]},
  { headerName: 'Precipitation (eff)', field: 'precipitation_eff', sortable: true, suppressMenu: true, width: 169,
      children: [{headerName: '[mm]', field: 'precipitation_eff', width: 169, suppressMenu: true}]},
  { headerName: 'Kc', field: 'kc', sortable: true, suppressMenu: true, width: 71,
      children: [{headerName: ' ', field: 'kc', width: 71, suppressMenu: true}]},
  { headerName: 'Daily Et0', field: 'daily_et0', sortable: true, suppressMenu: true, width: 101,
      children: [{headerName: '[mm]', field: 'daily_et0', width: 101, suppressMenu: true}]},
  { headerName: 'Daily Etc', field: 'daily_etc', sortable: true, suppressMenu: true, width: 97,
      children: [{headerName: '[mm]', field: 'daily_etc', width: 97, suppressMenu: true}]},
  { headerName: 'Irrigation', field: 'irrigation', sortable: true, suppressMenu: true, width: 99,
      children: [{headerName: '[mm]', field: 'irrigation', width: 99, suppressMenu: true}]},
  { headerName: 'Irrigation (eff)', field: 'irrigation_eff', sortable: true, suppressMenu: true, width: 128,
      children: [{headerName: '[mm]', field: 'irrigation_eff', width: 128, suppressMenu: true}]},
  { headerName: 'Water Balance', field: 'dwb', sortable: true, suppressMenu: true, width: 135,
      children: [{headerName: '[mm]', field: 'dwb', width: 135, suppressMenu: true}]},
  { headerName: 'Water Balance as PAW', field: 'paw', sortable: true, suppressMenu: true, width: 179,
    children: [{headerName: '[%]', field: 'paw', width: 179, suppressMenu: true}]}
];

export const columnDefsWaterbalanceImperial: any[] = [
  // tslint:disable-next-line:max-line-length
  { headerName: 'Date', field: 'date', sort: 'desc', valueFormatter: (data) => moment(data.data.date).format('DD/MM/YYYY'), sortable: true, suppressMenu: true, width: 97, pinned: true },
  { headerName: 'Precipitation', field: 'precipitation', sortable: true, suppressMenu: true, width: 125,
    children: [{ headerName: '[in]', field: 'precipitation', width: 125, suppressMenu: true}]},
  { headerName: 'Precipitation (eff)', field: 'precipitation_eff', sortable: true, suppressMenu: true, width: 169,
    children: [{ headerName: '[in]', field: 'precipitation_eff', width: 169, suppressMenu: true}]},
  { headerName: 'Kc', field: 'kc', sortable: true, suppressMenu: true, width: 71,
    children: [{ headerName: ' ', field: 'kc', width: 71, suppressMenu: true}]},
  { headerName: 'Daily Et0', field: 'daily_et0', sortable: true, suppressMenu: true, width: 97,
    children: [{ headerName: '[in]', field: 'daily_et0', width: 97, suppressMenu: true}]},
  { headerName: 'Daily Etc', field: 'daily_etc', sortable: true, suppressMenu: true, width: 97,
    children: [{ headerName: '[in]', field: 'daily_etc', width: 97, suppressMenu: true}]},
  { headerName: 'Irrigation', field: 'irrigation', sortable: true, suppressMenu: true, width: 99,
    children: [{ headerName: '[in]', field: 'irrigation', width: 99, suppressMenu: true}]},
  { headerName: 'Irrigation (eff)', field: 'irrigation_eff', sortable: true, suppressMenu: true, width: 130,
    children: [{ headerName: '[in]', field: 'irrigation_eff', width: 130, suppressMenu: true}]},
  { headerName: 'Available water', field: 'dwb', sortable: true, suppressMenu: true, width: 135,
    children: [{ headerName: '[in]', field: 'dwb', width: 135, suppressMenu: true}]},
  { headerName: 'PAW', field: 'paw', sortable: true, suppressMenu: true, width: 70,
    children: [{headerName: '[%]', field: 'paw', width: 70, suppressMenu: true}]}
];

export const irrigationTypes: IOptions[] = [{ value: 'FLOOD', content: 'flood' }, { value: 'FURROW', content: 'furrow' },
  { value: 'PIVOT', content: 'center pivot' }, { value: 'SPRINKLER', content: 'sprinkler' },
  { value: 'MICRO_SPRINKLER', content: 'micro sprinkler' }, { value: 'DRIP', content: 'drip' },
  { value: 'SUBSURFACE_DRIP', content: 'subsurface drip' }];

export const daysWeeks = [{ value: 'hours', content: 'hours' }, { value: 'days', content: 'days' }];

export enum irrigationTypeEnum {
  DRIP = 'DRIP',
  FLOOD = 'FLOOD',
  FURROW = 'FURROW',
  PIVOT = 'PIVOT',
  MICRO_SPRINKLER = 'MICRO_SPRINKLER',
  SPRINKLER = 'SPRINKLER',
  SUBSURFACE_DRIP = 'SUBSURFACE_DRIP'
}
