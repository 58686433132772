import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SoilMoistureConfigComponent} from './soil-moisture-config.component';

const routes: Routes = [
    { path: '', component: SoilMoistureConfigComponent}
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class SoilMoistureConfigRoutingModule {}
