<app-sample-data-tree></app-sample-data-tree>
<div class="body-view-content" [@placeContent]="state$ | async">
  <i (click)="openHelpModal()" class="zmdi zmdi-help help-icon"></i>
  <app-cropzone-page-header pageName="Cropzone Sample Data"></app-cropzone-page-header>
  <ng-content></ng-content>
</div>

<app-info-modal [modalId]="modalId" header="Cropzone Sample Data">
  <app-sample-modal></app-sample-modal>
</app-info-modal>
