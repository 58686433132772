import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {ICameraDataTableHeader} from '../../shared/interfaces';

@Injectable()
export class CameraDataResolver implements Resolve<any> {
  constructor(private translations: TranslateService) {}

  public resolve(): Observable<any>|Promise<any>|any {
    const translatedHeaders: ICameraDataTableHeader = {
      fruitTitle: 'Fruit',
      diameterTitle: 'Diameter (mm)',
      fsiTitle: 'FSI'
    };

    Object.keys(translatedHeaders).forEach(key => {
      this.translations.get(translatedHeaders[key])
        .subscribe(translatedHeader => {
          translatedHeaders[key] = translatedHeader;
        });
    });

    return translatedHeaders;
  }
}
