import { Component, Input, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getWidgetData, removeWidget, setWidgetError, setWidgetLoading, updateWidgetEditMode, updateWidgetSettings } from '../../../actions/dashboard';
import { ILoadWidgetData, IUpdateWidgetEditMode, IWidget, IWindRoseRequest } from '../../../models/dashboard.models';
import * as fromDashboard from '../../../reducers/dashboard';
import { selectEdit } from '../../../reducers';

@Component({
  selector: 'app-wind-rose-diagram-widget',
  templateUrl: './wind-rose-diagram-widget.component.html',
  styleUrls: ['./wind-rose-diagram-widget.component.scss']
})
export class WindRoseDiagramWidgetComponent implements OnInit {
  @Input()
  public widget: IWidget;
  @Input()
  private widgetIndex: number;
  @Input()
  private columnIndex: number;
  @Input()
  private rowIndex: number;

  public isEdit$: Observable<boolean>;
  private loadWidgetData: ILoadWidgetData;

  constructor(private dashboardStore: Store<fromDashboard.IDashboard>) { }

  public ngOnInit(): void {
    this.isEdit$ = this.dashboardStore.pipe(select(selectEdit));
    if (!this.widget.loadData) {
      this.loadChartData();
    }
  }

  public removeCurrentWidget(): void {
    const path: Array<number> = [this.rowIndex, this.columnIndex, this.widgetIndex];
    this.dashboardStore.dispatch(removeWidget(path));
  }

  public loadChartData(data?): void {
    const windRoseWidget = this.widget.requests.windrose;

    if (windRoseWidget) {
      this.loadWidgetData = {
        url: '/wind-rose/' + windRoseWidget.endpoint.station + '/' +
          windRoseWidget.endpoint.group + '/last/' + windRoseWidget.endpoint.last + 'd',
        path: [this.rowIndex, this.columnIndex, this.widgetIndex],
        channels: [windRoseWidget.endpoint.windspeed, windRoseWidget.endpoint.winddir]
      };
    }
    if (data) {
      this.loadWidgetData = {
        url: '/wind-rose/' + data.endpoint.station + '/' + data.endpoint.group + '/last/' + data.endpoint.last + 'd',
        path: [this.rowIndex, this.columnIndex, this.widgetIndex],
        channels: [data.endpoint.windspeed, data.endpoint.winddir]
      };
    }

    if ((Number.isInteger(windRoseWidget?.endpoint.windspeed) && Number.isInteger(windRoseWidget?.endpoint.winddir))
      || (Number.isInteger(data?.endpoint.windspeed) && Number.isInteger(data?.endpoint.winddir))) {
      this.resetLoading();
      this.dashboardStore.dispatch(getWidgetData(this.loadWidgetData));
    } else {
      this.windroseWarning();
    }

  }

  public updateRequest(updateSettings: IWindRoseRequest): void {
    this.dashboardStore.dispatch(updateWidgetSettings({
      type: 'windrose',
      updateSettings: updateSettings,
      path: [this.rowIndex, this.columnIndex, this.widgetIndex]
    }));
    if (updateSettings.endpoint.winddir === '' || updateSettings.endpoint.windspeed === '') {
      this.windroseWarning();
    }
  }

  public editMode(editMode: boolean): void {
    const updateSettings: IUpdateWidgetEditMode = {
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      type: 'windrose',
      isEdit: editMode,
    };
    this.dashboardStore.dispatch(updateWidgetEditMode(updateSettings));
  }

  private resetLoading(): void {
    this.dashboardStore.dispatch(setWidgetLoading({
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      loading: true
    }));
    this.dashboardStore.dispatch(setWidgetError({
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      error: ''
    }));
  }

  private windroseWarning(): void {

    this.dashboardStore.dispatch(setWidgetError({
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      error: 'Wind rose diagram requires both wind speed and wind direction sensors to work.'
    }));
  }
}
