import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { ActionTypes } from '../actions/davis';
import { IState } from '../models/davis';

export const initialState: IState = {
  settings: {
    returnCode: -1,
    APIKey: '',
    APISecret: '',
  },
  assets: {
    returnCode: -1,
    stationList: []
  },
  licenses: {
    returnCode: -1,
    licenseList: []
  },
  sensorMap: {
    returnCode: -1,
    sensorMap: []
  }
};

export function reducer(state: IState = initialState, action: IAction): any {
  switch (action.type) {
    case ActionTypes.SET_SETTINGS:
      return {
        ...state,
        settings: {
          ...action.payload
        }
      };
    case ActionTypes.SET_ASSETS:
      return {
        ...state,
        assets: {
          ...action.payload
        }
      };
    case ActionTypes.SET_LICENSES:
      return {
        ...state,
        licenses: {
          ...action.payload
        }
      };
    case ActionTypes.SET_SENSOR_MAP: {
      return {
        ...state,
        sensorMap: {
          ...action.payload
        }
      };
    }
    case ActionTypes.DELETE_SETTINGS:
      return initialState;
    default:
      return state;
  }
}

