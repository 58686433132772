<div [@cardAnimation] class="other-settings" *ngIf="(basicInfo$ | async)">
  <app-form-layout >
    <app-user-settings-card-header [header]="'Other settings'"
                                   [description]="'Basic user information and settings'"></app-user-settings-card-header>

    <div class="row other-settings__forms">
      <div class="col-md-4">
        <app-basic-info [basicInfo]="basicInfo$ | async"
                        (basicInfoChange)="basicInfoChange($event)"></app-basic-info>
      </div>

      <div class="col-md-4">
        <app-address-info [addressInfo]="addressInfo$ | async"
                          (addressInfoChange)="addressInfoChange($event)"></app-address-info>
      </div>

      <div class="col-md-4">
        <app-company-info [companyInfo]="companyInfo$ | async"
                          (companyInfoChange)="companyInfoChange($event)"></app-company-info>

        <app-settings [settings]="settings$ | async"
                      (settingsInfoChange)="settingsInfoChange($event)"></app-settings>
      </div>
    </div>

    <hr>

    <div class="row other-settings__bottom">
      <div class="col-md-8">
        <div class="other-settings__error"
             *ngIf="errorMsg">
          {{ errorMsg | translate }}
        </div>
      </div>
      <div class="col-md-4">
        <div class="other-settings__submit">
          <app-custom-button [content]="'Update settings'"
                             (click)="update()"
                             [color]="'red'"></app-custom-button>
        </div>
      </div>
    </div>


  </app-form-layout>

</div>
