import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './services/auth/auth.guard';

const routes: Routes = [
  { path: 'auth', canLoad: [ AuthGuard ], loadChildren: () => import('../app/modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'password-update/:activeKey', redirectTo: '/auth/password-update/:activeKey', pathMatch: 'full' },
  { path: '', canActivate: [ AuthGuard ], loadChildren: () => import('../app/core/main.module').then(m => m.MainModule) },
  { path: '**', redirectTo: '/auth/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
