<section class="form-layout height450">
  <div class="row">

    <div class="col-md-9 height450">
      <div class="container widthHeight100 padding0">
        <div id="tooltip" class="hidden">
          <p><strong><span id="label"></span></strong></p>
          <p><span id="date"></span></p>
        </div>
        <div #chart id="chart" class="widthHeight100"></div>
      </div>
    </div>

    <div class="col-md-3 paddingR35">
      <div class="row">
        <h3>{{'Dates' | translate }}</h3>
      </div>

      <div class="row">
        <p>{{ 'Seeding / begin of season' | translate }}</p>
        <app-custom-datepicker
          class="startPicker"
          [minDate]="minDate"
          [maxDate]="cropDevelopmentPicker.value"
          [formControl]="startOfSeasonPicker">
        </app-custom-datepicker>
      </div>
      <div class="row">
        <p>{{ '10% groundcover' | translate }}</p>
        <app-custom-datepicker
          class="cropPicker"
          [minDate]="startOfSeasonPicker.value"
          [maxDate]="midSeasonPicker.value"
          [formControl]="cropDevelopmentPicker">
        </app-custom-datepicker>
      </div>
      <div class="row">
        <p>{{ 'Flowering / full groundcover' | translate }}</p>
        <app-custom-datepicker
          class="midPicker"
          [minDate]="cropDevelopmentPicker.value"
          [maxDate]="lateSeasonPicker.value"
          [formControl]="midSeasonPicker">
        </app-custom-datepicker>
      </div>
      <div class="row">
        <p>{{ 'Maturity' | translate }}</p>
        <app-custom-datepicker
          class="latePicker"
          [minDate]="midSeasonPicker.value"
          [maxDate]="endSeasonPicker.value"
          [formControl]="lateSeasonPicker">
        </app-custom-datepicker>
      </div>
      <div class="row">
        <p>{{ 'Harvest / leaf fall / end of season' | translate }}</p>
        <app-custom-datepicker
          class="endPicker"
          [minDate]="lateSeasonPicker.value"
          [maxDate]="maxDate"
          [formControl]="endSeasonPicker">
        </app-custom-datepicker>
      </div>
    </div>
  </div>
</section>
