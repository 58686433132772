<div class="station-sample-modal">
  <p style="overflow-wrap: break-word;">{{ 'The tables on the page show the raw data per device. Devices can be SoilGuard, DualEx or Mobilab.' | translate }}</p>

  <p>{{ 'Samples from SoilGuard (SG) will contain:' | translate }}</p>
  <ul>
    <li>{{ 'Volumetric Water Content (VWC) in %' | translate }}</li>
    <li>{{ 'Electric Conductivity (EC) in mS/cm' | translate }}</li>
    <li>{{ 'Soil Temperature (°C)' | translate }}</li>
    <li>{{ 'Geolocation where the samples were taken' | translate }}</li>
    <li>{{ 'Rod Length (multiple lengths options between Turf T, Short S, Medium M or Long L)' | translate }}</li>
    <li>{{ 'Soil type (Standard S, Hi-Clay H, Sand D)' | translate }}</li>
    <li>{{ 'VWC mode (depicted as V for EC compensated or 3 for non-compensated)' | translate }}</li>
    <li>{{ 'VWC mode (depicted as V for EC compensated or 3 for non-compensated)' | translate}}</li>
  </ul>
  <br/>

  <p>{{ 'Samples from DualEx will contain:' | translate }}</p>
  <ul>
    <li>{{ 'Group = Indicates the group rank of a set of measurements statistically connected' | translate }}</li>
    <li>{{ 'Measure = corresponds to the rank number of the measurement performed in the group. This value increases automatically with every measurement.' | translate }}</li>
    <li>{{ 'CHL = Chlorophyll Index' | translate }}</li>
    <li>{{ 'FLAV = Flavonol Index' | translate }}</li>
    <li>{{ 'NBI = Nitrogen status given by Chl/Flav ratio' | translate }}</li>
    <li>{{ 'Anth = Anthocyanin Index' | translate }}</li>
    <li>{{ 'Side = Side of the leaf, adaxial or abaxial, and its associated epidermis.' | translate }}</li>
    <li>{{ 'Position where the samples were taken' | translate }}</li>
    <li>{{ 'Leaf Temperature (°C)' | translate }}</li>
  </ul>
  <br/>

  <p>{{ 'Samples from Mobilab will contain:' | translate }}</p>
  <ul>
    <li>{{ 'Nitrate, Sulfate, Ammonium, Chloride, Potassium, Sodium, Calcium, Magnesium' | translate }}</li>
    <li>{{ 'Soil Moisture type works as an index' | translate }}</li>
    <li>{{ 'pH level' | translate }}</li>
    <li>{{ 'Position where the samples were taken' | translate }}</li>
    <li>{{ 'Sample type (Plant SAP or soil)' | translate }}</li>
    <li>{{ 'Leaf type (old or new)' | translate }}</li>
    <li>{{ 'Crop type' | translate }}</li>
  </ul>
  <br/>

  <p>{{ 'More details can be found in the manual on ' | translate }} <a href="http://metos.at/manuals">metos.at/manuals</a></p>
</div>
