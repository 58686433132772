import { ICropZone, IIrrigationEvents } from '../../../core/models/cropzones';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { IIrrigationCalendarEvent } from '../models/models';

export const enum IrrimetActionTypes {
  SET_IRRIGATION_EVENTS = '[Irrimet] SET_IRRIGATION_EVENTS',
  SET_CALENDAR_EVENTS = '[Irrimet] SET_CALENDAR_EVENTS',
  SET_VIEW_DATE = '[Irrimet] SET_VIEW_DATE',
  UPDATE_CROPZONE = '[Irrimet] UPDATE_CROPZONE'
}

export function setIrrigationEvents(irrigationEvents: Array<IIrrigationEvents>): IActionWithPayload {
  return { type: IrrimetActionTypes.SET_IRRIGATION_EVENTS, payload: irrigationEvents };
}

export function setCalendarEvents(calendarEvents: Array<IIrrigationCalendarEvent>): IActionWithPayload {
  return { type: IrrimetActionTypes.SET_CALENDAR_EVENTS, payload: calendarEvents };
}

export function setViewDate(date: Date): IActionWithPayload {
  return { type: IrrimetActionTypes.SET_VIEW_DATE, payload: date };
}

export function updateCropzone(payload: ICropZone): IActionWithPayload {
  return { type: IrrimetActionTypes.UPDATE_CROPZONE, payload };
}
