<app-chart-toolbar-layout>
  <form class="crop-zone-soil-moisture-sums-sm-toolbar"
    [formGroup]="form">

    <div class="crop-zone-soil-moisture-sums-sm-toolbar__left">

      <div class="crop-zone-soil-moisture-sums-sm-toolbar__toggle crop-zone-soil-moisture-sums-sm-toolbar__left__item">

        <app-custom-button
          [color]="isLastDataMode.value ? 'dark-green' : 'green'"
          appTooltip
          [tooltipPlacement]="'top'"
          [tooltipTitle]="'Last data mode' | translate"
          [shadow]="false"
          [radius]="'2px 0 0 2px'"
          (click)="isLastDataMode.setValue(!isLastDataMode.value)">
          <span class="fa fa-step-backward"></span>
        </app-custom-button>

        <app-custom-button
          [color]="!isLastDataMode.value ? 'dark-green' : 'green'"
          appTooltip
          [tooltipPlacement]="'top'"
          [tooltipTitle]="'From - To mode' | translate"
          [shadow]="false"
          [radius]="'0 2px 2px 0'"
          (click)="isLastDataMode.setValue(false)">
          <span class="fa fa-calendar"></span>
        </app-custom-button>

      </div>

      <app-custom-dropdown class="crop-zone-soil-moisture-sums-sm-toolbar__left__item"
        [options]="scopes"
        [formControl]="scope">
      </app-custom-dropdown>

      <app-custom-dropdown class="crop-zone-soil-moisture-sums-sm-toolbar__left__item"
        [hidden]="!isLastDataMode.value"
        [options]="periods"
        [formControl]="period">
      </app-custom-dropdown>

      <ng-container *ngIf="!isLastDataMode.value">

        <app-custom-datepicker class="crop-zone-soil-moisture-sums-sm-toolbar__left__item"
          [isSmall]="true"
          [minDate]="minDate"
          [maxDate]="to.value"
          [formControl]="from">
        </app-custom-datepicker>

        <app-custom-datepicker class="crop-zone-soil-moisture-sums-sm-toolbar__left__item"
          [isSmall]="true"
          [maxDate]="maxDate"
          [minDate]="from.value"
          [formControl]="to">
        </app-custom-datepicker>

      </ng-container>

      <app-date-range-arrow  class="crop-zone-soil-moisture-sums-sm-toolbar__left__item"
        [formControl]="range"
        [hidden]="!isLastDataMode.value"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [period]="period.value"
        (rangeClick)="refresh()">
      </app-date-range-arrow>

    </div>

    <div class="crop-zone-soil-moisture-sums-sm-toolbar__middle">

      <div class="crop-zone-soil-moisture-sums-sm-toolbar__toggle crop-zone-soil-moisture-sums-sm-toolbar__middle__item">

        <app-custom-button
          [color]="displayActiveRootZone.value ? 'green' : 'white'"
          [disabled]="!cropZone.crop"
          [content]="'Display Active Root Zone'"
          [shadow]="false"
          [radius]="'2px 0 0 2px'"
          (click)="displayActiveRootZone.setValue(!displayActiveRootZone.value)">
        </app-custom-button>

      </div>

    </div>

    <div class="crop-zone-soil-moisture-sums-sm-toolbar__right">

      <div class="crop-zone-soil-moisture-sums-sm-toolbar__right__item">

        <app-custom-button
          [color]="'red'"
          [content]="'Refresh' | translate"
          (click)="refresh()">
          <i class="fa fa-fw fa-refresh"></i>
        </app-custom-button>

      </div>

    </div>
  </form>
</app-chart-toolbar-layout>

