<div class="dropdown-container"
     appOutClick
     (outClick)="close()">
  <app-custom-button
    [caret]="true"
    [content]="staticText || currentText"
    (click)="toggleOpen()"
    [color]="color"
  ></app-custom-button>
  <div class="custom-dropdown" *ngIf="isOpen">
    <div class="input-container">
      <input type="text" [attr.placeholder]="placeholder | translate" [value]="filterString" (keyup)="inputChanged($event.target.value)">
      <i class="zmdi zmdi-search"></i>
    </div>
    <div class="custom-dropdown-data">
      <p *ngFor="let item of filteredItems" (click)="onChange(item)">
        <span class="fa fa-check check"
        *ngIf="item[itemIdProperty] === currentId"></span>
        {{ item[itemTextProperty] }}
      </p>
      <ng-container *ngIf="(totalItems > items.length)">
        <p class="custom-dropdown-loadmore" (click)="loadMore.emit()" *ngIf="!isBusy; else busy">
          {{ "Load more" | translate }}
        </p>
        <ng-template #busy>
          <p class="custom-dropdown-busy">
            {{ "Loading..." | translate }}
          </p>
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>

