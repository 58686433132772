import { Options } from 'highcharts';
import { IAccumulatorToolHeaders } from '../interfaces/interfaces';

export const HEADERS: IAccumulatorToolHeaders = {
  degreeDays: {
    'title': 'Degree Days',
    'help': [
      'To calculate the Degree Days or the development of insects through temperature accumulation tool, insert the initial and final ' +
      'dates, and select the temperature sensor you would like to use.',
      '\n\n',
      'The Base Temperature calculation, for corn, for example, will be 9°C or 48°F and, for the most insects, it will be 10°C or 50°F. ' +
      'The upper threshold indicates temperature with the optimum growth or development possibilities. As an example: if the Base ' +
      'Temperature is setted up for 10°C (50°F), and the upper threshold to 24°C (75°F), a day with an average temperature of 15°C ' +
      '(59°F) will increase the accumulation by 5°C (9°F).',
      '\n\n',
      'Degree Days are calculated by taking the daily temperature average over 24 hours (second column). Whereas, Degree Days ' +
      '(Tmax+Tmin)/2 uses the daily maximum and minimum temperatures measured by the station, divided per two, subtracting the Base ' +
      'Temperature from it (third column). Given by the equation: (Tmax+Tmin)/2 - Base Temperature.'
    ]
  },
  chillingUnits: {
    'title': 'Chilling Units and models',
    'help': [
      'In the chilling unit menu, the maximal temperature indicates the temperature below which the dormancy breaking impact starts and ' +
      'the lower threshold indicates the temperature which has the maximum dormancy breaking impact. Setting the maximum temperature to ' +
      '+4°C (39°F) and the lower threshold to 0°C (32°F), a day or hour with an average temperature in between will increase the result ' +
      'by 1°C (34°F).',
      '\n\n',
      'HOURS < 7.2°C (45°F)',
      '\n',
      '1 chill hour is 1 hour < 45°F, as defined by the Pomology Department Weather Services, University of California, Davis.',
      '\n\n',
      'The Utah model is more complex because it introduces the concept of relative chilling effectiveness and negative chilling ' +
      'accumulation (or chilling negation). According to Richardson et al. (1974) temperatures between 0 and 16°C promote the breaking ' +
      'of rest, whereas temperatures > 16°C negate such effects. Maximum promotion occurs at 7°C (1 h at 7°C = 1 chill unit); higher and ' +
      'lower temperatures within the 0-16°C range are less effective. This model has been modified as more information has become ' +
      'available.',
      '\n\n',
      'The Positive Chill Units (PCU) model is a modification of the Utah Model, which does not consider the negative values for ' +
      'the chill accumulation, and which is designed for situations where, because of the abundance of high winter temperatures ' +
      '(temperatures > 16 °C), the final values are negative. This model has been found to give a more accurate estimation of winter ' +
      'chilling in areas with mild winters or sub-tropical climates.'
    ]
  },
  rainSum: {
    'title': 'Rain Sum',
    'help': [
      'Rain sum tool calculates the cumulative rainfall over the time period selected by the user.'
    ]
  },
  asparagusGrowthModel: {
    'title': 'Asparagus Growth Model',
    'help': [
      'The Asparagus Growth Models include:',
      '\n\n',
      '1. Model for VERNALIZATION:',
      '\n',
      'for determination of the vernalization we use the air temperature sensor with a lower threshold at 2°C and a upper one of 5°C. ' +
        'Hours within those temperatures (chilling) are accumulated in a chosen time period. Output resulted in the first graph in a ' +
        'green line (GOOD conditions with 800 Chilling hours) and red line (VERY GOOD Conditions with 1000 Chilling hours) reached ' +
        'within that time.',
      '\n\n',
      '2. Model for HARVESTING CONDITIONS:',
      '\n',
      'here we define',
      '\n',
      'a) START of GROWTH of Asparagus, where we use the conditions in 20cm soil depth and accumulate 500 degreehours by a lower ' +
        'threshold of 5°C.',
      '\n',
      'b) LONG TERM decisions, where we use the conditions in 10cm, 20cm, 30cm and 40 cm soil depth and accumulate 5000 to 6000 ' +
        'degreehous by a lower threshold of 5°C.',
      '\n',
      'c) SHORT TERM decisions, where we use the conditions in 40cm soil depth and show when 3 consecutive days with a temperature ' +
        'higher than 16°C have been measured.',
      '\n\n',
      '3. Model for QUALITY:',
      '\n',
      'we define parameters for the quality of the asparagus, when',
      '\n',
      'Soil temperature in different depths >= 18°C = ROSA',
      '\n',
      'Soil temperature >= 20°C = DARK ROSA',
      '\n',
      'Soil temperature >= 22°C = RED',
      '\n',
      'and recommend to turn the sheeting as soon as ROSA is determined.',
      '\n\n',
      '4. CRITICAL (Hollow Stem):',
      '\n',
      'here we use sensor in 10cm depth and sensor in 40cm depth and calculate the difference in temperature between them. If the ' +
        'difference is higher than 4°C it is critical and we display a red band.'
    ]
  }
};

export const ASPARAGUS_TYPES: Array<string> = [
  'asparagusModel1',
  'asparagusModel2Start',
  'asparagusModel2Long',
  'asparagusModel2Short',
  'asparagusModel3',
  'asparagusModel4'
];

export const CHART_OPTIONS: Options = {
  title: {
    text: null
  },
  chart: {
    height: 400,
    marginLeft: 80,
    marginRight: 80,
    marginTop: 50,
    style: { fontFamily: 'Helvetica' },
    zoomType: 'x',
    alignTicks: false
  },
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  tooltip: {
    useHTML: true,
    xDateFormat: '%d/%m/%Y',
    shared: true
  },
  legend: {
    enabled: true,
    useHTML: true,
    labelFormatter: function (): string {
      return this.name;
    }
  },
  xAxis: {
    crosshair: true,
    gridLineWidth: 1,
    labels: {
      style: {
        fontSize: '12px'
      }
    },
    type: 'datetime',
  },
  plotOptions: {
    series: {
      states: {
        hover: {
          enabled: false
        }
      },
      marker: {
        states: {
          hover: {
            enabled: false
          }
        }
      }
    }
  }
};

export const DEGREE_DAYS_COLUMN_DEFS = [
  {
    headerName: 'Date',
    field: 'dt',
    enableColResize: true,
    enableSorting: true,
    suppressMenu: true,
    headerHeight: 28
  },
  {
    headerName: 'Degree Hours',
    field: 'degree_hours',
    enableColResize: true,
    enableSorting: true,
    suppressMenu: true,
    headerHeight: 28
  },
  {
    headerName: 'Degree Days',
    field: 'degree_days',
    enableColResize: true,
    enableSorting: true,
    suppressMenu: true,
    headerHeight: 28
  },
  {
    headerName: 'Degree Days (max+min)/2 - Base Temperature',
    field: 'degree_days_usa',
    enableColResize: true,
    enableSorting: true,
    suppressMenu: true,
    headerHeight: 28
  }
];

export const CHILLING_UNITS_COLUMN_DEFS = [
  {
    headerName: 'Date',
    field: 'dt',
    enableColResize: true,
    enableSorting: true,
    suppressMenu: true,
    headerHeight: 28,
  },
  {
    headerName: 'N° Hours',
    field: 'n_hours',
    enableColResize: true,
    enableSorting: true,
    suppressMenu: true,
    headerHeight: 28,
  },
  {
    headerName: 'N° Days',
    field: 'n_days',
    enableColResize: true,
    enableSorting: true,
    suppressMenu: true,
    headerHeight: 28,
  },
  {
    headerName: 'N° Days (max+min)/2',
    field: 'n_days_avg',
    enableColResize: true,
    enableSorting: true,
    suppressMenu: true,
    headerHeight: 28,
  },
  {
    headerName: 'Hours',
    field: 'chill_45',
    enableColResize: true,
    enableSorting: true,
    suppressMenu: true,
    headerHeight: 28,
  },
  {
    headerName: 'Utah Chilling Hours',
    field: 'chill_utah',
    enableColResize: true,
    enableSorting: true,
    suppressMenu: true,
    headerHeight: 28,
  },
  {
    headerName: 'Utah Positive CU',
    field: 'chill_utah_infruitec',
    enableColResize: true,
    enableSorting: true,
    suppressMenu: true,
    headerHeight: 28,
  }
];

export const RAIN_SUM_COLUMN_DEFS = [
  {
    headerName: 'Date',
    field: 'dt',
    enableColResize: true,
    enableSorting: true,
    suppressMenu: true,
    headerHeight: 28
  },
  {
    headerName: 'Rain Sum',
    field: 'rain_sum',
    enableColResize: true,
    enableSorting: true,
    suppressMenu: true,
    headerHeight: 28
  }
];
