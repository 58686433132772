<div *ngIf="trackerConnections && false">
  <div *ngFor="let connection of trackerConnections" (click)="pushValue(connection)"
       [class.active]="connection._id === selectedCard" class="tracker-card">
    <h4>{{connection.machinery.machineryName}}</h4>
    <p>
      <span *ngIf="connection.time || connection.time === 0"><strong>{{ 'Time' | translate }}:</strong>
        {{calculateTime(connection.time)}} <br /></span>
      <span *ngIf="connection.distance || connection.distance === 0"><strong>{{ 'Distance' | translate }}:</strong>
        {{ connection.distance / 1000 | number:'1.1-2'}} km <br /></span>
      <span *ngIf="connection.fromDate"><strong>{{ 'From' | translate }}:</strong> {{connection.fromDate}} <br /></span>
      <span *ngIf="connection.toDate"><strong>{{ 'To' | translate }}:</strong> {{connection.toDate}}</span>
    </p>
  </div>
</div>

<div *ngIf="trips">
  <h3>{{ 'Trips' | translate }}</h3>

  <div class="tree-body">
    <ul>
      <li *ngFor="let trip of trips" [class.active]="trip.selected">
        <div class="pull-right buttons-right">
          <a (click)="loadTripData(trip);" class="pointer"><span class="fa fa-table" ></span></a>
        </div>
        <a class="pointer" (click)="toggleTripVisibility(trip.name);">
          <div class="color-marker" [ngStyle]="{'background-color': trip.color}"><span *ngIf="trip.selected" class="fa fa-check"></span></div>
          {{trip.name}} / <span class="trip-info">{{trip.distance}} {{trip.unit}} / {{trip.time}}</span>
          <div class="trip-dates">
            <small>
              <span class="fa fa-fw fa-calendar-check-o"></span> &nbsp;{{trip.tripStart}}<br />
              <span class="fa fa-fw fa-calendar-times-o"></span> &nbsp;{{trip.tripEnd}}<br />
              <!-- <span class="fa fa-fw fa-map-marker"></span> &nbsp;{{trip.count}} -->
            </small>
          </div>
        </a>
      </li>
    </ul>
  </div>
</div>
