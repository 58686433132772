import { IModel } from '../models';

export const MODELS: IModel[] = [
    {
        id: 'evapotranspiration',
        name: 'Evapotranspiration',
        requiredSensorGroups: [1, 2, 4, 6],
        units: [
            'mm',
            'in'
        ],
        defaultUnits: {
            metric: 'mm',
            imperial: 'in'
        },
        defaultColor: '#ff7f00'
    }
];
