
import { IDegreeAndChillingThresholds } from '../models/accumulatorTool';
import { IActionWithPayload } from '../models/actionWithPayload';


export const enum AccumulatorToolActionTypes {
    GET_THRESHOLDS = '[Accumulator] GET_THRESHOLDS',
    SET_THRESHOLDS = '[Accumulator] SET_THRESHOLDS',
    UPDATE_THRESHOLDS = '[Accumulator] UPDATE_THRESHOLDS'
}

export function getAccumulatorThresholds(): IActionWithPayload {
    return { type: AccumulatorToolActionTypes.GET_THRESHOLDS, payload: null };
}

export function updateAccumulatorThresholds(body: IDegreeAndChillingThresholds): IActionWithPayload {
    return { type: AccumulatorToolActionTypes.UPDATE_THRESHOLDS, payload: body };
}

export function setAccumulatorThresholds(body: IDegreeAndChillingThresholds): IActionWithPayload {
    return { type: AccumulatorToolActionTypes.SET_THRESHOLDS, payload: body };
}
