import { IDisplayRulesConfig } from '../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../environments/interfaces/theme';

export const themeConfig: IThemeConfig = {
  subDomain: 'farol',
  language: 'en',
  headerBackground: '#008366',
  src: '../../../assets/themes/farol/assets/images/logo.png',
  width: '250',
  mainWidth: '300',
  mainMTop: '20',
  mainLeft: '8',
  loginBackground: '#3A7C3C',
  loginLeft: '40',
  loginTop: '15',
  loginBottom: '15',
  registerBackground: '#3A7C3C',
  registerTop: '15',
  registerBottom: '15',
  resetBackground: '#3A7C3C',
  resetLeft: '20',
  resetTop: '15',
  resetBottom: '15',
  name: 'Farol',
  profileMenuSrc: '../../../assets/themes/default/assets/images/profile.png',
  faviconSrc: '../../../assets/themes/default/assets/favicon.ico',
};

export const displayRulesConfig: IDisplayRulesConfig = {
  userMenu: true,
  farmViewMenu: true,
  leftBarMenu: {
    stationData: true,
    cropView: true,
    iScout: true,
    weatherForecast: true,
    workPlanning: true,
    animalProduction: true,
    accumulatorTool: true,
    frostProtection: true,
    diseaseModels: true,
    soilMoisture: true,
    hydroponics: true,
    tracker: true,
    soilGuard: false,
    settings: true,
  },
};
