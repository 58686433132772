import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import {
  IAvailableUserStation,
  IDiseaseModelActivation,
  IForecastActivation,
  ILicenseActivationItem,
  IProductConfiguration,
  IProductConfigurationItem,
  IServiceLicenseActivation,
  IValidLicense,
  IVerifyLicense,
  IVirtualStationActivation
} from '../models/product-activation.models';
import { IDisease } from '../../../core/models/diseases.models';

export const enum ProductActivationActionTypes {
  GET_PRODUCT_ACTIVATION_BY_KEY = '[ProductActivation] GET_PRODUCTS_BY_ACTIVATION_KEY',
  GET_PRODUCT_ACTIVATION_BY_KEY_AND_TYPE = '[ProductActivation] GET_PRODUCT_ACTIVATION_BY_KEY_AND_TYPE',
  GET_AVAILABLE_DISEASE_MODELS = '[ProductActivation] GET_AVAILABLE_DISEASE_MODELS',
  SET_AVAILABLE_DISEASE_MODELS = '[ProductActivation] SET_AVAILABLE_DISEASE_MODELS',
  GET_AVAILABLE_USER_STATIONS = '[ProductActivation] GET_AVAILABLE_USER_STATIONS',
  SET_AVAILABLE_USER_STATIONS = '[ProductActivation] SET_AVAILABLE_USER_STATIONS',
  GET_AVAILABLE_SERVICE_LICENSE_STATIONS = '[ProductActivation] GET_AVAILABLE_SERVICE_LICENSE_STATIONS',
  SET_AVAILABLE_SERVICE_LICENSE_STATIONS = '[ProductActivation] SET_AVAILABLE_SERVICE_LICENSE_STATIONS',
  GET_PRODUCT_ACTIVATION_LICENSE_STATUS = '[ProductActivation] GET_PRODUCT_ACTIVATION_LICENSE_STATUS',
  SET_PRODUCT_ACTIVATION_LICENSE_STATUS = '[ProductActivation] SET_PRODUCT_ACTIVATION_LICENSE_STATUS',
  SET_PRODUCT_ACTIVATION_KEY = '[ProductActivation] SET_PRODUCTS_BY_ACTIVATION_KEY',
  SET_PRODUCT_ACTIVATION_CONFIGURATION = '[ProductActivation] SET_PRODUCT_ACTIVATION_CONFIGURATION',
  SET_PRODUCT_ACTIVATION_CONFIGURATION_ITEM = '[ProductActivation] SET_PRODUCT_ACTIVATION_CONFIGURATION_ITEM',
  UPDATE_PRODUCT_ACTIVATION_CONFIGURATION = '[ProductActivation] UPDATE_PRODUCT_ACTIVATION_CONFIGURATION',
  SAVE_PRODUCT_ACTIVATION_VIRTUAL_STATION = '[ProductActivation] SAVE_PRODUCT_ACTIVATION_VIRTUAL_STATION',
  SAVE_PRODUCT_ACTIVATION_DISEASE_MODEL = '[ProductActivation] SAVE_PRODUCT_ACTIVATION_DISEASE_MODEL',
  SAVE_PRODUCT_ACTIVATION_FORECAST = '[ProductActivation] SAVE_PRODUCT_ACTIVATION_FORECAST',
  SAVE_PRODUCT_ACTIVATION_SERVICE_LICENSE = '[ProductActivation] SAVE_PRODUCT_ACTIVATION_SERVICE_LICENSE',
  SAVE_PRODUCT_ACTIVATION_DAVIS_INGEST = '[ProductActivation] SAVE_PRODUCT_ACTIVATION_DAVIS_INGEST',
  SAVE_PRODUCT_ACTIVATION_FARM_VIEW = '[ProductActivation] SAVE_PRODUCT_ACTIVATION_FARM_VIEW',
  SAVE_PRODUCT_ACTIVATION_ISCOUT_MOBILE = '[ProductActivation] SAVE_PRODUCT_ACTIVATION_ISCOUT_MOBILE',
}

export function getProductActivationByKey(productKey: string): IActionWithPayload {
  return { type: ProductActivationActionTypes.GET_PRODUCT_ACTIVATION_BY_KEY, payload: productKey };
}

export function getProductActivationByKeyAndType(productKey: string, productType: string): IActionWithPayload {
  const payload = { productKey, productType };
  return { type: ProductActivationActionTypes.GET_PRODUCT_ACTIVATION_BY_KEY_AND_TYPE, payload };
}

export function getAvailableDiseaseModels(): IActionWithPayload {
  return { type: ProductActivationActionTypes.GET_AVAILABLE_DISEASE_MODELS, payload: null };
}

export function setAvailableDiseaseModels(diseaseModels: IDisease[]): IActionWithPayload {
  return { type: ProductActivationActionTypes.SET_AVAILABLE_DISEASE_MODELS, payload: diseaseModels };
}

export function getAvailableUserStations(productKey: string): IActionWithPayload {
  return { type: ProductActivationActionTypes.GET_AVAILABLE_USER_STATIONS, payload: productKey };
}

export function setAvailableUserStations(userStations: IAvailableUserStation): IActionWithPayload {
  return { type: ProductActivationActionTypes.SET_AVAILABLE_USER_STATIONS, payload: userStations };
}

export function getAvailableServiceLicenseStations(): IActionWithPayload {
  return { type: ProductActivationActionTypes.GET_AVAILABLE_SERVICE_LICENSE_STATIONS, payload: null };
}

export function setAvailableServiceLicenseStations(serviceLicenseStations: IAvailableUserStation): IActionWithPayload {
  return { type: ProductActivationActionTypes.SET_AVAILABLE_SERVICE_LICENSE_STATIONS, payload: serviceLicenseStations };
}

export function getProductActivationLicenseStatus(productPos: number, referenceDate: number, licenseGroup: string[]): IActionWithPayload {
  const payload: IVerifyLicense = {
    product_pos: productPos,
    reference_date: referenceDate,
    license_group: licenseGroup
  };
  return { type: ProductActivationActionTypes.GET_PRODUCT_ACTIVATION_LICENSE_STATUS, payload };
}

export function setProductActivationLicenseStatus(productPos: number, validLicenses: IValidLicense[]): IActionWithPayload {
  const payload = { productPos, validLicenses };
  return { type: ProductActivationActionTypes.SET_PRODUCT_ACTIVATION_LICENSE_STATUS, payload };
}

export function setProductActivationKey(productKey: string): IActionWithPayload {
  return { type: ProductActivationActionTypes.SET_PRODUCT_ACTIVATION_KEY, payload: productKey };
}

export function setProductActivationConfiguration(configuration: IProductConfiguration): IActionWithPayload {
  return { type: ProductActivationActionTypes.SET_PRODUCT_ACTIVATION_CONFIGURATION, payload: configuration };
}

export function setProductActivationConfigurationItem(configuration: IProductConfigurationItem): IActionWithPayload {
  return { type: ProductActivationActionTypes.SET_PRODUCT_ACTIVATION_CONFIGURATION_ITEM, payload: configuration };
}

export function updateProductActivationConfiguration(updated: ILicenseActivationItem): IActionWithPayload {
  return { type: ProductActivationActionTypes.UPDATE_PRODUCT_ACTIVATION_CONFIGURATION, payload: updated };
}

export function saveVirtualStationActivation(activation: IVirtualStationActivation): IActionWithPayload {
  return { type: ProductActivationActionTypes.SAVE_PRODUCT_ACTIVATION_VIRTUAL_STATION, payload: activation };
}

export function saveDiseaseModelActivation(activation: IDiseaseModelActivation): IActionWithPayload {
  return { type: ProductActivationActionTypes.SAVE_PRODUCT_ACTIVATION_DISEASE_MODEL, payload: activation };
}

export function saveForecastActivation(activation: IForecastActivation): IActionWithPayload {
  return { type: ProductActivationActionTypes.SAVE_PRODUCT_ACTIVATION_FORECAST, payload: activation };
}

export function saveServiceLicenseActivation(activation: IServiceLicenseActivation): IActionWithPayload {
  return { type: ProductActivationActionTypes.SAVE_PRODUCT_ACTIVATION_SERVICE_LICENSE, payload: activation };
}

export function saveDavisIngestActivation(activation: ILicenseActivationItem): IActionWithPayload {
  return { type: ProductActivationActionTypes.SAVE_PRODUCT_ACTIVATION_DAVIS_INGEST, payload: activation };
}

export function saveFarmViewActivation(activation: ILicenseActivationItem): IActionWithPayload {
  return { type: ProductActivationActionTypes.SAVE_PRODUCT_ACTIVATION_FARM_VIEW, payload: activation };
}

export function saveIScoutMobileActivation(activation: ILicenseActivationItem): IActionWithPayload {
  return { type: ProductActivationActionTypes.SAVE_PRODUCT_ACTIVATION_ISCOUT_MOBILE, payload: activation };
}
