import { Injectable } from '@angular/core';
import { IOptions, ISensor } from '../../../shared/interfaces';
import { IServerAlert } from '../interfaces/server-alert';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { from, Observable } from 'rxjs';
import { filter, groupBy, map, mergeMap, toArray } from 'rxjs/operators';
import { INodes, ISerialNames } from '../../sensors-and-nodes/models/sensors-and-nodes';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ServerAlertsService {

  protected options: IOptions[];
  protected sensors;
  public spaceReg = / /g;
  private atnodeString = '';

  constructor(
    private translations: TranslateService
  ) { }

  public setListOfSensors(sensors: ISensor[], nodes: INodes, serials: ISerialNames): IOptions[] {
    const options: Array<IOptions> = [];
    this.sensors = sensors;

    this.translations.get('at node')
      .subscribe(translatedValue => {
        this.atnodeString = translatedValue;
      });

    if (sensors && sensors.length) {
      for (const sensor of sensors) {
        if (sensor.isActive) {
          for (const aggr of sensor.aggr) {
            const nodeName = this.getNodeName(sensor, nodes, serials, this.atnodeString);
            const value = `${sensor.ch}_${sensor.code}_${aggr}_${sensor.unit}`;

            let content = this.getName(sensor);
            this.translations.get(content)
              .subscribe(translatedName => {
                content = translatedName + ` [${sensor.ch}] ` + nodeName;
                sensor.name = translatedName;
                options.push({ value: value, content: content });
            });
          }
        }
      }
    }
    return options;
  }

  protected getName(sensor: ISensor): string | any {
    const name = sensor.name_custom.length ? sensor.name_custom : sensor.name;
    return name;
  }

  private getNodeName(sensor: ISensor, nodes: INodes, serials: ISerialNames, atnodeString: string): string {
    if (sensor.serial !== 'X') {
      if (serials[sensor.serial] && serials[sensor.serial].name) {
        return ` ${atnodeString} ${serials[sensor.serial].name}`;
      } else {
        return ` ${atnodeString} ${sensor.serial}`;
      }
    }
    if (sensor.mac !== 'X') {
      if (nodes[sensor.mac] && nodes[sensor.mac].name) {
        return ` ${atnodeString} ${nodes[sensor.mac].name}`;
      } else {
        return ` ${atnodeString} ${sensor.mac}`;
      }
    }
    return '';
  }

  public findSensorData(alert: IServerAlert): {} | any {
    if (this.sensors) {
      const targetSensor = this.sensors.find(sensor =>
        sensor.ch === alert.sensorChannel &&
        sensor.code === alert.sensorCode
      );
      if (targetSensor) {
        const sensor = targetSensor;
        const name = sensor.name_custom || sensor.name;
        const unit = sensor.unit || sensor.unit_default;
        return { name, unit };
      } else {
        return { name: 'Station sensor', unit: '-' };
      }
    }
  }

  public filterOptions(value: string, sensors: IOptions[]): Observable<IOptions[]> {
    return from(sensors).pipe(
      groupBy(sensor => sensor.content),
      mergeMap(group => group.pipe(toArray())),
      map(group => {
        for (const aggr of [value, 'last', 'sum', 'avg']) {
          for (const option of group) {
            if (option.value.includes(aggr)) {
              return option;
            }
          }
        }
      }),
      filter(data => Boolean(data)),
      toArray()
    );
  }

  public askNotification(): Promise<any> {
    return new Promise((resolve, reject) => {
      const permissionResult = Notification.requestPermission(
        (result: NotificationPermission) => resolve(result)
      );

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    }).then((permissionResult: string) => {
      if (permissionResult !== 'granted') {
        throw new Error('We weren\'t granted permission.');
      }
    });
  }

  public sendNotification(title: string, body: string): any {
    return new Notification(title, { body });
  }

  public checkThreshold(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value: string = control.value.replace(this.spaceReg, '');
      const forbidden: boolean = /^[\-]?[0-9]+(([,.]+-?[0-9]+)*)?$/gm.test(value);
      return forbidden || !value ? null : { 'format': true };
    };
  }

  public checkEmail(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      // tslint:disable-next-line:max-line-length
      const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      const forbidden: boolean = re.test(String(control.value).toLowerCase());
      return forbidden || !control.value ? null : { 'format': true };
    };
  }
}
