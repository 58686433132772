<app-form-layout [shadow]="false">
  <div class="hydroponics-header" *ngIf="period$ | async as period">
    <div>
      <h2>{{ 'Period' | translate}}</h2>
      <small>{{ prepareDate(period.periodValue) | translate}} / {{ period.periodScope | translate}}</small>
    </div>
    <div class="hydroponics-header__right">
      <i class="zmdi zmdi-help" (click)="openModal.emit()"></i>
    </div>
  </div>
</app-form-layout>


