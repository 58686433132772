<app-form-layout [class.hide]="isTableActive === false">
  <div class="table-content">
    <ag-grid-angular *ngIf="columnDefs"
                     class="ag-theme-bootstrap"
                     [gridOptions]="dataGridOptions"
                     [rowData]="sampleData"
                     (rowClicked)="setActiveSample($event)"
                     [columnDefs]="columnDefs">
    </ag-grid-angular>
  </div>
</app-form-layout>
