import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IAddress } from '../../../../../../core/models/account';
import { Countries, NotSet } from '../../../../../../shared/constants';
import { IOptions } from '../../../../../../shared/interfaces';
import { deepClone } from '../../../../../../shared/utils/deepClone';

@Component({
  selector: 'app-address-info',
  templateUrl: './address-info.component.html',
  styleUrls: ['./address-info.component.scss']
})
export class AddressInfoComponent implements OnInit, OnDestroy {
  @Input()
  public addressInfo             : IAddress;
  @Output()
  private addressInfoChange      : EventEmitter<IAddress> = new EventEmitter();
  public form                    : FormGroup;
  public countries               : Array<IOptions> = deepClone(Countries);
  private alive$                 : Subject<boolean> = new Subject<boolean>();
  private notSet                 : IOptions = NotSet;

  constructor(private formBuilder: FormBuilder) { }

  public ngOnInit(): void {
    this.countries.unshift(this.notSet);

    this.form = this.formBuilder.group({
      'city': [this.addressInfo.city || ''],
      'country': [this.addressInfo.country || ''],
      'district': [this.addressInfo.district || ''],
      'street': [this.addressInfo.street || ''],
      'zip': [this.addressInfo.zip || ''],
    });

    this.addressInfoChange.emit(this.form.value);

    this.form.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this.alive$),
    ).subscribe((form: IAddress) => {
      this.addressInfoChange.emit(form);
    });
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }

}
