import { take } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  getWidgetData,
  removeWidget,
  setWidgetError,
  setWidgetLoading,
  updateWidgetEditMode,
  updateWidgetSettings
} from '../../../actions/dashboard';
import { IDiseaseRequest, ILoadWidgetData, IUpdateWidgetEditMode, IWidget } from '../../../models/dashboard.models';
import * as fromDashboard from '../../../reducers/dashboard';
import { selectEdit } from '../../../reducers/index';
import { ApiCallService } from '../../../../../services/api/api-call.service';
import * as moment from 'moment';
import { deepClone } from '../../../../../shared/utils/deepClone';
import { IFromTo } from '../../../../tracker/models/tracker.models';
import { IStation } from '../../../../../core/models/stations';
import { TranslateService } from '@ngx-translate/core';
import { IAccount } from '../../../../../core/reducers/account';
import { selectStations } from '../../../../../core/reducers';
import { dateToUtcUnixTimestamp } from '../../../../../shared/utils/dateFormat';

@Component({
  selector: 'app-disease-widget',
  templateUrl: './disease-widget.component.html',
  styleUrls: ['./disease-widget.component.scss']
})
export class DiseaseWidgetComponent implements OnInit {
  @Input()
  public widget                     : IWidget;
  @Input()
  private widgetIndex               : number;
  @Input()
  private columnIndex               : number;
  @Input()
  private rowIndex                  : number;
  public isEdit$                    : Observable<boolean>;
  private loadWidgetData            : ILoadWidgetData;
  private licenses                  : Array<IFromTo>;

  public licenseExpiredOn           : string;
  public toRenew                    : string;
  public expirationDate             : string;
  public isExpired                  : boolean = false;
  private stations                  : Array<IStation>;

  constructor(
    private dashboardStore: Store<fromDashboard.IDashboard>,
    private api: ApiCallService,
    private translate: TranslateService,
    private account: Store<IAccount>) {}

  public get diseaseDescription(): string {
    return `${this.widget.requests.disease.profile}
    : ${this.widget.requests.disease.endpoint.crop}
    - ${this.widget.requests.disease.endpoint.diseaseName}`;
  }

  public ngOnInit(): void {
    this.isEdit$ = this.dashboardStore.pipe(select(selectEdit));
    if (!this.widget.loadData) {
      this.account.pipe(
        select(selectStations)
      ).subscribe((stations) => this.stations = stations);

      this.loadDiseaseData();
      this.getTranslatedStrings();
    }
  }

  private resetLoading(): void {
    this.dashboardStore.dispatch(setWidgetLoading({
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      loading: true
    }));
    this.dashboardStore.dispatch(setWidgetError({
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      error: ''
    }));
  }

  public removeCurrentWidget(): void {
    const path: Array<number> = [this.rowIndex, this.columnIndex, this.widgetIndex];
    this.dashboardStore.dispatch(removeWidget(path));
  }

  public loadDiseaseData(): void {
    this.api.getStationLicenses(this.widget.requests.disease.endpoint.station).pipe().subscribe((response: any) => {
      this.licenses = deepClone(response.models[this.widget.requests.disease.endpoint.crop]);
      const station = this.stations.find((s: IStation) => s.name.original === this.widget.requests.disease.endpoint.station);
      const validToDate: moment.Moment = this.getValidToDate(this.licenses, station);
      const period = parseInt(this.widget.requests.disease.endpoint.last, 10) ;
      const hoursOrDays = this.widget.requests.disease.endpoint.group === 'daily' ? 'days' : 'hours';

      this.loadWidgetData = {
        url: '/fc/' + this.widget.requests.disease.endpoint.station
        + '/' + this.widget.requests.disease.endpoint.group
        + '/from/' + dateToUtcUnixTimestamp(validToDate.clone().subtract(period, hoursOrDays).toDate())
        + '/to/' + dateToUtcUnixTimestamp(validToDate.toDate()),
        path: [this.rowIndex, this.columnIndex, this.widgetIndex],
        profile: `disease, ${this.widget.requests.disease.endpoint.disease}`
      };

      this.resetLoading();
      this.dashboardStore.dispatch(getWidgetData(this.loadWidgetData));
    });
  }

  private getValidToDate(licenses: Array<IFromTo>, station: IStation): moment.Moment {
    const allToDates = licenses.map((license: IFromTo) => moment(license.to));
    const newestDiseaseLicenseEndDate = moment.max(allToDates);
    this.expirationDate = newestDiseaseLicenseEndDate.format('DD/MM/YYYY');
    this.isExpired = newestDiseaseLicenseEndDate.isBefore(moment());
    const licenseEndDate = newestDiseaseLicenseEndDate.isAfter(moment())  ? moment() : newestDiseaseLicenseEndDate;
    const maxStationDate = moment(station.dates.max_date);
    if (maxStationDate.isBefore(licenseEndDate)) {
      return maxStationDate;
    } else {
      return licenseEndDate;
    }
  }

  public updateRequest(updateSettings: IDiseaseRequest): void {
    this.dashboardStore.dispatch(updateWidgetSettings({
      type: 'disease',
      updateSettings: updateSettings,
      path: [this.rowIndex, this.columnIndex, this.widgetIndex]
    }));
  }

  public editMode(editMode: boolean): void  {
    const updateSettings: IUpdateWidgetEditMode = {
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      type: 'disease',
      isEdit: editMode,
    };
    this.dashboardStore.dispatch(updateWidgetEditMode(updateSettings));
  }

  private getTranslatedStrings(): void {
    this.translate.get('Your license has expired on').pipe(take(1)).subscribe((translatedText: string) => {
      this.licenseExpiredOn = translatedText;
    });
    this.translate.get('To renew it, please contact your METOS salesperson.').pipe(take(1)).subscribe((translatedText: string) => {
      this.toRenew = translatedText;
    });
  }
}
