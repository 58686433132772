import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { IFrostProtectionState } from '../../models/models';
import { takeUntil } from 'rxjs/operators';
import {
  selectFrostProtectionError,
  selectFrostProtectionLoading,
  selectFrostProtectionResponse
} from '../../selectors/selectors';
import {
  setFrostProtectionError,
  setFrostProtectionErrorMessage
} from '../../actions/actions';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { generateId } from '../../../dashboard/utils/makeWidget';

@Component({
  selector: 'app-frost-protection-content',
  templateUrl: './frost-protection-content.component.html',
  styleUrls: ['./frost-protection-content.component.scss']
})
export class FrostProtectionContentComponent implements OnInit, OnDestroy {

  private destroy$                  : Subject<boolean> = new Subject<boolean>();

  public isLoading$                 : Observable<boolean>;
  public isError$                   : Observable<boolean>;
  public exportChartImg             : boolean = false;
  public errorMessage               : string = null;
  public forecastRecordsLength      : number = null;
  public measurementRecordsLength   : number = null;
  public FROST_HELP_MODAL_ID        : string = generateId();

  constructor(
    private frostProtectionStore    : Store<IFrostProtectionState>,
    private modalService            : ModalService
  ) { }

  public ngOnInit(): void {
    this.isError$ = this.frostProtectionStore.pipe(
      takeUntil(this.destroy$),
      select(selectFrostProtectionError)
    );
    this.isLoading$ = this.frostProtectionStore.pipe(
      takeUntil(this.destroy$),
      select(selectFrostProtectionLoading)
    );

    // get forecast records length of a new response
    this.frostProtectionStore.pipe(
      select(selectFrostProtectionResponse),
      takeUntil(this.destroy$)
    ).subscribe((response: any) => {
      if (response) {
        this.errorMessage = null;
        if (response.info.forecast_records_length) {
          this.forecastRecordsLength = response.info.forecast_records_length;
        }
        if (response.info.measure_records_length) {
          this.measurementRecordsLength = response.info.measure_records_length;
        }
        if (response.hasOwnProperty('sensors') === false || !response.sensors.length) {
          this.errorMessage = 'No required sensors';
          setFrostProtectionErrorMessage(this.errorMessage);
          setFrostProtectionError(true);
        }
      }
    });
  }

  public openModal(): void {
    this.modalService.openModal(this.FROST_HELP_MODAL_ID );
  }

  public exportCharts(value : boolean): void {
    this.exportChartImg = value;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
