import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {MachinerySettingsRoutingModule} from './machinery-settings-routing.module';
import {MachinerySettingsComponent} from './machinery-settings.component';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {reducer} from './reducers/index';
import {MachinerySettingsService} from './effects/machinery-settings.service';
import {AgGridModule} from 'ag-grid-angular';

@NgModule({
  imports: [
    CommonModule,
    MachinerySettingsRoutingModule,
    TranslateModule.forChild(),
    SharedModule,
    AgGridModule.withComponents([]),
    StoreModule.forFeature('machinery-settings', reducer),
    EffectsModule.forFeature([MachinerySettingsService])
  ],
  declarations: [
    MachinerySettingsComponent
  ],
  providers: [],
  exports: [MachinerySettingsComponent]
})
export class MachinerySettingsModule { }



