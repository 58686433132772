import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions } from 'ag-grid';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { environmentToken } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../../../environments/interfaces/theme';
import { IStation } from '../../../../core/models/stations';
import { selectSettings, selectStations } from '../../../../core/reducers';
import { IAccount } from '../../../../core/reducers/account';
import * as fromStations from '../../../../core/reducers/stations';
import { NavigationService } from '../../../../core/services/navigation/navigation.service';
import { IOptions } from '../../../../shared/interfaces';
import { ExcelService } from '../../../../shared/services/excel-service/excel-service.service';
import { deepClone } from '../../../../shared/utils/deepClone';
import {
  ColumnsForSort,
  DefaultColumnSorting,
  RowStyle, StationListHeaders, StationListRemovedOptions,
  StationsListOptions
} from '../../constants/constants';

@Component({
  selector: 'app-stations-list-sort',
  templateUrl: './stations-list-sort.component.html',
  styleUrls: ['./stations-list-sort.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StationsListSortComponent implements OnInit, OnDestroy {

  private stationsSub             : Subscription;
  private sortedColumn            : any[] = [];
  private dropdownChanged         : boolean = false;
  private defaultSorting          : any = DefaultColumnSorting;
  private sortingOnChoosenColumn  : boolean = false;
  private unitSystem              : string;
  private subDomain                   : IThemeConfig;
  private destroy$                : Subject<boolean> = new Subject<boolean>();

  public stations                 : any;
  public form                     : FormGroup;
  public dataGridOptions          : GridOptions;
  public columnDefs               : any[];
  public rowStyle                 : any = RowStyle;
  public dropDownOptions          : Array<IOptions> = StationsListOptions;
  public page                     : number = 1;
  public itemLimit                : number = 15;

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    private stationsStore         : Store<fromStations.IStations>,
    private accountStore          : Store<IAccount>,
    private changeDetector        : ChangeDetectorRef,
    private router                : Router,
    private formBuilder           : FormBuilder,
    private translationService    : TranslateService,
    private excelService          : ExcelService,
    private navigationService     : NavigationService,
  ) {
    this.subDomain = environment.theme;

    this.stationsSub = stationsStore.pipe(
      select(selectStations),
      takeUntil(this.destroy$)
    ).subscribe(stations => {
      if (stations) {
        this.stations = this.getData(stations);               // save only station infos we need
      }
    });

    this.accountStore.pipe(
      select(selectSettings),
      filter((settings) => !!settings),
      takeUntil(this.destroy$)
    ).subscribe(settings => {
      this.unitSystem = settings.unit_system;
    });

    this.getColumnDefs();

    this.columnDefs.forEach((column, i) => {           // translate table headers
      this.translationService.get(column.headerName + this.getUnit(column.field))
        .subscribe(translatedHeader => {
          this.columnDefs[i].headerName = translatedHeader;
          this.columnDefs[i].headerTooltip = translatedHeader;
        });
    });
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      column: ['rain24h', [Validators.required]]
    });

    this.getGridOptions();

    if (this.stations) {
      this.setDefaultSorting();
      this.onSortChange('');
    }

    this.column.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged()
    )
      .subscribe((choosenColumn: string) => {                            // change the choosen column
        this.columnDefs[5].hasOwnProperty('sort') ?
          this.sortingOnChoosenColumn = true :
          this.sortingOnChoosenColumn = false;

        this.columnDefs[5].field = choosenColumn;
        this.columnDefs[5].headerName = this.dropDownOptions
          .find(option => option.value === choosenColumn).content.toUpperCase();

        this.translationService.get(this.columnDefs[5].headerName + this.getUnit(this.columnDefs[5].field))        // translate header
          .subscribe(translatedHeader => {
            this.columnDefs[5].headerName = translatedHeader;
            this.columnDefs[5].headerTooltip = translatedHeader;
          });

        this.dropdownChanged = true;
        this.onSortChange('');
      });

    this.accountStore.pipe(
      select(selectSettings),
      filter((settings) => !!settings),
      takeUntil(this.destroy$)
    ).subscribe(settings => {
      this.unitSystem = settings.unit_system;
    });
  }

  private getColumnDefs(): void {
    this.columnDefs = [
      {
        headerName: 'STATION ID',
        field: 'stationID',
        suppressMenu: true,
        suppressMovable: true,
        headerClass: 'table-header-padding-first',
        cellStyle: { paddingLeft: '25px;' },
        cellRenderer: (params) => {                                   // mark station IDs as links
          const link = this.prepareNavigateToUrl(params.value);
          const element = document.createElement('span');
          element.style.cssText = 'color:dodgerblue;' +
            'text-decoration:none;' +
            'cursor:pointer';
          element.innerHTML = params.value;
          element.addEventListener('click', () => {
            this.router.navigate([link]);
          });
          element.addEventListener('mouseover', () => {
            element.style.cssText = 'color:#0a6ebd;' +
              'text-decoration:none;' +
              'cursor:pointer';
          });
          element.addEventListener('mouseout', () => {
            element.style.cssText = 'color:dodgerblue;' +
              'text-decoration:none;' +
              'cursor:pointer';
          });
          return element;
        }
      },
      {
        headerName: 'STATION NAME',
        field: 'stationName',
        suppressMenu: true,
        resizable: false,
        suppressMovable: true
      },
      {
        headerName: 'DEVICE TYPE',
        field: 'deviceType',
        suppressMenu: true,
        resizable: false,
        suppressMovable: true
      },
      {
        headerName: 'LAST DATA READING',
        field: 'max_date',
        suppressMenu: true,
        resizable: false,
        suppressMovable: true
      },
      {
        headerName: 'BATTERY [mV]',
        field: 'battery',
        suppressMenu: true,
        resizable: false,
        suppressMovable: true,
        cellClass: 'leftAlignment',
        cellRenderer: (params) => {                                                 // insert battery icon
          if (params.value && params.data.meta.battery) {
            const customClass = this.getBattery(params.value, params.data.meta);
            const element = document.createElement('i');
            element.className = 'fa ' +
              customClass +
              ' ng-star-inserted padding';
            element.innerHTML = '<span>' + params.value + '</span>';
            return element;
          }
        }
      },
      {
        headerName: 'RAIN, 24 HOURS',
        field: 'rain24h',
        suppressMenu: true,
        resizable: false,
        suppressMovable: true
      },
      {
        headerName: 'RAIN 24H / 7D',
        field: 'rainfall',
        suppressMenu: true,
        resizable: false,
        suppressMovable: true,
        sortable: false,
        suppressSorting: true,
        cellRenderer: (params) => {                                                 // insert rain bar graphic
          if (params.value) {
            const rainHeight = this.getRainBar(params.data.meta);
            const rain7days = params.data.meta.rain7d.vals;
            const elements = [];

            const div = document.createElement('div');
            div.style.cssText = 'height: 33px; display: flex; align-items: center';
            rain7days.forEach((value, i) => {
              value = Number(Number(value).toFixed(2));
              const el = document.createElement('div');
              el.className = 'rainBar ng-star-inserted';
              el.style.cssText = 'height:' + rainHeight[i] + 'px; align-self: flex-end;';
              el.title = params.data.metaUnits === 'metric' ? value + ' mm' : +(value * (0.0393700787)).toFixed(2) + ' in';
              div.appendChild(el);
              return el;
            });
            const span = document.createElement('span');
            span.innerHTML = params.value;
            span.className = 'value';
            div.appendChild(span);
            return div;
          }
        }
      }
    ];

    if (this.subDomain && this.subDomain.hasOwnProperty('hideStationListDropdown')) {
      if (this.subDomain.hideStationListDropdown) {
        StationListRemovedOptions.forEach(option => {
          this.columnDefs = this.columnDefs.filter(col => col.field !== option);
        });
      }
    }
  }

  private getGridOptions(): void {
    this.dataGridOptions = {
      columnDefs: [],
      rowData: [],
      enableColResize: false,
      enableSorting: true,
      enableServerSideSorting: true,
      rowClassRules: {
        'no-resize': () => true,
      },
      rowHeight: 38,
      headerHeight: 42,
      defaultColDef: {
        cellStyle: { padding: '2.5px 2.5px 2.5px 12px' },
      },
      rowSelection: 'single',
      suppressContextMenu: true,
      onGridReady: (event) => {
        event.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (event) => {
        event.api.sizeColumnsToFit();
      }
    };
  }

  public prepareNavigateToUrl(id: string): string {
    return this.navigationService.prepareNavigateToUrl(id);
  }

  public get column(): AbstractControl {
    return this.form.get('column');
  }

  private getData(stations: Array<IStation>): any[] {
    return stations.map((station: any) => {
      return {
        stationID: station.name.original,
        stationName: station.name.custom ? station.name.custom : '',
        deviceType: station.info.device_name,
        lastCommunication: station.dates.last_communication,
        max_date: station.dates.max_date,
        battery: station.meta && station.meta.battery ? station.meta.battery : '',
        rainfall: station.meta && station.meta.rain7d ? this.getRainfall(station) : '',
        rain7d: station.meta && station.meta.rain7d ? this.getRain7d(station) : '',
        rain24h: station.meta && station.meta.rain24h ? this.getRain24h(station) : '',
        rain2d: station.meta && station.meta.rain48h ? this.getRain2d(station) : '',
        airTemperature: station.meta && station.meta.airTemp ? this.getAirTemperature(station) : '',
        relativeHumidity: station.meta && station.meta.rh ? station.meta.rh : '',
        soilTemperature: station.meta && station.meta.soilTemp ? this.getSoilTemperature(station) : '',
        solarRadiation: station.meta && station.meta.solarRadiation ? station.meta.solarRadiation : '',
        windSpeed: station.meta && station.meta.windSpeed ? this.getWindSpeed(station) : '',
        wetBulb: station.meta && station.meta.wetBulb ? this.getWetBulbTemperature(station) : '',
        dryBulb: station.meta && station.meta.dryBulb ? station.meta.dryBulb : '',
        lw: station.meta && station.meta.lw ? station.meta.lw : '',
        solarPanel: station.meta && station.meta.solarPanel ? station.meta.solarPanel : '',
        volumeter: station.meta && station.meta.volumeter ? station.meta.volumeter : '',
        tensiometer: station.meta && station.meta.tensiometer ? station.meta.tensiometer : '',
        meta: station.meta ? station.meta : '',
        metaUnits: station.metaUnits
      };
    });
  }

  public getBattery(battery: number, deviceId: number): string {
    const nbiotStations = [49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 76, 77];
    let isNbiot = false;

    if (nbiotStations.indexOf(deviceId) !== -1) {
      isNbiot = true;
    }

    let className: string = 'fa-battery-1';
    // Nbiot stations
    if (isNbiot && battery < 4000) {                // for exceptions
      if (battery >= 3200) {
        className = 'fa-battery-4';
      }
      if (battery < 3200 && battery >= 3000) {
        className = 'fa-battery-3';
      }
      if (battery < 3000 && battery >= 2600) {
        className = 'fa-battery-2';
      }
    } else {
      if (battery >= 6400) {
        className = 'fa-battery-4';
      }
      if (battery < 6400 && battery >= 6200) {
        className = 'fa-battery-3';
      }
      if (battery < 6200 && battery >= 6100) {
        className = 'fa-battery-2';
      }
    }

    return className;
  }

  public getRainfall(station: any): string {
    if (station.metaUnits === 'metric') {
      return station.meta.rain24h.sum + ' mm / ' +
        station.meta.rain7d.sum + ' mm';
    } else {
      return +(station.meta.rain24h.sum * 0.0393700787).toFixed(2) + ' in / ' +
        +(station.meta.rain7d.sum * 0.0393700787).toFixed(2) + ' in';
    }
  }

  public getRain7d(station: any): string {
    return station.metaUnits === 'metric' ?
      station.meta.rain7d.sum :
      +(station.meta.rain7d.sum * 0.0393700787).toFixed(2);
  }

  public getRain24h(station: any): string {
    return station.metaUnits === 'metric' ?
      station.meta.rain24h.sum :
      +(station.meta.rain24h.sum * 0.0393700787).toFixed(2);
  }

  public getRain2d(station: any): string {
    return station.metaUnits === 'metric' ?
      station.meta.rain48h.sum :
      +(station.meta.rain48h.sum * 0.0393700787).toFixed(2);
  }

  public getAirTemperature(station: any): number {
    return station.metaUnits === 'metric' ?
      station.meta.airTemp :
      +(station.meta.airTemp * (9 / 5) + 32).toFixed(2);
  }

  public getSoilTemperature(station: any): number {
    return station.metaUnits === 'metric' ?
      station.meta.soilTemp :
      station.meta.soilTemp * (9 / 5) + 32;
  }

  public getWetBulbTemperature(station: any): number {
    return station.metaUnits === 'metric' ?
      station.meta.wetBulb :
      +(station.meta.wetBulb * (9 / 5) + 32).toFixed(2);
  }

  public getWindSpeed(station: any): number {
    return station.metaUnits === 'metric' ?
      +(station.meta.windSpeed).toFixed(1) :          // windspeed always 1 decimal
      +(station.meta.windSpeed / 0.44704).toFixed(1);
  }

  public getRainBar(meta: any): number[] {
    if (meta && meta.rain7d) {
      const clonedVals = deepClone(meta.rain7d.vals);
      const largest = clonedVals.sort((a: any, b: any) => a - b).reverse()[0];

      const rain7d = [];
      meta.rain7d.vals.forEach((value, i) => {
        if (value === 0) {
          rain7d.push(1);
        } else {
          rain7d.push( (33 * value) / largest );
        }
      });
      return rain7d;
    }
  }

  private updateTable(): void {
    setTimeout(() => {
      if (this.dataGridOptions && this.dataGridOptions.api) {
        this.dataGridOptions.api.setRowData(this.stations           // show only 15 stations on the page
          .filter((station, i) =>
            i >= (this.page - 1) * this.itemLimit &&
            i < this.page * this.itemLimit)
        );
        this.dataGridOptions.api.setColumnDefs(this.columnDefs);
        this.dataGridOptions.api.sizeColumnsToFit();
      }
    }, 0);
  }

  public onPageChange(page): void {
    this.page = page.page;
    this.updateTable();
  }

  public onSortChange(columnEvent): void {
    if (this.dataGridOptions && this.dataGridOptions.api) {
      this.sortedColumn = this.dataGridOptions.api.getSortModel();

      if (this.dropdownChanged && this.sortedColumn.length) {          // if dropdown value was changed and if sorting is set
        if (this.sortingOnChoosenColumn) {                             // if the sorting is set on the choosen column, keep the sorting here
          this.sortedColumn[0] = {
            colId: this.columnDefs[5].field,
            sort: this.dataGridOptions.api.getSortModel()[0].sort
          };
        } else {                                                        // otherwise keep it on other column
          this.sortedColumn = this.dataGridOptions.api.getSortModel();
        }

        this.dropdownChanged = false;
      }

      // updating columnDefs to set 'sort' on chosen column
      if (this.sortedColumn.length) {                                       // if sort is 'asc' or 'desc'
        this.columnDefs.forEach((column, i) => {
          if (this.columnDefs[i].field === this.sortedColumn[0].colId) {    // set 'sort' on chosen column, delete previous sorting
            this.columnDefs[i].sort = this.sortedColumn[0].sort;
          } else {
            delete this.columnDefs[i].sort;
          }
        });

        const sortCol = ColumnsForSort                                      // check if column contains numbers or strings and sort it
          .find(col => col.name === this.sortedColumn[0].colId);
        if (sortCol?.value === 'numbers') {
          this.sortNumbers();
        } else if (sortCol?.value === 'strings') {
          sortCol?.name === 'stationName' ? this.sortAlphaNumeric() : this.sortStrings();
        }
      } else {                                                              // if sort is not set (not 'asc' or 'desc')
        this.columnDefs.forEach((column, i) => {                    // delete sorting - set the default sorting (stationID, asc)
          if (i === 0) {
            this.setDefaultSorting();
            this.sortStrings();
          } else {
            delete this.columnDefs[i].sort;
          }
        });
      }
    } else if (this.dataGridOptions && !this.dataGridOptions.api) {         // on init
      this.setDefaultSorting();
      this.sortStrings();
    }

    this.updateTable();
  }

  private sortNumbers(): void {
    if (this.sortedColumn[0].sort === 'asc') {
      this.stations.sort((a, b) =>
        a[this.sortedColumn[0].colId] < b[this.sortedColumn[0].colId] ? -1 : 1
      );
    } else if (this.sortedColumn[0].sort === 'desc') {
      this.stations.sort((a, b) =>
        a[this.sortedColumn[0].colId] > b[this.sortedColumn[0].colId] ? -1 : 1
      );
    }
  }

  private sortStrings(): void {
    if (this.sortedColumn[0].sort === 'asc') {
      this.stations.sort((a, b) => {
        if (a[this.sortedColumn[0].colId] !== undefined && b[this.sortedColumn[0].colId] !== undefined) {
          return a[this.sortedColumn[0].colId].toLowerCase().localeCompare(b[this.sortedColumn[0].colId].toLowerCase());
        }
      });
    } else if (this.sortedColumn[0].sort === 'desc') {
      this.stations.sort((a, b) => {
        if (a[this.sortedColumn[0].colId] !== undefined && b[this.sortedColumn[0].colId] !== undefined) {
          return b[this.sortedColumn[0].colId].toLowerCase().localeCompare(a[this.sortedColumn[0].colId].toLowerCase());
        }
      });
    }
  }

  private sortAlphaNumeric(): void {
    if (this.sortedColumn[0].sort === 'asc') {
      this.stations.sort((a, b) =>
        a[this.sortedColumn[0].colId].toLowerCase().toString().localeCompare(
          b[this.sortedColumn[0].colId].toLowerCase().toString(), 'en', { numeric: true }));
    } else if (this.sortedColumn[0].sort === 'desc') {
      this.stations.sort((a, b) =>
        b[this.sortedColumn[0].colId].toLowerCase().toString().localeCompare(
          a[this.sortedColumn[0].colId].toLowerCase().toString(), 'en', { numeric: true }));
    }
  }

  public exportAsExcel(): void {
    const datetime = moment(new Date()).format('YYYY-MM-DD');
    const fileName = `stations_list_${datetime}`;
    const feature = 'stationsList';

    this.excelService.exportAsExcelFile(this.getExcelData(), fileName, feature);
  }

  private getExcelData(): any[] {
    const excelData = [];

    const headers = StationListHeaders;
    this.stations.forEach(station => {
      const obj = {};

      headers.forEach((header) => {
        const unit = header.unit ? header.unit[this.unitSystem] : '';
        const key = header.headerName + unit;
        const value = station[header.field];
        obj[key] = value;
      });

      excelData.push(obj);
    });
    return excelData;
  }

  private setDefaultSorting(): void {
    this.sortedColumn = this.defaultSorting;
    this.columnDefs[0].sort = 'asc';
  }

  private getUnit(columnName: string): string {
      switch (columnName) {
        case 'rain7d':
        case 'rain2d':
        case 'rain24h':
        case 'rainfall':
          return this.unitSystem === 'metric' ? ' [mm]' : ' [in]';
        case 'airTemperature':
        case 'soilTemperature':
        case 'wetBulb':
        case 'dryBulb':
          return this.unitSystem === 'metric' ? ' [°C]' : ' [°F]';
        case 'relativeHumidity':
          return ' [%]';
        case 'solarRadiation':
          return ' [W/m²]';
        case 'windSpeed':
          return this.unitSystem === 'metric' ? ' [m/s]' : ' [mph]';
        case 'lw':
          return ' [min]';
        case 'solarPanel':
        case 'volumeter':
        case 'tensiometer':
        default:
          return '';
      }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
