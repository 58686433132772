import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Column, GridOptions } from 'ag-grid';
import { Options } from 'highcharts';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { ISettings } from '../../../../core/models/account';
import { selectSelectedStation, selectSettings } from '../../../../core/reducers';
import * as fromAccount from '../../../../core/reducers/account';
import * as fromSelectedStation from '../../../../core/reducers/selectedStation';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { DataGridOptions } from '../../../../shared/constants';
import { IOptions } from '../../../../shared/interfaces';
import { deepClone } from '../../../../shared/utils/deepClone';
import { addToLocalStorageObject } from '../../../../shared/utils/localStorageSync';
import { SampleDataService } from '../../../cropzone-sample/services/sample-data.service';
import { DEFAULT_CHART_OPTIONS } from '../../../irrimet/constants/constants';
import * as fromStationSettings from '../../../station-data/reducers/station-data-settings';
import { RAIN_SUM_COLUMN_DEFS } from '../../constants/constants';
import {
  IAccumulationCalculationValues,
  IAccumulationRainRequest,
  IAccumulatorToolState
} from '../../interfaces/interfaces';
import { selectStationSensors } from '../../selectors/selectors';
import { ISelectedSearchWidgetItem } from '../../../../core/models/selectedSearchWidgetItem';

@Component({
  selector: 'app-accumulator-tool-rain-sum',
  templateUrl: './accumulator-tool-rain-sum.component.html',
  styleUrls: ['./accumulator-tool-rain-sum.component.scss']
})
export class AccumulatorToolRainSumComponent implements OnInit, OnDestroy {

  private stationId         : string = '';
  private alive             : boolean = true;
  private rainSumTableData  : any = {};
  private destroy$          : Subject<boolean> = new Subject<boolean>();

  public form               : FormGroup;
  public sensorsOptions     : Array<IOptions>;
  public unitSystem         : string = '';
  public isLoading          : boolean = false;
  public exportChartImg     : boolean = false;
  public isTableHidden      : boolean = false;
  public response           : any = null;
  public columnDefs         : any;
  public dataGridOptions    : GridOptions = DataGridOptions;
  public maxDate            = new Date();
  public chartData          : any;
  public chartOptions       : Options;
  public noData             : boolean = false;
  public noSensors          : boolean = false;
  public translatedValues   : IAccumulationCalculationValues;
  public isActive$          : Observable<boolean>;

  constructor(
    private fb                    : FormBuilder,
    private api                   : ApiCallService,
    private selectedStationStore  : Store<fromSelectedStation.ISelectedStationState>,
    private stationSettingsStore  : Store<fromStationSettings.IStationDataSettingsState>,
    private accountStore          : Store<fromAccount.IAccount>,
    private accumulatorToolStore  : Store<IAccumulatorToolState>,
    private route                 : ActivatedRoute,
    private sampleDataService     : SampleDataService
  ) {
    this.selectedStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectSelectedStation)
    ).subscribe((station: ISelectedSearchWidgetItem) => {
      if (station.original_name !== this.stationId) {
        this.stationId = station.original_name;
      }
    });
  }

  public ngOnInit(): void {
    this.translatedValues = this.route.snapshot.data['accumulatorToolResolver'];

    this.form = this.fb.group({
      'sensor': ['', [Validators.required]],
      'from': [moment().startOf('year').toDate(), Validators.required],
      'to': [moment().endOf('day').toDate(), Validators.required],
      'isChartActive': [true, [Validators.required]],
      'isTableActive': [true, [Validators.required]]
    });

    this.columnDefs = RAIN_SUM_COLUMN_DEFS;
    this.dataGridOptions.columnDefs = this.columnDefs;
    this.dataGridOptions = {
      ...this.dataGridOptions,
      suppressClickEdit: true,
      onGridReady: this.onGridReady.bind(this),
    };

    this.accountStore.pipe(
      takeWhile(() => this.alive),
      select(selectSettings),
      filter((settings: ISettings) => !!settings)
    ).subscribe((settings: ISettings) => {
      this.unitSystem = settings.unit_system;
      if (this.unitSystem === 'imperial') {
        this.translatedValues['rainSum'] += ' [inch]';
      } else {
        this.translatedValues['rainSum'] += ' [mm]';
      }
    });

    this.accumulatorToolStore.pipe(
      takeWhile(() => this.alive),
      select(selectStationSensors),
      map((sensors: Array<any>) => sensors.filter((s: any) => s.group === 5)),
      tap ((sensors: Array<any>) => {
        if (sensors.length === 0) {
          this.noSensors = true;
        }
      }),
      map((sensors: Array<any>) => sensors.map((s: any) => {
        return {
          value: s.ch,
          content: `${s.name} [${s.ch}]`
        };
      })),
      filter((options: Array<IOptions>) => !!options.length)
    ).subscribe((options: Array<IOptions>) => {
      this.sensorsOptions = options;
      this.sensor.setValue(options[0].value);
      this.response = null;
      this.chartData = null;
      this.noData = true;
      this.noSensors = false;
    });
  }

  public onGridReady(event: any) : void {
    this.dataGridOptions.api = event.api;
    if (this.response) {
      this.setDegreeDaysData();
    }
  }

  public setDegreeDaysData() : void {
    this.dataGridOptions.api.setRowData(this.rainSumTableData);
    this.toggleColumns();
  }

  public calculateAccumulation(): void {
    this.isLoading = true;
    this.response = null;
    this.chartData = null;
    this.noData = true;

    const startDateUTC: Date = moment(moment(this.from.value).format('YYYY-MM-DDTHH:mm') + 'Z').toDate();
    const endDateUTC: Date = moment(moment(this.to.value).format('YYYY-MM-DDTHH:mm') + 'Z').toDate();
    const startDate = moment(startDateUTC).format('X');
    const endDate = moment(endDateUTC).format('X');

    const reqBody: IAccumulationRainRequest = {
      type : 'rain',
      ch: +this.sensor.value,
      date_from: startDate,
      date_to: endDate,
    };

    this.api.calculationRain(this.stationId, reqBody).pipe(
      takeWhile(() => this.alive)
    ).subscribe((response: any) => {
      this.isLoading = false;
      this.noData = false;
      this.response = response;
      this.chartData = response.chart;
      this.rainSumTableData = this.formatRainSumData(response.chart);
      if (this.chartData) {
        this.createChart();
      }
    }, () => {
      this.isLoading = false;
    });
  }

  private formatRainSumData(response) : any {
    const result = [];
    for (const key of Object.keys(response)) {
      result.push({
          dt: key,
          rain_sum: response[key]
        });
    }
    return result;
  }

  private createChart(): void {
    this.chartOptions = {
      ...deepClone(DEFAULT_CHART_OPTIONS),
      legend: {
        enabled: true
      },
      plotOptions: {
        series: {
          states: {
            hover: {
              enabled: false
            }
          },
          marker: {
            states: {
              hover: {
                enabled: false
              }
            }
          },
          events: {
            legendItemClick: (event: any) => {
              this.changeVisible('Rain Sum', event.target.options.visible);
            }
          }
        },
        line: {
          softThreshold: false
        }
      },
      chart: {
        height: 400,
        marginLeft: 80,
        marginRight: 80,
        marginTop: 50,
        style: { fontFamily: 'Helvetica' },
        zoomType: 'x',
        alignTicks: false,
      },
      yAxis: [
        {
          title: {
            text: this.translatedValues['rainSum']
          },
          minRange : 0.1,
          softMax: 10,
          min: 0,
        }
      ],
      xAxis: {
        crosshair: true,
        gridLineWidth: 1,
        labels: { style: { fontSize: '12px' } },
        lineWidth: 1,
        type: 'datetime',
      },
      series: [
        {
          type: 'line',
          name: this.translatedValues['rainSum'],
          data: [],
          color: '#3F729B',
          yAxis: 0
        } as any,
      ],
      exporting: {
        filename: 'rain-sum-chart_' + moment(Date.now()),
        enabled: false,
        chartOptions: {
          title: {
            text: 'Rain Sum Chart_' + moment(Date.now()).toLocaleString()
          }
        }
      }
    };
  }

  public startExportXLS(): void {
    this.sampleDataService.setGridOptions(this.dataGridOptions);
    this.sampleDataService.exportXLS(this.stationId);
  }

  public onExportChart(): void {
    this.exportChartImg = true;
    setTimeout(() => {
      this.exportChartImg = false;
    }, 1000);
  }

  public changeVisible(name: string, visible: boolean): void {
    const change = visible === undefined ? true : visible;
    addToLocalStorageObject('accumulatorToolTable', name, !change);
    this.toggleColumns();
  }

  private toggleColumns(): void {
    this.isTableHidden = false;
    if (!this.dataGridOptions.api) {
      return;
    }
    if (this.dataGridOptions.columnApi != null) {
      const tableItems = JSON.parse(localStorage.getItem('accumulatorToolTable'));
      this.dataGridOptions.columnApi.getAllColumns().forEach(
        (c: Column) => {
          if (
            c.getId() === 'dt' ||
            (c.getId() === 'rain_sum' && tableItems['Rain Sum'] === true)
            ) {
            this.dataGridOptions.columnApi.setColumnVisible(c.getId(), true);
          } else {
            this.dataGridOptions.columnApi.setColumnVisible(c.getId(), false);
          }
        }
      );
    }
    this.isTableHidden = this.dataGridOptions.columnApi.getAllDisplayedVirtualColumns().length === 1;
  }

  public get sensor(): AbstractControl {
    return this.form.get('sensor');
  }

  public get from(): AbstractControl {
    return this.form.get('from');
  }

  public get to(): AbstractControl {
    return this.form.get('to');
  }

  public get isChartActive(): AbstractControl {
    return this.form.get('isChartActive');
  }

  public get isTableActive(): AbstractControl {
    return this.form.get('isTableActive');
  }

  public onValueChanged(): void {
    this.response = null;
    this.chartData = null;
    this.noData = true;
  }

  public ngOnDestroy(): void {
    localStorage.removeItem('accumulatorToolTable');
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
