<ng-container>
  <app-custom-image-gallery-toolbar [stationOptions]="cropViewStations"
                                    [toolbarSettings$]="toolbarSettings$"
                                    [stationMinDate$]="stationMinDate$"
                                    [stationMaxDate$]="stationMaxDate$"
                                    [currentDate$]="currentDate$"
                                    (refreshEmitter)="refresh($event)"
                                    (toolbarChangeEmitter)="saveToolbarSettings($event)">
  </app-custom-image-gallery-toolbar>

  <h4 class="current-date-header">
    {{ formatCurrentDate(currentDate$ | async) }}
  </h4>
</ng-container>
