import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ICam } from '../../../../../core/models/stations';
import { IOptions } from '../../../../../shared/interfaces';
import { ModalService } from '../../../../../shared/modal/services/modal.service';
import { generateId } from '../../../../dashboard/utils/makeWidget';
import { setOneCamSettings } from '../../../actions/cameraSettings';
import { ExposureModes, GlobalGainOptions } from '../../../constants/constants';
import { ICamSettings } from '../../../models/station-config.models';
import * as fromCameraSettings from '../../../reducers/cameraSettings';

@Component({
  selector: 'app-camera-settings-form',
  templateUrl: './camera-settings-form.component.html',
  styleUrls: ['./camera-settings-form.component.scss']
})
export class CameraSettingsFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public camIndex           : number;
  @Input()
  public cam                : ICamSettings;
  @Input()
  public camKey             : string;
  public exposureOptions    : IOptions[] = ExposureModes;
  public globalGainOptions  : IOptions[] = GlobalGainOptions;
  public form               : FormGroup;

  public modalId            : string = generateId();

  private destroy$          : Subject<boolean> = new Subject<boolean>();

  constructor(private modalService: ModalService,
              private store: Store<fromCameraSettings.ICameraSettingsState>,
              private fb: FormBuilder) { }

  public ngOnInit(): void {
    this.form = this.fb.group({
      'active': [this.cam.settings.active, []],
      // Will always be automatic
      'auto_exposure': [this.exposureOptions[0].value, []],
      'brightness_ref': [this.cam.settings.brightness_ref, []],
      'global_gain': [this.cam.settings.global_gain, []],
      'integration_time': [this.cam.settings.integration_time, []],
      'max_integration_time': [this.cam.settings.max_integration_time, []],
      'square_spots': [this.cam.settings.square_spots, []]
    });

    this.form.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(100),
      distinctUntilChanged((p: ICam, c: ICam) => JSON.stringify(p) === JSON.stringify(c))
    ).subscribe((f: ICam) => {
        this.store.dispatch(setOneCamSettings({
          camKey: this.camKey,
          settings: f
        }));
    });
  }

  public get active(): AbstractControl {
    return this.form.get('active');
  }

  public get exposure(): AbstractControl {
    return this.form.get('auto_exposure');
  }

  public get brightness(): AbstractControl {
    return this.form.get('brightness_ref');
  }

  public get global(): AbstractControl {
    return this.form.get('global_gain');
  }

  public get integration(): AbstractControl {
    return this.form.get('integration_time');
  }

  public get maxIntegration(): AbstractControl {
    return this.form.get('max_integration_time');
  }

  public turnOff(): void {
    this.active.setValue(false);
    this.closeModal();
  }

  public turnOn(): void {
    this.active.setValue(true);
  }

  public openModal(): void {
    if (this.active.value) {
      this.modalService.openModal(this.modalId);
    }
  }

  public closeModal(): void {
    this.modalService.closeModal(this.modalId);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public ngOnChanges(): void {
    if (this.form) {
      // Should only add existing attributes to the form values
      const validFormControls = Object.keys(this.cam.settings)
        .filter((key) => this.form.contains(key))
        .reduce((camSettings, key) => ({ ...camSettings, [key]: this.cam.settings[key] }), {});

      this.form.setValue(validFormControls);
    }
  }

}
