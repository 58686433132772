import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { CropZoneDataSourceType, CropZoneModule, ICropZone } from '../../../../core/models/cropzones';
import { stationDataContentAnimations } from '../../../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../../../core/services/left-components-toggler/left-components-toggler.service';
import { ITreeStructure } from '../../../../services/tree/models';
import { TreeService } from '../../../../services/tree/tree.service';
import { setSumsSmInitialState } from '../../actions';
import { ISumsSmState } from '../../models';
import { sumsSmSelector } from '../../selectors';
import { selectSelectedCropZone } from './../../../../core/reducers/index';
import { ISelectedCropZoneState } from './../../../../core/reducers/selectedCropZone';
import * as moment from 'moment';

@Component({
  selector: 'app-crop-zone-soil-moisture-sums-sm',
  templateUrl: './crop-zone-soil-moisture-sums-sm.component.html',
  styleUrls: ['./crop-zone-soil-moisture-sums-sm.component.scss'],
  animations: stationDataContentAnimations
})
export class CropZoneSoilMoistureSumsSmComponent implements OnInit, OnDestroy {

  public tree$: BehaviorSubject<ITreeStructure>;
  public state$: Observable<string>;
  public cropZone$: Observable<ICropZone>;
  public destroyed$: Subject<boolean> = new Subject();

  public isLoading: boolean = true;
  public isError: boolean = false;

  constructor(
    private treeService: TreeService,
    private leftComponentsToggler: LeftComponentsTogglerService,
    private selectedCropzoneStore: Store<ISelectedCropZoneState>,
    private sumsSmStore: Store<ISumsSmState>
  ) { }

  public ngOnInit(): void {

    this.tree$ = this.treeService.getCropZoneSoilMoistureTreeStructure();
    this.state$ = this.leftComponentsToggler.getStationDataContentState();
    this.cropZone$ = this.selectedCropzoneStore.pipe(
      select(selectSelectedCropZone),
      filter(cropZone => !!cropZone),
      distinctUntilChanged((a, b) => a.id === b.id),
      tap(cropZone => this.sumsSmStore.dispatch(setSumsSmInitialState(this.getSourceCount(cropZone))))
    );

    this.sumsSmStore.pipe(
      takeUntil(this.destroyed$),
      select(sumsSmSelector)
    ).subscribe(sumsSm => {
      this.isLoading = sumsSm.isLoading;
      const bothDatesInFuture: boolean = moment(sumsSm.from).isAfter(moment()) && moment(sumsSm.to).isAfter(moment());
      this.isError = sumsSm.isError || bothDatesInFuture;
    });

  }

  private getSourceCount(cropZone: ICropZone): number {
    return cropZone.data_sources.filter(source => {
      return source.module === CropZoneModule.SOIL_MOISTURE &&
        source.source.type === CropZoneDataSourceType.SOIL_MOISTURE_SENSOR_OR_PROBE;
    }).length;
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
