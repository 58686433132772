<section>
  <ng-container *ngIf="!(isLoading$ | async); else loadInfo">
    <app-iscout-glue-board-list
      *ngIf="(selectedGlueBoard$ | async) === null"
      (glueBoardAdded)="selectGlueBoardCreate()"
      (glueBoardEdited)="selectGlueBoardUpdate($event)"
      (glueBoardExchanged)="handleGlueBoardExchange($event)"
      (glueBoardRemoved)="handleGlueBoardRemove($event)"
    ></app-iscout-glue-board-list>

    <app-iscout-glue-board-form
      *ngIf="(selectedGlueBoard$ | async) !== null"
      [stationId]="stationId"
      [formAction]="(selectedGlueBoard$ | async)"
      [availableDates]="(availableDates$ | async)"
      (glueBoardSaved)="handleGlueBoardSave($event)"
      (glueBoardUpdated)="handleGlueBoardUpdate($event)"
    ></app-iscout-glue-board-form>
  </ng-container>

  <ng-template #loadInfo>
    <app-station-load-info [isLoading]="isLoading$ | async"></app-station-load-info>
  </ng-template>
</section>
