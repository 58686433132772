import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StationCommunicationComponent} from './station-communication.component';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {SharedModule} from '../../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {StationCommunicationRoutingModule} from './station-communication-routing.module';
import {reducers} from './reducers';
import {StationCommunicationService} from './effects/station-communication.service';
import { StationCommunicationContentComponent } from './containers/station-communication-content/station-communication-content.component';

@NgModule({
  imports: [
    CommonModule,
    StationCommunicationRoutingModule,
    SharedModule,
    StoreModule.forFeature('stationCommunication', reducers),
    EffectsModule.forFeature([StationCommunicationService]),
    TranslateModule.forChild()
  ],
  declarations: [StationCommunicationComponent, StationCommunicationContentComponent]
})
export class StationCommunicationModule { }
