import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { INode, ITreeStructure } from '../../../services/tree/models';
import { TreeService } from '../../../services/tree/tree.service';
import { setTree } from '../actions/actions';
import { IHydroponicsState } from '../models/models';
import { hydroponicsIsDrainageVisible, hydroponicsIsWeightVisible } from '../selectors/selectors';

@Component({
  selector: 'app-hydroponics-tree',
  templateUrl: './hydroponics-tree.component.html',
  styleUrls: ['./hydroponics-tree.component.scss']
})
export class HydroponicsTreeComponent implements OnInit, OnDestroy {

  @Output()
  public toggleSensorEmitter  : EventEmitter<INode> = new EventEmitter<INode>();
  private destroy$            : Subject<boolean> = new Subject<boolean>();
  public tree$                : BehaviorSubject<ITreeStructure>;
  public tree                 : ITreeStructure;

  constructor(
    private hydroponicsStore  : Store<IHydroponicsState>,
    private treeService       : TreeService,
  ) { }

  public ngOnInit(): void {
    this.tree$ = this.treeService.getHydroponicsTreeStructure();
    this.tree$
      .pipe()
      .subscribe((tree: any) => {
        this.tree = tree;
        if (this.tree) {
          this.hydroponicsStore.dispatch(setTree(this.tree.trees));
        }
        this.hydroponicsStore.pipe(
          select(hydroponicsIsDrainageVisible),
          takeUntil(this.destroy$)
        ).subscribe(visible => {
          if (this.tree) {
            for (let i = 0; i < this.tree.trees.length; i++) {
              this.tree.trees[i].nodes.filter(n => n.ch === 2011)[0].visible = visible;
              this.hydroponicsStore.dispatch(setTree(this.tree.trees));
            }
          }
        });
        this.hydroponicsStore.pipe(
          select(hydroponicsIsWeightVisible),
          takeUntil(this.destroy$)
        ).subscribe(visible => {
          if (this.tree) {
            for (let i = 0; i < this.tree.trees.length; i++) {
              this.tree.trees[i].nodes.filter(n => n.ch === 2010)[0].visible = visible;
              this.hydroponicsStore.dispatch(setTree(this.tree.trees));
            }
          }
        });
      });
  }

  public toggleSensor(node: INode): void {
    this.toggleSensorEmitter.emit(node);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
