<div class="my-john-deere-modal">
  <p>
    {{ 'As an iMETOS station and FieldClimate user, you have the possibility to see selected stations with their ' +
  'last sensor readings on MyJohnDeere map if you have a MyJohnDeere user account.' | translate }}
  </p>
  <p>
    {{ 'With GRANT ACCESS you start the process to register your FieldClimate account with your MyJohnDeere account. ' +
  'After successful authentication on MyJohnDeere, all your organizations are fetched from John Deere systems. ' +
  'Select an organization and click YES to activate a station to be synchronized with MyJohnDeere map.' | translate }}
    (<a href="https://my.deere.com/map" target="_blank">https://my.deere.com/map</a>).
  </p>
</div>
