<ng-container *ngIf="isFullscreen">
  <div class="hover-zone"></div>
  <div class="fullscreen-toolbar">
    <div>
      <app-custom-button [color]="'white'"
                         [shadow]="false"
                         (click)="firstSlide()">
        <i class="fa fa-fast-backward"></i>
      </app-custom-button>
      <app-custom-button [color]="'white'"
                         [shadow]="false"
                         (click)="play()">
        <i [ngClass]="isPlayingControl.value ? 'fa fa-pause' : 'fa fa-play'"></i>
      </app-custom-button>
      <app-custom-button [color]="'white'"
                         [shadow]="false"
                         (click)="fullscreen()"
                         class="right-margin">
        <i [ngClass]="isFullscreenControl.value ? 'fa fa-compress' : 'fa fa-expand'"></i>
      </app-custom-button>
      <app-play-speed-input [formControl]="playSpeedControl"
                            [className]="'white'"></app-play-speed-input>
    </div>
  </div>
</ng-container>
<app-chart-toolbar-layout>
  <form class="camera-slideshow-toolbar" [formGroup]="form">

    <div class="camera-slideshow-toolbar__left">
      <div class="camera-slideshow-toolbar__toggle camera-slideshow-toolbar__left__item">
        <app-custom-button [color]="isLastDataModeControl.value ? 'dark-green' : 'green'"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'Last data mode' | translate"
                           [shadow]="false"
                           [radius]="'2px 0 0 2px'"
                           (click)="isLastDataModeControl.setValue(true)">
          <span class="fa fa-step-backward"></span>
        </app-custom-button>
        <app-custom-button [color]="!isLastDataModeControl.value ? 'dark-green' : 'green'"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'Date/week select mode' | translate"
                           [shadow]="false"
                           [radius]="'0 2px 2px 0'"
                           (click)="isLastDataModeControl.setValue(false)">
          <span class="fa fa-calendar"></span>
        </app-custom-button>
      </div>
      <ng-container *ngIf="!isLastDataModeControl.value">
        <app-custom-datepicker [isSmall]="true"
                               [minDate]=""
                               [maxDate]=""
                               [formControl]="fromDatepickerControl">
        </app-custom-datepicker>
        <app-custom-datepicker [isSmall]="true"
                               [maxDate]=""
                               [minDate]=""
                               [formControl]="toDatepickerControl" class="camera-slideshow-toolbar__left__item">
        </app-custom-datepicker>
      </ng-container>
      <app-custom-dropdown *ngIf="showCameraDropdown"
                           [options]="cameraOptions"
                           [formControl]="cameraSelectedControl">
      </app-custom-dropdown>
    </div>

    <div class="camera-slideshow-toolbar__right">
      <app-custom-button [color]="'white'"
                         appTooltip
                         [tooltipPlacement]="'top'"
                         [tooltipTitle]="'Rewind' | translate"
                         [shadow]="false"
                         (click)="firstSlide()">
        <i class="fa fa-fast-backward"></i>
      </app-custom-button>
      <app-custom-button [color]="'white'"
                         appTooltip
                         [tooltipPlacement]="'top'"
                         [tooltipTitle]="'Play' | translate"
                         [shadow]="false"
                         (click)="play()">
        <i [ngClass]="isPlayingControl.value ? 'fa fa-pause' : 'fa fa-play'"></i>
      </app-custom-button>
      <app-custom-button [color]="'white'"
                         appTooltip
                         [tooltipPlacement]="'top'"
                         [tooltipTitle]="'Fullscreen' | translate"
                         [shadow]="false"
                         (click)="fullscreen()"
                         class="right-margin">
        <i [ngClass]="isFullscreenControl.value ? 'fa fa-compress' : 'fa fa-expand'"></i>
      </app-custom-button>
      <app-play-speed-input [formControl]="playSpeedControl"
                            appTooltip
                            [tooltipPlacement]="'top'"
                            [tooltipTitle]="'Play speed' | translate"></app-play-speed-input>
      <div class="camera-slideshow-toolbar__right__item">
        <app-custom-button [color]="'red'"
                           (click)="refresh()"
                           [content]="'Refresh'">
          <i class="fa fa-fw fa-refresh"></i>
        </app-custom-button>
      </div>
    </div>
  </form>
</app-chart-toolbar-layout>
