import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ICropZone } from '../../core/models/cropzones';
import { selectSelectedCropZone } from '../../core/reducers';
import * as fromSelectedCropzone from '../../core/reducers/selectedCropZone';
import { stationDataContentAnimations } from '../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../core/services/left-components-toggler/left-components-toggler.service';
import { ITreeStructure } from '../../services/tree/models';
import { TreeService } from '../../services/tree/tree.service';

@Component({
  selector: 'app-yield-prediction',
  templateUrl: './yield-prediction.component.html',
  styleUrls: ['./yield-prediction.component.scss'],
  animations: stationDataContentAnimations
})
export class YieldPredictionComponent implements OnInit, OnDestroy {

  public state$: Observable<string>;
  public tree$: Observable<ITreeStructure>;
  public selectedCropzone: ICropZone;
  public destroy$ = new Subject<boolean>();

  constructor(
    private treeService: TreeService,
    private leftComponentsToggler: LeftComponentsTogglerService,
    private selectedStore: Store<fromSelectedCropzone.ISelectedCropZoneState>
  ) {}

  public ngOnInit(): void {
    this.state$ = this.leftComponentsToggler.getStationDataContentState().pipe(
      takeUntil(this.destroy$)
    );
    this.tree$ = this.treeService.getCropZoneYieldPredictionTreeStructure().pipe(
      takeUntil(this.destroy$)
    );

    this.selectedStore.pipe(
      takeUntil(this.destroy$),
      select(selectSelectedCropZone)
    ).subscribe((cropzone: ICropZone) =>
      this.selectedCropzone = cropzone
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
