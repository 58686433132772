<section>
  <section *ngIf="chartData; else loading">
    <div *ngIf="!errorMessage">
      <div *ngFor="let item of chart">
        <div class="chart" [chart]="item"></div>
      </div>
    </div>
    <div *ngIf="!errorMessage" class="chart" [chart]="chart"></div>
    <div *ngIf="errorMessage">
      <div>
        <h4 class="status-title" style="padding: 0px 27px;"> {{ 'Error ' | translate }}</h4>
        <h5 class="sub-status-title" style="padding: 0px 27px;">
          {{ 'Graph could not be calculated. Please contact the ' | translate }}
          <a href="https://support.metos.at/">{{ 'support' | translate }}</a>
        </h5>
      </div>
    </div>
  </section>

  <ng-template #loading>
    <h4 class="status-title"><i class="fa fa-cog fa-spin fa-fw"></i> {{ 'Please wait, loading data ...' | translate
      }}</h4>
  </ng-template>
</section>
