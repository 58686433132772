import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {StationDiseaseTableComponent} from './containers/station-disease-table/station-disease-table.component';
import {SharedModule} from '../../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {StationDiseaseSettingsComponent} from './containers/station-disease-settings/station-disease-settings.component';
import {StationDiseaseHeaderComponent} from './containers/station-disease-header/station-disease-header.component';
import {StationDiseaseFireblightComponent} from './containers/station-disease-fireblight/station-disease-fireblight.component';
import {StationDiseaseChartsComponent} from './containers/station-disease-charts/station-disease-charts.component';
import {StationDiseaseComponent} from './containers/station-disease/station-disease.component';
import {StationDiseaseHelpService} from './services/station-disease-help.service';
import {StationDiseaseEffects} from './effects/station-disease.effects';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {reducers} from './reducers';
import {StationDiseaseRoutingModule} from './station-disease-routing.module';
import {StationDataExportService} from '../../shared/services/export/station-data-export.service';
import {ChartModule, HIGHCHARTS_MODULES} from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import {AgGridModule} from 'ag-grid-angular';
import { StationDiseaseWarningComponent } from './containers/station-disease-warnings/station-disease-warning.component';
import { StationDiseaseContentComponent } from './containers/station-disease-content/station-disease-content.component';
import { StationDiseaseHelpModalComponent } from './components/station-disease-help-modal/station-disease-help-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    StoreModule.forFeature('disease', reducers),
    EffectsModule.forFeature([StationDiseaseEffects]),
    StationDiseaseRoutingModule,
    ChartModule,
    AgGridModule.withComponents([])
  ],
  declarations: [
    StationDiseaseTableComponent,
    StationDiseaseSettingsComponent,
    StationDiseaseHeaderComponent,
    StationDiseaseFireblightComponent,
    StationDiseaseChartsComponent,
    StationDiseaseComponent,
    StationDiseaseWarningComponent,
    StationDiseaseContentComponent,
    StationDiseaseHelpModalComponent
  ],
  providers: [
    StationDiseaseHelpService,
    StationDataExportService,
    { provide: HIGHCHARTS_MODULES, useFactory: () => [ more, exporting ] },
  ]
})
export class StationDiseaseModule { }
