<link href='https://api.tiles.mapbox.com/mapbox-gl-js/v1.4.1/mapbox-gl.css' rel='stylesheet' />

<app-weather-forecast-layout>
  <app-weather-forecast-load-info *ngIf="!map" [isLoading]="isLoading">
  </app-weather-forecast-load-info>

  <app-card-header [header]="header?.title | translate" [helpButton]="true" (openModal)="openModal()">
  </app-card-header>

  <app-info-modal [modalId]="modalId" [header]="header?.title | translate">
    <app-weather-forecast-help-modal [help]="header?.help"></app-weather-forecast-help-modal>
  </app-info-modal>

  <app-form-layout *ngIf="!(isLoading);">
    <div>
      <div id="map" class="map-container"></div>
      <div #mapContainer class="map-container"></div>
    </div>

    <div *ngIf="warningsMap && response && warningsOpen">
      <div *ngIf="response.results_count > 0"
           class="warnings">
        <div class="warnings__header">
          <div class="col col-md-11">
            {{ response.results[selectedWarningIndex].info[0].areas_description[0] }}
            {{ currentTime }} -
            {{ response.results_count }} {{ response.results_count === 1 ? 'active warning' : 'active warnings' | translate }}
          </div>
          <div class="col col-md-1">
            <i class="zmdi zmdi-close zmdi-hc-lg warnings__header__close"
               (click)="onClose()">
            </i>
          </div>
        </div>
        <div class="warnings__body">
          <div class="warnings__body__event"
               [ngStyle]="{'backgroundColor': severity}">
            <div class="col col-md-9 warnings__body__event__text">
              {{ response.results[selectedWarningIndex].info[languageIndex].headline }}
            </div>
            <div class="col col-md-3 warnings__body__event__status">
              {{ status | translate }}
            </div>
          </div>
          <div class="warnings__body__text">
            <b>{{ 'From:' | translate }}</b> {{ startDate | translate }} {{ 'At' | translate }} {{ startTime }}<br>
            <b>{{ 'Until:' | translate }}</b> {{ endDate | translate }} {{ 'At' | translate }} {{ endTime }}<br>
            <b>{{ 'Official warning explanations:' | translate }}</b> {{ response.results[selectedWarningIndex].info[languageIndex].description }}
          </div>
        </div>
        <div *ngIf="response.results_count > 1"
             class="warnings__footer">
          <div class="col">
            <i class="zmdi zmdi-chevron-left zmdi-hc-lg warnings__footer__arrow"
               (click)="onBackward()">
            </i>
            <div class="warnings__footer__text">
              {{ selectedWarningIndex + 1 }} / {{ response.results_count }}
            </div>
            <i class="zmdi zmdi-chevron-right zmdi-hc-lg warnings__footer__arrow"
              (click)="onForward()">
            </i>
          </div>
        </div>
      </div>
      <div *ngIf="response.results_count === 0"
           class="warnings">
        <div class="warnings__header">
          <div class="col col-md-11">
            {{ currentTime }} -
            {{ 'No active warnings' | translate }}
          </div>
          <div class="col col-md-1">
            <i class="zmdi zmdi-close warnings__header__close"
               (click)="onClose()">
            </i>
          </div>
        </div>
      </div>
    </div>
  </app-form-layout>
</app-weather-forecast-layout>
