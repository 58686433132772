import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {
  INodeChanged,
  INodes,
  ISaveNodeNamePayload, ISaveSensorPayload,
  IUpdateNodeName,
  IUpdateSensor
} from '../models/sensors-and-nodes';

export const enum SensorsAndNodeActionTypes {
  TOGGLE_SENSORS_LOADING = '[Sensors] TOGGLE_LOADING',
  TOGGLE_SENSORS_LOADING_ERROR = '[Sensors] TOGGLE_LOADING_ERROR',
  LOAD_SENSORS = '[Sensors] LOAD_SENSORS',
  SET_NODES = '[Sensors] SET_NODES',
  LOAD_NODES = '[Sensors] LOAD_NODES',
  UPDATE_SENSOR = '[Sensors] UPDATE_SENSOR',
  SAVE_SENSOR = '[Sensors] SAVE_SENSOR',
  UPDATE_NODE_NAME = '[Sensors] UPDATE_NODE_NAME',
  SAVE_NODE_NAME = '[Sensors] SAVE_NODE_NAME',
  RESET_NODES = '[Sensors] RESET_NODES',
  SET_SENSORS_STATION = '[Sensors] SET_SENSORS_STATION',
  SET_NODE_CHANGED = '[Sensors] SET_NODE_CHANGED'
}

export function setNodeChanged(changed: INodeChanged): IActionWithPayload {
  return {type: SensorsAndNodeActionTypes.SET_NODE_CHANGED, payload: changed};
}

export function setSensorsStation(stationId: string): IActionWithPayload {
  return {type: SensorsAndNodeActionTypes.SET_SENSORS_STATION, payload: stationId};
}

export function resetNodes(stationId: string): IActionWithPayload {
  return {type: SensorsAndNodeActionTypes.RESET_NODES, payload: stationId};
}

export function saveSensor(save: ISaveSensorPayload): IActionWithPayload {
  return {type: SensorsAndNodeActionTypes.SAVE_SENSOR, payload: save};
}

export function saveNodeName(save: ISaveNodeNamePayload): IActionWithPayload {
  return {type: SensorsAndNodeActionTypes.SAVE_NODE_NAME, payload: save};
}

export function updateNodeName(update: IUpdateNodeName): IActionWithPayload {
  return {type: SensorsAndNodeActionTypes.UPDATE_NODE_NAME, payload: update};
}

export function toggleSensorsLoading(isLoading: boolean): IActionWithPayload {
  return {type: SensorsAndNodeActionTypes.TOGGLE_SENSORS_LOADING, payload: isLoading};
}

export function toggleSensorsLoadingError(isError: boolean): IActionWithPayload {
  return {type: SensorsAndNodeActionTypes.TOGGLE_SENSORS_LOADING_ERROR, payload: isError};
}

export function loadSensors(id: string): IActionWithPayload {
  return {type: SensorsAndNodeActionTypes.LOAD_SENSORS, payload: id};
}

export function setNodes(nodes: INodes): IActionWithPayload {
  return {type: SensorsAndNodeActionTypes.SET_NODES, payload: nodes};
}

export function loadNodes(id: string): IActionWithPayload {
  return {type: SensorsAndNodeActionTypes.LOAD_NODES, payload: id};
}

export function updateSensor(update: IUpdateSensor): IActionWithPayload {
  return {type: SensorsAndNodeActionTypes.UPDATE_SENSOR, payload: update};
}
