<div class="cropzone-map">
  <label>{{ 'Cropzone location' | translate }}</label>
  <agm-map
    [latitude]="center.lat"
    [longitude]="center.lng"
    [zoom]="zoom"
    [disableDefaultUI]="true"
    [zoomControl]="true"
    [scrollwheel]="true"
    [streetViewControl]="false"
    [mapTypeId]="'hybrid'"
    [minZoom]="4"
    [mapTypeControl]="true"
    (mapReady)="onMapReady($event)"
    (idle)="updateMapCenter()">
  </agm-map>
</div>
