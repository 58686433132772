import {IActionWithPayload} from '../models/actionWithPayload';
import {IProductLicense, LicenseFetchMode} from '../models/licenses';

export const enum ProductLicenseActionTypes {
  GET_PRODUCT_LICENSES = '[ProductLicense] GET_PRODUCT_LICENSES',
  SET_PRODUCT_LICENSES = '[ProductLicense] SET_PRODUCT_LICENSES',
}

export function getProductLicenses(featureType?: string, mode?: LicenseFetchMode): IActionWithPayload {
  return {type: ProductLicenseActionTypes.GET_PRODUCT_LICENSES, payload: {type: featureType, mode}};
}

export function setProductLicenses(payload: Array<IProductLicense>): IActionWithPayload {
  return {type: ProductLicenseActionTypes.SET_PRODUCT_LICENSES, payload};
}
