import { ISensor } from './../../../shared/interfaces';
import { IActionWithPayload } from './../../../core/models/actionWithPayload';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { ApiCallService } from './../../../services/api/api-call.service';
import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { ActionTypes, appendServerAlert, setUserAlerts } from '../actions/alerts';
import { of } from 'rxjs';
import { setNotify } from '../../../core/actions/notify';
import { ActionTypes as SensorsActionTypes, setStationSensors}  from '../actions/sensors';

@Injectable()

export class AlertsEffects {
  constructor(
    private api: ApiCallService,
    private actions$: Actions
  ) { }

  @Effect()
  public getUserAlerts = this.actions$.pipe(
    ofType(ActionTypes.GET_USER_ALERTS),
    switchMap(() => this.api.getUserServerAlerts().pipe(
      map((data: any[]) => setUserAlerts(data))
    ))
  );

  @Effect()
  public updateStationServerAlert = this.actions$.pipe(
    ofType(ActionTypes.UPDATE_SERVER_ALERT),
    switchMap((action: IActionWithPayload) => this.api.updateStationServerAlert(action.payload).pipe(
      map(() => setNotify('The FieldClimate notification was successfully updated')),
      catchError(() =>
        of(setNotify('Failed to update the FieldClimate notification'))
      )
    ))
  );

  @Effect()
  public addServerAlert = this.actions$.pipe(
    ofType(ActionTypes.ADD_SERVER_ALERT),
    switchMap((action: IActionWithPayload) => this.api.addStationServerAlert(action.payload).pipe(
      mergeMap(result => [
        setNotify('The FieldClimate notification was successfully added'),
        appendServerAlert({ ...action.payload.alert, _id: result['$oid']})
      ]),
      catchError(() =>
        of(setNotify('Failed to update the FieldClimate notification'))
      )
    ))
  );

  @Effect()
  public deleteStationServerAlert = this.actions$.pipe(
    ofType(ActionTypes.DELETE_SERVER_ALERT),
    switchMap((action: IActionWithPayload) => this.api.deleteStationServerAlert(action.payload).pipe(
      map(() => setNotify('The FieldClimate notification was successfully deleted')),
      catchError(() =>
        of(setNotify('Failed to delete the FieldClimate notification'))
      )
    ))
  );

  @Effect()
  public getStationSensors = this.actions$.pipe(
    ofType(SensorsActionTypes.GET_STATION_SENSORS),
    switchMap((action: IActionWithPayload) => this.api.getStationSensors(action.payload).pipe(
      map((sensors: ISensor[]) => setStationSensors(sensors)),
      catchError(() =>
        of(setNotify('Notifications are currently unavailable'))
      )
    ))
  );
}
