import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-frost-protection-help-modal',
  templateUrl: './frost-protection-help-modal.component.html',
  styleUrls: ['./frost-protection-help-modal.component.scss']
})
export class FrostProtectionHelpModalComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }
}

