import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { setCropZone } from '../../../../core/actions/cropzones';
import { setSelectedCropZone } from '../../../../core/actions/selectedCropZone';
import { ICropZone, ISoil } from '../../../../core/models/cropzones';
import { selectSelectedCropZone, selectUserData } from '../../../../core/reducers';
import { IAccount } from '../../../../core/reducers/account';
import * as fromCropzone from '../../../../core/reducers/cropzones';
import * as fromSelectedCropzone from '../../../../core/reducers/selectedCropZone';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { collectConfigData, updateCropzone } from '../../actions/irrimet-config.action';
import { initialWaterOptions, soilProfileOptions } from '../../constants/config';
import * as fromIrrimetConfig from '../../reducers/irrimet-config.reducer';
import { SoilProfileTableComponent } from '../soil-profile-table/soil-profile-table.component';
import { ModalService } from './../../../../shared/modal/services/modal.service';

@Component({
  selector: 'app-rootzone-settings',
  templateUrl: './rootzone-settings.component.html',
  styleUrls: ['./rootzone-settings.component.scss'],
  animations: [
    trigger('collapse-animation', [
      state('small', style({ height: '0px' })),
      state('large', style({ height: 'auto' })),
      transition('small <=> large', animate('2ms ease-in'))
    ])
  ]
})
export class RootzoneSettingsComponent implements OnInit, OnDestroy {
  @ViewChild(SoilProfileTableComponent) private childComp: SoilProfileTableComponent;

  public selectedCropzone: ICropZone;
  private alive$ = new Subject<boolean>();
  public unitSystem: string;
  public form: FormGroup;
  public optionalButtonColor: string = 'green';
  public optionalButtonContent: string = 'Show rootzone settings';
  public initialWaterOptions = initialWaterOptions;
  public soilProfileOptions = soilProfileOptions;
  public state: string = 'small';
  public parentAndChildFormValid: boolean;
  public modalId: string = generateId();
  constructor(
    private fb: FormBuilder,
    private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private userStore: Store<IAccount>,
    private cropzoneStore: Store<fromCropzone.ICropZones>,
    private irrimetConfigStore: Store<fromIrrimetConfig.IDataSourcesState>,
    private modalService: ModalService
  ) {

    this.selectedCropzoneStore.pipe(
      select(selectSelectedCropZone),
      takeUntil(this.alive$)
    ).subscribe((cropzone) => {
        this.selectedCropzone = cropzone;

        if (this.selectedCropzone.soil) {
          this.initAndPopulateForm();
        } else {
          this.initNewForm();
        }
    });

    this.userStore.pipe(
      select(selectUserData),
      takeUntil(this.alive$),
      filter((u: IAccount) => !!u)
    ).subscribe((u: IAccount) => {
      if (u.settings !== null) {
        this.unitSystem = u.settings.unit_system;
      }
    });

  }

  public ngOnInit(): void {
    if (this.selectedCropzone.soil) {
      this.initAndPopulateForm();
    } else {
      this.initNewForm();
    }
  }

  public initAndPopulateForm(): void {
    this.form = this.fb.group({
      'initialWater': [this.selectedCropzone.soil.initial_soil_water_content, [Validators.required, Validators.min(0.01)]],
      'soilProfile': [0, []]
    });

    this.form.get('initialWater').valueChanges.subscribe(val => {
      if (val !== 0) {
        this.form.get('initialWater').setErrors(null);
      }
      this.childComp.validateForm();
    });
  }

  public initNewForm(): void {
    this.form = this.fb.group({
      'initialWater': [0, [Validators.required, Validators.min(0.01)]],
      'soilProfile': [0, []]
    });

    this.form.get('initialWater').valueChanges.subscribe(val => {
      if (val !== 0) {
        this.form.get('initialWater').setErrors(null);
      }
      this.childComp.validateForm();
    });
  }

  public changeOptionalButtonState(): void {
    this.optionalButtonColor === 'green' ? this.optionalButtonColor = 'orange' : this.optionalButtonColor = 'green';
    this.optionalButtonContent === 'Show rootzone settings' ? this.optionalButtonContent = 'Hide rootzone settings' :
      this.optionalButtonContent = 'Show rootzone settings';
    this.state = (this.state === 'small' ? 'large' : 'small');
  }

  public save(): void {
    this.irrimetConfigStore.dispatch(collectConfigData(true));
  }

  public setSoilLayers(layers): void {

    const soil: ISoil = {
      initial_soil_water_content: parseFloat(this.form.value.initialWater),
      // change root restriction depth in new version + probably parseFloat()
      root_restriction_depth: 2,
      layers
    };

    this.selectedCropzone.soil = soil;

    this.cropzoneStore.dispatch(setCropZone(this.selectedCropzone));
    this.selectedCropzoneStore.dispatch(setSelectedCropZone(this.selectedCropzone));
    this.irrimetConfigStore.dispatch(updateCropzone(this.selectedCropzone));
  }

  public setFormStatus(event): void {
    this.parentAndChildFormValid = event && this.form.valid;
  }

  public openModal(): void {
    this.modalService.openModal(this.modalId);
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }
}
