import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { cardAnimation } from '../../../../shared/animations/card-animations';
import { getUserLicenses, selectUserLicense, unselectUserLicense } from '../../actions';
import { IActiveLicense } from '../../models/user-licenses.models';
import { IState as UserLicensesState, selectUserLicenses, selectUserLicenseSelected } from '../../reducers';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-user-licenses-content',
  templateUrl: './user-licenses-content.component.html',
  styleUrls: ['./user-licenses-content.component.scss'],
  animations: [cardAnimation()]
})
export class UserLicensesContentComponent implements OnInit {
  public fieldClimateLicenses$: Observable<Array<IActiveLicense>>;
  public farmViewLicenses$: Observable<Array<IActiveLicense>>;
  public selectedLicense$: Observable<IActiveLicense>;

  constructor(private licenseState: Store<UserLicensesState>) {}

  public ngOnInit(): void {
    this.fieldClimateLicenses$ = this.licenseState.pipe(
      select(selectUserLicenses),
      map((licenses) => licenses.filter((license) => {
        // tslint:disable-next-line:max-line-length
        const checkForStationsWhenNoProduct: boolean = license.stations.length > 0 && license.products.length === 0 && license.stations[0].length === 8;
        const checkForLicenseGroup = license.products.length > 0 && license.products[0].license_group !== 'FarmView';
        return checkForStationsWhenNoProduct || checkForLicenseGroup;
      }))
    );

    this.farmViewLicenses$ = this.licenseState.pipe(
      select(selectUserLicenses),
      map((licenses) => licenses.filter((license) => {
        // tslint:disable-next-line:max-line-length
        const checkForCropzonesWhenNoProduct = license.stations.length > 0 && license.products.length === 0 && license.stations[0].length === 24;
        const checkForLicenseGroup = license.products.length > 0 && license.products[0].license_group === 'FarmView';
        return checkForCropzonesWhenNoProduct || checkForLicenseGroup;
      }))
    );

    this.selectedLicense$ = this.licenseState.pipe(
      select(selectUserLicenseSelected)
    );
    this.licenseState.dispatch(getUserLicenses());
  }

  public handleSelectLicense(event: IActiveLicense): void {
    this.licenseState.dispatch(selectUserLicense(event));
  }

  public handleUnselectLicense(): void {
    this.licenseState.dispatch(unselectUserLicense());
  }
}
