import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IChartAndLegend } from '../../../../shared/interfaces';

@Component({
  selector: 'app-animal-production-charts',
  templateUrl: './animal-production-charts.component.html',
  styleUrls: ['./animal-production-charts.component.scss']
})
export class AnimalProductionChartsComponent implements OnInit {

  @Input()
  public chartsAndLegends$  : Observable<IChartAndLegend[]>;
  @Input()
  public isLoading          : boolean = true;
  @Input()
  public isError            : boolean = false;

  constructor() { }

  public ngOnInit(): void {}

}
