<app-form-layout>
  <div class="padding">
    <app-custom-button (click)="changeOptionalButtonState()" [content]="optionalButtonContent"
      [color]="optionalButtonColor"> </app-custom-button>
      <i (click)="openModal()" class="zmdi zmdi-help help-icon"></i>
    <h5 class="text marginT10">
      {{ 'Define field capacity, refill point and initial water in the soil - parameters that are required to limit the maximum water that can be stored in the soil.' | translate}}
    </h5>
    <div [@collapse-animation]='state'>
      <div *ngIf="state === 'large'">
        <form [formGroup]="form" class="marginT35">
          <h5 class="text">{{ 'Soil moisture level at the beginning of the season' | translate }}</h5>
          <div class="width30">
            <app-form-select
              [type]="'border'"
              class="rootzone-settings__input"
              [options]="initialWaterOptions"
              formControlName="initialWater"
              [size]="'small'">
            </app-form-select>
          </div>
          <div class="options marginT15">
            <div>
              <h5 class="text">{{ 'Load soil profile' | translate }}</h5>
              <div class="width30 marginB20">
                <app-form-select
                  [type]="'border'"
                  class="rootzone-settings__input"
                  [options]="soilProfileOptions"
                  formControlName="soilProfile"
                  [size]="'small'">
                </app-form-select>
              </div>
              <app-soil-profile-table
                (formIsValid)="setFormStatus($event)"
                (soilLayers)="setSoilLayers($event)"
                [soilProfileIndex]="form.get('soilProfile').value">
              </app-soil-profile-table>
            </div>
          </div>
          <div class="row marginT15">
            <div class="col-md-12">
                <app-custom-button class="floatR" color="red" content="{{ 'Save' | translate }}" (click)="save()" [disabled]="!parentAndChildFormValid"></app-custom-button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</app-form-layout>

<app-info-modal [modalId]="modalId"
                  [header]="'Rootzone settings'">
                  <app-rootzone-settings-help-text-modal></app-rootzone-settings-help-text-modal>
</app-info-modal>