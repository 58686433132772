import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { updateWarningsListSensor } from '../../../actions/warningsList';
import { IWarning, IWarningSettings } from '../../../models/models';
import * as fromWarningsList from '../../../reducers/warningsList';

@Component({
  selector: '[app-warnings-list-row]',
  templateUrl: './warnings-list-row.component.html',
  styleUrls: ['./warnings-list-row.component.scss']
})
export class WarningsListRowComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public warningKey   : string;
  @Input()
  public warning      : IWarning;

  public form         : FormGroup;
  public isMinMax     : boolean = false;

  private destroy$    : Subject<boolean> = new Subject<boolean>();

  private readonly formatError : string = 'Format issue - please enter the right format (1, -1.2, 3.45)';
  private readonly minMaxError : string = 'MAX values can not be lower then MIN values.';
  private readonly spaceReg    : RegExp = / /g;

  constructor(private fb: FormBuilder,
              private warningsListStore: Store<fromWarningsList.IWarningsListState>) { }

  public get min(): AbstractControl {
    return this.form.get('min');
  }

  public get max(): AbstractControl {
    return this.form.get('max');
  }

  public get error(): string {
    if (this.isMinMax) {
      return this.minMaxError;
    }
    if (this.max.invalid || this.min.invalid) {
      return this.formatError;
    }
    return '';
  }

  public checkMinMaxError(): void {
    const min: string = this.min.value.replace(this.spaceReg, '');
    const max: string = this.max.value.replace(this.spaceReg, '');
    this.isMinMax = !!(min && max);
    if (!this.isMinMax) {
      return;
    }
    const minValues: number[] = min.split(',').map((v: string) => parseFloat(v));
    const maxValues: number[] = max.split(',').map((v: string) => parseFloat(v));
    this.isMinMax  = Math.max(...minValues) > Math.min(...maxValues);
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      'min': [this.warning.settings.min || '', [this.warningsRegValidator()]],
      'max': [this.warning.settings.max || '', [this.warningsRegValidator()]]
    });
    this.checkMinMaxError();

    this.form.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(100),
      takeUntil(this.destroy$)
    ).subscribe((form: IWarningSettings) => {
      this.checkMinMaxError();
      this.warningsListStore.dispatch(updateWarningsListSensor({
        warningKey: this.warningKey,
        settings: form
      }));
    });
  }

  public ngOnChanges(changes): void {

    if (changes['warning'] && this.form) {
      const settings = changes['warning'].currentValue.settings as IWarningSettings;
      this.form.patchValue({
        min: settings.min,
        max: settings.max
      }, { emitEvent: false });
    }

  }

  private warningsRegValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: boolean} | null => {
      const value: string = control.value.replace(this.spaceReg, '');
      const forbidden: boolean = /^[\-]?[0-9]+(([,.]+-?[0-9]+)*)?$/gm.test(value);
      return forbidden || !value ? null : {'format': true };
    };
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }


}
