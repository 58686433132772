import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-irrimet-config-chart-help-text-modal',
  templateUrl: './irrimet-config-chart-help-text-modal.component.html',
  styleUrls: ['./irrimet-config-chart-help-text-modal.component.scss']
})
export class IrrimetConfigChartHelpTextModalComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
