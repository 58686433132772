import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { centroid } from '@turf/turf';
import { Observable, of, Subject } from 'rxjs';
import { filter, map, takeUntil, tap, switchMap } from 'rxjs/operators';
import { setCropZone } from '../../../../core/actions/cropzones';
import { setSelectedCropZone } from '../../../../core/actions/selectedCropZone';
import {
  ICrop,
  ICropZone,
  IDataSources,
  IDataSourceSensor,
  IET0DataSource,
  IRainDataSource,
  IRainEfficacies
} from '../../../../core/models/cropzones';
import { selectSelectedCropZone, selectStations, selectUserData } from '../../../../core/reducers';
import { IAccount } from '../../../../core/reducers/account';
import * as fromCropzones from '../../../../core/reducers/cropzones';
import * as fromNotify from '../../../../core/reducers/notify';
import * as fromSelectedCropzone from '../../../../core/reducers/selectedCropZone';
import { IStations } from '../../../../core/reducers/stations';
import { IOptions } from '../../../../shared/interfaces';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { generateId } from '../../../dashboard/utils/makeWidget';
import {
  getCropsLazyLoading,
  getDatasourcesET0,
  getDatasourcesRain,
  IOptionsForDatasources,
  updateCropzone
} from '../../actions/irrimet-config.action';
import { distanceItems } from '../../constants/config';
import {
  selectCropsLazyLoading,
  selectDatasourcesETO,
  selectDatasourcesRain
} from '../../reducers';
import * as fromIrrimetConfig from '../../reducers/irrimet-config.reducer';
import { IrrimetService } from '../../services/irrimet.service';

@Component({
  selector: 'app-irrimet-data',
  templateUrl: './irrimet-data.component.html',
  styleUrls: ['./irrimet-data.component.scss']
})
export class IrrimetDataComponent implements OnInit, OnDestroy {

  public width              : string;
  public items$             : Observable<IOptions[]>;
  public datasourcesRain$   : Observable<IOptions[]>;
  public datasourcesETO$    : Observable<IOptions[]>;
  public stationArray       = [];
  public crop               : ICrop;
  private alive$            = new Subject<boolean>();
  private cropzone          : ICropZone;
  public form               : FormGroup;
  public unitSystem         : string;
  public metric             : string = 'metric';
  public imperial           : string = 'imperial';
  public selectedCrop       : any;
  public isDefault          : boolean;
  public modalId            : string = generateId();
  public modalIdChart       : string = generateId();
  public crops              : any[];
  private rainDatasource    : any;
  private et0Datasource     : any;
  public distanceItems$     : Observable<IOptions[]> = of(distanceItems);
  public selectedDistance   : IOptions = {value: '30 km', content: '30000'};

  constructor(
    private stationStore          : Store<IStations>,
    private fb                    : FormBuilder,
    private selectedCropzoneStore : Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private userStore             : Store<IAccount>, private notifyStore: Store<fromNotify.INotifyState>,
    private cropzoneStore         : Store<fromCropzones.ICropZones>,
    private irrimetConfigStore    : Store<fromIrrimetConfig.IDataSourcesState>,
    private irrimetService        : IrrimetService,
    private modalService          : ModalService,
    private translations          : TranslateService
  ) {
  }

  public get dataSourceRain(): AbstractControl {
    return this.form.get('dataSourceRain');
  }

  public get dataSourceET0(): AbstractControl {
    return this.form.get('dataSourceET0');
  }

  public get cropType(): AbstractControl {
    return this.form.get('cropType');
  }

  public get stationDistance(): AbstractControl {
    return this.form.get('stationDistance');
  }

  public ngOnInit(): void {
    this.iniForm();

    this.userStore.pipe(
      select(selectUserData),
      takeUntil(this.alive$),
      filter((user: IAccount) => !!user)
    ).subscribe((user: IAccount) => {
      if (user.settings !== null) {
        this.unitSystem = user.settings.unit_system;
      }
    });

    this.irrimetConfigStore.dispatch(getCropsLazyLoading());

    this.stationStore.pipe(
      select(selectStations),
      takeUntil(this.alive$)
    ).subscribe((stations) => this.stationArray = stations);

    this.items$ = this.irrimetConfigStore.pipe(
      select(selectCropsLazyLoading),
      map(crops => crops.map((item: any) =>
        ({content: item, value: item.name})
      )),
      tap(crops => {
        this.crops = crops;
        this.crops.forEach((crop, i) => this.translations.get(crop.value).subscribe(translatedValue => {
          this.crops[i].value = translatedValue;
        }));
      }),
      tap(() => {
        this.crops.sort((a, b) => a.value > b.value ? 1 : -1);     // sort aplphabetically
        this.setCropValue();
      }),
    );

    this.datasourcesRain$ = this.irrimetConfigStore.pipe(
      select(selectDatasourcesRain),
      map((rainSources) => rainSources.sort((a, b) => a.id < b.id ? -1 : 1)),
      map(datasourcesRain => datasourcesRain.map((item: any) => {
        if (item.value !== 'OMRG') {
          return ({content: item, value: `${item.id} (${item.info.device_name})`});
        } else {
          return ({content: item.value, value: item.content});
        }
      }
      ))
    );

    this.datasourcesETO$ = this.irrimetConfigStore.pipe(
      select(selectDatasourcesETO),
      map((etoSources) => etoSources.sort((a, b) => a.id < b.id ? -1 : 1)),
      map(datasourcesETO => datasourcesETO.map((item: any) =>
        ({content: item, value: `${item.id} (${item.info.device_name})`})
      ))
    );

    this.stationDistance.valueChanges.pipe(
      takeUntil(this.alive$)
    ).subscribe(distance => {
      if (distance) {
        this.selectedDistance = distance;
      }
    });

    this.selectedCropzoneStore.pipe(
      select(selectSelectedCropZone),
      takeUntil(this.alive$)
    ).subscribe((cropzone) => {
      this.iniForm();
      this.rainDatasource = null;
      this.et0Datasource = null;
      this.cropzone = cropzone;

      this.cropzone.data_sources.forEach(datasource => {
        if (datasource.module === 'IRRIMET') {
          if (datasource.source.type === 'SENSOR') {
            this.rainDatasource = datasource;
          } else if (datasource.source.type === 'MODEL') {
            this.et0Datasource = datasource;
          }
        }
      });
      this.setDatasources();
      this.setCropValue();
      this.setRainEfficacies();
      this.setDistance();

      if (cropzone.boundary) {
        this.onLoadRain();
        this.onLoadETO();
      }

      this.cropType.valueChanges.pipe(
        takeUntil(this.alive$)
      ).subscribe(data => {
        if (data) {
          this.selectedCrop = data.content;
        } else {
          this.selectedCrop = null;
        }
      });
    });

  }

  private setDistance(): void {
    if (this.cropzone.distance) {
      const value = this.cropzone.distance / 1000;
      this.selectedDistance = {
        value: `${value} km`,
        content: this.cropzone.distance.toString()
      };
    } else {
      this.selectedDistance = {
        value: '30 km',
        content: '30000'};
    }
    this.stationDistance.setValue(this.selectedDistance);
  }

  private setRainEfficacies(): void {
    if (this.cropzone.rain_efficacies.length > 0) {

      this.form.get('rainEfficacy1').setValue(
        this.cropzone.rain_efficacies[0].efficacy
      );

      this.form.get('rainEfficacy2').setValue(
        this.cropzone.rain_efficacies[1].efficacy
      );

      this.form.get('rainEfficacy3').setValue(
        this.cropzone.rain_efficacies[2].efficacy
      );
    } else {
      this.iniForm();
    }
  }

  private setDatasources(): void {
    this.cropzone.data_sources.forEach((datasource) => {
      if (datasource.module === 'IRRIMET' && datasource.source.type === 'SENSOR') {
        const rainOption = {
          content: datasource,
          value: datasource.device_name
        };
        this.dataSourceRain.setValue(rainOption);
      }
      if (datasource.module === 'IRRIMET' && datasource.source['id'] === 'evapotranspiration') {
        const etoOption = {
          content: datasource,
          value: datasource.device_name
        };
        this.dataSourceET0.setValue(etoOption);
      }
    });

    if (this.cropzone.manualRainGauge) {
      const rainOption = {
        value: 'Only manual rain gauge',
        content: 'OMRG'
      };
      this.dataSourceRain.setValue(rainOption);
    }
  }

  private setCropValue(): void {

    this.cropType.setValue(null);
    this.selectedCrop = null;

    if (this.crops) {
      this.crops.filter(crop =>
        this.cropzone.crop && crop.content.id === this.cropzone.crop.id
      )
        .map(crop => {
          if (crop) {
            this.cropType.setValue(crop);
            this.selectedCrop = crop.content;
          } else {
            this.cropType.setValue(null);
            this.selectedCrop = null;
          }
        });
    }
  }

  private iniForm(): void {
    this.form = this.fb.group({
      'cropType': ['', [Validators.required]],
      'dataSourceRain': ['', [Validators.required]],
      'dataSourceET0': ['', [Validators.required]],
      'stationDistance': ['', [Validators.required]],
      'rainEfficacy1': [0.8, [Validators.required, Validators.min(0), Validators.max(1)]],
      'rainEfficacy2': [0.7, [Validators.required, Validators.min(0), Validators.max(1)]],
      'rainEfficacy3': [0.6, [Validators.required, Validators.min(0), Validators.max(1)]],
    });

    if (this.cropzone) {
      if (this.cropzone.data_sources.length) {
        this.cropzone.data_sources.forEach((datasource) => {
          if (datasource.module === 'IRRIMET' && datasource.source['id'] === 'evapotranspiration') {
            this.dataSourceET0.setValue(datasource.device_name);
          }
          if (datasource.module === 'IRRIMET' && datasource.source.type === 'SENSOR') {
            this.dataSourceRain.setValue(datasource.device_name);
          }
        });
      }
    }

  }

  public save(): void {

    const rainEfficacies: IRainEfficacies[] = [];
    const rainEfficacy1 = this.form.get('rainEfficacy1').value;
    const rainEfficacy2 = this.form.get('rainEfficacy2').value;
    const rainEfficacy3 = this.form.get('rainEfficacy3').value;

    if (rainEfficacy1) {
      rainEfficacies.push({
        efficacy: rainEfficacy1,
        lt: 5,
        gte: 0
      });
    }

    if (rainEfficacy2) {
      rainEfficacies.push({
        efficacy: rainEfficacy2,
        lt: 10,
        gte: 5
      });
    }

    if (rainEfficacy3) {
      rainEfficacies.push({
        efficacy: rainEfficacy3,
        lt: 999,
        gte: 10
      });
    }
    const manualRainGauge = this.dataSourceRain.value.content === 'OMRG';
    let keys = [];
    if (this.dataSourceET0.value) {
      if (this.dataSourceET0.value.content.hasOwnProperty('groups')) {
        keys = Object.keys(this.dataSourceET0.value.content.groups);
      } else if (this.dataSourceET0.value.content.source.sensors) {
        this.dataSourceET0.value.content.source.sensors.forEach((sensor) => {
          keys.push(sensor.group);
        });
      }
    }

    let sensorArray = [];

    if (this.dataSourceET0.value) {
      if (this.dataSourceET0.value.content.hasOwnProperty('groups')) {
        for (let i = 0; i < keys.length; i++) {
          const sensor: IDataSourceSensor = {
            group: parseInt(keys[i], 10),
            channel: parseInt(this.dataSourceET0.value.content.groups[keys[i]][0].ch, 10),
            code: parseInt(this.dataSourceET0.value.content.groups[keys[i]][0].c, 10)
          };
          sensorArray.push(sensor);
        }
      } else if (this.dataSourceET0.value.content.source.sensors) {
        sensorArray = this.dataSourceET0.value.content.source.sensors;
      }
    }

    const eT0SourceWithoutId: IET0DataSource = {
      id: 'evapotranspiration',
      type: 'MODEL',
      sensors: sensorArray
    };

    const et0DataSourceStation = this.stationArray.filter(
      station => station.name.original === this.dataSourceET0.value.content.id
    );

    let dataSource_ET0 = null;

    if (this.dataSourceET0.value.content.hasOwnProperty('groups')) {
      dataSource_ET0 = {
        device_name: this.dataSourceET0.value.content.id,
        device_id: this.dataSourceET0.value.content.info.device_id,
        source: eT0SourceWithoutId,
        module: 'IRRIMET'
      };
    } else if (this.dataSourceET0.value.content.source.sensors) {
      dataSource_ET0 = {
        device_name: this.dataSourceET0.value.content.device_name,
        device_id: this.dataSourceET0.value.content.device_id,
        source: eT0SourceWithoutId,
        module: 'IRRIMET'
      };
    }

    if (this.cropzone.data_sources.some(source => source.source['id'] === 'evapotranspiration')) {
      this.cropzone.data_sources.forEach((datasource, index) => {
        if (datasource.module === 'IRRIMET' && datasource.source['id'] === 'evapotranspiration') {
          this.cropzone.data_sources[index] = dataSource_ET0;
        }
      });
    } else {
      this.cropzone.data_sources.push(dataSource_ET0);
    }

    if (manualRainGauge) {
      this.cropzone.manualRainGauge = true;
      if (this.cropzone.data_sources.some(datasource => datasource.module === 'IRRIMET' && datasource.source.type === 'SENSOR')) {
          this.cropzone.data_sources.forEach((datasource, index) => {
            if (datasource.module === 'IRRIMET' && datasource.source.type === 'SENSOR') {
              this.cropzone.data_sources.splice(index, 1);
            }
          });
      }
    } else {
      let sourceWithoutId: IRainDataSource;
      this.cropzone.manualRainGauge = false;
      if (this.dataSourceRain.value.content.hasOwnProperty('groups')) {
        const groupNumber = parseInt(Object.keys(this.dataSourceRain.value.content.groups)[0], 10);
        sourceWithoutId = {
          channel: this.dataSourceRain.value.content.groups[groupNumber][0].ch,
          code: this.dataSourceRain.value.content.groups[groupNumber][0].c,
          group: groupNumber,
          type: 'SENSOR',
          aggr: 'SUM'
        };
      } else if (this.dataSourceRain.value.content.hasOwnProperty('source')) {
        const groupNumber = parseInt(this.dataSourceRain.value.content.source.group, 10);
        sourceWithoutId = {
          channel: this.dataSourceRain.value.content.source.channel,
          code: this.dataSourceRain.value.content.source.code,
          group: groupNumber,
          type: 'SENSOR',
          aggr: 'SUM'
        };
      }

      let rainDataSourceStation;
      if (this.dataSourceRain.value.content.hasOwnProperty('groups')) {
        rainDataSourceStation = this.stationArray.filter(station =>
          station.name.original === this.dataSourceRain.value.content.id
        );
      } else if (this.dataSourceRain.value.content.hasOwnProperty('source')) {
        rainDataSourceStation = this.stationArray.filter(station =>
          station.name.original === this.dataSourceRain.value.content.device_name
        );
      }

      let dataSource_Rain: IDataSources;

      if (this.dataSourceRain.value.content.hasOwnProperty('groups')) {
        dataSource_Rain = {
          device_name: this.dataSourceRain.value.content.id,
          device_id: rainDataSourceStation[0].info.device_id,
          source: sourceWithoutId,
          module: 'IRRIMET'
        };
      } else if (this.dataSourceRain.value.content.hasOwnProperty('source')) {
        dataSource_Rain = {
          device_name: this.dataSourceRain.value.content.device_name,
          device_id: rainDataSourceStation[0].info.device_id,
          source: sourceWithoutId,
          module: 'IRRIMET'
        };
      }

      if (this.cropzone.data_sources.some(datasource => datasource.module === 'IRRIMET' && datasource.source.type === 'SENSOR')) {
        this.cropzone.data_sources.forEach((datasource, index) => {
          if (datasource.module === 'IRRIMET' && datasource.source.type === 'SENSOR') {
            this.cropzone.data_sources[index] = dataSource_Rain;
          }
        });
      } else {
        this.cropzone.data_sources.push(dataSource_Rain);
      }

      this.cropzone.rain_events = [];
    }

    this.cropzone.rain_efficacies = rainEfficacies;
    this.crop.id = this.selectedCrop.id;
    this.cropzone.crop = this.crop;
    this.cropzone.distance = +this.selectedDistance.content;

    this.cropzoneStore.dispatch(setCropZone(this.cropzone));
    this.selectedCropzoneStore.dispatch(setSelectedCropZone(this.cropzone));
    this.irrimetConfigStore.dispatch(updateCropzone(this.cropzone));
  }

  public checkKcR(event): void {
    if (
      event.kc[0][1] !== 0 &&
      event.kc[1][1] !== 0 &&
      event.kc[2][1] !== 0 &&
      event.kc[3][1] !== 0 &&
      event.kc[4][1] &&
      this.selectedCrop
    ) {

      this.crop = this.irrimetService.hydrateCrop(this.selectedCrop.id, event, this.unitSystem);
      this.isDefault = this.irrimetService.isDefaultCrop(event, this.selectedCrop);
    }
  }

  public onLoadCrops(): void {
    this.irrimetConfigStore.dispatch(getCropsLazyLoading());
  }

  public onLoadRain(): void {
    let isDate: boolean = <any>this.cropzone.from instanceof Date;
    let isDateTo: boolean = <any>this.cropzone.to instanceof Date;

    if (isDate) {
      const fromDate: Date = <any>this.cropzone.from;
      this.cropzone.from = fromDate.toISOString();
      isDate = false;
    }
    if (isDateTo) {
      const toDate: Date = <any>this.cropzone.to;
      this.cropzone.to = toDate.toISOString();
      isDateTo = false;
    }

    const groups = '5';

    this.irrimetConfigStore.dispatch(getDatasourcesRain(
      this.prepareData(groups, +this.selectedDistance.content)
    ));
  }

  public onLoadETO(): void {

    const groups = '1,2,4,6';

    this.irrimetConfigStore.dispatch(
      getDatasourcesET0(this.prepareData(groups, +this.selectedDistance.content))
    );
  }

  private prepareData(groups: string, distance: number): IOptionsForDatasources {
    return {
      centroid: centroid(this.cropzone.boundary),
      from: this.cropzone.from.split('T')[0],
      to: this.cropzone.to.split('T')[0],
      groups,
      distance
    };
  }

  public openModal(): void {
    this.modalService.openModal(this.modalId);
  }

  public openModalChart(): void {
    this.modalService.openModal(this.modalIdChart);
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }
}
