import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { IHydroponicsValues } from '../../models/models';

@Component({
  selector: 'app-hydroponics-table',
  templateUrl: './hydroponics-table.component.html',
  styleUrls: ['./hydroponics-table.component.scss']
})
export class HydroponicsTableComponent implements OnInit, OnDestroy {
  @Input()
  public response           : any = null;

  public translatedValues   : IHydroponicsValues;
  public unitSystem         : string = '';
  public headers            : string[] = [];
  public values             : string[] = [];
  private destroy$          : Subject<boolean> = new Subject<boolean>();

  constructor(
    private route           : ActivatedRoute,
  ) { }

  public ngOnInit(): void {
    this.translatedValues = this.route.snapshot.data['hydroponicsResolver'];

    this.getHeaders();
    this.getValues();
  }

  private getHeaders(): void {
    this.headers.push(this.translatedValues['qFactor']);
    this.headers.push(this.translatedValues['uFactor']);
    this.headers.push(this.translatedValues['balance']);
  }

  private getValues(): void {
    this.values.push(Math.round(this.response['coefficients']['DQ']) + ' %');
    this.values.push(Math.round(this.response['coefficients']['UQ']) + ' %');
    this.values.push(Math.round(this.response['coefficients']['B']) + ' %');
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

