import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[appWarningsPhone]'
})

export class WarningsPhoneDirective {

  @HostListener('keypress', ['$event'])
  public onKeyDown(event): void {
    const e: KeyboardEvent = <KeyboardEvent> event;
    if (!((Number(e.key) >= 0 && Number(e.key) <= 9) || e.charCode === 0)) {
      e.preventDefault();
    }
  }

}
