import { Component, EventEmitter, Input, OnDestroy, OnInit, OnChanges, SimpleChanges, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, takeWhile } from 'rxjs/operators';
import { IStation } from '../../../../../../core/models/stations';
import { selectStations } from '../../../../../../core/reducers/index';
import * as fromStations from '../../../../../../core/reducers/stations';
import * as fromAccount from '../../../../../../core/reducers/account';
import { IOptions, ISensor } from '../../../../../../shared/interfaces';
import { ChartProfiles, WindSpeed, WindDirection, hourlyWindroseData } from '../../../../constants/constants';
import { IWindRoseRequest } from '../../../../models/dashboard.models';
import { selectSensors } from '../../../../../soil-moisture-config/reducers';
import { ISensorGroups } from '../../../../../station-config/models/station-config.models';
import { ApiCallService } from '../../../../../../services/api/api-call.service';
import { PeriodScopeHourly } from '../../../../../../shared/constants/sample-data.constant';
import { DevicesWithoutWindSensor } from '../../../../../../shared/constants';
import { deepClone } from '../../../../../../shared/utils/deepClone';

@Component({
  selector: 'app-wind-rose-diagram-settings',
  templateUrl: './wind-rose-diagram-settings.component.html',
  styleUrls: ['./wind-rose-diagram-settings.component.scss']
})
export class WindRoseDiagramSettingsComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  private chartRequest              : IWindRoseRequest;
  @Input()
  public loading                    : boolean;
  @Output()
  private updateRequest             : EventEmitter<IWindRoseRequest> = new EventEmitter();
  @Output()
  private updateWidgetData          : EventEmitter<IWindRoseRequest> = new EventEmitter();

  public form                       : FormGroup;
  public allStations$               : Observable<Array<IStation>>;
  public filteredStations           : Observable<Array<IStation>>;
  public allSensors$               : Observable<Array<ISensor>>;
  public groupValues                : Array<IOptions> = PeriodScopeHourly;
  public lastValues                 : Array<IOptions> = hourlyWindroseData;
  public windSpeedValues            : Array<IOptions> = WindSpeed;
  public winddirValues              : Array<IOptions> = WindDirection;
  public chartProfiles              : Array<IOptions> = ChartProfiles;
  private alive                     : boolean = true;

  constructor(private stationsStore: Store<fromStations.IStations>,
              private userStore: Store<fromAccount.IAccount>,
              private api: ApiCallService,
              private formBuilder: FormBuilder) {}

  public get group(): AbstractControl {
    return this.form.get('group');
  }

  public get last(): AbstractControl {
    return this.form.get('last');
  }

  public get windspeed(): AbstractControl {
    return this.form.get('windspeed');
  }

  public get winddir(): AbstractControl {
    return this.form.get('winddir');
  }

  public get station(): AbstractControl {
    return this.form.get('station');
  }

  public get profile(): AbstractControl {
    return this.form.get('profile');
  }

  public ngOnInit(): void {
    this.allStations$ = this.stationsStore.pipe(
      select(selectStations),
      filter((stations: IStation[]) => !!stations && stations.length > 0),
      map((stations: IStation[]) => this.sortArray(stations))
    );
    this.allSensors$ = this.stationsStore.pipe(select(selectSensors));

    this.stationsStore.pipe(
      select(selectStations),
      filter((stations: IStation[]) => !!stations && stations.length > 0),
      takeWhile(() => this.alive)
    ).subscribe((stations) => {
      this.filteredStations = of(stations?.filter((s: IStation) => !(DevicesWithoutWindSensor.includes(s.info.device_id)))).pipe(
        map((allStations: IStation[]) => this.sortArray(allStations))
      );
    });

    this.form = this.formBuilder.group({
      'group': new FormControl(this.chartRequest.endpoint.group),
      'last' : new FormControl(this.chartRequest.endpoint.last),
      'station': new FormControl(this.chartRequest.endpoint.station),
      'windspeed': new FormControl(this.chartRequest.endpoint.windspeed),
      'winddir': new FormControl(this.chartRequest.endpoint.winddir),
      'profile': new FormControl(this.chartRequest.profile)
    });

    this.form.valueChanges.pipe(
      distinctUntilChanged(),
      takeWhile(() => this.alive)
    ).subscribe(() => {
      this.changeRequest();
    });
  }

  private sortArray(stationArray: IStation[]): IStation[] {
    const stations = deepClone(stationArray);
    const customNamedStations = stations.filter((station) => station.name.custom !== '');
    customNamedStations.sort((a, b) => {
      const nameA = a.name.custom.toLowerCase();
      const nameB = b.name.custom.toLowerCase();

      const isNumberA = !isNaN(Number(nameA));
      const isNumberB = !isNaN(Number(nameB));

      if (isNumberA && isNumberB) {
        return Number(nameA) - Number(nameB);
      } else if (isNumberA) {
        return -1;
      } else if (isNumberB) {
        return 1;
      } else {
        return nameA.localeCompare(nameB);
      }
    });

    const otherStations = stations.filter((station) => station.name.custom === '');
    otherStations.sort((a: IStation, b: IStation) => a.name.original.toLowerCase() < b.name.original.toLowerCase() ? -1 : 1);

    return customNamedStations.concat(otherStations);
  }

  public ngOnChanges(changes: SimpleChanges) : void {
    // sensor groups filter
    const previousStation = changes?.chartRequest?.previousValue?.endpoint?.station;
    const currentStation = changes?.chartRequest?.currentValue?.endpoint?.station;
    if ((previousStation !== currentStation) && currentStation) {
      this.getGroupSensorsList(currentStation);
    }
  }

  public changeRequest(): void {
    const newRequest = {
      endpoint: {
        group: this.group.value,
        last: this.last.value,
        station: this.station.value,
        windspeed: this.windspeed.value,
        winddir: this.winddir.value
      },
      legend: this.chartRequest.legend,
      profile: 'Wind monitoring'
    };
    // Remove station health from virtual stations
    if (this.station.value.substring(0, 3) === 'D00') {
      this.chartProfiles = this.chartProfiles.filter(item => item.value !== 'Station Health');
    } else {
      this.chartProfiles = ChartProfiles;
    }
    this.updateRequest.emit(newRequest);
  }

  public submit(): void {
    const newRequest = {
      endpoint: {
        group: this.group.value,
        last: this.last.value,
        station: this.station.value,
        windspeed: this.windspeed.value,
        winddir: this.winddir.value
      },
      legend: this.chartRequest.legend,
      profile: 'Wind monitoring'
    };
    this.updateWidgetData.emit(newRequest);
  }

  public getGroupSensorsList(stationId): void {
    const groupsList: ISensorGroups = {
      groups: [6, 16, 66]
    };
    this.windSpeedValues = JSON.parse(JSON.stringify(WindSpeed));
    this.winddirValues = JSON.parse(JSON.stringify(WindDirection));
    this.api.getSensorsByGroups(stationId, groupsList).pipe().subscribe(response => {
      response.map(item => {
        if (item.group === 6 || item.group === 66) {
          this.windSpeedValues.push({
            value: item.channel,
            content: item.name + ' [ ' + item.channel + ' ]'
          });
        } else if (item.group === 16) {
          this.winddirValues.push({
            value: item.channel,
            content: item.name + ' [ ' + item.channel + ' ]'
          });
        }
      });
      if (this.windSpeedValues?.length > 1) {
        this.windspeed.setValue(this.windSpeedValues[1].value);
      }
      if (this.winddirValues?.length > 1) {
        this.winddir.setValue(this.winddirValues[1].value);
      }
    });
  }

  public isWinddir(): boolean {
    return this.winddirValues.length <= 1;
  }

  public isWindspeed(): boolean {
    return this.windSpeedValues.length <= 1;
  }

  public isSubmitDisabled(): boolean {
    return this.loading || this.windspeed.value === '' || this.winddir.value === '';
  }

  public ngOnDestroy(): void {
    this.alive = false;
  }
}
