import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'angular-highcharts';
import { AxisOptions } from 'highcharts';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { isNull } from 'util';
import { ISettings } from '../../../../core/models/account';
import { selectSettings, selectStations } from '../../../../core/reducers';
import { IAccount } from '../../../../core/reducers/account';
import { INavigationCropzoneState } from '../../../../core/reducers/navigation-cropzone';
import * as fromSelectedCropzone from '../../../../core/reducers/selectedCropZone';
import * as fromStations from '../../../../core/reducers/stations';
import { stationDataContentAnimations } from '../../../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../../../core/services/left-components-toggler/left-components-toggler.service';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { ITreeStructure } from '../../../../services/tree/models';
import { TreeService } from '../../../../services/tree/tree.service';
import { deepClone } from '../../../../shared/utils/deepClone';
import { generateId } from '../../../dashboard/utils/makeWidget';
import chartZoomSync from '../../chartZoomSync';
import { DEFAULT_CHART_OPTIONS } from '../../constants/constants';
import { ICropZonePartial, IIrrimetState, IWaterBalanceFromTo } from '../../models/models';
import { waterBalanceFromToSelector } from '../../selectors/irrimet-water-balance';
import { selectSelectedCropZone } from './../../../../core/reducers/index';
import { ModalService } from './../../../../shared/modal/services/modal.service';

@Component({
  selector: 'app-available-water-paw',
  templateUrl: './irrimet-available-water-paw.component.html',
  styleUrls: ['./irrimet-available-water-paw.component.scss'],
  animations: stationDataContentAnimations
})
export class IrrimetAvailableWaterPawComponent implements OnInit, OnDestroy {

  public charts: Chart[] = [];
  public chartData = [[], []];
  public tree$: BehaviorSubject<ITreeStructure>;
  public state$: Observable<string>;
  public cropzonePartial$: Observable<ICropZonePartial>;
  public datasources$: Observable<any>;
  private destroy$: Subject<boolean> = new Subject();
  public isError: boolean = false;
  public isLoading: boolean = true;
  public dataAvailable: boolean = false;
  public modalId: string = generateId();
  private precipitationString: string = 'precipitation (eff.)';
  private irrigationString: string = 'irrigation (eff.)';
  private rootDepthString: string = 'Root depth';
  private fieldCapacityString: string = 'Field Capacity';
  private refillPointString: string = 'Refill Point';
  private PAWString: string = 'PAW';
  public unitSystem: string;
  public cropzoneName: string;

  public apiResponse: any;
  public fromTo: any;

  public zoomed: boolean = false;
  public waterForCurrentPeriod: boolean = true;
  public pawForCurrentPeriod: boolean = true;
  private periodChanged: boolean = false;
  private availableWater = [];

  constructor(
    private treeService: TreeService,
    private leftComponentsToggler: LeftComponentsTogglerService,
    private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private navigationStore: Store<INavigationCropzoneState>,
    private accountStore: Store<IAccount>,
    private irrimetStore: Store<IIrrimetState>,
    private api: ApiCallService,
    private translation: TranslateService,
    private modalService: ModalService,
    private account: Store<IAccount>,
    private stationsStore: Store<fromStations.IStations>,
  ) {

  }

  public ngOnInit(): void {
    this.translation.get('precipitation (eff.)').subscribe(translatedPrecipitationString =>
      this.precipitationString = translatedPrecipitationString);
    this.translation.get('irrigation (eff.)').subscribe(translatedIrrigationString =>
      this.irrigationString = translatedIrrigationString);
    this.translation.get('Root depth').subscribe(translatedRootDepthString =>
      this.rootDepthString = translatedRootDepthString);
    this.translation.get('PAW').subscribe(translatedPAWString =>
        this.PAWString = translatedPAWString);
    this.translation.get('Field Capacity').subscribe(translatedFCString =>
      this.fieldCapacityString = translatedFCString);
    this.translation.get('Refill Point').subscribe(translatedRPString =>
      this.refillPointString = translatedRPString);

    this.irrimetStore.pipe(
      select(waterBalanceFromToSelector),
      debounceTime(300),
      takeUntil(this.destroy$)
    ).subscribe((wb) => {
      this.fromTo = wb;
      this.updateCharts(wb);
    });

    this.account.pipe(
      select(selectSettings),
      filter(settings => !!settings),
      takeUntil(this.destroy$)
    ).subscribe(settings => {
      this.unitSystem = settings.unit_system;
    });

    this.charts = chartZoomSync([
      new Chart({
        ...deepClone(DEFAULT_CHART_OPTIONS),
        legend: {
          enabled: true
        },
        chart: {
          height: 400,
          marginLeft: 85,
          marginRight: 200,
          marginTop: 5,
          style: { fontFamily: 'Helvetica' },
          zoomType: 'x',
          alignTicks: false,
          resetZoomButton: {
            theme: {
              display: 'none'
            }
          },
          events: {
            selection: (event) => {
              this.zoomed = true;
              localStorage.setItem('zoomed', 'true');
              localStorage.setItem('min', String(event.xAxis[0].min));
            }
          }
        },
        yAxis: [
          {
            title: {
              text: this.PAWString
            },
            plotBands: [
              {
                color: 'rgba(227,26,28, 0.5)',
                from: Number.MIN_SAFE_INTEGER,
                to: 0,
              },
              {
                color: 'rgba(178,223,138, 0.5)',
                from: 0,
                to: 100,
              },
              {
                color: 'rgba(166,206,227, 0.5)',
                from: 100,
                to: Number.MAX_SAFE_INTEGER
              },
            ]
          },
          {
            title: {
              text: this.rootDepthString
            },
            opposite: true
          },
        ],
        series: [
          {
            type: 'line',
            name: this.PAWString,
            pointPadding: 0,
            groupPadding: 0,
            borderWidth: 0,
            shadow: false,
            data: [],
            color: '#000000',
            marker: {
              enabled: false,
              symbol: 'circle',
              states: {
                hover: {
                  enabled: true
                }
              }
            },
            states: {
              hover: {
                enabled: true
              }
            },
            tooltip: {
              valueDecimals: 1
            }
          } as any,
          {
            type: 'line',
            name: this.rootDepthString,
            yAxis: 1,
            data: [],
            color: '#A9A9A9',
            marker: {
              enabled: false,
              symbol: 'circle',
              states: {
                hover: {
                  enabled: true
                }
              }
            },
            states: {
              hover: {
                enabled: true
              }
            },
            tooltip: {
              valueDecimals: this.unitSystem === 'metric' ? 1 : 3
            }
          } as any,
          {
            type: 'line',
            name: this.fieldCapacityString,
            data: [],
            color: 'blue',
            marker: {
              enabled: false,
              symbol: 'circle',
              states: {
                hover: {
                  enabled: true
                }
              }
            },
            states: {
              hover: {
                enabled: true
              }
            },
            tooltip: {
              valueDecimals: 0
            }
          } as any,
          {
            type: 'line',
            name: this.refillPointString,
            data: [],
            color: 'red',
            marker: {
              enabled: false,
              symbol: 'circle',
              states: {
                hover: {
                  enabled: true
                }
              }
            },
            states: {
              hover: {
                enabled: true
              }
            },
            tooltip: {
              valueDecimals: 0
            }
          } as any,
        ],
      }),
      new Chart({
        ...deepClone(DEFAULT_CHART_OPTIONS),
        chart: {
          height: 250,
          marginLeft: 85,
          marginRight: 200,
          marginTop: 5,
          style: { fontFamily: 'Helvetica' },
          zoomType: 'x',
          alignTicks: false,
          resetZoomButton: {
            theme: {
              display: 'none'
            }
          },
          events: {
            selection: (event) => {
              this.zoomed = true;
              localStorage.setItem('zoomed', 'true');
              localStorage.setItem('min', String(event.xAxis[0].min));
            }
          }
        },
        yAxis: [
          {
            title: {
              text: 'prec. / irri.'
            }
          },
          {
            title: {
              text: 'K<sub>c</sub>'
            },
            opposite: true
          },
          {
            title: {
              text: 'ET<sub>c</sub>'
            },
            opposite: true
          }
        ],
        series: [
          {
            type: 'column',
            name: this.precipitationString,
            yAxis: 0,
            data: [],
            color: '#3F729B',
            tooltip: {
              valueDecimals: this.unitSystem === 'metric' ? 1 : 3
            }
          },
          {
            type: 'column',
            name: this.irrigationString,
            yAxis: 0,
            data: [],
            tooltip: {
              valueDecimals: this.unitSystem === 'metric' ? 1 : 3
            }
          },
          {
            type: 'line',
            name: 'K<sub>c</sub>',
            yAxis: 1,
            data: [],
            color: '#398439',
            marker: {
              enabled: false,
              symbol: 'square',
              states: {
                hover: {
                  enabled: true
                }
              }
            },
            states: {
              hover: {
                enabled: true
              }
            },
            tooltip: {
              valueDecimals: 2
            }
          } as any,
          {
            type: 'area',
            name: 'ET<sub>c</sub>',
            yAxis: 2,
            data: [],
            color: '#ff7f00',
            fillOpacity: 0.2,
            marker: {
              enabled: false
            },
            tooltip: {
              valueDecimals: this.unitSystem === 'metric' ? 1 : 3
            }
          }
        ]
      })
    ]);

    if (localStorage.hasOwnProperty('zoomed')) {
      localStorage.getItem('zoomed') === 'true' ? this.zoomed = true : this.zoomed = false;
    }
    localStorage.setItem('periodChanged', 'false');

    this.tree$ = this.treeService.getIrrimetTreeStructure();
    this.state$ = this.leftComponentsToggler.getStationDataContentState();

    this.accountStore.pipe(
      select(selectSettings),
      filter(settings => !!settings),
      takeUntil(this.destroy$)
    ).subscribe(settings => this.translateCharts(settings));

    this.datasources$ = combineLatest([
      this.selectedCropzoneStore.pipe(select(selectSelectedCropZone)),
      this.stationsStore.pipe(select(selectStations))
    ]).pipe(
      filter(([cropzone, stations]) => Array.isArray(stations)),
      map(([cropzone, stations]) => {

        const datasources = cropzone.data_sources
          .filter(datasource => datasource.module === 'IRRIMET')
          .map(datasource => ({
            name: datasource.device_name,
            type: datasource.source.id === 'evapotranspiration' ? 'ETO' : 'Rain',
            virtual: false
          }));

        for (const station of stations) {
          for (const datasource of datasources) {
            if (datasource.name === station.name.original) {
              if (station.info.device_name === 'Virtual Station') {
                datasource.virtual = true;
              }
            }
          }
        }

        return datasources;
      })
    );

    this.cropzonePartial$ = this.selectedCropzoneStore.pipe(
      select(selectSelectedCropZone),
      filter(cropZone => !!cropZone),
      tap(() => this.isLoading = true),
      map(cropZone => ({
        id: cropZone.id,
        name: cropZone.name,
        from: this.setToMidnight(new Date(cropZone.from)),
        to: Date.now() < Date.parse(cropZone.to) ? this.setToMidnight(new Date(Date.now())) : this.setToMidnight(new Date(cropZone.to))
      })),
      tap(cropZone => {
        this.fromTo = {from: cropZone.from, to: cropZone.to};
        this.fetchDataAndUpdateCharts(cropZone);
        this.cropzoneName = cropZone.name;
      }),
    );
  }

  public onPeriodChanged(event): void {
    this.periodChanged = true;
    localStorage.setItem('periodChanged', 'true');
   }

  private setToMidnight(date: Date): Date {
    return new Date(date.setHours(0, 0, 0, 0));
  }

  private fetchDataAndUpdateCharts(cropZone: ICropZonePartial): void {
    this.api.getIrrimet(cropZone.id).pipe(
      withLatestFrom(this.irrimetStore.pipe(select(waterBalanceFromToSelector))),
      takeUntil(this.destroy$)
    ).subscribe({
      next: ([response, fromTo]) => {
        this.isLoading = this.isError = false;
        this.apiResponse = response;

        if (fromTo.from !== null && fromTo.to !== null) {
          this.fromTo = fromTo;
        }

        if (response.length !== 0) {
          this.dataAvailable = true;
          this.chartData = response.reduce((data: [number, number][][][], d) => {

          const date = Date.parse(d.date);
          this.availableWater.push(d.WB);

          const paw = d.WB / d.WB_max * 100;
          data[0][0].push([date, paw]);                       // PAW
          if (isNaN(paw)) {
            data[0][2].push([date, NaN]);                     // don't show FC, if PAW is NaN
            data[0][3].push([date, NaN]);                     // don't show RP, if PAW is NaN
          } else {
            data[0][2].push([date, 100]);                     // FC
            data[0][3].push([date, 0]);                       // RP
          }
          data[1][0].push([date, d.W_p_eff]);
          data[1][1].push([date, d.W_i_eff]);
          data[1][2].push([date, d.K_c]);
          data[1][3].push([date, d.ET_c]);

          if (d.R !== null) {                                 // Root depth
            if (this.unitSystem === 'metric') {
              data[0][1].push([date, d.R * 100]);
            } else {
              data[0][1].push([date, d.R * 39.37]);
            }
          } else {
            data[0][1].push([date, null]);
          }
          return data;

        }, [[[], [], [], []], [[], [], [], []]]);

        this.updateCharts(fromTo);
        } else {
          this.dataAvailable = false;
          return;
        }
      },
      error: () => {
        this.isError = true;
        this.isLoading = false;
      }
    });
  }

  private translateCharts(settings: ISettings): void {
    const unit = settings.unit_system === 'metric' ? 'mm' : 'in';
    const rootdepthUnit = settings.unit_system === 'metric' ? 'cm' : 'in';
    this.charts.forEach((chart: Chart, i: number) => {
      const axis = chart.options.yAxis as AxisOptions;
      const series: Array<any> = chart.options.series;
      series.forEach(element => {
        element.tooltip.valueSuffix = ' ' + unit;
        if (element.name === this.rootDepthString) {
          element.tooltip.valueSuffix = ' ' + rootdepthUnit;
        } else if (element.name === 'K<sub>c</sub>') {
          element.tooltip.valueSuffix = null;
        } else if (element.name === this.PAWString ||
                   element.name === this.fieldCapacityString ||
                   element.name === this.refillPointString) {
            element.tooltip.valueSuffix = ' %';
        }
      });

      if (i === 1) {                                                                     // second chart
        this.translation.get(
          this.getAxisText(axis[0].title.text)
        ).subscribe(text => axis[0].title.text = `${text} [${unit}]`);              // 'prec. / irri. [' + unit + ']'
        this.translation.get(
          this.getAxisText(axis[2].title.text)
        ).subscribe(text => axis[2].title.text = `${text} [${unit}]`);              // 'ETc'
      } else {                                                                           // first chart
        this.translation.get(
          this.getAxisText(axis[1].title.text)
        ).subscribe(text => axis[1].title.text = `${text} [${rootdepthUnit}]`);     // 'Root depth [' + unit + ']'
        this.translation.get(
          this.getAxisText(axis[0].title.text)
        ).subscribe(text => axis[0].title.text = `${text} [%]`);                    // 'PAW [%]'
      }
    });
  }

  private getAxisText(text: string): string {
    const length = text.lastIndexOf('[') === -1 ? text.length : text.lastIndexOf('[');
    return text.substr(0, length);
  }

  private updateCharts(fromTo: IWaterBalanceFromTo): void {
    this.waterForCurrentPeriod = false;
    if (this.chartData[0][0]) {
      let start = 0, end = this.chartData[0][0].length - 1;
      let availableWaterForPeriod = [];

      if (fromTo.from && fromTo.to && this.chartData[0][0][0].length) {
        start = this.getNoOfDays(new Date(this.chartData[0][0][0][0]), fromTo.from);
        start = start < 0 ? 0 : start;
        end = start + this.getNoOfDays(fromTo.from, fromTo.to);
      }

      this.chartData.forEach((data: any[], i: number) => {
        data.forEach((d, j) => {
          if (this.charts[i].ref) {
            this.charts[i].ref.series[j].setData(d.slice(start, end));
          } else {
            this.charts[i].options.series[j].data = d.slice(start, end);
          }
        });
      });
      // Available Water data for current period
      availableWaterForPeriod = this.availableWater.slice(start, end);
      // checking if Available Water data is available for current period
      this.waterForCurrentPeriod = this.availableWaterData(availableWaterForPeriod);
      // checking if PAW data is available for current period
      this.pawForCurrentPeriod = this.availablePawData(this.charts[0]);

      const maxValueRoothDepth = this.chartData[0][1]
        .map(data => data[1])
        .reduce((a, b) => Math.max(a, b));
      this.charts[0].options.series[1].visible = !!maxValueRoothDepth;

        let minimal = this.charts[0].options.series[0].data[0][0];    // set MIN to first date
        localStorage.setItem('startDate', minimal);
      if (this.zoomed === true && this.periodChanged === false) {
        minimal = Number(localStorage.getItem('min'));
      } else {                                                        // if period WAS changed, set new MIN and new startDate for resetZoom
        localStorage.setItem('min', minimal);
        localStorage.setItem('startDate', minimal);
        this.onResetZoom();
      }

      if (this.charts[0].ref) {
          this.charts[0].ref.xAxis[0].setExtremes(minimal, null);
        }
      if (this.charts[1].ref) {
        this.charts[1].ref.xAxis[0].setExtremes(minimal, null);
      }
    }
  }

  private getNoOfDays(d0: Date, d1: Date): number {
    return Math.ceil((d1.getTime() - d0.getTime()) / (24 * 60 * 60 * 1000));
  }

  private availableWaterData(availableWater: Array<number>): boolean {
    if (!!(availableWater                                     // if at least one WB value is available, data for current period is available
      .reduce((a, b) => Math.max(a, b)))) {
      return true;
    }
    return false;
  }

  private availablePawData(chart: Chart): boolean {
    return (!!(chart.options.series[0].data             // if at least one Finite value is available, data for current period is available
      .map(data => data[1])
      .filter(el => isFinite(el))
      .filter(el => el !== null)).length);
  }

  public syncCrosshair(event: any): void {
    this.charts.forEach(data => {
      const chart: any = data.ref;
      event = chart.pointer.normalize(event);
      const points = chart.series.map(series => {
        if (series.visible) {
          series.searchPoint(event, true);
        }
      });
      if (points.length > 0 && !points.some(p => !p)) {
        chart.tooltip.refresh(points, event);
        chart.xAxis[0].drawCrosshair(event, points[0]);
      }
    });
  }

  public hideCrosshair(event: any): void {
    this.charts.forEach((chart: any) =>
      chart.ref.tooltip.hide()
    );
  }

  public openHelpModal(): void {
    this.modalService.openModal(this.modalId);
  }

  public onResetZoom(): void {
    if (localStorage.hasOwnProperty('startDate')) {
      if (this.charts[0].ref) {
        this.charts[0].ref.xAxis[0].setExtremes(Number(localStorage.getItem('startDate')), null);
      }
      if (this.charts[1].ref) {
        this.charts[1].ref.xAxis[0].setExtremes(Number(localStorage.getItem('startDate')), null);
      }
    } else {
      if (this.charts[0].ref) {
        this.charts[0].ref.xAxis[0].setExtremes(null, null);
      }
      if (this.charts[1].ref) {
        this.charts[1].ref.xAxis[0].setExtremes(null, null);
      }
    }
    if (this.charts[0].ref) {
      this.charts[0].ref.yAxis[0].setExtremes(null, null);
    }
    if (this.charts[1].ref) {
      this.charts[1].ref.yAxis[0].setExtremes(null, null);
    }
    this.zoomed = false;
    localStorage.setItem('zoomed', 'false');
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
