<app-chart-toolbar-layout>
  <form class="irrimet-water-balance-toolbar">
    <div class="irrimet-water-balance-toolbar__left">
      <div class="irrimet-water-balance-toolbar__toggle irrimet-water-balance-toolbar__left__item">
        <app-custom-button (click)="isLastDataMode.setValue(true)"
                           [color]="isLastDataMode.value ? 'dark-green' : 'green'"
                           tooltipPlacement="top" [tooltipTitle]="'Last data mode' | translate" appTooltip>
          <span class="fa fa-step-backward"></span>
        </app-custom-button>
        <app-custom-button (click)="isLastDataMode.setValue(false)"
                           [color]="!isLastDataMode.value ? 'dark-green' : 'green'"
                           tooltipPlacement="top" [tooltipTitle]="'From - To mode' | translate" appTooltip>
          <span class="fa fa-calendar"></span>
        </app-custom-button>
      </div>

      <ng-container *ngIf="isLastDataMode.value">
        <app-custom-dropdown [formControl]="period" [options]="periods"
                             class="irrimet-water-balance-toolbar__left__item">
        </app-custom-dropdown>
      </ng-container>

      <ng-container *ngIf="!isLastDataMode.value">
        <app-custom-datepicker [formControl]="from"
                               [isSmall]="true"
                               [maxDate]="to.value"
                               [minDate]="minDate">
        </app-custom-datepicker>
        <app-custom-datepicker [formControl]="to"
                               [isSmall]="true"
                               [maxDate]="maxDate"
                               [minDate]="from.value"
                               class="irrimet-water-balance-toolbar__left__item">
        </app-custom-datepicker>
      </ng-container>

      <app-date-range-arrow [formControl]="range" [hidden]="!isLastDataMode.value" [maxDate]="maxDate"
                            [minDate]="minDate" [period]="computedPeriod.value + 'd'">
      </app-date-range-arrow>
    </div>
    <div class="irrimet-water-balance-toolbar__right">
      <app-custom-button [shadow]="false" appTooltip [tooltipPlacement]="'top'"
                         [tooltipTitle]="'Export table as xls' | translate" [color]="'white'"
                         (click)="startExportXLS()">
        <i class="fa fa-table"></i>
      </app-custom-button>

      <div class="irrimet-water-balance-toolbar__toggle irrimet-water-balance-toolbar__right__item">
        <app-custom-button [color]="isTableActive.value ? 'green' : 'white'" appTooltip [tooltipPlacement]="'top'"
                           [tooltipTitle]="'Toggle table' | translate" [shadow]="false"
                           (click)="isTableActive.setValue(!isTableActive.value)" [radius]="'2px'">
          <span class="fa fa-table"></span>
        </app-custom-button>
      </div>
    </div>
  </form>
</app-chart-toolbar-layout>
