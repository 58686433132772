import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, mergeMap, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { environmentToken } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../../../environments/interfaces/theme';
import { selectStations } from '../../../../core/reducers';
import * as fromStations from '../../../../core/reducers/stations';
import { StationTypes } from '../../../../shared/constants';
import { IOptions, ISensor } from '../../../../shared/interfaces';
import { INodes } from '../../../sensors-and-nodes/models/sensors-and-nodes';
import { alertTypes } from '../../../server-alerts/constants/server-alerts';
import { ServerAlertsService } from '../../../server-alerts/services/server-alerts.service';
import { addServerAlert } from '../../actions/alerts';
import { getStationSensors } from '../../actions/sensors';
import { selectSensorsConfig } from '../../reducers';
import * as fromAlerts from '../../reducers/alerts';
import { ApiCallService } from './../../../../services/api/api-call.service';
import { ModalService } from './../../../../shared/modal/services/modal.service';
import { ISerialNames } from './../../../sensors-and-nodes/models/sensors-and-nodes';
import { aggrType, resendOptions } from './../../../server-alerts/constants/server-alerts';

@Component({
  selector: 'app-new-alerts',
  templateUrl: './new-alerts.component.html',
  styleUrls: ['./new-alerts.component.scss']
})
export class NewAlertsComponent implements OnInit, OnDestroy {
  public stationSensors$: Observable<ISensor[]>;
  public sensorsApi: Array<ISensor> = [];
  public allStations$: Observable<IOptions[]>;
  public sensors: Array<IOptions> = [];
  public resendOptions = resendOptions;
  public sensorOptions$: Observable<IOptions[]>;
  public alertForm: FormGroup;
  public alertTypes = alertTypes;
  public stationId: string;
  public nodes: INodes;
  public serials: ISerialNames;
  public optionUnit: string;
  public defaultSensor: string;
  public isLoading: boolean = true;
  public NEW_ALERT_MODAL_ID = 'NEW_ALERT_MODAL_ID';
  public subDomain: IThemeConfig;

  public readonly formatError   = 'Format issue - please enter the right format (1, -1.2, 3.45)';
  public readonly emailError    = 'Introduced email doesn\'t exist';

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    private stationsStore: Store<fromStations.IStations>,
    private fb: FormBuilder,
    private alertsStore: Store<fromAlerts.IAlertsState>,
    private alertsService: ServerAlertsService,
    private api: ApiCallService,
    private modalService: ModalService
  ) {
    this.subDomain = environment.theme;
  }

  public get threshold(): AbstractControl {
    return this.alertForm.get('threshold');
  }

  public get email(): AbstractControl {
    return this.alertForm.get('email').get('destination');
  }

  public get sensor(): AbstractControl {
    return this.alertForm.get('sensor');
  }

  public get type(): AbstractControl {
    return this.alertForm.get('type');
  }

  public ngOnInit(): void {
    this.allStations$ = this.stationsStore.pipe(
      select(selectStations),
      filter(stationList => !!stationList && stationList.length > 0),
      takeUntil(this.destroy$),
      map((stationList) => {
        const stations = stationList
        .filter((item) => item.rights === 'rw'
                          && item.info.device_id !== StationTypes.get('iMetos Active Tracker')
                          && item.info.device_id !== StationTypes.get('iMetos Object Tracker')
                          && item.info.device_id !== StationTypes.get('CropViewType1')
                          && item.info.device_id !== StationTypes.get('CropViewType2')
                          && item.info.device_id !== StationTypes.get('IScoutType')
                          && item.info.device_id !== StationTypes.get('CropViewType3')
                          && item.info.device_id !== StationTypes.get('SolAntenna')
                          && item.info.device_id !== StationTypes.get('IScoutMobileType')
                          && item.info.device_id !== StationTypes.get('SoilGuard Probe')
                          && item.info.device_id !== StationTypes.get('Dualex Device'))
        .map((station) => {
          return {
            value: station.name.original,
            content: station.name.custom ? `${station.name.custom} [${station.name.original}]` : station.name.original
          };
        });

        return stations;
      })
      );

      this.stationSensors$ = this.alertsStore.pipe(
        select(selectSensorsConfig),
        filter((sensors) => !!sensors && sensors !== undefined && sensors.length > 0),
        takeUntil(this.destroy$)
      );

      this.stationSensors$.pipe(
        filter(sensors => !!sensors && !!this.stationId),
        takeUntil(this.destroy$),
        map((sensors: ISensor[]) => {
          this.sensorsApi = sensors;
        }),
        switchMap(() => this.api.getStationNodes(this.stationId).pipe(
          takeUntil(this.destroy$),
          tap((nodes: INodes) => {
            this.nodes = nodes;
          })
        )),
        switchMap(() => this.api.getStationSerials(this.stationId).pipe(
          takeUntil(this.destroy$),
          tap((serials: ISerialNames) => {
            this.serials = serials;
          })
        ))
      ).subscribe(() => {
        this.isLoading = false;
        this.sensors = this.alertsService.setListOfSensors(this.sensorsApi, this.nodes, this.serials);
        if (this.sensors.length > 0) {
          this.defaultSensor = this.sensors[0].value;
        }
      });

      this.createForm();
  }

  public createForm(): void {
    this.alertForm = this.fb.group({
      station: ['', [Validators.required]],
      threshold: ['', [Validators.required, this.alertsService.checkThreshold()]],
      type: ['min', [Validators.required]],
      sensor: [this.defaultSensor, [Validators.required]],
      resend: [60, [Validators.required]],
      email: this.createTarget('email'),
      push_web: this.createTarget('push_web', false),
      push_mobile: this.createTarget('push_mobile', true),
    });

    this.email.setValidators([this.alertsService.checkEmail()]);

    this.alertForm.get('station')
      .valueChanges
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe((station: string) => {
        this.stationId = station;
        this.alertsStore.dispatch(getStationSensors(station));
        this.type.setValue('min');
        this.sensors = [];
      });

      this.sensorOptions$ = this.alertForm.get('type')
      .valueChanges
      .pipe(
        startWith('min'),
        takeUntil(this.destroy$),
        mergeMap(value => {
          return this.alertsService.filterOptions(value, this.sensors);
        })
      );

      this.alertForm.get('sensor')
        .valueChanges
        .pipe(
          startWith(this.defaultSensor),
          takeUntil(this.destroy$)
        ).subscribe(data => {
          if (data) {
          const sensorData = data.split('_');
          this.optionUnit = sensorData[sensorData.length - 1];
          }
        });
  }

  public createTarget(method: string, data: String | boolean = ''): FormGroup {
    return this.fb.group({
      method: [method, [Validators.required]],
      destination: data
    });
  }

  public save(): void {
    if (this.alertForm.valid && this.stationId) {
      const { email, push_mobile, push_web, sensor, resend, threshold, ...form} = this.alertForm.value;

      const sensorData = sensor.split('_');
      const thresholds = threshold.split(',').map(Number);
      const sensorChannel = +sensorData[0];
      const sensorCode = +sensorData[1];
      const aggrCode = sensorData[2];
      const aggr = aggrType[aggrCode];
      const active = true;
      const resendPeriod = Number(resend);

      const targets = [email, push_mobile, push_web]
        .filter(_ => _.destination)
        .map(_ => ({..._, destination: String(_.destination)}));

      const alert = { ...form, thresholds, sensorChannel, sensorCode, aggr, targets, active, resendPeriod };
      delete alert.station;
      alert.nm = this.stationId;

      this.alertsStore.dispatch(
        addServerAlert({
          id: this.stationId,
          alert: alert
        })
      );
    }
  }

  public openInfoModal(): void {
    this.modalService.openModal(this.NEW_ALERT_MODAL_ID);
  }

  public ngOnDestroy(): void {
      this.destroy$.next(true);
      this.destroy$.complete();
  }

}
