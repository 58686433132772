import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { setViewDate } from '../../actions/irrigation.action';
import * as fromIrrigationEvents from '../../reducers/irrimet.reducer';

@Component({
  selector: 'app-irrimet-calendar-header',
  templateUrl: './irrimet-calendar-header.component.html'
})

export class IrrimetCalendarHeaderComponent {

  @Input()
  public view: string;

  @Input()
  public viewDate: Date;

  @Input()
  public locale;

  @Output()
  public viewChange: EventEmitter<string> = new EventEmitter();

  @Output()
  public viewDateChange: EventEmitter<Date> = new EventEmitter();

  constructor(
    private irrigationEventsStore: Store<fromIrrigationEvents.IIrrimetEventsState>
  ) {}

  public viewDateChangePrevious(): void {
    this.irrigationEventsStore.dispatch(setViewDate(moment(this.viewDate).toDate()));
  }

  public viewDateChangeToday(): void {
    this.irrigationEventsStore.dispatch(setViewDate(new Date()));
  }

  public viewDateChangeNext(): void {
    this.irrigationEventsStore.dispatch(setViewDate(moment(this.viewDate).toDate()));
  }
}
