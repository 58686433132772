<div class="save-logging">
    <hr class="save-logging__hr">
    <div class="save-logging__content">
        <div>
        <app-custom-button
            [color]="'orange'"
            *ngIf="isAdvanced"
            [content]="'Advanced options'"
            (click)="toggleAdvancedOptions()">
        </app-custom-button>
        <app-custom-button
            [color]="isDefault ? 'green' : 'white'"
            class="save-logging__save"
            [content]="'Default values'"
            (click)="toggleDefaults()">
            <i class="fa fa-fw" [class.fa-check]="isDefault"></i>
        </app-custom-button>
        <app-custom-button
            [color]="'red'"
            class="save-logging__save"
            [content]="'Save configuration'"
            [disabled]="disabled"
            (click)="saveConfiguration()">
        </app-custom-button>
        </div>
    </div>
</div>
