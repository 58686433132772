<div class="left-menu">
  <app-selected-station></app-selected-station>
  <app-hydroponics-tree (toggleSensorEmitter)="onToggleSensor($event)"><!--[tree]="tree"-->
  </app-hydroponics-tree>
</div>

<div class="hydroponics">
  <app-station-page-header [pageName]="'Hydroponics'"></app-station-page-header>
  <app-hydroponics-content [drainageDisabled]="drainageVisible"></app-hydroponics-content>
</div>

<div class="clear-both"></div>

