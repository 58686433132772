import {IActionWithPayload} from '../models/actionWithPayload';
import {Action} from '@ngrx/store';
import {IDisease} from '../models/diseases.models';

export const enum DiseasesActionTypes {
  GET_DISEASES = '[Account] GET_DISEASES',
  SET_DISEASES = '[Account] SET_DISEASES'
}

export function getDiseases(): Action {
  return { type: DiseasesActionTypes.GET_DISEASES };
}

export function setDiseases(data: Array<IDisease>): IActionWithPayload {
  return { type: DiseasesActionTypes.SET_DISEASES, payload: data };
}
