<div class="sensor-node-modal">
  <p>
    {{ 'In this section you can define custom name for your station and all nodes connected to it. Moreover, you can ' +
    'also define custom name and color for every sensor connected to your station. For every sensor you can also select ' +
    'unit in which the value of the sensor should be presented in charts and tables.' | translate }}
  </p>
  <p>
    {{ 'After defining custom units, names and colors, you need to save your settings. You can do this by clicking on ' +
    '“Update” button for every single sensor or on the “Save all” button at the end of sensor list to save all at once.'
    | translate }}
  </p>
  <p>
    {{ 'The button “Reset Sensors” enables you to reset all sensor units to their default values (Important note: When ' +
    'you change unit settings (Metric/Imperial) in the User menu, the change only affects the default sensor unit ' +
    'settings and not customized unit settings).' | translate }}
  </p>
  <p>
    {{ 'For some soil moisture profile probes additional column CALIBRATION is available, where you can select different soil ' +
    'texture calibration equations to be applied to your soil moisture data. The texture calibration options available are ' +
    'the official ones provided by the OEM probe manufacturers.' | translate }}
  </p>
</div>
