<app-form-layout>
  <app-card-header (openModal)="openModal('upper')"
                   [header]="('WeatherLink Station Map' | translate) + ' - ' + stationName"
                   description="Ingest your WeatherLink sensors to your FieldClimate account"
                   helpButton="true">
  </app-card-header>

  <div class="sensor-map">
    <table>
      <thead>
      <tr>
        <th>{{ 'Sensor' | translate }}</th>
        <th>{{ 'Data' | translate }}</th>
        <th>{{ 'Original Name' | translate }}</th>
        <th>{{ 'Custom Name' | translate }}</th>
        <th>{{ 'Active' | translate }}</th>
      </tr>
      </thead>
      <tbody *ngIf="sensorMap && sensorMap.length">
      <tr *ngFor="let sensor of sensorMap">
        <td>
          <span>{{ sensor.productName }} ({{ sensor.sensorType }})</span>
        </td>
        <td>
          <span>{{ sensor.channel !== -1 ? sensor.dataFieldName : ('Not supported sensor' | translate) }}</span>
        </td>
        <td>{{ sensor.originalName }}</td>
        <td>
          <app-form-input #customName="ngModel"
                          [(ngModel)]="sensor.customName"
                          [hidden]="sensor.channel === -1 || !sensor.originalName"
                          [placeholder]="'Custom name' | translate"
                          [styles]="{ 'background-color': '#fffffff', 'border-color': customName.invalid ? 'red' : '' }"
                          [tooltipDisable]="customName.valid"
                          [tooltipTitle]="'Unsupported input data format!' | translate"
                          appTooltip
                          appWarningSensor
                          class="server-alerts-td__control"
                          contentType="text"
                          size="small"
                          tooltipColor="red"
                          tooltipPlacement="top"
                          tooltipWidth="200px"
                          type="border">
          </app-form-input>
        </td>
        <td>
          <app-yes-no-toggle (change)="sensor.syncThisData = $event"
                             [hidden]="sensor.channel === -1"
                             [ngModel]="sensor.syncThisData"
                             noTab="true">
          </app-yes-no-toggle>
        </td>
      </tr>
      </tbody>
    </table>
    <h4 *ngIf="!sensorMap || !sensorMap.length">{{ 'No data for selected station' | translate }}</h4>
    <div class="row">
      <div class="col-md-12 button-container" style="margin-bottom: 15px; margin-top: 20px">
        <div class="col-md-offset-10 col-md-1 save-sensor-map__cancel">
          <app-custom-button (click)="cancelEditing()"
                             color="red"
                             content="Cancel">
          </app-custom-button>
        </div>
        <div class="col-md-1 save-sensor-map__submit">
          <app-custom-button (click)="saveSensorMap()"
                             color="orange"
                             content="Save">
          </app-custom-button>
        </div>
      </div>
    </div>
  </div>
</app-form-layout>

<app-info-modal [modalId]="modalId" header="WeatherLink integration">
  <app-davis-sensor-modal [dialog]="dialog"></app-davis-sensor-modal>
</app-info-modal>
