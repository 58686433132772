import {ICam, IFrostMonitoring, IStation} from '../../../core/models/stations';

export interface ILoggingSave {
  station: IStation;
  saveObj: ILoggingSaveObj;
}

export interface ILoggingSaveObj {
  'config.upload.scheduler': string;
  'config.interval.logging'?: number;
  'config.interval.measurement'?: number;
  'config.interval.transfer'?: number;
  'config.activity_mode'?: number;
  'config.monitor.rain'?: number;
  'config.frost_monitoring.enabled'?: boolean;
  'config.frost_monitoring.threshold'?: number;
  'config.frost_monitoring.sensor_code'?: number;
  'config.frost_monitoring.sensor_channel'?: number;
  'config.frost_monitoring.interval'?: number;
  'config.upload.transfer_fixed'?: number;
  'config.upload.network_registration_timeout'?: number;
}

export interface IAdvancedSettings {
  rain_monitor: number;
  frost_monitoring_enabled?: boolean;
  frost_monitoring_threshold?: number;
  frost_monitoring_sensor_code?: number;
  frost_monitoring_sensor_channel?: number;
  frost_monitoring_interval?: number;
  activity_mode: number;
  /* fixed_transfer_interval: number; */
  measuring_interval?: number;
  transfer_interval?: number;
  network_registration_timeout?: number;
}

export interface ISensorGroups {
  groups: number[];
}

export interface IEmergencySave {
  'config.emergency_sms_number': string;
}

export interface IEmergencySaveAction {
  emergency: IEmergencySave;
  station: IStation;
}

export interface ICamSettings {
  img: string;
  settings: ICam;
  spots: string[];
}

export interface ISaveCamsPayload {
  cams: ISaveCams;
  station: IStation;
}

export interface ISetCameraImagePayload {
  camKey: string;
  img: string;
}

export interface IGetCameraImagePayload {
  camKey: string;
  camId: string;
  stationId: string;
}

export interface ISetSpotsPayload {
  camKey: string;
  spots: string[];
}

export interface ISetOneCamPayload {
  camKey: string;
  settings: ICam;
}

export interface ISaveCams {
  [x: string]: number;
}

export interface ILocationExtremes {
  longitudeMax: number;
  longitudeMin: number;
  latitudeMax: number;
  latitudeMin: number;
}

export interface ILoggingAndTransferConfig {
  logging_interval: number;
  fixed_transfer_interval: number;
  transfer_interval?: number;
  measuring_interval?: number;
  network_registration_timeout?: number;
  activity_mode: number;
  rain_monitor: number;
  frost_monitoring?: IFrostMonitoring;
  frost_monitoring_enabled?: boolean;
  frost_monitoring_threshold?: number;
  frost_monitoring_sensor_code?: number;
  frost_monitoring_sensor_channel?: number;
  frost_monitoring_interval?: number;
  scheduler: string[][];
  led?: number;
}

export interface ILEDTrapSave {
  station: IStation;
  saveObj: ILEDTrapSaveObj;
}

export interface ILoggingTransferDevice {
  measuring: boolean;
  logging: boolean;
  fixedTransfer: boolean;
  scheduler: boolean;
  network: boolean;
  led: boolean;
  rain: boolean;
  frost: boolean;
  allowDisabledTransfer?: boolean;
  deviceIDs: Array<number>;
  defaults: Array<any>;
  options: Array<any>;
  stationActivity: boolean;
  frostDefaults?: Array<string>;
}

export interface ILEDTrapSaveObj {
  'config.led_trap.scheduler': string;
  'config.led_trap.led_1_time_on'?: number;
  'config.led_trap.led_1_time_off'?: number;
  'config.led_trap.led_2_time_on'?: number;
  'config.led_trap.led_2_time_off'?: number;
  'config.led_trap.temperature_threshold'?: number;
}

export function parseScheduler(scheduler: number): string[][] {
  const prepareScheduler: string[] = scheduler.toString(2).split('').reverse();
  if (prepareScheduler.length < 24) {
    const count: number = 24 - prepareScheduler.length;
    for (let i = 0; i < count; i++) {
      prepareScheduler.push('0');
    }
  }

  return prepareScheduler.reduce((previous: string[][], value: string, i: number) => {
    if (i % 4 === 0) {
      previous.push([]);
    }
    previous[previous.length - 1].push(value);
    return previous;
  }, []);
}

export function parseSchedulerString(config: any): string {
  const scheduler: string[] = config.scheduler.reduce(
    (p, a) => p.concat(a), []
  );

  if (config.logging_interval === 120) {
    for (let i = 1; i < scheduler.length; i += 2) {
      scheduler[i] = '0';
    }
  }

  return scheduler.join('');
}

export function parseSchedulerBinary(scheduler: number): string {
  return parseScheduler(scheduler)
    .reduce((p, a) => p.concat(a), [])
    .join('');
}
