import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IWorkPlanningState } from '../../models/work-planning.models';
import { selectWarningMessage } from '../../reducers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-work-planning-warnings',
  templateUrl: './work-planning-warnings.component.html',
  styleUrls: ['./work-planning-warnings.component.scss']
})
export class WorkPlanningWarningsComponent implements OnInit, OnDestroy {
  public warningMessage: string|null = null;
  public icon: boolean = false;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private store           : Store<IWorkPlanningState>,
  ) { }

  public ngOnInit(): void {
    this.store.pipe(
      select(selectWarningMessage),
      takeUntil(this.destroy$)
    ).subscribe((warning: any) => {
      this.warningMessage = warning.message;
      this.icon = warning.icon;
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
