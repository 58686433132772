import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {ApiCallService} from '../../../services/api/api-call.service';
import {catchError, switchMap} from 'rxjs/operators';
import {from} from 'rxjs';
import {setStationEvents, setStationEventsError, setStationEventsLoading, StationEventsActionTypes} from '../actions/station-events';
import {Observable} from 'rxjs';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {ISetStationEventsPayload, IStationEvent} from '../models/station-events.models';

@Injectable()
export class StationEventsService {

  constructor(private api: ApiCallService, private actions$: Actions) { }

  @Effect()
  public getStationEvents$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(StationEventsActionTypes.GET_STATION_EVENTS),
    switchMap((action: IActionWithPayload) => {
      setStationEventsError(false);
      setStationEventsLoading(true);
      return this.api.getStationEvents(action.payload).pipe(
        switchMap((eventsResponse: Array<IStationEvent>) => {
          const setStationEventsPayload: ISetStationEventsPayload = {
            stationId: action.payload.stationId,
            stationEvents: eventsResponse
          };
          return from ([
            setStationEvents(setStationEventsPayload),
            setStationEventsLoading(false),
            setStationEventsError(!eventsResponse.length)
          ]);
        }),
        catchError(() => from([
          setStationEventsError(true),
          setStationEventsLoading(false)
        ]))
      );
    }),
  );
}
