import { ICropZone } from './../../../core/models/cropzones';
import { IOptionsForDatasources } from './../../irrimet-config/actions/irrimet-config.action';
import { IActionWithPayload } from './../../../core/models/actionWithPayload';

export const enum SoilMoistureStationsActionTypes {
    GET_SOIL_MOISTURE_STATIONS = 'GET_SOIL_MOISTURE_STATIONS',
    SET_SOIL_MOISTURE_STATIONS = 'SET_SOIL_MOISTURE_STATIONS',
    UPDATE_CROPZONE = 'UPDATE_CROPZONE',
    UPDATE_CROPZONE_AND_SENSORS = 'UPDATE_CROPZONE_AND_SENSORS'
}

export function getSoilMoistureStations(payload: IOptionsForDatasources): IActionWithPayload {
    return { type: SoilMoistureStationsActionTypes.GET_SOIL_MOISTURE_STATIONS, payload: payload };
}

export function setSoilMoistureStations(soilMoistureStations: {}[]): IActionWithPayload {
    return { type: SoilMoistureStationsActionTypes.SET_SOIL_MOISTURE_STATIONS, payload: soilMoistureStations };
}

export function updateCropzone(payload: ICropZone): IActionWithPayload {
    return { type: SoilMoistureStationsActionTypes.UPDATE_CROPZONE, payload };
}

export function updateCropzoneAndSensors(payload: {cropzone: ICropZone, array: Array<Array<any>>, smStationId: string, node: string}) : IActionWithPayload {
    return { type: SoilMoistureStationsActionTypes.UPDATE_CROPZONE_AND_SENSORS, payload: payload };
}
