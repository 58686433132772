import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IPhone } from '../../../../../../core/models/stations';
import { ModalService } from '../../../../../../shared/modal/services/modal.service';
import { generateId } from '../../../../../dashboard/utils/makeWidget';
import { removeWarningsPhone, updateWarningsPhone } from '../../../../actions/warningsPhones';
import * as fromWarningsPhones from '../../../../reducers/warningsPhones';

@Component({
  selector: '[app-warnings-phone-list-row]',
  templateUrl: './warnings-phone-list-row.component.html',
  styleUrls: ['./warnings-phone-list-row.component.scss']
})
export class WarningsPhoneListRowComponent implements OnInit, OnDestroy {
  @Input()
  public phone        : IPhone;

  public modalId      : string = generateId();
  public form         : FormGroup;

  private destroy$    : Subject<boolean> = new Subject<boolean>();

  constructor(private fb: FormBuilder,
              private modalService: ModalService,
              private warningsPhonesStore: Store<fromWarningsPhones.IWarningsPhonesState>) { }

  public ngOnInit(): void {
    this.form = this.fb.group({
      'num': [this.phone.num, []],
      'name': [this.phone.name, []],
      'active': [this.phone.active, []]
    });

    this.form.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(100),
      distinctUntilChanged()
    ).subscribe((form: any) => {
      this.warningsPhonesStore.dispatch(updateWarningsPhone(form));
    });
  }

  public get active(): AbstractControl {
    return this.form.get('active');
  }

  public remove(): void {
    this.closeModal();
    this.warningsPhonesStore.dispatch(removeWarningsPhone(this.phone.num));
  }

  public closeModal(): void {
    this.modalService.closeModal(this.modalId);
  }

  public openModal(): void {
    this.modalService.openModal(this.modalId);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
