import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import * as fromStations from '../../core/reducers/stations';
import { getUserAlerts } from './actions/alerts';
import * as fromAlerts from './reducers/alerts';

@Component({
  selector: 'app-notifications-configuration',
  templateUrl: './notifications-configuration.component.html'
})

export class NotificationsConfigurationComponent implements OnInit, OnDestroy {
  private destroy$: Subject<null> = new Subject();

  constructor(
    private stationStore: Store<fromStations.IStations>,
    private alertsStore: Store<fromAlerts.IAlertsState>
  ) { }

  public ngOnInit(): void {
    this.alertsStore.dispatch(getUserAlerts());
  }

  public ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
  }
}
