<app-work-planning-warnings></app-work-planning-warnings>
<app-work-planning-header [header]="header"></app-work-planning-header>

<div class="work-planning-content__item">
  <app-work-planning-toolbar  (exportChartImg)="exportCharts($event)"></app-work-planning-toolbar>
</div>

<app-form-layout [shadow]="false" *ngIf="validLicense">
  <div class="work-planning-content__item">
    <app-work-planning-charts
      [exportChartImg]="exportChartImg"
      [isLoading]="isLoading$ | async"
      [isError]="isError$ | async"
      [chartsAndLegends$]="chartsAndLegends$">
    </app-work-planning-charts>
  </div>
</app-form-layout>

<app-work-planning-table *ngIf="validLicense && !(isLoading$ | async)"></app-work-planning-table>
