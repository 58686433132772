import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerModule } from 'ngx-color-picker';

import { StationModelsRoutingModule } from './station-models-routing.module';
import { StationModelsComponent } from './station-models.component';
import { SharedModule } from '../../shared/shared.module';
import { LoadingComponent } from './components/loading/loading.component';
import { ModelRowComponent } from './components/model-row/model-row.component';

@NgModule({
  imports: [
    CommonModule,
    StationModelsRoutingModule,
    SharedModule,
    TranslateModule.forChild(),
    ColorPickerModule
  ],
  declarations: [StationModelsComponent, LoadingComponent, ModelRowComponent]
})
export class StationModelsModule { }
