<app-form-layout class="sensor-node">
  <app-card-header [helpButton]="true"
                   (openModal)="openModal(true)"></app-card-header>
  <div class="sensor-node__content table-responsive">
    <table class="sensor-node__table">
      <thead app-sensor-head
             class="sensor-node__table__head"
             [nodeKey]="nodeKey"
             [stationId]="stationId"
             [node]="node"
             [nodeHasCalibration]="nodeHasCalibration()"
             (showInactiveSensors)="onShowInactiveSensors($event)"></thead>
      <tbody class="sensor-node__table__body">
      <ng-container *ngFor="let sensorKey of getSensorsKeys()">
        <tr *ngIf="node.sensors[sensorKey].isActive ||
                  (!node.sensors[sensorKey].isActive && showInactiveSensors)"
            app-sensor-row
            [stationId]="stationId"
            [sensorName]="sensorKey"
            [nodeKey]="nodeKey"
            [sensor]="node.sensors[sensorKey]"
            [nodeHasCalibration]="nodeHasCalibration()"
            [calibrationOptions]="calibrationOptions(sensorKey)">
        </tr>
      </ng-container>
      </tbody>
    </table>
    <app-sensor-save (resetUnits)="openModal()"
                     (saveAllSensors)="saveAllSensors()"></app-sensor-save>
  </div>
</app-form-layout>

<app-info-modal [modalId]="modalId"
                [headSize]="17"
                [hideFooter]="true"
                [header]="'Reset sensors and nodes' | translate"
                [styles]="{ 'max-width': '300px'}">
  <app-sensor-confirm-modal (close)="closeModal()"
                            (reset)="resetUnits()"></app-sensor-confirm-modal>
</app-info-modal>

<app-info-modal [header]="'Sensors and nodes' | translate"
                [modalId]="infoModalId">
  <app-sensor-node-modal></app-sensor-node-modal>
</app-info-modal>
