import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { deepClone } from '../../../../shared/utils/deepClone';
import { saveNote, setNote } from '../../actions/note';
import { selectNote } from '../../reducers';
import * as fromNote from '../../reducers/note';

@Component({
  selector: 'app-station-notes',
  templateUrl: './station-notes.component.html'
})

export class StationNotesComponent implements OnInit, OnChanges {
  @Input()
  public isReadOnly                     : boolean;
  @Input()
  private station                       : IStation;

  public noteValue                      : string = '';

  constructor(private noteStore: Store<fromNote.INoteState>) { }

  public ngOnInit(): void {
    this.noteStore.pipe(
      distinctUntilChanged(),
      select(selectNote)
    ).subscribe(response => {
      this.noteValue = response;
      if (!response) {
        this.noteValue = this.station.note || '';
        this.noteStore.dispatch(setNote(this.station.note || ''));
      }
    });
  }

  public saveNote(note: string): void {
    const s: IStation = deepClone(this.station);
    s.note = note;
    this.noteStore.dispatch(saveNote(s));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.station) {
      this.noteStore.dispatch(setNote(this.station.note || ''));
    }
  }

}
