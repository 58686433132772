<form class="account-settings-form"
      [formGroup]="form"
       #accountform ="ngForm"
      [attr.autocomplete]="'new-password'"
      (ngSubmit)="submit()">

  <div class="account-settings-form__user">
    <app-form-input [icon]="'account'"
                    [isDisabled]="true"
                    [formControlName]="'user'"></app-form-input>
  </div>

  <div class="account-settings-form__password">
    <app-form-input class="account-settings-form__password__fake"
                    [contentType]="'password'"></app-form-input>
    <app-form-input [icon]="'password'"
                    [contentType]="'password'"
                    [placeholder]="'Password (min 5 characters)'"
                    [formControlName]="'password'"></app-form-input>
    <div class="account-settings-form__error" *ngIf="password.hasError('minlength') && !password.pristine">
      <p>{{ 'Password must be at least 5 characters long' | translate }}</p>
    </div>
    <div class="account-settings-form__error" *ngIf="password.hasError('required') && accountform.submitted">
      <p>{{ 'You have to enter password' | translate }}</p>
    </div>
  </div>

  <div class="account-settings-form__password">
    <app-form-input [icon]="'password'"
                    [contentType]="'password'"
                    [placeholder]="'Confirm password (min 5 characters)'"
                    [formControlName]="'password2'"></app-form-input>
    <div class="account-settings-form__error" *ngIf="password2.hasError('minlength') && !password2.pristine">
      <p>{{ 'Password must be at least 5 characters long' | translate }}</p>
    </div>
    <div class="account-settings-form__error" *ngIf="password2.hasError('required') && accountform.submitted">
      <p>{{ 'You have to enter password' | translate }}</p>
    </div>
    <div class="account-settings-form__error" *ngIf="password.value !== password2.value && !password.pristine">
      <p>{{ 'Password mismatch' | translate }}</p>
    </div>
  </div>

  <hr>

  <div class="account-settings-form__submit">
    <app-custom-button [content]="'Update Password'"
                       [color]="'red'"></app-custom-button>
  </div>

</form>
