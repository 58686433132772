<section>
  <app-form-layout>
    <app-iscout-header
      *ngIf="isCreationForm()"
      [title]="'Add a new Season'"
      [subtitle]="'Create a season to manage glue boards on iScout'"
    ></app-iscout-header>

    <app-iscout-header
      *ngIf="!isCreationForm()"
      [title]="'Update the Season'"
      [subtitle]="'Update an existent iScout Season'"
    ></app-iscout-header>

    <form class="iscout-season-form" [formGroup]="form">
      <div class="row">
        <div class="col-sm-12 col-md-6 iscout-season-form__group">
          <label>{{ 'Label' | translate }}*</label>
          <app-form-input
            class="iscout-season-form__input"
            [formControl]="seasonLabel"
          ></app-form-input>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 iscout-season-form__group">
          <table class="table table-responsive table-striped">
            <thead>
            <tr>
              <th class="text-center" colspan="3">
                {{ 'Available Glue Boards' | translate }}
              </th>
            </tr>
            <tr *ngIf="availableGlueBoards.length > 0">
              <th>{{ 'From' | translate }}</th>
              <th>{{ 'To' | translate }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let glueBoard of availableGlueBoards">
              <td>{{ renderDateTime(glueBoard.from) }}</td>
              <td>{{ renderDateTime(glueBoard.to) }}</td>
              <td style="text-align: right">
                <app-custom-button
                  [color]="'green'"
                  (click)="handleAddGlueBoardToSeason(glueBoard)"
                >
                  <i class="fa fa-plus-circle"></i>
                </app-custom-button>
              </td>
            </tr>
            <tr *ngIf="availableGlueBoards.length === 0">
              <td>{{ 'There is no available glue board to add' | translate }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row" *ngIf="seasonDetections.value.length !== 0">
        <div class="col-sm-12 iscout-season-form__group">
          <table class="table table-responsive table-striped">
            <thead>
            <tr>
              <th class="text-center" colspan="3">{{ 'Season Glue Boards' | translate }}</th>
            </tr>
            <tr>
              <th>{{ 'From' | translate }}</th>
              <th>{{ 'To' | translate }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let glueBoard of seasonDetections.value">
              <td>{{ renderDateTime(glueBoard.from) }}</td>
              <td>{{ renderDateTime(glueBoard.to) }}</td>
              <td style="text-align: right">
                <app-custom-button
                  [color]="'red'"
                  (click)="handleRemoveGlueBoardFromSeason(glueBoard)"
                >
                  <i class="fa fa-trash"></i>
                </app-custom-button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="iscout-season-form__submit">
        <app-custom-button
          [disabled]="!form.valid"
          [color]="'red'"
          [content]="'Save'"
          (click)="submitSeason()">
        </app-custom-button>

        <app-custom-button
          [content]="'Back'"
          (click)="unselectSeason()">
        </app-custom-button>
      </div>
    </form>
  </app-form-layout>
</section>
