import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-accumulator-tool-help-modal',
  templateUrl: './accumulator-tool-help-modal.component.html',
  styleUrls: ['./accumulator-tool-help-modal.component.css']
})
export class AccumulatorToolHelpModalComponent implements OnInit {

  @Input()
  public help: string;

  constructor() { }

  public ngOnInit(): void {
  }
}
