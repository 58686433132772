<app-form-layout [shadow]="false"
                  class="station-disease-header">
  <div class="station-disease-header__info">
    <div>
      <h2>{{ diseaseGroupName | translate }}</h2>
      <small>{{ diseaseName | translate }}</small>
    </div>
    <div *ngIf="help">
      <i class="zmdi zmdi-help station-disease-header__help" (click)="openModal()"></i>
    </div>
  </div>
</app-form-layout>

<app-info-modal [modalId]="modalId" [header]="diseaseName | translate">

  <app-station-disease-help-modal [help]="help">
  </app-station-disease-help-modal>

</app-info-modal>
