import { IChartColors, IChartSeries, IFrostProtectionValues, ITableStyle } from '../models/models';
import { Options } from 'highcharts';
import { GridOptions } from 'ag-grid';

export const TRANSLATED_VALUES: IFrostProtectionValues = {
  extremeWeatherMonitoring    : 'Extreme Temperature Monitoring',
  temperature                 : 'Temperature',
  date                        : 'Date',
  hcAirTemperature            : 'HC Air temperature',
  wetbulbTemperature          : 'Wetbulb temperature',
  drybulbTemperature          : 'Drybulb temperature',
  avg                         : 'avg',
  min                         : 'min',
  max                         : 'max',
  warningSms                  : 'Warning SMS at',
  notification                : 'Notification at',
  forecast                    : 'forecast',
  measurement                 : 'measurement',
  pictocode                   : 'Pictocode'
};

export const CHART_COLORS: IChartColors = {
  plotline: 'rgba(0, 0, 0, 0.4)',
  lastMeasurement: 'rgba(0, 120, 91, 255)',
  thresholdAbove: 'rgb(178, 222, 39)',
  thresholdBelow: 'rgb(12, 138, 33)',
  smsWarningAbove: 'rgb(72, 209, 204)',
  smsWarningBelow: 'rgb(32, 104, 145)'
};

export const CHART_ATTRIBUTES: IChartSeries = {
  group1: {                                             // air temperature
    measurementColor: 'rgb(255, 0, 0)',
    forecastColor: 'rgba(255, 0, 0, 0.3)',
    type: 'line',
    units: {
      metric: '°C',
      imperial: '°F'
    },
    name: 'hcAirTemperature'
  },
  group8: {                                             // wet temperature
    measurementColor: 'rgb(128, 0, 128)',
    forecastColor: 'rgba(128, 0, 128, 0.3)',
    type: 'line',
    units: {
      metric: '°C',
      imperial: '°F'
    },
    name: 'wetbulbTemperature'
  },
  group9: {                                             // dry temperature
    measurementColor: 'rgb(255 ,215, 0)',
    forecastColor: 'rgba(255, 215, 0, 0.3)',
    type: 'line',
    units: {
      metric: '°C',
      imperial: '°F'
    },
    name: 'drybulbTemperature'
  }
};

export const DEFAULT_CHART_OPTIONS: Options = {
  title: {
    text: null
  },
  chart: {
    height: 550,
    marginLeft: 85,
    marginRight: 100,
    marginTop: 20,
    style: { fontFamily: 'Helvetica' },
    zoomType: 'x',
    alignTicks: false
  },
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  legend: {
    useHTML: true,
    labelFormatter: function (): string {
      return this.name;
    }
  },
  plotOptions: {
    series: {
      states: {
        hover: {
          enabled: true,
          lineWidth: 3
        }
      },
      pointPlacement: 'on',
      marker: {
        enabled: false
      }
    },
    arearange: {
      fillOpacity: 0.25,
      showInLegend: false,
      marker: {
        enabled: false
      }
    }
  },
};

export const FrostProtectionGridOptions: GridOptions = {
  columnDefs: [],
  rowData: [],
  enableColResize: true,
  enableSorting: true,
  rowHeight: 30,
  suppressDragLeaveHidesColumns: true,
  headerHeight: 28,
  rowSelection: 'single',
  suppressContextMenu: true,
  suppressCellSelection: true,
};

export const TABLE_COLORS: ITableStyle = {
  whiteRow:  '#ffffff',
  forecastRow: '#f6f6f6',
  measurementRow: '#E8E8E8',
  selectedRow: '#bde2e5',
  line: '3px solid black',
};

export const GROUP_VALUES = {
  measurement: ['avg', 'min', 'max'],
  forecast: ['forecast', 'pictocode']
};

export const DefaultColumnSorting = [{
  colId: 'date',
  sort: 'asc'
}];
