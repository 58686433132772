import { cropzoneSamplesReducer } from './cropzone-samples.reducer';
import { cropzoneSettingsReducer } from './cropzone-settings.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICropzoneMissionState, ICropzoneSettingsState } from '../models/model';

export const featureKey = 'cropzone-sample';

export interface ICropzoneSampleState {
  sampleMissions: ICropzoneMissionState;
  sampleSettings: ICropzoneSettingsState;
}

export const reducers = {
  sampleMissions: cropzoneSamplesReducer,
  sampleSettings: cropzoneSettingsReducer
};

export const cropzoneSample = createFeatureSelector<ICropzoneSampleState>(featureKey);


export const selectSamples = createSelector(
  cropzoneSample,
  (state: ICropzoneSampleState) => state.sampleMissions
);

export const selectSoilGuard = createSelector(
  cropzoneSample,
  (state: ICropzoneSampleState) => state.sampleMissions.soilGuard
);

export const selectDualex = createSelector(
  cropzoneSample,
  (state: ICropzoneSampleState) => state.sampleMissions.dualex
);

export const selectSettings = createSelector(
  cropzoneSample,
  (state: ICropzoneSampleState) => state.sampleSettings.table
);

export const selectDates = createSelector(
  cropzoneSample,
  (state: ICropzoneSampleState) => state.sampleSettings.table.dates
);

export const selectTableActive = createSelector(
  cropzoneSample,
  (state: ICropzoneSampleState) => state.sampleSettings.table.isTableActive
);

export const selectLastMode = createSelector(
  cropzoneSample,
  (state: ICropzoneSampleState) => state.sampleSettings.table.isLastMode
);

export const selectDeviceType = createSelector(
  cropzoneSample,
  (state: ICropzoneSampleState) => state.sampleSettings.table.deviceType
);

export const selectActiveMeter = createSelector(
  cropzoneSample,
  (state: ICropzoneSampleState) => state.sampleSettings.table.activeMeter
);

export const selectActiveTitle = createSelector(
  cropzoneSample,
  (state: ICropzoneSampleState) => state.sampleSettings.table.activeTitle
);

export const selectActiveDevice = createSelector(
  cropzoneSample,
  (state: ICropzoneSampleState) => state.sampleSettings.activeDevice
);

export const selectActiveSample = createSelector(
  cropzoneSample,
  (state: ICropzoneSampleState) => state.sampleSettings.activeSample
);

export const selectMobilab = createSelector(
  cropzoneSample,
  (state: ICropzoneSampleState) => state.sampleMissions.mobilab
);

export const selectActiveDeviceType = createSelector(
  cropzoneSample,
  (state: ICropzoneSampleState) => state.sampleSettings.table.deviceType
);
