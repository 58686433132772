import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {ICameraSlideshowState} from '../models/camera-slideshow';
import {CameraSlideshowActions} from '../actions/camera-slideshow';

export const initialState: ICameraSlideshowState = {
  lastTimeDate: null,
  pictures: [],
  isLoading: true,
  isError: false,
  settings: {
    isFullscreen: false,
    fromLastData: null,
    toLastData: null,
    isLastDataMode: false,
    fromDatepicker: null,
    toDatepicker: null,
    isPlaying: false,
    playSpeed: 2,
    activeSlide: null,
    cameraSelected: 1
  }
};

export function reducer(state: ICameraSlideshowState = initialState, action: IActionWithPayload): ICameraSlideshowState {
  switch (action.type) {
    case CameraSlideshowActions.SET_CAMERA_SLIDESHOW_PHOTOS:
      return <ICameraSlideshowState> {
        ...state,
        pictures: action.payload
      };
    case CameraSlideshowActions.SET_CAMERA_SLIDESHOW_LOADING:
      return <ICameraSlideshowState> {
        ...state,
        isLoading: action.payload
      };
    case CameraSlideshowActions.SET_CAMERA_SLIDESHOW_ERROR:
      return <ICameraSlideshowState> {
        ...state,
        isError: action.payload
      };
    case CameraSlideshowActions.SET_CAMERA_SLIDESHOW_LAST_DATE:
      return <ICameraSlideshowState>{
        ...state,
        lastTimeDate: action.payload
      };
    case CameraSlideshowActions.SET_CAMERA_SLIDESHOW_SETTINGS:
      return <ICameraSlideshowState>{
        ...state,
        settings: action.payload
      };
    default:
      return state;
  }
}
