import { Chart } from 'angular-highcharts';
import { AxisOptions } from 'highcharts';

export default function (charts: Chart[]): Chart[] {
    return (function (cs: Chart[]): Chart[] {
        return cs.map(chart => {
            (<AxisOptions>chart.options.xAxis).events = {
                afterSetExtremes: (evt): void => {
                    cs.forEach((c: Chart) => {
                        if (chart !== c) {
                            const { min, max } = c.ref.xAxis[0].getExtremes();
                            if (Number.isFinite(min) &&
                                Number.isFinite(max) &&
                                Number.isFinite(evt.min) &&
                                Number.isFinite(evt.max) &&
                                min !== evt.min && max !== evt.max) {
                                c.ref.xAxis[0].setExtremes(evt.min, evt.max);
                            }
                        }
                    });
                }
            };
            return chart;
        });
    })(charts);
}

