import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-navigate-button',
  templateUrl: './navigate-button.component.html',
  styleUrls: ['./navigate-button.component.css', '../../auth.css']
})
export class NavigateButtonComponent {
  @Input()
  public icon                     : string;
  @Input()
  public content                  : string;
  @Input()
  public url                      : string;
  @Output()
  private navigate                : EventEmitter<void> = new EventEmitter<void>();

  public navigateEmit(): void {
    this.navigate.emit();
  }

}
