import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IEmergencySaveAction} from '../models/station-config.models';

export const enum EmergencyActionTypes {
  SET_EMERGENCY = '[Config] SET_EMERGENCY',
  SAVE_EMERGENCY = '[Config] SAVE_EMERGENCY',
}

export function setEmergency(emergency: string): IActionWithPayload {
  return { type: EmergencyActionTypes.SET_EMERGENCY, payload: emergency };
}

export function saveEmergency(emergencySave: IEmergencySaveAction): IActionWithPayload {
  return { type: EmergencyActionTypes.SAVE_EMERGENCY, payload: emergencySave };
}
