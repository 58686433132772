import { Component, Input } from '@angular/core';
import { isArray } from 'rxjs/internal/util/isArray';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { ICropAndScoutData } from '../../models/dashboard.models';
import { generateId } from '../../utils/makeWidget';

@Component({
  selector: 'app-dashboard-images',
  templateUrl: './dashboard-images.component.html',
  styleUrls: ['./dashboard-images.component.scss']
})
export class DashboardImagesComponent {
  @Input()
  public data           : Array<ICropAndScoutData>;

  public modalId        : string = generateId();
  private _selectedImage: any;

  constructor(private modalService: ModalService) {}

  public get IsImagesAvailability(): boolean {
    return (isArray(this.data) && !!this.data.length) || false;
  }

  public set selectedImage(img: any) {
    this._selectedImage = img;
    this.openModal();
  }

  public get selectedImage(): any {
    return this._selectedImage;
  }

  public closeModal(): void {
    this.modalService.closeModal(this.modalId);
  }

  private openModal(): void {
    this.modalService.openModal(this.modalId);
  }
}
