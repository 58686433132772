import { IActionWithPayload } from './../../../core/models/actionWithPayload';
import { IWaterBalanceFromTo } from '../models/models';

export const enum ActionTypes {
    SET_WATER_BALANCE_PERIOD = '[Irrimet] SET_WATER_BALANCE_PERIOD',
    SET_WATER_BALANCE_IS_LAST_DATA_MODE = '[Irrimet] SET_WATER_BALANCE_IS_LAST_DATA_MODE',
    SET_WATER_BALANCE_FROM_TO = '[Irrimet] SET_WATER_BALANCE_FROM_TO',
    SET_DWB_TABLE_ACTIVE = '[Irrimet] SET_DWB_TABLE_ACTIVE'
}

export function setWaterBalancePeriod(period: number): IActionWithPayload {
    return { type: ActionTypes.SET_WATER_BALANCE_PERIOD, payload: period };
}

export function setWaterBalanceIsLastDataMode(isLastDataMode: boolean): IActionWithPayload {
    return { type: ActionTypes.SET_WATER_BALANCE_IS_LAST_DATA_MODE, payload: isLastDataMode };
}

export function setWaterBalanceFromTo(fromTo: IWaterBalanceFromTo): IActionWithPayload {
    return { type: ActionTypes.SET_WATER_BALANCE_FROM_TO, payload: fromTo };
}

export function setDwbTableActive(isActive: boolean): IActionWithPayload {
    return { type: ActionTypes.SET_DWB_TABLE_ACTIVE, payload: isActive };
}
