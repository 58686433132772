import { IDisplayRulesConfig } from '../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../environments/interfaces/theme';

export const themeConfig: IThemeConfig = {
  subDomain: 'robertaebimeteo',
  language: 'en',
  headerClass: 'white',
  src: '../../../assets/themes/robertaebimeteo/assets/images/logo.png',
  mainSrc: '../../../assets/themes/robertaebimeteo/assets/images/landing.png',
  width: '200',
  mainMTop: '9',
  mainWidth: '70',
  background: '../../../assets/themes/robertaebimeteo/assets/backgrounds/back1.png',
  name: 'RobertAebiMeteo',
  hideTerms: true,
  profileMenuSrc: '../../../assets/themes/robertaebimeteo/assets/images/profile.png',
  faviconSrc: '../../../assets/themes/robertaebimeteo/assets/favicon.ico',
  colorConfig: {
      '--search-widget-border': '#012240',
      '--search-widget-label': '#0157a7',
      '--search-widget-label-dark-green': '#0157a7',
      '--search-widget-label-orange': '#f39224',
      '--search-widget-button': '#0157a7',
      '--main-static': '#0157a7',
      '--main-static-orange': '#f39224',
      '--form-button': '#0157a7',
      '--form-button-action': '#4a4d4e',
    }
};

export const displayRulesConfig: IDisplayRulesConfig = {
  userMenu: true,
  farmViewMenu: true,
  leftBarMenu: {
    stationData: true,
    cropView: true,
    iScout: true,
    weatherForecast: true,
    workPlanning: true,
    animalProduction: true,
    accumulatorTool: true,
    frostProtection: true,
    diseaseModels: true,
    soilMoisture: true,
    hydroponics: true,
    tracker: true,
    soilGuard: true,
    settings: true,
  },
};
