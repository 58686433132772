export function deepClone<T>(objectpassed: T): T {
  if (objectpassed === null || typeof objectpassed !== 'object') {
    return objectpassed;
  }
  if (objectpassed instanceof Date) {
    return new Date((objectpassed as Date).getTime()) as any;
  }

  // give temporary-storage the original obj's constructor
  const temporaryStorage = objectpassed.constructor();

  for (const key in objectpassed) {
    if (objectpassed.hasOwnProperty(key)) {
      temporaryStorage[key] = deepClone(objectpassed[key]);
    }
  }
  return temporaryStorage;
}
