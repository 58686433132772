import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  addCropZone,
  addCropZoneField,
  addCropZoneFieldFarm,
  setExecutingAddCropzone
} from '../../../../core/actions/cropzones';
import { addFarm } from '../../../../core/actions/farms';
import { setNotify } from '../../../../core/actions/notify';
import {
  IAddCropZone,
  IAddCropZoneField,
  IAddCropZoneFieldFarm,
  IAddCropZoneRequest,
  IAddCropZoneType
} from '../../../../core/models/cropzones';
import { ISelectedLicense } from '../../../../core/models/licenses';
import { selectExecutingAddCropZone, selectFarms } from '../../../../core/reducers';
import * as fromCropzones from '../../../../core/reducers/cropzones';
import * as fromFarms from '../../../../core/reducers/farms';
import * as fromFields from '../../../../core/reducers/fields';
import * as fromNotify from '../../../../core/reducers/notify';
import { LicenseType } from '../../../product-activation/constants/constants';
import { addField } from './../../../../core/actions/fields';
import { NavigationService } from './../../../../core/services/navigation/navigation.service';

@Component({
  selector: 'app-add-cropzone',
  templateUrl: './add-cropzone.component.html',
  styleUrls: ['./add-cropzone.component.scss']
})
export class AddCropZoneManagementComponent implements OnInit, OnDestroy {
  @Output()
  public closeModalEmitter: EventEmitter<any> = new EventEmitter<any>();

  public licenseType = LicenseType.FARM_VIEW;
  public isAddCropzone: boolean = true;
  public isAddField: boolean = false;
  public isAddFarm: boolean = false;
  public isLicensing: boolean = false;
  public licensingPayload: IAddCropZoneRequest;
  public isExecuting$: Observable<boolean>;
  private destroy$ = new Subject<boolean>();

  constructor(
    private CZstore: Store<fromCropzones.ICropZones>,
    private FarmStore: Store<fromFarms.IFarms>,
    private FieldStore: Store<fromFields.IFields>,
    private navigationService: NavigationService,
    private notifyStore: Store<fromNotify.INotifyState>
  ) {}

  public addCropzone(): void {
    this.isAddCropzone = true;
    this.isAddField = false;
    this.isAddFarm = false;
    this.isLicensing = false;
  }

  public addField(): void {
    this.isAddCropzone = false;
    this.isAddField = true;
    this.isAddFarm = false;
    this.isLicensing = false;
  }

  public addFarm(): void {
    this.isAddCropzone = false;
    this.isAddField = false;
    this.isAddFarm = true;
    this.isLicensing = false;
  }

  public enableLicensing(event: any): void {
    if (event === 'closeModal') {
      this.closeModalEmitter.emit(true);
    } else {
      this.isLicensing = true;
      this.licensingPayload = event;
    }
  }

  public disableLicensing(): void {
    this.isLicensing = false;
    this.licensingPayload = null;
  }

  public submitLicensing(event: ISelectedLicense): void {
    if (this.isLicensing && event.productKey) {
      this.isLicensing = false;
      this.submitCropzone({
        ...this.licensingPayload,
        product_key: event.productKey
      });
    } else {
      this.notifyStore.dispatch(setNotify('No license was selected'));
    }
  }

  public submitCropzone(event: IAddCropZoneRequest): void {
    this.CZstore.dispatch(setExecutingAddCropzone(true));
    if (event.type === IAddCropZoneType.ADD_CROPZONE_AND_FIELD_AND_FARM) {
      this.CZstore.dispatch(addCropZoneFieldFarm(<IAddCropZoneFieldFarm>event, false));
    } else if (event.type === IAddCropZoneType.ADD_CROPZONE_AND_FIELD) {
      this.CZstore.dispatch(addCropZoneField(<IAddCropZoneField>event, false));
    } else {
      this.CZstore.dispatch(addCropZone(<IAddCropZone>event, false));
    }
  }

  public submitField(event: any): void {
    if (event !== 'closeModal') {
      this.FieldStore.dispatch(addField(event));
    }
    this.closeModalEmitter.emit(true);

  }

  public submitFarm(event: any): void {
    if (event !== 'closeModal') {
      this.FarmStore.pipe(
        select(selectFarms),
        takeUntil(this.destroy$)
      ).subscribe(farms => {

        const farm = farms.filter(data =>
          data.name.toLowerCase() === event.name.toLowerCase()
        );

        if (farm && farm.length) {
          this.notifyStore.dispatch(
            setNotify('Unable to create the farm. There is already a farm with the same name.')
          );
        } else {
          this.FarmStore.dispatch(addFarm(event));
        }

      });
    }
    this.closeModalEmitter.emit(true);
  }

  public ngOnInit(): void {
    this.isExecuting$ = this.CZstore.pipe(
      takeUntil(this.destroy$),
      select(selectExecutingAddCropZone)
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
