import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IFromTo } from '../../../../../shared/camera/models/camera';
import { ICameraDataTableHeader } from '../../../../../shared/interfaces';
import { getMonthFromDate } from '../../../../../shared/utils/dateFormat';
import { deepClone } from '../../../../../shared/utils/deepClone';
import { getCropViewFirstDate, getCropViewLastDate } from '../../../../crop-view/actions/crop-view';
import { ICropViewSettingsState, ICropViewState } from '../../../../crop-view/models/crop-view.models';
import { selectCropViewIsError, selectCropViewIsLoading, selectCropViewLastDate } from '../../../../crop-view/reducers';
import { getCameraDataFromCropView, setCameraDataCurrentDateString } from '../../../actions/camera-data';
import { baseCropViewChartOptions, buildPhotoRequest, getSelectedCameraId } from '../../../constants/camera-data';
import { ICameraDataState, ICropViewReportData } from '../../../models/camera-data';
import { selectCameraDataCropViewReport } from '../../../reducers';

@Component({
  selector: 'app-camera-data-content-cropview',
  templateUrl: './camera-data-content-cropview.component.html',
  styleUrls: ['./camera-data-content-cropview.component.scss']
})
export class CameraDataContentCropviewComponent implements OnInit, OnDestroy {
  @Input()
  public title: Observable<string>;
  @Input()
  public stationCameraType: string;
  @Input()
  public stationChangedListener$: Observable<string>;
  @Input()
  public currentDateListener$: Observable<string | IFromTo>;
  @Input()
  public isHelpActive$: Observable<boolean>;
  @Input()
  public isChartActive$: Observable<boolean>;
  @Input()
  public isTableActive$: Observable<boolean>;

  public chartOptions: any = null;
  public rowData = [];
  public additionalColumnDefs: any = [];
  public translatedHeaders: ICameraDataTableHeader;
  public isLoading$: Observable<boolean>;
  public isError$: Observable<boolean>;

  private alreadyDetectedIDs: Array<string> = [];
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private cropviewStore: Store<ICropViewState>,
              private cropviewSettingsStore: Store<ICropViewSettingsState>,
              private cameraDataStore: Store<ICameraDataState>,
              private route: ActivatedRoute) {
    this.translatedHeaders = this.route.snapshot.data['cameraDataResolver'];
  }

  public ngOnInit(): void {
    this.initDataRetrieving();
    this.initStatusListeners();
    this.initPictureListeners();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initDataRetrieving(): void {
    this.stationChangedListener$.subscribe((stationId: string): void => {
      this.cropviewStore.dispatch(getCropViewFirstDate(stationId));
      this.cropviewStore.dispatch(getCropViewLastDate(stationId));
    });

    combineLatest([
      this.stationChangedListener$,
      this.currentDateListener$
    ]).subscribe((payload: Array<string | IFromTo>): void => {
      const stationId = <string>payload[0];
      const currentDate = <string | IFromTo>payload[1];
      const params = buildPhotoRequest(stationId, currentDate, getSelectedCameraId(this.stationCameraType));
      this.cameraDataStore.dispatch(getCameraDataFromCropView(params));
    });
  }

  private initStatusListeners(): void {
    this.isLoading$ = this.cropviewStore.pipe(
      select(selectCropViewIsLoading)
    );

    this.isError$ = this.cropviewStore.pipe(
      select(selectCropViewIsError)
    );
  }

  private initPictureListeners(): void {
    this.cropviewSettingsStore.pipe(
      takeUntil(this.destroy$),
      select(selectCropViewLastDate),
      filter((date: moment.Moment): boolean => !!date),
    ).subscribe((date: moment.Moment): void => {
      this.cameraDataStore.dispatch(setCameraDataCurrentDateString(getMonthFromDate(date)));
    });

    this.cameraDataStore.pipe(
      select(selectCameraDataCropViewReport),
      takeUntil(this.destroy$)
    ).subscribe((reportData: Array<ICropViewReportData>): void => {
      this.prepareCropViewChartAndGridData(reportData);
    });
  }

  private prepareCropViewChartAndGridData(reportData: Array<ICropViewReportData>): void {
    this.rowData = [];
    this.chartOptions = deepClone(baseCropViewChartOptions);

    const rowDataColumnDefinitions = {};
    const groupedBy = reportData.reduce((result, current) => {
      const dateTime: number = moment(current.timestamp).unix() * 1000;
      this.chartOptions.series[0].data.unshift([dateTime, current.summary.average]);
      this.chartOptions.series[1].data.unshift([dateTime, current.summary.min]);
      this.chartOptions.series[2].data.unshift([dateTime, current.summary.max]);

      const measurementRows = current.measurements.reduce((rows, measurement) => {
        rowDataColumnDefinitions[measurement.rect_id] = {
          headerName: measurement.rect_id,
          children: [
            {headerName: this.translatedHeaders['diameterTitle'], field: 'diameter' + measurement.rect_id},
            {headerName: this.translatedHeaders['fsiTitle'], field: 'fsi' + measurement.rect_id},
          ],
          suppressMenu: true,
        };

        return {
          ...rows,
          ['diameter' + measurement.rect_id]: measurement.diameter,
          ['fsi' + measurement.rect_id]: measurement.fsi,
        };
      }, {});

      const timeLabel = moment(current.timestamp).format('YYYY-MM-DD HH:mm:ss');
      return {
        ...result,
        [timeLabel]: {
          ...(result[timeLabel] || {}),
          name: timeLabel,
          average: current.summary.average,
          max: current.summary.max,
          min: current.summary.min,
          ...measurementRows
        }
      };
    }, {});

    this.rowData = Object.values(groupedBy);
    this.additionalColumnDefs = Object.values(rowDataColumnDefinitions);
  }
}
