<ng-container *ngIf="status$ | async as status">

  <div class="body-view-content" [@placeContent]="state$ | async">
    <app-station-page-header [pageName]="'Soil Moisture'"></app-station-page-header>
    <div class="soil-moisture-content__header">
      <app-soil-moisture-header (openModal)="openModal()"></app-soil-moisture-header>
      <app-soil-moisture-toolbar (exportChartImg)="exportCharts($event)"></app-soil-moisture-toolbar>
    </div>
    <div class="soil-moisture-content__item">
      <app-soil-moisture-sensor-settings [stationId]="stationId"></app-soil-moisture-sensor-settings>
    </div>
    <div class="soil-moisture-content__item">
      <app-soil-moisture-view-editor></app-soil-moisture-view-editor>
    </div>
    <ng-container *ngIf="!status.isLoading && !status.isError; else loadinfo">
      <div class="soil-moisture-content__item">
        <app-soil-moisture-charts [exportChartImg]="exportChartImg"></app-soil-moisture-charts>
      </div>
      <div class="soil-moisture-content__item">
        <app-soil-moisture-table></app-soil-moisture-table>
      </div>
    </ng-container>
  </div>

  <ng-template #loadinfo>
    <app-station-load-info [isError]="status.isError" [isLoading]="status.isLoading"></app-station-load-info>
  </ng-template>

</ng-container>

<app-modal [modalId]="PLOTBAND_EDITOR_MODAL_ID"
           [outClick]="false">
  <app-soil-moisture-plotband-editor (modalClosed)="onClosedModal($event)"></app-soil-moisture-plotband-editor>
</app-modal>

<app-info-modal [modalId]="HELP_MODAL_ID" [header]="'Soil moisture monitoring - Custom View' | translate">
  <app-soil-moisture-modal></app-soil-moisture-modal>
</app-info-modal>
