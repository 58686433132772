import { ITreeSettingsState } from '../../../shared/tree/models/tree.models';
import { IView, IChart } from '../models';
import { ViewType, ViewTemplateType } from '../constants';
import { IStationSettingsPeriod, IStationSettingsActivity, ISensor } from '../../../shared/interfaces';

export interface ISettingsActivity extends IStationSettingsActivity {
    isViewEditorActive: boolean;
    isSensorSettingsActive: boolean;
}

export const initialDataState: IStateData = {
    views: [],
    chart: {
        chartsOptions: [],
        grid: {},
        topology: [],
    },
    soilMoistureSensors: []
};

export const initialSettingsState: IStateSettings = {
    period: {
        periodScope: 'hourly',
        periodValue: '30d',
        fromTo: null,
        fromDatepicker: null,
        toDatepicker: null,
        stationId: '',
        isLastDataMode: true
    },
    activity: {
        isChartActive: true,
        isTableActive: true,
        isViewEditorActive: false,
        isExportImageActive: false,
        isExportActive: false,
        isSensorSettingsActive: false
    },
    status: {
        isLoading: true,
        isError: false,
        viewIsPending: false,
        isLoadingSoilMoistureSensors: false
    }
};

export const defaultPredefinedViews: IView[] = [
    {
        _id: '', template: ViewTemplateType.SOIL_MOISTURE_ALL, name: 'All Sensors',
        type: ViewType.PREDEFINED, group: 'soilmoist', station_id: ''
    },
    {
        _id: '', template: ViewTemplateType.SOIL_MOISTURE_STACKED, name: 'Stacked',
        type: ViewType.PREDEFINED, group: 'soilmoist', station_id: ''
    }
];

export interface IState {
    data: IStateData;
    settings: IStateSettings;
    tree: ITreeSettingsState;
}

export interface IStateData {
    views: IView[];
    chart: IChart;
    soilMoistureSensors: ISensor[];
}

export interface IStateSettings {
    period: IStationSettingsPeriod;
    activity: ISettingsActivity;
    status: IStateStatus;
}

export interface IStateStatus {
    isLoading: boolean;
    isError: boolean;
    viewIsPending: boolean;
    isLoadingSoilMoistureSensors: boolean;
}
