import { IDisplayRulesConfig } from '../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../environments/interfaces/theme';

export const themeConfig: IThemeConfig = {
  subDomain: 'telus',
  language: 'en',
  headerBackground: '#4b286d',
  src: '../../../assets/themes/telus/assets/images/logo.png',
  mainSrc: '../../../assets/themes/telus/assets/images/landing.png',
  mainWidth: '240',
  mainMTop: '-2',
  customBackgroundFolder: 'telus',
  name: 'Telus',
  portalName: 'TELUS Agriculture Weather Station',
  trackingId: 'G-8912ZFN414',
  hideCropzoneRequest: true,
  hideDashboardText: true,
  hideTerms: true,
  hideUserMenu: {
    apiServices: true,
    help: true
  },
  hideCropZonesIcon: true,
  profileMenuSrc: '../../../assets/themes/default/assets/images/profile.png',
  faviconSrc: '../../../assets/themes/default/assets/favicon.ico',
  hideWarningProductActivation: true,
  hideSubscriptions: true
};

export const displayRulesConfig: IDisplayRulesConfig = {
  userMenu: true,
  farmViewMenu: true,
  leftBarMenu: {
    stationData: true,
    cropView: true,
    iScout: true,
    weatherForecast: true,
    workPlanning: true,
    animalProduction: true,
    accumulatorTool: true,
    frostProtection: true,
    diseaseModels: true,
    soilMoisture: true,
    hydroponics: true,
    tracker: true,
    soilGuard: false,
    settings: true,
  },
};
