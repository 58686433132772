import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { IStationDataState } from '../../reducers/station-data';
import { selectVWSWarning } from '../../reducers';
import { IStationDataWarningMessage } from '../../models/station-data.models';

@Component({
  selector: 'app-station-data-warning',
  templateUrl: './station-data-warning.component.html',
  styleUrls: ['./station-data-warning.component.scss']
})
export class StationDataWarningComponent implements OnInit, OnDestroy {
  public warningMessage: string|null = null;
  public icon: boolean;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private store: Store<IStationDataState>
  ) { }

  public ngOnInit(): void {
    this.store.pipe(
      select(selectVWSWarning),
      takeUntil(this.destroy$)
    ).subscribe((warning: IStationDataWarningMessage) => {
      this.warningMessage = warning.message;
      this.icon = warning.icon;
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
