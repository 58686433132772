<form [formGroup]="form"
      class="widget-settings-form"
      *ngIf="form">

  <app-custom-dropdown [formControl]="profile"
                       class="widget-settings-form__dropdown"
                       [options]="forecastProfiles">
  </app-custom-dropdown>

  <app-custom-dropdown [formControl]="station"
                       class="widget-settings-form__dropdown"
                       [options]="allStations$ | async | weatherForecast | stationsOptions">
  </app-custom-dropdown>

  <app-custom-button (click)="submit()"
                     [content]="'Refresh'"
                     [color]="'red'">
    <span><i class="fa fa-fw fa-refresh"></i></span>
  </app-custom-button>
</form>
