import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { IAccumulationCalculationValues, IAccumulatorToolHelpText } from './interfaces/interfaces';
import { HEADERS } from './constants/constants';

@Injectable()
export class AccumulatorToolResolver implements Resolve<any> {
  constructor(
    private translations: TranslateService
  ) { }

  public resolve(): Observable<any>|Promise<any>|any {
    const translatedValues: IAccumulationCalculationValues = {
      degreeHours         : 'Degree Hours',
      degreeDays          : 'Degree Days',
      degreeDaysUsa       : 'Degree Days (max+min)/2 - Base temperature',
      chill45             : 'Hours',
      chillUtah           : 'Utah Chilling Hours',
      chillUtahInfruitec  : 'Utah Positive CU',
      nHours              : 'N° Hours',
      nDays               : 'N° Days',
      nDaysUsa            : 'N° Days (max+min)/2',
      rainSum             : 'Rain Sum',
      chillingUnits       : 'Chilling Units',
      threeDaysMin16C     : '3 days with min. 16°C in 40cm depth',
      set                 : 'set',
      airSoilTemp         : 'Air & Soil Temperature',
      soilTempDeg10       : 'Degree Hours Soil 10cm',
      soilTempDeg20       : 'Degree Hours Soil 20cm',
      soilTempDeg30       : 'Degree Hours Soil 30cm',
      soilTempDeg40       : 'Degree Hours Soil 40cm',
      airTemp             : 'Air Temperature',
      soilTemp10          : 'Soil Temperature 10cm',
      soilTemp20          : 'Soil Temperature 20cm',
      soilTemp30          : 'Soil Temperature 30cm',
      soilTemp40          : 'Soil Temperature 40cm',
      startOfGrowth       : 'Start of growth'
    };

    const translatedHelpText: IAccumulatorToolHelpText = {
      degreeDaysHelp      : HEADERS['degreeDays']['help'],
      chillingUnitsHelp   : HEADERS['chillingUnits']['help'],
      rainSumHelp         : HEADERS['rainSum']['help'],
      asparagusModelHelp  : HEADERS['asparagusGrowthModel']['help']
    };

    Object.keys(translatedValues).forEach(key => {
      this.translations.get(translatedValues[key])
        .subscribe(translatedValue => {
          translatedValues[key] = translatedValue;
        });
    });

    Object.keys(translatedHelpText).forEach(key => {
      translatedHelpText[key].forEach((helpValue, i) => {
        this.translations.get(helpValue)
          .subscribe(translatedHelpValue => {
            translatedHelpText[key][i] = translatedHelpValue;
        });
      });
    });

    return Object.assign(translatedValues, translatedHelpText);
  }
}
