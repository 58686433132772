import { INode, ITree } from '../../../services/tree/models';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { IFrostProtectionRequest, IFrostProtectionSettings, IFrostProtectionVisibility } from '../models/models';
import { Options } from 'highcharts';

export const enum ActionTypes {
  SET_FROST_PROTECTION_TREE             = '[FrostProtection] SET_FROST_PROTECTION_TREE',
  SET_FROST_PROTECTION_TREE_NODES       = '[FrostProtection] SET_FROST_PROTECTION_TREE_NODES',
  SET_FROST_PROTECTION_LOADING          = '[FrostProtection] SET_FROST_PROTECTION_LOADING',
  SET_FROST_PROTECTION_ERROR            = '[FrostProtection] SET_FROST_PROTECTION_ERROR',
  SET_FROST_PROTECTION_ERROR_MESSAGE    = '[FrostProtection] SET_FROST_PROTECTION_ERROR_MESSAGE',
  SET_FROST_PROTECTION_PERIOD           = '[FrostProtection] SET_FROST_PROTECTION_PERIOD',
  SET_FROST_PROTECTION_SETTINGS         = '[FrostProtection] SET_FROST_PROTECTION_SETTINGS',
  GET_FROST_PROTECTION_RESPONSE         = '[FrostProtection] GET_FROST_PROTECTION_RESPONSE',
  SET_FROST_PROTECTION_RESPONSE         = '[FrostProtection] SET_FROST_PROTECTION_RESPONSE',
  SET_FROST_PROTECTION_CHART_ACTIVE     = '[FrostProtection] SET_FROST_PROTECTION_CHART_ACTIVE',
  SET_FROST_PROTECTION_TABLE_ACTIVE     = '[FrostProtection] SET_FROST_PROTECTION_TABLE_ACTIVE',
  SET_FROST_PROTECTION_CHARTS           = '[FrostProtection] SET_FROST_PROTECTION_CHARTS',
  SET_FROST_PROTECTION_VISIBILITY       = '[FrostProtection] SET_FROST_PROTECTION_VISIBILITY'
}

export function setFrostProtectionTree (tree: ITree): IActionWithPayload {
  return { type: ActionTypes.SET_FROST_PROTECTION_TREE, payload: tree };
}

export function setFrostProtectionTreeNodes (nodes: INode[]): IActionWithPayload {
  return { type: ActionTypes.SET_FROST_PROTECTION_TREE_NODES, payload: nodes };
}

export function setFrostProtectionLoading (isLoading: boolean): IActionWithPayload {
  return { type: ActionTypes.SET_FROST_PROTECTION_LOADING, payload: isLoading };
}

export function setFrostProtectionError (isError: boolean): IActionWithPayload {
  return { type: ActionTypes.SET_FROST_PROTECTION_ERROR, payload: isError };
}

export function setFrostProtectionErrorMessage (message: string): IActionWithPayload {
  return { type: ActionTypes.SET_FROST_PROTECTION_ERROR_MESSAGE, payload: message };
}

export function setFrostProtectionPeriod (message: string): IActionWithPayload {
  return { type: ActionTypes.SET_FROST_PROTECTION_PERIOD, payload: message };
}

export function setFrostProtectionSettings (settings: IFrostProtectionSettings): IActionWithPayload {
  return { type: ActionTypes.SET_FROST_PROTECTION_SETTINGS, payload: settings };
}

export function getFrostProtectionResponse (request: IFrostProtectionRequest): IActionWithPayload {
  return { type: ActionTypes.GET_FROST_PROTECTION_RESPONSE, payload: request };
}

export function setFrostProtectionResponse (response: any): IActionWithPayload {
  return { type: ActionTypes.SET_FROST_PROTECTION_RESPONSE, payload: response };
}

export function setFrostProtectionChartActive (isActive: boolean): IActionWithPayload {
  return { type: ActionTypes.SET_FROST_PROTECTION_CHART_ACTIVE, payload: isActive };
}

export function setFrostProtectionTableActive (isActive: boolean): IActionWithPayload {
  return { type: ActionTypes.SET_FROST_PROTECTION_TABLE_ACTIVE, payload: isActive };
}

export function setFrostProtectionCharts (chartOptions: Array<Options>): IActionWithPayload {
  return { type: ActionTypes.SET_FROST_PROTECTION_CHARTS, payload: chartOptions };
}

export function setFrostProtectionVisibility (visibility: IFrostProtectionVisibility): IActionWithPayload {
  return { type: ActionTypes.SET_FROST_PROTECTION_VISIBILITY, payload: visibility };
}
