import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-camera-settings-save',
  templateUrl: './camera-settings-save.component.html',
  styleUrls: ['./camera-settings-save.component.scss']
})
export class CameraSettingsSaveComponent {
  @Input()
  public isInvalid      : boolean;

  @Output()
  private save          : EventEmitter<void> = new EventEmitter<void>();

  public saveCam(): void {
    this.save.emit();
  }

}
