import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAlerts from './alerts';

export interface IState {
  serverAlerts: fromAlerts.IAlertsState;
}

export const reducers = {
  serverAlerts: fromAlerts.reducer
};

export const selector = createFeatureSelector<IState>('serverAlerts');

export const selectAlerts = createSelector(
  selector,
  state => state.serverAlerts.serverAlerts
);

export const selectSensorsConfig = createSelector(
  selector,
  state => state.serverAlerts.sensors
);
