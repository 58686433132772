<div class="tree-sensors-container">
  <div *ngIf="tree.profiles.options && tree.profiles.options.length" class="tree-head">
    <app-custom-dropdown [formControl]="profileControl"
                         [options]="tree.profiles.options"
                         [currentValue]="profile"></app-custom-dropdown>
  </div>
  <hr>

  <div class="tree-body" *ngIf="!!tree.trees.length" >
      <ul class="main" *ngFor="let treeItem of tree.trees">
        <li>
          <i (click)="treeItem.expanded=!treeItem.expanded"
            [ngClass]="{'triangle': true, 'fa-caret-down': treeItem.expanded, 'fa-caret-right': !treeItem.expanded}"></i>
          <i [class.fa-feed]="treeItem.type=='radio'"
            [class.fa-share-alt]="treeItem.type=='mac'"
            [class.fa-sitemap]="treeItem.type=='base'"
            class="type-icon"></i>
          <a>
            {{ treeItem.name }}
          </a>
          <i *ngIf="treeItem.hasSettingsIcon" class="zmdi zmdi-settings pointer" (click)="treeItemSettingsClicked(treeItem.id || treeItem.name)"></i>
          <ng-container *ngIf="tree.contentType !== ContentType.SoilMoisture">
            <i class="zmdi zmdi-eye-off" (click)="disableTree(treeItem)"></i>
            <i class="zmdi zmdi-eye" (click)="enableTree(treeItem)"></i>
          </ng-container>
          <i class="icomoon icon-checkbox-checked"></i>
          <div *ngIf="treeItem.expanded">
            <app-sensor-leaves [ulClass]="'submenu'"
                              [leaves]="treeItem.nodes"
                              [disabledGroupIds]="disabledGroupIds"
                              (toggleSensorEmitter)="toggleSensor($event)"></app-sensor-leaves>

            <ul *ngIf="treeItem.subNodes" class="submenu">
              <li *ngFor="let subNode of treeItem.subNodes">
                <i class="fa fa-share-alt"></i> {{ subNode.name }}
                <i *ngIf="subNode.hasSettingsIcon" class="zmdi zmdi-settings pointer" (click)="treeItemSettingsClicked(subNode.id || subNode.name)"></i>
                <app-sensor-leaves [ulClass]="'subsubmenu'" [disabledGroupIds]="disabledGroupIds" [leaves]="subNode.nodes" (toggleSensorEmitter)="toggleSensor($event)"></app-sensor-leaves>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>

</div>
