import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { IAccumulatorToolState } from '../interfaces/interfaces';
import { initialState } from '../states/states';
import { ActionTypes } from '../actions/actions';

export function AccumulatorToolReducer(state: IAccumulatorToolState = initialState, action: IAction): IAccumulatorToolState {
  switch (action.type) {
    case ActionTypes.SET_STATION_SENSORS:
      return {
        ...state,
        sensors: action.payload
      };
    default:
      return state;
  }
}
