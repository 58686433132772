import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { hamburgerAnimations } from '../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../core/services/left-components-toggler/left-components-toggler.service';


@Component({
  selector: 'app-hamburger',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.scss'],
  animations: hamburgerAnimations
})
export class HamburgerComponent implements OnInit {
  public state$: Observable<string>;
  constructor(public leftComponentsToggler: LeftComponentsTogglerService) { }

  public ngOnInit(): void {
    this.state$ = this.leftComponentsToggler.getHamburgerState();
  }

  public toggleBar(): void {
    this.leftComponentsToggler.toggleBar();
  }

}
