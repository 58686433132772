import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthBackgroundRoutingModule} from './auth-background-routing.module';
import {AuthModule} from '../../auth.module';
import {AuthBackgroundComponent} from './auth-background.component';
import {BackgroundLoadDirective} from '../../directives/background-load.directive';
import {DeeplinkComponent} from '../deeplink/deeplink.component';

@NgModule({
  imports: [
    CommonModule,
    AuthBackgroundRoutingModule,
    AuthModule,
  ],
  declarations: [
    AuthBackgroundComponent,
    BackgroundLoadDirective,
    DeeplinkComponent
  ]
})
export class AuthBackgroundModule { }
