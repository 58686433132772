import {IStation} from '../../../core/models/stations';
import {StationTypes} from '../../../shared/constants';
import { IColumn } from '../models/dashboard.models';
import { makeWidget } from './makeWidget';

export function findCropView(stations: Array<IStation>): Array<IStation> {
  return stations.filter((item: IStation) => item.info.device_id === StationTypes.get('CropViewType1')
    || item.info.device_id === StationTypes.get('CropViewType2'));
}

export function findIscout(stations: Array<IStation>): Array<IStation> {
  return stations.filter((item: IStation) => item.info.device_id === StationTypes.get('IScoutType'));
}

export function findDisease(stations: Array<IStation>): Array<IStation> {
  return stations.filter((s: IStation) => {
    return s.licenses ? s.licenses.models : false;
  });
}

export function findSimple(stations: Array<IStation>): Array<IStation> {
  return stations.filter((item: IStation) => item.info.device_id !== StationTypes.get('CropViewType1')
  && item.info.device_id !== StationTypes.get('CropViewType2')
  && item.info.device_id !== StationTypes.get('CropViewType3')
  && item.info.device_id !== StationTypes.get('IScoutType')
  && item.info.device_id !== StationTypes.get('IScoutMobileType'));
}

export function findForecast(stations: Array<IStation>): Array<IStation> {
  return stations.filter((s: IStation) => s.licenses.Forecast);
}

export function findTracker(stations: Array<IStation>): Array<IStation> {
  return stations.filter( (item: IStation) => item.info.device_id === StationTypes.get('iMetos Active Tracker') ||
  item.info.device_id === StationTypes.get('iMetos Object Tracker')
  );
}
