import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IStation, NetworkTypes } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { ICharacteristic } from '../../models/models';

@Component({
  selector: 'app-station-information-content',
  templateUrl: './station-information-content.component.html',
  styleUrls: ['./station-information-content.component.scss']
})
export class StationInformationContentComponent implements OnInit, OnDestroy {
  public destroy$               : Subject<boolean> = new Subject<boolean>();
  public selectedStation$       : Observable<IStation> = this.navigationStore.pipe(
    takeUntil(this.destroy$),
    select(selectNavigationStation),
    filter((station: IStation): boolean => !!station)
  );
  public basicInformation       : Array<ICharacteristic> = [];
  public networkDetails         : Array<ICharacteristic> = [];
  public weatherData            : Array<ICharacteristic> = [];
  public gpsLocation            : Array<ICharacteristic> = [];
  public readonly DEFAULT_GPS_LOCATION_TEXT = `Station location is not set.
    This information is very important for proper functioning of weather forecast and other services.
    Please go to station configuration and set the location.`;

  constructor(private navigationStore: Store<INavigationStationState>) {}

  public ngOnInit(): void {
    this.selectedStation$.subscribe((station: IStation): void => {
      this.setBasicInfo(station);
      this.setNetworkDetails(station);
      this.setWeatherData(station);
      this.setGpsLocation(station);
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private setBasicInfo(station: IStation): void {
    this.basicInformation = [
      {
        name: 'Station ID',
        value: station.name.original
      },
      {
        name: 'User defined name',
        value: station.name.custom || '/',
      },
      {
        name: 'Station type',
        value: station.info.device_name || '/',
      },
      {
        name: 'Firmware version',
        value: station.info.firmware || '/',
      },
      {
        name: 'Hardware version',
        value: station.info.hardware || '/'
      },
      {
        name: 'Logging interval',
        value: `${station.config.logging_interval} min`
      },
      {
        name: 'Max record time',
        value: this.parseMaxTime(parseInt(station.info.max_time, 10))
      },
      {
        name: 'Timezone',
        value: `UTC ${station.config.timezone_offset > 0 ? '+' : ''} ${station.config.timezone_offset / 60}`
      },
      {
        name: 'UID',
        value: station.info.uid
      },
      {
        name: 'IMEI',
        value: station.networking.imei
      },
    ].filter((characteristic: ICharacteristic): boolean => !!characteristic.value);
  }

  private setNetworkDetails(station: IStation): void {
    const networkDetails = [
      {
        name: 'Network type',
        value: station.networking.type
      },
      {
        name: 'Country',
        value: station.networking.country,
        spanWithLabel: station.networking.mcc
      },
      {
        name: 'Provider',
        value: station.networking.provider,
        spanWithLabel: station.networking.mnc
      },
      {
        name: 'Signal strength (%)',
        value: station.networking.rssi_pct
      }
    ];

    if (station.networking.type === NetworkTypes.GPRS
        || station.networking.type === NetworkTypes.GSM
        || station.networking.type === NetworkTypes.UMTS
        || station.networking.type === NetworkTypes.LTE) {
      networkDetails.push(
        {
          name: 'SIM ID',
          value: station.networking.simid || '/',
        },
        {
          name: 'APN name',
          value: station.networking.apn,
        },
        {
          name: 'APN username',
          value: station.networking.username,
        },
        {
          name: 'APN password',
          value: station.networking.password,
        }
      );
    }

    if (station?.networking?.current_radio_technology || station?.networking?.default_radio_technology) {
      networkDetails.push(
        {
          name: 'RAT Default',
          value: station.networking.default_radio_technology,
        },
        {
          name: 'RAT Current',
          value: station.networking.current_radio_technology,
        }
      );
    }

    // add the sim provider network, if it differs from current one (when roaming)
    if (station.networking.provider_sim && station.networking.provider_sim !== station.networking.provider) {
      networkDetails.push(
        {
          name: 'Provider (SIM)',
          value: station.networking.provider_sim,
          spanWithLabel: station.networking.mnc_sim
        }
      );
    }

    if (station.networking.type === NetworkTypes.CDMA) {
      networkDetails.push(
        {
          name: 'PPP created by',
          value: station.networking.apn
        },
        {
          name: 'PPP username',
          value: station.networking.username
        },
        {
          name: 'PPP password',
          value: station.networking.password
        },
        {
          name: 'MEID',
          value: station.networking.meid
        }
      );
    }
    this.networkDetails = networkDetails.filter((characteristic: ICharacteristic): boolean => !!characteristic.value);
  }

  private setWeatherData(station: IStation): void {
    this.weatherData = station.dates
      ? [
          {
            name: 'Created at',
            value: station.dates.created_at || '/'
          },
          {
            name: 'Database from',
            value: station.dates.min_date || '/'
          },
          {
            name: 'Database to',
            value: station.dates.max_date || '/'
          },
          {
            name: 'Last communication',
            value: station.dates.last_communication || '/'
          },
        ]
      : [];
  }

  private setGpsLocation(station: IStation): void {
    this.gpsLocation = station.position
      ? [
          {
            name: 'Altitude',
            value: station.position.altitude.toString() || '/'
          },
          {
            name: 'Latitude',
            value: station.position.geo.coordinates[1].toString() || '/'
          },
          {
            name: 'Longitude',
            value: station.position.geo.coordinates[0].toString() || '/'
          },
        ]
      : [];
  }

  private parseMaxTime(time: number): string {
    if (time > 0) {
      const days: number = Math.floor(time / (60 * 60 * 24));

      time = time % (60 * 60 * 24);
      const hours: number = Math.floor(time / (60 * 60));

      time = time % (60 * 60);
      const minutes: number = Math.floor(time / 60);

      return `${days} days, ${hours} + ' hours, ${minutes} minutes`;
    } else {
      return '';
    }
  }
}
