import {HostListener, Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {filter, map, tap} from 'rxjs/operators';
import {
  HAMBURGER_STATE_BAR_AND_STATION_ARE_DISPLAYED,
  HAMBURGER_STATE_BAR_IS_DISPLAYED,
  HAMBURGER_STATE_LEFT_COMPONENTS_ARE_HIDDEN,
  LEFT_BAR_STATE_IS_DISPLAYED, LEFT_BAR_STATE_IS_HIDDEN,
  STATE_TREE_IS_DISPLAYED_FOR_FULL_WIDTH, STATE_TREE_IS_DISPLAYED_FOR_REDUCED_WIDTH, STATE_TREE_IS_HIDDEN,
  CROPZONE_DATA_CONTENT_STATE_FULL_WIDTH,
  CROPZONE_DATA_CONTENT_STATE_REDUCED_WIDTH
} from './animation.constants';

@Injectable({
  providedIn: 'root'
})
export class LeftComponentsCropzoneTogglerService {
  public treeCanBeShown$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  private readonly fullScreenWidth  : number = 1024;
  private isFullScreen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(window.innerWidth > this.fullScreenWidth);
  private leftBarIsDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(window.innerWidth > this.fullScreenWidth);
  private leftCropzoneIsDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private leftCropzoneIsClosed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly pagesWithoutCropzone: Array<string> = ['/dashboard', '/user-settings', '/user-api-services', '/auth/login'];

  constructor(private router: Router) {
    this.canBeShown = this.pagesWithoutCropzone.indexOf(this.router.url) === -1;
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd): boolean => this.pagesWithoutCropzone.indexOf(event.url) === -1),
    ).subscribe((canBeShown: boolean): void => {
      this.canBeShown = canBeShown;
    });
  }

  private set canBeShown(canBeShown: boolean) {
    this.treeCanBeShown$.next(canBeShown);
    this.leftCropzoneIsDisplayed$.next(canBeShown ? !this.leftCropzoneIsClosed$.getValue() : false);
  }

  public getCropzoneDataContentState(): Observable<string> {
    return this.leftCropzoneIsDisplayed$.pipe(
      filter((): boolean => this.isFullScreen$.getValue()),
      map((isDisplayed: boolean): string => isDisplayed
        ? CROPZONE_DATA_CONTENT_STATE_REDUCED_WIDTH
        : CROPZONE_DATA_CONTENT_STATE_FULL_WIDTH
      )
    );
  }

  public getTreeState(): Observable<string> {
    return combineLatest([
      this.leftBarIsDisplayed$,
      this.leftCropzoneIsDisplayed$,
      this.isFullScreen$
    ])
      .pipe(
        map((result: Array<boolean>): string => {
          const leftBarIsDisplayed: boolean = result[0];
          const leftCropzoneIsDisplayed: boolean = result[1];
          return (leftBarIsDisplayed && leftCropzoneIsDisplayed)
            ? (this.isFullScreen$.getValue() ? STATE_TREE_IS_DISPLAYED_FOR_FULL_WIDTH : STATE_TREE_IS_DISPLAYED_FOR_REDUCED_WIDTH)
            : STATE_TREE_IS_HIDDEN;
        })
      );
  }

  public getTreeIsDisplayed(): Observable<boolean> {
    return this.getTreeState().pipe(
      map((state: string): boolean => state !== STATE_TREE_IS_HIDDEN)
    );
  }

  public getLeftBarState(): Observable<string> {
    return this.leftBarIsDisplayed$.pipe(
      map((isDisplayed: boolean): string => {
        return isDisplayed ? LEFT_BAR_STATE_IS_DISPLAYED : LEFT_BAR_STATE_IS_HIDDEN;
      })
    );
  }

  public getHamburgerState(): Observable<string> {
    return combineLatest([
      this.leftBarIsDisplayed$,
      this.leftCropzoneIsDisplayed$,
    ])
      .pipe(
        map((result: Array<boolean>): string => {
          const leftBarIsDisplayed = result[0];
          const leftCropzoneIsDisplayed = result[1];
          if (leftBarIsDisplayed && leftCropzoneIsDisplayed) {
            return HAMBURGER_STATE_BAR_AND_STATION_ARE_DISPLAYED;
          } else if (leftBarIsDisplayed) {
            return HAMBURGER_STATE_BAR_IS_DISPLAYED;
          } else {
            return HAMBURGER_STATE_LEFT_COMPONENTS_ARE_HIDDEN;
          }
        })
      );
  }

  public toggleBar(): void {
    this.leftBarIsDisplayed$.next(!this.leftBarIsDisplayed$.getValue());
  }

  public toggleCropzone(): void {
    this.leftCropzoneIsClosed$.next(!this.leftCropzoneIsClosed$.getValue());
    this.leftCropzoneIsDisplayed$.next(!this.leftCropzoneIsDisplayed$.getValue());
  }

  public handleSizeChange(): void {
    if (this.isFullScreen$.getValue() && window.innerWidth < this.fullScreenWidth) {
      this.isFullScreen$.next(false);
    }
    if (!this.isFullScreen$.getValue() && window.innerWidth > this.fullScreenWidth) {
      this.isFullScreen$.next(true);
      this.leftBarIsDisplayed$.next(true);
    }
  }
}
