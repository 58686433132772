import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IGetWarningsSensors, ISaveConvertUnitsPayload, IUpdateWarningPayload, IWarningsList} from '../models/models';

export const enum WarningsListActionTypes {
  SET_WARNINGS_LIST = '[Warnings] SET_WARNINGS_LIST',
  SAVE_CONVERT_UNITS = '[Warnings] SAVe_CONVERT_UNITS',
  GET_SENORS = '[Warnings] GET_SENORS',
  UPDATE_WARNINGS_LIST_SENSOR = '[Warnings] UPDATE_WARNINGS_LIST_SENSOR'
}

export function updateWarningsListSensor(update: IUpdateWarningPayload): IActionWithPayload {
  return {type: WarningsListActionTypes.UPDATE_WARNINGS_LIST_SENSOR, payload: update};
}

export function saveConvertUnits(save: ISaveConvertUnitsPayload): IActionWithPayload {
  return {type: WarningsListActionTypes.SAVE_CONVERT_UNITS, payload: save};
}

export function getSensors(getSenors: IGetWarningsSensors): IActionWithPayload {
  return {type: WarningsListActionTypes.GET_SENORS, payload: getSenors};
}

export function setWarningsList(warnings: IWarningsList): IActionWithPayload {
  return {type: WarningsListActionTypes.SET_WARNINGS_LIST, payload: warnings};
}




