import { Actions, Effect, ofType } from '@ngrx/effects';
import { ApiCallService } from '../../../services/api/api-call.service';
import { Observable, from } from 'rxjs';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import {
  setAnimalProductionCharts,
  setAnimalProductionError,
  setAnimalProductionLoading,
  AnimalProductionActionTypes,
  setAnimalProductionLicenses,
  setAnimalProductionWarning
} from '../actions';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAnimalProductionState } from '../models/animal-production.models';
import { setNotify } from '../../../core/actions/notify';
import { PictocodeService } from '../../../services/pictocode/pictocode.service';

@Injectable()
export class AnimalProductionEffects {
  constructor(
    private api               : ApiCallService,
    private store             : Store<IAnimalProductionState>,
    private actions$          : Actions,
    private pictocodeService  : PictocodeService
  ) { }

  @Effect()
  public getWorkPlanningCharts$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(AnimalProductionActionTypes.GET_ANIMAL_PRODUCTION_CHARTS),
    tap(() => {
      this.store.dispatch(setAnimalProductionLoading(true));
      this.store.dispatch(setAnimalProductionError(false));
    }),
    switchMap((action: IActionWithPayload) => this.api.getWorkplanningService(action.payload)
      .pipe(
        tap(response => {
          this.pictocodeService.addTooltip(response.chartsOptions);
          this.pictocodeService.setBiggerIcons(response.chartsOptions);
        }),
        switchMap(response => [
          setAnimalProductionLoading(false),
          setAnimalProductionCharts({ ...response, service: action.payload.service }),
          ...(response.chartsOptions && response.chartsOptions.length === 1 ? [
              setNotify('This station does not have all required sensors or required data sets are not available')
            ] : [])
        ]),
        catchError(err => [
          setAnimalProductionLoading(false),
          setAnimalProductionError(true)
        ]
      ))
  ));

  @Effect()
  public getAnimalProductionLicenses$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(AnimalProductionActionTypes.GET_ANIMAL_PRODUCTION_LICENSES),
    tap(() => {
      this.store.dispatch(setAnimalProductionLicenses([]));
      this.store.dispatch(setAnimalProductionWarning(null));
    }),
    switchMap((action: IActionWithPayload) => this.api.getStationLicenses(action.payload).pipe(
      switchMap((res: any) => from([
        setAnimalProductionLicenses(res),
      ])),
      catchError(() => from([]))
      )
    )
  );
}
