<div class="page-without-left-tree">
  <app-product-activation-header [title]="'Product Activation' | translate"
                                 [subtitle]="'Use emailed keys to activate products' | translate"></app-product-activation-header>

  <ng-container *ngIf="!(productConfiguration$ | async); else productActivationForm">
    <app-form-layout>
      <div class="alert alert-info"
           *ngIf="!theme?.hideWarningProductActivation">
        <span class="glyphicon glyphicon-info-sign"></span>
        {{ warningPurchaseLicenses['text1'] | translate }}
        <a href="{{ warningPurchaseLicenses['link'] }}">{{ warningPurchaseLicenses['linkText'] }}</a>
        {{ warningPurchaseLicenses['text2'] | translate }}
        <a href="{{ warningPurchaseLicenses['mail'] }}">{{ warningPurchaseLicenses['mailText'] }}</a>
        {{ warningPurchaseLicenses['text3'] | translate }}
      </div>

      <div class="product-activation-form">
        <div class="product-activation-form__title">
          <h2>{{ 'Product information' | translate }}</h2>
        </div>

        <div class="product-activation-form__content">
          <app-product-activation-form (activateProductEmitter)="findProductsToActivate($event)"></app-product-activation-form>
        </div>
      </div>
    </app-form-layout>
  </ng-container>

  <ng-template #productActivationForm>
    <div class="alert alert-info" *ngIf="(productConfiguration$ | async).isAllActivated">
      <span class="glyphicon glyphicon-info-sign"></span>
      {{ 'The activation of the product key was successful!' | translate }}
    </div>

    <div class="product-activation-block"
         [@cardAnimation]
         *ngFor="let product of (productConfiguration$ | async)?.products">
      <div [ngSwitch]="product.type">
        <app-product-activation-vws
          *ngSwitchCase="productType.VIRTUAL_STATION"
          [configuration]="product">
        </app-product-activation-vws>

        <app-product-activation-disease-model
          *ngSwitchCase="productType.DISEASE_MODEL"
          [configuration]="product"
          [licensesOverlapped]="getProductLicenses(product.pos)"
          [availableUserStations$]="availableUserStations$">
        </app-product-activation-disease-model>

        <app-product-activation-forecast
          *ngSwitchCase="productType.WEATHER_FORECAST"
          [configuration]="product"
          [licensesOverlapped]="getProductLicenses(product.pos)"
          [availableUserStations$]="availableUserStations$">
        </app-product-activation-forecast>

        <app-product-activation-service-license
          *ngSwitchCase="productType.SERVICE_LICENSE"
          [configuration]="product"
          [licensesOverlapped]="getProductLicenses(product.pos)">
        </app-product-activation-service-license>

        <app-product-activation-davis-ingest
          *ngSwitchCase="productType.DAVIS_INGEST"
          [configuration]="product"
          [licensesOverlapped]="getProductLicenses(product.pos)">
        </app-product-activation-davis-ingest>

        <app-product-activation-farm-view
          *ngSwitchCase="productType.FARM_VIEW"
          [configuration]="product"
          [licensesOverlapped]="getProductLicenses(product.pos)">
        </app-product-activation-farm-view>

        <app-product-activation-iscout-mobile
          *ngSwitchCase="productType.ISCOUT_MOBILE"
          [configuration]="product"
          [licensesOverlapped]="getProductLicenses(product.pos)">
        </app-product-activation-iscout-mobile>
      </div>
    </div>

    <div class="product-activation-block-button">
      <app-custom-button [content]="'Back' | translate"
                         [color]="'red'"
                         (click)="deselectProductConfiguration()"></app-custom-button>
    </div>
  </ng-template>
</div>
