<app-form-layout class="current-settings">
  <ng-container>
    <app-card-header [header]="'Current elevation settings'"
                     [helpButton]="true"
                     [description]="'Set reference value for the water level'"
                     (openModal)="openModal()">
    </app-card-header>

    <form class="current-settings__input-form">
      <div class="row">
      <app-form-input class="current-settings__input-form__input col col-md-4"
                      [type]="'border'"
                      [formControl]="elevation"
                      [size]="'small'"
                      [placeholder]="'Sensor elevation above ground level'">
      </app-form-input>
      <label *ngIf="ultrasonicSensors && ultrasonicSensors.length"
             class="current-settings__input-form__label col col-md-1">
        <span class="input-group-addon current-settings__input-form__unit">{{ currentUnit }}</span>
      </label>
      </div>
      <div class="row">
        <hr class="current-settings__button-div__hr">
          <div>
            <app-custom-button class="current-settings__button-div__button"
                               [color]="'red'"
                               [content]="'Save configuration'"
                               (click)="saveUltrasonicSettings()">
            </app-custom-button>
        </div>
      </div>
    </form>
  </ng-container>
</app-form-layout>

<app-info-modal [modalId]="ULTRASONIC_HELP_MODAL_ID"
                [header]="flags.nmetosfwt ? 'Field Water Tube Settings' : 'Water Level Settings' | translate">
  <app-ultrasonic-distance-settings-help [flags]="flags">
  </app-ultrasonic-distance-settings-help>
</app-info-modal>

