import { Component } from '@angular/core';

@Component({
  selector: 'app-xarvio-modal',
  templateUrl: './xarvio-modal.component.html',
  styleUrls: [ './xarvio-modal.component.scss' ]
})
export class XarvioModalComponent {

}
