import { IDisplayRulesConfig } from '../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../environments/interfaces/theme';

export const themeConfig: IThemeConfig = {
  subDomain: 'proglu',
  language: 'en',
  headerClass: 'white',
  src: '../../../assets/themes/proglu/assets/images/logo.png',
  width: '300',
  mainMTop: '16',
  mainWidth: '250',
  mainLeft: '10',
  customBackgroundFolder: 'proglu',
  loginLeft: '40',
  loginBottom: '10',
  registerBottom: '10',
  resetLeft: '20',
  resetBottom: '10',
  name: 'Proglu',
  profileMenuSrc: '../../../assets/themes/proglu/assets/images/profile.png',
  faviconSrc: '../../../assets/themes/default/assets/favicon.ico',
  hideTerms: true,
  hideUserMenu: {
    apiServices: true,
    dataUpload: true,
    feedbackAndSupport: true,
    help: true
  },
  hideCropZonesIcon: true,
  hideDashboardText: true,
  hideStationListDropdown: true,
  hideDashboardMap: true,
  hideNotificationsIcon: true,
  iScoutNames: 'ProGlu',
  colorConfig: {
    '--search-widget-border': '#565959',
    '--search-widget-label': '#565959',
    '--search-widget-label-dark-green': '#CCE226',
    '--search-widget-label-orange': '#f39224',
    '--search-widget-button': '#565959',
    '--main-static': '#CCE226',
    '--main-static-orange': '#f39224',
    '--form-button': '#CCE226',
    '--form-button-action': '#565959',
  },
  customWarningProductActivation: true,
  hideSubscriptions: true
};

export const displayRulesConfig: IDisplayRulesConfig = {
  userMenu: true,
  farmViewMenu: true,
  leftBarMenu: {
    stationData: true,
    cropView: true,
    iScout: true,
    weatherForecast: true,
    workPlanning: true,
    animalProduction: true,
    accumulatorTool: true,
    frostProtection: true,
    diseaseModels: true,
    soilMoisture: true,
    hydroponics: true,
    tracker: true,
    soilGuard: false,
    settings: true,
  },
};
