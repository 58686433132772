<div [formGroup]="form" class="remove-form">
        <div class="remove-form__fields">
            <div class="remove-form__fields__item">
                <h5>{{ 'Farm name' | translate }}</h5>
                <input
                [formControl]="farm"
                [typeahead]="farms$ | async"
                [typeaheadScrollable]="true"
                [typeaheadOptionsInScrollableView]="5"
                typeaheadOptionField="name"
                class="remove-form__fields__item__input__padding"/>
            </div>
        </div>
        <div class="remove-form__buttons">
            <app-custom-button [content]="'Cancel'" (click)="close()"></app-custom-button>
            <app-custom-button [color]="submitColor" [disabled]="form.invalid" (click)="submitEmit()"
                [content]="submitContent"></app-custom-button>
        </div>
    </div>
