import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IFromTo} from '../../../shared/camera/models/camera';
import {ICameraDataState, ICropViewReportData, IIscoutReportData, IRequestCameraData} from '../models/camera-data';
import {IIscoutGlueBoard, IIscoutSeason} from '../../iscout/models/iscout.models';

export enum CameraDataActionTypes {
  SET_CAMERA_DATA_IS_CHART_EXPORT_IN_PROGRESS = '[CameraData] SET_CAMERA_DATA_IS_CHART_EXPORT_IN_PROGRESS',
  SET_CAMERA_DATA_IS_TABLE_EXPORT_IN_PROGRESS = '[CameraData] SET_CAMERA_DATA_IS_TABLE_EXPORT_IN_PROGRESS',
  SET_CAMERA_DATA_IS_HELP_ACTIVE = '[CameraData] SET_CAMERA_DATA_IS_HELP_ACTIVE',
  SET_CAMERA_DATA_IS_CHART_ACTIVE = '[CameraData] SET_CAMERA_DATA_IS_CHART_ACTIVE',
  SET_CAMERA_DATA_IS_TABLE_ACTIVE = '[CameraData] SET_CAMERA_DATA_IS_TABLE_ACTIVE',
  SET_CAMERA_DATA_SETTINGS = '[CameraData] SET_CAMERA_DATA_SETTINGS',
  SET_CAMERA_DATA_CURRENT_DATE_STRING = '[CameraData] SET_CAMERA_DATA_CURRENT_DATE_STRING',
  GET_CAMERA_DATA_FROM_ISCOUT = '[CameraData] GET_CAMERA_DATA_FROM_ISCOUT',
  SET_CAMERA_DATA_FROM_ISCOUT = '[CameraData] SET_CAMERA_DATA_FROM_ISCOUT',
  GET_CAMERA_DATA_FROM_CROPVIEW = '[CameraData] GET_CAMERA_DATA_FROM_CROPVIEW',
  SET_CAMERA_DATA_FROM_CROPVIEW = '[CameraData] SET_CAMERA_DATA_FROM_CROPVIEW',
  SET_CAMERA_GLUE_BOARD_DATA_FROM_ISCOUT = '[CameraData] SET_CAMERA_GLUE_BOARD_DATA_FROM_ISCOUT',
  SET_CAMERA_SEASON_DATA_FROM_ISCOUT = '[CameraData] SET_CAMERA_SEASON_DATA_FROM_ISCOUT',
}

export function setCameraDataIsChartExportInProgress(status: boolean): IActionWithPayload {
  return {type: CameraDataActionTypes.SET_CAMERA_DATA_IS_CHART_EXPORT_IN_PROGRESS, payload: status};
}

export function setCameraDataIsTableExportInProgress(status: boolean): IActionWithPayload {
  return {type: CameraDataActionTypes.SET_CAMERA_DATA_IS_TABLE_EXPORT_IN_PROGRESS, payload: status};
}

export function setCameraDataIsHelpActive(status: boolean): IActionWithPayload {
  return {type: CameraDataActionTypes.SET_CAMERA_DATA_IS_HELP_ACTIVE, payload: status};
}

export function setCameraDataIsChartActive(status: boolean): IActionWithPayload {
  return {type: CameraDataActionTypes.SET_CAMERA_DATA_IS_CHART_ACTIVE, payload: status};
}

export function setCameraDataIsTableActive(status: boolean): IActionWithPayload {
  return {type: CameraDataActionTypes.SET_CAMERA_DATA_IS_TABLE_ACTIVE, payload: status};
}

export function setCameraDataSettings(data: ICameraDataState): IActionWithPayload {
  return {type: CameraDataActionTypes.SET_CAMERA_DATA_SETTINGS, payload: data};
}

export function setCameraDataCurrentDateString(dateString: string | IFromTo): IActionWithPayload {
  return {type: CameraDataActionTypes.SET_CAMERA_DATA_CURRENT_DATE_STRING, payload: dateString};
}

export function getCameraDataFromIscout(request: IRequestCameraData): IActionWithPayload {
  return {type: CameraDataActionTypes.GET_CAMERA_DATA_FROM_ISCOUT, payload: request};
}

export function setCameraDataFromIscout(payload: Array<IIscoutReportData>): IActionWithPayload {
  return {type: CameraDataActionTypes.SET_CAMERA_DATA_FROM_ISCOUT, payload};
}
export function getCameraDataFromCropView(request: IRequestCameraData): IActionWithPayload {
  return {type: CameraDataActionTypes.GET_CAMERA_DATA_FROM_CROPVIEW, payload: request};
}

export function setCameraDataFromCropView(payload: Array<ICropViewReportData>): IActionWithPayload {
  return {type: CameraDataActionTypes.SET_CAMERA_DATA_FROM_CROPVIEW, payload};
}

export function setCameraGlueBoardDataFromIscout(payload: IIscoutGlueBoard): IActionWithPayload {
  return {type: CameraDataActionTypes.SET_CAMERA_GLUE_BOARD_DATA_FROM_ISCOUT, payload};
}

export function setCameraSeasonDataFromIscout(payload: IIscoutSeason): IActionWithPayload {
  return {type: CameraDataActionTypes.SET_CAMERA_SEASON_DATA_FROM_ISCOUT, payload};
}
