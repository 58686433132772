<div
  class="dropdown-container"
  appOutClick
  *ngIf="currentContent"
  (click)="toggleOpen()"
  (outClick)="close()"
>
  <app-custom-button
    [caret]="true"
    [content]="staticContent || currentContent"
    [color]="color"
  ></app-custom-button>

  <div class="custom-dropdown" *ngIf="isOpen">
    <p class="search-input" (click)="stopBubbling($event)">
      <app-multilevel-dropdown-input
        [placeholder]="'Search insects' | translate"
        [iconClass]="'zmdi zmdi-search'"
        [formControl]="searchControl"
      ></app-multilevel-dropdown-input>
    </p>
    <ng-container *ngIf="groupedSetFiltered && groupedSetFiltered.length">
      <div *ngFor="let group of groupedSetFiltered">
        <hr>
        <p class="group-name" title="{{group.name}}" style="text-align: left">{{group.name | translate}}</p>
        <p
          class="pest-text-left"
          *ngFor="let option of group.options"
          (click)="onChange(option)"
        >
          <span
            *ngIf="option.value === currentValue"
            class="fa fa-check check"
          ></span>
          {{ option.content |translate}}
        </p>
      </div>
    </ng-container>
  </div>
</div>
