import { SharedModule } from './../../shared/shared.module';
import { PaginationModule } from 'ngx-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationsHistoryRoutingModule } from './notifications-history-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsHistoryComponent } from './notifications-history.component';
import { NotificationHistoryHeaderComponent } from './components/notifications-history-header/notifications-history-header.component';
import { NotificationsHistoryListComponent } from './components/notifications-history-list/notifications-history-list.component';

@NgModule({
  imports: [
    CommonModule,
    NotificationsHistoryRoutingModule,
    SharedModule,
    TranslateModule.forChild(),
    PaginationModule.forRoot()
  ],
  declarations: [
    NotificationsHistoryComponent,
    NotificationHistoryHeaderComponent,
    NotificationsHistoryListComponent
  ]
})
export class NotificationsHistoryModule { }
