import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { YieldConfigActionTypes } from '../actions/yield-config.action';

export interface IDataSourcesState {
  datasourcesRain: Array<any>;
  datasourcesAirTemperature: Array<any>;
  cropsLazyLoading: Array<any>;
  collectConfigData: boolean;
  isLoading: boolean;
}

export const initialState: IDataSourcesState = {
  datasourcesRain: [],
  datasourcesAirTemperature: [],
  cropsLazyLoading: [],
  collectConfigData: false,
  isLoading: false
};

export function reducer(state: IDataSourcesState = initialState, action: IActionWithPayload): IDataSourcesState {
  switch (action.type) {
    case YieldConfigActionTypes.SET_DATASOURCES_RAIN:
      return <IDataSourcesState>{
        ...state,
        datasourcesRain: action.payload
      };
    case YieldConfigActionTypes.SET_DATASOURCES_AIRTEMPERATURE:
      return <IDataSourcesState>{
        ...state,
        datasourcesAirTemperature: action.payload
      };
    case YieldConfigActionTypes.SET_CROPS_LAZY_LOADING:
      return <IDataSourcesState>{
        ...state,
        cropsLazyLoading: action.payload
      };
    case YieldConfigActionTypes.SET_YIELD_CONFIG_LOADING:
      return <IDataSourcesState>{
        ...state,
        isLoading: action.payload
      };
    case YieldConfigActionTypes.COLLECT_CONFIG_DATA:
      return <IDataSourcesState>{
        ...state,
        collectConfigData: action.payload
      };
    default:
      return state;
  }
}
