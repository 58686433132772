import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import {
  getWeatherForecastImage,
  setWeatherForecastImageError,
  setWeatherForecastImageLoading
} from '../../actions/weather-forecast-image';
import { IGetWeatherForecastRequest, IWeatherForecastImageState } from '../../models/models';
import { selectWeatherForecastImage, selectWeatherForecastImageError, selectWeatherForecastImageLoading } from '../../reducers';

@Component({
  selector: 'app-weather-forecast-image',
  templateUrl: './weather-forecast-image.component.html',
  styleUrls: ['./weather-forecast-image.component.scss']
})
export class WeatherForecastImageComponent implements OnInit, OnDestroy {
  public imageUrl$: Observable<string>;
  public isLoading$: Observable<boolean>;
  public isError$: Observable<boolean>;

  constructor(private weatherForecastImageStore: Store<IWeatherForecastImageState>,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly availablePages: Array<string> = [
    'meteogram_one',
    'meteogram_agro',
    'pictoprint',
    'meteogram_14day',
  ];

  public ngOnInit(): void {
    this.initImageListener();
    this.iniStatusListeners();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initImageListener(): void {
    this.imageUrl$ = this.weatherForecastImageStore.pipe(
      select(selectWeatherForecastImage)
    );
    this.router.events.pipe(
      takeUntil(this.destroy$),
      filter(event => event instanceof NavigationEnd),
      startWith(<string>null)
    ).subscribe(() => {
      this.dispatchGetImage();
    });
  }

  private dispatchGetImage(): void {
    this.weatherForecastImageStore.dispatch(setWeatherForecastImageLoading(true));
    this.weatherForecastImageStore.dispatch(setWeatherForecastImageError(false));
    this.weatherForecastImageStore.dispatch(getWeatherForecastImage(this.prepareRequest()));
  }

  private prepareRequest(): IGetWeatherForecastRequest {
    const params = (<BehaviorSubject<{ page: string, id: string }>>this.activatedRoute.params).getValue();
    if (this.availablePages.indexOf(params.page) === -1) {
      this.router.navigate(['/dashboard']);
    }
    return {
      stationId: params.id,
      mode: params.page
    };
  }

  private iniStatusListeners(): void {
    this.isLoading$ = this.weatherForecastImageStore.pipe(select(selectWeatherForecastImageLoading));
    this.isError$ = this.weatherForecastImageStore.pipe(select(selectWeatherForecastImageError));
  }
}
