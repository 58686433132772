import { Injectable } from '@angular/core';
import { ICrop } from '../../../core/models/cropzones';
import * as moment from 'moment';
import { deepClone } from './../../../shared/utils/deepClone';
import { ICropZone } from './../../../core/models/cropzones';
import { ICropzoneManagementSort } from '../reducers/cropzoneManagement';

@Injectable({
  providedIn: 'root'
})
export class CropzoneManagementService {

  constructor() { }

  public setCropDates(oldCropzone: ICropZone, duplicatedCropzone: ICropZone): ICrop {
    const fromDifference = moment(duplicatedCropzone.from).clone().diff(moment(oldCropzone.from).clone(), 'days', true);
    const toDifference = moment(duplicatedCropzone.to).clone().diff(moment(oldCropzone.to).clone(), 'days', true);

    const newEmergence = moment(oldCropzone.crop.emergence).clone().add(fromDifference, 'days');
    const newLate = moment(oldCropzone.crop.fao56.L_late).clone().add(toDifference, 'days');

    const from = moment(duplicatedCropzone.from);
    const to = moment(duplicatedCropzone.to);
    const duration = to.diff(from, 'days', true);

    const newCropObject: ICrop = duplicatedCropzone.crop = {
      ...duplicatedCropzone.crop,
      emergence: from.clone().format('YYYY-MM-DD[T]HH:mm:ss') + '+00:00',
      fao56: {
        ...duplicatedCropzone.crop.fao56,
        L_ini: from.clone().add(duration * 0.25, 'days').format('YYYY-MM-DD[T]HH:mm:ss') + '+00:00',
        L_dev: from.clone().add(duration * 0.5, 'days').format('YYYY-MM-DD[T]HH:mm:ss') + '+00:00',
        L_mid: from.clone().add(duration * 0.75, 'days').format('YYYY-MM-DD[T]HH:mm:ss') + '+00:00',
        L_late: from.clone().add(duration, 'days').format('YYYY-MM-DD[T]HH:mm:ss') + '+00:00'
      }
    };

    return newCropObject;
  }

  public sortManagementList(managementList: any, sorting: ICropzoneManagementSort[]): any {
    sorting.forEach((sort) => {
      if (sort.sortingItem === 'FARM') {
        if (sort.sortingOrder === 'ascending') {
          managementList =  managementList.sort((a, b) => (a.farm.name.toLowerCase() > b.farm.name.toLowerCase()) ? 1
          : ((b.farm.name.toLowerCase() > a.farm.name.toLowerCase()) ? -1 : 0));
        } else if (sort.sortingOrder === 'descending') {
          managementList = managementList.sort((a, b) => (a.farm.name.toLowerCase() > b.farm.name.toLowerCase()) ? -1
          : ((b.farm.name.toLowerCase() > a.farm.name.toLowerCase()) ? 1 : 0));
        }
      }
    });

    managementList.forEach((farm, index) => {
      const fieldOrder = sorting.filter((sort) => sort.sortingItem === 'FIELD')[0].sortingOrder;
      if (fieldOrder === 'ascending') {
        managementList[index].fields = managementList[index].fields.sort((a, b) =>
        (a.field.name.toLowerCase() > b.field.name.toLowerCase()) ? 1
        : ((b.field.name.toLowerCase() > a.field.name.toLowerCase()) ? -1 : 0));
      } else if (fieldOrder === 'descending') {
        managementList[index].fields = managementList[index].fields.sort((a, b) =>
        (a.field.name.toLowerCase() > b.field.name.toLowerCase()) ? -1
        : ((b.field.name.toLowerCase() > a.field.name.toLowerCase()) ? 1 : 0));
      }

      const cropzoneSortObject = sorting.filter((sort) =>
        sort.sortingItem === 'CROPZONE' && sort.sortingOrder !== 'none' ||
        sort.sortingItem === 'CROP' && sort.sortingOrder !== 'none' ||
        sort.sortingItem === 'FROM' && sort.sortingOrder !== 'none' ||
        sort.sortingItem === 'TO' && sort.sortingOrder !== 'none'
      )[0];

      const property = cropzoneSortObject.sortingItem === 'CROP' ? 'crop_name'
        : cropzoneSortObject.sortingItem === 'FROM' ? 'from'
        : cropzoneSortObject.sortingItem === 'TO' ? 'to'
        : 'name';

      managementList[index].fields.forEach((field, i) => {
        if (property !== 'from' && property !== 'to') {
          if (cropzoneSortObject.sortingOrder === 'ascending') {
            managementList[index].fields[i].cropzones = managementList[index].fields[i].cropzones.sort((a, b) =>
            (a[property].toLowerCase() > b[property].toLowerCase()) ? 1
            : ((b[property].toLowerCase() > a[property].toLowerCase()) ? -1 : 0));
          } else if (cropzoneSortObject.sortingOrder === 'descending') {
            managementList[index].fields[i].cropzones = managementList[index].fields[i].cropzones.sort((a, b) =>
            (a[property].toLowerCase() > b[property].toLowerCase()) ? -1
            : ((b[property].toLowerCase() > a[property].toLowerCase()) ? 1 : 0));
          }
        } else {
          if (cropzoneSortObject.sortingOrder === 'ascending') {
            managementList[index].fields[i].cropzones = managementList[index].fields[i].cropzones.sort((a, b) =>
            (moment(a[property]).clone().isAfter(moment(b[property]).clone())) ? 1
            : ((moment(b[property]).clone().isAfter(moment(a[property]).clone())) ? -1 : 0));
          } else if (cropzoneSortObject.sortingOrder === 'descending') {
            managementList[index].fields[i].cropzones = managementList[index].fields[i].cropzones.sort((a, b) =>
            (moment(a[property]).clone().isAfter(moment(b[property]).clone())) ? -1
            : ((moment(b[property]).clone().isAfter(moment(a[property]).clone())) ? 1 : 0));
          }
        }

        managementList[index].fields[i].cropzones = managementList[index].fields[i].cropzones.map((cropzone) => {
          const licenseStart = cropzone.license_start_date && cropzone.license_start_date.length === 10 ? cropzone.license_start_date
          : cropzone.license_start_date && cropzone.license_start_date.length !== 10 ? moment(cropzone.license_start_date).clone().format('DD-MM-YYYY') : null;

          const licenseEnd = cropzone.license_end_date && cropzone.license_end_date.length === 10 ? cropzone.license_end_date
          : cropzone.license_end_date && cropzone.license_end_date.length !== 10 ? moment(cropzone.license_end_date).clone().format('DD-MM-YYYY') : null;

          let isActive: boolean = false;
          if (cropzone.license_end_date) {
            if (cropzone.license_end_date.length === 10) {
              isActive = moment(cropzone.license_end_date, 'DD-MM-YYYY').clone().isAfter(moment());
            } else {
              isActive = moment(cropzone.license_end_date, 'YYYY-MM-DDTHH:mm:ssZ').clone().isAfter(moment());
            }
          }

          const daysUntilNow = moment(cropzone.license_end_date, 'DD-MM-YYYY').clone().diff(moment(), 'days');
          return {
            ...cropzone,
              from: cropzone.from.length === 10 ? cropzone.from
              : moment(cropzone.from, 'YYYY-MM-DDTHH:mm:ssZ').clone().format('DD-MM-YYYY'),
              to: cropzone.to.length === 10 ? cropzone.to : moment(cropzone.to, 'YYYY-MM-DDTHH:mm:ssZ').clone().format('DD-MM-YYYY'),
              license_start_date: licenseStart,
              license_end_date: licenseEnd,
              isActiveLicense: isActive,
              aboutToExpire: daysUntilNow <= 30 && daysUntilNow > 0,
              daysUntilExpired: daysUntilNow
          };
        });
      });
    });
    return managementList;
  }

  public formatDates(cropzone: any): ICropZone {
    const licenseStart = cropzone.license_start_date && cropzone.license_start_date.length === 10
    ? moment(cropzone.license_start_date).clone().format('DD-MM-YYYY')
    : cropzone.license_start_date && cropzone.license_start_date.length !== 10
    ? moment(cropzone.license_start_date, 'YYYY-MM-DDTHH:mm:ssZ').clone().format('DD-MM-YYYY')
    : null;

    const licenseEnd = cropzone.license_end_date && cropzone.license_end_date.length === 10 ? cropzone.license_end_date
    : cropzone.license_end_date && cropzone.license_end_date.length !== 10
    ? moment(cropzone.license_end_date, 'YYYY-MM-DDTHH:mm:ssZ').clone().format('DD-MM-YYYY')
    : null;

    let isActive: boolean = false;
    if (cropzone.license_end_date) {
      if (cropzone.license_end_date.length === 10) {
        isActive = moment(cropzone.license_end_date, 'DD-MM-YYYY').clone().isAfter(moment());
      } else {
        isActive = moment(cropzone.license_end_date, 'YYYY-MM-DDTHH:mm:ssZ').clone().isAfter(moment());
      }
    } else {
      isActive = false;
    }

    const daysUntilNow = moment(cropzone.license_end_date, 'DD-MM-YYYY').clone().diff(moment(), 'days');

    return {
      ...cropzone,
        from: cropzone.from.length === 10 ? cropzone.from : moment(cropzone.from, 'YYYY-MM-DDTHH:mm:ssZ').clone().format('DD-MM-YYYY'),
        to: cropzone.to.length === 10 ? cropzone.to : moment(cropzone.to, 'YYYY-MM-DDTHH:mm:ssZ').clone().format('DD-MM-YYYY'),
        license_start_date: licenseStart,
        license_end_date: licenseEnd,
        isActiveLicense: isActive,
        aboutToExpire: daysUntilNow <= 30 && daysUntilNow > 0
    };
  }

  public getEligibleClass(management): string {
    const visibleCropzones: ICropZone[] = [];
    management.forEach((m) => {
      if (m.farm && m.fields) {
        m.fields.forEach((f) => {
          if (f.cropzones.length > 0) {
            f.cropzones.forEach((c) => {
              visibleCropzones.push(c);
            });
          }
        });
      }
    });

    let isEligibleForRenew = false;

    visibleCropzones.forEach((c: ICropZone) => {
      if (c.license_end_date) {
        if (c.license_end_date.length === 10) {
          if (moment(c.license_end_date, 'DD-MM-YYYY').clone().isBefore(moment())) {
            isEligibleForRenew = true;
          }
        } else {
          if (moment(c.license_end_date, 'YYYY-MM-DDTHH:mm:ssZ').clone().isBefore(moment())) {
            isEligibleForRenew = true;
          }
        }
      } else {
        isEligibleForRenew = true;
      }
    });

    return isEligibleForRenew ? '' : 'none';
  }
}
