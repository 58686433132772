import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { stationDataContentAnimations } from '../../../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../../../core/services/left-components-toggler/left-components-toggler.service';
import { ITreeStructure } from '../../../../services/tree/models';
import { TreeService } from '../../../../services/tree/tree.service';

@Component({
  selector: 'app-sample-layout',
  templateUrl: './sample-layout.component.html',
  styleUrls: ['./sample-layout.component.css'],
  animations: stationDataContentAnimations
})
export class SampleLayoutComponent implements OnInit {

  public tree$: BehaviorSubject<ITreeStructure>;
  public state$: Observable<string>;

  constructor(
    private treeService: TreeService,
    private leftComponentsToggler: LeftComponentsTogglerService
  ) {

  }

  public ngOnInit(): void {
    this.tree$ = this.treeService.getSoilGuardTreeStructure();
    this.state$ = this.leftComponentsToggler.getStationDataContentState();
  }

}
