import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { cameraDistanceMultiplier, rectangleConfig, RectangleVertexTypes } from '../camera/constants/rectangles';
import { IDrawingOptions, IPicture } from '../camera/models/camera';
import {
  IMultipleRectangleSelectedEvent,
  IPoint,
  IRectangle,
  IRectangleSelectedEvent,
  IRectangleVertex
} from '../camera/models/rectangles';
import { RectangleService } from '../camera/services/rectangles/rectangle.service';

declare var canvas: HTMLCanvasElement;

@Component({
  selector: 'app-detailed-image',
  templateUrl: './detailed-image.component.html',
  styleUrls: ['./detailed-image.component.scss']
})
export class DetailedImageComponent implements OnInit, OnChanges {
  @Input()
  public picture: IPicture;
  @Input()
  public filteredRectangles: Array<IRectangle>;
  @Input()
  public measurementsAreActive: boolean = false;
  @Input()
  public currentDate: string = '';
  @Input()
  public hasWritePermission: boolean = false;
  @Input()
  public selectedRectangleLabel: string = '';
  @Input()
  public cameraDistance: number = 3000;
  @Input()
  public cameraDrawingOptions: IDrawingOptions;
  @Input()
  public enableRectangleMarking: boolean = false;
  @Input()
  public multipleSelection = false;
  @Input()
  public rectangleOperationListener: Subject<boolean>;

  @Output()
  public rectangleCreatedEmitter = new EventEmitter<IRectangleSelectedEvent>();
  @Output()
  public rectangleUpdatedEmitter = new EventEmitter<IRectangleSelectedEvent>();
  @Output()
  public rectangleDeletedEmitter = new EventEmitter<IRectangleSelectedEvent>();
  @Output()
  public multipleRectangleDeletedEmitter = new EventEmitter<IMultipleRectangleSelectedEvent>();
  @Output()
  public rectangleSelectionEmitter = new EventEmitter<IRectangleSelectedEvent>();
  @Output()
  public multipleRectangleSelectionEmitter = new EventEmitter<IMultipleRectangleSelectedEvent>();

  public imageUrl: string = '';

  // canvas properties
  public canvasWidth: string = '100%';
  public canvasHeight: string = '100%';

  public zoomStep: number = 0;
  private mouseIsDown: boolean = false;
  private mouseIsMoved: boolean = false;
  private rectangles: Array<IRectangle> = [];
  private x1: number;
  private y1: number;
  private x2: number;
  private y2: number;
  private drawingRectangleIndex: number;

  private newRectanglesCount: number = 0;
  private rotateImg: boolean = false;
  private readonly removeKeyCodes = [46, 8];
  private readonly markKeyCodes = [13];

  constructor(private rectangleService: RectangleService) {
  }

  public ngOnInit(): void {
    if (this.rectangleOperationListener) {
      this.rectangleOperationListener.subscribe((signal: boolean) => {
        if (signal) {
          this.rectangleService.clearMultipleRectanglesSelection();
        }
      });
    }
  }

  public ngOnChanges(): void {
    if (!this.picture) {
      return;
    }

    if (!this.picture.rectangles) {
      this.picture.rectangles = [];
    }

    this.imageUrl = this.picture.url;
    this.rectangles = this.filteredRectangles || this.picture.rectangles;
    this.rectangleService.init(this.picture.url, canvas);
    this.redraw();
  }

  @HostListener('document:keyup', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    const eventType = this.getKeyEventType(event.keyCode);

    if (eventType === 'delete' && this.rectangleCanBeDeleted(event.keyCode)) {
      // Needs to have 2 different flows for this functionality,
      // the multiple selection flow and the single selection flow
      if (this.multipleSelection) {
        this.removeMultipleRectanglesFlow();
      } else {
        this.removeSingleRectangleFlow();
      }
    }

    if (this.enableRectangleMarking && eventType === 'mark') {
      this.markSelectedRectangle();
    }

    this.rectangleService.setSelectedRectangleIndex(undefined);
    this.redraw();
  }

  private removeSingleRectangleFlow(): void {
    const selectedRectangleIndex = this.rectangleService.getSelectedRectangleIndex();
    if (selectedRectangleIndex === undefined) {
      return;
    }

    const {originalIndex, ...rectangle} = this.rectangles[selectedRectangleIndex];
    if (this.rectangles[selectedRectangleIndex].new) {
      this.newRectanglesCount--;
    }
    this.rectangles.splice(selectedRectangleIndex, 1);

    this.rectangleDeletedEmitter.emit({
      value: {
        rectangleIndex: originalIndex || selectedRectangleIndex,
        rectangle: {...rectangle}
      }
    });
  }

  private removeMultipleRectanglesFlow(): void {
    if (this.rectangleService.isMultipleRectangleSelected()) {
      this.multipleRectangleDeletedEmitter.emit({
        value: this.rectangleService.getMultipleSelectedRetangles(this.rectangles)
      });
    }
  }

  private getKeyEventType(keyCode: number): string | boolean {
    if (this.removeKeyCodes.includes(keyCode)) {
      return 'delete';
    }

    if (this.markKeyCodes.includes(keyCode)) {
      return 'mark';
    }

    return false;
  }

  public handleMouseWheelEvent(event: WheelEvent): void {
    if (event.ctrlKey) {
      event.preventDefault();

      const delta = event.deltaY ? -event.deltaY : -event.detail; // fix for FireFox
      const zoomStepPercent = 10;
      if (delta > 0) {
        if (canvas.width >= this.canvasElement.clientWidth + this.canvasElement.clientWidth * zoomStepPercent / 100) {
          this.zoomStep++;
        }
      } else {
        if (this.zoomStep > 0) {
          this.zoomStep--;
        }
      }

      this.canvasWidth = 100 + (zoomStepPercent * this.zoomStep) + '%';
      this.canvasHeight = 100 + (zoomStepPercent * this.zoomStep) + '%';

      this.canvasWrapper.scroll(event.offsetX / 2, event.offsetY / 2);
      this.redraw(event);
    }
  }

  public handleMouseMoveEvent(event: MouseEvent): void {
    if (!this.hasWritePermission) {
      return;
    }

    if (!this.mouseIsDown) {
      this.handleNotCompressedMouseMove(event);
    } else if (!this.rectangleService.getSelectedRectangleVertex()) {
      this.handleDrawingMouseMove(event);
    } else {
      this.handleEditingMouseMove(event);
    }
  }

  public handleMouseDownEvent(event: MouseEvent): void {
    if (!this.hasWritePermission) {
      return;
    }

    this.rectangleService.setSelectedRectangleVertex(this.getRectangleVertex(event));
    this.mouseIsDown = true;
    if (!this.rectangleService.getSelectedRectangleVertex()) {
      const {x, y} = this.getMousePosition(event);
      this.x1 = x;
      this.y1 = y;

      this.drawingRectangleIndex = this.rectangles.length;
    }
  }

  public handleMouseUpEvent(event: MouseEvent): void {
    if (!this.hasWritePermission) {
      return;
    }

    this.mouseIsDown = false;
    this.drawingRectangleIndex = undefined;
    this.rectangleService.setSelectedRectangleIndex(undefined);

    if (this.x1 && this.y1 && this.x2 && this.y2) {
      this.x1 = undefined;
      this.y1 = undefined;
      this.x2 = undefined;
      this.y2 = undefined;
    }

    const selectedRectangleIndex = this.getActiveRectangleIndex(event);
    const selectedRectangleVertex = this.rectangleService.getSelectedRectangleVertex();
    if (!selectedRectangleVertex || selectedRectangleVertex.corner === RectangleVertexTypes.Center) {
      if (selectedRectangleIndex != null) {
        this.rectangleService.setSelectedRectangleIndex(selectedRectangleIndex);

        if (this.rectangles[selectedRectangleIndex].emit) {
          this.rectangles[selectedRectangleIndex].emit = false;
          const {originalIndex, ...rectangle} = this.rectangles[selectedRectangleIndex];
          this.rectangleCreatedEmitter.emit({
            value: {
              rectangleIndex: originalIndex || selectedRectangleIndex,
              rectangle: {...rectangle}
            }
          });
        } else if (this.multipleSelection === true && event.button === 0) {
          // If multiple selection is enabled, should ignore rectangle selection when context menu is called
          this.rectangleService.addSelectedRectangleToList(selectedRectangleIndex);
        }
      }
    } else if (selectedRectangleIndex != null) {
      const {originalIndex, ...rectangle} = this.rectangles[selectedRectangleIndex];
      this.rectangleUpdatedEmitter.emit({
        value: {
          rectangleIndex: originalIndex || selectedRectangleIndex,
          rectangle: {...rectangle}
        }
      });
    }
    this.mouseIsMoved = false;
    this.rectangleService.setSelectedRectangleVertex(undefined);
    this.redraw(event);
  }

  public handleMouseOutEvent(): void {
    this.redraw();
  }

  public handleOpenContextMenu(event: MouseEvent): boolean {
    if (this.multipleSelection === true) {
      this.handleContextMenuMultipleSelectionEvent(event);
    } else {
      this.handleContextMenuSingleSelectionEvent(event);
    }
    return false;
  }

  private handleContextMenuSingleSelectionEvent(event: MouseEvent): void {
    this.rectangleService.setSelectedRectangleVertex(this.getRectangleVertex(event));
    const rectangleIndex = this.rectangleService.getSelectedRectangleIndex();
    const rectangle = this.rectangles[rectangleIndex];
    if (rectangle) {
      this.rectangleSelectionEmitter.emit({
        value: {
          rectangleIndex: rectangle.originalIndex || rectangleIndex,
          rectangle
        },
        position: {
          x: event.clientX,
          y: event.clientY
        }
      });
    }
  }

  private handleContextMenuMultipleSelectionEvent(event: MouseEvent): void {
    if (this.rectangleService.isMultipleRectangleSelected()) {
      this.multipleRectangleSelectionEmitter.emit({
        value: this.rectangleService.getMultipleSelectedRetangles(this.rectangles),
        position: {
          x: event.clientX,
          y: event.clientY
        }
      });
    }
  }

  private get canvasElement(): HTMLElement {
    return <HTMLElement>document.getElementsByTagName('canvas')[0];
  }

  private get canvasWrapper(): HTMLElement {
    return <HTMLElement>this.canvasElement.parentElement;
  }

  private get drawingRectangle(): IRectangle {
    return this.rectangles[this.drawingRectangleIndex] || null;
  }

  private get cameraDistancePixelMultiplier(): number {
    return cameraDistanceMultiplier / this.cameraDistance;
  }

  private rectangleCanBeDeleted(keyCode: number): boolean {
    return this.removeKeyCodes.includes(keyCode) && this.hasWritePermission && document.activeElement.tagName !== 'INPUT';
  }

  private handleNotCompressedMouseMove(event: MouseEvent): void {
    this.rectangleService.setSelectedRectangleVertex(this.getRectangleVertex(event));
    this.rectangleService.setHoveredRectangleIndex(this.getActiveRectangleIndex(event));
    this.redraw(event);
  }

  private handleDrawingMouseMove(event: MouseEvent): void {
    this.mouseIsMoved = true;
    const mousePosition: IPoint = this.getMousePosition(event);
    this.x2 = mousePosition.x;
    this.y2 = mousePosition.y;

    const min_rect = 0;
    const xCorrectOrder = this.x2 >= this.x1 + min_rect;
    const yCorrectOrder = this.y2 >= this.y1 + min_rect;

    if (this.drawingRectangle) {
      this.drawingRectangle[xCorrectOrder ? 'x_max' : 'x_min'] = this.x2 / canvas.width;
      this.drawingRectangle[yCorrectOrder ? 'y_max' : 'y_min'] = this.y2 / canvas.height;
    } else {
      this.addNewRectangle(xCorrectOrder, yCorrectOrder);
    }
    this.redraw(event);
  }

  private addNewRectangle(xCorrectOrder: boolean, yCorrectOrder: boolean): void {
    const newXMin = xCorrectOrder ? this.x1 : this.x2;
    const newXMax = xCorrectOrder ? this.x2 : this.x1;
    const newYMin = yCorrectOrder ? this.y1 : this.y2;
    const newYMax = yCorrectOrder ? this.y2 : this.y1;

    const min_diameter = 10;

    if (newXMin + min_diameter <= newXMax && newYMin + min_diameter <= newYMax) {
      this.rectangles.push({
        origin: 'user',
        x_min: newXMin / canvas.width,
        x_max: newXMax / canvas.width,
        y_min: newYMin / canvas.height,
        y_max: newYMax / canvas.height,
        probability: 1,
        label: this.selectedRectangleLabel,
        generated_by: 'user',
        new: true,
        hidden: false,
        visible: true,
        emit: true
      });
      this.newRectanglesCount++;
    }
  }

  private handleEditingMouseMove(event: MouseEvent): void {
    this.mouseIsMoved = true;
    this.getRectangleVertex(event);
    this.redraw(event);
  }

  private getMousePosition(event: MouseEvent): IPoint {
    return {
      x: event.offsetX * canvas.width / canvas.clientWidth || 0,
      y: event.offsetY * canvas.height / canvas.clientHeight || 0
    };
  }

  public redraw(event?: MouseEvent): void {
    const {
      showCircleScale = false,
      showCircleMeasurement = false,
      showMarked = true,
      showUnmarked = true
    } = this.cameraDrawingOptions || {};

    const options = {
      showCircleScale,
      showCircleMeasurement,
      showMarked,
      showUnmarked,
      rotateImg: this.rotateImg,
      cameraDistancePixelMultiplier: this.cameraDistancePixelMultiplier,
      measurementsAreActive: this.measurementsAreActive
    };

    this.rectangleService.redraw(this.rectangles, options, event ? this.getMousePosition(event) : null);
  }

  private getRectangleVertex(event: MouseEvent): IRectangleVertex {
    if ((!this.rectangleService.getSelectedRectangleVertex() && this.mouseIsDown) || !this.mouseIsDown) {
      return this.getRectangleVertexOnMouseMoving(event);
    }
    if (this.mouseIsDown) {
      return this.getRectangleVertexOnResizing(event);
    }
    return null;
  }

  private getRectangleVertexOnMouseMoving(event: MouseEvent): IRectangleVertex {
    const mousePosition = this.getMousePosition(event);
    for (let i = 0; i < this.rectangles.length; i++) {
      const rectangle: IRectangle = this.rectangles[i];
      const rectangleBoundaryVertexType = this.getMovingRectangleBoundaryVertex(rectangle, mousePosition);
      if (rectangleBoundaryVertexType !== null) {
        return {corner: rectangleBoundaryVertexType, index: i};
      }
      const center = this.rectangleService.getRectangleCenter(rectangle);
      if (Math.abs(center.x - mousePosition.x) <= rectangleConfig.rectangle_center_size
        && Math.abs(center.y - mousePosition.y) <= rectangleConfig.rectangle_center_size) {
        return {corner: RectangleVertexTypes.Center, index: i};
      }
    }
  }

  private getMovingRectangleBoundaryVertex(rectangle: IRectangle, mousePosition: IPoint): RectangleVertexTypes {
    const xPosition = this.getMovingRectanglePosition(rectangle, mousePosition.x, true);
    const yPosition = this.getMovingRectanglePosition(rectangle, mousePosition.y, false);
    if (!xPosition || !yPosition || !RectangleVertexTypes.hasOwnProperty(yPosition + xPosition)) {
      return null;
    }
    return RectangleVertexTypes[yPosition + xPosition];
  }

  private getMovingRectanglePosition(rectangle: IRectangle, mousePosition: number, isXAxis: boolean): 'Left' | 'Right' | 'Top' | 'Bottom' {
    let position: 'Left' | 'Right' | 'Top' | 'Bottom' = null;
    const hoverEdgeSize = rectangleConfig.hover_edge_size;
    const canvasProperty = isXAxis ? canvas.width : canvas.height;
    const coordinateName = isXAxis ? 'x' : 'y';
    if (Math.abs(rectangle[`${coordinateName}_min`] * canvasProperty - mousePosition) <= hoverEdgeSize) {
      position = isXAxis ? 'Left' : 'Top';
    }
    if (Math.abs(rectangle[`${coordinateName}_max`] * canvasProperty - mousePosition) <= hoverEdgeSize) {
      position = isXAxis ? 'Right' : 'Bottom';
    }
    return position;
  }

  private getRectangleVertexOnResizing(event: MouseEvent): IRectangleVertex {
    const editedRectangle = this.rectangles[this.rectangleService.getSelectedRectangleVertex().index];
    this.resizeRectangle(editedRectangle, event);
    if (editedRectangle.generated_by === 'system') {
      editedRectangle.generated_by = 'user_edit';
    }
    return this.rectangleService.getSelectedRectangleVertex();
  }

  private resizeRectangle(rectangle: IRectangle, event: MouseEvent): void {
    const mousePosition = this.getMousePosition(event);
    const mouseX = mousePosition.x;
    const mouseY = mousePosition.y;
    switch (this.rectangleService.getSelectedRectangleVertex().corner) {
      case RectangleVertexTypes.TopLeft:
        rectangle.x_min = this.resizeVertexCoordinate(rectangle, 'x_min', mouseX, 1);
        rectangle.y_min = this.resizeVertexCoordinate(rectangle, 'y_min', mouseY, 1);
        break;
      case RectangleVertexTypes.TopRight:
        rectangle.x_max = this.resizeVertexCoordinate(rectangle, 'x_max', mouseX, -1);
        rectangle.y_min = this.resizeVertexCoordinate(rectangle, 'y_min', mouseY, 1);
        break;
      case RectangleVertexTypes.BottomLeft:
        rectangle.x_min = this.resizeVertexCoordinate(rectangle, 'x_min', mouseX, 1);
        rectangle.y_max = this.resizeVertexCoordinate(rectangle, 'y_max', mouseY, -1);
        break;
      case RectangleVertexTypes.BottomRight:
        rectangle.x_max = this.resizeVertexCoordinate(rectangle, 'x_max', mouseX, -1);
        rectangle.y_max = this.resizeVertexCoordinate(rectangle, 'y_max', mouseY, -1);
        break;
      case RectangleVertexTypes.Center:
        const width = rectangle.x_max * canvas.width - rectangle.x_min * canvas.width;
        const height = rectangle.y_max * canvas.height - rectangle.y_min * canvas.height;

        rectangle.x_min = (mouseX - width / 2) / canvas.width;
        rectangle.y_min = (mouseY - height / 2) / canvas.height;
        rectangle.x_max = (mouseX + width / 2) / canvas.width;
        rectangle.y_max = (mouseY + height / 2) / canvas.height;
        break;
    }
  }

  private resizeVertexCoordinate(rectangle: IRectangle, targetField: string, mouseCoordinate: number, directionSign: number): number {
    const [axisName, pointType] = targetField.split('_');
    const oppositeField = `${axisName}_${pointType === 'max' ? 'min' : 'max'}`;
    const oppositeFieldCoordinate = rectangle[oppositeField];
    const canvasProperty = canvas[axisName === 'x' ? 'width' : 'height'];
    const comparedValue = mouseCoordinate + directionSign * rectangleConfig.min_rectangle_size - oppositeFieldCoordinate * canvasProperty;
    return directionSign * comparedValue <= 0
      ? mouseCoordinate / canvasProperty
      : rectangle[targetField];
  }

  private getActiveRectangleIndex(event: MouseEvent): number {
    const mousePosition = this.getMousePosition(event);
    const mouseX = mousePosition.x;
    const mouseY = mousePosition.y;

    for (let i = 0; i < this.rectangles.length; i++) {
      if (this.rectangles[i].x_min * canvas.width - rectangleConfig.hover_edge_size <= mouseX
        && this.rectangles[i].x_max * canvas.width + rectangleConfig.hover_edge_size >= mouseX
        && this.rectangles[i].y_min * canvas.height - rectangleConfig.hover_edge_size <= mouseY
        && this.rectangles[i].y_max * canvas.height + rectangleConfig.hover_edge_size >= mouseY) {
        return i;
      }
    }

    return null;
  }

  private markSelectedRectangle(): void {
    const selectedIndex: number = this.rectangleService.getSelectedRectangleIndex();
    if (this.rectangles[selectedIndex]) {
      this.rectangles[selectedIndex]['marked'] = !(
        this.rectangles[selectedIndex].hasOwnProperty('marked')
        && this.rectangles[selectedIndex]['marked']
      );
    }
  }
}
