import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, filter, take, takeUntil } from 'rxjs/operators';
import { CameraPeriodScopes, DefaultPeriodScope } from '../../../../../core/constants/camera';
import { IActionWithPayload } from '../../../../../core/models/actionWithPayload';
import { IFromTo } from '../../../../../shared/camera/models/camera';
import { IOptions } from '../../../../../shared/interfaces';
import { getMonthFromDate, getWeekFromDate } from '../../../../../shared/utils/dateFormat';
import { ICropViewState } from '../../../../crop-view/models/crop-view.models';
import { selectCropViewFirstDate, selectCropViewLastDate } from '../../../../crop-view/reducers';
import {
  setCameraDataIsChartActive,
  setCameraDataIsHelpActive,
  setCameraDataIsTableActive
} from '../../../actions/camera-data';
import { ICameraDataSettings, ICameraDataState } from '../../../models/camera-data';
import { selectCameraDataCurrentDateString, selectCameraDataSettings } from '../../../reducers';

@Component({
  selector: 'app-camera-data-toolbar-cropview',
  templateUrl: './camera-data-toolbar-cropview.component.html',
  styleUrls: ['./camera-data-toolbar-cropview.component.scss']
})
export class CameraDataToolbarCropviewComponent implements OnInit, OnDestroy {
  @Output()
  public refresh: EventEmitter<string | IFromTo> = new EventEmitter<string | IFromTo>();
  @Output()
  public exportChart: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public exportTable: EventEmitter<void> = new EventEmitter<void>();

  public stationMinDate$: Observable<moment.Moment>;
  public stationMaxDate$: Observable<moment.Moment>;

  public readonly periodOptions: Array<IOptions> = CameraPeriodScopes;
  public form: FormGroup;
  public rangeArrowMin: moment.Moment = moment();

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private currentDate$: Observable<string | IFromTo>;

  constructor(private formBuilder: FormBuilder,
              private cropviewStore: Store<ICropViewState>,
              private cameraDataStore: Store<ICameraDataState>) {
  }

  public ngOnInit(): void {
    this.createForm();
    this.populateForm();

    this.initCurrentDateChange();
    this.initFirstAndLastDates();
    this.initStatusListeners();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      'settings': this.formBuilder.group({
        'isLastDataMode': [true, [Validators.required]],
        'periodScope': [null, [Validators.required]],
        'fromTo': [null, [Validators.required]],
        'datepicker': [null, [Validators.required]],
        'datepickerStart': [''],
        'datepickerEnd': [''],
      }),
      'activity': this.formBuilder.group({
        'isHelpActive': [false],
        'isChartActive': [true],
        'isTableActive': [true],
      })
    });
  }

  private populateForm(): void {
    this.cameraDataStore.pipe(
      take(1),
      select(selectCameraDataSettings),
      filter((settings: ICameraDataSettings): boolean => !!settings)
    ).subscribe((settings: ICameraDataSettings): void => {
      this.settingsControl.setValue(settings);
    });
  }

  private initCurrentDateChange(): void {
    this.currentDate$ = this.cameraDataStore.pipe(
      select(selectCameraDataCurrentDateString),
      filter((currentDate: string | IFromTo): boolean => !!currentDate)
    );

    this.currentDate$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((currentDate: string | IFromTo): void => {
      if (typeof currentDate === 'string') {
        const date = moment(currentDate);
        this.datepickerEndControl.setValue(date);
        this.datepickerStartControl.setValue(date.subtract(1, 'week'));
      } else {
        this.datepickerEndControl.setValue(moment(currentDate.to * 1000).toDate());
        this.datepickerStartControl.setValue(moment(currentDate.from * 1000).toDate());
      }
    });
  }

  private initFirstAndLastDates(): void {
    this.stationMinDate$ = this.cropviewStore.pipe(
      select(selectCropViewFirstDate),
      filter((date: moment.Moment): boolean => !!date)
    );

    this.stationMaxDate$ = this.cropviewStore.pipe(
      select(selectCropViewLastDate),
      filter((date: moment.Moment): boolean => !!date)
    );

    this.stationMaxDate$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((date: moment.Moment): void => {
      this.fromToControl.setValue({
        from: null,
        to: date
      });
    });

    combineLatest([
      this.currentDate$,
      this.stationMinDate$
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe((result: Array<(string | IFromTo) | moment.Moment>): void => {
      const currentDate = <string | IFromTo>result[0];
      let periodUnit;
      if (typeof currentDate === 'string') {
        periodUnit = currentDate.includes('W') ? 'week' : 'month';
      } else {
        const start = moment(currentDate.from);
        const end = moment(currentDate.to);
        periodUnit = start.diff(end, 'days') > 7 ? 'month' : 'week';
      }
      const stationMinDate: moment.Moment = <moment.Moment>result[1];
      this.rangeArrowMin = stationMinDate.clone().subtract(1, periodUnit);
    });
  }

  private initStatusListeners(): void {
    this.listenStatusChangeAndDispatch(this.isHelpActiveControl, setCameraDataIsHelpActive);
    this.listenStatusChangeAndDispatch(this.isChartActiveControl, setCameraDataIsChartActive);
    this.listenStatusChangeAndDispatch(this.isTableActiveControl, setCameraDataIsTableActive);
  }

  private listenStatusChangeAndDispatch(control: AbstractControl, actionCallback: (status: boolean) => IActionWithPayload): void {
    control.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(200),
    ).subscribe((status: boolean): void => {
      this.cameraDataStore.dispatch(actionCallback(status));
    });
  }

  public onExportChart(): void {
    this.exportChart.emit();
  }

  public onExportTable(): void {
    this.exportTable.emit();
  }

  public onRefresh(): void {
    this.refresh.emit(this.currentDateString);
  }

  private get settingsControl(): AbstractControl {
    return this.form.get('settings');
  }

  public get isLastDataModeControl(): AbstractControl {
    return this.settingsControl.get('isLastDataMode');
  }

  public get periodScopeControl(): AbstractControl {
    return this.settingsControl.get('periodScope');
  }

  public get fromToControl(): AbstractControl {
    return this.settingsControl.get('fromTo');
  }

  public get datepickerStartControl(): AbstractControl {
    return this.settingsControl.get('datepickerStart');
  }

  public get datepickerEndControl(): AbstractControl {
    return this.settingsControl.get('datepickerEnd');
  }

  private get activityControl(): AbstractControl {
    return this.form.get('activity');
  }

  public get isHelpActiveControl(): AbstractControl {
    return this.activityControl.get('isHelpActive');
  }

  public get isChartActiveControl(): AbstractControl {
    return this.activityControl.get('isChartActive');
  }

  public get isTableActiveControl(): AbstractControl {
    return this.activityControl.get('isTableActive');
  }

  private get currentDateString(): string | IFromTo {
    return this.isLastDataModeControl.value
      ? this.currentDateStringFromLastDataMode
      : this.currentDateStringFromSelectedMode;
  }

  private get currentDateStringFromLastDataMode(): string {
    return this.prepareCurrentDateString(this.fromToControl.value.to);
  }

  private prepareCurrentDateString(date: Date): string {
    return this.periodScopeControl.value === DefaultPeriodScope.value
      ? getMonthFromDate(moment(date))
      : getWeekFromDate(moment(date));
  }

  private get currentDateStringFromSelectedMode(): IFromTo {
    return {
      from: moment(this.datepickerStartControl.value).valueOf() / 1000,
      to: moment(this.datepickerEndControl.value).valueOf() / 1000
    };
  }

  public getDate(momentObject: moment.Moment): Date {
    return new Date(momentObject ? momentObject.toString() : null);
  }
}
