import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { setNotify } from '../../../core/actions/notify';
import { getStations } from '../../../core/actions/stations';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { ApiCallService } from '../../../services/api/api-call.service';
import {
  ProductActivationActionTypes,
  setAvailableDiseaseModels,
  setAvailableServiceLicenseStations,
  setAvailableUserStations,
  setProductActivationConfiguration,
  setProductActivationConfigurationItem,
  setProductActivationKey,
  setProductActivationLicenseStatus,
  updateProductActivationConfiguration
} from '../actions/product-activation';
import { LicenseType } from '../constants/constants';
import {
  IDiseaseModelActivation,
  IForecastActivation,
  ILicenseActivationItem,
  IServiceLicenseActivation,
  IValidLicense,
  IVerifyLicense,
  IVirtualStationActivation
} from '../models/product-activation.models';

@Injectable()
export class ProductActivationService {
  constructor(private api: ApiCallService,
              private actions$: Actions) { }

  @Effect()
  public getProductActivationByKey$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ProductActivationActionTypes.GET_PRODUCT_ACTIVATION_BY_KEY),
    switchMap((action: IActionWithPayload) => this.api.getProductByActivationKey(action.payload).pipe(
      switchMap((res: any) => from([
        setProductActivationKey(action.payload),
        setProductActivationConfiguration(res)
      ])),
      catchError(({error}: any) => of(setNotify('This product does not exist')))
    ))
  );

  @Effect()
  public getProductActivationByKeyAndType$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ProductActivationActionTypes.GET_PRODUCT_ACTIVATION_BY_KEY_AND_TYPE),
    switchMap((action: IActionWithPayload) => this.api.getProductByActivationKeyAndType(
      action.payload.productKey,
      action.payload.productType
    ).pipe(
      switchMap((res: any) => from([
        setProductActivationKey(action.payload.productKey),
        setProductActivationConfigurationItem(res)
      ])),
      catchError(({error}: any) => of(setNotify(error.message)))
    ))
  );

  @Effect()
  public getAvailableDiseaseModels$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ProductActivationActionTypes.GET_AVAILABLE_DISEASE_MODELS),
    switchMap((action: IActionWithPayload) => this.api.getAvailableDiseaseModels().pipe(
      switchMap((res: any) => of(setAvailableDiseaseModels(res))),
      catchError(() => of(setNotify('Something went wrong')))
    ))
  );

  @Effect()
  public getAvailableUserStations$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ProductActivationActionTypes.GET_AVAILABLE_USER_STATIONS),
    switchMap((action: IActionWithPayload) => this.api.getAvailableUserStations(action.payload).pipe(
      switchMap((res: any) => of(setAvailableUserStations(res))),
      catchError(() => of(setNotify('Something went wrong')))
    ))
  );

  @Effect()
  public getAvailableServiceLicenseStations$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ProductActivationActionTypes.GET_AVAILABLE_SERVICE_LICENSE_STATIONS),
    switchMap((action: IActionWithPayload) => this.api.getAvailableServiceLicenseStations().pipe(
      switchMap((res: any) => of(setAvailableServiceLicenseStations(res))),
      catchError(() => of(setNotify('Something went wrong')))
    ))
  );

  @Effect()
  public getProductActivationLicenseStatus$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ProductActivationActionTypes.GET_PRODUCT_ACTIVATION_LICENSE_STATUS),
    map((action: IActionWithPayload) => action.payload),
    switchMap((verifyLicense: IVerifyLicense) => this.api.getProductActivationLicenseStatus(verifyLicense).pipe(
      switchMap((res: any) => of(setProductActivationLicenseStatus(verifyLicense.product_pos, res))),
      catchError(({error}: any) => of(setNotify(error.message)))
    ))
  );

  @Effect()
  public saveProductActivationVirtualStation$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ProductActivationActionTypes.SAVE_PRODUCT_ACTIVATION_VIRTUAL_STATION),
    map((action: IActionWithPayload) => action.payload),
    switchMap((activation: IVirtualStationActivation) => this.api.saveVirtualStationActivation(activation).pipe(
      switchMap(() => from([
        updateProductActivationConfiguration(activation),
        getStations(),
        setNotify('Virtual station successfully added')
      ])),
      catchError(({error}: any) => of(setNotify(error.message)))
    ))
  );

  @Effect()
  public saveProductActivationDiseaseModel$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ProductActivationActionTypes.SAVE_PRODUCT_ACTIVATION_DISEASE_MODEL),
    map((action: IActionWithPayload) => action.payload),
    // First it must be checked if there is a license overlap
    switchMap((activation: IDiseaseModelActivation) => this.api.getProductActivationLicenseStatus({
      product_pos: activation.product_pos,
      station_name: activation.station_name,
      license_group: [activation.license_group],
      reference_date: activation.starting_date
    }).pipe(
      switchMap((validLicenses: IValidLicense[]) => {
        if (validLicenses.length > 0) {
          return of(setProductActivationLicenseStatus(activation.product_pos, validLicenses));
        }

        // If there is no license overlapping this product, then it is possible to activate it
        return this.api.saveDiseaseModelActivation(activation).pipe(
          switchMap(() => from([
            setProductActivationLicenseStatus(activation.product_pos, null),
            updateProductActivationConfiguration(activation)
          ])),
          catchError(({error}: any) => of(setNotify(error.message)))
        );
      }),
      catchError(({error}: any) => of(setNotify(error.message)))
    ))
  );

  @Effect()
  public saveProductActivationForecast$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ProductActivationActionTypes.SAVE_PRODUCT_ACTIVATION_FORECAST),
    map((action: IActionWithPayload) => action.payload),
    // First it must be checked if there is a license overlap
    switchMap((activation: IForecastActivation) => this.api.getProductActivationLicenseStatus({
      product_pos: activation.product_pos,
      station_name: activation.station_name,
      license_group: [LicenseType.WEATHER_FORECAST],
      reference_date: activation.starting_date
    }).pipe(
      switchMap((validLicenses: IValidLicense[]) => {
        if (validLicenses.length > 0) {
          return of(setProductActivationLicenseStatus(activation.product_pos, validLicenses));
        }

        // If there is no license overlapping this product, then it is possible to activate it
        return this.api.saveForecastActivation(activation).pipe(
          switchMap(() => from([
            setProductActivationLicenseStatus(activation.product_pos, null),
            updateProductActivationConfiguration(activation)
          ])),
          catchError(({error}: any) => of(setNotify(error.message)))
        );
      }),
      catchError(({error}: any) => of(setNotify(error.message)))
    )),
  );

  @Effect()
  public saveServiceLicenseActivation$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ProductActivationActionTypes.SAVE_PRODUCT_ACTIVATION_SERVICE_LICENSE),
    map((action: IActionWithPayload) => action.payload),
    // First it must be checked if there is a license overlap
    switchMap((activation: IServiceLicenseActivation) => this.api.getProductActivationLicenseStatus({
      product_pos: activation.product_pos,
      station_name: activation.station_name,
      license_group: [LicenseType.SERVICE_LICENSE],
      reference_date: activation.starting_date
    }).pipe(
      switchMap((validLicenses: IValidLicense[]) => {
        if (validLicenses.length > 0) {
          return of(setProductActivationLicenseStatus(activation.product_pos, validLicenses));
        }

        // If there is no license overlapping this product, then it is possible to activate it
        return this.api.saveServiceLicenseActivation(activation).pipe(
          switchMap(() => from([
            setProductActivationLicenseStatus(activation.product_pos, null),
            updateProductActivationConfiguration(activation)
          ])),
          catchError(({error}: any) => of(setNotify(error.message)))
        );
      }),
      catchError(({error}: any) => of(setNotify(error.message)))
    )),
  );

  @Effect()
  public saveDavisIngestActivation$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ProductActivationActionTypes.SAVE_PRODUCT_ACTIVATION_DAVIS_INGEST),
    map((action: IActionWithPayload) => action.payload),
    switchMap((activation: ILicenseActivationItem) => this.api.saveDavisIngestActivation(activation).pipe(
      switchMap(() => from([
        updateProductActivationConfiguration(activation),
        getStations(),
        setNotify('Davis Ingest successfully added')
      ])),
      catchError(({error}: any) => of(setNotify(error.message)))
    ))
  );

  @Effect()
  public saveFarmViewActivation$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ProductActivationActionTypes.SAVE_PRODUCT_ACTIVATION_FARM_VIEW),
    map((action: IActionWithPayload) => action.payload),
    switchMap((activation: ILicenseActivationItem) => this.api.saveFarmViewActivation(activation).pipe(
      switchMap(() => from([
        updateProductActivationConfiguration(activation),
        getStations(),
        setNotify('Farm View successfully added')
      ])),
      catchError(({error}: any) => of(setNotify(error.message)))
    ))
  );

  @Effect()
  public saveIScoutMobileActivation$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ProductActivationActionTypes.SAVE_PRODUCT_ACTIVATION_ISCOUT_MOBILE),
    map((action: IActionWithPayload) => action.payload),
    switchMap((activation: ILicenseActivationItem) => this.api.saveIScoutMobileActivation(activation).pipe(
      switchMap(() => from([
        updateProductActivationConfiguration(activation),
        getStations(),
        setNotify('Mobile Detections successfully added')
      ])),
      catchError(({error}: any) => of(setNotify(error.message)))
    ))
  );
}
