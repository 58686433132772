import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnimalProductionComponent } from './animal-production.component';
import {AnimalProductionResolver} from './animal-production-content.resolver.service';

const routes: Routes = [
  { path: '', component: AnimalProductionComponent, pathMatch: 'full', redirectTo: 'dairy-stress'},
  { path: ':service',
    component: AnimalProductionComponent,
    resolve: { animalProductionResolver: AnimalProductionResolver }}
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
  providers: [AnimalProductionResolver]
})
export class WorkPlanningRoutingModule { }
