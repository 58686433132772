import { IAddCropZone, IAddCropZoneField, IAddCropZoneFieldFarm, ICropZone } from '../models/cropzones';
import { IActionWithPayload } from '../models/actionWithPayload';

export const enum CropZoneActionTypes {
    GET_CROPZONES = '[Account] GET_CROPZONES',
    GET_CROPZONE = '[Account] GET_CROPZONE',
    SET_CROPZONES = '[Account] SET_CROPZONES',
    SET_CROPZONE = '[Account] SET_CROPZONE',
    ADD_DUPLICATED_CROPZONES = '[Account] ADD_DUPLICATED_CROPZONES',
    UPDATE_DUPLICATED_CROPZONES = '[Account] UPDATE_DUPLICATED_CROPZONES',
    ADD_CROPZONE = '[Account] ADD_CROPZONE',
    ADD_CROPZONE_FIELD = '[Account] ADD_CROPZONE_FIELD',
    ADD_CROPZONE_FIELD_FARM = '[Account] ADD_CROPZONE_FIELD_FARM',
    UPDATE_CROPZONE = '[Account] UPDATE_CROPZONE',
    CHANGE_CROPZONE = '[Account] CHANGE_CROPZONE',
    DELETE_CROPZONE = '[Account] DELETE_CROPZONE',
    REMOVE_CROPZONE = '[Account] REMOVE_CROPZONE',
    SET_CROPZONE_ROUTE = '[Account] SET_CROPZONE_ROUTE',
    SET_EXECUTING_ADD_CROPZONE = '[Account] SET_RUNNING_ADD_CROPZONE',
    GET_YPM_CONSTANTS = '[Account] GET_YPM_CONSTANTS',
    SET_YPM_CONSTANTS = '[Account] SET_YPM_CONSTANTS'
}

export function addCropZone(add: IAddCropZone, redirect: boolean): IActionWithPayload {
  return { type: CropZoneActionTypes.ADD_CROPZONE, payload: {add, redirect} };
}

export function getCropZones(): IActionWithPayload {
  return { type: CropZoneActionTypes.GET_CROPZONES, payload: null };
}

export function getCropZone(id: string): IActionWithPayload {
  return { type: CropZoneActionTypes.GET_CROPZONE, payload: id };
}

export function setCropZones(data: Array<ICropZone>): IActionWithPayload {
  return { type: CropZoneActionTypes.SET_CROPZONES, payload: data };
}

export function changeCropzone(cropzone: ICropZone): IActionWithPayload {
  return { type: CropZoneActionTypes.CHANGE_CROPZONE, payload: cropzone };
}

export function setCropZone(data: ICropZone): IActionWithPayload {
  return { type: CropZoneActionTypes.SET_CROPZONE, payload: data };
}

export function addDuplicatedCropzones(cropzones: ICropZone[]): IActionWithPayload {
  return { type: CropZoneActionTypes.ADD_DUPLICATED_CROPZONES, payload: cropzones };
}

export function updateDuplicatedCropzones(cropzones: ICropZone[]): IActionWithPayload {
  return { type: CropZoneActionTypes.UPDATE_DUPLICATED_CROPZONES, payload: cropzones };
}

export function updateCropzone(data: ICropZone): IActionWithPayload {
  return { type: CropZoneActionTypes.UPDATE_CROPZONE, payload: data };
}

export function addCropZoneField(add: IAddCropZoneField, redirect: boolean): IActionWithPayload {
  return { type: CropZoneActionTypes.ADD_CROPZONE_FIELD, payload: {add, redirect} };
}

export function addCropZoneFieldFarm(add: IAddCropZoneFieldFarm, redirect: boolean): IActionWithPayload {
  return { type: CropZoneActionTypes.ADD_CROPZONE_FIELD_FARM, payload: {add, redirect} };
}

export function removeCropzone(remove: ICropZone): IActionWithPayload {
  return { type: CropZoneActionTypes.REMOVE_CROPZONE, payload: remove };
}

export function deleteCropzone(id: string): IActionWithPayload {
  return { type: CropZoneActionTypes.DELETE_CROPZONE, payload: id };
}

export function setCropzoneRoute(path: string): IActionWithPayload {
  return { type: CropZoneActionTypes.SET_CROPZONE_ROUTE, payload: path };
}

export function setExecutingAddCropzone(isRunning: boolean): IActionWithPayload {
  return { type: CropZoneActionTypes.SET_EXECUTING_ADD_CROPZONE, payload: isRunning };
}

export function getYpmConstants(): IActionWithPayload {
  return { type: CropZoneActionTypes.GET_YPM_CONSTANTS, payload: null };
}

export function setYpmConstants(constants: any): IActionWithPayload {
  return { type: CropZoneActionTypes.SET_YPM_CONSTANTS, payload: constants };
}
