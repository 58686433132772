export const NotifyTypes = new Map([
  ['User with this token does not exist', {
    message: 'User with this token does not exist',
    type: 'warning'
  }],
  ['Activation token is missing', {
    message: 'Activation token is missing',
    type: 'warning'
  }],
  ['Account has been successfully activated', {
    message: 'Account has been successfully activated',
    type: 'success'
  }],
  ['Password was updated successfully', {
    message: 'Password was updated successfully',
    type: 'success'
  }],
  ['Error deleting station. Please check your Station ID/Station Key number', {
    message: 'Error deleting station. Please check your Station ID/Station Key number',
    type: 'warning'
  }],
  ['Error adding station. Please check your Station ID/Station Key number', {
    message: 'Error adding station. Please check your Station ID/Station Key number',
    type: 'warning'
  }],
  ['This station has been disabled. Please contact your Metos support.', {
    message: 'This station has been disabled. Please contact your Metos support.',
    type: 'warning'
  }],
  ['Station successfully deleted', {
    message: 'Station successfully deleted',
    type: 'success',
    timer: 2000
  }],
  ['Station successfully added', {
    message: 'Station successfully added',
    type: 'success',
    timer: 2000
  }],
  ['Virtual station successfully added', {
    message: 'Virtual station successfully added',
    type: 'success',
    timer: 2000
  }],
  ['This product does not exist', {
    message: 'This product does not exist',
    type: 'warning',
    timer: 2000
  }],
  ['The product is already activated', {
    message: 'The product is already activated',
    type: 'warning',
    timer: 2000
  }],
  ['Could not add station to user', {
    message: 'Could not add station to user',
    type: 'warning',
    timer: 2000
  }],
  ['Virtual station successfully deleted', {
    message: 'Virtual station successfully deleted',
    type: 'success',
    timer: 2000
  }],
  ['Virtual station does not exist', {
    message: 'Virtual station does not exist',
    type: 'warning',
    timer: 2000
  }],
  ['Could not delete station from user', {
    message: 'Could not delete station from user',
    type: 'warning',
    timer: 2000
  }],
  ['Product key format is incorrect', {
    message: 'Product key format is incorrect',
    type: 'warning',
    timer: 2000
  }],
  ['Product key is already activated', {
    message: 'Product key is already activated',
    type: 'warning',
    timer: 2000
  }],
  ['Dashboard was not saved, due to the error', {
    message: 'Dashboard was not saved, due to the error',
    type: 'warning'
  }],
  ['Something went wrong. Please check your form data',
    {
      message: 'Something went wrong. Please check your form data',
      type: 'warning'
    }],
  ['Notifications were updated successfully',
    {
      message: 'Notifications were updated successfully',
      type: 'success'
    }],
  ['No changes have been made yet. Edit the status before updating.',
    {
      message: 'No changes have been made yet. Edit the status before updating.',
      type: 'warning'
    }],
  ['User updated successfully',
    {
      message: 'User updated successfully',
      type: 'success'
    }],
  ['Web Notifications were activated successfully',
    {
      message: 'Web Notifications were activated successfully',
      type: 'success'
    }],
  ['Entered Username/Email already exist',
    {
      message: 'Entered Username/Email already exist',
      type: 'warning'
    }],
  ['All entered data already exists. Please change form data and try again',
    {
      message: 'All entered data already exists. Please change form data and try again',
      type: 'warning'
    }],
  ['Notifications are currently unavailable',
    {
      message: 'Notifications are currently unavailable',
      type: 'warning'
    }],
  ['Notification was successfully added',
    {
      message: 'Notification was successfully added',
      type: 'success'
    }],
  ['Failed to add a new notification',
    {
      message: 'Failed to add a new notification',
      type: 'warning'
    }],
  ['Notification was successfully updated',
    {
      message: 'Notification was successfully updated',
      type: 'success'
    }],
  ['Failed to update a notification',
    {
      message: 'Failed to update a notification',
      type: 'warning'
    }],
  ['Notification was successfully deleted',
    {
      message: 'Notification was successfully deleted',
      type: 'success'
    }],
  ['Failed to delete a notification',
    {
      message: 'Failed to delete a notification',
      type: 'warning'
    }],
  ['SMS warnings successfully saved.',
    {
      message: 'SMS warnings successfully saved.',
      type: 'success'
    }],
  ['Failed to save sms warning data!',
    {
      message: 'Failed to save sms warning data!',
      type: 'warning'
    }],
  ['The list of phone numbers was saved successfully.',
    {
      message: 'The list of phone numbers was saved successfully.',
      type: 'success'
    }],
  ['There was a problem saving phone numbers!',
    {
      message: 'There was a problem saving phone numbers!',
      type: 'warning'
    }],
  ['Data successfully scheduled for removal. Procedure may take up to 30 minutes',
    {
      message: 'Data successfully scheduled for removal. Procedure may take up to 30 minutes',
      type: 'success'
    }],
  ['Permission denied. Did you provide the correct station key?',
    {
      message: 'Permission denied. Did you provide the correct station key?',
      type: 'warning'
    }],
  ['Dashboard was successfully saved',
    {
      message: 'Dashboard was successfully saved',
      type: 'success'
    }],
  ['No changes have been made yet. Edit the dashboard before saving the changes.',
    {
      message: 'No changes have been made yet. Edit the dashboard before saving the changes.',
      type: 'warning'
    }],
  ['The sensor\'s settings were updated successfully',
    {
      message: 'The sensor\'s settings were updated successfully',
      type: 'success'
    }],
  ['The settings were not saved, due to the error',
    {
      message: 'The settings were not saved, due to the error',
      type: 'warning'
    }],
  ['The rain corrector data was updated successfully',
    {
      message: 'The rain corrector data was updated successfully',
      type: 'success'
    }],
  ['Error in saving the rain corrector data',
    {
      message: 'Error in saving the rain corrector data',
      type: 'warning'
    }],
  ['The rain data were cleared successfully',
    {
      message: 'The rain data were cleared successfully',
      type: 'success'
    }],
  ['The rain data were not cleared, due to the error',
    {
      message: 'The rain data were not cleared, due to the error',
      type: 'warning'
    }],
  ['The model\'s settings were updated successfully',
    {
      message: 'The model\'s settings were updated successfully',
      type: 'success'
    }],
  ['Custom base station name was updated successfully',
    {
      message: 'Custom base station name was updated successfully',
      type: 'success'
    }],
  ['Custom name was updated successfully',
    {
      message: 'Custom name was updated successfully',
      type: 'success'
    }],
  ['Station location saved successfully',
    {
      message: 'Station location saved successfully',
      type: 'success'
    }],
  ['Error saving location',
    {
      message: 'Error saving location',
      type: 'warning'
    }],
  ['Custom name was updated successfully',
    {
      message: 'Custom name was updated successfully',
      type: 'success'
    }],
  ['Something went wrong - custom name was not updated',
    {
      message: 'Something went wrong - custom name was not updated',
      type: 'warning'
    }],
  ['Note was updated successfully',
    {
      message: 'Note was updated successfully',
      type: 'success'
    }],
  ['Error saving note',
    {
      message: 'Error saving note',
      type: 'warning'
    }],
  ['Configuration saved successfully',
    {
      message: 'Configuration saved successfully',
      type: 'success'
    }],
    ['LED trap configuration saved successfully',
    {
      message: 'LED trap configuration saved successfully',
      type: 'success'
    }],
  ['Temperature threshold should be between 12 and 25 degrees',
    {
      message: 'Temperature threshold should be between 12 and 25 degrees',
      type: 'warning',
    }],
  ['Error saving LED trap configuration',
    {
      message: 'Error saving LED trap configuration',
      type: 'warning',
    }],
  ['Error saving station configuration',
    {
      message: 'Error saving station configuration',
      type: 'warning'
    }],
  ['SMS emergency phone number saved successfully',
    {
      message: 'SMS emergency phone number saved successfully',
      type: 'success'
    }],
  ['Error saving SMS emergency number',
    {
      message: 'Error saving SMS emergency number',
      type: 'warning'
    }],
  ['CropView configuration saved successfully',
    {
      message: 'CropView configuration saved successfully',
      type: 'success'
    }],
  ['Error saving CropView configuration',
    {
      message: 'Error saving CropView configuration',
      type: 'warning'
    }],
  ['Camera\'s drawing options were saved',
    {
      message: 'Camera\'s drawing options were saved',
      type: 'success'
    }],
  ['Could not save camera\'s drawing options',
    {
      message: 'Could not save camera\'s drawing options',
      type: 'warning'
    }],
  ['Could not retrieve camera drawing options',
    {
      message: 'Could not retrieve camera drawing options',
      type: 'warning'
    }],
  ['No changes have been made yet',
    {
      message: 'No changes have been made yet',
      type: 'warning'
    }],
  ['Measurements were saved',
    {
      message: 'Measurements were saved',
      type: 'success'
    }],
  ['Measurements were removed',
    {
      message: 'Measurements were removed',
      type: 'success'
    }],
  ['Image was deleted',
    {
      message: 'Image was deleted',
      type: 'success'
    }],
  ['Please, select a valid target',
    {
      message: 'Please, select a valid target',
      type: 'warning'
    }],
  ['Could not retrieve data from server',
    {
      message: 'Could not retrieve data from server',
      type: 'danger'
    }],
  ['Could not save the measurements',
    {
      message: 'Could not save the measurements',
      type: 'warning'
    }],
  ['Could not remove the measurements',
    {
      message: 'Could not remove the measurements',
      type: 'warning'
    }],
  ['Could not delete the selected image',
    {
      message: 'Could not delete the selected image',
      type: 'warning'
    }],
  ['Pest was saved',
    {
      message: 'Pest was saved',
      type: 'success'
    }],
  ['Could not save pest',
    {
      message: 'Could not save pest',
      type: 'warning'
    }],
  ['Pest was removed',
    {
      message: 'Pest was removed',
      type: 'success'
    }],
  ['Could not remove the selected pest',
    {
      message: 'Could not remove the selected pest',
      type: 'warning'
    }],
  ['Pest toggles were saved.',
    {
      message: 'Pest toggles were saved.',
      type: 'success'
    }],
  ['Could not update pest toggle',
    {
      message: 'Could not update pest toggle',
      type: 'warning'
    }],
  ['Could not retrieve pest toggles',
    {
      message: 'Could not retrieve pest toggles',
      type: 'warning'
    }],
  ['Could not retrieve the glue boards',
    {
      message: 'Could not retrieve the glue boards',
      type: 'warning'
    }],
  ['Could not retrieve available dates',
    {
      message: 'Could not retrieve available dates',
      type: 'warning'
    }],
  ['Glue board was created',
    {
      message: 'Glue board was created',
      type: 'success'
    }],
  ['Please, select a target',
    {
      message: 'Please, select a target',
      type: 'warning'
    }],
  ['This target was already added',
    {
      message: 'This target was already added',
      type: 'warning'
    }],
  ['Please, select at least one target',
    {
      message: 'Please, select at least one target',
      type: 'warning'
    }],
  ['Glue board interval cannot overlap',
    {
      message: 'Glue board interval cannot overlap',
      type: 'warning'
    }],
  ['Could not create the glue board',
    {
      message: 'Could not create the glue board',
      type: 'warning'
    }],
  ['Glue board was updated',
    {
      message: 'Glue board was updated',
      type: 'success'
    }],
  ['Glue board interval is overlapping with others',
    {
      message: 'Glue board interval is overlapping with others',
      type: 'warning'
    }],
  ['Could not update the glue board',
    {
      message: 'Could not update the glue board',
      type: 'warning'
    }],
  ['Invalid glue board start date',
    {
      message: 'Invalid glue board start date',
      type: 'warning'
    }],
  ['Glue board exchange is complete',
    {
      message: 'Glue board exchange is complete',
      type: 'success'
    }],
  ['Could not exchange the glue board',
    {
      message: 'Could not exchange the glue board',
      type: 'warning'
    }],
  ['Glue board was removed',
    {
      message: 'Glue board was removed',
      type: 'success'
    }],
  ['Could not remove the glue board',
    {
      message: 'Could not remove the glue board',
      type: 'warning'
    }],
  ['Could not retrieve the seasons',
    {
      message: 'Could not retrieve the seasons',
      type: 'warning'
    }],
  ['Could not retrieve available glue boards',
    {
      message: 'Could not retrieve available glue boards',
      type: 'warning'
    }],
  ['Season was created',
    {
      message: 'Season was created',
      type: 'success'
    }],
  ['Could not save the season.',
    {
      message: 'Could not save the season.',
      type: 'warning'
    }],
  ['Please, select at least one glue board',
    {
      message: 'Please, select at least one glue board',
      type: 'warning'
    }],
  ['Season was updated',
    {
      message: 'Season was updated',
      type: 'success'
    }],
  ['Could not update the season.',
    {
      message: 'Could not update the season.',
      type: 'warning'
    }],
  ['Season was removed',
    {
      message: 'Season was removed',
      type: 'success'
    }],
  ['Could not remove the season',
    {
      message: 'Could not remove the season',
      type: 'warning'
    }],
  ['Must have at least one glue board to the season.',
    {
      message: 'Must have at least one glue board to the season.',
      type: 'warning'
    }],
  ['Camera distance was saved',
    {
      message: 'Camera distance was saved',
      type: 'success'
    }],
  ['Could not save camera distance',
    {
      message: 'Could not save camera distance',
      type: 'warning'
    }],
  ['Could not retrieve camera distance',
    {
      message: 'Could not retrieve camera distance',
      type: 'danger'
    }],
  ['Something went wrong',
    {
      message: 'Something went wrong.',
      type: 'warning'
    }],
  ['Key was copied to clipboard successfully',
    {
      message: 'Key was copied to clipboard successfully',
      type: 'success'
    }],
  ['Initializing MyJohnDeere.',
    {
      message: 'Initializing MyJohnDeere.',
      type: 'success'
    }],
  ['Fetching token from MyJohnDeere.',
    {
      message: 'Fetching token from MyJohnDeere.',
      type: 'success'
    }],
  ['Fetching user from MyJohnDeere.',
    {
      message: 'Fetching user from MyJohnDeere.',
      type: 'success'
    }],
  ['Fetching organizations from MyJohnDeere.',
    {
      message: 'Fetching organizations from MyJohnDeere.',
      type: 'success'
    }],
  ['MyJohnDeere API currently unavailable.',
    {
      message: 'MyJohnDeere API currently unavailable.',
      type: 'warning'
    }],
  ['Failed fetching api catalog from MyJohnDeere.',
    {
      message: 'Failed fetching api catalog from MyJohnDeere.',
      type: 'warning'
    }],
  ['Failed fetching token from MyJohnDeere.',
    {
      message: 'Failed fetching token from MyJohnDeere',
      type: 'warning'
    }],
  ['Failed fetching user from MyJohnDeere.',
    {
      message: 'Failed fetching user from MyJohnDeere.',
      type: 'warning'
    }],
  ['Failed fetching organizations from MyJohnDeere.',
    {
      message: 'Failed fetching organizations from MyJohnDeere.',
      type: 'warning'
    }],
  ['You seem not to have sufficient rights in your MyJohnDeere organization.',
    {
      message: 'You seem not to have sufficient rights in your MyJohnDeere organization.',
      type: 'warning'
    }],
  ['Added station to your MyJohnDeere organization.',
    {
      message: 'Added station to your MyJohnDeere organization.',
      type: 'success'
    }],
  ['Adding station to your MyJohnDeere organization failed.',
    {
      message: 'Adding station to your MyJohnDeere organization failed.',
      type: 'warning'
    }],
  ['Removed station from your MyJohnDeere organization.',
    {
      message: 'Removed station from your MyJohnDeere organization.',
      type: 'success'
    }],
  ['Removing station from your MyJohnDeere organization failed.',
    {
      message: 'Removing station from your MyJohnDeere organization failed.',
      type: 'warning'
    }],
  ['You seem not to be member of any MyJohnDeere organization.',
    {
      message: 'You seem not to be member of any MyJohnDeere organization.',
      type: 'warning'
    }],
  ['Your MyJohnDeere access token has expired.',
    {
      message: 'Your MyJohnDeere access token has expired.',
      type: 'warning'
    }],
  ['MyJohnDeere Token verification failed.',
    {
      message: 'MyJohnDeere Token verification failed.',
      type: 'warning'
    }],
  ['Fetching Token URL from MyJohnDeere failed.',
    {
      message: 'Fetching Token URL from MyJohnDeere failed.',
      type: 'warning'
    }],
  ['Redirecting to MyJohnDeere.',
    {
      message: 'Redirecting to MyJohnDeere.',
      type: 'success'
    }],
  ['MyJohnDeere Token verified.',
    {
      message: 'MyJohnDeere Token verified.',
      type: 'success'
    }],
  ['Your MyJohnDeere access token has been removed.',
    {
      message: 'Your MyJohnDeere access token has been removed.',
      type: 'success'
    }],
  ['No stations to sync',
    {
      message: 'No stations to sync',
      type: 'warning'
    }],
  ['Could not synchronize Xarvio\'s assets',
    {
      message: 'Could not synchronize Xarvio\'s assets',
      type: 'warning'
    }],
  ['Xarvio assets successfully synchronized',
    {
      message: 'Xarvio assets successfully synchronized',
      type: 'success'
    }],
  ['View saved',
    {
      message: 'View saved',
      type: 'success'
    }],
  ['Saving View failed',
    {
      message: 'Saving View failed',
      type: 'warning'
    }],
  ['Deleting View failed',
    {
      message: 'Deleting View failed',
      type: 'warning'
    }],
  ['View deleted',
    {
      message: 'View deleted',
      type: 'success'
    }],
  ['Tracker information was updated successfully',
    {
      message: 'Tracker information was updated successfully',
      type: 'success'
    }],
  ['Error saving tracker configuration',
    {
      message: 'Error saving tracker configuration',
      type: 'warning'
    }],
  ['Machinery information was saved successfully',
    {
      message: 'Machinery information was saved successfully',
      type: 'success'
    }],
  ['Something went wrong - machinery was not saved',
    {
      message: 'Something went wrong - machinery was not saved',
      type: 'warning'
    }],
  ['This station does not have all required sensors or required data sets are not available',
    {
      message: 'This station does not have all required sensors or required data sets are not available',
      type: 'warning'
    }],
  ['Tracker settings were updated successfully',
    {
      message: 'Tracker settings were updated successfully',
      type: 'success'
    }],
  ['No changes have been made yet. Edit the settings before updating',
    {
      message: 'No changes have been made yet. Edit the settings before updating',
      type: 'warning'
    }],
  ['At least one required sensor is missing',
    {
      message: 'At least one required sensor is missing',
      type: 'warning'
    }],
  ['Chart image export failed',
    {
      message: 'Chart image export failed',
      type: 'warning'
    }],
  ['Cropzone successfully added',
    {
      message: 'Cropzone successfully added',
      type: 'success',
      timer: 2000
    }],
  ['Loading cropzone',
    {
      message: 'Loading cropzone...',
      type: 'success',
      timer: 1500
    }
  ],
  ['Error adding cropzone',
    {
      message: 'Error creating cropzone',
      type: 'warning'
    }],
  ['Field and cropzone successfully created',
    {
      message: 'Field and cropzone successfully created',
      type: 'success',
      timer: 2000
    }],
  ['Farm, field and cropzone successfully created',
    {
      message: 'Farm, field and cropzone successfully created',
      type: 'success',
      timer: 2000
    }],
  ['Farm successfully created',
    {
      message: 'Farm successfully created',
      type: 'success',
      timer: 2000
    }],
  ['Error creating farm',
    {
      message: 'Error creating farm',
      type: 'warning'
    }],
  ['Error creating field',
    {
      message: 'Error creating field',
      type: 'warning'
    }],
  ['Field successfully created',
    {
      message: 'Field successfully created',
      type: 'success',
      timer: 2000
    }],
  ['Cropzone successfully deleted',
    {
      message: 'Cropzone successfully deleted',
      type: 'success',
      timer: 2000
    }],
  ['Field successfully deleted',
    {
      message: 'Field successfully deleted',
      type: 'success',
      timer: 2000
    }],
  ['Farm successfully deleted',
    {
      message: 'Farm successfully deleted',
      type: 'success',
      timer: 2000
    }],
  ['Error deleting cropzone. please check your cropzone, field and farm name',
    {
      message: 'Error deleting cropzone. please check your cropzone, field and farm name',
      type: 'warning'
    }],
  ['Error deleting field. please check your field and farm name',
    {
      message: 'Error deleting field. please check your field and farm name',
      type: 'warning'
    }],
  ['Error deleting farm. please check your farm name',
    {
      message: 'Error deleting farm. please check your farm name',
      type: 'warning'
    }],
  ['There is no cropzone with these values. Please select the farm, field and cropzone from the list',
    {
      message: 'There is no cropzone with these values. Please select the farm, field and cropzone from the list',
      type: 'warning'
    }],
  ['Cropzone boundaries successfully specified',
    {
      message: 'Cropzone boundaries successfully specified',
      type: 'success',
      timer: 2000
    }],
  ['Cropzone successfully updated',
    {
      message: 'Cropzone successfully updated',
      type: 'success',
      timer: 2000
    }],
  ['Field successfully updated',
    {
      message: 'Field successfully updated',
      type: 'success',
      timer: 2000
    }],
  ['Farm successfully updated',
    {
      message: 'Farm successfully updated',
      type: 'success',
      timer: 2000
    }],
  ['Failed to store the cropzone, since it has not been drawn on the map',
    {
      message: 'Failed to store the cropzone, since it has not been drawn on the map',
      type: 'warning'
    }],
  ['Irrimet settings successfully saved',
    {
      message: 'Irrimet settings successfully saved',
      type: 'success',
      timer: 2000
    }],
  ['Yield prediction settings successfully saved',
    {
      message: 'Yield prediction settings successfully saved',
      type: 'success',
      timer: 2000
    }],
  ['Previous Yield is successfully saved.',
    {
      message: 'Previous Yield is successfully saved.',
      type: 'success',
      timer: 2000
    }],
  ['Error in saving the previous yield data.',
    {
      message: 'Error in saving the previous yield data.',
      type: 'danger',
      timer: 2000
    }],
  ['Previous Yield is successfully updated.',
    {
      message: 'Previous Yield is successfully updated.',
      type: 'success',
      timer: 2000
    }],
  ['Error in updating the previous yield data.',
    {
      message: 'Error in updating the previous yield data.',
      type: 'danger',
      timer: 2000
    }],
  ['Configuration could not be saved, please fill out all fields (also check within “Advanced Settings”).',
    {
      message: 'Configuration could not be saved, please fill out all fields (also check within “Advanced Settings”).',
      type: 'danger',
      timer: 2000
    }],
  ['Previous yield could not be saved, please fill out all fields.',
    {
      message: 'Previous yield could not be saved, please fill out all fields.',
      type: 'danger',
      timer: 2000
    }],
  ['The date you picked is outside the cropzone’s cultivation period. Please use another date, or go to the ' +
  'cropzone’s general settings and change the cultivation period accordingly.',
    {
      message: 'The date you picked is outside the cropzone’s cultivation period. Please use another date, or go to the ' +
      'cropzone’s general settings and change the cultivation period accordingly.',
      type: 'danger',
      timer: 2000
    }],
  ['Field Capacity must be higher than Wilting Point, please change the values.',
    {
      message: 'Field Capacity must be higher than Wilting Point, please change the values.',
      type: 'danger',
      timer: 2000
    }],
  ['Yield Prediction requires an active Weather Forecast license on at least one of the stations selected in the ' +
  'Yield Prediction settings, but no active Weather Forecast license was found. Please review your Yield Prediction settings.',
    {
      message: 'Yield Prediction requires an active Weather Forecast license on at least one of the stations selected in the ' +
      'Yield Prediction settings, but no active Weather Forecast license was found. Please review your Yield Prediction settings.',
      type: 'danger',
      timer: 8000
    }],
  ['Attention: The predicted crop physiological maturity date deviates largely from your expected harvest date. ' +
    'Make sure your settings are correct, and check the yield prediction results.',
    {
      message: 'Attention: The predicted crop physiological maturity date deviates largely from your expected harvest date. ' +
      'Make sure your settings are correct, and check the yield prediction results.',
      type: 'warning',
      timer: 5000
    }],
  ['Warning: Unable to calculate predicted physiological maturity date. Expected harvest date was used instead.',
    {
      message: 'Warning: Unable to calculate predicted physiological maturity date. Expected harvest date was used instead.',
      type: 'warning',
      timer: 2000
    }],
  ['Warning: Missing station data detected for the selected temperature and/or precipitation sensor! To calculate Yield Prediction, ' +
    'the data gaps were automatically filled with data from historic averages (your station data was not changed.)',
    {
      message: 'Warning: Missing station data detected for the selected temperature and/or precipitation sensor! To calculate Yield ' +
        'Prediction, the data gaps were automatically filled with data from historic averages (your station data was not changed.)',
      type: 'warning',
      timer: 4000
    }],
  ['Attention: The average predicted yield was limited by your setting of best possible average yields. ' +
    'Please review your settings.',
    {
      message: 'Attention: The average predicted yield was limited by your setting of best possible average yields. ' +
        'Please review your settings.',
      type: 'warning',
      timer: 6000
    }],
  ['Settings could not be saved. You must select at least one station with an active weather forecast license.',
    {
      message: 'Settings could not be saved. You must select at least one station with an active weather forecast license.',
      type: 'danger',
      timer: 3000
    }],
  ['Please select a croptype and a data source for rain and ET0 before going to the requested page',
    {
      message: 'Please select a croptype and a data source for rain and ET0 before going to the requested page',
      type: 'warning'
    }],
  ['Please draw your cropzone before going to the requested page',
    {
      message: 'Please draw your cropzone before going to the requested page',
      type: 'warning'
    }],
  ['Soil temp. sensor is missing! For accurate data please upgrade your station with the soil sensor.',
    {
      message: 'Soil temp. sensor is missing! For accurate data please upgrade your station with the soil sensor.',
      type: 'warning'
    }],
  ['Unsupported input data format!',
    {
      message: 'Unsupported input data format!',
      type: 'warning'
    }],
  ['Only the first polygon from the multi polygon is read!',
    {
      message: 'Only the first polygon from the multi polygon is read!',
      type: 'warning'
    }],
  ['Invalid geoJson or shape file!',
    {
      message: 'Invalid geoJson or shape file!',
      type: 'warning'
    }],
  ['Unable to create the farm. There is already a farm with the same name.',
    {
      message: 'Unable to create the farm. There is already a farm with the same name.',
      type: 'warning'
    }],
  ['Unable to create the field. This farm already has a field with this name.',
    {
      message: 'Unable to create the field. This farm already has a field with this name.',
      type: 'warning'
    }],
  ['Unable to create the cropzone. This farm already has a field and cropzone with this name.',
    {
      message: 'Unable to create the cropzone. This farm already has a field and cropzone with this name.',
      type: 'warning'
    }],
  ['Farm and field succesfully created.',
    {
      message: 'Farm and field succesfully created.',
      type: 'success',
      timer: 2000
    }],
  ['Unable to remove farm. There is no farm with this name.',
    {
      message: 'Unable to remove farm. There is no farm with this name.',
      type: 'warning'
    }],
  ['Unable to remove field. There is no farm with this name.',
    {
      message: 'Unable to remove field. There is no farm with this name.',
      type: 'warning'
    }],
  ['Unable to remove field. The selected farm has no field with this name.',
    {
      message: 'Unable to remove field. The selected farm has no field with this name.',
      type: 'warning'
    }],
  ['Unable to remove cropzone. There is no farm with this name.',
    {
      message: 'Unable to remove cropzone. There is no farm with this name.',
      type: 'warning'
    }],
  ['Unable to remove cropzone. The selected farm has no field with this name.',
    {
      message: 'Unable to remove cropzone. The selected farm has no field with this name.',
      type: 'warning'
    }],
  ['Unable to remove cropzone. The selected field has no cropzone with this name.',
    {
      message: 'Unable to remove cropzone. The selected field has no cropzone with this name.',
      type: 'warning'
    }],
  ['The surface area of the boundaries are too big, must be under 25km².',
    {
      message: 'The surface area of the boundaries are too big, must be under 25km².',
      type: 'warning'
    }],
  ['Boundaries are not allowed to have self-intersecting points.',
    {
      message: 'Boundaries are not allowed to have self-intersecting points.',
      type: 'warning'
    }],
  ['Unable to save cropzone. The cultivation period is bigger than a year',
    {
      message: 'Unable to save cropzone. The cultivation period is bigger than a year',
      type: 'warning'
    }],
  ['Soil profile successfully defined',
    {
      message: 'Soil profile successfully defined',
      type: 'success',
      timer: 2000
    }],
  ['FarmBeats settings successfully saved.',
    {
      message: 'FarmBeats settings successfully saved.',
      type: 'success'
    }
  ],
  ['FarmBeats API currently unavailable.',
    {
      message: 'FarmBeats API currently unavailable.',
      type: 'warning'
    }],
  ['FarmBeats asset successfully added.',
    {
      message: 'FarmBeats asset successfully added.',
      type: 'success'
    }],
  ['FarmBeats asset successfully removed.',
    {
      message: 'FarmBeats asset successfully removed.',
      type: 'success'
    }],
  ['FarmBeats setting not valid.',
    {
      message: 'FarmBeats setting not valid.',
      type: 'warning'
    }],
  ['User\'s data was not found in the WeatherLink collection',
    {
      message: 'User\'s data was not found in the WeatherLink collection',
      type: 'warning'
    }],
  ['WeatherLink API is currently unavailable',
    {
      message: 'WeatherLink API is currently unavailable',
      type: 'warning'
    }],
  ['WeatherLink settings were successfully updated',
    {
      message: 'WeatherLink settings were successfully updated',
      type: 'success'
    }],
  ['WeatherLink settings are not valid',
    {
      message: 'WeatherLink settings are not valid',
      type: 'warning'
    }],
  ['No license found',
    {
      message: 'No license found',
      type: 'warning'
    }],
  ['WeatherLink settings were successfully removed',
    {
      message: 'WeatherLink settings were successfully removed',
      type: 'success'
    }],
  ['WeatherLink settings were not found',
    {
      message: 'WeatherLink settings were not found',
      type: 'warning'
    }],
  ['WeatherLink settings were successfully saved',
    {
      message: 'WeatherLink settings were successfully saved',
      type: 'success'
    }],
  ['WeatherLink station was activated',
    {
      message: 'WeatherLink station was activated',
      type: 'success'
    }],
  ['WeatherLink station couldn\'t be activated',
    {
      message: 'WeatherLink station couldn\'t be activated',
      type: 'warning'
    }],
  ['Soil moisture settings successfully saved',
    {
      message: 'Soil moisture settings successfully saved',
      type: 'success',
      timer: 2000
    }],
  ['Can\'t define soil moisture settings',
    {
      message: 'Can\'t define soil moisture settings',
      type: 'warning'
    }],
  ['The geojson file needs to have geographic coordinate reference system (latlon).',
    {
      message: 'The geojson file needs to have geographic coordinate reference system (latlon).',
      type: 'warning'
    }],
  ['Imported file must be a geoJson or zip file.',
    {
      message: 'Imported file must be a geoJson or zip file.',
      type: 'warning'
    }],
  ['The message was successfully sent',
    {
      message: 'The message was successfully sent.',
      type: 'success'
    }],
  ['No license was selected',
    {
      message: 'No license was selected',
      type: 'warning'
    }],
  ['This product does not contain the selected feature',
    {
      message: 'This product does not contain the selected feature',
      type: 'warning'
    }],
  ['Cannot add a new cropzone to this license',
    {
      message: 'Cannot add a new cropzone to this license',
      type: 'warning'
    }],
  ['Error creating cropzone.',
    {
      message: 'Error creating cropzone.',
      type: 'warning'
    }],
  ['Error saving settings. Transfer interval should not be smaller than logging interval.',
    {
      message: 'Error saving settings. Transfer interval should not be smaller than logging interval.',
      type: 'warning'
    }],
  ['Intersections are not allowed when drawing the cropzone boundaries.',
    {
      message: 'Intersections are not allowed when drawing the cropzone boundaries.',
      type: 'warning'
    }],
  ['No boundaries found on the imported zip file.',
  {
    message: 'No boundaries found on the imported zip file.',
    type: 'warning'
  }],
  ['Multiple boundaries detected, click on desired boundary to select.',
  {
    message: 'Multiple boundaries detected, click on desired boundary to select.',
    type: 'warning'
  }],
  ['No boundaries found on the imported geoJson.',
  {
    message: 'No boundaries found on the imported geoJson.',
    type: 'warning'
  }],
  ['The FieldClimate notification was successfully updated',
  {
    message: 'The FieldClimate notification was successfully updated',
    type: 'success'
  }],
  ['Failed to update the FieldClimate notification',
  {
    message: 'Failed to update the FieldClimate notification',
    type: 'warning'
  }],
  ['No boundaries found on the imported geoJson.',
  {
    message: 'The FieldClimate notification was successfully deleted',
    type: 'success'
  }],
  ['Failed to delete the FieldClimate notification',
  {
    message: 'Failed to delete the FieldClimate notification',
    type: 'warning'
  }],
  ['The FieldClimate notification was successfully added',
  {
    message: 'The FieldClimate notification was successfully added',
    type: 'success'
  }],
  ['Sensors have been updated successfully',
  {
    message: 'Sensors have been updated successfully',
    type: 'success'
  }],
  ['Distance between new and old boundaries is too big - irrimet and soil moisture settings are reset',
  {
    message: 'Distance between new and old boundaries is too big - irrimet and soil moisture settings are reset',
    type: 'warning'
  }],
  ['Cultivation period has been adjusted by too many days - irrimet and soil moisture settings are reset',
  {
    message: 'Cultivation period has been adjusted by too many days - irrimet and soil moisture settings are reset',
    type: 'warning'
  }],
  ['Cropzones successfully duplicated',
  {
    message: 'Cropzones successfully duplicated',
    type: 'success'
  }],
  ['Duplicated cropzones successfully updated.',
  {
    message: 'Duplicated cropzones successfully updated.',
    type: 'success'
  }],
  ['Station added to favorites.',
  {
    message: 'Station added to favorites.',
    type: 'success'
  }],
  ['Error: Unsuccessfully added to favorites.',
  {
    message: 'Error: Unsuccessfully added to favorites.',
    type: 'warning'
  }],
  ['Station removed from your favorites.',
  {
    message: 'Station removed from your favorites.',
    type: 'success'
  }],
  ['Cropzone added to your favorites.',
  {
    message: 'Cropzone added to your favorites.',
    type: 'success'
  }],
  ['Error: Unsuccessfully added to favorites.',
  {
    message: 'Error: Unsuccessfully added to favorites.',
    type: 'warning'
  }],
  ['Cropzone removed from your favorites.',
  {
    message: 'Cropzone removed from your favorites.',
    type: 'success'
  }]
]);
