import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { filter, takeWhile, tap } from 'rxjs/operators';
import { environmentToken } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../../../environments/interfaces/theme';
import { BackgroundImages, CustomBackgroundImages } from '../../login.constants';

@Component({
  selector: 'app-auth-background',
  templateUrl: './auth-background.component.html',
  styleUrls: ['./auth-background.component.css', '../../auth.css'],
  animations: [
    trigger('backgroundAnimation', [
      transition('* => animate', [
        query('.back', style({ opacity: 0})),
        query('.first', style({ opacity: 1})),

        query('.back',
          stagger('8000ms', [
            animate('2000ms', style({opacity: 1}))
          ]), {optional: true}),

        query('.item', style({ opacity: 0})),
        query('.first', style({ opacity: 1})),

        query('.last',
          stagger('0ms', [
            animate('8000ms', keyframes([
                style({opacity: '1', offset: 0}),
                style({opacity: '1', offset: 0.75}),
                style({opacity: '0', offset: 1.0})
            ]))
          ]), {optional: true})
      ])
    ]),
    trigger('firstImageAnimation', [
      transition(':enter', [
        style({
          opacity: 0
        }),
        animate('2s ease-in-out', style({
          opacity: 1
        }))
      ])
    ])
  ]
})

export class AuthBackgroundComponent implements OnInit, OnDestroy {
  public state                            : string = '';
  public isLoaded$                        : BehaviorSubject<boolean> = new BehaviorSubject(false);
  public animateImages                    : Array<string> = [];
  public backgroundImages                 : Array<string> = BackgroundImages;
  private alive                           : boolean = true;
  public backLoaded$                      : Subject<number> = new Subject();
  public firstImage                       : string = '';
  public subDomain                        : IThemeConfig;

  constructor(@Inject(environmentToken) environment: IEnvironment) {
    this.subDomain = environment.theme;
  }

  public ngOnInit(): void {
    if (this.subDomain && this.subDomain.hasOwnProperty('background')) {
      this.backgroundImages = [];
      this.backgroundImages.push(this.subDomain.background);
      this.firstImage = (this.backgroundImages[0]);
    }

    if (this.subDomain && this.subDomain.hasOwnProperty('customBackgroundFolder')) {
      this.backgroundImages = CustomBackgroundImages[this.subDomain.customBackgroundFolder];
    }

    if (document.readyState === 'complete') {
      this.firstImage = (this.backgroundImages[0]);
    }

    fromEvent(window, 'load').pipe(
      takeWhile(() => this.alive),
      tap(() => this.isLoaded$.next(true))
    )
    .subscribe(() => this.firstImage = (this.backgroundImages[0]));

    this.isLoaded$.pipe(
      takeWhile(() => this.alive),
      filter(() => this.animateImages.length > 1)
    )
    .subscribe(() => this.state = this.state === 'animate' ? '' : 'animate');

    this.backLoaded$.pipe(
      takeWhile(() => this.alive)
    )
    .subscribe((imgIndex: number) => {
      if (imgIndex === 0) {
        setTimeout(() => this.nextImage(imgIndex), 2000);
      } else {
        this.nextImage(imgIndex);
      }
    });
  }

  private nextImage(imgIndex: number): void {
    if (imgIndex === 1) {
      this.isLoaded$.next(true);
    }
    if (imgIndex < this.backgroundImages.length - 1) {
      this.animateImages.push(this.backgroundImages[imgIndex + 1]);
    }
  }

  public ngOnDestroy(): void {
    this.alive = false;
  }
}
