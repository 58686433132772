import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ChartTranslationsService } from '../../../../core/services/chart-translations.service';
import { ITree } from '../../../../services/tree/models';
import { deepClone } from '../../../../shared/utils/deepClone';
import chartZoomSync from '../../../irrimet/chartZoomSync';
import {
  setDrainageVisibility,
  setHydroponicsCharts,
  setWeightVisibility,
  setWinVisibility,
  setWoutVisibility
} from '../../actions/actions';
import { DEFAULT_CHART_OPTIONS } from '../../constants/constats';
import { IHydroponicsState, IHydroponicsValues } from '../../models/models';
import {
  hydroponicsIsDrainageVisible, hydroponicsIsWeightVisible,
  hydroponicsTree,
  selectHydroponicsChartActive,
  selectHydroponicsCharts
} from '../../selectors/selectors';

@Component({
  selector: 'app-hydroponics-charts',
  templateUrl: './hydroponics-charts.component.html',
  styleUrls: ['./hydroponics-charts.component.css']
})
export class HydroponicsChartsComponent implements OnInit, OnDestroy {

  @Input()
  public response             : any;
  @Input()
  public chartData            : any;
  @Input()
  public type                 : string;
  @Output()
  public hiddenSeries         = new EventEmitter<boolean>();

  public charts               : Array<Chart> = [];
  public chartsOptions        : Array<Options> = [];
  public translatedValues     : IHydroponicsValues;
  public seriesText           : string[] = [];
  public series               : any = [];
  public noData               : boolean = false;
  public isDrainageVisible    : boolean;
  public isWeightVisible      : boolean;
  public chartsSeries         : boolean[] = [true, true, true];
  public isAllChartsDisabled  : boolean = false;
  private tree$               : Observable<Array<ITree>>;

  public isChartActive$       : Observable<boolean>;
  private destroy$            : Subject<boolean> = new Subject<boolean>();

  constructor(
    private route                     : ActivatedRoute,
    private hydroponicsStore          : Store<IHydroponicsState>,
    private chartTranslationsService  : ChartTranslationsService
  ) { }

  public ngOnInit(): void {
    this.chartTranslationsService.translateShortMonths();
    this.translatedValues = this.route.snapshot.data['hydroponicsResolver'];

    this.hydroponicsStore.pipe(
      select(hydroponicsIsDrainageVisible),
      takeUntil(this.destroy$)
    ).subscribe((isVisible: boolean) => {
      this.isDrainageVisible = isVisible;
      if (this.charts.length) {
        this.setChartVisible(1);
      }
    });

    this.hydroponicsStore.pipe(
      select(hydroponicsIsWeightVisible),
      takeUntil(this.destroy$)
    ).subscribe((isVisible: boolean) => {
      this.isWeightVisible = isVisible;
      if (this.charts.length) {
        this.setChartVisible(2);
      }
    });

    this.hydroponicsStore.pipe(
      select(selectHydroponicsCharts),
      take(1)
    ).subscribe((charts: Array<Options>) => {
      if (charts && this.response) {
        this.createCharts();
      }
    });

    this.isChartActive$ = this.hydroponicsStore.pipe(
      takeUntil(this.destroy$),
      select(selectHydroponicsChartActive)
    );

    this.tree$ = this.hydroponicsStore.pipe(
      takeUntil(this.destroy$),
      select(hydroponicsTree)
    );
  }

  private setChartVisible(chartIndex): void {
    let isVisible;
    if (chartIndex === 1) {
      isVisible = this.isDrainageVisible;
    } else if (chartIndex === 2) {
      isVisible = this.isWeightVisible;
    }
    if (isVisible && this.noSerieData(this.series[chartIndex]) === true) {
      this.charts[chartIndex].options.series[0].visible = true;
    } else {
      this.charts[chartIndex].options.series[0].visible = false;
    }

    this.chartsSeries[chartIndex] = this.charts[chartIndex].options.series[0].visible;
  }

  public noSerieData(serie): boolean {
    const maxValue = serie
      .map(data => data[1])
      .reduce((a, b) => Math.max(a, b));
    const minValue = serie
      .map(data => data[1])
      .reduce((a, b) => Math.min(a, b));

    if (maxValue === 0 && minValue < 0) {       // water out values are negative, show them
      return true;
    }
    return !!maxValue;
  }

  private getSeriesData(): void {
    this.series.push(Array(Array(), Array()));
    this.series.push(Array());
    this.series.push(Array());

    if (this.response) {
      this.response.data.forEach((record: any) => {
        this.series[0][0].push([record.date * 1000, record.win]);
        this.series[0][1].push([record.date * 1000, record.wout]);
        this.series[1].push([record.date * 1000, record.drainage]);
        this.series[2].push([record.date * 1000, record.weight]);
      });
    }
  }

  public noSeries(): void {
    this.hiddenSeries.emit(this.noData);
  }

  private createCharts(): void {
    this.charts = [];
    this.chartsOptions = [];
    this.series = [];
    this.createOptions();
    this.getSeriesData();

    this.charts.forEach((chart: Chart, index) => {
      if (index > 0) {
        if (this.charts[index].ref) {
          this.charts[index].ref.series[0].setData(this.series[index]);
        } else {
          this.charts[index].options.series[0].data = this.series[index];
          this.charts[index].options.series[0].visible = !!this.noSerieData(this.series[index]);    // set serie visibility
          if (index === 1) {                                                                        // DRAINAGE chart
            this.charts[index].options.series[0].visible = this.isDrainageVisible;                  // if DRAINAGE chart is enabled in tree
            if (!!this.noSerieData(this.series[index]) === false) {
              this.charts[index].options.series[0].visible = false;
            }
          } else if (index === 2) {
            this.charts[index].options.series[0].visible = this.isWeightVisible;                  // if WEIGHT chart is enabled in tree
            if (!!this.noSerieData(this.series[index]) === false) {
              this.charts[index].options.series[0].visible = false;
            }
          }
        }
      } else {
        if (this.charts[index].ref) {
          this.charts[index].ref.series[0].setData(this.series[0][0]);
          this.charts[index].ref.series[1].setData(this.series[0][1]);
        } else {
          this.charts[index].options.series[0].data = this.series[0][0];
          this.charts[index].options.series[0].visible = !!this.noSerieData(this.series[0][0]);    // set serie visibility
          this.charts[index].options.series[1].data = this.series[0][1];
          this.charts[index].options.series[1].visible = !!this.noSerieData(this.series[0][1]);    // set serie visibility
        }
      }
    });

    this.charts.forEach((chart: Chart, index) => {
      if (index === 0) {
        this.chartsSeries[index] = chart.options.series.every(serie => serie.visible === true);
      } else {
        this.chartsSeries[index] = chart.options.series[0].visible;
      }
      this.chartsOptions[index] = chart.options;
    });

    this.hydroponicsStore.dispatch(setHydroponicsCharts(this.chartsOptions));
    this.noData = this.chartsSeries.every(visible => visible === false);
  }

  private setSeriesVisible(chartIndex: number, seriesNumber: number): boolean {
    let visible = this.charts[chartIndex].options.series[seriesNumber].visible;
    visible = !visible;
    this.charts[chartIndex].options.series[seriesNumber].visible = !this.charts[chartIndex].options.series[seriesNumber].visible;
    if (this.charts[chartIndex].ref) {
      if (visible) {
        this.charts[chartIndex].ref.series[seriesNumber].show();
      } else {
        this.charts[chartIndex].ref.series[seriesNumber].hide();
      }
    }
    return visible;
  }

  private setVisibility(event): void {
    if (event.name === 'Water In')  {
      const visible = this.setSeriesVisible(0, 0);
      this.hydroponicsStore.dispatch(setWinVisibility(visible));
    } else if (event.name === 'Water Out') {
      const visible = this.setSeriesVisible(0, 1);
      this.hydroponicsStore.dispatch(setWoutVisibility(visible));
    } else if (event.name === 'Drainage') {
      const visible = this.setSeriesVisible(1, 0);
      this.hydroponicsStore.dispatch(setDrainageVisibility(visible));
    } else if (event.name === 'Scale high res.') {
      const visible = this.setSeriesVisible(2, 0);
      this.hydroponicsStore.dispatch(setWeightVisibility(visible));
    }
  }

  private createOptions(): void {
    this.charts = chartZoomSync([
      new Chart({
        ...deepClone(DEFAULT_CHART_OPTIONS),
        plotOptions: {
          series: {
            states: {
              hover: {
                enabled: false
              }
            },
            marker: {
              enabled: false
            },
            pointPlacement: 'on',
            events: {
              legendItemClick: (event: any) => {
                event.preventDefault();
                this.setVisibility(event.target);
              }
            }
          }
        },
        yAxis: [
          {
            title: {
              text: this.translatedValues['water']
            },
            showEmpty: false
          }
        ],
        tooltip: {
          useHTML: true,
          xDateFormat: '%Y-%m-%d %H:%M',
          shared: true,
          valueSuffix: ` l`
        },
        series: [
          {
            type: 'column',
            name: this.translatedValues['waterIn'],
            data: [],
            color: '#0000FF',
            yAxis: 0
          } as any,
          {
            type: 'column',
            name: this.translatedValues['waterOut'],
            data: [],
            color: '#FF0000',
            yAxis: 0
          } as any,
        ]
      }),
      new Chart({
        ...deepClone(DEFAULT_CHART_OPTIONS),
        plotOptions: {
          series: {
            states: {
              hover: {
                enabled: false
              }
            },
            marker: {
              enabled: false
            },
            pointPlacement: 'on',
            events: {
              legendItemClick: (event: any) => {
                event.preventDefault();
                this.setVisibility(event.target);
              }
            }
          }
        },
        yAxis: [
          {
            title: {
              text: this.translatedValues['drainageWithUnit'],
            },
            min: 0,
            showEmpty: false
          }
        ],
        tooltip: {
          useHTML: true,
          xDateFormat: '%Y-%m-%d %H:%M',
          shared: true,
          valueSuffix: ` cm³`
        },
        series: [
          {
            type: 'column',
            name: this.translatedValues['drainage'],
            data: [],
            color: '#a8caff',
            yAxis: 0
          } as any,
        ]
      }),
      new Chart({
        ...deepClone(DEFAULT_CHART_OPTIONS),
        plotOptions: {
          series: {
            states: {
              hover: {
                enabled: false
              }
            },
            marker: {
              enabled: false
            },
            pointPlacement: 'on',
            events: {
              legendItemClick: (event: any) => {
                event.preventDefault();
                this.setVisibility(event.target);
              }
            }
          }
        },
        chart: {
          height: 250,
          marginLeft: 85,
          marginRight: 100,
          marginTop: 20,
          style: { fontFamily: 'Helvetica' },
          zoomType: 'x',
          alignTicks: false
        },
        yAxis: [
          {
            title: {
              text: this.translatedValues['weightScaleWithUnit'],
            },
            showEmpty: false
          }
        ],
        tooltip: {
          useHTML: true,
          xDateFormat: '%Y-%m-%d %H:%M',
          shared: true,
          valueSuffix: ` g`
        },
        series: [
          {
            type: 'line',
            name: this.translatedValues['weightScale'],
            data: [],
            color: '#E31A1C',
            yAxis: 0,
          } as any,
        ]
      })
    ]);
  }


  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
