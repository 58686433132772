<app-form-layout [shadow]="false">

  <app-card-header
    [header]="header?.name | translate"
    [description]="''"
    [period]="header?.period"
    [helpButton]="!!header?.help"
    (openModal)="openModal()">
  </app-card-header>

</app-form-layout>

<app-info-modal
  [modalId]="modalId"
  [header]="header?.name | translate">

  <app-animal-production-help-modal
    [help]="header?.help">
  </app-animal-production-help-modal>

</app-info-modal>
