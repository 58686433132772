<app-tree [tree$]="tree$"></app-tree>
<div class="station-models" [@placeContent]="state$ | async">
  <app-station-page-header [pageName]="'Models'"></app-station-page-header>
  <app-loading [state$]="loadingState$"></app-loading>
  <ng-container *ngIf="settings$ | async as settings">
    <div class="alert alert-warning station-models__alert" *ngIf="settings.length === 0 && !(loadingState$ | async).isLoading">
      {{ 'There are no configurable models available for this station.' | translate }}
    </div>
    <app-form-layout class="sensor-node" *ngIf="settings.length > 0">
      <div class="station-models__settings" *ngIf="!(loadingState$ | async).isLoading">
        <table>
          <thead>
            <tr>
              <th>
                {{ 'Name' | translate }}
              </th>
              <th>
                {{ 'Unit' | translate }}
              </th>
              <th>
                {{ 'Color' | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let setting of settings"
              app-model-row
              [setting]="setting"
              (update)="onUpdateClicked($event)"
              >
            </tr>
          </tbody>
        </table>
      </div>
    </app-form-layout>
  </ng-container>
</div>
