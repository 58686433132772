import * as moment from 'moment';
import {DEFAULT_CAMM_ID, WIDE_CAMM_ID, ZOOM_CAMM_ID} from '../../../core/constants/camera';
import {IFromTo} from '../../../shared/camera/models/camera';
import {StationTypes} from '../../../shared/constants';
import {IRequestCameraData, IRequestCameraDataInterval, RequestCameraDataIntervalType} from '../models/camera-data';

export const baseIScoutChartOptions = {
  title: {
    text: 'Insect population/dynamics',
    margin: 50,
  },
  plotOptions: {
    column: {
      stacking: 'normal'
    }
  },
  exporting: {
    enabled: false
  },
  series: [],
  credits: {
    enabled: false
  },
  chart: {
    height: '300',
    marginLeft: 85,
    marginRight: 150,
    style: { fontFamily: 'Helvetica Neue,Helvetica,Arial' },
    zoomType: 'x'
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%d %b %Y'
    },
    ordinal: true
  },
  yAxis: {
    title: {text: 'Detections'}
  }
};

export const baseIScoutGridOptions = {
  columnDefs: [
    {headerName: 'Date/Time', field: 'name'}
  ],
  defaultColDef: {
    editable: false,
    sortingOrder: ['asc', 'desc']
  },
  rowData: [],
  enableColResize: true,
  enableSorting: true,
  rowHeight: 30,
  headerHeight: 28,
  rowSelection: 'single'
};

export const baseCropViewChartOptions = {
  title: {text: 'Fruit growth'},
  plotOptions: {
    column: {
      stacking: 'normal'
    }
  },
  exporting: {
    enabled: false
  },
  series: [
    {name: 'average', type: 'line', data: [], color: '#00FF00'},
    {name: 'min', type: 'line', data: [], color: '#FFA500'},
    {name: 'max', type: 'line', data: [], color: '#800080'}
  ],
  credits: {
    enabled: false
  },
  chart: {
    height: '300',
    marginLeft: 85,
    marginRight: 150,
    marginTop: 20,
    style: {fontFamily: 'Helvetica'},
    zoomType: 'x'
  },
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      day: '%d %b %Y'
    },
    ordinal: true
  },
  yAxis: {
    title: {text: 'mm'}
  }
};

export const baseCropViewGridOptions = {
  columnDefs: [
    {headerName: 'Date/Time', field: 'name'},
    {headerName: 'Average (mm)', field: 'average'},
    {headerName: 'Min (mm)', field: 'min'},
    {headerName: 'Max (mm)', field: 'max'},
  ],
  defaultColDef: {
    editable: false,
    sortingOrder: ['asc', 'desc']
  },
  rowData: [],
  enableColResize: true,
  enableSorting: true,
  rowHeight: 30,
  headerHeight: 28,
  rowSelection: 'single'
};

export enum StationCameraTypes {
  CROPVIEW_ZOOM = 'cropview-zoom',
  CROPVIEW_PANORAMA = 'cropview-panorama',
  CROPVIEW_DUAL = 'cropview-dual',
  ISCOUT = 'iscout'
}

export function getCameraDeviceType(deviceID: number): StationCameraTypes {
  switch (deviceID) {
    case StationTypes.get('CropViewType1'):
      return StationCameraTypes.CROPVIEW_DUAL;
    case StationTypes.get('CropViewType2'):
      return StationCameraTypes.CROPVIEW_ZOOM;
    case StationTypes.get('CropViewType3'):
      return StationCameraTypes.CROPVIEW_PANORAMA;
    case StationTypes.get('IScoutType'):
    case StationTypes.get('IScoutMobileType'):
      return StationCameraTypes.ISCOUT;
    default:
      return null;
  }
}

export function getSelectedCameraId(stationCameraType: string): number {
  switch (stationCameraType) {
    case StationCameraTypes.CROPVIEW_DUAL:
    case StationCameraTypes.CROPVIEW_ZOOM:
      return ZOOM_CAMM_ID;
    case StationCameraTypes.CROPVIEW_PANORAMA:
      return WIDE_CAMM_ID;
    default:
      return DEFAULT_CAMM_ID;
  }
}

export function buildPhotoRequest(
  stationId: string,
  currentDate: string | IFromTo,
  cameraId: number
): IRequestCameraData {
  let interval: IRequestCameraDataInterval = null;
  if (typeof currentDate === 'string') {
    interval = {
      type: RequestCameraDataIntervalType.MONTH,
      value: moment(currentDate).format('YYYY-MM'),
    };
  } else {
    interval = {
      type: RequestCameraDataIntervalType.INTERVAL,
      from: currentDate.from,
      to: currentDate.to
    };
  }

  return {stationId, cameraId, interval};
}
