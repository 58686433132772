import {
  IProductActivationState,
  IProductConfigurationItem,
  IProductConfigurationMap
} from '../models/product-activation.models';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {initialProductActivationState} from '../constants/constants';
import {ProductActivationActionTypes} from '../actions/product-activation';

export function reducer(state: IProductActivationState = initialProductActivationState, action: IActionWithPayload): IProductActivationState {
  switch (action.type) {
    case ProductActivationActionTypes.SET_PRODUCT_ACTIVATION_KEY:
      return {
        ...state,
        productKey: action.payload
      };
    case ProductActivationActionTypes.SET_AVAILABLE_DISEASE_MODELS:
      return {
        ...state,
        availableDiseaseModels: action.payload
      };
    case ProductActivationActionTypes.SET_AVAILABLE_USER_STATIONS:
      return {
        ...state,
        availableUserStations: action.payload
      };
    case ProductActivationActionTypes.SET_AVAILABLE_SERVICE_LICENSE_STATIONS:
      return {
        ...state,
        availableServiceLicenseStations: action.payload
      };
    case ProductActivationActionTypes.SET_PRODUCT_ACTIVATION_LICENSE_STATUS:
      return {
        ...state,
        validLicenses: {
          ...state.validLicenses,
          [action.payload.productPos]: action.payload.validLicenses || []
        }
      };
    case ProductActivationActionTypes.SET_PRODUCT_ACTIVATION_CONFIGURATION:
      const productsArrayToObject = (map: IProductConfigurationMap, current: IProductConfigurationItem) => {
        map[current.pos] = current;
        return map;
      };

      const productConfiguration = action.payload && {
        ...action.payload,
        products: action.payload.products.reduce(productsArrayToObject, {})
      };

      return {
        ...state,
        productConfiguration
      };
    case ProductActivationActionTypes.SET_PRODUCT_ACTIVATION_CONFIGURATION_ITEM:
      return {
        ...state,
        productConfigurationItem: action.payload
      };
    case ProductActivationActionTypes.UPDATE_PRODUCT_ACTIVATION_CONFIGURATION:
      const updatedConfiguration = state.productConfiguration !== null
        ? {
          ...state.productConfiguration,
          products: {
            ...state.productConfiguration.products,
            [action.payload.product_pos]: {
              ...state.productConfiguration.products[action.payload.product_pos],
              activated: true
            }
          }
        } : null;
      return {
        ...state,
        productConfiguration: updatedConfiguration
      };
    default:
      return state;
  }
}
