<div class="station-location">
  <label>{{ 'Station location' | translate }}</label>
  <div *ngIf="!position" class="alert alert-warning p-15 m-t-5">
    <strong>{{ 'Position not set!' | translate }}</strong> {{
    'It seems that the position of this station has not been set. ' +
    'Please specify the position of your station, as this information ' +
    'is very important for the calculations.' | translate }}
  </div>
  <agm-map #stationMap
           [latitude]="position.geo.coordinates[1]"
           [longitude]="position.geo.coordinates[0]"
           [zoom]="10"
           [disableDefaultUI]="true"
           [zoomControl]="true"
           [scrollwheel]="false"
           [streetViewControl]="false"
           [mapTypeId]="'hybrid'"
           [minZoom]="4"
           [mapTypeControl]="true">
    <agm-marker [latitude]="position.geo.coordinates[1]"
                [longitude]="position.geo.coordinates[0]"
                [iconUrl]="getDeviceIcon(id)"
                [markerDraggable]="true"
                (dragEnd)="moveStation($event)"></agm-marker>
  </agm-map>
</div>
