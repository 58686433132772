import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {CropViewComponent} from './containers/crop-view/crop-view.component';
import {CropViewRoutingModule} from './crop-view-routing.module';
import {SharedModule} from '../../shared/shared.module';
import {CropViewContentComponent} from './containers/crop-view-content/crop-view-content.component';
import {TranslateModule} from '@ngx-translate/core';
import {CropViewImageGalleryToolbarComponent} from './containers/crop-view-image-gallery-toolbar/crop-view-image-gallery-toolbar.component';
import {RectangleService} from '../../shared/camera/services/rectangles/rectangle.service';
import {StoreModule} from '@ngrx/store';
import {reducers} from './reducers';
import {EffectsModule} from '@ngrx/effects';
import {CropViewService} from './effects/crop-view.service';
import {CropViewImageSettingsComponent} from './containers/crop-view-image-settings/crop-view-image-settings.component';
import {CropViewHeaderComponent} from './components/crop-view-header/crop-view-header.component';
import {CropViewImageGalleryComponent} from './containers/crop-view-image-gallery/crop-view-image-gallery.component';
import {CropViewImageSettingsToolbarComponent} from './containers/crop-view-image-settings-toolbar/crop-view-image-settings-toolbar.component';

@NgModule({
  imports: [
    CommonModule,
    CropViewRoutingModule,
    TranslateModule.forChild(),
    SharedModule,
    StoreModule.forFeature('cropView', reducers),
    EffectsModule.forFeature([CropViewService]),
    TabsModule.forRoot()
  ],
  declarations: [
    CropViewHeaderComponent,
    CropViewComponent,
    CropViewContentComponent,
    CropViewImageGalleryComponent,
    CropViewImageGalleryToolbarComponent,
    CropViewImageSettingsComponent,
    CropViewImageSettingsToolbarComponent
  ],
  providers: [RectangleService]
})
export class CropViewModule { }
