
import { ICropZone } from '../../../core/models/cropzones';
import { ICropzoneManagementSort, ICropzoneManagementState, IManagementConfig } from '../reducers/cropzoneManagement';
import { IActionWithPayload } from './../../../core/models/actionWithPayload';
import { IFarm } from './../../../core/models/farms';
import { IField } from './../../../core/models/fields';

export const enum CropzoneManagementActionTypes {
  UPDATE_ITEM_TO_EDIT = '[CROPZONE_MANAGEMENT] UPDATE_ITEM_TO_EDIT',
  SAVE_FARM_NAME = '[CROPZONE_MANAGEMENT] SAVE_FARM_NAME',
  SAVE_FIELD_NAME = '[CROPZONE_MANAGEMENT] SAVE_FIELD_NAME',
  SAVE_CROPZONE_NAME = '[CROPZONE_MANAGEMENT] SAVE_CROPZONE_NAME',
  UPDATE_MANAGEMENT_SORT = '[CROPZONE_MANAGEMENT] UPDATE_MANAGEMENT_SORT',
  RENEW_CROPZONES = '[CROPZONE_MANAGEMENT] RENEW_CROPZONES',
  UPDATE_DUPLICATED_CROPZONES = '[CROPZONE_MANAGEMENT] UPDATE_DUPLICATED_CROPZONES',
  SHOW_INFO_MODAL = '[CROPZONE_MANAGEMENT] SHOW_INFO_MODAL',
  GET_MANAGEMENT_CROPS_LAZY_LOADING = '[CROPZONE_MANAGEMENT] GET_MANAGEMENT_CROPS_LAZY_LOADING',
  SET_MANAGEMENT_CROPS_LAZY_LOADING = '[CROPZONE_MANAGEMENT] SET_MANAGEMENT_CROPS_LAZY_LOADING',
  UPDATE_MANAGEMENT_CONFIG = '[CROPZONE_MANAGEMENT] UPDATE_MANAGEMENT_CONFIG',
  SET_MANAGEMENT_STATE = '[CROPZONE_MANAGEMENT] SET_MANAGEMENT_STATE'
}

export function updateManagementConfig(config: IManagementConfig): IActionWithPayload {
  return { type: CropzoneManagementActionTypes.UPDATE_MANAGEMENT_CONFIG, payload: config };
}

export function updateItemToEdit(itemToEdit: any): IActionWithPayload {
  return { type: CropzoneManagementActionTypes.UPDATE_ITEM_TO_EDIT, payload: itemToEdit };
}

export function saveFarmName(farm: IFarm): IActionWithPayload {
  return { type: CropzoneManagementActionTypes.SAVE_FARM_NAME, payload: farm };
}

export function saveFieldName(field: IField): IActionWithPayload {
  return { type: CropzoneManagementActionTypes.SAVE_FIELD_NAME, payload: field };
}

export function saveCropzoneName(cropzone: ICropZone): IActionWithPayload {
  return { type: CropzoneManagementActionTypes.SAVE_CROPZONE_NAME, payload: cropzone };
}

export function updateManagementSort(sort: ICropzoneManagementSort[]): IActionWithPayload {
  return { type: CropzoneManagementActionTypes.UPDATE_MANAGEMENT_SORT, payload: sort };
}

export function renewCropzones(cropzones: ICropZone[]): IActionWithPayload {
  return { type: CropzoneManagementActionTypes.RENEW_CROPZONES, payload: cropzones };
}

export function showInfoModal(res: ICropZone[]): IActionWithPayload {
  return { type: CropzoneManagementActionTypes.SHOW_INFO_MODAL, payload: res };
}

export function getManagementCropsLazyLoading(): IActionWithPayload {
  return {type: CropzoneManagementActionTypes.GET_MANAGEMENT_CROPS_LAZY_LOADING, payload: undefined};
}

export function setManagementCropsLazyLoading(crops: {}[]): IActionWithPayload {
  return {type: CropzoneManagementActionTypes.SET_MANAGEMENT_CROPS_LAZY_LOADING, payload: crops};
}

export function updateDuplicatedCropzones(cropzones: ICropZone[]): IActionWithPayload {
  return {type: CropzoneManagementActionTypes.UPDATE_DUPLICATED_CROPZONES, payload: cropzones};
}

export function setManagementState(state: ICropzoneManagementState): IActionWithPayload {
  return {type: CropzoneManagementActionTypes.SET_MANAGEMENT_STATE, payload: state};
}
