<ng-content select="[slot=header]"></ng-content>

<div class="detailed-image__main">
  <ng-content select="[slot=toolbar]"></ng-content>
  <div class="detailed-image__main__body">
    <div class="image"
         [class.scroll]="zoomStep !== 0">
      <canvas id="canvas"
              class="photo"
              height="2748"
              width="3664"
              appMouseWheel
              [mouseWheelPreventDefault]="false"
              (mouseWheelEventEmitter)="handleMouseWheelEvent($event)"
              (mousemove)="handleMouseMoveEvent($event)"
              (mousedown)="handleMouseDownEvent($event)"
              (mouseup)="handleMouseUpEvent($event)"
              (mouseout)="handleMouseOutEvent()"
              (contextmenu)="handleOpenContextMenu($event)"
              [ngStyle]="{
                  'background-image': 'url(' + imageUrl + ')',
                  width: canvasWidth,
                  height: canvasHeight
              }">
      </canvas>
    </div>
  </div>
</div>

<ng-content select="[slot=footer]"></ng-content>

<div class="detailed-image__bottom">
  {{ 'Station data from' | translate }}
  <span class="date">{{currentDate}}</span>
</div>
