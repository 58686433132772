import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { chartRedraw } from '../../../../shared/utils/chartRedraw';
import { addWidget, removeColumn, setDashboardChanged, updateColumnWidth } from '../../actions/dashboard';
import { IAddWidget, IColumn, IUpdateColumnWidth, IWidget } from '../../models/dashboard.models';
import * as fromDashboard from '../../reducers/dashboard';

@Component({
  selector: 'app-column',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.scss',
              '../../../../../../node_modules/dragula/dist/dragula.css']
})
export class ColumnComponent {
  @Input()
  public columnIndex                     : number;
  @Input()
  public rowIndex                        : number;
  @Input()
  public column                          : IColumn;
  @Input()
  public columnCount                     : number;
  @Input()
  public isEdit                          : boolean;

  public isAddWidget                     : boolean = false;

  constructor(private dashboardStore: Store<fromDashboard.IDashboard>) {}

  public removeCurrentColumn(): void {
    this.dashboardStore.dispatch(removeColumn([this.rowIndex, this.columnIndex]));
    this.dashboardStore.dispatch(setDashboardChanged(true));
    chartRedraw(500);
  }

  public columnClass(): string {
    switch (this.columnCount) {
      case 1: {
        return 'col-md-12';
      }
      case 3: {
        return 'col-md-4';
      }
      case 2: {
        return `col-md-${this.column.width}`;
      }
      default: {
        return '';
      }
    }
  }

  public startAddWidget(): void {
    this.isAddWidget = true;
  }

  public endAddWidget(): void {
    this.isAddWidget = false;
  }

  public addWidgetToStore(widget: IWidget): void {
    const addWidgetObj: IAddWidget = {
      path: [this.rowIndex, this.columnIndex],
      widget: widget
    };
    this.dashboardStore.dispatch(addWidget(addWidgetObj));
    this.dashboardStore.dispatch(setDashboardChanged(true));
  }

  public resize(plus: boolean): void {
    let updateColumnsWidth : IUpdateColumnWidth;
    let newColumnWidth    : number;
    const columnsWidth    : Array<number> = [];
    const siblingId       : number = Math.abs(this.columnIndex - 1);

    if (plus) {
      if (this.column.width >= 8) {
        return;
      }
      newColumnWidth = this.column.width + 1;
      columnsWidth[this.columnIndex] = newColumnWidth;
      columnsWidth[siblingId] = 12 - newColumnWidth;
      updateColumnsWidth = {
        path: [this.rowIndex, this.columnIndex],
        width: columnsWidth
      };
    } else {
      if (this.column.width <= 4) {
        return;
      }
      newColumnWidth = this.column.width - 1;
      columnsWidth[this.columnIndex] = newColumnWidth;
      columnsWidth[siblingId] = 12 - newColumnWidth;
      updateColumnsWidth = {
        path: [this.rowIndex, this.columnIndex],
        width: columnsWidth
      };
    }

    this.dashboardStore.dispatch(updateColumnWidth(updateColumnsWidth));
    this.dashboardStore.dispatch(setDashboardChanged(true));
    chartRedraw();
  }
}
