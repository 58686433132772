import { Component, Input, OnInit } from '@angular/core';
import { IPhone } from '../../../../../core/models/stations';

@Component({
  selector: 'app-warnings-phone-list',
  templateUrl: './warnings-phone-list.component.html',
  styleUrls: ['./warnings-phone-list.component.scss']
})
export class WarningsPhoneListComponent implements OnInit {
  @Input()
  public phones       : IPhone[];

  constructor() { }

  public ngOnInit(): void {
  }

}
