import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { ILoggingAndTransferState, ILoggingIsDefault } from '../reducers/loggingAndTransfer';
import { IAdvancedSettings, ILoggingSave } from '../models/station-config.models';

export const enum LoggingAndTransferActionTypes {
  SET_LOGGING_AND_TRANSFER = '[Config] SET_LOGGING_AND_TRANSFER',
  SET_MEASURMENT_INTERVAL  = '[Config] SET_MEASURMENT_INTERVAL',
  SET_LOGGING_INTERVAL  = '[Config] SET_LOGGING_INTERVAL',
  SET_TRANSFER_INTERVAL  = '[Config] SET_TRANSFER_INTERVAL',
  SET_SCHEDULER  = '[Config] SET_SCHEDULER',
  SET_ADVANCED = '[Config] SET_ADVANCED',
  SET_SHOW_ADVANCED = '[Config] SET_SHOW_ADVANCED',
  SET_IS_DEFAULT = '[Config] SET_IS_DEFAULT',
  SAVE_LOGGING = '[Config] SAVE_LOGGING',
}

export function setMeasurementInterval(measurementInterval: number): IActionWithPayload {
  return { type: LoggingAndTransferActionTypes.SET_MEASURMENT_INTERVAL, payload: measurementInterval };
}

export function setLoggingAndTransfer(loggingAndTransfer: ILoggingAndTransferState): IActionWithPayload {
  return { type: LoggingAndTransferActionTypes.SET_LOGGING_AND_TRANSFER, payload: loggingAndTransfer };
}

export function setLoggingInterval(loggingInterval: number): IActionWithPayload {
  return { type: LoggingAndTransferActionTypes.SET_LOGGING_INTERVAL, payload: loggingInterval };
}

export function setTransferInterval(transferInterval: number): IActionWithPayload {
  return { type: LoggingAndTransferActionTypes.SET_TRANSFER_INTERVAL, payload: transferInterval };
}

export function setScheduler(scheduler: string[][]): IActionWithPayload {
  return { type: LoggingAndTransferActionTypes.SET_SCHEDULER, payload: scheduler };
}

export function setAdvanced(advanced: IAdvancedSettings): IActionWithPayload {
  return { type: LoggingAndTransferActionTypes.SET_ADVANCED, payload: advanced };
}

export function setShowAdvanced(isShow: boolean): IActionWithPayload {
  return { type: LoggingAndTransferActionTypes.SET_SHOW_ADVANCED, payload: isShow };
}

export function setIsDefault(defaults: ILoggingIsDefault): IActionWithPayload {
  return { type: LoggingAndTransferActionTypes.SET_IS_DEFAULT, payload: defaults };
}

export function saveLogging(save: ILoggingSave): IActionWithPayload {
  return { type: LoggingAndTransferActionTypes.SAVE_LOGGING, payload: save };
}
