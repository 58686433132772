<div class="more-options"
     appOutClick
     (outClick)="close.emit()"
     (click)="close.emit()">

  <p (click)="addDashboard()"><i class="zmdi zmdi-plus"></i>{{ 'Add a dashboard' | translate }}</p>

  <hr *ngIf="(dashboards | async).length">

  <p (click)="changeDashboard(dashboard)"
     *ngFor="let dashboard of dashboards | async">
    <i class="zmdi zmdi-equalizer"></i>{{ dashboard.title }}
  </p>

  <hr>
  <p (click)="addColumns.emit(1)"><i class="zmdi zmdi-collection-item-1"></i>{{ 'Add a 1 column container' | translate }}</p>
  <p (click)="addColumns.emit(2)"><i class="zmdi zmdi-collection-item-2"></i>{{ 'Add a 2 column container' | translate }}</p>
  <p (click)="addColumns.emit(3)"><i class="zmdi zmdi-collection-item-3"></i>{{ 'Add a 3 column container' | translate }}</p>
  <hr>

  <p (click)="restore.emit()"><i class="zmdi zmdi-refresh-alt"></i>{{ 'Restore last saved' | translate }}</p>
  <p (click)="setDefault.emit()"><i class="zmdi zmdi-swap"></i>{{ subdomain$ | async | translate }}</p>

</div>
