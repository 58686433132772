import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductActivationComponent } from './containers/product-activation/product-activation.component';

const routes: Routes = [
  { path: '', component: ProductActivationComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class ProductActivationRoutingModule { }
