import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import * as fromNavigationStation from '../../../../core/reducers/navigation-station';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { IOptions } from '../../../../shared/interfaces';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';
import { ITreeSettingsState } from '../../../../shared/tree/models/tree.models';
import { dateToUtcUnixTimestamp, getDaysFromPeriodValue } from '../../../../shared/utils/dateFormat';
import { getStationDataFc, setStationDataError, setStationDataLoading } from '../../actions/station-data';
import {
  setStationChartActive,
  setStationDataSettings,
  setStationTableActive
} from '../../actions/station-data-settings';
import { DefaultPeriodScope, PeriodValuesByScope } from '../../constants/constants';
import { IGetStationDataFcRequest, IStationDataProfile } from '../../models/station-data.models';
import {
  selectStationDataProfiles, selectStationDataSettings,
  selectStationExportActive,
  selectTreeSettings
} from '../../reducers';
import * as fromStationData from '../../reducers/station-data';
import * as fromStationSettings from '../../reducers/station-data-settings';
import { IStationDataSettingsState } from '../../reducers/station-data-settings';

@Component({
  selector: 'app-station-data-toolbar',
  templateUrl: './station-data-toolbar.component.html',
  styleUrls: ['./station-data-toolbar.component.scss']
})

export class StationDataToolbarComponent implements OnInit, OnDestroy {
  @Output()
  private exportChartImg          : EventEmitter<boolean> = new EventEmitter<boolean>();
  public station                  : IStation;
  public fromStation              : Date = null;
  public toStation                : Date = null;
  public periodScopeSelectItems   : Array<IOptions> = Object.keys(PeriodValuesByScope).map((item: string) => ({
    content : item,
    value   : item
  }));

  public periodValueSelectItems   : Array<IOptions> = PeriodValuesByScope[DefaultPeriodScope];
  public periodForm               : FormGroup;
  public selectedPeriodValue      : string = null;
  public hideTime                 : boolean = false;

  private profile                 : string;
  private destroy$                : Subject<boolean> = new Subject<boolean>();
  private initDestroy$            : Subject<boolean> = new Subject<boolean>();
  private isDatepickerRequest     : boolean = false;

  constructor(
    private formBuilder           : FormBuilder,
    private exportService         : StationDataExportService,
    private api                   : ApiCallService,
    private navigationStore       : Store<fromNavigationStation.INavigationStationState>,
    private stationDataStore      : Store<fromStationData.IStationDataState>,
    private stationSettingsStore  : Store<fromStationSettings.IStationDataSettingsState>,
    private treeSettingStore      : Store<ITreeSettingsState>
  ) { }

  private get period(): AbstractControl {
    return this.periodForm.get('period');
  }

  private get activity(): AbstractControl {
    return this.periodForm.get('activity');
  }

  public get fromDatepicker(): AbstractControl {
    return this.period.get('fromDatepicker');
  }

  public get toDatepicker(): AbstractControl {
    return this.period.get('toDatepicker');
  }

  public get isTableActive(): AbstractControl {
    return this.activity.get('isTableActive');
  }

  public get isChartActive(): AbstractControl {
    return this.activity.get('isChartActive');
  }

  public get isLastDataMode(): AbstractControl {
    return this.period.get('isLastDataMode');
  }

  public get periodScope(): AbstractControl {
    return this.period.get('periodScope');
  }

  public get periodValue(): AbstractControl {
    return this.period.get('periodValue');
  }

  public get fromTo(): AbstractControl {
    return this.period.get('fromTo');
  }

  public get stationId(): AbstractControl {
    return this.period.get('stationId');
  }

  public get isExportImageActive(): AbstractControl {
    return this.activity.get('isExportImageActive');
  }

  public get isExportActive(): AbstractControl {
    return this.activity.get('isExportActive');
  }

  public startExportXLS(): void {
    this.exportService.startExportXLS(this.stationId.value);
  }

  public startExportImage(): void {
    if (this.isExportImageActive.value) {
      return;
    } else {
      this.isExportImageActive.setValue(true);
    }

    this.exportChartImg.emit(true);
    setTimeout(() => {
      this.exportChartImg.emit(false);
      this.isExportImageActive.setValue(false);
    }, 500);
  }

  public ngOnInit(): void {
    this.periodForm = this.formBuilder.group({
      'period': this.formBuilder.group({
        'periodScope': ['hourly', [Validators.required]],
        'periodValue': ['2d', [Validators.required]],
        'fromTo': [null, [Validators.required]],
        'fromDatepicker': [null, [Validators.required]],
        'toDatepicker': [null, [Validators.required]],
        'stationId': ['', [Validators.required]],
        'isLastDataMode': [true, [Validators.required]]
        }),
      'activity': this.formBuilder.group({
        'isChartActive': [true, [Validators.required]],
        'isTableActive': [true, [Validators.required]],
        'isExportImageActive': [false, [Validators.required]],
        'isExportActive': [false, [Validators.required]],
      })
    });

    this.stationSettingsStore.pipe(
      takeUntil(this.initDestroy$),
      select(selectStationDataSettings)
    ).subscribe((settings: IStationDataSettingsState) => {
      this.initDestroy$.next(true);
      this.periodForm.setValue({
        period: settings.period,
        activity: settings.activity
      });
    });

    this.subscribeToTreeAndSelectedStation();

    this.periodScope.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged()
    )
    .subscribe((scope: string) => {
      this.periodValueSelectItems = PeriodValuesByScope[scope];
      const periodValue = this.selectedPeriodValue ? this.selectedPeriodValue : this.periodValueSelectItems[1].value;
      setTimeout(() => this.periodValue.setValue(periodValue), 0);
      this.removeTime();
    });

    this.isChartActive.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      debounceTime(10)
    ).subscribe((isActive: boolean) => {
      this.stationSettingsStore.dispatch(setStationChartActive(isActive));
    });

    this.stationSettingsStore.pipe(
      takeUntil(this.destroy$),
      select(selectStationExportActive)
    ).subscribe((isActive: boolean) => {
      this.isExportActive.setValue(isActive);
    });

    this.isTableActive.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      debounceTime(10)
    ).subscribe((isActive: boolean) => {
      this.stationSettingsStore.dispatch(setStationTableActive(isActive));
    });
  }

  public setDateRange(): void {
    if (this.isDatepickerRequest && !this.isLastDataMode.value) {
      this.fromTo.setValue({
        from: this.fromDatepicker.value,
        to: this.toDatepicker.value
      });
    }
  }

  public refresh(): void {
    if (this.periodForm.invalid) {
      return;
    }
    this.isDatepickerRequest = !this.isLastDataMode.value;
    this.stationSettingsStore.dispatch(setStationDataSettings(this.periodForm.value));

    const request: IGetStationDataFcRequest = {
      from: null,
      to: null,
      stationId: this.stationId.value,
      timePeriod: this.periodScope.value,
      name: this.profile
    };

    if (this.isLastDataMode.value) {
      request.from = this.setTime(this.fromTo.value.from);
      request.to = this.setTime(this.fromTo.value.to);
    } else {
      request.from = this.setTime(this.fromDatepicker.value);
      request.to = this.setTime(this.toDatepicker.value);
    }
    this.stationDataStore.dispatch(setStationDataLoading(true));
    this.stationDataStore.dispatch(setStationDataError(false));
    this.stationDataStore.dispatch(getStationDataFc(request));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.initDestroy$.next(true);
    this.initDestroy$.complete();
  }

  private subscribeToTreeAndSelectedStation(): void {
    combineLatest([
      this.getSelectedStationObservable(),
      this.getTreeSettingsObservable(),
      this.stationDataStore.pipe(select(selectStationDataProfiles))
    ])
      .subscribe(([station, tree, profiles]: [IStation, ITreeSettingsState, IStationDataProfile[]]) => {
        const profileWasChanged: boolean = !!tree[station.name.original] && this.profile !== tree[station.name.original].selectedProfile;
        this.fromStation = moment(station.dates.min_date).toDate();
        this.toStation = moment(station.dates.max_date).toDate();
        if (station.name.original === this.stationId.value && !profileWasChanged) {
          return;
        }
        this.stationId.setValue(station.name.original);
        setTimeout(() => {
          if (!this.station ||  station.name.original !== this.station.name.original) {
            this.fromDatepicker.setValue(moment(this.toStation).subtract(2, 'day').toDate());
            this.toDatepicker.setValue(moment(this.toStation).toDate());
            this.fromTo.setValue({
              from: moment(this.toStation).subtract(2, 'day').toDate(),
              to: this.toStation
            });
            this.periodValue.setValue('2d');
          }
          if (profileWasChanged) {
            this.profile = tree[station.name.original].selectedProfile;
            const profile = profiles.find(p => p.name === this.profile);
            if (profile) {
              this.selectedPeriodValue = profile.period;
              const daysFromPeriod = getDaysFromPeriodValue(this.selectedPeriodValue);
              this.fromDatepicker.setValue(moment(this.toStation).subtract(daysFromPeriod, 'day').toDate());
              this.toDatepicker.setValue(moment(this.toStation).toDate());
              this.periodValue.setValue(profile.period);
              this.fromTo.setValue({
                from: moment(this.toStation).subtract(daysFromPeriod, 'day').toDate(),
                to: this.toStation
              });
              if (this.isLastDataMode.value) {
                this.periodScope.setValue(profile.resolution);
              }
            }
          }
          setTimeout(() => this.refresh(), 500);
          this.station = station;
        });
      });
  }

  private getSelectedStationObservable(): Observable<IStation> {
    return this.navigationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((s: IStation) => !!s)
    );
  }

  private getTreeSettingsObservable(): Observable<ITreeSettingsState> {
    return this.treeSettingStore.pipe(
      takeUntil(this.destroy$),
      select(selectTreeSettings)
    );
  }

  private removeTime(): void {
    if (['monthly', 'daily'].includes(this.periodScope.value)) {
      this.hideTime = true;
    } else {
      this.hideTime = false;
    }
  }

  private setTime(date: Date): any {
    if (this.hideTime) {
      return dateToUtcUnixTimestamp(new Date(new Date(date).setHours(0, 0, 0, 0)));
    } else {
      return dateToUtcUnixTimestamp(date);
    }
  }
}
