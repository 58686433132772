import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-station-notes-form',
  templateUrl: './station-notes-form.component.html',
  styleUrls: ['./station-notes-form.component.scss']
})
export class StationNotesFormComponent implements OnInit, OnChanges {
  @Input()
  public isReadOnly              : boolean;
  @Input()
  private noteValue              : string = '';
  @Output()
  private saveNote               : EventEmitter<string> = new EventEmitter<string>();

  public noteForm                : FormGroup;

  constructor(private fb: FormBuilder) { }

  public ngOnInit(): void {
    this.noteForm = this.fb.group({
      'note': [this.noteValue, []],
    });
  }

  public emitSaveNote(): void {
    this.saveNote.emit(this.note.value);
  }

  public get note(): AbstractControl {
    return this.noteForm.get('note');
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.noteForm) {
      this.note.setValue(this.noteValue);
    }
  }

}
