import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControlOptions, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ISelectedSearchWidgetItem } from '../../../../core/models/selectedSearchWidgetItem';
import { selectSelectedStation } from '../../../../core/reducers';
import * as fromSelectedStation from '../../../../core/reducers/selectedStation';
import { ITopologyItem, ITopologySensorData } from '../../../../services/tree/models';
import { IOptions, ISensor } from '../../../../shared/interfaces';
import { ITreeSettingsState } from '../../../../shared/tree/models/tree.models';
import { deleteView, postView, putView } from '../../actions';
import {
  PlotbandModeType, SensorGroupType, SENSOR_GROUPS, ViewAggregationType,
  ViewEditorMode, ViewOperations, ViewOperationType, ViewType
} from '../../constants';
import {
  IAggregate, IOptionsMap, IPawParameter,
  IPawSensor, IView
} from '../../models';
import {
  activitySettings, soilMoistureSensors, status,
  topology as topologySelector, treeSettings, views as viewsSelector
} from '../../selectors';
import { defaultPredefinedViews, IStateData, IStateStatus } from '../../states';

@Component({
  selector: 'app-soil-moisture-view-editor',
  templateUrl: './soil-moisture-view-editor.component.html',
  styleUrls: ['./soil-moisture-view-editor.component.scss']
})
export class SoilMoistureViewEditorComponent implements OnInit, OnDestroy {

  public isActive$:             Observable<boolean>;
  public status$:               Observable<IStateStatus>;
  public mode:                  ViewEditorMode = ViewEditorMode.CREATE;

  public form:                  FormGroup;

  public selectedOperation:     ViewOperationType = ViewOperationType.AVG;
  public selectedSensorTypes:   IOptions[] = [];
  public selectedSensorGroup:   IOptions[] = [];

  public OperationType =        ViewOperationType;
  public EditorMode =           ViewEditorMode;

  public operations =           ViewOperations;

  public sensorTypes:           IOptionsMap = Object.keys(ViewOperationType).reduce((o, k) => {
                                  o[ViewOperationType[k]] = [];
                                  return o;
                                }, {});

  public sensorGroups:          IOptionsMap = Object.keys(SensorGroupType).reduce((o, k) => {
                                  o[SensorGroupType[k]] = [];
                                  return o;
                                }, {});

  public sensorGroupsForm:      {[key: string]: FormArray } = Object.keys(SensorGroupType).reduce((o, k) => {
                                  o[SensorGroupType[k]] = new FormArray([]);
                                  return o;
                                }, {});

  public profileName:           string = defaultPredefinedViews[0].name;
  public hasSensors:            boolean = true;

  private destroy$:             Subject<boolean> = new Subject<boolean>();
  private selectedSensors:      IOptionsMap = {};
  private stationId:            string;
  private profile:              string = defaultPredefinedViews[0].name;
  private views:                IView[] = [];
  private treeSettings:         ITreeSettingsState = {};

  constructor(
    private dataStore: Store<IStateData>,
    private treeSettingsStore: Store<ITreeSettingsState>,
    private stationStore: Store<fromSelectedStation.ISelectedStationState>,
    private formBuilder: FormBuilder,
    private translate: TranslateService
  ) { }

  public ngOnInit(): void {

    this.isActive$ = this.dataStore.pipe(select(activitySettings, 'isViewEditorActive'), debounceTime(200));
    this.status$ = this.dataStore.pipe(select(status), debounceTime(100));

    this.form = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      operation: [this.selectedOperation, [Validators.required]],
      sensorType: ['', [Validators.required]],
      refillPoint: [50, [Validators.required, () => {
          if (this.selectedOperation !== ViewOperationType.PAW) {
            return null;
          }
          return null;
        }]
      ],
      fullPoint: [100, [Validators.required, () => {
        if (this.selectedOperation !== ViewOperationType.PAW) {
          return null;
        }
        return null;
      }]
      ],
      sensors: this.formBuilder.array([])
    }, {
      validator: (form: FormGroup) => {
        const forms = form.controls.sensors as FormArray;
        if (this.selectedOperation === ViewOperationType.PAW) {
          const rp = +form.controls.refillPoint.value;
          const fp = +form.controls.fullPoint.value;
          if (rp < 0 || rp >= fp) {
            return { fullPoint: false };
          }
          if (forms.controls.some((group: FormGroup) => {
            const pawControls = group.controls.paw as FormGroup;
            const wp = pawControls.controls.wiltingPoint.value as string;
            const fc = pawControls.controls.fieldCapacity.value as string;
            return wp && fc && +wp < +fc && +wp >= 0  && +fc >= 0;
          })) {
            return null;
          } else {
            return { sensors: false };
          }
        } else {
          if (forms.controls.some((group: FormGroup) => {
            const defaultControls = group.controls.default as FormGroup;
            return !!defaultControls.controls.sensorChecked.value;
          })) {
            return null;
          } else {
            return { sensors: false };
          }
        }
      }
    } as AbstractControlOptions);

    this.operation.valueChanges.subscribe((operation: ViewOperationType) => {
      this.selectedOperation = operation;
      this.selectedSensorTypes = this.sensorTypes[operation];
      if (this.selectedSensorTypes.length) {
        this.sensorType.setValue(this.selectedSensorTypes[0].value);
      }
    });

    this.sensorType.valueChanges.subscribe((type: SensorGroupType) => {
      this.selectedSensorGroup = this.sensorGroups[type];
      this.form.controls.sensors = this.sensorGroupsForm[type];
    });

    this.stationStore.pipe(
      select(selectSelectedStation),
      map((selectedStation: ISelectedSearchWidgetItem) => selectedStation.original_name),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(stationId => this.stationId = stationId);

    this.dataStore.pipe(
      select(viewsSelector),
      filter(d => !!d && !!d.length),
      map(d => JSON.parse(JSON.stringify(d))),
      takeUntil(this.destroy$)
    ).subscribe(views => this.views = views);

    this.treeSettingsStore.pipe(
      select(treeSettings),
      filter(d => !!d),
      takeUntil(this.destroy$)
    ).subscribe(settings => this.treeSettings = settings);

    this.dataStore.pipe(
      select(topologySelector),
      withLatestFrom(this.dataStore.pipe(select(soilMoistureSensors))),
      filter(([topology, sensors]) => !!topology),
      takeUntil(this.destroy$)
    ).subscribe(([topology, sensors]) => {

      if (this.treeSettings[this.stationId] && this.treeSettings[this.stationId].selectedProfile) {
        this.profile = this.treeSettings[this.stationId].selectedProfile;
        const view = this.getSelectedView();
        if (view) {
          this.profileName = view.name;
          this.mode = this.getMode();
          [this.operations, this.sensorTypes, this.sensorGroups, this.sensorGroupsForm] = this.optionsFromTopology(topology, sensors);
          this.hasSensors = Object.keys(this.sensorGroups).some(key => !!this.sensorGroups[key].length);
          switch (view.type) {
            case ViewType.CUSTOM:
              if (view.content && view.content.aggregate && view.content.aggregate[0]) {
                const aggregation = view.content.aggregate[0];
                [
                  this.selectedSensorTypes,
                  this.selectedSensorGroup
                ] = this.selectionsFromCustomView(aggregation);

                this.name.setValue(this.profileName, { emitEvent: false });
                this.operation.setValue(aggregation.operation, { emitEvent: false });
                this.selectedOperation = this.operation.value;
                this.sensorType.setValue(aggregation.meta.secondMenuName, { emitEvent: false });
                this.form.controls.sensors = this.sensorGroupsForm[this.selectedSensorTypes[0].value];
              }
              break;
              case ViewType.PREDEFINED:
              this.translate.get('Custom View').pipe(take(1)).subscribe((defaultName: string) => {
                this.name.setValue(`${defaultName} ${this.views.length - defaultPredefinedViews.length + 1}`);
              });
              if (this.hasSensors) {
                this.operation.setValue(this.operations[0].value, { emitEvent: false });
                this.selectedOperation = this.operation.value;
                this.selectedSensorTypes = this.sensorTypes[this.operations[0].value];
                this.sensorType.setValue(this.selectedSensorTypes[0].value, { emitEvent: false });
                this.selectedSensorGroup = this.sensorGroups[this.selectedSensorTypes[0].value];
                this.form.controls.sensors = this.sensorGroupsForm[this.selectedSensorTypes[0].value];
              }
            break;
          }
        }
      }

    });

  }

  public get name(): FormControl {
    return this.form.get('name') as FormControl;
  }

  public get operation(): FormControl {
    return this.form.get('operation') as FormControl;
  }

  public get sensorType(): FormControl {
    return this.form.get('sensorType') as FormControl;
  }

  public get refillPoint(): FormControl {
    return this.form.get('refillPoint') as FormControl;
  }

  public get fullPoint(): FormControl {
    return this.form.get('fullPoint') as FormControl;
  }

  public get sensors(): FormArray {
    return this.form.get('sensors') as FormArray;
  }

  public saveView(): void {

    const selectedView = this.getSelectedView(),
      view: IView = {
        _id: selectedView._id,
        name: this.name.value,
        group: 'soilmoist',
        station_id: this.stationId,
        template: selectedView.template,
        type: ViewType.CUSTOM,
        content: {
          aggregate: []
        }
      },
      aggregate: IAggregate = {
        name: '',
        operation: this.operation.value,
        meta: {
          firstMenuName: '',
          secondMenuName: this.sensorType.value,
          secondMenuValue: ''
        },
        params: []
      };

    if (selectedView.configuration) {
      view.configuration = JSON.parse(JSON.stringify(selectedView.configuration));
      if (selectedView.type === ViewType.PREDEFINED) {
        // If we have copied the plot band configuration from a predefined view we need to update the plotBandMode
        // because they do not map 1:1 ON (data) -> ON (aggr)
        Object.keys(view.configuration.plotBands).forEach((key) => {
          if (view.configuration.plotBands[key].mode === PlotbandModeType.DATA) {
            view.configuration.plotBands[key].mode = PlotbandModeType.AGGR;
          }
        });
      }
    }

    switch (this.operation.value) {
      case ViewOperationType.PAW:
        aggregate.name = `${this.sensorType.value} Paw`;
        aggregate.params = {
          default: {
            rf: +this.refillPoint.value,
            fp: +this.fullPoint.value,
          },
          sensors: this.sensors.controls.reduce((sensors, group: FormGroup, i) => {
            const pawGroup = group.controls.paw;
            const ch = +pawGroup.get('ch').value;
            const code = +pawGroup.get('code').value;
            const wp = pawGroup.get('wiltingPoint').value as string;
            const fc = pawGroup.get('fieldCapacity').value as string;
            if (wp && fc && +wp < +fc) {
              sensors.push({ ch, code, fc: +fc, wp: +wp });
            }
            return sensors;
          }, [] as IPawSensor[])
        } as IPawParameter;
        break;
      case ViewOperationType.AVG:
      case ViewOperationType.SUM:
        aggregate.name = `${this.sensorType.value} ${this.operation.value === ViewOperationType.AVG ? 'Avg' : 'Sum'}`;
        aggregate.params = this.sensors.controls.reduce((sensors, group: FormGroup, i) => {
          const defaultGroup = group.controls.default;
          if (defaultGroup.get('sensorChecked').value) {
            sensors.push(`ch.${defaultGroup.get('ch').value}`);
          }
          return sensors;
        }, [] as string[]);
        break;
    }

    view.content.aggregate = [aggregate];

    if (selectedView) {
      if (selectedView._id && selectedView.type === ViewType.CUSTOM) {
        this.dataStore.dispatch(putView(view));
      } else {
        this.dataStore.dispatch(postView(view));
      }
    }

  }

  public deleteView(): void {

    const view = this.getSelectedView();
    if (view && view.type === ViewType.CUSTOM) {
      this.dataStore.dispatch(deleteView(view));
    }

  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public isChecked(sensor: IOptions): boolean {

    return this.selectedSensors[this.sensorType.value] && this.selectedSensors[this.sensorType.value].some(d => {
      return d.value === sensor.value && d.content === sensor.content;
    });

  }

  public onSensorSelectionChanged(sensor: IOptions, checked: boolean): void {

    if (checked) {
      this.selectedSensors[this.sensorType.value] = this.selectedSensors[this.sensorType.value] ?
        [...this.selectedSensors[this.sensorType.value], sensor] : [sensor];
    } else {
      this.selectedSensors[this.sensorType.value] = this.selectedSensors[this.sensorType.value].filter(d => {
        return d.value !== sensor.value && d.content !== sensor.content;
      });
    }

  }

  private getMode(): ViewEditorMode {

    const view = this.getSelectedView();
    return view && view.type === ViewType.CUSTOM ? ViewEditorMode.EDIT : ViewEditorMode.CREATE;

  }

  private selectionsFromCustomView(aggregation: IAggregate): [IOptions[], IOptions[]] {

    const selectedSensorTypes = this.sensorTypes[aggregation.operation],
      selectedSensorGroup = this.sensorGroups[aggregation.meta.secondMenuName],
      groups = this.sensorGroupsForm[aggregation.meta.secondMenuName].controls as FormGroup[];

    if (aggregation.operation === ViewOperationType.PAW) {

      const params = aggregation.params as IPawParameter;
      this.refillPoint.setValue(params.default && params.default.hasOwnProperty('rf') ? params.default.rf : 0.01);
      this.fullPoint.setValue(params.default && params.default.hasOwnProperty('fp') ? params.default.fp : 100);
      groups.forEach(group => {
        const pawControls = group.controls.paw as FormGroup;
        const param = params.sensors.find(p => {
          return p.ch === pawControls.controls.ch.value && p.code === pawControls.controls.code.value;
        });
        pawControls.controls.wiltingPoint.setValue(param ? param.wp : '');
        pawControls.controls.fieldCapacity.setValue(param ? param.fc : '');
      });

    } else {

      const params = aggregation.params as string[];
      groups.forEach(group => {
        const defaultControls = group.controls.default as FormGroup;
        defaultControls.controls.sensorChecked.setValue(params.some(param => `ch.${defaultControls.controls.ch.value}` === param));
      });

    }

    return [selectedSensorTypes, selectedSensorGroup];

  }

  private optionsFromTopology(
      topology: ITopologyItem[],
      sensorSettings: ISensor[]
    ): [IOptions[], IOptionsMap, IOptionsMap, {[key: string]: FormArray}] {

    const operations = [],
      sensorTypes = {},
      sensorGroups = {},
      sensorGroupsForm = {},
      sensors: ITopologySensorData[] = topology.reduce((a, t) => [...a, ...t.sensors, ...(
          t.nodes ? t.nodes.reduce((b, n) => [...b, ...n.sensors], []) : []
        )], []),
      availableGroups: number[] = sensors.reduce((groups: number[], sensor) => {
        if (!groups.includes(sensor.sensor.group)) {
          groups.push(sensor.sensor.group);
        }
        return groups;
      }, []);

    Object.keys(SensorGroupType).forEach((key) => {

      const sensorGroupType = SensorGroupType[key];
      sensorGroups[sensorGroupType] = sensors.reduce((options: IOptions[], sensor) => {
        if (SENSOR_GROUPS[sensorGroupType].includes(sensor.sensor.group)) {
          options.push({
            value: sensor.sensor,
            content: sensor.sensor.name
          });
        }
        return options;
      }, []);

      sensorGroupsForm[sensorGroupType] = sensors.reduce((form, sensor) => {
        if (SENSOR_GROUPS[sensorGroupType].includes(sensor.sensor.group)) {
          const setting = sensorSettings.find(s => s.ch === sensor.sensor.ch && s.code === sensor.sensor.code);
          form.push(this.formBuilder.group({
            default: this.formBuilder.group({
              name: [sensor.sensor.name],
              ch: [sensor.sensor.ch],
              sensorChecked: [false]
            }),
            paw: this.formBuilder.group({
              name: [sensor.sensor.name],
              ch: [sensor.sensor.ch],
              code: [sensor.sensor.code],
              wiltingPoint: [setting ? setting.refill_point || '' : ''],
              fieldCapacity: [setting ? setting.field_capacity || '' : '']
            })
          }));
        }
        return form;
      }, this.formBuilder.array([]));

      sensorGroupsForm[sensorGroupType].valueChanges.subscribe((d) => {
        this.form.updateValueAndValidity();
      });

    });

    Object.keys(ViewOperationType).forEach((key) => {

      const viewOperationType = ViewOperationType[key];
      sensorTypes[viewOperationType] = availableGroups.reduce((options: IOptions[], group) => {
          ViewAggregationType[viewOperationType].forEach(sensorGroupType => {
            if (SENSOR_GROUPS[sensorGroupType].includes(group)) {
              options.push({
                value: sensorGroupType,
                content: sensorGroupType
              });
            }

        });
        return options;
      }, []);

      if (sensorTypes[viewOperationType].length) {
        const operation = ViewOperations.find(o => o.value === viewOperationType);
        if (operation) {
          operations.push(operation);
        }
      }

    });

    return [operations, sensorTypes, sensorGroups, sensorGroupsForm];

  }

  private getSelectedView(): IView | null {

    const view = this.views.find(v => {
      return (v._id && v._id === this.profile) || v.name === this.profile;
    });
    return view || null;

  }


}
