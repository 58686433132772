<app-chart-toolbar-layout>
  <form class="image-gallery-toolbar" [formGroup]="form">

    <div class="image-gallery-toolbar__left">
      <app-custom-dropdown class="image-gallery-toolbar__left__item"
                           [options]="stationOptions"
                           [formControl]="stationIdControl"></app-custom-dropdown>

      <ng-container *ngIf="enableIntervalSearch">
        <div class="image-gallery-toolbar__left__item">
          <app-custom-datepicker [isSmall]="true"
                                 [minDate]="getDate(stationMinDate$ | async)"
                                 [maxDate]="getDate(stationMaxDate$ | async)"
                                 [formControl]="datepickerControl">
          </app-custom-datepicker>
        </div>

        <div class="image-gallery-toolbar__left__item">
          <app-custom-datepicker [isSmall]="true"
                                 [minDate]="getDate(stationMinDate$ | async)"
                                 [maxDate]="getDate(stationMaxDate$ | async)"
                                 [formControl]="datepickerControl">
          </app-custom-datepicker>
        </div>
      </ng-container>

      <ng-container *ngIf="!enableIntervalSearch">
        <div class="image-gallery-toolbar__left__item">
          <app-custom-dropdown class="image-gallery-toolbar__left__item"
                               [options]="periodOptions"
                               [formControl]="periodScopeControl">
          </app-custom-dropdown>
        </div>

        <div class="image-gallery-toolbar__left__item" *ngIf="!isExtraOptionEnabled()">
          <app-date-range-arrow [formControl]="fromToControl"
                                [minDate]="rangeArrowMin"
                                [maxDate]="stationMaxDate$ | async"
                                [period]="periodScopeControl.value"
                                (rangeClick)="onRefresh()">
          </app-date-range-arrow>
        </div>

        <div class="image-gallery-toolbar__left__item" *ngIf="isExtraOptionEnabled()">
          <app-custom-simple-lazy-dropdown
            class="image-gallery-toolbar__left__item"
            [currentItem]="extraOptionSelected"
            [formControl]="fromToControl"
            [items$]="extraOptions$"
            (ngModelChange)="onExtraOptionSelected($event)">
          </app-custom-simple-lazy-dropdown>
        </div>
      </ng-container>
    </div>

    <div class="image-gallery-toolbar__right">
      <div class="image-gallery-toolbar__right__item">
        <app-custom-button [color]="'red'"
                           (click)="onRefresh()"
                           [content]="'Refresh'">
          <i class="fa fa-fw fa-refresh"></i>
        </app-custom-button>
      </div>
    </div>
  </form>
</app-chart-toolbar-layout>
