import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ISelectedSearchWidgetItem } from '../../../../core/models/selectedSearchWidgetItem';
import { selectSelectedStation } from '../../../../core/reducers';
import * as fromSelectedStation from '../../../../core/reducers/selectedStation';
import { IStationSettingsPeriod } from '../../../../shared/interfaces';
import { ITreeSettingsState } from '../../../../shared/tree/models/tree.models';
import { dateTypeFromPeriod } from '../../../../shared/utils/dateTypeFromPeriod';
import { selectStationDataSettingsPeriod, selectTreeSettings } from '../../reducers';
import * as fromStationSettings from '../../reducers/station-data-settings';

@Component({
  selector: 'app-station-data-header',
  templateUrl: './station-data-header.component.html',
  styleUrls: ['./station-data-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class StationDataHeaderComponent implements OnInit, OnDestroy {
  public period$                     : Observable<IStationSettingsPeriod>;
  public profile$                    : Observable<string>;
  public readonly format             : string = 'yyyy-MM-dd HH:mm';
  private destroy$                   : Subject<boolean> = new Subject<boolean>();

  constructor(private stationSettingsStore: Store<fromStationSettings.IStationDataSettingsState>,
              private treeSettingStore: Store<ITreeSettingsState>,
              private selectedStationStore: Store<fromSelectedStation.ISelectedStationState>) { }

  public ngOnInit(): void {
    this.period$ = this.stationSettingsStore.pipe(
      select(selectStationDataSettingsPeriod)
    );
    this.profile$ = combineLatest([
      this.selectedStationStore.pipe(
        select(selectSelectedStation),
      ),
      this.treeSettingStore.pipe(
        select(selectTreeSettings)
      )
    ]).pipe(
      takeUntil(this.destroy$),
      map((result: Array<ISelectedSearchWidgetItem|ITreeSettingsState>): string => {
        const stationId: string = (<ISelectedSearchWidgetItem>result[0]).original_name;
        const tree: ITreeSettingsState = <ITreeSettingsState>result[1];
        return tree[stationId] ? tree[stationId].selectedProfile : '';
      })
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  public prepareDate(date: string): string {
    return `${parseInt(date, 10)} ${dateTypeFromPeriod(date)}`;
  }

}
