<section>
  <div class="alert alert-warning" *ngIf="geoPosition.hasError('required') && geoPosition.touched">
    <span class="glyphicon glyphicon-alert"></span>
    {{ 'Please, pick a location for this virtual station clicking on the map' | translate }}
  </div>

  <div class="product-activation-vws-form" [formGroup]="form">
    <div class="row">
      <div class="col-sm-5">
        <div class="row">
          <div class="col-md-12 form-group">
            <label>{{ 'Virtual station name' | translate }}</label>
            <app-form-input class="product-activation-vws-form__input"
                            [icon]="'zmdi zmdi-label'"
                            [formControl]="customName"></app-form-input>
          </div>

          <div class="col-md-12 form-group">
            <label>{{ 'Starting date' | translate }}</label>
            <app-custom-datepicker class="product-activation-vws-form__input"
                                   [isSmall]="true"
                                   [minDate]="currentDate"
                                   [maxDate]="maxDate"
                                   [formControl]="startingDate">
            </app-custom-datepicker>
          </div>

          <div class="col-md-12 form-group">
            <div class="row">
              <div class="col-md-3"><label>{{ 'Longitude' | translate }}</label></div>
              <div class="col-md-3"><label>{{ 'Latitude' | translate }}</label></div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <input style="width: 100%" [formControl]="longitude" (keyup.enter)="onEnterKey()">
              </div>
              <div class="col-md-3">
                <input style="width: 100%" [formControl]="latitude" (keyup.enter)="onEnterKey()">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-7">
        <label>{{ 'Pick the station location' | translate }}</label>
        <agm-map
          [latitude]="position.geo.coordinates[1]"
          [longitude]="position.geo.coordinates[0]"
          [zoom]="10"
          [minZoom]="2"
          [maxZoom]="17"
          [disableDefaultUI]="true"
          [zoomControl]="true"
          [scrollwheel]="true"
          [mapTypeControl]="true"
          [streetViewControl]="false"
          [mapTypeId]="'hybrid'"
          (mapClick)="selectLocationFromMap($event)">
          <agm-marker
            [latitude]="position.geo.coordinates[1]"
            [longitude]="position.geo.coordinates[0]">
          </agm-marker>
        </agm-map>
      </div>
    </div>

    <div class="product-activation-vws-form__submit">
      <span class="product-activation-vws-form__submit__info">
        {{ '* License is valid for' | translate }} {{ licenseDuration }}
      </span>

      <app-custom-button [color]="'green'"
                         [content]="'Add virtual station' | translate"
                         (click)="submitEmit()"></app-custom-button>
    </div>
  </div>
</section>
