import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SoilguardDataComponent } from './containers/soilguard-data/soilguard-data.component';
import { DualexDataComponent } from './containers/dualex-data/dualex-data.component';
import { MobilabDataComponent } from './containers/mobilab-data/mobilab-data.component';

const routes: Routes = [
  { path: '', redirectTo: 'soil-guard', pathMatch: 'full' },
  { path: 'soil-guard', component: SoilguardDataComponent },
  { path: 'dualex', component: DualexDataComponent },
  { path: 'mobilab', component: MobilabDataComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StationSampleRoutingModule { }
