<div *ngIf="chartsSettings.loadData?.length !== 0; else loading">
  <div *ngFor="let item of chart">
    <div class="chart" [chart]="item"></div>
  </div>
</div>

<ng-template #loading>
  <app-widget-info>
    <h4><i class="fa fa-warning fa-fw"></i> {{ 'No data available' | translate}}</h4>
  </app-widget-info>
</ng-template>