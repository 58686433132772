import { IOptions } from '../../../shared/interfaces';
import { Options } from 'highcharts';

export const PERIODS_BY_SCOPE = {
    raw: [
        { content: '24 hours', value: '24h' },
        { content: '48 hours', value: '48h' },
        { content: '7 days', value: '7d' },
        { content: '10 days', value: '10d' }
    ] as IOptions[],
    hourly: [
        { content: '48 hours', value: '48h' },
        { content: '7 days', value: '7d' },
        { content: '10 days', value: '10d' },
        { content: '14 days', value: '14d' },
        { content: '30 days', value: '30d' }
    ] as IOptions[],
    daily: [
        { content: '7 days', value: '7d' },
        { content: '10 days', value: '10d' },
        { content: '14 days', value: '14d' },
        { content: '30 days', value: '30d' },
        { content: '90 days', value: '90d' },
        { content: '180 days', value: '180d' },
        { content: '360 days', value: '360d' }
    ] as IOptions[],
    monthly: [
        { content: '6 months', value: '6m' },
        { content: '12 months', value: '12m' },
        { content: '24 months', value: '24m' },
        { content: '36 months', value: '36m' }
    ] as IOptions[]
};

export const DEFAULT_SCOPE: string = 'hourly';

export const DEFAULT_PERIOD: string = PERIODS_BY_SCOPE[DEFAULT_SCOPE][3].value;

export const DEFAULT_CHART_OPTIONS: Options = {
    title: {
        text: null
    },
    chart: {
        height: 400,
        marginLeft: 85,
        marginRight: 100,
        marginTop: 5,
        style: { fontFamily: 'Helvetica' },
        zoomType: 'x',
        alignTicks: false
    },
    credits: {
        enabled: false
    },
    exporting: {
        enabled: false
    },
    tooltip: {
        useHTML: true,
        xDateFormat: '%Y-%m-%d %H:%M',
        shared: true
    },
    legend: {
        useHTML: true,
        labelFormatter: function (): string {
            return this.name;
        }
    },
    xAxis: {
        crosshair: true,
        gridLineWidth: 1,
        labels: { style: { fontSize: '12px' } },
        lineWidth: 2,
        type: 'datetime'
    },
    plotOptions: {
        series: {
            states: {
                hover: {
                    enabled: false
                }
            },
            marker: {
                enabled: false
            }
        }
    }
};
