<app-camera-data-header [title]="'Monitoring data'"></app-camera-data-header>

<section>
  <app-camera-data-toolbar
    [stationCameraType]="stationCameraType"
    [stationChangedListener$]="stationChangedListener$"
  ></app-camera-data-toolbar>

  <app-form-layout *ngIf="!(isLoading$ | async) && !(isError$ | async); else loadinfo">
    <h4 class="current-date-header" *ngIf="(isChartActive$ | async) || (isTableActive$ | async)">
      {{ ((title | async) || '') | translate }}
    </h4>

    <app-camera-data-chart
      *ngIf="isChartActive$ | async"
      [chartOptions]="chartOptions"
    ></app-camera-data-chart>

    <app-camera-data-table
      *ngIf="isTableActive$ | async"
      [stationCameraType]="stationCameraType"
      [rowData]="rowData"
      [additionalColumnDefs]="additionalColumnDefs"
    ></app-camera-data-table>
  </app-form-layout>

  <ng-template #loadinfo>
    <app-station-load-info
      [isError]="isError$ | async"
      [isLoading]="isLoading$ | async"
    ></app-station-load-info>
  </ng-template>
</section>
