import { IrrimetComponent } from './irrimet.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IrrimetWaterBalanceComponent } from './containers/irrimet-water-balance/irrimet-water-balance.component';
import { IrrimetAvailableWaterPawComponent } from './containers/irrimet-available-water-paw/irrimet-available-water-paw.component';

const routes: Routes = [
  { path: '', component: IrrimetComponent },
  { path: 'available-water-mm', component: IrrimetWaterBalanceComponent },
  { path: 'paw', component: IrrimetAvailableWaterPawComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class IrrimetRoutingModule {
}
