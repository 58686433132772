import { Injectable, OnDestroy } from '@angular/core';
import { ExcelExportSettings } from '../../../shared/constants';
import { map, mergeMap, takeUntil, toArray } from 'rxjs/operators';
import { GridOptions } from 'ag-grid';
import { from, Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { StationDataExportService } from '../../../shared/services/export/station-data-export.service';

@Injectable({
  providedIn: 'root'
})
export class SampleDataService implements OnDestroy {

  public gridOptions: GridOptions;
  public alive$ = new Subject<void>();

  constructor(
    private translation: TranslateService,
    private exportService: StationDataExportService
  ) {

    this.exportService.getExportXLS()
      .pipe(takeUntil(this.alive$))
      .subscribe((data: string) => this.exportXLS(data));
  }

  public setGridOptions(data: GridOptions): void {
    this.gridOptions = data;
  }

  public groupSamples(samples: Array<any>): Array<any> {
    if (samples && samples.length) {
      const data = samples.reduce(({ group, list }, sample) => {
        const index = list.indexOf(sample.deviceID);

        if (index >= 0) {
          group[index].push(sample);
        } else {
          list.push(sample.deviceID);
          group.push([sample]);
        }

        return { group, list };
      }, { group: [], list: [] });

      return data.group;
    } else {
      return [];
    }
  }

  public exportXLS(id: string): void {
    const config = {
      ExcelExportSettings,
      fileName: `${id}_device_data`,
      processCellCallback: cell => {
        let data = cell.value;

        if (cell && cell.column && cell.column.colDef.cellRenderer) {
          data = cell.column.colDef.cellRenderer({value: cell.value});
        }

        return data;
      }
    };

    this.gridOptions.api.exportDataAsExcel(config);
  }

  public translate(columns: any[]): Observable<any> {
    return from(columns).pipe(
      mergeMap(column => this.translation.get(column.headerName).pipe(
        map(header => (column.headerName = header) && column)
      )),
      toArray()
    );
  }

  public groupMobilabSamples(samples: Array<any>): Array<any> {
    return samples.map(sample => {
      const { measurements } = sample;
      if (measurements && measurements.length) {
        return measurements.map(measurement => ({
          ...sample,
          ...measurement,
          measurement: null
        }));
      } else {
        return [{ ...sample }];
      }
    });
  }

  public ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }
}
