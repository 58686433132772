import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { selectSelectedStation } from '../../../../core/reducers';
import * as fromSelectedStation from '../../../../core/reducers/selectedStation';
import { IStationSettingsPeriod } from '../../../../shared/interfaces';
import { ITreeSettingsState } from '../../../../shared/tree/models/tree.models';
import { dateTypeFromPeriod } from '../../../../shared/utils/dateTypeFromPeriod';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { periodSettings, treeSettings, views as viewsSelector } from '../../selectors';
import * as SoilMoistureStates from '../../states';


@Component({
  selector: 'app-soil-moisture-header',
  templateUrl: './soil-moisture-header.component.html',
  styleUrls: ['./soil-moisture-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoilMoistureHeaderComponent implements OnInit, OnDestroy {

  @Output()
  public openModal:       EventEmitter<void> = new EventEmitter();

  public HELP_MODAL_ID:   string = generateId();
  public period$:         Observable<IStationSettingsPeriod>;
  public profile$:        Observable<string>;
  public readonly format: string = 'yyyy-MM-dd HH:mm';
  private destroy$:       Subject<boolean> = new Subject<boolean>();


  constructor(
    private dataStore: Store<SoilMoistureStates.IStateData>,
    private settingsStore: Store<SoilMoistureStates.IStateSettings>,
    private treeStore: Store<ITreeSettingsState>,
    private selectedStationStore: Store<fromSelectedStation.ISelectedStationState>
  ) { }

  public ngOnInit(): void {

    this.period$ = this.settingsStore.pipe(
      select(periodSettings),
      takeUntil(this.destroy$)
    );

    this.profile$ = this.treeStore.pipe(
      select(treeSettings)
    ).pipe(
      withLatestFrom(
        this.dataStore.pipe(select(viewsSelector)),
        this.selectedStationStore.pipe(
          select(selectSelectedStation),
          map(station => station.original_name)
        )
      ),
      map(([settings, views, stationId]) => {
        const profile = settings[stationId] ? settings[stationId].selectedProfile : '';
        const view = views.find(v => {
          return (v._id && v._id === profile) || v.name === profile;
        });
        return view ? view.name : '';
      }),
      takeUntil(this.destroy$)
    );

  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public prepareDate(date: string): string {
    return `${parseInt(date, 10)} ${dateTypeFromPeriod(date)}`;
  }

}
