import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IStationFromToCommunication, IStationSettingsPeriod} from '../../../shared/interfaces';
import {IFireBlight, IStationDiseaseSettingsActivity} from '../models/station-disease.models';
import {StationDiseaseSettingsActionTypes} from '../actions/station-disease-settings';

export interface IStationDiseaseSettingsState {
  activity: IStationDiseaseSettingsActivity;
  period: IStationSettingsPeriod;
  fromTo: IStationFromToCommunication;
  fireBlight: IFireBlight;
}

const initialState: IStationDiseaseSettingsState = {
  activity: {
    isChartActive: true,
    isTableActive: true,
    isFireBlightActive: false,
    isExportImageActive: false,
    isExportActive: false
  },
  period: {
    periodScope: '',
    periodValue: '',
    fromTo: null,
    fromDatepicker: null,
    toDatepicker: null,
    stationId: '',
    isLastDataMode: true
  },
  fromTo: null,
  fireBlight: {
    value: '',
    rights: '',
    isSuccess: false,
  }
};

export function reducer(state: IStationDiseaseSettingsState = initialState, action: IActionWithPayload ): IStationDiseaseSettingsState {
  switch (action.type) {
    case (StationDiseaseSettingsActionTypes.SET_STATION_DISEASE_FIRE_BLIGHT_SUCCESS): {
      return {
        ...state,
        fireBlight: {
          ...state.fireBlight,
          isSuccess: action.payload
        }
      };
    }
    case (StationDiseaseSettingsActionTypes.SET_STATION_DISEASE_SETTINGS_PERIOD_SCOPE): {
      return {
        ...state,
        period: {
          ...state.period,
          periodScope: action.payload.periodScope,
          periodValue: action.payload.periodValue
        }
      };
    }
    case (StationDiseaseSettingsActionTypes.SET_STATION_DISEASE_FIRE_BLIGHT): {
      return {
        ...state,
        fireBlight: {
          ...state.fireBlight,
          value: action.payload
        }
      };
    }
    case (StationDiseaseSettingsActionTypes.SET_STATION_DISEASE_RIGHTS): {
      return {
        ...state,
        fireBlight: {
          ...state.fireBlight,
          rights: action.payload
        }
      };
    }
    case (StationDiseaseSettingsActionTypes.SET_STATION_DISEASE_SETTINGS_FROM_TO): {
      return {
        ...state,
        fromTo: action.payload
      };
    }
    case (StationDiseaseSettingsActionTypes.SET_STATION_DISEASE_SETTINGS_PERIOD): {
      return {
        ...state,
        period: action.payload
      };
    }
    case (StationDiseaseSettingsActionTypes.SET_STATION_DISEASE_SETTINGS_ACTIVITY): {
      return {
        ...state,
        activity: action.payload
      };
    }
    case (StationDiseaseSettingsActionTypes.SET_STATION_DISEASE_SETTINGS_EXPORT_ACTIVE): {
      return {
        ...state,
        activity: {
          ...state.activity,
          isExportActive: action.payload
        }
      };
    }
    case (StationDiseaseSettingsActionTypes.SET_STATION_DISEASE_FIRE_BLIGHT_ACTIVE): {
      return {
        ...state,
        activity: {
          ...state.activity,
          isFireBlightActive: action.payload
        }
      };
    }
    case (StationDiseaseSettingsActionTypes.SET_STATION_DISEASE_SETTINGS_EXPORT_IMAGE): {
      return {
        ...state,
        activity: {
          ...state.activity,
          isExportImageActive: action.payload
        }
      };
    }
    default: {
      return state;
    }
  }
}
