import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, takeWhile, map, filter } from 'rxjs/operators';
import { IStation } from '../../../../../../core/models/stations';
import { selectStations } from '../../../../../../core/reducers/index';
import * as fromStations from '../../../../../../core/reducers/stations';
import { ICropViewRequest } from '../../../../models/dashboard.models';
import { deepClone } from '../../../../../../shared/utils/deepClone';

@Component({
  selector: 'app-crop-view-settings',
  templateUrl: './crop-view-settings.component.html',
  styleUrls: ['./crop-view-settings.component.scss']
})
export class CropViewSettingsComponent implements OnInit, OnDestroy {
  @Input()
  private cropViewRequest           : ICropViewRequest;
  @Output()
  private updateRequest             : EventEmitter<ICropViewRequest> = new EventEmitter();
  @Output()
  private updateWidgetData          : EventEmitter<void> = new EventEmitter();

  public form                       : FormGroup;
  public stations$                  : Observable<Array<IStation>>;
  private alive                     : boolean = true;

  constructor(private stationsStore: Store<fromStations.IStations>,
              private formBuilder: FormBuilder) {}

  public get station(): AbstractControl {
    return this.form.get('station');
  }

  public ngOnInit(): void {
    this.stations$ = this.stationsStore.pipe(
      select(selectStations),
      filter((stations: IStation[]) => !!stations && stations.length > 0),
      map((stations) => this.sortArray(stations))
    );

    this.form = this.formBuilder.group({
      'station': new FormControl(this.cropViewRequest.endpoint.station),
    });

    this.form.valueChanges.pipe(
      distinctUntilChanged(),
      takeWhile(() => this.alive)
    ).subscribe(() => {
      this.changeRequest();
    });
  }

  private sortArray(stationArray: IStation[]): IStation[] {
    const stations = deepClone(stationArray);
    const customNamedStations = stations.filter((station) => station.name.custom !== '');
    customNamedStations.sort((a, b) => {
      const nameA = a.name.custom.toLowerCase();
      const nameB = b.name.custom.toLowerCase();

      const isNumberA = !isNaN(Number(nameA));
      const isNumberB = !isNaN(Number(nameB));

      if (isNumberA && isNumberB) {
        return Number(nameA) - Number(nameB);
      } else if (isNumberA) {
        return -1;
      } else if (isNumberB) {
        return 1;
      } else {
        return nameA.localeCompare(nameB);
      }
    });

    const otherStations = stations.filter((station) => station.name.custom === '');
    otherStations.sort((a: IStation, b: IStation) => a.name.original.toLowerCase() < b.name.original.toLowerCase() ? -1 : 1);

    return customNamedStations.concat(otherStations);
  }

  public changeRequest(): void {
    const newRequest = {
      endpoint: {
        group: this.cropViewRequest.endpoint.group,
        last: this.cropViewRequest.endpoint.last,
        station: this.station.value
      }
    };
    this.updateRequest.emit(newRequest);
  }

  public submit(): void {
    this.updateWidgetData.emit();
  }

  public ngOnDestroy(): void {
    this.alive = false;
  }
}
