import { Pipe, PipeTransform } from '@angular/core';
import { filterIrrigationDayEvents } from '../../utils/irrigation-day-events/day-events-irrigation-util';
import { filterSplitIrrigationDayEvents } from '../../utils/irrigation-day-events/day-events-irrigation-util';

@Pipe({
  name: 'dayIrrigationEventsPipe'
})
export class DayIrrigationEventsPipe implements PipeTransform {

  public transform(events: Array<any>): Array<any> {
    return Array.isArray(events) ? filterIrrigationDayEvents(events) : [];
  }

}

@Pipe({
  name: 'splitDayIrrigaitonEvents'
})
export class SplitDayIrrigationEventsPipe implements PipeTransform {

  public transform(events: Array<any>): Array<any> {
    return Array.isArray(events) ? filterSplitIrrigationDayEvents(events) : [];
  }

}
