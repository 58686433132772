<app-form-layout [shadow]="false">
  <div class="frost-protection-header" *ngIf="period$ | async">
    <div>
      <h2>{{ 'Extreme Temperature Monitoring' | translate }}</h2>
      <small>{{ prepareDate((period$ | async).periodValue) | translate}} / {{ (period$ | async).periodScope | translate }}</small>
    </div>
    <div class="frost-protection-header__right">
      <i class="zmdi zmdi-help" (click)="openModal.emit()"></i>
    </div>
  </div>
</app-form-layout>

