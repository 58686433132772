import {Component, EventEmitter, Input, Output} from '@angular/core';
import {stationsOrderSettings} from '../../../containers/main-static/right-stations/constants/constants';
import {IOrderSettingItem} from '../models/search-widget.models';

@Component({
  selector: 'app-search-widget-order-button',
  templateUrl: './search-widget-order-button.component.html',
  styleUrls: ['./search-widget-order-button.component.scss']
})
export class SearchWidgetOrderButtonComponent {
  @Input()
  public content: string;
  @Input()
  public direction: string = null;
  @Input()
  public orderField: string;
  @Input()
  public customCSS?: string;
  @Output()
  public applySort: EventEmitter<string> = new EventEmitter<string>();

  public emitSortClick(orderField: string): void {
    if (stationsOrderSettings.find(
      (sortOption: IOrderSettingItem)  => sortOption.orderField === orderField)) {
      localStorage.setItem('sortingStations', orderField);
    } else {
      localStorage.setItem('sortingCropzones', orderField);
    }
    this.applySort.emit(orderField);
  }
}
