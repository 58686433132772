import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridApi, GridOptions } from 'ag-grid';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DataGridOptions } from '../../../../../shared/constants';
import { suppressMenu } from '../../../../../shared/utils/suppressMenu';
import { baseCropViewGridOptions } from '../../../constants/camera-data';

@Component({
  selector: 'app-camera-data-table-cropview',
  templateUrl: './camera-data-table-cropview.component.html',
  styleUrls: ['./camera-data-table-cropview.component.scss']
})
export class CameraDataTableCropviewComponent implements OnChanges, OnDestroy {
  @Input()
  public rowData: any = [];
  @Input()
  public additionalColumnDefs: any = [];

  @Output()
  public exportListener: EventEmitter<GridApi> = new EventEmitter<GridApi>();

  public dataGridOptions: GridOptions = {
    ...DataGridOptions,
    enableFilter: true
  };

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private translate: TranslateService) {
  }

  public ngOnChanges(): void {
    if (!this.rowData.length) {
      return;
    }

    this.initializeTable();
    this.exportListener.emit(this.dataGridOptions.api);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initializeTable(): void {
    setTimeout(() => {
      if (this.rowData && this.dataGridOptions.api) {
        baseCropViewGridOptions.columnDefs = suppressMenu(baseCropViewGridOptions.columnDefs);

        // Translating table headers
        for (let i = 0; i < baseCropViewGridOptions.columnDefs.length; i++) {
          this.translate.get(baseCropViewGridOptions.columnDefs[i].headerName).pipe(take(1)).subscribe((translatedText: string) => {
            baseCropViewGridOptions.columnDefs[i].headerName = translatedText;
          });
        }

        this.dataGridOptions.api.setColumnDefs(baseCropViewGridOptions.columnDefs.concat(this.additionalColumnDefs));
        this.dataGridOptions.api.setRowData(this.rowData);
      } else if (this.dataGridOptions.api) {
        this.dataGridOptions.api.setColumnDefs(null);
        this.dataGridOptions.api.setRowData(null);
      }
    }, 0);
  }
}
