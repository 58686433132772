import { IChartLegendItem, IChartTooltipItem } from '../../../shared/interfaces';

export const SERVICE_HELP_CONTENT: { [id: string]: string[] | { [id: string]: string[] } } = {
    'plant-nutrition': ['When fertilizer is spread, it lays around and waits for water to be diluted and brought into the ground with ' +
        'it. This is why we rate periods which are followed by more than 20 mm of rain as fantastic for fertilizer spreading as long as ' +
        'it is not too windy. Bad conditions occur if there is no rain after fertilizer is spread out or if the field is not accessible.'],
    'field-accessibility': ['When we have a rain load of 30 mm, we expect a very limited field access. ' +
        'It will get better when we have 20 mm of active rain or less. This is indicated with the field access rating from -5 to +3. ' +
        'With every dry day the field access is rated better.'],
    'tillage-ability': ['Tillage ability is negatively influenced by too much water or when the soil is too dry. When we have no rain ' +
        'over a certain time period, it is rated as moderately negative. If we have more than 40 mm of rain within the chosen time ' +
        'period and no drying out, it is rated as very negative (-5). If the soil is moderately dry or moderately wet, it is fine for ' +
        'tillage.'],
    'sowing-window': ['Suitable (green), less suitable (yellow) and unsuitable (red) periods to seed corn, potatoes, sugarbeet, canola, ' +
        'sunflower and more general crops. For uniform, rapid germination and emergence, all seeds need correct moisture and optimal ' +
        'temperature ranges. Conditions are calculated from soil temperature, rainfall of the previous days and minimum air temperature ' +
        'of the next days. Corn germination requires minimum temperature of 10°C in the seed zone. Soil temperatures of 6°C or higher ' +
        'with warmer weather in the forecast should facilitate reasonably good rates of emergence of canola, sunflower and sugarbeet.'],
    'tropical-sowing-window': ['Suitable (green), less suitable (yellow) and unsuitable (red) periods to sow crops in a tropical ' +
        'climate. For uniform, rapid germination and emergence, all seeds need sufficient moisture and optimal temperature. The ' +
        'calculated sowing suitability is limited by low rainfall (previous and forecast) and high air temperature (forecast). ' +
        'Also, extreme rainfall forecasts (high hourly precipitation) will limit the tropical sowing suitability due to the danger ' +
        'of flushing the seeds away.'],
    'plant-protection': ['Suitable periods for applying crop protection measures are shown in green color, marginal in yellow ' +
        '(spray with caution) and unsuitable in red (do not spray). Pesticide spraying and its effectiveness are extremely ' +
        'sensitive to atmospheric conditions. ' + 'The conditions are calculated from wind speed and gust, precipitation, air ' +
        'temperature, relative humidity and delta T which indicates whether climatic conditions are suitable for spraying ' +
        'in order to maximize pesticide performance, and to minimize drift and evaporation. The best time to spray is usually ' +
        'when the wind speed is above 2 km/h, predictable in direction, and wind gusts less than 20 km/h. Delta T should be above ' +
        '2 and the target plants or weeds not stressed. Current rainfall, next 4h and next 24h are also considered in order to ' +
        'avoid run-off of the active compound. ' + 'Highlighted values: In order to help farmers in making better decisions, any ' +
        'value which falls outside the ideal range is now highlighted in the tooltip by including the warning parameter. The ' +
        'warning colors - red, yellow, green - correspond, respectively, to the output value 0, 1 ,2. ' + 'Always consult the ' +
        'product label before considering spraying. Follow all label instructions.'],
    'harvest-window': {
        'fruit-grape': ['We like to pick dry fruits. Therefore it is indicated to be negative when we have leaf wetness. ' +
          'If the air is dry we will get higher sugar content in the grapes we harvest. This is indicated when the value ' +
          'reaches 1, 2 or more.'],
        'sugar-beet-potato': ['Sugar Beet and Potato Campaign is depending on the soil water content, a muddy field is ' +
          'stopping the use of sugar beet and potato harvesters. Common for all this calculations is that it assumes a ' +
          'dry day to reduce the actual water content in the tops soil by 5 mm. This is indicated by the calculations ' +
          'with the measured and forecast data.'],
        'combine-cereals': ['We do not want to send you to the field with your combine if the forecasted weather is not ' +
          'optimal for this. Only times with no leaf wetness and less than 60% relative humidity are rated to be good ' +
          'for harvesting. Sorry for the Scottish but we know they have better harvesters.'],
        'hay-silage': ['This page will give producers of hay and silage a fast view on the ability to dry grass or ' +
          'leguminous in the forecasted time period. Times with leaf wetness or a vapour pressure deficit under 1.0 ' +
          'are indicated to be bad. In periods rated to be bad it has to be expected that the hay gets wet instead ' +
          'of dry. In periods indicated to be neutral we are expecting a vapour pressure deficit below 2. More than 2 ' +
          'is indicated to be moderate, more than 3 good and more than 4 is very good. Whereas we have to state most ' +
          'areas will hardly have periods of 3 and higher. We wish good success for the hay and silage preparation in ' +
          'this areas too.'],
    },
  'grass-fire-danger-index': ['Grassland Fire Danger Index (GFDI) is an index that describes the rate of spread of a ' +
    'grass fire (and therefore the difficulty of putting it out). The index here calculated is valid only for ' +
    'Australia country. It is based on a combination of the “greenness” of the grass, and various meteorological ' +
    'parameters. The weather parameters (listed in decreasing order of importance) are wind speed, relative humidity ' +
    'and temperature. However, the biggest single factor is “curing.”  The higher the index, the faster a grass fire ' +
    'will spread. Under moderate conditions fire is likely to be controlled while the worst conditions for a bush or ' +
    'fire are on catastrophic category: if a fire starts and takes hold, it will be extremely difficult to control it.']
};

export const SERVICE_CHART_LEGEND: { [id: string]: IChartLegendItem[] } = {
    'plant-nutrition': [
        { color: '#660000', label: '0' },
        { color: '#c67465', label: '1' },
        { color: '#a1c16e', label: '2' },
        { color: '#458248', label: '3' }
    ],
    'field-accessibility': [
        { color: '#660000', label: '-5' },
        { color: '#ab2424', label: '-4' },
        { color: '#b84949', label: '-3' },
        { color: '#c67465', label: '-2' },
        { color: '#d6af7b', label: '-1' },
        { color: '#d1cc82', label: '0' },
        { color: '#a1c16e', label: '1' },
        { color: '#7aac5f', label: '2' },
        { color: '#458248', label: '3' }
    ],
    'tillage-ability': [
        { color: '#660000', label: '-5' },
        { color: '#ab2424', label: '-4' },
        { color: '#b84949', label: '-3' },
        { color: '#c67465', label: '-2' },
        { color: '#d6af7b', label: '-1' },
        { color: '#d1cc82', label: '0' },
        { color: '#a1c16e', label: '1' },
        { color: '#458248', label: '2' }
    ],
    'sowing-window': [
        { color: '#660000', label: '0' },
        { color: '#d6af7b', label: '1' },
        { color: '#458248', label: '2' }
    ],
    'tropical-sowing-window': [
        { color: '#660000', label: '0' },
        { color: '#d6af7b', label: '1' },
        { color: '#458248', label: '2' }
    ],
    'plant-protection': [
        { color: '#660000', label: '0' },
        { color: '#d6af7b', label: '1' },
        { color: '#458248', label: '2' }
    ],
    'harvest-window': [
        { color: '#660000', label: '-5' },
        { color: '#ab2424', label: '-4' },
        { color: '#b84949', label: '-3' },
        { color: '#c67465', label: '-2' },
        { color: '#d6af7b', label: '-1' },
        { color: '#d1cc82', label: '0' },
        { color: '#a1c16e', label: '1' },
        { color: '#7aac5f', label: '2' },
        { color: '#458248', label: '3' },
        { color: '#336035', label: '4' }
    ],
    'grass-fire-danger-index': [
      { color: '#458248', width: '40px', label: 'Low'  },
      { color: '#a1c16e', width: '40px', label: 'High' },
      { color: '#d6af7b', width: '70px', label: 'Very High' },
      { color: '#b84949', width: '70px', label: 'Severe' },
      { color: '#ab2424', width: '70px', label: 'Extreme' },
      { color: '#660000', width: '90px', label: 'Catastrophic'  },
    ],
};

export const SERVICE_CHART_TOOLTIP_PROPERTIES: { [id: string]: IChartTooltipItem[] } = {
    'plant-nutrition': [
        { prop: 'prec', name: 'Precipitation' },
        { prop: 'gust', name: 'Wind gust' },
        { prop: 'realValue', name: 'Value' }
    ],
    'field-accessibility': [
        { prop: 'prec', name: 'Precipitation' },
        { prop: 'realValue', name: 'Value' }
    ],
    'tillage-ability': [
        { prop: 'prec', name: 'Precipitation' },
        { prop: 'realValue', name: 'Value' }
    ],
    'sowing-window': [
        { prop: 'prec', name: 'Precipitation' },
        { prop: 'temp', name: 'Temperature' },
        { prop: 'soil_temp', name: 'Soil Temperature' },
        { prop: 'realValue', name: 'Value' }
    ],
    'tropical-sowing-window': [
        { prop: 'prec', name: 'Precipitation' },
        { prop: 'temp', name: 'Temperature' },
        { prop: 'soil_temp', name: 'Soil Temperature' },
        { prop: 'realValue', name: 'Value' }
    ],
    'plant-protection': [
        { prop: 'delta_t', name: 'Delta T' },
        { prop: 'temp', name: 'Temperature' },
        { prop: 'winspeed', name: 'Wind Speed' },
        { prop: 'gust', name: 'Wind gust' },
        { prop: 'hum', name: 'Relative humidity' },
        { prop: 'prec', name: 'Precipitation' },
        { prop: 'prec4h', name: 'Rain 4h' },
        { prop: 'prec24h', name: 'Rain 24h' },
        { prop: 'realValue', name: 'Value' },
    ],
    'harvest-window': [
      { prop: 'prec', name: 'Precipitation' },
      { prop: 'hum', name: 'Relative humidity' },
      { prop: 'temp', name: 'Temperature' },
      { prop: 'leaf', name: 'Leaf wetness' },
      { prop: 'realValue', name: 'Value' }
    ],
    'grass-fire-danger-index': [
      { prop: 'hum', name: 'Relative humidity' },
      { prop: 'temp', name: 'Temperature' },
      { prop: 'wind', name: 'Wind Speed' },
      { prop: 'realValue', name: 'GFDI' }
    ]
};
