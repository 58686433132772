import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MemoizedSelector, select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { IActionWithPayload } from '../../../../core/models/actionWithPayload';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';
import { DEFAULT_WEATHER_FORECAST_MODE, WEATHER_FORECAST_MODES } from '../../../weather-forecast/models/models';
import { setWorkPlanningSettingsIsChartActive, setWorkPlanningSettingsIsTableActive } from '../../actions';
import { IWorkPlanningSettingsState } from '../../models/work-planning.models';
import { selectWorkPlanningSettings, selectWorkPlanningSettingsIsChartActive, selectWorkPlanningSettingsIsTableActive } from '../../reducers';

@Component({
  selector: 'app-work-planning-toolbar',
  templateUrl: './work-planning-toolbar.component.html',
  styleUrls: ['./work-planning-toolbar.component.scss']
})
export class WorkPlanningToolbarComponent implements OnInit, OnDestroy {
  @Input()
  public currentMode: string = '';
  @Output()
  public changeModeEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  private exportChartImg: EventEmitter<boolean> = new EventEmitter<boolean>();
  public isExportChart: boolean = false;
  public form: FormGroup;
  public modeOptions = WEATHER_FORECAST_MODES;
  public imageExportInProgress$: Observable<boolean>;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private stationId: string;

  constructor(private formBuilder: FormBuilder,
              private workPlanningSettingsStore: Store<IWorkPlanningSettingsState>,
              private navigationStore: Store<INavigationStationState>,
              private exportService: StationDataExportService) { }

  public get modeControl(): AbstractControl {
    return this.form.get('mode');
  }

  public get isChartActiveControl(): AbstractControl {
    return this.form.get('isChartActive');
  }

  public get isTableActiveControl(): AbstractControl {
    return this.form.get('isTableActive');
  }

  public ngOnInit(): void {
    this.initForm();
    this.initStationChangeListener();
    this.initTogglers();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public startExportImage(): void {
    this.isExportChart = true;
    this.exportChartImg.emit(true);
    setTimeout(() => {
      this.isExportChart = false;
      this.exportChartImg.emit(false);
    }, 500);

  }

  public startExportXLS(): void {
    this.exportService.startExportXLS(this.stationId);
  }

  public refresh(): void {
    this.changeModeEmitter.emit(this.modeControl.value);
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      'mode': [DEFAULT_WEATHER_FORECAST_MODE.value],
      'isChartActive': [true],
      'isTableActive': [true],
    });
  }

  private initStationChangeListener(): void {
    this.navigationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((station: IStation): boolean => !!station),
      distinctUntilChanged(),
    ).subscribe((station: IStation): void => {
      this.stationId = station.name.original;
      this.initMode();
    });
  }

  private initMode(): void {
    this.workPlanningSettingsStore.pipe(
      take(1),
      select(selectWorkPlanningSettings)
    ).subscribe((WorkPlanningSettings: IWorkPlanningSettingsState): void => {
      if (!!WorkPlanningSettings.settings[this.stationId]) {
        this.modeControl.setValue(WorkPlanningSettings.settings[this.stationId].selectedMode);
      }
    });
  }

  private initTogglers(): void {
    this.initTogglerStatuses();
    this.initTogglerDispatchig();
  }

  private initTogglerStatuses(): void {
    this.setInitialTogglerStatus(this.isChartActiveControl, selectWorkPlanningSettingsIsChartActive);
    this.setInitialTogglerStatus(this.isTableActiveControl, selectWorkPlanningSettingsIsTableActive);
  }

  private setInitialTogglerStatus(control: AbstractControl, selector: MemoizedSelector<object, boolean>): void {
    this.workPlanningSettingsStore.pipe(
      take(1),
      select(selector)
    ).subscribe((status: boolean): void => {
      control.setValue(status);
    });
  }

  private initTogglerDispatchig(): void {
    this.initTogglerListener(this.isChartActiveControl, setWorkPlanningSettingsIsChartActive);
    this.initTogglerListener(this.isTableActiveControl, setWorkPlanningSettingsIsTableActive);
  }

  private initTogglerListener(control: AbstractControl, prepareActionCallback: (status: boolean) => IActionWithPayload): void {
    control.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe((status: boolean): void => {
      this.workPlanningSettingsStore.dispatch(prepareActionCallback(status));
    });
  }
}
