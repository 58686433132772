import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { MachinerySettingsActionTypes } from '../actions/machinery-settings';
// import {IIscoutSettingsState} from '../models/iscout.models';

export interface IMachineryDataState {
  data: IMachineryFormData;
  availableTypes: any;
  machineryBrands: any;
  addedMachinery: any;
  addedTrackers: any;
  lastAddedMachinery: string;
  isLoading: boolean;
  isError: boolean;
}

interface IMachineryFormData {
  machineryPlateNumber: string;
  machineryFrameNumber: string;
  machineryType: string;
  machineryNewType: string;
  machineryName: string;
  machineryDescription: string;
  machineryBrand: string;
  machineryModel: string;
  machineryPower: string;
  machineryPowerType: string;
  machineryPurchaseDate: string;
}

export const initialState: IMachineryDataState = {
  data: null,
  availableTypes: null,
  machineryBrands: null,
  addedMachinery: null,
  addedTrackers: null,
  lastAddedMachinery: null,
  isLoading: true,
  isError: false
};

export const machinerySettingsSelector = createFeatureSelector<IMachineryDataState>('machinery-settings');

export const selectMachineryTypes = createSelector(
  machinerySettingsSelector,
  (machinerySettingsState: IMachineryDataState): Array<any> => machinerySettingsState.availableTypes
);

export const selectMachineryBrands = createSelector(
  machinerySettingsSelector,
  (machinerySettingsState: IMachineryDataState): Array<any> => machinerySettingsState.machineryBrands
);

export const selectMachinery = createSelector(
  machinerySettingsSelector,
  (machinerySettingsState: IMachineryDataState): Object => machinerySettingsState.data
);

export const selectAddedMachinery = createSelector(
  machinerySettingsSelector,
  (machinerySettingsState: IMachineryDataState): Array<any> => machinerySettingsState.addedMachinery
);

export const selectAddedTrackers = createSelector(
  machinerySettingsSelector,
  (machinerySettingsState: IMachineryDataState): Array<any> => machinerySettingsState.addedTrackers
);

export const selectLastAddedMachinery = createSelector(
  machinerySettingsSelector,
  (machinerySettingsState: IMachineryDataState): string => machinerySettingsState.lastAddedMachinery
);


export function reducer(state: any = initialState, action: IActionWithPayload): any {
  switch (action.type) {
    case MachinerySettingsActionTypes.SET_MACHINERY:
      return <any>{
        ...state,
        data: action.payload
      };
    case MachinerySettingsActionTypes.SET_ADDED_MACHINERY:
      return <any>{
        ...state,
        addedMachinery: action.payload
      };
    case MachinerySettingsActionTypes.SET_ADDED_TRACKERS:
      return <any>{
        ...state,
        addedTrackers: action.payload
      };
    case MachinerySettingsActionTypes.SET_LAST_MACHINERY:
      return <any>{
        ...state,
        lastAddedMachinery: action.payload
      };
    case MachinerySettingsActionTypes.RESET_MACHINERY_DATA_STORE:
      return <any>{
        ...state,
        data: null,
        lastAddedMachinery: null
      };
    default:
      return state;
  }
}


