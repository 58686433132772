import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StationDataRoutingModule } from './station-data-routing.module';
import { StationDataComponent } from './station-data.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { StationDataContentComponent } from './containers/station-data-content/station-data-content.component';
import { StoreModule } from '@ngrx/store';
import { reducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { StationDataService } from './effects/station-data.service';
import { StationDataToolbarComponent } from './containers/station-data-toolbar/station-data-toolbar.component';
import { StationDataChartsComponent } from './containers/station-data-charts/station-data-charts.component';
import { AgGridModule } from 'ag-grid-angular';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { StationDataTableComponent } from './containers/station-data-table/station-data-table.component';
import { StationDataHeaderComponent } from './containers/station-data-header/station-data-header.component';
import { StationDataExportService } from '../../shared/services/export/station-data-export.service';
import { StationDataWarningComponent } from './containers/station-data-warning/station-data-warning.component';

@NgModule({
  imports: [
    CommonModule,
    StationDataRoutingModule,
    SharedModule,
    TranslateModule.forChild(),
    StoreModule.forFeature('stationData', reducers),
    EffectsModule.forFeature([StationDataService]),
    AgGridModule.withComponents([]),
    ChartModule
  ],
  declarations: [
    StationDataComponent,
    StationDataContentComponent,
    StationDataToolbarComponent,
    StationDataChartsComponent,
    StationDataTableComponent,
    StationDataHeaderComponent,
    StationDataWarningComponent,
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [ more, exporting ] }, // add as factory to your providers
    StationDataExportService
  ],
})
export class StationDataModule { }
