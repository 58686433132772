<ng-container>
  <app-camera-data-toolbar-iscout
    *ngIf="isIScout"
    [stationId]="stationChangedListener$ | async"
    (exportChart)="handleChartExport()"
    (exportTable)="handleTableExport()"
    (refresh)="handleRefresh($event)"
  ></app-camera-data-toolbar-iscout>

  <app-camera-data-toolbar-cropview
    *ngIf="isCropView"
    (exportChart)="handleChartExport()"
    (exportTable)="handleTableExport()"
    (refresh)="handleRefresh($event)"
  ></app-camera-data-toolbar-cropview>
</ng-container>
