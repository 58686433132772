import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromYieldConfig from './yield-config.reducer';

export interface IState {
  configData: fromYieldConfig.IDataSourcesState;
}

export const reducers = {
  configData: fromYieldConfig.reducer,
};

export const yieldConfig = createFeatureSelector<IState>('yield-config');

export const selectCropsLazyLoading = createSelector(
  yieldConfig,
  (yieldConfigState: IState) => yieldConfigState.configData.cropsLazyLoading
);

export const selectYieldConfigIsLoading = createSelector(
  yieldConfig,
  (yieldConfigState: IState) => yieldConfigState.configData.isLoading
);

export const selectDatasourcesRain = createSelector(
  yieldConfig,
  (yieldConfigState: IState) => yieldConfigState.configData.datasourcesRain
);

export const selectDatasourcesAirTemperature = createSelector(
  yieldConfig,
  (yieldConfigState: IState) => yieldConfigState.configData.datasourcesAirTemperature
);

export const rainDatasourceById = (id: string) => createSelector(
  yieldConfig,
  (yieldConfigState: IState) => yieldConfigState.configData.datasourcesRain.find(o => o.id === id)
);

export const airTemperatureDatasourceById = (id: string) => createSelector(
  yieldConfig,
  (yieldConfigState: IState) => yieldConfigState.configData.datasourcesAirTemperature.find(o => o.id === id)
);

export const cropById = (id: string) => createSelector(
  yieldConfig,
  (yieldConfigState: IState) => yieldConfigState.configData.cropsLazyLoading.find(o => o.id === id)
);

export const getCollectConfigData = createSelector(
  yieldConfig,
  (yieldConfigState: IState) => yieldConfigState.configData.collectConfigData
);
