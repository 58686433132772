<section class="widget-container">
  <app-chart-header [color]="widget.color"
                    [title]="widget.title"
                    [description]="widget.description"
                    [isDraggable]="isEdit$ | async"
                    [isClose]="isEdit$ | async"
                    (remove) = "removeCurrentWidget()"
                    [isEditable]="false"></app-chart-header>
  <app-form-layout [size]="'dashboard'">
    <!--<app-stations-list></app-stations-list>-->
    <app-stations-list-sort></app-stations-list-sort>
  </app-form-layout>
</section>
