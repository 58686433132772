<div>
  <div class="modal-header">
    <button aria-label="Close" class="close" type="button" (click)="handleClose()">
      <span aria-hidden="true">×</span>
    </button>

    <h3 class="modal-title text-center">{{ 'iScout Season Glue Boards' | translate }}</h3>
  </div>

  <div class="modal-body">
    <div class="modal-body-panel">
      <tabset>
        <tab *ngFor="let detection of availableGlueBoards"
             [heading]="'Glue board #' + (detection.position + 1)"
        >
          <table class="table table-striped table-responsive">
            <tbody>
              <tr>
                <th>{{ 'From' | translate }}</th>
                <td>{{ detection.from | date: 'yyyy-MM-dd HH:mm' }}</td>
              </tr>
              <tr>
                <th>{{ 'To' | translate }}</th>
                <td>{{ detection.to | date: 'yyyy-MM-dd HH:mm' }}</td>
              </tr>
              <ng-container *ngFor="let insectCount of getInsectCount(detection)">
                <tr>
                  <th>{{ insectCount.label }}</th>
                  <td>{{ insectCount.value }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </tab>
      </tabset>
    </div>

    <div class="button-wrapper">
      <div class="button">
        <app-custom-button
          [color]="'blue'"
          [content]="'Close' | translate"
          (click)="handleClose()"></app-custom-button>
      </div>
    </div>
  </div>
</div>
