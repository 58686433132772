<app-detailed-image [picture]="selectedPicture$ | async"
                    [currentDate]="getCurrentDateForImage((selectedPicture$ | async).time)"
                    [hasWritePermission]="hasWritePermission"
                    [measurementsAreActive]="false"
                    [cameraDrawingOptions]="cameraDrawingOptions$ | async"
                    [cameraDistance]="cameraDistance$ | async"
                    [enableRectangleMarking]="true">
  <ng-container slot="header">
    <app-crop-view-header
      [title]="(selectedPicture$ | async).time | date: 'medium'"
      [subtitle]="title"
      [enableHelpButton]="true"
      (openModal)="openHelpModal()"
    ></app-crop-view-header>
  </ng-container>

  <ng-container slot="toolbar">
    <app-crop-view-image-settings-toolbar [selectedPicture]="selectedPicture$ | async"
                                          [stationId]="stationId"
                                          [activeCameraId]="activeCameraId"
                                          [hasWritePermission]="hasWritePermission"
                                          [toolbarSettings$]="toolbarSettings$"
                                          [cameraDistance]="cameraDistance$ | async"
                                          [cameraDrawingOptions]="cameraDrawingOptions$ | async"
                                          (cameraDistanceEmitter)="changeCameraDistance($event)"
                                          (unselectPictureEmitter)="unselectPicture()">
    </app-crop-view-image-settings-toolbar>
  </ng-container>

  <ng-container slot="footer">
    <div class="panel panel-default crop-view-image-settings__custom-panel">
      <div class="panel-footer">
        {{ 'Press Ctrl and scroll on the image to zoom in and out' | translate }}
      </div>
    </div>
  </ng-container>
</app-detailed-image>

<app-info-modal [modalId]="helpModalId" [header]="'CropView Tracking'">
  <h5>{{ 'Drawing tool' | translate }}</h5>
  <p>
    {{
    'Use your mouse to draw objects on the image. If you wish to edit the drawn rectangles you can ' +
    'simply hover over the rectangle you wish to edit and use the edges of the rectangle to change ' +
    'its size or grab the center of the rectangle and drag the mouse to move the rectangle around. ' +
    'If you want to remove the drawn rectangle, simply select it by clicking on it with your left mouse ' +
    'button then hit the delete key on your keyboard to remove the rectangle.' | translate
    }}
  </p>

  <h5>{{ 'Tracking tool' | translate }}</h5>
  <p>
    {{
    'You can mark a rectangle by selecting it with the mouse click then pressing the ENTER key on ' +
    'your keyboard. Marked rectangles will be turned to color red. You can track the marked rectangles ' +
    'growth later in the Monitoring data page.' | translate
    }}
  </p>

  <h5>{{ 'Zooming into the picture to see details' | translate }}</h5>
  <p>{{ 'Press the button Crtl (Control) and zoom in and out with the mouse.' | translate }}</p>

  <br>
  <p><strong>{{ "Don't forget to SAVE once you are done." | translate }}</strong></p>
</app-info-modal>
