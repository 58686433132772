<app-tree
  [tree$]="tree$">
</app-tree>

<div class="body-view-content" *ngIf="cropZone$ | async as cropZone"
  [@placeContent]="state$ | async">

  <app-cropzone-page-header
    [pageName]="'Soil Moisture Sum' | translate">
  </app-cropzone-page-header>

  <div class="crop-zone-soil-moisture-sums-sm__container">
    <app-form-layout
      [shadow]="false">

      <app-crop-zone-soil-moisture-sums-sm-toolbar
        [cropZone]="cropZone">
      </app-crop-zone-soil-moisture-sums-sm-toolbar>

      <app-crop-zone-soil-moisture-sums-sm-charts *ngIf="!(isLoading || isError); else loadinfo">
      </app-crop-zone-soil-moisture-sums-sm-charts>

    </app-form-layout>
  </div>
</div>

<ng-template #loadinfo>
  <app-station-load-info
    [isError]="isError"
    [isLoading]="isLoading"
    [errorText]="'No data for selected crop zone'">
  </app-station-load-info>
</ng-template>
