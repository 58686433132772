import { IActionWithPayload } from '../models/actionWithPayload';
import { ISystemSensor } from '../../shared/interfaces';
import { Action } from '@ngrx/store';
import { IActionWithPayload as IAction } from '../models/actionWithPayload';
import { ICalibrationSetting } from '../models/system';

export const enum SystemActionsTypes {
    GET_SYSTEM_SENSORS = '[Account] GET_SYSTEM_SENSORS',
    SET_SYSTEM_SENSORS = '[Account] SET_SYSTEM_SENSORS',
    GET_CALIBRATION_SETTINGS = '[Account] GET_CALIBRATION_SETTINGS',
    SET_CALIBRATION_SETTINGS = '[Account] SET_CALIBRATION_SETTINGS',
}

export function getSystemSensors(): IActionWithPayload {
    return { type: SystemActionsTypes.GET_SYSTEM_SENSORS, payload: undefined };
}

export function setSystemSensors(systemSensors: { [index: number]: ISystemSensor }): IActionWithPayload {
    return { type: SystemActionsTypes.SET_SYSTEM_SENSORS, payload: systemSensors };
}

export function getCalibrationSettings(): Action {
    return { type: SystemActionsTypes.GET_CALIBRATION_SETTINGS };
}

export function setCalibrationSettings(payload: ICalibrationSetting[]): IAction {
    return { type: SystemActionsTypes.SET_CALIBRATION_SETTINGS, payload: payload };
}
