import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ApiServiceType } from '../../constants/api-services';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit {

  public serviceType = ApiServiceType;
  public term: string = '';

  constructor(private activatedRoute: ActivatedRoute) { }

  public ngOnInit(): void {
    this.activatedRoute.params.subscribe((value: Params) => {
      this.term = value['term'] || this.serviceType.FIELD_CLIMATE;
    });
  }

}
