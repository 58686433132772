import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MemoizedSelector, select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, pluck, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { NavigationService } from '../../../../core/services/navigation/navigation.service';
import { selectAnimalProductionTreeSettings } from '../../../../modules/animal-production/reducers';
import { selectTreeSettings as selectDiseaseTreeSettings } from '../../../../modules/station-disease/reducers';
import { selectWeatherForecastTreeSettings } from '../../../../modules/weather-forecast/reducers';
import { selectWorkPlanningTreeSettings } from '../../../../modules/work-planning/reducers';
import { INode, ISubNode, ITree, ITreeStructure } from '../../../../services/tree/models';
import { setGroupAndProfile } from '../../actions/tree-settings';
import { IStationTreeSettings, ITreeComponent, ITreeSettingsState } from '../../models/tree.models';

@Component({
  selector: 'app-tree-nested-links',
  templateUrl: './tree-nested-links.component.html',
  styleUrls: ['./tree-nested-links.component.scss']
})
export class TreeNestedLinksComponent implements ITreeComponent, OnInit, OnDestroy {
  public nestedLinkTree: ITree;
  public selectedStationId$: Observable<string>;
  public selectedStationId: string;
  public openedNode: string | number;
  public selectedNode: string | number;
  public selectedGroupId: string | number;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly routeToSelectorMap = new Map([
    ['work-planning', selectWorkPlanningTreeSettings],
    ['disease', selectDiseaseTreeSettings],
    ['animal-production', selectAnimalProductionTreeSettings],
    ['weather-forecast', selectWeatherForecastTreeSettings]
  ]);

  constructor(private navigationStationStore: Store<INavigationStationState>,
              private navigationService: NavigationService,
              private treeSettingStore: Store<ITreeSettingsState>,
              private activatedRoute: ActivatedRoute) {}

  public ngOnInit(): void {
    this.listenStationId();
    this.listenSettings();
  }

  public setTree(tree: ITreeStructure): void {
    this.nestedLinkTree = tree.trees.length > 0 ? tree.trees[0] : null;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public openNode(subNode: ISubNode): void {
    this.openedNode = subNode.id || subNode.name;
  }

  public isOpenNode(subNode: ISubNode): boolean {
    return this.openedNode === (subNode.id || subNode.name);
  }

  public onNodeClicked(node: INode | ISubNode, subNode: ISubNode): void {
    this.selectedNode = node.id;
    this.selectedGroupId = subNode.id;
    this.dispatchTreeSettings();
  }

  public isSelectedGroup(node: INode, subNode: ISubNode): boolean {
    return this.selectedNode === node.id && this.selectedGroupId === subNode.id;
  }

  private listenStationId(): void {
    this.selectedStationId$ = this.navigationStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((station: IStation): boolean => !!station),
      distinctUntilChanged((a: IStation, b: IStation): boolean => {
        return a.name.original === b.name.original;
      }),
      pluck('name', 'original')
    );
  }

  private listenSettings(): void {
    this.selectedStationId$.pipe(
      takeUntil(this.destroy$),
      filter((stationId: string): boolean => !!stationId),
      tap((stationId: string) => this.selectedStationId = stationId),
      switchMap(() => this.observeTreeSettingsStore())
    ).subscribe((treeSettings: ITreeSettingsState) => {
      this.selectedNode = treeSettings[this.selectedStationId].selectedProfile;
      this.selectedGroupId = this.openedNode = treeSettings[this.selectedStationId].selectedProfileGroup;
    });
  }

  private dispatchTreeSettings(): void {
    this.treeSettingStore.dispatch(setGroupAndProfile({
      stationId: this.selectedStationId,
      profile: '' + this.selectedNode,
      group: this.selectedGroupId
    }, this.navigationService.treeSetGroupProfileAction));
  }

  private treeSettingsSelector(): MemoizedSelector<any, ITreeSettingsState> {
    const routePath = this.activatedRoute.parent && this.activatedRoute.parent.routeConfig
      ? this.activatedRoute.parent.routeConfig.path
      : null;
    if (routePath) {
      if (routePath.includes('work-planning')) {
        return this.routeToSelectorMap.get('work-planning');
      } else if (routePath.includes('disease')) {
        return this.routeToSelectorMap.get('disease');
      } else if (routePath.includes('animal-production')) {
        return this.routeToSelectorMap.get('animal-production');
      } else if (routePath.includes('weather-forecast')) {
        return this.routeToSelectorMap.get('weather-forecast');
      }
    }
  }

  private observeTreeSettingsStore(): Observable<ITreeSettingsState> {
    return this.treeSettingStore.pipe(
      takeUntil(this.destroy$),
      select(this.treeSettingsSelector() || ''),
      filter(treeSettings => !!treeSettings),
      tap((treeSettings: ITreeSettingsState): void => {
        if (!treeSettings[this.selectedStationId] || !this.checkExistingModels(treeSettings[this.selectedStationId])) {
          this.initSettings();
        }
      }),
      filter((treeSettings: ITreeSettingsState): boolean => !!treeSettings[this.selectedStationId])
    );
  }

  private checkExistingModels(settings: IStationTreeSettings): boolean {
    if (settings.selectedProfileGroup) {
      return this.nestedLinkTree.subNodes.some(
        (node: ISubNode) => node.id === settings.selectedProfileGroup || node.name === settings.selectedProfileGroup);
    }
    return true;
  }

  private initSettings(): void {
    const subNode = this.nestedLinkTree.subNodes[0];
    const node = this.nestedLinkTree.subNodes[0].nodes[0] ? this.nestedLinkTree.subNodes[0].nodes[0] : subNode;
    this.selectedNode = this.selectedNode || node.id || node.name;
    this.selectedGroupId = this.selectedGroupId ||  subNode.id || subNode.name;
    this.dispatchTreeSettings();
  }
}
