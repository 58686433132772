import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { stationDataContentAnimations } from '../../../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../../../core/services/left-components-toggler/left-components-toggler.service';
import { SOIL_MOISTURE_PLOTBAND_EDITOR_MODAL_ID } from '../../../../shared/constants';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { status } from '../../selectors';
import { IStateSettings, IStateStatus } from '../../states';

@Component({
  selector: 'app-soil-moisture-content',
  templateUrl: './soil-moisture-content.component.html',
  styleUrls: ['./soil-moisture-content.component.scss'],
  animations: stationDataContentAnimations
})
export class SoilMoistureContentComponent implements OnInit, OnDestroy {

  @Input()
  public stationId:                 string;

  public PLOTBAND_EDITOR_MODAL_ID:  string = SOIL_MOISTURE_PLOTBAND_EDITOR_MODAL_ID;
  public HELP_MODAL_ID:             string = generateId();
  public status$:                   Observable<IStateStatus>;
  public state$:                    Observable<string> = this.leftComponentsToggler.getStationDataContentState();
  public exportChartImg:            boolean = false;

  private destroy$:                 Subject<boolean> = new Subject<boolean>();

  constructor(
    private settingsStore: Store<IStateSettings>,
    private leftComponentsToggler: LeftComponentsTogglerService,
    private modalService: ModalService
  ) {

    this.status$ = this.settingsStore.pipe(select(status));

  }

  public ngOnInit(): void {


  }

  public exportCharts(value : boolean): void {
    this.exportChartImg = value;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public openModal(): void {
    this.modalService.openModal(this.HELP_MODAL_ID);
  }

  public onClosedModal(event): void {
    this.modalService.closeModal(this.PLOTBAND_EDITOR_MODAL_ID);
  }

}
