import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as deepEqual from 'deep-equal';
import * as moment from 'moment';
import RRule from 'rrule';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ICropZone, IExceptions, IIrrigationEvents, IRainEvents } from '../../core/models/cropzones';
import { selectSelectedCropZone } from '../../core/reducers';
import * as fromSelectedCropzone from '../../core/reducers/selectedCropZone';
import { stationDataContentAnimations } from '../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../core/services/left-components-toggler/left-components-toggler.service';
import { NavigationService } from '../../core/services/navigation/navigation.service';
import { ITreeStructure } from '../../services/tree/models';
import { TreeService } from '../../services/tree/tree.service';
import { ADD_IRRIGATION_EVENT, OPEN_DAY_EVENTS } from '../../shared/constants';
import { IOptions } from '../../shared/interfaces';
import { ModalService } from '../../shared/modal/services/modal.service';
import { generateId } from '../dashboard/utils/makeWidget';
import { IAccount } from './../../core/reducers/account';
import { selectSettings } from './../../core/reducers/index';
import { setCalendarEvents, setIrrigationEvents, updateCropzone } from './actions/irrigation.action';
import { daysWeeks, irrigationTypes } from './constants/constants';
import { IIrrigationCalendarEvent, IManualCalendarEvent } from './models/models';
import { selectCalendarEvents } from './reducers';
import * as fromIrrigationEvents from './reducers/irrimet.reducer';
import * as fromIrrimet from './reducers/irrimet.reducer';

@Component({
  selector: 'app-irrimet',
  templateUrl: './irrimet.component.html',
  styleUrls: ['./irrimet.component.scss'],
  animations: stationDataContentAnimations
})
export class IrrimetComponent implements OnInit, OnDestroy {

  public editSingleEvent: boolean = false;
  public calendarEvents: any[];
  public cropzone: ICropZone;
  public irrigationForm: FormGroup;
  public rainForm: FormGroup;
  public deleteRecurringForm: FormGroup;
  public deleteEventForm: FormGroup;
  public ADD_IRRIGATION_EVENT: string = 'ADD_IRRIGATION_EVENT';
  public DELETE_RECURRING: string = 'DELETE_RECURRING';
  public DELETE_EVENT: string = 'DELETE_EVENT';
  public OPEN_DAY_EVENTS: string = 'OPEN_DAY_EVENTS';
  public date: Date;
  public endDate: Date;
  public maxDate: Date;
  public dateString: string;
  public state$: Observable<string>;
  public tree$: Observable<ITreeStructure> = this.treeService.getIrrimetTreeStructure();
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private rainDatasource    : any;
  public rainDivOpen: boolean = false;

  public panelStatus: any = {
    panelId: '',
    nextStatus: null
  };

  public eventToDelete: IIrrigationCalendarEvent;
  public selectedIrrigationType: any;
  public recurringEvent: boolean = false;
  public oldTitle: string;
  public oldStart: Date;
  public oldEnd: Date;
  public newEventOrEditEvent: boolean = false;
  public irrigationTypes: IOptions[] = irrigationTypes;
  public daysWeeks: IOptions[] = daysWeeks;
  public dayEvents: any[] = [];
  public eventDay: Date;
  public modalId: string = generateId();
  public unitSystem: string;
  public dayAndYear: string;
  public irrigationOrRainEvent: boolean = true;
  public precipitationType: string;
  public isSplitted: boolean;
  public hiddenSave: boolean = false;

  constructor(
    private treeService: TreeService,
    private leftComponentsToggler: LeftComponentsTogglerService,
    private modalService: ModalService,
    private fb: FormBuilder,
    private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private irrigationEventsStore: Store<fromIrrigationEvents.IIrrimetEventsState>,
    private irrimetStore: Store<fromIrrimet.IIrrimetEventsState>,
    private accountStore: Store<IAccount>,
    private translations: TranslateService,
    private navigationService: NavigationService
  ) {

  }

  public get endScheduleDate(): AbstractControl {
    return this.irrigationForm.get('endScheduleDate');
  }

  public get dayOrWeek(): AbstractControl {
    return this.irrigationForm.get('dayOrWeek');
  }

  public get toggle(): AbstractControl {
    return this.irrigationForm.get('toggle');
  }

  public get frequency(): AbstractControl {
    return this.irrigationForm.get('frequency');
  }

  public get irrigation_name(): AbstractControl {
    return this.irrigationForm.get('irrigation_name');
  }

  public get rain_event_name(): AbstractControl {
    return this.rainForm.get('rain_event_name');
  }

  public get irrigationType(): AbstractControl {
    return this.irrigationForm.get('irrigationType');
  }

  public get efficacy(): AbstractControl {
    return this.irrigationForm.get('efficacy');
  }

  public get duration(): AbstractControl {
    return this.irrigationForm.get('duration');
  }

  public get amountIrrigation(): AbstractControl {
    return this.irrigationForm.get('amountIrrigation');
  }

  public get amountRain(): AbstractControl {
    return this.rainForm.get('amountRain');
  }

  public get fromHourIrrigation(): AbstractControl {
    return this.irrigationForm.get('fromHourIrrigation');
  }

  public get fromHourRain(): AbstractControl {
    return this.rainForm.get('fromHourRain');
  }

  public get deleteRecurring(): AbstractControl {
    return this.deleteRecurringForm.get('deleteRecurring');
  }

  public get deleteEvent(): AbstractControl {
    return this.deleteEventForm.get('deleteEvent');
  }

  public ngOnInit(): void {
    this.initIrrigationForm();
    this.initRainForm();

    this.accountStore.pipe(
      select(selectSettings),
      filter((settings) => !!settings)
    ).subscribe(settings => this.unitSystem = settings.unit_system);

    this.selectedCropzoneStore.pipe(
      select(selectSelectedCropZone),
      filter((c: any) => !!c),
      takeUntil(this.destroy$)
    ).subscribe(cropzone => {
      this.cropzone = cropzone;
      this.rainDatasource = null;
      this.irrigationEventsStore.dispatch(
        setIrrigationEvents(cropzone.irrigation_events)
      );
      this.cropzone.data_sources.forEach(datasource => {
        if (datasource.module === 'IRRIMET') {
          if (datasource.source.type === 'SENSOR') {
            this.rainDatasource = datasource;
          }
        }
      });
    });

    this.irrigationEventsStore.pipe(
      select(selectCalendarEvents),
      takeUntil(this.destroy$)
    ).subscribe(calendarEvents =>
      this.calendarEvents = calendarEvents
    );

    this.deleteRecurringForm = this.fb.group({
      'deleteRecurring': ['one', [Validators.required]]
    });

    this.deleteEventForm = this.fb.group({
      'deleteEvent': ['yes', [Validators.required]]
    });

    this.toggle.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(res => {
      this.recurringEvent = res === true;
    });

    this.state$ = this.leftComponentsToggler.getStationDataContentState();

    this.irrigationType.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(res => {
      switch (res) {
        case 'FLOOD':
          this.selectedIrrigationType = 'FLOOD';
          this.efficacy.setValidators([Validators.required, Validators.min(0.3), Validators.max(0.8)]);
          this.efficacy.setValue(0.6);

          break;
        case 'FURROW':
          this.selectedIrrigationType = 'FURROW';
          this.efficacy.setValidators([Validators.required, Validators.min(0.3), Validators.max(0.8)]);
          this.efficacy.setValue(0.6);

          break;
        case 'PIVOT':
          this.selectedIrrigationType = 'PIVOT';
          this.efficacy.setValidators([Validators.required, Validators.min(0.6), Validators.max(0.9)]);
          this.efficacy.setValue(0.8);

          break;
        case 'SPRINKLER':
          this.selectedIrrigationType = 'SPRINKLER';
          this.efficacy.setValidators([Validators.required, Validators.min(0.6), Validators.max(0.9)]);
          this.efficacy.setValue(0.8);

          break;
        case 'MICRO_SPRINKLER':
          this.selectedIrrigationType = 'MICRO_SPRINKLER';
          this.efficacy.setValidators([Validators.required, Validators.min(0.7), Validators.max(0.95)]);
          this.efficacy.setValue(0.9);

          break;
        case 'DRIP':
          this.selectedIrrigationType = 'DRIP';
          this.efficacy.setValidators([Validators.required, Validators.min(0.7), Validators.max(0.95)]);
          this.efficacy.setValue(0.9);

          break;
        case 'SUBSURFACE_DRIP':
          this.selectedIrrigationType = 'SUBSURFACE_DRIP';
          this.efficacy.setValidators([Validators.required, Validators.min(0.8), Validators.max(1)]);
          this.efficacy.setValue(0.95);

          break;
      }
    });

    this.fromHourIrrigation.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(val => {
      if (this.eventToDelete && val) {
        const eventInOneDay: boolean = moment(this.eventToDelete.start).isSame(moment(this.eventToDelete.end), 'day');
        if (24 - val.hour >= this.eventToDelete.duration / 60 && !eventInOneDay) {
          this.hiddenSave = true;
        } else {
          this.hiddenSave = false;
        }
      }
    });


    this.irrigationForm.get('dayOrWeek').valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(val => {
      if (val === 'hours') {
        this.frequency.setValidators(Validators.min(24));
        this.frequency.setValue(this.irrigationForm.value.frequency);
      } else {
        this.frequency.setValidators(Validators.min(1));
        this.frequency.setValue(this.irrigationForm.value.frequency);
      }
    });
  }

  public deleteRecurringModal(): void {
    this.modalService.closeModal(OPEN_DAY_EVENTS);
    this.modalService.closeModal(this.ADD_IRRIGATION_EVENT);
    this.modalService.openModal(this.DELETE_RECURRING);
  }

  public deleteRecurringModal2(event): void {
    this.eventToDelete = event;
    this.deleteRecurringModal();
  }

  public deleteModal(): void {
    this.modalService.closeModal(OPEN_DAY_EVENTS);
    this.modalService.closeModal(this.ADD_IRRIGATION_EVENT);
    this.modalService.openModal(this.DELETE_EVENT);
  }

  public deleteModal2(event): void {
    this.eventToDelete = event;
    this.deleteModal();
  }

  public closeDayEventsModal(): void {
    this.modalService.closeModal(OPEN_DAY_EVENTS);
  }

  public openModal(event: Date): void {
    this.hiddenSave = false;
    this.rainDivOpen = false;
    this.newEventOrEditEvent = true;
    this.editSingleEvent = false;
    this.date = new Date(event);
    this.dayOrWeek.setValue('days');
    this.endScheduleDate.setValue(event);
    this.maxDate = new Date(event.getFullYear() + 2, event.getMonth(), event.getDate());
    const newDate = moment(event).format('MMM DD, YYYY');
    this.translations.get(newDate.substring(0, 3))
      .subscribe(translatedMonth => {
          this.dayAndYear = newDate.substring(3, 12);
          this.dateString = translatedMonth + this.dayAndYear;
      });

    if (event.getHours() !== 0 || event.getMinutes() !== 0) {
      this.fromHourIrrigation.setValue({ hour: event.getHours(), minute: event.getMinutes() });
      this.fromHourRain.setValue({ hour: event.getHours(), minute: event.getMinutes() });
    } else {
      this.fromHourIrrigation.setValue({ hour: 0, minute: 0 });
      this.fromHourRain.setValue({ hour: 0, minute: 0 });
    }

    if (this.unitSystem === 'imperial') {
      this.amountIrrigation.setValue(0.4);
      this.amountRain.setValue(0.4);
    }

    this.modalService.openModal(ADD_IRRIGATION_EVENT);
  }

  public openEventListModal($event: any): void {
    this.isSplitted = false;
    const day = $event.day;
    const type = $event.eventType;
    this.isSplitted = $event.isSplitted;

    this.eventDay = day.date;
    this.dayEvents = [];
    this.precipitationType = null;

    const filteredEventsOnDay = day.events.filter((event) => event.eventType === type);

    filteredEventsOnDay.forEach((event: any) => {
      if (type === 'manual') {
        this.dayEvents.push({event: event, isSingleEvent: true});
      }
      if (type === 'irrigation') {
        this.dayEvents.push({event: event, isSingleEvent: this.isSingleEvent(event)});
      }
    });

    this.precipitationType = type;
    this.modalService.openModal(OPEN_DAY_EVENTS);
  }

  public isSingleEvent(event: IIrrigationCalendarEvent): boolean {
    const convertedEvent = this.calendarEventToIrrigationEvent(event);

    let isSingleEvent: boolean = false;
    for (let i = 0; i < this.cropzone.irrigation_events.length; i++) {
      if (deepEqual(convertedEvent, this.cropzone.irrigation_events[i])) {
        isSingleEvent = true;
      }
    }
    return isSingleEvent;
  }

  public editModal(eventx): void {
    this.hiddenSave = false;
    this.formReset();
    this.modalService.closeModal(OPEN_DAY_EVENTS);
    this.eventToDelete = eventx.event;
    this.dateString = moment(eventx.event.start).format('MMM DD, YYYY');
    this.editSingleEvent = eventx.isSingleEvent;
    this.newEventOrEditEvent = false;

    if (eventx.event.eventType === 'manual') {
      let event: IManualCalendarEvent = eventx.event;

      if (this.unitSystem === 'imperial') {
        event = {...event, amount: event.amount / 25.4};
      }

      this.date = new Date(event.start);
      this.rain_event_name.setValue(event.title);
      this.amountRain.setValue(event.amount);
      this.fromHourRain.setValue({ hour: event.start.getHours(), minute: event.start.getMinutes() });

      this.irrigationOrRainEvent = false;
      this.rainDivOpen = true;
    } else if (eventx.event.eventType === 'irrigation') {
      let event: IIrrigationCalendarEvent = eventx.event;

      if (this.unitSystem === 'imperial') {
        event = {...event, amount: event.amount / 25.4};
      }

      this.date = new Date(event.start);
      this.endScheduleDate.setValue(event.end),
      this.irrigation_name.setValue(event.title);
      this.irrigationType.setValue(event.type);
      this.efficacy.setValue(event.efficacy);
      this.duration.setValue(event.duration);
      this.amountIrrigation.setValue(event.amount);
      this.fromHourIrrigation.setValue({ hour: event.start.getHours(), minute: event.start.getMinutes() });
      this.dayOrWeek.setValue('days');
      this.irrigationOrRainEvent = true;
      this.rainDivOpen = false;
    }

    this.modalService.openModal(ADD_IRRIGATION_EVENT);
  }

  public editRain(): void {
    if (this.cropzone.manualRainGauge) {
      this.date.setHours(this.rainForm.value.fromHourRain.hour, this.rainForm.value.fromHourRain.minute);

      const newRainEvent: IRainEvents = {
        title: this.rainForm.value.rain_event_name,
        amount: parseFloat(this.rainForm.value.amountRain),
        date: this.dateToAdjustedISO(this.date)
      };

      const newCalendarEvent: IManualCalendarEvent = {
        title: this.rainForm.value.rain_event_name,
        amount: parseFloat(this.rainForm.value.amountRain),
        start: this.date,
        eventType: 'manual'
      };

      const eventToDelete: IRainEvents = {
        title: this.eventToDelete.title,
        amount: this.eventToDelete.amount,
        date: this.dateToAdjustedISO(this.eventToDelete.start)
      };

      this.cropzone.rain_events.forEach((rainEvent, index) => {
        rainEvent.date =  this.dateToAdjustedISO(moment(rainEvent.date).toDate());
        if (deepEqual(eventToDelete, rainEvent)) {
          this.cropzone.rain_events[index] = newRainEvent;

          if (this.unitSystem === 'imperial') {
            this.cropzone.rain_events[index].amount *= 25.4;
          }
        }
      });

      this.calendarEvents.forEach((event, index) => {
        if (deepEqual(event, this.eventToDelete)) {
          this.calendarEvents[index] = newCalendarEvent;
        }
      });

      this.irrigationEventsStore.dispatch(setCalendarEvents(this.calendarEvents));
      this.closeAddModal();
      this.irrimetStore.dispatch(updateCropzone(this.cropzone));
    }
  }

  public editIrrigation(): void {
    const oldDate = moment(JSON.parse(JSON.stringify(this.date))).toDate();
    this.date.setHours(this.irrigationForm.value.fromHourIrrigation.hour, this.irrigationForm.value.fromHourIrrigation.minute);

    const newCalendarEvent: IIrrigationCalendarEvent = {
      title: this.irrigationForm.value.irrigation_name,
      start: this.date,
      end: moment(this.date).add(this.irrigationForm.value.duration, 'minutes').toDate(),
      type: this.irrigationForm.value.irrigationType,
      efficacy: parseFloat(this.irrigationForm.value.efficacy),
      duration: parseInt(this.irrigationForm.value.duration, 10),
      fromHour: this.irrigationForm.value.fromHourIrrigation,
      frequency: this.eventToDelete.frequency,
      amount: parseFloat(this.irrigationForm.value.amountIrrigation),
      index: this.eventToDelete.index,
      eventType: 'irrigation'
    };

    if (this.isSingleEvent(this.eventToDelete)) {
      const irrigationEventToDelete: IIrrigationEvents = {
        title: this.eventToDelete.title,
        from: this.dateToAdjustedISO(oldDate),
        to: this.dateToAdjustedISO(moment(oldDate).add(this.eventToDelete.duration, 'minutes').toDate()),
        type: this.eventToDelete.type,
        efficacy: this.eventToDelete.efficacy,
        duration: this.eventToDelete.duration,
        frequency: this.eventToDelete.frequency,
        amount: this.eventToDelete.amount,
        exceptions: []
      };

      const newIrrigation: IIrrigationEvents = {
        title: this.irrigationForm.value.irrigation_name,
        from: this.dateToAdjustedISO(this.date),
        to: this.dateToAdjustedISO(moment(this.date).add(this.eventToDelete.duration, 'minutes').toDate()),
        type: this.irrigationForm.value.irrigationType,
        efficacy: parseFloat(this.irrigationForm.value.efficacy),
        duration: parseInt(this.irrigationForm.value.duration, 10),
        frequency: this.eventToDelete.frequency,
        amount: parseFloat(this.irrigationForm.value.amountIrrigation),
        exceptions: []
      };

      this.cropzone.irrigation_events.forEach((event, index) => {
        if (deepEqual(event, irrigationEventToDelete)) {
          this.cropzone.irrigation_events[index] = newIrrigation;
        }
      });

      this.calendarEvents.forEach((event, index) => {
        if (deepEqual(event, this.eventToDelete)) {
          this.calendarEvents[index] = newCalendarEvent;
        }
      });

      this.irrimetStore.dispatch(updateCropzone(this.cropzone));

    } else {
      newCalendarEvent.index = this.eventToDelete.index;
      newCalendarEvent.frequency = this.eventToDelete.frequency;

      // tslint:disable-next-line:max-line-length
      this.calendarEvents = this.calendarEvents.map(event => event = this.editRecurringEvent(event, newCalendarEvent, this.eventToDelete.index));
    }

    this.irrigationEventsStore.dispatch(setCalendarEvents(this.calendarEvents));
    this.closeAddModal();
  }

  private editRecurringEvent(event: any, calendarEvent: IIrrigationCalendarEvent, index: number): IIrrigationCalendarEvent {
    calendarEvent.index = this.eventToDelete.index;
    calendarEvent.frequency = this.eventToDelete.frequency;
    const irrigationEventToEdit = this.calendarEventToIrrigationEvent(calendarEvent);

    if (deepEqual(event, this.eventToDelete)) {

      let exception: IExceptions = {
        date: irrigationEventToEdit.from,
        amount: calendarEvent.amount,
        duration: irrigationEventToEdit.duration
      };

      if (this.unitSystem === 'imperial') {
        exception = {...exception, amount: exception.amount *= 25.4};
      }

      const exceptions = this.cropzone.irrigation_events[index].exceptions;

      const location = exceptions.findIndex(
        element => {
          return moment(exception.date).toDate().getDate() === moment(element.date).toDate().getDate() &&
          moment(exception.date).toDate().getMonth() === moment(element.date).toDate().getMonth();
        }
      );

      if (location >= 0) {
        exceptions[location] = exception;
      } else {
        exceptions.push(exception);
      }

      this.irrimetStore.dispatch(updateCropzone(this.cropzone));

      return calendarEvent;
    } else {
      return event;
    }
  }

  public submitRain(): void {
    this.date.setHours(this.rainForm.value.fromHourRain.hour, this.rainForm.value.fromHourRain.minute);
    let rainEvent: IRainEvents = {
      title: this.rainForm.value.rain_event_name,
      date: this.dateToAdjustedISO(this.date),
      amount: parseFloat(this.rainForm.value.amountRain)
    };

    if (this.unitSystem === 'imperial') {
      rainEvent = {...rainEvent, amount: rainEvent.amount * 25.4};
    }

    if (!this.cropzone.rain_events) {
      this.cropzone.rain_events = [];
    }

    this.cropzone.rain_events.push(rainEvent);
    this.irrimetStore.dispatch(updateCropzone(this.cropzone));
    this.closeAddModal();
  }

  public submitIrrigation(): void {
    this.date.setHours(this.irrigationForm.value.fromHourIrrigation.hour, this.irrigationForm.value.fromHourIrrigation.minute);

    let submitObject: IIrrigationEvents;
      if (this.recurringEvent === true) {
        submitObject = {
          title: this.irrigationForm.value.irrigation_name,
          from: this.dateToAdjustedISO(this.date),
          duration: parseInt(this.duration.value, 10),
          type: this.irrigationForm.value.irrigationType,
          efficacy: parseFloat(this.irrigationForm.value.efficacy),
          amount: parseFloat(this.irrigationForm.value.amountIrrigation),
          recurringEvent: this.recurringEvent,
          frequency: this.irrigationForm.value.frequency,
          dayOrWeek: this.irrigationForm.value.dayOrWeek,
          exceptions: null,
          to: this.dateToAdjustedISO(this.irrigationForm.value.endScheduleDate)
        };

        if (this.irrigationForm.value.dayOrWeek === 'days') {
          submitObject.dayOrWeek = 'hours';
          submitObject.frequency = this.irrigationForm.value.frequency * 24;
        }
        this.addRecurringEvent();
      } else {
        this.addSingleEvent();
    }

    this.closeAddModal();
  }

  private addSingleEvent(): void {
    let irrigationEvent: IIrrigationEvents = {
      title: this.irrigationForm.value.irrigation_name,
      from: this.dateToAdjustedISO(this.date),
      to: this.dateToAdjustedISO(moment(this.date).add(parseInt(this.duration.value, 10), 'minutes').toDate()),
      duration: parseInt(this.duration.value, 10),
      efficacy: parseFloat(this.irrigationForm.value.efficacy),
      type: this.irrigationForm.value.irrigationType,
      amount: parseFloat(this.irrigationForm.value.amountIrrigation),
      frequency: 24,
      exceptions: []
    };

    if (this.unitSystem === 'imperial') {
      irrigationEvent = {...irrigationEvent, amount: irrigationEvent.amount * 25.4};
    }

    this.cropzone.irrigation_events.push(irrigationEvent);
    this.irrimetStore.dispatch(updateCropzone(this.cropzone));

    let calendarEvent: IIrrigationCalendarEvent = {
      title: this.irrigationForm.value.irrigation_name,
      start: moment(this.date).toDate(),
      duration: parseInt(this.duration.value, 10),
      fromHour: new Date(this.date).getHours(),
      end: moment(this.date).add(parseInt(this.duration.value, 10), 'minutes').toDate(),
      efficacy: parseFloat(this.irrigationForm.value.efficacy),
      type: this.irrigationForm.value.irrigationType,
      amount: parseFloat(this.irrigationForm.value.amountIrrigation),
      frequency: 24,
      eventType: 'irrigation'
    };

    if (this.unitSystem === 'imperial') {
      calendarEvent = {...calendarEvent, amount: calendarEvent.amount * 25.4};
    }

    this.calendarEvents = [
      ...this.calendarEvents,
      calendarEvent
    ];

    this.irrigationEventsStore.dispatch(setCalendarEvents(this.calendarEvents));
    this.irrigationForm.reset();
  }

  private addRecurringEvent(): void {
    let rrule: RRule;
    if (this.irrigationForm.value.dayOrWeek === 'days') {
      rrule = new RRule({
        freq: RRule.HOURLY,
        dtstart: new Date(this.date),
        until: new Date(this.irrigationForm.value.endScheduleDate),
        interval: this.irrigationForm.value.frequency * 24
      });
    } else {
      rrule = new RRule({
        freq: RRule.HOURLY,
        dtstart: new Date(this.date),
        until: new Date(this.irrigationForm.value.endScheduleDate),
        interval: this.irrigationForm.value.frequency
      });
    }

    let irrigationEvent: IIrrigationEvents = {
      title: this.irrigationForm.value.irrigation_name,
      from: this.dateToAdjustedISO(this.date),
      duration: parseInt(this.duration.value, 10),
      type: this.irrigationForm.value.irrigationType,
      efficacy: parseFloat(this.irrigationForm.value.efficacy),
      amount: parseFloat(this.irrigationForm.value.amountIrrigation),
      frequency: this.irrigationForm.value.frequency,
      exceptions: [],
      to: this.dateToAdjustedISO(this.irrigationForm.value.endScheduleDate)
    };

    if (this.unitSystem === 'imperial') {
      irrigationEvent = {...irrigationEvent, amount: irrigationEvent.amount * 25.4};
    }

    if (this.irrigationForm.value.dayOrWeek === 'days') {
      irrigationEvent.frequency = irrigationEvent.frequency * 24;
    }

    this.cropzone.irrigation_events.push(irrigationEvent);
    this.irrimetStore.dispatch(updateCropzone(this.cropzone));

    rrule.all().forEach(date => {
      let calendarEvent: IIrrigationCalendarEvent = {
        title: this.irrigationForm.value.irrigation_name,
        start: moment(date).toDate(),
        duration: parseInt(this.duration.value, 10),
        type: this.irrigationForm.value.irrigationType,
        fromHour: new Date(date).getHours(),
        efficacy: parseFloat(this.irrigationForm.value.efficacy),
        end: moment(date).add(parseInt(this.duration.value, 10), 'minutes').toDate(),
        amount: parseFloat(this.irrigationForm.value.amountIrrigation),
        frequency: 24,
        index: this.cropzone.irrigation_events.length - 1,
        eventType: 'irrigation'
      };

      if (this.unitSystem === 'imperial') {
        calendarEvent = {...calendarEvent, amount: calendarEvent.amount * 25.4};
      }

      this.calendarEvents = [
        ...this.calendarEvents,
        calendarEvent
      ];
    });
    this.irrigationEventsStore.dispatch(setCalendarEvents(this.calendarEvents));
  }

  public closeModal(): void {
    this.rainDivOpen = false;
    this.closeAddModal();
  }

  public formReset(): void {
    this.rain_event_name.setValue('');
    this.amountRain.setValue(10);
    this.fromHourRain.setValue('');
    this.selectedIrrigationType = '';
    this.irrigation_name.setValue('');
    this.amountIrrigation.setValue(10);
    this.fromHourIrrigation.setValue('');
    this.duration.setValue('');
    this.toggle.setValue('');
    this.frequency.setValue(1);
    this.dayOrWeek.setValue('days');
    this.endScheduleDate.setValue(new Date());
  }

  public closeConfirmationModal(): void {
    this.modalService.closeModal(this.DELETE_RECURRING);
  }

  public closeConfirmationEventModal(): void {
    this.modalService.closeModal(this.DELETE_EVENT);
  }

  public closeAddModal(): void {
    this.rainForm.reset();
    this.irrigationForm.reset();
    if (this.irrigationForm.value.dayOrWeek) {
      this.irrigationForm.value.dayOrWeek.setValue('days');
    }
    this.modalService.closeModal(ADD_IRRIGATION_EVENT);
  }

  public dateToAdjustedISO(date: Date): string {
    let isoString: string = date.toISOString();
    isoString = isoString.substring(0, isoString.length - 5);
    isoString += '+00:00';
    return isoString;
  }

  public deleteRecurringSubmit(): void {
    this.eventToDelete.amount = 0;
    const convertedEvent = this.calendarEventToIrrigationEvent(this.eventToDelete);

    const index = this.eventToDelete.index;

    if (this.deleteRecurring.value === 'one') {
      this.deleteRecurringEventOne(this.eventToDelete, convertedEvent, index);
    } else if (this.deleteRecurring.value === 'following') {
      this.deleteRecurringEventFollowing(this.eventToDelete, index);
    } else if (this.deleteRecurring.value === 'all') {
      this.deleteRecurringEventAll(index);
    }

    this.closeConfirmationModal();
  }

  private getRRuleOfIrrigationEvent(index: number): RRule {
    const subtractedDates = (<any>moment(this.cropzone.irrigation_events[index].to).toDate().getTime() / 1000)
      - (<any>moment(this.cropzone.irrigation_events[index].from).toDate().getTime() / 1000);
    const frequencyInSeconds = this.cropzone.irrigation_events[index].frequency * 60 * 60;
    const count = Math.floor(subtractedDates / frequencyInSeconds) + 1;

    const rule: RRule = new RRule({
      freq: RRule.HOURLY,
      dtstart: moment(this.cropzone.irrigation_events[index].from).toDate(),
      interval: this.cropzone.irrigation_events[index].frequency,
      count: count
    });

    return rule;
  }

  private deleteRecurringEventOne(event: any, convertedEvent: any, index: number): void {
    this.calendarEvents = this.calendarEvents.map(eventx => {
      if (deepEqual(eventx, event)) {
        const exception: IExceptions = {
          date: convertedEvent.from,
          amount: 0,
          duration: convertedEvent.duration
        };
        this.cropzone.irrigation_events[index].exceptions.push(exception);
        this.irrimetStore.dispatch(updateCropzone(this.cropzone));

        return eventx = event;
      }
      return eventx;
    });
    this.irrigationEventsStore.dispatch(setCalendarEvents(this.calendarEvents));
  }

  private deleteRecurringEventFollowing(event: any, index: number): void {
    const rule: RRule = this.getRRuleOfIrrigationEvent(index);

    rule.all().forEach(date => {
      if (date >= event.start) {
        const exception: IExceptions = {
          date: this.dateToAdjustedISO(date),
          amount: 0,
          duration: event.duration
        };
        this.cropzone.irrigation_events[index].exceptions.push(exception);
      }
    });

    this.calendarEvents = this.calendarEvents.map(eventx => {
      if (index === eventx.index && eventx.start >= event.start) {
        return {
          ...eventx,
          amount: 0
        };
      } else {
        return eventx;
      }
    });

    this.irrigationEventsStore.dispatch(setCalendarEvents(this.calendarEvents));
    this.irrimetStore.dispatch(updateCropzone(this.cropzone));
  }

  private deleteRecurringEventAll(index: number): void {
    this.cropzone.irrigation_events.splice(index, 1);
    this.calendarEvents = this.calendarEvents.filter((event) => event.index !== index);
    this.irrimetStore.dispatch(updateCropzone(this.cropzone));
    this.irrigationEventsStore.dispatch(setCalendarEvents(this.calendarEvents));
  }

  public panelChange(change): void {
    this.panelStatus = change;
  }

  public deleteSubmit(): void {
    if (
      this.eventToDelete.hasOwnProperty('type') &&
      this.eventToDelete.eventType === 'irrigation'
      ) {
      if (this.deleteEvent.value === 'yes') {
        this.deleteSingleEvent(this.eventToDelete);
      }
    } else {
      const rainEventToDelete: any = {
        title: this.eventToDelete.title,
        amount: this.eventToDelete.amount,
        date: this.dateToAdjustedISO(this.eventToDelete.start)
      };

      this.cropzone.rain_events.forEach((rainEvent, index) => {
        rainEvent.date =  this.dateToAdjustedISO(moment(rainEvent.date).toDate());
        if (deepEqual(rainEventToDelete, rainEvent)) {
          this.cropzone.rain_events.splice(index, 1);
        }
      });

      this.calendarEvents.forEach((event, index) => {
        if (deepEqual(event, this.eventToDelete)) {
          this.calendarEvents.splice(index, 1);
        }
      });

      this.irrimetStore.dispatch(updateCropzone(this.cropzone));
      this.irrigationEventsStore.dispatch(setCalendarEvents(this.calendarEvents));
    }

    this.closeConfirmationEventModal();
  }

  private deleteSingleEvent(event: any): void {
    const convertedEvent = this.calendarEventToIrrigationEvent(event);

    let index: number;
    for (let i = 0; i < this.cropzone.irrigation_events.length; i++) {
      if (deepEqual(convertedEvent, this.cropzone.irrigation_events[i])) {
        index = i;
      }
    }

    this.cropzone.irrigation_events.splice(index, 1);
    this.irrimetStore.dispatch(updateCropzone(this.cropzone));

    const calendarEventsWithoutDeletedEvent = this.calendarEvents.filter(eventx => eventx !== event);
    this.irrigationEventsStore.dispatch(setCalendarEvents(calendarEventsWithoutDeletedEvent));
  }

  public calendarEventToIrrigationEvent(calendarEvent: IIrrigationCalendarEvent): IIrrigationEvents {
    const event: IIrrigationEvents = {
      title: calendarEvent.title,
      from: this.dateToAdjustedISO(calendarEvent.start),
      to: this.dateToAdjustedISO(calendarEvent.end),
      duration: calendarEvent.duration,
      efficacy: calendarEvent.efficacy,
      type: calendarEvent.type,
      amount: calendarEvent.amount,
      frequency: calendarEvent.frequency,
      exceptions: []
    };
    return event;
  }

  public calendarEventToRainEvent(calendarEvent: IManualCalendarEvent): IRainEvents {
    const event: IRainEvents = {
      title: calendarEvent.title,
      amount: calendarEvent.amount,
      date: this.dateToAdjustedISO(calendarEvent.start)
    };
    return event;
  }

  public openHelpModal(): void {
    this.modalService.openModal(this.modalId);
  }

  public openRainDiv(opened): void {
    this.rainDivOpen = opened;
  }

  private initIrrigationForm(): void {
    this.irrigationForm = this.fb.group({
      'irrigation_name': ['', [Validators.required]],
      'irrigationType': ['', []],
      'efficacy': ['', [Validators.required]],
      'amountIrrigation': [10, [Validators.required]],
      'fromHourIrrigation': [{ hour: 0, minute: 0 }, [Validators.required]],
      'duration': ['', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.max(1439),
        Validators.min(0),
        Validators.maxLength(4)]
      ],
      'toggle': ['', []],
      'frequency': [1, []],
      'dayOrWeek': ['days', [Validators.required]],
      'endScheduleDate': [new Date(), []]
    });
  }

  private initRainForm(): void {
    this.rainForm = this.fb.group({
      'rain_event_name': ['', [Validators.required]],
      'amountRain': [10, [Validators.required]],
      'fromHourRain': [{ hour: 0, minute: 0 }, [Validators.required]]
    });
  }

  public metricOrImperial(amount: number, unitSystem: string): number {
    if (unitSystem === 'metric') {
      return amount;
    } else {
      return amount / 25.4;
    }
  }

  public routeRainCorrector(): void {
    localStorage.setItem('routeCropzone', this.cropzone.id);
    this.navigationService.navigateToRainCorrector(this.rainDatasource.device_name);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
