import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { soilMoistureTreeReducer } from '../../../shared/tree/reducers/tree-settings';
import { IView, IChart } from '../models';
import { initialDataState, initialSettingsState, defaultPredefinedViews, IStateData, IStateSettings } from '../states';
import { ActionTypes } from '../actions';
import { ViewType } from '../constants';


export const reducers = {
  data: reducerData,
  settings: reducerSettings,
  tree: soilMoistureTreeReducer
};


export function reducerData(state: IStateData = initialDataState, action: IAction): IStateData {
  switch (action.type) {
    case ActionTypes.SET_VIEWS:
      return {
        ...state,
        views: [
          ...defaultPredefinedViews.map(
            defaultView => action.payload.find(
              (view: IView) => view.type === ViewType.PREDEFINED && view.template === defaultView.template
            ) || JSON.parse(JSON.stringify(defaultView))
          ),
          ...action.payload.filter((view: IView) => view.type === ViewType.CUSTOM)
        ]
      };
    case ActionTypes.SET_VIEW:
      return {
        ...state,
        views: state.views.find(view => viewsEqual(view, action.payload)) ?
          state.views.map(view => viewsEqual(view, action.payload) ? action.payload : view) : [...state.views, action.payload]
      };
    case ActionTypes.UNSET_VIEW:
      return {
        ...state,
        views: [
          ...state.views.filter(view => view._id !== action.payload._id)
        ]
      };
    case ActionTypes.SET_CHART:
      return {
        ...state,
        chart: reorderChartResponse(action.payload)
      };
    case ActionTypes.SET_SOIL_MOISTURE_SENSORS:
      return {
        ...state,
        soilMoistureSensors: action.payload
      };
    default:
      return state;
  }
}

export function reducerSettings(state: IStateSettings = initialSettingsState, action: IAction): IStateSettings {
  switch (action.type) {
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        status: {
          ...state.status,
          isLoading: action.payload
        }
      };
    case ActionTypes.SET_VIEW_PENDING:
      return {
        ...state,
        status: {
          ...state.status,
          viewIsPending: action.payload
        }
      };
    case ActionTypes.SET_SOIL_MOISTURE_SENSORS_LOADING:
      return {
        ...state,
        status: {
          ...state.status,
          isLoadingSoilMoistureSensors: action.payload
        }
      };
    case ActionTypes.SET_ERROR:
      return {
        ...state,
        status: {
          ...state.status,
          isError: action.payload
        }
      };
    case ActionTypes.SET_VIEW_EDITOR_ACTIVE:
      return {
        ...state,
        activity: {
          ...state.activity,
          isViewEditorActive: action.payload
        }
      };
    case ActionTypes.SET_SENSOR_SETTINGS_ACTIVE:
      return {
        ...state,
        activity: {
          ...state.activity,
          isSensorSettingsActive: action.payload
        }
      };
    case ActionTypes.SET_CHART_ACTIVE:
      return {
        ...state,
        activity: {
          ...state.activity,
          isChartActive: action.payload
        }
      };
    case ActionTypes.SET_TABLE_ACTIVE:
      return {
        ...state,
        activity: {
          ...state.activity,
          isTableActive: action.payload
        }
      };
    case ActionTypes.SET_SETTINGS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}

function reorderChartResponse(response: IChart): IChart {

  if (response.chartsOptions && response.chartsOptions.length > 0) {
    const charts = response.chartsOptions;
    const topology = response.topology;
    // re-order charts. check if we have an aggr at all (0 or exactly 1)
    const aggrIdx = charts.findIndex((chart: any) => chart.sources.indexOf('aggr') >= 0);
    if (aggrIdx >= 1 /* must not eq 0 */) {
      const chartsReordered = charts.slice();
      /* the chart related to the aggr must be at index aggrIdx - 1*/
      chartsReordered.unshift(...chartsReordered.splice(aggrIdx - 1, 2).reverse());
      // update chart idxs
      topology.forEach((topo: any) => {
        topo.sensors.forEach((sensor: any) => {
          const chart = charts[sensor.chart];
          sensor.chart = chartsReordered.findIndex((chartReordered: any) => {
            return chartReordered === chart;
          });
        });
        topo.sensors.sort((a: any, b: any) => a.chart - b.chart);
        topo.nodes.forEach((node: any) => {
          node.sensors.forEach((sensor: any) => {
            const chart = charts[sensor.chart];
            sensor.chart = chartsReordered.findIndex((chartReordered: any) => {
              return chartReordered === chart;
            });
          });
          node.sensors.sort((a: any, b: any) => {
            return (a.chart === b.chart) ? a.series - b.series : a.chart - b.chart;
          });
        });
      });
      response.chartsOptions = chartsReordered;
    }
  }

  return response;

}

function viewsEqual(a: IView, b: IView): boolean {
  return a._id === b._id || (a.type === ViewType.PREDEFINED && b.type === ViewType.PREDEFINED && a.template === b.template);
}
