import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {SensorsAndNodesRoutingModule} from './sensors-and-nodes-routing.module';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {TranslateModule} from '@ngx-translate/core';
import {SensorsAndNodesComponent} from './containers/sensors-and-nodes.component';
import {SensorsAndNodesEffects} from './effects/sensors-and-nodes.effects';
import {SensorsAndNodesHelpService} from './services/sensors-and-nodes-help.service';
import {reducers} from './reducers';
import { SensorsLoadingComponent } from './containers/sensors-loading/sensors-loading.component';
import { SensorNodeComponent } from './containers/sensor-node/sensor-node.component';
import { SensorRowComponent } from './containers/sensor-node/sensor-row/sensor-row.component';
import { SensorHeadComponent } from './containers/sensor-node/sensor-head/sensor-head.component';
import { SensorSaveComponent } from './containers/sensor-node/sensor-save/sensor-save.component';
import {ColorPickerModule} from 'ngx-color-picker';
import { SensorConfirmModalComponent } from './containers/sensor-confirm-modal/sensor-confirm-modal.component';
import { SensorNodeModalComponent } from './containers/sensor-node/sensor-node-modal/sensor-node-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SensorsAndNodesRoutingModule,
    TranslateModule.forChild(),
    StoreModule.forFeature('sensorsAndNodes', reducers),
    EffectsModule.forFeature([SensorsAndNodesEffects]),
    ColorPickerModule
  ],
  declarations: [
    SensorsAndNodesComponent,
    SensorsLoadingComponent,
    SensorNodeComponent,
    SensorRowComponent,
    SensorHeadComponent,
    SensorSaveComponent,
    SensorConfirmModalComponent,
    SensorNodeModalComponent
  ],
  providers: [
    SensorsAndNodesHelpService
  ]
})
export class SensorsAndNodesModule { }
