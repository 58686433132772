import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CarouselModule, TabsModule} from 'ngx-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AgGridModule } from 'ag-grid-angular';
import { ColorPickerModule } from 'ngx-color-picker';
import { RectangleService } from '../../shared/camera/services/rectangles/rectangle.service';
import { SharedModule } from '../../shared/shared.module';
import { IscoutRoutingModule } from './iscout-routing.module';
import { IscoutHeaderComponent } from './components/iscout-header/iscout-header.component';
import { IscoutContextMenuComponent } from './components/iscout-context-menu/iscout-context-menu.component';
import { IscoutComponent } from './containers/iscout/iscout.component';
import { IscoutContentComponent } from './containers/iscout-content/iscout-content.component';
import { IscoutImageSettingsComponent } from './containers/iscout-image-settings/iscout-image-settings.component';
import { IscoutImageSettingsToolbarComponent } from './containers/iscout-image-settings-toolbar/iscout-image-settings-toolbar.component';
import { IscoutImageGalleryToolbarComponent } from './containers/iscout-image-gallery-toolbar/iscout-image-gallery-toolbar.component';
import { IscoutImageGalleryComponent } from './containers/iscout-image-gallery/iscout-image-gallery.component';
import { IscoutPestsComponent } from './containers/iscout-pests/iscout-pests.component';
import { IscoutPestsContentComponent } from './containers/iscout-pests/iscout-pests-content/iscout-pests-content.component';
import { IscoutPestsListComponent } from './containers/iscout-pests/iscout-pests-list/iscout-pests-list.component';
import { IscoutPestsFormComponent } from './containers/iscout-pests/iscout-pests-form/iscout-pests-form.component';
import { IscoutPestsDeleteModalComponent } from './containers/iscout-pests/iscout-pests-delete-modal/iscout-pests-delete-modal.component';
import { IscoutGlueBoardComponent } from './containers/iscout-glue-board/iscout-glue-board.component';
import { IscoutGlueBoardContentComponent } from './containers/iscout-glue-board/iscout-glue-board-content/iscout-glue-board-content.component';
import { IscoutGlueBoardListComponent } from './containers/iscout-glue-board/iscout-glue-board-list/iscout-glue-board-list.component';
import { IscoutGlueBoardFormComponent } from './containers/iscout-glue-board/iscout-glue-board-form/iscout-glue-board-form.component';
import { IscoutGlueBoardDeleteModalComponent } from './containers/iscout-glue-board/iscout-glue-board-delete-modal/iscout-glue-board-delete-modal.component';
import { IscoutGlueBoardExchangeModalComponent } from './containers/iscout-glue-board/iscout-glue-board-exchange-modal/iscout-glue-board-exchange-modal.component';
import { IscoutSeasonComponent } from './containers/iscout-season/iscout-season.component';
import { IscoutSeasonContentComponent } from './containers/iscout-season/iscout-season-content/iscout-season-content.component';
import { IscoutSeasonFormComponent } from './containers/iscout-season/iscout-season-form/iscout-season-form.component';
import { IscoutSeasonListComponent } from './containers/iscout-season/iscout-season-list/iscout-season-list.component';
import { IscoutSeasonDeleteModalComponent } from './containers/iscout-season/iscout-season-delete-modal/iscout-season-delete-modal.component';
import { IscoutSeasonDetailModalComponent } from './containers/iscout-season/iscout-season-detail-modal/iscout-season-detail-modal.component';
import { IscoutService } from './effects/iscout.service';
import { reducers } from './reducers';

@NgModule({
  imports: [
    CommonModule,
    IscoutRoutingModule,
    TranslateModule.forChild(),
    ColorPickerModule,
    AgGridModule,
    SharedModule,
    StoreModule.forFeature('iscout', reducers),
    EffectsModule.forFeature([IscoutService]),
    CarouselModule.forRoot(),
    TabsModule.forRoot()
  ],
  declarations: [
    IscoutComponent,
    IscoutContentComponent,
    IscoutImageSettingsComponent,
    IscoutImageSettingsToolbarComponent,
    IscoutImageGalleryToolbarComponent,
    IscoutImageGalleryComponent,
    IscoutHeaderComponent,
    IscoutContextMenuComponent,
    IscoutPestsComponent,
    IscoutPestsContentComponent,
    IscoutPestsListComponent,
    IscoutPestsFormComponent,
    IscoutPestsDeleteModalComponent,
    IscoutGlueBoardComponent,
    IscoutGlueBoardContentComponent,
    IscoutGlueBoardListComponent,
    IscoutGlueBoardFormComponent,
    IscoutGlueBoardDeleteModalComponent,
    IscoutGlueBoardExchangeModalComponent,
    IscoutSeasonComponent,
    IscoutSeasonContentComponent,
    IscoutSeasonListComponent,
    IscoutSeasonFormComponent,
    IscoutSeasonDeleteModalComponent,
    IscoutSeasonDetailModalComponent
  ],
  providers: [RectangleService]
})
export class IscoutModule { }
