import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TypeaheadMatch } from '../../../../../../../node_modules/ngx-bootstrap';
import { REMOVE_CROPZONE } from '../../../../../shared/constants';
import { ModalService } from '../../../../../shared/modal/services/modal.service';
import { setNotify } from '../../../../actions/notify';
import { IFarm } from '../../../../models/cropzones';
import { IAddField, IField } from '../../../../models/fields';
import { selectFarms, selectFields } from '../../../../reducers';
import * as fromFarms from '../../../../reducers/farms';
import * as fromFields from '../../../../reducers/fields';
import * as fromNotify from '../../../../reducers/notify';

@Component({
    selector: 'app-remove-field-form',
    templateUrl: './remove-field-form.component.html',
    styleUrls: ['./remove-field-form.component.scss']
})

export class RemoveFieldFormComponent implements OnInit {
    public form: FormGroup;
    public farms$: Observable<IFarm[]>;
    public fields$: Observable<IField[]>;
    public selectedOption: IFarm = {name: '', id: ''};
    public selectedField: IField = {name: '', id: '', farm: {name: '', id: ''}};

    public farmList: IFarm[];
    public fieldList: IField[];

    @Output()
    private submit: EventEmitter<IAddField> = new EventEmitter<IAddField>();

    constructor(
        private fb: FormBuilder,
        private modalService: ModalService,
        private farmStore: Store<fromFarms.IFarms>,
        private fieldStore: Store<fromFields.IFields>,
        private notifyStore: Store<fromNotify.INotifyState>) {
            this.farmStore.pipe(
                select(selectFarms)
            ).subscribe((farms) => this.farmList = farms);

            this.fieldStore.pipe(
                select(selectFields)
            ).subscribe((fields) => this.fieldList = fields);
        }

    public get farm(): AbstractControl {
        return this.form.get('farm');
    }

    public get field(): AbstractControl {
        return this.form.get('field');
    }

    public get submitColor(): string {
        return 'red';
    }

    public get submitContent(): string {
        return 'Remove field';
    }

    public ngOnInit(): void {
        this.form = this.fb.group({
            'farm': ['', [Validators.required]],
            'field': ['', [Validators.required]]
        });

        this.farms$ = this.farmStore.pipe(
            select(selectFarms),
        );

        this.fields$ = this.fieldStore.pipe(
            select(selectFields),
        );

        this.onChanges();
    }

    public onChanges(): void {
        this.form.get('farm').valueChanges.subscribe(val => {
        this.getFieldsFromFarmName(val);
      });
    }

    public onSelect(event: TypeaheadMatch): void {
        this.fields$ = this.fieldStore.pipe(
            select(selectFields)
        );

        this.selectedOption = event.item;

        this.getFieldsFromFarmId(this.selectedOption.id);
    }

    public onSelectField(event: TypeaheadMatch): void {
        this.selectedField = event.item;
    }

    private getFieldsFromFarmName(name: string): void {
        this.fields$.pipe(
          map(fields => fields.filter(field => field.farm.name === name))
        ).subscribe((fields) => this.fields$ = of(fields));
      }

      private getFieldsFromFarmId(id: string): void {

        this.fields$.pipe(
          map(fields => fields.filter(field => field.farm.id === id))
        ).subscribe((fields) => this.fields$ = of(fields));
      }

    public submitEmit(): void {
        if (this.farmList.some((farm) => farm.name.toLowerCase() === this.form.value.farm.toLowerCase())) {
            const fieldsFromFarm: IField[] = this.fieldList.filter((field) =>
            field.farm.name.toLowerCase() === this.form.value.farm.toLowerCase());

            if (fieldsFromFarm.some((field) => field.name.toLowerCase() === this.form.value.field.toLowerCase())) {
                const field: IField = fieldsFromFarm.filter((fieldx) =>
                fieldx.name.toLowerCase() === this.form.value.field.toLowerCase())[0];

                this.submit.emit(field);
            } else {
                this.notifyStore.dispatch(setNotify('Unable to remove field. The selected farm has no field with this name.'));
            }
        } else {
            this.notifyStore.dispatch(setNotify('Unable to remove field. There is no farm with this name.'));
        }
        this.form.reset();
    }

    public close(): void {
        this.modalService.closeModal(REMOVE_CROPZONE);
    }
}
