import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import {
  IView,
  IGetViewsRequest,
  IGetChartRequest,
  IChart
} from '../models';
import { IStateSettings } from '../states';
import { ISensor } from '../../../shared/interfaces';

export const enum ActionTypes {
  SET_LOADING = '[SoilMoisture] SET_LOADING',
  SET_VIEW_PENDING = '[SoilMoisture] SET_VIEW_PENDING',
  SET_ERROR = '[SoilMoisture] SET_ERROR',
  GET_VIEWS = '[SoilMoisture] GET_VIEWS',
  SET_VIEWS = '[SoilMoisture] SET_VIEWS',
  POST_VIEW = '[SoilMoisture] POST_VIEW',
  DELETE_VIEW = '[SoilMoisture] DELETE_VIEW',
  PUT_VIEW = '[SoilMoisture] PUT_VIEW',
  SET_VIEW  = '[SoilMoisture] SET_VIEW',
  UNSET_VIEW = '[SoilMoisture] UNSET_VIEW',
  GET_CHART = '[SoilMoisture] GET_CHART',
  SET_CHART = '[SoilMoisture] SET_CHART',
  SET_VIEW_EDITOR_ACTIVE = '[SoilMoisture] SET_VIEW_EDITOR_ACTIVE',
  SET_SENSOR_SETTINGS_ACTIVE = '[SoilMoisture] SET_SENSOR_SETTINGS_ACTIVE',
  SET_CHART_ACTIVE = '[SoilMoisture] SET_CHART_ACTIVE',
  SET_TABLE_ACTIVE = '[SoilMoisture] SET_TABLE_ACTIVE',
  SET_SETTINGS = '[SoilMoisture] SET_SETTINGS',
  GET_SOIL_MOISTURE_SENSORS = '[SoilMoisture] GET_SOIL_MOISTURE_SENSORS',
  SET_SOIL_MOISTURE_SENSORS_LOADING = '[SoilMoisture] SET_SOIL_MOISTURE_SENSORS_LOADING',
  SET_SOIL_MOISTURE_SENSORS = '[SoilMoisture] SET_SOIL_MOISTURE_SENSORS'
}

export function setLoading(isLoading: boolean): IAction {
  return { type: ActionTypes.SET_LOADING, payload: isLoading };
}

export function setViewPending(isPending: boolean): IAction {
  return { type: ActionTypes.SET_VIEW_PENDING, payload: isPending };
}

export function setError(isError: boolean): IAction {
  return { type: ActionTypes.SET_ERROR, payload: isError };
}

export function getViews(payload: IGetViewsRequest): IAction {
  return { type: ActionTypes.GET_VIEWS, payload: payload };
}

export function setViews(payload: IView[]): IAction {
  return { type: ActionTypes.SET_VIEWS, payload: payload };
}

export function deleteView(payload: IView): IAction {
  return { type: ActionTypes.DELETE_VIEW, payload: payload };
}

export function postView(payload: IView): IAction {
  return { type: ActionTypes.POST_VIEW, payload: payload };
}

export function putView(payload: IView): IAction {
  return { type: ActionTypes.PUT_VIEW, payload: payload };
}

export function setView(payload: IView): IAction {
  return { type: ActionTypes.SET_VIEW, payload: payload };
}

export function unsetView(payload: IView): IAction {
  return { type: ActionTypes.UNSET_VIEW, payload: payload };
}

export function getChart(payload: IGetChartRequest): IAction {
  return { type: ActionTypes.GET_CHART, payload: payload };
}

export function setChart(payload: IChart): IAction {
  return { type: ActionTypes.SET_CHART, payload: payload };
}

export function setSensorSettingsActive(isActive: boolean): IAction {
  return { type: ActionTypes.SET_SENSOR_SETTINGS_ACTIVE, payload: isActive };
}

export function setViewEditorActive(isActive: boolean): IAction {
  return { type: ActionTypes.SET_VIEW_EDITOR_ACTIVE, payload: isActive };
}

export function setChartActive(isActive: boolean): IAction {
  return { type: ActionTypes.SET_CHART_ACTIVE, payload: isActive };
}

export function setTableActive(isActive: boolean): IAction {
  return { type: ActionTypes.SET_TABLE_ACTIVE, payload: isActive };
}

export function setSettings(settings: IStateSettings): IAction {
  return { type: ActionTypes.SET_SETTINGS, payload: settings };
}

export function getSoilMoistureSensors(stationId: string): IAction {
  return { type: ActionTypes.GET_SOIL_MOISTURE_SENSORS, payload: stationId };
}

export function setSoilMoistureSensorsLoading(isLoading: boolean): IAction {
  return { type: ActionTypes.SET_SOIL_MOISTURE_SENSORS_LOADING, payload: isLoading };
}

export function setSoilMoistureSensors(sensors: ISensor[]): IAction {
  return { type: ActionTypes.SET_SOIL_MOISTURE_SENSORS, payload: sensors };
}

