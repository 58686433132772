import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LicenseType } from '../../../product-activation/constants/constants';
import { IActiveLicense } from '../../models/user-licenses.models';

@Component({
  selector: 'app-user-licenses-detail',
  templateUrl: './user-licenses-detail.component.html',
  styleUrls: ['./user-licenses-detail.component.scss']
})
export class UserLicensesDetailComponent {
  @Input()
  public selectedLicense: IActiveLicense = null;
  @Output()
  public unselectLicense: EventEmitter<void> = new EventEmitter<void>();

  public onUnselectClick(): void {
    this.unselectLicense.emit();
  }

  public hasFarmViewLicense(): boolean {
    const filter = (product) => product.license_group === LicenseType.FARM_VIEW;
    return this.selectedLicense.products.find(filter) !== undefined;
  }

  public hasIScoutMobileLicense(): boolean {
    const filter = (product) => product.license_group === LicenseType.ISCOUT_MOBILE;
    return this.selectedLicense.products.find(filter) !== undefined;
  }
}
