import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { setNotify } from '../../../../core/actions/notify';
import * as fromNotify from '../../../../core/reducers/notify';
import { IOptions } from '../../../../shared/interfaces';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { resetNodes, saveSensor } from '../../actions/sensors-and-nodes';
import { INode, ISaveSensorPayload } from '../../models/sensors-and-nodes';
import * as fromSensorsAndNodes from '../../reducers/sensors-and-nodes';
import { SensorsAndNodesHelpService } from '../../services/sensors-and-nodes-help.service';

@Component({
  selector: 'app-sensor-node',
  templateUrl: './sensor-node.component.html',
  styleUrls: ['./sensor-node.component.scss']
})
export class SensorNodeComponent {
  @Input()
  public node                 : INode;
  @Input()
  public nodeKey              : string;
  @Input()
  public stationId            : string;
  @Input()
  public nodeCalibration      : { [sensor: string]: IOptions[]|null } = {};

  public showInactiveSensors  : boolean = false;
  public modalId              : string = generateId();
  public infoModalId          : string = generateId();

  constructor(private sensorsStore: Store<fromSensorsAndNodes.ISensorsAndNodesState>,
              private modalService: ModalService,
              private helpService: SensorsAndNodesHelpService,
              private notifyStore: Store<fromNotify.INotifyState>) { }

  public getSensorsKeys(): string[] {
    return Object.keys(this.node.sensors);
  }

  public resetUnits(): void {
    this.sensorsStore.dispatch(resetNodes(this.stationId));
    this.closeModal();
  }

  public openModal(info: boolean = false): void {
    this.modalService.openModal(info ? this.infoModalId : this.modalId);
  }

  public closeModal(): void {
    this.modalService.closeModal(this.modalId);
  }

  public saveAllSensors(): void {
    const save: ISaveSensorPayload = {
      stationId: this.stationId,
      sensors: {
        save: [],
        update: []
      }
    };
    Object.keys(this.node.sensors).forEach((key: string) => {
      if (this.node.sensors[key] && this.node.sensors[key].isChanged) {
        save.sensors.save.push({
          unit: this.node.sensors[key].unit || false,
          color: this.node.sensors[key].color,
          name: this.node.sensors[key].name_custom,
          channel: this.node.sensors[key].ch,
          code: this.node.sensors[key].code,
          calibration_id: this.node.sensors[key].calibration_id || undefined,
        });
        save.sensors.update.push({
          ...this.helpService.prepareUpdateSensor(this.node.sensors[key], key, this.nodeKey)
        });
      }
    });

    if (!save.sensors.save.length) {
      this.notifyStore.dispatch(setNotify('No changes have been made yet'));
    } else {
      this.sensorsStore.dispatch(saveSensor(save));
    }
  }

  public nodeHasCalibration(): boolean {
    return this.nodeCalibration && Object.keys(this.nodeCalibration).some(key => !!this.nodeCalibration[key]);
  }

  public calibrationOptions(sensorKey: string): IOptions[] {
    return this.nodeCalibration ? this.nodeCalibration[sensorKey] : [];
  }

  public onShowInactiveSensors(show: boolean): void {
    this.showInactiveSensors = show;
  }

}
