import {IIscoutGlueBoardState, IIscoutPestsState, IIscoutSeasonState, IIscoutState} from '../models/iscout.models';

export const iScoutInitialState: IIscoutState = {
  pictures: {},
  isLoading: true,
  isError: false,
  userPests: [],
  generalPests: [],
  firstDate: null,
  lastDate: null
};

export const iScoutPestsInitialState: IIscoutPestsState = {
  pestsOrders: [],
  pestsGenus: [],
  pestsFamily: [],
  pests: [],
  selectedPest: null
};

export const iScoutGlueBoardInitialState: IIscoutGlueBoardState = {
  glueBoards: [],
  availableDates: {
    last: new Date(),
    min: new Date(),
    max: new Date(),
  },
  selectedGlueBoard: null,
  selectedGalleryGlueBoard: null
};

export const iScoutSeasonInitialState: IIscoutSeasonState = {
  seasons: [],
  availableGlueBoards: [],
  selectedSeason: null
};

export const iScoutPresetColors: string[] = [
  '#4c1313',
  '#73621d',
  '#996326',
  '#8c2500',
  '#e56739',
  '#e59900',
  '#0d1c33',
  '#204680',
  '#002280',
  '#0088ff',
  '#00c2f2',
  '#003033',
  '#004011',
  '#006680',
  '#00735c',
  '#00ccbe',
  '#00f281',
  '#2b0040',
  '#330014',
  '#8c234d',
  '#ff4073',
  '#ff00cc',
  '#bf40ff',
  '#331b00',
  '#258c00',
  '#9ba600',
  '#88ff00'
];
