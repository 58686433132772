import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {AgGridModule} from 'ag-grid-angular';
import {ChartModule, HIGHCHARTS_MODULES} from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import {StationDataExportService} from '../../shared/services/export/station-data-export.service';
import {SharedModule} from '../../shared/shared.module';
import {CropViewService} from '../crop-view/effects/crop-view.service';
import {reducers as cropviewReducers} from '../crop-view/reducers';
import {IscoutService} from '../iscout/effects/iscout.service';
import {reducers as iscoutReducers} from '../iscout/reducers';
import {CameraDataRoutingModule} from './camera-data-routing.module';
import {CameraDataHeaderComponent} from './components/camera-data-header/camera-data-header.component';
import {CameraDataComponent} from './containers/camera-data/camera-data.component';
import {CameraDataContentComponent} from './containers/camera-data-content/camera-data-content.component';
import {CameraDataContentCropviewComponent} from './containers/camera-data-content/camera-data-content-cropview/camera-data-content-cropview.component';
import {CameraDataContentIscoutComponent} from './containers/camera-data-content/camera-data-content-iscout/camera-data-content-iscout.component';
import {CameraDataChartComponent} from './containers/camera-data-chart/camera-data-chart.component';
import {CameraDataTableComponent} from './containers/camera-data-table/camera-data-table.component';
import {CameraDataToolbarComponent} from './containers/camera-data-toolbar/camera-data-toolbar.component';
import {CameraDataToolbarCropviewComponent} from './containers/camera-data-toolbar/camera-data-toolbar-cropview/camera-data-toolbar-cropview.component';
import {CameraDataToolbarIscoutComponent} from './containers/camera-data-toolbar/camera-data-toolbar-iscout/camera-data-toolbar-iscout.component';
import {CameraDataTableCropviewComponent} from './containers/camera-data-table/camera-data-table-cropview/camera-data-table-cropview.component';
import {CameraDataTableIscoutComponent} from './containers/camera-data-table/camera-data-table-iscout/camera-data-table-iscout.component';
import {CameraDataService} from './effects/camera-data.service';
import {reducers as cameraDataReducers} from './reducers';

@NgModule({
  imports: [
    CommonModule,
    CameraDataRoutingModule,
    TranslateModule.forChild(),
    SharedModule,
    StoreModule.forFeature('iscout', iscoutReducers),
    StoreModule.forFeature('cropView', cropviewReducers),
    StoreModule.forFeature('cameraData', cameraDataReducers),
    EffectsModule.forFeature([IscoutService, CropViewService, CameraDataService]),
    ChartModule,
    AgGridModule.withComponents([]),
  ],
  declarations: [
    CameraDataComponent,
    CameraDataContentComponent,
    CameraDataContentCropviewComponent,
    CameraDataContentIscoutComponent,
    CameraDataHeaderComponent,
    CameraDataToolbarComponent,
    CameraDataToolbarCropviewComponent,
    CameraDataToolbarIscoutComponent,
    CameraDataChartComponent,
    CameraDataTableComponent,
    CameraDataTableCropviewComponent,
    CameraDataTableIscoutComponent
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [ more, exporting ] }, // add as factory to your providers
    StationDataExportService
  ]
})
export class CameraDataModule { }
