import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { IAsset, IState } from '../models/my-john-deere';
import { ActionTypes } from '../actions/my-john-deere';

export const initialState: IState = {
  authData: {
    app: '',
    app_username: '',
    our_username: '',
    last_refresh: null,
    redirectURL: ''
  },
  catalog: {
    links: []
  },
  currentUser: null,
  currentUserOrganizationsPages: [],
  currentUserOrganizations: null,
  assets: [],
  sensors: {},
  status: {
    currentUserOrganizations: {
      loading: false
    }
  },
};

export function reducer(state: IState = initialState, action: IAction): IState {
  switch (action.type) {
    case ActionTypes.SET_STATE:
      return {
        ...action.payload
      };
    case ActionTypes.SET_STATUS:
      return {
        ...state,
        status: {
          currentUserOrganizations: {
            ...state.status.currentUserOrganizations,
            ...action.payload.currentUserOrganizations
          }
        }
      };
    case ActionTypes.SET_AUTH_DATA:
      return {
        ...state,
        authData: {
          ...action.payload
        }
      };
    case ActionTypes.DROP_USER:
      return {
        ...state,
        currentUser: initialState.currentUser
      };
    case ActionTypes.SET_CATALOG:
      return {
        ...state,
        catalog: {
          ...state.catalog,
          ...action.payload
        }
      };
    case ActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload
      };
    case ActionTypes.SET_CURRENT_USER_ORGANIZATIONS_PAGE:
      return {
        ...state,
        currentUserOrganizationsPages: [
          ...state.currentUserOrganizationsPages,
          action.payload
        ]
      };
    case ActionTypes.SET_CURRENT_USER_ORGANIZATIONS:
      return {
        ...state,
        currentUserOrganizations: [
          action.payload
        ]
      };
    case ActionTypes.SET_ASSETS:
      return {
        ...state,
        assets: [
          ...state.assets,
          ...action.payload.filter(newAsset => !state.assets.some(asset => (
            asset.organization === newAsset.organization && asset.station === newAsset.station
          )))
        ]
      };
    case ActionTypes.SET_ASSET:
      return {
        ...state,
        assets: [
          ...state.assets,
          action.payload
        ]
      };
    case ActionTypes.REMOVE_ASSET:
      return {
        ...state,
        assets: state.assets.filter((asset: IAsset) => !(
          action.payload.organization === asset.organization &&
          action.payload.station === asset.station)
        )
      };
    case ActionTypes.UPDATE_ASSET:
      return {
        ...state,
        assets: state.assets.map((asset: IAsset) => {
          if ((asset.asset === action.payload.asset) || (
            action.payload.organization && action.payload.organization === asset.organization &&
            action.payload.station === asset.station
          )) {
            return {
              ...asset,
              ...action.payload
            };
          }
          return asset;
        })
      };
    case ActionTypes.SET_SENSORS:
      return {
        ...state,
        sensors: {
          ...state.sensors,
          [action.payload.stationId]: action.payload.sensors
        }
      };
    default:
      return state;
  }
}

