<section class="row">
  <ng-container *ngFor="let item of row; let i = index">
    <app-column [column]="item"
                [columnIndex]="i"
                [columnCount]="row.length"
                [isEdit]="isEdit"
                [rowIndex]="rowIndex"></app-column>
  </ng-container>
</section>

