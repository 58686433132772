import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { IHeaderData } from '../../../work-planning/models/work-planning.models';
import { HEADERS } from '../../constants/constants';

@Component({
  selector: 'app-accumulator-tool-header',
  templateUrl: './accumulator-tool-header.component.html',
  styleUrls: ['./accumulator-tool-header.component.css']
})
export class AccumulatorToolHeaderComponent implements OnInit {
  public header       : IHeaderData = { title: '', subtitle: '', help: ''};
  public modalId      : string = generateId();
  private type        : string;

  constructor(
    private modal     : ModalService,
    private route     : ActivatedRoute,
    private router    : Router
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((): void => {
      if (this.route.snapshot.firstChild.routeConfig.path === 'degree-days') {
        this.type = 'degreeDays';
      } else if (this.route.snapshot.firstChild.routeConfig.path === 'chilling-units') {
        this.type = 'chillingUnits';
      } else if (this.route.snapshot.firstChild.routeConfig.path === 'rain-sum') {
        this.type = 'rainSum';
      } else if (this.route.snapshot.firstChild.routeConfig.path === 'asparagus-growth-model') {
        this.type = 'asparagusGrowthModel';
      }
      this.getHeader(this.type);
    });
  }

  public ngOnInit(): void {
  }

  public openModal(): void {
    this.modal.openModal(this.modalId);
  }

  private getHeader(type: string): void {
    this.header.title = HEADERS[type]['title'];

    this.header.help = '';
    HEADERS[type]['help'].forEach(text => {
      this.header.help += text;
    });
  }
}
