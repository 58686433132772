import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ExcelExportParams, GridOptions } from 'ag-grid';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StationDataExportService } from '../../../../../shared/services/export/station-data-export.service';
import { deepClone } from '../../../../../shared/utils/deepClone';
import { columnDefsWaterbalanceImperial, columnDefsWaterbalanceMetric } from '../../../constants/constants';
import { selectDwbTableActive } from '../../../reducers';
import { DataGridOptions, ExcelExportSettings } from './../../../../../shared/constants';
import { IIrrimetState } from './../../../models/models';

@Component({
  selector: 'app-irrimet-water-balance-table',
  templateUrl: './irrimet-water-balance-table.component.html',
  styleUrls: ['./irrimet-water-balance-table.component.scss']
})
export class IrrimetWaterBalanceTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public unitSystem: string;
  @Input()
  public apiResponse: any;
  @Input()
  public fromTo: any;
  @Input()
  private cropzoneName: string;
  private downloadFileName: string;

  public tableActive: boolean;

  private adjustedResponse: any;

  public rowData: any;
  public columnDefs = columnDefsWaterbalanceMetric;
  public dataGridOptions: GridOptions = { ...deepClone(DataGridOptions), headerHeight: 26 };
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private cd: ChangeDetectorRef,
              private irrimetStore: Store<IIrrimetState>,
              private exportService: StationDataExportService,
              private router: Router,
              private route: ActivatedRoute,
              private translationService: TranslateService
  ) {
      this.columnDefs.forEach((column, i) => {
        this.translationService.get(column.headerName)
          .subscribe(translatedHeader => {
            this.columnDefs[i].headerName = translatedHeader;
          });
      });
  }

  public ngOnInit(): void {
    this.cd.detach();
    this.downloadFileName = this.cropzoneName.replace(/[^\w\s]/gi, '_');

    this.exportService.getExportXLS().pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.exportXLS();
    });

    this.irrimetStore.pipe(
      takeUntil(this.destroy$),
      select(selectDwbTableActive)
    ).subscribe(isActive => {
      this.tableActive = isActive;
      this.cd.detectChanges();
    });
  }

  private exportXLS(): void {
    let path = 'available_water';
    if (this.route.snapshot.url[0].path === 'paw') {
      path = 'paw';
    }
    const params: ExcelExportParams = {
      ...ExcelExportSettings,
      fileName: `${this.downloadFileName}_${path}`
    };

    this.dataGridOptions.api.exportDataAsExcel(params);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.apiResponse) {
      this.adjustedResponse = this.mapResponse(this.apiResponse);
      const updatedResponse = this.updateData(this.fromTo, this.adjustedResponse);
      this.updateTable(this.filterNullValues(updatedResponse));
    }
  }

  private filterNullValues(apiResponse: any[]): any[] {
    if (apiResponse && apiResponse.length) {
      return apiResponse.filter(response => {
        return response.daily_et0 !== null &&
          response.daily_etc !== null &&
          response.dwb !== null &&
          response.irrigation !== null &&
          response.irrigation_eff !== null &&
          response.kc !== null &&
          response.precipitation !== null &&
          response.precipitation_eff !== null &&
          response.paw !== null;
      });
    }
  }

  private mapResponse(apiResponse): any[] {
    return apiResponse.map((res: any) => {
      return {
        date: res.date,
        irrigation_eff: res.W_i_eff,
        precipitation: res.W_p,
        precipitation_eff: res.W_p_eff,
        kc: res.K_c,
        daily_et0: res.ET_0,
        daily_etc: res.ET_c,
        irrigation: res.W_i,
        dwb: res.WB,
        paw: (res.WB / res.WB_max * 100).toFixed(1).replace(/\.?0*$/g, '')
      };
    });
  }

  private updateData(fromTo: any, data: any[]): any[] {

    if (data && data.length) {
      let start = 0;
      let end = data.length - 1;

      if (fromTo.from && fromTo.to) {
        start = this.getNoOfDays(new Date(data[0].date), fromTo.from);
        start = start < 0 ? 0 : start;
        end = start + this.getNoOfDays(fromTo.from, fromTo.to);
      }

      return data.slice(start, end);
    }
  }

  private updateTable(response: any[]): void {
    setTimeout(() => {
      this.unitSystem === 'metric' ? this.columnDefs = columnDefsWaterbalanceMetric : this.columnDefs = columnDefsWaterbalanceImperial;

      if (this.dataGridOptions && this.dataGridOptions.api) {
        this.dataGridOptions.api.setColumnDefs(this.columnDefs);
        this.dataGridOptions.api.setRowData(response);
      }
    }, 0);
    this.cd.detectChanges();
  }

  private getNoOfDays(d0: Date, d1: Date): number {
    return Math.ceil((d1.getTime() - d0.getTime()) / (24 * 60 * 60 * 1000));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
