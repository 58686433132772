import { Component, Input } from '@angular/core';
import { IDiseaseWarning } from '../../models/station-disease.models';

@Component({
  selector: 'app-station-disease-warning',
  templateUrl: './station-disease-warning.component.html',
  styleUrls: ['./station-disease-warning.component.scss']
})
export class StationDiseaseWarningComponent {
  @Input()
  public warning: IDiseaseWarning;
}
