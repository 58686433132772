<app-tree [tree$]="tree$"></app-tree>

<div *ngIf="cropZone$ | async as cropZone" [@placeContent]="state$ | async">
  <div class="cropzoneNdviContainer" #chartContainer>
    <ng-container *ngIf="!loading; else loadingSpin">
      <ng-container *ngIf="!noDataError; else noDataErrorTemplate">
        <app-cropzone-page-header [pageName]="'Satellite' | translate"></app-cropzone-page-header>
        <app-sat-warning-message></app-sat-warning-message>
        <div class="alert alert-info" *ngIf="lastDateError">
          {{ 'Due to the amount of cloud cover in this cropzone region, the given frequency of satellite imagery can be reduced.' | translate }}
        </div>
        <div *ngFor="let chart of charts" class="chartContainer">
            <button (click)="downloadChart()"><i class="fa fa-download"></i></button>
          <i class="zmdi zmdi-help help-icon" (click)="openModal()"></i>
          <div [chart]="chart" style="width: auto;"></div>
        </div>
      </ng-container>
    </ng-container>

    <div class="lazyListContainer" [hidden]="loading || noDataError">
      <app-lazy-list class="lazyList" [loading]="loading" [noDataError]="noDataError" [resolution]="resolution"
        [cropZone]="cropZone" [values$]="ndvis$" [type]="'NDVI'" (selectedItem)="onSelectedItemChanged($event)">
      </app-lazy-list>
    </div>
  </div>
</div>

<app-modal [modalId]="DETAIL_MODAL">
  <div class="modalContainer" id="modalContainer" [hidden]="isLoading">

    <div class="col-md-10 align-middle modalMapContainer" id="mapContainer">

      <div class="tooltipContainer" id="tooltipContainer">
        <div class="tooltipClass" id="tooltip"></div>
      </div>

      <button class="downloadButton" id="download" (click)="downloadFullRes()">
        <i class="fa fa-download"></i>
      </button>

      <div class="modalDateResolutionContainer">
        <div class="col-md-6">
          <p style="margin-bottom: 0px;">{{'Date' | translate }}: {{ detailDate }}</p>
        </div>
        <div class="col-md-6 textAlignRight">
          <p>{{ resolution10mText }}</p>
        </div>
      </div>

      <div style="border-left: 1px solid black; z-index: 10;">
        <agm-map
          [fitBounds]="bounds"
          [latitude]="center.lat"
          [longitude]="center.lng"
          [disableDefaultUI]="true"
          [zoomControl]="zoomControl"
          [scrollwheel]="false"
          [streetViewControl]="false"
          [mapTypeId]="'hybrid'"
          [mapTypeControl]="false"
          (mapReady)="onMapReady($event)"
        ></agm-map>
      </div>

    </div>

    <div id="legendContainer" class="col-md-2 legendContainer">
      <svg id="legend" class="fullWidthAndHeight"></svg>
    </div>

  </div>

  <div class="row loadingContainer" *ngIf="isLoading">
    <h4>
      <i class="fa fa-cog fa-spin fa-fw"></i>
      {{ "Please wait, loading data ..." | translate }}
    </h4>
  </div>
</app-modal>

<ng-template #loadingSpin>
  <app-form-layout>
    <div class="widget-info-container">
      <h4><i class="fa fa-cog fa-spin fa-fw"></i> {{ 'Please wait, loading data ...' | translate }}</h4>
      <h5>{{ 'This might take a while' | translate }}</h5>
    </div>
  </app-form-layout>
</ng-template>

<ng-template #noDataErrorTemplate>
  <app-form-layout>
    <div class="widget-info-container">
      <h3>{{ 'No data found!' | translate }}</h3>
      <h5>{{ 'No data points were detected for the selected cultivation period.' | translate }}</h5>
      <h5>{{ 'To see Satellite data, please adjust your cultivation period to a few days in the past.' | translate }}</h5>
    </div>
  </app-form-layout>
</ng-template>

<app-info-modal [modalId]="helpTextModalId" [header]="'Normalized Difference Vegetation Index'">
  <app-satellite-ndvi-help-text-modal></app-satellite-ndvi-help-text-modal>
</app-info-modal>
