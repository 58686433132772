<div class="auth-container reset-container" [@formRouterAnimation]
                                  [@errorFormAnimation]="!!errorMsg">

  <app-form-layout
    class="reset auth-item"
    [size]="subDomain?.subDomain !== 'vodafone-idea' ? 'login' : 'login-bigger'">

    <form [formGroup]="resetForm" (ngSubmit)="resetPassword()" #resetform ="ngForm">

      <div class="form-item">
        <app-logo [type]="'reset'"></app-logo>
      </div>

      <alert type="warning" *ngIf="errorMsg">
        {{ errorMsg | translate }}
      </alert>

      <alert type="success" *ngIf="success">
        {{ success | translate }}
      </alert>

      <div class="form-item">
        <app-form-input [placeholder]="'Username'" [icon]="'account'" [formControl]="username"></app-form-input>
        <div class="error" *ngIf="username.hasError('required') && resetform.submitted">
          <p>{{ 'You have to enter username' | translate }}</p>
        </div>
      </div>

      <div class="button-container">
        <app-custom-button [content]="'Reset password'" [disabled]="resetDisabled"></app-custom-button>
      </div>

    </form>

    <div [ngClass]="subDomain?.subDomain !== 'vodafone-idea' ? 'navigate-block' : 'navigate-block-bigger'">
      <app-navigate-button (navigate)="router.navigate(['/auth/login'])" [icon]="'arrow'" [content]="'Back' | translate"></app-navigate-button>
    </div>

  </app-form-layout>

</div>

