export enum CustomFavicon {
  FAVICON_ROBERTAEBIMETEO = 'faviconRobertAebiMeteo',
  FAVICON_FIELDCLIMATE = 'faviconFieldclimate'
}

export type AvailableCustomFavicons = { [key in CustomFavicon]: string };

// map all the types of favicon to their href
export const customFavicons: AvailableCustomFavicons = {
  faviconRobertAebiMeteo: 'robertaebimeteo-favicon.ico',
  faviconFieldclimate: 'fieldclimate-favicon.ico'
};

