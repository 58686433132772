import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StationEventsComponent} from './station-events.component';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../../shared/shared.module';
import {StationEventsRoutingModule} from './station-events-routing.module';
import {StationEventsContentComponent} from './containers/station-events-content/station-events-content.component';
import {reducers} from './reducers';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StationEventsService} from './effects/station-events.service';

@NgModule({
  imports: [
    CommonModule,
    StationEventsRoutingModule,
    SharedModule,
    StoreModule.forFeature('stationEvents', reducers),
    EffectsModule.forFeature([StationEventsService]),
    TranslateModule.forChild()
  ],
  declarations: [StationEventsComponent, StationEventsContentComponent]
})
export class StationEventsModule { }
