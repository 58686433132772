import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IIrrimetState } from '../models/models';

export const irrimetSelector = createFeatureSelector<IIrrimetState>('irrimet');

export const waterBalanceFromToSelector = createSelector(
  irrimetSelector,
  (state) => state.waterBalance.fromTo
);

export const waterBalanceSelector = createSelector(
  irrimetSelector,
  (state) => state.waterBalance
);
