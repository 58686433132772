<aside [class.active]="isActive"
       class="search-widget-container"
       (mouseenter)="hover$.next(true)"
       (mouseleave)="hover$.next(false)">
  <div class="search-container">

      <a (click)="openAddCropZoneModal()"><i class="zmdi zmdi-plus-circle"></i>{{ 'Cropzone/Field/Farm' | translate }}</a>
      <a (click)="openRemoveCropZoneModal()"><i class="zmdi zmdi-minus-circle"></i>{{ 'Cropzone/Field/Farm' | translate }}</a>
      <a [routerLink]="['/cropzone/management']"><i class="zmdi zmdi-folder"></i>{{ 'Management page' | translate }}</a>
    <app-search-widget-input [placeholder]="searchPlaceholder"
                             [formControl]="searchTermControl"
                             (focusin)="focus$.next(true)"
                             (focusout)="focus$.next(false)">
    </app-search-widget-input>
    <div class="order-controls-container">
      <div class="list-order-buttons" role="group">
        <table style="width: 100%">
          <thead>

          </thead>
          <tbody>
            <tr>
              <td class="firstColumn">
                <app-search-widget-order-button
                (applySort)="setOrder($event)"
                [content]="orderSettings[0].name"
                [direction]="getDirection(orderSettings[0])"
                [customCSS]="orderSettings[0].width"
                [orderField]="orderSettings[0].orderField"></app-search-widget-order-button>
              </td>
              <td>
                <app-search-widget-order-button
                (applySort)="setOrder($event)"
                [content]="orderSettings[1].name"
                [direction]="getDirection(orderSettings[1])"
                [customCSS]="orderSettings[1].width"
                [orderField]="orderSettings[1].orderField"></app-search-widget-order-button>
              </td>
            </tr>
            <tr>
              <td class="firstColumn">
                <app-search-widget-order-button
                (applySort)="setOrder($event)"
                [content]="orderSettings[2].name"
                [direction]="getDirection(orderSettings[2])"
                [customCSS]="orderSettings[2].width"
                [orderField]="orderSettings[2].orderField"></app-search-widget-order-button>
              </td>
              <td>
                <app-search-widget-order-button
                (applySort)="setOrder($event)"
                [content]="orderSettings[3].name"
                [direction]="getDirection(orderSettings[3])"
                [customCSS]="orderSettings[3].width"
                [orderField]="orderSettings[3].orderField"></app-search-widget-order-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="search-result-list-container">
    <div class="search-result-list">
      <app-search-widget-list-cropzone-item *ngFor="let item of handledItems"
                                   [item]="item"
                                   [isSelected]="isSelected(item)"
                                   [cropzones]="cropzones"
                                   (selectedCropzone)="selectCropzone($event)"></app-search-widget-list-cropzone-item>
    </div>
    <form class = "toggle-container"
      [formGroup]="form">
      {{ 'Show finished cropzones' | translate }}
      <span class="toggle">
        <app-yes-no-toggle [small]="true"
          [formControlName]="'toggle'">
        </app-yes-no-toggle>
      </span>
    </form>
  </div>
</aside>
