<div class="alert alert-info camera-topology__alert" *ngIf="(stations$ | async) && checkStationTopology()">
  <div *ngIf="isCamera1(); else camera2Controls">
    {{ 'The following devices are connected to this' | translate }} {{ selectedStation.info.device_name }}:

    <ng-container *ngIf="!!controlUnit; else camera1ControlUnitText">
      <a class="camera-topology__link camera-topology__bold"
         (click)="navigateToOtherDevice(controlUnit)">
        {{ controlUnit.name.original }} ({{ controlUnit.info.device_name }})
      </a>
    </ng-container>

    <ng-template #camera1ControlUnitText>
      <span class="camera-topology__bold">{{ selectedStation.topology.ccu }}</span>
    </ng-template>

    <ng-container *ngIf="hasLEDTrap(); else showDeviceCamera2">
      {{ 'and' | translate }}
      <span class="camera-topology__bold">{{ selectedStation.topology.cam2 }}</span>
      ({{ 'LED Trap' | translate }})
    </ng-container>

    <ng-template #showDeviceCamera2>
      <ng-container *ngIf="!!camera2; else camera1Camera2Text">
      <span *ngIf="selectedStation.topology.cam2 !== ''"> {{ 'and' | translate }} </span>
        <a class="camera-topology__link camera-topology__bold"
        (click)="navigateToOtherDevice(camera2)">
        {{ camera2.name.original }} ({{ camera2.info.device_name }})
      </a>
    </ng-container>
    
    <ng-template #camera1Camera2Text>
        <span *ngIf="selectedStation?.topology?.cam2"> {{ 'and' | translate }} </span>
        <span class="camera-topology__bold">{{ selectedStation.topology.cam2 }}</span>
      </ng-template>
    </ng-template>
  </div>
  <ng-template #camera2Controls>
  <div *ngIf="isCamera2()">
    {{ 'The following devices are connected to this' | translate }} {{ selectedStation.info.device_name }}:

    <ng-container *ngIf="!!controlUnit; else camera2ControlUnitText">
      <a class="camera-topology__link camera-topology__bold"
         (click)="navigateToOtherDevice(controlUnit)">
        {{ controlUnit.name.original }} ({{ controlUnit.info.device_name }})
      </a>
    </ng-container>

    <ng-template #camera2ControlUnitText>
      <span class="camera-topology__bold">{{ selectedStation.topology.ccu }}</span>
    </ng-template>

    <span *ngIf="selectedStation.topology.cam1 !== ''"> {{ 'and' | translate }} </span>

    <ng-container *ngIf="!!camera1; else camera2Camera1Text">
      <a class="camera-topology__link camera-topology__bold"
         (click)="navigateToOtherDevice(camera1)">
        {{ camera1.name.original }} ({{ camera1.info.device_name }})
      </a>
    </ng-container>

    <ng-template #camera2Camera1Text>
      <span class="camera-topology__bold">{{ selectedStation.topology.cam1 }}</span>
    </ng-template>
  </div>
  </ng-template>

  <div *ngIf="isControlUnit()">
    {{ 'The following devices are connected to this Control Unit' | translate }}:

    <ng-container *ngIf="!!camera1; else controlUnitCamera1Text">
      <a class="camera-topology__link camera-topology__bold"
         (click)="navigateToOtherDevice(camera1)">
        {{ camera1.name.original }} ({{ camera1.info.device_name }})
      </a>
    </ng-container>

    <ng-template #controlUnitCamera1Text>
      <span class="camera-topology__bold">{{ selectedStation.topology.cam1 }}</span>
    </ng-template>

    <ng-container *ngIf="hasLEDTrap(); else showControlUnitCamera2">
      {{ 'and' | translate }}
      <span class="camera-topology__bold">{{ selectedStation.topology.cam2 }}</span>
      ({{ 'LED Trap' | translate }})
    </ng-container>

    <ng-template #showControlUnitCamera2>
      <span *ngIf="selectedStation.topology.cam2 !== ''"> {{ 'and' | translate }} </span>

      <ng-container *ngIf="!!camera2; else controlUnitCamera2Text">
        <a class="camera-topology__link camera-topology__bold"
           (click)="navigateToOtherDevice(camera2)">
          {{ camera2.name.original }} ({{ camera2.info.device_name }})
        </a>
      </ng-container>

      <ng-template #controlUnitCamera2Text>
        <span class="camera-topology__bold">{{ selectedStation.topology.cam2 }}</span>
      </ng-template>
    </ng-template>
  </div>
</div>
