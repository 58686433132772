<app-form-layout class="section tracker-settings">
    <app-card-header [header]="'Device settings' | translate"
                     [helpButton]="false"></app-card-header>
    <div class="tracker-settings__content">
      <div class="row">
        <form [formGroup]="trackerSettingsForm">
          <div class="col-md-4">
            <label class="tracker-settings__label">{{ 'Trip delimiter' | translate }}</label>
            <app-form-select [type]="'border'"
                             [options]="tripDelimiterOptions"
                             [placeholder]="''"
                             [formControlName]="'trip_delimiter'">
            </app-form-select>
          </div>
        </form>
      </div>
      <div class="tracker-settings__save">
        <app-custom-button [color]="'red'"
                           (click)="save()"
                           [content]="'Save'"></app-custom-button>
      </div>
    </div>
</app-form-layout>