<div class="page-without-left-tree">
  <div class="data-upload-header">
    <h2>{{ 'Data Upload' | translate }}</h2>
    <small>
      {{ 'Upload a file containing sample data' | translate }}
    </small>
  </div>

  <div [@cardAnimation]>
    <app-form-layout>
      <div class="data-upload-card-header">
        <h2>{{ 'Select and upload an iMETOS SoilGuard or DualEx file' | translate }}</h2>
        <!--        <small>{{ 'description' | translate }}</small>-->
      </div>

      <div class="alert-container">
        <alert *ngIf="basicInfo?.resultCode === 0" type="success">
          <strong>{{ 'Data file was successfully uploaded' | translate }}</strong>
          <p>
            <span>{{ 'Station' | translate }}: </span>
            <a [routerLink]="['/station', basicInfo?.stationID, 'data']">
              {{ basicInfo?.stationID }}
            </a>
          </p>
          <p>
            <span>{{ 'Type' | translate }}: </span>
            <span>{{ basicInfo?.detectedType }}</span>
          </p>
          <p>
            <span>{{ 'Serial number' | translate }}: </span>
            <span>{{ basicInfo?.SerialNumber }}</span>
          </p>
          <p>
            <span>{{ 'Detected lines' | translate }}: </span>
            <span>{{ basicInfo?.detectedDataLines }}</span>
          </p>
          <p>
            <span>{{ 'Uploaded lines' | translate }}: </span>
            <span>{{ basicInfo?.insertedDataCount }}</span>
          </p>

          <p>
            <span>{{ 'From' | translate }}: </span>
            <span>{{ parseDate(basicInfo?.timeRangeOfData?.min) }}</span>
          </p>
          <p>
            <span>{{ 'To' | translate }}: </span>
            <span>{{ parseDate(basicInfo?.timeRangeOfData?.max) }}</span>
          </p>
        </alert>
      </div>

      <div class="alert-container">
        <alert *ngIf="errorUpload" type="danger">
          <strong>{{ 'Data file was not parsed' | translate }}</strong>
          <p>{{ errorUpload }}</p>
        </alert>
        <alert *ngIf="uploadMessage" type="info">
          <strong>{{ 'Uploading sample data' | translate }}...</strong>
        </alert>

        <alert *ngIf="basicInfo?.parserErrors?.length" type="info">
          <strong>{{ 'Skipped lines' | translate }}</strong>
          <p *ngFor="let parserError of basicInfo?.parserErrors">
            <span>{{ "line" | translate }}: {{ parserError?.line }} </span> /
            <span>{{ "error" | translate }}: {{ parserError?.error }}</span>
          </p>
        </alert>

        <alert *ngIf="basicInfo?.dataErrors?.length" type="info">
          <strong>{{ 'Processing errors' | translate }}</strong>
          <p *ngFor="let dataError of basicInfo?.dataErrors">
            <span>{{ "line" | translate }}: {{ dataError?.dataLine }} </span> /
            <span>{{ "message" | translate }}: {{ dataError?.message }}</span>
          </p>
        </alert>
      </div>

      <form class="data-upload-form">
        <div class="input-group sample-file-control" (click)="uploadFile.click()">
          <span class="input-group-addon" id="file-import">
            <i class="zmdi zmdi-file-text"></i>
          </span>
          <input type="text"
                 style="cursor: default"
                 class="form-control"
                 [placeholder]="filename || ('Choose file' | translate)"
                 aria-describedby="file-import"
                 readonly="readonly">
          <input #uploadFile type="file" accept=".csv, text/csv" (change)="uploadData($event)" hidden>
        </div>

        <div class="data-upload-form__submit">
          <app-custom-button content="Upload File"
                             [disabled]="blockUpload"
                             color="red"
                             (click)="submitFile()">
          </app-custom-button>
        </div>
      </form>
    </app-form-layout>
  </div>
</div>
