<app-form-layout [shadow]="false">
  <div class="soil-moisture-header" *ngIf="period$ | async as period">
    <div>
      <h2>{{ profile$ | async | translate }}</h2>
      <small>{{ prepareDate(period.periodValue) | translate}} / {{ period.periodScope | translate}}</small>
    </div>
    <div class="soil-moisture-header__right">
      <i class="zmdi zmdi-help" (click)="openModal.emit()"></i>
    </div>
  </div>
</app-form-layout>
