import { IActionWithPayload } from '../../../core/models/actionWithPayload';

export const enum ActionTypes {
  GET_CROPZONE_SAMPLES = '[CropzoneSample] GET_CROPZONE_SAMPLES',
  SET_CROPZONE_SAMPLES = '[CropzoneSample] SET_CROPZONE_SAMPLES',
  GET_SOILGUARD_LAST = '[CropzoneSample] GET_SOILGUARD_LAST',
  GET_SOILGUARD_DATES = '[CropzoneSample] GET_SOILGUARD_DATES',
  SET_SOILGUARD_SAMPLES = '[CropzoneSample] SET_SOILGUARD_SAMPLES',
  GET_DUALEX_LAST = '[CropzoneSample] GET_DUALEX_LAST',
  GET_DUALEX_DATES = '[CropzoneSample] GET_DUALEX_DATES',
  SET_DUALEX_SAMPLES = '[CropzoneSample] SET_DUALEX_SAMPLES',
  GET_MOBILAB_LAST = '[CropzoneSample] GET_MOBILAB_LAST',
  GET_MOBILAB_DATES = '[CropzoneSample] GET_MOBILAB_DATES',
  SET_MOBILAB_SAMPLES = '[CropzoneSample] SET_MOBILAB_SAMPLES'
}


export function getCropzoneSamples(payload: any): IActionWithPayload {
  return { type: ActionTypes.GET_CROPZONE_SAMPLES, payload };
}

export function getSoilGuardLast(payload): IActionWithPayload {
  return { type: ActionTypes.GET_SOILGUARD_LAST, payload };
}

export function getSoilGuardByDate(payload): IActionWithPayload {
  return { type: ActionTypes.GET_SOILGUARD_DATES, payload };
}

export function setSoilGuardSamples(payload): IActionWithPayload {
  return { type: ActionTypes.SET_SOILGUARD_SAMPLES, payload };
}

export function getDualexLast(payload): IActionWithPayload {
  return { type: ActionTypes.GET_DUALEX_LAST, payload };
}

export function getDualexByDate(payload): IActionWithPayload {
  return { type: ActionTypes.GET_DUALEX_DATES, payload };
}

export function setDualexSamples(payload): IActionWithPayload {
  return { type: ActionTypes.SET_DUALEX_SAMPLES, payload };
}

export function getMobilabLast(payload): IActionWithPayload {
  return { type: ActionTypes.GET_MOBILAB_LAST , payload };
}

export function getMobilabByDate(payload): IActionWithPayload {
  return { type: ActionTypes.GET_MOBILAB_DATES, payload };
}

export function setMobilabSamples(payload): IActionWithPayload {
  return { type: ActionTypes.SET_MOBILAB_SAMPLES, payload };
}

export function setCropzoneSamples(payload): IActionWithPayload {
  return { type: ActionTypes.SET_CROPZONE_SAMPLES, payload };
}
