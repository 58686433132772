import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  skip,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { setNotify } from '../../../../core/actions/notify';
import { IStation } from '../../../../core/models/stations';
import { selectStations, selectSystemSensors } from '../../../../core/reducers';
import * as fromAccount from '../../../../core/reducers/account';
import * as fromNotify from '../../../../core/reducers/notify';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { cardAnimation } from '../../../../shared/animations/card-animations';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { generateId } from '../../../dashboard/utils/makeWidget';
import {
  deleteAsset,
  getAssets,
  getAuthData,
  getCatalog,
  getCurrentUserOrganizations,
  getSensors,
  postAsset,
  sendAuthCode
} from '../../actions/my-john-deere';
import {
  CONTRIBUTION_DEFINITION_ID,
  JD_CONTRIBUTION_ACTIVATION, PageFetchType, PAGE_SIZE
} from '../../constants/my-john-deere';
import {
  IAsset,
  IJDOrganization,
  IJDOrganizations,
  IState as IMyJohnDeereState,
  IStationConnectToggled,
  IStationsPageItem
} from '../../models/my-john-deere';
import {
  assets as assetsSelector,
  currentUser,
  currentUserOrganizations,
  currentUserOrganizationsPath,
  selectAuthData,
  sensors as sensorsSelector,
  status as statusSelector
} from '../../selectors/my-john-deere';
import { MyJohnDeereService } from '../../services/my-john-deere.service';
import { uri2path } from '../../utils/my-john-deere';


@Component({
  selector: 'app-my-john-deere',
  templateUrl: './my-john-deere.component.html',
  styleUrls: ['./my-john-deere.component.scss'],
  animations: [cardAnimation()]
})
export class MyJohnDeereComponent implements OnInit, OnDestroy {

  public readonly STATONS_PAGE_SIZE = 10;
  private redirectURL: string;

  public stations$: Observable<IStation[]>;
  public stationsPageItems$: Observable<IStationsPageItem[]>;
  public selectedStation: IStation;
  public orgData;
  public userData;
  public isGrantAccess: boolean;
  public userAssets$: Observable<IAsset[]>;
  public authUserData$: Observable<any>;

  public stationsPageChanged = new BehaviorSubject<number>(0);
  public selectedOrganization: IJDOrganization;
  public selectedOrganizationChanged = new BehaviorSubject<any>(null);
  public stationConnectToggled = new Subject<IStationConnectToggled>();
  public organizationsFetchRequested = new Subject<PageFetchType>();
  public organizationsDropDownIsClosed = new BehaviorSubject<boolean>(true);
  public destroy$ = new Subject<boolean>();
  public assetRequestIsBusy$ = new Subject<boolean>();
  public needAccessGranted = true;
  public currentUserOrganizationsIsLoading = false;
  public grantingAccessIsLoading = false;
  public INFO_MODAL_ID: string = generateId();
  public REVOKE_ACCESS_MODAL_ID: string = generateId();

  private pendingItems: IStation[] = [];

  constructor(
    private myJohnDeereStore: Store<IMyJohnDeereState>,
    private notifyStore: Store<fromNotify.INotifyState>,
    private accountStore: Store<fromAccount.IAccount>,
    private myJohnDeereService: MyJohnDeereService,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiCallService,
    private modal: ModalService
  ) {

    this.myJohnDeereStore.dispatch(getAuthData());

    const token = this.route.snapshot.queryParamMap.get('code');

    if (token) {
      this.myJohnDeereStore.dispatch(sendAuthCode(token));
      this.router.navigate(['.'], { relativeTo: this.route });
    }
  }

  public ngOnInit(): void {

    this.authUserData$ = this.myJohnDeereStore.pipe(
      select(selectAuthData),
      takeUntil(this.destroy$)
    );

    this.authUserData$.subscribe(({ redirectURL }) => {
      this.redirectURL = redirectURL;
      this.needAccessGranted = Boolean(redirectURL);
    });

    this.authUserData$.pipe(skip(1)).subscribe(() => {
      if (!this.needAccessGranted && localStorage.getItem('currentUser')) {
        this.myJohnDeereStore.dispatch(getCatalog());
        this.myJohnDeereStore.dispatch(getCurrentUserOrganizations('/organizations'));
      }
    });

    this.myJohnDeereStore.pipe(
      select(statusSelector),
      takeUntil(this.destroy$)
    ).subscribe(status =>
      this.currentUserOrganizationsIsLoading = status.currentUserOrganizations.loading
    );

    this.myJohnDeereStore.pipe(select(currentUser)).pipe(
      takeUntil(this.destroy$),
      map(user => ({ user })),
      tap(() => {
        combineLatest([
          this.myJohnDeereStore.pipe(select(currentUserOrganizationsPath), filter(path => !!path)),
          this.myJohnDeereStore.pipe(select(currentUserOrganizations), filter(organizations => !organizations))
        ]).pipe(take(1))
          .subscribe(([path, organizations]) => {
            if (path && !this.needAccessGranted && localStorage.getItem('currentUser')) {
              this.myJohnDeereStore.dispatch(getCurrentUserOrganizations(`${path};start=0;count=${PAGE_SIZE}`));
            }
          });
      })
    ).subscribe(user => this.userData = user);

    this.stations$ = this.accountStore.pipe(
      select(selectStations),
      takeUntil(this.destroy$)
    );

    this.myJohnDeereStore.pipe(
      select(currentUserOrganizations),
      takeUntil(this.destroy$),
      tap(organizations => {
        if (Array.isArray(organizations) && !organizations.length) {
          this.notifyStore.dispatch(setNotify('You seem not to be member of any MyJohnDeere organization.'));
        }
      }),
      map(organizations => {
        if (Array.isArray(organizations)) {
          return organizations.reduce((a, b) => {
            return {
              total: b.total,
              items: [
                ...a.items,
                ...b.values
              ]
            };
          }, { total: 0, items: [] });
        }
        return organizations;
      })
    ).subscribe(data => this.orgData = data);

    this.stationsPageItems$ = combineLatest([
      this.stationsPageChanged.asObservable(),
      this.accountStore.pipe(select(selectStations)),
      this.selectedOrganizationChanged.asObservable(),
      this.myJohnDeereStore.pipe(select(assetsSelector))
    ]).pipe(
      filter(([pageNo, stations, organization, assets]) => !!stations && !!organization),
      map(([pageNo, stations, organization, assets]) => {
        const page = stations.slice(pageNo * this.STATONS_PAGE_SIZE, pageNo * this.STATONS_PAGE_SIZE + this.STATONS_PAGE_SIZE)
          .map(station => {
            return {
              station,
              asset: assets.find(asset => asset.station === station.name.original && asset.organization === organization.id)
            };
          });
        this.pendingItems = this.pendingItems.filter(pending => {
          const item = page.find(i => i.station.name.original === pending.name.original);
          return item && item.asset && !item.asset.asset;
        });
        return page;
      }),
      delay(0),
      takeUntil(this.destroy$)
    );

    this.selectedOrganizationChanged.pipe(
      filter(a => !!a),
      distinctUntilChanged((a, b) => a.id === b.id),
      takeUntil(this.destroy$)
    ).subscribe(organization => {
      this.selectedOrganization = organization;

      this.isGrantAccess = this.selectedOrganization.links
        .filter(link => link.rel === 'connections').length > 0;

      this.assetRequestIsBusy$.next(true);
      this.myJohnDeereStore.dispatch(getAssets(organization.id));
      this.myJohnDeereStore.pipe(select(assetsSelector), skip(1), take(1))
        .subscribe(() => this.assetRequestIsBusy$.next(false));
      // store can be bypassed. we just try to set it activated by default
      this.activateFieldClimateAtOrganization(organization);
    });

    this.stationConnectToggled.subscribe(toggled => {
      const { station, asset, add } = toggled;
      this.pendingItems.push(station);
      if (add && station) {
        this.addAsset(station);
      } else if (!add && asset) {
        this.deleteAsset(asset);
      }
    });

    this.organizationsFetchRequested.pipe(
      filter(() => !this.currentUserOrganizationsIsLoading),
      withLatestFrom(this.selectedOrganizationChanged),
      filter(currentOrganization => !!currentOrganization),
      mergeMap(([fetchType, currentOrganization]) => {
        return this.myJohnDeereStore.pipe(select(currentUserOrganizations), take(fetchType === PageFetchType.FetchOne ? 1 : Infinity))
          .pipe(map(organizations => [currentOrganization, organizations]));
      }),
      filter(([currentOrganization, organizations]: [IJDOrganization, IJDOrganizations[]]) => {
        return !(
          !organizations ||
          !organizations.length ||
          this.organizationsDropDownIsClosed.getValue() ||
          this.selectedOrganizationChanged.getValue().id !== currentOrganization.id
        );
      }),
      map(([currentOrganization, organizations]) => organizations)
    ).subscribe(organizations => {
      const nextPage = organizations[organizations.length - 1].links.find(link => link.rel === 'nextPage');
      if (nextPage) {
        this.myJohnDeereStore.dispatch(getCurrentUserOrganizations(uri2path(nextPage.uri)));
      }
    });

  }

  private isTokenExpired(createDate: number): boolean {
    return createDate + 364 * 24 * 60 * 60 * 1000 < Date.now();
  }

  public grantOrgAccess(): void {
    this.selectedOrganization.links
      .filter(link => link.rel === 'connections')
      .map(link => {
        this.notifyStore.dispatch(setNotify('Redirecting to MyJohnDeere.'));
        (window as any).open(link.uri.concat(`?redirect_uri=${location.origin}/user-api-services/my-john-deere`), '_self');
      });
  }

  public grantAccess(): void {

    this.grantingAccessIsLoading = true;

    if (this.redirectURL) {
      this.notifyStore.dispatch(setNotify('Redirecting to MyJohnDeere.'));
      (window as any).open(this.redirectURL, '_self');
    } else {
      this.notifyStore.dispatch(setNotify('Requesting token from MyJohnDeere failed.'));
    }

    this.grantingAccessIsLoading = false; // true
  }

  public revokeAccess(): void {
    this.userAssets$ = this.api.getMyJohnDeereUserAssets();
    this.modal.openModal(this.REVOKE_ACCESS_MODAL_ID);
  }

  public openModal(): void {
    this.modal.openModal(this.INFO_MODAL_ID);
  }

  private deleteAsset(asset): void {
    this.myJohnDeereStore.dispatch(deleteAsset(asset));
  }

  private addAsset(station): void {

    combineLatest([
      this.myJohnDeereStore.pipe(select(sensorsSelector)),
      this.accountStore.pipe(select(selectSystemSensors), take(1))
    ]).pipe(
      mergeMap(([stationSensors, systemSensors]) => {
        if (stationSensors[station.name.original]) {
          return of([stationSensors[station.name.original], systemSensors]);
        } else {
          this.myJohnDeereStore.dispatch(getSensors(station.name.original));
          return of(null);
        }
      }),
      filter(_ => Boolean(_)),
      take(1),
      mergeMap(([stationSensors, systemSensors]) => {
        const organization = this.selectedOrganizationChanged.getValue();
        return this.myJohnDeereService.getAssetConfiguration(station, stationSensors, systemSensors, organization);
      })
    ).subscribe((assetConfiguration: any) => {
      if (assetConfiguration && assetConfiguration.sensors) {
        this.myJohnDeereStore.dispatch(postAsset({
          config: assetConfiguration,
          asset: {
            title: station.name.custom || assetConfiguration.station,
            text: station.info.device_name,
            assetCategory: 'DEVICE',
            assetType: 'SENSOR',
            assetSubType: 'ENVIRONMENTAL',
            links: [{
              '@type': 'Link',
              rel: 'contributionDefinition',
              uri: `/contributionDefinitions/${CONTRIBUTION_DEFINITION_ID}`
            }]
          },
        }));
      }
    });
  }

  private activateFieldClimateAtOrganization(organization: IJDOrganization): void {

    const activateProductLink = organization.links.find(link => link.rel === 'activateProduct');
    if (activateProductLink) {
      this.api.postMyJohnDeereRessource(
        uri2path(activateProductLink.uri), JD_CONTRIBUTION_ACTIVATION
      ).subscribe();
    }

  }

  public isItemPending(item: IStationsPageItem): boolean {
    return this.pendingItems.some(pending => pending.name.original === item.station.name.original);
  }

  public setSelectedStation(item: any): void {
    this.selectedStation = item.station;
  }

  public closeSensorMap(): void {
    this.selectedStation = null;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
