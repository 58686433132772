import { IOptions } from '../../../shared/interfaces';
import { IAddWidgetType, IStationListColumn } from '../models/dashboard.models';

export const GroupValues: Array<IOptions> = [
  {value: 'hourly', content: 'hourly'},
  {value: 'daily', content: 'daily'}
];

export const LastValues: Array<IOptions> = [
  {value: '8', content: '8 hours'},
  {value: '24', content: '24 hours'},
  {value: '48', content: '48 hours'},
  {value: '72', content: '72 hours'},
];

export const LastDailyValues: Array<IOptions> = [
  { content: '7 days', value: '7' },
  { content: '10 days', value: '10' },
  { content: '14 days', value: '14' }
];

export const hourlyTrackerData: Array<IOptions> = [
  { content: '24 hours', value: '1' },
  { content: '48 hours', value: '2' },
  { content: '7 days', value: '7' },
  { content: '10 days', value: '10' },
  { content: '14 days', value: '14' },
  // { content: '30 days', value: '30' }
];

export const hourlyWindroseData: Array<IOptions> = [
  { content: '24 hours', value: '1' },
  { content: '48 hours', value: '2' },
  { content: '7 days', value: '7' },
  { content: '10 days', value: '10' },
  { content: '14 days', value: '14' },
  { content: '30 days', value: '30' },
  { content: '90 days', value: '90' },
  { content: '180 days', value: '180' },
  { content: '360 days', value: '360' },
];

export const ChartProfiles: Array<IOptions> = [
  { value: 'All sensors', content: 'All sensors' },
  { value: 'Disease climate', content: 'Disease climate' },
  { value: 'Frost and temperature monitoring', content: 'Frost and temperature m.' },
  { value: 'Growing climate', content: 'Growing climate' },
  { value: 'Precipitation', content: 'Precipitation' },
  { value: 'Soil and precipitation monitoring', content: 'Soil and precipitation m.' },
  { value: 'Station Health', content: 'Station Health' },
  { value: 'Wind monitoring', content: 'Wind monitoring' }
];

export const WidgetRequests = {
  'sd-data': 'data',
  'sd-highcharts': 'chart',
  'sd-forecast': 'forecast',
  'sd-forecast-chart': 'forecastChart',
  'forecast-table': 'forecastTable',
  'cropview': 'cropview',
  'iscout': 'iscout',
  'sd-diseasemodels': 'disease',
  'sd-trackers': 'tracker'
};

export const WeatherForecast = [
  {
    content: '3 days',
    value: 'general3'
  },
  {
    content: '7 days',
    value: 'general7'
  },
  {
    content: '7 days, simple',
    value: 'simple7'
  }
];

export const ForecastProfiles: Array<IOptions> = [
  { value: 'pictoprint', content: 'Pictoprint' },
  { value: 'meteogram_agro', content: 'Meteogram Agro' },
  { value: 'meteogram_one', content: 'Meteogram One' },
];

export const MapWidgetModes: Array<IOptions> = [
  { value: 'airTemp', content: 'Air temperature' },
  { value: 'rain24h', content: 'Rain, 24 hours' },
  { value: 'rain48h', content: 'Rain, 48 hours' },
  { value: 'rain7d', content: 'Rain, 7 days' },
  { value: 'rh', content: 'Relative humidity' },
  { value: 'windSpeed', content: 'Wind speed' },
  { value: 'solarRadiation', content: 'Solar radiation' },
  { value: 'lw', content: 'Leaf wetness, 1 hour' },
  { value: 'wetBulb', content: 'Wet bulb temperature' },
  { value: 'soilTemp', content: 'Soil temperature' },
  { value: 'battery', content: 'Battery' },
  { value: 'lastComm', content: 'Last communication' },
  // { value: 'dryBulb', content: 'Dry bulb temperature' },
  // { value: 'solarPanel', content: 'Solar panel' },
  // { value: 'volumeter', content: 'Volumeter' },
  // { value: 'tensiometer', content: 'Tensiometer' },
];

export const stationWidgetsIdList: Array<string> = [
  'stations-list',
  'sd-data',
  'sd-highcharts',
  'sd-forecast',
  'sd-forecast-chart',
  'forecast-table',
  'google-map',
  'cropview',
  'iscout',
  'sd-diseasemodels',
  'sd-trackers',
  'wind-rose-diagram'
];

export const cropzoneWidgetsIdList: Array<string> = [
  'cropzone-list'
];

export const cropzoneWidgetOptions: Array<IOptions> = [
  {value: 'SM SUM', content: 'SM SUM'}
];

export const AddWidgets: Array<IAddWidgetType> = [
  {
    name: 'Stations List',
    id: 'stations-list',
    description: 'Basic list of stations',
    text: 'This widget displays the list of your stations, showing Station ID, Station Name, ' +
    'Device Type, Last Communication, Battery level and, for the devices with rain gauge, the Rainfall of the last 24 hours.'
  },
  {
    name: 'Station Data Table',
    id: 'sd-data',
    description: 'Station data widget',
    text: 'A data table for a selected station and profile is displayed. You can group the data hourly ' +
    'or daily and select the last period of time to display.'
  },
  {
    name: 'Station Chart',
    id: 'sd-highcharts',
    description: 'Configurable station charts',
    text: 'You can create a chart for a selected station and profile. You can group the data hourly or ' +
    'daily and select the last period of time to display.'
  },
  {
    name: 'Weather Forecast',
    id: 'sd-forecast',
    description: 'Weather forecast - Picrogram, Meteo One, Meteo Agro',
    text: 'The station-specific weather forecast services provided in collaboration with our partner Meteoblue are ' +
    'displayed for a selected station in the following modes: Meteo One Meteo Agro, Meteo Pictogram. The widget can ' +
    'only be created for stations where the weather forecast service has been previously activated.'
  },
  {
    name: 'Weather Forecast Chart',
    id: 'sd-forecast-chart',
    description: 'Weather forecast - detailed charts',
    text: 'The station-specific weather forecast services provided in collaboration with our partner Meteoblue are ' +
    'displayed for a selected station in the detailed mode. The widget can ' +
    'only be created for stations where the weather forecast service has been previously activated.'
  },
  {
    name: 'Weather Forecast Table',
    id: 'forecast-table',
    description: 'Weather forecast - Table',
    text: 'The station-specific weather forecast services provided in collaboration with our partner Meteoblue are ' +
      'displayed for a selected station in the following modes: 3 days or 7 days. The widget can ' +
      'only be created for stations where the weather forecast service has been previously activated.'
  },
  {
    name: 'Stations Map',
    id: 'google-map',
    description: 'Stations displayed on a map',
    text: 'All your devices are positioned and selectable on the map.'
  },
  {
    name: 'CropView',
    id: 'cropview',
    description: 'Integrate the CropView picture',
    text: 'You can see the last pictures transmitted by CropVIEW device.'
  },
  {
    name: 'iScout',
    id: 'iscout',
    description: 'Integrate the iScout picture',
    text: 'You can see the last pictures transmitted by iScout device.'
  },
  {
    name: 'Disease models',
    id: 'sd-diseasemodels',
    description: 'Configurable disease models charts',
    text: 'You can create a chart for a selected station and profile. You can group the data hourly or daily and ' +
    'select the last period of time to display.'
  },
  {
    name: 'Work Tracking',
    id: 'sd-trackers',
    description: 'Work Tracking overview',
    text: 'This widget displays an overview of trips for the selected work tracking.'
  },
  {
    name: 'Cropzones status',
    id: 'cropzone-list',
    description: 'Cropzones status',
    // tslint:disable-next-line:max-line-length
    text: 'This widget displays the Soil Moisture Sum status in mm/inches from the last hour of all cropzones with available soil moisture sensors. It also displays Sum of Field Capacities and Sum of Refill Points. The cropzones are listed from the driest to the wettest.'
  },
  {
    name: 'Wind Rose Diagram',
    id: 'wind-rose-diagram',
    description: 'Wind Rose Diagram',
    text: 'Wind rose diagram'
  }
];

export const RenewWidgetsTime: number = 3600000;

export const RowStyle: any = {
  padding: '10px 15px 9px 15px',
  lineHeight: 1.42857143,
  verticalAlign: top,
  color: '#5e5e5e',
  fontWeight: 400
};

export const StationsListOptions: Array<IOptions> = [
  { content: 'Air temperature', value: 'airTemperature' },
  { content: 'Rain, 24 hours', value: 'rain24h' },
  { content: 'Rain, 48 hours', value: 'rain2d' },
  { content: 'Rain, 7 days', value: 'rain7d' },
  { content: 'Relative humidity', value: 'relativeHumidity' },
  { content: 'Wind speed', value: 'windSpeed' },
  { content: 'Solar radiation', value: 'solarRadiation' },
  { content: 'Leaf wetness, 1 hour', value: 'lw' },
  { content: 'Wet bulb temperature', value: 'wetBulb' },
  { content: 'Soil temperature', value: 'soilTemperature' }
];

export const StationListRemovedOptions: Array<string> = [
  'deviceType',
  'battery',
  'rain24h',
  'rainfall'
];

export const ColumnsForSort: Array<any> = [
  { name: 'stationID', value: 'strings' },
  { name: 'stationName', value: 'strings' },
  { name: 'deviceType', value: 'strings' },
  { name: 'lastCommunication', value: 'strings' },
  { name: 'max_date', value: 'strings' },
  { name: 'battery', value: 'numbers' },
  { name: 'rain7d', value: 'numbers' },
  { name: 'rain24h', value: 'numbers' },
  { name: 'rain2d', value: 'numbers' },
  { name: 'airTemperature', value: 'numbers' },
  { name: 'relativeHumidity', value: 'numbers' },
  { name: 'soilTemperature', value: 'numbers' },
  { name: 'solarRadiation', value: 'numbers' },
  { name: 'rainfall', value: ''},
  { name: 'windSpeed', value: 'numbers' },
  { name: 'wetBulb', value: 'numbers' },
  { name: 'dryBulb', value: 'numbers' },
  { name: 'lw', value: 'numbers' },
  { name: 'solarPanel', value: 'numbers' }
];

export const DefaultColumnSorting = [{
  colId: 'stationID',
  sort: 'asc'
}];

export const WindSpeed: Array<IOptions> = [
  {value: '', content: 'Select wind speed'},
];

export const WindDirection: Array<IOptions> = [
  {value: '', content: 'Select wind direction'},
];

export const defaultDirections: Array<string> = [
  'N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'
];

export const StationListHeaders: Array<IStationListColumn> = [
  {
    headerName: 'STATION ID',
    field: 'stationID',
    unit: null
  },
  {
    headerName: 'STATION NAME',
    field: 'stationName',
    unit: null
  },
  {
    headerName: 'DEVICE TYPE',
    field: 'deviceType',
    unit: null
  },
  {
    headerName: 'LAST COMMUNICATION',
    field: 'lastCommunication',
    unit: null
  },
  {
    headerName: 'BATTERY [mV]',
    field: 'battery',
    unit: null
  },
  {
    headerName: 'AIR TEMPERATURE',
    field: 'airTemperature',
    unit: {
      metric: ' [°C]',
      imperial: ' [°F]'
    }
  },
  {
    headerName: 'SOIL TEMPERATURE',
    field: 'soilTemperature',
    unit: {
      metric: ' [°C]',
      imperial: ' [°F]'
    }
  },
  {
    headerName: 'RELATIVE HUMIDITY [%]',
    field: 'relativeHumidity',
    unit: null
  },
  {
    headerName: 'SOLAR RADIATION [W/m²]',
    field: 'solarRadiation',
    unit: null
  },
  {
    headerName: 'RAIN, 24 HOURS',
    field: 'rain24h',
    unit: {
      metric: ' [mm]',
      imperial: ' [in]'
    }
  },
  {
    headerName: 'RAIN, 48 HOURS',
    field: 'rain2d',
    unit: {
      metric: ' [mm]',
      imperial: ' [in]'
    }
  },
  {
    headerName: 'RAIN, 7 DAYS',
    field: 'rain7d',
    unit: {
      metric: ' [mm]',
      imperial: ' [in]'
    }
  },
  {
    headerName: 'WIND SPEED',
    field: 'windSpeed',
    unit: {
      metric: ' [m/s]',
      imperial: ' [mph]'
    }
  },
  {
    headerName: 'WET BULB TEMPERATURE',
    field: 'wetBulb',
    unit: {
      metric: ' [°C]',
      imperial: ' [°F]'
    }
  },
  {
    headerName: 'LEAF WETNESS, 1 HOUR [min]',
    field: 'lw',
    unit: null
  }
];
