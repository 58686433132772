<div>
  <ng-container>
    <tabset (click)="selectedTab($event)">
      <tab heading="{{item}}" *ngFor="let item of tabheadings">
        <ng-container *ngIf="!(isLoading$ | async) && !(isError$ | async); else loadinfo">
          <app-form-layout>
            <div class="m-img">
              <img src="{{ imageUrl$ | async }}">
            </div>
          </app-form-layout>
        </ng-container>
      </tab>
    </tabset>
  </ng-container>
</div>
<ng-template #loadinfo>
  <app-weather-forecast-load-info [isError]="isError$ | async" [isLoading]="isLoading$ | async">
  </app-weather-forecast-load-info>
</ng-template>