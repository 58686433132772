<div class="icon-container" *ngIf="isTextForIcon()">
  <div (mouseleave)="hideContainer()">
    <div (mouseenter)="showText()">
      <div class="new-icon-container">
        <img src="{{imageUrl}}" style="width: 28px;">
    </div>
    <div class="text-container" id="{{module}}">
      <div class="close">
        <i class="zmdi zmdi-close" (click)="closeText()"></i>
      </div>
      <div class="text">
        <span>{{ getText() }}</span>
      </div>
    </div>
    </div>
  </div>
  
</div>
