import { Injectable } from '@angular/core';
import { ApiCallService } from '../../../services/api/api-call.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of, from } from 'rxjs';
import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
// tslint:disable-next-line:max-line-length
import { ActionTypes, getFittingCurve, setFittingCurve, setLaiStats, setLaiValsRes10, setVisibleLaiDates, setVisibleLaiVals } from '../actions/index';
// tslint:disable-next-line:max-line-length
import { ActionTypes as ActionTypesNdvi, getFittingCurveNdvi, setFittingCurveNdvi, setNdviStats, setNdviValsRes10, setVisibleNdviDates } from '../actions/ndvi';
import { catchError, concatMap, debounceTime, mergeMap, switchMap, filter, map, withLatestFrom } from 'rxjs/operators';
import { setNoDataError } from './../actions/index';
import { setVisibleNdviVals, setNoDataErrorNdvi } from './../actions/ndvi';
import { Store, select } from '@ngrx/store';
import * as fromLicenses from '../../../core/reducers/licenses';
import { selectAvailableLicenses } from './../../../core/reducers/index';
import moment = require('moment');

@Injectable()
export class LaiEffects {

  constructor(
    private api: ApiCallService,
    private actions$: Actions,
    private licenseStore: Store<fromLicenses.IProductLicenseState>
  ) {
  }

  @Effect()
  public laiStats$ = this.actions$.pipe(
    ofType(ActionTypes.GET_LAI_STATS),
    switchMap((action: IAction) => this.api.getCropZoneLaiStats(action.payload.cropZoneId, action.payload.resolution)
      .pipe(
        switchMap((data) => from([
          setLaiStats(action.payload.cropZoneId, data),
          getFittingCurve(action.payload.cropZoneId, data),
          setNoDataError(action.payload.cropZoneId, false)
        ]))
      )
    )
  );

  @Effect()
  public ndviStats$ = this.actions$.pipe(
    ofType(ActionTypesNdvi.GET_NDVI_STATS),
    switchMap((action: IAction) => this.api.getCropZoneNDVIStats(action.payload.cropZoneId, action.payload.resolution)
      .pipe(
        switchMap((data) => from([
          setNdviStats(action.payload.cropZoneId, data),
          getFittingCurveNdvi(action.payload.cropZoneId, data),
          setNoDataErrorNdvi(action.payload.cropZoneId, false)
        ]))
      )
    )
  );

  @Effect()
  public fittingCurve = this.actions$.pipe(
    ofType(ActionTypes.GET_FITTING_CURVE),
    withLatestFrom(this.licenseStore.pipe(select(selectAvailableLicenses))),
    switchMap(([action, licenses]: [IAction, any[]]) => {
      const selectedLicense = licenses.filter((license) => license.cropzones.some((l) => l.$oid === action.payload.cropZoneId))[0];
      let filteredData: any;
      if (!selectedLicense.Irrimet && selectedLicense.Satellite && !selectedLicense.SoilMoisture) {
        filteredData = action.payload.data.filter((data) => moment(data.date).isBefore(selectedLicense.end_date));
      } else {
        filteredData = action.payload.data;
      }
      return this.api.getFittingCurve(action.payload.cropZoneId, filteredData, 'LAI')
      .pipe(
        switchMap((data) => {
          if (data.length === 0) {
            return from([
              setFittingCurve(action.payload.cropZoneId, [])
            ]);
          } else {
            return from([
              setFittingCurve(action.payload.cropZoneId, data['data'])
            ]);
          }
        }),
        catchError(() => from([setFittingCurve(action.payload.cropZoneId, null)]))
      ); })
    );

  @Effect()
  public fittingCurveNdvi = this.actions$.pipe(
    ofType(ActionTypesNdvi.GET_FITTING_CURVE_NDVI),
    withLatestFrom(this.licenseStore.pipe(select(selectAvailableLicenses))),
    switchMap(([action, licenses]: [IAction, any]) => {
      const selectedLicense = licenses.filter((license) => license.cropzones.some((l) => l.$oid === action.payload.cropZoneId))[0];
      let filteredData: any;
      if (!selectedLicense.Irrimet && selectedLicense.Satellite && !selectedLicense.SoilMoisture) {
        filteredData = action.payload.data.filter((data) => moment(data.date).isBefore(selectedLicense.end_date));
      } else {
        filteredData = action.payload.data;
      }
      return this.api.getFittingCurve(action.payload.cropZoneId, filteredData, 'NDVI')
      .pipe(
        switchMap((data) => {
          if (data.length === 0) {
            return from([
              setFittingCurveNdvi(action.payload.cropZoneId, [])
            ]);
          } else {
            return from([
              setFittingCurveNdvi(action.payload.cropZoneId, data['data'])
            ]);
          }
        }),
        catchError(() => from([setFittingCurveNdvi(action.payload.cropZoneId, null)]))
      ); })
  );

  @Effect()
  public visibleLaiDates$ = this.actions$.pipe(
    ofType(ActionTypes.GET_VISIBLE_LAI_DATES),
    debounceTime(300),
    switchMap((action: IAction) => this.api.getCropZoneLaiVals(
      action.payload.cropZoneId, action.payload.dates, action.payload.resolution, false)
      .pipe(
        switchMap(data => {
          return [setVisibleLaiDates(action.payload.cropZoneId, data)];
        })
      )
    )
  );

  @Effect()
  public visibleNdviDates$ = this.actions$.pipe(
    ofType(ActionTypesNdvi.GET_VISIBLE_NDVI_DATES),
    debounceTime(300),
    switchMap((action: IAction) => this.api.getCropZoneNDVIVals(
      action.payload.cropZoneId, action.payload.dates, action.payload.resolution, false)
      .pipe(
        switchMap(data => [setVisibleNdviDates(action.payload.cropZoneId, data)])
      )
    )
  );

  @Effect()
  public visibleLaiVals$ = this.actions$.pipe(
    ofType(ActionTypes.GET_VISIBLE_LAI_VALS),
    mergeMap((action: IAction) => this.api.getCropZoneLaiVals(
      action.payload.cropzone.id, action.payload.dates, action.payload.resolution, true)
      .pipe(
        switchMap(data => [
          setVisibleLaiVals(action.payload.cropzone, data)
        ]),
        catchError(() => of())
      )
    )
  );

  @Effect()
  public visibleNdviVals$ = this.actions$.pipe(
    ofType(ActionTypesNdvi.GET_VISIBLE_NDVI_VALS),
    mergeMap((action: IAction) => this.api.getCropZoneNDVIVals(
      action.payload.cropzone.id, action.payload.dates, action.payload.resolution, true)
      .pipe(
        switchMap(data => [
          setVisibleNdviVals(action.payload.cropzone, data)
        ]),
        catchError(() => of())
      )
    )
  );

  @Effect()
  public laiValsRes10$ = this.actions$.pipe(
    ofType(ActionTypes.GET_LAI_VAL_RES_10),
    concatMap((action: IAction) => {
      return this.api.getCropZoneLaiVals(
        action.payload.cropZoneId, action.payload.dates, 10, false)
        .pipe(
          switchMap(data => [setLaiValsRes10(action.payload.cropZoneId, data)]),
          catchError(() => of())
        );
    })
  );

  @Effect()
  public ndviValsRes10$ = this.actions$.pipe(
    ofType(ActionTypesNdvi.GET_NDVI_VAL_RES_10),
    concatMap((action: IAction) => {
      return this.api.getCropZoneNDVIVals(
        action.payload.cropZoneId, action.payload.dates, 10, false)
        .pipe(
          switchMap(data => [setNdviValsRes10(action.payload.cropZoneId, data)]),
          catchError(() => of())
        );
    })
  );

}
