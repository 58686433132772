import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {WarningsRouterModule} from './warnings-router.module';
import {EffectsModule} from '@ngrx/effects';
import {reducers} from './reducers';
import {StoreModule} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {WarningsEffects} from './effects/warnings.effects';
import {WarningsHelpService} from './services/warnings-help.service';
import {WarningsComponent} from './containers/warnings/warnings.component';
import {WarningsListComponent} from './containers/warnings-list/warnings-list.component';
import {WarningsListSaveComponent} from './containers/warnings-list/warnings-list-save/warnings-list-save.component';
import {WarningsListModalComponent} from './containers/warnings-list/warnings-list-modal/warnings-list-modal.component';
import {WarningsListRowComponent} from './containers/warnings-list/warnings-list-row/warnings-list-row.component';
import {WarningsPhonesComponent} from './containers/warnings-phones/warnings-phones.component';
import {WarningsPhoneSaveComponent} from './containers/warnings-phones/warnings-phone-save/warnings-phone-save.component';
import {WarningsPhoneModalComponent} from './containers/warnings-phones/warnings-phone-modal/warnings-phone-modal.component';
import {WarningsPhoneFormComponent} from './containers/warnings-phones/warnings-phone-form/warnings-phone-form.component';
import {WarningsPhoneListComponent} from './containers/warnings-phones/warnings-phone-list/warnings-phone-list.component';
import {WarningsPhoneRemoveModalComponent}
  from './containers/warnings-phones/warnings-phone-list/warnings-phone-remove-modal/warnings-phone-remove-modal.component';
import {WarningsPhoneListRowComponent}
  from './containers/warnings-phones/warnings-phone-list/warnings-phone-list-row/warnings-phone-list-row.component';
import {WarningsPhoneDirective} from './directives/warnigs-phone/warnings-phone.directive';
import {WarningSensorDirective} from './directives/warnings-sensor/warning-sensor.directive';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    WarningsRouterModule,
    TranslateModule.forChild(),
    StoreModule.forFeature('warnings', reducers),
    EffectsModule.forFeature([WarningsEffects]),
  ],
  declarations: [
    WarningsComponent,
    WarningsListComponent,
    WarningsListSaveComponent,
    WarningsListModalComponent,
    WarningsListRowComponent,
    WarningsPhonesComponent,
    WarningsPhoneSaveComponent,
    WarningsPhoneModalComponent,
    WarningsPhoneFormComponent,
    WarningsPhoneListComponent,
    WarningsPhoneRemoveModalComponent,
    WarningsPhoneListRowComponent,
    WarningsPhoneDirective,
    WarningSensorDirective
  ],
  providers: [
    WarningsHelpService
  ]
})
export class WarningsModule { }
