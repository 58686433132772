import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';

export const enum ActionTypes {
  SET_SAMPLE_SETTINGS = '[CropzoneSample] SET_SAMPLE_SETTINGS',
  SET_SAMPLE_DATES = '[CropzoneSample] SET_SAMPLE_DATES',
  SET_SAMPLE_ACTIVE = '[CropzoneSample] SET_SAMPLE_ACTIVE',
  SET_SAMPLE_LASTMODE = '[CropzoneSample] SET_SAMPLE_LASTMODE',
  SET_DEVICE_TYPE = '[CropzoneSample] SET_DEVICE_TYPE',
  SET_ACTIVE_METER = '[CropzoneSample] SET_ACTIVE_METER',
  SET_ACTIVE_TITLE = '[CropzoneSample] SET_ACTIVE_TITLE',
  SET_ACTIVE_DEVICE = '[CropzoneSample] SET_ACTIVE_DEVICE',
  SET_ACTIVE_SAMPLE = '[CropzoneSample] SET_ACTIVE_SAMPLE',
  SET_ACTIVE_DEVICE_TYPE = '[CropzoneSample] SET_ACTIVE_DEVICE_TYPE'
}


export function setSampleSettings(payload): IAction {
  return { type: ActionTypes.SET_SAMPLE_SETTINGS, payload };
}

export function setSampleDates(payload): IAction {
  return { type: ActionTypes.SET_SAMPLE_DATES, payload };
}

export function setSampleActive(payload): IAction {
  return { type: ActionTypes.SET_SAMPLE_ACTIVE, payload };
}

export function setSampleLastMode(payload): IAction {
  return { type: ActionTypes.SET_SAMPLE_LASTMODE, payload };
}

export function setDeviceType(payload): IAction {
  return { type: ActionTypes.SET_DEVICE_TYPE, payload };
}

export function setActiveMeter(payload): IAction {
  return { type: ActionTypes.SET_ACTIVE_METER, payload };
}

export function setActiveTitle(payload): IAction {
  return { type: ActionTypes.SET_ACTIVE_TITLE, payload };
}

export function setActiveDevice(payload): IAction {
  return { type: ActionTypes.SET_ACTIVE_DEVICE, payload };
}

export function setActiveSample(payload): IAction {
  return { type: ActionTypes.SET_ACTIVE_SAMPLE, payload };
}

export function setActiveDeviceType(payload): IAction {
  return { type: ActionTypes.SET_ACTIVE_DEVICE_TYPE, payload };
}
