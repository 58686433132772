import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { IDrawingOptions, IPicture, IToolbarSettings } from '../../../../shared/camera/models/camera';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { getWeekForCameraPicture } from '../../../../shared/utils/dateFormat';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { setCameraDistance } from '../../actions/crop-view-settings';
import { ICropViewSettingsState, ICropViewState } from '../../models/crop-view.models';
import {
  selectCameraDistance,
  selectCameraDrawingOptions,
  selectCropViewSettingsAreMeasurementsActive,
  selectCropViewSettingsForToolbar
} from '../../reducers';

@Component({
  selector: 'app-crop-view-image-settings',
  templateUrl: './crop-view-image-settings.component.html',
  styleUrls: ['./crop-view-image-settings.component.scss']
})
export class CropViewImageSettingsComponent implements OnInit, OnDestroy {
  @Input()
  public title: string;
  @Input()
  public stationId: string;
  @Input()
  public activeCameraId: number;
  @Input()
  public hasWritePermission: boolean;
  @Input()
  public selectedPicture$: Observable<IPicture>;

  @Output()
  public unselectPictureEmitter: EventEmitter<void> = new EventEmitter<void>();

  public helpModalId: string = generateId();
  public measurementsAreActive$: Observable<boolean>;
  public toolbarSettings$: Observable<IToolbarSettings>;
  public cameraDistance$: Observable<number>;
  public cameraDrawingOptions$: Observable<IDrawingOptions>;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private cropViewStore: Store<ICropViewState>,
              private cropViewSettingsStore: Store<ICropViewSettingsState>,
              private modalService: ModalService) {
  }

  public ngOnInit(): void {
    this.initStatusListeners();
    this.initCameraListeners();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public changeCameraDistance(distance: number): void {
    this.cropViewSettingsStore.dispatch(setCameraDistance(distance));
  }

  public unselectPicture(): void {
    this.unselectPictureEmitter.emit();
  }

  public getCurrentDateForImage(pictureTime): string {
    return getWeekForCameraPicture(pictureTime);
  }

  public openHelpModal(): void {
    this.modalService.openModal(this.helpModalId);
  }

  private initStatusListeners(): void {
    this.measurementsAreActive$ = this.cropViewSettingsStore.pipe(
      select(selectCropViewSettingsAreMeasurementsActive)
    );

    this.toolbarSettings$ = this.cropViewSettingsStore.pipe(
      take(1),
      select(selectCropViewSettingsForToolbar)
    );
  }

  private initCameraListeners(): void {
    this.cameraDistance$ = this.cropViewSettingsStore.pipe(
      takeUntil(this.destroy$),
      select(selectCameraDistance),
    );

    // Get show/hide settings from API
    this.cameraDrawingOptions$ = this.cropViewSettingsStore.pipe(
      takeUntil(this.destroy$),
      select(selectCameraDrawingOptions),
      map((drawingOptions) => {
        // Check if it's empty, then return a default object
        if (Object.keys(drawingOptions).length === 0) {
          return {
            showCircleScale: false,
            showCircleMeasurement: false,
            showMarked: true,
            showUnmarked: true
          };
        }
        return drawingOptions;
      })
    );
  }
}
