import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { ILaiStat, ILaiVal } from '../models';
import { ICropZone } from './../../../core/models/cropzones';

export const enum ActionTypes {
    GET_LAI_STATS = '[CropZoneSat] GET_LAI_STATS',
    SET_LAI_STATS = '[CropZoneSat] SET_LAI_STATS',
    GET_VISIBLE_LAI_DATES = '[CropZoneSat] GET_VISIBLE_LAI_DATES',
    SET_VISIBLE_LAI_DATES = '[CropZoneSat] SET_VISIBLE_LAI_DATES',
    SET_LAI_VAL_RES_10 = '[CropZoneSat] SET_LAI_VAL_RES_10',
    GET_LAI_VAL_RES_10 = '[CropZoneSat] GET_LAI_VAL_RES_10',
    ADD_VISIBLE_LAI_VAL = '[CropZoneSat] ADD_VISIBLE_LAI_VAL',
    GET_VISIBLE_LAI_VALS = '[CropZoneSat] GET_VISIBLE_LAI_VALS',
    SET_VISIBLE_LAI_VALS = '[CropZoneSat] SET_VISIBLE_LAI_VALS',
    SET_NO_DATA_ERROR = '[CropZoneSat] SET_NO_DATA_ERROR',
    GET_FITTING_CURVE = '[CropZoneSat] GET_FITTING_CURVE',
    SET_FITTING_CURVE = '[CropZoneSat] SET_FITTING_CURVE',
    SET_REDCHI = '[CropZoneSat] SET_REDCHI',
    SET_FITTING_CURVE_ERROR = '[CropZoneSat] SET_FITTING_CURVE_ERROR',
    GET_FITTING_CURVE_ERROR = '[CropZoneSat] GET_FITTING_CURVE_ERROR',
    DELETE_ALL_LAI = '[CropZoneSat] DELETE_ALL_LAI',
    SET_SAT_WARNING_MESSAGE = '[CropZoneSat] SET_SAT_WARNING_MESSAGE'
}

export function addVisibleLaiVal(cropZoneId: string, date: string, resolution: number): IAction {
    return { type: ActionTypes.ADD_VISIBLE_LAI_VAL, payload: { cropZoneId, date, resolution}};
}

export function getLaiStats(cropZoneId: string, resolution: number): IAction {
    return { type: ActionTypes.GET_LAI_STATS, payload: { cropZoneId, resolution } };
}

export function setLaiStats(cropZoneId: string, laiStats: ILaiStat[] | string): IAction {
    return { type: ActionTypes.SET_LAI_STATS, payload: { cropZoneId, laiStats } };
}

export function getVisibleLaiDates(cropZoneId: string, dates: string[], resolution: number): IAction {
    return { type: ActionTypes.GET_VISIBLE_LAI_DATES, payload: { cropZoneId, dates, resolution } };
}

export function getVisibleLaiVals(cropzone: ICropZone, dates: string[], resolution: number): IAction {
    return { type: ActionTypes.GET_VISIBLE_LAI_VALS, payload: { cropzone, dates, resolution } };
}

export function getLaiValRes10(cropZoneId: string, dates: string[]): IAction {
    return { type: ActionTypes.GET_LAI_VAL_RES_10, payload: { cropZoneId, dates, resolution: 10 } };
}

export function setVisibleLaiDates(cropZoneId: string, laiVals: ILaiVal[]): IAction {
    return { type: ActionTypes.SET_VISIBLE_LAI_DATES, payload: { cropZoneId, laiVals } };
}

export function setVisibleLaiVals(cropzone: ICropZone, laiVals: ILaiVal[]): IAction {
    return { type: ActionTypes.SET_VISIBLE_LAI_VALS, payload: { cropzone, laiVals } };
}

export function setLaiValsRes10(cropZoneId: string, laiVals: ILaiVal[]): IAction {
    return { type: ActionTypes.SET_LAI_VAL_RES_10, payload: { cropZoneId, laiVals}};
}

export function setNoDataError(cropZoneId: string, noDataError: boolean): IAction {
    return { type: ActionTypes.SET_NO_DATA_ERROR, payload: { cropZoneId, noDataError }};
}

export function  getFittingCurve(cropZoneId: string, data: any): IAction {
    data = data.filter((item) => item.cloud.mean <= 0.05);
    return { type: ActionTypes.GET_FITTING_CURVE, payload: { cropZoneId, data }};
}

export function  setFittingCurve(cropZoneId: string, data: any): IAction {
    return { type: ActionTypes.SET_FITTING_CURVE, payload: { cropZoneId, data }};
}

export function  setRechi(cropZoneId: string, redchi: any): IAction {
    return { type: ActionTypes.SET_REDCHI, payload: { cropZoneId, redchi }};
}

export function deleteAllLai(cropZoneId: string): IAction {
    return { type: ActionTypes.DELETE_ALL_LAI, payload: cropZoneId};
}

export function setSatWarningMessage(message: string|null, icon: boolean): IAction {
    return { type: ActionTypes.SET_SAT_WARNING_MESSAGE, payload: {message: message, icon: icon} };
}
