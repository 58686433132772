import {IOptions} from '../../../shared/interfaces';
import { IFromTo } from '../../station-data/models/station-data.models';

export interface IHeaderData {
  title: string;
  subtitle: string;
  help: string;
  period?: {
    title: string;
    from: Date;
    to: Date;
  };
}

export interface IGetWeatherForecastRequest {
  stationId: string;
  mode: string;
}
export interface IGetWeatherForecastImageRequest extends IGetWeatherForecastRequest {
  endpointType: string;
}

export const DEFAULT_WEATHER_FORECAST_MODE: IOptions =   {
  content: '3 days',
  value: 'general3'
};

export const WEATHER_FORECAST_MODES: IOptions[] = [
  DEFAULT_WEATHER_FORECAST_MODE,
  {
    content: '7 days',
    value: 'general7'
  }
];

export interface IWeatherForecastState {
  chartsOptions: Array<any>;
  grid: {};
  isLoading: boolean;
  isError: boolean;
  mode: string;
  weatherForecastLicenses: Array<IFromTo>;
  warning: IForecastWarningMessage;
}

export interface IForecastWarningMessage {
  message: string|null;
  icon: boolean;
}

export interface IWeatherForecastSettingsActivity {
  isChartActive: boolean;
  isTableActive: boolean;
  imageExportInProgress: boolean;
}

export interface IWeatherForecastStationModeSettings {
  disabledGroupIds: {
    [groupId: string]: boolean
  };
}
export interface IWeatherForecastStationSettings {
  selectedMode: string;
  modes: {
    [mode: string]: IWeatherForecastStationModeSettings;
  };
}

export interface IWeatherForecastSettings {
  [stationId: string]: IWeatherForecastStationSettings;
}

export interface IWeatherForecastSettingsState {
  settings: IWeatherForecastSettings;
  activity: IWeatherForecastSettingsActivity;
}

export interface IWeatherForecastSettingsPayload {
  groupdId: string;
  mode: string;
  stationId: string;
}

export const InitialWeatherForecastStationSetting: IWeatherForecastStationSettings = {
  modes: {},
  selectedMode: DEFAULT_WEATHER_FORECAST_MODE.value
};

export interface IWeatherForecastSettingsModePayload {
  mode: string;
  stationId: string;
}

export interface IWeatherForecastImageState {
  imageUrl: string;
  isLoading: boolean;
  isError: boolean;
}

export interface IImageResponse {
  [index: number]: string;
}
