import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Column, ColumnGroup, ExcelExportParams, GridOptions } from 'ag-grid';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ISelectedSearchWidgetItem } from '../../../../core/models/selectedSearchWidgetItem';
import { selectSelectedStation } from '../../../../core/reducers';
import * as fromSelectedStation from '../../../../core/reducers/selectedStation';
import { DataGridOptions, ExcelExportSettings } from '../../../../shared/constants';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';
import { ITreeSettingsState } from '../../../../shared/tree/models/tree.models';
import { suppressMenu } from '../../../../shared/utils/suppressMenu';
import { setStationExportActive } from '../../actions/station-data-settings';
import { selectStationDataGrid, selectStationDataTableActive, selectTreeSettings } from '../../reducers';
import * as fromStationSettings from '../../reducers/station-data-settings';
import * as fromStationData from './../../reducers/station-data';

@Component({
  selector: 'app-station-data-table',
  templateUrl: './station-data-table.component.html',
  styleUrls: ['./station-data-table.component.scss']
})

export class StationDataTableComponent implements OnInit, OnDestroy {
  public stationData        : any = {};
  public isDataTableActive$ : Observable<boolean>;
  public isTableHidden      : boolean = false;
  public dataGridOptions    : GridOptions = DataGridOptions;

  private tree            : any = {};
  private profile         : string = 'All sensors';
  private destroy$        : Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store<fromStationData.IStationDataState>,
              private exportService: StationDataExportService,
              private stationSettingsStore: Store<fromStationSettings.IStationDataSettingsState>,
              private selectedStationStore: Store<fromSelectedStation.ISelectedStationState>,
              private treeSettingsStore: Store<ITreeSettingsState>) { }

  private toggleColumns(): void {
    if (!this.dataGridOptions.api) {
      return;
    }
    this.dataGridOptions.columnApi.getAllColumns().forEach(
      (c: Column) => {
        const isInvisible: string = Object.keys(this.tree).find((t) => c.getId().startsWith(t));
        if (!isInvisible && !c.isVisible()) {
          this.dataGridOptions.columnApi.setColumnVisible(c.getId(), true);
        }
      }
    );

    Object.keys(this.tree).forEach((key: string) => {
      const gr: ColumnGroup = this.dataGridOptions.columnApi.getColumnGroup(key);
      if (gr) {
        gr.getOriginalColumnGroup().getChildren().forEach((column) => {
          if (column.isVisible()) {
            this.dataGridOptions.columnApi.setColumnVisible(column.getId(), false);
          }
        });
      } else {
        this.dataGridOptions.columnApi.setColumnVisible(key, false);
      }
    });
    this.isTableHidden = this.dataGridOptions.columnApi.getAllDisplayedVirtualColumns().length === 1;
  }

  private exportXLS(id: string): void {
    const params: ExcelExportParams = {
      ...ExcelExportSettings,
      fileName: `${id}_station_data`
    };

    this.dataGridOptions.api.exportDataAsExcel(params);
  }

  public ngOnInit(): void {
    this.exportService.getExportXLS().pipe(
      takeUntil(this.destroy$)
    ).subscribe((id: string) => {
      this.exportXLS(id);
    });

    this.selectedStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectSelectedStation),
      switchMap((id: ISelectedSearchWidgetItem) => this.treeSettingsStore.pipe(
        takeUntil(this.destroy$),
        select(selectTreeSettings),
        filter((tree: ITreeSettingsState): boolean => !!tree
            && !!tree[id.original_name]
            && !!tree[id.original_name].profileSettings[this.profile]),
        tap((tree: ITreeSettingsState): void => {
          this.profile = tree[id.original_name].selectedProfile;
        }),
        map((tree: ITreeSettingsState) => tree[id.original_name].profileSettings[this.profile].disabledGroupIds)
      ))
    ).subscribe((tree: any) => {
      this.tree = tree;
      setTimeout(() => {
        this.toggleColumns();
      }, 0);
    });

    this.isDataTableActive$ = this.stationSettingsStore.pipe(
      takeUntil(this.destroy$),
      select(selectStationDataTableActive),
    );

    this.store.pipe(
      takeUntil(this.destroy$),
      select(selectStationDataGrid),
      filter((data: object): boolean => !!data)
    ).subscribe((data: any) => {
      this.stationData = data;
      this.stationSettingsStore.dispatch(setStationExportActive(!Array.isArray(data)));
      setTimeout(() => {
        if (data.headers && this.dataGridOptions.api) {
          data.headers = suppressMenu(data.headers);
          data.headers[0].unSortIcon = true;
          this.dataGridOptions.api.setColumnDefs(this.stationData.headers);
          this.dataGridOptions.api.setRowData(this.stationData.data);
          this.toggleColumns();
        } else if (this.dataGridOptions.api) {
          this.dataGridOptions.api.setColumnDefs(null);
          this.dataGridOptions.api.setRowData(null);
        }
      }, 0);
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
