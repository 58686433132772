import { Component, EventEmitter, Input, Output, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IPicture } from '../../../../shared/camera/models/camera';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { StationCameraTypes } from '../../../camera-data/constants/camera-data';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { IThemeConfig } from '../../../../../environments/interfaces/theme';
import { environmentToken } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/interfaces/environment';

@Component({
  selector: 'app-iscout-image-gallery',
  templateUrl: './iscout-image-gallery.component.html',
  styleUrls: ['./iscout-image-gallery.component.scss']
})
export class IscoutImageGalleryComponent {
  @Input()
  public title: string = '';
  @Input()
  public deviceType: StationCameraTypes;
  @Input()
  public pictures: Array<IPicture> = [];
  @Input()
  public helpIsActive$: Observable<boolean>;

  @Output()
  public selectPictureEmitter: EventEmitter<IPicture> = new EventEmitter<IPicture>();

  public modalId: string = generateId();
  public subDomain          : IThemeConfig;

  public constructor(
      @Inject(environmentToken) environment: IEnvironment,
      private modalService: ModalService
  ) {
    this.subDomain = environment.theme;
  }

  public selectPicture(activePicture: IPicture): void {
    this.selectPictureEmitter.emit(activePicture);
  }

  public openHelpModal(): void {
    this.modalService.openModal(this.modalId);
  }
}
