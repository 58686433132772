<app-form-layout>
  <div class="white">
    <table>
      <thead>
        <tr>
          <th *ngFor="let header of headers">
            {{ header }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td *ngFor="let value of values">
            {{ value }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</app-form-layout>
