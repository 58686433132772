import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ExcelExportParams, GridOptions } from 'ag-grid';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DataGridOptions, ExcelExportSettings } from '../../../../shared/constants';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';
import { suppressMenu } from '../../../../shared/utils/suppressMenu';
import { selectStationDiseaseSettingsIsTableActive, selectStationDiseaseTable } from '../../reducers';
import * as fromDisease from '../../reducers/station-disease';
import * as fromDiseaseSettings from '../../reducers/station-disease-settings';

@Component({
  selector: 'app-station-disease-table',
  templateUrl: './station-disease-table.component.html',
  styleUrls: ['./station-disease-table.component.scss']
})
export class StationDiseaseTableComponent implements OnInit, OnDestroy {
  public isDataTableActive$ : Observable<boolean>;
  public dataGridOptions    : GridOptions = DataGridOptions;

  private destroy$          : Subject<boolean> = new Subject<boolean>();

  constructor(private exportService: StationDataExportService,
              private diseaseStore: Store<fromDisease.IStationDiseaseState>,
              private diseaseSettingsStore: Store<fromDiseaseSettings.IStationDiseaseSettingsState>) { }

  public ngOnInit(): void {
    this.exportService.getExportXLS().pipe(
      takeUntil(this.destroy$)
    ).subscribe((id: string) => {
      this.exportXLS(id);
    });

    this.isDataTableActive$ = this.diseaseSettingsStore.pipe(
      select(selectStationDiseaseSettingsIsTableActive)
    );

    this.diseaseStore.pipe(
      takeUntil(this.destroy$),
      select(selectStationDiseaseTable),
      filter((stationData: any) => !!stationData)
    ).subscribe((stationData: any) => {
      setTimeout(() => {
        if (stationData.headers && this.dataGridOptions.api) {
          stationData.headers = suppressMenu(stationData.headers);
          stationData.headers[0].unSortIcon = true;
          this.dataGridOptions.api.setColumnDefs(stationData.headers);
          this.dataGridOptions.api.setRowData(stationData.data);
        } else if (this.dataGridOptions.api) {
          this.dataGridOptions.api.setColumnDefs(null);
          this.dataGridOptions.api.setRowData(null);
        }
      }, 0);
    });
  }

  private exportXLS(id: string): void {
    const params: ExcelExportParams = {
      ...ExcelExportSettings,
      fileName: `${id}_disease_models`,
    };

    this.dataGridOptions.api.exportDataAsExcel(params);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
