import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../services/auth/auth.service';

@Component({
  selector: 'app-deeplink',
  templateUrl: './deeplink.component.html',
  styleUrls: ['./deeplink.component.scss']
})
export class DeeplinkComponent implements OnInit {

  public constructor(private router: Router,
                     private route: ActivatedRoute,
                     private auth: AuthService) {}

  public ngOnInit(): void {
    const token = this.route.snapshot.params['deepLinkToken'];
    this.auth.deepLinkLogin(token).subscribe(
      () => {},
      () => this.router.navigate(['/auth/login'])
    );
  }

}
