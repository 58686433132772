import { Injectable } from '@angular/core';
import {ISensor} from '../../../shared/interfaces';
import {INode, INodes, INodesNames, IUpdateSensor, ISerialNames} from '../models/sensors-and-nodes';

@Injectable()
export class SensorsAndNodesHelpService {
  private nodesNames: INodesNames = {};
  private serialNames: ISerialNames = {};

  constructor() { }

  public prepareNodes(response: ISensor[]): INodes {
    const nodes: INodes = {};
    response.forEach((s: ISensor) => {
      const nodeName: string = `${s.mac}/${s.serial}`;
      const sensorName: string = `${s.ch}_${s.code}_${s.registered}`;
      s.units = Array.isArray(s.units) ? s.units : [];
      this.checkConflict(s);
      if (!nodes[nodeName]) {
        nodes[nodeName] = this.getBasicNodes();
        nodes[nodeName].mac = s.mac;
        nodes[nodeName].serial = s.serial;
        nodes[nodeName].name = this.getCustomName(s.mac, s.serial);
      }
      nodes[nodeName].sensors[sensorName] = s;
    });
    return nodes;
  }

  public setNodesNames(nodes: INodesNames, serials: ISerialNames ): void {
    this.nodesNames = nodes;
    this.serialNames = serials;
  }

  private getBasicNodes(): INode {
    return {
      mac: '',
      name: '',
      serial: '',
      isChanged: false,
      sensors: {}
    };
  }

  private getCustomName(mac: string, serial: string|number): string {
    if (serial && String(serial).toLowerCase() !== 'x') {
      return this.serialNames[serial] ? this.serialNames[serial].name : '';
    }
    return this.nodesNames[mac] ? this.nodesNames[mac].name : '';
  }

  private checkConflict(s: ISensor): void {
    if (!s.units.includes(s.unit)) {
      s.unitConflict = true;
    }
  }

  public prepareUpdateSensor(sensor: ISensor, key: string, nodeName: string, isChanged: boolean = true): IUpdateSensor {
    return {
      nodeId: nodeName,
      sensorId: key,
      sensor: {
        unit: sensor.unit,
        color: sensor.color,
        name_custom: sensor.name_custom,
        isChanged: isChanged
      }
    };
  }
}
