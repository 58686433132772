<div class="warnings-list-modal">
  <p>
    {{ 'In this section you can define an upper or lower threshold for every sensor at which SMS warning will be sent. SMS warning ' +
    'is sent if the sensor value falls below the defined minimum value (warning at min) or if the sensor value exceeds the defined maximum value ' +
    '(warning at max).' | translate }}
  </p>

  <p>
    {{ 'You can insert several values (thresholds) for one sensor. In this case you need to separate them with a comma example: 5.2,0,-20). You can also input negative values.' | translate }}
  </p>

  <p>
    {{ 'When you finish the configuration, do not forget to save the new settings (click on “SAVE WARNING SETTINGS” button at the end of the warnings list).' | translate }}
  </p>
</div>
