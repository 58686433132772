import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { removeDashboard, saveDefault, setDashboardName } from '../../actions/dashboard';
import { selectEdit, selectId, selectMain, selectName } from '../../reducers';
import * as fromDashboard from '../../reducers/dashboard';

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: [
    './dashboard-menu.component.scss',
    '../../../../shared/common.scss'
  ]
})
export class DashboardMenuComponent implements OnInit, OnDestroy {
  @Output()
  public restore: EventEmitter<void> = new EventEmitter();
  @Output()
  public addColumns: EventEmitter<number> = new EventEmitter();
  @Output()
  public setDefault: EventEmitter<void> = new EventEmitter();
  @Output()
  public save: EventEmitter<void> = new EventEmitter();
  @Output()
  private editMode: EventEmitter<void> = new EventEmitter();

  private destroy$: Subject<boolean> = new Subject<boolean>();

  public isMain                     : boolean;
  public isEditMode                 : boolean;
  public isNew                      : boolean;
  public name                       : string;
  public isOptionsOpen              : boolean = false;
  public isSaved                    : boolean = false;
  public isAddDashboardButtonClicked: boolean = false;

  public constructor(
    private dashboardStore          : Store<fromDashboard.IDashboard>
  ) {

  }

  public ngOnInit(): void {

    this.dashboardStore.pipe(
      takeUntil(this.destroy$),
      select(selectName)
    ).subscribe(name => this.name = name);

    combineLatest([
      this.dashboardStore.pipe(select(selectMain)),
      this.dashboardStore.pipe(select(selectEdit)),
      this.dashboardStore.pipe(select(selectId))
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.isMain = data[0];
        this.isEditMode = data[1];
        this.isNew = !data[2];
      });
  }

  public updateName(name: string): void {
    this.dashboardStore.dispatch(setDashboardName(name));
  }

  public saveDefault(): void {
    this.dashboardStore.dispatch(saveDefault());
  }

  public remove(): void {
    this.dashboardStore.dispatch(removeDashboard());
  }

  public toggleEdit(): void {
    this.editMode.emit();
  }

  public openOptions(): void {
    setTimeout(() => this.isOptionsOpen = true);
  }

  public saved(): void {
    this.isSaved = true;
    this.isAddDashboardButtonClicked = false;
  }

  public onAddDashboardButtonIsClicked(): void {
    this.isAddDashboardButtonClicked = true;
    this.isSaved = false;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
