import { GoogleMapLibraries } from './../../shared/constants';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TrackerRoutingModule} from './tracker-routing.module';
import {TrackerComponent} from './containers/tracker/tracker.component';
import {TranslateModule} from '@ngx-translate/core';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {reducer} from './reducers';
import {SharedModule} from '../../shared/shared.module';
import {AgmCoreModule} from '@agm/core';
import {environment} from '../../../assets/themes/default/configuration';
import {AgGridModule} from 'ag-grid-angular';
import {TrackerDataEffect} from './effects/tracker.effect';
import {TrackerMapComponent} from './containers/tracker-map/tracker-map.component';
import {TrackerTableComponent} from './containers/tracker-table/tracker-table.component';
import {TrackerToolbarComponent} from './containers/tracker-toolbar/tracker-toolbar.component';
import {TrackerHeaderComponent} from './containers/tracker-header/tracker-header.component';
import { TrackerReportsComponent } from './containers/tracker-reports/tracker-reports.component';

@NgModule({
  imports: [
    CommonModule,
    TrackerRoutingModule,
    SharedModule,
    StoreModule.forFeature('tracker', reducer),
    EffectsModule.forFeature([TrackerDataEffect]),
    AgmCoreModule.forRoot({
      apiKey: environment.gMapKey,
      libraries: GoogleMapLibraries
    }),
    AgGridModule.withComponents([]),
    TranslateModule.forChild(),
  ],
  declarations: [
    TrackerComponent,
    TrackerMapComponent,
    TrackerTableComponent,
    TrackerToolbarComponent,
    TrackerHeaderComponent,
    TrackerReportsComponent
  ],
  providers: []
})
export class TrackerModule { }
