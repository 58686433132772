import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { selectSelectedCropZone } from '../../../../core/reducers';
import * as fromSelectedCropzone from '../../../../core/reducers/selectedCropZone';
import { DeviceValues, PeriodValues } from '../../../../shared/constants';
import { setSampleSettings } from '../../actions/crozone-settings.action';
import { DeviceTypeValues, MeterValues, TitleValues } from '../../constants/sample-data.constants';
import { ICropzoneSampleState, selectSamples } from '../../reducers';

@Component({
  selector: 'app-sample-data-toolbar',
  templateUrl: './sample-data-toolbar.component.html',
  styleUrls: ['./sample-data-toolbar.component.scss']
})
export class SampleDataToolbarComponent implements OnInit, OnDestroy {

  @Output()
  public activateTable = new EventEmitter<boolean>();

  @Output()
  public dropSelection = new EventEmitter<boolean>();
  public alive$ = new Subject<void>();
  public startDate;
  public endDate;
  public cropzone$;
  public periodForm: FormGroup;
  public periodValues = PeriodValues;
  public deviceValues = [{content: 'All Devices', value: 'all'}];
  public isLastMode = true;
  public isTableActive = true;
  public activeType = '';
  public meterOptions = [null];
  public deviceTypes = DeviceTypeValues;
  public titleValues = TitleValues;

  constructor(
    private formBuilder: FormBuilder,
    private cropzoneSampleStore: Store<ICropzoneSampleState>,
    private selectedStore: Store<fromSelectedCropzone.ISelectedCropZoneState>
  ) {

  }

  @Input()
  public set activeDeviceType(value) {
    if (value) {
      this.meterOptions = MeterValues[value];
      this.activeType = value;
    }
  }

  public get fromTo(): AbstractControl {
    return this.periodForm.get('fromTo');
  }

  public get fromDate(): AbstractControl {
    return this.periodForm.get('fromDate');
  }

  public get toDate(): AbstractControl {
    return this.periodForm.get('toDate');
  }

  public get periodValue(): AbstractControl {
    return this.periodForm.get('periodValue');
  }

  public get deviceType(): AbstractControl {
    return this.periodForm.get('deviceType');
  }

  public get activeMeter(): AbstractControl {
    return this.periodForm.get('activeMeter');
  }

  public ngOnInit(): void {

    this.cropzone$ = this.selectedStore.pipe(
      select(selectSelectedCropZone),
      takeUntil(this.alive$),
      distinctUntilChanged((prev, curr) => prev.id === curr.id),
    ).subscribe(cropzone => {
      this.startDate = moment(cropzone.from).toDate();

      const endDate = moment(cropzone.to);
      const today = moment();

      if (today < endDate) {
        this.endDate = today.toDate();
      } else {
        this.endDate = endDate.toDate();
      }

      this.createForm();
    });

    this.cropzoneSampleStore.pipe(
      select(selectSamples),
      takeUntil(this.alive$),
      filter(_ => Boolean(_)),
    ).subscribe(samples => {
      this.deviceValues = DeviceValues.filter(option =>
        option.value === 'all' || samples[option.value].length
      );
    });

    this.periodValue.valueChanges.pipe(
      takeUntil(this.alive$)
    ).subscribe(period => {
      this.fromTo.setValue({
        from: moment(this.endDate).subtract(period, 'day').toDate(),
        to: this.endDate
      });
    });
  }

  public createForm(): void {

    this.periodForm = this.formBuilder.group({
      'periodValue': ['30d', [Validators.required]],
      'fromTo': [null, [Validators.required]],
      'fromDate': [null, [Validators.required]],
      'toDate': [null, [Validators.required]],
      'isExportActive': [false, [Validators.required]],
      'activeTitle': [true, [Validators.required]],
      'deviceType': [],
      'activeMeter': ['temp']
    });

    this.periodValue.setValue('30d');
    const floor = moment(this.endDate).subtract(30, 'day').toDate();

    this.fromDate.setValue(floor);
    this.toDate.setValue(this.endDate);

    this.fromTo.setValue({
      from: floor,
      to: this.endDate
    });

    this.deviceType.valueChanges.pipe(
      takeUntil(this.alive$),
      tap(_ => this.activeType = _)
    ).subscribe(_ => this.meterOptions = MeterValues[_]);

    this.submitSettings();
  }

  public updateLastMode(lastMode: boolean): void {
    if (lastMode) {
      this.fromTo.setValue({
        from: this.fromDate,
        to: this.toDate
      });
    }

    this.isLastMode = lastMode;
  }

  public submitSettings(): any {

    this.cropzoneSampleStore.dispatch(
      setSampleSettings({
        ...this.periodForm.value,
        deviceType: this.activeType,
        isLastMode: this.isLastMode
      })
    );
  }

  public dropActive(): any {
    this.dropSelection.emit(true);
  }

  public toggleTable(): void {
    this.isTableActive = !this.isTableActive;
    this.activateTable.emit(this.isTableActive);
  }

  public resetSettings(): void {
    this.createForm();
  }

  public ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }
}
