import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromIrrimetConfig from './irrimet-config.reducer';

export interface IState {
  configData: fromIrrimetConfig.IDataSourcesState;
}

export const reducers = {
  configData: fromIrrimetConfig.reducer,
};

export const irrimetConfig = createFeatureSelector<IState>('irrimet-config');

export const selectCropsLazyLoading = createSelector(
  irrimetConfig,
  (irrimetConfigState: IState) => irrimetConfigState.configData.cropsLazyLoading
);

export const selectDatasourcesRain = createSelector(
  irrimetConfig,
  (irrimetConfigState: IState) => irrimetConfigState.configData.datasourcesRain
);

export const selectDatasourcesETO = createSelector(
  irrimetConfig,
  (irrimetConfigState: IState) => irrimetConfigState.configData.datasourcesETO
);

export const rainDatasourceById = (id: string) => createSelector(
  irrimetConfig,
  (irrimetConfigState: IState) => irrimetConfigState.configData.datasourcesRain.find(o => o.id === id)
);

export const etoDatasourceById = (id: string) => createSelector(
  irrimetConfig,
  (irrimetConfigState: IState) => irrimetConfigState.configData.datasourcesETO.find(o => o.id === id)
);

export const cropById = (id: string) => createSelector(
  irrimetConfig,
  (irrimetConfigState: IState) => irrimetConfigState.configData.cropsLazyLoading.find(o => o.id === id)
);

export const getCollectConfigData = createSelector(
  irrimetConfig,
  (irrimetConfigState: IState) => irrimetConfigState.configData.collectConfigData
);
