import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'angular-highcharts';
import * as moment from 'moment';
import { Observable, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, switchMap, filter, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { selectSettings } from '../../../../core/reducers';
import { IAccount } from '../../../../core/reducers/account';
import { WORK_PLANNING_TREE_NODES } from '../../../../services/tree/constants';
import { IChartAndLegend, IChartLegendItem } from '../../../../shared/interfaces';
import { setGroupAndProfile, TreeSettingsActionTypes } from '../../../../shared/tree/actions/tree-settings';
import { ITreeSettingsState } from '../../../../shared/tree/models/tree.models';
import addChartTooltip from '../../../../shared/utils/addChartTooltip';
import addChartZoomSync from '../../../../shared/utils/addChartZoomSync';
import { deepClone } from '../../../../shared/utils/deepClone';
import { getWorkPlanningCharts } from '../../actions';
import { SERVICE_CHART_LEGEND, SERVICE_CHART_TOOLTIP_PROPERTIES, SERVICE_HELP_CONTENT } from '../../constants';
import { IHeaderData, IWorkPlanningState } from '../../models/work-planning.models';
import {
  selectWorkPlanningCharts, selectWorkPlanningIsError,
  selectWorkPlanningIsLoading, selectWorkPlanningTreeSettings
} from '../../reducers';
import { selectLicenses } from './../../reducers/index';
import { WorkPlanningService } from './../../services/work-planning.service';

@Component({
  selector: 'app-work-planning-content',
  templateUrl: './work-planning-content.component.html',
  styleUrls: ['./work-planning-content.component.scss']
})
export class WorkPlanningContentComponent implements OnInit, OnDestroy {
  public isLoading$                   : Observable<boolean>;
  public isError$                     : Observable<boolean>;
  public chartsAndLegends$            : Observable<IChartAndLegend[]>;
  public validLicense                 : boolean = null;
  public header                       : IHeaderData = {
                                          title: '', subtitle: '', help: '', period: {
                                            title: '', from: null, to: null
                                          }
                                        };

  private destroyed$                  : Subject<boolean> = new Subject<boolean>();
  private selectedService             : string;
  private selectedCrop                : string;
  public exportChartImg               : boolean = false;
  public unitSystem                   : string;

  constructor(
    private store               : Store<IWorkPlanningState>,
    private tree                : Store<ITreeSettingsState>,
    private activatedRoute      : ActivatedRoute,
    private translate           : TranslateService,
    private accountStore: Store<IAccount>,
    private workPlanningService : WorkPlanningService
  ) { }


  public ngOnInit(): void {

    this.isLoading$ = this.store.pipe(
      select(selectWorkPlanningIsLoading)
    );
    this.isError$ = this.store.pipe(
      select(selectWorkPlanningIsError)
    );

    this.accountStore.pipe(
      select(selectSettings),
      filter((settings) => !!settings)
    ).subscribe(settings => this.unitSystem = settings.unit_system);

    this.chartsAndLegends$ = this.store.pipe(
      select(selectWorkPlanningCharts),
      switchMap(charts => {
        let tooltip = SERVICE_CHART_TOOLTIP_PROPERTIES[this.selectedService];
        if (tooltip && charts.length > 1) {
          return this.translate.get(tooltip.map(tip => tip.name))
            .pipe(map((i18n) => {
              tooltip = deepClone(tooltip).map(tip => ({ prop: tip.prop, name: i18n[tip.name] }));
              return addChartTooltip(charts, [tooltip], this.unitSystem);
            }));
        }
        return [charts];
      }),
      map(charts => charts.map(c => new Chart(c))),
      tap(charts => this.setHeader(this.selectedService, this.selectedCrop, charts)),
      map(addChartZoomSync),
      map(charts => this.addChartLegends(charts, (charts.length > 1 ? [SERVICE_CHART_LEGEND[this.selectedService]] : [])))
    );

    combineLatest([
      this.activatedRoute.params.pipe(
        distinctUntilChanged((a, b) => a.service === b.service && a.crop === b.crop && a.id === b.id),
        withLatestFrom(this.tree.pipe(select(selectWorkPlanningTreeSettings))),
        takeUntil(this.destroyed$)
      ),
      this.store.pipe(
        select(selectLicenses),
        takeUntil(this.destroyed$)
      )
    ]).subscribe(([[params, settings], licenses]) => {
      const { id, service, crop} = params;
      const setting = settings[id];

      if (service) {
        this.getCharts(id, service, crop);
        this.setHeader(service, crop);
        this.validLicense = this.workPlanningService.setWarningForLicense(params.service, licenses);

        if (!setting || setting.selectedProfile !== crop || setting.selectedProfileGroup !== service) {
          this.setTreeSelection(id, service, crop || service);
        }
      }
    });
  }

  private getCharts(stationId: string, service: string, crop: string): void {
    this.selectedService = service;
    this.selectedCrop = crop;
    this.store.dispatch(getWorkPlanningCharts({
      stationId,
      service,
      crop
    }));
  }

  public exportCharts(value : boolean): void {
    this.exportChartImg = value;
  }

  private setTreeSelection(stationId: string, group: string, profile: string): void {
    this.tree.dispatch(setGroupAndProfile({
      stationId,
      group,
      profile
    }, TreeSettingsActionTypes.SET_WORK_PLANNING_GROUP_AND_PROFILE));
  }

  private setHeader(service: string, crop: string, charts?: Chart[]): void {

    const subNode = WORK_PLANNING_TREE_NODES.find(n => n.id === service);
    const node = subNode.nodes.find(n => n.id === crop);

    // There may be exceptions when each item from a model service have it's own help section.
    let help;
    if (typeof SERVICE_HELP_CONTENT[service][crop] !== 'undefined') {
      help = SERVICE_HELP_CONTENT[service][crop].toString();
    } else {
      help = SERVICE_HELP_CONTENT[service].toString();
    }

    if (charts && charts[0]) {
      const from = charts[0].options.series[0].data[0][0];
      const to = charts[0].options.series[0].data[charts[0].options.series[0].data.length - 1][0];
      const offset = new Date().getTimezoneOffset();
      this.header = {
        title: '' + (subNode ? subNode.name : ''),
        subtitle: node ? node.name : '',
        help,
        period: {
          title: 'Forecast',
          from: moment(from).add(offset, 'minutes').toDate(),
          to: moment(to).add(offset, 'minutes').toDate(),
        }
      };
    } else {
      this.header = {
        title: '' + (subNode ? subNode.name : ''),
        subtitle: node ? node.name : '',
        help,
        period: this.header.period
      };
    }

  }

  private addChartLegends(charts: Chart[], legends: IChartLegendItem[][]): IChartAndLegend[] {
    return charts.map((chart, i) => {
      return { chart: chart, legends: legends[i] || [] };
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
