import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { INode } from '../../../../../services/tree/models';

@Component({
  selector: 'app-sensor-leaves',
  templateUrl: './sensor-leaves.component.html',
  styleUrls: ['./sensor-leaves.component.scss']
})
export class SensorLeavesComponent implements OnInit {
  @Input()
  public ulClass: string;
  @Input()
  public leaves: INode[];
  @Input()
  public disabledGroupIds: {};
  @Output()
  public toggleSensorEmitter: EventEmitter<INode> = new EventEmitter<INode>();


  constructor() { }

  public ngOnInit(): void {
  }

  public isDisabled(leaf: INode): boolean {
    return this.disabledGroupIds.hasOwnProperty(leaf.groupId);
  }

  public toggleSensor(leaf: INode): void {
    this.emitToggleSensor(leaf);
  }

  public emitToggleSensor(node: INode): void {
    this.toggleSensorEmitter.emit(node);
  }
}
