import {IActionWithPayload} from '../models/actionWithPayload';
import {Action} from '@ngrx/store';
import {IAccount} from '../reducers/account';
import {IAddress, ICompany, IInfo, INewsLetter, ISettings, ITermsSavePayload, IMessage, IFlagSettings} from '../models/account';
import {IColumn} from '../../modules/dashboard/models/dashboard.models';
import { IStation } from '../models/stations';
import { ICropZone } from '../models/cropzones';

export const enum AccountActionsTypes {
  GET_USER = '[Account] GET_USER',
  SET_USER = '[Account] SET_USER',
  UPDATE_INFO = '[Account] UPDATE_INFO',
  UPDATE_ADDRESS = '[Account] UPDATE_ADDRESS',
  UPDATE_SETTINGS = '[Account] UPDATE_SETTINGS',
  UPDATE_COMPANY = '[Account] UPDATE_COMPANY',
  UPDATE_NOTIFICATIONS = '[Account] UPDATE_NOTIFICATIONS',
  UPDATE_DASHBOARD = '[Account] UPDATE_DASHBOARD',
  TOGGLE_AUTO_SYNC = '[Account] TOGGLE_AUTO_SYNC',
  SAVE_PASSWORD = '[Account] SAVE_PASSWORD',
  SAVE_OTHER_SETTINGS = '[Account] SAVE_OTHER_SETTINGS',
  SAVE_NOTIFICATIONS = '[Account] SAVE_NOTIFICATIONS',
  SAVE_TERMS = '[Account] SAVE_TERMS',
  GET_NEW_API_KEYS = '[Account] GET_NEW_API_KEYS',
  SEND_SUPPORT_MESSAGE= '[UserSupport] SEND_MESSAGE',
  UPDATE_SHOW_FINISHED_CROPZONES = '[Account] UPDATE_SHOW_FINISHED_CROPZONES',
  CLOSE_ACCOUNT = '[Account] CLOSE_ACCOUNT',
  SET_DELETED_ACCOUNT = '[Account] SET_DELETED_ACCOUNT',
  CLOSE_DASHBOARD_TEXT = '[Account] CLOSE_DASHBOARD_TEXT',
  GET_USERS_CLOSED_TEXTS = '[Account] GET_USERS_DASHBOARD_TEXTS',
  SET_USERS_CLOSED_TEXTS = '[Account] SET_USERS_CLOSED_TEXTS',
  SAVE_USERS_CLOSED_TEXTS = '[Account] SAVE_USERS_CLOSED_TEXTS',
  GET_USERS_CLOSED_MODULES_TEXTS = '[Account] GET_USERS_CLOSED_MODULES_TEXTS',
  SET_USERS_CLOSED_MODULES_TEXTS = '[Account] SET_USERS_CLOSED_MODULES_TEXTS',
  UPDATE_USERS_CLOSED_MODULES_TEXTS = '[Account] UPDATE_USERS_CLOSED_MODULES_TEXTS',
  UPDATE_STARRED_STATION = '[Account] UPDATE_STARRED_STATION',
  UPDATE_STARRED_CROPZONE = '[Account] UPDATE_STARRED_CROPZONE',
  UPDATE_FLAG_SETTINGS = '[Account] UPDATE_FLAG_SETTINGS',
}

export function saveTerms(terms: ITermsSavePayload): IActionWithPayload {
  return { type: AccountActionsTypes.SAVE_TERMS, payload: terms };
}

export function updateDashboard(dashboard: Array<Array<IColumn>>): IActionWithPayload {
  return { type: AccountActionsTypes.UPDATE_DASHBOARD, payload: dashboard };
}

export function savePassword(password: string): IActionWithPayload {
  return { type: AccountActionsTypes.SAVE_PASSWORD, payload: password };
}

export function saveOtherSettings(settings: any): IActionWithPayload {
  return { type: AccountActionsTypes.SAVE_OTHER_SETTINGS, payload: settings };
}

export function saveNotifications(notifications: any): IActionWithPayload {
  return { type: AccountActionsTypes.SAVE_NOTIFICATIONS, payload: notifications };
}

export function toggleAutoSync(): Action {
  return { type: AccountActionsTypes.TOGGLE_AUTO_SYNC };
}

export function updateNotifications(notifications: INewsLetter): IActionWithPayload {
  return { type: AccountActionsTypes.UPDATE_NOTIFICATIONS, payload: notifications };
}

export function updateCompany(company: ICompany): IActionWithPayload {
  return { type: AccountActionsTypes.UPDATE_COMPANY, payload: company };
}

export function updateSettings(settings: ISettings): IActionWithPayload {
  return { type: AccountActionsTypes.UPDATE_SETTINGS, payload: settings };
}

export function updateAddress(address: IAddress): IActionWithPayload {
  return { type: AccountActionsTypes.UPDATE_ADDRESS, payload: address };
}

export function updateInfo(info: IInfo): IActionWithPayload {
  return { type: AccountActionsTypes.UPDATE_INFO, payload: info };
}

export function updateFlags(flags: IFlagSettings): IActionWithPayload {
  return { type: AccountActionsTypes.UPDATE_FLAG_SETTINGS, payload: flags };
}

export function getUser(): Action {
  return { type: AccountActionsTypes.GET_USER };
}

export function setUser(data: IAccount): IActionWithPayload {
  return { type: AccountActionsTypes.SET_USER, payload: data };
}

export function getNewApiKeys(): IActionWithPayload {
  return { type: AccountActionsTypes.GET_NEW_API_KEYS, payload: undefined };
}

export function sendSupportMessage(message: IMessage) : IActionWithPayload {
  return { type: AccountActionsTypes.SEND_SUPPORT_MESSAGE, payload: message };
}

export function updateShowFinishedCropzones(showFinishedCropzones: boolean): IActionWithPayload {
  return { type: AccountActionsTypes.UPDATE_SHOW_FINISHED_CROPZONES, payload: showFinishedCropzones };
}

export function closeAccount(): Action {
  return { type: AccountActionsTypes.CLOSE_ACCOUNT };
}

export function setDeletedAccount(deletedAccount: boolean): IActionWithPayload {
  return { type: AccountActionsTypes.SET_DELETED_ACCOUNT, payload: deletedAccount };
}

export function closeDashboardText(index: number): IActionWithPayload {
  return { type: AccountActionsTypes.CLOSE_DASHBOARD_TEXT, payload: index };
}

export function getUsersClosedTexts(): Action {
  return { type: AccountActionsTypes.GET_USERS_CLOSED_TEXTS };
}

export function setUsersClosedTexts(closedTexts: Array<string>): IActionWithPayload {
  return { type: AccountActionsTypes.SET_USERS_CLOSED_TEXTS, payload: closedTexts };
}

export function saveUsersClosedTexts(closedTexts: Array<number>): IActionWithPayload {
  return { type: AccountActionsTypes.SAVE_USERS_CLOSED_TEXTS, payload: closedTexts };
}

export function getUsersClosedModulesTexts(): Action {
  return { type: AccountActionsTypes.GET_USERS_CLOSED_MODULES_TEXTS };
}

export function setUsersClosedModulesTexts(closedTexts: Array<string>): IActionWithPayload {
  return { type: AccountActionsTypes.SET_USERS_CLOSED_MODULES_TEXTS, payload: closedTexts };
}

export function updateUsersClosedModulesTexts(closedTexts: Array<string>): IActionWithPayload {
  return { type: AccountActionsTypes.UPDATE_USERS_CLOSED_MODULES_TEXTS, payload: closedTexts };
}

export function updateStarredStation(value: boolean, id: string, stations: IStation[]): IActionWithPayload {
  return { type: AccountActionsTypes.UPDATE_STARRED_STATION, payload: {starred: value, stationID: id, stations: stations} };
}

export function updatedStarredCropzone(cropzone: ICropZone): IActionWithPayload {
  return { type: AccountActionsTypes.UPDATE_STARRED_CROPZONE, payload: cropzone };
}
