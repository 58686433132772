<app-form-layout [class.hide]="isTableActive === false">
  <div class="sample-data-table-content">


    <div class="sample-data-header" style="margin-bottom: 10px" *ngIf="tableData && tableData.length">

      <a [routerLink]="['/station', tableData[0]?.deviceID || tableData[0]?.sampleName, 'data']">
        <strong style="font-size: 16px">{{ tableData[0]?.deviceID || tableData[0]?.sampleName }}</strong>
      </a>

      <br>
      <span style="font-size: 14px; margin-bottom: 10px ">{{ deviceType }}</span>
    </div>

    <ag-grid-angular *ngIf="columnDefs"
                     class="ag-theme-bootstrap"
                     [gridOptions]="dataGridOptions"
                     [rowData]="tableData"
                     (rowClicked)="setActiveSample($event)"
                     [columnDefs]="columnDefs">
    </ag-grid-angular>
  </div>
</app-form-layout>
