import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginRoutingModule} from './login-routing.module';
import {LoginComponent} from './login.component';
import {AuthModule} from '../../auth.module';
import {TranslateModule} from '@ngx-translate/core';
import { LanguageSelectComponent } from '../../components/language-select/language-select.component';

@NgModule({
  imports: [
    CommonModule,
    LoginRoutingModule,
    AuthModule,
    TranslateModule.forChild()
  ],
  declarations: [LoginComponent, LanguageSelectComponent]
})
export class LoginModule { }
