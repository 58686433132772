import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { StationConfigDefaultScheduler } from '../../../constants/constants';

@Component({
  selector: 'app-station-led-trap-scheduler',
  templateUrl: './station-led-trap-scheduler.component.html',
  styleUrls: ['./station-led-trap-scheduler.component.scss']
})
export class StationLedTrapSchedulerComponent implements OnInit, OnDestroy {
  @Input()
  public scheduler: Array<Array<string>>;

  @Output()
  private changeScheduler: EventEmitter<Array<Array<string>>> = new EventEmitter<Array<Array<string>>>();

  public form: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly steps: number = 6;
  private readonly stepSize: number = 4;

  constructor(private fb: FormBuilder) {}

  public ngOnInit(): void {
    this.form = this.fb.group({
      scheduler: this.createSchedulerFormControl()
    });

    if (this.scheduler) {
      this.schedulerArray.setValue(this.scheduler);
    }

    this.form.valueChanges.pipe(
      debounceTime(100),
      distinctUntilChanged((p, c) => JSON.stringify(p) === JSON.stringify(c)),
      takeUntil(this.destroy$)
    ).subscribe((form: any) => {
      this.changeScheduler.emit(form.scheduler);
    });
  }

  private createSchedulerFormControl(): FormArray {
    const formArray: FormArray = this.fb.array([]);
    for (let i = 0; i < this.steps; i++) {
      const subArray: FormArray = this.fb.array([]);
      for (let j = 0; j < this.stepSize; j++) {
        subArray.push(this.fb.control('0'));
      }
      formArray.push(subArray);
    }
    return formArray;
  }

  public get schedulerArray(): FormArray {
    return <FormArray>this.form.get('scheduler');
  }

  public getTime(i: number, j: number): string {
    const hoursCount: number = (i * 4) + j;
    const hours: string = hoursCount < 10 ? `0${hoursCount}` : `${hoursCount}`;
    return `${hours}:00`;
  }

  public selectAll(value: string = '1', half: boolean = false): void {
    this.schedulerArray.controls.forEach((a: FormArray) => {
      a.controls.forEach((c: AbstractControl, index) => {
        if (half && index !== 2) {
          c.setValue('0');
        } else {
          c.setValue(value);
        }
      });
    });
  }

  public selectDefault(): void {
    this.selectAll('0');
    // Select 20:00, 21:00, 22:00 as default
    (<FormArray>this.schedulerArray.controls[5]).controls[0].setValue('1');
    (<FormArray>this.schedulerArray.controls[5]).controls[1].setValue('1');
    (<FormArray>this.schedulerArray.controls[5]).controls[2].setValue('1');
  }

  public isSelectDefaultActive(): boolean {
    const schedulerString = this.schedulerArray
      .getRawValue()
      .reduce((p, a) => p.concat(a), [])
      .join('');

    return schedulerString === StationConfigDefaultScheduler.CAMERA_LED_TRAP_SCHEDULER;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
