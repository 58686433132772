import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AgmCoreModule } from '@agm/core';
import { ProductActivationRoutingModule } from './product-activation-routing.module';
import { ProductActivationHeaderComponent } from './components/product-activation-header/product-activation-header.component';
import { ProductActivationLicenseAlertComponent } from './components/product-activation-license-alert/product-activation-license-alert.component';
import { ProductActivationComponent } from './containers/product-activation/product-activation.component';
import { ProductActivationFormComponent } from './containers/product-activation/product-activation-form/product-activation-form.component';
import { ProductActivationVwsComponent } from './containers/product-activation-vws/product-activation-vws.component';
import { ProductActivationVwsFormComponent } from './containers/product-activation-vws/product-activation-vws-form/product-activation-vws-form.component';
import { ProductActivationDiseaseModelComponent } from './containers/product-activation-disease-model/product-activation-disease-model.component';
import { ProductActivationDiseaseModelFormComponent } from './containers/product-activation-disease-model/product-activation-disease-model-form/product-activation-disease-model-form.component';
import { ProductActivationForecastComponent } from './containers/product-activation-forecast/product-activation-forecast.component';
import { ProductActivationForecastFormComponent } from './containers/product-activation-forecast/product-activation-forecast-form/product-activation-forecast-form.component';
import { ProductActivationServiceLicenseComponent } from './containers/product-activation-service-license/product-activation-service-license.component';
import { ProductActivationServiceLicenseFormComponent } from './containers/product-activation-service-license/product-activation-service-license-form/product-activation-service-license-form.component';
import { ProductActivationDavisIngestComponent } from './containers/product-activation-davis-ingest/product-activation-davis-ingest.component';
import { ProductActivationDavisIngestFormComponent } from './containers/product-activation-davis-ingest/product-activation-davis-ingest-form/product-activation-davis-ingest-form.component';
import { ProductActivationFarmViewComponent } from './containers/product-activation-farm-view/product-activation-farm-view.component';
import { ProductActivationFarmViewFormComponent } from './containers/product-activation-farm-view/product-activation-farm-view-form/product-activation-farm-view-form.component';
import { ProductActivationIscoutMobileComponent } from './containers/product-activation-iscout-mobile/product-activation-iscout-mobile.component';
import { ProductActivationIscoutMobileFormComponent } from './containers/product-activation-iscout-mobile/product-activation-iscout-mobile-form/product-activation-iscout-mobile-form.component';
import { ProductActivationService } from './effects/product-activation.service';
import { SharedModule } from '../../shared/shared.module';
import { GoogleMapLibraries } from '../../shared/constants';
import { environment } from '../../../assets/themes/default/configuration';
import { reducers } from './reducers';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    StoreModule.forFeature('productActivation', reducers),
    EffectsModule.forFeature([ProductActivationService]),
    AgmCoreModule.forRoot({
      apiKey: environment.gMapKey,
      libraries: GoogleMapLibraries
    }),
    ProductActivationRoutingModule
  ],
  declarations: [
    ProductActivationHeaderComponent,
    ProductActivationLicenseAlertComponent,
    ProductActivationComponent,
    ProductActivationFormComponent,
    ProductActivationVwsComponent,
    ProductActivationVwsFormComponent,
    ProductActivationDiseaseModelComponent,
    ProductActivationDiseaseModelFormComponent,
    ProductActivationForecastComponent,
    ProductActivationForecastFormComponent,
    ProductActivationServiceLicenseComponent,
    ProductActivationServiceLicenseFormComponent,
    ProductActivationDavisIngestComponent,
    ProductActivationDavisIngestFormComponent,
    ProductActivationFarmViewComponent,
    ProductActivationFarmViewFormComponent,
    ProductActivationIscoutMobileComponent,
    ProductActivationIscoutMobileFormComponent,
  ],
  exports: [
    ProductActivationVwsComponent,
    ProductActivationVwsFormComponent,
    ProductActivationDiseaseModelComponent,
    ProductActivationDiseaseModelFormComponent,
    ProductActivationForecastComponent,
    ProductActivationForecastFormComponent,
    ProductActivationServiceLicenseComponent,
    ProductActivationServiceLicenseFormComponent,
    ProductActivationDavisIngestComponent,
    ProductActivationDavisIngestFormComponent,
    ProductActivationFarmViewComponent,
    ProductActivationFarmViewFormComponent,
    ProductActivationIscoutMobileComponent,
    ProductActivationIscoutMobileFormComponent,
  ]
})
export class ProductActivationModule { }
