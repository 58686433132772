import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProductActivation from './product-activation';
import {
  IAvailableUserStation,
  IProductActivationState,
  IProductConfiguration, IProductConfigurationItem, IValidLicenseMap
} from '../models/product-activation.models';
import { IDisease } from '../../../core/models/diseases.models';

interface IState {
  productActivation: IProductActivationState;
}

export const reducers = {
  productActivation: fromProductActivation.reducer
};

export const productActivationSelector = createFeatureSelector<IState>('productActivation');

export const selectProductConfiguration = createSelector(
  productActivationSelector,
  (state: IState): IProductConfiguration => state.productActivation.productConfiguration
);

export const selectProductConfigurationItem = createSelector(
  productActivationSelector,
  (state: IState): IProductConfigurationItem => state.productActivation.productConfigurationItem
);

export const selectAvailableDiseaseModels = createSelector(
  productActivationSelector,
  (state: IState): IDisease[] => state.productActivation.availableDiseaseModels
);

export const selectAvailableUserStations = createSelector(
  productActivationSelector,
  (state: IState): IAvailableUserStation[] => state.productActivation.availableUserStations
);

export const selectAvailableServiceLicenseStations = createSelector(
  productActivationSelector,
  (state: IState): IAvailableUserStation[] => state.productActivation.availableServiceLicenseStations
);

export const selectValidLicenses = createSelector(
  productActivationSelector,
  (state: IState): IValidLicenseMap => state.productActivation.validLicenses
);
