import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.scss']
})
export class CustomButtonComponent {
  @Input()
  public content                  : string = '';
  @Input()
  public color                    : string = 'blue';
  @Input()
  public width                    : string = 'auto';
  @Input()
  public caret                    : boolean = false;
  @Input()
  public radius                   : string = '';
  @Input()
  public shadow                   : boolean = true;
  @Input()
  public disabled                 : boolean = false;
  @Input()
  public hidden                   : boolean = false;
  @Input()
  public customCSS                : {[klass: string]: any; };
  @Input()
  public tooltip                  : string = null;
  @Input()
  public type                     : string = null;
}
