import { environment } from '../../../../assets/themes/default/configuration';
import { JDRelationTypes } from '../constants/my-john-deere';
import { IState } from '../models';

export const uri2path = (uri: string) => uri.slice(environment.myJohnDeereUrl.length);

export const getPathFromCatalog = (rel: JDRelationTypes, state: IState) => {
  const relLink = state.myJohnDeere.catalog ? state.myJohnDeere.catalog.links.find(link => link.rel === rel) : null;
  return relLink ? uri2path(relLink.uri) : '';
};

export const getPathFromCurrentUser = (rel: JDRelationTypes, state: IState) => {
  const relLink = state.myJohnDeere.currentUser ? state.myJohnDeere.currentUser.links.find(link => link.rel === rel) : null;
  return relLink ? uri2path(relLink.uri) : '';
};
