<div class="header" [ngClass]="color">
  <div class="block-header">
    <h2>{{ title | translate }}</h2>

    <div class="actions">
      <a *ngIf="isDraggable"><i  class="zmdi zmdi-arrows handle"></i></a>
      <a (click)="changeEditMode()" *ngIf="isEditable">
        <i class="him-icon zmdi zmdi-more-vert"></i>
      </a>
      <a *ngIf="isClose"
         (click)="remove.emit()"><i class="zmdi zmdi-close"></i></a>
    </div>
  </div>
  <small class="widget-description" *ngIf="description">{{ description | translate }}</small>
</div>
