import { IActionWithPayload, IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { IHydroponicsState } from '../models/models';
import { IStationSettingsPeriod } from '../../../shared/interfaces';
import { ITree } from '../../../services/tree/models';
import { Options } from 'highcharts';

export const enum ActionTypes {
  SET_SETTINGS = '[Hydroponics] SET_SETTINGS',
  SET_PERIOD = '[Hydroponics] SET_PERIOD',
  SET_TREE = '[Hydroponics] SET_TREE',
  SET_DRAINAGE_VISIBILITY = '[Hydroponics] SET_DRAINAGE_VISIBILITY',
  SET_WIN_VISIBILITY = '[Hydroponics] SET_WIN_VISIBILITY',
  SET_WOUT_VISIBILITY = '[Hydroponics] SET_WOUT_VISIBILITY',
  SET_WEIGHT_VISIBILITY = '[Hydroponics] SET_WEIGHT_VISIBILITY',
  SET_HYDROPONICS_LOADING = '[Hydroponics] SET_HYDROPONICS_LOADING',
  SET_HYDROPONICS_ERROR = '[Hydroponics] SET_HYDROPONICS_ERROR',
  SET_HYDROPONICS_CHART_ACTIVE = '[Hydroponics] SET_HYDROPONICS_CHART_ACTIVE',
  SET_HYDROPONICS_TABLE_ACTIVE = '[Hydroponics] SET_HYDROPONICS_TABLE_ACTIVE',
  SET_HYDROPONICS_CHARTS = '[Hydroponics] SET_HYDROPONICS_CHARTS',
}

export function setSettings(settings: IHydroponicsState): IAction {
  return { type: ActionTypes.SET_SETTINGS, payload: settings };
}

export function setPeriod(period: IStationSettingsPeriod): IAction {
  return { type: ActionTypes.SET_PERIOD, payload: period };
}

export function setTree(tree: Array<ITree>): IAction {
  return { type: ActionTypes.SET_TREE, payload: tree };
}

export function setHydroponicsLoading(isLoading: boolean): IActionWithPayload {
  return {type: ActionTypes.SET_HYDROPONICS_LOADING, payload: isLoading};
}

export function setHydroponicsError(isError: boolean): IActionWithPayload {
  return {type: ActionTypes.SET_HYDROPONICS_ERROR, payload: isError};
}

export function setHydroponicsChartActive(isActive: boolean): IActionWithPayload {
  return { type: ActionTypes.SET_HYDROPONICS_CHART_ACTIVE, payload: isActive };
}

export function setHydroponicsTableActive(isActive: boolean): IActionWithPayload {
  return { type: ActionTypes.SET_HYDROPONICS_TABLE_ACTIVE, payload: isActive };
}

export function setHydroponicsCharts(payload: Array<Options>): IAction {
  return { type: ActionTypes.SET_HYDROPONICS_CHARTS, payload: payload };
}

export function setDrainageVisibility(visible: boolean): IAction {
  return { type: ActionTypes.SET_DRAINAGE_VISIBILITY, payload: visible };
}

export function setWinVisibility(visible: boolean): IAction {
  return { type: ActionTypes.SET_WIN_VISIBILITY, payload: visible };
}

export function setWoutVisibility(visible: boolean): IAction {
  return { type: ActionTypes.SET_WOUT_VISIBILITY, payload: visible };
}

export function setWeightVisibility(visible: boolean): IAction {
  return { type: ActionTypes.SET_WEIGHT_VISIBILITY, payload: visible };
}

