import { Pipe, PipeTransform } from '@angular/core';
import { filterManualDayEvents } from '../../utils/manual-day-events/day-events-manual-util';

@Pipe({
  name: 'dayManualEventsPipe'
})
export class DayManualEventsPipePipe implements PipeTransform {

  public transform(events: Array<any>): Array<any> {
    return Array.isArray(events) ? filterManualDayEvents(events) : [];
  }

}
