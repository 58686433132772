<div class="hydroponics-content__header">
  <app-hydroponics-header (openModal)="openModal()"></app-hydroponics-header>
  <app-hydroponics-toolbar ></app-hydroponics-toolbar>
</div>
<div class="hydroponics-content__body">
  <app-hydroponics-form></app-hydroponics-form>
</div>

<app-info-modal [modalId]="HYDROPONICS_HELP_MODAL_ID" [header]="'Hydroponics' | translate">
  <app-hydroponics-help-modal></app-hydroponics-help-modal>
</app-info-modal>

