<section>
  <div [@cardAnimation] class="app-xarvio">
    <app-form-layout>
      <app-card-header
        [header]="'Xarvio integration'"
        [description]="'Enable or revoke Xarvio integration'"
        helpButton="true"
        (openModal)="openModal()">
      </app-card-header>

      <div class="xarvio">
        <div class="xarvio-form" *ngIf="stationSelectionVisible">
          <div class="row">
            <div class="col-md-5">
              <label>
                {{ 'Sharing key' | translate }}
                <i [ngClass]="sharingKeyInputStyle"
                   (click)="toggleSharingKeyVisibility()">
                </i>
              </label>
              <input #sharingKey
                     readonly
                     class="key"
                     [type]="xarvioSharingKeyInputType"
                     [value]="xarvioSharingKey"
                     (click)="toClipboard(sharingKey, 'Sharing')">
            </div>
          </div>

          <br />
        </div>

        <hr />

        <div class="row">
          <div class="col-md-12 app-xarvio__submit" *ngIf="stationSelectionVisible">
            <app-custom-button content="Revoke access" (click)="disableSync()" [color]="'red'"></app-custom-button>
          </div>

          <div class="col-md-12 app-xarvio__submit" *ngIf="!stationSelectionVisible">
            <app-custom-button content="Grant access" (click)="enableSync()" [color]="'red'"></app-custom-button>
          </div>
        </div>
      </div>
    </app-form-layout>
  </div>

  <div [@cardAnimation] class="app-xarvio" *ngIf="stationSelectionVisible">
    <app-form-layout>
      <app-card-header
        helpButton="true"
        [header]="'Xarvio device sync'"
        [description]="'Connect PI devices to Xarvio'"
        (openModal)="openModal()">
      </app-card-header>

      <div *ngIf="stationsPageItems$ | async as stationsPageItem">
        <div class="row">
          <div class="col-md-12">
            <table class="table">
              <thead>
              <tr>
                <th>{{ 'Station ID' | translate }}</th>
                <th>{{ 'Station name' | translate }}</th>
                <th>{{ 'Device type' | translate }}</th>
                <th>{{ 'Last data' | translate }}</th>
                <th>{{ 'Access rights' | translate }}</th>
                <th>{{ 'Connect to Xarvio' | translate }}</th>
              </tr>
              </thead>

              <tbody>
              <tr *ngFor="let item of stationsPageItem" [class.disabled]="item.station.rights === 'r'">
                <td><span>{{ item.station.name.original }}</span></td>
                <td><span>{{ item.station.name.custom }}</span></td>
                <td><span>{{ item.station.info.device_name }}</span></td>
                <td><span>{{ item.station.dates.max_date }}</span></td>
                <td><span>{{ item.station.rights }}</span></td>
                <td>
                  <app-yes-no-toggle
                    [ngModel]="hasAsset(item)"
                    (change)="stationConnectToggled.next({ station: item.station, add: $event })"
                    noTab="true"></app-yes-no-toggle>
                </td>
              </tr>
              </tbody>
            </table>

            <pagination class="xarvio-station-pagination"
                        previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                        *ngIf="stations$ | async as stations;"
                        [totalItems]="stations.length"
                        [itemsPerPage]="STATIONS_PAGE_SIZE"
                        [maxSize]="10"
                        [boundaryLinks]="stations.length / STATIONS_PAGE_SIZE > 10 ? true : false"
                        (pageChanged)="stationsPageChanged.next($event.page - 1)"></pagination>
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-md-12 app-xarvio__submit">
            <app-custom-button [content]="'Synchronize'" [color]="'red'" (click)="syncStations()"></app-custom-button>
          </div>
        </div>
      </div>
    </app-form-layout>
  </div>

  <app-info-modal [modalId]="modalId" [header]="'Xarvio integration' | translate">
    <app-xarvio-modal></app-xarvio-modal>
  </app-info-modal>
</section>
