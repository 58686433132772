import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { CropzoneNameActionTypes } from '../actions/cropzone-name';

export interface ICropzoneNameState {
  cropzone: any;
  source: { value: string, content: string };
}

const initialState: ICropzoneNameState = {
  cropzone: {
    name: '',
    crop_name: '',
    from: '',
    to: '',
    field: {
      id: '',
      name: ''
    },
    farm: {
      id: '',
      name: ''
    },
    id: '',
    year: ''
  },
  source: {
    value: '',
    content: ''
  }
};

export function reducer(state: ICropzoneNameState = initialState, action: IActionWithPayload): ICropzoneNameState {
  switch (action.type) {
    case CropzoneNameActionTypes.SET_CROPZONE_NAME: {
      return {
        ...state,
        cropzone: action.payload
      };
    }
    case CropzoneNameActionTypes.SET_SOURCE: {
      return {
        ...state,
        source: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
