import { catchError, finalize, mergeMap } from 'rxjs/operators';
import { Inject, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, throwError } from 'rxjs';
import { LanguageService } from '../translation/language.service';
import { environmentToken } from '../../../environments/environment';
import { IEnvironment } from '../../../environments/interfaces/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private auth: AuthService;
  private environment: IEnvironment;
  private languageService: LanguageService;
  private isRefreshingToken: boolean = false;

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    private inj: Injector) {
      this.environment = environment;
  }


  // Add token to request
  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {

    const standard = 'application/json';
    const header = request.headers.get('Content-type');

    const headers = {
      'Authorization': 'Bearer ' + token,
      'Accept': 'application/json',
      'accept-language': this.language
    };

    if (!(request.body instanceof FormData)) {
      headers['Content-type'] = header || standard;
    }

    return request.clone({ setHeaders: headers });
  }

  private addDebugParameter(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      url: `${request.url}${request.url.includes('?') ? '&XDEBUG_SESSION_START=weiz' : '?XDEBUG_SESSION_START=weiz'}`
    });
  }

  private get language(): string {
    return this.languageService.languageFromStorage || navigator.language.substring(0, 2);
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.environment.production && this.environment.debugPHP && request.url.includes('.metos.')) {
      request = this.addDebugParameter(request);
    }

    // don't intercept requests that go to the OAuth server
    // handle the request directly
    // password-update make a token request in the auth.service
    if (
      !request.url.includes('myjohndeere') &&
      (request.url.includes('oauth') ||
        request.url.includes('assets') ||
        request.url.includes('password-reset') ||
        request.url.includes('password-update') ||
        request.url.includes('user/web/register') ||
        request.url.includes('dev/user/activate-account') ||
        request.url.includes('dev/user/close-account') ||
        request.url.includes('https://maps.googleapis.com'))
    ) {
      return next.handle(request);
    }

    // because of Cyclic Dependency injection we cannot instantiate the AuthService in the constructor
    // https://github.com/angular/angular/issues/18224#issuecomment-316957213

    this.auth = this.inj.get(AuthService);
    this.languageService = this.inj.get(LanguageService);

    if (this.auth.hasAccessToken()) {
      // attach the Authorization header to all API calls
      const accessToken = this.auth.getAccessToken();
      return next.handle(this.addToken(request, accessToken)).pipe(catchError(error => {
        if (error instanceof HttpErrorResponse && (<HttpErrorResponse>error).status === 401) {
          return this.tryRefresh(request, next);
        }
        return throwError(error);
      }));
    } else {

      // if token isn't present or is expired try to refresh it
      return this.tryRefresh(request, next);
    }
  }

  private tryRefresh(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | Observable<never> {
    if (!this.isRefreshingToken && this.auth.refreshToken()) {
      this.isRefreshingToken = true;

      return this.auth.refreshToken().pipe(
        mergeMap(newToken => {
          if (newToken) {
            return next.handle(this.addToken(request, this.auth.getAccessToken()));
          }

          // If we don't get a new token, we are in trouble so logout.
          return this.logoutUser();
        }),
        catchError(error => {
          // If there is an exception calling 'refreshToken', bad news so logout.
          return this.logoutUser();
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        }));
    } else {
      this.auth.logoutUser();
      window.location.href = '/auth/login';
    }
  }

  private logoutUser(): Observable<never> {
    this.auth.logoutUser();
    return throwError('');
  }
}
