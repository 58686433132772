import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ICropZone } from '../../core/models/cropzones';
import { selectSelectedCropZone } from '../../core/reducers';
import * as fromSelectedCropzone from '../../core/reducers/selectedCropZone';
import { stationDataContentAnimations } from '../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../core/services/left-components-toggler/left-components-toggler.service';
import { ITreeStructure } from '../../services/tree/models';
import { TreeService } from '../../services/tree/tree.service';
import { IAccount } from './../../core/reducers/account';
import { selectAvailableLicenses } from './../../core/reducers/index';

@Component({
  selector: 'app-irrimet-config',
  templateUrl: './irrimet-config.component.html',
  styleUrls: ['./irrimet-config.component.scss'],
  animations: stationDataContentAnimations
})
export class IrrimetConfigComponent implements OnInit, OnDestroy {

  public state$: Observable<string>;
  public tree$: Observable<ITreeStructure> = this.treeService.getCropzoneSettingsTreeStructure();
  public selectedCropzone: ICropZone;
  public destroy$ = new Subject<boolean>();

  constructor(
    private treeService: TreeService,
    private leftComponentsToggler: LeftComponentsTogglerService,
    private selectedStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private accountStore: Store<IAccount>
  ) {

  }

  public ngOnInit(): void {
    this.state$ = this.leftComponentsToggler.getStationDataContentState();

    const availableLicenses$ = this.accountStore.pipe(
      select(selectAvailableLicenses),
      filter(licenses => !!licenses),
      takeUntil(this.destroy$)
    );

    const selectedCropzone$ = this.selectedStore.pipe(
      filter((cropzone) => !!cropzone),
      select(selectSelectedCropZone),
      takeUntil(this.destroy$),
      tap((cropzone) => this.selectedCropzone = cropzone)
    );

    combineLatest([
      availableLicenses$,
      selectedCropzone$
    ]).pipe(takeUntil(this.destroy$))
    .subscribe(([licenses, cropzone]) => {
      let licenseFromCropzone;
      if (cropzone.product_key) {
        const now = new Date();

        licenses.forEach((license) => {
          if (license.product_item.key === cropzone.product_key) {
            if (now > moment(license.start_date).toDate() && now < moment(license.end_date).toDate()) {
              licenseFromCropzone = license;
            }
          }
        });
      }

      if (licenseFromCropzone) {
        this.tree$ = this.treeService.getCropzoneSettingsTreeStructure(licenseFromCropzone);
      } else {
        this.tree$ = this.treeService.getCropzoneSettingsTreeStructure();
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
