import {IActionWithPayload} from '../models/actionWithPayload';
import {IStation} from '../models/stations';
import {INavigationAccess, IStationBasicInfo} from '../models/navigation-station';
import {NavigationStationActionTypes} from '../actions/navigation-station';
import {deepClone} from '../../shared/utils/deepClone';
import {defaultNavigation} from '../constants/default-navigation';

export interface INavigationStationState {
  station: IStation;
  stationBasicInfo: IStationBasicInfo;
  navigationAccess: INavigationAccess;
  notFound: boolean;
}

const initialState: INavigationStationState = deepClone(defaultNavigation);

export function reducer(state: INavigationStationState = initialState, action: IActionWithPayload ): INavigationStationState {
  switch (action.type) {
    case NavigationStationActionTypes.SET_NAVIGATION_STATION: {
      return {
        ...state,
        ...action.payload
      };
    }
    case NavigationStationActionTypes.SET_NAVIGATION_STATION_BASE_NAME: {
      return {
        ...state,
        station: {
          ...state.station,
          name: {
            ...state.station.name,
            custom: action.payload
          }
        },
        stationBasicInfo: {
          ...state.stationBasicInfo,
          customName: action.payload
        }
      };
    }
    // TODO: changing the state triggers other actions to load
    case NavigationStationActionTypes.SET_TRACKER_SETTINGS: {
      return {
        ...state,
        station: {
          ...state.station,
          config: {
            ...state.station.config,
            trip_delimiter: action.payload
          }
        },
      };
    }
    default: {
      return state;
    }
  }
}
