<aside [class.active]="isActive"
       class="search-widget-container"
       (mouseenter)="hover$.next(true)"
       (mouseleave)="hover$.next(false)">
  <div class="search-container">
    <app-search-widget-input [placeholder]="searchPlaceholder"
                             [formControl]="searchTermControl"
                             (focusin)="focus$.next(true)"
                             (focusout)="focus$.next(false)">
    </app-search-widget-input>
    <div class="order-controls-container">
      <div class="list-order-buttons" role="group">
        <app-search-widget-order-button *ngFor="let orderSettingsItem of orderSettings"
                                        (applySort)="setOrder($event)"
                                        [content]="orderSettingsItem.name"
                                        [direction]="getDirection(orderSettingsItem)"
                                        [orderField]="orderSettingsItem.orderField"></app-search-widget-order-button>
      </div>
    </div>
  </div>

  <div class="search-result-list-container">
    <div class="search-result-list">
      <app-search-widget-list-item *ngFor="let item of handledItems"
                                   [item]="item"
                                   [isSelected]="isSelected(item)"
                                   [stations]="stations"
                                   (selectItem)="selectItem($event)"></app-search-widget-list-item>
    </div>
  </div>
</aside>
