import { Component, OnInit } from '@angular/core';
import { HELP_TEXT } from '../../constants/constats';

@Component({
  selector: 'app-hydroponics-help-modal',
  templateUrl: './hydroponics-help-modal.component.html',
  styleUrls: ['./hydroponics-help-modal.component.scss']
})
export class HydroponicsHelpModalComponent implements OnInit {
  public help: string;

  constructor() {
    this.help = '';
    HELP_TEXT.forEach(text => {
      this.help += text;
    });
  }

  public ngOnInit(): void {
  }
}

