import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Highcharts} from 'angular-highcharts';

@Injectable({
  providedIn: 'root'
})

export class ChartTranslationsService {
  public months:             string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public translatedMonths:   string[] = [];
  public days:             string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  public translatedDays:   string[] = [];

  constructor(private translationService: TranslateService) { }

  public translateShortMonths(): void {
    this.translatedMonths = [];
    this.months.forEach(month => {
      this.translationService.get(month).subscribe(translatedMonth => {
        this.translatedMonths.push(translatedMonth);
      });
    });

    Highcharts.setOptions({
      lang: {
        shortMonths: this.translatedMonths,
      }
    });
  }

  public translateWeekdays(): void {
    this.translatedDays = [];
    this.days.forEach(day => {
      this.translationService.get(day).subscribe(translatedDay => {
        this.translatedDays.push(translatedDay);
      });
    });

    Highcharts.setOptions({
      lang: {
        weekdays: this.translatedDays,
      }
    });
  }
}
