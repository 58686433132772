import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { Action } from '@ngrx/store';
import { IDvAssets, IDvLicense, IDvSensorMap, IDvSettings } from '../models/davis';

export const enum ActionTypes {
  LOAD_SETTINGS = '[Davis] LOAD_SETTINGS',
  SET_SETTINGS = '[Davis] SET_SETTINGS',
  SAVE_SETTINGS = '[Davis] SAVE_SETTINGS',
  DELETE_SETTINGS = '[Davis] DELETE_SETTINGS',
  GET_ASSETS = '[Davis] GET_ASSETS',
  SET_ASSETS = '[Davis] SET_ASSETS',
  ACTIVATE_STATION = '[Davis] ACTIVATE_STATION',
  UPDATE_SYNC = '[Davis] UPDATE_SYNC',
  LOAD_LICENSES = '[Davis] LOAD_LICENSES',
  SET_LICENSES = '[Davis] SET_LICENSES',
  LOAD_SENSOR_MAP = '[Davis] LOAD_SENSOR_MAP',
  SET_SENSOR_MAP = '[Davis] SET_SENSOR_MAP',
  SAVE_SENSOR_MAP = '[Davis] SAVE_SENSOR_MAP'
}

export function setSettings(payload: IDvSettings): IAction {
  return { type: ActionTypes.SET_SETTINGS, payload: payload };
}

export function loadSettings(): Action {
  return { type: ActionTypes.LOAD_SETTINGS };
}

export function saveSettings(payload: IDvSettings): IAction {
  return { type: ActionTypes.SAVE_SETTINGS, payload: payload };
}

export function deleteSettings(): Action {
  return { type: ActionTypes.DELETE_SETTINGS };
}

export function getAssets(): Action {
  return { type: ActionTypes.GET_ASSETS };
}

export function setAssets(payload: IDvAssets): IAction {
  return { type: ActionTypes.SET_ASSETS, payload: payload };
}

export function updateSync(davisStationID: number, SyncStatus: boolean): IAction {
  return { type: ActionTypes.UPDATE_SYNC, payload: { SyncStatus, davisStationID }};
}

export function activateStation(davisStationID: number, product_key: string): IAction {
  return { type: ActionTypes.ACTIVATE_STATION, payload: { davisStationID, product_key }};
}

export function loadLicenses(): Action {
  return { type: ActionTypes.LOAD_LICENSES };
}

export function setLicenses(licenses: IDvLicense[]): IAction {
  return { type: ActionTypes.SET_LICENSES, payload: licenses };
}

export function loadSensorMap(davisStationID: number): IAction {
  return { type: ActionTypes.LOAD_SENSOR_MAP, payload: { davisStationID }};
}

export function setSensorMap(sensorMap: IDvSensorMap[]): IAction {
  return { type: ActionTypes.SET_SENSOR_MAP, payload: sensorMap };
}

export function saveSensorMap(sensorMap, davisStationID): IAction {
  return { type: ActionTypes.SAVE_SENSOR_MAP, payload: { sensorMap, davisStationID }};
}
