import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IStation } from '../../../../../core/models/stations';
import { ILoggingTransferDevice } from '../../../models/station-config.models';
import { LoggingTransferGroups, ledFlashOptions } from '../../../constants/constants';
import { IOptions } from '../../../../../shared/interfaces';
import * as deepEqual from 'deep-equal';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormValidationService } from '../../../services/form-validation.service';
import { solAntennaDeviceIDs } from '../../../../../shared/constants';

@Component({
  selector: 'app-logging-settings-two',
  templateUrl: './logging-settings-two.component.html',
  styleUrls: ['./logging-settings-two.component.scss']
})
export class LoggingSettingsTwoComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public station: IStation;
  @Input()
  public frostEnabled: boolean;
  @Input()
  public state: any;

  @Output()
  private changeMeasurementInterval: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  private changeLoggingInterval: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  private changeFixedTransferInterval: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  private changeLEDFlash: EventEmitter<number> = new EventEmitter<number>();

  public form: FormGroup;
  private oldFormValues: any;
  public deviceGroup: ILoggingTransferDevice;

  public measurementOptions: Array<IOptions>;
  public loggingOptions: Array<IOptions>;
  public fixedTransferOptions: Array<IOptions>;
  public ledFlashOptions: Array<IOptions>;
  public isSolAntenna: boolean = false;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private fb: FormBuilder,
    private formValidationService: FormValidationService
  ) { }

  public ngOnInit(): void {
  }

  public get measurementIntervalControl(): AbstractControl {
    return this.form.get('measurementInterval');
  }

  public get loggingIntervalControl(): AbstractControl {
    return this.form.get('loggingInterval');
  }

  public get fixedTransferControl(): AbstractControl {
    return this.form.get('fixedTransferInterval');
  }

  public get ledFlashControl(): AbstractControl {
    return this.form.get('ledFlash');
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.deviceGroup = LoggingTransferGroups.filter((group) => group.deviceIDs.includes(this.station.info.device_id))[0];
    this.isSolAntenna = solAntennaDeviceIDs.includes(this.station.info.device_id);

    if (this.station && this.state) {
      const config = this.state.config;
      const deviceId: number = this.station.info.device_id;
      this.setMeasuringOptions();
      this.setLoggingOptions();
      this.setTransferOptions();
      this.ledFlashOptions = ledFlashOptions;

      const isFixedTransferInterval: boolean = [6, 7, 8, 28].includes(deviceId);
      this.form = this.fb.group({
        ledFlash: [config.flash ? config.flash : 0, []],
        measurementInterval: [config.measuring_interval ? config.measuring_interval.toString() : null, []],
        loggingInterval: [
          this.station.info.device_id === 78 ? config.transfer_interval?.toString()
          : config.logging_interval ? config.logging_interval?.toString()
          : null, []
        ],
        fixedTransferInterval: [
          isFixedTransferInterval ? config.fixed_transfer_interval.toString()
          : config.transfer_interval ? config.transfer_interval.toString()
          : null, []
        ]
      });
      this.listenToChanges();
      this.setFormValidators();
    }

    if (changes.frostEnabled) {
      this.setMeasuringOptions();
      this.setLoggingOptions();
      this.setTransferOptions();

      if (changes.frostEnabled.currentValue) {
        this.oldFormValues = this.form.value;
        if (this.deviceGroup.frostDefaults) {
          this.measurementIntervalControl.setValue(this.deviceGroup.frostDefaults[0]);
          this.loggingIntervalControl.setValue(this.deviceGroup.frostDefaults[1]);
          this.fixedTransferControl.setValue(this.deviceGroup.frostDefaults[2]);
        }
      } else if (!changes.frostEnabled.currentValue && changes.frostEnabled.currentValue !== undefined) {
        if (this.oldFormValues) {
          this.measurementIntervalControl.setValue(this.oldFormValues.measurementInterval.toString());
          this.loggingIntervalControl.setValue(this.oldFormValues.loggingInterval.toString());
          this.fixedTransferControl.setValue(this.oldFormValues.fixedTransferInterval.toString());
        }
      }
    }
  }

  public getFormData(): any {
    return this.form.value;
  }

  private listenToChanges(): void {
    this.measurementIntervalControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged((a, b) => a === b)
    ).subscribe((value: number) => {
      this.changeMeasurementInterval.emit(+value);
    });

    this.loggingIntervalControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged((a, b) => a === b)
    ).subscribe((value: number) => {
      this.changeLoggingInterval.emit(+value);
      if (this.station.info.device_id === 78) {
        this.changeFixedTransferInterval.emit(+value);
      }
    });

    this.fixedTransferControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged((a, b) => a === b)
    ).subscribe((value: number) => {
      const valueToEmit: number = value ? value : 0;
      this.changeFixedTransferInterval.emit(+valueToEmit);
    });

    this.ledFlashControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged((a, b) => a === b)
    ).subscribe((value: number) => {
      this.changeLEDFlash.emit(+value);
    });
  }

  public setDefaultValues(): void {
    this.measurementIntervalControl.setValue(this.deviceGroup.defaults[0] ? this.deviceGroup.defaults[0] : null);
    this.loggingIntervalControl.setValue(this.deviceGroup.defaults[1] ? this.deviceGroup.defaults[1] : null);
    this.fixedTransferControl.setValue(this.deviceGroup.defaults[2] ? this.deviceGroup.defaults[2] : null);
    this.ledFlashControl.setValue('0');
  }

  private setFormValidators(): void {
    this.form.clearValidators();
    this.measurementIntervalControl.clearValidators();
    this.loggingIntervalControl.clearValidators();
    this.fixedTransferControl.clearValidators();

    this.deviceGroup = LoggingTransferGroups.filter((group) => group.deviceIDs.includes(this.station.info.device_id))[0];
    if (this.station.info.device_id === 78) {
      this.measurementIntervalControl.setValidators(Validators.required);
      this.loggingIntervalControl.setValidators(Validators.required);
      this.form.setValidators(this.formValidationService.solAntennaValidation);
    } else {
      if (this.deviceGroup.measuring && this.deviceGroup.logging && this.deviceGroup.fixedTransfer) {
        this.measurementIntervalControl.setValidators(Validators.required);
        this.loggingIntervalControl.setValidators(Validators.required);
        this.form.setValidators(this.formValidationService.intervalFormValidation);
      }
      if (!this.deviceGroup.measuring && this.deviceGroup.logging && !this.deviceGroup.fixedTransfer) {
        this.loggingIntervalControl.setValidators(Validators.required);
        this.form.setValidators(this.formValidationService.loggingValidation);
      }
      if (!this.deviceGroup.measuring && this.deviceGroup.logging && this.deviceGroup.fixedTransfer) {
        this.loggingIntervalControl.setValidators(Validators.required);
        this.form.setValidators(this.formValidationService.loggingFixedTransferValidation);
      }
      if (this.deviceGroup.measuring && this.deviceGroup.logging && !this.deviceGroup.fixedTransfer) {
        this.measurementIntervalControl.setValidators(Validators.required);
        this.loggingIntervalControl.setValidators(Validators.required);
        this.form.setValidators(this.formValidationService.measuringLoggingValidation);
      }
    }
  }

  private setMeasuringOptions(): void {
    if (!this.frostEnabled) {
      this.measurementOptions = this.deviceGroup.options[0];
    } else {
      this.measurementOptions = [{ value: this.deviceGroup.frostDefaults[0], content: this.deviceGroup.frostDefaults[0] + ' minutes' }];
    }
  }

  private setLoggingOptions(): void {
    if (!this.frostEnabled) {
      this.loggingOptions = this.deviceGroup.options[1];
    } else {
      this.loggingOptions = [{ value: this.deviceGroup.frostDefaults[1], content: this.deviceGroup.frostDefaults[1] + ' minutes' }];
    }
  }

  private setTransferOptions(): void {
    if (!this.frostEnabled) {
      this.fixedTransferOptions = this.deviceGroup.options[2];
    } else {
      this.fixedTransferOptions = [{ value: this.deviceGroup.frostDefaults[2], content: this.deviceGroup.frostDefaults[2] + ' minutes' }];
    }
  }

  public checkIfMeasurementError(): boolean {
    return this.form.errors && (
      this.form.errors.noValidMeasuringSelection
      || this.form.errors.measurementNotFilled
    );
  }

  public checkIfLoggingError(): boolean {
    return this.form.errors && (
      this.form.errors.measurementLargerThanLogging
      || this.form.errors.loggingIsNotAMultiplier
      || this.form.errors.noValidLoggingSelection
    );
  }

  public checkIfLoggingTransferError(): boolean {
    return this.form.errors && (
      this.form.errors.measuringLargerThanLoggingTransfer
      || this.form.errors.loggingTransferNotAMultiplier
      || this.form.errors.noValidLoggingTransferSelection
    );
  }

  public checkIfTransferError(): boolean {
    return this.form.errors && (
      this.form.errors.loggingLargerThanTransfer
      || this.form.errors.transferIsNotAMultiplier
      || this.form.errors.noValidTransferSelection
    );
  }

  public showMeasuring(): boolean {
    return this.measurementOptions.some((option) => +option.value === +this.measurementIntervalControl.value);
  }

  public showLogging(): boolean {
    return this.loggingOptions.some((option) => +option.value === +this.loggingIntervalControl.value);
  }

  public showTransfer(): boolean {
    return this.fixedTransferOptions.some((option) => +option.value === +this.fixedTransferControl.value);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
