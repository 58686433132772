import { Injectable } from '@angular/core';
import { ApiCallService } from '../../../services/api/api-call.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  ActionTypes,
  getDualexSamples, getMobilabSamples,
  getSoilGuardSamples,
  setSamples
} from '../actions/station-sample.action';
import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { setNotify } from '../../../core/actions/notify';
import * as moment from 'moment';


@Injectable()
export class StationSampleEffect {

  constructor(
    private api: ApiCallService,
    private actions$: Actions
  ) {

  }


  @Effect()
  public getSoilGuardByDate = this.actions$.pipe(
    ofType(ActionTypes.GET_SOILGUARD_SAMPLES),
    switchMap<IAction, any>(({ payload: { name, period } }) =>
      this.api.getSoilGuardByDate(name, period).pipe(
        filter(({ returnCode }) => returnCode === 0),
        map(({ data }) => setSamples(data)),
        catchError(() => of(
          setSamples([]),
          setNotify('Sample data is currently unavailable')
        ))
      )
    )
  );

  @Effect()
  public getDualexByDate = this.actions$.pipe(
    ofType(ActionTypes.GET_DUALEX_SAMPLES),
    switchMap<IAction, any>(({ payload: { name, period } }) =>
      this.api.getDualexByDate(name, period).pipe(
        filter(({ returnCode }) => returnCode === 0),
        map(({ data }) => setSamples(data)),
        catchError(() => of(
          setSamples([]),
          setNotify('Sample data is currently unavailable')
        ))
      )
    )
  );

  @Effect()
  public getMobilabByDate = this.actions$.pipe(
    ofType(ActionTypes.GET_MOBILAB_SAMPLES),
    switchMap<IAction, any>(({ payload: { name, period } }) =>
      this.api.getMobilabByDate(name, period).pipe(
        filter(({ returnCode }) => returnCode === 0),
        map(({ data }) => setSamples(data)),
        catchError(() => of(
          setSamples([]),
          setNotify('Sample data is currently unavailable')
        ))
      )
    )
  );

  @Effect()
  public setSettingsSample = this.actions$.pipe(
    ofType(ActionTypes.SET_DEVICE),
    switchMap<IAction, any>(({ payload: { name, period, type } }) => {

      const dates = {
        from: moment(period.from)
          .add(period.from.getTimezoneOffset() * -1, 'minutes')
          .unix(),
        to: moment(period.to)
          .add(period.from.getTimezoneOffset() * -1, 'minutes')
          .unix()
      };

      switch (type) {
        case 'soilGuard':
          return of(getSoilGuardSamples({ period: dates, name }));
        case 'dualex':
          return of(getDualexSamples({ period: dates, name }));
        case 'mobilab':
          return of(getMobilabSamples({ period: dates, name }));
      }
    })
  );
}
