import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StationConfigComponent} from './station-config.component';
import { NgSelectModule } from '@ng-select/ng-select';

const routes: Routes = [
  { path: '', component: StationConfigComponent }
];

@NgModule({
  imports: [
    NgSelectModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule, NgSelectModule]
})
export class StationConfigRoutingModule { }
