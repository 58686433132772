import { SoilMoistureModule } from './../soil-moisture/soil-moisture.module';
import { EffectsModule } from '@ngrx/effects';
import { SoilMoistureConfigRoutingModule } from './soil-moisture-config-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { SoilMoistureConfigComponent } from './soil-moisture-config.component';
import { SoilMoistureConfigTopComponent } from './containers/soil-moisture-config-top/soil-moisture-config-top.component';
import { reducers } from './reducers';
import { SoilMoistureConfigEffectsEffects } from './effects/soil-moisture-config-effects.effects';
// tslint:disable-next-line
import { SoilMoistureConfigBottomComponent } from './containers/soil-moisture-config-bottom/soil-moisture-config-bottom/soil-moisture-config-bottom.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        SoilMoistureConfigRoutingModule,
        TranslateModule.forChild(),
        StoreModule.forFeature('soil-moisture-config', reducers),
        EffectsModule.forFeature([SoilMoistureConfigEffectsEffects]),
        SoilMoistureModule
    ],
    declarations: [
        SoilMoistureConfigComponent,
        SoilMoistureConfigTopComponent,
        SoilMoistureConfigBottomComponent,
    ],
    exports: [],
    providers: [
        SoilMoistureConfigEffectsEffects
    ],
})
export class SoilMoistureConfigModule {}
