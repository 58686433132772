import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { getProductLicenses } from '../../../actions/licenses';
import { ISelectedLicense, LicenseFetchMode } from '../../../models/licenses';
import { selectAvailableLicenses } from '../../../reducers';
import * as fromLicenses from '../../../reducers/licenses';

@Component({
  selector: 'app-product-license',
  templateUrl: './product-license.component.html',
  styleUrls: ['./product-license.component.scss']
})
export class ProductLicenseComponent implements OnInit, OnDestroy {

  @Input()
  public licenseType: string;
  @Output()
  public selectLicense = new EventEmitter<ISelectedLicense>();
  @Output()
  public cancel = new EventEmitter<void>();
  private destroy$: Subject<boolean> = new Subject();

  public isLoading: boolean = true;
  public availableLicenses$: Observable<any>;

  constructor(
    private licenseStore: Store<fromLicenses.IProductLicenseState>
  ) {

  }

  public ngOnInit(): void {

    this.availableLicenses$ = this.licenseStore.pipe(
      select(selectAvailableLicenses),
      map(licenses => licenses.filter(license =>
        (license.cropzones && license.amount > license.cropzones.length) ||
        (license.nm && license.amount > license.nm.length)
      ))
    );

    this.licenseStore.dispatch(getProductLicenses(this.licenseType, LicenseFetchMode.AVAILABLE));
  }

  public onSelectLicense(event: ISelectedLicense): void {
    this.selectLicense.emit(event);
  }

  public onCancel(): void {
    this.cancel.emit();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
