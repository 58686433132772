import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IGetStationCommunicationRequest, ISetStationCommunicationPayload} from '../models/station-communication.models';

export const enum StationCommunicationActionTypes {
  GET_STATION_COMMUNICATION = '[StationCommunication] GET_STATION_COMMUNICATION',
  SET_STATION_COMMUNICATION = '[StationCommunication] SET_STATION_COMMUNICATION',
  SET_STATION_COMMUNICATION_LOADING = '[StationCommunication] SET_STATION_COMMUNICATION_LOADING',
  SET_STATION_COMMUNICATION_ERROR = '[StationCommunication] SET_STATION_COMMUNICATION_ERROR',
}

export function getStationCommunication(data: IGetStationCommunicationRequest): IActionWithPayload {
  return { type: StationCommunicationActionTypes.GET_STATION_COMMUNICATION, payload: data};
}

export function setStationCommunication(data: ISetStationCommunicationPayload): IActionWithPayload {
  return { type: StationCommunicationActionTypes.SET_STATION_COMMUNICATION, payload: data};
}

export function setStationCommunicationLoading(status: boolean): IActionWithPayload {
  return { type: StationCommunicationActionTypes.SET_STATION_COMMUNICATION_LOADING, payload: status};
}

export function setStationCommunicationError(status: boolean): IActionWithPayload {
  return { type: StationCommunicationActionTypes.SET_STATION_COMMUNICATION_ERROR, payload: status};
}
