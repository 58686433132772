<div class="warnings-phone-form"
     *ngIf="form"
     [formGroup]="form">
  <h4 class="warnings-phone-form__header"><i class="fa fa-phone fa-fw"></i>{{ 'Add phone number' | translate }}</h4>
  <table class="table-condensed">
    <tr>
      <th class="col-md-2">{{ 'Phone number' | translate }}:</th>
      <td class="col-md-2">
        <app-form-input [type]="'border'"
                        [formControl]="num"
                        [size]="'small'"
                        appWarningsPhone
                        [placeholder]="'Phone (include 0043... or 0049... etc)'"></app-form-input>
      </td>
    </tr>
    <tr>
      <th class="col-md-2">{{ 'Name' | translate }}:</th>
      <td class="col-md-2"><app-form-input [type]="'border'"
                          [formControl]="name"
                          [size]="'small'"
                          [placeholder]="'Name'"></app-form-input></td>
    </tr>
    <tr>
      <td [attr.colspan]="2">
        <div class="warnings-phone-form__submit">
          <app-custom-button [content]="'Add'"
                             class="warnings-phone-form__add"
                             (click)="add()"></app-custom-button>
          <p class="alert alert-success warnings-phone-form__alert" *ngIf="isAdded">
            {{ 'The phone number was added to the list!' | translate }}
          </p>
          <p class="alert alert-warning warnings-phone-form__alert" *ngIf="isExists">
            {{ 'The phone number you are trying to add is already on the list!' | translate }}
          </p>
        </div>
      </td>
    </tr>
  </table>
</div>
