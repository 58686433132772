import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-chart-header',
  templateUrl: './widget-header.component.html',
  styleUrls: ['./widget-header.component.scss',
    '../../../../shared/common.scss']
})
export class WidgetHeaderComponent {
  @Input()
  public color          : string;
  @Input()
  public title          : string;
  @Input()
  public description    : string;
  @Input()
  public isEditable     : boolean;
  @Input()
  public isDraggable    : boolean;
  @Input()
  public isClose        : boolean;
  @Output()
  public remove         : EventEmitter<void> = new EventEmitter();
  @Output()
  private editMode      : EventEmitter<void> = new EventEmitter();

  public changeEditMode(): void {
    this.editMode.emit();
  }

}
