import { IUltrasonicDistanceSettingsState } from '../models/models';

export const initialState: IUltrasonicDistanceSettingsState = {
  dataLoading: {
    error: {
      isError             : false,
      errorMessage        : ''
    },
    isLoading             : false
  },
  sensors                 : null,
  ultrasonicSettings      : null,
  currentValue            : null
};
