import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegisterComponent } from './register.component';
import { ActivateUserGuard } from '../../directives/activate-user.guard';
import { CloseAccountGuard } from '../../directives/close-account.guard';

const routes: Routes = [
  { path: '', component: RegisterComponent },
  { path: 'activate', component: RegisterComponent, canActivate: [ActivateUserGuard] },
  { path: 'close', component: RegisterComponent, canActivate: [CloseAccountGuard] },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class RegisterRoutingModule { }
