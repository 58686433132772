import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import * as fromNavigationStation from '../../../../core/reducers/navigation-station';
import { stationDataContentAnimations } from '../../../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../../../core/services/left-components-toggler/left-components-toggler.service';
import { ITreeStructure } from '../../../../services/tree/models';
import { TreeService } from '../../../../services/tree/tree.service';
import { StationTypes } from '../../../../shared/constants';

@Component({
  selector: 'app-camera-data',
  templateUrl: './camera-data.component.html',
  styleUrls: ['./camera-data.component.scss'],
  animations: stationDataContentAnimations
})
export class CameraDataComponent implements OnInit {
  public stationCameraType: string;
  public tree$: BehaviorSubject<ITreeStructure>;
  public state$: Observable<string>;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private treeService: TreeService,
              private leftComponentsToggler: LeftComponentsTogglerService,
              private navigationStore: Store<fromNavigationStation.INavigationStationState>) {
  }

  public ngOnInit(): void {
    this.state$ = this.leftComponentsToggler.getStationDataContentState();
    this.navigationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation)
    ).subscribe((station: IStation) => {
      if (station && station.info && station.info.device_id) {
        this.tree$ = this.getTree(station.info.device_id);
      }
    });
  }

  private getTree(deviceID: number): BehaviorSubject<ITreeStructure> {
    switch (deviceID) {
      case StationTypes.get('CropViewType1'):
        this.stationCameraType = 'cropview-dual';
        return this.treeService.getCropViewTreeStructure();
      case StationTypes.get('CropViewType2'):
        this.stationCameraType = 'cropview-zoom';
        return this.treeService.getCropViewTreeStructure();
      case StationTypes.get('CropViewType3'):
        this.stationCameraType = 'cropview-panorama';
        return this.treeService.getCropViewTreeStructure();
      case StationTypes.get('IScoutType'):
      case StationTypes.get('IScoutMobileType'):
        this.stationCameraType = 'iscout';
        return this.treeService.getIScoutTreeStructure();
      default:
        return null;
    }
  }
}
