import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { ActionTypes } from '../actions';
import { ILaiState } from '../models';

const initialState: ILaiState = {
    laiStats: {},
    visibleLaiDates: {},
    visibleLaiVals: {},
    detailedLaiVal: [],
    fittingCurve: {},
    noDataError: {}
};

export function reducer(state: ILaiState = initialState, action: IActionWithPayload): ILaiState {
    switch (action.type) {
        case ActionTypes.SET_LAI_STATS:
            if (typeof action.payload.laiStats !== 'string') {
                state.laiStats[action.payload.cropZoneId] = action.payload.laiStats;

                return {
                    ...state,
                    laiStats: {
                        ...state.laiStats
                    }
                };
            } else {
                return {
                    ...state,
                    noDataError: true
                };
            }

        case ActionTypes.SET_NO_DATA_ERROR:
            state.noDataError[action.payload.cropZoneId] = action.payload.noDataError;

            return {
                ...state,
                noDataError: {
                    ...state.noDataError
                }
            };
        case ActionTypes.SET_LAI_VAL_RES_10:
            state.detailedLaiVal[action.payload.cropZoneId] = action.payload.laiVals[0];

            state.visibleLaiVals[action.payload.cropZoneId].forEach((laiVal, index) => {
                if (laiVal.date === action.payload.laiVals[0].date) {
                    state.visibleLaiVals[action.payload.cropZoneId][index] = action.payload.laiVals[0];
                }
            });

            return {
                ...state,
                visibleLaiVals: {
                    ...state.visibleLaiVals
                },
                detailedLaiVal: {
                    ...state.detailedLaiVal
                }
            };
        case ActionTypes.SET_VISIBLE_LAI_DATES:
            return {
                ...state,
                visibleLaiDates: {
                    ...action.payload
                }
            };
        case ActionTypes.ADD_VISIBLE_LAI_VAL:
            if (state.visibleLaiDates[action.payload.cropZoneId]) {
                if (!state.visibleLaiDates[action.payload.cropZoneId].some(date => date === action.payload.date)) {
                    state.visibleLaiDates[action.payload.cropZoneId].push(action.payload.date);
                }
            } else {
                state.visibleLaiDates[action.payload.cropZoneId] = [];
                state.visibleLaiDates[action.payload.cropZoneId].push(action.payload.date);
            }

            return {
                ...state,
                visibleLaiDates: {
                    ...state.visibleLaiDates
                }
            };
        case ActionTypes.SET_VISIBLE_LAI_VALS:
            if (action.payload.laiVals.length > 0) {
                let laiVals = state.visibleLaiVals[action.payload.cropzone.id];

                if (laiVals) {
                    laiVals = laiVals.concat(action.payload.laiVals);
                } else {
                    laiVals = [];
                    laiVals = action.payload.laiVals;
                }
                const temp = [];
                const fullResLaiValues = laiVals.filter(laiValue => laiValue['resolution'] === 10);

                fullResLaiValues.forEach(fullResValue => {
                    if (!temp.some(value => value.date === fullResValue.date)) {
                        temp.push(fullResValue);
                    }
                });

                laiVals.forEach(val => {
                    if (!temp.some(value => value.date === val.date)) {
                        temp.push(val);
                    }
                });

                state.visibleLaiVals[action.payload.cropzone.id] = temp;

                return {
                    ...state,
                    visibleLaiVals: {
                        ...state.visibleLaiVals
                    }
                };
            } else {
                state.visibleLaiVals[action.payload.cropzone.id] = [];
                return {
                    ...state,
                    visibleLaiVals: {
                        ...state.visibleLaiVals
                    }
                };
            }
        case ActionTypes.SET_FITTING_CURVE:
            state.fittingCurve[action.payload.cropZoneId] = action.payload.data;

            return {
                ...state,
                fittingCurve: {
                    ...state.fittingCurve
                }
            };
        case ActionTypes.DELETE_ALL_LAI:
            if (state.laiStats[action.payload]) {
                delete state.laiStats[action.payload];
            }
            if (state.visibleLaiDates[action.payload]) {
                delete state.visibleLaiDates[action.payload];
            }
            if (state.visibleLaiVals[action.payload]) {
                delete state.visibleLaiVals[action.payload];
            }
            if (state.fittingCurve[action.payload]) {
                delete state.fittingCurve[action.payload];
            }
            if (state.detailedLaiVal[action.payload]) {
                delete state.detailedLaiVal[action.payload];
            }
            if (state.noDataError[action.payload]) {
                delete state.noDataError[action.payload];
            }

            return state;
        default: {
            return state;
        }
    }
}
