import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IGetStationEventsPayload, ISetStationEventsPayload, IStationEvent} from '../models/station-events.models';

export const enum StationEventsActionTypes {
  GET_STATION_EVENTS = '[StationEvents] GET_STATION_EVENTS',
  SET_STATION_EVENTS = '[StationEvents] SET_STATION_EVENTS',
  SET_STATION_EVENTS_LOADING = '[StationEvents] SET_STATION_EVENTS_LOADING',
  SET_STATION_EVENTS_ERROR = '[StationEvents] SET_STATION_EVENTS_ERROR',
}

export function getStationEvents(data: IGetStationEventsPayload): IActionWithPayload {
  return { type: StationEventsActionTypes.GET_STATION_EVENTS, payload: data};
}

export function setStationEvents(data: ISetStationEventsPayload): IActionWithPayload {
  return { type: StationEventsActionTypes.SET_STATION_EVENTS, payload: data};
}

export function setStationEventsLoading(status: boolean): IActionWithPayload {
  return { type: StationEventsActionTypes.SET_STATION_EVENTS_LOADING, payload: status};
}

export function setStationEventsError(status: boolean): IActionWithPayload {
  return { type: StationEventsActionTypes.SET_STATION_EVENTS_ERROR, payload: status};
}
