import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ITreeStructure } from '../../../../services/tree/models';
import { TreeService } from '../../../../services/tree/tree.service';
import { IStation } from '../../../../core/models/stations';
import { select, Store } from '@ngrx/store';
import { selectNavigationStation } from '../../../../core/reducers';
import { READ_PERMISSION } from '../../../../shared/constants';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';


@Component({
  selector: 'app-ultrasonic-distance-settings',
  templateUrl: './ultrasonic-distance-settings.component.html',
  styleUrls: ['./ultrasonic-distance-settings.component.scss']
})
export class UltrasonicDistanceSettingsComponent implements OnInit, OnDestroy {

  public station                    : IStation;
  public tree$                      : Observable<ITreeStructure>;

  private destroy$                  : Subject<boolean> = new Subject();

  constructor(
    private treeService             : TreeService,
    private navigationStationStore  : Store<INavigationStationState>
  ) { }

  public ngOnInit(): void {
    this.tree$ = this.treeService.getStationSettingsTreeStructure().pipe(
      takeUntil(this.destroy$)
    );

    this.navigationStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((station: IStation): boolean => !!station)
    ).subscribe((station) => {
      this.station = station;
    });
  }

  public canAccess(): boolean {
    return this.station.rights !== READ_PERMISSION;
  }

  public getPageName(): string {
    if (this.station.flags.nmetosfwt) {
      return 'Field Water Tube Settings';
    } else if (this.station.flags.waterlevel) {
      return 'Water Level Settings';
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
