<div>
  <div class="row">
    <p><b>{{ 'Farm name' | translate }}</b>{{ ' is defined and used by the user to identify his/her farm (e.g. Bell Mount).' | translate }}</p>
    <p><b>{{ 'Field name' | translate }}</b>{{ ' is defined and used by the user to identify his/her field (e.g. KennHill).' | translate }}</p>
    <p><b>{{ 'Farm and field names' | translate }}</b> {{ 'cannot be edited after the cropzone was created. Because multiple cropzones can be linked to the same farm and field, it is safer to keep them uneditable.' | translate }}</p>
    <p><b>{{ 'Cropzone name' | translate }}</b> {{ ' is defined and used by the user to identify the given cropzone (e.g. KennHill18).' | translate }}</p>
    <p><b>{{ 'Crop name' | translate }}</b>{{ " is defined and used by the user to identify the grown crop (e.g. Apple). It is used only for the user's overview and has no further implications on the service." | translate }}</p>
    <p><b>{{ 'The start of the period' | translate }}</b>{{ ' that captures all user operations. The date may lay even before seeding to capture agriculture activities related to e.g. field preparations.' | translate }}</p>
    <p><b>{{ 'The end of the period' | translate }}</b>{{ ' that captures all user operations. Usually corresponds to the harvest time but may lay even after harvest to capture agriculture activities related to e.g. post-harvest spraying.' | translate }}</p>
    <p><b>{{ 'Copy settings from' | translate }}</b>{{ ' enables you to copy all cropzone’s settings (borders, Irrimet settings, Soil moisture settings) from already existing cropzone into this one.' | translate }}</p>
  </div>
</div>