<app-form-layout>
  <div *ngIf="sensorList; else loading">
    <app-card-header
    [header]="'MyJohnDeere Operations Center'"
    [description]="'Choose active sensors for the device data forwarding'"
    >
  </app-card-header>

  <div class="sensor-map">
    <div class="tableContainer">
      <table>
        <thead>
        <tr>
          <th width="5%">{{ 'Station' | translate }}</th>
          <th width="12.5%">{{ 'Sensor' | translate }}</th>
          <th width="12.5%">{{ 'Custom sensor name' | translate }}</th>
          <th>{{ 'Unit' | translate }}</th>
          <th class="lastHeader">{{ 'Active' | translate }}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let sensor of sensorList; let i = index"
            draggable="true"
            (dragover)="allowDrop($event)"
            (dragstart)="onDragStart(i)"
            (drop)="onDrop($event, i)"
        >
          <td width="5%">{{ station.name.original }}</td>
          <td width="12.5%">{{ sensor.name }}</td>
          <td width="12.5%">{{ sensor.custom_name }}</td>
          <td>{{ sensor.unit }}</td>
          <td class="lastColumn">
            <app-yes-no-toggle [ngModel]="sensor.syncThisData" (change)="toggle(sensor)">
            </app-yes-no-toggle>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div>
      <div class="col-md-12 container">
        <div class="save-sensor-map__cancel buttonDiv">
          <app-custom-button (click)="cancelEditing()"
                             color="red"
                             content="Cancel"
                             class="leftButton">
          </app-custom-button>
          <app-custom-button (click)="saveSensorMap()"
                             color="orange"
                             content="Save">
          </app-custom-button>
        </div>
      </div>
    </div>
  </div>
  </div>


  <ng-template #loading>
    <div class="loadingDiv">
      <h4><i class="fa fa-cog fa-spin fa-fw"></i> {{ 'Please wait, loading data ...' | translate }}</h4>
    </div>
    <div class="backButtonLoading">
      <div>
        <app-custom-button (click)="cancelEditing()"
                           color="red"
                           content="Cancel">
        </app-custom-button>
      </div>
    </div>
  </ng-template>
</app-form-layout>
