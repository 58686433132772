import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { selectUserName } from '../../../../../core/reducers';
import * as fromAccount from '../../../../../core/reducers/account';
import { cardAnimation } from '../../../../../shared/animations/card-animations';
import { CLOSE_ACCOUNT_INFO_TEXT } from '../../../constants/constants';

@Component({
  selector: 'app-close-account',
  templateUrl: './close-account.component.html',
  animations: [cardAnimation()]
})
export class CloseAccountComponent implements OnInit {

  public description          : string = CLOSE_ACCOUNT_INFO_TEXT;
  public username$            : Observable<string>;

  constructor(
    private accountStore      : Store<fromAccount.IAccount>,
    private translateService  : TranslateService
  ) { }

  public ngOnInit(): void {
    this.username$ = this.accountStore.pipe(
      select(selectUserName)
    );
  }
}
