<section class="widget-creation section"
         *ngIf="addWidgetDropDownOptions.length">
  <app-chart-header [title]="'Make a new widget' | translate"
                    [color]="formValues.color"
                    [isClose]="false"
                    [isDraggable]="false"
                    [isEditable]="false"></app-chart-header>
  <app-form-layout [size]="'widget-create'">

    <div class="row">
      <app-widget-add-form [addWidgetDropDownOptions]="addWidgetDropDownOptions"
                           (changeForm)="changeForm($event)"></app-widget-add-form>

      <app-widget-add-info [text]="selectedWidget.text"
                           [description]="selectedWidget.description">
      </app-widget-add-info>
    </div>

    <app-widget-add-buttons (add)="add()"
                            (close)="close()">
    </app-widget-add-buttons>

  </app-form-layout>
</section>
