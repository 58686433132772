import {
  IBaseCameraSettingsState,
  IBaseCameraState,
  ICameraPest, ICameraPestFamily, ICameraPestGenus,
  ICameraPestOrder,
  IPestToggler
} from '../../../shared/camera/models/camera';

export interface IIscoutState extends IBaseCameraState {
  userPests: Array<ICameraPest>;
  generalPests: Array<ICameraPest>;
}

export interface IIscoutSettingsState extends IBaseCameraSettingsState {
  pestTogglers: Array<IPestToggler>;
}

export interface IIscoutPestsState {
  pestsOrders: Array<ICameraPestOrder>;
  pestsGenus: Array<ICameraPestGenus>;
  pestsFamily: Array<ICameraPestFamily>;
  pests: Array<ICameraPest>;
  selectedPest: IIscoutPestFormAction;
}

export interface IIscoutGlueBoardState {
  glueBoards: Array<IIscoutGlueBoard>;
  availableDates: IIscoutGlueBoardAvailableDates;
  selectedGlueBoard: IIscoutGlueBoardFormAction;
  selectedGalleryGlueBoard: IIscoutGlueBoard;
}

export interface IIscoutSeasonState {
  seasons: Array<IIscoutSeason>;
  availableGlueBoards: Array<IIscoutGlueBoard>;
  selectedSeason: IIscoutSeasonFormAction;
}

export enum IscoutFormActionType {
  CREATE = 'create',
  UPDATE = 'update'
}

export interface IIscoutPestFormAction {
  pest: ICameraPest;
  action: IscoutFormActionType;
}

export interface IIscoutGlueBoardFormAction {
  glueBoard: IIscoutGlueBoard;
  action: IscoutFormActionType;
}

export enum IscoutPestType {
  USER = 'user',
  STATION = 'station',
  SYSTEM = 'system'
}

export interface IIscoutPestMap {
  [pestName: string]: ICameraPest;
}

export interface IIscoutBlueBoardPlateState {
  label: string;
  probability: number;
}

export interface IIscoutGlueBoard {
  _id?: string;
  nm: string;
  target: Array<IIscoutGlueBoardTarget>;
  from: string | Date;
  to?: string | Date;
  plate_state?: IIscoutBlueBoardPlateState;
}

export enum IIscoutGlueBoardTargetType {
  PEST_ORDER = 'pest_order',
  PEST_GENUS = 'pest_genus',
  PEST_FAMILY = 'pest_family',
  PEST_SPECIES = 'pest_species',
  PEST_LABEL = 'string',
}

export interface IIscoutGlueBoardTarget {
  type: IIscoutGlueBoardTargetType;
  ref: string;
  name?: string;
}

export interface IIscoutGlueBoardAvailableDates {
  last: Date;
  min: Date;
  max: Date;
}

export interface IIscoutGlueBoardPestMap {
  [id: string]: string;
}

export interface IIscoutSeason {
  _id?: string;
  nm: string;
  label: string;
  detections: Array<IIscoutSeasonDetection>;
}

export interface IIscoutSeasonDetection {
  position: number;
  glue_board_id: string;
  from?: Date;
  to?: Date;
  total?: Array<IIscoutSeasonDetectionCount>;
}

export interface IIscoutSeasonDetectionCount {
  [label: string]: number;
}

export interface IIscoutSeasonFormAction {
  season: IIscoutSeason;
  action: IscoutFormActionType;
}

export function getGlueBoardTargetType(type: string | IIscoutGlueBoardTargetType): string {
  switch (type) {
    case IIscoutGlueBoardTargetType.PEST_ORDER:
      return 'Insect Order';
    case IIscoutGlueBoardTargetType.PEST_FAMILY:
      return 'Insect Family';
    case IIscoutGlueBoardTargetType.PEST_GENUS:
      return 'Insect Genus';
    case IIscoutGlueBoardTargetType.PEST_SPECIES:
      return 'Insect Species';
    default:
      return 'Text';
  }
}
