import * as fromTimezoneAndLocation from './timezoneAndLocation';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromBaseName from './baseName';
import * as fromLoggingAndTransfer from './loggingAndTransfer';
import * as fromEmergency from './emergency';
import * as fromLedTrapSettings from './ledTrapSettings';
import * as fromCameraSettings from './cameraSettings';
import * as fromNote from './note';
import * as fromTrackerSettings from './tracker-settings';

export interface IState {
  timezoneAndLocation : fromTimezoneAndLocation.ITimezoneAndLocationState;
  baseName: fromBaseName.IBaseNameState;
  loggingAndTransfer: fromLoggingAndTransfer.ILoggingAndTransferState;
  emergency: fromEmergency.IEmergencyState;
  cameraSettings: fromCameraSettings.ICameraSettingsState;
  ledTrapSettings: fromLedTrapSettings.ILedTrapSettingsState;
  note: fromNote.INoteState;
  trackerSettings: fromTrackerSettings.ITrackerSettingsState;
}

export const reducers = {
  timezoneAndLocation: fromTimezoneAndLocation.reducer,
  baseName: fromBaseName.reducer,
  loggingAndTransfer: fromLoggingAndTransfer.reducer,
  emergency: fromEmergency.reducer,
  cameraSettings: fromCameraSettings.reducer,
  ledTrapSettings: fromLedTrapSettings.reducer,
  note: fromNote.reducer,
  trackerSettings: fromTrackerSettings.reducer
};


export const config = createFeatureSelector<IState>('config');

export const selectNote = createSelector(
  config,
  (account: IState) => account?.note?.note
);

export const selectLocation = createSelector(
  config,
  (account: IState) => account.timezoneAndLocation
);

export const selectBaseName = createSelector(
  config,
  (account: IState) => account.baseName
);

export const selectLoggingAndTransfer = createSelector(
  config,
  (account: IState) => account.loggingAndTransfer
);

export const selectIsDefault = createSelector(
  config,
  (account: IState) => account.loggingAndTransfer.isDefault
);

export const selectEmergency = createSelector(
  config,
  (account: IState) => account.emergency
);

export const selectCameraSettings = createSelector(
  config,
  (account: IState) => account.cameraSettings
);

export const selectLedTrapSettings = createSelector(
  config,
  (account: IState) => account.ledTrapSettings.config
);

export const selectTrackerSettings = createSelector(
  config,
  (account: IState) => account.trackerSettings
);
