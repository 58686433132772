import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { stationDataContentAnimations } from '../../../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../../../core/services/left-components-toggler/left-components-toggler.service';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { generateId } from '../../../dashboard/utils/makeWidget';

@Component({
  selector: 'app-sample-data-layout',
  templateUrl: './sample-data-layout.component.html',
  styleUrls: ['./sample-data-layout.component.scss'],
  animations: stationDataContentAnimations
})
export class SampleDataLayoutComponent {

  public state$: Observable<string>;
  public modalId: string = generateId();


  constructor(
    private modalService: ModalService,
    private leftComponentsToggler: LeftComponentsTogglerService
  ) {

    this.state$ = this.leftComponentsToggler.getStationDataContentState();
  }

  public openHelpModal(): void {
    this.modalService.openModal(this.modalId);
  }
}
