<div *ngIf="subtitle == 'RISKASSESSMENT'">
  <p><b>{{'Risk Assessment' | translate}}</b></p>
  <p>{{ 'The risk assessment diagram displays the probability of a specific weather event or risk to occur during a year. ' +
    'The diagrams are available for cold and warm events, precipitation, and are based on more than 30 years of historical simulation data. ' + 
    'By comparing different years, you can reveal patterns of specific events. ' + 
    'This diagram assists in weather depended decisions such as outdoor activities, vacation planning or sowing periods.' | translate}}
  </p>
  <p>{{'The risk assessment diagram is subdivided into 3 parts:' | translate}}</p>
  <ul>
    <li>
      <p>{{'Likelihood: The first diagram shows the likelihood of a certain event in percent (%). ' +
        'The y-axis indicates the likelihood, whilst the x-axis displays the time frame for all diagrams.' | translate}}</p>
    </li>
    <li>
      <p>{{'Tolerable risk: The tolerable risk approximates the personal tolerable risk in percent (%). ' +
        'This enables the risk evaluation by taking into account divergences, which are personally acceptable. ' +
        'The y-axis indicates the tolerable risk in percent.' | translate}}
      </p>
    </li>
    <li>
      <p>{{'Years: The third diagram compares the event occurrences of the last 40 years. ' +
        'The y-axis shows the different years. '+
        'This helps to differentiate seasons and assists in evaluating the probability of a certain event.' | translate}}
      </p>
    </li>
  </ul>
  <p><b>{{'Cold and warm event' | translate}}</b></p>
  <p>{{'Temperature is below or above a certain threshold for a certain time. ' +
    'The threshold is set for temperature values at 1°C for cold events while at 30°C for warm events. ' +
    'The cold events meteogram is commonly used to evaluate frost. ' +
    'The first diagram shows the likelihood of the defined event. ' +
    'You can estimate your personal risk in the second diagram. ' +
    'For instance, if your personal tolerable risk for a frost event is 20% you should schedule sowing later (e.g. end of April). ' +
    'On the other hand, if you can accept 50% frost likelihood in favor of earlier sowing, you should sow earlier (e.g. beginning of march). '+ 
    'The last diagram shows the occurrence of the defined event in the last 40 years for each year. ' +
    'You can also define the duration of an event.' | translate}}</p>
  <p><b>{{'Precipitation' | translate}}</b></p>
  <p>{{'This variable evaluates precipitation amounts in a week above a certain threshold and with the help of this representation; ' +
    'you can estimate strong precipitation events and schedule activities accordingly. ' +
    'The threshold for precipitation amounts is set at 30 mm/week.' | translate}}</p>
  <p><b>{{'Water capacity' | translate}}</b></p>
  <p>{{'Estimated probability of remaining soil water amount. Higher soil water capacities are better indicators for extremely dry ' +
    'seasons. Yellow, orange and red bars indicate almost depleted soil water.' | translate}}</p>
</div>
<div *ngIf="subtitle == 'CLIMATECOMPARISON'">
  <p><b>{{'Climate Comparison' | translate}}</b></p>
  <p><b>{{'Meteogram ‘Next 6 days’' | translate}}</b></p>
  <p>{{'The meteogram Climate comparison shows the expected weather, compared to the weather of the previous 10 or more years. ' +
    'Thereby, you can see how "normal" the current weather is. ' +
    'The meteogram selects automatically the closest place which has a weather station with a minimum of 10 years of reliable measurements available. ' +
    'The name of the place with the station is indicated in the meteogram title, as well as the distance from the selected place. ' | translate}}</p>
  <p>{{'The temperature chart shows the range of maximum, mean (thin line) and minimum temperatures of the past years, and the forecast for the next 6 days (thick line). ' +
    'The total precipitation for the next days is shown in the precipitation chart, relative to the average precipitation of the period during the last years. ' +
    'The climate chart shows temperature and precipitation of the current date as measured at the station on the same date during the past years. ' +
    'Years without measurement are marked with a grey bar.' | translate}}</p>
  <p><b>{{'Meteogram ‘Last 12 months’' | translate}}</b></p>
  <p>{{'It compares weather variables of the current season to the 30-year mean climate.' | translate}}</p>
  <ul>
    <li>
      <p>{{'The black line shows the mean temperature for every month of the last 12 months (current).' | translate}}
      </p>
    </li>
    <li>
      <p>{{'The thick red line shows the calculated mean temperature of the last 30 years for every month (climate). ' +
        'This line denotes the exact mean of the temperatures but does not reveal fluctuations in the temperature from year to year.' | translate}}</p>
    </li>
    <li>
      <p>{{'The orange buffer around the red line makes the fluctuations between the last 30 years more visible. ' +
        'It shows in what range the temperatures of the last 30 years are distributed. ' +
        'It shows the maximum monthly mean and the minimum monthly mean of the last 30 years.' | translate}}</p>
    </li>
  </ul>
  <p>{{'The diagram for the monthly precipitation shows the amount of precipitation for each month of the last 12 months
    compared to the precipitation of the last 30 years. ' +
    'The graph is labeled with millimeters and months.' | translate}}
  </p>
  <ul>
    <li>
      <p>{{'The black bars show the recorded precipitation for each current month.' | translate}}</p>
    </li>
    <li>
      <p>{{'The dark blue bars show the maximum amount of precipitation during the last 30 years for each month. ' +
        'The light blue bars show the minimum amount of precipitation during the last 30 years.' | translate}}</p>
    </li>
    <li>
      <p>{{'The boundary between dark blue and light blue is the monthly mean precipitation calculated from the last 30
        years.' | translate}}</p>
    </li>
  </ul>
</div>