import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IscoutSeasonActionTypes} from '../actions/iscout-seasons';
import {iScoutSeasonInitialState} from '../constants/constants';
import {IIscoutSeasonState} from '../models/iscout.models';

export function reducer(state: IIscoutSeasonState = iScoutSeasonInitialState, action: IActionWithPayload): IIscoutSeasonState {
  switch (action.type) {
    case IscoutSeasonActionTypes.SET_ISCOUT_SEASONS:
      return {
        ...state,
        seasons: action.payload
      };
    case IscoutSeasonActionTypes.SET_ISCOUT_SEASONS_AVAILABLE_GLUE_BOARDS:
      return {
        ...state,
        availableGlueBoards: action.payload
      };
    case IscoutSeasonActionTypes.SELECT_ISCOUT_SEASON:
      return {
        ...state,
        selectedSeason: action.payload
      };
    case IscoutSeasonActionTypes.UNSELECT_ISCOUT_SEASON:
      return {
        ...state,
        selectedSeason: null
      };
    default:
      return state;
  }
}
