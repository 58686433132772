import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notifications-history-header',
  templateUrl: './notifications-history-header.component.html',
  styleUrls: ['./notifications-history-header.component.scss']
})
export class NotificationHistoryHeaderComponent {
  @Input()
  public header     : string = '';
  @Input()
  public subTitle   : string = '';
}
