import { Component } from '@angular/core';

@Component({
  selector: 'app-widget-info',
  templateUrl: './widget-info.component.html',
  styleUrls: ['./widget-info.component.scss']
})
export class WidgetInfoComponent {

}
