<div>
<table [formGroup]="form" (change)="validateForm()">
  <thead>
    <tr>
      <td>{{ 'Refill point' | translate }}</td>
      <td>{{ 'Field Capacity' | translate }}</td>
      <td>{{ 'Unit' | translate }}</td>
      <td>{{ 'Depth in Soil' | translate }}</td>
    </tr>
  </thead>
  <tbody formArrayName="soilProfileLayers">
    <tr *ngFor="let layer of formData.controls ; let i = index;" [formGroupName]="i"><!--  [ngStyle]="layer.invalid ? {'background-color': '#d9534f'} : null"-->
      <td>
        <app-form-input id="refill_point" minValue="0" maxValue="100" contentType="number" formControlName="refill_point"></app-form-input>
      </td>
      <td>
        <app-form-input minValue="0" maxValue="100" contentType="number" formControlName="field_capacity">
        </app-form-input>
      </td>
      <td>%</td>
      <td>
        <div class="vertMiddleTableCell" >
          <app-form-input minValue="0" contentType="number" class="inlineBlock" formControlName="depth_in_soil"></app-form-input>
          <p *ngIf="unitSystem === 'imperial'" class="inlineBlock" >inch</p>
          <p *ngIf="unitSystem === 'metric'" class="inlineBlock" >cm</p>
        </div>
      </td>
      <td *ngIf="i === 0" class="whiteBackground">
        <app-custom-button color="green" (click)="copyToAllOtherDepths()">{{ 'Copy to rows below' | translate }}</app-custom-button>
      </td>
    </tr>
  </tbody>
</table>
<div class="alert alert-warning alert-block" *ngIf="!refillPointValid">{{ 'Refill point values should be between 1% and 100%.' | translate }}</div>
<div class="alert alert-warning alert-block" *ngIf="!fieldCapacityValid">{{ 'Field capacity values should be between 1% and 100%.' | translate }}</div>
<div class="alert alert-warning alert-block" *ngIf="!comparisonFieldCapacityRefillPointValid">{{ 'Field capacity should be higher than the refill point.' | translate }}</div>
<div class="alert alert-warning alert-block" *ngIf="!depthInSoilValid">{{ 'The depth should be between 1cm and 200cm.' | translate }}</div>
<div class="alert alert-warning alert-block" *ngIf="!depthsFollowingValid">{{ 'Depths should be in increasing order.' | translate }}</div>
</div>
