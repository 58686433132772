import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WeatherForecastDataComponent } from './containers/weather-forecast-data/weather-forecast-data.component';
import { WeatherForecastImageComponent } from './containers/weather-forecast-image/weather-forecast-image.component';
import { WeatherForecastPrecipitationRadarComponent } from './containers/weather-forecast-precipitation-radar/weather-forecast-precipitation-radar.component';
import { WeatherForecastHistoryClimateComponent } from './containers/weather-forecast-history-climate/weather-forecast-history-climate.component';

const routes: Routes = [
  { path: 'data', component: WeatherForecastDataComponent },
  { path: 'precipitation-radar', component: WeatherForecastPrecipitationRadarComponent },
  { path: ':page', component: WeatherForecastImageComponent },
  { path: ':page/:sub', component: WeatherForecastHistoryClimateComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class WeatherForecastRoutingModule { }
