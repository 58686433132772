import {ICropViewSettingsState, ICropViewState} from '../models/crop-view.models';
import * as fromCropView from './crop-view';
import * as fromCropViewSettings from './crop-view-settings';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {WIDE_CAMM_ID, ZOOM_CAMM_ID} from '../../../core/constants/camera';
import * as moment from 'moment';
import {IDrawingOptions, IFromTo, IPicture, IToolbarSettings} from '../../../shared/camera/models/camera';

interface IState {
  cropView: ICropViewState;
  cropViewSettings: ICropViewSettingsState;
}

export const reducers = {
  cropView: fromCropView.reducer,
  cropViewSettings: fromCropViewSettings.reducer,
};

export const cropViewSelector = createFeatureSelector<IState>('cropView');

export const selectCropViewWidePictures = createSelector(
  cropViewSelector,
  (cropViewState: IState): Array<IPicture> => cropViewState.cropView.pictures[WIDE_CAMM_ID]
);

export const selectCropViewZoomPictures = createSelector(
  cropViewSelector,
  (cropViewState: IState): Array<IPicture> => cropViewState.cropView.pictures[ZOOM_CAMM_ID]
);

export const selectCropViewIsLoading = createSelector(
  cropViewSelector,
  (cropViewState: IState): boolean => cropViewState.cropView.isLoading
);

export const selectCropViewIsError = createSelector(
  cropViewSelector,
  (cropViewState: IState): boolean => cropViewState.cropView.isError
);

export const selectCropViewFirstDate = createSelector(
  cropViewSelector,
  (cropViewState: IState): moment.Moment => cropViewState.cropView.firstDate
);

export const selectCropViewLastDate = createSelector(
  cropViewSelector,
  (cropViewState: IState): moment.Moment => cropViewState.cropView.lastDate
);

export const selectCropViewSettingsCurrentDateString = createSelector(
  cropViewSelector,
  (cropViewState: IState): string | IFromTo => cropViewState.cropViewSettings.currentDateString
);

export const selectCropViewSettingsSelectedPicture = createSelector(
  cropViewSelector,
  (cropViewState: IState): IPicture => cropViewState.cropViewSettings.selectedPicture
);

export const selectCropViewSettingsForToolbar = createSelector(
  cropViewSelector,
  (cropViewState: IState): IToolbarSettings => cropViewState.cropViewSettings.toolbarSettings
);

export const selectCropViewSettingsAreMeasurementsActive = createSelector(
  cropViewSelector,
  (cropViewState: IState): boolean => cropViewState.cropViewSettings.toolbarSettings.activity.areMeasurementsActive
);

export const selectCropViewSettingsIsHelpActive = createSelector(
  cropViewSelector,
  (cropViewState: IState): boolean => cropViewState.cropViewSettings.toolbarSettings.activity.isHelpActive
);

export const selectCameraDistance = createSelector(
  cropViewSelector,
  (cropViewState: IState): number => cropViewState.cropViewSettings.cameraDistance
);

export const selectCameraDrawingOptions = createSelector(
  cropViewSelector,
  (cropViewState: IState): IDrawingOptions => cropViewState.cropViewSettings.drawingOptions
);

export const selectCropViewNextPicture = createSelector(
  cropViewSelector,
  (cropViewState: IState, props: { currentPicture: IPicture, camId: number, next: boolean }): IPicture => {
    const availablePictures = cropViewState.cropView.pictures[props.camId];
    const index = availablePictures.findIndex(
      (picture) => picture.filename === props.currentPicture.filename
    );
    // Since is ordered by date descendent, it is necessary to subtract to go to the next
    const nextIndex = props.next ? index - 1 : index + 1;
    return availablePictures[nextIndex] || props.currentPicture;
  }
);
