<app-station-page-header [pageName]="'Station communication'"></app-station-page-header>
<app-form-layout>
  <div class="station-communication">
    <div class="communication__header">
      <h2>
        {{ 'Communication History' | translate }}
        <small>{{ 'Showing' | translate }} {{ getSelectedMode() | lowercase}}</small>
      </h2>
    </div>
    <div class="communication__body">
      <app-chart-toolbar-layout>
        <app-custom-dropdown [options]="modeOptions"
                             [formControl]="modeControl"></app-custom-dropdown>
      </app-chart-toolbar-layout>
      <div class="table-container">
        <table>
          <tbody >
            <tr>
              <th>{{ 'Time' | translate }}</th>
              <th>{{ 'Time from' | translate }}</th>
              <th>{{ 'Time to' | translate }}</th>
              <th>{{ 'Response' | translate }}</th>
              <th>{{ 'HTTP size' | translate }}</th>
            </tr>
            <ng-container *ngIf="stationCommunication.length && !(isError$ | async) && !(isLoading$ | async)">
              <tr *ngFor="let communication of stationCommunication">
                <td>{{ getFormattedDate(communication.dateTime) }}</td>
                <td>{{ getDateFromTimestamp(communication.dt_st?.$date?.$numberLong) }}</td>
                <td>{{ getDateFromTimestamp(communication.dt_end?.$date?.$numberLong) }}</td>
                <td>
                  <div *ngFor="let flag of communication.flags" [ngClass]="['status', flag]">{{ flag }}</div>
                </td>
                <td>{{ getSizeInKb(communication.http_s) }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
        <h4 *ngIf="isLoading$ | async">{{ 'Please wait, loading data ...' | translate }}</h4>
        <h4 *ngIf="isError$ | async">{{ 'No data to display.' | translate }}</h4>
      </div>
    </div>
  </div>
</app-form-layout>
