<div class="modal-container">
  <div class="modal-header">
    <button aria-label="Close" class="close" type="button" (click)="cancel()">
      <span aria-hidden="true">×</span>
    </button>
    <h3 class="modal-title text-center">{{ 'Delete image' | translate }}</h3>
  </div>

  <div class="modal-body">
    <div class="body-text">
      {{ 'Are you sure you want to delete the selected image?' | translate }}
    </div>

    <div class="button-wrapper">
      <div class="button">
        <app-custom-button [color]="'blue'"
                           [content]="'Cancel' | translate"
                           (click)="cancel()"></app-custom-button>
      </div>

      <div class="button">
        <app-custom-button [color]="'red'"
                           [content]="'Delete' | translate"
                           (click)="confirm()">
          <i class="fa fa-fw fa-trash"></i>
        </app-custom-button>
      </div>
    </div>

  </div>
</div>
