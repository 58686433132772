import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromWarningsList from './warningsList';
import * as fromWarningsPhones from './warningsPhones';

export interface IState {
  warningsList : fromWarningsList.IWarningsListState;
  warningsPhones: fromWarningsPhones.IWarningsPhonesState;
}

export const reducers = {
  warningsList: fromWarningsList.reducer,
  warningsPhones: fromWarningsPhones.reducer
};


export const warnings = createFeatureSelector<IState>('warnings');


export const selectWarningsList = createSelector(
  warnings,
  (state: IState) => state.warningsList
);

export const selectWarningsPhones = createSelector(
  warnings,
  (state: IState) => state.warningsPhones
);
