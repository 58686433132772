export enum DefaultDomain {
    NG_FIELD_CLIMATE = 'default',
}

export interface IDomain {
    [domain: string]: string;
}

export const domainMap: IDomain = {
    'ng.fieldclimate.com': DefaultDomain.NG_FIELD_CLIMATE,
    'turfclimate.com': 'turfclimate',
    'adama.fieldclimate.com': 'adama',
    'agrostoc.fieldclimate.com': 'agrostoc',
    'agroker.fieldclimate.com': 'agroker',
    'a1digital.fieldclimate.com': 'a1digital',
    'agritecgeo.fieldclimate.com': 'agritecgeo',
    'agrodat.fieldclimate.com': 'agrodat',
    'agromet.fieldclimate.com': 'agromet',
    'tramthoitiet.vn': 'agrimedia',
    'ng.tramthoitiet.vn': 'agrimedia',
    'agronomax.fieldclimate.com': 'agronomax',
    'defenso.fieldclimate.com': 'defenso',
    'farol.fieldclimate.com': 'farol',
    'farmfacts.fieldclimate.com': 'farmfacts',
    'frontierag.fieldclimate.com': 'frontierag',
    'geosys.fieldclimate.com': 'geosys',
    'ibfservizi.fieldclimate.com': 'ibfservizi',
    'ipko.fieldclimate.com': 'ipko',
    'johndeere.fieldclimate.com': 'johndeere',
    'lagerhaus.fieldclimate.com': 'lagerhaus',
    'leova.fieldclimate.com': 'leova',
    'nextfarming.fieldclimate.com': 'nextfarming',
    'nokia.fieldclimate.com': 'nokia',
    'ngdev.fieldclimate.com': 'ngdev',
    'ngdev.weiz.metos.at': 'ngweiz',
    'ngstage.fieldclimate.com': 'ngstage',
    'progludigital.com': 'proglu',
    'proglu.fieldclimate.com': 'proglu',
    'raiffeisen-digital.fieldclimate.com': 'raiffeisen-digital',
    'robertaebimeteo.ch': 'robertaebimeteo',
    'sce.fieldclimate.com': 'sce',
    'sunbelt.fieldclimate.com': 'sunbelt',
    'upl.fieldclimate.com': 'upl',
    'ng.dijitaltarimcozumu.com': 'vodafone',
    'dijitaltarimcozumu.com': 'vodafone',
    'vodafone.fieldclimate.com': 'vodafone',
    'vodafone-idea.fieldclimate.com': 'vodafone-idea',
    'ng.vodafoneideasagriculture.com': 'vodafone-idea',
    'ng.vodafoneideasmartagri.org': 'vodafone-idea',
    'vodafoneideasmartagri.org': 'vodafone-idea',
};

export function getAvailableDomain(): string {
    const currentDomain = (window.location.hostname).replace('www.', '');
    if (!domainMap[currentDomain]) {
        return DefaultDomain.NG_FIELD_CLIMATE;
    }
    return domainMap[currentDomain];
}
