<section>
  <app-form-layout>
    <app-iscout-header
      *ngIf="isCreationForm()"
      [title]="'Add a new Glue Board'"
      [subtitle]="'Create a Glue Board to manage insects on iScout'"
    ></app-iscout-header>

    <app-iscout-header
      *ngIf="!isCreationForm()"
      [title]="'Update the Glue Board'"
      [subtitle]="'Update an existent Glue Board on iScout'"
    ></app-iscout-header>

    <form class="iscout-glue-board-form" [formGroup]="form">
      <div class="row">
        <div class="col-sm-12 col-md-6 iscout-glue-board-form__group">
          <label>{{ 'Start' | translate }}*</label>
          <br>
          <app-custom-datepicker
            class="iscout-glue-board-form__input"
            [dateInputFormat]="'YYYY-MM-DD HH:mm'"
            [isSmall]="true"
            [minDate]="availableDateMin"
            [maxDate]="availableDateMax"
            [formControl]="glueBoardStart"
          ></app-custom-datepicker>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-6 iscout-glue-board-form__group">
          <label>{{ 'Finish' | translate }}*</label>
          <br>
          <app-custom-datepicker
            class="iscout-glue-board-form__input"
            [dateInputFormat]="'YYYY-MM-DD HH:mm'"
            [isSmall]="true"
            [minDate]="availableDateMin"
            [maxDate]="availableDateMax"
            [formControl]="glueBoardFinish"
          ></app-custom-datepicker>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-6 iscout-glue-board-form__group">
          <label>{{ 'Target' | translate }}</label>
          <br>
          <app-custom-lazy-grouped-dropdown
            class="iscout-glue-board-form__input"
            [title]="'Target selection'"
            [items$]="targetPestList$"
            [formControl]="glueBoardTarget"
          ></app-custom-lazy-grouped-dropdown>

          <app-custom-button
            [content]="'Add target'"
            (click)="handleAddGlueBoardTarget()"
          ></app-custom-button>
        </div>
      </div>

      <div class="row">
        <fieldset class="col-sm-12 col-md-12 iscout-glue-board-form__group">
          <legend>{{'Selected targets' | translate}}</legend>
          <ng-container *ngFor="let target of selectedTargetList">
            <app-custom-button
              [color]="'red'"
              (click)="handleRemoveGlueBoardTarget(target)"
            >
              {{ target.name }}
              <i class="fa fa-trash"></i>
            </app-custom-button>
          </ng-container>
        </fieldset>
      </div>

      <div class="iscout-glue-board-form__submit">
        <app-custom-button
          [disabled]="!form.valid"
          [color]="'red'"
          [content]="'Save'"
          (click)="submitGlueBoard()"
        ></app-custom-button>

        <app-custom-button
          [content]="'Back'"
          (click)="unselectGlueBoard()"
        ></app-custom-button>
      </div>
    </form>
  </app-form-layout>
</section>
