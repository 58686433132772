<div>
  <p>{{ 'Water balance: green represents water surplus while red represents water deficit. The water balance lies ' +
  'between 0 (Refill point) and Max water in root depth (Field capacity). If root depth was not provided in Irrimet ' +
  'settings, there is no upper limit for water balance.' | translate }}</p>
  <p>{{ 'When to irrigate: If soil water is depleted to 0, it reached the refill point and you should consider to ' +
  'irrigate.' | translate }}</p>
  <p>{{ 'How much to irrigate: If soil water approaches 0, you should irrigate according to the crop type and crop ' +
  'stage. The "Max water in rootzone depth" shows you the irrigation amount missing to reach the field capacity.' | translate }}</p>
  <p>{{ 'Root depth is defined in irrimet settings.' | translate }}</p>
  <p>{{ 'Kc indicates crop coefficient and is defined in irrimet settings.' | translate }}</p>
  <p>{{ 'ETc indicates evapotranspiration and is computed upon station and settings defined in irrimet settings.' | translate }}</p>
  <p>{{ 'Max water in rootzone depth indicates the maximum amount of water that can be stored in soil within the ' +
  'rootzone reach. It is only computed if you provided the irrimet rootzone settings (below the interactive graphic ' +
  'in irrimet settings).' | translate }}</p>
</div>
