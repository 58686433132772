import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthModule} from '../../auth.module';
import {ResetPasswordRoutingModule} from './reset-password-routing.modulte';
import {ResetPasswordComponent} from './reset-password.component';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  imports: [
    CommonModule,
    ResetPasswordRoutingModule,
    AuthModule,
    TranslateModule.forChild()
  ],
  declarations: [ResetPasswordComponent]
})
export class ResetPasswordModule { }
