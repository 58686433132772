import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IGetWeatherForecastRequest, IWeatherForecastSettingsModePayload, IWeatherForecastSettingsPayload} from '../models/models';

export enum WeatherForecastSettingsActionTypes {
  SET_WEATHER_FORECAST_SETTINGS_IS_CHART_ACTIVE = '[WeatherForecastSettings] SET_WEATHER_FORECAST_SETTINGS_IS_CHART_ACTIVE',
  SET_WEATHER_FORECAST_SETTINGS_IS_TABLE_ACTIVE = '[WeatherForecastSettings] SET_WEATHER_FORECAST_SETTINGS_IS_TABLE_ACTIVE',
  SET_WEATHER_FORECAST_SETTINGS = '[WeatherForecastSettings] SET_WEATHER_FORECAST_SETTINGS',
  SET_WEATHER_FORECAST_SETTINGS_MODE = '[WeatherForecastSettings] SET_WEATHER_FORECAST_SETTINGS_MODE',
  GET_WEATHER_FORECAST_CHART = '[WeatherForecastSettings] GET_WEATHER_FORECAST_CHART',
  SET_WEATHER_FORECAST_IMAGE_EXPORT_IN_PROGRESS = '[WeatherForecastSettings] SET_WEATHER_FORECAST_IMAGE_EXPORT_IN_PROGRESS',
}

export function setWeatherForecastSettingsIsChartActive(status: boolean): IActionWithPayload {
  return {type: WeatherForecastSettingsActionTypes.SET_WEATHER_FORECAST_SETTINGS_IS_CHART_ACTIVE, payload: status};
}

export function setWeatherForecastSettingsIsTableActive(status: boolean): IActionWithPayload {
  return {type: WeatherForecastSettingsActionTypes.SET_WEATHER_FORECAST_SETTINGS_IS_TABLE_ACTIVE, payload: status};
}

export function setWeatherForecastSetting(data: IWeatherForecastSettingsPayload): IActionWithPayload {
  return {type: WeatherForecastSettingsActionTypes.SET_WEATHER_FORECAST_SETTINGS, payload: data};
}

export function setWeatherForecastSetttingsMode(data: IWeatherForecastSettingsModePayload): IActionWithPayload {
  return {type: WeatherForecastSettingsActionTypes.SET_WEATHER_FORECAST_SETTINGS_MODE, payload: data};
}

export function getWeatherForecastChart(data: IGetWeatherForecastRequest): IActionWithPayload {
  return {type: WeatherForecastSettingsActionTypes.GET_WEATHER_FORECAST_CHART, payload: data};
}

export function setWeatherForecastImageExportInProgress(status: boolean): IActionWithPayload {
  return {type: WeatherForecastSettingsActionTypes.SET_WEATHER_FORECAST_IMAGE_EXPORT_IN_PROGRESS, payload: status};
}
