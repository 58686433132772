import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ICameraPestGenus, IPostCameraPestBody } from '../../../../../shared/camera/models/camera';
import { IOptions } from '../../../../../shared/interfaces';
import { getIscoutPestsGenus, unselectIscoutPest } from '../../../actions/iscout-pests';
import { iScoutPresetColors } from '../../../constants/constants';
import { IIscoutPestFormAction, IIscoutPestsState, IscoutFormActionType } from '../../../models/iscout.models';
import { selectIscoutPestsGenus } from '../../../reducers';

@Component({
  selector: 'app-iscout-pests-form',
  templateUrl: './iscout-pests-form.component.html',
  styleUrls: ['./iscout-pests-form.component.scss']
})
export class IscoutPestsFormComponent implements OnInit, OnDestroy {
  @Input()
  public formAction: IIscoutPestFormAction;
  @Output()
  public pestSaved = new EventEmitter<IPostCameraPestBody>();

  public presetColors = iScoutPresetColors;
  public form: FormGroup;
  public pestGenusList$: Observable<Array<IOptions>>;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  public constructor(private iscoutPestStore: Store<IIscoutPestsState>,
                     private fb: FormBuilder) {
  }

  public ngOnInit(): void {
    this.initializeFormValues();
    this.initializePestArray();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initializeFormValues(): void {
    let formConfig = {
      'genus': [{}, [Validators.required]],
      'scientificName': ['', [Validators.required]],
      'commonName': ['', []],
      'color': ['', [Validators.required]]
    };

    if (this.formAction.action === IscoutFormActionType.UPDATE) {
      const selectedGenus: IOptions = {
        value: this.formAction.pest.genus.name,
        content: this.formAction.pest.genus._id
      };

      formConfig = {
        'genus': [selectedGenus, [Validators.required]],
        'scientificName': [this.formAction.pest.name, [Validators.required]],
        'commonName': [this.formAction.pest.common_name, []],
        'color': [this.formAction.pest.color, [Validators.required]]
      };
    }

    this.form = this.fb.group(formConfig);
  }

  private initializePestArray(): void {
    this.pestGenusList$ = this.iscoutPestStore.pipe(
      takeUntil(this.destroy$),
      select(selectIscoutPestsGenus),
      map((result: Array<ICameraPestGenus>): Array<IOptions> =>
        result.map((o: ICameraPestGenus) => ({
          value: `${o.name}`,
          content: o._id
        }))
      )
    );
    this.iscoutPestStore.dispatch(getIscoutPestsGenus());
  }

  public get pestGenus(): AbstractControl {
    return this.form.get('genus');
  }

  public get pestScientificName(): AbstractControl {
    return this.form.get('scientificName');
  }

  public get pestCommonName(): AbstractControl {
    return this.form.get('commonName');
  }

  public get pestColor(): AbstractControl {
    return this.form.get('color');
  }

  public onColorChange(color: string): void {
    this.pestColor.setValue(color);
  }

  public submitPest(): void {
    const request: IPostCameraPestBody = {
      color: this.form.value.color,
      common_name: this.form.value.commonName,
      name: this.form.value.scientificName,
      genus_id: this.form.value.genus.content
    };

    if (this.formAction.pest && this.formAction.pest._id) {
      request._id = this.formAction.pest._id;
    }

    this.pestSaved.emit(request);
  }

  public unselectPest(): void {
    this.iscoutPestStore.dispatch(unselectIscoutPest());
  }

  public isCreationForm(): boolean {
    return this.formAction.action === IscoutFormActionType.CREATE;
  }
}
