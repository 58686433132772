<app-sample-data-layout>

  <ng-container *ngIf="devices.soilGuard">
    <app-sample-data-map [data]="devices.soilGuard"
                         [cropzone]="cropzone"
                         [activeSample]="activeSample"
                         [activeDevice]="activeDevice"
                         [activeMeter]="activeMeter"
                         (navigate)="navigatePage($event)"
                         [lengend]="true">
    </app-sample-data-map>
  </ng-container>

  <app-sample-data-toolbar (activateTable)="isTableActive = $event"
                           (dropSelection)="navigatePage({source: null, activeSample: null, activeDevice: null})"
                           [activeDeviceType]="deviceType">
  </app-sample-data-toolbar>

    <div class="sample-data-content">

      <ng-container *ngIf="isReady; else loadInfo">

      <div class="soil-guard-table" *ngIf="devices.soilGuard?.length">
        <div *ngFor="let table of devices.soilGuard" #deviceTables [id]="table[0]?.deviceID">
          <app-sample-data-table [tableData]="table"
                                 [columnDefs]="soilGuardDefs"
                                 deviceType="SoilGuard Device"
                                 [activeSample]="table[0]?.deviceID === activeDevice ? activeSample : null"
                                 [isTableActive]="isTableActive"
                                 (navigate)="navigatePage($event)">
          </app-sample-data-table>
        </div>
      </div>
      </ng-container>


      <ng-template #loadInfo>
        <app-station-load-info
          [errorText]="errorText"
          [isLoading]="isLoading">
        </app-station-load-info>
      </ng-template>
    </div>

</app-sample-data-layout>
