<section *ngIf="trackerData; else loading">
  <section *ngIf="trackerData.trip.length != 0 && trackerData.trip[0].path != null; else noData">
    <agm-map #AgmMap
    *ngIf="trackerData.trip[0].path[0]; else noData"
    [latitude]="trackerData.trip[0].path[0].lat"
    [longitude]="trackerData.trip[0].path[0].lng"
    [zoom]="10"
    [disableDefaultUI]="true"
    [zoomControl]="true"
    [scrollwheel]="false"
    [streetViewControl]="false"
    [mapTypeId]="'satellite'"
    [mapTypeControl]="true"
    (mapReady)="onMapReady($event)">
      <section>
        <ng-container *ngFor="let trip of trackerData.trip; let j = index">
        <agm-polyline [strokeColor]="trip.color" [clickable]="true" (lineClick)="navigateTrackerPage(trip, j)">
          <ng-container *ngFor="let marker of trip.path; let i = index">
            <agm-polyline-point
              [latitude]="marker.lat"
              [longitude]="marker.lng">
            </agm-polyline-point>
            <agm-marker [agmFitBounds]="true" *ngIf="i === 0 || i ===trip.path.length - 1" [iconUrl]="getIconUrl(i ===trip.path.length - 1, i === 0)"
              [latitude]="trip.path[i].lat" [longitude]="trip.path[i].lng">
              <agm-info-window>
                <ng-container *ngIf="i === 0">
                  {{ 'Trips start' | translate }}: {{trip.tripStart}}
                </ng-container>
                <ng-container *ngIf="i ===trip.path.length - 1">
                  {{ 'Trips end' | translate }}: {{trip.tripEnd}}
                </ng-container>
              </agm-info-window>
            </agm-marker>
          </ng-container>
        </agm-polyline>
      </ng-container>
      </section>
    </agm-map>
  </section>
</section>

<ng-template #noData>
  <app-widget-info>
    <h4>{{ 'This tracker has no trips to be shown.' | translate }}</h4>
  </app-widget-info>
</ng-template>

<ng-template #loading>
  <app-widget-info>
    <h4><i class="fa fa-cog fa-spin fa-fw"></i> {{ 'Please wait, loading data ...' | translate }}</h4>
  </app-widget-info>
</ng-template>
