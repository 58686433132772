import {Injectable} from '@angular/core';
import {
  circleMeasurementsFont,
  edgeRectangleVertices,
  rectangleConfig,
  RectangleVertexTypes,
  scaleLines
} from '../../constants/rectangles';
import {
  ICanvasDrawingOptions,
  IPoint,
  IRectangle,
  IRectangleCompanion,
  IRectangleCoordinates,
  IRectangleDrawingOptions,
  IRectangleSelected,
  IRectangleVertex
} from '../../models/rectangles';

@Injectable({
  providedIn: 'root'
})
export class RectangleService {
  private imgUrl: string;
  private canvas: HTMLCanvasElement;
  private canvasContext: CanvasRenderingContext2D;

  private selectedRectangleIndex: number;
  private multipleSelectedRectangleIndexes: number[] = [];
  private hoveredRectangleIndex: number;
  private selectedRectangleVertex: IRectangleVertex;

  public init(imgUrl: string, canvas: HTMLCanvasElement): void {
    this.imgUrl = imgUrl;
    this.canvas = canvas;
    this.canvasContext = canvas.getContext('2d');
  }

  public setSelectedRectangleIndex(value: number): void {
    this.selectedRectangleIndex = value;
  }

  public getSelectedRectangleIndex(): number {
    return this.selectedRectangleIndex;
  }

  public setHoveredRectangleIndex(value: number): void {
    this.hoveredRectangleIndex = value;
  }

  public setSelectedRectangleVertex(selectedRectangleVertex: IRectangleVertex): void {
    this.selectedRectangleVertex = selectedRectangleVertex;
  }

  public addSelectedRectangleToList(rectangleIndex: number): void {
    const filter = (r) => r === rectangleIndex;
    const selectedIndex = this.multipleSelectedRectangleIndexes.findIndex(filter);
    if (selectedIndex === -1) {
      this.multipleSelectedRectangleIndexes.push(rectangleIndex);
    } else {
      this.multipleSelectedRectangleIndexes.splice(selectedIndex, 1);
    }
  }

  public isMultipleRectangleSelected(): boolean {
    return this.multipleSelectedRectangleIndexes.length > 0;
  }

  public getMultipleSelectedRetangles(rectangles: IRectangle[]): IRectangleSelected[] {
    return this.multipleSelectedRectangleIndexes.map((rectangleIndex) => ({
      rectangleIndex: rectangles[rectangleIndex].originalIndex || rectangleIndex,
      rectangle: rectangles[rectangleIndex]
    }));
  }

  public clearMultipleRectanglesSelection(): void {
    this.multipleSelectedRectangleIndexes = [];
  }

  public getSelectedRectangleVertex(): IRectangleVertex {
    return this.selectedRectangleVertex;
  }

  public redraw(rectangles: Array<IRectangle>, canvasOptions: ICanvasDrawingOptions, mousePosition: IPoint = null): void {
    if (!this.imgUrl) {
      return;
    }
    const imageObj = this.createImage();
    imageObj.onload = () => {
      this.canvasContext.drawImage(imageObj, 0, 0, this.canvas.width, this.canvas.height);
      this.drawRectangles(rectangles, canvasOptions);
      if (mousePosition) {
        this.drawCrosshair(mousePosition);
      }
    };
  }

  private createImage(): HTMLImageElement {
    const imageObj = new Image();
    imageObj.crossOrigin = 'Anonymous';
    imageObj.src = this.imgUrl;
    imageObj.crossOrigin = 'Anonymous';
    return imageObj;
  }

  public drawCrosshair(position: IPoint): void {
    this.canvasContext.lineWidth = 2;
    this.canvasContext.beginPath();
    this.canvasContext.moveTo(0, position.y);
    this.canvasContext.lineTo(this.canvas.width, position.y);
    this.canvasContext.strokeStyle = rectangleConfig.crosshair_color;
    this.canvasContext.stroke();
    this.canvasContext.moveTo(position.x, 0);
    this.canvasContext.lineTo(position.x, this.canvas.height);
    this.canvasContext.stroke();
    this.canvasContext.closePath();
  }

  public drawRectangles(rectangles: Array<IRectangle>, canvasOptions: ICanvasDrawingOptions): void {
    for (let i = 0; i < rectangles.length; i++) {
      const targetRectangle = rectangles[i];
      const coordinates: IRectangleCoordinates = this.getRectangleCoordinates(targetRectangle);
      const rectangleOptions: IRectangleDrawingOptions = this.getRectangleDrawingOptions(targetRectangle, i);

      // Skip the drawing of the rectangle if:
      // - rectangle is marked and the show marked rectangles option is turned off
      // - rectangle is not marked and show unmarked rectangles option is turned off
      // - rectangle 'visible' property is false
      if ((
        canvasOptions.measurementsAreActive === false
        && targetRectangle.hasOwnProperty('visible')
        && !targetRectangle.visible
      ) || (
        canvasOptions.showMarked === false
        && targetRectangle.hasOwnProperty('marked')
        && targetRectangle.marked
      ) || (
        canvasOptions.showUnmarked === false
        && (
          !targetRectangle.hasOwnProperty('marked')
          || (
            targetRectangle.hasOwnProperty('marked')
            && !targetRectangle.marked
          )
        )
      )) {
        continue;
      }

      this.drawOneRectangle(coordinates, rectangleOptions);
      if (canvasOptions.cameraDistancePixelMultiplier > 0) {
        this.drawRectangleCompanion(coordinates, rectangleOptions, canvasOptions);
      }
      if (rectangleOptions.hasEdges) {
        this.drawRectangleEdges(coordinates);
        this.drawRectangleCenter(coordinates);
      }
      // Should display the label of selected rectangle
      if (this.multipleSelectedRectangleIndexes.find((r) => r === i) !== undefined) {
        this.drawRectangleLabel(targetRectangle, coordinates);
      }
    }
  }

  private drawRectangleLabel(targetRectangle: IRectangle, coordinates: IRectangleCoordinates): void {
    this.canvasContext.font = '50px Helvetica, sans-serif';
    const startPointWithPaddingY = coordinates.startPoint.y - 40;
    this.canvasContext.fillText(targetRectangle.label, coordinates.startPoint.x, startPointWithPaddingY);
  }

  public getRectangleCenter(rectangle: IRectangle): IPoint {
    return this.getRectangleCenterByCoordinates(this.getRectangleCoordinates(rectangle));
  }

  private getRectangleCoordinates(rectangle: IRectangle): IRectangleCoordinates {
    const x1 = rectangle.x_min * this.canvas.width;
    const x2 = rectangle.x_max * this.canvas.width;
    const y1 = rectangle.y_min * this.canvas.height;
    const y2 = rectangle.y_max * this.canvas.height;
    return {
      startPoint: {x: Math.min(x1, x2), y: Math.min(y1, y2)},
      finishPoint: {x: Math.max(x1, x2), y: Math.max(y1, y2)}
    };
  }

  private getRectangleDrawingOptions(rectangle: IRectangle, rectangleIndex: number): IRectangleDrawingOptions {
    let type: string = 'system';
    let lineIsBold: boolean = rectangle.new;

    if (this.selectedRectangleIndex === rectangleIndex) {
      type = 'selected';
    } else if (this.hoveredRectangleIndex === rectangleIndex) {
      type = 'hover';
    } else if (rectangle.generated_by && rectangle.generated_by === 'user') {
      type = 'user';
      lineIsBold = true;
    }

    let pestColor = rectangleConfig.pest_color[type];
    if (rectangle.hasOwnProperty('marked') && rectangle['marked']) {
      pestColor = rectangleConfig.marked_rectangle_color;
    } else if (this.multipleSelectedRectangleIndexes.includes(rectangleIndex)) {
      pestColor = rectangleConfig.pest_color['selected'];
    } else if (rectangle.color) {
      pestColor = rectangle.color;
    }

    let markedID = null;
    if (rectangle.hasOwnProperty('marked') && rectangle['marked'] === true) {
      markedID = rectangle['rect_id'];
    }

    return {
      color: pestColor,
      lineWidth: lineIsBold ? 8 : 4,
      hasEdges: ['hover', 'selected'].includes(type),
      id: markedID
    };
  }

  private drawOneRectangle({startPoint, finishPoint}: IRectangleCoordinates, drawingOptions: IRectangleDrawingOptions): void {
    this.canvasContext.beginPath();
    this.canvasContext.lineWidth = drawingOptions.lineWidth;
    this.canvasContext.rect(startPoint.x, startPoint.y, finishPoint.x - startPoint.x, finishPoint.y - startPoint.y);
    this.canvasContext.strokeStyle = drawingOptions.color;
    this.canvasContext.stroke();
    this.canvasContext.closePath();
  }

  private drawRectangleEdges(coordinates: IRectangleCoordinates): void {
    const edgeSize: number = rectangleConfig.hover_edge_size * 2;
    edgeRectangleVertices.forEach((vertexName: RectangleVertexTypes): void => {
      this.canvasContext.beginPath();
      this.canvasContext.fillStyle = this.getRectangleVertexColorByType(vertexName);
      const vertexPoints = this.getRectangleVertexPointByType(coordinates, vertexName);
      const edgeRectangle: IPoint = {
        x: vertexPoints.x - rectangleConfig.hover_edge_size,
        y: vertexPoints.y - rectangleConfig.hover_edge_size
      };
      this.canvasContext.rect(edgeRectangle.x, edgeRectangle.y, edgeSize, edgeSize);
      this.canvasContext.fill();
      this.canvasContext.closePath();
    });
  }

  private getRectangleVertexColorByType(type: RectangleVertexTypes): string {
    return this.selectedRectangleVertex && this.selectedRectangleVertex.corner === type
      ? rectangleConfig.hover_edge_color
      : rectangleConfig.rectangle_edge_color;
  }

  private getRectangleVertexPointByType(coordinates: IRectangleCoordinates, type: RectangleVertexTypes): IPoint {
    let result: IPoint;
    switch (type) {
      case RectangleVertexTypes.TopLeft:
        result = {x: coordinates.startPoint.x, y: coordinates.startPoint.y};
        break;
      case RectangleVertexTypes.TopRight:
        result = {x: coordinates.finishPoint.x, y: coordinates.startPoint.y};
        break;
      case RectangleVertexTypes.BottomLeft:
        result = {x: coordinates.startPoint.x, y: coordinates.finishPoint.y};
        break;
      case RectangleVertexTypes.BottomRight:
        result = {x: coordinates.finishPoint.x, y: coordinates.finishPoint.y};
        break;
      default:
        result = null;
    }
    return result;
  }

  private drawRectangleCenter(coordinates: IRectangleCoordinates): void {
    const centerPoint: IPoint = this.getRectangleCenterByCoordinates(coordinates);
    const centerSize: number = rectangleConfig.rectangle_center_size;
    this.canvasContext.beginPath();
    this.canvasContext.strokeStyle = this.getRectangleVertexColorByType(RectangleVertexTypes.Center);
    this.canvasContext.lineWidth = 6;
    this.canvasContext.moveTo(centerPoint.x - centerSize, centerPoint.y);
    this.canvasContext.lineTo(centerPoint.x + centerSize, centerPoint.y);
    this.canvasContext.stroke();
    this.canvasContext.moveTo(centerPoint.x, centerPoint.y - centerSize);
    this.canvasContext.lineTo(centerPoint.x, centerPoint.y + centerSize);
    this.canvasContext.stroke();
    this.canvasContext.closePath();
  }

  private getRectangleCenterByCoordinates(coordinates: IRectangleCoordinates): IPoint {
    return {
      x: coordinates.startPoint.x + (coordinates.finishPoint.x - coordinates.startPoint.x) / 2,
      y: coordinates.startPoint.y + (coordinates.finishPoint.y - coordinates.startPoint.y) / 2
    };
  }

  private drawRectangleCompanion(
    coordinates: IRectangleCoordinates,
    rectangleOptions: IRectangleDrawingOptions,
    canvasOptions: ICanvasDrawingOptions
  ): void {
      this.canvasContext.strokeStyle = rectangleOptions.color;
      this.canvasContext.fillStyle = rectangleOptions.color;
      const rectangleCompanion: IRectangleCompanion = this.getRectanleCompanion(coordinates);

      if (rectangleOptions.hasOwnProperty('id') && rectangleOptions.id !== null) {
        this.drawCircleID(coordinates, rectangleOptions.id);
      }
      if (canvasOptions.showCircleScale) {
        this.drawCircleScale(coordinates, rectangleCompanion, canvasOptions, true);
        this.drawCircleScale(coordinates, rectangleCompanion, canvasOptions);
      }
      if (canvasOptions.showCircleMeasurement) {
        this.drawCircleMeasurements(coordinates, rectangleCompanion, canvasOptions, true);
        this.drawCircleMeasurements(coordinates, rectangleCompanion, canvasOptions);
      }
  }

  private getRectanleCompanion(coordinates: IRectangleCoordinates): IRectangleCompanion {
    return {
      X: {
        type: 'x',
        point1: coordinates.startPoint['x'],
        point2: coordinates.finishPoint['x']
      },
      Y: {
        type: 'y',
        point1: coordinates.startPoint['y'],
        point2: coordinates.finishPoint['y']
      }
    };
  }

  private drawCircleScale(
    coordinates: IRectangleCoordinates,
    companionObject: IRectangleCompanion,
    canvasOptions: ICanvasDrawingOptions,
    typeX: boolean = false
  ): void {
    const companion = typeX ? companionObject.X : companionObject.Y;
    const center = companion.point1 + ((companion.point2 - companion.point1) / 2);
    this.canvasContext.beginPath();
    if (canvasOptions.rotateImg) {
      if (typeX) {
        this.canvasContext.moveTo(center, coordinates.startPoint.y - scaleLines.big.start);
        this.canvasContext.lineTo(center, coordinates.startPoint.y - scaleLines.big.end);
      } else {
        this.canvasContext.moveTo(coordinates.finishPoint.x + scaleLines.big.start, center);
        this.canvasContext.lineTo(coordinates.finishPoint.x + scaleLines.big.end, center);
      }
    } else {
      if (typeX) {
        this.canvasContext.moveTo(center, coordinates.finishPoint.y + scaleLines.big.start);
        this.canvasContext.lineTo(center, coordinates.finishPoint.y + scaleLines.big.end);
      } else {
        this.canvasContext.moveTo(coordinates.startPoint.x - scaleLines.big.start, center);
        this.canvasContext.lineTo(coordinates.startPoint.x - scaleLines.big.end, center);
      }
    }
    this.canvasContext.stroke();
    this.canvasContext.closePath();

    const d = Math.floor((companion.point2 - companion.point1) / 2 / canvasOptions.cameraDistancePixelMultiplier);

    for (let k = 1; k <= d; k++) {
      const offset = k * canvasOptions.cameraDistancePixelMultiplier;
      let lineStart: number, lineEnd: number;
      if (k % 10 === 0) {
        lineStart = scaleLines.big.start;
        lineEnd = scaleLines.big.end;
      } else {
        lineStart = scaleLines.small.start;
        lineEnd = scaleLines.small.end;
      }
      this.canvasContext.beginPath();
      if (canvasOptions.rotateImg) {
        this.canvasContext.save();
        if (typeX) {
          this.canvasContext.moveTo(center - offset, coordinates.startPoint.y - lineStart);
          this.canvasContext.lineTo(center - offset, coordinates.startPoint.y - lineEnd);
        } else {
          this.canvasContext.moveTo(coordinates.finishPoint.x + lineStart, center - offset);
          this.canvasContext.lineTo(coordinates.finishPoint.x + lineEnd, center - offset);
        }
        this.canvasContext.restore();
      } else {
        if (typeX) {
          this.canvasContext.moveTo(center - offset, coordinates.finishPoint.y + lineStart);
          this.canvasContext.lineTo(center - offset, coordinates.finishPoint.y + lineEnd);
        } else {
          this.canvasContext.moveTo(coordinates.startPoint.x - lineStart, center - offset);
          this.canvasContext.lineTo(coordinates.startPoint.x - lineEnd, center - offset);
        }
      }

      this.canvasContext.stroke();
      this.canvasContext.closePath();

      this.canvasContext.beginPath();
      if (canvasOptions.rotateImg) {
        this.canvasContext.save();
        if (typeX) {
          this.canvasContext.moveTo(center + offset, coordinates.startPoint.y - lineStart);
          this.canvasContext.lineTo(center + offset, coordinates.startPoint.y - lineEnd);
        } else {
          this.canvasContext.moveTo(coordinates.finishPoint.x + lineStart, center + offset);
          this.canvasContext.lineTo(coordinates.finishPoint.x + lineEnd, center + offset);
        }
        this.canvasContext.restore();
      } else {
        if (typeX) {
          this.canvasContext.moveTo(center + offset, coordinates.finishPoint.y + lineStart);
          this.canvasContext.lineTo(center + offset, coordinates.finishPoint.y + lineEnd);
        } else {
          this.canvasContext.moveTo(coordinates.startPoint.x - lineStart, center + offset);
          this.canvasContext.lineTo(coordinates.startPoint.x - lineEnd, center + offset);
        }
      }
      this.canvasContext.stroke();
      this.canvasContext.closePath();
    }
  }

  private drawCircleID(
    coordinates: IRectangleCoordinates,
    id: number | string
  ): void {
    this.canvasContext.beginPath();
    const prevousColor = this.canvasContext.fillStyle;
    this.canvasContext.font = circleMeasurementsFont;
    const text: string = '' + id;

    this.canvasContext.fillRect(
      coordinates.startPoint.x + 10,
      coordinates.startPoint.y - 50,
      this.canvasContext.measureText(text).width + 10,
      50
      );
    this.canvasContext.fillStyle = 'yellow';
    this.canvasContext.fillText(
      text,
      coordinates.startPoint.x + 15,
      coordinates.startPoint.y - 10
    );

    this.canvasContext.closePath();
    this.canvasContext.fillStyle = prevousColor;
  }

  private drawCircleMeasurements(
    coordinates: IRectangleCoordinates,
    companion: IRectangleCompanion,
    canvasOptions: ICanvasDrawingOptions,
    typeX: boolean = false
  ): void {
    this.canvasContext.beginPath();
    this.canvasContext.font = circleMeasurementsFont;
    const diameter: number = typeX ?
                            (companion.X.point2 - companion.X.point1) / canvasOptions.cameraDistancePixelMultiplier :
                            (companion.Y.point2 - companion.Y.point1) / canvasOptions.cameraDistancePixelMultiplier;

    const text: string = diameter.toFixed(2) + ' mm';
    const margin = canvasOptions.showCircleScale ? 90 : 70;

    if (canvasOptions.rotateImg) {
      this.canvasContext.save();
      this.canvasContext.rotate(180 * Math.PI / 180);
      const x = ((coordinates.finishPoint.x - (coordinates.finishPoint.x - coordinates.startPoint.x) / 2)
        - this.canvasContext.measureText(text).width / 2);

      this.canvasContext.fillText(text, -(this.canvasContext.measureText(text).width + x), -(coordinates.startPoint.y - margin));

      this.canvasContext.restore();
    } else {
     if (!typeX) {
        this.canvasContext.save();
        this.canvasContext.translate(coordinates.startPoint.x, coordinates.startPoint.y);
        this.canvasContext.rotate(90 * Math.PI / 180);

        this.canvasContext.fillText(text,
          ((coordinates.finishPoint.y - coordinates.startPoint.y) / 2) - this.canvasContext.measureText(text).width / 2,
          margin);

        this.canvasContext.restore();
     } else {
      this.canvasContext.fillText(text, (coordinates.finishPoint.x - (coordinates.finishPoint.x - coordinates.startPoint.x) / 2)
        - this.canvasContext.measureText(text).width / 2, coordinates.finishPoint.y + margin);
     }
    }
    this.canvasContext.closePath();
  }
}
