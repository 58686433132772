import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IStation} from '../../../core/models/stations';

export const enum BaseNameActionTypes {
  SET_BASE_NAME = '[Config] SET_BASE_NAME',
  SAVE_BASE_NAME = '[Config] SAVE_BASE_NAME',
}

export function setBaseName(name: string): IActionWithPayload {
  return { type: BaseNameActionTypes.SET_BASE_NAME, payload: name };
}

export function saveBaseName(station: IStation): IActionWithPayload {
  return { type: BaseNameActionTypes.SAVE_BASE_NAME, payload: station };
}

