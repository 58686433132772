import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Chart } from 'angular-highcharts';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ChartTranslationsService } from '../../../../core/services/chart-translations.service';
import { ObjectTranslator } from '../../../../services/translation/object-translator.service';
import { setZoomTitle } from '../../../../shared/utils/setZoomTitle';
import { setCameraDataIsChartExportInProgress } from '../../actions/camera-data';
import { ICameraDataState } from '../../models/camera-data';
import { selectCameraDataIsChartExportInProgress } from '../../reducers';

@Component({
  selector: 'app-camera-data-chart',
  templateUrl: './camera-data-chart.component.html',
  styleUrls: ['./camera-data-chart.component.scss']
})
export class CameraDataChartComponent implements OnChanges, OnDestroy {
  @Input()
  public chartOptions: any;
  @Output()
  public changedNodeVisibility: EventEmitter<any> = new EventEmitter<any>();

  public chart: Chart;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private cameraDataStore: Store<ICameraDataState>,
              private translateObj: ObjectTranslator,
              private chartTranslationsService: ChartTranslationsService) {
    this.chartTranslationsService.translateShortMonths();
    this.chartTranslationsService.translateWeekdays();
  }

  public ngOnChanges(): void {
    if (!this.chartOptions) {
      return;
    }
    this.createChart();
    this.listenToExport();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private createChart(): void {
    this.translateObj.translate(this.chartOptions);
    setTimeout(() => {
      this.chart = new Chart(this.chartOptions);
      this.chart.options.plotOptions = {
        series: {
          events: {
            legendItemClick: (event: any): void => {
              this.changedNodeVisibility.emit(event.target.options);
            }
          }
        }
      };
      setZoomTitle(this.chart);
    }, 1000);
  }

  private listenToExport(): void {
    this.cameraDataStore.pipe(
      select(selectCameraDataIsChartExportInProgress),
      takeUntil(this.destroy$),
      filter((status: boolean): boolean => status)
    ).subscribe((): void => {
      this.chart.ref.exportChart();
      this.cameraDataStore.dispatch(setCameraDataIsChartExportInProgress(false));
    });
  }
}
