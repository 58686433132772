<p>{{ 'Biomass Viewer is the quantification of LAI index (Leaf Area Index) = canopy area per unit of horizontal ground area.' | translate }}</p>
<p>{{ 'The Biomass LAI can vary on a scale from 0 (bare soil) to 3-4 (highest LAI for tomatoes or wheat), to 5-6 (highest LAI for maize, soybeans), including other crops. Each crop will have an expected LAI.' | translate }}</p> 
<p>{{ 'The fitting curve in the graph will show the Maximum, Mean, and Minimum Biomass throughout the cultivation period.' | translate }}</p>
<p>{{ '10 m resolution satellite data at any given time of the year. In addition, options to: identify good and problematic areas in the field, growth stage, weather effect, and long-term yield potential.' | translate }}</p>
<p>{{ 'Please note that any \'non-field\' pieces inside the cropzone boundaries - such as buildings or forests - can affect the overall calculation of the LAI fitting curve. We recommend splitting up a cropzone polygon under smaller pieces of cropzones, to avoid any type of blockage.' }}</p>
<p>{{ 'Due to the amount of cloud cover in this cropzone region, the given frequency of satellite imagery can be reduced.' | translate }}</p>
<p>{{ 'Cloud status = graphic indication of cloudy days above the 20%-cloud threshold. Anything above 20% interferes in data collection and reduces the number of data points in the graph. The orange triangle is a mere indicator of the exact date and does not have an associated value to it.' | translate }}</p>
<p>{{ '1) Raw mean curve: mean values obtained from the mean of all pixels inside the cropzone boundary.' | translate }}</p> 
<p>{{ '2) Fitted curve: a calculation on top of the mean data points to derive a curve that best indicates the trend of the given set of data.' | translate }}</p>
<p>{{ '3) Maximum values: max values obtained from all pixels inside the cropzone boundaries.' | translate }}</p>
<p>{{ '4) Minimum values: min values obtained from all pixels inside the cropzone boundaries.' | translate }}</p>
<p>{{ '5) Cloud-status tool: cloudy days, with cloud coverage above 20% threshold are signalized on the graph. When cloud conditions reach numbers higher than the 20% threshold, data cannot be captured from satellite remote sensing because of imagery blockage.' | translate }}</p>