<div class="api-key-modal">
  <p>
    {{ 'As a user you have the possibility to access your data directly using the HTTP protocol. Besides OAuth 2.0, ' +
  'we offer the user-specific Keyed-Hash Message Authentication Code (HMAC) approach which requires you to use a ' +
  'public and private key pair to be able to set the authorization field in the HTTP header appropriately as ' +
  'described in the FieldClimate API documentation.' | translate }}
  </p>
  <p>
    {{ 'Click once on GENERATE NEW to create your public and private key pair which will be automatically added to ' +
  'your FieldClimate profile, then copy and use them in your software.' | translate }}
  </p>
  <p>
    {{ 'The FieldClimate API documentation reference:' | translate }}
    <a href="https://docsdev.fieldclimate.com/#intro" target="_blank">https://docsdev.fieldclimate.com/#intro</a>.
  </p>
</div>
