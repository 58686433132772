import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, takeUntil } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { IOptions } from '../../../../shared/interfaces';
import { getStationCommunication, setStationCommunicationError, setStationCommunicationLoading } from '../../actions/station-communication';
import { COMMUNICATION_MODES } from '../../constants/constants';
import { IGetStationCommunicationRequest, IStationCommunication } from '../../models/station-communication.models';
import { selectStationCommunication, selectStationCommunicationError, selectStationCommunicationLoading } from '../../reducers';
import { ICommunicationByStation, IStationCommunicationState } from '../../reducers/station-communication';

import { debounceTime } from 'rxjs/operators';
import { formatDate } from '../../../../shared/utils/dateFormat';

@Component({
  selector: 'app-station-communication-content',
  templateUrl: './station-communication-content.component.html',
  styleUrls: ['./station-communication-content.component.scss']
})
export class StationCommunicationContentComponent implements OnInit, OnDestroy {
  public destroy$               : Subject<boolean> = new Subject<boolean>();
  public stationCommunication   : Array<IStationCommunication> = [];
  public modeOptions            : Array<IOptions> = COMMUNICATION_MODES;
  public modeControl            : FormControl = new FormControl(this.modeOptions[0].value);

  public isLoading$             : Observable<boolean> = this.stationCommunicationStore.pipe(
    takeUntil(this.destroy$),
    select(selectStationCommunicationLoading)
  );
  public isError$               : Observable<boolean> = this.stationCommunicationStore.pipe(
    takeUntil(this.destroy$),
    select(selectStationCommunicationError)
  );
  private navigationStation$    : Observable<IStation> = this.selectedNavigationStore.pipe(
    select(selectNavigationStation),
    filter((station: IStation): boolean => !!station),
  );
  private stationCommunication$  : Observable<ICommunicationByStation> = this.stationCommunicationStore.pipe(
    select(selectStationCommunication),
  );

  private modeControl$           : Observable<string> = this.modeControl.valueChanges.pipe(
    startWith(this.modeControl.value),
    takeUntil(this.destroy$),
    distinctUntilChanged(),
    debounceTime(400),
  );

  private state$ = combineLatest([
    this.navigationStation$,
    this.stationCommunication$,
    this.modeControl$
  ]).pipe(
    map(([station, communicationByStation, mode]: [IStation, ICommunicationByStation, string]) => {
      return [station.name.original, communicationByStation, mode];
    }),
    distinctUntilChanged(),
    takeUntil(this.destroy$),
  );

  constructor(
    private selectedNavigationStore: Store<INavigationStationState>,
    private stationCommunicationStore: Store<IStationCommunicationState>
  ) { }

  public ngOnInit(): void {
    this.state$.subscribe(([stationId, communicationByStation, mode]: [string, ICommunicationByStation, string]): void => {
      if (communicationByStation[stationId] && communicationByStation[stationId][this.modeControl.value]) {
        this.stationCommunication = communicationByStation[stationId][mode];
        this.stationCommunicationStore.dispatch(setStationCommunicationError(!this.stationCommunication.length));
      } else {
        this.dispatchGetStationCommunication({
          stationId: stationId,
          mode: String(this.modeControl.value)
        });
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public getSelectedMode(): string {
    const selectedOption = this.modeOptions.find((option: IOptions): boolean => option.value === this.modeControl.value);
    return selectedOption ? selectedOption.content : '';
  }

  public getFormattedDate(date: Date): string {
    return formatDate(date);
  }

  public getDateFromTimestamp(timestamp: any): string {
    return timestamp ? formatDate(new Date(Number(timestamp))) : (timestamp === null) ? 'NULL' : timestamp;
  }

  public getSizeInKb(size: number): string {
    return (size / 1024).toFixed(2) + ' kbytes';
  }

  private dispatchGetStationCommunication(request: IGetStationCommunicationRequest): void {
    this.stationCommunicationStore.dispatch(setStationCommunicationError(false));
    this.stationCommunicationStore.dispatch(setStationCommunicationLoading(true));
    this.stationCommunicationStore.dispatch(getStationCommunication(request));
  }
}
