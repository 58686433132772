import {ICameraDataState} from '../models/camera-data';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {CameraDataActionTypes} from '../actions/camera-data';
import {DefaultPeriodScope} from '../../../core/constants/camera';

const initialState: ICameraDataState = {
  settings: {
    isLastDataMode: true,
    periodScope: DefaultPeriodScope.value,
    fromTo: null,
    datepicker: null,
    datepickerStart: null,
    datepickerEnd: null
  },
  activity: {
    isChartExportInProgress: false,
    isTableExportInProgress: false,
    isHelpActive: false,
    isChartActive: true,
    isTableActive: true,
  },
  currentDateString: null,
  iscoutReportData: [],
  iscoutReportDataGlueBoard: null,
  iscoutReportDataSeason: null,
  cropViewReportData: [],
};

export function reducer(state: ICameraDataState = initialState, action: IActionWithPayload): ICameraDataState {
  switch (action.type) {
    case CameraDataActionTypes.SET_CAMERA_DATA_IS_CHART_EXPORT_IN_PROGRESS:
      return <ICameraDataState>{
        ...state,
        activity: {
          ...state.activity,
          isChartExportInProgress: action.payload
        }
      };
    case CameraDataActionTypes.SET_CAMERA_DATA_IS_TABLE_EXPORT_IN_PROGRESS:
      return <ICameraDataState>{
        ...state,
        activity: {
          ...state.activity,
          isTableExportInProgress: action.payload
        }
      };
    case CameraDataActionTypes.SET_CAMERA_DATA_IS_HELP_ACTIVE:
      return <ICameraDataState>{
        ...state,
        activity: {
          ...state.activity,
          isHelpActive: action.payload
        }
      };
    case CameraDataActionTypes.SET_CAMERA_DATA_IS_CHART_ACTIVE:
      return <ICameraDataState>{
        ...state,
        activity: {
          ...state.activity,
          isChartActive: action.payload
        }
      };
    case CameraDataActionTypes.SET_CAMERA_DATA_IS_TABLE_ACTIVE:
      return <ICameraDataState>{
        ...state,
        activity: {
          ...state.activity,
          isTableActive: action.payload
        }
      };
    case CameraDataActionTypes.SET_CAMERA_DATA_SETTINGS:
      return <ICameraDataState>{
        ...state,
        settings: action.payload
      };
    case CameraDataActionTypes.SET_CAMERA_DATA_CURRENT_DATE_STRING:
      return <ICameraDataState>{
        ...state,
        currentDateString: action.payload
      };
    case CameraDataActionTypes.SET_CAMERA_DATA_FROM_ISCOUT:
      return <ICameraDataState>{
        ...state,
        iscoutReportData: action.payload
      };
    case CameraDataActionTypes.SET_CAMERA_DATA_FROM_CROPVIEW:
      return <ICameraDataState>{
        ...state,
        cropViewReportData: action.payload
      };
    case CameraDataActionTypes.SET_CAMERA_GLUE_BOARD_DATA_FROM_ISCOUT:
      return <ICameraDataState>{
        ...state,
        iscoutReportDataGlueBoard: action.payload
      };
    case CameraDataActionTypes.SET_CAMERA_SEASON_DATA_FROM_ISCOUT:
      return <ICameraDataState>{
        ...state,
        iscoutReportDataSeason: action.payload
      };
    default:
      return state;
  }
}
