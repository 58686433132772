import * as fromDashboard from './dashboard';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export interface IState {
  dashboardData: fromDashboard.IDashboard;
}

export const reducers = {
  dashboardData: fromDashboard.reducer
};

export const personalDashboard = createFeatureSelector<IState>('dashboard');

export const selectDashboard = createSelector(
  personalDashboard,
  (dashboard: IState) => dashboard.dashboardData.dashboard
);

export const selectEdit = createSelector(
  personalDashboard,
  (dashboard: IState) => dashboard.dashboardData.isEdit
);

export const selectChanged = createSelector(
  personalDashboard,
  (dashboard: IState) => dashboard.dashboardData.isChanged
);

export const selectName = createSelector(
  personalDashboard,
  (dashboard: IState) => dashboard.dashboardData.name
);

export const selectSiblings = createSelector(
  personalDashboard,
  (dashboard: IState) => dashboard.dashboardData.siblings
);

export const selectMain = createSelector(
  personalDashboard,
  (dashboard: IState) => dashboard.dashboardData.isMain
);

export const selectId = createSelector(
  personalDashboard,
  (dashboard: IState) => dashboard.dashboardData.id
);

export const selectSubdomain = createSelector(
  personalDashboard,
  (dashboard: IState) => dashboard.dashboardData.subdomain
);

export const selectWiddgetLoadDataByPath = (rowIndex: number, columnIndex: number, widgetIndex: number) => createSelector(
  personalDashboard,
  (dashboard: IState) => dashboard.dashboardData.dashboard[rowIndex][columnIndex].items[widgetIndex].loadData
);

