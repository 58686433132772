import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { setUserNotifications } from '../../../../core/actions/notifications';
import { IAccount } from '../../../../core/reducers/account';
import { cardAnimation } from '../../../../shared/animations/card-animations';
import { emptyNotification } from './../../../../core/constants/notifications';
import { INotification } from './../../../../core/models/notifications';
import { selectNotifications, selectSelectedNotification } from './../../../../core/reducers/index';
import { IOptions } from './../../../../shared/interfaces';
import { notificationPeriodOptions } from './../../constants/notifications-history';
import { deepClone } from '../../../../shared/utils/deepClone';

@Component({
  selector: 'app-notifications-history-list',
  templateUrl: './notifications-history-list.component.html',
  styleUrls: ['./notifications-history-list.component.scss'],
  animations: [cardAnimation()]
})

export class NotificationsHistoryListComponent implements OnInit, OnDestroy {
  public obs = new Subject();
  public obs$ = this.obs.asObservable();
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public notifications$: Observable<INotification[]>;
  public notificationList$: Observable<any>;
  public itemLimit: number = 15;
  public page: number = 1;
  public periodOptions: IOptions[] = notificationPeriodOptions;
  public form: FormGroup;
  public notifications: any;
  public emptyNotification = emptyNotification;

  @HostListener('click', ['$event'])
  public clickHandler(event): void {
    this.obs.next(event);
  }

  constructor(
    private accountStore: Store<IAccount>,
    private fb: FormBuilder,
    private router: Router
  ) { }

  public get periodControl(): AbstractControl {
    return this.form.get('period');
  }

  public ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
    this.form = this.fb.group({
      'period': ['all', [Validators.required]]
    });

    this.notifications$ = this.accountStore.pipe(
      select(selectNotifications),
      filter((notifications: any) => JSON.stringify(notifications[0]) !== JSON.stringify(this.emptyNotification)),
      takeUntil(this.destroy$),
      map(notifications => notifications.sort((a, b) => new Date(moment(b.time).format('YYYY-MM-DD HH:mm:ss')).getTime() -
        new Date(moment(a.time).format('YYYY-MM-DD HH:mm:ss')).getTime()))
    );

    this.notificationList$ = combineLatest([
      this.notifications$,
      this.periodControl.valueChanges.pipe(
        startWith('all')
      )
    ]).pipe(
      takeUntil(this.destroy$),
      map(([notifications, period]) => {
        this.notifications = notifications;

        switch (period) {
          case 'all': {
            notifications.forEach(notification => {
              notification.time = moment(notification.time).format('YYYY-MM-DD HH:mm:ss');
            });
            if (notifications.filter(item => !item.seen).length > 0) {
              const unseenNotifications = notifications.filter(item => !item.seen);
              const seenNotifications = notifications.filter(item => item.seen);
              return unseenNotifications.concat(seenNotifications);
            }
            return notifications;
          }
          case '15d': {
            const now = moment().toDate();
            const startDate = moment(now).subtract(15, 'd').toDate();

            const filteredNotifications = notifications.filter((notification) => {
              const notificationTime = new Date(notification.time).getTime();
              return notificationTime >= startDate.getTime() && notificationTime <= now.getTime();
            });

            if (filteredNotifications.filter(item => !item.seen).length > 0) {
              const unseenNotifications = filteredNotifications.filter(item => !item.seen);
              const seenNotifications = filteredNotifications.filter(item => item.seen);
              return unseenNotifications.concat(seenNotifications);
            }
            return filteredNotifications;
          }
          default: {
            return [];
          }
        }
      })
    );

    this.accountStore.pipe(
      select(selectSelectedNotification),
      filter(notification => !!notification),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  public navigateToUrl(item): void {
    this.router.navigate([`/station/${item.stationID}/data`]);
  }

  public markAsRead(item2: any): void {
    const notifications = deepClone(this.notifications);
    for (let i = 0; i < notifications.length; i++) {
      if (notifications[i].body === item2.body) {
        notifications[i] = { ...notifications[i], seen: true };
        break;
      }
    }
    this.accountStore.dispatch(setUserNotifications(notifications));
  }

  public markAllRead(): void {
    const cloneNotifications = JSON.parse(JSON.stringify(this.notifications));
    cloneNotifications.forEach((notification: INotification, index: number) => {
      cloneNotifications[index].seen = true;
    });

    this.accountStore.dispatch(setUserNotifications(cloneNotifications));
  }

  public getData(notifications: INotification[]): INotification[] {
    if (!notifications) {
      return [];
    }
    return notifications.slice((this.page - 1) * this.itemLimit, this.page * this.itemLimit);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
