<div class="modal-container">
  <div class="modal-header">
    <button aria-label="Close" class="close" type="button" (click)="handleCancel()">
      <span aria-hidden="true">×</span>
    </button>

    <h3 class="modal-title text-center">{{ 'Reset precipitation data' | translate }}</h3>
  </div>

  <div class="modal-body">
    <div class="body-text">
      {{"You are about to reset precipitation data to 0 mm from " | translate}}
      <b>{{ dateRange.fromDate | date: 'dd/MM/yyyy HH:mm' }}</b>
      {{"to" | translate}}
      <b>{{ dateRange.toDate | date: 'dd/MM/yyyy HH:mm' }}</b>
    </div>

    <div class="button-wrapper">
      <div class="button">
        <app-custom-button [color]="'blue'" [content]="'Cancel' | translate" (click)="handleCancel()">
        </app-custom-button>
      </div>

      <div class="button">
        <app-custom-button [color]="'red'" [content]="'Reset' | translate" (click)="handleConfirm()">
          <i class="fa fa-fw fa-eraser"></i>
        </app-custom-button>
      </div>
    </div>
  </div>
</div>