<div class="dropdown-container"
     appOutClick
     (outClick)="close()">
  <app-custom-button
    [ngStyle]="customCSS2"
    [caret]="true"
    [content]="(isLoading ? errorString : (currentItem?.value || buttonString)) | translate"
    (click)="!isDisabled && toggleOpen()"
    [color]="color"
    [disabled]="isLoading"
    [customCSS]="customCSS"
    [shadow]="shadow"
  ></app-custom-button>
  <div class="custom-dropdown" *ngIf="isOpen" [ngStyle]="customCSS2">
    <div class="custom-dropdown-title">
      <span>
        {{ title | translate }}
      </span>
    </div>

    <div class="input-container" *ngIf="isOpen && searchable && items?.length > 5" (click)="stopPropagation($event)">
      <input 
        type="text"
        (input)="filterItems()"
        (click)="stopPropagation($event)"
        [(ngModel)]="searchTerm"
        class="form-control"
        [attr.placeholder]="placeholder | translate"
        (keydown.escape)="resetDropdown()">
      <i class="zmdi zmdi-search"></i>
    </div>

    <div class="custom-dropdown-data">
      <ng-container *ngIf="!isLoading">
        <p *ngFor="let item of filteredItems" (click)="onChange(item)">
          <span class="fa fa-check check"
          *ngIf="currentItem && item.content === currentItem.content"></span>
          {{ item.value | translate }}
        </p>
      </ng-container>
    </div>
  </div>
</div>

