<app-form-layout class="warnings-phones">
  <app-card-header [helpButton]="true"
                   [header]="'Phone numbers'"
                   [description]="'Set up phone numbers for sms warning'"
                   (openModal)="openModal()"></app-card-header>
  <div class="warnings-phones__content row">
    <app-warnings-phone-form class="col-md-6"
                             [phones]="warningsPhones.phones"></app-warnings-phone-form>
    <app-warnings-phone-list class="col-md-6"
                             [phones]="warningsPhones.phones"></app-warnings-phone-list>
    <app-warnings-phone-save  class="col-md-12"
                              (save)="save()"></app-warnings-phone-save>
    <div class="warnings-phones__clear"></div>
  </div>
</app-form-layout>


<app-info-modal [modalId]="modalId"
                [header]="'Phone numbers'">
  <app-warnings-phone-modal></app-warnings-phone-modal>
</app-info-modal>
