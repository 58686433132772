import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-yes-no-toggle',
  templateUrl: './yes-no-toggle.component.html',
  styleUrls: ['./yes-no.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => YesNoToggleComponent),
    multi: true
  }]
})
export class YesNoToggleComponent implements ControlValueAccessor {
  @Input()
  public yes                  : string = 'Yes';
  @Input()
  public no                   : string = 'No';
  @Input()
  public noTab                : boolean = false;
  @Input()
  public small                : boolean = false;

  public value                : boolean;

  @Output()
  public change: EventEmitter<boolean> = new EventEmitter<boolean>();

  private propagateChange     : any = () => { return; };

  public onChange(value: boolean): void {
    if (value !== this.value && this.noTab) {
      this.change.emit(value);
    }
    this.value = value;
    this.propagateChange(this.value);
  }

  public writeValue(value: boolean): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    return;
  }

  public setDisabledState(isDisabled: boolean): void {
    return;
  }

}
