import { IChartLegendItem, IChartTooltipItem } from '../../../shared/interfaces';

export const SERVICE_HELP_CONTENT: { [id: string]: string } = {
    'dairy-stress': 'Periods with heat and high relative humidity will reduce the milk production and can even harm dairy cows. If it ' +
        'becomes too hot and too humid, cows should be held in shade or cooled by sprinklers. Stress starts slightly with 24 degree ' +
        'Celsius and more than 65% relative humidity. It will increase to 33 degree Celsius and 100 % relative humidity or 36 degree ' +
        'Celsius and 50% relative humidity. At 40 degree Celsius and high relative humidity we have to expect severe health problems ' +
        'for the cows.',
    'poultry-stress': 'Poultry can not sweat. They regulate their temperature through their skin but when it is too hot and too humid it ' +
        'is not enough so they have to do it with ventilation through their beaks and tongue. If it is too hot or too humid (or both) ' +
        'they will spend too much energy for cooling. If the heat and relative humidity stress gets higher they can be harmed and even ' +
        'killed by this. The index indicating the heat stress of poultries is called enthalpy. The stress indication is using a ' +
        'simplified calculation of the enthalpy.'
};

export const SERVICE_CHART_LEGEND: { [id: string]: IChartLegendItem[] } = {
    'dairy-stress': [
        { color: '#458248', label: 'No stress' },
        { color: '#8FBD68', label: 'Low stress' },
        { color: '#E0CF87', label: 'Medium stress' },
        { color: '#BF5D5C', label: 'High stress' },
        { color: '#AB2424', label: 'Dead cow' }
    ],
    'poultry-stress': [
        { color: '#458248', label: 'No stress' },
        { color: '#8FBD68', label: 'Low stress' },
        { color: '#E0CF87', label: 'Medium stress' },
        { color: '#BF5D5C', label: 'High stress' },
        { color: '#AB2424', label: 'Dead chicken' }
    ]
};

export const SERVICE_CHART_TOOLTIP_PROPERTIES: { [id: string]: IChartTooltipItem[] } = {
    'dairy-stress': [
        { prop: 'temp', name: 'Temperature' },
        { prop: 'hum', name: 'Relative humidity' },
        { prop: 'realValue', name: 'Value' }
    ],
    'poultry-stress': [
        { prop: 'temp', name: 'Temperature' },
        { prop: 'hum', name: 'Relative humidity' },
        { prop: 'realValue', name: 'Value' }
    ]
};
