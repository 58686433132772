import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class PictocodeService {

  constructor() { }

  public addTooltip(charts: Array<any>): Array<any> {
    charts.forEach((chart, i) => {
      if (chart.sources.includes('forecast')) {
        charts[i].tooltip = {
          useHTML: true,
          shared: true,
          formatter: function(): string | boolean {
            let str = '';
            const date = moment.utc(this.points[0].x).format('Y-MM-DD HH:mm');
            str += `<small>${date}</small><br>`;
            // show tooltip only if value
            this.points.forEach((point) => {
              if (point.y !== null) {
                let serieName = point.series.name;
                let unit = '';
                if (!serieName.includes('Pictocode')) {                 // remove pictocode serie from tooltip
                  const splittedSerieName = serieName.split(' [');      // split the serie name into name and unit
                  serieName = splittedSerieName[0];
                  if (splittedSerieName.length > 1) {                   // if there is an unit in serie name
                    unit = splittedSerieName[1].slice(0, -1);
                  }
                  str += `<li style='color:${point.color}'>`;                                         // bullet
                  str += `<span style='color:black; margin-left:-8px'>${serieName}: <b>${point.y} `;  // serie name and value
                  str += `${unit}</b></span></li>`;                                                   // unit
                }
              } else {
                str = '';
              }
            });
            return str !== '' ? str : false;                                         // show tooltip if value, otherwise don't show it
          }
        };
      }
    });

    return charts;
  }

  public setBiggerIcons(charts: Array<any>): Array<any> {
    charts.forEach((chart, chartIndex) => {
      chart.series.forEach((serie, serieIndex) => {
        if (serie.name === 'Pictocode' || serie.name === 'Pictocode forecast') {
          serie.data.forEach((record, i) => {
            charts[chartIndex].series[serieIndex].data[i].marker = {
              symbol: charts[chartIndex].series[serieIndex].data[i].marker.symbol,
              width: 40,
              height: 40
            };
          });
        }
      });
    });

    return charts;
  }

}
