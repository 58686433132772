<section class="widget-container">
  <app-chart-header [color]="widget.color"
                    [title]="widget.title"
                    [description]="widget.description"
                    [isEditable]="true"
                    [isDraggable]="isEdit$ | async"
                    [isClose]="isEdit$ | async"
                    (remove) = "removeCurrentWidget()"
                    (editMode)="editMode(!widget.settings.tracker)"></app-chart-header>

  <div class="settings" *ngIf="widget.settings.tracker">
    <app-track-widget-settings [trackerViewRequest]="widget.requests.tracker"
                               (updateWidgetData)="loadTrackerViewData($event)"
                               (updateRequest)="updateRequest($event)">
    </app-track-widget-settings>
  </div>

  <app-tracker-component [widget]="widget" [trackerData]="trackerData"></app-tracker-component>

</section>
