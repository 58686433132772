import {AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output} from '@angular/core';
import { of } from 'rxjs';
import { filter, takeWhile } from 'rxjs/operators';

@Directive({
  selector: '[appBackgroundLoad]'
})
export class BackgroundLoadDirective implements AfterViewInit, OnDestroy {
  @Output()
  private backLoaded           : EventEmitter<boolean> = new EventEmitter<boolean>(true);
  private alive                : boolean = true;

  constructor(private el: ElementRef) {
  }

  public ngAfterViewInit(): void {
    of(window.getComputedStyle(this.el.nativeElement).backgroundImage).pipe(
      takeWhile(() => this.alive),
      filter((style) => Boolean(style))
    ).subscribe(() => {
        this.backLoaded.emit(true);
    });
  }

  public ngOnDestroy(): void {
    this.alive = false;
  }

}
