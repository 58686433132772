import {Injectable} from '@angular/core';
import {ApiCallService} from '../../../services/api/api-call.service';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {from} from 'rxjs';
import {
  CameraSlideshowActions,
  setCameraSlideshowError,
  setCameraSlideshowLastDate,
  setCameraSlideshowLoading,
  setCameraSlideshowPhotos
} from '../actions/camera-slideshow';
import {catchError, switchMap} from 'rxjs/operators';
import {
  IGetPhotosRequest,
  IGetPhotosRequestFromTo,
  IPicture,
  PhotoRequestType
} from '../../../shared/camera/models/camera';
import * as moment from 'moment';

@Injectable()
export class CameraSlideshowService {
  constructor(private api: ApiCallService,
              private actions$: Actions) { }

  @Effect()
  public getCameraSlideshowLastDate$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CameraSlideshowActions.GET_CAMERA_SLIDESHOW_LAST_DATE),
    switchMap((action: IActionWithPayload) => {
      const request: IGetPhotosRequest = {
        type: PhotoRequestType.SINGLE_DATE_INTERVAL,
        stationId: action.payload,
        date: 'last/1/1'
      };
      return this.api.getCameraPhotos(request).pipe(
          switchMap((apiResponse: {[index: number]: IPicture}) => from([
            setCameraSlideshowLastDate(moment(apiResponse[0].time))])
          ),
          catchError(() => this.unsuccessfulApiCall())
        );
      }
    )
  );

  @Effect()
  public getCameraSlideshowPhotos$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CameraSlideshowActions.GET_CAMERA_SLIDESHOW_PHOTOS),
    switchMap((action: IActionWithPayload) => this.requestPhotos(action.payload)
    )
  );

  private requestPhotos(request: IGetPhotosRequestFromTo): Observable<IActionWithPayload> {
    return this.api.getCameraPhotosFromTo(request).pipe(
      switchMap((apiResponse: Array<IPicture>) => from([
        setCameraSlideshowPhotos(apiResponse),
        setCameraSlideshowLoading(false)
      ])),
      catchError(() => this.unsuccessfulApiCall())
    );
  }

  private unsuccessfulApiCall(): Observable<IActionWithPayload> {
    return from([
      setCameraSlideshowError(true),
      setCameraSlideshowLoading(false)
    ]);
  }
}
