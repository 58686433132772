import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { IrrimetConfigActionTypes } from '../actions/irrimet-config.action';

export interface IDataSourcesState {
  datasourcesRain: Array<any>;
  datasourcesETO: Array<any>;
  cropsLazyLoading: Array<any>;
  collectConfigData: boolean;
}

export const initialState: IDataSourcesState = {
  datasourcesETO: [],
  datasourcesRain: [],
  cropsLazyLoading: [],
  collectConfigData: false
};

export function reducer(state: IDataSourcesState = initialState, action: IActionWithPayload): IDataSourcesState {
  switch (action.type) {
    case IrrimetConfigActionTypes.SET_DATASOURCES_ETO:
      return <IDataSourcesState>{
        ...state,
        datasourcesETO: action.payload
      };
    case IrrimetConfigActionTypes.SET_DATASOURCES_RAIN:
      action.payload.unshift({content: 'Only manual rain gauge', value: 'OMRG'});

      return <IDataSourcesState>{
        ...state,
        datasourcesRain: action.payload
      };
    case IrrimetConfigActionTypes.SET_CROPS_LAZY_LOADING:
      return <IDataSourcesState>{
        ...state,
        cropsLazyLoading: action.payload
      };
    case IrrimetConfigActionTypes.COLLECT_CONFIG_DATA:
      return <IDataSourcesState>{
        ...state,
        collectConfigData: action.payload
      };
    default:
      return state;
  }
}
