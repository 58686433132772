<div class="custom-modal"
     *ngIf="isOpen"
     [@modalCardAnimation]>
  <div class="custom-modal__content"
       [ngStyle]="styles"
       appOutClick
       (outClick)="closeModal()">

    <div class="custom-modal__header">
      <button class="close custom-modal__header__close"
              (click)="closeModal()"><span>×</span></button>
      <h3 [style.font-size.px]="headSize">{{ header | translate}}</h3>
    </div>

    <div class="custom-modal__body">
      <ng-content></ng-content>
    </div>

    <div class="custom-modal__footer"
         *ngIf="!hideFooter">
      <button class="custom-modal__close"
              (click)="closeModal()">{{ 'Close' | translate }}</button>
    </div>
  </div>
</div>

<div class="custom-modal-background"
     [@modalBackAnimation]
     *ngIf="isOpen"></div>
