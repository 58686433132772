<div class="camera-settings-form" *ngIf="form">
  <div class="camera-settings-form__head">
    <h4>{{ 'Lens' | translate }} {{camIndex}}</h4>
    <div style="margin-top: 8px;">
      <label class="toggle">
        <input type="checkbox" [checked]="active.value" [formControl]="active">
        <span class="slider"></span>
        <span class="labels" data-on="Enabled" data-off="Disabled"></span>
      </label>
    </div>
  </div>

  <h5>{{ 'Camera exposure' | translate }}</h5>

  <div class="camera-settings-form__options">
    <div class="camera-settings-form__options__item">
      <label class="camera-settings-form__label">{{ 'Brightness reference' | translate }}</label>
      <app-form-input [type]="'border'"
                      [formControl]="brightness"></app-form-input>
    </div>
  </div>
</div>

<app-info-modal [modalId]="modalId"
                [styles]="{'max-width': '300px'}"
                [hideFooter]="true"
                [header]="'Turn camera ' + camIndex + ' off?'">
</app-info-modal>
