import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RegisterRoutingModule} from './register-routing.module';
import {AuthModule} from '../../auth.module';
import {RegisterComponent} from './register.component';
import {RecaptchaModule} from 'ng-recaptcha';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    RegisterRoutingModule,
    AuthModule,
    RecaptchaModule,
    TranslateModule.forChild()
  ],
  declarations: [
    RegisterComponent
  ]
})
export class RegisterModule { }
