import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Options } from 'highcharts';
import { ASPARAGUS_TYPES } from '../../constants/constants';

@Component({
  selector: 'app-accumulator-tool-charts',
  templateUrl: './accumulator-tool-charts.component.html',
  styleUrls: ['./accumulator-tool-charts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccumulatorToolChartsComponent implements OnInit {
  @Input()
  public chartOptions       : Array<Options>;
  @Input()
  public chartData          : any;
  @Input()
  public response           : any = null;

  public types              : Array<string> = ASPARAGUS_TYPES;
  public noData             : boolean = false;

  constructor(private cdRef : ChangeDetectorRef) { }

  public ngOnInit(): void {
  }

  public hiddenSeries(noData: boolean): void {
    this.noData = noData;
  }

}
