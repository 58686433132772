import { IWarningMessage } from '../../work-planning/models/work-planning.models';
import { YPActionTypes } from '../actions';
import { IActionWithPayload } from './../../../core/models/actionWithPayload';

const initialState: IWarningMessage = {
    message: null,
    icon: false
};

export function reducer(state: IWarningMessage = initialState, action: IActionWithPayload): IWarningMessage {
    switch (action.type) {
        case YPActionTypes.SET_YP_WARNING_MESSAGE:
            return <IWarningMessage>{
                ...state,
                    message: action.payload.message,
                    icon: action.payload.icon
            };
        default:
            return state;
    }
}
