<div class="plotband-editor" [formGroup]="form">

  <div class="plotband-editor__header">{{ 'Set Budget Lines' | translate }}</div>
  <div class="plotband-editor__body">
    <div>
      <ng-container *ngIf="hasSensorTypes[SensorGroupType.VOLUMETRIC]">
        <div class="plotband-editor__subheader">{{ 'Volumetric Sensors' | translate }}</div>
        <div class="plotband-editor__group">
          <div>
            <label>{{ 'refill point' | translate }}</label>
            <app-form-input minValue="0" maxValue="100" [contentType]="'number'" formControlName="volumetricRefillPoint"></app-form-input>
          </div>
          <div>
            <label>{{ 'full point' | translate }}</label>
            <app-form-input minValue="0" maxValue="100" [contentType]="'number'" formControlName="volumetricFullPoint"></app-form-input>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="hasSensorTypes[SensorGroupType.TENSIOMETRIC]">
        <div class="plotband-editor__subheader">{{ 'Tensiometric Sensors' | translate }}</div>
        <div class="plotband-editor__group">
          <div>
            <label>{{ 'full point' | translate }}</label>
            <app-form-input minValue="0" [contentType]="'number'" formControlName="tensiometricFullPoint"></app-form-input>
          </div>
          <div>
            <label>{{ 'refill point' | translate }}</label>
            <app-form-input minValue="0" [contentType]="'number'" formControlName="tensiometricRefillPoint"></app-form-input>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="plotband-editor__footer" *ngIf="status$ | async as status">
    <span>{{ 'Budget Lines' | translate }}:</span>
    <app-custom-dropdown [options]="view ? PlotbandModeLabels[view.type] : []" formControlName="mode"></app-custom-dropdown>
    <span style="flex: 1 1 auto;"></span>
    <app-custom-button (click)="onModalClosed()" [color]="'blue'" [content]="'Cancel' | translate"></app-custom-button>
    <app-custom-button (click)="reset()" [disabled]="!viewIsResetable() || status.isLoading || status.viewIsPending" [color]="'orange'" [content]="'Reset' | translate"></app-custom-button>
    <app-custom-button (click)="save()" [disabled]="!form.valid || status.isLoading || status.viewIsPending" [color]="'red'" [content]="'Save' | translate"></app-custom-button>
  </div>

</div>
