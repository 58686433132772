import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { IForecastWarningMessage, IWeatherForecastState } from './../models/models';
import { take } from 'rxjs/operators';
import { IProductLicense } from '../../../core/models/licenses';
import * as moment from 'moment';
import { IFromTo } from './../../station-data/models/station-data.models';
import { setForecastWarningMessage } from '../actions/weather-forecast';

@Injectable({
  providedIn: 'root'
})
export class ForecastWarningService {
  private pleaseNoteString: string;
  private daysToRenew: string;
  private licenseExpiredOn: string;
  private toRenew: string;

  constructor(
    private translate: TranslateService,
    private store: Store<IWeatherForecastState>
  ) {
    this.getTranslatedStrings();
  }

  public setForecastWarning(licenses: Array<IFromTo>): boolean {
    if (licenses?.length > 0) {
      const warningObject: IForecastWarningMessage = {
        message: null,
        icon: true
      };

      let validLicense: boolean = true;

      const maximumDates: Array<any> = licenses.map((license) => moment(license.to));
      const licenseEndDate: moment.Moment = moment.max(maximumDates);
      const now: moment.Moment = moment();
      const daysUntilEndLicense = licenseEndDate.diff(now, 'days');

      if (daysUntilEndLicense <= 0) {
        warningObject.message = this.licenseExpiredOn + ' ' + licenseEndDate.format('DD-MM-YYYY') + '. ' + this.toRenew;
        validLicense = false;
      } else if (daysUntilEndLicense <= 10) {
        warningObject.message = this.pleaseNoteString + ' ' + daysUntilEndLicense + ' ' + this.daysToRenew;
      }

      this.store.dispatch(setForecastWarningMessage(warningObject));
      return validLicense;
    } else {
      return false;
    }
  }

  private getTranslatedStrings(): void {
    this.translate.get('Your license will expire in').pipe(take(1)).subscribe((translatedText: string) => {
      this.pleaseNoteString = translatedText;
    });
    this.translate.get('days. To renew it, please contact your METOS salesperson.').pipe(take(1)).subscribe((translatedText: string) => {
      this.daysToRenew = translatedText;
    });
    this.translate.get('Your license has expired on').pipe(take(1)).subscribe((translatedText: string) => {
      this.licenseExpiredOn = translatedText;
    });
    this.translate.get('To renew it, please contact your METOS salesperson.').pipe(take(1)).subscribe((translatedText: string) => {
      this.toRenew = translatedText;
    });
  }
}
