import { IPosition } from '../../../core/models/stations';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { DefaultCropzonePosition } from '../constants/constants';
import { TimezoneAndLocationActionTypes } from '../actions/timezone-location';

export interface ITimezoneAndLocationState {
  position: IPosition;
  locations: any;
}

const initialState: ITimezoneAndLocationState = {
  position: {
    ...DefaultCropzonePosition
  },
  locations: null
};

export function reducer(state: ITimezoneAndLocationState = initialState, action: IActionWithPayload): ITimezoneAndLocationState {
  switch (action.type) {
    case TimezoneAndLocationActionTypes.SET_LOCATION_SETTINGS: {
      return {
        ...state,
        position: action.payload
      };
    }
    case TimezoneAndLocationActionTypes.SET_TIMEZONES: {
      return {
        ...state,
        locations: action.payload
      };
    }
    case TimezoneAndLocationActionTypes.SET_COORDINATES: {
      return {
        ...state,
        position: {
          ...state.position,
          geo: {
            coordinates: action.payload
          }
        }
      };
    }
    case TimezoneAndLocationActionTypes.SET_ELEVATION: {
      return {
        ...state,
        position: {
          ...state.position,
          altitude: action.payload
        }
      };
    }
    case TimezoneAndLocationActionTypes.SET_TIMEZONE: {
      return {
        ...state,
        position: {
          ...state.position,
          timezoneCode: action.payload
        }
      };
    }
    default: {
      return state;
    }
  }
}
