<app-chart-toolbar-layout>
  <form class="soil-moisture-toolbar" (keydown.enter)="$event.preventDefault()" [formGroup]="periodForm">

    <div class="soil-moisture-toolbar__left">
      <div class="soil-moisture-toolbar__toggle soil-moisture-toolbar__left__item">
        <app-custom-button [color]="isLastDataMode.value ? 'dark-green' : 'green'" appTooltip [tooltipPlacement]="'top'"
          [tooltipTitle]="'Last data mode' | translate" [shadow]="false" [radius]="'2px 0 0 2px'" (click)="setDateRange(); isLastDataMode.setValue(true)">
          <span class="fa fa-step-backward"></span>
        </app-custom-button>
        <app-custom-button [color]="!isLastDataMode.value ? 'dark-green' : 'green'" appTooltip [tooltipPlacement]="'top'"
          [tooltipTitle]="'From - To mode' | translate" [shadow]="false" [radius]="'0 2px 2px 0'" (click)="isLastDataMode.setValue(false)">
          <span class="fa fa-calendar"></span>
        </app-custom-button>
      </div>

      <ng-container *ngIf="!isLastDataMode.value">
        <app-custom-datepicker [isSmall]="true"
                               [minDate]="fromStation"
                               [hideTime]="hideTime"
                               [formControl]="fromDatepicker">
        </app-custom-datepicker>
        <app-custom-datepicker [isSmall]="true"
                               [maxDate]="toStation"
                               [hideTime]="hideTime"
                               class="soil-moisture-toolbar__left__item"
                               [formControl]="toDatepicker">
        </app-custom-datepicker>
      </ng-container>

      <app-custom-dropdown [options]="periodScopeSelectItems" class="soil-moisture-toolbar__left__item" [formControl]="periodScope"></app-custom-dropdown>


      <app-custom-dropdown [options]="periodValueSelectItems" [hidden]="!isLastDataMode.value" class="soil-moisture-toolbar__left__item"
        [formControl]="periodValue"></app-custom-dropdown>
      <app-date-range-arrow [formControl]="fromTo" (rangeClick)="refresh()" [hidden]="!isLastDataMode.value" [minDate]="fromStation"
        [maxDate]="toStation" [period]="periodValue.value">
      </app-date-range-arrow>
    </div>

    <div class="soil-moisture-toolbar__right">

      <div class="soil-moisture-toolbar__settings soil-moisture-toolbar__right__item">
        <app-custom-button
          *ngIf="hasVolumetricSensors$ | async"
          [color]="!isSensorSettingsActive.value ? 'blue' : 'dark-blue'"
          (click)="isSensorSettingsActive.setValue(!isSensorSettingsActive.value)"
          appTooltip [tooltipPlacement]="'top'"
          [tooltipTitle]="'Sensor settings' | translate">
          <i class="fa fa-gear"></i>
        </app-custom-button>

        <app-custom-button [color]="!isViewEditorActive.value ? 'blue' : 'dark-blue'"
          appTooltip [tooltipPlacement]="'top'" [tooltipTitle]="'Create or edit custom view' | translate" (click)="isViewEditorActive.setValue(!isViewEditorActive.value)">
          <i class="fa fa-edit"></i>
        </app-custom-button>
      </div>

      <div class="soil-moisture-toolbar__toggle
                  soil-moisture-toolbar__right__item
                  soil-moisture-toolbar__export"
        *ngIf="stationId.value">
        <app-custom-button [shadow]="false" (click)="startExportImage()" appTooltip [tooltipPlacement]="'top'"
          [tooltipTitle]="'Export charts as image' | translate" [color]="'white'">
          <i *ngIf="!isExportImageActive.value; else imgExport" class="glyphicon glyphicon-download-alt"></i>
          <ng-template #imgExport>
            <i class="fa fa-cog fa-spin fa-fw"></i>
          </ng-template>
        </app-custom-button>
        <app-custom-button [shadow]="false" (click)="startExportXLS()" appTooltip [tooltipPlacement]="'top'"
          [tooltipTitle]="'Export table as xls' | translate" [color]="'white'">
          <i class="fa fa-table"></i>
        </app-custom-button>
      </div>

      <div class="soil-moisture-toolbar__toggle soil-moisture-toolbar__right__item">
        <app-custom-button [color]="isChartActive.value ? 'green' : 'white'" appTooltip [tooltipPlacement]="'top'"
          [tooltipTitle]="'Toggle charts' | translate" [shadow]="false" (click)="isChartActive.setValue(!isChartActive.value)"
          [radius]="'2px 0 0 2px'">
          <span class="fa fa-bar-chart-o"></span>
        </app-custom-button>
        <app-custom-button [color]="isTableActive.value ? 'green' : 'white'" appTooltip [tooltipPlacement]="'top'"
          [tooltipTitle]="'Toggle table' | translate" [shadow]="false" (click)="isTableActive.setValue(!isTableActive.value)"
          [radius]="'0 2px 2px 0'">
          <span class="fa fa-table"></span>
        </app-custom-button>
      </div>

      <app-custom-button [color]="'red'" class="soil-moisture-toolbar__right__item" (click)="refresh()" [content]="'Refresh' | translate">
        <i class="fa fa-fw fa-refresh"></i>
      </app-custom-button>
    </div>

  </form>
</app-chart-toolbar-layout>
