import { Component, EventEmitter, forwardRef, Input, OnChanges, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { unitOfTime } from 'moment';
import { IDateRange } from '../interfaces';
import { dateTypeFromPeriod } from '../utils/dateTypeFromPeriod';

@Component({
  selector: 'app-date-range-arrow',
  templateUrl: './date-range-arrow.component.html',
  styleUrls: ['./date-range-arrow.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateRangeArrowComponent),
    multi: true
  }
  ]
})

export class DateRangeArrowComponent implements  ControlValueAccessor, OnChanges {
  @Input()
  public maxDate            : Date;
  @Input()
  public minDate            : Date;
  @Input()
  public period             : string;
  @Output()
  private rangeClick        : EventEmitter<void> = new EventEmitter<void>();

  public dateRange          : IDateRange;

  private dataType          : unitOfTime.DurationConstructor = 'day';
  private range             : number = 0;

  private propagateChange           : any = () => { return; };

  public ngOnChanges(): void {
    this.initPeriod();
  }

  private initPeriod(): void {
    if (!this.period) {
      return;
    }
    this.range = parseInt(this.period, 10);
    this.dataType = <unitOfTime.DurationConstructor>dateTypeFromPeriod(this.period);
    this.recalc();
  }

  private recalc(): void {
    if (!this.checkActive()) {
      return;
    }
    if (this.checkForward()) {
      this.setFullForward();
    } else {
      this.dateRange.from = moment(this.dateRange.to).subtract(this.range, this.dataType).toDate();
      if (this.checkBackward()) {
        this.setFullBackward();
      }
    }
    this.changeValue(false);
  }


  public forward(full: boolean): void {
    if (!this.checkActive() || this.checkForward()) {
      return;
    }
    if (full) {
      this.setFullForward();
    } else {
      this.dateRange.from = moment(this.dateRange.from).add(this.range, this.dataType).toDate();
      this.dateRange.to = moment(this.dateRange.to).add(this.range, this.dataType).toDate();
    }
    if (this.checkForward()) {
      this.setFullForward();
    }
    this.changeValue();
  }

  private setFullForward(): void {
    this.dateRange.to = this.maxDate;
    this.dateRange.from = moment(this.maxDate).subtract(this.range, this.dataType).toDate();
  }

  public backward(full: boolean): void {
    if (!this.checkActive() || this.checkBackward()) {
      return;
    }
    if (full) {
      this.setFullBackward();
    } else {
      this.dateRange.from = moment(this.dateRange.from).subtract(this.range, this.dataType).toDate();
      this.dateRange.to = moment(this.dateRange.to).subtract(this.range, this.dataType).toDate();
    }
    if (this.checkBackward()) {
      this.setFullBackward();
    }
    this.changeValue();
  }

  private setFullBackward(): void {
    this.dateRange.from = this.minDate;
    this.dateRange.to = moment(this.minDate).add(this.range, this.dataType).toDate();
  }

  private checkActive(): boolean {
    return !!(this.maxDate && this.minDate && this.period && this.dateRange);
  }

  public checkForward(): boolean {
    if (!this.checkActive()) {
      return false;
    } else {
      return moment(this.dateRange.to).isAfter(this.maxDate) || (this.dateRange.to.toString() === this.maxDate.toString());
    }
  }

  public checkBackward(): boolean {
    if (!this.checkActive()) {
      return false;
    } else {
      return moment(this.dateRange.from).isBefore(this.minDate) || (this.dateRange.from.toString() === this.minDate.toString());
    }
  }

  private changeValue(isRangeClick: boolean = true): void {
    const propagateValue: IDateRange = {
        from: this.dateRange.from,
        to: this.dateRange.to,
      };
    this.propagateChange(propagateValue);
    if (isRangeClick) {
      this.rangeClick.emit();
    }
  }

  public writeValue(dateRange: IDateRange): void {
    this.dateRange = dateRange;
    if (dateRange && dateRange.from === undefined) {
      this.dateRange['from'] = moment(new Date()).subtract(this.range, this.dataType).toDate();
      this.dateRange['to'] = new Date();
    }
    this.recalc();
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    return;
  }

  public setDisabledState(isDisabled: boolean): void {
    return;
  }
}
