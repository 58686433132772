import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[appWarningSensor]'
})

export class WarningSensorDirective {

  @HostListener('keypress', ['$event'])
  public onKeyDown(event): void {
    const e: KeyboardEvent = <KeyboardEvent> event;
    if (!((event.charCode >= 48 && event.charCode <= 57)
      || (event.charCode >= 44 && event.charCode <= 46)
      || event.charCode === 32
      || event.charCode === 0)) {
      e.preventDefault();
    }
  }

}
