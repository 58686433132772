import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Chart } from 'angular-highcharts';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { selectSelectedStation } from '../../../../core/reducers';
import * as fromSelectedStation from '../../../../core/reducers/selectedStation';
import { ChartTranslationsService } from '../../../../core/services/chart-translations.service';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';
import { setTreeSetting, TreeSettingsActionTypes } from '../../../../shared/tree/actions/tree-settings';
import { ITreeSettingsState } from '../../../../shared/tree/models/tree.models';
import { setZoomTitle } from '../../../../shared/utils/setZoomTitle';
import { activitySettings, chart as chartSelector, treeSettings } from '../../selectors';
import { defaultPredefinedViews, IStateData, IStateSettings } from '../../states';

@Component({
  selector: 'app-soil-moisture-charts',
  templateUrl: './soil-moisture-charts.component.html',
  styleUrls: ['./soil-moisture-charts.component.scss']
})
export class SoilMoistureChartsComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public exportChartImg:      boolean;
  public isChartActive$:      Observable<boolean>;
  public charts:              Chart[] = [];
  public chartsSeries:        any = {};
  public isAllChartsDisabled: boolean = false;
  private hashMap:            any = {};
  private tree:               any = {};
  private destroy$:           Subject<boolean> = new Subject<boolean>();
  private stationId:          string = '';
  private profile:            string = defaultPredefinedViews[0].name;

  constructor(
    private store: Store<IStateData>,
    private settingsStore: Store<IStateSettings>,
    private treeStore: Store<ITreeSettingsState>,
    private selectedStationStore: Store<fromSelectedStation.ISelectedStationState>,
    private exportService: StationDataExportService,
    private chartTranslationsService: ChartTranslationsService
  ) { }

  public ngOnInit(): void {
    this.chartTranslationsService.translateShortMonths();

    this.treeStore
      .pipe(
        select(treeSettings),
        filter(settings => !!(settings[this.stationId] && settings[this.stationId].profileSettings[this.profile])),
        map(settings => settings[this.stationId].profileSettings[this.profile].disabledGroupIds),
        takeUntil(this.destroy$)
      ).subscribe(disabledGroupIds => {
        const SoilSettingsState = localStorage.getItem('soilMoisture') ? JSON.parse(localStorage.getItem('soilMoisture')) : {};
        SoilSettingsState[this.stationId] = disabledGroupIds;
        if (localStorage.getItem('currentUser')) {
          localStorage.setItem('soilMoisture', JSON.stringify(SoilSettingsState));
        }
      this.tree = disabledGroupIds;
      this.setChartsVisible();
    });

    this.isChartActive$ = this.settingsStore.pipe(
      takeUntil(this.destroy$),
      select(activitySettings, 'isChartActive')
    );

    this.store.pipe(
      takeUntil(this.destroy$),
      select(chartSelector),
      filter((chartsData: any) => Array.isArray(chartsData)),
      withLatestFrom(
        this.selectedStationStore.pipe(select(selectSelectedStation), map(station => station.original_name)),
        this.treeStore.pipe(select(treeSettings))
      )
    ).subscribe(([chartsData, stationId, settings]) => {
      this.stationId = stationId;
      this.profile = settings[stationId] ? settings[stationId].selectedProfile : this.profile;
      this.tree = settings[stationId] ? settings[stationId].profileSettings[this.profile].disabledGroupIds : this.tree;
      this.charts = [];
      this.hashMap = {};
      this.chartsSeries = {};

      chartsData.forEach((item, index) => {
        this.chartsSeries[index] = {
          isDisabled: true,
          series: []
        };
        item.series.forEach((s, i) => {
          const groupId: string = s.groupId;
          if (groupId) {
            this.chartsSeries[index].series.push(groupId);
            if (!this.hashMap[groupId]) {
              this.hashMap[groupId] = [];
            }
            this.hashMap[groupId].push({
              chart: index,
              series: i
            });
          }
        });

        const chart: Chart = new Chart(item);
        chart.options.plotOptions = {
          series: {
            events: {
              legendItemClick: (event: any) => {
                event.preventDefault();
                this.changeVisible(event.target.options.groupId);
              }
            }
          },
        };

        setZoomTitle(chart);
        this.charts.push(chart);
      });
      this.setChartsVisible();

    });
  }

  public ngOnChanges(): void {
    if (this.charts && this.exportChartImg) {
      const fileName = 'soil_moisture_charts_' + moment(new Date()).toISOString() + '.png';
      this.exportService.exportChartsToImage(fileName);
    }
  }

  private checkChartsActive(): void {
    let disabledChartsCount: number = 0;
    Object.keys(this.chartsSeries).forEach((c) => {
      let chartSeriesDisabled: number = 0;
      this.chartsSeries[c].series.forEach((s) => {
        if (this.tree[s]) {
          chartSeriesDisabled++;
        }
      });
      if (this.chartsSeries[c].series.length === chartSeriesDisabled) {
        this.chartsSeries[c].isDisabled = true;
        disabledChartsCount++;
      } else {
        this.chartsSeries[c].isDisabled = false;
      }
    });

    this.isAllChartsDisabled = this.charts.length === disabledChartsCount;
  }

  private setChartsVisible(): void {
    Object.keys(this.hashMap).forEach((key) => {
      const hash = this.hashMap[key];
      hash.forEach((h) => {
        if (this.charts[h.chart].ref) {
          if (this.tree[key] === undefined) {
            if (!this.charts[h.chart].options.series[h.series].visible) {
              this.charts[h.chart].ref.series[h.series].show();
            }
          } else {
            if (this.charts[h.chart].options.series[h.series].visible) {
              this.charts[h.chart].ref.series[h.series].hide();
            }
          }
        } else {
          this.charts[h.chart].options.series[h.series].visible = this.tree[key] === undefined;
        }
      });
    });
    this.checkChartsActive();
  }

  public changeVisible(groupID: string): void {
    this.treeStore.dispatch(setTreeSetting({
      groupdId: groupID,
      profile: this.profile,
      stationId: this.stationId
    }, TreeSettingsActionTypes.SET_SOIL_MOISTURE_TREE_SETTINGS));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
