import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { ActionTypes } from '../actions';
import { ISumsSmState, ISumsSmData } from '../models';
import { deepClone } from '../../../shared/utils/deepClone';
import { DEFAULT_PERIOD, DEFAULT_SCOPE } from '../constants';

export const reducers = {
    sumsSm
};

const unixTimestampToTime = n => n * 1000;

export const initalSumsSmState: ISumsSmState = {
    from: null,
    to: null,
    scope: DEFAULT_SCOPE,
    period: DEFAULT_PERIOD,
    isLastDataMode: true,
    displayActiveRootZone: true,
    hiddenRows: [],
    isLoading: false,
    isError: false,
    data: []
};

export function sumsSm(state = initalSumsSmState, action: IAction): ISumsSmState {
    switch (action.type) {
        case ActionTypes.SET_SUMS_SM_INITIAL_STATE:
            state.hiddenRows = [];
            state.data = [];
            while (state.hiddenRows.length < action.payload.sourceCount) {
                state.hiddenRows.push([]);
            }
            return {
                ...state
            };
        case ActionTypes.SET_SUMS_SM_FROM_TO:
            return {
                ...state,
                from: action.payload.from,
                to: action.payload.to
            };
        case ActionTypes.SET_SUMS_SM_ONLY_ACTIVE_ROOT_ZONE:
            return {
                ...state,
                displayActiveRootZone: action.payload.displayActiveRootZone
            };
        case ActionTypes.SET_SUMS_SM_ROW_HIDDEN:
            state.hiddenRows[action.payload.sourceIndex][action.payload.rowIndex] = action.payload.hidden;
            return {
                ...state
            };
        case ActionTypes.SET_SUMS_SM_SCOPE_AND_PERIOD:
            return {
                ...state,
                scope: action.payload.scope,
                period: action.payload.period
            };
        case ActionTypes.SET_SUMS_SM_DATA:
            return {
                ...state,
                data: (<ISumsSmData[]>action.payload.data).map(data => {
                    data.dates = data.dates.map(unixTimestampToTime);
                    return data;
                })
            };
        case ActionTypes.SET_SUMS_SM_SET_LOADING_STATE:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                isError: action.payload.isError
            };
        default:
            return state;
    }
}


