import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { setNotify } from '../../../../../core/actions/notify';
import * as fromNotify from '../../../../../core/reducers/notify';
import { saveNodeName, setNodeChanged, updateNodeName } from '../../../actions/sensors-and-nodes';
import { INode } from '../../../models/sensors-and-nodes';
import * as fromSensorsAndNodes from '../../../reducers/sensors-and-nodes';

@Component({
  selector: '[app-sensor-head]',
  templateUrl: './sensor-head.component.html',
  styleUrls: ['./sensor-head.component.scss']
})
export class SensorHeadComponent implements OnInit, OnDestroy {
  @Input()
  public nodeKey                : string;
  @Input()
  public node                   : INode;
  @Input()
  public stationId              : string;
  @Input()
  public nodeHasCalibration     : boolean;
  @Output()
  public showInactiveSensors    = new EventEmitter<boolean>();

  public isInactiveSensorsShown : boolean = false;
  public form                   : FormGroup;
  public readonly basicNode     : string = 'X';

  private destroy$              : Subject<boolean> = new Subject<boolean>();

  constructor(private fb: FormBuilder,
              private notifyStore: Store<fromNotify.INotifyState>,
              private sensorsStore: Store<fromSensorsAndNodes.ISensorsAndNodesState>) { }

  public get nameControl(): AbstractControl {
    return this.form.get('name');
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      'name': [this.node.name, []]
    });

    this.nameControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(100),
      distinctUntilChanged()
    ).subscribe((name: string) => {
      this.sensorsStore.dispatch(updateNodeName({
        nodeId: this.nodeKey,
        name: name
      }));
      this.sensorsStore.dispatch(setNodeChanged({
        nodeId: this.nodeKey,
        isChanged: true
      }));
    });
  }

  public saveNodeName(): void {
    if (!this.node.isChanged) {
      this.notifyStore.dispatch(setNotify('No changes have been made yet'));
    } else {
      this.sensorsStore.dispatch(saveNodeName({
        nodeId: this.nodeKey,
        stationId: this.stationId,
        node: {
          mac: this.node.mac,
          name: this.nameControl.value,
          serial: String(this.node.serial)
        }
      }));
    }
  }

  public checkBasicNode(): boolean {
    return !(this.node.mac !== this.basicNode || this.node.serial !== this.basicNode);
  }

  public nodeHasInactiveSensors(): boolean {
    return Object.keys(this.node.sensors).some(key => !this.node.sensors[key].isActive);
  }

  public toggleShowInactiveSensors(): void {
    this.isInactiveSensorsShown = !this.isInactiveSensorsShown;
    this.showInactiveSensors.emit(this.isInactiveSensorsShown);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
