import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectSelectedCropZone } from '../../../../core/reducers';
import * as fromSelectedCropzone from '../../../../core/reducers/selectedCropZone';
import { DualexColumns, MobilabColumns } from '../../../../shared/constants/sample-data.constant';
import { SampleDeviceService } from '../../../../shared/services/sample-device/sample-device.service';
import { deepClone } from '../../../../shared/utils/deepClone';
import { getDualexByDate, getMobilabByDate } from '../../actions/cropzone-missions.action';
import { setActiveMeter, setDeviceType } from '../../actions/crozone-settings.action';
import { ICropzoneSampleState, selectActiveDevice, selectSamples, selectSettings } from '../../reducers';
import { SampleDataService } from '../../services/sample-data.service';

@Component({
  templateUrl: './sample-nutrition.component.html',
  styleUrls: ['./sample-nutrition.component.scss'],
  providers: [SampleDeviceService]
})
export class SampleNutritionComponent implements OnInit, OnDestroy {

  public station: IStation;
  public isReady = false;
  public isLoading = true;
  public isTableActive: boolean = true;
  public mobilabDefs = MobilabColumns;
  public dualexDefs = DualexColumns;
  public alive$ = new Subject<void>();
  public station$: Observable<IStation>;
  public cropzone;
  public settings$;
  public errorText: string;
  public deviceType = 'dualex';
  public activeMeter = 'temp';
  public activeDevice;
  public activeSample;
  public date;
  public data;
  public devices = {
    dualex: [],
    mobilab: []
  };

  @ViewChildren('deviceTables') private tableList: QueryList<any>;

  constructor(
    private cropzoneSampleStore: Store<ICropzoneSampleState>,
    private selectedStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private sampleDataService: SampleDataService,
    private sampleDeviceService: SampleDeviceService
  ) {

    this.cropzoneSampleStore.dispatch(setActiveMeter('temp'));
  }

  public ngOnInit(): void {

    this.cropzoneSampleStore.dispatch(setDeviceType('dualex'));
    this.deviceType = 'dualex';

    this.cropzoneSampleStore.pipe(
      select(selectActiveDevice),
      takeUntil(this.alive$),
      filter(_ => Boolean(_)),
      tap(_ => this.activeDevice = _),
      tap(() => this.activeSample = null)
    ).subscribe(activeDevice =>
      this.navigatePage({
        source: null,
        activeSample: null,
        activeDevice
      })
    );

    this.selectedStore.pipe(
      select(selectSelectedCropZone),
      takeUntil(this.alive$),
      tap(_ => this.deviceType = 'dualex'),
      distinctUntilChanged((prev, curr) => prev.id === curr.id),
    ).subscribe(cropzone =>
      this.cropzone = cropzone
    );

    this.settings$ = this.cropzoneSampleStore.pipe(
      select(selectSettings),
      tap(() => {
        this.isLoading = true;
        this.isReady = false;
      }),
      takeUntil(this.alive$)
    );

    this.cropzoneSampleStore.pipe(
      select(selectSamples),
      takeUntil(this.alive$),
      filter(data => Boolean(data))
    ).subscribe(tables => {

      this.devices = {
        dualex: [],
        mobilab: []
      };

      const mobilabSample = (tables.mobilab[0] && tables.mobilab[0][0]);
      const dualexSample = (tables.dualex[0] && tables.dualex[0][0]);

      this.updateUnits(dualexSample, mobilabSample);

      if ('dualex' === this.deviceType && tables.dualex.length) {
        this.devices.dualex = this.sampleDeviceService.parseTableData(tables.dualex, this.activeMeter, 'nutrition');
      }

      if ('mobilab' === this.deviceType && tables.mobilab.length) {
        this.devices.mobilab = this.sampleDeviceService.parseTableData(tables.mobilab, this.activeMeter, 'nutrition');
      }

      this.data = [...this.devices.dualex, ...this.devices.mobilab];

      this.isReady = Boolean(
        this.devices.dualex.length ||
        this.devices.mobilab.length
      );

      this.isLoading = false;
    });

    this.settings$.pipe(
      filter(data => Boolean(data)),
    ).subscribe(settings => {

      this.sampleDeviceService.setTitleActive(settings.activeTitle);
      this.activeMeter = settings.activeMeter;
      this.date = this.sampleDeviceService.setPeriod(settings);

      if (settings.deviceType !== null) {
        this.deviceType = settings.deviceType;
      }

      if (this.date && this.date.from) {
        const fromStr = moment.unix(this.date.from).format('DD/MM/YYYY');
        const toStr = moment.unix(this.date.to).format('DD/MM/YYYY');
        this.errorText = `The sample data was not found for the period from ${fromStr} to ${toStr}`;

        this.cropzoneSampleStore.dispatch(
          getMobilabByDate({cropzone: this.cropzone.id, date: this.date})
        );

        this.cropzoneSampleStore.dispatch(
          getDualexByDate({cropzone: this.cropzone.id, date: this.date})
        );
      }
    });
  }

  /*
   * Quick hack to extract units (ex. °C, °F, %, etc) from the response
   *
   * @param dualexSample
   */
  public updateUnits(dualexSample, mobilabSample): void {
    if (dualexSample && dualexSample.temp_unit) {
      const {temp_unit, sat_qual_unit} = dualexSample;

      this.sampleDataService.translate(deepClone(DualexColumns))
        .subscribe(columns => {

          if (sat_qual_unit) {
            columns[1].headerName += ` [${sat_qual_unit}]`;
          }

          columns[2].headerName += ` [${temp_unit}]`;

          this.dualexDefs = columns;
        });
    }

    if (mobilabSample && mobilabSample.nitrate_unit) {
      this.sampleDataService.translate(deepClone(MobilabColumns))
        .subscribe(columns => {
          columns[1].headerName += ` [${mobilabSample.nitrate_unit}]`;
          columns[2].headerName += ` [${mobilabSample.sulfate_unit}]`;
          columns[3].headerName += ` [${mobilabSample.ammonium_unit}]`;
          columns[4].headerName += ` [${mobilabSample.chloride_unit}]`;
          columns[5].headerName += ` [${mobilabSample.potassium_unit}]`;
          columns[6].headerName += ` [${mobilabSample.sodium_unit}]`;
          columns[7].headerName += ` [${mobilabSample.calcium_unit}]`;
          columns[8].headerName += ` [${mobilabSample.magnesium_unit}]`;

          this.mobilabDefs = columns;
        });
    }
  }

  /**
   * Custom page navigation using scrolling
   *
   * @param data
   * @param source
   */
  public navigatePage({source, activeDevice, activeSample}): void {

    this.sampleDeviceService.scrollInto(source, activeDevice, this.tableList);

    this.activeDevice = activeDevice;
    this.activeSample = activeSample;
  }

  public ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }
}
