import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-yield-config-help-text-modal',
  templateUrl: './yield-config-help-text-modal.component.html',
  styleUrls: ['./yield-config-help-text-modal.component.scss']
})
export class YieldConfigHelpTextModalComponent {

  @Input()
  public yieldHelpText: string;

  constructor() { }

}
