import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CropViewComponent} from './containers/crop-view/crop-view.component';


const routes: Routes = [
  { path: '', component: CropViewComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class CropViewRoutingModule { }
