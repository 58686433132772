<form [formGroup]="form"
      class="widget-settings-form"
      *ngIf="allStations$ | async">
  <app-custom-dropdown [formControl]="group"
                       class="widget-settings-form__dropdown"
                       [options]="groupValues">
  </app-custom-dropdown>

  <app-custom-dropdown [formControl]="last"
                       class="widget-settings-form__dropdown"
                       [options]="group.value === 'hourly' ? lastValues : lastDailyValues">
  </app-custom-dropdown>

  <app-custom-dropdown [formControl]="station"
                       class="widget-settings-form__dropdown"
                       [options]="allStations$ | async | stationsOptions">
  </app-custom-dropdown>

  <app-custom-button (click)="submit()"
                     [content]="'Refresh' | translate"
                     [color]="'red'">
    <span><i class="fa fa-fw fa-refresh"></i></span>
  </app-custom-button>
</form>
