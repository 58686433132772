<div class="warnings-phones-save">
  <hr class="warnings-phones-save__hr">
  <div class="warnings-phones-save__content">
    <div>
      <app-custom-button [color]="'red'"
                         class="warnings-phones-save__save"
                         [content]="'Save'"
                         (click)="savePhones()">
        <i class="zmdi zmdi-refresh zmdi-hc-fw"></i>
      </app-custom-button>
    </div>
  </div>
</div>
