import { FormGroup, ValidationErrors } from '@angular/forms';
import { SCALED_FREQUENCY_ID } from '../constants';

export function validator(form: FormGroup): ValidationErrors {

    const validation = Object.keys(form.controls).reduce((valid: ValidationErrors, code) => {
        if (Object.keys((<FormGroup>form.get(code)).controls).some(channel => !validate(form.get(code).get(channel).value))) {
            valid[code] = {
                valid: false,
                frequencyNotAllowed: true
            };
        }
        return valid;
    }, {});
    if (Object.keys(validation).length !== 0) {
        Object.keys(validation).forEach(key => {
            Object.keys(form.controls[key]['controls']).forEach(key2 => {
                if (key2 !== 'soilProfile') {
                    Object.keys(form.controls[key]['controls'][key2]['controls']).forEach(key3 => {
                        if (key3 === 'calibration_id') {
                            if (form.controls[key]['controls'][key2]['controls'][key3].value === SCALED_FREQUENCY_ID) {
                                form.controls[key].setErrors({'frequencyNotAllowed': true});
                                form.setErrors({'frequencyNotAllowed': true});
                            }
                        }
                    });
                }
            });
        });
    }
    return Object.keys(validation).length ? validation : null;
}

export function validate(group: {[index: string]: any}): boolean {

    const { refill_point, field_capacity, depth, calibration_id }  = group;
    return (
        (isEmpty(refill_point) && isEmpty(field_capacity) && isEmpty(depth)) ||
        (
            calibration_id !== SCALED_FREQUENCY_ID &&
            isPositiveNumber(refill_point) && isPositiveNumber(field_capacity) && isPositiveNumber(depth) &&
            depth > 0 && +refill_point < +field_capacity
        )
    );

}

export function isEmpty(value: any): boolean {
    return value === undefined || value === null || value === '';
}

function isPositiveNumber(value: any): boolean {
    return Number.isFinite(parseFloat(value)) && parseFloat(value) >= 0;
}
