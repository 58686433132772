import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { setNotify } from '../../../../../core/actions/notify';
import { INotifyState } from '../../../../../core/reducers/notify';
import { ApiCallService } from '../../../../../services/api/api-call.service';
import { ModalService } from '../../../../../shared/modal/services/modal.service';
import { deleteToken, removeAsset } from '../../../actions/my-john-deere';
import { IAsset, IState } from '../../../models/my-john-deere';

@Component({
  selector: 'app-my-john-deere-revoke-access-modal',
  templateUrl: './my-john-deere-revoke-access-modal.component.html',
  styleUrls: ['./my-john-deere-revoke-access-modal.component.scss']
})
export class MyJohnDeereRevokeAccessModalComponent implements OnChanges {

  @Input()
  public MODAL_ID: string;

  @Input()
  public assets: IAsset[];

  public noOrganizations: number = 0;
  public removing: boolean = false;
  public doAbort: boolean = false;
  public currentAsset: IAsset;
  public removed: number = 0;

  constructor(
    private apiService: ApiCallService,
    private modalService: ModalService,
    private myJdStore: Store<IState>,
    private notify: Store<INotifyState>
  ) {
  }

  private initialize(): void {
    this.noOrganizations = Array.isArray(this.assets) ? this.assets.reduce((a: string[], b) => {
      return a.includes(b.organization) ? a : [...a, b.organization];
    }, []).length : 0;
    this.removing = false;
    this.removed = 0;
    this.currentAsset = null;
    this.doAbort = false;
  }

  private deleteToken(): void {
    this.myJdStore.dispatch(deleteToken());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.assets) {
      this.initialize();
    }
  }

  public close(): void {
    this.modalService.closeModal(this.MODAL_ID);
  }

  public remove(): void {

    if (this.assets.length > 0) {
      this.removing = true;
      this.currentAsset = this.assets[0];
      if (this.assets.length > 0) {
        from(this.assets)
          .pipe(
            concatMap(asset => {
              return this.apiService.deleteMyJohnDeereAsset(asset.asset).pipe(
                tap(() => this.currentAsset = asset),
                tap(res => {
                  if (res.httpcode === 204) {
                    this.removed++;
                    this.myJdStore.dispatch(removeAsset(asset));
                  } else if (res.httpcode === 401 || res.httpcode === 403) {
                    this.removed++;
                    this.notify.dispatch(setNotify('Removing station from your MyJohnDeere organization failed.'));
                  }
                  if (this.doAbort) {
                    throw new Error('aborted');
                  }
                }),
                map(() => asset)
              );
            })
          ).subscribe({
          complete: () => {
            this.deleteToken();
            this.close();
          },
          error: (err: Error) => {
            if (err.message !== 'aborted') {
              this.notify.dispatch(setNotify('MyJohnDeere API currently unavailable.'));
            }
            this.close();
          }
        });
      }
    } else {
      this.deleteToken();
      this.close();
    }

  }

  public keep(): void {
    this.deleteToken();
    this.close();
  }

  public abort(): void {
    this.doAbort = true;
  }

}
