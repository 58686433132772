import { Component, Input } from '@angular/core';
import { ICamSettings } from '../../../models/station-config.models';

@Component({
  selector: 'app-camera-settings-cam',
  templateUrl: './camera-settings-cam.component.html'
})
export class CameraSettingsCamComponent {
  @Input()
  public cam: ICamSettings;
  @Input()
  public camKey: string;
  @Input()
  public camIndex: number;

}
