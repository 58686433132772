import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ITrackerDataState, selectTrackerDataPeriod } from '../../reducers/index';
import { ApiCallService } from './../../../../services/api/api-call.service';
import { ITrackerPeriod } from './../../models/tracker.models';

@Component({
  selector: 'app-tracker-header',
  templateUrl: './tracker-header.component.html',
  styleUrls: ['./tracker-header.component.scss']
})
export class TrackerHeaderComponent implements OnInit, OnDestroy {

  public period$                     : Observable<ITrackerPeriod>;
  public readonly format             : string = 'yyyy-MM-dd HH:mm';
  private destroy$                   : Subject<boolean> = new Subject<boolean>();
  public validSettingsData           : boolean = true;

  // ***************************************************************
  // ** The commented code in this component is commented because **
  // ** it's a form validating feature that we are not yet using  **
  // ***************************************************************

  /*private requiredFields = [
    'serialNumber',
    'simNumber',
  ];*/

  constructor(private trackerStore: Store<ITrackerDataState>, private api: ApiCallService) { }

  public ngOnInit(): void {
    this.period$ = this.trackerStore.pipe(
      takeUntil(this.destroy$),
      select(selectTrackerDataPeriod)
    );

    this.trackerStore.pipe(
      takeUntil(this.destroy$),
      select(selectTrackerDataPeriod)
    );

   /* this.period$.subscribe(
      data =>
        this.api.getTracker(data.stationId).subscribe(
          response => {
            this.validateSettingsData(response);
          }
        )
    );*/
  }

  /*public validateSettingsData(settings: any): void {
    for (let i = 0; i < this.requiredFields.length; i++) {
      if (!settings[this.requiredFields[i]]) {
        this.validSettingsData = false;
        return;
      }
    }
    this.validSettingsData = true;
  }*/

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
