<section>
  <div class="alert alert-warning station-config-content__alert" *ngIf="!(hasWritePermission$ | async)">
    {{ 'You have read-only rights to this station, therefore you cannot use the drawing tool.' | translate }}
  </div>

  <app-camera-topology [linkType]="topologyLinkType.LINK_DATA"></app-camera-topology>

  <app-form-layout>
    <ng-container *ngIf="!(isLoading$ | async) && !(isError$ | async); else loadInfo">
      <app-crop-view-image-gallery *ngIf="(selectedPicture$ | async) === null"
                                   [title]="'Crop View camera pictures' | translate"
                                   [deviceType]="stationType"
                                   [widePictures]="widePictures$ | async"
                                   [zoomPictures]="zoomPictures$ | async"
                                   [activeCameraId]="activeCameraId"
                                   (selectPictureEmitter)="selectPicture($event)"></app-crop-view-image-gallery>

      <app-crop-view-image-settings *ngIf="(selectedPicture$ | async) !== null"
                                    [title]="'Crop View selected picture' | translate"
                                    [stationId]="stationId"
                                    [activeCameraId]="activeCameraId"
                                    [selectedPicture$]="selectedPicture$"
                                    [hasWritePermission]="hasWritePermission$ | async"
                                    (unselectPictureEmitter)="unselectPicture()"></app-crop-view-image-settings>
    </ng-container>

    <ng-template #loadInfo>
      <app-station-load-info [isError]="isError$ | async" [isLoading]="isLoading$ | async"></app-station-load-info>
    </ng-template>
  </app-form-layout>
</section>
