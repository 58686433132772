import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { environmentToken } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/interfaces/environment';
import { VERSION } from '../../../../../environments/version';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', '../../../../shared/common.scss']
})
export class HeaderComponent {
  @Input()
  public notificationCount              : number = 0;
  @Output()
  public cropZonesToggled               : EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public rightStationsToggled           : EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public closeBar                       : EventEmitter<void> = new EventEmitter<void>();
  @Output()
  private userMenuToggled               : EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  private notificationsToggled          : EventEmitter<boolean> = new EventEmitter<boolean>();

  public version                        : string = VERSION.buildDate + ' / ' + VERSION.name;
  public backgroundColor                : string = '#ffffff';
  public headerClass                    : string = '';
  public versionDisplayed               : boolean = true;
  public farmView                       : boolean;
  public theme                          : any;

  constructor(@Inject(environmentToken) environment: IEnvironment) {
    this.farmView = environment.displayRules.farmViewMenu;
    this.theme = environment.theme;
  }

  public setBackground (backgroundColor: string): void {
    this.backgroundColor = backgroundColor;
  }

  public hideVersion (): void {
    this.versionDisplayed = false;
  }

  public setHeaderClass (headerClass: string): void {
    this.headerClass = headerClass;
  }

  public toggleCropZones(): void {
    this.cropZonesToggled.emit();
  }

  public toggleUserMenu(isClose: boolean): void {
    this.userMenuToggled.emit(isClose);
  }

  public toggleNotifications(isClose: boolean): void {
    this.notificationsToggled.emit(isClose);
  }

  public toggleRightStations(): void {
    this.rightStationsToggled.emit();
  }
}
