import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { from, Observable, of } from 'rxjs';
import { catchError, delay, filter, mergeMap, switchMap, tap } from 'rxjs/operators';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import {
  getCoordinates,
  getElevation,
  getTimezone,
  setCoordinates, setElevation,
  setTimezone,
  setTimezones,
  TimezoneAndLocationActionTypes
} from '../actions/timezoneAndLocation';
import { ApiCallService } from '../../../services/api/api-call.service';
import { changeStation } from '../../../core/actions/stations';
import { GoogleMapService } from '../services/google-map.service';
import { BaseNameActionTypes } from '../actions/baseName';
import { LoggingAndTransferActionTypes } from '../actions/loggingAndTransfer';
import { LedTrapSettingsActionTypes } from '../actions/ledTrapSettings';
import { EmergencyActionTypes } from '../actions/emergency';
import {
  CameraSettingsActionType,
  setCameraImage,
  toggleCameraSettingsInvalid
} from '../actions/cameraSettings';
import {
  TrackerSettingsActionTypes,
  setAddedMachinery,
  setTracker
} from '../actions/trackerSettings';
import { NoteActionTypes } from '../actions/note';
import { setNotify } from '../../../core/actions/notify';
import { setTrackerSettings } from '../../../core/actions/navigation-station';
import { checkCropView } from '../../../shared/utils/checkCropView';
import { IStation } from '../../../core/models/stations';
import { Store } from '@ngrx/store';
import { INavigationStationState } from '../../../core/reducers/navigation-station';
import { setNavigationStationBaseName } from '../../../core/actions/navigation-station';

@Injectable()
export class StationConfigEffectsEffects {

  constructor(private actions$: Actions,
    private api: ApiCallService,
    private map: GoogleMapService,
    private navigationStore: Store<INavigationStationState>) {
  }

  @Effect()
  public getTimezones$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(TimezoneAndLocationActionTypes.GET_TIMEZONES),
    switchMap(() => this.api.getTimezone().pipe(
      switchMap((res: any) => from([
        setTimezones(res),
      ])),
      catchError(() => from([]))
    )
    )
  );

  @Effect()
  public getCoordinates$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(TimezoneAndLocationActionTypes.GET_COORDINATES),
    switchMap((action: IActionWithPayload) => from([
      getTimezone(action.payload),
      setCoordinates(action.payload),
      getElevation(action.payload)
    ])
    )
  );

  @Effect()
  public getTimezone$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(TimezoneAndLocationActionTypes.GET_TIMEZONE),
    switchMap((action: IActionWithPayload) => this.api.getTimezoneFromAPI(action.payload).pipe(
      filter((res: any) => res.status === 'OK'),
      switchMap((res: any) => of(setTimezone(res.timeZoneId))),
      catchError(() => from([]))
    )
    )
  );

  @Effect()
  public getElevation$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(TimezoneAndLocationActionTypes.GET_ELEVATION),
    switchMap((action: IActionWithPayload) => this.map.getElevator(action.payload[1], action.payload[0]).pipe(
      switchMap((res: any) => of(setElevation(res))),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public updateStationLocation$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(TimezoneAndLocationActionTypes.UPDATE_STATION_LOCATION),
    switchMap((action: IActionWithPayload) =>
      this.api.saveStationLocation(action.payload.position, action.payload.name.original).pipe(
        switchMap((response: any) => from([
          changeStation(action.payload),
          setNotify(!Array.isArray(response)
            ? 'Station location saved successfully'
            : 'No changes have been made yet. Edit the status before updating.')
        ])
        ),
        catchError(() => of(setNotify('Error saving location'))),
      ))
  );

  @Effect()
  public getLocation$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(TimezoneAndLocationActionTypes.GET_LOCATION),
    switchMap((action: IActionWithPayload) => this.map.getGeocoder(action.payload).pipe(
      switchMap((res: any) => of(getCoordinates(res))),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public saveBaseName$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(BaseNameActionTypes.SAVE_BASE_NAME),
    switchMap((action: IActionWithPayload) => this.api.saveCustomName(
      action.payload.name.custom,
      action.payload.name.original)
      .pipe(
        switchMap((response: any) => {
          // TODO: should be handled in the backend: Ideally custom name should nt be stored in two collections
          if (!checkCropView((<IStation>action.payload).info.device_id)) {
            this.api.updateStationNode(action.payload.name.original, { mac: 'X', name: action.payload.name.custom })
              .subscribe();
          }
          this.navigationStore.dispatch(setNavigationStationBaseName(action.payload.name.custom));
          return from([
            changeStation(action.payload),
            setNotify(!Array.isArray(response)
              ? 'Custom name was updated successfully'
              : 'No changes have been made yet. Edit the status before updating.')
          ]);
        }),
        catchError(() => of(setNotify('Something went wrong - custom name was not updated')))
      ))
  );

  @Effect()
  public saveNote$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(NoteActionTypes.SAVE_NOTE),
    switchMap((action: IActionWithPayload) => this.api.saveStationNote(
      action.payload.name.original,
      action.payload.note)
      .pipe(
        switchMap((response: any) => from([
          changeStation(action.payload),
          setNotify(!Array.isArray(response)
            ? 'Note was updated successfully'
            : 'No changes have been made yet. Edit the status before updating.')
        ])
        ),
        catchError(() => of(setNotify('Error saving note')))
      ))
  );

  @Effect()
  public saveLogging$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(LoggingAndTransferActionTypes.SAVE_LOGGING),
    switchMap((action: IActionWithPayload) => this.api.saveStationLogging(
      action.payload.saveObj,
      action.payload.station.name.original)
      .pipe(
        switchMap((response: any) => from([
          changeStation(action.payload.station),
          setNotify(!Array.isArray(response)
            ? 'Configuration saved successfully'
            : 'No changes have been made yet. Edit the status before updating.')
        ])
        ),
        catchError(() => of(setNotify('Error saving station configuration')))
      ))
  );

  @Effect()
  public saveCameraLEDFlash$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CameraSettingsActionType.SAVE_CAMERA_LED_FLASH),
    switchMap((action: IActionWithPayload) => this.api.saveStationLEDFlash(
      action.payload.station,
      action.payload.flash)
      .pipe(
        switchMap((response: any) => {
          return from([
            changeStation(action.payload.station)
        ]); }
        ),
        catchError(() => of(setNotify('Error saving station configuration')))
      ))
  );

  @Effect()
  public saveLedTraps$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(LedTrapSettingsActionTypes.SAVE_LED_SETTINGS),
    switchMap((action: IActionWithPayload) => this.api.saveStationLedTrap(
      action.payload.saveObj,
      action.payload.station.name.original)
      .pipe(
        switchMap((response: any) => from([
            changeStation(action.payload.station),
            setNotify(!Array.isArray(response)
              ? 'LED trap configuration saved successfully'
              : 'No changes have been made yet. Edit the status before updating.')
          ])
        ),
        catchError(({ error }) => of(setNotify(error.message || 'Error saving LED trap configuration')))
      ))
  );

  @Effect()
  public saveEmergency$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(EmergencyActionTypes.SAVE_EMERGENCY),
    switchMap((action: IActionWithPayload) => this.api.saveStationEmergency(
      action.payload.emergency,
      action.payload.station.name.original)
      .pipe(
        switchMap((response: any) => from([
          changeStation(action.payload.station),
          setNotify(!Array.isArray(response)
            ? 'SMS emergency phone number saved successfully'
            : 'No changes have been made yet. Edit the status before updating.')
        ])
        ),
        catchError(() => of(setNotify('Error saving SMS emergency number')))
      ))
  );

  @Effect()
  public loadCamImage$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CameraSettingsActionType.GET_CAMERA_IMAGE),
    mergeMap((action: IActionWithPayload) => this.api.getCameraLastImage(
      action.payload)
      .pipe(
        switchMap((res: any) => from([
          setCameraImage({
            camKey: action.payload.camKey,
            img: res[0].thumb
          })
        ])),
        catchError(() => from([
          setCameraImage({
            camKey: action.payload.camKey,
            img: 'assets/img/backs/cropview-picture.png'
          })
        ]))
      ))
  );

  @Effect()
  public saveCams$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CameraSettingsActionType.SAVE_CAM_SETTINGS),
    switchMap((action: IActionWithPayload) => this.api.saveStationCam(
      action.payload.cams,
      action.payload.station.name.original)
      .pipe(
        switchMap((response: any) => from([
          changeStation(action.payload.station),
          setNotify(!Array.isArray(response)
            ? 'CropView configuration saved successfully'
            : 'No changes have been made yet. Edit the status before updating.')
        ])
        ),
        catchError(() => of(setNotify('Error saving CropView configuration')))
      ))
  );

  @Effect()
  public offCamMessage$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CameraSettingsActionType.OFF_CAMERA_SETTINGS_MESSAGE),
    delay(3000),
    switchMap(() => from([
      toggleCameraSettingsInvalid(false)
    ]))
  );

  @Effect()
  public getTracker$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(TrackerSettingsActionTypes.GET_TRACKER),
    switchMap((action: IActionWithPayload) => this.api.getTracker(action.payload).pipe(
      switchMap((res: any) => from([
        setTracker(res),
      ])),
      catchError(() => from([]))
    )
    )
  );
  @Effect()
  public getAddedMachinery$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(TrackerSettingsActionTypes.GET_ADDED_MACHINERY),
    switchMap((action: IActionWithPayload) => this.api.getAddedMachinery().pipe(
      switchMap((res: any) => from([
        setAddedMachinery(res),
      ])),
      catchError(() => from([]))
    )
    )
  );

  @Effect()
  public saveTracker$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(TrackerSettingsActionTypes.SAVE_TRACKER),
    switchMap((action: IActionWithPayload) => this.api.saveTracker(
      action.payload.stationId,
      action.payload.body)
      .pipe(
        switchMap((response: any) => from([
          setNotify((response)
            ? 'Tracker information was updated successfully'
            : 'No changes have been made yet. Edit the status before updating.')
        ])
        ),
        catchError(() => of(setNotify('Error saving tracker configuration')))
      ))
  );

  @Effect()
  public saveTrackerSettings$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(TrackerSettingsActionTypes.SAVE_TRACKER_SETTINGS),
    switchMap((action: IActionWithPayload) => this.api.saveTrackerSettings(
      action.payload.stationId,
      action.payload.body)
      .pipe(
        switchMap((response: any) => from([
          setNotify((response)
            ? 'Tracker settings were updated successfully'
            : 'No changes have been made yet. Edit the settings before updating'),
            setTrackerSettings(action.payload.body['config.trip_delimiter']),
        ])
        ),
        catchError(() => of(setNotify('Error saving tracker configuration')))
      ))
  );

}
