import {Injectable} from '@angular/core';
import {IUserToken} from '../../modules/auth/login.interfaces';

@Injectable()
export class UserTokenRepository {
  private static readonly LOCAL_STORAGE_KEY: string = 'currentUser';
  private userData: IUserToken = null;

  public static clearToken(): void {
    localStorage.removeItem(UserTokenRepository.LOCAL_STORAGE_KEY);
  }

  public setToken(userData: IUserToken): void {
    if (userData && userData.access_token) {
      this.userData = userData;
      this.setExpiresOn();
      localStorage.setItem(UserTokenRepository.LOCAL_STORAGE_KEY, JSON.stringify(userData));
    }
  }

  public getToken(): IUserToken {
    return JSON.parse(localStorage.getItem(UserTokenRepository.LOCAL_STORAGE_KEY));
  }

  /**
   * Retrieves access_token
   * @returns {string}
   */
  public getActualAccessToken(): string {
    return this.getToken().access_token;
  }

  public isTokenActual(): boolean {
    return this.tokenExists() && this.getToken().expires_on > Date.now();
  }

  private tokenExists(): boolean {
    return !!this.getToken();
  }

  private setExpiresOn(): void {
    this.userData.expires_on = Date.now() + this.userData.expires_in * 1000;
  }
}
