import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Chart } from 'angular-highcharts';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';
import addChartZoomSync from '../../../../shared/utils/addChartZoomSync';
import { setZoomTitle } from '../../../../shared/utils/setZoomTitle';
import { selectStationDiseaseCharts, selectStationDiseaseSettingsIsChartActive } from '../../reducers';
import * as fromDisease from '../../reducers/station-disease';
import * as fromDiseaseSettings from '../../reducers/station-disease-settings';

@Component({
  selector: 'app-station-disease-charts',
  templateUrl: './station-disease-charts.component.html',
  styleUrls: ['./station-disease-charts.component.scss']
})
export class StationDiseaseChartsComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public exportChartImg           : boolean;
  public isChartActive$           : Observable<boolean>;
  public charts                   : Array<Chart> = [];

  private destroy$                : Subject<boolean> = new Subject<boolean>();

  constructor(private diseaseStore: Store<fromDisease.IStationDiseaseState>,
              private exportService: StationDataExportService,
              private diseaseSettingsStore: Store<fromDiseaseSettings.IStationDiseaseSettingsState>) { }

  public ngOnInit(): void {
    this.isChartActive$ = this.diseaseSettingsStore.pipe(
      select(selectStationDiseaseSettingsIsChartActive)
    );

    this.diseaseStore.pipe(
      takeUntil(this.destroy$),
      select(selectStationDiseaseCharts),
      filter((chartsData: any[]) => !!(Array.isArray(chartsData) && chartsData))
    ).subscribe((chartsData: any[]) => {
      this.charts = addChartZoomSync(chartsData.map((chart: any) => new Chart(chart)));
      this.charts.forEach((c: Chart) => {
        setZoomTitle(c);
      });
    });
  }

  public ngOnChanges(): void {
    if (this.charts && this.exportChartImg) {
      const fileName = 'disease_models_charts_' + moment(new Date()).toISOString() + '.png';
      this.exportService.exportChartsToImage(fileName);
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
