<ng-container *ngIf="noTab">
      <span class="yes-no-toggle">
            <span class="yes-no-toggle__yes"
                  [class.active]="value"
                  (click)="onChange(true)">{{ yes | translate }}</span>
            <span class="yes-no-toggle__no"
                  [class.active]="!value"
                  (click)="onChange(false)">{{ no | translate }}</span>
      </span>
</ng-container>
<ng-container *ngIf="!noTab">
      <div *ngIf="!small"
        class="btn-group btn-group-toggle yes-no-toggle" data-toggle="buttons">
            <label class="btn" [class.btn-success]="value" [class.btn-light]="!value">
                  <input type="radio" name="yes" autocomplete="off" class="pointer" (click)="onChange(true)" checked> {{ yes | translate }}
            </label>
            <label class="btn" [class.btn-warning]="!value" [class.btn-light]="value">
                  <input type="radio" name="no" autocomplete="off" class="pointer" (click)="onChange(false)"> {{ no | translate }}
            </label>
      </div>
      <div *ngIf="small"
        class="btn-group btn-group-toggle yes-no-toggle-small" data-toggle="buttons">
            <label class="btn-xs" [class.btn-success]="value" [class.btn-light]="!value">
                  <input type="radio" name="yes" autocomplete="off" class="pointer" (click)="onChange(true)" checked> {{ yes | translate }}
            </label>
            <label class="btn-xs" [class.btn-warning]="!value" [class.btn-light]="value">
                  <input type="radio" name="no" autocomplete="off" class="pointer" (click)="onChange(false)"> {{ no | translate }}
            </label>
      </div>
</ng-container>





