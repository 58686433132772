import { ISensor, ISystemSensor } from '../../../shared/interfaces';
import { ISensorColumns, IDefaultPawSetting } from '../models';
import { ICalibrationSetting } from '../../../core/models/system';

export function toColumns(calibrations: ICalibrationSetting[], systemSensors: { [code: number]: ISystemSensor }):
    (ISensorColumns, ISensor) => ISensorColumns {

    return function (cols: ISensorColumns, sensor: ISensor): ISensorColumns {
        const calibration = calibrations.find(s => s.code === sensor.code);
        const id = `${sensor.serial}_${sensor.mac}_${sensor.code}`;
        const setting: IDefaultPawSetting = {
            name: sensor.name,
            name_custom: sensor.name_custom,
            color: sensor.color,
            code: sensor.code,
            channel: sensor.ch,
            refill_point: sensor.refill_point,
            field_capacity: sensor.field_capacity,
            calibration_id: calibration ? sensor.calibration_id || calibration.calibration.find(c => c.default).id : '',
            calibrations: calibration ? calibration.calibration.map(c => ({ value: c.id, content: c.name })) : undefined,
            units: systemSensors[sensor.code].units.map(u => ({ value: u, content: u })),
            unit: sensor.unit || sensor.unit_default,
            unit_default: sensor.unit_default || '-',
            depth: sensor.depth
        };
        cols[id] = cols[id] ? [...cols[id], setting] : [setting];
        return cols;
    };

}
