import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-api-key-modal',
  templateUrl: './api-key-modal.component.html',
  styleUrls: ['./api-key-modal.component.scss']
})
export class ApiKeyModalComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {}

}
