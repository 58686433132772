import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GetQuickFilterTextParams, GridOptions, RowSelectedEvent } from 'ag-grid';
import * as moment from 'moment';
import { DataGridOptions } from '../../../../shared/constants';
import { IActiveLicense, IActiveLicenseProduct, IUserLicenseTableHeaders } from '../../models/user-licenses.models';

@Component({
  selector: 'app-user-licenses-list',
  templateUrl: './user-licenses-list.component.html',
  styleUrls: ['./user-licenses-list.component.scss']
})
export class UserLicensesListComponent implements OnInit {
  private static readonly MAX_ITEM_AMOUNT: number = 5;

  @Input()
  public activeUserLicenses: Array<IActiveLicense> = [];
  @Input()
  public header: string;
  @Input()
  public type: string;
  @Output()
  public selectLicense: EventEmitter<IActiveLicense> = new EventEmitter<IActiveLicense>();

  public gridOptions: GridOptions;
  private tableHeaders: IUserLicenseTableHeaders;

  constructor(
    public route: ActivatedRoute,
    private translations: TranslateService
  ) {
    this.tableHeaders = route.snapshot.data['translatedHeaders'];
  }

  public ngOnInit(): void {
    this.gridOptions = {
      ...DataGridOptions,
      columnDefs: [
        {
          headerName: this.tableHeaders.licensesHeader,
          field: 'products',
          width: 450,
          maxWidth: 500,
          suppressMenu: true,
          valueFormatter: this.displayProducts
        },
        {
          headerName: this.type === 'FieldClimate' ? this.tableHeaders.stationsHeader
                      : this.type === 'FarmView' ? this.tableHeaders.cropzonesHeader
                      : '',
          field: 'stations',
          width: 400,
          maxWidth: 450,
          suppressMenu: true,
          valueFormatter: this.displayStations
        },
        {
          headerName: this.tableHeaders.startHeader,
          field: 'from',
          width: 100,
          maxWidth: 150,
          suppressMenu: true,
          valueFormatter: this.displayDate
        },
        {
          headerName: this.tableHeaders.expiryHeader,
          field: 'to',
          width: 100,
          maxWidth: 100,
          suppressMenu: true,
          valueFormatter: this.displayDate
        },
      ],
      onRowSelected: ({ api }: RowSelectedEvent): void => {
        this.selectLicense.emit(api.getSelectedRows()[0] || null);
      }
    };
  }

  public displayDate = (params: GetQuickFilterTextParams): string => {
    return moment(params.value).format('YYYY-MM-DD');
  }

  public displayProducts = (params: GetQuickFilterTextParams): any => {
    const licenses: IActiveLicenseProduct[] = params.value;
    const translatedLicenses: string[] = [];
    licenses.forEach((license: IActiveLicenseProduct) => {
      const label = license.name ? license.name : license.title;
      if (license.license_group && label) {
        this.translations.get(label).subscribe(translatedLicense =>
          translatedLicenses.push(translatedLicense)
        );
      }
    });
    return this.truncateValues(translatedLicenses);
  }

  public displayStations = (params: GetQuickFilterTextParams): string => {
    return this.truncateValues(params.value);
  }

  public truncateValues(values: Array<string>): string {
    if (values) {
      const displayed = values.length > 5
        ? values.slice(0, UserLicensesListComponent.MAX_ITEM_AMOUNT)
        : values.slice();
      return displayed.join(', ') + (values.length > 5 ? ', ...' : '');
    }
  }
}
