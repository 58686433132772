import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { IStation } from '../../../../../core/models/stations';
import { selectNavigationStation } from '../../../../../core/reducers';
import { INavigationStationState } from '../../../../../core/reducers/navigation-station';
import { ICameraPest, IDeleteCameraPestBody, IPostCameraPestBody } from '../../../../../shared/camera/models/camera';
import { getIscoutGeneralPests } from '../../../actions/iscout';
import {
  getIscoutUserPests,
  removeIscoutPest,
  saveIscoutPest,
  selectIscoutPest,
  unselectIscoutPest
} from '../../../actions/iscout-pests';
import { IIscoutPestFormAction, IIscoutPestsState, IscoutFormActionType } from '../../../models/iscout.models';
import { selectIscoutIsLoading, selectIscoutSelectedPest } from '../../../reducers';

@Component({
  selector: 'app-iscout-pests-content',
  templateUrl: './iscout-pests-content.component.html'
})
export class IscoutPestsContentComponent implements OnInit, OnDestroy {
  public selectedPest$: Observable<IIscoutPestFormAction>;
  public isLoading$: Observable<boolean>;
  public displaySystemPests: boolean = true;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private stationId: string;

  constructor(private iscoutPestStore: Store<IIscoutPestsState>,
              private navigationStationStore: Store<INavigationStationState>) {
  }

  public ngOnInit(): void {
    this.initStatusListener();
    this.initStationChangeObservable().subscribe((): void => {
      this.iscoutPestStore.dispatch(getIscoutGeneralPests(this.stationId));
      this.iscoutPestStore.dispatch(getIscoutUserPests(this.stationId));
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initStatusListener(): void {
    this.selectedPest$ = this.iscoutPestStore.pipe(
      select(selectIscoutSelectedPest)
    );
    this.isLoading$ = this.iscoutPestStore.pipe(
      select(selectIscoutIsLoading)
    );
  }

  private initStationChangeObservable(): Observable<IStation> {
    return this.navigationStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((station: IStation): boolean => !!station),
      tap((station: IStation): void => {
        if (this.stationId !== station.name.original) {
          this.iscoutPestStore.dispatch(unselectIscoutPest());
        }
        this.stationId = station.name.original;
      })
    );
  }

  public selectPestCreate(): void {
    const formAction = {action: IscoutFormActionType.CREATE, pest: null};
    this.iscoutPestStore.dispatch(selectIscoutPest(formAction));
  }

  public selectPestUpdate(pest: ICameraPest): void {
    const formAction: IIscoutPestFormAction = {action: IscoutFormActionType.UPDATE, pest};
    this.iscoutPestStore.dispatch(selectIscoutPest(formAction));
  }

  public handlePestSave(pest: IPostCameraPestBody): void {
    this.iscoutPestStore.dispatch(saveIscoutPest(this.stationId, pest));
  }

  public handlePestDelete(pest: IDeleteCameraPestBody): void {
    this.iscoutPestStore.dispatch(removeIscoutPest(this.stationId, pest));
  }

  public handleSystemPestToggle(toggleEvent: boolean): void {
    this.displaySystemPests = toggleEvent;
  }
}
