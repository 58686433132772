<app-chart-toolbar-layout>
  <form class="soil-guard-toolbar" (keydown.enter)="$event.preventDefault()" [formGroup]="periodForm">

    <div class="soil-guard-toolbar__left" *ngIf="dates">
      <div class="soil-guard-toolbar__toggle soil-guard-toolbar__left__item">
        <app-custom-button appTooltip
                           [color]="isLastMode ? 'dark-green' : 'green'"
                           tooltipPlacement="top"
                           [tooltipTitle]="'Last data mode' | translate"
                           [shadow]="false"
                           radius="2px 0 0 2px"
                           (click)="setLastMode(true)">
          <span class="fa fa-step-backward"></span>
        </app-custom-button>
        <app-custom-button appTooltip
                           [color]="!isLastMode ? 'dark-green' : 'green'"
                           tooltipPlacement="top"
                           [tooltipTitle]="'From - To mode' | translate"
                           [shadow]="false"
                           radius="0 2px 2px 0"
                           (click)="setLastMode(false)">
          <span class="fa fa-calendar"></span>
        </app-custom-button>
      </div>

      <ng-container formGroupName="fromTo" *ngIf="!isLastMode">
        <app-custom-datepicker [isSmall]="true"
                               [minDate]="dates.from"
                               [maxDate]="dates.to"
                               formControlName="from">
        </app-custom-datepicker>
        <app-custom-datepicker [isSmall]="true"
                               [minDate]="dates.from"
                               [maxDate]="dates.to"
                               class="soil-guard-toolbar__left__item"
                               formControlName="to">
        </app-custom-datepicker>
      </ng-container>
      <app-custom-dropdown [options]="periodValues"
                           [hidden]="!isLastMode"
                           class="soil-guard-toolbar__left__item"
                           formControlName="periodValue">
      </app-custom-dropdown>
      <app-date-range-arrow [hidden]="!isLastMode"
                            (rangeClick)="dropActiveSample() || submitData()"
                            [minDate]="dates.from"
                            [maxDate]="dates.to"
                            [period]="periodValue.value"
                            [formControl]="range">
      </app-date-range-arrow>
    </div>

    <div class="soil-guard-toolbar__right">

      <app-custom-dropdown style="margin-right: 10px"
                           [options]="titleValues"
                           color="white"
                           class="soil-guard-toolbar__left__item"
                           formControlName="activeTitle">
      </app-custom-dropdown>

      <app-custom-dropdown style="margin-right: 0"
                           [options]="interestValues"
                           color="white"
                           class="soil-guard-toolbar__left__item"
                           formControlName="activeMeter">
      </app-custom-dropdown>
      <div class="soil-guard-toolbar__toggle
                  soil-guard-toolbar__right__item
                  soil-guard-toolbar__export" *ngIf="this.dates">
        <app-custom-button appTooltip
                           [shadow]="false"
                           (click)="exportXLS()"
                           tooltipPlacement="top"
                           color="white"
                           [tooltipTitle]="'Export table as xls' | translate">
          <i class="glyphicon glyphicon-download-alt ng-star-inserted"></i>
        </app-custom-button>
      </div>

      <div class="soil-guard-toolbar__toggle soil-guard-toolbar__right__item">
        <app-custom-button [color]="isTableActive ? 'green' : 'white'"
                           appTooltip
                           tooltipPlacement="top"
                           [tooltipTitle]="'Toggle table' | translate"
                           [shadow]="false"
                           (click)="toggleTable()"
                           radius="0 2px 2px 0">
          <span class="fa fa-table"></span>
        </app-custom-button>
      </div>

      <app-custom-button color="red"
                         class="soil-guard-toolbar__right__item"
                         (click)="submitData()"
                         [content]="'Refresh' | translate">
        <i class="fa fa-fw fa-refresh"></i>
      </app-custom-button>
    </div>
  </form>
</app-chart-toolbar-layout>
