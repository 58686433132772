<div>
  <h3>{{ 'Boundaries' | translate }}</h3>

  <hr>
  <div>
    <label class="locationSearchLabel">{{ 'Location search' | translate }}</label>
    <app-form-input
      type="border"
      placeholder="Location search"
      [formControl]="search">
    </app-form-input>
    <app-custom-button
      color="white"
      (click)="find()"
      [content]="'Go!' | translate"
      class="marginTop10px">
    </app-custom-button>
  </div>
  <hr>

  <app-custom-button
    class="button marginTop15px"
    [customCSS]="{'max-width': '100%', 'width':'100%'}"
    [disabled]="drawDisabled"
    content="Draw"
    (click)="drawFieldOnMap()"
    *ngIf="drawButton">
  </app-custom-button>

  <app-custom-button
    class="button marginTop15px"
    [customCSS]="{'max-width': '100%', 'width':'100%'}"
    content="Delete"
    color="red"
    (click)="deleteFieldOnMap()"
    *ngIf="!drawButton">
  </app-custom-button>

  <app-custom-button
    class="button marginTop15px"
    [customCSS]="{'max-width': '100%', 'width':'100%'}"
    content="Edit"
    [disabled]="!editButton"
    (click)="editFieldOnMap()">
  </app-custom-button>

  <form [formGroup]="form" class="form-group marginTop15px marginBottom0">


    <div class="custom-file marginTop15px">
      <input type="file"
             class="custom-file-input"
             accept=".zip, .geojson, .kmz"
             single
             id="importFile1"
             [disabled]="!importButton"
             (click)="importClick()"
             (change)="onFileChange($event.target.files)"
             #myFileInput>
      <label [ngClass]="customFileLabel" class="custom-file-label" for="importFile1" [ngStyle]="{'border-radius': uploadButtonBorder}"> {{ 'Import' | translate }}</label>
    </div>

    <div *ngIf="fileToUpload" class="uploadName">
      {{ fileToUpload.name }}
    </div>
  </form>

  <div class="alert-warning warningDiv" *ngIf="showAlert">
    {{ 'Multiple boundaries detected, click on desired boundary to select.' | translate }}
  </div>

  <app-custom-button
    class="button bottom"
    [customCSS]="{'max-width': '100%', 'width':'100%'}"
    content="Store boundaries"
    [color]="storeColor"
    (click)="store()"
    [disabled]="!storeButton">
  </app-custom-button>
</div>
