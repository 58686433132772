import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-davis-sensor-modal',
  templateUrl: './davis-sensor-modal.component.html',
  styleUrls: ['./davis-sensor-modal.component.css']
})
export class DavisSensorModalComponent {

  @Input()
  public dialog: string;

}
