<div class="selected-cropzone">
  <a [ngClass]="'selected-cropzone-image'"
     [ngStyle]="{'background-image': 'url(' + theme.profileMenuSrc + ')'}"
     (click)="infoIsOpen = !infoIsOpen">
    <div class="cropzone-name">
      {{ cropzoneName }}
      <i [ngClass]="{'zmdi': true, 'zmdi-caret-down': !infoIsOpen, 'zmdi-caret-up': infoIsOpen}"></i>
    </div>
  </a>
  <ul *ngIf="infoIsOpen" class="selected-cropzone-info">
    <li>{{ 'Cropzone name' | translate }}: <strong>{{ cropzoneName }}</strong></li>
    <li>{{ 'Fieldname' | translate }}: <strong>{{ fieldName }}</strong></li>
    <li>{{ 'Farmname' | translate }}: <strong>{{ farmName }}</strong></li>
    <li>{{ 'Crop' | translate }}: <strong>{{ crop }}</strong></li>
    <li>{{ 'Year' | translate }}: <strong>{{ year }}</strong></li>
  </ul>
</div>
