import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { setNotify } from '../../../../core/actions/notify';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import * as fromNavigationStation from '../../../../core/reducers/navigation-station';
import { INotifyState } from '../../../../core/reducers/notify';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { IOptions } from '../../../../shared/interfaces';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';
import { DefaultPeriodScope, PeriodValuesByScope } from '../../../station-data/constants/constants';
import { setHydroponicsChartActive, setHydroponicsTableActive, setPeriod } from '../../actions/actions';
import { IHydroponicsState } from '../../models/models';
import { hydroponicsData, selectHydroponicsCharts } from '../../selectors/selectors';

@Component({
  selector: 'app-hydroponics-toolbar',
  templateUrl: './hydroponics-toolbar.component.html',
  styleUrls: ['./hydroponics-toolbar.component.scss']
})

export class HydroponicsToolbarComponent implements OnInit, OnDestroy {
  public station                  : IStation;
  public fromStation              : Date = null;
  public toStation                : Date = null;
  public periodScopeSelectItems   : Array<IOptions> = Object.keys(PeriodValuesByScope).map((item: string) => ({
    content : item,
    value   : item
  }));
  public periodValueSelectItems   : Array<IOptions> = PeriodValuesByScope[DefaultPeriodScope];
  public periodForm               : FormGroup;
  private isDatepickerRequest     : boolean = false;

  private destroy$                : Subject<boolean> = new Subject<boolean>();
  private initDestroy$            : Subject<boolean> = new Subject<boolean>();

  constructor(
    private formBuilder           : FormBuilder,
    private api                   : ApiCallService,
    private exportService         : StationDataExportService,
    private navigationStore       : Store<fromNavigationStation.INavigationStationState>,
    private hydroponicsStore      : Store<IHydroponicsState>,
    private notifyStore           : Store<INotifyState>,
  ) { }

  private get period(): AbstractControl {
    return this.periodForm.get('period');
  }

  public get fromDatepicker(): AbstractControl {
    return this.period.get('fromDatepicker');
  }

  public get toDatepicker(): AbstractControl {
    return this.period.get('toDatepicker');
  }

  public get isLastDataMode(): AbstractControl {
    return this.period.get('isLastDataMode');
  }

  public get periodScope(): AbstractControl {
    return this.period.get('periodScope');
  }

  public get periodValue(): AbstractControl {
    return this.period.get('periodValue');
  }

  public get fromTo(): AbstractControl {
    return this.period.get('fromTo');
  }

  public get stationId(): AbstractControl {
    return this.period.get('stationId');
  }

  private get activity(): AbstractControl {
    return this.periodForm.get('activity');
  }

  public get isChartActive(): AbstractControl {
    return this.activity.get('isChartActive');
  }

  public get isTableActive(): AbstractControl {
    return this.activity.get('isTableActive');
  }

  public get isExportImageActive(): AbstractControl {
    return this.activity.get('isExportImageActive');
  }

  public get isExportActive(): AbstractControl {
    return this.activity.get('isExportActive');
  }

  public ngOnInit(): void {
    this.periodForm = this.formBuilder.group({
      'period': this.formBuilder.group({
        'periodScope': ['hourly', [Validators.required]],
        'periodValue': ['2d', [Validators.required]],
        'fromTo': [null, [Validators.required]],
        'fromDatepicker': [null, [Validators.required]],
        'toDatepicker': [null, [Validators.required]],
        'stationId': ['', [Validators.required]],
        'isLastDataMode': [true, [Validators.required]]
        }),
      'activity': this.formBuilder.group({
        'isChartActive': [true, [Validators.required]],
        'isTableActive': [true, [Validators.required]],
        'isExportImageActive': [false, [Validators.required]],
        'isExportActive': [false, [Validators.required]],
      })
    });

    this.hydroponicsStore.pipe(
      select(hydroponicsData),
      takeUntil(this.initDestroy$)
    ).subscribe((settings: IHydroponicsState) => {
      this.initDestroy$.next(true);
      this.periodForm.setValue({
        period: settings.period,
        activity: settings.activity
      });
    });

    this.navigationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((s: IStation) => !!s)
    ).subscribe(station => {
      this.fromStation = moment(station.dates.min_date).toDate();
      this.toStation = moment(station.dates.max_date).toDate();
      if (station.name.original === this.stationId.value) {
        return;
      }
      this.stationId.setValue(station.name.original);

      setTimeout(() => {
        if (!this.station ||  station.name.original !== this.station.name.original) {
          this.fromDatepicker.setValue(moment(this.toStation).subtract(1, 'day').toDate());
          this.toDatepicker.setValue(moment(this.toStation).toDate());
          this.fromTo.setValue({
            from: moment(this.toStation).subtract(2, 'day').toDate(),
            to: this.toStation
          });
        }
        this.refresh();
        this.station = station;
      });
    });

    this.periodScope.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged()
    ).subscribe((scope: string) => {
      this.periodValueSelectItems = PeriodValuesByScope[scope];
      setTimeout(() => this.periodValue.setValue(this.periodValueSelectItems[0].value), 0);
    });

    this.periodValue.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged()
    ).subscribe((value: string) => {
      this.periodValue.setValue(value);
    });

    this.isChartActive.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      debounceTime(10)
    ).subscribe((isActive: boolean) => {
      this.hydroponicsStore.dispatch(setHydroponicsChartActive(isActive));
    });

    this.isTableActive.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      debounceTime(10)
    ).subscribe((isActive: boolean) => {
      this.hydroponicsStore.dispatch(setHydroponicsTableActive(isActive));
    });
  }

  public setDateRange(): void {
    if (this.isDatepickerRequest && !this.isLastDataMode.value) {
      this.fromTo.setValue({
        from: this.fromDatepicker.value,
        to: this.toDatepicker.value
      });
    }
  }

  public refresh(): void {
    this.isDatepickerRequest = !this.isLastDataMode.value;
    this.hydroponicsStore.dispatch(setPeriod(this.periodForm.value.period));
  }

  public startExportImage(): void {
    if (this.isExportImageActive.value) {
      return;
    } else {
      this.isExportImageActive.setValue(true);
    }

    this.hydroponicsStore.pipe(
      select(selectHydroponicsCharts),
      take(1),
      map(charts => charts.filter(c => c.series.some(s => s.visible))),
      filter(charts => charts.length > 0),
      tap((charts) => {
        this.isExportImageActive.setValue(true);
      }),
      mergeMap(charts => this.api.getHydroponicsChartsExport(charts)
        .pipe(
          map(res => res[0])
        )
      )
    ).subscribe(url => {
        this.isExportImageActive.setValue(false);
        window.open(url, '_blank');
      },
      err => {
        this.isExportImageActive.setValue(false);
        this.notifyStore.dispatch(setNotify('Chart image export failed'));
      }
    );
  }

  public startExportXLS(): void {
    this.exportService.startExportXLS(this.stationId.value);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.initDestroy$.next(true);
    this.initDestroy$.complete();
  }
}
