<app-tree *ngIf="!reduced" [tree$]="tree$"></app-tree>
<div class="body-view-content" *ngIf="!reduced" [@placeContent]="state$ | async">
  <app-station-page-header [pageName]="'Machinery settings'"></app-station-page-header>
  <app-form-layout class="tracker-definition">
    <app-card-header [header]="'Machinery definition'" [helpButton]="true"></app-card-header>
    <div class="tracker-definition__content">
      <div class="row">
        <div class="tracker-definition-form">
          <form [formGroup]="machinerySelectForm">
            <label class="tracker-definition-form__label">{{ 'Machinery' | translate }}</label>
            <app-form-select class="tracker-definition-form__item" [options]="allMachinerys"
              [formControlName]="'selectedMachinery'" [type]="'border'"></app-form-select>
          </form>

          <form [formGroup]="machineryForm" *ngIf="machineryForm.enabled">
            <div class="row">
              <div class="col-md-6">
                <label class="tracker-definition-form__label">{{ 'Name' | translate }}</label>
                <app-form-input class="tracker-definition-form__item"
                  [placeholder]="'Enter machinery name'" [formControlName]="'machineryName'">
                </app-form-input>

                <label class="tracker-definition-form__label">{{ 'Date of purchase' | translate }}</label>
                <app-custom-datepicker class="tracker-definition-form__item" [formControlName]="'machineryPurchaseDate'">
                </app-custom-datepicker>

                <label class="tracker-definition-form__label">{{ 'Date of last technical control' | translate }}</label>
                <app-custom-datepicker class="tracker-definition-form__item"
                  [formControlName]="'machineryLastTechicalDate'"></app-custom-datepicker>

                <label
                  class="tracker-definition-form__label">{{ 'Date of upcomming technical control' | translate }}</label>
                <app-custom-datepicker class="tracker-definition-form__item" [maxDate]="null"
                  [formControlName]="'machineryUpcomingTechnicalDate'"></app-custom-datepicker>

                <label
                  class="tracker-definition-form__label">{{ 'Kilometers between service interval' | translate }}</label>
                <div class="row">
                  <div class="col-md-6">
                    <label class="tracker-definition-form__label">{{ 'Current km' | translate }}</label>
                    <app-form-input class="tracker-definition-form__item"
                      [placeholder]="'Enter current km'"
                      [formControlName]="'machineryServiceCurrentKm'"></app-form-input>
                  </div>
                  <div class="col-md-6">
                    <label class="tracker-definition-form__label">{{ 'Max km' | translate }}</label>
                    <app-form-input class="tracker-definition-form__item" [placeholder]="'Enter max km'"
                      [formControlName]="'machineryServiceMaxtKm'"></app-form-input>
                  </div>
                </div>

                <label
                  class="tracker-definition-form__label">{{ 'Engine hours between service interval' | translate }}</label>
                <div class="row">
                  <div class="col-md-6">
                    <label class="tracker-definition-form__label">{{ 'Current hours' | translate }}</label>
                    <app-form-input class="tracker-definition-form__item"
                      [placeholder]="'Enter current engine hours'"
                      [formControlName]="'machineryServiceCurrentEngineH'"></app-form-input>
                  </div>
                  <div class="col-md-6">
                    <label class="tracker-definition-form__label">{{ 'Max hours' | translate }}</label>
                    <app-form-input class="tracker-definition-form__item"
                      [placeholder]="'Enter max engine hours'"
                      [formControlName]="'machineryServiceMaxEngineH'"></app-form-input>
                  </div>
                </div>

                <label
                  class="tracker-definition-form__label">{{ 'Max number of days between service intervals' | translate }}</label>
                <app-form-input class="tracker-definition-form__item"
                  [placeholder]="'Enter max service days'"
                  [formControlName]="'machineryServiceMaxDays'"></app-form-input>
              </div>
              <div class="col-md-6">
                <label class="tracker-definition-form__label">{{ 'Plate number' | translate }}</label>
                <app-form-input class="tracker-definition-form__item"
                  [placeholder]="'Enter plate number'" [formControlName]="'machineryPlateNumber'">
                </app-form-input>

                <label class="tracker-definition-form__label">{{ 'Frame number' | translate }}</label>
                <app-form-input class="tracker-definition-form__item"
                  [placeholder]="'Enter frame number'" [formControlName]="'machineryFrameNumber'">
                </app-form-input>

                <label class="tracker-definition-form__label">{{ 'Description' | translate }}</label>
                <app-form-input class="tracker-definition-form__item"
                  [placeholder]="'Enter description'" [formControlName]="'machineryDescription'">
                </app-form-input>

                <label class="tracker-definition-form__label">{{ 'Model' | translate }}</label>
                <app-form-input class="tracker-definition-form__item"
                  [placeholder]="'Enter machinery model'" [formControlName]="'machineryModel'">
                </app-form-input>

                <label class="tracker-definition-form__label">{{ 'Power' | translate }}</label>
                <div class="form-group">
                  <app-form-input class="tracker-definition-form__item-power-input"
                    [placeholder]="'Enter machinery power'" [formControlName]="'machineryPower'">
                  </app-form-input>
                  <app-form-select class="tracker-definition-form__item-power-select"
                    [options]="machineryPowerTypeOptions" [formControlName]="'machineryPowerType'" [type]="'border'">
                  </app-form-select>
                </div>

                <label class="tracker-definition-form__label">{{ 'Work Tracking' | translate }}</label>
                <app-form-select class="tracker-definition-form__item" [options]="machineryTrackerOptions"
                  [formControlName]="'trackerID'" [type]="'border'"></app-form-select>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="save">
        <hr class="save__hr">
        <div class="save__content _mtop">
          <div>
            <app-custom-button [color]="'red'" class="save__save" [content]="'Save configuration'" (click)="save()">
            </app-custom-button>
          </div>
        </div>
      </div>
    </div>
  </app-form-layout>
  <app-form-layout class="tracker-definition">
      <app-card-header [header]="'Service logs' | translate" [helpButton]="true"></app-card-header>
      <div class="row tracker-definition__content">
        <form [formGroup]="serviceForm" class="tracker-definition-form">
          <div class="col-md-2">
            <label class="tracker-definition-form__label">{{ 'Date time' | translate }}:</label>
            <app-custom-datepicker [dateInputFormat]="'DD/MM/YYYY'" [formControl]="serviceDateControl"></app-custom-datepicker>
            <!--app-form-input class="tracker-definition-form__item"
            [placeholder]="'Enter date' | translate" [formControlName]="'date'">
            </app-form-input-->
          </div>
          <div class="col-md-3">
              <label class="tracker-definition-form__label">{{ 'Service' | translate }}:</label>
              <app-form-input class="tracker-definition-form__item"
              [placeholder]="'Enter service' | translate" [formControlName]="'service'">
              </app-form-input>
          </div>
          <div class="col-md-2">
              <label class="tracker-definition-form__label">{{ 'Km' | translate }}:</label>
              <app-form-input class="tracker-definition-form__item"
              [placeholder]="'Kilometers at service' | translate" [formControlName]="'km'">
              </app-form-input>
          </div>
          <div class="col-md-2">
              <label class="tracker-definition-form__label">{{ 'H' | translate }}:</label>
              <app-form-input class="tracker-definition-form__item"
              [placeholder]="'Hours at service' | translate" [formControlName]="'h'">
              </app-form-input>
          </div>
          <div class="col-md-3">
              <label class="tracker-definition-form__label">{{ 'Note' | translate }}:</label>
              <app-form-input class="tracker-definition-form__item"
              [placeholder]="'Enter note' | translate" [formControlName]="'note'">
              </app-form-input>
          </div>
          
        </form>
      </div>
      <div class="tracker-definition__content">
        <div class="save">
          <div class="save__content">
            <app-custom-button [color]="'red'" class="save__save" [content]="'Add new log' | translate" (click)="saveLog()">
            </app-custom-button>
          </div>
        </div>
        <div>
          <ag-grid-angular #agGrid *ngIf="dataGridOptions" class="table ag-theme-bootstrap" [gridOptions]="dataGridOptions">
          </ag-grid-angular>
        </div>
      </div>
  </app-form-layout>

  <app-info-modal [modalId]="'addMachineryModal'" [header]="'Add Machinery' | translate">

  </app-info-modal>
</div>

<div *ngIf="reduced">
  <form [formGroup]="machineryForm">
    <div class="row">
      <div class="col-md-6">
        <label class="tracker-definition-form__label">{{ 'Name' | translate }}</label>
        <app-form-input class="tracker-definition-form__item" [placeholder]="'Enter machinery name'"
          [formControlName]="'machineryName'"></app-form-input>

        <label class="tracker-definition-form__label">{{ 'Plate number' | translate }}</label>
        <app-form-input class="tracker-definition-form__item" [placeholder]="'Enter plate number'"
          [formControlName]="'machineryPlateNumber'"></app-form-input>
      </div>
      <div class="col-md-6">
        <label class="tracker-definition-form__label">{{ 'Frame number' | translate }}</label>
        <app-form-input class="tracker-definition-form__item" [placeholder]="'Enter frame number'"
          [formControlName]="'machineryFrameNumber'"></app-form-input>
      </div>
    </div>
  </form>
  <div class="save">
    <hr class="save__hr">
    <div class="save__content">
      <div>
        <app-custom-button [color]="'red'" class="save__save" [content]="'Add machinery'" (click)="save()">
        </app-custom-button>
      </div>
    </div>
  </div>
</div>