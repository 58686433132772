import { Component, Inject } from '@angular/core';
import { IThemeConfig } from '../../../../../environments/interfaces/theme';
import { environmentToken } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/interfaces/environment';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html'
})
export class UserSettingsComponent {

  public theme                : IThemeConfig;

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
  ) {
    this.theme = environment.theme;
  }

}
