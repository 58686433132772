<form [formGroup]="form"
      *ngIf="allStations$ | async"
      class="widget-settings-form">

  <app-custom-dropdown formControlName="profile"
                       class="widget-settings-form__dropdown"
                       [options]="modeOptions"
                       [currentValue]="currentMode">
  </app-custom-dropdown>

  <app-custom-dropdown formControlName="station"
                       class="widget-settings-form__dropdown"
                       [options]="allStations$ | async | weatherForecast | stationsOptions">
  </app-custom-dropdown>

  <app-custom-button (click)="submit()"
                     [content]="'Refresh' | translate"
                     [color]="'red'">
    <span><i class="fa fa-fw fa-refresh"></i></span>
  </app-custom-button>

</form>
