import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { TranslateService } from '../../../../node_modules/@ngx-translate/core';
import {IAnimalProductionChartValues} from '../../shared/interfaces';

@Injectable()
export class AnimalProductionResolver implements Resolve<any> {
  constructor(private translations: TranslateService) {}

  public resolve(): Observable<any>|Promise<any>|any {
    const translatedValues: IAnimalProductionChartValues = {
      noStress: 'No stress',
      lowStress: 'Low stress',
      mediumStress: 'Medium stress',
      highStress: 'High stress',
      deadCow: 'Dead cow',
      deadChicken: 'Dead chicken',
    };

    Object.keys(translatedValues).forEach(key => {
      this.translations.get(translatedValues[key])
        .subscribe(translatedValue => {
          translatedValues[key] = translatedValue;
        });
    });

    return translatedValues;
  }

}
