import { Component } from '@angular/core';

@Component({
  selector: 'app-yield-prediction-help-text-modal',
  templateUrl: './yield-prediction-help-text-modal.component.html',
  styleUrls: ['./yield-prediction-help-text-modal.component.scss']
})
export class YieldPredictionHelpTextModalComponent {

  constructor() { }

}
