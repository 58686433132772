import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-widget-load-info',
  templateUrl: './widget-load-info.component.html'
})
export class WidgetLoadInfoComponent {
  @Input()
  public error      : string;
  @Input()
  public loading    : boolean;
}
