<app-camera-data-content-cropview
  *ngIf="isCropView"
  [title]="pageTitleObservable$"
  [stationCameraType]="stationCameraType"
  [currentDateListener$]="currentDateObservable$"
  [stationChangedListener$]="stationChangeObservable$"
  [isChartActive$]="isChartActive$"
  [isHelpActive$]="isHelpActive$"
  [isTableActive$]="isTableActive$"
></app-camera-data-content-cropview>

<app-camera-data-content-iscout
  *ngIf="isIScout"
  [title]="pageTitleObservable$"
  [stationCameraType]="stationCameraType"
  [currentDateListener$]="currentDateObservable$"
  [stationChangedListener$]="stationChangeObservable$"
  [isChartActive$]="isChartActive$"
  [isHelpActive$]="isHelpActive$"
  [isTableActive$]="isTableActive$"
></app-camera-data-content-iscout>
