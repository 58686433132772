import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IFrostProtectionState } from '../models/models';
import { ApiCallService } from '../../../services/api/api-call.service';
import { from, Observable } from 'rxjs';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import {
  ActionTypes,
  setFrostProtectionError,
  setFrostProtectionLoading,
  setFrostProtectionResponse
} from '../actions/actions';
import { catchError, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class FrostProtectionEffects {

  constructor(
    private actions$             : Actions,
    private frostProtectionStore : Store<IFrostProtectionState>,
    private api                  : ApiCallService
  ) { }

  @Effect()
  public getFrostProtectionResponse$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ActionTypes.GET_FROST_PROTECTION_RESPONSE),
    tap((res) => {
      setFrostProtectionError(false);
      setFrostProtectionLoading(true);
      setFrostProtectionResponse(null);
    }),
    switchMap((action: IActionWithPayload) => this.api.getFrostProtection(action.payload).pipe(
      switchMap((response: any) => from([
        setFrostProtectionError(false),
        setFrostProtectionResponse(response),
        setFrostProtectionLoading(false)
      ])),
      catchError(({ error }) => from([
        setFrostProtectionLoading(false),
        setFrostProtectionError(true),
        // setFrostProtectionErrorMessage(error.message)
      ])))
    )
  );
}
