import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { saveNotifications, updateNotifications } from '../../../../../core/actions/account';
import { INewsLetter } from '../../../../../core/models/account';
import * as fromAccount from '../../../../../core/reducers/account';
import { selectNewsLetter } from '../../../../../core/reducers/index';
import { card3Animation } from '../../../../../shared/animations/card-animations';
import { environmentToken } from '../../../../../../environments/environment';
import { IEnvironment } from '../../../../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../../../../environments/interfaces/theme';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  animations: [card3Animation()]
})
export class NotificationsComponent implements OnInit, OnDestroy {
  public newsletter$        : Observable<INewsLetter>;
  public success            : boolean;
  public error              : boolean;
  private destroy$          : Subject<boolean> = new Subject<boolean>();
  public subDomain          : IThemeConfig;

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    private accountStore: Store<fromAccount.IAccount>
    ) {
      this.subDomain = environment.theme;
  }

  public ngOnInit(): void {
    this.newsletter$ = this.accountStore.pipe(select(selectNewsLetter));
    this.newsletter$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((newsletter: INewsLetter) => {
      if (typeof newsletter === 'undefined') {
        const defaultNewsLetter: INewsLetter = {
          promotional: false,
          technical: false
        };
        this.accountStore.dispatch(updateNotifications(defaultNewsLetter));
      }
    });

  }

  public updateNotifications(newsLetter: INewsLetter): void {
    this.accountStore.dispatch(saveNotifications({
      'newsletter.promotional' : newsLetter.promotional,
      'newsletter.technical' : newsLetter.technical
    }));
    this.accountStore.dispatch(updateNotifications(newsLetter));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
