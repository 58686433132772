import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { IStationSettingsPeriod } from '../../../../shared/interfaces';
import { setHydroponicsError, setHydroponicsLoading } from '../../actions/actions';
import { IGetHydroponicsRequest, IHydroponicsState } from '../../models/models';
import { hydroponicsPeriod, selectHydroponicsLoadError, selectHydroponicsLoadProcess } from '../../selectors/selectors';

@Component({
  selector: 'app-hydroponics-form',
  templateUrl: './hydroponics-form.component.html',
  styleUrls: ['./hydroponics-form.component.scss']
})
export class HydroponicsFormComponent implements OnInit, OnDestroy {
  public form             : FormGroup;
  public isLoading        : boolean = false;
  public response         : any = null;
  private alive           : boolean = true;
  private startDate       : any;
  private endDate         : any;
  public period$          : Observable<IStationSettingsPeriod>;
  public isError$         : Observable<boolean>;
  public isLoading$       : Observable<boolean>;

  private destroy$        : Subject<boolean> = new Subject<boolean>();

  constructor(
    private fb                  : FormBuilder,
    private hydroponicsStore    : Store<IHydroponicsState>,
    private api                 : ApiCallService
  ) { }

  public ngOnInit(): void {
    this.isError$ = this.hydroponicsStore.pipe(
      takeUntil(this.destroy$),
      select(selectHydroponicsLoadError)
    );
    this.isLoading$ = this.hydroponicsStore.pipe(
      takeUntil(this.destroy$),
      select(selectHydroponicsLoadProcess)
    );

    this.form = this.fb.group({
      'flowDripper': [0, Validators.required],
      'numberDripper': [0, Validators.required]
    });

    this.flowDripper.setValue(30);
    this.numberDripper.setValue(1);

    this.period$ = this.hydroponicsStore.pipe(
      select(hydroponicsPeriod),
      takeUntil(this.destroy$)
    );

    this.hydroponicsStore.pipe(
      select(hydroponicsPeriod),
    ).subscribe((period: IStationSettingsPeriod) => {
      if (period.stationId) {
        if (period.isLastDataMode === true) {
          const startDateUTC: Date = moment(moment(period.fromTo.from).format('YYYY-MM-DDTHH:mm') + 'Z').toDate();
          const endDateUTC: Date = moment(moment(period.fromTo.to).format('YYYY-MM-DDTHH:mm') + 'Z').toDate();
          this.startDate = moment(startDateUTC).format('X');
          this.endDate = moment(endDateUTC).format('X');
        } else {
          const startDateUTC: Date = moment(moment(period.fromDatepicker).format('YYYY-MM-DDTHH:mm') + 'Z').toDate();
          const endDateUTC: Date = moment(moment(period.toDatepicker).format('YYYY-MM-DDTHH:mm') + 'Z').toDate();
          this.startDate = moment(startDateUTC).format('X');
          this.endDate = moment(endDateUTC).format('X');
        }
        this.calculate(period);
      }
     });
  }

  public get flowDripper(): AbstractControl {
    return this.form.get('flowDripper');
  }

  public get numberDripper(): AbstractControl {
    return this.form.get('numberDripper');
  }

  public calculate(period: IStationSettingsPeriod): void {
    this.hydroponicsStore.dispatch(setHydroponicsLoading(true));
    this.hydroponicsStore.dispatch(setHydroponicsError(false));
    this.response = null;

      const request: IGetHydroponicsRequest = {
        stationId: period.stationId,
        from: this.startDate,
        to: this.endDate,
        timePeriod: period.periodScope,
        dripperData: {
          flowDripper: +this.flowDripper.value,
          numberDripper: +this.numberDripper.value
        }
      };

      this.api.getHydroponics(request).pipe(
        takeWhile(() => this.alive)
      ).subscribe((response: any) => {
        this.hydroponicsStore.dispatch(setHydroponicsLoading(false));
        this.response = response;
        if (this.response.hasOwnProperty('data') === false) {
          this.hydroponicsStore.dispatch(setHydroponicsError(true));
          this.hydroponicsStore.dispatch(setHydroponicsLoading(false));
        }
      }, () => {
        this.hydroponicsStore.dispatch(setHydroponicsLoading(false));
        this.hydroponicsStore.dispatch(setHydroponicsError(true));
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
