import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { IIrrigationEvents } from '../../../core/models/cropzones';
import { IrrimetActionTypes } from '../actions/irrigation.action';
import { IIrrigationCalendarEvent } from '../models/models';
import * as moment from 'moment';

export interface IIrrimetEventsState {
  irrigationEvents: Array<IIrrigationEvents>;
  calendarEvents: Array<IIrrigationCalendarEvent>;
  viewDate: Date;
}

export const initialState: IIrrimetEventsState = {
  irrigationEvents: [],
  calendarEvents: [],
  viewDate: new Date()
};

export function irrimetReducer(state: IIrrimetEventsState = initialState, action: IActionWithPayload): IIrrimetEventsState {
  switch (action.type) {
    case IrrimetActionTypes.SET_IRRIGATION_EVENTS:
      return <IIrrimetEventsState>{
        ...state,
        irrigationEvents: action.payload
      };
    case IrrimetActionTypes.SET_CALENDAR_EVENTS:
      action.payload.forEach((calendarEvent, index) => {
        if (!calendarEvent.hasOwnProperty('end') && calendarEvent.eventType === 'manual') {
          action.payload[index].end = moment(calendarEvent.start).add(30, 'minute').toDate();
        }
      });
      return <IIrrimetEventsState>{
        ...state,
        calendarEvents: action.payload
      };
    case IrrimetActionTypes.SET_VIEW_DATE:
      return <IIrrimetEventsState>{
        ...state,
        viewDate: action.payload
      };
    default:
      return state;
  }
}
