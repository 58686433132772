import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';

import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';

import { StoreModule } from '@ngrx/store';
import { reducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';

import { SoilMoistureRoutingModule } from './soil-moisture-routing.module';
import { SoilMoistureComponent } from './soil-moisture.component';
import { SoilMoistureContentComponent } from './containers/soil-moisture-content/soil-moisture-content.component';
import { SoilMoistureHeaderComponent } from './containers/soil-moisture-header/soil-moisture-header.component';
import { SoilMoistureChartsComponent } from './containers/soil-moisture-charts/soil-moisture-charts.component';
import { SoilMoistureTableComponent } from './containers/soil-moisture-table/soil-moisture-table.component';
import { SoilMoistureToolbarComponent } from './containers/soil-moisture-toolbar/soil-moisture-toolbar.component';

import { Effects } from './effects';
import { StationDataExportService } from '../../shared/services/export/station-data-export.service';
import { SoilMoistureViewEditorComponent } from './containers/soil-moisture-view-editor/soil-moisture-view-editor.component';
import { SoilMoisturePlotbandEditorComponent } from './containers/soil-moisture-plotband-editor/soil-moisture-plotband-editor.component';
import { SoilMoistureModalComponent } from './containers/soil-moisture-modal/soil-moisture-modal.component';
import { SoilMoistureSensorSettingsComponent } from './containers/soil-moisture-sensor-settings/soil-moisture-sensor-settings.component';
import {
  SensorSettingsColumnComponent
} from './containers/soil-moisture-sensor-settings/sensor-settings-column/sensor-settings-column.component';



@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SoilMoistureRoutingModule,
    TranslateModule.forChild(),
    StoreModule.forFeature('soilMoisture', reducers),
    EffectsModule.forFeature([Effects]),
    AgGridModule.withComponents([]),
    ChartModule
  ],
  declarations: [
    SoilMoistureComponent,
    SoilMoistureContentComponent,
    SoilMoistureHeaderComponent,
    SoilMoistureChartsComponent,
    SoilMoistureTableComponent,
    SoilMoistureToolbarComponent,
    SoilMoistureViewEditorComponent,
    SoilMoisturePlotbandEditorComponent,
    SoilMoistureModalComponent,
    SoilMoistureSensorSettingsComponent,
    SensorSettingsColumnComponent
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting] },
    StationDataExportService
  ],
  exports: [
    SensorSettingsColumnComponent,
    SoilMoistureSensorSettingsComponent
  ]
})
export class SoilMoistureModule { }
