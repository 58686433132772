<app-form-layout [shadow]="false">
  <div class="station-data-info alert alert-info" *ngIf="!showTable && !loadingData">
    {{ 'To show trip data, click on the ' | translate }}
    <span class="fa fa-table"></span>
    {{ ' icon in the left menu.' | translate }}
  </div>

  <div class="station-data-header" *ngIf="showTable">
    <div>
      <h2>{{selectedTrip?.name}} {{ 'data' | translate }}</h2>
      <small>
        <span class="fa fa-fw fa-calendar-check-o"></span> {{selectedTrip?.tripStart}}<br />
        <span class="fa fa-fw fa-calendar-times-o"></span> {{selectedTrip?.tripEnd}}
      </small>
    </div>
    <p>
      {{ 'Distance traveled' | translate }}: {{selectedTrip?.distance}} {{selectedTrip?.unit}}<br />
      {{ 'Time traveled' | translate }}: {{selectedTrip?.time}}
    </p>
  </div>

  <div class="station-data-loading" *ngIf="loadingData">
    <h4><i class="fa fa-cog fa-spin fa-fw"></i> {{ 'Please wait, loading data ...' | translate }}</h4>
  </div>

  <ag-grid-angular #agGrid
                   *ngIf="showTable && dataGridOptions"
                   class="table ag-theme-bootstrap"
                   (selectionChanged)="selectionChanged($event)"
                   [gridOptions]="dataGridOptions">
  </ag-grid-angular>
</app-form-layout>
