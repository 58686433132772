<div class="modal-container">
  <div class="modal-header">
    <button aria-label="Close" class="close" type="button" (click)="handleCancel()">
      <span aria-hidden="true">×</span>
    </button>

    <h3 class="modal-title text-center">{{ 'Clear image measurements' | translate }}</h3>
  </div>

  <div class="modal-body">
    <div class="body-text">
      {{
        "When the measurements are clean, the system will not track any of the previous rectangles " +
        "in this picture anymore. Are you sure you want to clear the measurements of this picture?" | translate
      }}
    </div>

    <div class="button-wrapper">
      <div class="button">
        <app-custom-button [color]="'blue'"
                           [content]="'Cancel' | translate"
                           (click)="handleCancel()"></app-custom-button>
      </div>

      <div class="button">
        <app-custom-button [color]="'red'"
                           [content]="'Clear' | translate"
                           (click)="handleConfirm()">
          <i class="fa fa-fw fa-eraser"></i>
        </app-custom-button>
      </div>
    </div>
  </div>
</div>
