import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {
  IWeatherForecastSettingsModePayload,
  IWeatherForecastSettingsPayload,
  IWeatherForecastSettingsState,
} from '../models/models';
import {WeatherForecastSettingsActionTypes} from '../actions/weather-forecast-settings';
import {WeatherForecastSettingsHelper} from './helpers/weather-forecast-settings.helper';

export const initialState: IWeatherForecastSettingsState = {
  settings: {},
  activity: {
    isChartActive: true,
    isTableActive: true,
    imageExportInProgress: false,
  },
};

export function reducer(state: IWeatherForecastSettingsState = initialState, action: IActionWithPayload): IWeatherForecastSettingsState {
  switch (action.type) {
    case WeatherForecastSettingsActionTypes.SET_WEATHER_FORECAST_SETTINGS_IS_CHART_ACTIVE:
      return <IWeatherForecastSettingsState>{
        ...state,
        activity: {
          ...state.activity,
          isChartActive: action.payload
        }
      };
    case WeatherForecastSettingsActionTypes.SET_WEATHER_FORECAST_SETTINGS_IS_TABLE_ACTIVE:
      return <IWeatherForecastSettingsState>{
        ...state,
        activity: {
          ...state.activity,
          isTableActive: action.payload
        }
      };
    case WeatherForecastSettingsActionTypes.SET_WEATHER_FORECAST_IMAGE_EXPORT_IN_PROGRESS:
      return <IWeatherForecastSettingsState>{
        ...state,
        activity: {
          ...state.activity,
          imageExportInProgress: action.payload
        }
      };
    case WeatherForecastSettingsActionTypes.SET_WEATHER_FORECAST_SETTINGS:
      const settings: IWeatherForecastSettingsPayload = <IWeatherForecastSettingsPayload>action.payload;
      const settingsHelper = new WeatherForecastSettingsHelper(state);
      if (settingsHelper.stateHasDisabledGroupId(settings)) {
        settingsHelper.enableGroupId(settings);
      } else {
        settingsHelper.disableGroupId(settings);
      }
      return {...state};
    case WeatherForecastSettingsActionTypes.SET_WEATHER_FORECAST_SETTINGS_MODE:
      const payload: IWeatherForecastSettingsModePayload = <IWeatherForecastSettingsModePayload>action.payload;
      if (!state.settings[payload.stationId]) {
        state.settings[payload.stationId] = {
          modes: {
            [payload.mode]: {
              disabledGroupIds: {}
            }
          },
          selectedMode: payload.mode
        };
      }
      if (!state.settings[payload.stationId].modes[payload.mode]) {
        state.settings[payload.stationId].modes[payload.mode] = {disabledGroupIds: {}};
      }
      state.settings[payload.stationId].selectedMode = payload.mode;
      return {...state};
    default:
      return state;
  }
}
