import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { IServerAlert } from '../../../server-alerts/interfaces/server-alert';
import { deleteServerAlert, updateServerAlert } from '../../actions/alerts';
import * as fromAlerts from '../../reducers/alerts';
import { IStation } from './../../../../core/models/stations';
import { alertShortTypes, channelOptions, resendOptions } from './../../../server-alerts/constants/server-alerts';
import { AlertsService } from './../../services/alerts.service';

@Component({
  selector: '[app-alerts-row]',
  templateUrl: './alerts-row.component.html',
  styleUrls: ['./alerts-row.component.scss']
})

export class AlertsRowComponent implements OnInit, OnDestroy {
  @Input()
  public alert: IServerAlert;
  @Input()
  public stations: IStation[];

  @Input()
  public sensorData: any;

  public tooltipText: string;
  public emailTargetActive: boolean = false;
  public mobileTargetActive: boolean = false;
  public customName: string;
  public alertForm: FormGroup;
  public channelOptions = channelOptions;
  public alertTypes = alertShortTypes;
  public resendOptions = resendOptions;
  public destroy$: Subject<boolean> = new Subject();

  public readonly formatError = 'Format issue - please enter the right format (1, -1.2, 3.45)';

  constructor(
    private alertsStore: Store<fromAlerts.IAlertsState>,
    private fb: FormBuilder,
    private alertsService: AlertsService
  ) { }

  public get threshold(): AbstractControl {
    return this.alertForm.get('threshold');
  }

  public ngOnInit(): void {
    if (this.alert) {
      this.customName = this.stations.filter(station => station.name.original === this.alert.nm)[0].name.custom;
      if (this.alert.targets.some((target) => target.method === 'email')) {
        this.emailTargetActive = true;
        const emailTarget = this.alert.targets.filter((target) => target.method === 'email')[0];
        // tslint:disable-next-line:max-line-length
        this.tooltipText = emailTarget.destination !== 'true' && emailTarget.destination !== 'false' && emailTarget.destination !== '' ? emailTarget.destination : null;
      }
      this.mobileTargetActive = this.alert.targets.some((target) => target.method === 'push_mobile');

      this.alertForm = this.fb.group({
        type: this.alert.type,
        threshold: [this.alert.thresholds.join(', '), this.alertsService.checkThreshold()],
        resend: this.alert.resendPeriod,
        active: this.alert.active || false,
        source: this.alert.source || ''
      });
    }
  }

  public updateAlert(): void {
    if (this.alertForm.valid && this.alert) {
      let invalid = true;

      const { threshold, resend, ...data } = this.alertForm.value;
      const thresholds = threshold.split(',').map(Number);
      const resendPeriod = Number(resend);

      if (thresholds && thresholds.length) {
        invalid = thresholds.some(_ => _ !== _);
      }

      if (!invalid) {
        const alert = { ...this.alert, ...data, thresholds, resendPeriod };

        delete alert.sensorName;
        delete alert.sensorUnit;
        delete alert.thresholdUserUnit;
        delete alert.thresholdDefaultUnit;
        delete alert.sensorData;
        delete alert.customSensorName;

        this.alertsStore.dispatch(updateServerAlert({
          id: this.alert.nm,
          alert: alert
        }));
      }
    }
  }

  public removeAlert(): void {

    const id = this.alert.nm;
    const alert = this.alert;
    delete alert.sensorName;
    delete alert.sensorUnit;

    if (alert && alert._id) {
      this.alertsStore.dispatch(deleteServerAlert({ id, alert }));
    }
  }

  public ngOnDestroy(): void {
      this.destroy$.next(true);
      this.destroy$.complete();
  }

}
