import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ICropZone } from '../../../models/cropzones';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { Store } from '@ngrx/store';
import { IAccount } from '../../../reducers/account';
import { updatedStarredCropzone } from '../../../actions/account';

export interface ISearchWidgetCropZoneItem {
    name: string;
    crop_name: string;
    id: string;
    farm: { id: string, name: string };
    field: { id: string, name: string };
    year: string;
    boundary: any;
    to: string;
    starred?: boolean;
}

@Component({
    selector: 'app-search-widget-list-cropzone-item',
    templateUrl: './search-widget-list-cropzone-item.component.html',
    styleUrls: ['./search-widget-list-cropzone-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SearchWidgetListCropZoneItemComponent implements OnInit, OnDestroy {
    @Input()
    public item: ISearchWidgetCropZoneItem;
    @Input()
    public isSelected: boolean = false;
    @Input()
    public cropzones: ICropZone[];
    @Output()
    public selectedCropzone: EventEmitter<ICropZone> = new EventEmitter<ICropZone>();
    public urlToNavigate: string;
    private destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private navigationService: NavigationService,
        private cd: ChangeDetectorRef,
        private router: Router,
        private accountStore: Store<IAccount>) { }

    public ngOnInit(): void {
        this.router.events.pipe(
            takeUntil(this.destroy$),
            filter(event => event instanceof NavigationEnd)
        ).subscribe((): void => {
            this.setUrlToNavigate();
        });
        this.setUrlToNavigate();
    }

    public emitSelectClickCropzone(cropzone: any): void {
        this.selectedCropzone.emit({
          name: cropzone.name,
          crop_name: cropzone.crop_name,
          field: cropzone.field,
          farm: cropzone.farm,
          id: cropzone.id,
          year: cropzone.year,
          to: cropzone.to,
          from: cropzone.from,
          boundary: cropzone.boundary,
          soil: cropzone.soil,
          crop: {...cropzone.crop},
          rain_efficacies: cropzone.rain_efficacies,
          data_sources: cropzone.data_sources,
          irrigation_events: cropzone.irrigation_events
        });
    }


    private setUrlToNavigate(): void {
        this.urlToNavigate = this.navigationService.prepareNavigateToCropzoneUrl(this.item.id);
        this.cd.markForCheck();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    public navigateUrl(event: Event): void {
        event.stopPropagation();
        this.router.navigate([this.urlToNavigate]);
        this.emitSelectClickCropzone(this.item);
    }

    public toggleFavorite(event: Event): void {
        event.stopPropagation();

        const filteredCZ = this.cropzones.filter((cropzone: ICropZone) => cropzone.id === this.item.id)[0];
        const newCropzone = {
            ...filteredCZ,
            starred: !this.item.starred
        };

        this.accountStore.dispatch(updatedStarredCropzone(newCropzone));
    }
}
