<div class="form-input" [class.inline-me]="inline">

  <span class="input-icon" *ngIf="icon">
    <i [ngClass]="[icon]"></i>
  </span>

  <input [attr.placeholder]="placeholder | translate"
         [ngClass]="[type, size]"
         [ngStyle]="styles"
         (input)="onChange($event)"
         [(ngModel)]="value"
         [min]="minValue"
         [max]="maxValue"
         [attr.step]="step"
         [attr.type]="contentType"
         [attr.disabled]="isDisabled ? '' : null"
         [autocomplete]="isAutocompleteOn ? 'on' : 'new-password'">

  <span class="suffix" *ngIf="suffix">{{ suffix }}</span>

</div>
