import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IOptions } from '../../../../shared/interfaces';
import { saveForecastActivation } from '../../actions/product-activation';
import {
  IForecastActivation,
  IProductActivationState,
  IProductConfigurationItem,
  IValidLicense
} from '../../models/product-activation.models';

@Component({
  selector: 'app-product-activation-forecast',
  templateUrl: './product-activation-forecast.component.html',
  styleUrls: ['./product-activation-forecast.component.scss']
})
export class ProductActivationForecastComponent {
  @Input()
  public configuration: IProductConfigurationItem;
  @Input()
  public availableUserStations$: Observable<IOptions[]>;
  @Input()
  public licensesOverlapped: IValidLicense[];

  public constructor(private productActivationStore: Store<IProductActivationState>) { }

  public activateProduct(activation: IForecastActivation): void {
    this.productActivationStore.dispatch(saveForecastActivation(activation));
  }
}
