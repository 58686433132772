import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IInfo } from '../../../../../../core/models/account';

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss']
})
export class BasicInfoComponent implements OnInit, OnDestroy {
  @Input()
  public basicInfo               : IInfo;
  @Output()
  private basicInfoChange        : EventEmitter<IInfo> = new EventEmitter();
  public form                    : FormGroup;
  private alive$                 : Subject<boolean> = new Subject<boolean>();

  constructor(private formBuilder: FormBuilder) { }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      'title': [this.basicInfo.title || ''],
      'name': [this.basicInfo.name || ''],
      'lastname': [this.basicInfo.lastname || ''],
      'email': [this.basicInfo.email || ''],
      'cellphone': [this.basicInfo.cellphone || ''],
      'phone': [this.basicInfo.phone || ''],
      'fax': [this.basicInfo.fax || ''],
    });

    this.basicInfoChange.emit(this.form.value);

    this.form.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this.alive$),
    ).subscribe((form: IInfo) => {
      this.basicInfoChange.emit(form);
    });
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }

}
