import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { WorkPlanningActionTypes } from '../actions';
import { IWorkPlanningState } from '../models/work-planning.models';

const initialState: IWorkPlanningState = {
  chartsOptions: [],
  isLoading: true,
  isError: false,
  grid: {},
  licenses: {
    workPlanningLicenses: [],
    tropicalSowingLicenses: [],
    grassFireDangerLicenses: []
  },
  warning: null
};

export function reducer(state: IWorkPlanningState = initialState, action: IActionWithPayload): IWorkPlanningState {
  switch (action.type) {
    case WorkPlanningActionTypes.SET_WORK_PLANNING_CHARTS:
      return <IWorkPlanningState>{
        ...state,
        chartsOptions: action.payload.chartsOptions,
        grid: action.payload.grid,
        service: action.payload.service
      };
    case WorkPlanningActionTypes.SET_WORK_PLANNING_LOADING:
      return <IWorkPlanningState>{
        ...state,
        isLoading: action.payload
      };
    case WorkPlanningActionTypes.SET_WORK_PLANNING_ERROR:
      return <IWorkPlanningState>{
        ...state,
        isError: action.payload
      };
    case WorkPlanningActionTypes.SET_STATION_WORK_PLANNING_LICENSES:
      let workPlanningLicenses = [];
      let tropicalSowingLicenses = [];
      let grassFireDangerIndexLicenses = [];

      if (action.payload['WorkPlanning']) {
        workPlanningLicenses = action.payload['WorkPlanning'];
      }
      if (action.payload['TropicalSowingWindow']) {
        tropicalSowingLicenses = action.payload['TropicalSowingWindow'];
      }
      if (action.payload['GrassFireDangerIndex']) {
        grassFireDangerIndexLicenses = action.payload['GrassFireDangerIndex'];
      }

      return <IWorkPlanningState>{
        ...state,
        licenses: {
          workPlanningLicenses: workPlanningLicenses,
          grassFireDangerLicenses: grassFireDangerIndexLicenses,
          tropicalSowingLicenses: tropicalSowingLicenses
        }
      };
    case WorkPlanningActionTypes.SET_WARNING_MESSAGE:
      return <IWorkPlanningState>{
        ...state,
        warning: action.payload
      };
    default:
      return state;
  }
}
