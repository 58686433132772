import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromStationCommunication from './station-communication';
import {IStationCommunicationState} from './station-communication';

export const stationCommunication = createFeatureSelector<IStationCommunicationState>('stationCommunication');

export const reducers = fromStationCommunication.reducer;

export const selectStationCommunication = createSelector(
  stationCommunication,
  (stationCommunicationState: IStationCommunicationState) => stationCommunicationState.stationCommunication
);

export const selectStationCommunicationLoading = createSelector(
  stationCommunication,
  (stationCommunicationState: IStationCommunicationState) => stationCommunicationState.isLoading
);

export const selectStationCommunicationError = createSelector(
  stationCommunication,
  (stationCommunicationState: IStationCommunicationState) => stationCommunicationState.isError
);
