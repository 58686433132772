import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Column, ColumnGroup, GridOptions, ICellRendererComp, ICellRendererParams } from 'ag-grid';
import * as moment from 'moment';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { setNotify } from '../../../../core/actions/notify';
import { ISelectedSearchWidgetItem } from '../../../../core/models/selectedSearchWidgetItem';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation, selectSelectedStation } from '../../../../core/reducers';
import { IAccount } from '../../../../core/reducers/account';
import { selectSettings } from '../../../../core/reducers/index';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { INotifyState } from '../../../../core/reducers/notify';
import * as fromSelectedStation from '../../../../core/reducers/selectedStation';
import { LeftComponentsTogglerService } from '../../../../core/services/left-components-toggler/left-components-toggler.service';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { ITreeStructure } from '../../../../services/tree/models';
import { TreeService } from '../../../../services/tree/tree.service';
import { DataGridOptions, RAIN_CORRECTOR_CLEAN_PRECIPITATION_MODAL_ID } from '../../../../shared/constants';
import { IOptions } from '../../../../shared/interfaces';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { ITreeSettingsState } from '../../../../shared/tree/models/tree.models';
import { dateToUtcUnixTimestamp, formatDate } from '../../../../shared/utils/dateFormat';
import { DefaultPeriodScope, PeriodValuesByScope } from '../../../station-data/constants/constants';
import { IGetStationDataFcRequest, IStationDataProfile } from '../../../station-data/models/station-data.models';
import {
  selectStationDataGrid, selectStationDataProfiles, selectStationDataSettings,
  selectStationDataTableActive, selectTreeSettings
} from '../../../station-data/reducers';
import * as fromStationData from '../../../station-data/reducers/station-data';
import * as fromStationSettings from '../../../station-data/reducers/station-data-settings';
import { IGetRainCorrectorRequest, IUpdateCleanPrecipitation } from '../../models/rain-corrector-data.module';

@Component({
  selector: 'app-precipitation-data-table',
  templateUrl: './precipitation-data-table.component.html',
  styleUrls: ['./precipitation-data-table.component.scss']
})
export class PrecipitationDataTableComponent implements OnInit, OnDestroy {

  public destroy$: Subject<boolean> = new Subject();
  public periodForm: FormGroup;
  private initDestroy$: Subject<boolean> = new Subject<boolean>();

  public station: IStation;
  public fromStation: Date = null;
  public toStation: Date = null;
  public periodScopeSelectItems: Array<IOptions> = Object.keys(PeriodValuesByScope).map((item: string) => ({
    content: item,
    value: item
  }));

  public periodValueSelectItems: Array<IOptions> = PeriodValuesByScope[DefaultPeriodScope];

  public tree$: Observable<ITreeStructure>;
  public state$: Observable<string>;
  public navigationStation: IStation;
  public loadingData = false;

  public stationData: any = {};
  public isDataTableActive$: Observable<boolean>;
  public isTableHidden: boolean = false;
  public dataGridOptions: GridOptions = DataGridOptions;
  public rainCorrectorData: any = null;
  public columnDefs: any;
  public unitSystem: string;

  public resultantJSON: any;
  public cValue: number = 0;
  public channelValue: number = 0;
  public alteredData: any = [];
  public uniqueAlteredData: any = new Map();
  public showApply: boolean = false;
  public cleanPrecipitationModelId = RAIN_CORRECTOR_CLEAN_PRECIPITATION_MODAL_ID;
  public dateRange: any = {};

  private tree: any = {};
  private profile: string = 'All Sensors';

  private navigationStation$: Observable<IStation> = this.navigationStationStore.pipe(
    takeUntil(this.destroy$),
    select(selectNavigationStation)
  );

  constructor(
    private treeService: TreeService,
    private leftComponentsToggler: LeftComponentsTogglerService,
    private stationDataStore: Store<fromStationData.IStationDataState>,
    private stationSettingsStore: Store<fromStationSettings.IStationDataSettingsState>,
    private treeSettingStore: Store<ITreeSettingsState>,
    private navigationStationStore: Store<INavigationStationState>,
    private selectedStationStore: Store<fromSelectedStation.ISelectedStationState>,
    private treeSettingsStore: Store<ITreeSettingsState>,
    private accountStore: Store<IAccount>,
    private fb: FormBuilder,
    private modalService: ModalService,
    private api: ApiCallService,
    private notify: Store<INotifyState>
  ) { }

  private get period(): AbstractControl {
    return this.periodForm.get('period');
  }

  private get activity(): AbstractControl {
    return this.periodForm.get('activity');
  }

  public get fromDatepicker(): AbstractControl {
    return this.period.get('fromDatepicker');
  }

  public get toDatepicker(): AbstractControl {
    return this.period.get('toDatepicker');
  }

  public get isTableActive(): AbstractControl {
    return this.activity.get('isTableActive');
  }

  public get isLastDataMode(): AbstractControl {
    return this.period.get('isLastDataMode');
  }

  public get periodScope(): AbstractControl {
    return this.period.get('periodScope');
  }

  public get periodValue(): AbstractControl {
    return this.period.get('periodValue');
  }

  public get fromTo(): AbstractControl {
    return this.period.get('fromTo');
  }

  public get stationId(): AbstractControl {
    return this.period.get('stationId');
  }

  private toggleColumns(): void {
    if (!this.dataGridOptions.api) {
      return;
    }
    if (this.dataGridOptions.columnApi != null) {
      this.dataGridOptions.columnApi.getAllColumns().forEach(
        (c: Column) => {
          if (c.getId() === 'val' || c.getId() === 'dt') {
            this.dataGridOptions.columnApi.setColumnVisible(c.getId(), true);
          } else {
            this.dataGridOptions.columnApi.setColumnVisible(c.getId(), false);
          }
        }
      );

      Object.keys(this.tree).forEach((key: string) => {
        const gr: ColumnGroup = this.dataGridOptions.columnApi.getColumnGroup(key);
        if (gr) {
          gr.getOriginalColumnGroup().getChildren().forEach((column) => {
            if (column.isVisible()) {
              this.dataGridOptions.columnApi.setColumnVisible(column.getId(), false);
            }
          });
        } else {
          this.dataGridOptions.columnApi.setColumnVisible(key, false);
        }
      });
    }
  }

  public decimalFormatter(params: any): any {
    return Number.isInteger(params.value) ?
      params.value.toFixed(2) :
      parseFloat(params.value).toFixed(2);
  }

  public decimalFormat(value): any {
    value = value <= 0 || value === null ? '0.0' : Number(Math.trunc( value * 10 ) / 10).toFixed(1);
    const splitArray = value.toString().split('.');
    value = splitArray[0].substring(0, 3) + '.' + splitArray[1];
    const regexp = /^[0-9]{1,3}(?:\.[0-9]{0,1})?$/;
    return regexp.test(value) ? value : '0.0';
  }

  public ngOnInit(): void {
    this.periodForm = this.fb.group({
      'period': this.fb.group({
        'periodScope': ['hourly', [Validators.required]],
        'periodValue': ['2d', [Validators.required]],
        'fromTo': [null, [Validators.required]],
        'fromDatepicker': [null, [Validators.required]],
        'toDatepicker': [null, [Validators.required]],
        'stationId': ['', [Validators.required]],
        'isLastDataMode': [false, [Validators.required]]
      }),
      'activity': this.fb.group({
        'isChartActive': [true, [Validators.required]],
        'isTableActive': [true, [Validators.required]],
        'isExportImageActive': [false, [Validators.required]],
        'isExportActive': [false, [Validators.required]],
      })
    });

    this.tree$ = this.treeService.getStationSettingsTreeStructure().pipe(
      takeUntil(this.destroy$)
    );
    this.state$ = this.leftComponentsToggler.getStationDataContentState().pipe(
      takeUntil(this.destroy$)
    );
    this.navigationStation$.subscribe((station: IStation): void => {
      this.navigationStation = station;
    });
    this.stationSettingsStore.pipe(
      takeUntil(this.initDestroy$),
      select(selectStationDataSettings)
    ).subscribe((settings: fromStationSettings.IStationDataSettingsState) => {
      this.initDestroy$.next(true);
      this.periodForm.setValue({
        period: settings.period,
        activity: settings.activity
      });
    });

    this.accountStore.pipe(
      select(selectSettings),
      filter((settings) => !!settings)
    ).subscribe(settings => this.unitSystem = settings.unit_system);

    this.subscribeToTreeAndSelectedStation();

    this.periodScope.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged()
    )
      .subscribe((scope: string) => {
        this.periodValueSelectItems = PeriodValuesByScope[scope];

        setTimeout(() => this.periodValue.setValue(this.periodValueSelectItems[0].value), 0);
      });

    this.selectedStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectSelectedStation),
      switchMap((id: ISelectedSearchWidgetItem) => this.treeSettingsStore.pipe(
        takeUntil(this.destroy$),
        select(selectTreeSettings),
        filter((tree: ITreeSettingsState): boolean => !!tree
          && !!tree[id.original_name]
          && !!tree[id.original_name].profileSettings[this.profile]),
        tap((tree: ITreeSettingsState): void => {
          this.profile = tree[id.original_name].selectedProfile;
        }),
        map((tree: ITreeSettingsState) => tree[id.original_name].profileSettings[this.profile].disabledGroupIds)
      ))
    ).subscribe((tree: any) => {
      this.tree = tree;
      setTimeout(() => {
        this.toggleColumns();
      }, 0);
    });

    this.isDataTableActive$ = this.stationSettingsStore.pipe(
      takeUntil(this.destroy$),
      select(selectStationDataTableActive),
    );

    this.stationDataStore.pipe(
      takeUntil(this.destroy$),
      select(selectStationDataGrid),
      filter((data: object): boolean => !!data)
    ).subscribe((data: any) => {
      this.stationData = data;
      this.loadingData = false;
    });
  }

  public onGridReady(event: any) : void {
    this.dataGridOptions.api = event.api;
    if (this.rainCorrectorData) {
      this.setRainCorrectorData();
    }
  }

  public setRainCorrectorData() : void {
    this.dataGridOptions.api.setRowData(this.rainCorrectorData);
  }

  public refresh(): void {
    if (this.periodForm.invalid) {
      return;
    }
    this.loadingData = true;
    const request: IGetStationDataFcRequest = {
      from: null,
      to: null,
      stationId: this.stationId.value,
      timePeriod: this.periodScope.value,
      name: this.profile
    };
    request.from = dateToUtcUnixTimestamp(this.fromDatepicker.value);
    request.to = dateToUtcUnixTimestamp(this.toDatepicker.value);
    this.columnDefs = [
      {
        headerName: 'Date/Time',
        field: 'dt',
        enableColResize: true,
        enableSorting: true,
        rowHeight: 30,
        suppressMenu: true,
        headerHeight: 28,
        stopEditingWhenCellsLoseFocus: true,
        suppressContextMenu: true
      },
      {
        headerName: 'Precipitation (mm)',
        field: 'val',
        suppressMenu: true,
        resizable: false,
        editable: true,
        suppressClickEdit: true,
        cellEditorPopup: false,
        suppressMovable: true,
        stopEditingWhenCellsLoseFocus: true,
        cellEditor: 'agPopupTextCellEditor',
        cellRenderer: this.getRenderer(),
      }
    ];
    if (this.unitSystem === 'imperial') {
      this.columnDefs.map(item => {
        if (item.field === 'val') {
          item.headerName = 'Precipitation (In)';
        }
      });
    }

    this.dataGridOptions.columnDefs = this.columnDefs;

    this.dataGridOptions = {
      ...this.dataGridOptions,
      suppressClickEdit: true,
      onGridReady: this.onGridReady.bind(this),
    };
    this.getRainPrecipitationData();
  }

  public getRainPrecipitationData(): any {
    const rainRequest: IGetRainCorrectorRequest = {
      stationId: this.stationId.value,
      from: dateToUtcUnixTimestamp(this.fromDatepicker.value),
      to: dateToUtcUnixTimestamp(this.toDatepicker.value),
    };
    if (this.fromDatepicker.value != null && this.dataGridOptions.api != null) {
      this.api.getRainCorrectorData(rainRequest).subscribe(response => {
        if (response) {
          this.rainCorrectorData = response;
          if (response.length !== 0) {
            this.cValue = response[0].c;
            this.channelValue = response[0].ch;
          }
          this.dataGridOptions.api.setRowData(response);
          this.loadingData = false;
          this.toggleColumns();
        }
        catchError(() => of(
          setNotify('Rain corrector data is currently unavailable')
        ));
      });
    }
  }

  public cleanPrecipitation(): any {
    this.openModal(this.cleanPrecipitationModelId);
  }

  public openModal(modalId: string): void {
    this.dateRange = {
      fromDate: this.fromDatepicker.value,
      toDate: this.toDatepicker.value,
    };
    this.modalService.openModal(modalId);
  }

  public closeModal(modalId: string): void {
    this.modalService.closeModal(modalId);
  }

  public cleanConfirm(): void {
    const range : IUpdateCleanPrecipitation = {
      dateStart : formatDate(this.fromDatepicker.value),
      dateEnd : formatDate(this.toDatepicker.value)
    };
    this.api.cleanRainCorrectorData(this.stationId.value, range).pipe(
      tap(() => this.refresh())
    ).subscribe(
      () => this.notify.dispatch(setNotify('The rain data were cleared successfully')),
      () => this.notify.dispatch(setNotify('The rain data were not cleared, due to the error'))
    );
    this.closeModal(this.cleanPrecipitationModelId);
  }

  public apply(): void {
    this.alteredData = [];
    this.uniqueAlteredData.forEach((value, key) => {
      const entry = { dt: key, c: this.cValue, ch: this.channelValue, v: Number(value) };
      this.alteredData.push(entry);
    });
    this.resultantJSON = {
      corrections: this.alteredData
    };
    this.resultantJSON = JSON.stringify(this.resultantJSON, null);
    this.api.updateRainCorrectorData(this.stationId.value, this.alteredData).pipe(
      takeUntil(this.destroy$),
    ).subscribe(
      () => this.notify.dispatch(setNotify('The rain corrector data was updated successfully')),
      () => this.notify.dispatch(setNotify('Error in saving the rain corrector data'))
    );
    this.uniqueAlteredData = new Map();
    this.alteredData = [];
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.initDestroy$.next(true);
    this.initDestroy$.complete();
  }

  private subscribeToTreeAndSelectedStation(): void {
    combineLatest([
      this.getSelectedStationObservable(),
      this.getTreeSettingsObservable(),
      this.stationDataStore.pipe(select(selectStationDataProfiles))
    ])
      .subscribe(([station, tree, profiles]: [IStation, ITreeSettingsState, IStationDataProfile[]]) => {
        const profileWasChanged: boolean = !!tree[station.name.original] && this.profile !== tree[station.name.original].selectedProfile;
        this.fromStation = moment(station.dates.min_date).toDate();
        this.toStation = moment(station.dates.max_date).toDate();
        if (station.name.original === this.stationId.value && !profileWasChanged) {
          return;
        }
        this.loadingData = false;
        this.stationId.setValue(station.name.original);
        if (profileWasChanged) {
          this.profile = tree[station.name.original].selectedProfile;
          const profile = profiles.find(p => p.name === this.profile);
          if (profile) {
            this.periodValue.setValue(profile.period);
            if (this.isLastDataMode.value) {
              this.periodScope.setValue(profile.resolution);
            }
          }
        }

        setTimeout(() => {
          if (!this.station || station.name.original !== this.station.name.original) {
            this.fromDatepicker.setValue(moment(this.toStation).subtract(1, 'day').toDate());
            this.toDatepicker.setValue(moment(this.toStation).toDate());
            this.fromTo.setValue({
              from: moment(this.toStation).subtract(2, 'day').toDate(),
              to: this.toStation
            });
          }
          this.refresh();
          this.station = station;
        });
      });
  }

  private getSelectedStationObservable(): Observable<IStation> {
    return this.navigationStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((s: IStation) => !!s)
    );
  }

  private getTreeSettingsObservable(): Observable<ITreeSettingsState> {
    return this.treeSettingStore.pipe(
      takeUntil(this.destroy$),
      select(selectTreeSettings)
    );
  }

  public onCellValueChanged(event): any {
    this.showApply = true;
    const date = event.data.dt;
    const value = this.decimalFormat(event.value);
    this.uniqueAlteredData.set(date, value);
  }

  public getRenderer(): any {
    class CellRenderer implements ICellRendererComp {
      private eGui: any;
      private eButton: any;
      private params!: ICellRendererParams;
      private buttonClickListener!: () => void;

      private createGui(): any {
        const template =
          `<section style="display: grid; grid-template-columns: 85% 1fr;">
              <span id="theValue" style="padding-left: 4px;"></span>
              <span id="theButton" style="height: 100%; cursor:pointer;"><i class="zmdi zmdi-edit"></i></span>
          </section>`;
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = template;
        this.eGui = tempDiv.firstElementChild;
      }

      public init(params: ICellRendererParams): any {
        // create the gui
        this.createGui();
        // keep params, we use it in onButtonClicked
        this.params = params;

        // attach the value to the value span
        const eValue = this.eGui.querySelector('#theValue');

        eValue.innerHTML = this.decimalFormat(params.value);
        // setup the button, first get reference to it
        this.eButton = this.eGui.querySelector('#theButton');

        // bind the listener so 'this' is preserved, also keep reference to it for removal
        this.buttonClickListener = this.onButtonClicked.bind(this);
        // add the listener
        this.eButton.addEventListener('click', this.buttonClickListener);
      }

      private decimalFormat(value): any {
        value = value <= 0 || value === null ? '0.0' : Number(Math.trunc( value * 10 ) / 10).toFixed(1);
        const splitArray = value.toString().split('.');
        value = splitArray[0].substring(0, 3) + '.' + splitArray[1];
        const regexp = /^[0-9]{1,3}(?:\.[0-9]{0,1})?$/;
        return regexp.test(value) ? value : '0.0';
      }

      private onButtonClicked(): any {
        // start editing this cell. see the docs on the params that this method takes
        const startEditingParams = {
          rowIndex: this.params.rowIndex,
          colKey: this.params.column.getId()
        };
        this.params.api.startEditingCell(startEditingParams);
      }

      public getGui(): any {
        // returns our gui to the grid for this cell
        return this.eGui;
      }

      public refresh(): any {
        return false;
      }

      public destroy(): any {
        // be good, clean up the listener
        this.eButton.removeEventListener('click', this.buttonClickListener);
      }
    }
    return CellRenderer;
  }

}
