import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { deepClone } from '../../../../shared/utils/deepClone';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { getTimezones, setLocationSettings, updateStationLocation } from '../../actions/timezoneAndLocation';
import { DefaultStationPosition, IngestedStations } from '../../constants/constants';
import { selectLocation } from '../../reducers';
import * as fromTimezoneAndLocation from '../../reducers/timezoneAndLocation';
import { ITimezoneAndLocationState } from '../../reducers/timezoneAndLocation';

@Component({
  selector: 'app-timezone-and-location',
  templateUrl: './timezone-and-location.component.html',
  styleUrls: ['./timezone-and-location.component.scss']
})
export class TimezoneAndLocationComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  public timezoneOnly: boolean;

  @Input()
  public set station(value) {
    if (value && value.name) {
      this.isIngestedStation = this.ingestedStations.includes(
        value.name.original.substring(0, 3)
      );
    }

    this.currentStation = value;
  }

  public currentStation         : IStation;
  public isIngestedStation      = false;
  public ingestedStations       = IngestedStations;
  public locationConfig         : ITimezoneAndLocationState;
  public readonly readOnly      : string = 'r';
  public modalId                : string = generateId();

  private destroy$              : Subject<boolean> = new Subject<boolean>();

  constructor(
    private timezoneStore       : Store<fromTimezoneAndLocation.ITimezoneAndLocationState>,
    private modalService        : ModalService
  ) {
  }

  public ngOnInit(): void {
    this.timezoneStore.dispatch(getTimezones());
    this.timezoneStore.pipe(
      takeUntil(this.destroy$),
      select(selectLocation)
    ).subscribe(_ => this.locationConfig = _);
  }

  public openModal(): void {
    this.modalService.openModal(this.modalId);
  }

  public save(): void {
    const station: IStation = deepClone(this.currentStation);
    station.position = this.locationConfig.position;
    this.timezoneStore.dispatch(updateStationLocation(station));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.currentStation) {
      this.timezoneStore.dispatch(setLocationSettings(this.currentStation.position || DefaultStationPosition));
    }
  }

}
