import {IOrderSettingCropzoneItem} from '../../../../components/search-widget/models/search-widget.models';


export const farmsOrderSettings: Array<IOrderSettingCropzoneItem> = [
  {
    name: 'Cropzone',
    orderField: 'name',
    width: {'width': '100%'},
    default: true
  },
  {
    name: 'Field',
    orderField: 'field',
    width: {'width': '100%'}
  },
  {
    name: 'Farm',
    orderField: 'farm',
    width: {'width': '100%'},
  },
  {
    name: 'Year',
    orderField: 'year',
    width: {'width': '100%'}
  }
];
