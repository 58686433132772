import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../../shared/shared.module';
import { ServerAlertsRoutingModule } from './server-alerts-routing.module';
import { ServerAlertsComponent } from './server-alerts.component';
import { ServerAlertsListingComponent } from './containers/server-alerts-listing/server-alerts-listing.component';
import { ServerAlertsFormComponent } from './containers/server-alerts-form/server-alerts-form.component';
import { ServerAlertsEffects } from './effects/server-alerts.effects';
import { reducers } from './reducers';
import { ServerAlertsRowComponent } from './containers/server-alerts-row/server-alerts-row.component';

@NgModule({
  imports: [
    CommonModule,
    ServerAlertsRoutingModule,
    SharedModule,
    StoreModule.forFeature('serverAlerts', reducers),
    EffectsModule.forFeature([ServerAlertsEffects]),
    TranslateModule.forChild()
  ],
  exports: [
    ServerAlertsRowComponent
  ],
  declarations: [
    ServerAlertsComponent,
    ServerAlertsListingComponent,
    ServerAlertsFormComponent,
    ServerAlertsRowComponent
  ]
})
export class ServerAlertsModule { }
