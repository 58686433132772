import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {combineLatest, Observable, of, OperatorFunction} from 'rxjs';
import {from} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import * as moment from 'moment';
import {setNotify} from '../../../core/actions/notify';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {
  ICameraPest, ICameraPestFamily,
  ICameraPestGenus,
  ICameraPestOrder,
  IClearMeasurementsResponse,
  IDeleteImagePayload,
  IGetPhotosRequest,
  IGetPhotosRequestFromTo,
  IGetPhotosRequests,
  IPicture,
  IPictureSet,
  IPostCameraPestPayload,
  ISaveMeasurementsResponse,
  PhotoRequestType
} from '../../../shared/camera/models/camera';
import {getCameraFilterDate, getMonthFromDate} from '../../../shared/utils/dateFormat';
import {ApiCallService} from '../../../services/api/api-call.service';
import {setCropViewError, setCropViewLoading} from '../../crop-view/actions/crop-view';
import {
  emptyIscoutMeasurements,
  getIscoutPhotos,
  IscoutActionTypes,
  setIscoutError,
  setIscoutFirstDate,
  setIscoutGeneralPests,
  setIscoutLastDate,
  setIscoutLoading,
  setIscoutMeasurements,
  setIscoutPhotos,
  setIscoutUserPests as setIscoutCameraPest
} from '../actions/iscout';
import {
  getIscoutUserPests,
  IscoutPestsActionTypes, setIscoutPestsFamily,
  setIscoutPestsGenus,
  setIscoutPestsOrders,
  setIscoutUserPests as setIscoutFormPest,
  unselectIscoutPest
} from '../actions/iscout-pests';
import {
  IscoutSettingsActionTypes,
  setIscoutSettingsCurrentDateString,
  setIscoutSettingsPestToggle,
  setIscoutSettingsPestToggles
} from '../actions/iscout-settings';
import {DEFAULT_CAMM_ID} from '../../../core/constants/camera';
import {
  getIscoutGlueBoards,
  IscoutGlueBoardActionTypes,
  setIscoutGlueBoard,
  setIscoutGlueBoardAvailableDates,
  unselectIscoutGlueBoard
} from '../actions/iscout-glue-boards';
import {
  getIscoutSeasons,
  IscoutSeasonActionTypes,
  setIscoutSeasons,
  setIscoutSeasonsAvailableGlueBoards,
  unselectIscoutSeason
} from '../actions/iscout-seasons';

@Injectable()
export class IscoutService {
  constructor(private api: ApiCallService,
              private actions$: Actions) { }

  @Effect()
  public getIscoutFirstDate$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutActionTypes.GET_ISCOUT_FIRST_DATE),
    this.getIscoutBoundaryDateCallback('first')
  );

  @Effect()
  public getIscoutLastDate$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutActionTypes.GET_ISCOUT_LAST_DATE),
    this.getIscoutBoundaryDateCallback('last')
  );

  @Effect()
  public getIscoutPhotos$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutActionTypes.GET_ISCOUT_PHOTOS),
    switchMap((action: IActionWithPayload) => {
      setIscoutError(false);
      return this.performApiCallsForPhotos(action);
    })
  );

  @Effect()
  public saveIscoutMeasurements$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutActionTypes.SAVE_ISCOUT_PHOTOS_MEASUREMENTS),
    switchMap((action: IActionWithPayload) => this.api.saveCameraMeasurements(
      action.payload.stationId,
      action.payload.body,
    ).pipe(
      switchMap((res: ISaveMeasurementsResponse) => from([
        setIscoutMeasurements(res),
        getIscoutPhotos({
          type: PhotoRequestType.SINGLE_DATE_INTERVAL,
          camIds: [DEFAULT_CAMM_ID],
          stationId: res.station_id,
          date: getCameraFilterDate(res.date),
        }),
        setNotify('Measurements were saved')
      ])),
      catchError(() => of(setNotify('Could not save the measurements')))
    ))
  );

  @Effect()
  public clearIscoutMeasurements$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutActionTypes.CLEAR_ISCOUT_PHOTOS_MEASUREMENTS),
    switchMap((action: IActionWithPayload) => this.api.clearCameraMeasurements(
      action.payload.stationId,
      action.payload.body
    ).pipe(
      switchMap((res: IClearMeasurementsResponse) => from([
        emptyIscoutMeasurements(res),
        setNotify('Measurements were removed')
      ])),
      catchError(() => of(setNotify('Could not remove the measurements')))
    ))
  );

  @Effect()
  public deleteIscoutImage$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutActionTypes.DELETE_ISCOUT_PHOTOS_IMAGE),
    switchMap((action: IActionWithPayload) => {
      const payload: IDeleteImagePayload = action.payload;
      return this.api.deleteCameraImage(payload.deleteRequest).pipe(
        switchMap(() => from([
            setNotify('Image was deleted'),
            getIscoutPhotos(payload.refreshRequest),
          ])
        ),
        catchError(() => of(setNotify('Could not delete the selected image'))),
      );
    })
  );

  @Effect()
  public getPestsOrders$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutPestsActionTypes.GET_ISCOUT_PESTS_ORDERS),
    switchMap(() => this.api.getCameraPestsOrders().pipe(
      switchMap((apiResponse: Array<ICameraPestOrder>) => from([
        setIscoutPestsOrders(apiResponse),
        setIscoutLoading(false)
      ])),
      catchError(() => this.unsuccessfulApiCall())
    ))
  );

  @Effect()
  public getPestsFamily$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutPestsActionTypes.GET_ISCOUT_PESTS_FAMILY),
    switchMap(() => this.api.getCameraPestsFamily().pipe(
      switchMap((apiResponse: Array<ICameraPestFamily>) => from([
        setIscoutPestsFamily(apiResponse),
        setIscoutLoading(false)
      ])),
      catchError(() => this.unsuccessfulApiCall())
    ))
  );

  @Effect()
  public getPestsGenus$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutPestsActionTypes.GET_ISCOUT_PESTS_GENUS),
    switchMap(() => this.api.getCameraPestsGenus().pipe(
      switchMap((apiResponse: Array<ICameraPestGenus>) => from([
        setIscoutPestsGenus(apiResponse),
        setIscoutLoading(false)
      ])),
      catchError(() => this.unsuccessfulApiCall())
    ))
  );

  @Effect()
  public getUserPests$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutActionTypes.GET_ISCOUT_USER_PESTS, IscoutPestsActionTypes.GET_ISCOUT_USER_PESTS),
    switchMap((action: IActionWithPayload) => this.api.getCameraPests({stationId: action.payload, type: 'station'}).pipe(
      switchMap((apiResponse: Array<ICameraPest>) => from([
        setIscoutCameraPest(apiResponse),
        setIscoutFormPest(apiResponse),
        setIscoutLoading(false)
      ])),
      catchError(() => this.unsuccessfulApiCall()),
    ))
  );

  @Effect()
  public getGeneralPests$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutActionTypes.GET_ISCOUT_GENERAL_PESTS),
    switchMap((action: IActionWithPayload) => this.api.getCameraPests({stationId: action.payload, type: 'general'}).pipe(
      switchMap((apiResponse: Array<ICameraPest>) => from([
        setIscoutGeneralPests(apiResponse)
      ])),
      catchError(() => this.unsuccessfulApiCall()),
    ))
  );

  @Effect()
  public savePest$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutPestsActionTypes.SAVE_ISCOUT_PEST),
    switchMap((action: IActionWithPayload) => this.preparePostCameraPestCall(action, 'add'))
  );

  @Effect()
  public removePest$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutPestsActionTypes.REMOVE_ISCOUT_PEST),
    switchMap((action: IActionWithPayload) => this.preparePostCameraPestCall(action, 'remove'))
  );

  @Effect()
  public getPestToggles$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutSettingsActionTypes.GET_ISCOUT_SETTINGS_PEST_TOGGLES),
    switchMap((action: IActionWithPayload) => this.api.getPestToggles(action.payload).pipe(
      switchMap((response: any) => of(setIscoutSettingsPestToggles(response))),
      catchError(() => of(setNotify('Could not retrieve pest toggles'))),
    ))
  );

  @Effect()
  public updatePestToggle$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutSettingsActionTypes.UPDATE_ISCOUT_SETTINGS_PEST_TOGGLES),
    switchMap((action: IActionWithPayload) => this.api.savePestToggle(
      action.payload.stationId,
      action.payload.pestToggle
    ).pipe(
      switchMap((response: any) => from([
        setIscoutSettingsPestToggle(action.payload.pestToggle),
        setNotify(response)
      ])),
      catchError(() => of(setNotify('Could not update pest toggle'))),
    ))
  );

  @Effect()
  public getIscoutGlueBoards$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutGlueBoardActionTypes.GET_ISCOUT_GLUE_BOARDS),
    switchMap((action: IActionWithPayload) => this.api.getGlueBoards(action.payload).pipe(
      switchMap((response: any) => from([
        setIscoutGlueBoard(response),
        setIscoutLoading(false)
      ])),
      catchError(() => of(setNotify('Could not retrieve the glue boards'))),
    ))
  );

  @Effect()
  public getIscoutGlueBoardsAvailableDates$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutGlueBoardActionTypes.GET_ISCOUT_GLUE_BOARD_AVAILABLE_DATES),
    switchMap((action: IActionWithPayload) => this.api.getGlueBoardsAvailableDates(action.payload).pipe(
      switchMap((response: any) => of(setIscoutGlueBoardAvailableDates(response))),
      catchError(() => of(setNotify('Could not retrieve available dates'))),
    ))
  );

  @Effect()
  public saveIscoutGlueBoard$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutGlueBoardActionTypes.SAVE_ISCOUT_GLUE_BOARD),
    switchMap((action: IActionWithPayload) => this.api.saveGlueBoard(action.payload).pipe(
      switchMap(() => from ([
        unselectIscoutGlueBoard(),
        getIscoutGlueBoards(action.payload.nm),
        setNotify('Glue board was created')
      ])),
      catchError(({ error }) => of(setNotify(error.message))),
    ))
  );

  @Effect()
  public updateIscoutGlueBoard$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutGlueBoardActionTypes.UPDATE_ISCOUT_GLUE_BOARD),
    switchMap((action: IActionWithPayload) => this.api.updateGlueBoard(action.payload).pipe(
      switchMap(() => from ([
        unselectIscoutGlueBoard(),
        getIscoutGlueBoards(action.payload.nm),
        setNotify('Glue board was updated')
      ])),
      catchError(({ error }) => of(setNotify(error.message))),
    ))
  );

  @Effect()
  public exchangeIscoutGlueBoard$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutGlueBoardActionTypes.EXCHANGE_ISCOUT_GLUE_BOARD),
    switchMap((action: IActionWithPayload) => this.api.exchangeGlueBoard(action.payload).pipe(
      switchMap(() => from ([
        unselectIscoutGlueBoard(),
        getIscoutGlueBoards(action.payload.nm),
        setNotify('Glue board exchange is complete')
      ])),
      catchError(({ error }) => of(setNotify(error.message))),
    ))
  );

  @Effect()
  public removeIscoutGlueBoard$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutGlueBoardActionTypes.REMOVE_ISCOUT_GLUE_BOARD),
    switchMap((action: IActionWithPayload) => this.api.removeGlueBoard(action.payload).pipe(
      switchMap(() => from ([
        unselectIscoutGlueBoard(),
        getIscoutGlueBoards(action.payload.station_id),
        setNotify('Glue board was removed')
      ])),
      catchError(({ error }) => of(setNotify(error.message))),
    ))
  );

  @Effect()
  public getIscoutSeason$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutSeasonActionTypes.GET_ISCOUT_SEASONS),
    switchMap((action: IActionWithPayload) => this.api.getIscoutSeasons(action.payload).pipe(
      switchMap((response: any) => from([
        setIscoutSeasons(response),
        setIscoutLoading(false)
      ])),
      catchError(() => of(setNotify('Could not retrieve the seasons'))),
    ))
  );

  @Effect()
  public getIscoutSeasonAvailableGlueBoards$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutSeasonActionTypes.GET_ISCOUT_SEASONS_AVAILABLE_GLUE_BOARDS),
    switchMap((action: IActionWithPayload) => this.api.getIscoutSeasonsAvailableGlueBoards(action.payload).pipe(
      switchMap((response: any) => from([
        setIscoutSeasonsAvailableGlueBoards(response),
        setIscoutLoading(false)
      ])),
      catchError(() => of(setNotify('Could not retrieve available glue boards'))),
    ))
  );

  @Effect()
  public saveIscoutSeason$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutSeasonActionTypes.SAVE_ISCOUT_SEASON),
    switchMap((action: IActionWithPayload) => this.api.saveIscoutSeason(action.payload).pipe(
      switchMap(() => from ([
        unselectIscoutSeason(),
        getIscoutSeasons(action.payload.nm),
        setNotify('Season was created')
      ])),
      catchError(({ error }) => of(setNotify(error.message)))
    ))
  );

  @Effect()
  public updateIscoutSeason$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutSeasonActionTypes.UPDATE_ISCOUT_SEASON),
    switchMap((action: IActionWithPayload) => this.api.updateIscoutSeason(action.payload).pipe(
      switchMap(() => from ([
        unselectIscoutSeason(),
        getIscoutSeasons(action.payload.nm),
        setNotify('Season was updated')
      ])),
      catchError(({ error }) => of(setNotify(error.message)))
    ))
  );

  @Effect()
  public removeIscoutSeason$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(IscoutSeasonActionTypes.REMOVE_ISCOUT_SEASON),
    switchMap((action: IActionWithPayload) => this.api.removeIscoutSeason(action.payload).pipe(
      switchMap(() => from ([
        unselectIscoutSeason(),
        getIscoutSeasons(action.payload.station_id),
        setNotify('Season was removed')
      ])),
      catchError(({ error }) => of(setNotify(error.message)))
    ))
  );

  private getIscoutBoundaryDateCallback(boundaryType: string): OperatorFunction<IActionWithPayload, IActionWithPayload> {
    return switchMap((action: IActionWithPayload) => {
      setIscoutLoading(true);
      setIscoutError(false);
      return this.performApiCallForDate(action, boundaryType);
    });
  }

  private performApiCallForDate(action: IActionWithPayload, boundaryType: string): Observable<IActionWithPayload> {
    return this.api.getCameraPhotos({
      type: PhotoRequestType.SINGLE_DATE_INTERVAL,
      stationId: action.payload,
      date: boundaryType
    }).pipe(
      switchMap((apiResponse: {date: string}) => from(this.prepareSetDateAction(apiResponse, boundaryType))),
      catchError(() => this.unsuccessfulApiCall())
    );
  }

  private prepareSetDateAction(apiResponse: {date: string}, boundaryType: string): Array<IActionWithPayload> {
    if (!apiResponse.date && !apiResponse[0]) {
      return [
        setCropViewError(true),
        setCropViewLoading(false)
      ];
    }
    const dateObject = moment(apiResponse.date ? apiResponse.date : apiResponse[0]);
    return boundaryType === 'last'
      ? [
        setIscoutLastDate(dateObject),
        setIscoutSettingsCurrentDateString(getMonthFromDate(dateObject))
      ]
      : [setIscoutFirstDate(dateObject)];
  }

  private performApiCallsForPhotos(action: IActionWithPayload): Observable<IActionWithPayload> {
    return combineLatest(this.prepareApiCallObservables(action.payload)).pipe(
      switchMap((sets: Array<IPictureSet>) => this.prepareSetPhotosActions(sets))
    );
  }

  private prepareApiCallObservables(data: IGetPhotosRequests | IGetPhotosRequestFromTo): Array<Observable<IPictureSet>> {
    return data.camIds.map((camId: number): Observable<IPictureSet> => {
      let request;
      if (data.type === PhotoRequestType.MIN_MAX_INTERVAL) {
        request = <IGetPhotosRequestFromTo> {
          type: PhotoRequestType.MIN_MAX_INTERVAL,
          stationId: data.stationId,
          camId: camId,
          // Need to send the interval as a unix timestamp
          from: new Date(data.from).getTime() / 1000,
          to: Math.trunc(new Date(data.to).getTime() / 1000)
        };
      } else {
        request = <IGetPhotosRequest> {
          type: PhotoRequestType.SINGLE_DATE_INTERVAL,
          stationId: data.stationId,
          camId: camId,
          date: data.date
        };
      }

      return this.performSingleApiCallForPhotos(request);
    });
  }

  private performSingleApiCallForPhotos(request: IGetPhotosRequest | IGetPhotosRequestFromTo): Observable<IPictureSet> {
    const observable = (request.type === PhotoRequestType.MIN_MAX_INTERVAL)
      ? this.api.getCameraPhotosFromTo(request)
      : this.api.getCameraPhotos(request);

    return observable.pipe(
      map((apiResponse: Array<IPicture>): IPictureSet => {
        return {
          pictures: apiResponse,
          camId: request.camId
        };
      }),
      catchError(() => of({
        pictures: [],
        camId: request.camId
      }))
    );
  }

  private prepareSetPhotosActions(sets: Array<IPictureSet>): Observable<IActionWithPayload> {
    const setPhotosActions = [
      setIscoutLoading(false)
    ];
    for (let i = 0; i < sets.length; i++) {
      setPhotosActions.push(setIscoutPhotos(sets[i]));
    }
    return setPhotosActions.length ? from(setPhotosActions) : this.unsuccessfulApiCall();
  }

  private preparePostCameraPestCall(action: IActionWithPayload, actionType: 'add' | 'remove'): Observable<IActionWithPayload> {
    const payload: IPostCameraPestPayload = <IPostCameraPestPayload>action.payload;
    const successMessage: string = actionType === 'add'
      ? 'Pest was saved'
      : 'Pest was removed';
    const errorMessage: string = actionType === 'add'
      ? 'Could not save pest'
      : 'Could not remove the selected pest';

    return this.api.postCameraPest(payload.stationId, payload.body, actionType).pipe(
      switchMap(() => from([
        unselectIscoutPest(),
        getIscoutUserPests(payload.stationId),
        setNotify(successMessage)
      ])),
      catchError(() => of(setNotify(errorMessage)))
    );
  }

  private unsuccessfulApiCall(message?: string): Observable<IActionWithPayload> {
    const triggerActions = [
      setIscoutError(true),
      setIscoutLoading(false)
    ];
    if (message) {
      triggerActions.push(setNotify(message));
    }
    return from(triggerActions);
  }
}
