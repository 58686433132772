import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  getWidgetData,
  removeWidget,
  setWidgetError,
  setWidgetLoading,
  updateWidgetEditMode,
  updateWidgetSettings
} from '../../../actions/dashboard';
import { ICropViewRequest, ILoadWidgetData, IUpdateWidgetEditMode, IWidget } from '../../../models/dashboard.models';
import * as fromDashboard from '../../../reducers/dashboard';
import { selectEdit } from '../../../reducers/index';

@Component({
  selector: 'app-crop-view-widget',
  templateUrl: './crop-view-widget.component.html',
  styleUrls: ['./crop-view-widget.component.scss']
})
export class CropViewWidgetComponent implements OnInit {
  @Input()
  public widget                     : IWidget;
  @Input()
  private widgetIndex               : number;
  @Input()
  private columnIndex               : number;
  @Input()
  private rowIndex                  : number;
  public isEdit$                    : Observable<boolean>;
  private loadWidgetData            : ILoadWidgetData;

  constructor(private dashboardStore: Store<fromDashboard.IDashboard>) {}

  public ngOnInit(): void {
    this.isEdit$ = this.dashboardStore.pipe(select(selectEdit));
    if (!this.widget.loadData) {
      this.loadCropViewData();
    }
  }

  public removeCurrentWidget(): void {
    const path: Array<number> = [this.rowIndex, this.columnIndex, this.widgetIndex];
    this.dashboardStore.dispatch(removeWidget(path));
  }

  public loadCropViewData(): void {
    this.loadWidgetData = {
      url: `/camera/${this.widget.requests.cropview.endpoint.station}/photos/last/6`,
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
    };
    this.resetLoading();
    this.dashboardStore.dispatch(getWidgetData(this.loadWidgetData));
  }

  public updateRequest(updateSettings: ICropViewRequest): void {
    this.dashboardStore.dispatch(updateWidgetSettings({
      type: 'cropview',
      updateSettings: updateSettings,
      path: [this.rowIndex, this.columnIndex, this.widgetIndex]
    }));
  }

  private resetLoading(): void {
    this.dashboardStore.dispatch(setWidgetLoading({
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      loading: true
    }));
    this.dashboardStore.dispatch(setWidgetError({
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      error: ''
    }));
  }

  public editMode(editMode: boolean): void  {
    const updateSettings: IUpdateWidgetEditMode = {
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      type: 'cropview',
      isEdit: editMode,
    };
    this.dashboardStore.dispatch(updateWidgetEditMode(updateSettings));
  }

}
