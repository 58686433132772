import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { StationCameraTypes } from '../../modules/camera-data/constants/camera-data';
import { IPicture } from '../camera/models/camera';
import { formatDate } from '../utils/dateFormat';

@Component({
  selector: 'app-custom-image-gallery',
  templateUrl: './custom-image-gallery.component.html',
  styleUrls: ['./custom-image-gallery.component.scss']
})
export class CustomImageGalleryComponent implements OnChanges {
  @Input()
  public pictures: Array<IPicture> = [];
  @Input()
  public deviceType: StationCameraTypes;

  @Output()
  public selectPictureEmitter: EventEmitter<IPicture> = new EventEmitter<IPicture>();

  public activePicture: IPicture = null;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.pictures || changes.activePicture) {
      this.activePicture = null;
      this.changeDetectorRef.detectChanges();
      setTimeout(() => {
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  public getTotalDetections(picture: IPicture): number {
    return (picture.rectangles || []).filter((rect) => rect.hidden === false).length;
  }

  public toLocalDate(date: Date): string {
    return formatDate(date);
  }

  public selectPicture(activePicture: IPicture): void {
    this.activePicture = activePicture;
    this.selectPictureEmitter.emit(this.activePicture);
  }

  public isActivePicture(picture: IPicture): boolean {
    return this.activePicture
      && this.activePicture.cam_id === picture.cam_id
      && this.activePicture.pic_id === picture.pic_id;
  }

  public isGoodQuality(picture: IPicture): boolean {
    return picture.image_quality?.label === 'Good';
  }

  public isBadQuality(picture: IPicture): boolean {
    return picture.image_quality?.label === 'Bad';
  }
}
