<div [formGroup]="form"
     class="add-remove-form">
  <div class="add-remove-form__fields">
    <div class="add-remove-form__fields__item">
      <h5>{{ 'Virtual station key' | translate }}</h5>
      <app-form-input [formControl]="key"
                      [type]="'border'"></app-form-input>
    </div>
    <div class="add-remove-form__fields__item"
         *ngIf="isAddForm">
      <h5>{{ 'Virtual station name' | translate }}</h5>
      <app-form-input [formControl]="customName"
                      [type]="'border'"></app-form-input>
    </div>

    <div class="add-remove-form__fields__item"
         *ngIf="isAddForm">
      <h5>{{ 'Pick the station location' | translate}}</h5>
    </div>
  </div>

  <agm-map
    *ngIf="isAddForm"
    [latitude]="position.geo.coordinates[1]"
    [longitude]="position.geo.coordinates[0]"
    [zoom]="10"
    [minZoom]="2"
    [maxZoom]="17"
    [disableDefaultUI]="true"
    [zoomControl]="true"
    [scrollwheel]="true"
    [mapTypeControl]="true"
    [streetViewControl]="false"
    [mapTypeId]="'hybrid'"
    (mapClick)="selectLocationFromMap($event)">
    <agm-marker
      [latitude]="position.geo.coordinates[1]"
      [longitude]="position.geo.coordinates[0]">
    </agm-marker>
  </agm-map>

  <div class="add-remove-form__buttons">
    <app-custom-button [content]="'Cancel'"
                       (click)="close()"></app-custom-button>
    <app-custom-button [color]="submitColor"
                       [disabled]="form.invalid"
                       (click)="submitEmit()"
                       [content]="submitContent"></app-custom-button>
  </div>
</div>
