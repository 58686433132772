import { IOptions } from '../../../shared/interfaces';

export enum ViewEditorMode {
    CREATE,
    EDIT
}

export enum SensorGroupType {
    VOLUMETRIC = 'Volumetric',
    TENSIOMETRIC = 'Tensiometric',
    TEMPERATUR = 'Temperature',
    SALINITY = 'Salinity'
}

export enum ViewTemplateType {
    SOIL_MOISTURE_ALL = 'Soil Moisture All',
    SOIL_MOISTURE_STACKED = 'Soil Moisture Stacked',
}

export enum ViewOperationType {
    AVG = 'pointOfTimeAvg',
    SUM = 'pointOfTimeSum',
    PAW = 'pointOfTimePaw'
}

export enum ViewType {
    CUSTOM = 'custom',
    PREDEFINED = 'predefined'
}

export enum PlotbandModeType {
    DATA = 'data',
    HIDE = 'hide',
    AGGR = 'aggr',
    BOTH = 'both'
}

export const PeriodValuesByScope = {
    'raw': [
        { content: '24 hours', value: '24h' },
        { content: '48 hours', value: '48h' },
        { content: '7 days', value: '7d' },
        { content: '10 days', value: '10d' },
        { content: '14 days', value: '14d' },
        { content: '30 days', value: '30d' }
    ],
    'hourly': [
        { content: '48 hours', value: '48h' },
        { content: '7 days', value: '7d' },
        { content: '10 days', value: '10d' },
        { content: '14 days', value: '14d' },
        { content: '30 days', value: '30d' },
        { content: '60 days', value: '60d' },
        { content: '90 days', value: '90d' },
        { content: '120 days', value: '120d' },
    ],
    'daily': [
        { content: '7 days', value: '6d' },
        { content: '10 days', value: '9d' },
        { content: '14 days', value: '13d' },
        { content: '30 days', value: '29d' },
        { content: '90 days', value: '89d' }
    ],
    'monthly': [
        { content: '6 months', value: '6m' },
        { content: '12 months', value: '12m' },
        { content: '24 months', value: '24m' },
        { content: '36 months', value: '36m' }
    ]
};

export const DefaultPeriodScope = 'hourly';

export const DefaultPeriodValue = { content: '30 days', value: '30d' };

export const ViewOperations: IOptions[] = [
    {
        content: 'SUM',
        value: ViewOperationType.SUM
    },
    {
        content: 'AVG',
        value: ViewOperationType.AVG
    },
    {
        content: 'PAW',
        value: ViewOperationType.PAW
    },
];

export const SENSOR_GROUPS = {
    [SensorGroupType.VOLUMETRIC]: [ 25 ],
    [SensorGroupType.TENSIOMETRIC]: [ 11, 37, 27, 28, 32, 42 ],
    [SensorGroupType.TEMPERATUR]: [ 3 ],
    [SensorGroupType.SALINITY]: [ 33 ],
};


export const PlotbandModeLabels = {
    [ViewType.PREDEFINED]: [
        { value: PlotbandModeType.DATA, content: 'ON' },
        { value: PlotbandModeType.HIDE, content: 'OFF' }
    ],
    [ViewType.CUSTOM]: [
        { value: PlotbandModeType.AGGR, content: 'ON' },
        { value: PlotbandModeType.BOTH, content: 'BOTH' },
        { value: PlotbandModeType.HIDE, content: 'OFF' }
    ]
};

export const ViewAggregationType = {
    [ViewOperationType.AVG]: [
        SensorGroupType.VOLUMETRIC, SensorGroupType.TEMPERATUR, SensorGroupType.TENSIOMETRIC, SensorGroupType.SALINITY
    ],
    [ViewOperationType.SUM]: [SensorGroupType.VOLUMETRIC],
    [ViewOperationType.PAW]: [SensorGroupType.VOLUMETRIC]
};

export const SCALED_FREQUENCY_ID = 'SCALED_FREQUENCY';
