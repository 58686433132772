import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCropzoneName from './cropzone-name';
import * as fromTimezoneAndLocation from './timezone-location';

export interface IState {
  cropzoneName: fromCropzoneName.ICropzoneNameState;
  timezoneAndLocation: fromTimezoneAndLocation.ITimezoneAndLocationState;
}

export const reducers = {
  cropzoneName: fromCropzoneName.reducer,
  timezoneAndLocation: fromTimezoneAndLocation.reducer
};

export const config = createFeatureSelector<IState>('config');

export const selectCropzoneName = createSelector(
  config,
  (configState: IState) => configState.cropzoneName.cropzone
);

export const selectLocation = createSelector(
  config,
  (configState: IState) => configState.timezoneAndLocation
);

export const selectSource = createSelector(
  config,
  (configState: IState) => configState.cropzoneName.source
);
