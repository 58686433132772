<div class="column-header">

  <app-custom-button [content]="'Add widget'"
                     (click)="addWidget.emit()"
                     class="column-header__block">
    <i class="zmdi zmdi-plus-square"></i>
  </app-custom-button>

  <div class="column-header__resize"
       *ngIf="columnCount === 2">
    <app-custom-button [color]="'white-double'"
                       (click)="resize.emit(columnIndex !== 0)"
                       [radius]="'2px 0 0 2px'">
      <i class="zmdi zmdi-arrow-left"></i>
    </app-custom-button>

    <app-custom-button [color]="'white-double'"
                       (click)="resize.emit(columnIndex === 0)"
                       [radius]="'0 2px 2px 0'">
      <i class="zmdi zmdi-arrow-right"></i>
    </app-custom-button>
  </div>

  <app-custom-button [color]="'white'"
                     class="column-header__block"
                     (click)="remove.emit()"
                     [content]="'Remove column'">
    <i class="zmdi zmdi-close"></i>
  </app-custom-button>

</div>
