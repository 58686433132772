<div [hidden]="selectedStation">
  <div [@cardAnimation] class="app-my-john-deere">
    <app-form-layout>
      <app-card-header
        [header]="'MyJohnDeere Operations Center'"
        [description]="'Connect FieldClimate stations to your organizations'"
        [helpButton]="true"
        (openModal)="openModal()">
      </app-card-header>
      <div>
  
        <div>
          <div class="row" *ngIf="!needAccessGranted">
            <div class="col-md-12">
              <div class="app-my-john-deere-user">
                <span *ngIf="userData?.token">
                  {{ "Token Expiration Date" | translate }}: {{ userData?.token?.expirationDate | date }},
                </span>
                <span>
                  &nbsp;{{ userData?.user?.givenName }} {{ userData?.user?.familyName }}&nbsp;
                </span>
                <span *ngIf="selectedOrganizationChanged | async as selectedOrganization">
                  @ {{ selectedOrganization?.name }} / {{ selectedOrganization?.id }}
                </span>
              </div>
              <div *ngIf="orgData" style="text-align: right">
                <app-custom-lazy-dropdown placeholder="{{ 'Search Organizations' | translate }}"
                                          [items]="orgData.items"
                                          [totalItems]="orgData.total"
                                          itemTextProperty="name"
                                          itemIdProperty="id"
                                          [isBusy]="currentUserOrganizationsIsLoading"
                                          (selectionChanged)="selectedOrganizationChanged.next($event)"
                                          (closed)="organizationsDropDownIsClosed.next($event)"
                                          (filterChanged)="organizationsFetchRequested.next($event)"
                                          (loadMore)="organizationsFetchRequested.next($event)">
                </app-custom-lazy-dropdown>
                <div class="grant-access">
                  <app-custom-button *ngIf="isGrantAccess"
                                     content="Grant Organization Access"
                                     color="red"
                                     (click)="grantOrgAccess()">
                  </app-custom-button>
                </div>
              </div>
            </div>
          </div>
  
          <div class="row" *ngIf="!isGrantAccess && !needAccessGranted && userData?.user && stationsPageItems$ | async as stationsPageItem">
            <div class="col-md-12">
  
              <!-- <div *ngIf="selectedOrganization && selectedOrganization.assets && selectedOrganization.permissions.assets"> -->
              <table class="table" [class.disabled]="!userData.user">
                <thead>
                <tr>
                  <th>{{ 'Station ID' | translate }}</th>
                  <th>{{ 'Station name' | translate }}</th>
                  <th>{{ 'Device type' | translate }}</th>
                  <th>{{ 'Last data' | translate }}</th>
                  <th>{{ 'Access rights' | translate }}</th>
                  <th></th>
                  <th>{{ 'Connect to Operations Center' | translate }}</th>
                </tr>
                </thead>
  
                <tbody [class.disabled]="assetRequestIsBusy$ | async">
                <tr *ngFor="let item of stationsPageItem"
                    [class.disabled]="item.station.rights === 'r' || (item.asset && !item.asset.asset) || isItemPending(item)">
                  <td>
                    <span>{{ item.station.name.original }}</span>
                  </td>
                  <td>
                    <span>{{ item.station.name.custom }}</span>
                  </td>
                  <td>
                    <span>{{ item.station.info.device_name }}</span>
                  </td>
                  <td>
                    <span>{{ item.station.dates.max_date }}</span>
                  </td>
                  <td>
                    <span>{{ item.station.rights }}</span>
                  </td>
                  <td>
                    <button (click)="setSelectedStation(item)"
                        [disabled]="false"
                        class="btn btn-sm btn-primary sensorButton"
                        type="button">
                      {{ 'Sensors' | translate }}
                    </button>
                  </td>
                  <td>
                    <app-yes-no-toggle
                      noTab="true"
                      [ngModel]="!!item.asset"
                      (change)="stationConnectToggled.next({ station: item.station, asset: item.asset, add: $event })">
                    </app-yes-no-toggle>
                  </td>
                </tr>
                </tbody>
              </table>
  
              <pagination class="my-john-deere-station-pagination" *ngIf="stations$ | async as stations;"
                          [totalItems]="stations.length"
                          [itemsPerPage]="STATONS_PAGE_SIZE"
                          [maxSize]="10"
                          [boundaryLinks]="stations.length / STATONS_PAGE_SIZE > 10"
                          previousText="&lsaquo;"
                          nextText="&rsaquo;"
                          firstText="&laquo;"
                          lastText="&raquo;"
                          (pageChanged)="stationsPageChanged.next($event.page - 1)">
              </pagination>
  
            </div>
          </div>
        </div>
  
        <hr>
  
        <div class="row">
          <div class="col-md-12 app-my-john-deere-grant">
            <app-custom-button *ngIf="needAccessGranted && !grantingAccessIsLoading"
                               content="Grant Access"
                               color="red"
                               (click)="grantAccess()">
            </app-custom-button>
            <app-custom-button *ngIf="!needAccessGranted && userData.user && !grantingAccessIsLoading"
                               content="Revoke Access"
                               color="red"
                               (click)="revokeAccess()">
            </app-custom-button>
            <!-- <app-custom-button [content]="'Revoke Access' | translate " [color]="'red'" (click)="revokeAccess()"></app-custom-button> -->
          </div>
        </div>
  
      </div>
    </app-form-layout>
  </div>
</div>

<ng-container *ngIf="selectedStation">
  <app-my-john-deere-map
    [organization]="selectedOrganization"
    [station]="selectedStation"
    (closeSensorMap)="closeSensorMap()"
  >
  </app-my-john-deere-map>
</ng-container>

<app-modal [modalId]="REVOKE_ACCESS_MODAL_ID">
  <app-my-john-deere-revoke-access-modal
    [MODAL_ID]="REVOKE_ACCESS_MODAL_ID"
    [assets]="userAssets$ | async">
  </app-my-john-deere-revoke-access-modal>
</app-modal>

<app-info-modal [modalId]="INFO_MODAL_ID" [header]="'John Deere OC'">
  <app-my-john-deere-modal></app-my-john-deere-modal>
</app-info-modal>
