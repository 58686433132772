<div class="save-sensors">
  <hr class="save-sensors__hr">
  <div class="save-sensors__content">
    <div>
      <app-custom-button [color]="'orange'"
                         [content]="'Reset units'"
                         (click)="emitResetUnits()"></app-custom-button>
      <app-custom-button [color]="'red'"
                         class="save-sensors__save"
                         [content]="'Save all sensors' | translate"
                         (click)="emitSaveAllSensors()"></app-custom-button>
    </div>
  </div>
</div>
