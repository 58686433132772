import {IStationTreeSettings, ITreeSettingsState} from '../../models/tree.models';
import {IStationProfileBatchSettingPayload, IStationProfileSettingPayload} from '../../actions/tree-settings';
import {InitialStationTreeSetting} from '../../../../modules/station-data/constants/constants';
import {IStationProfileTreeSettings} from '../../../../services/tree/models';

export class TreeSettingsHelper {
  private state: ITreeSettingsState;

  constructor(state: ITreeSettingsState) {
    this.state = state;
  }

  public stateHasDisabledGroupId(settings: IStationProfileSettingPayload): boolean {
    const stationProfileSettings: IStationProfileTreeSettings = this.getStationProfileSettings(settings.stationId, settings.profile);
    return stationProfileSettings && stationProfileSettings.disabledGroupIds.hasOwnProperty(settings.groupdId);
  }

  public enableGroupId(settings: IStationProfileSettingPayload): void {
    delete this.getStationProfileSettings(settings.stationId, settings.profile).disabledGroupIds[settings.groupdId];
  }

  public disableGroupId(settings: IStationProfileSettingPayload): void {
    const stationProfileSettings = this.prepareStationProfileSettings(settings.stationId, settings.profile);
    stationProfileSettings.disabledGroupIds[settings.groupdId] = true;
  }

  public enableGroupIds(settings: IStationProfileBatchSettingPayload): void {
    settings.groupIds.forEach((groupId: string) => {
      delete this.getStationProfileSettings(settings.stationId, settings.profile).disabledGroupIds[groupId];
    });
  }

  public disableGroupIds(settings: IStationProfileBatchSettingPayload): void {
    const stationProfileSettings = this.prepareStationProfileSettings(settings.stationId, settings.profile);
    settings.groupIds.forEach(
      (groupId: string) => stationProfileSettings.disabledGroupIds[groupId] = true
    );
  }

  private getStationProfileSettings(stationId: string, profile: string): IStationProfileTreeSettings {
    const stationSettings: IStationTreeSettings = this.getStationSettings(stationId);
    return stationSettings && (stationSettings.profileSettings[profile] || null);
  }

  private getStationSettings(stationId: string): IStationTreeSettings {
    return this.state[stationId] || null;
  }

  private prepareStationProfileSettings(stationId: string, profile: string): IStationProfileTreeSettings {
    this.initStationSettings(stationId);
    const stationProfileSettings = this.getStationProfileSettings(stationId, profile);
    if (!stationProfileSettings) {
      this.state[stationId].profileSettings[profile] = {disabledGroupIds: {}};
    }
    return stationProfileSettings;
  }

  private initStationSettings(stationId: string): void {
    this.state[stationId] = this.state[stationId] || InitialStationTreeSetting;
  }
}
