import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ITrackerTripsData, ITrackerPoint } from '../models/tracker.models';

@Injectable({
  providedIn: 'root'
})
export class TrackerService {
  // service to load trip data
  public selectedTripIOSubject$ = new BehaviorSubject<ITrackerTripsData>(null);
  public selectedTripIO$ = this.selectedTripIOSubject$.asObservable();

  // service to show selected points as markers on the map
  public selectedTripPointsSubject$ = new BehaviorSubject<any>(null);
  public selectedTripPoints$ = this.selectedTripPointsSubject$.asObservable();

  public setSelectedTripIO(trip: ITrackerTripsData): void {
    this.selectedTripIOSubject$.next(trip);
  }

  public setSelectedTripPoints(points: ITrackerPoint[]): void {
    this.selectedTripPointsSubject$.next(points);
  }
}
