<div class="block-header">

  <h2 *ngIf="!isEditMode || isMain">{{ name | translate }}</h2>
  <div *ngIf="isEditMode && !isMain" class="edit-block">
    <div class="input-container">
      <input type="text"
             class="form-control"
             [attr.placeholder]="'Dashboard' | translate"
             (focusout)="updateName($event.target.value)"
             [(ngModel)]="name">
    </div>

      <app-custom-button *ngIf="!isMain"
                         [color]="'blue'"
                         class="column-header__block"
                         (click)="saveDefault()"
                         [disabled]="isNew"
                         [content]="'Copy to Main'">
        <i class="zmdi zmdi-plus-circle-o"></i>
      </app-custom-button>

      <app-custom-button [color]="'white'"
                         class="column-header__block"
                         (click)="remove()"
                         [disabled]="isNew"
                         [content]="'Remove dashboard'">
        <i class="zmdi zmdi-close"></i>
      </app-custom-button>
  </div>

  <div class="block-header__warning-alert alert alert-warning"
        *ngIf="isEditMode && !isMain && (isAddDashboardButtonClicked || !isSaved)">
    <i class="zmdi zmdi-save block-header__warning-alert__icon"></i>
    <span>{{ 'To proceed, please save the dashboard on the diskette icon.' | translate }}</span>
  </div>

  <div class="block-header__actions">
    <a appTooltip
       [tooltipTitle]="'Edit the Dashboard' | translate"
       tooltipPlacement="top"
       (click)="toggleEdit()">
      <i class="zmdi zmdi-edit"></i>
    </a>
    <a appTooltip
       [tooltipTitle]="'Save settings' | translate"
       (click)="save.emit(); saved()"
       tooltipPlacement="top">
      <i class="block-header__save">
        <svg class="block-header__save__svg"
             viewBox="0 0 1792 1792"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M512 1536h768v-384h-768v384zm896 0h128v-896q0-14-10-38.5t-20-34.5l-281-281q-10-10-34-20t-39-10v416q0 40-28 68t-68 28h-576q-40 0-68-28t-28-68v-416h-128v1280h128v-416q0-40 28-68t68-28h832q40 0 68 28t28 68v416zm-384-928v-320q0-13-9.5-22.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 22.5v320q0 13 9.5 22.5t22.5 9.5h192q13 0 22.5-9.5t9.5-22.5zm640 32v928q0 40-28 68t-68 28h-1344q-40 0-68-28t-28-68v-1344q0-40 28-68t68-28h928q40 0 88 20t76 48l280 280q28 28 48 76t20 88z"/>
        </svg>
      </i>
    </a>
    <a class="pointer"
       appTooltip
       [tooltipTitle]="'More options' | translate"
       (click)="openOptions()"
       tooltipPlacement="top">
      <i class="him-icon zmdi zmdi-more-vert"></i>
    </a>
    <app-more-options *ngIf="isOptionsOpen"
                      (restore)="restore.emit()"
                      (addColumns)="addColumns.emit($event)"
                      (setDefault)="setDefault.emit()"
                      (close)="isOptionsOpen = false"
                      (addDashboardButtonIsClicked)="onAddDashboardButtonIsClicked($event)">
    </app-more-options>
  </div>

</div>
