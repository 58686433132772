import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-support-card-header',
  templateUrl: './user-support-card-header.component.html',
  styleUrls: ['./user-support-card-header.component.scss']
})
export class UserSupportCardHeaderComponent {
  @Input()
  public header           : string = '';
  @Input()
  public description      : string = '';
}
