import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IState } from '../states';

export const soilMoistureSelector = createFeatureSelector<IState>('soilMoisture');

export const views = createSelector(
    soilMoistureSelector,
    (state: IState) => state.data.views
);

export const chart = createSelector(
    soilMoistureSelector,
    (state: IState) => state.data.chart.chartsOptions
);

export const grid = createSelector(
    soilMoistureSelector,
    (state: IState) => state.data.chart.grid
);

export const topology = createSelector(
    soilMoistureSelector,
    (state: IState) => state.data.chart.topology
);

export const settings = createSelector(
    soilMoistureSelector,
    (state: IState) => state.settings
);

export const treeSettings = createSelector(
    soilMoistureSelector,
    (state: IState) => state.tree
);

export const treeStationSettings = createSelector(
    soilMoistureSelector,
    (state: IState, stationId: string) => state.tree[stationId] ? state.tree[stationId] : null
);

export const periodSettings = createSelector(
    soilMoistureSelector,
    (state: IState) => state.settings.period
);

export const activitySettings = createSelector(
    soilMoistureSelector,
    (state: IState, activity: string) => state.settings.activity[activity]
);

export const status = createSelector(
    soilMoistureSelector,
    (state: IState) => state.settings.status
);

export const soilMoistureSensors = createSelector(
    soilMoistureSelector,
    (state: IState) => state.data.soilMoistureSensors
);


