import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {IscoutComponent} from './containers/iscout/iscout.component';
import {IscoutPestsComponent} from './containers/iscout-pests/iscout-pests.component';
import {IscoutGlueBoardComponent} from './containers/iscout-glue-board/iscout-glue-board.component';
import {IscoutSeasonComponent} from './containers/iscout-season/iscout-season.component';

const routes: Routes = [
  { path: 'seasons', component: IscoutSeasonComponent },
  { path: 'glue-boards', component: IscoutGlueBoardComponent },
  { path: 'pests', component: IscoutPestsComponent },
  { path: '', component: IscoutComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class IscoutRoutingModule { }
