import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectStations } from '../../../../core/reducers';
import * as fromStations from '../../../../core/reducers/stations';
import { NavigationService } from '../../../../core/services/navigation/navigation.service';
import * as moment from 'moment';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit, OnChanges {

  @Input()
  public mapMode: string;

  public lat: number = 45;
  public lng: number = 15;
  public zoom: number = 2;
  public icon: string = '/assets/img/markers/green.png';
  // public                 metaMode: string = 'airTemp';

  public stationsLocal: Array<IStation>;

  @ViewChild('agmMap', {static: true}) public agmMap: ElementRef;
  public showMap: boolean = false;

  constructor(
    private stationsStore: Store<fromStations.IStations>,
    private navigationService: NavigationService,
  ) {

    stationsStore.pipe(
      select(selectStations),
      filter(stations => Array.isArray(stations)),
      map((stations: IStation[]) => {
        this.stationsLocal = JSON.parse(JSON.stringify(stations));
        this.switchMeta(this.mapMode);
      })
    ).subscribe();
  }

  public ngOnInit(): void {
    if ('IntersectionObserver' in window) {
      const lazyMap = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.showMap = true;
            observer.unobserve(entry.target);
          }
        });
      });

      lazyMap.observe(this.agmMap.nativeElement);
    }
  }

  public switchMeta(mode: string): void {
    this.mapMode = mode;
    localStorage.setItem('mapWidgetMode', mode);

    let template: string;
    const now = new Date();

    for (const station of this.stationsLocal) {
      // hide stations with no location set and iScout virtual stations (D01)
      if (station?.position === null ||
         (station?.position?.geo?.coordinates[0] === 0 && station?.position?.geo?.coordinates[1] === 0) ||
          station?.name?.original.startsWith('D01')) {
         station.hidden = true;
      } else {
        template = 'green';
        let value: number;
        const units = station.metaUnits;

        station.title = station.name.original;
        station.opacity = 1;
        station.hidden = true;

        // calculate time difference from last communication
        const comm = new Date(station.dates.last_communication);
        const timeDiff = (now.getTime() - comm.getTime()) / 1000 / 60 / 60;

        if (station.name.hasOwnProperty('custom') && station.name.custom) {
          station.title = station.name.custom + ' (' + station.name.original + ')';
        }

        // if tracker
        if (station.info.device_id === 41) {
          station.icon = '/assets/img/markers/device/active_tracker.png';
          station.hidden = false;

          if (timeDiff > 168 && timeDiff < 720) {
            station.opacity = 0.6;
          } else if (timeDiff > 720) {
            station.opacity = 0.4;
          }
        } else {
          if (this.mapMode === 'lastComm') {

            if (timeDiff < 24) {
              station.icon = '/assets/img/markers/green.png';
            } else if (timeDiff < 72) {
              station.icon = '/assets/img/markers/light_green.png';
            } else {
              station.icon = '/assets/img/markers/red.png';
            }
            station.hidden = false;

          } else {
            // set opacity if data is old
            if (timeDiff > 168 && timeDiff < 720) {
              station.opacity = 0.6;
            } else if (timeDiff > 720) {
              station.opacity = 0.4;
            }

            if (station.meta !== null && station.hasOwnProperty('meta') && station.meta.hasOwnProperty(this.mapMode)) {
              if (this.mapMode === 'rain24h' || this.mapMode === 'rain48h' || this.mapMode === 'rain7d') {
                value = parseFloat(station.meta[this.mapMode].sum);
              } else {
                value = parseFloat(station.meta[this.mapMode]);
              }

              switch (this.mapMode) {
                case 'rain24h':
                case 'rain48h':
                case 'rain7d':
                  if (value === 0) {
                    template = 'yellow';
                  } else if (value < 15) {
                    template = 'lightblue';
                  } else if (isNaN(value)) {
                    template = 'gray';
                  } else {
                    template = 'blue';
                  }

                  if (units === 'imperial') {
                    value = value * 0.0393700787;
                  }
                  break;

                case 'soilTemp':
                case 'airTemp':
                  if (value < -15) {
                    template = 'purple';
                  } else if (value < 0 || value === 0) {
                    template = 'blue';
                  } else if (value < 5) {
                    template = 'lightblue';
                  } else if (value < 10) {
                    template = 'lightgreen';
                  } else if (value < 15) {
                    template = 'green';
                  } else if (value < 18) {
                    template = 'yellow';
                  } else if (value < 24) {
                    template = 'orange';
                  } else if (value < 30) {
                    template = 'red';
                  } else if (value === 30 || value > 30) {
                    template = 'darkred';
                  } else if (isNaN(value)) {
                    template = 'gray';
                  }

                  // convert to fahrenheit for users with imperial units
                  if (units === 'imperial') {
                    value = (value * 9 / 5) + 32;

                    if (value < 5) {
                      template = 'purple';
                    } else if (value < 32 || value === 32) {
                      template = 'blue';
                    } else if (value < 41) {
                      template = 'lightblue';
                    } else if (value < 50) {
                      template = 'lightgreen';
                    } else if (value < 59) {
                      template = 'green';
                    } else if (value < 65) {
                      template = 'yellow';
                    } else if (value < 75) {
                      template = 'orange';
                    } else if (value < 86) {
                      template = 'red';
                    } else if (value === 86 || value > 86) {
                      template = 'darkred';
                    } else if (isNaN(value)) {
                      template = 'gray';
                    }
                  }
                  break;

                case 'dryBulb':
                case 'wetBulb':
                  if (value < -10) {
                    template = 'purple';
                  } else if (value < 2) {
                    template = 'blue';
                  } else if (value < 5) {
                    template = 'lightblue';
                  } else if (value < 10) {
                    template = 'lightgreen';
                  } else if (value < 15) {
                    template = 'green';
                  } else if (value < 18) {
                    template = 'yellow';
                  } else if (value < 24) {
                    template = 'orange';
                  } else if (value < 30) {
                    template = 'red';
                  } else if (value === 30 || value > 30) {
                    template = 'darkred';
                  } else if (isNaN(value)) {
                    template = 'gray';
                  }

                  // convert to fahrenheit for users with imperial units
                  if (units === 'imperial') {
                    value = (value * 9 / 5) + 32;

                    if (value < 14) {
                      template = 'purple';
                    } else if (value < 36) {
                      template = 'blue';
                    } else if (value < 41) {
                      template = 'lightblue';
                    } else if (value < 50) {
                      template = 'lightgreen';
                    } else if (value < 59) {
                      template = 'green';
                    } else if (value < 65) {
                      template = 'yellow';
                    } else if (value < 75) {
                      template = 'orange';
                    } else if (value < 86) {
                      template = 'red';
                    } else if (value === 86 || value > 86) {
                      template = 'darkred';
                    } else if (isNaN(value)) {
                      template = 'gray';
                    }
                  }
                  break;

                case 'windSpeed':
                  value = Number(value.toFixed(1));     // Windspeed limit to one decimal
                  if (value < 0.2) {
                    template = 'purple';
                  } else if (value < 1.5) {
                    template = 'blue';
                  } else if (value < 3.3) {
                    template = 'lightblue';
                  } else if (value < 5.4) {
                    template = 'lightgreen';
                  } else if (value < 7.9) {
                    template = 'green';
                  } else if (value < 10.7) {
                    template = 'yellow';
                  } else if (value < 13.8) {
                    template = 'orange';
                  } else if (value < 20.7 || value === 20.7) {
                    template = 'red';
                  } else if (value === 20.8 || value > 20.8) {
                    template = 'darkred';
                  } else if (isNaN(value)) {
                    template = 'gray';
                  }

                  // convert to fahrenheit for users with imperial units
                  if (units === 'imperial') {
                    value = Number((value / 0.44704).toFixed(1));

                    if (value < 1) {
                      template = 'purple';
                    } else if (value < 3) {
                      template = 'blue';
                    } else if (value < 7) {
                      template = 'lightblue';
                    } else if (value < 12) {
                      template = 'lightgreen';
                    } else if (value < 18) {
                      template = 'green';
                    } else if (value < 24) {
                      template = 'yellow';
                    } else if (value < 31) {
                      template = 'orange';
                    } else if (value < 46 || value === 46) {
                      template = 'red';
                    } else if (value === 47 || value > 47) {
                      template = 'darkred';
                    } else if (isNaN(value)) {
                      template = 'gray';
                    }
                  }
                  break;

                case 'rh':
                  if (value < 20) {
                    template = 'yellow';
                  } else if (value < 50) {
                    template = 'lightgreen';
                  } else if (value < 60) {
                    template = 'lightblue';
                  } else if (value === 60 || value > 60) {
                    template = 'blue';
                  } else if (isNaN(value)) {
                    template = 'gray';
                  }
                  break;

                case 'lw':
                  if (value === 0) {
                    template = 'yellow';
                  } else if (value < 15) {
                    template = 'lightblue';
                  } else if (value === 15 || value > 15) {
                    template = 'blue';
                  } else if (isNaN(value)) {
                    template = 'gray';
                  }
                  break;

                case 'solarRadiation':
                  if (value < 500) {
                    template = 'yellow';
                  } else if (value < 700) {
                    template = 'orange';
                  } else if (value < 900) {
                    template = 'red';
                  } else if (value === 900 || value > 900) {
                    template = 'darkred';
                  } else if (isNaN(value)) {
                    template = 'gray';
                  }
                  break;

                case 'battery':
                case 'solarPanel':
                  // need to handle lora/umetos differently
                  if (value <= 5900) {
                    template = 'red';
                  } else if (value < 6200) {
                    template = 'orange';
                  } else if (value === 6200 || value > 6200) {
                    template = 'lightgreen';
                  } else if (isNaN(value)) {
                    template = 'gray';
                  }
                  break;
              }
              station.hidden = false;
              station.icon = 'https://api.fieldclimate.com/tools/marker.php?t=' + template + '&v=' + value.toFixed(2);
              if (template === 'gray') {
                station.icon = '/assets/img/markers/gray.png';
              }

            } else {
              station.hidden = false;
              station.icon = '/assets/img/markers/gray.png';
            }
          }
        }
      }
    }
  }

  public title(st: IStation): string {
    return st.name.custom ? `${st.name.custom} [ ${st.name.original} ]` : st.name.original;
  }

  public getMetaDate(st: IStation): string {
    return st.meta ? '\n' + moment.utc(st.meta.time * 1000).format('YYYY-MM-DD HH:mm') : '';
  }

  public navigate(id: string): void {
    this.navigationService.navigateTo(id);
  }

  public ngOnChanges(changes: { [propKey: string]: SimpleChange }): void {
    if (changes.hasOwnProperty('mapMode') && changes.mapMode.currentValue !== undefined) {
      this.switchMeta(changes.mapMode.currentValue);
    }
  }
}
