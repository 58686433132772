<div class="save-emergency">
  <hr class="save-emergency__hr">
  <div class="save-emergency__content">
    <div>
      <app-custom-button [color]="'red'"
                         [content]="'Save SMS number'"
                         (click)="saveEmergency()"></app-custom-button>
    </div>
  </div>
</div>
