<section class="product-license">
  <div class="product-license__loading"
       *ngIf="(availableLicenses$ | async) === null; else displayComponent">
    <h4>
      <i class="fa fa-cog fa-spin fa-fw"></i>
      {{ 'Checking available subscriptions...' | translate }}
    </h4>
  </div>

  <ng-template #displayComponent>
    <ng-container *ngIf="(availableLicenses$ | async).length > 0 else productLicenseActivation">
      <app-product-license-selector
        [licenses]="availableLicenses$ | async"
        (selectLicense)="onSelectLicense($event)"
        (cancel)="onCancel()">
      </app-product-license-selector>
    </ng-container>

    <ng-template #productLicenseActivation>
      <app-product-license-activation
        [licenseType]="licenseType"
        (selectLicense)="onSelectLicense($event)"
        (cancel)="onCancel()">
      </app-product-license-activation>
    </ng-template>
  </ng-template>
</section>
