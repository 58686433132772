<table class="table" *ngIf="characteristics.length > 0">
  <tr *ngFor="let characteristic of characteristics">
    <th class="col-md-{{thWidthProportion}}">{{ characteristic.name | translate }}</th>
    <td class="col-md-{{tdWidthProportion}}">
      {{ characteristic.value }}
      <span *ngIf="characteristic.spanWithLabel" class="label">{{ characteristic.spanWithLabel }}</span>
    </td>
  </tr>
</table>
<div *ngIf="characteristics.length === 0 && defaultText" class="default-text">
  {{ defaultText | translate }}
</div>
