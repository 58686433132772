import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { saveTerms } from '../../../../core/actions/account';
import { ITermsSave } from '../../../../core/models/account';
import { selectAccount } from '../../../../core/reducers';
import * as fromAccount from '../../../../core/reducers/account';
import { IAccount } from '../../../../core/reducers/account';
import { Countries, NotSet } from '../../../../shared/constants';
import { IOptions } from '../../../../shared/interfaces';
import { deepClone } from '../../../../shared/utils/deepClone';
import { emailValidation } from '../../../../shared/utils/emailValidation';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit, OnDestroy {
  public countries        : IOptions[] = deepClone(Countries);
  public form             : FormGroup;
  public isEmptyEmail     : boolean = false;
  public isInvalidEmail   : boolean = false;
  public isInvalidTerms   : boolean = false;

  private destroy$        : Subject<boolean> = new Subject<boolean>();
  private account         : IAccount;
  private notSet          : IOptions = NotSet;

  constructor(private fb: FormBuilder,
              private accountStore: Store<fromAccount.IAccount>) { }

  public ngOnInit(): void {
    this.countries.unshift(this.notSet);

    this.form = this.fb.group({
      name: [''],
      lastName: [''],
      email: [''],
      country: [''],
      promotional: [true],
      technical: [true],
      terms_accepted: [false]
    });
    this.initAccountSubscription();
  }

  public get name(): AbstractControl {
    return this.form.get('name');
  }

  public get lastName(): AbstractControl {
    return this.form.get('lastName');
  }

  public get email(): AbstractControl {
    return this.form.get('email');
  }

  public get country(): AbstractControl {
    return this.form.get('country');
  }

  public get promotional(): AbstractControl {
    return this.form.get('promotional');
  }

  public get technical(): AbstractControl {
    return this.form.get('technical');
  }

  public get terms(): AbstractControl {
    return this.form.get('terms_accepted');
  }

  private initAccountSubscription(): void {
    this.accountStore.pipe(
      takeUntil(this.destroy$),
      select(selectAccount),
    ).subscribe((account: IAccount) => {
      this.account = account;
      this.name.setValue(account.info ? account.info.name : '');
      this.lastName.setValue(account.info ? account.info.lastname : '');
      this.email.setValue(account.info ? account.info.email : '');
      this.country.setValue(account.address ? account.address.country : '');
    });
  }

  public save(): void {
    this.isInvalidEmail = !emailValidation(this.email.value);
    this.isEmptyEmail = !this.email.value;
    this.isInvalidTerms = !this.terms.value;

    if (this.isInvalidEmail || this.isEmptyEmail || this.isInvalidTerms) {
      return;
    }

    const account: IAccount = deepClone(this.account);
    account.info.name = this.name.value;
    account.info.lastname = this.lastName.value;
    account.info.email = this.email.value;
    account.address.country = this.country.value;
    account.newsletter = {
      promotional: this.promotional.value,
      technical: this.technical.value
    };
    account.terms_accepted = this.terms.value;

    const terms: ITermsSave = {
      'info.name': this.name.value,
      'info.lastname': this.lastName.value,
      'info.email': this.email.value,
      'address.country': this.country.value,
      'newsletter.promotional': this.promotional.value,
      'newsletter.technical': this.technical.value,
      'terms_accepted': this.terms.value
    };

    this.accountStore.dispatch(saveTerms({
      account: account,
      terms: terms
    }));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
