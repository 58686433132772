<app-form-layout *ngIf="!configuration.activated; else productActivated">
  <app-product-activation-license-alert [licensesOverlapped]="licensesOverlapped"></app-product-activation-license-alert>

  <div class="product-activation-farm-view">
    <div class="product-activation-farm-view__title">
      <h2>{{ (configuration.name || 'IScout Mobile information') | translate }}</h2>
    </div>

    <div class="product-activation-farm-view__content">
      <app-product-activation-iscout-mobile-form
        [cancelable]="cancelable"
        [configuration]="configuration"
        (activateProductEmitter)="handleActivateProduct($event)"
        (cancel)="handleCancel()">
      </app-product-activation-iscout-mobile-form>
    </div>
  </div>
</app-form-layout>

<ng-template #productActivated>
  <div class="alert alert-success">
    <span class="glyphicon glyphicon-ok-sign"></span>
    {{ 'The IScout Mobile license was successfully activated!' | translate }}
  </div>
</ng-template>
