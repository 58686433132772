<ng-container [formGroup]="yieldForm" *ngIf="yieldForm">
  <td>
    <app-form-select formControlName="crop" [options]="cropTypeOptions" [type]="'border'" [size]="'small'">
    </app-form-select>
  </td>
  <td>
    <app-custom-datepicker [isSmall]="true" [isOnlyFocus]="false" formControlName="sowing_date">
    </app-custom-datepicker>
  </td>
  <td>
    <app-custom-datepicker [isSmall]="true" [isOnlyFocus]="false" formControlName="harvest_date"
      [maxDate]="maximumDate">
    </app-custom-datepicker>
  </td>
  <td>
    <input type="number" step="0.01" min="0" class="yield-prediction-config__input numberInput"
      (change)="validateDecimal($event)" [formControl]="yieldControl">
  </td>
  <td>
    <button type="button" [disabled]="yieldForm.invalid" class="btn btn-sm btn-warning" (click)="updatePreviousYield()">
      {{ 'Update' | translate}}
    </button>
    <button type="button" style="padding-left: 10px;" class="btn btn-sm btn-danger" (click)="deletePreviousYield()">
      {{ 'Delete' | translate }}
    </button>
  </td>
</ng-container>