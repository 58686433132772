<section>
  <div class="alert alert-warning station-config-content__alert" *ngIf="!(hasWritePermission$ | async)">
    {{ 'You have read-only rights to this station, therefore you cannot use the drawing tool.' | translate }}
  </div>

  <app-camera-topology [linkType]="topologyLinkType.LINK_DATA"></app-camera-topology>

  <app-form-layout>
    <ng-container *ngIf="!(isLoading$ | async) && !(isError$ | async); else loadInfo">
      <app-iscout-image-gallery *ngIf="(selectedPicture$ | async) === null"
                                [title]="(subDomain?.iScoutNames ? subDomain.iScoutNames : ('iScout' | translate)) + ' ' + ('camera pictures' | translate)"
                                [deviceType]="stationType"
                                [pictures]="pictures$ | async"
                                [helpIsActive$]="helpIsActive$"
                                (selectPictureEmitter)="selectPicture($event)"></app-iscout-image-gallery>

      <app-iscout-image-settings *ngIf="(selectedPicture$ | async) !== null"
                                 [title]="(subDomain?.iScoutNames ? subDomain.iScoutNames : ('iScout' | translate)) + ' ' + ('selected picture' | translate)"
                                 [selectedPicture$]="selectedPicture$"
                                 [helpIsActive$]="helpIsActive$"
                                 [hasWritePermission]="hasWritePermission$ | async"
                                 (updatePestToggle)="updatePestToggle($event)"
                                 (unselectPictureEmitter)="unselectPicture()"></app-iscout-image-settings>
    </ng-container>

    <ng-template #loadInfo>
      <app-station-load-info [isError]="isError$ | async" [isLoading]="isLoading$ | async"></app-station-load-info>
    </ng-template>
  </app-form-layout>
</section>
