import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { IDisease } from '../../../../core/models/diseases.models';
import { IOptions } from '../../../../shared/interfaces';
import { saveDiseaseModelActivation } from '../../actions/product-activation';
import {
  IDiseaseModelActivation,
  IProductActivationState,
  IProductConfigurationItem,
  IValidLicense
} from '../../models/product-activation.models';
import { selectAvailableDiseaseModels } from '../../reducers/index';

@Component({
  selector: 'app-product-activation-disease-model',
  templateUrl: './product-activation-disease-model.component.html',
  styleUrls: ['./product-activation-disease-model.component.scss']
})
export class ProductActivationDiseaseModelComponent implements OnInit, OnDestroy {
  @Input()
  public configuration: IProductConfigurationItem;
  @Input()
  public availableUserStations$: Observable<IOptions[]>;
  @Input()
  public licensesOverlapped: IValidLicense[] = [];

  public availableDiseaseModels$: Observable<IOptions[]>;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private productActivationStore: Store<IProductActivationState>) { }

  public ngOnInit(): void {
    this.availableDiseaseModels$ = this.productActivationStore.pipe(
      takeUntil(this.destroy$),
      select(selectAvailableDiseaseModels),
      map((diseases: IDisease[]) => diseases.map(({ title, group }: IDisease) => ({
        content: group,
        value: title
      })))
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public activateProduct(activation: IDiseaseModelActivation): void {
    this.productActivationStore.dispatch(saveDiseaseModelActivation(activation));
  }
}
