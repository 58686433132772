import { IDisplayRulesConfig } from '../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../environments/interfaces/theme';

export const themeConfig: IThemeConfig = {
  subDomain: 'agrimedia',
  language: 'vn',
  headerClass: 'white',
  src: '../../../assets/themes/agrimedia/assets/images/logo.png',
  mainSrc: '../../../assets/themes/agrimedia/assets/images/landing.png',
  loginBottom: '10',
  registerBottom: '10',
  resetBottom: '10',
  mainMTop: '7',
  mainLeft: '10',
  mainWidth: '130',
  profileMenuSrc: '../../../assets/themes/default/assets/images/profile.png',
  faviconSrc: '../../../assets/themes/default/assets/favicon.ico',
  colorConfig: {
      '--search-widget-border': '#0c8c9c',
      '--search-widget-label': '#5cb85c',
      '--search-widget-label-dark-green': '#5cb85c',
      '--search-widget-label-orange': '#f39224',
      '--search-widget-button': '#009688',
      '--main-static': '#009688',
      '--main-static-orange': '#f39224',
      '--form-button': '#009688',
      '--form-button-action': '#5cb85c',
    }
};

export const displayRulesConfig: IDisplayRulesConfig = {
  userMenu: true,
  farmViewMenu: false,
  leftBarMenu: {
    stationData: true,
    cropView: true,
    iScout: true,
    weatherForecast: true,
    workPlanning: true,
    animalProduction: true,
    accumulatorTool: true,
    frostProtection: true,
    diseaseModels: true,
    soilMoisture: true,
    hydroponics: true,
    tracker: true,
    soilGuard: true,
    settings: true
  },
};
