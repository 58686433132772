import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {TranslateModule} from '@ngx-translate/core';
import {AgGridModule} from 'ag-grid-angular';
import {SharedModule} from '../../shared/shared.module';
import {UserLicensesRoutingModule} from './user-licenses-routing.module';
import {UserLicensesService} from './effects/user-licenses.service';
import {UserLicensesComponent} from './user-licenses.component';
import {UserLicensesHeaderComponent} from './components/user-licenses-header/user-licenses-header.component';
import {UserLicensesCardHeaderComponent} from './components/user-licenses-card-header/user-licenses-card-header.component';
import {UserLicensesContentComponent} from './containers/user-licenses-content/user-licenses-content.component';
import {UserLicensesListComponent} from './containers/user-licenses-list/user-licenses-list.component';
import {UserLicensesDetailComponent} from './containers/user-licenses-detail/user-licenses-detail.component';
import {reducers} from './reducers';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    SharedModule,
    StoreModule.forFeature('userLicenses', reducers),
    EffectsModule.forFeature([UserLicensesService]),
    AgGridModule.withComponents([]),
    UserLicensesRoutingModule
  ],
  declarations: [
    UserLicensesComponent,
    UserLicensesHeaderComponent,
    UserLicensesCardHeaderComponent,
    UserLicensesContentComponent,
    UserLicensesListComponent,
    UserLicensesDetailComponent
  ],
  providers: []
})
export class UserLicensesModule {
}
