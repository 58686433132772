import { Injectable } from '@angular/core';
import { Highcharts } from 'angular-highcharts';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class StationDataExportService {
  private exportXLS       : Subject<string> = new Subject<string>();

  public getExportXLS(): Observable<string> {
    return this.exportXLS.asObservable();
  }

  public startExportXLS(id: string): void {
    this.exportXLS.next(id);
  }

  public exportImage(res: any): void {
    window.open(res[0], '_blank');
  }

  public exportChartsToImage(fileName: string): void {
    const svgArr = [];
    let fullHeight = 0, width = 0, fullWidth = 0;

    Highcharts.charts.forEach((chart) => {
      if (chart) {
        let svg = chart.getSVG({
          chart: {
            width: 1200,
            height: 400
          }
        });
        // Get width/height of SVG for export
        const svgWidth = +svg.match(
            /^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/
          )[1],
          svgHeight = +svg.match(
            /^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/
          )[1];

        svg = svg.replace(
          '<svg',
          '<g transform="translate(' + width + ', ' + fullHeight + ')" '
        );

        svg = svg.replace('</svg>', '</g>');
        width += svgWidth;
        fullWidth = Math.max(fullWidth, width);

        if (width === 1 * svgWidth) {
          width = 0;
          fullHeight += svgHeight;
        }

        svgArr.push(svg);
      }
    });

    const svgString = '<svg height="' + fullHeight + '" width="' + fullWidth +
      '" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">' +
      svgArr.join('') + '</svg>';

    this.createImageFile(fullWidth, fullHeight, svgString, fileName);
  }

  private createImageFile(width, height, svgString, fileName) : void {
    const svgBlob = new Blob([svgString], {
      type: 'image/svg+xml;charset=utf-8'
    });

    const url = URL.createObjectURL(svgBlob);

    // load the SVG blob to a fresh image object
    const img = new Image();
    img.addEventListener('load', () => {
      // draw the image on an ad-hoc canvas
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;

      const context = canvas.getContext('2d');
      context.drawImage(img, 0, 0, width, height);

      URL.revokeObjectURL(url);

      this.download(canvas.toDataURL(), fileName);
    });
    img.src = url;
  }

  private download(url, fileName) : void {
    const a = document.createElement('a');

    a.download = fileName;
    a.href = url;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a.remove();
  }

}
