import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {EmergencyActionTypes} from '../actions/emergency';

export interface IEmergencyState {
  emergency: string;
}

const initialState: IEmergencyState = {
  emergency: '',
};

export function reducer(state: IEmergencyState = initialState, action: IActionWithPayload ): IEmergencyState {
  switch (action.type) {
    case EmergencyActionTypes.SET_EMERGENCY: {
      return {
        ...state,
        emergency: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
