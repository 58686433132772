<section class="form-layout height130">
  <div class="container" style="width: 100%; height: 100%">
    <div class="cropzoneInfoDiv">
      <div class="cropzoneInfo" (click)="goToSoilMoistureSumOfCropzone()">
      <p>{{ cropzone.farm.name }} / {{ cropzone.field.name }} / {{ cropzone.name }}</p>
      <p>{{ stationId }} / {{ node }}</p>
    </div>
    </div>
    <div class="chart" #chart id="{{cropzone.id}}"></div>
  </div>
</section>
