import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { AgmCoreModule } from '@agm/core';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

import { CropZoneSatRoutingModule } from './crop-zone-sat-routing.module';
import { CropZoneLaiComponent } from './components/crop-zone-lai/crop-zone-lai.component';

import { reducers } from './reducers';
import { LaiEffects } from './effects';
import { LazyListComponent } from './containers/lazy-list.component';
import { LazyListItemComponent } from './containers/lazy-list-item.component';
import { ObserveVisibilityDirective } from './directives/observe-visibility.directive';
import { SatelliteHelpTextModalComponent } from './containers/satellite-help-text-modal/satellite-help-text-modal.component';
import { CropZoneNdviComponent } from './components/crop-zone-ndvi/crop-zone-ndvi.component';
import { SatelliteNdviHelpTextModalComponent } from './containers/satellite-ndvi-help-text-modal/satellite-ndvi-help-text-modal.component';
import { SatWarningMessageComponent } from './containers/sat-warning-message/sat-warning-message.component';

@NgModule({
  imports: [
  CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    CropZoneSatRoutingModule,
    AgmCoreModule,
    StoreModule.forFeature('cropZoneSat', reducers),
    EffectsModule.forFeature([LaiEffects]),
    ChartModule
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting] }
  ],
  declarations: [
    CropZoneLaiComponent,
    LazyListComponent,
    LazyListItemComponent,
    ObserveVisibilityDirective,
    SatelliteHelpTextModalComponent,
    CropZoneNdviComponent,
    SatelliteNdviHelpTextModalComponent,
    SatWarningMessageComponent
  ]
})
export class CropZoneSatModule { }
