import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { LoggingAndTransferActionTypes } from '../actions/loggingAndTransfer';
import { ILoggingAndTransferConfig } from '../models/station-config.models';

export interface ILoggingAndTransferState {
  config: ILoggingAndTransferConfig;
  isIScout: boolean;
  isCropView: boolean;
  isAdvanced: boolean;
  showAdvanced: boolean;
  isDefault?: ILoggingIsDefault;
}

export interface ILoggingIsDefault {
  settingsAreDefault: boolean;
  schedulerIsDefault: boolean;
}

const initialState: ILoggingAndTransferState = {
  config: {
    logging_interval: 60,
    fixed_transfer_interval: 30,
    transfer_interval: 10,
    measuring_interval: 10,
    network_registration_timeout: 60,
    activity_mode: 0,
    rain_monitor: 0,
    frost_monitoring_enabled: false,
    frost_monitoring_threshold: 0,
    frost_monitoring_sensor_code: 0,
    frost_monitoring_sensor_channel: 0,
    frost_monitoring_interval: 0,
    scheduler: null
  },
  isIScout: false,
  isCropView: false,
  isAdvanced: false,
  showAdvanced: false,
  isDefault: {
    settingsAreDefault: false,
    schedulerIsDefault: false
  }
};

export function reducer(state: ILoggingAndTransferState = initialState, action: IActionWithPayload ): ILoggingAndTransferState {
  switch (action.type) {
    case LoggingAndTransferActionTypes.SET_LOGGING_AND_TRANSFER: {
      return {
        ...state,
        ...action.payload
      };
    }
    case LoggingAndTransferActionTypes.SET_MEASURMENT_INTERVAL: {
      return {
        ...state,
        config: {
          ...state.config,
          measuring_interval: action.payload
        }
      };
    }
    case LoggingAndTransferActionTypes.SET_LOGGING_INTERVAL: {
      return {
        ...state,
        config: {
          ...state.config,
          logging_interval: action.payload
        }
      };
    }
    case LoggingAndTransferActionTypes.SET_TRANSFER_INTERVAL: {
      return {
        ...state,
        config: {
          ...state.config,
          fixed_transfer_interval: action.payload
        }
      };
    }
    case LoggingAndTransferActionTypes.SET_SCHEDULER: {
      return {
        ...state,
        config: {
          ...state.config,
          scheduler: action.payload
        }
      };
    }
    case LoggingAndTransferActionTypes.SET_ADVANCED: {
      return {
        ...state,
        config: {
          ...state.config,
          ...action.payload
        }
      };
    }
    case LoggingAndTransferActionTypes.SET_SHOW_ADVANCED: {
      return {
        ...state,
        showAdvanced: action.payload
      };
    }
    case LoggingAndTransferActionTypes.SET_IS_DEFAULT: {
      return {
        ...state,
        isDefault: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
