import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { ActionTypes } from '../actions/ndvi';
import { INdviState } from '../models';

const initialState: INdviState = {
    ndviStats: {},
    visibleNdviDates: {},
    visibleNdviVals: {},
    detailedNdviVal: [],
    fittingCurve: {},
    noDataError: {}
};

export function reducer(state: INdviState = initialState, action: IActionWithPayload): INdviState {
    switch (action.type) {
        case ActionTypes.SET_NDVI_STATS:
            if (typeof action.payload.laiStats !== 'string') {
                state.ndviStats[action.payload.cropZoneId] = action.payload.ndviStats;

                return {
                    ...state,
                    ndviStats: {
                        ...state.ndviStats
                    }
                };
            } else {
                return {
                    ...state,
                    noDataError: true
                };
            }

        case ActionTypes.SET_NO_DATA_ERROR_NDVI:
            state.noDataError[action.payload.cropZoneId] = action.payload.noDataError;

            return {
                ...state,
                noDataError: {
                    ...state.noDataError
                }
            };
        case ActionTypes.SET_NDVI_VAL_RES_10:
            state.detailedNdviVal[action.payload.cropZoneId] = action.payload.ndviVals[0];

            return {
                ...state,
                detailedNdviVal: {
                    ...state.detailedNdviVal
                }
            };
        case ActionTypes.SET_VISIBLE_NDVI_DATES:
            return {
                ...state,
                visibleNdviDates: {
                    ...action.payload
                }
            };
        case ActionTypes.ADD_VISIBLE_NDVI_VAL:
            if (state.visibleNdviDates[action.payload.cropZoneId]) {
                if (!state.visibleNdviDates[action.payload.cropZoneId].some(date => date === action.payload.date)) {
                    state.visibleNdviDates[action.payload.cropZoneId].push(action.payload.date);
                }
            } else {
                state.visibleNdviDates[action.payload.cropZoneId] = [];
                state.visibleNdviDates[action.payload.cropZoneId].push(action.payload.date);
            }

            return {
                ...state,
                visibleNdviDates: {
                    ...state.visibleNdviDates
                }
            };
        case ActionTypes.SET_VISIBLE_NDVI_VALS:
            if (action.payload.ndviVals.length > 0) {
                let ndviVals = state.visibleNdviVals[action.payload.cropzone.id];

                if (ndviVals) {
                    ndviVals = ndviVals.concat(action.payload.ndviVals);
                } else {
                    ndviVals = [];
                    ndviVals = action.payload.ndviVals;
                }

                const temp = [];

                ndviVals = ndviVals.filter(val => val !== undefined);
                const fullResNdviValues = ndviVals.filter(laiValue => laiValue['resolution'] === 10);

                fullResNdviValues.forEach(fullResValue => {
                    if (!temp.some(value => value.date === fullResValue.date)) {
                        temp.push(fullResValue);
                    }
                });
                ndviVals.forEach(val => {
                    if (!temp.some(value => value.date === val.date)) {
                        temp.push(val);
                    }
                });
                state.visibleNdviVals[action.payload.cropzone.id] = temp;
                return {
                    ...state,
                    visibleNdviVals: {
                        ...state.visibleNdviVals
                    }
                };
            } else {
                state.visibleNdviVals[action.payload.cropzone.id] = [];
                return {
                    ...state,
                    visibleNdviVals: {
                        ...state.visibleNdviVals
                    }
                };
            }
        case ActionTypes.SET_FITTING_CURVE_NDVI:
            state.fittingCurve[action.payload.cropZoneId] = action.payload.data;

            return {
                ...state,
                fittingCurve: {
                    ...state.fittingCurve
                }
            };
        case ActionTypes.DELETE_ALL_NDVI:
            if (state.ndviStats[action.payload]) {
                delete state.ndviStats[action.payload];
            }
            if (state.visibleNdviDates[action.payload]) {
                delete state.visibleNdviDates[action.payload];
            }
            if (state.visibleNdviVals[action.payload]) {
                delete state.visibleNdviVals[action.payload];
            }
            if (state.fittingCurve[action.payload]) {
                delete state.fittingCurve[action.payload];
            }
            if (state.detailedNdviVal[action.payload]) {
                delete state.detailedNdviVal[action.payload];
            }
            if (state.noDataError[action.payload]) {
                delete state.noDataError[action.payload];
            }

            return state;
        default: {
            return state;
        }
    }
}
