import { CropzonesOptionsPipe } from './pipes/cropzones-options/cropzones-options.pipe';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DashboardComponent} from './dashboard.component';
import {DashboardRoutingModule} from './dashboard-routing.module';
import {ColumnComponent} from './containers/column/column.component';
import {DragulaModule} from 'ng2-dragula';
import {SharedModule} from '../../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {DashboardMenuComponent} from './components/dashboard-menu/dashboard-menu.component';
import {StoreModule} from '@ngrx/store';
import {reducers} from './reducers/index';
import {DashboardService} from './effects/dashboard.effects';
import {EffectsModule} from '@ngrx/effects';
import {ChartModule, HIGHCHARTS_MODULES} from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import {AgmCoreModule} from '@agm/core';
import {GoogleMapComponent} from './components/google-map/google-map.component';
import {StationsListComponent} from './components/stations-list/stations-list.component';
import {StationDataComponent} from './components/station-data/station-data.component';
import {ForecastTableComponent} from './components/forecast-table/forecast-table.component';
import {AgGridModule} from 'ag-grid-angular';
import {ForecastComponent} from './components/forecast/forecast.component';
import {DashboardImagesComponent} from './components/dashboard-images/dashboard-images.component';
import {PaginationModule} from 'ngx-bootstrap';
import {ChartSettingsComponent} from './containers/widgets/chart-widget/chart-settings/chart-settings.component';
import {WidgetHeaderComponent} from './components/widget-header/widget-header.component';
import {ChartComponent} from './components/chart/chart.component';
import {RowComponent} from './containers/row/row.component';
import {ChartWidgetComponent} from './containers/widgets/chart-widget/chart-widget.component';
import {ForecastWidgetComponent} from './containers/widgets/forecast-widget/forecast-widget.component';
import {CropViewWidgetComponent} from './containers/widgets/crop-view-widget/crop-view-widget.component';
import {IscoutWidgetComponent} from './containers/widgets/iscout-widget/iscout-widget.component';
import {GoogleMapWidgetComponent} from './containers/widgets/google-map-widget/google-map-widget.component';
import {DiseaseWidgetComponent} from './containers/widgets/disease-widget/disease-widget.component';
import {DataWidgetComponent} from './containers/widgets/data-widget/data-widget.component';
import {StationsListWidgetComponent} from './containers/widgets/stations-list-widget/stations-list-widget.component';
import {CropViewSettingsComponent} from './containers/widgets/crop-view-widget/crop-view-settings/crop-view-settings.component';
import {DataSettingsComponent} from './containers/widgets/data-widget/data-settings/data-settings.component';
import {ForecastSettingsComponent} from './containers/widgets/forecast-widget/forecast-settings/forecast-settings.component';
import {IscoutSettingsComponent} from './containers/widgets/iscout-widget/iscout-settings/iscout-settings.component';
import {DiseaseSettingsComponent} from './containers/widgets/disease-widget/disease-settings/disease-settings.component';
import {WidgetInfoComponent} from './components/widget-info/widget-info.component';
import {CustomMultiDropdownComponent} from './components/custom-multi-dropdown/custom-multi-dropdown.component';
import {DiseaseStationPipe} from './pipes/disease-station/disease-station.pipe';
import {DiseaseStationsPipe} from './pipes/disease-stations/disease-stations.pipe';
import {WeatherForecastPipe} from './pipes/weather-forecast/weather-forecast.pipe';
import {IscoutPipe} from './pipes/iscout/iscout.pipe';
import {StationsOptionsPipe} from './pipes/stations-options/stations-options.pipe';
import {CropViewPipe} from './pipes/crop-view/crop-view.pipe';
import {WidgetCreationComponent} from './containers/widget-creation/widget-creation.component';
import {ColumnHeaderComponent} from './containers/column/column-header/column-header.component';
import {WidgetAddButtonsComponent} from './containers/widget-creation/widget-add-buttons/widget-add-buttons.component';
import {WidgetAddInfoComponent} from './containers/widget-creation/widget-add-info/widget-add-info.component';
import {WidgetAddFormComponent} from './containers/widget-creation/widget-add-form/widget-add-form.component';
import {MoreOptionsComponent} from './components/more-options/more-options.component';
import {EmptyColumnComponent} from './containers/column/empty-column/empty-column.component';
import {WidgetLoadInfoComponent} from './components/widget-load-info/widget-load-info.component';
import {environment} from '../../../assets/themes/default/configuration';
import { LeaveConfirmComponent } from './containers/leave-confirm/leave-confirm.component';
import {DashboardGuard} from './guards/dashboard.guard';
import { TermsAndConditionsComponent } from './containers/terms-and-conditions/terms-and-conditions.component';
import { ImageModalComponent } from './components/image-modal/image-modal.component';
// import { TrackerWidgetComponent } from './containers/tracker-widget/tracker-widget.component';
import { TrackWidgetSettingsComponent } from './containers/widgets/tracker/track-widget-settings/track-widget-settings.component';
import { TrackStationPipe } from './pipes/track-station/track-station.pipe';
import { TrackerComponentComponent } from './components/tracker/tracker-component.component';
import { TrackerContainerComponent } from './containers/widgets/tracker/tracker-container.component';
import { ForecastChartWidgetComponent } from './containers/widgets/forecast-chart-widget/forecast-chart-widget.component';
import { ForecastChartSettingsComponent } from './containers/widgets/forecast-chart-widget/forecast-chart-settings/forecast-chart-settings.component';
import { ForecastTableWidgetComponent } from './containers/widgets/forecast-table-widget/forecast-table-widget.component';
import { ForecastTableSettingsComponent } from './containers/widgets/forecast-table-widget/forecast-table-settings/forecast-table-settings.component';
import {WeatherForecastModule} from '../weather-forecast/weather-forecast.module';
import { GoogleMapLibraries } from '../../shared/constants';
import { CropzonesWidgetComponent } from './containers/widgets/cropzones-widget/cropzones-widget.component';
import { CropzoneListComponent } from './components/cropzone-list/cropzone-list.component';
import { SoilMoistureSumChartComponent } from './components/cropzone-list/soil-moisture-sum-chart/soil-moisture-sum-chart.component';
import { LeafletMapComponent } from './components/leaflet-map/leaflet-map.component';
// tslint:disable-next-line:max-line-length
import { CropzonesWidgetHelpTextModalComponent } from '../dashboard/containers/widgets/cropzones-widget/cropzones-widget-help-text-modal/cropzones-widget-help-text-modal/cropzones-widget-help-text-modal.component';
import { StationsListSortComponent } from './components/stations-list-sort/stations-list-sort.component';
import { WindRoseDiagramWidgetComponent } from './containers/widgets/wind-rose-diagram-widget/wind-rose-diagram-widget.component';
import { WindRoseDiagramSettingsComponent } from './containers/widgets/wind-rose-diagram-widget/wind-rose-diagram-settings/wind-rose-diagram-settings.component';
import { WindRoseDiagramChartComponent } from './components/wind-rose-diagram-chart/wind-rose-diagram-chart.component';

@NgModule({
  imports: [
    CommonModule,
    DashboardRoutingModule,
    DragulaModule,
    SharedModule,
    StoreModule.forFeature('dashboard', reducers),
    EffectsModule.forFeature([DashboardService]),
    AgmCoreModule.forRoot({
      apiKey: environment.gMapKey,
      libraries: GoogleMapLibraries
    }),
    ChartModule,
    AgGridModule.withComponents([]),
    PaginationModule.forRoot(),
    TranslateModule.forChild(),
    WeatherForecastModule
  ],
  declarations: [
    DashboardComponent,
    ColumnComponent,
    DashboardMenuComponent,
    GoogleMapComponent,
    StationsListComponent,
    StationDataComponent,
    ForecastComponent,
    DashboardImagesComponent,
    ChartSettingsComponent,
    WidgetHeaderComponent,
    ChartComponent,
    RowComponent,
    ChartWidgetComponent,
    ForecastWidgetComponent,
    CropViewWidgetComponent,
    IscoutWidgetComponent,
    GoogleMapWidgetComponent,
    DiseaseWidgetComponent,
    DataWidgetComponent,
    StationsListWidgetComponent,
    CropViewSettingsComponent,
    DataSettingsComponent,
    ForecastSettingsComponent,
    IscoutSettingsComponent,
    DiseaseSettingsComponent,
    WidgetInfoComponent,
    CropViewPipe,
    StationsOptionsPipe,
    CropzonesOptionsPipe,
    IscoutPipe,
    WeatherForecastPipe,
    DiseaseStationsPipe,
    DiseaseStationPipe,
    CustomMultiDropdownComponent,
    WidgetCreationComponent,
    ColumnHeaderComponent,
    WidgetAddButtonsComponent,
    WidgetAddInfoComponent,
    WidgetAddFormComponent,
    MoreOptionsComponent,
    EmptyColumnComponent,
    WidgetLoadInfoComponent,
    LeaveConfirmComponent,
    TermsAndConditionsComponent,
    ImageModalComponent,
    TrackWidgetSettingsComponent,
    TrackStationPipe,
    TrackerComponentComponent,
    TrackerContainerComponent,
    ForecastChartWidgetComponent,
    ForecastChartSettingsComponent,
    ForecastTableWidgetComponent,
    ForecastTableSettingsComponent,
    ForecastTableComponent,
    CropzonesWidgetComponent,
    CropzoneListComponent,
    SoilMoistureSumChartComponent,
    LeafletMapComponent,
    CropzonesWidgetHelpTextModalComponent,
    StationsListSortComponent,
    WindRoseDiagramWidgetComponent,
    WindRoseDiagramSettingsComponent,
    WindRoseDiagramChartComponent
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [ more, exporting ]},
    DashboardGuard
  ]
})
export class DashboardModule { }
