<app-station-page-header [pageName]="'Station Information'"></app-station-page-header>
<app-form-layout>
  <div class="station-information">
    <div class="row">
      <div class="basic-information">
        <h4><i class="fa fa-info-circle fa-fw"></i>{{ 'Basic information' | translate }}</h4>
        <app-station-information-table [characteristics]="basicInformation"></app-station-information-table>
      </div>
      <div class="network-details">
        <h4><i class="fa fa-signal fa-fw"></i> {{ 'Network details' | translate }}</h4>
        <app-station-information-table [characteristics]="networkDetails"></app-station-information-table>
        <hr />

        <app-custom-button class="link-button" [routerLink]="['/station/' + (selectedStation$ | async)?.name.original + '/events']" [color]="'green'" [content]="'Station events'"></app-custom-button>
        <app-custom-button class="link-button" [routerLink]="['/station/' + (selectedStation$ | async)?.name.original + '/communication']" [color]="'green'" [content]="'Communication history'"></app-custom-button>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="weather-data">
        <h4><i class="fa fa-calendar fa-fw"></i> {{ 'Weather data' | translate }}</h4>
        <app-station-information-table [characteristics]="weatherData"></app-station-information-table>
      </div>
      <div class="gps-location">
        <h4><i class="fa fa-globe fa-fw"></i> {{ 'GPS Location' | translate }}</h4>
        <app-station-information-table [characteristics]="gpsLocation"
                                       [thWidthProportion]="5"
                                       [tdWidthProportion]="7"
                                       [defaultText]="DEFAULT_GPS_LOCATION_TEXT">
        </app-station-information-table>
      </div>
    </div>
  </div>
</app-form-layout>
