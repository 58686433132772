import { IWaterBalanceState } from '../models/models';
import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { ActionTypes } from '../actions/irrimet-water-balance';

export const waterBalanceState: IWaterBalanceState = {
  fromTo: {
    from: null,
    to: null
  },
  period: Infinity,
  isTableActive: true,
  isLastDataMode: true
};

export function waterBalanceReducer(state = waterBalanceState, action: IAction): IWaterBalanceState {
  switch (action.type) {
    case ActionTypes.SET_WATER_BALANCE_PERIOD:
      return {
        ...state,
        period: action.payload
      };
    case ActionTypes.SET_WATER_BALANCE_IS_LAST_DATA_MODE:
      return {
        ...state,
        isLastDataMode: action.payload
      };
    case ActionTypes.SET_WATER_BALANCE_FROM_TO:
      return {
        ...state,
        fromTo: action.payload
      };
    case ActionTypes.SET_DWB_TABLE_ACTIVE:
      return {
        ...state,
        isTableActive: action.payload
      };
    default:
      return state;
  }
}

