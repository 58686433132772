<ng-container [formGroup]="formGroup">
  <td class="first">
    {{ setting.name | translate}}
  </td>
  <td>
    <app-form-select
      formControlName="unit"
      class="control"
      [size]="'small'"
      [options]="setting.units">
    </app-form-select>
  </td>
  <td>
      <app-form-input
        formControlName="color"
        [type]="'border'"
        [styles]="{'cursor': 'pointer', 'background-color': formGroup.getRawValue().color}"
        class="control"
        [isDisabled]="true"
        [size]="'small'">
      </app-form-input>
      <div
        class="fake-color-picker"
        [colorPicker]="formGroup.getRawValue().color"
        (colorPickerChange)="colorChange($event)"
        [cpFallbackColor]="'#f5f5f5'"
        [cpOutputFormat]="'hex'"
        [cpAlphaChannel]="'disabled'"
        [cpPosition]="'bottom'"
        [cpPresetColors]="presetColors"
        [cpUseRootViewContainer]="true"></div>
  </td>
  <td class="last">
    <app-custom-button (click)="update.emit(formGroup.getRawValue())">{{ 'Update' | translate }}</app-custom-button>
  </td>
</ng-container>
