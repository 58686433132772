<ng-container>
  <app-crop-view-header
    [title]="title"
    [enableHelpButton]="true"
    (openModal)="openHelpModal()"
  ></app-crop-view-header>
  <app-crop-view-image-gallery-toolbar></app-crop-view-image-gallery-toolbar>

  <tabset>
    <tab [heading]="'Wide lens' | translate" [active]="activeCameraId === 1">
      <app-custom-image-gallery
        [pictures]="widePictures"
        [deviceType]="deviceType"
        (selectPictureEmitter)="selectPicture($event, 1)"
      ></app-custom-image-gallery>
    </tab>

    <tab [heading]="'Zoom lens' | translate" [active]="activeCameraId === 2">
      <app-custom-image-gallery
        [pictures]="zoomPictures"
        [deviceType]="deviceType"
        (selectPictureEmitter)="selectPicture($event, 2)"
      ></app-custom-image-gallery>
    </tab>
  </tabset>

  <app-info-modal [modalId]="helpModalId" [header]="'CropView Gallery'">
    <p>
      {{
      'In the Gallery we show all pictures per month or week. If you want ' +
      'to see previous months/weeks use the buttons for moving back or forward.' | translate
      }}
    </p>

    <p>
      {{
      'When clicking on one of the gallery pictures that one will be enlarged and you will be able to ' +
      'adapt/modify it by changing the camera distance or drawing new fruits.' | translate
      }}
    </p>
  </app-info-modal>
</ng-container>
