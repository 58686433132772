import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserSupportRoutingModule} from './user-support-routing.module';
import {SharedModule} from '../../shared/shared.module';

import {TranslateModule} from '@ngx-translate/core';
import {UserSupportCardHeaderComponent} from './components/user-support-card-header/user-support-card-header.component';
import {UserSupportComponent} from './containers/user-support/user-support.component';
import {UserSupportHeaderComponent} from './containers/user-support/user-support-header/user-support-header.component';
import {SendMessageComponent} from './containers/user-support/send-message/send-message.component';
import {SendMessageFormComponent} from './containers/user-support/send-message/send-message-form/send-message-form.component';

@NgModule({
  imports: [
    CommonModule,
    UserSupportRoutingModule,
    SharedModule,
    TranslateModule.forChild()
  ],
  declarations: [
    UserSupportCardHeaderComponent,
    UserSupportComponent,
    UserSupportHeaderComponent,
    SendMessageComponent,
    SendMessageFormComponent
   ]
})
export class UserSupportModule { }
