<ng-container *ngIf="sampleData && sampleData.length; else loading">
  <ng-container *ngIf="hasPosition; else noData">
    <agm-map [latitude]="lat"
             [longitude]="lng"
             [disableDefaultUI]="true"
             [zoomControl]="true"
             [scrollwheel]="true"
             [mapTypeControl]="true"
             [streetViewControl]="false"
             [fitBounds]="!zoom"
             [zoom]="zoom"
             [styles]="styles"
             (zoomChange)="updateZoom($event)"
             [mapTypeId]="'hybrid'">

      <ng-container *ngFor="let sample of sampleData; let i = index">
        <agm-marker *ngIf="sample.latitude && sample.longitude"
                    [latitude]="sample.latitude"
                    [longitude]="sample.longitude"
                    [markerDraggable]="false"
                    [iconUrl]="getMarker(i, sample)"
                    [zIndex]="i === activeSample ? 100 : 1"
                    [title]="sample.deviceID"
                    [visible]="true"
                    [opacity]="1"
                    [agmFitBounds]="true"
                    (markerClick)="navigate(i)">
        </agm-marker>
      </ng-container>
    </agm-map>

    <div *ngIf="legend && legendValues[activeMeter] as legend" class="legend">
      <b class="legend__title">{{ legend.title | translate }}</b>
      <div class="legend__circles">
        <ng-container *ngFor="let image of legend.markers">
          <img class="legend__circle" [src]="image.src" [alt]="image.alt">
        </ng-container>
      </div>
      <div class="legend__text">
        <p *ngFor="let title of legend[unitSystem]">
          <b>{{ title.name | translate }}</b>
          <br>
        </p>
      </div>
    </div>
  </ng-container>

  <ng-template #noData>
    <div class="widget-info-container" style="min-height: 500px">
      <h4>
        <i class="fa fa-warning fa-fw"></i>
        {{ 'The uploaded samples don’t contain geographic coordinates, therefore, are unable to be visualized on the map.' | translate }}
      </h4>
    </div>
  </ng-template>
</ng-container>

<ng-template #loading>
  <div *ngIf="!dataReady" class="widget-info-container" style="min-height: 500px">
    <h4>
      <i class="fa fa-cog fa-spin fa-fw"></i>
      {{ 'Please wait, loading data ...' | translate }}
    </h4>
  </div>

  <div *ngIf="dataReady" class="widget-info-container" style="min-height: 500px">
    <h4>
      <i class="fa fa-warning fa-fw"></i>
      {{ 'No samples to show!' | translate }}
    </h4>
  </div>
</ng-template>
