import {
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IStationBasicInfo } from '../../../../core/models/navigation-station';
import { selectNavigationStationBasicInfo } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { setSelectedConnection, toggleTripSelected } from '../../../../modules/tracker/actions/tracker';
import { ITrackerTripsData } from '../../../../modules/tracker/models/tracker.models';
import { selectTrackerData } from '../../../../modules/tracker/reducers';
import { TrackerService } from '../../../../modules/tracker/services/tracker.service';
import { ITree, ITreeStructure } from '../../../../services/tree/models';
import { getTimeString } from '../../../utils/getTimeString';
import { getTrackerReports } from '../../actions/tree-settings';
import { ITreeComponent } from '../../models/tree.models';
import { ITrackerReports, selectTrackerReports } from '../../reducers/tracker';

@Component({
  selector: 'app-tree-cards',
  templateUrl: './tree-cards.component.html',
  styleUrls: ['./tree-cards.component.scss']
})
export class TreeCardsComponent implements ITreeComponent, OnInit, OnDestroy {
  public cards: ITree;
  public stationName: string;
  public trackerConnections: any;
  public selectedCard: string;
  public trips: ITrackerTripsData[];
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private navigationStore: Store<INavigationStationState>,
    private trackerReportsStore: Store<ITrackerReports>,
    private trackerDataStore: Store<ITrackerTripsData>,
    private trackerService: TrackerService,
  ) { }

  public ngOnInit(): void {
    this.trackerReportsStore.pipe(
      select(selectTrackerReports),
      takeUntil(this.destroy$)
    ).subscribe(data => {
      this.trackerConnections = data;
      if (this.trackerConnections && this.trackerConnections.length > 0) {
        this.setDefaultSelected();
      }
    });

    this.navigationStore.pipe(
      select(selectNavigationStationBasicInfo),
      takeUntil(this.destroy$),
      filter((stationBasicInfo: IStationBasicInfo): boolean => !!stationBasicInfo.originalName)
    ).subscribe((stationBasicInfo: IStationBasicInfo) => {
      this.stationName = stationBasicInfo.originalName;
      this.getTrackerReports(this.stationName);
    });

    this.trackerDataStore.pipe(
      select(selectTrackerData),
      takeUntil(this.destroy$)
    ).subscribe((trackerData: ITrackerTripsData[]) => {
      this.trips = trackerData;
      if (this.trips.length !== 0) {
          const selectedTrip = localStorage.getItem('selectedTrip');
          if (selectedTrip) {
            this.trackerDataStore.dispatch(toggleTripSelected(this.trips[0].name));
            this.trackerDataStore.dispatch(toggleTripSelected(selectedTrip));
            localStorage.removeItem('selectedTrip');
          }
      }
    });
  }

  public setDefaultSelected(): void {
    for (let i = 0; i <= this.trackerConnections.length; i++ ) {
      if (this.trackerConnections[i].toDate == null) {
        this.selectedCard = this.trackerConnections[i]._id;
        this.pushValue(this.trackerConnections[i]);
        return;
      }
    }
    this.selectedCard = null;
  }

  public loadTripData(trip: ITrackerTripsData): void {
      this.trackerService.setSelectedTripIO(trip);
  }

  public setTree(tree: ITreeStructure): void {
    this.cards = tree.trees.length > 0 ? tree.trees[0] : null;
  }

  public toggleTripVisibility(tripName: string): void {
    this.trackerDataStore.dispatch(toggleTripSelected(tripName));
  }

  public getTrackerReports(stationId: string): void {
    this.trackerReportsStore.dispatch(getTrackerReports(stationId));
  }

  // pushes value to tracker page
  public pushValue(connection: any): void {
    this.selectedCard = connection._id;
    this.trackerDataStore.dispatch(setSelectedConnection(connection));
  }

  public calculateTime (time: number): string {
    return getTimeString(time);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
