import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LANG } from '../../shared/constants';
import { environmentToken } from '../../../environments/environment';
import { IEnvironment } from '../../../environments/interfaces/environment';

@Injectable()
export class LanguageService {

  private language: string;

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    private translate: TranslateService
    ) {

    const storedLang: string = this.languageFromStorage;
    const navigatorLang = navigator.language.substring(0, 2);

    const domainInfo = environment.theme;

    if (storedLang) {

      this.language = storedLang;

    } else if (domainInfo && domainInfo.subDomain && domainInfo.language) {

      this.language = domainInfo.language;

    } else {

      this.language = environment.languages.some(option => option.value === navigatorLang) ? navigatorLang : DEFAULT_LANG;
    }

    this.setLanguage(this.language);
   }

  public setLanguage(lang: string): void {
    localStorage.setItem('language', lang);
    this.translate.use(lang);
  }

  public get languageFromStorage(): string {
    return localStorage.getItem('language');
  }
}
