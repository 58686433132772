import {iScoutPestsInitialState} from '../constants/constants';
import {IIscoutPestsState} from '../models/iscout.models';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IscoutPestsActionTypes} from '../actions/iscout-pests';

export function reducer(state: IIscoutPestsState = iScoutPestsInitialState, action: IActionWithPayload): IIscoutPestsState {
  switch (action.type) {
    case IscoutPestsActionTypes.SET_ISCOUT_PESTS_ORDERS:
      return {
        ...state,
        pestsOrders: action.payload
      };
    case IscoutPestsActionTypes.SET_ISCOUT_PESTS_FAMILY:
      return {
        ...state,
        pestsFamily: action.payload
      };
    case IscoutPestsActionTypes.SET_ISCOUT_PESTS_GENUS:
      return {
        ...state,
        pestsGenus: action.payload
      };
    case IscoutPestsActionTypes.SET_ISCOUT_USER_PESTS:
      return {
        ...state,
        pests: action.payload
      };
    case IscoutPestsActionTypes.SELECT_ISCOUT_PEST:
      return {
        ...state,
        selectedPest: action.payload
      };
    case IscoutPestsActionTypes.UNSELECT_ISCOUT_PEST:
      return {
        ...state,
        selectedPest: iScoutPestsInitialState.selectedPest
      };
    default:
      return state;
  }
}
