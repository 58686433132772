<app-selected-cropzone *ngIf="stationOrCropzone === 'cropzone'"></app-selected-cropzone>
<app-selected-station *ngIf="stationOrCropzone === 'station'"></app-selected-station>

<div class="content">
  <h3>{{ name | translate }}</h3>
  <ul class="link-list">
    <li class="main-group">

      <ng-container *ngIf="devices?.soilGuard?.length && 'soilguard' === deviceType">
        <span>{{ 'SoilGuard' | translate }}</span>
        <ul class="second-group">
          <ng-container *ngFor="let device of devices?.soilGuard">
            <li (click)="selectDevice(device[0]?.deviceID)" [class.active]="device[0]?.deviceID === activeDevice">
              <span>
                <i class="icon-fc-soilguard"></i>
                {{ device[0]?.deviceID }}
              </span>
            </li>
          </ng-container>
        </ul>
      </ng-container>

      <ng-container *ngIf="devices?.dualex?.length && ['nutrition', 'dualex'].includes(deviceType)">
        <span>{{ 'Dualex' | translate }}</span>
        <ul class="second-group">
          <ng-container *ngFor="let device of devices?.dualex">
            <li (click)="selectDevice(device[0]?.deviceID)" [class.active]="device[0]?.deviceID === activeDevice">
              <span>
                <i class="icon-fc-dualex" style="margin-right: 0"></i>
                {{ device[0]?.deviceID }}
              </span>
            </li>
          </ng-container>
        </ul>
      </ng-container>

      <ng-container *ngIf="devices?.mobilab?.length && ['nutrition', 'mobilab'].includes(deviceType)">
        <span>{{ 'MobiLab' | translate }}</span>
        <ul class="second-group">
          <ng-container *ngFor="let device of devices?.mobilab">
            <li (click)="selectDevice(device[0]?.sampleName)" [class.active]="device[0]?.deviceID === activeDevice">
              <span>
                <i class="fa fa-flask" style="margin-right: 0; font-size: 11px"></i>
                {{ device[0]?.sampleName }}
              </span>
            </li>
          </ng-container>
        </ul>
      </ng-container>

    </li>
  </ul>
</div>
