import { ICropZone } from '../../core/models/cropzones';
import { Injectable, Inject } from '@angular/core';
import {
  INode,
  ISubNode,
  ITopologyItem,
  ITopologyNode,
  ITopologySensorData,
  ITree,
  ITreeProfile,
  ITreeStructure,
  TreeContentType,
  TreeType
} from './models';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/internal/operators';
import { IOptions } from '../../shared/interfaces';
import { select, Store } from '@ngrx/store';
import { IStationDataState } from '../../modules/station-data/reducers/station-data';
import { selectStationDataProfiles, selectStationDataTopology } from '../../modules/station-data/reducers';
import {
  topology as SelectSoilMoistureTopology,
  treeSettings,
  views as soilMoistureViewsSelector
} from '../../modules/soil-moisture/selectors';
import { IStationDataProfile } from '../../modules/station-data/models/station-data.models';
import { withLatestFrom } from 'rxjs/operators';

import {
  ANIMAL_PRODUCTION_TREE_NODES,
  CROP_VIEW_TREE_NODES,
  CROP_ZONE_SOIL_MOISTURE_TREE_NODES,
  CROPZONE_SETTINGS_TREE_NODES,
  IRRIMET_TREE_NODES,
  ISCOUT_TREE_NODES,
  STATION_SETTINGS_TREE_NODES,
  TRACKER_SETTINGS_TREE_NODES,
  TRACKER_TREE_NODES,
  CROP_ZONE_SATELLITE_TREE_NODES,
  USER_API_SERVICES_TREE_NODES,
  WEATHER_FORECAST_TREE_NODES,
  ACCUMULATOR_TOOL_TREE_NODES,
  SOIL_GUARD_TREE_NODES,
  WP_TROPICAL_SOWING_WINDOW_SERVICE,
  WP_GRASS_FIRE_DANGER_INDEX_SERVICE,
  WP_PLANT_NUTRITION_SERVICE,
  WP_FIELD_ACCESSIBILITY_SERVICE,
  WP_TILLAGE_ABILITY_SERVICE,
  WP_SOWING_WINDOW_SERVICE,
  WP_PLANT_PROTECTION_SERVICE,
  WP_HARVEST_WINDOW,
  CROP_ZONE_YIELD_PREDICTION_TREE_NODES,
  WEATHER_FORECAST_HISTORY_CLIMATE,
  WEATHER_FORECAST_DETAILS,
  WEATHER_FORECAST_METEOONE,
  WEATHER_FORECAST_METEOAGRO,
  WEATHER_FORECAST_PICTOPRINT,
  WEATHER_FORECAST_DAYS_FORECAAST,
  WEATHER_FORECAST_WEATHER_MAP, ISCOUT_TREE_NODES_PROGLU
} from './constants';
import { INavigationStationState } from '../../core/reducers/navigation-station';
import {
  IState,
  selectAvailableLicenses,
  selectDiseases,
  selectNavigationAccess,
  selectNavigationStation,
  selectSelectedCropZone,
  selectSelectedStation, selectStations
} from '../../core/reducers';
import { INavigationAccess } from '../../core/models/navigation-station';
import { IState as ISoilMoistureState } from '../../modules/soil-moisture/states';
import { IView } from '../../modules/soil-moisture/models';
import {
  SENSOR_GROUPS,
  SensorGroupType,
  ViewOperationType,
  ViewTemplateType,
  ViewType
} from '../../modules/soil-moisture/constants';
import { IStationDiseaseState } from '../../modules/station-disease/reducers/station-disease';
import { selectStationDiseaseLicenses } from '../../modules/station-disease/reducers';
import { IDiseaseLicenses } from '../../modules/station-disease/models/station-disease.models';
import { IDisease, IDiseaseModel } from '../../core/models/diseases.models';
import { ITreeSettingsState } from '../../shared/tree/models/tree.models';
import { INavigationCropzoneState } from '../../core/reducers/navigation-cropzone';
import * as fromSelectedCropzone from '../../core/reducers/selectedCropZone';
import * as fromSelectedStation from '../../core/reducers/selectedStation';
import * as fromLicenses from '../../core/reducers/licenses';
import { ApiCallService } from '../api/api-call.service';
import { Router } from '@angular/router';
import { IFrostProtectionState } from '../../modules/frost-protection/models/models';
import { selectFrostProtectionResponse } from '../../modules/frost-protection/selectors/selectors';
import { hideRainCorrectorForDevices } from '../../modules/correct-precipitation-data/constants/constants';
import { IThemeConfig } from '../../../environments/interfaces/theme';
import { environmentToken } from '../../../environments/environment';
import { IEnvironment } from '../../../environments/interfaces/environment';

@Injectable({
  providedIn: 'root'
})
export class TreeService {
  public treeItemSettingsClicked$   : Subject<string> = new Subject<string>();
  private treeStructure$            : BehaviorSubject<ITreeStructure> = new BehaviorSubject<ITreeStructure>(null);
  private subscriptions             : Array<Subscription> = [];
  private cropzone                  : ICropZone;
  private stationId                 : string = '';
  private stationName               : string = '';
  private yieldPredictionLicense    : boolean = false;
  private activeLicenses            : any;
  private deviceId                  : number = null;
  public subDomain                  : IThemeConfig;

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    private stationDataStore        : Store<IStationDataState>,
    private navigationStationStore  : Store<INavigationStationState>,
    private navigationCropzoneStore : Store<INavigationCropzoneState>,
    private soilMoistureStore       : Store<ISoilMoistureState>,
    private stationDiseaseStore     : Store<IStationDiseaseState>,
    private accountStore            : Store<IState>,
    private treeSettingsStore       : Store<ITreeSettingsState>,
    private selectedCropzoneStore   : Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private selectedStationStore    : Store<fromSelectedStation.ISelectedStationState>,
    private frostProtectionStore    : Store<IFrostProtectionState>,
    private licenseStore            : Store<fromLicenses.IProductLicenseState>,
    private api                     : ApiCallService,
    public router                   : Router,
  ) {
    this.subDomain = environment.theme;
  }

  public getStationDataTreeStructure(): BehaviorSubject<ITreeStructure> {
    this.stopExistingSubscriptions();
    this.initTreeStructureForStationData();
    return this.treeStructure$;
  }

  public getStationSettingsTreeStructure(): BehaviorSubject<ITreeStructure> {
    this.stopExistingSubscriptions();
    this.initStationSettingsTreeStructure();
    return this.treeStructure$;
  }

  public getCropzoneSettingsTreeStructure(license?): BehaviorSubject<ITreeStructure> {
    this.stopExistingSubscriptions();
    this.initCropzoneSettingsTreeStructure(license);
    return this.treeStructure$;
  }

  public getIrrimetTreeStructure(): BehaviorSubject<ITreeStructure> {
    this.stopExistingSubscriptions();
    this.initIrrimetTreeStruture();
    return this.treeStructure$;
  }

  public getCropZoneSoilMoistureTreeStructure(): BehaviorSubject<ITreeStructure> {
    this.stopExistingSubscriptions();
    this.initCropZoneSoilMoistureTreeStruture();
    return this.treeStructure$;
  }

  public getCropZoneYieldPredictionTreeStructure(): BehaviorSubject<ITreeStructure> {
    this.stopExistingSubscriptions();
    this.initCropZoneYieldPredictionTreeStruture();
    return this.treeStructure$;
  }

  public getCropZoneSatelliteTreeStructure(): BehaviorSubject<ITreeStructure> {
    this.stopExistingSubscriptions();
    this.initCropZoneSatelliteTreeStructure();
    return this.treeStructure$;
  }

  public getSoilGuardTreeStructure(): BehaviorSubject<ITreeStructure> {
    this.stopExistingSubscriptions();
    this.initSoilGuardSubscriptions();
    return this.treeStructure$;
  }

  public getWeatherForecastTreeStructure(): BehaviorSubject<ITreeStructure> {
    this.stopExistingSubscriptions();
    this.initWeatherForecastSubscriptions();
    return this.treeStructure$;
  }

  public getDiseaseTreeStructure(): BehaviorSubject<ITreeStructure> {
    this.stopExistingSubscriptions();
    this.initDiseaseSubscriptions();
    return this.treeStructure$;
  }

  public getWorkplanningServicesTreeStructure(): BehaviorSubject<ITreeStructure> {
    this.stopExistingSubscriptions();
    this.initWorkplanningServicesSubscriptions();
    return this.treeStructure$;
  }

  public getAnimalProductionTreeStructure(): BehaviorSubject<ITreeStructure> {
    this.stopExistingSubscriptions();
    this.initAnimalProductionSubscriptions();
    return this.treeStructure$;
  }

  public getAccumulatorToolTreeStructure(): BehaviorSubject<ITreeStructure> {
    this.stopExistingSubscriptions();
    this.initAccumulatorToolSubscriptions();
    return this.treeStructure$;
  }

  public getFrostProtectionTreeStructure(): BehaviorSubject<ITreeStructure> {
    this.stopExistingSubscriptions();
    this.initFrostProtectionSubscriptions();
    return this.treeStructure$;
  }

  public getHydroponicsTreeStructure(): BehaviorSubject<ITreeStructure> {
    this.stopExistingSubscriptions();
    this.initHydroponicsSubscriptions();
    return this.treeStructure$;
  }

  public getSoilMoistureTreeStructure(): BehaviorSubject<ITreeStructure> {
    this.stopExistingSubscriptions();
    this.initSoilMoistureSubscriptions();
    return this.treeStructure$;
  }

  public getCropViewTreeStructure(): BehaviorSubject<ITreeStructure> {
    this.stopExistingSubscriptions();
    this.initCropViewSubscriptions();
    return this.treeStructure$;
  }

  public getIScoutTreeStructure(): BehaviorSubject<ITreeStructure> {
    this.stopExistingSubscriptions();
    this.initIScoutSubscriptions();
    return this.treeStructure$;
  }

  public getTrackerTreeStructure(): BehaviorSubject<ITreeStructure> {
    this.stopExistingSubscriptions();
    this.initTrackerSubscriptions();
    return this.treeStructure$;
  }

  public getUserApiServicesTreeStructure(): BehaviorSubject<ITreeStructure> {
    this.stopExistingSubscriptions();
    this.initUserApiServicesSubscriptions();
    return this.treeStructure$;
  }

  private initSoilMoistureSubscriptions(): void {
    this.subscriptions = [
      combineLatest([
        this.soilMoistureStore.pipe(
          select(soilMoistureViewsSelector),
          filter(views => Array.isArray(views) && !!views.length)
        ),
        this.getSoilMoistureTopology()
      ]).pipe(
        withLatestFrom(
          this.treeSettingsStore.pipe(select(treeSettings)),
          this.navigationStationStore.pipe(select(selectNavigationStation))
        ),
        map(([[views, tree], settings, station]): [ITree[], ITreeProfile] => {
          const options: IOptions[] = views.map(view => {
            return {
              value: view._id || view.name,
              content: view.name,
              data: view
            };
          });
          return [
            tree,
            {
              options: options,
              selectedOption: options.find(option => {
                return station && settings[station.name.original] && option.value === settings[station.name.original].selectedProfile;
              }) || options[0]
            }
          ];
        }),
        map(([trees, profiles]): ITreeStructure => {
          return {
            type: TreeType.Sensors,
            contentType: TreeContentType.SoilMoisture,
            profiles: profiles,
            trees: trees
          };
        })
      ).subscribe((tree: ITreeStructure) => {
        tree.trees.forEach(t => {
          t.hasSettingsIcon = this.soilMoistureTreeItemHasSettings(t, tree.profiles.selectedOption.data);
          if (t.subNodes) {
            t.subNodes.forEach(s => {
              s.hasSettingsIcon = this.soilMoistureTreeItemHasSettings(s, tree.profiles.selectedOption.data);
            });
          }
        });
        this.treeStructure$.next(tree);
      })
    ];
  }

  private soilMoistureTreeItemHasSettings(tree: ITree | ISubNode, view: IView): boolean {
    if (view.template === ViewTemplateType.SOIL_MOISTURE_STACKED && view.type === ViewType.PREDEFINED || (
      view.content &&
      Array.isArray(view.content.aggregate) &&
      view.content.aggregate[0] &&
      view.content.aggregate[0].operation === ViewOperationType.PAW
    )) {
      return false;
    }
    return tree.nodes.some(node => {
      return SENSOR_GROUPS[SensorGroupType.VOLUMETRIC].includes(node.group) ||
        SENSOR_GROUPS[SensorGroupType.TENSIOMETRIC].includes(node.group);
    });
  }

  private getSoilMoistureTopology(): Observable<ITree[]> {
    return this.soilMoistureStore.pipe(
      select(SelectSoilMoistureTopology),
      filter((topology: Array<ITopologyItem>): boolean => Array.isArray(topology)),
      map((topology: Array<ITopologyItem>): Array<ITree> => this.convertTopologyToTree(topology))
    );
  }

  private initTreeStructureForStationData(): void {
    this.subscriptions = [
      combineLatest([this.getProfiles(), this.getTopology()]).pipe(
        map((result: Array<ITreeProfile | ITree[]>): ITreeStructure => {
          return {
            type: TreeType.Sensors,
            contentType: TreeContentType.StationData,
            profiles: <ITreeProfile>result[0],
            trees: <Array<ITree>>result[1]
          };
        })
      ).subscribe((tree: ITreeStructure): void => {
        this.treeStructure$.next(tree);
      })
    ];
  }

  private getProfiles(): Observable<ITreeProfile> {
    return this.stationDataStore.pipe(
      select(selectStationDataProfiles),
      filter((profiles: Array<IStationDataProfile>) => Array.isArray(profiles)),
      map((profiles: Array<IStationDataProfile>): ITreeProfile => {
        const options: IOptions[] = profiles.map((profile: IStationDataProfile) => {
          return {
            value: profile.name,
            content: profile.name
          };
        });
        return {
          options: options,
          selectedOption: options[0] || null
        };
      })
    );
  }

  private getTopology(): Observable<ITree[]> {
    return this.stationDataStore.pipe(
      select(selectStationDataTopology),
      filter((topology: Array<ITopologyItem>) => Array.isArray(topology)),
      map((topology: Array<ITopologyItem>): Array<ITree> => this.convertTopologyToTree(topology))
    );
  }

  private convertTopologyToTree(topology: Array<ITopologyItem>): Array<ITree> {
    return topology.map((topologyItem: ITopologyItem): ITree => {
      return {
        id: topologyItem.name,
        expanded: topologyItem.expanded,
        type: topologyItem.type,
        hasSettingsIcon: false,
        name: (topologyItem.name_custom && topologyItem.name_custom !== '') ? topologyItem.name_custom : topologyItem.name,
        nodes: this.transformTopologySensorsArray(topologyItem.sensors),
        subNodes: this.transformTopologyNodesArray(topologyItem.nodes),
      };
    });
  }

  private transformTopologySensorsArray(topologySensors: ITopologySensorData[]): INode[] {
    return topologySensors.map((topologySensor: ITopologySensorData): INode => this.transformTopologySensor(topologySensor));
  }

  private transformTopologySensor(topologySensor: ITopologySensorData): INode {
    return {
      groupId: topologySensor.groupId,
      group: topologySensor.sensor.group || null,
      name: topologySensor.sensor.name,
      ch: topologySensor.sensor.ch || null,
      color: topologySensor.sensor.color
    };
  }

  private transformTopologyNodesArray(topologyNodes: Array<ITopologyNode>): Array<ISubNode> {
    return topologyNodes.map((topologyNode: ITopologyNode): ISubNode => this.transformTopologyNode(topologyNode));
  }

  private transformTopologyNode(topologyNode: ITopologyNode): ISubNode {
    return {
      id: topologyNode.name,
      name: topologyNode.name_custom || topologyNode.name,
      nodes: this.transformTopologySensorsArray(topologyNode.sensors),
      hasSettingsIcon: false
    };
  }

  private initStationSettingsTreeStructure(): void {
    this.subscriptions = [
      this.navigationStationStore.pipe(
        select(selectNavigationAccess),
        filter((access: INavigationAccess): boolean => access.cropView !== null), // filter initial access
        map((access: INavigationAccess): ITreeStructure => {
          return {
            type: TreeType.Links,
            contentType: TreeContentType.StationSettings,
            trees: [
              {
                name: 'Device settings',
                nodes: this.getFilterNodesForStationSettings(access),
                hasSettingsIcon: false
              }
            ]
          };
        })
      ).subscribe((tree: ITreeStructure): void => {
        this.treeStructure$.next(tree);
      })
    ];
  }

  private initIrrimetTreeStruture(): void {
    const tree = {
      type: TreeType.Links,
      contentType: TreeContentType.Irrimet,
      trees: [
        {
          name: 'Water Balance',
          nodes: this.getFilterNodesForIrrimet(),
          hasSettingsIcon: false
        }
      ]
    };
    this.treeStructure$.next(tree);
  }

  private initCropZoneSoilMoistureTreeStruture(): void {
    this.treeStructure$.next({
      type: TreeType.Links,
      contentType: TreeContentType.CropZoneSoilMoisture,
      trees: [
        {
          name: 'Soil Moisture',
          nodes: CROP_ZONE_SOIL_MOISTURE_TREE_NODES,
          hasSettingsIcon: false
        }
      ]
    });
  }

  private initCropZoneYieldPredictionTreeStruture(): void {
    this.treeStructure$.next({
      type: TreeType.Links,
      contentType: TreeContentType.CropZoneYieldPrediction,
      trees: [
        {
          name: 'Yield Prediction',
          nodes: CROP_ZONE_YIELD_PREDICTION_TREE_NODES,
          hasSettingsIcon: false
        }
      ]
    });
  }

  private initCropZoneSatelliteTreeStructure(): void {
    this.treeStructure$.next({
      type: TreeType.Links,
      contentType: TreeContentType.CropZoneSatellite,
      trees: [
        {
          name: 'Satellite',
          nodes: CROP_ZONE_SATELLITE_TREE_NODES,
          hasSettingsIcon: false
        }
      ]
    });
  }

  private initCropzoneSettingsTreeStructure(license?): void {

    const tree = {
      type: TreeType.Links,
      contentType: TreeContentType.CropzoneSettings,
      trees: [
        {
          name: 'Cropzone settings',
          nodes: this.getFilterNodesForCropzoneSettings(license),
          hasSettingsIcon: false
        }
      ]
    };

    this.treeStructure$.next(tree);
  }

  private getFilterNodesForStationSettings(access: INavigationAccess): Array<INode> {
    let flags;

    this.selectedStationStore.pipe(
      select(selectNavigationStation),
    ).subscribe(station => {
      if (station) {
        this.deviceId = station.info.device_id;
        flags = station.flags;
      }
    });

    const source = access.tracker ? TRACKER_SETTINGS_TREE_NODES : STATION_SETTINGS_TREE_NODES;

    if (!access.tracker) {
      if (flags) {
        source.find(node => node.link === '/ultrasonic-distance-settings').name =
          flags.nmetosfwt ? 'Field Water Tube Settings' :
          flags.waterlevel ? 'Water Level Settings' :
          '';
      }
    }

    return source.filter((item: INode): boolean =>
      !(item.link === '/sensor-names/' && !access.settingsConfiguration.sensorsAndNodes) &&
      !(item.link === '/sms-warnings' && !access.settingsConfiguration.warnings) &&
      !(item.link === '/server-alerts' && !access.settingsConfiguration.serverAlerts) &&
      !(item.link === '/correct-precipitation-data' && hideRainCorrectorForDevices.includes(this.deviceId)) &&
      !(item.link === '/correct-precipitation-data' && access.ultrasonicDistanceSettings) &&
      !(item.link === '/ultrasonic-distance-settings' && !access.ultrasonicDistanceSettings)
    );
  }

  private getFilterNodesForCropzoneSettings(license?): Array<INode> {
    combineLatest([
      this.licenseStore.pipe(
        select(selectAvailableLicenses),
        filter((licenses) => !!licenses)
      ),
      this.selectedCropzoneStore.pipe(
        select(selectSelectedCropZone),
        filter((cropzone) => !!cropzone)
      )
    ]).subscribe(([licenses, cropzone]) => {
      this.cropzone = cropzone;
      if (cropzone.product_key) {
        licenses
            .filter(item => item.product_item.key === cropzone.product_key)
            .forEach(item => {
              this.yieldPredictionLicense = item.YieldPrediction;
            });
      }});

    if (license) {
      return CROPZONE_SETTINGS_TREE_NODES.filter((item: INode): boolean => {
        if (item.name === 'General') {
          return true;
        } else if (item.name === 'Irrimet') {
          return this.cropzone.boundary !== null && license.Irrimet;
        } else if (item.name === 'Soil moisture') {
          return this.cropzone.boundary !== null && license.SoilMoisture;
        } else if (item.name === 'Yield Prediction') {
          return this.cropzone?.boundary !== null && license.YieldPrediction;
        }
      });
    } else {
      return CROPZONE_SETTINGS_TREE_NODES.filter((item: INode): boolean => {
        if (item.name === 'General') {
          return true;
        } else if (item.name === 'Irrimet') {
          return false;
        } else if (item.name === 'Soil moisture') {
          return false;
        } else if (item.name === 'Yield Prediction') {
          return (false  || (this.cropzone?.boundary !== null && this.yieldPredictionLicense));
        }
      });
    }
  }

  private getFilterNodesForIrrimet(): Array<INode> {
    return IRRIMET_TREE_NODES.filter((item: INode): boolean => {
      return true;
    });
  }

  private initSoilGuardSubscriptions(): void {
    this.treeStructure$.next({
      type: TreeType.Links,
      contentType: TreeContentType.SoilGuard,
      trees: [
        {
          name: 'Sampling Device',
          hasSettingsIcon: false,
          nodes: SOIL_GUARD_TREE_NODES
        }
      ]
    });
  }

  private initWeatherForecastSubscriptions(): void {
    const weatherForecastTreeNodes = [
      WEATHER_FORECAST_DETAILS,
      WEATHER_FORECAST_METEOONE,
      WEATHER_FORECAST_METEOAGRO,
      WEATHER_FORECAST_PICTOPRINT,
      WEATHER_FORECAST_DAYS_FORECAAST,
      WEATHER_FORECAST_WEATHER_MAP,
      WEATHER_FORECAST_HISTORY_CLIMATE
    ];

    this.treeStructure$.next({
      type: TreeType.NestedLinks,
      contentType: TreeContentType.WeatherForecast,
      trees: [
        {
          name: 'Weather forecast',
          hasSettingsIcon: false,
          subNodes: weatherForecastTreeNodes
        }
      ]
    });
  }

  private initWorkplanningServicesSubscriptions(): void {
    this.navigationStationStore.pipe(
      select(selectNavigationAccess),
      first()
    ).subscribe((navigationAccess: INavigationAccess) => {
      const workPlanningTreeNodes = [
        WP_PLANT_NUTRITION_SERVICE,
        WP_FIELD_ACCESSIBILITY_SERVICE,
        WP_TILLAGE_ABILITY_SERVICE,
        WP_SOWING_WINDOW_SERVICE,
      ];

      if (navigationAccess.tropicalSowingWindow === true) {
        workPlanningTreeNodes.push(WP_TROPICAL_SOWING_WINDOW_SERVICE);
      }

      workPlanningTreeNodes.push(WP_PLANT_PROTECTION_SERVICE, WP_HARVEST_WINDOW);

      if (navigationAccess.grassFireDangerIndex === true) {
        workPlanningTreeNodes.push(WP_GRASS_FIRE_DANGER_INDEX_SERVICE);
      }

      this.treeStructure$.next({
        type: TreeType.NestedLinks,
        contentType: TreeContentType.WorkPlanning,
        trees: [
          {
            name: 'Work planning',
            hasSettingsIcon: false,
            subNodes: workPlanningTreeNodes
          }
        ]
      });
    });
  }

  private initAnimalProductionSubscriptions(): void {
    this.treeStructure$.next({
      type: TreeType.NestedLinks,
      contentType: TreeContentType.AnimalProduction,
      trees: [
        {
          name: 'Animal production',
          hasSettingsIcon: false,
          subNodes: ANIMAL_PRODUCTION_TREE_NODES
        }
      ]
    });
  }

  private initFrostProtectionSubscriptions(): void {
    this.subscriptions = [
      combineLatest([
        this.frostProtectionStore.pipe(
          select(selectFrostProtectionResponse),
          map((response: any) => {
            if (response.sensors.length > 0) {
              return response.sensors.map((sensor: any): INode => {
                return {
                  group: sensor.group || null,
                  name: sensor.name,
                  ch: sensor.ch || null,
                  color: sensor.color,
                  visible: sensor.visible || true
                };
              });
            }
          })
        ),
        this.selectedStationStore.pipe(select(selectNavigationStation))
      ]).subscribe(([nodes, station]) => {
        this.stationId = station.name.original;
        this.stationName = station.name.custom;

        this.treeStructure$.next({
          type: TreeType.Sensors,
          contentType: TreeContentType.FrostProtection,
          trees: [
            {
              name: this.stationName ? this.stationName : this.stationId,
              hasSettingsIcon: false,
              type: 'base',
              expanded: true,
              nodes: nodes
            }
          ]
        });
      })
    ];
  }

  private initAccumulatorToolSubscriptions(): void {
    this.subscriptions = [
      this.selectedStationStore.pipe(
        select(selectSelectedStation),
        switchMap((station: any) => {
          this.stationId = station.original_name;
          return this.api.getStationSensors(station.original_name);
        }),
        map((sensors: Array<any>) => sensors.filter((s: any) => s.code === 17667 || s.code === 17668)),
        map((sensors: Array<any>) => {
          if (sensors.length === 0) {
            return ACCUMULATOR_TOOL_TREE_NODES.filter((item: INode): boolean => {
              if (item.name !== 'Asparagus growth model') {
                if (this.router.url.split('?')[0].split('/').pop() === 'asparagus-growth-model') {
                  this.router.navigate([`/station/${this.stationId}/accumulator-tool/degree-days`]);
                }
                return true;
              }
            });
          } else {
            return ACCUMULATOR_TOOL_TREE_NODES;
          }
        })
      ).subscribe((nodes: Array<INode>) => {
        this.treeStructure$.next({
          type: TreeType.Links,
          contentType: TreeContentType.AccumulatorTool,
          trees: [
            {
              name: 'Accumulator Tool',
              hasSettingsIcon: false,
              nodes: nodes
            }
          ]
        });
      })
    ];
  }

  private initHydroponicsSubscriptions(): void {
    this.subscriptions = [
      this.selectedStationStore.pipe(
        select(selectSelectedStation),
        switchMap((station: any) => {
          this.stationId = station.original_name;
          return this.api.getStationSensors(station.original_name);
        }),
        map((sensors: Array<any>) => sensors.filter((s: any) =>
          s.code === 24608 && s.isActive === true ||		      // Drainage [mL]
          /* s.code === 32770 && s.isActive === true ||		    // Conductivity [mS/m]
           s.code === 32769 && s.isActive === true ||		      // PH [-]*/
          s.code === 19459 && s.isActive === true)		  		  // Scale high res.
        ),
        map((sensors: Array<any>) => {
          if (sensors.length > 0) {
            return sensors.map((sensor: any): INode => {
              return {
                group: sensor.group || null,
                name: sensor.name,
                ch: sensor.ch || null,
                color: sensor.color,
                visible: sensor.visible || true
              };
            });
          }
        })
      ).subscribe((nodes: Array<INode>) => {
        this.treeStructure$.next({
          type: TreeType.Sensors,
          contentType: TreeContentType.Hydroponics,
          trees: [
            {
              name: 'Hydroponics',
              hasSettingsIcon: false,
              type: 'base',
              expanded: true,
              nodes: nodes
            }
          ]
        });
      })
    ];
  }

  private initDiseaseSubscriptions(): void {
    this.subscriptions = [
      this.stationDiseaseStore.pipe(
        select(selectStationDiseaseLicenses),
        filter((licenses: IDiseaseLicenses): boolean => !!licenses && licenses.hasOwnProperty('models')),
        switchMap((licenses: IDiseaseLicenses) => this.accountStore.pipe(
          select(selectDiseases),
          filter((diseases) => !!diseases),
          map((diseases: Array<IDisease>) => this.filterAndTransformDiseases(diseases, licenses))
        ))
      ).subscribe((nodes: Array<ISubNode>): void => {
        this.treeStructure$.next({
          type: TreeType.NestedLinks,
          contentType: TreeContentType.Disease,
          trees: [
            {
              name: 'Disease models',
              hasSettingsIcon: false,
              subNodes: nodes
            }
          ]
        });
      })
    ];
  }

  private filterAndTransformDiseases(diseases: Array<IDisease>, licenses: IDiseaseLicenses): Array<ISubNode> {
    if (!licenses) {
      return [];
    }
    const licenseKeys = Object.keys(licenses.models);
    const filteredDiseases: Array<IDisease> = this.filterDiseasesByLicenseKeys(diseases, licenseKeys);
    return this.transformDiseases(filteredDiseases);
  }

  private filterDiseasesByLicenseKeys(diseases: Array<IDisease>, licenseKeys: Array<string>): Array<IDisease> {
    return diseases.filter((disease: IDisease): boolean => licenseKeys.includes(disease.group));
  }

  private transformDiseases(diseases: Array<IDisease>): Array<ISubNode> {
    return diseases.map((disease: IDisease): ISubNode => {
      return {
        name: disease.title,
        id: disease.group,
        nodes: this.transformDiseaseModels(disease.models),
        hasSettingsIcon: false
      };
    });
  }

  private transformDiseaseModels(diseaseModels: Array<IDiseaseModel>): Array<INode> {
    return diseaseModels.map((diseaseModel: IDiseaseModel): INode => {
      return {
        name: diseaseModel.name,
        id: diseaseModel.key
      };
    });
  }

  private initCropViewSubscriptions(): void {
    this.treeStructure$.next({
      type: TreeType.Links,
      contentType: TreeContentType.CropView,
      trees: [
        {
          name: 'Camera device',
          hasSettingsIcon: false,
          nodes: CROP_VIEW_TREE_NODES
        }
      ]
    });
  }

  private initIScoutSubscriptions(): void {
    this.treeStructure$.next({
      type: TreeType.Links,
      contentType: TreeContentType.IScout,
      trees: [
        {
          name: 'Camera device',
          hasSettingsIcon: false,
          nodes: this.subDomain.subDomain !== 'proglu' ? ISCOUT_TREE_NODES : ISCOUT_TREE_NODES_PROGLU
        }
      ]
    });
  }

  private initTrackerSubscriptions(): void {
    this.treeStructure$.next({
      type: TreeType.Cards,
      contentType: TreeContentType.Tracker,
      trees: [
        {
          name: 'Tracker',
          hasSettingsIcon: false,
          nodes: TRACKER_TREE_NODES
        }
      ]
    });
  }

  private initUserApiServicesSubscriptions(): void {
    this.treeStructure$.next({
      type: TreeType.Links,
      contentType: TreeContentType.UserApiServices,
      trees: [
        {
          name: 'User API services',
          hasSettingsIcon: false,
          nodes: USER_API_SERVICES_TREE_NODES
        }
      ]
    });
  }

  private stopExistingSubscriptions(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    // clear the tree immediately
    this.treeStructure$.next(null);
  }

}
