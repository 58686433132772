import { AgGridModule } from 'ag-grid-angular';
import { IrrimetRoutingModule } from './irrimet-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IrrimetComponent } from './irrimet.component';
import { IrrimetCalendarComponent } from './containers/irrimet-calendar/irrimet-calendar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { IrrimetCalendarHeaderComponent } from './containers/irrimet-calendar-header/irrimet-calendar-header.component';
import { IrrimetWaterBalanceComponent } from './containers/irrimet-water-balance/irrimet-water-balance.component';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { IrrimetWaterBalanceToolbarComponent } from './components/irrimet-water-balance-toolbar/irrimet-water-balance-toolbar.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './reducers';
import { WaterbalanceEffects } from './effects/irrimet.effect';
import { IrrimetCalendarHelpTextModalComponent } from './containers/irrimet-calendar-help-text-modal/irrimet-calendar-help-text-modal/irrimet-calendar-help-text-modal.component';
import { WaterBalanceHelpTextModalComponent } from './containers/irrimet-water-balance/water-balance-help-text-modal/water-balance-help-text-modal.component';
import { IrrimetWaterBalanceTableComponent } from './components/water-balance-table/irrimet-water-balance-table/irrimet-water-balance-table.component';
import { StationDataExportService } from '../../shared/services/export/station-data-export.service';
import { AvailableWaterPawHelpTextModalComponent } from './containers/irrimet-available-water-paw/available-water-paw-help-text-modal/available-water-paw-help-text-modal.component';
import { IrrimetAvailableWaterPawComponent } from './containers/irrimet-available-water-paw/irrimet-available-water-paw.component';
import { DayManualEventsPipePipe } from './pipes/manual-day-events-pipe/day-manual-events-pipe.pipe';
import { DayIrrigationEventsPipe, SplitDayIrrigationEventsPipe } from './pipes/irrigation-day-events.pipe/day-irrigation-events-pipe.pipe';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    IrrimetRoutingModule,
    TranslateModule.forChild(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    NgbModule,
    ChartModule,
    StoreModule.forFeature('irrimet', reducers),
    EffectsModule.forFeature([WaterbalanceEffects]),
    AgGridModule.withComponents([]),
  ],
  declarations: [
    IrrimetComponent,
    IrrimetCalendarComponent,
    IrrimetCalendarHeaderComponent,
    IrrimetWaterBalanceComponent,
    IrrimetAvailableWaterPawComponent,
    IrrimetWaterBalanceToolbarComponent,
    IrrimetCalendarHelpTextModalComponent,
    WaterBalanceHelpTextModalComponent,
    AvailableWaterPawHelpTextModalComponent,
    IrrimetWaterBalanceTableComponent,
    DayManualEventsPipePipe,
    DayIrrigationEventsPipe,
    SplitDayIrrigationEventsPipe
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting] },
    StationDataExportService
  ]
})
export class IrrimetModule {
}
