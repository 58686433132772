import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {
  DashboardActionTypes
} from '../actions/dashboard';
import {IColumn} from '../models/dashboard.models';

export const initialName = 'New Dashboard';
export interface IDashboard {
  id?: string;
  name: string;
  dashboard: Array<Array<IColumn>>;
  subdomain: string;
  siblings: Array<{id: string, title: string}>;
  isMain: boolean;
  isEdit: boolean;
  isChanged: boolean;
}

export const initialState: IDashboard = {
  name: 'Main Dashboard',
  dashboard: [],
  subdomain: 'default',
  siblings: [],
  isMain: true,
  isEdit: false,
  isChanged: false
};

export function reducer(
  state: IDashboard = initialState,
  action: IActionWithPayload
): IDashboard {
  switch (action.type) {
    case DashboardActionTypes.SET_DASHBOARD_SETTINGS: {
      return {
        ...state,
        dashboard: action.payload
      };
    }
    case DashboardActionTypes.SET_DASHBOARD_CHANGED: {
      return {
        ...state,
        isChanged: action.payload
      };
    }
    case DashboardActionTypes.SET_WIDGET_DATA: {
      const widget = state.dashboard[action.payload.path[0]]
        [action.payload.path[1]]
        .items
        [action.payload.path[2]];
      widget.loadData = action.payload.loadData;
      return {
        ...state,
        dashboard: state.dashboard
      };
    }
    case DashboardActionTypes.SET_WIDGET_SETTINGS: {
      state.dashboard[action.payload.path[0]]
        [action.payload.path[1]]
        .items
        [action.payload.path[2]]
        .requests[action.payload.type] = action.payload.updateSettings;
      return {
        ...state,
        dashboard: state.dashboard
      };
    }
    case DashboardActionTypes.SET_WIDGET_EDIT_MODE: {
      state.dashboard[action.payload.path[0]]
        [action.payload.path[1]]
        .items
        [action.payload.path[2]]
        .settings[action.payload.type] = action.payload.isEdit;
      return {
        ...state,
        dashboard: state.dashboard
      };
    }
    case DashboardActionTypes.ADD_WIDGET: {
      state.dashboard[action.payload.path[0]]
        [action.payload.path[1]]
        .items.unshift(action.payload.widget);
      return {
        ...state,
        dashboard: state.dashboard
      };
    }
    case DashboardActionTypes.UPDATE_COLUMN_WIDTH: {
      state.dashboard[action.payload.path[0]]
        [0].width = action.payload.width[0];
      state.dashboard[action.payload.path[0]]
        [1].width = action.payload.width[1];
      return {
        ...state,
        dashboard: state.dashboard
      };
    }
    case DashboardActionTypes.CHANGE_EDIT_MODE: {
      return {
        ...state,
        isEdit: !state.isEdit
      };
    }
    case DashboardActionTypes.ACTIVATE_EDIT_MODE: {
      return {
        ...state,
        isEdit: true
      };
    }
    case DashboardActionTypes.ADD_NEW_COLUMNS: {
      state.dashboard.unshift(action.payload);
      return {
        ...state,
        dashboard: state.dashboard
      };
    }
    case DashboardActionTypes.REMOVE_WIDGET: {
      state.dashboard[action.payload[0]]
        [action.payload[1]]
        .items.splice(action.payload[2], 1);
      return {
        ...state,
        dashboard: state.dashboard
      };
    }
    case DashboardActionTypes.REMOVE_COLUMN: {
      state.dashboard[action.payload[0]].splice(action.payload[1], 1);
      if (!state.dashboard[action.payload[0]].length) {
        state.dashboard.splice(action.payload[0], 1);
      } else {
        state.dashboard[action.payload[0]].forEach((c) => c.width = 6);
      }
      return {
        ...state,
        dashboard: state.dashboard
      };
    }
    case DashboardActionTypes.SET_WIDGET_LOADING: {
      state.dashboard[action.payload.path[0]]
        [action.payload.path[1]]
        .items
        [action.payload.path[2]]
        .isLoading = action.payload.loading;
      return {
        ...state,
        dashboard: state.dashboard
      };
    }
    case DashboardActionTypes.SET_WIDGET_ERROR: {
      state.dashboard[action.payload.path[0]]
        [action.payload.path[1]]
        .items
        [action.payload.path[2]]
        .error = action.payload.error;
      return {
        ...state,
        dashboard: state.dashboard
      };
    }
    case DashboardActionTypes.SET_DASHBOARD_NAME: {
      return {
        ...state,
        name: action.payload
      };
    }
    case DashboardActionTypes.SET_DASHBOARD_LIST: {
      return {
        ...state,
        siblings: action.payload
      };
    }
    case DashboardActionTypes.SET_DASHBOARD_META: {
      return {
        ...state,
        isMain: action.payload.main,
        id: action.payload.id
      };
    }
    case DashboardActionTypes.CHANGE_SUBDOMAIN: {

      return {
        ...state,
        subdomain: action.payload
      };
    }
    default:
      return state;
  }
}
