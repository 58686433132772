<ng-container>
  <div class="row ultrasonic-distance-settings-content">

    <div class="col col-md-6">
      <div class="row ultrasonic-distance-settings-content__left-column">
        <app-ultrasonic-distance-settings-current>
        </app-ultrasonic-distance-settings-current>
      </div>
      <div class="row ultrasonic-distance-settings-content__left-column">
        <app-ultrasonic-distance-settings-archived>
        </app-ultrasonic-distance-settings-archived>
      </div>
    </div>

    <div class="col col-md-6 ultrasonic-distance-settings-content__right-column">
      <app-ultrasonic-distance-settings-sketches>
      </app-ultrasonic-distance-settings-sketches>
    </div>

  </div>
</ng-container>





