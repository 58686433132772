import { IFirebaseConfig } from './interfaces/environment';
import { IEnvironmentConfig } from './interfaces/environment';

export const firebaseConfig: IFirebaseConfig = {
    apiKey: 'AIzaSyAt3ANJPjyAO12frUd4rhZiFmNx-3RIVOY',
    authDomain: 'fieldclimate-6a587.firebaseapp.com',
    databaseURL: 'https://fieldclimate-6a587.firebaseio.com',
    projectId: 'fieldclimate-6a587',
    storageBucket: 'fieldclimate-6a587.appspot.com',
    messagingSenderId: '287626092133',
    appId: '1:287626092133:web:432f10177280abb17db08c',
    measurementId: 'G-VNJC6BK2ZJ',
};

export const environmentConfig: IEnvironmentConfig = {
    oauthUrl: 'https://oauth.fieldclimate.com/token',  // dev url: 'https://oauthdev.fieldclimate.com/token',
    apiUrl: 'https://api.fieldclimate.com/v2',         // dev url: 'https://apidev.fieldclimate.com',
    socketUrl: 'https://sockets.fieldclimate.com',      // dev url: 'https://socketsdev.fieldclimate.com',
    myJohnDeereUrl: 'https://partnerapi.deere.com/platform/',
    clientId: 'FieldclimateNG',
    clientSecret: '618a5baf48287eecbdfc754e9c933a',
    gMapKey: 'AIzaSyCedBT0Gt_vC5mgamHHLqnuKfOpJj1ETvI',
    gMapTZKey: 'AIzaSyDdqivRhdU1vv0IJR0zOdk_sc-P4iAFyxY',
    gMapEndPoint: 'https://maps.googleapis.com/maps/api/timezone/',
};
