import { Component, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { IChartAndLegend } from '../../../../shared/interfaces';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';
import { IWorkPlanningSettingsState, IWorkPlanningState } from '../../models/work-planning.models';
import moment = require('moment');
import { select, Store } from '@ngrx/store';
import { selectWorkPlanningSettingsIsChartActive } from '../../reducers';

@Component({
  selector: 'app-work-planning-charts',
  templateUrl: './work-planning-charts.component.html',
  styleUrls: ['./work-planning-charts.component.scss']
})
export class WorkPlanningChartsComponent implements OnInit, OnChanges {

  @Input()
  public exportChartImg     : boolean;
  @Input()
  public chartsAndLegends$  : Observable<IChartAndLegend[]>;
  @Input()
  public isLoading          : boolean = true;
  @Input()
  public isError            : boolean = false;
  public isChartActive$     : Observable<boolean>;

  constructor(private exportService: StationDataExportService,
              private workPlanningSettingsStore: Store<IWorkPlanningSettingsState>) { }

  public ngOnInit(): void {
    this.isChartActive$ = this.workPlanningSettingsStore.pipe(
      select(selectWorkPlanningSettingsIsChartActive)
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.chartsAndLegends$ && this.exportChartImg) {
      const fileName = 'work_planning_charts_' + moment(new Date()).toISOString() + '.png';
      this.exportService.exportChartsToImage(fileName);
    }
  }

}
