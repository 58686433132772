<ng-container>
  <app-custom-image-gallery-toolbar
    [stationOptions]="iscoutStations"
    [toolbarSettings$]="toolbarSettings$"
    [stationMinDate$]="stationMinDate$"
    [stationMaxDate$]="stationMaxDate$"
    [currentDate$]="currentDate$"
    [extraOptions$]="iscoutGlueBoards$"
    [extraOptionScopes]="iscoutPeriodScopes"
    [extraOptionSelected]="iscoutGlueBoardSelection$ | async"
    [extraOptionsEnabled]="true"
    (refreshEmitter)="onRefresh($event)"
    (toolbarChangeEmitter)="onSaveToolbarSettings($event)"
    (extraOptionsLoaderEmitter)="onLoadGlueBoards($event)"
    (extraOptionsSelectedEmitter)="onSelectGlueBoard($event)"
  ></app-custom-image-gallery-toolbar>

  <h4 class="current-date-header">
    {{ formatCurrentDate(currentDate$ | async) }}
  </h4>
</ng-container>
