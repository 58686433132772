import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { ApiCallService } from '../../../services/api/api-call.service';
import { from, Observable, of } from 'rxjs';
import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { catchError, filter, map, mergeMap, retry, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { setNotify } from '../../../core/actions/notify';
import { currentUserOrganizationsPath, currentUserPath } from '../selectors/my-john-deere';
import { IState } from '../models/my-john-deere';
import { PAGE_SIZE } from '../constants/my-john-deere';
import { ISensor } from '../../../shared/interfaces';
import {
  ActionTypes,
  getAuthData,
  getCurrentUser,
  removeAsset,
  setAsset,
  setAssets,
  setAuthData,
  setCatalog,
  setCurrentUser,
  setCurrentUserOrganizations,
  setCurrentUserOrganizationsPage,
  setSensors,
  setStatus,
  updateAsset,
  updateAssetSensors
} from '../actions/my-john-deere';


@Injectable()
export class Effects {

  constructor(
    private api: ApiCallService,
    private actions$: Actions,
    private store: Store<IState>
  ) {
  }

  @Effect()
  public getAuthData$: Observable<IAction> = this.actions$.pipe(
    ofType(ActionTypes.GET_AUTH_DATA),
    switchMap(() => this.api.getMyJohnDeereAuthData().pipe(
      map(authData => setAuthData(authData)),
      catchError(() => of(setNotify('MyJohnDeere API currently unavailable.')))
    ))
  );

  @Effect()
  public deleteToken$: Observable<IAction> = this.actions$.pipe(
    ofType(ActionTypes.DELETE_TOKEN),
    switchMap(() => this.api.deleteMyJohnDeereToken().pipe(
      switchMap(() => from([
        setNotify('Your MyJohnDeere access token has been removed.'),
        getAuthData(),
      ])),
      catchError(() => [])
    ))
  );

  @Effect()
  public getCatalog$: Observable<IAction> = this.actions$.pipe(
    ofType(ActionTypes.GET_CATALOG),
    switchMap(() => this.api.getMyJohnDeereApiCatalog().pipe(
      switchMap(response => {
        if (response.httpcode === 200) {
          return from([
            setCatalog(response.payload),
            getCurrentUser()
          ]);
        } else if (response.httpcode === 401) {
          return [
            setNotify('Your MyJohnDeere access token has expired.')
          ];
        } else {
          throw response.httpcode;
        }
      }),
      retry(2),
      catchError(() => of(setNotify('MyJohnDeere API currently unavailable.')))
    ))
  );

  @Effect()
  public getCurrentUser$: Observable<IAction> = this.actions$.pipe(
    ofType(ActionTypes.GET_CURRENT_USER),
    withLatestFrom(this.store.pipe(select(currentUserPath))),
    filter(([action, path]: [IAction, string]) => !!path),
    switchMap(([action, path]: [IAction, string]) => this.api.getMyJohnDeereRessource(path).pipe(
      switchMap(response => {
        if (response.httpcode === 200) {
          return of(setCurrentUser(response.payload));
        } else {
          throw response.httpcode;
        }
      }),
      retry(2),
      catchError(() => of(setNotify('Failed fetching user from MyJohnDeere.')))
    ))
  );

  @Effect()
  public getCurrentUserOrganizationsPage$: Observable<IAction> = this.actions$.pipe(
    ofType(ActionTypes.GET_CURRENT_USER_ORGANIZATIONS_PAGE),
    withLatestFrom(this.store.pipe(select(currentUserOrganizationsPath))),
    filter(([action, path]: [IAction, string]) => !!path),
    switchMap(([action, path]: [IAction, string]) => {
      return this.api.getMyJohnDeereRessource(`${path};start=${action.payload * PAGE_SIZE};count=${PAGE_SIZE}`).pipe(
        switchMap(response => {
          if (response.httpcode === 200) {
            return of(setCurrentUserOrganizationsPage(response.payload, action.payload));
          } else {
            throw response.httpcode;
          }
        }),
        retry(2),
        catchError(() => of(setNotify('Failed fetching organizations from MyJohnDeere.')))
      );
    })
  );

  @Effect()
  public getCurrentUserOrganizations$: Observable<IAction> = this.actions$.pipe(
    ofType(ActionTypes.GET_CURRENT_USER_ORGANIZATIONS),
    filter((action: IAction) => !!action.payload),
    tap(() => this.store.dispatch(setStatus({
      currentUserOrganizations: { loading: true }
    }))),
    switchMap((action: IAction) => {
      return this.api.getMyJohnDeereRessource(action.payload).pipe(
        switchMap(response => {
          if (response.httpcode === 200) {
            return of(setCurrentUserOrganizations(response.payload));
          } else if (response.httpcode === 310) {
            return of(getAuthData());
          } else {
            throw response.httpcode;
          }
        }),
        retry(5),
        catchError(() => of(setNotify('Failed fetching organizations from MyJohnDeere.')))
      );
    }),
    tap(() => this.store.dispatch(setStatus({
      currentUserOrganizations: { loading: false }
    }))),
  );

  @Effect()
  public getAssets$: Observable<IAction> = this.actions$.pipe(
    ofType(ActionTypes.GET_ASSETS),
    switchMap((action: IAction) => this.api.getMyJohnDeereAssets(action.payload)
      .pipe(
        map((response) => setAssets(response)),
        catchError(() => of(setNotify('Failed fetching organizations from MyJohnDeere.')))
      )
    )
  );

  @Effect()
  public updateAssetSensors$: Observable<IAction> = this.actions$.pipe(
    ofType(ActionTypes.UPDATE_ASSET_SENSORS),
    mergeMap((action: IAction) => {
      return this.api.deleteMyJohnDeereAsset(action.payload.asset.asset).pipe(
        mergeMap((response) => {
          if (response.httpcode === 204) {
            return this.api.postMyJohnDeereAsset(action.payload.assetConfig).pipe(
              mergeMap((response2) => {
                if (response2.httpcode === 201) {
                  return from([
                    removeAsset(action.payload.asset),
                    setAsset(response2.payload),
                    setNotify('Sensors have been updated successfully')
                  ]);
                } else {
                  return from([]);
                }
              })
            );
          }
        })
      );
    })
    );

  @Effect()
  public postAsset$: Observable<IAction> = this.actions$.pipe(
    ofType(ActionTypes.POST_ASSET),
    mergeMap((action: IAction) => {
      this.store.dispatch(setAsset(action.payload.config));
      return of(action);
    }),
    mergeMap((action: IAction) => {
      return this.api.postMyJohnDeereAsset(action.payload).pipe(
        mergeMap((response) => {
          if (response.httpcode === 201) {
            return from([
              updateAsset(response.payload),
              setNotify('Added station to your MyJohnDeere organization.')
            ]);
          } else {
            let ret = [
              removeAsset(response.payload)
            ];
            if (response.httpcode && (response.httpcode === 401 || response.httpcode === 403)) {
              ret = [
                ...ret,
                setNotify('Adding station to your MyJohnDeere organization failed.'),
                setNotify('You seem not to have sufficient rights in your MyJohnDeere organization.')
              ];
            }
            return from(ret);
          }
        }),
        catchError(err => of(setNotify('Adding station to your MyJohnDeere organization failed.')))
      );
    })
  );

  @Effect()
  public deleteAsset$: Observable<IAction> = this.actions$.pipe(
    ofType(ActionTypes.DELETE_ASSET),
    mergeMap((action: IAction) => {
      return this.api.deleteMyJohnDeereAsset(action.payload.asset).pipe(
        mergeMap((response) => {
          if (response.httpcode === 204) {
            return from([
              removeAsset(action.payload),
              setNotify('Removed station from your MyJohnDeere organization.')
            ]);
          } else if (response.httpcode === 401 || response.httpcode === 403) {
            return from([
              setAsset(action.payload),
              setNotify('Removing station from your MyJohnDeere organization failed.'),
              setNotify('You seem not to have sufficient rights in your MyJohnDeere organization.')
            ]);
          } else {
            return from([
              setAsset(action.payload),
              setNotify('Removing station from your MyJohnDeere organization failed.')
            ]);
          }
        }),
        catchError(() => of(setNotify('Removing station from your MyJohnDeere organization failed.')))
      );
    })
  );

  @Effect()
  public getSensors$: Observable<IAction> = this.actions$.pipe(
    ofType(ActionTypes.GET_SENSORS),
    mergeMap((action: IAction) =>
      this.api.getStationSensors(action.payload).pipe(
        map((response: ISensor[]) => setSensors(action.payload, response)),
        catchError(() => of(setNotify('Failed fetching organizations from MyJohnDeere.')))
      )
    )
  );

  @Effect()
  public sendAuthCode$: Observable<IAction> = this.actions$.pipe(
    ofType(ActionTypes.SEND_AUTH_CODE),
    switchMap((action: IAction) =>
      this.api.saveMyJohnDeereAuthCode(action.payload).pipe(
        map(authData => setAuthData(authData)),
        catchError(() => of(setNotify('Failed fetching token from MyJohnDeere.')))
      )
    )
  );
}

