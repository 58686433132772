import {IOptions} from '../../../shared/interfaces';
import {IDiseaseDescription} from '../models/station-disease.models';

export const FIRE_BLIGHT = 'GeneralFireblight';

export const FireBlightSettings: IOptions[] = [
  {content: 'No Fire Blight in area past 2 seasons', value: '1'},
  {content: 'Fire Blight in local area 2 seasons ago', value: '2'},
  {content: 'Fire Blight in local area last season', value: '3'},
  {content: 'Fire Blight in or near your orchard', value: '4'},
  {content: 'Active cancers are now nearby', value: '5'}
];

export const DiseaseDescription: IDiseaseDescription = {
  'GeneralAphidRisk': 'The model describes the general risk of aphid propagation. ' +
  'The risk is displayed as a value between 0 (no risk) and 100% risk. Periods with ' +
  'low or no risk are time periods, where it is too cold or too dry for aphids to propagate. ' +
  'In contrast warm and humid periods favor propagation and are displayed as risky ' +
  'periods (100% risk). Long lasting leaf wetness periods could be beneficial, because ' +
  'they promote entomopathogenic fungal occurrence and therefore reduce aphid population.',
  'GeneralRainModel4mm': 'Rainy periods with more than 5 mm per hour are accumulated for ' +
  '3, 5, 7, 10 and 14 days. The accumulated rain amount per time interval is displayed. ' +
  'This information is useful to see how much rain has been falling since the last pesticide ' +
  'application/plant protection measurement.',
  'GeneralFireblight': 'Two different models are displaying risky periods for fireblight / ' +
  'the propagation of the fireblight bacterium. A) “Blossom Bight” indicates climatic situations ' +
  'where we have to expect Erwinia amylovora infections in the open flower through the ' +
  'nectarines if flowers are present. This might be flowers form the main flowering period ' +
  'but there could be also late flowers (especially in varieties with the tendency to late ' +
  'flowering). B) “Fireblight DIV” is indicating the propagation ability of the bacteria E. ' +
  'amylovora within a 4 day period. The disease history of the orchard is integrated into the ' +
  'model calculation by settings at the beginning and background colors of the graph will ' +
  'indicate the risk for the orchard.',
  'GeneralChillingPortions': 'There are various models used to calculate chilling. We accumulate ' +
  'chilling units to chill portions (CP), using a temperature range from 2 to 7°C. Calculations ' +
  'of chill portions end after 96 hours of equal or more then >15°C. Thresholds of required ' +
  'chilling portions depend on the fruit variety and environment and have to be known by the farmer.',
  'GeneralJBlossomInfection': 'The bacterial disease is favored by warm seasons with temperatures ' +
  'of 10-28°C and light frequently rainfalls with heavy winds and dews. Local dispersal is ' +
  'possible by rain splash in orchards. We show three different severity classes (weak, moderate and ' +
  'severe infections), which one has to be considered depends on the inoculum (last year epidemiology), ' +
  'susceptibility of the variety and the weather conditions. A value of 100% displays optimal ' +
  'conditions for the infection with the bacterial disease.',
  'GeneralMoniliniaAndStigminaCarpholiaRisk': 'The curves indicate at a value of 100% optimal conditions ' +
  'for a Monilia infection in orchards. The calculations are based on leaf wetness, temperature ' +
  'and relative humidity. Monilia laxa is well adapted to relatively low temperatures during spring ' +
  'and cause infections at temperatures as low as 5°C within a very short period of wetness duration. ' +
  '100% infection on the graph indicates optimal conditions for the fungus to enter the plant tissue ' +
  'and cause infections. Curative plant protection measurements have to be taken into account. ',
  'GeneralSphaerothecaPannosaRisk': 'Powdery mildew fungi generally require moist conditions to ' +
  'release overwintering spores and infect plant tissue. However, no moisture is needed for the fungus ' +
  'to establish itself and grow after infecting the plant. Powdery mildew favor warm, Mediterranean ' +
  'climates. Our model is based on leaf wetness durations and the temperature during this period. ' +
  'At a value of 60-100% a high risk of an infection could be indicated in the orchard and plant protection ' +
  'measurements should be considered.',
  'GeneralTaphrinaLeafCurl': 'The graph shows possible infection times, based on precipitation, leaf wetness ' +
  'and temperature during that time. The fungus Taphrina deformans develops after rainfall at relative ' +
  'cool temperatures below 19°C. After an incubation time of about 3 weeks (calculated by an accumulation ' +
  'of temperatures) the symptoms are seen on leaves. The graph indicates at 100% infection optimal conditions ' +
  'for the fungus to infect the plant and shows the considered incubation period, where symptoms ' +
  'should be seen at 100% incubation.',
  'GeneralCladosporiumCarpophilumRisk': 'The risk of a Caldosporium carpophilum infection is determined ' +
  'by wet conditions during spring and early summer after petal fall. The disease is usually more serious ' +
  'in low- lying, shady and moist areas with low air movement. Infection curves increase during leaf wetness ' +
  'periods within a temperature range of 7 to 24°C and at a risk of 100% optimal conditions for the ' +
  'fungus to enter the plant tissue have been given. Curative plant protection measurements should be considered ' +
  'after 100% risk.',
  'GeneralStemphyliumVesicarium': 'Favorable conditions for a Stemphylium vesicarium infection are temperatures ' +
  'of 11 to 30°C, leaf wetness or relative humidity more than 90%. Infection curves for weak, moderate ' +
  'and severe infections are computed. Depending on the disease history and disease pressure of the location ' +
  'a treatment has to be taken into consideration wheatear by an weak, moderate or heavy infection. S. ' +
  'versicarium infections exists often together with Alternaria porri infections. Therefore, the Purple blotch ' +
  'TomCast (Alternaria) model is quite frequent used for both diseases.',
  'GeneralJFruitAndLeafInfection': 'Fruit and leaf infections by Xanthomonas arboricola are determined by temperatures ' +
  'between 15°C and 30°C, precipitation and leaf wetness as well as daytime. The model separates between ' +
  'weak, moderate and severe infections in dependence of the amount of rain (precipitation sum of more ' +
  'than 2 mm for a weak and precipitation sum of more than 5 mm for a severe infection). Whenever 100% infection ' +
  'are reached optimal conditions for the bacteria to infect plant tissue have been given and plant protection ' +
  'measurements should be taken into account.',
  'GeneralJPropagationIndex': 'Xanthomonas arboricola is an aerobic, gram-negative bacterium; propagation ' +
  'index indicates optimal conditions for the bacterium’s dispersal and development. The bacterium overwinters ' +
  'in the intercellular spaces of the cortex, phloem and xylem parenchyma as well as in buds and fallen ' +
  'leaves. In spring the bacteria starts to multiply and cause the epidermis to rupture. Lesions are visible ' +
  'and inoculum from this cankers are disseminating by rain and wind. For determination of the propagation ' +
  'index temperatures between 15°C and 30°C, leaf wetness or relative humidity higher than 80% are taken ' +
  'into account.',
  'GeneralMoniliniaRisk': 'The curves indicate at a value of 100% optimal conditions for a Monilia infection ' +
  'in orchards. The calculations are based on leaf wetness, temperature and relative humidity. Monilia laxa is ' +
  'well adapted to relatively low temperatures during spring and cause infections at temperatures as low as ' +
  '5°C within a very short period of wetness duration. 100% infection on the graph indicates optimal conditions ' +
  'for the fungus to enter the plant tissue and cause infections. Curative plant protection measurements have ' +
  'to be taken into account.',
  'Apple/Scab/AscosporeRelease': 'The progress of possible ascospore infections is indicated in graphs for weak, ' +
  'moderate and severe infections form 0 to 100% It is common to take an infection which reaches 95 to 100% ' +
  'to be completed. If you have to react on weak infections is depending on the history of the orchard and ' +
  'the efficacy of the available fungicides. The graph on ascospore formation and ascopsore release is showing ' +
  'the proportional available inoculate for a given infection period. This is not an absolute value. The model ' +
  'does not know the absolute number of potential ascospores in the orchard.',
  'Apple/Scab/AscosporeInfection': 'Possible ascospore infections of apple scab are indicated in graphs for weak, ' +
  'moderate and severe infections, ranging form 0 to 100%. It is common to take an infection, which reaches ' +
  '95 to 100% to be completed. The history of the orchard and the efficacy of available fungicides determine ' +
  'if the farmer has to react on weak infections or wait till moderate/severe infections are predicted. The ' +
  'graph of ascospore formation (asco mature) and ascopsore release (asco free) shows the proportional available ' +
  'inoculate for a given infection period. This is not an absolute value. The model does not know the absolute ' +
  'number of potential ascospores in the orchard.',
  'Apple/Scab/ConidiaInfection': 'The development of possible conidia infections is indicated in graphs for weak, ' +
  'moderate and severe infections, ranging from 0 to 100%. It is common to take an infection which reaches ' +
  '95 to 100% to be completed. In dependence of the disease history of the orchard and the efficacy of available ' +
  'fungicides farmers have to react on weak infections or could wait till moderate/severe infections have ' +
  'been predicted.',
  'Apple/CodlingMoth': 'Insect development (larval stages and adult) is calculated by the proportional accumulation ' +
  'of daily temperatures between 10°C and 32°C. Specific thresholds for each developmental stage are involved ' +
  'in modelling the first appearance and duration of the larvae instar 1 to 5 and the presence of adults ' +
  'in 5 different activity levels.',
  'GeneralGreyMould': 'The Graph presents the risk for damages caused by Botrytis cinerea on base of temperature ' +
  'and leaf wetness. Warm periods with extended leaf wetness periods will lead to increased B. cinerea risk. ' +
  'While dry periods are leading to a reduced risk.',
  'GeneralRainModel2mm': 'Rainy periods with more than 2 mm per hour are accumulated for 3, 5, 7, 10 and 14 days. ' +
  'This accumulated rain period is displayed in the graph and could be used to see how much rain has been ' +
  'fallen since the last pesticide application. The leaf growth graph is indicating how many leaves are expected ' +
  'to grow within a week. This information support future fungicide applications too.',
  'Viticulture/Anthracnose': 'The possible development of a weak, moderate or severe infection of Anthracnose ' +
  'Elsinoë ampelina are displayed in separate graphs. An infection has to be assumed to be completed when the ' +
  'increasing curve reaches 100%. A separate graph shows the sporulation of E. ampelina and if the value ' +
  'there reaches 100% it is assumed that spores of E. ampelina are present.',
  'Viticulture/LeafGrowthPerWeek': 'Rainy periods with more than 2 mm per hour are accumulated for 3, 5, 7, ' +
  '10 and 14 days. This accumulated rain period is displayed in the graph and could be used to see how much ' +
  'rain has been fallen since the last pesticide application. The leaf growth graph is indicating how many leaves ' +
  'are expected to grow within a week. This information support future fungicide applications too.',
  'Viticulture/Blackrot': 'The possible development of an infection by the fungus of Blackrot Guignardia bidwellii ' +
  'are displayed in three severity classes (weak, middle, severe) in separate graphs. At warm temperatures ' +
  'leaf wetness periods of 8 hours are enough to favor infections. An infection is assumed to be completed ' +
  'when it reaches 100%.',
  'Viticulture/GrapeVine/BerryMoth': 'The first graph shows flight activity periods with egg laying and good egg ' +
  'laying activities for the insect. The second graph shows which generation has to be expected in the period ' +
  'of time. And in the third graph climatic data are displayed which show the accumulation of degree days ' +
  '(temperatures > 8°C up to 24°C per hour divided by 24).',
  'Viticulture/GrapeVine/DownyMildew/PrimaryCycle': 'The lower graph shows the infection progress for a weak, ' +
  'moderate or severe infection. An infection has to be assumed when the increasing curve in the graph reach 100%. ' +
  'Above the graph show the incubation time after an infection has been completed. In general weak infections ' +
  'occurr more frequent then moderate and severe infections.',
  'Viticulture/GrapeVine/DownyMildew/SecondaryCycle': 'The lower graph is showing the infection progress for a ' +
  'weak, moderate or severe infection. An infection has to be assumed when the increasing curve in the graph reach ' +
  '100%. Above the progress curve for the different incubation periods are shown. In general weak infections ' +
  'occurr more frequent then moderate and severe infections.',
  'Viticulture/PowderyMildew/AscosporeInfection': 'Possible Ascspore Infections following the models proposed ' +
  'by David Gadoury and Doug Gubler are displayed in the graph for ascospore infection. If the progress curve ' +
  'for the infection model reaches 100% there is the possibility of an ascospore infection. 3 to 5 days after ' +
  'this event first symptoms of powdery mildew might be visible on leaves close to the old cordon. The powdery ' +
  'mildew risk following the California Model and the model for moderate climate zones is displayed in the ' +
  'graph above. Periods with risk have to be covered with fungicide applicaitons. In periods with risk higher ' +
  'than 60 points it is recommended to spray more frequent specially arround and short after bloom.',
  'Viticulture/PowderyMildew/Risk': 'Three slightly different models are displayed. A) One is the infections by ' +
  'ascospores. The models have been developed by David Gadoury and Doug Gubler. If the progress curve of the ' +
  'infection model reaches 100% optimal conditions for an ascospore infection in the environment have been given. ' +
  'Three to 5 days after this event first symptoms of powdery mildew might be visible on leaves close to the ' +
  'old cordon. B) The powdery mildew risk following the California Model and the c) model for moderate climate ' +
  'zones are also displayed in the graph above. Periods with a risk have to be covered with fungicide applications. ' +
  'In periods with a risk higher than 60 points it is recommended to spray more frequent especially ' +
  'around the time and short after bloom.',
  'Viticulture/PowderyMildew/CaliforniaRisk': 'Possible Ascspore Infections following the models proposed by ' +
  'David Gadoury and Doug Gubler are displayed in the graph for ascospore infection. If the progress curve for ' +
  'the infection model reaches 100% there is the possibility of an ascospore infection. 3 to 5 days after ' +
  'this event first symptoms of powdery mildew might be visible on leaves close to the old cordon. The powdery ' +
  'mildew risk following the California Model and the model for moderate climate zones is displayed in the ' +
  'graph above. Periods with risk have to be covered with fungicide applicaitons. In periods with risk higher ' +
  'than 60 points it is recommended to spray more frequent specially arround and short after bloom.',
  'GeneralShotHoleInfection': 'Favorable infection periods by the disease “Shot hole” are calculated on base ' +
  'of precipitation, leaf wetness, relative humidity and temperature. Three different infection severities are ' +
  'calculated. Weak infections could already start at high relative humidities and warm temperatures. Moderate ' +
  'and severe infections are calculated at a rain event and increase with long leaf wetness periods and warm ' +
  'temperatures. 100% infection on the scale indicate optimal conditions for the fungus to infect the plant on ' +
  'the field. In dependence of the experience, history of orchard and fruit variety plant protection measurements ' +
  'have to be taken into account at light, moderate or severe infection.',
  'Avocado/Colletotrichum': 'The fungal pathogen overwinters in twigs and flower buds, spurs and trusses. In spring ' +
  'the fungus produce spores, which are dispersed by rain, irrigation and water- splashes. Infections occur ' +
  'at a temperature optimum of 12- 27°C and a leaf wetness period of more than 12 hours. The fruit can be infected ' +
  'at any time, although infections are just visible (symptoms seen) when the fruit is mature. In FieldClimate.com ' +
  'we determine optimal conditions for weak, moderate and severe infections based on the parameters ' +
  'temperature (10°C to 35°C), leaf wetness and precipitation (5mm for moderate/10mm for severe infections). ' +
  'Whenever the progress curve reach 100% optimal conditions for a weak/moderate or severe infection have ' +
  'been fulfilled. In dependance of the variety of the plant and location the farmer has to decide if he/she ' +
  'has to take curative plant protection measurements on a weak, moderate or severe infection into account.',
  'Banana/SigatokaInfection': 'The model includes following fungal stages: 1) Conidia Formation: based on relative ' +
  'humidity (higher than 70%) and air temperature(optimum of 27°C to 30°C). 2) Ascospore Formation: based ' +
  'on relative humidity(higher than 70%) and temperatures (optimum of 27°C to 30°C). The formation of ascospores ' +
  'takes a little bit longer than of conidia. 3) Ascospore Discharge: based on a rain event. The majority of spores ' +
  'is released at the beginning of precipitation. 4)Ascospore Infection: based on leaf wetness periods or relative ' +
  'humidity higher than 90%. Whenever 100% infection curve have been reached optimal conditions for each ' +
  'fungal stage have been given in the field and plant protection measurements should be taken into account.',
  'Banana/SprayEvaluation': 'We use two events to evaluate spray applications: Precipitation and Leaf Formation. ' +
  'Precipitation will be accumulated during defined time periods. We accumulate rain (more than 3mm) for 3, 5 and ' +
  '7 days. The accumulated rainsum is shown in the graph as well as the building of new leaves. Leaf formation ' +
  'is determined on the day-night temperatures (12-14°C and 12-13°C it takes 23, 16, or 21 days; 15-17°C and 14-15°C ' +
  'it takes 20, 14, or 10 days; 18-20°C and 16-22°C it takes <15, <12, or <8 days; 21-27°C and 16-22°C it ' +
  'takes <13, <11, or <6 days; 28-30°C and 23-24°C it takes <14, <12, or <8 days; 31-40°C and 25-40°C it takes 20, ' +
  '14, or 10 days). The days needed for leaf formation are displayed as lines in a graph. These information should ' +
  'help to evaluate unprotected tissue. Reasons therefore could be a high amount of rain since the last application ' +
  'of plant protection measurements; residue are already washed off or new leaves are formed.',
  'Banana/RiskByEto': 'We show two risk models: A) Sigatoka risk by ET0 is based on the determination of the evapotranspiration ' +
  '(temperature, rel. humidity, wind speed and global radiation). FieldClimate uses the Penman Monteith ' +
  'equation (is the most general and widely used equation for calculation reference ET0 instead of ETpiche). ' +
  'If the accumulated evapotranspiration of the last 7 days is: > 40 mm there is No Risk; > 30 mm Low Risk; > 22 mm ' +
  'Average Risk and < 22 mm High Risk (we assume that on this low ET0 it is raining and very wet, the sky is cloudy) ' +
  'B) The Sigatoka Risk by Infection uses the results of the infection model to prove for infections and the ' +
  'range of precipitation during this infection to assess the importance of these infection events. The amount of rain ' +
  'during an infection is responsible for the distribution of the spore and correlates with the spread of the disease. ' +
  'An infection without rain has the disease severity value of 1. An infection with less than 2, 5 or 10 mm ' +
  'of rain has the severity value of 2, 3 or 4. More than 10mm of rain have the severity value of 5. The risk indication ' +
  'is done by the accumulation of these severity values for the last 4 days: 0 is No risk; less than 4 is a Low ' +
  'risk; 4 to 12 is a Moderate risk and if we accumulate more than 12 the risk is High.',
  'Citrus/PostBloomFruit': 'The PFD Model in FieldClimate predicts the percentage of flowers, which will be diseased ' +
  'within the next 4 days on base of 133 infected flowers on 20 trees (amount of inoculum). A fungicide application ' +
  'is recommended, if these three criteria are met: A) The model predicts a disease incidence of greater than 20%; B) ' +
  'Sufficient bloom is present or developing to represent a significant portion of the total crop; and C) No fungicide ' +
  'application has been made in the last 10-14 day.',
  'Citrus/Alternaria': 'The disease occurrence is mainly influenced by wet conditions. Days with leaf wetness of more ' +
  'than 10 hours /day are increasing disease severity. We use the model AlterRater: The AlterRater values for every ' +
  'day are accumulated over 5, 7, 10 and 14 days and these results are displayed. Three thresholds are recommended ' +
  'for spray application: 1) a value of 50 for heavily infested orchards, 2) a value of 100 for moderate infested orchards ' +
  'and 3) a value of 150 for weak infested orchards.',
  'Cherry/LeafSpot': 'The graph “Ascospore discharge” shows time periods, when conditions for discharging overwintering ' +
  'spores are optimal (value 1). Ascospores are carried by wind and water to fresh leaves, where they serve as primary ' +
  'inoculum. The ascospore stick on the leave and if optimal conditions (temperature, leaf wetness, precipitation) ' +
  'are given the infection of leaves takes place. The graph “leaf spot infection” shows possible infection times ' +
  'and at a value of 100% optimal conditions for infections (fungus enter the plant tissue) have been given in the field.',
  'Cherry/MoniliaLaxa': 'The graph indicates optimal conditions for Monilia infections in orchards. The calculations ' +
  'are based on leaf wetness, temperature and relative humidity. Monilia laxa is well adapted to relatively low temperatures ' +
  'during spring and cause infections at temperatures as low as 5°C within a very short period of wetness ' +
  'duration. 100% infection on the graph indicate optimal conditions for the fungus to enter the plant tissue and cause ' +
  'infections. Curative plant protection measurements should be considered. For decision support two severity classes ' +
  '(infection and severe infection) in dependence of the fruit variety, history of orchard and experience of farmers ' +
  'are shown.',
  'Cherry/FrankliniellaOccidentalis': 'The graph shows a risk model for the Western Flower thrips Frankliniella occidentalis ' +
  'based on daily temperatures and relative humidity. If temperatures are higher than 18°C and lower than 32°C ' +
  'and relative humidity is higher than 70% the risk for the occurrence of the thrips increases. If the temperature ' +
  'is higher than 32°C and/or relative humidity is lower than 70% the risk decreases again.',
  'Cherry/PseudomonasSyringae': 'The risk model of the bacterial cancer Pseudomonas syringae is based on daily values. ' +
  'This model includes leaf wetness, relative humidity as well as soil water tension. Wet conditions (water tension ' +
  'smaller than 25, precipitation more than 2 mm and leaf wetness) increase the risk for the infection. While dry ' +
  'conditions (water tension higher than, no leaf wetness and low relative humidity decrease the risk for infection ' +
  'again. The graph shows possible infections times and at a value of 100% optimal conditions have been given.',
  'CarrotAndBeetroot/AlernariaDauciiAndAlternataInfection': 'The pathogen overwinters in crop refuse or on alternate ' +
  'hosts. The disease also may be seed borne. Older leaves are more susceptible to Alternaria spp. than younger leaves. ' +
  'Leaf spots are common in carrots grown for seed production. Infection of Alternaria daucii is determined based ' +
  'on leaf wetness and an air temperature between 7°C and 25 °C. Whenever the infection curve reaches 100% optimal conditions ' +
  'for an infection have been given and plant protection measurements should be taken into account. Further ' +
  'on, we calculate a so called “DIV” (Daily infection value): The DIV model is looking for the hours with relative humidity ' +
  'higher than 85% or with leaf wetness. On base of the number of hours and the average temperature during this ' +
  'period, it points out a DIV for this day. Depending on the susceptibility of the variety an accumulated DIV value ' +
  'over two consecutive days of 6 or more will indicate the need of the first spray. If we have more resistant varieties ' +
  'we can wait for higher accumulated DIV values on consecutive days.',
  'CarrotAndBeetroot/Cercospora': 'Because of the strict environmental conditions needed for an infection the disease is ' +
  'predictable within a time period, when outbreaks are mostly favorable. The prediction system is an estimate of the potential ' +
  'for disease development based on the relative humidity and air temperature. This system assumes that a susceptible ' +
  'host and sufficient inoculum are present. Based on hours of leaf wetness or high relative humidity (> 90 %) and ' +
  'temperature during this period, a daily infection value (DIV) is determined. If the two-day sum of the DIVs is 7 or ' +
  'greater, there is a strong potential for infection and further disease development. If the sum is less than 6, there ' +
  'is little likelihood of infection. If no symptoms were observed on leaves, then the DIV sum indicates that careful scouting ' +
  'is advised, and if symptoms were present, then a fungicide application would be warranted.',
  'Coffee/BerryDisease': 'Flowers are susceptible at all developmental stages. Immature fruit or berries are most susceptible ' +
  'during their expansion phase which occurs from 4-14 weeks after flowering. Earlier berry stages or pin head berries ' +
  'and the mature green beans are fairly resistant, becoming susceptible again as the berry begins to ripen. The pathogen ' +
  'sporulates readily and is evidend by a pale pink crust on the lesion surface. When the berry ripens and anthracnose ' +
  'fully develops, the bean can become infected and seed borne. Spores germinate to produce germ tubes and appressoria in ' +
  'free water from which infection pegs emerge to penetrate the surface cuticle. Optimum temperature is form 22°C to 27°C. ' +
  'In FieldClimate we show the development of spores (graph 2), when reaching 100% the infection could start (graph 1). When ' +
  '100% infection is reached, optimal conditions for the fungus have been in the field to infect the plant and plant protection ' +
  'measurements should be taken into account.',
  'Coffee/LeafRust': 'Uredospores need free water to germinate and form an appressoria. Germination takes place within 2hours ' +
  'to 10 hours depending on climatic conditions. Appressoria formation needs less than 6 hours under optimum conditions. ' +
  'At least after 22h to 24 hours the infection process has finished. Optimal conditions for infections are leaf wetness, darkness, ' +
  'temperatures between 15°C and 28°C. In FieldClimate we show the progress of weak, moderate and severe Infections. ' +
  'In dependence of the variety, history of the location the farmer has to decide to consider plant protection measurements whenever ' +
  '100% of a weak, moderate or severe infection are reached.',
  'Coffee/DiseaseSeverity': 'The Severity of the three coffee diseases is evaluated based on the rain intensity during the leaf ' +
  'wetness period leading to infections. More than 100 mm of rain: Severity =5; more than 55 mm of rain: Severity = 4; more ' +
  'than 22 mm of rain: Severity = 3; no rain: Severity = 2; no infection: Severity = 0.',
  'Coffee/PesticideWashOff': 'Rainy periods with more than 5 mm rain per hour are accumulated for 3, 5, 7, 10 and 14 days. The ' +
  'accumulated rain amount per time interval is displayed. This information is useful to see how much rain has been falling ' +
  'since the last pesticide application/plant protection.',
  'ApricotPlum/TaphrinaPrunii': 'Cold (below 16°C) and wet weather conditions promote the germination of fungal spores, while ' +
  'warm and dry weather conditions result in low infections. We model the risk of a Taphrina pruni infection in dependence of ' +
  'the actual temperature, precipitation, leaf wetness and relative humidity. Three severity classes, based on the rain amount, ' +
  'are shown. A value of 100% indicates optimal environmental conditions for fungal infection and plant protection measurements ' +
  'have to be taken into account. Which severity class (weak, moderate, severe) should be considered depends also on the ' +
  'inoculum, epidemiology, fruit variety.',
  'ApricotPlum/Scab/CladosporiumCarpophilum': 'The risk of a Caldosporium carpophilum infection is determined by wet conditions ' +
  'during spring and early summer after petal fall. The disease is usually more serious in low- lying, shady and moist areas with ' +
  'low air movement. Infection curves increase during leaf wetness periods within a temperature range of 7 to 24°C and at a ' +
  'risk of 100% optimal conditions for the fungus to enter the plant tissue have been given. Curative plant protection measurements ' +
  'should be considered after 100% risk.',
  'ApricotPlum/MoniliaLaxa': 'The graph indicates optimal conditions for Monilia infections in orchards. The calculations are ' +
  'based on leaf wetness, temperature and relative humidity. Monilia laxa is well adapted to relatively low temperatures during ' +
  'spring and cause infections at temperatures as low as 5°C within a very short period of wetness duration. 100% infection on the ' +
  'graph indicate optimal conditions for the fungus to enter the plant tissue and cause infections. Curative plant protection ' +
  'measurements should be considered. For decision support two severity classes (infection and severe infection) in dependence ' +
  'of the fruit variety, history of orchard and experience of farmers are shown.',
  'ApricotPlum/TaphrinaDeformans': 'The graph shows possible infection times, based on precipitation, leaf wetness and temperature ' +
  'during wet conditions. The fungus Taphrina deformans develops after rainfall at relative cool temperatures below 19°C. After ' +
  'incubation time of about 3 weeks (calculated by temperature accumulation) the symptoms are seen on leaves. The infection curve ' +
  'indicates at 100% optimal conditions for the fungus to infect the plant and afterwards the incubation curve increase and at ' +
  'a value of 100% first symptoms could be find on the plant.',
  'Asparagus/Puccina': 'Asparagus rust Puccinia asparagi is favored by moist and warm climate. Small amounts of rain and wind will ' +
  'distribute the urediaspores, whereas heavy rains can wash them down to soil. By this reason, a rain event with more than 10 ' +
  'mm is not taken into account for an infection periods. Leaf wetness periods coming from light rains and dews from 3 hours to ' +
  '8 hours with temperatures from 10°C to 25°C can be infectious in summer and late summer. In FieldClimate we show the progress ' +
  'of the infection curve in dependence of the leaf wetness time and the temperature. Whenever 100% infection is reached optimal ' +
  'conditions have been given in the field for the fungus to infect the plant tissue and plant protection measurements should be ' +
  'taken into account (at 60 to 80% infection).',
  'Pear/StemphyliumVesicarium': 'The graph shows the infection progress lines for weak, moderate and severe infections of the brown ' +
  'spot fungus Stemphylium versicarium. Moist periods (leaf wetness) are needed for infection and temperature during this wet ' +
  'periods define the speed of infection. The curves showing the progress of light, moderate and severe infections can be used as ' +
  'action thresholds for orchards with a differing disease history. In orchards with more than 1% disease incidence on fruit chemical ' +
  'control of the disease should be done on all light infections. In orchards with less than 1% of disease incidence on fruits ' +
  'chemical control should be done on all completed moderate infections. And in orchards which had up to now no occurrence of ' +
  'Stemphylium control methods should be started if severe infections will reach 100%.',
  'Pear/VenturiaPirina': 'The graph shows possible infection times based on leaf wetness and temperatures. The minimum wetness duration ' +
  'for foliage infection by conidia fell between the values required for light and moderate infection of apple by Venuria ' +
  'inequalis. When the curve increase to 100% optimal conditions for the fungus to enter the plant tissue and cause infections have ' +
  'been given in the orchard.',
  'Pear/FabreaLeafSpot': 'The graph shows possible infection times based on wet conditions (precipitation, leaf wetness) and ' +
  'temperature. Overwintering spores are spread mainly by splashing water from rains or irrigation. Wet periods of 8 to 12 hours at ' +
  'temperatures of 10°C to 25°C are needed for infections. If 100% infection is reached optimal conditions for the fungus to reach and ' +
  'enter the plant tissue have been fulfilled in the orchard.',
  'Potato/NegativePrognosisPhythopthoraInfestans': 'The Negative prognosis model means NOT to spray as long the prognosis answers the ' +
  'question about the presence of the pathogen in field with NO. For calculations the temperature, leaf wetness or high relative ' +
  'humidity and rain is used to assess the propagation of the pathogen in the potato field. A value in between 0 and 400 is indicating ' +
  'the propagation of P. infestans in the field. This value increases if air temperature is in between 15°C and 20°C, if relative ' +
  'humidity is higher than 70%. It increases faster for all times if relative humidity is higher than 90% and there is precipitation ' +
  'or if there is leaf wetness for more than 4 hours. If this situation holds for more than 10 hours the increase is higher. The ' +
  'start of calculation is based on a “temperature based rule” making sure that we calculate as soon as the first possible potato will ' +
  'grow. For potato, we will calculate as soon as temperature from 10:00 to 18:00 is higher than 8°C and night temperature is never ' +
  'below 2°C. Data have to be interpreted: a value of 150 correspond with a disease incidence in the field of 0.1%. A value of 250 ' +
  'corresponds with a disease incidence of 1%. So after a year with a low pressure of late blight in the seed producing area no sprays ' +
  'are needed before a value of 250 is reached. If a higher amount of inoculum is assumed sprays should start at 150.',
  'Potato/PectobacteriumAerialInfection': 'Blackleg, aerial stem rot and bacterial soft rot of tubers are caused by the bacterial ' +
  'species Dickeya sp. and Pectobacterium sp. High humidity, wounds, poor drainage, frequent irrigation, and improper plant nutrition ' +
  'favor the disease as well as warm temperatures. The pathogen is also often responsible for soft rot in storage. In FieldClimate we ' +
  'show aerial infection of the pathogen based on wet conditions (leaf wetness more than 0) and temperatures higher than 16°C. Whenever ' +
  '100% infection have been calculated optimal conditions have been given in the field for the development of the pathogen.',
  'Potato/PectobacteriumSoilInfection': 'Blackleg, aerial stem rot and bacterial soft rot of tubers are caused by the bacterial ' +
  'pathogens Dickeya and Pectobacterium. High humidity, wounds, poor drainage, frequent irrigation, and improper plant nutrition favor ' +
  'the disease as well as warm temperatures. Soil infections by the pathogen are determined by using sensors for soil moisture and air ' +
  'temperature. In FieldClimate we show optimal conditions for the disease when temperatures are above 16°C and soil moisture is smaller ' +
  'than 10. Whenever 100% infection have been determined optimal conditions have been given in the field for the development of the ' +
  'disease.',
  'Potato/WinstelCast': 'Be aware that this model was developed for early potato varieties! For determination of Phytophthora infestans ' +
  'model WinstelCast sensors for temperature and relative humidity are needed. Daily average, minimum and maximum temperatures as well ' +
  'as hours of temperatures greater than 10°C and relative humidity greater than 90% are calculated. This model is composed of two ' +
  'phases: Phase 1 predicts infection (if the daily average temperature is between 10°C and 23° C and then 10 hours or more of ' +
  'temperatures greater than 10° C and relative humidity greater than 90%). Phase 2 sets criteria for pathogen growth and occurs when ' +
  'the maximum daily temperature for two consecutive days is between 23°C and 30°C. Phase 2 must occur at least 24 hours but not later ' +
  'than 10 days after phase 1. We recommend treatments, when phase 1 occurs and is followed by phase 2.',
  'Potato/BliteCast': 'For this model sensors of precipitation, temperature, relative humidity and leaf wetness are needed. The model ' +
  'BLITECAST is used to determine the first possible infection time by P. infestans. The first part of the model forecasts the ' +
  'accumulation of 18 severity values (figure 2 with severity values determination) at the time of first emergence of green tissue. The ' +
  'second part of the model recommends fungicide sprays based on the number of rain-favorable days and severity values accumulated ' +
  'during the previous seven days (figure 1 spray demand). The first spray is recommended when the first late blight forecast is given. ' +
  'Be aware that irrigation can lead to favorable conditions in a field and extend the wetting period for a day.',
  'Potato/SmithPeriodsPhytophthoraInfestans': 'Air temperature sensor and relative humidity are needed for determination of Smith ' +
  'Periods. The model is based on the biology of the pathogen: Phytophthora infestans can grow if temperature is lower than 10°C, but ' +
  'sporulation will be nearly nothing at these temperatures. Reasonable sporulation needs a moist period with temperatures higher than ' +
  '10°C and infection needs free moisture. A Smith Period is: two consecutive days with minimum temperature of 10°C and 10 hours of ' +
  'relative humidity higher than 90% on the first day and 11 hours of relative humidity higher than 90% on the second day. A Smith ' +
  'period point out very favorable days for the disease. This model shows good results in UK and is valid in regions where temperature ' +
  'increase during spring very steady. The red line in the graphs indicates when reaching a Smith period (in sum 21 hours in two ' +
  'consecutive days).',
  'Potato/ColoradoBeetleRisk': 'We determine risky periods for the occurrence of the Colorado beetle Leptinotarsa decemlineata using -) ' +
  'the duration of sunlight of the day (14 to 15 hours of light), -) Soil temperature is higher than 12°C, -) Average air temperatures ' +
  'during the last four days in combination with the day length. A Severity value from 1- 4 is calculated indicating: 1 = very low risk; ' +
  '2 = low risk; 3 = average risk and 4 = high risk of Colorado potato beetle.',
  'Potato/PhytophthoraInfestans': 'For calculation of this model additional to the sensors of leaf wetness, r. h., precipitation and ' +
  'temperature the sensor of solar radiation is needed. We start to calculate the sporulation during night when the relative humidity is ' +
  'above 80%. If the sporulation already takes place and it starts raining the progress of infection starts to be determined at ' +
  'temperatures between 10 and 30°C. Whenever 100% infection are reached optimal conditions for an infection by P. infestans have been ' +
  'given in the field. Further on severity values of the disease are shown with 0 (very low pressure) and 5 very high pressure of the ' +
  'disease.',
  'Rice/BlastInfection': 'The Rice Blast Infection model points out date and severity of an infection. Light infections finish faster ' +
  'than severe infections, where higher temperatures and longer leaf wetness periods are needed. Further on higher rain amounts will ' +
  'lead to a better distribution of spores (Yoshino 1974, 1979 & Hashimoto et al 1984). We start to calculate infections by a rain ' +
  'event (< 4 mm) and leaf wetness. The infection progress depends on the temperature. If the disease progress curve reaches 100% the ' +
  'infection is finished and symptoms are expected 4 to 5 days after. The severity of infection depends on the temperature and on the ' +
  'amount of precipitation. This information is valid to improve the applied spray program. In a preventative spray program plant growth ' +
  'reduce the protected plant surface within days. An infection, which takes place immediately after a preventative spray, will be ' +
  'covered perfectly. If an infection takes place 6 or more days after the last spray high amounts of uncovered leaves could lead to a ' +
  'curative spray to ensure the success of disease control.',
  'Rice/SheathBlight': 'The Model of Rhizoctonia solani in rice fields evaluates the risk for this disease on base of temperature, leaf ' +
  'wetness and global radiation. The last five days are taking into account to determine risky times. Consecutive leaf wetness and ' +
  'temperature (more than 12°C) during that time will be accumulated. These accumulated values are evaluated as well as the global ' +
  'radiation (higher than 800W/m²). The model results in a risk value between 0 and 100 indicating the times favorable for R. solani in ' +
  'rice fields. No sprays will have to be applied in periods where the risk is low. In periods with moderate risk spray interval can be ' +
  'prolonged and in periods with high risk spray interval has to be reduced or more effective compounds will have to be used.',
  'SugarBeet/BeetCast': 'For fungicide application combine this model Beetcast with the output of the CercoPRI model. A) When CercoPRI ' +
  'reaches threshold before 01.06. – than apply first fungicide when 55 DSV units are accumulated and repeat spray when 35 DSV units are ' +
  'reached again. B) When CercoPRI reaches threshold before 01.07. – than apply first fungicide when 70 DSV units are accumulated and ' +
  'repeat spray when 55 DSV units are reached again. C) when CercoPRI reaches threshold after 01.07. – than apply first fungicide when ' +
  '80 DSV units are accumulated and repeat spray when 55 DSV units are reached again.',
  'SugarBeet/Cercospora': 'This simple risk model is used to evaluate, if C. beticola could be expected at all. If the incubation period ' +
  'could be fulfilled within the last 2 weeks a value of 1 of incubation is shown, if not the value is 0. Besides this model also ' +
  'a model for sporulation is shown. For sporulation optimal conditions of 48 hours of high relative humidity with an average ' +
  'temperature of 30°C are used. For interpretation of data: The risk is 0 if the incubation period has been found to be longer than 2 ' +
  'weeks. If this is not the case the model output will be 1. If there is a sporulation model result of higher than 10% calculated ' +
  'within the last week the risk will be shown to be 2 and if the sporulation result is higher than 30% the risk will be outputted to be ' +
  '3.',
  'SugarBeet/Cercopri': 'This model estimates the date of the first occurrence of C. beticola on base of the accumulated daily average ' +
  'temperatures, beginning on the first of January (base is 5°C). It takes the threshold of 1006.2°C in periods with relative humidity ' +
  'higher than 60%. If the relative humidity is lower, threshold of 1081.9°C is valid. This model have been developed for Italy and ' +
  'is used in Italy and Germany.',
  'Strawberry/PowderyMildew/GublerPowderyMildew': 'The model has been developed by David Gadoury and Doug Gubler and is based on ' +
  'temperature values. Periods with a risk have to be covered with fungicide applications. In periods with a risk higher than 60 points ' +
  'it is recommended to spray more frequent especially around the time and short after bloom.',
  'Strawberry/PowderyMildew/PowderyMildew': 'This model is based on leaf wetness, relative humidity and temperature. Air temperature of ' +
  'more than 21°C and relative humidity of more than 66° increase the risk, while lower temperatures as well as lower relative humidity ' +
  'or leaf wetness decrease risk. A risk higher than 0 but less than 60 will indicate plant protection measures on a low level. A ' +
  'risk higher than 60 points indicates an increased disease risk which might need highly effective products. Disease risks of 100 for ' +
  'a longer time period will need an increased application density.',
  'Strawberry/PhytophthoraCactorum': 'The Phytophthora cactorum infection model is displayed by the values 0 (no infection) and 1 ' +
  '(infection). The calculations are based on average temperatures at the last three days and the assessing of free water in the soil by ' +
  'determination of the precipitation. In a high pressure situation every infection period has to be taken serious. In low disease ' +
  'pressure situations a three day infection period has to be taken seriously.',
  'Soya/Rust': 'Once viable spores have landed on the leaf surface of a suitable host, infection and subsequent epidemic development are ' +
  'dependent on environmental conditions. Generally, infection occur when leaves are wet and temperatures are between 8°C and 28°C, with ' +
  'an optimum of 16°C to 28°C. At 25°C, some infection occur in as little as 6 hours of leaf wetness, but 12 hours are optimal. After ' +
  'infection, lesions and pustules with urediniospores can appear within 7 or 8 days, and the next infection cycle starts again. This ' +
  'short life cycle means that, under the right conditions, soybean rust epidemics can quickly build up from almost undetectable levels ' +
  'to very high levels. In FieldClimate the progress of an infection is determined by leaf wetness and air temperature. Further on the ' +
  'level of severity is determined (from 1(weak) -3(severe)), after optimal conditions of an infection have been determined in the field.',
  'Soya/Rost': 'In this Soybean model we have included solar radiation (as UV light destroys the spores and sometimes clouds are ' +
  'protecting the spores from sun). For determination of infection the leaf wetness time, relative humidity, precipitation and ' +
  'temperature as well as solar radiation is used. In FieldClimate we show optimal conditions for light and strong infections. If 100% ' +
  'of an infection curve are reached, optimal conditions have been in the field for the fungus to enter the plant. A strong infection ' +
  'could lead to the establishment of local inoculum at that side, which is also shown in the graph (risk is very high at 100% local ' +
  'inoculum).',
  'Sunflower/PlasmoparaHalstediiSoilBorne': 'With respect to the primary infection, P. halstedii is a soilborne pathogen. Starting from ' +
  'a single oospore that germinates and gives rise to a macrosporangium, macrospore move to infection sites (root, hypocotyl) penetrate ' +
  'and infect the susceptible host (“soil infection”). When conditions are favorable, asexual sporulation takes place on affected leaves ' +
  '("Air infection"). Fully developed sporangia disseminate by wind and their survival depends on the current weather situation ' +
  '("Secondary infection"). Secondary infection is considered as an important factor in the spreading of the disease in certain regions. ' +
  'Due to the fact that secondary infection of inflorescence may give rise to latent infection of seeds In FieldClimate we determine the ' +
  'infection progress, when temperature is between 6°C and 32°C with an optimum from 18 to 24°C and a soil temperature above 10°C. ' +
  'Further on wet conditions are favorable for the disease (rain event, relative humidity above 70%). So: Makrosporangia are formed at ' +
  'soil temperatures above 10°C and precipitation (rel. humidity more than 70%). If Makrosporangia are fully developed (100%) - the ' +
  'calculations for a soil infection or an air infection starts (under leaf wetness conditions). Sporangia are formed under humid ' +
  'conditions (more than 95% r.h.), in darkness and temperatures above 12°C. If Sporangia are fully developed (100%) the determination ' +
  'of the secondary infection starts in dependence of the air temperature. If sunflower seedlings are in a sensitive stage at the time ' +
  'of optimal infection conditions (just sown) control measurements have to be taken into account (prophylactic systemic fungicides, ' +
  'mostly phosphoric acids).',
  'WalnutsAlmondsPistachios/PanicleAndShootBlight': 'The first graph shows the sporulation of the Panicle and Shoot Blight of Pistachios ' +
  'based on precipitation, leaf wetness and temperatures between 6°C and 30°C. A value of 100% indicates optimal conditions for the ' +
  'sporulation in the field and is the basis to start an infection (graph 2). Infection increases if leaf wetness is there or relative ' +
  'humidity is more than 90% during temperatures of 6°C to 30°C. At a value of 100% optimal conditions for an infection have been given.',
  'GeneralFusariumHeadBlight': 'Here we determine favorable conditions of a Fusarium Head Blight infection in the field and further on ' +
  'the risk of high contents of mycotoxin in seeds. A leaf wetness period of 2 days or more during the sensitive wheat stage ' +
  '(BBCH 61-69) is assumed to give a high risk of mycotoxins. We accumulate risky period’s proportional to the infection progress for ' +
  'every successful infection period. Six completed infections would lead to a risk of 100%. The risk value pointing to a problematic ' +
  'situation depends on the field history. Nevertheless first year wheat should be tested for DON, if the risk exceeds 100%.',
  'Wheat/FusariumHeadBlightRisk': 'The fungal strains belonging to the Fusarium Head Blight complex favor warm temperatures between 20°C ' +
  'to 30°C and long humid periods. We show here risky periods whenever infection curve reaches 100% and therefore favorable conditions ' +
  'have been given in the field. The farmers knowledge about the development stage of different wheat varieties gives the possibility to ' +
  'decide for an application of a curative spray immediately after infection.',
  'Wheat/PerikularumGrisea': 'Cloudy skies, humid conditions and drizzles favor the development and severity of the disease. In ' +
  'FieldClimate we calculate three severity classes and when 100% of infection are reached optimal conditions for the fungal pathogen ' +
  'have been given in the field. In dependence of the variety, field history curative plant protection measurements have to be taken ' +
  'into account when a light, moderate or severe infection has reached 100%.',
  'Wheat/SeptoriaNodorum': 'Optimal conditions for Septoria infections are temperatures around 16°C to 21°C and leaf wetness or high ' +
  'relative humidity of 14 hours or more. For Septoria nodorum a risk model is calculated. Whenever risky periods of 80% are reached ' +
  'preventative plant protection measurements should be taken into account, while after showing a risk of 100% curative (systemic) ' +
  'compounds should be used.',
  'Wheat/SeptoriaTritici': 'Optimal conditions for Septoria infections are temperatures around 16°C to 21°C and leaf wetness or high ' +
  'relative humidity of 14 hours or more. Septoria tritici is calculated in three severity classes (in dependence of rainfall and leaf ' +
  'wetness). Further, we determine so called "Severity levels (1-5)". This should support to assess the infection pressure between BBCH ' +
  'stages 10 to 32 of wheat. Severity 1: weak infection is fulfilled, lower rainfall than 5 mm, otherwise it will be Severity 2. ' +
  'Severity 3: moderate infection is fulfilled and lower rainfall than 5 mm, otherwise it is Severity 4. Severity 5: a severe infection ' +
  'is fulfilled with more than 5 mm of rain.',
  'Wheat/Anthracnose': 'The model evaluates risky periods based on temperature, leaf wetness and global radiation. It checks conditions ' +
  'of the last 120 hours. The model results in a risk value between 0 and 100, indicating favorable conditions for the disease. No ' +
  'sprays have to be applied in periods where risk is low. In moderate risk periods, the spray interval can be prolonged and in periods ' +
  'with a high-risk spray interval may have to be reduced or compounds that are more effective have to be used.',
  'Wheat/PucciniaSpp': 'Rusts are diseases of warmer climate. The three rust diseases Leaf rust (P. tritici), Steam rust (P. graminis) ' +
  'and Stripe rust (P. striiformis) are shown in one graph. They slightly differ in their climatic needs and calculations are based on ' +
  'solar radiation, leaf wetness and temperature. Whenever infection curve reaches, 100% optimal conditions for the fungal disease have ' +
  'been given in the field. Curative plant protection measurements have to be taken into account.',
  'WalnutsAlmondsPistachios/GnomoniaLeptostyla': 'The disease is worldwide distributed. Wet and rainy spring weather with temperatures ' +
  'above 10°C favor the discharge of ascospores, which are carried by wind and rain to new green leaves. Infections take place and ' +
  'symptoms are found beginning of May. Infected leaves show black small asexual fruiting bodies (acervuli). At about 18°C, the ' +
  'production of these acervuli is highest; they produce large numbers of conidia. Conidia cause the secondary infection source (in ' +
  'June- autumn). Rain splash is required, as well as free moisture must remain on the leaf surface more than 6 hours. Optimal ' +
  'temperature for infection is about 21°C. Symptoms could be found after 2-3weeks incubation. Under unfavorable conditions (dry ' +
  'conditions), spores are able to survive some weeks. In FieldClimate we determine the possibility of infections and discharge of ' +
  'ascospores under favourable conditions based on temperature, rain and leaf wetness. On the first graph conditions for ascospore ' +
  'discharge are modelled, whenever 100% is reached optimal conditions have been in the field. The graph below shows optimal conditions ' +
  '(100%) for an infection in the field.',
  'GeneralAnthracnose': 'Anthracnose infects plants at temperatures ranging from 15°C to 30°C, but a long lasting leaf wetness period is ' +
  'needed. At optimum temperatures of 20°C to 25°C a leaf wetness period of 12 hours is needed. So, whenever the infection curve reaches ' +
  '100% optimal conditions have been given in the field for an infection.',
  'GeneralPhythopthoraBlightPhythopthoraCapsici': 'We calculate the formation and infection of Phythopthora capsici by Oospores (sexual) ' +
  'and by Sporangia (asexual). Oospores are thick walled and able to survive a long time, they need the presence of two isolates of ' +
  'P. capsici. Sporangia are the form, which will be dispersed quickly in the culture. Whenever 100% infection is reached optimal ' +
  'conditions for the development and infection by oospores or sporangia have been given.',
  'GeneralNoBlightPhythopthoraInfestans': 'The possibility for Late Blight to appear is predicted by accumulation of so called "Severity ' +
  'values". These values are based on weather conditions (mild and wet) and accumulate when they are appropriate for the development ' +
  'of the pathogen. We show these Severity values in combination with the recommended spray interval (reduced interval when severity ' +
  'is high and prolonged interval when severity is low).',
  'GeneralTomCast': 'TomCast (TOMato disease foreCASTing) is a computer model based on field data that attempts to predict fungal ' +
  'disease development. The data over the last 24 hours may result in the formation of a "Disease Severity Value (DSV)"; essentially an ' +
  'increment of disease development. As DSV accumulate, disease pressure continues to build on the crop. When the number of accumulated ' +
  'DSV exceed the spray interval, a fungicide application is recommended to relieve the disease pressure. These Disease Severity Values ' +
  'are shown in the graph as well as the sum of these values. The spray interval range between 15 - 20 DSV and when the total number of ' +
  'accumulated DSV exceed these limit a fungicide spray is recommended.',
  'GeneralLeafMold': 'We determine the risk of a Cladosporium fulvum infection based on leaf wetness, relative humidity and air ' +
  'temperature. The graph shows high risk periods of an infection when the curve reaches 80-100% risk.',
  'GeneralSeptoriaLeafSpot': 'Wet conditions favor production of spores during temperatures from 15°C to 27°C. These spores may be ' +
  'spread by wind, water, hand, insects and equipment. Spores may germinate within 48 hours under moist conditions, leaf spots can ' +
  'appear within 5 days, and spore production starts again. Long lasting dew and rainy days favor disease development. Favorable ' +
  'conditions showing increased infection curves are determined in fieldclimate.com. Whenever 100% infection has been reached curative ' +
  'plant protection measurements should be taken into account.',
  'GeneralTomatoPowderyMildewRisk': 'Powdery mildew is an inoculum driven disease, therefore just risky periods are determined. These ' +
  'risks are calculated based on leaf wetness and temperature. Whenever 80 to 100% risk is shown control strategies should be taken into ' +
  'account: combine the modeling of the risky period together with the monitoring of fungal inoculum in the field.',
  'GeneralSclerotinia': 'Apothecia are formed on the soil surface from which ascospores are released into the air. Infection of most ' +
  'crop species is principally associated with ascospores but direct infection of healthy, intact plant tissue from germinating ' +
  'ascospores usually does not occur. Instead, infection of leaf and stem tissue of healthy plants results only when germinating ' +
  'ascospores colonize dead or senescing tissues, usually flower parts, prior to the formation of infection structures and penetration. ' +
  'The FieldClimate model shows the periods when the formation and sporulation of apothecia are expected. These events will be ' +
  'calculated when a rain of more than 8 mm is followed by a period of high relative humidity lasting longer than 20 hours at optimum ' +
  'temperature of 21°C to 26°C. Further on direct infection by apothecia can be expected after a leaf wetness period followed by 16 ' +
  'hours of relative humidity higher than 90% under an optimum of 1°C to 26°C ("appressoria infection"). Whereas saprophytic growth ' +
  'followed by encymatic degradation of the epidermic cells ("hydrolytic infection") can be expected under a slightly lower relative ' +
  'humidity of 80 % lasting for a period of 24 hours under optimum conditions of 21°C to 26°C. If these periods are coincident with the ' +
  'flowering period of brassica plants or canola we have to expect S. sclerotiorum infections during a moist period. If the infection ' +
  'progress line reaches 100% an infection has to be assumed. These infections should be covered preventative or a fungicid with a ' +
  'curative action against S. sclerotiorum has to be used.',
  'WarmClimateTomato/PowderyMildewLeveillulaTaurica': 'This is another model for determination of the Powdery Mildew risk in fields. ' +
  'The model is based on a forecast model developed by the University of California, Davis. Favorable conditions for Powdery Mildew are ' +
  'determined based on temperature, relative humidity and leaf wetness. Based on this result spray recommendation and next evaluations ' +
  'of the disease are given. In the first graph the determination of favorable conditions is shown and on the second one the recommended ' +
  'spray intervals.',
  'GeneralFreyPhytophthoraInfestans': 'This model, developed by W.E.Fry (1983) includes different susceptibility levels of plants at ' +
  'different durations of relative humidity higher than 90% or leaf wetness and temperature. Susceptible cultivars will be infected ' +
  'within shorter moist periods and the disease severity will be higher. Whereas moderate susceptible and resistant varieties will need ' +
  'a longer moist period or warmer temperatures to be infected and the disease severity is lower. We show an infection curve ranging ' +
  'from 0 to 100% infection and additional rating of infections for the different susceptibility levels of tomato plants. For decision ' +
  'support: A spray is needed if the last spray is longer than 6 days away and the accumulated Blight Units are exceeding: 30 for ' +
  'susceptible varieties, 35 for moderate varieties and 40 for resistant varieties.',
  'Corn/LeafBlight': 'SOUTHERN CORN LEAF BLIGHT: This disease needs free water (leaf wetness) to infect plant tissue. Therefore, ' +
  'conditions for starting an infection are leaf wetness and temperatures higher than 15°C. The infection will be completed after ' +
  'accumulation of 100 wet degree hours. NORTHERN CORN LEAF BLIGHT: This fungus infects the leaves of the corn plant also when ' +
  'temperature and leaf wetness are given. The infection takes place when temperature is ranging from 13°C to 28°C. At a temperature ' +
  'of 18°C, it will need 18 hours for infection. If temperature is lower it will need even longer time and if it is warmer it will need ' +
  'a shorter leaf wetness period. We show in the first graph the time period of optimal conditions for an infection with Southern Corn ' +
  'Leaf Blight (100% infection) and in the second graph when optimal conditions for Northern Corn Leaf Blight infection (100% infection) ' +
  'are there. Curative plant protection measurements should be taken into account after an infection.',
  'Corn/GreyLeafSpot': 'The fungus survives during the winter on infected corn residues on the soil surface. With increasing ' +
  'temperatures in the spring spores are produced on maize residues and they splash to young leaves. That is the reason why symptoms are ' +
  'commonly observed on the lower leaves of the plant. Spores are transported by wind or rain. Infections are favored when temperature ' +
  'is between 24°C to 30°C and relative humidity of more than 90%. The development of the spores decreases under low humidity, but ' +
  'starts again under high r. h. Each lesion produces many spores, which are carried to young healthy leaves. Due to the length of the ' +
  'infection process; symptoms may not be seen for some weeks after infection, depending on weather conditions and hybrid ' +
  'susceptibility. We model the disease in FieldClimate when temperatures are between 22°C and 30°C, and a relative humidity of more or ' +
  'equal of 90%. The risk range from 0 (no risk) to 100% (very high risk).',
  'GeneralRustInfection': 'Spores of the fungal pathogen Tranzschelia discolor sp. germinate over a wide temperature range, ranging ' +
  'from 5°C to 30°C, with an optimum between 15 to 25°C and wetness. Under controlled conditions the optimal wetness duration and ' +
  'temperature for an infection was 18 hours to 36 hours at 15°C to 20°C. The disease becomes severe when rain occurs in late spring and ' +
  'summer. Trees can be defoliated quickly when rust becomes severe. We calculate a risky period for a rust infection based on ' +
  'precipitation, leaf wetness and temperature. When the infection curve reaches 100% optimal conditions for a rust infection have been ' +
  'determined in the orchard and preventive plant protection measurements should be taken into account.',
  'GeneralElsinoeVeneta': 'The fungus produces in spring two types of microscopic spores (conidia and ascospores) that are ' +
  'rain-splashed, blown, or carried by insects to young, actively growing cane and leaf tissues. The spores germinate in a film of ' +
  'moisture in 3h to 12 hours and infect young tissues; new spots appear about a week later. As the canes mature, they become hardened ' +
  'and more resistant to infection. Spores (conidia) are released and spread during late August and September rains. These lesions ' +
  'produce the conidia for infections the following spring, thus completing the disease cycle. In FieldClimate we show infection curves ' +
  'for weak, moderate and severe infections. Whenever 100% infections are reached optimal conditions for the fungal pathogen have been ' +
  'given in the field to infect the plant. Showing different infection severities (weak, moderate, severe) should support the farmer to ' +
  'take plant protection measurements in dependence of his/her experience on that field and the variety of the plant.',
  'GeneralPhragmidiumViolaceum': 'Rust is mainly prevalent in warm and wet conditions. Optimum temperature for spore production is ' +
  'around 21°C and millions of spores are easily and quickly transported by wind to new plant tissue. The spores are able to re-infect ' +
  'the original host as well as other berry plants and other host species. In FieldClimate we determine optimal conditions for ' +
  'infections by temperature, relative humidity, leaf wetness. Whenever 100% infections are reached curative plant protection ' +
  'measurements should be taken into account, thus the pathogen already had optimal conditions to enter and infect the plant.',
  'GeneralColletotrichum': 'The fungal pathogen overwinters in twigs and flower buds, spurs and trusses. In spring the fungus produce ' +
  'spores, which are dispersed by rain, irrigation and water splashes. Infections occur at a temperature optimum of 12- 27°C and a leaf ' +
  'wetness period of more than 12 hours. The fruit can be infected at any time, although infections are just visible (symptoms seen) ' +
  'when the fruit is mature. The fungus enters the fruit and remains dormant until the berry starts to ripen. At this time the fungus ' +
  'starts to colonize the infected area and produce enzymes with destroy the plant cell walls. These damaged cells loose their ' +
  'contents and integrity (symptoms are seen as sunken areas and shriveled). Spent fruit trusses could be infected after harvest. In ' +
  'FieldClimate.com weak, moderate and severe Infections are calculated in dependence of the parameters temperature, leaf wetness and ' +
  'precipitation. Whenever 100 % of infection are reached the conditions for a weak/moderate or severe infection have been fulfilled. ' +
  'In dependence of the variety of the plant and location the farmer has to decide if he/she has to take curative plant protection ' +
  'measurements on a weak, moderate or severe infection into account.',
  'MelonCucumberZucchiniPumpkin/MelonPowderyMildewRisk': 'Possible local sources of initial inoculum, which is believed to be airborne ' +
  'conidia dispersed over long distances, include conidia from greenhouse-grown cucurbits, cleistothecia, and alternate hosts. ' +
  'Conidia remain available for 7 to 8 days and when finding a host develop quickly under favorable conditions. The time between ' +
  'infection and symptoms appearance is usually only 3 to 7 days and a large number of conidia can be produced in a short time. ' +
  'Favorable conditions include dense plant growth and low light intensity as well as high relative humidity. However infection can take ' +
  'place as low as 50% RH. Further on dryness is favorable for colonization, sporulation, and dispersal. Rain and free moisture on ' +
  'the plant surface are unfavorable. However, disease development occurs in the presence or absence of dew. In FieldClimate.com ' +
  'the risk of Powdery mildew is determined by leaf wetness and temperature. Conditions for optimal development of the fungal pathogen ' +
  'are a mean temperature of 20°C to 27°C and no long leaf wetness periods.',
  'Raspberry/YellowRustPhragmidiumRubiIdaei': 'Infected plants show yellow, powdery clumps or “pustules” on the underside of the leaves. ' +
  'These are the “summer” spores. On the top of the leaf, corresponding directly with the yellow clumps will be a purple spot. ' +
  'In late summer through fall, the yellow summer spores change to black "winter" spores, which remain attached to the underside of ' +
  'the leaves through the winter, or are deposited on the soil when the leaves fall. Either these "winter" spores can remain dormant in ' +
  'the soil, or they can germinate in the spring to start a new cycle of infection. P. violaceum needs moisture on the leaves for ' +
  'several hours and is highest in areas of high rainfall and moderate temperature. When the rust is exposed to temperatures over 29°C ' +
  'for several continuous days, it stops growing, and switches from the active "summer" spore stage to the dormant "winter" spore stage. ' +
  'In FieldClimate we determine and increase the yellow rust infection based on leaf wetness, relative humidity more than 80% and ' +
  'temperature between 5 and 25°C. Whenever the infection curve reaches 100% optimal conditions for an infection with yellow rust have ' +
  'been given on the field, therefore plant protection measurements should be taken into account (curative after 100% infection, ' +
  'prophylactic around 60 – 80% infection).',
  'OilSeedAndCabbage/MycosphaerellaBrassicicola': 'The fungus infects brassicas at all growth stages, from seedlings to mature plants, ' +
  'but is normally most prevalent on older crops. Symptoms develop initially as small dark-green to black spots, called "ring spot ' +
  'disease". In cases of severe infection, the entire leaf gets brown and leads to early senescence and defoliation. Temperatures ' +
  'favoring infection are 16°C to 20°C and leaf wetness. The pathogen penetrates by invading through the stomata. Symptoms develop in ' +
  'about 10-14 days after infection (Incubation time). Sexual reproductive bodies appear following four consecutive days of 100% ' +
  'relative humidity, usually taking about three weeks after the initial infection. Once ring spot is established in a crop, ascospores ' +
  'from the leaf lesions rapidly spread through the canopy. In FieldClimate we determine, based on relative humidity and temperature, ' +
  'optimal conditions for an infection (80% - 100% of infection curve, graph 1) and predict the number of days between infection and ' +
  'visible symptoms seen on the plant (incubation time, graph 2).',
  'Onion/BotrytisSquamosaInfection': 'Infections can occur with only same hours of leaf wetness if temperatures are near the optimum ' +
  'range of 15°C to 20°C. As temperatures deviate from this optimum range, longer periods of leaf wetness are required. In FieldClimate ' +
  'we determine optimum conditions for an infection as well as four severity levels: An infection of the weakest severity is fulfilled ' +
  'after the minimum time needed for spore germination and infection. Longer leaf wetness periods lead to increased spore amounts. The ' +
  'four severity levels are 1) insignificant, 2) slight, 3) moderate and 4) severe potential infection periods. Decision should be made ' +
  'in combination with a method of forecasting the presence of significant amounts of inoculum of B. squamosa. The presence of inoculum ' +
  'depends on the age of the field culture and observed symptoms before.',
  'Onion/BotrytisLeafBlightBotrytisSquamosa': 'Infections can occur with only same hours of leaf wetness if temperatures are near the ' +
  'optimum range of 15°C to 20°C. As temperatures deviate from this optimum range, longer periods of leaf wetness are required. In ' +
  'FieldClimate we determine optimum conditions for an infection as well as four severity levels: An infection of the weakest severity ' +
  'is fulfilled after the minimum time needed for spore germination and infection. Longer leaf wetness periods lead to increased spore ' +
  'amounts. The four severity levels are 1) insignificant, 2) slight, 3) moderate and 4) severe potential infection periods. Decision ' +
  'should be made in combination with a method of forecasting the presence of significant amounts of inoculum of B. squamosa. The ' +
  'presence of inoculum depends on the age of the field culture and observed symptoms before.',
  'Olive/SpilocaeaOleagina': 'Heavily infected leaves and fruits on the ground and remaining once on the trees are a source of inoculum ' +
  'for the current season or allow over-wintering of the fungus. The disease generally is favored by cool weather. Further on ' +
  'germination requires 98% humidity, with temperatures in the range of 0–27°C. The graphical presentation in FieldClimate determine the ' +
  'infection progress based on the duration of leaf wetness, relative humidity and air temperature. Whenever 100% infection are reached ' +
  'optimal conditions have been in the field for the fungus to infect plant tissue (young leaves are most susceptible). If infection ' +
  'conditions are optimal and a long moist period is there an infection severity value (highest: 4) is calculated too.',
  'Olive/Anthracnose': 'The fungal pathogens (Colletotrichum sp.) overwinter in mummified fruits, woody tissue and leaves. In spring the ' +
  'fungus produce spores, which are dispersed by rain, irrigation and water splashes. Infections occur at a temperature optimum of ' +
  '12- 27°C and a leaf wetness period of more than 12 hours. The infection of flowers lead to fruit rot. The fungus enters the flower ' +
  'and remains dormant until the fruit starts to ripen. At this time the fungus starts to colonize the infected area and produce enzymes ' +
  'with destroy the plant cell walls. These damaged cells loose their contents and integrity (symptoms are seen as sunken areas ' +
  'and shriveled). Spent fruit trusses could be infected after harvest. In FieldClimate.com weak, moderate and severe Infections are ' +
  'calculated in dependence of the parameters temperature, leaf wetness and precipitation. Whenever 100 % of infection are reached ' +
  'the conditions for a weak/moderate or severe infection have been fulfilled. In dependence of the variety of the plant and location ' +
  'the farmer has to decide if he/she has to take curative plant protection measurements on a weak, moderate or severe infection into ' +
  'account.',
  'Onion/MilIonCastSeverity': 'Here we determine optimal conditions for building sporangia: 1) The greatest number of sporangia is ' +
  'produced at 100% relative humidity, darkness and temperatures between 6°c and 24°C, and sporulation decrease rapidly when humidity ' +
  'decreased to 93% Rh. The severity of the infection is shown in steps of 0 to 5 (second graph) with background colors, reaching the ' +
  'orange color with severity of 3 means to take plant protection measurements into account. Please combine this model with the Downy ' +
  'Mildew P. destructor Model Milion Cast Infection to see the progress of the infection and incubation times.',
  'Onion/MilIonCast': 'We determine different stages of Peronospora destructor in FieldClimate. 1) The greatest number of sporangia is ' +
  'produced at 100% relative humidity (RH), darkness and temperatures between 6°c and 24°C and sporulation decrease rapidly when ' +
  'humidity decreased to 93% Rh; 2) for Infection: Downy mildew spores require temperatures between 6 to 32°C and anywhere between 4 to ' +
  '10 hours of leaf wetness to germinate. Spores survive 1- 3 days after sporulation, free water has to be available. 3) Incubation ' +
  'Period: The mycelium of Downy Mildew in leaves of infected onion plants in commercial bulb production fields produces a new crop of ' +
  'spores called conidia in cycles of approximately 11–15 days.',
  'Rapseed/PhomaStemCancer': 'The model in FieldClimate starts with the assessment, if a Black Leg infection is possible in late summer ' +
  'and early autumn. This part of the model can be used as an "negativ prognosis". It has been tested for the UK climate to estimate ' +
  'the first occurrence of P. lingam infections, therefore the model is valid for cool and humid climate. It should be used with some ' +
  'caution in continental climate like Hungarian or Austria. The models for Ascospore maturation, ascospore release and ascospore ' +
  'infection are showing possible ascospore infections during autumn. This models are basing on the biology of the pathogen and will ' +
  'most probably show more ascospore infections than you might find in the field. This is because on base of the climate data we do not ' +
  'know anything about the inoculum density and the precious crops of the rape fields. Anyhow if one or several ascospore infections ' +
  'are fitting to the susceptible plant stages after emergence P. lingam infections will take place and secondary infections from ' +
  'conidia have to be expected during warm and moist periods in fall or spring.',
  'Rapseed/PollenBeetleFlight': 'Pollen beetles Meligethes spp. occur in high abundance at temperatures above 10°C. They overwinter ' +
  'in litter of forests and start to immigrate to rape fields in spring. Most damage is done by adults feeding on buds. Females lay ' +
  'their eggs into buds, but once the flower occurs no damage is done by larval feeding on pollen. There are a lot of reports, which ' +
  'describe the resistance of the pollen beetle against special insecticides. Flight Period: Pollen beetle start flying if the soil ' +
  'temperature is higher than 9°C and the air temperature more than 15°C and low relative humidity. Flight of Pollen beetle lasts about ' +
  'four weeks (in Austria between begin to mid of April till mid of May). In FieldClimate.com we just determine the occurrence of the ' +
  'flight by "Yes"; or "NO"; so if the immigration to the field was already determined by soil and air temperatures ' +
  'it will stay at "YES"; for the season and the farmer has to check the occurrence on the field at that time.',
  'TurfGrass/Overseeding': 'For successful over sowing you need to A) Choose the proper seed B) Properly prepare for and time ' +
  'application, C) Carefully maintain the over seeded grass and D) attentively manage the spring transition back to the warm-season ' +
  'grass. The proper seed is the grass with the characteristics best suited to your particular needs. We determine optimal conditions ' +
  'for over seeding in FieldClimate for either WARM (GPW) or COLD (GPC) season grass species by 1) Soil temperatures at a 4-inch depth ' +
  'approaching 24°C. 2) Night temperatures consistently around 10°C and 3) Average midday temperature below 21°C, or two to four weeks ' +
  'before the average annual first killing frost.',
  'TurfGrass/PhytiumDollarSpotBrownPatch': '1) PHYTIUM BLIGHT - We determine risky periods for Phytium Blight based on following ' +
  'conditions: A) infection and disease development is associated with daytime air temperatures above 30°C B) night temperatures are ' +
  'above 20°C, C) relative humidity for 15 or more hours are above 90% and D) high nitrogen nutrition appear to be more susceptible to ' +
  'the disease as are young/germinating seedlings, so care must be taken during overseeding or establishment in hot weather. 2) DOLLAR ' +
  'SPOT - For the development of the Dollar Spot disease following conditions are optimal: A) optimal temperatures between 15°C and ' +
  '32°C, B) long periods of leaf wetness from dew, rain, irrigation, C) most prevalent during springtime and fall, D) low soil moisture ' +
  '(which stress the plant and makes it more susceptibility to the pathogen) and E) turf grass under low nitrogen fertility show more ' +
  'dollar spot than once with optimum fertility. We determine risky periods on the scale of 0 (no risk) and 1 (risk) in dependence of ' +
  'high humidity and air temperature. 3) BROWN PATCH - Optimal conditions for the disease development are temperatures around 20°C ' +
  'during night and day temperatures around 25°C with high relative humidity (rain, dew). The model shows risky periods of infections by ' +
  'a scale of 0 (no risk) and 1 (risk).',
  'TurfGrass/SnowMold': 'The fungal disease occurs in early spring after snow melting. The two types of snow molds (gray and pink) ' +
  'become active under the snow cover and are caused by the fungus Typhula spp. (gray snow mold) and Microdochium nivalis (pink snow ' +
  'mold). Gray snow mold survives hot summer temperatures in the soil or in infected plant debris as sclerotia, resistant fungal ' +
  'structures, while pink snow mold survives as mycelium or spores in infected plant debris. Fungal growth begins in the winter, beneath ' +
  'a cover of snow on unfrozen ground. Growth can take place at temperatures slightly below freezing and may continue after snow melt, ' +
  'as long as the grass remains cool and wet. Gray snow mold activity stops when the temperature exceeds 7°C or the surface dries. Pink ' +
  'snow mold activity may continue during wet weather in the fall and spring, as long as the temperature is between 0°C and 15°C. We ' +
  'determine the progress of risk in FieldClimate. Whenever 100% risk are reached optimal conditions have been given in the field for ' +
  'disease prevalence.',
  'Lettuce/Anthracnose': 'Free water (rain, dew, irrigation) is required for spore dispersal, germination and infection. Therefore, the ' +
  'FieldClimate model is initiated by rain and uses temperature and leaf wetness duration for determination of an infection. Optimum ' +
  'temperature is around 20°C to 22°C, but infections occur over a range of 5°C to 30°C. We determine in FieldClimate two ways of ' +
  'infection: A) the Microsclerotia infection, where 4 to 6 hours of optimum conditions are needed for germination and infection and B) ' +
  'Conidia infection, where spores are produced in lesions on leaves and are spread to other leaves primarily by splashing water.',
  'Lettuce/DownyMildewBremiaLactucae': 'Downy mildew infection model is based on the work done in UC Davis. The key climate and ' +
  'epidemiologic factors are sporulation driven by darkness, relative humidity (more than 85%) and air temperature (15°C to 23°C). Spore ' +
  'dispersal driven by the upcoming morning (dew until 10:00) and rain. Infection is driven by leaf wetness. We determine three ' +
  'severities: light infection (result of light sporulation and dew), moderate infection (result of light/moderate sporulation and rain ' +
  'or dew) and severe infection (result of moderate/severe infection and rain or leaf wetness). Whenever 100% of the infection has been ' +
  'finished optimal conditions have been given in the field for the fungus to infect plant tissue and plant protection measurements ' +
  'should be taken into account at 60 to 80% infection progress (prophylactic).',
  'Viticulture/PhomopsisInfection': 'We determine the risk of a Phomopsis Infection at a temperature range of 5 to 35°C and wet ' +
  'conditions (leaf wetness, high relative humidity). Further on moderate and severe Infections are calculated in dependence of the ' +
  'amount of rain (more than 2mm), as spores are distributed fast to healthy plant material. Whenever 100% infection is shown optimal ' +
  'conditions have been measured in the field to infect plant tissue and plant protection measurements should be taken into account.',
  'Sugarcane/FloweringInduction': 'We calculate the risk of flowering described by Pereira (1983) as the Function L: ' +
  'L = 1, 263 - 0, 06764 * X1 - 0, 02296 * X2, where X1 = number of nights with minimum temperature higher or equal to 18°C, X2 = number ' +
  'of days with maximum temperature below or equal to 31°C. As a result, we show the "L- value" and the "RISK" the value 1: very low ' +
  'risk, 2: low risk, 3: moderate risk and 4: high risk.',
  'Wheat/PowderyMildew': 'We determine the risk of Powdery mildew varying from 0 (no infection) to 100% (full infection). Risk increase ' +
  'if temperatures are between 12 °C and 21°C for most hours of the day and global radiation is low. While leaf wetness, high global ' +
  'radiation and high temperatures (above 32°C) decrease the risk. The model is relevant during BBCH stage 21 to 39 for barley and for ' +
  'wheat and oat in stage BBCH 21 to 75.',
  'Hazelnut/PowderyMildew': 'For Powdery Mildew in Hazelnut we show: 1. Spore release under wet conditions. In dependence of the amount ' +
  'of rain we define different severities: 1: low spore release, 2: moderate spore release and 3: severe spore release. If spores have ' +
  'been released see the model2: 2. Propagation risk model, which increase at optimum temperature from 21 to 32°C and decrease when leaf ' +
  'wetness lasts more than 8 hours per day or temperature does not reach 21°C.',
  'GeneralBerryPhomopsisRisk': 'We increase the risk of a Phomopsis Infection when relative humidity is more than 70% and temperature is ' +
  'above 10°C. In dependence of the rain amount we show severity classes: 1: low risk, 2: moderate risk and 3: severe risk of an ' +
  'infection.',
  'Berry/BacterialCancer': 'Depended on the temperature (ranging from 1 to 35°C), relative humidity, precipitation and frost event of ' +
  'the last days we determine the risk of a Pseudomonas propagation: 1 : very low risk, 2: low risk, 3: moderate risk, 4: high risk, ' +
  '5: very high risk.',
  'Blueberry/MoniliniaBlight': 'This model assist blueberry growers when the plant reach a susceptible stage to get infected. First ' +
  'Spray should be applied, when following questions are fulfilled: Is my field prone to Moniliniablight, that is, did I ever have ' +
  'blight? If the answer is NO, then a spray is not necessary. If YES, move to question 2. Are 40 percent to 50 percent of the leaf buds ' +
  'at a susceptible stage, i.e., leaf buds showing 0.2 inches ( 0.5 cm) of a green tip? If the answer is NO, a spray is not necessary at ' +
  'this time. If YES, than follow the recommendations of the model and see if optimal conditions for fungal development have been given ' +
  'and a spray is recommended after 72 hours (frost periods within the last days are already implemented in the model). The model refers ' +
  'to Wild Blueberry Fact Sheets 211, Blueberry Diseases 1, and 219, Disease Control Guide for Wild Blueberries, for details on ' +
  'identification of blight and disease control treatments. University of Maine.',
  'GeneralTomatoPowderyMildewRiskPE' : 'Powdery mildew is an inoculum driven disease, therefore just risky periods are determined. These ' +
  'risks are calculated based on leaf wetness, temperature and solar radiation. Whenever 80 to 100% risk is shown control strategies ' +
  'should be taken into account: combine the modeling of the risky period together with the monitoring of fungal inoculum in the field.',
  'Pea/AscochytaBlight': 'Ascochyta blight disease development is optimal at a temperature range of 20 to 21°C and high relative ' +
  'humidity and leaf wetness. We show two steps of the disease cycle: 1. the Sporulation (development of pycnidia ) ,so producing of new ' +
  'infectious units and 2. optimal conditions for an Infection. If the sporulation took place (100%) and afterwards the infection curve ' +
  'could reach 100% optimal conditions for the infection of plant tissue has been given in the field.',
  'GeneralDrechsleraGraminea' : 'D. graminea is seedborne and survives in the outer layers of infected seed. Seedlings get infected by ' +
  'the fungus under cool, moist conditions. The fungus grows systemically within the plant, produces toxins, and kills cells and ' +
  'discolors leaf tissue between veins, thus causing striped lesions. When conditions are wet or humid, spores are produced on the ' +
  'surface of leaves at about the time spikes of healthy plants in the field begin to flower. Spores are dispersed by wind to these ' +
  'developing spikes, germinate, and cause infections. Kernels are most susceptible to infection during early development. Only one ' +
  'cycle of infection and spore production occurs each season. We show 2 stages: 1) RISK: That are high risky periods during emergency ' +
  'stages of the plant (in the early stage) and 2) Infection model (during flowering time, barley head development), where we show ' +
  'optimal conditions for sporulation and infections. Whenever 100% infection is reached we had optimal conditions in the field for the ' +
  'fungus to infect plant tissue and grow systematically inside.',
  'GeneralDrechsleraTeres' : 'Primary inoculum develops on death plant residues under humid conditions and optimal temperatures between ' +
  '15 and 25°C (optimum 20°C). Also sunlight is enhancing the growth of conidiophores, following afterwards conditions of around 6 or ' +
  'more hours of darkness lead to optimal growth of conidia (displayed as sporulation, if 100% sporulation is determined we had optimal ' +
  'conditions). Further dispersal takes place via wind, rain or dry leaves.. Infections of plant tissue need humid conditions (leaf ' +
  'wetness/high relative humidity) for about 10 to 30 hours with optimal conditions between 15 and 25°C (displayed as infection, when ' +
  '100% infection are determined optimal conditions have been measured in the field). Altered conditions of humid and dry time periods ' +
  'with high solar radiation and low relative humidity and wind favor the disease development.(Obst und Paul 1993).',
  'GeneralRamularia' : 'Ramularia infection on barley is mostly driven by humid conditions. We start the calculation with a rain event, ' +
  'followed by leaf wetness or relative humidity (higher than 85%). There are two different ways of an infection described: once where ' +
  'just humid conditions promote the infection event (called 1. Infection) and once where global radiation (sunlight) is needed to ' +
  'finish an infection event (called 2. Infection with Light). Further on whenever 100% infection is reached we start to determine the ' +
  'incubation time (so time between the infection and the occurrence of symptoms on leaves). When incubation reach 50% we already ' +
  'recommend to control the field for new inoculum, so new sporulation on the field and when 100% is reached necrotic lesions on leaves ' +
  'are predicted.',
  'GeneralRynchosporium' : 'Spores are distributed by wet conditions, germinate and build an appressorium to enter the plant tissue. For ' +
  'an infection with Rynchosporium a minimum of 7 to 15 hours of leaf wetness is needed, depending on the temperature (optimum around ' +
  '20°C). When we reach 100% infection optimal conditions have been measured and determined in the field. Already before symptoms are ' +
  'visible (incubation finish) new conidia are build in mass. Around one week after infection those new symptoms are visible, which are ' +
  'distributed further. Infection starts on the lower leaves going up to the  upper leaves.',
  'GeneralGaeumannomycesGraminis' : 'The disease “Take all”, caused by the fungal pathogen Gaeumannomyces graminis, is one of the most ' +
  'devastating pathogens in cereals. The pathogen harms the root system and the stem, causing decrease of water and nutrition ' +
  'availability and finally death of the root. Leaves are becoming yellowing and the ear start to mature too early without fully ' +
  'developed kernels. Crop rotation with at least two to three years distance between susceptible cereals is the most important ' +
  'protection strategy. We developed a risk model implementing length of the day and soil temperature for determination of risky ' +
  'periods. Days with lower than 13 hours of sunlight and soil temperatures between 10°C and 20°C favor disease development. When we ' +
  'reach 100% risk optimal conditions for the development of that pathogen have been given in the field.',
  'Cotton/Colletotrichum' : 'C. gossypii is transmitted through seed, and may also overwinter in infected cotton plant debris. ' +
  'Perithecia usually develop in old, dead tissues, and release ascospores which are the primary inoculum source. Usually, only the ' +
  'conidial spore stage is seen on the cotton plant. Secondary spread by conidia occurs in rain and wind and also through a hemipteran' +
  'insect, Dysdercus sp., to be an important vector. Infection needs a rain splash and further wet conditions (leaf wetness, high ' +
  'relative humidity and temperatures between 15°C to 35°C) for 8-10 h. In FieldClimate.com weak, moderate and severe Infections are ' +
  'calculated in dependence of the parameters temperature, leaf wetness and precipitation. Whenever 100 % of infection are reached the ' +
  'conditions for a weak/moderate or severe infection have been fulfilled. In dependence of the variety of the plant and location the ' +
  'farmer has to decide if he/she has to take curative plant protection measurements on a weak, moderate or severe infection into account.'
};

