import { Injectable } from '@angular/core';
import { Route, CanLoad, ActivatedRouteSnapshot, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { ISelectedStationState } from '../../reducers/selectedStation';
import { Store, select } from '@ngrx/store';
import { IStations } from '../../reducers/stations';
import { NavigationService } from './navigation.service';
import { IStation } from '../../models/stations';
import { selectSelectedStation, selectStations } from '../../reducers';
import { filter } from 'rxjs/operators';

@Injectable()
export class NavigationGuard implements CanLoad {

  private stations:           IStation[] = [];
  private selectedStationId:  string = '';

  constructor(
    private selectedStore: Store<ISelectedStationState>,
    private stationsStore: Store<IStations>,
    private navigationService: NavigationService,
    private router: Router
  ) {

    combineLatest([
      this.selectedStore.pipe(select(selectSelectedStation)),
      this.stationsStore.pipe(select(selectStations), filter(stations => Array.isArray(stations)))
    ]).subscribe(([station, stations]) => {
      this.selectedStationId = station.original_name;
      this.stations = stations;
    });

  }

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    return this.hasLicenseForPath(route.routeConfig.path, route.params['id']);
  }

  public canLoad(route: Route): boolean {
    return this.hasLicenseForPath(route.path, this.selectedStationId);
  }

  private hasLicenseForPath(path: string, stationId: string): boolean {
    const station = this.stations.find(s => s.name.original === stationId);
    if (!station) {
      this.router.navigate(['/dashboard']);
      return false;
    } else {
      if (this.navigationService.hasLicenseForPath(path, station)) {
        return true;
      } else {
        this.router.navigate([`/station/${station.name.original}/data`]);
        return false;
      }
    }
  }

}
