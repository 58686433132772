import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {initialIscoutPicturesState} from '../../../core/constants/camera';
import {IscoutSettingsActionTypes} from '../actions/iscout-settings';
import {IIscoutSettingsState} from '../models/iscout.models';
import {IRectangle, IRectangleSelected} from '../../../shared/camera/models/rectangles';

export function reducer(state: IIscoutSettingsState = initialIscoutPicturesState, action: IActionWithPayload): IIscoutSettingsState {
  switch (action.type) {
    case IscoutSettingsActionTypes.RESET_ISCOUT_SETTINGS:
      return {
        ...state,
        selectedPicture: initialIscoutPicturesState.selectedPicture
      };
    case IscoutSettingsActionTypes.SET_ISCOUT_SETTINGS_CURRENT_DATE_STRING:
      return <IIscoutSettingsState>{
        ...state,
        currentDateString: action.payload
      };
    case IscoutSettingsActionTypes.SET_ISCOUT_SETTINGS_SELECTED_PICTURE:
      return <IIscoutSettingsState>{
        ...state,
        selectedPicture: action.payload
      };
    case IscoutSettingsActionTypes.SET_ISCOUT_SETTINGS_TOOLBAR_SETTINGS:
      return <IIscoutSettingsState>{
        ...state,
        toolbarSettings: action.payload
      };
    case IscoutSettingsActionTypes.TOGGLE_ISCOUT_SETTINGS_HELP_IS_ACTIVE:
      return <IIscoutSettingsState>{
        ...state,
        toolbarSettings: {
          ...state.toolbarSettings,
          activity: {
            ...state.toolbarSettings.activity,
            isHelpActive: action.payload
          }
        }
      };
    case IscoutSettingsActionTypes.TOGGLE_ISCOUT_SETTINGS_SAVE_INFO_IS_ACTIVE:
      return <IIscoutSettingsState>{
        ...state,
        toolbarSettings: {
          ...state.toolbarSettings,
          activity: {
            ...state.toolbarSettings.activity,
            isSaveInfoActive: action.payload
          }
        }
      };
    case IscoutSettingsActionTypes.TOGGLE_ISCOUT_SETTINGS_UPDATE_INFO_IS_ACTIVE:
      return <IIscoutSettingsState>{
        ...state,
        toolbarSettings: {
          ...state.toolbarSettings,
          activity: {
            ...state.toolbarSettings.activity,
            isUpdateInfoActive: action.payload
          }
        }
      };
    case IscoutSettingsActionTypes.SET_ISCOUT_SETTINGS_PEST_TOGGLES:
      return <IIscoutSettingsState>{
        ...state,
        pestTogglers: action.payload
      };
    case IscoutSettingsActionTypes.SET_ISCOUT_SETTINGS_PEST_TOGGLE:
      if (state.pestTogglers.length === 0) {
        return <IIscoutSettingsState>{...state, pestTogglers: [action.payload]};
      }

      const foundToggle = state.pestTogglers.find((pestToggle) => pestToggle.name === action.payload.name);
      if (foundToggle === undefined) {
        return <IIscoutSettingsState>{...state, pestTogglers: state.pestTogglers.concat(action.payload)};
      }

      return <IIscoutSettingsState>{
        ...state,
        pestTogglers: state.pestTogglers.map((pestToggle) => {
          if (pestToggle.name === action.payload.name) {
            return action.payload;
          }
          return pestToggle;
        })
      };
    case IscoutSettingsActionTypes.ADD_ISCOUT_SELECTED_PICTURE_RECTANGLE:
      return <IIscoutSettingsState>{
        ...state,
        selectedPicture: {
          ...state.selectedPicture,
          rectangles: [
            ...state.selectedPicture.rectangles,
            action.payload
          ]
        },
      };
    case IscoutSettingsActionTypes.REMOVE_ISCOUT_SELECTED_PICTURE_RECTANGLE:
      return <IIscoutSettingsState>{
        ...state,
        selectedPicture: {
          ...state.selectedPicture,
          rectangles: state.selectedPicture
            .rectangles
            .filter((rect) =>
              rect.generated_by !== 'system' && rect.originalIndex === action.payload.rectangleIndex
            )
            .map((rect) => {
              if (rect.generated_by === 'system' && rect.originalIndex === action.payload.rectangleIndex) {
                return {...rect, hidden: true};
              }
              return rect;
            })
        },
      };
    case IscoutSettingsActionTypes.REMOVE_ISCOUT_SELECTED_PICTURE_MULTIPLE_RECTANGLES:
      return <IIscoutSettingsState>{
        ...state,
        selectedPicture: {
          ...state.selectedPicture,
          rectangles: state.selectedPicture.rectangles.filter((item, index): boolean => {
            const found = action.payload.find((s: IRectangleSelected) => s.rectangleIndex === index);
            return found === undefined;
          })
        },
      };
    case IscoutSettingsActionTypes.UPDATE_ISCOUT_SELECTED_PICTURE_RECTANGLE:
      return <IIscoutSettingsState>{
        ...state,
        selectedPicture: {
          ...state.selectedPicture,
          rectangles: state.selectedPicture.rectangles.map((item, index): IRectangle => {
            if (index !== action.payload.rectangleIndex) {
              return item;
            }
            return {...action.payload.rectangle};
          })
        },
      };
    case IscoutSettingsActionTypes.UPDATE_ISCOUT_SELECTED_PICTURE_MULTIPLE_RETANGLES:
      return <IIscoutSettingsState>{
        ...state,
        selectedPicture: {
          ...state.selectedPicture,
          rectangles: state.selectedPicture.rectangles.map((item, index): IRectangle => {
            const found = action.payload.find((s: IRectangleSelected) => s.rectangleIndex === index);
            if (found === undefined) {
              return item;
            }
            return found.rectangle;
          })
        },
      };
    default:
      return state;
  }
}
