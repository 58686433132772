import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PresetColors } from '../../../sensors-and-nodes/constants/constants';
import { IModelSetting, IModelSettingForm } from '../../models';

@Component({
  selector: '[app-model-row]',
  templateUrl: './model-row.component.html',
  styleUrls: ['./model-row.component.scss']
})
export class ModelRowComponent implements OnInit, OnChanges {

  @Input()
  public setting:       IModelSettingForm;
  @Output()
  public update:        EventEmitter<IModelSetting> = new EventEmitter();
  public formGroup:     FormGroup;
  public presetColors:  string[] = PresetColors;

  constructor(formBuilder: FormBuilder) {

    this.formGroup = formBuilder.group({
      id: [''],
      unit: [''],
      color: ['#000']
    });

  }

  public ngOnInit(): void { }

  public ngOnChanges(changes: SimpleChanges): void {

    if (changes.setting && changes.setting.currentValue) {
      this.formGroup.patchValue(changes.setting.currentValue);
    }

  }

  public colorChange(color: string): void {
    this.formGroup.patchValue({ 'color': color });
  }

}
