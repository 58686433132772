import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, pluck, takeUntil, tap } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { getWeatherForecast, setWeatherForecastLoading } from '../../actions/weather-forecast';
import { setWeatherForecastSetttingsMode } from '../../actions/weather-forecast-settings';
import { DEFAULT_WEATHER_FORECAST_MODE, IWeatherForecastSettingsState, IWeatherForecastState } from '../../models/models';
import { selectWeatherForecastIsError, selectWeatherForecastIsLoading, selectWeatherForecastSettings } from '../../reducers';

@Component({
  selector: 'app-weather-forecast-data',
  templateUrl: './weather-forecast-data.component.html',
  styleUrls: ['./weather-forecast-data.component.scss']
})
export class WeatherForecastDataComponent implements OnInit, OnDestroy {
  public isLoading$: Observable<boolean>;
  public isError$: Observable<boolean>;
  public mode: string;
  public exportChartImg: boolean = false;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private stationId: string;

  constructor(private weatherForecastStore: Store<IWeatherForecastState>,
              private weatherForecastSettingsStore: Store<IWeatherForecastSettingsState>,
              private navigationStationStore: Store<INavigationStationState>) { }

  public ngOnInit(): void {
    this.listenWeatherForecastStatuses();
    this.listenWeatherForecastSettings();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public changeMode(mode: string): void {
    if (this.stationId) {
      this.weatherForecastSettingsStore.dispatch(setWeatherForecastSetttingsMode({
        stationId: this.stationId,
        mode: mode
      }));
    }
  }

  public exportCharts(value : boolean): void {
    this.exportChartImg = value;
  }

  private listenWeatherForecastStatuses(): void {
    this.isLoading$ = this.weatherForecastStore.pipe(select(selectWeatherForecastIsLoading));
    this.isError$ = this.weatherForecastStore.pipe(select(selectWeatherForecastIsError));
  }

  private listenWeatherForecastSettings(): void {
    combineLatest([
      this.listenToStationChange(),
      this.listenToSettingsChange()]
    ).pipe(
      takeUntil(this.destroy$),
      tap((result: Array<string|IWeatherForecastSettingsState>): void => {
        this.stationId = <string>result[0];
      }),
      map((result: Array<string|IWeatherForecastSettingsState>): IWeatherForecastSettingsState =>
        <IWeatherForecastSettingsState>result[1]),
      tap((weatherForecastSettings: IWeatherForecastSettingsState): void => {
        if (this.modeIsChanged(weatherForecastSettings)) {
          this.setNewMode(weatherForecastSettings);
        }
      })
    ).subscribe((): void => {
      this.refreshWeatherForecastData();
    });
  }

  private listenToStationChange(): Observable<string> {
    return this.navigationStationStore.pipe(
      select(selectNavigationStation),
      filter((station: IStation): boolean => !!station),
      distinctUntilChanged((a: IStation, b: IStation): boolean => a.name.original === b.name.original),
      pluck('name', 'original')
    );
  }

  private listenToSettingsChange(): Observable<IWeatherForecastSettingsState> {
    return this.weatherForecastSettingsStore.pipe(
      takeUntil(this.destroy$),
      select(selectWeatherForecastSettings),
    );
  }

  private modeIsChanged(weatherForecastSettings: IWeatherForecastSettingsState): boolean {
    return !weatherForecastSettings.settings[this.stationId]
      || (weatherForecastSettings.settings[this.stationId].selectedMode !== this.mode);
  }

  private setNewMode(weatherForecastSettings: IWeatherForecastSettingsState): void {
      this.mode = weatherForecastSettings.settings[this.stationId] && weatherForecastSettings.settings[this.stationId].selectedMode
        ? weatherForecastSettings.settings[this.stationId].selectedMode
        : DEFAULT_WEATHER_FORECAST_MODE.value;
  }

  private refreshWeatherForecastData(): void {
    this.weatherForecastStore.dispatch(setWeatherForecastLoading(true));
    this.weatherForecastStore.dispatch(getWeatherForecast({
      stationId: this.stationId,
      mode: this.mode
    }));
  }
}
