import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ICameraPest, IDeleteCameraPestBody } from '../../../../../shared/camera/models/camera';
import { ISCOUT_PESTS_DELETE_MODAL_ID } from '../../../../../shared/constants';
import { ModalService } from '../../../../../shared/modal/services/modal.service';
import { generateId } from '../../../../dashboard/utils/makeWidget';
import { IIscoutPestsState, IIscoutSettingsState, IscoutPestType } from '../../../models/iscout.models';
import { selectIscoutGeneralPests, selectIscoutPestList } from '../../../reducers';
import { IThemeConfig } from '../../../../../../environments/interfaces/theme';
import { environmentToken } from '../../../../../../environments/environment';
import { IEnvironment } from '../../../../../../environments/interfaces/environment';

@Component({
  selector: 'app-iscout-pests-list',
  templateUrl: './iscout-pests-list.component.html',
  styleUrls: ['./iscout-pests-list.component.scss']
})
export class IscoutPestsListComponent implements OnInit {
  @Input()
  public displaySystemPests: boolean;
  @Output()
  public displaySystemPestsToggled = new EventEmitter<boolean>();
  @Output()
  public pestAdded = new EventEmitter<void>();
  @Output()
  public pestEdited = new EventEmitter<ICameraPest>();
  @Output()
  public pestRemoved = new EventEmitter<IDeleteCameraPestBody>();

  public pestsDeleteModalId       = ISCOUT_PESTS_DELETE_MODAL_ID;
  public pestSelection            : ICameraPest = null;
  public pestFilter               : FormControl;
  public pestFilter$              : Observable<string>;
  public pestList$                : Observable<Array<ICameraPest>>;
  public helpModalId              : string = generateId();
  public subDomain                : IThemeConfig;

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    private iscoutStore           : Store<IIscoutPestsState>,
    private iscoutSettingsStore   : Store<IIscoutSettingsState>,
    private modalService          : ModalService
  ) {
    this.pestFilter = new FormControl('');
    this.pestFilter$ = this.pestFilter.valueChanges.pipe(startWith(''));
    this.subDomain = environment.theme;
  }

  public ngOnInit(): void {
    this.pestList$ = combineLatest([
      this.iscoutStore.pipe(select(selectIscoutGeneralPests)),
      this.iscoutStore.pipe(select(selectIscoutPestList)),
      this.pestFilter$
    ]).pipe(
      map(([generalPests, userPests, pestFilter]): Array<ICameraPest> => {
        return userPests.slice().concat(generalPests).filter(
          (pest) => pest.name.toLowerCase().indexOf(pestFilter.toLowerCase()) !== -1
        );
      })
    );
  }

  public onToggleHelp(): void {
    this.modalService.openModal(this.helpModalId);
  }

  public onPestAdd(): void {
    this.pestAdded.emit(null);
  }

  public onPestEdit(selectedPest: ICameraPest): void {
    this.pestEdited.emit(selectedPest);
  }

  public onPestRemove(selected: ICameraPest): void {
    this.pestSelection = selected;
    this.modalService.openModal(this.pestsDeleteModalId);
  }

  public onPestRemoveConfirmation(): void {
    this.pestRemoved.emit({pest_id: this.pestSelection._id});
    this.onCloseModal(this.pestsDeleteModalId);
  }

  public onCloseModal(pestsModal: string): void {
    this.modalService.closeModal(pestsModal);
    this.pestSelection = null;
  }

  public toggleDisplaySystemPests(): void {
    this.displaySystemPestsToggled.emit(!this.displaySystemPests);
  }

  public shouldDisplayPest(pest: ICameraPest): boolean {
    return !this.isSystemPest(pest) || (this.isSystemPest(pest) && this.displaySystemPests === true);
  }

  public isSystemPest(pest: ICameraPest): boolean {
    return pest.type === IscoutPestType.SYSTEM;
  }
}
