<app-form-layout [shadow]="false">
  <div class="disclaimer-padding">
    <app-custom-expiry-warning [license]="activeLicense" [FVModule]="'yieldPrediction'"></app-custom-expiry-warning>
  </div>
  <div *ngIf="showYieldHelpText == 'true'" class="disclaimer-padding">
    <div class="alert alert-success disclaimer-text xContainer">
      <section>
        <p>
          <b>{{ 'Please note:' | translate }}</b>
          {{ 'The predicted yield values represent an estimate of potential yield at harvest. This estimate is ' +
          'largely based on estimates (measured, historic average, and forecast) of precipitation. Other ' +
          'yield-limiting factors such as pests, diseases, soil fertility, and others are (currently) not ' +
          'considered. There is no guarantee that your actual yield at harvest will be within the presented ' +
          'ranges. However, the predicted values can serve as an in-season indication whether the crop\'s ' +
          'yield performance is likely to fall below or beat the historic average. Use this additional ' +
          'information when making management decisions such as fertilization or irrigation.' | translate }}
        </p>
      </section>
      <div class="close-description" (click)="closeDescription()">
        <i class="zmdi zmdi-close closeButton"></i>
      </div>
    </div>
  </div>
  <div class="yield-prediction">
    <ng-container *ngIf="yieldSource; else addYieldConfig">
      <section class="wrapper yield-prediction__end">
        <section>
          <section class="flex-row-direction">
            <section class="flex-row-direction padding-r30">
              <label class="yield-prediction-blabel"><b>{{ 'Crop:' | translate }} </b></label>
              <label class="yield-prediction__label" *ngIf="yieldSource.yield_prediction">
                {{ cropType[0]?.content | titlecase | translate  }}
              </label>
            </section>
          </section>
          <section class="flex-row-direction">
            <section class="flex-row-direction padding-r30">
              <label class="yield-prediction-blabel"><b>{{ 'Rain station:' | translate }} </b></label>
              <label class="yield-prediction__label" *ngIf="yieldSource.yield_prediction">
                {{ stationId }} 
              </label>
            </section>
            <section class="flex-row-direction padding-r30">
              <label class="yield-prediction-blabel"><b>{{ 'Air temperature station:' | translate }} </b></label>
              <label class="yield-prediction__label" *ngIf=" yieldSource?.source?.device_id">
                {{ yieldSource.source?.device_id[0] }} </label>
            </section>
          </section>
          <!-- <br /> -->
          <section class="flex-row-direction padding-r30">
            <label class="yield-prediction-blabel"><b>{{ 'Report for:' | translate }} </b></label>
            <label class="yield-prediction__label" *ngIf=" yieldSource.yield_prediction">
              {{ cropType[0]?.content | titlecase | translate }},&nbsp;
              <span>{{ 'generated on' | translate }}</span>&nbsp;
              <span *ngIf="currentDate">{{ currentDate | date : 'mediumDate' }}</span>
            </label>
          </section>
          <section class="flex-row-direction padding-r30">
            <label class="yield-prediction-blabel"><b>{{ 'Sowing date:' | translate }} </b></label>
            <label class="yield-prediction__label" *ngIf=" yieldSource.yield_prediction">
              {{ yieldSource.yield_prediction.sowing_date | date: 'mediumDate' }}
            </label>
          </section>
          <section class="flex-row-direction padding-r30">
            <label class="yield-prediction-blabel"><b>{{ 'Expected harvest date:' | translate }} </b></label>
            <label class="yield-prediction__label" *ngIf=" yieldSource.yield_prediction">
              {{ yieldSource.yield_prediction.harvest_date_user_estimate | date: 'mediumDate' }}
            </label>
          </section>
        </section>
      </section>
      <div class="yield-prediction__break"></div>
      <section *ngIf="stationForecastList?.includes(stationId); else noActiveWF">
        <app-yield-prediction-graph class="yield-prediction__container" [chartData]="chartData"></app-yield-prediction-graph>
      </section>
      <ng-template #noActiveWF>
          <h4 class="no-data-warning"><i class="fa fa-warning fa-fw"></i> {{ 'Could not run Yield Prediction. Please review the Yield Prediction settings.' | translate}}</h4>
    </ng-template>
      <div class="yield-prediction__break"></div>
      <section *ngIf="stationForecastList?.includes(stationId)" class="yield-prediction__end">
        <section class="flex-row-direction space-between">
          <section class="flex-row-direction flex-inline padding-r30">
            <label class="yield-prediction-blabel"><b>{{ 'Predicted crop physiological maturity:' | translate }}
              </b></label>
            <label class="yield-prediction__label" *ngIf="physiologicalMaturityDate">
              {{ physiologicalMaturityDate | date: 'mediumDate' }}
            </label>
          </section>
          <i class="zmdi zmdi-help help-icon" (click)="openModal()"></i>
        </section>
        <section>
          <section class="flex-row-direction padding-r30">
            <label class="yield-prediction-blabel"><b>{{ 'Yield “today” (current season):' | translate }} </b></label>
            <label class="yield-prediction__label">{{ currentYield }} &nbsp;
              <span *ngIf="unitSystem === 'imperial' else metricUnit">bu/ac</span>
              <ng-template #metricUnit>t/ha</ng-template>
            </label>
          </section>
          <small>{{ 'Today\'s yield potential based on actual available water to date (station measured precipitation).' | translate }}</small>
        </section>
        <section>
          <section class="flex-row-direction padding-r30">
            <label class="yield-prediction-blabel"><b>{{ 'Predicted yield at harvest (normal season):' | translate }} </b></label>
            <label class="yield-prediction__label">{{ predictedNormalYield }} &nbsp;
              <span *ngIf="unitSystem === 'imperial' else metricUnit">bu/ac</span>
              <ng-template #metricUnit>t/ha</ng-template>
            </label>
          </section>
          <small>{{ 'Seasonal yield potential based on 35 year weather averages to physiological maturity.' | translate }}</small>
        </section>
        <section>
          <section class="flex-row-direction padding-r30">
            <label class="yield-prediction-blabel"><b>{{ 'Predicted yield at harvest (current season + rain forecast):' | translate }} </b></label>
            <label class="yield-prediction__label" *ngIf=" yieldSource.yield_prediction">
              {{ predictedYield | translate }}&nbsp;
              <span *ngIf="unitSystem === 'imperial' else metricUnit">bu/ac</span>
              <ng-template #metricUnit>t/ha</ng-template>
            </label>
          </section>
          <small>{{ 'Yield potential based on actual available water to date and seasonal forecast to physiological maturity.' | translate }}</small>
        </section>
      </section>
    </ng-container>
    <ng-template #addYieldConfig>
      <section class="centered">
        <h2>{{ 'Please add yield prediction config in cropzone settings' | translate }}</h2>
      </section>
    </ng-template>
  </div>
</app-form-layout>

<app-info-modal [modalId]="modalId" [header]="'Yield Prediction'">
  <app-yield-prediction-help-text-modal></app-yield-prediction-help-text-modal>
</app-info-modal>