import { CropzoneConfigRoutingModule } from './cropzone-config-routing.module';
import { CropzoneConfigEffects } from './effects/cropzone-config.effects';
import { AgmCoreModule } from '@agm/core';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './reducers';
import { TranslateModule } from '@ngx-translate/core';
import { CropzoneConfigComponent } from './cropzone-config.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { CropzoneDataFormComponent } from './containers/cropzone-data-form/cropzone-data-form.component';
import { CropzoneDataComponent } from './containers/cropzone-data/cropzone-data.component';
import { CropzoneConfigLocationComponent } from './containers/cropzone-config-location/cropzone-config-location.component';
import { CropzoneMapFormComponent } from './containers/cropzone-map-form/cropzone-map-form.component';
import { CropzoneConfigHelpTextModalComponent } from './containers/cropzone-config-help-text-modal/cropzone-config-help-text-modal.component';
import { CropzoneConfigLocationHelpTextModalComponent } from './containers/cropzone-config-location-help-text-modal/cropzone-config-location-help-text-modal.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { CropzoneConfigMapComponent } from './containers/cropzone-config-map/cropzone-config-map.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CropzoneConfigRoutingModule,
    TranslateModule.forChild(),
    StoreModule.forFeature('config', reducers),
    EffectsModule.forFeature([CropzoneConfigEffects]),
    AgmCoreModule,
  ],
  declarations: [
    CropzoneConfigComponent,
    CropzoneDataFormComponent,
    CropzoneDataComponent,
    CropzoneConfigLocationComponent,
    CropzoneMapFormComponent,
    CropzoneConfigHelpTextModalComponent,
    CropzoneConfigLocationHelpTextModalComponent,
    CropzoneConfigMapComponent
  ]
})
export class CropzoneConfigModule {
}
