import {Chart} from 'angular-highcharts';
import {PlotEvents, SubtitleOptions, TitleOptions} from 'highcharts';


const title: SubtitleOptions = {
  useHTML: true,
  text: '<span>Left click and drag to zoom</span>',
  align: 'left',
  x: 70,
  y: 20,
  style: {
    color: '#333333',
    padding: '2px 5px',
    background: '#f7f7f7',
    'border': '1px solid #cccccc',
    'border-radius': '3px',
    'font-size': '11px',
    'font-weight': 'bold'
  }
};

const emptyTitle: TitleOptions = {
  text: '',
  style: ''
};

export function setZoomTitle(c : Chart): void {
  let timerOut: any = null;
  let isOver: boolean = false;

  const events: PlotEvents = {
      mouseOver: () => {
        clearTimeout(timerOut);
        if (!isOver) {
          isOver = true;
          c.ref.subtitle.update(title);
          setTimeout(() => {
            if (c.ref) {
              c.ref.subtitle.update(emptyTitle);
            }
          }, 3000);
        }
      },
      mouseOut: () => {
        timerOut = setTimeout(() => {
          c.ref.subtitle.update(emptyTitle);
        }, 0);
      }
  };

  if (c.options.plotOptions && c.options.plotOptions.series) {
    c.options.plotOptions.series.events = {
      ...c.options.plotOptions.series.events,
      ...events
    };
  } else {
    c.options.plotOptions = {
      series: {
        events: events
      }
    };
  }
}

