import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { selectUltrasonicSettings } from '../../selectors/selectors';
import { IUltrasonicDistanceSettingsState, IUltrasonicSetting } from '../../models/models';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-ultrasonic-distance-settings-archived',
  templateUrl: './ultrasonic-distance-settings-archived.component.html',
  styleUrls: ['./ultrasonic-distance-settings-archived.component.scss']
})
export class UltrasonicDistanceSettingsArchivedComponent implements OnInit, OnDestroy {

  public ultrasonicSettings     : Array<IUltrasonicSetting> = [];

  private destroy$              : Subject<boolean> = new Subject<boolean>();

  constructor(
    private ultrasonicStore     : Store<IUltrasonicDistanceSettingsState>
  ) { }

  public ngOnInit(): void {
    this.ultrasonicStore.pipe(
      takeUntil(this.destroy$),
      select(selectUltrasonicSettings)
    ).subscribe((ultrasonicSettings: Array<IUltrasonicSetting>) => {
      if (ultrasonicSettings) {
        this.ultrasonicSettings = ultrasonicSettings;
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
