import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ADD_CROPZONE } from '../../../../../shared/constants';
import { ModalService } from '../../../../../shared/modal/services/modal.service';
import { setNotify } from '../../../../actions/notify';
import {
  IAddCropZone,
  IAddCropZoneField,
  IAddCropZoneFieldFarm, IAddCropZoneRequest, IAddCropZoneType, ICropZone, IFarm
} from '../../../../models/cropzones';
import { IField } from '../../../../models/fields';
import { selectCropzones, selectFarms, selectFields } from '../../../../reducers';
import * as fromCropZones from '../../../../reducers/cropzones';
import * as fromFarms from '../../../../reducers/farms';
import * as fromFields from '../../../../reducers/fields';
import * as fromNotify from '../../../../reducers/notify';

@Component({
  selector: 'app-add-cropzone-form',
  templateUrl: './add-cropzone-form.component.html',
  styleUrls: ['./add-cropzone-form.component.scss']
})
export class AddCropzoneFormComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public selectedFarm: IFarm = {name: '', id: ''};
  public selectedField: IField = {name: '', id: '', farm: {name: '', id: ''}};
  public farmList: IFarm[];
  public fields: IField[];
  public fieldList: IField[];
  public cropzoneList: ICropZone[];
  private alive$ = new Subject<boolean>();

  @Output()
  private submit: EventEmitter<IAddCropZoneRequest> = new EventEmitter<IAddCropZoneRequest>();

  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
    private farmStore: Store<fromFarms.IFarms>,
    private fieldStore: Store<fromFields.IFields>,
    private CZstore: Store<fromCropZones.ICropZones>,
    private notifyStore: Store<fromNotify.INotifyState>
  ) {}

  public get farm(): AbstractControl {
    return this.form.get('farm');
  }

  public get field(): AbstractControl {
    return this.form.get('field');
  }

  public get cropzone(): AbstractControl {
    return this.form.get('cropzone');
  }

  public get submitColor(): string {
    return 'green';
  }

  public get submitContent(): string {
    return 'Add cropzone';
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      'farm': ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      'field': ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      'cropzone': ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]]
    });


    this.fieldStore.pipe(
      select(selectFields),
      takeUntil(this.alive$)
    ).subscribe(data => {
      this.fieldList = data;
    });

    this.farmStore.pipe(
      select(selectFarms),
      takeUntil(this.alive$)
    ).subscribe(data => {
      this.farmList = data;
    });

    this.CZstore.pipe(
      select(selectCropzones),
      takeUntil(this.alive$)
    ).subscribe(data => {
      this.cropzoneList = data;
    });

    this.onChanges();
  }

  public onChanges(): void {
    this.form.get('farm').valueChanges.pipe(
      takeUntil(this.alive$)
    ).subscribe(val => {
      this.getFieldsFromFarmName(val);
    });
  }

  public onSelect(event: TypeaheadMatch): void {
    this.selectedFarm = event.item;
    this.getFieldsFromFarmId(this.selectedFarm.id);
  }

  private getFieldsFromFarmName(name: string): void {
    this.fields = this.fieldList.filter(field => field.farm.name === name);
  }

  private getFieldsFromFarmId(id: string): void {
    this.fields = this.fieldList.filter(field => field.farm.id === id);
  }

  public onSelectField(event: TypeaheadMatch): void {
    this.selectedField = event.item;
  }

  public submitEmit(): void {
    if (!this.farmList.some((farm) => farm.name === this.form.value.farm)) {
      // new farm
      const object: IAddCropZoneFieldFarm = {
        type: IAddCropZoneType.ADD_CROPZONE_AND_FIELD_AND_FARM,
        name: this.form.value.cropzone,
        crop_name: '',
        from: '',
        to: '',
        field: {
          name: this.form.value.field
        },
        farm: {
          name: this.form.value.farm
        }
      };

      this.submit.emit(object);
    } else {
      // existing farm
      const fieldsFromFarm: IField[] = this.fieldList.filter((field) => field.farm.name === this.form.value.farm);

      if (!fieldsFromFarm.some((field) => field.name === this.form.value.field)) {
        // new field from existing farm
        const existingFarm: IFarm = this.farmList.filter(farm => farm.name === this.form.value.farm)[0];

        const object: IAddCropZoneField = {
          type: IAddCropZoneType.ADD_CROPZONE_AND_FIELD,
          name: this.form.value.cropzone,
          crop_name: '',
          from: '',
          to: '',
          field: {
            name: this.form.value.field
          },
          farm: {
            name: existingFarm.name,
            id: existingFarm.id
          }
        };

        this.submit.emit(object);
      } else {
        // existing field from existing farm
        const cropzonesFromField: ICropZone[] = this.cropzoneList.filter((cropzone) => cropzone.field.name === this.form.value.field);

        if (!cropzonesFromField.some((cropzone) => cropzone.name === this.form.value.cropzone)) {
          // new cropzone from existing field and existing farm
          const existingFieldOfExistingFarm: IField = this.fieldList.filter(field => field.farm.name === this.form.value.farm &&
            field.name === this.form.value.field)[0];

          const cropZoneObject: IAddCropZone = {
            type: IAddCropZoneType.ADD_CROPZONE,
            name: this.form.value.cropzone,
            crop_name: '',
            from: '',
            to: '',
            field: {
              name: existingFieldOfExistingFarm.name,
              id: existingFieldOfExistingFarm.id
            },
            farm: {
              name: existingFieldOfExistingFarm.farm.name,
              id: existingFieldOfExistingFarm.farm.id
            }
          };

          this.submit.emit(cropZoneObject);
        } else {
          // existing cropzone from existing field and existing farm
          this.notifyStore.dispatch(setNotify('Unable to create the cropzone. This farm already has a field and cropzone with this name.'));
        }
      }
    }
  }

  public close(): void {
    this.modalService.closeModal(ADD_CROPZONE);
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }
}
