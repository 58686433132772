import {Pipe, PipeTransform} from '@angular/core';
import {IStation} from '../../../../core/models/stations';
import {IOptions} from '../../../../shared/interfaces';

@Pipe({
  name: 'stationsOptions'
})
export class StationsOptionsPipe implements PipeTransform {

  public transform(stations: Array<IStation>): Array<IOptions> {
    return Array.isArray(stations) ? stations.map((item) => {
      return {
        value: item.name.original,
        content: item.name.custom ? `${item.name.custom} [${item.name.original}]` : item.name.original
      };
    })
    : [];
  }

}
