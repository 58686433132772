<app-form-layout [shadow]="false">
  <div class="tracker-definition-warning" *ngIf="!validSettingsData">
    <alert type="warning">
      <strong>{{ 'Warning!' | translate }}</strong> {{ 'Work Tracking definitions are not set, please go to' | translate }} <strong>{{ 'Station settings (click' | translate }}<i
          class="zmdi zmdi-hc-fw zmdi-settings"></i> {{ 'in the left menu) -> Work Tracking definition' | translate }}</strong> {{ 'and fill out the data required.' | translate }} 
    </alert>
  </div>
  <div class="station-data-header" *ngIf="period$ | async">
    <div>
      <h2>{{ 'Work Tracking map' | translate }}</h2>
      <small>{{ 'map of work tracking locations' | translate }}</small>
    </div>
    <div>
      <p *ngIf="(period$ | async).fromTo">
        {{ 'Work Tracking data from' | translate }}
        <span>{{ ((period$ | async).isLastDataMode ? (period$ | async).fromTo?.from : (period$ | async).fromDatepicker) | date:format }}</span>
        {{'to' | translate}}
        <span>{{ ((period$ | async).isLastDataMode ? (period$ | async).fromTo?.to : (period$ | async).toDatepicker) | date:format }}</span>
      </p>
    </div>
  </div>
</app-form-layout>