import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { WorkPlanningActionTypes } from '../actions';
import { IWorkPlanningSettingsPayload, IWorkPlanningSettingsState } from '../models/work-planning.models';
import { WorkPlanningSettingsHelper } from './helpers/work-planning-settings-helper';

export const initialState: IWorkPlanningSettingsState = {
    settings: {},
    activity: {
      isChartActive: true,
      isTableActive: true,
      imageExportInProgress: false,
    },
  };

  export function reducer(state: IWorkPlanningSettingsState = initialState, action: IActionWithPayload): IWorkPlanningSettingsState {
    switch (action.type) {
      case WorkPlanningActionTypes.SET_WORK_PLANNING_SETTINGS_IS_CHART_ACTIVE:
        return <IWorkPlanningSettingsState>{
          ...state,
          activity: {
            ...state.activity,
            isChartActive: action.payload
          }
        };
      case WorkPlanningActionTypes.SET_WORK_PLANNING_SETTINGS_IS_TABLE_ACTIVE:
        return <IWorkPlanningSettingsState>{
          ...state,
          activity: {
            ...state.activity,
            isTableActive: action.payload
          }
        };
      case WorkPlanningActionTypes.SET_WORK_PLANNING_IMAGE_EXPORT_IN_PROGRESS:
        return <IWorkPlanningSettingsState>{
          ...state,
          activity: {
            ...state.activity,
            imageExportInProgress: action.payload
          }
        };
      case WorkPlanningActionTypes.SET_WORK_PLANNING_SETTINGS:
        const settings: IWorkPlanningSettingsPayload = <IWorkPlanningSettingsPayload>action.payload;
        const settingsHelper = new WorkPlanningSettingsHelper(state);
        if (settingsHelper.stateHasDisabledGroupId(settings)) {
          settingsHelper.enableGroupId(settings);
        } else {
          settingsHelper.disableGroupId(settings);
        }
        return {...state};
      case WorkPlanningActionTypes.SET_WORK_PLANNING_SETTINGS_MODE:
        const payload: IWorkPlanningSettingsPayload = <IWorkPlanningSettingsPayload>action.payload;
        if (!state.settings[payload.stationId]) {
          state.settings[payload.stationId] = {
            modes: {
              [payload.mode]: {
                disabledGroupIds: {}
              }
            },
            selectedMode: payload.mode
          };
        }
        if (!state.settings[payload.stationId].modes[payload.mode]) {
          state.settings[payload.stationId].modes[payload.mode] = {disabledGroupIds: {}};
        }
        state.settings[payload.stationId].selectedMode = payload.mode;
        return {...state};
      default:
        return state;
    }
  }
