import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {ApiCallService} from '../../../services/api/api-call.service';
import {Observable, of} from 'rxjs';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {setUserLicenses, UserLicensesActionTypes} from '../actions';
import {catchError, switchMap} from 'rxjs/operators';
import {setNotify} from '../../../core/actions/notify';

@Injectable()
export class UserLicensesService {
  constructor(private api: ApiCallService,
              private actions$: Actions) { }

  @Effect()
  public getUserLicenses$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(UserLicensesActionTypes.GET_USER_LICENSES),
    switchMap(() => this.api.getActiveUserLicenses().pipe(
      switchMap((resp: any) => of(setUserLicenses(resp))),
      catchError(() => of(setNotify('Could not fetch the user licenses')))
    ))
  );
}
