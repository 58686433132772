import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-weather-forecast-history-climate-help-text-model',
  templateUrl: './weather-forecast-history-climate-help-text-model.component.html',
  styleUrls: ['./weather-forecast-history-climate-help-text-model.component.scss']
})
export class WeatherForecastHistoryClimateHelpTextModelComponent implements OnInit, OnChanges {

  @Input()
  public subtitle: string;

  constructor() { }

  public ngOnInit(): void {
  }

  public ngOnChanges(): void {
    this.subtitle = this.subtitle.split(' ').join('').toLocaleUpperCase();
  }
}
