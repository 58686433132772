import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {
  ICameraPest,
  IClearMeasurementsPayload, IClearMeasurementsResponse,
  IDeleteImagePayload,
  IGetPhotosRequestFromTo,
  IGetPhotosRequests,
  IPictureSet,
  IPostCameraPestPayload,
  ISaveMeasurements,
  ISaveMeasurementsPayload
} from '../../../shared/camera/models/camera';
import * as moment from 'moment';

export const enum IscoutActionTypes {
  GET_ISCOUT_PHOTOS = '[Iscout] GET_ISCOUT_PHOTOS',
  SET_ISCOUT_PHOTOS = '[Iscout] SET_ISCOUT_PHOTOS',
  SET_ISCOUT_PHOTOS_LOADING = '[Iscout] SET_ISCOUT_PHOTOS_LOADING',
  SET_ISCOUT_PHOTOS_ERROR = '[Iscout] SET_ISCOUT_PHOTOS_ERROR',
  SET_ISCOUT_PHOTO_MEASUREMENTS = '[Iscout] SET_ISCOUT_MEASURMENT_PICTURE_UPDATE',
  SAVE_ISCOUT_PHOTOS_MEASUREMENTS = '[Iscout] SAVE_ISCOUT_PHOTOS_MEASUREMENTS',
  CLEAR_ISCOUT_PHOTOS_MEASUREMENTS = '[Iscout] CLEAR_ISCOUT_PHOTOS_MEASUREMENTS',
  EMPTY_ISCOUT_PHOTO_MEASUREMENTS = '[Iscout] EMPTY_ISCOUT_PHOTO_MEASUREMENTS',
  DELETE_ISCOUT_PHOTOS_IMAGE = '[Iscout] DELETE_ISCOUT_PHOTOS_IMAGE',
  GET_ISCOUT_USER_PESTS = '[Iscout] GET_ISCOUT_USER_PESTS',
  SET_ISCOUT_USER_PESTS = '[Iscout] SET_ISCOUT_USER_PESTS',
  GET_ISCOUT_GENERAL_PESTS = '[Iscout] GET_ISCOUT_GENERAL_PESTS',
  SET_ISCOUT_GENERAL_PESTS = '[Iscout] SET_ISCOUT_GENERAL_PESTS',
  ADD_ISCOUT_PEST = '[Iscout] ADD_ISCOUT_PEST',
  REMOVE_ISCOUT_PEST = '[Iscout] REMOVE_ISCOUT_PEST',
  GET_ISCOUT_FIRST_DATE = '[Iscout] GET_ISCOUT_FIRST_DATE',
  SET_ISCOUT_FIRST_DATE = '[Iscout] SET_ISCOUT_FIRST_DATE',
  GET_ISCOUT_LAST_DATE = '[Iscout] GET_ISCOUT_LAST_DATE',
  SET_ISCOUT_LAST_DATE = '[Iscout] SET_ISCOUT_LAST_DATE',
}

export function getIscoutPhotos(data: IGetPhotosRequests | IGetPhotosRequestFromTo): IActionWithPayload {
  return {type: IscoutActionTypes.GET_ISCOUT_PHOTOS, payload: data};
}

export function setIscoutPhotos(data: IPictureSet): IActionWithPayload {
  return {type: IscoutActionTypes.SET_ISCOUT_PHOTOS, payload: data};
}

export function setIscoutLoading(status: boolean): IActionWithPayload {
  return {type: IscoutActionTypes.SET_ISCOUT_PHOTOS_LOADING, payload: status};
}

export function setIscoutError(status: boolean): IActionWithPayload {
  return {type: IscoutActionTypes.SET_ISCOUT_PHOTOS_ERROR, payload: status};
}

export function setIscoutMeasurements(data: ISaveMeasurements): IActionWithPayload {
  return {type: IscoutActionTypes.SET_ISCOUT_PHOTO_MEASUREMENTS, payload: data};
}

export function emptyIscoutMeasurements(data: IClearMeasurementsResponse): IActionWithPayload {
  return {type: IscoutActionTypes.EMPTY_ISCOUT_PHOTO_MEASUREMENTS, payload: data};
}

export function saveIscoutMeasurements(data: ISaveMeasurementsPayload): IActionWithPayload {
  return {type: IscoutActionTypes.SAVE_ISCOUT_PHOTOS_MEASUREMENTS, payload: data};
}

export function clearIscoutMeasurements(data: IClearMeasurementsPayload): IActionWithPayload {
  return {type: IscoutActionTypes.CLEAR_ISCOUT_PHOTOS_MEASUREMENTS, payload: data};
}

export function deleteIscoutImage(data: IDeleteImagePayload): IActionWithPayload {
  return {type: IscoutActionTypes.DELETE_ISCOUT_PHOTOS_IMAGE, payload: data};
}

export function getIscoutUserPests(stationId: string): IActionWithPayload {
  return {type: IscoutActionTypes.GET_ISCOUT_USER_PESTS, payload: stationId};
}

export function setIscoutUserPests(data: Array<ICameraPest>): IActionWithPayload {
  return {type: IscoutActionTypes.SET_ISCOUT_USER_PESTS, payload: data};
}

export function getIscoutGeneralPests(stationId: string): IActionWithPayload {
  return {type: IscoutActionTypes.GET_ISCOUT_GENERAL_PESTS, payload: stationId};
}

export function setIscoutGeneralPests(data: Array<ICameraPest>): IActionWithPayload {
  return {type: IscoutActionTypes.SET_ISCOUT_GENERAL_PESTS, payload: data};
}

export function addIscoutPest(data: IPostCameraPestPayload): IActionWithPayload {
  return {type: IscoutActionTypes.ADD_ISCOUT_PEST, payload: data};
}

export function removeIscoutPest(data: IPostCameraPestPayload): IActionWithPayload {
  return {type: IscoutActionTypes.REMOVE_ISCOUT_PEST, payload: data};
}

export function getIscoutFirstDate(stationId: string): IActionWithPayload {
  return {type: IscoutActionTypes.GET_ISCOUT_FIRST_DATE, payload: stationId};
}

export function setIscoutFirstDate(firstDate: moment.Moment): IActionWithPayload {
  return {type: IscoutActionTypes.SET_ISCOUT_FIRST_DATE, payload: firstDate};
}

export function getIscoutLastDate(stationId: string): IActionWithPayload {
  return {type: IscoutActionTypes.GET_ISCOUT_LAST_DATE, payload: stationId};
}

export function setIscoutLastDate(lastDate: moment.Moment): IActionWithPayload {
  return {type: IscoutActionTypes.SET_ISCOUT_LAST_DATE, payload: lastDate};
}
