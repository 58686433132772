<app-form-layout *ngIf="!timezoneOnly" class="section timezone">
    <app-card-header [header]="'Timezone and location'"
                     [helpButton]="true"
                     (openModal)="openModal()"
                     [description]="'Be aware that precise location and time-zone'+
                     ' settings are essential for proper functioning of weather fo'+
                     'recast and other services'">
    </app-card-header>
    <div class="timezone__content">
      <div class="row">
        <!--
        <app-timezone-leaflet-map class="col-md-8"
                                  [position]="locationConfig.position"
                                  [id]="currentStation.info.device_id"
                                  *ngIf="locationConfig">
        </app-timezone-leaflet-map>
        -->
        <app-timezone-map class="col-md-8"
                          *ngIf="locationConfig"
                          [position]="locationConfig.position"
                          [id]="currentStation.info.device_id">
        </app-timezone-map>
        <app-timezone-form class="col-md-4"
                           [settings]="locationConfig"
                           [isIngestedStation]="isIngestedStation"
                           [station]="currentStation">
        </app-timezone-form>
      </div>
      <div *ngIf="currentStation.rights === 'rw' && !isIngestedStation"
            class="timezone__save">
        <app-custom-button [color]="'red'"
                           (click)="save()"
                           [content]="'Save location'">
        </app-custom-button>
      </div>
    </div>
  </app-form-layout>

  <app-info-modal [modalId]="modalId"
                  [header]="'Timezone and location settings help'">
    <app-timezone-and-location-modal></app-timezone-and-location-modal>
  </app-info-modal>

  <app-form-layout *ngIf="timezoneOnly" class="section timezone">
      <app-card-header [header]="'Timezone settings'"
                       [helpButton]="false"></app-card-header>
      <div class="timezone__content">
        <div class="row">
          <app-timezone-form class="col-md-4"
                             [timezoneOnly]="timezoneOnly"
                             [settings]="locationConfig"
                             [station]="currentStation">
          </app-timezone-form>
        </div>
        <div *ngIf="currentStation.rights === 'rw' && !isIngestedStation"
              class="timezone__save">
          <app-custom-button [color]="'red'"
                             (click)="save()"
                             [content]="'Save location'">
          </app-custom-button>
        </div>
      </div>
  </app-form-layout>

