import { Component, Input, SimpleChanges, Output, EventEmitter, OnChanges } from '@angular/core';
import { ILoggingAndTransferState } from '../../../reducers/loggingAndTransfer';
import { IStation } from '../../../../../core/models/stations';
import { ILoggingTransferDevice, parseSchedulerString } from '../../../models/station-config.models';
import { LoggingTransferGroups } from '../../../constants/constants';
import { FormValidationService } from '../../../services/form-validation.service';
import * as deepEqual from 'deep-equal';

@Component({
  selector: 'app-save-logging-and-transfer-two',
  templateUrl: './save-logging-and-transfer-two.component.html',
  styleUrls: ['./save-logging-and-transfer-two.component.scss']
})
export class SaveLoggingAndTransferTwoComponent implements OnChanges {
  @Input()
  public state: ILoggingAndTransferState;
  @Input()
  public isAdvanced: boolean;
  @Input()
  public station: IStation;

  @Output()
  private save: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  private toggleAdvanced: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  private toggleDefault: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private formValidationService: FormValidationService) {}

  public disabled: boolean = true;
  public isDefault: boolean = false;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.state && changes.isAdvanced && changes.station) {
      this.disabled = true;
    }

    // tslint:disable-next-line:max-line-length
    const deviceGroup: ILoggingTransferDevice = LoggingTransferGroups.filter((group) => group.deviceIDs.includes(this.station.info.device_id))[0];
    const config = this.state.config;

    if (changes.state && !deepEqual(changes.state.currentValue, changes.state.previousValue)) {
      this.isDefault = this.formValidationService.checkIfDefault(deviceGroup, config);
      let isHourGapError: boolean = false;
      let overThreeHours: boolean = false;

      if (deviceGroup.led) {
        if (this.state.config.scheduler) {
          isHourGapError = !this.formValidationService.isFourHoursGap(parseSchedulerString(this.state.config));
          overThreeHours = this.formValidationService.countOnes(this.state.config.scheduler) > 3 ? true : false;
        } else {
          isHourGapError = false;
          overThreeHours = false;
        }
      }

      const isFixedTransferInterval: boolean = [6, 7, 8, 28].includes(this.station.info.device_id);
      const transferInterval = isFixedTransferInterval ? config.fixed_transfer_interval : config.transfer_interval;
      const isValidForm: boolean = this.formValidationService.checkIfFormValid(
        deviceGroup,
        +config.measuring_interval,
        +config.logging_interval,
        +transferInterval,
        this.station.info.device_id,
        config.frost_monitoring_enabled
      );

      this.disabled = !isValidForm || isHourGapError || overThreeHours;
    }
  }

  public saveConfiguration(): void {
    this.save.emit();
  }

  public toggleAdvancedOptions(): void {
    this.toggleAdvanced.emit();
  }

  public toggleDefaults(): void {
    if (!this.isDefault) {
      this.isDefault = !this.isDefault;
      this.toggleDefault.emit(this.isDefault);
    }
  }
}
