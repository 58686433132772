import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-satellite-help-text-modal',
  templateUrl: './satellite-help-text-modal.component.html',
  styleUrls: ['./satellite-help-text-modal.component.scss']
})
export class SatelliteHelpTextModalComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
