import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ICropZone } from '../../core/models/cropzones';
import { selectSelectedCropZone } from '../../core/reducers';
import * as fromSelectedCropzone from '../../core/reducers/selectedCropZone';
import { stationDataContentAnimations } from '../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../core/services/left-components-toggler/left-components-toggler.service';
import { ITreeStructure } from '../../services/tree/models';
import { TreeService } from '../../services/tree/tree.service';
import { IAccount } from './../../core/reducers/account';
import { selectAvailableLicenses } from './../../core/reducers/index';

@Component({
  selector: 'app-cropzone-config',
  templateUrl: './cropzone-config.component.html',
  styleUrls: ['./cropzone-config.component.scss'],
  animations: stationDataContentAnimations
})
export class CropzoneConfigComponent implements OnInit, OnDestroy {

  public selectedCropzone: ICropZone;

  public state$: Observable<string>;
  public tree$: Observable<ITreeStructure> = this.treeService.getCropzoneSettingsTreeStructure();
  private licenseFromCropzone;
  private destroy$ = new Subject<boolean>();

  constructor(
    private leftComponentsToggler: LeftComponentsTogglerService,
    private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private treeService: TreeService,
    private accountStore: Store<IAccount>
  ) {

    this.state$ = this.leftComponentsToggler.getStationDataContentState();
  }

  public ngOnInit(): void {
    const availableLicenses$ = this.accountStore.pipe(
      select(selectAvailableLicenses),
      filter(licenses => !!licenses),
      takeUntil(this.destroy$)
    );

    const selectedCropzone$ = this.selectedCropzoneStore.pipe(
      filter((cropzone) => !!cropzone),
      select(selectSelectedCropZone),
      tap((cropzone) => this.selectedCropzone = cropzone)
    );

    combineLatest([
      availableLicenses$,
      selectedCropzone$
    ]).pipe(takeUntil(this.destroy$))
    .subscribe(([licenses, cropzone]: any[]) => {
      this.licenseFromCropzone = null;

      if (cropzone.product_key) {
        const now = new Date();

        licenses.forEach((license) => {
          if (license.product_item.key === cropzone.product_key) {
            if (now > moment(license.start_date).toDate() && now < moment(license.end_date).toDate()) {
              this.licenseFromCropzone = license;
            }
          }
        });
      }

      if (this.licenseFromCropzone) {
        this.tree$ = this.treeService.getCropzoneSettingsTreeStructure(this.licenseFromCropzone);
      } else {
        this.tree$ = this.treeService.getCropzoneSettingsTreeStructure();
      }
    });
  }

  public polygonDrawn(event: any): void {
    this.selectedCropzone.boundary = event;
    if (this.licenseFromCropzone) {
      this.tree$ = this.treeService.getCropzoneSettingsTreeStructure(this.licenseFromCropzone);
    } else {
      this.tree$ = this.treeService.getCropzoneSettingsTreeStructure();
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
