<section class="widget-container">
  <app-chart-header [color]="widget.color"
                    [title]="widget.title"
                    [description]="widget.description"
                    [isEditable]="true"
                    [isDraggable]="isEdit$ | async"
                    [isClose]="isEdit$ | async"
                    (remove) = "removeCurrentWidget()"
                    (editMode)="editMode(!widget.settings.disease)"></app-chart-header>

  <div class="settings" *ngIf="widget.settings.disease">
    <app-disease-settings [diseaseRequest]="widget.requests.disease"
                          (updateWidgetData)="loadDiseaseData()"
                          (updateRequest)="updateRequest($event)"></app-disease-settings>
  </div>

  <app-form-layout [size]="'dashboard'">
    <div class="alert alert-warning" *ngIf="isExpired">{{ licenseExpiredOn + ' ' + expirationDate + '. ' + toRenew}}</div>
    <app-chart [chartsSettings]="widget.loadData?.chartsOptions"
               *ngIf="!widget.error && !widget.isLoading"
               [description]="diseaseDescription">
    </app-chart>
    <app-widget-load-info *ngIf="widget.error || widget.isLoading"
                          [error]="widget.error"
                          [loading]="widget.isLoading">
    </app-widget-load-info>
  </app-form-layout>
</section>
