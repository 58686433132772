import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CameraDataComponent} from './containers/camera-data/camera-data.component';
import {CameraDataResolver} from './camera-data.resolver.service';


const routes: Routes = [
  { path: '', component: CameraDataComponent, resolve: {cameraDataResolver: CameraDataResolver}}
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
  providers: [CameraDataResolver]
})
export class CameraDataRoutingModule { }
