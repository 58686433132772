<div class="header" [ngClass]="headerClass" [ngStyle]="{ 'backgroundColor': backgroundColor }">
  <div *ngIf="versionDisplayed" class="version" style="position: absolute; left: 13px; top: 4px; color: #fff; font-size: 11px">
    {{version}}
  </div>
  <app-logo [type]="'main'" (hideVersion)="hideVersion()" (headerBackground)="setBackground($event)" (headerClass)="setHeaderClass($event)"></app-logo>
  <div class="header-navbar">
    <a *ngIf="false" href="https://shop.metos.at" target="_blank">
      <i
         appTooltip
         [tooltipPlacement]="'bottom'"
         [tooltipTitle]="'Web shop' | translate"
         class="icon-webshop">
      </i>
    </a>
    <a *ngIf="!theme?.hideNotificationsIcon">
      <i (click)="toggleNotifications(false)"
        appTooltip
        [tooltipPlacement]="'bottom'"
        [tooltipTitle]="'Notifications' | translate"
        appOutClick
        (outClick)="toggleNotifications(true)"
        class="zmdi zmdi-notifications">
      </i>
    </a>
    <a>
      <i (click)="toggleUserMenu(false)"
         appTooltip
         [tooltipPlacement]="'bottom'"
         [tooltipTitle]="'User menu' | translate"
         appOutClick
         (outClick)="toggleUserMenu(true)"
         class="user">
      </i>
    </a>
    <a *ngIf="farmView && !theme?.hideCropZonesIcon">
        <i  class="icon-fc-farm"
            appTooltip
            [tooltipPlacement]="'bottom'"
            [tooltipTitle]="'Cropzone list' | translate"
            (click)="toggleCropZones()"
        ></i>
      </a>
    <a>
      <i class="portable-wifi"
         appTooltip
         [tooltipPlacement]="'bottom'"
         [tooltipTitle]="'Station list' | translate"
         (click)="toggleRightStations()"></i>
    </a>
  </div>
</div>
