<section class="selected-image-toolbar__sticky-header">
  <app-selected-image-toolbar [modalId]="'crop-view-delete-image'"
                              [selectedImage]="selectedPicture"
                              [downloadFilenamePrefix]="'CropVIEW_'"
                              [toolbarSettings$]="toolbarSettings$"
                              [hasWritePermission]="hasWritePermission"
                              [showRemovedMeasurementsEnabled]="false"
                              (saveMeasurementsEmitter)="saveMeasurements($event)"
                              (deleteImageEmitter)="deleteImage($event)"
                              (toolbarChangeEmitter)="saveToolbarSettings($event)"
                              (nextImageEmitter)="nextPicture($event)"
                              (previousImageEmitter)="previousPicture($event)">
    <div class="selected-image-toolbar__left__item">
      <app-custom-button (click)="unselectPicture()">
        <i class="fa fa-fw fa-picture-o"></i>
        {{'Gallery' | translate}}
      </app-custom-button>
    </div>

    <div class="selected-image-toolbar__left__item">
      <app-custom-button (click)="toggleCircle('showUnmarked')"
                         [color]="cameraDrawingOptions.showUnmarked ? 'green': 'white'"
                         [tooltip]="'Show/hide unmarked rectangles'">
        <i class="fa fa-object-ungroup"></i>
      </app-custom-button>
    </div>

    <div class="selected-image-toolbar__left__item">
      <app-custom-button (click)="toggleCircle('showMarked')"
                         [color]="cameraDrawingOptions.showMarked ? 'green': 'white'"
                         [tooltip]="'Show/hide marked rectangles'">
        <i class="fa fa-crosshairs"></i>
      </app-custom-button>
    </div>

    <div class="selected-image-toolbar__left__item">
      <app-custom-button (click)="toggleCircle('showCircleScale')"
                         [color]="cameraDrawingOptions.showCircleScale ? 'green': 'white'"
                         [tooltip]="'Show/hide rectangle scale'">
        <i class="fa fa-arrows-h"></i>
      </app-custom-button>
    </div>

    <div class="selected-image-toolbar__left__item">
      <app-custom-button (click)="toggleCircle('showCircleMeasurement')"
                         [color]="cameraDrawingOptions.showCircleMeasurement ? 'green': 'white'"
                         [content]="'mm'"
                         [tooltip]="'Show/hide rectangle measurements'">
      </app-custom-button>
    </div>
  </app-selected-image-toolbar>

  <div class="panel panel-default">
    <div class="panel-body" *ngIf="selectedPicture.rectangles.length > 0">
      <div class="row">
        <div class="col-sm-7">
          <ul class="btn-group-justified">
            <li class="btn btn-default" style="cursor: default; pointer-events: none">
              {{ 'Potential fruits found' | translate }}
              <span class="badge">{{ totalRectanglesCount }}</span>
            </li>

            <li class="btn btn-default" style="cursor: default; pointer-events: none">
              {{ 'New detections' | translate }}
              <span class="badge">{{ newRectanglesCount }}</span>
            </li>
          </ul>
        </div>

        <div class="col-sm-5">
          <div class="form-inline">
            <div class="form-group">
              <label class="control-label-camera-distance-spacing">
                {{ 'Distance from camera' | translate }}
              </label>

              <div class="input-group">
                <input class="form-control"
                       type="number"
                       width="60"
                       placeholder="3000"
                       min="{{minValue}}"
                       max="{{maxValue}}"
                       [formControl]="distanceControl"
                       (blur)="changeDistance()"
                       (keyup.enter)="changeDistance()">
                <span class="input-group-addon">mm</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
