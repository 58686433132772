import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { takeUntil, filter, distinctUntilChanged } from 'rxjs/operators';
import { stationDataContentAnimations } from '../../../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../../../core/services/left-components-toggler/left-components-toggler.service';
import { StationTopologyLinkType } from '../../../station-config/constants/constants';
import { selectStationDataSettingsPeriod, selectStationLoadError, selectStationLoadProcess, selectStationLicenses } from '../../reducers';
import * as fromStationData from '../../reducers/station-data';
import * as fromStationSettings from '../../reducers/station-data-settings';
import { IStationSettingsPeriod } from '../../../../shared/interfaces';
import { noDataWarningMessages } from '../../constants/constants';
import { IAccount } from '../../../../core/reducers/account';
import { selectNavigationStation } from '../../../../core/reducers';
import { IStation } from '../../../../core/models/stations';
import { getStationDataLicenses } from '../../actions/station-data';
import { StationDataService } from '../../services/station-data.service';

@Component({
  selector: 'app-station-data-content',
  templateUrl: './station-data-content.component.html',
  styleUrls: ['./station-data-content.component.scss'],
  animations: stationDataContentAnimations
})
export class StationDataContentComponent implements OnInit, OnDestroy {
  public isError$                 : Observable<boolean>;
  public isLoading$               : Observable<boolean>;
  public errorMessage             : string;
  public error                    : boolean;
  public state$                   : Observable<string> = this.leftComponentsToggler.getStationDataContentState();
  public exportChartImg           : boolean = false;

  private period                  : string;
  private noDataWarningMessages   = noDataWarningMessages;
  private destroy$                : Subject<boolean> = new Subject<boolean>();
  private previousStation         : IStation = null;

  public stationTopologyLink      = StationTopologyLinkType;

  constructor(
    private stationDataStore      : Store<fromStationData.IStationDataState>,
    private stationSettingsStore  : Store<fromStationSettings.IStationDataSettingsState>,
    private leftComponentsToggler : LeftComponentsTogglerService,
    private account               : Store<IAccount>,
    private stationDataService    : StationDataService
  ) {}

  public ngOnInit(): void {
    this.isError$ = this.stationDataStore.pipe(
      takeUntil(this.destroy$),
      select(selectStationLoadError)
    );

    this.stationSettingsStore.pipe(
      select(selectStationLoadError),
      takeUntil(this.destroy$)
    ).subscribe((error: boolean) => {
      this.error = error;
      if (this.error && this.period) {
        this.period === 'monthly' ?
          this.errorMessage = this.noDataWarningMessages.monthly :
          this.errorMessage = this.noDataWarningMessages.general;
      } else {
        this.errorMessage = null;
      }
    });

    this.isLoading$ = this.stationDataStore.pipe(
      takeUntil(this.destroy$),
      select(selectStationLoadProcess)
    );

    this.stationSettingsStore.pipe(
      select(selectStationDataSettingsPeriod),
      takeUntil(this.destroy$)
    ).subscribe((period: IStationSettingsPeriod) => {
      this.period = period.periodScope;
    });

    const stationObservable: Observable<IStation> = this.account.pipe(
      select(selectNavigationStation),
      filter((station) => !!station),
      distinctUntilChanged((a, b) => a.name.original === b.name.original),
      takeUntil(this.destroy$)
    );

    const licensesObservable: Observable<any> = this.stationDataStore.pipe(
      select(selectStationLicenses),
      takeUntil(this.destroy$),
      distinctUntilChanged()
    );

    combineLatest([
      stationObservable,
      licensesObservable
    ]).subscribe(([station, licenses]: [IStation, any]) => {
      if ((!this.previousStation || station.name.original !== this.previousStation.name.original)) {
        this.stationDataStore.dispatch(getStationDataLicenses(station.name.original));
        this.previousStation = station;
      }

      if (licenses) {
        const deviceID: number = station.info.device_id;
        this.stationDataService.setVWSWarning(licenses, deviceID);
      }
    });
  }

  public exportCharts(value : boolean): void {
    this.exportChartImg = value;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
