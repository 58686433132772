import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ApiCallService } from '../../../services/api/api-call.service';
import { catchError, exhaustMap, mergeMap } from 'rxjs/operators';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { setNotify } from '../../../core/actions/notify';
import { of } from 'rxjs';
import { IrrimetActionTypes } from '../actions/irrigation.action';
import { setCropZone } from '../../../core/actions/cropzones';
import { setSelectedCropZone } from '../../../core/actions/selectedCropZone';

@Injectable()
export class WaterbalanceEffects {

  constructor(
    private actions$: Actions,
    private api: ApiCallService
  ) {

  }

  @Effect()
  public updateCropzone$ = this.actions$.pipe(
    ofType(IrrimetActionTypes.UPDATE_CROPZONE),
    exhaustMap((action: IActionWithPayload) =>
      this.api.updateCropzone(action.payload).pipe(
        mergeMap(response => [
          setCropZone(action.payload),
          setSelectedCropZone(action.payload)
        ]),
        catchError(() => of(
          setNotify('Can\'t define events')
        ))
      )
    )
  );
}
