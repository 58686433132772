import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ADD_CROPZONE } from '../../../../../shared/constants';
import { ModalService } from '../../../../../shared/modal/services/modal.service';
import { IAddFarm } from '../../../../models/farms';

@Component({
  selector: 'app-add-farm-form',
  templateUrl: './add-farm-form.component.html',
  styleUrls: ['./add-farm-form.component.scss']
})

export class AddFarmFormComponent implements OnInit {

  @Output()
  private submit: EventEmitter<IAddFarm> = new EventEmitter<IAddFarm>();

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private modalService: ModalService
  ) {

  }

  public get farm(): AbstractControl {
    return this.form.get('farm');
  }

  public get submitColor(): string {
    return 'green';
  }

  public get submitContent(): string {
    return 'Add farm';
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      'farm': ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50)]
      ]
    });
  }

  public submitEmit(): void {
    const farmObject = { name: this.form.value.farm };
    this.submit.emit(farmObject);
    this.form.reset();
  }

  public close(): void {
    this.modalService.closeModal(ADD_CROPZONE);
  }
}
