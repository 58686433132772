<section>
  <app-form-layout>
    <app-iscout-header
      [title]="'Seasons Management'"
      [enableHelpButton]="true"
      (openModal)="handleToggleHelp()"
    ></app-iscout-header>

    <div class="iscout-season-list-table">
      <div class="iscout-season-list__button">
        <app-custom-button [content]="'Add Season'" (click)="handleSeasonAdd()">
          <i class="fa fa-fw fa-plus"></i>
        </app-custom-button>
      </div>

      <br style="clear: both">
    </div>

    <div class="iscout-season-list-table table-responsive">
      <table class="iscout-season-list-table__table">
        <thead class="iscout-season-list-table__table__head">
        <tr>
          <th>{{ 'Name' | translate }}</th>
          <th></th>
        </tr>
        </thead>
        <tbody class="iscout-season-list-table__table__body">
        <ng-container *ngFor="let season of (seasonList$ | async)">
          <tr>
            <td>{{ season.label }}</td>
            <td style="float: right">
              <app-custom-button
                [content]="'Glue boards'"
                [color]="'green'"
                (click)="handleSeasonDetail(season)"
              >
                <i class="fa fa-fw fa-eye"></i>
              </app-custom-button>

              <app-custom-button
                [content]="'Remove'"
                [color]="'red'"
                (click)="handleSeasonRemove(season)"
              >
                <i class="fa fa-fw fa-trash"></i>
              </app-custom-button>

              <app-custom-button
                [content]="'Update'"
                (click)="handleSeasonEdit(season)"
              >
                <i class="fa fa-fw fa-pencil"></i>
              </app-custom-button>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
  </app-form-layout>

  <app-modal [modalId]="seasonRemoveModalId" size="sm">
    <app-iscout-season-delete-modal
      (confirmEmitter)="handleSeasonRemoveConfirmation()"
      (cancelEmitter)="handleCloseModal(seasonRemoveModalId)"
    ></app-iscout-season-delete-modal>
  </app-modal>

  <app-modal [modalId]="seasonDetailModalId" size="md">
    <app-iscout-season-detail-modal
      [selectedSeason]="seasonSelection"
      (closeEmitter)="handleCloseModal(seasonDetailModalId)"
    ></app-iscout-season-detail-modal>
  </app-modal>

  <app-info-modal [modalId]="helpModalId"
                  [header]="(subDomain?.iScoutNames ? subDomain.iScoutNames : ('iScout' | translate)) + ' ' + ('Seasons' | translate)">
    <p>
      {{
      'Glue boards are summarized into seasons. When clicking the blue button ADD SEASON ' +
      'you\'ll automatically find your glue boards and put them together into a defined season.' | translate
      }}
    </p>
  </app-info-modal>
</section>
