import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { updateCropzone } from '../../../../core/actions/cropzones';
import { setNotify } from '../../../../core/actions/notify';
import { setSelectedCropZone } from '../../../../core/actions/selectedCropZone';
import { ICropZone } from '../../../../core/models/cropzones';
import { selectCropzones, selectSelectedCropZone } from '../../../../core/reducers';
import * as fromCropzones from '../../../../core/reducers/cropzones';
import * as fromNotify from '../../../../core/reducers/notify';
import * as fromSelectedCropzone from '../../../../core/reducers/selectedCropZone';
import { deepClone } from '../../../../shared/utils/deepClone';
import { saveFarmName, saveFieldName } from '../../actions/cropzone-name';
import * as fromCropzoneName from '../../reducers/cropzone-name';
import { CropzoneConfigService } from './../../services/cropzone-config.service';

@Component({
  selector: 'app-cropzone-data',
  templateUrl: './cropzone-data.component.html'
})
export class CropzoneDataComponent implements OnInit, OnDestroy {

  @Input()
  public cropzone: ICropZone;

  public cropzones: ICropZone[];
  public selectedCropzone: ICropZone;
  public destroy$ = new Subject<boolean>();
  public cropzoneSource: ICropZone;
  public sourceLinked: boolean;

  constructor(
    private configStore: Store<fromCropzoneName.ICropzoneNameState>,
    private selectedStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private cropzoneStore: Store<fromCropzones.ICropZones>,
    private notifyStore: Store<fromNotify.INotifyState>,
    private configService: CropzoneConfigService
  ) {

  }

  public ngOnInit(): void {
    this.selectedStore.pipe(
      takeUntil(this.destroy$),
      select(selectSelectedCropZone)
    ).subscribe((cropzone: ICropZone) =>
      this.selectedCropzone = cropzone
    );

    this.cropzoneStore.pipe(
      takeUntil(this.destroy$),
      select(selectCropzones)
    ).subscribe(cropzones =>
      this.cropzones = cropzones
    );

    this.sourceLinked = false;
  }

  public updateCropzone(data): void {

    this.cropzoneSource = deepClone(this.selectedCropzone);
    this.sourceLinked = true;

    for (const key in data) {
      if (this.selectedCropzone.hasOwnProperty(key)) {
        this.selectedCropzone[key] = data[key];
      }
    }

    this.selectedStore.dispatch(
      setSelectedCropZone(deepClone(this.selectedCropzone))
    );
  }

  public saveRename(cropzone: ICropZone): void {
    const startCached = moment(this.selectedCropzone.from);
    const startNew = moment(cropzone.from);
    const startDifferenceInDays = moment.duration(startCached.diff(startNew)).asDays();

    const toCached = moment(this.selectedCropzone.to);
    const toNew = moment(cropzone.to);
    const toDifferenceInDays = moment.duration(toCached.diff(toNew)).asDays();

    if ((startDifferenceInDays > 31 || startDifferenceInDays < -31) || (toDifferenceInDays > 31 || toDifferenceInDays < -31)) {
      cropzone = this.configService.removeIrrimetSettings(cropzone);
      this.notifyStore.dispatch(
        setNotify('Cultivation period has been adjusted by too many days - irrimet and soil moisture settings are reset')
      );
    }

    this.cropzoneStore.dispatch(updateCropzone(cropzone));
    this.selectedStore.dispatch(setSelectedCropZone(cropzone));
    this.cropzoneSource = null;
    this.sourceLinked = false;

    if (cropzone.farm.name !== this.selectedCropzone.farm.name) {
      this.configStore.dispatch(saveFarmName(cropzone));
    }

    if (cropzone.field.name !== this.selectedCropzone.field.name) {
      this.configStore.dispatch(saveFieldName(cropzone));
    }
  }

  public ngOnDestroy(): void {

    if (this.sourceLinked && this.cropzoneSource) {
      this.selectedStore.dispatch(
        setSelectedCropZone(deepClone(this.cropzoneSource))
      );
    }

    this.cropzoneSource = null;
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
