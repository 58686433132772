<div *ngIf="flags.nmetosfwt"
     class="ultrasonic-distance-settings-help">
  <p>
    {{ 'Elevation is the distance between the sensor and the field soil surface level.' }}
  </p>
  <p>
  • {{ 'Water table above the soil surface will have positive level values;' | translate }}
  <br>• {{ 'Water table below the soil surface will have negative level values.' | translate }}
  </p>

  <br>
  <p>
    <b>{{ 'Notes' | translate }}</b>
  </p>
  <p>
    {{ 'Units: uses the same units selected on the "Sensor and nodes settings" page.' | translate }}
    <br>
    {{ 'Default value: if not set the value is 400 mm (15.75 in).' | translate }}
  </p>
</div>

<div *ngIf="flags.waterlevel"
     class="ultrasonic-distance-settings-help">
  <p>
    {{ 'Elevation is the distance between the sensor and the field soil surface level.' }}
  </p>

  <br>
  <p>
    <b>{{ 'Notes' | translate }}</b>
  </p>
  <p>
    {{ 'Units: uses the same units selected on the "Sensor and nodes settings" page.' | translate }}
    <br>
    {{ 'Default value: if not set the value is 400 mm (15.75 in).' | translate }}
  </p>
</div>

