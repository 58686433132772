import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {WarningsPhonesActionTypes} from '../actions/warningsPhones';
import {IPhone} from '../../../core/models/stations';
import {deepClone} from '../../../shared/utils/deepClone';

export interface IWarningsPhonesState {
  phones: IPhone[];
}

export const initialState: IWarningsPhonesState = {
  phones: []
};

export function reducer(state: IWarningsPhonesState = initialState, action: IActionWithPayload ): IWarningsPhonesState {
  switch (action.type) {
    case WarningsPhonesActionTypes.SET_WARNINGS_PHONES: {
      return {
        ...state,
        phones: action.payload
      };
    }
    case WarningsPhonesActionTypes.UPDATE_WARNINGS_PHONE: {
      return {
        ...state,
        phones: updatePhone(deepClone(state.phones), action.payload)
      };
    }
    case WarningsPhonesActionTypes.SET_WARNINGS_PHONE: {
      return {
        ...state,
        phones: setPhone(deepClone(state.phones), action.payload)
      };
    }
    case WarningsPhonesActionTypes.REMOVE_WARNINGS_PHONE: {
      return {
        ...state,
        phones: removePhone(deepClone(state.phones), action.payload)
      };
    }
    default: {
      return state;
    }
  }
}

function removePhone(phones: IPhone[], key: string): IPhone[] {
  return phones.filter((phone) => phone.num !== key);
}

function updatePhone(phones: IPhone[], phone: IPhone): IPhone[] {
  const index: number = phones.findIndex((p: IPhone) => p.num === phone.num);
  phones[index] = phone;
  return phones;
}

function setPhone(phones: IPhone[], phone: IPhone): IPhone[] {
  phones.push(phone);
  return phones;
}
