<div class="warnings-phone-remove-modal">
  <p>{{ 'Are you sure you want to remove phone number?' | translate }} {{ number }} ?</p>
  <div class="warnings-phone-remove-modal__buttons">
    <app-custom-button [content]="'Yes'"
                       (click)="emitRemove()"></app-custom-button>
    <app-custom-button [content]="'No'"
                       (click)="emitClose()"
                       [color]="'red'"></app-custom-button>
  </div>
</div>
