<div *ngIf="cropzoneListWidgetItems.length > 0 && show" class="cropzone-list" (window:resize)="onResize()">
  <app-soil-moisture-sum-chart *ngFor="let item of cropzoneListWidgetItems"
  [cropzone]="item.cropzone"
  [sum_sm]="item.sum_sm"
  [refill_point]="item.refill_point"
  [field_capacity]="item.field_capacity"
  [unit]="item.unit"></app-soil-moisture-sum-chart>
</div>
<div *ngIf="!show" class="loading">
  <h4><i class="fa fa-cog fa-spin fa-fw"></i> {{ 'Please wait, loading data ...' | translate }} ...</h4>
</div>
