<div class="aside" [@toggleBar]="barState$ | async">
  <div class="left-bar">
    <ul class="list">
      <div *ngIf="themeName !== 'frontierag'">
        <li appTooltip [tooltipTitle]="'Dashboard' | translate" tooltipPlacement="right">
          <a routerLinkActive="active"
            [routerLink]="['/dashboard']">
            <i class="zmdi zmdi-hc-fw zmdi-home"></i>
          </a>
        </li>
         <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
          [type]="'FieldClimate'"
          [module]="'dashboard'"
          [moduleTexts]="moduleTexts"
        ></app-left-bar-new-text>
      </div>
      <div *ngIf="themeName === 'frontierag'">
        <li appTooltip [tooltipTitle]="'MyFarm' | translate" tooltipPlacement="right">
          <a onClick="window.location.replace('https://myfarm.frontierag.co.uk/login?returnUrl=dashboard')">
            <i class="zmdi zmdi-hc-fw zmdi-home"></i>
          </a>
        </li>
         <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
          [type]="'FieldClimate'"
          [module]="'myFarm'"
          [moduleTexts]="moduleTexts"
        ></app-left-bar-new-text>
      </div>
      <div *ngIf="(navigationAccess$ | async).stationData && (cropzoneOrStation === station || cropzoneOrStation === 'dashboard') && menuItems.stationData" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'Station data' | translate"
          tooltipPlacement="right">
        <a [routerLinkActive]="'active'"
           [routerLink]="'/station/' + (selectedStation$ | async).original_name + '/data/'"
           [routerLinkActiveOptions]="{ exact: true, __change_detection_hack__: (selectedStation$ | async).original_name }">
          <i class="zmdi zmdi-hc-fw zmdi-chart"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
          [type]="'FieldClimate'"
          [module]="'stationData'"
          [moduleTexts]="moduleTexts"
        ></app-left-bar-new-text>
    </div>
    <div *ngIf="(navigationAccess$ | async).cropView && (cropzoneOrStation === station || cropzoneOrStation === 'dashboard') && menuItems.cropView" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'Cameras' | translate"
          tooltipPlacement="right">
        <a [class.active]="isStationCamerasPage()"
           [routerLink]="'/station/' + (selectedStation$ | async).original_name + '/cropview'">
          <i class="icon-fc-cropview"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
          [type]="'FieldClimate'"
          [module]="'camerasCropView'"
          [moduleTexts]="moduleTexts"
        ></app-left-bar-new-text>
    </div>
    <div *ngIf="(navigationAccess$ | async).iScout && (cropzoneOrStation === station || cropzoneOrStation === 'dashboard') && menuItems.iScout" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'Cameras' | translate"
          tooltipPlacement="right">
        <a [class.active]="isStationCamerasPage()"
           [routerLink]="'/station/' + (selectedStation$ | async).original_name + '/iscout'">
          <i class="icon-fc-cropview"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
          [type]="'FieldClimate'"
          [module]="'camerasIScout'"
          [moduleTexts]="moduleTexts"
        ></app-left-bar-new-text>
    </div>
    <div *ngIf="(navigationAccess$ | async).weatherForecast && (cropzoneOrStation === station || cropzoneOrStation === 'dashboard') && menuItems.weatherForecast" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'Weather forecast' | translate"
          tooltipPlacement="right">
        <a [class.active]="isStationWeatherForecastPage()"
           [routerLink]="'/station/' + (selectedStation$ | async).original_name + '/weather-forecast/data/'">
          <i class="icon-fc-weather-forecast"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
          [type]="'FieldClimate'"
          [module]="'weatherForecast'"
          [moduleTexts]="moduleTexts"
        ></app-left-bar-new-text>
    </div>
    <div *ngIf="(navigationAccess$ | async).workPlanning && (cropzoneOrStation === station || cropzoneOrStation === 'dashboard') && menuItems.workPlanning" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'Work planning' | translate"
          tooltipPlacement="right">
        <a [class.active]="isStationWorkPlanningPage()"
           [routerLink]="'/station/' + (selectedStation$ | async).original_name + '/work-planning/plant-nutrition'">
          <i class="icon-fc-work-planning"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
          [type]="'FieldClimate'"
          [module]="'workPlanning'"
          [moduleTexts]="moduleTexts"
        ></app-left-bar-new-text>
    </div>
    <div *ngIf="(navigationAccess$ | async).animalProduction && (cropzoneOrStation === station || cropzoneOrStation === 'dashboard') && menuItems.animalProduction" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'Animal production' | translate"
          tooltipPlacement="right">
        <a [class.active]="isStationAnimalProductionPage()"
           [routerLink]="'/station/' + (selectedStation$ | async).original_name + '/animal-production/dairy-stress'">
          <i class="icon-fc-animals-services"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
          [type]="'FieldClimate'"
          [module]="'animalProduction'"
          [moduleTexts]="moduleTexts"
        ></app-left-bar-new-text>
    </div>
    <div *ngIf="(navigationAccess$ | async).stationData && (cropzoneOrStation === station || cropzoneOrStation === 'dashboard') && menuItems.accumulatorTool" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'Accumulator Tool' | translate"
          tooltipPlacement="right">
        <a [class.active]="isStationAccumulatorToolPage()"
           [routerLink]="'/station/' + (selectedStation$ | async).original_name + '/accumulator-tool/degree-days'">
          <i class="icon-fc-sigma-leaf sigma"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
          [type]="'FieldClimate'"
          [module]="'accumulatorTool'"
          [moduleTexts]="moduleTexts"
        ></app-left-bar-new-text>
    </div>
    <div *ngIf="(navigationAccess$ | async).stationData && (cropzoneOrStation === station || cropzoneOrStation === 'dashboard') && menuItems.frostProtection" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'Extreme Temperature Monitoring' | translate"
          tooltipPlacement="right">
        <a [class.active]="isExtremeMonitoringPage()"
           [routerLink]="'/station/' + (selectedStation$ | async).original_name + '/extreme-weather-monitoring'"
           [ngClass]="{'orangeIcon' : (selectedStation$ | async).original_name.startsWith('D0')}">
          <i class="icon-fc-frost-monitoring"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
          [type]="'FieldClimate'"
          [module]="'extremeTempMonitoring'"
          [moduleTexts]="moduleTexts"
        ></app-left-bar-new-text>
    </div>
    <div *ngIf="(navigationAccess$ | async).diseaseModels && (cropzoneOrStation === station || cropzoneOrStation === 'dashboard') && menuItems.diseaseModels" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'Disease models' | translate"
          tooltipPlacement="right">
        <a [routerLinkActive]="'active'"
           [routerLink]="'/station/' + (selectedStation$ | async).original_name + '/disease-models/'"
           [routerLinkActiveOptions]="{ exact: true, __change_detection_hack__: (selectedStation$ | async).original_name }"
           [ngClass]="{'orangeIcon' : (selectedStation$ | async).original_name.startsWith('D0')}"
        >
          <i class="icon-fc-disease-models"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
          [type]="'FieldClimate'"
          [module]="'diseaseModels'"
          [moduleTexts]="moduleTexts"
        ></app-left-bar-new-text>
    </div>


    <div *ngIf="(navigationAccess$ | async).soilMoisture && (cropzoneOrStation === station || cropzoneOrStation === 'dashboard') && menuItems.soilMoisture" style="position: relative;">
      <li appTooltip
        [tooltipTitle]="'Soil moisture' | translate"
        tooltipPlacement="right">
      <a [routerLinkActive]="'active'"
        [routerLink]="'/station/' + (selectedStation$ | async).original_name + '/soil-moisture/'"
        [routerLinkActiveOptions]="{ exact: true, __change_detection_hack__: (selectedStation$ | async).original_name }">
        <i class="icon-fc-soil-moisture"></i>
      </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
        [type]="'FieldClimate'"
        [module]="'soilMoisture'"
        [moduleTexts]="moduleTexts"
      ></app-left-bar-new-text>
    </div>
    <div *ngIf="(navigationAccess$ | async).hydroponics && (cropzoneOrStation === station || cropzoneOrStation === 'dashboard') && menuItems.hydroponics" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'Hydroponics' | translate"
          tooltipPlacement="right">
        <a [class.active]="isHydroponicsPage()"
           [routerLink]="'/station/' + (selectedStation$ | async).original_name + '/hydroponics'">
          <i class="icon-fc-hydroponics"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
        [type]="'FieldClimate'"
        [module]="'hydrophonics'"
        [moduleTexts]="moduleTexts"
      ></app-left-bar-new-text>
    </div>
    <div *ngIf="(navigationAccess$ | async).tracker && (cropzoneOrStation === station || cropzoneOrStation === 'dashboard') && menuItems.tracker" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'Work Tracking' | translate"
          tooltipPlacement="right">
        <a [routerLinkActive]="'active'"
           [routerLinkActiveOptions]="{ exact: true, __change_detection_hack__: (selectedStation$ | async).original_name }"
           [routerLink]="'/station/' + (selectedStation$ | async).original_name + '/tracker'">
          <i class="icon-fc-active-tracker noPadding" style="font-size: 18px;"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
        [type]="'FieldClimate'"
        [module]="'workTracking'"
        [moduleTexts]="moduleTexts"
      ></app-left-bar-new-text>
    </div>
    <div *ngIf="(navigationAccess$ | async).soilGuard && (cropzoneOrStation === station || cropzoneOrStation === 'dashboard')" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'Soil Guard' | translate"
          tooltipPlacement="right">
        <a [class.active]="isStationSample()"
           [routerLink]="'/station/' + (selectedStation$ | async).original_name + '/samples/soil-guard'">
          <i class="zmdi zmdi-hc-fw zmdi-chart"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
        [type]="'FieldClimate'"
        [module]="'soilGuard'"
        [moduleTexts]="moduleTexts"
      ></app-left-bar-new-text>
    </div>
    <div *ngIf="(navigationAccess$ | async).dualex && (cropzoneOrStation === station || cropzoneOrStation === 'dashboard')" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'Dualex' | translate"
          tooltipPlacement="right">
        <a [class.active]="isStationSample()"
           [routerLink]="'/station/' + (selectedStation$ | async).original_name + '/samples/dualex'">
          <i class="zmdi zmdi-hc-fw zmdi-chart"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
        [type]="'FieldClimate'"
        [module]="'dualex'"
        [moduleTexts]="moduleTexts"
      ></app-left-bar-new-text>
    </div>
    <div *ngIf="(navigationAccess$ | async).mobilab && (cropzoneOrStation === station || cropzoneOrStation === 'dashboard')" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'MobiLab' | translate"
          tooltipPlacement="right">
        <a [class.active]="isStationSample()"
           [routerLink]="'/station/' + (selectedStation$ | async).original_name + '/samples/mobilab'">
          <i class="zmdi zmdi-hc-fw zmdi-chart"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
        [type]="'FieldClimate'"
        [module]="'mobiLab'"
        [moduleTexts]="moduleTexts"
      ></app-left-bar-new-text>
    </div>
    <div *ngIf="(navigationAccess$ | async).settings && (cropzoneOrStation === station || cropzoneOrStation === 'dashboard') && menuItems.settings" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'Device settings' | translate"
          tooltipPlacement="right">
        <a [class.active]="isStationSettingsPage()"
           [routerLink]="'/station/' + (selectedStation$ | async).original_name + '/config/'">
          <i class="zmdi zmdi-hc-fw zmdi-settings"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
        [type]="'FieldClimate'"
        [module]="'deviceSettings'"
        [moduleTexts]="moduleTexts"
      ></app-left-bar-new-text>
    </div>
    <div *ngIf="cropzoneOrStation === cropzone && irrimetAccess() === true" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'Irrimet' | translate"
          tooltipPlacement="right">
        <a [class.active]="isIrrimetPage()"
           [routerLink]="'/cropzone/' + (selectedCropzone$ | async).id + '/irrimet/'">
          <i class="icon-fc-water-level"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
        [type]="'FarmView'"
        [module]="'irrimet'"
        [moduleTexts]="moduleTexts"
      ></app-left-bar-new-text>
    </div>
    <div *ngIf="cropzoneOrStation === cropzone && soilMoistureSumAccess()" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'Soil moisture' | translate"
          tooltipPlacement="right">
        <a [class.active]="isSoilMoisturePage()"
           [routerLink]="'/cropzone/' + (selectedCropzone$ | async).id + '/soil-moisture/sums-sm'">
            <i class="icon-fc-soil-moisture"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
        [type]="'FarmView'"
        [module]="'soilMoisture'"
        [moduleTexts]="moduleTexts"
      ></app-left-bar-new-text>
    </div>
    <div *ngIf="cropzoneOrStation === cropzone && satelliteAccess()" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'Satellite' | translate"
          tooltipPlacement="right">
        <a [class.active]="isSatellitePage()"
           [routerLink]="'/cropzone/' + (selectedCropzone$ | async).id + '/sat/lai'">
          <i class="icon-fc-satellites"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
        [type]="'FarmView'"
        [module]="'satellite'"
        [moduleTexts]="moduleTexts"
      ></app-left-bar-new-text>
    </div>
    <div *ngIf="cropzoneOrStation === cropzone && cropzoneDualexAccess()" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'Cropzone Nutrition Samples' | translate"
          tooltipPlacement="right">
        <a [class.active]="isCropzoneNutrition()"
           [routerLink]="'/cropzone/' + (selectedCropzone$ | async).id + '/cropzone-samples/nutrition'">
          <i class="icon-fc-samples-cropzone"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
        [type]="'FarmView'"
        [module]="'nutritionSamples'"
        [moduleTexts]="moduleTexts"
      ></app-left-bar-new-text>
    </div>
    <div *ngIf="cropzoneOrStation === cropzone && cropzoneSoilGuardAccess()" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'Cropzone SoilGuard Samples' | translate"
          tooltipPlacement="right">
        <a [class.active]="isCropzoneSoilGuard()"
           [routerLink]="'/cropzone/' + (selectedCropzone$ | async).id + '/cropzone-samples/soilguard'">
          <i class="icon-fc-soilguard"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
        [type]="'FarmView'"
        [module]="'soilGuardSamples'"
        [moduleTexts]="moduleTexts"
      ></app-left-bar-new-text>
    </div>
    <div *ngIf="cropzoneOrStation === cropzone  && yieldPredictionAccess()" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'Yield Prediction' | translate"
          tooltipPlacement="right">
        <a [class.active]="isYieldPredictionPage()"
           [routerLink]="'/cropzone/' + (selectedCropzone$ | async).id + '/yield-prediction/prediction'">
          <i class="icon-fc-yield-prediction"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
        [type]="'FarmView'"
        [module]="'yieldPrediction'"
        [moduleTexts]="moduleTexts"
      ></app-left-bar-new-text>
    </div>
    <div *ngIf="cropzoneOrStation === cropzone" style="position: relative;">
      <li appTooltip
          [tooltipTitle]="'Cropzone settings' | translate"
          tooltipPlacement="right">
        <a [class.active]="isCropzoneSettingsPage()"
           [routerLink]="'/cropzone/' + (selectedCropzone$ | async).id + '/config/'">
          <i class="zmdi zmdi-hc-fw zmdi-settings"></i>
        </a>
      </li>
       <app-left-bar-new-text *ngIf="moduleTexts.length > 0"
        [type]="'FarmView'"
        [module]="'cropzoneSettings'"
        [moduleTexts]="moduleTexts"
      ></app-left-bar-new-text>
    </div>
    </ul>
    <ng-container *ngIf="treeCanBeShown$ | async">
      <a class="nav" (click)="changeStationVisible()">
        <i [class.left]="treeIsDisplayed$ | async"
           [class.right]="!(treeIsDisplayed$ | async)">
        </i>
      </a>
    </ng-container>
  </div>
</div>
