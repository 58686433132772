import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { YieldPredictionComponent } from './yield-prediction.component';
import { YieldPredictionResolver } from './yield-prediction.resolver.service';

const routes: Routes = [
  { path: '', component: YieldPredictionComponent,
    resolve: { yieldPredictionResolver: YieldPredictionResolver},  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
  providers: [YieldPredictionResolver]
})
export class YieldPredictionRoutingModule {
}
