import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-square-control',
  templateUrl: './custom-square-control.component.html',
  styleUrls: ['./custom-square-control.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomSquareControlComponent),
    multi: true
  }]
})
export class CustomSquareControlComponent implements ControlValueAccessor {
  public value                          : string;
  private propagateChange               : any = () => { return; };

  constructor() { }

  public change(): void {
    this.value = this.value === '0' ? '1' : '0';
    this.propagateChange(this.value);
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
  }

  public setDisabledState(isDisabled: boolean): void {
  }

  public writeValue(isActive: string): void {
    this.value = isActive;
  }

}
