<form class="product-license-form"
      [formGroup]="form"
      (ngSubmit)="submit()">

  <div class="row">
    <div class="col-md-8">
      <label>{{ 'Product key' | translate }}</label>
      <app-form-input class="product-license-form__input"
                      [type]="'border'"
                      [formControlName]="'productKey'"></app-form-input>
    </div>
  </div>
  <br>

  <div class="product-license-form__submit">
    <app-custom-button [content]="'Cancel'"
                       [type]="'button'"
                       (click)="cancel()"></app-custom-button>
    <app-custom-button [disabled]="form.invalid"
                       [color]="'green'"
                       [content]="'Confirm'"></app-custom-button>
  </div>
</form>
