import { IActionWithPayload } from '../../../core/models/actionWithPayload';

export const enum MachinerySettingsActionTypes {
  GET_MACHINERY_TYEPS = '[MachinerySettings] GET_MACHINERY_TYEPS',
  SET_MACHINERY_TYEPS = '[MachinerySettings] SET_MACHINERY_TYEPS',
  GET_MACHINERY_BRANDS = '[MachinerySettings] GET_MACHINERY_BRANDS',
  SET_MACHINERY_BRANDS = '[MachinerySettings] SET_MACHINERY_BRANDS',

  GET_MACHINERY = '[MachinerySettings] GET_MACHINERY',
  SET_MACHINERY = '[MachinerySettings] SET_MACHINERY',
  SAVE_MACHINERY = '[MachinerySettings] SAVE_MACHINERY',
  GET_ADDED_MACHINERY = '[MachinerySettings] GET_ADDED_MACHINERY',
  SET_ADDED_MACHINERY = '[MachinerySettings] SET_ADDED_MACHINERY',
  GET_ADDED_TRACKERS = '[MachinerySettings] GET_ADDED_TRACKERS',
  SET_ADDED_TRACKERS = '[MachinerySettings] SET_ADDED_TRACKERS',
  SET_LAST_MACHINERY = '[MachinerySettings] SET_LAST_MACHINERY',
  RESET_MACHINERY_DATA_STORE = '[MachinerySettings] RESET_MACHINERY_DATA_STORE'
}

export function getMachinery(data: any): IActionWithPayload {
  return { type: MachinerySettingsActionTypes.GET_MACHINERY, payload: data };
}

export function setMachinery(data: any): IActionWithPayload {
  return { type: MachinerySettingsActionTypes.SET_MACHINERY, payload: data };
}

export function saveMachinery(data: any): IActionWithPayload {
  return { type: MachinerySettingsActionTypes.SAVE_MACHINERY, payload: data };
}

export function getAddedMachinery(): IActionWithPayload {
  return { type: MachinerySettingsActionTypes.GET_ADDED_MACHINERY, payload: null };
}

export function setAddedMachinery(data: any): IActionWithPayload {
  return { type: MachinerySettingsActionTypes.SET_ADDED_MACHINERY, payload: data };
}

export function getAddedTrackers(): IActionWithPayload {
  return { type: MachinerySettingsActionTypes.GET_ADDED_TRACKERS, payload: null };
}

export function setAddedTrackers(data: any): IActionWithPayload {
  return { type: MachinerySettingsActionTypes.SET_ADDED_TRACKERS, payload: data };
}


export function getMachineryTypes(data: any): IActionWithPayload {
  return { type: MachinerySettingsActionTypes.GET_MACHINERY_TYEPS, payload: data };
}

export function setMachineryTypes(data: any): IActionWithPayload {
  return { type: MachinerySettingsActionTypes.SET_MACHINERY_TYEPS, payload: data };
}

export function getMachineryBrands(data: any): IActionWithPayload {
  return { type: MachinerySettingsActionTypes.GET_MACHINERY_BRANDS, payload: data };
}

export function setLastMachineryAdded(data: any): IActionWithPayload {
  return { type: MachinerySettingsActionTypes.SET_LAST_MACHINERY, payload: data };
}

export function resetMachineryDataStore(): IActionWithPayload {
  return { type: MachinerySettingsActionTypes.RESET_MACHINERY_DATA_STORE, payload: null };
}

