import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CameraSlideshowComponent} from './containers/camera-slideshow/camera-slideshow.component';


const routes: Routes = [
  { path: '', component: CameraSlideshowComponent}
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class CameraSlideshowRoutingModule { }
