import {Directive, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[appOutClick]'
})
export class OutClickDirective {
  @Output()
  public outClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  @Input()
  public appOutClick = true;

  constructor(private el: ElementRef) { }

  @HostListener('document:click', ['$event'])
  public closeControl(event: MouseEvent): void {
    if (!this.clickWasOnSelect(event) && this.appOutClick !== false) {
      this.outClick.emit(event);
    }
  }

  private clickWasOnSelect(event: MouseEvent): boolean {
    let currentElem: any = event.target;
    if (currentElem.closest('.fadeInDown')) {
      return true;
    }

    while ( currentElem !== document) {
      if (!currentElem || currentElem === this.el.nativeElement) {
        return true;
      }

      if (currentElem) {
        currentElem = currentElem.parentNode;
      }
    }

    return false;
  }

}
