import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromDisease from './station-disease';
import * as fromDiseaseSettings from './station-disease-settings';
import * as stateFromTreeSettings from '../../../shared/tree/models/tree.models';
import * as reducerFromTreeSettings from '../../../shared/tree/reducers/tree-settings';
import {stationData} from '../../station-data/reducers';
import {stationDiseaseTreeReducer} from '../../../shared/tree/reducers/tree-settings';

export interface IState {
  disease : fromDisease.IStationDiseaseState;
  diseaseSettings: fromDiseaseSettings.IStationDiseaseSettingsState;
  treeSettings: stateFromTreeSettings.ITreeSettingsState;
}

export const reducers = {
  disease: fromDisease.reducer,
  diseaseSettings: fromDiseaseSettings.reducer,
  treeSettings: stationDiseaseTreeReducer
};


export const disease = createFeatureSelector<IState>('disease');

export const selectStationDiseaseLicenses = createSelector(
  disease,
  (state: IState) => state.disease.licenses
);

export const selectStationDiseaseWarnings = createSelector(
  disease,
  (state: IState) => state.disease.warnings
);

export const selectStationDiseaseFireBlight = createSelector(
  disease,
  (state: IState) => state.diseaseSettings.fireBlight
);

export const selectStationDiseaseStationId = createSelector(
  disease,
  (state: IState) => state.diseaseSettings.period.stationId
);

export const selectStationDisease = createSelector(
  disease,
  (state: IState) => state.disease
);

export const selectStationDiseaseLoading = createSelector(
  disease,
  (state: IState) => state.disease.isLoading
);

export const selectStationDiseaseCharts = createSelector(
  disease,
  (state: IState) => state.disease.charts
);

export const selectStationDiseaseTable = createSelector(
  disease,
  (state: IState) => state.disease.grid
);

export const selectStationDiseaseSettings = createSelector(
  disease,
  (state: IState) => state.diseaseSettings
);

export const selectStationDiseaseSettingsPeriod = createSelector(
  disease,
  (state: IState) => state.diseaseSettings.period
);

export const selectStationDiseaseSettingsActivity = createSelector(
  disease,
  (state: IState) => state.diseaseSettings.activity
);

export const selectStationDiseaseSettingsIsChartActive = createSelector(
  disease,
  (state: IState) => state.diseaseSettings.activity.isChartActive
);

export const selectStationDiseaseSettingsIsTableActive = createSelector(
  disease,
  (state: IState) => state.diseaseSettings.activity.isTableActive
);

export const selectStationDiseaseSettingsExportActive = createSelector(
  disease,
  (state: IState) => state.diseaseSettings.activity.isExportActive
);

export const selectStationDiseaseSettingsFromTo = createSelector(
  disease,
  (state: IState) => state.diseaseSettings.fromTo
);

export const selectTreeSettings = createSelector(
  disease,
  (state: IState) => state.treeSettings
);
