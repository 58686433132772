<app-form-layout class="rename-cropzone-name">
  <div class="rename-cropzone-name__content">
    <div class="row">
      <i class="zmdi zmdi-help help-icon"
         (click)="openModal()"></i>
    </div>
    <form class="rename-cropzone-name__input-form" [formGroup]="renameForm">
      <div class="row">
        <div class="col-md-4 noPaddingLeft">
          <h5 class="rename_cropzone_name__label">{{ 'Cropzone name' | translate }}</h5>
          <app-form-input
            [type]="'border'"
            class="rename-cropzone-name__input"
            [formControl]="cropzoneName"
            [size]="'small'">
          </app-form-input>
        </div>
        <div class="col-md-4">
          <h5 class="rename_cropzone_name__label">{{ 'Field name' | translate }}</h5>
          <app-form-input
            [type]="'border'"
            class="rename-cropzone-name__input"
            [formControl]="fieldName"
            [size]="'small'"
            [isDisabled]="true">
          </app-form-input>
        </div>
        <div class="col-md-4 noPaddingRight">
          <h5 class="rename_cropzone_name__label">{{ 'Farm name' | translate }}</h5>
          <app-form-input
            [type]="'border'"
            class="rename-cropzone-name__input"
            [formControl]="farmName"
            [size]="'small'"
            [isDisabled]="true">
          </app-form-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 noPaddingLeft">
          <h5 class="rename_cropzone_name__label">{{ 'Crop' | translate }}</h5>
          <app-form-input
            [type]="'border'"
            class="rename-cropzone-name__input"
            [formControl]="crop"
            [size]="'small'">
          </app-form-input>
        </div>
        <div class="col-md-4">
          <div class="row paddingLeftRight">
            <h5 class="rename_cropzone_name__label floatL">
              {{ 'Cultivation period from - to' | translate }}
            </h5>
          </div>
          <div class="row paddingLeftRight">
            <div class="col-md-6 noPaddingLeft leftDatePicker">
              <app-custom-datepicker
                [isSmall]="true"
                [formControl]="from"
                [maxDate]="to.value">
              </app-custom-datepicker>
            </div>
            <div class="col-md-6 noPaddingRight rightDatePicker">
              <app-custom-datepicker
                [isSmall]="true"
                [formControl]="to"
                [maxDate]="maximumDate">
              </app-custom-datepicker>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <h5 class="rename_cropzone_name__label">{{ 'Copy settings from' | translate }}</h5>
          <app-custom-simple-lazy-dropdown
            [currentItem]="source"
            class="rename_cropzone_name__select-form"
            [customCSS2]="{'max-width': '100%', 'width':'100%'}"
            [shadow]="false"
            [customCSS]="{'max-width': '100%', 'width':'100%', 'border': '1px solid #e0e0e0'}"
            width="100%"
            color="white"
            [formControl]="copyFrom"
            [items$]="options$">
          </app-custom-simple-lazy-dropdown>
        </div>
      </div>
      <div class="row">
        <app-custom-button
          class="rename-cropzone-name__button"
          [disabled]="renameForm.invalid"
          (click)="emitSaveRename()"
          [color]="'red'"
          [content]="'Save'">
        </app-custom-button>
      </div>
    </form>
  </div>
</app-form-layout>

<app-info-modal [modalId]="modalId" [header]="'General cropzone settings'">
  <app-cropzone-config-help-text-modal></app-cropzone-config-help-text-modal>
</app-info-modal>
