<app-tree [tree$]="tree$"></app-tree>
<div class="body-view-content" [@placeContent]="state$ | async">
    <app-cropzone-page-header [pageName]="'Soil moisture'"></app-cropzone-page-header>
    <ng-container>
        <div>
            <div *ngFor="let datasource of soilMoistureDatasources; let i = index">
                <app-soil-moisture-config-top [index]="i" (removeDatasource)="removeDataSource($event)" [dataSource]="datasource" [cropzone]="cropzone" [fromDate]="fromDate" [toDate]="toDate" (save)="save($event)"></app-soil-moisture-config-top>
            </div>
        </div>
        
        
    </ng-container>
    <app-form-layout>
        <app-custom-button style="padding-left: 15px; padding-bottom: 15px; padding-top: 15px" [customCSS]="{'max-width': '100%', 'width':'100%'}" (click)="addNewDatasource()" [content]="'+ Add data source for soil moisture'">
        </app-custom-button> 
    </app-form-layout>
</div>