import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-card-header',
  templateUrl: './card-header.component.html',
  styleUrls: ['./card-header.component.scss']
})
export class CardHeaderComponent {
  @Input()
  public header       : string = '';
  @Input()
  public description  : string = '';
  @Input()
  public period       : { from: Date, to: Date, title?: string };
  @Input()
  public helpButton   : boolean = false;
  @Output()
  public openModal    : EventEmitter<void> = new EventEmitter<void>();
}
