<app-form-layout *ngIf="response.sensors.length"
                 [class.hide]="!isActive">
  <div class="frost-protection-table">
    <ag-grid-angular class="ag-theme-bootstrap"
                     [gridOptions]="dataGridOptions"
                     [columnDefs]="columnDefs"
                     (sortChanged)="onSortChange($event)">
    </ag-grid-angular>
  </div>
</app-form-layout>
