import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SampleLayoutComponent } from './components/sample-layout/sample-layout.component';
import { SharedModule } from '../../shared/shared.module';
import { SampleHeaderComponent } from './components/sample-header/sample-header.component';
import { SoilguardDataComponent } from './containers/soilguard-data/soilguard-data.component';
import { SampleDetailsComponent } from './components/sample-details/sample-details.component';
import { SampleToolbarComponent } from './components/sample-toolbar/sample-toolbar.component';
import { StationSampleRoutingModule } from './station-sample-routing.module';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import { StationDataExportService } from '../../shared/services/export/station-data-export.service';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { DualexDataComponent } from './containers/dualex-data/dualex-data.component';
import { SampleTableComponent } from './components/sample-table/sample-table.component';
import { SampleMapComponent } from './components/sample-map/sample-map.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../../assets/themes/default/configuration';
import { GoogleMapLibraries } from '../../shared/constants';
import { EffectsModule } from '@ngrx/effects';
import { stationSampleReducer } from './reducers/station-sample.reducer';
import { featureKey } from './selectors/station-sample.selector';
import { StoreModule } from '@ngrx/store';
import { StationSampleEffect } from './effects/station-sample.effect';
import { SampleModalComponent } from './components/sample-modal/sample-modal.component';
import { MobilabDataComponent } from './containers/mobilab-data/mobilab-data.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ChartModule,
    StationSampleRoutingModule,
    TranslateModule.forChild(),
    AgGridModule.withComponents([]),
    StoreModule.forFeature(featureKey, stationSampleReducer),
    EffectsModule.forFeature([StationSampleEffect]),
    AgmCoreModule.forRoot({
      apiKey: environment.gMapKey,
      libraries: GoogleMapLibraries
    })
  ],
  declarations: [
    SampleLayoutComponent,
    SampleHeaderComponent,
    SoilguardDataComponent,
    SampleDetailsComponent,
    SampleToolbarComponent,
    DualexDataComponent,
    SampleTableComponent,
    SampleMapComponent,
    SampleModalComponent,
    MobilabDataComponent
  ],
  providers: [
    StationDataExportService,
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting] }
  ]
})
export class StationSampleModule {
}
