import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { UltrasonicDistanceSettingsRoutingModule } from './ultrasonic-distance-settings-routing.module';
import { StoreModule } from '@ngrx/store';
import { UltrasonicDistanceSettingsReducer} from './reducers/reducers';
import { EffectsModule } from '@ngrx/effects';
import { UltrasonicDistanceSettingsEffects } from './effects/effects';
import { UltrasonicDistanceSettingsComponent } from './containers/ultrasonic-distance-settings/ultrasonic-distance-settings.component';
import { UltrasonicDistanceSettingsContentComponent } from './containers/ultrasonic-distance-settings-content/ultrasonic-distance-settings-content.component';
import { UltrasonicDistanceSettingsCurrentComponent } from './containers/ultrasonic-distance-settings-current/ultrasonic-distance-settings-current.component';
import { UltrasonicDistanceSettingsArchivedComponent } from './containers/ultrasonic-distance-settings-archived/ultrasonic-distance-settings-archived.component';
import { UltrasonicDistanceSettingsHelpComponent } from './containers/ultrasonic-distance-settings-help/ultrasonic-distance-settings-help.component';
import { UltrasonicDistanceSettingsSketchesComponent } from './containers/ultrasonic-distance-settings-sketches/ultrasonic-distance-settings-sketches.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    AgGridModule.withComponents([]),
    UltrasonicDistanceSettingsRoutingModule,
    StoreModule.forFeature('ultrasonicDistanceSettings', UltrasonicDistanceSettingsReducer),
    EffectsModule.forRoot([UltrasonicDistanceSettingsEffects])
  ],
  declarations: [
    UltrasonicDistanceSettingsComponent,
    UltrasonicDistanceSettingsContentComponent,
    UltrasonicDistanceSettingsCurrentComponent,
    UltrasonicDistanceSettingsArchivedComponent,
    UltrasonicDistanceSettingsHelpComponent,
    UltrasonicDistanceSettingsSketchesComponent
  ],
})
export class UltrasonicDistanceSettingsModule { }
