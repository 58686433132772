import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IOptions, IStationFromToCommunication, IStationSettingsPeriod } from '../../../../shared/interfaces';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';
import { ITreeSettingsState } from '../../../../shared/tree/models/tree.models';
import { dateToUtcUnixTimestamp } from '../../../../shared/utils/dateFormat';
import { jsonCompare } from '../../../../shared/utils/jsonCompare';
import { DefaultPeriodScope, PeriodValuesByScope } from '../../../station-data/constants/constants';
import { setStationDiseaseLoadingProgress } from '../../actions/station-disease';
import {
  getStationDiseaseImage,
  setStationDiseaseSettingsActivity,
  setStationDiseaseSettingsExportImage,
  setStationDiseaseSettingsPeriod
} from '../../actions/station-disease-settings';
import { IStationDiseaseSettingsActivity } from '../../models/station-disease.models';
import {
  selectStationDiseaseSettingsActivity,
  selectStationDiseaseSettingsFromTo,
  selectStationDiseaseSettingsPeriod, selectTreeSettings
} from '../../reducers';
import * as fromDisease from '../../reducers/station-disease';
import * as fromDiseaseSettings from '../../reducers/station-disease-settings';
import { StationDiseaseHelpService } from '../../services/station-disease-help.service';

@Component({
  selector: 'app-station-disease-settings',
  templateUrl: './station-disease-settings.component.html',
  styleUrls: ['./station-disease-settings.component.scss']
})
export class StationDiseaseSettingsComponent implements OnInit, OnDestroy {
  @Output()
  private exportChartImg        : EventEmitter<boolean> = new EventEmitter<boolean>();
  public fromStation            : Date = moment().toDate();
  public toStation              : Date = moment().toDate();
  public modalId                : string = 'disease-model-configuration';

  public form                   : FormGroup;
  public periodValueSelectItems : Array<IOptions> = PeriodValuesByScope[DefaultPeriodScope];

  private startExport$          : Subject<void> = new Subject<void>();
  private destroy$              : Subject<boolean> = new Subject<boolean>();
  private diseaseName           : string;
  private isDatepickerRequest   : boolean = false;

  constructor(private fb: FormBuilder,
              private helpService: StationDiseaseHelpService,
              private exportService: StationDataExportService,
              private diseaseStore: Store<fromDisease.IStationDiseaseState>,
              private diseaseSettingsStore: Store<fromDiseaseSettings.IStationDiseaseSettingsState>,
              private modalService: ModalService,
              private treeSettingsStore: Store<ITreeSettingsState>) { }

  private get period(): AbstractControl {
    return this.form.get('period');
  }

  private get activity(): AbstractControl {
    return this.form.get('activity');
  }

  public get fromDatepicker(): AbstractControl {
    return this.period.get('fromDatepicker');
  }

  public get toDatepicker(): AbstractControl {
    return this.period.get('toDatepicker');
  }

  public get isTableActive(): AbstractControl {
    return this.activity.get('isTableActive');
  }

  public get isChartActive(): AbstractControl {
    return this.activity.get('isChartActive');
  }

  public get isLastDataMode(): AbstractControl {
    return this.period.get('isLastDataMode');
  }

  public get periodValue(): AbstractControl {
    return this.period.get('periodValue');
  }

  public get periodScope(): AbstractControl {
    return this.period.get('periodScope');
  }

  public get fromTo(): AbstractControl {
    return this.period.get('fromTo');
  }

  public get stationId(): AbstractControl {
    return this.period.get('stationId');
  }

  public get isExportImageActive(): AbstractControl {
    return this.activity.get('isExportImageActive');
  }

  public get isExportActive(): AbstractControl {
    return this.activity.get('isExportActive');
  }

  public get isFireBlightActive(): AbstractControl {
    return this.activity.get('isFireBlightActive');
  }

  public ngOnInit(): void {
    this.initForm();
    this.initFromToSubscription();
    this.initActivitySubscription();
    this.initPeriodSubscription();
    this.initActivityValueChangesSubscription();
    this.prepareExporting();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public openModal(): void {
    this.helpService.openFireblight();
    this.modalService.openModal(this.modalId);
  }

  public closeModal(): void {
    this.modalService.closeModal(this.modalId);
  }

  public refresh(): void {
    this.isDatepickerRequest = !this.isLastDataMode.value;
    this.diseaseStore.dispatch(setStationDiseaseLoadingProgress());
    this.diseaseSettingsStore.dispatch(setStationDiseaseSettingsPeriod(this.period.value));
  }

  public startExportImage(): void {
    if (this.isExportImageActive.value) {
      return;
    }
    this.exportChartImg.emit(true);
    this.diseaseSettingsStore.dispatch(setStationDiseaseSettingsExportImage(true));
    setTimeout(() => {
      this.exportChartImg.emit(false);
      this.diseaseSettingsStore.dispatch(setStationDiseaseSettingsExportImage(false));
    }, 500);
  }

  public startExportXLS(): void {
    this.exportService.startExportXLS(this.stationId.value);
  }

  public setDateRange(): void {
    if (this.isDatepickerRequest && !this.isLastDataMode.value) {
      this.fromTo.setValue({
        from: this.fromDatepicker.value,
        to: this.toDatepicker.value
      });
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      'period': this.fb.group({
        'periodScope': [DefaultPeriodScope, Validators.required],
        'periodValue': [null, [Validators.required]],
        'fromTo': [null, [Validators.required]],
        'fromDatepicker': [null, [Validators.required]],
        'toDatepicker': [null, [Validators.required]],
        'stationId': ['', [Validators.required]],
        'isLastDataMode': [true, [Validators.required]]
      }),
      'activity': this.fb.group({
        'isChartActive': [true, [Validators.required]],
        'isTableActive': [true, [Validators.required]],
        'isExportImageActive': [false, [Validators.required]],
        'isExportActive': [false, [Validators.required]],
        'isFireBlightActive': [false, [Validators.required]]
      })
    });
  }

  private initFromToSubscription(): void {
    this.diseaseSettingsStore.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      select(selectStationDiseaseSettingsFromTo),
      filter((fromTo: IStationFromToCommunication) => !!fromTo)
    ).subscribe((fromTo: IStationFromToCommunication) => {
      this.fromStation = fromTo.fromStation;
      this.toStation = fromTo.toStation;
    });
  }

  private initActivitySubscription(): void {
    this.diseaseSettingsStore.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(jsonCompare()),
      select(selectStationDiseaseSettingsActivity)
    ).subscribe((activity: IStationDiseaseSettingsActivity) => {
      this.activity.setValue(activity);
    });
  }

  private initPeriodSubscription(): void {
    this.diseaseSettingsStore.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      select(selectStationDiseaseSettingsPeriod),
      filter((period: IStationSettingsPeriod) => !!period.periodScope)
    ).subscribe((period: IStationSettingsPeriod) => {
      this.periodValueSelectItems = PeriodValuesByScope[period.periodScope];
      setTimeout(() => this.period.setValue(period), 0);
    });
  }

  private initActivityValueChangesSubscription(): void {
    this.activity.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(100)
    ).subscribe((form: IStationDiseaseSettingsActivity) => {
      this.diseaseSettingsStore.dispatch(setStationDiseaseSettingsActivity(form));
    });
  }

  private prepareExporting(): void {
    this.treeSettingsStore.pipe(
      select(selectTreeSettings),
      debounceTime(10),
      takeUntil(this.destroy$),
      filter((treeSettings: ITreeSettingsState): boolean => !!treeSettings[this.stationId.value]),
      tap((treeSettings: ITreeSettingsState): void => {
        this.diseaseName = treeSettings[this.stationId.value].selectedProfile;
      }),
      switchMap(() => this.startExport$)
    ).subscribe(() => {
      this.diseaseSettingsStore.dispatch(getStationDiseaseImage({
        stationId: this.stationId.value,
        name: this.diseaseName,
        timePeriod: 'hourly',
        from: this.isLastDataMode.value
          ? dateToUtcUnixTimestamp(this.fromTo.value.from)
          : dateToUtcUnixTimestamp(this.fromDatepicker.value),
        to: this.isLastDataMode.value
          ? dateToUtcUnixTimestamp(this.fromTo.value.to)
          : dateToUtcUnixTimestamp(this.toDatepicker.value)
      }));
    });
  }
}
