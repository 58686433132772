import { Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { selectSelectedCropZone, selectUserData } from '../../../../core/reducers';
import { IAccount } from '../../../../core/reducers/account';
import * as fromSelectedCropzone from '../../../../core/reducers/selectedCropZone';
import { yieldCropColorTypes, yieldUnitTypes } from '../../constants/config';
import { IYieldPredictionValues } from '../../models/model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-yield-prediction-graph',
  templateUrl: './yield-prediction-graph.component.html',
  styleUrls: ['./yield-prediction-graph.component.scss']
})
export class YieldPredictionGraphComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public chartData: any = null;
  public chart: Chart[] = [];
  public chartOptions: Options[] = [];
  private defaultOptions: Options = null;
  private alive$: Subject<boolean> = new Subject<boolean>();
  private translatedValues: IYieldPredictionValues;
  public currentDate: any = new Date();
  public todayPlotLine: number = 0;
  public tillToday: number = 0;
  public harvestPlotLine: number = 0;
  public unitSystem: string;
  public actualUnit: string = '';
  public historicAverage: any = [];
  public historicRange: any = [];
  public forecastAverage: any = [];
  public forecastRange: any = [];
  public minMaxAverage: any = [];
  public minMaxRange: any = [];
  public yieldSource: any = null;
  public errorMessage: string = null;

  constructor(
    private userStore: Store<IAccount>,
    private route: ActivatedRoute,
    private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>
  ) { }

  public ngOnInit(): void {
    this.translatedValues = this.route.snapshot.data['yieldPredictionResolver'];

    this.userStore.pipe(
      select(selectUserData),
      takeUntil(this.alive$),
      filter((user: IAccount) => !!user)
    ).subscribe((user: IAccount) => {
      if (user.settings !== null) {
        this.unitSystem = user.settings.unit_system;
        this.unitSystem === 'metric' ? this.actualUnit = 't/ha' : this.actualUnit = 'bu/ac';
        this.defaultOptions = {
          title: {
            text: ''
          },
          credits: {
            enabled: false
          },
          exporting: {
            enabled: false
          },
          xAxis: {
            type: 'datetime',
            labels: {
              format: '{value:%e. %b}'
            }
          },
          legend: {},
          plotOptions: {
            series: {
              marker: {
                enabled: false
              },
            }
          },
        };
      }
      this.updateChartData();
    });

    this.selectedCropzoneStore.pipe(
      select(selectSelectedCropZone),
      takeUntil(this.alive$)
    ).subscribe((cropzone) => {
      cropzone.data_sources.map(datasource => {
        if (datasource.module === 'YIELD_PREDICTION') {
          this.yieldSource = datasource;
          const unit = this.yieldSource.yield_prediction.unit;
          if (yieldUnitTypes(unit) !== null) {
            this.unitSystem = yieldUnitTypes(unit);
            this.actualUnit = unit;
          }
        }
      });
    });
  }

  public ngOnChanges(): void {
    this.updateChartData();
  }

  public updateChartData(): void {
    if (this.chartData) {
      if (!this.chartData.error) {
      this.chart = [];
      const categories = [];
      this.chartData.date?.map((item, index) => {
        const date = new Date(item * 1000);
        const currentDate = new Date();
        categories.push(item * 1000);
        if (moment(date) <= moment(this.currentDate)) {
          this.tillToday = index;
        }
        if (date.toDateString() === currentDate.toDateString()) {
          this.todayPlotLine = index;
        }
      });
      this.harvestPlotLine = this.chartData.date.length - 1;
      this.historicAverage = this.formatAverage(categories, this.chartData.data.yield_avg[0]);
      this.historicRange = this.formatRange(categories, this.chartData.data.yield_min[0], this.chartData.data.yield_max[0]);
      this.forecastAverage = this.formatAverage(categories, this.chartData.data.yield_avg[1])
        .splice(this.tillToday, this.chartData.date.length);
      this.forecastRange = this.formatRange(categories, this.chartData.data.yield_min[1], this.chartData.data.yield_max[1])
        .splice(this.tillToday, this.chartData.date.length);
      this.minMaxAverage = this.formatAverage(categories, this.chartData.data.yield_avg[1]).splice(0, this.tillToday + 1);
      this.minMaxRange = this.formatRange(categories, this.chartData.data.yield_min[1], this.chartData.data.yield_max[1])
        .splice(0, this.tillToday + 1);
      this.chartOptions[1] = {
        ...this.defaultOptions,
        chart: {
          height: 250,
        },
          yAxis: [{
            title: {
              text: '<b>' + this.translatedValues['precipitationAccm'] +  ' [mm]</b>'
            },
            min: 0,
            opposite: true,
          }, {
            title: {
              text: '<b>' + this.translatedValues['precipitationDaily'] +  ' [mm]</b>'
            },
            min: 0,
          }],
          tooltip: {
            xDateFormat: '%Y-%m-%d',
            crosshairs: true,
            shared: true,
            valueSuffix: ' mm'
          },
          series: [
            {
              name: this.translatedValues['precipitationDaily'],
              data: this.formatAverage(categories, this.chartData?.precipitationChart?.measured),
              zIndex: 1,
              type: 'column',
              color: '#1f78b4',
              yAxis: 1
            },
            {
              name: this.translatedValues['precipitationCumulative'],
              data: this.formatAverage(categories, this.chartData?.precipitationChart?.measureAcumulative),
              zIndex: 1,
              type: 'line',
              color: '#566F85',
              yAxis: 0
            },
            {
              name: this.translatedValues['precipitation35YearAvg'],
              data: this.formatAverage(categories, this.chartData?.precipitationChart?.historyAcumulative),
              zIndex: 1,
              type: 'line',
              color: '#6666ff',
              yAxis: 0
            },
          ]
        };
        this.chartOptions[0] = {
          ...this.defaultOptions,
          yAxis: [{
            title: {
              text: '<b>' + this.translatedValues['yieldPrediction'] + ' [' + this.actualUnit + ']</b>'
            },
            min: 0,
          }, {
            title: {
              text: '<b>' + this.translatedValues['yieldPrediction'] + ' [' + this.actualUnit + ']</b>'
            },
            min: 0,
            opposite: true
          }],
          tooltip: {
            xDateFormat: '%Y-%m-%d',
            crosshairs: true,
            shared: true,
            valueSuffix: ' ' + this.actualUnit
          },
          series: [{
            name: this.translatedValues['currentSeason'],
            data: this.minMaxAverage,
            zIndex: 1,
            type: 'line',
            yAxis: 0,
            color: '#' + yieldCropColorTypes(this.yieldSource.yield_prediction.crop_type)
          }, {
            name: this.translatedValues['predictedNormalSeason'],
            data: this.historicAverage,
            zIndex: 1,
            type: 'line',
            yAxis: 0,
            color: '#566F85'
          }, {
            name: this.translatedValues['predictedRainForest'],
            data: this.forecastAverage,
            color: '#e90edc',
            type: 'line',
            zIndex: 1,
            yAxis: 0,
          }, {
            name: this.translatedValues['range'],
            data: this.minMaxRange,
            zIndex: 1,
            type: 'arearange',
            yAxis: 0,
            // '66'(aka alpha value) is added at end for getting 40% transparency;
            color: '#' + yieldCropColorTypes(this.yieldSource.yield_prediction.crop_type) + '66'
          }, {
            name: this.translatedValues['range'],
            data: this.historicRange,
            type: 'arearange',
            color: 'rgb(86,111,133, 0.4)',
            yAxis: 0,
            zIndex: 0
          }, {
            name: this.translatedValues['range'],
            data: this.forecastRange,
            type: 'arearange',
            color: 'rgb(233,14,220, 0.4)',
            yAxis: 0,
            zIndex: 0
          }]
        };
        const plotlineHarvest = {
          color: 'rgb(86, 111, 133, 0.4)',
          width: 2,
          dashStyle: 'Solid',
          value: this.parseDate(this.harvestPlotLine),
          label: {
            text: `<b>` + this.translatedValues['physiologicalMaturity'] + `</b>`,
            verticalAlign: 'top',
            textAlign: 'bottom'
          }
        };
        if (this.tillToday < this.harvestPlotLine) {
          this.chartOptions[0] = {
            ...this.chartOptions[0],
            xAxis: {
              ...this.chartOptions[0].xAxis,
              plotLines: [{
                color: 'rgb(86, 111, 133, 0.4)',
                width: 2,
                dashStyle: 'Solid',
                value: this.todayPlotLine === 0 ? this.parseDate(this.tillToday) : this.parseDate(this.todayPlotLine),
                label: {
                  text: `<b>` + this.translatedValues['today'] + `</b>`,
                  verticalAlign: 'top',
                  textAlign: 'bottom'
                }
              }, plotlineHarvest]
            },
          };
        } else if (this.tillToday >= this.harvestPlotLine) {
          this.chartOptions[0] = {
            ...this.chartOptions[0],
            xAxis: {
              ...this.chartOptions[0].xAxis,
              plotLines: [plotlineHarvest]
            },
          };
        }
        this.chartOptions.map(chartItem => {
          this.chart.push(new Chart(chartItem));
        });
        this.errorMessage = null;
      } else {
        this.errorMessage = this.chartData.error.message;
      }
    }
  }

  public formatAverage(array1, array2): any {
    const result = [];
    array1.map((item, index) => {
      result.push([item, array2[index]]);
    });
    return result;
  }

  public formatRange(array1, array2, array3): any {
    const result = [];
    array1.map((item, index) => {
      result.push([item, array2[index], array3[index]]);
    });
    return result;
  }

  public parseDate(chartIndex: number): number {
    return Date.parse(new Date((this.chartData?.date[chartIndex]) * 1000).toString());
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }

}
