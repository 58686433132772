<div class="leave-confirm">
  <p class="leave-confirm__text">{{ 'You have unsaved data on this page. Are you sure you want to leave?' | translate }}</p>
  <div class="leave-confirm__buttons">
    <app-custom-button [content]="'Leave'"
                       (click)="emitLeave()"
                       [color]="'red'"></app-custom-button>
    <app-custom-button [content]="'Stay'"
                       (click)="emitStay()"></app-custom-button>
  </div>
</div>
