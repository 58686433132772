<app-form-layout [hidden]="hide$ | async">
  <div class="soil-moisture-sensor-settings" *ngIf="(status$ | async) as status">
    <div class="soil-moisture-sensor-settings__header">
      <h2>{{ 'Default Sensor PAW Settings' | translate }} </h2>
    </div>

    <div class="soil-moisture-sensor-settings__body">
        <form [formGroup]="form" *ngIf="sensors$ | async as sensorData">
          <ng-container *ngFor="let sensors of sensorData | keyvalue as keyvalues; let i = index">
            <app-sensor-settings-column
              [form]="form.get(sensors.key)"
              [sensors]="sensors.value"
              [depth_unit]="(userSettings$ | async)?.unit_system === 'metric' ? 'cm' : 'in'">
            </app-sensor-settings-column>
            <app-sensor-settings-column
              *ngIf="i === keyvalues.length - 1 && keyvalues.length % 2">
            </app-sensor-settings-column>
          </ng-container>
        </form>
    </div>

    <hr/>

    <div class="soil-moisture-sensor-settings__footer">
      <div *ngIf="form.invalid" class="alert alert-warning soil-moisture-sensor-settings__footer__alert">
        {{ 'Sensor settings are incomplete or invalid' | translate }}
      </div>
      <app-custom-button (click)="saveSettings()" [disabled]="status.isLoadingSoilMoistureSensors || status.isLoading || form.invalid"
            [color]="'red'" [content]="'Save'"></app-custom-button>
    </div>
  </div>
</app-form-layout>

