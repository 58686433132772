<a [ngClass]="{'search-widget-item-container' : true, 'selected': isSelected}"
   (click)="navigateUrl($event)">
  <div class="search-widget-item-info">
    <div class="text-right">
      <span *ngIf="isVirtualDevice()" class="label orange">{{ item.info_name }}</span>
      <span *ngIf="isCameraDevice()" class="label darkgreen">{{ item.info_name }}</span>
      <span *ngIf="!isVirtualDevice() && !isCameraDevice()" class="label lightgreen">{{ item.info_name }}</span>
      <br>
      <div class="icons">
        <i *ngIf="item.model_licenses" class="icon-fc-disease-models"></i>
        <i *ngIf="item.forecast_licenses" class="icon-fc-weather-forecast"></i>
      </div>
      <i [ngStyle]="{'color': item.starred ? '#ffc107' : 'lightgrey', 'margin-top': item.model_licenses || item.forecast_licenses ? '0px' : '18px'}" class="zmdi zmdi-star starIcon" (click)="toggleFavorite($event)"></i>
    </div>
    <div class="heading">{{ item.original_name }} <i *ngIf="item.rights === 'r'" class="read-only zmdi zmdi-lock-outline"></i></div>
    <small *ngIf="item.custom_name" class="custom-name">{{ item.custom_name }}</small>
    <small>
      <i class="zmdi-calendar"></i>
      {{ item.last_communication }}
    </small>
  </div>
</a>
