import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-warnings-list-save',
  templateUrl: './warnings-list-save.component.html',
  styleUrls: ['./warnings-list-save.component.scss']
})

export class WarningsListSaveComponent {
  @Output()
  private save          : EventEmitter<void> = new EventEmitter<void>();

  public saveWarnings(): void {
    this.save.emit();
  }
}
