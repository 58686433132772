<div class="warnings-phone-list">
  <h4 class="warnings-phone-list__header">
    <i class="fa fa-address-book-o fa-fw"></i>{{ 'List of phone numbers' | translate }}
  </h4>
  <table class="warnings-phone-list__table">
    <thead class="warnings-phone-list__table__head">
    <tr>
      <th class="col-md-3 warnings-phone-list__table__th">{{ 'Phone number' | translate }}</th>
      <th class="col-md-3 warnings-phone-list__table__th">{{ 'Name' | translate }}</th>
      <th class="col-md-4 warnings-phone-list__table__th text-center">{{ 'Active' | translate }}</th>
      <th class="col-md-2 warnings-phone-list__table__th text-center">&nbsp;</th>
    </tr>
      <tr *ngIf="!phones.length" class="warnings-phone-list__table__empty">
        <td colspan="4" class="text-center warnings-phone-list__table__th warnings-phone-list__table__th__empty">
          <i>{{ 'No phone numbers were added to the list' | translate }}</i>.
        </td>
      </tr>
    </thead>
    <tbody class="warnings-phone-list__table__body">
      <tr *ngFor="let phone of phones"
          [phone]="phone"
          app-warnings-phone-list-row>
      </tr>
    </tbody>
  </table>
</div>
