import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {StationDiseaseComponent} from './containers/station-disease/station-disease.component';


const routes: Routes = [
  { path: '', component: StationDiseaseComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class StationDiseaseRoutingModule { }
