import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { setZoomTitle } from '../../../../shared/utils/setZoomTitle';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnChanges {
  @Input()
  public chartsSettings                    : Array<any>;
  @Input()
  private description                      : string;

  public charts                            : Array<Chart> = [];
  public viewDescription                   : string;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartsSettings !== undefined) {
      if (changes.chartsSettings.currentValue !== changes.chartsSettings.previousValue) {
          this.viewDescription = this.description;
        this.charts = [];
        this.generateCharts();
      } else {
        this.charts = [];
        this.generateCharts();
      }
    }
  }

  private generateCharts(): void {
    if (!this.chartsSettings) {
      return;
    }
    this.chartsSettings.forEach((item) => {
      const chart = new Chart(item);
      setZoomTitle(chart);
      this.charts.push(chart);
    });
  }

}
