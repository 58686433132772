import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { setNotify } from '../../core/actions/notify';
import { IStation } from '../../core/models/stations';
import { selectNavigationStation } from '../../core/reducers';
import { INavigationStationState } from '../../core/reducers/navigation-station';
import * as fromNotify from '../../core/reducers/notify';
import { stationDataContentAnimations } from '../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../core/services/left-components-toggler/left-components-toggler.service';
import { ApiCallService } from '../../services/api/api-call.service';
import { ITreeStructure } from '../../services/tree/models';
import { TreeService } from '../../services/tree/tree.service';
import { READ_AND_WRITE_PERMISSION, WRITE_PERMISSION } from '../../shared/constants';
import { dateToUtcUnixTimestamp } from '../../shared/utils/dateFormat';
import { IDeleteWeatherDataRequest } from './models/delete-weather-data.models';

@Component({
  selector: 'app-station-delete-weather-data',
  templateUrl: './station-delete-weather-data.component.html',
  styleUrls: ['./station-delete-weather-data.component.scss'],
  animations: stationDataContentAnimations
})

export class StationDeleteWeatherDataComponent implements OnInit, OnDestroy {
  public readonly notificationText = 'You can delete all station data by clicking the checkbox or choose a time period from the provided ' +
    'date-picker. Careful, removing the data is non-reversible.';
  public readonly stationKeyPlaceholder = 'Key 1 provided on the station sticker';
  public readonly confirmationText1 = 'The weather data will be permanently deleted from the database and can\'t be restored! This ' +
    'action will be logged with your user ID and IP address for possible inquiries.';
  public readonly confirmationText2 = 'No claims of data loss will be accepted';
  public readonly permissionError = 'Permission denied. Did you provide the correct station key?';

  public destroy$              : Subject<boolean> = new Subject();

  public tree$                  : Observable<ITreeStructure>;
  public state$                 : Observable<string>;
  public navigationStation      : IStation;

  public showConfirmBlock      : boolean = false;
  public showErrorBlock        : boolean = false;
  public hasSuccessResponse    : boolean = false;

  private form                  : FormGroup = this.fb.group({
    'shouldDeleteAllData': [false],
    'from': [moment().subtract(2, 'days').startOf('day').toDate()],
    'to': [moment().add(1, 'days').startOf('day').toDate()],
    'stationKey': ['', [Validators.required]],
  });

  private navigationStation$    : Observable<IStation> = this.navigationStationStore.pipe(
    takeUntil(this.destroy$),
    select(selectNavigationStation)
  );

  private readonly PERMISSION_DENIED_STATUS = 403;

  constructor(
    private treeService: TreeService,
    private leftComponentsToggler: LeftComponentsTogglerService,
    private navigationStationStore: Store<INavigationStationState>,
    private fb: FormBuilder,
    private api: ApiCallService,
    private notifyStore: Store<fromNotify.INotifyState>
  ) { }

  public get shouldDeleteAllData(): AbstractControl {
    return this.form.get('shouldDeleteAllData');
  }

  public get from(): AbstractControl {
    return this.form.get('from');
  }

  public get to(): AbstractControl {
    return this.form.get('to');
  }

  public get stationKey(): AbstractControl {
    return this.form.get('stationKey');
  }

  public ngOnInit(): void {
    this.tree$ = this.treeService.getStationSettingsTreeStructure().pipe(
      takeUntil(this.destroy$)
    );
    this.state$ = this.leftComponentsToggler.getStationDataContentState().pipe(
      takeUntil(this.destroy$)
    );

    this.navigationStation$.subscribe((station: IStation): void => {
      this.navigationStation = station;
    });

    this.initDynamicValidation();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public canDelete(): boolean {
    return this.navigationStation && [WRITE_PERMISSION, READ_AND_WRITE_PERMISSION].indexOf(this.navigationStation.rights) !== -1;
  }

  public datepickerIsEnabled(): boolean {
    return !this.shouldDeleteAllData.value;
  }

  public buttonIsDisabled(): boolean {
    return !this.stationKey.value;
  }

  public clickDelete(): void {
    if (this.form.invalid) {
      return;
    }
    this.showErrorBlock = false;
    this.showConfirmBlock = true;
  }

  public cancelConfirmation(): void {
    this.showConfirmBlock = false;
  }

  public confirm(): void {
    if (this.form.invalid) {
      return;
    }
    this.showConfirmBlock = false;
    this.api.deleteWeatherData(this.prepareRequest()).subscribe(
      () => this.notifyStore.dispatch(setNotify('Data successfully scheduled for removal. Procedure may take up to 30 minutes')),
      (error: HttpErrorResponse): void => {
        if (error.status === this.PERMISSION_DENIED_STATUS) {
          this.notifyStore.dispatch(setNotify(this.permissionError));
        }
      }
    );
  }

  private initDynamicValidation(): void {
    this.shouldDeleteAllData.valueChanges.subscribe((shouldDeleteAllData: boolean): void => {
      if (shouldDeleteAllData) {
        this.from.clearValidators();
        this.to.clearValidators();
      } else {
        this.from.setValidators([Validators.required]);
        this.to.setValidators([Validators.required]);
      }
    });
  }

  private prepareRequest(): IDeleteWeatherDataRequest {
    const request: IDeleteWeatherDataRequest = {
      stationId: this.navigationStation.name.original,
      stationKey: this.stationKey.value
    };
    if (!this.shouldDeleteAllData.value) {
      request.from = dateToUtcUnixTimestamp(this.from.value);
      request.to = dateToUtcUnixTimestamp(this.to.value);
    }
    return request;
  }
}
