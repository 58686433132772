<div [@cardAnimation]>
  <app-form-layout>
    <app-card-header [header]="'API Key'"
                    [description]="'Your API public and private keys'"
                    helpButton="true"
                    (openModal)="openModal()">
    </app-card-header>

    <div class="api-key">
      <div class="row">

        <div class="col-md-6">
          <label>{{ 'Public key' | translate }}</label>
          <input #publicKey class="key" (click)="toClipboard(publicKey, 'Public')" readonly [value]="(apiAccess$ | async).hmac.public_key">
        </div>

        <div class="col-md-6">
          <label>{{ 'Private key' | translate }}</label>
          <input #privateKey class="key material" (click)="toClipboard(privateKey, 'Private')" readonly [value]="(apiAccess$ | async).hmac.private_key">
        </div>

      </div>

      <div class="row">
        <div class="col-md-12 api-key__submit">
          <app-custom-button [content]="'Generate new'" [color]="'red'" (click)="generateNewKeys()"></app-custom-button>
        </div>

      </div>

    </div>

  </app-form-layout>
</div>

<app-info-modal [modalId]="modalId" [header]="'API keys'">
  <app-api-key-modal></app-api-key-modal>
</app-info-modal>
