import { IOptions } from '../../../shared/interfaces';

export const cropTypeOptions: IOptions[] = [
  { value: '', content: '-- Choose crop --' },
  { value: 'barley', content: 'Barley' },
  { value: 'potatoes', content: 'Potatoes' },
  { value: 'maize', content: 'Maize' },
  { value: 'canola', content: 'Rapeseed (canola)' },
  { value: 'soy', content: 'Soya beans' },
  { value: 'wheat', content: 'Wheat' },
  { value: 'durum', content: 'Wheat (durum)' },
];

export const initialSoilMoistureOptions: IOptions[] = [
  { value: '', content: '-- Choose initial soil moisture --' },
  { value: 'full', content: 'Full (100%)' },
  { value: 'very_wet', content: 'Wet (ca. 90%)' },
  { value: 'wet', content: 'Normal (ca. 60%)' },
  { value: 'medium', content: 'Dry (ca. 40%)' },
  { value: 'dry', content: 'Very dry (ca. 20%)' },
];

export const soilTextureOptions: IOptions[] = [
  { value: '', content: '-- Choose soil texture (Wilting Point % - Field Capacity % ) --' },
  { value: 'sand', content: 'Sand (1-5%)' },
  { value: 'loamy_sand', content: 'Loamy Sand (4-9%)' },
  { value: 'sandy_loam', content: 'Sandy Loam (6-15%)' },
  { value: 'loam', content: 'Loam (15-28%)' },
  { value: 'silt_loam', content: 'Silt Loam (10-28%)' },
  { value: 'silt', content: 'Silt (4-28%)' },
  { value: 'sandy_clay_loam', content: 'Sandy Clay Loam (16-26%)' },
  { value: 'clay_loam', content: 'Clay Loam (20-33%)' },
  { value: 'silty_clay_loam', content: 'Silty Clay Loam (21-37%)' },
  { value: 'sandy_clay', content: 'Sandy Clay (24-35%)' },
  { value: 'silty_clay', content: 'Silty Clay (27-41%)' },
  { value: 'clay', content: 'Clay (37-48%)' },
  { value: 'custom', content: 'Custom' },
];

export const yieldUnitOptions: IOptions[] = [
  { value: false, content: 'Default' },
  { value: 'bu/ac', content: '(bu/ac)' },
  { value: 't/ha', content: '(t/ha)' }
];
