import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { IFromTo, IPicture, PhotoRequestType } from '../../../../shared/camera/models/camera';
import { dateToUtcUnixTimestamp } from '../../../../shared/utils/dateFormat';
import { getCameraSlideshowLastDate, getCameraSlideshowPhotos } from '../../actions/camera-slideshow';
import { ICameraSlideshowSettings, ICameraSlideshowState } from '../../models/camera-slideshow';
import {
  selectCameraSlideshowError,
  selectCameraSlideshowLoading,
  selectCameraSlideshowPictures,
  selectCameraSlideshowSettings
} from '../../reducers';

@Component({
  selector: 'app-camera-slideshow-content',
  templateUrl: './camera-slideshow-content.component.html',
  styleUrls: ['./camera-slideshow-content.component.scss']
})
export class CameraSlideshowContentComponent implements OnInit, OnDestroy {
  public isLoading$: Observable<boolean>;
  public isError$: Observable<boolean>;
  public isPlaying$: Observable<boolean>;
  public isFullscreen$: Observable<boolean>;
  public playSpeed$: Observable<number>;
  public activeSlide$: Observable<number>;
  public cameraSelected$: Observable<number>;

  public pictures$: Observable<Array<IPicture>>;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private stationId: string;
  private fromTo: IFromTo;
  private cameraID: number = 1;

  constructor(private cameraSlideshowStore: Store<ICameraSlideshowState>,
              private navigationStationStore: Store<INavigationStationState>) { }

  public ngOnInit(): void {
    this.initStatusListeners();
    this.initPictureListeners();
    this.initPhotosRetrieving();
    this.initLastDataRetrieving();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public refresh(): void {
    this.dispatchGetPhotos();
  }

  private initStatusListeners(): void {
    this.isLoading$ = this.cameraSlideshowStore.pipe(
      select(selectCameraSlideshowLoading)
    );
    this.isError$ = this.cameraSlideshowStore.pipe(
      select(selectCameraSlideshowError)
    );
    this.isFullscreen$ = this.cameraSlideshowStore.pipe(
      select(selectCameraSlideshowSettings),
      filter((settings: ICameraSlideshowSettings): boolean => Boolean(settings)),
      map((settings: ICameraSlideshowSettings): boolean => settings.isFullscreen)
    );
    this.isPlaying$ = this.cameraSlideshowStore.pipe(
      select(selectCameraSlideshowSettings),
      filter((settings: ICameraSlideshowSettings): boolean => Boolean(settings)),
      map((settings: ICameraSlideshowSettings): boolean => settings.isPlaying)
    );
    this.playSpeed$ = this.cameraSlideshowStore.pipe(
      select(selectCameraSlideshowSettings),
      filter((settings: ICameraSlideshowSettings): boolean => Boolean(settings)),
      map((settings: ICameraSlideshowSettings): number => settings.playSpeed)
    );
    this.activeSlide$ = this.cameraSlideshowStore.pipe(
      select(selectCameraSlideshowSettings),
      filter((settings: ICameraSlideshowSettings): boolean => Boolean(settings)),
      map((settings: ICameraSlideshowSettings): number => settings.activeSlide)
    );
    this.cameraSelected$ = this.cameraSlideshowStore.pipe(
      select(selectCameraSlideshowSettings),
      filter((settings: ICameraSlideshowSettings): boolean => Boolean(settings)),
      map((settings: ICameraSlideshowSettings): number => settings.cameraSelected)
    );
  }

  private initPictureListeners(): void {
    this.pictures$ = this.cameraSlideshowStore.pipe(
      takeUntil(this.destroy$),
      select(selectCameraSlideshowPictures),
      // Value mustn't be null
      map((pictures: Array<IPicture>): Array<IPicture> => pictures || [])
    );
  }

  private initPhotosRetrieving(): void {
    this.getFromToObservable().subscribe((fromTo: IFromTo): void => {
      this.fromTo = fromTo;
    });
    this.getStationChangeObservable().pipe(
      switchMap(() => this.getFromToObservable().pipe(take(1)))
    ).subscribe(() => {
      this.dispatchGetPhotos();
    });
  }

  private initLastDataRetrieving(): void {
    this.getStationChangeObservable().subscribe((): void => {
      this.cameraSlideshowStore.dispatch(getCameraSlideshowLastDate(this.stationId));
    });
  }

  private getFromToObservable(): Observable<IFromTo> {
    return this.cameraSlideshowStore.pipe(
      takeUntil(this.destroy$),
      select(selectCameraSlideshowSettings),
      filter((settings: ICameraSlideshowSettings): boolean => {
        return (settings.isLastDataMode && settings.fromLastData && settings.toLastData)
          || (settings.isLastDataMode === false && settings.fromDatepicker && settings.toDatepicker);
      }),
      map(this.prepareFromToCallback()),
      distinctUntilChanged((a: IFromTo, b: IFromTo): boolean => a.from === b.from && a.to === b.to)
    );
  }

  private getStationChangeObservable(): Observable<IStation> {
    return this.navigationStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((station: IStation): boolean => !!station),
      tap((station: IStation): void => {
        this.stationId = station.name.original;
      })
    );
  }

  private prepareFromToCallback(): (settings: ICameraSlideshowSettings) => IFromTo {
    return (settings: ICameraSlideshowSettings): IFromTo => {
      return settings.isLastDataMode
        ? {
          from: settings.fromLastData,
          to: settings.toLastData
        }
        : {
          from: dateToUtcUnixTimestamp(settings.fromDatepicker),
          to: dateToUtcUnixTimestamp(settings.toDatepicker)
        };
    };
  }

  private dispatchGetPhotos(): void {
    this.cameraSelected$.subscribe(event => this.cameraID = event);
    this.cameraSlideshowStore.dispatch(getCameraSlideshowPhotos({
      type: PhotoRequestType.MIN_MAX_INTERVAL,
      stationId: this.stationId,
      camId: this.cameraID,
      ...this.fromTo
    }));
  }
}
