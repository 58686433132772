import { IEnvironment } from '../../../environments/interfaces/environment';
import { firebaseConfig, environmentConfig } from '../../../environments/environment.config';
import { languageOptions } from './languages';
import { displayRulesConfig, themeConfig } from './theme.config';

export const environment: IEnvironment = {
  production: true,
  oauthUrl: environmentConfig.oauthUrl,
  apiUrl: environmentConfig.apiUrl,
  socketUrl: environmentConfig.socketUrl,
  myJohnDeereUrl: environmentConfig.myJohnDeereUrl,
  clientId: environmentConfig.clientId,
  clientSecret: environmentConfig.clientSecret,
  gMapKey: environmentConfig.gMapKey,
  gMapTZKey: environmentConfig.gMapTZKey,
  gMapEndPoint: environmentConfig.gMapEndPoint,
  debugPHP: false,
  displayRules: displayRulesConfig,
  theme: themeConfig,
  firebase: firebaseConfig,
  languages: languageOptions,
};
