<form [formGroup]="form"
      class="widget-add-form col-md-5">

  <div class="widget-add-form__control">
    <label class="">{{ 'Select the widget type'  | translate }}</label>
    <app-form-select [type]="'border'"
                     [options]="addWidgetDropDownOptions"
                     [formControl]="type"></app-form-select>
  </div>

  <div class="widget-add-form__control">
    <label>{{ 'Insert the widget title'  | translate }}</label>
    <app-form-input [type]="'border'"
                    [formControl]="title"></app-form-input>
  </div>

  <div class="widget-add-form__control">
    <label>{{ 'Insert the widget description'  | translate }}</label>
    <app-form-input [type]="'border'"
                    [formControl]="description"></app-form-input>
  </div>


  <div class="widget-add-form__radio">
    <label><input type="radio"
                  name="color"
                  value=""
                  [formControl]="color"> {{ 'White' | translate }}</label>
    <label><input type="radio"
                  name="color"
                  value="cht-alt"
                  [formControl]="color"> {{ 'Light gray' | translate }}</label>
    <label><input type="radio"
                  value="bgm-bluegray"
                  [formControl]="color"
                  name="color"> {{ 'Blue gray' | translate }}</label>
    <label><input type="radio"
                  value="bgm-green"
                  name="color"
                  [formControl]="color"> {{ 'Green' | translate }}</label>
    <label><input type="radio"
                  name="color"
                  value="bgm-blue"
                  [formControl]="color"> {{ 'Blue' | translate }}</label>
  </div>

</form>
