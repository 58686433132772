import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { IStation } from '../../../../core/models/stations';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { deepClone } from '../../../../shared/utils/deepClone';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { saveWarningsPhones } from '../../actions/warningsPhones';
import { selectWarningsPhones } from '../../reducers';
import * as fromWarningsPhones from '../../reducers/warningsPhones';
import { IWarningsPhonesState } from '../../reducers/warningsPhones';

@Component({
  selector: 'app-warnings-phones',
  templateUrl: './warnings-phones.component.html',
  styleUrls: ['./warnings-phones.component.scss']
})
export class WarningsPhonesComponent implements OnInit, OnDestroy {
  @Input()
  public station          : IStation;
  public warningsPhones   : IWarningsPhonesState;

  public modalId          : string = generateId();

  private destroy$        : Subject<boolean> = new Subject<boolean>();

  constructor(private modalService: ModalService,
              private warningsPhonesStore: Store<fromWarningsPhones.IWarningsPhonesState>) { }

  public ngOnInit(): void {
    this.warningsPhonesStore.pipe(
      select(selectWarningsPhones)
    ).subscribe((w: IWarningsPhonesState) => {
      this.warningsPhones = w;
    });
  }

  public save(): void {
    const s: IStation = deepClone(this.station);
    if (s.warnings !== undefined) {
      s.warnings.sms_numbers = this.warningsPhones.phones;
    }
    this.warningsPhonesStore.dispatch(saveWarningsPhones({
      station: s,
      save: {
        'warnings.sms_numbers': this.warningsPhones.phones
      }
    }));
  }

  public openModal(): void {
    this.modalService.openModal(this.modalId);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
