import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { IYpState } from './../models/model';
import { take } from 'rxjs/operators';
import { setYpWarningMessage } from '../actions';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class YieldPredictionWarningsService {
  private pleaseNoteString: string;
  private daysToRenew: string;
  private licenseExpiredOn: string;
  private toRenew: string;

  constructor(
    private translate: TranslateService,
    private store: Store<IYpState>
  ) {
    this.getTranslatedStrings();
  }

  public setYieldPredictionWarning(selectedLicense: any): boolean {
    const licenseEndDate: moment.Moment = moment(selectedLicense.end_date);
    const ypOnlyLicense: boolean = !selectedLicense.Irrimet && !selectedLicense.SoilMoisture && selectedLicense.YieldPrediction;
    let warningMessage: string|null = null;
    let icon: boolean = false;

    if (ypOnlyLicense) {
      const daysUntilEndLicense = licenseEndDate.diff(moment(), 'days');
      if (daysUntilEndLicense <= 0) {
        warningMessage = this.licenseExpiredOn + ' ' + licenseEndDate.format('DD-MM-YYYY') + '. ' + this.toRenew;
        icon = true;
      } else if (daysUntilEndLicense <= 10) {
        warningMessage = this.pleaseNoteString + ' ' + daysUntilEndLicense + ' ' + this.daysToRenew;
      }
    }

    this.store.dispatch(setYpWarningMessage(warningMessage, icon));
    /* return true; */
    return ypOnlyLicense;
  }

  private getTranslatedStrings(): void {
    this.translate.get('Please note: your license will expire in').pipe(take(1)).subscribe((translatedText: string) => {
      this.pleaseNoteString = translatedText;
    });
    this.translate.get('days. To renew it, please contact your METOS salesperson.').pipe(take(1)).subscribe((translatedText: string) => {
      this.daysToRenew = translatedText;
    });
    this.translate.get('Your license has expired on').pipe(take(1)).subscribe((translatedText: string) => {
      this.licenseExpiredOn = translatedText;
    });
    this.translate.get('To renew it, please contact your METOS salesperson.').pipe(take(1)).subscribe((translatedText: string) => {
      this.toRenew = translatedText;
    });
  }
}
