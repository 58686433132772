import { Pipe, PipeTransform } from '@angular/core';
import {IStation} from '../../../../core/models/stations';
import {findCropView, findTracker} from '../../utils/findStations';

@Pipe({
  name: 'trackStation'
})
export class TrackStationPipe implements PipeTransform {

  public transform(stations: Array<IStation>): Array<IStation> {
    return Array.isArray(stations) ? findTracker(stations)
      : [];
  }

}
