import { Component, EventEmitter, OnDestroy, OnInit, Inject, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, takeWhile } from 'rxjs/operators';
import { ICropZone } from '../../../../core/models/cropzones';
import { IStation } from '../../../../core/models/stations';
import { selectCropzones, selectStations } from '../../../../core/reducers';
import * as fromCropzones from '../../../../core/reducers/cropzones';
import * as fromStations from '../../../../core/reducers/stations';
import { IOptions } from '../../../../shared/interfaces';
import { AddWidgets, cropzoneWidgetsIdList, stationWidgetsIdList } from '../../constants/constants';
import { IAddWidgetForm, IAddWidgetType, IWidget } from '../../models/dashboard.models';
import { findCropView, findDisease, findForecast, findIscout, findSimple, findTracker } from '../../utils/findStations';
import { makeWidget } from '../../utils/makeWidget';
import { IThemeConfig } from '../../../../../environments/interfaces/theme';
import { environmentToken } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/interfaces/environment';

@Component({
  selector: 'app-widget-creation',
  templateUrl: './widget-creation.component.html',
  styleUrls: ['./widget-creation.component.scss']
})
export class WidgetCreationComponent implements OnInit, OnDestroy {
  @Output()
  private addWidget: EventEmitter<IWidget> = new EventEmitter();
  @Output()
  private closeWidget: EventEmitter<void> = new EventEmitter();

  public selectedWidget           : IAddWidgetType = AddWidgets[0];
  public addWidgetDropDownOptions : Array<IOptions> = [];
  public formValues               : IAddWidgetForm = {
    type: 'stations-list',
    title: '',
    description: '',
    color: ''
  };
  private alive                   : boolean = true;
  private stations                : Array<IStation>;
  private cropzones               : Array<ICropZone> = [];
  private cropViewStations        : Array<IStation>;
  private iscoutStations          : Array<IStation>;
  private simpleStations          : Array<IStation>;
  private forecastStations        : Array<IStation>;
  private diseaseStations         : Array<IStation>;
  private trackStations           : Array<IStation>;
  private addWidgets              : Array<IAddWidgetType> = AddWidgets;
  private stationWidgetsIdList    : Array<string> = stationWidgetsIdList;
  private cropzoneWidgetsIdList   : Array<string> = cropzoneWidgetsIdList;

  public subDomain                : IThemeConfig;

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    private stationsStore: Store<fromStations.IStations>,
    private cropzonesStore: Store<fromCropzones.ICropZones>
  ) {
      this.subDomain = environment.theme;

      if (this.subDomain && this.subDomain.hasOwnProperty('hideDashboardMap')) {
        if (this.subDomain.hideDashboardMap) {
          this.addWidgets = this.addWidgets.filter(widget => widget.id !== 'google-map');
        }
      }
  }

  public ngOnInit(): void {

    this.cropzonesStore.pipe(
      select(selectCropzones),
      takeWhile(() => this.alive),
      filter((cropzones: Array<ICropZone>, index: number) => !!(cropzones && cropzones.length)),
      catchError(() => of(this.close()))
    ).subscribe((cropzones: Array<ICropZone>) => this.cropzones = cropzones);

    this.stationsStore.pipe(
      select(selectStations),
      takeWhile(() => this.alive),
      filter((stations: Array<IStation>, index: number) => !!(stations && stations.length)),
      catchError(() => of(this.close()))
    ).subscribe((stations: Array<IStation>) => {
      this.alive = false;
      this.stations = stations;

      this.cropViewStations = findCropView(this.stations);
      this.iscoutStations = findIscout(this.stations);
      this.simpleStations = findSimple(this.stations);
      this.forecastStations = findForecast(this.stations);
      this.diseaseStations = findDisease(this.stations);
      this.trackStations = findTracker(this.stations);

      this.addWidgets.forEach((w: IAddWidgetType) => {
        if ((w.id === 'cropview' && !this.cropViewStations.length)
          || (w.id === 'iscout' && !this.iscoutStations.length)
          || (w.id === 'sd-forecast' && !this.forecastStations.length)
          || (w.id === 'sd-forecast-chart' && !this.forecastStations.length)
          || (w.id === 'forecast-table' && !this.forecastStations.length)
          || (w.id === 'sd-diseasemodels' && !this.diseaseStations.length)
          || (w.id === 'sd-trackers' && !this.trackStations.length)
          || (w.id === 'cropzone-list' && !this.cropzones.length)
          || ((w.id === 'sd-highcharts' || w.id === 'sd-data')
            && !this.simpleStations.length
            && !this.cropViewStations.length
            && !this.iscoutStations.length)) {
          return;
        }
        this.addWidgetDropDownOptions.push({
          value: w.id,
          content: w.name
        });
      });
    });
  }

  public changeForm(form: IAddWidgetForm): void {
    this.formValues = {
      ...form
    };
    const newSelectedWidget = this.addWidgets.find((w) => w.id === form.type);
    if (this.selectedWidget.id !== newSelectedWidget.id) {
      this.selectedWidget = newSelectedWidget;
    }
  }

  public close(): void {
    this.closeWidget.emit();
  }

  public add(): void {
    if (this.stationWidgetsIdList.includes(this.selectedWidget.id)) {
      this.selectStation();
    } else if (this.cropzoneWidgetsIdList.includes(this.selectedWidget.id)) {
      this.selectCropzone();
    } else {
      return;
    }
  }

  private selectStation(): void {
    let station: IStation;
    switch (this.formValues.type) {
      case 'cropview': {
        station = this.cropViewStations[0];
        break;
      }
      case 'iscout': {
        station = this.iscoutStations[0];
        break;
      }
      case 'sd-forecast': {
        station = this.forecastStations[0];
        break;
      }
      case 'sd-forecast-chart': {
        station = this.forecastStations[0];
        break;
      }
      case 'forecast-table': {
        station = this.forecastStations[0];
        break;
      }
      case 'sd-diseasemodels': {
        station = this.diseaseStations[0];
        break;
      }
      case 'sd-trackers': {
        station = this.trackStations[0];
        break;
      }
      case 'sd-highcharts':
      case 'sd-data': {
        station = this.stations[0];
        break;
      }
      case 'wind-rose-diagram': {
        station = this.stations[0];
        break;
      }
      default: {
        station = this.stations[0];
        break;
      }
    }
    this.addWidget.emit(makeWidget(station, this.formValues));
    this.close();
  }

  private selectCropzone(): void {
    const cropzone: ICropZone = this.cropzones[0];
    const station: IStation = this.stations[0];

    this.addWidget.emit(makeWidget(station, this.formValues, cropzone));
    this.close();
  }

  public ngOnDestroy(): void {
    this.alive = false;
  }

}
