import { INotification } from './../models/notifications';
import {IActionWithPayload} from '../models/actionWithPayload';
import {Action} from '@ngrx/store';
import { INotifications } from '../reducers/notifications';
import { IUserWebToken } from '../models/notifications';

export const enum NotificationsActionTypes {
  GET_USER_NOTIFICATIONS = '[Account] GET_USER_NOTIFICATIONS',
  SET_USER_NOTIFICATIONS = '[Account] SET_USER_NOTIFICATIONS',
  CLEAR_USER_NOTIFICATIONS = '[Account] CLEAR_USER_NOTIFICATIONS',
  SAVE_USER_WEB_TOKEN = '[Account] SAVE_USER_WEB_TOKEN',
  SET_SELECTED_NOTIFICATION = '[Account] SET_SELECTED_NOTIFICATION'
}

export function getUserNotifications(): Action {
  return { type: NotificationsActionTypes.GET_USER_NOTIFICATIONS };
}

export function setUserNotifications(data: INotifications): IActionWithPayload {
  return { type: NotificationsActionTypes.SET_USER_NOTIFICATIONS, payload: data };
}

export function clearUserNotifications(): Action {
  return { type: NotificationsActionTypes.CLEAR_USER_NOTIFICATIONS };
}

export function saveUserWebToken(payload: IUserWebToken): IActionWithPayload {
  return { type: NotificationsActionTypes.SAVE_USER_WEB_TOKEN, payload };
}

export function setSelectedNotification(notification: INotification): IActionWithPayload {
  return { type: NotificationsActionTypes.SET_SELECTED_NOTIFICATION, payload: notification };
}
