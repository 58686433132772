import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { distinctUntilChanged, takeWhile, map, filter } from 'rxjs/operators';
import { IStation } from '../../../../../../core/models/stations';
import { selectStations } from '../../../../../../core/reducers';
import { IStations } from '../../../../../../core/reducers/stations';
import { hourlyTrackerData } from '../../../../constants/constants';
import { ITrackerWidgetRequest, IWidget } from '../../../../models/dashboard.models';
import * as fromDashboard from '../../../../reducers/dashboard';
import { deepClone } from '../../../../../../shared/utils/deepClone';

@Component({
  selector: 'app-track-widget-settings',
  templateUrl: './track-widget-settings.component.html',
  styleUrls: ['./track-widget-settings.component.css']
})
export class TrackWidgetSettingsComponent implements OnInit, OnDestroy {

  @Input()
  public widget                     : IWidget;
  @Input()
  private widgetIndex               : number;
  @Input()
  private columnIndex               : number;
  @Input()
  private rowIndex                  : number;
  @Input()
  private trackerViewRequest        : ITrackerWidgetRequest;
  @Output()
  private updateRequest             : EventEmitter<ITrackerWidgetRequest> = new EventEmitter();
  @Output()
  private updateWidgetData          : EventEmitter<ITrackerWidgetRequest> = new EventEmitter();

  public isEdit$                    : Observable<boolean>;

  public form                       : FormGroup;
  public stations$                  : Observable<Array<IStation>>;
  public periodScopeOptions         : any = hourlyTrackerData;
  private alive                     : boolean = true;

  constructor(private dashboardStore: Store<fromDashboard.IDashboard>,
              private stationsStore: Store<IStations>,
              private formBuilder: FormBuilder) {}

  public get station(): AbstractControl {
    return this.form.get('station');
  }

  public get periodScope(): AbstractControl {
    return this.form.get('periodScope');
  }

  public ngOnInit(): void {
    this.stations$ = this.stationsStore.pipe(
      select(selectStations),
      filter((stations: IStation[]) => !!stations && stations.length > 0),
      map((stations: IStation[]) => this.sortArray(stations))
    );

    const timeDifference = new Date(this.trackerViewRequest.to * 1000).getTime() - new Date(this.trackerViewRequest.from * 1000).getTime();
    const daysDifference = Math.round(timeDifference / (1000 * 3600 * 24)).toString();
    this.form = this.formBuilder.group({
      'station': new FormControl(this.trackerViewRequest.station),
      'periodScope': new FormControl(daysDifference)
    });
    this.submit();
    localStorage.setItem('trackerPeriodScope', this.periodScope.value);

    this.form.valueChanges.pipe(
      distinctUntilChanged(),
      takeWhile(() => this.alive)
    ).subscribe(() => {
      this.changeRequest();
    });
  }

  private sortArray(stationArray: IStation[]): IStation[] {
    const stations = deepClone(stationArray);
    const customNamedStations = stations.filter((station) => station.name.custom !== '');
    customNamedStations.sort((a, b) => {
      const nameA = a.name.custom.toLowerCase();
      const nameB = b.name.custom.toLowerCase();

      const isNumberA = !isNaN(Number(nameA));
      const isNumberB = !isNaN(Number(nameB));

      if (isNumberA && isNumberB) {
        return Number(nameA) - Number(nameB);
      } else if (isNumberA) {
        return -1;
      } else if (isNumberB) {
        return 1;
      } else {
        return nameA.localeCompare(nameB);
      }
    });

    const otherStations = stations.filter((station) => station.name.custom === '');
    otherStations.sort((a: IStation, b: IStation) => a.name.original.toLowerCase() < b.name.original.toLowerCase() ? -1 : 1);

    return customNamedStations.concat(otherStations);
  }

  public changeRequest(): void {
    const newRequest = {
        to: moment().unix(),
        from: moment().subtract(this.periodScope.value, 'd').unix(),
        station: this.station.value,
    };
    this.updateRequest.emit(newRequest);
  }

  public submit(): void {
    localStorage.setItem('trackerPeriodScope', this.periodScope.value);
    const newRequest = {
      to: moment().unix(),
      from: moment().subtract(this.periodScope.value, 'd').unix(),
      station: this.station.value,
    };
    this.updateWidgetData.emit(newRequest);
  }

  public ngOnDestroy(): void {
    this.alive = false;
  }
}
