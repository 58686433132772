import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { LedTrapConfigLimits } from '../../../constants/constants';

@Component({
  selector: 'app-station-led-trap-settings',
  templateUrl: './station-led-trap-settings.component.html',
  styleUrls: ['./station-led-trap-settings.component.scss']
})
export class StationLedTrapSettingsComponent implements OnInit, OnDestroy {
  @Input()
  private temperatureThreshold: number;

  @Output()
  private changeTemperatureThreshold: EventEmitter<number> = new EventEmitter<number>();

  public form: FormGroup;
  public ledTrapConfigLimits = LedTrapConfigLimits;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private fb: FormBuilder) {
  }

  public get temperatureThresholdControl(): AbstractControl {
    return this.form.get('temperatureThreshold');
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      temperatureThreshold: [this.temperatureThreshold, []]
    });

    this.temperatureThresholdControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      debounceTime(100)
    ).subscribe((value: number) => {
      this.changeTemperatureThreshold.emit(+value);
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
