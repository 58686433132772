import { Component, Input, OnDestroy, OnInit, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, mergeMap, skip, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { setNotify } from '../../../../core/actions/notify';
import { IStation } from '../../../../core/models/stations';
import { ICalibrationSetting } from '../../../../core/models/system';
import * as fromNotify from '../../../../core/reducers/notify';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { environmentToken } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../../../environments/interfaces/theme';
import { IOptions, ISensor } from '../../../../shared/interfaces';
import { INodes, ISerialNames } from '../../../sensors-and-nodes/models/sensors-and-nodes';
import { addServerAlert } from '../../actions/server-alerts';
import { aggrType, alertTypes, channelOptions, resendOptions } from '../../constants/server-alerts';
import { selectStationSensors } from '../../reducers';
import * as fromServerAlerts from '../../reducers/server-alerts';
import { ServerAlertsService } from '../../services/server-alerts.service';

@Component({
  selector: 'app-server-alerts-form',
  templateUrl: './server-alerts-form.component.html',
  styleUrls: ['./server-alerts-form.component.scss']
})
export class ServerAlertsFormComponent implements OnInit, OnDestroy {

  @Input()
  public station          : IStation;

  public alertForm        : FormGroup;
  public isLoading        = true;
  public alertTypes       = alertTypes;
  public stationSensors$  : Observable<any>;
  public resendOptions    = resendOptions;
  public channelOptions   = channelOptions;
  public sensorOptions    : Array<IOptions> = [];
  public sensorsApi       : Array<ISensor> = [];
  public sensors          : Array<IOptions> = [];
  private destroy$        = new Subject<boolean>();
  public optionUnit       : string;
  public defaultSensor    : string;

  public subDomain        : IThemeConfig;

  public readonly formatError   = 'Format issue - please enter the right format (1, -1.2, 3.45)';
  public readonly emailError    = 'Introduced email doesn\'t exist';

  public calibrationSettings    : ICalibrationSetting[] = [];
  public nodeCalibration        : { [node: string]: { [sensor: string]: IOptions[]|boolean } } = {};
  public nodes                  : INodes;
  public serials                : ISerialNames;

  constructor(
    @Inject(environmentToken) environment : IEnvironment,
    private formBuilder         : FormBuilder,
    protected alertsService     : ServerAlertsService,
    protected serverAlertsStore : Store<fromServerAlerts.IServerAlertsState>,
    protected notifyStore       : Store<fromNotify.INotifyState>,
    private api                 : ApiCallService,
  ) {
    this.subDomain = environment.theme;
  }

  public ngOnInit(): void {

    this.stationSensors$ = this.serverAlertsStore.pipe(
      select(selectStationSensors),
      takeUntil(this.destroy$)
    );

    this.stationSensors$.pipe(
      map((sensors: ISensor[]) => {
        this.sensorsApi = sensors;
      }),
      switchMap(() => this.api.getStationNodes(this.station.name.original).pipe(
        tap((nodes: INodes) => {
          this.nodes = nodes;
        })
      )),
      switchMap(() => this.api.getStationSerials(this.station.name.original).pipe(
        tap((serials: ISerialNames) => {
          this.serials = serials;
        })
      )),
    ).subscribe(() => {
      this.sensors = this.alertsService.setListOfSensors(this.sensorsApi, this.nodes, this.serials);
      if (this.sensors.length > 0) {
        this.defaultSensor = this.sensors[0].value;
      }
      this.createForm();
    });

   this.stationSensors$.pipe(
      skip(1), // Ignore the first station
      tap(() => this.isLoading = false)
    ).subscribe(() => this.createForm());
  }

  public get threshold(): AbstractControl {
    return this.alertForm.get('threshold');
  }

  public get email(): AbstractControl {
    return this.alertForm.get('email').get('destination');
  }

  public createForm(): void {
    this.alertForm = this.formBuilder.group({
      threshold: ['', [Validators.required, this.alertsService.checkThreshold()]],
      type: ['min', [Validators.required]],
      sensor: [this.defaultSensor, [Validators.required]],
      resend: [0, [Validators.required]],
      email: this.createTarget('email'),
      push_web: this.createTarget('push_web', false),
      push_mobile: this.createTarget('push_mobile', true),
    });

    this.email.setValidators([this.alertsService.checkEmail()]);

    this.alertForm.get('type')
      .valueChanges
      .pipe(
        startWith('min'),
        takeUntil(this.destroy$),
        mergeMap(value =>
          this.alertsService.filterOptions(value, this.sensors)
        )
      ).subscribe(sensors => this.sensorOptions = sensors);

    this.alertForm.get('sensor')
      .valueChanges
      .pipe(
        startWith(this.defaultSensor),
        takeUntil(this.destroy$),
      ).subscribe(data => {
        if (data) {
        const sensorData = data.split('_');
        this.optionUnit = sensorData[sensorData.length - 1];
        }
      });
  }

  public createTarget(method: string, data: string | boolean = ''): FormGroup {
    return this.formBuilder.group({
      method: [method, [Validators.required]],
      destination: data
    });
  }

  public save(): void {

    if (this.alertForm.valid && this.station.name.original) {

      const { email, push_mobile, push_web, sensor, resend, threshold, ...form } = this.alertForm.value;

      const sensorData = sensor.split('_');
      const thresholds = threshold.split(',').map(Number);
      const sensorChannel = +sensorData[0];
      const sensorCode = +sensorData[1];
      const aggrCode = sensorData[2];
      const aggr = aggrType[aggrCode];
      const active = true;
      const resendPeriod = Number(resend);
      const source = '';

      const targets = [email, push_mobile, push_web]
        .filter(_ => _.destination)
        .map(_ => ({ ..._, destination: String(_.destination) }));

      this.serverAlertsStore.dispatch(
        addServerAlert({
          id: this.station.name.original,
          alert: { ...form, thresholds, sensorChannel, sensorCode, aggr, targets, active, resendPeriod, source }
        })
      );

      this.createForm();

    } else {
      this.notifyStore.dispatch(setNotify('Something went wrong. Please check your form data'));
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
