import { AfterViewInit, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import {environmentToken} from '../../../environments/environment';
import {IEnvironment} from '../../../environments/interfaces/environment';
import {IThemeConfig} from '../../../environments/interfaces/theme';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent implements AfterViewInit {
  @Input()
  public type                           : string;
  @Output()
  private headerBackground              : EventEmitter<string> = new EventEmitter();
  @Output()
  private headerClass                   : EventEmitter<string> = new EventEmitter();
  @Output()
  private hideVersion                   : EventEmitter<boolean> = new EventEmitter();

  public subDomain                      : IThemeConfig;

  constructor(@Inject(environmentToken) environment: IEnvironment) {
    this.subDomain = environment.theme;
  }

  public ngAfterViewInit(): void {
    // Avoiding the ExpressionChangedAfterItHasBeenCheckedError
    window.setTimeout(() => {
      if (this.subDomain.hasOwnProperty('headerBackground')) {
        this.headerBackground.emit(this.subDomain.headerBackground);
      }

      if (this.subDomain.hasOwnProperty('headerClass')) {
        this.headerClass.emit(this.subDomain.headerClass);
      }

      if (this.subDomain.hasOwnProperty('hideVersion') && this.subDomain.hideVersion) {
        this.hideVersion.emit(true);
      }
    });
  }
}
