import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, pluck, takeUntil } from 'rxjs/operators';
import { DEFAULT_CAMM_ID } from '../../../../core/constants/camera';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import {
  ICameraPest,
  ICameraPestOrder,
  IClearMeasurementsPayload,
  IDeleteImagePayload,
  IPestToggler,
  IPicture,
  ISaveMeasurementsPayload,
  IToolbarSettings
} from '../../../../shared/camera/models/camera';
import { RectangleService } from '../../../../shared/camera/services/rectangles/rectangle.service';
import { IOptions } from '../../../../shared/interfaces';
import { IOptionGroup } from '../../../../shared/multilevel-dropdown/models';
import {
  clearIscoutMeasurements,
  deleteIscoutImage,
  getIscoutGeneralPests,
  getIscoutUserPests,
  saveIscoutMeasurements
} from '../../actions/iscout';
import { getIscoutPestsOrders } from '../../actions/iscout-pests';
import {
  getIscoutSettingsPestToggles,
  setIscoutSettingsSelectedPicture,
  setIscoutSettingsToolbarSettings
} from '../../actions/iscout-settings';
import { IIscoutPestsState, IIscoutSettingsState, IIscoutState } from '../../models/iscout.models';
import { selectIscoutNextPicture, selectIscoutPestsOrders } from '../../reducers';

@Component({
  selector: 'app-iscout-image-settings-toolbar',
  templateUrl: './iscout-image-settings-toolbar.component.html',
  styleUrls: ['./iscout-image-settings-toolbar.component.scss']
})
export class IscoutImageSettingsToolbarComponent implements OnInit, OnDestroy {
  @Input()
  public selectedPicture: IPicture;
  @Input()
  public toolbarSettings$: Observable<IToolbarSettings>;
  @Input()
  public totalCount: number = 0;
  @Input()
  public newCount: number = 0;
  @Input()
  public pestToggles$: Observable<Array<IPestToggler>>;
  @Input()
  public availablePestsOptions$: Observable<Array<Array<ICameraPest>>>;
  @Input()
  public hasWritePermission: boolean;
  @Output()
  public toggleEmitter: EventEmitter<IPestToggler> = new EventEmitter<IPestToggler>();
  @Output()
  public selectedPestEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  public unselectPictureEmitter: EventEmitter<void> = new EventEmitter<void>();

  public currentDate = new Date();
  public selectedRectangleLabel: FormControl = new FormControl('');
  public pestGroups: Array<IOptionGroup>;
  public pestOrders: Array<ICameraPestOrder>;

  private stationId: string;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private iscoutStore: Store<IIscoutState>,
              private iscoutPestStore: Store<IIscoutPestsState>,
              private iscoutSettingsStore: Store<IIscoutSettingsState>,
              private rectangleService: RectangleService,
              private navigationStationStore: Store<INavigationStationState>) {
  }

  public ngOnInit(): void {
    this.initAvailablePestsOrders();
    this.initAvailablePests();
    this.initRetrievePests();
    this.initSelectedRectangleLabel();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public saveMeasurements(data: ISaveMeasurementsPayload): void {
    this.iscoutStore.dispatch(saveIscoutMeasurements(data));
  }

  public clearMeasurements(data: IClearMeasurementsPayload): void {
    this.selectedPicture.rectangles = [];
    this.selectedPicture.filteredRectangles = [];
    this.iscoutStore.dispatch(clearIscoutMeasurements(data));
  }

  public deleteImage(data: IDeleteImagePayload): void {
    data.refreshRequest.camIds = [DEFAULT_CAMM_ID];
    this.iscoutStore.dispatch(deleteIscoutImage(data));
  }

  public saveToolbarSettings(data: IToolbarSettings): void {
    this.iscoutSettingsStore.dispatch(setIscoutSettingsToolbarSettings(data));
  }

  public togglePest(pestToggler: IPestToggler): void {
    this.toggleEmitter.emit(pestToggler);
  }

  public unselectPicture(): void {
    this.unselectPictureEmitter.emit();
  }

  public isMultipleSelection(): boolean {
    return this.rectangleService.isMultipleRectangleSelected();
  }

  public unselectMultiple(): void {
    this.rectangleService.clearMultipleRectanglesSelection();
  }

  public nextPicture(currentPicture: IPicture): void {
    this.triggerPictureChange(currentPicture, true);
  }

  public previousPicture(currentPicture: IPicture): void {
    this.triggerPictureChange(currentPicture, false);
  }

  private triggerPictureChange(currentPicture: IPicture, next: boolean): void {
    combineLatest([]);
    this.iscoutStore
      .pipe(
        takeUntil(this.destroy$),
        select(selectIscoutNextPicture, { currentPicture, next }),
      )
      .subscribe((nextPicture: IPicture) => {
        this.iscoutSettingsStore.dispatch(setIscoutSettingsSelectedPicture(nextPicture));
      })
      .unsubscribe();
  }

  private initAvailablePests(): void {
    this.availablePestsOptions$.subscribe(([userPests, generalPests]: Array<Array<ICameraPest>>) => {
      this.setDefaultPest(userPests, generalPests);

      const groupedPests = userPests
        .slice()
        .concat(generalPests)
        .reduce(function (result, current): Array<{string: Array<ICameraPest>}> {
          result[current.order.name] = (result[current.order.name] || []).concat(current);
          return result;
        }, []);

      this.pestGroups = Object.keys(groupedPests)
        .map(key => ({
          name: this.hasCommonName(key),
          options: groupedPests[key].map((pest) => ({
            value: pest.name,
            content: pest.name
          }))
        }));
    });
  }

  private initAvailablePestsOrders(): void {
    this.iscoutStore.pipe(
      select(selectIscoutPestsOrders)
    ).subscribe((filteredOrders: Array<ICameraPestOrder>) => {
      this.pestOrders = filteredOrders;
    });
  }

  private hasCommonName(order: string): string {
    const filteredOrder = this.pestOrders.filter(item => item.name === order);
    return filteredOrder[0]?.common_name ? filteredOrder[0]?.common_name + ' - ' + order : order;
  }

  private setDefaultPest(userPests: ICameraPest[], generalPests: ICameraPest[]): void {
    let defaultPest = '';
    if (userPests && userPests[0]) {
      defaultPest = userPests[0].name;
    } else if (generalPests && generalPests[0]) {
      defaultPest = generalPests[0].name;
    }
    this.selectedRectangleLabel.setValue(defaultPest);
  }

  private getCameraPestAsOptions(pests: Array<ICameraPest>): Array<IOptions> {
    return pests.filter(
      (pest: ICameraPest): boolean => !!pest.name
    ).map((pest: ICameraPest): IOptions => {
      return {
        value: pest.name,
        content: pest.name,
      };
    });
  }

  private initRetrievePests(): void {
    this.getStationIdChangeObservable().subscribe((): void => {
      this.iscoutPestStore.dispatch(getIscoutPestsOrders());
      this.iscoutPestStore.dispatch(getIscoutUserPests(this.stationId));
      this.iscoutPestStore.dispatch(getIscoutGeneralPests(this.stationId));
      this.iscoutSettingsStore.dispatch(getIscoutSettingsPestToggles(this.stationId));
    });
  }

  private getStationIdChangeObservable(): Observable<void> {
    return this.navigationStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((station: IStation): boolean => !!station),
      distinctUntilChanged((a: IStation, b: IStation): boolean => a.name.original === b.name.original),
      pluck('name', 'original'),
      map((stationId: string): void => {
        this.stationId = stationId;
      })
    );
  }

  private initSelectedRectangleLabel(): void {
    this.selectedRectangleLabel.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(200),
      distinctUntilChanged()
    ).subscribe((selectedRectangleLabel: string) => {
      this.selectedPestEmitter.emit(selectedRectangleLabel);
    });
  }
}
