import {Directive, ElementRef, HostListener, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements OnChanges {
  @Input()
  private tooltipTitle          : string = '';
  @Input()
  private tooltipDisable        : boolean = false;
  @Input()
  private tooltipPlacement      : string = 'bottom';
  @Input()
  private tooltipColor          : string = '#333333';
  @Input()
  private tooltipWidth          : string = '';
  private tooltipContainer      : ElementRef;
  private arrow                 : ElementRef;

  constructor(private element: ElementRef, private render: Renderer2) { }

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    this.destroy();
    if (this.tooltipDisable) {
      return;
    }
    const elRect: ClientRect = this.element.nativeElement.getBoundingClientRect();

    const text = this.render.createText(this.tooltipTitle);
    this.arrow = this.render.createElement('div');
    this.render.setStyle(this.arrow, `border-${this.tooltipPlacement}-color`, this.tooltipColor);
    this.tooltipContainer = this.render.createElement('div');

    this.render.addClass(this.arrow , this.tooltipPlacement + '-arrow');

    this.render.appendChild(this.tooltipContainer, text);
    this.render.appendChild(this.tooltipContainer, this.arrow);

    this.render.addClass(this.tooltipContainer, 'custom-tooltip');
    this.render.setStyle(this.tooltipContainer, 'background-color', this.tooltipColor);
    this.render.appendChild(document.body, this.tooltipContainer);
    if (this.tooltipWidth) {
      this.render.setStyle(this.tooltipContainer, 'max-width', this.tooltipWidth);
    }

    const tooltipContainerEl: Element = document.querySelector('.custom-tooltip');
    const containerRect: ClientRect = tooltipContainerEl.getBoundingClientRect();

    if (typeof this[this.tooltipPlacement] === 'function') {
      this[this.tooltipPlacement](elRect, containerRect);
    } else {
      this.destroy();
    }

    this.render.setStyle(this.tooltipContainer, 'opacity', 1);
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.destroy();
  }

  @HostListener('focusout')
  public onFocusOut(): void {
    this.destroy();
  }

  private destroy(): void {
    if (this.tooltipContainer) {
      this.render.removeChild(document.body, this.tooltipContainer);
    }
  }

  private bottom(parentRect: ClientRect, tooltipRect: ClientRect): void {
    const shift: number = tooltipRect.width - parentRect.width;
    this.render.setStyle(this.tooltipContainer, 'left', parentRect.left - shift / 2 + 'px');
    this.render.setStyle(this.tooltipContainer, 'top', parentRect.top + parentRect.height + 8 + 'px');
  }

  private top(parentRect: ClientRect, tooltipRect: ClientRect): void {
    const shift: number = tooltipRect.width - parentRect.width;
    this.render.setStyle(this.tooltipContainer, 'left', parentRect.left - shift / 2 + 'px');
    this.render.setStyle(this.tooltipContainer, 'bottom', document.documentElement.clientHeight - parentRect.top + 8 + 'px');
  }

  private left(parentRect: ClientRect, tooltipRect: ClientRect): void {
    const shift: number = tooltipRect.height - parentRect.height;
    this.render.setStyle(this.tooltipContainer, 'left', parentRect.right - shift / 2 + 'px');
    this.render.setStyle(this.tooltipContainer, 'bottom', document.documentElement.clientHeight - parentRect.top + 8 + 'px');
  }

  private right(parentRect: ClientRect, tooltipRect: ClientRect): void {
    const shift: number =  parentRect.height - tooltipRect.height;
    this.render.setStyle(this.tooltipContainer, 'left', parentRect.right + 8 + 'px');
    this.render.setStyle(this.tooltipContainer, 'top', parentRect.top + shift / 2 + 'px');
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.tooltipDisable) {
      this.destroy();
    }
  }
}
