export const MeterValues = {
  dualex: [
    { content: 'Temp', value: 'temp' },
    { content: 'CHL', value: 'chl' },
    { content: 'FLAV', value: 'flav' },
    { content: 'ANTH', value: 'anth' },
    { content: 'NBI', value: 'nbi' },
  ],
  soilguard: [
    { content: 'VWC', value: 'vwc' },
    { content: 'Temperature', value: 'temp' }
  ],
  mobilab: [
    { content: 'Nitrate', value: 'nitrate' },
    { content: 'Sulfate', value: 'sulfate' },
    { content: 'Ammonium', value: 'ammonium' },
    { content: 'Chloride', value: 'chloride' },
    { content: 'Potassium', value: 'potassium' },
    { content: 'Sodium', value: 'sodium' },
    { content: 'Calcium', value: 'calcium' },
    { content: 'Magnesium', value: 'magnesium' },
    { content: 'pH Level', value: 'pHLevel' },
  ]
};

export const DeviceTypeValues = [
  { content: 'Dualex', value: 'dualex' },
  { content: 'Mobilab', value: 'mobilab' },
];

export const TitleValues = [
  { content: 'Show title for all samples', value: true },
  { content: 'Show title for chosen sample', value: false },
];
