import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ApiCallService } from '../../../services/api/api-call.service';
import { Observable, from, of } from 'rxjs';
import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { catchError, switchMap, map, tap } from 'rxjs/operators';
import {
    ActionTypes,
    setViews,
    setView,
    setChart,
    setLoading,
    setError,
    unsetView,
    setViewPending,
    setSoilMoistureSensorsLoading,
    setSoilMoistureSensors
} from '../actions';
import { IView, IChart } from '../models';
import { IState } from '../states';
import { setProfile, TreeSettingsActionTypes } from '../../../shared/tree/actions/tree-settings';
import { setNotify } from '../../../core/actions/notify';
import { ISensor } from '../../../shared/interfaces';
import { SENSOR_GROUPS, SensorGroupType } from '../constants';

@Injectable()
export class Effects {

    constructor(
        private api: ApiCallService,
        private actions$: Actions,
        private store: Store<IState>
    ) { }

    @Effect()
    public getViews$: Observable<IAction> = this.actions$.pipe(
        ofType(ActionTypes.GET_VIEWS),
        tap(() => this.store.dispatch(setViewPending(true))),
        tap(() => this.store.dispatch(setError(false))),
        switchMap(
            (action: IAction) => this.api.getSoilMoistureViews(action.payload)
                .pipe(
                    map((response: IView[]) => setViews(response)),
                    catchError(() => from([setError(true), setViewPending(false)]))
                )
        ),
        tap(() => this.store.dispatch(setViewPending(false)))
    );

    @Effect()
    public postView$: Observable<IAction> = this.actions$.pipe(
        ofType(ActionTypes.POST_VIEW),
        tap(() => this.store.dispatch(setError(false))),
        tap(() => this.store.dispatch(setViewPending(true))),
        switchMap(
            (action: IAction) => this.api.postSoilMoistureView(action.payload)
                .pipe(
                    switchMap(([_id]) => from([
                        setView({ ...action.payload, _id }),
                        setProfile({
                            stationId: action.payload.station_id,
                            profile: _id }, TreeSettingsActionTypes.SET_SOIL_MOISTURE_PROFILE
                        ),
                        setNotify('View saved')
                    ])),
                    catchError(() => from([
                        setError(true),
                        setNotify('Saving View failed'),
                        setViewPending(false)
                    ]))
                )
        ),
        tap(() => this.store.dispatch(setViewPending(false)))
    );

    @Effect()
    public putView$: Observable<IAction> = this.actions$.pipe(
        ofType(ActionTypes.PUT_VIEW),
        tap(() => this.store.dispatch(setViewPending(true))),
        tap(() => this.store.dispatch(setError(false))),
        switchMap(
            (action: IAction) => this.api.putSoilMoistureView(action.payload)
                .pipe(
                    switchMap(() => from([
                        setView(action.payload),
                        setProfile({
                            stationId: action.payload.station_id,
                            profile: action.payload._id }, TreeSettingsActionTypes.SET_SOIL_MOISTURE_PROFILE
                        ),
                        setNotify('View saved')
                    ])),
                    catchError(() => from([
                        setError(true),
                        setNotify('Saving View failed'),
                        setViewPending(false)
                    ]))
                )
        ),
        tap(() => this.store.dispatch(setViewPending(false))),
    );

    @Effect()
    public deleteView$: Observable<IAction> = this.actions$.pipe(
        ofType(ActionTypes.DELETE_VIEW),
        tap(() => this.store.dispatch(setError(false))),
        tap(() => this.store.dispatch(setViewPending(true))),
        switchMap(
            (action: IAction) => this.api.deleteSoilMoistureView(action.payload)
                .pipe(
                    switchMap(([_id]) => from([
                        unsetView(action.payload),
                        setProfile({
                            stationId: action.payload.station_id,
                            profile: null
                            }, TreeSettingsActionTypes.SET_SOIL_MOISTURE_PROFILE
                        ),
                        setNotify('View deleted')
                    ])),
                    catchError(() => from([
                        setError(true),
                        setNotify('Deleting View failed'),
                        setViewPending(false)
                    ]))
                )
        ),
        tap(() => this.store.dispatch(setViewPending(false))),
    );

    @Effect()
    public getChart$: Observable<IAction> = this.actions$.pipe(
        ofType(ActionTypes.GET_CHART),
        tap(() => this.store.dispatch(setLoading(true))),
        tap(() => this.store.dispatch(setError(false))),
        switchMap((action: IAction) => this.api.getSoilMoistureChart(action.payload)
                .pipe(
                    map((response: IChart) => {
                        if (this.isValidChart(response)) {
                            return setChart(response);
                        } else {
                            this.store.dispatch(setError(true));
                            return setChart({
                                chartsOptions: [],
                                grid: {},
                                topology: []
                            });
                        }
                    }),
                    catchError(() => from([
                        setError(true),
                        setLoading(false)
                    ]))
                )
        ),
        tap(() => this.store.dispatch(setLoading(false))),
    );

    @Effect()
    public getSoilMoistureSensors$: Observable<IAction> = this.actions$.pipe(
        ofType(ActionTypes.GET_SOIL_MOISTURE_SENSORS),
        tap(() => this.store.dispatch(setSoilMoistureSensorsLoading(true))),
        switchMap((action: IAction) => this.api.getStationSensors(action.payload).pipe(
            switchMap((sensors: ISensor[]) => [
                setSoilMoistureSensors(sensors.filter(sensor => {
                    return sensor.isActive && (
                        SENSOR_GROUPS[SensorGroupType.VOLUMETRIC].includes(sensor.group)
                    );
                }).sort((a, b) => a.ch - b.ch)),
                setSoilMoistureSensorsLoading(false)
            ]),
            catchError(() => from([
                setSoilMoistureSensorsLoading(false)
            ]))
        ))
    );

    private isValidChart(chart: IChart): boolean {
        return !!(chart.chartsOptions && chart.grid && chart.topology);
    }

}
