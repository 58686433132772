<div *ngIf="stations; else loading">
  <form [formGroup]="form">
    <div class="toolbar toolbar__export">
      <app-custom-dropdown *ngIf="!subDomain?.hideStationListDropdown"
                           [options]="dropDownOptions"
                           [formControlName]="'column'"
                           [searchable]="false">
      </app-custom-dropdown>
      <app-custom-button [shadow]="false"
                         (click)="exportAsExcel()"
                         appTooltip
                         [tooltipPlacement]="'top'"
                         [tooltipTitle]="'Export table as xls' | translate"
                         [color]="'white'">
        <i class="fa fa-table"></i>
      </app-custom-button>
    </div>
  </form>

  <div>
  <ag-grid-angular class="ag-theme-bootstrap bootstrap"
                   [gridOptions]="dataGridOptions"
                   [rowStyle]="rowStyle"
                   (sortChanged)="onSortChange($event)">
  </ag-grid-angular>
  </div>
  <div class="pagination-container">
    <pagination [boundaryLinks]="'showBoundaryLinks'"
                [maxSize]="10"
                [(ngModel)]="page"
                [itemsPerPage]="itemLimit"
                [totalItems]="stations.length"
                (pageChanged)="onPageChange($event)"
                previousText=" "
                nextText=" "
                firstText=" "
                lastText=" ">
    </pagination>
  </div>
</div>

<ng-template #loading>
  <app-widget-info>
    <h4><i class="fa fa-cog fa-spin fa-fw"></i> {{ 'Please wait, loading data ...' | translate }}</h4>
  </app-widget-info>
</ng-template>
