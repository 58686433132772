import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject, Observable, combineLatest } from 'rxjs';
import { CameraPeriodScopes } from '../../../../core/constants/camera';
import { ICropZone } from './../../../../core/models/cropzones';
import { Store, select } from '@ngrx/store';
import { ICropzoneManagementState, selectManagementCrops, selectNewCropzones } from '../../reducers/cropzoneManagement';
import { getManagementCropsLazyLoading, updateManagementConfig } from '../../actions/cropzoneManagement';
import { IOptions } from '../../../../shared/interfaces';
import { map, tap, filter, distinctUntilChanged, takeUntil, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { FormArray, FormBuilder, Validators, FormGroup, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';
import { renewCropzones } from './../../actions/cropzoneManagement';
import { ModalService } from './../../../../shared/modal/services/modal.service';
import { RENEW_CROPZONES_MODAL_ID } from '../../constants';

@Component({
  selector: 'app-duplication-info-modal',
  templateUrl: './duplication-info-modal.component.html',
  styleUrls: ['./duplication-info-modal.component.scss']
})
export class DuplicationInfoModalComponent implements OnInit, OnDestroy {
  @Output()
  public submitEmit: EventEmitter<void> = new EventEmitter<void>();

  public cropzoneGroups: FormArray;
  public items$: Observable<IOptions[]>;
  public newCropzones$: Observable<ICropZone[]>;
  public options = CameraPeriodScopes;
  public crops: any[];
  public form: FormGroup;
  public submitClicked: boolean = false;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private managementStore: Store<ICropzoneManagementState>,
    private translations: TranslateService,
    private fb: FormBuilder,
    private modal: ModalService
  ) { }

  public ngOnInit(): void {
    this.items$ = this.managementStore.pipe(
      select(selectManagementCrops),
      filter((crops) => crops.length > 0),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      map(crops => crops.map((item: any) => ({content: item, value: item.name}))),
      map(crops => crops.sort((a, b) => a.value > b.value ? 1 : -1)),
      map(crops => crops.map((crop) => this.getTranslatedCropName(crop)))
    );

    this.newCropzones$ = this.managementStore.pipe(
      select(selectNewCropzones),
      filter((cropzones) => cropzones.length > 0),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      tap(cropzones => {
        if (!this.form) {
          this.cropzoneGroups = this.fb.array(this.getControlArray(cropzones).map((cropzone) => this.fb.group(cropzone)));
          this.form = this.fb.group({
            cropzones: this.cropzoneGroups
          });
        }
      }),
      takeUntil(this.destroy$)
    );

    combineLatest([
      this.items$,
      this.newCropzones$
    ]).pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.destroy$)
      ).subscribe(([options, cropzones]: [IOptions[], ICropZone[]]) => {
      this.setCropTypeValues(cropzones, options);
    });

  }

  private getTranslatedCropName(crop: IOptions): IOptions {
    const cropObj = crop;

    this.translations.get(crop.value).subscribe(translatedValue => {
      cropObj.value = translatedValue;
    });

    return cropObj;
  }

  private setCropTypeValues(newCropzones: ICropZone[], options: any[]): void {
    newCropzones.forEach((cropzone, index) => {
      options.filter((crop) => cropzone.crop && cropzone.crop.id === crop.content.id)
        .map(crop => {
          if (crop) {
            const cropzoneControls: FormGroup = this.form.controls.cropzones['controls'];
            cropzoneControls[index].controls.cropType.setValue(crop);
          }
        });
    });
  }

  public submit(): void {
    this.newCropzones$.pipe(take(1)).subscribe((cropzones: ICropZone[]) => {
      const cropzonesToSubmit: ICropZone[] = [];

      cropzones.forEach((cropzone, index) => {
        const cropzoneFormValue = this.form.value.cropzones[index];
        const cropTypeContent = cropzoneFormValue.cropType ? cropzoneFormValue.cropType.content : null;

        const dataSources = cropzone.data_sources;

        dataSources.forEach((data_source, i) => {
          if (data_source.module === 'YIELD_PREDICTION' && cropzoneFormValue.L_ini && cropzoneFormValue.L_late) {
            dataSources[i] = {
              ...data_source,
              yield_prediction: {
                ...data_source.yield_prediction,
                sowing_date: cropzoneFormValue.L_ini,
                harvest_date_user_estimate: cropzoneFormValue.L_late
              }
            };
          }

          if (data_source.module === 'SOIL_MOISTURE' && cropzoneFormValue.from && cropzoneFormValue.to) {
            dataSources[i] = {
              ...data_source,
                from: cropzoneFormValue.from,
                to: cropzoneFormValue.to
            };
          }
        });

        let updatedCropzone: any = cropzone;
        if (cropTypeContent) {
          updatedCropzone = {
            ...cropzone,
              crop: {
                id: cropTypeContent.id,
                emergence: cropzoneFormValue.emergence,
                fao56: {
                  K_ini: cropzone.crop && cropzone.crop.id === cropzoneFormValue.id ? cropzone.crop.fao56.K_ini : cropTypeContent.K_ini,
                  K_mid: cropzone.crop && cropzone.crop.id === cropzoneFormValue.id ? cropzone.crop.fao56.K_mid : cropTypeContent.K_mid,
                  K_end: cropzone.crop && cropzone.crop.id === cropzoneFormValue.id ? cropzone.crop.fao56.K_end : cropTypeContent.K_end,
                  L_ini: cropzoneFormValue.L_ini ? cropzoneFormValue.L_ini : null,
                  L_dev: cropzoneFormValue.L_dev ? cropzoneFormValue.L_dev : null,
                  L_mid: cropzoneFormValue.L_mid ? cropzoneFormValue.L_mid : null,
                  L_late: cropzoneFormValue.L_late ? cropzoneFormValue.L_late : null,
                  R_ini: cropzone.crop && cropzone.crop.id === cropzoneFormValue.id ? cropzone.crop.fao56.R_ini : cropTypeContent.R_ini,
                  R_mid: cropzone.crop && cropzone.crop.id === cropzoneFormValue.id ? cropzone.crop.fao56.R_mid : cropTypeContent.R_mid,
                  R_end: cropzone.crop && cropzone.crop.id === cropzoneFormValue.id ? cropzone.crop.fao56.R_end : cropTypeContent.R_end
                }
              }
          };
        }

        updatedCropzone.oldCropzoneId = updatedCropzone.id;
        delete updatedCropzone.id;
        cropzonesToSubmit.push(updatedCropzone);
      });

      this.managementStore.dispatch(renewCropzones(cropzonesToSubmit));
      this.managementStore.dispatch(updateManagementConfig({
        licenseModalActive: false,
        duplicationModalActive: false,
        cropTypeModalActive: false
        }));
      this.modal.closeModal(RENEW_CROPZONES_MODAL_ID),
      this.submitEmit.emit();
      /* this.submitClicked = true; */
    /* this.managementStore.dispatch(updateDuplicatedCropzones(this.newCropzones)); */
    /* this.submitEmit.emit(); */
    });
  }

  private getControlArray(cropzones): any {
    const controlArray = [];

    cropzones.forEach((c) => {
      const fromMoment = moment(c.from);
      const toMoment = moment(c.to);
      const duration = toMoment.diff(fromMoment, 'days', true);

      controlArray.push({
        name: [c.name, Validators.required],
        cropType: [c.crop || null, []],
        emergence: [c.crop ? fromMoment.clone().format('YYYY-MM-DD[T]HH:mm:ss') + '+00:00' : null, []],
        L_ini: [c.crop ? fromMoment.clone().add(duration * 0.25, 'days').format('YYYY-MM-DD[T]HH:mm:ss') + '+00:00' : null, []],
        L_dev: [c.crop ? fromMoment.clone().add(duration * 0.5, 'days').format('YYYY-MM-DD[T]HH:mm:ss') + '+00:00' : null, []],
        L_mid: [c.crop ? fromMoment.clone().add(duration * 0.75, 'days').format('YYYY-MM-DD[T]HH:mm:ss') + '+00:00' : null, []],
        L_late: [c.crop ? fromMoment.clone().add(duration, 'days').format('YYYY-MM-DD[T]HH:mm:ss') + '+00:00' : null, []],
        from: [c.from ? fromMoment.toDate() : null, Validators.required],
        to: [c.to ? toMoment.toDate() : null, Validators.required]
      });
    });

    return controlArray;
  }

  public saveDisabled(): boolean {
    let disabled: boolean = false;
    this.form.value.cropzones.forEach((c: any) => {
      if (c.cropType) {
        if (!c.L_ini || !c.L_dev || !c.L_late || !c.L_mid || !c.emergence) {
          disabled = true;
        }
      } else {
        if (c.L_ini || c.L_dev || c.L_late || c.L_mid || c.emergence) {
          disabled = true;
        }
      }
    });
    return disabled;
  }

  public isValidControl(cropzone, type): boolean {
    let validControl = false;

    if (cropzone.cropType) {
      validControl = cropzone[type] ? true : false;
    } else {
      if (
        !cropzone.emergence &&
        !cropzone.L_dev &&
        !cropzone.L_ini &&
        !cropzone.L_mid &&
        !cropzone.L_late
      ) {
        validControl = true;
      } else {
        validControl = cropzone[type] ? true : false;
      }
    }

    return validControl;
  }

  public back(): void {
    this.managementStore.dispatch(updateManagementConfig({
      licenseModalActive: false,
      duplicationModalActive: true,
      cropTypeModalActive: false
    }));
  }

  public onLoadCrops(): void {
    this.managementStore.dispatch(getManagementCropsLazyLoading());
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
