<app-form-layout [shadow]="false">

  <app-card-header
    [header]="header?.title | translate"
    [helpButton]=true
    (openModal)="openModal()">
  </app-card-header>

</app-form-layout>

<app-info-modal [modalId]="modalId" [header]="header?.title | translate">
  <app-accumulator-tool-help-modal [help]="header?.help"></app-accumulator-tool-help-modal>
</app-info-modal>
