import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdatePasswordComponent } from './update-password.component';
import { AuthModule } from '../../auth.module';
import { TranslateModule } from '@ngx-translate/core';
import { UpdatePasswordRoutingModule } from './update-password-routing.module';

@NgModule({
  imports: [
    CommonModule,
    UpdatePasswordRoutingModule,
    AuthModule,
    TranslateModule.forChild()
  ],
  declarations: [UpdatePasswordComponent]
})
export class UpdatePasswordModule { }
