import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {CameraSlideshowRoutingModule} from './camera-slideshow-routing.module';
import {CameraSlideshowComponent} from './containers/camera-slideshow/camera-slideshow.component';
import {CameraSlideshowContentComponent} from './containers/camera-slideshow-content/camera-slideshow-content.component';
import {CameraSlideshowHeaderComponent} from './components/camera-slideshow-header/camera-slideshow-header.component';
import {CameraSlideshowToolbarComponent} from './containers/camera-slideshow-toolbar/camera-slideshow-toolbar.component';
import {PlaySpeedInputComponent} from './containers/play-speed-input/play-speed-input.component';
import {reducer} from './reducers/camera-slideshow';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {CameraSlideshowService} from './effects/camera-slideshow.service';

@NgModule({
  imports: [
    CommonModule,
    CameraSlideshowRoutingModule,
    TranslateModule.forChild(),
    SharedModule,
    StoreModule.forFeature('cameraSlideshow', reducer),
    EffectsModule.forFeature([CameraSlideshowService]),
  ],
  declarations: [
    CameraSlideshowComponent,
    CameraSlideshowContentComponent,
    CameraSlideshowHeaderComponent,
    CameraSlideshowToolbarComponent,
    PlaySpeedInputComponent
  ]
})
export class CameraSlideshowModule { }
