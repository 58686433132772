import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { setNotify } from '../../../core/actions/notify';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { ApiCallService } from '../../../services/api/api-call.service';
import {ActionTypes, getAssets, setAssets, setSharingKey} from '../actions/xarvio';

@Injectable()
export class Effects {

  constructor(
    private api: ApiCallService,
    private actions$: Actions
  ) {}

  @Effect()
  public getXarvioAssets$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ActionTypes.GET_ASSETS),
    switchMap(() => this.api.getXarvioAssets().pipe(
      map((response: any) => setAssets(response)),
      catchError(() => of(setNotify('Xarvio API currently unavailable.')))
    ))
  );

  @Effect()
  public getXarvioSharingKey$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ActionTypes.GET_SHARING_KEY),
    switchMap(() => this.api.getXarvioSharingKey().pipe(
      map((response: any) => response.xarvio_sync_enabled ? response.sharing_key : null),
      map((sharingKey: string) => setSharingKey(sharingKey)),
      catchError(({ error }) => of(setNotify(error.message)))
    ))
  );

  @Effect()
  public enableSync$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ActionTypes.ENABLE_SYNC),
    switchMap(() => this.api.enableXarvioSync().pipe(
      map((response: any) => setSharingKey(response.sharing_key)),
      catchError(({ error }) => of(setNotify(error.message)))
    ))
  );

  @Effect()
  public disableSync$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ActionTypes.DISABLE_SYNC),
    switchMap(() => this.api.disableXarvioSync().pipe(
      map(() => setNotify('Xarvio integration successfully disabled')),
      catchError(({ error }) => of(setNotify(error.message)))
    ))
  );

  @Effect()
  public syncXarvioAssets$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ActionTypes.SYNC_ASSETS),
    switchMap((action: IActionWithPayload) => this.api.syncXarvioAssets(action.payload).pipe(
      switchMap(() => from([
        getAssets(),
        setNotify('Xarvio assets successfully synchronized')
      ])),
      catchError(({ error }) => of(setNotify(error.message)))
    ))
  );
}
