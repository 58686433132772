<app-form-layout class="camera-settings">
  <app-card-header [header]="'Camera settings'"
                   (openModal)="openModal()"
                   [helpButton]="true"></app-card-header>
  <div class="camera-settings__content">
    <div class="row">
      <form [formGroup]="form" class="col-md-12">
        <div style="margin-bottom: 15px;">
          <h4>{{ 'LED flash' | translate }}</h4>
          <div>
            <app-form-select
              [formControl]="ledFlashControl"
              [size]="'small'"
              [type]="'border'"
              [options]="ledFlashOptions"></app-form-select>
          </div>
        </div>
      </form>
    </div>
    <div class="row">
      <app-camera-settings-cam *ngFor="let cam of getCamKeys(); let i = index"
                               [class.col-md-6]="getCamKeys().length === 2"
                               [class.col-md-12]="getCamKeys().length === 1"
                               [cam]="cameraSettings[cam]"
                               [camIndex]="i+1"
                               [camKey]="cam"></app-camera-settings-cam>
    </div>
    <div class="camera-settings__info">
      <hr>
      <i class="fa fa-exclamation-triangle"></i>
      {{ 'Changing any camera settings might result in corrupted images. ' +
      'We recommend using the default settings.' | translate }}
    </div>
    <app-camera-settings-save [isInvalid]="cameraSettings.isInvalid"
                              (save)="save()"></app-camera-settings-save>
  </div>
</app-form-layout>

<app-info-modal [modalId]="modalId"
                [header]="'Camera settings help'">
  <app-camera-settings-modal></app-camera-settings-modal>
</app-info-modal>
