import {IStationEvent} from '../models/station-events.models';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {StationEventsActionTypes} from '../actions/station-events';

export interface IStationEventsState {
  stationEvents: IEventsByStation;
  isLoading: boolean;
  isError: boolean;
}

export interface IEventsByStation {
  [stationId: string]: Array<IStationEvent>;
}

export const initStationEventState: IStationEventsState = {
  stationEvents: {},
  isLoading: true,
  isError: false
};

export function reducer(state: IStationEventsState = initStationEventState, action: IActionWithPayload): IStationEventsState {
  switch (action.type) {
    case StationEventsActionTypes.SET_STATION_EVENTS:
      const stationEvents: IEventsByStation = {
        ...state.stationEvents,
        [action.payload.stationId]: action.payload.stationEvents
      };
      return {
        isLoading: state.isLoading,
        isError: state.isError,
        stationEvents: stationEvents
      };
    case StationEventsActionTypes.SET_STATION_EVENTS_LOADING:
      return <IStationEventsState> {
        ...state,
        isLoading: action.payload,
      };
    case StationEventsActionTypes.SET_STATION_EVENTS_ERROR:
      return <IStationEventsState> {
        ...state,
        isError: action.payload,
      };
    default:
      return state;
  }
}
