<div class="sensor-confirm">
  <div class="sensor-confirm__text">
    <strong>{{ 'Warning!' | translate }}</strong>
    {{ 'This change will reset all sensor/node units on their default values' | translate }}
  </div>
  <div class="sensor-confirm__buttons">
    <app-custom-button [content]="'yes'"
                       (click)="resetEmit()"></app-custom-button>
    <app-custom-button [content]="'no'"
                       (click)="closeEmit()"
                       [color]="'red'"></app-custom-button>
  </div>
</div>
