import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { IStation } from '../../../../../core/models/stations';
import { selectNavigationStation } from '../../../../../core/reducers';
import { INavigationStationState } from '../../../../../core/reducers/navigation-station';
import { IDeleteGlueBoardBody } from '../../../../../shared/camera/models/camera';
import { getIscoutGeneralPests } from '../../../actions/iscout';
import {
  exchangeIscoutGlueBoard,
  getIscoutGlueBoardAvailableDates,
  getIscoutGlueBoards,
  removeIscoutGlueBoard,
  saveIscoutGlueBoard,
  selectIscoutGlueBoard,
  unselectIscoutGlueBoard,
  updateIscoutGlueBoard
} from '../../../actions/iscout-glue-boards';
import {
  getIscoutPestsFamily,
  getIscoutPestsGenus,
  getIscoutPestsOrders,
  getIscoutUserPests
} from '../../../actions/iscout-pests';
import {
  IIscoutGlueBoard,
  IIscoutGlueBoardAvailableDates,
  IIscoutGlueBoardFormAction,
  IIscoutGlueBoardState,
  IIscoutPestsState,
  IscoutFormActionType
} from '../../../models/iscout.models';
import {
  selectIscoutGlueBoardAvailableDateList,
  selectIscoutIsLoading,
  selectIscoutSelectedGlueBoard
} from '../../../reducers';

@Component({
  selector: 'app-iscout-glue-board-content',
  templateUrl: './iscout-glue-board-content.component.html'
})
export class IscoutGlueBoardContentComponent implements OnInit, OnDestroy {
  public availableDates$: Observable<IIscoutGlueBoardAvailableDates>;
  public selectedGlueBoard$: Observable<IIscoutGlueBoardFormAction>;
  public isLoading$: Observable<boolean>;

  public stationId: string;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private iscoutGlueBoardStore: Store<IIscoutGlueBoardState>,
              private iscoutPestStore: Store<IIscoutPestsState>,
              private navigationStationStore: Store<INavigationStationState>) {}

  public ngOnInit(): void {
    this.initStatusListener();
    this.initStationChangeObservable().subscribe((): void => {
      this.iscoutGlueBoardStore.dispatch(getIscoutGlueBoards(this.stationId));
      this.iscoutGlueBoardStore.dispatch(getIscoutGlueBoardAvailableDates(this.stationId));

      // Need to fetch all possible targets for the GlueBoard
      this.iscoutPestStore.dispatch(getIscoutPestsOrders());
      this.iscoutPestStore.dispatch(getIscoutPestsFamily());
      this.iscoutPestStore.dispatch(getIscoutPestsGenus());
      this.iscoutPestStore.dispatch(getIscoutUserPests(this.stationId));
      this.iscoutPestStore.dispatch(getIscoutGeneralPests(this.stationId));
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initStatusListener(): void {
    this.availableDates$ = this.iscoutGlueBoardStore.pipe(
      select(selectIscoutGlueBoardAvailableDateList)
    );

    this.selectedGlueBoard$ = this.iscoutGlueBoardStore.pipe(
      select(selectIscoutSelectedGlueBoard)
    );

    this.isLoading$ = this.iscoutGlueBoardStore.pipe(
      select(selectIscoutIsLoading)
    );
  }

  private initStationChangeObservable(): Observable<IStation> {
    return this.navigationStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((station: IStation): boolean => !!station),
      tap((station: IStation): void => {
        if (this.stationId !== station.name.original) {
          this.iscoutGlueBoardStore.dispatch(unselectIscoutGlueBoard());
        }
        this.stationId = station.name.original;
      })
    );
  }

  public selectGlueBoardCreate(): void {
    const formAction: IIscoutGlueBoardFormAction = {action: IscoutFormActionType.CREATE, glueBoard: null};
    this.iscoutGlueBoardStore.dispatch(selectIscoutGlueBoard(formAction));
  }

  public selectGlueBoardUpdate(glueBoard: IIscoutGlueBoard): void {
    const formAction: IIscoutGlueBoardFormAction = {action: IscoutFormActionType.UPDATE, glueBoard};
    this.iscoutGlueBoardStore.dispatch(selectIscoutGlueBoard(formAction));
  }

  public handleGlueBoardSave(glueBoard: IIscoutGlueBoard): void {
    const action = saveIscoutGlueBoard({...glueBoard, nm: this.stationId});
    this.iscoutGlueBoardStore.dispatch(action);
  }

  public handleGlueBoardUpdate(glueBoard: IIscoutGlueBoard): void {
    const action = updateIscoutGlueBoard({...glueBoard, nm: this.stationId});
    this.iscoutGlueBoardStore.dispatch(action);
  }

  public handleGlueBoardExchange(glueBoard: IIscoutGlueBoard): void {
    const action = exchangeIscoutGlueBoard({...glueBoard, nm: this.stationId});
    this.iscoutGlueBoardStore.dispatch(action);
  }

  public handleGlueBoardRemove(event: IDeleteGlueBoardBody): void {
    this.iscoutGlueBoardStore.dispatch(removeIscoutGlueBoard(event));
  }
}
