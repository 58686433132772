<div *ngIf="!loadingData; else loading">
  <app-chart-toolbar-layout>
    <form class="station-data-toolbar" (keydown.enter)="$event.preventDefault()" [formGroup]="periodForm">
      <div class="station-data-toolbar__left">
       <!-- <div class="station-data-toolbar__toggle station-data-toolbar__left__item">
          <app-custom-button [color]="!isLastDataMode.value ? 'dark-green' : 'green'" appTooltip
            [tooltipPlacement]="'top'" [tooltipTitle]="'From - To mode' | translate" [shadow]="false"
            [radius]="'0 2px 2px 0'" (click)="isLastDataMode.setValue(false)">
            <span class="fa fa-calendar"></span>
          </app-custom-button>
        </div> -->
        <!-- *ngIf="!isLastDataMode.value" -->
        <ng-container>
          <app-custom-datepicker [isSmall]="true" [minDate]="fromStation" [maxDate]="toDatepicker.value"
            [formControl]="fromDatepicker">
          </app-custom-datepicker>
          <app-custom-datepicker [isSmall]="true" [maxDate]="toStation" [minDate]="fromDatepicker.value"
            class="station-data-toolbar__left__item" [formControl]="toDatepicker">
          </app-custom-datepicker>
        </ng-container>
      </div>

      <div class="station-data-toolbar__right">
        <app-custom-button style="margin-right: 10px;" [color]="'blue'" class="station-data-toolbar__right__item"
          (click)="cleanPrecipitation()" [content]="'Reset to 0' | translate">
        </app-custom-button>

        <app-custom-button [color]="'red'" class="station-data-toolbar__right__item" (click)="refresh()"
          [content]="'Refresh' | translate">
          <i class="fa fa-fw fa-refresh"></i>
        </app-custom-button>
      </div>
    </form>
  </app-chart-toolbar-layout>

  <section class="table-wrapper">
    <app-form-layout >
      <div class="station-data-table-content">
        <ag-grid-angular class="ag-theme-bootstrap" (cellValueChanged)="onCellValueChanged($event)"
          [columnDefs]="columnDefs" [gridOptions]="dataGridOptions">
        </ag-grid-angular>
      </div>
    </app-form-layout>
    <section class="corrector-content">
      <br />
      <div class="alert alert-info server-alerts-list__alert" style="margin: 20px;">
        {{ 'Apply Button:' | translate }}
        <br><br>
        {{ 'After changing precipitation values, make sure to click on Apply button to save your changes.' | translate
        }}
      </div>
      <section class="flex-reverse">
        <section style="margin-right: 20px;">
          <app-custom-button [disabled]="uniqueAlteredData.size == 0" [color]="'red'" (click)="apply()" [content]="'Apply' | translate">
          </app-custom-button>
        </section>
      </section>
      <br />
      <!-- <div class="alert alert-info server-alerts-list__alert" style="margin: 20px;">
        {{ 'Clean Precipitation Button:' | translate }}
        <br><br>
        {{
        "You can clean the precipitation data to 0 mm by clicking the clean precipitation button below." | translate
        }}
      </div> -->
      <!-- <section class="flex-reverse">
        <app-custom-button style="margin-right: 20px;" [color]="'blue'" class="station-data-toolbar__right__item"
          (click)="cleanPrecipitation()" [content]="'Clean Precipitation' | translate">
        </app-custom-button>
      </section> -->
    </section>
  </section>
</div>

<ng-template #loading>
  <h4 class="rain-data-loading"><i class="fa fa-cog fa-spin fa-fw"></i> {{ 'Please wait, loading data ...' | translate
    }}</h4>
</ng-template>

<app-modal [modalId]="cleanPrecipitationModelId">
  <app-clean-precipitation-modal (cancelEmitter)="closeModal(cleanPrecipitationModelId)" [dateRange]="dateRange"
    (confirmEmitter)="cleanConfirm()"></app-clean-precipitation-modal>
</app-modal>