<app-form-layout *ngIf="!configuration.activated; else productActivated">
  <app-product-activation-license-alert [licensesOverlapped]="licensesOverlapped"></app-product-activation-license-alert>

  <div class="product-activation-disease-model-form">
    <div class="product-activation-disease-model-form__title">
      <h2>{{ (configuration.name || 'Disease model activation information') | translate }}</h2>
    </div>

    <div class="product-activation-disease-model-form__content">
      <app-product-activation-disease-model-form
        [configuration]="configuration"
        [diseaseModels$]="availableDiseaseModels$"
        [userStations$]="availableUserStations$"
        (activateProductEmitter)="activateProduct($event)">
      </app-product-activation-disease-model-form>
    </div>
  </div>
</app-form-layout>

<ng-template #productActivated>
  <div class="alert alert-success">
    <span class="glyphicon glyphicon-ok-sign"></span>
    {{ 'The Disease Model license was successfully activated!' | translate }}
  </div>
</ng-template>
