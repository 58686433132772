import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { REMOVE_CROPZONE } from '../../../../../shared/constants';
import { removeFarm } from '../../../../actions/farms';
import { IFarm } from '../../../../models/farms';
import { selectCropzones } from '../../../../reducers';
import * as fromCropzones from '../../../../reducers/cropzones';
import * as fromFarms from '../../../../reducers/farms';
import { ModalService } from './../../../../../shared/modal/services/modal.service';

@Component({
    selector: 'app-remove-farm-confirmation',
    templateUrl: './remove-farm-confirmation.component.html',
    styleUrls: ['./remove-farm-confirmation.component.scss']
})
export class RemoveFarmConfirmationComponent implements OnInit {
    @Input()
    public farm                 : IFarm;

    @Output()
    public resetModal           : EventEmitter<string> = new EventEmitter<string>();

    constructor(private farmStore: Store<fromFarms.IFarms>,
                private modalService: ModalService,
                private cropzoneStore: Store<fromCropzones.ICropZones>) { }

    public ngOnInit(): void { }

    public delete(): void {
        const cropzones$ = this.cropzoneStore.pipe(
            select(selectCropzones)
        );

        cropzones$.pipe(
            map(cropzones => cropzones.filter(cropzone => cropzone.farm.id === this.farm.id))
        ).subscribe((cropzones) => {
            if (cropzones.length > 0) {
                this.farmStore.dispatch(removeFarm(this.farm, cropzones));
                this.resetModal.emit('cropzone');
                this.modalService.closeModal(REMOVE_CROPZONE);
            }
        });
    }

    public goBack(): void {
        this.resetModal.emit('farm');
    }
}
