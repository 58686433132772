import {IActionWithPayload} from '../models/actionWithPayload';
import {Action} from '@ngrx/store';
import {ISelectedSearchWidgetItem} from '../models/selectedSearchWidgetItem';

export const enum SelectedStationActionTypes {
  GET_SELECTED_STATION = '[Account] GET_SELECTED_STATION',
  SET_SELECTED_STATION = '[Account] SET_SELECTED_STATION'
}

export function getSelectedStation(): Action {
  return { type: SelectedStationActionTypes.GET_SELECTED_STATION };
}

export function setSelectedStation(data: ISelectedSearchWidgetItem): IActionWithPayload {
  return { type: SelectedStationActionTypes.SET_SELECTED_STATION, payload: data };
}
