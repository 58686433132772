import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

declare const google: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleMapService {

  private elevator: any;
  private geocoder: any;

  public getElevator(lat: number, lng: number): Observable<number> {

    this.elevator = new google.maps.ElevationService;
    const location = { 'lat': lat, 'lng': lng };

    return new Observable<number>((observer: Subscriber<number>) =>
      this.elevator.getElevationForLocations({ 'locations': [location]
      }, (results: Object, status: string) => {

        if (results[0] && status === 'OK') {
          observer.next(
            parseFloat(results[0].elevation.toFixed(2))
          );
        } else {
          observer.error();
        }
      })
    );
  }

  // TODO: Would it be possible to pass the 'location' instead of creating an array
  public getGeocoder(address: string): Observable<number[]> {

    this.geocoder = new google.maps.Geocoder();

    return new Observable<number[]>((observer: Subscriber<number[]>) =>
      this.geocoder.geocode({ 'address': address
      }, (results: Object, status: string) => {

        if (status === 'OK') {
          observer.next([
            results[0].geometry.location.lat(),
            results[0].geometry.location.lng()
          ]);
        } else {
          observer.error();
        }
      })
    );
  }
}
