import { IOptions } from './../../../shared/interfaces';
import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  protected options: IOptions[];
  protected sensors;
  public spaceReg = / /g;

  public checkThreshold(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const value: string = control.value.replace(this.spaceReg, '');
      const forbidden: boolean = /^[\-]?[0-9]+(([,.]+-?[0-9]+)*)?$/gm.test(value);
      return forbidden || !value ? null : { 'format': true };
    };
  }

  /* public findSensorData(alert: IServerAlert): {} {
    if (this.sensors) {
      const result = this.sensors.filter(sensor =>
        sensor.ch === alert.sensorChannel &&
        sensor.code === alert.sensorCode
      );

      if (result && result.length) {
        const sensor = result[0];
        const name = sensor.name_custom || sensor.name;
        const unit = sensor.unit || sensor.unit_default;

        return { name, unit };
      } else {
        return { name: 'Station sensor', unit: '-' };
      }
    }
  } */
}
