import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate} from '@angular/router';
import {DashboardComponent} from '../dashboard.component';
import {Observable} from 'rxjs';

@Injectable()
export class DashboardGuard implements CanDeactivate<DashboardComponent> {
  public canDeactivate(component: DashboardComponent,
                       currentRoute: ActivatedRouteSnapshot,
                       currentState: RouterStateSnapshot,
                       nextState?: RouterStateSnapshot): Observable<boolean> {
    component.nextUrl = nextState.url;
    return component.deactivate();
  }
}
