import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-empty-column',
  templateUrl: './empty-column.component.html',
  styleUrls: ['./empty-column.component.scss']
})
export class EmptyColumnComponent {
  @Output()
  public addWidget    : EventEmitter<void> = new EventEmitter();
}
