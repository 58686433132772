<app-form-layout [shadow]="false">
  <div>
    <div class="row" [formGroup]="form">
      <div class="row" style="padding: 5px 30px">
        <!-- 1st line -->
        <div class="col-md-3">
          {{ 'Soil moisture station' | translate }}
        </div>
        <div class="col-md-3">
          {{ 'Node' | translate }}
        </div>
        <div class="col-md-6">
          {{ 'Operates/-ed in this cropzone from - to' | translate }}
        </div>
      </div>
      <div class="row" style="padding: 0px 30px">
        <!-- 2nd line -->
        <div class="col-md-3">
          <app-custom-simple-lazy-dropdown [customCSS2]="{'max-width': '100%', 'width':'100%'}"
            [customCSS]="{'max-width': '100%', 'width':'100%', 'border': '1px solid #909090', 'height': '30px'}"
            [color]="'white'" [formControl]="soilMoistureStation" [items$]="soilMoistureStations$">
          </app-custom-simple-lazy-dropdown>
        </div>
        <div class="col-md-3">
          <app-custom-simple-lazy-dropdown [items$]="nodes$" [customCSS2]="{'max-width': '100%', 'width':'100%'}"
            [customCSS]="{'max-width': '100%', 'width':'100%', 'border': '1px solid #909090', 'height': '30px'}"
            [color]="'white'" [formControl]="node"></app-custom-simple-lazy-dropdown>
        </div>
        <div class="col-md-3" style="padding-left: 0">
          <app-custom-datepicker *ngIf="cropzone" [isSmall]="true" [formControl]="periodFrom" [maxDate]="periodTo.value" [minDate]="fromDate">
          </app-custom-datepicker>
        </div>
        <div class="col-md-3" style="padding-left: 0;">
          <app-custom-datepicker [isSmall]="true" [formControl]="periodTo" [minDate]="periodFrom.value"
            [maxDate]="toDate">
          </app-custom-datepicker>
        </div>
      </div>
    </div>

    <app-soil-moisture-config-bottom
      *ngIf="selectedNode"
      [indexx]="index"
      (removeDatasource)="removeDataSource($event)"
      [selectedSoilMoistureStation]="selectedSoilMoistureStation"
      [dataSource]="dataSource"
      [cropzone]="cropzone"
      [selectedNode]="selectedNode"
      [periodFrom]="periodFrom.value"
      [periodTo]="periodTo.value"
      [nodesList]="selectedSoilMoistureStation.groups[25]"
      (save)="saveX($event)">
    </app-soil-moisture-config-bottom>

  </div>
</app-form-layout>
