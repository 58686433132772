import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as d3 from 'd3';
import * as moment from 'moment';
import * as localeAll from 'ngx-bootstrap/locale';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ICropZone } from '../../../../core/models/cropzones';
import { selectSelectedCropZone } from '../../../../core/reducers';
import * as fromSelectedCropzone from '../../../../core/reducers/selectedCropZone';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { LanguageService } from '../../../../services/translation/language.service';
import { data, data2, margin } from '../../constants/config';


@Component({
  selector: 'app-irrimet-config-chart',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './irrimet-config-chart.component.html',
  styleUrls: ['./irrimet-config-chart.component.scss']
})
export class IrrimetConfigChartComponent implements OnInit, OnDestroy {

  @ViewChild('chart', {static: true})
  private chartContainer: ElementRef;

  @Output()
  public dataKcR = new EventEmitter<{ kc: any, r: any }>();

  @Input('selectedCropType')
  public set setCropType(selectedCropType) {
    if (selectedCropType) {
      this.selectedCropType = selectedCropType;
      this.rebuild();
    }
  }

  @Input()
  public unitSystem: string;

  public selectedCropType: any;
  private alive$ = new Subject<boolean>();
  public datePickerForm: FormGroup;
  public minDate: Date = null;
  public maxDate: Date = null;
  public selectedLineId: string = '';
  public startDateXAxis: Date = null;
  public endDateXAxis: Date = null;
  public bottomValueYAxis = null;
  public topValueYAxis = null;
  public visible: boolean = false;
  private selectedCropzone: ICropZone;
  public data = data;
  public data2 = data2;
  public margin = margin;
  private language: string = '';

  public rootzoneDepthLabel: string = 'Rootzone depth';
  public cropCoeffLabel: string = 'Crop coeff.';

  private distanceLabelToCircleRootzone: number;
  private distanceLabelToCircleCrop: number;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiCallService,
    private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private translation: TranslateService,
    private languages: LanguageService) {

      this.language = languages.languageFromStorage;
      if (localeAll[this.language + 'Locale']) {
        moment.locale(this.language);
      } else if (this.language === 'si') {
        moment.locale('sl');
      } else if (this.language === 'br') {
        moment.locale('pt-br');
      } else {
        moment.locale('en-gb');
      }
  }

  public get startOfSeasonPicker(): AbstractControl {
    return this.datePickerForm.get('startOfSeasonPicker');
  }

  public get cropDevelopmentPicker(): AbstractControl {
    return this.datePickerForm.get('cropDevelopmentPicker');
  }

  public get midSeasonPicker(): AbstractControl {
    return this.datePickerForm.get('midSeasonPicker');
  }

  public get lateSeasonPicker(): AbstractControl {
    return this.datePickerForm.get('lateSeasonPicker');
  }

  public get endSeasonPicker(): AbstractControl {
    return this.datePickerForm.get('endSeasonPicker');
  }

  public rebuild(): void {
    if (this.selectedCropzone && this.datePickerForm) {

      if (this.selectedCropzone.crop) {

        this.minDate = new Date(this.selectedCropzone.from);
        this.maxDate = new Date(this.selectedCropzone.to);

        if (this.selectedCropzone.crop.id === this.selectedCropType.id) {
          // crop type is same as the defined crop type

          this.startOfSeasonPicker.setValue(new Date(this.selectedCropzone.crop.emergence));
          this.cropDevelopmentPicker.setValue(new Date(this.selectedCropzone.crop.fao56.L_ini));
          this.midSeasonPicker.setValue(new Date(this.selectedCropzone.crop.fao56.L_dev));
          this.lateSeasonPicker.setValue(new Date(this.selectedCropzone.crop.fao56.L_mid));
          this.endSeasonPicker.setValue(new Date(this.selectedCropzone.crop.fao56.L_late));

          // tslint:disable:max-line-length
          if (this.unitSystem === 'metric') {
            this.data2 = [[new Date(this.selectedCropzone.crop.emergence), -parseFloat(this.selectedCropzone.crop.fao56.R_ini.toFixed(2)), 'R1'],
            [new Date(this.selectedCropzone.crop.fao56.L_ini), -parseFloat(this.selectedCropzone.crop.fao56.R_ini.toFixed(2)), 'R2'],
            [new Date(this.selectedCropzone.crop.fao56.L_dev), -parseFloat(this.selectedCropzone.crop.fao56.R_mid.toFixed(2)), 'R3'],
            [new Date(this.selectedCropzone.crop.fao56.L_mid), -parseFloat(this.selectedCropzone.crop.fao56.R_end.toFixed(2)), 'R4'],
            [new Date(this.selectedCropzone.crop.fao56.L_late), -parseFloat(this.selectedCropzone.crop.fao56.R_end.toFixed(2)), 'R5']];
          } else if (this.unitSystem === 'imperial') {
            this.data2 = [[new Date(this.selectedCropzone.crop.emergence), parseFloat((-this.selectedCropzone.crop.fao56.R_ini * 3.28084).toFixed(2)), 'R1'],
            [new Date(this.selectedCropzone.crop.fao56.L_ini), parseFloat((-this.selectedCropzone.crop.fao56.R_ini * 3.28084).toFixed(2)), 'R2'],
            [new Date(this.selectedCropzone.crop.fao56.L_dev), parseFloat((-this.selectedCropzone.crop.fao56.R_mid * 3.28084).toFixed(2)), 'R3'],
            [new Date(this.selectedCropzone.crop.fao56.L_mid), parseFloat((-this.selectedCropzone.crop.fao56.R_end * 3.28084).toFixed(2)), 'R4'],
            [new Date(this.selectedCropzone.crop.fao56.L_late), parseFloat((-this.selectedCropzone.crop.fao56.R_end * 3.28084).toFixed(2)), 'R5']];
          }
          this.data = [[new Date(this.selectedCropzone.crop.emergence), parseFloat((this.selectedCropzone.crop.fao56.K_ini).toFixed(2)), 'C1'],
            [new Date(this.selectedCropzone.crop.fao56.L_ini), parseFloat((this.selectedCropzone.crop.fao56.K_ini).toFixed(2)), 'C2'],
            [new Date(this.selectedCropzone.crop.fao56.L_dev), parseFloat((this.selectedCropzone.crop.fao56.K_mid).toFixed(2)), 'C3'],
            [new Date(this.selectedCropzone.crop.fao56.L_mid), parseFloat((this.selectedCropzone.crop.fao56.K_mid).toFixed(2)), 'C4'],
            [new Date(this.selectedCropzone.crop.fao56.L_late), parseFloat((this.selectedCropzone.crop.fao56.K_end).toFixed(2)), 'C5']];

            // tslint:enable:max-line-length
          this.dataKcR.emit({ kc: this.data, r: this.data2 });

          const kcValues: Array<any> = [this.data[0][1], this.data[1][1], this.data[2][1], this.data[3][1], this.data[4][1]];
          const highestKC: number = Math.max(...kcValues);
          const RValues: Array<any> = [this.data2[0][1], this.data2[1][1], this.data2[2][1], this.data2[3][1], this.data2[4][1]];
          const lowestRValue: number = Math.min(...RValues);

          this.startDateXAxis = new Date(this.selectedCropzone.from);
          this.endDateXAxis = new Date(this.selectedCropzone.to);

          if (this.unitSystem === 'metric') {
            this.bottomValueYAxis = lowestRValue - 0.3;
          } else {
            this.bottomValueYAxis = lowestRValue - (0.3 * 3.28084);
          }

          this.topValueYAxis = highestKC + 0.2;
          this.createChart();

        } else {
          // new crop type selected
          // calculating days between start and end of cultivation period
          const differenceInTime = new Date(this.selectedCropzone.to).getTime() - new Date(this.selectedCropzone.from).getTime();
          const numberOfDaysInBetween = differenceInTime / (1000 * 3600 * 24);

          // calculating days between first and last date (season)
          // tslint:disable-next-line:max-line-length
          const differenceInTime2 = moment(new Date(this.selectedCropzone.to)).subtract(numberOfDaysInBetween / 10, 'days').toDate().getTime() -
            moment(new Date(this.selectedCropzone.from)).add(numberOfDaysInBetween / 10, 'days').toDate().getTime();
          const numberOfDaysInBetween2 = differenceInTime2 / (1000 * 3600 * 24);

          const iniDate = moment(new Date(this.selectedCropzone.from)).add(numberOfDaysInBetween / 10, 'days').toDate();
          const devDate = moment(iniDate).add(numberOfDaysInBetween2 / 4, 'days').toDate();
          const midDate = moment(devDate).add(numberOfDaysInBetween2 / 4, 'days').toDate();
          const lateDate = moment(midDate).add(numberOfDaysInBetween2 / 4, 'days').toDate();
          const endDate = moment(new Date(this.selectedCropzone.to)).subtract(numberOfDaysInBetween / 10, 'days').toDate();

          this.startOfSeasonPicker.setValue(iniDate);
          this.cropDevelopmentPicker.setValue(devDate);
          this.midSeasonPicker.setValue(midDate);
          this.lateSeasonPicker.setValue(lateDate);
          this.endSeasonPicker.setValue(endDate);

          this.data = [[iniDate, this.selectedCropType.K_ini, 'C1'], [devDate, this.selectedCropType.K_ini, 'C2'],
          [midDate, this.selectedCropType.K_mid, 'C3'], [lateDate, this.selectedCropType.K_mid, 'C4'],
          [endDate, this.selectedCropType.K_end, 'C5']];

          this.data2 = [[iniDate, -this.selectedCropType.R_ini, 'R1'], [devDate, -this.selectedCropType.R_ini, 'R2'],
          [midDate, -this.selectedCropType.R_mid, 'R3'], [lateDate, -this.selectedCropType.R_end, 'R4'],
          [endDate, -this.selectedCropType.R_end, 'R5']];

          this.dataKcR.emit({ kc: this.data, r: this.data2 });

          const kcValues: Array<any> = [this.data[0][1], this.data[1][1], this.data[2][1], this.data[3][1], this.data[4][1]];
          const highestKC: number = Math.max(...kcValues);
          const RValues: Array<any> = [this.data2[0][1], this.data2[1][1], this.data2[2][1], this.data2[3][1], this.data2[4][1]];
          const lowestRValue: number = Math.min(...RValues);

          this.startDateXAxis = new Date(this.selectedCropzone.from);
          this.endDateXAxis = new Date(this.selectedCropzone.to);

          if (this.unitSystem === 'metric') {
            this.bottomValueYAxis = lowestRValue - 0.3;
          } else {
            this.bottomValueYAxis = lowestRValue - (0.3 * 3.28084);
          }

          this.topValueYAxis = highestKC + 0.2;
          this.createChart();
        }
      } else {
        // has no crop defined yet
        // calculating days between start and end of cultivation period
        const differenceInTime = new Date(this.selectedCropzone.to).getTime() - new Date(this.selectedCropzone.from).getTime();
        const numberOfDaysInBetween = differenceInTime / (1000 * 3600 * 24);

        // calculating days between first and last date (season)
        // tslint:disable-next-line:max-line-length
        const differenceInTime2 = moment(new Date(this.selectedCropzone.to)).subtract(numberOfDaysInBetween / 10, 'days').toDate().getTime() -
          moment(new Date(this.selectedCropzone.from)).add(numberOfDaysInBetween / 10, 'days').toDate().getTime();
        const numberOfDaysInBetween2 = differenceInTime2 / (1000 * 3600 * 24);

        const iniDate = moment(new Date(this.selectedCropzone.from)).add(numberOfDaysInBetween / 10, 'days').toDate();
        const devDate = moment(iniDate).add(numberOfDaysInBetween2 / 4, 'days').toDate();
        const midDate = moment(devDate).add(numberOfDaysInBetween2 / 4, 'days').toDate();
        const lateDate = moment(midDate).add(numberOfDaysInBetween2 / 4, 'days').toDate();
        const endDate = moment(new Date(this.selectedCropzone.to)).subtract(numberOfDaysInBetween / 10, 'days').toDate();

        this.startOfSeasonPicker.setValue(iniDate);
        this.cropDevelopmentPicker.setValue(devDate);
        this.midSeasonPicker.setValue(midDate);
        this.lateSeasonPicker.setValue(lateDate);
        this.endSeasonPicker.setValue(endDate);

        this.data = [[iniDate, this.selectedCropType.K_ini, 'C1'], [devDate, this.selectedCropType.K_ini, 'C2'],
        [midDate, this.selectedCropType.K_mid, 'C3'], [lateDate, this.selectedCropType.K_mid, 'C4'],
        [endDate, this.selectedCropType.K_end, 'C5']];

        this.data2 = [[iniDate, -this.selectedCropType.R_ini, 'R1'], [devDate, -this.selectedCropType.R_ini, 'R2'],
        [midDate, -this.selectedCropType.R_mid, 'R3'], [lateDate, -this.selectedCropType.R_end, 'R4'],
        [endDate, -this.selectedCropType.R_end, 'R5']];

        this.dataKcR.emit({ kc: this.data, r: this.data2 });

        const kcValues: Array<any> = [this.data[0][1], this.data[1][1], this.data[2][1], this.data[3][1], this.data[4][1]];
        const highestKC: number = Math.max(...kcValues);
        const RValues: Array<any> = [this.data2[0][1], this.data2[1][1], this.data2[2][1], this.data2[3][1], this.data2[4][1]];
        const lowestRValue: number = Math.min(...RValues);

        this.startDateXAxis = new Date(this.selectedCropzone.from);
        this.endDateXAxis = new Date(this.selectedCropzone.to);

        if (this.unitSystem === 'metric') {
          this.bottomValueYAxis = lowestRValue - 0.4;
        } else {
          this.bottomValueYAxis = lowestRValue - (0.4 * 3.28084);
        }

        this.topValueYAxis = highestKC + 0.2;
        this.createChart();
      }
    }
  }

  public ngOnInit(): void {
    this.translation.get('Crop coeff.').subscribe(translatedCropCoeff => this.cropCoeffLabel = translatedCropCoeff);

    this.selectedCropzoneStore.pipe(
      select(selectSelectedCropZone),
      takeUntil(this.alive$),
      tap(selectedCropzone => this.selectedCropzone = selectedCropzone)
    ).subscribe(() => this.rebuild());

    this.datePickerForm = this.formBuilder.group({
      'startOfSeasonPicker': [null, [Validators.required]],
      'cropDevelopmentPicker': [null, [Validators.required]],
      'midSeasonPicker': [null, [Validators.required]],
      'lateSeasonPicker': [null, [Validators.required]],
      'endSeasonPicker': [null, [Validators.required]]
    });
    this.startOfSeasonListener();
    this.cropDevelopmentListener();
    this.midSeasonListener();
    this.lateSeasonListener();
    this.endSeasonListener();
    this.rebuild();
  }

  public startOfSeasonListener(): void {
    this.startOfSeasonPicker.valueChanges.pipe(
      takeUntil(this.alive$)
    ).subscribe(val => {
      if (val !== this.data[0][0]) {
        this.data[0][0] = val;
        this.data2[0][0] = val;
        this.dataKcR.emit({ kc: this.data, r: this.data2 });
        this.createChart();
      }
    });
  }

  public cropDevelopmentListener(): void {
    this.cropDevelopmentPicker.valueChanges.pipe(
      takeUntil(this.alive$)
    ).subscribe(val => {
      if (val !== this.data[1][0]) {
        this.data[1][0] = val;
        this.data2[1][0] = val;
        this.dataKcR.emit({ kc: this.data, r: this.data2 });
        this.createChart();
      }
    });
  }

  public midSeasonListener(): void {
    this.midSeasonPicker.valueChanges.pipe(
      takeUntil(this.alive$)
    ).subscribe(val => {
      if (val !== this.data[2][0]) {
        this.data[2][0] = val;
        this.data2[2][0] = val;
        this.dataKcR.emit({ kc: this.data, r: this.data2 });
        this.createChart();
      }
    });
  }

  public lateSeasonListener(): void {
    this.lateSeasonPicker.valueChanges.pipe(
      takeUntil(this.alive$)
    ).subscribe(val => {
      if (val !== this.data[3][0]) {
        this.data[3][0] = val;
        this.data2[3][0] = val;
        this.dataKcR.emit({ kc: this.data, r: this.data2 });
        this.createChart();
      }
    });
  }

  public endSeasonListener(): void {
    this.endSeasonPicker.valueChanges.pipe(
      takeUntil(this.alive$)
    ).subscribe(val => {
      if (val !== this.data[4][0]) {
        this.data[4][0] = val;
        this.data2[4][0] = val;
        this.dataKcR.emit({ kc: this.data, r: this.data2 });
        this.createChart();
      }
    });
  }

  public changeDate(): void {
    if (this.selectedLineId === 'startLine') {
      this.data[0][0] = this.startOfSeasonPicker.value;
      this.data2[0][0] = this.startOfSeasonPicker.value;
    } else if (this.selectedLineId === 'cropDevelopment') {
      this.data[1][0] = this.cropDevelopmentPicker.value;
      this.data2[1][0] = this.cropDevelopmentPicker.value;
    } else if (this.selectedLineId === 'midSeason') {
      this.data[2][0] = this.midSeasonPicker.value;
      this.data2[2][0] = this.midSeasonPicker.value;
    } else if (this.selectedLineId === 'lateSeason') {
      this.data[3][0] = this.lateSeasonPicker.value;
      this.data2[3][0] = this.lateSeasonPicker.value;
    } else if (this.selectedLineId === 'endSeason') {
      this.data[4][0] = this.endSeasonPicker.value;
      this.data2[4][0] = this.endSeasonPicker.value;
    }
    this.createChart();
    d3.select('.visibility').style('display', 'none');
    this.visible = false;
  }

  public cancel(): void {
    this.removeAllActives();
    d3.select('.visibility').style('display', 'none');
  }

  private removeAllActives(): void {
    d3.select('#startLine').raise().classed('active', false);
    d3.select('#cropDevelopment').raise().classed('active', false);
    d3.select('#midSeason').raise().classed('active', false);
    d3.select('#lateSeason').raise().classed('active', false);
    d3.select('#endSeason').raise().classed('active', false);
  }

  private createChart(): void {
    const self = this;

    d3.select('svg').remove();

    const element = this.chartContainer.nativeElement;
    const timeFormat = d3.timeFormat('%d-%b-%y');

    const svg = d3.select('#chart').append('svg')
      .attr('width', element.offsetWidth)
      .attr('height', element.offsetHeight)
      .style('position', 'absolute')
      .style('z-index', 0);

    const contentWidth = element.offsetWidth - this.margin.left - this.margin.right;
    const contentHeight = element.offsetHeight - this.margin.top - this.margin.bottom;

    const x = d3
      .scaleTime()
      .rangeRound([0, contentWidth]);

      const myTimeFormatter = function(date): any {
        return moment(date).format('MMMM');
    };


    x.domain([self.startDateXAxis, self.endDateXAxis]);

    let topYAxis = null;
    let bottomYAxis = null;

    if (self.bottomValueYAxis === null && self.topValueYAxis === null) {
      bottomYAxis = -0.7;
      topYAxis = 0.7;
    } else {
      bottomYAxis = self.bottomValueYAxis;
      topYAxis = self.topValueYAxis;
    }

    const yCrop = d3
      .scaleLinear()
      .rangeRound([0, contentHeight / 2]);

    const yRootzone = d3
      .scaleLinear()
      .rangeRound([contentHeight / 2, contentHeight]);

    yCrop.domain([topYAxis, 0]);

    yRootzone.domain([0, bottomYAxis]);

    const xAxis = d3
      .axisBottom(x)
      .tickFormat(myTimeFormatter);

    const dividedBy4Crop = parseFloat((Math.round(self.topValueYAxis / 4 * 10) / 10).toFixed(1));
    const dividedBy4Rootzone = parseFloat((Math.round(self.bottomValueYAxis / 4 * 10) / 10).toFixed(1)) * -1;

    const cropTicks: Array<number> = [];
    for (let i = 0; i < self.topValueYAxis; i = i + dividedBy4Crop) {
      cropTicks.push(i);
    }

    const rootzoneTicks: Array<number> = [];
    for (let i = dividedBy4Rootzone; i < (self.bottomValueYAxis * -1); i = i + dividedBy4Rootzone) {
      rootzoneTicks.push(i * -1);
    }

    const yAxisCrop = d3.axisLeft(yCrop).tickValues(cropTicks);
    const yAxisRootzone = d3.axisLeft(yRootzone).tickValues(rootzoneTicks);

    if (this.unitSystem === 'metric') {
      this.translation.get('Rootzone depth [m]').subscribe(translatedRootzoneDepth => this.rootzoneDepthLabel = translatedRootzoneDepth);

    } else if (this.unitSystem === 'imperial') {
      this.translation.get('Rootzone depth [feet]').subscribe(translatedRootzoneDepth => this.rootzoneDepthLabel = translatedRootzoneDepth);
    }

    this.distanceLabelToCircleRootzone = dividedBy4Rootzone / 1.5;
    this.distanceLabelToCircleCrop = dividedBy4Crop / 3;

    const focus = svg.append('g').attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
    const focus4 = svg.append('g').attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
    const focus2 = svg.append('g').attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
    const focus3 = svg.append('g').attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    // Axis
    focus.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + contentHeight + ')')
      .call(xAxis)
      .selectAll('text')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-65)')
      .style('font-family', 'helvetica')
      .style('font-size', '11px')
      .style('text-anchor', 'end');

    // crop coefficient Y axis
    focus.append('g')
      .attr('class', 'axis axis--y')
      .call(yAxisCrop)
      .selectAll('text')
      .style('font-family', 'helvetica')
      .style('font-size', '11px');

    // rootzone Y axis
    focus.append('g')
      .attr('class', 'axis axis--y')
      .call(yAxisRootzone)
      .selectAll('text')
      .style('font-family', 'helvetica')
      .style('font-size', '11px');

    // Y axis labels
    focus.append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', -35)
      .attr('x', 0 - yCrop(topYAxis / 2))
      .style('text-anchor', 'middle')
      .style('font-family', 'helvetica')
      .style('font-size', '12px')
      .text(this.cropCoeffLabel);

    focus.append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', -35)
      .attr('x', 0 - yRootzone(bottomYAxis / 2))
      .style('text-anchor', 'middle')
      .style('font-family', 'helvetica')
      .style('font-size', '12px')
      .text(this.rootzoneDepthLabel);
    // importing images
    const defs = svg.append('svg:defs');

    // X drag (lines)
    const xDrag = d3.drag()
      .on('start', xDragStarted)
      .on('drag', xDragged)
      .on('end', xDragEnded);

    // start line
    focus4.append('g')
      .append('line')
      .attr('id', 'startLine')
      .attr('x1', x(this.data[0][0] as any))
      .attr('y1', yCrop(this.data[0][1] as any))
      .attr('x2', x(this.data2[0][0] as any))
      .attr('y2', yRootzone(this.data2[0][1] as any))
      .style('stroke', 'orange')
      .style('stroke-width', '4.5px')
      .style('cursor', 'e-resize')
      .call(xDrag);

    // crop development line (10%)
    focus4.append('g')
      .append('line')
      .attr('id', 'cropDevelopment')
      .attr('x1', x(this.data[1][0] as any))
      .attr('y1', yCrop(this.data[1][1] as any))
      .attr('x2', x(this.data2[1][0] as any))
      .attr('y2', yRootzone(this.data2[1][1] as any))
      .style('stroke', 'orange')
      .style('stroke-width', '4.5px')
      .style('cursor', 'e-resize')
      .call(xDrag);

    // mid season line
    focus4.append('g')
      .append('line')
      .attr('id', 'midSeason')
      .attr('x1', x(this.data[2][0] as any))
      .attr('y1', yCrop(this.data[2][1] as any))
      .attr('x2', x(this.data2[2][0] as any))
      .attr('y2', yRootzone(this.data2[2][1] as any))
      .style('stroke', 'orange')
      .style('stroke-width', '4.5px')
      .style('cursor', 'e-resize')
      .call(xDrag);

    // late season line
    focus4.append('g')
      .append('line')
      .attr('id', 'lateSeason')
      .attr('x1', x(this.data[3][0] as any))
      .attr('y1', yCrop(this.data[3][1] as any))
      .attr('x2', x(this.data2[3][0] as any))
      .attr('y2', yRootzone(this.data2[3][1] as any))
      .style('stroke', 'orange')
      .style('stroke-width', '4.5px')
      .style('cursor', 'e-resize')
      .call(xDrag);

    // end season line
    focus4.append('g')
      .append('line')
      .attr('id', 'endSeason')
      .attr('x1', x(this.data[4][0] as any))
      .attr('y1', yCrop(this.data[4][1] as any))
      .attr('x2', x(this.data2[4][0] as any))
      .attr('y2', yRootzone(this.data2[4][1] as any))
      .style('stroke', 'orange')
      .style('stroke-width', '4.5px')
      .style('cursor', 'e-resize')
      .call(xDrag);

    // Line functions
    // Top line
    const lineTop = d3.line()
      .x(function (d): number {
        return x(d[0]);
      })
      .y(function (d): number {
        return yCrop(d[1]);
      });

    // bottom line
    const lineBottom = d3.line()
      .x(function (d): number {
        return x(d[0]);
      })
      .y(function (d): number {
        return yRootzone(d[1]);
      });
    // Area function
    const areaTop = d3.area()
      .x(function (as): any {
        return x(as[0]);
      })
      .y0(yCrop(0))
      .y1(function (af): any {
        return yCrop(af[1]);
      });

    const areaBottom = d3.area()
      .x(function (as): any {
        return x(as[0]);
      })
      .y0(yRootzone(0))
      .y1(function (af): any {
        return yRootzone(af[1]);
      });

    // Top line
    focus2.append('path')
      .datum(this.data)
      .attr('id', 'topLine')
      .attr('fill', 'none')
      .attr('stroke', 'green')
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 2)
      .attr('d', lineTop);
    // Draggable circles on top line
    focus2.selectAll('circle')
      .data(this.data)
      .enter()
      .append('circle')
      .filter(function (d): any {
        return d[2] === 'C2' || d[2] === 'C3' || d[2] === 'C5';
      })
      .attr('r', 7.0)
      .attr('cx', function (d): number {
        return x(d[0] as number);
      })
      .attr('cy', function (d): number {
        return yCrop(d[1] as number);
      })
      .attr('id', function (d): string {
        return d[2] as string;
      })
      .style('cursor', 'pointer')
      .style('fill', 'green');

    // Labels top line
    const textTop = svg.append('g').attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
    textTop.selectAll('text')
      .data(this.data)
      .enter()
      .append('text')
      .text(function (d): any {
        return d[1];
      })
      .attr('id', function (d): any {
        return 'T' + d[2];
      })
      .attr('text-anchor', 'middle')
      .attr('font-family', 'helvetica')
      .attr('font-size', '11px')
      .attr('x', function (d): any {
        return x(d[0] as Date);
      })
      .attr('y', function (d): any {
        return yCrop(d[1] as number + self.distanceLabelToCircleCrop);
      });

    // Bottom line
    focus3.append('path')
      .datum(this.data2)
      .attr('id', 'bottomLine')
      .attr('fill', 'none')
      .attr('stroke', 'brown')
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 2)
      .attr('d', lineBottom);

    // Draggable circles on bottom line
    focus3.selectAll('circle')
      .data(this.data2)
      .enter()
      .append('circle')
      .filter(function (d): any {
        return d[2] === 'R2' || d[2] === 'R3' || d[2] === 'R4';
      })
      .attr('r', 7.0)
      .attr('cx', function (d): number {
        return x(d[0] as number);
      })
      .attr('cy', function (d): number {
        return yRootzone(d[1] as number);
      })
      .attr('id', function (d): string {
        return d[2] as string;
      })
      .style('cursor', 'pointer')
      .style('fill', 'red');

    // Labels bottom line
    focus3.selectAll('text')
      .data(this.data2)
      .enter()
      .append('text')
      .text(function (d): any {
        return d[1];
      })
      .attr('id', function (d): any {
        return 'T' + d[2];
      })
      .attr('text-anchor', 'middle')
      .attr('font-family', 'helvetica')
      .attr('font-size', '11px')
      .attr('x', function (d): any {
        return x(d[0] as Date);
      })
      .attr('y', function (d): any {
        return yRootzone(d[1] as number - self.distanceLabelToCircleRootzone);
      });

    // nill line
    focus.append('g')
      .append('line')
      .attr('x1', 0)
      .attr('y1', contentHeight / 2)
      .attr('x2', contentWidth)
      .attr('y2', contentHeight / 2)
      .style('stroke', 'brown')
      .style('stroke-width', '5px');

    focus.append('path')
      .datum(this.data)
      .attr('id', 'topArea')
      .style('fill', 'url(#crop_image)')
      .attr('d', areaTop);

    focus.append('path')
      .datum(this.data2)
      .attr('id', 'bottomArea')
      .style('fill', 'url(#roots_image)')
      .attr('d', areaBottom);

    defs.append('svg:pattern')
      .attr('id', 'crop_image')
      .attr('width', 60)
      .attr('height', 282)
      .attr('patternUnits', 'userSpaceOnUse')
      .append('svg:image')
      .attr('xlink:href', '../../../../../assets/img/Kc_r_graph_imgs/corn-fields.svg')
      .attr('width', 60)
      .attr('height', 282)
      .attr('x', -2)
      .attr('y', yCrop(0) - 260 + 65);

    defs.append('svg:pattern')
      .attr('id', 'roots_image')
      .attr('width', 60)
      .attr('height', 282)
      .attr('patternUnits', 'userSpaceOnUse')
      .append('svg:image')
      .attr('xlink:href', '../../../../../assets/img/Kc_r_graph_imgs/roots_for_chart.png')
      .attr('width', 60)
      .attr('height', 282)
      .attr('x', 0)
      .attr('y', yRootzone(0) + self.margin.top - 91);

    // drag functions
    function xDragStarted(d): void {
      d3.select('.visibility').style('display', 'none');
      self.visible = false;
      d3.select(this).raise().classed('active', true);
      if (d3.select(this).attr('id') === 'startLine') {
        d3.select('#tooltip')
          .style('left', x(self.data[0][0] as any) + 'px')
          .select('#date')
          .text(timeFormat(self.data[0][0] as Date));

        d3.select('#tooltip')
          .select('#label')
          .text('Seeding / begin of season');
      } else if (d3.select(this).attr('id') === 'cropDevelopment') {
        d3.select('#tooltip')
          .style('left', x(self.data[1][0] as any) + 'px')
          .select('#date')
          .text(timeFormat(self.data[1][0] as Date));

        d3.select('#tooltip')
          .select('#label')
          .text('10% groundcover');
      } else if (d3.select(this).attr('id') === 'midSeason') {
        d3.select('#tooltip')
          .style('left', x(self.data[2][0] as any) + 'px')
          .select('#date')
          .text(timeFormat(self.data[2][0] as Date));

        d3.select('#tooltip')
          .select('#label')
          .text('Flowering / full leaf');
      } else if (d3.select(this).attr('id') === 'lateSeason') {
        d3.select('#tooltip')
          .style('left', x(self.data[3][0] as any) + 'px')
          .select('#date')
          .text(timeFormat(self.data[3][0] as Date));

        d3.select('#tooltip')
          .select('#label')
          .text('Maturity');
      } else if (d3.select(this).attr('id') === 'endSeason') {
        d3.select('#tooltip')
          .style('left', x(self.data[4][0] as any) + 'px')
          .select('#date')
          .text(timeFormat(self.data[4][0] as Date));

        d3.select('#tooltip')
          .select('#label')
          .text('Harvest / leaf fall / end of season');
      }
      d3.select('#tooltip').classed('hidden', false);
    }

    function updatingX(d): void {
      d[0] = x.invert(d3.event.x);
      const circleNumber = d[2].substr(-1);
      const arrayNumber = Number(circleNumber) - 1;

      // Update circle positions
      d3.select('#C' + circleNumber).attr('cx', x(d[0]));
      d3.select('#R' + circleNumber).attr('cx', x(d[0]));

      // Update textlabels
      d3.select('#TC' + circleNumber).attr('x', x(d[0]));
      d3.select('#TR' + circleNumber).attr('x', x(d[0]));
      // Updating data
      self.data[arrayNumber][0] = x.invert(d3.event.x);
      self.data2[arrayNumber][0] = x.invert(d3.event.x);

      // Updating x values
      if (arrayNumber === 0) {
        d3.select('#startLine').attr('x1', x(d[0]));
        d3.select('#startLine').attr('x2', x(d[0]));
      } else if (arrayNumber === 1) {
        d3.select('#cropDevelopment').attr('x1', x(d[0]));
        d3.select('#cropDevelopment').attr('x2', x(d[0]));
      } else if (arrayNumber === 2) {
        d3.select('#midSeason').attr('x1', x(d[0]));
        d3.select('#midSeason').attr('x2', x(d[0]));
      } else if (arrayNumber === 3) {
        d3.select('#lateSeason').attr('x1', x(d[0]));
        d3.select('#lateSeason').attr('x2', x(d[0]));
      } else if (arrayNumber === 4) {
        d3.select('#endSeason').attr('x1', x(d[0]));
        d3.select('#endSeason').attr('x2', x(d[0]));
      }

      // Updating both paths
      focus2.select('#topLine').attr('d', lineTop);
      focus3.select('#bottomLine').attr('d', lineBottom);

      // Updating both area's
      svg.select('#topArea').attr('d', areaTop);
      svg.select('#bottomArea').attr('d', areaBottom);

      d3.select('#tooltip').select('#date').text(timeFormat(x.invert(d3.event.x)));
    }

    function xDragged(d): void {
      if (d3.select(this).attr('id') === 'startLine') {
        if (x.invert(d3.event.x) < self.data[1][0] && x.invert(d3.event.x) > x.domain()[0]) {
          d = self.data[0];
          updatingX(d);
        }
      } else if (d3.select(this).attr('id') === 'cropDevelopment') {
        if (x.invert(d3.event.x) > self.data[0][0] && x.invert(d3.event.x) < self.data[2][0]) {
          d = self.data[1];
          updatingX(d);
        }
      } else if (d3.select(this).attr('id') === 'midSeason') {
        if (x.invert(d3.event.x) > self.data[1][0] && x.invert(d3.event.x) < self.data[3][0]) {
          d = self.data[2];
          updatingX(d);
        }
      } else if (d3.select(this).attr('id') === 'lateSeason') {
        if (x.invert(d3.event.x) > self.data[2][0] && x.invert(d3.event.x) < self.data[4][0]) {
          d = self.data[3];
          updatingX(d);
        }
      } else if (d3.select(this).attr('id') === 'endSeason') {
        if (x.invert(d3.event.x) > self.data[3][0] && x.invert(d3.event.x) < x.domain()[1]) {
          d = self.data[4];
          updatingX(d);
        }
      }
    }

    function xDragEnded(d): void {
      d3.select('#tooltip').classed('hidden', true);
      d3.select(this).raise().classed('active', false);

      if (d3.select(this).attr('id') === 'startLine') {
        self.startOfSeasonPicker.setValue(self.data[0][0], { emitEvent: false });
      } else if (d3.select(this).attr('id') === 'cropDevelopment') {
        self.cropDevelopmentPicker.setValue(self.data[1][0], { emitEvent: false });
      } else if (d3.select(this).attr('id') === 'midSeason') {
        self.midSeasonPicker.setValue(self.data[2][0], { emitEvent: false });
      } else if (d3.select(this).attr('id') === 'lateSeason') {
        self.lateSeasonPicker.setValue(self.data[3][0], { emitEvent: false });
      } else if (d3.select(this).attr('id') === 'endSeason') {
        self.endSeasonPicker.setValue(self.data[4][0], { emitEvent: false });
      }
    }

    // Y drag
    const drag = d3.drag()
      .on('start', dragStarted)
      .on('drag', dragged)
      .on('end', dragEnded);

    // call drag functions on circles
    focus2.selectAll('circle')
      .call(drag);
    focus3.selectAll('circle')
      .call(drag);

    // drag started function (place class active => true)
    function dragStarted(d): void {
      d3.select(this).raise().classed('circle-active', true);
    }

    function dragEnded(d): void {
      self.dataKcR.emit({ kc: self.data, r: self.data2 });
      d3.select(this).classed('circle-active', false);
    }

    function dragged(d): void {
      if (d[2].charAt(0) === 'C') {
        // circles on top line
        if (yCrop.invert(d3.event.y) >= 0) {
          if (d[2] === 'C2') {
            if (yCrop.invert(d3.event.y) <= yCrop.domain()[0]) {
              d[1] = yCrop.invert(d3.event.y);
              d3.select('#C2').attr('cy', yCrop(d[1]));
              d3.select('#TC2').attr('y', yCrop(d[1] + self.distanceLabelToCircleCrop));
              d3.select('#TC2').text(function (c): any {
                return Math.round(c[1] * 100) / 100;
              });

              self.data[1][1] = Math.round(yCrop.invert(d3.event.y) * 100) / 100;
              self.data[0][1] = Math.round(yCrop.invert(d3.event.y) * 100) / 100;

              d3.select('#C1').attr('cy', yCrop(d[1]));
              d3.select('#TC1').attr('y', yCrop(d[1] + self.distanceLabelToCircleCrop));
              d3.select('#TC1').text(function (b): any {
                return Math.round(b[1] * 100) / 100;
              });

              // Update vertical lines
              d3.select('#startLine').attr('y1', yCrop(d[1]));
              d3.select('#cropDevelopment').attr('y1', yCrop(d[1]));
            }
          } else if (d[2] === 'C3') {
            if (yCrop.invert(d3.event.y) <= yCrop.domain()[0]) {
              d[1] = yCrop.invert(d3.event.y);
              d3.select('#C3').attr('cy', yCrop(d[1]));
              d3.select('#TC3').attr('y', yCrop(d[1] + self.distanceLabelToCircleCrop));
              d3.select('#TC3').text(function (cd): any {
                return Math.round(cd[1] * 100) / 100;
              });

              self.data[2][1] = Math.round(yCrop.invert(d3.event.y) * 100) / 100;
              self.data[3][1] = Math.round(yCrop.invert(d3.event.y) * 100) / 100;

              d3.select('#C4').attr('cy', yCrop(d[1]));
              d3.select('#TC4').attr('y', yCrop(d[1] + self.distanceLabelToCircleCrop));
              d3.select('#TC4').text(function (dd): any {
                return Math.round(dd[1] * 100) / 100;
              });

              // Update vertical lines
              d3.select('#midSeason').attr('y1', yCrop(d[1]));
              d3.select('#lateSeason').attr('y1', yCrop(d[1]));
            }
          } else {
            if (yCrop.invert(d3.event.y) <= yCrop.domain()[0]) {
              d[1] = yCrop.invert(d3.event.y);
              d3.select(this).attr('cy', yCrop(d[1]));

              self.data[4][1] = Math.round(yCrop.invert(d3.event.y) * 100) / 100;

              d3.select('#TC5').attr('y', yCrop(d[1] + self.distanceLabelToCircleCrop));
              d3.select('#TC5').text(function (cc): any {
                return Math.round(cc[1] * 100) / 100;
              });

              // Update vertical line
              d3.select('#endSeason').attr('y1', yCrop(d[1]));
            }
          }
        }
        focus2.select('#topLine').attr('d', lineTop);
        svg.select('#topArea').attr('d', areaTop);
      } else {
        // circles bottom (rootzone)
        if (yRootzone.invert(d3.event.y) <= 0 && yRootzone.invert(d3.event.y) >= yRootzone.domain()[1]) {
          if (d[2] === 'R4') {
            if (yRootzone.invert(d3.event.y) >= yRootzone.domain()[1] &&
              yRootzone.invert(d3.event.y) <= yRootzone.invert(parseFloat(d3.select('#R3').attr('cy')))) {
              d[1] = yRootzone.invert(d3.event.y);
              d3.select('#R5').attr('cy', yRootzone(d[1]));
              d3.select('#TR5').text(function (q): any {
                return Math.round(q[1] * 100) / 100;
              });
              self.data2[4][1] = Math.round(yRootzone.invert(d3.event.y) * 100) / 100;

              d3.select('#R4').attr('cy', yRootzone(d[1]));
              d3.select('#TR4').text(function (q): any {
                return Math.round(q[1] * 100) / 100;
              });
              self.data2[3][1] = Math.round(yRootzone.invert(d3.event.y) * 100) / 100;

              // Update vertical lines
              d3.select('#endSeason').attr('y2', yRootzone(d[1]));
              d3.select('#lateSeason').attr('y2', yRootzone(d[1]));

              if (yRootzone.invert(d3.event.y) >= yRootzone.domain()[1] + self.distanceLabelToCircleRootzone) {
                d3.select('#TR5').attr('y', yRootzone(d[1] - self.distanceLabelToCircleRootzone));
                d3.select('#TR4').attr('y', yRootzone(d[1] - self.distanceLabelToCircleRootzone));
              } else {
                d3.select('#TR5').attr('y', yRootzone(yRootzone.domain()[1] + self.distanceLabelToCircleRootzone));
                d3.select('#TR4').attr('y', yRootzone(yRootzone.domain()[1] + self.distanceLabelToCircleRootzone));
              }
            }
          } else if (d[2] === 'R3') {
            if (yRootzone.invert(d3.event.y) >= yRootzone.invert(parseFloat(d3.select('#R4').attr('cy'))) &&
              yRootzone.invert(d3.event.y) <= yRootzone.invert(parseFloat(d3.select('#R2').attr('cy')))) {
              d[1] = yRootzone.invert(d3.event.y);
              d3.select('#R3').attr('cy', yRootzone(d[1]));

              self.data2[2][1] = Math.round(yRootzone.invert(d3.event.y) * 100) / 100;

              d3.select('#TR3').text(function (xc): any {
                return Math.round(xc[1] * 100) / 100;
              });
              d3.select('#midSeason').attr('y2', yRootzone(d[1]));

              if (yRootzone.invert(d3.event.y) >= yRootzone.domain()[1] + self.distanceLabelToCircleRootzone) {
                d3.select('#TR3').attr('y', yRootzone(d[1] - self.distanceLabelToCircleRootzone));
              } else {
                d3.select('#TR3').attr('y', yRootzone(yRootzone.domain()[1] + self.distanceLabelToCircleRootzone));
              }
            }
          } else if (d[2] === 'R2') {
            if (yRootzone.invert(d3.event.y) >=
                yRootzone.invert(parseFloat(d3.select('#R3').attr('cy'))) && yRootzone.invert(d3.event.y) <= -0.1) {
              d[1] = yRootzone.invert(d3.event.y);

              self.data2[0][1] = Math.round(yRootzone.invert(d3.event.y) * 100) / 100;
              self.data2[1][1] = Math.round(yRootzone.invert(d3.event.y) * 100) / 100;

              d3.select('#TR1').attr('y', yRootzone(d[1] - self.distanceLabelToCircleRootzone));
              d3.select('#TR1').text(function (af): any {
                return Math.round(af[1] * 100) / 100;
              });

              d3.select('#R2').attr('cy', yRootzone(d[1]));
              d3.select('#T' + d3.select('#R2').attr('id')).attr('y', yRootzone(d[1] - self.distanceLabelToCircleRootzone));
              d3.select('#T' + d3.select('#R2').attr('id')).text(function (af): any {
                return Math.round(af[1] * 100) / 100;
              });

              d3.select('#startLine').attr('y2', yRootzone(d[1]));
              d3.select('#cropDevelopment').attr('y2', yRootzone(d[1]));
            }
          }
        }
        focus3.select('#bottomLine').attr('d', lineBottom);
        svg.select('#bottomArea').attr('d', areaBottom);
      }
    }
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }
}
