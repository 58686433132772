<div *ngIf="notificationList$ | async; else loading">
  <app-form-layout *ngIf="(notificationList$ | async).length > 0 else noData">
    <div style="padding: 20px">
      <form [formGroup]="form">
        <div style="width: 100%">
          <div style="margin-bottom: 20px; float: right">
            <app-custom-dropdown
              class="periodDropdown"
              [options]="periodOptions"
              [formControl]="periodControl"
            ></app-custom-dropdown>
            <app-custom-button
              [color]="'blue'"
              (click)="markAllRead()"
              [content]="'Mark all as read'"
            >
            </app-custom-button>
          </div>
          <!-- <div style="margin-right: 20px; display: inline-block;">          {{ 'Show unread only' | translate}}          <app-yes-no-toggle            (change)="onChange($event)"            noTab="true">          </app-yes-no-toggle>        </div> -->
        </div>
        <!-- <button (click)="changeRandomToUnseen()">Change random to not seen</button> -->
      </form>
      <div
        class="table-responsive"
        style="border: 1px solid #dddddd; width: 100%"
      >
        <table class="notifications-table table-condensed">
          <thead></thead>
          <tbody *ngIf="(notificationList$ | async).length > 0">
            <tr
              *ngFor="
                let item of getData(notificationList$ | async);
                let i = index
              "
              style="display: flex; cursor: pointer"
              (click)="navigateToUrl(item)"
            >
              <div
                class="left"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <i class="{{ item.icon }} icon"></i>
              </div>
              <div class="right">
                <div class="title">
                  {{ item.title | translate }}
                </div>
                <div class="station">
                  {{ "Station" | translate }}: {{ item.stationID }}
                </div>
                <div class="body">
                  {{ item.body }}
                </div>
              </div>
              <!-- <div
                class="eye"
                *ngIf="!item.seen"
                (click)="markAsRead(item); $event.stopPropagation()"
              >
                <i class="zmdi zmdi-eye-off" style="cursor: pointer"></i>
                <span class="tooltiptext" style="background-color: black">{{
                  "Mark as read" | translate
                }}</span>
              </div> -->
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-container">
        <pagination
          [boundaryLinks]="'showBoundaryLinks'"
          [maxSize]="10"
          [(ngModel)]="page"
          [itemsPerPage]="itemLimit"
          [totalItems]="(notificationList$ | async)?.length"
          previousText=" "
          nextText=" "
          firstText=" "
          lastText=" "
        ></pagination>
      </div>
    </div>
  </app-form-layout>
</div>
<ng-template #loading>
  <app-form-layout>
    <div [@cardAnimation] class="load">
      <h4>
        <i class="fa fa-cog fa-spin fa-fw"></i>
        {{ "Please wait, loading data ..." | translate }}
      </h4>
    </div>
  </app-form-layout>
</ng-template>


<ng-template #noData>
  <app-form-layout>
    <div [@cardAnimation] class="load">
      <h4>
        {{ "No active notifications for you" | translate }}
      </h4>
    </div>
  </app-form-layout>
</ng-template>
