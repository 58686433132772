<app-station-page-header [pageName]="'Station events'"></app-station-page-header>
<app-form-layout>
  <div class="station-events">
    <table>
      <thead>
      <tr>
        <th>{{ 'Station Time' | translate }}</th>
        <th>{{ 'Code' | translate }}</th>
        <th>{{ 'Description' | translate }}</th>
        <th>{{ 'Keyword' | translate }}</th>
      </tr>
      </thead>
      <tbody *ngIf="stationEvents.length && !(isError$ | async) && !(isLoading$ | async)">
      <tr *ngFor="let event of stationEvents">
        <td>
          <span>{{ getFormattedDate(event.dateTime) }}</span>
        </td>
        <td>
          <span>{{ event.code }}</span>
        </td>
        <td>
          <span>{{ event.description }}</span>
        </td>
        <td>
          <span>{{ event.keyword }}</span>
        </td>
      </tr>
      </tbody>
    </table>
    <h4 *ngIf="isLoading$ | async">{{ 'Please wait, loading data ...' | translate }}</h4>
    <h4 *ngIf="isError$ | async">{{ 'No data for selected station' | translate }}</h4>
  </div>
</app-form-layout>
