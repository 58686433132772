import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  HostBinding,
  Input, OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { treeAnimations } from '../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../core/services/left-components-toggler/left-components-toggler.service';
import { ITreeStructure, TreeType } from '../../services/tree/models';
import { AdDirective } from '../ad/ad.directive';
import { TreeCardsComponent } from './containers/tree-cards/tree-cards.component';
import { TreeLinksComponent } from './containers/tree-links/tree-links.component';
import { TreeNestedLinksComponent } from './containers/tree-nested-links/tree-nested-links.component';
import { TreeSensorsComponent } from './containers/tree-sensors/tree-sensors.component';
import { ITreeComponent } from './models/tree.models';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  animations: treeAnimations
})
export class TreeComponent implements OnInit, OnDestroy {
  @Input()
  public tree$: Observable<ITreeStructure>;

  public stationOrCropzone  : string;
  public station            : string = 'station';
  public cropzone           : string = 'cropzone';

  @ViewChild(AdDirective, {static: true})
  public adHost: AdDirective;

  @HostBinding('@placeTree')
  public state: string;

  private destroy$: Subject<boolean>  = new Subject<boolean>();

  private treeTypeComponentMap: {[treeType: string]: Type<ITreeComponent>} = {
    [TreeType.Sensors]: TreeSensorsComponent,
    [TreeType.Links]: TreeLinksComponent,
    [TreeType.NestedLinks]: TreeNestedLinksComponent,
    [TreeType.Cards]: TreeCardsComponent
  };

  private viewContainerRef: ViewContainerRef;
  private componentRef: ComponentRef<ITreeComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private leftComponentsToggler: LeftComponentsTogglerService,
    private activatedRoute: ActivatedRoute
  ) { }

  public ngOnInit(): void {
    this.stationOrCropzone = this.activatedRoute.parent.parent.routeConfig.path;
    this.viewContainerRef = this.adHost.viewContainerRef;
    this.initTree();


      this.leftComponentsToggler.getTreeState().subscribe((treeState: string): void => {
        this.state = treeState;
      });
  }

  private initTree(): void {
    this.tree$
      .pipe(
        takeUntil(this.destroy$),
        filter((tree: ITreeStructure): boolean => !!tree && !!this.treeTypeComponentMap[tree.type])
      )
      .subscribe((tree: ITreeStructure) => {
        const treeComponent: Type<ITreeComponent> = this.treeTypeComponentMap[tree.type];
        const componentFactory: ComponentFactory<ITreeComponent> = this.componentFactoryResolver.resolveComponentFactory(treeComponent);
        this.viewContainerRef.clear();
        if (this.componentRef) {
          this.componentRef.destroy();
        }
        this.componentRef = this.adHost.viewContainerRef.createComponent(componentFactory);
        (<ITreeComponent>this.componentRef.instance).setTree(tree);
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
