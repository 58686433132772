import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject, timer } from 'rxjs';
import { debounce, debounceTime, filter, startWith, takeUntil } from 'rxjs/operators';
import { updateShowFinishedCropzones } from '../../actions/account';
import { farmsOrderSettings } from '../../containers/main-static/right-cropzones/constants/constants';
import { selectShowFinishedCropzones } from '../../reducers';
import { IAccount } from '../../reducers/account';
import { NavigationService } from '../../services/navigation/navigation.service';
import { ISearchWidgetCropZoneItem } from '../search-widget/list-cropzone-item/search-widget-list-cropzone-item.component';
import { IOrderSettingCropzoneItem, IOrderSettingItem, ISortReturnValuesSet } from '../search-widget/models/search-widget.models';
import { ICropZone } from './../../models/cropzones';
import * as moment from 'moment';

@Component({
  selector: 'app-search-widget-cropzones',
  templateUrl: './search-widget-cropzones.component.html',
  styleUrls: ['./search-widget-cropzones.component.scss']
})
export class SearchWidgetCropZonesComponent implements OnInit, OnDestroy {

  @Input()
  public orderSettings     : Array<IOrderSettingCropzoneItem>;
  @Input()
  public searchPlaceholder : string;
  @Input()
  public items             : Array<ISearchWidgetCropZoneItem> = [];
  @Input()
  public items$            : Observable<Array<ISearchWidgetCropZoneItem>>;
  @Input()
  public cropzones         : ICropZone[];
  @Input()
  public isActive          : boolean;
  @Input()
  public selectedCropzone      : ICropZone;

  public hover$ = new BehaviorSubject(true);
  public focus$ = new BehaviorSubject(false);

  @Output()
  public close             : EventEmitter<null> = new EventEmitter<null>();

  @Output()
  private openAddCropZoneModalEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  private openRemoveCropZoneModalEvent: EventEmitter<void> = new EventEmitter<void>();

  public searchTermControl      : FormControl = new FormControl('');
  public sort$                  : BehaviorSubject<void> = new BehaviorSubject<void>(null);
  public handledItems           : Array<ISearchWidgetCropZoneItem>;
  public currentOrderSettingItem: IOrderSettingCropzoneItem;
  public orderDirection         : string = 'asc';
  public sortValues             : ISortReturnValuesSet = {
    asc : {lower: 1, equal: 0, higher: -1},
    desc: {lower: -1, equal: 0, higher: 1},
  };

  private destroy$              : Subject<boolean> = new Subject();
  private sortCropzonesBy       : string;
  public form                   : FormGroup;
  private showFinishedCropzones : boolean;

  constructor(
    private navigation: NavigationService,
    private fb: FormBuilder,
    private accountStore: Store<IAccount>) {
  }

  public get toggle(): AbstractControl {
    return this.form.get('toggle');
  }

  public ngOnInit(): void {
    this.accountStore.pipe(
      select(selectShowFinishedCropzones),
      takeUntil(this.destroy$)
    ).subscribe(showFinishedCropzones => {
      this.showFinishedCropzones = showFinishedCropzones;

      if (!this.form) {
        this.form = this.fb.group({
          'toggle': showFinishedCropzones
        });
      } else {
        this.toggle.setValue(showFinishedCropzones);
      }
    });

    if (localStorage.getItem('showFinishedCropzones')) {
      this.showFinishedCropzones = JSON.parse(localStorage.getItem('showFinishedCropzones'));

      this.accountStore.dispatch(updateShowFinishedCropzones(this.showFinishedCropzones));
      this.showFinishedCropzones = this.showFinishedCropzones;
    } else {
      this.showFinishedCropzones = false;
    }

    this.initClosing();
    this.initResultList();

    this.toggle.setValue(this.showFinishedCropzones);

    this.sortCropzonesBy = localStorage.getItem('sortingCropzones');

    this.toggle.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(value => {
        localStorage.setItem('showFinishedCropzones', value);
        this.accountStore.dispatch(updateShowFinishedCropzones(value));
        this.initResultList();
    });

    if (this.sortCropzonesBy &&
      farmsOrderSettings.find(
        (sortOption: IOrderSettingItem)  => sortOption.orderField === this.sortCropzonesBy)) {
        this.currentOrderSettingItem = this.orderSettings.find(
          (orderSettingsItem: IOrderSettingCropzoneItem) => orderSettingsItem.orderField === this.sortCropzonesBy);
    } else {
      this.currentOrderSettingItem = this.orderSettings.find(
        (orderSettingsItem: IOrderSettingCropzoneItem) => orderSettingsItem.default);
    }
  }

  public openAddCropZoneModal(): void {
    this.openAddCropZoneModalEvent.emit();
  }

  public openRemoveCropZoneModal(): void {
    this.openRemoveCropZoneModalEvent.emit();
  }

  public setOrder(orderField: string): void {
    if (this.currentOrderSettingItem.orderField === orderField) {
      this.orderDirection = (this.orderDirection === 'asc') ? 'desc' : 'asc';
    } else {
      this.currentOrderSettingItem = this.orderSettings
        .find((orderSettingsItem: IOrderSettingCropzoneItem) => orderSettingsItem.orderField === orderField);
      this.orderDirection = 'asc';
    }
    this.sort$.next(null);
  }

  public selectCropzone(selectedCropzone: ICropZone): void {
    this.navigation.changeCropzone(selectedCropzone.id);
  }

  public getDirection(orderSettingsItem: IOrderSettingCropzoneItem): string {
    if (this.currentOrderSettingItem.orderField === orderSettingsItem.orderField) {
      return this.orderDirection;
    }

    return '';
  }

  public isSelected(item: ISearchWidgetCropZoneItem): boolean {
    return this.selectedCropzone && this.selectedCropzone.id === item.id;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initClosing(): void {
    combineLatest([
      this.hover$,
      this.focus$
    ]).pipe(
      takeUntil(this.destroy$),
      debounce(() => timer(3000)),
      filter(([hover, focus]) => !hover && !focus)
    ).subscribe(() => this.close.emit());
  }

  private initResultList(): void {
    combineLatest([
      this.items$.pipe(
        takeUntil(this.destroy$),
        filter((items: Array<ISearchWidgetCropZoneItem>): boolean => Array.isArray(items))
      ),
      this.searchTermControl.valueChanges.pipe(
        takeUntil(this.destroy$),
        startWith(''),
        debounceTime(400),
      ),
      this.sort$.pipe(takeUntil(this.destroy$))
    ]).subscribe((result: Array<Array<ISearchWidgetCropZoneItem>|string|void>): void => {
        let items = <Array<ISearchWidgetCropZoneItem>>result[0];
        if ((this.toggle.value === false) && items.length > 0) {
          items = this.dontShowFinishedCropzones(items);
        }
        const sortedArray = this.sortArray(this.filterArray(items));
        this.handledItems = this.sortStarred(sortedArray);
      });
  }

  private dontShowFinishedCropzones (items: Array<ISearchWidgetCropZoneItem>): Array<ISearchWidgetCropZoneItem> {
    const now = moment();
    return items.filter(item =>
      moment(item.to).isAfter(now)
    );
  }

  private filterArray(items: Array<ISearchWidgetCropZoneItem>): Array<ISearchWidgetCropZoneItem> {
    const preparedSearchTerm = this.preparedSearchTerm;
    return  items.filter((item: ISearchWidgetCropZoneItem): boolean => {
      const preparedFarmName = this.prepareStringForSearch(item.farm.name);
      const preparedFieldName = this.prepareStringForSearch(item.field.name);
      const preparedCropzoneName = this.prepareStringForSearch(item.name);
      if (item.crop_name) {
      const preparedCustomName = this.prepareStringForSearch(item.crop_name);
      preparedCustomName.includes(preparedSearchTerm);
      }
      // tslint:disable-next-line:max-line-length
      return preparedFarmName.includes(preparedSearchTerm) || preparedFieldName.includes(preparedSearchTerm) || preparedCropzoneName.includes(preparedSearchTerm);
    });
  }

  private get preparedSearchTerm(): string {
    return this.prepareStringForSearch(this.searchTermControl.value);
  }

  private sortArray(items: Array<ISearchWidgetCropZoneItem>): Array<ISearchWidgetCropZoneItem> {
    if (this.currentOrderSettingItem.orderField === 'farm' || this.currentOrderSettingItem.orderField === 'field') {
        return [...items].sort((itemA: ISearchWidgetCropZoneItem, itemB: ISearchWidgetCropZoneItem): number => {
        const propertyA: string = itemA[this.currentOrderSettingItem.orderField].name.toLowerCase();
        const propertyB: string = itemB[this.currentOrderSettingItem.orderField].name.toLowerCase();

        if (propertyA > propertyB) {
          return this.sortValues[this.orderDirection].lower;
        }

        if (propertyA < propertyB) {
          return this.sortValues[this.orderDirection].higher;
        }

        if (propertyA === propertyB) {
          return 0;
        }

      });
    } else if (this.currentOrderSettingItem.orderField === 'year' || this.currentOrderSettingItem.orderField === 'name') {
      return [...items].sort((itemA: ISearchWidgetCropZoneItem, itemB: ISearchWidgetCropZoneItem): number => {
        const propertyA: string = itemA[this.currentOrderSettingItem.orderField].toLowerCase();
        const propertyB: string = itemB[this.currentOrderSettingItem.orderField].toLowerCase();

        if (propertyA > propertyB) {
          return this.sortValues[this.orderDirection].lower;
        }

        if (propertyA < propertyB) {
          return this.sortValues[this.orderDirection].higher;
        }

        if (propertyA === propertyB) {
          return 0;
        }

      });
    }
  }

  private sortStarred(sortedArray: Array<ISearchWidgetCropZoneItem>): Array<ISearchWidgetCropZoneItem> {
    const starredItems = sortedArray.filter((item) => item.starred);
    const unStarredItems = sortedArray.filter((item) => !item.starred);
    return starredItems.concat(unStarredItems);
  }

  private prepareStringForSearch(initString: string): string {
    return initString.toLowerCase().split(' ').join('');
  }
}
