import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { ChartTranslationsService } from '../../../../core/services/chart-translations.service';
import { deepClone } from '../../../../shared/utils/deepClone';
import chartZoomSync from '../../../irrimet/chartZoomSync';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Options } from 'highcharts';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { filter, skip, takeUntil } from 'rxjs/operators';
import { ISettings } from '../../../../core/models/account';
import { selectSettings } from '../../../../core/reducers';
import * as fromAccount from '../../../../core/reducers/account';
import { ITree } from '../../../../services/tree/models';
import {
  setFrostProtectionCharts,
  setFrostProtectionTreeNodes
} from '../../actions/actions';
import { CHART_ATTRIBUTES, CHART_COLORS, DEFAULT_CHART_OPTIONS, GROUP_VALUES } from '../../constants/constants';
import {
  IChartColors, IChartSeries,
  IFrostProtectionSensors,
  IFrostProtectionState,
  IFrostProtectionValues, IFrostProtectionVisibility, IThreshold
} from '../../models/models';
import {
  selectFrostProtectionIsChartActive,
  selectFrostProtectionResponse,
  selectFrostProtectionTree,
  selectFrostProtectionVisibility
} from '../../selectors/selectors';
import { PictocodeService } from '../../../../services/pictocode/pictocode.service';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';

@Component({
  selector: 'app-frost-protection-charts',
  templateUrl: './frost-protection-charts.component.html',
  styleUrls: ['./frost-protection-charts.component.scss']
})
export class FrostProtectionChartsComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public exportChartImg             : boolean;
  public charts                     : Array<Chart> = [];
  public response                   : any = null;
  public series                     : any = [];
  public isChartActive$             : Observable<boolean>;

  private translatedValues          : IFrostProtectionValues;
  private data                      : any = [];
  private lastMeasureTime           : number = null;
  private nowTime                   : number = null;
  private measurementRecordsLength  : number;
  private chartColors               : IChartColors = CHART_COLORS;
  private chartAttributes           : IChartSeries = CHART_ATTRIBUTES;
  private unitSystem                : string = '';
  private sensors                   : Array<IFrostProtectionSensors> = [];
  private tree                      : ITree;
  private chartsOptions             : Array<Options> = [];
  private visibility                : IFrostProtectionVisibility = null;
  private forecastLicense           : boolean;
  private oneHourGap                : boolean = false;
  private groupValues               = GROUP_VALUES;
  private destroy$                  : Subject<boolean> = new Subject<boolean>();

  constructor(
    private chartTranslationsService      : ChartTranslationsService,
    private route                         : ActivatedRoute,
    private frostProtectionStore          : Store<IFrostProtectionState>,
    private accountStore                  : Store<fromAccount.IAccount>,
    private translateService              : TranslateService,
    private pictocodeService              : PictocodeService,
    private exportService                 : StationDataExportService
  ) {
      // get unit system
      this.accountStore.pipe(
        takeUntil(this.destroy$),
        select(selectSettings),
        filter((settings: ISettings) => !!settings)
      ).subscribe((settings: ISettings) => {
        this.unitSystem = settings.unit_system;
      });
  }

  public ngOnInit(): void {
    this.chartTranslationsService.translateShortMonths();
    this.translatedValues = this.route.snapshot.data['frostProtectionResolver'];

    // sync chart with the tree
    this.frostProtectionStore.pipe(
      select(selectFrostProtectionVisibility),
      skip(1),
      takeUntil(this.destroy$)
    ).subscribe((visibility: IFrostProtectionVisibility) => {
      this.visibility = visibility;
      this.setSeriesVisibility(visibility);
    });

    // if new response, get the response, map data and set series
    this.frostProtectionStore.pipe(
      select(selectFrostProtectionResponse),
      takeUntil(this.destroy$)
    ).subscribe((response: any) => {
      if (response) {
        this.response = response;

        this.getResponseData();
        this.mapData();
        this.getSensors();
        this.createOptions();
        this.addSeries();
        this.setOptions();

        this.frostProtectionStore.dispatch(setFrostProtectionCharts(this.chartsOptions));
      }
    });

    this.isChartActive$ = this.frostProtectionStore.pipe(
      takeUntil(this.destroy$),
      select(selectFrostProtectionIsChartActive)
    );

    this.frostProtectionStore.pipe(
      takeUntil(this.destroy$),
      select(selectFrostProtectionTree),
    ).subscribe((tree: ITree) => {
      if (tree.nodes) {
       this.tree = tree;
      }
    });
  }

  private mapData(): void {
    this.data = [];
    this.response.sensors.forEach((sensor, sensorInd) => {
      this.resetData(sensorInd, sensor);
      this.separateDates(sensorInd);

      // add the last measurement value to the forecast serie only if there is only one hour gap between measurement and forecast data
      if (this.oneHourGap) {
        const lastMeasurementValue = sensor.values.avg[this.measurementRecordsLength - 1];
        this.data[sensorInd]['values']['forecast']['forecast'].push(lastMeasurementValue);
        this.data[sensorInd]['values']['forecast']['pictocode'].push(null);
      }

      // separate values in two groups:
      // MEASUREMENT ('avg, 'max' and 'min' group values) and
      // FORECAST ('forecast' and 'pictocode' group values)
      Object.keys(sensor.values).forEach((groupValue, j) => {
        const group = this.groupValues['forecast'].includes(groupValue) ? 'forecast' : 'measurement';
        sensor.values[groupValue].forEach(value => {
          this.data[sensorInd]['values'][group][groupValue].push(value);
        });
      });
    });
  }

  private addSeries(): void {
    this.series = [];
    this.data.forEach((sensor, sensorInd) => {
      this.series.push(this.resetSeries());                           // prepare empty serie
      // for each group (MEASUREMENT dates and FORECAST dates) create series
      Object.keys(sensor.dates).forEach(group => {                    // dates: forecast, measurement
        sensor.dates[group].forEach((date, valueInd) => {
          Object.keys(sensor.values[group]).forEach(valueGroup => {   // valueGroup: avg, max, min, forecast, pictocode
            if (valueGroup !== 'max') {                               // leave out the 'max', because we need it for 'range'
              if (valueGroup === 'min') {                             // set range (min, max)
                this.series[sensorInd][group]['range'].push([
                  date,                                               // date
                  sensor.values[group][valueGroup][valueInd],         // min value
                  sensor.values[group]['max'][valueInd]]);            // max value
              } else {
                if (valueGroup === 'pictocode') {                         // for 'pictocode' valueGroup
                  if (sensor.values[group][valueGroup].length > 1) {      // if pictocode data exist (only for HC Air Temperature)
                    this.series[sensorInd][group][valueGroup].push([
                      date,                                                // date
                      sensor.values[group][valueGroup][valueInd]]);        // value
                  } else {                                                 // if pictocode data don't exist
                    delete this.series[sensorInd][group][valueGroup];
                  }
                } else {                                                  // for 'forecast' valueGroup
                  this.series[sensorInd][group][valueGroup].push([
                    date,                                                 // date
                    sensor.values[group][valueGroup][valueInd]]);         // value
                }
              }
            }
          });
        });
      });
    });

    this.series.forEach((sensor, sensorInd) => {          // for each sensor
      Object.keys(sensor).forEach(dataType => {           // set series for each data type (FORECAST and MEASUREMENT)
        if (dataType === 'measurement') {
          Object.keys(sensor['measurement']).forEach(valueGroup => {             // avg, range
            if (this.charts[0].ref) {
              this.charts[0].ref.series[sensorInd].setData(this.series[sensorInd]);
            } else {
              this.charts[0].addSerie(
                {
                  name: this.sensors[sensorInd].customName ?
                    this.sensors[sensorInd].customName + ' ' + this.translatedValues[dataType] :
                    this.sensors[sensorInd].translatedName + ' ' + this.translatedValues[dataType],
                  data: sensor[dataType][valueGroup],
                  color: valueGroup === 'avg' ?
                    this.sensors[sensorInd].colors[dataType] :
                    this.sensors[sensorInd].colors['forecast'],
                  type: valueGroup === 'avg' ? 'line' : 'arearange',
                  yAxis: 0
                });
            }
          });
        } else if (dataType === 'forecast') {

          Object.keys(sensor['forecast']).forEach(valueGroup => {             // forecast, pictocode
            if (this.charts[0].ref) {
              this.charts[0].ref.series[sensorInd].setData(this.series[sensorInd]);
            } else {
              if (valueGroup === 'forecast') {
                this.charts[0].addSerie(
                  {
                    name: this.sensors[sensorInd].customName ?
                      this.sensors[sensorInd].customName + ' ' + this.translatedValues[dataType] :
                      this.sensors[sensorInd].translatedName + ' ' + this.translatedValues[dataType],
                    data: this.forecastLicense ? sensor[dataType][valueGroup] : [],        // if no forecast license, don't set the data
                    color: this.sensors[sensorInd].colors[dataType],
                    type: 'line',
                    yAxis: 0,
                    showInLegend: this.sensors[sensorInd].group !== 9 && this.forecastLicense
                    // disable forecast for group 9 (Dry temp.) => forecast data does not exist
                  });
              } else if (valueGroup === 'pictocode') {
                this.charts[0].addSerie(
                  {
                    name: this.translatedValues[valueGroup] + ' ' + this.translatedValues[dataType],
                    data: this.forecastLicense ? this.getPictocodeData(sensor) : [],     // if no forecast license, don't set the data
                    color: 'transparent',
                    type: 'line',
                    yAxis: 0,
                    showInLegend: false,
                    marker: {
                      enabled: true,
                      radius: 20
                    },
                  });
              }
            }
          });
        }
      });
    });

    // show LAST measure point only when forecast license, otherwise not
    if (this.series.length) {
      if (this.forecastLicense) {                      // if forecast, define NOW point on chart
        this.addLastMeasurePoint();
      } else {                                         // if only measurement data, HIDE the forecast series
        const seriesForDisable = [];
        this.sensors.forEach(sensor => {
          const sensorSeries = sensor.series;
          if (sensorSeries.length === 4) {             // if HC Air Temperature (avg, range, forecast, pictocode)
            seriesForDisable.push(sensorSeries[2]);    // last two series are forecast (ind. 2 and 3)
            seriesForDisable.push(sensorSeries[3]);
          } else {                                     // if dry or bulb temperature (avg, range, forecast)
            seriesForDisable.push(sensorSeries[2]);    // last serie is forecast (ind. 2)
          }
        });

        seriesForDisable.forEach(serieInd => {
          this.charts[0].options.series[serieInd].visible = false;
          if (this.charts[0].ref) {
            this.charts[0].ref.series[serieInd].hide();
          }
        });
      }
    }
  }

  // define point on last measurement data
  private defineBlackPointOnSerie(measurementRecordsLength: number): any {
    if (measurementRecordsLength > 0) {
      // const reduceIndex = this.oneHourGap ? 1 : 2;
      const x = this.series[0].measurement['avg'][measurementRecordsLength - 1][0];
      const y = this.series[0].measurement['avg'][measurementRecordsLength - 1][1];
      return [x, y];
    }
    return null;
  }

  private addLastMeasurePoint(): void {
    // mark LAST measurement point
    const blackPointCoords = this.defineBlackPointOnSerie(this.measurementRecordsLength);

    // add last measurement line
    this.charts[0].addSerie(
      {
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: 5
        },
        data: [blackPointCoords],
        color: this.chartColors.lastMeasurement,
        showInLegend: false,
        enableMouseTracking: false
      });
  }

  private getPictocodeData(sensor): any {
    const pictocodeData = [];
    const sensorForecastData = sensor['forecast']['forecast'];
    const sensorPictocodeData = sensor['forecast']['pictocode'];

    // show pictocode marker only every three hours, if the one hour gap, skip the first element (we added it for connecting lines)
    for (let i = this.oneHourGap ? 1 : 0; i < sensorForecastData.length; i = i + 3) {
      pictocodeData.push({
        x: sensorForecastData[i][0],
        y: sensorForecastData[i][1] + 0.5,
        marker: {
          symbol:
          // if third hour and if link to pictocode marker, add a marker, otherwise not
            sensorPictocodeData[i][1] !== null ?
              'url(https://ng.fieldclimate.com' + sensorPictocodeData[i][1] + ')' :
              null
        }
      });
    }

    return pictocodeData;
  }

  private resetSeries(): any {
    return {
      measurement: {
        avg: [],
        range: []
      },
      forecast: {
        forecast: [],
        pictocode: []
      }
    };
  }

  private resetData(sensorInd: number, sensor): any {
    this.data[sensorInd] = [];
    this.data[sensorInd]['info'] = { name: sensor.name_original, ch: sensor.ch };
    this.data[sensorInd]['dates'] = { measurement: [], forecast: [] };
    this.data[sensorInd]['values'] = {
      measurement: { avg: [], max: [], min: [] },
      forecast: { forecast: [], pictocode: [] } };
  }

  // separate dates into 'measurement' and 'forecast' dates
  private separateDates(sensorInd: number): void {
    this.response.dates.forEach((record, dateInd) => {
      const date = record * 1000;
      if (date < this.lastMeasureTime) {                                                   // add measurement dates
        this.data[sensorInd]['dates']['measurement'].push(date);
      } else if (date === this.lastMeasureTime) {                                          // find the last measurement date
        // if there is only one hour gap or less between measurement and forecast data, add the last measurement value
        // to the first forecast value also to connect the two series into one
        if (this.response.dates[dateInd + 1] * 1000 <= this.lastMeasureTime + 3600000) {  // check if only one hour gap
          this.oneHourGap = true;
          this.data[sensorInd]['dates']['measurement'].push(date);                         // add measurement AND forecast date
          this.data[sensorInd]['dates']['forecast'].push(date);
        } else {                                                                           // if the gap is bigger than one hour
          this.oneHourGap = false;
          this.data[sensorInd]['dates']['measurement'].push(date);                         // add only measurement date
        }
      } else {                                                                            // add forecast dates
        this.data[sensorInd]['dates']['forecast'].push(date);
      }
    });
  }

  // set series visibility when visibility for one group is changed from the tree
  private setSeriesVisibility(visibility): void {
    const selectedSensor = this.sensors.find(sensor => sensor.channel === visibility.ch);
    const selectedSensorSeries = selectedSensor.series;
    const seriesLength = this.forecastLicense ? selectedSensorSeries.length : 2;  // consider only measurement series (no forecast)
    for (let i = selectedSensor.series[0]; i < selectedSensor.series[0] + seriesLength; i++ ) {
      this.setSerieVisibility(i, visibility.visible);
    }
  }

  // save sensors, set settings for every sensor
  private getSensors(): void {
    this.sensors = [];
    let serieIndex = 0;

    this.response.sensors.forEach((sensor, i) => {
      const thresholds = [];
      const smsThresholds = [];
      sensor.thresholds.forEach(alert => {
        alert.forEach((threshold, ind) => {
          thresholds.push({
            value: threshold,
            type: sensor.thresholds_type[ind]
          });
        });
      });
      sensor.sms.forEach((threshold, ind) => {
        smsThresholds.push({
          value: threshold,
          type: sensor.sms_type[ind]
        });
      });

      this.sensors[i] = {
        name: sensor.name_original,
        customName: sensor.name ? sensor.name : null,
        group: sensor.group,
        channel: sensor.ch,
        unit: sensor.unit,
        colors: {
          measurement: this.chartAttributes['group' + sensor.group].measurementColor,
          forecast: this.chartAttributes['group' + sensor.group].forecastColor,
        },
        series: sensor.group === 1 ?
          // HC Air temperature sensor (group 1) has 4 series (measurement: avg, range; forecast: forecast, pictocode)
          [serieIndex, serieIndex + 1, serieIndex + 2, serieIndex + 3] :
          // all others have 3 series (measurement: avg, range; forecast: forecast)
          [serieIndex, serieIndex + 1, serieIndex + 2],
        thresholds: thresholds,
        smsThresholds: smsThresholds
      };
      serieIndex = sensor.group === 1 ? serieIndex + 4 : serieIndex + 3;
    });

    // translate sensor names
    this.sensors.forEach((sensor, i) => {
      this.translateService.get(sensor.name)
        .subscribe(translatedSensorName => {
          this.sensors[i].translatedName = translatedSensorName;
        });
    });
  }

  private getResponseData(): void {
    this.lastMeasureTime = this.response.info.last_measure_date * 1000;
    this.nowTime = this.response.info.now * 1000;
    this.measurementRecordsLength = this.response.info.measure_records_length;
    this.forecastLicense = this.response.info.forecast_license;
  }

  // set chart options
  private setOptions(): void {
    this.charts.forEach((chart: Chart, index) => {
      this.chartsOptions[index] = chart.options;
      this.chartsOptions[index]['sources'] = ['forecast'];        // add a source to use it in 'pictocodeService'
    });

    this.chartsOptions = this.pictocodeService.setBiggerIcons(this.chartsOptions);
  }

  private createOptions(): void {
    const self = this;

    this.charts = chartZoomSync([
      new Chart({
        ...deepClone(DEFAULT_CHART_OPTIONS),
        chart: {
          height: 550,
          marginLeft: 85,
          marginRight: 100,
          marginTop: 20,
          style: { fontFamily: 'Helvetica' },
          zoomType: 'x',
          alignTicks: false,
          // add plotlines depend on user's thresholds
          events: {
            load: function(event): void {
              const chart = this;
              const plotlines = [];
              let dataMax = chart.yAxis[0].getExtremes().max;
              let dataMin = chart.yAxis[0].getExtremes().min;

              // for each sensor get the treshold and show plotlines
             self.sensors.forEach(sensor => {
               sensor.thresholds.forEach((threshold: IThreshold) => {
                // const arrow = threshold.type === 'min' ? `\u{1F87B}` : `\u{1F879}`;
                  plotlines.push(
                    chart.yAxis[0].addPlotLine({
                      value: threshold.value,
                      color: threshold.type === 'min' ? self.chartColors.thresholdBelow : self.chartColors.thresholdAbove,
                      width: 1.5,
                      label: {
                        text: self.translatedValues['notification'] + ' ' + threshold.value + ' ' +
                              (self.unitSystem === 'metric' ? '°C' : '°F'),
                        align: 'right',
                        x: 95,
                        y: 2,
                        style: {
                          fontSize: '9px'
                        }
                      }
                    })
                  );
                  // if thresholds are hidden because of yAxis min and max, calculate the new min and max values -
                  // ALWAYS show the thresholds
                  if (threshold.value < dataMin) {
                    dataMin = +threshold.value - 3;
                  } else if (threshold.value > dataMax) {
                    dataMax = +threshold.value + 3;
                  }
                });

                sensor.smsThresholds.forEach((threshold: IThreshold) => {
                  // const arrow = threshold.type === 'min' ? `\u{1F87B}` : `\u{1F879}`;
                  plotlines.push(
                    chart.yAxis[0].addPlotLine({
                      value: threshold.value,
                      color: threshold.type === 'min' ? self.chartColors.smsWarningBelow : self.chartColors.smsWarningAbove,
                      width: 1.5,
                      label: {
                        text: self.translatedValues['warningSms']  + ' ' + threshold.value + ' ' +
                              (self.unitSystem === 'metric' ? '°C' : '°F'),
                        align: 'right',
                        x: 95,
                        y: 2,
                        style: {
                          fontSize: '9px'
                        }
                      }
                    })
                  );

                  // if thresholds are hidden because of yAxis min and max, calculate the new min and max values -
                  // ALWAYS show the thresholds
                  if (threshold.value < dataMin) {
                    dataMin = +threshold.value - 3;
                  } else if (threshold.value > dataMax) {
                    dataMax = +threshold.value + 3;
                  }
                });
              });
              // set min and max yAxis values
              chart.yAxis[0].update({
                min: dataMin,
                max: dataMax
              });
            }
          }
        },
        plotOptions: {
          series: {
            states: {
              hover: {
                enabled: true,
                lineWidth: 3
              }
            },
            pointPlacement: 'on',
            marker: {
              enabled: false
            },
            events: {
              legendItemClick: (event: any) => {
                event.preventDefault();
                this.setVisibility(event.target);
              }
            }
          },
          arearange: {                                    // range temperature
            fillOpacity: 0.25,
            showInLegend: false,
            marker: {
              enabled: false
            }
          }
        },
        xAxis: {
          type: 'datetime',
          crosshair: true,
          gridLineWidth: 1,
          labels: {style: {fontSize: '12px'}},
          lineWidth: 2,
          showEmpty: false,
          plotLines: this.forecastLicense ? [{
            color: this.chartColors.plotline,
            value: this.lastMeasureTime,
            width: 2,
            dashStyle: 'longDash',
            zIndex: 10
          }, {
            color: this.chartColors.plotline,
            value: this.nowTime,
            width: 2,
            zIndex: 10
          }] :
          []
        },
        yAxis: [
          {
            title: {
              text: this.translatedValues['temperature'] + (this.unitSystem === 'metric' ? ' [°C]' : ' [°F]')
            },
            showEmpty: false,
            plotLines: []
          }
        ],
        tooltip: {
          useHTML: true,
          shared: true,
          formatter: function (): string | boolean {
            let str = '';
            const date = moment.utc(this.x).format('Y-MM-DD HH:mm');
            str += `<small>${date}</small><br>`;
            if (this.y) {                                         // show tooltip only if value
              // if last measure time and one hour gap, the series are duplicated because of connected forecast serie => filter them
              const series = this.x === self.lastMeasureTime && self.oneHourGap ? self.filterDoubleSeries(this.points) : this.points;

              series.forEach(point => {
                if (point.color !== 'transparent') {              // don't show pictocode (pictocode color is 'transparent')
                  let serieName = point.series.name;
                  const splittedSerieName = serieName.split(' ');
                  serieName = '';
                  for (let ind = 0; ind < splittedSerieName.length - 1; ind++) {
                    // if last word, don't add ' '
                    if (ind !== splittedSerieName.length - 2) {
                      serieName += splittedSerieName[ind] + ' ';
                    } else {
                      serieName += splittedSerieName[ind];
                    }
                  }
                  if (!point.point.options.high) {
                    str += `<li style='color:${point.color}'>`;                                          // bullet
                    str += `<span style='color:black; margin-left:-8px'>${serieName}: <b>${point.y} `;   // serie name and value
                    str += `${self.unitSystem === 'metric' ? ' °C' : ' °F'}</b></span></li>`;            // unit
                  } else if (point.point.options.high && point.point.options.high !== point.y) {         // if range
                    str += `<li style='color:${point.color}'>`;                                          // bullet
                    str += `<span style='color:black; margin-left:-8px'>
                    ${serieName} range: <b>${point.point.options.low} - ${point.point.options.high}`;   // serie name and value if range
                    str += `${self.unitSystem === 'metric' ? ' °C' : ' °F'}</b></span></li>`;           // unit
                  }
                }
              });
            } else {
              str = '';
            }
            return str !== '' ? str : false;                      // show tooltip if value, otherwise don't show it
          }
        },
        series: []
      }),
    ]);
  }

  // for the last measurement time filter series (they are duplicated because of connected forecast serie)
  private filterDoubleSeries(points: any): any {
    const serieIndexes = [];
    const filteredPoints = [];

    // add avg and range series from each sensor
    this.sensors.forEach(sensor => {
      serieIndexes.push(sensor.series[0]);        // avg serie
      serieIndexes.push(sensor.series[1]);        // range serie
    });
    serieIndexes.forEach(index => {
      filteredPoints.push(points[index]);
    });

    return filteredPoints;
  }

  // sync the series visibility with the tree
  private setVisibility(event): void {
    const eventName = event.name;
    const selectedSerie = this.charts[0].options.series.find(serie => serie.name === eventName);
    const visible = selectedSerie.visible !== undefined ? !selectedSerie.visible : false;
    let selectedSerieIndex = null;

    // enable or disable selected serie
    this.charts[0].options.series.forEach((serie, serieInd) => {
      if (serie.name === eventName) {
        selectedSerieIndex = serieInd;
        this.setSerieVisibility(selectedSerieIndex, visible);
      }
    });

    // 1. AVG and RANGE series have the same names => SOLVED
    // 2. NO FORECAST license => series are already HIDDEN from the options if no license => SOLVED
    // 3. FORECAST and PICTOCODE series are connected, hide or show them together => pictocode is only for group 1;
    // only if FORECAST license and group 1 or 8 (for group 9 forecast does not exist) AND if forecast series is chosen
    const selectedSerieGroup = this.sensors.find(sensor => sensor.series.includes(selectedSerieIndex)).group;
    const selectedSensorSeries = this.sensors.find(sensor => sensor.series.includes(selectedSerieIndex)).series;
    const selectedSensor = this.sensors.find(sensor => sensor.series.includes(selectedSerieIndex));
    const indexOfSelectedSensorSerie = selectedSensorSeries.indexOf(selectedSerieIndex);
    if (this.forecastLicense && (selectedSerieGroup === 1 && indexOfSelectedSensorSerie === 2)) {
      this.setSerieVisibility(selectedSerieIndex + 1, visible);
    }

    // sync with the tree
    const sensorSeries = [];
    const seriesLength = this.forecastLicense ? selectedSensorSeries.length : 2;  // consider only measurement series (no forecast)
    for (let i = selectedSensor.series[0]; i < selectedSensor.series[0] + seriesLength; i++ ) {
      sensorSeries.push(this.charts[0].options.series[i]);
    }

    const showSensor = sensorSeries.every(serie => serie.visible === true);     // if all sensor series enabled
    const hideSensor = sensorSeries.every(serie => serie.visible === false);    // if all sensor series disabled

    // save visibility for the tree
    if (showSensor) {
      this.tree.nodes.find(node => node.ch === selectedSensor.channel).visible = true;
    }
    if (hideSensor) {
      this.tree.nodes.find(node => node.ch === selectedSensor.channel).visible = false;
    }

    this.frostProtectionStore.dispatch(setFrostProtectionTreeNodes(this.tree.nodes));
  }

  private setSerieVisibility(index: number, visible: boolean): void {
    this.charts[0].options.series[index].visible = visible;
    if (this.charts[0].ref) {
      if (visible) {
        this.charts[0].ref.series[index].show();
      } else {
        this.charts[0].ref.series[index].hide();
      }
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.charts && this.exportChartImg) {
      const fileName = 'extreme_monitoring_charts_' + moment(new Date()).toISOString() + '.png';
      this.exportService.exportChartsToImage(fileName);
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
