import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsConfigurationComponent } from './notifications-configuration.component';
import { NotificationsConfigurationRoutingModule } from './notifications-configuration-routing.module';
import { reducers } from './reducers/index';
import { AlertsEffects } from './effects/alerts';
import { AlertsRowComponent } from './components/alerts-row/alerts-row.component';
import { CurrentAlertsComponent } from './components/current-alerts/current-alerts.component';
import { NewAlertsComponent } from './components/new-alerts/new-alerts.component';
import { NotificationsConfigurationHeaderComponent } from './components/notifications-configuration-header/notifications-configuration-header.component';
import { NotificationsConfigurationWarningComponent } from './components/notifications-configuration-warning/notifications-configuration-warning.component';
import {ServerAlertsModule} from '../server-alerts/server-alerts.module';

@NgModule({
    imports: [
        CommonModule,
        NotificationsConfigurationRoutingModule,
        SharedModule,
        StoreModule.forFeature('serverAlerts', reducers),
        EffectsModule.forFeature([AlertsEffects]),
        TranslateModule.forChild(),
        ServerAlertsModule
    ],
  declarations: [
    NotificationsConfigurationComponent,
    AlertsRowComponent,
    CurrentAlertsComponent,
    NewAlertsComponent,
    NotificationsConfigurationHeaderComponent,
    NotificationsConfigurationWarningComponent
  ]
})
export class NotificationsConfigurationModule { }
