<div>
  <p>
    {{ 'hourly/daily sum of soil moisture content given in mm.' | translate }}
  </p>
  <p>
    {{ 'SM SUM widget will show soil moisture content at root zone depth. The warning colors are based on Refill point and Field capacity inputs. Data display follows the cultivation period.' | translate }}
  </p>
  <p>
    {{ 'Green means actual content.' | translate }} <br>
    {{ 'Red means below Refill point.' | translate }} <br>
    {{ 'Blue means above Field capacity.' | translate }} <br>
    {{ 'Grey means moisture content needed to reach Field capacity.' | translate }}
  </p>
</div>
