import { IDisplayRulesConfig } from '../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../environments/interfaces/theme';

export const themeConfig: IThemeConfig = {
  subDomain: 'farmfacts',
  language: 'en',
  headerClass: 'white',
  src: '../../../assets/themes/farmfacts/assets/images/logo.png',
  width: '320',
  mainWidth: '243',
  mainMTop: '4',
  mainLeft: '-10',
  name: 'Farmfacts',
  profileMenuSrc: '../../../assets/themes/default/assets/images/profile.png',
  faviconSrc: '../../../assets/themes/default/assets/favicon.ico',
};

export const displayRulesConfig: IDisplayRulesConfig = {
  userMenu: true,
  farmViewMenu: true,
  leftBarMenu: {
    stationData: true,
    cropView: true,
    iScout: true,
    weatherForecast: true,
    workPlanning: true,
    animalProduction: true,
    accumulatorTool: true,
    frostProtection: true,
    diseaseModels: true,
    soilMoisture: true,
    hydroponics: true,
    tracker: true,
    soilGuard: false,
    settings: true,
  },
};
