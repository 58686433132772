import { INavigationStationState } from '../reducers/navigation-station';

export const defaultNavigation: INavigationStationState = {
  station: null,
  stationBasicInfo: {
    firmWare: '',
    timeZoneOffset: null,
    lastCommunication: null,
    maxDate: null,
    minDate: null,
    originalName: '',
    customName: '',
    deviceType: ''
  },
  navigationAccess: {
    cropView: null,
    iScout: null,
    stationData: null,
    weatherForecast: null,
    workPlanning: null,
    tropicalSowingWindow: null,
    grassFireDangerIndex: null,
    yieldPrediction: null,
    animalProduction: null,
    accumulatorTool: null,
    frostProtection: null,
    diseaseModels: null,
    soilMoisture: null,
    hydroponics: null,
    settings: null,
    tracker: null,
    soilGuard: null,
    dualex: null,
    mobilab: null,
    ultrasonicDistanceSettings: null,
    settingsConfiguration: {
      sensorsAndNodes: null,
      warnings: null,
      serverAlerts: null
    }
  },
  notFound: null
};
