import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { selectNavigationStation } from '../../core/reducers';
import { ISelectedStationState } from '../../core/reducers/selectedStation';
import { ITreeStructure } from '../../services/tree/models';
import { TreeService } from '../../services/tree/tree.service';
import { setProfile, setTreeSetting, TreeSettingsActionTypes } from '../../shared/tree/actions/tree-settings';
import { ITreeSettingsState } from '../../shared/tree/models/tree.models';
import { IView } from './models';
// import { ModalComponent } from '../../shared/modal/modals/modal';

import { getSoilMoistureSensors, getViews } from './actions';
import {
  treeSettings as treeSettingsSelector, views as viewsSelector
} from './selectors';
import { IState } from './states';

@Component({
  selector: 'app-soilmoisture',
  templateUrl: './soil-moisture.component.html',
  styleUrls: ['./soil-moisture.component.scss']
})
export class SoilMoistureComponent implements OnInit, OnDestroy {

  public tree$: Observable<ITreeStructure>;

  public stationId: string;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private isSoilMoistureLoaded: boolean = false;

  constructor(
    private store: Store<IState>,
    private selectedStationStore: Store<ISelectedStationState>,
    private treeSettingsStore: Store<ITreeSettingsState>,
    private treeService: TreeService
  ) {}

  public ngOnInit(): void {

    this.tree$ = this.treeService.getSoilMoistureTreeStructure().pipe(
      takeUntil(this.destroy$)
    );

    this.selectedStationStore
      .pipe(
        select(selectNavigationStation),
        filter((station): boolean => !!station && this.stationId !== station.name.original),
        tap(station => this.stationId = station.name.original),
        takeUntil(this.destroy$)
      )
      .subscribe(station => {
        this.store.dispatch(getViews({ stationId: station.name.original }));
        this.isSoilMoistureLoaded = false;
        this.store.dispatch(getSoilMoistureSensors(station.name.original));
      });

    combineLatest([
      this.store.pipe(
        select(viewsSelector),
        filter(d => !!d && !!d.length),
        map(views => views[0]),
        takeUntil(this.destroy$)
      ),
      this.treeSettingsStore.pipe(
        select(treeSettingsSelector),
        filter(d => !!d),
        takeUntil(this.destroy$)
      )
    ]).subscribe(([view, treeSettings]: [IView, ITreeSettingsState]) => {

      const soilSettings = JSON.parse(localStorage.getItem('soilMoisture'));
      const SelectedSoilSettings = soilSettings ? soilSettings[this.stationId] : null;
      const soilDisabled = treeSettings[this.stationId]?.profileSettings[treeSettings[this.stationId]?.selectedProfile]?.disabledGroupIds;
      if (soilSettings && SelectedSoilSettings && soilDisabled && Object.keys(soilDisabled).length === 0 &&
          (!view.station_id || view.station_id === this.stationId) && this.isSoilMoistureLoaded === false) {
          Object.keys(SelectedSoilSettings).map(item => {
            this.changeVisible(item, view._id || view.name);
          });
          this.isSoilMoistureLoaded = true;
      }
      if ((!view.station_id || view.station_id === this.stationId)
      && (!treeSettings[this.stationId] || !treeSettings[this.stationId]?.selectedProfile)) {
        this.treeSettingsStore.dispatch(setProfile(
          { stationId: this.stationId, profile: view._id || view.name },
          TreeSettingsActionTypes.SET_SOIL_MOISTURE_PROFILE
        ));
      }
    });
  }

  public changeVisible(groupID: string, profile: string): void {
    this.treeSettingsStore.dispatch(setTreeSetting({
      groupdId: groupID,
      profile: profile,
      stationId: this.stationId
    }, TreeSettingsActionTypes.SET_SOIL_MOISTURE_TREE_SETTINGS));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.treeService.getSoilMoistureTreeStructure().next(null);
  }


}
