import { IFrostProtectionState } from '../models/models';
import { initialState } from '../states/states';
import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { ActionTypes } from '../actions/actions';

export function frostProtectionReducer(state: IFrostProtectionState = initialState, action: IAction): IFrostProtectionState {
  switch (action.type) {
    case ActionTypes.SET_FROST_PROTECTION_TREE:
      return {
        ...state,
        tree: action.payload
      };
    case ActionTypes.SET_FROST_PROTECTION_TREE_NODES:
      return {
        ...state,
        tree: {
          ...state.tree,
          nodes: action.payload
        }
      };
    case ActionTypes.SET_FROST_PROTECTION_LOADING:
      return {
        ...state,
        dataLoading : {
          ...state.dataLoading,
          isLoading : action.payload
        }
      };
    case ActionTypes.SET_FROST_PROTECTION_ERROR:
      return {
        ...state,
        dataLoading : {
          ...state.dataLoading,
          error: {
            ...state.dataLoading.error,
            isError : action.payload
          }
        }
      };
    case ActionTypes.SET_FROST_PROTECTION_ERROR_MESSAGE:
      return {
        ...state,
        dataLoading : {
          ...state.dataLoading,
          error: {
            ...state.dataLoading.error,
            errorMessage : action.payload
          }
        }
      };
    case ActionTypes.SET_FROST_PROTECTION_PERIOD:
      return {
        ...state,
        settings : {
          ...state.settings,
          period: action.payload
        }
      };
    case ActionTypes.SET_FROST_PROTECTION_SETTINGS:
      return {
        ...state,
        settings: action.payload
      };
    case ActionTypes.SET_FROST_PROTECTION_RESPONSE:
      return {
        ...state,
        response: action.payload
      };
    case ActionTypes.SET_FROST_PROTECTION_CHART_ACTIVE:
      return {
        ...state,
        settings : {
          ...state.settings,
          activity: {
            ...state.settings.activity,
            isChartActive : action.payload
          }
        }
      };
    case ActionTypes.SET_FROST_PROTECTION_TABLE_ACTIVE:
      return {
        ...state,
        settings : {
          ...state.settings,
          activity: {
            ...state.settings.activity,
            isTableActive : action.payload
          }
        }
      };
    case ActionTypes.SET_FROST_PROTECTION_CHARTS:
      return {
        ...state,
        charts: action.payload
      };
    case ActionTypes.SET_FROST_PROTECTION_VISIBILITY:
      return {
        ...state,
        visibility: action.payload
      };
    default: {
      return state;
    }
  }
}
