import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IOptions } from '../../../../shared/interfaces';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';
import {
  setDwbTableActive,
  setWaterBalanceFromTo,
  setWaterBalanceIsLastDataMode,
  setWaterBalancePeriod
} from '../../actions/irrimet-water-balance';
import { PERIODS } from '../../constants/constants';
import { IIrrimetState } from '../../models/models';
import { waterBalanceSelector } from '../../selectors/irrimet-water-balance';


@Component({
  selector: 'app-irrimet-water-balance-toolbar',
  templateUrl: './irrimet-water-balance-toolbar.component.html',
  styleUrls: ['./irrimet-water-balance-toolbar.component.scss']
})
export class IrrimetWaterBalanceToolbarComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  public minDate: Date;
  @Input()
  public maxDate: Date;
  @Input()
  public cropzoneName: string;
  @Output()
  public periodChanged = new EventEmitter();

  private form: FormGroup;
  public periods: IOptions[] = PERIODS;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private fb: FormBuilder,
    private irrimetStore: Store<IIrrimetState>,
    private exportService: StationDataExportService
  ) {

  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      'period': [Infinity],
      'computedPeriod': [this.getMaxNoDays()],
      'from': [this.minDate],
      'to': [this.maxDate],
      'isLastDataMode': [true],
      'isTableActive': [true],
      'range': [{
        from: this.minDate,
        to: this.maxDate
      }]
    });

    this.irrimetStore.pipe(
      takeUntil(this.destroy$),
      select(waterBalanceSelector)
    ).subscribe(wb => {

      if (!moment(wb.fromTo.from).isSame(this.from.value)) {
        this.from.setValue(wb.fromTo.from || this.minDate, { emitEvent: false });
      }

      if (!moment(wb.fromTo.to).isSame(this.to.value)) {
        this.to.setValue(wb.fromTo.to || this.maxDate, { emitEvent: false });
      }

      this.period.setValue(wb.period, { emitEvent: false });
      this.computedPeriod.setValue(wb.period === Infinity ? this.getMaxNoDays() : wb.period);
      this.isLastDataMode.setValue(wb.isLastDataMode, { emitEvent: false });
    });

    this.isTableActive.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe((isActive: boolean) => {
      this.irrimetStore.dispatch(setDwbTableActive(isActive));
    });

    this.isLastDataMode.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(isLastDataMode => {
        this.irrimetStore.dispatch(setWaterBalanceIsLastDataMode(isLastDataMode));
        if (isLastDataMode) {
          this.from.setValue(new Date(this.range.value.from));
          this.to.setValue(new Date(this.range.value.to));
        }
      });

    this.period.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(period => {
        this.irrimetStore.dispatch(setWaterBalancePeriod(period));
        this.computedPeriod.setValue(period === Infinity ? this.getMaxNoDays() : period);
        this.periodChanged.emit();
      });

    combineLatest([
      this.from.valueChanges,
      this.to.valueChanges
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([from, to]: [Date, Date]) => {
      this.irrimetStore.dispatch(setWaterBalanceFromTo({ from, to }));
    });

    this.range.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(range => {
      this.from.setValue(new Date(range.from));
      this.to.setValue(new Date(range.to));
    });
  }

  private getMaxNoDays(): number {
    return Math.ceil((this.maxDate.getTime() - this.minDate.getTime()) / (24 * 60 * 60 * 1000));
  }

  public ngOnChanges(changes): void {
    if (this.form && (changes.minDate || changes.maxDate)) {
      if (this.period.value === Infinity) {
        this.computedPeriod.setValue(this.getMaxNoDays());
      }
      this.isLastDataMode.setValue(true);
      this.from.setValue(this.minDate);
      this.to.setValue(this.maxDate);
    }
  }

  public get period(): AbstractControl {
    return this.form.get('period');
  }

  public get computedPeriod(): AbstractControl {
    return this.form.get('computedPeriod');
  }

  public get from(): AbstractControl {
    return this.form.get('from');
  }

  public get to(): AbstractControl {
    return this.form.get('to');
  }

  public get isLastDataMode(): AbstractControl {
    return this.form.get('isLastDataMode');
  }

  public get range(): AbstractControl {
    return this.form.get('range');
  }

  public get isTableActive(): AbstractControl {
    return this.form.get('isTableActive');
  }

  public startExportXLS(): void {
    this.exportService.startExportXLS(this.cropzoneName);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
