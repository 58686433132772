import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { selectNavigationStation } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { MobilabColumns } from '../../../../shared/constants/sample-data.constant';
import { SampleDeviceService } from '../../../../shared/services/sample-device/sample-device.service';
import { deepClone } from '../../../../shared/utils/deepClone';
import { setActiveMeter, setActiveSample, setDevice } from '../../actions/station-sample.action';
import { MeterValues } from '../../constants/station-sample.constant';
import { ISampleDataPeriod, IStationSampleState } from '../../models/station-sample.model';
import { selectActiveMeter, selectActiveTitle, selectSamples } from '../../selectors/station-sample.selector';

@Component({
  selector: 'app-mobilab-data',
  templateUrl: './mobilab-data.component.html',
  styleUrls: ['./mobilab-data.component.css'],
  providers: [SampleDeviceService]
})
export class MobilabDataComponent implements OnInit, OnDestroy {

  public status = {
    isLoading: true,
    isError: false
  };

  public settings: ISampleDataPeriod;
  public isTableActive: boolean;
  public columnDefs;
  public data;
  public activeSample;
  public dates;
  public activeMeter;
  private alive$ = new Subject<void>();
  public interestValues = MeterValues['mobilab'];

  constructor(
    private stationSampleStore: Store<IStationSampleState>,
    private stationSampleService: SampleDeviceService,
    private navigationStationStore: Store<INavigationStationState>
  ) {

  }

  public ngOnInit(): void {
    this.navigationStationStore.pipe(
      select(selectNavigationStation),
      takeUntil(this.alive$),
      filter(_ => Boolean(_)),
      distinctUntilChanged((a, b) => a.name.original === b.name.original)
    ).subscribe(station => {

      this.dates = {
        from: moment(station.dates.min_date).toDate(),
        to: moment(station.dates.max_date).toDate()
      };

      this.stationSampleStore.dispatch(setDevice({
        name: station.name.original,
        type: 'mobilab',
        period: {
          from: moment(this.dates.to).subtract(30, 'day').toDate(),
          to: this.dates.to
        }
      }));

      this.stationSampleStore.dispatch(setActiveSample(null));
      this.stationSampleStore.dispatch(setActiveMeter('nitrate'));
      this.status.isLoading = false;
    });

    this.stationSampleStore.pipe(
      select(selectActiveMeter),
      takeUntil(this.alive$),
      filter(_ => Boolean(_)),
      distinctUntilChanged((a, b) => a === b)
    ).subscribe(_ => this.activeMeter = _);

    combineLatest([
      this.stationSampleStore.pipe(select(selectSamples)),
      this.stationSampleStore.pipe(select(selectActiveTitle))
    ])
      .pipe(
        takeUntil(this.alive$),
        filter(([a, _]) => Boolean(a)),
        map(([data, title]) => {
          const samples = this.stationSampleService.groupMobilabSamples(data);
          return [samples, title];
        })
      )
      .subscribe(([data, title]) => {

        this.stationSampleService.setTitleActive(title);
        let samples = [];

        if (data && Array.isArray(data) && data.length) {
          this.updateUnits(data[0]);

          samples = data.map(record => {
            const dt = new Date(Number(record.dt['$date']['$numberLong']));

            let longitude = null;
            let latitude = null;

            const marker = {
              url: this.stationSampleService.getMobilabMarker(record, this.activeMeter),
              scaledSize: {
                width: 32,
                height: 32
              }
            };

            if (record.isMixture) {
              record.sampleStatus = 'mixed';
            }

            if (record.position && record.position.geo) {
              [longitude, latitude] = record.position.geo.coordinates;

              record.latitude = latitude;
              record.longitude = longitude;
            } else if (
              record.boundary &&
              record.boundary.coordinates &&
              record.boundary.coordinates[0]
            ) {

              // Caroline requested always to display only the first measurement location
              [longitude, latitude] = record.boundary.coordinates[0][0];

              record.latitude = latitude;
              record.longitude = longitude;
            }

            return { ...record, latitude, longitude, dt, marker };
          });
        }

        this.data = samples;
      });
  }

  public updateUnits(mobilabSample): void {
    if (mobilabSample && mobilabSample.nitrate_unit) {
      this.stationSampleService.translate(deepClone(MobilabColumns))
        .subscribe(columns => {
          columns[1].headerName += ` [${mobilabSample.nitrate_unit}]`;
          columns[2].headerName += ` [${mobilabSample.sulfate_unit}]`;
          columns[3].headerName += ` [${mobilabSample.ammonium_unit}]`;
          columns[4].headerName += ` [${mobilabSample.chloride_unit}]`;
          columns[5].headerName += ` [${mobilabSample.potassium_unit}]`;
          columns[6].headerName += ` [${mobilabSample.sodium_unit}]`;
          columns[7].headerName += ` [${mobilabSample.calcium_unit}]`;
          columns[8].headerName += ` [${mobilabSample.magnesium_unit}]`;

          this.columnDefs = columns;
        });
    }
  }

  public ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }
}
