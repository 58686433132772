import { Component, forwardRef, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { CustomDropdownComponent } from '../custom-dropdown/custom-dropdown.component';
import { IOptions } from '../interfaces';
import { IOptionGroup } from '../multilevel-dropdown/models';

@Component({
  selector: 'app-multilevel-grouped-dropdown',
  templateUrl: './multilevel-grouped-dropdown.component.html',
  styleUrls: ['./multilevel-grouped-dropdown.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultilevelGroupedDropdownComponent),
    multi: true
  }]
})
export class MultilevelGroupedDropdownComponent extends CustomDropdownComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
  @Input()
  public groupedSet: Array<IOptionGroup>;

  public groupedSetFiltered: Array<IOptionGroup>;
  public form: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  public ngOnInit(): void {
    this.initForm();
    this.listenToSearch();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public ngOnChanges(changes): void {
    if (this.groupedSet) {
      this.options = this.groupedSet.reduce((g, item) => {
        return g.concat(item.options);
      }, []);
    }
    if (this.form) {
      this.filterOptionGroup(this.searchControl.value);
    } else {
      this.groupedSetFiltered = this.groupedSet;
    }
    this.setContent();
  }

  public get searchControl(): AbstractControl {
    return this.form.get('search');
  }

  public stopBubbling(event: Event): void {
    event.stopPropagation();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      'search': ['', []]
    });
  }

  private listenToSearch(): void {
    this.subscribeToStringControl(this.searchControl, (searchTerm: string): void => {
      this.filterOptionGroup(searchTerm);
    });
  }

  private filterOptionGroup(searchTerm: string): void {
    this.groupedSetFiltered = this.groupedSet
      .map((group: IOptionGroup) => {
        const options = group.options.filter((option: IOptions) =>
          searchTerm ? option.content.toLowerCase().includes(searchTerm.toLowerCase()) : true
        );
        return {...group, options: options || []};
      })
      .filter((group: IOptionGroup): boolean => group.options.length !== 0);
  }

  private subscribeToStringControl(control: AbstractControl, callback: (value: string) => void): void {
    control.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      debounceTime(200)
    ).subscribe(callback);
  }
}
