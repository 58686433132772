import {IStationCommunication} from '../models/station-communication.models';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {StationCommunicationActionTypes} from '../actions/station-communication';

export interface IStationCommunicationState {
  stationCommunication: ICommunicationByStation;
  isLoading: boolean;
  isError: boolean;
}

export interface ICommunicationByStation {
  [stationId: string]: {
    [modeName: string]: Array<IStationCommunication>;
  };
}

export const initStationCommunicationState: IStationCommunicationState = {
  stationCommunication: {},
  isLoading: true,
  isError: false
};

export function reducer(
  state: IStationCommunicationState = initStationCommunicationState,
  action: IActionWithPayload
): IStationCommunicationState {
  switch (action.type) {
    case StationCommunicationActionTypes.SET_STATION_COMMUNICATION:
      const stationCommunication: ICommunicationByStation = {
        ...state.stationCommunication,
        [action.payload.stationId]: {
          ...state.stationCommunication[action.payload.stationId] || {},
          [action.payload.mode]: action.payload.stationCommunication
        }
      };
      return {
        isLoading: state.isLoading,
        isError: state.isError,
        stationCommunication: stationCommunication
      };
    case StationCommunicationActionTypes.SET_STATION_COMMUNICATION_LOADING:
      return <IStationCommunicationState> {
        ...state,
        isLoading: action.payload,
      };
    case StationCommunicationActionTypes.SET_STATION_COMMUNICATION_ERROR:
      return <IStationCommunicationState> {
        ...state,
        isError: action.payload,
      };
    default:
      return state;
  }
}
