import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TypeaheadMatch } from '../../../../../../../node_modules/ngx-bootstrap';
import { Observable } from '../../../../../../../node_modules/rxjs';
import { REMOVE_CROPZONE } from '../../../../../shared/constants';
import { ModalService } from '../../../../../shared/modal/services/modal.service';
import { setNotify } from '../../../../actions/notify';
import { ICropZone, IFarm } from '../../../../models/cropzones';
import { IField } from '../../../../models/fields';
import { selectCropzones, selectFarms, selectFields } from '../../../../reducers';
import * as fromCropZones from '../../../../reducers/cropzones';
import * as fromFarms from '../../../../reducers/farms';
import * as fromFields from '../../../../reducers/fields';
import * as fromNotify from '../../../../reducers/notify';

@Component({
    selector: 'app-remove-cropzone-form',
    templateUrl: './remove-cropzone-form.component.html',
    styleUrls: ['./remove-cropzone-form.component.scss']
})
export class RemoveCropzoneFormComponent implements OnInit {
    public form : FormGroup;
    public farms$: Observable<IFarm[]>;
    public fields$: Observable<IField[]>;
    public cropzones$: Observable<ICropZone[]>;

    public selectedFarm: IFarm = {name: '', id: ''};
    public selectedField: IField = {name: '', id: '', farm: {name: '', id: ''}};
    public selectedCropzone: ICropZone;

    public farmList: IFarm[];
    public fieldList: IField[];
    public cropzoneList: ICropZone[];

    @Output()
    private submit: EventEmitter<ICropZone> = new EventEmitter<ICropZone>();

    constructor(
      private fb : FormBuilder,
      private modalService : ModalService,
      private farmStore: Store<fromFarms.IFarms>,
      private fieldStore: Store<fromFields.IFields>,
      private CZstore: Store<fromCropZones.ICropZones>,
      private notifyStore: Store<fromNotify.INotifyState>
    ) {
      this.farmStore.pipe(
        select(selectFarms)
      ).subscribe((farms) => this.farmList = farms);

      this.fieldStore.pipe(
        select(selectFields)
      ).subscribe((fields) => this.fieldList = fields);

      this.CZstore.pipe(
        select(selectCropzones)
      ).subscribe((cropzones) => this.cropzoneList = cropzones);
    }

    public get farm(): AbstractControl {
        return this.form.get('farm');
      }

    public get field(): AbstractControl {
        return this.form.get('field');
      }

    public get cropzone(): AbstractControl {
        return this.form.get('cropzone');
      }

    public get submitColor(): string {
        return 'red';
      }

    public get submitContent(): string {
        return 'Remove cropzone';
      }

    public ngOnInit(): void {
        this.form = this.fb.group({
            'farm': ['', [Validators.required]],
            'field': ['', [Validators.required]],
            'cropzone': ['', [Validators.required]]
          });

          this.farms$ = this.farmStore.pipe(
            select(selectFarms)
          );

          this.fields$ = this.fieldStore.pipe(
            select(selectFields)
          );

          this.cropzones$ = this.CZstore.pipe(
              select(selectCropzones)
          );

          this.onChanges();
    }

    public onChanges(): void {
        this.form.get('farm').valueChanges.subscribe(val => {
        this.getFieldsFromFarmName(val);
      });

        this.form.get('field').valueChanges.subscribe(val => {
            this.getCropzonesFromFieldName(val);
        });
    }

    public onSelect(event: TypeaheadMatch): void {
      this.fields$ = this.fieldStore.pipe(
        select(selectFields)
      );

      this.selectedFarm = event.item;

      this.getFieldsFromFarmId(this.selectedFarm.id);
    }

    private getCropzonesFromFieldName(name: string): void {
        this.cropzones$.pipe(
            map(cropzones => cropzones.filter(cropzone => cropzone.field.name === name))
        ).subscribe((cropzones) => this.cropzones$ = of(cropzones));
    }

    public getCropzonesFromFieldId(id: string): void {
        this.cropzones$.pipe(
            map(cropzones => cropzones.filter(cropzone => cropzone.field.id === id))
        ).subscribe((cropzones) => this.cropzones$ = of(cropzones));
    }

    private getFieldsFromFarmName(name: string): void {
      this.fields$.pipe(
        map(fields => fields.filter(field => field.farm.name === name))
      ).subscribe((fields) => this.fields$ = of(fields));
    }

    private getFieldsFromFarmId(id: string): void {

      this.fields$.pipe(
        map(fields => fields.filter(field => field.farm.id === id))
      ).subscribe((fields) => this.fields$ = of(fields));
    }

    public onSelectField(event: TypeaheadMatch): void {
      this.cropzones$ = this.CZstore.pipe(
          select(selectCropzones)
      );

      this.selectedField = event. item;

      this.getCropzonesFromFieldId(this.selectedField.id);
    }

    public onSelectCropzone(event: TypeaheadMatch): void {
        this.selectedCropzone = event.item;
    }

    public submitEmit(): void {
      if (this.farmList.some((farm) => farm.name.toLowerCase() === this.form.value.farm.toLowerCase())) {
        const fieldsFromFarm: IField[] = this.fieldList.filter((field) =>
        field.farm.name.toLowerCase() === this.form.value.farm.toLowerCase());

        if (fieldsFromFarm.some((field) => field.name.toLowerCase() === this.form.value.field.toLowerCase())) {
          const cropzonesFromField: ICropZone[] = this.cropzoneList.filter((cropzone) =>
          cropzone.field.name.toLowerCase() === this.form.value.field.toLowerCase());

          if (cropzonesFromField.some((cropzone) => cropzone.name.toLowerCase() === this.form.value.cropzone.toLowerCase())) {
            const cropzone: ICropZone = cropzonesFromField.filter((cropzonex) =>
            cropzonex.name.toLowerCase() === this.form.value.cropzone.toLowerCase())[0];

            this.submit.emit(cropzone);
          } else {
            this.notifyStore.dispatch(setNotify('Unable to remove cropzone. The selected field has no cropzone with this name.'));
          }
        } else {
          this.notifyStore.dispatch(setNotify('Unable to remove cropzone. The selected farm has no field with this name.'));
        }
      } else {
        this.notifyStore.dispatch(setNotify('Unable to remove cropzone. There is no farm with this name.'));
      }
        this.form.reset();
      }

      public close(): void {
        this.modalService.closeModal(REMOVE_CROPZONE);
      }
}
