<section class="widget-container">
  <app-chart-header [color]="widget.color"
                    [title]="widget.title"
                    [description]="widget.description"
                    [isEditable]="true"
                    [isDraggable]="isEdit$ | async"
                    [isClose]="isEdit$ | async"
                    (remove) = "removeCurrentWidget()"
                    (editMode)="editMode(!widget.settings.forecastTable)"></app-chart-header>

  <div class="settings" *ngIf="widget.settings.forecastTable">
    <app-forecast-table-settings [forecastRequest]="widget.requests.forecastTable"
                       (updateWidgetData)="loadTableData()"
                       (updateRequest)="updateRequest($event)"></app-forecast-table-settings>
  </div>

  <app-form-layout [size]="'dashboard'">
    <app-forecast-table [data]="widget.loadData"
                      *ngIf="!widget.error && !widget.isLoading"
                      [station]="widget.requests.forecastTable.endpoint.station"
                      [stationName]="widget.requests.forecastTable.endpoint.stationName"></app-forecast-table>

    <app-widget-load-info *ngIf="widget.error || widget.isLoading"
                          [error]="widget.error"
                          [loading]="widget.isLoading">
    </app-widget-load-info>
  </app-form-layout>
</section>
