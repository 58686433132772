import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cropzone-config-help-text-modal',
  templateUrl: './cropzone-config-help-text-modal.component.html',
  styleUrls: ['./cropzone-config-help-text-modal.component.scss']
})
export class CropzoneConfigHelpTextModalComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
