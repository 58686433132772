import * as io from 'socket.io-client';
import { environmentToken } from '../../../environments/environment';
import { IEnvironment } from '../../../environments/interfaces/environment';
import { EMPTY, fromEvent, Observable } from 'rxjs';
import { socketOptions } from '../../shared/constants';
import { AuthService } from '../auth/auth.service';
import { Inject, Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MainSocket {

  protected route;
  protected options = socketOptions;
  protected socket;

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    protected authService: AuthService
  ) {
    this.route = environment.socketUrl;
    this.socket = io(this.route, this.options);
  }

  /**
   * Joins a room in Sockets
   *
   * @return {void}
   */
  public listen(event: string): void {
    this.socket.emit(event);
  }

  /**
   * Emits an event bound to the Sockets controller
   *
   * @param {string} event
   * @param {string} message
   * @return {void}
   */
  public dispatch(event: string, message: string): void {
    this.socket.emit(event, message);
  }

  /**
   * Subscribes to events emitted in Sockets controller
   *
   * @param event
   * @return {Observable<T>}
   */
  public select<T>(event: string): Observable<T> {

    let response$: Observable<any>;

    if (this.socket === undefined) {
      response$ = EMPTY;
    } else {
      response$ = fromEvent(this.socket, event);
    }

    return response$;
  }

  /**
   * Subscribes to collection events (isolated in a namespace)
   *
   * @return {Observable<T>}
   */
  public monitor<T>(event): Observable<T> {

    let response$: Observable<any>;

    if (this.socket === undefined) {
      response$ = EMPTY;
    } else {
      response$ = fromEvent(this.socket, event);
    }

    return response$;
  }

  public authorize(): void {
    if (this.authService.hasAccessToken()) {
      this.socket.emit('user.login', this.authService.getAccessToken());
    }
  }

  public watch<T>(event: string): Observable<T> {
    return this.monitor(event).pipe(
      filter((data: string) => data && data.length > 11),
      map((data: string) => JSON.parse(data))
    );
  }
}
