<app-weather-forecast-layout>
  <ng-container *ngIf="(isError$ | async) && !(isLoading$ | async);">
    <app-weather-forecast-info [isError]="isError$ | async" [isLoading]="isLoading$ | async"></app-weather-forecast-info>
    <app-weather-forecast-load-info [isError]="isError$ | async" [isLoading]="isLoading$ | async">
    </app-weather-forecast-load-info>
  </ng-container>
  <app-weather-forecast-header></app-weather-forecast-header>
  <app-weather-forecast-toolbar (changeModeEmitter)="changeMode($event)" (exportChartImg)="exportCharts($event)"
    [currentMode]="mode"></app-weather-forecast-toolbar>
  <ng-container *ngIf="!(isLoading$ | async) && !(isError$ | async);">
    <div class="weather-forecast-content__item">
      <app-weather-forecast-charts [exportChartImg]="exportChartImg"></app-weather-forecast-charts>
    </div>
    <div class="weather-forecast-content__item">
      <app-weather-forecast-table></app-weather-forecast-table>
    </div>
  </ng-container>
</app-weather-forecast-layout>