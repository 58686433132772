import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { CropzoneManagementActionTypes } from '../actions/cropzoneManagement';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICropZone } from './../../../core/models/cropzones';
import { managementInitialState } from './../constants/index';

export interface ICropzoneManagementSort {
  sortingItem: string;
  sortingOrder: string;
}
export interface ICropzoneManagementState {
  itemToEdit: any;
  cropzoneManagementSort: ICropzoneManagementSort[];
  managementCropsLazyLoading: Array<any>;
  newCropzones: ICropZone[];
  config: IManagementConfig;
}

export interface IManagementConfig {
  licenseModalActive: boolean;
  duplicationModalActive: boolean;
  cropTypeModalActive: boolean;
}

const initialState: ICropzoneManagementState = managementInitialState;

export function reducer(state: ICropzoneManagementState = initialState, action: IActionWithPayload): ICropzoneManagementState {
  switch (action.type) {
    case CropzoneManagementActionTypes.UPDATE_MANAGEMENT_CONFIG: {
      return {
        ...state,
        config: action.payload
      };
    }
    case CropzoneManagementActionTypes.UPDATE_ITEM_TO_EDIT: {
      return {
        ...state,
        itemToEdit: action.payload
      };
    }
    case CropzoneManagementActionTypes.UPDATE_MANAGEMENT_SORT: {
      return {
        ...state,
        cropzoneManagementSort: JSON.parse(JSON.stringify(action.payload))
      };
    }
    case CropzoneManagementActionTypes.SET_MANAGEMENT_CROPS_LAZY_LOADING: {
      return {
        ...state,
        managementCropsLazyLoading: action.payload
      };
    }
    case CropzoneManagementActionTypes.SHOW_INFO_MODAL: {
      return {
        ...state,
        newCropzones: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

export const cropzoneManagement = createFeatureSelector<ICropzoneManagementState>('cropzoneManagement');

export const selectManagementConfig = createSelector(
  cropzoneManagement,
  (cropzoneManagementState: ICropzoneManagementState) => cropzoneManagementState.config
);

export const selectItemToEdit = createSelector(
  cropzoneManagement,
  (cropzoneManagementState: ICropzoneManagementState) => cropzoneManagementState.itemToEdit
);

export const selectManagementSort = createSelector(
  cropzoneManagement,
  (cropzoneManagementState: ICropzoneManagementState) => cropzoneManagementState.cropzoneManagementSort
);

export const selectManagementState = createSelector(
  cropzoneManagement,
  (cropzoneManagementState: ICropzoneManagementState) => cropzoneManagementState
);

export const selectNewCropzones = createSelector(
  cropzoneManagement,
  (cropzoneManagementState: ICropzoneManagementState) => cropzoneManagementState.newCropzones
);

export const selectManagementCrops = createSelector(
  cropzoneManagement,
  (cropzoneManagementState: ICropzoneManagementState) => cropzoneManagementState.managementCropsLazyLoading
);
