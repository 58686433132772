import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import * as fromNavigationStation from '../../../../core/reducers/navigation-station';
import { INotifyState } from '../../../../core/reducers/notify';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { IOptions } from '../../../../shared/interfaces';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';
import { ITreeSettingsState } from '../../../../shared/tree/models/tree.models';
import { dateToUtcUnixTimestamp } from '../../../../shared/utils/dateFormat';
import { getChart, setChartActive, setSensorSettingsActive, setSettings, setTableActive, setViewEditorActive } from '../../actions';
import { DefaultPeriodScope, PeriodValuesByScope, ViewTemplateType } from '../../constants';
import { IGetChartRequest, IView } from '../../models';
import { activitySettings, settings as settingsSelector, soilMoistureSensors, treeSettings, views as viewSelector } from '../../selectors';
import * as SoilMoistureStates from '../../states';

@Component({
  selector: 'app-soil-moisture-toolbar',
  templateUrl: './soil-moisture-toolbar.component.html',
  styleUrls: ['./soil-moisture-toolbar.component.scss']
})
export class SoilMoistureToolbarComponent implements OnInit, OnDestroy {
  @Output()
  private exportChartImg:         EventEmitter<boolean> = new EventEmitter<boolean>();
  public station:                 IStation;
  public fromStation:             Date = null;
  public toStation:               Date = null;
  public periodScopeSelectItems:  Array<IOptions> = Object.keys(PeriodValuesByScope).map((item: string) => ({
    content: item,
    value: item
  }));

  public periodValueSelectItems:  Array<IOptions> = PeriodValuesByScope[DefaultPeriodScope];
  public periodForm:              FormGroup;
  public hasVolumetricSensors$:   Observable<boolean>;
  public hideTime:                 boolean = false;

  private profile:                string = SoilMoistureStates.defaultPredefinedViews[0].name;
  private destroy$:               Subject<boolean> = new Subject<boolean>();
  private isDatepickerRequest:    boolean = false;
  private views:                  IView[] = [];
  private selectedView:           IView = null;

  constructor(
    private formBuilder: FormBuilder,
    private exportService: StationDataExportService,
    private api: ApiCallService,
    private navigationStore: Store<fromNavigationStation.INavigationStationState>,
    private dataStore: Store<SoilMoistureStates.IStateData>,
    private settingsStore: Store<SoilMoistureStates.IStateSettings>,
    private treeStore: Store<ITreeSettingsState>,
    private notifyStore: Store<INotifyState>,
  ) { }

  private get period(): AbstractControl {
    return this.periodForm.get('period');
  }

  private get activity(): AbstractControl {
    return this.periodForm.get('activity');
  }

  public get fromDatepicker(): AbstractControl {
    return this.period.get('fromDatepicker');
  }

  public get toDatepicker(): AbstractControl {
    return this.period.get('toDatepicker');
  }

  public get isViewEditorActive(): AbstractControl {
    return this.activity.get('isViewEditorActive');
  }

  public get isTableActive(): AbstractControl {
    return this.activity.get('isTableActive');
  }

  public get isChartActive(): AbstractControl {
    return this.activity.get('isChartActive');
  }

  public get isLastDataMode(): AbstractControl {
    return this.period.get('isLastDataMode');
  }

  public get periodScope(): AbstractControl {
    return this.period.get('periodScope');
  }

  public get periodValue(): AbstractControl {
    return this.period.get('periodValue');
  }

  public get fromTo(): AbstractControl {
    return this.period.get('fromTo');
  }

  public get stationId(): AbstractControl {
    return this.period.get('stationId');
  }

  public get isExportImageActive(): AbstractControl {
    return this.activity.get('isExportImageActive');
  }

  public get isExportActive(): AbstractControl {
    return this.activity.get('isExportActive');
  }

  public get isSensorSettingsActive(): AbstractControl {
    return this.activity.get('isSensorSettingsActive');
  }

  public startExportXLS(): void {
    this.exportService.startExportXLS(this.stationId.value);
  }

  public startExportImage(): void {

    if (this.isExportImageActive.value) {
      return;
    }

    this.isExportImageActive.setValue(true);
    this.exportChartImg.emit(true);
    setTimeout(() => {
      this.exportChartImg.emit(false);
      this.isExportImageActive.setValue(false);
    }, 500);
  }

  public ngOnInit(): void {

    this.periodForm = this.formBuilder.group({
      'period': this.formBuilder.group({
        'periodScope': ['hourly', [Validators.required]],
        'periodValue': ['30d', [Validators.required]],
        'fromTo': [null, [Validators.required]],
        'fromDatepicker': [null, [Validators.required]],
        'toDatepicker': [null, [Validators.required]],
        'stationId': ['', [Validators.required]],
        'isLastDataMode': [true, [Validators.required]]
      }),
      'activity': this.formBuilder.group({
        'isChartActive': [true, [Validators.required]],
        'isTableActive': [true, [Validators.required]],
        'isViewEditorActive': [false, [Validators.required]],
        'isExportImageActive': [false, [Validators.required]],
        'isExportActive': [false, [Validators.required]],
        'isSensorSettingsActive': [false, [Validators.required]]
      })
    });

    this.settingsStore.pipe(
      select(settingsSelector),
      take(1)
    ).subscribe((settings: SoilMoistureStates.IStateSettings) => {
      this.periodForm.setValue({
        period: settings.period,
        activity: settings.activity
      });
    });

    this.subscribeToTreeAndSelectedStation();

    this.hasVolumetricSensors$ = this.dataStore.pipe(
      select(soilMoistureSensors),
      map(sensors => sensors.length > 0)
    );

    this.periodScope.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe((scope: string) => {
        this.periodValueSelectItems = PeriodValuesByScope[scope];
        setTimeout(() => this.periodValue.setValue(this.periodValueSelectItems[0].value), 0);
        this.removeTime();
      });

    this.isViewEditorActive.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(10),
      takeUntil(this.destroy$)
    ).subscribe((isActive: boolean) => {
      this.settingsStore.dispatch(setViewEditorActive(isActive));
    });

    this.isSensorSettingsActive.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(10),
      takeUntil(this.destroy$)
    ).subscribe((isActive: boolean) => {
      this.settingsStore.dispatch(setSensorSettingsActive(isActive));
    });

    this.isChartActive.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(10),
      takeUntil(this.destroy$)
    ).subscribe((isActive: boolean) => {
      this.settingsStore.dispatch(setChartActive(isActive));
    });

    this.settingsStore.pipe(
      select(activitySettings, 'isExportActive'),
      takeUntil(this.destroy$)
    ).subscribe((isActive: boolean) => {
      this.isExportActive.setValue(isActive);
    });

    this.isTableActive.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(10),
      takeUntil(this.destroy$)
    ).subscribe((isActive: boolean) => {
      this.settingsStore.dispatch(setTableActive(isActive));
    });

  }

  public setDateRange(): void {
    if (this.isDatepickerRequest && !this.isLastDataMode.value) {
      this.fromTo.setValue({
        from: this.fromDatepicker.value,
        to: this.toDatepicker.value
      });
    }
  }

  public refresh(): void {

    if (this.periodForm.invalid) {
      return;
    }

    this.isDatepickerRequest = !this.isLastDataMode.value;
    this.settingsStore.dispatch(setSettings(this.periodForm.value));
    this.dataStore.dispatch(getChart(this.buildRequest()));

  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private buildRequest(): IGetChartRequest {

    const view = this.views.find(v => {
      return (v._id && v._id === this.profile) || v.name === this.profile;
    });
    const template = view ? view.template : ViewTemplateType.SOIL_MOISTURE_ALL;
    const customViewId = view && view._id ? view._id : undefined;

    const request = {
      from: null,
      to: null,
      stationId: this.stationId.value,
      timePeriod: this.periodScope.value,
      payload: {
        template,
        customViewId
      }
    };
    if (this.isLastDataMode.value) {
      request.from = this.setTime(this.fromTo.value.from);
      request.to = this.setTime(this.fromTo.value.to);
    } else {
      request.from = this.setTime(this.fromDatepicker.value);
      request.to = this.setTime(this.toDatepicker.value);
    }

    return request;

  }

  private subscribeToTreeAndSelectedStation(): void {
    combineLatest([
      this.getSelectedStationObservable(),
      this.getTreeSettingsObservable(),
      this.dataStore.pipe(select(viewSelector), filter(d => !!d && !!d.length))
    ]).pipe(filter(([station, settings, views]) => !!settings[station.name.original]))
      .subscribe(([station, tree, views]) => {
        const selectedProfile = tree[station.name.original].selectedProfile;
        this.views = views;
        let profileWasChanged = false;
        if (!this.selectedView) {
          this.selectedView = this.views.find(v => {
            return (v._id && v._id === selectedProfile) || v.name === selectedProfile;
          });
        }
        const view = this.views.find(v => {
          return (v._id && v._id === selectedProfile) || v.name === selectedProfile;
        });
        profileWasChanged = JSON.stringify(this.selectedView) !== JSON.stringify(view || {});
        this.profile = selectedProfile;
        this.selectedView = view || this.selectedView;

        if (!this.selectedView) {
          return;
        }

        this.fromStation = moment(station.dates.min_date).toDate();
        this.toStation = moment(station.dates.max_date).toDate();
        if (station.name.original === this.stationId.value && !profileWasChanged) {
          return;
        }
        this.stationId.setValue(station.name.original);

        setTimeout(() => {
          if (!this.station || station.name.original !== this.station.name.original) {
            this.fromDatepicker.setValue(moment(this.toStation).subtract(1, 'day').toDate());
            this.toDatepicker.setValue(moment(this.toStation).toDate());
            this.fromTo.setValue({
              from: moment(this.toStation).subtract(2, 'day').toDate(),
              to: this.toStation
            });
          }
          this.refresh();
          this.station = station;
        });
      });
  }

  private getSelectedStationObservable(): Observable<IStation> {
    return this.navigationStore.pipe(
      select(selectNavigationStation),
      filter((s: IStation) => !!s),
      takeUntil(this.destroy$)
    );
  }

  private getTreeSettingsObservable(): Observable<ITreeSettingsState> {
    return this.treeStore.pipe(
      select(treeSettings),
      takeUntil(this.destroy$)
    );
  }

  private removeTime(): void {
    if (['monthly', 'daily'].includes(this.periodScope.value)) {
      this.hideTime = true;
    } else {
      this.hideTime = false;
    }
  }

 private setTime(date: Date): any {
    if (this.hideTime) {
      return dateToUtcUnixTimestamp(new Date(new Date(date).setHours(0, 0, 0, 0)));
    } else {
      return dateToUtcUnixTimestamp(date);
    }
  }
}
