import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Column, ColumnGroup, ExcelExportParams, GridOptions } from 'ag-grid';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { DataGridOptions, ExcelExportSettings } from '../../../../shared/constants';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';
import {
  DEFAULT_WEATHER_FORECAST_MODE,
  IWeatherForecastSettingsState,
  IWeatherForecastState
} from '../../models/models';
import { selectWeatherForecastGrid, selectWeatherForecastSettings, selectWeatherForecastSettingsIsTableActive } from '../../reducers';

@Component({
  selector: 'app-weather-forecast-table',
  templateUrl: './weather-forecast-table.component.html',
  styleUrls: ['./weather-forecast-table.component.scss']
})
export class WeatherForecastTableComponent implements OnInit {
  public forecastGrid       : any = {};
  public isTableActive$     : Observable<boolean>;
  public dataGridOptions    : GridOptions = {
    ...DataGridOptions,
    enableFilter: true
  };

  private mode              : string = DEFAULT_WEATHER_FORECAST_MODE.value;
  private destroy$          : Subject<boolean> = new Subject<boolean>();
  private disabledGroupIds                 : object = {};

  constructor(private weatherForecastSettingsStore: Store<IWeatherForecastSettingsState>,
              private weatherForecastStore: Store<IWeatherForecastState>,
              private navigationStationStore: Store<INavigationStationState>,
              private exportService: StationDataExportService) { }

  public ngOnInit(): void {
    this.isTableActive$ = this.weatherForecastSettingsStore.pipe(
      select(selectWeatherForecastSettingsIsTableActive)
    );
    this.listenStationChange();
    this.listenWeatherForecastStore();
    this.listenExporting();
  }

  private listenStationChange(): void {
    this.navigationStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((selectedStation: IStation) => !!selectedStation),
      switchMap((selectedStation: IStation) => this.getWeatherForecastSettingsObservable(selectedStation))
    ).subscribe((disabledGroupIds: object) => {
      this.disabledGroupIds = disabledGroupIds;
      setTimeout(() => {
        this.toggleColumns();
      }, 0);
    });
  }

  private getWeatherForecastSettingsObservable(selectedStation: IStation): Observable<object> {
    return this.weatherForecastSettingsStore.pipe(
      takeUntil(this.destroy$),
      select(selectWeatherForecastSettings),
      filter((weatherForecastSettings: IWeatherForecastSettingsState): boolean => {
        return !!weatherForecastSettings && !!weatherForecastSettings.settings[selectedStation.name.original];
      }),
      tap((weatherForecastSettings: IWeatherForecastSettingsState): void => {
        this.mode = weatherForecastSettings.settings[selectedStation.name.original].selectedMode;
      }),
      map((weatherForecastSettings: IWeatherForecastSettingsState): object => {
        return weatherForecastSettings.settings[selectedStation.name.original].modes[this.mode].disabledGroupIds;
      })
    );
  }

  private listenWeatherForecastStore(): void {
    this.weatherForecastStore.pipe(
      takeUntil(this.destroy$),
      select(selectWeatherForecastGrid),
      filter((grid): boolean => !!grid)
    ).subscribe((grid): void => {
        this.forecastGrid = grid;
        this.initTable(grid);
    });
  }

  private toggleColumns(): void {
    if (!this.dataGridOptions.api) {
      return;
    }
    this.dataGridOptions.columnApi.getAllColumns().forEach(
      (c: Column) => {
        const isInvisible: string = Object.keys(this.disabledGroupIds).find((t) => c.getId().startsWith(t));
        if (!isInvisible && !c.isVisible()) {
          this.dataGridOptions.columnApi.setColumnVisible(c.getId(), true);
        }
      }
    );

    Object.keys(this.disabledGroupIds).forEach((key: string) => {
      const gr: ColumnGroup = this.dataGridOptions.columnApi.getColumnGroup(key);
      if (gr) {
        gr.getOriginalColumnGroup().getChildren().forEach((column) => {
          if (column.isVisible()) {
            this.dataGridOptions.columnApi.setColumnVisible(column.getId(), false);
          }
        });
      } else {
        this.dataGridOptions.columnApi.setColumnVisible(key, false);
      }
    });
  }

  private initTable(data: any): void {
    setTimeout(() => {
      if (data.headers && this.dataGridOptions.api) {
        this.forecastGrid.headers[0].unSortIcon = true;
        this.forecastGrid.headers = this.forecastGrid.headers.map((header: any) => {
          return {
            ...header,
            filter: 'agTextColumnFilter',
            menuTabs: ['filterMenuTab'],
            showTabs: false
          };
        });
        this.dataGridOptions.api.setSortModel({ colId: data.headers[0].field, sort: data.headers[0].sort });
        this.dataGridOptions.api.setColumnDefs(this.forecastGrid.headers);
        this.dataGridOptions.api.setRowData(this.forecastGrid.data);
        this.toggleColumns();
      } else if (this.dataGridOptions.api) {
        this.dataGridOptions.api.setColumnDefs(null);
        this.dataGridOptions.api.setRowData(null);
      }
    }, 0);
  }

  private listenExporting(): void {
    this.exportService.getExportXLS().pipe(
      takeUntil(this.destroy$)
    ).subscribe((id: string) => {
      this.exportXLS(id);
    });
  }

  private exportXLS(id: string): void {
    const params: ExcelExportParams = {
      ...ExcelExportSettings,
      fileName: `${id}_weather_forecast`,
    };

    this.dataGridOptions.api.exportDataAsExcel(params);
  }
}
