import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, take, takeUntil } from 'rxjs/operators';
import { setNotify } from '../../../../../core/actions/notify';
import {
  DefaultPeriodScope,
  IScoutCameraPeriodMonitoringScopes,
  IScoutGlueBoardPeriodScope,
  IScoutSeasonPeriodScope
} from '../../../../../core/constants/camera';
import { IActionWithPayload } from '../../../../../core/models/actionWithPayload';
import { INotifyState } from '../../../../../core/reducers/notify';
import { IFromTo } from '../../../../../shared/camera/models/camera';
import { IOptions } from '../../../../../shared/interfaces';
import { getIscoutGlueBoards } from '../../../../iscout/actions/iscout-glue-boards';
import { getIscoutSeasons } from '../../../../iscout/actions/iscout-seasons';
import { IIscoutGlueBoard, IIscoutSeason, IIscoutState } from '../../../../iscout/models/iscout.models';
import {
  selectIscoutFirstDate,
  selectIscoutGlueBoardList,
  selectIscoutLastDate,
  selectIscoutSeasonList
} from '../../../../iscout/reducers';
import {
  setCameraDataIsChartActive,
  setCameraDataIsTableActive,
  setCameraGlueBoardDataFromIscout,
  setCameraSeasonDataFromIscout
} from '../../../actions/camera-data';
import { ICameraDataSettings, ICameraDataState } from '../../../models/camera-data';
import { selectCameraDataCurrentDateString, selectCameraDataSettings } from '../../../reducers';

@Component({
  selector: 'app-camera-data-toolbar-iscout',
  templateUrl: './camera-data-toolbar-iscout.component.html',
  styleUrls: ['./camera-data-toolbar-iscout.component.scss']
})
export class CameraDataToolbarIscoutComponent implements OnInit, OnDestroy {
  @Input()
  public stationId: string;

  @Output()
  public refresh: EventEmitter<string | IFromTo> = new EventEmitter<string | IFromTo>();
  @Output()
  public exportChart: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public exportTable: EventEmitter<void> = new EventEmitter<void>();

  public stationMinDate$: Observable<moment.Moment>;
  public stationMaxDate$: Observable<moment.Moment>;
  public iscoutGlueBoardFilter$: Observable<IOptions[]>;
  public iscoutSeasonFilter$: Observable<IOptions[]>;

  public form: FormGroup;
  public rangeArrowMin: moment.Moment = moment();
  public readonly periodOptions: Array<IOptions> = IScoutCameraPeriodMonitoringScopes;

  private currentDate$: Observable<string | IFromTo>;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private formBuilder: FormBuilder,
              private iscoutStore: Store<IIscoutState>,
              private cameraDataStore: Store<ICameraDataState>,
              private notifyStore: Store<INotifyState>) {
  }

  public ngOnInit(): void {
    this.createForm();
    this.populateForm();

    this.initGlueBoardFilter();
    this.initSeasonFilter();

    this.initCurrentDateChange();
    this.initFirstAndLastDates();
    this.initStatusListeners();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      'settings': this.formBuilder.group({
        'isLastDataMode': [true, [Validators.required]],
        'periodScope': [DefaultPeriodScope.value, [Validators.required]],
        'fromTo': [null, [Validators.required]],
        'datepicker': [null, [Validators.required]],
        'datepickerStart': [''],
        'datepickerEnd': [''],
      }),
      'activity': this.formBuilder.group({
        'isHelpActive': [false],
        'isChartActive': [true],
        'isTableActive': [true],
      })
    });
  }

  private populateForm(): void {
    this.cameraDataStore.pipe(
      take(1),
      select(selectCameraDataSettings),
      filter((settings: ICameraDataSettings): boolean => !!settings)
    ).subscribe((settings: ICameraDataSettings): void => {
      this.settingsControl.setValue({...settings, periodScope: IScoutGlueBoardPeriodScope.value});
    });
  }

  private initGlueBoardFilter(): void {
    this.iscoutStore.dispatch(getIscoutGlueBoards(this.stationId));

    this.iscoutGlueBoardFilter$ = this.iscoutStore.pipe(
      select(selectIscoutGlueBoardList),
      map((glueBoards: IIscoutGlueBoard[]): IOptions[] => glueBoards.map(
        (glueBoard) => ({
          value: this.formatGlueBoardInterval(glueBoard),
          content: glueBoard._id,
          data: glueBoard
        })
      ))
    );
  }

  private formatGlueBoardInterval(glueBoard: IIscoutGlueBoard): string {
    const lastDate = glueBoard.to || new Date();
    return moment(glueBoard.from).format('YYYY-MM-DD') + ' - ' + moment(lastDate).format('YYYY-MM-DD');
  }

  private initSeasonFilter(): void {
    this.iscoutStore.dispatch(getIscoutSeasons(this.stationId));

    this.iscoutSeasonFilter$ = this.iscoutStore.pipe(
      select(selectIscoutSeasonList),
      map((seasons: IIscoutSeason[]): IOptions[] => seasons.map(
        (season) => ({value: season.label, content: season._id, data: season})
      ))
    );
  }

  private initFirstAndLastDates(): void {
    this.stationMinDate$ = this.iscoutStore.pipe(
      select(selectIscoutFirstDate),
      filter((date: moment.Moment): boolean => !!date)
    );

    this.stationMaxDate$ = this.iscoutStore.pipe(
      select(selectIscoutLastDate),
      filter((date: moment.Moment): boolean => !!date)
    );

    this.stationMaxDate$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((date: moment.Moment): void => {
      this.fromToControl.setValue({
        from: null,
        to: date
      });
    });

    combineLatest([
      this.currentDate$,
      this.stationMinDate$
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe((result: Array<(string | IFromTo) | moment.Moment>): void => {
      const currentDate = <string | IFromTo>result[0];
      let periodUnit;
      if (typeof currentDate === 'string') {
        periodUnit = currentDate.includes('W') ? 'week' : 'month';
      } else {
        const start = moment(currentDate.from);
        const end = moment(currentDate.to);
        periodUnit = start.diff(end, 'days') > 7 ? 'month' : 'week';
      }
      const stationMinDate: moment.Moment = <moment.Moment>result[1];
      this.rangeArrowMin = stationMinDate.clone().subtract(1, periodUnit);
    });
  }

  private initCurrentDateChange(): void {
    this.currentDate$ = this.cameraDataStore.pipe(
      select(selectCameraDataCurrentDateString),
      filter((currentDate: string | IFromTo): boolean => !!currentDate)
    );

    this.currentDate$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((currentDate: string | IFromTo): void => {
      if (typeof currentDate === 'string') {
        const date = moment(currentDate);
        this.datepickerEndControl.setValue(date);
        this.datepickerStartControl.setValue(date.subtract(1, 'week'));
      } else {
        this.datepickerEndControl.setValue(new Date(currentDate.to * 1000));
        this.datepickerStartControl.setValue(new Date(currentDate.from * 1000));
      }
    });
  }

  private initStatusListeners(): void {
    this.listenStatusChangeAndDispatch(this.isChartActiveControl, setCameraDataIsChartActive);
    this.listenStatusChangeAndDispatch(this.isTableActiveControl, setCameraDataIsTableActive);
  }

  private listenStatusChangeAndDispatch(control: AbstractControl, actionCallback: (status: boolean) => IActionWithPayload): void {
    control.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(200),
    ).subscribe((status: boolean): void => {
      this.cameraDataStore.dispatch(actionCallback(status));
    });
  }

  public onExportChart(): void {
    this.exportChart.emit();
  }

  public onExportTable(): void {
    this.exportTable.emit();
  }

  public onRefresh(): void {
    this.refresh.emit(this.currentDateString);
  }

  private get settingsControl(): AbstractControl {
    return this.form.get('settings');
  }

  public get isLastDataModeControl(): AbstractControl {
    return this.settingsControl.get('isLastDataMode');
  }

  public get periodScopeControl(): AbstractControl {
    return this.settingsControl.get('periodScope');
  }

  public get fromToControl(): AbstractControl {
    return this.settingsControl.get('fromTo');
  }

  public get datepickerStartControl(): AbstractControl {
    return this.settingsControl.get('datepickerStart');
  }

  public get datepickerEndControl(): AbstractControl {
    return this.settingsControl.get('datepickerEnd');
  }

  private get activityControl(): AbstractControl {
    return this.form.get('activity');
  }

  public get isHelpActiveControl(): AbstractControl {
    return this.activityControl.get('isHelpActive');
  }

  public get isChartActiveControl(): AbstractControl {
    return this.activityControl.get('isChartActive');
  }

  public get isTableActiveControl(): AbstractControl {
    return this.activityControl.get('isTableActive');
  }

  public resetSelectedOption(): void {
    this.fromToControl.reset('');
  }

  private get currentDateString(): string | IFromTo {
    return this.isLastDataModeControl.value
      ? this.currentDateStringFromLastDataMode
      : this.currentDateStringFromSelectedMode;
  }

  private get currentDateStringFromLastDataMode(): IFromTo {
    let startDate: string | Date = new Date();
    let endDate: string | Date = new Date();

    if (this.isGlueBoardFilter()) {
      const selectedGlueBoard: IIscoutGlueBoard = this.fromToControl.value.data;

      if (!selectedGlueBoard) {
        this.notifyStore.dispatch(setNotify('Must select a glue board to filter.'));
        return;
      }

      startDate = selectedGlueBoard.from;
      endDate = selectedGlueBoard.to || new Date();
      this.cameraDataStore.dispatch(setCameraSeasonDataFromIscout(null));
      this.cameraDataStore.dispatch(setCameraGlueBoardDataFromIscout(selectedGlueBoard));
    }

    if (this.isSeasonFilter()) {
      const selectedSeason: IIscoutSeason = this.fromToControl.value.data;

      if (!selectedSeason) {
        this.notifyStore.dispatch(setNotify('Must select a season to filter.'));
        return;
      }

      startDate = selectedSeason.detections[0].from;
      endDate = selectedSeason.detections.slice().pop().to || new Date();
      this.cameraDataStore.dispatch(setCameraSeasonDataFromIscout(selectedSeason));
      this.cameraDataStore.dispatch(setCameraGlueBoardDataFromIscout(null));
    }

    return {
      from: moment(startDate).valueOf() / 1000,
      to: moment(endDate).valueOf() / 1000
    };
  }

  private get currentDateStringFromSelectedMode(): IFromTo {
    return {
      from: moment(this.datepickerStartControl.value).valueOf() / 1000,
      to: moment(this.datepickerEndControl.value).valueOf() / 1000
    };
  }

  public isGlueBoardFilter(): boolean {
    return this.periodScopeControl.value === IScoutGlueBoardPeriodScope.value;
  }

  public isSeasonFilter(): boolean {
    return this.periodScopeControl.value === IScoutSeasonPeriodScope.value;
  }

  public getDate(momentObject: moment.Moment): Date {
    return new Date(momentObject ? momentObject.toString() : null);
  }
}
