import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IFcResponse, IGetStationDataFcRequest, IGetStationDataProfilesRequest, IStationDataProfile, IStationDataWarningMessage} from '../models/station-data.models';

export const enum StationDataActionTypes {
  GET_STATION_DATA_PROFILES = '[StationData] GET_PROFILES',
  SET_STATION_DATA_PROFILES = '[StationData] SET_PROFILES',
  GET_STATION_DATA_FC = '[StationData] GET_FC',
  SET_STATION_DATA_FC = '[StationData] SET_FC',
  SET_STATION_DATA_ERROR = '[StationData] SET_STATION_DATA_ERROR',
  SET_STATION_DATA_LOADING = '[StationData] SET_STATION_DATA_LOADING',
  GET_STATION_DATA_LICENSES = '[StationData] GET_STATION_DATA_LICENSES',
  SET_STATION_DATA_LICENSES = '[StationData] SET_STATION_DATA_LICENSES',
  SET_STATION_DATA_WARNING = '[StationData] SET_STATION_DATA_WARNING'
}

export function getStationDataProfiles(request: IGetStationDataProfilesRequest): IActionWithPayload {
  return { type: StationDataActionTypes.GET_STATION_DATA_PROFILES, payload: request };
}

export function setStationDataProfiles(profiles: Array<IStationDataProfile>): IActionWithPayload {
  return { type: StationDataActionTypes.SET_STATION_DATA_PROFILES, payload: profiles };
}

export function getStationDataFc(request: IGetStationDataFcRequest): IActionWithPayload {
  return {type: StationDataActionTypes.GET_STATION_DATA_FC, payload: request};
}

export function setStationDataFc(response: IFcResponse): IActionWithPayload {
  return {type: StationDataActionTypes.SET_STATION_DATA_FC, payload: response};
}

export function setStationDataLoading(isLoading: boolean): IActionWithPayload {
  return {type: StationDataActionTypes.SET_STATION_DATA_LOADING, payload: isLoading};
}

export function setStationDataError(isError: boolean): IActionWithPayload {
  return {type: StationDataActionTypes.SET_STATION_DATA_ERROR, payload: isError};
}

export function getStationDataLicenses(stationId: string): IActionWithPayload {
  return {type: StationDataActionTypes.GET_STATION_DATA_LICENSES, payload: stationId};
}

export function setStationDataLicenses(licenses: any): IActionWithPayload {
  return {type: StationDataActionTypes.SET_STATION_DATA_LICENSES, payload: licenses};
}

export function setStationDataWarningMessage(warning: IStationDataWarningMessage): IActionWithPayload {
  return {type: StationDataActionTypes.SET_STATION_DATA_WARNING, payload: warning};
}
