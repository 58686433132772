import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StationDeleteWeatherDataComponent} from './station-delete-weather-data.component';
import {SharedModule} from '../../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {StationDeleteWeatherDataRoutingModule} from './station-delete-weather-data-routing.module';

@NgModule({
  imports: [
    CommonModule,
    StationDeleteWeatherDataRoutingModule,
    SharedModule,
    TranslateModule.forChild()
  ],
  declarations: [StationDeleteWeatherDataComponent]
})
export class StationDeleteWeatherDataModule { }
