import { Actions, Effect, ofType } from '@ngrx/effects';
import { ApiCallService } from '../../../services/api/api-call.service';
import { Observable, of } from 'rxjs';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import {
  setForecastWarningMessage,
  setStationWeatherForecastLicenses,
  setWeatherForecast,
  setWeatherForecastError,
  setWeatherForecastLoading,
  WeatherForecastActionTypes
} from '../actions/weather-forecast';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { from } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGetWeatherForecastImageRequest, IImageResponse, IWeatherForecastState } from '../models/models';
import {
  WeatherForecastSettingsActionTypes,
  setWeatherForecastImageExportInProgress,
} from '../actions/weather-forecast-settings';
import { StationDataExportService } from '../../../shared/services/export/station-data-export.service';
import {
  setWeatherForecastImage,
  setWeatherForecastImageError,
  setWeatherForecastImageLoading,
  WeatherForecastImageActionTypes
} from '../actions/weather-forecast-image';
import { PictocodeService } from '../../../services/pictocode/pictocode.service';
import { Store } from '@ngrx/store';

@Injectable()
export class WeatherForecastService {
  constructor(
    private api               : ApiCallService,
    private actions$          : Actions,
    private exportService     : StationDataExportService,
    private weatherForecastStore: Store<IWeatherForecastState>,
    private pictocodeService  : PictocodeService
  ) { }

  @Effect()
  public getWeatherForecast$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(WeatherForecastActionTypes.GET_WEATHER_FORECAST),
    switchMap((action: IActionWithPayload) => {
      setWeatherForecastError(false);
      return this.handleApiCall(action);
    })
  );

  @Effect()
  public getWeatherForecastChart$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(WeatherForecastSettingsActionTypes.GET_WEATHER_FORECAST_CHART),
    switchMap((action: IActionWithPayload) => this.api.exportWeatherForecastImage(action.payload).pipe(
      switchMap((apiResponse: IImageResponse) => {
        this.exportService.exportImage(apiResponse);
        return from([
          setWeatherForecastImageExportInProgress(false)
        ]);
      }),
      catchError(() => from([
        setWeatherForecastImageExportInProgress(false)
      ]))
    ))
  );

  @Effect()
  public getWeatherForecastImage$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(WeatherForecastImageActionTypes.GET_WEATHER_FORECAST_IMAGE),
    switchMap((action: IActionWithPayload) => {
      const request: IGetWeatherForecastImageRequest = <IGetWeatherForecastImageRequest>{...action.payload, endpointType: 'hourly'};
      return this.api.getWeatherForecastImage(request).pipe(
        switchMap((apiResponse: IImageResponse) => from([
          setWeatherForecastImageLoading(false),
          setWeatherForecastImageError(apiResponse[0] && apiResponse[0].slice(-4) === 'json' ? true : false),
          setWeatherForecastImage(apiResponse[0])
        ])),
        catchError(() => from([
          setWeatherForecastImageLoading(true),
          setWeatherForecastImageError(true),
        ]))
      );
    })
  );

  @Effect()
  public getStationForecastLicenses$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(WeatherForecastActionTypes.GET_STATION_WEATHER_FORECAST_LICENSES),
    tap(() => {
      this.weatherForecastStore.dispatch(setStationWeatherForecastLicenses([]));
      this.weatherForecastStore.dispatch(setForecastWarningMessage({message: null, icon: false}));
    }),
    switchMap((action: IActionWithPayload) => this.api.getStationLicenses(action.payload).pipe(
      switchMap((res: any) => from([
        setStationWeatherForecastLicenses(res),
      ])),
      catchError(() => from([]))
      )
    )
  );

  private handleApiCall(action: IActionWithPayload): Observable<any> {
    return this.api.getWeatherForecastDetails(action.payload).pipe(
      switchMap((apiResponse: IWeatherForecastState) => {
        this.pictocodeService.addTooltip(apiResponse.chartsOptions);
        this.pictocodeService.setBiggerIcons(apiResponse.chartsOptions);

        return (apiResponse.chartsOptions.length)
          ? from([
            setWeatherForecastLoading(false),
            setWeatherForecastError(false),
            setWeatherForecast({...apiResponse, mode: action.payload.mode}),
          ])
          : this.unsuccessfulApiCall();
      }),
      catchError(() => this.unsuccessfulApiCall())
    );
  }

  private unsuccessfulApiCall(): Observable<IActionWithPayload> {
    return from([
      setWeatherForecastError(true),
      setWeatherForecastLoading(false)
    ]);
  }
}
