import { Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { environmentToken } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../../../environments/interfaces/theme';
import { addStation, removeStation } from '../../../actions/stations';
import { IAddRemoveStation } from '../../../models/stations';
import * as fromStations from '../../../reducers/stations';

@Component({
  selector: 'app-add-remove-station',
  templateUrl: './add-remove-station.component.html',
  styleUrls: ['./add-remove-station.component.scss']
})
export class AddRemoveStationComponent {
  public isAddStation     : boolean = true;
  public subDomain        : IThemeConfig;
  public portalName       : string = 'iMetos';

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    private store         : Store<fromStations.IStations>
  ) {
    this.subDomain = environment.theme;

    if (this.subDomain.hasOwnProperty('portalName')) {
      this.portalName = this.subDomain.portalName;
    }
  }

  public addStation(event: IAddRemoveStation): void {
    this.store.dispatch(addStation(event));
  }

  public removeStation(event: IAddRemoveStation): void {
    this.store.dispatch(removeStation(event));
  }
}
