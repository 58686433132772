<app-form-layout [shadow]="false" *ngIf="form">
    <div class="irrimet-config">
        <div class="irrimet-config_header">
            <h2>{{ 'Settings' | translate }} <i (click)="openModal()" class="zmdi zmdi-help help-icon"></i></h2>
        </div>
        <div class="irrimet-config_content" [formGroup]="form">
            <div class="row">
                <div class="col-md-6 noPaddingLeft">
                    <h5 class="irrimet-config__label">{{ 'Irrimet crop type' | translate }}</h5>
                    <app-custom-simple-lazy-dropdown
                      (load)="onLoadCrops()"
                      [customCSS2]="{'max-width': '100%', 'width':'100%'}"
                      [customCSS]="{'max-width': '100%', 'width':'100%', 'border': '1px solid #909090'}"
                      [formControl]="form.controls['cropType']"
                      [items$]="items$"
                      [shadow]="false"
                      [width]="width"
                      color="white">
                    </app-custom-simple-lazy-dropdown>
                </div>
                <div class="col-md-6">
                    <h5 class="irrimet-config__label" *ngIf="unitSystem === 'metric'">
                        {{ 'Rainfall efficacy' | translate }} (&lt;5mm)</h5>
                    <h5 class="irrimet-config__label" *ngIf="unitSystem === 'imperial'">
                        {{ 'Rainfall efficacy' | translate }} (&lt;0.2 inch)</h5>
                    <input type="number"
                           step="0.01"
                           min="0"
                           max="1"
                           class="irrimet-config__input numberInput"
                           formControlName="rainEfficacy1">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 noPaddingLeft">
                    <h5 class="irrimet-config__label">{{ 'Data source for rain' | translate }}</h5>
                    <app-custom-simple-lazy-dropdown
                      [customCSS2]="{'max-width': '100%', 'width':'100%'}"
                      [shadow]="false"
                      [customCSS]="{'max-width': '100%', 'width':'100%', 'border': '1px solid #909090'}"
                      color="white"
                      [formControl]="dataSourceRain"
                      [items$]="datasourcesRain$"
                      (load)="onLoadRain()">
                    </app-custom-simple-lazy-dropdown>
                </div>
                <div class="col-md-6">
                    <h5 class="irrimet-config__label" *ngIf="unitSystem === 'metric'">
                        {{ 'Rainfall efficacy' | translate }} (5mm - 10mm)</h5>
                    <h5 class="irrimet-config__label" *ngIf="unitSystem === 'imperial'">
                        {{ 'Rainfall efficacy' | translate }} (0.2 inch - 0.4 inch)</h5>
                    <input type="number"
                           step="0.01"
                           min="0"
                           max="1"
                           class="irrimet-config__input numberInput"
                           formControlName="rainEfficacy2">
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 noPaddingLeft">
                    <h5 class="irrimet-config__label">{{ 'Data source for ET0' | translate }}</h5>
                    <app-custom-simple-lazy-dropdown
                      [customCSS2]="{'max-width': '100%', 'width':'100%'}"
                      [shadow]="false"
                      [customCSS]="{'max-width': '100%', 'width':'100%', 'border': '1px solid #909090'}"
                      width="100%"
                      color="white"
                      [formControl]="dataSourceET0"
                      [items$]="datasourcesETO$"
                      (load)="onLoadETO()">
                    </app-custom-simple-lazy-dropdown>
                </div>
                <div class="col-md-6">
                    <h5 class="irrimet-config__label" *ngIf="unitSystem === 'metric'">
                        {{ 'Rainfall efficacy' | translate }} (&gt;10mm)</h5>
                    <h5 class="irrimet-config__label" *ngIf="unitSystem === 'imperial'">
                        {{ 'Rainfall efficacy' | translate }} (&gt;0.4 inch)</h5>
                    <input type="number" step="0.01" min="0" max="1"
                        class="irrimet-config__input numberInput" formControlName="rainEfficacy3">
                </div>
            </div>
            <div class="row marginB15">
              <div class="col-md-6 noPaddingLeft">
                <h5 class="irrimet-config__label">{{ 'Station distance' | translate }}</h5>
                <app-custom-simple-lazy-dropdown
                  [customCSS2]="{'max-width': '100%', 'width':' 100%', 'height': '100px'}"
                  [shadow]="false"
                  [customCSS]="{'max-width': '100%', 'width': '100%', 'border': '1px solid #909090'}"
                  color="white"
                  [formControl]="form.controls['stationDistance']"
                  [items$]="distanceItems$"
                  [currentItem]="selectedDistance">
                </app-custom-simple-lazy-dropdown>
              </div>
            </div>

            <div class="row" *ngIf="selectedCrop && selectedCrop !== null">
                <i (click)="openModalChart()" class="zmdi zmdi-help help-icon2"></i>
            </div>

            <app-irrimet-config-chart
              *ngIf="selectedCrop && selectedCrop !== null && unitSystem"
              [selectedCropType]="selectedCrop"
              [unitSystem]="unitSystem"
              (dataKcR)="checkKcR($event)">
            </app-irrimet-config-chart>

            <div class="row">
                <div class="col-md-12 noPaddingLeft section irrimet-config__button">
                    <app-custom-button (click)="save()" content="{{ 'Save' | translate }}" [color]="'red'" [disabled]="form.invalid">
                    </app-custom-button>
                </div>
            </div>
        </div>
    </div>
</app-form-layout>

<app-info-modal [modalId]="modalId"
                  [header]="'Irrimet settings'">
                  <app-irrimet-config-form-help-text-modal></app-irrimet-config-form-help-text-modal>
</app-info-modal>

<app-info-modal [modalId]="modalIdChart"
                [header]="'Irrimet graphic'">
                <app-irrimet-config-chart-help-text-modal></app-irrimet-config-chart-help-text-modal>
</app-info-modal>
