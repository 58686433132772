<ng-container *ngFor="let type of types; let i = index">
  <ng-container [ngSwitch]=i>
    <p class="accumulator-tool-charts__title" *ngSwitchCase="0">
      {{ '1. Model: VERNALIZATION' | translate }}
    </p>

    <div *ngSwitchCase="1">
    <p class="accumulator-tool-charts__title">
      {{ '2. Model: HARVESTING Condition' | translate }}
    </p>
      <p class="accumulator-tool-charts__title accumulator-tool-charts__subtitle">
        {{ 'a) START of growth of asparagus' | translate }}
      </p>
    </div>

    <div *ngSwitchCase="2">
    <p class="accumulator-tool-charts__title">
      {{ '2. Model: HARVESTING Condition' | translate }}
    </p>
      <p class="accumulator-tool-charts__title accumulator-tool-charts__subtitle">
        {{ 'b) LONG term conditions' | translate }}
      </p>
    </div>

    <div *ngSwitchCase="3">
    <p class="accumulator-tool-charts__title">
      {{ '2. Model: HARVESTING Condition' | translate }}
    </p>
      <p class="accumulator-tool-charts__title accumulator-tool-charts__subtitle">
        {{ 'c) SHORT term decision' | translate }}
      </p>
    </div>

    <p class="accumulator-tool-charts__title" *ngSwitchCase="4">
      {{ '3. Model: QUALITY' | translate }}
    </p>

    <p class="accumulator-tool-charts__title" *ngSwitchCase="5">
      {{ '4. Model: CRITICAL (Holow Stem)' | translate }}
    </p>
  </ng-container>

  <app-accumulator-tool-chart class="accumulator-tool-charts__item"
                              [chartOptions]="chartOptions[i]"
                              [chartData]="chartData"
                              [type]="types[i]"
                              (hiddenSeries)="hiddenSeries($event)">
  </app-accumulator-tool-chart>

  <app-form-layout [shadow]="false">
    <div *ngIf="noData"
         class="accumulator-tool-charts__no-data">
      <app-station-load-info
        [isError]=true
        [errorText]="'No data'">
      </app-station-load-info>
    </div>
  </app-form-layout>
</ng-container>




