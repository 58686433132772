import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ITranslatableContainer} from './translation.interfaces';

@Injectable()
export class ObjectTranslator {
  private translations;
  private translatables: ITranslatableContainer[] = [];

  constructor(private translationService: TranslateService) {
    this.translationService.getTranslation(this.translationService.currentLang)
      .subscribe(translations => {
        this.translations = translations;
      });
    this.translationService.onLangChange.subscribe(change => {
      for (const containerId in this.translatables) {
        if (this.translatables.hasOwnProperty(containerId)) {
          this.translateContainer(this.translatables[containerId], true);
        }
      }
    });
  }

  public translate(referenced: object, isTranslatedContainer?: boolean): void {
    const newContainer: ITranslatableContainer = {
      referenced: referenced,
      original: {...referenced}
    };
    if (!isTranslatedContainer) {
      this.translatables.push(newContainer);
    }
    this.translateContainer(newContainer);
  }

  private translateContainer(container: ITranslatableContainer, isTranslatedContainer?: boolean): void {
    for (const propertyName of Object.keys(container.original)) {
      if (container.original.hasOwnProperty(propertyName)) {
        const propertyValue = container.original[propertyName];
        if (typeof propertyValue === 'string' && propertyValue) {
          this.translationService.get(propertyValue).subscribe(translatedProperty => {
            container.referenced[propertyName] = translatedProperty;
          });
        }
        if (!isTranslatedContainer && typeof propertyValue === 'object') {
          this.translate(propertyValue);
        }
      }
    }
  }
}
