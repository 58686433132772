<div class="work-planning-toolbar">
    <div class="work-planning-toolbar__right">

        <div class="work-planning-toolbar__toggle
                    work-planning-toolbar__right__item
                    work-planning-toolbar__export">
            <app-custom-button [shadow]="false" appTooltip (click)="startExportImage()" [tooltipPlacement]="'top'"
                [tooltipTitle]="'Export charts as image' | translate" [color]="'white'">
                <i *ngIf="!isExportChart; else imgExport" class="glyphicon glyphicon-download-alt"></i>
                <ng-template #imgExport>
                    <i class="fa fa-cog fa-spin fa-fw"></i>
                </ng-template>
            </app-custom-button>
            <app-custom-button [shadow]="false" (click)="startExportXLS()" appTooltip [tooltipPlacement]="'top'"
                [tooltipTitle]="'Export table as xls' | translate" [color]="'white'">
                <i class="fa fa-table"></i>
            </app-custom-button>
        </div>

        <div class="work-planning-toolbar__toggle work-planning-toolbar__right__item">
            <app-custom-button [color]="isChartActiveControl.value ? 'green' : 'white'" appTooltip
                [tooltipPlacement]="'top'" [tooltipTitle]="'Toggle charts' | translate" [shadow]="false"
                (click)="isChartActiveControl.setValue(!isChartActiveControl.value)" [radius]="'2px 0 0 2px'"
                [content]="''">
                <span class="fa fa-bar-chart-o"></span>
            </app-custom-button>
            <app-custom-button [color]="isTableActiveControl.value ? 'green' : 'white'" appTooltip
                [tooltipPlacement]="'top'" [tooltipTitle]="'Toggle table' | translate" [shadow]="false"
                (click)="isTableActiveControl.setValue(!isTableActiveControl.value)" [radius]="'0 2px 2px 0'"
                [content]="''">
                <span class="fa fa-table"></span>
            </app-custom-button>
        </div>

    </div>
</div>