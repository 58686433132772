import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { selectUserData } from '../../../../core/reducers';
import { IAccount } from '../../../../core/reducers/account';
import { defaultDirections } from '../../constants/constants';

@Component({
  selector: 'app-wind-rose-diagram-chart',
  templateUrl: './wind-rose-diagram-chart.component.html',
  styleUrls: ['./wind-rose-diagram-chart.component.scss']
})
export class WindRoseDiagramChartComponent implements OnInit, OnDestroy {
  @Input()
  public chartsSettings: any;
  public chart: Chart[] = [];
  public chartOptions: Options[] = [];
  public windDataJSON: any = [];
  public windRoseJSON: any;
  public directionNames: any = defaultDirections;
  private alive$: Subject<boolean> = new Subject<boolean>();
  public unitSystem: string = '';
  public actualUnit: string = '';

  constructor() { }

  public ngOnInit(): void {
    this.windRoseJSON = this.chartsSettings?.loadData;
    const temp_legends = [];
    if (this.windRoseJSON?.legend?.length) {
      for (let i = 0; i < (this.windRoseJSON?.legend?.length - 2); i++) {
        temp_legends.push(`${Number(this.windRoseJSON.legend[i]).toFixed(2)} - ${Number(this.windRoseJSON.legend[i + 1]).toFixed(2)}`);
      }
      temp_legends.push(` > ${Number(this.windRoseJSON.legend[this.windRoseJSON.legend.length - 2]).toFixed(2)}`);
    }

    this.actualUnit = this.windRoseJSON['units'][0].unit;
    const temp_table = [];
    const temp_tables = this.windRoseJSON.table;
    if (this.windRoseJSON?.legend?.length) {
      for (let i = 0; i < temp_tables.length; i++) {
        const iter = [];
        temp_tables[i].map((item, index) => {
          iter.push(Number(item.toFixed(2)));
        });
        temp_table.push(iter);
      }
    }
    this.windRoseJSON['legend'] = temp_legends;
    this.windRoseJSON['table'] = temp_table;
    this.createWindrose();
  }

  public createWindrose(): void {
    const seriesData = [];
    this.windRoseJSON['legend'].map((item1, index1) => {
      seriesData.push({
        data: this.directionNames.map((item2, index2) => {
          return [item2, this.windRoseJSON['table'][index1][index2]];
        }),
        type: 'column',
        name: item1
      });
    });
    this.chartOptions[1] = {
      chart: {
        polar: true,
        type: 'column'
      },

      title: {
        text: ''
      },

      pane: {
        size: '85%'
      },

      legend: {
        title: {
          text: 'Wind Speed (' + this.actualUnit + ')',
        },
        align: 'right',
        verticalAlign: 'top',
        y: 100,
        layout: 'vertical'
      },

      xAxis: {
        tickmarkPlacement: 'on',
        type: 'category'
      },

      yAxis: {
        min: 0,
        endOnTick: false,
        showLastLabel: true,
        reversedStacks: false,
        title: {
          text: 'Frequency'
        },
        labels: {
          format: '{value}'
        }
      },
      series: seriesData,
      plotOptions: {
        series: {
          stacking: 'normal',
          shadow: false,
          pointPlacement: 'on'
        }
      }
    };

    this.chart.push(new Chart(this.chartOptions[1]));
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }
}
