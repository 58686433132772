import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Options } from 'highcharts';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { ISettings } from '../../../../core/models/account';
import { selectSelectedStation, selectSettings } from '../../../../core/reducers';
import * as fromAccount from '../../../../core/reducers/account';
import * as fromSelectedStation from '../../../../core/reducers/selectedStation';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { IOptions } from '../../../../shared/interfaces';
import { deepClone } from '../../../../shared/utils/deepClone';
import * as fromStationSettings from '../../../station-data/reducers/station-data-settings';
import { CHART_OPTIONS } from '../../constants/constants';
import {
  IAccumulationAsparagusRequest,
  IAccumulationCalculationValues,
  IAccumulatorToolState,
  ISensorChannel,
  ISensorSets
} from '../../interfaces/interfaces';
import { selectStationSensors } from '../../selectors/selectors';
import { ISelectedSearchWidgetItem } from '../../../../core/models/selectedSearchWidgetItem';

@Component({
  selector: 'app-accumulator-tool-asparagus-growth-model',
  templateUrl: './accumulator-tool-asparagus-growth-model.component.html',
  styleUrls: ['./accumulator-tool-asparagus-growth-model.component.scss']
})
export class AccumulatorToolAsparagusGrowthModelComponent implements OnInit, OnDestroy {

  private stationId                : string = '';
  private alive                   : boolean = true;
  private destroy$                : Subject<boolean> = new Subject<boolean>();

  public form                     : FormGroup;
  public sensorsOptions           : Array<IOptions> = [];
  public sensorSets               : Array<ISensorSets>;
  public unitSystem               : string = '';
  public isLoading                : boolean = false;
  public response                 : any = null;
  public chartData                : any;
  public chartOptions             : Array<Options>;
  public noData                   : boolean = false;
  public noSensors                : boolean = false;
  public translatedValues         : IAccumulationCalculationValues;
  public isActive$                : Observable<boolean>;

  constructor(
    private fb                    : FormBuilder,
    private api                   : ApiCallService,
    private selectedStationStore  : Store<fromSelectedStation.ISelectedStationState>,
    private stationSettingsStore  : Store<fromStationSettings.IStationDataSettingsState>,
    private accountStore          : Store<fromAccount.IAccount>,
    private accumulatorToolStore  : Store<IAccumulatorToolState>,
    private route                 : ActivatedRoute,
  ) {
    this.selectedStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectSelectedStation)
    ).subscribe((station: ISelectedSearchWidgetItem) => {
      if (station.original_name !== this.stationId) {
        this.stationId = station.original_name;
      }
    });
  }

  public ngOnInit(): void {
    this.translatedValues = this.route.snapshot.data['accumulatorToolResolver'];

    this.form = this.fb.group({
      'sensor': ['', [Validators.required]],
      'from': [moment().startOf('year').toDate(), Validators.required],
      'to': [moment().endOf('day').toDate(), Validators.required]
    });

    this.accountStore.pipe(
      takeWhile(() => this.alive),
      select(selectSettings),
      filter((settings: ISettings) => !!settings)
    ).subscribe((settings: ISettings) => {
      this.unitSystem = settings.unit_system;
    });

    this.accumulatorToolStore.pipe(
      takeWhile(() => this.alive),
      select(selectStationSensors),
      map((sensors: Array<any>) => sensors.filter((s: any) => s.code === 17667 || s.code === 17668)),
      tap ((sensors: Array<any>) => {
        if (sensors.length === 0) {
          this.noSensors = true;
        }
        this.sensorSets = [];
        this.sensorsOptions = [];
        let j = 1;                                              // number of set
        let channels: Array<ISensorChannel> = [];
        sensors.forEach((sensor, i) => {
          channels.push({ch: sensor.ch, name: sensor.name});
          if (i % 5 === 4) {                                    // last sensor in set
            this.sensorSets.push({
              sets: [{
                set: j,
                channels: channels,
                value:
                  `${j}. ${this.translatedValues['set']} - ${this.translatedValues['airSoilTemp']} [${channels[0].ch} - ${channels[4].ch}]`
              }]
            });
            this.sensorsOptions.push({
              value: j,
              content:
                `${j}. ${this.translatedValues['set']} - ${this.translatedValues['airSoilTemp']} [${channels[0].ch} - ${channels[4].ch}]`
            });
            j++;
            channels = [];
          }
        });
      }),
      map((sensors: Array<any>) => sensors.map((s: any) => {
        return {
          value: s.ch,
          content: `${s.name} [${s.ch}]`
        };
      })),
      filter((options: Array<IOptions>) => !!options.length)
    ) .subscribe(() => {
      this.sensor.setValue(this.sensorsOptions[0].value);
      this.response = null;
      this.chartData = null;
      this.noData = true;
      this.noSensors = false;
      this.chartOptions = null;
    });
  }

  public calculateTempAccumulation(): void {
    this.isLoading = true;
    this.response = null;
    this.chartData = null;
    this.noData = true;
    this.chartOptions = null;

    const startDateUTC: Date = moment(moment(this.from.value).format('YYYY-MM-DDTHH:mm') + 'Z').toDate();
    const endDateUTC: Date = moment(moment(this.to.value).format('YYYY-MM-DDTHH:mm') + 'Z').toDate();
    const startDate = moment(startDateUTC).format('X');
    const endDate = moment(endDateUTC).format('X');

    const indexOfChosenSet = (+this.sensor.value) - 1;
    const reqBody: IAccumulationAsparagusRequest = {
      type : 'asparagus',
      ch0: this.sensorSets[indexOfChosenSet].sets[0].channels[0].ch,    // Air Temperature        - Model 1
      ch1: this.sensorSets[indexOfChosenSet].sets[0].channels[1].ch,    // Soil Temperature 10 cm
      ch2: this.sensorSets[indexOfChosenSet].sets[0].channels[2].ch,    // Soil Temperature 20 cm - Model 2a, Model 3
      ch3: this.sensorSets[indexOfChosenSet].sets[0].channels[3].ch,    // Soil Temperature 30 cm
      ch4: this.sensorSets[indexOfChosenSet].sets[0].channels[4].ch,    // Soil Temperature 40 cm - Model 2c
      date_from: startDate,
      date_to: endDate
    };

    this.api.calculationAsparagus(this.stationId, reqBody).pipe(
      takeWhile(() => this.alive)
    ).subscribe((response: any) => {
      this.isLoading = false;
      this.noData = false;
      this.response = response;
      this.chartData = response.chart;
      if (this.chartData) {
        this.createCharts();
      }
    }, () => {
      this.isLoading = false;
    });
  }

  private createCharts(): void {
    this.chartOptions = [
      // 1. Model VERNALIZATION
      {
        ...deepClone(CHART_OPTIONS),
        yAxis: [
          {
            title: {
              text: null
            },
            min: 0
          }
        ],
        series: [
          {
            type: 'line',
            name: this.translatedValues['chillingUnits'],
            data: [],
            color: '#3F729B',
            yAxis: 0
          } as any,
          {
            type: 'line',
            name: 'Good',
            showInLegend: false,
            data: [],
            color: '#00ff00',
            yAxis: 0,
            marker: {
              enabled: false
            },
          } as any,
          {
            type: 'line',
            name: 'Very Good',
            showInLegend: false,
            data: [],
            color: '#FF0000',
            yAxis: 0,
            marker: {
              enabled: false
            },
          } as any
        ]
      },
      // 2. Model HARVESTING Condition - a) Start of growth of asparagus
      {
        ...deepClone(CHART_OPTIONS),
        yAxis: [
          {
            title: {
              text: null
            },
            min: 0
          }
        ],
        series: [
          {
            type: 'line',
            name: this.translatedValues['soilTempDeg20'],
            data: [],
            color: '#3F729B',
            yAxis: 0
          } as any,
          {
            type: 'line',
            name: this.translatedValues['startOfGrowth'],
            showInLegend: false,
            data: [],
            color: '#00ff00',
            yAxis: 0,
            marker: {
              enabled: false
            },
          } as any
        ]
      },
      // 2. Model Harvesting Condition - b) Long term conditions
      {
        ...deepClone(CHART_OPTIONS),
        yAxis: [
          {
            plotBands: [
              {
                color: '#CCFFCC',
                from: 5000,
                to: 6000
              }
            ],
            title: {
              text: null
            }
          }
        ],
        series: [
          {
            type: 'line',
            name: this.translatedValues['soilTempDeg10'],
            data: [],
            color: '#449d44',
            yAxis: 0
          } as any,
          {
            type: 'line',
            name: this.translatedValues['soilTempDeg20'],
            data: [],
            color: '#3F729B',
            yAxis: 0
          } as any,
          {
            type: 'line',
            name: this.translatedValues['soilTempDeg30'],
            data: [],
            color: '#A9A9A9',
            yAxis: 0
          } as any,
          {
            type: 'line',
            name: this.translatedValues['soilTempDeg40'],
            data: [],
            color: '#ADD8E6',
            yAxis: 0
          } as any,
          {
            type: 'line',
            name: 'Good',
            showInLegend: false,
            data: [],
            color: '#00FF00',
            yAxis: 0,
            marker: {
              enabled: false
            },
          } as any
        ]
      },
      // 2. Model Harvesting Condition - c) Short time conditions
      {
        ...deepClone(CHART_OPTIONS),
        chart: {
          height: 220,
          marginLeft: 80,
          marginRight: 80,
          marginTop: 50,
          style: { fontFamily: 'Helvetica' },
          zoomType: 'x',
          alignTicks: false
        },
        yAxis: [
          {
            title: {
              text: null
            },
            min: 0,
            max: 1,
            tickInterval: 1
          }
        ],
        series: [
          {
            type: 'column',
            name: this.translatedValues['threeDaysMin16C'],
            data: [],
            color: '#3F729B',
            yAxis: 0
          } as any
        ]
      },
      // 3. Model - Quality
      {
        ...deepClone(CHART_OPTIONS),
        yAxis: [
          {
            plotBands: [
              {
                color: 'rgba(255,229,229, 0.5)',
                from: 18,
                to: 20,
              },
              {
                color: 'rgba(255, 178, 178, 0.5)',
                from: 20,
                to: 22,
              },
              {
                color: 'rgba(255, 0, 0, 0.5)',
                from: 22,
                to: Number.MAX_SAFE_INTEGER
              }
            ],
            title: {
              text: null
            }
          }
        ],
        series: [
          {
            type: 'line',
            name: this.translatedValues['airTemp'],
            data: [],
            color: 'FFB266',
            yAxis: 0
          } as any,
          {
            type: 'line',
            name: this.translatedValues['soilTemp10'],
            data: [],
            color: '#449d44',
            yAxis: 0
          } as any,
          {
            type: 'line',
            name: this.translatedValues['soilTemp20'],
            data: [],
            color: '#3F729B',
            yAxis: 0
          } as any,
          {
            type: 'line',
            name: this.translatedValues['soilTemp30'],
            data: [],
            color: '#A9A9A9',
            yAxis: 0
          } as any,
          {
            type: 'line',
            name: this.translatedValues['soilTemp40'],
            data: [],
            color: '#ADD8E6',
            yAxis: 0
          } as any
        ]
      },
      // 4. Model - Critical (Holow stem)
      {
        ...deepClone(CHART_OPTIONS),
        yAxis: [
          {
            title: {
              text: null
            }
          }
        ],
        series: [
          {
            type: 'line',
            name: this.translatedValues['soilTemp10'],
            data: [],
            color: '#449d44',
            yAxis: 0
          } as any,
          {
            type: 'line',
            name: this.translatedValues['soilTemp40'],
            data: [],
            color: '#ADD8E6',
            yAxis: 0
          } as any,
          {
            type: 'line',
            name: null,
            data: [],
            yAxis: 0,
            hidden: true,
            showInLegend: false
          } as any
        ]
      }
    ];
  }

  public get sensor(): AbstractControl {
    return this.form.get('sensor');
  }

  public get from(): AbstractControl {
    return this.form.get('from');
  }

  public get to(): AbstractControl {
    return this.form.get('to');
  }

  public onValueChanged(): void {
    this.response = null;
    this.chartData = null;
    this.noData = true;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
