import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import {
  ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR
} from '@angular/forms';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { IOptions, IOptionsGroup } from '../interfaces';

@Component({
  selector: 'app-custom-lazy-grouped-dropdown',
  templateUrl: './custom-lazy-grouped-dropdown.component.html',
  styleUrls: ['./custom-lazy-grouped-dropdown.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomLazyGroupedDropdownComponent),
    multi: true
  }]
})
export class CustomLazyGroupedDropdownComponent implements ControlValueAccessor, OnChanges, OnDestroy {
  @Input()
  public title: string = '';
  @Input()
  public items$: Observable<IOptionsGroup[]>;
  @Input()
  public currentItem: any = null;
  @Input()
  public errorString?: string = 'No datasource found';

  @Input()
  public color: string = 'blue';
  @Input()
  public width: string = 'auto';
  @Input()
  public customCSS: { [key: string]: any; };
  @Input()
  public customCSS2: { [key: string]: any; };
  @Input()
  public shadow: boolean = true;

  @Output()
  public load: EventEmitter<undefined> = new EventEmitter<undefined>();

  public searchFilter$: Observable<string>;
  public searchFilter: FormControl;
  public isOpen: boolean = false;
  public isLoading: boolean = false;

  public items: IOptionsGroup[] = [];
  public buttonString: string = 'Choose a value';
  private subscription: Subscription;
  private propagateChange: any = () => {};

  public constructor() {
    this.searchFilter = new FormControl('');
    this.searchFilter$ = this.searchFilter.valueChanges.pipe(startWith(''));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['items$'] && changes['items$'].currentValue) {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }

      this.subscription = combineLatest([this.items$, this.searchFilter$])
        .pipe(
          map(([items, filterValue]) => items.map(
            (group: IOptionsGroup) => ({
              ...group,
              options: group.options.filter(
                (o: IOptions) => o.value.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1
              ),
            })
          ))
        ).subscribe((items: IOptionsGroup[]) => {
          if (items.length === 0) {
            this.buttonString = this.errorString;
          } else {
            this.buttonString = 'Choose a value';
          }

          this.items = items;

          if (this.isLoading) {
            this.isOpen = items.length > 0;
          }

          this.isLoading = false;
          this.setContent();
        });
    }
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public toggleOpen(): void {
    if (!this.isLoading) {
      if (this.items.length === 0) {
        this.isLoading = true;
        this.load.emit();
      } else {
        this.isOpen = !this.isOpen;
      }
    }
  }

  public close(): void {
    this.isOpen = false;
    this.searchFilter.setValue('');
  }

  public onChange(item: IOptions): void {
    this.currentItem = item;
    this.close();
    this.propagateChange(item);
  }

  public writeValue(item: IOptions): void {
    this.currentItem = item;
    this.setContent();
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
    this.setContent();
  }

  public registerOnTouched(fn: any): void {
    return;
  }

  public setDisabledState(isDisabled: boolean): void {
    return;
  }

  protected setContent(): void {
    if (!this.items || this.items.length < 1) {
      return;
    }

    if (this.currentItem) {
      this.propagateChange(this.currentItem);
    }
  }
}
