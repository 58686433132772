import { IStation } from './../../../../core/models/stations';
import { filter, takeUntil } from 'rxjs/operators';
import { selectNavigationStation } from './../../../../core/reducers/index';
import { Store, select } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {LeftComponentsTogglerService} from '../../../../core/services/left-components-toggler/left-components-toggler.service';
import {TreeService} from '../../../../services/tree/tree.service';
import {ITreeStructure} from '../../../../services/tree/models';
import { BehaviorSubject, Observable, combineLatest, Subject } from 'rxjs';
import {stationDataContentAnimations} from '../../../../core/services/left-components-toggler/animation.constants';
import * as fromNavigationStation from '../../../../core/reducers/navigation-station';
import { IWeatherForecastState } from '../../models/models';
import { getStationWeatherForecastLicenses } from '../../actions/weather-forecast';
import { selectWeatherForecastLicenses } from '../../reducers';
import * as moment from 'moment';
import { ForecastWarningService } from './../../services/forecast-warning.service';

@Component({
  selector: 'app-weather-forecast-layout',
  templateUrl: './weather-forecast-layout.component.html',
  styleUrls: ['./weather-forecast-layout.component.scss'],
  animations: stationDataContentAnimations
})
export class WeatherForecastLayoutComponent implements OnInit, OnDestroy {
  public validLicense: boolean;
  public tree$: BehaviorSubject<ITreeStructure>;
  public state$: Observable<string>;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private treeService: TreeService,
    private leftComponentsToggler: LeftComponentsTogglerService,
    private navigationStationStore: Store<fromNavigationStation.INavigationStationState>,
    private weatherForecastStore: Store<IWeatherForecastState>,
    private forecastWarningService: ForecastWarningService
  ) { }

  public ngOnInit(): void {
    this.tree$ = this.treeService.getWeatherForecastTreeStructure();
    this.state$ = this.leftComponentsToggler.getStationDataContentState();


    this.navigationStationStore.pipe(
      select(selectNavigationStation),
      filter((station) => !!station),
      takeUntil(this.destroy$)
    ).subscribe((station: IStation) => {
      this.weatherForecastStore.dispatch(getStationWeatherForecastLicenses(station.name.original));
    });

    this.weatherForecastStore.pipe(
      select(selectWeatherForecastLicenses),
      takeUntil(this.destroy$)
    ).subscribe((licenses) => {
      if (licenses.length > 0) {
        this.validLicense = this.forecastWarningService.setForecastWarning(licenses);
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
