<section class="notifications-menu">
  <div *ngIf="notifications?.length > 0">
    <a
      class="notification clearfix"
      *ngFor="let item of notifications"
      (click)="goToStationPage(item)">
      <div
        class="icon left"
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          padding-right: 5px;">
        <i class="{{ item.icon }}"></i>
      </div>
      <div class="right flexItem">
        <div class="title">
          <p>{{ item.title | translate }}</p>
        </div>
        <div class="station">
          <p>{{ "Station" | translate }}: {{ item.stationID }}</p>
        </div>
        <div class="body">
          <p>{{ item.body }}</p>
        </div>
      </div>
    </a>
  <hr />
  </div>
  
  <ng-container>
    <a class="flexItem" [routerLink]="['/notifications-history']"
      ><div class="iconDiv"><i class="zmdi zmdi-notifications"></i></div>
      {{ "History" | translate }}</a
    >
    <a *ngIf="hasKey1Stations" class="flexItem" [routerLink]="['/notifications-configuration']"
      ><div class="iconDiv"><i class="zmdi zmdi-hc-fw zmdi-settings"></i></div>
      {{ "Configuration" | translate }}</a
    >
  </ng-container>
</section>
