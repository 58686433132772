import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import {
  changeVirtualStationElevation,
  changeVirtualStationTimezone
} from '../../../../core/actions/stations';
import { IPosition } from '../../../../core/models/stations';
import { selectVirtualStationDetails } from '../../../../core/reducers/index';
import * as fromStations from '../../../../core/reducers/stations';
import { IVirtualStationDetails } from '../../../../core/reducers/stations';
import { saveVirtualStationActivation } from '../../actions/product-activation';
import { IProductConfigurationItem, IVirtualStationActivation } from '../../models/product-activation.models';

@Component({
  selector: 'app-product-activation-vws',
  templateUrl: './product-activation-vws.component.html',
  styleUrls: ['./product-activation-vws.component.scss'],
})
export class ProductActivationVwsComponent implements OnInit, OnDestroy {
  @Input()
  public configuration: IProductConfigurationItem;

  private stationDetails: IVirtualStationDetails;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store<fromStations.IStations>) { }

  public ngOnInit(): void {
    const virtualStationDetails$ = this.store.pipe(
      takeUntil(this.destroy$),
      select(selectVirtualStationDetails),
      tap(virtualStationDetails => this.stationDetails = virtualStationDetails)
    );
    virtualStationDetails$.subscribe();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public setElevationAndTimezone(event: IPosition): void {
    this.store.dispatch(changeVirtualStationElevation(event));
    this.store.dispatch(changeVirtualStationTimezone(event));
  }

  public addVirtualStation(event: IVirtualStationActivation): void {
    const { elevation, timezoneCode } = this.stationDetails;
    const activationRequest = {
      ...event,
      position: {
        ...event.position,
        altitude: elevation,
        timezoneCode
      },
      license_duration: this.configuration.license_duration,
      product_key: this.configuration.product_key
    };
    this.store.dispatch(saveVirtualStationActivation(activationRequest));
  }
}
