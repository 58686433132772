import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-textarea',
  templateUrl: './custom-textarea.component.html',
  styleUrls: ['./custom-textarea.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomTextareaComponent),
    multi: true
  }]
})
export class CustomTextareaComponent implements OnInit, ControlValueAccessor {
  @Input()
  public rowsCount               : number = 10;
  @Input()
  public readOnly                : boolean = false;

  private _value                 : string = '';

  private propagateChange        : any = () => { return; };

  constructor() { }

  public get value(): string {
    return this._value;
  }

  public set value(value: string) {
    this.propagateChange(value);
    this._value = value;
  }

  public ngOnInit(): void {
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
  }

  public setDisabledState(isDisabled: boolean): void {
  }

  public writeValue(value: string): void {
    this.value = value;
  }

}
