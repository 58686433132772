import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormInputComponent),
    multi: true
  }]
})

export class FormInputComponent implements ControlValueAccessor {
  @Input()
  public placeholder                    : string = '';
  @Input()
  public icon                           : string;
  @Input()
  public type                           : string = 'material';
  @Input()
  public contentType                    : string = 'text';
  @Input()
  public isDisabled                     : boolean = false;
  @Input()
  public size                           : string = '';
  @Input()
  public step                           : number = 1;
  @Input()
  public minValue                       : number;
  @Input()
  public maxValue                       : number;
  @Input()
  public inline                         : boolean = false;
  @Input()
  public styles                         : {[styleProperty: string]: string};
  @Input()
  public isAutocompleteOn               : boolean = true;
  @Input()
  public suffix                         : string = '';

  private _value                        : string = '';

  private propagateChange               : any = () => { return; };

  public get value(): string {
    return this._value;
  }

  public set value(value: string) {
    this._value = value;
  }

  public onChange(event: any): void {
    this.propagateChange(event.target.value);
  }

  public writeValue(value: any): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    return;
  }

  public setDisabledState(isDisabled: boolean): void {
    return;
  }

}
