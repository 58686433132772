export const HELP_TEXT: string =
  'Our new radar function shows weather maps in 15 minute updates, which are then visualized in 1-minute intervals, for the last ' +
  '3 hours. It is available also for time ranges of 6, 9, 12, 18 and 24 hours - worldwide, seamless, animated. For the USA and EU, we ' +
  'now offer a high-resolution 1-hour and 2-hours forecast, respectively. Thanks to the real-time map animation, we can see in which ' +
  'direction the precipitation is moving, whether it is attenuating or intensifying (orange crosses mark places where lightning was ' +
  'detected). The location marker is placed on your station location, however the user is able to move and zoom the viewport and control ' +
  'the animation.';

export const SEVERITIES: any = {
  'Moderate'  : '#fff464',
  'Severe'    : '#ffb166',
  'Extreme'   : '#ff6464'
};

export const LOCALE_OPTIONS = {
  weekday     : 'long',
  day         : 'numeric',
  month       : 'numeric',
  year        : 'numeric',
  hour        : '2-digit',
  minute      : '2-digit',
  hour12      : true
};

