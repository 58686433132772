import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IPosition, IStation, ITimeZone } from '../../../../../core/models/stations';
import { getLocation, setLocationSettings } from '../../../actions/timezoneAndLocation';
import { LocationExtremes } from '../../../constants/constants';
import { ILocationExtremes } from '../../../models/station-config.models';
import * as fromTimezoneAndLocation from '../../../reducers/timezoneAndLocation';
import { ITimezoneAndLocationState } from '../../../reducers/timezoneAndLocation';

@Component({
  selector: 'app-timezone-form',
  templateUrl: './timezone-form.component.html',
  styleUrls: ['./timezone-form.component.scss']
})
export class TimezoneFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public settings             : ITimezoneAndLocationState;
  @Input()
  public timezoneOnly         : boolean = false;
  @Input()
  public isIngestedStation    = false;
  @Input()
  public station              : IStation;

  public form                 : FormGroup;
  public timeZones            : Array<ITimeZone>;
  public readonly step        : number = 0.001;
  public readonly readOnly    : string = 'r';
  public locationExtremes     : ILocationExtremes = LocationExtremes;
  private destroy$            : Subject<boolean> = new Subject<boolean>();

  constructor(
    private fb                : FormBuilder,
    private store             : Store<fromTimezoneAndLocation.ITimezoneAndLocationState>
  ) {
  }

  public get search(): AbstractControl {
    return this.form.get('search');
  }

  public get latitude(): AbstractControl {
    return this.form.get('latitude');
  }

  public get longitude(): AbstractControl {
    return this.form.get('longitude');
  }

  public get alt(): AbstractControl {
    return this.form.get('alt');
  }

  public get timezone(): AbstractControl {
    return this.form.get('timezone');
  }

  public find(): void {
    this.store.dispatch(getLocation(this.search.value));
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      'search': ['', []],
      'latitude': [0, []],
      'longitude': [0, []],
      'alt': [0, []],
      'timezone': [{value: 'UTC', disabled: this.station.rights === 'r' || this.isIngestedStation}, []],
    });
    this.setSettings();

    this.form.valueChanges.pipe(
      distinctUntilChanged((old: any, current: any) => {
        return Object.keys(old).every((key: string) => old[key] === current[key]);
      }),
      debounceTime(200),
      takeUntil(this.destroy$)
    ).subscribe((form: any) => {
      if (this.checkLngAndLat(this.longitude.value, this.latitude.value)) {
        return;
      }
      const position: IPosition = {
        altitude: +this.alt.value,
        geo: {
          coordinates: [
            this.longitude.value ? +this.longitude.value : null,
            this.latitude.value ? +this.latitude.value : null
          ]
        },
        timezoneCode: this.timezone.value || this.settings.position.timezoneCode
      };
      this.checkRights() ? this.timezone.enable() : this.timezone.disable();
      this.store.dispatch(setLocationSettings(position));
    });
  }


  public ngOnChanges(changes: SimpleChanges): void {
    this.setSettings();
    if (!this.timeZones || !this.timeZones.length) {
      this.timeZones = this.parseOptions();
    }
  }

  private checkLngAndLat(lng: number, lat: number): boolean {
    return this.setMin(lng, 'longitude')
      || this.setMax(lng, 'longitude')
      || this.setMin(lat, 'latitude')
      || this.setMax(lat, 'latitude');
  }

  private setMin(value: number, name: string): boolean {
    if (LocationExtremes[`${name}Min`] > value) {
      this[name].setValue(LocationExtremes[`${name}Min`]);
      return true;
    }
    return false;
  }

  private setMax(value: number, name: string): boolean {
    if (LocationExtremes[`${name}Max`] < value) {
      this[name].setValue(LocationExtremes[`${name}Max`]);
      return true;
    }
    return false;
  }

  private parseOptions(): Array<ITimeZone> {
    if (this.settings.locations) {
      const timezones = [];

      for (const loc of this.settings.locations) {
        if (loc.hasOwnProperty('utc')) {
          for (const tz of loc.utc) {
            let name = tz.replace(/_/g, ' ');
            name = name.replace(/\//g, ' / ');

            timezones.push({
              zone: loc.text,
              value: tz,
              name: name
            });
          }
        }
      }
      return timezones;

    } else {
      return [];
    }
  }

  private setSettings(): void {
    if (this.settings && this.form) {
      if (this.settings.position.geo.coordinates[1] !== null) {
        this.latitude.setValue(`${this.settings.position.geo.coordinates[1]}`);
      }
      if (this.settings.position.geo.coordinates[0] !== null) {
        this.longitude.setValue(`${this.settings.position.geo.coordinates[0]}`);
      }
      this.alt.setValue(this.settings.position.altitude);
      this.timezone.setValue(this.settings.position.timezoneCode || this.timezone.value);
    }
  }

  public checkRights(): boolean {
    return this.station.rights !== this.readOnly;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
