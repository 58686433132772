<div class="auth-container login-container" [@formRouterAnimation]
     [@errorFormAnimation]="showError">

  <app-form-layout
    class="login auth-item"
    [size]="subDomain?.subDomain !== 'vodafone-idea' ? 'login' : 'login-bigger'">
    <div class="telusInfoMessage" *ngIf="subDomain?.subDomain === 'telus'">
      <div class="telusInfoMessage__header">
        <span>
          <b>{{ 'Migration to FieldClimate' | translate }}</b>
        </span>
      </div>
      <div class="telusInfoMessage__body">
        <span>{{ 'Hi there, TELUS Weather Station web app recently transitioned to our partner\'s solution in FieldClimate.' | translate }}</span>
        <span>{{ 'To continue to access your TELUS Weather Station, make sure to access FieldClimate and use your existing credentials to log in.' | translate }}</span>
      </div>
      <div class="telusInfoMessage__footing">
        <span>Access <a href="https://www.fieldclimate.com">{{ 'FieldClimate' | translate }}</a>.</span>
      </div>
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="login()" #loginform="ngForm">

      <div class="form-item">
        <app-logo
          [type]="'login'"></app-logo>
      </div>

      <alert *ngIf="showError" type="warning">{{ response | translate }}</alert>

      <div class="row form-item">
        <div class="col-sm-11">
          <app-form-input [placeholder]="'Username'"
                          [icon]="'account'"
                          [formControl]="username">
          </app-form-input>
          <div class="error" *ngIf="username.hasError('required') && loginform.submitted">
            <p>{{ 'You have to enter username' | translate }}</p>
          </div>
        </div>
      </div>

      <div class="row form-item">
        <div class="col-sm-11">
          <app-form-input [placeholder]="'Password'"
                          [icon]="'password'"
                          [formControl]="password"
                          [contentType]="showPassword ? ' text' : 'password'">
          </app-form-input>
          <div class="error" *ngIf="password.hasError('required') && loginform.submitted">
            <p>{{ 'You have to enter password' | translate }}</p>
          </div>
        </div>
        <div class="col-sm-1 eye-icon">
          <i *ngIf="!showPassword"
              class="zmdi zmdi-eye-off"
              (click)="onShowPassword()"></i>
          <i *ngIf="showPassword"
              class="zmdi zmdi-eye"
              (click)="onShowPassword()"></i>
        </div>
      </div>

      <app-form-button type="submit" *ngIf="subDomain?.subDomain !== 'telus'"></app-form-button>

    </form>

    <div [ngClass]="subDomain?.subDomain !== 'vodafone-idea' ? 'navigate-block' : 'navigate-block-bigger'" *ngIf="subDomain?.subDomain !== 'telus'">
      <app-navigate-button (navigate)="router.navigate(['/auth/registration'])" [icon]="'plus'"
                           [content]="'Registration'"></app-navigate-button>
      <app-navigate-button (navigate)="router.navigate(['/auth/reset-password'])" [icon]="'question'"
                           [content]="'Forgot Password'"></app-navigate-button>
    </div>

  </app-form-layout>

</div>

