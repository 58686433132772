<div class="data-range-arrow">
  <app-custom-button [color]="'white'"
                     (click)="backward(true)"
                     [shadow]="false"
                     [radius]="'2px 0 0 2px'"
                     appTooltip
                     [tooltipPlacement]="'top'"
                     [tooltipTitle]="'Show the oldest data' | translate"
                     [tooltipDisable]="checkBackward()"
                     [disabled]="checkBackward()"
                     [content]="''">
    <span class="fa fa-fast-backward"></span>
  </app-custom-button>
  <app-custom-button [color]="'white'"
                     (click)="backward(false)"
                     [radius]="'0'"
                     appTooltip
                     [tooltipPlacement]="'top'"
                     [tooltipTitle]="'Move back' | translate"
                     [tooltipDisable]="checkBackward()"
                     [disabled]="checkBackward()"
                     [shadow]="false"
                     [content]="''">
    <span class="fa fa-backward"></span>
  </app-custom-button>
  <app-custom-button [color]="'white'"
                     (click)="forward(false)"
                     [shadow]="false"
                     [radius]="'0'"
                     appTooltip
                     [tooltipPlacement]="'top'"
                     [tooltipTitle]="'Move forward' | translate"
                     [tooltipDisable]="checkForward()"
                     [disabled]="checkForward()"
                     [content]="''">
    <span class="fa fa-forward"></span>
  </app-custom-button>
  <app-custom-button [color]="'white'"
                     (click)="forward(true)"
                     [shadow]="false"
                     appTooltip
                     [tooltipPlacement]="'top'"
                     [tooltipTitle]="'Show the newest data' | translate"
                     [tooltipDisable]="checkForward()"
                     [disabled]="checkForward()"
                     [radius]="'0 2px 2px 0'"
                     [content]="''">
    <span class="fa fa-fast-forward"></span>
  </app-custom-button>
</div>
