import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { stationDataContentAnimations } from '../../../../core/services/left-components-toggler/animation.constants';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { generateId } from '../../../dashboard/utils/makeWidget';

@Component({
  selector: 'app-hydroponics-content',
  templateUrl: './hydroponics-content.component.html',
  styleUrls: ['./hydroponics-content.component.scss'],
  animations: stationDataContentAnimations
})
export class HydroponicsContentComponent implements OnInit, OnDestroy {
  @Input()
  public drainageDisabled           : boolean;

  public state$                     : Observable<string>;
  public HYDROPONICS_HELP_MODAL_ID  : string = generateId();

  private destroy$                  : Subject<boolean> = new Subject<boolean>();

  constructor(
    private modalService            : ModalService,
  ) { }

  public ngOnInit(): void {
  }

  public openModal(): void {
    this.modalService.openModal(this.HYDROPONICS_HELP_MODAL_ID);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
