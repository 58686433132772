import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Column, GridOptions } from 'ag-grid';
import { Options } from 'highcharts';
import * as moment from 'moment';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { ISettings } from '../../../../core/models/account';
import { selectAccumulatorToolThresholds, selectSelectedStation, selectSettings } from '../../../../core/reducers';
import * as fromAccount from '../../../../core/reducers/account';
import * as fromSelectedStation from '../../../../core/reducers/selectedStation';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { DataGridOptions } from '../../../../shared/constants';
import { IOptions } from '../../../../shared/interfaces';
import { deepClone } from '../../../../shared/utils/deepClone';
import { addToLocalStorageObject } from '../../../../shared/utils/localStorageSync';
import { SampleDataService } from '../../../cropzone-sample/services/sample-data.service';
import { DEFAULT_CHART_OPTIONS } from '../../../irrimet/constants/constants';
import * as fromStationSettings from '../../../station-data/reducers/station-data-settings';
import { DEGREE_DAYS_COLUMN_DEFS } from '../../constants/constants';
import { IAccumulationCalculationValues, IAccumulationRequest, IAccumulatorToolState } from '../../interfaces/interfaces';
import { IDegreeAndChillingThresholds } from '../../../../core/models/accumulatorTool';
import { updateAccumulatorThresholds } from '../../../../core/actions/accumulatorTool';
import { selectStationSensors } from '../../selectors/selectors';
import { ISelectedSearchWidgetItem } from '../../../../core/models/selectedSearchWidgetItem';

@Component({
  selector: 'app-accumulator-tool-degree-days',
  templateUrl: './accumulator-tool-degree-days.component.html',
  styleUrls: ['./accumulator-tool-degree-days.component.scss']
})
export class AccumulatorToolDegreeDaysComponent implements OnInit, OnDestroy {

  private stationId       : string = '';
  private alive           : boolean = true;
  private thresholds      : IDegreeAndChillingThresholds;
  private degreeTableData : any = {};
  private destroy$        : Subject<boolean> = new Subject<boolean>();

  public form             : FormGroup;
  public sensorsOptions   : Array<IOptions>;
  public unitSystem       : string = '';
  public isLoading        : boolean = false;
  public exportChartImg   : boolean = false;
  public isTableHidden    : boolean = false;
  public response         : any = null;
  public columnDefs       : any;
  public dataGridOptions  : GridOptions = DataGridOptions;
  public maxDate          = new Date();
  public chartData        : any;
  public chartOptions     : Options;
  public noData           : boolean = false;
  public noSensors        : boolean = false;
  public translatedValues : IAccumulationCalculationValues;
  public isActive$        : Observable<boolean>;

  constructor(
    private fb                    : FormBuilder,
    private api                   : ApiCallService,
    private selectedStationStore  : Store<fromSelectedStation.ISelectedStationState>,
    private stationSettingsStore  : Store<fromStationSettings.IStationDataSettingsState>,
    private accountStore          : Store<fromAccount.IAccount>,
    private accumulatorToolStore  : Store<IAccumulatorToolState>,
    private route                 : ActivatedRoute,
    private sampleDataService     : SampleDataService
  ) {
    this.selectedStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectSelectedStation)
    ).subscribe((station: ISelectedSearchWidgetItem) => {
      if (station.original_name !== this.stationId) {
        this.stationId = station.original_name;
      }
    });
  }

  public ngOnInit(): void {
    this.translatedValues = this.route.snapshot.data['accumulatorToolResolver'];

    this.form = this.fb.group({
      'sensor': ['', [Validators.required]],
      'from': [moment().startOf('year').toDate(), Validators.required],
      'to': [moment().endOf('day').toDate(), Validators.required],
      'minimum': [0, Validators.required],
      'upper': [0, Validators.required],
      'lower': [0, Validators.required],
      'maximum': [0, Validators.required],
      'isChartActive': [true, [Validators.required]],
      'isTableActive': [true, [Validators.required]],
    });

    combineLatest([
      this.accountStore.pipe(
        takeWhile(() => this.alive),
        select(selectSettings),
        filter((settings: ISettings) => !!settings)
      ),
      this.accountStore.pipe(
        takeUntil(this.destroy$),
        select(selectAccumulatorToolThresholds),
        filter((thresholds) => !!thresholds)
      )
    ]).subscribe(([settings, thresholds]) => {
      this.unitSystem = settings.unit_system;
      this.thresholds = thresholds;
      if (this.unitSystem === 'imperial') {
        this.minimum.setValue(thresholds.degree_days.lower_imperial_value);
        this.upper.setValue(thresholds.degree_days.upper_imperial_value);
      } else {
        this.minimum.setValue(thresholds.degree_days.lower_metric_value);
        this.upper.setValue(thresholds.degree_days.upper_metric_value);
      }
    });

    this.columnDefs = DEGREE_DAYS_COLUMN_DEFS;
    this.dataGridOptions.columnDefs = this.columnDefs;
    this.dataGridOptions = {
      ...this.dataGridOptions,
      suppressClickEdit: true,
      onGridReady: this.onGridReady.bind(this),
    };

    this.accumulatorToolStore.pipe(
      takeWhile(() => this.alive),
      select(selectStationSensors),
      map((sensors: Array<any>) => sensors.filter((s: any) => s.group === 3 || s.group === 1)),
      tap ((sensors: Array<any>) => {
        if (sensors.length === 0) {
          this.noSensors = true;
        }
      }),
      map((sensors: Array<any>) => sensors.map((s: any) => {
        return {
          value: s.ch,
          content: `${s.name} [${s.ch}]`
        };
      })),
      filter((options: Array<IOptions>) => !!options.length)
    ).subscribe((options: Array<IOptions>) => {
      this.sensorsOptions = options;
      this.sortOptions();
      this.sensor.setValue(options[0].value);
      this.response = null;
      this.chartData = null;
      this.noData = true;
      this.noSensors = false;
    });
  }

  private sortOptions(): void {
    this.sensorsOptions = this.sensorsOptions.sort((a, b) => {
      const contentA = a.content.toLowerCase();
      const contentB = b.content.toLowerCase();

      return contentA < contentB ? -1 : 1;
    });
  }

  public onGridReady(event: any) : void {
    this.dataGridOptions.api = event.api;
    if (this.response) {
      this.setDegreeDaysData();
    }
  }

  public setDegreeDaysData() : void {
    this.dataGridOptions.api.setRowData(this.degreeTableData);
    this.toggleColumns();
  }

  public calculateTempAccumulation(): void {
    this.isLoading = true;
    this.response = null;
    this.chartData = null;
    this.noData = true;

    const startDateUTC: Date = moment(moment(this.from.value).format('YYYY-MM-DDTHH:mm') + 'Z').toDate();
    const endDateUTC: Date = moment(moment(this.to.value).format('YYYY-MM-DDTHH:mm') + 'Z').toDate();
    const startDate = moment(startDateUTC).format('X');
    const endDate = moment(endDateUTC).format('X');

    const reqBody: IAccumulationRequest = {
      type : 'temp',
      ch: +this.sensor.value,
      date_from: startDate,
      date_to: endDate,
      tmin: 0,
      tmax: 0
    };

    reqBody.tmin = +this.minimum.value;
    reqBody.tmax = +this.upper.value;

    this.api.calculation(this.stationId, reqBody).pipe(
      takeWhile(() => this.alive)
    ).subscribe((response: any) => {
      this.isLoading = false;
      this.noData = false;
      this.response = response;
      this.chartData = response.chart;
      if (this.chartData) {
        this.createChart();
        this.degreeTableData = this.formatDegreeData(response.chart);
      }
    }, () => {
      this.isLoading = false;
    });

    if (this.unitSystem === 'imperial') {
      this.thresholds = {
        ...this.thresholds,
        degree_days: {
          ...this.thresholds.degree_days,
          lower_imperial_value: Number(this.minimum.value),
          upper_imperial_value: Number(this.upper.value)
        }
      };
    } else {
      this.thresholds = {
        ...this.thresholds,
        degree_days: {
          ...this.thresholds.degree_days,
          lower_metric_value: Number(this.minimum.value),
          upper_metric_value: Number(this.upper.value)
        }
      };
    }

    this.accountStore.dispatch(updateAccumulatorThresholds(this.thresholds));
  }

  private formatDegreeData(response) : any {
    const result = [];
    for (const key of Object.keys(response)) {
      result.push({
          dt: key,
          degree_days: response[key].degree_days,
          degree_days_usa: response[key].degree_days_usa,
          degree_hours: response[key].degree_hours
        });
    }
    return result;
  }

  private createChart(): void {
    this.chartOptions = {
      ...deepClone(DEFAULT_CHART_OPTIONS),
      legend: {
        enabled: true
      },
      chart: {
        height: 400,
        marginLeft: 80,
        marginRight: 120,
        marginTop: 50,
        style: { fontFamily: 'Helvetica' },
        zoomType: 'x',
        alignTicks: false,
      },
      yAxis: [
        {
          title: {
            text: this.translatedValues['degreeHours']
          },
          min: 0
        },
        {
          title: {
            text: this.translatedValues['degreeDays']
          },
          opposite: true,
          min: 0
        },
        {
          title: {
            text: this.translatedValues['degreeDaysUsa']
          },
          opposite: true,
          min: 0
        }
      ],
      series: [
        {
          type: 'line',
          name: this.translatedValues['degreeHours'],
          data: [],
          color: '#449d44',
          yAxis: 0
        } as any,
        {
          type: 'line',
          name: this.translatedValues['degreeDays'],
          data: [],
          color: '#3F729B',
          yAxis: 1
        } as any,
        {
          type: 'line',
          name: this.translatedValues['degreeDaysUsa'],
          data: [],
          color: '#A9A9A9',
          yAxis: 1
        } as any
      ],
      plotOptions: {
        series: {
            events: {
                  legendItemClick: (event: any) => {
                    this.changeVisible(event.target.options.name, event.target.options.visible);
                }
            }
        }
      },
      exporting: {
        filename: 'degree-days-chart_' + moment(Date.now()),
        enabled: false,
        chartOptions: {
          title: {
            text: 'Degree Days Chart_' + moment(Date.now()).toLocaleString()
          }
        }
      }
    };
  }

  public changeVisible(name: string, visible: boolean): void {
    addToLocalStorageObject('accumulatorToolTable', name, !visible);
    this.toggleColumns();
  }

  private toggleColumns(): void {
    this.isTableHidden = false;
    if (!this.dataGridOptions.api) {
      return;
    }
    if (this.dataGridOptions.columnApi != null) {
      const tableItems = JSON.parse(localStorage.getItem('accumulatorToolTable'));
      this.dataGridOptions.columnApi.getAllColumns().forEach(
        (c: Column) => {
          if (
            c.getId() === 'dt' ||
            (c.getColDef().headerName === 'Degree Days' && tableItems['Degree Days'] === true) ||
            (c.getColDef().headerName === 'Degree Hours'  && tableItems['Degree Hours'] === true) ||
            (c.getColDef().headerName === `Degree Days (max+min)/2 - Base Temperature`
              && tableItems[`Degree Days (max+min)/2 - Base temperature`] === true)
            ) {
            this.dataGridOptions.columnApi.setColumnVisible(c.getId(), true);
          } else {
            this.dataGridOptions.columnApi.setColumnVisible(c.getId(), false);
          }
        }
      );
    }
    this.isTableHidden = this.dataGridOptions.columnApi.getAllDisplayedVirtualColumns().length === 1;
  }

  public get sensor(): AbstractControl {
    return this.form.get('sensor');
  }

  public get from(): AbstractControl {
    return this.form.get('from');
  }

  public get to(): AbstractControl {
    return this.form.get('to');
  }

  public get minimum(): AbstractControl {
    return this.form.get('minimum');
  }

  public get upper(): AbstractControl {
    return this.form.get('upper');
  }

  public get isChartActive(): AbstractControl {
    return this.form.get('isChartActive');
  }

  public get isTableActive(): AbstractControl {
    return this.form.get('isTableActive');
  }

  public onValueChanged(): void {
    this.response = null;
    this.chartData = null;
    this.noData = true;
  }

  public startExportXLS(): void {
    this.sampleDataService.setGridOptions(this.dataGridOptions);
    this.sampleDataService.exportXLS(this.stationId);
  }

  public onExportChart(): void {
    this.exportChartImg = true;
    setTimeout(() => {
      this.exportChartImg = false;
    }, 1000);
  }

  public ngOnDestroy(): void {
    localStorage.removeItem('accumulatorToolTable');
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
