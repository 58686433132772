import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[appMouseWheel]'
})
export class MouseWheelDirective {
  @Input()
  public mouseWheelPreventDefault: boolean = true;
  @Output()
  public mouseWheelEventEmitter: EventEmitter<WheelEvent> = new EventEmitter<WheelEvent>();

  @HostListener('mousewheel', ['$event'])
  public onMouseWheelChrome(event: WheelEvent): void {
    this.mouseWheelFunc(event);
  }

  @HostListener('DOMMouseScroll', ['$event'])
  public onMouseWheelFirefox(event: WheelEvent): void {
    this.mouseWheelFunc(event);
  }

  @HostListener('onmousewheel', ['$event'])
  public onMouseWheelIE(event: WheelEvent): void {
    this.mouseWheelFunc(event);
  }

  private mouseWheelFunc(event: WheelEvent): void {
    const delta = Math.max(-1, Math.min(1, (-event.deltaY || -event.detail)));
    if (delta !== 0) {
      this.mouseWheelEventEmitter.emit(event);
    }
    if (this.mouseWheelPreventDefault) {
      event.preventDefault();
    }
  }
}
