import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CropzoneManagementComponent } from './cropzone-management.component';
import { SharedModule } from './../../shared/shared.module';
import { CropzoneManagementRoutingModule } from './cropzone-management-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { CropzoneManagementSearchComponent } from './containers/cropzone-management-search/cropzone-management-search.component';
import { AddCropZoneManagementComponent } from './containers/add-cropzone/add-cropzone.component';
import { AddCropzoneManagementFormComponent } from './containers/add-cropzone-form/add-cropzone-form.component';
import { AddFarmManagementFormComponent } from './containers/add-farm-form/add-farm-form.component';
import { AddFieldManagementFormComponent } from './containers/add-field-form/add-field-form.component';
import { SearchInputComponent } from './containers/search-input/search-input.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { StoreModule } from '@ngrx/store';
import { reducer } from './reducers/cropzoneManagement';
import { EffectsModule } from '@ngrx/effects';
import { CropzoneManagementEffects } from './effects/index';
import { ProductLicenseModule } from '../../core/containers/main-static/product-license/product-license.module';
import { RenewCropzoneModalComponent } from './containers/renew-cropzone-modal/renew-cropzone-modal.component';
import { RenewCropzonesListComponent } from './containers/renew-cropzones-list/renew-cropzones-list.component';
import { DuplicationInfoModalComponent } from './containers/duplication-info-modal/duplication-info-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CropzoneManagementRoutingModule,
    StoreModule.forFeature('cropzoneManagement', reducer),
    TranslateModule.forChild(),
    EffectsModule.forFeature([CropzoneManagementEffects]),
    TypeaheadModule,
    ProductLicenseModule
  ],
  declarations: [
    CropzoneManagementComponent,
    CropzoneManagementSearchComponent,
    AddCropZoneManagementComponent,
    AddCropzoneManagementFormComponent,
    AddFarmManagementFormComponent,
    AddFieldManagementFormComponent,
    SearchInputComponent,
    RenewCropzoneModalComponent,
    RenewCropzonesListComponent,
    DuplicationInfoModalComponent
  ]
})
export class CropzoneManagementModule { }
