<div class="checkbox-container">
  <label class="checkbox-container__label">
    <input type="checkbox"
           class="checkbox-container__fake"
           ([ngModel])="value"
           [checked]="value"
           (change)="onChange()">
    <i class="checkbox-container__input-helper"></i>
    <ng-content>
    </ng-content>
  </label>
</div>
