import * as moment from 'moment';

export function filterIrrigationDayEvents(events: Array<any>): Array<any> {
    return events.filter((event) => {
        const startDate = moment(event.start).date();
        const endDate = moment(event.end).date();
        return event.eventType === 'irrigation' && startDate === endDate;
    });
}

export function filterSplitIrrigationDayEvents(events: Array<any>): Array<any> {
    return events.filter((event) => {
        const startDate = moment(event.start).date();
        const endDate = moment(event.end).date();
        return event.eventType === 'irrigation' && startDate !== endDate;
    });
}
