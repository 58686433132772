import {IActionWithPayload} from '../models/actionWithPayload';
import {SelectedCropZoneActionTypes} from '../actions/selectedCropZone';
import {ICropZone} from '../models/cropzones';

export interface ISelectedCropZoneState {
  selectedCropZone: ICropZone;
}

const initialState: ISelectedCropZoneState = {
  selectedCropZone: null
};

export function reducer(state: ISelectedCropZoneState = initialState, action: IActionWithPayload): ISelectedCropZoneState {
  switch (action.type) {
    case SelectedCropZoneActionTypes.SET_SELECTED_CROPZONE: {
      return {
        ...state,
        selectedCropZone: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
