import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { sendSupportMessage } from '../../../../../../core/actions/account';
import { IMessage } from '../../../../../../core/models/account';
import * as fromAccount from '../../../../../../core/reducers/account';
import { IOptions } from '../../../../../../shared/interfaces';

@Component({
  selector: 'app-send-message-form',
  templateUrl: './send-message-form.component.html',
  styleUrls: ['./send-message-form.component.scss']
})
export class SendMessageFormComponent implements OnInit, OnDestroy {
  @Input()
  public message                  : IMessage;
  public form                    : FormGroup;
  private alive$                 : Subject<boolean> = new Subject<boolean>();
  public feedbackTopic           : Array<IOptions> =
    [
      {
        value: 'support',
        content: 'Support'
      },
      {
        value: 'request',
        content: 'Feature Request'
      },
      {
        value: 'issues',
        content: 'Technical Issues'
      },
    ];

  constructor(
    private accountStore: Store<fromAccount.IAccount>,
    private formBuilder: FormBuilder
  ) {}


  public ngOnInit(): void {

    this.form = this.formBuilder.group({
      message: ['', [Validators.required, Validators.minLength(10)]],
      topic: ['support', Validators.required]
    });
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }
  public sendMessage(): void {
    this.accountStore.dispatch(sendSupportMessage(this.form.value));
  }
}
