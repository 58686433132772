<div class="auth-container update-container" [@formRouterAnimation] [@errorFormAnimation]="!!error">

  <app-form-layout [size]="'login'" class="update auth-item">

    <form [formGroup]="updateForm" (ngSubmit)="updatePassword(updateForm.value)">

      <div class="form-item">
        <app-logo [type]="'reset'"></app-logo>
      </div>

      <alert type="warning" *ngIf="error">
        {{ error | translate }}
      </alert>

      <alert type="success" *ngIf="success">
        {{ success | translate }}
      </alert>


      <div class="form-item">
        <app-form-input [isAutocompleteOn]="false" placeholder="Password (min 5 characters)" icon="password" formControlName="password" contentType="password" ></app-form-input>
        <div class="error" *ngIf="updateForm.controls['password'].hasError('minlength') && !updateForm.controls['password'].pristine">
          <p>{{ 'Password must be at least 5 characters long' | translate }}</p>
        </div>
        <div class="error" *ngIf="updateForm.controls['password'].hasError('required') && !updateForm.controls['password'].pristine">
          <p>{{ 'You have to enter password' | translate }}</p>
        </div>
      </div>

      <div class="form-item">
        <app-form-input [isAutocompleteOn]="false" placeholder="Confirm Password (min 5 characters)'" icon="password" formControlName="password_repeat" contentType="password" ></app-form-input>
        <div class="error" *ngIf="updateForm.controls['password_repeat'].hasError('minlength') && !updateForm.controls['password_repeat'].pristine">
          <p>{{ 'Password must be at least 5 characters long' | translate }}</p>
        </div>
        <div class="error" *ngIf="updateForm.controls['password_repeat'].hasError('required') && !updateForm.controls['password_repeat'].pristine">
          <p>{{ 'You have to enter password' | translate }}</p>
        </div>
        <div class="error" *ngIf="updateForm.controls['password'].value !== updateForm.controls['password_repeat'].value && !updateForm.controls['password'].pristine">
          <p>{{ 'Password mismatch' | translate }}</p>
        </div>
      </div>


      <div class="button-container">
        <app-custom-button content="Update password" [disabled]="!updateForm.valid || !!success || updateDisabled"></app-custom-button>
      </div>

    </form>

    <div class="navigate-block">
      <app-navigate-button (navigate)="back()" icon="arrow" [content]="'Back' | translate"></app-navigate-button>
    </div>

  </app-form-layout>

</div>

