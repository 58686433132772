import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { INode, ITreeStructure } from '../../services/tree/models';
import { TreeService } from '../../services/tree/tree.service';
import { setDrainageVisibility, setTree, setWeightVisibility } from './actions/actions';
import { IHydroponicsState } from './models/models';

@Component({
  selector: 'app-hydroponics',
  templateUrl: './hydroponics.component.html',
  styleUrls: ['./hydroponics.component.scss']
})
export class HydroponicsComponent implements OnInit, OnDestroy {
  public tree$                : BehaviorSubject<ITreeStructure>;
  public tree                 : ITreeStructure;
  public drainageVisible      : boolean = true;

  private destroy$            : Subject<boolean> = new Subject<boolean>();

  constructor(
    private treeService       : TreeService,
    private hydroponicsStore  : Store<IHydroponicsState>,
  ) { }

  public ngOnInit(): void {
    this.tree$ = this.treeService.getHydroponicsTreeStructure();
    this.tree$
      .pipe()
      .subscribe((tree: any) => {
        this.tree = tree;
        if (this.tree) {
          this.hydroponicsStore.dispatch(setTree(this.tree.trees));
        }
      });
  }

  public onToggleSensor(node: INode): void {
    for (let i = 0; i < this.tree.trees.length; i++) {
      const selectedNode = this.tree.trees[i].nodes.filter(n => n.ch === node.ch)[0];
      let nodeVisible = selectedNode.visible;
      nodeVisible = !nodeVisible;
      this.tree.trees[i].nodes.filter(n => n.ch === node.ch)[0].visible = nodeVisible;
      this.hydroponicsStore.dispatch(setTree(this.tree.trees));

      if (selectedNode.ch === 2011) {           // drainage
        this.hydroponicsStore.dispatch(setDrainageVisibility(nodeVisible));
      }
      if (selectedNode.ch === 2010) {           // scale high res.
        this.hydroponicsStore.dispatch(setWeightVisibility(nodeVisible));
      }
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.treeService.getHydroponicsTreeStructure().next(null);
  }
}
