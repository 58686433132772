import { IOptions } from '../../../shared/interfaces';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { ICropZone } from '../../../core/models/cropzones';

export interface IIrrimetConfig {
  datasourcesRainFull: Array<any>;
  datasourcesET0Full: Array<any>;
  rainOptions: Array<IOptions>;
  et0Options: Array<IOptions>;
  cropTypeOptions: Array<IOptions>;
  cropObjects: Array<any>;
  cropzoneId: string;
}

export interface IOptionsForDatasources {
  centroid: any;
  from: string;
  to: string;
  groups: string;
  distance: number;
}

export const enum IrrimetConfigActionTypes {
  GET_DATASOURCES_RAIN = '[IrrimetConfig] GET_DATASOURCES_RAIN',
  SET_DATASOURCES_RAIN = '[IrrimetConfig] SET_DATASOURCES_RAIN',
  GET_DATASOURCES_ETO = '[IrrimetConfig] GET_DATASOURCES_ETO',
  SET_DATASOURCES_ETO = '[IrrimetConfig] SET_DATASOURCES_ETO',
  GET_CROPS_LAZY_LOADING = '[IrrimetConfig] GET_CROPS_LAZY_LOADING',
  SET_CROPS_LAZY_LOADING = '[IrrimetConfig] SET_CROPS_LAZY_LOADING',
  UPDATE_CROPZONE = '[IrrimetConfig] UPDATE_CROPZONE',
  COLLECT_CONFIG_DATA = '[IrrimetConfig] COLLECT_CONFIG_DATA'
}

export function getDatasourcesRain(payload: IOptionsForDatasources): IActionWithPayload {
  return {type: IrrimetConfigActionTypes.GET_DATASOURCES_RAIN, payload: payload};
}

export function setDatasourcesRain(datasources: {}[]): IActionWithPayload {
  return {type: IrrimetConfigActionTypes.SET_DATASOURCES_RAIN, payload: datasources};
}

export function getDatasourcesET0(payload: IOptionsForDatasources): IActionWithPayload {
  return {type: IrrimetConfigActionTypes.GET_DATASOURCES_ETO, payload: payload};
}

export function setDatasourcesET0(datasources: {}[]): IActionWithPayload {
  return {type: IrrimetConfigActionTypes.SET_DATASOURCES_ETO, payload: datasources};
}

export function getCropsLazyLoading(): IActionWithPayload {
  return {type: IrrimetConfigActionTypes.GET_CROPS_LAZY_LOADING, payload: undefined};
}

export function setCropsLazyLoading(crops: {}[]): IActionWithPayload {
  return {type: IrrimetConfigActionTypes.SET_CROPS_LAZY_LOADING, payload: crops};
}

export function updateCropzone(payload: ICropZone): IActionWithPayload {
  return { type: IrrimetConfigActionTypes.UPDATE_CROPZONE, payload };
}

export function collectConfigData(payload: boolean): IActionWithPayload {
  return { type: IrrimetConfigActionTypes.COLLECT_CONFIG_DATA, payload };
}
