import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-satellite-ndvi-help-text-modal',
  templateUrl: './satellite-ndvi-help-text-modal.component.html',
  styleUrls: ['./satellite-ndvi-help-text-modal.component.scss']
})
export class SatelliteNdviHelpTextModalComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
