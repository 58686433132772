import { Component } from '@angular/core';

@Component({
  selector: 'app-previous-yield-help-text',
  templateUrl: './previous-yield-help-text.component.html',
  styleUrls: ['./previous-yield-help-text.component.scss']
})
export class PreviousYieldHelpTextComponent {

  constructor() { }

}
