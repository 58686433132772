import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-product-license-duplication-text',
  templateUrl: './product-license-duplication-text.component.html',
  styleUrls: ['./product-license-duplication-text.component.scss']
})
export class ProductLicenseDuplicationTextComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public amountOfCropzones: number = null;
  @Input()
  public selectedLicense: any = null;

  public duplicationText: string;
  public remainingDays: number = 0;
  public showRemainingDays: boolean = false;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedLicense) {
      this.showRemainingDays = this.setText();
    }
  }

  public ngOnInit(): void {}

  public setText(): boolean {
    // return boolean to show the remaining days in license or not
    if (this.selectedLicense) {
      // valid selected license
      if (this.selectedLicense.remaining > this.amountOfCropzones) {
        // enough remaining cropzones in license
        this.remainingDays = moment(this.selectedLicense.end_date).diff(moment(), 'days');
        this.duplicationText = 'All the selected cropzones are about to be duplicated.';
        return true;
      } else {
        // not enough cropzones remaining in license
        this.duplicationText = 'Not enough remaining cropzones left to duplicate all the selected cropzones.';
        return false;
      }
    } else {
      // unvalid selected license
      this.duplicationText = 'To obtain a valid subscription, please contact your METOS salesperson.';
      return false;
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
