import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IUserLicensesState} from '../models/user-licenses.models';
import {UserLicensesActionTypes} from '../actions';

const initialState: IUserLicensesState = {
  validLicenses: [],
  selectedLicense: null
};

export function reducer(state: IUserLicensesState = initialState, action: IActionWithPayload): IUserLicensesState {
  switch (action.type) {
    case UserLicensesActionTypes.SET_USER_LICENSES:
      return {...state, validLicenses: action.payload};
    case UserLicensesActionTypes.SELECT_USER_LICENSE:
      return {...state, selectedLicense: action.payload};
    case UserLicensesActionTypes.UNSELECT_USER_LICENSE:
      return {...state, selectedLicense: null};
    default:
      return state;
  }
}
