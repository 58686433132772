import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ICropZone } from '../../core/models/cropzones';
import { selectSelectedCropZone } from '../../core/reducers';
import * as fromSelectedCropzone from '../../core/reducers/selectedCropZone';

@Component({
    selector: 'app-cropzone-page-header',
    templateUrl: './cropzone-page-header.component.html',
    styleUrls: ['./cropzone-page-header.component.scss']
})
export class CropzonePageHeaderComponent implements OnInit, OnDestroy {
    @Input()
    public pageName                 : string = '';

    public selectedCropzone$        : Observable<ICropZone>;
    private destroy$                : Subject<boolean> = new Subject<boolean>();

    constructor(private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>) { }

    public ngOnInit(): void {
        this.selectedCropzone$ = this.selectedCropzoneStore.pipe(
            takeUntil(this.destroy$),
            select(selectSelectedCropZone)
        );
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
