<app-chart-toolbar-layout>
  <ng-container *ngIf="periodForm">
    <form class="sample-data-toolbar"
          (keydown.enter)="$event.preventDefault()"
          [formGroup]="periodForm">

      <div class="sample-data-toolbar__left">
        <div class="sample-data-toolbar__toggle sample-data-toolbar__left__item">
          <app-custom-button appTooltip
                             [color]="isLastMode ? 'dark-green' : 'green'"
                             tooltipPlacement="top"
                             [tooltipTitle]="'Last data mode' | translate"
                             [shadow]="false"
                             radius="2px 0 0 2px"
                             (click)="updateLastMode(true)">
            <span class="fa fa-step-backward"></span>
          </app-custom-button>
          <app-custom-button appTooltip
                             [color]="!isLastMode ? 'dark-green' : 'green'"
                             tooltipPlacement="top"
                             [tooltipTitle]="'From - To mode' | translate"
                             [shadow]="false"
                             radius="0 2px 2px 0"
                             (click)="updateLastMode(false)">
            <span class="fa fa-calendar"></span>
          </app-custom-button>
        </div>

        <ng-container *ngIf="!isLastMode">
          <app-custom-datepicker [isSmall]="true"
                                 [minDate]="startDate"
                                 [maxDate]="toDate.value"
                                 [formControl]="fromDate">
          </app-custom-datepicker>
          <app-custom-datepicker [isSmall]="true"
                                 [maxDate]="endDate"
                                 [minDate]="fromDate.value"
                                 class="sample-data-toolbar__left__item"
                                 [formControl]="toDate">
          </app-custom-datepicker>
        </ng-container>
        <app-custom-dropdown [options]="periodValues"
                             [hidden]="!isLastMode"
                             class="sample-data-toolbar__left__item"
                             formControlName="periodValue">
        </app-custom-dropdown>
        <app-date-range-arrow [hidden]="!isLastMode"
                              (rangeClick)="submitSettings() || dropActive()"
                              [minDate]="startDate"
                              [maxDate]="endDate"
                              [period]="periodValue.value"
                              [formControl]="fromTo">
        </app-date-range-arrow>
      </div>

      <div class="sample-data-toolbar__right">
        <app-custom-dropdown *ngIf="activeType !=='soilguard'"
                             [options]="deviceTypes"
                             style="margin-right: 10px"
                             color="white"
                             class="soil-guard-toolbar__left__item"
                             [formControl]="deviceType">
        </app-custom-dropdown>

        <app-custom-dropdown style="margin-right: 10px"
                             [options]="titleValues"
                             color="white"
                             class="soil-guard-toolbar__left__item"
                             formControlName="activeTitle">
        </app-custom-dropdown>

        <app-custom-dropdown style="margin-right: 0"
                             [hidden]="!meterOptions"
                             [options]="meterOptions"
                             color="white"
                             class="soil-guard-toolbar__left__item"
                             [formControl]="activeMeter">
        </app-custom-dropdown>
        <app-custom-button color="red"
                           class="sample-data-toolbar__right__item"
                           (click)="resetSettings()"
                           [content]="'Reset' | translate">
          <i class="fa fa-fw fa-rotate-right"></i>
        </app-custom-button>

        <div class="sample-data-toolbar__toggle sample-data-toolbar__right__item">
          <app-custom-button [color]="isTableActive ? 'green' : 'white'"
                             appTooltip
                             tooltipPlacement="top"
                             [tooltipTitle]="'Toggle table' | translate"
                             [shadow]="false"
                             (click)="toggleTable()"
                             radius="0 2px 2px 0">
            <span class="fa fa-table"></span>
          </app-custom-button>
        </div>

        <app-custom-button color="green"
                           class="sample-data-toolbar__right__item"
                           (click)="submitSettings()"
                           [content]="'Refresh' | translate">
          <i class="fa fa-fw fa-refresh"></i>
        </app-custom-button>
      </div>
    </form>
  </ng-container>
</app-chart-toolbar-layout>
