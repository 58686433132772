import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IStation } from '../../../../../core/models/stations';
import { selectStations } from '../../../../../core/reducers';
import { IStations } from '../../../../../core/reducers/stations';
import { ApiCallService } from '../../../../../services/api/api-call.service';
import { StationTypes } from '../../../../../shared/constants';
import { IOptions } from '../../../../../shared/interfaces';
import { IGetTrackerDataRequest } from '../../../../tracker/models/tracker.models';
import {
  removeWidget,
  setWidgetError,
  setWidgetLoading,
  updateWidgetEditMode,
  updateWidgetSettings
} from '../../../actions/dashboard';
import { ILoadWidgetData, ITrackerWidgetRequest, IUpdateWidgetEditMode, IWidget } from '../../../models/dashboard.models';
import { selectEdit } from '../../../reducers';
import * as fromDashboard from '../../../reducers/dashboard';

@Component({
  selector: 'app-tracker-container',
  templateUrl: './tracker-container.component.html',
  styleUrls: ['./tracker-container.component.scss']
})
export class TrackerContainerComponent implements OnInit {
  @Input()
  public widget             : IWidget;
  @Input()
  private widgetIndex       : number;
  @Input()
  private columnIndex       : number;
  @Input()
  private rowIndex          : number;
  @Input()
  public isEditable         : boolean;
  @Input()
  public isDraggable        : boolean;
  @Input()
  public isClose            : boolean;

  public trackerData        : any;
  private loadWidgetData    : ILoadWidgetData;
  public isEdit$            : Observable<boolean>;

  public trackViewStations  : Array<IOptions> = [];
  private destroy$          : Subject<boolean> = new Subject<boolean>();

  private pickedStation     : string;

  constructor(
    private dashboardStore: Store<fromDashboard.IDashboard>,
    private stationsStore: Store<IStations>,
    private apiCallService: ApiCallService
    ) {}

  public ngOnInit(): void {
    this.isEdit$ = this.dashboardStore.pipe(select(selectEdit));
    this.initStationListListener();
    if (!this.widget.loadData ) {
      this.loadTrackerViewData();
    }
  }

  private initStationListListener(): void {
    this.stationsStore.pipe(
      takeUntil(this.destroy$),
      select(selectStations),
      filter((stations: Array<IStation>): boolean => !!stations && stations.length > 0),
    ).subscribe((stations: Array<IStation>): void => {
      this.trackViewStations = stations.filter((station: IStation): boolean => {
        return [
          StationTypes.get('iMetos Active Tracker'),
          StationTypes.get('iMetos Object Tracker')
        ].includes(station.info.device_id);
      }).map((station: IStation): any => {
        const trackerPeriodScope = localStorage.getItem('trackerPeriodScope');
        return {
          value: station.name.original,
          from: moment().subtract(trackerPeriodScope, 'd').unix(),
          to: moment().unix()
        };
      });
    });
  }

  public loadTrackerViewData(data?): void {
    const curStationIndex = this.trackViewStations.findIndex(
      (station: IOptions) => station.value === this.widget.requests.tracker.station);
    this.loadWidgetData = {
      url: `/tracker/${this.widget.requests.tracker.station}
/from/${this.trackViewStations[curStationIndex]['from']}
/to/${this.trackViewStations[curStationIndex]['to']}`,
      path: [this.rowIndex, this.columnIndex, this.widgetIndex]
    };
    let requestData = {
      from : this.trackViewStations[curStationIndex]['from'],
      to: this.trackViewStations[curStationIndex]['to'],
      stationId: this.widget.requests.tracker.station
    };
    if (data) {
      this.loadWidgetData = {
        ...this.loadWidgetData,
        url: `/tracker/${data.station}/from/${data.from}/to/${data.to}`,
      };
      requestData = {
        from: data.from,
        to: data.to,
        stationId: data.station
      };
    }
    this.resetLoading();
    this.getTrackerTrips(requestData.stationId, requestData.from, requestData.to);
  }

  public getTrackerTrips(station, from, to) : void {
    const request: IGetTrackerDataRequest = {
      from: from,
      to: to,
      stationId: station
    };
    this.apiCallService.getTrackerData(request).subscribe(response => {
      this.trackerData = response;
    });
  }

  public removeCurrentWidget(): void {
    const path: Array<number> = [this.rowIndex, this.columnIndex, this.widgetIndex];
    this.dashboardStore.dispatch(removeWidget(path));
  }

  public updateRequest(updateSettings: ITrackerWidgetRequest): void {
    this.dashboardStore.dispatch(updateWidgetSettings({
      type: 'tracker',
      updateSettings: updateSettings,
      path: [this.rowIndex, this.columnIndex, this.widgetIndex]
    }));
  }

  public editMode(editMode: boolean): void  {
    const updateSettings: IUpdateWidgetEditMode = {
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      type: 'tracker',
      isEdit: editMode,
    };
    this.dashboardStore.dispatch(updateWidgetEditMode(updateSettings));
  }

  private resetLoading(): void {
    this.dashboardStore.dispatch(setWidgetLoading({
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      loading: true
    }));
    this.dashboardStore.dispatch(setWidgetError({
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      error: ''
    }));
  }

}
