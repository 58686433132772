<div style="height: 100%;">

  <div *ngIf="noDataError" class="align-middle w-100 text-center">
    <div class="alert alert-warning" role="alert">
      No data has been found.
    </div>
  </div>

  <div class="lazy-list-container wrapper" #container>

    <div class="lazy-list-container__header" *ngIf="loading && !noDataError">
      <span class="lazy-list-container__header__loading">.. loading ..</span>
    </div>

    <ng-container *ngIf="sortedValues$ | async as values; let i = index" onresize="resize()">
      <app-lazy-list-item appObserveVisibility [cropzone]="cropZone" class="maxWidth255"
        (visible)="onVisible($event)" *ngFor="let value of values; let i = index" [index]="i" [resolution]="resolution"
        [value]="value" [cropZone]="cropZone" (selectedItem)="onItemClicked($event)" [type]="type"></app-lazy-list-item>
    </ng-container>

  </div>
</div>