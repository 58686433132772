import { IOptions } from '../../../shared/interfaces';

export const alertTypes: Array<IOptions> = [
  { value: 'min', content: 'Below' },
  { value: 'max', content: 'Above' }
];

export const alertShortTypes: Array<IOptions> = [
  { value: 'min', content: 'Below' },
  { value: 'max', content: 'Above' }
];

export const resendOptions: Array<IOptions> = [
  { value: 0, content: '0 hour' },
  { value: 60, content: '1 hour' },
  { value: 120, content: '2 hours' },
  { value: 240, content: '4 hours' },
  { value: 360, content: '6 hours' }
];

export const channelOptions: { [key: string]: string } = {
  // push_web: 'Web',
  push_mobile: 'Mobile',
  email: 'Email'
}
;

export const aggrType = {
  avg: 'a',
  min: 'mn',
  max: 'mx',
  last: 'l',
  sum: 's',
  time: 't'
};

export const aggrTypeInv = {
  a: 'avg',
  mn: 'min',
  mx: 'max',
  l: 'last',
  s: 'sum',
  t: 'time'
};
