import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IUltrasonicDistanceSettingsState } from '../models/models';

export const ultrasonicDistanceSettingsSelector = createFeatureSelector<IUltrasonicDistanceSettingsState>('ultrasonicDistanceSettings');

export const selectUltrasonicSettingsData = createSelector(
  ultrasonicDistanceSettingsSelector,
  (state: IUltrasonicDistanceSettingsState) => state
);

export const selectUltrasonicSensors = createSelector(
  ultrasonicDistanceSettingsSelector,
  (state: IUltrasonicDistanceSettingsState) => state.sensors
);

export const selectUltrasonicSettings = createSelector(
  ultrasonicDistanceSettingsSelector,
  (state: IUltrasonicDistanceSettingsState) => state.ultrasonicSettings
);

export const selectUltrasonicSettingsCurrentValue = createSelector(
  ultrasonicDistanceSettingsSelector,
  (state: IUltrasonicDistanceSettingsState) => state.currentValue
);

export const selectUltrasonicError = createSelector(
  ultrasonicDistanceSettingsSelector,
  (state: IUltrasonicDistanceSettingsState) => state.dataLoading.error.isError
);

export const selectUltrasonicErrorMessage = createSelector(
  ultrasonicDistanceSettingsSelector,
  (state: IUltrasonicDistanceSettingsState) => state.dataLoading.error.errorMessage
);

export const selectUltrasonicLoading = createSelector(
  ultrasonicDistanceSettingsSelector,
  (state: IUltrasonicDistanceSettingsState) => state.dataLoading.isLoading
);
