<app-tree [tree$]="tree$"></app-tree>
<div class="station-data-content">
    <app-station-page-header [pageName]="'Work Tracking'"></app-station-page-header>

    <div class="station-data-content__header">
        <app-tracker-header></app-tracker-header>
        <app-tracker-toolbar></app-tracker-toolbar>
    </div>
    <ng-container *ngIf="!(isLoading$ | async) && !(isError$ | async); else loadinfo">
        <app-tracker-reports *ngIf="false"></app-tracker-reports>

        <div class="station-data-content__item">
            <app-tracker-map></app-tracker-map>
        </div>

        <div class="station-data-content__item">
            <app-tracker-table></app-tracker-table>
        </div>
    </ng-container>
</div>

<ng-template #loadinfo>
    <app-station-load-info [isError]="isError$ | async" [isLoading]="isLoading$ | async"></app-station-load-info>
</ng-template>