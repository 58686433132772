<app-form-layout>
  <div [formGroup]="form" class="terms">
    <div>
      <h3 class="terms__header">{{ 'Dear user' | translate }}</h3>
      <p class="terms__info"> {{ 'We invite you to read our new' | translate }}
        <a class="terms__link" href="http://metos.at/home/use-terms/" target="_blank"><b>{{ 'Terms of Use' | translate }}</b></a>
        {{'and' | translate}} <a class="terms__link" href="http://metos.at/home/privacy-policy/" target="_blank">
          <b>{{ 'Privacy Policy' | translate}}</b></a>,
        {{ 'updated to comply with new European Union data protection laws. Please, check your details below. Note that ' +
        'inserting the correct data can help us provide a better service.' | translate }}</p>
    </div>

    <form>
      <div class="row">
        <div class="terms__form__info col-md-6">
          <div class="terms__form__info__group col-md-6">
              <app-form-input [placeholder]="'Enter First Name'"
                              [formControl]="name"></app-form-input>

              <app-form-input [placeholder]="'Enter Last Name'"
                              [formControl]="lastName"
                              class="terms__bottom-control"></app-form-input>
          </div>
          <div class="terms__form__info__group col-md-6">
              <app-form-input [placeholder]="'Enter Email'"
                              [formControl]="email"></app-form-input>
              <small *ngIf="isEmptyEmail" class="terms__danger">{{ 'You have to enter an email' | translate }}</small>
              <small *ngIf="isInvalidEmail" class="terms__danger">{{ 'Email must be in correct format' | translate }}</small>
              <app-form-select [options]="countries"
                               [formControl]="country"
                               class="terms__bottom-select"
                               [type]="'border'"
                               [icon]="'country'"></app-form-select>
          </div>
        </div>


        <div class="col-md-6">
          <app-custom-checkbox [formControl]="terms"
                               class="terms__checkbox">
            {{ 'I read and accept new Terms and Privacy Policy' | translate }}
          </app-custom-checkbox>
          <div *ngIf="isInvalidTerms" class="alert alert-warning">
            {{ 'If for any reason you do not agree with the new conditions and prefer to deactivate your account you can ' +
            'do it by sending an email to' | translate}}<a class="terms__link" href="mailto:fieldclimate@metos.at">fieldclimate@metos.at</a>
          </div>
          <hr/>
          <p> {{ 'I would like to receive' | translate}} :</p>

          <app-custom-checkbox [formControl]="technical"
                               class="terms__checkbox">
            {{ 'Technical information about FieldClimate updates' | translate }}
          </app-custom-checkbox>

          <app-custom-checkbox [formControl]="promotional"
                               class="terms__checkbox">
            {{ 'Promotional information about new Pessl Instruments and iMETOS new products, services and events' | translate }}
          </app-custom-checkbox>

        </div>

      </div>

      <div class="terms__save">
        <app-custom-button [content]="'Save'"
                           (click)="save()"
                           [color]="'red'"></app-custom-button>
      </div>

    </form>
  </div>
</app-form-layout>
