import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {StationDiseaseActionTypes} from '../actions/station-disease';
import {IDiseaseLicenses, IDiseaseWarning} from '../models/station-disease.models';
import {deepClone} from '../../../shared/utils/deepClone';

export interface IStationDiseaseState {
  grid: {};
  charts: any[];
  topology: {};
  licenses: IDiseaseLicenses;
  warnings: IDiseaseWarning[];
  isLoading: boolean;
  isError: boolean;
}

const initialState: IStationDiseaseState = {
  grid: null,
  charts: [],
  topology: {},
  licenses: null,
  warnings: [],
  isLoading: false,
  isError: false,
};

export function reducer(state: IStationDiseaseState = initialState, action: IActionWithPayload ): IStationDiseaseState {
  switch (action.type) {
    case StationDiseaseActionTypes.ADD_STATION_DISEASE_WARNING: {
      return {
        ...state,
        warnings: addWarning(deepClone(state.warnings), action.payload)
      };
    }
    case StationDiseaseActionTypes.SET_STATION_DISEASE_LICENSES: {
      return {
        ...state,
        licenses: action.payload
      };
    }
    case StationDiseaseActionTypes.SET_STATION_DISEASE_WARNINGS: {
      return {
        ...state,
        warnings: action.payload
      };
    }
    case StationDiseaseActionTypes.SET_STATION_DISEASE_LOADING: {
      return {
        ...state,
        isLoading: action.payload
      };
    }
    case StationDiseaseActionTypes.SET_STATION_DISEASE_DATA: {
      return {
        ...state,
        grid: action.payload.grid,
        charts: action.payload.chartsOptions
      };
    }
    case StationDiseaseActionTypes.SET_STATION_DISEASE_ERROR: {
      return {
        ...state,
        isError: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

function addWarning(warnings: IDiseaseWarning[], warning: IDiseaseWarning): IDiseaseWarning[] {
  warnings.push(warning);
  return warnings;
}
