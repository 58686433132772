<button [ngClass]="[color, width]"
        [class.shadow]="shadow"
        [disabled]="disabled"
        [hidden]="hidden"
        class="custom-button"
        [style.border-radius]="radius"
        [ngStyle]="customCSS"
        [tooltip]="tooltip | translate"
        [attr.type]="type">
  <ng-content></ng-content>
  {{ content | translate }}
  <span *ngIf="caret"  class="caret"></span>
</button>
