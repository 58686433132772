import {IActionWithPayload} from '../models/actionWithPayload';
import {
  IAddRemoveStation, IAddRemoveVirtualStation, IPosition, IStation, IWarningsResponseItem
} from '../models/stations';

export const enum StationsActionTypes {
  GET_STATIONS = '[Account] GET_STATIONS',
  SET_STATIONS = '[Account] SET_STATIONS',
  CHANGE_STATION = '[Account] CHANGE_STATION',
  ADD_STATION = '[Account] ADD_STATION',
  REMOVE_STATION = '[Account] REMOVE_STATION',
  DELETE_STATION = '[Account] DELETE_STATION',
  ADD_VIRTUAL_STATION = '[Account] ADD_VIRTUAL_STATION',
  REMOVE_VIRTUAL_STATION = '[Account] REMOVE_VIRTUAL_STATION',
  CHANGE_VIRTUAL_STATION_ELEVATION = '[Account] CHANGE_VIRTUAL_STATION_ELEVATION',
  SET_VIRTUAL_STATION_ELEVATION = '[Account] SET_VIRTUAL_STATION_ELEVATION',
  CHANGE_VIRTUAL_STATION_TIMEZONE = '[Account] CHANGE_VIRTUAL_STATION_TIMEZONE',
  SET_VIRTUAL_STATION_TIMEZONE = '[Account] SET_VIRTUAL_STATION_TIMEZONE',
  GET_WARNINGS = '[Account] GET_WARNINGS',
  SET_WARNINGS = '[Account] SET_WARNINGS',
  SET_UNIT_SYSTEM = '[Account] SET_UNIT_SYSTEM'
}

export function addStation(add: IAddRemoveStation): IActionWithPayload {
  return { type: StationsActionTypes.ADD_STATION, payload: add };
}

export function removeStation(remove: IAddRemoveStation): IActionWithPayload {
  return { type: StationsActionTypes.REMOVE_STATION, payload: remove };
}

export function addVirtualStation(add: IAddRemoveVirtualStation): IActionWithPayload {
  return { type: StationsActionTypes.ADD_VIRTUAL_STATION, payload: add };
}

export function removeVirtualStation(remove: IAddRemoveVirtualStation): IActionWithPayload {
  return { type: StationsActionTypes.REMOVE_VIRTUAL_STATION, payload: remove };
}

export function changeVirtualStationElevation(position: IPosition): IActionWithPayload {
  return { type: StationsActionTypes.CHANGE_VIRTUAL_STATION_ELEVATION, payload: position };
}

export function setVirtualStationElevation(elevation: number): IActionWithPayload {
  return { type: StationsActionTypes.SET_VIRTUAL_STATION_ELEVATION, payload: elevation };
}

export function changeVirtualStationTimezone(position: IPosition): IActionWithPayload {
  return { type: StationsActionTypes.CHANGE_VIRTUAL_STATION_TIMEZONE, payload: position };
}

export function setVirtualStationTimezone(timezoneCode: string): IActionWithPayload {
  return { type: StationsActionTypes.SET_VIRTUAL_STATION_TIMEZONE, payload: timezoneCode };
}

export function deleteStation(id: string): IActionWithPayload {
  return { type: StationsActionTypes.DELETE_STATION, payload: id };
}

export function getStations(): IActionWithPayload {
  return { type: StationsActionTypes.GET_STATIONS, payload: null };
}

export function setStations(data: Array<IStation>): IActionWithPayload {
  return { type: StationsActionTypes.SET_STATIONS, payload: data };
}

export function changeStation(station: IStation): IActionWithPayload {
  return { type: StationsActionTypes.CHANGE_STATION, payload: station };
}

export function getWarnings(stationId?: string): IActionWithPayload {
  return { type: StationsActionTypes.GET_WARNINGS, payload: stationId };
}

export function setWarnings(warnings: IWarningsResponseItem[]): IActionWithPayload {
  return { type: StationsActionTypes.SET_WARNINGS, payload: warnings };
}

export function setUnitSystem(unitSystem: string): IActionWithPayload {
  return { type: StationsActionTypes.SET_UNIT_SYSTEM, payload: unitSystem };
}
