<section class="column"
        [ngClass]="[columnClass()]">

    <app-column-header (resize)="resize($event)"
                       *ngIf="isEdit"
                       (addWidget)="startAddWidget()"
                       (remove)="removeCurrentColumn()"
                       [columnIndex]="columnIndex"
                       [columnCount]="columnCount"></app-column-header>

    <app-widget-creation (addWidget)="addWidgetToStore($event)"
                         *ngIf="isAddWidget"
                         (closeWidget)="endAddWidget()">
    </app-widget-creation>

  <div class="widgets" [dragula]="'first-bag'" [dragulaModel]='column.items'>

    <app-empty-column *ngIf="!column.items.length && !isAddWidget"
                      (addWidget)="startAddWidget()"></app-empty-column>

    <ng-container *ngFor="let item of column.items; let i = index">
      <ng-container [ngSwitch]="item.name">
        <app-cropzones-widget *ngSwitchCase="'cropzone-list'"
                              [widget]="item"
                              [widgetIndex]="i"
                              [columnIndex]="columnIndex"
                              [rowIndex]="rowIndex"></app-cropzones-widget>
        <app-wind-rose-diagram-widget *ngSwitchCase="'wind-rose-diagram'"
                              [widget]="item"
                              [widgetIndex]="i"
                              [columnIndex]="columnIndex"
                              [rowIndex]="rowIndex"></app-wind-rose-diagram-widget>
        <app-chart-widget *ngSwitchCase="'sd-highcharts'"
                              [widget]="item"
                              [widgetIndex]="i"
                              [columnIndex]="columnIndex"
                              [rowIndex]="rowIndex"></app-chart-widget>
        <app-crop-view-widget *ngSwitchCase="'cropview'"
                              [widget]="item"
                              [widgetIndex]="i"
                              [columnIndex]="columnIndex"
                              [rowIndex]="rowIndex"></app-crop-view-widget>
        <app-data-widget *ngSwitchCase="'sd-data'"
                              [widget]="item"
                              [widgetIndex]="i"
                              [columnIndex]="columnIndex"
                              [rowIndex]="rowIndex"></app-data-widget>
        <app-disease-widget *ngSwitchCase="'sd-diseasemodels'"
                              [widget]="item"
                              [widgetIndex]="i"
                              [columnIndex]="columnIndex"
                              [rowIndex]="rowIndex"></app-disease-widget>
        <app-forecast-widget *ngSwitchCase="'sd-forecast'"
                              [widget]="item"
                              [widgetIndex]="i"
                              [columnIndex]="columnIndex"
                              [rowIndex]="rowIndex"></app-forecast-widget>
        <app-forecast-chart-widget *ngSwitchCase="'sd-forecast-chart'"
                                   [widget]="item"
                                   [widgetIndex]="i"
                                   [columnIndex]="columnIndex"
                                   [rowIndex]="rowIndex"></app-forecast-chart-widget>
        <app-forecast-table-widget *ngSwitchCase="'forecast-table'"
                             [widget]="item"
                             [widgetIndex]="i"
                             [columnIndex]="columnIndex"
                             [rowIndex]="rowIndex"></app-forecast-table-widget>
        <app-google-map-widget *ngSwitchCase="'google-map'"
                              [widget]="item"
                              [widgetIndex]="i"
                              [columnIndex]="columnIndex"
                              [rowIndex]="rowIndex"></app-google-map-widget>
        <app-iscout-widget *ngSwitchCase="'iscout'"
                              [widget]="item"
                              [widgetIndex]="i"
                              [columnIndex]="columnIndex"
                              [rowIndex]="rowIndex"></app-iscout-widget>
        <app-stations-list-widget *ngSwitchCase="'stations-list'"
                              [widget]="item"
                              [widgetIndex]="i"
                              [columnIndex]="columnIndex"
                              [rowIndex]="rowIndex"></app-stations-list-widget>
        <app-tracker-container *ngSwitchCase="'sd-trackers'"
                               [widget]="item"
                               [widgetIndex]="i"
                               [columnIndex]="columnIndex"
                               [rowIndex]="rowIndex"></app-tracker-container>
      </ng-container>
    </ng-container>
  </div>
</section>
