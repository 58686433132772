import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {from, Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {ApiCallService} from '../../../services/api/api-call.service';
import {CameraDataActionTypes, setCameraDataFromCropView, setCameraDataFromIscout} from '../actions/camera-data';
import {catchError} from 'rxjs/operators';
import {setNotify} from '../../../core/actions/notify';
import {setIscoutLoading} from '../../iscout/actions/iscout';
import {setCropViewLoading} from '../../crop-view/actions/crop-view';

@Injectable()
export class CameraDataService {
  constructor(private api: ApiCallService,
              private actions$: Actions) {
  }

  @Effect()
  public getCameraDataFromIscout$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CameraDataActionTypes.GET_CAMERA_DATA_FROM_ISCOUT),
    switchMap((action: IActionWithPayload) => this.api.getCameraDataFromIscout(action.payload).pipe(
      switchMap((result: any) => from([
        setCameraDataFromIscout(result),
        setIscoutLoading(false)
      ])),
      catchError(() => of(setNotify('Could not retrieve data from server')))
    ))
  );

  @Effect()
  public getCameraDataFromCropView$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CameraDataActionTypes.GET_CAMERA_DATA_FROM_CROPVIEW),
    switchMap((action: IActionWithPayload) => this.api.getCameraDataFromCropView(action.payload).pipe(
      switchMap((result: any) => from([
        setCameraDataFromCropView(result),
        setCropViewLoading(false)
      ])),
      catchError(() => of(setNotify('Could not retrieve data from server')))
    ))
  );
}
