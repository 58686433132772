import { Injectable } from '@angular/core';
import { ApiCallService } from '../../../services/api/api-call.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { from, Observable, of } from 'rxjs';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import {
  activateEditMode,
  changeDashboard,
  DashboardActionTypes,
  getDashboardList,
  reloadDashboard,
  restoreDashboard,
  setDashboardChanged,
  setDashboardList,
  setDashboardMeta,
  setDashboardName,
  setDashboardSettings,
  setWidgetData,
  setWidgetEditMode,
  setWidgetError,
  setWidgetLoading,
  setWidgetSettings
} from '../actions/dashboard';
import { IUserRequest } from '../../../core/models/userRequest';
import { setNotify } from '../../../core/actions/notify';
import { HttpErrorResponse } from '@angular/common/http';
import { Action, select, Store } from '@ngrx/store';
import * as fromDashboard from '../reducers/dashboard';
import { selectDashboard, selectId, selectName, selectSubdomain } from '../reducers';
import * as fromStations from '../../../core/reducers/stations';
import { selectStations } from '../../../core/reducers';
import { IColumn } from '../models/dashboard.models';
import { catchError, delay, exhaustMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { createAdamaDashboard, createDashboard, prepareDashboard } from '../utils/prepareDashboard';
import { RenewWidgetsTime } from '../constants/constants';
import { updateDashboard } from '../../../core/actions/account';

@Injectable()
export class DashboardService {

  constructor(
    private actions$: Actions,
    private api: ApiCallService,
    private dashboardStore: Store<fromDashboard.IDashboard>,
    private stationsStore: Store<fromStations.IStations>,
  ) {}

  @Effect()
  public getWidgetData: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(DashboardActionTypes.GET_WIDGET_DATA),
    mergeMap((loadData: IActionWithPayload) => this.api.getWidget(loadData.payload).pipe(
      switchMap((data: any) => from([
        setWidgetData({
          path: loadData.payload.path,
          loadData: data
        }),
        setWidgetLoading({
          path: loadData.payload.path,
          loading: false
        })
      ])),
      catchError((error: HttpErrorResponse) => from([
        setWidgetLoading({
          path: loadData.payload.path,
          loading: false
        }),
        setWidgetError({
          path: loadData.payload.path,
          error: error?.error?.message ? error?.error?.message : 'No data for selected station'
        })
      ]))
    ))
  );

  @Effect()
  public restoreDashboard$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(DashboardActionTypes.RESTORE_DASHBOARD),
    exhaustMap(() => this.api.getUser().pipe(
      mergeMap((user: IUserRequest) => from([
        setDashboardSettings(
          prepareDashboard(
            user.customizations.dashboard,
            ['loadData']
          )
        ),
        setDashboardName(fromDashboard.initialState.name),
        setDashboardMeta({ main: true }),
        setDashboardChanged(false),
        reloadDashboard({ main: true })
      ])),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public resetDashboard$: Observable<Action> = this.actions$.pipe(
    ofType(DashboardActionTypes.RESET_DASHBOARD),
    withLatestFrom(
      this.stationsStore.pipe(select(selectStations)),
      this.stationsStore.pipe(select(selectSubdomain))
    ),
    filter(([action, stations, subdomain]) => Array.isArray(stations)),
    map(([action, stations, subdomain]) => {

      let dashboard: Array<IColumn[]>;

      if (subdomain === 'adama') {
        dashboard = createAdamaDashboard(stations);
      } else {
        dashboard = createDashboard(stations);
      }

      return dashboard;
    }),
    mergeMap((dashboard: Array<IColumn[]>) => [
      setDashboardSettings(dashboard),
      setDashboardName(fromDashboard.initialState.name),
      setDashboardMeta({ main: true }),
      setDashboardChanged(false)
    ]),
    catchError(() => from([]))
  );

  @Effect()
  public addNewDashboard: Observable<Action> = this.actions$.pipe(
    ofType(DashboardActionTypes.ADD_NEW_DASHBOARD),
    withLatestFrom(
      this.stationsStore.pipe(select(selectStations)),
      this.stationsStore.pipe(select(selectSubdomain))
    ),
    filter(([action, stations, subdomain]) => Array.isArray(stations)),
    map(([action, stations, subdomain]) => {

      let dashboard: Array<IColumn[]>;

      if (subdomain === 'adama') {
        dashboard = createAdamaDashboard(stations);
      } else {
        dashboard = createDashboard(stations);
      }

      return dashboard;
    }),
    mergeMap((dashboard: Array<IColumn[]>) => [
      setDashboardSettings(dashboard),
      setDashboardName(fromDashboard.initialName),
      setDashboardMeta({ main: false }),
      activateEditMode(),
      setDashboardChanged(true)
    ]),
    catchError(() => from([]))
  );

  @Effect()
  public removeDashboard$: Observable<Action> = this.actions$.pipe(
    ofType(DashboardActionTypes.REMOVE_DASHBOARD),
    withLatestFrom(this.dashboardStore.pipe(select(selectId))),
    exhaustMap(([action, id]) => this.api.removeDashboard(id).pipe(
      mergeMap(() => [
        getDashboardList(),
        restoreDashboard()
      ]),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public getDashboardList$: Observable<Action> = this.actions$.pipe(
    ofType(DashboardActionTypes.GET_DASHBOARD_LIST),
    exhaustMap(() => this.api.listDashboards().pipe(
      mergeMap((data: Array<string>) =>
        of(setDashboardList(data))
      ),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public changeDashboard$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(DashboardActionTypes.CHANGE_DASHBOARD),
    switchMap((action: IActionWithPayload) => this.api.getDashboard(action.payload._id).pipe(
      mergeMap(data => from([
          setDashboardSettings(data.dashboard),
          setDashboardName(data.title),
          setDashboardMeta({ main: false, id: data._id }),
          setDashboardChanged(false),
          reloadDashboard(action.payload)
        ])
      ),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public saveDefault: Observable<Action> = this.actions$.pipe(
    ofType(DashboardActionTypes.SAVE_DEFAULT),
    withLatestFrom(this.dashboardStore.pipe(select(selectDashboard))),
    exhaustMap(([action, dashboard]) => this.api.saveDefaultDashboard(
      prepareDashboard(dashboard, ['loadData', 'isLoading', 'error'])
    ).pipe(
      mergeMap(() => [
        setNotify('Dashboard was successfully saved'),
        setDashboardChanged(false)
      ]),
      catchError((error: HttpErrorResponse) => {
        if (error.error.message !== 'No content') {
          return from([
            setNotify('Dashboard was not saved, due to the error')
          ]);
        } else {
          return from([
            setNotify('No changes have been made yet. Edit the dashboard before saving the changes.'),
            setDashboardChanged(false)
          ]);
        }
      })
    ))
  );

  @Effect()
  public saveDashboard$: Observable<Action> = this.actions$.pipe(
    ofType(DashboardActionTypes.SAVE_DASHBOARD),
    withLatestFrom(
      this.dashboardStore.pipe(select(selectName)),
      this.dashboardStore.pipe(select(selectId)),
    ),
    exhaustMap(([action, name, id]) => {

      let observable$: Observable<any>;

      if (typeof id === 'string') {
        observable$ = this.api.updateDashboard(name, action['payload'], id);
      } else {
        observable$ = this.api.createDashboard(name, action['payload']);
      }

      return observable$.pipe(
        mergeMap((result: string[]) => [
          getDashboardList(),
          setDashboardMeta({ main: false, id: result[0] }),
          setNotify('Dashboard was successfully saved'),
          setDashboardChanged(false)
        ]),
        catchError((error: HttpErrorResponse) => {
          if (error.error.message !== 'No content') {
            return from([
              setNotify('Dashboard was not saved, due to the error')
            ]);
          } else {
            return from([
              setNotify('No changes have been made yet. Edit the dashboard before saving the changes.'),
              setDashboardChanged(false)
            ]);
          }
        })
      );

    }),
  );

  @Effect()
  public updateWidgetSettings$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(DashboardActionTypes.UPDATE_WIDGET_SETTINGS),
    switchMap((action: IActionWithPayload) => from([
      setWidgetSettings(action.payload),
      setDashboardChanged(true)
    ])),
    catchError(() => from([]))
  );

  @Effect()
  public updateWidgetEditMode$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(DashboardActionTypes.UPDATE_WIDGET_EDIT_MODE),
    switchMap((action: IActionWithPayload) => from([
      setWidgetEditMode(action.payload),
      setDashboardChanged(true)
    ])),
    catchError(() => from([]))
  );

  @Effect()
  public renewDashboard$: Observable<Action | IActionWithPayload> = this.actions$.pipe(
    ofType(DashboardActionTypes.RELOAD_DASHBOARD),
    switchMap((action: IActionWithPayload) => of(action).pipe(
      delay(RenewWidgetsTime),
      map(() => {
        if (action.payload._id) {
          return changeDashboard(action.payload);
        } else if (action.payload.main) {
          return restoreDashboard();
        }
      })
    ))
  );
}
