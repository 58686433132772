import {IActionWithPayload} from '../../../core/models/actionWithPayload';

export const enum TrackerSettingsActionTypes {
  GET_TRACKER = '[MachinerySettings] GET_TRACKER',
  SET_TRACKER = '[MachinerySettings] SET_TRACKER',
  SAVE_TRACKER = '[MachinerySettings] SAVE_TRACKER',
  GET_ADDED_MACHINERY = '[MachinerySettings] GET_ADDED_MACHINERY',
  SET_ADDED_MACHINERY = '[MachinerySettings] SET_ADDED_MACHINERY',
  RESET_TRACKER_DATA = '[MachinerySettings] RESET_TRACKER_DATA',

  GET_TRACKER_SETTINGS = '[TrackerSettings] GET_TRACKER_SETTINGS',
  SAVE_TRACKER_SETTINGS = '[TrackerSettings] SAVE_TRACKER_SETTINGS',
}

export function getTracker(data: any): IActionWithPayload {
  return {type: TrackerSettingsActionTypes.GET_TRACKER, payload: data};
}

export function setTracker(data: any): IActionWithPayload {
  return {type: TrackerSettingsActionTypes.SET_TRACKER, payload: data};
}

export function saveTracker(data: any): IActionWithPayload {
  return {type: TrackerSettingsActionTypes.SAVE_TRACKER, payload: data};
}

export function getAddedMachinery(): IActionWithPayload {
  return {type: TrackerSettingsActionTypes.GET_ADDED_MACHINERY, payload: null};
}

export function setAddedMachinery(data: any): IActionWithPayload {
  return {type: TrackerSettingsActionTypes.SET_ADDED_MACHINERY, payload: data};
}

export function resetTrackerData(): IActionWithPayload {
  return {type: TrackerSettingsActionTypes.RESET_TRACKER_DATA, payload: null};
}

export function getTrackerSettings(data: any): IActionWithPayload {
  return {type: TrackerSettingsActionTypes.GET_TRACKER_SETTINGS, payload: data};
}

export function saveTrackerSettings(data: any): IActionWithPayload {
  return {type: TrackerSettingsActionTypes.SAVE_TRACKER_SETTINGS, payload: data};
}

