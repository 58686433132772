import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { setNotify } from '../../../../../core/actions/notify';
import * as fromNotify from '../../../../../core/reducers/notify';
import { IOptions, ISensor } from '../../../../../shared/interfaces';
import { saveSensor, updateSensor } from '../../../actions/sensors-and-nodes';
import { ELEVATION_SENSOR_CODE, PresetColors } from '../../../constants/constants';
import { IUpdateSensor } from '../../../models/sensors-and-nodes';
import * as fromSensorsAndNodes from '../../../reducers/sensors-and-nodes';
import { SensorsAndNodesHelpService } from '../../../services/sensors-and-nodes-help.service';

@Component({
  selector: '[app-sensor-row]',
  templateUrl: './sensor-row.component.html',
  styleUrls: ['./sensor-row.component.scss']
})
export class SensorRowComponent implements OnInit, OnDestroy {
  @Input()
  public sensor               : ISensor;
  @Input()
  public sensorName           : string;
  @Input()
  public nodeKey              : string;
  @Input()
  public stationId            : string;
  @Input()
  public nodeHasCalibration   : boolean;
  @Input()
  public calibrationOptions   : IOptions[]|null;

  public presetColors         : string[] = PresetColors;
  public form                 : FormGroup;
  public sensorOptions        : IOptions[];
  public elevationSensorCode  : number = ELEVATION_SENSOR_CODE;

  private destroy$            : Subject<boolean> = new Subject<boolean>();

  constructor(
      private sensorsStore    : Store<fromSensorsAndNodes.ISensorsAndNodesState>,
      private helpService     : SensorsAndNodesHelpService,
      private notifyStore     : Store<fromNotify.INotifyState>,
      private fb              : FormBuilder,
      private translations    : TranslateService) { }

  public ngOnInit(): void {
    this.form = this.fb.group({
      'color': [this.sensor.color, []],
      'name': [this.sensor.name_custom || '', []],
      'calibration': [
        this.calibrationOptions ? (
          this.sensor.calibration_id
            ? this.sensor.calibration_id
            : (this.calibrationOptions.find(option => option.data.default)
            ? this.calibrationOptions.find(option => option.data.default).value
            : '')
        ) : '',
        []
      ],
      'unit': [this.sensor.is_user_set.unit ? this.sensor.unit : '', []]
    });

    this.sensorOptions = this.getSensorOptions();


    this.form.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(100),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.sensorsStore.dispatch(updateSensor(this.prepareUpdateSensor()));
    });
  }

  private prepareUpdateSensor(isChanged: boolean = true): IUpdateSensor {
    return {
      nodeId: this.nodeKey,
      sensorId: this.sensorName,
      sensor: {
        unit: this.unitControl.value,
        color: this.colorControl.value,
        name_custom: this.nameControl.value,
        isChanged: isChanged,
        calibration_id: this.calibrationControl.value ? this.calibrationControl.value : undefined
      }
    };
  }

  public get colorControl(): AbstractControl {
    return this.form.get('color');
  }

  public get nameControl(): AbstractControl {
    return this.form.get('name');
  }

  public get calibrationControl(): AbstractControl {
    return this.form.get('calibration');
  }

  public get unitControl(): AbstractControl {
    return this.form.get('unit');
  }

  public colorChange(color: string): void {
    this.colorControl.setValue(color);
  }

  public updateSensor(): void {
    if (!this.sensor.isChanged) {
      this.notifyStore.dispatch(setNotify('No changes have been made yet'));
    } else {
      this.sensorsStore.dispatch(saveSensor({
        stationId: this.stationId,
        sensors: {
          save: [{
            unit: this.unitControl.value || false,
            color: this.colorControl.value,
            name: this.nameControl.value,
            channel: this.sensor.ch,
            code: this.sensor.code,
            calibration_id: this.calibrationControl.value ? this.calibrationControl.value : undefined
          }],
          update: [{
            ...this.helpService.prepareUpdateSensor(this.sensor, this.sensorName, this.nodeKey)
          }]
        }
      }));
    }
  }

  public getSensorOptions(): IOptions[] {
    const options: IOptions[] = [];
    if (this.sensor.unitConflict) {
      options.push({
        value: this.sensor.unit,
        content: this.sensor.unit
      });
    }
    if (this.sensor.unit_default) {
      this.translations.get('Default')
        .subscribe(translatedString => {
          options.push({
            value: '',
            content: this.sensor.unit_default + ' (' + translatedString + ')'
          });
        });
    }
    this.sensor.units.forEach((u: string) => {
      options.push({
        value: u,
        content: u
      });
    });
    return options;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
