import * as moment from 'moment';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IGetPhotosRequestFromTo, IGetPhotosRequests, IPicture} from '../../../shared/camera/models/camera';
import {ICameraSlideshowSettings} from '../models/camera-slideshow';

export const enum CameraSlideshowActions {
  GET_CAMERA_SLIDESHOW_LAST_DATE = '[CameraSlideshow] GET_CAMERA_SLIDESHOW_LAST_DATE',
  SET_CAMERA_SLIDESHOW_LAST_DATE = '[CameraSlideshow] SET_CAMERA_SLIDESHOW_LAST_DATE',
  GET_CAMERA_SLIDESHOW_PHOTOS = '[CameraSlideshow] GET_CAMERA_SLIDESHOW_PHOTOS',
  SET_CAMERA_SLIDESHOW_PHOTOS = '[CameraSlideshow] SET_CAMERA_SLIDESHOW_PHOTOS',
  SET_CAMERA_SLIDESHOW_LOADING = '[CameraSlideshow] SET_CAMERA_SLIDESHOW_LOADING',
  SET_CAMERA_SLIDESHOW_ERROR = '[CameraSlideshow] SET_CAMERA_SLIDESHOW_ERROR',
  SET_CAMERA_SLIDESHOW_SETTINGS = '[CameraSlideshow] SET_CAMERA_SLIDESHOW_SETTINGS',
}

export function getCameraSlideshowLastDate(stationId: string): IActionWithPayload {
  return {type: CameraSlideshowActions.GET_CAMERA_SLIDESHOW_LAST_DATE, payload: stationId};
}

export function setCameraSlideshowLastDate(lastDate: moment.Moment): IActionWithPayload {
  return {type: CameraSlideshowActions.SET_CAMERA_SLIDESHOW_LAST_DATE, payload: lastDate};
}

export function getCameraSlideshowPhotos(data: IGetPhotosRequestFromTo): IActionWithPayload {
  return {type: CameraSlideshowActions.GET_CAMERA_SLIDESHOW_PHOTOS, payload: data};
}

export function setCameraSlideshowPhotos(data: Array<IPicture>): IActionWithPayload {
  return {type: CameraSlideshowActions.SET_CAMERA_SLIDESHOW_PHOTOS, payload: data};
}

export function setCameraSlideshowLoading(status: boolean): IActionWithPayload {
  return {type: CameraSlideshowActions.SET_CAMERA_SLIDESHOW_LOADING, payload: status};
}

export function setCameraSlideshowError(status: boolean): IActionWithPayload {
  return {type: CameraSlideshowActions.SET_CAMERA_SLIDESHOW_ERROR, payload: status};
}

export function setCameraSlideshowSettings(data: ICameraSlideshowSettings): IActionWithPayload {
  return {type: CameraSlideshowActions.SET_CAMERA_SLIDESHOW_SETTINGS, payload: data};
}
