import { ICropZone } from '../models/cropzones';
import { IActionWithPayload } from '../models/actionWithPayload';
import { CropZoneActionTypes } from '../actions/cropzones';
import {deepClone} from '../../shared/utils/deepClone';

export interface ICropZones {
    cropzones: Array<ICropZone>;
    activeCropzoneRoute: string;
    isExecuting: boolean;
    ypmConstants?: any;
}

const initialState: ICropZones = {
    cropzones: [],
    activeCropzoneRoute: '',
    isExecuting: false,
    ypmConstants: {}
};

export function reducer(state: ICropZones = initialState, action: IActionWithPayload) : ICropZones {
    switch (action.type) {
        case CropZoneActionTypes.SET_CROPZONES: {
            return{
                ...state,
                cropzones: action.payload
            };
        }
        case CropZoneActionTypes.CHANGE_CROPZONE: {
            return {
                ...state,
                cropzones: cloneHelp(action.payload, state.cropzones)
            };
        }
        case CropZoneActionTypes.SET_CROPZONE: {
            return {
                ...state,
                cropzones: [
                    ...state.cropzones.filter(cropzone => cropzone.id !== action.payload.id),
                    action.payload
                ]
            };
        }
        case CropZoneActionTypes.ADD_DUPLICATED_CROPZONES: {
            const stateCropzones: ICropZone[] = deepClone(state.cropzones);
            const duplicatedCropzones: ICropZone[] = action.payload;

            duplicatedCropzones.forEach((cropzone) => {
                if (!stateCropzones.some((c) => c.id === cropzone.id)) {
                    stateCropzones.push((cropzone));
                }
            });

            return {
                ...state,
                cropzones: stateCropzones
            };
        }
        case CropZoneActionTypes.UPDATE_DUPLICATED_CROPZONES: {
            const stateCropzones: ICropZone[] = deepClone(state.cropzones);

            action.payload.forEach((cropzone) => {
                const index = stateCropzones.findIndex((c) => c.id === cropzone.id);
                stateCropzones[index] = cropzone;
            });

            return {
                ...state,
                cropzones: stateCropzones
            };
        }
        case CropZoneActionTypes.DELETE_CROPZONE: {
            return {
                ...state,
                cropzones: deleteCropzoneHelp(action.payload, state.cropzones)
            };
        }
        case CropZoneActionTypes.SET_CROPZONE_ROUTE: {
            return {
                ...state,
                activeCropzoneRoute: action.payload
            };
        }
        case CropZoneActionTypes.SET_EXECUTING_ADD_CROPZONE:
            return {
                ...state,
                isExecuting: action.payload
            };
        case CropZoneActionTypes.SET_YPM_CONSTANTS:
            return {
                ...state,
                ypmConstants: action.payload
            };
        default: {
            return state;
        }
    }
}

function cloneHelp(cropzone: ICropZone, oldCropzones: Array<ICropZone>): Array<ICropZone> {
    const cropzones: Array<ICropZone> = deepClone(oldCropzones);
    const index: number = cropzones.findIndex((c: ICropZone) => c.id === cropzone.id);
    if (index !== undefined) {
        cropzones[index] = cropzone;
    } else {
        cropzones.push(cropzone);
    }
    return cropzones;
}

function deleteCropzoneHelp(id: string, cropzones: ICropZone[]): ICropZone[] {
    const newCropzones: ICropZone[] = cropzones.filter((c: ICropZone) => c.id !== id);
    return deepClone(newCropzones);
}

