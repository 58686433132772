<app-sample-layout>
  <app-sample-header *ngIf="!this.status.isLoading"
                     title="SoilGuard Device">
  </app-sample-header>

  <ng-container *ngIf="data">
    <app-sample-map [samples]="data"
                    [activeMeter]="activeMeter"
                    [legend]="true">
    </app-sample-map>
  </ng-container>

  <ng-container *ngIf="dates">
    <app-sample-toolbar [dates]="dates"
                        [interestValues]="interestValues"
                        (activateTable)="isTableActive = $event">
    </app-sample-toolbar>
  </ng-container>

  <ng-container *ngIf="!status.isLoading && !status.isError; else loadInfo">
    <div class="soil-guard-content">
      <app-sample-table [columnDefs]="columnDefs"
                        [samples]="data"
                        [isTableActive]="isTableActive">
      </app-sample-table>
    </div>
  </ng-container>

  <ng-template #loadInfo>
    <app-station-load-info [isError]="status.isError"
                           [isLoading]="status.isLoading">
    </app-station-load-info>
  </ng-template>

</app-sample-layout>
