import {INode, ISubNode} from './models';
import {ApiServiceType} from '../../modules/user-api-services/constants/api-services';

export const STATION_SETTINGS_TREE_NODES: Array<INode> = [
  {
    name: 'Configuration',
    iconClass: 'zmdi-wrench',
    link: '/config/'
  },
  {
    name: 'Sensors and nodes',
    iconClass: 'zmdi-label',
    link: '/sensor-names/'
  },
  {
    name: 'Models',
    iconClass: 'zmdi icon-fc-disease-models',
    link: '/models/'
  },
  {
    name: 'Station SMS Warnings',
    iconClass: 'zmdi-shield-check',
    link: '/sms-warnings'
  },
  {
    name: 'Notifications',
    iconClass: 'zmdi-notifications',
    link: '/server-alerts'
  },
  {
    name: 'Information',
    iconClass: 'zmdi-info',
    link: '/info'
  },
  {
    name: 'Delete Weather Data',
    iconClass: 'zmdi-delete',
    link: '/delete-weather-data/'
  },
  {
    name: 'Correct Precipitation Data',
    iconClass: 'icon-fc-rain-corrector',
    link: '/correct-precipitation-data'
  },
  {
    name: 'Ultrasonic Distance Settings',
    iconClass: 'icon-fc-ultrasonic-distance-settings',
    link: '/ultrasonic-distance-settings'
  }
];

export const IRRIMET_TREE_NODES: Array<INode> = [
  {
    name: 'Calendar',
    iconClass: 'icon-fc-irrigation-scheduling',
    link: '/irrimet'
  },
  {
    name: 'Water Balance',
    iconClass: 'icon-fc-water-level',
    link: '/irrimet/available-water-mm'
  },
  {
    name: 'Water Balance as PAW (%)',
    iconClass: 'icon-fc-water-level',
    link: '/irrimet/paw'
  }/*,
  {
    name: 'Chronology',
    link: '/chronology'
  }*/
];

export const CROP_ZONE_SOIL_MOISTURE_TREE_NODES: Array<INode> = [
  {
    name: 'Soil Moisture Sum',
    iconClass: 'icon-fc-irrigation-scheduling',
    link: '/soil-moisture/sums-sm'
  }
];

export const CROP_ZONE_YIELD_PREDICTION_TREE_NODES: Array<INode> = [
  {
    name: 'Yield Prediction',
    iconClass: 'icon-fc-yp-graph',
    link: '/yield-prediction/prediction'
  },
  {
    name: 'Previous Yields',
    iconClass: 'icon-fc-previous-yield',
    link: '/yield-prediction/previous'
  }
];

export const CROP_ZONE_SATELLITE_TREE_NODES: Array<INode> = [
  {
    name: 'Leaf Area Index',
    iconClass: 'fa fa-leaf',
    link: '/sat/lai'
  },
  {
    name: 'Normalized Difference Vegetation Index',
    iconClass: 'fa fa-leaf',
    link: '/sat/ndvi'
  }
];

export const CROPZONE_SETTINGS_TREE_NODES: Array<INode> = [
  {
    name: 'General',
    iconClass: 'zmdi-wrench',
    link: '/config/'
  },
  {
    name: 'Irrimet',
    iconClass: 'zmdi-wrench',
    link: '/irrimet-config'
  },
  {
    name: 'Soil moisture',
    iconClass: 'zmdi-wrench',
    link: '/soil-moisture-config'
  },
  {
    name: 'Yield Prediction',
    iconClass: 'zmdi-wrench',
    link: '/yield-prediction-config'
  },
];

export const TRACKER_SETTINGS_TREE_NODES: Array<INode> = [
  {
    name: 'Configuration',
    iconClass: 'zmdi-wrench',
    link: '/config/'
  }/* ,
  {
    name: 'Machinery settings',
    iconClass: 'zmdi-info',
    link: '/machinery-settings'
  } */
];

export const WEATHER_FORECAST_DETAILS: ISubNode = {
  id: 'Details',
  name: 'Details',
  iconClass: 'zmdi-chart',
  link: '/weather-forecast/data/',
  nodes: [],
};

export const WEATHER_FORECAST_METEOONE: ISubNode = {
  id: 'Meteo-One',
  name: 'Meteo One',
  iconClass: 'icon-fc-weather-forecast',
  link: '/weather-forecast/meteogram_one/',
  nodes: [],
};

export const WEATHER_FORECAST_METEOAGRO: ISubNode = {
  id: 'Meteo-Agro',
  name: 'Meteo Agro',
  iconClass: 'zmdi-flower-alt',
  link: '/weather-forecast/meteogram_agro/',
  nodes: [],
};

export const WEATHER_FORECAST_PICTOPRINT: ISubNode = {
  id: 'Pictoprint',
  name: 'Pictoprint',
  iconClass: 'fa fa-dot-circle-o',
  link: '/weather-forecast/pictoprint/',
  nodes: [],
};

export const WEATHER_FORECAST_DAYS_FORECAAST: ISubNode = {
  id: '14days-forecast',
  name: '14 days forecast',
  iconClass: 'fa fa-line-chart',
  link: '/weather-forecast/meteogram_14day/',
  nodes: [],
};

export const WEATHER_FORECAST_WEATHER_MAP: ISubNode = {
  id: 'Weather-Maps',
  name: 'Weather Maps',
  iconClass: 'icon-fc-precipitation-radar',
  link: '/weather-forecast/precipitation-radar/',
  nodes: [],
};

export const WEATHER_FORECAST_HISTORY_CLIMATE: ISubNode = {
  id: 'history-climate',
  name: 'History & Climate',
  iconClass: 'icon-fc-history-climate-black',
  link: '/weather-forecast/history-climate',
  nodes: [
    {
      id: 'risk-assessment',
      name: 'Risk Assessment'
    },
    {
      id: 'climate-comparison',
      name: 'Climate Comparison'
    }
    ]
  };

  export const WEATHER_FORECAST_TREE_NODES: Array<ISubNode> = [
      WEATHER_FORECAST_DETAILS,
      WEATHER_FORECAST_METEOONE,
      WEATHER_FORECAST_METEOAGRO,
      WEATHER_FORECAST_PICTOPRINT,
      WEATHER_FORECAST_DAYS_FORECAAST,
      WEATHER_FORECAST_WEATHER_MAP,
      WEATHER_FORECAST_HISTORY_CLIMATE
  ];

export const ACCUMULATOR_TOOL_TREE_NODES: Array<INode> = [
  {
    name: 'Degree days',
    iconClass: 'icon-fc-degree-days',
    link: '/accumulator-tool/degree-days'
  },
  {
    name: 'Chilling units',
    iconClass: 'icon-fc-chilling-units',
    link: '/accumulator-tool/chilling-units'
  },
  {
    name: 'Rain Sum',
    iconClass: 'icon-fc-rain-sum',
    link: '/accumulator-tool/rain-sum'
  },
  {
    name: 'Asparagus growth model',
    iconClass: 'icon-fc-asparagus',
    link: '/accumulator-tool/asparagus-growth-model'
  }
];

// todo: how to implement it without the second redirect and once for all devices?
export const SOIL_GUARD_TREE_NODES: Array<INode> = [
  {
    name: 'Raw mission data',
    iconClass: 'fa fa-database',
    link: '/samples'
  }
];

export const WP_PLANT_NUTRITION_SERVICE: ISubNode = {
  id: 'plant-nutrition',
  name: 'Plant nutrition',
  iconClass: 'icon-fc-fertilize',
  link: '/work-planning/plant-nutrition',
  nodes: [],
};

export const WP_FIELD_ACCESSIBILITY_SERVICE: ISubNode = {
  id: 'field-accessibility',
  name: 'Field accessibility',
  iconClass: 'icon-fc-field-accessibility',
  link: '/work-planning/field-accessibility',
  nodes: [],
};

export const WP_TILLAGE_ABILITY_SERVICE: ISubNode = {
  id: 'tillage-ability',
  name: 'Tillage ability',
  iconClass: 'icon-fc-tillage-ability',
  link: '/work-planning/tillage-ability',
  nodes: [],
};

export const WP_SOWING_WINDOW_SERVICE: ISubNode = {
  id: 'sowing-window',
  name: 'Sowing window',
  iconClass: 'icon-fc-sowing',
  link: '/work-planning/sowing-window',
  nodes: [
    {
      id: 'general',
      name: 'General'
    },
    {
      id: 'potato',
      name: 'Potato',
    },
    {
      id: 'sugarbeet',
      name: 'Sugarbeet',
    },
    {
      id: 'corn',
      name: 'Corn',
    },
    {
      id: 'canola',
      name: 'Canola',
    },
    {
      id: 'sunflower',
      name: 'Sunflower',
    },
    {
      id: 'cotton',
      name: 'Cotton',
    }
  ],
};

export const WP_TROPICAL_SOWING_WINDOW_SERVICE: ISubNode = {
  id: 'tropical-sowing-window',
  name: 'Tropical sowing window',
  iconClass: 'icon-fc-tropical-sowing',
  link: '/work-planning/tropical-sowing-window',
  nodes: [
    {
      id: 'general',
      name: 'General'
    },
  ],
};

export const WP_PLANT_PROTECTION_SERVICE: ISubNode = {
  id: 'plant-protection',
  name: 'Spraying window',
  iconClass: 'icon-fc-plant-protection',
  link: '/work-planning/plant-protection',
  nodes: [],
};

export const WP_HARVEST_WINDOW: ISubNode = {
  id: 'harvest-window',
  name: 'Harvest window',
  iconClass: 'icon-fc-hay-sillage',
  link: '/work-planning/harvest-window',
  nodes: [
    {
      id: 'fruit-grape',
      name: 'Fruit and Grape',
    },
    {
      id: 'sugar-beet-potato',
      name: 'Sugar Beet & Potato',
    },
    {
      id: 'combine-cereals',
      name: 'Small Cereals',
    },
    {
      id: 'hay-silage',
      name: 'Hay and silage preparation',
    }
  ],
};

export const WP_GRASS_FIRE_DANGER_INDEX_SERVICE: ISubNode = {
  id: 'grass-fire-danger-index',
  name: 'Grass fire danger index',
  iconClass: 'zmdi zmdi-fire',
  link: '/work-planning/grass-fire-danger-index',
  nodes: []
};

export const WORK_PLANNING_TREE_NODES: Array<ISubNode> = [
  WP_PLANT_NUTRITION_SERVICE,
  WP_FIELD_ACCESSIBILITY_SERVICE,
  WP_TILLAGE_ABILITY_SERVICE,
  WP_SOWING_WINDOW_SERVICE,
  WP_TROPICAL_SOWING_WINDOW_SERVICE,
  WP_PLANT_PROTECTION_SERVICE,
  WP_HARVEST_WINDOW,
  WP_GRASS_FIRE_DANGER_INDEX_SERVICE,
];

export const ANIMAL_PRODUCTION_TREE_NODES: Array<ISubNode> = [
  {
    id: 'dairy-stress',
    name: 'Dairy stress',
    iconClass: 'icon-fc-diary',
    link: '/animal-production/dairy-stress',
    nodes: []
  },
  {
    id: 'poultry-stress',
    name: 'Poultry stress',
    iconClass: 'icon-fc-poultry',
    link: '/animal-production/poultry-stress',
    nodes: []
  }
];

export const CROP_VIEW_TREE_NODES: Array<INode> = [
  {
    name: 'CropVIEW',
    iconClass: 'fa fa-leaf',
    link: '/cropview'
  },
  {
    name: 'Monitoring data',
    iconClass: 'fa fa-bar-chart',
    link: '/camera-data'
  },
  {
    name: 'Slideshow',
    iconClass: 'fa fa-film',
    link: '/camera-slideshow'
  }
];


export const ISCOUT_TREE_NODES: Array<INode> = [
  {
    name: 'iScout',
    iconClass: 'fa fa-bug',
    link: '/iscout'
  },
  {
    name: 'iScout Insects',
    iconClass: 'fa fa-tags',
    link: '/iscout/pests'
  },
  {
    name: 'iScout Glue Boards',
    iconClass: 'fa fa-square',
    link: '/iscout/glue-boards'
  },
  {
    name: 'iScout Seasons',
    iconClass: 'fa fa-calendar',
    link: '/iscout/seasons'
  },
  {
    name: 'Monitoring data',
    iconClass: 'fa fa-bar-chart',
    link: '/camera-data'
  },
  {
    name: 'Slideshow',
    iconClass: 'fa fa-film',
    link: '/camera-slideshow'
  }
];

export const ISCOUT_TREE_NODES_PROGLU: Array<INode> = [
  {
    name: 'ProGlu Digital',
    iconClass: 'fa fa-bug',
    link: '/iscout'
  },
  {
    name: 'ProGlu Insects',
    iconClass: 'fa fa-tags',
    link: '/iscout/pests'
  },
  {
    name: 'ProGlu Digital Glue Boards',
    iconClass: 'fa fa-square',
    link: '/iscout/glue-boards'
  },
  {
    name: 'ProGlu Seasons',
    iconClass: 'fa fa-calendar',
    link: '/iscout/seasons'
  },
  {
    name: 'Monitoring data',
    iconClass: 'fa fa-bar-chart',
    link: '/camera-data'
  },
  {
    name: 'Slideshow',
    iconClass: 'fa fa-film',
    link: '/camera-slideshow'
  }
];

export const TRACKER_TREE_NODES: Array<INode> = [];

export const USER_API_SERVICES_TREE_NODES: Array<INode> = [
  {
    name: 'FieldClimate',
    iconClass: 'fa fa-leaf',
    link: `/user-api-services/${ApiServiceType.FIELD_CLIMATE}`
  },
  {
    name: 'My John Deere',
    iconClass: 'fa fa-shield',
    link: `/user-api-services/${ApiServiceType.MY_JOHN_DEERE}`
  },
  /*{
    name: 'Farm Beats',
    iconClass: 'fa fa-windows',
    link: `/user-api-services/${ApiServiceType.FARM_BEATS}`
  },*/
  {
    name: 'WeatherLink',
    iconClass: 'fa icon-fc-weatherlink',
    link: `/user-api-services/${ApiServiceType.DAVIS}`
  },
  {
    name: 'Xarvio',
    iconClass: 'fa fa-map-marker',
    link: `/user-api-services/${ApiServiceType.XARVIO}`
  }
];
