import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { AgGridModule } from 'ag-grid-angular';
import { StationDataExportService } from '../../shared/services/export/station-data-export.service';
import { AccumulatorToolComponent } from './accumulator-tool.component';
import { AccumulatorToolDegreeDaysComponent } from './components/accumulator-tool-degree-days/accumulator-tool-degree-days.component';
import { AccumulatorToolChillingUnitsComponent } from './components/accumulator-tool-chilling-units/accumulator-tool-chilling-units.component';
import { AccumulatorToolHeaderComponent } from './components/accumulator-tool-header/accumulator-tool-header.component';
import { AccumulatorToolHelpModalComponent } from './components/accumulator-tool-help-modal/accumulator-tool-help-modal.component';
import { AccumulatorToolTableComponent } from './components/accumulator-tool-table/accumulator-tool-table.component';
import { AccumulatorToolChartComponent } from './components/accumulator-tool-chart/accumulator-tool-chart.component';
import { AccumulatorToolContentComponent } from './containers/accumulator-tool-content/accumulator-tool-content.component';
import { AccumulatorToolRoutingModule } from './accumulator-tool-routing.module';
import { AccumulatorToolRainSumComponent } from './components/accumulator-tool-rain-sum/accumulator-tool-rain-sum.component';
import { AccumulatorToolAsparagusGrowthModelComponent } from './components/accumulator-tool-asparagus-growth-model/accumulator-tool-asparagus-growth-model.component';
import { AccumulatorToolChartsComponent } from './components/accumulator-tool-charts/accumulator-tool-charts.component';
import { SampleDataService } from '../cropzone-sample/services/sample-data.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AccumulatorToolReducer } from './reducers/reducers';
import { AccumulatorToolEffects } from './effects/effects';

@NgModule({
  imports: [
    CommonModule,
    AccumulatorToolRoutingModule,
    SharedModule,
    TranslateModule.forChild(),
    ChartModule,
    AgGridModule.withComponents([]),
    StoreModule.forFeature('accumulatorTool', AccumulatorToolReducer),
    EffectsModule.forRoot([AccumulatorToolEffects])
  ],
  declarations: [
    AccumulatorToolComponent,
    AccumulatorToolHeaderComponent,
    AccumulatorToolHelpModalComponent,
    AccumulatorToolContentComponent,
    AccumulatorToolDegreeDaysComponent,
    AccumulatorToolChillingUnitsComponent,
    AccumulatorToolTableComponent,
    AccumulatorToolChartComponent,
    AccumulatorToolRainSumComponent,
    AccumulatorToolAsparagusGrowthModelComponent,
    AccumulatorToolChartsComponent
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [ more, exporting ] }, // add as factory to your providers
    StationDataExportService,
    SampleDataService,
  ]
})
export class AccumulatorToolModule { }
