import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IStations} from '../../../reducers/stations';
import {selectSelectedStation, selectStations} from '../../../reducers';
import {select, Store} from '@ngrx/store';
import {IStation} from '../../../models/stations';
import {Observable} from 'rxjs';
import {ISearchWidgetItem} from '../../../components/search-widget/list-item/search-widget-list-item.component';
import {map} from 'rxjs/operators';
import {stationsOrderSettings} from './constants/constants';
import {IOrderSettingItem} from '../../../components/search-widget/models/search-widget.models';
import {ISelectedSearchWidgetItem} from '../../../models/selectedSearchWidgetItem';
import {getStations} from '../../../actions/stations';
import {STATION_LIST_REFRESH_TIMER} from '../../../../shared/constants';

@Component({
  selector: 'app-right-stations',
  templateUrl: './right-stations.component.html',
})

export class RightStationsComponent implements OnInit {
  public orderSettings: Array<IOrderSettingItem> = stationsOrderSettings;
  @Input()
  public stations: IStation[];
  public stations$: Observable<Array<ISearchWidgetItem>> = this.store.pipe(
    select(selectStations),
    map((stations: Array<IStation>): Array<ISearchWidgetItem> => this.getStationsList(stations))
  );

  @Input()
  public stationsAreOpened: boolean = false;

  @Output()
  public closeBar         : EventEmitter<null> = new EventEmitter<null>();

  public selectedStation$: Observable<ISelectedSearchWidgetItem>;

  constructor(private store: Store<IStations>) {
    setInterval(() => {
      this.store.dispatch(getStations());
    }, STATION_LIST_REFRESH_TIMER);
  }

  public ngOnInit(): void {
    this.subscribeToSelectedStation();
  }

  private subscribeToSelectedStation(): void {
    this.selectedStation$ = this.store.pipe(
      select(selectSelectedStation)
    );
  }

  private getStationsList(stations: Array<IStation>): Array<ISearchWidgetItem> {
    return stations
      ? stations.map((station: IStation): ISearchWidgetItem => {
        return {
          info_name: station.info.device_name,
          original_name: station.name.original,
          custom_name: station.name.custom,
          forecast_licenses: station.licenses.Forecast,
          model_licenses: station.licenses.models && station.licenses.models.length > 0,
          last_communication: station.dates.last_communication,
          rights: station.rights,
          starred: station.starred
        };
      })
      : [];
  }
}
