import {IActionWithPayload} from '../models/actionWithPayload';
import {ICropzoneBasicInfo} from '../models/navigation-cropzone';
import {NavigationCropzoneActionTypes} from '../actions/navigation-cropzone';
import {deepClone} from '../../shared/utils/deepClone';
import {defaultCropzone} from '../constants/default-cropzone';
import { ICropZone } from '../models/cropzones';

export interface INavigationCropzoneState {
  cropzone: ICropZone;
  // cropzoneBasicInfo: ICropzoneBasicInfo;
  // navigationAccess: INavigationAccess;
  notFound: boolean;
}

const initialState: INavigationCropzoneState = deepClone(defaultCropzone);

export function reducer(state: INavigationCropzoneState = initialState, action: IActionWithPayload ): INavigationCropzoneState {
  switch (action.type) {
    case NavigationCropzoneActionTypes.SET_NAVIGATION_CROPZONE: {
      return {
        ...state,
        ...action.payload
      };
    }

    /*
    case NavigationCropzoneActionTypes.SET_NAVIGATION_CROPZONE_BASE_NAME: {
      return {
        ...state,
        cropzone: {
          ...state.cropzone,
          name: {
            ...state.cropzone.name,
            custom: action.payload
          }
        },
        cropzoneBasicInfo: {
          ...state.cropzoneBasicInfo,
          customName: action.payload
        }
      };
    }
    // TODO: changing the state triggers other actions to load
    case NavigationCropzoneActionTypes.SET_TRACKER_SETTINGS: {
      return {
        ...state,
        cropzone: {
          ...state.cropzone,
          config: {
            ...state.cropzone.config,
            trip_delimiter: action.payload
          }
        },
      };
    } */
    default: {
      return state;
    }
  }
}
