import {
  SearchWidgetListCropZoneItemComponent
} from '../../components/search-widget/list-cropzone-item/search-widget-list-cropzone-item.component';
import { AddFarmFormComponent } from './add-farm/add-farm-form/add-farm-form.component';
import {CommonModule} from '@angular/common';
import {MainStaticRoutingModule} from './main-static-routing.module';
import {SharedModule} from '../../../shared/shared.module';
import {MainStaticComponent} from './main-static.component';
import {HeaderComponent} from './header/header.component';
import {LeftBarComponent} from './left-bar/left-bar.component';
import {NgModule} from '@angular/core';
import {RightStationsComponent} from './right-stations/right-stations.component';
import {RightCropZonesComponent} from './right-cropzones/right-cropzones.component';
import {TranslateModule} from '@ngx-translate/core';
import { AgmCoreModule } from '@agm/core';
import {SearchWidgetComponent} from '../../components/search-widget/search-widget.component';
import {SearchWidgetInputComponent} from '../../components/search-widget/search-input/search-widget-input.component';
import {SearchWidgetOrderButtonComponent} from '../../components/search-widget/order-button/search-widget-order-button.component';
import {SearchWidgetListItemComponent} from '../../components/search-widget/list-item/search-widget-list-item.component';
import {UserMenuComponent} from './user-menu/user-menu.component';
import { AddRemoveStationComponent } from './add-remove-station/add-remove-station.component';
import { AddRemoveStationFormComponent } from './add-remove-station/add-remove-station-form/add-remove-station-form.component';
import { SearchWidgetCropZonesComponent } from '../../components/search-widget-cropzones/search-widget-cropzones.component';
import { AddCropZoneComponent } from './add-farm/add-cropzone.component';
import { AddCropzoneFormComponent } from './add-farm/add-cropzone-form/add-cropzone-form.component';
import { AddFieldFormComponent } from './add-farm/add-field-form/add-field-form.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { RemoveCropzoneFormComponent } from './remove-cropzone/remove-cropzone-form/remove-cropzone-form.component';
import { RemoveFarmFormComponent } from './remove-cropzone/remove-farm-form/remove-farm-form.component';
import { RemoveFieldFormComponent } from './remove-cropzone/remove-field-form/remove-field-form.component';
import { RemoveCropZoneComponent } from './remove-cropzone/remove-cropzone.component';
import { RemoveFieldConfirmationComponent } from './remove-cropzone/remove-field-confirmation/remove-field-confirmation.component';
import { RemoveFarmConfirmationComponent } from './remove-cropzone/remove-farm-confirmation/remove-farm-confirmation.component';
import { AddRemoveVirtualStationComponent } from './add-remove-virtual-station/add-remove-virtual-station.component';
import { AddRemoveVirtualStationFormComponent } from './add-remove-virtual-station/add-remove-virtual-station-form/add-remove-virtual-station-form.component';
import { environment } from '../../../../assets/themes/default/configuration';
import { NotificationsComponent } from './notifications/notifications.component';
import {DEFAULT_LANG, GoogleMapLibraries} from '../../../shared/constants';
import {ProductLicenseModule} from './product-license/product-license.module';
import { DataUploadComponent } from './data-upload/data-upload.component';
import { LeftBarNewTextComponent } from './left-bar-new-text/left-bar-new-text.component';

@NgModule({
  imports: [
    CommonModule,
    MainStaticRoutingModule,
    SharedModule,
    AgmCoreModule.forRoot({
      apiKey: environment.gMapKey,
      libraries: GoogleMapLibraries,
      language: localStorage.getItem('language') || DEFAULT_LANG
    }),
    TranslateModule.forChild(),
    TypeaheadModule,
    ProductLicenseModule
  ],
  declarations: [
    MainStaticComponent,
    HeaderComponent,
    LeftBarComponent,
    SearchWidgetComponent,
    SearchWidgetInputComponent,
    SearchWidgetOrderButtonComponent,
    SearchWidgetListItemComponent,
    RightStationsComponent,
    RightCropZonesComponent,
    UserMenuComponent,
    AddRemoveStationComponent,
    AddRemoveStationFormComponent,
    RemoveCropzoneFormComponent,
    RemoveFarmFormComponent,
    RemoveFieldFormComponent,
    RemoveCropZoneComponent,
    RemoveFieldConfirmationComponent,
    RemoveFarmConfirmationComponent,
    SearchWidgetCropZonesComponent,
    AddCropZoneComponent,
    AddCropzoneFormComponent,
    AddFieldFormComponent,
    AddFarmFormComponent,
    SearchWidgetListCropZoneItemComponent,
    AddRemoveVirtualStationComponent,
    AddRemoveVirtualStationFormComponent,
    NotificationsComponent,
    DataUploadComponent,
    LeftBarNewTextComponent
  ]
})
export class MainStaticModule { }
