<section>
  <ng-content></ng-content>

  <div class="container-fluid">
    <ng-container *ngIf="pictures && pictures.length; else emptyDataMessage">
      <div class="row picture">
        <div *ngFor="let picture of pictures"
             class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
             [class.active]="isActivePicture(picture)"
             (click)="selectPicture(picture)">
          <div class="picture-wrapper pictureBorder">
            <span class="amount-label">
              {{ 'Detections:' | translate }} {{ getTotalDetections(picture) }}
            </span>
            <span class="icon-label">
              <i *ngIf="isGoodQuality(picture)" class="fa fa-thumbs-o-up" style="color: greenyellow"></i>
              <i *ngIf="isBadQuality(picture)" class="fa fa-thumbs-o-down" style="color: red"></i>
            </span>
            <img src="{{picture.thumb}}" alt="{{picture.filename}}"/>
            <div class="date-label">
              {{ toLocalDate(picture.time) }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #emptyDataMessage>
      <h4 class="empty-data-message">{{ 'No data for selected date' | translate }}</h4>
    </ng-template>
  </div>
</section>
