<div *ngIf="index >= 0 && notEmpty && value.date !== 'legend'" class="lazy-list-item-container" style="position: relative;" #container>
  <div class="lazy-list-item-container__header" #header>
    <span class="lazy-list-item-container__header__date">
      {{ value.date | date: 'dd-MM-yyyy' }}
    </span>
    <span *ngIf=pending class="lazy-list-item-container__header__loading">
      .. loading ..
    </span>
    <span class="lazy-list-item-container__header__data">
      {{ 'Resolution' | translate }}
      <span *ngIf="resolution === 10" class="label lightgreen">{{ resolution + "m" }}</span>
      <span *ngIf="resolution > 10" class="label orange">{{ resolution + "m" }}</span>
    </span>
  </div>
  <div class="lazy-list-item-container__content" id="{{ 'render' + index }}" #contentContainer (click)="openFullResModal()" [hidden]="isLoading"></div>
  <div *ngIf="!isLoading">
    <button (click)="downloadImage()"  style="position: absolute; bottom: 10%; right: 5px;"><i class="fa fa-download" [hidden]="isLoading"></i></button>
  </div>
  <div [ngClass]="isLoading ? 'lazy-list-item-container__content displayTable' : 'lazy-list-item-container__content displayNone' ">
    <div class="loadingDiv">
      <h5><i class="fa fa-cog fa-spin fa-fw"></i> {{ 'Please wait, loading data...' | translate }}</h5>
    </div>
  </div>
</div>

<div *ngIf="index > 0 && notEmpty && value.date === 'legend'" class="lazy-list-item-container" id="legendContainer" style="position: relative; margin: 0; padding: 10px 5px 0px 5px;" #legendContainer>
  <div id="{{ 'legendSVG' + index }}" #svgDiv></div>
</div>