import { ICropZone } from '../../../core/models/cropzones';

export function createEmptyCropzone(): ICropZone {
  return {
    name: '',
    crop_name: '',
    from: '',
    to: '',
    field: {
      name: '',
      id: ''
    },
    farm: {
      name: '',
      id: ''
    },
    id: '',
    year: '',
    boundary: null,
    soil: null,
    crop: null,
    rain_efficacies: [],
    data_sources: [],
    irrigation_events: []
  };
}

