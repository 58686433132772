import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserLicensesComponent} from './user-licenses.component';
import {UserLicensesListResolver} from './containers/user-licenses-list/user-licenses-list.resolver';

const routes: Routes = [
  { path: '', component: UserLicensesComponent, resolve: {translatedHeaders: UserLicensesListResolver} }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
  providers: [UserLicensesListResolver]
})
export class UserLicensesRoutingModule { }
