<app-irrimet-calendar-header [(view)]="view" [(viewDate)]="viewDate" [locale]="locale"></app-irrimet-calendar-header>
<!-- Custom month template -->
<ng-template #customCellTemplate let-day="day" let-locale="locale" class="inheritMaxWidth">
  <div class="cal-cell-top" (click)="createOnMonthView(day)">
    <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
    <div *ngIf="day.events.length > 0" class="paddingLR10">
      {{calculateAmount(day)}} <p style="display: inline;" *ngIf="unitSystem === 'metric'">mm</p><p style="display: inline;" *ngIf="unitSystem === 'imperial'">in</p>
    </div>
  </div>

  <div *ngIf="day.events.length > 0">
    <div *ngIf="(day.events | dayIrrigationEventsPipe).length === 1">
      <button class="eventButton multipleEventButton" (click)="openEventListModalFunction(day, 'irrigation', false)">{{ (day.events | dayIrrigationEventsPipe).length }} {{ 'irrigation event on this day.' | translate }}</button>
    </div>
    <div *ngIf="(day.events | dayIrrigationEventsPipe).length > 1">
      <button class="eventButton multipleEventButton" (click)="openEventListModalFunction(day, 'irrigation', false)">{{ (day.events | dayIrrigationEventsPipe).length }} {{ 'irrigation events on this day.' | translate }}</button>
    </div>
    <div *ngIf="(day.events | splitDayIrrigaitonEvents).length === 1">
      <button class="splitEventButton" (click)="openEventListModalFunction(day, 'irrigation', true)">{{ (day.events | splitDayIrrigaitonEvents).length }} {{ 'split irrigation event on this day.' | translate }}</button>
    </div>
    <div *ngIf="(day.events | splitDayIrrigaitonEvents).length > 1">
      <button class="splitEventButton" (click)="openEventListModalFunction(day, 'irrigation', true)">{{ (day.events | splitDayIrrigaitonEvents).length }} {{ 'split irrigation events on this day.' | translate }}</button>
    </div>
    <!-- Manual rain gauge events-->
    <div *ngIf="(day.events | dayManualEventsPipe).length === 1">
      <button class="rainEventButton multipleEventButton" (click)="openEventListModalFunction(day, 'manual')">1 {{ 'rain event on this day.' | translate }}</button>
    </div>
    <div *ngIf="(day.events | dayManualEventsPipe).length > 1">
      <button class="rainEventButton multipleEventButton" (click)="openEventListModalFunction(day, 'manual')">{{ (day.events | dayManualEventsPipe).length }} {{ 'rain events on this day.' | translate }}</button>
    </div>
  </div>
</ng-template>

<!-- Custom week template -->
<ng-template #weekViewHoursSegmentTemplate let-segment="segment" let-locale="locale" let-segmentHeight="segmentHeight"
             let-isTimeLabel="isTimeLabel">
  <div #segmentElement class="cal-hour-segment" [style.height.px]="segmentHeight"
       [class.cal-hour-start]="segment.isStart" [class.cal-after-hour-start]="!segment.isStart"
       [ngClass]="segment.cssClass" (click)="createOnWeekView(segment)">
    <div class="cal-time" *ngIf="isTimeLabel">
      {{segment.date | calendarDate: 'weekViewHour': locale}}
    </div>
  </div>
</ng-template>

<ng-template #weekViewEventTemplate let-weekEvent="weekEvent" let-locale="locale">
  <div class="cal-event noPBM fullWidth" (click)="editEvent(weekEvent.event)">
    <div class="fullWidth">
      <button class="eventButton leftEventButton textAlignL fullWidth"
              (click)="editEvent(weekEvent.event)">{{weekEvent.event.title}}</button>
    </div>

  </div>
</ng-template>

<div [ngSwitch]="view">
  <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="calendarEvents$ | async"
                           [cellTemplate]="customCellTemplate" [locale]="locale">
  </mwl-calendar-month-view>
  <mwl-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate" [events]="calendarEvents$ | async"
                          [eventTemplate]="weekViewEventTemplate" [locale]="locale" (hourSegmentClicked)="createOnWeekView($event)">
  </mwl-calendar-week-view>

  <mwl-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate" [events]="calendarEvents$ | async" [locale]="locale"
                         (hourSegmentClicked)="createOnWeekView($event)" [eventTemplate]="weekViewEventTemplate">
  </mwl-calendar-day-view>
</div>
