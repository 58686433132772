<ng-container *ngIf="selectedStation">
  <app-station-disease-warning *ngFor="let warning of (warnings$ | async)"
                               [warning]="warning"></app-station-disease-warning>
  <div class="station-disease-content__head">
    <app-station-disease-header></app-station-disease-header>
    <app-station-disease-settings (exportChartImg)="exportCharts($event)"></app-station-disease-settings>
  </div>
  <div *ngIf="(isLoading$ | async) || !(isExportActive$ | async); else data">
    <app-station-load-info [isLoading]="isLoading$ | async"
                           [isError]="!(isExportActive$ | async)"></app-station-load-info>
  </div>
  <ng-template #data>
    <app-station-disease-charts class="station-disease-content__chart"
                                [exportChartImg]="exportChartImg"></app-station-disease-charts>
    <app-station-disease-table></app-station-disease-table>
  </ng-template>
</ng-container>
