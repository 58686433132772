import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, pluck, takeUntil } from 'rxjs/operators';
import { CameraPeriodScopes, DefaultPeriodScope } from '../../core/constants/camera';
import { IStation } from '../../core/models/stations';
import { selectNavigationStation } from '../../core/reducers';
import { INavigationStationState } from '../../core/reducers/navigation-station';
import { NavigationService } from '../../core/services/navigation/navigation.service';
import { ICameraRefresh, IFromTo, IToolbarSettings } from '../camera/models/camera';
import { IOptions } from '../interfaces';
import { getMonthFromDate, getWeekFromDate } from '../utils/dateFormat';

type PhotoCurrentDate = string | IFromTo;

@Component({
  selector: 'app-custom-image-gallery-toolbar',
  templateUrl: './custom-image-gallery-toolbar.component.html',
  styleUrls: ['./custom-image-gallery-toolbar.component.scss'],
})
export class CustomImageGalleryToolbarComponent implements OnInit, OnDestroy {
  @Input()
  public stationOptions: Array<IOptions> = [];
  @Input()
  public extraOptions$: Observable<Array<IOptions>>;
  @Input()
  public stationMinDate$: Observable<moment.Moment>;
  @Input()
  public stationMaxDate$: Observable<moment.Moment>;
  @Input()
  public toolbarSettings$: Observable<IToolbarSettings>;
  @Input()
  public currentDate$: Observable<PhotoCurrentDate>;
  @Input()
  public enableIntervalSearch: boolean = false;
  @Input()
  public extraOptionScopes: Array<IOptions> = [];
  @Input()
  public extraOptionSelected: IOptions;
  @Input()
  public extraOptionsEnabled: boolean = false;

  @Output()
  public refreshEmitter: EventEmitter<ICameraRefresh> = new EventEmitter<ICameraRefresh>();
  @Output()
  public toolbarChangeEmitter: EventEmitter<IToolbarSettings> = new EventEmitter<IToolbarSettings>();
  @Output()
  public extraOptionsLoaderEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  public extraOptionsSelectedEmitter: EventEmitter<IOptions> = new EventEmitter<IOptions>();

  public periodOptions: Array<IOptions> = CameraPeriodScopes;
  public rangeArrowMin: moment.Moment = moment();
  public stationId: string;
  public form: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private formBuilder: FormBuilder,
              private navigationStationStore: Store<INavigationStationState>,
              private router: Router,
              private navigationService: NavigationService) { }

  public ngOnInit(): void {
    this.createForm();
    this.initToolbarSettings();
    this.initSelectedStationListener();
    this.initFirstAndLastDates();
    this.initCurrentDateChange();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      'settings': this.formBuilder.group({
        'periodScope': [null, [Validators.required]],
        'fromTo': [null, [Validators.required]],
        'datepicker': [null, [Validators.required]],
        'stationId': ['', [Validators.required]],
        'isLastDataMode': [null, [Validators.required]]
      }),
      'activity': this.formBuilder.group({
        'areMeasurementsActive': [null, [Validators.required]],
        'isHelpActive': [null, [Validators.required]],
        'isSaveInfoActive': [null],
        'isUpdateInfoActive': [null],
      })
    });

    this.form.valueChanges.pipe(
      debounceTime(200),
      takeUntil(this.destroy$),
    ).subscribe((changes): void => {
      this.toolbarChangeEmitter.emit(<IToolbarSettings>changes);
    });
  }

  private initToolbarSettings(): void {
    if (this.extraOptionsEnabled) {
      this.periodOptions = CameraPeriodScopes.concat(this.extraOptionScopes);
    }

    this.toolbarSettings$.subscribe((toolbarSettings: IToolbarSettings): void => {
      this.settingsControl.setValue(toolbarSettings.settings);
      this.activityControl.setValue(toolbarSettings.activity);
    });
  }

  private initSelectedStationListener(): void {
    this.navigationStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((station: IStation): boolean => !!station),
      pluck('name', 'original'),
    ).subscribe((stationId: string): void => {
      this.stationId = stationId;
      this.stationIdControl.setValue(this.stationId);
      // Must trigger a glue board search each time the station is changed
      if (this.extraOptionsEnabled) {
        this.extraOptionsLoaderEmitter.emit(stationId);
      }
    });

    this.stationIdControl.valueChanges.pipe(
      debounceTime(200),
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      filter((stationId: string): boolean => this.stationId !== stationId)
    ).subscribe((stationId: string): void => {
      this.router.navigate([
        this.navigationService.prepareNavigateToUrl(stationId)
      ]);
    });
  }

  private initFirstAndLastDates(): void {
    combineLatest([
      this.currentDate$,
      this.stationMinDate$
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([currentDate, stationMinDate]: [PhotoCurrentDate, moment.Moment]): void => {
      if (typeof currentDate === 'string') {
        const periodUnit = currentDate.includes('W') ? 'week' : 'month';

        this.rangeArrowMin = stationMinDate
          ? stationMinDate.clone().subtract(1, periodUnit)
          : moment(currentDate);

        this.fromToControl.setValue({
          from: null,
          to: moment(currentDate)
        });
      }
    });
  }

  private initCurrentDateChange(): void {
    this.currentDate$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((currentDate: string | IFromTo): void => {
      if (typeof currentDate === 'string') {
        this.datepickerControl.setValue(currentDate);
      } else if (!this.isExtraOptionEnabled()) {
        this.fromToControl.setValue(currentDate);
      }
    });
  }

  private get settingsControl(): AbstractControl {
    return this.form.get('settings');
  }

  public get periodScopeControl(): AbstractControl {
    return this.settingsControl.get('periodScope');
  }

  public get fromToControl(): AbstractControl {
    return this.settingsControl.get('fromTo');
  }

  public get datepickerControl(): AbstractControl {
    return this.settingsControl.get('datepicker');
  }

  public get stationIdControl(): AbstractControl {
    return this.settingsControl.get('stationId');
  }

  private get activityControl(): AbstractControl {
    return this.form.get('activity');
  }

  public get isHelpActiveControl(): AbstractControl {
    return this.activityControl.get('isHelpActive');
  }

  public get formatForDatePicker(): string {
    return this.periodScopeControl.value === DefaultPeriodScope.value ? 'YYYY-MM' : 'W/YYYY';
  }

  public onRefresh(): void {
    const payload = {
      scope: this.periodScopeControl.value,
      value: null
    };

    if (this.isExtraOptionEnabled()) {
      payload.value = this.fromToControl.value.data;
    } else if (this.enableIntervalSearch) {
      payload.value = this.fromToControl.value;
    } else {
      payload.value = this.currentDateString;
    }

    this.refreshEmitter.emit(payload);
  }

  private get currentDateString(): string {
    return this.prepareCurrentDateString(this.fromToControl.value.to);
  }

  private prepareCurrentDateString(date: Date): string {
    return this.periodScopeControl.value === DefaultPeriodScope.value
      ? getMonthFromDate(moment(date))
      : getWeekFromDate(moment(date));
  }

  public onToggleHelp(): void {
    this.isHelpActiveControl.setValue(!this.isHelpActiveControl.value);
  }

  public onExtraOptionSelected($event: IOptions): void {
    this.extraOptionsSelectedEmitter.emit($event);
  }

  public isExtraOptionEnabled(): boolean {
    const searchFilter = (option): boolean => option.value === this.periodScopeControl.value;
    const isExtraOptionSelected = this.extraOptionScopes.find(searchFilter) !== undefined;
    return this.extraOptionsEnabled && isExtraOptionSelected;
  }

  public getDate(momentObject: moment.Moment): Date {
    return new Date(momentObject.toString());
  }
}
