<div [@.disabled]="accountCreated">

  <div class="auth-container" *ngIf="!accountCreated"
       [@formRouterAnimation]
       [@errorFormAnimation]="errorAnimation">

    <app-form-layout
      [minHeight]="835.33"
      [size]="themeConfig?.subDomain !== 'vodafone-idea' ? 'login' : 'login-bigger'">

      <form [formGroup]="registerForm" (ngSubmit)="register()" #registerform="ngForm">

        <div class="form-item">
          <app-logo [type]="'register'"></app-logo>
        </div>

        <alert type="warning" *ngIf="errorMsg">
          {{ errorMsg | translate }}
        </alert>

        <div class="center head-info">
          {{ 'LOGIN INFO' | translate }}
        </div>

        <div class="form-item">
          <app-form-input [placeholder]="'Username'" [icon]="'account'" [formControl]="username"></app-form-input>
          <div class="error" *ngIf="username.hasError('required') && registerform.submitted">
            <p>{{ 'You have to enter username' | translate }}</p>
          </div>
          <div class="error" *ngIf="usernameExist">
            <p>{{ 'Entered Username already exist' | translate}}</p>
          </div>
        </div>

        <div class="form-item">
          <app-form-input [isAutocompleteOn]="false" [placeholder]="'Password (min 5 characters)'" [icon]="'password'"
                          [formControl]="password" [contentType]="'password'"></app-form-input>
          <div class="error" *ngIf="password.hasError('minlength') && !password.pristine">
            <p>{{ 'Password must be at least 5 characters long' | translate }}</p>
          </div>
          <div class="error" *ngIf="password.hasError('required') && registerform.submitted">
            <p>{{ 'You have to enter password' | translate }}</p>
          </div>
        </div>

        <div class="form-item">
          <app-form-input [isAutocompleteOn]="false" [placeholder]="'Confirm Password (min 5 characters)'"
                          [icon]="'password'" [formControl]="passwordConfirm"
                          [contentType]="'password'"></app-form-input>
          <div class="error" *ngIf="passwordConfirm.hasError('minlength') && !passwordConfirm.pristine">
            <p>{{ 'Password must be at least 5 characters long' | translate }}</p>
          </div>
          <div class="error" *ngIf="passwordConfirm.hasError('required') && registerform.submitted">
            <p>{{ 'You have to enter password' | translate }}</p>
          </div>
          <div class="error" *ngIf="password.value !== passwordConfirm.value && !password.pristine">
            <p>{{ 'Password mismatch' | translate }}</p>
          </div>
        </div>

        <div class="center head-info">
          {{ 'BASIC INFO' | translate }}
        </div>

        <div class="user-data">
          <div class="form-item">
            <app-form-input [placeholder]="'First name'" [formControl]="firstName"></app-form-input>
            <div class="error" *ngIf="firstName.hasError('required')  && registerform.submitted">
              <p>{{ 'You have to enter a first name' | translate }}</p>
            </div>
          </div>

          <div class="form-item">
            <app-form-input [placeholder]="'Last name'" [formControl]="lastName"></app-form-input>
            <div class="error" *ngIf="lastName.hasError('required') && registerform.submitted">
              <p>{{ 'You have to enter a last name' | translate }}</p>
            </div>
          </div>

          <div class="form-item">
            <app-form-input [placeholder]="'Email with format: example@test.com'"
                            [formControl]="email"></app-form-input>
            <div class="error" *ngIf="email.hasError('required') && registerform.submitted">
              <p>{{ 'You have to enter an email' | translate }}</p>
            </div>
            <div class="error" *ngIf="!email.valid && !email.pristine">
              <p>{{ 'Email must be in correct format' | translate }}</p>
            </div>
          </div>
        </div>

        <div class="form-item">
          <app-form-select [type]="'border'" [icon]="'language'" [options]="languages"
                           [formControl]="language"></app-form-select>
        </div>

        <div class="form-item">
          <app-form-select [type]="'border'" [icon]="'country'" [options]="countries"
                           [formControl]="country"></app-form-select>
        </div>

        <div class="checkbox-items"
             *ngIf="themeConfig?.subDomain !== 'telus'">

          <div class="form-item">
            <div class="custom-checkbox">
              <app-custom-checkbox [formControl]="terms">
                {{ 'Please review the following terms and conditions before you sign up for FieldClimate services. By checking the box you indicate your agreement.' | translate }}
                <a target="_blank" href="https://metos.at/en/terms-and-conditions/">{{ 'Terms' | translate }} & {{ 'Privacy Statement' | translate }}</a>
              </app-custom-checkbox>
            </div>
            <div class="error checkbox-error" *ngIf="(terms.value === false) && registerform.submitted">
              <p>{{ 'You have to agree with terms and conditions' | translate }}</p>
            </div>
          </div>

          <div class="form-item captcha">
            <div class="captcha-container">
              <re-captcha (resolved)="resolved()" [siteKey]="siteKey"></re-captcha>
            </div>
            <div class="error" *ngIf="(captcha.value === false) && registerform.submitted">
              <p>{{ 'You have to check reCAPTCHA' | translate }}</p>
            </div>
          </div>

        </div>

        <div class="center">
          <a class="link btn" routerLink="/login">
            {{ 'BACK' | translate }}
          </a>
        </div>

        <app-form-button [disabled]="disableForm"></app-form-button>

      </form>

    </app-form-layout>

  </div>

</div>

<div class="auth-container" *ngIf="accountCreated" [@formRouterAnimation]>

  <app-form-layout
    [size]="themeConfig?.subDomain !== 'vodafone-idea' ? 'login' : 'login-bigger'">

    <div class="form-item">
      <app-logo [type]="'register'"></app-logo>
    </div>

    <alert type="success">
      {{ 'User successfully created! Please check your email to activate your account' | translate }}
    </alert>

    <div class="button-container">
      <app-custom-button [width]="'long'" [content]="'Login'" (click)="goToLogin()"></app-custom-button>
    </div>
    <div class="button-container">
      <app-custom-button [color]="'orange'" [width]="'long'" [content]="'Register another user'"
                         (click)="showRegisterForm()"></app-custom-button>
    </div>

  </app-form-layout>

</div>
