import { map, exhaustMap, catchError, mergeMap } from 'rxjs/operators';
import { ApiCallService } from '../../../services/api/api-call.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import {
  collectConfigData,
  IrrimetConfigActionTypes,
  setCropsLazyLoading,
  setDatasourcesET0,
  setDatasourcesRain
} from '../actions/irrimet-config.action';
import { EMPTY, from } from 'rxjs';
import { setNotify } from '../../../core/actions/notify';

@Injectable()
export class IrrimetConfigEffect {

  constructor(
    private actions$: Actions,
    private api: ApiCallService
  ) {

  }

  @Effect()
  public getCropsLazyLoading$ = this.actions$.pipe(
    ofType(IrrimetConfigActionTypes.GET_CROPS_LAZY_LOADING),
    exhaustMap(() => this.api.getCrops().pipe(
      map((crops) => setCropsLazyLoading(crops)),
      catchError(() => EMPTY)
    ))
  );

  @Effect()
  public getDatasourcesRain$ = this.actions$.pipe(
    ofType(IrrimetConfigActionTypes.GET_DATASOURCES_RAIN),
    exhaustMap((action: IActionWithPayload) =>
      this.api.getDataSource(action.payload).pipe(
        map(data => setDatasourcesRain(data)),
        catchError(() => EMPTY)
      )
    )
  );

  @Effect()
  public getDatasourcesEto$ = this.actions$.pipe(
    ofType(IrrimetConfigActionTypes.GET_DATASOURCES_ETO),
    exhaustMap((action: IActionWithPayload) =>
      this.api.getDataSource(action.payload).pipe(
        map(data => setDatasourcesET0(data)),
        catchError(() => EMPTY)
      )
    )
  );

  @Effect()
  public updateCropzone$ = this.actions$.pipe(
    ofType(IrrimetConfigActionTypes.UPDATE_CROPZONE),
    exhaustMap((action: IActionWithPayload) =>
      this.api.updateCropzone(action.payload).pipe(
        mergeMap(response => [
          collectConfigData(false),
          setNotify('Irrimet settings successfully saved')
        ]),
        catchError(() => from([
          collectConfigData(true),
          setNotify('Can\'t define soil profile')
        ]))
      )
    )
  );

}
