import { Injectable } from '@angular/core';
import { IWorkPlanningLicenses } from '../models/work-planning.models';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { IWorkPlanningState } from './../models/work-planning.models';
import { setWarningMessage } from '../actions';
import { IFromTo } from '../../tracker/models/tracker.models';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkPlanningService {
  private pleaseNoteString: string;
  private daysToRenew: string;
  private licenseExpiredOn: string;
  private toRenew: string;

  constructor(
    private translate: TranslateService,
    private store: Store<IWorkPlanningState>
  ) {
    this.getTranslatedStrings();
  }

  public setWarningForLicense(service: string, licenses: IWorkPlanningLicenses): boolean {
    let serviceLicenses: Array<IFromTo> = licenses.workPlanningLicenses;
    if (service === 'tropical-sowing-window') {
      serviceLicenses = licenses.tropicalSowingLicenses;
    } else if (service === 'grass-fire-danger-index') {
      serviceLicenses = licenses.grassFireDangerLicenses;
    }

    const maximumDates: any[] = serviceLicenses.map((license) => moment(license.to));
    const licenseEndDate: moment.Moment = moment.max(maximumDates);
    const now: moment.Moment = moment();
    const daysUntilEndLicense: number = licenseEndDate.diff(now, 'days');

    let warningMessage: string|null = null;
    const icon: boolean = true;
    let validLicense: boolean = true;

    if (serviceLicenses.length > 0) {
      if (daysUntilEndLicense <= 0) {
        warningMessage = this.licenseExpiredOn + ' ' + licenseEndDate.format('DD-MM-YYYY') + '. ' + this.toRenew;
        validLicense = false;
      } else if (daysUntilEndLicense <= 10) {
        warningMessage = this.pleaseNoteString + ' ' + daysUntilEndLicense + ' ' + this.daysToRenew;
      }
    }

    this.store.dispatch(setWarningMessage(warningMessage, icon));
    return validLicense;
  }

  private getTranslatedStrings(): void {
    this.translate.get('Your license will expire in').pipe(take(1)).subscribe((translatedText: string) => {
      this.pleaseNoteString = translatedText;
    });
    this.translate.get('days. To renew it, please contact your METOS salesperson.').pipe(take(1)).subscribe((translatedText: string) => {
      this.daysToRenew = translatedText;
    });
    this.translate.get('Your license has expired on').pipe(take(1)).subscribe((translatedText: string) => {
      this.licenseExpiredOn = translatedText;
    });
    this.translate.get('To renew it, please contact your METOS salesperson.').pipe(take(1)).subscribe((translatedText: string) => {
      this.toRenew = translatedText;
    });
  }
}
