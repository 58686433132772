import { Component, Input, OnDestroy, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { IStation } from '../../../../core/models/stations';
import { Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { ILoggingAndTransferState, ILoggingIsDefault } from '../../reducers/loggingAndTransfer';
import { LoggingSettingsTwoComponent } from './logging-settings-two/logging-settings-two.component';
import { SchedulerTwoComponent } from './scheduler-two/scheduler-two.component';
import { IAdvancedSettings, ILoggingSave, ILoggingSaveObj, ILoggingTransferDevice, parseScheduler, parseSchedulerString } from '../../models/station-config.models';
import { AdvancedDevices, LoggingTransferGroups } from '../../constants/constants';
import { StationTypes } from '../../../../shared/constants';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { deepClone } from '../../../../shared/utils/deepClone';
import { saveLogging } from '../../actions/loggingAndTransfer';
import { deleteServerAlert, getServerAlerts } from '../../../server-alerts/actions/server-alerts';
import { IServerAlert, IServerAlertData, IServerTarget } from '../../../server-alerts/interfaces/server-alert';
import { IAccount } from '../../../../core/reducers/account';
import { takeUntil, filter } from 'rxjs/operators';
import { selectSettings } from '../../../../core/reducers';
import { ISettings } from '../../../../core/models/account';
import * as fromServerAlerts from '../../../server-alerts/reducers/server-alerts';
import { selectServerAlerts } from '../../../server-alerts/reducers';
import { addServerAlert } from '../../../notifications-configuration/actions/alerts';
import { ApiCallService } from '../../../../services/api/api-call.service';

@Component({
  selector: 'app-logging-and-transfer-two',
  templateUrl: './logging-and-transfer-two.component.html',
  styleUrls: ['./logging-and-transfer-two.component.scss']
})
export class LoggingAndTransferTwoComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public selectedStation: IStation;
  @ViewChild('settings') public settingsComponent: LoggingSettingsTwoComponent;
  @ViewChild('scheduler') public schedulerComponent: SchedulerTwoComponent;

  public defaults: ILoggingIsDefault = {settingsAreDefault: false, schedulerIsDefault: false};
  public changedSettingsAreDefault: boolean;
  public modalId: string = generateId();
  private readonly noCropViewHeader : string = 'Logging and transfer settings';
  private readonly cropViewHeader   : string = 'Transfer settings';
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public state: ILoggingAndTransferState;
  public deviceGroup: ILoggingTransferDevice;
  public frostEnabled: boolean = false;
  public unitSystem: string = '';
  private alerts: IServerAlert[];

  constructor(
    private loggingStore: Store<ILoggingAndTransferState>,
    private modalService: ModalService,
    private accountStore: Store<IAccount>,
    private serverAlertsStore: Store<fromServerAlerts.IServerAlertsState>,
    private apiCallService: ApiCallService
  ) { }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // tslint:disable-next-line:max-line-length
    if (changes.selectedStation && changes.selectedStation.currentValue.name.original !== changes.selectedStation.previousValue?.name.original) {
      this.serverAlertsStore.dispatch(getServerAlerts(this.selectedStation.name.original));
      this.deviceGroup = LoggingTransferGroups.filter((group) => group.deviceIDs.includes(this.selectedStation.info.device_id))[0];
      this.state = this.setLoggingTransferState();

      this.accountStore.pipe(
        takeUntil(this.destroy$),
        select(selectSettings),
        filter((settings: ISettings) => !!settings)
      ).subscribe((settings: ISettings) => {
        this.unitSystem = settings.unit_system;
      });

      this.serverAlertsStore.pipe(
        select(selectServerAlerts),
        takeUntil(this.destroy$)
      ).subscribe((alerts: IServerAlert[]) => {
        this.alerts = alerts;
      });
    }
  }

  private setLoggingTransferState(): ILoggingAndTransferState {
    const config = this.selectedStation.config;
    const state: ILoggingAndTransferState = {
      config: {
        scheduler: config.scheduler ? parseScheduler(config.scheduler) : null,
        logging_interval: config.logging_interval ? config.logging_interval : null,
        fixed_transfer_interval: (config.fixed_transfer_interval !== null && config.fixed_transfer_interval !== undefined)
          ? config.fixed_transfer_interval
          : null,
        activity_mode: (config.activity_mode !== null && config.activity_mode !== undefined) ? config.activity_mode : null,
        rain_monitor: config.rain_monitor,
        measuring_interval: config.measuring_interval ? config.measuring_interval : null,
        transfer_interval: config.transfer_interval ? config.transfer_interval : null,
        network_registration_timeout: (config.network_registration_timeout !== null && config.network_registration_timeout !== undefined)
          ? config.network_registration_timeout
          : null,
        led: config.flash ? config.flash : 0.
      },
      isIScout: this.checkIScout(this.selectedStation.info.device_id),
      isCropView: this.checkCropView(this.selectedStation.info.device_id),
      isAdvanced: this.checkAdvanced(this.selectedStation.info.device_id),
      showAdvanced: this.checkAdvanced(this.selectedStation.info.device_id)
    };

    if (config.frost_monitoring) {
      this.frostEnabled = config.frost_monitoring.enabled;
      state.config = {
        ...state.config,
        frost_monitoring_enabled: config.frost_monitoring.enabled,
        frost_monitoring_threshold: config.frost_monitoring.threshold,
        frost_monitoring_sensor_code: config.frost_monitoring.sensor_code,
        frost_monitoring_sensor_channel: config.frost_monitoring.sensor_channel,
        frost_monitoring_interval: config.frost_monitoring.interval,
      };
    } else {
      this.frostEnabled = config.frost_monitoring_enabled;
      state.config = {
        ...state.config,
        frost_monitoring_enabled: config.frost_monitoring_enabled,
        frost_monitoring_threshold: config.frost_monitoring_threshold,
        frost_monitoring_sensor_code: config.frost_monitoring_sensor_code,
        frost_monitoring_sensor_channel: config.frost_monitoring_sensor_channel,
        frost_monitoring_interval: config.frost_monitoring_interval,
      };
    }

    return state;
  }

  private checkIScout(id: number):  boolean {
    return id === StationTypes.get('IScoutType')
      || id === StationTypes.get('IScoutMobileType');
  }

  private checkCropView(id: number): boolean {
    return id === StationTypes.get('CropViewType1')
    || id === StationTypes.get('CropViewType2')
    || id === StationTypes.get('CropViewType3');
  }

  private checkAdvanced(id: number): boolean {
    return AdvancedDevices.includes(id);
  }

  public changeMeasurementInterval(value: number): void {
    this.state = {
      ...this.state,
      config: {
        ...this.state.config,
        measuring_interval: value
      }
    };
  }

  public changeLoggingInterval(value: number): void {
    this.state = {
      ...this.state,
      config: {
        ...this.state.config,
        logging_interval: value
      }
    };
  }

  public changeFixedTransferInterval(value: number): void {
    this.state = {
      ...this.state,
      config: {
        ...this.state.config,
        fixed_transfer_interval: value,
        transfer_interval: value
      }
    };
  }

  public changeScheduler(scheduler: Array<Array<string>>): void {
    this.state = {
      ...this.state,
      config: {
        ...this.state.config,
        scheduler: scheduler
      }
    };
  }

  public changeAdvanced(advancedOptions: IAdvancedSettings): void {
    this.frostEnabled = advancedOptions.frost_monitoring_enabled;
    this.state = {
      ...this.state,
      config: {
        ...this.state.config,
        ...advancedOptions
      }
    };
  }

  public changeLEDFlash(flashIsOn: number): void {
    this.state = {
      ...this.state,
      config: {
        ...this.state.config,
        led: flashIsOn
      }
    };
  }

  public save(): void {
    const saveObj = {};
    const saveStation: IStation = deepClone(this.selectedStation);
    const config = this.state.config;

    if (config.scheduler && this.deviceGroup.scheduler) {
      saveObj['config.upload.scheduler'] = parseSchedulerString(config);
      saveStation.config.scheduler = parseInt(
        saveObj['config.upload.scheduler']
          .split('')
          .reverse()
          .join(''),
        2
      );
    }

    if (this.deviceGroup.measuring) {
      saveObj['config.interval.measuring'] = config.measuring_interval;
      saveStation.config.measuring_interval = config.measuring_interval;
    }
    if (this.deviceGroup.logging && saveStation.info.device_id !== 78) {
      saveObj['config.interval.logging'] = config.logging_interval;
      saveStation.config.logging_interval = config.logging_interval;
    }
    if (this.deviceGroup.fixedTransfer) {
      const isFixedIntervalStation = [6, 7, 8, 28].includes(this.selectedStation.info.device_id);
      if (isFixedIntervalStation) {
        saveObj['config.upload.transfer_fixed'] = config.fixed_transfer_interval;
        saveStation.config.fixed_transfer_interval = config.fixed_transfer_interval;
      } else {
        saveObj['config.interval.transfer'] = config.fixed_transfer_interval;
        saveStation.config.transfer_interval = config.fixed_transfer_interval;
      }
    }
    /* if (this.deviceGroup.led) {
      saveObj['config.flash'] = config.led;
      saveStation.config.flash = config.led;
    } */
    if (saveStation.info.device_id === 78) {
      saveObj['config.interval.transfer'] = config.transfer_interval;
      saveStation.config.transfer_interval = config.transfer_interval;
    }

    if (this.state.showAdvanced) {
      if (this.deviceGroup.stationActivity) {
        saveObj['config.activity_mode'] = config.activity_mode ? config.activity_mode : 0;
        saveStation.config.activity_mode = config.activity_mode ? config.activity_mode : 0;
      }
      if (this.deviceGroup.frost) {
        if (!saveStation.config.frost_monitoring) {
          saveStation.config.frost_monitoring = {};
        }
        saveObj['config.frost_monitoring.enabled'] = config.frost_monitoring_enabled;
        saveStation.config.frost_monitoring.enabled = config.frost_monitoring_enabled;
        saveObj['config.frost_monitoring.interval'] = config.frost_monitoring_interval;
        saveStation.config.frost_monitoring.interval = config.frost_monitoring_interval;
        saveObj['config.frost_monitoring.sensor_channel'] = config.frost_monitoring_sensor_channel;
        saveStation.config.frost_monitoring.sensor_channel = config.frost_monitoring_sensor_channel;
        saveObj['config.frost_monitoring.sensor_code'] = config.frost_monitoring_sensor_code;
        saveStation.config.frost_monitoring.sensor_code = config.frost_monitoring_sensor_code;
        saveObj['config.frost_monitoring.threshold'] = config.frost_monitoring_threshold;
        saveStation.config.frost_monitoring.threshold = config.frost_monitoring_threshold;
      }

      if (this.deviceGroup.rain) {
        saveObj['config.monitor.rain'] = config.rain_monitor ? config.rain_monitor : 0;
        saveStation.config.rain_monitor = config.rain_monitor ? config.rain_monitor : 0;
      }

      if (this.deviceGroup.network) {
        const networkTimeout = +config.network_registration_timeout;
        saveObj['config.upload.network_registration_timeout'] = networkTimeout !== 240 && networkTimeout !== 300 ? 240 : networkTimeout;
        saveStation.config.network_registration_timeout = networkTimeout !== 240 && networkTimeout !== 300 ? 240 : networkTimeout;
      }
    }

    const save: ILoggingSave = {
      station: saveStation,
      saveObj: <ILoggingSaveObj>saveObj
    };

    this.loggingStore.dispatch(saveLogging(save));

    // if frost mode is disabled, delete all frost notifications
    if (!this.state.config.frost_monitoring_enabled && this.alerts) {
      const alertsForDelete = this.alerts.filter(alert => alert.source === 'frost');
      if (alertsForDelete) {
        alertsForDelete.forEach(alert => {
          this.serverAlertsStore.dispatch(deleteServerAlert({ id: alert.nm, alert: alert }));
        });
      }
    } else if (this.state.config.frost_monitoring_enabled) {
      let temperatureThreshold = 0;
      this.unitSystem === 'imperial' ? temperatureThreshold = 33.8 : temperatureThreshold = 1;
      const targets: IServerTarget[] = [{
        method: 'push_mobile',
        destination: 'true'
      }];

      const serverAlert : IServerAlert = {
        thresholds: [temperatureThreshold],
        sensorCode: this.state.config.frost_monitoring_sensor_code,
        sensorChannel: this.state.config.frost_monitoring_sensor_channel,
        type: 'min',
        targets: targets,
        resendPeriod: 60,
        aggr: 'mn',
        active: true,
        source: 'frost'
      };
      const serverAlertData : IServerAlertData = {
        id: this.selectedStation.name.original,
        alert: serverAlert
      };

      // if default frost notification already exists, don't create a new one. If not, create it
      let alertExist;
      if (this.alerts) {
        alertExist = this.alerts.find(alert =>
          alert.sensorChannel === serverAlert.sensorChannel &&
          alert.sensorCode === serverAlert.sensorCode &&
          alert.source === serverAlert.source &&
          alert.thresholds.length === 1 &&
          alert.thresholds[0] === serverAlert.thresholds[0]
        );
      }
      if (!alertExist) {
        this.apiCallService.addStationServerAlert(serverAlertData).subscribe();
        this.serverAlertsStore.dispatch(addServerAlert(serverAlertData));
      }
    }
  }

  public toggleAdvanced(): void {
    this.state = {
      ...this.state,
      showAdvanced: !this.state.showAdvanced
    };
  }

  public toggleDefaults(defaultVal: boolean): void {
    if (defaultVal) {
      if (this.settingsComponent) {
        this.settingsComponent.setDefaultValues();
      }
      if (this.schedulerComponent) {
        this.schedulerComponent.setDefaultValues();
      }
    }
  }

  public getHeader(): string {
    if (this.state?.isIScout || this.state?.isCropView) {
      return this.cropViewHeader;
    } else {
      return this.noCropViewHeader;
    }
  }

  public openModal(): void {
    this.modalService.openModal(this.modalId);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
