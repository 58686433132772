import * as fromAnimalProduction from './animal-production';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAnimalProductionState } from '../models/animal-production.models';
import { ITreeSettingsState } from '../../../shared/tree/models/tree.models';
import { animalProductionTreeReducer } from '../../../shared/tree/reducers/tree-settings';

interface IState {
  animalProduction: IAnimalProductionState;
  treeSettings: ITreeSettingsState;
}

export const reducers = {
  animalProduction: fromAnimalProduction.reducer,
  treeSettings: animalProductionTreeReducer
};

export const animalProduction = createFeatureSelector<IState>('animalProduction');

export const selectAnimalProductionCharts = createSelector(
  animalProduction,
  (state: IState): Array<any> => state.animalProduction.chartsOptions
);

export const selectAnimalProductionIsLoading = createSelector(
  animalProduction,
  (state: IState): boolean => state.animalProduction.isLoading
);

export const selectAnimalProductionIsError = createSelector(
  animalProduction,
  (state: IState): boolean => state.animalProduction.isError
);

export const selectAnimalProductionTreeSettings = createSelector(
  animalProduction,
  (state: IState) => state.treeSettings
);

export const selectAnimalProductionWarning = createSelector(
  animalProduction,
  (state: IState) => state.animalProduction.warning
);

export const selectAnimalProductionLicenses = createSelector(
  animalProduction,
  (state: IState) => state.animalProduction.animalProductionLicenses
);
