import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-iscout-season-delete-modal',
  templateUrl: './iscout-season-delete-modal.component.html',
  styleUrls: ['./iscout-season-delete-modal.component.scss']
})
export class IscoutSeasonDeleteModalComponent {
  @Output()
  private cancelEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  private confirmEmitter: EventEmitter<void> = new EventEmitter<void>();

  public handleCancel(): void {
    this.cancelEmitter.emit();
  }

  public handleConfirm(): void {
    this.confirmEmitter.emit();
  }
}
