<div class="camera-settings-modal">
  <p>
    {{ 'You can change your camera settings, but note that wrong settings can result in corrupted images. Change these ' +
    'settings only if you are an expert, otherwise we recommend the use of default settings.' | translate }}
  </p>
  <p>
    {{ 'With "ON/OFF" button you can turn the camera on and off.' | translate }}
  </p>
  <br>

  <h5>{{ 'Camera exposure configuration' | translate }}</h5>
  <p>
    {{ 'Camera exposure determines how light or dark a photo will appear. You can adjust the max integration time ' +
    'and brightness reference.' | translate }}
  </p>
  <p>
    {{ 'Max integration time indicates how long the shutter will be open and permit the light to enter the camera.' |
    translate }}
  </p>
  <p>
    {{ 'Brightness reference is the reference brightness value to which the software of the camera wants to adjust the ' +
    'picture actual brightness. If actual brightness is lower, then the integration time is longer; and if the actual ' +
    'brightness is higher than reference brightness, then the integration time is shorter.' | translate }}
  </p>
  <p>
    {{ 'Max integration time and brightness reference is recommended to be set to default values ' +
       '(you can always click on „reset to default“ button to get back the default values).' | translate }}
  </p>
  <br>

  <h5>{{ 'Measuring points configuration' | translate }}</h5>
  <p>
    {{ 'Red and white squares are Brightness squares (red square – measuring point is turned on, white square – ' +
    'measuring point is turned off). With this option you can select the measuring points in which you want to measure ' +
    'the image brightness used by the automatic exposure algorithm. This comes handy, if a part of picture has high ' +
    'amount of light, and auto exposure automatically dimms also the part of picture of your interest (you can deselect ' +
    'measuring point with high brightness to have better brightness on the rest of the picture).' | translate }}
  </p>

  <hr>
  <p>
    {{ 'When you finish the configuration, do not forget to save the new settings (click on "SAVE CAMERA SETTINGS" ' +
    'button).' | translate }}
  </p>
</div>
