import { Actions, Effect, ofType } from '@ngrx/effects';
import { ApiCallService } from '../../../services/api/api-call.service';
import { Observable, from } from 'rxjs';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import {
  setStationWorkPlanningLicenses,
  setWarningMessage,
  setWorkPlanningCharts,
  setWorkPlanningError,
  setWorkPlanningLoading,
  WorkPlanningActionTypes
} from '../actions';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IWorkPlanningState } from '../models/work-planning.models';
import { setNotify } from '../../../core/actions/notify';
import { PictocodeService } from '../../../services/pictocode/pictocode.service';

@Injectable()
export class WorkPlanningEffects {
  constructor(
    private api               : ApiCallService,
    private store             : Store<IWorkPlanningState>,
    private actions$          : Actions,
    private workPlanningStore: Store<IWorkPlanningState>,
    private pictocodeService  : PictocodeService
  ) { }

  @Effect()
  public getWorkPlanningCharts$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(WorkPlanningActionTypes.GET_WORK_PLANNING_CHARTS),
    tap(() => {
      this.store.dispatch(setWorkPlanningLoading(true));
      this.store.dispatch(setWorkPlanningError(false));
    }),
    switchMap((action: IActionWithPayload) => this.api.getWorkplanningService(action.payload)
      .pipe(
        tap(response => {
          this.pictocodeService.addTooltip(response.chartsOptions);
          this.pictocodeService.setBiggerIcons(response.chartsOptions);
        }),
        switchMap(response => [
          setWorkPlanningLoading(false),
          setWorkPlanningCharts({ ...response, service: action.payload.service }),
          ...(response.chartsOptions && response.chartsOptions.length === 1 ? [
              setNotify('This station does not have all required sensors or required data sets are not available')
            ] : [])
        ]),
        catchError(err => [
          setWorkPlanningLoading(false),
          setWorkPlanningError(true)
        ]
      ))
  ));

  @Effect()
  public getStationWorkPlanningLicenses$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(WorkPlanningActionTypes.GET_STATION_WORK_PLANNING_LICENSES),
    tap(() => {
      this.workPlanningStore.dispatch(setStationWorkPlanningLicenses([]));
      this.workPlanningStore.dispatch(setWarningMessage(null, false));
    }),
    switchMap((action: IActionWithPayload) => this.api.getStationLicenses(action.payload).pipe(
      switchMap((res: any) => from([
        setStationWorkPlanningLicenses(res)
      ])),
      catchError(() => from([]))
    ))
  );

}
