import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

declare const google: any;

@Injectable()
export class GoogleMapService {
  private elevator          : any;
  private geocoder          : any;
  private elevatorSub$      : Subject<any> = new Subject<any>();
  private geocoderSub$      : Subject<any> = new Subject<any>();

  constructor() {
  }

  public getElevator(lat: number, lng: number): Observable<any> {
    this.elevator = new google.maps.ElevationService;
    this.elevator.getElevationForLocations(
      { 'locations': [{ 'lat': lat, 'lng': lng }] },
      (result: Object, status: any) => {
        if (result[0] && status === 'OK') {
          this.elevatorSub$.next(parseFloat(result[0].elevation.toFixed(2)));
        }});
    return this.elevatorSub$.asObservable();
  }

  public getGeocoder(name: string): Observable<any> {
    this.geocoder = new google.maps.Geocoder();
    this.geocoder.geocode(
      { 'address': name },
      (result: any, status: any) => {
        if (status === 'OK') {
          const lat: number = result[0].geometry.location.lat();
          const lng: number = result[0].geometry.location.lng();
          this.geocoderSub$.next([lng, lat]);
        }
      });
    return this.geocoderSub$.asObservable();
  }
}
