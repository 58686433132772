import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { YieldPredictionPreviousComponent } from './yield-prediction-previous.component';
import { PreviousYieldResolver } from './previous-yield.resolver.service';

const routes: Routes = [
  { path: '', component: YieldPredictionPreviousComponent,
  resolve: { previousYieldResolver: PreviousYieldResolver},  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
  providers: [PreviousYieldResolver]
})
export class YieldPredictionPreviousRoutingModule {
}
