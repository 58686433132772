import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-weather-forecast-load-info',
  templateUrl: './weather-forecast-load-info.component.html',
  styleUrls: ['./weather-forecast-load-info.component.scss']
})
export class WeatherForecastLoadInfoComponent implements OnInit {
  @Input()
  public isError      : boolean = false;
  @Input()
  public isLoading    : boolean = false;
  @Input()
  public errorText    : string = 'No data for selected station.';

  constructor() { }

  public ngOnInit() : void {
  }

}
