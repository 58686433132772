import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { IStationSampleState } from '../../models/station-sample.model';
import { selectDevice } from '../../selectors/station-sample.selector';

@Component({
  selector: 'app-sample-header',
  templateUrl: './sample-header.component.html',
  styleUrls: ['./sample-header.component.scss']
})
export class SampleHeaderComponent implements OnInit, OnDestroy {

  @Input()
  public title: string;

  public dates: any;
  public deviceType: string;
  public stationSampleID = generateId();
  public format = 'yyyy-MM-dd HH:mm';
  private alive$ = new Subject<void>();

  constructor(
    private modalService: ModalService,
    private stationSampleStore: Store<IStationSampleState>,
  ) {

  }

  public ngOnInit(): void {
    this.stationSampleStore.pipe(
      select(selectDevice),
      takeUntil(this.alive$),
      filter(_ => Boolean(_)),
    ).subscribe(({ period, type }) => {
      this.dates = period;
      this.deviceType = type;
    });
  }

  public openModal(): void {
    this.modalService.openModal(this.stationSampleID);
  }

  public ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }
}
