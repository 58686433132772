import { IStationSampleState } from '../models/station-sample.model';
import { ActionTypes } from '../actions/station-sample.action';

export const initialState: IStationSampleState = {
  samples: [],
  activeSample: null,
  activeMeter: 'vwc', // todo remove on Dualex (null)
  activeTitle: true,
  settings: null,
  device: null
};


export function stationSampleReducer(state = initialState, action): IStationSampleState {
  switch (action.type) {
    case ActionTypes.SET_SAMPLES:
      return {
        ...state,
        samples: action.payload
      };
    case ActionTypes.SET_SETTINGS:
      return {
        ...state,
        settings: action.payload
      };
    case ActionTypes.SET_ACTIVE_SAMPLE:
      return {
        ...state,
        activeSample: action.payload
      };
    case ActionTypes.SET_DEVICE:
      return {
        ...state,
        device: action.payload
      };
    case ActionTypes.SET_ACTIVE_METER:
      return {
        ...state,
        activeMeter: action.payload
      };
    case ActionTypes.SET_ACTIVE_TITLE:
      return {
        ...state,
        activeTitle: action.payload
      };
    default:
      return state;
  }
}
