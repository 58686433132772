import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IFromTo } from '../../../../../shared/camera/models/camera';
import { ModalService } from '../../../../../shared/modal/services/modal.service';
import { getMonthFromDate } from '../../../../../shared/utils/dateFormat';
import { deepClone } from '../../../../../shared/utils/deepClone';
import { generateId } from '../../../../dashboard/utils/makeWidget';
import { getIscoutFirstDate, getIscoutLastDate } from '../../../../iscout/actions/iscout';
import {
  IIscoutGlueBoard,
  IIscoutSeason,
  IIscoutSettingsState,
  IIscoutState
} from '../../../../iscout/models/iscout.models';
import { selectIscoutIsError, selectIscoutIsLoading, selectIscoutLastDate } from '../../../../iscout/reducers';
import { getCameraDataFromIscout, setCameraDataCurrentDateString } from '../../../actions/camera-data';
import { baseIScoutChartOptions, buildPhotoRequest, getSelectedCameraId } from '../../../constants/camera-data';
import {
  ChartSerieType,
  ICameraDataState,
  IChartSeries,
  IChartSeriesMap,
  IIscoutReportData
} from '../../../models/camera-data';
import {
  selectCameraDataIscoutReport,
  selectCameraGlueBoardDataIscoutReport,
  selectCameraSeasonDataIscoutReport
} from '../../../reducers';

@Component({
  selector: 'app-camera-data-content-iscout',
  templateUrl: './camera-data-content-iscout.component.html',
  styleUrls: ['./camera-data-content-iscout.component.scss']
})
export class CameraDataContentIscoutComponent implements OnInit, OnDestroy {
  @Input()
  public title: Observable<string>;
  @Input()
  public stationCameraType: string;
  @Input()
  public stationChangedListener$: Observable<string>;
  @Input()
  public currentDateListener$: Observable<string | IFromTo>;
  @Input()
  public isHelpActive$: Observable<boolean>;
  @Input()
  public isChartActive$: Observable<boolean>;
  @Input()
  public isTableActive$: Observable<boolean>;

  public helpModalId: string = generateId();
  public rowData: any = [];
  public additionalColumnDefs: any = [];
  public chartOptions: any = null;
  public isLoading$: Observable<boolean>;
  public isError$: Observable<boolean>;

  private pestDisplayMap: {[label: string]: boolean} = {};
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private iscoutStore: Store<IIscoutState>,
              private iscoutSettingsStore: Store<IIscoutSettingsState>,
              private cameraDataStore: Store<ICameraDataState>,
              private modalService: ModalService) {
  }

  public ngOnInit(): void {
    this.initDataRetrieving();
    this.initStatusListeners();
    this.initPictureListeners();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initDataRetrieving(): void {
    this.stationChangedListener$.subscribe((stationId: string): void => {
      this.iscoutStore.dispatch(getIscoutFirstDate(stationId));
      this.iscoutStore.dispatch(getIscoutLastDate(stationId));
    });

    combineLatest([
      this.stationChangedListener$,
      this.currentDateListener$
    ]).subscribe((payload: Array<string | IFromTo>): void => {
      const stationId = <string>payload[0];
      const currentDate = <string | IFromTo>payload[1];
      const params = buildPhotoRequest(stationId, currentDate, getSelectedCameraId(this.stationCameraType));
      this.cameraDataStore.dispatch(getCameraDataFromIscout(params));
    });
  }

  private initStatusListeners(): void {
    this.isLoading$ = this.iscoutStore.pipe(
      select(selectIscoutIsLoading)
    );

    this.isError$ = this.iscoutStore.pipe(
      select(selectIscoutIsError)
    );
  }

  private initPictureListeners(): void {
    this.iscoutSettingsStore.pipe(
      takeUntil(this.destroy$),
      select(selectIscoutLastDate),
      filter((date: moment.Moment): boolean => !!date),
    ).subscribe((date: moment.Moment): void => {
      this.cameraDataStore.dispatch(setCameraDataCurrentDateString(getMonthFromDate(date)));
    });

    combineLatest([
      this.cameraDataStore.pipe(takeUntil(this.destroy$), select(selectCameraDataIscoutReport)),
      this.cameraDataStore.pipe(takeUntil(this.destroy$), select(selectCameraGlueBoardDataIscoutReport)),
      this.cameraDataStore.pipe(takeUntil(this.destroy$), select(selectCameraSeasonDataIscoutReport))
    ])
    .subscribe(([
      reportData,
      glueBoard,
      season
    ]: [
      IIscoutReportData[],
      IIscoutGlueBoard,
      IIscoutSeason
    ]): void => {
      this.pestDisplayMap = {};
      this.prepareChartAndGridData(reportData, glueBoard, season);
    });
  }

  private prepareChartAndGridData(
    reportData: IIscoutReportData[],
    glueBoard: IIscoutGlueBoard,
    season: IIscoutSeason
  ): void {
    this.rowData = [];
    this.chartOptions = deepClone(baseIScoutChartOptions);

    const series: IChartSeriesMap = reportData.reduce(function (result, data): IChartSeriesMap {
      return {
        ...result,
        [data.label]: {
          type: ChartSerieType.AREA,
          name: data.label,
          data: [],
          visible: true
        }
      };
    }, {});

    const tableRowData = reportData.reduce((result, current) => {
      const dateTime: number = moment(current.detection_time).unix() * 1000;
      const dateTimeLabel = moment(current.detection_time).format('YYYY-MM-DD');
      const tableRowField = this.toSnakeCase(current.label);

      // Must add the glueboard total to the amount of detected pests
      let pestCount = current.detections;
      if (season && season.detections) {
        const o = season.detections.find((detection) => {
          return moment(current.detection_time).isAfter(detection.to);
        });

        if (o && o.total.hasOwnProperty(current.label)) {
          pestCount += o.total[current.label];
        }
      }

      if (this.pestDisplayMap[current.label] !== undefined) {
        series[current.label].visible = this.pestDisplayMap[current.label];
      } else {
        if (glueBoard && glueBoard.target) {
          const foundTarget = glueBoard.target.find((tgt) => tgt.name === current.label);
          series[current.label].visible = (foundTarget !== undefined);
        }
      }

      series[current.label].data.unshift([dateTime, pestCount]);
      return {
        ...result,
        [dateTimeLabel]: {
          name: dateTimeLabel,
          ...(result[dateTimeLabel] || {}),
          [tableRowField]: pestCount,
        }
      };
    }, {});

    this.chartOptions.series = Object.keys(series).sort().map((key) => series[key]);
    this.rowData = Object.values(tableRowData);
    this.generateTableCols();
  }

  private generateTableCols(): void {
    this.additionalColumnDefs = this.chartOptions.series
      .map((detection: IChartSeries) => {
        const columnDef = {
          headerName: detection.name,
          field: this.toSnakeCase(detection.name),
          hide: !detection.visible
        };

        if (this.pestDisplayMap[detection.name]  !== undefined) {
          columnDef.hide = !this.pestDisplayMap[detection.name];
        }

        return columnDef;
      });
  }

  private toSnakeCase(label: string): string {
    return label.replace(/\W+/g, ' ')
      .split(/ |\B(?=[A-Z])/)
      .map(word => word.toLowerCase())
      .join('_');
  }

  public handleOpenModal(): void {
    this.modalService.openModal(this.helpModalId);
  }

  public handleVisibilityToggle({name, visible}: any): void {
    this.pestDisplayMap[name] = !visible;
    this.generateTableCols();
  }
}
