import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-emergency-form',
  templateUrl: './emergency-form.component.html'
})
export class EmergencyFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public emergency       : string;
  @Output()
  private changeEmergency: EventEmitter<string> = new EventEmitter<string>();

  public form            : FormGroup;
  private destroy$       : Subject<boolean> = new Subject<boolean>();

  constructor(private fb: FormBuilder) {}

  public get emergencyControl(): AbstractControl {
    return this.form.get('emergency');
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      'emergency': ['', []]
    });
    this.emergencyControl.setValue(this.emergency);

    this.emergencyControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      debounceTime(100)
    ).subscribe((emergency: string) => {
      this.changeEmergency.emit(emergency);
    });
  }

  public ngOnChanges(): void {
    if (this.form) {
      this.emergencyControl.setValue(this.emergency);
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
