<app-tree [tree$]="tree$"></app-tree>
<div class="body-view-content" [@placeContent]="state$ | async">
    <app-cropzone-page-header [pageName]="'Irrimet settings'"></app-cropzone-page-header>
    <ng-container>
        <app-irrimet-data class="irrimet-config-content__item"></app-irrimet-data>
        <div class="alert alert-warning cropzone-config-content__alert" *ngIf="selectedCropzone.soil === null">
          <i class="fa fa-exclamation-triangle"></i> {{ 'The rootzone settings for this cropzone have not been specified yet, so default values will be used. Please specify parameters in the section below.' | translate}}
        </div>
        <app-rootzone-settings class="irrimet-config-content__item"></app-rootzone-settings>
    </ng-container>
</div>
