import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { IState } from '../models/farmbeats';
import { ActionTypes } from '../actions/farmbeats';

export const initialState: IState = {
  settings: {
    fb_api_endpoint: '',
    fb_client_id: '',
    fb_client_secret: '',
    fb_eventhub_connection_string: '',
    fb_eventhub_name: '',
    fb_tenant_id: '',
    returnCode: 0
  },
  assets: []
};

export function reducer(state: IState = initialState, action: IAction): IState {
  switch (action.type) {
    case ActionTypes.SET_STATE:
      return {
        ...action.payload
      };

    case ActionTypes.SET_SETTINGS:
      return {
        ...state,
        settings: {
          ...action.payload
        }
      };

    case ActionTypes.SET_ASSETS:
      let assets = [];
      if (action.payload.hasOwnProperty('StationList')) {
        assets = action.payload.StationList;
      }

      return {
        ...state, assets
      };

    default:
      return state;
  }
}

