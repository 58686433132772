import { IDisplayRulesConfig } from '../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../environments/interfaces/theme';

export const themeConfig: IThemeConfig = {
  subDomain: 'default',
  language: 'en',
  headerBackground: '#008366',
  src: '../../../assets/themes/default/assets/images/logo.png',
  mainSrc: '../../../assets/themes/default/assets/images/landing.png',
  mainMTop: '10',
  profileMenuSrc: '../../../assets/themes/default/assets/images/profile.png',
  faviconSrc: '../../../assets/themes/default/assets/favicon.ico',
  colorConfig: {
      '--search-widget-border': '#8bc34a',
      '--search-widget-label': '#8bc34a',
      '--search-widget-label-dark-green': '#008366',
      '--search-widget-label-orange': '#f39224',
      '--search-widget-button': '#5cb85c',
      '--main-static': '#008366',
      '--main-static-orange': '#f39224',
      '--form-button': '#5cb85c',
    }
};

export const displayRulesConfig: IDisplayRulesConfig = {
  userMenu: true,
  farmViewMenu: true,
  leftBarMenu: {
    stationData: true,
    cropView: true,
    iScout: true,
    weatherForecast: true,
    workPlanning: true,
    animalProduction: true,
    accumulatorTool: true,
    frostProtection: true,
    diseaseModels: true,
    soilMoisture: true,
    hydroponics: true,
    tracker: true,
    soilGuard: true,
    settings: true,
  },
};
