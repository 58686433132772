import { Options } from 'highcharts';
import { IHydroponicsValues } from '../models/models';

export const DEFAULT_CHART_OPTIONS: Options = {
  title: {
    text: null
  },
  chart: {
    height: 350,
    marginLeft: 85,
    marginRight: 100,
    marginTop: 20,
    style: { fontFamily: 'Helvetica' },
    zoomType: 'x',
    alignTicks: false
  },
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  legend: {
    enabled: true,
    useHTML: true,
    labelFormatter: function (): string {
      return this.name;
    }
  },
  xAxis: {
    crosshair: true,
    gridLineWidth: 1,
    labels: { style: { fontSize: '12px' } },
    lineWidth: 2,
    type: 'datetime',
    showEmpty: false
  },
  plotOptions: {
    series: {
      states: {
        hover: {
          enabled: false
        }
      },
      marker: {
        enabled: false
      },
      pointPlacement: 'on'
    }
  }
};

export const TRANSLATED_VALUES: IHydroponicsValues = {
  qFactor             : 'Q factor',
  uFactor             : 'U factor',
  balance             : 'Balance',
  drainage            : 'Drainage',
  drainageWithUnit    : 'Drainage [cm³]',
  weightScale         : 'Scale high res.',
  weightScaleWithUnit : 'Scale high res. [g]',
  waterIn             : 'Water In',
  waterOut            : 'Water Out',
  water               : 'Water [l]'
};

export const HELP_TEXT: string[] = [
  'Flux rate - per each dripper (l/h) and n° of drippers, the inputs inserted by the user, refer to the part of the irrigation line ' +
  'over the tray system and they are taken into consideration for the water balance algorithm. Both fields are pre-filled with default ' +
  'values.',
  '\n\n',
  'The water balance is also defined from the changes in weight and the measurement of the drainage. Water inlet is the water ' +
  'which is added to the tray system with irrigation, while water outlet is the water lost by drainage and evapotranspiration.',
  '\n\n',
  'Three important factors are also displayed:',
  '\n',
  'a) the drainage quotient (Q factor) to inform about the drainage blocking or excessive rate,',
  '\n',
  'b) the uptake quotient (U factor) is related with the plant uptake and plant growth and',
  '\n',
  'c) balance which validates the system data.'
];

export const hydroponicsColumnDefs: any[] = [
  { headerName: 'Date/Time', field: 'date', sort: 'desc', suppressMenu: true, sortable: true, width: 158, pinned: true },
  { headerName: 'Water In [l]', field: 'win', suppressMenu: true, sortable: true, width: 112 },
  { headerName: 'Water Out [l]', field: 'wout', suppressMenu: true, sortable: true, width: 122 },
  { headerName: 'Drainage [cm³]', field: 'drainage', suppressMenu: true, sortable: true, width: 137 },
  { headerName: 'Scale high res. [g]', field: 'weight', suppressMenu: true, sortable: true, width: 160 }
];
