import {ICropViewSettingsState} from '../models/crop-view.models';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {CropViewSettingsActionTypes} from '../actions/crop-view-settings';
import {initialCameraPicturesState} from '../../../core/constants/camera';

export function reducer(state: ICropViewSettingsState = initialCameraPicturesState, action: IActionWithPayload): ICropViewSettingsState {
  switch (action.type) {
    case CropViewSettingsActionTypes.RESET_CROP_VIEW_SETTINGS:
      return <ICropViewSettingsState> {
        ...state,
        selectedPicture: initialCameraPicturesState.selectedPicture
      };
    case CropViewSettingsActionTypes.SET_CROP_VIEW_SETTINGS_CURRENT_DATE_STRING:
      return <ICropViewSettingsState> {
        ...state,
        currentDateString: action.payload
      };
    case CropViewSettingsActionTypes.SET_CROP_VIEW_SETTINGS_SELECTED_PICTURE:
      return <ICropViewSettingsState> {
        ...state,
        selectedPicture: action.payload
      };
    case CropViewSettingsActionTypes.UPDATE_CROP_VIEW_SETTINGS_SELECTED_PICTURE:
      return <ICropViewSettingsState> {
        ...state,
        selectedPicture: {
          ...state.selectedPicture,
          rectangles: action.payload.rectangles
        }
      };
    case CropViewSettingsActionTypes.SET_CROP_VIEW_SETTINGS_TOOLBAR_SETTINS:
      return <ICropViewSettingsState> {
        ...state,
        toolbarSettings: action.payload
      };
    case CropViewSettingsActionTypes.SET_CAMERA_DISTANCE:
      return <ICropViewSettingsState> {
        ...state,
        cameraDistance: action.payload
      };
    case CropViewSettingsActionTypes.SET_CAMERA_DRAWING_OPTIONS:
      return {
        ...state,
        drawingOptions: { ...action.payload }
      };
    default:
      return state;
  }
}
