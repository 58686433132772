<section>
  <ng-container *ngIf="!(isLoading$ | async); else loadInfo">
    <app-iscout-season-list
      *ngIf="(selectedSeason$ | async) === null"
      (seasonAdded)="selectSeasonCreate()"
      (seasonEdited)="selectSeasonUpdate($event)"
      (seasonRemoved)="handleSeasonRemoved($event)"
    ></app-iscout-season-list>

    <app-iscout-season-form
      *ngIf="(selectedSeason$ | async) !== null"
      [stationId]="stationId"
      [formAction]="(selectedSeason$ | async)"
      (seasonSaved)="handleSeasonSaved($event)"
      (seasonUpdated)="handleSeasonUpdated($event)"
    ></app-iscout-season-form>
  </ng-container>

  <ng-template #loadInfo>
    <app-station-load-info [isLoading]="isLoading$ | async"></app-station-load-info>
  </ng-template>
</section>
