import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrostProtectionComponent } from './containers/frost-protection/frost-protection.component';
import { FrostProtectionRoutingModule } from './frost-protection-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ChartModule } from 'angular-highcharts';
import { AgGridModule } from 'ag-grid-angular';
import { FrostProtectionTreeComponent } from './containers/frost-protection-tree/frost-protection-tree.component';
import { StoreModule } from '@ngrx/store';
import { frostProtectionReducer } from './reducers/reducers';
import { FrostProtectionContentComponent } from './containers/frost-protection-content/frost-protection-content.component';
import { FrostProtectionHeaderComponent } from './containers/frost-protection-header/frost-protection-header.component';
import { FrostProtectionToolbarComponent } from './containers/frost-protection-toolbar/frost-protection-toolbar.component';
import { EffectsModule } from '@ngrx/effects';
import { FrostProtectionEffects } from './effects/effects';
import { FrostProtectionChartsComponent } from './containers/frost-protection-charts/frost-protection-charts.component';
import { FrostProtectionTableComponent } from './containers/frost-protection-table/frost-protection-table.component';
import { FrostProtectionHelpModalComponent } from './containers/frost-protection-help-modal/frost-protection-help-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    FrostProtectionRoutingModule,
    ChartModule,
    AgGridModule.withComponents([]),
    StoreModule.forFeature('frostProtection', frostProtectionReducer),
    EffectsModule.forRoot([FrostProtectionEffects])
  ],
  declarations: [
    FrostProtectionComponent,
    FrostProtectionTreeComponent,
    FrostProtectionContentComponent,
    FrostProtectionHeaderComponent,
    FrostProtectionToolbarComponent,
    FrostProtectionChartsComponent,
    FrostProtectionTableComponent,
    FrostProtectionHelpModalComponent
  ]
})
export class FrostProtectionModule { }
