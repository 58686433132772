import { Directive, OnDestroy, OnInit, AfterViewInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { delay, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ICropZone } from './../../../core/models/cropzones';

@Directive({
  selector: '[appObserveVisibility]'
})
export class ObserveVisibilityDirective implements OnDestroy, OnInit, AfterViewInit {
  @Input()
  public debounceTime = 0;
  @Input()
  public threshold = 0.5;
  @Input()
  public value;
  @Input()
  public cropzone: ICropZone;

  @Output()
  public visible = new EventEmitter<any>();

  private observer: IntersectionObserver | undefined;
  private subject$ = new Subject<{
    entry: IntersectionObserverEntry,
    observer: IntersectionObserver
  }>();

  constructor(private element: ElementRef) { }

  public ngOnInit(): void {
    this.createObserver();
    this.startObservingElements();
  }

  public ngAfterViewInit(): void {
  }

  public ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = undefined;
    }

    this.subject$.next();
    this.subject$.complete();
  }

  private createObserver(): void {
    const options = {
      rootMargin: '0px',
      threshold: this.threshold
    };

    const isIntersecting = (entry: IntersectionObserverEntry) => entry.isIntersecting || entry.intersectionRatio > 0;

    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (isIntersecting(entry)) {
          this.subject$.next({entry, observer});
        }
      });
    }, options);
  }

  private startObservingElements(): void {
    if (!this.observer) {
      return;
    }

    this.observer.observe(this.element.nativeElement);

    this.subject$
      .pipe(delay(this.debounceTime), filter(Boolean))
      .subscribe(async ({ entry, observer }) => {
        const target = entry.target as HTMLElement;
        const date = this.value.date;

        this.visible.emit(date);
        observer.unobserve(target);
      });
  }
}
