import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { saveTrackerSettings } from '../../actions/trackerSettings';
import * as fromTrackerSettings from '../../reducers/tracker-settings';

@Component({
  selector: 'app-tracker-settings',
  templateUrl: './tracker-settings.component.html',
  styleUrls: ['./tracker-settings.component.scss']
})
export class TrackerSettingsComponent implements OnInit, OnDestroy {

  public stationId: string;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public tripDelimiterOptions = [
    {content: '10 min', value: 10},
    {content: '30 min', value: 30},
    {content: '60 min', value: 60},
    {content: '90 min', value: 90},
    {content: '120 min', value: 120}
  ];

  public trackerSettingsForm = new FormGroup({
    trip_delimiter: new FormControl(),
  });

  private updated: boolean = false;

  constructor(private trackerSettingsStore: Store<fromTrackerSettings.ITrackerSettingsState>,
              private navigationStationStore: Store<INavigationStationState>) {}

  public ngOnInit(): void {
    this.navigationStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((station: IStation): boolean => !!station),
    ).subscribe(station => {
      this.stationId = station.name.original;
      if (station && station.config.trip_delimiter && !this.updated) {
        this.trackerSettingsForm.patchValue({trip_delimiter: station.config.trip_delimiter});
      }
    });
  }

  public prepareDataToSave(): Object {
    const data = {};
    data['config.trip_delimiter'] = +this.trackerSettingsForm.value.trip_delimiter;
    return data;
  }

  public save(): void {
    this.trackerSettingsStore.dispatch(
      saveTrackerSettings({
        stationId: this.stationId,
        body: this.prepareDataToSave()
      })
    );
    this.updated = true;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
