<div [@cardAnimation]>
  <div class="user-licenses-section" *ngIf="(selectedLicense$ | async) === null && (fieldClimateLicenses$ | async).length > 0">
    <app-user-licenses-list
      [activeUserLicenses]="fieldClimateLicenses$ | async"
      [header]="'Current FieldClimate licenses'"
      [type]="'FieldClimate'"
      (selectLicense)="handleSelectLicense($event)"
    ></app-user-licenses-list>
  </div>

  <div class="user-licenses-section" *ngIf="(selectedLicense$ | async) === null && (farmViewLicenses$ | async).length > 0">
    <app-user-licenses-list
      [activeUserLicenses]="farmViewLicenses$ | async"
      [header]="'Current FarmView licenses'"
      [type]="'FarmView'"
      (selectLicense)="handleSelectLicense($event)"
    ></app-user-licenses-list>
  </div>

  <div class="user-licenses-section" *ngIf="(selectedLicense$ | async) !== null">
    <app-user-licenses-detail
      [selectedLicense]="selectedLicense$ | async"
      (unselectLicense)="handleUnselectLicense()"
    ></app-user-licenses-detail>
  </div>
</div>
