import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IPhone } from '../../../../../core/models/stations';
import { setWarningsPhone } from '../../../actions/warningsPhones';
import * as fromWarningsPhones from '../../../reducers/warningsPhones';

@Component({
  selector: 'app-warnings-phone-form',
  templateUrl: './warnings-phone-form.component.html',
  styleUrls: ['./warnings-phone-form.component.scss']
})
export class WarningsPhoneFormComponent implements OnInit {
  @Input()
  public phones         : IPhone[];
  public form           : FormGroup;
  public isAdded        : boolean = false;
  public isExists       : boolean = false;

  private timeId        : any;

  constructor(private fb: FormBuilder,
              private warningsPhonesStore: Store<fromWarningsPhones.IWarningsPhonesState>) { }

  public ngOnInit(): void {
    this.form = this.fb.group({
      'num': ['', []],
      'name': ['', []],
      'active': [true, []]
    });
  }

  public get num(): AbstractControl {
    return this.form.get('num');
  }

  public get name(): AbstractControl {
    return this.form.get('name');
  }

  public add(): void {
    if (!this.name.value || !this.num.value) {
      return;
    }
    this.resetMessage();
    if (this.phones.some((p: IPhone) => this.num.value === p.num)) {
      this.isExists = true;
    } else {
      this.isAdded = true;
      this.warningsPhonesStore.dispatch(setWarningsPhone(this.form.value));
      this.num.setValue('');
      this.name.setValue('');
    }
    this.offMessage();
  }

  private resetMessage(): void {
    clearTimeout(this.timeId);
    this.isAdded = false;
    this.isExists = false;
  }

  private offMessage(): void {
    this.timeId = setTimeout(() => {
      this.isAdded = false;
      this.isExists = false;
    }, 3000);
  }

}
