import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromDashboard from '../../../reducers/dashboard';
import { cropzoneWidgetOptions } from './../../../constants/constants';
import { ICropzoneRequest, ILoadWidgetData, IUpdateWidgetEditMode, IWidget } from './../../../models/dashboard.models';
import { selectEdit } from './../../../reducers/index';
// tslint:disable-next-line:max-line-length
import { IOptions } from '../../../../../shared/interfaces';
import { getWidgetData, removeWidget, setWidgetError, setWidgetLoading, updateWidgetEditMode, updateWidgetSettings } from '../../../actions/dashboard';
import { generateId } from '../../../utils/makeWidget';
import { ModalService } from './../../../../../shared/modal/services/modal.service';

@Component({
  selector: 'app-cropzones-widget',
  templateUrl: './cropzones-widget.component.html',
  styleUrls: ['./cropzones-widget.component.css']
})
export class CropzonesWidgetComponent implements OnInit {
  @Input()
  public widget                     : IWidget;
  @Input()
  public widgetIndex               : number;
  @Input()
  public columnIndex               : number;
  @Input()
  public rowIndex                  : number;

  private loadWidgetData            : ILoadWidgetData;
  public isEdit$                    : Observable<boolean>;
  public isCropzoneInput            : boolean = false;

  public cropzoneWidgets: Array<IOptions> = cropzoneWidgetOptions;
  public form: FormGroup;

  public modalId: string = generateId();

  constructor(private dashboardStore: Store<fromDashboard.IDashboard>,
              private fb: FormBuilder,
              private modalService: ModalService) { }

  public get cropzoneWidget(): AbstractControl {
    return this.form.get('cropzoneWidget');
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      'cropzoneWidget': ['SM SUM', [
        Validators.required
      ]]
    });

    this.isEdit$ = this.dashboardStore.pipe(select(selectEdit));
    if (!this.widget.loadData) {
      this.loadCropzoneData();
    }
  }

  public removeCurrentWidget(): void {
    const path: Array<number> = [this.rowIndex, this.columnIndex, this.widgetIndex];
    this.dashboardStore.dispatch(removeWidget(path));
  }

  public editMode(editMode: boolean): void  {
    const updateSettings: IUpdateWidgetEditMode = {
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      type: 'cropzones',
      isEdit: editMode,
    };
    this.dashboardStore.dispatch(updateWidgetEditMode(updateSettings));
  }

  public loadCropzoneData(): void { // TODO

    const scope = '24h';

    this.loadWidgetData = {
      url: `/fc/sum-sm/${scope}`,
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
    };
    this.resetLoading();
    this.dashboardStore.dispatch(getWidgetData(this.loadWidgetData));
  }

  private resetLoading(): void {
    this.dashboardStore.dispatch(setWidgetLoading({
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      loading: true
    }));
    this.dashboardStore.dispatch(setWidgetError({
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      error: ''
    }));
  }

  public updateRequest(updateSettings: ICropzoneRequest): void {
    this.dashboardStore.dispatch(updateWidgetSettings({
      type: 'cropzones',
      updateSettings: updateSettings,
      path: [this.rowIndex, this.columnIndex, this.widgetIndex]
    }));
  }

  public openModal(): void {
    this.modalService.openModal(this.modalId);
  }
}
