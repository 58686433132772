<section class="camera-data-header">
  <h2 class="camera-data-header__header">
    {{ title | translate }}
    <small class="camera-data-header__description">
      {{ subtitle | translate }}
    </small>
  </h2>

  <div *ngIf="enableHelpButton">
    <i class="camera-data-header__help zmdi zmdi-help" (click)="handleOpenModal()"></i>
  </div>
</section>
