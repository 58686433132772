<form class="notifications-settings-form"
      [formGroup]="form">

  <table class="notifications-settings-form__table">
    <tbody>
      <tr>
        <th>{{ 'Subscription type' | translate }}</th>
        <th>{{ 'Status' | translate }}</th>
      </tr>
      <tr>
        <td>{{ 'Technical newsletter about FieldClimate updates' | translate }}</td>
        <td>
          <app-yes-no-toggle [formControlName]="'promotional'"></app-yes-no-toggle>
        </td>
      </tr>
      <tr>
        <td>{{ 'Promotional newsletter about new Pessl Instruments and METOS country affiliate products, services and events' | translate }}</td>
        <td>
          <app-yes-no-toggle [formControlName]="'technical'"></app-yes-no-toggle>
        </td>
      </tr>
    </tbody>
  </table>

  <hr>

  <div class="notifications-settings-form__submit">
    <app-custom-button [content]="'Update Notifications'"
                       (click)="updateNotifications()"
                       [color]="'red'"></app-custom-button>
  </div>

</form>
