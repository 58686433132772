<app-form-layout>
  <app-card-header
    header="Current alerts"
    [helpButton]="true"
    description="List of your current alerts"
    (openModal)="openInfoModal()"
  >
  </app-card-header>
  <app-notifications-configuration-warning></app-notifications-configuration-warning>
  <div class="table-responsive server-alerts-list__table-content">
    <table class="server-alerts-list__table" *ngIf="stations">
      <thead class="server-alerts-list__table__head">
        <tr>
          <th class="col-md-1 server-alerts-list__table__th">
            {{ "Station name" | translate }}
          </th>
          <th class="col-md-1 server-alerts-list__table__th">
            {{ "Custom name" | translate }}
          </th>
          <th class="col-md-2 server-alerts-list__table__th">
            {{ "Sensor" | translate }}
          </th>
          <th class="col-md-2 server-alerts-list__table__th">
            {{ 'Type' | translate }}</th>
          <th class="col-md-1 server-alerts-list__table__th">
            {{ "Alert" | translate }}
          </th>
          <th class="col-md-1 server-alerts-list__table__th">
            {{ "Thresholds" | translate }}
          </th>
          <th class="col-md-1 server-alerts-list__table__th">
            {{ "Unit" | translate }}
          </th>
          <th class="col-md-1 server-alerts-list__table__th">
            {{ "Targets" | translate }}
          </th>
          <th class="col-md-1 server-alerts-list__table__th">
            {{ "Resend period" | translate }}
          </th>
          <th class="col-md-1 server-alerts-list__table__th">
            {{ "Active" | translate }}
          </th>
          <th class="col-md-1 server-alerts-list__table__th">
            {{ "Action" | translate }}
          </th>
        </tr>
      </thead>
      <tbody class="server-alerts-list__table__body">
        <tr
          app-alerts-row [ngClass]="{'frost-mode' : alert.source==='frost'}"
          *ngFor="let alert of alerts"
          [alert]="alert"
          [sensorData] = getSensorData(alert)
          [stations]="stations"
        ></tr>
      </tbody>
    </table></div
></app-form-layout>

<app-info-modal [modalId]="CURRENT_ALERTS_MODAL_ID" [header]="'Current alerts'">
  <p>
    {{
      'The ‘current alerts’ will show the already created notifications on a list. The same notifications can also be configured, activated, deactivated, or deleted. When editing your list, don’t forget to click on ‘update’ to save your changes.' | translate
    }}
  </p>
</app-info-modal>
