import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { AnimalProductionActionTypes } from '../actions';
import { IAnimalProductionState } from '../models/animal-production.models';

const initialState: IAnimalProductionState = {
  chartsOptions: [],
  isLoading: true,
  isError: false,
  warning: null,
  animalProductionLicenses: null
};

export function reducer(state: IAnimalProductionState = initialState, action: IActionWithPayload): IAnimalProductionState {
  switch (action.type) {
    case AnimalProductionActionTypes.SET_ANIMAL_PRODUCTION_CHARTS:
      return <IAnimalProductionState>{
        ...state,
        chartsOptions: action.payload.chartsOptions,
        service: action.payload.service
      };
    case AnimalProductionActionTypes.SET_ANIMAL_PRODUCTION_LOADING:
      return <IAnimalProductionState>{
        ...state,
        isLoading: action.payload
      };
    case AnimalProductionActionTypes.SET_ANIMAL_PRODUCTION_ERROR:
      return <IAnimalProductionState>{
        ...state,
        isError: action.payload
      };
    case AnimalProductionActionTypes.SET_ANIMAIL_PRODUCTION_WARNING:
      return <IAnimalProductionState>{
        ...state,
        warning: action.payload
      };
    case AnimalProductionActionTypes.SET_ANIMAL_PRODUCTION_LICENSES:
      let licenses = [];

      if (action.payload['AnimalProduction']) {
        licenses = action.payload['AnimalProduction'];
      }
      return <IAnimalProductionState>{
        ...state,
        animalProductionLicenses: licenses
      };
    default:
      return state;
  }
}

