import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { environmentToken } from '../../../../../../../environments/environment';
import { IEnvironment } from '../../../../../../../environments/interfaces/environment';
import { ISettings } from '../../../../../../core/models/account';
import { IOptions } from '../../../../../../shared/interfaces';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
  @Input()
  public settings                     : ISettings;
  @Output()
  private settingsInfoChange          : EventEmitter<ISettings> = new EventEmitter();
  public form                         : FormGroup;
  public languages                    : Array<IOptions>;
  public metrics                      : Array<IOptions> = [
    {value: 'metric', content: 'Metric'},
    {value: 'imperial', content: 'Imperial'}
  ];
  public oldMetric                    : string;

  private alive$                  : Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    private formBuilder: FormBuilder
    ) {
      this.languages = environment.languages;
    }

  public get metric(): AbstractControl {
    return this.form.get('unit_system');
  }

  public ngOnInit(): void {
    this.oldMetric = this.settings.unit_system;

    this.form = this.formBuilder.group({
      'language': [this.settings.language || ''],
      'unit_system': [this.settings.unit_system || ''],
    });

    this.settingsInfoChange.emit(this.form.value);

    this.form.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this.alive$),
    ).subscribe((form: ISettings) => {
      this.settingsInfoChange.emit(form);
    });
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }

}
