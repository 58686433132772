import { IHydroponicsState } from '../models/models';

export const initialState: IHydroponicsState = {
  period: {
    periodScope     : 'hourly',
    periodValue     : '24h',
    fromTo          : null,
    fromDatepicker  : null,
    toDatepicker    : null,
    stationId       : '',
    isLastDataMode  : true
  },
  activity: {
    isChartActive       : true,
    isTableActive       : true,
    isExportImageActive : false,
    isExportActive      : false
  },
  tree: [{
    id              : 17,
    expanded        : true,
    type            : 'Hydroponics',
    name            : 'Hydroponics',
    hasSettingsIcon : false,
    nodes           : null,
    subNodes        : null
  }],
  dataLoading: {
    isError         : false,
    isLoading       : false
  },
  charts            : [],
  visibility: {
    win             : true,
    wout            : true,
    drainage        : true,
    weight          : true
  }
};
