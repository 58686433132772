import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';

export const enum ActionTypes {
  GET_SOILGUARD_SAMPLES = '[StationSample] GET_SOILGUARD_SAMPLES',
  GET_DUALEX_SAMPLES = '[StationSample] GET_DUALEX_SAMPLES',
  GET_MOBILAB_SAMPLES = '[StationSample] GET_MOBILAB_SAMPLES',
  SET_ACTIVE_SAMPLE = '[StationSample] SET_ACTISET_ACTIVE_SAMPLEVE_SAMPLE',
  SET_SAMPLES = '[StationSample] SET_SAMPLES',
  SET_SETTINGS = '[StationSample] SET_SETTINGS',
  SET_DEVICE = '[StationSample] SET_DEVICE',
  SET_ACTIVE_METER = '[StationSample] SET_ACTIVE_METER',
  SET_ACTIVE_TITLE = '[StationSample] SET_ACTIVE_TITLE'
}


export function getSoilGuardSamples(payload): IAction {
  return { type: ActionTypes.GET_SOILGUARD_SAMPLES, payload };
}

export function getDualexSamples(payload): IAction {
  return { type: ActionTypes.GET_DUALEX_SAMPLES, payload };
}

export function getMobilabSamples(payload): IAction {
  return { type: ActionTypes.GET_MOBILAB_SAMPLES, payload };
}

export function setSamples(payload): IAction {
  return { type: ActionTypes.SET_SAMPLES, payload };
}

export function setActiveSample(payload): IAction {
  return { type: ActionTypes.SET_ACTIVE_SAMPLE, payload };
}

export function setSettings(payload): IAction {
  return { type: ActionTypes.SET_SETTINGS, payload };
}

export function setDevice(payload): IAction {
  return { type: ActionTypes.SET_DEVICE, payload };
}

export function setActiveMeter(payload): IAction {
  return { type: ActionTypes.SET_ACTIVE_METER, payload };
}

export function setActiveTitle(payload): IAction {
  return { type: ActionTypes.SET_ACTIVE_TITLE, payload };
}
