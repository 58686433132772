<form class="product-activation-farm-view-form" [formGroup]="form">

  <div class="row">
    <div class="col-md-4">
      <label>{{ 'Starting date' | translate }}</label>
      <app-custom-datepicker class="product-activation-farm-view-form__input"
                             [isSmall]="true"
                             [minDate]="currentDate"
                             [maxDate]="maxDate"
                             [formControl]="startingDate">
      </app-custom-datepicker>
    </div>
  </div>

  <div class="product-activation-farm-view-form__submit">
    <span class="product-activation-farm-view-form__submit__info">
      {{ '* License is valid for' | translate }} {{ licenseDuration }}
    </span>

    <app-custom-button *ngIf="cancelable"
                       class="product-activation-farm-view-form__submit__first-button"
                       [disabled]="form.invalid"
                       [content]="'Cancel' | translate"
                       (click)="handleCancel()"></app-custom-button>
    <app-custom-button [disabled]="form.invalid"
                       [content]="'Add Farm view' | translate"
                       [color]="'green'"
                       (click)="handleSubmit()"></app-custom-button>
  </div>
</form>
