import { Component, forwardRef, Input, OnChanges, Renderer2, SimpleChange, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import * as localeAll from 'ngx-bootstrap/locale';
import { LanguageService } from '../../services/translation/language.service';

@Component({
  selector: 'app-custom-datepicker',
  templateUrl: './custom-datepicker.component.html',
  styleUrls: ['./custom-datepicker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomDatepickerComponent),
    multi: true
  }]
})

export class CustomDatepickerComponent implements ControlValueAccessor, OnChanges {
  @Input()
  public isOnlyFocus                         : boolean = true;
  @Input()
  public isSmall                             : boolean = false;
  @Input()
  public hideTime                            : boolean = false;
  @Input()
  public minDate                             : Date = new Date(0);
  @Input()
  public maxDate                             : Date = moment().toDate();
  @Input()
  public dateInputFormat                     : string = 'DD/MM/YYYY HH:mm';
  @Input()
  public hourStep                            : number = 1;
  @Input()
  public minuteStep                          : number = 1;
  @Input()
  public secondsStep                         : number = 1;
  @Input()
  public isDisabled                          : boolean = false;

  public date                                : Date;
  public time                                : Date;

  public isOpen                              : boolean = false;
  public displayed                           : string = '';
  public dateFormat                          : string = 'DD/MM/YYYY';

  private maxHours                           : number = 0;
  private maxMinutes                         : number = 0;

  private language                           : string = '';

  private propagateChange                    : any = () => { return; };

  constructor(
    private renderer: Renderer2,
    private languages: LanguageService,
    private localeService: BsLocaleService) {

      this.language = languages.languageFromStorage;
      if (localeAll[this.language + 'Locale']) {
        defineLocale(this.language, localeAll[this.language + 'Locale']);
        this.localeService.use(this.language);
      } else if (this.language === 'si') {
        defineLocale('sl', localeAll.slLocale);
        this.localeService.use('sl');
      } else if (this.language === 'br') {
        defineLocale('pt-br', localeAll.ptBrLocale);
        this.localeService.use('pt-br');
      } else {
        defineLocale('engb', localeAll.enGbLocale);
        this.localeService.use('engb');
      }
  }

  public writeValue(date: Date): void {
    const mom = moment(date);
    if (mom.isValid()) {
      this.displayed = mom.format(this.hideTime ? this.dateFormat : this.dateInputFormat);
      this.date = mom.toDate();
      this.time = mom.clone().toDate();
    } else {
      this.displayed = '';
      this.date = null;
      this.time = null;
      this.propagateChange(null);
    }
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    return;
  }

  public setDisabledState(isDisabled: boolean): void {
    return;
  }

  public open(): void {
    this.isOpen = true;
    this.maxHours = moment(this.maxDate).get('hours');
    this.maxMinutes = moment(this.maxDate).get('minutes');
    setTimeout(() => {
      const timePicker: Element = document.querySelector('.form-input__timepicker');
      const datePicker: Element = document.querySelector('.bs-datepicker-container');
      if (timePicker && datePicker) {
        this.renderer.appendChild(datePicker, timePicker);
      }
    }, 1);
  }

  public close(): void {
    this.isOpen = false;
    this.setDate();
  }

  private setDate(): void {
    if (!this.date) {
      return;
    }

    // If there is no time set, must initialize at 00:00
    if (!this.time) {
      this.time = new Date(new Date(this.date).setHours(0, 0, 0, 0));
    }

    let mom: Moment;
    const hours = moment(this.time).get('hours');
    const minutes = moment(this.time).get('minutes');

    if (moment(this.maxDate).diff(moment(this.date)) > 0) {
      mom = moment(this.date)
        .set('hours', moment(this.time).get('hours'))
        .set('minutes', moment(this.time).get('minutes'));
    } else {
      mom = moment(this.date)
        .set('hours', this.maxHours > hours ? hours : this.maxHours)
        .set('minutes', this.maxMinutes > minutes ? minutes : this.maxMinutes);
    }

    this.date = mom.clone().toDate();
    this.time = mom.clone().toDate();
    this.displayed = mom.format(this.hideTime ? this.dateFormat : this.dateInputFormat);
    this.propagateChange(mom.clone().toDate());

  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (Object.keys(changes).some(key => {
      const change: SimpleChange = changes[key];
      return (key === 'maxDate' || key === 'minDate') &&
        change.previousValue && change.currentValue.toString() !== change.previousValue.toString();
    })) {
      this.maxHours = moment(this.maxDate).get('hours');
      this.maxMinutes = moment(this.maxDate).get('minutes');
      this.setDate();
    }

    if (changes.hideTime) {
      this.displayed = moment(this.date).format(changes.hideTime.currentValue ? this.dateFormat : this.dateInputFormat);
    }
  }
}
