import { emptyNotification } from './../constants/notifications';
import {IActionWithPayload} from '../models/actionWithPayload';
import {NotificationsActionTypes} from '../actions/notifications';
import {INotification} from '../models/notifications';

export interface INotifications {
  notifications: Array<INotification>;
  selectedNotification: INotification;
}

const initialState: INotifications = {
  notifications: [
    emptyNotification
  ],
  selectedNotification: null
};

export function reducer(state: INotifications = initialState, action: IActionWithPayload ): INotifications {
  switch (action.type) {

    case NotificationsActionTypes.SET_USER_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.payload
      };
    }
    case NotificationsActionTypes.SET_SELECTED_NOTIFICATION: {
      return {
        ...state,
        selectedNotification: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
