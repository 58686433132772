import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { ISensor } from '../../../shared/interfaces';
import { IServerAlertData } from '../../server-alerts/interfaces/server-alert';
import { IServerAlertDataUpload } from '../models/alerts';

export enum ActionTypes {
  ADD_SERVER_ALERT = '[ALERTS] ADD_SERVER_ALERT',
  APPEND_SERVER_ALERT = '[ALERTS] APPEND_SERVER_ALERT',
  UPDATE_SERVER_ALERT = '[ALERTS] UPDATE_SERVER_ALERT',
  DELETE_SERVER_ALERT= '[SERVER_ALERTS] DELETE_SERVER_ALERT',
  GET_USER_ALERTS = '[ALERTS] GET_USER_ALERTS',
  SET_USER_ALERTS = '[ALERTS] SET_USER_ALERTS'
}

export function getUserAlerts(): IAction {
  return { type: ActionTypes.GET_USER_ALERTS, payload: null };
}

export function setUserAlerts(alerts: any): IAction {
  return { type: ActionTypes.SET_USER_ALERTS, payload: alerts};
}

export function addServerAlert(payload: IServerAlertData): IAction {
  return { type: ActionTypes.ADD_SERVER_ALERT, payload };
}

export function appendServerAlert(payload: IServerAlertData): IAction {
  return { type: ActionTypes.APPEND_SERVER_ALERT, payload };
}

export function updateServerAlert(payload: IServerAlertDataUpload): IAction {
  return { type: ActionTypes.UPDATE_SERVER_ALERT, payload };
}

export function deleteServerAlert(payload: IServerAlertDataUpload): IAction {
  return { type: ActionTypes.DELETE_SERVER_ALERT, payload };
}
