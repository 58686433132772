<a [ngClass]="{'search-widget-item-container' : true, 'selected': isSelected}"
   (click)="navigateUrl($event)">
    <div class="search-widget-item-info">
        <div class="text-right">
            <span class="lightgreen-label">{{item.farm.name}}</span>
            <i [ngStyle]="{'color': item.starred ? '#ffc107' : 'lightgrey'}" class="zmdi zmdi-star starIcon" (click)="toggleFavorite($event)"></i>
        </div>
        <div class="heading">{{item.name}} <i *ngIf="!item.boundary" class="fa fa-exclamation-triangle"></i></div>
        <div class="fieldName">{{item.field.name}}</div>
    </div>
</a>