import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import * as fromNotify from '../../../../core/reducers/notify';
import { IServerAlert } from '../../interfaces/server-alert';
import { selectServerAlerts } from '../../reducers';
import * as fromServerAlerts from '../../reducers/server-alerts';
import { ServerAlertsService } from '../../services/server-alerts.service';

@Component({
  selector: 'app-server-alerts-listing',
  templateUrl: './server-alerts-listing.component.html',
  styleUrls: ['./server-alerts-listing.component.scss']
})
export class ServerAlertsListingComponent implements OnInit, OnDestroy {

  @Input()
  public station: IStation;

  public alerts: IServerAlert[];
  private destroy$ = new Subject<null>();

  constructor(
    protected alertsService: ServerAlertsService,
    protected serverAlertsStore: Store<fromServerAlerts.IServerAlertsState>,
    protected notifyStore: Store<fromNotify.INotifyState>
  ) {

  }

  public ngOnInit(): void {

    this.serverAlertsStore.pipe(
      select(selectServerAlerts),
      takeUntil(this.destroy$)
    ).subscribe((alerts: IServerAlert[]) => {
       this.alerts = alerts;
    }

    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getSensorData(alert): {} {
    return this.alertsService.findSensorData(alert);
  }
}
