import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { IUltrasonicDistanceSettingsState } from '../models/models';
import { initialState } from '../states/states';
import { ActionTypes } from '../actions/actions';

export function UltrasonicDistanceSettingsReducer(state: IUltrasonicDistanceSettingsState = initialState, action: IAction): IUltrasonicDistanceSettingsState {
  switch (action.type) {
    case ActionTypes.SET_ULTRASONIC_SENSORS:
      return {
        ...state,
        sensors: action.payload
      };
    case ActionTypes.SET_ULTRASONIC_LOADING:
      return {
        ...state,
        dataLoading : {
          ...state.dataLoading,
          isLoading : action.payload
        }
      };
    case ActionTypes.SET_ULTRASONIC_ERROR:
      return {
        ...state,
        dataLoading : {
          ...state.dataLoading,
          error: {
            ...state.dataLoading.error,
            isError : action.payload
          }
        }
      };
    case ActionTypes.SET_ULTRASONIC_SETTINGS:
      return {
        ...state,
        ultrasonicSettings: action.payload
      };
    case ActionTypes.SET_CURRENT_VALUE:
      return {
        ...state,
        currentValue: action.payload
      };
    default:
      return state;
  }
}
