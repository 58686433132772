import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Chart } from 'angular-highcharts';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ISelectedSearchWidgetItem } from '../../../../core/models/selectedSearchWidgetItem';
import { IStation } from '../../../../core/models/stations';
import {
  selectNavigationStation,
  selectSelectedStation
} from '../../../../core/reducers';
import * as fromNavigationStation from '../../../../core/reducers/navigation-station';
import * as fromSelectedStation from '../../../../core/reducers/selectedStation';
import { ChartTranslationsService } from '../../../../core/services/chart-translations.service';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';
import { setTreeSetting, TreeSettingsActionTypes } from '../../../../shared/tree/actions/tree-settings';
import { ITreeSettingsState } from '../../../../shared/tree/models/tree.models';
import { setZoomTitle } from '../../../../shared/utils/setZoomTitle';
import {
  selectStationDataChartActive,
  selectStationDataCharts,
  selectStationDataSettingsPeriod,
  selectTreeSettings
} from '../../reducers';
import * as fromStationData from '../../reducers/station-data';
import * as fromStationSettings from '../../reducers/station-data-settings';

@Component({
  selector: 'app-station-data-charts',
  templateUrl: './station-data-charts.component.html',
  styleUrls: ['./station-data-charts.component.scss']
})
export class StationDataChartsComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public exportChartImg                    : boolean;
  public isChartActive$                    : Observable<boolean>;
  public charts                            : Array<Chart> = [];
  public chartsSeries                      : any = {};
  public isAllChartsDisabled               : boolean = false;
  private hashMap                          : any = {};
  private tree                             : any = {};
  private destroy$                         : Subject<boolean> = new Subject<boolean>();
  private stationId                        : string = '';
  private profile                          : string = 'All sensors';
  private loggingInterval                  : number;
  private period                           : string;

  constructor(
    private store: Store<fromStationData.IStationDataState>,
    private stationSettingsStore: Store<fromStationSettings.IStationDataSettingsState>,
    private selectedStationStore: Store<fromSelectedStation.ISelectedStationState>,
    private treeSettingsStore: Store<ITreeSettingsState>,
    private chartTranslationsService: ChartTranslationsService,
    private navigationStore: Store<fromNavigationStation.INavigationStationState>,
    private exportService: StationDataExportService,
  ) { }

  public ngOnInit(): void {
    this.chartTranslationsService.translateShortMonths();

    this.selectedStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectSelectedStation),
      switchMap((id: ISelectedSearchWidgetItem) => this.treeSettingsStore.pipe(
        tap(() => this.stationId = id.original_name),
        takeUntil(this.destroy$),
        select(selectTreeSettings),
        filter((tree: ITreeSettingsState): boolean => !!tree
          && !!tree[id.original_name]
          && !!tree[id.original_name].profileSettings[this.profile]),
        tap((tree: ITreeSettingsState): void => {
          this.profile = tree[id.original_name].selectedProfile;
        }),
        map((tree: ITreeSettingsState) => tree[id.original_name].profileSettings[this.profile].disabledGroupIds)
      ))
    ).subscribe((tree: any) => {
      this.tree = tree;
      this.setChartsVisible();
    });

    this.navigationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((station: IStation): boolean => !!station),
      distinctUntilChanged(),
    ).subscribe((station: IStation) => {
      this.loggingInterval = station.config.logging_interval;
    });

    this.stationSettingsStore.pipe(
      select(selectStationDataSettingsPeriod)
    ).subscribe(periodSettings => {
      this.period = periodSettings.periodScope;
    });

    this.isChartActive$ = this.stationSettingsStore.pipe(
      takeUntil(this.destroy$),
      select(selectStationDataChartActive)
    );
    this.store.pipe(
      takeUntil(this.destroy$),
      select(selectStationDataCharts),
      filter((chartsData: any) => !!(Array.isArray(chartsData) && chartsData))
    ).subscribe((chartsData: any) => {
      this.charts = [];
      this.hashMap = {};
      this.chartsSeries = {};
      chartsData.forEach((item, index) => {
        this.chartsSeries[index] = {
          isDisabled: true,
          series: []
        };
        item.series.forEach((s, i) => {
          const groupId: string = s.groupId;
          if (groupId) {
            this.chartsSeries[index].series.push(groupId);
            if (!this.hashMap[groupId]) {
              this.hashMap[groupId] = [];
            }
            this.hashMap[groupId].push({
              chart: index,
              series: i
            });
          }
        });
        const chart: Chart = new Chart(item);
        chart.options.plotOptions = {
          series: {
            events: {
              legendItemClick: (event: any) => {
                event.preventDefault();
                this.changeVisible(event.target.options.groupId);
              }
            }
          },
        };
        setZoomTitle(chart);
        this.charts.push(chart);
      });
      this.setChartsVisible();

      // set the yAxis max for sunshine duration depending on loggingInterval for the raw data
      const sunshineSensor = 'sensor_x_x_25_145';
      const sunshineOnChart = this.hashMap.hasOwnProperty(sunshineSensor) ? this.hashMap[sunshineSensor] : null;
      if (sunshineOnChart && this.period === 'raw') {
        this.charts[sunshineOnChart[0].chart].options.yAxis[0].max = this.loggingInterval;
      }
    });
  }

  public ngOnChanges(): void {
    if (this.charts && this.exportChartImg) {
      const fileName = 'station_data_charts_' + moment(new Date()).toISOString() + '.png';
      this.exportService.exportChartsToImage(fileName);
    }
  }

  private checkChartsActive(): void {
    let disabledChartsCount: number = 0;
    Object.keys(this.chartsSeries).forEach((c) => {
      let chartSeriesDisabled: number = 0;
      this.chartsSeries[c].series.forEach((s) => {
        if (this.tree[s]) {
          chartSeriesDisabled++;
        }
      });
      if (this.chartsSeries[c].series.length === chartSeriesDisabled) {
        this.chartsSeries[c].isDisabled = true;
        disabledChartsCount++;
      } else {
        this.chartsSeries[c].isDisabled = false;
      }
    });

    this.isAllChartsDisabled = this.charts.length === disabledChartsCount;
  }

  private setChartsVisible(): void {
    Object.keys(this.hashMap).forEach((key) => {
      const hash = this.hashMap[key];
      hash.forEach((h) => {
        if (this.charts[h.chart].ref) {
          if (this.tree[key] === undefined) {
            if (!this.charts[h.chart].options.series[h.series].visible) {
              this.charts[h.chart].ref.series[h.series].show();
            }
          } else {
            if (this.charts[h.chart].options.series[h.series].visible) {
              this.charts[h.chart].ref.series[h.series].hide();
            }
          }
        } else {
          this.charts[h.chart].options.series[h.series].visible = this.tree[key] === undefined;
        }
      });
    });
    this.checkChartsActive();
  }

  public changeVisible(groupID: string): void {
    this.treeSettingsStore.dispatch(setTreeSetting({
      groupdId: groupID,
      profile: this.profile,
      stationId: this.stationId
    }, TreeSettingsActionTypes.SET_STATION_DATA_TREE_SETTINGS));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
