import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../../shared/shared.module';
import {WorkPlanningRoutingModule} from './work-planning-routing.module';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {WorkPlanningEffects} from './effects/workplanning.service';
import {reducers} from './reducers';
import {ChartModule, HIGHCHARTS_MODULES} from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import {AgGridModule} from 'ag-grid-angular';
import {StationDataExportService} from '../../shared/services/export/station-data-export.service';
import { WorkPlanningHelpModalComponent } from './components/work-planning-help-modal/work-planning-help-modal.component';
import { WorkPlanningComponent } from './work-planning.component';
import { WorkPlanningHeaderComponent } from './components/work-planning-header/work-planning-header.component';
import { WorkPlanningChartsComponent } from './components/work-planning-charts/work-planning-charts.component';
import { WorkPlanningContentComponent } from './containers/work-planning-content/work-planning-content.component';
import { WorkPlanningWarningsComponent } from './components/work-planning-warnings/work-planning-warnings.component';
import { WorkPlanningTableComponent } from './components/work-planning-table/work-planning-table.component';
import { WorkPlanningToolbarComponent } from './components/work-planning-toolbar/work-planning-toolbar.component';

@NgModule({
  imports: [
    CommonModule,
    WorkPlanningRoutingModule,
    SharedModule,
    StoreModule.forFeature('workPlanning', reducers),
    EffectsModule.forFeature([WorkPlanningEffects]),
    TranslateModule.forChild(),
    ChartModule,
    AgGridModule.withComponents([]),
  ],
  declarations: [
    WorkPlanningComponent,
    WorkPlanningHeaderComponent,
    WorkPlanningChartsComponent,
    WorkPlanningContentComponent,
    WorkPlanningHelpModalComponent,
    WorkPlanningWarningsComponent,
    WorkPlanningTableComponent,
    WorkPlanningToolbarComponent,
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [ more, exporting ] }, // add as factory to your providers
    StationDataExportService
  ]
})
export class WorkPlanningModule { }
