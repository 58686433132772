<app-camera-data-table-cropview
  *ngIf="isCropView"
  [rowData]="rowData"
  [additionalColumnDefs]="additionalColumnDefs"
  (exportListener)="exportedDataListener($event)"
></app-camera-data-table-cropview>

<app-camera-data-table-iscout
  *ngIf="isIScout"
  [rowData]="rowData"
  [additionalColumnDefs]="additionalColumnDefs"
  (exportListener)="exportedDataListener($event)"
></app-camera-data-table-iscout>
