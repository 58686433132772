import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromServerAlerts from './server-alerts';

export interface IState {
  serverAlerts: fromServerAlerts.IServerAlertsState;
}

export const reducers = {
  serverAlerts: fromServerAlerts.reducer
};

export const selector = createFeatureSelector<IState>('serverAlerts');

export const selectServerAlerts = createSelector(
  selector,
  state => state?.serverAlerts.alerts
);

export const selectStationSensors = createSelector(
  selector,
  state => state.serverAlerts.sensors
);
