import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { cardAnimation } from '../../../../shared/animations/card-animations';
import { getStations } from '../../../actions/stations';
import { IDataUploadBasic } from '../../../models/data-upload';
import { IStations } from '../../../reducers/stations';

@Component({
  selector: 'app-data-upload',
  templateUrl: './data-upload.component.html',
  styleUrls: ['./data-upload.component.scss'],
  animations: [cardAnimation()]
})
export class DataUploadComponent {

  private formData: FormData;
  public filename: string;
  public filetype: string;
  public errorUpload = '';
  public blockUpload = true;
  public uploadMessage = false;
  public basicInfo: IDataUploadBasic;

  constructor(
    private api: ApiCallService,
    private store: Store<IStations>
  ) {

  }

  public uploadData(event): void {
    const file = event.target.files[0];

    this.formData = new FormData();
    this.filename = null;
    this.filetype = null;
    this.blockUpload = true;

    if (file && file.size) {
      this.filename = file.name;
      this.filetype = file.type;

      this.formData.append('dataFile', file);

      this.basicInfo = null;
      this.errorUpload = null;
      event.target.value = '';

      this.blockUpload = false;
    }
  }

  public submitFile(): void {

    this.blockUpload = true;
    this.uploadMessage = true;

    this.api.uploadSampleDataFile(this.formData).subscribe(
      value => this.basicInfo = value,
      data => {
        this.errorUpload = data.error.message;
        this.uploadMessage = false;
        this.formReset();
      },
      () => {
        this.store.dispatch(getStations());
        this.uploadMessage = false;
        this.formReset();
      }
    );
  }

  public parseDate(date): string {
    return moment.unix(date).utc().format('YYYY-MM-DD HH:mm:ss');
  }
  private formReset(): void {
    this.formData = null;
    this.filename = null;
  }
}
