import { AccumulatorToolActionTypes } from '../actions/accumulatorTool';
import { IAccumulatorThresholdState } from '../models/accumulatorTool';
import { IActionWithPayload } from '../models/actionWithPayload';

const initialState: IAccumulatorThresholdState = {
    thresholds: {
        degree_days: {
            lower_metric_value: 10,
            upper_metric_value: 24,
            lower_imperial_value: 50,
            upper_imperial_value: 86
        },
        chilling_units: {
            lower_metric_value: 2,
            upper_metric_value: 7,
            lower_imperial_value: 36,
            upper_imperial_value: 45
        }
    }
};

export function reducer(state: IAccumulatorThresholdState = initialState, action: IActionWithPayload): IAccumulatorThresholdState {
    switch (action.type) {
        case AccumulatorToolActionTypes.SET_THRESHOLDS:
            return <IAccumulatorThresholdState> {
                ...state,
                thresholds: {
                    ...state.thresholds,
                    degree_days: action.payload.degree_days,
                    chilling_units: action.payload.chilling_units
                }
            };
        default:
            return state;
    }
}
