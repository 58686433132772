import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ICropZoneMap} from '../../../core/models/cropzones';
import {selectCropzonesAsMap} from '../../../core/reducers';
import {LicenseType} from '../../product-activation/constants/constants';
import {IActiveLicense, IUserLicensesState} from '../models/user-licenses.models';
import * as fromUserLicenses from './user-licenses';

export interface IState {
  userLicenses: IUserLicensesState;
}

export const reducers = {
  userLicenses: fromUserLicenses.reducer
};

export const featureSelector = createFeatureSelector<IState>('userLicenses');

export const selectUserLicenses = createSelector(
  featureSelector,
  selectCropzonesAsMap,
  (userLicensesState: IState, cropZoneMap: ICropZoneMap): Array<IActiveLicense> => {
    if (Object.keys(cropZoneMap).length === 0) {
      return userLicensesState.userLicenses.validLicenses;
    }

    return userLicensesState.userLicenses.validLicenses.map((validLicense: IActiveLicense) => {
      const found = validLicense.products.find(
        (product) => product.license_group === LicenseType.FARM_VIEW
      );
      if (found !== undefined) {
        return {
          ...validLicense,
          stations: validLicense.stations
            .filter((stationID: string) => cropZoneMap[stationID] !== undefined)
            .map((stationID: string) => cropZoneMap[stationID].name),
        };
      }
      return validLicense;
    });
  }
);

export const selectUserLicenseSelected = createSelector(
  featureSelector,
  (userLicenseState): IActiveLicense => userLicenseState.userLicenses.selectedLicense
);
