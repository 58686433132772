import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notifications-configuration-header',
  templateUrl: './notifications-configuration-header.component.html',
  styleUrls: ['./notifications-configuration-header.component.scss']
})
export class NotificationsConfigurationHeaderComponent {
  @Input()
  public header     : string = '';
  @Input()
  public subTitle   : string = '';
}
