import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { selectSelectedStation } from '../../../../core/reducers';
import { ISelectedStationState } from '../../../../core/reducers/selectedStation';
import { PlotbandModeLabels, SensorGroupType, SENSOR_GROUPS, ViewType } from '../../../../modules/soil-moisture/constants';
import { IView } from '../../../../modules/soil-moisture/models';
import { ITopologyItem, ITopologySensorData } from '../../../../services/tree/models';
import { TreeService } from '../../../../services/tree/tree.service';
import { SOIL_MOISTURE_PLOTBAND_EDITOR_MODAL_ID } from '../../../../shared/constants';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { ITreeSettingsState } from '../../../../shared/tree/models/tree.models';
import { postView, putView } from '../../actions';
import { status, topology as topologySelector, treeSettings, views as viewsSelector } from '../../selectors';
import { IState, IStateStatus } from '../../states';

@Component({
  selector: 'app-soil-moisture-plotband-editor',
  templateUrl: './soil-moisture-plotband-editor.component.html',
  styleUrls: ['./soil-moisture-plotband-editor.component.scss']
})
export class SoilMoisturePlotbandEditorComponent implements OnInit, OnDestroy {

  public status$:             Observable<IStateStatus>;
  public form:                FormGroup;
  public SensorGroupType      = SensorGroupType;
  public ViewType             = ViewType;
  public PlotbandModeLabels   = PlotbandModeLabels;
  public hasSensorTypes:      {[key: string]: boolean} = {
                                [SensorGroupType.TENSIOMETRIC]: false,
                                [SensorGroupType.VOLUMETRIC]: false
                              };

  public view:               IView;
  private node:               string;
  private destroy$:           Subject<boolean> = new Subject<boolean>();

  @Output()
  public modalClosed = new EventEmitter();

  constructor(
    private store: Store<IState>,
    private stationStore: Store<ISelectedStationState>,
    private settings: Store<ITreeSettingsState>,
    private treeService: TreeService,
    private formBuilder: FormBuilder,
    private modalService: ModalService
  ) {}

  public ngOnInit(): void {

    this.status$ = this.store.pipe(select(status), debounceTime(100));

    this.form = this.formBuilder.group({
        volumetricRefillPoint: [''],
        volumetricFullPoint: [''],
        tensiometricRefillPoint: [''],
        tensiometricFullPoint: [''],
        mode: ['']
    }, {
      validator: (form) => {
        const {
          volumetricRefillPoint,
          volumetricFullPoint,
          tensiometricRefillPoint,
          tensiometricFullPoint
        } = form.value;
        return (
          (volumetricRefillPoint && volumetricFullPoint && +volumetricRefillPoint < +volumetricFullPoint) ||
          (tensiometricRefillPoint && tensiometricFullPoint && +tensiometricRefillPoint > +tensiometricFullPoint)
          ? null : {
              mode: false
          }
        );
      }
    } as AbstractControlOptions);

    this.treeService.treeItemSettingsClicked$.pipe(
      debounceTime(100),
      withLatestFrom(
        this.store.pipe(select(topologySelector)),
        this.store.pipe(select(viewsSelector)),
        this.settings.pipe(select(treeSettings)),
        this.stationStore.pipe(select(selectSelectedStation), map(s => s.original_name))
      ),
      takeUntil(this.destroy$)
    ).subscribe(([itemName, topology, views, settings, stationId]) => {
      const id = settings[stationId].selectedProfile;
      const view = views.find(v => v._id && v._id === id || v.name === id);
      if (view) {
        this.form.reset();
        this.view = JSON.parse(JSON.stringify(view));
        if (!this.view.station_id) {
          this.view.station_id = stationId;
        }
        this.node = itemName;
        this.hasSensorTypes = this.getSensorTypes(itemName, topology);
        if (this.view.configuration && this.view.configuration.plotBands) {
          const plotBand = this.view.configuration.plotBands[this.node];
          if (this.hasSensorTypes[SensorGroupType.VOLUMETRIC] && plotBand && plotBand.volumetric) {
            this.form.controls.volumetricRefillPoint.setValue(plotBand.volumetric.refill_point);
            this.form.controls.volumetricFullPoint.setValue(plotBand.volumetric.field_capacity);
          }
          if (this.hasSensorTypes[SensorGroupType.TENSIOMETRIC] && plotBand && plotBand.tensiometric) {
            this.form.controls.tensiometricRefillPoint.setValue(plotBand.tensiometric.refill_point);
            this.form.controls.tensiometricFullPoint.setValue(plotBand.tensiometric.field_capacity);
          }
          if (plotBand && plotBand.mode) {
            this.form.controls.mode.setValue(plotBand.mode);
          }
        }
        this.modalService.openModal(SOIL_MOISTURE_PLOTBAND_EDITOR_MODAL_ID);
      }
    });

  }

  public viewIsResetable(): boolean {
    return (
      this.view && this.view._id &&
      this.view.configuration &&
      this.view.configuration.plotBands &&
      this.view.configuration.plotBands[this.node] && (
        (this.view.configuration.plotBands[this.node].volumetric &&
          !!this.view.configuration.plotBands[this.node].volumetric.field_capacity) ||
        (this.view.configuration.plotBands[this.node].tensiometric &&
          !!this.view.configuration.plotBands[this.node].tensiometric.field_capacity)
      )
    );
  }

  public close(): void {
    this.modalService.closeModal(SOIL_MOISTURE_PLOTBAND_EDITOR_MODAL_ID);
  }

  public reset(): void {
    this.put({
      ...this.view,
      configuration: {
        plotBands: {
          ...(this.view.configuration ? this.view.configuration.plotBands : {}),
          [this.node]: {
            volumetric: {},
            tensiometric: {},
            mode: PlotbandModeLabels[this.view.type][0].value
          }
        }
      }
    });

    this.close();
  }

  public save(): void {

    const {
      volumetricRefillPoint,
      volumetricFullPoint,
      tensiometricRefillPoint,
      tensiometricFullPoint,
      mode
    } = this.form.value;

    const view = {
      ...this.view,
      configuration: {
        plotBands: {
          ...(this.view.configuration ? this.view.configuration.plotBands : {}),
          [this.node]: {
            mode: mode,
            volumetric: (
              this.hasSensorTypes[SensorGroupType.VOLUMETRIC] &&
              volumetricRefillPoint && volumetricFullPoint &&
              +volumetricRefillPoint < +volumetricFullPoint) ? {
              refill_point: +this.form.controls.volumetricRefillPoint.value,
              field_capacity: +this.form.controls.volumetricFullPoint.value
            } : undefined,
            tensiometric: (
              this.hasSensorTypes[SensorGroupType.TENSIOMETRIC] &&
              tensiometricRefillPoint && tensiometricFullPoint &&
              +tensiometricRefillPoint > +tensiometricFullPoint)  ? {
              refill_point: +this.form.controls.tensiometricRefillPoint.value,
              field_capacity: +this.form.controls.tensiometricFullPoint.value
            } : undefined
          }
        }
      }
    };

    if (this.view._id) {
      this.put(view);
    } else {
      this.post(view);
    }

    this.close();

  }

  private put(view: IView): void {
    this.store.dispatch(putView(view));
  }

  private post(view: IView): void {
    this.store.dispatch(postView({
      ...view,
      _id: undefined
    }));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.modalService.deleteModal(SOIL_MOISTURE_PLOTBAND_EDITOR_MODAL_ID);
  }

  private getSensorTypes(itemName: string, topology: ITopologyItem[]): { [key: string]: boolean } {

    function has(group: SensorGroupType): (sensor: ITopologySensorData) => boolean {
      return function (sensor: ITopologySensorData): boolean {
        return SENSOR_GROUPS[group].includes(sensor.sensor.group);
      };
    }

    for (let i = 0; i < topology.length; i++) {
      const topo = topology[i];
      if (topo.name === itemName) {
        return {
          [SensorGroupType.TENSIOMETRIC]: topo.sensors.some(has(SensorGroupType.TENSIOMETRIC)),
          [SensorGroupType.VOLUMETRIC]: topo.sensors.some(has(SensorGroupType.VOLUMETRIC))
        };
      }
      if (topo.nodes) {
        for (let j = 0; j < topo.nodes.length; j++) {
          const node = topo.nodes[j];
          if (node.name === itemName) {
            return {
              [SensorGroupType.TENSIOMETRIC]: node.sensors.some(has(SensorGroupType.TENSIOMETRIC)),
              [SensorGroupType.VOLUMETRIC]: node.sensors.some(has(SensorGroupType.VOLUMETRIC))
            };
          }
        }
      }
    }

  }

  public onModalClosed(): void {
    this.modalClosed.emit();
  }

}
