import { Component, EventEmitter, OnInit, Output, Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IDeleteIscoutSeasonBody } from '../../../../../shared/camera/models/camera';
import {
  ISCOUT_SEASON_DELETE_MODAL_ID,
  ISCOUT_SEASON_DETAIL_MODAL_ID,
} from '../../../../../shared/constants';
import { ModalService } from '../../../../../shared/modal/services/modal.service';
import { generateId } from '../../../../dashboard/utils/makeWidget';
import { IIscoutSeason, IIscoutSeasonState, IIscoutSettingsState } from '../../../models/iscout.models';
import { selectIscoutSeasonList } from '../../../reducers';
import { IThemeConfig } from '../../../../../../environments/interfaces/theme';
import { environmentToken } from '../../../../../../environments/environment';
import { IEnvironment } from '../../../../../../environments/interfaces/environment';

@Component({
  selector: 'app-iscout-season-list',
  templateUrl: './iscout-season-list.component.html',
  styleUrls: ['./iscout-season-list.component.scss'],
})
export class IscoutSeasonListComponent implements OnInit {
  @Output()
  public seasonAdded: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public seasonEdited: EventEmitter<IIscoutSeason> = new EventEmitter<IIscoutSeason>();
  @Output()
  public seasonRemoved: EventEmitter<IDeleteIscoutSeasonBody> = new EventEmitter<IDeleteIscoutSeasonBody>();

  public seasonDetailModalId      = ISCOUT_SEASON_DETAIL_MODAL_ID;
  public seasonRemoveModalId      = ISCOUT_SEASON_DELETE_MODAL_ID;
  public seasonList$              : Observable<Array<IIscoutSeason>>;
  public seasonSelection          : IIscoutSeason = null;
  public helpModalId              : string = generateId();

  public subDomain                : IThemeConfig;

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    private iscoutStore           : Store<IIscoutSeasonState>,
    private iscoutSettingsStore   : Store<IIscoutSettingsState>,
    private modalService          : ModalService
  ) {
    this.subDomain = environment.theme;
  }

  public ngOnInit(): void {
    this.seasonList$ = this.iscoutStore.pipe(select(selectIscoutSeasonList));
  }

  public handleToggleHelp(): void {
    this.modalService.openModal(this.helpModalId);
  }

  public handleSeasonAdd(): void {
    this.seasonAdded.emit(null);
  }

  public handleSeasonEdit(season: IIscoutSeason): void {
    this.seasonEdited.emit(season);
  }

  public handleSeasonDetail(selected: IIscoutSeason): void {
    this.seasonSelection = selected;
    this.modalService.openModal(this.seasonDetailModalId);
  }

  public handleSeasonRemove(selected: IIscoutSeason): void {
    this.seasonSelection = selected;
    this.modalService.openModal(this.seasonRemoveModalId);
  }

  public handleSeasonRemoveConfirmation(): void {
    const {nm, _id} = this.seasonSelection;
    this.seasonRemoved.emit({station_id: nm, season_id: _id});
    this.handleCloseModal(this.seasonRemoveModalId);
  }

  public handleCloseModal(seasonModal: string): void {
    this.modalService.closeModal(seasonModal);
    this.seasonSelection = null;
  }
}
