import {IWeatherForecastImageState} from '../models/models';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {WeatherForecastImageActionTypes} from '../actions/weather-forecast-image';

export const initialState: IWeatherForecastImageState = {
  imageUrl: null,
  isError: false,
  isLoading: true
};

export function reducer(state: IWeatherForecastImageState = initialState, action: IActionWithPayload): IWeatherForecastImageState {
  switch (action.type) {
    case WeatherForecastImageActionTypes.SET_WEATHER_FORECAST_IMAGE:
      return <IWeatherForecastImageState>{
        ...state,
        imageUrl: action.payload
      };
    case WeatherForecastImageActionTypes.SET_WEATHER_FORECAST_IMAGE_LOADING:
      return <IWeatherForecastImageState>{
        ...state,
        isLoading: action.payload
      };
    case WeatherForecastImageActionTypes.SET_WEATHER_FORECAST_IMAGE_ERROR:
      return <IWeatherForecastImageState>{
        ...state,
        isError: action.payload
      };
    default:
      return state;
  }
}
