import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ILicenseActivationItem, IProductConfigurationItem } from '../../../models/product-activation.models';

@Component({
  selector: 'app-product-activation-davis-ingest-form',
  templateUrl: './product-activation-davis-ingest-form.component.html',
  styleUrls: ['./product-activation-davis-ingest-form.component.scss'],
})
export class ProductActivationDavisIngestFormComponent implements OnInit {
  @Input()
  public cancelable: boolean = false;
  @Input()
  public configuration: IProductConfigurationItem;
  @Output()
  public activateProductEmitter: EventEmitter<ILicenseActivationItem> = new EventEmitter<ILicenseActivationItem>();
  @Output()
  public cancel: EventEmitter<void> = new EventEmitter<void>();

  public form: FormGroup;
  public currentDate: Date = new Date();
  public maxDate: Date = moment().add(2, 'year').toDate();

  constructor(private formBuilder: FormBuilder) { }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      'starting_date': [this.currentDate, [Validators.required]]
    });
  }

  public get licenseDuration(): string {
    return moment.duration(this.configuration.license_duration).humanize();
  }

  public get startingDate(): AbstractControl {
    return this.form.get('starting_date');
  }

  public handleSubmit(): void {
    const { starting_date } = this.form.value;
    this.activateProductEmitter.emit(<ILicenseActivationItem>{
      product_key: this.configuration.product_key,
      product_pos: this.configuration.pos,
      license_duration: this.configuration.license_duration,
      starting_date
    });
  }

  public handleCancel(): void {
    this.cancel.emit();
  }
}
