import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-search-widget-input',
  templateUrl: './search-widget-input.component.html',
  styleUrls: ['./search-widget-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SearchWidgetInputComponent),
    multi: true
  }]
})
export class SearchWidgetInputComponent implements ControlValueAccessor {
  @Input()
  public placeholder: string = 'Search';

  public _value: string = '';

  private propagateChange: any = () => { return; };

  public get value(): string {
    return this._value;
  }

  public set value(value: string) {
    this._value = value;
  }

  public onChange(event: any): void {
    this.propagateChange(event.target.value);
  }

  public writeValue(value: any): void {
    this._value = value;
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    return;
  }

  public setDisabledState(isDisabled: boolean): void {
    return;
  }
}
