<agm-map #AgmMap
  (zoomChange)="zoomChanged($event)" [latitude]="46" [longitude]="13" [zoom]="10"
  [disableDefaultUI]="true" [zoomControl]="true" [scrollwheel]="false" [streetViewControl]="false"
  [mapTypeId]="'satellite'" [mapTypeControl]="true">

  <ng-container>
    <ng-container *ngFor="let trip of data; let i = index">
      <agm-polyline *ngIf="trip.selected" [strokeColor]="trip.color" [strokeOpacity]="0.8" [strokeWeight]="4" [clickable]="true">
        <ng-container *ngFor="let point of trip.path; let j = index">
          <agm-polyline-point [latitude]="point.lat" [longitude]="point.lng">
          </agm-polyline-point>
          <agm-marker *ngIf="j === 0 || j === trip.path.length - 1" [iconUrl]="getIconUrl(j === trip.path.length - 1, j === 0)"
            [latitude]="point.lat" [longitude]="point.lng">
            <agm-info-window>
              <ng-container *ngIf="j === 0">
                {{ 'Trip start' | translate }}: {{trip.tripStart}}
              </ng-container>
              <ng-container *ngIf="j === trip.path.length - 1">
                {{ 'Trip end' | translate }}: {{trip.tripEnd}}
              </ng-container>
            </agm-info-window>
          </agm-marker>
        </ng-container>
      </agm-polyline>      
    </ng-container>

    <agm-marker *ngFor="let point of selectedPoints" [iconUrl]="{url: markerIcon.intermediate,  anchor : {x:5, y:5}}" [latitude]="point.position.lat" [longitude]="point.position.lng"></agm-marker>
  </ng-container>
</agm-map>