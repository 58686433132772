import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {TimezoneAndLocationActionTypes} from '../actions/timezoneAndLocation';
import {IPosition} from '../../../core/models/stations';
import {DefaultStationPosition} from '../constants/constants';

export interface ITimezoneAndLocationState {
  position: IPosition;
  locations: any;
}

const initialState: ITimezoneAndLocationState = {
  position: {
    ...DefaultStationPosition
  },
  locations: null
};

export function reducer(state: ITimezoneAndLocationState = initialState, action: IActionWithPayload ): ITimezoneAndLocationState {
  switch (action.type) {
    case TimezoneAndLocationActionTypes.SET_LOCATION_SETTINGS: {
      return {
        ...state,
        position: action.payload
      };
    }
    case TimezoneAndLocationActionTypes.SET_TIMEZONES: {
      return {
        ...state,
        locations: action.payload
      };
    }
    case TimezoneAndLocationActionTypes.SET_COORDINATES: {
      return {
        ...state,
        position: {
          ...state.position,
          geo: {
            coordinates: action.payload
          }
        }
      };
    }
    case TimezoneAndLocationActionTypes.SET_ELEVATION: {
      return {
        ...state,
        position: {
          ...state.position,
          altitude: action.payload
        }
      };
    }
    case TimezoneAndLocationActionTypes.SET_TIMEZONE: {
      return {
        ...state,
        position: {
          ...state.position,
          timezoneCode: action.payload
        }
      };
    }
    default: {
      return state;
    }
  }
}
