import { LatLngLiteral } from '@agm/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DefaultStationPosition } from '../../../../../modules/station-config/constants/constants';
import { ADD_REMOVE_VIRTUAL_STATION_MODAL_ID } from '../../../../../shared/constants';
import { ModalService } from '../../../../../shared/modal/services/modal.service';
import { IAddRemoveVirtualStation, IPosition } from '../../../../models/stations';

@Component({
  selector: 'app-add-remove-virtual-station-form',
  templateUrl: './add-remove-virtual-station-form.component.html',
  styleUrls: ['./add-remove-virtual-station-form.component.scss']
})
export class AddRemoveVirtualStationFormComponent implements OnInit {
  @Input()
  public isAddForm: boolean = true;
  @Output()
  private positionChanged: EventEmitter<IPosition> = new EventEmitter<IPosition>();
  @Output()
  private submit: EventEmitter<IAddRemoveVirtualStation> = new EventEmitter<IAddRemoveVirtualStation>();

  public position: IPosition;
  public form: FormGroup;

  constructor(private fb: FormBuilder,
              private modalService: ModalService) { }

  public get key(): AbstractControl {
    return this.form.get('key');
  }

  public get customName(): AbstractControl {
    return this.form.get('name');
  }

  public get submitColor(): string {
    return this.isAddForm ? 'green' : 'red';
  }

  public get submitContent(): string {
    return this.isAddForm ? 'Add virtual station' : 'Remove virtual station';
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      'key': ['', [Validators.required]],
    });
    if (this.isAddForm) {
      this.form.addControl('name', this.fb.control(''));
      this.form.addControl('position', this.fb.control('', Validators.required));
      this.resetStationPosition();
    }
  }

  public selectLocationFromMap($event: { coords: LatLngLiteral }): void {
    this.position.geo.coordinates = [$event.coords.lng, $event.coords.lat];
    this.form.get('position').setValue(this.position);
    this.positionChanged.emit(this.position);
  }

  public submitEmit(): void {
    this.submit.emit(this.form.value);
    this.form.reset();
    this.resetStationPosition();
  }

  public close(): void {
    this.form.reset();
    this.modalService.closeModal(ADD_REMOVE_VIRTUAL_STATION_MODAL_ID);
  }

  private resetStationPosition(): void {
    this.position = {
      geo: DefaultStationPosition.geo,
      altitude: DefaultStationPosition.altitude,
      timezoneCode: DefaultStationPosition.timezoneCode
    };
  }
}
