<div class="animal-production-charts-container" *ngIf="!(isLoading || isError); else loadinfo">
  <ng-container *ngFor="let item of (chartsAndLegends$ | async) as items; let i = index">
    <div [chart]="item.chart"></div>
    <div class="animal-production-charts-container__chartlegend" *ngIf="item.legends">
        <span *ngFor="let legendItem of item.legends"
          class="label"
          [style.background-color]="legendItem.color">
          {{ legendItem.label | translate }}
        </span>
    </div>
  </ng-container>
</div>

<ng-template #loadinfo>
  <app-station-load-info [isError]="isError" [isLoading]="isLoading"></app-station-load-info>
</ng-template>
