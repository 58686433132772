import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notifications-configuration-warning',
  templateUrl: './notifications-configuration-warning.component.html',
  styleUrls: ['./notifications-configuration-warning.component.scss']
})
export class NotificationsConfigurationWarningComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
