<div class="remove">
    <div class="remove__header">
        <div (click)="removeCropzone()" [class.remove__header-item__inactive]="!isRemoveCropzone"
            class="remove__header-item"><i class="zmdi zmdi-minus-circle"></i> {{ 'Cropzone' | translate }}</div>
        <div (click)="removeField()" [class.remove__header-item__inactive]="!isRemoveField" class="remove__header-item">
            <i class="zmdi zmdi-minus-circle"></i> {{ 'Field' | translate }}</div>
        <div (click)="removeFarm()" [class.remove__header-item__inactive]="!isRemoveFarm" class="remove__header-item"><i
                class="zmdi zmdi-minus-circle"></i> {{ 'Farm' | translate }}</div>
    </div>
    <div class="remove__form">
        <div class="alert alert-info" *ngIf="!additionalMessageWindowField && !additionalMessageFarm">
            <ng-container *ngIf="isRemoveCropzone">
                &bull; {{ 'Farm, Field and Cropzone name: select from the existing names.' | translate }}
            </ng-container>
            <ng-container *ngIf="isRemoveField">
                &bull; {{ 'Farm and Field name: select from the existing names.' | translate }}
            </ng-container>
            <ng-container *ngIf="isRemoveFarm">
                &bull; {{ 'Farm name: select from the existing names.' | translate }}
            </ng-container>
        </div>
        <app-remove-cropzone-form [hidden]="!isRemoveCropzone" (submit)="submitCropzone($event)">
        </app-remove-cropzone-form>
        <app-remove-field-form [hidden]="!isRemoveField" *ngIf="!additionalMessageWindowField"
            (submit)="submitField($event)"></app-remove-field-form>
        <app-remove-field-confirmation (resetModal)="resetModalAfterDelete($event)" [field]="fieldToRemove"
            *ngIf="additionalMessageWindowField"></app-remove-field-confirmation>

        <app-remove-farm-form [hidden]="!isRemoveFarm" (submit)="submitFarm($event)" *ngIf="!additionalMessageFarm"></app-remove-farm-form>
        <app-remove-farm-confirmation (resetModal)="resetModalAfterDelete($event)" [farm]="farmToRemove"
            *ngIf="additionalMessageFarm"></app-remove-farm-confirmation>
    </div>
</div>