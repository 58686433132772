import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GridOptions } from 'ag-grid';
import { Subject } from 'rxjs';
import { DataGridOptions } from '../../../../shared/constants';

@Component({
  selector: 'app-sample-data-table',
  templateUrl: './sample-data-table.component.html',
  styleUrls: ['./sample-data-table.component.scss']
})
export class SampleDataTableComponent {

  @Output()
  public navigate = new EventEmitter<{ activeDevice: string, activeSample: number, source: string }>();

  @Input()
  public columnDefs;

  @Input()
  public tableData;

  @Input()
  public isTableActive: boolean;

  @Input()
  public deviceType: string;

  @Input()
  public set activeSample(data) {
    setTimeout(() => this.selectRow(data));
  }

  public alive$ = new Subject();
  public dataGridOptions: GridOptions = {...DataGridOptions, enableFilter: true};
  public currentSample;

  public selectRow(values): void {
    if (this.dataGridOptions && this.dataGridOptions.api) {
      if (values !== null) {
        this.currentSample = values;
        this.dataGridOptions.api.setFocusedCell(values, '0');
        this.dataGridOptions.api.ensureIndexVisible(values);
        if (this.dataGridOptions.api.getRowNode(values + '')) {
          this.dataGridOptions.api.getRowNode(values + '').setSelected(true, true);
        }
      } else {
        this.dataGridOptions.api.deselectAll();
        this.dataGridOptions.api.ensureIndexVisible(0, 'top');
      }
    }
  }

  public setActiveSample(row): void {
    this.currentSample = row.rowIndex;
    const activeDevice = this.tableData[0].deviceID || this.tableData[0].sampleName;

    this.navigate.emit({
      activeDevice,
      activeSample: row.rowIndex,
      source: 'table'
    });
  }
}
