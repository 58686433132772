import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { WEATHER_FORECAST_TREE_NODES } from '../../../../services/tree/constants';
import { IHeaderData } from '../../models/models';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ITreeSettingsState } from '../../../../shared/tree/models/tree.models';
import { select, Store } from '@ngrx/store';
import { selectWeatherForecastTreeSettings } from '../../reducers';
import { ISelectedStationState } from '../../../../core/reducers/selectedStation';
import { selectNavigationStation } from '../../../../core/reducers';
import { IStation } from '../../../../core/models/stations';

@Component({
  selector: 'app-weather-forecast-history-climate',
  templateUrl: './weather-forecast-history-climate.component.html',
  styleUrls: ['./weather-forecast-history-climate.component.scss']
})
export class WeatherForecastHistoryClimateComponent implements OnInit, OnDestroy {
  public isLoading$                      : Observable<boolean>;
  public isError$                        : Observable<boolean>;
  public validLicense                    : boolean = null;
  public params                          : any;
  public stationID                       : string;
  private destroyed$                     : Subject<boolean> = new Subject<boolean>();

  public header: IHeaderData = {
    title: '',
    subtitle: '',
    help: '',
    period: {
      title: '',
      from: null,
      to: null
    }
  };

  constructor(private activatedRoute      : ActivatedRoute,
              private tree                : Store<ITreeSettingsState>,
              private selectedStationStore: Store<ISelectedStationState>) { }

  public ngOnInit(): void {

    combineLatest([
      this.activatedRoute.params.pipe(
        withLatestFrom(this.tree.pipe(select(selectWeatherForecastTreeSettings))),
        takeUntil(this.destroyed$)
      ),
    ]).subscribe(([params]) => {
      this.params = params[0];
      const { id, page, sub } = params[0];
      if (page) {
        this.setHeader(page, sub);
      }
    });

    this.selectedStationStore
    .pipe(
      takeUntil(this.destroyed$),
      select(selectNavigationStation),
      filter((station: IStation): boolean => !!station)
    )
    .subscribe((station: IStation) => {
      this.stationID = station.name.original;
    });
  }

  private setHeader(page: string, subPage: string): void {

    const subNode = WEATHER_FORECAST_TREE_NODES.find(n => n.id === page);
    const node = subNode.nodes.find(n => n.id === subPage);

    // There may be exceptions when each item from a model service have it's own help section.
    const help = subPage;
    this.header = {
      title: '' + (subNode ? subNode.name : ''),
      subtitle: node ? node.name : '',
      help,
      period: {
        title: 'Forecast',
        from: new Date(),
        to: new Date(),
      }
    };
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
