import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-station-config-base-name-form',
  templateUrl: './station-config-base-name-form.component.html',
  styleUrls: ['./station-config-base-name-form.component.scss']
})
export class StationConfigBaseNameFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  private customName                          : string = '';
  @Output()
  private saveBaseName                        : EventEmitter<string> = new EventEmitter<string>();
  @Output()
  private changeBaseName                      : EventEmitter<string> = new EventEmitter<string>();

  public nameForm                             : FormGroup;

  private destroy$                            : Subject<boolean> = new Subject<boolean>();

  constructor(private fb: FormBuilder) {
  }

  public get name(): AbstractControl {
    return this.nameForm.get('name');
  }

  public emitSaveBaseName(): void {
    this.saveBaseName.emit(this.name.value);
  }

  public ngOnInit(): void {
    this.nameForm = this.fb.group({
      name: [this.customName, []]
    });

    this.name.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(200),
      takeUntil(this.destroy$)
    ).subscribe((name: string) => {
      this.changeBaseName.emit(name);
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.nameForm) {
      this.name.setValue(this.customName);
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
