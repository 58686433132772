import * as moment from 'moment';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const valueFixed = ({ value }) => {

  if (value === null || value === undefined) {
    return '';
  }

  return value.toFixed(2);
};

export const SoilGuardColumns = [
  { headerName: 'Date/Time', field: 'dt', unSortIcon: true,
    cellRenderer: ({ value }) => moment(value).utc().format(dateFormat)
  },
  { headerName: 'VWC', field: 'vwc', unSortIcon: true, cellRenderer: valueFixed },
  { headerName: 'EC', field: 'ec', unSortIcon: true, cellRenderer: valueFixed },
  { headerName: 'Soil Temperature', field: 'temp', unSortIcon: true, cellRenderer: valueFixed },
  { headerName: 'Position', children: [
    { headerName: 'Latitude', field: 'latitude', unSortIcon: true },
    { headerName: 'Longitude', field: 'longitude', unSortIcon: true }
  ]},
  { headerName: 'Rod Length', field: 'rodLength', width: 125 },
  { headerName: 'Soil Type', field: 'soilType', width: 125 },
  { headerName: 'VWC Mode', field: 'vwcMode', width: 125 },
];

export const DualexColumns = [
  { headerName: 'Date/Time', field: 'dt', uSortIcon: true,
    cellRenderer: ({ value }) => moment(value).utc().format(dateFormat)
  },
  { headerName: 'Sat Qual', field: 'sat_qual', width: 115, cellRenderer: valueFixed },
  { headerName: 'Temp', field: 'temp', width: 115, cellRenderer: valueFixed },
  { headerName: 'Group', field: 'group', width: 115, cellRenderer: valueFixed },
  { headerName: 'Measure', field: 'measure', width: 115, cellRenderer: valueFixed },
  { headerName: 'CHL', field: 'chl', width: 115, cellRenderer: valueFixed },
  { headerName: 'FLAV', field: 'flav', width: 115, cellRenderer: valueFixed },
  { headerName: 'ANTH', field: 'anth', width: 115, cellRenderer: valueFixed },
  { headerName: 'NBI', field: 'nbi', width: 115, cellRenderer: valueFixed },
  { headerName: 'Position', children: [
    { headerName: 'Latitude', field: 'latitude', width: 135, unSortIcon: true },
    { headerName: 'Longitude', field: 'longitude', width: 135, unSortIcon: true }
  ]},
  { headerName: 'Calibre', field: 'calib', width: 115 },
  { headerName: 'Side', field: 'side', width: 115 }
];

export const MobilabColumns = [
  { headerName: 'Date/Time', field: 'RecordedDateTime', unSortIcon: true },
  { headerName: 'Nitrate', field: 'nitrate', unSortIcon: true, cellRenderer: valueFixed },
  { headerName: 'Sulfate', field: 'sulfate', unSortIcon: true, cellRenderer: valueFixed },
  { headerName: 'Ammonium', field: 'ammonium', unSortIcon: true, cellRenderer: valueFixed },
  { headerName: 'Chloride', field: 'chloride', unSortIcon: true, cellRenderer: valueFixed },
  { headerName: 'Potassium', field: 'potassium', unSortIcon: true, cellRenderer: valueFixed },
  { headerName: 'Sodium', field: 'sodium', unSortIcon: true, cellRenderer: valueFixed },
  { headerName: 'Calcium', field: 'calcium', unSortIcon: true, cellRenderer: valueFixed },
  { headerName: 'Magnesium', field: 'magnesium', unSortIcon: true, cellRenderer: valueFixed },
  { headerName: 'Soil Moisture Type', field: 'soilMoistureType', unSortIcon: true, cellRenderer: valueFixed },
  { headerName: 'pH Level', field: 'pHLevel', unSortIcon: true, cellRenderer: valueFixed },
  { headerName: 'Position', children: [
    { headerName: 'Latitude', field: 'latitude', unSortIcon: true },
    { headerName: 'Longitude', field: 'longitude', unSortIcon: true }
  ]},
  { headerName: 'Sample Type', field: 'sampleType', unSortIcon: true },
  { headerName: 'Leaf Type', field: 'leafType', unSortIcon: true },
  { headerName: 'Crop Type', field: 'cropType', unSortIcon: true },
  { headerName: 'Sample Status', field: 'sampleStatus', unSortIcon: true }
];

export const PeriodScopeValues = [
  { content: 'Raw', value: 'raw' },
  { content: 'Hourly', value: 'hourly' },
  { content: 'Daily', value: 'daily' },
  { content: 'Monthly', value: 'monthly' }
];

export const PeriodScopeHourly = [
  { content: 'Raw', value: 'raw' },
  { content: 'Hourly', value: 'hourly' }
];

export const PeriodValuesByScope = {
  'raw': [
    { content: '24 hours', value: '24h' },
    { content: '48 hours', value: '48h' },
    { content: '7 days', value: '7d' },
    { content: '10 days', value: '10d' },
    { content: '14 days', value: '14d' },
    { content: '30 days', value: '30d' }
  ],
  'hourly': [
    { content: '48 hours', value: '48h' },
    { content: '7 days', value: '7d' },
    { content: '10 days', value: '10d' },
    { content: '14 days', value: '14d' },
    { content: '30 days', value: '30d' },
    { content: '60 days', value: '60d' },
    { content: '90 days', value: '90d' },
    { content: '120 days', value: '120d' },
  ],
  'daily': [
    { content: '7 days', value: '6d' },
    { content: '10 days', value: '9d' },
    { content: '14 days', value: '13d' },
    { content: '30 days', value: '29d' },
    { content: '90 days', value: '89d' }
  ],
  'monthly': [
    { content: '6 months', value: '6m' },
    { content: '12 months', value: '12m' },
    { content: '24 months', value: '24m' },
    { content: '36 months', value: '36m' }
  ]
};
