<form [formGroup]="form" class="logging-settings">
  <h4 class="logging-settings__header">{{ 'LED ring settings' | translate }}</h4>

  <label class="logging-settings__label">{{ 'Temperature threshold (°C)' | translate }}</label>

  <app-form-input
    [formControl]="temperatureThresholdControl"
    [contentType]="'number'"
    [size]="'small'"
    [type]="'border'"
    [minValue]="ledTrapConfigLimits.TEMPERATURE_THRESHOLD_MIN"
    [maxValue]="ledTrapConfigLimits.TEMPERATURE_THRESHOLD_MAX"
    [styles]="{width: '20%'}"
  ></app-form-input>
</form>
