<app-form-layout [shadow]="false">

  <app-card-header
    [header]="header?.title | translate"
    [description]="header?.subtitle | translate"
    [helpButton]="!!header?.help"
    (openModal)="openModal()">
  </app-card-header>

</app-form-layout>

<app-info-modal [modalId]="modalId" [header]="header?.title | translate">
  <app-work-planning-help-modal [help]="header?.help"></app-work-planning-help-modal>
</app-info-modal>
