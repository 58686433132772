<app-chart-toolbar-layout>
  <form class="camera-data-toolbar" [formGroup]="form">

    <div class="camera-data-toolbar__left">
      <div class="camera-data-toolbar__toggle camera-data-toolbar__left__item">
        <app-custom-button [color]="isLastDataModeControl.value ? 'dark-green' : 'green'"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'Last data mode' | translate"
                           [shadow]="false"
                           [radius]="'2px 0 0 2px'"
                           (click)="isLastDataModeControl.setValue(true)">
          <span class="fa fa-step-backward"></span>
        </app-custom-button>

        <app-custom-button [color]="!isLastDataModeControl.value ? 'dark-green' : 'green'"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'Date/week select mode' | translate"
                           [shadow]="false"
                           [radius]="'0 2px 2px 0'"
                           (click)="isLastDataModeControl.setValue(false)">
          <span class="fa fa-calendar"></span>
        </app-custom-button>
      </div>

      <ng-container *ngIf="!isLastDataModeControl.value">
        <div class="camera-data-toolbar__left__item">
          <app-custom-datepicker [isSmall]="true"
                                 [minDate]="getDate(stationMinDate$ | async)"
                                 [maxDate]="getDate(stationMaxDate$ | async)"
                                 [formControl]="datepickerStartControl"
          ></app-custom-datepicker>
        </div>

        <div class="camera-data-toolbar__left__item">
          <app-custom-datepicker [isSmall]="true"
                                 [minDate]="getDate(stationMinDate$ | async)"
                                 [maxDate]="getDate(stationMaxDate$ | async)"
                                 [formControl]="datepickerEndControl"
          ></app-custom-datepicker>
        </div>
      </ng-container>

      <ng-container *ngIf="isLastDataModeControl.value">
        <div class="camera-data-toolbar__left__item">
          <app-custom-dropdown [options]="periodOptions"
                               class="camera-data-toolbar__left__item"
                               [formControl]="periodScopeControl"
          ></app-custom-dropdown>
        </div>

        <div class="camera-data-toolbar__left__item">
          <app-date-range-arrow [formControl]="fromToControl"
                                (rangeClick)="onRefresh()"
                                [minDate]="rangeArrowMin"
                                [maxDate]="stationMaxDate$ | async"
                                [period]="periodScopeControl.value"
          ></app-date-range-arrow>
        </div>
      </ng-container>
    </div>

    <div class="camera-data-toolbar__right">
      <div class="camera-data-toolbar__right__item">
        <app-custom-button [color]="'white'"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'Export charts' | translate"
                           (click)="onExportChart()">
          <i class="fa fa-download"></i>
        </app-custom-button>
      </div>

      <div class="camera-data-toolbar__right__item">
        <app-custom-button [color]="'white'"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'Export table as XLS' | translate"
                           (click)="onExportTable()">
          <i class="fa fa-table"></i>
        </app-custom-button>
      </div>

      <div class="camera-data-toolbar__right__item">
        <app-custom-button [color]="isChartActiveControl.value ? 'green' : 'white'"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'Toggle charts' | translate"
                           [shadow]="false"
                           (click)="isChartActiveControl.setValue(!isChartActiveControl.value)"
                           [radius]="'2px 0 0 2px'">
          <span class="fa fa-bar-chart-o"></span>
        </app-custom-button>

        <app-custom-button [color]="isTableActiveControl.value ? 'green' : 'white'"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'Toggle table' | translate"
                           [shadow]="false"
                           (click)="isTableActiveControl.setValue(!isTableActiveControl.value)"
                           [radius]="'0 2px 2px 0'">
          <span class="fa fa-table"></span>
        </app-custom-button>
      </div>

      <div class="camera-data-toolbar__right__item">
        <app-custom-button [color]="'red'"
                           (click)="onRefresh()"
                           [content]="'Refresh' | translate">
          <i class="fa fa-fw fa-refresh"></i>
        </app-custom-button>
      </div>
    </div>

  </form>
</app-chart-toolbar-layout>
