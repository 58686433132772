import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { IStation } from '../../core/models/stations';
import { selectNavigationStation } from '../../core/reducers';
import * as fromNavigationStation from '../../core/reducers/navigation-station';
import { stationDataContentAnimations } from '../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../core/services/left-components-toggler/left-components-toggler.service';
import { ITreeStructure } from '../../services/tree/models';
import { TreeService } from '../../services/tree/tree.service';
import { getServerAlerts, getStationSensors } from './actions/server-alerts';
import * as fromServerAlerts from './reducers/server-alerts';

@Component({
  selector: 'app-server-alerts',
  templateUrl: './server-alerts.component.html',
  styleUrls: ['./server-alerts.component.scss'],
  animations: stationDataContentAnimations
})
export class ServerAlertsComponent implements OnInit, OnDestroy {

  public tree$: BehaviorSubject<ITreeStructure>;
  public state$: Observable<string>;
  public selectedStation: IStation;
  private destroy$: Subject<null> = new Subject();

  constructor(
    private treeService: TreeService,
    private leftComponentsToggler: LeftComponentsTogglerService,
    private serverAlertsStore: Store<fromServerAlerts.IServerAlertsState>,
    private navigationStore: Store<fromNavigationStation.INavigationStationState>
  ) {

  }

  public ngOnInit(): void {
    this.tree$ = this.treeService.getStationSettingsTreeStructure();
    this.state$ = this.leftComponentsToggler.getStationDataContentState();

    this.navigationStore.pipe(
      select(selectNavigationStation),
      takeUntil(this.destroy$),
      filter((station: IStation) =>
        Boolean(station && station.name)
      ),
      tap((station: IStation) => {

        const id = station.name.original;
        this.serverAlertsStore.dispatch(getServerAlerts(id));
        this.serverAlertsStore.dispatch(getStationSensors(id));
      })
    ).subscribe((station: IStation) => this.selectedStation = station);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
