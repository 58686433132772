import { Injectable } from '@angular/core';
import {ApiCallService} from '../../../services/api/api-call.service';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {from, Observable, of} from 'rxjs';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {catchError, delay, switchMap, tap} from 'rxjs/operators';
import {
  setStationDiseaseData, setStationDiseaseError,
  setStationDiseaseLicenses,
  setStationDiseaseLoading,
  setStationDiseaseWarnings,
  StationDiseaseActionTypes
} from '../actions/station-disease';
import {
  offStationDiseaseFireBlightSuccess,
  setStationDiseaseFireBlight, setStationDiseaseFireBlightSuccess,
  setStationDiseaseSettingsExportActive,
  setStationDiseaseSettingsExportImage,
  StationDiseaseSettingsActionTypes
} from '../actions/station-disease-settings';
import * as fromDisease from '../reducers/station-disease';
import {Store} from '@ngrx/store';
import * as fromDiseaseSettings from '../reducers/station-disease-settings';
import {StationDataExportService} from '../../../shared/services/export/station-data-export.service';
import {FIRE_BLIGHT} from '../constants/constants';
import {StationDiseaseHelpService} from '../services/station-disease-help.service';
import { setNotify } from '../../../core/actions/notify';

@Injectable()
export class StationDiseaseEffects {

  constructor(private actions$: Actions,
              private exportService: StationDataExportService,
              private helpService: StationDiseaseHelpService,
              private diseaseStore: Store<fromDisease.IStationDiseaseState>,
              private diseaseSettingsStore: Store<fromDiseaseSettings.IStationDiseaseSettingsState>,
              private api: ApiCallService) { }

  @Effect()
  public getStationDiseaseLicenses$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(StationDiseaseActionTypes.GET_STATION_DISEASE_LICENSES),
    tap(() => {
      this.diseaseSettingsStore.dispatch(setStationDiseaseLicenses(null));
      this.diseaseSettingsStore.dispatch(setStationDiseaseWarnings([]));
    }),
    switchMap((action: IActionWithPayload) => this.api.getStationLicenses(action.payload).pipe(
      switchMap((res: any) => from([
        setStationDiseaseLicenses(res),
      ])),
      catchError(() => from([]))
      )
    )
  );

  @Effect()
  public getStationDiseaseFireBlight$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(StationDiseaseSettingsActionTypes.GET_STATION_DISEASE_FIRE_BLIGHT),
    switchMap((action: IActionWithPayload) => this.api.getStationDisease(action.payload).pipe(
      switchMap((res: any) => from([
        setStationDiseaseFireBlight(res[FIRE_BLIGHT].option),
      ])),
      catchError(() => from([]))
      )
    )
  );

  @Effect()
  public saveStationDiseaseFireBlight$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(StationDiseaseSettingsActionTypes.SAVE_STATION_DISEASE_FIRE_BLIGHT),
    switchMap((action: IActionWithPayload) => this.api.saveStationDisease(action.payload).pipe(
      switchMap(() => from([
        setStationDiseaseFireBlight(action.payload.request.value),
        setStationDiseaseFireBlightSuccess(true),
        offStationDiseaseFireBlightSuccess()
      ])),
      catchError(() => from([]))
      )
    )
  );

  @Effect()
  public offStationDiseaseFireBlightSuccess$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(StationDiseaseSettingsActionTypes.OFF_STATION_DISEASE_FIRE_BLIGHT_SUCCESS),
    delay(3000),
    switchMap(() => of(setStationDiseaseFireBlightSuccess(false))
    )
  );

  @Effect()
  public setStationDiseaseLoadingProgress$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(StationDiseaseActionTypes.SET_STATION_DISEASE_LOADING_PROGRESS),
    switchMap(() => from([
        setStationDiseaseLoading(true),
        setStationDiseaseSettingsExportActive(false)
      ])
    )
  );

  @Effect()
  public getStationDiseaseData$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(StationDiseaseActionTypes.GET_STATION_DISEASE_DATA),
    switchMap((action: IActionWithPayload) => this.api.getStationDataFc(action.payload).pipe(
      switchMap((res: any) => {
        this.helpService.setSensorWarning(res);
        return from([
          setStationDiseaseData(res),
          setStationDiseaseSettingsExportActive(!Array.isArray(res) && res.chartsOptions && res.chartsOptions.length !== 0),
          setStationDiseaseLoading(false),
          ...res.notifications.map(notification => setNotify(notification))
        ]);
      }),
      catchError(() => from([
        setStationDiseaseError(true),
        setStationDiseaseLoading(false),
        setStationDiseaseSettingsExportActive(false)
      ]))
      )
    )
  );

  @Effect()
  public getStationDiseaseImage$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(StationDiseaseSettingsActionTypes.GET_STATION_DISEASE_IMAGE),
    tap(() => this.diseaseSettingsStore.dispatch(setStationDiseaseSettingsExportImage(true))),
    switchMap((action: IActionWithPayload) => this.api.exportStationDataImage(action.payload).pipe(
      switchMap((res: any) => {
        this.exportService.exportImage(res);
        return from([
          setStationDiseaseSettingsExportImage(false)
        ]);
      }),
      catchError(() => from([
        setStationDiseaseSettingsExportImage(false)
      ]))
      )
    )
  );
}
