import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../services/auth/auth.service';
import { errorFormAnimation, formRouterAnimation } from '../../../../shared/router.animations';
import { IUpdatePasswordRequest } from '../../login.interfaces';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css', '../../auth.css'],
  animations: [formRouterAnimation(), errorFormAnimation()]
})
export class UpdatePasswordComponent implements OnInit {

  public updateForm: FormGroup;
  private activeKey: string;
  public success: string;
  public error: string;
  public updateDisabled: boolean = false;

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {

  }

  public ngOnInit(): void {
    this.route.params.subscribe(params => this.activeKey = params['activeKey']);
    this.initForm();
  }

  public initForm(): void {
    this.updateForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(5)]],
      password_repeat: ['', [Validators.required, Validators.minLength(5)]]
    });
  }

  public updatePassword(values: IUpdatePasswordRequest): void {
    this.updateDisabled = true;
    this.auth.updatePassword(values, this.activeKey).subscribe(
      () => (this.success = 'Password was updated successfully') && this.back(750),
      error => this.error = 'Something went wrong. Please check your form data',
      () => this.updateDisabled = false,
    );
  }

  public back(time: number = 0): void {
    setTimeout(() => this.router.navigate(['/auth/login']), time);
  }
}
