<app-custom-image-gallery
  [deviceType]="deviceType"
  [pictures]="pictures"
  (selectPictureEmitter)="selectPicture($event)"
>
  <app-iscout-header
    [title]="title"
    [enableHelpButton]="true"
    (openModal)="openHelpModal()"
  ></app-iscout-header>
  <app-iscout-image-gallery-toolbar></app-iscout-image-gallery-toolbar>
</app-custom-image-gallery>

<app-info-modal [modalId]="modalId"
                [header]="(subDomain?.iScoutNames ? subDomain.iScoutNames : ('iScout' | translate)) + ' ' + ('Gallery' | translate)">
  <p>
    {{
    'In the Gallery we show all pictures per month, week or glueboard (drop down list for selection). ' +
    'If you want to see previous months/weeks use the buttons for moving back or forward.' | translate
    }}
  </p>

  <p>
    {{
    'When clicking on one of the gallery pictures that one will be enlarged and you will be able to ' +
    'adapt/modify it by choosing the specific insect name (using the drop down list or adding your specific ' +
    'insect name in the' | translate
    }}
    {{ (subDomain?.iScoutNames ? subDomain.iScoutNames : ('iScout' | translate)) + ' ' }}
    {{ 'Insect page and it will appear in the list).' | translate }}
  </p>
</app-info-modal>
