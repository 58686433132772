import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IState } from '../models';
import { JDRelationTypes } from '../constants/my-john-deere';
import { getPathFromCatalog, getPathFromCurrentUser } from '../utils/my-john-deere';

export const selector = createFeatureSelector<IState>('apiServices');

export const status = createSelector(
  selector,
  state => state.myJohnDeere.status
);

export const selectAuthData = createSelector(
  selector,
  state => state.myJohnDeere.authData
);

export const currentTokenPath = createSelector(
  selector,
  state => getPathFromCatalog(JDRelationTypes.currentToken, state)
);

export const currentUser = createSelector(
  selector,
  state => state.myJohnDeere.currentUser
);

export const currentUserPath = createSelector(
  selector,
  state => getPathFromCatalog(JDRelationTypes.currentUser, state)
);

export const currentUserOrganizationsPath = createSelector(
  selector,
  state => getPathFromCurrentUser(JDRelationTypes.organizations, state)
);

export const currentUserOrganizationsPages = createSelector(
  selector,
  state => state.myJohnDeere.currentUserOrganizationsPages
);

export const currentUserOrganizations = createSelector(
  selector,
  state => state.myJohnDeere.currentUserOrganizations
);

export const assets = createSelector(
  selector,
  state => state.myJohnDeere.assets
);

export const sensors = createSelector(
  selector,
  state => state.myJohnDeere.sensors
);
