<app-form-layout class="station-notes" *ngIf="noteForm">
  <h2 class="station-notes__header">{{ 'Device notes' | translate }}</h2>
  <form class="station-notes__content" [formGroup]="noteForm">
    <app-custom-textarea [formControl]="note"
                         [readOnly]="isReadOnly">
    </app-custom-textarea>
    <div *ngIf="!isReadOnly"
         class="station-notes__save">
      <app-custom-button [color]="'red'"
                         (click)="emitSaveNote()"
                         [content]="'Save note'"></app-custom-button>
    </div>
  </form>
</app-form-layout>
