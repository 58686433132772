import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-available-water-paw-help-text-modal',
  templateUrl: './available-water-paw-help-text-modal.component.html',
  styleUrls: ['./available-water-paw-help-text-modal.component.css']
})
export class AvailableWaterPawHelpTextModalComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
