import { IDateRange, IOptions } from './interfaces';
import {ExcelExportParams, GridOptions} from 'ag-grid';

export const NotSet: IOptions = {
  value: '',
  content: 'Not set'
};

export const DEFAULT_LANG = 'en';

export const Countries: Array<IOptions> = [
  {
    value: 'ad',
    content: 'Afghanistan'
  },
  {
    value: 'ax',
    content: 'Åland Islands'
  },
  {
    value: 'al',
    content: 'Albania'
  },
  {
    value: 'dz',
    content: 'Algeria'
  },
  {
    value: 'as',
    content: 'American Samoa'
  },
  {
    value: 'ad',
    content: 'Andorra'
  },
  {
    value: 'ao',
    content: 'Angola'
  },
  {
    value: 'ai',
    content: 'Anguilla'
  },
  {
    value: 'aq',
    content: 'Antarctica'
  },
  {
    value: 'ag',
    content: 'Antigua and Barbuda'
  },
  {
    value: 'ar',
    content: 'Argentina'
  },
  {
    value: 'am',
    content: 'Armenia'
  },
  {
    value: 'aw',
    content: 'Aruba'
  },
  {
    value: 'au',
    content: 'Australia'
  },
  {
    value: 'at',
    content: 'Austria'
  },
  {
    value: 'az',
    content: 'Azerbaijan'
  },
  {
    value: 'bs',
    content: 'Bahamas'
  },
  {
    value: 'bh',
    content: 'Bahrain'
  },
  {
    value: 'bd',
    content: 'Bangladesh'
  },
  {
    value: 'bb',
    content: 'Barbados'
  },
  {
    value: 'by',
    content: 'Belarus'
  },
  {
    value: 'be',
    content: 'Belgium'
  },
  {
    value: 'bz',
    content: 'Belize'
  },
  {
    value: 'bj',
    content: 'Benin'
  },
  {
    value: 'bm',
    content: 'Bermuda'
  },
  {
    value: 'bt',
    content: 'Bhutan'
  },
  {
    value: 'bo',
    content: 'Bolivia, Plurinational State o'
  },
  {
    value: 'bq',
    content: 'Bonaire, Sint Eustatius and Saba'
  },
  {
    value: 'ba',
    content: 'Bosnia and Herzegovina'
  },
  {
    value: 'bw',
    content: 'Botswana'
  },
  {
    value: 'bv',
    content: 'Bouvet Island'
  },
  {
    value: 'br',
    content: 'Brazil'
  },
  {
    value: 'io',
    content: 'British Indian Ocean Territory'
  },
  {
    value: 'bn',
    content: 'Brunei Darussalam'
  },
  {
    value: 'bg',
    content: 'Bulgaria'
  },
  {
    value: 'bf',
    content: 'Burkina Faso'
  },
  {
    value: 'bi',
    content: 'Burundi'
  },
  {
    value: 'kh',
    content: 'Cambodia'
  },
  {
    value: 'cm',
    content: 'Cameroon'
  },
  {
    value: 'ca',
    content: 'Canada'
  },
  {
    value: 'cv',
    content: 'Cape Verde'
  },
  {
    value: 'ky',
    content: 'Cayman Islands'
  },
  {
    value: 'cf',
    content: 'Central African Republic'
  },
  {
    value: 'td',
    content: 'Chad'
  },
  {
    value: 'cl',
    content: 'Chile'
  },
  {
    value: 'cn',
    content: 'China'
  },
  {
    value: 'cx',
    content: 'Christmas Island'
  },
  {
    value: 'cc',
    content: 'Cocos (Keeling) Islands'
  },
  {
    value: 'co',
    content: 'Colombia'
  },
  {
    value: 'km',
    content: 'Comoros'
  },
  {
    value: 'cg',
    content: 'Congo'
  },
  {
    value: 'cf',
    content: 'Congo, the Democratic Republic of the'
  },
  {
    value: 'ck',
    content: 'Cook Islands'
  },
  {
    value: 'cr',
    content: 'Costa Rica'
  },
  {
    value: 'ci',
    content: 'Côte d\'Ivoire'
  },
  {
    value: 'hr',
    content: 'Croatia'
  },
  {
    value: 'cu',
    content: 'Cuba'
  },
  {
    value: 'cw',
    content: 'Curaçao'
  },
  {
    value: 'cy',
    content: 'Cyprus'
  },
  {
    value: 'cz',
    content: 'Czech Republic'
  },
  {
    value: 'dk',
    content: 'Denmark'
  },
  {
    value: 'dj',
    content: 'Djibouti'
  },
  {
    value: 'dm',
    content: 'Dominica'
  },
  {
    value: 'do',
    content: 'Dominican Republic'
  },
  {
    value: 'ec',
    content: 'Ecuador'
  },
  {
    value: 'eg',
    content: 'Egypt'
  },
  {
    value: 'sv',
    content: 'El Salvador'
  },
  {
    value: 'gq',
    content: 'Equatorial Guinea'
  },
  {
    value: 'er',
    content: 'Eritrea'
  },
  {
    value: 'ee',
    content: 'Estonia'
  },
  {
    value: 'et',
    content: 'Ethiopia'
  },
  {
    value: 'fk',
    content: 'Falkland Islands (Malvinas)'
  },
  {
    value: 'fo',
    content: 'Faroe Islands'
  },
  {
    value: 'fj',
    content: 'Fiji'
  },
  {
    value: 'fi',
    content: 'Finland'
  },
  {
    value: 'fr',
    content: 'France'
  },
  {
    value: 'gf',
    content: 'French Guiana'
  },
  {
    value: 'pf',
    content: 'French Polynesia'
  },
  {
    value: 'tf',
    content: 'French Southern Territories'
  },
  {
    value: 'ga',
    content: 'Gabon'
  },
  {
    value: 'gm',
    content: 'Gambia'
  },
  {
    value: 'ge',
    content: 'Georgia'
  },
  {
    value: 'de',
    content: 'Germany'
  },
  {
    value: 'gh',
    content: 'Ghana'
  },
  {
    value: 'gi',
    content: 'Gibraltar'
  },
  {
    value: 'gr',
    content: 'Greece'
  },
  {
    value: 'gl',
    content: 'Greenland'
  },
  {
    value: 'gd',
    content: 'Grenada'
  },
  {
    value: 'gp',
    content: 'Guadeloupe'
  },
  {
    value: 'gu',
    content: 'Guam'
  },
  {
    value: 'gt',
    content: 'Guatemala'
  },
  {
    value: 'gg',
    content: 'Guernsey'
  },
  {
    value: 'gn',
    content: 'Guinea'
  },
  {
    value: 'gw',
    content: 'Guinea-Bissau'
  },
  {
    value: 'gy',
    content: 'Guyana'
  },
  {
    value: 'ht',
    content: 'Haiti'
  },
  {
    value: 'hm',
    content: 'Heard Island and McDonald Islands'
  },
  {
    value: 'vy',
    content: 'Holy See (Vatican City State)'
  },
  {
    value: 'hn',
    content: 'Honduras'
  },
  {
    value: 'hk',
    content: 'Hong Kong'
  },
  {
    value: 'hu',
    content: 'Hungary'
  },
  {
    value: 'is',
    content: 'Iceland'
  },
  {
    value: 'in',
    content: 'India'
  },
  {
    value: 'id',
    content: 'Indonesia'
  },
  {
    value: 'ir',
    content: 'Iran, Islamic Republic of'
  },
  {
    value: 'iq',
    content: 'Iraq'
  },
  {
    value: 'ie',
    content: 'Ireland'
  },
  {
    value: 'im',
    content: 'Isle of Man'
  },
  {
    value: 'il',
    content: 'Israel'
  },
  {
    value: 'it',
    content: 'Italy'
  },
  {
    value: 'jm',
    content: 'Jamaica'
  },
  {
    value: 'jp',
    content: 'Japan'
  },
  {
    value: 'je',
    content: 'Jersey'
  },
  {
    value: 'jo',
    content: 'Jordan'
  },
  {
    value: 'kz',
    content: 'Kazakhstan'
  },
  {
    value: 'ke',
    content: 'Kenya'
  },
  {
    value: 'ki',
    content: 'Kiribati'
  },
  {
    value: 'kp',
    content: 'Korea, Democratic People\'s Republic of'
  },
  {
    value: 'kr',
    content: 'Korea, Republic of'
  },
  {
    value: 'kw',
    content: 'Kuwait'
  },
  {
    value: 'kg',
    content: 'Kyrgyzstan'
  },
  {
    value: 'la',
    content: 'Lao People\'s Democratic Republic'
  },
  {
    value: 'lv',
    content: 'Latvia'
  },
  {
    value: 'lb',
    content: 'Lebanon'
  },
  {
    value: 'ls',
    content: 'Lesotho'
  },
  {
    value: 'lr',
    content: 'Liberia'
  },
  {
    value: 'ly',
    content: 'Libya'
  },
  {
    value: 'li',
    content: 'Liechtenstein'
  },
  {
    value: 'lt',
    content: 'Lithuania'
  },
  {
    value: 'lu',
    content: 'Luxembourg'
  },
  {
    value: 'mo',
    content: 'Macao'
  },
  {
    value: 'mk',
    content: 'Macedonia, the former Yugoslav Republic of'
  },
  {
    value: 'mg',
    content: 'Madagascar'
  },
  {
    value: 'mw',
    content: 'Malawi'
  },
  {
    value: 'my',
    content: 'Malaysia'
  },
  {
    value: 'mv',
    content: 'Maldives'
  },
  {
    value: 'ml',
    content: 'Mali'
  },
  {
    value: 'mt',
    content: 'Malta'
  },
  {
    value: 'mh',
    content: 'Marshall Islands'
  },
  {
    value: 'mq',
    content: 'Martinique'
  },
  {
    value: 'mr',
    content: 'Mauritania'
  },
  {
    value: 'mu',
    content: 'Mauritius'
  },
  {
    value: 'yt',
    content: 'Mayotte'
  },
  {
    value: 'mx',
    content: 'Mexico'
  },
  {
    value: 'fm',
    content: 'Micronesia, Federated States of'
  },
  {
    value: 'md',
    content: 'Moldova, Republic of'
  },
  {
    value: 'mc',
    content: 'Monaco'
  },
  {
    value: 'mn',
    content: 'Mongolia'
  },
  {
    value: 'me',
    content: 'Montenegro'
  },
  {
    value: 'ms',
    content: 'Montserrat'
  },
  {
    value: 'ma',
    content: 'Morocco'
  },
  {
    value: 'mz',
    content: 'Mozambique'
  },
  {
    value: 'mm',
    content: 'Myanmar'
  },
  {
    value: 'na',
    content: 'Namibia'
  },
  {
    value: 'nr',
    content: 'Nauru'
  },
  {
    value: 'np',
    content: 'Nepal'
  },
  {
    value: 'nl',
    content: 'Netherlands'
  },
  {
    value: 'nc',
    content: 'New Caledonia'
  },
  {
    value: 'nz',
    content: 'New Zealand'
  },
  {
    value: 'ni',
    content: 'Nicaragua'
  },
  {
    value: 'ne',
    content: 'Niger'
  },
  {
    value: 'ng',
    content: 'Nigeria'
  },
  {
    value: 'nu',
    content: 'Niue'
  },
  {
    value: 'nf',
    content: 'Norfolk Island'
  },
  {
    value: 'mp',
    content: 'Northern Mariana Islands'
  },
  {
    value: 'no',
    content: 'Norway'
  },
  {
    value: 'om',
    content: 'Oman'
  },
  {
    value: 'ok',
    content: 'Pakistan'
  },
  {
    value: 'ow',
    content: 'Palau'
  },
  {
    value: 'ps',
    content: 'Palestinian Territory, Occupied'
  },
  {
    value: 'pa',
    content: 'Panama'
  },
  {
    value: 'pg',
    content: 'Papua New Guinea'
  },
  {
    value: 'py',
    content: 'Paraguay'
  },
  {
    value: 'pe',
    content: 'Peru'
  },
  {
    value: 'ph',
    content: 'Philippines'
  },
  {
    value: 'pn',
    content: 'Pitcairn'
  },
  {
    value: 'pl',
    content: 'Poland'
  },
  {
    value: 'pt',
    content: 'Portugal'
  },
  {
    value: 'pr',
    content: 'Puerto Rico'
  },
  {
    value: 'qa',
    content: 'Qatar'
  },
  {
    value: 're',
    content: 'Réunion'
  },
  {
    value: 'ro',
    content: 'Romania'
  },
  {
    value: 'ru',
    content: 'Russian Federation'
  },
  {
    value: 'rw',
    content: 'Rwanda'
  },
  {
    value: 'bl',
    content: 'Saint Barthélemy'
  },
  {
    value: 'sh',
    content: 'Saint Helena, Ascension and Tristan da Cunha'
  },
  {
    value: 'kn',
    content: 'Saint Kitts and Nevis'
  },
  {
    value: 'lc',
    content: 'Saint Lucia'
  },
  {
    value: 'mf',
    content: 'Saint Martin (French part)'
  },
  {
    value: 'pm',
    content: 'Saint Pierre and Miquelon'
  },
  {
    value: 'vc',
    content: 'Saint Vincent and the Grenadines'
  },
  {
    value: 'ws',
    content: 'Samoa'
  },
  {
    value: 'sm',
    content: 'San Marino'
  },
  {
    value: 'st',
    content: 'Sao Tome and Principe'
  },
  {
    value: 'sa',
    content: 'Saudi Arabia'
  },
  {
    value: 'sn',
    content: 'Senegal'
  },
  {
    value: 'rs',
    content: 'Serbia'
  },
  {
    value: 'sc',
    content: 'Seychelles'
  },
  {
    value: 'sl',
    content: 'Sierra Leone'
  },
  {
    value: 'sg',
    content: 'Singapore'
  },
  {
    value: 'sx',
    content: 'Sint Maarten (Dutch part)'
  },
  {
    value: 'sk',
    content: 'Slovakia'
  },
  {
    value: 'si',
    content: 'Slovenia'
  },
  {
    value: 'sb',
    content: 'Solomon Islands'
  },
  {
    value: 'so',
    content: 'Somalia'
  },
  {
    value: 'za',
    content: 'South Africa'
  },
  {
    value: 'gs',
    content: 'South Georgia and the South Sandwich Islands'
  },
  {
    value: 'ss',
    content: 'South Sudan'
  },
  {
    value: 'es',
    content: 'Spain'
  },
  {
    value: 'lk',
    content: 'Sri Lanka'
  },
  {
    value: 'sd',
    content: 'Sudan'
  },
  {
    value: 'sr',
    content: 'Suriname'
  },
  {
    value: 'sj',
    content: 'Svalbard and Jan Mayen'
  },
  {
    value: 'sz',
    content: 'Swaziland'
  },
  {
    value: 'se',
    content: 'Sweden'
  },
  {
    value: 'ch',
    content: 'Switzerland'
  },
  {
    value: 'sy',
    content: 'Syrian Arab Republic'
  },
  {
    value: 'tw',
    content: 'Taiwan, Province of China'
  },
  {
    value: 'tj',
    content: 'Tajikistan'
  },
  {
    value: 'tz',
    content: 'Tanzania, United Republic of'
  },
  {
    value: 'th',
    content: 'Thailand'
  },
  {
    value: 'tl',
    content: 'Timor-Leste'
  },
  {
    value: 'tg',
    content: 'Togo'
  },
  {
    value: 'tk',
    content: 'Tokelau'
  },
  {
    value: 'to',
    content: 'Tonga'
  },
  {
    value: 'tt',
    content: 'Trinidad and Tobago'
  },
  {
    value: 'tn',
    content: 'Tunisia'
  },
  {
    value: 'tr',
    content: 'Turkey'
  },
  {
    value: 'tm',
    content: 'Turkmenistan'
  },
  {
    value: 'tc',
    content: 'Turks and Caicos Islands'
  },
  {
    value: 'tv',
    content: 'Tuvalu'
  },
  {
    value: 'ug',
    content: 'Uganda'
  },
  {
    value: 'ua',
    content: 'Ukraine'
  },
  {
    value: 'ae',
    content: 'United Arab Emirates'
  },
  {
    value: 'gb',
    content: 'United Kingdom'
  },
  {
    value: 'us',
    content: 'United States'
  },
  {
    value: 'um',
    content: 'United States Minor Outlying Islands'
  },
  {
    value: 'uy',
    content: 'Uruguay'
  },
  {
    value: 'uz',
    content: 'Uzbekistan'
  },
  {
    value: 'vu',
    content: 'Vanuatu'
  },
  {
    value: 've',
    content: 'Venezuela, Bolivarian Republic of'
  },
  {
    value: 'vn',
    content: 'Viet Nam'
  },
  {
    value: 'vg',
    content: 'Virgin Islands, British'
  },
  {
    value: 'vi',
    content: 'Virgin Islands, U.S.'
  },
  {
    value: 'wf',
    content: 'Wallis and Futuna'
  },
  {
    value: 'eh',
    content: 'Western Sahara'
  },
  {
    value: 'ye',
    content: 'Yemen'
  },
  {
    value: 'zm',
    content: 'Zambia'
  }
];

export const StationTypes = new Map([
  ['iMetos 1 Old', 0],
  ['iMetos 1', 1],
  ['iMetos 2', 2],
  ['iMetos SD', 3],
  ['iMetos AOS', 4],
  ['iMetos 3', 5],
  ['iMetos ECO D2', 6],
  ['iMetos 3.3', 7],
  ['iMetos ECO D3', 8],
  ['iMetos ICA', 28],
  ['iMetos Track', 40],
  ['iMetos Active Tracker', 41],
  ['iMetos Object Tracker', 42],

  ['uMetos CLIMA (NBIoT)', 49],
  ['iMETOS LoRa CLIMA', 50],
  ['iMETOS LoRa SOIL', 51],
  ['iMETOS LoRAIN', 52],
  ['LoRATH (LoRaWAN)', 53],
  ['LoRAIN SOIL (LoRaWAN)', 55],
  ['LoRATH SOIL (LoRaWAN)', 56],
  ['uMetos SOIL (NBIoT)', 57],
  ['LoRAIN (NBIoT)', 58],
  ['LoRATH (NBIoT)', 59],
  ['LoRAIN SOIL (NBIoT)', 60],
  ['LoRATH SOIL (NBIoT)', 61],

  ['IScoutType', 72],
  ['IScoutMobileType', 3329],
  ['CropViewType1', 70],
  ['CropViewType2', 71],
  ['CropViewType3', 73],

  ['nMETOS80', 76],
  ['nMETOS100+', 77],
  ['SolAntenna', 78],

  ['Virtual Weather Station', 100],
  ['CameraNewControlUnit', 125],
  ['Davis Virtual Station', 2832],
  ['SoilGuard Probe', 2833],
  ['Dualex Device', 2834],
  ['MobiLab Device', 144] // todo DOUBLE CHECK THE MOBILAB DEVICE ID
  // ['MobiLab Device', 2835]
]);

export const DevicesWithSMSAlertSupport = [
  0, 1, 2, 6, 7, 8
];

export const DevicesWithServerAlertSupport = [
  0, 1, 2, 6, 7, 8, // iMetos devices
  49, 50, 51, 52, 53, 55, 56, 57, 58, 59, 60, 61, 76, 77, // NBIoT & Lora
  70, 71, 72, 73, 125, // Camera devices
  100, 2832 // Virtual stations
];

export const DevicesWithoutWindSensor = [
  64, 65, 66, 67, 68, 70, 71, 72, 73, 78, 125, 127, 144, 145, 3329, 2816, 2832, 2833, 2834,
  40, 41, 42, 43, 44  // trackers
];

export const uMetosNBIoTDevices = [
  49,
  57,
  58,
  59,
  60,
  61,
  76,
  77
];

export const frostMonitoringDevices = [
  6,
  7,
  8,
  49
];

export const solAntennaDeviceIDs = [
  78
];

export enum sampleDevices {
  'SoilGuard Device' = 2833,
  'Dualex Device' = 2834
}

export const DateTypes = {
  h: 'hours',
  d: 'days',
  w: 'weeks',
  m: 'months',
  y: 'years',
  undefined: 'days'
};

export const READ_AND_WRITE_PERMISSION = 'rw';
export const READ_PERMISSION = 'r';
export const WRITE_PERMISSION = 'w';

export const ExcelExportSettings: ExcelExportParams = {
  skipHeader: false,
  columnGroups: true,
  skipGroups: false,
};

export const DataGridOptions: GridOptions = {
  columnDefs: [],
  rowData: [],
  enableColResize: true,
  enableSorting: true,
  rowHeight: 30,
  suppressDragLeaveHidesColumns: true,
  headerHeight: 28,
  rowSelection: 'single',
  suppressContextMenu: true
};

export const GoogleMapLibraries: string[] = ['drawing', 'geometry', 'places'];

export const ADD_REMOVE_MODAL_ID: string = 'ADD_REMOVE_MODAL';
export const ADD_CROPZONE: string = 'ADD_CROPZONE';
export const REMOVE_CROPZONE: string = 'REMOVE_CROPZONE';
export const ADD_IRRIGATION_EVENT: string = 'ADD_IRRIGATION_EVENT';
export const OPEN_DAY_EVENTS: string = 'OPEN_DAY_EVENTS';
export const ADD_REMOVE_VIRTUAL_STATION_MODAL_ID: string = 'ADD_REMOVE_VIRTUAL_STATION_MODAL';
export const SOIL_MOISTURE_PLOTBAND_EDITOR_MODAL_ID: string = 'SOIL_MOISTURE_PLOTBAND_EDITOR_MODAL';
export const PRODUCT_LICENSE_MODAL_ID: string = 'PRODUCT_LICENSE_MODAL';
export const STATION_SAMPLE_MODAL_ID: string = 'STATION_SAMPLE_MODAL';
export const RAIN_CORRECTOR_CLEAN_PRECIPITATION_MODAL_ID: string = 'RAIN_CORRECTOR_CLEAN_PRECIPITATION_MODAL';
export const CAMERA_CLEAR_MEASUREMENTS_MODAL_ID: string = 'ISCOUT_CLEAR_MEASUREMENTS_MODAL_ID';
export const ISCOUT_PESTS_DELETE_MODAL_ID: string = 'ISCOUT_PESTS_DELETE_MODAL';
export const ISCOUT_GLUE_BOARD_DELETE_MODAL_ID: string = 'ISCOUT_GLUE_BOARD_DELETE_MODAL';
export const ISCOUT_GLUE_BOARD_EXCHANGE_MODAL_ID: string = 'ISCOUT_GLUE_BOARD_EXCHANGE_MODAL';
export const ISCOUT_SEASON_DELETE_MODAL_ID: string = 'ISCOUT_SEASON_DELETE_MODAL';
export const ISCOUT_SEASON_DETAIL_MODAL_ID: string = 'ISCOUT_SEASON_DETAIL_MODAL';

export const STATION_LIST_REFRESH_TIMER = 900000; // 15 min in milliseconds
export const CROPZONE_LIST_REFRESH_TIMER = 900000; // 15 min im milliseconds

export const MAX_SURFACE_AREA_POLYGON = 25; // km

export const socketOptions = {
  reconnection: true,
  reconnectionDelay: 30000,
  reconnectionDelayMax: 60000,
  reconnectionAttempts: 10,
  transports: ['websocket']
};

export const PeriodValues = [
  { content: '48 hours', value: '48h' },
  { content: '7 days', value: '7d' },
  { content: '10 days', value: '10d' },
  { content: '14 days', value: '14d' },
  { content: '30 days', value: '30d' },
  { content: '60 days', value: '60d' },
  { content: '90 days', value: '90d' },
  { content: '120 days', value: '120d' }
];

export const DeviceValues = [
  { content: 'All Devices', value: 'all' },
  { content: 'SoilGuard', value: 'soilGuard' },
  { content: 'Dualex', value: 'dualex' },
  { content: 'Mobilab', value: 'mobilab' }
];

export interface ISampleDataSettings {
  periodValue: string;
  fromTo: IDateRange;
  fromDate: Date;
  toDate: Date;
  isExportActive?: boolean;
  isTableActive?: boolean;
  isLastMode: boolean;
}

const samplePath = '/assets/img/markers/samples/';

export const SampleMarkers = {
  blue: `${samplePath}circles-01.png`,
  turquoise: `${samplePath}circles-02.png`,
  green: `${samplePath}circles-03.png`,
  orange: `${samplePath}circles-04.png`,
  red: `${samplePath}circles-05.png`,
  purple: `${samplePath}circles-06.png`
};

export const LegendValues = {
  vwc: {
    title: 'VWC color definitions',
    markers: [
      { src: SampleMarkers.blue, alt: 'blue' },
      { src: SampleMarkers.turquoise, alt: 'turquoise' },
      { src: SampleMarkers.green, alt: 'green' },
      { src: SampleMarkers.orange, alt: 'orange' },
      { src: SampleMarkers.red, alt: 'red' },
    ],
    metric: [
      { name: 'Starts at 35.0%', detail: 'VWC is very high' },
      { name: 'Starts at 30.0%', detail: 'VWC is high' },
      { name: 'Starts at 19.0%', detail: 'VWC is good' },
      { name: 'Starts at 11.0%', detail: 'VWC is low' },
      { name: 'Anything below 11.0%', detail: 'VWC is very low' }
    ],
    imperial: [
      { name: 'Starts at 35.0%', detail: 'VWC is very high' },
      { name: 'Starts at 30.0%', detail: 'VWC is high' },
      { name: 'Starts at 19.0%', detail: 'VWC is good' },
      { name: 'Starts at 11.0%', detail: 'VWC is low' },
      { name: 'Anything below 11.0%', detail: 'VWC is very low' }
    ]
  },
  temp: {
    title: 'Temp color definitions',
    markers: [
      { src: SampleMarkers.red, alt: 'red' },
      { src: SampleMarkers.orange, alt: 'orange' },
      { src: SampleMarkers.green, alt: 'green' },
      { src: SampleMarkers.turquoise, alt: 'turquoise' },
      { src: SampleMarkers.blue, alt: 'blue' },
    ],
    metric: [
      { name: 'Starts at 30.0°C', detail: 'Temp is very high' },
      { name: 'Starts at 25.0°C', detail: 'Temp is high' },
      { name: 'Starts at 10.0°C', detail: 'Temp is good' },
      { name: 'Starts at 8.0°C', detail: 'Temp is low' },
      { name: 'Anything below 8.0°C', detail: 'Temp is very low' }
    ],
    imperial: [
      { name: 'Starts at 86.0°F', detail: 'Temp is very high' },
      { name: 'Starts at 77.0°F', detail: 'Temp is high' },
      { name: 'Starts at 50.0°F', detail: 'Temp is good' },
      { name: 'Starts at 46.4°F', detail: 'Temp is low' },
      { name: 'Anything below 46.4°F', detail: 'Temp is very low' }
    ]
  }
};
