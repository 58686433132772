import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-column-header',
  templateUrl: './column-header.component.html',
  styleUrls: ['./column-header.component.scss']
})
export class ColumnHeaderComponent {
  @Input()
  public columnCount         : number;
  @Input()
  public columnIndex         : number;
  @Output()
  public resize              : EventEmitter<boolean> = new EventEmitter();
  @Output()
  public addWidget           : EventEmitter<void> = new EventEmitter();
  @Output()
  public remove              : EventEmitter<void> = new EventEmitter();
}
