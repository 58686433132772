import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromSensorsAndNodes from './sensors-and-nodes';

export interface IState {
  sensorsAndNodes : fromSensorsAndNodes.ISensorsAndNodesState;
}

export const reducers = {
  sensorsAndNodes: fromSensorsAndNodes.reducer,
};


export const sensorsAndNodes = createFeatureSelector<IState>('sensorsAndNodes');


export const selectSensorsAndNodesStation = createSelector(
  sensorsAndNodes,
  (state: IState) => state.sensorsAndNodes.stationId
);

export const selectSensorsAndNodesError = createSelector(
  sensorsAndNodes,
  (state: IState) => state.sensorsAndNodes.isError
);

export const selectSensorsAndNodesLoading = createSelector(
  sensorsAndNodes,
  (state: IState) => state.sensorsAndNodes.isLoading
);

export const selectSensorsAndNodes = createSelector(
  sensorsAndNodes,
  (state: IState) => state.sensorsAndNodes.nodes
);
