import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment = require('moment');
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-custom-expiry-warning',
  templateUrl: './custom-expiry-warning.component.html',
  styleUrls: ['./custom-expiry-warning.component.scss']
})
export class CustomExpiryWarningComponent implements OnChanges {

  @Input()
  public license                   : any;
  @Input()
  public FVModule?                 : string = null;
  public warningMessage            : string = '';
  public icon                      : boolean = false;

  private pleaseNoteString: string;
  private daysToRenew: string;
  private licenseExpiredOn: string;
  private toRenew: string;
  public moduleMessage: string = null;

  constructor(
    private translate: TranslateService,
  ) {
    this.getTranslatedStrings();
  }

  public ngOnChanges(): void {
    this.checkFVLicenseExpiry(this.license);
  }

  public checkFVLicenseExpiry(selectedLicense: any): void {
    const licenseEndDate: moment.Moment = moment(selectedLicense?.end_date);
    this.warningMessage = null;
    this.icon = false;

    const moduleString = this.customFVModuleMessage(this.FVModule);
    if (moduleString) {
      this.translate.get(moduleString).pipe(take(1)).subscribe((translatedText: string) => {
        this.moduleMessage = translatedText;
      });
    }

    const daysUntilEndLicense = licenseEndDate.diff(moment(), 'days');
    if (daysUntilEndLicense <= 0) {
      this.warningMessage = this.licenseExpiredOn + ' ' + licenseEndDate.format('DD-MM-YYYY') + '. ' + this.toRenew;
      this.icon = true;
    } else if (daysUntilEndLicense <= 10) {
      this.warningMessage = this.pleaseNoteString + ' ' + daysUntilEndLicense + ' ' + this.daysToRenew;
    }
  }

  private getTranslatedStrings(): void {
    this.translate.get('Please note: your license will expire in').pipe(take(1)).subscribe((translatedText: string) => {
      this.pleaseNoteString = translatedText;
    });
    this.translate.get('days. To renew it, please contact your METOS salesperson.').pipe(take(1)).subscribe((translatedText: string) => {
      this.daysToRenew = translatedText;
    });
    this.translate.get('Your license has expired on').pipe(take(1)).subscribe((translatedText: string) => {
      this.licenseExpiredOn = translatedText;
    });
    this.translate.get('To renew it, please contact your METOS salesperson.').pipe(take(1)).subscribe((translatedText: string) => {
      this.toRenew = translatedText;
    });
  }

  private customFVModuleMessage(module: string) : string {
    switch (module) {
      case'yieldPrediction' :
        return 'You can still view your Yield Prediction graph, but changing the settings has been disabled due to the license expiration.';
      default:
        return null;
    }
  }

}
