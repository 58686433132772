import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WorkPlanningComponent } from './work-planning.component';

const routes: Routes = [
  { path: '', component: WorkPlanningComponent, pathMatch: 'full', redirectTo: 'plant-nutrition' },
  { path: ':service', component: WorkPlanningComponent },
  { path: ':service/:crop', component: WorkPlanningComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class WorkPlanningRoutingModule { }
