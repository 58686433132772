import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth/auth.service';
import { environmentToken } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../../../environments/interfaces/theme';
import { errorFormAnimation, formRouterAnimation } from '../../../../shared/router.animations';
import { IErrorResponse } from '../../login.interfaces';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css', '../../auth.css'],
  animations: [formRouterAnimation(), errorFormAnimation()]
})
export class ResetPasswordComponent {
  public resetForm                              : FormGroup;
  public success                                : string = '';
  public errorMsg                               : string = '';
  public subDomain                              : IThemeConfig;
  public resetDisabled                          : boolean = false;

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    private auth: AuthService,
    private formBuilder: FormBuilder,
    public router: Router
    ) {
    this.resetForm = formBuilder.group({
      'username': ['', [Validators.required]],
    });

    this.subDomain = environment.theme;
  }

  public get username(): AbstractControl {
    return this.resetForm.get('username');
  }

  public resetPassword(): void {
    if (this.resetForm.invalid) {
      return;
    }
    this.resetDisabled = true;
    this.errorMsg = '';
    this.success = '';

    this.auth.resetPassword({username: this.username.value})
      .subscribe(() => {
        this.success = 'Please check your email and follow the link to continue';
        this.resetDisabled = false;
      }, (error: IErrorResponse) => {
        this.resetDisabled = false;
        if (error.status === 404) {
          this.errorMsg = 'Username not found. Please try again';
        } else {
          this.errorMsg = 'Something went wrong. Please check your form data';
        }
      });
  }
}
