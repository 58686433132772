import { Component, OnInit, OnDestroy, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { IStation } from '../../../../../core/models/stations';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { IOptions } from '../../../../../shared/interfaces';
import { ActivityOptions, FrostOptions, LoggingTransferGroups, NetworkRegistrationTimeoutOptions, RainOptions } from '../../../constants/constants';
import { Subject } from 'rxjs';
import { ILoggingTransferDevice, ISensorGroups } from '../../../models/station-config.models';
import { ApiCallService } from '../../../../../services/api/api-call.service';
import { Store, select } from '@ngrx/store';
import * as fromNavigationStation from '../../../../../core/reducers/navigation-station';
import { debounceTime, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { selectNavigationStation } from '../../../../../core/reducers';
import { frostMonitoringDevices } from '../../../../../shared/constants';

@Component({
  selector: 'app-advanced-options-two',
  templateUrl: './advanced-options-two.component.html',
  styleUrls: ['./advanced-options-two.component.scss']
})
export class AdvancedOptionsTwoComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public fixedTransferInterval            : number;
  @Input()
  public stationActivity                  : number;
  @Input()
  public rainMonitoring                   : number;
  @Input()
  public frostEnabled                     : boolean;
  @Input()
  public threshold                        : number;
  @Input()
  public frostMonitorInterval             : number;
  @Input()
  public sensorCode                       : number;
  @Input()
  public sensorChannel                    : number;
  @Input()
  public loggingInterval                  : number;
  @Input()
  public measuringInterval                : number;
  @Input()
  public transferInterval                 : number;
  @Input()
  public networkRegistrationTimeout       : number;
  @Input()
  public station                          : IStation;

  @Output()
  private changeAdvanced                  : EventEmitter<any> = new EventEmitter<any>();

  public form                             : FormGroup;
  public activityOptions                  : Array<IOptions> = ActivityOptions;
  public rainOptions                      : Array<IOptions> = RainOptions;
  public frostOption                      : Array<IOptions> = FrostOptions;
  public frostSensorOptions               : Array<IOptions> = [];
  public fixedOptions                     : Array<IOptions> = [];
  public networkRegistrationOptions       : Array<IOptions> = NetworkRegistrationTimeoutOptions;

  private destroy$                        : Subject<boolean> = new Subject<boolean>();
  public navigationStation                : IStation;
  public stationId                        : string;
  public oldStationId                     : string = '';
  public deviceId                         : number = null;
  public deviceGroup                      : ILoggingTransferDevice;
  public loggingTransferGroups            : ILoggingTransferDevice[] = LoggingTransferGroups;

  constructor(
    private fb: FormBuilder,
    private api: ApiCallService,
    private navigationStationStore: Store<fromNavigationStation.INavigationStationState>,
  ) { }

  private getFixedOptions(): Array<IOptions> {
    const fixedOptions: Array<IOptions> = [
      {
        value: '0',
        content: 'Not set'
      }
    ];
    if (this.loggingInterval <= 10) {
      fixedOptions.push({
        value: '10',
        content: 'every 10 minutes'
      });
    }
    if (this.loggingInterval < 20) {
      fixedOptions.push({
        value: '15',
        content: 'every 15 minutes'
      });
    }
    if (this.loggingInterval < 30) {
      fixedOptions.push({
        value: '20',
        content: 'every 20 minutes'
      });
    }
    if (this.loggingInterval < 60) {
      fixedOptions.push({
        value: '30',
        content: 'every 30 minutes'
      });
    }
    if (this.loggingInterval < 120) {
      fixedOptions.push({
        value: '60',
        content: 'every 60 minutes'
      });
    }
    return fixedOptions;
  }

  /* public get fixedTransferIntervalControl(): AbstractControl {
    return this.form.get('fixed_transfer_interval');
  } */

  public get stationActivityControl(): AbstractControl {
    return this.form.get('activity_mode');
  }

  public get rainMonitoringControl(): AbstractControl {
    return this.form.get('rain_monitor');
  }

  public get frostMonitoringEnable(): AbstractControl {
    return this.form.get('frost_monitoring_enabled');
  }

  public get frostMonitoringInterval(): AbstractControl {
    return this.form.get('frost_monitoring_interval');
  }

  public get frostMonitoringThreshold(): AbstractControl {
    return this.form.get('frost_monitoring_threshold');
  }

  public get frostMonitoringSensorCode(): AbstractControl {
    return this.form.get('frost_monitoring_sensor_code');
  }

  public get frostMonitoringSensorChannel(): AbstractControl {
    return this.form.get('frost_monitoring_sensor_channel');
  }

  public get measuringIntervalControl(): AbstractControl {
    return this.form.get('measuring_interval');
  }

  public get transferIntervalControl(): AbstractControl {
    return this.form.get('transfer_interval');
  }

  public get networkRegistrationTimeoutControl(): AbstractControl {
    return this.form.get('network_registration_timeout');
  }

  public ngOnInit(): void {
    this.deviceGroup = this.loggingTransferGroups.filter((group) => group.deviceIDs.includes(this.station.info.device_id))[0];
    this.form = this.fb.group({
      /* 'fixed_transfer_interval': [this.fixedTransferInterval, []], */
      'activity_mode': [this.stationActivity !== null ? this.stationActivity.toString() : null, []],
      'rain_monitor': [this.rainMonitoring, []],
      'frost_monitoring_enabled': [this.frostEnabled, []],
      'frost_monitoring_threshold': [this.threshold, []],
      'frost_monitoring_interval': [this.frostMonitorInterval, []],
      'frost_monitoring_sensor_code': [this.sensorCode, []],
      'frost_monitoring_sensor_channel': [this.sensorChannel, []],
      'measuring_interval': [this.measuringInterval, []],
      'transfer_interval': [this.transferInterval, []],
      'network_registration_timeout': [this.networkRegistrationTimeout !== null ? this.networkRegistrationTimeout.toString() : null, []]
    });

    this.form.valueChanges.pipe(
      debounceTime(100),
      takeUntil(this.destroy$),
      distinctUntilChanged((p, c) => JSON.stringify(p) === JSON.stringify(c))
    ).subscribe((form: any) => {
      this.changeAdvanced.emit({
        /* fixed_transfer_interval: +form.fixed_transfer_interval, */
        activity_mode: +form.activity_mode,
        rain_monitor: +form.rain_monitor,
        frost_monitoring_enabled: form.frost_monitoring_enabled,
        frost_monitoring_threshold: 5,
        frost_monitoring_sensor_code: form.frost_monitoring_sensor_code ? +form.frost_monitoring_sensor_code : 0,
        frost_monitoring_sensor_channel: form.frost_monitoring_sensor_channel ? +form.frost_monitoring_sensor_channel : 0,
        frost_monitoring_interval: 10,
        measuring_interval: +form.measuring_interval,
        transfer_interval: +form.transfer_interval,
        network_registration_timeout: +form.network_registration_timeout
      });
    });

    this.navigationStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation)
    ).subscribe((station: IStation) => {
      this.navigationStation = station;
      this.stationId = station.name.original;
      this.deviceId = station.info.device_id;

      if (this.stationId !== this.oldStationId) {
        this.getGroupSensorsList();
      }
    });
  }

  public ngOnChanges(): void {
    this.deviceGroup = this.loggingTransferGroups.filter((group) => group.deviceIDs.includes(this.station.info.device_id))[0];

    if (this.form) {
      if (!this.sensorChannel && this.frostSensorOptions.length !== 0) {
        if (this.frostEnabled === undefined) {
          this.frostEnabled = false;
        }
        this.sensorChannel = this.frostSensorOptions[0].value;
        this.sensorCode = this.frostSensorOptions[0].data.code;
        this.setFrostValues();
      } else {
        this.frostSensorOptions.map(item => {
          if (item.data.channel === this.sensorChannel) {
            this.sensorChannel = item.data.channel;
            this.sensorCode = item.data.code;
            this.setFrostValues();
          }
        });
      }
      this.setFrostValues();
      this.setFormValue();
    }

    this.fixedOptions = this.getFixedOptions();
  }

  public isFrostDevice(): boolean {
    return frostMonitoringDevices.includes(this.deviceId);
  }

  private setFormValue(): void {
    this.rainMonitoringControl.setValue(this.rainMonitoring);
    this.stationActivityControl.setValue(
      (this.stationActivity !== null && this.stationActivity !== undefined) ? this.stationActivity.toString()
      : null
    );
    /* this.fixedTransferIntervalControl.setValue(this.fixedTransferInterval); */
    this.measuringIntervalControl.setValue(this.measuringInterval);
    this.transferIntervalControl.setValue(this.transferInterval);
    this.networkRegistrationTimeoutControl.setValue(this.networkRegistrationTimeout ? this.networkRegistrationTimeout.toString() : null);
  }

  private setFrostValues() : void {
    this.form.patchValue({
      frost_monitoring_enabled: this.frostEnabled,
      frost_monitoring_threshold: this.threshold,
      frost_monitoring_interval: this.frostMonitorInterval,
      frost_monitoring_sensor_code: this.sensorCode,
      frost_monitoring_sensor_channel: this.sensorChannel,
    });
  }

  public changeFrostSensor(event) : void {
    this.frostSensorOptions.map(item => {
      if (item.data.channel === Number(event.target.value)) {
        this.sensorChannel = item.data.channel;
        this.sensorCode = item.data.code;
        this.setFrostValues();
      }
    });
  }

  public changeFrostMonitoring(event) : void {
    event.target.checked ? this.frostEnabled = true : this.frostEnabled = false;
    if (this.frostEnabled === true) {
      this.rainMonitoring = 0;
    }
    this.setFrostValues();
  }

  public changeRainMonitoring(event) : void {
    event.target.checked ? this.rainMonitoring = 1 : this.rainMonitoring = 0;
    if (this.rainMonitoring = 1) {
      this.frostEnabled = false;
    }
  }

  public getGroupSensorsList() : void {
    const groupsList : ISensorGroups = {
      groups: [1, 8, 9]
    };
    this.api.getSensorsByGroups(this.stationId, groupsList)
      .pipe()
      .subscribe(response => {
        this.frostSensorOptions = [];
        response.map(item => {
          this.frostSensorOptions.push({
            value: item.channel,
            content: 'CH-' + item.channel + '  ' + item.name,
            data: item,
          });
        });
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
