import { Action } from '@ngrx/store';
import { IPosition } from '../../../core/models/stations';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';

export const enum TimezoneAndLocationActionTypes {
  SET_LOCATION_SETTINGS = '[Config] SET_LOCATION_SETTINGS',
  GET_TIMEZONES = '[Config] GET_TIMEZONES',
  SET_TIMEZONES = '[Config] SET_TIMEZONES',
  SET_COORDINATES = '[Config] SET_COORDINATES',
  GET_COORDINATES = '[Config] GET_COORDINATES',
  GET_ELEVATION = '[Config] GET_ELEVATION',
  SET_ELEVATION = '[Config] SET_ELEVATION',
  SET_TIMEZONE = '[Config] SET_TIMEZONE',
  GET_TIMEZONE = '[Config] GET_TIMEZONE',
  GET_LOCATION = '[Config] GET_LOCATION',
}

export function setLocationSettings(settings: IPosition): IActionWithPayload {
  return { type: TimezoneAndLocationActionTypes.SET_LOCATION_SETTINGS, payload: settings };
}

export function setTimezones(timezones: any): IActionWithPayload {
  return { type: TimezoneAndLocationActionTypes.SET_TIMEZONES, payload: timezones };
}

export function getTimezones(): Action {
  return { type: TimezoneAndLocationActionTypes.GET_TIMEZONES };
}

export function setCoordinates(coords: Array<number>): IActionWithPayload {
  return { type: TimezoneAndLocationActionTypes.SET_COORDINATES, payload: coords };
}

export function getCoordinates(coords: Array<number>): IActionWithPayload {
  return { type: TimezoneAndLocationActionTypes.GET_COORDINATES, payload: coords };
}

export function getElevation(coords: Array<number>): IActionWithPayload {
  return { type: TimezoneAndLocationActionTypes.GET_ELEVATION, payload: coords };
}

export function setElevation(elevation: number): IActionWithPayload {
  return { type: TimezoneAndLocationActionTypes.SET_ELEVATION, payload: elevation };
}

export function getTimezone(coords: Array<number>): IActionWithPayload {
  return { type: TimezoneAndLocationActionTypes.GET_TIMEZONE, payload: coords };
}

export function setTimezone(zone: string): IActionWithPayload {
  return { type: TimezoneAndLocationActionTypes.SET_TIMEZONE, payload: zone };
}

export function getLocation(name: string): IActionWithPayload {
  return { type: TimezoneAndLocationActionTypes.GET_LOCATION, payload: name };
}
