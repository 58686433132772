import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { ICropZone } from '../../../core/models/cropzones';

export const enum CropzoneNameActionTypes {
  SET_CROPZONE_NAME = '[Config] SET_CROPZONE_NAME',
  SAVE_FARM_NAME = '[Config] SAVE_FARM_NAME',
  SAVE_FIELD_NAME = '[Config] SAVE_FIELD_NAME',
  SAVE_CROPZONE_NAME = '[Config] SAVE_CROPZONE_NAME',
  SAVE_CROPZONE_BOUNDARY = '[Config] SAVE_CROPZONE_BOUNDARY',
  NO_DRAWN_BOUNDARY = '[Config] NO_DRAWN_BOUNDARY',
  SET_SOURCE = '[Config] SET_SOURCE'
}

export function setCropzoneName(cropzone: ICropZone): IActionWithPayload {
  return { type: CropzoneNameActionTypes.SET_CROPZONE_NAME, payload: cropzone };
}

export function saveCropzoneName(cropzone: ICropZone): IActionWithPayload {
  return { type: CropzoneNameActionTypes.SAVE_CROPZONE_NAME, payload: cropzone };
}

export function setSource(data: { value: string, content: string }): IActionWithPayload {
  return { type: CropzoneNameActionTypes.SET_SOURCE, payload: data };
}

export function saveFarmName(cropzone: ICropZone): IActionWithPayload {
  return { type: CropzoneNameActionTypes.SAVE_FARM_NAME, payload: cropzone };
}

export function saveFieldName(cropzone: ICropZone): IActionWithPayload {
  return { type: CropzoneNameActionTypes.SAVE_FIELD_NAME, payload: cropzone };
}

export function saveCropzoneBoundary(cropzone: ICropZone): IActionWithPayload {
  return { type: CropzoneNameActionTypes.SAVE_CROPZONE_BOUNDARY, payload: cropzone };
}

export function noDrawnBoundary(errorString: string): IActionWithPayload {
  return { type: CropzoneNameActionTypes.NO_DRAWN_BOUNDARY, payload: errorString };
}
