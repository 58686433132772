import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IStationBasicInfo } from '../../core/models/navigation-station';
import { selectNavigationStationBasicInfo } from '../../core/reducers';
import { INavigationStationState } from '../../core/reducers/navigation-station';
import { sampleDevices } from '../constants';
import { formatDate } from '../utils/dateFormat';
import { environmentToken } from '../../../environments/environment';
import { IEnvironment } from '../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../environments/interfaces/theme';

@Component({
  selector: 'app-selected-station',
  templateUrl: './selected-station.component.html',
  styleUrls: ['./selected-station.component.scss']
})
export class SelectedStationComponent implements OnInit, OnDestroy {

  public infoIsOpen           : boolean = false;
  public stationName          : string;
  public deviceType           : string;
  public firmWare             : string;
  public timeZoneOffset       : number;
  public lastCommunication    : string;
  public isSampleDevice       = false;
  private destroy$            : Subject<void> = new Subject<void>();
  public theme                : IThemeConfig;

  constructor(
    @Inject(environmentToken) environment   : IEnvironment,
    private navigationStore                 : Store<INavigationStationState>
  ) {
    this.theme = environment.theme;
  }

  public ngOnInit(): void {
    this.navigationStore.pipe(
      select(selectNavigationStationBasicInfo),
      takeUntil(this.destroy$),
      filter((stationBasicInfo: IStationBasicInfo): boolean => !!stationBasicInfo.originalName)
    )
      .subscribe((stationBasicInfo: IStationBasicInfo) => {
        this.stationName = (stationBasicInfo.customName ? stationBasicInfo.customName + ' / ' : '') + stationBasicInfo.originalName;
        this.deviceType = stationBasicInfo.deviceType;
        this.isSampleDevice = stationBasicInfo.deviceType in sampleDevices;
        this.firmWare = stationBasicInfo.firmWare;
        this.timeZoneOffset = stationBasicInfo.timeZoneOffset;
        this.lastCommunication = formatDate(stationBasicInfo.lastCommunication);
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }
}
