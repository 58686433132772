<form class="product-activation-form"
      [formGroup]="form"
      (ngSubmit)="submit()">

  <div class="row">
    <div class="col-md-4">
      <label>{{ 'Product key' | translate }}</label>
      <app-form-input class="product-activation-form__input"
                      [type]="'border'"
                      [formControlName]="'productKey'"></app-form-input>
    </div>
  </div>

  <div class="product-activation-form__submit">
    <app-custom-button [disabled]="form.invalid"
                       [content]="'Activate product'"
                       [color]="'red'"></app-custom-button>
  </div>
</form>
