<ng-container *ngIf="state$ | async as state">

  <app-form-layout *ngIf="state.isLoading; else error" [size]="'loading'">
    <h4><i class="fa fa-cog fa-spin fa-fw"></i> {{ 'Please wait, loading data ...' | translate }}</h4>
  </app-form-layout>

  <ng-template #error>
    <app-form-layout *ngIf="state.isError" [size]="'loading'">
      <h4><i class="fa fa-warning fa-fw"></i> {{ 'Error loading!' | translate }}</h4>
    </app-form-layout>
  </ng-template>

</ng-container>

