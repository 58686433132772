<app-form-layout class="station-load-info"
                 [size]="'loading'">
  <h4 class="station-load-info__content"
      *ngIf="isLoading; else nodata">
    <i class="fa fa-cog fa-spin fa-fw"></i> {{ 'Please wait, loading data ...' | translate }}</h4>
</app-form-layout>

<ng-template #nodata>
    <h4 class="station-load-info__content">
      <i class="fa fa-warning fa-fw"></i> {{ errorText | translate }}</h4>
</ng-template>
