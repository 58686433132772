import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  CropZoneSoilMoistureSumsSmComponent
} from './containers/crop-zone-soil-moisture-sums-sm/crop-zone-soil-moisture-sums-sm.component';

const routes: Routes = [
  { path: 'sums-sm', component: CropZoneSoilMoistureSumsSmComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CropZoneSoilMoistureRoutingModule { }
