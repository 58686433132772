import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component,
  EventEmitter, Input, OnChanges, Output, SimpleChanges
} from '@angular/core';
import { IPicture } from '../camera/models/camera';
import { formatDate } from '../utils/dateFormat';

@Component({
  selector: 'app-custom-carousel',
  templateUrl: './custom-carousel.component.html',
  styleUrls: ['./custom-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCarouselComponent implements OnChanges {
  @Input()
  public title: string = '';
  @Input()
  public pictures: Array<IPicture> = [];
  @Input()
  public setSize: number = 5;
  @Input()
  public hasActivePicture: boolean = true;
  @Input()
  public isPlaying: boolean = false;
  @Input()
  public playSpeed: number = 1;
  @Input()
  public startIndex: number;
  @Input()
  public srcProperty: string = 'thumb';
  @Input()
  public fullSizeImages: boolean = false;
  @Input()
  public noPause: boolean = false;
  @Input()
  public noWrap: boolean = false;
  @Input()
  public showIndicators: boolean = true;
  @Input()
  public pictureBorder: boolean = true;
  @Input()
  public animationDuration: number = 1;
  @Input()
  public animation: boolean = false;
  @Input()
  public activeSlide: number = null;
  @Input()
  public deselect: boolean = false;

  @Output()
  public activatePictureEmitter: EventEmitter<IPicture> = new EventEmitter<IPicture>();
  public slides: Array<Array<IPicture>> = [];
  public activeSlideIndex: number;
  public activePicture: IPicture = null;

  public show = true;

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.pictures || changes.activeSlide) {
      // Reseting values to refresh the carousel
      this.activePicture = null;
      this.activeSlide = null;
      this.show = false;
      this.changeDetectorRef.detectChanges();
      setTimeout(() => {
        this.show = true;
        this.changeDetectorRef.detectChanges();
      });
      this.preparePictureSets();
      this.initActiveSlideAndPicture();
    }
  }

  public activatePicture(activePicture: IPicture): void {
    this.activePicture = activePicture;
    this.activatePictureEmitter.emit(this.activePicture);
  }

  public isActivePicture(picture: IPicture): boolean {
    return this.hasActivePicture
      && this.activePicture
      && this.activePicture.cam_id === picture.cam_id
      && this.activePicture.pic_id === picture.pic_id;
  }

  public toLocalDate(date: Date): string {
    return formatDate(date);
  }

  public getInterval(): number | boolean {
    return (this.isPlaying && this.playSpeed)
      ? (this.playSpeed * 1000)
      : false;
  }

  public startAnimation(): boolean {
    return this.isPlaying
      && this.playSpeed
      && this.animation;
  }

  private preparePictureSets(): void {
    this.slides = [];
    let pictureSet = [];
    let pictureIndex = 0;
    this.pictures.forEach((picture: IPicture): void => {
      if (pictureIndex === this.setSize) {
        this.slides.unshift(pictureSet);
        pictureIndex = 0;
        pictureSet = [];
      }
      pictureSet.unshift(picture);
      pictureIndex++;
    });
    if (pictureSet.length) {
      this.slides.unshift(pictureSet);
    }
  }

  private initActiveSlideAndPicture(): void {
    if (this.slides.length) {
      // setTimetout because of carousel's library bug
      setTimeout(() => {
        this.activeSlideIndex = this.startIndex === undefined
          ? this.slides.length - 1
          : this.startIndex;
        const lastSlide = this.slides[this.activeSlideIndex] || [];
        this.activePicture = lastSlide[lastSlide.length - 1] || null;
      });
    }
  }
}

