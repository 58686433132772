import { IActionWithPayload } from './../../../core/models/actionWithPayload';

export const enum SensorsActionTypes {
    GET_SENSORS = 'GET_SENSORS',
    SET_SENSORS = 'SET_SENSORS',
    RESET_SENSORS = 'RESET_SENSORS',
    /* GET_FILTERED_SENSORS = 'GET_FILTERED_SENSORS', */
    SET_FILTERED_SENSORS = 'SET_FILTERED_SENSORS',
    UPDATE_SENSORS = 'UPDATE_SENSORS',
    INIT_SENSORS_FROM_DATASOURCE = 'INIT_SENSORS_FROM_DATASOURCE'
}

export function getSensors(stationId: string, node: any): IActionWithPayload {
    return { type: SensorsActionTypes.GET_SENSORS, payload: {stationId: stationId, node: node }};
}

export function setSensors(payload: Array<any>, stationId: string, node: any): IActionWithPayload {
    return { type: SensorsActionTypes.SET_SENSORS, payload: {array: payload, stationId: stationId, node: node }};
}

export function resetSensors(): IActionWithPayload {
    return { type: SensorsActionTypes.RESET_SENSORS, payload: undefined };
}
/*
export function getFilteredSensors(): IActionWithPayload {
    return { type: SensorsActionTypes.GET_FILTERED_SENSORS, payload: undefined };
} */

export function setFilteredSensors(payload: Array<Array<any>>): IActionWithPayload {
    return { type: SensorsActionTypes.SET_FILTERED_SENSORS, payload: payload };
}

export function updateSensors(payload: {array: Array<Array<any>>, smStationId: string, node: string}): IActionWithPayload {
    return { type: SensorsActionTypes.UPDATE_SENSORS, payload: payload };
}

export function initSensorsFromDatasource(payload: Array<Array<any>>): IActionWithPayload {
    return { type: SensorsActionTypes.INIT_SENSORS_FROM_DATASOURCE, payload: payload };
}
