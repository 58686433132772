import { NotificationsConfigurationComponent } from './notifications-configuration.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
const routes: Routes = [
    { path: '', component: NotificationsConfigurationComponent }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class NotificationsConfigurationRoutingModule {}
