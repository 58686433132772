import { withLatestFrom } from 'rxjs/operators';
import { IAccount } from './../../../../../core/reducers/account';
import { ApiCallService } from './../../../../../services/api/api-call.service';
// tslint:disable-next-line:max-line-length
import { AbstractControlOptions, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { ICropZone, IDataSources, ISoilMoistureDataSource, ISoilMoistureDataSourceLayer, SoilMoistureTypeEnum } from './../../../../../core/models/cropzones';
import { ISaveSensor } from './../../../../sensors-and-nodes/models/sensors-and-nodes';
// tslint:disable-next-line:max-line-length
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as deepEqual from 'deep-equal';
import { Observable, Subject } from 'rxjs';
import { ISettings } from '../../../../../core/models/account';
import { IStation } from '../../../../../core/models/stations';
import { IState } from '../../../../../core/reducers';
import * as fromCropzones from '../../../../../core/reducers/cropzones';
import * as fromSelectedCropzone from '../../../../../core/reducers/selectedCropZone';
import * as fromStations from '../../../../../core/reducers/stations';
import { isEmpty, validate, validator } from '../../../../../modules/soil-moisture/util/settings-validator';
import { toColumns } from '../../../../soil-moisture/util/to-sensor-columns';
import { initSensorsFromDatasource } from '../../../actions/sensors';
import * as fromSensors from '../../../reducers/sensors';
import * as fromSoilMoistureStations from '../../../reducers/soilMoistureStations';
import { createEmptyCropzone } from '../../../util/soil-moisture-config-util';
import { selectCalibrationSettings, selectSettings, selectStations, selectSystemSensors, selectUserData } from './../../../../../core/reducers/index';
import { ISensorColumns } from './../../../../soil-moisture/models/index';
import { selectFilteredSensors, selectSensors, selectSoilMoistureStations } from './../../../reducers/index';

@Component({
  selector: 'app-soil-moisture-config-bottom',
  templateUrl: './soil-moisture-config-bottom.component.html',
  styleUrls: ['./soil-moisture-config-bottom.component.scss']
})
export class SoilMoistureConfigBottomComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public dataSource?: IDataSources;
  @Input()
  public cropzone: ICropZone;
  @Input()
  public selectedNode: any;
  @Input()
  public nodesList: any;
  @Input()
  public periodFrom: Date;
  @Input()
  public periodTo: Date;
  @Input()
  public selectedSoilMoistureStation: any;
  @Input()
  public indexx: number;

  @Output()
  public removeDatasource: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public save: EventEmitter<any> = new EventEmitter<any>();

  public previousCropzone: ICropZone = createEmptyCropzone();

  public smStations: Array<any>;
  public sensors$: Observable<ISensorColumns>;
  public userSettings$: Observable<ISettings>;

  public form: FormGroup = new FormGroup({});
  public sensorsList: Array<any> = [];
  public filteredSensorsList: Array<any> = [];
  private selectedCropzone: ICropZone;

  private stationsList: Array<IStation>;
  public unitSystem: string = 'metric';
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private firstTime: boolean = true;
  private previousColumns: any;

  constructor(
    private stationStore: Store<fromStations.IStations>,
    private sensorsStore: Store<fromSensors.ISensorsState>,
    private soilMoistureStationsStore: Store<fromSoilMoistureStations.ISoilMoistureStationsState>,
    private account: Store<IState>,
    private fb: FormBuilder,
    private api: ApiCallService,
    private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private cropzonesStore: Store<fromCropzones.ICropZones>
  ) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.cropzone) {
      this.selectedCropzone = changes.cropzone.currentValue;
    }
    this.checkfilteredSensors(this.dataSource);
  }

  public ngOnInit(): void {
    this.userSettings$ = this.account.pipe(
      select(selectSettings)
    );

    this.account.pipe(
      select(selectUserData),
      filter((u: IAccount) => !!u)
    ).subscribe((u: IAccount) => {
      if (u.settings !== null) {
        this.unitSystem = u.settings.unit_system;
      }
    });

    this.stationStore.pipe(
      select(selectStations)
    ).subscribe(stations => this.stationsList = stations);

    this.soilMoistureStationsStore.pipe(
      select(selectSoilMoistureStations)
    ).subscribe(soilMoistureStations => this.smStations = soilMoistureStations);

    this.sensorsStore.pipe(
      select(selectFilteredSensors)
    ).subscribe(sensorss => this.filteredSensorsList = sensorss);

    this.sensorsStore.pipe(
      select(selectSensors)
    ).subscribe(sensorss => {
      this.sensorsList = sensorss;
    });

  }

  private checkfilteredSensors(dataSource: any): void {
    if (this.selectedSoilMoistureStation && this.selectedNode) {
      // tslint:disable-next-line
      if (this.sensorsList.some(sensor => sensor.stationId === this.selectedSoilMoistureStation.id && sensor.node.node === this.selectedNode.node)) {
        this.setSensors();
      } else {
        setTimeout(() => { this.checkfilteredSensors(dataSource); }, 100);
      }
    } else {
      setTimeout(() => { this.checkfilteredSensors(dataSource); }, 100);
    }
  }

  private setSensors(): void {
    this.sensors$ = this.sensorsStore.pipe(
      select(selectSensors), takeUntil(this.destroy$),
      withLatestFrom(
        this.account.pipe(select(selectCalibrationSettings), takeUntil(this.destroy$)),
        this.account.pipe(select(selectSystemSensors), takeUntil(this.destroy$))
      ), map(([sensorss, calibrations, systemSensors]) => {

        const data = sensorss.find(sensor =>
          sensor.node.node.toString() === this.selectedNode.node.toString() &&
          sensor.stationId === this.selectedSoilMoistureStation.id
        );

        const sensorSet = data.sensors.filter(sensor => this.nodesList.some(node => node.ch === sensor.ch));

        return [sensorSet, calibrations, systemSensors];
      }),
      map(([sensorss, calibrations, systemSensors]) => sensorss.reduce(toColumns(calibrations, systemSensors), {})),
      tap((columns: ISensorColumns) => {
        if (!deepEqual(columns, this.previousColumns)) {
          this.form = this.buildForm(columns);

          this.previousColumns = columns;
        }

      }));

    if (this.dataSource) {
      // tslint:disable-next-line:max-line-length
        if (this.dataSource.device_name === this.selectedSoilMoistureStation.id && this.dataSource.source['layers'][0].node.toString() === this.selectedNode.node.toString()) {
          const copyOfSensorsList = JSON.parse(JSON.stringify(this.sensorsList));
          let index: number;
          copyOfSensorsList.forEach((sensor, indexx) => {
            // tslint:disable-next-line:max-line-length
            if (sensor.stationId === this.selectedSoilMoistureStation.id && sensor.node.node.toString() === this.selectedNode.node.toString()) {
              index = indexx;
            }
          });

          copyOfSensorsList[index].sensors.forEach((sensor, indexx) => {
            this.dataSource.source['layers'].forEach(layer => {
              if (layer.code === sensor.code && layer.channel === sensor.ch) {
                copyOfSensorsList[index].sensors[indexx].depth = layer.depth;
                copyOfSensorsList[index].sensors[indexx].field_capacity = layer.field_capacity;
                copyOfSensorsList[index].sensors[indexx].refill_point = layer.refill_point;
                copyOfSensorsList[index].sensors[indexx].unit = layer.unit;

                if (layer.calibration_id) {
                  copyOfSensorsList[index].sensors[indexx].calibration_id = layer.calibration_id;
                }
              }
            });
          });

          this.sensorsStore.dispatch(initSensorsFromDatasource(copyOfSensorsList));
        }
    }
  }



  private buildForm(columns: ISensorColumns): FormGroup {
    if (this.unitSystem === 'metric') {
      return this.fb.group(Object.keys(columns).reduce((groups, id) => {
        groups[id] = this.fb.group(columns[id].reduce((group, setting) => {
          group[setting.channel] = this.fb.group(setting);
          return group;
        }, {}));
        return groups;
      }, {}), { validator } as AbstractControlOptions);
    } else {
      return this.fb.group(Object.keys(columns).reduce((groups, id) => {
        groups[id] = this.fb.group(columns[id].reduce((group, setting) => {
          group[setting.channel] = this.fb.group(setting);
          group[setting.channel].controls.depth.setValue((group[setting.channel].controls.depth.value / 2.54).toFixed(1));
          return group;
        }, {}));
        return groups;
      }, {}), { validator } as AbstractControlOptions);
    }
  }

  public saveSettings(): void {
    this.checkStationsList();
  }

  private checkStationsList(): void {
    if (this.stationsList.length > 0) {
      this.saveSettingsContinues();
    } else {
      setTimeout(() => { this.checkStationsList(); }, 100);
    }
  }

  public saveSettingsContinues(): void {
    const settings = this.buildPayload();

    const soilMoistureDataSourceLayers: Array<ISoilMoistureDataSourceLayer> = [];
    settings.forEach(setting => {
      const layer: ISoilMoistureDataSourceLayer = {
        code: setting.code,
        channel: setting.channel,
        depth: <number>setting.depth,
        field_capacity: <number>setting.field_capacity,
        refill_point: <number>setting.refill_point,
        unit: <string>setting.unit,
        node: this.selectedNode.node.toString()
      };

      if (typeof (setting.calibration_id) === 'string') {
        layer.calibration_id = setting.calibration_id;
      }
      if (this.selectedNode.type === 'onStation') {
        layer.node = '';
      }
      soilMoistureDataSourceLayers.push(layer);
    });

    const soilMoistureSource: ISoilMoistureDataSource = {
      type: SoilMoistureTypeEnum.SOIL_MOISTURE_SENSOR_OR_PROBE,
      group: 25,
      layers: soilMoistureDataSourceLayers,
    };

    const smStationID = this.selectedSoilMoistureStation.id;
    const smStation = this.stationsList.filter(station => station.name.original === smStationID)[0];

    const soilMoistureDataSource: IDataSources = {
      device_name: smStationID,
      device_id: this.stationsList.filter(station => station.name.original === smStationID)[0]['info'].device_id,
      source: soilMoistureSource,
      module: 'SOIL_MOISTURE',
      from: this.periodFrom,
      to: this.periodTo
    };

    let alreadyExistingDataSource: boolean = false;

    if (this.selectedCropzone.data_sources.length > 0) {
      this.selectedCropzone.data_sources.forEach((datasource, indexx) => {
        if (datasource.module === 'SOIL_MOISTURE') {
          if (datasource.device_name === smStation.name.original
            && datasource.source['layers'][0].node.toString() === this.selectedNode.node.toString()) {
            this.selectedCropzone.data_sources[indexx] = soilMoistureDataSource;
            alreadyExistingDataSource = true;
          }
        }
      });
    }

    if (this.dataSource) {
      // Adjusting existing datasource
      this.selectedCropzone.data_sources.forEach((datasource, indexx) => {
        if (datasource.module === 'SOIL_MOISTURE') {
          if (datasource.device_name === this.dataSource.device_name &&
            datasource.source['layers'][0].node === this.dataSource.source['layers'][0].node) {
              this.selectedCropzone.data_sources[indexx] = soilMoistureDataSource;
            }
        }
      });
    } else {
      // Adding new datasource
      if (!alreadyExistingDataSource) {
        this.selectedCropzone.data_sources.push(soilMoistureDataSource);
      }
    }

    let index: number;

    this.sensorsList.forEach((sensor, indexx) => {
      if (sensor.stationId === smStationID && sensor.node.node.toString() === this.selectedNode.node.toString()) {
        index = indexx;
      }
    });

    const array: any[] = [];
    const formControlKeys = Object.keys(this.form.controls);

    formControlKeys.forEach(key => {
      const code: number = parseInt(key.substr(key.lastIndexOf('_') + '_'.length), 10);
      const channels = Object.keys(this.form.controls[key]['controls']);

      channels.forEach(channel => {
        if (channel !== 'soilProfile') {
          this.sensorsList[index].sensors.forEach((sensor, indexx) => {
            if (sensor.code === code && sensor.ch === parseInt(channel, 10)) {
              array.push(this.form.controls[key]['controls'][channel]);
            }
          });
        }
      });

    });
    // tslint:disable-next-line:max-line-length
    this.save.emit({ cropzone: this.selectedCropzone, array: array, smStationId: smStationID, node: this.selectedNode.node.toString(), datasource: soilMoistureDataSource });
  }

  public buildPayload(): ISaveSensor[] {
    const formValue = this.form.getRawValue();

    return Object.keys(formValue).reduce((settings: ISaveSensor[], id) => {
      Object.keys(formValue[id]).forEach(channel => {
        if (channel !== 'soilProfile') {
          const group = formValue[id][channel];
          const setting = Object.keys(group).filter(key => [
            'refill_point', 'field_capacity', 'depth', 'calibration_id'
          ].includes(key)).reduce((obj, key) => {
            const value = group[key];
            if (isEmpty(value)) {
              obj[key] = false;
            } else {
              obj[key] = Number.isFinite(+value) ? +value : value;
            }
            return obj;
          }, {});

          if (validate(this.form.get(id).get(channel).value)) {
            settings.push({
              ...setting,
              channel: group.channel,
              code: group.code,
              unit: group.unit,
              name: group.custom_name || undefined,
              color: group.color || undefined
            });
          }
        }
      });

      return settings;
    }, []);
  }

  public removeDataSource(): void {
    this.removeDatasource.emit(this.indexx);
  }

  public copyToRowsBelow(sensor): void {
    const channels: Array<any> = sensor.value.map((sensorX: any) => sensorX.channel.toString());
    // tslint:disable
    const refillPointFirstLine: FormControl = this.form.controls[sensor.key]['controls'][channels[0]]['controls']['refill_point'].value;
    const fieldCapacityFirstLine: FormControl = this.form.controls[sensor.key]['controls'][channels[0]]['controls']['field_capacity'].value;
    const unitFirstLine: FormControl = this.form.controls[sensor.key]['controls'][channels[0]]['controls']['unit'].value;
    const depthFirstLine: string = this.form.controls[sensor.key]['controls'][channels[0]]['controls']['depth'].value;
    const calibrationIdFirstLine: FormControl = this.form.controls[sensor.key]['controls'][channels[0]]['controls']['calibration_id'].value;
    // tslint:enable

    channels.forEach((channel, index) => {
      this.form.controls[sensor.key]['controls'][channel]['controls']['refill_point'].setValue(refillPointFirstLine);
      this.form.controls[sensor.key]['controls'][channel]['controls']['field_capacity'].setValue(fieldCapacityFirstLine);
      this.form.controls[sensor.key]['controls'][channel]['controls']['unit'].setValue(unitFirstLine);
      this.form.controls[sensor.key]['controls'][channel]['controls']['calibration_id'].setValue(calibrationIdFirstLine);

      if (this.unitSystem === 'metric') {
        const depth = parseInt(depthFirstLine, 10) + (index * 10);
        this.form.controls[sensor.key]['controls'][channel]['controls']['depth'].setValue(depth);
      } else {
        const depth = parseFloat(depthFirstLine) + (index * 3.937007874015748);
        this.form.controls[sensor.key]['controls'][channel]['controls']['depth'].setValue(depth.toFixed(1));
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public formErrors(sensor: any): boolean {
    if (this.form.controls[sensor.key].errors) {
      if (this.form.controls[sensor.key].errors.frequencyNotAllowed) {
        return true;
      }
    } else {
      return false;
    }
  }
}
