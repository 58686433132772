<form class="basic-info-form"
      [formGroup]="form">

  <div class="basic-info-form__header">
    {{ 'BASIC INFO' | translate }}
  </div>

  <div class="basic-info-form__item">
    <app-form-input [placeholder]="'Enter Title'"
                    [formControlName]="'title'"></app-form-input>
  </div>

  <div class="basic-info-form__item">
    <app-form-input [placeholder]="'Enter First Name'"
                    [formControlName]="'name'"></app-form-input>
  </div>

  <div class="basic-info-form__item">
    <app-form-input [placeholder]="'Enter Last Name'"
                    [formControlName]="'lastname'"></app-form-input>
  </div>

  <div class="basic-info-form__item">
    <app-form-input [placeholder]="'Enter email'"
                    [formControlName]="'email'"></app-form-input>
  </div>

  <div class="basic-info-form__item">
    <app-form-input [placeholder]="'Enter cellphone'"
                    [formControlName]="'cellphone'"></app-form-input>
  </div>

  <div class="basic-info-form__item">
      <app-form-input [placeholder]="'Enter phone'"
                      [formControlName]="'phone'"></app-form-input>
    </div>

  <div class="basic-info-form__item">
    <app-form-input [placeholder]="'Enter fax'"
                    [formControlName]="'fax'"></app-form-input>
  </div>

</form>
