import { InitialWorkPlanningStationSetting, IWorkPlanningSettingsPayload, IWorkPlanningSettingsState,
         IWorkPlanningStationModeSettings, IWorkPlanningStationSettings } from '../../models/work-planning.models';

  export class WorkPlanningSettingsHelper {
    private state: IWorkPlanningSettingsState;

    constructor(state: IWorkPlanningSettingsState) {
      this.state = state;
    }

    public stateHasDisabledGroupId(settings: IWorkPlanningSettingsPayload): boolean {
      const stationModeSettings: IWorkPlanningStationModeSettings = this.getStationModeSettings(settings.stationId, settings.mode);
      return stationModeSettings && stationModeSettings.disabledGroupIds.hasOwnProperty(settings.groupdId);
    }

    public enableGroupId(settings: IWorkPlanningSettingsPayload): void {
      delete this.getStationModeSettings(settings.stationId, settings.mode).disabledGroupIds[settings.groupdId];
    }

    public disableGroupId(settings: IWorkPlanningSettingsPayload): void {
      const stationModeSettings = this.prepareStationModeSettings(settings.stationId, settings.mode);
      stationModeSettings.disabledGroupIds[settings.groupdId] = true;
    }

    private getStationModeSettings(stationId: string, mode: string): IWorkPlanningStationModeSettings {
      const stationSettings: IWorkPlanningStationSettings = this.getStationSettings(stationId);
      return stationSettings && (stationSettings.modes[mode] || null);
    }

    private getStationSettings(stationId: string): IWorkPlanningStationSettings {
      return this.state.settings[stationId] || null;
    }

    private prepareStationModeSettings(stationId: string, mode: string): IWorkPlanningStationModeSettings {
      this.initStationSettings(stationId);
      const stationModeSettings = this.getStationModeSettings(stationId, mode);
      if (!stationModeSettings) {
        this.state.settings[stationId].modes[mode] = {disabledGroupIds: {}};
      }
      return this.getStationModeSettings(stationId, mode);
    }

    private initStationSettings(stationId: string): void {
      this.state.settings[stationId] = this.state.settings[stationId] || InitialWorkPlanningStationSetting;
    }
  }
