import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { centroid } from '@turf/turf';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { setCropZone } from '../../../../core/actions/cropzones';
import { setNotify } from '../../../../core/actions/notify';
import { setSelectedCropZone } from '../../../../core/actions/selectedCropZone';
import { ICrop, ICropZone, IDataSources, IDataSourceSensor, IYieldDataSource, IYieldPrediction } from '../../../../core/models/cropzones';
import { IStation } from '../../../../core/models/stations';
import {
  selectAvailableLicenses,
  selectNavigationStation,
  selectSelectedCropZone,
  selectStations,
  selectUserData
} from '../../../../core/reducers';
import { IAccount } from '../../../../core/reducers/account';
import * as fromCropzones from '../../../../core/reducers/cropzones';
import * as fromLicenses from '../../../../core/reducers/licenses';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import * as fromNotify from '../../../../core/reducers/notify';
import * as fromSelectedCropzone from '../../../../core/reducers/selectedCropZone';
import { IStations } from '../../../../core/reducers/stations';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { IOptions } from '../../../../shared/interfaces';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { dateToUtcUnixTimestamp, formatDate } from '../../../../shared/utils/dateFormat';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { IOptionsForDatasources } from '../../../irrimet-config/actions/irrimet-config.action';
import { yieldUnitTypes } from '../../../yield-prediction/constants/config';
import { getDatasourcesAirTemperature, getDatasourcesRain, setYieldConfigLoading, updateCropzone } from '../../actions/yield-config.action';
import { cropTypeOptions, initialSoilMoistureOptions, soilTextureOptions, yieldUnitOptions } from '../../constants/config';
import { ICropManagement, IFieldWiltingRequest, ISliderSettings } from '../../models/model';
import {
  airTemperatureDatasourceById,
  rainDatasourceById, selectDatasourcesAirTemperature,
  selectDatasourcesRain,
  selectYieldConfigIsLoading
} from '../../reducers';
import * as fromYieldConfig from '../../reducers/yield-config.reducer';

@Component({
  selector: 'app-yield-data',
  templateUrl: './yield-data.component.html',
  styleUrls: ['./yield-data.component.scss']
})
export class YieldDataComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public stationArray = [];
  public stationForecastList = [];
  public crops: any[];
  public crop: ICrop;
  public selectedCrop: any;
  public cropzone: ICropZone;
  public cropTypeOptions = cropTypeOptions;
  public cropType: any = '';
  public datasourcesRain$: Observable<IOptions[]>;
  public datasourcesAirTemperature$: Observable<IOptions[]>;
  public rainDatasource: any;
  public airTemperatureDatasource: any;
  public productKey: any = '';
  public activeLicenses: any;
  private alive$: Subject<boolean> = new Subject<boolean>();
  public soilTextureOptions = soilTextureOptions;
  public soilTexture: any = '';
  public initialSoilMoistureOptions = initialSoilMoistureOptions;
  public yieldUnitOptions = yieldUnitOptions;
  public initialSoilMoisture: any = '';
  public selectedDistance: IOptions = {value: '10 km', content: '10000'};
  public maximumDate: Date = new Date(new Date().getFullYear() + 10, 11, 31);
  public unitSystem: string;
  public actualUnit: string;
  public stationId: string;
  public pawIni: number = null;
  public yieldPrediction: IYieldPrediction = null;
  public sliderSettings: ISliderSettings = null;
  public modalId: string = generateId();
  public FCWPChanged: boolean = false;
  public currentAirItem: any = null;
  public currentRainItem: any = null;
  public yieldHelpText: string = '';
  public showAdvanced: boolean = false;
  public expiredLicense: boolean = false;
  public isSavingConfig$: Observable<boolean>;
  public cropManagement: ICropManagement;

  constructor(
    private fb: FormBuilder,
    private yieldConfigStore: Store<fromYieldConfig.IDataSourcesState>,
    private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private navigationStationStore: Store<INavigationStationState>,
    private userStore: Store<IAccount>,
    private apiCallService: ApiCallService,
    private stationStore: Store<IStations>,
    private translations: TranslateService,
    private notifyStore: Store<fromNotify.INotifyState>,
    private cropzoneStore: Store<fromCropzones.ICropZones>,
    private licenseStore: Store<fromLicenses.IProductLicenseState>,
    private modalService: ModalService

  ) { }

  public get cropTypeControl(): AbstractControl {
    return this.form.get('cropType');
  }

  public get fromDatepicker(): AbstractControl {
    return this.form.get('fromDatepicker');
  }

  public get toDatepicker(): AbstractControl {
    return this.form.get('toDatepicker');
  }

  public get bestPossibleYieldControl(): AbstractControl {
    return this.form.get('bestPossibleYield');
  }

  public get rainSourceControl(): AbstractControl {
    return this.form.get('rainSource');
  }

  public get airTemperatureSourceControl(): AbstractControl {
    return this.form.get('airTemperatureSource');
  }

  public get soilTextureControl(): AbstractControl {
    return this.form.get('soilTexture');
  }

  public get initialSoilMoistureControl(): AbstractControl {
    return this.form.get('initialSoilMoisture');
  }

  public get yieldUnitControl(): AbstractControl {
    return this.form.get('yieldUnit');
  }

  public get fieldCapacityControl(): AbstractControl {
    return this.form.get('fieldCapacity');
  }

  public get wiltingPointControl(): AbstractControl {
    return this.form.get('wiltingPoint');
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      'cropType': [this.cropType, [Validators.required]],
      'fromDatepicker': [new Date(), [Validators.required]],
      'toDatepicker': [new Date(), [Validators.required]],
      'bestPossibleYield': ['', [Validators.required]],
      'rainSource': ['', [Validators.required]],
      'airTemperatureSource': ['', [Validators.required]],
      'soilTexture': ['loam', [Validators.required]],
      'initialSoilMoisture': ['wet', [Validators.required]],
      'yieldUnit': [false, [Validators.required]],
      'fieldCapacity': ['', []],
      'wiltingPoint': ['', []],
    });

    this.navigationStationStore.pipe(
      select(selectNavigationStation),
      takeUntil(this.alive$)
    ).subscribe((station: IStation) => {
      if (station) {
        this.stationId = station.name.original;
      }
    });

    this.userStore.pipe(
      select(selectUserData),
      takeUntil(this.alive$),
      filter((user: IAccount) => !!user)
    ).subscribe((user: IAccount) => {
      if (user.settings !== null) {
        this.unitSystem = user.settings.unit_system;
        this.actualUnit = this.unitSystem === 'metric' ? 't/ha' : 'bu/ac';
        this.bestPossibleYieldControl.setValue(9999);
      }
    });

    this.stationStore.pipe(
      select(selectStations),
      takeUntil(this.alive$)
    ).subscribe((stations) => {
      this.stationArray = stations;
      stations?.map(item => {
        if (item.licenses?.Forecast === true) {
          this.stationForecastList.push(item?.name?.original);
        }
      });
    });

    this.isSavingConfig$ = this.yieldConfigStore.pipe(
      select(selectYieldConfigIsLoading)
    );

    this.datasourcesRain$ = this.yieldConfigStore.pipe(
      select(selectDatasourcesRain),
      map(datasourcesRain => datasourcesRain.map((item: any) =>
        ({ content: item, value: `${item.id} (${item.info.device_name})` + this.hasForecastLicense(item.id) + `` })
      )),
      map((sources) => sources.sort((a, b) => a.value < b.value ? -1 : 1))
    );

    this.datasourcesAirTemperature$ = this.yieldConfigStore.pipe(
      select(selectDatasourcesAirTemperature),
      map(datasourcesAirTemperature =>
        [].concat.apply([], datasourcesAirTemperature.map((item: any) => item.groups[1].map((group) => ({
          content: { ...item, groups: { 1: [group] } },
          value: `${item.id} [${group.ch}] (${item.info.device_name})` + this.hasForecastLicense(item.id) + ``
        })))
      )),
      map((sources) => sources.sort((a, b) => a.value < b.value ? -1 : 1))
    );

    this.selectedCropzoneStore.pipe(
      select(selectSelectedCropZone),
      takeUntil(this.alive$)
    ).subscribe((cropzone) => {
      this.rainDatasource = null;
      this.cropzone = cropzone;

      if (moment(this.cropzone?.license_end_date) < (moment(new Date()))) {
        this.expiredLicense = true;
        this.bestPossibleYieldControl.disable();
      } else {
        this.bestPossibleYieldControl.enable();
        this.expiredLicense = false;
      }

      if (this.cropzone.data_sources.filter(item => item.module === 'YIELD_PREDICTION').length === 0) {
        this.resetFormValues();
        this.yieldPrediction = null;
      } else {
        this.cropzone.data_sources.forEach(datasource => {
          if (datasource.module === 'YIELD_PREDICTION') {
            this.yieldPrediction = datasource.yield_prediction;
            this.soilTextureControl.setValue(datasource.yield_prediction.soil_texture);
            this.initialSoilMoistureControl.setValue(datasource.yield_prediction.initial_soil_moisture);
            this.cropTypeControl.setValue(datasource.yield_prediction.crop_type);
            this.fromDatepicker.setValue(datasource.yield_prediction.sowing_date);
            this.toDatepicker.setValue(datasource.yield_prediction.harvest_date_user_estimate);
            this.bestPossibleYieldControl.setValue(datasource.yield_prediction.yield_max_user_estimate);
            if (datasource.yield_prediction.wilting_point) {
              this.wiltingPointControl.setValue(datasource.yield_prediction.wilting_point);
              this.fieldCapacityControl.setValue(datasource.yield_prediction.field_capacity);
            }
            if (datasource.yield_prediction.unit) {
              const yieldUnit = datasource.yield_prediction.unit;
              this.yieldUnitControl.setValue(datasource.yield_prediction.unit);
              if (yieldUnitTypes(yieldUnit) !== null) {
                this.actualUnit = yieldUnit;
              }
            }
            if (datasource.yield_prediction.paw_ini) {
              this.pawIni = datasource.yield_prediction.paw_ini;
            }
            if (datasource.yield_prediction.sliders) {
              this.sliderSettings = datasource.yield_prediction.sliders;
            }
            if (datasource.source.type === 'MODEL') {
              this.rainDatasource = datasource;
              this.airTemperatureDatasource = datasource;
            } else if (datasource.source.type === 'AIR_SENSOR') {
              this.airTemperatureDatasource = datasource;
            }
          }
        });
      }
      this.setDatasources();
      if (cropzone.boundary) {
        this.onLoadRain();
        this.onLoadAirTemperature();
      }
      this.changeCropManagementData();
    });

    this.licenseStore.pipe(
      select(selectAvailableLicenses),
      filter((licenses) => !!licenses),
      takeUntil(this.alive$)
    ).subscribe(licenses => {
      this.activeLicenses = licenses;
      if (this.activeLicenses) {
        this.activeLicenses.map(license => {
          license.cropzones.map(cropzone => {
            if (cropzone.$oid === this.cropzone.id) {
              this.productKey = license.product_item.key;
            }
          });
        });
      }
    });

    this.fromDatepicker.valueChanges.subscribe(() => {
      if (this.fromDatepicker.value) {
        if (!this.checkDateRange(this.fromDatepicker.value)) {
          this.fromDatepicker.setValue(new Date(this.cropzone.from));
        }
      }
      this.changeCropManagementData();
    });
    this.toDatepicker.valueChanges.subscribe(() => {
      if (this.toDatepicker.value) {
        if (!this.checkDateRange(this.toDatepicker.value)) {
          this.toDatepicker.setValue(new Date(this.cropzone.to));
        }
      }
      this.changeCropManagementData();
    });
    this.rainSourceControl.valueChanges.subscribe((selectedValue) => {
      if (selectedValue !== this.form.value['rainSource'] && this.rainSourceControl.value) {
        this.changeCropManagementData();
      }
    });
    this.soilTextureControl.valueChanges.subscribe((selectedValue) => {
      if (selectedValue !== this.form.value['soilTexture'] && this.soilTextureControl.value && this.initialSoilMoistureControl.value) {
        const fieldWiltingData: IFieldWiltingRequest = {
          type: 'fc_wp',
          year: moment(this.toDatepicker.value).year(),
          sowing_date: dateToUtcUnixTimestamp(this.fromDatepicker.value),
          tsw_ini_value: this.initialSoilMoistureControl.value,
          soil_texture: this.soilTextureControl.value,
          cropZoneId: this.cropzone.id
        };
        this.apiCallService.calculateFieldWilting(this.stationId, fieldWiltingData).subscribe(response => {
          this.fieldCapacityControl.setValue(response.field_capacity ? response.field_capacity : 1);
          this.wiltingPointControl.setValue(response.wilting_point ? response.wilting_point : 1);
        });
        this.changeCropManagementData();
      }
    });
  }

  public openModal(helpText: string): void {
    this.yieldHelpText = helpText;
    this.modalService.openModal(this.modalId);
  }

  public popupTitle(): string {
    return this.yieldHelpText === 'advanced' ? 'Yield Prediction Advanced Settings' : 'Yield Prediction Settings';
  }

  public checkDateRange(date : any): boolean {
      const chosenDate = new Date(date);
      if (new Date(this.cropzone.from) <= chosenDate && chosenDate  <= new Date(this.cropzone.to)) {
        return true;
      } else {
        this.notifyStore.dispatch(setNotify('The date you picked is outside the cropzone’s cultivation period. ' +
        'Please use another date, or go to the cropzone’s general settings and change the cultivation period accordingly.'));
        return false;
      }
  }

  public getFieldWilting() : void {
    const fieldWiltingData : IFieldWiltingRequest = {
      type: 'fc_wp',
      year: moment(this.toDatepicker.value).year(),
      sowing_date: dateToUtcUnixTimestamp(this.fromDatepicker.value),
      tsw_ini_value: this.initialSoilMoistureControl.value,
      soil_texture: this.soilTextureControl.value,
      cropZoneId: this.cropzone.id
    };

    this.apiCallService.calculateFieldWilting(this.stationId, fieldWiltingData).subscribe(response => {
      this.fieldCapacityControl.setValue(response.field_capacity ? response.field_capacity : 1);
      this.wiltingPointControl.setValue(response.wilting_point ? response.wilting_point : 1);
      const pawIniRequestpayload : IFieldWiltingRequest = {
        type: 'initial_soil_water',
        year: moment(this.toDatepicker.value).year(),
        sowing_date: dateToUtcUnixTimestamp(this.fromDatepicker.value),
        tsw_ini_value: this.initialSoilMoistureControl.value,
        soil_texture: this.soilTextureControl.value,
        fc_sensor: this.fieldCapacityControl.value,
        wp_sensor: this.wiltingPointControl.value,
      };
      this.apiCallService.calculateFieldWilting(this.stationId, pawIniRequestpayload).subscribe(resp => {
        this.pawIni = resp.paw_ini;
        this.yieldPrediction = {
          ...this.yieldPrediction,
          field_capacity: this.fieldCapacityControl.value,
          wilting_point: this.wiltingPointControl.value,
          paw_ini: resp.paw_ini
        };
      });
    });
  }

  public saveConfiguration() : void {
    if (this.form.invalid) {
      this.notifyStore.dispatch(
        setNotify('Configuration could not be saved, please fill out all fields (also check within “Advanced Settings”).'));
      return;
    }
    if (!(this.stationForecastList?.includes(this.airTemperatureSourceControl.value.content.id) ||
          this.stationForecastList?.includes(this.rainSourceControl.value.content.id))) {
      this.notifyStore.dispatch(
        setNotify('Settings could not be saved. You must select at least one station with an active weather forecast license.')
        );
      return;
    }
    this.yieldConfigStore.dispatch(setYieldConfigLoading(true));
    const reqStation = this.stationArray.filter(station =>
      station.name.original === this.rainSourceControl.value.content.id
    );
    this.stationId = reqStation[0].name.original;

    let yieldPredictionValues : IYieldPrediction;
    if (this.wiltingPointControl.value && this.fieldCapacityControl.value && this.FCWPChanged) {
      if (Number(this.fieldCapacityControl.value) < Number(this.wiltingPointControl.value)) {
        this.notifyStore.dispatch(setNotify('Field Capacity must be higher than Wilting Point, please change the values.'));
        this.yieldConfigStore.dispatch(setYieldConfigLoading(false));
        return;
      }
      this.soilTextureControl.setValue('custom');
      const pawIniRequestpayload : IFieldWiltingRequest = {
        type: 'initial_soil_water',
        year: moment(this.toDatepicker.value).year(),
        sowing_date: dateToUtcUnixTimestamp(this.fromDatepicker.value),
        tsw_ini_value: this.initialSoilMoistureControl.value,
        soil_texture: this.soilTextureControl.value,
        fc_sensor: Number(this.fieldCapacityControl.value),
        wp_sensor: Number(this.wiltingPointControl.value),
        cropZoneId: this.cropzone.id
      };
      this.apiCallService.calculateFieldWilting(this.stationId, pawIniRequestpayload).subscribe(resp => {
        this.pawIni = resp.paw_ini;
        this.cropzone.data_sources.forEach((datasource, index) => {
          if (datasource.module === 'YIELD_PREDICTION' && datasource.source.type === 'MODEL') {
            const temp = datasource;
            temp.yield_prediction.field_capacity = Number(this.fieldCapacityControl.value);
            temp.yield_prediction.wilting_point = Number(this.wiltingPointControl.value);
            temp.yield_prediction.paw_ini = this.pawIni;
            temp.yield_prediction.soil_texture = this.soilTextureControl.value;
            yieldPredictionValues = temp.yield_prediction;
            this.yieldPrediction = {
              ...this.yieldPrediction,
              field_capacity: Number(this.fieldCapacityControl.value),
              wilting_point: Number(this.wiltingPointControl.value),
              paw_ini: resp.paw_ini
            };
          }
        });
        this.FCWPChanged = false;
      });
    } else {
      this.getFieldWilting();
    }
    setTimeout(() => {
      yieldPredictionValues = {
        sowing_date: formatDate(this.fromDatepicker.value),
        harvest_date_user_estimate: formatDate(this.toDatepicker.value),
        yield_max_user_estimate: Number(this.bestPossibleYieldControl.value),
        soil_texture: this.soilTextureControl.value,
        initial_soil_moisture: this.initialSoilMoistureControl.value,
        unit: this.yieldUnitControl.value === 'false' ? Boolean(false) : this.yieldUnitControl.value,
        crop_type: this.cropTypeControl.value
      };

      const airDataSourceStation = this.stationArray.filter(station =>
        station.name.original === this.airTemperatureSourceControl.value.content.id
      );

      const airTemperatureGroupNumber = parseInt(Object.keys(this.airTemperatureSourceControl.value.content.groups)[0], 10);
      const airTemperatureSensor: IDataSourceSensor = {
        group: airTemperatureGroupNumber,
        channel: this.airTemperatureSourceControl.value.content.groups[airTemperatureGroupNumber][0].ch,
        code: this.airTemperatureSourceControl.value.content.groups[airTemperatureGroupNumber][0].c
      };

      const sourceYieldAirTemperature: IYieldDataSource = {
        type: 'AIR_SENSOR',
        id: 'yield_prediction',
        sensors: [airTemperatureSensor]
      };

      // newly selected data source for air temp
      const dataSource_AirTemperature: IDataSources = {
        device_name: this.airTemperatureSourceControl.value.content.id,
        device_id: airDataSourceStation[0].info.device_id,
        source: sourceYieldAirTemperature,
        yield_prediction: yieldPredictionValues,
        module: 'YIELD_PREDICTION'
      };

      const rainDataSourceStation = this.stationArray.filter(station =>
        station.name.original === this.rainSourceControl.value.content.id
      );

      const rainGroupNumber = parseInt(Object.keys(this.rainSourceControl.value.content.groups)[0], 10);
      const rainSensor: IDataSourceSensor = {
        group: rainGroupNumber,
        channel: this.rainSourceControl.value.content.groups[rainGroupNumber][0].ch,
        code: this.rainSourceControl.value.content.groups[rainGroupNumber][0].c
      };

      const sourceYieldRain: IYieldDataSource = {
        type: 'MODEL',
        id: 'yield_prediction',
        sensors: [rainSensor, airTemperatureSensor],
        sensor_rain: rainSensor,
        sensor_air: airTemperatureSensor,
        device_id: [airDataSourceStation[0].name.original, rainDataSourceStation[0].name.original]
      };

      this.yieldPrediction = {
        ...this.yieldPrediction,
        ...yieldPredictionValues,
        sliders: this.sliderSettings
      };
      // newly selected data source for rain
      const dataSource_Rain: IDataSources = {
        device_name: this.rainSourceControl.value.content.id,
        device_id: rainDataSourceStation[0].info.device_id,
        source: sourceYieldRain,
        yield_prediction: this.yieldPrediction,
        module: 'YIELD_PREDICTION'
      };

      if (this.cropzone.data_sources.some(datasource => datasource.module === 'YIELD_PREDICTION') === true ) {
        // datasource for rain and air temperature already exist => overwrite these datasources
        this.cropzone.data_sources.forEach((datasource, index) => {
          if (datasource.module === 'YIELD_PREDICTION' && datasource.source.type === 'MODEL') {
              this.cropzone.data_sources[index] = dataSource_Rain;
          }
        });
      } else {
        // no datasource for rain or air temperature available yet => push to datasources
        this.cropzone.data_sources.push(dataSource_Rain);
      }
      if (this.productKey) {
        this.cropzone.product_key = this.productKey;
      }
      this.cropzone.crop = this.crop;
      this.cropzone.distance = +this.selectedDistance.content;

      this.cropzoneStore.dispatch(setCropZone(this.cropzone));
      this.selectedCropzoneStore.dispatch(setSelectedCropZone(this.cropzone));
      this.yieldConfigStore.dispatch(updateCropzone(this.cropzone));
    }, 3000);
  }

  public isCustomValue() : boolean {
    if (this.expiredLicense) {
      return false;
    }
    return this.soilTextureControl.value === 'custom' ? true : false;
  }

  public advancedSettings() : void {
    this.showAdvanced = !this.showAdvanced;
  }

  public onFCWPChange() : void {
    this.FCWPChanged = true;
  }

  public onLoadRain(): void {
    this.setFromTo();
    const groups = '5';

    this.yieldConfigStore.dispatch(getDatasourcesRain(
      this.prepareData(groups, +this.selectedDistance.content)
    ));
  }

  public validateDecimal(event) : void {
    const value = event.target.value;
    this.bestPossibleYieldControl.setValue(Number(Math.abs(value * 100) / 100).toFixed(2));
  }

  public setFromTo(): void {
    let isDate: boolean = <any>this.cropzone.from instanceof Date;
    let isDateTo: boolean = <any>this.cropzone.to instanceof Date;

    if (isDate) {
      const fromDate: Date = <any>this.cropzone.from;
      this.cropzone.from = fromDate.toISOString();
      isDate = false;
    }
    if (isDateTo) {
      const toDate: Date = <any>this.cropzone.to;
      this.cropzone.to = toDate.toISOString();
      isDateTo = false;
    }
  }

  public onLoadAirTemperature(): void {
    this.setFromTo();
    const groups = '1';

    this.yieldConfigStore.dispatch(getDatasourcesAirTemperature(
      this.prepareData(groups, +this.selectedDistance.content)
    ));
  }

  private prepareData(groups: string, distance: number): IOptionsForDatasources {
    return {
      centroid: centroid(this.cropzone.boundary),
      from: this.cropzone.from.split('T')[0],
      to: this.cropzone.to.split('T')[0],
      groups,
      distance
    };
  }

  public changeCropManagementData(): void {
    this.cropManagement = {
      crop: this.cropTypeControl.value,
      sowing_date: dateToUtcUnixTimestamp(this.fromDatepicker.value),
      harvest_date: dateToUtcUnixTimestamp(this.toDatepicker.value),
      crop_id: this.cropzone?.id,
      station_id: this.rainSourceControl?.value?.content?.id,
      yield_unit: this.actualUnit,
      soil_texture: this.soilTextureControl.value
    };
  }

  private hasForecastLicense(station: string): string {
    if (this.stationForecastList?.includes(station)) {
      return ' (Active Weather Forecast) ';
    }
    return '';
  }

  public changeSliders(changeSlider: ISliderSettings): void {
    if (this.sliderSettings !== changeSlider) {
      this.sliderSettings = changeSlider;
    }
  }

  private setDatasources(): void {
    let hasYieldRainDatasources: boolean = false;
    let hasYieldAirTemperatureDatasources: boolean = false;
    if (this.cropzone.data_sources.length > 0) {
      this.cropzone.data_sources.forEach(datasource => {
        if (datasource.module === 'YIELD_PREDICTION') {
          if (datasource.source.type === 'MODEL') {
            hasYieldRainDatasources = true;
            hasYieldAirTemperatureDatasources = true;
          }
        } else if (datasource.source.type === 'AIR_SENSOR') {
          hasYieldAirTemperatureDatasources = true;
        }
      });
    }

    if (hasYieldRainDatasources && this.rainDatasource) {
      this.yieldConfigStore.pipe(
        select(rainDatasourceById(this.rainDatasource.device_name)),
        takeUntil(this.alive$)
      ).subscribe((rainDatasource) => {
        if (this.cropzone.data_sources.length > 0) {
          if (rainDatasource) {
            const rainOption = {
              content: rainDatasource,
              value: `${this.rainDatasource.device_name} (${rainDatasource.info.device_name})`
                      + this.hasForecastLicense(this.rainDatasource.device_name) + ``
            };
            if (this.rainDatasource) {
              this.rainSourceControl.setValue(rainOption);
            }
          }
        }
      });
    } else {
      this.datasourcesRain$.subscribe(items => {
        this.currentRainItem = items[0];
        this.rainSourceControl.setValue(this.currentRainItem);
      });
    }
    if (hasYieldAirTemperatureDatasources && this.airTemperatureDatasource) {

      this.yieldConfigStore.pipe(
        select(airTemperatureDatasourceById(this.airTemperatureDatasource.source?.device_id[0])),
        takeUntil(this.alive$)
        ).subscribe((airTemperatureDatasource) => {
        if (this.cropzone.data_sources.length > 0) {
          if (airTemperatureDatasource) {
            const airTempratureOption = {
              content: airTemperatureDatasource,
              value: `${this.airTemperatureDatasource.source?.device_id[0]} ` +
                     `[${this.airTemperatureDatasource.source?.sensor_air.channel}] (${airTemperatureDatasource.info.device_name})` +
                     this.hasForecastLicense(this.airTemperatureDatasource.source?.device_id[0])
            };
            if (this.airTemperatureDatasource) {
              this.airTemperatureSourceControl.setValue(airTempratureOption);
            }
          }
        }
      });

    } else {
      this.datasourcesAirTemperature$.subscribe(items => {
        this.currentAirItem = items[0];
        this.airTemperatureSourceControl.setValue(this.currentAirItem);
      });
    }
    this.changeCropManagementData();
  }

  private resetFormValues(): void {
    this.form.reset();
    this.bestPossibleYieldControl.setValue(9999);
    this.soilTextureControl.setValue('loam');
    this.initialSoilMoistureControl.setValue('wet');
    this.yieldUnitControl.setValue('false');
    this.fromDatepicker.setValue(new Date());
    this.toDatepicker.setValue(new Date());
    this.cropTypeControl.setValue('');
    this.pawIni = null;
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }

}
