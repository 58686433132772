import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IStationDataSettingsState} from '../reducers/station-data-settings';

export const enum StationDataSettingsActionTypes {
  SET_STATION_DATA_SETTINGS = '[StationDataSettings] SET_STATION_DATA_SETTINGS',
  SET_STATION_CALCULATE_ACTIVE = '[StationDataSettings] SET_STATION_CALCULATE_ACTIVE',
  SET_STATION_CHART_ACTIVE = '[StationDataSettings] SET_STATION_CHART_ACTIVE',
  SET_STATION_TABLE_ACTIVE = '[StationDataSettings] SET_STATION_TABLE_ACTIVE',
  SET_STATION_EXPORT_ACTIVE = '[StationDataSettings] SET_STATION_EXPORT_ACTIVE',
  SET_STATION_CALCULATION_ENABLED = '[StationDataSettings] SET_STATION_CALCULATION_ENABLED'
}

export function setStationCalculationEnabled(isEnabled: boolean): IActionWithPayload {
  return { type: StationDataSettingsActionTypes.SET_STATION_CALCULATION_ENABLED, payload: isEnabled };
}

export function setStationDataSettings(settings: IStationDataSettingsState): IActionWithPayload {
  return { type: StationDataSettingsActionTypes.SET_STATION_DATA_SETTINGS, payload: settings };
}

export function setStationCalculateActive(isActive: boolean): IActionWithPayload {
  return { type: StationDataSettingsActionTypes.SET_STATION_CALCULATE_ACTIVE, payload: isActive };
}
export function setStationChartActive(isActive: boolean): IActionWithPayload {
  return { type: StationDataSettingsActionTypes.SET_STATION_CHART_ACTIVE, payload: isActive };
}

export function setStationTableActive(isActive: boolean): IActionWithPayload {
  return { type: StationDataSettingsActionTypes.SET_STATION_TABLE_ACTIVE, payload: isActive };
}

export function setStationExportActive(isActive: boolean): IActionWithPayload {
  return { type: StationDataSettingsActionTypes.SET_STATION_EXPORT_ACTIVE, payload: isActive };
}


