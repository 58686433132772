import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import {ActionTypes, getServerAlerts} from '../actions/server-alerts';
import { IServerAlert } from '../interfaces/server-alert';
import { ISensor } from '../../../shared/interfaces';

export interface IServerAlertsState {
  alerts: IServerAlert[];
  sensors: ISensor[];
}

export const initialState: IServerAlertsState = {
  alerts: [],
  sensors: []
};

export function reducer(state: IServerAlertsState = initialState, action: IActionWithPayload): IServerAlertsState {
  switch (action.type) {
    case ActionTypes.SET_STATION_SENSORS: {
      return {
        ...state,
        sensors: action.payload
      };
    }
    case ActionTypes.SET_SERVER_ALERTS: {
      return {
        ...state,
        alerts: action.payload
      };
    }
    case ActionTypes.UPDATE_SERVER_ALERT: {
      return {
        ...state,
        alerts: state.alerts.map(alert => {
          if (
            action.payload._id &&
            alert._id === action.payload._id
          ) {
            return { ...action.payload };
          } else {
            return alert;
          }
        })
      };
    }
    case ActionTypes.APPEND_SERVER_ALERT: {
      return {
        ...state,
        alerts: [...state.alerts, { ...action.payload }]
      };
    }
    case ActionTypes.DELETE_SERVER_ALERT: {
      return {
        ...state,
        alerts: state.alerts.filter(alert =>
          alert._id !== action.payload.alert._id
        )
      };
    }
    default: {
      return state;
    }
  }
}
