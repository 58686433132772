import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IStation } from '../../core/models/stations';
import { selectNavigationStation } from '../../core/reducers';
import * as fromNavigationStation from '../../core/reducers/navigation-station';
import { stationDataContentAnimations } from '../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../core/services/left-components-toggler/left-components-toggler.service';
import { ITreeStructure } from '../../services/tree/models';
import { TreeService } from '../../services/tree/tree.service';
import { StationTypes, solAntennaDeviceIDs, uMetosNBIoTDevices } from '../../shared/constants';
import { StationTopologyLinkType, LoggingTransferGroups } from './constants/constants';
import { ILoggingTransferDevice } from './models/station-config.models';

@Component({
  selector: 'app-station-config',
  templateUrl: './station-config.component.html',
  styleUrls: ['./station-config.component.scss'],
  animations: stationDataContentAnimations
})
export class StationConfigComponent implements OnInit, OnDestroy {
  public selectedStation        : IStation;
  public state$                 : Observable<string>;
  public readonly readOnly      : string = 'r';
  public readonly simpleStation : number = 20;
  public tree$                  : Observable<ITreeStructure> = this.treeService.getStationSettingsTreeStructure();

  private destroy$              : Subject<boolean> = new Subject<boolean>();
  public showTimezoneOnly       : boolean;
  private loggingTransferGroups : ILoggingTransferDevice[] = LoggingTransferGroups;

  public topologyLinkType       = StationTopologyLinkType;

  constructor(private leftComponentsToggler: LeftComponentsTogglerService,
              private navigationStore: Store<fromNavigationStation.INavigationStationState>,
              private treeService: TreeService
              ) { }

  public ngOnInit(): void {
    this.state$ = this.leftComponentsToggler.getStationDataContentState();

    this.navigationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation)
    ).subscribe((s: IStation) => {
      this.selectedStation = s;
      if (this.selectedStation) {
        this.showTimezoneOnly = this.showOnlyTimezone();
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public checkSensors(): boolean {
    return this.selectedStation.info.device_id === StationTypes.get('IScoutType')
      || this.selectedStation.info.device_id === StationTypes.get('CropViewType1')
      || this.selectedStation.info.device_id === StationTypes.get('CropViewType2')
      || this.selectedStation.info.device_id === StationTypes.get('CropViewType3')
      || this.selectedStation.info.device_id === StationTypes.get('CameraNewControlUnit');
  }

  public checkIfNBIoT(): boolean {
    return uMetosNBIoTDevices.includes(this.selectedStation.info.device_id);
  }

  public checkIfSolAntenna(): boolean {
    return solAntennaDeviceIDs.includes(this.selectedStation.info.device_id);
  }

  public checkIfTracker(): boolean {
    return this.selectedStation.info.device_id === StationTypes.get('iMetos Active Tracker')
      || this.selectedStation.info.device_id === StationTypes.get('iMetos Object Tracker');
  }

  public showOnlyTimezone(): boolean {
    return this.selectedStation.info.device_id === StationTypes.get('iMetos Active Tracker')
      || this.selectedStation.info.device_id === StationTypes.get('iMetos Object Tracker');
  }

  public checkRights(): boolean {
    return this.selectedStation.rights !== this.readOnly;
  }

  public checkSimple(): boolean {
    return this.selectedStation.info.device_id < this.simpleStation;
  }

  public checkIfLEDTrap(): boolean {
    return this.selectedStation.topology
      && this.selectedStation.topology.cam2
      && this.selectedStation.topology.cam2.match(/^07F/) !== null;
  }

  public isNMetosFrost(): boolean {
    return this.selectedStation.flags?.hasOwnProperty('nmetosfrost') && this.selectedStation.flags?.nmetosfrost;
  }

  public isNMetosFWT(): boolean {
    return this.selectedStation.flags?.hasOwnProperty('nmetosfwt') && this.selectedStation.flags?.nmetosfwt;
  }

  public checkValidDeviceGroup(): boolean {
    const deviceGroupArray = this.loggingTransferGroups.filter((group) => group.deviceIDs.includes(this.selectedStation.info.device_id));
    if (deviceGroupArray.length > 0) {
      const deviceGroup = deviceGroupArray[0];
      if (deviceGroup.measuring || deviceGroup.logging || deviceGroup.fixedTransfer) {
        return true;
      }
    }
    return false;
  }
}
