import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {WeatherForecastActionTypes} from '../actions/weather-forecast';
import {IWeatherForecastState} from '../models/models';

export const initialState: IWeatherForecastState = {
  chartsOptions: [],
  grid: null,
  isLoading: true,
  isError: false,
  mode: null,
  weatherForecastLicenses: [],
  warning: {
    message: null,
    icon: false
  }
};

export function reducer(state: IWeatherForecastState = initialState, action: IActionWithPayload): IWeatherForecastState {
  switch (action.type) {
    case WeatherForecastActionTypes.SET_WEATHER_FORECAST:
      return <IWeatherForecastState>{
        ...state,
        chartsOptions: action.payload.chartsOptions,
        grid: action.payload.grid,
        mode: action.payload.mode
      };
    case WeatherForecastActionTypes.SET_WEATHER_FORECAST_LOADING:
      return <IWeatherForecastState>{
        ...state,
        isLoading: action.payload
      };
    case WeatherForecastActionTypes.SET_WEATHER_FORECAST_ERROR:
      return <IWeatherForecastState>{
        ...state,
        isError: action.payload
      };
    case WeatherForecastActionTypes.SET_STATION_WEATHER_FORECAST_LICENSES:
      let forecastLicenses = [];
      if (action.payload['Forecast']) {
        forecastLicenses = action.payload['Forecast'];
      }
      return <IWeatherForecastState>{
        ...state,
        weatherForecastLicenses: forecastLicenses
      };
    case WeatherForecastActionTypes.SET_WF_WARNING_MESSAGE:
      return <IWeatherForecastState>{
        ...state,
          warning: action.payload
      };
    default:
      return state;
  }
}
