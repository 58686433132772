import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ILEDTrap, IStation } from '../../../../core/models/stations';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { deepClone } from '../../../../shared/utils/deepClone';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { saveLedSettings, setLedSettings, setScheduler, setTemperatureThreshold } from '../../actions/ledTrapSettings';
import { ILEDTrapSave, parseScheduler, parseSchedulerBinary } from '../../models/station-config.models';
import { selectLedTrapSettings } from '../../reducers';
import * as fromLedTrapSettings from '../../reducers/ledTrapSettings';

@Component({
  selector: 'app-station-led-trap',
  templateUrl: './station-led-trap.component.html',
  styleUrls: ['./station-led-trap.component.scss']
})
export class StationLedTrapComponent implements OnInit, OnChanges {
  @Input()
  public station: IStation;

  public ledTrapSettings: ILEDTrap;
  public schedulerMatrix: string[][];
  public modalId: string = generateId();

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private oldStation: string = '';

  constructor(
    private ledTrapSettingsStore: Store<fromLedTrapSettings.ILedTrapSettingsState>,
    private modalService: ModalService
  ) {
  }

  public ngOnInit(): void {
    this.ledTrapSettingsStore.pipe(
      takeUntil(this.destroy$),
      select(selectLedTrapSettings)
    ).subscribe((ledTrapSettings: ILEDTrap) => {
      this.ledTrapSettings = ledTrapSettings;
      this.schedulerMatrix = parseScheduler(this.ledTrapSettings.led_scheduler);
    });
  }

  public ngOnChanges(): void {
    if (this.station && this.station.name.original !== this.oldStation) {
      const ledTrapSettings: ILEDTrap = {
        led_scheduler: this.station.config.led_scheduler,
        led_settings: {
          led1: this.station.config.led_settings.led1,
          led2: this.station.config.led_settings.led2,
          temperature_threshold: this.station.config.led_settings.temperature_threshold,
        },
      };
      this.ledTrapSettingsStore.dispatch(setLedSettings(ledTrapSettings));
    }
  }

  public get temperatureThreshold(): number {
    return this.ledTrapSettings.led_settings.temperature_threshold;
  }

  public changeTemperatureThreshold(temperatureThreshold: number): void {
    this.ledTrapSettingsStore.dispatch(setTemperatureThreshold(temperatureThreshold));
  }

  public changeScheduler(scheduler: string[][]): void {
    const schedulerString: string = scheduler.reduce((p, a) => p.concat(a), []).reverse().join('');
    this.ledTrapSettingsStore.dispatch(setScheduler(parseInt(schedulerString, 2)));
  }

  public save(): void {
    const saveStation: IStation = deepClone(this.station);
    this.oldStation = saveStation.name.original;

    const save: ILEDTrapSave = {
      station: saveStation,
      saveObj: {
        'config.led_trap.scheduler': parseSchedulerBinary(this.ledTrapSettings.led_scheduler),
        'config.led_trap.led_1_time_on': this.ledTrapSettings.led_settings.led1.led_time_on,
        'config.led_trap.led_1_time_off': this.ledTrapSettings.led_settings.led1.led_time_off,
        'config.led_trap.led_2_time_on': this.ledTrapSettings.led_settings.led2.led_time_on,
        'config.led_trap.led_2_time_off': this.ledTrapSettings.led_settings.led2.led_time_off,
        'config.led_trap.temperature_threshold': this.ledTrapSettings.led_settings.temperature_threshold,
      },
    };

    this.ledTrapSettingsStore.dispatch(saveLedSettings(save));
  }

  public openHelpModal(): void {
    this.modalService.openModal(this.modalId);
  }
}
