import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-logging-and-transfer-modal-two',
  templateUrl: './logging-and-transfer-modal-two.component.html',
  styleUrls: ['./logging-and-transfer-modal-two.component.scss']
})
export class LoggingAndTransferModalTwoComponent {
  @Input()
  public isIScout       : boolean;
  @Input()
  public isCropView     : boolean;
  @Input()
  public isAdvanced     : boolean;
  @Input()
  public isNbiot?       : boolean;
  @Input()
  public isMetosFrost?  : boolean;
}
