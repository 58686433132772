import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { addNewDashboard, changeDashboard } from '../../actions/dashboard';
import { selectSiblings, selectSubdomain } from '../../reducers';
import * as fromDashboard from '../../reducers/dashboard';
import { capitalize } from '../../utils/prepareDashboard';

@Component({
  selector: 'app-more-options',
  templateUrl: './more-options.component.html',
  styleUrls: ['./more-options.component.scss']
})
export class MoreOptionsComponent implements OnInit {
  @Output()
  public close: EventEmitter<void> = new EventEmitter();
  @Output()
  public restore: EventEmitter<void> = new EventEmitter();
  @Output()
  public addColumns: EventEmitter<number> = new EventEmitter();
  @Output()
  public setDefault: EventEmitter<void> = new EventEmitter();
  @Output()
  public addDashboardButtonIsClicked = new EventEmitter();


  public subdomain$: Observable<string>;

  public get dashboards(): Observable<{ id: string, title: string}[]> {
    return this.dashboardStore.pipe(select(selectSiblings));
  }

  public constructor(
    private dashboardStore: Store<fromDashboard.IDashboard>
  ) {

  }

  public ngOnInit(): void {
    this.subdomain$ = this.dashboardStore.pipe(
      select(selectSubdomain),
      map((data: string) => capitalize(data)),
      map((subdomain: string) => `Restore ${subdomain} dashboard`)
    );
  }

  public changeDashboard(data: {id: string, title: string}): void {
    this.dashboardStore.dispatch(changeDashboard(data));
  }

  public addDashboard(): void {
    this.addDashboardButtonIsClicked.emit();
    this.dashboardStore.dispatch(addNewDashboard());
  }
}
