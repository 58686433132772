<div class="add-remove">
  <div class="add-remove__header">
    <div (click)="isAddStation = true"
         [class.add-remove__header-item__inactive]="!isAddStation"
         class="add-remove__header-item"><i class="zmdi zmdi-plus-circle"></i>{{ 'Add station' | translate }}
    </div>
    <div (click)="isAddStation = false"
         [class.add-remove__header-item__inactive]="isAddStation"
         class="add-remove__header-item"><i class="zmdi zmdi-minus-circle"></i>{{ 'Remove station' | translate }}
    </div>
  </div>
  <div class="add-remove__form">
    <div class="alert alert-info">
      <ng-container *ngIf="isAddStation; else remove">
        <p>
          {{ 'To add a new station, please insert the station ID and key, included in your' | translate }}
          {{ portalName | translate }}
          {{ 'station kit. Insert (key 1) if you want to change station settings or (key 2) if you only want to access the data.' | translate }}
        </p>
      </ng-container>
    </div>
    <app-add-remove-station-form [hidden]="!isAddStation"
                                 (submit)="addStation($event)"
                                 [isAddForm]="true"></app-add-remove-station-form>
    <app-add-remove-station-form [hidden]="isAddStation"
                                 (submit)="removeStation($event)"
                                 [isAddForm]="false"></app-add-remove-station-form>
  </div>
</div>


<ng-template #remove>
  <p>
    {{ 'Use the Station ID and key that came with your' | translate }} 
    {{ portalName | translate }}
    {{ 'station to remove it from your list.' | translate }}
  </p>
</ng-template>
