import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AgGridEvent, ColDef, GridOptions } from 'ag-grid';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IStationBasicInfo } from '../../../../core/models/navigation-station';
import { selectNavigationStationBasicInfo } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { dateToUtcUnixTimestamp } from '../../../../shared/utils/dateFormat';
import { ILngLat, ITrackerData, ITrackerPoint, ITrackerTripsData } from '../../models/tracker.models';
import { TrackerService } from '../../services/tracker.service';
import * as fromTracker from './../../reducers/index';


@Component({
  selector: 'app-tracker-table',
  templateUrl: './tracker-table.component.html',
  styleUrls: ['./tracker-table.component.scss']
})
export class TrackerTableComponent implements OnInit, OnDestroy {
  @ViewChild('agGrid')
  public agGrid: any;

  public dataGridOptions: GridOptions = null;
  public selectedTrip: ITrackerTripsData;
  public showTable = false;
  public loadingData = false;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private trackerStore: Store<fromTracker.ITrackerDataState>,
    private trackerService: TrackerService,
    private navigationStore: Store<INavigationStationState>,
    private api: ApiCallService
    ) { }

  public ngOnInit(): void {

combineLatest([
  this.trackerService.selectedTripIO$,
  this.navigationStore.pipe(
    select(selectNavigationStationBasicInfo),
    filter((stationBasicInfo: IStationBasicInfo): boolean => !!stationBasicInfo.originalName)
  )])
    .pipe(takeUntil(this.destroy$))
    .subscribe(([tripData, stationBasicInfo]) => {
      if (tripData) {
        const stationName = stationBasicInfo.originalName;
        this.selectedTrip = tripData;
        this.fetchTripData(tripData, stationName);
      }
    });
  }

  public selectionChanged(event: AgGridEvent): void {
    const selectedRows = event.api.getSelectedNodes();
    const selectedData = selectedRows.map(node => {
      const time = node.data.time;
      const position: ILngLat = {
        lng: node.data.long,
        lat: node.data.lat
      };

      return <ITrackerPoint>{ time, position };
    });
    this.trackerService.setSelectedTripPoints(selectedData);
  }

  public fetchTripData(trip: ITrackerTripsData, stationName: string): void {
    this.showTable = false;
    this.loadingData = true;

    const io = this.api.getTrackerIO({
      stationId: stationName,
      from: dateToUtcUnixTimestamp(new Date(trip.tripStart)),
      to: dateToUtcUnixTimestamp(new Date(trip.tripEnd))
    });
    io.subscribe(data => {
      this.loadingData = false;
      this.setDataGridOptions(data);
    });
  }

  public setDataGridOptions(data: ITrackerData[]): void {
    this.dataGridOptions = null;
    const rowData: Array<any> = [];
    const columnDefs: Array<ColDef> = [{
      headerName: 'Date/Time',
      field: 'time',
      pinned: true,
      sort: 'desc',
      suppressMenu: true,
      suppressSorting: false
    }, {
      headerName: 'Longitude',
      field: 'long',
      pinned: false,
      suppressMenu: true,
      suppressSorting: false
    }, {
      headerName: 'Latitude',
      field: 'lat',
      pinned: false,
      suppressMenu: true,
      suppressSorting: false
    }];
    const ioInHeaderArray: Array<any> = [];

    // loop through all points and get construct header & rows
    for (let i = 0; i < data.length; i++) {
      const row: Array<any> = [];

      if (data[i].IO) {
        for (const ioField of Object.keys(data[i].IO)) {
          const trackerPointData = data[i].IO[ioField];

          if (!ioInHeaderArray.includes(ioField)) {
            ioInHeaderArray.push(ioField);

            columnDefs.push({
              headerName: trackerPointData.name,
              headerTooltip: trackerPointData.name,
              field: '' + ioField,
              suppressSorting: true
            });
          }

          row[ioField] = trackerPointData.value + ' ' + trackerPointData.unit;
        }
      }
      row['time'] = data[i].dt;
      row['long'] = data[i].position.coordinates[0];
      row['lat'] = data[i].position.coordinates[1];
      rowData.unshift(row);

      this.dataGridOptions = {
        columnDefs: columnDefs,
        rowData: rowData,
        enableColResize: true,
        enableSorting: true,
        rowHeight: 30,
        headerHeight: 28,
        rowSelection: 'multiple'
      };

      this.showTable = true;

      if (this.agGrid) {
        this.agGrid.api.setColumnDefs(columnDefs);
        this.agGrid.api.setRowData(rowData);
      }
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
