import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-station-disease-help-modal',
  templateUrl: './station-disease-help-modal.component.html',
  styleUrls: ['./station-disease-help-modal.component.scss']
})
export class StationDiseaseHelpModalComponent implements OnInit {

  @Input()
  public help: string;

  constructor() { }

  public ngOnInit(): void { }

}
