import { Injectable } from '@angular/core';
import {IModal, IModalList} from '../models/modal.interface';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modals  : IModalList = {};

  constructor() { }

  public addModal(id: string, modal: IModal): void {
    this.modals[id] = modal;
  }

  public deleteModal(id: string): void {
    if (this.modals[id]) {
      delete this.modals[id];
    }
  }

  public openModal(id: string): void {
    if (this.modals[id]) {
      this.modals[id].openModal();
    }
  }

  public closeModal(id: string): void {
    if (this.modals[id]) {
      this.modals[id].closeModal();
    }
  }
}
