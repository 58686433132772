import * as fromNavigationCropzone from './navigation-cropzone';
import * as fromAccount from './account';
import * as fromStations from './stations';
import * as fromCropZones from './cropzones';
import * as fromDiseases from './diseases';
import * as fromNotifications from './notifications';
import * as fromSelectedStation from './selectedStation';
import * as fromSelectedCropZone from './selectedCropZone';
import * as fromNavigationStation from './navigation-station';
import * as fromNotify from './notify';
import * as fromSystem from './system';
import * as fromFarms from './farms';
import * as fromFields from './fields';
import * as fromProductLicense from './licenses';
import * as fromAccumulatorTool from './accumulatorTool';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IStation} from '../models/stations';
import {ISelectedSearchWidgetItem} from '../models/selectedSearchWidgetItem';
import {ICropZone, ICropZoneMap, IIrrigationEvents} from '../models/cropzones';
import { Polygon } from 'geojson';
import {INavigationAccess} from '../models/navigation-station';
import { IAccumulatorThresholdState } from '../models/accumulatorTool';
import { IFlagSettings } from '../models/account';

export interface IState {
  userData: fromAccount.IAccount;
  userCropZones: fromCropZones.ICropZones;
  userFarms: fromFarms.IFarms;
  userFields: fromFields.IFields;
  userStations: fromStations.IStations;
  userDiseases: fromDiseases.IDiseases;
  userSelectedStation: fromSelectedStation.ISelectedStationState;
  userSelectedCropZone: fromSelectedCropZone.ISelectedCropZoneState;
  userNavigationStation: fromNavigationStation.INavigationStationState;
  userNavigationCropzone: fromNavigationCropzone.INavigationCropzoneState;
  userNotifications: fromNotifications.INotifications;
  userAccumulatorTool: IAccumulatorThresholdState;
  notify: fromNotify.INotifyState;
  system: fromSystem.ISystemState;
  license: fromProductLicense.IProductLicenseState;
}

export const reducers = {
  userData: fromAccount.reducer,
  userCropZones: fromCropZones.reducer,
  userFarms: fromFarms.reducer,
  userFields: fromFields.reducer,
  userStations: fromStations.reducer,
  userDiseases: fromDiseases.reducer,
  userSelectedStation: fromSelectedStation.reducer,
  userSelectedCropZone: fromSelectedCropZone.reducer,
  userNavigationStation: fromNavigationStation.reducer,
  userNavigationCropzone: fromNavigationCropzone.reducer,
  userNotifications: fromNotifications.reducer,
  userAccumulatorTool: fromAccumulatorTool.reducer,
  notify: fromNotify.reducer,
  system: fromSystem.reducer,
  license: fromProductLicense.reducer
};


export const personalAccount = createFeatureSelector<IState>('account');


export const selectNotify = createSelector(
  personalAccount,
  (account: IState) => account.notify
);

export const selectUserData = createSelector(
  personalAccount,
  (account: IState) => account.userData
);

export const selectStations = createSelector(
  personalAccount,
  (account: IState): Array<IStation> => account.userStations.stations
);

export const selectCropzones = createSelector(
  personalAccount,
  (account: IState): Array<ICropZone> => account.userCropZones.cropzones
);

export const selectCropzonesAsMap = createSelector(
  personalAccount,
  (account: IState): ICropZoneMap => account.userCropZones.cropzones.reduce(
    (result, current) => ({...result, [current.id]: current}), {}
  )
);

export const selectYpmConstants = createSelector(
  personalAccount,
  (account: IState): any => account.userCropZones.ypmConstants
);

export const selectFarms = createSelector(
  personalAccount,
  (account: IState) => account.userFarms.farms
);

export const selectFields = createSelector(
  personalAccount,
  (account: IState) => account.userFields.fields
);

export const selectDiseases = createSelector(
  personalAccount,
  (account: IState) => account.userDiseases.diseases
);

export const selectSelectedStation = createSelector(
  personalAccount,
  (account: IState): ISelectedSearchWidgetItem => account.userSelectedStation.selectedStation
);

export const selectSelectedCropZone = createSelector(
  personalAccount,
  (account: IState): ICropZone => account.userSelectedCropZone.selectedCropZone
);

export const selectSelectedCropzoneBoundary = createSelector(
  personalAccount,
  (account: IState): Polygon => account.userSelectedCropZone.selectedCropZone.boundary
);

export const selectUserName = createSelector(
  personalAccount,
  (account: IState) => account.userData.username
);

export const selectFirstName = createSelector(
  personalAccount,
  (account: IState) => account.userData.info ? account.userData.info.name : ''
);

export const selectLastName = createSelector(
  personalAccount,
  (account: IState) => account.userData.info ? account.userData.info.lastname : ''
);

export const selectEmail = createSelector(
  personalAccount,
  (account: IState) => account.userData.info ? account.userData.info.email : ''
);

export const selectTerms = createSelector (
  personalAccount,
  (account: IState) => account.userData.terms_accepted
);

export const selectAccount = createSelector (
  personalAccount,
  (account: IState) => account.userData
);

export const selectAutoSync = createSelector(
  personalAccount,
  (account: IState) => account.userData.sync_disabled
);

export const selectBasicInfo = createSelector (
  personalAccount,
  (account: IState) => account.userData.info
);

export const selectSettings = createSelector (
  personalAccount,
  (account: IState) => account.userData ? account.userData.settings : null
);

export const selectLanguage = createSelector (
  personalAccount,
  (account: IState) => account.userData.settings ? account.userData.settings.language : null
);

export const selectAddress = createSelector (
  personalAccount,
  (account: IState) => account.userData.address
);

export const selectCompany = createSelector (
  personalAccount,
  (account: IState) => account.userData.company
);

export const selectNewsLetter = createSelector (
  personalAccount,
  (account: IState) => account.userData.newsletter
);

export const selectNavigationAccess = createSelector (
  personalAccount,
  (account: IState): INavigationAccess => account.userNavigationStation.navigationAccess
);
/*
export const selectNavigationCropzoneAcces = createSelector (
  personalAccount,
  (account: IState) => account.userNavigationCropzone.navigationAccess
);
*/
export const selectNotFound = createSelector (
  personalAccount,
  (account: IState) => account.userNavigationStation.notFound
);

export const selectNavigationStation = createSelector (
  personalAccount,
  (account: IState) => account.userNavigationStation.station
);

export const selectNavigationCropzone = createSelector (
  personalAccount,
  (account: IState) => account.userNavigationCropzone.cropzone
);
export const selectNavigationStationBasicInfo = createSelector (
  personalAccount,
  (account: IState) => account.userNavigationStation.stationBasicInfo
);

export const selectCustomizationsFromAccount = createSelector(
  personalAccount,
  (account: IState) => account.userData.customizations
);

export const selectApiAccess = createSelector(
  personalAccount,
  (account: IState) => account.userData.api_access
);

export const selectSystemSensors = createSelector(
  personalAccount,
  (account: IState) => account.system.sensors
);

export const selectCalibrationSettings = createSelector(
  personalAccount,
  (account: IState) => account.system.calibrationSettings
);

export const selectVirtualStationDetails = createSelector(
  personalAccount,
  (account: IState) => account.userStations.virtualStation
);

export const selectActiveCropzoneRoute = createSelector(
  personalAccount,
  (account: IState) => account.userCropZones.activeCropzoneRoute
);

export const selectShowFinishedCropzones = createSelector(
  personalAccount,
  (account: IState) => account.userData.show_finished_cropzones
);

export const selectNotifications = createSelector(
  personalAccount,
  (account: IState) => account.userNotifications.notifications
);

export const selectSelectedNotification = createSelector(
  personalAccount,
  (account: IState) => account.userNotifications.selectedNotification
);

export const selectAvailableLicenses = createSelector(
  personalAccount,
  (account: IState) => account.license.activeLicenses
);

export const selectExecutingAddCropZone = createSelector(
  personalAccount,
  (account: IState): boolean => account.userCropZones.isExecuting
);

export const selectDeletedAccount = createSelector(
  personalAccount,
  (account: IState): boolean => account.userData.deletedAccount
);

export const selectClosedDashboardTexts = createSelector(
  personalAccount,
  (account: IState): Array<number> => account.userData.closedDashboardTexts
);

export const selectClosedModulesTexts = createSelector(
  personalAccount,
  (account: IState): Array<string> => account.userData.closedModulesTexts
);

export const selectUserFlags = createSelector(
  personalAccount,
  (account: IState): IFlagSettings => account.userData.flags
);

export const selectAccumulatorToolThresholds = createSelector(
  personalAccount,
  (account: IState) => account.userAccumulatorTool.thresholds
);
