import {IForecastWarningMessage, IGetWeatherForecastRequest} from '../models/models';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IWeatherForecastState} from '../models/models';

export const enum WeatherForecastActionTypes {
  GET_WEATHER_FORECAST = '[WeatherForecast] GET_WEATHER_FORECAST',
  SET_WEATHER_FORECAST = '[WeatherForecast] SET_WEATHER_FORECAST',
  SET_WEATHER_FORECAST_LOADING = '[WeatherForecast] SET_WEATHER_FORECAST_LOADING',
  SET_WEATHER_FORECAST_ERROR = '[WeatherForecast] SET_WEATHER_FORECAST_ERROR',
  GET_STATION_WEATHER_FORECAST_LICENSES = '[WeatherForecast] GET_STATION_WEATHER_FORECAST_LICENSES',
  SET_STATION_WEATHER_FORECAST_LICENSES = '[WeatherForecast] SET_STATION_WEATHER_FORECAST_LICENSES',
  SET_WF_WARNING_MESSAGE = '[WeatherForecast] SET_WF_WARNING_MESSAGE'
}

export function setForecastWarningMessage(warning: IForecastWarningMessage): IActionWithPayload {
  return { type: WeatherForecastActionTypes.SET_WF_WARNING_MESSAGE, payload: warning };
}

export function getWeatherForecast(data: IGetWeatherForecastRequest): IActionWithPayload {
  return {type: WeatherForecastActionTypes.GET_WEATHER_FORECAST, payload: data};
}

export function setWeatherForecast(data: IWeatherForecastState): IActionWithPayload {
  return {type: WeatherForecastActionTypes.SET_WEATHER_FORECAST, payload: data};
}

export function setWeatherForecastLoading(status: boolean): IActionWithPayload {
  return {type: WeatherForecastActionTypes.SET_WEATHER_FORECAST_LOADING, payload: status};
}

export function setWeatherForecastError(status: boolean): IActionWithPayload {
  return {type: WeatherForecastActionTypes.SET_WEATHER_FORECAST_ERROR, payload: status};
}

export function getStationWeatherForecastLicenses(stationId: string): IActionWithPayload {
  return {type: WeatherForecastActionTypes.GET_STATION_WEATHER_FORECAST_LICENSES, payload: stationId};
}

export function setStationWeatherForecastLicenses(licenses: any): IActionWithPayload {
  return {type: WeatherForecastActionTypes.SET_STATION_WEATHER_FORECAST_LICENSES, payload: licenses};
}
