import { concatMap, exhaustMap } from 'rxjs/operators';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ApiCallService } from '../../../services/api/api-call.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { setSoilMoistureStations, SoilMoistureStationsActionTypes } from '../actions/soilMoistureStations';
import { SensorsActionTypes, setSensors, updateSensors } from '../actions/sensors';
import { from } from 'rxjs';
import { setNotify } from '../../../core/actions/notify';

@Injectable()
export class SoilMoistureConfigEffectsEffects {

  constructor(
    private actions$: Actions,
    private api: ApiCallService
  ) {

  }

  @Effect()
  public getSoilMoistureStations$ = this.actions$.pipe(
    ofType(SoilMoistureStationsActionTypes.GET_SOIL_MOISTURE_STATIONS),
    mergeMap((action: IActionWithPayload) => this.api.getDataSource(action.payload)),
    map((soilMoistureStations) => setSoilMoistureStations(soilMoistureStations))
  );

  @Effect()
  public getSensors$ = this.actions$.pipe(
    ofType(SensorsActionTypes.GET_SENSORS),
    concatMap((action: IActionWithPayload) => this.api.getStationSensors(action.payload.stationId).pipe(
      concatMap((res) => {
        if (res) {
          return from([
            setSensors(res, action.payload.stationId, action.payload.node)]);
        }
      }),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public updateCropzone$ = this.actions$.pipe(
    ofType(SoilMoistureStationsActionTypes.UPDATE_CROPZONE),
    exhaustMap((action: IActionWithPayload) =>
      this.api.updateCropzone(action.payload).pipe(
        mergeMap(response => [
          setNotify('Soil moisture settings successfully saved')
        ]),
        catchError(() => from([
          setNotify('Can\'t define soil moisture settings')
        ]))
      )
    )
  );


  @Effect()
  public updateCropzoneAndSensors$ = this.actions$.pipe(
    ofType(SoilMoistureStationsActionTypes.UPDATE_CROPZONE_AND_SENSORS),
    exhaustMap((action: IActionWithPayload) =>
      this.api.updateCropzone(action.payload.cropzone).pipe(
        concatMap(response => [
          setNotify('Soil moisture settings successfully saved'),
          updateSensors({
            array: action.payload.array,
            smStationId: action.payload.smStationId,
            node: action.payload.node
          })
        ]),
        catchError(() => from([
          setNotify('Can\'t define soil moisture settings')
        ]))
      )
    )
  );
}
