import {Pipe, PipeTransform} from '@angular/core';
import {IStation} from '../../../../core/models/stations';
import {findIscout} from '../../utils/findStations';

@Pipe({
  name: 'iscout'
})
export class IscoutPipe implements PipeTransform {

  public transform(stations: Array<IStation>): Array<IStation> {
    return Array.isArray(stations) ? findIscout(stations) : [];
  }

}
