import {AccountActionsTypes} from '../actions/account';
import {IActionWithPayload} from '../models/actionWithPayload';
import {IAddress, ICompany, IInfo, INewsLetter, ISettings, IApiAccess, IFlagSettings } from '../models/account';
import {IColumn} from '../../modules/dashboard/models/dashboard.models';

export interface IAccount {
  address: IAddress;
  company: ICompany;
  create_time: string;
  created_by: string;
  custom_name: string;
  customizations?: {
    dashboard: Array<Array<IColumn>>;
  };
  info: IInfo;
  last_access: string;
  newsletter: INewsLetter;
  profile?: any;
  settings: ISettings;
  station_id?: string;
  station_rights?: string;
  sync_disabled: boolean;
  terms_accepted?: boolean;
  username: string;
  api_access: IApiAccess;
  show_finished_cropzones: boolean;
  deletedAccount?: boolean;
  closedDashboardTexts: Array<number>;
  closedModulesTexts: Array<string>;
  flags?: IFlagSettings;
}

const initialState: IAccount = {
  address: null,
  company: null,
  create_time: null,
  created_by: null,
  custom_name: null,
  customizations: {
    dashboard: []
  },
  info: null,
  last_access: null,
  newsletter: null,
  settings: null,
  station_id: null,
  station_rights: null,
  sync_disabled: null,
  terms_accepted: null,
  username: null,
  api_access: {
    hmac: {
      public_key: '',
      private_key: ''
    }
  },
  show_finished_cropzones: false,
  closedDashboardTexts: [],
  closedModulesTexts: []
};

export function reducer(state: IAccount = initialState, action: IActionWithPayload ): IAccount {
  switch (action.type) {

    case AccountActionsTypes.SET_USER: {
      return {...action.payload, show_finished_cropzones: state.show_finished_cropzones};
    }
    case AccountActionsTypes.TOGGLE_AUTO_SYNC: {
      return {
        ...state,
        sync_disabled: !state.sync_disabled
      };
    }
    case AccountActionsTypes.UPDATE_INFO: {
      return {
        ...state,
        info: action.payload
      };
    }
    case AccountActionsTypes.UPDATE_ADDRESS : {
      return {
        ...state,
        address: action.payload
      };
    }
    case AccountActionsTypes.UPDATE_SETTINGS : {
      return {
        ...state,
        settings: action.payload
      };
    }
    case AccountActionsTypes.UPDATE_FLAG_SETTINGS : {
      return {
        ...state,
        flags: action.payload
      };
    }
    case AccountActionsTypes.UPDATE_COMPANY : {
      return {
        ...state,
        company: action.payload
      };
    }
    case AccountActionsTypes.UPDATE_NOTIFICATIONS : {
      return {
        ...state,
        newsletter: action.payload
      };
    }
    case AccountActionsTypes.UPDATE_DASHBOARD : {
      return {
        ...state,
        customizations: {
          ...state.customizations,
          dashboard: action.payload
        }
      };
    }
    case AccountActionsTypes.UPDATE_SHOW_FINISHED_CROPZONES : {
      return {
        ...state,
        show_finished_cropzones: action.payload
      };
    }
    case AccountActionsTypes.SET_DELETED_ACCOUNT : {
      return {
        ...state,
        deletedAccount: action.payload
      };
    }
    case AccountActionsTypes.CLOSE_DASHBOARD_TEXT : {
      const index = action.payload;
      const newArray = [...state.closedDashboardTexts];    // Copying state array
      newArray.push(index);                                // add a new index

      return {
        ...state,
        closedDashboardTexts: newArray
      };
    }
    case AccountActionsTypes.SET_USERS_CLOSED_TEXTS : {
      return {
        ...state,
        closedDashboardTexts: action.payload
      };
    }
    case AccountActionsTypes.SET_USERS_CLOSED_MODULES_TEXTS : {
      return {
        ...state,
        closedModulesTexts: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
