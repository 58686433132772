export const WarningsSensorsGroups: Map<number, string> = new Map(<any>[
  [1,  /** Air temperature SG_AIRTEMP */],
  [2, /** Relative humidity SG_RELH */],
  [3, /** Soil temperature SG_SOILT */],
  [4, /** Solar radiation SG_SOLAR **/],
  [5, /** Precipitation SG_PRECP */],
  [6, /** Wind speed SG_WINDSP */],
  [7, /** Battery voltage SG_BATT */],
  [8, /** Wet bulb temperature SG_WETTEMP */],
  [9, /** Dry bulb temperature SG_DRYTEMP */],
  [10, /** Air pressure SG_AIRPRESS */],
  [11, /** Soil moisture SG_SOILMOIST */],
  [12, /** Leaf wetness SG_LEAFWET */],
  [14, /** Leaf temperature SG_LEAFTEMP */],
  [15, /** Water temperature SG_WATERTEMP */],
  [17, /** Dew point SG_DEWP */],
  [25, /** Water content SG_WATERCONT */],
  [27, /** Water pressure SG_WATER_PRES */],
  [28, /** Water depth SG_WATER_DEPTH */],
  [39, /** Snow depth **/],
  [42, /** Water potential in kPa SG_WATER_PT */],
  [66, /** Water speed max in m/s */],
  [68, /** DeltaT temperature */],
]);
