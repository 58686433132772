<div class="save-cam">
  <hr class="save-cam__hr">
  <div class="save-cam__content">
    <div>
      <app-custom-button [color]="'red'"
                         class="save-cam__save"
                         [content]="'Save configuration'"
                         (click)="saveCam()"></app-custom-button>
    </div>
    <div class="alert alert-warning" *ngIf="isInvalid">
      {{ 'Configuration was NOT saved - cameras need at least one measuring point to work properly (red square).' | translate }}
    </div>
  </div>
</div>
