import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-licenses-header',
  templateUrl: './user-licenses-header.component.html',
  styleUrls: ['./user-licenses-header.component.scss']
})
export class UserLicensesHeaderComponent {
  @Input()
  public title: string;
  @Input()
  public subtitle: string = '';
}
