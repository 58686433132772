import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-detailed-image-clear-measurements-modal',
  templateUrl: './detailed-image-clear-measurements-modal.component.html',
  styleUrls: ['./detailed-image-clear-measurements-modal.component.scss']
})
export class DetailedImageClearMeasurementsModalComponent {
  @Output()
  private cancelEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  private confirmEmitter: EventEmitter<void> = new EventEmitter<void>();

  public handleCancel(): void {
    this.cancelEmitter.emit();
  }

  public handleConfirm(): void {
    this.confirmEmitter.emit();
  }
}
