import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IOptions } from '../interfaces';
import { deepClone } from '../utils/deepClone';

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormSelectComponent),
    multi: true
  }]
})
export class FormSelectComponent implements ControlValueAccessor, OnChanges {
  @Input()
  public icon: string;
  @Input()
  public type: string = 'material';
  @Input()
  public options: IOptions[];
  @Input()
  public size: string = 'normal';
  @Input()
  public width: string = 'normal';
  @Input()
  private isFind: boolean = false;
  @Input()
  public isDisabled: boolean = false;
  @Input()
  public searchable: boolean = true;
  @Input()
  public placeholder: string = 'Search..';

  public filteredOptions: IOptions[] = [];
  public searchTerm: string = '';

  private _value: string = '';
  private propagateChange: any = () => {
    return;
  }

  public get value(): string {
    return this._value;
  }

  public set value(value: string) {
    this._value = value;
  }

  public onChange(): void {
    this.propagateChange(this.value);
  }

  public writeValue(value: any): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
    if (this.isFind) {
      this.findValue();
    }
  }

  public registerOnTouched(fn: any): void {
    return;
  }

  public setDisabledState(isDisabled: boolean): void {
    return;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.filteredOptions = deepClone(this.options);
    }
    if (this.isFind) {
      this.findValue();
    }
  }

  public filterOptions(): void {
    if (this.searchTerm === '') {
      this.filteredOptions = deepClone(this.options);
    } else {
      this.filteredOptions = deepClone(this.options)
      .filter((option: IOptions) => option.content.toLowerCase().includes(this.searchTerm.toLowerCase()));
    }
  }

  private findValue(): void {
    if (this.value !== undefined) {
      const selectedOption: IOptions = this.options.find((o) => o.value.toString() === this.value.toString());
      if (!selectedOption) {
      this.propagateChange(this.options[0].value);
    } else {
      this.value = selectedOption.value;
      this.propagateChange(selectedOption.value);
    }
  }
  }

  public resetDropdown(): void {
    this.searchTerm = '';
    this.filteredOptions = deepClone(this.options);
  }

  public getWidth(): number {
    let largestWidth: number = 0;
    deepClone(this.options).forEach((option: IOptions) => {
      const text = option.content;
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = '13px roboto';
      const width = context.measureText(text).width;

      largestWidth = width > largestWidth ? width : largestWidth;
    });

    return largestWidth + 22.01;
  }
}
