import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-lazy-dropdown',
  templateUrl: './custom-lazy-dropdown.component.html',
  styleUrls: ['./custom-lazy-dropdown.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomLazyDropdownComponent),
    multi: true
  }]
})

export class CustomLazyDropdownComponent implements ControlValueAccessor, OnChanges {
  @Input()
  public placeholder                : string = '';
  @Input()
  public color                      : string = 'blue';
  @Input()
  public width                      : string = 'auto';
  @Input()
  public items                      : Array<{}> = [];
  @Input()
  public totalItems                 : number = 0;
  @Input()
  public itemIdProperty             : string = 'id';
  @Input()
  public itemTextProperty           : string = 'value';
  @Input()
  public staticText                 : string = '';
  @Input()
  public currentId                  : string = '';
  @Input()
  private isSetDefault              : boolean = true;
  @Input()
  public isBusy                    : boolean = false;
  public currentText                : string = '';
  public currentPage                : number = 1;
  public isOpen                     : boolean = false;
  public currentItem                : any = null;
  public filteredItems             : Array<{}> = [];
  public filterString              : string = '';

  @Output()
  public filterChanged              : EventEmitter<{}> = new EventEmitter<string>();
  @Output()
  public selectionChanged           : EventEmitter<{}> = new EventEmitter<{}>();
  @Output()
  public loadMore                   : EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public closed                     : EventEmitter<boolean> = new EventEmitter<boolean>();

  private propagateChange           : any = () => { return; };


  public toggleOpen(): void {
    this.isOpen = !this.isOpen;
    this.closed.emit(!this.isOpen);
  }

  public close(): void {
    this.isOpen = false;
    this.closed.emit(!this.isOpen);
  }

  public inputChanged(filterString: string): void {
    this.filterString = filterString.trim();
    if (this.filterString) {
      this.filterChanged.emit(this.filterString);
    }
    this.filteredItems = this.items.filter(this.filter(this.filterString));
  }

  public onChange(item: {}): void {
    if (this.currentId !== item[this.itemIdProperty]) {
      this.currentItem = item;
      this.selectionChanged.emit(item);
      this.close();
    }
    this.currentId = item[this.itemIdProperty];
    this.currentText = item[this.itemTextProperty];
    this.propagateChange(this.currentId);
  }

  public writeValue(value: string): void {
    this.currentId = value;
    this.setContent();
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
    this.setContent();
  }

  public registerOnTouched(fn: any): void {
    return;
  }

  public setDisabledState(isDisabled: boolean): void {
    return;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.setContent();
  }

  private filter(filterString: string): ({}) => boolean {
    return (item) => {
      return !filterString || (<string>item[this.itemTextProperty].toLowerCase()).indexOf(filterString.toLowerCase()) >= 0;
    };
  }

  protected setContent(): void {

    this.filteredItems = this.items.filter(this.filter(this.filterString));

    if (!this.items || !this.items.length) {
      return;
    }
    this.currentItem = this.items.find((o) => o[this.itemIdProperty] === this.currentId) || this.currentItem;
    if (this.currentItem) {
      this.currentText = this.currentItem[this.itemTextProperty];
    } else if (this.propagateChange && this.isSetDefault) {
      this.currentItem = this.items[0];
      this.currentId = this.items[0][this.itemIdProperty];
      this.currentText = this.items[0][this.itemTextProperty];
      this.propagateChange(this.currentId);
      this.selectionChanged.emit(this.currentItem);
    }
  }
}
