import { IOptions } from "../../../app/shared/interfaces";

export const languageOptions: Array<IOptions> = [
    {
      value: 'ar',
      content: 'الْعَرَبيّة'
    },
    {
      value: 'sq',
      content: 'Shqip'
    },
    {
      value: 'al',
      content: 'Afrikaans'
    },
    {
      value: 'bg',
      content: 'български'
    },
    {
      value: 'br',
      content: 'Português do Brasil'
    },
    {
      value: 'zh',
      content: '中文'
    },
    {
      value: 'cs',
      content: 'Česky'
    },
    {
      value: 'de',
      content: 'Deutsch'
    },
    {
      value: 'hi',
      content: 'Hindi'
    },
    {
      value: 'mr',
      content: 'Marathi'
    },
    {
      value: 'nl',
      content: 'Dutch'
    },
    {
      value: 'en',
      content: 'English'
    },
    {
      value: 'es',
      content: 'Español'
    },
    {
      value: 'fr',
      content: 'Français'
    },
    {
      value: 'gr',
      content: 'ελληνικά'
    },
    {
      value: 'he',
      content: 'עברית'
    },
    {
      value: 'hr',
      content: 'Hrvatski'
    },
    {
      value: 'it',
      content: 'Italiano'
    },
    {
      value: 'lv',
      content: 'Latviešu'
    },
    {
      value: 'hu',
      content: 'Magyar'
    },
    {
      value: 'pl',
      content: 'Polskie'
    },
    {
      value: 'pt',
      content: 'Português'
    },
    {
      value: 'ro',
      content: 'Română'
    },
    {
      value: 'ru',
      content: 'Русский'
    },
    {
      value: 'sk',
      content: 'Slovenčina'
    },
    {
      value: 'si',
      content: 'Slovenščina'
    },
    {
      value: 'sr',
      content: 'Srpski'
    },
    {
      value: 'vn',
      content: 'tiếng Việt'
    },
    {
      value: 'uk',
      content: 'Українська'
    },
    {
      value: 'tr',
      content: 'Türk'
    }
  ];
