import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { deepClone } from '../../../../shared/utils/deepClone';
import { saveBaseName, setBaseName } from '../../actions/baseName';
import { selectBaseName } from '../../reducers';
import * as fromBaseName from '../../reducers/baseName';
import { IBaseNameState } from '../../reducers/baseName';

@Component({
  selector: 'app-station-config-base-name',
  templateUrl: './station-config-base-name.component.html'
})
export class StationConfigBaseNameComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public station                      : IStation;

  public baseNameState                : IBaseNameState;

  private destroy$                    : Subject<boolean> = new Subject<boolean>();

  constructor(private api: ApiCallService,
              private store: Store<fromBaseName.IBaseNameState>) {}

  public ngOnInit(): void {
    this.store.pipe(
      takeUntil(this.destroy$),
      select(selectBaseName)
    ).subscribe((baseName: IBaseNameState) => {
      this.baseNameState = baseName;
      if (!baseName) {
        this.baseNameState = {
          name: this.station.name.custom || ''
        };
        this.store.dispatch(setBaseName(this.station.name.custom || ''));
      }
    });
  }

  public changeBaseName(name: string): void {
    this.store.dispatch(setBaseName(name));
  }

  public saveBaseName(name: string): void {
    const s: IStation = deepClone(this.station);
    s.name.custom = name;
    this.store.dispatch(saveBaseName(s));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.station) {
      this.store.dispatch(setBaseName(this.station.name.custom || ''));
    }
  }

}
