<app-detailed-image [picture]="selectedPicture$ | async"
                    [measurementsAreActive]="measurementsAreActive$ | async"
                    [currentDate]="getCurrentDateForImage((selectedPicture$ | async).time)"
                    [hasWritePermission]="hasWritePermission"
                    [filteredRectangles]="filteredRectangles"
                    [selectedRectangleLabel]="selectedRectangleLabel$ | async"
                    [multipleSelection]="true"
                    [rectangleOperationListener]="iscoutRectangleOperation$"
                    (rectangleCreatedEmitter)="handleAddRectangle($event)"
                    (rectangleUpdatedEmitter)="handleUpdateRectangle($event)"
                    (rectangleDeletedEmitter)="handleRemoveRectangle($event)"
                    (rectangleSelectionEmitter)="handleShowRectangleOptions($event)"
                    (multipleRectangleDeletedEmitter)="handleRemoveMultipleRectangles($event)"
                    (multipleRectangleSelectionEmitter)="handleShowMultipleRectanglesOptions($event)">
  <ng-container slot="header">
    <app-iscout-header [title]="(selectedPicture$ | async).time | date: 'medium'"
                       [subtitle]="title"
                       [enableHelpButton]="true"
                       (openModal)="handleOpenHelpModal()">
    </app-iscout-header>
  </ng-container>

  <ng-container slot="toolbar">
    <app-iscout-image-settings-toolbar [selectedPicture]="selectedPicture$ | async"
                                       [hasWritePermission]="hasWritePermission"
                                       [toolbarSettings$]="toolbarSettings$"
                                       [availablePestsOptions$]="availablePests$"
                                       [totalCount]="totalRectanglesCount"
                                       [newCount]="newRectanglesCount"
                                       [pestToggles$]="pestToggles$"
                                       (toggleEmitter)="handleUpdatePestToggler($event)"
                                       (selectedPestEmitter)="handleChangeSelectedPest($event)"
                                       (unselectPictureEmitter)="handleUnselectPicture()">
    </app-iscout-image-settings-toolbar>

    <div class="disclaimer-padding iscout-image-settings__custom-toolbar">
      <div class="row">
        <section *ngIf="!userFlags?.iscout?.saveInsectFlag" class="col col-md-6">
          <div class="alert alert-info iscout-image-settings__custom-alert disclaimer-text xContainer">
            <section>
              <b>{{ 'Save Insect:' | translate}}</b>
              <p class="m-0">
                {{ '1. Select a species name on the drop-down menu' | translate }}
              </p>
              <p class="m-0">
                {{ '2. Use a mouse to draw rectangle' | translate }}
              </p>
              <p class="m-0">
                {{ '3. Save' | translate }}
              </p>
            </section>
            <div class="close-description" (click)="updateInfoFlag('save')">
              <i class="zmdi zmdi-close closeButton"></i>
            </div>
          </div>
        </section>
        <section *ngIf="!userFlags?.iscout?.updateInsectFlag" class="col col-md-6">
          <div class="alert alert-info iscout-image-settings__custom-alert disclaimer-text xContainer">
            <section>
              <b>{{ 'Rename Insect:' | translate}}</b>
              <p class="m-0">
                {{ '1. Click on existing rectangle to activate it' | translate }}
              </p>
              <p class="m-0">
                {{ '2. Right click, the dropdown menu appears' | translate }}
              </p>
              <p class="m-0">
                {{ '3. Select the correct species & Save.' | translate }}
              </p>
            </section>
            <div class="close-description" (click)="updateInfoFlag('update')">
              <i class="zmdi zmdi-close closeButton"></i>
            </div>
          </div>
        </section>
      </div>
      
    </div>
  </ng-container>

  <ng-container slot="footer">
    <div class="panel panel-default iscout-image-settings__custom-panel">
      <div class="panel-footer">
        {{ 'Press Ctrl and scroll on the image to zoom in and out' | translate }}
      </div>
    </div>
  </ng-container>
</app-detailed-image>

<app-iscout-context-menu
  [isOpen]="contextMenuOpen"
  [position]="contextMenuPosition"
  [pestMap]="contextMenuPestList"
  (menuOptionClickedEmitter)="handleRectangleOptionsChange($event)"
  (closeMenuEmitter)="contextMenuOpen = false"
>
</app-iscout-context-menu>

<app-info-modal [modalId]="helpModalId" [header]="'iScout Detection'">
  <p>
    {{
    'System automatically detects potential insects and marks them with rectangles. The detected order ' +
    'names of those insects are on the top of the picture. When clicking on those you can activate or ' +
    'deactivate those insects (activated once are labelled green with counts). Further on, if you want ' +
    'to specify the target insect you can click on the rectangle and choose the species name with the right ' +
    'mouse ( a drop down list appear and you choose from that list). The name of the insect appear directly ' +
    'above the rectangle and on the list above the picture ( list of active detected insects). Those insects ' +
    '(based on automatic rectangles) will be tracked back (on previous photos) and forward (next incoming photos) ' +
    'as long as the insect is visible on that place on the photo. ' | translate
    }}
  </p>

  <h5>{{ 'Drawing new insects on the photo' | translate }}</h5>
  <p>
    {{
    'Further on you are able to mark an insect by drawing a rectangle around and name it (click on ' +
    'the right mouse and name it from the list).  But this manual drawn rectangle only stay on the photo, ' +
    'where it was drawn.' | translate
    }}
  </p>

  <h5>{{ 'Removing insects from the photo' | translate }}</h5>
  <p>
    {{
    'If you want to remove the drawn rectangle, simply select it by clicking on it with your left ' +
    'mouse button then hit the delete key on your keyboard to remove the rectangle. The counts on the ' +
    'top of the picture will be automatically adapted to the rectangle (so when removing on it will decrease ' +
    'the number of the specific insect).' | translate
    }}
  </p>

  <h5>{{ 'Zooming into the picture to see details' | translate }}</h5>
  <p>{{ 'Press the button Crtl (Control) and zoom in and out with the mouse.' | translate }}</p>

  <br>
  <p><strong>{{ "Don't forget to SAVE once you are done." | translate }}</strong></p>
</app-info-modal>
