import {IFromTo} from '../../../shared/camera/models/camera';
import {IIscoutGlueBoard, IIscoutSeason} from '../../iscout/models/iscout.models';

export interface ICameraDataSettings {
  isLastDataMode: boolean;
  periodScope: string;
  fromTo: any;
  datepicker: any;
  datepickerStart?: any;
  datepickerEnd?: any;
}

export interface ICameraDataState {
  settings: ICameraDataSettings;
  activity: {
    isChartExportInProgress: boolean;
    isTableExportInProgress: boolean;
    isHelpActive: boolean;
    isChartActive: boolean;
    isTableActive: boolean;
  };
  currentDateString: string | IFromTo;
  iscoutReportData: Array<IIscoutReportData>;
  iscoutReportDataGlueBoard: IIscoutGlueBoard;
  iscoutReportDataSeason: IIscoutSeason;
  cropViewReportData: Array<ICropViewReportData>;
}

export enum ChartSerieType {
  COLUMN = 'column',
  SCATTER = 'scatter',
  LINE = 'line',
  AREA = 'areaspline'
}

export interface IChartSeries {
  name: string;
  type: string;
  data: Array<any>;
  color?: string;
  visible?: boolean;
}

export interface IChartSeriesMap {
  [name: string]: IChartSeries;
}

export interface IChartToggleVisibility {
  name: string;
  visible: boolean;
}

export interface IPestDetection {
  timestamp: number;
  label: string;
  total: number;
}

export interface IPestDetectionMap {
  [label: string]: IPestDetection;
}

export interface IFruitDetection {
  name: string;
  [rect_id: string]: {

  };
}

export interface IRequestCameraData {
  stationId: string;
  cameraId?: number;
  interval: IRequestCameraDataInterval;
}

export interface IRequestCameraDataInterval {
  type: string;
  value?: string;
  from?: number;
  to?: number;
}

export enum RequestCameraDataIntervalType {
  INTERVAL = 'interval',
  MONTH = 'month',
  WEEK = 'week',
}

export interface IIscoutReportData {
  detections: number;
  nm: string;
  label: string;
  detection_time: Date;
}

export interface ICropViewReportData {
  timestamp: Date;
  summary: ICropViewReportDataSummary;
  measurements: Array<ICropViewReportDataMeasurement>;
}

export interface ICropViewReportDataSummary {
  average: number;
  min: number;
  max: number;
}

export interface ICropViewReportDataMeasurement {
  rect_id: string;
  diameter: number;
  fsi: number;
}
