import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectStations } from '../../../../core/reducers';
import { IStations } from '../../../../core/reducers/stations';
import { DatesTranslationsService } from '../../../../core/services/dates-translations.service';
import { ICameraRefresh, IToolbarSettings } from '../../../../shared/camera/models/camera';
import { StationTypes } from '../../../../shared/constants';
import { IOptions } from '../../../../shared/interfaces';
import { getDateForCameraPicture } from '../../../../shared/utils/dateFormat';
import {
  setCropViewSettingsCurrentDateString,
  setCropViewSettingsToolbarSettings
} from '../../actions/crop-view-settings';
import { ICropViewSettingsState, ICropViewState } from '../../models/crop-view.models';
import {
  selectCropViewFirstDate,
  selectCropViewLastDate,
  selectCropViewSettingsCurrentDateString,
  selectCropViewSettingsForToolbar
} from '../../reducers';

@Component({
  selector: 'app-crop-view-image-gallery-toolbar',
  templateUrl: './crop-view-image-gallery-toolbar.component.html',
  styleUrls: ['./crop-view-image-gallery-toolbar.component.scss']
})
export class CropViewImageGalleryToolbarComponent implements OnInit, OnDestroy {
  public stationMinDate$: Observable<moment.Moment>;
  public stationMaxDate$: Observable<moment.Moment>;
  public toolbarSettings$: Observable<IToolbarSettings>;
  public currentDate$: Observable<string>;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public cropViewStations: Array<IOptions> = [];

  private translatedMonths: object;
  private translatedForWeekDate: object;

  constructor(
    private cropViewStore: Store<ICropViewState>,
    private cropViewSettingsStore: Store<ICropViewSettingsState>,
    private stationsStore: Store<IStations>,
    private datesTranslationsService: DatesTranslationsService
  ) { }

  public ngOnInit(): void {
    this.translatedMonths = this.datesTranslationsService.translateMonths();
    this.translatedForWeekDate = this.datesTranslationsService.translateForDate();

    this.initSettingsListener();
    this.initFirstAndLastDates();
    this.initStationListListener();
    this.initCurrentDateListener();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public refresh(refreshFilter: ICameraRefresh): void {
    this.cropViewSettingsStore.dispatch(setCropViewSettingsCurrentDateString(<string>refreshFilter.value));
  }

  public saveToolbarSettings(data: IToolbarSettings): void {
    this.cropViewSettingsStore.dispatch(setCropViewSettingsToolbarSettings(data));
  }

  public formatCurrentDate(date: string): string {
    const weekDate = getDateForCameraPicture(date);
    if (weekDate === date) {
      const d = moment(date).format('MMMM, YYYY');
      const year = this.datesTranslationsService.getYearMonths(d);
      const m = this.datesTranslationsService.getMonth(d);
      const month = this.translatedMonths[m];
      return `${month}, ${year}`;
    } else {
      const week = this.datesTranslationsService.getWeek(weekDate);
      const year = this.datesTranslationsService.getYearWeek(weekDate);
      return `${this.translatedForWeekDate['week']} ${week} ${this.translatedForWeekDate['in']} ${year}`;
    }
  }

  private initSettingsListener(): void {
    this.toolbarSettings$ = this.cropViewSettingsStore.pipe(
      take(1),
      select(selectCropViewSettingsForToolbar),
    );
  }

  private initFirstAndLastDates(): void {
    this.stationMinDate$ = this.cropViewSettingsStore.pipe(
      select(selectCropViewFirstDate)
    );
    this.stationMaxDate$ = this.cropViewSettingsStore.pipe(
      select(selectCropViewLastDate)
    );
  }

  private initStationListListener(): void {
    this.stationsStore.pipe(
      takeUntil(this.destroy$),
      select(selectStations),
      filter((stations: Array<IStation>): boolean => !!stations && stations.length > 0),
    ).subscribe((stations: Array<IStation>): void => {
      this.cropViewStations = stations.filter((station: IStation): boolean => {
        return [
          StationTypes.get('CropViewType1'),
          StationTypes.get('CropViewType2'),
          StationTypes.get('CropViewType3')
        ].includes(station.info.device_id);
      }).map((station: IStation): IOptions => {
        return {
          value: station.name.original,
          content: station.name.original
        };
      });
    });
  }

  private initCurrentDateListener(): void {
    this.currentDate$ = this.cropViewSettingsStore.pipe(
      takeUntil(this.destroy$),
      select(selectCropViewSettingsCurrentDateString),
      filter((currentDateString: string): boolean => !!currentDateString),
    );
  }
}
