import {IGetWeatherForecastRequest} from '../models/models';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';

export const enum WeatherForecastImageActionTypes {
  GET_WEATHER_FORECAST_IMAGE = '[WeatherForecastImage] GET_WEATHER_FORECAST_IMAGE',
  SET_WEATHER_FORECAST_IMAGE = '[WeatherForecastImage] SET_WEATHER_FORECAST_IMAGE',
  SET_WEATHER_FORECAST_IMAGE_LOADING = '[WeatherForecastImage] SET_WEATHER_FORECAST_IMAGE_LOADING',
  SET_WEATHER_FORECAST_IMAGE_ERROR = '[WeatherForecastImage] SET_WEATHER_FORECAST_IMAGE_ERROR',
}

export function getWeatherForecastImage(request: IGetWeatherForecastRequest): IActionWithPayload {
  return {type: WeatherForecastImageActionTypes.GET_WEATHER_FORECAST_IMAGE, payload: request};
}

export function setWeatherForecastImage(imageUrl: string): IActionWithPayload {
  return {type: WeatherForecastImageActionTypes.SET_WEATHER_FORECAST_IMAGE, payload: imageUrl};
}

export function setWeatherForecastImageLoading(status: boolean): IActionWithPayload {
  return {type: WeatherForecastImageActionTypes.SET_WEATHER_FORECAST_IMAGE_LOADING, payload: status};
}

export function setWeatherForecastImageError(status: boolean): IActionWithPayload {
  return {type: WeatherForecastImageActionTypes.SET_WEATHER_FORECAST_IMAGE_ERROR, payload: status};
}
