<div class="add-remove">
  <div class="add-remove__header">
    <div (click)="isAddStation = true"
         [class.add-remove__header-item__inactive]="!isAddStation"
         class="add-remove__header-item"><i class="zmdi zmdi-plus-circle"></i>{{ 'Add virtual station' | translate }}
    </div>
    <div (click)="isAddStation = false"
         [class.add-remove__header-item__inactive]="isAddStation"
         class="add-remove__header-item"><i class="zmdi zmdi-minus-circle"></i>{{ 'Remove virtual station' | translate }}
    </div>
  </div>
  <div class="add-remove__form">
    <div class="alert alert-info">
      <ng-container *ngIf="isAddStation; else remove">
        {{ 'Use the Product Key, that was emailed to you after the purchase, to add it to your list.' | translate }}
      </ng-container>
      <ng-template #remove>
        {{ 'Use the Product Key, that was emailed to you after the purchase, to remove it from your list.' | translate }}
      </ng-template>
    </div>
    <app-add-remove-virtual-station-form [hidden]="!isAddStation"
                                 (positionChanged)="setElevationAndTimezone($event)"
                                 (submit)="addVirtualStation($event)"
                                 [isAddForm]="true"></app-add-remove-virtual-station-form>
    <app-add-remove-virtual-station-form [hidden]="isAddStation"
                                 (submit)="removeVirtualStation($event)"
                                 [isAddForm]="false"></app-add-remove-virtual-station-form>
  </div>
</div>
