import {Injectable} from '@angular/core';
import {CanActivate, CanLoad, Route, Router} from '@angular/router';
import {AuthService} from './auth.service';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class AuthGuard implements CanLoad, CanActivate {

    constructor(private router: Router, private authService: AuthService) { }

    public canLoad(route: Route): boolean {
      const token = this.authService.hasAccessToken();
      if (route.path === 'auth') {
        // logout user and allow component to load for activation and acount closing
        if (window.location.href.indexOf('close') > -1 ||
            window.location.href.indexOf('activate') > -1
        ) {

          this.authService.logoutUser(null, false);
          return true;
        }

        if (token) {
          this.router.navigate(['/dashboard']);
          return false;
        }
        return true;
      } else {
        if (!token) {
          this.authService.logoutUser();
          return false;
        }
        return true;
      }
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      if (this.authService.hasAccessToken()) {
        return true;
      }
      this.authService.logoutUser({
        queryParams: {
          returnUrl: state.url
        }
      });
      return false;
    }
}
