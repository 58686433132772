import { rgb } from 'd3';
import { IChartTooltipItem } from '../interfaces';

export default function (chartOptions, tooltips: IChartTooltipItem[][], unitSystem?): any {
    return (function (options): any {
        return options.map((option, i) => {
            const tooltip = tooltips[i];
            if (tooltip) {
                option.tooltip.formatter = function (): string {
                    return tooltip.reduce((html: string, item: IChartTooltipItem) => {
                        let requiredColor = null;
                        if (this.point.series.name === 'plant-protection') {
                            const color = parameterColor(item.prop, this.point[item.prop], unitSystem);
                            const parameterColour = popupColor(color);
                            requiredColor = `background-color: ${hexToRgb(parameterColour)}`;
                        }
                        return html += `<tr style="${requiredColor}"><td>${item.name}: </td>
                            <td style="text-align: right">
                            <b>${this.point[item.prop] === undefined ? '--' : this.point[item.prop]}</b>
                            </td>
                            </tr>`;
                    }, `<small>${this.point.name}</small><table>`) + '</table>';
                };
            }
            return option;
        });
    })(chartOptions);
}

export function parameterColor(parameter: string, value: any, unitSystem?): any {
    const red = '#660000';
    const yellow = '#d6af7b';
    if (unitSystem === 'metric') {
        switch (parameter) {
            case ('delta_t'): {
                const val = splitValue(value, -3);
                return val > 10 ? red : (val < 2 || (val > 8 && val <= 10)) ? yellow : null;
            }
            case ('temp'): {
                const val = splitValue(value, -3);
                return (val < 5 || val > 35) ? red : ((val > 30 && val <= 35) || (val > 5 && val < 10)) ? yellow : null;
            }
            case ('winspeed'): {
                const val = splitValue(value, -5);
                return val > 15 ? red : (val < 2 || val > 10) ? yellow : null;
            }
            case ('gust'): {
                const val = splitValue(value, -5);
                return val >= 25 ? red : (val > 20 && val < 25) ? yellow : null;
            }
            case ('hum'): {
                const val = splitValue(value, -2);
                return (val < 40 || val >= 95) ? red : ((val >= 40 && val <= 50) || (val > 90 && val < 95)) ? yellow : null;
            }
            case ('prec'): {
                const val = splitValue(value, -3);
                return val >= 0.2 ? red : val === 0.2 ? yellow : null;
            }
            case ('prec4h'): {
                const val = splitValue(value, -3);
                return val > 2 ? red : (val > 0 && val <= 2) ? yellow : null;
            }
            case ('prec24h'): {
                const val = splitValue(value, -3);
                return val > 15 ? red : (val >= 8 && val <= 15) ? yellow : null;
            }
            default: return null;
        }
    } else {
        switch (parameter) {
            case ('delta_t'): {
                const val = splitValue(value, -3);
                return val > 21.6 ? red : (val < 3.6 || (val > 14.4 && val <= 21.6)) ? yellow : null;
            }
            case ('temp'): {
                const val = splitValue(value, -3);
                return (val < 41 || val > 95) ? red : ((val > 86 && val <= 95) || (val > 41 && val < 50)) ? yellow : null;
            }
            case ('winspeed'): {
                const val = splitValue(value, -4);
                return val > 9.32 ? red : (val < 1.24 || val > 6.21) ? yellow : null;
            }
            case ('gust'): {
                const val = splitValue(value, -4);
                return val >= 15.5 ? red : (val > 12.4 && val < 15.5) ? yellow : null;
            }
            case ('hum'): {
                const val = splitValue(value, -2);
                return (val < 40 || val >= 95) ? red : ((val >= 40 && val <= 50) || (val > 90 && val < 95)) ? yellow : null;
            }
            case ('prec'): {
                const val = splitValue(value, -3);
                return val >= 0.0078 ? red : val === 0.0078 ? yellow : null;
            }
            case ('prec4h'): {
                const val = splitValue(value, -3);
                return val > 0.078 ? red : (val > 0 && val <= 0.078) ? yellow : null;
            }
            case ('prec24h'): {
                const val = splitValue(value, -3);
                return val > 0.59 ? red : (val >= 0.31 && val <= 0.59) ? yellow : null;
            }
            default: return null;
        }
    }
}

export function popupColor(color: string): any {
    switch (color) {
        case ('#660000') : return '#E08484';
        case ('#d6af7b') : return '#F7E26C';
        default: return null;
    }
}

export function hexToRgb(hex): any {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? rgb(parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)) : null;
}

export function splitValue(str, removeLast): any {
    const result = str.slice(0, removeLast);
    return result;
}
