import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IGetPhotosRequestFromTo, IPictureSet} from '../../../shared/camera/models/camera';
import {
  IDeleteImagePayload,
  IGetPhotosRequests,
  ISaveMeasurementsPayload,
  ISaveMeasurementsRequest
} from '../../../shared/camera/models/camera';
import * as moment from 'moment';

export const enum CropViewActionTypes {
  GET_CROP_VIEW_PHOTOS = '[CropView] GET_CROP_VIEW_PHOTOS',
  SET_CROP_VIEW_PHOTOS = '[CropView] SET_CROP_VIEW_PHOTOS',
  SET_CROP_VIEW_PHOTOS_LOADING = '[CropView] SET_CROP_VIEW_PHOTOS_LOADING',
  SET_CROP_VIEW_PHOTOS_ERROR = '[CropView] SET_CROP_VIEW_PHOTOS_ERROR',
  SAVE_CROP_VIEW_PHOTOS_MEASUREMENTS = '[CropView] SAVE_CROP_VIEW_PHOTOS_MEASUREMENTS',
  SET_CROP_VIEW_MEASUREMENT = '[CropView] SET_CROP_VIEW_MEASUREMENT',
  DELETE_CROP_VIEW_PHOTOS_IMAGE = '[CropView] DELETE_CROP_VIEW_PHOTOS_IMAGE',
  GET_CROP_VIEW_FIRST_DATE = '[CropView] GET_CROP_VIEW_FIRST_DATE',
  SET_CROP_VIEW_FIRST_DATE = '[CropView] SET_CROP_VIEW_FIRST_DATE',
  GET_CROP_VIEW_LAST_DATE = '[CropView] GET_CROP_VIEW_LAST_DATE',
  SET_CROP_VIEW_LAST_DATE = '[CropView] SET_CROP_VIEW_LAST_DATE'
}

export function getCropViewPhotos(data: IGetPhotosRequests | IGetPhotosRequestFromTo): IActionWithPayload {
  return {type: CropViewActionTypes.GET_CROP_VIEW_PHOTOS, payload: data};
}

export function setCropViewPhotos(data: IPictureSet): IActionWithPayload {
  return {type: CropViewActionTypes.SET_CROP_VIEW_PHOTOS, payload: data};
}

export function setCropViewLoading(status: boolean): IActionWithPayload {
  return {type: CropViewActionTypes.SET_CROP_VIEW_PHOTOS_LOADING, payload: status};
}

export function setCropViewError(status: boolean): IActionWithPayload {
  return {type: CropViewActionTypes.SET_CROP_VIEW_PHOTOS_ERROR, payload: status};
}

export function saveCropViewMeasurements(data: ISaveMeasurementsPayload): IActionWithPayload {
  return {type: CropViewActionTypes.SAVE_CROP_VIEW_PHOTOS_MEASUREMENTS, payload: data};
}

export function setCropViewMeasurement(data: ISaveMeasurementsRequest): IActionWithPayload {
  return {type: CropViewActionTypes.SET_CROP_VIEW_MEASUREMENT, payload: data};
}

export function deleteCropViewImage(data: IDeleteImagePayload): IActionWithPayload {
  return {type: CropViewActionTypes.DELETE_CROP_VIEW_PHOTOS_IMAGE, payload: data};
}

export function getCropViewFirstDate(stationId: string): IActionWithPayload {
  return {type: CropViewActionTypes.GET_CROP_VIEW_FIRST_DATE, payload: stationId};
}

export function setCropViewFirstDate(firstDate: moment.Moment): IActionWithPayload {
  return {type: CropViewActionTypes.SET_CROP_VIEW_FIRST_DATE, payload: firstDate};
}

export function getCropViewLastDate(stationId: string): IActionWithPayload {
  return {type: CropViewActionTypes.GET_CROP_VIEW_LAST_DATE, payload: stationId};
}

export function setCropViewLastDate(lastDate: moment.Moment): IActionWithPayload {
  return {type: CropViewActionTypes.SET_CROP_VIEW_LAST_DATE, payload: lastDate};
}
