import { INotification } from './../models/notifications';

export const emptyNotification: INotification = {
    category: '',
    title: '',
    time: '',
    body: '',
    icon: '',
    stationID: '',
    seen: true
};
