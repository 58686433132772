import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { IState } from '../../../../../core/reducers';
import { IDefaultPawSetting } from '../../../models';
import { selectSettings } from './../../../../../core/reducers/index';
import { soilProfileOptions, soilProfileValues } from './../../../../irrimet-config/constants/config';

@Component({
  selector: 'app-sensor-settings-column',
  templateUrl: './sensor-settings-column.component.html',
  styleUrls: ['./sensor-settings-column.component.scss']
})
export class SensorSettingsColumnComponent implements OnInit {

  @Input()
  public form       : FormGroup = new FormGroup({});
  @Input()
  public name       : string = '';
  @Input()
  public sensors    : IDefaultPawSetting[] = [];
  @Input()
  public depth_unit :  string = '';
  @Input()
  public soilProfileActive?: boolean = false;
  @Input()
  public sfAllowed? : boolean = true;

  public soilProfileOptions = soilProfileOptions;
  private unitSystem: string;

  constructor(private account: Store<IState>) {}

  public get soilProfile(): AbstractControl {
    return this.form.get('soilProfile');
  }

  public ngOnInit(): void {
    this.account.pipe(
      select(selectSettings)
    ).subscribe(account => this.unitSystem = account.unit_system);

    if (this.soilProfileActive) {
      this.form.addControl('soilProfile', new FormControl(0, ));
      this.onSoilProfileChanges();
    }
  }

  private onSoilProfileChanges(): void {
    this.soilProfile.valueChanges.subscribe(soilProfileIndex => {
      if (soilProfileIndex > 0) {
        this.fillInFirstRow(soilProfileIndex);
      }
    });
  }

  private fillInFirstRow(soilProfileIndex: number): void {
    // tslint:disable
    this.form['controls'][Object.keys(this.form['controls'])[0]]['controls']['refill_point'].setValue(soilProfileValues[soilProfileIndex].RP);
    this.form['controls'][Object.keys(this.form['controls'])[0]]['controls']['field_capacity'].setValue(soilProfileValues[soilProfileIndex].FC);
    this.form['controls'][Object.keys(this.form['controls'])[0]]['controls']['unit'].setValue('%');
    // tslint:enable
    if (this.unitSystem === 'metric') {
      this.form['controls'][Object.keys(this.form['controls'])[0]]['controls']['depth'].setValue(10);
    } else if (this.unitSystem === 'imperial') {
      this.form['controls'][Object.keys(this.form['controls'])[0]]['controls']['depth'].setValue(3.9);
    }
  }
}
