import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-work-planning-help-modal',
  templateUrl: './work-planning-help-modal.component.html',
  styleUrls: ['./work-planning-help-modal.component.scss']
})
export class WorkPlanningHelpModalComponent implements OnInit {

  @Input()
  public help: string;

  constructor() { }

  public ngOnInit(): void { }

}
