import { IYieldPredictionValues } from '../models/model';

export const yieldCropColorTypes = (crop) => {
    switch (crop) {
        case 'barley' : return '9B540D';
        case 'canola' : return 'E8C637';
        case 'potatoes' : return 'A68A64';
        case 'wheat' : return 'EB8723';
        case 'soy' : return '426732';
        case 'durum' : return 'F4BF8A';
        case 'maize' : return 'D5B740';
        default : return '33A02C';
    }
};

export const yieldUnitTypes = (unit) => {
    switch (unit) {
        case 'bu/ac' : return 'imperial';
        case 't/ha' : return 'metric';
        default : return null;
    }
};

export const TRANSLATED_VALUES: IYieldPredictionValues = {
    currentSeason: 'Current season',
    predictedNormalSeason: 'Predicted (normal season)',
    predictedRainForest: 'Predicted (rain forecast)',
    range: 'Range',
    today: 'Today',
    physiologicalMaturity: 'Physiological maturity',
    yieldPrediction: 'Yield Prediction',
    precipitation35YearAvg: 'Precipitation (35 year average, cumulative)',
    precipitationCumulative: 'Precipitation (cumulative)',
    precipitationDaily: 'Precipitation (daily)',
    precipitationAccm: 'Precip. (accum.)',
};
