import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environmentToken } from '../../../environments/environment';
import { IColumn, ILoadWidgetData } from '../../modules/dashboard/models/dashboard.models';
import {
  IGetStationDataFcRequest,
  IGetStationDataProfilesRequest
} from '../../modules/station-data/models/station-data.models';
import { IGetTrackerDataRequest } from '../../modules/tracker/models/tracker.models';
import { IAddRemoveStation, IAddRemoveVirtualStation, IPosition, IStation } from '../../core/models/stations';
import {
  IEmergencySave,
  ILEDTrapSaveObj,
  ILoggingSaveObj,
  ISaveCams,
  ISensorGroups
} from '../../modules/station-config/models/station-config.models';
import { IGetStationEventsPayload } from '../../modules/station-events/models/station-events.models';
import { ISaveNodeName, ISaveSensor } from '../../modules/sensors-and-nodes/models/sensors-and-nodes';
import { IGetStationCommunicationRequest } from '../../modules/station-communication/models/station-communication.models';
import { IDeleteWeatherDataRequest } from '../../modules/station-delete-weather-data/models/delete-weather-data.models';
import { IPhonesSave, ISaveConvertUnits, ISaveStationWarnings } from '../../modules/warnings/models/models';
import { ISaveDiseaseModelPayload } from '../../modules/station-disease/models/station-disease.models';
import * as SoilMoisture from '../../modules/soil-moisture/models';
import {
  IGetWeatherForecastImageRequest,
  IGetWeatherForecastRequest
} from '../../modules/weather-forecast/models/models';
import { IWorkPlanningRequest } from '../../modules/work-planning/models/work-planning.models';
import { IMessage, ITermsSave } from '../../core/models/account';
import {
  IClearMeasurementsRequest,
  IDeleteCameraPestBody,
  IDeleteGlueBoardBody,
  IDeleteImageRequest,
  IDeleteIscoutSeasonBody,
  IDrawingOptions,
  IGetCameraPestRequest,
  IGetPhotosRequest,
  IGetPhotosRequestFromTo,
  IPestToggler,
  IPostCameraPestBody,
  ISaveMeasurementsRequest
} from '../../shared/camera/models/camera';
import { IAsset, IJDAsset } from '../../modules/user-api-services/models/my-john-deere';
import { IAnimalProductionRequest } from '../../modules/animal-production/models/animal-production.models';
import { IModelSetting } from '../../modules/station-models/models';
import { IFbSettings } from '../../modules/user-api-services/models/farmbeats';
import { IAddCropZone, ICropZone } from '../../core/models/cropzones';
import { IAddFarm, IFarm } from '../../core/models/farms';
import { IAddField, IField } from '../../core/models/fields';
import { IIrrimetData } from '../../modules/irrimet/models/models';
import { ISumsSmData } from '../../modules/crop-zone-soil-moisture/models';
import { IOptionsForDatasources } from '../../modules/irrimet-config/actions/irrimet-config.action';
import {
  IDiseaseModelActivation,
  IForecastActivation,
  ILicenseActivationItem,
  IServiceLicenseActivation,
  IVerifyLicense,
  IVirtualStationActivation
} from '../../modules/product-activation/models/product-activation.models';
import { MainSocket } from '../sockets/main.socket';
import { IXarvioStation } from '../../modules/user-api-services/models/xarvio';
import {
  ILaiStat as ICropZoneLaiStats,
  ILaiVal as ICropZoneLaiVals,
  INdviStat as ICropZoneNdviStats,
  INDVIVal as ICropZoneNdviVals
} from '../../modules/crop-zone-sat/models';
import { IServerAlert, IServerAlertData } from '../../modules/server-alerts/interfaces/server-alert';
import { IUserWebToken } from '../../core/models/notifications';
import { IDvAssets, IDvSettings } from '../../modules/user-api-services/models/davis';
import {
  IAccumulationAsparagusRequest,
  IAccumulationRainRequest,
  IAccumulationRequest
} from '../../modules/accumulator-tool/interfaces/interfaces';
import { IGetHydroponicsRequest } from '../../modules/hydroponics/models/models';
import { IIscoutGlueBoard, IIscoutSeason } from '../../modules/iscout/models/iscout.models';
import {
  IRequestCameraData,
  IRequestCameraDataInterval,
  RequestCameraDataIntervalType
} from '../../modules/camera-data/models/camera-data';
import {
  IGetRainCorrectorRequest,
  IUpdateCleanPrecipitation,
  IUpdateRainCorrectorRequest
} from '../../modules/correct-precipitation-data/models/rain-corrector-data.module';
import { IFieldWiltingRequest } from '../../modules/yield-prediction-config/models/model';
import { IPhysiologicalMaturityRequest, IYieldPredictionModelRequest } from '../../modules/yield-prediction/models/model';
import { IPreviousYieldRequest } from '../../modules/yield-prediction-previous/models/model';
import { IFrostProtectionRequest } from '../../modules/frost-protection/models/models';
import { IUltrasonicValue } from '../../modules/ultrasonic-distance-settings/models/models';
import { IConversionRequest } from '../../shared/interfaces';
import { IDegreeAndChillingThresholds } from '../../core/models/accumulatorTool';
import { IEnvironment } from '../../../environments/interfaces/environment';

@Injectable()
export class ApiCallService {

  constructor(
    @Inject(environmentToken) private environment: IEnvironment,
    private http        : HttpClient,
    private mainSocket  : MainSocket
  ) {}

  public getUser(): Observable<any> {
    return this.http.get(this.environment.apiUrl + '/user');
  }

  public getUserNotifications(): Observable<any> {
    return this.http.get(this.environment.apiUrl + '/user/notifications');
  }

  public clearUserNotifications(): Observable<any> {
    return this.http.delete(this.environment.apiUrl + '/user/notifications');
  }

  public getUserLicenses(): Observable<any> {
    return this.http.get(this.environment.apiUrl + '/user/licenses');
  }

  public getActiveUserLicenses(): Observable<any> {
    return this.http.get(this.environment.apiUrl + '/user/activeLicenses');
  }

  public getFields(): Observable<any> {
    return this.http.get(this.environment.apiUrl + '/fields');
  }

  public getFarms(): Observable<any> {
    return this.http.get(this.environment.apiUrl + '/farms');
  }

  public getCropZones(): Observable<any> {
    return this.http.get(this.environment.apiUrl + '/crop-zones');
  }

  public getCropzonesByFieldId(field: IField): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/farms/${field.farm.id}/fields/${field.id}/crop-zones`);
  }

  public saveIrrimetSettings(body: any): Observable<any> {
    // use the Effect of farm/cropzone config instead
    return this.http.post(this.environment.apiUrl + `/farms`, body);
  }

  public getIrrimet(cropZoneId: string): Observable<IIrrimetData[]>  {
    const offset = new Date().getTimezoneOffset();
    // tslint:disable-next-line:max-line-length
    return this.http.post(this.environment.apiUrl + `/crop-zones/${cropZoneId}/irrimet`, { 'timezoneDiff': offset }) as Observable<IIrrimetData[]>;
  }

  public getCropZoneSoilMoistureSumsSm(cropZoneId: string, params: { from: number, to: number, group: string }): Observable<ISumsSmData[]> {
    return this.http.get(
      this.environment.apiUrl + `/crop-zones/${cropZoneId}/soil-moisture`, { params: params as any }
    ) as Observable<ISumsSmData[]>;
  }

  public getStations(): Observable<any> {
    return this.http.get(this.environment.apiUrl + '/user/stations');
  }

  public getStationsWarnings(stationId?: string): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/station/warnings${stationId ? `/${stationId}` : ''}`);
  }

  public getWidget(loadData: ILoadWidgetData): Observable<any> {
    if (loadData.profile) {
      return this.http.post(this.environment.apiUrl + loadData.url, {name: loadData.profile});
    } else if (loadData.channels) {
      return this.http.post(this.environment.apiUrl + loadData.url, {channels: loadData.channels});
    } else {
      return this.http.get(this.environment.apiUrl + loadData.url);
    }
  }

  public getDiseases(): Observable<any> {
    return this.http.get(this.environment.apiUrl + '/system/diseases');
  }

  public getCalibrationSettings(): Observable<any> {
    return this.http.get(this.environment.apiUrl + '/system/calibrationsettings');
  }

  public saveDefaultDashboard(dashboard: Array<Array<IColumn>>): Observable<any> {
    return this.http.put(this.environment.apiUrl + '/user', {
      customizations: { dashboard: dashboard }
    });
  }

  public getDashboard(id: string): Observable<any> {
    return this.http.get(this.environment.apiUrl + '/dashboard/' + id);
  }

  public createDashboard(title: string, dashboard: Array<IColumn[]>): Observable<any> {
    return this.http.post(this.environment.apiUrl + '/dashboard', {
      customizations: { title, dashboard}
    });
  }

  public updateDashboard(title: string, dashboard: Array<IColumn[]>, id: string): Observable<any> {
    return this.http.put(this.environment.apiUrl + '/dashboard/' + id, {
      customizations: { title, dashboard}
    });
  }

  public removeDashboard(id: string): Observable<any> {
    return this.http.delete(this.environment.apiUrl + '/dashboard/' + id);
  }

  public listDashboards(): Observable<any> {
    return this.http.get(this.environment.apiUrl + '/dashboard/list');
  }

  public getDashboardTexts(): Observable<any> {
    return this.http.get(this.environment.apiUrl + '/dashboard/text');
  }

  public getNewModulesTexts(): Observable<any> {
    return this.http.get(this.environment.apiUrl + '/newModulesTexts');
  }

  public getStationDataProfiles(request: IGetStationDataProfilesRequest): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/view/predefined/${request.stationId}/from/${request.from}/to/${request.to}`);
  }

  public getStationDataFc(request: IGetStationDataFcRequest): Observable<any> {
    const url = `${this.environment.apiUrl}/fc/${request.stationId}/${request.timePeriod}/from/${request.from}/to/${request.to}`;
    return this.http.post(url, {name: request.name});
  }

  public getStationSensors(id: string): Observable<any> {
    const url = `${this.environment.apiUrl}/station/${id}/sensors`;
    return this.http.get(url);
  }

  public getUserServerAlerts(): Observable<any[]> {
    const url = `${this.environment.apiUrl}/user/notification-configs`;
    return this.http.get<any>(url);
  }

  public getStationServerAlerts(id: string): Observable<IServerAlert[]> {
    const url = `${this.environment.apiUrl}/station/${id}/userAlerts`;
    return this.http.get<IServerAlert[]> (url);
  }

  public addStationServerAlert({ id, alert }: IServerAlertData): Observable<any> {
    const url = `${this.environment.apiUrl}/station/${id}/userAlerts`;
    return this.http.post(url, alert);
  }

  public updateStationServerAlert({ id, alert }: IServerAlertData): Observable<any> {
    const url = `${this.environment.apiUrl}/station/${id}/userAlerts`;
    return this.http.put(url, alert);
  }

  public deleteStationServerAlert({ id, alert }: IServerAlertData): Observable<any> {
    if (alert._id) {
      const url = `${this.environment.apiUrl}/station/${id}/userAlerts/${alert._id}`;
      return this.http.delete(url);
    }
  }

  public updateStationSensors(id: string, sensors: ISaveSensor[]): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/station/${id}/sensors`, sensors);
  }

  public calculation(id: string, body: IAccumulationRequest): Observable<any> {
    const url = `${this.environment.apiUrl}/calculation/${id}`;
    return this.http.post(url, body);
  }

  public getYpmConstants(): Observable<any> {
    const url = `${this.environment.apiUrl}/ypm/constants`;
    return this.http.get(url);
  }

  public calculateFieldWilting(id: string, body: IFieldWiltingRequest): Observable<any> {
    const url = `${this.environment.apiUrl}/ypm/calculate/${id}`;
    return this.http.post(url, body);
  }

  public calculatePhysiologicalMaturity(id: string, body: IPhysiologicalMaturityRequest): Observable<any> {
    const url = `${this.environment.apiUrl}/ypm/calculate/${id}`;
    return this.http.post(url, body);
  }

  public getYieldPredictionModel(id: string, body: IYieldPredictionModelRequest): Observable<any> {
    const url = `${this.environment.apiUrl}/ypm/model/${id}`;
    return this.http.post(url, body);
  }

  public createPreviousYield(body: IPreviousYieldRequest): Observable<any> {
    const url = `${this.environment.apiUrl}/previous-yields/create`;
    return this.http.post(url, body);
  }

  public updatePreviousYield(id: string, body: IPreviousYieldRequest): Observable<any> {
    const url = `${this.environment.apiUrl}/previous-yields/update/${id}`;
    return this.http.put(url, body);
  }

  public getPreviousYields(id: string): Observable<any> {
    const url = `${this.environment.apiUrl}/previous-yields/cropId/${id}`;
    return this.http.get(url);
  }

  public deletePreviousYield(id: string): Observable<any> {
    const url = `${this.environment.apiUrl}/previous-yields/delete/${id}`;
    return this.http.delete(url);
  }

  public getPreviousYieldPredictionModel(id: string): Observable<any> {
    const url = `${this.environment.apiUrl}/previous-yields/chart/cropId/${id}`;
    return this.http.get(url);
  }

  public getSensorsByGroups(id: string, body: ISensorGroups): Observable<any> {
    const url = `${this.environment.apiUrl}/station/${id}/sensors/byGroup`;
    return this.http.post(url, body);
  }

  public calculationRain(id: string, body: IAccumulationRainRequest): Observable<any> {
    const url = `${this.environment.apiUrl}/calculation/${id}/rain`;
    return this.http.post(url, body);
  }

  public getRainCorrectorData(req: IGetRainCorrectorRequest): Observable<any> {
    const url: string = `${this.environment.apiUrl}/station/${req.stationId}/rain/from/${req.from}/to/${req.to}`;
    return this.http.get(url);
  }

  public updateRainCorrectorData(id: string, req: IUpdateRainCorrectorRequest[]): Observable<any> {
    const url = `${this.environment.apiUrl}/station/${id}/rain`;
    return this.http.post(url, req);
  }

  public cleanRainCorrectorData(id: string, req: IUpdateCleanPrecipitation): Observable<any> {
    const url = `${this.environment.apiUrl}/station/${id}/clearRain`;
    return this.http.post(url, req);
  }

  public calculationAsparagus(id: string, body: IAccumulationAsparagusRequest): Observable<any> {
    const url = `${this.environment.apiUrl}/calculation/${id}/asparagus`;
    return this.http.post(url, body);
  }

  public exportStationDataImage(req: IGetStationDataFcRequest): Observable<any> {
    const endpoint: string = `${this.environment.apiUrl}/chart/image/${req.stationId}/${req.timePeriod}/from/${req.from}/to/${req.to}`;
    return this.http.post(endpoint, {name : req.name});
  }

  public getStation(id: string): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/station/${id}`);
  }

  public getCropzone(id: string): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/crop-zones/${id}`);
  }

  public toggleAutoSync(): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/user/toggleAutoSync`);
  }

  public saveStationNote(id: string, note: string): Observable<any> {
    return this.http.put(`${this.environment.apiUrl}/station/${id}`, {
      note: note
    });
  }

  public saveCustomName(name: string, id: string): Observable<any> {
    return this.http.put(`${this.environment.apiUrl}/station/${id}`, {
      name: name
    });
  }

  public saveStarredStation(value: boolean, id: string): Observable<any> {
    return this.http.put(`${this.environment.apiUrl}/station/${id}`, {
      starred: value
    });
  }

  public saveRenamedCropzoneName(cropzone: ICropZone): Observable<any> {
    return this.http.put(
      `${this.environment.apiUrl}/farms/${cropzone.farm.id}/fields/${cropzone.field.id}/crop-zones/${cropzone.id}`,
      cropzone
    );
  }

  public updateCropzone(cropzone: ICropZone): Observable<any> {

    const data = {...cropzone};

    delete data['field'];
    delete data['farm'];
    delete data['id'];
    delete data['year'];

    try {
      this.mainSocket.dispatch('cropzone.change', JSON.stringify(cropzone));
    } catch (e) {
      //
    }

    return this.http.put(
      `${this.environment.apiUrl}/farms/${cropzone.farm.id}/fields/${cropzone.field.id}/crop-zones/${cropzone.id}`,
      data
    );
  }

  public saveCropzoneBoundaries(cropzone: ICropZone): Observable<any> {
    return this.http.put(`${this.environment.apiUrl}/farms/${cropzone.farm.id}/fields/${cropzone.field.id}/crop-zones/${cropzone.id}`, {
      boundary: cropzone.boundary
    });
  }

  public saveRenamedFarmName(cropzone: ICropZone): Observable<any> {
    return this.http.put(`${this.environment.apiUrl}/farms/${cropzone.farm.id}`, {
      name: cropzone.farm.name
    });
  }

  public saveRenamedFarmNameFromFarm(farm: IFarm): Observable<any> {
    return this.http.put(`${this.environment.apiUrl}/farms/${farm.id}`, {
      name: farm.name
    });
  }

  public saveRenamedFieldName(cropzone: ICropZone): Observable<any> {
    return this.http.put(`${this.environment.apiUrl}/farms/${cropzone.farm.id}/fields/${cropzone.field.id}`, {
      name: cropzone.field.name
    });
  }

  public updateMultiple(cropzones: ICropZone[]): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/updateMultiple`;
    return this.http.post(endpoint, cropzones);
  }

  public saveRenamedFieldFromField(field: IField): Observable<any> {
    return this.http.put(`${this.environment.apiUrl}/farms/${field.farm.id}/fields/${field.id}`, {
      name: field.name
    });
  }

  public getTimezone(): Observable<any> {
    return this.http.get('./assets/settings/timezones.json');
  }

  public getTimezoneFromAPI(coords: Array<number>): Observable<any> {
    const timeStamp: number = Math.floor(Date.now() / 1000);
    const url: string = `${this.environment.gMapEndPoint}json?` +
      `location=${coords[1]},${coords[0]}&timestamp=${timeStamp}&key=${this.environment.gMapTZKey}`;
    return this.http.get(url);
  }

  public saveStationLocation(position: IPosition, id: string): Observable<any> {
    return this.http.put(`${this.environment.apiUrl}/station/${id}`, {
      position: position
    });
  }

  public saveStationLogging(save: ILoggingSaveObj, id: string): Observable<any> {
    return this.http.put(`${this.environment.apiUrl}/station/${id}`, save);
  }

  public getStationEvents(request: IGetStationEventsPayload): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/station/${request.stationId}/events/last/100`);
  }

  public saveStationEmergency(save: IEmergencySave, id: string): Observable<any> {
    return this.http.put(`${this.environment.apiUrl}/station/${id}`, save);
  }

  public saveStationLedTrap(save: ILEDTrapSaveObj, id: string): Observable<any> {
    return this.http.put(`${this.environment.apiUrl}/station/${id}`, save);
  }

  public saveStationCam(save: ISaveCams, id: string): Observable<any> {
    return this.http.put(`${this.environment.apiUrl}/station/${id}`, save);
  }

  public saveStationLEDFlash(station: IStation, flash: any): Observable<any> {
    return this.http.put(`${this.environment.apiUrl}/station/${station.name.original}`, {'config.flash': flash});
  }

  public getStationCommunication(request: IGetStationCommunicationRequest): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/station/${request.stationId}/history/${request.mode ? (request.mode + '/') : ''}last/100`;
    return this.http.get(endpoint);
  }

  public getStationNodes(id: string): Observable<any> {
    const url = `${this.environment.apiUrl}/station/${id}/nodes`;
    return this.http.get(url);
  }

  public updateStationNode(id: string, node: ISaveNodeName): Observable<any> {
    const url = `${this.environment.apiUrl}/station/${id}/nodes`;
    return this.http.put(url, node);
  }

  public getStationSerials(id: string): Observable<any> {
    const url = `${this.environment.apiUrl}/station/${id}/serials`;
    return this.http.get(url);
  }

  public updateStationSerial(id: string, node: ISaveNodeName): Observable<any> {
    const url = `${this.environment.apiUrl}/station/${id}/serials`;
    return this.http.put(url, node);
  }

  public resetUnits(id: string): Observable<any> {
    const url = `${this.environment.apiUrl}/station/${id}/reset/units`;
    return this.http.get(url);
  }

  public getStationModels(stationId: string): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/station/${stationId}/models`);
  }

  public updateStationModels(stationId: string, setting: IModelSetting): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/station/${stationId}/models`, setting);
  }

  public getCameraLastImage(request: any): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/camera/${request.stationId}/photos/last/1/${request.camId}`;
    return this.http.get(endpoint);
  }

  public deleteWeatherData(request: IDeleteWeatherDataRequest): Observable<any> {
    let url = `${this.environment.apiUrl}/data/${request.stationId}/${request.stationKey}`;
    if (request.from && request.to) {
      url += `/from/${request.from}/to/${request.to}`;
    }
    return this.http.delete(url);
  }

  public getConvertUnits(stationId: string): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/warning/${stationId}/convertUnits`;
    return this.http.post(endpoint, {});
  }

  public saveConvertUnits(stationId: string, units: ISaveConvertUnits): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/warning/${stationId}/convertUnits`;
    return this.http.post(endpoint, units);
  }

  public saveWarningsPhones(stationId: string, phones: IPhonesSave): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/station/${stationId}`;
    return this.http.put(endpoint, phones);
  }

  public saveWarningsSensors(stationId: string, warnings: ISaveStationWarnings): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/station/${stationId}`;
    return this.http.put(endpoint, warnings);
  }

  public getStationLicenses(stationId: string): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/station/${stationId}/licenses`;
    return this.http.get(endpoint);
  }

  public getStationDisease(stationId: string): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/station/${stationId}/settings/disease`;
    return this.http.get(endpoint);
  }

  public saveStationDisease(payload: ISaveDiseaseModelPayload): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/station/${payload.stationId}/settings/disease`;
    return this.http.put(endpoint, payload.request);
  }

  public getSoilMoistureViews(payload: SoilMoisture.IGetViewsRequest): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/view/custom/of/${payload.stationId}`);
  }

  public postSoilMoistureView(payload: SoilMoisture.IView): Observable<string[]> {
    return this.http.post(`${this.environment.apiUrl}/view/custom`, { ...payload, _id: undefined }) as Observable<string[]>;
  }

  public putSoilMoistureView(payload: SoilMoisture.IView): Observable<any> {
    return this.http.put(`${this.environment.apiUrl}/view/custom/${payload._id}`, { ...payload, _id: undefined });
  }

  public deleteSoilMoistureView(payload: SoilMoisture.IView): Observable<any> {
    return this.http.delete(`${this.environment.apiUrl}/view/custom/${payload._id}`);
  }

  public getSoilMoistureChart(request: SoilMoisture.IGetChartRequest): Observable<any> {
    const url = `${this.environment.apiUrl}/fc/${request.stationId}/${request.timePeriod}/from/${request.from}/to/${request.to}`;
    return this.http.post(url, request.payload);
  }

  public getSoilMoistureChartExport(request: {}[]): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/fc/charts`, request);
  }

  public getHydroponicsChartsExport(request: any): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/fc/charts`, request);
  }

  public savePassword(password: string): Observable<any> {
    return this.http.put(this.environment.apiUrl + '/user', { password: password });
  }

  public saveOtherSettings(settings: any): Observable<any> {
    return this.http.put(this.environment.apiUrl + '/user', settings);
  }

  public saveNotifications(notifications: any): Observable<any> {
    return this.http.put(this.environment.apiUrl + '/user', notifications);
  }

  public saveUserToken(data: IUserWebToken): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/user/token`, data);
  }

  public getWeatherForecastDetails(data: IGetWeatherForecastRequest): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/fc/${data.stationId}/raw`;
    return this.http.post(endpoint, {name: data.mode});
  }

  public exportWeatherForecastImage(request: IGetWeatherForecastRequest): Observable<any> {
    return this.getWeatherForecastImage(<IGetWeatherForecastImageRequest>{
      ...request,
      endpointType: 'raw'
    });
  }

  public getSoilGuardByDevice(device: string, period: string = '3d'): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/soilguard/data/device/${device}/last/${period}`);
  }

  public getSoilGuardByDate(device: string, date: { from: number, to: number }): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/soilguard/data/device/${device}/from/${date.from}/to/${date.to}`);
  }

  public getSoilGuardByCropzoneId(cropzone: string, period: string): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/soilguard/data/crop-zone/${cropzone}/last/${period}`);
  }

  public getSoilGuardByCropzoneDate(cropzone: string, date: { from: number, to: number }): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/soilguard/data/crop-zone/${cropzone}/from/${date.from}/to/${date.to}`);
  }

  public getDualexByDevice(device: string, period: string = '3d'): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/dualex/data/device/${device}/last/${period}`);
  }

  public getDualexByDate(device: string, date: { from: number, to: number }): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/dualex/data/device/${device}/from/${date.from}/to/${date.to}`);
  }

  public getMobilabByDate(device: string, date: { from: number, to: number }): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/mobilab/data/device/${device}/from/${date.from}/to/${date.to}`);
  }

  public getDualexByCropzoneId(cropzone: string, period: string): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/dualex/data/crop-zone/${cropzone}/last/${period}`);
  }

  public getDualexByCropzoneDate(cropzone: string, date: { from: number, to: number }): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/dualex/data/crop-zone/${cropzone}/from/${date.from}/to/${date.to}`);
  }

  public getMobilabByCropzoneId(cropzone: string, period: string): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/mobilab/data/crop-zone/${cropzone}/last/${period}`);
  }

  public getMobilabByCropzoneDate(cropzone: string, date: { from: number, to: number }): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/mobilab/data/crop-zone/${cropzone}/from/${date.from}/to/${date.to}`);
  }

  public uploadSampleDataFile(data: FormData): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/dataupload/file`, data);
  }

  public getWorkplanningService(data: IWorkPlanningRequest): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/fc/${data.stationId}`;
    return this.http.post(endpoint, { name: data.service, crop: data.crop });
  }

  public getAnimalProductionCharts(data: IAnimalProductionRequest): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/fc/${data.stationId}`;
    return this.http.post(endpoint, { name: data.service });
  }

  public getMachineryTypesData(data: any): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/tracker//machinery/types`;
    return this.http.post(endpoint, {name: data.mode});
  }

  public getMachinery(stationId: string): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/tracker/machinery/definition/${stationId}`);
  }

  public getAddedMachinery(): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/tracker/machinery`);
  }

  public saveMachineryServiceLog(id: string, body: any): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/tracker/machinery/log/${id}`, body);
  }

  public getMachineryServiceLog(id: string): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/tracker/machinery/log/${id}`);
  }

  public getMachineryServiceLogFromTo(id: string, from: number, to: number): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/tracker/machinery/log/${id}/from/${from}/to/${to}`);
  }

  public getAddedTrackers(): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/tracker/all`);
  }

  public saveMachinery(machineryId: string, data: any): Observable<any> {
    let endpoint = `${this.environment.apiUrl}/tracker/machinery/save`;
    if (machineryId) {
      endpoint += `/${machineryId}`;
    }
    return this.http.post(endpoint, data);
  }

  public getTrackerReports(trackerID: any): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/tracker/report/${trackerID}`);
  }

  public getMachineryReports(machineryID: any): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/tracker/machinery/report/${machineryID}`);
  }

  public getTracker(stationId: any): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/tracker/definition/${stationId}`);
  }

  public saveTracker(stationId: string, data: any): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/tracker/${stationId}/save`, data);
  }

  public saveTrackerSettings(stationId: string, data: any): Observable<any> {
    return this.http.put(`${this.environment.apiUrl}/station/${stationId}`, data);
  }

  public getWeatherForecastImage(request: IGetWeatherForecastImageRequest): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/chart/image/${request.stationId}/${request.endpointType}`;
    return this.http.post(endpoint, {name: request.mode});
  }

  public saveTerms(terms: ITermsSave): Observable<any> {
    return this.http.put(this.environment.apiUrl + '/user', terms);
  }

  public getCameraPhotos(request: IGetPhotosRequest): Observable<any> {
    const cam = `${request.camId ? ('/' + request.camId) : ''}`;
    const endpoint = `${this.environment.apiUrl}/camera/${request.stationId}${cam}/photos/${request.date}`;
    return this.http.get(endpoint);
  }

  public getCameraDataFromIscout(request: IRequestCameraData): Observable<any> {
    const queryParams = this.buildCameraDataQueryParams(request.interval);
    const endpoint = `${this.environment.apiUrl}/camera/${request.stationId}/monitoring/iscout?${queryParams}`;
    return this.http.get(endpoint);
  }

  public getCameraDataFromCropView(request: IRequestCameraData): Observable<any> {
    const queryParams = this.buildCameraDataQueryParams(request.interval);
    const url = `${this.environment.apiUrl}/camera/${request.stationId}/monitoring/cropview/${request.cameraId}`;
    const endpoint = `${url}?${queryParams}`;
    return this.http.get(endpoint);
  }

  private buildCameraDataQueryParams(interval: IRequestCameraDataInterval): string {
    if (interval.type === RequestCameraDataIntervalType.INTERVAL) {
      return [`type=${interval.type}`, `from=${interval.from}`, `to=${interval.to}`].join('&');
    } else {
      return [`type=${interval.type}`, `value=${interval.value}`].join('&');
    }
  }

  public getCameraDistance(request: IGetPhotosRequest): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/station/${request.stationId}/cameradistance`;
    return this.http.get(endpoint);
  }

  public getCameraPhotosFromTo(request: IGetPhotosRequestFromTo): Observable<any> {
    const cam = `${request.camId ? ('/' + request.camId) : ''}`;
    const endpoint = `${this.environment.apiUrl}/camera/${request.stationId}/photos/from/${request.from}/to/${request.to}${cam}`;
    return this.http.get(endpoint);
  }

  public saveCameraMeasurements(stationId: string, body: ISaveMeasurementsRequest): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/camera/${stationId}/saveMeasurement`;
    return this.http.post(endpoint, body);
  }

  public clearCameraMeasurements(stationId: string, body: IClearMeasurementsRequest): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/camera/${stationId}/clearMeasurement`;
    return this.http.post(endpoint, body);
  }

  public getDrawingOptions(stationId: string): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/camera/${stationId}/getDrawingOptions`;
    return this.http.get(endpoint);
  }

  public saveDrawingOptions(stationId: string, body: IDrawingOptions): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/camera/${stationId}/saveDrawingOptions`;
    return this.http.post(endpoint, body);
  }

  public saveCameraDistance(stationId: string, body: any): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/station/${stationId}/cameradistance`;
    return this.http.post(endpoint, body);
  }

  public deleteCameraImage(request: IDeleteImageRequest): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/camera/${request.stationId}/deleteImage/${request.cam_id}/${request.pic_id}`);
  }

  public getCameraPests(request: IGetCameraPestRequest): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/camera/${request.stationId}/pests/${request.type}`);
  }

  public getCameraPestsOrders(): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/camera/getPestsOrders`);
  }

  public getCameraPestsFamily(): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/camera/getPestsFamily`);
  }

  public getCameraPestsGenus(): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/camera/getPestsGenus`);
  }

  public postCameraPest(
    stationId: string,
    body: IPostCameraPestBody | IDeleteCameraPestBody,
    action: 'add' | 'remove'
  ): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/camera/${stationId}/pests/userPest/${action}`;
    return this.http.post(endpoint, body);
  }

  public getPestToggles(stationId: string): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/camera/${stationId}/getPestToggles`;
    return this.http.get(endpoint);
  }

  public savePestToggle(stationId: string, pestToggle: IPestToggler): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/camera/${stationId}/savePestToggles`;
    return this.http.post(endpoint, pestToggle);
  }

  public getGlueBoards(stationId: string): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/camera/${stationId}/glueBoards`);
  }

  public getGlueBoardsAvailableDates(stationId: string): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/camera/${stationId}/glueBoards/availableDates`);
  }

  public saveGlueBoard(glueBoard: IIscoutGlueBoard): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/camera/${glueBoard.nm}/glueBoards`, glueBoard);
  }

  public updateGlueBoard(glueBoard: IIscoutGlueBoard): Observable<any> {
    return this.http.put(`${this.environment.apiUrl}/camera/${glueBoard.nm}/glueBoards/${glueBoard._id}`, glueBoard);
  }

  public exchangeGlueBoard(glueBoard: IIscoutGlueBoard): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/camera/${glueBoard.nm}/glueBoards/${glueBoard._id}/exchange`, glueBoard);
  }

  public removeGlueBoard(requestBody: IDeleteGlueBoardBody): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/camera/${requestBody.station_id}/glueBoards/${requestBody.glue_board_id}`;
    return this.http.delete(endpoint);
  }

  public getIscoutSeasons(stationId: string): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/camera/${stationId}/seasons`);
  }

  public getIscoutSeasonsAvailableGlueBoards(stationId: string): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/camera/${stationId}/seasons/availableGlueBoards`);
  }

  public saveIscoutSeason(season: IIscoutSeason): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/camera/${season.nm}/seasons`, season);
  }

  public updateIscoutSeason(season: IIscoutSeason): Observable<any> {
    return this.http.put(`${this.environment.apiUrl}/camera/${season.nm}/seasons/${season._id}`, season);
  }

  public removeIscoutSeason(requestBody: IDeleteIscoutSeasonBody): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/camera/${requestBody.station_id}/seasons/${requestBody.season_id}`;
    return this.http.delete(endpoint);
  }

  public addStation(add: IAddRemoveStation): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/station/${add.id}/${add.key}`;
    return this.http.post(endpoint, {
      name: add.name || ''
    });
  }

  public addFarm(add: IAddFarm): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/farms`;
    return this.http.post(endpoint, {
      name: add.name || ''
    });
  }

  public addField(add: IAddField): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/farms/${add.farm.id}/fields`;
    return this.http.post(endpoint, {
      name: add.name || ''
    });
  }

  public renewCropzones(cropzones): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/renewCropzones`;
    return this.http.post(endpoint, cropzones);
  }

  public addCropZone(add: IAddCropZone): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/farms/${add.farm.id}/fields/${add.field.id}/crop-zones`;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
    aYearFromNow.setHours(0, 0, 0, 0);

    return this.http.post(endpoint, {
      name: add.name || '',
      from: add.from || today.toISOString(),
      to: add.to || aYearFromNow.toISOString(),
      crop_name: add.crop_name || '...',
      boundary: add.boundary || null,
      soil: null,
      crop: null,
      rain_efficacies: [],
      data_sources: [],
      irrigation_events: [],
      product_key: add.product_key || null
    });
  }

  public getCrops(): Observable<any> {
    return this.http.get(this.environment.apiUrl + '/system/crops');
  }

  public getDataSource(payload: IOptionsForDatasources): Observable<any> {
    const lat = payload.centroid.geometry.coordinates[0].toString();
    const long = payload.centroid.geometry.coordinates[1].toString();

    return this.http.get(
      // tslint:disable-next-line:max-line-length
      this.environment.apiUrl + `/user/stations/with?date=${payload.from},${payload.to}&groups=${payload.groups}&near=${lat},${long}&distance=${payload.distance}`
    );

  }

  public removeStation(remove: IAddRemoveStation): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/station/${remove.id}/${remove.key}`;
    return this.http.delete(endpoint);
  }

  public removeCropzone(remove: ICropZone): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/farms/${remove.farm.id}/fields/${remove.field.id}/crop-zones/${remove.id}`;
    return this.http.delete(endpoint);
  }

  public removeField(remove: IField): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/farms/${remove.farm.id}/fields/${remove.id}`;
    return this.http.delete(endpoint);
  }

  public removeFarm(remove: IFarm): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/farms/${remove.id}`;
    return this.http.delete(endpoint);
  }

  public addVirtualStation(add: IAddRemoveVirtualStation): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/station/virtual/${add.key}`;
    return this.http.post(endpoint, {
      product_pos: add.product_pos,
      name: add.name || '',
      position: add.position || {}
    });
  }

  public removeVirtualStation(remove: IAddRemoveVirtualStation): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/station/virtual/${remove.key}`;
    return this.http.delete(endpoint);
  }

  public getNewApiKeys(): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/user/generateHMAC`);
  }

  public getSystemSensors(): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/system/sensors`);
  }

  public getFarmBeatsSettings(): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/farmbeats/connection`);
  }

  public getFarmBeatsAssets(): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/farmbeats/station/list`, {});
  }

  public saveFarmBeatsSettings(payload: IFbSettings): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/farmbeats/connection`, { connectionData: payload });
  }

  public addFarmBeatsAsset(payload: IFbSettings): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/farmbeats/station/set`, payload);
  }

  public resetFarmBeatsSettings(): Observable<any> {
    return this.http.delete(`${this.environment.apiUrl}/farmbeats/connection/reset`);
  }

  public deleteFarmBeatsAsset(payload: IFbSettings): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/farmbeats/station/unset`, payload);
  }

  public getMyJohnDeereAuthData(): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/myjohndeere/token`);
  }

  public deleteMyJohnDeereToken(): Observable<any> {
    return this.http.delete(`${this.environment.apiUrl}/myjohndeere/token`);
  }

  public getMyJohnDeereApiCatalog(): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/myjohndeere?path=`);
  }

  public getMyJohnDeereRessource(path: string): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/myjohndeere?path=${path}`);
  }

  public postMyJohnDeereRessource(path: string, payload: any): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/myjohndeere?path=${path}`, payload);
  }

  public getMyJohnDeereAssets(organizationId: string): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/myjohndeere/organization/${organizationId}/assets`);
  }

  public getMyJohnDeereUserAssets(): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/myjohndeere/assets`);
  }

  public postMyJohnDeereAsset(asset: { asset: IJDAsset, config: IAsset } ): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/myjohndeere/createasset`, asset);
  }

  public deleteMyJohnDeereAsset(assetId: string): Observable<any> {
    return this.http.delete(`${this.environment.apiUrl}/myjohndeere/asset/${assetId}`);
  }

  public saveMyJohnDeereAuthCode(code: string): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/myjohndeere/token`, { code });
  }

  public verifyMyJohnDeereToken(token: string, verifier: string): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/myjohndeere/verifytoken`, { token, verifier });
  }

  public getXarvioAssets(): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/xarvio/stations`);
  }

  public getXarvioSharingKey(): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/xarvio/check-sync`);
  }

  public enableXarvioSync(): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/xarvio/enable-sync`, {});
  }

  public disableXarvioSync(): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/xarvio/disable-sync`, {});
  }

  public syncXarvioAssets(assets: IXarvioStation[]): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/xarvio/stations`, { assets });
  }

  public addDavisUser(data: IDvSettings): Observable<{ returnCode: number }> {
    return this.http.post<{ returnCode: number }>(`${this.environment.apiUrl}/davis/user/register`, data);
  }

  public updateDavisKeys(data: IDvSettings): Observable<{ returnCode: number }> {
    return this.http.post<{ returnCode: number }>(`${this.environment.apiUrl}/davis/user/updatekeys`, data);
  }

  public updateDavisStations(): Observable<{ returnCode: number }> {
    return this.http.post<{ returnCode: number }>(`${this.environment.apiUrl}davis/user/updatestations`, { });
  }

  public getDavisUser(): Observable<any> {
    return this.http.get<IDvSettings>(`${this.environment.apiUrl}/davis/user`);
  }

  public removeDavisUser(): Observable<any> {
    return this.http.delete<{ returnCode: number }>(`${this.environment.apiUrl}/davis/user`);
  }

  public getDavisAssets(): Observable<IDvAssets> {
    return this.http.get<IDvAssets>(`${this.environment.apiUrl}/davis/stations`);
  }

  public setDavisSyncStatus(payload: { SyncStatus: boolean, davisStationID: number }): Observable<any> {
    return this.http.post<{ returnCode: number }>(`${this.environment.apiUrl}/davis/station/setsyncstatus`, payload);
  }

  public getDavisLicenses(): Observable<any> {
    return this.http.get<any>(`${this.environment.apiUrl}/davis/licenses`);
  }

  public addDavisStation(data: { davisStationID: number, licenseID: string }): Observable<any> {
    return this.http.post<{ returnCode: number }>(`${this.environment.apiUrl}/davis/station/activate`, data);
  }

  public upgradeDavisStation(data: { davisStationID: number, licenseID: string }): Observable<any> {
    return this.http.post<{ returnCode: number }>(`${this.environment.apiUrl}/davis/station/upgrade`, data);
  }

  public getDavisSensorMap(data: { davisStationID: number }): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/davis/station/sensormap`, data);
  }

  public setDavisSensorMap(data): Observable<any> {
    return this.http.put(`${this.environment.apiUrl}/davis/station/sensormap`, data);
  }

  public getTrackerData(request: IGetTrackerDataRequest): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/tracker/${request.stationId}/trips/from/${request.from}/to/${request.to}`);
  }

  public getTrackerIO(request: any): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/tracker/${request.stationId}/from/${request.from}/to/${request.to}`);
  }

  public sendUserMessage(message: IMessage): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/user/sendUserMessage`;
    return this.http.post(endpoint, {
      mess: message.message || '',
      topic: message.topic || ''
    });
  }

  public uploadShapeFile(formData: any): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/geo/shp`,
      formData
    );
  }

  public getProductByActivationKey(productKey: string): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/productActivation/${productKey}`);
  }

  public getProductByActivationKeyAndType(productKey: string, productType: string): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/productActivation/${productKey}/${productType}`);
  }

  public getAvailableDiseaseModels(): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/productActivation/availableDiseaseModels`);
  }

  public getAvailableUserStations(productKey: string): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/productActivation/${productKey}/availableUserStations`);
  }

  public getAvailableServiceLicenseStations(): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/productActivation/availableServiceLicenseStations`);
  }

  public getProductActivationLicenseStatus(params: IVerifyLicense): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/user/licenses/checkValidity`, {
      license_group: params.license_group,
      station_name: params.station_name || '',
      reference_date: params.reference_date || ''
    });
  }

  public saveVirtualStationActivation(activation: IVirtualStationActivation): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/station/virtual/${activation.product_key}`;
    const {product_pos, starting_date, license_duration} = activation;
    return this.http.post(endpoint, {
      product_pos,
      starting_date,
      license_duration,
      name: activation.name || '',
      position: activation.position || {}
    });
  }

  public saveDiseaseModelActivation(activation: IDiseaseModelActivation): Observable<any> {
    return this.http.post(
      `${this.environment.apiUrl}/productActivation/${activation.product_key}/diseaseModel`,
      activation
    );
  }

  public saveForecastActivation(activation: IForecastActivation): Observable<any> {
    return this.http.post(
      `${this.environment.apiUrl}/productActivation/${activation.product_key}/forecast`,
      activation
    );
  }

  public saveServiceLicenseActivation(activation: IServiceLicenseActivation): Observable<any> {
    return this.http.post(
      `${this.environment.apiUrl}/productActivation/${activation.product_key}/serviceLicense`,
      activation
    );
  }

  public getCropZoneLaiStats(cropZoneId: string, resolution: number): Observable<ICropZoneLaiStats[]> {
    return this.http.post(
      `${this.environment.apiUrl}/crop-zones/${cropZoneId}/sentinel-statistics`,
      { resolution: resolution, type: 'LAI' }
    ) as Observable<ICropZoneLaiStats[]>;
  }

  public getCropZoneNDVIStats(cropZoneId: string, resolution: number): Observable<ICropZoneNdviStats[]> {
    return this.http.post(
      `${this.environment.apiUrl}/crop-zones/${cropZoneId}/sentinel-statistics`,
      { resolution: resolution, type: 'NDVI' }
    ) as Observable<ICropZoneNdviStats[]>;
  }

  public getFittingCurve(cropZoneId: string, data: any, type: string): Observable<any[]> {
    const date = new Date();

    return this.http.post(
      `${this.environment.apiUrl}/crop-zones/${cropZoneId}/fitting-curve`,
      { data: data, timeZoneDifference: date.getTimezoneOffset(), type: type.toUpperCase() }
    ) as Observable<any[]>;
  }

  // tslint:disable-next-line:max-line-length
  public getCropZoneLaiVals(cropZoneId: string, dates: string[], resolution: number, getBestResolution: boolean): Observable<ICropZoneLaiVals[]> {
    dates = dates.filter(date => date !== undefined && date !== null);
    return this.http.post(
      `${this.environment.apiUrl}/crop-zones/${cropZoneId}/sentinel-values`,
      { dates, resolution, getBestResolution, type: 'LAI' }
    ) as Observable<ICropZoneLaiVals[]>;
  }

  // tslint:disable-next-line:max-line-length
  public getCropZoneNDVIVals(cropZoneId: string, dates: string[], resolution: number, getBestResolution: boolean): Observable<ICropZoneNdviVals[]> {
    dates = dates.filter(date => date !== undefined && date !== null);
    return this.http.post(
      `${this.environment.apiUrl}/crop-zones/${cropZoneId}/sentinel-values`,
      { dates, resolution, getBestResolution, type: 'NDVI' }
    ) as Observable<ICropZoneNdviVals[]>;
  }

  public saveDavisIngestActivation(activation: ILicenseActivationItem): Observable<any> {
    return this.http.post(
      `${this.environment.apiUrl}/productActivation/${activation.product_key}/davisIngest`,
      activation
    );
  }

  public saveFarmViewActivation(activation: ILicenseActivationItem): Observable<any> {
    return this.http.post(
      `${this.environment.apiUrl}/productActivation/${activation.product_key}/farmView`,
      activation
    );
  }

  public saveIScoutMobileActivation(activation: ILicenseActivationItem): Observable<any> {
    return this.http.post(
      `${this.environment.apiUrl}/productActivation/${activation.product_key}/iScoutMobile`,
      activation
    );
  }

  public getProductLicense(params: { type: string, mode: string }): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/user/productLicenses?type=${params.type}&mode=${params.mode}`);
  }

  public getHydroponics(request: IGetHydroponicsRequest): Observable<any> {
    const url = `${this.environment.apiUrl}/hydroponics/${request.stationId}/${request.from}/${request.to}/${request.timePeriod}`;
    return this.http.post(
      url, {
        flowDripper: request.dripperData.flowDripper,
        numberDripper: request.dripperData.numberDripper
      }
    );
  }

  public getFrostProtection(request: IFrostProtectionRequest): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${this.environment.apiUrl}/frost-monitoring/${request.stationId}/${request.timePeriod}/from/${request.from}/to/${request.to}`);
  }

  public getFrostProtectionChartsExport(request: any): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/fc/charts`, request);
  }

  public closeAccount(): Observable<any> {
    const url = `${this.environment.apiUrl}/user/web/account-close`;
    return this.http.post(
      url, null
    );
  }

  public getUsersClosedModulesTexts(): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/user/getClosedModulesTextsList`);
  }

  public updateClosedModulesTexts(closedIDs: Array<string>): Observable<any> {
    return this.http.post(`${this.environment.apiUrl}/user/updateClosedModulesTextsList`, closedIDs);
  }

  public getUsersClosedTexts(): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/user/getDashboardTextsList`);
  }

  public createUsersClosedTexts(list: Array<string>): Observable<any> {
    const url = `${this.environment.apiUrl}/user/createDashboardTextsList`;
    return this.http.post(
      url, list
    );
  }

  public getAccumulatorToolThresholds(): Observable<any> {
    const url = `${this.environment.apiUrl}/user/getAccumulatorToolThresholds`;
    return this.http.get(url);
  }

  public updateAccumulatorThresholds(thresholds: IDegreeAndChillingThresholds): Observable<any> {
    const url = `${this.environment.apiUrl}/user/updateAccumulatorToolThresholds`;
    return this.http.post(url, thresholds);
  }

  public getUltrasonicSettings(stationId: string): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/station/${stationId}/config/fwt`);
  }

  public saveUltrasonicSettings(data: IUltrasonicValue): Observable<any> {
    const endpoint = `${this.environment.apiUrl}/station/${data.stationId}/config/fwt`;
    return this.http.post(endpoint, {
      elevationValue: data.elevationValue,
      unit: data.unit,
      from: data.from,
      to: data.to
    });
  }

  public convertValue(request: IConversionRequest): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/system/unit-conversion/${request.group}/${request.unitFrom}/${request.unitTo}/${request.value}`);
  }
}
