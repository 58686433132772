import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { REMOVE_CROPZONE } from '../../../../../shared/constants';
import { ModalService } from '../../../../../shared/modal/services/modal.service';
import { removeField } from '../../../../actions/fields';
import { IField } from '../../../../models/fields';
import { selectCropzones } from '../../../../reducers';
import * as fromCropzones from '../../../../reducers/cropzones';
import * as fromFields from '../../../../reducers/fields';

@Component({
    selector: 'app-remove-field-confirmation',
    templateUrl: './remove-field-confirmation.component.html',
    styleUrls: ['./remove-field-confirmation.component.scss']
})
export class RemoveFieldConfirmationComponent implements OnInit {
    @Input()
    public field : IField;

    @Output()
    public resetModal: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        private fieldStore: Store<fromFields.IFields>,
        private modalService: ModalService,
        private cropzoneStore: Store<fromCropzones.ICropZones>
        ) { }

    public ngOnInit(): void {}

    public delete(): void {
        const cropzones$ = this.cropzoneStore.pipe(
            select(selectCropzones)
        );

        cropzones$.pipe(
            map(cropzones => cropzones.filter(cropzone => cropzone.field.id === this.field.id))
        ).subscribe((cropzones) => {
            if (cropzones.length > 0) {
                this.fieldStore.dispatch(removeField(this.field, cropzones));
                this.resetModal.emit('cropzone');
                this.modalService.closeModal(REMOVE_CROPZONE);
            }
        });
    }

    public goBack(): void {
        this.resetModal.emit('field');
    }
}
