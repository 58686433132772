import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-clean-precipitation-modal',
  templateUrl: './clean-precipitation-modal.component.html',
  styleUrls: ['./clean-precipitation-modal.component.scss']
})
export class CleanPrecipitationModalComponent {

  @Output()
  private cancelEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  private confirmEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Input() public dateRange : any;

  public handleCancel(): void {
    this.cancelEmitter.emit();
  }

  public handleConfirm(): void {
    this.confirmEmitter.emit();
  }
}
