<app-form-layout>
  <app-user-licenses-card-header [title]="header"></app-user-licenses-card-header>

  <div class="user-licenses-list-table">
    <ag-grid-angular
      class="ag-theme-bootstrap"
      [gridOptions]="gridOptions"
      [rowData]="activeUserLicenses"
    ></ag-grid-angular>
  </div>
</app-form-layout>
