import { IActionWithPayload } from '../../../core/models/actionWithPayload';

export const enum ActionTypes {
  GET_STATION_SENSORS = '[AccumulatorTool] GET_STATION_SENSORS',
  SET_STATION_SENSORS = '[AccumulatorTool] SET_ STATION_SENSORS'
}

export function getStationSensors(stationId: string): IActionWithPayload {
  return {type: ActionTypes.GET_STATION_SENSORS, payload: stationId};
}

export function setStationSensors(sensors: Array<any>): IActionWithPayload {
  return { type: ActionTypes.SET_STATION_SENSORS, payload: sensors};
}
