<app-chart-toolbar-layout>
  <form class="selected-image-toolbar" [formGroup]="form">

    <div class="selected-image-toolbar__left">
      <ng-content></ng-content>

      <div class="selected-image-toolbar__left__item">
        <app-custom-button (click)="displayPreviousPicture()">
          <i class="fa fa-fw fa-chevron-circle-left"></i>
          {{'Previous' | translate}}
        </app-custom-button>
      </div>

      <div class="selected-image-toolbar__left__item">
        <app-custom-button (click)="displayNextPicture()">
          {{'Next' | translate}}
          <i class="fa fa-fw fa-chevron-circle-right"></i>
        </app-custom-button>
      </div>
    </div>

    <div class="selected-image-toolbar__right">
      <div class="selected-image-toolbar__right__item" *ngIf="showRemovedMeasurementsEnabled">
        <app-custom-button [color]="areMeasurementsActiveControl.value ? 'green' :  'white'"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="getMeasurementsToggleTooltip() | translate"
                           (click)="toggleMeasurements()">
          <i class="fa fa-fw fa-object-ungroup"></i>
        </app-custom-button>
      </div>

      <div class="selected-image-toolbar__right__item">
        <app-custom-button [color]="'white'"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'Download image' | translate"
                           (click)="downloadImage()">
          <i class="fa fa-download"></i>
        </app-custom-button>
      </div>

      <div class="selected-image-toolbar__right__item" *ngIf="hasWritePermission && clearMeasurementsEnabled">
        <app-custom-button [color]="'red'"
                           [content]="'Clear Image'"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'Remove all measurements from image' | translate"
                           (click)="clear()">
          <i class="fa fa-fw fa-eraser"></i>
        </app-custom-button>
      </div>

      <div class="selected-image-toolbar__right__item" *ngIf="hasWritePermission">
        <app-custom-button [color]="'red'"
                           [content]="'Delete Image'"
                           (click)="openModal()">
          <i class="fa fa-fw fa-trash"></i>
        </app-custom-button>
      </div>

      <div class="selected-image-toolbar__right__item" *ngIf="hasWritePermission">
        <app-custom-button [color]="'green'"
                           [content]="'Save'"
                           (click)="save()">
          <i class="fa fa-fw fa-floppy-o"></i>
        </app-custom-button>
      </div>
    </div>
  </form>
</app-chart-toolbar-layout>

<app-modal [modalId]="modalId">
  <app-detailed-image-delete-modal
    (cancelEmitter)="closeModal(modalId)"
    (confirmEmitter)="deleteImage()"
  ></app-detailed-image-delete-modal>
</app-modal>

<app-modal [modalId]="clearMeasurementsModalId">
  <app-detailed-image-clear-measurements-modal
    (cancelEmitter)="closeModal(clearMeasurementsModalId)"
    (confirmEmitter)="clearConfirm()"
  ></app-detailed-image-clear-measurements-modal>
</app-modal>
