import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { ApiCallService } from '../../../services/api/api-call.service';
import { from, Observable, of } from 'rxjs';
import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import {
  ActionTypes,
  getAssets,
  setAssets,
  setLicenses,
  setSensorMap,
  setSettings,
  updateSync
} from '../actions/davis';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { setNotify } from '../../../core/actions/notify';
import { IDvAssets, IDvSettings } from '../models/davis';
import { Action } from '@ngrx/store';

@Injectable()
export class Effects {

  constructor(
    private api: ApiCallService,
    private actions$: Actions
  ) {

  }

  @Effect()
  public getDavisSettings$: Observable<IAction> = this.actions$.pipe(
    ofType(ActionTypes.LOAD_SETTINGS),
    mergeMap(() => this.api.getDavisUser().pipe(
      map((response: IDvSettings) => {
        if (response.returnCode === 0) {
          return setSettings(response);
        } else if (response.returnCode === -1) {
          return setNotify('User\'s data was not found in the WeatherLink collection');
        } else {
          return setNotify('WeatherLink API is currently unavailable');
        }
      }),
      catchError(() => of(setNotify('WeatherLink API is currently unavailable')))
    ))
  );

  @Effect()
  public saveDavisSettings$: Observable<IAction | IAction[]> = this.actions$.pipe(
    ofType(ActionTypes.SAVE_SETTINGS),
    mergeMap((action: IAction) =>
      this.api.addDavisUser(action.payload).pipe(
        switchMap((response: { returnCode: number }) => {
          if (response.returnCode === 0) {
            return from([
              setSettings(action.payload),
              setNotify('WeatherLink settings were successfully updated')
            ]);
          } else if (response.returnCode === -3) {
            return of(setNotify('WeatherLink API is currently unavailable'));
          } else if (response.returnCode === -4) {
            return of(setNotify('WeatherLink settings are not valid'));
          } else {
            return of(setNotify('WeatherLink API is currently unavailable'));
          }
        }),
        catchError(() => of(setNotify('WeatherLink API is currently unavailable')))
      )
    )
  );

  @Effect()
  public deleteDavisSettings$: Observable<IAction> = this.actions$.pipe(
    ofType(ActionTypes.DELETE_SETTINGS),
    mergeMap(() => this.api.removeDavisUser().pipe(
      mergeMap((response: { returnCode: number }) => {
        if (response.returnCode === 0) {
          return from([
            setNotify('WeatherLink settings were successfully removed')
          ]);
        } else if (response.returnCode === -1) {
          return of(setNotify('WeatherLink settings were not found'));
        } else {
          return of(setNotify('WeatherLink API is currently unavailable'));
        }
      }),
      catchError(() => of(setNotify('WeatherLink API is currently unavailable')))
      )
    )
  );

  @Effect()
  public getDavisAssets$: Observable<IAction> = this.actions$.pipe(
    ofType(ActionTypes.GET_ASSETS),
    switchMap(() => this.api.getDavisAssets().pipe(
      map((response: IDvAssets) => {
        if (response.returnCode === 0) {
          return setAssets(response);
        } else {
          return setNotify('WeatherLink API is currently unavailable');
        }
      }),
      catchError(() => of(setNotify('WeatherLink API is currently unavailable')))
    ))
  );

  @Effect()
  public updateSyncStatus$ = this.actions$.pipe(
    ofType(ActionTypes.UPDATE_SYNC),
    mergeMap((action: IAction) =>
      this.api.setDavisSyncStatus(action.payload).pipe(
        mergeMap((response: { returnCode: number }) => {
          if (response.returnCode === -2) {
            return [setNotify('WeatherLink settings are not valid')];
          } else if (response.returnCode === 0) {
            return [
              getAssets(),
              setNotify('WeatherLink settings were successfully updated')
            ];
          }
        }),
        catchError(() => of(setNotify('WeatherLink API is currently unavailable')))
      )
    )
  );

  @Effect()
  public activateStation$ = this.actions$.pipe(
    ofType(ActionTypes.ACTIVATE_STATION),
    mergeMap<IAction, any>(({ payload }) =>
      this.api.addDavisStation(payload).pipe(
        mergeMap((response: { returnCode: number }) => {
          if (response.returnCode === 0) {
            return [
              updateSync(payload.davisStationID, true),
              setNotify('WeatherLink station was activated')
            ];
          } else {
            return [setNotify('WeatherLink station couldn\'t be activated')];
          }
        }),
        catchError(() => of(setNotify('WeatherLink API is currently unavailable')))
      )
    )
  );

  @Effect()
  public getLicenseList$: Observable<IAction> = this.actions$.pipe(
    ofType(ActionTypes.LOAD_LICENSES),
    mergeMap(() =>
      this.api.getDavisLicenses().pipe(
        map((response: any) => {
          if (response.returnCode === 0) {
            return setLicenses(response);
          } else if ( response.returnCode === -1) {
            return setNotify('No license found');
          } else {
            return setNotify('WeatherLink API is currently unavailable');
          }
        })
      )
    )
  );
  @Effect()
  public getSensorMap$: Observable<IAction> = this.actions$.pipe(
    ofType(ActionTypes.LOAD_SENSOR_MAP),
    mergeMap((action: IAction) =>
      this.api.getDavisSensorMap(action.payload).pipe(
        map((response: any) => {
          if (response.returnCode === 0) {
            return setSensorMap(response);
          } else {
            return setNotify('WeatherLink API is currently unavailable');
          }
        })
      )
    )
  );

  @Effect()
  public saveSensorMap$: Observable<IAction> = this.actions$.pipe(
    ofType(ActionTypes.SAVE_SENSOR_MAP),
    mergeMap((action: IAction) =>
      this.api.setDavisSensorMap(action.payload).pipe(
        map((response: any) => {
          if (response.returnCode === 0) {
            return setNotify('WeatherLink settings were successfully updated');
          } else {
            return setNotify('WeatherLink settings are not valid');
          }
        })
      )
    )
  );
}
