import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { IAnimalProductionState, IAnimalProductionWarningMessage } from '../models/animal-production.models';
import { IFromTo } from '../../tracker/models/tracker.models';
import { IWarningMessage } from '../../work-planning/models/work-planning.models';
import * as moment from 'moment';
import { setAnimalProductionWarning } from '../actions';

@Injectable({
  providedIn: 'root'
})
export class AnimalProductionService {
  private pleaseNoteString: string;
  private daysToRenew: string;
  private licenseExpiredOn: string;
  private toRenew: string;

  constructor(
    private translate: TranslateService,
    private store: Store<IAnimalProductionState>
  ) {
    this.getTranslatedStrings();
  }

  public setWarningMessage(licenses: Array<IFromTo>): boolean {
    if (licenses?.length > 0) {
      const warningObject: IAnimalProductionWarningMessage = {
        message: null,
        icon: true
      };

      let validLicense: boolean = true;

      const maximumDates: Array<any> = licenses.map((license) => moment(license.to));
      const licenseEndDate: moment.Moment = moment.max(maximumDates);
      const now: moment.Moment = moment();
      const daysUntilEndLicense = licenseEndDate.diff(now, 'days');

      if (daysUntilEndLicense <= 0) {
        warningObject.message = this.licenseExpiredOn + ' ' + licenseEndDate.format('DD-MM-YYYY') + '. ' + this.toRenew;
        validLicense = false;
      } else if (daysUntilEndLicense <= 10) {
        warningObject.message = this.pleaseNoteString + ' ' + daysUntilEndLicense + ' ' + this.daysToRenew;
      }

      this.store.dispatch(setAnimalProductionWarning(warningObject));
      return validLicense;
    } else {
      return false;
    }
  }


  private getTranslatedStrings(): void {
    this.translate.get('Your license will expire in').pipe(take(1)).subscribe((translatedText: string) => {
      this.pleaseNoteString = translatedText;
    });
    this.translate.get('days. To renew it, please contact your METOS salesperson.').pipe(take(1)).subscribe((translatedText: string) => {
      this.daysToRenew = translatedText;
    });
    this.translate.get('Your license has expired on').pipe(take(1)).subscribe((translatedText: string) => {
      this.licenseExpiredOn = translatedText;
    });
    this.translate.get('To renew it, please contact your METOS salesperson.').pipe(take(1)).subscribe((translatedText: string) => {
      this.toRenew = translatedText;
    });
  }
}
