import { Component, Input, OnInit } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map, tap, withLatestFrom } from 'rxjs/operators';
import { setNotify } from '../../../../core/actions/notify';
import { ISettings } from '../../../../core/models/account';
import { IState, selectCalibrationSettings, selectSettings, selectSystemSensors } from '../../../../core/reducers';
import { INotifyState } from '../../../../core/reducers/notify';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { ISaveSensor } from '../../../sensors-and-nodes/models/sensors-and-nodes';
import { getSoilMoistureSensors } from '../../actions';
import { ISensorColumns } from '../../models';
import { activitySettings, soilMoistureSensors, status } from '../../selectors';
import { IState as ISoilMoistureState, IStateStatus } from '../../states';
import { isEmpty, validate, validator } from '../../util/settings-validator';
import { toColumns } from '../../util/to-sensor-columns';

@Component({
  selector: 'app-soil-moisture-sensor-settings',
  templateUrl: './soil-moisture-sensor-settings.component.html',
  styleUrls: ['./soil-moisture-sensor-settings.component.scss']
})
export class SoilMoistureSensorSettingsComponent implements OnInit {

  @Input()
  public stationId        : string;

  public hide$            : Observable<boolean>;
  public status$          : Observable<IStateStatus>;
  public sensors$         : Observable<ISensorColumns>;
  public userSettings$    : Observable<ISettings>;
  public form             : FormGroup = new FormGroup({});

  constructor(
    private soilMoisture: Store<ISoilMoistureState>,
    private account: Store<IState>,
    private formBuilder: FormBuilder,
    private api: ApiCallService,
    private notify: Store<INotifyState>
  ) { }

  public ngOnInit(): void {

    this.hide$ = combineLatest([
      this.soilMoisture.pipe(
        select(activitySettings, 'isSensorSettingsActive'),
        debounceTime(100)
      ),
      this.soilMoisture.pipe(select(soilMoistureSensors))
    ]).pipe(map(([isActive, sensors]) => !isActive || sensors.length === 0));

    this.soilMoisture.pipe(
      select(activitySettings, 'isSensorSettingsActive'),
      debounceTime(100)
    );

    this.status$ = this.soilMoisture.pipe(
      select(status),
      debounceTime(100)
    );

    this.sensors$ = this.soilMoisture.pipe(
      select(soilMoistureSensors),
      withLatestFrom(
        this.account.pipe(select(selectCalibrationSettings)),
        this.account.pipe(select(selectSystemSensors))
      ),
      map(([sensors, calibrations, systemSensors]) => sensors.reduce(toColumns(calibrations, systemSensors), {})),
      tap((columns: ISensorColumns) => {
        this.form = this.buildForm(columns);
      })
    );

    this.userSettings$ = this.account.pipe(
      select(selectSettings)
    );

  }

  public saveSettings(): void {

    const settings = this.buildPayload();

    if (this.stationId && settings.length > 0) {
      this.api.updateStationSensors(this.stationId, settings).pipe(
        tap(() => this.soilMoisture.dispatch(getSoilMoistureSensors(this.stationId)))
      ).subscribe(
        () => this.notify.dispatch(setNotify('The sensor\'s settings were updated successfully')),
        () => this.notify.dispatch(setNotify('The settings were not saved, due to the error'))
      );
    }

  }

  private buildForm(columns: ISensorColumns): FormGroup {

    return this.formBuilder.group(Object.keys(columns).reduce((groups, id) => {
      groups[id] = this.formBuilder.group(columns[id].reduce((group, setting) => {
        group[setting.channel] = this.formBuilder.group(setting);
        return group;
      }, {}));
      return groups;
    }, {}), { validator } as AbstractControlOptions);

  }

  private buildPayload(): ISaveSensor[] {

    const formValue = this.form.getRawValue();

    return Object.keys(formValue).reduce((settings: ISaveSensor[], id) => {

      Object.keys(formValue[id]).forEach(channel => {
        const group = formValue[id][channel];
        const setting = Object.keys(group).filter(key => [
          'refill_point', 'field_capacity', 'depth', 'calibration_id'
        ].includes(key)).reduce((obj, key) => {
          const value = group[key];
          if (isEmpty(value)) {
            obj[key] = false;
          } else {
            obj[key] = Number.isFinite(+value) ? +value : value;
          }
          return obj;
        }, {});

        if (validate(this.form.get(id).get(channel).value)) {
          settings.push({
            ...setting,
            channel: group.channel,
            code: group.code,
            unit: group.unit !== group.unit_default ? group.unit : undefined,
            name: group.custom_name || undefined,
            color: group.color || undefined
          });
        }
      });

      return settings;

    }, []);

  }

}
