import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { IWeatherForecastState } from '../../models/models';
import { selectWeatherForecastModeName } from '../../reducers';

@Component({
  selector: 'app-weather-forecast-header',
  templateUrl: './weather-forecast-header.component.html',
  styleUrls: ['./weather-forecast-header.component.scss']
})
export class WeatherForecastHeaderComponent implements OnInit, OnDestroy {
  public modeName$: Observable<string>;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private weatherForecastStore: Store<IWeatherForecastState>) { }

  public ngOnInit(): void {
    this.modeName$ = this.weatherForecastStore.pipe(select(selectWeatherForecastModeName));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
