import { Component, Input, OnInit } from '@angular/core';
import { INewModulesTextObj } from '../../../models/account';
import { Store, select } from '@ngrx/store';
import { IAccount } from '../../../reducers/account';
import { deepClone } from '../../../../shared/utils/deepClone';
import { setUsersClosedModulesTexts, updateUsersClosedModulesTexts } from '../../../actions/account';
import { selectClosedModulesTexts } from '../../../reducers';

@Component({
  selector: 'app-left-bar-new-text',
  templateUrl: './left-bar-new-text.component.html',
  styleUrls: ['./left-bar-new-text.component.scss']
})
export class LeftBarNewTextComponent implements OnInit {
  @Input()
  public type: string;
  @Input()
  public module: string;
  @Input()
  public moduleTexts: Array<INewModulesTextObj>;

  public imageUrl: string = '../../../../../assets/img/fc-new.png';

  private newModulesTexts: Array<INewModulesTextObj>;
  private closedTexts: Array<string>;

  constructor(
    private accountStore: Store<IAccount>
  ) { }

  public ngOnInit(): void {
    this.accountStore.pipe(
      select(selectClosedModulesTexts)
    ).subscribe((closedTexts: Array<string>) => {
      this.newModulesTexts = this.moduleTexts.filter((obj) => !closedTexts.includes(obj.id));
      this.closedTexts = closedTexts;
    });
  }

  public hideContainer(): void {
    document.getElementById(this.module).style.visibility = 'hidden';
  }

  public isTextForIcon(): boolean {
    return this.newModulesTexts ? this.newModulesTexts.some((textObj) => textObj.module === this.module) : false;
  }

  public showText(): void {
    document.getElementById(this.module).style.visibility = 'visible';
  }

  public closeText(): void {
    if (this.newModulesTexts && this.newModulesTexts.some((obj) => obj.module === this.module)) {
      const selectedModulesText = this.newModulesTexts.filter((obj) => obj.module === this.module)[0];
      if (!this.closedTexts.some((id) => id === selectedModulesText.id)) {
        this.closedTexts.push(selectedModulesText.id);
        this.accountStore.dispatch(setUsersClosedModulesTexts(deepClone(this.closedTexts)));
        this.accountStore.dispatch(updateUsersClosedModulesTexts(this.closedTexts));
      }
    }
  }

  public getText(): string {
    if (this.newModulesTexts && this.newModulesTexts.length > 0 && this.newModulesTexts.some((obj) => obj.module === this.module)) {
      return this.newModulesTexts.filter((textObj) => textObj.module === this.module)[0].text;
    }
    return '';
  }
}
