import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import {
  IUltrasonicSensor,
  IUltrasonicSetting,
  IUltrasonicSettingsCurrentValue,
  IUltrasonicValue
} from '../models/models';
import { IConversionRequest } from '../../../shared/interfaces';

export const enum ActionTypes {
  GET_ULTRASONIC_SENSORS    = '[UltrasonicDistanceSettings] GET_ULTRASONIC_SENSORS',
  SET_ULTRASONIC_SENSORS    = '[UltrasonicDistanceSettings] SET_ULTRASONIC_SENSORS',
  SAVE_ULTRASONIC_SETTINGS  = '[UltrasonicDistanceSettings] SAVE_ULTRASONIC_SETTINGS',
  GET_ULTRASONIC_SETTINGS   = '[UltrasonicDistanceSettings] GET_ULTRASONIC_SETTINGS',
  SET_ULTRASONIC_SETTINGS   = '[UltrasonicDistanceSettings] SET_ULTRASONIC_SETTINGS',
  CONVERT_DEFAULT_VALUE     = '[UltrasonicDistanceSettings] CONVERT_DEFAULT_VALUE',
  SET_CURRENT_VALUE         = '[UltrasonicDistanceSettings] SET_CURRENT_VALUE',
  GET_ULTRASONIC_DATA       = '[UltrasonicDistanceSettings] GET_ULTRASONIC_DATA',
  SET_ULTRASONIC_LOADING    = '[UltrasonicDistanceSettings] SET_ULTRASONIC_LOADING',
  SET_ULTRASONIC_ERROR      = '[UltrasonicDistanceSettings] SET_ULTRASONIC_ERROR'
}

export function getUltrasonicSensors(stationId: string): IActionWithPayload {
  return {type: ActionTypes.GET_ULTRASONIC_SENSORS, payload: stationId};
}

export function setUltrasonicSensors(sensors: Array<IUltrasonicSensor>): IActionWithPayload {
  return { type: ActionTypes.SET_ULTRASONIC_SENSORS, payload: sensors};
}

export function saveUltrasonicSettings(data: IUltrasonicValue): IActionWithPayload {
  return {type: ActionTypes.SAVE_ULTRASONIC_SETTINGS, payload: data};
}

export function getUltrasonicSettings(stationId: string): IActionWithPayload {
  return {type: ActionTypes.GET_ULTRASONIC_SETTINGS, payload: stationId};
}

export function setUltrasonicSettings(ultrasonicSettings: Array<IUltrasonicSetting>): IActionWithPayload {
  return {type: ActionTypes.SET_ULTRASONIC_SETTINGS, payload: ultrasonicSettings};
}

export function convertDefaultValue(data: IConversionRequest): IActionWithPayload {
  return {type: ActionTypes.CONVERT_DEFAULT_VALUE, payload: data};
}

export function setCurrentValue(currentValue: IUltrasonicSettingsCurrentValue): IActionWithPayload {
  return {type: ActionTypes.SET_CURRENT_VALUE, payload: currentValue};
}

export function setUltrasonicLoading(isLoading: boolean): IActionWithPayload {
  return { type: ActionTypes.SET_ULTRASONIC_LOADING, payload: isLoading};
}

export function setUltrasonicError(isError: boolean): IActionWithPayload {
  return { type: ActionTypes.SET_ULTRASONIC_ERROR, payload: isError};
}

export function getUltrasonicData(stationId: string): IActionWithPayload {
  return { type: ActionTypes.GET_ULTRASONIC_DATA, payload: stationId};
}






