import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {ICameraSettingsState} from '../reducers/cameraSettings';
import {
  IGetCameraImagePayload,
  ISaveCamsPayload,
  ISetCameraImagePayload,
  ISetOneCamPayload,
  ISetSpotsPayload
} from '../models/station-config.models';
import { IStation } from '../../../core/models/stations';

export const enum CameraSettingsActionType {
  TOGGLE_CAMERA_SETTINGS_INVALID = '[Config] TOGGLE_CAMERA_SETTINGS_INVALID',
  OFF_CAMERA_SETTINGS_MESSAGE = '[Config] OFF_CAMERA_SETTINGS_MESSAGE',
  SET_CAMERA_SETTINGS = '[Config] SET_CAMERA_SETTINGS',
  SET_CAMERA_IMAGE = '[Config] SET_CAMERA_IMAGE',
  GET_CAMERA_IMAGE = '[Config] GET_CAMERA_IMAGE',
  SET_SPOTS = '[Config] SET_SPOTS',
  SET_ONE_CAM_SETTINGS = '[Config] SET_ONE_CAM_SETTINGS',
  SAVE_CAM_SETTINGS = '[Config] SAVE_CAM_SETTINGS',
  SAVE_CAMERA_LED_FLASH = '[Config] SAVE_CAMERA_LED_FLASH'
}

export function saveCamSettings(save: ISaveCamsPayload): IActionWithPayload {
  return { type: CameraSettingsActionType.SAVE_CAM_SETTINGS, payload: save };
}

export function setOneCamSettings(cam: ISetOneCamPayload): IActionWithPayload {
  return { type: CameraSettingsActionType.SET_ONE_CAM_SETTINGS, payload: cam };
}

export function setSpots(spots: ISetSpotsPayload): IActionWithPayload {
  return { type: CameraSettingsActionType.SET_SPOTS, payload: spots };
}

export function getCameraImage(image: IGetCameraImagePayload): IActionWithPayload {
  return { type: CameraSettingsActionType.GET_CAMERA_IMAGE, payload: image };
}

export function setCameraImage(image: ISetCameraImagePayload): IActionWithPayload {
  return { type: CameraSettingsActionType.SET_CAMERA_IMAGE, payload: image };
}

export function setCameraSettings(state: ICameraSettingsState): IActionWithPayload {
  return { type: CameraSettingsActionType.SET_CAMERA_SETTINGS, payload: state };
}

export function toggleCameraSettingsInvalid(isInvalid: boolean): IActionWithPayload {
  return { type: CameraSettingsActionType.TOGGLE_CAMERA_SETTINGS_INVALID, payload: isInvalid };
}

export function offCameraSettingsMessage(): IActionWithPayload {
  return { type: CameraSettingsActionType.OFF_CAMERA_SETTINGS_MESSAGE, payload: null };
}

export function saveCameraLEDFLash(station: IStation, flashValue: number): IActionWithPayload {
  return { type: CameraSettingsActionType.SAVE_CAMERA_LED_FLASH, payload: {station: station, flash: flashValue }};
}
