import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IStationSampleState } from '../models/station-sample.model';

export const featureKey = 'station-sample';

export const stationSample = createFeatureSelector<IStationSampleState>(featureKey);

export const selectSamples = createSelector(
  stationSample,
  state => state.samples
);

export const selectSettings = createSelector(
  stationSample,
  state => state.settings
);

export const selectActiveSample = createSelector(
  stationSample,
  state => state.activeSample
);

export const selectDevice = createSelector(
  stationSample,
  state => state.device
);

export const selectActiveMeter = createSelector(
  stationSample,
  state => state.activeMeter
);

export const selectActiveTitle = createSelector(
  stationSample,
  state => state.activeTitle
);
