import { sensors } from './../../user-api-services/selectors/my-john-deere';
import { IActionWithPayload } from './../../../core/models/actionWithPayload';
import { SensorsActionTypes } from '../actions/sensors';
import { group } from '@angular/animations';

export interface ISensorsState {
    sensors: Array<any>;
    filteredSensors: Array<any>;
}

export const initialState: ISensorsState = {
    sensors: [],
    filteredSensors: []
};

export function reducer(state: ISensorsState = initialState, action: IActionWithPayload): ISensorsState {
    switch (action.type) {
        case SensorsActionTypes.SET_SENSORS:
            let filteredSensors: Array<any> = [];

            if (action.payload.node.kind === 'mac') {
                // tslint:disable-next-line:max-line-length
                filteredSensors = action.payload.array.filter(element => element.group === 25 && element.mac === action.payload.node.node && element.serial === 'X');
            } else if (action.payload.node.kind === 'serial') {
                // tslint:disable-next-line
                filteredSensors = action.payload.array.filter(element => element.group === 25 && element.serial.toString() === action.payload.node.node.toString() && element.mac === 'X');
            } else if (action.payload.node.kind === 'onStation') {
                // tslint:disable-next-line
                filteredSensors = action.payload.array.filter(element => element.group === 25 && element.serial === 'X' && element.mac === 'X');
            }

            filteredSensors = filteredSensors.sort((a, b) => parseInt(a.ch, 10) - parseInt(b.ch, 10));

            const object = {
                stationId: action.payload.stationId,
                node: action.payload.node,
                sensors: filteredSensors
            };

            const sensorState = state.sensors;
            // tslint:disable-next-line
            if (sensorState.some(sensor => sensor.stationId === action.payload.stationId && sensor.node.node === action.payload.node.node) === false) {
                sensorState.push(object);
            }

            return <ISensorsState>{
                ...state,
                sensors: sensorState
            };
        case SensorsActionTypes.SET_FILTERED_SENSORS:
            return <ISensorsState>{
                ...state,
                filteredSensors: action.payload
            };
        case SensorsActionTypes.UPDATE_SENSORS:
            const sensorsList = state.sensors;

            let indexSensorToChange: any;
            sensorsList.forEach((sensor, index) => {
                if (sensor.node.node.toString() === action.payload.node && sensor.stationId === action.payload.smStationId) {
                    indexSensorToChange = index;
                }
            });

            sensorsList[indexSensorToChange].sensors.forEach((sensor, indexx) => {
                action.payload.array.forEach(testZ => {

                    if (sensor.code === testZ.controls['code'].value && sensor.ch === testZ.controls['channel'].value) {
                        sensorsList[indexSensorToChange].sensors[indexx].depth = testZ.controls['depth'].value;
                        sensorsList[indexSensorToChange].sensors[indexx].field_capacity = testZ.controls['field_capacity'].value;
                        sensorsList[indexSensorToChange].sensors[indexx].refill_point = testZ.controls['refill_point'].value;
                        sensorsList[indexSensorToChange].sensors[indexx].unit = testZ.controls['unit'].value;

                        if (testZ.controls['calibration_id'].value !== '') {
                            sensorsList[indexSensorToChange].sensors[indexx].calibration_id = testZ.controls['calibration_id'].value;
                        }
                    }
                });
            });

            return <ISensorsState>{
                ...state,
                sensors: sensorsList
            };
        case SensorsActionTypes.INIT_SENSORS_FROM_DATASOURCE:
            return <ISensorsState>{
                ...state,
                sensors: action.payload
            };
        case SensorsActionTypes.RESET_SENSORS:
            return <ISensorsState>{
                ...state,
                sensors: []
            };
        default:
            return state;
    }
}
