import { IActionWithPayload, IActionWithPayloadRemoveField } from './../models/actionWithPayload';
import { IAddFarm, IFarm } from './../models/farms';
import { ICropZone } from '../models/cropzones';

export const enum FarmActionTypes {
    ADD_FARM = '[Account] ADD_FARM',
    SET_FARMS = '[Account] SET_FARMS',
    SET_FARM = '[Account] SET_FARM',
    GET_FARMS = '[Account] GET_FARMS',
    DELETE_FARM = '[Account] DELETE_FARM',
    REMOVE_FARM = '[Account] REMOVE_FARM',
    REMOVE_FARM_WITHOUT_CROPZONES = '[Account] REMOVE_FARM_WITHOUT_CROPZONES'
}

export function addFarm(add: IAddFarm): IActionWithPayload {
    return { type: FarmActionTypes.ADD_FARM, payload: add };
}

export function setFarms(data: Array<IFarm>): IActionWithPayload {
    return { type: FarmActionTypes.SET_FARMS, payload: data};
}

export function setFarm(data: IFarm): IActionWithPayload {
    return { type: FarmActionTypes.SET_FARM, payload: data};
}

export function getFarms(): IActionWithPayload {
    return { type: FarmActionTypes.GET_FARMS, payload: null};
}

export function removeFarm(remove: IFarm, cropzones: ICropZone[]): IActionWithPayloadRemoveField {
    return { type: FarmActionTypes.REMOVE_FARM, payload: remove, cropzones: cropzones};
}

export function removeFarmWithoutCropzones(remove: IFarm): IActionWithPayload {
    return { type: FarmActionTypes.REMOVE_FARM_WITHOUT_CROPZONES, payload: remove };
}

export function deleteFarm(id: string): IActionWithPayload {
    return { type: FarmActionTypes.DELETE_FARM, payload: id};
}
