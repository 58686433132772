import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {
  IPicture,
  IToolbarSettings,
  ISaveCameraDistance,
  IDrawingOptions,
  ISaveMeasurementsResponse
} from '../../../shared/camera/models/camera';

export const enum CropViewSettingsActionTypes {
  RESET_CROP_VIEW_SETTINGS = '[CropViewSettings] RESET_CROP_VIEW_SETTINGS',
  SET_CROP_VIEW_SETTINGS_CURRENT_DATE_STRING = '[CropViewSettings] SET_CROP_VIEW_SETTINGS_CURRENT_DATE_STRING',
  SET_CROP_VIEW_SETTINGS_SELECTED_PICTURE = '[CropViewSettings] SET_CROP_VIEW_SETTINGS_SELECTED_PICTURE',
  UPDATE_CROP_VIEW_SETTINGS_SELECTED_PICTURE = '[CropViewSettings] UPDATE_CROP_VIEW_SETTINGS_SELECTED_PICTURE',
  SET_CROP_VIEW_SETTINGS_TOOLBAR_SETTINS = '[CropViewSettings] SET_CROP_VIEW_SETTINGS_TOOLBAR_SETTINS',
  GET_CAMERA_DISTANCE = '[CropViewSettings] GET_CAMERA_DISTANCE',
  SET_CAMERA_DISTANCE = '[CropViewSettings] SET_CAMERA_DISTANCE',
  SAVE_CAMERA_DISTANCE = '[CropViewSettings] SAVE_CAMERA_DISTANCE',
  GET_CAMERA_DRAWING_OPTIONS = '[CropViewSettings] GET_CAMERA_DRAWING_OPTIONS',
  SET_CAMERA_DRAWING_OPTIONS = '[CropViewSettings] SET_CAMERA_DRAWING_OPTIONS',
  SAVE_CAMERA_DRAWING_OPTIONS = '[CropViewSettings] SAVE_CAMERA_DRAWING_OPTIONS'
}

export function resetCropViewSettings(): IActionWithPayload {
  return {type: CropViewSettingsActionTypes.RESET_CROP_VIEW_SETTINGS, payload: null};
}

export function setCropViewSettingsCurrentDateString(dateString: string): IActionWithPayload {
  return {type: CropViewSettingsActionTypes.SET_CROP_VIEW_SETTINGS_CURRENT_DATE_STRING, payload: dateString};
}

export function setCropViewSettingsSelectedPicture(picture: IPicture): IActionWithPayload {
  return {type: CropViewSettingsActionTypes.SET_CROP_VIEW_SETTINGS_SELECTED_PICTURE, payload: picture};
}

export function updateCropViewSettingsSelectedPicture(rectangles: ISaveMeasurementsResponse): IActionWithPayload {
  return {type: CropViewSettingsActionTypes.UPDATE_CROP_VIEW_SETTINGS_SELECTED_PICTURE, payload: rectangles};
}

export function setCropViewSettingsToolbarSettings(toolbarSettings: IToolbarSettings): IActionWithPayload {
  return {type: CropViewSettingsActionTypes.SET_CROP_VIEW_SETTINGS_TOOLBAR_SETTINS, payload: toolbarSettings};
}

export function getCameraDistance(data): IActionWithPayload {
  return {type: CropViewSettingsActionTypes.GET_CAMERA_DISTANCE, payload: data};
}

export function setCameraDistance(data: number): IActionWithPayload {
  return {type: CropViewSettingsActionTypes.SET_CAMERA_DISTANCE, payload: data};
}

export function saveCameraDistance(data: ISaveCameraDistance): IActionWithPayload {
  return {type: CropViewSettingsActionTypes.SAVE_CAMERA_DISTANCE, payload: data};
}

export function getCameraDrawingOptions(stationId: string): IActionWithPayload {
  return {type: CropViewSettingsActionTypes.GET_CAMERA_DRAWING_OPTIONS, payload: stationId};
}

export function setCameraDrawingOptions(data: IDrawingOptions): IActionWithPayload {
  return {type: CropViewSettingsActionTypes.SET_CAMERA_DRAWING_OPTIONS, payload: data};
}

export function saveCameraDrawingOptions(stationId: string, data: IDrawingOptions): IActionWithPayload {
  const payload = { stationId, data };
  return {type: CropViewSettingsActionTypes.SAVE_CAMERA_DRAWING_OPTIONS, payload};
}
