<app-form-layout *ngIf="!configuration.activated; else productActivated">
  <app-product-activation-license-alert [licensesOverlapped]="licensesOverlapped"></app-product-activation-license-alert>

  <div class="product-activation-service-license">
    <div class="product-activation-service-license__title">
      <h2>{{ (configuration.name || 'Service license information') | translate }}</h2>
    </div>

    <div class="product-activation-service-license__content">
      <app-product-activation-service-license-form
        [configuration]="configuration"
        [userStations$]="availableUserStations$"
        (activateProductEmitter)="activateProduct($event)">
      </app-product-activation-service-license-form>
    </div>
  </div>
</app-form-layout>

<ng-template #productActivated>
  <div class="alert alert-success">
    <span class="glyphicon glyphicon-ok-sign"></span>
    {{ 'The Support Assistance license was successfully activated!' | translate }}
  </div>
</ng-template>
