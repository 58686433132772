import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ITree } from '../../../../services/tree/models';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { IFrostProtectionState } from '../../models/models';
import { selectFrostProtectionPeriod, selectFrostProtectionTree } from '../../selectors/selectors';
import { dateTypeFromPeriod } from '../../../../shared/utils/dateTypeFromPeriod';
import { IStationSettingsPeriod } from '../../../../shared/interfaces';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-frost-protection-header',
  templateUrl: './frost-protection-header.component.html',
  styleUrls: ['./frost-protection-header.component.scss']
})
export class FrostProtectionHeaderComponent implements OnInit, OnDestroy {

  private tree                    : ITree = null;
  private destroy$                : Subject<boolean> = new Subject<boolean>();

  @Output()
  public openModal                : EventEmitter<void> = new EventEmitter();
  public period$                  : Observable<IStationSettingsPeriod>;
  public readonly format          : string = 'yyyy-MM-dd HH:mm';

  constructor(
    private frostProtectionStore  : Store<IFrostProtectionState>,
  ) { }

  public ngOnInit(): void {
    // get tree
    this.frostProtectionStore.pipe(
      takeUntil(this.destroy$),
      select(selectFrostProtectionTree)
    ).subscribe((tree: ITree) => {
      this.tree = tree;
    });

    this.period$ = this.frostProtectionStore.pipe(
      select(selectFrostProtectionPeriod)
    );
  }

  public prepareDate(date: string): string {
    return `${parseInt(date, 10)} ${dateTypeFromPeriod(date)}`;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
