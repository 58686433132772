import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from '../../../../../../../node_modules/rxjs';
import { REMOVE_CROPZONE } from '../../../../../shared/constants';
import { ModalService } from '../../../../../shared/modal/services/modal.service';
import { setNotify } from '../../../../actions/notify';
import { IAddFarm } from '../../../../models/farms';
import { IFarm } from '../../../../models/fields';
import { selectFarms } from '../../../../reducers';
import * as fromFarms from '../../../../reducers/farms';
import * as fromNotify from '../../../../reducers/notify';

@Component({
    selector: 'app-remove-farm-form',
    templateUrl: './remove-farm-form.component.html',
    styleUrls: ['./remove-farm-form.component.scss']
})

export class RemoveFarmFormComponent implements OnInit {
    @Output()
    private submit: EventEmitter<IAddFarm> = new EventEmitter<IAddFarm>();

    public farms$: Observable<IFarm[]>;
    public farmList: IFarm[];
    public form: FormGroup;

    constructor(private fb: FormBuilder,
        private modalService: ModalService,
        private farmStore: Store<fromFarms.IFarms>,
        private notifyStore: Store<fromNotify.INotifyState>) {
        this.farmStore.pipe(
            select(selectFarms)
        ).subscribe((farms) => {
            this.farmList = farms;
        });
    }

    public get farm(): AbstractControl {
        return this.form.get('farm');
    }

    public get submitColor(): string {
        return 'red';
    }

    public get submitContent(): string {
        return 'Remove farm';
    }

    public ngOnInit(): void {
        this.form = this.fb.group({
            'farm': ['', [Validators.required]]
        });

        this.farms$ = this.farmStore.pipe(
            select(selectFarms)
        );
    }

    public submitEmit(): void {
        if (this.farmList.some((farm) => farm.name.toLowerCase() === this.form.value.farm.toLowerCase())) {
            const farmObject = this.farmList.filter((farm) => farm.name.toLowerCase() === this.form.value.farm.toLowerCase())[0];
            this.submit.emit(farmObject);
        } else {
            this.notifyStore.dispatch(setNotify('Unable to remove farm. There is no farm with this name.'));
        }
        this.form.reset();
    }

    public close(): void {
        this.modalService.closeModal(REMOVE_CROPZONE);
    }
}
