<div class="body-view-content" [@placeContent]="state$ | async">
  <app-station-page-header [pageName]="'Station Data'"></app-station-page-header>
  <app-station-data-warning></app-station-data-warning>
  <app-camera-topology [linkType]="stationTopologyLink.LINK_DATA"></app-camera-topology>
  <div class="station-data-content__header">
    <app-station-data-header></app-station-data-header>
    <app-station-data-toolbar (exportChartImg)="exportCharts($event)"></app-station-data-toolbar>
  </div>

  <ng-container *ngIf="!(isLoading$ | async) && !(isError$ | async); else loadinfo">
    <div class="station-data-content__item">
      <app-station-data-charts [exportChartImg]="exportChartImg"></app-station-data-charts>
    </div>
    <div class="station-data-content__item">
      <app-station-data-table></app-station-data-table>
    </div>
  </ng-container>
</div>

<ng-template #loadinfo>
  <app-station-load-info [isError]="isError$ | async"
                         [isLoading]="isLoading$ | async"
                         [errorText]="errorMessage"></app-station-load-info>
</ng-template>
