<app-product-license-selector
    *ngIf="(config$ | async).licenseModalActive"
    [licenses]="licenses"
    [amountOfCropzones]="cropzones.length"
    (selectLicense)="selectLicense($event)"
    (cancel)="cancel()">
</app-product-license-selector>

<app-renew-cropzones-list
    *ngIf="(config$ | async).duplicationModalActive"
    [selectedLicense]="selectedLicense"
    [cropzones]="cropzones"
    (cancelEmit)="cancel()">
</app-renew-cropzones-list>

<app-duplication-info-modal
    *ngIf="(config$ | async).cropTypeModalActive"
></app-duplication-info-modal>
