import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromStationData from './station-data';
import * as fromStationSettings from './station-data-settings';
import {ITreeSettingsState} from '../../../shared/tree/models/tree.models';
import {IStationSettingsPeriod} from '../../../shared/interfaces';
import {stationDataTreeReducer} from '../../../shared/tree/reducers/tree-settings';

export interface IState {
  stationData     : fromStationData.IStationDataState;
  stationSettings : fromStationSettings.IStationDataSettingsState;
  treeSettings    : ITreeSettingsState;
}

export const reducers = {
  stationData     : fromStationData.reducer,
  stationSettings : fromStationSettings.reducer,
  treeSettings    : stationDataTreeReducer,
};

export const stationData = createFeatureSelector<IState>('stationData');

export const selectStationDataProfiles = createSelector(
  stationData,
  (stationDataState: IState) => stationDataState.stationData.profiles
);

export const selectStationDataCharts = createSelector(
  stationData,
  (stationDataState: IState) => stationDataState.stationData.chartOptions
);

export const selectStationDataGrid = createSelector(
  stationData,
  (stationDataState: IState) => stationDataState.stationData.grid
);

export const selectStationLoadError = createSelector(
  stationData,
  (stationDataState: IState) => stationDataState.stationData.isError
);

export const selectStationLoadProcess = createSelector(
  stationData,
  (stationDataState: IState) => stationDataState.stationData.isLoading
);

export const selectStationDataTopology = createSelector(
  stationData,
  (stationDataState: IState) => stationDataState.stationData.topology
);

export const selectStationDataSettings = createSelector(
  stationData,
  (stationDataState: IState) => stationDataState.stationSettings
);

export const selectStationDataCalculationEnabled = createSelector(
  stationData,
  (stationDataState: IState) => stationDataState.stationSettings.isCalculationEnabled
);

export const selectStationDataSettingsPeriod = createSelector(
  stationData,
  (stationDataState: IState): IStationSettingsPeriod => stationDataState.stationSettings.period
);

export const selectStationDataChartActive = createSelector(
  stationData,
  (stationDataState: IState) => stationDataState.stationSettings.activity.isChartActive
);

export const selectStationDataTableActive = createSelector(
  stationData,
  (stationDataState: IState) => stationDataState.stationSettings.activity.isTableActive
);

export const selectStationExportActive = createSelector(
  stationData,
  (stationDataState: IState) => stationDataState.stationSettings.activity.isExportActive
);

export const selectStationExportImageActive = createSelector(
  stationData,
  (stationDataState: IState) => stationDataState.stationSettings.activity.isExportImageActive
);

export const selectTreeSettings = createSelector(
  stationData,
  (stationDataState: IState) => stationDataState.treeSettings
);

export const selectStationLicenses = createSelector(
  stationData,
  (stationDataState: IState) => stationDataState.stationData.licenses
);

export const selectVWSWarning = createSelector(
  stationData,
  (stationDataState: IState) => stationDataState.stationData.warning
);
