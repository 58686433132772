import { IStation } from '../../../core/models/stations';
import { IColumn } from '../models/dashboard.models';
import { makeWidget } from './makeWidget';
import { findForecast, findSimple } from './findStations';

export function prepareDashboard<T>(objectpassed: T, deleteKeys: Array<string>): T {
  if (objectpassed === null || typeof objectpassed !== 'object') {
    return objectpassed;
  }
  const temporaryStorage = objectpassed.constructor();

  for (const key in objectpassed) {
    if (objectpassed.hasOwnProperty(key) && deleteKeys.indexOf(key) === -1) {
      temporaryStorage[key] = prepareDashboard(objectpassed[key], deleteKeys);
    }
  }
  return temporaryStorage;
}

export function createDashboard(stations: Array<IStation>): Array<IColumn[]> {

  const simpleStations = findSimple(stations);
  const dashboard: Array<Array<IColumn>> = [];
  const newRow: IColumn[] = [];
  const widgetTitles = ['Demo chart widget', 'Station table widget', 'Default stations list', 'Default stations map'];

  if (simpleStations.length) {
    newRow.push({
      items: [makeWidget(simpleStations[0], {
        type: 'sd-highcharts',
        title: widgetTitles[0],
        description: '',
        color: 'cht-alt'
      })],
      width: 6
    });

    const tableRow: IColumn = {
      items: [makeWidget(stations[0], {
        type: 'sd-data',
        title: widgetTitles[1],
        description: '',
        color: 'cht-alt'
      })],
      width: 6
    };
    if (stations.length >= 2) {
      tableRow.items.push(makeWidget(stations[1], {
        type: 'sd-data',
        title: widgetTitles[1],
        description: '',
        color: 'cht-alt'
      }));
    }
    newRow.push(tableRow);

    dashboard.push(newRow);
  }

  dashboard.push([{
    items: [makeWidget(stations[0], {
      type: 'stations-list',
      title: widgetTitles[2],
      description: '',
      color: 'cht-alt'
    }),
      makeWidget(stations[0], {
        type: 'google-map',
        title: widgetTitles[3],
        description: '',
        color: 'cht-alt'
      })],
    width: 12
  }]);

  return dashboard;
}

export function createAdamaDashboard(stations: Array<IStation>): Array<IColumn[]> {

  const forecastStations = findForecast(stations);
  const dashboard: Array<Array<IColumn>> = [];
  const newRow: IColumn[] = [];

  if (forecastStations.length) {
    const chartWidget = makeWidget(stations[0], {
      type: 'sd-highcharts',
      title: `Últimos dados (${forecastStations[0].name.original})`,
      description: '',
      color: 'cht-alt'
    });
    chartWidget.requests.chart.endpoint.last = '72';
    chartWidget.requests.chart.profile = 'All sensors';

    const forecastWidget = makeWidget(forecastStations[0], {
      type: 'sd-forecast-chart',
      title: 'Previsão 7 dias',
      description: '',
      color: 'cht-alt'
    });
    forecastWidget.requests.forecastChart.endpoint.profile = 'simple7';

    newRow.push({
      items: [
        forecastWidget,
        chartWidget
      ],
      width: 12
    });

    dashboard.push(newRow);
  }

  dashboard.push([{
      items: [makeWidget(stations[0], {
        type: 'google-map',
        title: 'Mapa',
        description: '',
        color: 'cht-alt'
      })],
    width: 12
  }]);

  return dashboard;
}

export function capitalize(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}
