import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IGetStationDataFcRequest} from '../../station-data/models/station-data.models';
import {IDiseaseWarning} from '../models/station-disease.models';

export const enum StationDiseaseActionTypes {
  GET_STATION_DISEASE_LICENSES = '[StationDisease] GET_STATION_DISEASE_LICENSES',
  SET_STATION_DISEASE_LICENSES = '[StationDisease] SET_STATION_DISEASE_LICENSES',
  GET_STATION_DISEASE_DATA = '[StationDisease] LOAD_STATION_DISEASE_DATA',
  SET_STATION_DISEASE_DATA = '[StationDisease] SET_STATION_DISEASE_DATA',
  SET_STATION_DISEASE_LOADING = '[StationDisease] SET_STATION_DISEASE_LOADING',
  SET_STATION_DISEASE_ERROR = '[StationDisease] SET_STATION_DISEASE_ERROR',
  SET_STATION_DISEASE_WARNINGS = '[StationDisease] SET_STATION_DISEASE_WARNINGS',
  SET_STATION_DISEASE_LOADING_PROGRESS = '[StationDisease] SET_STATION_DISEASE_LOADING_PROGRESS',
  ADD_STATION_DISEASE_WARNING = '[StationDisease] ADD_STATION_DISEASE_WARNING'
}

export function setStationDiseaseLoadingProgress(): IActionWithPayload {
  return { type: StationDiseaseActionTypes.SET_STATION_DISEASE_LOADING_PROGRESS, payload: null };
}

export function addStationDiseaseWarning(warning: IDiseaseWarning): IActionWithPayload {
  return { type: StationDiseaseActionTypes.ADD_STATION_DISEASE_WARNING, payload: warning };
}

export function setStationDiseaseWarnings(warnings: IDiseaseWarning[]): IActionWithPayload {
  return { type: StationDiseaseActionTypes.SET_STATION_DISEASE_WARNINGS, payload: warnings };
}

export function setStationDiseaseLoading(isLoading: boolean): IActionWithPayload {
  return { type: StationDiseaseActionTypes.SET_STATION_DISEASE_LOADING, payload: isLoading };
}

export function setStationDiseaseError(isError: boolean): IActionWithPayload {
  return { type: StationDiseaseActionTypes.SET_STATION_DISEASE_ERROR, payload: isError };
}

export function getStationDiseaseData(request: IGetStationDataFcRequest): IActionWithPayload {
  return { type: StationDiseaseActionTypes.GET_STATION_DISEASE_DATA, payload: request };
}

export function getStationDiseaseLicenses(stationId: string): IActionWithPayload {
  return { type: StationDiseaseActionTypes.GET_STATION_DISEASE_LICENSES, payload: stationId };
}

export function setStationDiseaseData(data: any): IActionWithPayload {
  return { type: StationDiseaseActionTypes.SET_STATION_DISEASE_DATA, payload: data };
}

export function setStationDiseaseLicenses(licenses: any): IActionWithPayload {
  return { type: StationDiseaseActionTypes.SET_STATION_DISEASE_LICENSES, payload: licenses };
}
