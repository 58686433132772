import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WIDE_CAMM_ID, ZOOM_CAMM_ID } from '../../../../core/constants/camera';
import {
  IDeleteImagePayload,
  IDrawingOptions,
  IPicture,
  ISaveMeasurementsPayload,
  IToolbarSettings
} from '../../../../shared/camera/models/camera';
import { filterNewRectangles, filterNotHiddenRectangles } from '../../../../shared/utils/rectangle-filter';
import { deleteCropViewImage, saveCropViewMeasurements } from '../../actions/crop-view';
import {
  saveCameraDistance,
  saveCameraDrawingOptions,
  setCropViewSettingsSelectedPicture,
  setCropViewSettingsToolbarSettings
} from '../../actions/crop-view-settings';
import { ICropViewSettingsState, ICropViewState } from '../../models/crop-view.models';
import { selectCropViewNextPicture } from '../../reducers';

@Component({
  selector: 'app-crop-view-image-settings-toolbar',
  templateUrl: './crop-view-image-settings-toolbar.component.html',
  styleUrls: ['./crop-view-image-settings-toolbar.component.scss']
})
export class CropViewImageSettingsToolbarComponent implements OnDestroy {
  @Input()
  public selectedPicture: IPicture;
  @Input()
  public stationId: string;
  @Input()
  public activeCameraId: number;
  @Input()
  public toolbarSettings$: Observable<IToolbarSettings>;
  @Input()
  public hasWritePermission: boolean;
  @Input()
  public cameraDrawingOptions: IDrawingOptions;
  @Input()
  public set cameraDistance(cameraDistance: number) {
    this.distanceControl = new FormControl(cameraDistance);
  }

  @Output()
  public cameraDistanceEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  public unselectPictureEmitter: EventEmitter<void> = new EventEmitter<void>();

  public readonly minValue: number = 500;
  public readonly maxValue: number = 10000;

  public distanceControl: FormControl = new FormControl(0);
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private cropViewStore: Store<ICropViewState>,
              private cropViewSettingsStore: Store<ICropViewSettingsState>) { }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public get totalRectanglesCount(): number {
    return filterNotHiddenRectangles(this.selectedPicture.rectangles || []).length;
  }

  public get newRectanglesCount(): number {
    return filterNewRectangles(this.selectedPicture.rectangles || []).length;
  }

  public get cameraDistance(): number {
    return this.distanceControl.value;
  }

  public saveDrawingOptions(): void {
    // Send show/hide settings to API
    this.cropViewSettingsStore.dispatch(saveCameraDrawingOptions(this.stationId, {
      ...this.cameraDrawingOptions
    }));
  }

  public saveToolbarSettings(data: IToolbarSettings): void {
    this.cropViewSettingsStore.dispatch(setCropViewSettingsToolbarSettings(data));
  }

  public saveMeasurements(data: ISaveMeasurementsPayload): void {
    this.cropViewStore.dispatch(saveCropViewMeasurements(data));
    this.saveCameraDistance();
  }

  public saveCameraDistance(): void {
    const data = {
      stationId: this.stationId,
      body: {
        distance: this.cameraDistance
      }
    };
    this.cropViewSettingsStore.dispatch(saveCameraDistance(data));
  }

  public changeDistance(): void {
    this.cameraDistanceEmitter.emit(this.distanceControl.value);
  }

  public deleteImage(data: IDeleteImagePayload): void {
    data.refreshRequest.camIds = [WIDE_CAMM_ID, ZOOM_CAMM_ID];
    this.cropViewStore.dispatch(deleteCropViewImage(data));
  }

  public toggleCircle(property: string): void {
    if (this.cameraDrawingOptions.hasOwnProperty(property)
        && typeof this.cameraDrawingOptions[property] === 'boolean') {
      this.cameraDrawingOptions[property] = !this.cameraDrawingOptions[property];
      this.saveDrawingOptions();
    }
  }

  public unselectPicture(): void {
    this.unselectPictureEmitter.emit();
  }

  public nextPicture(currentPicture: IPicture): void {
    this.triggerPictureChange(currentPicture, true);
  }

  public previousPicture(currentPicture: IPicture): void {
    this.triggerPictureChange(currentPicture, false);
  }

  private triggerPictureChange(currentPicture: IPicture, next: boolean): void {
    this.cropViewStore
      .pipe(
        takeUntil(this.destroy$),
        select(selectCropViewNextPicture, { currentPicture, camId: this.activeCameraId, next }),
      )
      .subscribe((nextPicture: IPicture) => {
        this.cropViewSettingsStore.dispatch(setCropViewSettingsSelectedPicture(nextPicture));
      })
      .unsubscribe();
  }
}
