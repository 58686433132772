import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {Action} from '@ngrx/store';
import {
  IAddWidget,
  IColumn,
  ILoadWidgetData,
  ISetWidgetData,
  ISetWidgetError,
  ISetWidgetLoading,
  IUpdateColumnWidth,
  IUpdateWidgetData,
  IUpdateWidgetEditMode
} from '../models/dashboard.models';

export const enum DashboardActionTypes {
  SET_DASHBOARD_NAME = '[Dashboard] SET_DASHBOARD_NAME',
  SET_DASHBOARD_SETTINGS = '[Dashboard] SET_DASHBOARD_SETTINGS',
  GET_WIDGET_DATA = '[Dashboard] GET_WIDGET_DATA',
  SET_WIDGET_DATA = '[Dashboard] SET_WIDGET_DATA',
  UPDATE_WIDGET_SETTINGS = '[Dashboard] UPDATE_WIDGET_SETTINGS',
  SET_WIDGET_SETTINGS = '[Dashboard] SET_WIDGET_SETTINGS',
  UPDATE_WIDGET_EDIT_MODE = '[Dashboard] UPDATE_WIDGET_EDIT_MODE',
  SET_WIDGET_EDIT_MODE = '[Dashboard] SET_WIDGET_EDIT_MODE',
  ADD_WIDGET = '[Dashboard] ADD_WIDGET',
  UPDATE_COLUMN_WIDTH = '[Dashboard] UPDATE_COLUMN_WIDTH',
  CHANGE_EDIT_MODE = '[Dashboard] CHANGE_EDIT_MODE',
  ACTIVATE_EDIT_MODE = '[Dashboard] ACTIVATE_EDIT_MODE',
  RESTORE_DASHBOARD = '[Dashboard] RESTORE_DASHBOARD',
  RESET_DASHBOARD = '[Dashboard] RESET_DASHBOARD',
  ADD_NEW_COLUMNS = '[Dashboard] ADD_NEW_COLUMNS',
  REMOVE_WIDGET = '[Dashboard] REMOVE_WIDGET',
  REMOVE_COLUMN = '[Dashboard] REMOVE_COLUMN',
  REMOVE_DASHBOARD = '[Dashboard] REMOVE_DASHBOARD',
  SET_WIDGET_ERROR = '[Dashboard] SET_WIDGET_ERROR',
  SET_WIDGET_LOADING = '[Dashboard] SET_WIDGET_LOADING',
  SAVE_DASHBOARD = '[Dashboard] SAVE_DASHBOARD',
  SET_DASHBOARD_CHANGED = '[Dashboard] SET_DASHBOARD_CHANGED',
  GET_DASHBOARD_LIST = '[Dashboard] GET_DASHBOARD_LIST',
  SET_DASHBOARD_LIST = '[Dashboard] SET_DASHBOARD_LIST',
  CHANGE_DASHBOARD = '[Dashboard] CHANGE_DASHBOARD',
  SET_DASHBOARD_META = '[Dashboard] SET_DASHBOARD_META',
  SAVE_DEFAULT = '[Dashboard] SAVE_DEFAULT',
  CHANGE_SUBDOMAIN = '[Dashboard] CHANGE_SUBDOMAIN',
  ADD_NEW_DASHBOARD = '[Dashboard] ADD_NEW_DASHBOARD',
  RELOAD_DASHBOARD = '[Dashboard] RELOAD_DASHBOARD'
}

export function setDashboardChanged(isChanged: boolean): IActionWithPayload {
  return { type: DashboardActionTypes.SET_DASHBOARD_CHANGED, payload: isChanged };
}

export function saveDashboard(dashboard: Array<Array<IColumn>>): IActionWithPayload {
  return { type: DashboardActionTypes.SAVE_DASHBOARD, payload: dashboard };
}

export function setWidgetLoading(loading: ISetWidgetLoading): IActionWithPayload {
  return { type: DashboardActionTypes.SET_WIDGET_LOADING, payload: loading };
}

export function setWidgetError(error: ISetWidgetError): IActionWithPayload {
  return { type: DashboardActionTypes.SET_WIDGET_ERROR, payload: error };
}

export function removeColumn(path: Array<number>): IActionWithPayload {
  return { type: DashboardActionTypes.REMOVE_COLUMN, payload: path };
}

export function removeWidget(path : Array<number>): IActionWithPayload {
  return { type: DashboardActionTypes.REMOVE_WIDGET, payload: path };
}

export function addNewColumns(row: Array<IColumn>): IActionWithPayload {
  return { type: DashboardActionTypes.ADD_NEW_COLUMNS, payload: row };
}

export function restoreDashboard(): Action {
  return { type: DashboardActionTypes.RESTORE_DASHBOARD };
}

export function resetDashboard(): Action {
  return { type: DashboardActionTypes.RESET_DASHBOARD };
}

export function changeEditMode(): Action {
  return { type: DashboardActionTypes.CHANGE_EDIT_MODE };
}

export function activateEditMode(): Action {
  return { type: DashboardActionTypes.ACTIVATE_EDIT_MODE };
}

export function updateColumnWidth(width: IUpdateColumnWidth): IActionWithPayload {
  return { type: DashboardActionTypes.UPDATE_COLUMN_WIDTH, payload: width };
}

export function addWidget(widget: IAddWidget): IActionWithPayload {
  return { type: DashboardActionTypes.ADD_WIDGET, payload: widget };
}

export function updateWidgetEditMode(data: IUpdateWidgetEditMode): IActionWithPayload {
  return { type: DashboardActionTypes.UPDATE_WIDGET_EDIT_MODE, payload: data };
}
export function updateWidgetSettings(data: IUpdateWidgetData): IActionWithPayload {
  return { type: DashboardActionTypes.UPDATE_WIDGET_SETTINGS, payload: data};
}

export function setWidgetEditMode(data: IUpdateWidgetEditMode): IActionWithPayload {
  return { type: DashboardActionTypes.SET_WIDGET_EDIT_MODE, payload: data };
}
export function setWidgetSettings(data: IUpdateWidgetData): IActionWithPayload {
  return { type: DashboardActionTypes.SET_WIDGET_SETTINGS, payload: data};
}

export function getWidgetData(data: ILoadWidgetData): IActionWithPayload {
  return { type: DashboardActionTypes.GET_WIDGET_DATA, payload: data};
}

export function setWidgetData(data: ISetWidgetData): IActionWithPayload {
  return { type: DashboardActionTypes.SET_WIDGET_DATA, payload: data};
}

export function setDashboardSettings(data: Array<Array<IColumn>>): IActionWithPayload {
  return { type: DashboardActionTypes.SET_DASHBOARD_SETTINGS, payload: data };
}

export function setDashboardName(data: string): IActionWithPayload {
  return { type: DashboardActionTypes.SET_DASHBOARD_NAME, payload: data };
}

export function removeDashboard(): Action {
  return { type: DashboardActionTypes.REMOVE_DASHBOARD };
}

export function getDashboardList(): Action {
  return { type: DashboardActionTypes.GET_DASHBOARD_LIST };
}

export function setDashboardList(data: Array<string>): IActionWithPayload {
  return { type: DashboardActionTypes.SET_DASHBOARD_LIST, payload: data };
}

export function changeDashboard(data: {id: string, title?: string}): IActionWithPayload {
  return { type: DashboardActionTypes.CHANGE_DASHBOARD, payload: data };
}

export function saveDefault(): Action {
  return { type: DashboardActionTypes.SAVE_DEFAULT };
}

export function setDashboardMeta(data: { main: boolean, id?: string }): IActionWithPayload {
  return { type: DashboardActionTypes.SET_DASHBOARD_META, payload: data };
}

export function changeSubdomain(newSubDomain: string): IActionWithPayload {
  return { type: DashboardActionTypes.CHANGE_SUBDOMAIN, payload: newSubDomain };
}

export function addNewDashboard(): Action {
  return { type: DashboardActionTypes.ADD_NEW_DASHBOARD };
}

export function reloadDashboard(data: {id?: string, title?: string, main?: boolean}): IActionWithPayload {
  return { type: DashboardActionTypes.RELOAD_DASHBOARD, payload: data };
}
