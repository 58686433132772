import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environmentToken } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../../../environments/interfaces/theme';
import { AuthService } from '../../../../services/auth/auth.service';
import { LanguageService } from '../../../../services/translation/language.service';
import { IOptions } from '../../../../shared/interfaces';
import { errorFormAnimation, formRouterAnimation } from '../../../../shared/router.animations';
import { IUserCredentials } from '../../login.interfaces';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', '../../auth.css'],
  animations: [formRouterAnimation(), errorFormAnimation()]
})
export class LoginComponent implements OnDestroy, OnInit {
  public response             : string = '';
  public showError            : boolean = false;
  public loginForm            : FormGroup;
  public languages            : Array<IOptions>;
  public language             : string = '';
  public showPassword         : boolean = false;
  private credentials         : IUserCredentials;
  private alive               : boolean = true;
  public subDomain            : IThemeConfig;

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    private auth              : AuthService,
    private formBuilder       : FormBuilder,
    public router             : Router,
    private route             : ActivatedRoute,
    private languageService   : LanguageService
  ) {

    if (this.auth.hasAccessToken()) {
      this.router.navigate(['/dashboard']);
    }

    this.loginForm = formBuilder.group({
      'username': ['', [Validators.required]],
      'password': ['', [Validators.required]]
    });

    this.language = this.languageService.languageFromStorage;
    this.languages = environment.languages;
    this.subDomain = environment.theme;
  }

  public ngOnInit(): void {

    const message = this.route.snapshot.paramMap.get('message');

    if (message) {
      this.response = message;
      this.showError = true;
    }
  }

  public get username(): AbstractControl {
    return this.loginForm.get('username');
  }

  public get password(): AbstractControl {
    return this.loginForm.get('password');
  }

  public ngOnDestroy(): void {
    this.alive = false;
  }

  public onLanguageChange(lang: string): void {
    this.languageService.setLanguage(lang);
  }

  public login(): void {
    if (this.loginForm.invalid) {
      return;
    }
    this.showError = false;

    this.credentials = {
      username: this.username.value,
      password: this.password.value
    };

    localStorage.removeItem('min');
    localStorage.removeItem('startDate');
    localStorage.removeItem('nrDays');

    this.auth.login(this.credentials).subscribe(
      result => {
        this.response = 'Login successful. Access code: ' + result.access_token;
      },
      err => {
        this.showError = true;
        this.response = err.error.error_description;
      }
    );
  }

  public onShowPassword(): void {
    this.showPassword = !this.showPassword;
  }
}
