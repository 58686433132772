import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ICropZone, ILayer } from '../../../../core/models/cropzones';
import { selectSelectedCropZone, selectUserData } from '../../../../core/reducers';
import { IAccount } from '../../../../core/reducers/account';
import * as fromSelectedCropzone from '../../../../core/reducers/selectedCropZone';
import { soilProfileValues } from '../../constants/config';
import { getCollectConfigData } from '../../reducers';
import * as fromIrrimetConfig from '../../reducers/irrimet-config.reducer';
import { IrrimetService } from './../../services/irrimet.service';

@Component({
  selector: 'app-soil-profile-table',
  templateUrl: './soil-profile-table.component.html',
  styleUrls: ['./soil-profile-table.component.scss']
})
export class SoilProfileTableComponent implements OnInit, OnDestroy {

  public soilProfileValue: {};
  private alive$ = new Subject<boolean>();
  public selectedCropzone: ICropZone;
  public form: FormGroup;
  public soilProfileLayers: FormArray;
  public unitSystem: string;

  public depthInSoilValid: boolean = true;
  public fieldCapacityValid: boolean = true;
  public refillPointValid: boolean = true;
  public comparisonFieldCapacityRefillPointValid: boolean = true;
  public depthsFollowingValid: boolean = true;

  @Input('soilProfileIndex')
  public set soilProfileIndex(ind: number) {
    if (ind > 0) {
      this.soilProfileValue = soilProfileValues[ind];
      this.fillInFirstRow(this.soilProfileValue);
      this.validateForm();
    }
  }

  @Output()
  public soilLayers: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public formIsValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private irrimetConfigStore: Store<fromIrrimetConfig.IDataSourcesState>,
    private userStore: Store<IAccount>,
    private irrimetService: IrrimetService
  ) {

    this.selectedCropzoneStore.pipe(
      select(selectSelectedCropZone),
      takeUntil(this.alive$)
    ).subscribe((cropzone) => {
      this.selectedCropzone = cropzone;

      this.initTable();
    });

    this.userStore.pipe(
      select(selectUserData),
      takeUntil(this.alive$),
      filter((u: IAccount) => !!u)
    ).subscribe((u: IAccount) => {
      if (u.settings !== null) {
        this.unitSystem = u.settings.unit_system;
      }
    });
  }

  get formData(): FormArray {
    return <FormArray>this.form.get('soilProfileLayers');
  }

  public ngOnInit(): void {
    this.initTable();

    this.listenCollectAction();
  }

  public fillInFirstRow(values): void {
    this.form['controls']['soilProfileLayers']['controls'][0]['controls'].refill_point.patchValue(values.RP);
    this.form['controls']['soilProfileLayers']['controls'][0]['controls'].field_capacity.patchValue(values.FC);
    this.form['controls']['soilProfileLayers']['controls'][0]['controls'].depth_in_soil.patchValue(10);
    if (this.unitSystem === 'imperial') {
      this.form['controls']['soilProfileLayers']['controls'][0]['controls'].depth_in_soil.patchValue(4);
    }
  }

  public copyToAllOtherDepths(): void {

    for (let i = 1; i < 6; i++) {
      this.form['controls']['soilProfileLayers']['controls'][i]['controls'].refill_point.patchValue(
        this.form['controls']['soilProfileLayers']['controls'][0]['controls'].refill_point.value
      );
      this.form['controls']['soilProfileLayers']['controls'][i]['controls'].field_capacity.patchValue(
        this.form['controls']['soilProfileLayers']['controls'][0]['controls'].field_capacity.value
      );
      if (this.unitSystem === 'imperial') {
        this.form['controls']['soilProfileLayers']['controls'][i]['controls'].depth_in_soil.patchValue(
          parseFloat(this.form['controls']['soilProfileLayers']['controls'][0]['controls'].depth_in_soil.value) + (i * 4)
        );
      } else {
        this.form['controls']['soilProfileLayers']['controls'][i]['controls'].depth_in_soil.patchValue(
          parseFloat(this.form['controls']['soilProfileLayers']['controls'][0]['controls'].depth_in_soil.value) + (i * 10)
        );
      }
    }

    this.validateForm();
  }

  public initTable(): void {
    this.form = this.fb.group({
      soilProfileLayers: this.fb.array([])
    });

    if (this.selectedCropzone.soil) {
      for (let i = 0; i < this.selectedCropzone.soil.layers.length; i++) {
        this.soilProfileLayers = this.form.get('soilProfileLayers') as FormArray;
        this.soilProfileLayers.push(
          this.fb.group({
            // tslint:disable-next-line:max-line-length
            refill_point: [this.selectedCropzone.soil.layers[i].refill_point, [Validators.required, Validators.min(1), Validators.max(100), this.irrimetService.customRefillPointValidator]],
            field_capacity: [this.selectedCropzone.soil.layers[i].field_capacity, [Validators.required, Validators.min(1),
            Validators.max(100), this.irrimetService.customFieldCapacityValidator]],
            unit: '%',
            depth_in_soil: [this.selectedCropzone.soil.layers[i].depth, [Validators.required, Validators.min(0)]]
          }, {})
        );
      }
    } else {
      for (let i = 0; i < 6; i++) {
        this.soilProfileLayers = this.form.get('soilProfileLayers') as FormArray;

        let depth: number;
        if (this.unitSystem === 'imperial') {
          depth = (i + 1) * 4;
        } else {
          depth = (i + 1) * 10;
        }

        this.soilProfileLayers.push(
          this.fb.group({
            // tslint:disable:max-line-length
            refill_point: [0, [Validators.required, Validators.min(1), Validators.max(100), this.irrimetService.customRefillPointValidator]],
            field_capacity: [0, [Validators.required, Validators.min(1), Validators.max(100), this.irrimetService.customFieldCapacityValidator]],
            // tslint:enable:max-line-length
            unit: ['%', [Validators.required]],
            depth_in_soil: [depth, [Validators.required, Validators.min(0)]]
          })
        );
      }
    }
  }

  public listenCollectAction(): void {

    this.irrimetConfigStore.pipe(
      select(getCollectConfigData),
      takeUntil(this.alive$)
    ).subscribe((collectConfigData: boolean) => {

      if (collectConfigData) {
        const layers: Array<ILayer> = new Array<ILayer>();

        for (let i = 0; i < this.form.value.soilProfileLayers.length; i++) {
          const layer: ILayer = {
            depth: parseFloat(this.form.value.soilProfileLayers[i].depth_in_soil),
            field_capacity: parseFloat(this.form.value.soilProfileLayers[i].field_capacity),
            refill_point: parseFloat(this.form.value.soilProfileLayers[i].refill_point),
            unit: '%'
          };
          layers.push(layer);
        }

        this.soilLayers.emit(layers);
      }
    });

  }

  public validateForm(): void {
    const soilProfileLayers = this.form.value.soilProfileLayers;

    let isValid: boolean = true;
    this.depthInSoilValid = true;
    this.fieldCapacityValid = true;
    this.refillPointValid = true;
    this.comparisonFieldCapacityRefillPointValid = true;
    this.depthsFollowingValid = true;

    for (let i = 0; i < soilProfileLayers.length; i++) {
      this.formData.controls[i].setErrors(null);
    }

    for (let i = 0; i < soilProfileLayers.length; i++) {
      if (parseInt(soilProfileLayers[i].depth_in_soil, 10) < 1 || parseInt(soilProfileLayers[i].depth_in_soil, 10) > 200) {
        isValid = false;
        this.depthInSoilValid = false;
      } else if (parseInt(soilProfileLayers[i].field_capacity, 10) < 1 || parseInt(soilProfileLayers[i].field_capacity, 10) > 100) {
        isValid = false;
        this.fieldCapacityValid = false;
      } else if (parseInt(soilProfileLayers[i].refill_point, 10) < 1 || parseInt(soilProfileLayers[i].refill_point, 10) > 100) {
        isValid = false;
        this.refillPointValid = false;
      } else if (parseInt(soilProfileLayers[i].field_capacity, 10) <= parseInt(soilProfileLayers[i].refill_point, 10)) {
        isValid = false;
        this.comparisonFieldCapacityRefillPointValid = false;
      }

      if (i !== 0) {
        if (parseInt(soilProfileLayers[i].depth_in_soil, 10) <= parseInt(soilProfileLayers[i - 1].depth_in_soil, 10)) {
          isValid = false;
          this.depthsFollowingValid = false;
          this.formData.controls[i - 1].setErrors({depthsNotFollowing: true});
        }
      }
    }

    this.formIsValid.emit(isValid);
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }
}
