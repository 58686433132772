<div *ngIf="licensesOverlapped.length > 0" class="product-license-alert">
  <div class="alert alert-warning">
    <div class="row">
      <div class="col-sm-12">
        <span class="glyphicon glyphicon-alert"></span>
        {{ 'There are already valid licenses for the selected product:' | translate }}
        <br>
      </div>

      <div class="col-sm-12">
        <ul>
          <li *ngFor="let license of licensesOverlapped">
            {{ 'Station' | translate }}
            <b>{{ license.station_name }}</b>,
            {{ 'valid until' | translate }}
            <b>{{ license.end_date | date: 'dd/MM/yyyy HH:mm' }}</b>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
