<app-chart-toolbar-layout>
  <form class="frost-protection-toolbar"
        (keydown.enter)="$event.preventDefault()"
        [formGroup]="periodForm">

    <div class="frost-protection-toolbar__left">
      <div class="frost-protection-toolbar__toggle frost-protection-toolbar__left__item">
        <app-custom-button [color]="isLastDataMode.value ? 'dark-green' : 'green'"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'Last data mode' | translate"
                           [shadow]="false"
                           [radius]="'2px 0 0 2px'"
                           (click)="isLastDataMode.setValue(true); setDateRange()">
          <span class="fa fa-step-backward"></span>
        </app-custom-button>
        <app-custom-button [color]="!isLastDataMode.value ? 'dark-green' : 'green'"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'From - To mode' | translate"
                           [shadow]="false"
                           [radius]="'0 2px 2px 0'"
                           (click)="isLastDataMode.setValue(false)">
          <span class="fa fa-calendar"></span>
        </app-custom-button>
      </div>

      <ng-container *ngIf="!isLastDataMode.value">
        <app-custom-datepicker [isSmall]="true"
                               [minDate]="fromStation"
                               [maxDate]="toDatepicker.value"
                               [formControl]="fromDatepicker">
        </app-custom-datepicker>

        <span class="frost-protection-toolbar__icon">
          <i class="zmdi zmdi-calendar-close padding-right"></i>
          <span class="frost-protection-toolbar__datepicker-end">{{ nowTimeString }}</span>
        </span>
      </ng-container>

      <app-custom-dropdown class="frost-protection-toolbar__left__item"
                           [options]="periodValueSelectItems"
                           [hidden]="!isLastDataMode.value"
                           [formControl]="periodValue">
      </app-custom-dropdown>
    </div>

    <div class="frost-protection-toolbar__right">
      <div class="frost-protection-toolbar__toggle
                  frost-protection-toolbar__right__item
                  frost-protection-toolbar__export"
           *ngIf="stationId.value">
        <app-custom-button [shadow]="false"
                           appTooltip
                           (click)="startExportImage()"
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'Export charts as image' | translate"
                           [color]="'white'">
          <i *ngIf="!isExportImageActive.value; else imgExport"
             class="glyphicon glyphicon-download-alt"></i>
          <ng-template #imgExport>
            <i class="fa fa-cog fa-spin fa-fw"></i>
          </ng-template>
        </app-custom-button>
        <app-custom-button [shadow]="false"
                           (click)="startExportXLS()"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'Export table as xls' | translate"
                           [color]="'white'">
          <i class="fa fa-table"></i>
        </app-custom-button>
      </div>

      <div class="frost-protection-toolbar__toggle frost-protection-toolbar__right__item">
        <app-custom-button [color]="isChartActive.value ? 'green' : 'white'"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'Toggle charts' | translate"
                           [shadow]="false"
                           (click)="isChartActive.setValue(!isChartActive.value)"
                           [radius]="'2px 0 0 2px'">
          <span class="fa fa-bar-chart-o"></span>
        </app-custom-button>
        <app-custom-button [color]="isTableActive.value ? 'green' : 'white'"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'Toggle table' | translate"
                           [shadow]="false"
                           (click)="isTableActive.setValue(!isTableActive.value)"
                           [radius]="'0 2px 2px 0'">
          <span class="fa fa-table"></span>
        </app-custom-button>
      </div>

      <div>
        <app-custom-button [color]="'red'"
                         class="frost-protection-toolbar__right__item"
                         (click)="refresh()"
                         [content]="'Refresh' | translate">
          <i class="fa fa-fw fa-refresh"></i>
        </app-custom-button>
      </div>
    </div>
  </form>
</app-chart-toolbar-layout>

