export function suppressMenu(headers: any): any {
  return headers.map((header: any) => {
    const newHeader: any = {
      ...header,
      suppressMenu: true
    };
    if (newHeader.hasOwnProperty('children')) {
      newHeader.children = suppressMenu(newHeader.children);
    }
    return newHeader;
  });
}
