import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { IHeaderData } from '../../models/work-planning.models';

@Component({
  selector: 'app-work-planning-header',
  templateUrl: './work-planning-header.component.html',
  styleUrls: ['./work-planning-header.component.scss']
})
export class WorkPlanningHeaderComponent implements OnInit {

  @Input()
  public header   : IHeaderData;
  public modalId  : string = generateId();

  constructor(private modal: ModalService) { }

  public ngOnInit(): void {}

  public openModal(): void {
    this.modal.openModal(this.modalId);
  }

}
