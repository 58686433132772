
<app-search-widget-cropzones [items$]="cropzones$"
                   [cropzones]="cropzones"
                   [isActive]="cropzonesAreOpened"
                   (close)="closeBar.emit()"
                   [searchPlaceholder]="'Search'"
                   [orderSettings]="orderSettings"
                   [selectedCropzone]="selectCropZone$ | async"
                   (openAddCropZoneModalEvent)="openAddCropZoneModal()"
                   (openRemoveCropZoneModalEvent)="openRemoveCropZoneModal()"></app-search-widget-cropzones>

                   <app-modal [modalId]="addCropZoneModalId">
                    <app-add-cropzone></app-add-cropzone>
                  </app-modal>

                  <app-modal [modalId]="removeCropZoneModalId">
                    <app-remove-cropzone></app-remove-cropzone>
                  </app-modal>

