  <ng-container *ngIf="sensor &&
                sensor.code !== elevationSensorCode"
                [formGroup]="form">
    <td class="sensor-td sensor-td__first">{{ sensor.ch }}</td>
    <td class="sensor-td">{{ sensor.code }}</td>
    <td class="sensor-td">{{ sensor.name | translate}}</td>
    <td class="sensor-td">
      <span *ngIf="sensor.isActive" class="glyphicon glyphicon-ok text-success"></span>
      <span *ngIf="!sensor.isActive" class="glyphicon glyphicon-remove text-danger"></span>
    </td>
    <ng-container *ngIf="nodeHasCalibration">
      <td class="sensor-td" *ngIf="calibrationOptions && sensor.isActive; else noCalibration">
        <app-form-select  [type]="'border'"
                          [formControl]="calibrationControl"
                          class="sensor-td__control"
                          [options]="calibrationOptions"
                          [size]="'small'"></app-form-select>
      </td>
      <ng-template #noCalibration>
        <td class="sensor-td"></td>
      </ng-template>
    </ng-container>
    <td class="sensor-td">
        <app-form-select [type]="'border'"
                         [formControl]="unitControl"
                         *ngIf="sensor.units.length; else nounits"
                         class="sensor-td__control"
                         [options]="sensorOptions"
                         [size]="'small'"></app-form-select>
      <ng-template #nounits>
        <span >{{'No units' | translate}}</span>
      </ng-template>
    </td>
    <td class="sensor-td">
      <app-form-input [type]="'border'"
                      [formControl]="nameControl"
                      class="sensor-td__control"
                      [size]="'small'"></app-form-input>
    </td>
    <td class="sensor-td">
      <app-form-input [type]="'border'"
                      [styles]="{'cursor': 'pointer',
                                 'background-color': colorControl.value}"
                      class="sensor-td__control"
                      [isDisabled]="true"
                      [formControl]="colorControl"
                      [size]="'small'"></app-form-input>
      <div class="fake-color-picker" [colorPicker]="colorControl.value"
           (colorPickerChange)="colorChange($event)"
           [cpFallbackColor]="'#f5f5f5'"
           [cpOutputFormat]="'hex'"
           [cpAlphaChannel]="'disabled'"
           [cpPosition]="'bottom'"
           [cpPresetColors]="presetColors"
           [cpUseRootViewContainer]="true"></div>
    </td>
    <td class="sensor-td">
      <app-custom-button [content]="'Update'"
                         (click)="updateSensor()"></app-custom-button>
    </td>

    <td class="sensor-td sensor-td__last">
    </td>
</ng-container>
