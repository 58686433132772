import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPicture } from '../../../../shared/camera/models/camera';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { StationCameraTypes } from '../../../camera-data/constants/camera-data';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { ISelectedCameraPicture } from '../../models/crop-view.models';

@Component({
  selector: 'app-crop-view-image-gallery',
  templateUrl: './crop-view-image-gallery.component.html',
  styleUrls: ['./crop-view-image-gallery.component.scss']
})
export class CropViewImageGalleryComponent {
  @Input()
  public title: string = '';
  @Input()
  public deviceType: StationCameraTypes;
  @Input()
  public activeCameraId: number;
  @Input()
  public widePictures: Array<IPicture> = [];
  @Input()
  public zoomPictures: Array<IPicture> = [];

  @Output()
  public selectPictureEmitter: EventEmitter<ISelectedCameraPicture> = new EventEmitter<ISelectedCameraPicture>();

  public helpModalId: string = generateId();

  public constructor(private modalService: ModalService) {}

  public selectPicture(activePicture: IPicture, cameraId: number): void {
    this.selectPictureEmitter.emit({ picture: activePicture, activeCameraId: cameraId });
  }

  public openHelpModal(): void {
    this.modalService.openModal(this.helpModalId);
  }
}
