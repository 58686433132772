import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, zip } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { setNotify } from '../../core/actions/notify';
import { selectNavigationStation, selectSettings } from '../../core/reducers';
import { IAccount } from '../../core/reducers/account';
import { INavigationStationState } from '../../core/reducers/navigation-station';
import { INotifyState } from '../../core/reducers/notify';
import { stationDataContentAnimations } from '../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../core/services/left-components-toggler/left-components-toggler.service';
import { ApiCallService } from '../../services/api/api-call.service';
import { ITreeStructure } from '../../services/tree/models';
import { TreeService } from '../../services/tree/tree.service';
import { ISensor } from '../../shared/interfaces';
import { MODELS } from './constants';
import { IModel, IModelSetting, IModelSettingForm } from './models';

@Component({
  selector: 'app-station-models',
  templateUrl: './station-models.component.html',
  styleUrls: ['./station-models.component.scss'],
  animations: stationDataContentAnimations
})
export class StationModelsComponent implements OnInit {

  private readonly MODELS = MODELS;
  private stationId =       '';
  private loadingState:     BehaviorSubject<{ isLoading: boolean, isError: boolean }> = new BehaviorSubject({
    isLoading: true,
    isError: true
  });

  public tree$:             Observable<ITreeStructure>;
  public state$:            Observable<string>;
  public loadingState$ =    this.loadingState.asObservable();
  public settings$:         Observable<IModelSettingForm[]>;

  private defaultStr = '';

  constructor(
    private api: ApiCallService,
    private notify: Store<INotifyState>,
    private treeService: TreeService,
    private navigationStore: Store<INavigationStationState>,
    private accountStore: Store<IAccount>,
    private leftToggler: LeftComponentsTogglerService,
    private translations: TranslateService
  ) {
    this.translations.get('Default')
      .subscribe(translatedString =>
        this.defaultStr = translatedString);
  }

  public ngOnInit(): void {

    this.tree$ = this.treeService.getStationSettingsTreeStructure();
    this.state$ = this.leftToggler.getStationDataContentState();

    this.settings$ = this.navigationStore.pipe(
      select(selectNavigationStation),
      filter(station => !!station),
      map(station => station.name.original),
      distinctUntilChanged(),
      tap(stationId => this.stationId = stationId),
      tap(() => this.loadingState.next({
        isLoading: true,
        isError: false
      })),
      withLatestFrom(this.accountStore.pipe(
        select(selectSettings),
        filter(userSettings => !!userSettings),
        map(userSettings => userSettings.unit_system)
      )),
      switchMap(([stationId, unitSystem]) => zip(
        this.api.getStationSensors(stationId),
        this.api.getStationModels(stationId)
        )
          .pipe(
            map(([sensors, modelSettings]: [ISensor[], IModelSetting[]]): IModelSettingForm[] => {
              return this.MODELS.filter(model => {
                return !model.requiredSensorGroups.some(group => {
                  return !sensors.find(sensor => sensor.group === group && sensor.isActive);
                });
              }).map(model => ([model, (modelSettings.find(setting => setting.id === model.id) || {})]))
                .map(([model, setting]: [IModel, IModelSetting]) => ({
                  id: model.id,
                  name: model.name,
                  units: model.units.map(unit => ({
                    value: unit,
                    content: `${unit} ${model.defaultUnits[unitSystem] === unit ? ' (' + this.defaultStr + ')' : ''}`
                  })),
                  unit: model.defaultUnits[unitSystem],
                  color: model.defaultColor,
                  ...setting
                }));
            }),
            tap(() => this.loadingState.next({
              isLoading: false,
              isError: false
            })),
            catchError(() => {
              this.loadingState.next({
                isLoading: false,
                isError: true
              });
              return [];
            })
          )
      )
    );

  }

  public onUpdateClicked(setting: IModelSetting): void {
    this.api.updateStationModels(this.stationId, setting)
      .subscribe(
        suc => this.notify.dispatch(setNotify('The model\'s settings were updated successfully')),
        err => this.notify.dispatch(setNotify('The settings were not saved, due to the error'))
      );
  }

}
