import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { IStation } from '../../../../../core/models/stations';
import { Subject } from 'rxjs';
import { ILoggingTransferDevice, parseScheduler, parseSchedulerString } from '../../../models/station-config.models';
import { LoggingTransferGroups, StationConfigDefaultScheduler } from '../../../constants/constants';
import * as deepEqual from 'deep-equal';
import { StationTypes } from '../../../../../shared/constants';
import { ILoggingIsDefault } from '../../../reducers/loggingAndTransfer';
import { takeUntil } from 'rxjs/operators';
import { FormValidationService } from '../../../services/form-validation.service';

@Component({
  selector: 'app-scheduler-two',
  templateUrl: './scheduler-two.component.html',
  styleUrls: ['./scheduler-two.component.scss']
})
export class SchedulerTwoComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public station: IStation;
  @Input()
  public defaults: ILoggingIsDefault;

  @Output()
  private changeScheduler: EventEmitter<Array<Array<string>>> = new EventEmitter<Array<Array<string>>>();

  public form: FormGroup;
  private readonly steps: number = 6;
  private readonly stepSize: number = 4;

  public isIScout: boolean;
  public isCropView: boolean;
  private oldConfig: any;
  public overThreeHours: boolean = false;
  public isHourGapError: boolean = false;
  private deviceGroup: ILoggingTransferDevice;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private fb: FormBuilder,
    private formValidation: FormValidationService
  ) { }

  public get schedulerArray(): FormArray {
    return <FormArray>this.form.get('scheduler');
  }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.station && !deepEqual(changes.station.currentValue.name.original, changes.station.previousValue?.name.original)) {
      this.deviceGroup = LoggingTransferGroups.filter((group) => group.deviceIDs.includes(this.station.info.device_id))[0];

      this.form = this.fb.group({
        scheduler: this.createForm()
      });

      if (this.station.config.scheduler) {
        if (this.deviceGroup.led) {
          const configParse = {
            scheduler: parseScheduler(this.station.config.scheduler),
            logging_interval: this.station.config.logging_interval
          };
          this.isHourGapError = !this.formValidation.isFourHoursGap(parseSchedulerString(configParse));
          this.overThreeHours = this.formValidation.countOnes(parseScheduler(this.station.config.scheduler)) > 3 ? true : false;
        } else {
          this.isHourGapError = false;
          this.overThreeHours = false;
        }
        this.schedulerArray.setValue(parseScheduler(this.station.config.scheduler));
      }

      this.isIScout = this.checkIScout(this.station.info.device_id);
      this.isCropView = this.checkCropView(this.station.info.device_id);

      this.form.valueChanges.pipe(
        takeUntil(this.destroy$)
      ).subscribe((form) => {
        if (this.deviceGroup.led) {
          const schedulerConfig = { scheduler: form.scheduler, logging_interval: this.station.config.logging_interval };
          this.isHourGapError = !this.formValidation.isFourHoursGap(parseSchedulerString(schedulerConfig));
          this.overThreeHours = this.formValidation.countOnes(form.scheduler) > 3 ? true : false;
        }
        this.changeScheduler.emit(form.scheduler);
      });
    }
  }

  private createForm(): FormArray {
    const formArray: FormArray = this.fb.array([]);
    for (let i = 0; i < this.steps; i++) {
      const subArray: FormArray = this.fb.array([]);
      for (let j = 0; j < this.stepSize; j++) {
        subArray.push(this.fb.control('0'));
      }
      formArray.push(subArray);
    }
    return formArray;
  }

  private checkIScout(id: number):  boolean {
    return id === StationTypes.get('IScoutType')
      || id === StationTypes.get('IScoutMobileType');
  }

  private checkCropView(id: number): boolean {
    return id === StationTypes.get('CropViewType1')
    || id === StationTypes.get('CropViewType2')
    || id === StationTypes.get('CropViewType3');
  }

  public getTime(i: number, j: number): string {
    const hoursCount: number = (i * 4) + j;
    const hours: string = hoursCount < 10 ? `0${hoursCount}` : `${hoursCount}`;
    return `${hours}:00`;
  }

  public isSelectAllActive(): boolean {
    const schedulerString = this.schedulerArray
      .getRawValue()
      .reduce((p, a) => p.concat(a), [])
      .join('');
    return schedulerString === StationConfigDefaultScheduler.ALL_SELECTED_SCHEDULER;
  }

  public selectAll(value: string = '1', half: boolean = false): void {
    this.schedulerArray.controls.forEach((a: FormArray) => {
      a.controls.forEach((c: AbstractControl, index) => {
        if (half && index !== 2) {
          c.setValue('0');
        } else {
          c.setValue(value);
        }
      });
    });
  }

  public setDefaultValues(): void {
    this.schedulerArray.setValue(parseScheduler(this.deviceGroup.defaults[3]));
  }

  public getFormData(): any {
    return this.form.value.scheduler;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
