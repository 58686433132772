import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IActiveLicense} from '../models/user-licenses.models';

export const enum UserLicensesActionTypes {
  GET_USER_LICENSES = '[UserLicenses] GET_USER_LICENSES',
  SET_USER_LICENSES = '[UserLicenses] SET_USER_LICENSES',
  SELECT_USER_LICENSE = '[UserLicenses] SELECT_USER_LICENSE',
  UNSELECT_USER_LICENSE = '[UserLicenses] UNSELECT_USER_LICENSE',
}

export function getUserLicenses(): IActionWithPayload {
  return {type: UserLicensesActionTypes.GET_USER_LICENSES, payload: null};
}

export function setUserLicenses(userLicenses: Array<IActiveLicense>): IActionWithPayload {
  return {type: UserLicensesActionTypes.SET_USER_LICENSES, payload: userLicenses};
}

export function selectUserLicense(userLicense: IActiveLicense): IActionWithPayload {
  return {type: UserLicensesActionTypes.SELECT_USER_LICENSE, payload: userLicense};
}

export function unselectUserLicense(): IActionWithPayload {
  return {type: UserLicensesActionTypes.UNSELECT_USER_LICENSE, payload: null};
}
