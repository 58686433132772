import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../../shared/shared.module';
import {WorkPlanningRoutingModule} from './animal-production-routing.module';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {AnimalProductionEffects} from './effects/animal-production.service';
import {reducers} from './reducers';
import {ChartModule, HIGHCHARTS_MODULES} from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import {AgGridModule} from 'ag-grid-angular';
import {StationDataExportService} from '../../shared/services/export/station-data-export.service';
import { AnimalProductionHelpModalComponent } from './components/animal-production-help-modal/animal-production-help-modal.component';
import { AnimalProductionComponent } from './animal-production.component';
import { AnimalProductionHeaderComponent } from './components/animal-production-header/animal-production-header.component';
import { AnimalProductionChartsComponent } from './components/animal-production-charts/animal-production-charts.component';
import { AnimalProductionContentComponent } from './containers/animal-production-content/animal-production-content.component';
import { AnimalProductionWarningsComponent } from './components/animal-production-warnings/animal-production-warnings.component';

@NgModule({
  imports: [
    CommonModule,
    WorkPlanningRoutingModule,
    SharedModule,
    StoreModule.forFeature('animalProduction', reducers),
    EffectsModule.forFeature([AnimalProductionEffects]),
    TranslateModule.forChild(),
    ChartModule,
    AgGridModule.withComponents([]),
  ],
  declarations: [
    AnimalProductionComponent,
    AnimalProductionHeaderComponent,
    AnimalProductionChartsComponent,
    AnimalProductionContentComponent,
    AnimalProductionHelpModalComponent,
    AnimalProductionWarningsComponent,
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [ more, exporting ] }, // add as factory to your providers
    StationDataExportService
  ]
})
export class AnimalProductionModule { }
