import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ApiCallService } from '../../../services/api/api-call.service';
import { Observable, of } from 'rxjs';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import {
  setTrackerData,
  setTrackerError,
  setTrackerLoading,
  TrackerActionTypes,
  setTrackerReports,
  setMachineryReports,
  setMachineryInfo,
  setTrackerInfo
} from '../actions/tracker';
import { catchError, switchMap } from 'rxjs/operators';
import { from } from 'rxjs';

@Injectable()
export class TrackerDataEffect {

  constructor(private api: ApiCallService, private actions$: Actions) { }

  @Effect()
  public getTrackerData$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(TrackerActionTypes.GET_TRACKER_DATA),
    switchMap((action: IActionWithPayload) => this.api.getTrackerData(action.payload).pipe(
      switchMap((response: any) => of(
        setTrackerData(response.trip),
        setTrackerLoading(false),
        setTrackerError(response.trip.length === 0)
      )),
      catchError(() => from([
        setTrackerError(true),
        setTrackerLoading(false)
      ]))
    )),
  );

  @Effect()
  public getTrackerReports$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(TrackerActionTypes.GET_TRACKER_REPORTS),
    switchMap((action: IActionWithPayload) => this.api.getTrackerReports(action.payload).pipe(
      switchMap((response: any) => of(
        setTrackerReports(response)
      )),
      catchError(() => from([]))
    )),
  );

  @Effect()
  public getMachineryReports$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(TrackerActionTypes.GET_MACHINERY_REPORTS),
    switchMap((action: IActionWithPayload) => this.api.getMachineryReports(action.payload).pipe(
      switchMap((response: any) => of(
        setMachineryReports(response)
      )),
      catchError(() => from([]))
    )),
  );

  @Effect()
  public getTrackerInfo$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(TrackerActionTypes.GET_TRACKER_INFO),
    switchMap((action: IActionWithPayload) => this.api.getTracker(action.payload).pipe(
      switchMap((response: any) => of(
        setTrackerInfo(response),
      )),
      catchError(() => from([]))
    )),
  );

  @Effect()
  public getMachineryInfo$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(TrackerActionTypes.GET_MACHINERY_INFO),
    switchMap((action: IActionWithPayload) => this.api.getMachinery(action.payload).pipe(
      switchMap((response: any) => of(
        setMachineryInfo(response)
      )),
      catchError(() => from([]))
    )),
  );
}
