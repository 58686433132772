import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { catchError, map, switchMap } from 'rxjs/operators';
import { setNotify } from '../../../core/actions/notify';
import { ActionTypes, setStationSensors } from '../actions/actions';
import { ISensor } from '../../../shared/interfaces';
import { Injectable } from '@angular/core';
import { ApiCallService } from '../../../services/api/api-call.service';

@Injectable()
export class AccumulatorToolEffects {

  constructor(
    private actions$    : Actions,
    private api         : ApiCallService
  ) {
  }

  @Effect()
  public getStationSensors$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ActionTypes.GET_STATION_SENSORS),
    switchMap((action: IActionWithPayload) => this.api.getStationSensors(action.payload).pipe(
      map((sensors: ISensor[]) => setStationSensors(sensors)),
      catchError(() =>
        of(setNotify('Error getting data'))
      )
    ))
  );
}
