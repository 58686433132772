<app-header
  [notificationCount]="notificationCount"
  (rightStationsToggled)="openStations()"
  (userMenuToggled)="toggleUserMenu($event)"
  (notificationsToggled)="toggleNotifications($event)"
  (closeBar)="stationsAreOpened = false"
  (cropZonesToggled)="openCropzones()"></app-header>
<div class="content">
  <app-left-bar></app-left-bar>
  <app-hamburger></app-hamburger>
  <app-right-cropzones
    [cropzonesAreOpened]="cropzonesAreOpened"
    (closeBar)="cropzonesAreOpened = false"
    [cropzones]="(cropzones$ | async)"
  >
  </app-right-cropzones>
  <app-right-stations
    [stationsAreOpened]="stationsAreOpened"
    [stations]="(stations$ | async)"
    (closeBar)="stationsAreOpened = false"
  >
  </app-right-stations>
  <app-user-menu
    *ngIf="isMenuEnabled"
    (openAddRemoveModal)="openAddRemoveModal()"
    (openAddRemoveVirtualStationModal)="openAddRemoveVirtualStationModal()"
  ></app-user-menu>
  <app-notifications *ngIf="isNotificationsEnabled"></app-notifications>
</div>
<div class="page-content"><router-outlet></router-outlet></div>
<app-modal [modalId]="addRemoveModalId">
  <app-add-remove-station></app-add-remove-station></app-modal
><app-modal [modalId]="addRemoveVirtualStationModalId">
  <app-add-remove-virtual-station></app-add-remove-virtual-station></app-modal
>"
