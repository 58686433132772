<div *ngIf="image" class="image-modal">
  <div class="image-modal__header">
    <h4 class="image-modal__content">
      {{ imageType }} {{ image.nm }}: {{ image.time }}
    </h4>
    <span class="image-modal__close"
          (click)="closeEmit()">×</span>
  </div>
  <p class="image-modal__wheel-info">
    <small>
      <i class="fa fa-info-circle"></i>
      {{ 'Use the mouse wheel to zoom in/out of the picture.' | translate }}
    </small>
  </p>
  <div class="image-modal__container" #imageEL>
    <div class="image-modal__loading">
      <div class="image-modal__loading__arc-cube"></div>
    </div>
    <div class="image-modal__loading image-modal__loading__text">{{ 'Loading...' | translate }}</div>
    <div [style.background-image]="'url(' + image.url + ')'"
         [style.transform]="zoomLevel"
         [style.transform-origin]="transformOrigin"
         (mouseout)="mouseOut()"
         (mouseover)="mouseOver()"
         appMouseWheel
         (mouseWheelEventEmitter)="mouseWheel($event)"
         (mousemove)="mouseMove($event)"
         class="image-modal__image">
    </div>
  </div>
</div>
