import { Component, OnInit, Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { savePassword } from '../../../../../core/actions/account';
import * as fromAccount from '../../../../../core/reducers/account';
import { selectUserName } from '../../../../../core/reducers/index';
import { environmentToken } from '../../../../../../environments/environment';
import { IEnvironment } from '../../../../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../../../../environments/interfaces/theme';
import { cardAnimation } from '../../../..//../shared/animations/card-animations';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  animations: [cardAnimation()]
})
export class AccountSettingsComponent implements OnInit {
  public success              : boolean;
  public error                : boolean;
  public username$            : Observable<string>;
  public portalName           : string = 'FieldClimate';
  public description          : string = '';
  public subDomain            : IThemeConfig;

  constructor(
    @Inject(environmentToken) environment : IEnvironment,
    private accountStore      : Store<fromAccount.IAccount>,
    private translations      : TranslateService
  ) {
    this.subDomain = environment.theme;

    if (this.subDomain.hasOwnProperty('portalName')) {
      this.translations.get(this.subDomain.portalName).subscribe(translatedValue => {
          this.portalName = translatedValue;
      });
    }
  }

  public ngOnInit(): void {
    this.username$ = this.accountStore.pipe(select(selectUserName));
    this.getDescription();
  }

  public submit(password: string): void {
    this.accountStore.dispatch(savePassword(password));
  }

  private getDescription(): void {
    this.description = 'login settings';
    this.translations.get(this.description)
      .subscribe(translatedValue => {
        this.description = this.portalName + ' ' + translatedValue;
      });
  }

}
