import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { area, centroid, distance, kinks, polygon } from '@turf/turf';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { updateCropzone } from '../../../../core/actions/cropzones';
import { getProductLicenses } from '../../../../core/actions/licenses';
import { setNotify } from '../../../../core/actions/notify';
import { setSelectedCropZone } from '../../../../core/actions/selectedCropZone';
import { LicenseFetchMode } from '../../../../core/models/licenses';
import { selectSelectedCropZone } from '../../../../core/reducers';
import * as fromLicenses from '../../../../core/reducers/licenses';
import * as fromNotify from '../../../../core/reducers/notify';
import * as fromSelectedCropzone from '../../../../core/reducers/selectedCropZone';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { MAX_SURFACE_AREA_POLYGON } from '../../../../shared/constants';
import { setLaiStats, setVisibleLaiDates, setVisibleLaiVals } from '../../../crop-zone-sat/actions';
import { setNdviStats, setVisibleNdviDates, setVisibleNdviVals } from '../../../crop-zone-sat/actions/ndvi';
import * as fromSatStore from '../../../crop-zone-sat/reducers';
import { getLocation } from '../../actions/timezone-location';
import * as fromCropzoneName from '../../reducers/cropzone-name';
import * as fromTimezoneAndLocation from '../../reducers/timezone-location';
import { ICropZone } from './../../../../core/models/cropzones';
import { IAccount } from './../../../../core/reducers/account';
import { CropzoneConfigService } from '../../services/cropzone-config.service';
import * as JSZip from 'jszip';
import * as toGeoJSON from '@mapbox/togeojson';

@Component({
  selector: 'app-cropzone-map-form',
  templateUrl: './cropzone-map-form.component.html',
  styleUrls: ['./cropzone-map-form.component.scss'],
})

export class CropzoneMapFormComponent implements OnInit, OnDestroy {
  public fileToUpload       : File = null;
  public selectedCropzone   : ICropZone;
  public receivedCoords     : any;
  public form               : FormGroup;
  public storeColor         : string = 'blue';
  public licenseType         : string = 'FarmView';
  public alive$ = new Subject();

  public customFileLabel    : string = 'custom-file-label';
  public drawButton         : boolean;
  public editButton         : boolean;
  public importButton       : boolean;
  public storeButton        : boolean;
  public drawDisabled       : any;
  public showAlert          : boolean;
  private disabledColor     : string = 'blue';
  private enabledColor      : string = 'green';
  public uploadButtonBorder : string = '2px';

  @ViewChild('myFileInput', {static: true})
  private myFileInput: any;

  @Output()
  public buttonClicked = new EventEmitter<{ button: string, boundary?: any }>();

  @Output()
  public rebound = new EventEmitter<any>();

  @Output()
  public sendingOldBoundaries = new EventEmitter<any>();

  @Input()
  public set coords(coords: any) {
    if (coords === undefined) {
      this.storeColor = this.disabledColor;
      this.storeButton = false;
    } else if (coords) {
      this.storeButton = true;
      this.storeColor = this.enabledColor;
      this.receivedCoords = coords;
    }
  }

  @Input()
  public set storeActive(active: boolean) {
    if (active !== undefined) {
      if (active) {
        this.storeButton = true;
        this.storeColor = this.enabledColor;
      } else {
        this.storeButton = false;
        this.storeColor = this.disabledColor;
      }
    }
  }

  constructor(
    private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private cropzoneNameStore: Store<fromCropzoneName.ICropzoneNameState>,
    private satStore: Store<fromSatStore.ICropZoneSatState>,
    private licenseStore: Store<fromLicenses.IProductLicenseState>,
    private locationStore: Store<fromTimezoneAndLocation.ITimezoneAndLocationState>,
    private notifyStore: Store<fromNotify.INotifyState>,
    private accountStore: Store<IAccount>,
    private api: ApiCallService,
    private fb: FormBuilder,
    private configService: CropzoneConfigService
  ) { }

  public get search(): AbstractControl {
    return this.form.get('search');
  }

  public get importFile(): AbstractControl {
    return this.form.get('importFile');
  }

  public find(): void {
    this.locationStore.dispatch(getLocation(this.search.value));
    this.search.setValue('');
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      search: ['', []]
    });

    this.selectedCropzoneStore
      .pipe(
        select(selectSelectedCropZone),
        filter((cropzone) => !!cropzone),
        takeUntil(this.alive$)
      )
      .subscribe((cropzone) => {
        this.selectedCropzone = cropzone;

        if (cropzone.boundary) {
          this.drawButton = false;
          this.editButton = true;
          this.importButton = false;
          this.storeButton = false;
          this.customFileLabel = 'custom-file-label-disabled';
        } else {
          this.drawButton = true;
          this.editButton = false;
          this.importButton = true;
          this.storeButton = false;
          this.customFileLabel = 'custom-file-label';
        }
        this.showAlert = false;
        this.storeColor = this.disabledColor;
      });
  }

  public onFileChange(files: any): void {
    if (files.item(0)) {
      this.fileToUpload = files.item(0);

      if (this.getFileExtension(files.item(0).name).toLowerCase() === 'zip') {
        const fd = new FormData();
        fd.append('fileData', this.fileToUpload);

        this.api.uploadShapeFile(fd).subscribe(
          (res: any) => {
            if (res) {
              this.sendBoundaries(res);
              this.uploadButtonBorder = '2px 2px 0px 0px';
            }
          },
          (err) => {
            this.uploadButtonBorder = '2px';
            this.fileToUpload = null;
            this.drawDisabled = false;
            this.notifyStore.dispatch(setNotify('No boundaries found on the imported zip file.'));
          }
        );
      } else if (this.getFileExtension(files.item(0).name).toLowerCase() === 'geojson') {
        const fileReader = new FileReader();
        fileReader.readAsText(files.item(0), 'UTF-8');
        fileReader.onload = () => {
          const geoJsonObject = JSON.parse(<string>fileReader.result);

          if (geoJsonObject.features.length > 0) {
            this.sendBoundaries(geoJsonObject);
          } else {
            this.notifyStore.dispatch(setNotify('No boundaries found on the imported geoJson.'));
          }
        };
      } else if (this.getFileExtension(files.item(0).name).toLowerCase() === 'kmz') {
        const getDom = xml => (new DOMParser()).parseFromString(xml, 'text/xml');
        const getExtension = fileName => fileName.split('.').pop();

        const getKmlDom = (kmzFile) => {
          const zip = new JSZip();
          return zip.loadAsync(kmzFile)
            .then(kml => {
            let kmlDom = null;
            kml.forEach((relPath, file) => {
              if (getExtension(relPath) === 'kml' && kmlDom === null) {
                kmlDom = file.async('string').then(getDom);
              }
            });
            return kmlDom || Promise.reject('No kml file found');
          });
        };

        const geoJson = getKmlDom(files.item(0)).then(kmlDom => {
          const geoJsonObject = toGeoJSON.kml(kmlDom);
          this.sendkmzBoundaries(geoJsonObject.features);
        });
      } else {
        this.uploadButtonBorder = '2px';
        this.fileToUpload = null;
        this.notifyStore.dispatch(
          setNotify('Imported file must be a geoJson or zip file.')
        );
      }
    }
  }

  private sendBoundaries(result): void {
    result.features.forEach((feature, index1) => {
      feature.geometry.coordinates[0].forEach((coords, index) => {
        if (index !== 0) {
          if (JSON.stringify(feature.geometry.coordinates[0][index]) === JSON.stringify(feature.geometry.coordinates[0][index - 1])) {
            feature.geometry.coordinates[0].splice(index, 1);
          }
        }
      });
    });

    if (result.features.length > 1) {
      this.showAlert = true;
    }

    this.sendingOldBoundaries.emit(result);
    this.drawButton = false;
    this.editButton = false;
    this.importButton = false;
    this.customFileLabel = 'custom-file-label-disabled';
  }

  private sendkmzBoundaries(result): void {
    const geojson = {
      type: 'FeatureCollection',
      features: []
    };
    result.forEach((features, index) => {
      features['geometry'].geometries.forEach((geometry, i) => {
        geojson.features.push({
          type: 'Feature',
          geometry: geometry
        });
      });
    });
    this.sendBoundaries(geojson);
  }

  public getFileExtension(filename: string): string {
    return filename.split('.').pop();
  }

  public deleteFieldOnMap(): void {
    this.drawButton = true;
    this.editButton = false;
    this.importButton = true;
    this.customFileLabel = 'custom-file-label';
    this.storeButton = false;
    this.buttonClicked.emit({ button: 'delete' });

    this.drawDisabled = false;
    this.showAlert = false;
    this.storeColor = this.disabledColor;
    this.sendingOldBoundaries.emit(undefined);
    this.uploadButtonBorder = '2px';
    this.fileToUpload = null;
  }

  public drawFieldOnMap(): void {
    this.drawButton = false;
    this.editButton = false;
    this.importButton = false;
    this.customFileLabel = 'custom-file-label-disabled';
    this.storeButton = false;
    this.showAlert = false;

    this.fileToUpload = null;
    this.uploadButtonBorder = '2px';
    this.buttonClicked.emit({ button: 'draw' });
  }

  public editFieldOnMap(): void {
    this.showAlert = false;
    this.editButton = false;
    this.buttonClicked.emit({ button: 'edit' });
  }

  public store(): void {
    if (this.receivedCoords) {
      const boundary: any = {
        type: 'Polygon',
        coordinates: this.receivedCoords.coordinates,
      };
      const feature: any = {
        geometry: boundary,
        properties: {},
        type: 'Feature'
      };

      this.receivedCoords.coordinates[0].forEach((coord, index) => {
        if (index !== 0) {
          if (JSON.stringify(this.receivedCoords.coordinates[0][index]) === JSON.stringify(this.receivedCoords.coordinates[0][index - 1])) {
            this.receivedCoords.coordinates[0].splice(index, 1);
          }
        }
      });

      const poly = polygon(this.receivedCoords.coordinates);
      const areaData = area(poly);
      if (areaData / 1000000 <= MAX_SURFACE_AREA_POLYGON) {
        const kinksData = kinks(feature);
        if (kinksData.features.length > 0) {
          this.notifyStore.dispatch(
            setNotify(
              'Boundaries are not allowed to have self-intersecting points.'
            )
          );
        } else {
          const receivedvCentroid = centroid(this.receivedCoords);

          if (this.selectedCropzone.boundary) {
            const existingCentroid = centroid(this.selectedCropzone.boundary);
            const distanceBetweenPoints = distance(receivedvCentroid, existingCentroid, {units: 'meters'});

            if (distanceBetweenPoints > 30000) {
              this.selectedCropzone = this.configService.removeIrrimetSettings(this.selectedCropzone);
              this.notifyStore.dispatch(
                setNotify('Distance between new and old boundaries is too big - irrimet and soil moisture settings are reset')
              );
            }
          }

          this.selectedCropzone.boundary = this.receivedCoords;
          this.storeColor = this.disabledColor;

          if (this.form) {
            this.search.setValue('');
          }

          this.licenseStore.dispatch(getProductLicenses(this.licenseType, LicenseFetchMode.AVAILABLE));

          this.accountStore.dispatch(
            updateCropzone(this.selectedCropzone)
          );
          this.selectedCropzoneStore.dispatch(
            setSelectedCropZone(this.selectedCropzone)
          );
          this.satStore.dispatch(
            setLaiStats(this.selectedCropzone.id, null)
          );
          this.satStore.dispatch(
            setVisibleLaiDates(this.selectedCropzone.id, null)
          );
          this.satStore.dispatch(
            setVisibleLaiVals(this.selectedCropzone, [])
          );
          this.satStore.dispatch(
            setNdviStats(this.selectedCropzone.id, null)
          );
          this.satStore.dispatch(
            setVisibleNdviDates(this.selectedCropzone.id, null)
          );
          this.satStore.dispatch(
            setVisibleNdviVals(this.selectedCropzone, [])
          );

          this.buttonClicked.emit({ button: 'store', boundary: this.selectedCropzone.boundary });
          this.rebound.emit(this.selectedCropzone.boundary);
        }
      } else {
        this.notifyStore.dispatch(
          setNotify(
            'The surface area of the boundaries are too big, must be under 25km².'
          )
        );
      }

      this.fileToUpload = null;
      this.uploadButtonBorder = '2px';
      this.showAlert = false;
      this.drawButton = false;
      this.editButton = true;
      this.importButton = false;
      this.storeButton = false;
      this.storeColor = this.disabledColor;
    }
  }

  public importClick(): void {
    this.myFileInput.nativeElement.value = '';
    this.drawDisabled = true;
  }

  public ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }
}
