import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  getWidgetData,
  removeWidget,
  setWidgetError,
  setWidgetLoading,
  updateWidgetEditMode,
  updateWidgetSettings
} from '../../../actions/dashboard';
import { IForecastTableRequest, ILoadWidgetData, IUpdateWidgetEditMode, IWidget } from '../../../models/dashboard.models';
import { selectEdit } from '../../../reducers';
import * as fromDashboard from '../../../reducers/dashboard';

@Component({
  selector: 'app-forecast-table-widget',
  templateUrl: './forecast-table-widget.component.html',
  styleUrls: ['./forecast-table-widget.component.css']
})
export class ForecastTableWidgetComponent implements OnInit {
  @Input()
  public widget                      : IWidget;
  @Input()
  private widgetIndex                : number;
  @Input()
  private columnIndex                : number;
  @Input()
  private rowIndex                   : number;
  public isEdit$                     : Observable<boolean>;
  private loadWidgetData             : ILoadWidgetData;

  constructor(private dashboardStore: Store<fromDashboard.IDashboard>) {}

  public ngOnInit(): void {
    this.isEdit$ = this.dashboardStore.pipe(select(selectEdit));
    if (!this.widget.loadData) {
      this.loadTableData();
    }
  }

  public removeCurrentWidget(): void {
    const path: Array<number> = [this.rowIndex, this.columnIndex, this.widgetIndex];
    this.dashboardStore.dispatch(removeWidget(path));
  }

  public loadTableData(): void {

    const pathway: string = '/ag-grid/' + this.widget.requests.forecastTable.endpoint.station
      + '/' + this.widget.requests.forecastTable.endpoint.profile;

    this.loadWidgetData = {
      url: pathway,
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      profile: this.widget.requests.forecastTable.endpoint.profile
    };
    this.resetLoading();
    this.dashboardStore.dispatch(getWidgetData(this.loadWidgetData));
  }

  private resetLoading(): void {
    this.dashboardStore.dispatch(setWidgetLoading({
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      loading: true
    }));
    this.dashboardStore.dispatch(setWidgetError({
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      error: ''
    }));
  }

  public updateRequest(updateSettings: IForecastTableRequest): void {
    this.dashboardStore.dispatch(updateWidgetSettings({
      type: 'forecastTable',
      updateSettings: updateSettings,
      path: [this.rowIndex, this.columnIndex, this.widgetIndex]
    }));
  }

  public editMode(editMode: boolean): void  {
    const updateSettings: IUpdateWidgetEditMode = {
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      type: 'forecastTable',
      isEdit: editMode,
    };
    this.dashboardStore.dispatch(updateWidgetEditMode(updateSettings));
  }
}
