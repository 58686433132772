import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IValidLicense } from '../../models/product-activation.models';

@Component({
  selector: 'app-product-activation-license-alert',
  templateUrl: './product-activation-license-alert.component.html',
  styleUrls: ['./product-activation-license-alert.component.scss'],
  providers: [DatePipe]
})
export class ProductActivationLicenseAlertComponent {
  @Input()
  public licensesOverlapped: IValidLicense[] = [];
}
