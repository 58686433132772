import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from 'ag-grid-enterprise';
import { AppModule } from './app/app.module';
import { IEnvironment } from './environments/interfaces/environment';
import { environmentToken } from './environments/environment';
import { getAvailableDomain } from './environments/domains';

LicenseManager.setLicenseKey('Pessl_Instruments_GmbH_Fieldclimate_1Devs2_March_2018__MTUxOTk0ODgwMDAwMA==735f362b2a883b6561e7206130be2f4b');

const targetDomain = getAvailableDomain();
import(`./assets/themes/${targetDomain}/configuration`)
  .then(({ environment }): any => bootstrapApplication(environment))
  .catch(err => console.log(err));
function bootstrapApplication(environment: IEnvironment): Promise<any> {
  if (environment.theme.colorConfig) {
    Object.keys(environment.theme.colorConfig).forEach(property => {
      document.documentElement.style.setProperty(
        property,
        environment.theme.colorConfig[property]
      );
    });
  }
  if (environment.production) {
    enableProdMode();
  }
  const extraProviders = [{
    provide: environmentToken,
    useValue: environment,
  }];
  return platformBrowserDynamic(extraProviders)
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
}
