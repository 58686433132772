import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: '[appPhoneNumber]'
})
export class PhoneNumberDirective {

  constructor() { }

  private regex: RegExp = /[0-9+]/;

  @HostListener('keypress', ['$event'])
  public onKeyDown(event): void {
    const e: KeyboardEvent = <KeyboardEvent> event;
    const ch: string = String.fromCharCode(e.charCode);
    if (this.regex.test(ch)) {
      return;
    } else {
      e.preventDefault();
    }
  }

}
