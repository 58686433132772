import { Component, EventEmitter, OnInit, Inject, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environmentToken } from '../../../../../environments/environment';
import { IEnvironment, ILeftBarMenuItem } from '../../../../../environments/interfaces/environment';
import { LicenseType } from '../../../../modules/product-activation/constants/constants';
import { StationTypes } from '../../../../shared/constants';
import { chartRedraw } from '../../../../shared/utils/chartRedraw';
import { getProductLicenses } from '../../../actions/licenses';
import { ICropZone } from '../../../models/cropzones';
import { LicenseFetchMode } from '../../../models/licenses';
import { INavigationAccess } from '../../../models/navigation-station';
import { ISelectedSearchWidgetItem } from '../../../models/selectedSearchWidgetItem';
import { IStation } from '../../../models/stations';
import {
  selectAvailableLicenses,
  selectNavigationAccess,
  selectSelectedCropZone,
  selectSelectedStation,
  selectStations
} from '../../../reducers';
import * as fromLicenses from '../../../reducers/licenses';
import * as fromNavigationStation from '../../../reducers/navigation-station';
import * as fromSelectedCropzone from '../../../reducers/selectedCropZone';
import * as fromSelectedStation from '../../../reducers/selectedStation';
import * as fromStations from '../../../reducers/stations';
import { leftBarAnimations } from '../../../services/left-components-toggler/animation.constants';
import { LeftComponentsCropzoneTogglerService } from '../../../services/left-components-toggler/left-components-cropzone-toggler.service';
import { LeftComponentsTogglerService } from '../../../services/left-components-toggler/left-components-toggler.service';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { INewModulesTextObj } from '../../../models/account';

@Component({
  selector: 'app-left-bar',
  templateUrl: './left-bar.component.html',
  styleUrls: ['./left-bar.component.scss'],
  animations: leftBarAnimations
})
export class LeftBarComponent implements OnInit {

  public menuItems: ILeftBarMenuItem;

  public barState$: Observable<string>;
  public treeCanBeShown$: Observable<boolean>;
  public treeIsDisplayed$: Observable<boolean>;

  @Output()
  private navigate: EventEmitter<string> = new EventEmitter<string>();

  public navigationAccess$: Observable<INavigationAccess>;
  public selectedStation$: Observable<ISelectedSearchWidgetItem>;
  public selectedCropzone$: Observable<ICropZone>;
  public selectedCropzone: ICropZone;
  public cropzoneOrStation: string = '';
  public station: string = 'station';
  public stations: Array<IStation>;
  public dashboard: string = 'dashboard';
  public cropzone: string = 'cropzone';
  public themeName: string;

  private irrimetLicense: boolean = false;
  private satelliteLicense: boolean = false;
  private soilMoistureLicense: boolean = false;
  private yieldPredictionLicense: boolean = false;
  public newModulesTexts: Array<INewModulesTextObj>;
  public moduleTexts: Array<INewModulesTextObj> = [];

  private currentRoutePath: string = this.activatedRoute.firstChild.firstChild.routeConfig.path;
  private stationSettingsRoutePaths: Array<string> = [
    ':id/config',
    ':id/info',
    ':id/events',
    ':id/models',
    ':id/sensor-names',
    ':id/sms-warnings',
    ':id/server-alerts',
    ':id/communication',
    ':id/delete-weather-data',
    ':id/correct-precipitation-data',
    'id/machinery-settings',
    ':id/ultrasonic-distance-settings',
  ];
  private cropzoneSettingsRoutePaths: Array<string> = [
    ':id/config',
    ':id/irrimet-config',
    ':id/yield-prediction-config'
  ];
  private irrimetRoutePaths: Array<string> = [
    ':id/irrimet'
  ];
  private cropzoneSamplesRoutePaths = [
    '/cropzone-samples/nutrition',
    '/cropzone-samples/soilguard',
  ];
  private soilMoistureRoutePaths: Array<string> = [
    ':id/soil-moisture',
    ':id/soil-moisture/sums-sm'
  ];
  private yieldPredictionRoutePaths: Array<string> = [
    ':id/yield-prediction',
    ':id/yield-prediction/prediction',
    ':id/yield-prediction/previous'
  ];
  private satelliteRoutePaths: Array<string> = [
    ':id/sat'
  ];
  private camerasRoutePaths: Array<string> = [
    ':id/cropview',
    ':id/camera-data',
    ':id/camera-slideshow',
    ':id/iscout',
    ':id/iscout/pests',
    ':id/iscout/glue-boards',
    ':id/iscout/seasons'
  ];
  private stationWeatherForecastRoutePath: string = ':id/weather-forecast';
  private stationWorkPlanningRoutePath: string = ':id/work-planning';
  private stationAnimalProductionRoutePath: string = ':id/animal-production';
  private stationAccumulatorToolRoutePath: string = ':id/accumulator-tool';
  private stationSoilGuardRoutePath: string = ':id/samples/soil-guard';
  private stationMobilabRoutePath: string = ':id/samples/mobilab';
  private stationDualexRoutePath: string = ':id/samples/dualex';
  private stationSampleRoutePath: string = ':id/samples';
  private stationHydroponicslRoutePath: string = ':id/hydroponics';
  private stationExtremeMonitoringRoutePath: string = ':id/extreme-weather-monitoring';

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    private navigationStore: Store<fromNavigationStation.INavigationStationState>,
    private selectedStationStore: Store<fromSelectedStation.ISelectedStationState>,
    private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private selectedStore: Store<fromSelectedStation.ISelectedStationState>,
    private licenseStore: Store<fromLicenses.IProductLicenseState>,
    private leftComponentsToggler: LeftComponentsTogglerService,
    private leftComponentsCropzoneToggler: LeftComponentsCropzoneTogglerService,
    private activatedRoute: ActivatedRoute,
    private stationsStore: Store<fromStations.IStations>,
    private router: Router,
    private api: ApiCallService
  ) {

    this.treeCanBeShown$ = this.leftComponentsToggler.treeCanBeShown$;

    this.themeName = environment.theme.subDomain;
    this.menuItems = environment.displayRules.leftBarMenu;

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd): void => {
      this.cropzoneOrStation = this.activatedRoute.firstChild.routeConfig.path;
      this.currentRoutePath = this.activatedRoute.firstChild.firstChild.routeConfig.path;
    });
  }

  public changeStationVisible(): void {
    chartRedraw(300);
    this.leftComponentsToggler.toggleStation();
  }

  public changeCropzoneVisible(): void {
    chartRedraw(300);
    this.leftComponentsCropzoneToggler.toggleCropzone();
  }

  public ngOnInit(): void {
    combineLatest([
      this.licenseStore.pipe(
        select(selectAvailableLicenses),
        filter((licenses) => !!licenses)
      ),
      this.selectedCropzoneStore.pipe(
        select(selectSelectedCropZone),
        filter((cropzone) => !!cropzone)
      )
    ]).subscribe(([licenses, cropzone]) => {
      this.irrimetLicense = false;
      this.soilMoistureLicense = false;
      this.satelliteLicense = false;
      this.yieldPredictionLicense = false;

      if (cropzone.product_key) {
        licenses
            .filter(license => license.product_item.key === cropzone.product_key)
            .forEach(license => {
              this.irrimetLicense = license.Irrimet;
              this.satelliteLicense = license.Satellite;
              this.soilMoistureLicense = license.SoilMoisture;
              this.yieldPredictionLicense = license.YieldPrediction;
            });
      }
    });

    this.api.getNewModulesTexts().subscribe((texts) => {
      this.moduleTexts = texts;
    });

    this.licenseStore.dispatch(getProductLicenses(LicenseType.FARM_VIEW, LicenseFetchMode.ALL));

    this.navigationAccess$ = this.navigationStore.pipe(
      select(selectNavigationAccess)
    );

    this.selectedStation$ = this.selectedStationStore.pipe(
      select(selectSelectedStation)
    );

    this.stationsStore.pipe(
      select(selectStations),
      filter(_ => Array.isArray(_))
    ).subscribe(stations => {
      this.stations = stations;
    });

    this.selectedCropzone$ = this.selectedCropzoneStore.pipe(
      select(selectSelectedCropZone)
    );

    this.selectedCropzoneStore.pipe(
      select(selectSelectedCropZone)
    ).subscribe(cropzone => this.selectedCropzone = cropzone);

    this.barState$ = this.leftComponentsToggler.getLeftBarState();
    this.treeIsDisplayed$ = this.leftComponentsToggler.getTreeIsDisplayed();

  }

  public isStationSettingsPage(): boolean {
    if (this.cropzoneOrStation === 'station') {
      return this.stationSettingsRoutePaths.indexOf(this.currentRoutePath) !== -1;
    } else {
      return false;
    }
  }

  public isCropzoneSettingsPage(): boolean {
    if (this.cropzoneOrStation === 'cropzone') {
      return this.cropzoneSettingsRoutePaths.indexOf(this.currentRoutePath) !== -1;
    } else {
      return false;
    }
  }

  public irrimetAccess(): boolean {
    return (this.isEvapotranspirationSensor() || this.isManualRainGauge()) && this.irrimetLicense === true;
  }

  private isEvapotranspirationSensor(): boolean {
    return this.selectedCropzone.data_sources.some(
      datasource => datasource.module === 'IRRIMET'
      && datasource.source.type === 'SENSOR'
    )
    && this.selectedCropzone.data_sources.some(
      datasource => datasource.module === 'IRRIMET'
      && datasource.source['id'] === 'evapotranspiration'
    )
    && this.selectedCropzone.crop !== null;
  }

  private isManualRainGauge(): boolean {
    return this.selectedCropzone.data_sources.some(
      datasource => datasource.module === 'IRRIMET'
      && datasource.source['id'] === 'evapotranspiration'
    )
    && this.selectedCropzone.manualRainGauge
    && this.selectedCropzone.crop !== null;
  }

  public soilMoistureSumAccess(): boolean {
    let access: boolean = false;
    if (!this.soilMoistureLicense) {
      return false;
    }

    this.selectedCropzone.data_sources.forEach(dataSource => {
      if (dataSource.source.type === 'SOIL_MOISTURE' || dataSource.source.type === 'SOIL_MOISTURE_SENSOR_OR_PROBE') {
        access = true;
      }
    });
    return access;
  }

  public satelliteAccess(): boolean {
    return this.selectedCropzone.boundary !== null && this.satelliteLicense;
  }

  public yieldPredictionAccess(): boolean {
    return this.selectedCropzone.boundary !== null && this.yieldPredictionLicense;
  }

  public cropzoneSoilGuardAccess(): boolean {
    if (this.stations) {
      return this.stations.some(station => {
        return StationTypes.get('SoilGuard Probe') === station.info.device_id;
      });
    }
  }

  public cropzoneDualexAccess(): boolean {
    if (this.stations) {
      return this.stations.some(station => {
        return StationTypes.get('Dualex Device') === station.info.device_id;
      });
    }
  }

  public isIrrimetPage(): boolean {
    if (this.cropzoneOrStation === 'cropzone') {
      return this.irrimetRoutePaths.indexOf(this.currentRoutePath) !== -1;
    } else {
      return false;
    }
  }

  public isSoilMoisturePage(): boolean {
    if (this.cropzoneOrStation === 'cropzone') {
      return this.soilMoistureRoutePaths.indexOf(this.currentRoutePath) !== -1;
    } else {
      return false;
    }
  }

  public isYieldPredictionPage(): boolean {
    if (this.cropzoneOrStation === 'cropzone') {
      return this.yieldPredictionRoutePaths.indexOf(this.currentRoutePath) !== -1;
    } else {
      return false;
    }
  }

  public isSatellitePage(): boolean {
    if (this.cropzoneOrStation === 'cropzone') {
      return this.satelliteRoutePaths.indexOf(this.currentRoutePath) !== -1;
    } else {
      return false;
    }
  }

  public isCropzoneSoilGuard(): boolean {
    if (this.cropzoneOrStation === 'cropzone') {
      return this.router.url.includes(this.cropzoneSamplesRoutePaths[1]);
    } else {
      return false;
    }
  }

  public isCropzoneNutrition(): boolean {
    if (this.cropzoneOrStation === 'cropzone') {
      return this.router.url.includes(this.cropzoneSamplesRoutePaths[0]);
    } else {
      return false;
    }
  }

  public isStationWeatherForecastPage(): boolean {
    if (this.cropzoneOrStation === 'station') {
      return this.stationWeatherForecastRoutePath === this.currentRoutePath;
    } else {
      return false;
    }
  }

  public isStationSample(): boolean {
    if (this.cropzoneOrStation === 'station') {
      return this.stationSampleRoutePath === this.currentRoutePath;
    } else {
      return false;
    }
  }

  public isStationWorkPlanningPage(): boolean {
    if (this.cropzoneOrStation === 'station') {
      return this.stationWorkPlanningRoutePath === this.currentRoutePath;
    } else {
      return false;
    }
  }

  public isStationAnimalProductionPage(): boolean {
    if (this.cropzoneOrStation === 'station') {
      return this.stationAnimalProductionRoutePath === this.currentRoutePath;
    } else {
      return false;
    }
  }

  public isStationAccumulatorToolPage(): boolean {
    if (this.cropzoneOrStation === 'station') {
      return this.stationAccumulatorToolRoutePath === this.currentRoutePath;
    } else {
      return false;
    }
  }

  public isHydroponicsPage(): boolean {
    if (this.cropzoneOrStation === 'station') {
      return this.stationHydroponicslRoutePath === this.currentRoutePath;
    } else {
      return false;
    }
  }

  public isExtremeMonitoringPage(): boolean {
    if (this.cropzoneOrStation === 'station') {
      return this.stationExtremeMonitoringRoutePath === this.currentRoutePath;
    } else {
      return false;
    }
  }

  public isStationCamerasPage(): boolean {
    return this.camerasRoutePaths.indexOf(this.currentRoutePath) !== -1;
  }
}
