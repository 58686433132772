<app-tree [tree$]="tree$"></app-tree>
<div class="body-view-content" [@placeContent]="state$ | async">
  <app-station-page-header [pageName]="'Station tools'"></app-station-page-header>
  <div class="form-container" *ngIf="navigationStation">
    <app-form-layout *ngIf="canDelete(); else warning">
      <div class="form-container__header">
        <h2>{{ 'Delete data for station' | translate }} {{navigationStation.name.original}}</h2>
      </div>
      <div *ngIf="!hasSuccessResponse" class="form-container__body">
        <div class="notification">{{ notificationText | translate }}</div>
        <app-custom-checkbox [formControl]="shouldDeleteAllData"
                             class="terms__checkbox">
          {{ 'Delete ALL station data' | translate }}
        </app-custom-checkbox>
        <div *ngIf="datepickerIsEnabled()">
          <div class="form-row">
            <div class="label-container">
              <h5>{{ 'First date' | translate }}:</h5>
            </div>
            <div class="input-container">
              <app-custom-datepicker [formControl]="from"
                                     [maxDate]="to.value"></app-custom-datepicker>
            </div>
          </div>
          <div class="form-row">
            <div class="label-container">
              <h5>{{ 'Last date' | translate }}:</h5>
            </div>
            <div class="input-container">
              <app-custom-datepicker [formControl]="to"
                                     [minDate]="from.value"></app-custom-datepicker>
            </div>
          </div>
        </div>
        <div class="form-row station-key-container">
          <div class="label-container">
            <h5>{{ 'Station key' | translate }}:</h5>
          </div>
          <div class="input-container">
            <app-form-input [placeholder]="stationKeyPlaceholder"
                            [type]="'border'"
                            [formControl]="stationKey"></app-form-input>
          </div>
        </div>
        <div class="form-row">
          <div *ngIf="!showConfirmBlock; else confirmBlock" class="button-container">
            <app-custom-button [color]="'red'"
                               [content]="'Delete'"
                               [disabled]="buttonIsDisabled()"
                               (click)="clickDelete()"></app-custom-button>
          </div>
          <ng-template #confirmBlock>
            <div class="confirmation-text">
              <p>{{ confirmationText1 | translate }}</p>
              <p><strong>{{ confirmationText2 | translate }}</strong></p>
              <p>For more information see
                <a href="http://metos.at/home/privacy-policy/" target="_blank">{{ 'Privacy Policy' | translate }}</a>
                and
                <a href="http://metos.at/home/use-terms/" target="_blank">{{ 'Terms of use' | translate }}</a>
              </p>
            </div>
            <div class="button-container">
              <app-custom-button [content]="'Cancel'"
                                 (click)="cancelConfirmation()"></app-custom-button>
              <app-custom-button [color]="'red'"
                                 [content]="'Yes, Delete'"
                                 (click)="confirm()"></app-custom-button>
            </div>
          </ng-template>
        </div>
      </div>
    </app-form-layout>

    <ng-template #warning>
      <div  class="warning">
        {{ 'You have read-only rights to this station, therefore you cannot update the settings.' | translate }}
      </div>
    </ng-template>
  </div>
</div>
