import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import { AgGridModule } from 'ag-grid-angular';
import { reducers } from './reducers';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { StationDataExportService } from '../../shared/services/export/station-data-export.service';
import { WeatherForecastLayoutComponent } from './containers/weather-forecast-layout/weather-forecast-layout.component';
import { WeatherForecastImageComponent } from './containers/weather-forecast-image/weather-forecast-image.component';
import { WeatherForecastHelpModalComponent } from './containers/weather-forecast-help-modal/weather-forecast-help-modal.component';
import { WeatherForecastChartsComponent } from './containers/weather-forecast-charts/weather-forecast-charts.component';
import { WeatherForecastTableComponent } from './containers/weather-forecast-table/weather-forecast-table.component';
import { WeatherForecastToolbarComponent } from './containers/weather-forecast-toolbar/weather-forecast-toolbar.component';
import { WeatherForecastDataComponent } from './containers/weather-forecast-data/weather-forecast-data.component';
import { WeatherForecastHeaderComponent } from './containers/weather-forecast-header/weather-forecast-header.component';
import { WeatherForecastService } from './effects/weather-forecast.service';
import { WeatherForecastRoutingModule } from './weather-forecast-routing.module';
import { WeatherForecastPrecipitationRadarComponent } from './containers/weather-forecast-precipitation-radar/weather-forecast-precipitation-radar.component';
import { WeatherForecastWarningComponent } from './containers/weather-forecast-warning/weather-forecast-warning.component';
import { WeatherForecastInfoComponent } from './containers/weather-forecast-info/weather-forecast-info.component';
import { WeatherForecastLoadInfoComponent } from './containers/weather-forecast-load-info/weather-forecast-load-info.component';
import { WeatherForecastHistoryClimateComponent } from './containers/weather-forecast-history-climate/weather-forecast-history-climate.component';
import { WeatherForecastHistoryClimateHeaderComponent } from './containers/weather-forecast-history-climate-header/weather-forecast-history-climate-header.component';
import { WeatherForecastHistoryClimateContentComponent } from './containers/weather-forecast-history-climate-content/weather-forecast-history-climate-content.component';
import { WeatherForecastHistoryClimateHelpTextModelComponent } from './containers/weather-forecast-history-climate-help-text-model/weather-forecast-history-climate-help-text-model.component';
import { WeatherForecastHistoryClimateRiskAssessmentComponent } from './containers/weather-forecast-history-climate-risk-assessment/weather-forecast-history-climate-risk-assessment.component';
import { WeatherForecastHistoryClimateClimateComparisonComponent } from './containers/weather-forecast-history-climate-climate-comparison/weather-forecast-history-climate-climate-comparison.component';

@NgModule({
  imports: [
    CommonModule,
    WeatherForecastRoutingModule,
    SharedModule,
    StoreModule.forFeature('weatherForecast', reducers),
    EffectsModule.forFeature([WeatherForecastService]),
    TranslateModule.forChild(),
    ChartModule,
    AgGridModule.withComponents([]),
    TabsModule.forRoot()
  ],
  declarations: [
    WeatherForecastDataComponent,
    WeatherForecastDataComponent,
    WeatherForecastHeaderComponent,
    WeatherForecastToolbarComponent,
    WeatherForecastChartsComponent,
    WeatherForecastTableComponent,
    WeatherForecastLayoutComponent,
    WeatherForecastImageComponent,
    WeatherForecastPrecipitationRadarComponent,
    WeatherForecastHelpModalComponent,
    WeatherForecastWarningComponent,
    WeatherForecastInfoComponent,
    WeatherForecastLoadInfoComponent,
    WeatherForecastHistoryClimateComponent,
    WeatherForecastHistoryClimateHeaderComponent,
    WeatherForecastHistoryClimateContentComponent,
    WeatherForecastHistoryClimateHelpTextModelComponent,
    WeatherForecastHistoryClimateRiskAssessmentComponent,
    WeatherForecastHistoryClimateClimateComparisonComponent
  ],
  exports: [
    WeatherForecastTableComponent
  ],
  providers: [
    {provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting]}, // add as factory to your providers
    StationDataExportService
  ]
})
export class WeatherForecastModule { }
