import { Component } from '@angular/core';

@Component({
  selector: 'app-warnings-phone-modal',
  templateUrl: './warnings-phone-modal.component.html',
  styleUrls: ['./warnings-phone-modal.component.scss']
})
export class WarningsPhoneModalComponent {

}
