<form content="!timezone-form"
      *ngIf="form && !timezoneOnly"
      [formGroup]="form">
  <div class="timezone-form__item">
    <label class="timezone-form__label">{{ 'Location search' | translate }}</label>
    <div class="timezone-form__search">
      <app-form-input [isDisabled]="isIngestedStation"
                      [type]="'border'"
                      [placeholder]="'Location search'"
                      [formControl]="search"></app-form-input>
      <app-custom-button [disabled]="isIngestedStation || !checkRights()"
                         [color]="'white'"
                         (click)="find()"
                         [content]="'Go!'">
      </app-custom-button>
    </div>
  </div>

  <div class="timezone-form__numbers">
    <div class="timezone-form__item timezone-form__numbers-item">
      <label class="timezone-form__label">{{ 'Longitude' | translate }}</label>
      <app-form-input [isDisabled]="isIngestedStation || !checkRights()"
                      [type]="'border'"
                      [step]="step"
                      [maxValue]="locationExtremes.longitudeMax"
                      [minValue]="locationExtremes.longitudeMin"
                      [placeholder]="'Longitude'"
                      [formControl]="longitude"
                      [contentType]="'number'"></app-form-input>
    </div>
    <div class="timezone-form__item timezone-form__numbers-item">
      <label class="timezone-form__label">{{ 'Latitude' | translate }}</label>
      <app-form-input [isDisabled]="isIngestedStation || !checkRights()"
                      [type]="'border'"
                      [maxValue]="locationExtremes.latitudeMax"
                      [minValue]="locationExtremes.latitudeMin"
                      [formControl]="latitude"
                      [placeholder]="'Latitude'"
                      [step]="step"
                      [contentType]="'number'"></app-form-input>
    </div>
    <div class="timezone-form__item timezone-form__numbers-item">
      <label class="timezone-form__label">{{ 'Elevation' | translate }}</label>
      <app-form-input [isDisabled]="isIngestedStation || !checkRights()"
                      [type]="'border'"
                      [formControl]="alt"
                      [placeholder]="'Altitude'"
                      [contentType]="'number'"></app-form-input>
    </div>
  </div>

  <hr class="timezone-form__hr">

  <div class="timezone-form__item">
    <label class="timezone-form__label">{{ 'Station timezone' | translate }}</label>
    <div class="alert alert-info" *ngIf="timezone.value">
      {{ 'The timezone is automatically set if you drag the marker on the map or if you find the location from the Location search input.' | translate }}
    </div>
    <div class="alert alert-warning" *ngIf="!timezone.value">
      {{ 'The timezone for this station is not set. If the station position has been set automatically with the GPS unit, you can store the timezone by simply clicking on Save location.' | translate }}
    </div>
    <ng-select [items]="timeZones"
               bindLabel="name"
               bindValue="value"
               groupBy="zone"
               [clearable]="false"
               [formControl]="timezone">
      <ng-template ng-optgroup-tmp let-item="item">
        {{item.zone || 'Unnamed group'}}
      </ng-template>
    </ng-select>
  </div>

</form>

<form content="timezone-form"
      *ngIf="form && timezoneOnly"
      [formGroup]="form">

  <div class="timezone-form__item">
    <label class="timezone-form__label">{{ 'Device timezone' | translate }}</label>
    <ng-select [items]="timeZones"
               bindLabel="name"
               bindValue="value"
               groupBy="zone"
               [clearable]="false"
               [formControl]="timezone">
      <ng-template ng-optgroup-tmp let-item="item">
        {{item.zone || 'Unnamed group'}}
      </ng-template>
    </ng-select>
  </div>

</form>


