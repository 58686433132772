import { IOptions } from '../../../shared/interfaces';

export const initialWaterOptions: IOptions[] = [
  { value: 0, content: '-- Choose initial water content --' },
  { value: 0.9, content: 'Very wet' },
  { value: 0.6, content: 'Wet' },
  { value: 0.4, content: 'Medium' },
  { value: 0.2, content: 'Dry' },
  { value: 0.05, content: 'Very dry' }
];

export const soilProfileOptions: IOptions[] = [
  { value: 0, content: '-- Choose soil profile --' },
  { value: 1, content: 'Heavy with good texture' },
  { value: 2, content: 'Heavy' },
  { value: 3, content: 'Medium with good texture' },
  { value: 4, content: 'Medium' },
  { value: 5, content: 'Light with good texture' },
  { value: 6, content: 'Light' },
  { value: 7, content: 'Light with very coarse texture' }
];

export const soilProfileValues = [
  { FC: 0, RP: 0, PAW: 0 },
  { FC: 38, RP: 22, PAW: 16 },
  { FC: 38, RP: 28, PAW: 10 },
  { FC: 32, RP: 14, PAW: 18 },
  { FC: 28, RP: 14, PAW: 14 },
  { FC: 22, RP: 11, PAW: 11 },
  { FC: 16, RP: 8, PAW: 8 },
  { FC: 12, RP: 6, PAW: 6 }
];

export const unitOptions: IOptions[] = [
  { value: '%', content: '%' },
  { value: 'mm/dm', content: 'mm/dm' },
  { value: 'mm/m', content: 'mm/m' },
  { value: 'm3/m3', content: 'm3/m3' },
  { value: 'in/ft', content: 'in/ft' },
  { value: 'in/4in', content: 'in/4in' }
];

export const data = [
  [new Date(2019, 2, 1), 0, 'C1'],
  [new Date(2019, 3, 1), 0, 'C2'],
  [new Date(2019, 5, 12), 0, 'C3'],
  [new Date(2019, 8, 10), 0, 'C4'],
  [new Date(2019, 10, 25), 0, 'C5']
];

export const data2 = [
  [new Date(2019, 2, 1), 0, 'R1'],
  [new Date(2019, 3, 1), 0, 'R2'],
  [new Date(2019, 5, 12), 0, 'R3'],
  [new Date(2019, 8, 10), 0, 'R4'],
  [new Date(2019, 10, 25), 0, 'R5']
];

export const margin = { top: 40, right: 30, bottom: 60, left: 50 };

export const distanceItems: IOptions[] = [
  { value: '30 km', content: '30000' },
  { value: '10 km', content: '10000' }
];
