import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IStationBasicInfo } from '../../core/models/navigation-station';
import { selectNavigationStationBasicInfo } from '../../core/reducers';
import * as fromNavigationStation from '../../core/reducers/navigation-station';

@Component({
  selector: 'app-station-page-header',
  templateUrl: './station-page-header.component.html',
  styleUrls: ['./station-page-header.component.scss']
})
export class StationPageHeaderComponent implements OnInit, OnDestroy {
  @Input()
  public pageName          : string = 'Station data';

  public stationBasicInfo$ : Observable<IStationBasicInfo>;
  private destroy$         : Subject<boolean> = new Subject<boolean>();

  constructor(private navigationStore: Store<fromNavigationStation.INavigationStationState>) { }

  public ngOnInit(): void {
    this.stationBasicInfo$ = this.navigationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStationBasicInfo)
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
