import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sensor-save',
  templateUrl: './sensor-save.component.html',
  styleUrls: ['./sensor-save.component.scss']
})
export class SensorSaveComponent {
  @Output()
  private resetUnits        : EventEmitter<void> = new EventEmitter<void>();
  @Output()
  private saveAllSensors    : EventEmitter<void> = new EventEmitter<void>();

  public emitResetUnits(): void {
    this.resetUnits.emit();
  }

  public emitSaveAllSensors(): void {
    this.saveAllSensors.emit();
  }
}
