<app-form-layout class="warnings-list" *ngIf="warningsList && warningsList.warnings">
  <app-card-header [header]="'Warnings'"
                   [helpButton]="true"
                   (openModal)="openModal()"
                   [description]="'Setup sms warnings'"></app-card-header>
  <div class="warnings-list__content">
    <div class="alert alert-info warnings-list__alert">
      {{ 'Warning thresholds can have a full number or decimal values (.) and are separated with a comma (,). ' +
      'Warning thresholds can also contain negative numbers (numbers below 0).' | translate }}
      <br />
      {{ 'Input fields with errors will be marked with red and will not be saved before they are fixed.' | translate }}
      <br /><br />
      {{ 'Reminder' | translate }}:
      <br />
      <ul>
        <li><b>,</b> - <i>{{ 'separator' | translate }}</i></li>
        <li><b>.</b> - <i>{{ 'decimal value (10.5, -12.81)' | translate }}</i></li>
      </ul>
    </div>
    <div class="table-responsive warnings-list__table-content">
      <table class="warnings-list__table">
        <thead class="warnings-list__table__head">
        <tr>
          <th class="col-md-1 warnings-list__table__th">{{ 'Chain' | translate }}</th>
          <th class="col-md-1 warnings-list__table__th">{{ 'Channel' | translate }}</th>
          <th class="col-md-1 warnings-list__table__th">{{ 'Code' | translate }}</th>
          <th class="col-md-4 warnings-list__table__th">{{ 'Name' | translate }}</th>
          <th class="col-md-1 warnings-list__table__th">{{ 'Unit' | translate }}</th>
          <th class="col-md-2 warnings-list__table__th"><i class="fa fa-caret-down" aria-hidden="true"></i> {{ 'Warning at Min' | translate }}</th>
          <th class="col-md-2 warnings-list__table__th"><i class="fa fa-caret-up" aria-hidden="true"></i> {{ 'Warning at Max' | translate }}</th>
        </tr>
        </thead>
        <tbody class="warnings-list__table__body">
          <tr *ngFor="let key of getListKeys()"
              app-warnings-list-row
              [warningKey]="key"
              [warning]="warningsList.warnings[key]"
              >
          </tr>
        </tbody>
      </table>
    </div>
    <app-warnings-list-save (save)="save()"></app-warnings-list-save>
  </div>
</app-form-layout>

<app-info-modal [modalId]="modalId"
                [header]="'Warnings'">
  <app-warnings-list-modal></app-warnings-list-modal>
</app-info-modal>
