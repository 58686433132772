<h4 *ngIf="title" class="carousel-title">
  <span>{{ title | translate }}</span>
</h4>
<ng-container *ngIf="show && pictures && pictures.length; else emtpyDataMessage">
  <carousel [interval]="getInterval()" class="hover" [(activeSlide)]="activeSlideIndex" [noPause]="noPause" [noWrap]="noWrap" [showIndicators]="showIndicators">
    <slide *ngFor="let pictureSet of slides"
           class="slide"
           [class.animation]="animation"
           [class.animationStart]="startAnimation()"
           [ngStyle]="{ 'animation-duration': animationDuration+'s', 'animation-delay': (playSpeed-animationDuration)+'s' }">
      <div *ngFor="let picture of pictureSet"
           class="picture-wrapper"
           [class.pictureBorder]="pictureBorder"
           (click)="activatePicture(picture)"
           [class.active]="isActivePicture(picture) && !deselect">
        <div class="date-label">{{toLocalDate(picture.time)}}</div>
        <img src="{{picture[srcProperty]}}" [class.fullsize-image]="fullSizeImages"/>
      </div>
    </slide>
  </carousel>
  <!----carousel *ngIf="activeSlideIndex != null && activeSlideIndex != undefined" [interval]="getInterval()" [activeSlide]="activeSlideIndex" [noPause]="noPause" [noWrap]="noWrap" [showIndicators]="showIndicators">
    <slide *ngFor="let pictureSet of slides"
           class="slide"
           [class.animation]="animation"
           [class.animationStart]="startAnimation()"
           [ngStyle]="{ 'animation-duration': animationDuration+'s', 'animation-delay': (playSpeed-animationDuration)+'s' }">
      <div *ngFor="let picture of pictureSet"
           class="picture-wrapper">
        <div class="date-label">{{toLocalDate(picture.time)}}</div>
        <img src="{{picture[srcProperty]}}" [class.fullsize-image]="fullSizeImages"/>
      </div>
    </slide>
  </carousel-->
</ng-container>
<ng-template #emtpyDataMessage>
  <h4 class="empty-data-message">{{ 'No data for selected date' | translate }}</h4>
</ng-template>
