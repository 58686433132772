import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { setNotify } from '../../../../core/actions/notify';
import { IStation } from '../../../../core/models/stations';
import { selectStations } from '../../../../core/reducers';
import * as fromAccount from '../../../../core/reducers/account';
import * as fromNotify from '../../../../core/reducers/notify';
import { cardAnimation } from '../../../../shared/animations/card-animations';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { disableSync, enableSync, getAssets, getSharingKey, syncAssets } from '../../actions/xarvio';
import { IState as IXarvioState, IXarvioStation } from '../../models/xarvio';
import { selectAssets, selectSharingKey } from '../../selectors/xarvio';

interface IStationsPageItem {
  station: IStation;
  asset: IXarvioStation;
}

interface IStationConnectToggled {
  station: IStation;
  add: boolean;
}

@Component({
  selector: 'app-xarvio',
  templateUrl: './xarvio.component.html',
  styleUrls: ['./xarvio.component.scss'],
  animations: [cardAnimation()]
})
export class XarvioComponent implements OnInit, OnDestroy {
  public readonly STATIONS_PAGE_SIZE = 10;

  public modalId: string = generateId();
  public stations$: Observable<IStation[]>;
  public xarvioSettings$: Observable<string>;
  public destroy$ = new Subject<boolean>();

  public xarvioSharingKey: string = '';
  public xarvioSharingKeyInputType: string = 'password';
  public stationSelectionVisible: boolean = false;
  public stationsPageItems$: Observable<IStationsPageItem[]>;
  public stationsPageChanged = new BehaviorSubject<number>(0);
  public stationConnectToggled = new Subject<IStationConnectToggled>();

  private pendingItems: IXarvioStation[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private accountStore: Store<fromAccount.IAccount>,
    private notifyStore: Store<fromNotify.INotifyState>,
    private xarvioStore: Store<IXarvioState>,
    private modal: ModalService
  ) {}

  public ngOnInit(): void {
    this.initXarvioSettings();
    this.initStationListeners();
    this.initAssetTogglerListener();
    this.initDataRetrieving();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public enableSync(): void {
    this.xarvioStore.dispatch(enableSync());
  }

  public disableSync(): void {
    this.xarvioStore.dispatch(disableSync());
  }

  public syncStations(): void {
    this.xarvioStore.dispatch(syncAssets(this.pendingItems));
    this.pendingItems.splice(0, this.pendingItems.length);
  }

  public openModal(): void {
    this.modal.openModal(this.modalId);
  }

  public hasAsset(pageItem: IStationsPageItem): boolean {
    if (!pageItem.asset) {
      return false;
    }
    return pageItem.asset.synced;
  }

  public get sharingKeyInputStyle(): any {
    return {
      'fa': true,
      'fa-eye-slash': this.xarvioSharingKeyInputType === 'text',
      'fa-eye': this.xarvioSharingKeyInputType !== 'text'
    };
  }

  public toggleSharingKeyVisibility(): void {
    this.xarvioSharingKeyInputType = this.xarvioSharingKeyInputType === 'text' ? 'password' : 'text';
  }

  public toClipboard(element: HTMLInputElement, key: string): void {
    // Cannot copy content if input is a password type
    element.type = 'text';
    element.select();
    document.execCommand('copy');
    element.type = this.xarvioSharingKeyInputType;
    this.notifyStore.dispatch(setNotify('Key was copied to clipboard successfully'));
  }

  private initXarvioSettings(): void {
    this.xarvioSettings$ = this.xarvioStore.pipe(
      select(selectSharingKey),
      takeUntil(this.destroy$)
    );

    this.xarvioSettings$.subscribe((sharingKey: string) => {
      this.stationSelectionVisible = !!sharingKey;
      this.xarvioSharingKey = sharingKey;
    });

    this.xarvioStore.dispatch(getSharingKey());
  }

  private initStationListeners(): void {
    this.stations$ = this.accountStore.pipe(
      select(selectStations),
      takeUntil(this.destroy$)
    );

    this.stationsPageItems$ = combineLatest([
      this.stationsPageChanged.asObservable(),
      this.accountStore.pipe(select(selectStations)),
      this.xarvioStore.pipe(select(selectAssets))
    ]).pipe(
      filter(([pageNo, stations, assets]) => {
        return !!stations;
      }),
      map(([pageNo, stations, assets]) => {
        return stations
          .slice(pageNo * this.STATIONS_PAGE_SIZE, pageNo * this.STATIONS_PAGE_SIZE + this.STATIONS_PAGE_SIZE)
          .map((station) => {
            let assetFound = this.pendingItems.find(asset => asset.station_name === station.name.original);
            if (!assetFound) {
              assetFound = assets[station.name.original] || null;
            }

            return {
              station,
              asset: assetFound
            };
          });
      })
    );
  }

  private initAssetTogglerListener(): void {
    this.stationConnectToggled
      .pipe(tap((toggled) => {
        const itemIndex = this.pendingItems.findIndex(
          (item) => item.station_name === toggled.station.name.original
        );
        // There must be only one station modification inside the pending items array
        if (itemIndex === -1) {
          this.pendingItems.push({ to_sync: toggled.add, station_name: toggled.station.name.original });
        } else {
          this.pendingItems.splice(itemIndex, 1);
        }
      }))
      .subscribe();
  }

  private initDataRetrieving(): void {
    this.xarvioStore.dispatch(getAssets());
  }
}
