<section>
  <h4 *ngIf="isLoading; else nodata">
    <i class="fa fa-cog fa-spin fa-fw"></i> {{ 'Please wait, loading data ...' | translate }}
  </h4>
</section>

<ng-template #nodata>
  <h4 class="alert alert-warning weather-forecast-warning">
    <i class="fa fa-warning fa-fw"></i> {{ errorText | translate }}
  </h4>
</ng-template>