import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ExcelExportParams, GridOptions } from 'ag-grid';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectNavigationStation } from '../../../../core/reducers';
import { ISelectedStationState } from '../../../../core/reducers/selectedStation';
import { DataGridOptions, ExcelExportSettings } from '../../../../shared/constants';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';
import { hydroponicsColumnDefs } from '../../constants/constats';
import { IHydroponicsState, IHydroponicsVisibility } from '../../models/models';
import { selectHydroponicsTableActive, selectHydroponicsVisibility } from '../../selectors/selectors';

@Component({
  selector: 'app-hydroponics-table-data',
  templateUrl: './hydroponics-table-data.component.html',
  styleUrls: ['./hydroponics-table-data.component.scss']
})
export class HydroponicsTableDataComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  public response             : any;
  public data                 : any[];
  public dataGridOptions      : GridOptions = DataGridOptions;
  public columnDefs           : any[] = hydroponicsColumnDefs;
  private stationID           : string;
  private visibility          : IHydroponicsVisibility;
  public hideTable            : boolean = false;

  public isActive             : boolean;
  private destroy$            : Subject<boolean> = new Subject<boolean>();

  constructor(
    private changeDetector        : ChangeDetectorRef,
    private translationService    : TranslateService,
    private exportService         : StationDataExportService,
    private selectedStationStore  : Store<ISelectedStationState>,
    private hydroponicsStore      : Store<IHydroponicsState>,
  ) {
      this.columnDefs.forEach((column, i) => {
        this.translationService.get(column.headerName)
          .subscribe(translatedHeader => {
            this.columnDefs[i].headerName = translatedHeader;
          });
      });
  }

  public ngOnInit(): void {
    this.exportService.getExportXLS().pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.exportXLS();
    });

    this.selectedStationStore.pipe(
      select(selectNavigationStation),
      takeUntil(this.destroy$)
    ).subscribe(station => {
      if (station) {
        this.stationID = station.name.original;
      }
    });

    this.hydroponicsStore.pipe(
      select(selectHydroponicsVisibility),
      takeUntil(this.destroy$)
    ).subscribe(visibility => {
      this.visibility = visibility;
      this.toggleColumns();
    });

    this.hydroponicsStore.pipe(
      select(selectHydroponicsTableActive),
      takeUntil(this.destroy$)
    ).subscribe(isActive => {
      this.isActive = isActive;
      this.updateTable(this.data);
      }
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.response) {
      this.data = this.getData(this.response.data);
      this.updateTable(this.data);
    }
  }

  private getData(response): any[] {
    return response.map((res: any) => {
      return {
        date: res.UTC_Date.slice(0, -3),
        win: res.win,
        wout: res.wout,
        drainage: res.drainage,
        weight: res.weight
      };
    });
  }

  private updateTable(response: any[]): void {
    setTimeout(() => {
      if (this.dataGridOptions && this.dataGridOptions.api) {
        this.dataGridOptions.api.setColumnDefs(this.columnDefs);
        this.dataGridOptions.api.setRowData(response);
      }
    }, 0);
    this.changeDetector.detectChanges();
  }

  private toggleColumns(): void {
    if (this.dataGridOptions.api || this.hideTable === true) {
      Object.keys(this.visibility).forEach(key => {
        this.dataGridOptions.columnApi.setColumnVisible(key, this.visibility[key]);
      });
      this.hideTable = this.dataGridOptions.columnApi.getAllColumns()
        .filter(col => col.getId() !== 'date')
        .every(col => col.isVisible() === false);
    }
  }

  private exportXLS(): void {
    const path = 'hydroponics';
    const params: ExcelExportParams = {
      ...ExcelExportSettings,
      fileName: `${this.stationID}_${path}`
    };

    this.dataGridOptions.api.exportDataAsExcel(params);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
