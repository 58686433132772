import { IDisplayRulesConfig } from "../../../environments/interfaces/environment";
import { IThemeConfig } from "../../../environments/interfaces/theme";

export const themeConfig: IThemeConfig = {
  subDomain: 'vodafone',
  language: 'en',
  headerClass: 'white',
  src: '../../../assets/themes/vodafone/assets/images/logo.png',
  width: '220',
  mainWidth: '220',
  mainMTop: '5',
  mainLeft: '8',
  loginLeft: '40',
  registerBottom: '10',
  resetLeft: '20',
  resetBottom: '10',
  name: 'Vodafone',
  profileMenuSrc: '../../../assets/themes/default/assets/images/profile.png',
  faviconSrc: '../../../assets/themes/default/assets/favicon.ico',
  colorConfig: {
      '--search-widget-border': '#eb9700',
      '--search-widget-label': '#e60000',
      '--search-widget-label-dark-green': '#e60000',
      '--search-widget-label-orange': '#f39224',
      '--search-widget-button': '#e40000DD',
      '--main-static': '#e60000',
      '--main-static-orange': '#f39224',
      '--form-button': '#e60000',
      '--form-button-action': '#4a4d4e',
    }
};

export const displayRulesConfig: IDisplayRulesConfig = {
  userMenu: true,
  farmViewMenu: false,
  leftBarMenu: {
    stationData: true,
    cropView: true,
    iScout: true,
    weatherForecast: true,
    workPlanning: true,
    animalProduction: true,
    accumulatorTool: true,
    frostProtection: true,
    diseaseModels: true,
    soilMoisture: true,
    hydroponics: true,
    tracker: true,
    soilGuard: true,
    settings: true,
  },
};
