import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectStations } from '../../../../core/reducers';
import * as fromStations from '../../../../core/reducers/stations';
import * as fromAlerts from '../../reducers';
import { selectAlerts } from '../../reducers/index';
import { ModalService } from './../../../../shared/modal/services/modal.service';
import { IServerAlert } from './../../../server-alerts/interfaces/server-alert';
import { selectStationSensors } from '../../../server-alerts/reducers';
import { IOptions } from '../../../../shared/interfaces';
import {INodes, ISensors} from '../../../sensors-and-nodes/models/sensors-and-nodes';
import * as fromServerAlerts from '../../../server-alerts/reducers/server-alerts';
import { ApiCallService } from '../../../../services/api/api-call.service';

@Component({
  selector: 'app-current-alerts',
  templateUrl: './current-alerts.component.html',
  styleUrls: ['./current-alerts.component.scss']
})
export class CurrentAlertsComponent implements OnInit, OnDestroy {
  public alerts: Array<any>;
  public stations: IStation[] = [];
  public station : IStation;
  public currentStation: IStation;
  public nodes: INodes;
  public stationSensors$: Observable<any>;
  public sensors: Array<IOptions> = [];
  public CURRENT_ALERTS_MODAL_ID = 'CURRENT_ALERTS_MODAL_ID';
  private destroy$ = new Subject<null>();

  constructor(
    private alertsStore: Store<fromAlerts.IState>,
    private stationStore: Store<fromStations.IStations>,
    private modalService: ModalService,
    private translations: TranslateService,
    protected serverAlertsStore: Store<fromServerAlerts.IServerAlertsState>,
    private api: ApiCallService
    ) { }

  public ngOnInit(): void {
    this.alertsStore.pipe(
      select(selectAlerts),
      filter(savedAlerts => !!savedAlerts && savedAlerts.length > 0),
      takeUntil(this.destroy$)
    ).subscribe((alerts: Array<IServerAlert[]>) => {
      this.alerts = alerts;
      this.alerts.forEach(alert => {
        if (alert.hasOwnProperty('sensorName')) {
          this.translations.get(alert.sensorName)
            .subscribe(translatedName => {
              alert.sensorName = translatedName;
            });
        }
      });

      this.alerts.map(alert => {
        const alertStationId = alert.nm;
        this.stationSensors$ = this.serverAlertsStore.pipe(
          select(selectStationSensors),
          takeUntil(this.destroy$)
        );

        this.stationSensors$.pipe(
          switchMap(() => this.api.getStationSensors(alertStationId).pipe(
            tap((sensorData: ISensors) => {
              alert.sensorData = sensorData;
            })
          )),
        ).subscribe(() => {});
      });
    });

    this.stationStore.pipe(
      select(selectStations),
      takeUntil(this.destroy$)
    ).subscribe((stations) => {
      this.stations = stations;
      const alertStation = this.alerts && this.alerts.length ? this.alerts[0]['nm'] : null;
      if (alertStation) {
        this.currentStation = this.stations.find(station => station.name.original === alertStation);
      }
    });
  }

  public openInfoModal(): void {
    this.modalService.openModal(this.CURRENT_ALERTS_MODAL_ID);
  }

  public getSensorData(alert): {} {
    const sensorData = alert.sensorData;
    if (sensorData && sensorData.length) {
      sensorData.map(sensor => {
        if (sensor.hasOwnProperty('name')) {
          this.translations.get(sensor.name)
            .subscribe(translatedName => {
              sensor.name = translatedName;
            });
        }
      });
      const targetSensor = sensorData.find(sensor => sensor.name === alert.sensorName);
      const customSensorName = targetSensor && targetSensor.name_custom;

      return { customSensorName: customSensorName };
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
