<div class="auth">

  <div [@backgroundAnimation]="state" (@backgroundAnimation.done)="isLoaded$.next(true)" class="background-container">

    <div [@firstImageAnimation] *ngIf="firstImage" class="back first item"
         [ngStyle]="{ 'background-image' : 'url('+ firstImage + ')'}"
         appBackgroundLoad (backLoaded)="backLoaded$.next(-1)">
    </div>

    <ng-container *ngFor="let image of animateImages; let i = index">
      <div class="back" [ngClass]="{'item': i!==animateImages.length-1,
                                    'last': i===animateImages.length-1}"
           [ngStyle]="{ 'background-image' : 'url('+ image + ')'}"
           appBackgroundLoad (backLoaded)="backLoaded$.next(i)">
      </div>
    </ng-container>

    <router-outlet *ngIf="(isLoaded$ | async); else logo"></router-outlet>

  </div>

  <ng-template #logo>

    <div class="auth-container preload">
      <app-logo></app-logo>
    </div>

  </ng-template>

</div>



