import { IActionWithPayload } from './../../../core/models/actionWithPayload';
import { SoilMoistureStationsActionTypes } from '../actions/soilMoistureStations';

export interface ISoilMoistureStationsState {
    soilMoistureStations: Array<any>;
}

export const initialState: ISoilMoistureStationsState = {
    soilMoistureStations: []
};

export function reducer(state: ISoilMoistureStationsState = initialState, action: IActionWithPayload): ISoilMoistureStationsState {
    switch (action.type) {
        case SoilMoistureStationsActionTypes.SET_SOIL_MOISTURE_STATIONS:
            return <ISoilMoistureStationsState>{
                ...state,
                soilMoistureStations: action.payload
            };
        default:
            return state;
    }
}
