import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-form-layout',
  templateUrl: './form-layout.component.html',
  styleUrls: ['./form-layout.component.css'],
})
export class FormLayoutComponent  {
  @Input()
  public size                 : string = 'full';
  @Input()
  public minHeight            : string = 'none';
  @Input()
  public opacity              : string = '1';
  @Input()
  public shadow               : boolean = true;

}
