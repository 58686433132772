import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ISumsSmState, ICropZoneSoilMoistureState } from '../models';
import { initalSumsSmState } from '../reducers';

export const featureSelector = createFeatureSelector<ICropZoneSoilMoistureState>('cropZoneSoilMoisture');

export const sumsSmSelector = createSelector(
    featureSelector,
    (state: ICropZoneSoilMoistureState): ISumsSmState => state.sumsSm
);
