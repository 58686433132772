import { Component, Input } from '@angular/core';
import { IColumn } from '../../models/dashboard.models';

@Component({
  selector: 'app-row',
  templateUrl: './row.component.html'
})
export class RowComponent {
  @Input()
  public row                     : Array<IColumn>;
  @Input()
  public rowIndex                : number;
  @Input()
  public isEdit                  : boolean;
}
