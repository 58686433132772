import {INavigationCropzoneState} from '../reducers/navigation-cropzone';

export const defaultCropzone: INavigationCropzoneState = {
  cropzone: null,
  /*
  cropzoneBasicInfo: {
    firmWare: '',
    timeZoneOffset: null,
    lastCommunication: null,
    maxDate: null,
    minDate: null,
    originalName: '',
    customName: '',
    deviceType: ''
  },
  navigationAccess: {
    cropView: null,
    iScout: null,
    cropzoneData: null,
    weatherForecast: null,
    workPlanning: null,
    animalProduction: null,
    diseaseModels: null,
    soilMoisture: null,
    settings: null,
    tracker: null,
    settingsConfiguration: {
      sensorsAndNodes: null,
      warnings: null
    }
  },*/
  notFound: null
};
