import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Column, ColumnGroup, ExcelExportParams, GridOptions } from 'ag-grid';
import { Observable, Subject } from 'rxjs';
import { delay, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { ISelectedSearchWidgetItem } from '../../../../core/models/selectedSearchWidgetItem';
import { selectSelectedStation } from '../../../../core/reducers';
import * as fromSelectedStation from '../../../../core/reducers/selectedStation';
import { DataGridOptions, ExcelExportSettings } from '../../../../shared/constants';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';
import { ITreeSettingsState } from '../../../../shared/tree/models/tree.models';
import { suppressMenu } from '../../../../shared/utils/suppressMenu';
import { activitySettings, grid as gridSelector, treeSettings } from '../../selectors';
import { IStateData, IStateSettings } from '../../states';
@Component({
  selector: 'app-soil-moisture-table',
  templateUrl: './soil-moisture-table.component.html',
  styleUrls: ['./soil-moisture-table.component.scss']
})
export class SoilMoistureTableComponent implements OnInit, OnDestroy {

  public grid: any = {};
  public isDataTableActive$: Observable<boolean>;
  public isTableHidden: boolean = false;
  public dataGridOptions: GridOptions = DataGridOptions;

  private tree: any = {};
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dataStore: Store<IStateData>,
    private settingsStore: Store<IStateSettings>,
    private selectedStationStore: Store<fromSelectedStation.ISelectedStationState>,
    private treeSettingsStore: Store<ITreeSettingsState>,
    private exportService: StationDataExportService,
  ) { }

  public ngOnInit(): void {

    this.exportService.getExportXLS().pipe(
      takeUntil(this.destroy$)
    ).subscribe((id: string) => {
      this.exportXLS(id);
    });

    this.isDataTableActive$ = this.settingsStore.pipe(
      select(activitySettings, 'isTableActive'),
      takeUntil(this.destroy$)
    );

    this.selectedStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectSelectedStation),
      switchMap((id: ISelectedSearchWidgetItem) => this.treeSettingsStore.pipe(
        takeUntil(this.destroy$),
        select(treeSettings),
        filter((tree: ITreeSettingsState): boolean => !!tree && !!tree[id.original_name] && !!tree[id.original_name].selectedProfile),
        map((tree: ITreeSettingsState) => tree[id.original_name].profileSettings[tree[id.original_name].selectedProfile].disabledGroupIds)
      ))
    ).subscribe((tree: any) => {
      this.tree = tree;
      setTimeout(() => {
        this.toggleColumns();
      }, 0);
    });

    this.dataStore.pipe(
      select(gridSelector),
      delay(0),
      filter(grid => !!grid),
      takeUntil(this.destroy$)
    ).subscribe((grid: any) => {
      this.grid = grid;
      if (grid.headers && this.dataGridOptions.api) {
          grid.headers = suppressMenu(grid.headers);
          grid.headers[0].unSortIcon = true;
          this.dataGridOptions.api.setColumnDefs(this.grid.headers);
          this.dataGridOptions.api.setRowData(this.grid.data);
          this.toggleColumns();
        } else if (this.dataGridOptions.api) {
          this.dataGridOptions.api.setColumnDefs(null);
          this.dataGridOptions.api.setRowData(null);
        }
    });

  }

  private toggleColumns(): void {
    if (!this.dataGridOptions.api) {
      return;
    }
    this.dataGridOptions.columnApi.getAllColumns().forEach(
      (c: Column) => {
        const isInvisible: string = Object.keys(this.tree).find((t) => c.getId().startsWith(t));
        if (!isInvisible && !c.isVisible()) {
          this.dataGridOptions.columnApi.setColumnVisible(c.getId(), true);
        }
      }
    );

    Object.keys(this.tree).forEach((key: string) => {
      const gr: ColumnGroup = this.dataGridOptions.columnApi.getColumnGroup(key);
      if (gr) {
        gr.getOriginalColumnGroup().getChildren().forEach((column) => {
          if (column.isVisible()) {
            this.dataGridOptions.columnApi.setColumnVisible(column.getId(), false);
          }
        });
      } else {
        this.dataGridOptions.columnApi.setColumnVisible(key, false);
      }
    });
    this.isTableHidden = this.dataGridOptions.columnApi.getAllDisplayedVirtualColumns().length === 1;
  }

  private exportXLS(id: string): void {
    const params: ExcelExportParams = {
      ...ExcelExportSettings,
      fileName: `${id}_station_data`
    };

    this.dataGridOptions.api.exportDataAsExcel(params);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
