import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IIscoutGlueBoard, IIscoutSeason, IIscoutSeasonFormAction} from '../models/iscout.models';
import {IDeleteIscoutSeasonBody} from '../../../shared/camera/models/camera';

export const enum IscoutSeasonActionTypes {
  GET_ISCOUT_SEASONS = '[IscoutSeason] GET_ISCOUT_SEASONS',
  SET_ISCOUT_SEASONS = '[IscoutSeason] SET_ISCOUT_SEASONS',
  GET_ISCOUT_SEASONS_AVAILABLE_GLUE_BOARDS = '[IscoutSeason] GET_ISCOUT_SEASONS_AVAILABLE_GLUE_BOARDS',
  SET_ISCOUT_SEASONS_AVAILABLE_GLUE_BOARDS = '[IscoutSeason] SET_ISCOUT_SEASONS_AVAILABLE_GLUE_BOARDS',
  SELECT_ISCOUT_SEASON = '[IscoutSeason] SELECT_ISCOUT_SEASON',
  UNSELECT_ISCOUT_SEASON = '[IscoutSeason] UNSELECT_ISCOUT_SEASON',
  SAVE_ISCOUT_SEASON = '[IscoutSeason] SAVE_ISCOUT_SEASON',
  UPDATE_ISCOUT_SEASON = '[IscoutSeason] UPDATE_ISCOUT_SEASON',
  REMOVE_ISCOUT_SEASON = '[IscoutSeason] REMOVE_ISCOUT_SEASON'
}

export function getIscoutSeasons(stationId: string): IActionWithPayload {
  return {type: IscoutSeasonActionTypes.GET_ISCOUT_SEASONS, payload: stationId};
}

export function setIscoutSeasons(seasons: Array<IIscoutSeason>): IActionWithPayload {
  return {type: IscoutSeasonActionTypes.SET_ISCOUT_SEASONS, payload: seasons};
}

export function getIscoutSeasonsAvailableGlueBoards(stationId: string): IActionWithPayload {
  return {type: IscoutSeasonActionTypes.GET_ISCOUT_SEASONS_AVAILABLE_GLUE_BOARDS, payload: stationId};
}

export function setIscoutSeasonsAvailableGlueBoards(glueBoards: Array<IIscoutGlueBoard>): IActionWithPayload {
  return {type: IscoutSeasonActionTypes.SET_ISCOUT_SEASONS_AVAILABLE_GLUE_BOARDS, payload: glueBoards};
}

export function selectIscoutSeason(season: IIscoutSeasonFormAction): IActionWithPayload {
  return {type: IscoutSeasonActionTypes.SELECT_ISCOUT_SEASON, payload: season};
}

export function unselectIscoutSeason(): IActionWithPayload {
  return {type: IscoutSeasonActionTypes.UNSELECT_ISCOUT_SEASON, payload: null};
}

export function saveIscoutSeason(season: IIscoutSeason): IActionWithPayload {
  return {type: IscoutSeasonActionTypes.SAVE_ISCOUT_SEASON, payload: season};
}

export function updateIscoutSeason(season: IIscoutSeason): IActionWithPayload {
  return {type: IscoutSeasonActionTypes.UPDATE_ISCOUT_SEASON, payload: season};
}

export function removeIscoutSeason(payload: IDeleteIscoutSeasonBody): IActionWithPayload {
  return {type: IscoutSeasonActionTypes.REMOVE_ISCOUT_SEASON, payload};
}
