import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as moment from 'moment';
import {DEFAULT_CAMM_ID} from '../../../core/constants/camera';
import {
  ICameraPest,
  ICameraPestFamily,
  ICameraPestGenus,
  ICameraPestOrder,
  IFromTo,
  IPestToggler,
  IPestTogglerMap,
  IPicture,
  IToolbarSettings
} from '../../../shared/camera/models/camera';
import {IRectangle} from '../../../shared/camera/models/rectangles';
import {
  IIscoutGlueBoard,
  IIscoutGlueBoardAvailableDates,
  IIscoutGlueBoardFormAction,
  IIscoutGlueBoardState,
  IIscoutGlueBoardTarget,
  IIscoutGlueBoardTargetType,
  IIscoutPestFormAction,
  IIscoutPestMap,
  IIscoutPestsState,
  IIscoutSeason,
  IIscoutSeasonFormAction,
  IIscoutSeasonState,
  IIscoutSettingsState,
  IIscoutState
} from '../models/iscout.models';
import * as fromIscout from './iscout';
import * as fromIscoutSettings from './iscout-settings';
import * as fromIscoutPests from './iscout-pests';
import * as fromIscoutGlueBoards from './iscout-glue-boards';
import * as fromIscoutSeasons from './iscout-seasons';

interface IState {
  iscout: IIscoutState;
  iscoutSettings: IIscoutSettingsState;
  iscoutPests: IIscoutPestsState;
  iscoutGlueBoards: IIscoutGlueBoardState;
  iscoutSeasons: IIscoutSeasonState;
}

export const reducers = {
  iscout: fromIscout.reducer,
  iscoutSettings: fromIscoutSettings.reducer,
  iscoutPests: fromIscoutPests.reducer,
  iscoutGlueBoards: fromIscoutGlueBoards.reducer,
  iscoutSeasons: fromIscoutSeasons.reducer
};

export const iscoutSelector = createFeatureSelector<IState>('iscout');

export const selectIscoutPictures = createSelector(
  iscoutSelector,
  (iscoutState: IState): Array<IPicture> => iscoutState.iscout.pictures[DEFAULT_CAMM_ID]
);

export const selectIscoutIsLoading = createSelector(
  iscoutSelector,
  (iscoutState: IState): boolean => iscoutState.iscout.isLoading
);

export const selectIscoutIsError = createSelector(
  iscoutSelector,
  (iscoutState: IState): boolean => iscoutState.iscout.isError
);

export const selectIscoutUserPests = createSelector(
  iscoutSelector,
  (iscoutState: IState): Array<ICameraPest> => iscoutState.iscout.userPests
);

export const selectIscoutGeneralPests = createSelector(
  iscoutSelector,
  (iscoutState: IState): Array<ICameraPest> => iscoutState.iscout.generalPests
);

export const selectIscoutFirstDate = createSelector(
  iscoutSelector,
  (iscoutState: IState): moment.Moment => iscoutState.iscout.firstDate
);

export const selectIscoutLastDate = createSelector(
  iscoutSelector,
  (iscoutState: IState): moment.Moment => iscoutState.iscout.lastDate
);

export const selectIscoutSettingsCurrentDate = createSelector(
  iscoutSelector,
  (iscoutState: IState): string | IFromTo => iscoutState.iscoutSettings.currentDateString
);

export const selectIscoutSettingsSelectedPicture = createSelector(
  iscoutSelector,
  (iscoutState: IState): IPicture => iscoutState.iscoutSettings.selectedPicture
);

export const selectIscoutSettingsForToolbar = createSelector(
  iscoutSelector,
  (iscoutState: IState): IToolbarSettings => iscoutState.iscoutSettings.toolbarSettings
);

export const selectIscoutSettingsAreMeasurementsActive = createSelector(
  iscoutSelector,
  (iscoutState: IState): boolean => iscoutState.iscoutSettings.toolbarSettings.activity.areMeasurementsActive
);

export const selectIscoutSettingsIsHelpActive = createSelector(
  iscoutSelector,
  (iscoutState: IState): boolean => iscoutState.iscoutSettings.toolbarSettings.activity.isHelpActive
);

export const selectIscoutGlueBoardGallerySelection = createSelector(
  iscoutSelector,
  (iScoutState: IState): IIscoutGlueBoard => iScoutState.iscoutGlueBoards.selectedGalleryGlueBoard
);

export const selectIscoutAllPossiblePestsAsPestToggles = createSelector(
  iscoutSelector,
  (iscoutState: IState): IPestTogglerMap => iscoutState.iscoutPests.pestsOrders
    .map((pestOrder): IPestToggler => ({
      type: IIscoutGlueBoardTargetType.PEST_ORDER,
      ref: pestOrder._id,
      name: pestOrder.name,
      is_active: true
    }))
    .concat(
      iscoutState.iscout.generalPests.map((pestSpecie) => ({
        type: IIscoutGlueBoardTargetType.PEST_SPECIES,
        ref: pestSpecie._id,
        name: pestSpecie.name,
        is_active: true
      })),
      iscoutState.iscout.userPests.map((pestSpecie) => ({
        type: IIscoutGlueBoardTargetType.PEST_SPECIES,
        ref: pestSpecie._id,
        name: pestSpecie.name,
        is_active: true
      }))
    )
    .reduce((result: IPestTogglerMap, toggle: IPestToggler): IPestTogglerMap => ({
      ...result,
      [toggle.name]: toggle
    }), {})
);

export const selectIscoutSettingsPestTogglers = createSelector(
  iscoutSelector,
  selectIscoutAllPossiblePestsAsPestToggles,
  selectIscoutGlueBoardGallerySelection,
  (
    iscoutState: IState,
    allPossibleToggles: IPestTogglerMap,
    selectedGlueBoard: IIscoutGlueBoard
  ): Array<IPestToggler> => {
    if (!iscoutState.iscoutSettings.selectedPicture) {
      return [];
    }

    if (iscoutState.iscoutSettings.pestTogglers.length > 0) {
      iscoutState.iscoutSettings.pestTogglers.forEach((toggle: IPestToggler): void => {
        allPossibleToggles[toggle.name] = toggle;
      });
    }

    const pestAmount = iscoutState.iscoutSettings.selectedPicture.rectangles
      .filter((rect: IRectangle) => !rect.hidden)
      .reduce(
        (result, current) => ({
          ...result,
          [current.label]: result[current.label] === undefined ? 1 : result[current.label] + 1
        }), {}
      );

    return Object.keys(pestAmount)
      .sort()
      .map((pestName: string): IPestToggler => {
        const toggleCreated = allPossibleToggles[pestName] === undefined ? {
          type: IIscoutGlueBoardTargetType.PEST_LABEL,
          ref: null,
          name: pestName,
          is_active: true,
          amount: pestAmount[pestName]
        } : {
          ...allPossibleToggles[pestName],
          amount: pestAmount[pestName]
        };

        if (selectedGlueBoard && selectedGlueBoard.target.length > 0) {
          const findFilter = (target) => target.name === pestName;
          toggleCreated.is_active = selectedGlueBoard.target.find(findFilter) !== undefined;
          toggleCreated.disabled = true;
        }

        return toggleCreated;
      });
  }
);

export const selectIscoutNextPicture = createSelector(
  iscoutSelector,
  (iscoutState: IState, props: { currentPicture: IPicture, next: boolean }): IPicture => {
    const availablePictures = iscoutState.iscout.pictures[DEFAULT_CAMM_ID];
    const index = availablePictures.findIndex(
      (picture) => picture.filename === props.currentPicture.filename
    );
    // Since is ordered by date descendent, it is necessary to subtract to go to the next
    const nextIndex = props.next ? index - 1 : index + 1;
    return availablePictures[nextIndex] || props.currentPicture;
  }
);

export const selectIscoutPestList = createSelector(
  iscoutSelector,
  (iscoutState: IState): Array<ICameraPest> => iscoutState.iscoutPests.pests
);

export const selectIscoutPestsOrders = createSelector(
  iscoutSelector,
  (iscoutState: IState): Array<ICameraPestOrder> => iscoutState.iscoutPests.pestsOrders
);

export const selectIscoutPestsGenus = createSelector(
  iscoutSelector,
  (iscoutState: IState): Array<ICameraPestGenus> => iscoutState.iscoutPests.pestsGenus
);

export const selectIscoutSelectedPest = createSelector(
  iscoutSelector,
  (iscoutState: IState): IIscoutPestFormAction => iscoutState.iscoutPests.selectedPest
);

export const selectIscoutPestsDetails = createSelector(
  iscoutSelector,
  (iscoutState: IState): IIscoutPestMap =>
    iscoutState.iscout.generalPests
      .slice()
      .concat(iscoutState.iscout.userPests)
      .reduce((result, current): IIscoutPestMap => {
        result[current.name] = current;
        return result;
      }, {})
);

export const selectIscoutFilteredRectangles = createSelector(
  iscoutSelector,
  selectIscoutPestsDetails,
  selectIscoutSettingsPestTogglers,
  (iscoutState: IState, pestMap: IIscoutPestMap, pestTogglers: IPestToggler[]): Array<IRectangle> => {
    if (!iscoutState.iscoutSettings.selectedPicture || !iscoutState.iscoutSettings.selectedPicture.rectangles) {
      return [];
    }

    const preFilteredRectangles = iscoutState.iscoutSettings.selectedPicture.rectangles
      .map(
        (rect: IRectangle, index: number): IRectangle => ({
          ...rect,
          originalIndex: index,
          color: pestMap[rect.label] ? pestMap[rect.label].color : null,
          visible: !rect.hidden,
        })
      )
      .filter((rect: IRectangle) => !rect.hidden);

    if (pestTogglers.length > 0) {
      const activePestNames: Array<string> = pestTogglers
        .filter((pestToggler: IPestToggler): boolean => pestToggler.is_active)
        .map((pestToggler: IPestToggler): string => pestToggler.name);
      return preFilteredRectangles.filter(
        (rectangle: IRectangle): boolean => activePestNames.includes(rectangle.label)
      );
    }

    return preFilteredRectangles;
  }
);

export const selectIscoutGlueBoardList = createSelector(
  iscoutSelector,
  (iScoutState: IState): Array<IIscoutGlueBoard> => iScoutState.iscoutGlueBoards.glueBoards
);

export const selectIscoutGlueBoardAvailableDateList = createSelector(
  iscoutSelector,
  (iScoutState: IState): IIscoutGlueBoardAvailableDates => iScoutState.iscoutGlueBoards.availableDates
);

export const selectIscoutSelectedGlueBoard = createSelector(
  iscoutSelector,
  (iScoutState: IState): IIscoutGlueBoardFormAction => iScoutState.iscoutGlueBoards.selectedGlueBoard
);

export const selectIscoutGlueBoardTargetPests = createSelector(
  iscoutSelector,
  (iscoutState: IState): Array<IIscoutGlueBoardTarget> => iscoutState.iscoutPests.pestsOrders
    .map(
      (order: ICameraPestOrder) => ({
        type: IIscoutGlueBoardTargetType.PEST_ORDER,
        ref: order._id,
        name: order.name
      })
    ).concat(
      iscoutState.iscoutPests.pestsFamily.map(
        (family: ICameraPestFamily) => ({
          type: IIscoutGlueBoardTargetType.PEST_FAMILY,
          ref: family._id,
          name: family.name,
        })
      )
    ).concat(
      iscoutState.iscoutPests.pestsGenus.map(
        (genus: ICameraPestGenus) => ({
          type: IIscoutGlueBoardTargetType.PEST_GENUS,
          ref: genus._id,
          name: genus.name,
        })
      )
    ).concat(
      iscoutState.iscoutPests.pests.map(
        (pest: ICameraPest) => ({
          type: IIscoutGlueBoardTargetType.PEST_SPECIES,
          ref: pest._id,
          name: pest.name
        })
      )
    ).concat(
      iscoutState.iscout.generalPests.map(
        (pest: ICameraPest) => ({
          type: IIscoutGlueBoardTargetType.PEST_SPECIES,
          ref: pest._id,
          name: pest.name
        })
      )
    )
);

export const selectIscoutSeasonList = createSelector(
  iscoutSelector,
  (iScoutState: IState): Array<IIscoutSeason> => iScoutState.iscoutSeasons.seasons
);

export const selectIscoutSeasonAvailableGlueBoards = createSelector(
  iscoutSelector,
  (iScoutState: IState): Array<IIscoutGlueBoard> => iScoutState.iscoutSeasons.availableGlueBoards
);

export const selectIscoutSelectedSeason = createSelector(
  iscoutSelector,
  (iScoutState: IState): IIscoutSeasonFormAction => iScoutState.iscoutSeasons.selectedSeason
);
