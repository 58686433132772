import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { setNotify } from '../../../../../core/actions/notify';
import { INotifyState } from '../../../../../core/reducers/notify';
import { IOptionsGroup } from '../../../../../shared/interfaces';
import { unselectIscoutGlueBoard } from '../../../actions/iscout-glue-boards';
import {
  getGlueBoardTargetType,
  IIscoutGlueBoard,
  IIscoutGlueBoardAvailableDates,
  IIscoutGlueBoardFormAction,
  IIscoutGlueBoardState,
  IIscoutGlueBoardTarget,
  IIscoutPestsState,
  IscoutFormActionType
} from '../../../models/iscout.models';
import { selectIscoutGlueBoardTargetPests } from '../../../reducers';

@Component({
  selector: 'app-iscout-glue-board-form',
  templateUrl: './iscout-glue-board-form.component.html',
  styleUrls: ['./iscout-glue-board-form.component.scss']
})
export class IscoutGlueBoardFormComponent implements OnInit, OnDestroy {
  @Input()
  public stationId: string;
  @Input()
  public availableDates: IIscoutGlueBoardAvailableDates;
  @Input()
  public formAction: IIscoutGlueBoardFormAction;
  @Output()
  public glueBoardSaved = new EventEmitter<IIscoutGlueBoard>();
  @Output()
  public glueBoardUpdated = new EventEmitter<IIscoutGlueBoard>();

  public form: FormGroup;
  public targetPestList$: Observable<IOptionsGroup[]>;
  public selectedTargetList: Array<IIscoutGlueBoardTarget> = [];

  private destroy$: Subject<boolean> = new Subject<boolean>();

  public constructor(private iscoutGlueBoardStore: Store<IIscoutGlueBoardState>,
                     private iscoutPestStore: Store<IIscoutPestsState>,
                     private notifyStore: Store<INotifyState>,
                     private fb: FormBuilder) {
  }

  public ngOnInit(): void {
    this.initializeFormValues();
    this.initializePestArray();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initializeFormValues(): void {
    if (this.formAction.action === IscoutFormActionType.UPDATE) {
      this.selectedTargetList = this.formAction.glueBoard.target.slice();

      this.form = this.fb.group({
        'from': [this.formAction.glueBoard.from, [Validators.required]],
        'to': [this.formAction.glueBoard.to, this.formAction.glueBoard.to ? [Validators.required] : []],
        'target': ['', []]
      });
    } else {
      this.form = this.fb.group({
        'from': [moment(this.availableDates.last).toDate(), [Validators.required]],
        'to': [null, []],
        'target': ['', []]
      });
    }
  }

  private initializePestArray(): void {
    this.targetPestList$ = this.iscoutPestStore.pipe(
      select(selectIscoutGlueBoardTargetPests),
      map((pestsTargets: IIscoutGlueBoardTarget[]): IOptionsGroup[] => {
        const groupedTargets = pestsTargets.reduce((result, data): { [type: string]: IOptionsGroup } => {
          if (result[data.type] === undefined) {
            result[data.type] = {
              groupName: getGlueBoardTargetType(data.type),
              options: [],
            };
          }

          result[data.type].options.push({
            value: data.name,
            content: data.ref,
            data
          });

          return result;
        }, {});

        return Object.values(groupedTargets);
      })
    );
  }

  public get glueBoardStart(): AbstractControl {
    return this.form.get('from');
  }

  public get glueBoardFinish(): AbstractControl {
    return this.form.get('to');
  }

  public get glueBoardTarget(): AbstractControl {
    return this.form.get('target');
  }

  public handleAddGlueBoardTarget(): void {
    if (!this.glueBoardTarget.value || this.glueBoardTarget.value === '') {
      this.notifyStore.dispatch(setNotify('Please, select a target'));
      return;
    }

    const selectedGlueBoardTarget: IIscoutGlueBoardTarget = this.glueBoardTarget.value.data;
    const duplicated = this.selectedTargetList.find(
      (tgt) => tgt.ref === selectedGlueBoardTarget.ref
    );
    if (duplicated) {
      this.notifyStore.dispatch(setNotify('This target was already added'));
      return;
    }

    this.selectedTargetList.push(selectedGlueBoardTarget);
    this.glueBoardTarget.setValue('');
  }

  public handleRemoveGlueBoardTarget($event: IIscoutGlueBoardTarget): void {
    this.selectedTargetList = this.selectedTargetList.filter(
      (target) => target.ref !== $event.ref
    );
  }

  public submitGlueBoard(): void {
    if (this.selectedTargetList.length === 0) {
      this.notifyStore.dispatch(setNotify('Please, select at least one target'));
      return;
    }

    const glueBoard = this.buildGlueBoard();

    if (this.formAction.action === IscoutFormActionType.CREATE) {
      this.glueBoardSaved.emit(glueBoard);
    } else {
      this.glueBoardUpdated.emit(glueBoard);
    }
  }

  private buildGlueBoard(): IIscoutGlueBoard {
    const glueBoard: IIscoutGlueBoard = {
      nm: this.stationId,
      from: this.form.value.from,
      target: this.selectedTargetList,
    };

    if ((this.formAction.glueBoard && this.formAction.glueBoard.to) || !!this.form.value.to) {
      glueBoard.to = this.form.value.to;
    }

    if (this.formAction.glueBoard && this.formAction.glueBoard._id) {
      glueBoard._id = this.formAction.glueBoard._id;
    }

    return glueBoard;
  }

  public unselectGlueBoard(): void {
    this.iscoutGlueBoardStore.dispatch(unselectIscoutGlueBoard());
  }

  public get availableDateMin(): Date {
    return moment(this.availableDates.min).toDate();
  }

  public get availableDateMax(): Date {
    return moment(this.availableDates.max).toDate();
  }

  public isCreationForm(): boolean {
    return this.formAction.action === IscoutFormActionType.CREATE;
  }
}
