import { Action } from '@ngrx/store';
import { IActionWithPayload, IActionWithExtraPayload, IActionWithPayloadRedirectAfterFieldDeletion } from '../models/actionWithPayload';
import { ICropZone, IField } from '../models/cropzones';

export const enum SelectedCropZoneActionTypes {
    GET_SELECTED_CROPZONE = '[Account] GET_SELECTED_CROPZONE',
    SET_SELECTED_CROPZONE = '[Account] SET_SELECTED_CROPZONE',
    REDIRECT_AFTER_CREATION = '[Account] REDIRECT_AFTER_CREATION',
    REDIRECT_AFTER_DELETION = '[Account] REDIRECT_AFTER_DELETION',
    REDIRECT_AFTER_FIELD_DELETION = '[Account] REDIRECT_AFTER_FIELD_DELETION'
}

export function getSelectedCropZone(): Action {
    return { type: SelectedCropZoneActionTypes.GET_SELECTED_CROPZONE };
}

export function redirectAfterSuccessfullCreation(payload: {id: string, cropzone: ICropZone}): IActionWithPayload {
    return { type: SelectedCropZoneActionTypes.REDIRECT_AFTER_CREATION, payload: payload };
}

export function setSelectedCropZone(data: ICropZone): IActionWithPayload {
    return { type: SelectedCropZoneActionTypes.SET_SELECTED_CROPZONE, payload: data };
}

export function redirectAfterDeletion(payload: ICropZone, url): IActionWithExtraPayload {
    return { type: SelectedCropZoneActionTypes.REDIRECT_AFTER_DELETION, payload: payload, url: url };
}

export function redirectAfterFieldDeletion(field: IField, cropzones: ICropZone[], url): IActionWithPayloadRedirectAfterFieldDeletion {
    return { type: SelectedCropZoneActionTypes.REDIRECT_AFTER_FIELD_DELETION, payload: field, cropzones: cropzones, url: url };
}
