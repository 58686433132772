<div class="previous-yield-help-modal">
  <p>
    {{ 'In the fields below you can enter and modify your measurements of average yields for this crop zone. Your measured yields ' +
       'are then displayed in the graph. This gives a handy overview of your crop\’s performance.' | translate }}
  </p>
  <p>
    {{ 'The graph also shows the current yield prediction. After harvest, you can enter your actual yield and ' + 
       'it will be shown in the graph in direct comparison with the final yield prediction.' | translate }}
  </p>
  <p>
    {{ 'Entering your measured yields also helps to improve the performance of the yield prediction, as we ' +
       'continue refining the predictor\’s parameterization based on field data.' | translate }}
  </p>
</div>