import { Component, EventEmitter, OnInit, Output, Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IDeleteGlueBoardBody } from '../../../../../shared/camera/models/camera';
import {
  ISCOUT_GLUE_BOARD_DELETE_MODAL_ID,
  ISCOUT_GLUE_BOARD_EXCHANGE_MODAL_ID,
} from '../../../../../shared/constants';
import { ModalService } from '../../../../../shared/modal/services/modal.service';
import { generateId } from '../../../../dashboard/utils/makeWidget';
import {
  IIscoutBlueBoardPlateState,
  IIscoutGlueBoard,
  IIscoutGlueBoardState,
  IIscoutGlueBoardTarget,
  IIscoutPestsState,
  IIscoutSettingsState
} from '../../../models/iscout.models';
import { selectIscoutGlueBoardList } from '../../../reducers';
import { IThemeConfig } from '../../../../../../environments/interfaces/theme';
import { environmentToken } from '../../../../../../environments/environment';
import { IEnvironment } from '../../../../../../environments/interfaces/environment';

@Component({
  selector: 'app-iscout-glue-board-list',
  templateUrl: './iscout-glue-board-list.component.html',
  styleUrls: ['./iscout-glue-board-list.component.scss']
})
export class IscoutGlueBoardListComponent implements OnInit {
  @Output()
  public glueBoardAdded = new EventEmitter<void>();
  @Output()
  public glueBoardEdited = new EventEmitter<IIscoutGlueBoard>();
  @Output()
  public glueBoardExchanged = new EventEmitter<IIscoutGlueBoard>();
  @Output()
  public glueBoardRemoved = new EventEmitter<IDeleteGlueBoardBody>();

  public glueBoardRemoveModalId       = ISCOUT_GLUE_BOARD_DELETE_MODAL_ID;
  public glueBoardExchangeModalId     = ISCOUT_GLUE_BOARD_EXCHANGE_MODAL_ID;
  public glueBoardList$               : Observable<IIscoutGlueBoard[]>;
  public glueBoardSelection           : IIscoutGlueBoard = null;
  public helpModalId                  : string = generateId();

  public subDomain                    : IThemeConfig;

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    private iscoutStore               : Store<IIscoutGlueBoardState>,
    private iscoutPestStore           : Store<IIscoutPestsState>,
    private iscoutSettingsStore       : Store<IIscoutSettingsState>,
    private modalService              : ModalService
  ) {
    this.subDomain = environment.theme;
  }

  public ngOnInit(): void {
    this.glueBoardList$ = this.iscoutStore.pipe(select(selectIscoutGlueBoardList));
  }

  public handleToggleHelp(): void {
    this.modalService.openModal(this.helpModalId);
  }

  public handleGlueBoardAdd(): void {
    this.glueBoardAdded.emit(null);
  }

  public handleGlueBoardEdit(glueBoard: IIscoutGlueBoard): void {
    this.glueBoardEdited.emit(glueBoard);
  }

  public handleGlueBoardExchange(selected: IIscoutGlueBoard): void {
    this.glueBoardSelection = selected;
    this.modalService.openModal(this.glueBoardExchangeModalId);
  }

  public handleGlueBoardExchangeConfirmation(): void {
    this.glueBoardExchanged.emit(this.glueBoardSelection);
    this.handleCloseModal(this.glueBoardExchangeModalId);
  }

  public handleGlueBoardRemove(selected: IIscoutGlueBoard): void {
    this.glueBoardSelection = selected;
    this.modalService.openModal(this.glueBoardRemoveModalId);
  }

  public handleGlueBoardRemoveConfirmation(): void {
    const {nm, _id} = this.glueBoardSelection;
    this.glueBoardRemoved.emit({station_id: nm, glue_board_id: _id});
    this.handleCloseModal(this.glueBoardRemoveModalId);
  }

  public handleCloseModal(glueBoardModal: string): void {
    this.modalService.closeModal(glueBoardModal);
    this.glueBoardSelection = null;
  }

  public getPlateStateClass(plate_state?: IIscoutBlueBoardPlateState): string {
    if (!plate_state) {
      return 'label-default';
    }

    switch (plate_state.label) {
      case 'Good':
        return 'label-success';
      case 'Bad':
        return 'label-danger';
      default:
        return 'label-default';
    }
  }

  public getPlateStateLabel(plate_state?: IIscoutBlueBoardPlateState): string {
    if (!plate_state) {
      return 'Unknown';
    }

    return plate_state.label;
  }

  public renderGlueBoardTargets(target: Array<IIscoutGlueBoardTarget>): string {
    return (target || []).map((value): string => value.name).join(', ');
  }
}
