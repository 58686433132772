import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';

@Component({
  selector: 'app-weather-forecast-history-climate-content',
  templateUrl: './weather-forecast-history-climate-content.component.html',
  styleUrls: ['./weather-forecast-history-climate-content.component.scss']
})
export class WeatherForecastHistoryClimateContentComponent implements OnInit, OnChanges {
  @Input()
  public params                   : any;
  @Input()
  public stationID                : string;
  public imageUrl$                : Observable<string>;
  public isLoading$               : Observable<boolean>;
  public isError$                 : Observable<boolean>;
  public climateType              : string;

  constructor() { }

  public ngOnInit(): void {

  }

  public ngOnChanges(): void {
    this.climateType = this.params.sub.split('-').join('').toLocaleUpperCase();
  }
}
