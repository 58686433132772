import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { saveOtherSettings, updateAddress, updateCompany, updateInfo, updateSettings } from '../../../../../core/actions/account';
import { IAddress, ICompany, IInfo, ISettings } from '../../../../../core/models/account';
import * as fromAccount from '../../../../../core/reducers/account';
import { selectAddress, selectBasicInfo, selectCompany, selectSettings } from '../../../../../core/reducers/index';
import { LanguageService } from '../../../../../services/translation/language.service';
import { card2Animation } from '../../../../../shared/animations/card-animations';
import { emailValidation } from '../../../../../shared/utils/emailValidation';

@Component({
  selector: 'app-other-settings',
  templateUrl: './other-settings.component.html',
  styleUrls: ['./other-settings.component.scss'],
  animations: [card2Animation()]
})
export class OtherSettingsComponent implements OnInit, OnDestroy {

  public basicInfo$           : Observable<IInfo>;
  public addressInfo$         : Observable<IAddress>;
  public settings$            : Observable<ISettings>;
  public companyInfo$         : Observable<ICompany>;
  private alive$              : Subject<boolean> = new Subject<boolean>();

  public success              : boolean;
  public errorMsg             : string;

  private readonly nameError           : string = 'First/Last name not entered';
  private readonly mailError           : string = 'Enter correct email';

  private basicInfo           : IInfo;
  private addressInfo         : IAddress;
  private companyInfo         : ICompany;
  private settings            : ISettings;
  private language            : string;


  constructor(private accountStore: Store<fromAccount.IAccount>,
              private languageService: LanguageService) { }

  public ngOnInit(): void {
    this.basicInfo$ = this.accountStore.pipe(select(selectBasicInfo));
    this.addressInfo$ = this.accountStore.pipe(select(selectAddress));
    this.settings$ = this.accountStore.pipe(select(selectSettings));
    this.companyInfo$ = this.accountStore.pipe(select(selectCompany));

    this.addressInfo$.pipe(
      takeUntil(this.alive$)
    ).subscribe((addressInfo: IAddress) => {
      if (!addressInfo) {
        const defaultAddress: IAddress = {
          city: '',
          country: '',
          district: '',
          street: '',
          zip: ''
        };
        this.accountStore.dispatch(updateAddress(defaultAddress));
      }
    });

    this.companyInfo$.pipe(
      takeUntil(this.alive$)
    ).subscribe((companyInfo: ICompany) => {
      if (!companyInfo) {
        const defaultCompany: ICompany = {
          department: '',
          name: '',
          profession: ''
        };
        this.accountStore.dispatch(updateCompany(defaultCompany));
      }
    });
  }

  public basicInfoChange(info: IInfo): void {
    this.basicInfo = info;
  }

  public addressInfoChange(address: IAddress): void {
    this.addressInfo = address;
  }

  public companyInfoChange(company: ICompany): void {
    this.companyInfo = company;
  }

  public settingsInfoChange(settings: ISettings): void {
    this.settings = settings;
  }

  public update(): void {
    if (!this.basicInfo && !this.addressInfo && !this.companyInfo && !this.settings) {
      return;
    }

    let basicRequest = {};
    let addressRequest = {};
    let companyRequest = {};
    let settingsRequest = {};

    if (this.basicInfo) {
      basicRequest = this.generateRequest('info', this.basicInfo);
    }

    if (this.addressInfo) {
      addressRequest = this.generateRequest('address', this.addressInfo);
    }

    if (this.companyInfo) {
      companyRequest = {
        company: {
          ...this.companyInfo
        }
      };
    }

    if (this.settings) {
      settingsRequest = this.generateRequest('settings', this.settings);
    }

    this.dispatchChanges();

    if (!this.emailValidator(this.basicInfo.email)) {
      this.errorMsg = this.mailError;
      this.disableErrorMsg();
      return;
    }

    if (!this.nameValidator(this.basicInfo.name, this.basicInfo.lastname)) {
      this.errorMsg = this.nameError;
      this.disableErrorMsg();
      return;
    }

    this.language = this.settings.language;


    this.accountStore.dispatch(saveOtherSettings({
      ...addressRequest,
      ...companyRequest,
      ...basicRequest,
      ...settingsRequest
    }));
    this.languageService.setLanguage(this.language);
  }

  private emailValidator(email : string): boolean {
    return emailValidation(email);
  }

  private nameValidator(firstName: string, lastName: string): boolean {
    return !!(firstName.trim() && lastName.trim());
  }
private disableErrorMsg(): void {
    setTimeout(() => this.errorMsg = '', 2000);
  }

  private generateRequest(key: string, obj: any): any {
    return Object.keys(obj).reduce((prev, curr) => {
      prev[`${key}.${curr}`] = obj[curr];
      return prev;
    }, {});
  }

  private dispatchChanges(): void {
    if (this.basicInfo) {
      this.accountStore.dispatch(updateInfo(this.basicInfo));
    }
    if (this.addressInfo) {
      this.accountStore.dispatch(updateAddress(this.addressInfo));
    }
    if (this.companyInfo) {
      this.accountStore.dispatch(updateCompany(this.companyInfo));
    }
    if (this.settings) {
      this.accountStore.dispatch(updateSettings(this.settings));
    }
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }

}
