<ng-container *ngIf="form">
  <form class="send-message-form" [formGroup]="form">

    <div class="send-message-form__text">
      <app-custom-textarea formControlName="message"></app-custom-textarea>
    </div>

    <p>{{ "Feedback Topic" | translate }}</p>

    <div class="send-message-form__dropbox">

      <app-form-select [options]="feedbackTopic"
                       [type]="'border'"
                       formControlName="topic">
      </app-form-select>
    </div>

    <div class="send-message-form__submit">
      <app-custom-button [content]="'Send message' | translate" [color]="'red'" (click)="sendMessage()">
      </app-custom-button>
    </div>
  </form>
</ng-container>
