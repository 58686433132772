<div style="padding: 10px;">
    <form [formGroup]="form">
        <table style="width: 100%;">
            <thead>
                <tr>
                    <th>{{ 'FARM' | translate }}</th>
                    <th>{{ 'FIELD' | translate }}</th>
                    <th>{{ 'CROPZONE' | translate }}</th>
                    <th>{{ 'CROP' | translate }}</th>
                    <th class="dateHeader">{{ 'FROM' | translate }}</th>
                    <th class="dateHeader">{{ 'TO' | translate }}</th>
                    <th>{{ 'START' | translate }}</th>
                    <th>{{ 'EXPIRY' | translate }}</th>
                </tr>
            </thead>
            <tbody [formArrayName]="'cropzones'" *ngIf="cropzoneGroups">
                    <tr *ngFor="let cropzone of cropzoneGroups.controls; let i = index" class="form-group" [formGroupName]="i">
                        <td>
                            <input formControlName="farm" [attr.disabled]="true" class="form-control"/>
                        </td>
                        <td>
                            <input formControlName="field" [attr.disabled]="true" class="form-control"/>
                        </td>
                        <td>
                            <input formControlName="cropzone" class="form-control"/>
                        </td>
                        <td>
                            <input formControlName="crop_name" class="form-control"/>
                        </td>
                        <td style="background: lightblue; border-radius: 3px 0px 0px 3px;">
                            <div [ngClass]="isValidCultivationPeriod(form.value.cropzones[i].from, form.value.cropzones[i].to)
                            && !isFromToOverLicense(form.value.cropzones[i].from, form.value.cropzones[i].to)
                            && !isFromToBeforeLicense(form.value.cropzones[i].from, form.value.cropzones[i].to)
                            && !isFromBeforeLicense(form.value.cropzones[i].from, form.value.cropzones[i].to) ? '' : 'error'">
                                <app-custom-datepicker
                                    [minDate]="minDate"
                                    [formControlName]="'from'"
                                    [maxDate]="form.value.cropzones[i].to"
                                    [hideTime]="true">
                                </app-custom-datepicker>
                            </div>
                        </td>
                        <td style="background: lightblue; border-radius: 0px 3px 3px 0px;">
                            <div [ngClass]="isValidCultivationPeriod(form.value.cropzones[i].from, form.value.cropzones[i].to)
                            && !isFromToOverLicense(form.value.cropzones[i].from, form.value.cropzones[i].to)
                            && !isToOverLicense(form.value.cropzones[i].from, form.value.cropzones[i].to)
                            && !isFromToBeforeLicense(form.value.cropzones[i].from, form.value.cropzones[i].to) ? '' : 'error'">
                                <app-custom-datepicker
                                    [formControlName]="'to'"
                                    [minDate]="form.value.cropzones[i].from"
                                    [maxDate]="maxDate"
                                    [hideTime]="true">
                                </app-custom-datepicker>
                            </div>
                        </td>
                        <td>
                            <input formControlName="start_license" [attr.disabled]="true" class="form-control"/>
                        </td>
                        <td>
                            <input formControlName="end_license" [attr.disabled]="true" class="form-control"/>
                        </td>
                    </tr>
            </tbody>
        </table>
    </form>
    <div class="alert alert-danger" *ngIf="saveDisabled()" style="margin-top: 20px; margin-bottom: 0px;">
        <p>{{ 'Cropzone cultivation period is not allowed to be longer than 1 year.' | translate }}</p>
    </div>
    <div class="alert alert-danger" *ngIf="fromToOverEndLicense()" style="margin-top: 20px; margin-bottom: 0px;">
        <p>{{ 'Warning: Cultivation \'from-to\' dates come after license expiry date' | translate }}</p>
    </div>
    <div class="alert alert-danger" *ngIf="toOverEndLicense()" style="margin-top: 20px; margin-bottom: 0px;">
        <p>{{ 'Warning: Cultivation \'to\' date comes after license expiry date' | translate }}</p>
    </div>

    <div class="alert alert-danger" *ngIf="fromToBeforeLicense()" style="margin-top: 20px; margin-bottom: 0px;">
        <p>{{ 'Warning: Cultivation \'from-to\' dates come before license start date' | translate }}</p>
    </div>
    <div class="alert alert-danger" *ngIf="fromBeforeLicense()" style="margin-top: 20px; margin-bottom: 0px;">
        <p>{{ 'Warning: Cultivation \'from\' date comes after license start date' | translate }}</p>
    </div>

    <div style="display: inline-block; width: 100%; margin-top: 35px;">
        <h4 style="margin-bottom: 10px !important; text-align: left !important;">{{ 'Cropzone duplication' | translate }}</h4>
        <p>{{ 'Cropzone settings are duplicated with cultivation periods in the future. You can edit and update them.' | translate }}</p>
    </div>
    <div style="margin-top: 10px; display: inline-block; width: 100%;">
        <div style="float: right;">
            <app-custom-button [content]="'SAVE'"
                (click)="submit()"
                [color]="'green'"
                [disabled]="submitClicked || saveDisabled() || fromToOverEndLicense() || toOverEndLicense() || fromToBeforeLicense() || fromBeforeLicense()"
            ></app-custom-button>
            <app-custom-button [content]="'BACK'" (click)="back()" [color]="'red'"></app-custom-button>
        </div>
    </div>
</div>
