import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-warnings-phone-remove-modal',
  templateUrl: './warnings-phone-remove-modal.component.html',
  styleUrls: ['./warnings-phone-remove-modal.component.scss']
})
export class WarningsPhoneRemoveModalComponent {
  @Input()
  public number         : string;
  @Output()
  private close         : EventEmitter<void> = new EventEmitter<void>();
  @Output()
  private remove        : EventEmitter<void> = new EventEmitter<void>();

  public emitClose(): void {
    this.close.emit();
  }

  public emitRemove(): void {
    this.remove.emit();
  }

}
