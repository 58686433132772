<form [formGroup]="form"
      class="widget-settings-form"
      *ngIf="stations$ | async">
  <app-custom-dropdown [formControl]="station"
                       class="widget-settings-form__dropdown"
                       [options]="stations$ | async | cropView | stationsOptions">
  </app-custom-dropdown>

  <app-custom-button (click)="submit()"
                     [content]="'Refresh' | translate"
                     [color]="'red'">
    <span><i class="fa fa-fw fa-refresh"></i></span>
  </app-custom-button>
</form>
