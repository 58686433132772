<form [formGroup]="form" class="logging-settings">

  <h4 class="logging-settings__header" *ngIf="!deviceGroup?.led">{{ 'Logging settings' | translate }}</h4>
  <div *ngIf="deviceGroup?.measuring">
    <label class="logging-settings__label">{{ 'Measurement interval' | translate }} {{ !showMeasuring() ? '(Current value: ' + measurementIntervalControl.value + ')' : '' }}</label>
    <div [ngClass]="checkIfMeasurementError() ? 'error' : ''">
      <app-form-select
        [formControl]="measurementIntervalControl"
        [size]="'small'"
        [type]="'border'"
        [isDisabled]="frostEnabled"
        [options]="measurementOptions"></app-form-select>
    </div>
  </div>
  <div *ngIf="deviceGroup?.logging && !isSolAntenna">
    <label class="logging-settings__label">{{ 'Logging interval' | translate }} {{ !showLogging() ? '(Current value: ' + loggingIntervalControl.value + ')' : '' }}</label>
    <div [ngClass]="checkIfLoggingError() ? 'error' : ''">
      <app-form-select
        [formControl]="loggingIntervalControl"
        [size]="'small'"
        [type]="'border'"
        [isDisabled]="frostEnabled"
        [options]="loggingOptions"></app-form-select>
    </div>
  </div>
  <div *ngIf="deviceGroup?.fixedTransfer && !isSolAntenna">
    <label class="logging-settings__label">{{ 'Fixed transfer interval' | translate }} {{ !showTransfer() ? '(Current value: ' + fixedTransferControl.value + ')' : '' }}</label>
    <div [ngClass]="checkIfTransferError() ? 'error' : ''">
      <app-form-select
        [formControl]="fixedTransferControl"
        [size]="'small'"
        [type]="'border'"
        [isDisabled]="frostEnabled"
        [options]="fixedTransferOptions"></app-form-select>
    </div>
  </div>

  <div *ngIf="isSolAntenna">
    <div>
      <label class="logging-settings__label">{{ 'Logging/Transfer interval' | translate }} {{ !showLogging() ? '(Current value: ' + loggingIntervalControl.value + ')' : '' }}</label>
      <div [ngClass]="checkIfLoggingTransferError() ? 'error' : ''">
          <app-form-select [formControl]="loggingIntervalControl"
          [size]="'small'"
          [type]="'border'"
          [isDisabled]="frostEnabled"
          [options]="loggingOptions"></app-form-select>
      </div>
    </div>
  </div>

  <div *ngIf="form && form.errors" style="margin-top: 20px;">
    <div class="alert alert-danger" *ngIf="form.errors.noValidMeasuringSelection" role="alert">
      <p *ngIf="measurementIntervalControl.value !== 0">
        {{ 'Not a valid Measuring interval.' | translate }}</p>
    </div>
    <div class="alert alert-danger" *ngIf="form.errors.noValidLoggingSelection && !form.errors.loggingNotSelected && !isSolAntenna" role="alert">
      <p>{{ 'Not a valid Logging interval.' | translate }}</p>
    </div>
    <div class="alert alert-danger" *ngIf="form.errors.loggingNotSelected && !isSolAntenna" role="alert">
      <p>{{ 'No Logging interval selected.' | translate }}</p>
    </div>
    <div class="alert alert-danger" *ngIf="form.errors.measurementNotFilled">
      <p>{{ 'Measurement interval should also be selected.' }}</p>
    </div>
    <div class="alert alert-danger" *ngIf="form.errors.loggingNotFilled">
      <p>{{ 'Logging interval should also be selected.' }}</p>
    </div>
    <div class="alert alert-danger" *ngIf="form.errors.noValidTransferSelection && !isSolAntenna" role="alert">
      <p>{{ 'Not a valid Fixed Transfer interval.' | translate }}</p>
    </div>
    <div class="alert alert-danger" *ngIf="form.errors.measurementLargerThanLogging && !isSolAntenna"
      role="alert">
        <p>{{ 'Logging interval must be the same or larger than the measurement interval.' | translate }}</p>
    </div>
    <div class="alert alert-danger" *ngIf="form.errors.loggingIsNotAMultiplier
      && !form.errors.measurementLargerThanLogging
      && !form.errors.noValidLoggingSelection
      && !isSolAntenna"
      role="alert">
        <p>{{ 'Logging interval must be a multiplier of the measurement interval.' | translate }}</p>
    </div>
    <div class="alert alert-danger" *ngIf="form.errors.loggingLargerThanTransfer
      && !noValidTransferSelection
      && !isSolAntenna"
      role="alert">
        <p>{{ 'Fixed transfer interval must be the same or larger than the logging interval.' | translate }}</p>
    </div>
    <div class="alert alert-danger" *ngIf="form.errors.transferIsNotAMultiplier
      && !form.errors.loggingLargerThanTransfer
      && !form.errors.noValidTransferSelection
      && !isSolAntenna"
      role="alert">
        <p>{{ 'Fixed transfer interval must be a multiplier of the logging interval.' | translate }}</p>
    </div>

    <div class="alert alert-danger" *ngIf="form.errors.noValidLoggingTransferSelection && isSolAntenna" role="alert">
      <p>{{ 'Not a valid Logging/Transfer interval.' | translate }}</p>
    </div>
    <div class="alert alert-danger" *ngIf="form.errors.measuringLargerThanLoggingTransfer
      && !noValidLoggingTransferSelection
      && isSolAntenna"
      role="alert">
        <p>{{ 'The Logging/Transfer interval must be the same or larger than the measuring interval.' | translate }}</p>
    </div>
    <div class="alert alert-danger" *ngIf="form.errors.loggingTransferNotAMultiplier
      && !form.errors.measuringLargerThanLoggingTransfer
      && !form.errors.noValidLoggingTransferSelection
      && isSolAntenna"
      role="alert">
        <p>{{ 'The Logging/Transfer interval must be a multiplier of the measuring interval.' | translate }}</p>
    </div>
  </div>




</form>
