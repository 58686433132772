<app-tree [tree$]="tree$"></app-tree>
<div class="body-view-content">
  <app-station-page-header [pageName]=getPageName()></app-station-page-header>

  <div *ngIf="station">
    <div *ngIf="canAccess(); else warning">
      <app-ultrasonic-distance-settings-content></app-ultrasonic-distance-settings-content>
    </div>

    <ng-template #warning>
      <div class="alert alert-warning ultrasonic-distance-settings__warning">
        {{ 'You have read-only rights to this station, therefore you cannot update the settings.' | translate }}
      </div>
    </ng-template>
  </div>
</div>


