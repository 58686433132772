import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-activation-header',
  templateUrl: './product-activation-header.component.html',
  styleUrls: ['./product-activation-header.component.scss'],
})
export class ProductActivationHeaderComponent {
  @Input()
  public title: string;
  @Input()
  public subtitle: string = '';
}
