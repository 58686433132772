import { Injectable } from '@angular/core';
import { IStation } from '../../../core/models/stations';
import { LanguageService } from '../../../services/translation/language.service';

@Injectable({
  providedIn: 'root'
})

export class MapService {
  private style : string = 'mapbox://styles/mapbox/streets-v11';
  private mapboxApiKey : string = 'pk.eyJ1Ijoia2Vubnl2ZXJzdHJhZXRlIiwiYSI6ImNrdjhuejJrbTA1YmwybnRjeHFpNHZwZ2cifQ.dUCaVPVWJa_Tb9foKAEsWA';
  private weatherMaps : string = 'satellite,radar,cloudsAndPrecipitation,temperature,windAnimation,warningsAndRisk,extremeForecastIndex';
  private meteoBlueApiKey : string = 'iuqwh32oifh';
  private timezoneAbbreviation : string = 'CET';
  private timezoneOffsetInSeconds : number = 3600;

  constructor(
    private languageService   : LanguageService
  ) { }

  public buildMap(station: IStation, mapContainer: string | HTMLElement): any {
    // @ts-ignore
    const map = new window.mapboxgl.Map({
      container: mapContainer,
      style: this.style,
      accessToken: this.mapboxApiKey,
      center: station.position.geo.coordinates,
      hash: false,
      attributionControl: false,
      keyboard: false,
    });

    return map;
  }

  public addPlugin(station, map): any {
    let language = this.languageService.languageFromStorage;
    language = language === 'br' ? 'pt' : language;

    const inventoryUrl =
      `https://maps-api.meteoblue.com/v1/map/inventory/filter?lang=${language}&maps=${this.weatherMaps}&apikey=${this.meteoBlueApiKey}`;

    this.timezoneOffsetInSeconds = station.config.timezone_offset * 60;
    this.timezoneAbbreviation = station.position.timezoneCode;

    // @ts-ignore
    this.plugin = new window.meteoblueMapsPlugin({
      mapboxMap: map,
      inventory: inventoryUrl,
      apiKey: this.meteoBlueApiKey,
      canUseRestricted: true,
      timezoneAbbreviation: this.timezoneAbbreviation,
      timezoneOffsetInSeconds: this.timezoneOffsetInSeconds,
      controls: {
        zoom: true
      },
      defaults: {
        map: 'satellite',
        variant: 'radar'
      },
      showLegend: true,
      showTimeSelection: true
    });

    // @ts-ignore
    return this.plugin;
  }

}
