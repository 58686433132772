import {Pipe, PipeTransform} from '@angular/core';
import {IStation} from '../../../../core/models/stations';
import {findForecast} from '../../utils/findStations';

@Pipe({
  name: 'weatherForecast'
})
export class WeatherForecastPipe implements PipeTransform {

  public transform(stations: Array<IStation>): Array<IStation> {
    return Array.isArray(stations) ? findForecast(stations) : [];
  }

}
