import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, takeWhile, map, filter } from 'rxjs/operators';
import { IStation } from '../../../../../../core/models/stations';
import { selectStations } from '../../../../../../core/reducers/index';
import * as fromStations from '../../../../../../core/reducers/stations';
import { IOptions } from '../../../../../../shared/interfaces';
import { ChartProfiles, GroupValues, LastDailyValues, LastValues } from '../../../../constants/constants';
import { IChartRequest } from '../../../../models/dashboard.models';
import { deepClone } from '../../../../../../shared/utils/deepClone';

@Component({
  selector: 'app-chart-settings',
  templateUrl: './chart-settings.component.html',
  styleUrls: ['./chart-settings.component.scss']
})
export class ChartSettingsComponent implements OnInit, OnDestroy {
  @Input()
  private chartRequest              : IChartRequest;
  @Output()
  private updateRequest             : EventEmitter<IChartRequest> = new EventEmitter();
  @Output()
  private updateWidgetData          : EventEmitter<void> = new EventEmitter();

  public form                       : FormGroup;
  public allStations$               : Observable<Array<IStation>>;
  public groupValues                : Array<IOptions> = GroupValues;
  public lastValues                 : Array<IOptions> = LastValues;
  public lastDailyValues            : Array<IOptions> = LastDailyValues;
  public chartProfiles              : Array<IOptions> = ChartProfiles;
  private alive                     : boolean = true;

  constructor(private stationsStore: Store<fromStations.IStations>,
              private formBuilder: FormBuilder) {}

  public get group(): AbstractControl {
    return this.form.get('group');
  }

  public get last(): AbstractControl {
    return this.form.get('last');
  }

  public get station(): AbstractControl {
    return this.form.get('station');
  }

  public get profile(): AbstractControl {
    return this.form.get('profile');
  }

  public ngOnInit(): void {
    this.allStations$ = this.stationsStore.pipe(
      select(selectStations),
      filter((stations: IStation[]) => !!stations && stations.length > 0),
      map((stations: IStation[]) => this.sortArray(stations))
      );

    this.form = this.formBuilder.group({
      'group': new FormControl(this.chartRequest.endpoint.group),
      'last' : new FormControl(this.chartRequest.endpoint.last),
      'station': new FormControl(this.chartRequest.endpoint.station),
      'profile': new FormControl(this.chartRequest.profile)
    });

    this.form.valueChanges.pipe(
      distinctUntilChanged(),
      takeWhile(() => this.alive)
    ).subscribe(() => {
      this.changeRequest();
    });
  }

  private sortArray(stationArray: IStation[]): IStation[] {
    const stations = deepClone(stationArray);
    const customNamedStations = stations.filter((station) => station.name.custom !== '');
    customNamedStations.sort((a, b) => {
      const nameA = a.name.custom.toLowerCase();
      const nameB = b.name.custom.toLowerCase();

      const isNumberA = !isNaN(Number(nameA));
      const isNumberB = !isNaN(Number(nameB));

      if (isNumberA && isNumberB) {
        return Number(nameA) - Number(nameB);
      } else if (isNumberA) {
        return -1;
      } else if (isNumberB) {
        return 1;
      } else {
        return nameA.localeCompare(nameB);
      }
    });

    const otherStations = stations.filter((station) => station.name.custom === '');
    otherStations.sort((a: IStation, b: IStation) => a.name.original.toLowerCase() < b.name.original.toLowerCase() ? -1 : 1);

    return customNamedStations.concat(otherStations);
  }

  public changeRequest(): void {
    const newRequest = {
      endpoint: {
        group: this.group.value,
        last: this.last.value,
        station: this.station.value
      },
      legend: this.chartRequest.legend,
      profile: this.profile.value
    };
    // Remove station health from virtual stations
    if (this.station.value.substring(0, 3) === 'D00') {
      this.chartProfiles = this.chartProfiles.filter(item => item.value !== 'Station Health');
    } else {
      this.chartProfiles = ChartProfiles;
    }
    this.updateRequest.emit(newRequest);
  }

  public submit(): void {
    this.updateWidgetData.emit();
  }

  public ngOnDestroy(): void {
    this.alive = false;
  }
}
