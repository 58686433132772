import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import * as fromNavigationStation from '../../../../core/reducers/navigation-station';
import { stationDataContentAnimations } from '../../../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../../../core/services/left-components-toggler/left-components-toggler.service';
import { ITreeStructure } from '../../../../services/tree/models';
import { TreeService } from '../../../../services/tree/tree.service';
import { deepClone } from '../../../../shared/utils/deepClone';
import { getSensors } from '../../actions/warningsList';
import { setWarningsPhones } from '../../actions/warningsPhones';
import * as fromWarningsList from '../../reducers/warningsList';
import * as fromWarningsPhones from '../../reducers/warningsPhones';

@Component({
  selector: 'app-warnings',
  templateUrl: './warnings.component.html',
  styleUrls: ['./warnings.component.scss'],
  animations: stationDataContentAnimations
})
export class WarningsComponent implements OnInit, OnDestroy {
  public state$                 : Observable<string>;
  public tree$                  : Observable<ITreeStructure> = this.treeService.getStationSettingsTreeStructure();
  public selectedStation        : IStation;
  private destroy$              : Subject<boolean> = new Subject<boolean>();
  private currentId             : string = '';

  constructor(private treeService: TreeService,
              private warningsListStore: Store<fromWarningsList.IWarningsListState>,
              private warningsPhonesStore: Store<fromWarningsPhones.IWarningsPhonesState>,
              private navigationStore: Store<fromNavigationStation.INavigationStationState>,
              private leftComponentsToggler: LeftComponentsTogglerService) { }

  public ngOnInit(): void {
    this.state$ = this.leftComponentsToggler.getStationDataContentState();

    this.navigationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((s: IStation) => !!s)
    ).subscribe((s: IStation) => {
      this.currentId = s.name.original;
      this.selectedStation = s;
      if (s.warnings !== undefined) {
        this.warningsListStore.dispatch(getSensors({
          stationId: s.name.original,
          warnings: s.warnings.sensors
        }));
        this.warningsPhonesStore.dispatch(setWarningsPhones(deepClone(this.selectedStation.warnings.sms_numbers)));
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
