import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as fromLicenses from '../../../../core/reducers/licenses';
import * as fromSelectedCropzone from '../../../../core/reducers/selectedCropZone';
import { selectAvailableLicenses, selectSelectedCropZone } from './../../../../core/reducers/index';

@Component({
  selector: 'app-cropzone-config-location-help-text-modal',
  templateUrl: './cropzone-config-location-help-text-modal.component.html',
  styleUrls: ['./cropzone-config-location-help-text-modal.component.scss']
})
export class CropzoneConfigLocationHelpTextModalComponent implements OnInit {
  public satelliteLicense: boolean = false;

  constructor(private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
              private licenseStore: Store<fromLicenses.IProductLicenseState>) {

  }

  public ngOnInit(): void {
    combineLatest([
      this.licenseStore.pipe(
        select(selectAvailableLicenses),
        filter((licenses) => !!licenses)
      ),
      this.selectedCropzoneStore.pipe(
        select(selectSelectedCropZone),
        filter((cropzone) => !!cropzone)
      )
    ]).subscribe(([licenses, cropzone]) => {
      let cropzoneProductKey: string;
      const now = new Date();

      if (cropzone.product_key) {
        cropzoneProductKey = cropzone.product_key;

        licenses.forEach(license => {
          if (license.product_item.key === cropzoneProductKey) {
            if (now > moment(license.start_date).toDate() && now < moment(license.end_date).toDate()) {
              this.satelliteLicense = license.Satellite;
            }
          }
        });
      } else {
        this.satelliteLicense = false;
      }
    });
  }

}
