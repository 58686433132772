<div class="page-without-left-tree dashboard">

  <div *ngIf="!subDomain?.hideTerms">
    <app-terms-and-conditions class="dashboard__terms"
                              *ngIf="!(terms$ | async) && (terms$ | async) !== null">
    </app-terms-and-conditions>
  </div>

  <app-dashboard-menu (editMode)="editMode()"
                      (addColumns)="addColumns($event)"
                      (setDefault)="setDefault()"
                      (save)="save()"
                      (restore)="restore()"></app-dashboard-menu>

  <h3 *ngIf="showAddRemove" class="dashboard__add-station">{{ 'No stations have been added yet.' | translate }}
    <a (click)="openAddRemoveModal()">{{ 'Add a station' | translate }}</a></h3>

  <div *ngIf="!subDomain?.hideDashboardText">
    <div *ngFor="let text of dashboardTexts; let i = index">
      <div *ngIf="text.show"
         class="alert alert-success dashboard__farmview-info xContainer">
        <i [ngClass]="text.icon"></i>
        {{ text.text | translate }}
        <a target="_blank" [href]="text.link">{{ text.link }}</a>
        <div class="closeDiv" (click)="closeDivClick(i)">
          <i class="zmdi zmdi-close closeButton"></i>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="false" class="alert alert-warning dashboard__farmview-info" >
    Dear customers,<br />
    We are sorry, but we are currently experiencing problems with our system.
    We are aware of this and we are working on providing a solution to you as soon as we can.<br />
    We apologize for any inconvenience and thank you for your patience.
    <br /><br />
    <strong>Your Pessl Instruments team.</strong>
  </div>

  <section class="columns">

    <ng-container *ngFor="let item of dashboard; let i = index">
      <app-row [row]="item"
               [rowIndex]="i"
               [isEdit]="isEdit$ | async"></app-row>
    </ng-container>
  </section>

</div>


<app-modal [modalId]="modalId"
           [styles]="{ 'max-width': '300px'}">
  <app-leave-confirm (leave)="leave()"
                     (stay)="closeLeaveModal()"></app-leave-confirm>
</app-modal>
