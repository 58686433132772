import { ICropZone } from './../../../core/models/cropzones';
import {IAddWidgetForm, IWidget} from '../models/dashboard.models';
import {IStation} from '../../../core/models/stations';
import {checkCameras} from '../../../shared/utils/checkCameras';
import * as moment from 'moment';

export function makeWidget(station?: IStation, basicInfo?: IAddWidgetForm, cropzone?: ICropZone): IWidget {

  return {
    color: basicInfo ? basicInfo.color : '',
    description: basicInfo ? basicInfo.description : '',
    id: generateId(),
    name: basicInfo ? basicInfo.type : 'stations-list',
    requests: {
      chart: {
        endpoint: {
          group: 'hourly',
          last: '24',
          station: station.name.original
        },
        legend: true,
        profile: checkCameras(station.info.device_id) ? 'Station Health' : 'Disease climate'
      },
      cropview: {
        endpoint: {
          group: 'hourly',
          last: '24',
          station: station.name.original
        }
      },
      data: {
        endpoint: {
          group: 'hourly',
          last: '24',
          station: station.name.original,
          stationName : station.name.custom
        }
      },
      disease: {
        endpoint: {
          crop : 'Apple',
          disease : 'Apple/Scab/AscosporeRelease',
          diseaseName : 'Scab / Ascospore Release',
          group: 'hourly',
          last: '24',
          station: station.name.original
        },
        legend: true,
        profile : 'Disease models'
      },
      forecast: {
        endpoint : {
          profile: 'meteogram_one',
          station: station.name.original
        }
      },
      forecastChart: {
        endpoint : {
          profile: 'general3',
          station: station.name.original
        }
      },
      forecastTable: {
        endpoint : {
          profile: 'general7',
          station: station.name.original,
          stationName: station.name.custom
        }
      },
      iscout : {
        endpoint: {
          group: 'hourly',
          last: '24',
          station: station.name.original
        }
      },
      tracker : {
        from: moment().subtract(1, 'day').unix(),
        to: moment().unix(),
        station: station.name.original
      },
      cropzones : {
        cropzone: cropzone ? cropzone : null
      },
      windrose: {
        endpoint: {
          group: 'hourly',
          last: '1',
          station: station.name.original,
          windspeed: '',
          winddir: ''
        },
        legend: true,
      },
    },
    settings: {
      chart : true,
      cropview : true,
      data : true,
      disease: true,
      forecast : true,
      forecastChart: true,
      forecastTable : true,
      iscout : true,
      tracker: true,
      cropzones: true,
      windrose: true
    },
    title: basicInfo ? basicInfo.title : ''
  };
}

export function generateId(): string {
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

function s4(): string {
  return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
}

