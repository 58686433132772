import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { DatesTranslationsService } from '../../../../core/services/dates-translations.service';
import { IFromTo } from '../../../../shared/camera/models/camera';
import { getDateForCameraPicture } from '../../../../shared/utils/dateFormat';
import { StationCameraTypes } from '../../constants/camera-data';
import { ICameraDataState } from '../../models/camera-data';
import {
  selectCameraDataCurrentDateString,
  selectCameraDataIsChartActive,
  selectCameraDataIsHelpActive,
  selectCameraDataIsTableActive
} from '../../reducers';

@Component({
  selector: 'app-camera-data-content',
  templateUrl: './camera-data-content.component.html'
})
export class CameraDataContentComponent implements OnInit, OnDestroy {
  @Input()
  public stationCameraType: string;

  public isHelpActive$: Observable<boolean>;
  public isChartActive$: Observable<boolean>;
  public isTableActive$: Observable<boolean>;
  public stationChangeObservable$: Observable<string>;
  public currentDateObservable$: Observable<string | IFromTo>;
  public pageTitleObservable$: Observable<string>;

  private translatedMonths: object;
  private translatedForWeekDate: object;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
      private navigationStationStore: Store<INavigationStationState>,
      private cameraDataStore: Store<ICameraDataState>,
      private datesTranslationsService: DatesTranslationsService) {
  }

  public ngOnInit(): void {
    this.translatedMonths = this.datesTranslationsService.translateMonths();
    this.translatedForWeekDate = this.datesTranslationsService.translateForDate();

    this.initStatusListeners();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public get isIScout(): boolean {
    return this.stationCameraType === StationCameraTypes.ISCOUT;
  }

  public get isCropView(): boolean {
    return this.stationCameraType !== StationCameraTypes.ISCOUT;
  }

  private initStatusListeners(): void {
    this.isHelpActive$ = this.cameraDataStore.pipe(
      select(selectCameraDataIsHelpActive)
    );

    this.isChartActive$ = this.cameraDataStore.pipe(
      select(selectCameraDataIsChartActive)
    );

    this.isTableActive$ = this.cameraDataStore.pipe(
      select(selectCameraDataIsTableActive)
    );

    this.initStationChangeObservable();
    this.initCurrentDateChangeObservable();
  }

  private initStationChangeObservable(): void {
    this.stationChangeObservable$ = this.navigationStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((station: IStation): boolean => !!station),
      map((station: IStation): string => {
        return station.name.original;
      })
    );
  }

  private initCurrentDateChangeObservable(): void {
    this.currentDateObservable$ = this.cameraDataStore.pipe(
      takeUntil(this.destroy$),
      select(selectCameraDataCurrentDateString),
      filter((currentDate: string | IFromTo): boolean => !!currentDate)
    );

    this.pageTitleObservable$ = this.currentDateObservable$.pipe(
      map((currentDate: string | IFromTo): string => this.formatCurrentDate(currentDate))
    );
  }

  private formatCurrentDate(date: string | IFromTo): string {
    if (typeof date === 'string') {
      const weekDate = getDateForCameraPicture(date);

      if (weekDate === date) {
        const d = moment(date).format('MMMM, YYYY');
        const year = this.datesTranslationsService.getYearMonths(d);
        const m = this.datesTranslationsService.getMonth(d);
        const month = this.translatedMonths[m];
        return `${month}, ${year}`;
      } else {
        const week = this.datesTranslationsService.getWeek(weekDate);
        const year = this.datesTranslationsService.getYearWeek(weekDate);
        return `${this.translatedForWeekDate['week']} ${week} ${this.translatedForWeekDate['in']} ${year}`;
      }
    }

    const dateFrom = this.datesTranslationsService.splitDate(this.formatDate(date.from));
    const dateTo = this.datesTranslationsService.splitDate(this.formatDate(date.to));
    const monthFrom = this.translatedMonths[dateFrom['month']];
    const monthTo = this.translatedMonths[dateTo['month']];
    return `${monthFrom} ${dateFrom['day']} ${dateFrom['year']} - ${monthTo} ${dateTo['day']} ${dateTo['year']}`;
  }

  private formatDate(timestamp: number): string {
    return moment(timestamp, 'X', true).format('LL');
  }
}
