import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-station-load-info',
  templateUrl: './station-load-info.component.html',
  styleUrls: ['./station-load-info.component.scss']
})

export class StationLoadInfoComponent {
  @Input()
  public isError      : boolean = false;
  @Input()
  public isLoading    : boolean = false;
  @Input()
  public errorText    : string = 'No data for selected station';

}
