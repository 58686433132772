import {IFromTo, IStationDataProfile, IStationDataWarningMessage} from '../models/station-data.models';
import {StationDataActionTypes} from '../actions/station-data';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {ITopologyItem} from '../../../services/tree/models';

export interface IStationDataState {
  profiles: Array<IStationDataProfile>;
  chartOptions: Array<any>;
  grid: {};
  topology: Array<ITopologyItem>;
  isLoading: boolean;
  isError: boolean;
  licenses: any;
  warning: IStationDataWarningMessage;
}

export const initialState: IStationDataState = {
  profiles: [],
  chartOptions: [],
  grid: {},
  topology: [],
  isLoading: true,
  isError: false,
  licenses: null,
  warning: {
    message: null,
    icon: null
  }
};

export function reducer(state: IStationDataState = initialState, action: IActionWithPayload): IStationDataState {
  switch (action.type) {
    case StationDataActionTypes.SET_STATION_DATA_PROFILES:
      return <IStationDataState>{
        ...state,
        profiles: action.payload
      };
    case StationDataActionTypes.SET_STATION_DATA_FC:
      if (action.payload.topology[0]?.name.slice(0, 3) === '04E') {
        const chartsOptions = action.payload.chartsOptions;
        const index = chartsOptions.findIndex((chartOption: any) => {
          return chartOption.yAxis[0].title.text === 'Battery [mV]';
        });
        if (index) {
          action.payload.chartsOptions.push(action.payload.chartsOptions.splice(index, 1)[0]);
        }
      }
      return <IStationDataState>{
        ...state,
        chartOptions: action.payload.chartsOptions,
        grid: action.payload.grid,
        topology: action.payload.topology,
      };
    case StationDataActionTypes.SET_STATION_DATA_LOADING:
      return <IStationDataState> {
        ...state,
        isLoading: action.payload,
      };
    case StationDataActionTypes.SET_STATION_DATA_ERROR:
      return <IStationDataState> {
        ...state,
        isError: action.payload,
      };
    case StationDataActionTypes.SET_STATION_DATA_LICENSES:
      return <IStationDataState> {
        ...state,
        licenses: action.payload
      };
    case StationDataActionTypes.SET_STATION_DATA_WARNING:
      return <IStationDataState>{
        ...state,
        warning: action.payload
      };
    default:
      return state;
  }
}
