<form class="product-activation-disease-model-form" [formGroup]="form">

  <div class="row">
    <div class="col-md-4">
      <label>{{ 'Select station' | translate }}</label>
      <br>
      <app-custom-simple-lazy-dropdown class="product-activation-disease-model-form__input"
                                       [shadow]="false"
                                       [formControl]="stationName"
                                       [items$]="userStations$"></app-custom-simple-lazy-dropdown>
    </div>

    <div class="col-md-4">
      <label>{{ 'Select disease model' | translate }}</label>
      <br>
      <app-custom-simple-lazy-dropdown class="product-activation-disease-model-form__input"
                                       [shadow]="false"
                                       [formControl]="licenseGroup"
                                       [items$]="diseaseModels$"></app-custom-simple-lazy-dropdown>
    </div>

    <div class="col-md-4">
      <label>{{ 'Starting date' | translate }}</label>
      <app-custom-datepicker class="product-activation-disease-model-form__input"
                             [isSmall]="true"
                             [minDate]="minDate"
                             [maxDate]="maxDate"
                             [formControl]="startingDate">
      </app-custom-datepicker>
    </div>
  </div>

  <div class="product-activation-disease-model-form__submit">
    <span class="product-activation-disease-model-form__submit__info">
      {{ '* License is valid for' | translate }} {{ licenseDuration }}
    </span>

    <app-custom-button [disabled]="form.invalid"
                       [content]="'Add disease model' | translate"
                       [color]="'green'"
                       (click)="submit()"></app-custom-button>

    <div class="product-activation-disease-model-form__error" *ngIf="stationName.hasError('station_name') && !stationName.pristine">
      <p>{{ 'A station must be selected!' | translate }}</p>
    </div>

    <div class="product-activation-disease-model-form__error" *ngIf="licenseGroup.hasError('license_group') && !licenseGroup.pristine">
      <p>{{ 'A disease model must be selected!' | translate }}</p>
    </div>
  </div>
</form>
