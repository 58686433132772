<form [formGroup]="form" class="scheduler">
  <h4 class="scheduler__header">
    {{ 'Scheduler' | translate }}
  </h4>

  <div class="alert alert-info scheduler__alert">
    <strong>{{ 'Attention!' | translate }}</strong>
    <span>
      {{ 'The LED configuration only applies if temperature is above the threshold' | translate }}
    </span>
  </div>

  <div class="row" [formArrayName]="'scheduler'">
    <div *ngFor="let column of schedulerArray.controls; let i = index" [formArrayName]="i" class="col-sm-2">
      <div *ngFor="let option of column.controls; let j = index" class="">
        <label class="scheduler__label">
          <input [attr.type]="'checkbox'"
                 [formControlName]="j"
                 [checked]="option.value === '1'"
                 (click)="option.setValue(option.value === '1' ? 0 : 1);">
          {{ getTime(i, j) }}
        </label>
      </div>
    </div>
  </div>

  <div class="scheduler__buttons">
    <app-custom-button [color]="isSelectDefaultActive() ? 'green' : 'white'"
                       (click)="selectDefault()"
                       [content]="'Default selection'">
      <i class="fa fa-fw" [class.fa-check]="isSelectDefaultActive()"></i>
    </app-custom-button>
  </div>
</form>
