import { AgmMap, LatLngBounds } from '@agm/core';
import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { NavigationService } from '../../../../core/services/navigation/navigation.service';
import { IWidget } from '../../models/dashboard.models';
import { ILngLat, ITrackerTripsData } from '../../../tracker/models/tracker.models';

declare var google: any;

@Component({
  selector: 'app-tracker-component',
  templateUrl: './tracker-component.component.html',
  styleUrls: ['./tracker-component.component.css']
})
export class TrackerComponentComponent implements OnChanges {
  @Input()
  public widget : IWidget;
  @Input()
  public trackerData : any = null;

  @ViewChild('AgmMap') public agmMap: AgmMap;
  public map: any;
  public loading: boolean = true;

  private markerIcon = {
    start: './../../../../assets/img/markers/ff_red.png',
    intermediate: './../../../../assets/img/markers/circle_ff_red.png',
    end: './../../../../assets/img/markers/light-green-lg.png'
  };

  constructor(
    private navigationService: NavigationService,
  ) {}

  public navigateTrackerPage(trip, index) : void {
    localStorage.setItem('selectedTrip', this.trackerData.trip[index].name);
    this.navigationService.navigateToTracker(this.widget.requests.tracker.station);
  }

  public onMapReady(map: AgmMap): void {
    this.map = map;
    this.changeBounds();
  }

  private changeBounds(): void {
    const bounds: LatLngBounds = new google.maps.LatLngBounds();
    const trips: Array<ITrackerTripsData> = this.trackerData.trip;

    trips.forEach((trip: ITrackerTripsData) => {
      trip.path.forEach((coordinates: ILngLat) => {
        bounds.extend(coordinates);
      });
    });

    if (!bounds.isEmpty()) {
      this.map.fitBounds(bounds);
    }
  }

  public getIconUrl(first: boolean, last: boolean): string {
    if (first) {
      return this.markerIcon.start;
    }
    if (last) {
      return this.markerIcon.end;
    }
    return this.markerIcon.intermediate;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.trackerData && this.map) {
      this.changeBounds();
    }
  }

}
