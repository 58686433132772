import * as moment from 'moment';
import {IRectangle} from './rectangles';

export enum PhotoRequestType {
  SINGLE_DATE_INTERVAL = 'singleDateIntervalRequest',
  MIN_MAX_INTERVAL = 'minMaxIntervalRequest'
}

interface IGetPhotosBaseRequest {
  type: PhotoRequestType.SINGLE_DATE_INTERVAL;
  stationId: string;
  date?: string;
}

export interface IGetPhotosRequest extends IGetPhotosBaseRequest {
  camId?: number;
}

export interface IGetPhotosRequests extends IGetPhotosBaseRequest {
  camIds?: Array<number>;
}

export interface IGetPhotosRequestFromTo {
  type: PhotoRequestType.MIN_MAX_INTERVAL;
  stationId: string;
  camId?: number;
  camIds?: Array<number>;
  from: number;
  to: number;
}

export interface ISaveMeasurements {
  cam_id: number;
  filename: string;
  rectangles: Array<IRectangle>;
}

export interface ISaveMeasurementsRequest extends ISaveMeasurements {
  date: Date;
}

export interface ISaveMeasurementsResponse extends ISaveMeasurements {
  station_id: string;
  date: string;
}

export interface IClearMeasurementsRequest {
  cam_id: number;
  filename: string;
}

export interface IClearMeasurementsResponse {
  station_id: string;
  cam_id: number;
  filename: string;
}

export interface IDrawingOptions {
  showCircleScale: boolean;
  showCircleMeasurement: boolean;
  showUnmarked: boolean;
  showMarked: boolean;
}

export interface ISaveMeasurementsPayload {
  stationId: string;
  body: ISaveMeasurementsRequest;
}

export interface IClearMeasurementsPayload {
  stationId: string;
  body: IClearMeasurementsRequest;
}

export interface IDeleteImageRequest {
  stationId: string;
  cam_id: number;
  pic_id: number;
}

export interface IDeleteImagePayload {
  deleteRequest: IDeleteImageRequest;
  refreshRequest: IGetPhotosRequests;
}

export interface IPictureImageQuality {
  label: string;
  probability: number;
}

export interface IPicture {
  cam_id: number;
  filename: string;
  nm: string; // station id
  pic_id: number;
  image_quality: IPictureImageQuality;
  rectangles: Array<IRectangle>;
  filteredRectangles?: Array<IRectangle>;
  thumb: string;
  time: Date;
  url: string;
  urlPNG: string;
}

export interface ICameraRefresh {
  scope: string;
  value: string | IFromTo;
}

export interface IToolbarSettings {
  settings: {
    periodScope: string,
    fromTo: IFromTo,
    datepicker: string,
    stationId: string,
    isLastDataMode: boolean
  };
  activity: {
    areMeasurementsActive: boolean;
    isHelpActive: boolean;
    isSaveInfoActive?: boolean;
    isUpdateInfoActive?: boolean;
  };
}

export interface ISaveCameraDistance {
  stationId: string;
  body: {
    distance: number
  };
}

export interface IPictureSet {
  camId: number;
  pictures: Array<IPicture>;
}

export interface IBaseCameraState {
  pictures: {
    [camId: number]: Array<IPicture>
  };
  isLoading: boolean;
  isError: boolean;
  firstDate: moment.Moment;
  lastDate: moment.Moment;
}

export interface IBaseCameraSettingsState {
  selectedPicture: IPicture;
  currentDateString: string | IFromTo;
  toolbarSettings: IToolbarSettings;
  cameraDistance?: number;
  drawingOptions?: IDrawingOptions;
}

export type CameraPestType = 'station' | 'user' | 'general';

export interface IGetCameraPestRequest {
  stationId: string;
  type: CameraPestType;
}

export interface IPostCameraPestBody {
  _id?: string;
  name: string;
  common_name: string;
  genus_id: string;
  color: string;
}

export interface IDeleteCameraPestBody {
  pest_id: string;
}

export interface IDeleteGlueBoardBody {
  station_id: string;
  glue_board_id: string;
}

export interface IDeleteIscoutSeasonBody {
  station_id: string;
  season_id: string;
}

export interface IPostCameraPestPayload {
  stationId: string;
  body: IPostCameraPestBody | IDeleteCameraPestBody;
}

export interface ICameraPestOrder {
  _id: string;
  name: string;
  common_name?: string;
}

export interface ICameraPestFamily {
  _id: string;
  name: string;
  order: ICameraPestOrder;
}

export interface ICameraPestGenus {
  _id: string;
  name: string;
  order: ICameraPestOrder;
  family: ICameraPestFamily;
}

export interface ICameraPest {
  _id?: string;
  type?: string;
  common_name: string;
  name: string;
  picture: string;
  order: ICameraPestOrder;
  family: ICameraPestFamily;
  genus: ICameraPestGenus;
  color: string;
}

export interface ICameraPestMap {
  [group: string]: Array<ICameraPest>;
}

export interface IPestToggler {
  ref: string;
  type: string;
  name: string;
  amount?: number;
  is_active: boolean;
  disabled?: boolean;
}

export interface IPestTogglerMap {
  [_id: string]: IPestToggler;
}

export interface IFromTo {
  from: number;
  to: number;
}
