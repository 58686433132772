import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../../../services/auth/auth.service';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActivateUserGuard implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

    const token = route.queryParams['token'];

    return this.auth.activateAccount(token).pipe(
      map(response => {
        this.router.navigate(['/auth/login', { message: 'Account has been successfully activated' }]);

        return false;
      }),
      catchError(response => {
        this.router.navigate(['/auth/login', { message: response.error.message }]);

        return of(false);
      })
    );
  }
}
