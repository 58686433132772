<section class="user-menu">
  <div class="user-menu__header">
    <div class="user-menu__image">
      <img src="../../../../../../assets/img/avt-2.jpg">
    </div>
    <p class="user-menu__name">{{ firstName$ | async }} {{ lastName$ | async }}</p>
    <p class="user-menu__email">{{ email$ | async }}</p>
    <p class="user-menu__username">{{ userName$ | async }}</p>
  </div>
  <hr>
  <ng-container *ngIf="userMenu">
    <a (click)="openAddRemoveModalEmit()"><i class="zmdi zmdi-plus-circle"></i>{{ 'Add/Remove station' | translate }}</a>
    <a [routerLink]="['/product-activation']"><i class="zmdi zmdi-key"></i>{{ 'Product Activation' | translate }}</a>
    <a [routerLink]="['/user-licenses']"><i class="zmdi zmdi-file"></i>{{ 'User Licenses' | translate }}</a>
    <a *ngIf="!themeConfig?.hideUserMenu?.dataUpload"
       [routerLink]="['/station/data-upload']"><i class="zmdi zmdi-upload"></i>{{ 'Data upload' | translate }}</a>
    <a [routerLink]="['/user-settings']"><i class="zmdi zmdi-settings"></i>{{ 'User Settings' | translate }}</a>
    <a *ngIf="!themeConfig?.hideUserMenu?.apiServices"
       [routerLink]="['/user-api-services']"><i class="zmdi zmdi-layers"></i>{{ 'API services' | translate }}
    </a>
    <a *ngIf="!themeConfig?.hideUserMenu?.feedbackAndSupport"
       [routerLink]="['/user-support']"><i class='zmdi zmdi-email'></i>{{ 'Feedback and Support' | translate }}</a>
    <a *ngIf="!themeConfig?.hideUserMenu?.help"
       href="http://metos.at/fieldclimate-manual/"
       target="_blank"><i class="zmdi zmdi-help"></i>{{ 'Help' | translate }}
    </a>
    <a [href]=termsHref target="_blank"><i class="zmdi zmdi-shield-security"></i>{{ 'Privacy and Terms' | translate }}</a>
    <hr>
  </ng-container>
  <a (click)="logout()"><i class="zmdi zmdi-power"></i>{{ 'Sign Out' | translate }}</a>
</section>
