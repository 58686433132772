<div class="revoke-access-modal">

  <div class="revoke-access-modal__header">
    {{ 'Remove or Keep JD Assets' | translate }}
  </div>

  <div class="revoke-access-modal__body">

    <p *ngIf="assets && !removing; else loading">
      {{ 'If you revoke access in order to grant access again afterwards with a different JD user account
        that has access to the assets\' organizations you may want to keep your assets.
        Otherwise all assets will be removed from MyJohnDeere before removing your access token.' | translate}}
      <br/>
      <br/>
      {{ 'Remove' | translate }} {{ assets.length }} {{ 'assets' | translate }}
      {{ 'from' | translate }} {{ noOrganizations }} {{ 'MyJohnDeere organizations' | translate }}?
    </p>
    <ng-template #loading>
      <p *ngIf="!removing">{{ '.. loading ..' | translate }}</p>
      <p *ngIf="removing && currentAsset">{{ 'Removing' | translate }} {{ currentAsset.station }} ({{ removed + 1 }}/{{ assets.length }}) {{ 'from' | translate }} {{ currentAsset.organization }} ..</p>
    </ng-template>

  </div>

  <hr>

  <div class="revoke-access-modal__footer">

    <app-custom-button
      *ngIf="removing; else other_buttons"
      (click)="abort()"
      [color]="'blue'"
      [content]="'Abort' | translate">
    </app-custom-button>
    <ng-template #other_buttons>
      <app-custom-button
        [disabled]="!assets"
        (click)="remove()"
        [color]="'red'"
        [content]="'Revoke & Remove JD Assets' | translate">
      </app-custom-button>
      <app-custom-button
        [disabled]="!assets"
        (click)="keep()"
        [color]="'red'"
        [content]="'Revoke & Keep JD Assets' | translate">
      </app-custom-button>
      <app-custom-button
        (click)="close()"
        [color]="'blue'"
        [content]="'Cancel' | translate">
      </app-custom-button>
    </ng-template>

  </div>

</div>
