import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IApiAccess } from '../../../../core/models/account';
import { selectApiAccess } from '../../../../core/reducers';
import * as fromAccount from '../../../../core/reducers/account';
import { cardAnimation } from '../../../../shared/animations/card-animations';

import { getNewApiKeys } from '../../../../core/actions/account';
import { setNotify } from '../../../../core/actions/notify';
import * as fromNotify from '../../../../core/reducers/notify';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { generateId } from '../../../dashboard/utils/makeWidget';

@Component({
  selector: 'app-api-key',
  templateUrl: './api-key.component.html',
  styleUrls: ['./api-key.component.scss'],
  animations: [cardAnimation()]
})
export class ApiKeyComponent implements OnInit {

  public apiAccess$: Observable<IApiAccess>;
  public modalId: string = generateId();

  constructor(
    private accountStore: Store<fromAccount.IAccount>,
    private notifyStore: Store<fromNotify.INotifyState>,
    private modal: ModalService
  ) { }

  public ngOnInit(): void {
    this.apiAccess$ = this.accountStore.pipe(select(selectApiAccess));
  }

  public generateNewKeys(): void {
    this.accountStore.dispatch(getNewApiKeys());
  }

  public toClipboard(element: any, key: string): void {
    element.select();
    document.execCommand('copy');
    this.notifyStore.dispatch(setNotify('Key was copied to clipboard successfully'));
  }

  public openModal(): void {
    this.modal.openModal(this.modalId);
  }

}
