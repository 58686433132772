<app-tree [tree$]="tree$"></app-tree>
<div class="body-view-content">
  <app-station-page-header [pageName]="'Rain Corrector'"></app-station-page-header>
    <section class="flex-reverse space-between">
      <section>
        <i class="zmdi zmdi-help" (click)="openModal()"></i>
      </section>
      <section *ngIf="cropzone" class="alert alert-info flex-row-direction">
        <i class="icon-fc-water-level" style="font-size: 20px;"></i>
        <section class="link-text" (click)="routeIrrimet()">
          {{ 'Back to Irrimet.' | translate }}</section>
        <p>&nbsp;{{ 'A link to get back to Irrimet data.' | translate }}</p>
      </section>
    </section>
  <div class="form-container" *ngIf="navigationStation">
    <div *ngIf="canDelete(); else warning">
      <app-precipitation-data-table></app-precipitation-data-table>
    </div>

    <ng-template #warning>
      <div class="warning">
        {{ 'You have read-only rights to this station, therefore you cannot update the settings.' | translate }}
      </div>
    </ng-template>
  </div>
</div>

<app-info-modal [modalId]="RAIN_CORRECTOR_HELP_MODAL_ID" [header]="'Correct Precipitation Data' | translate">
  <app-rain-corrector-help-modal></app-rain-corrector-help-modal>
</app-info-modal>