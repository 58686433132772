import { EffectsModule } from '@ngrx/effects';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { IrrimetConfigComponent } from './irrimet-config.component';
import { IrrimetConfigRoutingModule } from './irrimet-config-routing.module';
import { IrrimetDataComponent } from './containers/irrimet-data/irrimet-data.component';
import { IrrimetConfigChartComponent } from './containers/irrimet-config-chart/irrimet-config-chart.component';
import { reducers } from './reducers';
import { StoreModule } from '@ngrx/store';
import { IrrimetConfigEffect } from './effects/irrimet-config.effect';
import { RootzoneSettingsComponent } from './containers/rootzone-settings/rootzone-settings.component';
import { SoilProfileTableComponent } from './containers/soil-profile-table/soil-profile-table.component';
import { TranslateModule } from '@ngx-translate/core';
import { IrrimetConfigFormHelpTextModalComponent } from './containers/irrimet-config-form-help-text-modal/irrimet-config-form-help-text-modal/irrimet-config-form-help-text-modal.component';
import { IrrimetConfigChartHelpTextModalComponent } from './containers/irrimet-config-chart-help-text-modal/irrimet-config-chart-help-text-modal/irrimet-config-chart-help-text-modal.component';
import { RootzoneSettingsHelpTextModalComponent } from './containers/rootzone-settings-help-text-modal/rootzone-settings-help-text-modal/rootzone-settings-help-text-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    IrrimetConfigRoutingModule,
    TranslateModule.forChild(),
    StoreModule.forFeature('irrimet-config', reducers),
    EffectsModule.forFeature([IrrimetConfigEffect])
  ],
  declarations: [
    IrrimetConfigComponent,
    IrrimetDataComponent,
    IrrimetConfigChartComponent,
    RootzoneSettingsComponent,
    SoilProfileTableComponent,
    IrrimetConfigFormHelpTextModalComponent,
    IrrimetConfigChartHelpTextModalComponent,
    RootzoneSettingsHelpTextModalComponent
  ]
})
export class IrrimetConfigModule {
}
