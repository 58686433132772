import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomCheckboxComponent),
    multi: true
  }]
})
export class CustomCheckboxComponent implements ControlValueAccessor {
  private _value                                  : boolean = false;

  private propagateChange: any = () => { return; };

  @Input()
  public get value(): boolean {
    return this._value;
  }

  public set value(value: boolean) {
    this._value = value;
  }

  public onChange(): void {
    this.value = !this.value;
    this.propagateChange(this.value);
  }

  public writeValue(value: boolean): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    return;
  }

  public setDisabledState(isDisabled: boolean): void {
    return;
  }

}
