import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { setNotify } from '../../../../../core/actions/notify';
import { INotifyState } from '../../../../../core/reducers/notify';
import { getIscoutSeasonsAvailableGlueBoards, unselectIscoutSeason } from '../../../actions/iscout-seasons';
import {
  IIscoutGlueBoard,
  IIscoutSeason,
  IIscoutSeasonFormAction,
  IIscoutSeasonState,
  IscoutFormActionType
} from '../../../models/iscout.models';
import { selectIscoutSeasonAvailableGlueBoards } from '../../../reducers';

@Component({
  selector: 'app-iscout-season-form',
  templateUrl: './iscout-season-form.component.html',
  styleUrls: ['./iscout-season-form.component.scss'],
})
export class IscoutSeasonFormComponent implements OnInit, OnDestroy {
  @Input()
  public stationId: string;
  @Input()
  public formAction: IIscoutSeasonFormAction;

  @Output()
  public seasonSaved: EventEmitter<IIscoutSeason> = new EventEmitter<IIscoutSeason>();
  @Output()
  public seasonUpdated: EventEmitter<IIscoutSeason> = new EventEmitter<IIscoutSeason>();

  public availableGlueBoards: Array<IIscoutGlueBoard> = [];
  public form: FormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private iscoutSeasonStore: Store<IIscoutSeasonState>,
              private notifyStore: Store<INotifyState>,
              private fb: FormBuilder) {
  }

  public ngOnInit(): void {
    this.iscoutSeasonStore.pipe(
      takeUntil(this.destroy$),
      select(selectIscoutSeasonAvailableGlueBoards)
    ).subscribe(
      (glueBoards) => this.availableGlueBoards = glueBoards
    );
    this.iscoutSeasonStore.dispatch(getIscoutSeasonsAvailableGlueBoards(this.stationId));
    this.initializeFormValues();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initializeFormValues(): void {
    if (this.formAction.action === IscoutFormActionType.UPDATE) {
      this.form = this.fb.group({
        'label': [this.formAction.season.label, [Validators.required]],
        'detections': this.fb.array(this.formAction.season.detections.map((detection) => ({
          position: detection.position,
          _id: detection.glue_board_id,
          from: detection.from,
          to: detection.to
        }))),
      });
    } else {
      this.form = this.fb.group({
        'label': ['', [Validators.required]],
        'detections': this.fb.array([]),
      });
    }
  }

  public get seasonLabel(): AbstractControl {
    return this.form.get('label');
  }

  public get seasonDetections(): FormArray {
    return this.form.controls['detections'] as FormArray;
  }

  public set seasonDetections(detections: FormArray) {
    this.form.setControl('detections', detections);
  }

  public handleAddGlueBoardToSeason(glueBoard: IIscoutGlueBoard): void {
    this.availableGlueBoards = this.availableGlueBoards
      .filter((g) => g._id !== glueBoard._id)
      .sort(this.sortByFromDate);

    const newValues = [...this.seasonDetections.getRawValue(), glueBoard]
      .sort(this.sortByFromDate)
      .map((gb, position) => ({...gb, position}));
    this.seasonDetections = this.fb.array(newValues);
  }

  public handleRemoveGlueBoardFromSeason(glueBoard: IIscoutGlueBoard): void {
    this.availableGlueBoards = [...this.availableGlueBoards, glueBoard].sort(this.sortByFromDate);

    const newValues = this.seasonDetections.getRawValue()
      .filter((gb) => gb._id !== glueBoard._id)
      .sort(this.sortByFromDate)
      .map((gb, position) => ({...gb, position}));
    this.seasonDetections = this.fb.array(newValues);
  }

  public renderDateTime(dateFormat?: string | Date): string {
    if (!dateFormat) {
      return moment().format('YYYY-MM-DD HH:mm');
    }

    if (typeof dateFormat === 'string') {
      return moment(dateFormat).format('YYYY-MM-DD HH:mm');
    }

    return moment(dateFormat.getTime()).format('YYYY-MM-DD HH:mm');
  }

  public submitSeason(): void {
    const season: IIscoutSeason = {
      nm: this.stationId,
      label: this.seasonLabel.value,
      detections: this.seasonDetections.getRawValue().map((gb) => ({
        position: gb.position,
        glue_board_id: gb._id,
      })),
    };

    if (season.detections.length === 0) {
      this.notifyStore.dispatch(setNotify('Please, select at least one glue board'));
      return;
    }

    if (this.formAction.action === IscoutFormActionType.CREATE) {
      this.seasonSaved.emit(season);
    } else {
      this.seasonUpdated.emit({...season, _id: this.formAction.season._id});
    }
  }

  public unselectSeason(): void {
    this.iscoutSeasonStore.dispatch(unselectIscoutSeason());
  }

  public isCreationForm(): boolean {
    return this.formAction.action === IscoutFormActionType.CREATE;
  }

  private sortByFromDate(g1: IIscoutGlueBoard, g2: IIscoutGlueBoard): number {
    if (g1.from === g2.from) {
      return 0;
    }

    if (g1.from < g2.from) {
      return -1;
    }

    return 1;
  }
}
