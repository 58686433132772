import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith, takeUntil, tap, distinctUntilChanged } from 'rxjs/operators';
import { updateShowFinishedCropzones } from '../../core/actions/account';
import { removeFarm } from '../../core/actions/farms';
import { ICropZone, IFarm } from '../../core/models/cropzones';
import { selectFarms, selectShowFinishedCropzones } from '../../core/reducers';
import { IAccount } from '../../core/reducers/account';
import * as fromCropzones from '../../core/reducers/cropzones';
import * as fromFarms from '../../core/reducers/farms';
import * as fromFields from '../../core/reducers/fields';
import { removeCropzone } from './../../core/actions/cropzones';
import { removeFarmWithoutCropzones } from './../../core/actions/farms';
import { removeField, removeFieldWithoutCropzones } from './../../core/actions/fields';
import { IField } from './../../core/models/fields';
import { selectCropzones, selectFields } from './../../core/reducers/index';
import { NavigationService } from './../../core/services/navigation/navigation.service';
import { ModalService } from './../../shared/modal/services/modal.service';
import {
  saveCropzoneName,
  saveFarmName,
  saveFieldName,
  updateItemToEdit,
  updateManagementSort,
  updateManagementConfig,
  setManagementState
} from './actions/cropzoneManagement';
import * as fromCropzoneManagement from './reducers/cropzoneManagement';
import { deepClone } from './../../shared/utils/deepClone';
import { CropzoneManagementService } from './services/cropzone-management.service';
import {
  ADD_CROPZONE_MODAL_ID,
  defaultManagementSort,
  managementHeaders,
  managementInitialState,
  REMOVE_CROPZONE_CONFIRMATION_ID,
  REMOVE_FARM_CONFIRMATION_ID,
  REMOVE_FIELD_CONFIRMATION_ID,
  RENEW_CROPZONES_MODAL_ID
} from './constants';

@Component({
  selector: 'app-cropzone-management',
  templateUrl: './cropzone-management.component.html',
  styleUrls: ['./cropzone-management.component.scss']
})
export class CropzoneManagementComponent implements OnInit, OnDestroy {
  public farms$: Observable<IFarm[]>;
  public fields$: Observable<IField[]>;
  public cropzones$: Observable<ICropZone[]>;
  private cropzoneList: ICropZone[];

  public management$: Observable<any>;

  public management: any;

  public addCropZoneModalId: string = ADD_CROPZONE_MODAL_ID;
  public renewCropzonesId: string = RENEW_CROPZONES_MODAL_ID;
  public REMOVE_FARM_CONFIRMATION: string = REMOVE_FARM_CONFIRMATION_ID;
  public REMOVE_FIELD_CONFIRMATION: string = REMOVE_FIELD_CONFIRMATION_ID;
  public REMOVE_CROPZONE_CONFIRMATION: string = REMOVE_CROPZONE_CONFIRMATION_ID;

  public farmToDelete: any;
  public fieldToDelete: any;
  public cropzoneToDelete: any;

  private deleteFieldBoolean: boolean;
  private deleteFarmBoolean: boolean;

  public itemToEdit: any;
  public isEditEnabled: boolean = false;

  public searchTermControl: FormControl = new FormControl('');
  public itemToEditFormControl: FormControl = new FormControl(null);

  public focus$ = new BehaviorSubject(false);

  public myForm: FormGroup;
  public selectContent: string = 'select all';
  private sortObjects: fromCropzoneManagement.ICropzoneManagementSort[];
  public cropzonesToRenew: any[];
  public isFinishedCropzonesShown: boolean;

  public ascendingIcon: string = 'ag-icon ag-icon-asc';
  public descendingIcon: string = 'ag-icon ag-icon-desc';
  public noneIcon: string = 'ag-icon ag-icon-none';

  public headers = managementHeaders;
  public defaultHeaders = managementHeaders;
  private defaultSort: fromCropzoneManagement.ICropzoneManagementSort[] = defaultManagementSort;
  public eligibleClass: string = '';
  private sorting: fromCropzoneManagement.ICropzoneManagementSort[];
  public searchString: string;
  public showFinished$: Observable<boolean>;
  public showTooltip: boolean = false;
  public hoveredIndex = null;
  public hoveredField = null;

  private destroy$: Subject<boolean> = new Subject();
  constructor(
    private farmStore: Store<fromFarms.IFarms>,
    private fieldStore: Store<fromFields.IFields>,
    private cropzoneStore: Store<fromCropzones.ICropZones>,
    private cropzoneManagementStore: Store<fromCropzoneManagement.ICropzoneManagementState>,
    private accountStore: Store<IAccount>,
    private navigationService: NavigationService,
    private modalService: ModalService,
    private fb: FormBuilder,
    private cropzoneManagementService: CropzoneManagementService
  ) { }

  public ngOnInit(): void {
    this.cropzoneManagementStore.dispatch(setManagementState(managementInitialState));
    this.myForm = this.fb.group({
      selectedCropzones: new FormArray([])
    });

    this.farms$ = this.farmStore.pipe(
      select(selectFarms),
      takeUntil(this.destroy$),
      filter(farms => farms.length > 0)
    );

    this.fields$ = this.fieldStore.pipe(
      select(selectFields),
      takeUntil(this.destroy$),
      filter(fields => fields.length > 0)
    );

    this.cropzones$ = this.cropzoneStore.pipe(
      select(selectCropzones),
      takeUntil(this.destroy$),
      filter((czs) => !!czs && czs.length > 0),
      tap((cropzones: ICropZone[]) => {
        this.cropzoneList = cropzones;
      }),
    );

    this.showFinished$ = this.accountStore.pipe(
      select(selectShowFinishedCropzones),
      takeUntil(this.destroy$),
      distinctUntilChanged((a, b) => a === b),
      tap((isFinished) => this.isFinishedCropzonesShown = isFinished)
    );

    this.management$ = combineLatest([
      this.farms$,
      this.fields$,
      this.cropzones$,
      this.searchTermControl.valueChanges.pipe(
        takeUntil(this.destroy$),
        debounceTime(400),
        startWith('')
      ),
      this.cropzoneManagementStore.pipe(
        select(fromCropzoneManagement.selectItemToEdit),
        takeUntil(this.destroy$)
      ),
      this.cropzoneManagementStore.pipe(
        select(fromCropzoneManagement.selectManagementSort),
        startWith(this.defaultSort),
        takeUntil(this.destroy$)
      ),
      this.showFinished$
    ]).pipe(
      takeUntil(this.destroy$),
      map(([farms, fields, cropzones, search, itemToEdit, sort, showFinished]: [
        any[], IField[], ICropZone[], string, any, any, boolean
      ]) => {
        return farms.map((farm) => {
          this.searchString = search;
          this.sorting = sort;

          const fieldsOfFarm: IField[] = fields.filter((field) => field.farm.id === farm.id);
          let includesActiveCropzone: boolean = false;

          const fieldsWithCropzones = fieldsOfFarm.map(field => {
              const enabled: boolean = itemToEdit && itemToEdit.field && field.id === itemToEdit.field.id ? true : false;
              let cropzoneEnabled: boolean = false;
              const filteredCropzones: ICropZone[] = cropzones.filter(cropzone => cropzone.field.id === field.id);
              let mappedCropzones: ICropZone[] = filteredCropzones.map((cropzone) => {
                cropzoneEnabled = itemToEdit && cropzone.id === itemToEdit.id ? true : false;
                includesActiveCropzone = cropzoneEnabled ? true : includesActiveCropzone;
                cropzone = this.cropzoneManagementService.formatDates(cropzone);

                return { ...cropzone, isEditEnabled: cropzoneEnabled };
              });

              if (!showFinished) {
                mappedCropzones = mappedCropzones.filter((cropzone) => moment(cropzone.to, 'DD-MM-YYYY').clone().isAfter(moment()));
              }

              return {
                field: { ...field, isEditEnabled: includesActiveCropzone ? false : enabled },
                cropzones: mappedCropzones
              };
            }
          );

          const farmEditEnabled: boolean = itemToEdit && itemToEdit.farm && itemToEdit.farm.id === farm.id ? true : false;

          return {
            farm: { ...farm, isEditEnabled: includesActiveCropzone ? false : farmEditEnabled },
            fields: fieldsWithCropzones
          };
        });
      }),
      map((management) => {
        return this.sorting ? this.cropzoneManagementService.sortManagementList(management, this.sorting) : management;
      }),
      map((management) => {
        const returnManagement = this.searchString !== '' ? this.filterArray(management, this.searchString) : management;
        this.eligibleClass = this.cropzoneManagementService.getEligibleClass(returnManagement);
        this.headers = this.eligibleClass === '' ? this.defaultHeaders : deepClone(this.defaultHeaders).slice(0, -1);
        return returnManagement;
      })
    );
  }

  private filterArray(items: any[], searchTerm: string): any[] {
    const preparedSearchTerm = this.prepareStringForSearch(searchTerm);
    const filteredFarms = items.filter((item: any): boolean => {
      const preparedFarmName = this.prepareStringForSearch(item.farm.name);

      let fieldCropzoneBoolean = false;
      item.fields.filter(field => {
        const preparedField = this.prepareStringForSearch(field.field.name);
        if (preparedField.includes(preparedSearchTerm)) {
          fieldCropzoneBoolean = true;
        }

        field.cropzones.forEach(cropzone => {
          const preparedCropzone = this.prepareStringForSearch(cropzone.name);
          if (preparedCropzone.includes(preparedSearchTerm)) {
            fieldCropzoneBoolean = true;
          }
        });
      });

      return preparedFarmName.includes(preparedSearchTerm) || fieldCropzoneBoolean;
    });

    return filteredFarms;
  }

  private prepareStringForSearch(initString: string): string {
    return initString.toLowerCase().split(' ').join('');
  }

  public openRemoveFarmConfirmationModal(farmItem): void {
    this.farmToDelete = farmItem.farm;
    this.modalService.openModal(this.REMOVE_FARM_CONFIRMATION);
  }

  public openRemoveCropzoneConfirmationModal(cropzoneItem): void {
    this.cropzoneToDelete = cropzoneItem;
    this.modalService.openModal(this.REMOVE_CROPZONE_CONFIRMATION);
  }

  public openAddCropzoneFieldFarmModal(): void {
    this.modalService.openModal(this.addCropZoneModalId);
  }

  public openRemoveFieldConfirmationModal(fieldItem): void {
    this.fieldToDelete = fieldItem.field;
    this.modalService.openModal(this.REMOVE_FIELD_CONFIRMATION);
  }

  public deleteFarm(): void {
    this.deleteFarmBoolean = true;

    this.cropzones$.pipe(
      map(cropzones => cropzones.filter(cropzone => cropzone.farm.id === this.farmToDelete.id))
    ).subscribe((cropzones) => {
      if (this.deleteFarmBoolean) {
        if (cropzones.length > 0) {
          this.farmStore.dispatch(removeFarm(this.farmToDelete, cropzones));
          this.modalService.closeModal(this.REMOVE_FARM_CONFIRMATION);
        } else {
          this.farmStore.dispatch(removeFarmWithoutCropzones(this.farmToDelete));
          this.modalService.closeModal(this.REMOVE_FARM_CONFIRMATION);
        }

        this.deleteFarmBoolean = false;
      }
    });
  }

  public deleteField(): void {
    this.deleteFieldBoolean = true;

    this.cropzones$.pipe(
      map(cropzones => cropzones.filter(cropzone => cropzone.field.id === this.fieldToDelete.id))
    ).subscribe((cropzones) => {
      if (this.deleteFieldBoolean) {
        if (cropzones.length > 0) {
          this.fieldStore.dispatch(removeField(this.fieldToDelete, cropzones));
          this.modalService.closeModal(this.REMOVE_FIELD_CONFIRMATION);
        } else {
          this.fieldStore.dispatch(removeFieldWithoutCropzones(this.fieldToDelete));
          this.modalService.closeModal(this.REMOVE_FIELD_CONFIRMATION);
        }

        this.deleteFieldBoolean = false;
      }
    });
  }

  public deleteCropzone(cropzoneItem): void {
    this.cropzoneStore.dispatch(removeCropzone(cropzoneItem));
    this.modalService.closeModal(this.REMOVE_CROPZONE_CONFIRMATION);
  }

  public closeRemoveConfirmationModal(modalId): void {
    this.modalService.closeModal(modalId);
  }

  public closeModal(event): void {
    this.modalService.closeModal(this.addCropZoneModalId);
  }

  public sort(item: string): void {
    if (this.sorting && this.sorting.length > 0) {
      this.sorting = this.sorting.map((object, index) => {
        if (item === 'CROPZONE') {
          if (object.sortingItem === 'CROP' || object.sortingItem === 'FROM' || object.sortingItem === 'TO') {
            object.sortingOrder = 'none';
          }
        } else if (item === 'CROP') {
          if (object.sortingItem === 'CROPZONE' || object.sortingItem === 'FROM' || object.sortingItem === 'TO') {
            object.sortingOrder = 'none';
          }
        } else if (item === 'FROM') {
          if (object.sortingItem === 'CROPZONE' || object.sortingItem === 'CROP' || object.sortingItem === 'TO') {
            object.sortingOrder = 'none';
          }
        } else if (item === 'TO') {
          if (object.sortingItem === 'CROPZONE' || object.sortingItem === 'CROP' || object.sortingItem === 'FROM') {
            object.sortingOrder = 'none';
          }
        }

        if (object.sortingItem === item) {
          if (object.sortingOrder === 'ascending' || object.sortingOrder === 'descending') {
            object.sortingOrder === 'ascending' ? this.sorting[index].sortingOrder = 'descending'
            : this.sorting[index].sortingOrder = 'ascending';
          } else if (object.sortingOrder === 'none') {
            object.sortingOrder = 'ascending';
          }
        }
        return object;
      });

      this.cropzoneManagementStore.dispatch(updateManagementSort(this.sorting));
    }
  }

  public openTest(event): void {
    if (event.hasOwnProperty('farm') && event.hasOwnProperty('fields')) {
      this.itemToEditFormControl.setValue(event.farm.name);
    } else if (event.hasOwnProperty('field') && event.hasOwnProperty('cropzones')) {
      this.itemToEditFormControl.setValue(event.field.name);
    } else if (event.hasOwnProperty('from') && event.hasOwnProperty('boundary')) {
      this.itemToEditFormControl.setValue(event.name);
    }

    this.cropzoneManagementStore.dispatch(updateItemToEdit(event));
  }

  public submitFarmName(event): void {
    const farm: IFarm = { name: this.itemToEditFormControl.value, id: event.farm.id };
    this.cropzoneManagementStore.dispatch(saveFarmName(farm));
    this.cropzoneManagementStore.dispatch(updateItemToEdit(null));
  }

  public submitFieldName(event): void {
    const field: IField = { name: this.itemToEditFormControl.value, id: event.field.id, farm: event.field.farm };
    this.cropzoneManagementStore.dispatch(saveFieldName(field));
    this.cropzoneManagementStore.dispatch(updateItemToEdit(null));
  }

  public submitCropzoneName(event): void {
    const { isEditEnabled, aboutToExpire, daysUntilExpired, isActiveLicense,  ...cropzone } = event;
    cropzone.name = this.itemToEditFormControl.value;

    this.cropzoneManagementStore.dispatch(saveCropzoneName(cropzone));
    this.cropzoneManagementStore.dispatch(updateItemToEdit(null));
  }

  public toggleShowFinishedCropzones(): void {
    this.isFinishedCropzonesShown = !this.isFinishedCropzonesShown;
    localStorage.setItem('showFinishedCropzones', JSON.stringify(this.isFinishedCropzonesShown));

    const checkBoxes = document.querySelectorAll('.checkbox');
    checkBoxes.forEach((ele: any) => {
      if (ele.checked) {
        ele.click();
      }
    });

    this.accountStore.dispatch(updateShowFinishedCropzones(this.isFinishedCropzonesShown));
  }

  public back(): void {
    this.cropzoneManagementStore.dispatch(updateItemToEdit(null));
  }

  public goToCropzoneConfig(cropzoneItem): void {
    this.navigationService.changeCropzone(cropzoneItem.id);
  }

  public onCheckChange(event): void {
    const selectedCropzoneIDs: FormArray = this.myForm.get('selectedCropzones') as FormArray;

    if (event.target.checked) {
      selectedCropzoneIDs.push(new FormControl(event.target.value));
    } else {
      let i: number = 0;

      selectedCropzoneIDs.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value === event.target.value) {
          selectedCropzoneIDs.removeAt(i);
          return;
        }
        i++;
      });
    }

    const checkBoxes = document.querySelectorAll('.checkbox');
    let allSelected = true;

    checkBoxes.forEach((box: any) => {
      if (!box.checked) {
        allSelected = false;
      }
    });

    this.selectContent = allSelected ? 'deselect all' : 'select all';
  }

  public renewCropzones(): void {
    const selectedCropzoneIDs: FormArray = this.myForm.get('selectedCropzones') as FormArray;

    const cropzoneIDs: string[] = selectedCropzoneIDs.value;
    const cropzones = this.cropzoneList.filter((cropzone) => cropzoneIDs.includes(cropzone.id));

    this.cropzonesToRenew = cropzones;

    this.modalService.openModal(this.renewCropzonesId);

    const checkBoxes = document.querySelectorAll('.checkbox');
    checkBoxes.forEach((ele: any) => {
        if (ele.checked === true) {
          ele.click();
        }
    });

    this.cropzoneManagementStore.dispatch(updateManagementConfig({
      licenseModalActive: true,
      duplicationModalActive: false,
      cropTypeModalActive: false
    }));
  }

  public cancelRenewCropzones(): void {
    this.modalService.closeModal(this.renewCropzonesId);
  }

  public cancelSelect(): void {
    const checkBoxes = document.querySelectorAll('.checkbox');

    checkBoxes.forEach((ele: any) => {
      if (ele.checked === true) {
        ele.click();
      }
    });
  }

  public selectAll(selectContent: string): void {
    const checkBoxes = document.querySelectorAll('.checkbox');

    checkBoxes.forEach((ele: any) => {
      if (selectContent === 'select all') {
        if (ele.checked === false) {
          ele.click();
        }
        this.selectContent = 'deselect all';
      } else if (selectContent === 'deselect all') {
        if (ele.checked === true) {
          ele.click();
        }
        this.selectContent = 'select all';
      }
    });
  }

  public isRecentlyDuplicated(cropzone: ICropZone): boolean {
    if (cropzone.duplication_date) {
      const diff = moment(cropzone.duplication_date).clone().diff(moment(), 'months', true);
      return  diff <= -1 || diff >= 1 ? false : true;
    } else {
      return false;
    }
  }

  public getIconClass(headerName: string): string {
    let iconClass: string = 'none';
    if (this.sorting) {
      this.sorting.forEach((sort) => {
        if (sort.sortingItem.toUpperCase() === headerName.toUpperCase()) {
          iconClass = sort.sortingOrder;
        }
      });
    }
    return iconClass;
  }

  public isDisabled(): boolean {
    const checkBoxes = document.querySelectorAll('.checkbox');
    let disabled: boolean = true;

    checkBoxes.forEach((box: any) => {
      if (box.checked) {
        disabled = false;
      }
    });

    return disabled;
  }

  public checkIfEligible(): string {
    return '';
  }

  public switchTooltipVisible(): void {
    this.showTooltip = !this.showTooltip;
  }

  public exitHover(): void {
    this.hoveredIndex = null;
    this.hoveredField = null;
  }

  public startHover(fieldItem: IField, index: number): void {
    this.hoveredIndex = index;
    this.hoveredField = fieldItem.id;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
