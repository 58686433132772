<div class="warnings-phone-modal">
  <p>
    {{ 'In this section you can define phone numbers to which you want a warning SMS to be sent. Under Add phone number ' +
    'please input phone number with the country code (00 + country code + telephone number, with no space) and a custom ' +
    'name associated with it. After filling the input boxes click on “Add” button to add the phone number. If you want a ' +
    'warning SMS to be sent to multiple numbers, you can add additional phone numbers to the list.' | translate }}
  </p>

  <p>
    {{ 'Under List of phone numbers you will see all phone numbers and associated names that you have added. If you want ' +
    'you can also set the status of a phone number from active (by default) to not active (with clicking on "yes/no" ' +
    'button). This way you can choose to which phone numbers from the list warning SMS will be sent. By clicking on ' +
    '"Remove" button you can also remove certain phone number from the list.' | translate }}
  </p>

  <p>
    {{ 'When you finish the configuration, do not forget to save the new settings (click on "SAVE PHONE NUMBERS" button).' | translate }}
  </p>
</div>
