<app-form-layout>
  <div class="accumulator-tool">

    <div class="accumulator-tool__body">
      <div [formGroup]="form"
           class="accumulator-tool__form">

        <div class="accumulator-tool__group">
          <label>{{ 'Precipitation sensor selection' | translate }}</label>
          <app-form-select [options]="sensorsOptions"
                           [formControl]="sensor"
                           (change)="onValueChanged()">
          </app-form-select>
        </div>

        <div class="accumulator-tool__group">
          <label>{{ 'Start date' | translate }}</label>
          <app-custom-datepicker [formControl]="from"
                                 [isOnlyFocus]="false"
                                 [hideTime]="true">
          </app-custom-datepicker>

          <label>{{ 'End date' | translate }}</label>
          <app-custom-datepicker [formControl]="to"
                                 [maxDate]="maxDate"
                                 [isOnlyFocus]="false"
                                 [hideTime]="true">
          </app-custom-datepicker>
        </div>
      </div>
      <hr>

      <div class="accumulator-tool__right accumulator-tool__button">
        <div class="accumulator-tool__right__item" *ngIf="!isLoading && response?.type === 'rain'">
          <app-custom-button [color]="'white'"
                             appTooltip
                             [tooltipPlacement]="'top'"
                             [tooltipTitle]="'Export charts' | translate"
                             (click)="onExportChart()">
            <i class="fa fa-download"></i>
          </app-custom-button>
        </div>

        <div class="accumulator-tool__right__item" *ngIf="!isLoading && response?.type === 'rain'">
          <app-custom-button [color]="'white'"
                             appTooltip
                             [tooltipPlacement]="'top'"
                             [tooltipTitle]="'Export table as XLS' | translate"
                             (click)="startExportXLS()">
            <i class="fa fa-table"></i>
          </app-custom-button>
        </div>

        <div class="accumulator-tool__right__item">
          <app-custom-button [color]="isChartActive.value ? 'green' : 'white'"
                             appTooltip
                             [tooltipPlacement]="'top'"
                             [tooltipTitle]="'Toggle charts' | translate"
                             [shadow]="false"
                             (click)="isChartActive.setValue(!isChartActive.value)"
                             [radius]="'2px 0 0 2px'">
            <span class="fa fa-bar-chart-o"></span>
          </app-custom-button>

          <app-custom-button [color]="isTableActive.value ? 'green' : 'white'"
                             appTooltip
                             [tooltipPlacement]="'top'"
                             [tooltipTitle]="'Toggle table' | translate"
                             [shadow]="false"
                             (click)="isTableActive.setValue(!isTableActive.value)"
                             [radius]="'0 2px 2px 0'">
            <span class="fa fa-table"></span>
          </app-custom-button>
        </div>

        <div class="accumulator-tool__right__item">
          <app-custom-button class="accumulator-tool__button"
                         (click)="calculateAccumulation()"
                         [disabled]="isLoading || noSensors"
                         [color]="'red'"
                         [content]="'Calculate'">
          </app-custom-button>
        </div>
      </div>

      <div *ngIf="isLoading"
           class="accumulator-tool__loading">
        {{ 'Loading, please wait ...' | translate }}
      </div>
    </div>
  </div>
</app-form-layout>

<app-form-layout [shadow]="false">
  <div *ngIf="noSensors"
       class="accumulator-tool__no-data">
    <app-station-load-info
      [isError]=true
      [errorText]="'No sensors for calculation'">
    </app-station-load-info>
  </div>
</app-form-layout>

<section *ngIf="!isLoading && response?.type === 'rain'">
  <div class="accumulator-tool__item">
    <app-accumulator-tool-table [response]="response"></app-accumulator-tool-table>
  </div>

  <div class="accumulator-tool__item">
    <app-accumulator-tool-chart *ngIf="isChartActive.value"
                                [type]="response?.type"
                                [chartData]="response.chart"
                                [exportChartImg]="exportChartImg"
                                [chartOptions]="chartOptions">
    </app-accumulator-tool-chart>
  </div>

  <div class="accumulator-tool__item">
    <app-form-layout *ngIf="isTableActive.value && !noData && !isTableHidden">
      <div class="accumulator-data-table">
        <ag-grid-angular class="ag-theme-bootstrap"
          [columnDefs]="columnDefs" [gridOptions]="dataGridOptions">
        </ag-grid-angular>
      </div>
    </app-form-layout>
  </div>
</section>
