<div *ngIf="data; else loading"
     class="forecast-container">
  <img *ngIf="data.length;" [attr.src]="data[0]" class="forecast-img">
</div>

<ng-template #loading>
  <app-widget-info>
    <h4><i class="fa fa-warning fa-fw"></i> {{ 'No images available' | translate}}</h4>
  </app-widget-info>
</ng-template>

