import { IActionWithPayload } from './../../../core/models/actionWithPayload';

export const enum NodesActionTypes {
    GET_NODES = 'GET_NODES',
    SET_NODES = 'SET_NODES',
}

export function getNodes(stationId: string): IActionWithPayload {
    return { type: NodesActionTypes.GET_NODES, payload: stationId };
}

export function setNodes(nodes: {}[], stationId: string): IActionWithPayload {
    return { type: NodesActionTypes.SET_NODES, payload: {nodes: nodes, stationId: stationId }};
}
