<app-form-layout [shadow]="false">
  <div class="yield-prediction-config">
    <section class="content_top yield-prediction-config_header ">
      <section class="flex-row-direction space-between">
        <h2>{{ 'Crop Management' | translate }} </h2>
        <i class="zmdi zmdi-help help-icon" (click)="openModal('cropManagement')"></i>
      </section>
      <h5 class="mini-text mt-2">
        {{ 'Set up the Yield Prediction with your actual crop, sowing date and expected harvest date.' | translate}}
      </h5>
    </section>

    <div [formGroup]="form">
      <section class="row yield-prediction-config_content content_body  mb-8">
        <section class="yield-prediction-config__control col-md-6">
          <label class="yield-prediction-config__label">{{ 'Crop:' | translate}}</label>
          <app-form-select [formControl]="cropTypeControl" [options]="cropTypeOptions" [type]="'border'"
            [size]="'small'" [isDisabled]="expiredLicense"  (change)="changeCropManagementData()"></app-form-select>
        </section>
        <section class="col-md-6">
          <section class="flex-row-direction">
            <section>
              <label class="yield-prediction-config__label date-label">{{ 'Sowing date:' | translate}}</label>
              <app-custom-datepicker [isSmall]="true" [isOnlyFocus]="false" [formControl]="fromDatepicker"
              [isDisabled]="expiredLicense" (change)="changeCropManagementData()"> </app-custom-datepicker>
            </section>
            <section>
              <label class="yield-prediction-config__label date-label">{{ 'Expected harvest date:' | translate}}</label>
              <app-custom-datepicker [isSmall]="true" [maxDate]="maximumDate" [isOnlyFocus]="false"
                [formControl]="toDatepicker" [isDisabled]="expiredLicense" (change)="changeCropManagementData()">
              </app-custom-datepicker>
            </section>
          </section>
        </section>
      </section>

      <div class="break"></div>

      <section class="content yield-prediction-config_content_item">
        <app-yield-slider
          [cropManagementData]="cropManagement" [sliderData]="sliderSettings"
          [isSavingConfig$]="isSavingConfig$" [isDisabled]="expiredLicense"
          (changeSliders)="changeSliders($event)" (clickSave)="saveConfiguration()"></app-yield-slider>
      </section>

      <div class="break"></div>

      <section class="content">
        <app-custom-button [customCSS]="{'max-width': '100%', 'width':'100%'}"
          [color]="showAdvanced ? 'orange' : 'green'" (click)="advancedSettings()"
          [content]="showAdvanced ? 'hide advanced settings' : 'show advanced settings'">
        </app-custom-button>
        <i (click)="openModal('advanced')" class="zmdi zmdi-help help-icon"></i>
        <h5 class="mini-text mt-8">
          {{ 'Define several yield-related parameters, such as initial soil moisture, field capacity and wilting point.'
          | translate}}
        </h5>
        <section *ngIf="showAdvanced" class="row">
          <section class="col-md-6 flex-column-direction yield-prediction-config_content">
            <section class="yield-prediction-config__control yield-prediction-config__label">
              <label *ngIf="actualUnit === 't/ha' else imperialYield">
                {{ 'Best possible average yields (t/ha):' | translate}}
              </label>
              <ng-template #imperialYield>
                <label>
                  {{ 'Best possible average yields (bu/ac):' | translate}}
                </label>
              </ng-template>
              <input type="number" step="0.01" min="0" class="yield-prediction-config__input numberInput"
                [ngStyle]="{'background-color': expiredLicense ? 'rgb(230, 230, 230)' : '' }"
                (change)="validateDecimal($event)" [formControl]="bestPossibleYieldControl">
            </section>
            <section class="yield-prediction-config__control">
              <label class="yield-prediction-config__label">{{ 'Initial soil moisture (at sowing):' |
                translate}}</label>
              <app-form-select [formControl]="initialSoilMoistureControl" [options]="initialSoilMoistureOptions"
                [type]="'border'" [size]="'small'" [isDisabled]="expiredLicense"></app-form-select>
            </section>
            <section class="yield-prediction-config__control">
              <label class="yield-prediction-config__label">{{ 'Soil texture:' | translate}}</label>
              <app-form-select [formControl]="soilTextureControl" [options]="soilTextureOptions" [type]="'border'"
                [size]="'small'" [isDisabled]="expiredLicense"></app-form-select>
            </section>
            <section class="yield-prediction-config__control">
              <label class="yield-prediction-config__label">{{ 'Air temperature source:' | translate}}</label>
              <app-custom-simple-lazy-dropdown (load)="onLoadAirTemperature()"
                [customCSS2]="{'max-width': '100%', 'width':'100%'}"
                [customCSS]="{'max-width': '100%', 'width':'100%',
                            'border': expiredLicense ? '' : '1px solid #909090', 'background': expiredLicense ? 'rgb(230, 230, 230)' : ''}"
                [shadow]="false" color="white" [currentItem]="currentAirItem" [formControl]="airTemperatureSourceControl"
                [items$]="datasourcesAirTemperature$" [isDisabled]="expiredLicense">
              </app-custom-simple-lazy-dropdown>
            </section>
            <section class="yield-prediction-config__control">
              <label class="yield-prediction-config__label">{{ 'Rain source:' | translate}}</label>
              <app-custom-simple-lazy-dropdown (load)="onLoadRain()"
                [customCSS2]="{'max-width': '100%', 'width':'100%'}"
                [customCSS]="{'max-width': '100%', 'width':'100%',
                            'border': expiredLicense ? '' : '1px solid #909090', 'background': expiredLicense ? 'rgb(230, 230, 230)' : ''}"
                [shadow]="false" color="white" [currentItem]="currentRainItem" [formControl]="rainSourceControl"
                [items$]="datasourcesRain$" [isDisabled]="expiredLicense">
              </app-custom-simple-lazy-dropdown>
            </section>
          </section>

          <section class="col-md-6 flex-column-direction yield-prediction-config_content">
            <section *ngIf="yieldPrediction && (yieldPrediction.field_capacity || yieldPrediction.wilting_point)">
              <section class="row">
                <section class="alert alert-info server-alerts-list__alert" style="margin: 0 15px 15px 15px;">
                  {{ 'From your settings, we recommend the following values for field capacity and wilting point.' |
                  translate
                  }}
                  {{ 'Select “Custom” in the “Soil texture” drop down menu if you want to change the values below.' |
                  translate }}
                </section>
              </section>

              <section class="row yield-prediction-config_content">
                <section class="yield-prediction-config__control col-md-3">
                  <label class="yield-prediction-config__label">{{ 'Field Capacity (%)' | translate}}</label>
                  <section class="flex-row-direction">
                    <app-form-input [type]="'border'" [contentType]="'number'" class="yield-prediction-config__input"
                      [formControl]="fieldCapacityControl" [isDisabled]="!isCustomValue()" (change)="onFCWPChange()"
                      [size]="'small'"
                      [ngStyle]="{'background-color': !isCustomValue() ? 'rgb(230, 230, 230)' : '' }">
                    </app-form-input>
                  </section>
                </section>
                <section class="yield-prediction-config__control col-md-3">
                  <label class="yield-prediction-config__label">{{ 'Wilting Point (%)' | translate}}</label>
                  <section class="flex-row-direction">
                    <app-form-input [type]="'border'" [contentType]="'number'" class="yield-prediction-config__input"
                      [formControl]="wiltingPointControl" [isDisabled]="!isCustomValue()" (change)="onFCWPChange()"
                      [size]="'small'"
                      [ngStyle]="{'background-color': !isCustomValue() ? 'rgb(230, 230, 230)' : '' }">
                    </app-form-input>
                  </section>
                </section>
              </section>
            </section>
            <section *ngIf="pawIni !== null" class="row yield-prediction-config_content mt-10">
              <section class="yield-prediction-config__control col-md-8">
                <label class="yield-prediction-config__label mb-0">
                  {{ 'Actual available water at sowing:' | translate }}
                </label>&nbsp;
                <label class="yield-prediction-config__label mb-0" *ngIf="unitSystem === 'metric' else inchUnit">{{
                  pawIni }}&nbsp;{{ 'mm' | translate}}</label>
                <ng-template #inchUnit>{{ pawIni }}&nbsp;{{ 'in' | translate}}</ng-template>
                <section class="small-text">{{ 'Click "Save" to update' | translate }}</section>
              </section>
            </section>
            <section class="yield-prediction-config__control">
              <label class="yield-prediction-config__label">{{ 'Yield unit:' |
                translate}}</label>
              <app-form-select [formControl]="yieldUnitControl" [options]="yieldUnitOptions"
                [type]="'border'" [size]="'small'" [isDisabled]="expiredLicense"></app-form-select>
            </section>
            <section class="flex-reverse-direction mt-8">
              <app-custom-button [color]="'red'" (click)="saveConfiguration()" [disabled]="expiredLicense || (isSavingConfig$ | async)"
                [content]="'Save' | translate">
                <i *ngIf="isSavingConfig$ | async; else loadingIcon" class="fa fa-cog fa-spin fa-fw"></i>
                <ng-template #loadingIcon>
                  <i class="fa fa-fw fa-refresh"></i>
                </ng-template>
              </app-custom-button>
            </section>
          </section>
        </section>
      </section>
    </div>
  </div>
</app-form-layout>

<app-info-modal [modalId]="modalId" [header]="popupTitle()">
  <app-yield-config-help-text-modal [yieldHelpText]="yieldHelpText"></app-yield-config-help-text-modal>
</app-info-modal>