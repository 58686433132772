import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { ICropZone } from '../../../../core/models/cropzones';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { generateId } from '../../../dashboard/utils/makeWidget';


@Component({
  selector: 'app-cropzone-config-location',
  templateUrl: './cropzone-config-location.component.html',
  styleUrls: ['./cropzone-config-location.component.scss']
})
export class CropzoneConfigLocationComponent implements OnInit, OnDestroy {
  @Output()
  public revert = new EventEmitter<any>();

  private alive$ = new Subject<boolean>();
  public selectedCropzone: ICropZone;
  public modalId: string = generateId();
  public coords: any;
  public oldBoundaries: any;
  public button: string;
  public rebound;
  public storeActive;

  constructor(
    private modalService: ModalService
  ) {
  }

  public ngOnInit(): void {
  }

  public openModal(): void {
    this.modalService.openModal(this.modalId);
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }
}
