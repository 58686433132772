<ng-container *ngIf="isOpen">
  <div class="iscout-context-menu"
       [style.left]="positionX"
       [style.top]="positionY">
    <div class="panel panel-primary">
      <div class="panel-heading">
        {{ 'Update detected insect' | translate }}
      </div>
      <div class="list-group">
        <div style="margin: 10px">
          <app-form-input
            [type]="'border'"
            [formControl]="pestFilter"
            [placeholder]="'Filter insects' | translate"
          ></app-form-input>
        </div>

        <ng-container *ngFor="let group of pestList$ | async">
          <div title="{{group.groupName}}" class="list-group-item iscout-context-menu__list-group-header">
            {{ group.groupName }}
          </div>

          <button *ngFor="let pest of group.options"
                  type="button"
                  class="list-group-item"
                  (click)="handleMenuOptionClick(pest)">
            {{ pest.name }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
