import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IFromTo} from '../../../shared/camera/models/camera';
import {ICameraDataSettings, ICameraDataState, ICropViewReportData, IIscoutReportData} from '../models/camera-data';
import * as fromCameraData from '../reducers/camera-data';
import {IIscoutGlueBoard, IIscoutSeason} from '../../iscout/models/iscout.models';

interface IState {
  cameraData: ICameraDataState;
}

export const reducers = {
  cameraData: fromCameraData.reducer,
};

export const cameraDataSelector = createFeatureSelector<IState>('cameraData');

export const selectCameraDataIsChartExportInProgress = createSelector(
  cameraDataSelector,
  (state: IState): boolean => state.cameraData.activity.isChartExportInProgress
);

export const selectCameraDataIsTableExportInProgress = createSelector(
  cameraDataSelector,
  (state: IState): boolean => state.cameraData.activity.isTableExportInProgress
);

export const selectCameraDataIsHelpActive = createSelector(
  cameraDataSelector,
  (state: IState): boolean => state.cameraData.activity.isHelpActive
);

export const selectCameraDataIsChartActive = createSelector(
  cameraDataSelector,
  (state: IState): boolean => state.cameraData.activity.isChartActive
);

export const selectCameraDataIsTableActive = createSelector(
  cameraDataSelector,
  (state: IState): boolean => state.cameraData.activity.isTableActive
);

export const selectCameraDataSettings = createSelector(
  cameraDataSelector,
  (state: IState): ICameraDataSettings => state.cameraData.settings
);

export const selectCameraDataCurrentDateString = createSelector(
  cameraDataSelector,
  (state: IState): string | IFromTo => state.cameraData.currentDateString
);

export const selectCameraDataIscoutReport = createSelector(
  cameraDataSelector,
  (state: IState): Array<IIscoutReportData> => state.cameraData.iscoutReportData
);

export const selectCameraDataCropViewReport = createSelector(
  cameraDataSelector,
  (state: IState): Array<ICropViewReportData> => state.cameraData.cropViewReportData
);

export const selectCameraGlueBoardDataIscoutReport = createSelector(
  cameraDataSelector,
  (state: IState): IIscoutGlueBoard => state.cameraData.iscoutReportDataGlueBoard
);

export const selectCameraSeasonDataIscoutReport = createSelector(
  cameraDataSelector,
  (state: IState): IIscoutSeason => state.cameraData.iscoutReportDataSeason
);
