import { Component, OnInit, OnDestroy } from '@angular/core';
import { LeftComponentsTogglerService } from '../../core/services/left-components-toggler/left-components-toggler.service';
import { TreeService } from '../../services/tree/tree.service';
import { ITreeStructure } from '../../services/tree/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { stationDataContentAnimations } from '../../core/services/left-components-toggler/animation.constants';
import { Store, select } from '@ngrx/store';
import { selectNavigationStation } from './../../core/reducers/index';
import { filter, takeUntil } from 'rxjs/operators';
import { IStation } from './../../core/models/stations';
import { IWorkPlanningState } from './models/work-planning.models';
import { getStationWorkPlanningLicenses } from './actions';
import * as fromNavigationStation from '../../core/reducers/navigation-station';

@Component({
  selector: 'app-work-planning',
  templateUrl: './work-planning.component.html',
  styleUrls: ['./work-planning.component.scss'],
  animations: stationDataContentAnimations
})
export class WorkPlanningComponent implements OnInit, OnDestroy {
  public tree$: BehaviorSubject<ITreeStructure>;
  public state$: Observable<string>;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private treeService: TreeService,
    private leftComponentsToggler: LeftComponentsTogglerService,
    private navigationStationStore: Store<fromNavigationStation.INavigationStationState>,
    private workPlanningStore: Store<IWorkPlanningState>
  ) { }

  public ngOnInit(): void {
    this.tree$ = this.treeService.getWorkplanningServicesTreeStructure();
    this.state$ = this.leftComponentsToggler.getStationDataContentState();

    this.navigationStationStore.pipe(
      select(selectNavigationStation),
      filter((station) => !!station),
      takeUntil(this.destroy$)
    ).subscribe((station: IStation) => {
      this.workPlanningStore.dispatch(getStationWorkPlanningLicenses(station.name.original));
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
