import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { stationDataContentAnimations } from '../../../../core/services/left-components-toggler/animation.constants';
import { ITreeStructure } from '../../../../services/tree/models';
import { TreeService } from '../../../../services/tree/tree.service';
import { resetSelectedConnection, setTrackerData } from '../../actions/tracker';
import { selectTrackerLoadError, selectTrackerLoadProcess } from '../../reducers';
import { TrackerService } from '../../services/tracker.service';
import * as fromTracker from './../../reducers/index';

@Component({
  selector: 'app-tracker',
  templateUrl: './tracker.component.html',
  styleUrls: ['./tracker.component.scss'],
  animations: stationDataContentAnimations
})
export class TrackerComponent implements OnInit, OnDestroy {
  public isError$: Observable<boolean>;
  public isLoading$: Observable<boolean>;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public tree$: Observable<ITreeStructure>;
  private currentStation: IStation;

  constructor(private trackerStore: Store<fromTracker.ITrackerDataState>,
    private treeService: TreeService,
    private trackerService: TrackerService,
    private navigationStationStore: Store<INavigationStationState>) { }

  public ngOnInit(): void {
    this.listenStationChange();
    this.tree$ = this.treeService.getTrackerTreeStructure().pipe(
      takeUntil(this.destroy$)
    );
    this.isError$ = this.trackerStore.pipe(
      takeUntil(this.destroy$),
      select(selectTrackerLoadError)
    );
    this.isLoading$ = this.trackerStore.pipe(
      takeUntil(this.destroy$),
      select(selectTrackerLoadProcess)
    );
  }

  private listenStationChange(): void {
    this.navigationStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((selectedStation: IStation): boolean => !!selectedStation),
    ).subscribe((station: IStation) => {
      if (!this.currentStation || station.name.original !== this.currentStation.name.original) {
        this.currentStation = station;

        this.trackerStore.dispatch(setTrackerData([]));
        this.trackerService.setSelectedTripIO(null);
        this.trackerStore.dispatch(resetSelectedConnection());
      }
    });
  }

  public tripsSelected(): void {
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.trackerStore.dispatch(resetSelectedConnection());
  }
}
