<section class="card-header">
  <h2 class="card-header__header">{{ header | translate }}
    <small class="card-header__description">{{ description | translate }}</small>
  </h2>
  <div class="card-header__date" *ngIf="period && period.from && period.to">
    {{ (period?.title || 'Data')  | translate }} {{ 'from' | translate }}
    <span>
      {{ period.from | date:'yyyy-MM-dd HH:mm' }}
    </span>
    {{ 'to' | translate }}
    <span>
      {{ period.to | date:'yyyy-MM-dd HH:mm' }}
    </span>
  </div>
  <div *ngIf="helpButton">
    <i class="zmdi zmdi-help card-header__help"
       (click)="openModal.emit()"></i>
  </div>
</section>
