import { Component, Input, OnInit, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IOptions } from '../../../../../shared/interfaces';
import { removeWidget } from '../../../actions/dashboard';
import { MapWidgetModes } from '../../../constants/constants';
import { IWidget } from '../../../models/dashboard.models';
import * as fromDashboard from '../../../reducers/dashboard';
import { selectEdit } from '../../../reducers/index';
import { IThemeConfig } from '../../../../../../environments/interfaces/theme';
import { environmentToken } from '../../../../../../environments/environment';
import { IEnvironment } from '../../../../../../environments/interfaces/environment';

@Component({
  selector: 'app-google-map-widget',
  templateUrl: './google-map-widget.component.html',
  styleUrls: ['./google-map-widget.component.scss']
})
export class GoogleMapWidgetComponent implements OnInit {
  @Input()
  public widget                      : IWidget;
  @Input()
  public widgetIndex                 : number;
  @Input()
  public columnIndex                 : number;
  @Input()
  public rowIndex                    : number;
  public isEdit$                     : Observable<boolean>;
  public mapModes                    : Array<IOptions> = MapWidgetModes;
  public form                        : FormGroup;
  public mapMode                     : string;

  public subDomain                   : IThemeConfig;

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    private dashboardStore: Store<fromDashboard.IDashboard>,
    private formBuilder: FormBuilder
  ) {
    this.subDomain = environment.theme;

    let mapWidgetMode: string = localStorage.getItem('mapWidgetMode');
    if (!mapWidgetMode) {
      mapWidgetMode = 'rain24h';
    }

    this.form = this.formBuilder.group({
      'mode': new FormControl(mapWidgetMode),
    });
    this.mapMode = this.mode.value;
  }

  public ngOnInit(): void {
    this.isEdit$ = this.dashboardStore.pipe(select(selectEdit));

    this.mode.valueChanges.subscribe((value) => {
      this.mapMode = value;
    });
  }

  public get mode(): AbstractControl {
    return this.form.get('mode');
  }

  public removeCurrentWidget(): void {
    const path: Array<number> = [this.rowIndex, this.columnIndex, this.widgetIndex];
    this.dashboardStore.dispatch(removeWidget(path));
  }

  public submit(): void {
    this.mapMode = this.mode.value;
  }
}
