import { Component, Input, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ExcelExportParams, GridApi, GridOptions } from 'ag-grid';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DataGridOptions, ExcelExportSettings } from '../../../../shared/constants';
import { setCameraDataIsTableExportInProgress } from '../../actions/camera-data';
import { StationCameraTypes } from '../../constants/camera-data';
import { ICameraDataState } from '../../models/camera-data';
import { selectCameraDataIsTableExportInProgress } from '../../reducers';

@Component({
  selector: 'app-camera-data-table',
  templateUrl: './camera-data-table.component.html'
})
export class CameraDataTableComponent implements OnDestroy {
  @Input()
  public rowData: any = [];
  @Input()
  public additionalColumnDefs: any = [];
  @Input()
  public stationCameraType: string;

  public dataGridOptions: GridOptions = {
    ...DataGridOptions,
    enableFilter: true
  };

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private cameraDataStore: Store<ICameraDataState>) {
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public get isIScout(): boolean {
    return this.stationCameraType === StationCameraTypes.ISCOUT;
  }

  public get isCropView(): boolean {
    return this.stationCameraType !== StationCameraTypes.ISCOUT;
  }

  public exportedDataListener(dataGridOptionsApi: GridApi): void {
    this.cameraDataStore.pipe(
      select(selectCameraDataIsTableExportInProgress),
      takeUntil(this.destroy$),
      filter((status: boolean): boolean => status)
    ).subscribe((): void => {
      const params: ExcelExportParams = {
        ...ExcelExportSettings,
        fileName: 'camera-data',
      };

      if (dataGridOptionsApi) {
        dataGridOptionsApi.exportDataAsExcel(params);
      }
      this.cameraDataStore.dispatch(setCameraDataIsTableExportInProgress(false));
    });
  }
}
