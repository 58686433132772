<div class="product-license-activation">
  <ng-container *ngIf="!(productConfiguration$ | async); else productActivationForm">
    <app-form-layout [shadow]="false">
      <div class="product-license-activation__form">
        <div class="product-license-activation__title">
          <h2>{{ "Activate a new product" | translate }}</h2>
        </div>

        <div class="alert alert-info">
          <span class="glyphicon glyphicon-info-sign"></span>
          {{ 'To purchase licenses online please visit our shop at ' | translate }}
          <a href="https://shop.metos.at">https://shop.metos.at</a>
          {{ 'or contact Sales via email at ' | translate }}
          <a href="mailto:orders@metos.at?subject=Product activation">orders@metos.at</a>
          {{ 'with your request.' | translate }}
        </div>

        <div class="product-license-activation__content">
          <app-product-license-activation-form
            (activateProductEmitter)="findProductToActivate($event)"
            (cancelEmitter)="handleCancel()"
          ></app-product-license-activation-form>
        </div>
      </div>
    </app-form-layout>
  </ng-container>

  <ng-template #productActivationForm>
    <app-product-activation-davis-ingest
      *ngIf="isDavisIngest()"
      [cancelable]="true"
      [emitActivation]="true"
      [configuration]="productConfiguration$ | async"
      (activateProduct)="handleDavisIngestActivation($event)"
      (cancel)="handleCancel()"
    ></app-product-activation-davis-ingest>

    <app-product-activation-farm-view
      *ngIf="isFarmView()"
      [cancelable]="true"
      [emitActivation]="true"
      [configuration]="productConfiguration$ | async"
      (activateProduct)="handleFarmViewActivation($event)"
      (cancel)="handleCancel()"
    ></app-product-activation-farm-view>

    <ng-container *ngIf="(productConfiguration$ | async).activated">
      <div class="alert alert-info">
        <i class="glyphicon glyphicon-info-sign"></i>
        {{ 'This product has already been activated' | translate }}
      </div>

      <div class="product-license-activation__button">
        <app-custom-button [content]="'Cancel'"
                           [type]="'button'"
                           (click)="handleCancel()"></app-custom-button>
      </div>
    </ng-container>
  </ng-template>
</div>
