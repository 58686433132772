<app-form-layout [hidden]="!(isActive$ | async)">
  <div class="view-editor">
    <div  *ngIf="!hasSensors; else editor" class="alert alert-info view-editor__alert">
      {{ 'No soil sensors available' | translate }}
    </div>
    <ng-template #editor>
      <div class="view-editor__header">
        <h2>{{ (mode === EditorMode.EDIT ? 'Edit View' : 'Create View') | translate }} </h2>
      </div>

      <div class="view-editor__body" *ngIf="status$ | async as status">

        <div [formGroup]="form" class="view-editor__form">
          <div class="view-editor__group">
            <label>{{ 'Name' | translate }}</label>
            <app-form-input [contentType]="'text'" [formControl]="name"></app-form-input>
          </div>

          <div class="view-editor__group">
            <div class="view-editor__dropdown">
              <label>{{ 'Operation' | translate }}</label>
              <app-custom-dropdown [options]="operations" [formControl]="operation"></app-custom-dropdown>
            </div>
          </div>

          <div class="view-editor__group">
            <div class="view-editor__dropdown">
              <label>{{ 'Sensor Type' | translate }}</label>
              <app-custom-dropdown [options]="selectedSensorTypes" [formControl]="sensorType"></app-custom-dropdown>
            </div>
          </div>

          <div class="view-editor__group" *ngIf="selectedOperation === OperationType.PAW" style="display:none;">
            <label>{{ 'Refill & Full Point in %' | translate }}</label>
            <div>
              <app-form-input inline="true" minValue="0" maxValue="100" [contentType]="'number'" [formControl]="refillPoint"></app-form-input>
              <app-form-input isDisabled="true" inline="true" minValue="0" maxValue="100" [contentType]="'number'" [formControl]="fullPoint"></app-form-input>
            </div>
          </div>

          <div class="view-editor__group">
            <div class="view-editor__select">
              <label *ngIf="selectedOperation !== OperationType.PAW">{{ 'Sensors' | translate }}</label>
              <label *ngIf="selectedOperation === OperationType.PAW">{{ 'Refill Point & Field Capacity' | translate }}</label>
              <div formArrayName="sensors">
                <div *ngFor="let sensor of form.get('sensors')['controls']; let i = index">

                  <div *ngIf="selectedOperation !== OperationType.PAW; else pawForm" [formGroup]="form.get('sensors')['controls'][i].controls.default">
                    <app-custom-checkbox formControlName="sensorChecked">
                      {{ sensor.get('default').get('name').value }} <small>[{{ sensor.get('default').get('ch').value }}]</small>
                    </app-custom-checkbox>
                  </div>

                  <ng-template #pawForm>
                    <div [formGroup]="form.get('sensors')['controls'][i].controls.paw" style="white-space: nowrap;">
                      <app-form-input inline="true" minValue="0" [contentType]="'number'" formControlName="wiltingPoint"></app-form-input>
                      <app-form-input inline="true" minValue="0" [contentType]="'number'" formControlName="fieldCapacity"></app-form-input>
                      <span class="view-editor__inline label" [class.error]="!sensor.valid">{{ sensor.get('default').get('name').value }} <small>[{{ sensor.get('default').get('ch').value }}]</small></span>
                    </div>
                  </ng-template>

                </div>
              </div>
            </div>
          </div>

          <div class="view-editor__group" *ngIf="selectedOperation === OperationType.PAW">

          </div>

        </div>

        <hr />

        <div class="view-editor__footer">
          <span style="flex: 1 1 auto;"></span>
          <app-custom-button (click)="deleteView()" [disabled]="status.viewIsPending || status.isLoading || mode === EditorMode.CREATE" [color]="'orange'" [content]="'Delete'"></app-custom-button>
          <app-custom-button (click)="saveView()" [disabled]="status.viewIsPending || status.isLoading || !this.form.valid" [color]="'red'" [content]="'Save'"></app-custom-button>
        </div>

      </div>
    </ng-template>
  </div>
</app-form-layout>
