import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IFromTo, IPestToggler, IPicture, IToolbarSettings} from '../../../shared/camera/models/camera';
import {IRectangle, IRectangleSelected} from '../../../shared/camera/models/rectangles';

export const enum IscoutSettingsActionTypes {
  RESET_ISCOUT_SETTINGS = '[IscoutSettings] RESET_ISCOUT_SETTINGS',
  SET_ISCOUT_SETTINGS_CURRENT_DATE_STRING = '[IscoutSettings] SET_ISCOUT_SETTINGS_CURRENT_DATE_FILTER',
  SET_ISCOUT_SETTINGS_SELECTED_PICTURE = '[IscoutSettings] SET_ISCOUT_SETTINGS_SELECTED_PICTURE',
  SET_ISCOUT_SETTINGS_TOOLBAR_SETTINGS = '[IscoutSettings] SET_ISCOUT_SETTINGS_TOOLBAR_SETTINGS',
  TOGGLE_ISCOUT_SETTINGS_HELP_IS_ACTIVE = '[IscoutSettings] TOGGLE_ISCOUT_SETTINGS_HELP_IS_ACTIVE',
  TOGGLE_ISCOUT_SETTINGS_SAVE_INFO_IS_ACTIVE = '[IscoutSettings] TOGGLE_ISCOUT_SETTINGS_SAVE_INFO_IS_ACTIVE',
  TOGGLE_ISCOUT_SETTINGS_UPDATE_INFO_IS_ACTIVE = '[IscoutSettings] TOGGLE_ISCOUT_SETTINGS_UPDATE_INFO_IS_ACTIVE',
  GET_ISCOUT_SETTINGS_PEST_TOGGLES = '[IscoutSettings] GET_ISCOUT_SETTINGS_PEST_TOGGLES',
  SET_ISCOUT_SETTINGS_PEST_TOGGLES = '[IscoutSettings] SET_ISCOUT_SETTINGS_PEST_TOGGLES',
  UPDATE_ISCOUT_SETTINGS_PEST_TOGGLES = '[IscoutSettings] UPDATE_ISCOUT_SETTINGS_PEST_TOGGLES',
  SET_ISCOUT_SETTINGS_PEST_TOGGLE = '[IscoutSettings] SET_ISCOUT_SETTINGS_PEST_TOGGLE',
  ADD_ISCOUT_SELECTED_PICTURE_RECTANGLE = '[IscoutSettings] ADD_ISCOUT_SELECTED_PICTURE_RECTANGLE',
  REMOVE_ISCOUT_SELECTED_PICTURE_RECTANGLE = '[IscoutSettings] REMOVE_ISCOUT_SELECTED_PICTURE_RECTANGLE',
  REMOVE_ISCOUT_SELECTED_PICTURE_MULTIPLE_RECTANGLES = '[IscoutSettings] REMOVE_ISCOUT_SELECTED_PICTURE_MULTIPLE_RECT',
  UPDATE_ISCOUT_SELECTED_PICTURE_RECTANGLE = '[IscoutSettings] UPDATE_ISCOUT_SELECTED_PICTURE_RECTANGLE',
  UPDATE_ISCOUT_SELECTED_PICTURE_MULTIPLE_RETANGLES = '[IscoutSettings] UPDATE_ISCOUT_SELECTED_PICTURE_MULTIPLE_RECT'
}

export function resetIscoutSettings(): IActionWithPayload {
  return {type: IscoutSettingsActionTypes.RESET_ISCOUT_SETTINGS, payload: null};
}

export function setIscoutSettingsCurrentDateString(currentDate: string | IFromTo): IActionWithPayload {
  return {type: IscoutSettingsActionTypes.SET_ISCOUT_SETTINGS_CURRENT_DATE_STRING, payload: currentDate};
}

export function setIscoutSettingsSelectedPicture(picture: IPicture): IActionWithPayload {
  return {type: IscoutSettingsActionTypes.SET_ISCOUT_SETTINGS_SELECTED_PICTURE, payload: picture};
}

export function setIscoutSettingsToolbarSettings(toolbarSettings: IToolbarSettings): IActionWithPayload {
  return {type: IscoutSettingsActionTypes.SET_ISCOUT_SETTINGS_TOOLBAR_SETTINGS, payload: toolbarSettings};
}

export function toggleIscoutSettingsIsHelpActive(isActive: boolean): IActionWithPayload {
  return {type: IscoutSettingsActionTypes.TOGGLE_ISCOUT_SETTINGS_HELP_IS_ACTIVE, payload: isActive};
}

export function toggleIscoutSettingsIsSaveActive(isActive: boolean): IActionWithPayload {
  return {type: IscoutSettingsActionTypes.TOGGLE_ISCOUT_SETTINGS_SAVE_INFO_IS_ACTIVE, payload: isActive};
}

export function toggleIscoutSettingsIsUpdateActive(isActive: boolean): IActionWithPayload {
  return {type: IscoutSettingsActionTypes.TOGGLE_ISCOUT_SETTINGS_UPDATE_INFO_IS_ACTIVE, payload: isActive};
}

export function getIscoutSettingsPestToggles(stationId: string): IActionWithPayload {
  return {type: IscoutSettingsActionTypes.GET_ISCOUT_SETTINGS_PEST_TOGGLES, payload: stationId};
}

export function setIscoutSettingsPestToggles(pestToggles: IPestToggler[]): IActionWithPayload {
  return {type: IscoutSettingsActionTypes.SET_ISCOUT_SETTINGS_PEST_TOGGLES, payload: pestToggles};
}

export function setIscoutSettingsPestToggle(pestToggle: IPestToggler): IActionWithPayload {
  return {type: IscoutSettingsActionTypes.SET_ISCOUT_SETTINGS_PEST_TOGGLE, payload: pestToggle};
}

export function updateIscoutSettingsPestToggles(stationId: string, pestToggle: IPestToggler): IActionWithPayload {
  return {
    type: IscoutSettingsActionTypes.UPDATE_ISCOUT_SETTINGS_PEST_TOGGLES,
    payload: {stationId, pestToggle}
  };
}

export function addIscoutSelectedPictureRectangle(rectangle: IRectangle): IActionWithPayload {
  return {type: IscoutSettingsActionTypes.ADD_ISCOUT_SELECTED_PICTURE_RECTANGLE, payload: rectangle};
}

export function removeIscoutSelectedPictureRectangle(rectangleSelected: IRectangleSelected): IActionWithPayload {
  return {type: IscoutSettingsActionTypes.REMOVE_ISCOUT_SELECTED_PICTURE_RECTANGLE, payload: rectangleSelected};
}

export function removeIscoutSelectedPictureMultipleRectangles(
  multipleSelection: IRectangleSelected[]
): IActionWithPayload {
  return {
    type: IscoutSettingsActionTypes.REMOVE_ISCOUT_SELECTED_PICTURE_MULTIPLE_RECTANGLES,
    payload: multipleSelection
  };
}

export function updateIscoutSelectedPictureRectangle(rectangle: IRectangle, rectangleIndex: number): IActionWithPayload {
  const payload = {rectangle, rectangleIndex};
  return {type: IscoutSettingsActionTypes.UPDATE_ISCOUT_SELECTED_PICTURE_RECTANGLE, payload};
}

export function updateIscoutSelectedPictureMultipleRectangles(
  multipleSelection: IRectangleSelected[]
): IActionWithPayload {
  return {
    type: IscoutSettingsActionTypes.UPDATE_ISCOUT_SELECTED_PICTURE_MULTIPLE_RETANGLES,
    payload: multipleSelection
  };
}
