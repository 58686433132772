import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectStationLoadError, selectStationLoadProcess } from '../../../station-data/reducers';
import * as fromStationData from '../../../station-data/reducers/station-data';
import { IAccumulatorToolState } from '../../interfaces/interfaces';
import { selectSelectedStation } from '../../../../core/reducers';
import { getStationSensors } from '../../actions/actions';
import * as fromSelectedStation from '../../../../core/reducers/selectedStation';
import { ISelectedSearchWidgetItem } from '../../../../core/models/selectedSearchWidgetItem';

@Component({
  selector: 'app-accumulator-tool-content',
  templateUrl: './accumulator-tool-content.component.html',
  styleUrls: ['./accumulator-tool-content.component.scss']
})
export class AccumulatorToolContentComponent implements OnInit, OnDestroy {

  private destroy$      : Subject<boolean> = new Subject<boolean>();

  public stationId      : string;
  public isLoading$     : Observable<boolean>;
  public isError$       : Observable<boolean>;

  constructor(
    private stationDataStore        : Store<fromStationData.IStationDataState>,
    private accumulatorToolStore    : Store<IAccumulatorToolState>,
    private selectedStationStore    : Store<fromSelectedStation.ISelectedStationState>,
  ) { }

  public ngOnInit(): void {
    this.selectedStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectSelectedStation)
    ).subscribe((station: ISelectedSearchWidgetItem) => {
      if (station.original_name !== this.stationId) {
        this.stationId = station.original_name;
        this.accumulatorToolStore.dispatch(getStationSensors(station.original_name));
      }
    });

    this.isError$ = this.stationDataStore.pipe(
      takeUntil(this.destroy$),
      select(selectStationLoadError)
    );
    this.isLoading$ = this.stationDataStore.pipe(
      takeUntil(this.destroy$),
      select(selectStationLoadProcess)
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
