import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'angular-highcharts';
import { charts, IndividualSeriesOptions } from 'highcharts';
import { range, Subject } from 'rxjs';
import { delay, distinctUntilChanged, filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { selectSystemSensors } from '../../../../../core/reducers';
import { IAccount } from '../../../../../core/reducers/account';
import { ISystemSensor } from '../../../../../shared/interfaces';
import addChartZoomSync from '../../../../../shared/utils/addChartZoomSync';
import { deepClone } from '../../../../../shared/utils/deepClone';
import { setSumsSmRowHidden } from '../../../actions';
import { DEFAULT_CHART_OPTIONS } from '../../../constants';
import { ISumsSmData, ISumsSmState } from '../../../models';
import { sumsSmSelector } from '../../../selectors';

@Component({
  selector: 'app-crop-zone-soil-moisture-sums-sm-charts',
  templateUrl: './crop-zone-soil-moisture-sums-sm-charts.component.html',
  styleUrls: ['./crop-zone-soil-moisture-sums-sm-charts.component.scss']
})
export class CropZoneSoilMoistureSumsSmChartsComponent implements OnInit, OnDestroy {

  public destroyed$: Subject<boolean> = new Subject();

  public data: ISumsSmData[] = [];
  public charts: Chart[][] = [];
  public hiddenRows: boolean[][] = [];
  public displayActiveRootZone: boolean = true;

  private refillPointString: string;
  private fieldCapacityString: string;
  private soilMoistureSumString: string;
  private rootDepthString: string;
  private perRootZoneString: string;
  private perProfileString: string;
  private perSensorString: string;

  constructor(
    private store: Store<ISumsSmState>,
    private accountStore: Store<IAccount>,
    private translation: TranslateService
  ) {
  }

  public ngOnInit(): void {
    this.translateStrings();

    this.store.pipe(
      takeUntil(this.destroyed$),
      select(sumsSmSelector),
      filter(sumsSmState => !!sumsSmState.data.length),
      distinctUntilChanged((a, b) => a.data === b.data),
      withLatestFrom(this.accountStore.pipe(select(selectSystemSensors))),
    ).subscribe(([sumsSmState, sensors]) => {
      this.data = sumsSmState.data;
      this.charts = this.getCharts(sensors, sumsSmState);
    });

    this.store.pipe(
      takeUntil(this.destroyed$),
      select(sumsSmSelector),
      distinctUntilChanged((a, b) => a.displayActiveRootZone === b.displayActiveRootZone),
      filter(() => !!this.data.length)
    ).subscribe(sumsSmState => {
      this.hiddenRows = sumsSmState.hiddenRows;
      this.displayActiveRootZone = sumsSmState.displayActiveRootZone;
      // avoid freezing when there is a lot of data
      range(0, sumsSmState.data.length).pipe(delay(100)).subscribe(index => {
        this.updateSum(index, sumsSmState.hiddenRows[index], sumsSmState.displayActiveRootZone);
        this.updateSm(index, sumsSmState.displayActiveRootZone);
      });
    });

  }

  private getCharts(sensors: { [index: number]: ISystemSensor }, state: ISumsSmState): Chart[][] {

    const { hiddenRows, displayActiveRootZone } = state;

    return state.data.map((d, i) => {

      const unit = d.rows.length ? d.rows[0].unit : '';
      const sumSeries = this.getSumSeries(d, hiddenRows[i], displayActiveRootZone, unit);

      const smSeries = d.rows.map((row, j, rows) => ({
        type: 'line',
        name: `${sensors[row.code].name || '?'} ${Math.round(row.depth)} ${d.depth_unit}`,
        data: row.sms.map((sm, k) => ([
          d.dates[k],
          displayActiveRootZone ? (sm.in_root_zone ? sm.soil_moisture : null) : sm.soil_moisture
        ])) as any,
        color: this.getColor(rows.length, j),
        tooltip: {
          valueDecimals: unit === 'mm' ? 1 : 3,
          valueSuffix: ` ${unit}` // whitespace
        },
        yAxis: 0,
        visible: !hiddenRows[i][j],
        turboThreshold: 100000
      }));

      return addChartZoomSync([
        new Chart({
          ...deepClone(DEFAULT_CHART_OPTIONS),
          legend: {
            enabled: true
          },
          yAxis: [
            {
              title: {
                text: `${unit} ${displayActiveRootZone ? this.perRootZoneString : this.perProfileString}`
              }
            },
            {
              title: {
                text: `${this.rootDepthString} [${unit === 'mm' ? 'cm' : 'in'}]`
              },
              opposite: true,
              min: 0
            }
          ],
          xAxis: {
            ...(deepClone(DEFAULT_CHART_OPTIONS) as any).xAxis,
            min: state.from.getTime(),
            max: state.to.getTime()
          },
          series: sumSeries,
          plotOptions: {
            series: {
              pointPlacement: 'on',
              states: {
                hover: {
                  enabled: false
                }
              },
              marker: {
                enabled: false
              }
            }
          }
        }),
        new Chart({
          ...deepClone(DEFAULT_CHART_OPTIONS),
          yAxis: [
            {
              title: {
                text: `${this.perSensorString} [${unit}]`
              }
            }
          ],
          xAxis: {
            ...(deepClone(DEFAULT_CHART_OPTIONS) as any).xAxis,
            min: state.from.getTime(),
            max: state.to.getTime()
          },
          series: smSeries,
          plotOptions: {
            series: {
              states: {
                hover: {
                  enabled: false
                }
              },
              marker: {
                enabled: false
              },
              events: {
                legendItemClick: (function (that, sourceIndex): any {
                  return function (): any {
                    const { index, visible } = this;
                    const rows = that.hiddenRows[sourceIndex].slice();
                    rows[index] = visible;
                    that.updateSum(sourceIndex, rows, that.displayActiveRootZone);
                    that.store.dispatch(setSumsSmRowHidden(sourceIndex, index, visible));
                  };
                }(this, i))
              }
            }
          }
        })
      ]);
    });
  }

  // @todo use colors form sensor settings if available
  private getColor(i, j): string {
    const grey = Math.round(255 / i) * j;
    return `rgb(${grey}, ${grey}, ${grey})`;
  }

  private updateSm(sourceIndex: number, displayActiveRootZone: boolean): void {
    const data = this.data[sourceIndex];
    const chart = this.charts[sourceIndex][1];
    data.rows.forEach((row, j) => {
      const seriesData = row.sms.map((sm, k) => ([
        data.dates[k],
        displayActiveRootZone ? (sm.in_root_zone ? sm.soil_moisture : null) : sm.soil_moisture
      ])) as any;
      if (chart.ref) {
        chart.ref.series[j].setData(seriesData, false);
      } else {
        chart.options.series[j].data = seriesData;
      }
    });
    if (chart.ref) {
      chart.ref.redraw(data.dates.length < 1000);
      charts.forEach((chartX: any) => {
        if (chartX) {
          chartX.reflow();
        }
      });
    }
  }

  private updateSum(sourceIndex: number, hiddenRows: boolean[], displayActiveRootZone): void {
    const unit = this.data[sourceIndex].depth_unit === 'cm' ? 'mm' : 'in';
    const data = this.getSum(this.data[sourceIndex], hiddenRows, displayActiveRootZone);
    const chart = this.charts[sourceIndex][0];

    const minData = chart.ref.yAxis[0].getExtremes().dataMin;
    chart.ref.yAxis[0].setExtremes(minData);
    const refillPoint = chart.options.series[0].data[0][1];

    if (refillPoint <= minData) {
      const min = (chart.ref.yAxis[0].getExtremes().min);
      const max = (chart.ref.yAxis[0].getExtremes().max);
      const newMin = refillPoint - ((max - min) * 0.05);
      chart.ref.yAxis[0].setExtremes(newMin);
    }

    data.forEach((d, i) => {
      if (chart.ref) {
        chart.ref.series[i].setData(d, false);
        chart.ref.yAxis[0].setTitle({
          text: `${displayActiveRootZone ? this.perRootZoneString : this.perProfileString} [${unit}]`
        });
      } else {
        chart.options.series[i].data = d as any;
      }
    });

    if (chart.ref) {
      chart.ref.redraw(data[0].length < 1000);
      charts.forEach((chartX: any) => {
        if (chartX) {
          chartX.reflow();
        }
      });
    }
  }

  private getSumSeries(data: ISumsSmData, hiddenRows: boolean[], displayActiveRootZone: boolean, unit: string): IndividualSeriesOptions[] {

    const seriesData = this.getSum(data, hiddenRows, displayActiveRootZone);

    return [
      {
        type: 'line',
        name: this.refillPointString,
        data: seriesData[0],
        color: '#ba162c',
        tooltip: {
          valueDecimals: unit === 'mm' ? 1 : 3,
          valueSuffix: ` ${unit}`
        },
        yAxis: 0
      },
      {
        type: 'line',
        name: this.fieldCapacityString,
        data: seriesData[1],
        color: '#165dba',
        tooltip: {
          valueDecimals: unit === 'mm' ? 1 : 3,
          valueSuffix: ` ${unit}`
        },
        yAxis: 0
      },
      {
        type: 'column',
        name: this.soilMoistureSumString,
        data: seriesData[2],
        color: 'rgba(68, 157, 68, 0.75)',
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 0,
        shadow: false,
        yAxis: 0,
        zIndex: -1,
        tooltip: {
          valueDecimals: unit === 'mm' ? 1 : 3,
          valueSuffix: ` ${unit}`
        },
        turboThreshold: 100000
      } as any,
      {
        type: 'line',
        name: this.rootDepthString,
        data: seriesData[3],
        color: '#333333',
        tooltip: {
          valueDecimals: unit === 'mm' ? 1 : 3,
          valueSuffix: ` ${unit === 'mm' ? 'cm' : 'in'}`
        },
        yAxis: 1
      }
    ];
  }

  private getSum(data: ISumsSmData, hiddenRows: boolean[], displayActiveRootZone: boolean): number[][][] {
    return data.dates.reduce((a, date, i) => {

      let rd = null;
      let [rp, fc, sm] = data.rows.reduce((sums, row, j) => {

        if (!hiddenRows[j]) {
          const sms = row.sms[i];
          if (!displayActiveRootZone || sms.in_root_zone) {
            sums[0] += row.refill_point;
            sums[1] += row.field_capacity;
            sums[2] = sums[2] === null && sms.soil_moisture === null ? null : sums[2] + sms.soil_moisture;
          }
        }

        return sums;
      }, [0, 0, null]);

      if (sm !== null) {
        sm = +sm.toFixed(2);
        rd = data.sum[i].root_depth;
      } else {
        rp = null;
        fc = null;
      }

      a[0].push([date, rp]);
      a[1].push([date, fc]);
      a[2].push({
        x: date,
        y: sm,
        color: sm > rp ? 'rgba(68, 157, 68, 0.75)' : 'rgba(217, 83, 79, 0.9)'
      });
      a[3].push([date, rd]);

      return a;

    }, [[], [], [], []]);
  }

  private translateStrings(): void {
    this.translation.get('Refill Point').subscribe(translatedRefillPointString =>
      this.refillPointString = translatedRefillPointString);
    this.translation.get('Field Capacity').subscribe(translatedFieldCapacityString =>
      this.fieldCapacityString = translatedFieldCapacityString);
    this.translation.get('Soil Moisture Sum').subscribe(translatedSoilMoistureSumString =>
      this.soilMoistureSumString = translatedSoilMoistureSumString);
    this.translation.get('Root Depth').subscribe(translatedRootDepthString =>
      this.rootDepthString = translatedRootDepthString);
    this.translation.get('Soil moisture sum').subscribe(translatedPerRootZoneString =>
      this.perRootZoneString = translatedPerRootZoneString);
    this.translation.get('Soil moisture sum (profile)').subscribe(translatedPerProfileString =>
      this.perProfileString = translatedPerProfileString);
    this.translation.get('Soil moisture per sensor').subscribe(translatedPerSensorString =>
      this.perSensorString = translatedPerSensorString);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
