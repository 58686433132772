import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'angular-highcharts';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, takeUntil, tap, withLatestFrom, filter } from 'rxjs/operators';
import { ANIMAL_PRODUCTION_TREE_NODES } from '../../../../services/tree/constants';
import { IAnimalProductionChartValues, IChartAndLegend, IChartLegendItem } from '../../../../shared/interfaces';
import { setGroupAndProfile, TreeSettingsActionTypes } from '../../../../shared/tree/actions/tree-settings';
import { ITreeSettingsState } from '../../../../shared/tree/models/tree.models';
import addChartTooltip from '../../../../shared/utils/addChartTooltip';
import addChartZoomSync from '../../../../shared/utils/addChartZoomSync';
import { deepClone } from '../../../../shared/utils/deepClone';
import { getAnimalProductionCharts, getAnimalProductionLicenses } from '../../actions';
import { SERVICE_CHART_LEGEND, SERVICE_CHART_TOOLTIP_PROPERTIES, SERVICE_HELP_CONTENT } from '../../constants';
import { IAnimalProductionState, IHeaderData } from '../../models/animal-production.models';
import {
  selectAnimalProductionCharts, selectAnimalProductionIsError,
  selectAnimalProductionIsLoading, selectAnimalProductionLicenses, selectAnimalProductionTreeSettings
} from '../../reducers';
import { IAccount } from '../../../../core/reducers/account';
import { AnimalProductionService } from '../../services/animal-production.service';
import { selectNavigationStation } from '../../../../core/reducers';
import { IStation } from '../../../../core/models/stations';

@Component({
  selector: 'app-animal-production-content',
  templateUrl: './animal-production-content.component.html',
  styleUrls: ['./animal-production-content.component.scss']
})
export class AnimalProductionContentComponent implements OnInit, OnDestroy {
  public validLicense                 : boolean;
  public isLoading$                   : Observable<boolean>;
  public isError$                     : Observable<boolean>;
  public chartsAndLegends$            : Observable<IChartAndLegend[]>;
  public header                       : IHeaderData = { name: '', help: '', period: {
                                          title: '', from: null, to: null
                                        }};

  private destroyed$                  : Subject<boolean> = new Subject<boolean>();
  private selectedService             : string;

  public translatedValues: IAnimalProductionChartValues;

  constructor(
    private store                   : Store<IAnimalProductionState>,
    private tree                    : Store<ITreeSettingsState>,
    private activatedRoute          : ActivatedRoute,
    private translate               : TranslateService,
    private account                 : Store<IAccount>,
    private animalProductionService : AnimalProductionService
  ) { }

  public ngOnInit(): void {
    this.account.pipe(
      select(selectNavigationStation),
      filter((station) => !!station),
      takeUntil(this.destroyed$)
    ).subscribe((station: IStation) => {
      this.store.dispatch(getAnimalProductionLicenses(station.name.original));
    });

    this.store.pipe(
      select(selectAnimalProductionLicenses),
      takeUntil(this.destroyed$)
    ).subscribe((licenses) => {
      this.validLicense = this.animalProductionService.setWarningMessage(licenses);
    });

    this.translatedValues = this.activatedRoute.snapshot.data['animalProductionResolver'];

    this.isLoading$ = this.store.pipe(
      select(selectAnimalProductionIsLoading)
    );
    this.isError$ = this.store.pipe(
      select(selectAnimalProductionIsError)
    );

    this.chartsAndLegends$ = this.store.pipe(
      select(selectAnimalProductionCharts),
      switchMap(charts => {
        const chart = charts[0];
          chart.series.forEach(serie => {
            serie.data.forEach(oneData => {
              oneData['realValue'] = this.getTranslatedValue(oneData['realValue']);
            });
          });

        let tooltip = SERVICE_CHART_TOOLTIP_PROPERTIES[this.selectedService];
        if (tooltip && charts.length > 1) {
          return this.translate.get(tooltip.map(tip => tip.name))
            .pipe(map((i18n) => {
              tooltip = deepClone(tooltip).map(tip => ({ prop: tip.prop, name: i18n[tip.name] }));
              return addChartTooltip(charts, [tooltip]);
            }));
        }
        return [charts];
      }),
      map(charts => charts.map(c => new Chart(c))),
      tap(charts => this.setHeader(this.selectedService, charts)),
      map(addChartZoomSync),
      map(charts => this.addChartLegends(charts, (charts.length > 1 ? [SERVICE_CHART_LEGEND[this.selectedService]] : [])))
    );

    this.activatedRoute.params.pipe(
      distinctUntilChanged((a, b) => a.service === b.service && a.crop === b.crop && a.id === b.id),
      withLatestFrom(this.tree.pipe(select(selectAnimalProductionTreeSettings))),
      takeUntil(this.destroyed$)
    ).subscribe(([params, settings]: [{ id: string, service: string, crop: string }, ITreeSettingsState]) => {
      const { id, service, crop } = params;
      const setting = settings[id];
      if (service) {
        this.getCharts(id, service, crop);
        this.setHeader(service);
        if (!setting || setting.selectedProfile !== crop || setting.selectedProfileGroup !== service) {
          this.setTreeSelection(id, service, crop || service);
        }
      }
    });

  }

  private getCharts(stationId: string, service: string, crop: string): void {
    this.selectedService = service;
    this.store.dispatch(getAnimalProductionCharts({
      stationId,
      service
    }));
  }

  private setTreeSelection(stationId: string, group: string, profile: string): void {
    this.tree.dispatch(setGroupAndProfile({
      stationId,
      group,
      profile
    }, TreeSettingsActionTypes.SET_ANIMAL_PRODUCTION_PROFILE));
  }

  private setHeader(service: string, charts?: Chart[]): void {

    const node = ANIMAL_PRODUCTION_TREE_NODES.find(n => n.id === service);

    if (charts && charts[0]) {
      const from = charts[0].options.series[0].data[0][0];
      const to = charts[0].options.series[0].data[charts[0].options.series[0].data.length - 1][0];
      const offset = new Date().getTimezoneOffset();
      this.header = {
        name: '' + (node ? node.name : ''),
        help: SERVICE_HELP_CONTENT[service],
        period: {
          title: 'Forecast',
          from: moment(from).add(offset, 'minutes').toDate(),
          to: moment(to).add(offset, 'minutes').toDate(),
        }
      };
    } else {
      this.header = {
        name: '' + (node ? node.name : ''),
        help: SERVICE_HELP_CONTENT[service],
        period: this.header.period
      };
    }

  }

  private addChartLegends(charts: Chart[], legends: IChartLegendItem[][]): IChartAndLegend[] {
    return charts.map((chart, i) => {
      return { chart: chart, legends: legends[i] || [] };
    });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private getTranslatedValue(value: string): string {
    switch (value) {
      case 'No stress':
        return this.translatedValues['noStress'];
      case 'Low stress':
        return this.translatedValues['lowStress'];
      case 'Medium stress':
        return this.translatedValues['mediumStress'];
      case 'High stress':
        return this.translatedValues['highStress'];
      case 'Dead cow':
        return this.translatedValues['deadCow'];
      case 'Dead chicken':
        return this.translatedValues['deadChicken'];
      default:
        return value;
    }
  }

}
