import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { INewsLetter } from '../../../../../../core/models/account';

@Component({
  selector: 'app-notifications-form',
  templateUrl: './notifications-form.component.html',
  styleUrls: ['./notifications-form.component.scss']
})
export class NotificationsFormComponent implements OnInit {
  @Input()
  public newsletter              : INewsLetter;
  @Output()
  public changeNewsLetter        : EventEmitter<INewsLetter> = new EventEmitter();
  public form                    : FormGroup;

  constructor(private formBuilder: FormBuilder) { }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      'promotional': [this.newsletter.promotional],
      'technical': [this.newsletter.technical],
    });
  }

  public updateNotifications(): void {
    this.changeNewsLetter.emit(this.form.value);
  }

}
