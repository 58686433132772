import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { IForecastWarningMessage, IWeatherForecastState } from '../../models/models';
import { selectWeatherForecastLicenses, selectWeatherForecastWarning } from '../../reducers';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-weather-forecast-warning',
  templateUrl: './weather-forecast-warning.component.html',
  styleUrls: ['./weather-forecast-warning.component.scss']
})
export class WeatherForecastWarningComponent implements OnInit, OnDestroy {
  public warningMessage: string|null = null;
  public icon: boolean = false;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private store: Store<IWeatherForecastState>
  ) { }

  public ngOnInit(): void {
    this.store.pipe(
      select(selectWeatherForecastWarning),
      takeUntil(this.destroy$)
    ).subscribe((warning: IForecastWarningMessage) => {
      this.warningMessage = warning.message;
      this.icon = warning.icon;
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
