import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
// interceptor
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
// auth
import {AuthGuard} from './services/auth/auth.guard';
import {AuthService} from './services/auth/auth.service';
import {TokenInterceptor} from './services/auth/token.interceptor';
import {UserTokenRepository} from './services/auth/user-token-repository.service';
// store
import {ActionReducer, MetaReducer, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {localStorageSync as customLocalStorageSync} from './shared/utils/localStorageSync';
// translations
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {LanguageService} from './services/translation/language.service';
import {ObjectTranslator} from './services/translation/object-translator.service';
import {TreeService} from './services/tree/tree.service';
import {MetaActionsTypes} from './actions/metaActions';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import {AgmCoreModule} from '@agm/core';
import { environment } from '../assets/themes/default/configuration';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import {DEFAULT_LANG, GoogleMapLibraries} from './shared/constants';
import {ApiCallService} from './services/api/api-call.service';
import {NgxFaviconModule} from 'ngx-favicon';
import {AvailableCustomFavicons, customFavicons} from '../favicon.config';
import {ExcelService} from './shared/services/excel-service/excel-service.service';
import {PictocodeService} from './services/pictocode/pictocode.service';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return customLocalStorageSync({
    keys: [
      {account: ['userSelectedStation', 'userSelectedCropZone']},
      {stationData: ['treeSettings']},
      {weatherForecast: ['weatherForecastSettings']},
      {disease: ['treeSettings']}
    ],
    rehydrate: true
  })(reducer);
}

export const infinityReducers: Array<string> = [
  'userSelectedStation',
  'userSelectedCropZone',
  'treeSettings',
  'weatherForecastSettings'
];

export function logout(reducer): any {
  return function (state, action): any {
    if (action.type === MetaActionsTypes.LOGOUT) {
      Object.keys(state).forEach((key) => {
        Object.keys(state[key]).forEach((childKey) => {
          state[key][childKey] = infinityReducers.indexOf(childKey) === -1 ? undefined : state[key][childKey];
        });
      });
    }
    return reducer(state, action);
  };
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer, logout];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TypeaheadModule.forRoot(),
    HttpClientModule,
    StoreModule.forRoot({}, {metaReducers}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.gMapKey,
      libraries: GoogleMapLibraries,
      language: localStorage.getItem('language') || DEFAULT_LANG
    }),
    NgxFaviconModule.forRoot<AvailableCustomFavicons>({
      faviconElementId: 'favicon',
      defaultUrl: 'fieldclimate-favicon.ico',
      custom: customFavicons,
    }),
  ],
  providers: [
    AuthService,
    HttpClient,
    AuthGuard,
    UserTokenRepository,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: DuplicateApiInterceptor, multi: true },
    ObjectTranslator,
    LanguageService,
    TranslateModule,
    TreeService,
    Title,
    ApiCallService,
    ExcelService,
    PictocodeService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
