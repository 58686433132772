import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { formatDate } from '../../../../shared/utils/dateFormat';
import { getStationEvents, setStationEventsError } from '../../actions/station-events';
import { IStationEvent } from '../../models/station-events.models';
import { selectStationEvents, selectStationEventsError, selectStationEventsLoading } from '../../reducers';
import { IEventsByStation, IStationEventsState } from '../../reducers/station-events';

@Component({
  selector: 'app-station-events-content',
  templateUrl: './station-events-content.component.html',
  styleUrls: ['./station-events-content.component.scss']
})
export class StationEventsContentComponent implements OnInit, OnDestroy {
  public destroy$               : Subject<boolean> = new Subject<boolean>();
  public stationEvents          : Array<IStationEvent> = [];

  public isLoading$             : Observable<boolean> = this.stationEventsStore.pipe(
    takeUntil(this.destroy$),
    select(selectStationEventsLoading)
  );
  public isError$               : Observable<boolean> = this.stationEventsStore.pipe(
    takeUntil(this.destroy$),
    select(selectStationEventsError)
  );

  constructor(
    private selectedNavigationStore: Store<INavigationStationState>,
    private stationEventsStore: Store<IStationEventsState>
  ) { }

  public ngOnInit(): void {
    combineLatest([
      this.selectedNavigationStore.pipe(
        select(selectNavigationStation),
        filter((station: IStation): boolean => !!station)
      ),
      this.stationEventsStore.pipe(
        select(selectStationEvents),
      )
    ]).pipe(
      distinctUntilChanged(),
      takeUntil(this.destroy$),
    ).subscribe((result: Array<IStation|IEventsByStation>): void => {
      const stationId = (<IStation>result[0]).name.original;
      const eventsByStation = <IEventsByStation>result[1];
      if (eventsByStation[stationId]) {
        this.stationEvents = eventsByStation[stationId];
        this.stationEventsStore.dispatch(setStationEventsError(false));
      } else {
        this.stationEventsStore.dispatch(getStationEvents({stationId: stationId}));
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public getFormattedDate(date: Date): string {
    return formatDate(date);
  }
}
