<app-form-layout *ngIf="!configuration.activated; else productActivated">
  <div class="product-activation-vws-form">
    <div class="product-activation-vws-form__title">
      <h2>{{ (configuration.name || 'Virtual station information') | translate }}</h2>
    </div>

    <div class="product-activation-vws-form__content">
      <app-product-activation-vws-form
        [configuration]="configuration"
        (positionChanged)="setElevationAndTimezone($event)"
        (submit)="addVirtualStation($event)">
      </app-product-activation-vws-form>
    </div>
  </div>
</app-form-layout>

<ng-template #productActivated>
  <div class="alert alert-success">
    <span class="glyphicon glyphicon-ok-sign"></span>
    {{ 'The Virtual Weather Station license was successfully activated!' | translate }}
  </div>
</ng-template>
