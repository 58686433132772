import { Component, OnInit, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, takeWhile } from 'rxjs/operators';
import { environmentToken } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../../../environments/interfaces/theme';
import { AuthService } from '../../../../services/auth/auth.service';
import { LanguageService } from '../../../../services/translation/language.service';
import { Countries, DEFAULT_LANG } from '../../../../shared/constants';
import { IOptions } from '../../../../shared/interfaces';
import { errorFormAnimation, formRouterAnimation } from '../../../../shared/router.animations';
import { IErrorResponse, IReqistrationRequest } from '../../login.interfaces';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css', '../../auth.css'],
  animations: [formRouterAnimation(), errorFormAnimation()]
})
export class RegisterComponent implements OnInit {
  public registerForm     : FormGroup;
  public languages        : Array<IOptions>;
  public countries        : Array<IOptions> = Countries;
  public accountCreated   : boolean = false;
  public usernameExist   : boolean = false;
  public errorMsg        : string = '';
  public siteKey          : string;
  public errorAnimation   : boolean = false;
  private alive           : boolean = true;
  public disableForm      : boolean = false;
  public themeConfig      : IThemeConfig;

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    private formBuilder: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private languageService: LanguageService
  ) {

    const EMAIL_REGEXP = new RegExp([
      '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))',
      '@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
    ].join(''));

    this.registerForm = formBuilder.group({
      'username': ['', [Validators.required]],
      'password': ['', [Validators.required, Validators.minLength(5)]],
      'passwordConfirm': ['', [Validators.required, Validators.minLength(5)]],
      'firstName': ['', [Validators.required]],
      'lastName': ['', [Validators.required]],
      'email': ['', [Validators.required, Validators.pattern(EMAIL_REGEXP)]],
      'language': [languageService.languageFromStorage || DEFAULT_LANG, []],
      'country': ['at', []],
      'terms': [false, [Validators.required]],
      'captcha': [false, [Validators.required]]
    });

    switch (window.location.hostname.substring(window.location.hostname.indexOf('.') + 1)) {
      case 'localhost':
        this.siteKey = '6LdzuycUAAAAAPIaj1dQqFbZocjZxnZ3qFBU1MAO';
        break;
      case 'metos.local':
        this.siteKey = '6LcKvScUAAAAAD2BKaucNdn2Njo9oTg9q2L4DHYJ';
        break;
      case 'fieldclimate.com':
        this.siteKey = '6LdF09MUAAAAABTDvjUkFthW9P5njcljB_VnBRX-';
        break;
      default:
        this.siteKey = '6LdF09MUAAAAABTDvjUkFthW9P5njcljB_VnBRX-';
        break;
    }

    this.themeConfig = environment.theme;
    this.languages = environment.languages;
  }

  public ngOnInit(): void {

    this.language.valueChanges.pipe(
      takeWhile(() => this.alive),
      debounceTime(100),
      distinctUntilChanged()
    ).subscribe(lang => this.languageService.setLanguage(lang));

    this.disableForm = false;
  }

  public get username(): AbstractControl {
    return this.registerForm.get('username');
  }

  public get password(): AbstractControl {
    return this.registerForm.get('password');
  }

  public get passwordConfirm(): AbstractControl {
    return this.registerForm.get('passwordConfirm');
  }

  public get firstName(): AbstractControl {
    return this.registerForm.get('firstName');
  }

  public get lastName(): AbstractControl {
    return this.registerForm.get('lastName');
  }

  public get email(): AbstractControl {
    return this.registerForm.get('email');
  }

  public get language(): AbstractControl {
    return this.registerForm.get('language');
  }

  public get country(): AbstractControl {
    return this.registerForm.get('country');
  }

  public get terms(): AbstractControl {
    return this.registerForm.get('terms');
  }

  public get captcha(): AbstractControl {
    return this.registerForm.get('captcha');
  }

  public goToLogin(): void {
    this.router.navigate(['/+login']);
  }

  public showRegisterForm(): void {
    this.accountCreated = false;
    this.disableForm = false;
    this.registerForm.reset({
      'language': DEFAULT_LANG,
      'country': 'at',
      'terms': false,
      'captcha': false
    });
  }

  public resolved(): void {
    this.registerForm.get('captcha').setValue(!this.registerForm.get('captcha').value);
  }

  public register(): void {
    if (this.themeConfig.subDomain !== 'telus') {
      if (this.registerForm.invalid || !this.terms.value || !this.captcha.value) {
        return;
      }
    } else {                                // if subDomain is 'telus', don't show the Agreement section
      if (this.registerForm.invalid) {
        return;
      }
    }

    this.errorAnimation = false;
    this.usernameExist = false;
    this.disableForm = true;
    this.errorMsg = '';

    this.auth.register(this.prepareRequest()).subscribe(() => {
      this.accountCreated = true;
    }, (err: IErrorResponse) => {
      if (err.status === 409) {
        this.usernameExist = true;
      } else {
        this.errorAnimation = true;
        if (err.error && err.error.message) {
          this.errorMsg = err.error.message;
        } else {
          this.errorMsg = 'Registration unavailable at the moment. Please try again later.';
        }
      }

      this.disableForm = false;
    });
  }

  private prepareRequest(): IReqistrationRequest {
    return {
      username: this.username.value,
      password: this.password.value,
      info: {
        name: this.firstName.value,
        lastname: this.lastName.value,
        email: this.email.value
      },
      settings: {
        language: this.language.value
      },
      address: {
        country: this.country.value
      }
    };
  }
}
