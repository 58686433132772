import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { ApiCallService } from '../../../services/api/api-call.service';
import { changeCropzone, getCropZones, setCropZone } from '../../../core/actions/cropzones';
import { GoogleMapService } from '../services/google-map-service';
import { setNotify } from '../../../core/actions/notify';
import { CropzoneNameActionTypes } from '../actions/cropzone-name';
import { setSelectedCropZone } from '../../../core/actions/selectedCropZone';
import { getCoordinates, setCoordinates, TimezoneAndLocationActionTypes } from '../actions/timezone-location';
import { getFields, setField } from '../../../core/actions/fields';
import { getFarms, setFarm } from '../../../core/actions/farms';
import { collectConfigData } from '../../irrimet-config/actions/irrimet-config.action';
import { MainSocket } from '../../../services/sockets/main.socket';


@Injectable()
export class CropzoneConfigEffects {
  constructor(
    private actions$: Actions,
    private api: ApiCallService,
    private googleMap: GoogleMapService,
    private mainSocket: MainSocket
  ) {

  }


  @Effect()
  public saveRename$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CropzoneNameActionTypes.SAVE_CROPZONE_NAME),
    switchMap((action: IActionWithPayload) => this.api.saveRenamedCropzoneName(action.payload).pipe(
      switchMap(() => from([
        changeCropzone(action.payload),
        setSelectedCropZone(action.payload),
        setNotify('Cropzone successfully updated')
      ])),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public saveCropzoneBoundary$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CropzoneNameActionTypes.SAVE_CROPZONE_BOUNDARY),
    switchMap((action: IActionWithPayload) => this.api.saveCropzoneBoundaries(action.payload).pipe(
      switchMap(() => from([
        changeCropzone(action.payload),
        setSelectedCropZone(action.payload),
        setNotify('Cropzone boundaries successfully specified')]
      )),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public noDrawnBoundary$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CropzoneNameActionTypes.NO_DRAWN_BOUNDARY),
    switchMap((action: IActionWithPayload) =>
      from([setNotify(action.payload)])
    )
  );

  @Effect()
  public getLocation$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(TimezoneAndLocationActionTypes.GET_LOCATION),
    switchMap((action: IActionWithPayload) => this.googleMap.getGeocoder(action.payload).pipe(
      switchMap((res: any) => of(getCoordinates(res))),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public getCoordinates$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(TimezoneAndLocationActionTypes.GET_COORDINATES),
    switchMap((action: IActionWithPayload) => from([
      setCoordinates(action.payload)
    ])),
    catchError(() => from([]))
  );

  @Effect()
  public saveFarmName$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CropzoneNameActionTypes.SAVE_FARM_NAME),
    switchMap((action: IActionWithPayload) => this.api.saveRenamedFarmName(action.payload).pipe(
      switchMap(() => from([
        changeCropzone(action.payload),
        getFarms(),
        setFarm({ id: action.payload.farm.id, ...action.payload.farm }),
        getCropZones(),
        setCropZone({ id: action.payload.id, ...action.payload }),
        setSelectedCropZone(action.payload)
      ])),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public saveFieldName$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CropzoneNameActionTypes.SAVE_FIELD_NAME),
    switchMap((action: IActionWithPayload) => this.api.saveRenamedFieldName(action.payload).pipe(
      switchMap(() => {
        const field = {
          id: action.payload.field.id,
          name: action.payload.field.name,
          farm: {
            name: action.payload.farm.name,
            id: action.payload.farm.id
          }
        };

        return from([
          changeCropzone(action.payload),
          getFields(),
          setField(field),
          getCropZones(),
          setCropZone(action.payload),
          setSelectedCropZone(action.payload)
        ]);
      }),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public shareCropzone$ = this.actions$.pipe(
    ofType(
      CropzoneNameActionTypes.SAVE_CROPZONE_BOUNDARY,
      CropzoneNameActionTypes.SAVE_CROPZONE_NAME,
      CropzoneNameActionTypes.SAVE_FARM_NAME,
      CropzoneNameActionTypes.SAVE_FIELD_NAME
    ),
    tap((action: IActionWithPayload) =>
      this.mainSocket.dispatch('cropzone.change', JSON.stringify(action.payload))
    ),
    map(() => collectConfigData(false)),
    catchError(() => of(''))
  );
}

