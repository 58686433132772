import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { setNotify } from '../../../../core/actions/notify';
import * as fromAccount from '../../../../core/reducers/account';
import * as fromNotify from '../../../../core/reducers/notify';
import { cardAnimation } from '../../../../shared/animations/card-animations';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { LicenseType } from '../../../product-activation/constants/constants';
import {
  activateStation,
  deleteSettings,
  getAssets,
  loadLicenses,
  loadSensorMap,
  loadSettings,
  saveSensorMap,
  saveSettings,
  updateSync
} from '../../actions/davis';
import { IDvAsset, IDvSensorMap, IDvSettings, IState } from '../../models/davis';
import { selectAssets, selectSensorMap, selectSettings } from '../../selectors/davis';

@Component({
  selector: 'app-davis',
  templateUrl: './davis.component.html',
  styleUrls: ['./davis.component.scss'],
  animations: [cardAnimation()]
})
export class DavisComponent implements OnInit, OnDestroy {

  public readonly stationsPerPage = 10;
  public licenseType = LicenseType.DAVIS_INGEST;
  public licenseModalId = generateId();

  public form: FormGroup;
  public destroy$ = new Subject<boolean>();
  public stationsPageItems$: Observable<IDvAsset[]>;
  public stations$: Observable<IDvAsset[]>;
  public leafThrough$ = new BehaviorSubject(0);
  public formVisible = true;
  public davisModalId: string = generateId();
  public sensorMap = [];
  public davisStationID: number;
  public pesslStationID: string;
  public dialog: string;
  public loadingSensorMap = false;
  public isActiveDavis = false;

  constructor(
    private formBuilder: FormBuilder,
    private davisStore: Store<IState>,
    private accountStore: Store<fromAccount.IAccount>,
    private notifyStore: Store<fromNotify.INotifyState>,
    private modal: ModalService
  ) {

    this.davisStore.dispatch(loadSettings());
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      'APIKey': '',
      'APISecret': ''
    });

    this.davisStore.pipe(
      select(selectSettings),
      takeUntil(this.destroy$),
      tap(data => {
        if (data.APIKey && data.APISecret) {
          this.davisStore.dispatch(getAssets());
          this.davisStore.dispatch(loadLicenses());
        }
      })
    ).subscribe((data: IDvSettings) =>
      this.form.patchValue(data)
    );


    this.stations$ = this.davisStore.pipe(
      select(selectAssets)
    ).pipe(
      map(data => data.stationList)
    );

    this.stationsPageItems$ = combineLatest([
      this.leafThrough$,
      this.stations$
    ]).pipe(
      takeUntil(this.destroy$),
      map(([page, stations]) =>
        stations.slice(page * this.stationsPerPage, (page + 1) * this.stationsPerPage)
      )
    );

    this.davisStore.pipe(
      select(selectSensorMap),
      takeUntil(this.destroy$)
    ).subscribe(data => {
      if (data && data.sensorMap) {
        this.sensorMap = data.sensorMap;
        this.loadingSensorMap = false;
      }
    });
  }

  public openLicenseModal(davisStationID: number): void {
    this.modal.openModal(this.licenseModalId);
    this.davisStationID = davisStationID;
    this.isActiveDavis = true;
  }

  public submitLicense(license: { productKey: string }): void {
    if (license && license.productKey) {
      this.davisStore.dispatch(activateStation(this.davisStationID, license.productKey));
    } else {
      this.notifyStore.dispatch(setNotify('No license found'));
    }

    this.davisStationID = null;
    this.modal.closeModal(this.licenseModalId);
  }

  public cancelLicense(): void {
    this.modal.closeModal(this.licenseModalId);
  }

  public updateSync(status: boolean, station: IDvAsset): any {
    if (station.pesslStationID) {
      this.davisStore.dispatch(updateSync(station.davisStationID, status));
    }
  }

  public saveSettings(): void {
    const data = this.form.value;

    if (data.APIKey && data.APISecret) {
      this.davisStore.dispatch(saveSettings(this.form.value));
    }
  }

  public removeSettings(): void {
    this.davisStore.dispatch(deleteSettings());
  }

  public loadSensorMap(davisStationID: number, pesslStationID: string): void {

    this.loadingSensorMap = true;
    this.pesslStationID = pesslStationID;
    this.davisStationID = davisStationID;

    this.davisStore.dispatch(loadSensorMap(davisStationID));
  }

  public saveSensorMap(sensorMap: IDvSensorMap): void {
    this.davisStore.dispatch(saveSensorMap(sensorMap, this.davisStationID));
    this.sensorMap = [];
  }

  public cancelSensorMap(data: boolean): void {
    if (data) {
      this.sensorMap = [];
    }
  }

  public openModal(dialog: string): void {
    this.dialog = dialog;
    this.modal.openModal(this.davisModalId);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
