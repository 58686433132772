import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as turf from '@turf/turf';
import { Polygon } from '@turf/turf';
import { Chart, Highcharts } from 'angular-highcharts';
import * as d3 from 'd3';
import { Feature } from 'geojson';
import * as html2canvas from 'html2canvas';
import * as moment from 'moment';
import { combineLatest, fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { selectSelectedCropZone } from '../../../../core/reducers';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { updateAmountOfColumns } from '../../actions/amountOfColumns';
import { ndviLegendItems, SerieNames } from '../../consts/index';
import { fittingCurve_ndvi, ndviRes10Selector, ndviSelector, noNdviDataErrorSelector } from '../../selectors';
import { getResolution } from '../../utils';
import { ICropZone } from './../../../../core/models/cropzones';
import { ISelectedCropZoneState } from './../../../../core/reducers/selectedCropZone';
import { stationDataContentAnimations } from './../../../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from './../../../../core/services/left-components-toggler/left-components-toggler.service';
import { ITreeStructure } from './../../../../services/tree/models';
import { TreeService } from './../../../../services/tree/tree.service';
import { ModalService } from './../../../../shared/modal/services/modal.service';
import { getNdviStats, getNdviValRes10 } from './../../actions/ndvi';
import { ILegendItem, INDVI, INdvis, INDVIVal, ISatState } from './../../models/index';
import { CropZoneSatServiceService } from './../../services/crop-zone-sat-service.service';
import * as fromLicenses from './../../../../core/reducers/licenses';
import { selectAvailableLicenses } from './../../../../core/reducers/index';
import { SatelliteWarningService } from './../../services/satellite-warning-service.service';

declare const google: any;

@Component({
  selector: 'app-crop-zone-ndvi',
  templateUrl: './crop-zone-ndvi.component.html',
  styleUrls: ['./crop-zone-ndvi.component.scss'],
  animations: stationDataContentAnimations
})
export class CropZoneNdviComponent implements OnInit, OnDestroy {
  @ViewChild('chartContainer')
  public chartContainer: ElementRef;
  public state$: Observable<string>;
  public tree$: Observable<ITreeStructure>;

  public cropZone$: Observable<ICropZone>;
  public cropZone: ICropZone;
  public ndvis$: Observable<INDVI[]>;
  private resizeObservable$: Observable<Event>;

  public isLoading: boolean = false;

  public cloudThreshold = 0.05;
  private destroy$: Subject<boolean> = new Subject();

  public charts: Chart[] = [];

  public resolution = -1;
  public loading = true;
  public noDataError: boolean = false;

  public DETAIL_MODAL: string = 'DETAIL_MODAL';
  public helpTextModalId: string = generateId();

  public detailDate: string;
  public resolution10mText: string;
  public xDays: number;
  public haveToGetStats: boolean;
  private fittingCurve: any;
  private currentNdvis: any;
  private xValuesOfCloudData: any = [];
  public lastDateError: boolean;
  public ndviMeanData: any;
  public amountOfColumns: number;
  public previousNdvis: any;
  public center: any = {lat: 47.2171, lng: 15.6230};
  public zoom: number = 7;
  public map: any;
  private allNdviItems: ILegendItem[] = ndviLegendItems;
  public bounds: boolean = true;
  private ndviVal: INDVIVal;
  private legendItemClicked: number = null;
  private itemActive: boolean = false;
  private previous: any = {active: false, legendItemCliciked: null};
  public zoomControl: boolean = true;
  public lastLicenseDate: string;

  constructor(
    private selectedCropzoneStore: Store<ISelectedCropZoneState>,
    private satStore: Store<ISatState>,
    private licenseStore: Store<fromLicenses.IProductLicenseState>,
    private treeService: TreeService,
    private leftComponentsToggler: LeftComponentsTogglerService,
    private modalService: ModalService,
    private cropzoneSatService: CropZoneSatServiceService,
    private warningService: SatelliteWarningService
  ) {
  }

  public ngOnInit(): void {
    this.state$ = this.leftComponentsToggler.getStationDataContentState();
    this.tree$ = this.treeService.getCropZoneSatelliteTreeStructure();

    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeObservable$.subscribe(() => {
      if (this.chartContainer) {
        const lazyListWidth = this.chartContainer.nativeElement.offsetWidth - 32;
        const lazyListWrapperWidth = (lazyListWidth / 100) * 90;

        const gap = 10;
        const minW = 225;
        const Wc = lazyListWrapperWidth;
        const N = Math.floor((Wc + gap) / (minW + gap));

        this.satStore.dispatch(updateAmountOfColumns(N));
      }
    });

    this.cropzoneSatService.componentMethodCalled$.pipe(takeUntil(this.destroy$)).subscribe((evt) => {
      let active: boolean;

      if (this.previous.legendItemClicked === evt) {
        if (this.previous.active) {
          this.map.data.revertStyle();
          active = false;
        } else {
          this.fillSelectedLegendColor(evt);
          active = true;
        }
      } else {
        this.fillSelectedLegendColor(evt);
        active = true;
      }

      this.previous.legendItemClicked = evt;
      this.previous.active = active;
    });

    this.charts = this.cropzoneSatService.addNdviChart(this.charts);

    this.satStore.pipe(
      select(noNdviDataErrorSelector),
      debounceTime(350),
      takeUntil(this.destroy$)
    ).subscribe((noDataError) => {
      if (noDataError[this.cropZone.id]) {
        this.noDataError = noDataError[this.cropZone.id];
        this.loading = false;
      }
    });

    this.satStore
      .pipe(select(ndviRes10Selector), takeUntil(this.destroy$))
      .subscribe((arrayOfFullRes: any) => {
        this.previous.legendItemClicked = null;
        this.previous.active = false;

        if (this.cropZone) {
          let ndviVal;
          if (Object.keys(arrayOfFullRes).length > 0) {
            ndviVal = arrayOfFullRes[this.cropZone.id];
          }

          if (ndviVal) {
            this.ndviVal = ndviVal;
            const splittedDate = ndviVal.date.split('-');
            this.detailDate = splittedDate[2] + '-' + splittedDate[1] + '-' + splittedDate[0];
            this.resolution10mText = 'Resolution: 10m';
            this.isLoading = false;

            if (this.map.data) {
              this.map.data.forEach(feature => {
                this.map.data.remove(feature);
              });
            }

            this.updateMap(ndviVal);
            this.styleMap();
          }

          const boundary: Feature<Polygon> = {
            type: 'Feature',
            geometry: this.cropZone.boundary,
            properties: {},
          };

          const insidePolygons = [];
          ndviVal.result.features.forEach(feature => {
            const outsideOfBoundary = turf.difference(feature.geometry, boundary.geometry);

            if (outsideOfBoundary == null) {
              insidePolygons.push(feature);
            } else {
              const insideOfBoundary = turf.difference(feature.geometry, outsideOfBoundary.geometry);
              insideOfBoundary.properties.ndvi = feature.properties.ndvi;
              insidePolygons.push(insideOfBoundary);
            }
          });

          const featureCollection = {
            type: 'FeatureCollection',
            features: insidePolygons
          };

          const featureAreas = [];
          featureCollection.features.forEach(feature => {
            let area = 0;
            area += turf.area(feature.geometry);

            const featureArea = {
              ndvi: feature.properties.ndvi,
              area: area / 10000,
              percentage: 0,
            };
            featureAreas.push(featureArea);
          });

          const fullArea = featureAreas.reduce(
            (accumulator, current) => accumulator + current.area,
            0
          );

          featureAreas.forEach((element) => {
            element.percentage = (element.area / fullArea) * 100;
          });

          this.cropzoneSatService.renderDetailedMap(
            featureCollection,
            boundary,
            featureAreas,
            'NDVI'
          );
        }
      });

    this.cropZone$ = this.selectedCropzoneStore.pipe(
      select(selectSelectedCropZone),
      filter(cropZone => !!cropZone),
      distinctUntilChanged((a, b) => a.id === b.id),
      debounceTime(300),
      tap(cropZone => {
        this.noDataError = false;
        this.loading = true;
        this.resolution = getResolution(cropZone.boundary);
        this.cropZone = cropZone;
        this.resolution10mText = '';
        this.detailDate = '';
        this.haveToGetStats = true;
        d3.select('#map').selectAll('g').remove();
        d3.select('#legend').selectAll('*').remove();
      }),
      takeUntil(this.destroy$)
    );

    this.satStore.pipe(
      select(fittingCurve_ndvi),
      debounceTime(350),
      takeUntil(this.destroy$)
    ).subscribe((fittingCurveX) => {
      this.fittingCurve = fittingCurveX;

      if (this.fittingCurve[this.cropZone.id]) {
        const fittingCurveFromThisCropZone = this.fittingCurve[this.cropZone.id];

        if (fittingCurveFromThisCropZone) {
          this.fitting(fittingCurveFromThisCropZone);

          this.removePlotLines();
          this.addPlotLines(this.getAverage(this.ndviMeanData));

          this.reflow();
        }
      }
    });

    this.ndvis$ = combineLatest([
      this.satStore.pipe(select(ndviSelector)),
      this.licenseStore.pipe(
        select(selectAvailableLicenses),
        filter((licenses) => !!licenses),
        takeUntil(this.destroy$)
      ),
      this.cropZone$
    ]).pipe(
      tap(([ndvis, licenses, cropZone]: [INdvis, any, ICropZone]) => {
        const lazyListWidth = this.chartContainer.nativeElement.offsetWidth - 32;
        const lazyListWrapperWidth = lazyListWidth / 100 * 90;

        const gap = 10;
        const minW = 225;
        const Wc = lazyListWrapperWidth;
        const N = Math.floor((Wc + gap) / (minW + gap));

        this.satStore.dispatch(updateAmountOfColumns(N));
        this.lastDateError = false;

        this.removePlotLines();
        this.removeFittedNdviSeries();

        if (this.fittingCurve) {
          const fittingCurveFromThisCropZone = this.fittingCurve[cropZone.id];

          if (fittingCurveFromThisCropZone) {
            this.fitting(fittingCurveFromThisCropZone);
            this.reflow();
          }
        }

        if (!ndvis[cropZone.id]) {
          this.haveToGetStats = true;
          this.removeFittedNdviSeries();
          this.satStore.dispatch(getNdviStats(cropZone.id, this.resolution));
          this.loading = true;
        } else {
          this.haveToGetStats = false;
        }
      }),
      map(([ndvis, licenses, cropZone]: [any, any, any]) => {
        const selectedLicense: any = licenses.filter((license: any) => license.product_item.key === cropZone.product_key)[0];
        const licenseEndDate = moment(selectedLicense.end_date);
        const satOnlyLicense = this.warningService.setSatelliteWarningMessage(selectedLicense);
        const ndvi = ndvis[cropZone.id];
        this.currentNdvis = ndvi;

        if (ndvi) {
          return ndvi.filter(n => {
            if (satOnlyLicense) {
              return n.cloud.mean <= this.cloudThreshold && moment(n.date).isBefore(licenseEndDate);
            } else {
              return n.cloud.mean <= this.cloudThreshold;
            }
          });
        } else {
          return [];
        }
      }),
      tap(ndvis => {
        if (!this.haveToGetStats && ndvis.length > 0) {
          this.noDataError = false;
          this.loading = false;

          if (this.lastDateOlderThan5(ndvis)) {
            this.lastDateError = true;
          } else {
            this.lastDateError = false;
          }
          ndvis = ndvis.filter(ndvi => !isNaN(ndvi.ndvi.min) && !isNaN(ndvi.ndvi.mean) && !isNaN(ndvi.ndvi.max));
          if (JSON.stringify(ndvis) !== JSON.stringify(this.previousNdvis)) {
            const array = this.updateChart(ndvis);
            this.removePlotLines();
            this.addPlotLines(array);

            this.previousNdvis = ndvis;
          }

          Highcharts.charts.forEach(chart => {
            if (chart) {
              chart.redraw();
            }
          });
        } else if (!this.haveToGetStats && ndvis.length === 0) {
          this.noDataError = true;
          this.loading = false;
        }
        this.reflow();
      }),
      takeUntil(this.destroy$)
    );
  }

  public onMapReady(mapInstance: any): void {
    this.map = mapInstance;
    this.map.setOptions({
      zoomControl: 'true',
      zoomControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT
      }
    });
  }

  public updateMap(ndviVal): void {
    const bounds = new google.maps.LatLngBounds();
    const boundary: Feature<Polygon> = {
      type: 'Feature',
      geometry: this.cropZone.boundary,
      properties: {}
    };
    const insidePolygons = [];
    ndviVal.result.features.forEach(feature => {
      const outsideOfBoundary = turf.difference(feature.geometry, boundary.geometry);

      if (outsideOfBoundary == null) {
        insidePolygons.push(feature);
      } else {
        const insideOfBoundary = turf.difference(feature.geometry, outsideOfBoundary.geometry);
        insideOfBoundary.properties.ndvi = feature.properties.ndvi < -0.2 ? -0.2 : feature.properties.ndvi;
        insideOfBoundary.properties.ndvi = feature.properties.ndvi > 1 ? 1 : feature.properties.ndvi;
        insidePolygons.push(insideOfBoundary);
      }
    });

    const geoJsonObject = {
      type: 'FeatureCollection',
      features: insidePolygons
    };

    this.map.data.addGeoJson(geoJsonObject);

    // @tslint:disable-next-line
    const cropzoneBoundaryPath = this.cropZone.boundary.coordinates[0].map((coords) => ({lat: coords[1], lng: coords[0]}));
    for (const coordinate of cropzoneBoundaryPath) {
      bounds.extend(new google.maps.LatLng(coordinate.lat, coordinate.lng));
    }

    this.bounds = bounds;
  }

  private styleMap(): void {
    const self = this;
    let color: string;

    this.map.data.setStyle(function (feature: any): any {
      const ndviValue = Object.values(feature)
        .filter((prop: any) => prop && prop.ndvi)
        .map((prop: any) => prop.ndvi)[0] || null;

      if (ndviValue !== null) {
        if (ndviValue < -0.2) {
          color = self.allNdviItems[self.allNdviItems.length - 1].color;
        } else {
          self.allNdviItems.forEach((legendItem) => {
            if (ndviValue > legendItem.startValue && ndviValue <= legendItem.endValue) {
              color = legendItem.color;
            }
          });
        }
      }

      return {
        fillColor: color,
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: 'white',
        strokeOpacity: 0.7
      };
    });
  }

  private fillSelectedLegendColor(evt): void {
    this.map.data.revertStyle();
    this.map.data.forEach((feature) => {
      Object.keys(feature).forEach((key) => {
        if (feature[key]) {
          if (feature[key].ndvi < -0.2 && evt === '-0.2') {
            this.map.data.overrideStyle(feature, {
              fillColor: 'blue',
              fillOpacity: 1,
              strokeWeight: 1,
              strokeColor: 'white',
              strokeOpacity: 0.7
            });
          } else {
            const legendItem = this.allNdviItems.filter(item => item.item === evt)[0];
            if (feature[key].ndvi > legendItem.startValue && feature[key].ndvi <= legendItem.endValue) {
              this.map.data.overrideStyle(feature, {
                fillColor: 'blue',
                fillOpacity: 1,
                strokeWeight: 1,
                strokeColor: 'white',
                strokeOpacity: 0.7
              });
            }
          }
        }
      });
    });
  }

  public updateChart(ndvi: any[]): number {
    if (ndvi && ndvi.length) {
      const ndviChart = this.charts[0];

      const data: any = ndvi.slice().reverse().reduce((arr, ndviValue) => {
        const date = Date.parse(ndviValue.date);
        const min = ndviValue.ndvi.min;
        const mean = ndviValue.ndvi.mean;
        const max = ndviValue.ndvi.max;

        arr[0].push([date, max]);
        arr[1].push([date, mean]);
        arr[2].push([date, min]);
        return arr;
      }, [[], [], []]);

      const ndvisAboveThreshold = this.currentNdvis.filter(currentNdviValue => currentNdviValue.cloud.mean > this.cloudThreshold);

      ndvisAboveThreshold.forEach(function (ndviAboveThreshold, index, theArray): void {
        const date = Date.parse(ndviAboveThreshold.date);
        theArray[index] = [date, ndviAboveThreshold.ndvi.mean];
      });

      this.xValuesOfCloudData = ndvisAboveThreshold.map((ndviValAboveThreshold: any) => [ndviValAboveThreshold[0]]);

      const ndviMaxData: any[] = data[0];
      const ndviMeanData: any[] = data[1];
      const ndviMinData: any[] = data[2];

      if (ndviChart.ref) {
        ndviChart.ref.series.forEach((serie, index, theArray) => {
          if (serie.name === SerieNames.MAX_NDVI) {
            theArray[index].data = ndviMaxData;
          } else if (serie.name === SerieNames.MEAN_NDVI) {
            theArray[index].data = ndviMeanData;
          } else if (serie.name === SerieNames.MIN_NDVI) {
            theArray[index].data = ndviMinData;
          }
        });
      } else {
        ndviChart.options.series.forEach((serie, index, theArray) => {
          if (serie.name === SerieNames.MAX_NDVI) {
            theArray[index].data = ndviMaxData;
          } else if (serie.name === SerieNames.MEAN_NDVI) {
            theArray[index].data = ndviMeanData;
          } else if (serie.name === SerieNames.MIN_NDVI) {
            theArray[index].data = ndviMinData;
          }
        });
      }

      this.charts[0] = ndviChart;

      this.reflow();

      this.ndviMeanData = ndviMeanData;
      return this.getAverage(ndviMeanData);
    }
  }

  private getAverage(ndviMeanData: any[]): number {
    if (ndviMeanData) {
      const average = (array) => array.reduce((a, b) => a + b) / array.length;
      const aver = average(ndviMeanData.map((dataa) => dataa[1]));
      const averageValue = this.xValuesOfCloudData.map((dataa) => [dataa[0], aver]);
      return averageValue;
    }
    return null;
  }

  private lastDateOlderThan5(ndvis: any[]): boolean {
    const newestNdviDate = new Date(ndvis[0].date);
    const now = new Date();

    const differenceInTime = now.getTime() - newestNdviDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    if (differenceInDays > 5) {
      return true;
    } else {
      return false;
    }
  }

  private fitting(fittingCurveFromThisCropZone: any[]): void {
    const data = this.getFittingCurveDataFromStore(fittingCurveFromThisCropZone);

    this.removeFittedNdviSeries();
    this.addFittedBioMassSeries();

    if (data.length > 0) {
      if (this.charts[0].ref) {
        if (this.charts[0].ref.series.some(serie => serie.name === SerieNames.FITTED_NDVI)) {
          const index = this.charts[0].ref.series.findIndex(serie => serie.name === SerieNames.FITTED_NDVI);
          this.charts[0].ref.series[index].setData(data);
        }
      }

      if (this.charts[0].options) {
        if (this.charts[0].options.series.some(serie => serie.name === SerieNames.FITTED_NDVI)) {
          const index = this.charts[0].options.series.findIndex(serie => serie.name === SerieNames.FITTED_NDVI);
          this.charts[0].options.series[index].data = data;
        }
      }
    }
  }

  private getFittingCurveDataFromStore(fittingCurveFromThisCropZone: any[]): any {
    const data = [];

    fittingCurveFromThisCropZone.forEach(fitting => {
      data.push([
        moment(this.cropZone.from).valueOf() + (fitting.x * 1000 * 60 * 60 * 24),
        fitting.y
      ]);
    });

    return data;
  }

  private addFittedBioMassSeries(): void {
    if (this.charts[0]) {
      this.charts[0].addSerie({
        type: 'spline',
        name: SerieNames.FITTED_NDVI,
        data: [],
        visible: true,
        color: '#8A9A5B',
        lineWidth: 3,
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 0,
        legendIndex: 0,
        shadow: false,
        marker: {
          enabled: false
        },
        tooltip: {
          valueDecimals: 2
        }
      } as any);
    }
  }

  private addPlotLines(array: any): void {
    const self = this;
    const xPlotLines = [];

    this.xValuesOfCloudData.forEach(element => {
      const value = {
        color: 'orange',
        width: 2,
        value: element,
        id: generateId()
      };

      if (this.charts[0].options) {
        this.charts[0].options.xAxis['plotLines'].push(value);
      } else {
        this.charts[0].ref.xAxis['plotLines'].push(value);
      }

      xPlotLines.push(value);
    });

    this.charts[0].addSerie({
      color: 'orange',
      name: SerieNames.CLOUD_STATUS,
      visible: false,
      legendIndex: 3,
      marker: {
        enabled: true,
        symbol: 'url(../../../../../assets/img/cloudicon.svg)',
        height: 16,
        width: 16
      },
      tooltip: {
        valueDecimals: 2,
        pointFormat: ''
      },
      events: {
        legendItemClick: function (): void {
          if (this.visible) {
            xPlotLines.forEach(element => {
              this.chart.xAxis[0].removePlotLine(element.id);
            });

            const series = this.chart.series;
            series.forEach(serie => {
              if (serie.name === SerieNames.CLOUD_MARKERS) {
                serie.hide();
              }
            });
          } else {
            xPlotLines.forEach(element => {
              this.chart.xAxis[0].addPlotLine(element);
            });

            array.sort((a, b) => a[0] - b[0]);
            const series = this.chart.series;
            series.forEach(serie => {
              if (serie.name === SerieNames.CLOUD_MARKERS) {
                serie.setData(array);
                serie.show();
              }
            });
          }
        }
      }
    } as any);

    this.charts[0].options.xAxis['plotLines'] = [];
  }

  private removePlotLines(): void {
    if (this.charts[0].ref) {
      const series = this.charts[0].ref.series;

      series.forEach((serie, index) => {
        if (serie.name === SerieNames.CLOUD_STATUS) {
          this.charts[0].removeSerie(index);
        }
      });

      if (series.some(serie => serie.name === SerieNames.CLOUD_STATUS)) {
        const index = series.findIndex(serie => serie.name === SerieNames.CLOUD_STATUS);
        this.charts[0].removeSerie(index);
      }

      if (series.some(serie => serie.name === SerieNames.CLOUD_MARKERS)) {
        const index = series.findIndex(serie => serie.name === SerieNames.CLOUD_MARKERS);
        this.charts[0].ref.series[index].data = [];
      }
    }

    if (this.charts[0].options) {
      const series = this.charts[0].options.series;

      if (series.some(serie => serie.name === SerieNames.CLOUD_STATUS)) {
        const index = series.findIndex(serie => serie.name === SerieNames.CLOUD_STATUS);
        this.charts[0].removeSerie(index);
      }
      if (series.some(serie => serie.name === SerieNames.CLOUD_MARKERS)) {
        const index = series.findIndex(serie => serie.name === SerieNames.CLOUD_MARKERS);
        this.charts[0].options.series[index].data = [];
      }
    }
  }

  private removeFittedNdviSeries(): void {
    if (this.charts[0].ref) {
      const series = this.charts[0].ref.series;

      if (series.some(serie => serie.name === SerieNames.FITTED_NDVI)) {
        const index = series.findIndex(serie => serie.name === SerieNames.FITTED_NDVI);
        this.charts[0].removeSerie(index);
      }
    }

    if (this.charts[0].options) {
      const series = this.charts[0].options.series;

      if (series.some(serie => serie.name === SerieNames.FITTED_NDVI)) {
        const index = series.findIndex(serie => serie.name === SerieNames.FITTED_NDVI);
        this.charts[0].removeSerie(index);
      }
    }
  }

  public onSelectedItemChanged(ndvi: INDVI): void {
    this.isLoading = true;
    this.modalService.openModal(this.DETAIL_MODAL);

    const date: any[] = [ndvi.date];
    this.satStore.dispatch(getNdviValRes10(this.cropZone.id, date));
  }

  private reflow(): void {
    Highcharts.charts.forEach(chart => {
      if (chart) {
        chart.reflow();
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public downloadChart(): void {
    Highcharts.charts.forEach(chart => {
      if (chart !== undefined) {
        chart.exportChart({
          type: 'image/png',
          filename: 'ndvi-statistics ' + this.cropZone.name,
          sourceWidth: 1600
        });
      }
    });
  }

  public downloadFullRes(): void {
    document.getElementById('download').style.display = 'none';
    this.zoomControl = false;

    setTimeout(() => {
      // @ts-ignore
      html2canvas(document.getElementById('modalContainer'), { useCORS: true}).then( canvas => {
        const image64 = canvas.toDataURL('image/jpeg');

        const link = document.createElement('a');
        link.download = 'full-resolution-' + this.ndviVal.date;
        link.href = image64;
        link.click();
      });

      document.getElementById('download').style.display = 'block';
      this.zoomControl = true;
    }, 50);
  }

  public openModal(): void {
    this.modalService.openModal(this.helpTextModalId);
  }
}
