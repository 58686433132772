import { Component } from '@angular/core';

@Component({
  selector: 'app-sensor-node-modal',
  templateUrl: './sensor-node-modal.component.html',
  styleUrls: ['./sensor-node-modal.component.scss']
})
export class SensorNodeModalComponent {

}
