import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IOptions } from '../../../../../shared/interfaces';
import { IProductLicense, ISelectedLicense } from '../../../../models/licenses';

@Component({
  selector: 'app-product-license-selector',
  templateUrl: './product-license-selector.component.html',
  styleUrls: ['./product-license-selector.component.scss']
})
export class ProductLicenseSelectorComponent implements OnInit {
  @Input()
  public licenses: Array<IProductLicense>;
  @Input()
  public amountOfCropzones: number = null;
  @Output()
  public selectLicense: EventEmitter<ISelectedLicense> = new EventEmitter<ISelectedLicense>();
  @Output()
  public cancel: EventEmitter<void> = new EventEmitter<void>();

  public form: FormGroup;

  public constructor(private formBuilder: FormBuilder) {}

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      productKey: ['', [Validators.required]]
    });
  }

  public get productKey(): string {
    return this.form.get('productKey').value;
  }

  public getLicenseOptions(): Array<IOptions> {
    // Must select the first available license on the first run
    if (!this.productKey && this.licenses.length > 0 && this.licenses[0]) {
      this.form.setValue({productKey: this.licenses[0].product_item.key});
    }

    if (this.licenses.length > 0) {
      return this.licenses
      .map((license: IProductLicense) => ({
        content: `${license.product_item.name} (${license.product_item.key})`,
        value: license.product_item.key
      }));
    } else {
      return [{content: 'No valid subscription', value: null}];
    }
  }

  public getSelectedOption(): IProductLicense {
    return this.licenses.find((item) =>
      item.product_item.key === this.productKey
    );
  }

  public handleSubmit(): void {
    const selectedLicense = this.form.value;
    this.form.reset();
    this.selectLicense.emit(selectedLicense);
  }

  public handleCancel(): void {
    this.form.reset();
    this.cancel.emit();
  }
}
