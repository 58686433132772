import { ActionTypes } from '../actions/cropzone-missions.action';
import { IActionWithPayload as IPayload } from '../../../core/models/actionWithPayload';
import { ICropzoneMissionState } from '../models/model';

export const initialState: ICropzoneMissionState = {
  soilGuard: [],
  dualex: [],
  mobilab: []
};

export function cropzoneSamplesReducer(state = initialState, action: IPayload): ICropzoneMissionState {
  switch (action.type) {
    case ActionTypes.SET_SOILGUARD_SAMPLES:
      return {
        ...state,
        soilGuard: action.payload
      };
    case ActionTypes.SET_DUALEX_SAMPLES:
      return {
        ...state,
        dualex: action.payload
      };
    case ActionTypes.SET_MOBILAB_SAMPLES:
      return {
        ...state,
        mobilab: action.payload
      };
    case ActionTypes.SET_CROPZONE_SAMPLES:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
