import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { UserApiServicesRoutingModule } from './user-api-services-routing.module';
import { UserApiServicesComponent } from './user-api-services.component';
import { ContentComponent } from './containers/content/content.component';
import { HeaderComponent } from './containers/header/header.component';
import { MyJohnDeereComponent } from './containers/my-john-deere/my-john-deere.component';
import { ApiKeyComponent } from './containers/api-key/api-key.component';
import { FarmbeatsComponent } from './containers/farmbeats/farmbeats.component';
import { XarvioComponent } from './containers/xarvio/xarvio.component';
import { reducers } from './reducers';
import { Effects as JDEffects } from './effects/my-john-deere';
import { Effects as FarmBeatsEffects } from './effects/farmbeats';
import { Effects as XarvioEffects } from './effects/xarvio';
import { Effects as DavisEffects } from './effects/davis';
import { MyJohnDeereModalComponent } from './components/my-john-deere-modal/my-john-deere-modal.component';
import { ApiKeyModalComponent } from './components/api-key-modal/api-key-modal.component';
import { FarmBeatsModalComponent } from './components/farmbeats-modal/farmbeats-modal.component';
import { MyJohnDeereRevokeAccessModalComponent } from './containers/my-john-deere/my-john-deere-revoke-access-modal/my-john-deere-revoke-access-modal.component';
import { XarvioModalComponent } from './components/xarvio-modal/xarvio-modal.component';
import { DavisComponent } from './containers/davis/davis.component';
import { DavisModalComponent } from './components/davis-modal/davis-modal.component';
import { DavisSensorMapComponent } from './containers/davis-sensor-map/davis-sensor-map.component';
import { DavisSensorModalComponent } from './components/davis-sensor-modal/davis-sensor-modal.component';
import { ProductLicenseModule } from '../../core/containers/main-static/product-license/product-license.module';
import { MyJohnDeereMapComponent } from './containers/my-john-deere-map/my-john-deere-map.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    UserApiServicesRoutingModule,
    StoreModule.forFeature('apiServices', reducers),
    EffectsModule.forFeature([JDEffects, FarmBeatsEffects, XarvioEffects, DavisEffects]),
    TranslateModule.forChild(),
    ProductLicenseModule
  ],
  declarations: [
    UserApiServicesComponent,
    ContentComponent,
    MyJohnDeereComponent,
    ApiKeyComponent,
    HeaderComponent,
    MyJohnDeereModalComponent,
    ApiKeyModalComponent,
    FarmbeatsComponent,
    XarvioComponent,
    FarmBeatsModalComponent,
    MyJohnDeereRevokeAccessModalComponent,
    XarvioModalComponent,
    DavisComponent,
    DavisModalComponent,
    DavisSensorMapComponent,
    DavisSensorModalComponent,
    MyJohnDeereMapComponent
  ]
})
export class UserApiServicesModule {
}
