import {Pipe, PipeTransform} from '@angular/core';
import {IStation} from '../../../../core/models/stations';
import {findCropView} from '../../utils/findStations';

@Pipe({
  name: 'cropView'
})
export class CropViewPipe implements PipeTransform {

  public transform(stations: Array<IStation>): Array<IStation> {
    return Array.isArray(stations) ? findCropView(stations)
      : [];
  }

}
