<div class="camera-settings-image"
      *ngIf="form">

  <div class="camera-settings-image__image-container">
    <div [formGroup]="form" class="camera-settings-image__form">
      <img [attr.src]="cam.img">
        <div [formArrayName]="'spots'"
            class="camera-settings-image__spots">
          <app-custom-square-control *ngFor="let control of spots.controls; let i = index"
                                     [ngClass]="'camera-settings-image__' + i"
                                     [formControlName]="i"></app-custom-square-control>
        </div>
    </div>
  </div>
  <div class="camera-settings-image__warning">
    <span class="camera-settings-image__warning__red">{{ 'Red square' | translate}}</span>
    - {{ 'measuring point is turned ON' | translate }} <i>{{ '(Recommended)' | translate }}</i>
    <br />
    {{ 'White square - measuring point is turned OFF' | translate }}
    <div class="alert alert-warning camera-settings-image__step"
         [class.camera-settings-image__step__show]="setShow">
      {{ 'Reset to default (save required)' | translate }}
    </div>
  </div>
  <div class="camera-settings-image__reset">
    <app-custom-button [content]="'Reset to default'"
                       (click)="reset()"></app-custom-button>
  </div>

</div>
