import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-davis-modal',
  templateUrl: './davis-modal.component.html',
  styleUrls: ['./davis-modal.component.scss']
})
export class DavisModalComponent {

  @Input()
  public dialog: string;

}
