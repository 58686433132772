import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccumulatorToolComponent } from './accumulator-tool.component';
import { AccumulatorToolDegreeDaysComponent } from './components/accumulator-tool-degree-days/accumulator-tool-degree-days.component';
import { AccumulatorToolChillingUnitsComponent } from './components/accumulator-tool-chilling-units/accumulator-tool-chilling-units.component';
import { AccumulatorToolRainSumComponent } from './components/accumulator-tool-rain-sum/accumulator-tool-rain-sum.component';
import { AccumulatorToolResolver } from './accumulator-tool.resolver.service';
import { AccumulatorToolAsparagusGrowthModelComponent } from './components/accumulator-tool-asparagus-growth-model/accumulator-tool-asparagus-growth-model.component';


const routes: Routes = [
  { path: '', component: AccumulatorToolComponent, children: [
      { path: 'degree-days',            component: AccumulatorToolDegreeDaysComponent,
                                        resolve: { accumulatorToolResolver: AccumulatorToolResolver}, },
      { path: 'chilling-units',         component: AccumulatorToolChillingUnitsComponent,
                                        resolve: { accumulatorToolResolver: AccumulatorToolResolver}, },
      { path: 'rain-sum',               component: AccumulatorToolRainSumComponent,
                                        resolve: { accumulatorToolResolver: AccumulatorToolResolver}, },
      { path: 'asparagus-growth-model', component: AccumulatorToolAsparagusGrowthModelComponent,
                                        resolve: { accumulatorToolResolver: AccumulatorToolResolver}, }
    ]
  }];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
  providers: [AccumulatorToolResolver]
})
export class AccumulatorToolRoutingModule { }
