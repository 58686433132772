import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { GridOptions } from 'ag-grid';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { DataGridOptions, ExcelExportSettings } from '../../../../shared/constants';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';
import { setActiveSample } from '../../actions/station-sample.action';
import { IStationSampleState } from '../../models/station-sample.model';
import { selectActiveSample } from '../../selectors/station-sample.selector';

@Component({
  selector: 'app-sample-table',
  templateUrl: './sample-table.component.html',
  styleUrls: ['./sample-table.component.scss']
})
export class SampleTableComponent implements OnInit, OnDestroy {

  @Input()
  public isTableActive: boolean = true;

  @Input()
  public columnDefs;

  @Input()
  public set samples(values) {
    this.sampleData = values;
    if (values !== null) {
      setTimeout(() => this.navigateTable());
    }
  }

  public activeSample;
  public sampleData;
  public dataGridOptions: GridOptions = { ...DataGridOptions, enableFilter: true };
  public alive$ = new Subject();


  constructor(
    private exportService: StationDataExportService,
    private stationSampleStore: Store<IStationSampleState>
  ) {

  }

  public ngOnInit(): void {

    this.stationSampleStore.pipe(
      select(selectActiveSample),
      takeUntil(this.alive$),
      filter(_ => _ !== this.activeSample),
      distinctUntilChanged((a, b) => a === b),
      tap(_ => this.activeSample = _)
    ).subscribe((values) => this.navigateTable());

    this.exportService.getExportXLS().pipe(
      takeUntil(this.alive$)
    ).subscribe((data: string) =>
      this.generateXLS(data)
    );
  }

  public setActiveSample(row): void {

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    this.activeSample = row.rowIndex;
    this.stationSampleStore.dispatch(setActiveSample(row.rowIndex));
  }

  public generateXLS(id: string): void {
    const config = {
      ExcelExportSettings,
      fileName: `${id}_device_data`,
      processCellCallback: cell => {

        let data = cell.value;

        if (cell && cell.column && cell.column.colDef.cellRenderer) {
          data = cell.column.colDef.cellRenderer({ value: cell.value });
        }

        return data;
      }
    };

    this.dataGridOptions.api.exportDataAsExcel(config);
  }

  public navigateTable(): void {
    if (this.dataGridOptions && this.dataGridOptions.api) {
      const api = this.dataGridOptions.api;
      if (this.activeSample !== null) {
        api.setFocusedCell(this.activeSample, '0');
        api.ensureIndexVisible(this.activeSample);
        if (api.getRowNode(this.activeSample + '')) {
          api.getRowNode(this.activeSample + '').setSelected(true, true);
        }
      } else {
        api.deselectAll();
      }
    }
  }

  public ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }
}
