<div class="custom-modal"
     *ngIf="isOpen"
     [@modalCardAnimation]
     [class.custom-modal__open]="isOpen">
  <div class="custom-modal__content"
       [ngClass]="size"
       [ngStyle]="styles"
       [appOutClick]="outClick"
       (outClick)="closeModal()">
    <ng-content></ng-content>
  </div>
</div>

<div class="custom-modal-background"
     [@modalBackAnimation]
     *ngIf="isOpen">
</div>
