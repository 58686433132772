import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainRoutingModule} from './main-routing.module';
import {StoreModule} from '@ngrx/store';
import {reducers} from './reducers';
import {ApiCallService} from '../services/api/api-call.service';
import {AccountService} from './effects/account.service';
import {EffectsModule} from '@ngrx/effects';
import {NavigationService} from './services/navigation/navigation.service';
import {LeftComponentsTogglerService} from './services/left-components-toggler/left-components-toggler.service';
import { NavigationGuard } from './services/navigation/navigation.guard';
import { GoogleMapService } from '../modules/station-config/services/google-map.service';

@NgModule({
  imports: [
    CommonModule,
    MainRoutingModule,
    StoreModule.forFeature('account', reducers),
    EffectsModule.forFeature([AccountService])
  ],
  declarations: [],
  providers: [
    ApiCallService,
    AccountService,
    NavigationService,
    NavigationGuard,
    LeftComponentsTogglerService,
    GoogleMapService
  ]
})
export class MainModule { }

