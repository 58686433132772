import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Chart, Highcharts } from 'angular-highcharts';
import { Options } from 'highcharts';
import { Subject } from 'rxjs';
import { addToLocalStorageObject } from '../../../../shared/utils/localStorageSync';
import { IAccumulationCalculationValues } from '../../interfaces/interfaces';

@Component({
  selector: 'app-accumulator-tool-chart',
  templateUrl: './accumulator-tool-chart.component.html',
  styleUrls: ['./accumulator-tool-chart.component.scss']
})
export class AccumulatorToolChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public chartOptions       : Options;
  @Input()
  public chartData          : any;
  @Input()
  public exportChartImg     : boolean;
  @Input()
  public type               : string;
  @Output()
  public hiddenSeries       = new EventEmitter<boolean>();

  public chart              : Chart = null;
  public translatedValues   : IAccumulationCalculationValues;
  public seriesText         : string[] = [];
  public series             : any = [];
  public noData             : boolean = false;
  private noDataSeries      : boolean[] = [];
  private destroy$          : Subject<boolean> = new Subject<boolean>();

  constructor(
    private route           : ActivatedRoute,
  ) { }

  public ngOnInit(): void {
    this.translatedValues = this.route.snapshot.data['accumulatorToolResolver'];

    this.chart = new Chart(this.chartOptions);
    this.getSeriesData(this.type);

    // hide serie, if no positive value is available
    if (this.type !== 'rain') {                                 // we always show Rain chart
      this.series.forEach((serie, i) => {
        const maxValue = serie
          .map(data => data[1])
          .reduce((a, b) => Math.max(a, b));
        this.chart.options.series[i].visible = !!maxValue;
        this.noDataSeries.push(!!maxValue);
        if (this.type === 'asparagusModel4' && i === 2) {       // hide 'critical' serie für Asparagus Model 4
          this.chart.options.series[i].visible = false;
        }
      });

      // don't show chart, if all the series have no positive value
      this.noData = this.noDataSeries.every(visible => visible === false);
      this.noSeries();

      const arr = [];
      this.series.forEach((item, index) => {
        if (this.chart.options.series[index]) {
          arr.push(this.chart.options.series[index]);
          const visible = this.chart.options.series[index].visible === undefined ? true : this.chart.options.series[index].visible;
          addToLocalStorageObject('accumulatorToolTable', this.chart.options.series[index].name, visible);
        }
      });
    } else {
      addToLocalStorageObject('accumulatorToolTable', 'Rain Sum', true);
    }

    if (this.type === 'asparagusModel4') {
      this.addPlotBands();
    }
  }

  public ngOnChanges(): void {
    if (this.chart && this.exportChartImg) {
      Highcharts.charts.forEach((chart, i) => {
        if (chart) {
          chart.exportChart();
        }
      });
    }
  }

  private getSeriesData(type: string): void {
    if (type === 'temp') {
      this.series.push(Array());
      this.series.push(Array());
      this.series.push(Array());
      Object.keys(this.chartData).forEach((date: string) => {
        this.series[0].push([Date.parse(date), Number(this.chartData[date]['degree_hours'])]);
        this.series[1].push([Date.parse(date), Number(this.chartData[date]['degree_days'])]);
        this.series[2].push([Date.parse(date), Number(this.chartData[date]['degree_days_usa'])]);
      });
    } else if (type === 'chilling') {
      this.series.push(Array());
      this.series.push(Array());
      this.series.push(Array());
      this.series.push(Array());
      this.series.push(Array());
      this.series.push(Array());
      Object.keys(this.chartData).forEach((date: string) => {
        this.series[0].push([Date.parse(date), Number(this.chartData[date]['n_hours'])]);
        this.series[1].push([Date.parse(date), Number(this.chartData[date]['n_days'])]);
        this.series[2].push([Date.parse(date), Number(this.chartData[date]['n_days_avg'])]);
        this.series[3].push([Date.parse(date), Number(this.chartData[date]['chill_45'])]);
        this.series[4].push([Date.parse(date), Number(this.chartData[date]['chill_utah'])]);
        this.series[5].push([Date.parse(date), Number(this.chartData[date]['chill_utah_infruitec'])]);
      });
    } else if (type === 'rain') {
      this.series.push(Array());
      Object.keys(this.chartData).forEach((date: string) => {
        this.series[0].push([Date.parse(date), Number(this.chartData[date])]);
      });
    } else if (type === 'asparagusModel1') {
      this.series.push(Array());
      this.series.push(Array());
      this.series.push(Array());
      Object.keys(this.chartData).forEach((date: string) => {
        this.series[0].push([Date.parse(date), Number(this.chartData[date]['model1'])]);
        this.series[1].push([Date.parse(date), 800]);
        this.series[2].push([Date.parse(date), 1000]);
      });
    } else if (type === 'asparagusModel2Start') {
      this.series.push(Array());
      this.series.push(Array());
      Object.keys(this.chartData).forEach((date: string) => {
        this.series[0].push([Date.parse(date), Number(this.chartData[date]['model2Start']['20cm'])]);
        this.series[1].push([Date.parse(date), 500]);
      });
    } else if (type === 'asparagusModel2Long') {
      this.series.push(Array());
      this.series.push(Array());
      this.series.push(Array());
      this.series.push(Array());
      this.series.push(Array());
      Object.keys(this.chartData).forEach((date: string) => {
        this.series[0].push([Date.parse(date), this.chartData[date]['model2Long']['10cm']]);
        this.series[1].push([Date.parse(date), this.chartData[date]['model2Long']['20cm']]);
        this.series[2].push([Date.parse(date), this.chartData[date]['model2Long']['30cm']]);
        this.series[3].push([Date.parse(date), this.chartData[date]['model2Long']['40cm']]);
        this.series[4].push([Date.parse(date), 5000]);
      });
    } else if (type === 'asparagusModel2Short') {
      this.series.push(Array());
      Object.keys(this.chartData).forEach((date: string) => {
        this.series[0].push([Date.parse(date), this.chartData[date]['model2Short']]);
      });
    } else if (type === 'asparagusModel3') {
      this.series.push(Array());
      this.series.push(Array());
      this.series.push(Array());
      this.series.push(Array());
      this.series.push(Array());
      Object.keys(this.chartData).forEach((date: string) => {
        this.series[0].push([Date.parse(date), this.chartData[date]['model3']['air']]);
        this.series[1].push([Date.parse(date), this.chartData[date]['model3']['10cm']]);
        this.series[2].push([Date.parse(date), this.chartData[date]['model3']['20cm']]);
        this.series[3].push([Date.parse(date), this.chartData[date]['model3']['30cm']]);
        this.series[4].push([Date.parse(date), this.chartData[date]['model3']['40cm']]);
      });
    } else if (type === 'asparagusModel4') {
      this.series.push(Array());
      this.series.push(Array());
      this.series.push(Array());
      Object.keys(this.chartData).forEach((date: string) => {
        this.series[0].push([Date.parse(date), this.chartData[date]['model4']['10cm']]);
        this.series[1].push([Date.parse(date), this.chartData[date]['model4']['40cm']]);
        this.series[2].push([Date.parse(date), this.chartData[date]['model4']['critical']]);
      });
    }

    this.series.forEach((series, i) => {
      if (this.chart.ref) {
        this.chart.ref.series[i].setData([series]);
      } else {
        this.chart.options.series[i].data = series;
      }
    });
  }

  public noSeries(): void {
    this.hiddenSeries.emit(this.noData);
  }

  private addPlotBands(): void {
    this.chartOptions.xAxis = [
      {
        crosshair: true,
        gridLineWidth: 1,
        labels: {
          style: {
            fontSize: '12px'
          }
        },
        type: 'datetime',
        plotBands: []
      }
    ];

    this.series[2].forEach((data, i) => {
      if (data[1] === 1) {
        if (this.chart.options) {
          this.chart.options.xAxis[0].plotBands.push(
            {
              color: 'rgba(255, 0, 0, 0.5)',
              from: data[0],
              to: this.series[2][i + 1][0],
            }
          );
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
