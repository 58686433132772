import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { modalBackAnimation, modalCardAnimation } from '../../animations/modal.animations';
import { IModal } from '../../models/modal.interface';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    modalCardAnimation(),
    modalBackAnimation()
  ]
})

export class ModalComponent implements OnInit, IModal, OnDestroy {
  @Input()
  public modalId                  : string;
  @Input()
  public styles                   : string;
  @Input()
  public size                     : string;

  public isOpen                   : boolean = false;

  @Input()
  public outClick                 : boolean = true;

  constructor(private modalService: ModalService) { }

  public ngOnInit(): void {
    this.modalService.addModal(this.modalId, this);
  }

  public closeModal(): void {
    this.isOpen = false;
  }

  public openModal(): void {
    setTimeout(() => {
      this.isOpen = true;
    }, 0);
  }

  public ngOnDestroy(): void {
    this.modalService.deleteModal(this.modalId);
  }

}
