import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IWaterBalanceState } from '../models/models';
import { waterBalanceReducer } from './irrimet-balance.reducer';
import { IIrrimetEventsState, irrimetReducer } from './irrimet.reducer';

export interface IState {
  waterBalance: IWaterBalanceState;
  irrigationEvents: IIrrimetEventsState;
}

export const reducers = {
  waterBalance: waterBalanceReducer,
  irrigationEvents: irrimetReducer
};

export const irrimet = createFeatureSelector<IState>('irrimet');

export const selectIrrigationEvents = createSelector(
  irrimet,
  (irrimetState: IState) => irrimetState.irrigationEvents.irrigationEvents
);

export const selectCalendarEvents = createSelector(
  irrimet,
  (irrimetState: IState) => irrimetState.irrigationEvents.calendarEvents
);

export const selectDwbTableActive = createSelector(
  irrimet,
  (irrimetState: IState) => irrimetState.waterBalance.isTableActive
);

export const selectViewDate = createSelector(
  irrimet,
  (irrimetState: IState) => irrimetState.irrigationEvents.viewDate
);
