import {Pipe, PipeTransform} from '@angular/core';
import {IStation} from '../../../../core/models/stations';
import {IDisease} from '../../../../core/models/diseases.models';

@Pipe({
  name: 'diseaseStation'
})
export class DiseaseStationPipe implements PipeTransform {

  public transform(station: IStation, diseases: Array<IDisease>): any {
    if (!station || !station.licenses || !Array.isArray(diseases)) {
      return [];
    }
    if (!station.licenses.models) {
      return [];
    }
    return diseases.filter((d: IDisease) => {
      return station.licenses.models.indexOf(d.group) !== -1;
    });
  }

}
