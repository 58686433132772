import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from '../../../../node_modules/rxjs/operators';
import { selectSelectedCropZone } from '../../core/reducers';
import * as fromSelectedCropzone from '../../core/reducers/selectedCropZone';
import { environmentToken } from '../../../environments/environment';
import { IEnvironment } from '../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../environments/interfaces/theme';

@Component({
  selector: 'app-selected-cropzone',
  templateUrl: './selected-cropzone.component.html',
  styleUrls: ['./selected-cropzone.component.scss']
})
export class SelectedCropzoneComponent implements OnInit, OnDestroy {

  public infoIsOpen         : boolean = false;
  public cropzoneName       : string;
  public fieldName          : string;
  public farmName           : string;
  public crop               : string;
  public year               : string;
  private destroy$          : Subject<void> = new Subject<void>();
  public theme              : IThemeConfig;

  constructor(
    @Inject(environmentToken) environment   : IEnvironment,
    private selectedCropzoneStore           : Store<fromSelectedCropzone.ISelectedCropZoneState>
  ) {
    this.theme = environment.theme;
  }

  public ngOnInit(): void {
    this.selectedCropzoneStore.pipe(
      select(selectSelectedCropZone),
      takeUntil(this.destroy$)
    ).subscribe((selectedCropzone) => {
      this.cropzoneName = selectedCropzone.name;
      this.fieldName = selectedCropzone.field.name;
      this.farmName = selectedCropzone.farm.name;
      this.crop = selectedCropzone.crop_name;
      this.year = new Date(selectedCropzone.from).getFullYear().toString();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }
}
