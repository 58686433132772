import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { IAccumulationCalculationValues } from '../../interfaces/interfaces';

@Component({
  selector: 'app-accumulator-tool-table',
  templateUrl: './accumulator-tool-table.component.html',
  styleUrls: ['./accumulator-tool-table.component.scss']
})
export class AccumulatorToolTableComponent implements OnInit, OnDestroy {

  @Input()
  public response           : any = null;
  @Input()
  public unitSystem         : string = '';

  public translatedValues   : IAccumulationCalculationValues;
  public headers            : string[] = [];
  public values             : string[] = [];
  private destroy$          : Subject<boolean> = new Subject<boolean>();

  constructor(
    private route           : ActivatedRoute,
  ) { }

  public ngOnInit(): void {
    this.translatedValues = this.route.snapshot.data['accumulatorToolResolver'];

    this.getHeaders(this.response.type);
    this.getValues(this.response.type);
  }

  private getHeaders(type: string): void {
    if (type === 'temp') {
      this.headers.push(this.translatedValues['degreeHours']);
      this.headers.push(this.translatedValues['degreeDays']);
      this.headers.push(this.translatedValues['degreeDaysUsa']);
    } else if (type === 'chilling') {
      this.headers.push(this.translatedValues['nHours']);
      this.headers.push(this.translatedValues['nDays']);
      this.headers.push(this.translatedValues['nDaysUsa']);
      this.headers.push(this.unitSystem === 'imperial' ?
        this.translatedValues['chill45'] + ' < 45°F' :
        this.translatedValues['chill45'] + ' < 7.2°C');
      this.headers.push(this.translatedValues['chillUtah']);
      this.headers.push(this.translatedValues['chillUtahInfruitec']);
    } else if (type === 'rain') {
      this.headers.push(this.translatedValues['rainSum']);
    }
  }

  private getValues(type: string): void {
    if (type === 'temp') {
      this.values.push(this.response['degree_hours']);
      this.values.push(this.response['degree_days']);
      this.values.push(this.response['degree_days_usa']);
    } else if (type === 'chilling') {
      this.values.push(this.response['n_hours']);
      this.values.push(this.response['n_days']);
      this.values.push(this.response['n_days_avg']);
      this.values.push(this.response['chill_45']);
      this.values.push(this.response['chill_utah']);
      this.values.push(this.response['chill_utah_infruitec']);
    } else if (type === 'rain') {
      this.values.push(this.response['rain']);
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

