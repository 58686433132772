import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { setNotify } from '../../../../core/actions/notify';
import * as fromNotify from '../../../../core/reducers/notify';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { dateToUtcUnixTimestamp } from '../../../../shared/utils/dateFormat';
import { cropTypeOptions } from '../../../yield-prediction-config/constants/config';
import { IPreviousYieldRequest } from '../../models/model';

@Component({
  selector: '[app-previous-yield-row]',
  templateUrl: './previous-yield-row.component.html',
  styleUrls: ['./previous-yield-row.component.scss']
})
export class PreviousYieldRowComponent implements OnInit, OnDestroy {

  @Input()
  public yield: any;
  @Output()
  public actionClicked = new EventEmitter<string>();

  public yieldForm: FormGroup;
  public cropTypeOptions = cropTypeOptions;
  public maximumDate: Date = new Date(new Date().getFullYear() + 1, 11, 31);
  private alive$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private apiCallService: ApiCallService,
    private notifyStore: Store<fromNotify.INotifyState>,
  ) { }

  public get yieldControl(): AbstractControl {
    return this.yieldForm.get('value');
  }

  public ngOnInit() : void {
    if (this.yield && this.yield._id) {
      this.yieldForm = this.formBuilder.group({
        crop: [this.yield.crop, Validators.required],
        harvest_date: [this.yield.harvest_date.date, Validators.required],
        sowing_date: [this.yield.sowing_date.date, Validators.required],
        value: [this.yield.value, Validators.required],
      });
    }
  }

  public updatePreviousYield() : void {
    if (this.yieldForm.invalid) {
      return;
    }
    const updateRequest : IPreviousYieldRequest = {
      sowing_date: dateToUtcUnixTimestamp(this.yieldForm.value.sowing_date),
      harvest_date: dateToUtcUnixTimestamp(this.yieldForm.value.harvest_date),
      field_name: this.yield.field_name,
      crop: this.yieldForm.value.crop,
      value: Number(this.yieldControl.value),
    };
    this.apiCallService.updatePreviousYield(this.yield._id, updateRequest).pipe(
      takeUntil(this.alive$),
      tap(),
    ).subscribe(
      () => {
        this.notifyStore.dispatch(setNotify('Previous Yield is successfully updated.')),
        this.refreshPreviousYields();
      },
      () => this.notifyStore.dispatch(setNotify('Error in updating the previous yield data.'))
    );
  }

  public deletePreviousYield() : void {
    this.apiCallService.deletePreviousYield(this.yield._id).subscribe(response => {
      this.refreshPreviousYields();
    });
  }

  public validateDecimal(event) : void {
    const value = event.target.value;
    this.yieldControl.setValue(Number(Math.abs(value * 100) / 100).toFixed(2));
  }

  public refreshPreviousYields() : void {
    this.actionClicked.emit('refresh');
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }
}
