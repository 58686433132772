import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FrostProtectionComponent } from './containers/frost-protection/frost-protection.component';
import { FrostProtectionResolver } from './frost-protection.resolver.service';

const routes: Routes = [
  { path: '', component: FrostProtectionComponent,
    resolve: { frostProtectionResolver: FrostProtectionResolver}, }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule],
  providers: [FrostProtectionResolver]
})
export class FrostProtectionRoutingModule { }
