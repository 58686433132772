import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {ApiCallService} from '../../../services/api/api-call.service';
import {catchError, switchMap} from 'rxjs/operators';
import {from} from 'rxjs';
import {Observable} from 'rxjs';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {
  setStationCommunication,
  setStationCommunicationError,
  setStationCommunicationLoading,
  StationCommunicationActionTypes
} from '../actions/station-communication';
import {ISetStationCommunicationPayload, IStationCommunication} from '../models/station-communication.models';

@Injectable()
export class StationCommunicationService {

  constructor(private api: ApiCallService, private actions$: Actions) { }

  @Effect()
  public getStationCommunication$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(StationCommunicationActionTypes.GET_STATION_COMMUNICATION),
    switchMap((action: IActionWithPayload) => {
      return this.api.getStationCommunication(action.payload).pipe(
        switchMap((communicationResponse: Array<IStationCommunication>) => {
          const setStationCommunicationPayload: ISetStationCommunicationPayload = {
            stationId: action.payload.stationId,
            mode: action.payload.mode,
            stationCommunication: communicationResponse
          };
          return from ([
            setStationCommunication(setStationCommunicationPayload),
            setStationCommunicationLoading(false),
            setStationCommunicationError(!communicationResponse.length)
          ]);
        }),
        catchError(() => from([
          setStationCommunicationError(true),
          setStationCommunicationLoading(false)
        ]))
      );
    }),
  );
}
