import * as moment from 'moment';

export function formatDate(date: Date): string {
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

export function formatZuluDate(date: Date): string {
  return moment(date).format('YYYY-MM-DDThh:mm:ssZ');
}

export function formatDateNoTime(date: Date): string {
  return moment(date).format('YYYY-MM-DD');
}

const minuteTime: number = 60;

export function dateToUtcUnixTimestamp(date: Date): number {
  return moment(date).utcOffset() * minuteTime + moment(date).unix();
}

export function momentToUtcUnixTimestamp(momentObject: moment.Moment): number {
  return momentObject.utcOffset() * minuteTime + momentObject.unix();
}

export function getMonthFromDate(date: moment.Moment): string {
  return date.format('YYYY-MM');
}

export function getWeekFromDate(date: moment.Moment): string {
  return date.format('YYYY') + '-W' + date.format('WW');
}

export function getCameraFilterDate(date: string): string {
  const aux = moment(date || undefined);
  if (date.includes('W')) {
    return getWeekFromDate(aux);
  }
  return getMonthFromDate(aux);
}

export function getDateForCameraPicture(date: string): string {
  if (!date) {
    return '';
  }
  if (!date.includes('W')) {
    return date;
  }
  const dateChunks = date.split('-');
  const weekNumber = dateChunks[1].replace('W', '');
  const yearNumber = dateChunks[0];
  return `week ${weekNumber} in ${yearNumber}`;
}

export function getWeekForCameraPicture(date: Date): string {
  const weekDate = getWeekFromDate(moment(date));
  return getDateForCameraPicture(weekDate);
}

export function getDaysFromPeriodValue(periodValue: string): number {
  switch (periodValue) {
    case '24h' : return 1;
    case '2d' : return 2;
    case '7d' : return 7;
    case '10d' : return 10;
    case '14d' : return 14;
    case '30d' : return 30;
  }
}
