import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { IStationDataState } from '../reducers/station-data';
import { IFromTo } from '../../tracker/models/tracker.models';
import * as moment from 'moment';
import { IStationDataWarningMessage } from '../models/station-data.models';
import { setStationDataWarningMessage } from '../actions/station-data';

@Injectable({
  providedIn: 'root'
})
export class StationDataService {
  private pleaseNoteString: string;
  private daysToRenew: string;
  private licenseExpiredOn: string;
  private toRenew: string;

  constructor(
    private translate: TranslateService,
    private store: Store<IStationDataState>
  ) {
    this.getTranslatedStrings();
  }

  public setVWSWarning(licenses: any, deviceID: number): void {
    if (deviceID === 100) {
      if (licenses['VWS']) {
        const warningObject: IStationDataWarningMessage = {
          message: null,
          icon: true
        };

        const maximumDates: Array<any> = licenses['VWS'].map((license) => moment(license.to));
        const licenseEndDate: moment.Moment = moment.max(maximumDates);
        const now: moment.Moment = moment();
        const daysUntilEndLicense = licenseEndDate.diff(now, 'days');

        if (daysUntilEndLicense <= 0) {
          warningObject.message = this.licenseExpiredOn + ' ' + licenseEndDate.format('DD-MM-YYYY') + '. ' + this.toRenew;
        } else if (daysUntilEndLicense <= 10) {
          warningObject.message = this.pleaseNoteString + ' ' + daysUntilEndLicense + ' ' + this.daysToRenew;
        }

        this.store.dispatch(setStationDataWarningMessage(warningObject));
      }
    }
  }

  private getTranslatedStrings(): void {
    this.translate.get('Your license will expire in').pipe(take(1)).subscribe((translatedText: string) => {
      this.pleaseNoteString = translatedText;
    });
    this.translate.get('days. To renew it, please contact your METOS salesperson.').pipe(take(1)).subscribe((translatedText: string) => {
      this.daysToRenew = translatedText;
    });
    this.translate.get('Your license has expired on').pipe(take(1)).subscribe((translatedText: string) => {
      this.licenseExpiredOn = translatedText;
    });
    this.translate.get('To renew it, please contact your METOS salesperson.').pipe(take(1)).subscribe((translatedText: string) => {
      this.toRenew = translatedText;
    });
  }
}
