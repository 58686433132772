import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { IServerAlert, IServerAlertData } from '../interfaces/server-alert';
import { ISensor } from '../../../shared/interfaces';

export enum ActionTypes {
  GET_STATION_SENSORS = '[SERVER_ALERTS] GET_STATION_SENSORS',
  SET_STATION_SENSORS = '[SERVER_ALERTS] SET_STATION_SENSORS',
  GET_SERVER_ALERTS = '[SERVER_ALERTS] GET_SERVER_ALERTS',
  SET_SERVER_ALERTS = '[SERVER_ALERTS] SET_SERVER_ALERTS',
  ADD_SERVER_ALERT = '[SERVER_ALERTS] ADD_SERVER_ALERT',
  APPEND_SERVER_ALERT = '[SERVER_ALERTS] APPEND_SERVER_ALERT',
  UPDATE_SERVER_ALERT = '[SERVER_ALERTS] UPDATE_SERVER_ALERT',
  DELETE_SERVER_ALERT= '[SERVER_ALERTS] DELETE_SERVER_ALERT'
}

export function getStationSensors(payload: string): IAction {
  return { type: ActionTypes.GET_STATION_SENSORS, payload };
}

export function setStationSensors(payload: ISensor[]): IAction {
  return { type: ActionTypes.SET_STATION_SENSORS, payload };
}

export function getServerAlerts(payload: string): IAction {
  return { type: ActionTypes.GET_SERVER_ALERTS, payload };
}

export function setServerAlerts(payload: IServerAlert[]): IAction {
  return {  type: ActionTypes.SET_SERVER_ALERTS, payload };
}

export function addServerAlert(payload: IServerAlertData): IAction {
  return { type: ActionTypes.ADD_SERVER_ALERT, payload };
}

export function appendServerAlert(payload: IServerAlertData): IAction {
  return { type: ActionTypes.APPEND_SERVER_ALERT, payload };
}

export function updateServerAlert(payload: IServerAlertData): IAction {
  return { type: ActionTypes.UPDATE_SERVER_ALERT, payload };
}

export function deleteServerAlert(payload: IServerAlertData): IAction {
  return { type: ActionTypes.DELETE_SERVER_ALERT, payload };
}
