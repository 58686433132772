import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IIscoutGlueBoard, IIscoutGlueBoardAvailableDates, IIscoutGlueBoardFormAction} from '../models/iscout.models';
import {IDeleteGlueBoardBody} from '../../../shared/camera/models/camera';

export const enum IscoutGlueBoardActionTypes {
  GET_ISCOUT_GLUE_BOARDS = '[IscoutGlueBoard] GET_ISCOUT_GLUE_BOARDS',
  SET_ISCOUT_GLUE_BOARDS = '[IscoutGlueBoard] SET_ISCOUT_GLUE_BOARDS',
  GET_ISCOUT_GLUE_BOARD_AVAILABLE_DATES = '[IscoutGlueBoard] GET_ISCOUT_GLUE_BOARD_AVAILABLE_DATES',
  SET_ISCOUT_GLUE_BOARD_AVAILABLE_DATES = '[IscoutGlueBoard] SET_ISCOUT_GLUE_BOARD_AVAILABLE_DATES',
  SET_ISCOUT_GALLERY_GLUE_BOARD_SELECTION = '[IscoutGlueBoard] SET_ISCOUT_GALLERY_GLUE_BOARD_SELECTION',
  SELECT_ISCOUT_GLUE_BOARD = '[IscoutGlueBoard] SELECT_ISCOUT_GLUE_BOARD',
  UNSELECT_ISCOUT_GLUE_BOARD = '[IscoutGlueBoard] UNSELECT_ISCOUT_GLUE_BOARD',
  SAVE_ISCOUT_GLUE_BOARD = '[IscoutGlueBoard] SAVE_ISCOUT_GLUE_BOARD',
  UPDATE_ISCOUT_GLUE_BOARD = '[IscoutGlueBoard] UPDATE_ISCOUT_GLUE_BOARD',
  EXCHANGE_ISCOUT_GLUE_BOARD = '[IscoutGlueBoard] EXCHANGE_ISCOUT_BOARD',
  REMOVE_ISCOUT_GLUE_BOARD = '[IscoutGlueBoard] REMOVE_ISCOUT_GLUE_BOARD'
}

export function getIscoutGlueBoards(stationId: string): IActionWithPayload {
  return {type: IscoutGlueBoardActionTypes.GET_ISCOUT_GLUE_BOARDS, payload: stationId};
}

export function setIscoutGlueBoard(glueBoards: Array<IIscoutGlueBoard>): IActionWithPayload {
  return {type: IscoutGlueBoardActionTypes.SET_ISCOUT_GLUE_BOARDS, payload: glueBoards};
}

export function getIscoutGlueBoardAvailableDates(stationId: string): IActionWithPayload {
  return {type: IscoutGlueBoardActionTypes.GET_ISCOUT_GLUE_BOARD_AVAILABLE_DATES, payload: stationId};
}

export function setIscoutGlueBoardAvailableDates(availableDates: IIscoutGlueBoardAvailableDates): IActionWithPayload {
  return {type: IscoutGlueBoardActionTypes.SET_ISCOUT_GLUE_BOARD_AVAILABLE_DATES, payload: availableDates};
}

export function setIscoutGalleryGlueBoardSelection(glueBoardId: string): IActionWithPayload {
  return {type: IscoutGlueBoardActionTypes.SET_ISCOUT_GALLERY_GLUE_BOARD_SELECTION, payload: glueBoardId};
}

export function selectIscoutGlueBoard(glueBoard: IIscoutGlueBoardFormAction): IActionWithPayload {
  return {type: IscoutGlueBoardActionTypes.SELECT_ISCOUT_GLUE_BOARD, payload: glueBoard};
}

export function unselectIscoutGlueBoard(): IActionWithPayload {
  return {type: IscoutGlueBoardActionTypes.UNSELECT_ISCOUT_GLUE_BOARD, payload: null};
}

export function saveIscoutGlueBoard(glueBoard: IIscoutGlueBoard): IActionWithPayload {
  return {type: IscoutGlueBoardActionTypes.SAVE_ISCOUT_GLUE_BOARD, payload: glueBoard};
}

export function updateIscoutGlueBoard(glueBoard: IIscoutGlueBoard): IActionWithPayload {
  return {type: IscoutGlueBoardActionTypes.UPDATE_ISCOUT_GLUE_BOARD, payload: glueBoard};
}

export function exchangeIscoutGlueBoard(glueBoard: IIscoutGlueBoard): IActionWithPayload {
  return {type: IscoutGlueBoardActionTypes.EXCHANGE_ISCOUT_GLUE_BOARD, payload: glueBoard};
}

export function removeIscoutGlueBoard(glueBoardId: IDeleteGlueBoardBody): IActionWithPayload {
  return {type: IscoutGlueBoardActionTypes.REMOVE_ISCOUT_GLUE_BOARD, payload: glueBoardId};
}
