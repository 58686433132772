import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YieldPredictionPreviousComponent } from './yield-prediction-previous.component';
import { YieldPredictionPreviousRoutingModule } from './yield-prediction-previous-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { reducers } from '../yield-prediction-config/reducers';
import { YieldConfigEffect } from '../yield-prediction-config/effects/yield-config.effect';
import { EffectsModule } from '@ngrx/effects';
import { YieldPreviousDataComponent } from './containers/yield-previous-data/yield-previous-data.component';
import { PreviousYieldGraphComponent } from './containers/previous-yield-graph/previous-yield-graph.component';
import { PreviousYieldRowComponent } from './containers/previous-yield-row/previous-yield-row.component';
import { PreviousYieldHelpTextComponent } from './containers/previous-yield-help-text/previous-yield-help-text.component';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ChartModule,
    YieldPredictionPreviousRoutingModule,
    TranslateModule.forChild(),
    StoreModule.forFeature('yield-config', reducers),
    EffectsModule.forFeature([YieldConfigEffect]),
  ],
  declarations: [
    YieldPredictionPreviousComponent,
    YieldPreviousDataComponent,
    PreviousYieldGraphComponent,
    PreviousYieldRowComponent,
    PreviousYieldHelpTextComponent
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [ more, exporting ] }, // add as factory to your providers
  ],
})
export class YieldPredictionPreviousModule { }
