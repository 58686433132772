import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IStationSettingsPeriod } from '../../../../shared/interfaces';
import { dateTypeFromPeriod } from '../../../../shared/utils/dateTypeFromPeriod';
import { IHydroponicsState } from '../../models/models';
import { hydroponicsPeriod } from '../../selectors/selectors';

@Component({
  selector: 'app-hydroponics-header',
  templateUrl: './hydroponics-header.component.html',
  styleUrls: ['./hydroponics-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class HydroponicsHeaderComponent implements OnInit, OnDestroy {
  public period$              : Observable<IStationSettingsPeriod>;

  @Output()
  public openModal            : EventEmitter<void> = new EventEmitter();

  public readonly format      : string = 'yyyy-MM-dd HH:mm';
  private destroy$            : Subject<boolean> = new Subject<boolean>();

  constructor(
    private hydroponicsStore  : Store<IHydroponicsState>,
) { }

  public ngOnInit(): void {
    this.period$ = this.hydroponicsStore.pipe(
      select(hydroponicsPeriod),
      takeUntil(this.destroy$)
    );
  }

  public prepareDate(date: string): string {
    return `${parseInt(date, 10)} ${dateTypeFromPeriod(date)}`;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
