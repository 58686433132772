<section>
  <ng-container *ngIf="!(isLoading$ | async); else loadInfo">
    <app-iscout-pests-list
      *ngIf="(selectedPest$ | async) === null"
      [displaySystemPests]="displaySystemPests"
      (displaySystemPestsToggled)="handleSystemPestToggle($event)"
      (pestAdded)="selectPestCreate()"
      (pestEdited)="selectPestUpdate($event)"
      (pestRemoved)="handlePestDelete($event)"
    ></app-iscout-pests-list>

    <app-iscout-pests-form
      *ngIf="(selectedPest$ | async) !== null"
      [formAction]="(selectedPest$ | async)"
      (pestSaved)="handlePestSave($event)"
    ></app-iscout-pests-form>
  </ng-container>

  <ng-template #loadInfo>
    <app-station-load-info [isLoading]="isLoading$ | async"></app-station-load-info>
  </ng-template>
</section>
