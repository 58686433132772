import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { IDisease, IDiseaseModel, IDiseaseModelSettings } from '../../../../core/models/diseases.models';
import { IStation } from '../../../../core/models/stations';
import { selectDiseases, selectNavigationStation } from '../../../../core/reducers';
import * as fromDiseases from '../../../../core/reducers/diseases';
import * as fromNavigationStation from '../../../../core/reducers/navigation-station';
import { LeftComponentsTogglerService } from '../../../../core/services/left-components-toggler/left-components-toggler.service';
import { ITreeStructure } from '../../../../services/tree/models';
import { TreeService } from '../../../../services/tree/tree.service';
import { IStationSettingsPeriod } from '../../../../shared/interfaces';
import { IStationTreeSettings, ITreeSettingsState } from '../../../../shared/tree/models/tree.models';
import { dateToUtcUnixTimestamp } from '../../../../shared/utils/dateFormat';
import { IFromTo } from '../../../station-data/models/station-data.models';
import {
  getStationDiseaseData,
  getStationDiseaseLicenses, setStationDiseaseLoadingProgress,
  setStationDiseaseWarnings
} from '../../actions/station-disease';
import {
  getStationDiseaseFireBlight,
  setStationDiseaseFireBlight,
  setStationDiseaseFireBlightActive, setStationDiseaseRights, setStationDiseaseSettingsFromTo, setStationDiseaseSettingsPeriod,
  setStationDiseaseSettingsPeriodScope
} from '../../actions/station-disease-settings';
import { FIRE_BLIGHT } from '../../constants/constants';
import { IDiseaseLicenses, IDiseaseWarning } from '../../models/station-disease.models';
import {
  selectStationDiseaseLicenses,
  selectStationDiseaseLoading,
  selectStationDiseaseSettingsExportActive,
  selectStationDiseaseSettingsPeriod, selectStationDiseaseWarnings, selectTreeSettings
} from '../../reducers';
import * as fromDisease from '../../reducers/station-disease';
import * as fromDiseaseSettings from '../../reducers/station-disease-settings';
import { StationDiseaseHelpService } from '../../services/station-disease-help.service';

@Component({
  selector: 'app-station-disease-content',
  templateUrl: './station-disease-content.component.html',
  styleUrls: ['./station-disease-content.component.scss']
})
export class StationDiseaseContentComponent implements OnInit, OnDestroy {

  public warnings$        : Observable<IDiseaseWarning[]>;
  public isLoading$       : Observable<boolean>;
  public isExportActive$  : Observable<boolean>;
  public selectedStation  : IStation;
  public state$           : Observable<string>;
  public tree$            : Observable<ITreeStructure> = this.treeService.getDiseaseTreeStructure();
  public exportChartImg   : boolean = false;

  private destroy$        : Subject<boolean> = new Subject<boolean>();
  private loadDestroy$    : Subject<boolean> = new Subject<boolean>();
  private diseases        : IDisease[];
  private selectedProfile : string = '';
  private requestDebounce : number = 200;
  private diseaseGroup    : string = '';

  constructor(private leftComponentsToggler: LeftComponentsTogglerService,
              private navigationStore: Store<fromNavigationStation.INavigationStationState>,
              private helpService: StationDiseaseHelpService,
              private diseaseStore: Store<fromDisease.IStationDiseaseState>,
              private diseaseSettingsStore: Store<fromDiseaseSettings.IStationDiseaseSettingsState>,
              private diseasesStore: Store<fromDiseases.IDiseases>,
              private treeService: TreeService,
              private treeSettingsStore: Store<ITreeSettingsState>) {
  }

  public ngOnInit(): void {
    this.state$ = this.leftComponentsToggler.getStationDataContentState();
    this.initLoadSubscription();
    this.initEmptySubscription();
    this.initWarningsSubscription();

    combineLatest([
      this.navigationStore.pipe(
        takeUntil(this.destroy$),
        select(selectNavigationStation),
        filter((s: IStation) => !!s)
      ),
      this.diseasesStore.pipe(
        takeUntil(this.destroy$),
        select(selectDiseases),
        filter((diseases: IDisease[]) => !!diseases)
      )
    ])
      .pipe(
        filter((result: Array<IStation | IDisease[]>) => this.selectedStation
          ? (<IStation>result[0]).name.original !== this.selectedStation.name.original
          : true)
      )
      .subscribe((result: Array<IStation | IDisease[]>) => {
        this.selectedStation = <IStation>result[0];
        this.diseases = <IDisease[]>result[1];
        this.loadDestroy$.next(true);
        this.initStationDisease();
        this.initStationDiseaseSettings();
        this.diseaseStore.dispatch(setStationDiseaseWarnings([]));
        this.diseaseSettingsStore.dispatch(setStationDiseaseFireBlight(''));
        this.diseaseSettingsStore.dispatch(setStationDiseaseRights(this.selectedStation.rights));
      });
  }

  private initLoadSubscription(): void {
    this.isLoading$ = this.diseaseStore.pipe(
      select(selectStationDiseaseLoading)
    );
  }

  private initWarningsSubscription(): void {
    this.warnings$ = this.diseaseStore.pipe(
      select(selectStationDiseaseWarnings)
    );
  }

  private initEmptySubscription(): void {
    this.isExportActive$ = this.diseaseSettingsStore.pipe(
      select(selectStationDiseaseSettingsExportActive)
    );
  }

  public exportCharts(value : boolean): void {
    this.exportChartImg = value;
  }

  private initStationDisease(): void {
    this.diseaseStore.dispatch(getStationDiseaseLicenses(this.selectedStation.name.original));
  }

  private initStationDiseaseSettings(): void {
    combineLatest([
      this.stationDiseaseLicensesObservable$.pipe(filter((licenses) => !!licenses)),
      this.stationTreeSettingsObservable$
    ]).subscribe(([diseaseLicenses, treeSettings]) => {
      const selectedProfileGroup = String(treeSettings.selectedProfileGroup);
      this.selectedProfile = treeSettings.selectedProfile;

      if (diseaseLicenses.models && diseaseLicenses.models[selectedProfileGroup]) {
        const minimumDates = diseaseLicenses.models[selectedProfileGroup].map((license) => moment(license.from));
        const minDate = moment.min(minimumDates);
        const maximumDates = diseaseLicenses.models[selectedProfileGroup].map((license) => moment(license.to));
        const maxDate = moment.max(maximumDates);

        const minStationDate = moment(this.selectedStation.dates.min_date);
        const minLicenseAfterStation = minDate.isSameOrAfter(minStationDate);
        const maxStationDate = moment(this.selectedStation.dates.max_date);
        const MaxLicenseBeforeStation = maxDate.isSameOrBefore(maxStationDate);


        const settings: IDiseaseModelSettings = this.getTimePeriod(
          selectedProfileGroup,
          this.selectedProfile);

        if (settings) {
          this.requestDebounce = 200;
          this.diseaseStore.dispatch(setStationDiseaseLoadingProgress());
          this.diseaseSettingsStore.dispatch(setStationDiseaseSettingsPeriodScope({
            periodScope: settings.resolution,
            periodValue: settings.period
          }));
        }

        const periodInDays = this.getPeriodDays(settings.period);

        this.diseaseSettingsStore.dispatch(setStationDiseaseSettingsPeriod({
          periodScope: settings.resolution,
          periodValue: settings.period,
          fromTo: {
            from: null,
            to: MaxLicenseBeforeStation ? maxDate.toDate() : maxStationDate.toDate()
          },
          fromDatepicker:  MaxLicenseBeforeStation ? maxDate.clone().subtract(periodInDays, 'day').toDate()
                           : maxStationDate.clone().subtract(periodInDays, 'day').toDate(),
          toDatepicker: MaxLicenseBeforeStation ? maxDate.toDate() : maxStationDate.toDate(),
          stationId: this.selectedStation.name.original,
          isLastDataMode: true
        }));
        this.diseaseSettingsStore.dispatch(setStationDiseaseSettingsFromTo({
          fromStation: minLicenseAfterStation ? minDate.toDate() : minStationDate.toDate(),
          toStation: MaxLicenseBeforeStation ? maxDate.toDate() : maxStationDate.toDate()
        }));

        this.initStationDiseaseSettingsSubscription();
        this.initDiseaseModelSubscription();
        this.initStationDiseaseHelpDescription();
      }
    });
  }

  private getPeriodDays(period: string): number {
    return parseInt(period.match(/\d/g).join(''), 10);
  }

  private initStationDiseaseSettingsSubscription(): void {
    this.stationDiseaseSettingsPeriodObservable$
      .pipe(
        takeUntil(this.loadDestroy$),
        debounceTime(this.requestDebounce),
        filter((period: IStationSettingsPeriod) =>
          !!this.selectedProfile
          && !!period.periodValue
          && !!period.periodScope)
      ).subscribe((period: IStationSettingsPeriod) => {
      this.requestDebounce = 10;
      this.diseaseStore.dispatch(getStationDiseaseData({
        ...this.prepareFromToParams(period),
        stationId: period.stationId,
        name: `disease, ${this.selectedProfile}`,
        timePeriod: period.periodScope
      }));
    });
  }

  private initStationDiseaseHelpDescription(): void {
    combineLatest([
      this.stationDiseaseSettingsPeriodObservable$,
      this.stationDiseaseLicensesObservable$,
      this.stationTreeSettingsObservable$
    ]).pipe(
      debounceTime(10),
      takeUntil(this.loadDestroy$),
    ).subscribe((result: Array<IStationSettingsPeriod|IDiseaseLicenses|IStationTreeSettings>) => {
      const period: IStationSettingsPeriod = <IStationSettingsPeriod>result[0];
      const licenses: IDiseaseLicenses = <IDiseaseLicenses>result[1];
      this.diseaseStore.dispatch(setStationDiseaseWarnings([]));
      if (licenses && licenses.models) {
        this.helpService.setLicenseWarnings(
          licenses.models,
          period,
          this.diseaseGroup
        );
      }
    });
  }

  private getTimePeriod(group: string, profile: string): IDiseaseModelSettings {
    let diseaseModel: IDiseaseModel;
    const diseaseGroup: IDisease = this.diseases.find((disease: IDisease) => disease.group === group);
    if (diseaseGroup) {
      this.diseaseGroup = diseaseGroup.group;
      diseaseModel = diseaseGroup.models.find((model: IDiseaseModel) => model.key === profile);
    }
    return diseaseModel ? diseaseModel.settings : null;
  }

  private get stationDiseaseSettingsPeriodObservable$(): Observable<IStationSettingsPeriod> {
    return this.diseaseSettingsStore.pipe(
      select(selectStationDiseaseSettingsPeriod),
      filter((period: IStationSettingsPeriod) => !!period.stationId
        && period.stationId === this.selectedStation.name.original)
    );
  }

  private get stationDiseaseLicensesObservable$(): Observable<IDiseaseLicenses> {
    return this.diseaseStore.pipe(
      select(selectStationDiseaseLicenses),
    );
  }

  private get stationTreeSettingsObservable$(): Observable<IStationTreeSettings> {
    return this.treeSettingsStore.pipe(
      select(selectTreeSettings),
      filter((treeSettings: ITreeSettingsState): boolean => !!treeSettings[this.selectedStation.name.original]),
      map((treeSettings: ITreeSettingsState): IStationTreeSettings => treeSettings[this.selectedStation.name.original])
    );
  }

  private prepareFromToParams(period: IStationSettingsPeriod): IFromTo {
    return {
      from: period.isLastDataMode
        ? dateToUtcUnixTimestamp(period.fromTo.from)
        : dateToUtcUnixTimestamp(period.fromDatepicker),
      to: period.isLastDataMode
        ? dateToUtcUnixTimestamp(period.fromTo.to)
        : dateToUtcUnixTimestamp(period.toDatepicker),
    };
  }

  private initDiseaseModelSubscription(): void {
    this.stationTreeSettingsObservable$.pipe(
      takeUntil(this.loadDestroy$)
    ).subscribe((stationTreeSettings: IStationTreeSettings): void => {
      const isFireblight: boolean = stationTreeSettings.selectedProfile === FIRE_BLIGHT;
      if (isFireblight) {
        this.diseaseSettingsStore.dispatch(getStationDiseaseFireBlight(this.selectedStation.name.original));
      }
      this.diseaseSettingsStore.dispatch(setStationDiseaseFireBlightActive(isFireblight));
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.loadDestroy$.next(true);
    this.loadDestroy$.complete();
  }
}
