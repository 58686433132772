import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-leave-confirm',
  templateUrl: './leave-confirm.component.html',
  styleUrls: ['./leave-confirm.component.scss']
})
export class LeaveConfirmComponent {
  @Output()
  public leave        : EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public stay         : EventEmitter<void> = new EventEmitter<void>();

  public emitLeave(): void {
    this.leave.emit();
  }

  public emitStay(): void {
    this.stay.emit();
  }
}
