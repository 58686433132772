import { Injectable } from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {ApiCallService} from '../../../services/api/api-call.service';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {from, Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {WarningsHelpService} from '../services/warnings-help.service';
import {
  setWarningsList,
  WarningsListActionTypes
} from '../actions/warningsList';
import {
  WarningsPhonesActionTypes
} from '../actions/warningsPhones';
import {changeStation, getWarnings} from '../../../core/actions/stations';
import {setNotify} from '../../../core/actions/notify';

@Injectable()
export class WarningsEffects {

  constructor(private actions$: Actions,
              private api: ApiCallService,
              private helpService: WarningsHelpService) { }

  @Effect()
  public loadSensors$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(WarningsListActionTypes.GET_SENORS),
    switchMap((action: IActionWithPayload) => this.api.getStationSensors(action.payload.stationId).pipe(
      switchMap((res: any) => from([
        setWarningsList(this.helpService.prepareSensors(res, action.payload.warnings)),
      ])),
      catchError(() => from([
      ]))
      )
    )
  );

  @Effect()
  public saveStationWarningsSensor$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(WarningsListActionTypes.SAVE_CONVERT_UNITS),
    switchMap((action: IActionWithPayload) => this.api.saveWarningsSensors(
      action.payload.station.name.original,
      action.payload.saveStation
      )
      .pipe(
        switchMap((response: any) => [
          setNotify(!Array.isArray(response)
          ? 'SMS warnings successfully saved.'
          : 'No changes have been made yet. Edit the status before updating.')
        ].concat(Array.isArray(response) ? [] : [getWarnings(action.payload.station.name.original)])),
        catchError(() => of(setNotify('Failed to save sms warning data!')))
      )
    )
  );

  @Effect()
  public saveWarningsPhones$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(WarningsPhonesActionTypes.SAVE_WARNINGS_PHONES),
    switchMap((action: IActionWithPayload) => this.api.saveWarningsPhones(
      action.payload.station.name.original,
      action.payload.save
      )
      .pipe(
        switchMap((response: any) => from([
            changeStation(action.payload.station),
            setNotify(!Array.isArray(response)
              ? 'The list of phone numbers was saved successfully.'
              : 'No changes have been made yet. Edit the status before updating.')
          ])
        ),
        catchError(() => of(setNotify('There was a problem saving phone numbers!')))
      )
    )
  );
}
