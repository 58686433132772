import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import * as fromNavigationStation from '../../../../core/reducers/navigation-station';
import { StationTypes } from '../../../../shared/constants';
import { IOptions } from '../../../../shared/interfaces';
import { momentToUtcUnixTimestamp } from '../../../../shared/utils/dateFormat';
import { setCameraSlideshowSettings } from '../../actions/camera-slideshow';
import { ICameraSlideshowSettings, ICameraSlideshowState } from '../../models/camera-slideshow';
import { selectCameraSlideshowLastTimeDate, selectCameraSlideshowSettings } from '../../reducers';

@Component({
  selector: 'app-camera-slideshow-toolbar',
  templateUrl: './camera-slideshow-toolbar.component.html',
  styleUrls: ['./camera-slideshow-toolbar.component.scss']
})
export class CameraSlideshowToolbarComponent implements OnInit, OnDestroy {
  @Input()
  public isFullscreen: boolean = false;
  @Output()
  public refreshEmitter: EventEmitter<void> = new EventEmitter<void>();
  public form: FormGroup;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private station: IStation;
  public showCameraDropdown: boolean = false;
  public readonly cameraOptions: Array<IOptions> = [
    {
      value: '1',
      content: 'Camera 1',
    },
    {
      value: '2',
      content: 'Camera 2',
    }
  ];

  constructor(private formBuilder: FormBuilder,
              private cameraSlideshowStore: Store<ICameraSlideshowState>,
              private navigationStore: Store<fromNavigationStation.INavigationStationState>) {}

  public ngOnInit(): void {
    this.navigationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation)
    ).subscribe((station: IStation) => {
      this.station = station;
      switch (station.info.device_id) {
        case StationTypes.get('CropViewType1'):
          this.showCameraDropdown = true;
          break;
        default:
          this.showCameraDropdown = false;
          break;
      }
    });
    this.createForm();
    this.listenForm();
    this.listenLastDateTime();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public play(): void {
    this.isPlayingControl.setValue(!this.isPlayingControl.value);
  }

  public firstSlide(): void {
    this.activeSlideControl.setValue(undefined);
    setTimeout(() => {
      this.activeSlideControl.setValue(0);
    });
  }

  public fullscreen(): void {
    this.isFullscreenControl.setValue(!this.isFullscreenControl.value);
  }

  public refresh(): void {
    this.refreshEmitter.emit();
  }

  public get isLastDataModeControl(): AbstractControl {
    return this.form.get('isLastDataMode');
  }

  public get fromLastDataControl(): AbstractControl {
    return this.form.get('fromLastData');
  }

  public get toLastDataControl(): AbstractControl {
    return this.form.get('toLastData');
  }

  public get fromDatepickerControl(): AbstractControl {
    return this.form.get('fromDatepicker');
  }

  public get toDatepickerControl(): AbstractControl {
    return this.form.get('toDatepicker');
  }

  public get isPlayingControl(): AbstractControl {
    return this.form.get('isPlaying');
  }

  public get playSpeedControl(): AbstractControl {
    return this.form.get('playSpeed');
  }

  public get isFullscreenControl(): AbstractControl {
    return this.form.get('isFullscreen');
  }

  public get activeSlideControl(): AbstractControl {
    return this.form.get('activeSlide');
  }

  public get cameraSelectedControl(): AbstractControl {
    return this.form.get('cameraSelected');
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      'isLastDataMode': [true, [Validators.required]],
      'fromLastData': [null],
      'toLastData': [null],
      'fromDatepicker': [null, [Validators.required]],
      'toDatepicker': [null, [Validators.required]],
      'isPlaying': [null],
      'playSpeed': [null],
      'activeSlide': [null],
      'isFullscreen': [null, Validators.required],
      'cameraSelected': [1]
    });
    this.cameraSlideshowStore.pipe(
      take(1),
      select(selectCameraSlideshowSettings),
      filter((settings: ICameraSlideshowSettings): boolean => Boolean(settings))
    ).subscribe((settings: ICameraSlideshowSettings): void => {
      this.form.setValue(<{}>settings);
    });
  }

  private listenForm(): void {
    this.form.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe((changes: any): void => {
      this.cameraSlideshowStore.dispatch(setCameraSlideshowSettings(<ICameraSlideshowSettings>changes));
    });
  }

  private listenLastDateTime(): void {
    this.cameraSlideshowStore.pipe(
      takeUntil(this.destroy$),
      select(selectCameraSlideshowLastTimeDate),
      filter((dateTime: moment.Moment): boolean => Boolean(dateTime))
    ).subscribe((toMoment: moment.Moment): void => {
      this.fromLastDataControl.setValue(momentToUtcUnixTimestamp(toMoment.clone().subtract(1, 'days')));
      this.toLastDataControl.setValue(momentToUtcUnixTimestamp(toMoment));

      this.fromDatepickerControl.setValue(toMoment.clone().subtract(2, 'days').startOf('day').toDate());
      this.toDatepickerControl.setValue(toMoment.clone().endOf('day').toDate());
    });
  }
}
