import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ICameraSlideshowSettings, ICameraSlideshowState} from '../models/camera-slideshow';
import {IPicture} from '../../../shared/camera/models/camera';
import * as moment from 'moment';

export const cameraSlideshowSelector = createFeatureSelector<ICameraSlideshowState>('cameraSlideshow');

export const selectCameraSlideshowLastTimeDate = createSelector(
  cameraSlideshowSelector,
  (state: ICameraSlideshowState): moment.Moment => state.lastTimeDate
);
export const selectCameraSlideshowPictures = createSelector(
  cameraSlideshowSelector,
  (state: ICameraSlideshowState): Array<IPicture> => state.pictures
);

export const selectCameraSlideshowLoading = createSelector(
  cameraSlideshowSelector,
  (state: ICameraSlideshowState): boolean => state.isLoading
);

export const selectCameraSlideshowError = createSelector(
  cameraSlideshowSelector,
  (state: ICameraSlideshowState): boolean => state.isError
);

export const selectCameraSlideshowSettings = createSelector(
  cameraSlideshowSelector,
  (state: ICameraSlideshowState): ICameraSlideshowSettings => state.settings
);
