import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { distinctUntilChanged, takeWhile } from 'rxjs/operators';
import { IOptions } from '../../../../../shared/interfaces';
import { IAddWidgetForm } from '../../../models/dashboard.models';

@Component({
  selector: 'app-widget-add-form',
  templateUrl: './widget-add-form.component.html',
  styleUrls: ['./widget-add-form.component.scss']
})
export class WidgetAddFormComponent implements OnInit, OnDestroy {
  @Input()
  public addWidgetDropDownOptions         : Array<IOptions> = [];
  @Output()
  private changeForm                      : EventEmitter<IAddWidgetForm> = new EventEmitter();

  public form                             : FormGroup;
  private alive                           : boolean = true;

  constructor(private formBuilder: FormBuilder) { }

  public get type(): AbstractControl {
    return this.form.get('type');
  }

  public get title(): AbstractControl {
    return this.form.get('title');
  }

  public get description(): AbstractControl {
    return this.form.get('description');
  }

  public get color(): AbstractControl {
    return this.form.get('color');
  }

  public ngOnInit(): void {

    this.form = this.formBuilder.group({
      'type': new FormControl('stations-list'),
      'title' : new FormControl(''),
      'description': new FormControl(''),
      'color': new FormControl(''),
    });

    this.form.valueChanges.pipe(
      distinctUntilChanged(),
      takeWhile(() => this.alive)
    ).subscribe((form: IAddWidgetForm) => {
      this.changeForm.emit(form);
    });
  }

  public ngOnDestroy(): void {
    this.alive = false;
  }

}
