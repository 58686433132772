import {Injectable} from '@angular/core';
import {ApiCallService} from '../../../services/api/api-call.service';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {combineLatest, Observable, of, OperatorFunction} from 'rxjs';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {
  CropViewActionTypes,
  getCropViewPhotos,
  setCropViewError,
  setCropViewFirstDate,
  setCropViewLastDate,
  setCropViewLoading,
  setCropViewPhotos
} from '../actions/crop-view';
import {catchError, map, switchMap} from 'rxjs/operators';
import {from} from 'rxjs';
import {
  IDeleteImagePayload,
  IGetPhotosRequest,
  IGetPhotosRequestFromTo,
  IGetPhotosRequests,
  IPicture,
  IPictureSet,
  ISaveMeasurementsResponse,
  PhotoRequestType
} from '../../../shared/camera/models/camera';
import {
  CropViewSettingsActionTypes,
  setCameraDistance,
  setCameraDrawingOptions,
  setCropViewSettingsCurrentDateString,
  updateCropViewSettingsSelectedPicture
} from '../actions/crop-view-settings';
import {getCameraFilterDate, getMonthFromDate} from '../../../shared/utils/dateFormat';
import {setNotify} from '../../../core/actions/notify';
import * as moment from 'moment';

@Injectable()
export class CropViewService {
  constructor(private api: ApiCallService,
              private actions$: Actions) { }

  @Effect()
  public getCropViewFirstDate$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CropViewActionTypes.GET_CROP_VIEW_FIRST_DATE),
    this.getCropViewBoundaryDateCallback('first')
  );

  @Effect()
  public getCropViewLastDate$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CropViewActionTypes.GET_CROP_VIEW_LAST_DATE),
    this.getCropViewBoundaryDateCallback('last')
  );

  @Effect()
  public getCropViewPhotos$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CropViewActionTypes.GET_CROP_VIEW_PHOTOS),
    switchMap((action: IActionWithPayload) => {
      setCropViewError(false);
      return this.performApiCallsForPhotos(action);
    })
  );

  @Effect()
  public saveCropViewMeasurements$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CropViewActionTypes.SAVE_CROP_VIEW_PHOTOS_MEASUREMENTS),
    switchMap((action: IActionWithPayload) => {
      return this.api.saveCameraMeasurements(
        action.payload.stationId,
        action.payload.body
      ).pipe(
        switchMap((res: ISaveMeasurementsResponse) => from([
          updateCropViewSettingsSelectedPicture(res),
          getCropViewPhotos({
            type: PhotoRequestType.SINGLE_DATE_INTERVAL,
            camIds: [res.cam_id],
            stationId: res.station_id,
            date: getCameraFilterDate(res.date),
          }),
          setNotify('Measurements were saved')
        ])),
        catchError(() => of(setNotify('Could not save the measurements'))),
      );
    })
  );

  @Effect()
  public saveCameraDistance$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CropViewSettingsActionTypes.SAVE_CAMERA_DISTANCE),
    switchMap((action: IActionWithPayload) => this.api.saveCameraDistance(
      action.payload.stationId,
      action.payload.body,
    ).pipe(
      switchMap(() => of(setNotify('Camera distance was saved'))),
      catchError(() => of(setNotify('Could not save camera distance')))
    ))
  );

  @Effect()
  public deleteCropViewImage$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CropViewActionTypes.DELETE_CROP_VIEW_PHOTOS_IMAGE),
    switchMap((action: IActionWithPayload) => {
      const payload: IDeleteImagePayload = action.payload;
      return this.api.deleteCameraImage(payload.deleteRequest).pipe(
        switchMap(() => {
          return from([
            setNotify('Image was deleted'),
            getCropViewPhotos(payload.refreshRequest),
          ]);
        }),
        catchError(() => of(setNotify('Could not delete the selected image'))),
      );
    })
  );

  @Effect()
  public getCameraDistance$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CropViewSettingsActionTypes.GET_CAMERA_DISTANCE),
    switchMap((action: IActionWithPayload) => {
      return this.api.getCameraDistance({
        type: PhotoRequestType.SINGLE_DATE_INTERVAL,
        stationId: action.payload
      }).pipe(
        switchMap((apiResponse: {camera_distance: number}) => {
          const distance: number = apiResponse.camera_distance ? apiResponse.camera_distance : 3000;
          return from ([
            setCameraDistance(distance)
          ]);
        }),
        catchError(() => of(setNotify('Could not retrieve camera distance')))
      );
    }),
  );

  @Effect()
  public getCameraDrawingOptions$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CropViewSettingsActionTypes.GET_CAMERA_DRAWING_OPTIONS),
    switchMap((action: IActionWithPayload) => {
      return this.api.getDrawingOptions(action.payload).pipe(
        switchMap((response: any) => of(setCameraDrawingOptions(response.drawingOptions))),
        catchError(() => of(setNotify('Could not retrieve camera drawing options')))
      );
    })
  );

  @Effect()
  public saveCameraDrawingOptions$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CropViewSettingsActionTypes.SAVE_CAMERA_DRAWING_OPTIONS),
    switchMap((action: IActionWithPayload) => {
      return this.api.saveDrawingOptions(action.payload.stationId, action.payload.data).pipe(
        switchMap((response: any) => from([
          setCameraDrawingOptions(action.payload.data),
          setNotify('Camera\'s drawing options were saved')
        ])),
        catchError(() => of(setNotify('Could not save camera\'s drawing options')))
      );
    })
  );

  private getCropViewBoundaryDateCallback(boundaryType: string): OperatorFunction<IActionWithPayload, IActionWithPayload> {
    return switchMap((action: IActionWithPayload) => {
      setCropViewLoading(true);
      setCropViewError(false);
      return this.performApiCallForDate(action, boundaryType);
    });
  }

  private performApiCallForDate(action: IActionWithPayload, boundaryType: string): Observable<IActionWithPayload> {
    return this.api.getCameraPhotos({
      type: PhotoRequestType.SINGLE_DATE_INTERVAL,
      stationId: action.payload,
      date: boundaryType
    }).pipe(
      switchMap((apiResponse: {date: string}) => from(this.prepareSetDateAction(apiResponse, boundaryType))),
      catchError(() => this.unsuccessfulApiCall())
    );
  }

  private prepareSetDateAction(apiResponse: {date: string}, boundaryType: string): Array<IActionWithPayload> {
    if (!apiResponse.date && !apiResponse[0]) {
      return [
        setCropViewError(true),
        setCropViewLoading(false)
      ];
    }
    const dateObject = moment(apiResponse.date ? apiResponse.date : apiResponse[0]);
    return boundaryType === 'last'
      ? [
        setCropViewLastDate(dateObject),
        setCropViewSettingsCurrentDateString(getMonthFromDate(dateObject))
      ]
      : [setCropViewFirstDate(dateObject)];
  }

  private performApiCallsForPhotos(action: IActionWithPayload): Observable<IActionWithPayload> {
    return combineLatest(this.prepareApiCallObservables(action.payload)).pipe(
      switchMap((sets: Array<IPictureSet>) => this.prepareSetPhotosActions(sets))
    );
  }

  private prepareApiCallObservables(data: IGetPhotosRequests | IGetPhotosRequestFromTo): Array<Observable<IPictureSet>> {
    return data.camIds.map((camId: number): Observable<IPictureSet> => {
      let request;
      if (data.type === PhotoRequestType.MIN_MAX_INTERVAL) {
        request = <IGetPhotosRequestFromTo> {
          type: data.type,
          stationId: data.stationId,
          from: data.from,
          to: data.to,
          camId
        };
      } else {
        request = <IGetPhotosRequest> {
          type: data.type,
          stationId: data.stationId,
          date: data.date,
          camId
        };
      }
      return this.performSingleApiCallForPhotos(request);
    });
  }

  private performSingleApiCallForPhotos(request: IGetPhotosRequest | IGetPhotosRequestFromTo): Observable<IPictureSet> {
    const observable = request.type === PhotoRequestType.MIN_MAX_INTERVAL
      ? this.api.getCameraPhotosFromTo(request)
      : this.api.getCameraPhotos(request);
    return observable.pipe(
      map((apiResponse: Array<IPicture>): IPictureSet => {
        return {
          pictures: apiResponse,
          camId: request.camId
        };
      }),
      catchError(() => of({
        pictures: [],
        camId: request.camId
      }))
    );
  }

  private prepareSetPhotosActions(sets: Array<IPictureSet>): Observable<IActionWithPayload> {
    const setPhotosActions = [
      setCropViewLoading(false)
    ];
    for (let i = 0; i < sets.length; i++) {
      setPhotosActions.push(setCropViewPhotos(sets[i]));
    }
    return setPhotosActions.length ? from(setPhotosActions) : this.unsuccessfulApiCall();
  }

  private unsuccessfulApiCall(): Observable<IActionWithPayload> {
    return from([
      setCropViewError(true),
      setCropViewLoading(false)
    ]);
  }
}
