import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-multilevel-dropdown-input',
  templateUrl: './multilevel-dropdown-input.component.html',
  styleUrls: ['./multilevel-dropdown-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultilevelDropdownInputComponent),
    multi: true
  }]
})
export class MultilevelDropdownInputComponent implements ControlValueAccessor {
  @Input()
  public placeholder: string;
  @Input()
  public iconClass: string;
  @Output()
  public iconClickEmitter: EventEmitter<void> = new EventEmitter<void>();

  private currentValue: string = '';

  private propagateChange: any = () => { return; };

  public get value(): string {
    return this.currentValue;
  }

  public set value(value: string) {
    this.currentValue = value;
  }

  public onChange(event: any): void {
    this.propagateChange(event.target.value);
  }

  public writeValue(value: any): void {
    this.currentValue = value;
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    return;
  }

  public setDisabledState(isDisabled: boolean): void {
    return;
  }

  public iconClick(): void {
    this.iconClickEmitter.emit();
  }
}
