<div class="selected-station">
  <a [ngClass]="'selected-station-image'"
     [ngStyle]="{'background-image': 'url(' + theme.profileMenuSrc + ')'}"
     (click)="infoIsOpen = !infoIsOpen">
    <div class="station-name">
      {{ stationName }}
      <i [ngClass]="{'zmdi': true, 'zmdi-caret-down': !infoIsOpen, 'zmdi-caret-up': infoIsOpen}"></i>
    </div>
  </a>
  <ul *ngIf="infoIsOpen" class="selected-station-info">
    <li>{{ 'Device type' | translate }}: <strong >{{ deviceType | translate }}</strong></li>
    <ng-container *ngIf="!this.isSampleDevice">
      <li>{{ 'Firmware' | translate }}: <strong >{{ firmWare }}</strong></li>
      <li>{{ 'Timezone offset' | translate }}: <strong >{{ timeZoneOffset }}</strong></li>
    </ng-container>
    <li>{{ 'Last comm' | translate }}: <strong >{{ lastCommunication }}</strong></li>
  </ul>
</div>
