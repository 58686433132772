import {HostListener, Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import {filter, map, tap} from 'rxjs/operators';
import {
  HAMBURGER_STATE_BAR_AND_STATION_ARE_DISPLAYED,
  HAMBURGER_STATE_BAR_IS_DISPLAYED,
  HAMBURGER_STATE_LEFT_COMPONENTS_ARE_HIDDEN,
  LEFT_BAR_STATE_IS_DISPLAYED,
  LEFT_BAR_STATE_IS_HIDDEN,
  LEFT_SIDE_MENU_STATE_FULL_WIDTH,
  LEFT_SIDE_MENU_STATE_REDUCED_WIDTH,
  STATE_TREE_IS_DISPLAYED_FOR_FULL_WIDTH,
  STATE_TREE_IS_DISPLAYED_FOR_REDUCED_WIDTH,
  STATE_TREE_IS_HIDDEN,
  STATION_DATA_CONTENT_STATE_FULL_WIDTH,
  STATION_DATA_CONTENT_STATE_REDUCED_WIDTH
} from './animation.constants';

@Injectable({
  providedIn: 'root'
})
export class LeftComponentsTogglerService {
  public treeCanBeShown$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  private readonly fullScreenWidth  : number = 1024;
  private isFullScreen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(window.innerWidth > this.fullScreenWidth);
  private leftBarIsDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(window.innerWidth > this.fullScreenWidth);
  private leftStationIsDisplayed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private leftStationIsClosed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly pagesWithoutStation: Array<string> = [
    '/dashboard',
    '/user-settings',
    '/user-support',
    '/auth/login',
    '/station/data-upload'
  ];

  constructor(private router: Router) {
    this.canBeShown = this.pagesWithoutStation.indexOf(this.router.url) === -1;
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd): boolean => this.pagesWithoutStation.indexOf(event.url) === -1),
    ).subscribe((canBeShown: boolean): void => {
      this.canBeShown = canBeShown;
    });
  }

  private set canBeShown(canBeShown: boolean) {
    this.treeCanBeShown$.next(canBeShown);
    this.leftStationIsDisplayed$.next(canBeShown ? !this.leftStationIsClosed$.getValue() : false);
  }

  public getStationDataContentState(): Observable<string> {
    return this.leftStationIsDisplayed$.pipe(
      filter((): boolean => this.isFullScreen$.getValue()),
      map((isDisplayed: boolean): string => isDisplayed
        ? STATION_DATA_CONTENT_STATE_REDUCED_WIDTH
        : STATION_DATA_CONTENT_STATE_FULL_WIDTH
      )
    );
  }

  public getLeftSideMenuNavigation(): Observable<string> {
    return this.leftBarIsDisplayed$.pipe(
      filter((): boolean => this.isFullScreen$.getValue()),
      map((isDisplayed: boolean): string => isDisplayed
          ? LEFT_SIDE_MENU_STATE_FULL_WIDTH
          : LEFT_SIDE_MENU_STATE_REDUCED_WIDTH
      )
    );
  }

  public getTreeState(): Observable<string> {
    return combineLatest([
      this.leftBarIsDisplayed$,
      this.leftStationIsDisplayed$,
      this.isFullScreen$
    ])
      .pipe(
        map((result: Array<boolean>): string => {
          const leftBarIsDisplayed: boolean = result[0];
          const leftStationIsDisplayed: boolean = result[1];
          return (leftBarIsDisplayed && leftStationIsDisplayed)
            ? (this.isFullScreen$.getValue() ? STATE_TREE_IS_DISPLAYED_FOR_FULL_WIDTH : STATE_TREE_IS_DISPLAYED_FOR_REDUCED_WIDTH)
            : STATE_TREE_IS_HIDDEN;
        })
      );
  }

  public getTreeIsDisplayed(): Observable<boolean> {
    return this.getTreeState().pipe(
      map((state: string): boolean => state !== STATE_TREE_IS_HIDDEN)
    );
  }

  public getLeftBarState(): Observable<string> {
    return this.leftBarIsDisplayed$.pipe(
      map((isDisplayed: boolean): string => {
        return isDisplayed ? LEFT_BAR_STATE_IS_DISPLAYED : LEFT_BAR_STATE_IS_HIDDEN;
      })
    );
  }

  public getHamburgerState(): Observable<string> {
    return combineLatest([
      this.leftBarIsDisplayed$,
      this.leftStationIsDisplayed$,
    ])
      .pipe(
        map((result: Array<boolean>): string => {
          const leftBarIsDisplayed = result[0];
          const leftStationIsDisplayed = result[1];
          if (leftBarIsDisplayed && leftStationIsDisplayed) {
            return HAMBURGER_STATE_BAR_AND_STATION_ARE_DISPLAYED;
          } else if (leftBarIsDisplayed) {
            return HAMBURGER_STATE_BAR_IS_DISPLAYED;
          } else {
            return HAMBURGER_STATE_LEFT_COMPONENTS_ARE_HIDDEN;
          }
        })
      );
  }

  public toggleBar(): void {
    this.leftBarIsDisplayed$.next(!this.leftBarIsDisplayed$.getValue());
  }

  public toggleStation(): void {
    this.leftStationIsClosed$.next(!this.leftStationIsClosed$.getValue());
    this.leftStationIsDisplayed$.next(!this.leftStationIsDisplayed$.getValue());
  }

  public handleSizeChange(): void {

    if (this.isFullScreen$.getValue() && window.innerWidth < this.fullScreenWidth) {
      this.isFullScreen$.next(false);
    }
    if (!this.isFullScreen$.getValue() && window.innerWidth > this.fullScreenWidth) {
      this.isFullScreen$.next(true);
      this.leftBarIsDisplayed$.next(true);
    }
  }
}
