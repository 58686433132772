<app-form-layout [shadow]="false">
  <div class="station-data-header">
    <div>
      <h2>{{ 'Reports' | translate }}</h2>
      <small>{{ 'tracker and machinery connection reports' | translate }}</small>
    </div>
  </div>
</app-form-layout>
<app-form-layout [shadow]="false">
  <div class="report-data">
    <!--div class="row">
      <div class="col-md-6 info" *ngIf="trackerInfo">
        <h4><i class="fa fa-info-circle fa-fw"></i>{{ 'Tracker' | translate }}</h4>

        <table class="table">
          <tr *ngIf="trackerInfo.name">
            <th class="col-md-8">{{ 'Name' | translate }}</th>
            <td class="col-md-4">{{trackerInfo.name}}</td>
          </tr>
          <tr *ngIf="trackerInfo.serial">
            <th class="col-md-8">{{ 'Serial' | translate }}</th>
            <td class="col-md-4">{{trackerInfo.serial}}</td>
          </tr>
          <tr *ngIf="trackerInfo.serialNumber">
            <th class="col-md-8">{{ 'Serial number' | translate }}</th>
            <td class="col-md-4">{{trackerInfo.serialNumber}}</td>
          </tr>
          <tr *ngIf="trackerInfo.trackerType">
            <th class="col-md-8">{{ 'Tracker type' | translate }}</th>
            <td class="col-md-4">{{trackerInfo.trackerType}}</td>
          </tr>
          <tr *ngIf="trackerInfo.conectivityType">
            <th class="col-md-8">{{ 'Conecivity type' | translate }}</th>
            <td class="col-md-4">{{trackerInfo.conectivityType}}</td>
          </tr>
          <tr *ngIf="trackerInfo.canbusSerial">
            <th class="col-md-8">{{ 'Canbus serial' | translate }}</th>
            <td class="col-md-4">{{trackerInfo.canbusSerial}}</td>
          </tr>
          <tr *ngIf="trackerInfo.simInfo">
            <th class="col-md-8">{{ 'SIM Card info' | translate }}</th>
            <td class="col-md-4">{{trackerInfo.simInfo}}
              <span *ngIf="trackerInfo.simInfoControl">({{trackerInfo.simInfoControl}})</span>
            </td>
          </tr>
          <tr *ngIf="trackerInfo.simNumber">
            <th class="col-md-8">{{ 'SIM Card number' | translate }}</th>
            <td class="col-md-4">{{trackerInfo.simNumber}}</td>
          </tr>
          <tr *ngIf="trackerInfo.simPlan">
            <th class="col-md-8">{{ 'SIM Card plan' | translate }}</th>
            <td class="col-md-4">{{trackerInfo.simPlan}}</td>
          </tr>
          <tr *ngIf="trackerInfo.simProvider">
            <th class="col-md-8">{{ 'SIM Card provider' | translate }}</th>
            <td class="col-md-4">{{trackerInfo.simProvider}}</td>
          </tr>
          <tr *ngIf="trackerInfo.apnName">
            <th class="col-md-8">{{ 'APN name' | translate }}</th>
            <td class="col-md-4">{{trackerInfo.apnName}}</td>
          </tr>
          <tr *ngIf="trackerInfo.apnUsername">
            <th class="col-md-8">{{ 'APN username' | translate }}</th>
            <td class="col-md-4">{{trackerInfo.apnUsername}}</td>
          </tr>
          <tr *ngIf="trackerInfo.apnPassword">
            <th class="col-md-8">{{ 'APN password' | translate }}</th>
            <td class="col-md-4">{{trackerInfo.apnPassword}}</td>
          </tr>
        </table>

      </div>
      <div class="col-md-6 info" *ngIf="machineryInfo">
        <h4><i class="fa fa-info-circle fa-fw"></i>{{ 'Machinery' | translate }}</h4>

        <table class="table">
          <tr *ngIf="machineryInfo.machineryName">
            <th class="col-md-8">{{ 'Name' | translate }}</th>
            <td class="col-md-4">{{machineryInfo.machineryName}}</td>
          </tr>
          <tr *ngIf="machineryInfo.machineryFrameNumber">
            <th class="col-md-8">{{ 'Frame number' | translate }}</th>
            <td class="col-md-4">{{machineryInfo.machineryFrameNumber}}</td>
          </tr>
          <tr *ngIf="machineryInfo.machineryPlateNumber">
            <th class="col-md-8">{{ 'Plate number' | translate }}</th>
            <td class="col-md-4">{{machineryInfo.machineryPlateNumber}}</td>
          </tr>
          <tr *ngIf="machineryInfo.machineryDescription">
            <th class="col-md-8">{{ 'Description' | translate }}</th>
            <td class="col-md-4">{{machineryInfo.machineryDescription}}</td>
          </tr>
          <tr *ngIf="machineryInfo.machineryNewType">
            <th class="col-md-8">{{ 'New type' | translate }}</th>
            <td class="col-md-4">{{machineryInfo.machineryNewType}}</td>
          </tr>
          <tr *ngIf="machineryInfo.machineryPower">
            <th class="col-md-8">{{ 'Power' | translate }}</th>
            <td class="col-md-4">{{machineryInfo.machineryPower}} <span
                *ngIf="machineryInfo.machineryPowerType">{{machineryInfo.machineryPowerType}}</span></td>
          </tr>
          <tr *ngIf="machineryInfo.machineryPurchaseDate">
            <th class="col-md-8">{{ 'Purchase date' | translate }}</th>
            <td class="col-md-4">{{toLocalDate(machineryInfo.machineryPurchaseDate)}}</td>
          </tr>
          <tr *ngIf="machineryInfo.machineryServiceCurrentEngineH">
            <th class="col-md-8">{{ 'Current engine hours' | translate }}</th>
            <td class="col-md-4">{{machineryInfo.machineryServiceCurrentEngineH}}</td>
          </tr>
          <tr *ngIf="machineryInfo.machineryServiceMaxEngineH">
            <th class="col-md-8">{{ 'Max engine hours' | translate }}</th>
            <td class="col-md-4">{{machineryInfo.machineryServiceMaxEngineH}}</td>
          </tr>
          <tr *ngIf="machineryInfo.machineryServiceCurrentKm">
            <th class="col-md-8">{{ 'Current kilometers' | translate }}</th>
            <td class="col-md-4">{{machineryInfo.machineryServiceCurrentKm}}</td>
          </tr>
          <tr *ngIf="machineryInfo.machineryServiceMaxtKm">
            <th class="col-md-8">{{ 'Max kilometers' | translate }}</th>
            <td class="col-md-4">{{machineryInfo.machineryServiceMaxtKm}}</td>
          </tr>
          <tr *ngIf="machineryInfo.machineryServiceMaxDays">
            <th class="col-md-8">{{ 'Max service days' | translate }}</th>
            <td class="col-md-4">{{machineryInfo.machineryServiceMaxDays}}</td>
          </tr>
          <tr *ngIf="machineryInfo.machineryUpcomingTechnicalDate">
            <th class="col-md-8">{{ 'Upcoming technical date' | translate }}</th>
            <td class="col-md-4">{{toLocalDate(machineryInfo.machineryUpcomingTechnicalDate)}}</td>
          </tr>
          <tr *ngIf="machineryInfo.machineryLastTechicalDate">
            <th class="col-md-8">{{ 'Last technical date' | translate }}</th>
            <td class="col-md-4">{{toLocalDate(machineryInfo.machineryLastTechicalDate)}}</td>
          </tr>
        </table>
      </div>
      <div class="row">
        <div class="col-md-12 reportNotSelected" *ngIf="!trackerInfo && !machineryInfo">
          {{ 'Select a machinery connection from the left menu' | translate }}
        </div>
      </div>
    </div-->
    <div class="row" *ngIf="machineryInfo">
      <div class="col-md-12">
          <h4>
            <i class="fa fa-info-circle fa-fw"></i>{{ 'Service' | translate }}
            <i class="warningIcon fa fa-exclamation-triangle fa-fw" style="color: orange" 
            [class.hidden]="!(serviceEngineInfo.next - serviceEngineInfo.current < 30 
                && serviceEngineInfo.next - serviceEngineInfo.current >= 10)
                && !(serviceKilometersInfo.next - serviceKilometersInfo.current < 50 
                && serviceKilometersInfo.next - serviceKilometersInfo.current >= 20)"></i>
            <i class="warningIcon fa fa-exclamation-triangle fa-fw" style="color: red"
            [class.hidden]="!(serviceEngineInfo.next - serviceEngineInfo.current < 10) 
              && !(serviceKilometersInfo.next - serviceKilometersInfo.current < 20)"></i>
          </h4>
          <table class="table">
            <tr>
              <td class="col-md-3"></td>
              <th class="col-md-2 center">{{ 'Base' | translate }}</th>
              <th class="col-md-2 center">{{ 'Current' | translate }}</th>
              <th class="col-md-2 center">{{ 'Next service' | translate }}</th>
              <th class="col-md-3 center">{{ 'Value per interval' | translate }}</th>
            </tr>
            <tr [class.warningY]="serviceEngineInfo.next - serviceEngineInfo.current < 30 
                && serviceEngineInfo.next - serviceEngineInfo.current >= 10"
                [class.warningR]="serviceEngineInfo.next - serviceEngineInfo.current < 10">
              <th class="col-md-3">{{ 'Engine hours' | translate }}:</th>
              <td class="col-md-2 center">{{serviceEngineInfo.base}}</td>
              <td class="col-md-2 center">{{serviceEngineInfo.current}}</td>
              <td class="col-md-2 center">{{serviceEngineInfo.next}}</td>
              <td class="col-md-3 center">{{machineryInfo.machineryServiceMaxEngineH}}</td>
            </tr>
            <tr [class.warningY]="serviceKilometersInfo.next - serviceKilometersInfo.current < 50 
                && serviceKilometersInfo.next - serviceKilometersInfo.current >= 20"
                [class.warningR]="serviceKilometersInfo.next - serviceKilometersInfo.current < 20">
              <th class="col-md-3">{{ 'Machinery kilometers' | translate }}:</th>
              <td class="col-md-2 center">{{serviceKilometersInfo.base}}</td>
              <td class="col-md-2 center">{{serviceKilometersInfo.current}}</td>
              <td class="col-md-2 center">{{serviceKilometersInfo.next}}</td>
              <td class="col-md-3 center">{{machineryInfo.machineryServiceMaxtKm}}</td>
            </tr>
          </table>
      </div>
    </div>
    <div class="row marginTop">
      <div class="col-md-6" *ngIf="!!connection && (!!connection.time || !!connection.distance)">
        <h4><i class="fa fa-info-circle fa-fw"></i>{{ 'SUM' | translate }}</h4>
        <table class="table">
          <tr *ngIf="connection.time">
            <th class="col-md-8">{{ 'Total time' | translate }}</th>
            <td class="col-md-4">{{calculateTime(connection.time)}}</td>
          </tr>
          <tr *ngIf="connection.distance">
            <th class="col-md-8">{{ 'Total distance' | translate }}</th>
            <td class="col-md-4">{{ connection.distance / 1000 | number:'1.1-2'}} km</td>
          </tr>
        </table>
      </div>
      <div class="col-md-6" *ngIf="machineryInfo">
        <h4><i class="fa fa-info-circle fa-fw"></i>{{ 'Machinery' | translate }}</h4>
        <table class="table">
          <tr *ngIf="machineryInfo.machineryName">
            <th class="col-md-8">{{ 'Name' | translate }}</th>
            <td class="col-md-4">{{machineryInfo.machineryName}}</td>
          </tr>
          <tr *ngIf="machineryInfo.machineryFrameNumber">
            <th class="col-md-8">{{ 'Frame number' | translate }}</th>
            <td class="col-md-4">{{machineryInfo.machineryFrameNumber}}</td>
          </tr>
          <tr *ngIf="machineryInfo.machineryPlateNumber">
            <th class="col-md-8">{{ 'Plate number' | translate }}</th>
            <td class="col-md-4">{{machineryInfo.machineryPlateNumber}}</td>
          </tr>
          <tr *ngIf="machineryInfo.machineryDescription">
            <th class="col-md-8">{{ 'Description' | translate }}</th>
            <td class="col-md-4">{{machineryInfo.machineryDescription}}</td>
          </tr>
          <tr *ngIf="machineryInfo.machineryNewType">
            <th class="col-md-8">{{ 'New type' | translate }}</th>
            <td class="col-md-4">{{machineryInfo.machineryNewType}}</td>
          </tr>
          <tr *ngIf="machineryInfo.machineryPower">
            <th class="col-md-8">{{ 'Power' | translate }}</th>
            <td class="col-md-4">{{machineryInfo.machineryPower}} <span
                *ngIf="machineryInfo.machineryPowerType">{{machineryInfo.machineryPowerType}}</span></td>
          </tr>
          <tr *ngIf="machineryInfo.machineryPurchaseDate">
            <th class="col-md-8">{{ 'Purchase date' | translate }}</th>
            <td class="col-md-4">{{toLocalDate(machineryInfo.machineryPurchaseDate)}}</td>
          </tr>
          <tr *ngIf="machineryInfo.machineryServiceMaxDays">
            <th class="col-md-8">{{ 'Max service days' | translate }}</th>
            <td class="col-md-4">{{machineryInfo.machineryServiceMaxDays}}</td>
          </tr>
          <tr *ngIf="machineryInfo.machineryUpcomingTechnicalDate">
            <th class="col-md-8">{{ 'Upcoming technical date' | translate }}</th>
            <td class="col-md-4">{{toLocalDate(machineryInfo.machineryUpcomingTechnicalDate)}}</td>
          </tr>
          <tr *ngIf="machineryInfo.machineryLastTechicalDate">
            <th class="col-md-8">{{ 'Last technical date' | translate }}</th>
            <td class="col-md-4">{{toLocalDate(machineryInfo.machineryLastTechicalDate)}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</app-form-layout>