import { Injectable } from '@angular/core';
import { TranslateService} from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { IYieldPredictionValues } from './models/model';
import { TRANSLATED_VALUES } from './constants/config';

@Injectable({
  providedIn: 'root'
})
export class YieldPredictionResolver {

  constructor(
    private translations    : TranslateService
  ) { }

  public resolve(): Observable<any>|Promise<any>|any {
    const translatedValues      : IYieldPredictionValues = TRANSLATED_VALUES;

    Object.keys(translatedValues).forEach(key => {
      this.translations.get(translatedValues[key])
        .subscribe(translatedValue => {
          translatedValues[key] = translatedValue;
        });
    });

    return Object.assign(translatedValues);
  }
}
