export enum JDRelationTypes {
    currentToken = 'currentToken',
    currentUser = 'currentUser',
    organizations = 'organizations'
}

export const PAGE_SIZE = 25;

export const CONTRIBUTION_PRODUCTS_ID = 'da94ec39-fbf4-431e-8780-bbe9ff7d3255';
export const CONTRIBUTION_DEFINITION_ID = '44b693d4-2571-4577-aba8-74e2076f58e5';

export const JD_CONTRIBUTION_ACTIVATION = {
    '@type': 'ContributionActivation',
    activationStatus: 'ACTIVATED',
    links: [{
        '@type': 'Link',
        rel: 'ContributionProduct',
        uri: `/contributionProducts/${CONTRIBUTION_PRODUCTS_ID}`
    }]
};

export const DEFAULT_SENSOR_GROUPS = [
    1,	/* Air temperature */
    2,	/* Relative humidity */
    3,	/* Soil temperature */
    4,	/* Solar radiation */
    5,	/* Precipitation */
    6,  /* Wind speed */
    7,	/* Battery voltage */
    8,  /* Wet bulb temperature */
    9,  /* Dry bulb temperature */
    10, /* Air pressure */
    11, /* Soil tension */
    12, /* Leaf wetness */
    14, /* Leaf temperature */
    15, /* Water temperature */
    16, /* Wind direction */
    17  /* Dew point */,
    22, /* Vapor pressure deficit */
    25, /* Water content */
    27, /* Water pressure */
    28, /* Water depth */
    33, /* Soil salinity in VIC */
    39, /* Snow depth */
    40, /* 32 bit counter */
    42, /* Water potential */
    43, /* Electrical conductivity */
    44, /* Electrical permitivity */
    45, /* Electrical resistance */
    47, /* Solar panel charger */
    48, /* Diameter from dendrometer */
    49, /* PH */
    64, /* Yara water sensor */
    66, /* Wind speed max */
    67, /* 32 bit accumulator */
    68  /* DeltaT */
];

export const enum PageFetchType {
    'FetchMany' = 'FetchMany',
    'FetchOne' = 'FetchOne'
}
