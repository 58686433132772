import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import * as moment from 'moment';
import { renewCropzones, showInfoModal, updateManagementConfig } from './../../actions/cropzoneManagement';
import { Store, select } from '@ngrx/store';
import * as fromManagement from '../../reducers/cropzoneManagement';
import { deepClone } from './../../../../shared/utils/deepClone';
import { IFarm } from './../../../../core/models/farms';
import { IField } from './../../../../core/models/fields';
import { CropzoneManagementService } from './../../services/cropzone-management.service';
import { ICropZone } from './../../../../core/models/cropzones';
import { selectNewCropzones } from '../../reducers/cropzoneManagement';
import { takeUntil, filter } from 'rxjs/operators';

@Component({
  selector: 'app-renew-cropzones-list',
  templateUrl: './renew-cropzones-list.component.html',
  styleUrls: ['./renew-cropzones-list.component.scss']
})
export class RenewCropzonesListComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public selectedLicense;
  @Input()
  public cropzones: ICropZone[];
  @Output()
  public cancelEmit: EventEmitter<void> = new EventEmitter<void>();

  public form: FormGroup;
  public cropzoneGroups: FormArray;
  public minDate: Date;
  public maxDate: Date;
  public farmsList: IFarm[];
  public fieldsList: IField[];
  public submitClicked: boolean = false;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public isTempData: boolean = false;

  constructor(
    private fb: FormBuilder,
    private cropzoneManagementStore: Store<fromManagement.ICropzoneManagementState>,
    private managementService: CropzoneManagementService
  ) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.cropzones) {
      this.submitClicked = false;
    }
  }

  public ngOnInit(): void {
    this.cropzoneManagementStore.pipe(
      select(selectNewCropzones),
      filter((cropzones) => !!cropzones),
      takeUntil(this.destroy$)
    ).subscribe((cropzones) => {
      if (cropzones.length > 0) {
        this.cropzones = cropzones;
        this.isTempData = true;
      }
    });

    this.cropzoneGroups = this.fb.array(this.getControlArray().map((cropzone) => this.fb.group(cropzone)));
    this.form = this.fb.group({
      cropzones: this.cropzoneGroups
    });

    this.minDate = moment(this.selectedLicense.start_date).toDate();
    this.maxDate = moment(this.selectedLicense.end_date).toDate();
  }

  public getControlArray(): any {
    const controlArray = [];
    this.cropzones.forEach((cz) => {
      const fromMoment: moment.Moment = moment(cz.from);
      const toMoment: moment.Moment = moment(cz.to);
      const startMoment: moment.Moment = moment(this.selectedLicense.start_date);
      const endMoment: moment.Moment = moment(this.selectedLicense.end_date);

      let fromNextYear = fromMoment.isAfter(endMoment) ? endMoment.toDate() : fromMoment.clone().add(1, 'year').toDate();
      fromNextYear = moment(fromNextYear).isBefore(startMoment) ? startMoment.toDate() : fromNextYear;
      let toNextYear = toMoment.isAfter(endMoment) ? endMoment.toDate() : toMoment.clone().add(1, 'year').toDate();
      toNextYear = moment(toNextYear).isBefore(startMoment) ? startMoment.toDate() : toNextYear;
      const startLicense = startMoment.format('DD-MM-YYYY');
      const endLicense = endMoment.format('DD-MM-YYYY');

      controlArray.push({
        farm: [cz.farm.name, Validators.required],
        farmId: [cz.farm.id, Validators.required],
        field: [cz.field.name, Validators.required],
        fieldId: [cz.field.id, Validators.required],
        cropzone: [cz.name, Validators.required],
        crop_name: [cz.crop_name, Validators.required],
        from: [
          this.isTempData ? moment(cz.from).toDate()
          : moment(fromNextYear).isAfter(endMoment) ? endMoment.toDate()
          : fromNextYear, Validators.required
        ],
        to: [this.isTempData ? moment(cz.to).toDate()
          : moment(toNextYear).isAfter(endMoment) ? endMoment.toDate()
          : toNextYear, Validators.required],
        start_license: [startLicense, Validators.required],
        end_license: [endLicense, Validators.required],
        boundary: [cz.boundary, Validators.required],
        crop: [cz.crop, Validators.required],
        data_sources: [cz.data_sources, Validators.required],
        irrigation_events: [cz.irrigation_events, Validators.required],
        rain_efficacies: [cz.rain_efficacies, Validators.required],
        soil: [cz.soil, Validators.required],
        fromToOverEndLicense: [moment(fromNextYear).isAfter(endMoment) && moment(toNextYear).isAfter(endMoment), Validators.required],
        toOverEndLicense: [moment(toNextYear).isAfter(endMoment) && !moment(fromNextYear).isAfter(endMoment), Validators.required],
        fromToBeforeLicense: [moment(fromNextYear).isBefore(startMoment) && moment(toNextYear).isBefore(startMoment), Validators.required],
        fromBeforeLicense: [moment(fromNextYear).isBefore(startMoment), Validators.required]
      });
    });
    return controlArray;
  }

  public submit(): void {
    const cropzones = this.form.value.cropzones.map((c, index) => {

      return {
        ...this.cropzones[index],
          field_id: c.fieldId,
          boundary: c.boundary,
          crop: c.crop ? this.managementService.setCropDates(this.cropzones[index], c) : null,
          crop_name: c.crop_name,
          data_sources: c.data_sources,
          from: moment(c.from).format('YYYY-MM-DD[T]HH:mm:ss') + '+00:00',
          irrigation_events: [],
          rain_events: [],
          cached_lai_stats: [],
          cached_lai_values: [],
          cached_ndvi_stats: [],
          cached_ndvi_values: [],
          name: c.cropzone,
          product_key: this.selectedLicense.product_item.key,
          rain_efficacies: c.rain_efficacies,
          soil: c.soil,
          to: moment(c.to).format('YYYY-MM-DD[T]HH:mm:ss') + '+00:00',
          duplication_date: moment().format('YYYY-MM-DD[T]HH:mm:ss') + '+00:00'
      };
    });

    this.submitClicked = true;
    this.changeActiveModal(false, false, true);

    this.cropzoneManagementStore.dispatch(showInfoModal(cropzones));
  }

  public isValidCultivationPeriod(from: any, to: any): boolean {
    const diff: number = moment(from).diff(moment(to), 'years', true);
    return diff < -1 || diff > 1 ? false : true;
  }

  public saveDisabled(): boolean {
    let disabled: boolean = false;
    const cropzones = this.form.value.cropzones;

    cropzones.forEach((c) => {
      const diff: number = moment(c.from).diff(moment(c.to), 'years', true);
      disabled = diff < -1 || diff > 1 ? true : disabled;
    });

    return disabled;
  }

  public isFromToBeforeLicense(from: any, to: any): boolean {
    const fromIsBeforeLicenseStart = moment(from).clone().subtract(2, 'hours').isBefore(moment(this.selectedLicense.start_date));
    const toIsBeforeLicenseStart = moment(to).clone().subtract(2, 'hours').isBefore(moment(this.selectedLicense.start_date));
    return fromIsBeforeLicenseStart && toIsBeforeLicenseStart;
  }

  public isFromBeforeLicense(from: any, to: any): boolean {
    const fromIsBeforeLicenseStart = moment(from).clone().subtract(2, 'hours').isBefore(moment(this.selectedLicense.start_date));
    const toIsBeforeLicenseStart = moment(to).clone().subtract(2, 'hours').isBefore(moment(this.selectedLicense.start_date));
    return fromIsBeforeLicenseStart && !toIsBeforeLicenseStart;
  }

  public fromToBeforeLicense(): boolean {
    let disabled: boolean = false;
    const cropzones: ICropZone[] = this.form.value.cropzones;
    const startMoment: moment.Moment = moment(this.selectedLicense.start_date);

    cropzones.forEach((cropzone) => {
      if (
      moment(cropzone.from).clone().subtract(2, 'hours').isBefore(startMoment) &&
      moment(cropzone.to).clone().subtract(2, 'hours').isBefore(startMoment)
      ) {
        disabled = true;
      }
    });

    return disabled;
  }

  public fromBeforeLicense(): boolean {
    let disabled: boolean = false;
    const cropzones = this.form.value.cropzones;
    const startMoment: moment.Moment = moment(this.selectedLicense.start_date);

    cropzones.forEach(cropzone => {
      if (
      moment(cropzone.from).clone().subtract(2, 'hours').isBefore(startMoment) &&
      !moment(cropzone.to).clone().subtract(2, 'hours').isBefore(startMoment)
      ) {
        disabled = true;
      }
    });

    return disabled;
  }

  public isFromToOverLicense(from: any, to: any): boolean {
    const fromIsAfterLicenseEnd = moment(from).isAfter(moment(this.selectedLicense.end_date));
    const toIsAfterLicenseEnd = moment(to).isAfter(moment(this.selectedLicense.end_date));
    return  fromIsAfterLicenseEnd && toIsAfterLicenseEnd;
  }

  public isToOverLicense(from: any, to: any): boolean {
    const fromIsAfterLicenseEnd = moment(from).isAfter(moment(this.selectedLicense.end_date));
    const toIsAfterLicenseEnd = moment(to).isAfter(moment(this.selectedLicense.end_date));
    return !fromIsAfterLicenseEnd && toIsAfterLicenseEnd;
  }

  public fromToOverEndLicense(): boolean {
    let disabled: boolean = false;
    const cropzones = this.form.value.cropzones;
    const endMoment = moment(this.selectedLicense.end_date);

    cropzones.forEach(cropzone => {
      if (moment(cropzone.from).isAfter(endMoment) && moment(cropzone.to).isAfter(endMoment)) {
        disabled = true;
      }
    });

    return disabled;
  }

  public toOverEndLicense(): boolean {
    let disabled: boolean = false;
    const cropzones = this.form.value.cropzones;
    const endMoment = moment(this.selectedLicense.end_date);

    cropzones.forEach(cropzone => {
      if (!moment(cropzone.from).isAfter(endMoment) && moment(cropzone.to).isAfter(endMoment)) {
        disabled = true;
      }
    });

    return disabled;
  }

  private changeActiveModal(licenseModal: boolean, duplicationModal: boolean, cropTypemodal: boolean): void {
    this.cropzoneManagementStore.dispatch(
      updateManagementConfig({
        licenseModalActive: licenseModal,
        duplicationModalActive: duplicationModal,
        cropTypeModalActive: cropTypemodal
      })
    );
  }

  public back(): void {
    this.cropzoneManagementStore.dispatch(showInfoModal([]));
    this.isTempData = false;
    this.changeActiveModal(true, false, false);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
