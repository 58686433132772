<app-chart-toolbar-layout class="station-disease-settings">
  <form class="station-disease-settings"
        (keydown.enter)="$event.preventDefault()"
        *ngIf="form"
        [formGroup]="form">
    <div class="station-disease-settings__left">
      <div class="station-disease-settings__toggle station-disease-settings__left__item">
        <app-custom-button [color]="isLastDataMode.value ? 'dark-green' : 'green'"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'Last data mode' | translate"
                           [shadow]="false"
                           [radius]="'2px 0 0 2px'"
                           (click)="setDateRange();isLastDataMode.setValue(true)"
                           [content]="''">
          <span class="fa fa-step-backward"></span>
        </app-custom-button>
        <app-custom-button [color]="!isLastDataMode.value ? 'dark-green' : 'green'"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'From - To mode' | translate"
                           [shadow]="false"
                           [radius]="'0 2px 2px 0'"
                           (click)="isLastDataMode.setValue(false)"
                           [content]="''">
          <span class="fa fa-calendar"></span>
        </app-custom-button>
      </div>

      <ng-container *ngIf="!isLastDataMode.value">
        <app-custom-datepicker [isSmall]="true"
                               [minDate]="fromStation"
                               [maxDate]="toDatepicker.value"
                               [formControl]="fromDatepicker">
        </app-custom-datepicker>
        <app-custom-datepicker [isSmall]="true"
                               [maxDate]="toStation"
                               [minDate]="fromDatepicker.value"
                               class="station-disease-settings__left__item"
                               [formControl]="toDatepicker">
        </app-custom-datepicker>
      </ng-container>

      <app-custom-dropdown [options]="periodValueSelectItems"
                           [isSetDefault]="false"
                           [hidden]="!isLastDataMode.value"
                           class="station-disease-settings__left__item"
                           [formControl]="periodValue"></app-custom-dropdown>
      <app-date-range-arrow [formControl]="fromTo"
                            (rangeClick)="refresh()"
                            [hidden]="!isLastDataMode.value"
                            [minDate]="fromStation"
                            [maxDate]="toStation"
                            [period]="periodValue.value">
      </app-date-range-arrow>
    </div>

    <div class="station-disease-settings__right">
      <div class="station-disease-settings__toggle
                  station-disease-settings__right__item
                  station-disease-settings__export"
           *ngIf="isExportActive.value">
        <app-custom-button [shadow]="false"
                           appTooltip
                           (click)="startExportImage()"
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'Export charts as image' | translate"
                           [color]="'white'">
          <i *ngIf="!isExportImageActive.value; else imgExport"
             class="glyphicon glyphicon-download-alt"></i>
          <ng-template #imgExport>
            <i class="fa fa-cog fa-spin fa-fw"></i>
          </ng-template>
        </app-custom-button>
        <app-custom-button [shadow]="false"
                           (click)="startExportXLS()"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'Export table as xls' | translate"
                           [color]="'white'">
          <i class="fa fa-table"></i>
        </app-custom-button>
      </div>

      <div class="station-disease-settings__toggle station-disease-settings__right__item">
        <app-custom-button [color]="isChartActive.value ? 'green' : 'white'"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'Toggle charts' | translate"
                           [shadow]="false"
                           (click)="isChartActive.setValue(!isChartActive.value)"
                           [radius]="'2px 0 0 2px'"
                           [content]="''">
          <span class="fa fa-bar-chart-o"></span>
        </app-custom-button>
        <app-custom-button [color]="isTableActive.value ? 'green' : 'white'"
                           appTooltip
                           [tooltipPlacement]="'top'"
                           [tooltipTitle]="'Toggle table' | translate"
                           [shadow]="false"
                           (click)="isTableActive.setValue(!isTableActive.value)"
                           [radius]="'0 2px 2px 0'"
                           [content]="''">
          <span class="fa fa-table"></span>
        </app-custom-button>
      </div>

      <app-custom-button *ngIf="isFireBlightActive.value"
                         class="station-disease-settings__right__item"
                         (click)="openModal()"
                         [content]="'Settings'">
      </app-custom-button>

      <app-custom-button [color]="'red'"
                         class="station-disease-settings__right__item"
                         (click)="refresh()"
                         [content]="'Refresh'">
        <i class="fa fa-fw fa-refresh"></i>
      </app-custom-button>
    </div>
  </form>
</app-chart-toolbar-layout>

<app-modal [modalId]="modalId">
  <app-station-disease-fireblight (close)="closeModal()"></app-station-disease-fireblight>
</app-modal>
