import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MemoizedSelector, select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import {
  ICameraPest,
  ICameraPestMap,
  IPestToggler,
  IPicture,
  IToolbarSettings
} from '../../../../shared/camera/models/camera';
import {
  IMultipleRectangleSelectedEvent,
  IRectangle,
  IRectangleSelected,
  IRectangleSelectedEvent
} from '../../../../shared/camera/models/rectangles';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { getWeekForCameraPicture } from '../../../../shared/utils/dateFormat';
import { filterNewRectangles } from '../../../../shared/utils/rectangle-filter';
import { generateId } from '../../../dashboard/utils/makeWidget';
import {
  addIscoutSelectedPictureRectangle,
  removeIscoutSelectedPictureMultipleRectangles,
  removeIscoutSelectedPictureRectangle,
  setIscoutSettingsToolbarSettings,
  updateIscoutSelectedPictureMultipleRectangles,
  updateIscoutSelectedPictureRectangle
} from '../../actions/iscout-settings';
import { IIscoutContextMenuPosition } from '../../components/iscout-context-menu/iscout-context-menu.component';
import { IIscoutSettingsState, IIscoutState } from '../../models/iscout.models';
import {
  selectIscoutFilteredRectangles,
  selectIscoutGeneralPests,
  selectIscoutSettingsAreMeasurementsActive,
  selectIscoutSettingsForToolbar,
  selectIscoutSettingsPestTogglers,
  selectIscoutUserPests
} from '../../reducers';
import { saveOtherSettings, updateFlags } from '../../../../core/actions/account';
import * as fromAccount from '../../../../core/reducers/account';
import { selectUserFlags } from '../../../../core/reducers';
import { IFlagSettings } from '../../../../core/models/account';

@Component({
  selector: 'app-iscout-image-settings',
  templateUrl: './iscout-image-settings.component.html',
  styleUrls: ['./iscout-image-settings.component.scss']
})
export class IscoutImageSettingsComponent implements OnInit, OnDestroy {
  @Input()
  public title: string;
  @Input()
  public hasWritePermission: boolean;
  @Input()
  public helpIsActive$: Observable<boolean>;
  @Input()
  public selectedPicture$: Observable<IPicture>;

  @Output()
  public updatePestToggle: EventEmitter<IPestToggler> = new EventEmitter<IPestToggler>();
  @Output()
  public unselectPictureEmitter: EventEmitter<void> = new EventEmitter<void>();

  public toolbarSettings$: Observable<IToolbarSettings>;
  public measurementsAreActive$: Observable<boolean>;
  public pestToggles$: Observable<Array<IPestToggler>>;
  public availablePests$: Observable<Array<Array<ICameraPest>>>;
  public selectedRectangleLabel$: BehaviorSubject<string> = new BehaviorSubject('');
  public userFlags: IFlagSettings = null;

  public contextMenuOpen: boolean = false;
  public contextMenuPestList: ICameraPestMap = {};
  public contextMenuPosition: IIscoutContextMenuPosition;
  public contextMenuRectangle: IRectangleSelected;
  public contextMenuMultipleRectangles: IRectangleSelected[];

  public helpModalId: string = generateId();
  public filteredRectangles: Array<IRectangle> = [];
  public iscoutRectangleOperation$: Subject<boolean> = new Subject<boolean>();
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private iscoutStore: Store<IIscoutState>,
              private iscoutSettingsStore: Store<IIscoutSettingsState>,
              private accountStore: Store<fromAccount.IAccount>,
              private modalService: ModalService) {}

  public ngOnInit(): void {
    this.initPestsListener();
    this.initSettingsListener();
    this.initUserFlagListeners();
    this.initStatusListeners();
    this.initPictureListeners();
  }

  public handleUnselectPicture(): void {
    this.unselectPictureEmitter.emit();
  }

  public handleUpdatePestToggler(pestToggler: IPestToggler): void {
    pestToggler.is_active = !pestToggler.is_active;
    this.updatePestToggle.emit(pestToggler);
  }

  public handleChangeSelectedPest(pestName: string): void {
    this.selectedRectangleLabel$.next(pestName);
  }

  public handleShowRectangleOptions({value, position}: IRectangleSelectedEvent): void {
    this.contextMenuRectangle = value;
    this.contextMenuMultipleRectangles = null;
    this.contextMenuPosition = {
      coordinateX: position.x,
      coordinateY: position.y
    };
    this.contextMenuOpen = true;
  }

  public handleShowMultipleRectanglesOptions({value, position}: IMultipleRectangleSelectedEvent): void {
    this.contextMenuRectangle = null;
    this.contextMenuMultipleRectangles = value;
    this.contextMenuPosition = {
      coordinateX: position.x,
      coordinateY: position.y
    };
    this.contextMenuOpen = true;
  }

  public handleRectangleOptionsChange(pest: ICameraPest): void {
    if (this.contextMenuRectangle === null) {
      this.handleMultipleRectanglesChanges(pest);
    } else {
      this.handleSingleRectangleChange(pest);
    }

    this.iscoutRectangleOperation$.next(true);
    this.contextMenuOpen = false;
  }

  private handleSingleRectangleChange({name}: ICameraPest): void {
    const updatedRectangle = {...this.contextMenuRectangle.rectangle, label: name};
    this.iscoutSettingsStore.dispatch(updateIscoutSelectedPictureRectangle(
      updatedRectangle,
      this.contextMenuRectangle.rectangleIndex
    ));
  }

  private handleMultipleRectanglesChanges({name}: ICameraPest): void {
    // Need to replace all rectangle labels for the selected pest name
    const updatedRectangles = this.contextMenuMultipleRectangles.map((selected) => ({
      rectangleIndex: selected.rectangleIndex,
      rectangle: {
        ...selected.rectangle,
        label: name
      }
    }));
    this.iscoutSettingsStore.dispatch(updateIscoutSelectedPictureMultipleRectangles(updatedRectangles));
  }

  public handleAddRectangle(selectedRectangle: IRectangleSelectedEvent): void {
    this.iscoutSettingsStore.dispatch(addIscoutSelectedPictureRectangle(selectedRectangle.value.rectangle));
    this.iscoutRectangleOperation$.next(true);
  }

  public handleUpdateRectangle(selectedRectangle: IRectangleSelectedEvent): void {
    this.iscoutSettingsStore.dispatch(updateIscoutSelectedPictureRectangle(
      selectedRectangle.value.rectangle,
      selectedRectangle.value.rectangleIndex
    ));
    this.iscoutRectangleOperation$.next(true);
  }

  public handleRemoveRectangle(selectedRectangle: IRectangleSelectedEvent): void {
    this.iscoutSettingsStore.dispatch(removeIscoutSelectedPictureRectangle(selectedRectangle.value));
    this.iscoutRectangleOperation$.next(true);
  }

  public handleRemoveMultipleRectangles({value}: IMultipleRectangleSelectedEvent): void {
    this.iscoutSettingsStore.dispatch(removeIscoutSelectedPictureMultipleRectangles(value));
    this.iscoutRectangleOperation$.next(true);
  }

  public handleOpenHelpModal(): void {
    this.modalService.openModal(this.helpModalId);
  }

  public get totalRectanglesCount(): number {
    return this.filteredRectangles.length;
  }

  public get newRectanglesCount(): number {
    return filterNewRectangles(this.filteredRectangles).length;
  }

  public getCurrentDateForImage(pictureTime): string {
    return getWeekForCameraPicture(pictureTime);
  }

  public updateInfoFlag(type : string): void {
    this.toolbarSettings$.subscribe((toolbarSettings: IToolbarSettings): void => {
      if (type === 'save') {
        this.accountStore.dispatch(updateFlags({
          iscout: {
            ...this.userFlags?.iscout,
            saveInsectFlag: true
          }
        }));

      } else if (type === 'update') {
        this.accountStore.dispatch(updateFlags({
          iscout: {
            ...this.userFlags?.iscout,
            updateInsectFlag: true
          }
        }));
      }

      this.accountStore.dispatch(saveOtherSettings({
        flags: this.userFlags
      }));
      this.iscoutSettingsStore.dispatch(setIscoutSettingsToolbarSettings(toolbarSettings));
    });
  }

  private initSettingsListener(): void {
    this.toolbarSettings$ = this.iscoutSettingsStore.pipe(
      take(1),
      select(selectIscoutSettingsForToolbar)
    );


    this.pestToggles$ = this.iscoutSettingsStore.pipe(
      select(selectIscoutSettingsPestTogglers)
    );
  }

  private initStatusListeners(): void {
    this.measurementsAreActive$ = this.iscoutSettingsStore.pipe(
      select(selectIscoutSettingsAreMeasurementsActive)
    );
  }

  private initPictureListeners(): void {
    this.iscoutStore.pipe(
      select(selectIscoutFilteredRectangles)
    ).subscribe((filteredRectangles: Array<IRectangle>) => {
      this.filteredRectangles = filteredRectangles;
    });
  }

  private initUserFlagListeners(): void {
    this.accountStore.pipe(
      select(selectUserFlags),
      takeUntil(this.destroy$)
    ).subscribe(userFlags => {
      this.userFlags = userFlags;
    });
  }

  private initPestsListener(): void {
    this.availablePests$ = combineLatest([
      this.getPestsObservable(selectIscoutUserPests),
      this.getPestsObservable(selectIscoutGeneralPests)
    ]).pipe(tap(([userPests, generalPests]) => {
      const pestReducer = function (result, current: ICameraPest): ICameraPestMap {
        return {
          ...result,
          [current.order.name]: (result[current.order.name] || []).concat(current)
        };
      };
      this.contextMenuPestList = userPests.concat(generalPests).reduce(pestReducer, {});
    }));
  }

  private getPestsObservable(selector: MemoizedSelector<object, Array<ICameraPest>>): Observable<Array<ICameraPest>> {
    return this.iscoutStore.pipe(
      takeUntil(this.destroy$),
      select(selector),
      map((pests: Array<ICameraPest>): Array<ICameraPest> => pests.length > 0 ? pests : [])
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
