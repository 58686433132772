export const ADD_CROPZONE_MODAL_ID = 'ADD_CROPZONE_MODAL_ID';
import { ICropzoneManagementSort, ICropzoneManagementState } from './../reducers/cropzoneManagement';
export const RENEW_CROPZONES_MODAL_ID = 'RENEW_CROPZONES_MODAL_ID';
export const REMOVE_FARM_CONFIRMATION_ID = 'REMOVE_FARM_CONFIRMATION_ID';
export const REMOVE_FIELD_CONFIRMATION_ID = 'REMOVE_FIELD_CONFIRMATION_ID';
export const REMOVE_CROPZONE_CONFIRMATION_ID = 'REMOVE_CROPZONE_CONFIRMATION_ID';

export const managementHeaders = [
    { name: 'FARM', hasButton: true},
    { name: 'FIELD', hasButton: true},
    { name: 'CROPZONE', hasButton: true},
    { name: 'CROP', hasButton: true},
    { name: 'FROM', hasButton: true},
    { name: 'TO', hasButton: true},
    { name: 'START', hasButton: false},
    { name: 'EXPIRY', hasButton: false},
    { name: 'CROPZONE DUPLICATION', hasButton: false}
];

export const defaultManagementSort: ICropzoneManagementSort[] = [
    {sortingItem: 'FARM', sortingOrder: 'ascending'},
    {sortingItem: 'FIELD', sortingOrder: 'ascending'},
    {sortingItem: 'CROPZONE', sortingOrder: 'ascending'},
    {sortingItem: 'CROP', sortingOrder: 'none'},
    {sortingItem: 'FROM', sortingOrder: 'none'},
    {sortingItem: 'TO', sortingOrder: 'none'}
];

export const managementInitialState: ICropzoneManagementState = {
    itemToEdit: null,
    cropzoneManagementSort: defaultManagementSort,
    newCropzones: [],
    managementCropsLazyLoading: [],
    config : {
      licenseModalActive: false,
      duplicationModalActive: false,
      cropTypeModalActive: false
    }
};
