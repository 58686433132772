<div class="chart-container">
  <p class="chart-container__description">{{ viewDescription | translate }}</p>

  <app-widget-info *ngIf="!charts.length">
    <h4><i class="fa fa-warning fa-fw"></i> {{ 'No charts available' | translate }}</h4>
  </app-widget-info>

  <ng-container *ngFor="let item of charts">
    <div [chart]="item" class="hello"></div>
  </ng-container>
</div>
