<div [formGroup]="form" class="add-remove-form">

  <div class="add-remove-form__fields">
    <div class="add-remove-form__fields__item">
      <h5 class="add-remove-form__fields__item__label">{{ 'Farm name' | translate }}</h5>

      <input [formControl]="farm"
             [typeahead]="farmlist"
             [typeaheadScrollable]="true"
             [typeaheadOptionsInScrollableView]="5"
             typeaheadOptionField="name"
             (typeaheadOnSelect)="onSelect($event)"
             class="add-remove-form__fields__item__input__padding">

    </div>
    <div class="add-remove-form__fields__item">
      <h5 class="add-remove-form__fields__item__label">{{ 'Field name' | translate }}</h5>
      <app-form-input [formControl]="field"
                      type="border"
                      class="add-remove-form__fields__item__input">
      </app-form-input>
    </div>
  </div>
  <div class="add-remove-form__buttons">
    <app-custom-button [content]="'Cancel'" (click)="close()"></app-custom-button>
    <app-custom-button [color]="submitColor"
                       [disabled]="form.invalid"
                       (click)="submitEmit()"
                       [content]="submitContent">
    </app-custom-button>
  </div>
</div>
