import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { formatDateNoTime } from '../../../../shared/utils/dateFormat';
import { getTimeString } from '../../../../shared/utils/getTimeString';
import { getMachineryInfo, getMachineryReports, getTrackerInfo } from '../../actions/tracker';
import * as fromTracker from './../../reducers/index';


@Component({
  selector: 'app-tracker-reports',
  templateUrl: './tracker-reports.component.html',
  styleUrls: ['./tracker-reports.component.scss']
})
export class TrackerReportsComponent implements OnInit, OnDestroy {
  public trackerInfo: Object = null;
  public machineryInfo: any = null;
  public connection: fromTracker.IConnection;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public serviceEngineInfo = { base: 0, current: 0, next: 0, interval: 0 };
  public serviceKilometersInfo = { base: 0, current: 0, next: 0, interval: 0 };

  constructor(private trackerStore: Store<fromTracker.ITrackerDataState>, private api: ApiCallService) {}

  public ngOnInit(): void {
    this.trackerStore.pipe(
      takeUntil(this.destroy$),
      select(fromTracker.selectSelectedConnection)
    ).subscribe(
      data => {
        this.connection = data;
        if (data instanceof Object && data.trackerID) {
          this.trackerStore.dispatch(getTrackerInfo(data.trackerID));
        }

        if (data instanceof Object && data.machineryID) {
          this.trackerStore.dispatch(getMachineryInfo(data.machineryID));

          const from = moment(data.fromDate).unix();
          // const from = moment(data.fromDate).toDate();
          let to = moment().unix();
          if (data.toDate) {
            to = moment(data.toDate).unix();
          }
          this.api.getMachineryServiceLogFromTo(data.machineryID, from, to).subscribe(
            res => {
              if (res.length > 0) {
                this.serviceEngineInfo.base = res[0].h;
                this.serviceKilometersInfo.base = res[0].km;

                if (this.machineryInfo) {
                  this.serviceEngineInfo.next = (+res[0].h) + (+(<any>this.machineryInfo).machineryServiceMaxEngineH);
                  this.serviceKilometersInfo.next = (+res[0].km) + (+(<any>this.machineryInfo).machineryServiceMaxtKm);

                  this.serviceEngineInfo.current = (+res[0].h) + (+res[0].currentH);
                  this.serviceKilometersInfo.current = (+res[0].km) + (+res[0].currentKm);
                }
              }
            }
          );
        }
      }
    );

    this.trackerStore.pipe(
      takeUntil(this.destroy$),
      select(fromTracker.selectTrackerInfo)
    ).subscribe(data => {
      this.trackerInfo = data;
    });

    this.trackerStore.pipe(
      takeUntil(this.destroy$),
      select(fromTracker.selectMachineryInfo)
    ).subscribe(data => {
      this.machineryInfo = data;
    });
  }

  public getMachineryReport(machineryID: string): void {
    this.trackerStore.dispatch(getMachineryReports(machineryID));
  }

  public toLocalDate(date: Date): string {
    return formatDateNoTime(date);
  }

  public calculateTime (time: number): string {
    return getTimeString(time);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
