<div class="xarvio-modal">
  <p>
    {{ 'As an iMetos station and FieldClimate user, you have the possibility to see selected stations with their ' +
       'last sensor readings on Xarvio if you have a Xarvio user account.' | translate }}
  </p>
  <p>
    {{ 'It can take 60 minutes after the click on Synchronize before your sharing key is usable in Xarvios ' +
       'FieldManager.' | translate }}
  </p>
</div>
