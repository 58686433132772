<div class="add-remove">
  <ng-container *ngIf="(isExecuting$ | async) === false; else executingAddCropZone">
    <div class="add-remove__header">
      <div (click)="addCropzone()"
           [class.add-remove__header-item__inactive]="!isAddCropzone"
           class="add-remove__header-item">
        <i class="zmdi zmdi-plus-circle"></i> {{ 'Cropzone' | translate }}
      </div>
      <div (click)="addField()"
           [class.add-remove__header-item__inactive]="!isAddField"
           class="add-remove__header-item">
        <i class="zmdi zmdi-plus-circle"></i> {{ 'Field' | translate }}
      </div>
      <div (click)="addFarm()"
           [class.add-remove__header-item__inactive]="!isAddFarm"
           class="add-remove__header-item">
        <i class="zmdi zmdi-plus-circle"></i> {{ 'Farm' | translate }}
      </div>
    </div>
    <div class="add-remove__form">
      <div *ngIf="!isLicensing" class="alert alert-info">
        <ng-container *ngIf="isAddCropzone">
          &bull; {{ 'Farm and Field name: select from the existing names by typing the first letters or type a new Farm and Field name.' | translate }}
          <br>
          &bull; {{ 'Cropzone name: add a new Cropzone (it is recommended to include a year in the Cropzone name: e.g. Nexttoroad19).' | translate }}
        </ng-container>
        <ng-container *ngIf="isAddField">
          &bull; {{ 'Farm name: select from the existing names by typing the first letters or type a new Farm name.' | translate }}
          <br>
          &bull; {{ 'Field name: add a new Field name.' | translate }}
        </ng-container>
        <ng-container *ngIf="isAddFarm">
          &bull; {{ 'Farm name: add a new Farm name.' | translate }}
        </ng-container>
      </div>
      <app-add-cropzone-form
        *ngIf="isAddCropzone && !isLicensing"
        (submit)="enableLicensing($event)">
      </app-add-cropzone-form>
      <app-add-field-form
        *ngIf="isAddField"
        (submit)="submitField($event)">
      </app-add-field-form>
      <app-add-farm-form
        *ngIf="isAddFarm"
        (submit)="submitFarm($event)">
      </app-add-farm-form>

      <app-product-license
        *ngIf="isAddCropzone && isLicensing"
        [licenseType]="licenseType"
        (selectLicense)="submitLicensing($event)"
        (cancel)="disableLicensing()">
      </app-product-license>
    </div>
  </ng-container>

  <ng-template #executingAddCropZone>
    <div class="add-remove__executing">
      <h4>
        <i class="fa fa-cog fa-spin fa-fw"></i>
        {{ 'Creating a new CropZone...' | translate }}
      </h4>
    </div>
  </ng-template>

</div>
