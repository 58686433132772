import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { StationDataSettingsActionTypes } from '../actions/station-data-settings';
import { IStationSettingsActivity, IStationSettingsPeriod } from '../../../shared/interfaces';

export interface IStationDataSettingsState {
  period                : IStationSettingsPeriod;
  activity              : IStationSettingsActivity;
  isCalculationEnabled  : boolean;
}

export const initialState: IStationDataSettingsState = {
  period: {
    periodScope: 'hourly',
    periodValue: '24h',
    fromTo: null,
    fromDatepicker: null,
    toDatepicker: null,
    stationId: '',
    isLastDataMode: true
  },
  activity: {
    isChartActive: true,
    isTableActive: true,
    isExportImageActive: false,
    isExportActive: false
  },
  isCalculationEnabled: false
};

export function reducer(state: IStationDataSettingsState = initialState, action: IActionWithPayload): IStationDataSettingsState {
  switch (action.type) {
    case StationDataSettingsActionTypes.SET_STATION_DATA_SETTINGS:
      return {
        ...state,
        ...action.payload
      };
    case StationDataSettingsActionTypes.SET_STATION_CALCULATION_ENABLED:
      return {
        ...state,
        isCalculationEnabled: action.payload
      };
    case StationDataSettingsActionTypes.SET_STATION_CHART_ACTIVE:
      return {
        ...state,
        activity: {
          ...state.activity,
          isChartActive: action.payload
        }
      };
    case StationDataSettingsActionTypes.SET_STATION_TABLE_ACTIVE:
      return {
        ...state,
        activity: {
          ...state.activity,
          isTableActive: action.payload
        }
      };
    case StationDataSettingsActionTypes.SET_STATION_EXPORT_ACTIVE:
      return {
        ...state,
        activity: {
          ...state.activity,
          isExportActive: action.payload
        }
      };
    default:
      return state;
  }
}
