import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AuthRoutingModule} from './auth-routing.module';

import {FormButtonComponent} from './components/form-button/form-button.component';
import {NavigateButtonComponent} from './components/navigate-button/navigate-button.component';
import {SharedModule} from '../../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    TranslateModule.forChild()
  ],
  declarations: [
    FormButtonComponent,
    NavigateButtonComponent
    ],
  exports: [
    FormButtonComponent,
    NavigateButtonComponent,
    SharedModule
  ]
})
export class AuthModule { }
