import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {ApiCallService} from '../../../services/api/api-call.service';
import {Observable, of} from 'rxjs';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {
  setStationDataError,
  setStationDataFc,
  setStationDataLicenses,
  setStationDataLoading,
  setStationDataProfiles,
  setStationDataWarningMessage,
  StationDataActionTypes
} from '../actions/station-data';
import { catchError, switchMap, tap } from 'rxjs/operators';
import {IFcResponse} from '../models/station-data.models';
import {from} from 'rxjs';
import { Store } from '@ngrx/store';
import { IStationDataState } from '../reducers/station-data';

@Injectable()
export class StationDataService {

  constructor(
    private api: ApiCallService,
    private actions$: Actions,
    private store: Store<IStationDataState>
  ) { }

  @Effect()
  public getStationDataProfiles$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(StationDataActionTypes.GET_STATION_DATA_PROFILES),
    switchMap((action: IActionWithPayload) => this.api.getStationDataProfiles(action.payload).pipe(
      switchMap((viewResponse: Array<{}>) => of(setStationDataProfiles(viewResponse['Weather station'] || []))),
      catchError((err) => from([
        setStationDataError(true),
        setStationDataLoading(false)
      ]))
    )),
  );

  @Effect()
  public getStationDataFc$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(StationDataActionTypes.GET_STATION_DATA_FC),
    switchMap((action: IActionWithPayload) => this.api.getStationDataFc(action.payload).pipe(
      switchMap((fcResponse: IFcResponse) => {
        fcResponse = this.makeValidResponse(fcResponse);
        return from ([
          setStationDataFc(fcResponse),
          setStationDataLoading(false),
          setStationDataError(!fcResponse.chartsOptions.length)
        ]);
      }),
      catchError(() => from([
        setStationDataError(true),
        setStationDataLoading(false)
      ]))
    )),
  );

  @Effect()
  public getStationDataLicenses$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(StationDataActionTypes.GET_STATION_DATA_LICENSES),
    tap(() => {
      this.store.dispatch(setStationDataLicenses([]));
      this.store.dispatch(setStationDataWarningMessage({message: null, icon: false}));
    }),
    switchMap((action: IActionWithPayload) => this.api.getStationLicenses(action.payload).pipe(
      switchMap((res: any) => from([
        setStationDataLicenses(res)
      ])),
      catchError(() => from([]))
    ))
  );

  private makeValidResponse(chart: IFcResponse): any {
    return chart.chartsOptions && chart.grid && chart.topology ? chart : {
      chartsOptions: [],
      grid: {},
      topology: []
    };
  }

}
