<form [formGroup]="form"
      *ngIf="allStations$ | async"
      class="widget-settings-form">
  <app-custom-dropdown [formControl]="group"
                       class="widget-settings-form__dropdown"
                       [options]="groupValues">
  </app-custom-dropdown>

  <app-custom-dropdown [formControl]="last"
                       class="widget-settings-form__dropdown"
                       [options]="lastValues">
  </app-custom-dropdown>
  
  <!-- <app-custom-dropdown [formControl]="profile"
                       class="widget-settings-form__dropdown"
                       [staticContent]="'Profile'"
                       [options]="chartProfiles">
  </app-custom-dropdown> -->

  <app-custom-dropdown [formControl]="station"
                       class="widget-settings-form__dropdown"
                       [options]="filteredStations | async | stationsOptions">
  </app-custom-dropdown>

  <app-custom-dropdown [formControl]="windspeed"
                       [disabled]="isWindspeed()"
                       class="widget-settings-form__dropdown"
                       [options]="windSpeedValues">
  </app-custom-dropdown>

  <app-custom-dropdown [formControl]="winddir"
                       [disabled]="isWinddir()"
                       class="widget-settings-form__dropdown"
                       [options]="winddirValues">
  </app-custom-dropdown>

  <app-custom-button (click)="submit()"
                     [disabled]="isSubmitDisabled()"
                     [content]="'Refresh' | translate"
                     [color]="'red'">
    <span><i class="fa fa-fw fa-refresh"></i></span>
  </app-custom-button>
</form>
