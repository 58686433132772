import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {ILEDConfig, ILEDTrap} from '../../../core/models/stations';
import {ILEDTrapSave} from '../models/station-config.models';

export const enum LedTrapSettingsActionTypes {
  SET_LED_SETTINGS = '[LedConfig] SET_LED_SETTINGS',
  SET_TEMPERATURE_THRESHOLD = '[LedConfig] SET_TEMPERATURE_THRESHOLD',
  SET_LED_1_CONFIG = '[LedConfig] SET_LED_1_CONFIG',
  SET_LED_2_CONFIG = '[LedConfig] SET_LED_2_CONFIG',
  SET_SCHEDULER = '[LedConfig] SET_LED_SCHEDULER',
  SAVE_LED_SETTINGS = '[LedConfig] SAVE_LED_SETTINGS',
}

export function setLedSettings(settings: ILEDTrap): IActionWithPayload {
  return {type: LedTrapSettingsActionTypes.SET_LED_SETTINGS, payload: settings};
}

export function setTemperatureThreshold(temperature: number): IActionWithPayload {
  return {type: LedTrapSettingsActionTypes.SET_TEMPERATURE_THRESHOLD, payload: temperature};
}

export function setLed1Config(ledConfig: ILEDConfig): IActionWithPayload {
  return {type: LedTrapSettingsActionTypes.SET_LED_1_CONFIG, payload: ledConfig};
}

export function setLed2Config(ledConfig: ILEDConfig): IActionWithPayload {
  return {type: LedTrapSettingsActionTypes.SET_LED_2_CONFIG, payload: ledConfig};
}

export function setScheduler(scheduler: number): IActionWithPayload {
  return {type: LedTrapSettingsActionTypes.SET_SCHEDULER, payload: scheduler};
}

export function saveLedSettings(settings: ILEDTrapSave): IActionWithPayload {
  return {type: LedTrapSettingsActionTypes.SAVE_LED_SETTINGS, payload: settings};
}
