import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CropZoneSoilMoistureRoutingModule } from './crop-zone-soil-moisture-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import {
  CropZoneSoilMoistureSumsSmComponent
} from './containers/crop-zone-soil-moisture-sums-sm/crop-zone-soil-moisture-sums-sm.component';
import {
  CropZoneSoilMoistureSumsSmToolbarComponent
} from './containers/crop-zone-soil-moisture-sums-sm/toolbar/crop-zone-soil-moisture-sums-sm-toolbar.component';
import { StoreModule } from '@ngrx/store';
import { reducers } from '../crop-zone-soil-moisture/reducers';
import { ChartModule } from 'angular-highcharts';
import {
  CropZoneSoilMoistureSumsSmChartsComponent
} from './containers/crop-zone-soil-moisture-sums-sm/charts/crop-zone-soil-moisture-sums-sm-charts.component';
import { EffectsModule } from '@ngrx/effects';
import { SumsSmEffects } from './effects';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ChartModule,
    TranslateModule.forChild(),
    CropZoneSoilMoistureRoutingModule,
    StoreModule.forFeature('cropZoneSoilMoisture', reducers),
    EffectsModule.forFeature([SumsSmEffects])
  ],
  declarations: [
    CropZoneSoilMoistureSumsSmComponent,
    CropZoneSoilMoistureSumsSmToolbarComponent,
    CropZoneSoilMoistureSumsSmChartsComponent
  ]
})
export class CropZoneSoilMoistureModule { }
