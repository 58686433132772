import { Component, OnInit, Inject, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { NgxFaviconService } from 'ngx-favicon';
import { CustomFavicon } from '../favicon.config';
import { IEnvironment } from '../environments/interfaces/environment';
import { environmentToken } from '../environments/environment';
import { IThemeConfig } from '../environments/interfaces/theme';
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  private appName                 : string = 'FieldClimate';
  private trackingId              : string = 'UA-100834039-1';
  public customFavicon            : typeof CustomFavicon = CustomFavicon;
  public themeConfig              : IThemeConfig;


  constructor(
    public vcRef                  : ViewContainerRef,
    @Inject(environmentToken) private environment: IEnvironment,
    private titleService          : Title,
    public router                 : Router,
    private ngxFaviconService     : NgxFaviconService<CustomFavicon>
  ) {
    this.themeConfig = this.environment.theme;
    if (this.themeConfig.hasOwnProperty('trackingId')) {
      this.trackingId = this.themeConfig.trackingId;
    }
    if (this.themeConfig.subDomain === 'robertaebimeteo') {
      this.setCustomFavicon(this.customFavicon.FAVICON_ROBERTAEBIMETEO);
    }
  }

  public ngOnInit(): void {
    this.themeConfig.hasOwnProperty('name') ?
      this.titleService.setTitle(this.themeConfig.name) :
      this.titleService.setTitle(this.appName);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', this.trackingId,
          {
            'page_path': event.urlAfterRedirects,
            'cookie_domain': window.location.hostname
          }
        );
      }
    });
  }

  public setCustomFavicon(faviconName: CustomFavicon): void {
    this.ngxFaviconService.setCustomFavicon(faviconName);
  }
}
