<section class="widget-container">
  <app-chart-header [color]="widget.color"
                    [title]="widget.title"
                    [description]="widget.description"
                    [isEditable]="false"
                    [isDraggable]="isEdit$ | async"
                    [isClose]="isEdit$ | async"
                    (remove) = "removeCurrentWidget()"
                    (editMode)="editMode(!widget.settings.cropzones)"></app-chart-header>

  <app-form-layout [size]="'dashboard'">
    <div class="wrapper">
      <div class="formSelectDiv">
        <app-form-select *ngIf="isCropzoneInput" [type]="'border'" [size]="'small'" [options]="cropzoneWidgets" [formControl]="cropzoneWidget"></app-form-select>
        <h5>SM SUM</h5>
      </div>
      <div class="help-icon-div">
        <div><i class="zmdi zmdi-help help-icon"
          (click)="openModal()"></i></div>
      </div>
    </div>
      <app-cropzone-list [rowIndex]="rowIndex" [columnIndex]="columnIndex" [widgetIndex]="widgetIndex"></app-cropzone-list>
    <app-widget-load-info *ngIf="widget.error || widget.isLoading"
                          [error]="widget.error"
                          [loading]="widget.isLoading">
    </app-widget-load-info>
  </app-form-layout>
</section>

<app-info-modal [modalId]="modalId" [header]="'Soil moisture SUM'">
  <app-cropzones-widget-help-text-modal></app-cropzones-widget-help-text-modal>
</app-info-modal>