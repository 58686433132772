import { Injectable } from '@angular/core';
import { TranslateService} from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { IFrostProtectionValues } from './models/models';
import { TRANSLATED_VALUES } from './constants/constants';

@Injectable({
  providedIn: 'root'
})
export class FrostProtectionResolver {

  constructor(
    private translations    : TranslateService
  ) { }

  public resolve(): Observable<any>|Promise<any>|any {
    const translatedValues      : IFrostProtectionValues = TRANSLATED_VALUES;

    Object.keys(translatedValues).forEach(key => {
      this.translations.get(translatedValues[key])
        .subscribe(translatedValue => {
          translatedValues[key] = translatedValue;
        });
    });

    return Object.assign(translatedValues);
  }
}
