import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-product-license-activation-form',
  templateUrl: './product-license-activation-form.component.html',
  styleUrls: ['./product-license-activation-form.component.scss']
})
export class ProductLicenseActivationFormComponent implements OnInit {
  @Output()
  public activateProductEmitter: EventEmitter<string> = new EventEmitter();
  @Output()
  public cancelEmitter: EventEmitter<void> = new EventEmitter();

  public form: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      productKey: ['', [Validators.required]]
    });
  }

  public get productKey(): AbstractControl {
    return this.form.get('productKey');
  }

  public submit(): void {
    this.activateProductEmitter.emit(this.productKey.value);
  }

  public cancel(): void {
    this.form.reset();
    this.cancelEmitter.emit();
  }
}
