import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, pluck, takeUntil } from 'rxjs/operators';
import { ICropZone } from '../../../../core/models/cropzones';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation, selectSelectedCropZone } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import * as fromSelectedCropzone from '../../../../core/reducers/selectedCropZone';
import { ITree, ITreeStructure } from '../../../../services/tree/models';
import { ITreeComponent } from '../../models/tree.models';

@Component({
  selector: 'app-tree-links',
  templateUrl: './tree-links.component.html',
  styleUrls: ['./tree-links.component.scss']
})
export class TreeLinksComponent implements ITreeComponent, OnInit, OnDestroy {
  public linkTree: ITree;
  public selectedStationId$ : Observable<string>;
  public selectedCropzoneId$: Observable<string>;
  public stationOrCropzone  : string;
  public station            : string = 'station';
  public cropzone           : string = 'cropzone';

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private navigationStationStore: Store<INavigationStationState>,
              private activatedRoute: ActivatedRoute,
              private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>) {}

  public ngOnInit(): void {
    this.stationOrCropzone = this.activatedRoute.parent.parent.routeConfig.path;

    if (this.stationOrCropzone === this.station) {
    this.selectedStationId$ = this.navigationStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((station: IStation): boolean => !!station),
      distinctUntilChanged((a: IStation, b: IStation): boolean => {
        return a.name.original === b.name.original;
      }),
      pluck('name', 'original')
    );
    } else if (this.stationOrCropzone === this.cropzone) {
      this.selectedCropzoneId$ = this.selectedCropzoneStore.pipe(
        takeUntil(this.destroy$),
        select(selectSelectedCropZone),
        filter((cropzone: ICropZone): boolean => !!cropzone),
        distinctUntilChanged((a: ICropZone, b: ICropZone): boolean => {
          return a.id === b.id;
        }),
        pluck('id')
      );
    }
  }

  public setTree(tree: ITreeStructure): void {
    this.linkTree = tree.trees.length > 0 ? tree.trees[0] : null;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
