import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { IStation } from '../../../core/models/stations';
import { ICalibrationSetting } from '../../../core/models/system';
import { selectCalibrationSettings, selectNavigationStation } from '../../../core/reducers';
import * as fromNavigationStation from '../../../core/reducers/navigation-station';
import { ISystemState } from '../../../core/reducers/system';
import { stationDataContentAnimations } from '../../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../../core/services/left-components-toggler/left-components-toggler.service';
import { ITreeStructure } from '../../../services/tree/models';
import { TreeService } from '../../../services/tree/tree.service';
import { IOptions } from '../../../shared/interfaces';
import {
  loadNodes, setSensorsStation
} from '../actions/sensors-and-nodes';
import { INodes } from '../models/sensors-and-nodes';
import {
  selectSensorsAndNodes,
  selectSensorsAndNodesError,
  selectSensorsAndNodesLoading
} from '../reducers';
import * as fromSensorsAndNodes from '../reducers/sensors-and-nodes';

@Component({
  selector: 'app-sensors-and-nodes',
  templateUrl: './sensors-and-nodes.component.html',
  styleUrls: ['./sensors-and-nodes.component.scss'],
  animations: stationDataContentAnimations
})
export class SensorsAndNodesComponent implements OnInit, OnDestroy {
  @ViewChild('content')
  private content               : ElementRef;

  public state$                 : Observable<string>;
  public isError$               : Observable<boolean>;
  public isLoading$             : Observable<boolean>;
  public tree$                  : Observable<ITreeStructure> = this.treeService.getStationSettingsTreeStructure();
  public nodes                  : INodes;

  public stationId              : string;
  public calibrationSettings    : ICalibrationSetting[] = [];
  public nodeCalibration        : { [node: string]: { [sensor: string]: IOptions[]|boolean } } = {};

  private destroy$              : Subject<boolean> = new Subject<boolean>();

  constructor(private treeService: TreeService,
              private systemStore: Store<ISystemState>,
              private sensorsStore: Store<fromSensorsAndNodes.ISensorsAndNodesState>,
              private navigationStore: Store<fromNavigationStation.INavigationStationState>,
              private leftComponentsToggler: LeftComponentsTogglerService) { }

  public ngOnInit(): void {

    this.navigationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((s: IStation) => !!s)
    ).subscribe((station) => {
      if (station.name.original !== this.stationId) {
        this.sensorsStore.dispatch(loadNodes(station.name.original));
        this.sensorsStore.dispatch(setSensorsStation(station.name.original));
        this.stationId = station.name.original;
      }
    });

    this.state$ = this.leftComponentsToggler.getStationDataContentState();

    this.sensorsStore.pipe(
      takeUntil(this.destroy$),
      select(selectSensorsAndNodes),
      withLatestFrom(
        this.systemStore.pipe(select(selectCalibrationSettings))
      ),
      filter(([nodes]) => !!nodes)
    ).subscribe(([nodes, calibrationSettings]) => {
      this.calibrationSettings = calibrationSettings;
      if (!this.nodes || this.nodesChanged(this.nodes, nodes)) {
        this.nodeCalibration = Object.keys(nodes).reduce((nodesObj, nodeKey) => {
          const node = nodes[nodeKey];
          nodesObj[nodeKey] = Object.keys(nodes[nodeKey].sensors).reduce((sensorsObj, sensorKey) => {
            const sensor = node.sensors[sensorKey];
            const calibrations = this.calibrationSettings.find(setting => {
              return setting.code === sensor.code && sensor.isActive;
            });
            sensorsObj[sensorKey] = calibrations
            ? calibrations.calibration
            .map(calibration => ({ value: calibration.id, content: calibration.name, data: {
              default: !!calibration.default
            } }))
            : false;
            return sensorsObj;
          }, {});
          return nodesObj;
        }, {});
      }
      this.nodes = nodes;
    });

    this.isError$ = this.sensorsStore.pipe(
      takeUntil(this.destroy$),
      select(selectSensorsAndNodesError)
    );

    this.isLoading$ = this.sensorsStore.pipe(
      takeUntil(this.destroy$),
      select(selectSensorsAndNodesLoading)
    );

  }

  public getNodesKeys(): string[] {
    return Object.keys(this.nodes);
  }

  public nodesChanged(oldNodes: INodes, newNodes: INodes): boolean {
    return (
      Object.keys(newNodes).length !== Object.keys(oldNodes).length ||
      Object.keys(newNodes).some(nodeKey => {
        return (
          !oldNodes[nodeKey] ||
          Object.keys(newNodes[nodeKey].sensors).length !== Object.keys(oldNodes[nodeKey].sensors).length ||
          Object.keys(newNodes[nodeKey].sensors).some(sensorKey => !oldNodes[nodeKey].sensors[sensorKey])
        );
      })
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
