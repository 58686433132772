<app-form-layout class="emergency">
  <app-card-header [header]="'Emergency SMS number' | translate"
                   [helpButton]="true"
                   (openModal)="openModal()"
                   [description]="'The phone number to be used for emergency SMS messages' | translate"></app-card-header>
  <div class="emergency__content">
    <app-emergency-form [emergency]="emergencyState?.emergency"
                        (changeEmergency)="changeEmergency($event)"></app-emergency-form>
    <app-save-emergency (save)="saveEmergency()"></app-save-emergency>
  </div>
</app-form-layout>

<app-info-modal [modalId]="modalId"
                [header]="'Emergency SMS number' | translate">
  <app-emergency-modal></app-emergency-modal>
</app-info-modal>
