<app-tree [tree$]="tree$"></app-tree>
<div class="body-view-content" [@placeContent]="state$ | async">
  <app-station-page-header [pageName]="'Device Configuration'"></app-station-page-header>

  <ng-container *ngIf="selectedStation">
    <!-- <div class="alert alert-warning station-config-content__alert" *ngIf="!checkRights()">
      {{ 'You have read-only rights to this device, therefore you cannot update the settings.' | translate }}
    </div> -->

    <app-camera-topology [linkType]="topologyLinkType.LINK_CONFIG"></app-camera-topology>

    <app-station-config-base-name
      class="station-config-content__item"
      [station]="selectedStation"
    ></app-station-config-base-name>

    <app-timezone-and-location
      *ngIf="!checkIfTracker()"
      [station]="selectedStation"
      [timezoneOnly]="showTimezoneOnly"
    ></app-timezone-and-location>

    <app-timezone-and-location
      *ngIf="checkIfTracker() && checkRights()"
      [station]="selectedStation"
      [timezoneOnly]="showTimezoneOnly"
    ></app-timezone-and-location>

    <app-station-notes
      class="station-config-content__item"
      [isReadOnly]="!checkRights()"
      [station]="selectedStation"
    ></app-station-notes>

    <app-logging-and-transfer-two
      *ngIf="(checkSensors() || checkSimple() || checkValidDeviceGroup()) && checkRights() && !isNMetosFrost() && !isNMetosFWT()"
      class="station-config-content__item"
      [selectedStation]="selectedStation"
    ></app-logging-and-transfer-two>

    <!-- <app-n-metos-frost-table
      *ngIf="(checkSensors() || checkSimple ||checkValidDeviceGroup()) && checkRights && isNMetosFrost() && !isNMetosFWT()"
    ></app-n-metos-frost-table> -->

    <app-emergency
      *ngIf="checkSimple() && checkRights()"
      [station]="selectedStation"
    ></app-emergency>

    <app-station-led-trap
      *ngIf="checkIfLEDTrap() && checkRights()"
      [station]="selectedStation"
    ></app-station-led-trap>

    <app-camera-settings
      *ngIf="checkSensors() && selectedStation.config.cam1 && checkRights()"
      [station]="selectedStation"
    ></app-camera-settings>

    <app-tracker-settings
      *ngIf="checkIfTracker() && checkRights()"
      id="tracker-settings"
    ></app-tracker-settings>
  </ng-container>
</div>
