import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-warnings-phone-save',
  templateUrl: './warnings-phone-save.component.html',
  styleUrls: ['./warnings-phone-save.component.scss']
})
export class WarningsPhoneSaveComponent {
  @Output()
  private save          : EventEmitter<void> = new EventEmitter<void>();

  public savePhones(): void {
    this.save.emit();
  }
}
