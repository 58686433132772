import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {ILEDTrap} from '../../../core/models/stations';
import {LedTrapSettingsActionTypes} from '../actions/ledTrapSettings';

export interface ILedTrapSettingsState {
  config: ILEDTrap;
}

const initialState: ILedTrapSettingsState = {
  config: {
    led_scheduler: 7340032, // 20:00, 21:00, 22:00
    led_settings: {
      led1: {
        led_time_on: 500,
        led_time_off: 500
      },
      led2: {
        led_time_on: 500,
        led_time_off: 500
      },
      temperature_threshold: 15
    }
  },
};

export function reducer(state: ILedTrapSettingsState = initialState, action: IActionWithPayload): ILedTrapSettingsState {
  switch (action.type) {
    case LedTrapSettingsActionTypes.SET_LED_SETTINGS: {
      return {...state, config: action.payload};
    }
    case LedTrapSettingsActionTypes.SET_TEMPERATURE_THRESHOLD: {
      return {
        config: {
          ...state.config,
          led_settings: {
            ...state.config.led_settings,
            temperature_threshold: action.payload,
          }
        }
      };
    }
    case LedTrapSettingsActionTypes.SET_LED_1_CONFIG: {
      return {
        config: {
          ...state.config,
          led_settings: {
            ...state.config.led_settings,
            led1: action.payload,
          }
        }
      };
    }
    case LedTrapSettingsActionTypes.SET_LED_2_CONFIG: {
      return {
        config: {
          ...state.config,
          led_settings: {
            ...state.config.led_settings,
            led2: action.payload,
          }
        }
      };
    }
    case LedTrapSettingsActionTypes.SET_SCHEDULER: {
      return {
        config: {
          ...state.config,
          led_scheduler: action.payload,
        }
      };
    }
    default: {
      return state;
    }
  }
}
