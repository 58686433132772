import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Chart } from 'angular-highcharts';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { IChartAndSeries, IChartHashMap } from '../../../../shared/interfaces';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';
import { setZoomTitle } from '../../../../shared/utils/setZoomTitle';
import { setWeatherForecastSetting } from '../../actions/weather-forecast-settings';
import { DEFAULT_WEATHER_FORECAST_MODE, IWeatherForecastSettingsState, IWeatherForecastState } from '../../models/models';
import { selectWeatherForecastCharts, selectWeatherForecastSettings, selectWeatherForecastSettingsIsChartActive } from '../../reducers';

@Component({
  selector: 'app-weather-forecast-charts',
  templateUrl: './weather-forecast-charts.component.html',
  styleUrls: ['./weather-forecast-charts.component.scss']
})
export class WeatherForecastChartsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input()
  public exportChartImg                    : boolean;
  public isChartActive$                    : Observable<boolean>;
  public charts                            : Array<Chart> = [];
  private hashMap                          : IChartHashMap = {};
  public chartsSeries                      : any = {};

  private destroy$                         : Subject<boolean> = new Subject<boolean>();
  private mode                             : string = DEFAULT_WEATHER_FORECAST_MODE.value;
  private stationId                        : string;
  private disabledGroupIds                 : object = {};

  constructor(private weatherForecastSettingsStore: Store<IWeatherForecastSettingsState>,
              private weatherForecastStore: Store<IWeatherForecastState>,
              private exportService: StationDataExportService,
              private navigationStationStore: Store<INavigationStationState>) { }

  public ngOnInit(): void {
    this.isChartActive$ = this.weatherForecastSettingsStore.pipe(
      takeUntil(this.destroy$),
      select(selectWeatherForecastSettingsIsChartActive)
    );
    this.listenStationChange();
    this.listenWeatherForecastStore();
  }

  public ngOnChanges(): void {
    if (this.charts && this.exportChartImg) {
      const fileName = 'weather_station_charts_' + moment(new Date()).toISOString() + '.png';
      this.exportService.exportChartsToImage(fileName);
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private listenStationChange(): void {
    this.navigationStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((selectedStation: IStation): boolean => !!selectedStation),
      tap((selectedStation: IStation): void => {
        this.stationId = selectedStation.name.original;
      }),
      switchMap(() => this.getWeatherForecastSettingsObservable())
    ).subscribe((disabledGroupIds: object) => {
      this.disabledGroupIds = disabledGroupIds;
      this.handleChartsVisibility();
    });
  }

  private getWeatherForecastSettingsObservable(): Observable<object> {
    return this.weatherForecastSettingsStore.pipe(
      takeUntil(this.destroy$),
      select(selectWeatherForecastSettings),
      filter((weatherForecastSettings: IWeatherForecastSettingsState): boolean => {
        return !!weatherForecastSettings && !!weatherForecastSettings.settings[this.stationId];
      }),
      tap((weatherForecastSettings: IWeatherForecastSettingsState): void => {
        this.mode = weatherForecastSettings.settings[this.stationId].selectedMode;
      }),
      map((weatherForecastSettings: IWeatherForecastSettingsState): object => {
        return weatherForecastSettings.settings[this.stationId].modes[this.mode].disabledGroupIds;
      })
    );
  }

  private listenWeatherForecastStore(): void {
    this.weatherForecastStore.pipe(
      takeUntil(this.destroy$),
      select(selectWeatherForecastCharts),
      filter((chartsData): boolean => Array.isArray(chartsData))
    ).subscribe((chartsData: Array<any>): void => {
      this.initCharts(chartsData);
    });
  }

  private initCharts(chartsData: Array<any>): void {
    this.emptyDataHolders();
    chartsData.forEach((item, index) => {
      this.initChartSeriesForIndex(index);
      item.series.forEach((s, i) => {
        const groupId: string = s.groupId;
        if (groupId) {
          this.chartsSeries[index].series.push(groupId);
          this.addHashMapItem(groupId, index, i);
        }
      });
      this.addChartObject(item);
    });
    this.handleChartsVisibility();
  }

  private emptyDataHolders(): void {
    this.charts = [];
    this.hashMap = {};
    this.chartsSeries = {};
  }

  private initChartSeriesForIndex(index): void {
    this.chartsSeries[index] = {
      isDisabled: true,
      series: []
    };
  }

  private addHashMapItem(groupId, chartIndex, seriesIndex): void {
    if (!this.hashMap[groupId]) {
      this.hashMap[groupId] = [];
    }
    this.hashMap[groupId].push({
      chart: chartIndex,
      series: seriesIndex
    });
  }

  private addChartObject(item): void {
    const chart: Chart = new Chart(item);
    this.setChartEvents(chart);
    setZoomTitle(chart);
    this.charts.push(chart);
  }

  private setChartEvents(chart: Chart): void {
    chart.options.plotOptions = {
      series: {
        events: {
          legendItemClick: (event: any) => {
            event.preventDefault();
            this.changeVisible(event.target.options.groupId);
            this.showlabel(event.target.options.groupId);
          }
        }
      },
    };
  }

  private handleChartsVisibility(): void {
    Object.keys(this.hashMap).forEach((key) => {
      const hash = this.hashMap[key];
      hash.forEach((h) => this.setChartVisibility(key, h));
    });
  }

  private setChartVisibility(key: string, hashMapItem: IChartAndSeries): void {
    const seriesFromOptions = this.charts[hashMapItem.chart].options.series[hashMapItem.series];
    if (this.charts[hashMapItem.chart].ref) {
      const seriesFromRef = this.charts[hashMapItem.chart].ref.series[hashMapItem.series];
      if (this.disabledGroupIds[key] === undefined && !seriesFromOptions.visible) {
        seriesFromRef.show();
      }
      if (this.disabledGroupIds[key] !== undefined && seriesFromOptions.visible) {
        seriesFromRef.hide();
      }
    } else {
      seriesFromOptions.visible = this.disabledGroupIds[key] === undefined;
    }
  }

  private changeVisible(groupId: string): void {
    this.weatherForecastSettingsStore.dispatch(setWeatherForecastSetting({
      groupdId: groupId,
      mode: this.mode,
      stationId: this.stationId
    }));
  }

  private showlabel(groupId: string): void {
    if (groupId === 'aggr_snowamount' && document.getElementById('chart0')) {
      const textTag = document.getElementById('chart0').querySelectorAll('text');
        if (textTag.length > 0) {
            textTag.forEach(tag => {
              const spanTag = tag.querySelectorAll('tspan');
              if (spanTag.length > 0) {
                  spanTag.forEach(tspan => {
                    if (tspan.innerHTML.split(' ').join('').toLocaleUpperCase() === 'SNOWAMOUNT') {
                      tag.style.display = this.disabledGroupIds['aggr_snowamount'] ? 'none' : 'block';
                    }
                  });
              }
            });
        }
    }
  }

  public ngAfterViewInit(): void {
    this.charts[0].options.series.forEach((x: any) => {
      if (x.groupId === 'aggr_snowamount') {
        setTimeout(() => {
          this.showlabel(x.groupId);
        }, 100);
      }
    });
  }
}
