import { Polygon } from 'geojson';
import { ISliderSettings } from '../../modules/yield-prediction-config/models/model';

export interface ICropZone {
    name: string;
    crop_name: string;
    from: string;
    to: string;
    field: IField;
    farm: IFarm;
    id: string;
    year: string;
    boundary: null|Polygon;
    soil: ISoil;
    crop: ICrop;
    rain_efficacies: Array<IRainEfficacies>;
    data_sources: Array<IDataSources>;
    irrigation_events: Array<IIrrigationEvents>;
    rain_events?: Array<IRainEvents>;
    product_key?: string;
    distance?: number;
    manualRainGauge?: boolean;
    license_start_date?: string;
    license_end_date?: string;
    duplication_date?: string;
    starred?: boolean;
}

export interface ICropZoneMap {
  [id: string]: ICropZone;
}

export interface IRainEvents {
    title: string;
    date: string | Date;
    amount: number;
}

export interface IIrrigationEvents {
    title           : string;
    from            : string;
    to              : string;
    duration        : number;
    efficacy        : number;
    amount          : number;
    frequency       : number;
    exceptions      : Array<IExceptions>;
    type           : irrigationTypeEnum;
    recurringEvent? : boolean;
    dayOrWeek?      : string;
}

export interface IExceptions {
    date : string;
    amount : number;
    duration: number;
}

enum irrigationTypeEnum {
    DRIP = 'DRIP',
    FLOOD = 'FLOOD',
    FURROW = 'FURROW',
    PIVOT = 'PIVOT',
    MICRO_SPRINKLER = 'MICRO_SPRINKLER',
    SPRINKLER = 'SPRINKLER',
    SUBSURFACE_DRIP = 'SUBSURFACE_DRIP'
}
export interface ICrop {
    id              : string;
    emergence       : Date | string;
    fao56           : IFAO56;
}

export interface IFAO56 {
    K_ini   : number;
    K_mid   : number;
    K_end   : number;
    L_ini   : string;
    L_dev   : string;
    L_mid   : string;
    L_late  : string;
    R_ini   : number;
    R_mid   : number;
    R_end   : number;
}
export interface ISoil {
    layers                          : Array<ILayer>;
    root_restriction_depth          : number;
    initial_soil_water_content      : number;
}

export interface ILayer {
    depth: number;
    field_capacity: number;
    refill_point: number;
    unit: string;
}

export interface IRainEfficacies {
    efficacy: number;
    lt: number;
    gte: number;
}

export interface IRainDataSource {
    type: string;
    group: number;
    channel: number;
    code: number;
    aggr?: aggrEnum | string;
    id?: number;
}

export enum aggrEnum {
    'AVG',
    'MAX',
    'MIN',
    'LAST',
    'SUM',
    'TIME'
}

export interface IET0DataSource {
    type: string;
    id: string;
    sensors: IDataSourceSensor[];
}

export enum SoilMoistureTypeEnum {
    SOIL_MOISTURE_SENSOR_OR_PROBE = 'SOIL_MOISTURE_SENSOR_OR_PROBE'
}

export interface ISoilMoistureDataSource {
    type: SoilMoistureTypeEnum ;
    group: number;
    layers: ISoilMoistureDataSourceLayer[];
    aggr?: aggregationEnum;
    id?: number;
}

export interface ISoilMoistureDataSourceLayer {
    code: number;
    channel: number;
    depth: number;
    field_capacity: number;
    refill_point: number;
    unit: string;
    calibration_id?: string;
    node?: string;
}

export interface IDataSources {
    device_name: string;
    device_id: number;
    source: IDataSourceSensor | IDataSourceDisease | IRainDataSource | IET0DataSource | ISoilMoistureDataSource | IYieldDataSource;
    module: string;
    from? : string|Date;
    to? : string|Date;
    yield_prediction?: IYieldPrediction;
}

export interface IYieldPrediction {
    sowing_date: string|Date;
    harvest_date_user_estimate: string|Date;
    yield_max_user_estimate: number;
    soil_texture: string;
    initial_soil_moisture: string;
    crop_type: string;
    wilting_point?: number;
    field_capacity?: number;
    paw_ini?: number;
    unit?: any;
    sliders?: ISliderSettings;
}

export interface IYieldDataSource {
    id: string;
    type: string;
    sensors: IDataSourceSensor[];
    sensor_rain?: IDataSourceSensor;
    sensor_air?: IDataSourceSensor;
    device_id?: any[];
}

export interface IDataSourceDisease {
    id: string;
    type: string; // DISEASE_MODEL
    device_id: string;
}

export interface IDataSourceSensor {
    type?: string;       // SENSOR
    group: number;
    channel: number;
    code: number;
    node? : string;
    aggr? : aggregationEnum;
    id?: number;
}

enum aggregationEnum {
    AVG = 'AVG',
    MAX = 'MAX',
    MIN = 'MIN',
    LAST = 'LAST',
    SUM = 'SUM',
    TIME = 'TIME'
}

export enum IAddCropZoneType {
  ADD_CROPZONE_AND_FIELD_AND_FARM = 'addcropzonefieldfarm',
  ADD_CROPZONE_AND_FIELD = 'addcropzonefield',
  ADD_CROPZONE = 'addcropzone',
}

export interface IAddCropZoneRequest {
  type?: string;
  name: string;
  from: string;
  to: string;
  crop_name: string;
  boundary?: any;
  product_key?: string;
}

export interface IAddCropZone extends IAddCropZoneRequest {
  farm: IFarm;
  field: IField;
}

export interface IAddCropZoneField extends IAddCropZoneRequest {
    farm: IFarm;
    field: {name: string};
}

export interface IAddCropZoneFieldFarm extends IAddCropZoneRequest {
    farm: {name: string};
    field: {name: string};
}

export interface IField {
    name: string;
    id: string;
}

export interface IFarm {
    name: string;
    id: string;
}

export enum CropZoneModule {
    'SOIL_MOISTURE' = 'SOIL_MOISTURE'
}

export enum CropZoneDataSourceType {
    'SOIL_MOISTURE_SENSOR_OR_PROBE' = 'SOIL_MOISTURE_SENSOR_OR_PROBE'
}
