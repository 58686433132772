import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import * as d3 from 'd3';
import { Subject } from 'rxjs';
import { ICropZone } from '../../../../../core/models/cropzones';

@Component({
  selector: 'app-soil-moisture-sum-chart',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './soil-moisture-sum-chart.component.html',
  styleUrls: ['./soil-moisture-sum-chart.component.scss']
})
export class SoilMoistureSumChartComponent implements OnInit, OnDestroy {

  @ViewChild('chart', {static: true})
  private chartContainer: ElementRef;

  @Input()
  public cropzone: ICropZone;

  @Input()
  public sum_sm: number;
  @Input()
  public refill_point: number;
  @Input()
  public field_capacity: number;

  @Input()
  public unit: string;

  public stationId: string;
  public node: string;
  private sumExtendsWidthOfChart: boolean = false;
  private radius: number = 3;
  private border: number = 1;
  private barHeight: number = 30;
  private distanceFromTopForBar: number = 10;
  public margin = { left: 30, top: 10, right: 35, bottom: 10 };
  private alive$ = new Subject<boolean>();
  constructor(private router: Router) { }

  public ngOnInit(): void {
    this.cropzone.data_sources.forEach(datasource => {

      if (datasource.module === 'SOIL_MOISTURE') {
        this.stationId = datasource.device_name;
        this.node = datasource.source['layers'][0].node;
      }
    });

    this.createChart();
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }


  public createChart(): void {
    const self = this;
    const element = this.chartContainer.nativeElement;

    const svg = d3.select(element).append('svg')
      .attr('width', element.offsetWidth)
      .attr('height', element.offsetHeight);

    const contentWidth = element.offsetWidth - this.margin.left - this.margin.right;
    const contentHeight = element.offsetHeight - this.margin.top - this.margin.bottom;

    const beforeAfterSUMS = (this.field_capacity - this.refill_point) / 10;
    const x = d3
      .scaleLinear()
      .rangeRound([0, contentWidth]);

    const y = d3
      .scaleLinear()
      .rangeRound([contentHeight, 0]);

    if (this.sum_sm > this.field_capacity + beforeAfterSUMS) {
      this.sumExtendsWidthOfChart = true;
    }

    let SMSIsBefore10PercentSumRP: boolean = false;
    if (this.sum_sm < this.refill_point - beforeAfterSUMS) {
      SMSIsBefore10PercentSumRP = true;
    }

    x.domain([this.refill_point - beforeAfterSUMS, this.field_capacity + beforeAfterSUMS]);
    y.domain([0, 10]);

    const xAxis = d3.axisBottom(x);

    const yAxis = d3.axisLeft(y);

    const focus = svg.append('g').attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    // rectangle of full width (the grey one (without data))
    if (!SMSIsBefore10PercentSumRP && !this.sumExtendsWidthOfChart) {
      const rectangle = svg.append('rect')
        .on('click', function (): void { self.goToSoilMoistureSumOfCropzone(); })
        .attr('x', this.margin.left)
        .attr('y', this.distanceFromTopForBar)
        .attr('class', 'pointerCursor')
        .attr('width', x(this.field_capacity + beforeAfterSUMS) - x(x.domain()[0]))
        .attr('height', this.barHeight)
        .attr('transform', `translate(0,${element.offsetHeight - 110})`)
        .attr('fill', '#f2f2f2')
        .attr('stroke-width', (this.border * 2))
        .attr('stroke', 'rgb(218, 218, 218)')
        .attr('rx', this.radius)
        .attr('ry', this.radius);

    } else if (SMSIsBefore10PercentSumRP) {
      // before 10 percent of sumRP
      const rectangle1 = svg.append('path')
        .on('click', function (): void { self.goToSoilMoistureSumOfCropzone(); })
        .attr('d', rightRoundedRect(
          x(this.refill_point - (beforeAfterSUMS / 4)) + this.margin.left,
          this.distanceFromTopForBar,
          x(this.field_capacity + beforeAfterSUMS) - x(this.refill_point - (beforeAfterSUMS / 4)),
          this.barHeight,
          this.radius,
          true))
        .attr('fill', '#f2f2f2')
        .attr('stroke-width', (this.border * 2))
        .attr('stroke', 'rgb(218, 218, 218)')
        .attr('class', 'pointerCursor')
        .attr('transform', `translate(0,${element.offsetHeight - 110})`);

      const rectangle2 = svg.append('path')
        .on('click', function (): void { self.goToSoilMoistureSumOfCropzone(); })
        .attr('d', leftRoundedRect(
          x(this.refill_point - (2 * (beforeAfterSUMS / 4))) + this.margin.left,
          this.distanceFromTopForBar,
          x(this.refill_point - (2 * (beforeAfterSUMS / 4))) - x(x.domain()[0]) - this.radius,
          this.barHeight,
          this.radius,
          true))
        .attr('fill', '#f2f2f2')
        .attr('stroke-width', (this.border * 2))
        .attr('stroke', 'rgb(218, 218, 218)')
        .attr('class', 'pointerCursor')
        .attr('transform', `translate(0,${element.offsetHeight - 110})`);
    } else if (this.sumExtendsWidthOfChart) {
      // after 10 percent of sumFC
      const rectangle1 = svg.append('path')
        .on('click', function (): void { self.goToSoilMoistureSumOfCropzone(); })
        .attr('d', rightRoundedRect(
          x(this.field_capacity + (2 * beforeAfterSUMS / 4)) + this.margin.left,
          this.distanceFromTopForBar,
          x(x.domain()[1]) - x(this.field_capacity + (2 * beforeAfterSUMS / 4)) - this.radius,
          this.barHeight,
          this.radius,
          true))
        .attr('fill', '#f2f2f2')
        .attr('stroke-width', (this.border * 2))
        .attr('stroke', 'rgb(218, 218, 218)')
        .attr('class', 'pointerCursor')
        .attr('transform', `translate(0,${element.offsetHeight - 110})`);

      const rectangle2 = svg.append('path')
        .on('click', function (): void { self.goToSoilMoistureSumOfCropzone(); })
        .attr('d', leftRoundedRect(
          x(this.field_capacity + (beforeAfterSUMS / 4)) + this.margin.left,
          this.distanceFromTopForBar,
          x(this.field_capacity + (beforeAfterSUMS / 4)) - x(x.domain()[0]) - this.radius,
          this.barHeight,
          this.radius,
          true
        ))
        .attr('fill', '#f2f2f2')
        .attr('stroke-width', (this.border * 2))
        .attr('stroke', 'rgb(218, 218, 218)')
        .attr('class', 'pointerCursor')
        .attr('transform', `translate(0,${element.offsetHeight - 110})`);
    }

    // inside rectangle (green or red)
    if (!this.sumExtendsWidthOfChart && !SMSIsBefore10PercentSumRP) {
      // inside graphic width (sumRP - 10% and sumFC + 10%)
      if (this.sum_sm > this.refill_point) {
        // Over sumRP but below sumFC + 10%
        if (this.sum_sm > this.field_capacity) {
          // SMS bigger than sumFC => color: #337ab7 (blue)
          const insideRectangle = svg.append('path')
            .on('click', function (): void { self.goToSoilMoistureSumOfCropzone(); })
            .attr('d', leftRoundedRect(
              x(this.sum_sm) + this.margin.left,
              this.distanceFromTopForBar + this.border,
              x(this.sum_sm) - x(x.domain()[0]) - this.radius - this.border,
              this.barHeight - (2 * this.border),
              this.radius,
              false))
            .attr('fill', '#337ab7')
            .attr('class', 'pointerCursor')
            .attr('transform', `translate(0,${element.offsetHeight - 110})`);
        } else {
          // SMS bigger than sumRP but lower than sumFC => color: #449d44 (green)
          const insideRectangle = svg.append('path')
            .on('click', function (): void { self.goToSoilMoistureSumOfCropzone(); })
            .attr('d', leftRoundedRect(
              x(this.sum_sm) + this.margin.left,
              this.distanceFromTopForBar + this.border,
              x(this.sum_sm) - x(x.domain()[0]) - this.radius - this.border,
              this.barHeight - (2 * this.border),
              this.radius,
              false))
            .attr('fill', '#449d44')
            .attr('class', 'pointerCursor')
            .attr('transform', `translate(0,${element.offsetHeight - 110})`);
        }
      } else {
        // between sumRP - 10% and sumRP
        const insideRectangle = svg.append('path')
          .on('click', function (): void { self.goToSoilMoistureSumOfCropzone(); })
          .attr('d', leftRoundedRect(
            x(this.sum_sm) + this.margin.left,
            this.distanceFromTopForBar + this.border,
            x(this.sum_sm) - x(x.domain()[0]) - this.radius - this.border,
            this.barHeight - (2 * this.border),
            this.radius,
            false))
          .attr('fill', '#d9534f')
          .attr('class', 'pointerCursor')
          .attr('transform', `translate(0,${element.offsetHeight - 110})`);
      }
    } else if (this.sumExtendsWidthOfChart) {
      // over sumFC + 10%
      const insideRectangle1 = svg.append('path')
        .on('click', function (): void { self.goToSoilMoistureSumOfCropzone(); })
        .attr('d', leftRoundedRect(
          x(this.field_capacity + (beforeAfterSUMS / 4)) + this.margin.left,
          this.distanceFromTopForBar + this.border,
          x(this.field_capacity + (beforeAfterSUMS / 4)) - x(x.domain()[0]) - this.radius - this.border,
          this.barHeight - (2 * this.border),
          this.radius,
          false
        ))
        .attr('fill', '#337ab7')
        .attr('class', 'pointerCursor')
        .attr('transform', `translate(0,${element.offsetHeight - 110})`);

      const insideRectangle2 = svg.append('rect')
        .on('click', function (): void { self.goToSoilMoistureSumOfCropzone(); })
        .attr('x', x(this.field_capacity + (2 * beforeAfterSUMS / 4)) + this.margin.left)
        .attr('y', this.distanceFromTopForBar + this.border)
        .attr('width', x(x.domain()[1]) - x(this.field_capacity + (3 * beforeAfterSUMS / 4)))
        .attr('height', this.barHeight - (2 * this.border))
        .attr('class', 'pointerCursor')
        .attr('transform', `translate(0,${element.offsetHeight - 110})`)
        .attr('fill', '#337ab7');
    } else if (SMSIsBefore10PercentSumRP) {
      const insideRectangle = svg.append('path')
        .on('click', function (): void { self.goToSoilMoistureSumOfCropzone(); })
        .attr('d', leftRoundedRect(
          x(this.refill_point - (3 * (beforeAfterSUMS / 4))) + this.margin.left,
          this.distanceFromTopForBar + this.border,
          x(this.refill_point - (3 * (beforeAfterSUMS / 4))) - x(x.domain()[0]) - this.radius - this.border,
          this.barHeight - (2 * this.border),
          this.radius,
          false))
        .attr('fill', '#d9534f')
        .attr('class', 'pointerCursor')
        .attr('transform', `translate(0,${element.offsetHeight - 110})`);
    }

    // sum of refill points line
    const sumRPMarker = svg.append('line')
      .on('click', function (): void { self.goToSoilMoistureSumOfCropzone(); })
      .attr('x1', x(this.refill_point) + this.margin.left)
      .attr('y1', this.distanceFromTopForBar - this.border)
      .attr('x2', x(this.refill_point) + this.margin.left)
      .attr('y2', this.distanceFromTopForBar + this.barHeight + this.border)
      .attr('transform', `translate(0,${element.offsetHeight - 110})`)
      .attr('marker-start', 'url(#triangle)')
      .style('stroke', 'black')
      .style('stroke-width', '2px')
      .attr('class', 'pointerCursor');

    // sum of refill points label
    const sumRPMarkerLabel = svg.append('text')
      .attr('y', 6 + this.distanceFromTopForBar)
      .attr('x', x(this.refill_point) + this.margin.left)
      .attr('style', 'font-size: 17px')
      .style('text-anchor', 'middle')
      .text(this.refill_point + this.unit);

    // sum of field capacities line
    const sumFCMarker = svg.append('line')
      .on('click', function (): void { self.goToSoilMoistureSumOfCropzone(); })
      .attr('x1', x(this.field_capacity) + this.margin.left)
      .attr('y1', this.distanceFromTopForBar - this.border)
      .attr('x2', x(this.field_capacity) + this.margin.left)
      .attr('y2', this.distanceFromTopForBar + this.barHeight + this.border)
      .attr('transform', `translate(0,${element.offsetHeight - 110})`)
      .attr('marker-start', 'url(#triangle)')
      .style('stroke', 'black')
      .style('stroke-width', '2px')
      .attr('class', 'pointerCursor');

    // sum of field capacities marker label
    const sumFCMarkerLabel = svg.append('text')
      .attr('y', 6 + this.distanceFromTopForBar)
      .attr('x', x(this.field_capacity) + this.margin.left)
      .attr('style', 'font-size: 17px')
      .style('text-anchor', 'middle')
      .text(this.field_capacity + this.unit);

    // sum_sm line
    const SMSMarker = svg.append('line')
      .on('click', function (): void { self.goToSoilMoistureSumOfCropzone(); })
      .attr('x1', x(this.sum_sm) + this.margin.left)
      .attr('y1', this.distanceFromTopForBar + this.barHeight + this.border)
      .attr('x2', x(this.sum_sm) + this.margin.left)
      .attr('y2', this.distanceFromTopForBar - this.border)
      .attr('transform', `translate(0,${element.offsetHeight - 110})`)
      .attr('marker-start', 'url(#triangle)')
      .style('stroke', 'black')
      .style('stroke-width', '2px')
      .attr('class', 'pointerCursor');

    if (SMSIsBefore10PercentSumRP) {
      SMSMarker
        .attr('x1', x(this.refill_point - (3 * (beforeAfterSUMS / 4))) + this.margin.left)
        .attr('x2', x(this.refill_point - (3 * (beforeAfterSUMS / 4))) + this.margin.left);
    } else if (this.sumExtendsWidthOfChart) {
      SMSMarker
        .attr('x1', x(this.field_capacity + (3 * (beforeAfterSUMS / 4))) + this.margin.left)
        .attr('x2', x(this.field_capacity + (3 * (beforeAfterSUMS / 4))) + this.margin.left);
    }
    // sum_sm label
    const SMSMarkerLabel = svg.append('text')
      .attr('y', 47 + this.distanceFromTopForBar + this.barHeight)
      .attr('x', x(this.sum_sm) + this.margin.left)
      .attr('style', 'font-size: 17px')
      .style('text-anchor', 'middle')
      .text(this.sum_sm + this.unit);

    if (SMSIsBefore10PercentSumRP) {
      SMSMarkerLabel.attr('x', x(this.refill_point - (3 * (beforeAfterSUMS / 4))) + this.margin.left);
    } else if (this.sumExtendsWidthOfChart) {
      SMSMarkerLabel.attr('x', x(this.field_capacity + (3 * (beforeAfterSUMS / 4))) + this.margin.left);
    }

    // marker triangle
    svg.append('svg:defs').append('svg:marker')
      .attr('id', 'triangle')
      .attr('refX', 5)
      .attr('refY', 3)
      .attr('markerWidth', 10)
      .attr('markerHeight', 10)
      .attr('orient', 'auto')
      .append('path')
      .attr('d', 'M 0 0 6 3 0 6') //  1.5 3
      .style('fill', 'black');

    function leftRoundedRect(cordX: number, cordY: number, width: number, height: number, radius: number, openStroke: boolean): string {
      if (openStroke) {
        return 'M' + cordX + ',' + cordY
          + 'h -' + width
          + 'a -' + radius + ',' + radius + ' 0 0 0 -' + radius + ',' + radius
          + 'v' + (height - (2 * radius))
          + 'a' + radius + ',' + radius + ' 0 0 0' + radius + ',' + radius
          + 'h' + (width);
      } else {
        return 'M' + cordX + ',' + cordY
          + 'h -' + width
          + 'a -' + radius + ',' + radius + ' 0 0 0 -' + radius + ',' + radius
          + 'v' + (height - (2 * radius))
          + 'a' + radius + ',' + radius + ' 0 0 0' + radius + ',' + radius
          + 'h' + (width)
          + 'z';
      }
    }

    function rightRoundedRect(cordX: number, cordY: number, width: number, height: number, radius: number, openStroke: boolean): string {
      if (openStroke) {
        return 'M' + cordX + ',' + cordY
          + ' h ' + width
          + ' a ' + radius + ',' + radius + ' 0 0 1 ' + radius + ',' + radius
          + ' v ' + (height - (2 * radius))
          + ' a -' + radius + ',' + radius + ' 0 0 1 -' + radius + ',' + radius
          + ' h -' + width;
      } else {
        return 'M' + cordX + ',' + cordY
          + ' h ' + width
          + ' a ' + radius + ',' + radius + ' 0 0 1 ' + radius + ',' + radius
          + ' v ' + (height - (2 * radius))
          + ' a -' + radius + ',' + radius + ' 0 0 1 -' + radius + ',' + radius
          + ' h -' + width
          + ' z';
      }
    }
  }
  public goToSoilMoistureSumOfCropzone(): void {
    this.router.navigate([`/cropzone/${this.cropzone.id}/soil-moisture/sums-sm`]);
  }
}
