import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IscoutGlueBoardActionTypes} from '../actions/iscout-glue-boards';
import {iScoutGlueBoardInitialState} from '../constants/constants';
import {IIscoutGlueBoardState} from '../models/iscout.models';

export function reducer(state: IIscoutGlueBoardState = iScoutGlueBoardInitialState, action: IActionWithPayload): IIscoutGlueBoardState {
  switch (action.type) {
    case IscoutGlueBoardActionTypes.SET_ISCOUT_GLUE_BOARDS:
      return {
        ...state,
        glueBoards: action.payload
      };
    case IscoutGlueBoardActionTypes.SET_ISCOUT_GLUE_BOARD_AVAILABLE_DATES:
      return {
        ...state,
        availableDates: action.payload
      };
    case IscoutGlueBoardActionTypes.SELECT_ISCOUT_GLUE_BOARD:
      return {
        ...state,
        selectedGlueBoard: action.payload
      };
    case IscoutGlueBoardActionTypes.UNSELECT_ISCOUT_GLUE_BOARD:
      return {
        ...state,
        selectedGlueBoard: null
      };
    case IscoutGlueBoardActionTypes.SET_ISCOUT_GALLERY_GLUE_BOARD_SELECTION:
      return {
        ...state,
        selectedGalleryGlueBoard: action.payload === null ? null : state.glueBoards.find(
          (glueBoard) => glueBoard._id === action.payload
        )
      };
    default:
      return state;
  }
}
