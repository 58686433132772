import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IDisease, IDiseaseModel } from '../../../../core/models/diseases.models';
import { IWidgetDiseaseControl } from '../../models/dashboard.models';
import { deepClone } from '../../../../shared/utils/deepClone';

@Component({
  selector: 'app-custom-multi-dropdown',
  templateUrl: './custom-multi-dropdown.component.html',
  styleUrls: ['./custom-multi-dropdown.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomMultiDropdownComponent),
    multi: true
  }]
})
export class CustomMultiDropdownComponent implements ControlValueAccessor, OnChanges {
  @Input()
  public color                      : string = 'blue';
  @Input()
  public width                      : string = 'auto';
  @Input()
  public options                    : Array<IDisease> = [];
  @Input()
  public placeholder                : string = 'Search..';
  @Input()
  public searchable                 : boolean = true;
  public currentValue               : IWidgetDiseaseControl;
  public isOpen                     : boolean = false;
  public activeDisease              : IDisease = null;

  public filteredOptions: Array<IDisease> = [];
  public searchTerm: string = '';

  private propagateChange: any = () => { return; };

  public checkArrow(key: string, group: string): boolean {
    return key === this.currentValue.disease && group === this.currentValue.crop;
  }

  public toggleOpen(): void {

    this.isOpen = !this.isOpen;
  }

  public close(): void {
    this.isOpen = false;
    this.searchTerm = '';
    this.filteredOptions = deepClone(this.options);
  }

  public onChange(option: IDiseaseModel): void {
    this.currentValue = {
      crop: this.activeDisease.group,
      disease: option.key,
      diseaseName: option.name
    };
    this.propagateChange(this.currentValue);
  }

  public writeValue(value: IWidgetDiseaseControl): void {
    this.currentValue = value;
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    return;
  }

  public setDisabledState(isDisabled: boolean): void {
    return;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.filteredOptions = this.options;
    }
    this.setContent();
  }

  public setActiveDisease(disease: IDisease): void {
    this.activeDisease = disease;
  }

  public removeActiveDisease(): void {
    this.activeDisease = null;
  }

  public stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  public filterOptions(): void {
    if (this.searchTerm === '') {
      this.filteredOptions = this.options;
    } else {
      this.filteredOptions = deepClone(this.options)
      .filter((option: IDisease) => option.group.toLowerCase().includes(this.searchTerm.toLowerCase()));
    }
  }

  public resetDropdown(): void {
    this.searchTerm = '';
    this.filteredOptions = deepClone(this.options);
  }

  private setContent(): void {
    if (this.options.length < 1) {
      return;
    }
    let option: IDisease;
    if (this.currentValue) {
      option = this.options.find((o) =>
        o.group === this.currentValue.crop
        && !!o.models.find((m) => m.key === this.currentValue.disease));
    }
    if (!option) {
      this.currentValue = {
        crop: this.options[0].group,
        disease: this.options[0].models[0].key,
        diseaseName: this.options[0].models[0].name
      };
      this.propagateChange(this.currentValue);
    }
  }

}
