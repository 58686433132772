<ng-container [formGroup]="form" *ngIf="form && warning">
  <td class="warning-td warning-td__first"> {{warning.chain}}</td>
  <td class="warning-td">{{warning.ch}}</td>
  <td class="warning-td">{{warning.code}}</td>
  <td class="warning-td">{{warning.name | translate}}</td>
  <td class="warning-td">{{warning.unit}}</td>
  <td class="warning-td">
    <app-form-input [formControl]="min"
                    class="warning-td__control"
                    [size]="'very-small'"
                    appWarningSensor
                    appTooltip
                    [tooltipPlacement]="'top'"
                    [tooltipWidth]="'200px'"
                    [tooltipColor]="'red'"
                    [tooltipDisable]="!min.invalid && !isMinMax"
                    [tooltipTitle]="error"
                    [styles]="{'background-color': '#fffffff',
                               'border-color': min.invalid || isMinMax ? 'red' : ''}"
                    [placeholder]="'Thresholds (e.g. 4, -5, 22)' | translate"
                    [type]="'border'"></app-form-input>
  </td>
  <td class="warning-td warning-td__last">
    <app-form-input [formControl]="max"
                    class="warning-td__control"
                    [size]="'very-small'"
                    appWarningSensor
                    appTooltip
                    [tooltipColor]="'red'"
                    [tooltipPlacement]="'top'"
                    [tooltipWidth]="'200px'"
                    [tooltipDisable]="!max.invalid && !isMinMax"
                    [tooltipTitle]="error"
                    [styles]="{'background-color': '#fffffff',
                               'border-color': max.invalid || isMinMax ? 'red' : ''}"
                    [placeholder]="'Thresholds (e.g. 4, -5, 22)' | translate"
                    [type]="'border'"></app-form-input>
  </td>
</ng-container>
