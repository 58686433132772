<app-sample-data-layout>

  <ng-container *ngIf="devices.dualex">
    <app-sample-data-map [data]="data"
                         [cropzone]="cropzone"
                         [activeSample]="activeSample"
                         [activeDevice]="activeDevice"
                         [activeMeter]="activeMeter"
                         (navigate)="navigatePage($event)"
                         [lengend]="false">
    </app-sample-data-map>
  </ng-container>

  <app-sample-data-toolbar (activateTable)="isTableActive = $event"
                           (dropSelection)="navigatePage({source: null, activeSample: null, activeDevice: null})"
                           [activeDeviceType]="deviceType">
  </app-sample-data-toolbar>

    <div class="sample-data-content">

      <ng-container *ngIf="isReady; else loadInfo">

      <div class="dualex-table"  *ngIf="devices.dualex?.length">
        <div *ngFor="let table of devices.dualex" #deviceTables [id]="table[0]?.deviceID">
          <app-sample-data-table [tableData]="table"
                                 [columnDefs]="dualexDefs"
                                 deviceType="Dualex Device"
                                 [activeSample]="table[0]?.deviceID === activeDevice ? activeSample : null"
                                 [isTableActive]="isTableActive"
                                 (navigate)="navigatePage($event)">
          </app-sample-data-table>
        </div>
      </div>

      <div class="mobilab-table"  *ngIf="devices.mobilab?.length">
        <div *ngFor="let table of devices.mobilab" #deviceTables [id]="table[0]?.sampleName">
          <app-sample-data-table [tableData]="table"
                                 [columnDefs]="mobilabDefs"
                                 deviceType="MobiLab Device"
                                 [activeSample]="table[0]?.sampleName === activeDevice ? activeSample : null"
                                 [isTableActive]="isTableActive"
                                 (navigate)="navigatePage($event)">
          </app-sample-data-table>
        </div>
      </div>
      </ng-container>

      <ng-template #loadInfo>
        <app-station-load-info
          [errorText]="errorText"
          [isLoading]="isLoading">
        </app-station-load-info>
      </ng-template>
    </div>

</app-sample-data-layout>
