import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable, Subject, timer } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectSelectedCropZone } from '../../../../core/reducers';
import * as fromSelectedCropzone from '../../../../core/reducers/selectedCropZone';
import { SoilGuardColumns } from '../../../../shared/constants/sample-data.constant';
import { SampleDeviceService } from '../../../../shared/services/sample-device/sample-device.service';
import { deepClone } from '../../../../shared/utils/deepClone';
import { getSoilGuardByDate } from '../../actions/cropzone-missions.action';
import { setActiveMeter, setDeviceType } from '../../actions/crozone-settings.action';
import { ICropzoneSampleState, selectActiveDevice, selectSamples, selectSettings } from '../../reducers';
import { SampleDataService } from '../../services/sample-data.service';

@Component({
  templateUrl: './sample-soilguard.component.html',
  styleUrls: ['./sample-soilguard.component.scss'],
  providers: [SampleDeviceService]
})
export class SampleSoilguardComponent implements OnInit, OnDestroy {

  public station: IStation;
  public isReady = false;
  public isLoading = true;
  public isTableActive: boolean = true;
  public soilGuardDefs = SoilGuardColumns;
  public alive$ = new Subject<void>();
  public station$: Observable<IStation>;
  public cropzone;
  public settings$;
  public errorText: string;
  public deviceType = 'soilguard';
  public activeMeter = 'vwc';
  public activeDevice;
  public activeSample;
  public date;
  public devices = {
    soilGuard: [],
  };

  @ViewChildren('deviceTables') private tableList: QueryList<any>;

  constructor(
    private cropzoneSampleStore: Store<ICropzoneSampleState>,
    private selectedStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private sampleDataService: SampleDataService,
    private sampleDeviceService: SampleDeviceService
  ) {

    this.cropzoneSampleStore.dispatch(setActiveMeter('vwc'));
  }

  public ngOnInit(): void {

    this.cropzoneSampleStore.dispatch(setDeviceType('soilguard'));
    this.deviceType = 'soilguard';

    this.cropzoneSampleStore.pipe(
      select(selectActiveDevice),
      takeUntil(this.alive$),
      filter(_ => Boolean(_)),
      tap(_ => this.activeDevice = _),
      tap(() => this.activeSample = null)
    ).subscribe(activeDevice =>
      this.navigatePage({
        source: null,
        activeSample: null,
        activeDevice
      })
    );

    this.selectedStore.pipe(
      select(selectSelectedCropZone),
      takeUntil(this.alive$),
      tap(_ => this.deviceType = 'soilguard'),
      distinctUntilChanged((prev, curr) => prev.id === curr.id),
    ).subscribe(cropzone =>
      this.cropzone = cropzone
    );

    this.settings$ = this.cropzoneSampleStore.pipe(
      select(selectSettings),
      tap(() => {
        this.isLoading = true;
        this.isReady = false;
      }),
      takeUntil(this.alive$)
    );

    this.cropzoneSampleStore.pipe(
      select(selectSamples),
      takeUntil(this.alive$),
      filter(data => Boolean(data))
    ).subscribe(tables => {

      this.devices = {
        soilGuard: [],
      };

      const soilGuardSample = (tables.soilGuard[0] && tables.soilGuard[0][0]);

      this.updateUnits(soilGuardSample);

      if ('soilguard' === this.deviceType && tables.soilGuard.length) {
        this.devices.soilGuard = this.sampleDeviceService.parseTableData(tables.soilGuard, this.activeMeter, 'soilguard');
      }

      this.isReady = Boolean(
        this.devices.soilGuard.length
      );

      timer(2000).subscribe(() => this.isLoading = false);
    });

    this.settings$.pipe(
      filter(data => Boolean(data)),
    ).subscribe(settings => {

      this.sampleDeviceService.setTitleActive(settings.activeTitle);
      this.activeMeter = settings.activeMeter;
      this.date = this.sampleDeviceService.setPeriod(settings);

      if (this.date && this.date.from) {
        const fromStr = moment.unix(this.date.from).format('DD/MM/YYYY');
        const toStr = moment.unix(this.date.to).format('DD/MM/YYYY');
        this.errorText = `The sample data was not found for the period from ${fromStr} to ${toStr}`;
        this.cropzoneSampleStore.dispatch(
          getSoilGuardByDate({cropzone: this.cropzone.id, date: this.date})
        );
      }
    });

  }

  /*
   * Quick hack to extract units (ex. °C, °F, %, etc) from the response
   *
   * @param soilGuardSample
   */
  public updateUnits(soilGuardSample): void {
    if (soilGuardSample && soilGuardSample.temp_unit) {
      const {vwc_unit, temp_unit, ec_unit} = soilGuardSample;

      this.sampleDataService.translate(deepClone(SoilGuardColumns))
        .subscribe(columns => {

          columns[1].headerName += ` [${vwc_unit}]`;
          columns[2].headerName += ` [${ec_unit}]`;
          columns[3].headerName += ` [${temp_unit}]`;

          this.soilGuardDefs = columns;
        });
    }
  }

  /**
   * Custom page navigation using scrolling
   *
   * @param data
   * @param source
   */
  public navigatePage({source, activeDevice, activeSample}): void {

    this.sampleDeviceService.scrollInto(source, activeDevice, this.tableList);

    this.activeDevice = activeDevice;
    this.activeSample = activeSample;
  }

  public ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }
}
