<app-camera-slideshow-header></app-camera-slideshow-header>
<app-camera-slideshow-toolbar (refreshEmitter)="refresh()"
                              [isFullscreen]="isFullscreen$ | async"></app-camera-slideshow-toolbar>
<ng-container *ngIf="!(isLoading$ | async) && !(isError$ | async); else loadinfo">
  <app-custom-carousel [pictures]="pictures$ | async"
                       [setSize]="1"
                       [hasActivePicture]="false"
                       [isPlaying]="isPlaying$ | async"
                       [playSpeed]="playSpeed$ | async"
                       [startIndex]="0"
                       [noPause]="true"
                       [noWrap]="true"
                       [activeSlide]="activeSlide$ | async"
                       [animation]="true"
                       [showIndicators]="false"
                       [pictureBorder]="false"
                       [ngClass]="{'fullscreen': isFullscreen$ | async }"
                       [srcProperty]="'url'" [fullSizeImages]="true"></app-custom-carousel>
</ng-container>

<ng-template #loadinfo>
  <app-station-load-info [isError]="isError$ | async"
                         [isLoading]="isLoading$ | async"></app-station-load-info>
</ng-template>
