import {IActionWithPayload} from '../models/actionWithPayload';
import {StationsActionTypes} from '../actions/stations';
import {IStation, IWarningsResponseItem} from '../models/stations';
import {deepClone} from '../../shared/utils/deepClone';

export interface IVirtualStationDetails {
  elevation: number;
  timezoneCode: string;
}

export interface IStations {
  stations: Array<IStation>;
  virtualStation: IVirtualStationDetails;
}

const initialState: IStations = {
  stations: null,
  virtualStation: {
    elevation: 0,
    timezoneCode: 'UTC'
  }
};

export function reducer(state: IStations = initialState, action: IActionWithPayload ): IStations {
  switch (action.type) {

    case StationsActionTypes.SET_STATIONS: {
      return {
        ...initialState,
        stations: action.payload
      };
    }
    case StationsActionTypes.CHANGE_STATION: {
      return {
        ...state,
        stations: cloneHelp(action.payload, state.stations)
      };
    }
    case StationsActionTypes.DELETE_STATION: {
      return {
        ...state,
        stations: deleteStationHelp(action.payload, state.stations)
      };
    }
    case StationsActionTypes.SET_WARNINGS: {
      return {
        ...state,
        stations: state.stations.map(station => {
          const warning = (<IWarningsResponseItem[]>action.payload).find(item => item.station_name === station.name.original);
          station.warnings = warning ? warning.warnings : station.warnings;
          return station;
        })
      };
    }
    case StationsActionTypes.SET_UNIT_SYSTEM: {
      return {
        ...state,
        stations: state.stations.map(station => {
          station.metaUnits = action.payload;
          return station;
        })
      };
    }
    case StationsActionTypes.SET_VIRTUAL_STATION_ELEVATION: {
      return {
        ...state,
        virtualStation: {
          ...state.virtualStation,
          elevation: action.payload
        }
      };
    }
    case StationsActionTypes.SET_VIRTUAL_STATION_TIMEZONE: {
      return {
        ...state,
        virtualStation: {
          ...state.virtualStation,
          timezoneCode: action.payload
        }
      };
    }
    default: {
      return state;
    }
  }
}

function cloneHelp(station: IStation, oldStations: Array<IStation>): Array<IStation> {
  const stations: Array<IStation> = deepClone(oldStations);
  const index: number = stations.findIndex((s: IStation) => s.name.original === station.name.original);
  if (index !== undefined) {
    stations[index] = station;
  } else {
    stations.push(station);
  }
  return stations;
}

function deleteStationHelp(id: string, stations: IStation[]): IStation[] {
  const newStations: IStation[] = stations.filter((s: IStation) => s.name.original !== id);
  return deepClone(newStations);
}
