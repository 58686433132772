import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-widget-add-info',
  templateUrl: './widget-add-info.component.html',
  styleUrls: ['./widget-add-info.component.scss']
})
export class WidgetAddInfoComponent {
  @Input()
  public text                   : string = '';
  @Input()
  public description            : string = '';

}
