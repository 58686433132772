import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { setSelectedNotification } from '../../../actions/notifications';
import { INotification } from '../../../models/notifications';
import { selectNotifications } from '../../../reducers/';
import * as fromAccount from '../../../reducers/account';
import * as fromStations from '../../../reducers/stations';
import { emptyNotification } from './../../../constants/notifications';
import { selectStations } from './../../../reducers/index';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {

  public notifications: INotification[];
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public emptyNotification = emptyNotification;
  public hasKey1Stations: boolean = false;

  constructor(
    private userStore: Store<fromAccount.IAccount>,
    private router: Router,
    private stationStore: Store<fromStations.IStations>
  ) {

  }

  public ngOnInit(): void {
    this.stationStore.pipe(
      select(selectStations),
      filter((stations) => !!stations && stations.length > 0),
      takeUntil(this.destroy$)
    ).subscribe((stations) => {
      this.hasKey1Stations = stations.some((station) => station.rights === 'rw');
    });

    this.userStore.pipe(
      select(selectNotifications),
      filter((notifications) => JSON.stringify(notifications[0]) !== JSON.stringify(this.emptyNotification)),
      takeUntil(this.destroy$)
    ).subscribe((notifications: any) => {
      notifications.sort((function(a, b): any {
        return new Date(b.time).getTime() - new Date(a.time).getTime();
      }));
      const unseenNotifications = notifications.filter(item => !item.seen);
      const seenNotifications = notifications.filter(item => item.seen);
      const concattedNotifications = unseenNotifications.concat(seenNotifications);
      this.notifications = concattedNotifications.slice(0, 5);
    });
  }

  public goToStationPage(item): void {
    this.userStore.dispatch(setSelectedNotification(item));
    this.router.navigate([`/station/${item.stationID}/data`]);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
