import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { WIDE_CAMM_ID, ZOOM_CAMM_ID } from '../../../../core/constants/camera';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { IGetPhotosRequests, IPicture, PhotoRequestType } from '../../../../shared/camera/models/camera';
import { IRectangle } from '../../../../shared/camera/models/rectangles';
import { READ_AND_WRITE_PERMISSION, StationTypes } from '../../../../shared/constants';
import { getCameraDeviceType, StationCameraTypes } from '../../../camera-data/constants/camera-data';
import { StationTopologyLinkType } from '../../../station-config/constants/constants';
import { getCropViewFirstDate, getCropViewLastDate, getCropViewPhotos } from '../../actions/crop-view';
import {
  getCameraDistance,
  getCameraDrawingOptions,
  resetCropViewSettings,
  setCropViewSettingsSelectedPicture
} from '../../actions/crop-view-settings';
import { ICropViewSettingsState, ICropViewState, ISelectedCameraPicture } from '../../models/crop-view.models';
import {
  selectCropViewIsError,
  selectCropViewIsLoading,
  selectCropViewSettingsCurrentDateString,
  selectCropViewSettingsSelectedPicture,
  selectCropViewWidePictures,
  selectCropViewZoomPictures
} from '../../reducers';

@Component({
  selector: 'app-crop-view-content',
  templateUrl: './crop-view-content.component.html',
  styleUrls: ['./crop-view-content.component.scss']
})
export class CropViewContentComponent implements OnInit, OnDestroy {
  public isLoading$: Observable<boolean>;
  public isError$: Observable<boolean>;
  public hasWritePermission$: Observable<boolean>;
  public widePictures$: Observable<Array<IPicture>>;
  public zoomPictures$: Observable<Array<IPicture>>;
  public selectedPicture$: Observable<IPicture>;
  public topologyLinkType = StationTopologyLinkType;

  public stationType: StationCameraTypes;
  public stationId: string;
  public previousStationId: string;
  private currentDateString: string;
  public activeCameraId: number = WIDE_CAMM_ID;
  private rectangles: Array<IRectangle> = [];
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private cropViewStore: Store<ICropViewState>,
              private cropViewSettingsStore: Store<ICropViewSettingsState>,
              private navigationStationStore: Store<INavigationStationState>) { }

  public ngOnInit(): void {
    this.initStatusListeners();
    this.initPictureListeners();
    this.initDataRetrieving();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public selectPicture(selected: ISelectedCameraPicture): void {
    this.cropViewSettingsStore.dispatch(setCropViewSettingsSelectedPicture(selected.picture));
    this.activeCameraId = selected.activeCameraId;
  }

  public unselectPicture(): void {
    this.cropViewSettingsStore.dispatch(resetCropViewSettings());
  }

  private initStatusListeners(): void {
    this.isLoading$ = this.cropViewStore.pipe(
      select(selectCropViewIsLoading)
    );

    this.isError$ = this.cropViewStore.pipe(
      select(selectCropViewIsError)
    );
  }

  private initPictureListeners(): void {
    this.hasWritePermission$ = this.getStationChangeObservable().pipe(
      map((station: IStation): boolean => station.rights === READ_AND_WRITE_PERMISSION)
    );

    this.widePictures$ = this.cropViewStore.pipe(
      select(selectCropViewWidePictures),
      filter((pictures: Array<IPicture>): boolean => !!pictures)
    );

    this.zoomPictures$ = this.cropViewStore.pipe(
      select(selectCropViewZoomPictures),
      filter((pictures: Array<IPicture>): boolean => !!pictures)
    );

    this.selectedPicture$ = this.cropViewSettingsStore.pipe(
      select(selectCropViewSettingsSelectedPicture),
      tap((picture: IPicture): void => {
        if (!!picture) {
          this.rectangles = picture.rectangles || [];
        }
      })
    );
  }

  private initDataRetrieving(): void {
    combineLatest([
      this.getCropViewCurrentDateObservable(),
      this.getStationChangeObservable()
    ]).subscribe((): void => {
      this.cropViewStore.dispatch(getCropViewPhotos(this.photosRequests));
    });

    this.initRefreshingByUser();
  }

  private getCropViewCurrentDateObservable(): Observable<void> {
    return this.cropViewSettingsStore.pipe(
      takeUntil(this.destroy$),
      select(selectCropViewSettingsCurrentDateString),
      filter((currentDateString: string): boolean => !!currentDateString),
      map((currentDateString: string): void => {
        this.currentDateString = currentDateString;
      })
    );
  }

  private getStationChangeObservable(): Observable<IStation> {
    return this.navigationStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((station: IStation): boolean => !!station),
      tap((station: IStation): void => {
        if (station.info.device_id === StationTypes.get('CropViewType2')) {
          this.activeCameraId = ZOOM_CAMM_ID;
        }
        if (this.previousStationId !== station.name.original) {
          this.unselectPicture();
          this.previousStationId = station.name.original;
        }
        this.stationId = station.name.original;
        this.stationType = getCameraDeviceType(station.info.device_id);
      })
    );
  }

  private get photosRequests(): IGetPhotosRequests {
    return {
      type: PhotoRequestType.SINGLE_DATE_INTERVAL,
      stationId: this.stationId,
      camIds: [WIDE_CAMM_ID, ZOOM_CAMM_ID],
      date: this.currentDateString
    };
  }

  private initRefreshingByUser(): void {
    this.getStationChangeObservable().subscribe((): void => {
      this.cropViewSettingsStore.dispatch(getCropViewLastDate(this.stationId));
      this.cropViewSettingsStore.dispatch(getCropViewFirstDate(this.stationId));
      this.cropViewSettingsStore.dispatch(getCameraDistance(this.stationId));
      this.cropViewSettingsStore.dispatch(getCameraDrawingOptions(this.stationId));
    });
  }
}
