import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IFrostProtectionState } from '../models/models';

export const frostProtectionSelector = createFeatureSelector<IFrostProtectionState>('frostProtection');

export const selectFrostProtectionTree = createSelector(
  frostProtectionSelector,
  (state: IFrostProtectionState) => state.tree
);

export const selectFrostProtectionTreeNodes = createSelector(
  frostProtectionSelector,
  (state: IFrostProtectionState) => state.tree.nodes
);

export const selectFrostProtectionError = createSelector(
  frostProtectionSelector,
  (state: IFrostProtectionState) => state.dataLoading.error.isError
);

export const selectFrostProtectionMessage = createSelector(
  frostProtectionSelector,
  (state: IFrostProtectionState) => state.dataLoading.error.errorMessage
);

export const selectFrostProtectionLoading = createSelector(
  frostProtectionSelector,
  (state: IFrostProtectionState) => state.dataLoading.isLoading
);

export const selectFrostProtectionPeriod = createSelector(
  frostProtectionSelector,
  (state: IFrostProtectionState) => state.settings.period
);

export const selectFrostProtectionSettings = createSelector(
  frostProtectionSelector,
  (state: IFrostProtectionState) => state.settings
);

export const selectFrostProtectionResponse = createSelector(
  frostProtectionSelector,
  (state: IFrostProtectionState) => state.response
);

export const selectFrostProtectionIsChartActive = createSelector(
  frostProtectionSelector,
  (state: IFrostProtectionState) => state.settings.activity.isChartActive
);

export const selectFrostProtectionIsTableActive = createSelector(
  frostProtectionSelector,
  (state: IFrostProtectionState) => state.settings.activity.isTableActive
);

export const selectFrostProtectionCharts = createSelector(
  frostProtectionSelector,
  (state: IFrostProtectionState) => state.charts
);

export const selectFrostProtectionVisibility = createSelector(
  frostProtectionSelector,
  (state: IFrostProtectionState) => state.visibility
);
