import { Observable, throwError } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { environmentToken } from '../../../environments/environment';
import { IEnvironment } from '../../../environments/interfaces/environment';
import { UserTokenRepository } from './user-token-repository.service';
import { map, switchMap } from 'rxjs/operators';
import {
  IAuthClientData,
  IBaseAuthRequest,
  IErrorResponse,
  ILoginRequest,
  IOneTokenResponse,
  IRefreshRequest,
  IReqistrationRequest,
  IResetPasswordRequest,
  ITokenBasedAuthRequest,
  IUpdatePasswordRequest,
  IUserCredentials,
  IUserToken
} from '../../modules/auth/login.interfaces';
import { Store } from '@ngrx/store';
import { logout } from '../../actions/metaActions';
import { NavigationExtras } from '@angular/router';

@Injectable()
export class AuthService {

  private static readonly GRANT_TYPE_PASSWORD: string = 'password';
  private static readonly GRANT_TYPE_REFRESH: string = 'refresh_token';
  private static readonly GRANT_TYPE_CLIENT_CREDENTIALS: string = 'client_credentials';
  private static readonly GRANT_TYPE_AUTHORIZATION_CODE: string = 'authorization_code';

  private readonly clientData: IAuthClientData;
  private readonly registrationURL: string;
  private readonly resetPasswordURL: string;
  private readonly activateAccountURL: string;
  private readonly closeAccountURL: string;
  private readonly clientURL: string;
  private readonly updatePasswordURL: string;
  private readonly oAuthURL: string;

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    private http: HttpClient,
    private router: Router,
    private userToken: UserTokenRepository,
    private metaStore: Store<any>,
    private route: ActivatedRoute
  ) {
    this.clientData = {
      client_id: environment.clientId,
      client_secret: environment.clientSecret
    };
    this.registrationURL = `${environment.apiUrl}/user/web/register`;
    this.resetPasswordURL = `${environment.apiUrl}/user/web/password-reset`;
    this.activateAccountURL = `${environment.apiUrl}/dev/user/activate-account`;
    this.closeAccountURL = `${environment.apiUrl}/dev/user/close-account`;
    this.clientURL = `${environment.apiUrl}/dev/user/${this.clientData.client_id}`;
    this.updatePasswordURL = `${this.clientURL}/password-update`;
    this.oAuthURL = environment.oauthUrl;
  }

  private prepareBaseAuthRequest(): IBaseAuthRequest {
    return {
      grant_type: AuthService.GRANT_TYPE_CLIENT_CREDENTIALS,
      ...this.clientData
    };
  }

  public login(credentials: IUserCredentials): Observable<IUserToken> {

    const requestBody: ILoginRequest = {
      grant_type: AuthService.GRANT_TYPE_PASSWORD,
      ...this.clientData,
      ...credentials
    };

    const response$ = this.performAuthRequest(requestBody).pipe(map((response: IUserToken) => {

      if (response && response.access_token) {
        this.userToken.setToken(response);
      }

      this.router.navigate([this.route.snapshot.queryParams['returnUrl'] || '/dashboard']);

      return response;
    }));

    return (<Observable<IUserToken>>response$);
  }

  public deepLinkLogin(code: string): Observable<IUserToken> {
    const credentials: ITokenBasedAuthRequest = {
      grant_type: AuthService.GRANT_TYPE_AUTHORIZATION_CODE,
      ...this.clientData,
      code
    };

    const response$ = this.performAuthRequest(credentials).pipe(map((response: IUserToken) => {
      if (response && response.access_token) {
        this.userToken.setToken(response);
      }

      window.location.href = response.redirect_url;

      return response;
    }));

    return (<Observable<IUserToken>>response$);
  }

  public refreshToken(): Observable<IUserToken> {

    if (!this.userToken.getToken() || this.userToken.getToken().refresh_token) {
      throwError('No refresh token');
    }

    if (this.userToken.getToken()) {
      const requestBody: IRefreshRequest = {
        grant_type: AuthService.GRANT_TYPE_REFRESH,
        refresh_token: this.userToken.getToken().refresh_token,
        ...this.clientData
      };

      return (<Observable<IUserToken>>this.performAuthRequest(requestBody)).pipe(map((response: IUserToken) => {
        this.userToken.setToken(response);
        return response;
      }));
    }
  }

  public getAccessToken(): string {
    return this.userToken.getActualAccessToken();
  }

  public hasAccessToken(): boolean {
    return this.userToken.isTokenActual();
  }

  public register(registerRequest: IReqistrationRequest): Observable<IOneTokenResponse | IErrorResponse> {
    return this.http.post<IOneTokenResponse>(this.registrationURL, registerRequest);
  }

  public activateAccount(token: string): Observable<any> {
    return this.http.post(this.activateAccountURL, { token });
  }

  public closeAccount(token: string): Observable<any> {
    return this.http.post(this.closeAccountURL, { token });
  }

  public resetPassword(resetPasswordRequest: IResetPasswordRequest): Observable<IOneTokenResponse> {
    return this.http.post<IOneTokenResponse>(this.resetPasswordURL, resetPasswordRequest);
  }

  public updatePassword(upatePasswordRequest: IUpdatePasswordRequest, activationKey: string): Observable<any> {

    const response$ = this.performAuthRequest(this.prepareBaseAuthRequest()).pipe(
      switchMap<IUserToken, any>((response: IUserToken) => {

        const headers = new HttpHeaders({
          Authorization: `Bearer ${response.access_token}`
        });

        return this.http.post<IOneTokenResponse>(`${this.updatePasswordURL}/${activationKey}`, upatePasswordRequest, { headers });
      })
    );
    return (<Observable<IOneTokenResponse>>response$);
  }

  public logoutUser(extras: NavigationExtras = null, redirect: Boolean = true): void {

    UserTokenRepository.clearToken();
    this.metaStore.dispatch(logout());

    if (redirect) {
      if (extras) {
        this.router.navigate(['/auth/login'], extras);
      } else {
        this.router.navigate(['/auth/login']);
      }
    }
  }

  private performAuthRequest(requestBody: IBaseAuthRequest): Observable<IUserToken | IOneTokenResponse | IErrorResponse> {
    return this.http.post<IUserToken>(this.oAuthURL, requestBody);
  }
}
