import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {NoteActionTypes} from '../actions/note';

export interface INoteState {
  note: string;
}

const initialState: INoteState = {
  note: '',
};

export function reducer(state: INoteState = initialState, action: IActionWithPayload ): INoteState {
  switch (action.type) {
    case NoteActionTypes.SET_NOTE: {
      return {
        ...state,
        note: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
