import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { IHydroponicsValues } from './models/models';
import { HELP_TEXT, TRANSLATED_VALUES } from './constants/constats';

@Injectable()
export class HydroponicsResolver implements Resolve<any> {
  constructor(
    private translations    : TranslateService
  ) { }

  public resolve(): Observable<any>|Promise<any>|any {

    const translatedValues      : IHydroponicsValues = TRANSLATED_VALUES;
    const translatedHelpText    : string[] = HELP_TEXT;

    Object.keys(translatedValues).forEach(key => {
      this.translations.get(translatedValues[key])
        .subscribe(translatedValue => {
          translatedValues[key] = translatedValue;
        });
    });

     translatedHelpText.forEach((helpValue, i) => {
       this.translations.get(helpValue)
         .subscribe(translatedHelpValue => {
           translatedHelpText[i] = translatedHelpValue;
       });
     });

    return Object.assign(translatedValues, translatedHelpText);
  }
}
