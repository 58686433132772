import { IActionWithPayload } from './../../../core/models/actionWithPayload';
import { ITrackerTripsData, ITrackerPeriod, IReports } from './../models/tracker.models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TrackerActionTypes } from './../actions/tracker';
import { deepClone } from '../../../shared/utils/deepClone';

export interface ITrackerDataState {
  data: ITrackerTripsData[];
  period: ITrackerPeriod;
  reports: IReports;
  isLoading: boolean;
  isError: boolean;
  selectedConnection: IConnection;
  info: IReports;
}

export interface IConnection {
  _id: string;
  trackerID: string;
  machineryID: string;
  machinery: any;
  fromDate: string;
  toDate: string;
  time: number;
  distance: number;
}

export const initialState: ITrackerDataState = {
  data: [],
  period: {
    periodValue: '2d',
    fromTo: {
      from: new Date(),
      to: new Date()
    },
    fromDatepicker: new Date(),
    toDatepicker: new Date(),
    stationId: '',
    isLastDataMode: true
  },
  reports: {
    tracker: [],
    machinery: []
  },
  info: {
    tracker: null,
    machinery: null
  },
  isLoading: true,
  isError: false,
  selectedConnection: {
    _id: null,
    trackerID: null,
    machineryID: null,
    machinery: null,
    fromDate: null,
    toDate: null,
    time: null,
    distance: null
  },
};

export function reducer(state: ITrackerDataState = initialState, action: IActionWithPayload): ITrackerDataState {
  switch (action.type) {
    case TrackerActionTypes.SET_TRACKER_DATA:
      return <ITrackerDataState>{
        ...state,
        data: action.payload
      };
    case TrackerActionTypes.SET_TRACKER_PERIOD:
      return {
        ...state,
        ...action.payload
      };
    case TrackerActionTypes.SET_TRACKER_REPORTS:
      const newStateT = <ITrackerDataState>{ ...state };
      newStateT.reports.tracker = action.payload;
      return newStateT;
    case TrackerActionTypes.SET_MACHINERY_REPORTS:
      const newStateM = <ITrackerDataState>{ ...state };
      newStateM.reports.machinery = action.payload;
      return newStateM;
    case TrackerActionTypes.SET_TRACKER_LOADING:
      return <ITrackerDataState>{
        ...state,
        isLoading: action.payload,
      };
    case TrackerActionTypes.SET_TRACKER_ERROR:
      return <ITrackerDataState>{
        ...state,
        isError: action.payload,
      };
    case TrackerActionTypes.SET_SELECTED_CONNECTION:
      return <ITrackerDataState>{
        ...state,
        selectedConnection: action.payload,
      };
    case TrackerActionTypes.SET_TRACKER_INFO:
      return <ITrackerDataState>{
        ...state,
        info: {
          tracker: action.payload
        }
      };
    case TrackerActionTypes.SET_MACHINERY_INFO:
      return <ITrackerDataState>{
        ...state,
        info: {
          machinery: action.payload
        }
      };
    case TrackerActionTypes.RESET_SELECTED_CONNECTION:
    return <ITrackerDataState>{
        ...state,
        selectedConnection: {
          ...initialState.selectedConnection
        },
        period: {
          ...initialState.period
        },
        info: {
          ...initialState.info
        }
      };

    case TrackerActionTypes.TOGGLE_TRIP_SELECTED:
      const newState = deepClone(state);
      for (const trip of newState.data) {
        if (trip.name === action.payload) {
          trip.selected = !trip.selected;
        }
      }

      return <ITrackerDataState>{
        ...state,
        data: newState.data
      };

    default:
      return state;
  }
}

export const trackerData = createFeatureSelector<ITrackerDataState>('tracker');

export const selectTrackerData = createSelector(
  trackerData,
  (state: ITrackerDataState) => state.data
);

export const selectTrackerLoadError = createSelector(
  trackerData,
  (state: ITrackerDataState) => state.isError
);

export const selectTrackerLoadProcess = createSelector(
  trackerData,
  (state: ITrackerDataState) => state.isLoading
);

export const selectTrackerDataPeriod = createSelector(
  trackerData,
  (state: ITrackerDataState): ITrackerPeriod => state.period
);

export const selectTrackerReports = createSelector(
  trackerData,
  (state: ITrackerDataState): any => state.reports.tracker
);

export const selectMachineryReports = createSelector(
  trackerData,
  (state: ITrackerDataState): any => state.reports.machinery
);

export const selectSelectedConnection = createSelector(
  trackerData,
  (state: ITrackerDataState): any => state.selectedConnection
);

export const selectTrackerInfo = createSelector(
  trackerData,
  (state: ITrackerDataState): any => state.info.tracker
);

export const selectMachineryInfo = createSelector(
  trackerData,
  (state: ITrackerDataState): any => state.info.machinery
);
