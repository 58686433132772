<div class="frost-protection-help-modal">
  <p>
    {{ 'Protect from frost and heat waves keeping an eye over measured and forecast data on the same chart, allowing ' +
  'a quick assessment of the current situation, to identify risks and prevent damage to the crops and to the health ' +
  'of the population. The chart includes by default:' | translate }}
  </p>
  <p>
        • {{ '48h past measured data of air temperature, wet & dry bulb temperature.' | translate }}
    <br>• {{ '48h hyper localized forecast of air temperature and wet bulb temperature updated frequently with the ' +
  'latest data from your station, satellite and radar.' | translate }}
    <br>• {{ 'RAW data.' | translate }}
  </p>

  <br>
  <p>
    <b>{{ 'What is the role of Wet & Dry bulb temperature for frost protection?' | translate }}</b>
  </p>
  <p>
    {{ 'Dry temperature sensor follows more accurately the plant temperature during clear nights, while the wet-bulb ' +
  'temperature can be used to estimate the potential for frost and to determine the best time to turn on sprinklers ' +
  'for frost protection. The dry-bulb temperature falls at night as heat radiates to the sky.' | translate }}
  </p>
  <p>
    {{ 'It is always important to remember that plants can be cooled to temperatures lower than the air temperature ' +
  'by radiation losses from plant surfaces, particularly during clear nights with low humidity. Plants can be ' +
  'protected from frost damage using sprinkler methods, but sprinklers must be turned on at the correct time to ' +
  'avoid cold damage by evaporative cooling and to conserve water. When sprinklers are first turned on, the air ' +
  'around the sprinklers will be cooled to the wet-bulb temperature.' | translate }}
  </p>
  <p>
    {{ 'Sprinklers should be started before wet temperature drops below 0°C (32°F) - set the minimum threshold of ' +
  'wet bulb temperature in FieldClimate at +1°C (34°F) - depending on the crop’s different phenological phases and ' +
  'critical damage temperature to avoid damage to plants. If the wet-bulb temperature is below 0°C (32°F), then cold ' +
  'damage can result from the use of the sprinkler system. Sprinklers can be started at wet-bulb temperatures above ' +
  '1°C (34°F), however, no additional cold protection will be provided, and excessive water will be used.' | translate }}
  </p>

  <br>
  <p>
    <b>{{ 'Notifications' | translate }}</b>
  </p>
  <p>
    • {{ 'Warning horizontal lines are shown on the chart, once the user has set the thresholds specific to its ' +
  'needs under Settings -> SMS Warnings/Notifications: blue line (Warning SMS) and green line (Notification).' | translate }}
    <br>• {{ 'Arrows: represent the thresholds defined by the user under SMS or notifications pages.' | translate }}
    <br>{{ 'arrows down = \'warning at MIN\' or \'below\'' | translate }}
    <br>{{ 'arrows up = \'warning at MAX\' or \'above\'' | translate }}
  </p>
</div>
