import { IEnvironment } from '../../../environments/interfaces/environment';
import { firebaseConfig, environmentConfig } from '../../../environments/environment.config';
import { languageOptions } from './languages';
import { themeConfig } from './theme.config';
import { displayRulesConfig } from '../default/theme.config';

export const environment: IEnvironment = {
  production: true,
  oauthUrl: 'https://oauth.vodafoneideasmartagri.org/token',
  apiUrl: 'https://api.vodafoneideasmartagri.org',
  socketUrl: 'sockets.vodafoneideasmartagri.org',
  myJohnDeereUrl: environmentConfig.myJohnDeereUrl,
  clientId: environmentConfig.clientId,
  clientSecret: environmentConfig.clientSecret,
  gMapKey: 'AIzaSyBdgbUH0rgEeTXgKwPpbnX07xMAiR3uqzE',
  gMapTZKey: environmentConfig.gMapTZKey,
  gMapEndPoint: environmentConfig.gMapEndPoint,
  debugPHP: false,
  displayRules: displayRulesConfig,
  theme: themeConfig,
  firebase: firebaseConfig,
  languages: languageOptions,
};
