<app-tree [tree$]="tree$"></app-tree>
<div class="body-view-content" [@placeContent]="state$ | async">
    <app-cropzone-page-header [pageName]="'General settings'"></app-cropzone-page-header>
    <ng-container>
        <app-cropzone-data class="cropzone-config-content__item"></app-cropzone-data>
        <div class="alert alert-warning cropzone-config-content__alert" *ngIf="selectedCropzone.boundary === null">
            <i class="fa fa-exclamation-triangle"></i> {{ 'The boundaries for this cropzone have not been specified yet. Please specify the boundaries in the map below.' | translate}}
        </div>
        <app-cropzone-config-location (polygonDrawn2)="polygonDrawn($event)"></app-cropzone-config-location>
    </ng-container>
</div>
