import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectYpmConstants } from '../../../../core/reducers';
import * as fromCropzones from '../../../../core/reducers/cropzones';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { dateToUtcUnixTimestamp } from '../../../../shared/utils/dateFormat';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { IPhysiologicalMaturityRequest } from '../../../yield-prediction/models/model';
import { ICropManagement, IFieldWiltingRequest, ISliderSettings } from '../../models/model';
import moment = require('moment');

@Component({
  selector: 'app-yield-slider',
  templateUrl: './yield-slider.component.html',
  styleUrls: ['./yield-slider.component.scss']
})
export class YieldSliderComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public cropManagementData: ICropManagement;

  @Input()
  public sliderData: ISliderSettings;

  @Input()
  public isSavingConfig$: any;

  @Input()
  public isDisabled: boolean;

  @Output()
  private changeSliders: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  private clickSave: EventEmitter<any> = new EventEmitter<any>();

  public wueConstants: any = null;
  public gddConstants: any = null;
  public currentDate: any = new Date();
  public calculatedYieldAverage: string = '';
  public calculatedMaturityDate: string = '';
  public actualUnit: string = '';
  public loading: boolean = false;
  public wueMin: number;
  public wueMax: number;
  public wueStep: number;
  public gddMin: number;
  public gddMax: number;
  public gddStep: number;
  public gddValue: number;
  public wueValue: number;
  public slidersPawIni: number;
  public modalId: string = generateId();
  public destroy$ = new Subject<boolean>();

  constructor(
    private api: ApiCallService,
    private modalService: ModalService,
    private cropzoneStore: Store<fromCropzones.ICropZones>

  ) { }

  public ngOnInit(): void { }

  public ngOnChanges(changes: SimpleChanges): void {
    this.cropzoneStore.pipe(
      takeUntil(this.destroy$),
      select(selectYpmConstants)
    ).subscribe(constants => {
      this.wueConstants = constants?.wue;
      this.gddConstants = constants?.gdd_pysiological_maturity;
    });
    if (changes.cropManagementData !== undefined && this.wueConstants) {
      if (changes.cropManagementData.currentValue !== changes.cropManagementData.previousValue) {
        const cropType = this.cropManagementData.crop;
        this.actualUnit = this.cropManagementData.yield_unit;
        this.wueMin = this.filterCrop(this.wueConstants['min'], cropType);
        this.wueMax = this.filterCrop(this.wueConstants['max'], cropType);
        this.wueStep = this.wueConstants['wue_slider_step'];
        this.gddMin = this.filterCrop(this.gddConstants['min'], cropType);
        this.gddMax = this.filterCrop(this.gddConstants['max'], cropType);
        this.gddStep = this.gddConstants['gdd_slider_step'];

        if (changes.cropManagementData?.currentValue?.crop !== changes.cropManagementData?.previousValue?.crop) {
          this.wueValue = this.filterCrop(this.wueConstants['default'], cropType);
          this.gddValue = this.filterCrop(this.gddConstants['default'], cropType);
          this.calculatePredictedValues();
        } else if (changes.cropManagementData?.currentValue?.yield_unit !== changes.cropManagementData?.previousValue?.yield_unit) {
          setTimeout(() => {
            this.calculatePredictedValues();
          }, 500);
        } else if (changes.cropManagementData?.currentValue?.station_id !== changes.cropManagementData?.previousValue?.station_id) {
          this.calculatePawIni();
          this.calculatePredictedValues();
        }

        if (this.sliderData !== null) {
          if (changes.sliderData?.currentValue !== changes.sliderData?.previousValue) {
            const wue_step = this.sliderData?.wue_slider_step;
            const gdd_step = this.sliderData?.gdd_step;
            this.wueValue = (wue_step === null || wue_step === 0) ?
                            this.filterCrop(this.wueConstants['default'], cropType) : wue_step;
            this.gddValue = (gdd_step === null || gdd_step === 0) ?
                            this.filterCrop(this.gddConstants['default'], cropType) : gdd_step;
            this.calculatedMaturityDate = moment(this.sliderData?.physiological_maturity_prediction).format('DD. MMMM YYYY');
            this.calculatedYieldAverage = String(this.sliderData?.average_yield) + ' ' + this.actualUnit;
          }
        } else {
          this.wueValue = this.filterCrop(this.wueConstants['default'], cropType);
          this.gddValue = this.filterCrop(this.gddConstants['default'], cropType);
          this.calculatePredictedValues();
        }

        if (changes.cropManagementData?.currentValue?.soil_texture !== changes.cropManagementData?.previousValue?.soil_texture) {
          this.calculatePawIni();
        }
      }
    }
  }

  private filterCrop(constants: any, crop: string): number {
    return constants[crop] ? constants[crop] : 0;
  }

  public calculateAverage(): void {
    this.calculatePredictedValues();
  }

  public isSliderDisabled(): boolean {
    const gddValue = this.gddStep ? false : true;
    return gddValue || this.isDisabled;
  }

  public calculatePredictedValues(): void {
    if (this.gddValue && this.wueValue && this.cropManagementData?.station_id && this.slidersPawIni) {
      this.loading = true;
      const request: IPhysiologicalMaturityRequest = {
        type: 'crop_physiological_maturity',
        sowing_date: this.cropManagementData.sowing_date,
        harvest_date: this.cropManagementData.harvest_date,
        today: dateToUtcUnixTimestamp(this.currentDate),
        crop_species: this.cropManagementData.crop,
        crop_id: this.cropManagementData.crop_id,
        wue_slider_step: Number(this.wueValue),
        gdd_step: Number(this.gddValue),
        paw_ini: Number(this.slidersPawIni)
      };

      this.api.calculatePhysiologicalMaturity(this.cropManagementData.station_id, request).subscribe(response => {
        this.calculatedMaturityDate = moment(response.physiological_maturity_prediction).format('DD. MMMM YYYY');
        this.calculatedYieldAverage = String(response.average_yield) + ' ' + this.actualUnit;

        this.changeSliders.emit({
          wue_slider_step: Number(this.wueValue),
          gdd_step: Number(this.gddValue),
          physiological_maturity_prediction: response.physiological_maturity_prediction,
          average_yield: Number(response.average_yield)
        });

        this.loading = false;
      }, error => {
        this.loading = false;
        this.resetMaturityValues();
      });
    } else {
      this.resetMaturityValues();
    }
  }

  private calculatePawIni(): void {
    if (this.cropManagementData?.station_id && this.cropManagementData?.soil_texture) {
      const fieldWiltingData: IFieldWiltingRequest = {
        type: 'fc_wp',
        year: moment.unix(this.cropManagementData.harvest_date).year(),
        sowing_date: this.cropManagementData.sowing_date,
        tsw_ini_value: 'wet',
        soil_texture: this.cropManagementData.soil_texture,
        cropZoneId: this.cropManagementData.crop_id
      };

      this.api.calculateFieldWilting(this.cropManagementData.station_id, fieldWiltingData).subscribe(response => {
        const fieldCapacity = response.field_capacity ? response.field_capacity : 1;
        const wiltingPoint = response.wilting_point ? response.wilting_point : 1;
        const pawIniRequestpayload: IFieldWiltingRequest = {
          type: 'initial_soil_water',
          year: moment.unix(this.cropManagementData.harvest_date).year(),
          sowing_date: this.cropManagementData.sowing_date,
          tsw_ini_value: 'wet',
          soil_texture: this.cropManagementData.soil_texture,
          fc_sensor: fieldCapacity,
          wp_sensor: wiltingPoint,
        };
        this.api.calculateFieldWilting(this.cropManagementData.station_id, pawIniRequestpayload).subscribe(resp => {
          this.slidersPawIni = resp.paw_ini;
          this.calculatePredictedValues();
        });
      }, error => {
        this.resetMaturityValues();
      });
    }
  }

  public openModal(): void {
    this.modalService.openModal(this.modalId);
  }

  public save(): void {
    this.clickSave.emit();
  }

  public resetMaturityValues(): void {
    this.calculatedMaturityDate = '';
    this.calculatedYieldAverage = '';
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
