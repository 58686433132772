export interface IProductItem {
  key: string;
  pos: string;
  name?: string;
}

export interface IProductLicense {
  type: string;
  product_item: IProductItem;
  start_date: string;
  end_date: string;
  amount: number;
  // Attributes for the majority of features
  nm?: Array<string>;
  // Specific attributes for FarmView
  cropzones?: Array<string>;
  Irrimet?: boolean;
  SoilMoisture?: boolean;
  Satellite?: boolean;
  YieldPrediction?: boolean;
  used?: number;
  remaining?: number;
  license_id?: string;
}

export interface ISelectedLicense {
  productKey: string;
}

export enum LicenseFetchMode {
  ALL = 'all',
  VALID = 'valid',
  AVAILABLE = 'available',
}
