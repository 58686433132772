import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TypeaheadContainerComponent, TypeaheadMatch } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { addFarmField } from '../../../../core/actions/fields';
import { setNotify } from '../../../../core/actions/notify';
import { IFarm } from '../../../../core/models/cropzones';
import * as fromFarms from '../../../../core/reducers/farms';
import * as fromFields from '../../../../core/reducers/fields';
import * as fromNotify from '../../../../core/reducers/notify';
import { IField } from './../../../../core/models/fields';
import { selectFarms, selectFields } from './../../../../core/reducers/index';
import { ModalService } from './../../../../shared/modal/services/modal.service';

@Component({
  selector: 'app-add-field-form',
  templateUrl: './add-field-form.component.html',
  styleUrls: ['./add-field-form.component.scss']
})

export class AddFieldManagementFormComponent implements OnInit, OnDestroy {

  @ViewChild(TypeaheadContainerComponent)

  public form: FormGroup;
  public selectedOption: IFarm = {name: '', id: ''};
  public fieldList: IField[];
  public farmlist: IFarm[];
  private alive$ = new Subject<boolean>();

  @Output()
  private submit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
    private farmStore: Store<fromFarms.IFarms>,
    private fieldStore: Store<fromFields.IFields>,
    private notifyStore: Store<fromNotify.INotifyState>
  ) {

  }

  public get farm(): AbstractControl {
    return this.form.get('farm');
  }

  public get field(): AbstractControl {
    return this.form.get('field');
  }

  public get submitColor(): string {
    return 'green';
  }

  public get submitContent(): string {
    return 'Add field';
  }

  public ngOnInit(): void {

    this.form = this.fb.group({
      'farm': ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      'field': ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]]
    });

    this.fieldStore.pipe(
      select(selectFields),
      takeUntil(this.alive$)
    ).subscribe((fields) => {
      this.fieldList = fields;
    });

    this.farmStore.pipe(
      select(selectFarms),
      takeUntil(this.alive$)
    ).subscribe((farms) => {
      this.farmlist = farms;
    });

  }

  public onSelect(event: TypeaheadMatch): void {
    this.selectedOption = event.item;
  }

  public submitEmit(): void {
    if (!this.farmlist.some((farm) => farm.name === this.form.value.farm)) {
      // new farm
      const object = {
        name: this.form.value.field,
        farm: {name: this.form.value.farm}
      };
      this.fieldStore.dispatch(addFarmField(object));
      this.submit.emit('closeModal');
    } else {
      // existing farm
      const fieldsFromFarm: IField[] = this.fieldList.filter((field) => field.farm.name === this.form.value.farm);
      const farm: IFarm = this.farmlist.filter(farmItem => farmItem.name === this.form.value.farm)[0];

      if (!fieldsFromFarm.some((field) => field.name === this.form.value.field)) {
        const fieldObject = {
          name: this.form.value.field,
          farm: {
            name: this.form.value.farm,
            id: farm.id
          }
        };

        this.submit.emit(fieldObject);
      } else {
        this.notifyStore.dispatch(setNotify('Unable to create the field. This farm already has a field with this name.'));
      }
    }
    this.form.reset();
  }

  public close(): void {
    this.submit.emit('closeModal');
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }
}
