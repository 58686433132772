import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { IOptions } from '../interfaces';
import { deepClone } from '../utils/deepClone';

@Component({
  selector: 'app-custom-simple-lazy-dropdown',
  templateUrl: './custom-simple-lazy-dropdown.component.html',
  styleUrls: ['./custom-simple-lazy-dropdown.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomSimpleLazyDropdownComponent),
    multi: true
  }]
})

export class CustomSimpleLazyDropdownComponent implements ControlValueAccessor, OnChanges, OnDestroy {
  @Input()
  public title                      : string = '';
  @Input()
  public color                      : string = 'blue';
  @Input()
  public width                      : string = 'auto';
  @Input()
  public items$                     : Observable<IOptions[]>;
  @Input()
  public customCSS                  : {[key: string]: any; };
  @Input()
  public customCSS2                  : {[key: string]: any; };
  @Input()
  public shadow                     : boolean = true;
  @Input()
  public errorString?               : string = 'No datasource found';
  @Input()
  public isDisabled                 : boolean = false;
  @Input()
  public placeholder                : string = 'Search..';
  @Input()
  public searchable                 : boolean = true;

  public searchTerm                 : string = '';
  public filteredItems              : IOptions[] = [];
  public items                      : IOptions[] = [];
  public isOpen                     : boolean = false;
  public isLoading                  : boolean = false;

  @Input()
  public currentItem                : any = null;

  @Output()
  public load                       : EventEmitter<undefined> = new EventEmitter<undefined>();

  public buttonString               : string = 'Choose a value';
  private subscription              : Subscription;
  private propagateChange           : any = () => { return; };


  public toggleOpen(): void {
    if (!this.isLoading) {
      if (this.items.length === 0) {
        this.isLoading = true;
        this.load.emit();
      } else {
        this.isOpen = !this.isOpen;
      }
    }
  }

  public close(): void {
    this.isOpen = false;
    this.searchTerm = '';
    this.filteredItems = deepClone(this.items);
  }

  public onChange(item: IOptions): void {
    this.currentItem = item;
    this.close();
    this.propagateChange(item);
  }

  public writeValue(item: IOptions): void {
    this.currentItem = item;
    this.setContent();
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
    this.setContent();
  }

  public registerOnTouched(fn: any): void {
    return;
  }

  public setDisabledState(isDisabled: boolean): void {
    return;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['items$']) {
      if (changes['items$'].currentValue) {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
        this.subscription = this.items$.subscribe(items =>  {
          if (items.length === 0) {
            this.buttonString = this.errorString;
          } else {
            this.buttonString = 'Choose a value';
          }

          this.items = items;
          this.filteredItems = deepClone(items);
          if (this.isLoading) {
            this.isOpen = items.length > 0;
          }
          this.isLoading = false;
          this.setContent();
        });
      }
    }
  }

  public filterItems(): void {
    if (this.searchTerm === '') {
      this.filteredItems = deepClone(this.items);
    } else {
      this.filteredItems = deepClone(this.items)
      .filter((item: IOptions) => item.value.toLowerCase().includes(this.searchTerm.toLowerCase()));
    }
  }

  public stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  public resetDropdown(): void {
    this.searchTerm = '';
    this.filteredItems = deepClone(this.items);
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  protected setContent(): void {
    if (!this.items || this.items.length < 1) {
      return;
    }
    if (this.currentItem) {
      this.propagateChange(this.currentItem);
    }
  }
}
