import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { IHeaderData } from '../../models/models';

@Component({
  selector: 'app-weather-forecast-history-climate-header',
  templateUrl: './weather-forecast-history-climate-header.component.html',
  styleUrls: ['./weather-forecast-history-climate-header.component.scss']
})
export class WeatherForecastHistoryClimateHeaderComponent implements OnInit {

  @Input()
  public header: IHeaderData;
  public modalId: string = generateId();

  constructor(private modal: ModalService) { }

  public ngOnInit(): void { }

  public openModal(): void {
    this.modal.openModal(this.modalId);
  }

}
