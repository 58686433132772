import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-account-settings-form',
  templateUrl: './account-settings-form.component.html',
  styleUrls: ['./account-settings-form.component.scss']
})
export class AccountSettingsFormComponent implements OnInit {
  @Input()
  private username               : string;
  @Output()
  public changePassword          : EventEmitter<string> = new EventEmitter();

  public form                    : FormGroup;

  constructor(private formBuilder: FormBuilder) { }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      'user': [this.username, []],
      'password': ['', [Validators.required, Validators.minLength(5)]],
      'password2': ['', [Validators.required, Validators.minLength(5)]]
    });
  }

  public get password(): AbstractControl {
    return this.form.get('password');
  }

  public get password2(): AbstractControl {
    return this.form.get('password2');
  }

  public submit(): void {
    if (this.form.invalid || this.password.value !== this.password2.value) {
      return;
    }
    this.changePassword.emit(this.password.value);
  }

}
