import { Component, HostListener, OnDestroy, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Action, select, Store } from '@ngrx/store';
import { DragulaService } from 'ng2-dragula';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environmentToken } from '../../../environments/environment';
import { IEnvironment } from '../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../environments/interfaces/theme';
import { closeDashboardText, getUsersClosedTexts, saveUsersClosedTexts } from '../../core/actions/account';
import { ICropZone } from '../../core/models/cropzones';
import { IStation } from '../../core/models/stations';
import { selectCustomizationsFromAccount, selectStations, selectTerms } from '../../core/reducers';
import * as fromAccount from '../../core/reducers/account';
import * as fromCropzones from '../../core/reducers/cropzones';
import * as fromStations from '../../core/reducers/stations';
import { ApiCallService } from '../../services/api/api-call.service';
import { ADD_CROPZONE, ADD_REMOVE_MODAL_ID } from '../../shared/constants';
import { ModalService } from '../../shared/modal/services/modal.service';
import { deepClone } from '../../shared/utils/deepClone';
import { selectClosedDashboardTexts, selectCropzones } from './../../core/reducers/index';
import {
  addNewColumns,
  changeEditMode,
  changeSubdomain,
  getDashboardList,
  reloadDashboard,
  resetDashboard,
  restoreDashboard,
  saveDashboard,
  saveDefault,
  setDashboardChanged,
  setDashboardSettings
} from './actions/dashboard';
import { IColumn, IDashboardText } from './models/dashboard.models';
import { selectChanged, selectDashboard, selectEdit, selectMain } from './reducers';
import * as fromDashboard from './reducers/dashboard';
import { prepareDashboard } from './utils/prepareDashboard';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss',
              '../../shared/common.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  public terms$             : Observable<boolean>;
  public isEdit$            : Observable<boolean>;
  public modalId            : string = 'dashboard-leave-confirm';
  public dashboard          : Array<Array<IColumn>> = [];
  public dashboardTexts     : Array<IDashboardText> = [];
  public nextUrl            : string = '';
  public farmView           : boolean;
  private destroy$          : Subject<boolean> = new Subject<boolean>();
  private deactivate$       : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private stations          : Array<IStation>;
  private cropzones         : Array<ICropZone>;
  private isChanged         : boolean = false;
  private isMain            : boolean;
  private closedTexts       : Array<number>;
  public subDomain          : IThemeConfig;

  public editMode(): void {
    this.dashboardStore.dispatch(changeEditMode());
  }

  @HostListener('window:beforeunload', ['$event'])
  public leaveListener(event: any): void {
    if (this.isChanged) {
      event.stopImmediatePropagation();
    }
  }

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    private dragulaService: DragulaService,
    private api: ApiCallService,
    private modalService: ModalService,
    private router: Router,
    private accountStore: Store<fromAccount.IAccount>,
    private stationsStore: Store<fromStations.IStations>,
    private cropzoneStore: Store<fromCropzones.ICropZones>,
    private dashboardStore: Store<fromDashboard.IDashboard>
  ) {
    this.farmView = environment.displayRules.farmViewMenu;
    this.subDomain = environment.theme;

    // get the users closed text and the dashboard texts
    combineLatest([
      this.accountStore.pipe(
        select(selectClosedDashboardTexts)
      ),
      this.api.getDashboardTexts()
    ]).subscribe(([usersClosedTexts, dashboardTexts]) => {
      this.closedTexts = usersClosedTexts;
      this.dashboardTexts = [];

      if (dashboardTexts) {
        dashboardTexts.forEach(dashboardText => {
          this.dashboardTexts.push({
            id: dashboardText.id,
            text: dashboardText.text.toString().split('http', 2)[0],
            link: this.detectURLs(dashboardText.text) ? this.detectURLs(dashboardText.text)[0] : null,
            icon: dashboardText.icon
          });
        });
      }

      this.getClosedTexts(usersClosedTexts);
    });
  }

  public ngOnInit(): void {
    this.initDragula();
    this.initDashboardSubscription();
    this.initAccountSubscription();
    this.initIsEditSubscription();
    this.initIsChangedSubscription();
    this.initTerms();

    this.accountStore.pipe(
      select(selectClosedDashboardTexts),
      takeUntil(this.destroy$)
    ).subscribe((texts: Array<number>) => {
      this.getClosedTexts(texts);
    });
  }

  private getClosedTexts(closedTexts): void {
    if (closedTexts) {
      this.closedTexts = closedTexts;
      if (closedTexts.length) {                                  // if user already closed any texts
        this.accountStore.dispatch(saveUsersClosedTexts(this.closedTexts));
        this.checkIfClosed();                                    // check if closed
      } else {
        this.showAllTexts();
      }
    } else {                                                     // if the list is not set and the list is not in the database
      this.dashboardStore.dispatch(getUsersClosedTexts());
    }
  }

  public closeDivClick(index: number): void {
    const id = this.dashboardTexts[index].id;
    this.accountStore.dispatch(closeDashboardText(id));
  }

  private checkIfClosed(): void {
    this.dashboardTexts.forEach((text, i) => {
      if (this.closedTexts.includes(text.id)) {
        this.dashboardTexts[i].show = false;
      } else {
        this.dashboardTexts[i].show = true;
      }
    });
  }

  private showAllTexts(): void {
    for (let i = 0; i < this.dashboardTexts.length; i++) {
      this.dashboardTexts[i].show = true;
    }
  }

  public deactivate(): Observable<boolean> {
    // Hide leave page for dashboard changes, users will save settings.
    // this.deactivate$.next(!this.isChanged);
    // if (this.isChanged) {
    //   this.openLeaveModal();
    // }
    return this.deactivate$.asObservable();
  }

  public leave(): void {
    this.dashboardStore.dispatch(setDashboardChanged(false));
    this.dashboardStore.dispatch(setDashboardSettings([]));
    this.dashboardStore.dispatch(reloadDashboard({}));
    this.router.navigate([this.nextUrl]);
    this.closeLeaveModal();
  }

  public get showAddRemove(): boolean {
    return this.stations !== undefined && !this.stations.length;
  }

  public get showAddCropzones(): boolean {
    return this.cropzones !== undefined && !this.cropzones.length;
  }

  public openAddRemoveModal(): void {
    this.modalService.openModal(ADD_REMOVE_MODAL_ID);
  }

  public openAddCropzoneModal(): void {
    this.modalService.openModal(ADD_CROPZONE);
  }

  public openLeaveModal(): void {
    this.modalService.openModal(this.modalId);
  }

  public closeLeaveModal(): void {
    this.modalService.closeModal(this.modalId);
  }

  public restore(): void {
    this.dashboardStore.dispatch(restoreDashboard());
  }

  public save(): void {
    const dashboard = prepareDashboard(
      this.dashboard, ['loadData', 'isLoading', 'error']
    );

    let action: Action;

    if (this.isMain) {
      action = saveDefault();
    } else {
      action = saveDashboard(dashboard);
    }

    this.dashboardStore.dispatch(action);
  }

  public setDefault(): void {
    if (this.stations && this.stations.length) {
      this.dashboardStore.dispatch(resetDashboard());
    }
  }

  public addColumns(count: number): void {
    if (!this.stations || !this.stations.length) {
      return;
    }
    const row: Array<IColumn> = [];
    for (let i = 0; i < count; i++) {
      row.push(this.generateColumn());
    }
    this.dashboardStore.dispatch(addNewColumns(row));
    this.dashboardStore.dispatch(setDashboardChanged(true));
  }

  private generateColumn(): IColumn {
    return {
      items: [],
      width: 6
    };
  }

  private initTerms(): void {
    this.terms$ = this.accountStore.pipe(
      select(selectTerms)
    );
  }

  private initDashboardSubscription(): void {
    this.dashboardStore.pipe(
      select(selectDashboard),
      takeUntil(this.destroy$),
      filter((dashboard) => !!dashboard)
    ).subscribe((dashboard: Array<Array<IColumn>>) => {
      this.dashboard = dashboard;
    });

    this.accountStore.pipe(
      select(selectMain),
      takeUntil(this.destroy$)
    ).subscribe(isMain =>
      this.isMain = isMain
    );

    this.dashboardStore.dispatch(getDashboardList());
    this.dashboardStore.dispatch(changeSubdomain(this.subDomain.subDomain));
  }

  private initAccountSubscription(): void {
    combineLatest([
      this.accountStore.pipe(
        select(selectCustomizationsFromAccount)
      ),
      this.stationsStore.pipe(
        select(selectStations)
      ),
      this.cropzoneStore.pipe(
        select(selectCropzones)
      )
    ]).pipe(
      takeUntil(this.destroy$),
      filter(([customizations, stations, cropzones]) => Array.isArray(stations) && Array.isArray(cropzones))
    ).subscribe(([customizations, stations, cropzones]) => {
      this.stations = stations;
      this.cropzones = cropzones;
      if (customizations && !this.dashboard.length) {
        this.dashboardStore.dispatch(setDashboardSettings(
          deepClone(
            prepareDashboard(
              customizations.dashboard,
              ['loadData']
            )
          )
        ));
        this.dashboardStore.dispatch(reloadDashboard({ main: true }));
      }
      if (!customizations && !this.dashboard.length) {
        this.setDefault();
      }
    });
  }

  private initDragula(): void {
    this.dragulaService.setOptions('first-bag', {
      moves: function (el: any, container: any, handle: any): boolean {
        return handle.className === 'zmdi zmdi-arrows handle';
      }
    });
  }

  private initIsEditSubscription(): void {
    this.isEdit$ = this.dashboardStore.pipe(
      takeUntil(this.destroy$),
      select(selectEdit)
    );
  }

  private initIsChangedSubscription(): void {
    this.dashboardStore.pipe(
      takeUntil(this.destroy$),
      select(selectChanged)
    ).subscribe((isChanged) => this.isChanged = isChanged);
  }

  public detectURLs(text: string): string[] {
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return text.toString().match(urlRegex);
  }

  public ngOnDestroy(): void {
    this.dashboardStore.dispatch(setDashboardChanged(false));
    this.dashboardStore.dispatch(setDashboardSettings([]));
    this.dashboardStore.dispatch(reloadDashboard({}));
    this.deactivate$.next(true);
    this.deactivate$.complete();
    this.destroy$.next(true);
    this.destroy$.complete();
    this.dragulaService.destroy('first-bag');
  }

}
