<h3>
  {{ nestedLinkTree.name | translate }}
</h3>
<ul class="nested-link-list">
  <ng-container *ngFor="let subNode of nestedLinkTree.subNodes">
    <li class="first-level" *ngIf="subNode.nodes?.length; else link">
      <a (click)="openNode(subNode)">
        <i *ngIf="subNode.iconClass" class="zmdi {{ subNode.iconClass }}"></i>
        <i [class.fa-caret-right]="!isOpenNode(subNode)"
        [class.fa-caret-down]="isOpenNode(subNode)"
        [class.move-caret-right]="subNode.iconClass"
        [class.move-caret-left]="!subNode.iconClass"
        class="fa">
        </i>
        {{ subNode.name | translate }}
      </a>
      <ul [hidden]="!isOpenNode(subNode)" class="second-level">
        <ng-container *ngFor="let node of subNode.nodes">
          <li (click)="onNodeClicked(node, subNode)" *ngIf="!subNode.link; else nestedlink">
            <a [class.active]="isSelectedGroup(node, subNode)">{{ node.name | translate }}</a>
          </li>
          <ng-template #nestedlink>
            <li class="nestedlink">
              <a (click)="onNodeClicked(node, subNode)" routerLinkActive="active"
                [routerLink]="['/station/' + (selectedStationId$ | async) + subNode.link + '/' + node.id]"
                [routerLinkActiveOptions]="{ exact: true, __change_detection_hack__: (selectedStationId$ | async) }">
                <i class="zmdi {{ node.iconClass }}"></i>
                {{ node.name | translate }}
              </a>
            </li>
          </ng-template>
        </ng-container>
      </ul>
    </li>
    <ng-template #link>
      <li class="link">
        <a (click)="onNodeClicked(subNode, subNode)" routerLinkActive="active" [routerLink]="['/station/' + (selectedStationId$ | async) + subNode.link]"
          [routerLinkActiveOptions]="{ exact: true, __change_detection_hack__: (selectedStationId$ | async) }">
          <i class="zmdi {{ subNode.iconClass }}"></i>
          {{ subNode.name | translate }}
        </a>
      </li>
    </ng-template>
  </ng-container>
</ul>

