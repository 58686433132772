<div class="form-input" [class.small]="isSmall" [ngStyle]="{'background-color': isDisabled ? 'rgb(230, 230, 230)' : '' }">

  <div class="form-input__container" *ngIf="isOpen">
    <div class="form-input__timepicker" *ngIf="!hideTime">
      <timepicker [(ngModel)]="time"
                  [hourStep]="hourStep"
                  [minuteStep]="minuteStep"
                  [secondsStep]="secondsStep"
                  [showMeridian]="false">
      </timepicker>
    </div>
  </div>

  <span class="input-icon">
    <i class="zmdi zmdi-calendar-close"></i>
  </span>

  <input [class.only-focus]="isOnlyFocus"
         tabindex="-1"
         class="form-input__hide"
         #dp="bsDatepicker"
         bsDatepicker
         [(ngModel)]="date"
         (onShown)="open()"
         (onHidden)="close()"
         [minDate]="minDate"
         [maxDate]="maxDate"
         [bsConfig]="{
          containerClass: 'theme-dark-blue',
          dateInputFormat: dateInputFormat
         }">

  <div (click)="!isDisabled && dp.toggle()" class="form-input__visible">
    <input [class.only-focus]="isOnlyFocus"
           [disabled]="true"
           [value]="displayed">
    <div class="form-input__visible__open"></div>
  </div>

  <div>
  </div>
</div>

