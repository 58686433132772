import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-widget-add-buttons',
  templateUrl: './widget-add-buttons.component.html',
  styleUrls: ['./widget-add-buttons.component.scss']
})
export class WidgetAddButtonsComponent {
  @Output()
  public close                 : EventEmitter<void> = new EventEmitter();
  @Output()
  public add                   : EventEmitter<void> = new EventEmitter();

}
