import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ICam, IStation } from '../../../../core/models/stations';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { deepClone } from '../../../../shared/utils/deepClone';
import { generateId } from '../../../dashboard/utils/makeWidget';
import {
  getCameraImage,
  offCameraSettingsMessage,
  saveCamSettings,
  saveCameraLEDFLash,
  setCameraSettings,
  toggleCameraSettingsInvalid
} from '../../actions/cameraSettings';
import { ICamSettings, ISaveCams, ISaveCamsPayload } from '../../models/station-config.models';
import { selectCameraSettings } from '../../reducers';
import * as fromCameraSettings from '../../reducers/cameraSettings';
import { ICameraSettingsState } from '../../reducers/cameraSettings';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { IOptions } from '../../../../shared/interfaces';
import { ledFlashOptions } from '../../constants/constants';
import { ApiCallService } from '../../../../services/api/api-call.service';

@Component({
  selector: 'app-camera-settings',
  templateUrl: './camera-settings.component.html',
  styleUrls: ['./camera-settings.component.scss']
})
export class CameraSettingsComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public station          : IStation;

  public form             : FormGroup;
  public ledFlashOptions  : IOptions[] = ledFlashOptions;
  public cameraSettings   : ICameraSettingsState;
  public modalId          : string = generateId();

  private destroy$        : Subject<boolean> = new Subject<boolean>();
  private currentStation  : string;

  constructor(private store: Store<fromCameraSettings.ICameraSettingsState>,
              private modalService: ModalService,
              private fb: FormBuilder,
              private api: ApiCallService) { }

  public get ledFlashControl(): AbstractControl {
    return this.form.get('ledFlash');
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      ledFlash: [this.station.config.flash ? this.station.config.flash : 0, []]
    });

    this.store.pipe(
      takeUntil(this.destroy$),
      select(selectCameraSettings),
      distinctUntilChanged()
    ).subscribe((c: ICameraSettingsState) => {
      this.cameraSettings = c;
    });
  }

  public openModal(): void {
    this.modalService.openModal(this.modalId);
  }

  public getCamKeys(): string[] {
    // Must be not null to be considered as a valid cam
    return Object.keys(this.cameraSettings)
      .filter((k: string) => k.includes('cam'))
      .filter((k: string) => !!this.cameraSettings[k]);
  }

  public ngOnChanges(): void {
    if (this.station.name.original === this.currentStation) {
      return;
    }
    this.currentStation = this.station.name.original;

    const config = this.station.config;
    if (this.form) {
      this.ledFlashControl.setValue(config.flash ? config.flash : 0);
    }

    const state: ICameraSettingsState = {
      cam1: {
        img: '',
        settings: this.station.config.cam1,
        spots: this.prepareSpots(this.station.config.cam1.square_spots)
      }
    };
    this.store.dispatch(getCameraImage({
      camKey: 'cam1',
      camId: '1',
      stationId: this.station.name.original
    }));
    if (this.station.config.cam2) {
      state.cam2 = {
        img: '',
        settings: this.station.config.cam2,
        spots: this.prepareSpots(this.station.config.cam2.square_spots)
      };
      this.store.dispatch(getCameraImage({
        camKey: 'cam2',
        camId: '2',
        stationId: this.station.name.original
      }));
    }
    this.store.dispatch(setCameraSettings(state));
  }

  public save(): void {
    if (this.checkSquareValid(this.cameraSettings.cam1.spots)) {
      this.dispatchInvalidSettings();
      return;
    }
    if (this.cameraSettings.cam2 && this.checkSquareValid(this.cameraSettings.cam2.spots)) {
      this.dispatchInvalidSettings();
      return;
    }

    this.station.config.flash = +this.ledFlashControl.value;
    let s: IStation = deepClone(this.station);
    s = this.prepareStation(s, this.cameraSettings.cam1, 'cam1');
    if (s.config.cam2) {
      s = this.prepareStation(s, this.cameraSettings.cam2, 'cam2');
    }
    const save: ISaveCamsPayload = {
      station: s,
      cams: {
        ...this.prepareCam(this.cameraSettings.cam1.settings, 'cam1', this.cameraSettings.cam1.spots)
      }
    };
    if (this.cameraSettings.cam2) {
      save.cams = {
        ...save.cams,
        ...this.prepareCam(this.cameraSettings.cam2.settings, 'cam2', this.cameraSettings.cam2.spots),
      };
    }

    this.store.dispatch(saveCamSettings(save));
  }

  private checkSquareValid(spots: string[]): boolean {
    return !spots.includes('1');
  }

  private dispatchInvalidSettings(): void {
    this.store.dispatch(toggleCameraSettingsInvalid(true));
    this.store.dispatch(offCameraSettingsMessage());
  }

  private prepareStation(station: IStation, cam: ICamSettings, name: string): IStation {
    station.config[name].square_spots = this.prepareSpotsToNumber(cam.spots);
    station.config[name].global_gain = cam.settings.global_gain;
    station.config[name].integration_time = cam.settings.integration_time;
    station.config[name].brightness_ref = cam.settings.brightness_ref;
    station.config[name].active = cam.settings.active;
    station.config[name].auto_exposure = cam.settings.auto_exposure;
    station.config[name].max_integration_time = cam.settings.max_integration_time;
    return station;
  }

  private prepareCam(cam: ICam, name: string, spots: string[]): ISaveCams {
    const saveCams: ISaveCams = {
      [`config.${name}.active`]: +cam.active,
      [`config.${name}.auto_exposure`]: +cam.auto_exposure,
      [`config.${name}.brightness_ref`]: +cam.brightness_ref,
      [`config.${name}.global_gain`]: +cam.global_gain,
      [`config.${name}.integration_time`]: +cam.integration_time,
      [`config.${name}.max_integration_time`]: +cam.max_integration_time,
      [`config.${name}.square_spots`]: this.prepareSpotsToNumber(spots),
      ['config.flash']: +this.ledFlashControl.value
    };
    return saveCams;
  }

  private prepareSpotsToNumber(spots: string[]): number {
    return parseInt(spots.slice().reverse().join(''), 2);
  }

  private prepareSpots(spots: number): string[] {
    const preparedSpots: string[] = spots.toString(2).split('');
    const diff: number = 8 - preparedSpots.length;
    for (let i = 0; i < diff; i++) {
      preparedSpots.unshift('0');
    }
    return preparedSpots.reverse();
  }


  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
