import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { deleteServerAlert, updateServerAlert } from '../../actions/server-alerts';
import { aggrTypeInv, alertShortTypes, channelOptions, resendOptions } from '../../constants/server-alerts';
import { IServerAlert } from '../../interfaces/server-alert';
import * as fromServerAlerts from '../../reducers/server-alerts';
import { ServerAlertsService } from '../../services/server-alerts.service';

@Component({
  selector: '[app-server-alerts-row]',
  templateUrl: './server-alerts-row.component.html',
  styleUrls: ['./server-alerts-row.component.scss']
})
export class ServerAlertsRowComponent implements OnInit, OnDestroy {

  @Input()
  public alert: IServerAlert;
  @Input()
  public station: string;
  @Input()
  public sensorData?: any;

  public channelOptions = channelOptions;
  public alertTypes = alertShortTypes;
  public resendOptions = resendOptions;
  public aggrType = aggrTypeInv;
  public alertForm: FormGroup;
  public emailTargetActive: boolean;
  public mobileTargetActive: boolean;
  public tooltipText: string;
  private destroy$ = new Subject<boolean>();

  public readonly formatError = 'Format issue - please enter the right format (1, -1.2, 3.45)';

  constructor(
    private formBuilder: FormBuilder,
    protected alertsService: ServerAlertsService,
    protected serverAlertsStore: Store<fromServerAlerts.IServerAlertsState>,
    private translationService: TranslateService
  ) {
      Object.keys(this.channelOptions).forEach(key => {
        this.translationService.get(this.channelOptions[key])
          .subscribe(translatedTarget => {
            this.channelOptions[key] = translatedTarget;
          });
      });
    }

  public get threshold(): AbstractControl {
    return this.alertForm.get('threshold');
  }

  public ngOnInit(): void {
    if (this.alert && this.alert.aggr) {
      if (this.alert.targets.some((target) => target.method === 'email')) {
        this.emailTargetActive = true;
        const emailTarget = this.alert.targets.filter((target) => target.method === 'email')[0];
        // tslint:disable-next-line:max-line-length
        this.tooltipText = emailTarget.destination !== 'true' && emailTarget.destination !== 'false' && emailTarget.destination !== '' ? emailTarget.destination : null;
      }
      this.mobileTargetActive = this.alert.targets.some((target) => target.method === 'push_mobile');
      this.alertForm = this.formBuilder.group({
        type: this.alert.type,
        threshold: [this.alert.thresholds.join(', '), this.alertsService.checkThreshold()],
        resend: this.alert.resendPeriod,
        active: this.alert.active || false,
        source: this.alert.source || ''
      });
    }
  }

  public updateAlert(): void {
    if (this.alertForm.valid && this.station) {

      let invalid = true;

      const { threshold, resend, ...data } = this.alertForm.value;
      const thresholds = threshold.split(',').map(Number);
      const resendPeriod = Number(resend);

      if (thresholds && thresholds.length) {
        invalid = thresholds.some(_ => _ !== _); // check once again for NaN values
      }

      if (!invalid) {
        const alert = { ...this.alert, ...data, thresholds, resendPeriod };

        this.serverAlertsStore.dispatch(updateServerAlert({
          id: this.station,
          alert: alert
        }));
      } else {
        this.threshold.setErrors({ incorrect: true });
      }
    }
  }

  public removeAlert(): void {

    const id = this.station;
    const alert = this.alert;

    if (alert && alert._id) {
      this.serverAlertsStore.dispatch(deleteServerAlert({ id, alert }));
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
