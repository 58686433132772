import { Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Chart } from 'angular-highcharts';
import { Options } from 'highcharts';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { selectUserData } from '../../../../core/reducers';
import { IAccount } from '../../../../core/reducers/account';
import * as fromSelectedCropzone from '../../../../core/reducers/selectedCropZone';
import { yieldCropColorTypes, yieldUnitTypes } from '../../../yield-prediction/constants/config';
import { IPreviousYieldValues } from '../../models/model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-previous-yield-graph',
  templateUrl: './previous-yield-graph.component.html',
  styleUrls: ['./previous-yield-graph.component.scss']
})
export class PreviousYieldGraphComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public chartData: any = null;
  @Input()
  public yieldUnit: any = null;
  public chart: Chart = null;
  public chartOptions: Options;
  private alive$: Subject<boolean> = new Subject<boolean>();
  private translatedValues: IPreviousYieldValues;
  public currentDate: any = new Date();
  public unitSystem: string;
  public actualUnit: string = '';
  public yieldsRecorded = [];
  public forecastsRecorded = [];
  public categoryYears = [];
  public yieldSource: any;

  constructor(
    private userStore: Store<IAccount>,
    private route: ActivatedRoute
  ) { }

  public ngOnInit(): void {
    this.translatedValues = this.route?.snapshot?.data['previousYieldResolver'];

    this.userStore.pipe(
      select(selectUserData),
      takeUntil(this.alive$),
      filter((user: IAccount) => !!user)
    ).subscribe((user: IAccount) => {
      if (user.settings !== null) {
        this.unitSystem = user.settings.unit_system;
        this.unitSystem === 'metric' ? this.actualUnit = 't/ha' : this.actualUnit = 'bu/ac';
        this.chartOptions = {
          title: {
            text: ''
          },
          credits: {
            enabled: false
          },
          exporting: {
            enabled: false
          },
          xAxis: {
            type: 'datetime',
          },
          legend: {},
          plotOptions: {
            series: {
              marker: {
                enabled: true
              },
            }
          },
        };
        this.chart = new Chart(this.chartOptions);
      }
      this.updateChartData();
    });
  }

  public ngOnChanges(): void {
    this.resetChart();
    this.updateChartData();
  }

  public updateChartData(): void {
    if (this.chartData) {
      if (yieldUnitTypes(this.yieldUnit) !== null) {
        this.unitSystem = yieldUnitTypes(this.yieldUnit);
        this.actualUnit = this.yieldUnit;
      }
      if (this.chartData.crop) {
        const cropData = [];
        for (const [key, value] of Object.entries(this.chartData.crop)) {
          cropData.push({ name: key, value: value });
        }
        cropData.map((item) => {
          if (item.value) {
            const years = [];
            item.value[1].map(unix => {
              const date = new Date(unix * 1000);
              years.push(date.getFullYear());
              this.categoryYears.push(date.getFullYear());
            });
            const yieldRecorded = this.formatYields(years, item.value[0]);
            this.yieldsRecorded.push({ cropName: item.name, value: yieldRecorded });
          }
        });
      }

      if (this.chartData.forecast) {
        const forecastData = [];
        for (const [key, value] of Object.entries(this.chartData.forecast)) {
          forecastData.push({ name: key, value: value });
        }
        forecastData.map((item) => {
          if (item.value) {
            const years = [];
            item.value[1].map(unix => {
              const date = new Date(unix * 1000);
              years.push(date.getFullYear());
              this.categoryYears.push(date.getFullYear());
            });
            const forecastRecord = this.formatForecasts(years, item.value[0]);
            this.forecastsRecorded.push({ cropName: item.name, value: forecastRecord });
          }
        });
      }

      const seriesData = [];
      this.yieldsRecorded.map(yieldItem => {
        const seriesDataArray = [];
        yieldItem.value.map(dataItem => {
          seriesDataArray.push({name: dataItem[0], y: dataItem[1]});
        });

        const seriesItem = {
          name: this.capitalizeFirstLetter(yieldItem.cropName) + ' ' + this.translatedValues['measured'],
          data: seriesDataArray,
          zIndex: 1,
          type: 'column',
          color: '#' + yieldCropColorTypes(yieldItem.cropName)
        };
        seriesData.push(seriesItem);
      });

      this.forecastsRecorded.map(item => {
        const forecastItem = {
          name: this.capitalizeFirstLetter(item.cropName) + ' ' + this.translatedValues['forecastAvg'],
          id: this.capitalizeFirstLetter(item.cropName) + ' ' + this.translatedValues['forecastAvg'],
          data: [{
            name: item.value[0][0],
            y: item.value[0][1],
          }],
          zIndex: 0,
          type: 'column',
          color: '#' + yieldCropColorTypes(item.cropName) + '66'
        };
        seriesData.push(forecastItem);

        const forecastRangeItem = {
          name: this.capitalizeFirstLetter(item.cropName) + ' ' + this.translatedValues['forecastRange'],
          type: 'errorbar',
          linkedTo: this.capitalizeFirstLetter(item.cropName) + ' ' + this.translatedValues['forecastAvg'],
          data: [{
            name: item.value[0][0],
            low: item.value[0][2],
            high: item.value[0][3]
          }],
          color: '#000'
        };
        seriesData.push(forecastRangeItem);
      });

      this.chartOptions = {
        ...this.chartOptions,
        xAxis: {
          type: 'category',
          categories: Array.from(new Set(this.categoryYears)).sort((a, b) => a - b)
        },
        yAxis: {
          title: {
            text: '<b>' + this.translatedValues['yield'] + ' [' + this.actualUnit + ']</b>'
          },
          min: 0,
        },
        tooltip: {
          crosshairs: true,
          shared: true,
          valueSuffix: ' ' + this.actualUnit
        },
        series: seriesData
      },
      this.chart = new Chart(this.chartOptions);
    }
  }

  public formatYields(array1, array2): any {
    const result = [];
    array1.map((item, index) => {
      result.push([item, array2[index]]);
    });
    return result;
  }

  public formatForecasts(array1, array2): any {
    const result = [];
    array1.map((item1) => {
      array2.map((item2) => {
        // year, avg, min, max
        result.push([item1, Number(Number((item2[0] + item2[1]) / 2).toFixed(2)), item2[0], item2[1]]);
      });
    });
    return result;
  }

  private capitalizeFirstLetter(string: String): String {
    return this.translatedValues[string[0].toUpperCase() + string.slice(1)];
  }

  public resetChart(): void {
    this.yieldsRecorded = [];
    this.forecastsRecorded = [];
    this.categoryYears = [];
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }

}
