import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { ISensor } from '../../../shared/interfaces';

export enum ActionTypes {
  GET_STATION_SENSORS = '[SERVER_ALERTS] GET_STATION_SENSORS',
  SET_STATION_SENSORS = '[SERVER_ALERTS] SET_STATION_SENSORS',
}

export function getStationSensors(payload: string): IAction {
  return { type: ActionTypes.GET_STATION_SENSORS, payload };
}

export function setStationSensors(payload: ISensor[]): IAction {
  return { type: ActionTypes.SET_STATION_SENSORS, payload };
}
