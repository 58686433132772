<div class="grid-container" *ngIf="data; else loading">
  <h5>{{ 'Station name' | translate }}: {{station}}</h5>
  <h5 *ngIf="stationName">{{ 'Custom name' | translate }}: {{stationName}}</h5>
  <ag-grid-angular class="ag-theme-bootstrap"
                   [gridOptions]="dataGridOptions">
  </ag-grid-angular>

</div>

<ng-template #loading>
  <app-widget-info>
    <h4><i class="fa fa-warning fa-fw"></i> {{ 'No data available' | translate}}</h4>
  </app-widget-info>
</ng-template>

