<app-animal-production-warnings></app-animal-production-warnings>
<app-animal-production-header
  [header]="header">
</app-animal-production-header>

<app-form-layout [shadow]="false" *ngIf="validLicense">
  <div class="animal-production-content__item">
    <app-animal-production-charts
      [isLoading]="isLoading$ | async"
      [isError]="isError$ | async"
      [chartsAndLegends$]="chartsAndLegends$">
    </app-animal-production-charts>
  </div>
</app-form-layout>
