import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { treeAnimations } from '../../../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../../../core/services/left-components-toggler/left-components-toggler.service';
import { setActiveDevice, setActiveSample } from '../../actions/crozone-settings.action';
import { ICropzoneSampleState, selectDeviceType, selectSamples } from '../../reducers';

@Component({
  selector: 'app-sample-data-tree',
  templateUrl: './sample-data-tree.component.html',
  styleUrls: ['./sample-data-tree.component.scss'],
  animations: treeAnimations
})
export class SampleDataTreeComponent implements OnInit, OnDestroy {

  public stationOrCropzone: string;
  public name: string;
  public devices: any;
  public activeDevice: string;
  public alive$ = new Subject<void>();
  public deviceType;

  @HostBinding('@placeTree')
  public state: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cropzoneSampleStore: Store<ICropzoneSampleState>,
    private leftComponentsToggler: LeftComponentsTogglerService,
  ) {

    this.leftComponentsToggler
      .getTreeState()
      .subscribe((treeState: string) => this.state = treeState);
  }

  public ngOnInit(): void {
    this.stationOrCropzone = this.activatedRoute.parent.parent.routeConfig.path;

    combineLatest([
      this.cropzoneSampleStore.pipe(select(selectDeviceType)),
      this.cropzoneSampleStore.pipe(select(selectSamples))
    ]).pipe(
      takeUntil(this.alive$),
      filter(data => Boolean(data[1]))
    ).subscribe(([deviceType, tables]) =>  {
      this.deviceType = deviceType;
      this.devices = tables;
    });
  }

  public selectDevice(deviceID: any): void {
    this.activeDevice = deviceID;
    this.cropzoneSampleStore.dispatch(setActiveDevice(deviceID));
    this.cropzoneSampleStore.dispatch(setActiveSample(null));
  }

  public ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }
}
