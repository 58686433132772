import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromDisease from '../reducers/station-disease';
import {addStationDiseaseWarning} from '../actions/station-disease';
import {IDiseaseLicenseItem, IDiseaseModelsLicenses, IDiseaseWarning} from '../models/station-disease.models';
import * as moment from 'moment';
import {IStationSettingsPeriod} from '../../../shared/interfaces';
import {Subject} from 'rxjs';
import {Observable} from 'rxjs';
import {Moment} from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Injectable()
export class StationDiseaseHelpService {
  private pleaseNoteString: string;
  private daysToRenew: string;
  private licenseExpiredOn: string;
  private toRenew: string;

  private openFireblight$: Subject<boolean> = new Subject<boolean>();
  private dateFormat: string = 'YYYY-MM-DD';

  constructor(
    private diseaseStore: Store<fromDisease.IStationDiseaseState>,
    private translate: TranslateService
    ) {
      this.getTranslatedStrings();
    }

  public openFireblight(): void {
    this.openFireblight$.next(true);
  }

  public getOpenFireblight(): Observable<boolean> {
    return this.openFireblight$.asObservable();
  }

  public setSensorWarning(res: any): void {
    if (res['messages']) {
      this.addWarning({
        type: 'info',
        message: res['messages'][0]
      });
    }
  }

  public setLicenseWarnings(models: IDiseaseModelsLicenses = {}, period: IStationSettingsPeriod, diseaseGroup: string|number): void {
    if (models.hasOwnProperty(diseaseGroup) && period.fromTo.from && period.fromDatepicker) {

      const dataStartDate: Date = period.isLastDataMode ? period.fromTo.from : period.fromDatepicker;
      const dataEndDate: Date = period.isLastDataMode ? period.fromTo.to : period.toDatepicker;
      let startCheckValidDate: Date = dataStartDate;

      let maxLicenseDate: Date = null;

      const periods: IDiseaseLicenseItem[] = this.preparePeriods(models[diseaseGroup]);
      const invalidDates: string[] = [];

      periods.forEach((license: IDiseaseLicenseItem) => {
        const licenseStartDate: Date = moment(license.from).toDate();
        const licenseEndDate: Date = moment(license.to).toDate();

        maxLicenseDate = maxLicenseDate > licenseEndDate ? maxLicenseDate : licenseEndDate;

        const isBetween: boolean = startCheckValidDate < dataStartDate && dataEndDate < licenseStartDate;
        const isNoLicensePeriod: boolean = startCheckValidDate < licenseStartDate
          && startCheckValidDate >= dataStartDate
          && startCheckValidDate <= dataEndDate;
        if (isBetween || isNoLicensePeriod) {
          const from = startCheckValidDate >= dataStartDate ? startCheckValidDate : dataStartDate;
          const to = licenseStartDate < dataEndDate ? licenseStartDate : dataEndDate;
          invalidDates.push(this.getInvalidDate(moment(from), moment(to)));
        }
        startCheckValidDate = licenseEndDate;
      });

      if (dataEndDate > startCheckValidDate) {
        invalidDates.push(this.getInvalidDate(moment(startCheckValidDate < dataStartDate
          ? dataStartDate : startCheckValidDate), moment(dataEndDate)));
      }

      const hasValidLicense: boolean = moment(maxLicenseDate).isAfter(moment());
      const daysUntilEndLicense: number = moment(maxLicenseDate).diff(moment(), 'days');

      if (!hasValidLicense) {
        const warningMessage = this.licenseExpiredOn + ' ' + moment(maxLicenseDate).format('DD-MM-YYYY') + '. ' + this.toRenew;
        this.addWarning({
          type: 'warning',
          message: warningMessage,
          lastLicenseDate: moment(maxLicenseDate).format('DD-MM-YYYY'),
          removeIcon: true
        });
      }

      if (hasValidLicense && daysUntilEndLicense <= 10) {
        const warningMessage = this.pleaseNoteString + ' ' + daysUntilEndLicense + ' ' + this.daysToRenew;
        this.addWarning({
          type: 'warning',
          message: warningMessage,
          removeIcon: true
        });
      }

      if (invalidDates.length) {
        this.addWarning({
          type: 'info',
          message: 'No Licence for specified period',
          dates: invalidDates
        });
      }
    }
  }

  private getTranslatedStrings(): void {
    this.translate.get('Your license will expire in').pipe(take(1)).subscribe((translatedText: string) => {
      this.pleaseNoteString = translatedText;
    });
    this.translate.get('days. To renew it, please contact your METOS salesperson.').pipe(take(1)).subscribe((translatedText: string) => {
      this.daysToRenew = translatedText;
    });
    this.translate.get('Your license has expired on').pipe(take(1)).subscribe((translatedText: string) => {
      this.licenseExpiredOn = translatedText;
    });
    this.translate.get('To renew it, please contact your METOS salesperson.').pipe(take(1)).subscribe((translatedText: string) => {
      this.toRenew = translatedText;
    });
  }

  private getInvalidDate(from: moment.Moment, to: moment.Moment): string {
    return from.diff(to, 'day') !== 0 ?
      `${from.format(this.dateFormat)} - ${to.format(this.dateFormat)}`
      : `${from.format(this.dateFormat)}`;
  }

  private preparePeriods(periodRanges: IDiseaseLicenseItem[]): IDiseaseLicenseItem[] {
    const preparePeriodRanges: IDiseaseLicenseItem[] = [];
    periodRanges.forEach((comparePeriod: IDiseaseLicenseItem) => {
      const newPeriod: IDiseaseLicenseItem = {
        from: comparePeriod.from,
        to: comparePeriod.to
      };
      let isCheck: boolean = false;
      periodRanges.forEach((period: IDiseaseLicenseItem) => {
        if (this.compareDate(comparePeriod.to, period.to) && this.compareDate(comparePeriod.from, period.from)) {
          return;
        }
        if (this.checkBeforeDate(comparePeriod, period)) {
          isCheck = true;
          this.setNewPeriodValue(newPeriod, comparePeriod, period);
        }
        if (this.checkAfterDate(comparePeriod, period)) {
          isCheck = true;
          this.setNewPeriodValue(newPeriod, period, comparePeriod);
        }
        if (this.checkShiftDate(comparePeriod, period)) {
          isCheck = true;
          this.setNewPeriodValue(newPeriod, comparePeriod, comparePeriod);
        }
      });

      if (!this.checkCopyOfDate(preparePeriodRanges, newPeriod) && isCheck) {
        preparePeriodRanges.push(newPeriod);
      }
    });

    periodRanges = preparePeriodRanges.length
      ? preparePeriodRanges.length > 1 ? this.preparePeriods(preparePeriodRanges) : preparePeriodRanges
      : periodRanges;
    return this.sortPeriodRange(periodRanges);
  }

  private setNewPeriodValue(newPeriod: IDiseaseLicenseItem,
                            period1: IDiseaseLicenseItem,
                            period2: IDiseaseLicenseItem): void {
    newPeriod.from = this.compareAfterDate(newPeriod.from, period1.from) ? period1.from : newPeriod.from;
    newPeriod.to = this.compareAfterDate(newPeriod.to, period2.to) ? newPeriod.to : period2.to;
  }

  private compareDate(a: string, b: string): boolean {
    return moment(a).isSame(moment(b));
  }

  private compareAfterDate(a: string, b: string): boolean {
    return moment(a).isAfter(moment(b));
  }

  private sortPeriodRange(periodRanges: IDiseaseLicenseItem[]): IDiseaseLicenseItem[] {
    periodRanges.sort((a: IDiseaseLicenseItem, b: IDiseaseLicenseItem) => moment(a.from).unix() - moment(b.from).unix());
    return periodRanges;
  }

  private checkBeforeDate(comparePeriod: IDiseaseLicenseItem, period: IDiseaseLicenseItem): boolean {
      return moment(comparePeriod.from).isSameOrBefore(moment(period.from))
        && moment(comparePeriod.to).isSameOrBefore(period.to)
        && moment(comparePeriod.to).isSameOrAfter(period.from);
  }

  private checkShiftDate(comparePeriod: IDiseaseLicenseItem, period: IDiseaseLicenseItem): boolean {
    return moment(comparePeriod.from).isSameOrBefore(moment(period.from)) && moment(comparePeriod.to).isSameOrAfter(period.to);
  }

  private checkAfterDate(comparePeriod: IDiseaseLicenseItem, period: IDiseaseLicenseItem): boolean {
    return moment(comparePeriod.from).isSameOrAfter(moment(period.from))
    && moment(comparePeriod.to).isSameOrAfter(period.to);
  }

  private checkCopyOfDate(periodRanges: IDiseaseLicenseItem[], period: IDiseaseLicenseItem ): boolean {
    return periodRanges.some((p: IDiseaseLicenseItem) => moment(p.from).isSame(moment(period.from)) && moment(p.to).isSame(period.to));
  }

  private addWarning(warning: IDiseaseWarning): void {
    this.diseaseStore.dispatch(addStationDiseaseWarning(warning));
  }
}
