<div class="yield-prediction-help-modal">
  <p>
    <b>{{ 'Predicted crop physiological maturity:' | translate }}</b>
    {{ 'The prediction of the finalization of yield formation (i.e. no further increase in yield after this date). ' +
    'Note that harvest is usually some weeks later, depending on the crop, as grains need to dry down.' | translate }}
  </p>
  <p>
    <b>{{ 'Yield “today” (current season):' | translate }}</b>
    {{ 'This prediction includes your station-measured precipitation since sowing and your setting of initial soil ' +
    'moisture. It assumes no further rainfall after today for the rest of the season, i.e. a worst-case scenario. ' | translate }}
  </p>
  <p>
    <b>{{ 'Predicted yield at harvest (normal season):' | translate }}</b>
    {{ 'This assumes average rain for the entire season, starting with your setting of initial soil moisture.' | translate }}
  </p>
  <p>
    <b>{{ 'Predicted yield at harvest (current season + rain forecast):' | translate }}</b>
    {{ 'This values considers not only your initial soil moisture setting and station-measured rainfall since sowing ' +
    'but also a seasonal forecast until the predicted crop physiological maturity date. However, keep in mind that ' +
    'seasonal rain forecasts come with a considerable uncertainty. Note that when today passes crop physiological ' +
    'maturity date, this value will be identical to Yield “today” (see above), since at this time, the prediction ' +
    'is based entirely on station-measured data.' | translate }}
  </p>
  <p>
    <b>{{ 'Please note:' | translate }}</b>
    {{ 'The predicted yield values represent an estimate of potential yield at harvest. This estimate is largely based on ' +
    'estimates (measured, historic average, and forecast) of precipitation. Other yield-limiting factors such as pests, ' +
    'diseases, soil fertility, and others are (currently) not considered. There is no guarantee that your actual yield at ' +
    'harvest will be within the presented ranges. However, the predicted values can serve as an in-season indication ' +
    'whether the crop\'s yield performance is likely to fall below or beat the historic average. Use this additional ' +
    'information when making management decisions such as fertilization or irrigation.' | translate }}
  </p>
  <p>
    <a href="https://metos.at/en/farmview-manual/#yield-prediction" target="_blank">{{ 'Click here' | translate }}</a>
    {{ 'to view the detailed Yield Prediction manual.' | translate}}
  </p>
</div>