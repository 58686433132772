import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { ISelectedSearchWidgetItem } from '../../../../core/models/selectedSearchWidgetItem';
import { selectSelectedStation } from '../../../../core/reducers';
import { ISelectedStationState } from '../../../../core/reducers/selectedStation';
import { NavigationService } from '../../../../core/services/navigation/navigation.service';
import { treeSettings as soilMoistureSelector } from '../../../../modules/soil-moisture/selectors';
import { selectTreeSettings as stationDataSelector } from '../../../../modules/station-data/reducers';
import { INode, ITree, ITreeStructure, TreeContentType } from '../../../../services/tree/models';
import { TreeService } from '../../../../services/tree/tree.service';
import { setBatchTreeSetting, setProfile, setTreeSetting } from '../../actions/tree-settings';
import { ITreeComponent, ITreeSettingsState } from '../../models/tree.models';

@Component({
  selector: 'app-tree-sensors',
  templateUrl: './tree-sensors.component.html',
  styleUrls: ['./tree-sensors.component.scss'],
})
export class TreeSensorsComponent implements ITreeComponent, OnInit, OnDestroy {
  public profileControl           : FormControl = new FormControl('');
  public ContentType              = TreeContentType;
  public tree                     : ITreeStructure;
  public disabledGroupIds         : {} = {};
  public profile                  : string = 'All sensors';

  private selectedStationChange$  : Observable<void> = new Observable<void>();
  private treeSelectorChange$     : Observable<void> = new Observable<void>();
  private stationId               : string;
  private destroy$                : Subject<boolean> = new Subject<boolean>();
  private readonly routeToSelectorMap = new Map([
    [':id/data', stationDataSelector],
    [':id/soil-moisture', soilMoistureSelector]
  ]);
  private currentSelector$  : Subject<MemoizedSelector<any, ITreeSettingsState>> = new Subject<MemoizedSelector<any, ITreeSettingsState>>();
  private currentSelector   : MemoizedSelector<any, ITreeSettingsState>;
  public isHydroponicsPage  : boolean = false;

  constructor(
    private treeSettingStore        : Store<ITreeSettingsState>,
    private selectedStationStore    : Store<ISelectedStationState>,
    private router                  : Router,
    private navigationService       : NavigationService,
    private activatedRoute          : ActivatedRoute,
    private treeService             : TreeService,
  ) {
    if (this.activatedRoute.parent.snapshot.url[1].path === 'hydroponics') {
      this.isHydroponicsPage = true;
    }
  }

  public ngOnInit(): void {
    this.listenToDropDown();
    this.listenToSelectedStation();
    this.listenToTreeSelectorChange();
    this.subscribeToSelectedStationAndTreeSettings();
    this.listenRouter();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public disableTree(tree: ITree): void {
    this.dispatchBatchTreeSettingsAction(tree, false);
  }

  public setTree(tree: ITreeStructure): void {
    this.tree = tree;
    this.profile = tree.profiles.selectedOption ? tree.profiles.selectedOption.value : null;
  }

  public enableTree(tree: ITree): void {
    this.dispatchBatchTreeSettingsAction(tree, true);
  }

  public toggleSensor(node: INode): void {
    this.treeSettingStore.dispatch(setTreeSetting({
      profile: this.profile,
      stationId: this.stationId,
      groupdId: node.groupId
    }, this.navigationService.treeSetSettingsAction));
  }

  public treeItemSettingsClicked(itemName: string): void {
    this.treeService.treeItemSettingsClicked$.next(itemName);
  }

  private listenToDropDown(): void {
    this.profileControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      debounceTime(400),
    ).subscribe((profile: string): void => {
      this.profile = profile;
      this.treeSettingStore.dispatch(setProfile(
        {stationId: this.stationId, profile: this.profile},
        this.navigationService.treeSetProfileAction));
    });
  }

  private listenToSelectedStation(): void {
    this.selectedStationChange$ = this.selectedStationStore.pipe(
      select(selectSelectedStation),
      distinctUntilChanged(),
      filter((selectedStation): boolean => !!selectedStation),
      map((selectedStation: ISelectedSearchWidgetItem): void => {
        this.stationId = selectedStation.original_name;
      })
    );
  }

  private listenToTreeSelectorChange(): void {
    this.treeSelectorChange$ = this.currentSelector$.pipe(
      map((selector: MemoizedSelector<any, ITreeSettingsState>): void => {
        this.currentSelector = selector;
      })
    );
  }

  private subscribeToSelectedStationAndTreeSettings(): void {
    combineLatest([
      this.selectedStationChange$,
      this.treeSelectorChange$.pipe(
        switchMap((): Observable<ITreeSettingsState> => this.getTreeSettingsObservable())
      )
    ]).pipe(
      map((result: Array<void|ITreeSettingsState>): ITreeSettingsState => <ITreeSettingsState>result[1]),
      filter((treeSettings: ITreeSettingsState): boolean => !!treeSettings[this.stationId])
    ).subscribe((treeSettings: ITreeSettingsState): void => {
      this.setProfileAndDisabledGroupIds(treeSettings);
    });
  }

  private listenRouter(): void {
    this.setTreeSelector();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((): void => {
      this.setTreeSelector();
    });
  }

  private setTreeSelector(): void {
    const routePath = this.activatedRoute.parent && this.activatedRoute.parent.routeConfig
      ? this.activatedRoute.parent.routeConfig.path
      : null;
    if (routePath) {
      this.currentSelector$.next(this.routeToSelectorMap.get(routePath));
    }
  }

  private getTreeSettingsObservable(): Observable<ITreeSettingsState> {
    return this.treeSettingStore.pipe(
      select(this.currentSelector),
      distinctUntilChanged(),
      filter((treeSettings: ITreeSettingsState): boolean => !!treeSettings)
    );
  }

  private dispatchBatchTreeSettingsAction(tree: ITree, enable: boolean): void {
    this.treeSettingStore.dispatch(setBatchTreeSetting({
      profile: this.profile,
      stationId: this.stationId,
      groupIds: tree.nodes.map((node: INode) => node.groupId),
      enable: enable
    }, this.navigationService.treeSetBatchSettingsAction));
  }

  private setProfileAndDisabledGroupIds(treeSettings: ITreeSettingsState): void {
    if (
        treeSettings[this.stationId].selectedProfile &&
        this.tree.profiles &&
        this.tree.profiles.options.some(profile => profile.value === treeSettings[this.stationId].selectedProfile)
      ) {
      this.profile = treeSettings[this.stationId].selectedProfile;
      this.profileControl.setValue(this.profile, { emitEvent: false });
    }
    if (treeSettings[this.stationId].profileSettings[this.profile]) {
      this.disabledGroupIds = treeSettings[this.stationId].profileSettings[this.profile].disabledGroupIds || {};
    }
  }
}
