import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import * as fileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {

  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string, feature: string): void {
    const wscols = this.getColumnsWidth(feature, json);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    worksheet['!cols'] = wscols;
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    fileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  private getColumnsWidth(feature: string, json: any): any[] {
    const headers = Object.keys(json[0]);     // column names (headers)

    switch (feature) {
      case 'stationsList':
        if (headers.length === 12) {
          return [
            { wch: headers[0].length },         // station ID
            { wch: headers[1].length + 15 },    // station name
            { wch: headers[2].length + 10 },    // device type
            { wch: headers[3].length + 3 },     // last communication
            { wch: headers[4].length + 2 },     // battery
            { wch: headers[5].length },         // air temperature
            { wch: headers[6].length },         // soil temperature
            { wch: headers[7].length },         // relative humidity
            { wch: headers[8].length },         // solar radiation
            { wch: headers[9].length },         // rain 24 hours
            { wch: headers[10].length },        // rain 48 hours
            { wch: headers[11].length },        // rain 7 days
          ];
        } else {
          const wcols = [];
          headers.forEach(header => {
            wcols.push({ wch: header.length + 10 });
          });
          return wcols;
        }
      default:
        const wscols = [];
        headers.forEach(header => {
          wscols.push({ wch: header.length + 10 });
        });
        return wscols;
    }
  }
}
