import {
  AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as turf from '@turf/turf';
import { Feature, Polygon } from 'geojson';
import { combineLatest, fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import * as svg from 'save-svg-as-png';
import { ICropZone } from '../../../core/models/cropzones';
import { ILai, INDVI, ISatState } from '../models';
import { laiRes10Selector, ndviRes10Selector, visibleLaiValSelector, visibleNdviValSelector } from './../selectors/index';
import { CropZoneSatServiceService } from './../services/crop-zone-sat-service.service';

@Component({
  selector: 'app-lazy-list-item',
  templateUrl: './lazy-list-item.component.html',
  styleUrls: ['./lazy-list-item.component.scss']
})

export class LazyListItemComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('contentContainer')
  public contentContainer: ElementRef;

  @ViewChild('header')
  public header;

  @ViewChild('legendContainer')
  public legendContainer;

  @ViewChild('container')
  public container;

  @ViewChild('svgDiv')
  public svgDiv;

  @Input()
  public value: ILai | INDVI;

  @Input()
  public resolution: number;

  @Input()
  public cropZone: ICropZone;

  @Input()
  public visible: boolean = false;

  @Input()
  public index: number;

  @Input()
  public type: string;

  @Output()
  public selectedItem = new EventEmitter();

  public pending = false;

  private width = 225;
  private height = 225;

  private laiRes10;
  private ndviRes10;
  private destroy$: Subject<boolean> = new Subject();
  public notEmpty: boolean;
  private resizeObservable$: Observable<Event>;
  private boundary: any;
  private featureCollection: any;

  public isLoading: boolean = true;
  constructor(private satStore: Store<ISatState>,
              private cropzoneSatService: CropZoneSatServiceService,
              private renderer: Renderer2,
              private cdRef: ChangeDetectorRef) { }

  public ngOnInit(): void {
    Object.keys(this.value).length > 0 ? this.notEmpty = true : this.notEmpty = false;
  }

  public ngAfterViewInit(): void {
    if (this.value.date === 'legend') {
      this.renderer.setStyle(this.legendContainer.nativeElement, 'height', '100%');
      this.renderer.setStyle(this.svgDiv.nativeElement, 'height', '100%');

      setTimeout(() => {
        const containerWidth = this.svgDiv.nativeElement.offsetWidth;
        const containerHeight = this.svgDiv.nativeElement.offsetHeight;

        if (this.type === 'LAI') {
          this.cropzoneSatService.renderLegend(this.index, '100%', containerHeight);
        } else if (this.type === 'NDVI') {
          this.cropzoneSatService.renderNdviLegend(this.index, '100%', containerHeight);
        }
      }, 0);
    }

    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeObservable$.subscribe(() => {
      if (this.contentContainer) {
        const containerWidth = this.contentContainer.nativeElement.offsetWidth;

        if (containerWidth > 0 && this.featureCollection && this.value.date !== 'legend') {
          if (this.type === 'LAI') {
            setTimeout(() => {
              this.cropzoneSatService.renderLai(this.featureCollection, this.boundary, this.index, containerWidth, containerWidth);
            }, 0);
          } else if (this.type === 'NDVI') {
            setTimeout(() => {
              this.cropzoneSatService.renderNdvi(this.featureCollection, this.boundary, this.index, containerWidth, containerWidth);
            }, 0);
          }
        }
      }
    });

    if (this.type === 'LAI') {
      combineLatest([
        this.satStore.pipe(
          select(visibleLaiValSelector),
          takeUntil(this.destroy$)
        ),
        this.satStore.pipe(
          select(laiRes10Selector),
          takeUntil(this.destroy$)
        )
      ]).pipe(debounceTime(0)).subscribe(([visibleLaiVals, laiRes10Val]) => {
        this.laiRes10 = null;
        this.isLoading = true;
        let laiVal;

        if (this.value.date !== 'legend') {
        if (laiRes10Val !== undefined && laiRes10Val !== null) {
          if (Object.keys(laiRes10Val).length > 0) {
            if (laiRes10Val.hasOwnProperty(this.cropZone.id)) {
              if (laiRes10Val[this.cropZone.id].date === this.value.date) {
                this.laiRes10 = laiRes10Val[this.cropZone.id];
              }
            }
          }
        }

        if (Object.keys(visibleLaiVals).length > 0) {
          if (visibleLaiVals.hasOwnProperty(this.cropZone.id)) {
            laiVal = visibleLaiVals[this.cropZone.id].filter((val) => val.date === this.value.date)[0];
          }
        }

        if (laiVal) {
          const boundary: Feature<Polygon> = {
            type: 'Feature',
            geometry: this.cropZone.boundary,
            properties: {
              lai: this.value['lai'].mean
            }
          };

          if (laiVal.result && !this.laiRes10) {
            const insidepolygons = [];
            laiVal.result.features.forEach(feature => {
              const outsideOfBoundary = turf.difference(feature.geometry, boundary.geometry);

              if (outsideOfBoundary == null) {
                feature.properties.lai = feature.properties.lai < 0 ? 0 : feature.properties.lai;
                insidepolygons.push(feature);
              } else {
                const insideofBoundary = turf.difference(feature.geometry, outsideOfBoundary);
                insideofBoundary.properties.lai = feature.properties.lai < 0 ? 0 : feature.properties.lai;
                insidepolygons.push(insideofBoundary);
              }
            });

            const featureCollection = {
              type: 'FeatureCollection',
              features: insidepolygons
            };

            this.featureCollection = featureCollection;
            this.boundary = boundary;

            if (this.header) {
              const containerWidth = this.header.nativeElement.offsetWidth;
              if (this.value.date !== 'legend') {
                this.cropzoneSatService.renderLai(featureCollection, boundary, this.index, containerWidth, containerWidth);
              }
            }
            this.resolution = laiVal.resolution;

          } else if (this.laiRes10) {
            const insidePolygons = [];
            this.laiRes10.result.features.forEach(feature => {
              const outsideOfBoundary = turf.difference(feature.geometry, boundary.geometry);

              if (outsideOfBoundary == null) {
                feature.properties.lai = feature.properties.lai < 0 ? 0 : feature.properties.lai;
                insidePolygons.push(feature);
              } else {
                const insideofBoundary = turf.difference(feature.geometry, outsideOfBoundary);
                insideofBoundary.properties.lai = feature.properties.lai < 0 ? 0 : feature.properties.lai;
                insidePolygons.push(insideofBoundary);
              }
            });

            const featureCollection = {
              type: 'FeatureCollection',
              features: insidePolygons
            };

            this.featureCollection = featureCollection;
            this.boundary = boundary;

            if (this.header) {
              const containerWidth = this.header.nativeElement.offsetWidth;
              if (this.value.date !== 'legend') {
                this.cropzoneSatService.renderLai(featureCollection, boundary, this.index, containerWidth, containerWidth);
              }
            }
          } else {
            this.cropzoneSatService.renderBoundary(boundary, this.index, this.width, this.height);
          }
          this.isLoading = false;
        }
      }
      });
    } else if (this.type === 'NDVI') {
      combineLatest([
        this.satStore.pipe(
          select(visibleNdviValSelector),
          takeUntil(this.destroy$)
        ),
        this.satStore.pipe(
          select(ndviRes10Selector),
          takeUntil(this.destroy$)
        )
      ]).pipe(debounceTime(0)).subscribe(([visibleNdviVals, ndviRes10Val]) => {
        this.laiRes10 = null;
        this.isLoading = true;
        let ndviVal;

        if (this.value.date !== 'legend') {
        if (ndviRes10Val !== undefined && ndviRes10Val !== null) {
          if (Object.keys(ndviRes10Val).length > 0) {
            if (ndviRes10Val.hasOwnProperty(this.cropZone.id)) {
              if (ndviRes10Val[this.cropZone.id].date === this.value.date) {
                this.ndviRes10 = ndviRes10Val[this.cropZone.id];
              }
            }
          }
        }

        if (Object.keys(visibleNdviVals).length > 0) {
          if (visibleNdviVals.hasOwnProperty(this.cropZone.id)) {
            ndviVal = visibleNdviVals[this.cropZone.id].filter((val) => val.date === this.value.date)[0];
          }
        }

        if (ndviVal) {
          const boundary: Feature<Polygon> = {
            type: 'Feature',
            geometry: this.cropZone.boundary,
            properties: {
              ndvi: this.value['ndvi'].mean
            }
          };

          if (ndviVal.result && !this.ndviRes10) {
            const insidepolygons = [];
            ndviVal.result.features.forEach(feature => {
              const outsideOfBoundary = turf.difference(feature.geometry, boundary.geometry);

              if (outsideOfBoundary == null) {
                feature.properties.ndvi = feature.properties.ndvi;
                insidepolygons.push(feature);
              } else {
                const insideofBoundary = turf.difference(feature.geometry, outsideOfBoundary);
                insideofBoundary.properties.ndvi = feature.properties.ndvi;
                insidepolygons.push(insideofBoundary);
              }
            });

            const featureCollection = {
              type: 'FeatureCollection',
              features: insidepolygons
            };

            this.featureCollection = featureCollection;
            this.boundary = boundary;

            if (this.header) {
              const containerWidth = this.header.nativeElement.offsetWidth;
              if (this.value.date !== 'legend') {
                this.cropzoneSatService.renderNdvi(featureCollection, boundary, this.index, containerWidth, containerWidth);
              }
            }
            this.resolution = ndviVal.resolution;
          } else if (this.ndviRes10) {
            const insidePolygons = [];
            this.ndviRes10.result.features.forEach(feature => {
              const outsideOfBoundary = turf.difference(feature.geometry, boundary.geometry);

              if (outsideOfBoundary == null) {
                feature.properties.ndvi = feature.properties.ndvi;
                insidePolygons.push(feature);
              } else {
                const insideofBoundary = turf.difference(feature.geometry, outsideOfBoundary);
                insideofBoundary.properties.ndvi = feature.properties.ndvi;
                insidePolygons.push(insideofBoundary);
              }
            });

            const featureCollection = {
              type: 'FeatureCollection',
              features: insidePolygons
            };

            if (this.header) {
              const containerWidth = this.header.nativeElement.offsetWidth;
              if (this.value.date !== 'legend') {
                this.cropzoneSatService.renderNdvi(featureCollection, boundary, this.index, containerWidth, containerWidth);
              }
            }

            this.resolution = 10;
          } else {
            this.cropzoneSatService.renderBoundary(boundary, this.index, this.width, this.height);
          }
          this.isLoading = false;
        }
      }
      });
    }
  }

  public downloadImage(): void {
    svg.saveSvgAsPng(document.getElementById('render' + this.index).children[0], 'lai_values_of_' + this.value.date + '.png');
  }

  public openFullResModal(): void {
    this.selectedItem.emit(this.value);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
