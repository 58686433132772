import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { formatZuluDate } from '../../shared/utils/dateFormat';

@Injectable({
  providedIn: 'root'
})

export class DatesTranslationsService {
  public months = {
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
  };

  public forWeekDate = {
    week: 'week',
    in: 'in'
  };

  public intervalDate = {
    from: 'From',
    to: 'to'
  };

  constructor(
    private translationService: TranslateService,
  ) {

  }

  public translateMonths(): any {
    Object.keys(this.months).forEach(key => {
      this.translationService.get(this.months[key])
        .subscribe(translatedMonth => {
          this.months[key] = translatedMonth;
        });
    });

    return this.months;
  }

  public translateForDate(): object {
    Object.keys(this.forWeekDate).forEach(key => {
      this.translationService.get(this.forWeekDate[key])
        .subscribe(translatedString => {
          this.forWeekDate[key] = translatedString;
        });
    });

    return this.forWeekDate;
  }

  public translateForInterval(): object {
    Object.keys(this.intervalDate).forEach(key => {
      this.translationService.get(this.intervalDate[key])
        .subscribe(translatedString => {
          this.intervalDate[key] = translatedString;
        });
    });

    return this.intervalDate;
  }

  public getMonth(date: string): string {
    return date.split(',')[0];
  }

  public getYearMonths(date: string): string {
    return date.split(',')[1];
  }

  public getWeek(weekDate: string): string {
    return weekDate.split(' ')[1];
  }

  public getYearWeek(weekDate: string): string {
    return weekDate.split(' ')[3];
  }

  public splitDate(dateToSplit: string): object {
    const date = dateToSplit.split(' ');
    return { month: date[0], day: date[1], year: date[2] };
  }

  public createDateRange(): { [key: string]: string } {

    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const next = new Date();
    next.setFullYear(next.getFullYear() + 1);
    next.setHours(0, 0, 0, 0);

    return {
      today: new Date(now).toDateString(),
      aYearFromNow: new Date(next).toDateString(),
      fullYear: now.getFullYear().toString()
    };
  }
}
