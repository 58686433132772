import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IState } from '../models';
import {IXarvioStationMap} from '../models/xarvio';

export const selector = createFeatureSelector<IState>('apiServices');

export const selectAssets = createSelector(
  selector,
  (state): IXarvioStationMap => state.xarvio.assets
);

export const selectSharingKey = createSelector(
  selector,
  (state: IState): string => state.xarvio.sharingKey
);
