import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ExcelExportParams, GridOptions } from 'ag-grid';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ISettings } from '../../../../core/models/account';
import { selectNavigationStation, selectSettings } from '../../../../core/reducers';
import * as fromAccount from '../../../../core/reducers/account';
import { ISelectedStationState } from '../../../../core/reducers/selectedStation';
import { ChartTranslationsService } from '../../../../core/services/chart-translations.service';
import { ExcelExportSettings } from '../../../../shared/constants';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';
import {
  CHART_ATTRIBUTES,
  DefaultColumnSorting,
  FrostProtectionGridOptions,
  TABLE_COLORS
} from '../../constants/constants';
import {
  IChartSeries, IFrostProtectionSensors,
  IFrostProtectionState,
  IFrostProtectionValues,
  ITableStyle
} from '../../models/models';
import { selectFrostProtectionIsTableActive, selectFrostProtectionResponse } from '../../selectors/selectors';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-frost-protection-table',
  templateUrl: './frost-protection-table.component.html',
  styleUrls: ['./frost-protection-table.component.scss']
})
export class FrostProtectionTableComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  private forecastRecordsLength : number;
  @Input()
  private measurementRecordsLength : number;

  private stationID             : string;
  private translatedValues      : IFrostProtectionValues;
  private unitSystem            : string = '';
  private tableStyle            : ITableStyle = TABLE_COLORS;
  private data                  : Array<any[]> = [];
  private mappedData            : Array<any[]> = [];
  private sortedData            : Array<any[]> = [];
  private chartAttributes       : IChartSeries = CHART_ATTRIBUTES;
  private forecastLicense       : boolean;
  private sortedColumn          : any[] = [];
  private defaultSorting        : any = DefaultColumnSorting;
  private sensors               : Array<IFrostProtectionSensors> = [];
  private destroy$              : Subject<boolean> = new Subject<boolean>();
  private datesSorted           : boolean = true;

  public response               : any = null;
  public isActive               : boolean = true;
  public dataGridOptions        : GridOptions = FrostProtectionGridOptions;
  public columnDefs             : Array<any> = [];

  constructor(
    private chartTranslationsService  : ChartTranslationsService,
    private route                     : ActivatedRoute,
    private frostProtectionStore      : Store<IFrostProtectionState>,
    private accountStore              : Store<fromAccount.IAccount>,
    private changeDetector            : ChangeDetectorRef,
    private selectedStationStore      : Store<ISelectedStationState>,
    private exportService             : StationDataExportService,
    private translateService          : TranslateService
  ) {
    // get unit system
    this.accountStore.pipe(
      takeUntil(this.destroy$),
      select(selectSettings),
      filter((settings: ISettings) => !!settings)
    ).subscribe((settings: ISettings) => {
      this.unitSystem = settings.unit_system;
    });

    this.getGridOptions();
  }

  public ngOnInit(): void {
    this.chartTranslationsService.translateShortMonths();
    this.translatedValues = this.route.snapshot.data['frostProtectionResolver'];

    this.frostProtectionStore.pipe(
      select(selectFrostProtectionResponse),
      takeUntil(this.destroy$)
    ).subscribe((response: any) => {
      if (response) {
        this.response = response;
        this.forecastLicense = this.response.info.forecast_license;
        this.resetData();
        this.getData();
        this.getColumnDefs();
        this.setDefaultSorting();
        this.sortDates();
      }
    });

    this.frostProtectionStore.pipe(
      select(selectFrostProtectionIsTableActive),
      takeUntil(this.destroy$)
    ).subscribe(isActive => {
      this.isActive = isActive;
      this.updateTable(this.sortedData);
    });

    this.selectedStationStore.pipe(
      select(selectNavigationStation),
      takeUntil(this.destroy$)
    ).subscribe(station => {
      if (station) {
        this.stationID = station.name.original;
      }
    });

    // export table
    this.exportService.getExportXLS().pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.exportXLS();
    });
  }

  // get data for the table
  public getData(): void {
    this.data['date'] = [];
    this.response.dates.forEach((date: number) => {
      this.data['date'].push(date);
    });

    this.response.sensors.forEach((sensor, i) => {
      this.sensors[i] = {
        name: sensor.name_original,
        customName: sensor.name ? sensor.name : null,
        nameWithoutSpaces: sensor.name_original.replace(/\s/g, ''),
        group: sensor.group,
        channel: sensor.ch,
        unit: sensor.unit,
      };

      Object.keys(sensor.values).forEach(valueGroup => {                              // avg, min, max, forecast
        if (valueGroup !== 'forecast') {                                              // avg, min, max
          this.data['channel' + sensor.ch + valueGroup] = sensor.values[valueGroup];
        } else {                                                                      // forecast
          Object.keys(sensor.values.forecast).forEach((value, valueInd) => {
            this.data['channel' + sensor.ch + 'avg'].push(sensor.values[valueGroup][valueInd]);
          });
        }
      });
    });

    // translate sensor names
    this.sensors.forEach((sensor, i) => {
      this.translateService.get(sensor.name)
        .subscribe(translatedSensorName => {
          this.sensors[i].translatedName = translatedSensorName;
        });
    });

    this.mapData();
  }

  // map and sort data from OLDEST to the NEWEST
  private mapData(): void {
    // show only measured data if no forecast license
    const dataLength = this.forecastLicense ? this.data['date'].length : this.measurementRecordsLength;

    for (let i = 0; i < dataLength; i++) {
      this.mappedData.push([]);
      Object.keys(this.data).forEach(attribute => {
        this.mappedData[i][attribute] = this.data[attribute][i];
      });
    }
  }

  private getColumnDefs(): void {
    this.columnDefs.push({
      headerName: this.translatedValues['date'],
      field: 'date',
      suppressMenu: true,
      suppressMovable: true,
      resizable: false,
      width: 210
    });

    this.sensors.forEach(sensor => {
      const group = 'group' + sensor.group;
      const attributeName = sensor.customName ?
        sensor.customName :
        sensor.translatedName;
      let headerName = '';

      headerName = attributeName + ' [' + this.chartAttributes[group].units[this.unitSystem] + ']';

      this.columnDefs.push({
        headerName: headerName,
        children: [
          { headerName: this.translatedValues['min'], field: 'channel' + sensor.channel + 'min', width: 70, suppressMenu: true },
          { headerName: this.translatedValues['max'], field: 'channel' + sensor.channel + 'max', width: 70, suppressMenu: true },
          { headerName: this.translatedValues['avg'], field: 'channel' + sensor.channel + 'avg', width: 70, suppressMenu: true },
        ],
        field: sensor.name,
        suppressMenu: true,
        width: 210
      });
    });
  }

  private updateTable(data: any[]): void {
    setTimeout(() => {
      if (this.dataGridOptions && this.dataGridOptions.api) {
        this.dataGridOptions.api.setColumnDefs(this.columnDefs);
        this.dataGridOptions.api.setRowData(data);
      }
    }, 1000);
    this.changeDetector.detectChanges();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.response) {
      this.resetData();
      this.getData();
      this.getColumnDefs();
      this.updateTable(this.sortedData);
    }
  }

  public getGridOptions(): void {
    this.dataGridOptions = {
      api: this.dataGridOptions.api,
      columnDefs: [],
      rowData: [],
      enableColResize: true,
      enableSorting: true,
      rowHeight: 30,
      suppressDragLeaveHidesColumns: true,
      headerHeight: 28,
      rowSelection: 'single',
      suppressContextMenu: true,
      suppressCellSelection: true,
      enableServerSideSorting: true,
      getRowStyle: params => {
        const style = { background: '', borderTop: '' };
        // if forecast license (measured and forecast data are shown), show the line between them and style the rows, otherwise not
        if (this.datesSorted && this.forecastLicense) {
          const lineIndex = this.sortedColumn[0].sort === 'asc' ? this.measurementRecordsLength : this.forecastRecordsLength;
          if (params.node.rowIndex === lineIndex) {                         // line between forecast and measurement data
            style.borderTop = this.tableStyle.line;
          }
          if (params.node.rowIndex % 2 === 0) {                             // odd row
            style.background = this.tableStyle.whiteRow;
          } else if (params.node.rowIndex < lineIndex) {                    // even row
            style.background = this.sortedColumn[0].sort === 'asc' ? this.tableStyle.measurementRow : this.tableStyle.forecastRow;
          } else {
            style.background = this.sortedColumn[0].sort === 'asc' ? this.tableStyle.forecastRow : this.tableStyle.measurementRow;
          }
        }
        if (params.node.isSelected()) {                                   // selected row
          style.background = this.tableStyle.selectedRow;
        }
        return style;
      },
      onRowClicked: params => {
        this.dataGridOptions.api.redrawRows();
      },
      onGridReady: (event) => {
        event.api.sizeColumnsToFit();
      }
    };
  }

  private resetData(): void {
    this.columnDefs = [];
    this.mappedData = [];
    this.sortedData = [];
    this.data = [];
    this.sensors = [];
  }

  private setDefaultSorting(): void {
    this.sortedColumn = this.defaultSorting;
    if (this.dataGridOptions.api) {
      this.dataGridOptions.api.setSortModel(this.sortedColumn);
    }
    this.setSort();
  }

  private exportXLS(): void {
    const path = 'frost_protection';
    const params: ExcelExportParams = {
      ...ExcelExportSettings,
      fileName: `${this.stationID}_${path}`
    };

    this.dataGridOptions.api.exportDataAsExcel(params);
  }

  public onSortChange(columnEvent): void {
    if (this.dataGridOptions && this.dataGridOptions.api) {
      this.sortedColumn = this.dataGridOptions.api.getSortModel();

      // updating columnDefs to set 'sort' on chosen column
      if (this.sortedColumn.length) {                               // if sort is 'asc' or 'desc'
        this.setSort();                                             // set sort fot the chosen column and delete the previous one
        this.sortedColumn[0].colId === 'date' ?                     // sort the data depends on what type of data the column contains
          this.sortDates() :
          this.sortNumbers();
      } else {                                                      // if sort is not set (not 'asc' or 'desc')
        this.setDefaultSorting();
      }
    } else if (this.dataGridOptions && !this.dataGridOptions.api) { // on init
      this.setDefaultSorting();
      this.sortDates();
    }

    this.datesSorted = this.sortedColumn[0].colId === 'date';       // if sorted column is 'date' column => we need it for the table style
    this.updateTable(this.sortedData);
  }

  // set 'sort' on chosen column, delete previous sorting
  private setSort(): void {
    this.columnDefs.forEach((column, i) => {
      if (!this.columnDefs[i].hasOwnProperty('children')) {
        if (this.columnDefs[i].field === this.sortedColumn[0].colId) {
          this.columnDefs[i].sort = this.sortedColumn[0].sort;
        } else {
          delete this.columnDefs[i].sort;
        }
      } else {
        this.columnDefs[i].children.forEach((child, j) => {
          if (this.columnDefs[i].children[j].field === this.sortedColumn[0].colId) {
            this.columnDefs[i].children[j].sort = this.sortedColumn[0].sort;
          } else {
            delete this.columnDefs[i].children[j].sort;
          }
        });
      }
    });
  }

  private sortNumbers(): void {
    if (this.sortedColumn[0].sort === 'asc') {
      this.sortedData.sort((a, b) =>
        a[this.sortedColumn[0].colId] < b[this.sortedColumn[0].colId] ? -1 : 1
      );
    } else if (this.sortedColumn[0].sort === 'desc') {
      this.sortedData.sort((a, b) =>
        a[this.sortedColumn[0].colId] > b[this.sortedColumn[0].colId] ? -1 : 1
      );
    }
  }

  private sortDates(): void {
    this.sortedData = [];
    this.mappedData.forEach((record, i) => {
      const val = (typeof record === 'object') ? Object.assign({}, record) : record;
      this.sortedData.push(val);
    });

    if (this.sortedColumn[0].sort === 'asc') {
      this.sortedData.sort((a, b) =>
        a[this.sortedColumn[0].colId] < b[this.sortedColumn[0].colId] ? -1 : 1
      );
    } else if (this.sortedColumn[0].sort === 'desc') {
      this.sortedData.sort((a, b) =>
        a[this.sortedColumn[0].colId] > b[this.sortedColumn[0].colId] ? -1 : 1
      );
    }

    this.formatTableDates();
  }

  private formatTableDates(): void {
    this.sortedData.forEach((date, i) => {
      this.sortedData[i]['date'] = moment.utc(date['date'] * 1000).format('DD-MM-YYYY HH:mm:ss');
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
