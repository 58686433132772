<div *ngIf="!loadingSensorMap; else loading">
  <div [hidden]="sensorMap && sensorMap.length">
    <div class="app-davis">
      <app-form-layout>
        <app-card-header (openModal)="openModal('upper')"
                         description="Ingest your WeatherLink sensors to your FieldClimate account"
                         header="WeatherLink API setup"
                         helpButton="true">
        </app-card-header>

        <div class="davis">
          <div *ngIf="formVisible" class="davis-form">
            <form [formGroup]="form">
              <div class="row">
                <div class="col-md-6">

                  <label>{{ 'API Key V2' | translate }}</label>
                  <app-form-input formControlName="APIKey"></app-form-input>

                  <label>{{ 'API Secret' | translate }}</label>
                  <app-form-input contentType="password" formControlName="APISecret"></app-form-input>

                </div>
              </div>
            </form>
          </div>

          <div class="row">
            <div *ngIf="formVisible" class="col-md-12 davis__submit">
              <app-custom-button (click)="saveSettings()"
                                 class="davis__revoke"
                                 color="red"
                                 content="Save settings">
              </app-custom-button>
              <app-custom-button (click)="removeSettings()"
                                 color="red"
                                 content="Remove settings">
              </app-custom-button>
            </div>
          </div>
        </div>
      </app-form-layout>
    </div>

    <ng-container *ngIf="(stations$ | async) as stations">
      <div *ngIf="stations.length && stationsPageItems$ | async as stationsPageItem" class="app-davis-stations">
        <app-form-layout>
          <app-card-header (openModal)="openModal('lower')"
                           description="Ingest your WeatherLink sensors to your FieldClimate account"
                           header="WeatherLink data pull service"
                           helpButton="true">
          </app-card-header>

          <div class="davis">
            <div class="row">
              <div class="col-md-12">
                <div class="scroll">

                  <table class="table">
                    <thead>
                    <tr>
                      <th>{{ 'Station Name' | translate }}</th>
                      <th>{{ 'Last sync' | translate }}</th>
                      <th>{{ 'Station ID' | translate }}</th>
                      <th>{{ 'Description' | translate }}</th>
                      <th>{{ '' }}</th>
                      <th>{{ 'Active' | translate }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of stationsPageItem">
                      <td>
                        <span>{{ item.davisName }}</span>
                      </td>
                      <td>
                        <span *ngIf="item.pesslStationID">
                          {{ item.lastSync + '000' | date: 'dd-MM-yyyy' }}
                        </span>
                      </td>
                      <td><span>{{ item.pesslStationID }}</span></td>
                      <td><span>{{ item.description }}</span></td>
                      <td>
                        <button (click)="loadSensorMap(item.davisStationID, item.pesslStationID)"
                                [disabled]="false"
                                [hidden]="!item.pesslStationID"
                                class="btn btn-sm btn-primary"
                                style="max-height: 33px; min-width: 60px; margin-bottom: 5px"
                                type="button">
                          {{ 'Sensors' | translate }}
                        </button>
                      </td>
                      <td>
                        <app-yes-no-toggle (change)="updateSync($event, item)"
                                           [hidden]="!item.pesslStationID"
                                           [ngModel]="item.needSync"
                                           noTab="true">
                        </app-yes-no-toggle>
                        <button (click)="openLicenseModal(item.davisStationID)"
                                [disabled]="false"
                                [hidden]="item.pesslStationID"
                                class="btn btn-sm btn-danger"
                                style="height: 33px; min-width: 60px; margin-bottom: 5px"
                                type="button">
                          {{ 'Use License' | translate }}
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>

                </div>

                <pagination (pageChanged)="leafThrough$.next($event.page - 1)"
                            *ngIf="stations"
                            [boundaryLinks]="stations.length / stationsPerPage > 10"
                            [itemsPerPage]="stationsPerPage" [maxSize]="10"
                            [totalItems]="stations.length"
                            class="davis-station-pagination" firstText="&laquo;" lastText="&raquo;" nextText="&rsaquo;"
                            previousText="&lsaquo;">
                </pagination>

              </div>
            </div>
          </div>
        </app-form-layout>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #loading>
  <div class="widget-info-container">
    <h4><i class="fa fa-cog fa-spin fa-fw"></i> {{ 'Please wait, loading data ...' | translate }}</h4>
  </div>
</ng-template>

<ng-container *ngIf="sensorMap && sensorMap.length">
  <app-davis-sensor-map (cancelSensorMap)="cancelSensorMap($event)"
                        (updateSensorMap)="saveSensorMap($event)"
                        [sensorMap]="sensorMap"
                        [stationName]="pesslStationID">
  </app-davis-sensor-map>
</ng-container>

<app-info-modal [modalId]="davisModalId" header="WeatherLink integration">
  <app-davis-modal [dialog]="dialog"></app-davis-modal>
</app-info-modal>

<app-info-modal [modalId]="licenseModalId"
                hideFooter="true"
                header="WeatherLink integration">
  <app-product-license
    *ngIf="isActiveDavis"
    [licenseType]="licenseType"
    (selectLicense)="submitLicense($event)"
    (cancel)="cancelLicense()">
  </app-product-license>
</app-info-modal>
