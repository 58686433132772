<app-form-layout class="custom-base-name">
  <div class="custom-base-name__content">
    <h2 class="custom-base-name__header">{{ 'Custom base device name' | translate}}</h2>
    <form class="custom-base-name__input-form"
          [formGroup]="nameForm"
          (ngSubmit)="emitSaveBaseName()">
      <app-form-input [type]="'border'"
                      class="custom-base-name__input"
                      [formControl]="name"
                      [size]="'small'"
      ></app-form-input>
      <app-custom-button class="custom-base-name__button" [content]="'Update'"></app-custom-button>
    </form>
  </div>
</app-form-layout>
