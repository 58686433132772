import {IRectangleCoordinates} from '../models/rectangles';

export const rectangleConfig = {
  system_rectangle_color: '#FFFF00',
  user_rectangle_color: '#00FF00',
  hover_color: '#0000FF',
  rectangle_edge_color: '#FFFF00',
  hover_edge_color: '#FF0000',
  hover_edge_size: 10,
  negative_color: '#FF0000',
  crosshair_color: '#800080',
  rectangle_center_size: 15,
  min_rectangle_size: 20,
  selected_rectangle_color: '#800080',
  marked_rectangle_color: '#FF0000',
  pest_color: {
    new: '#00FF00',
    hover: '#0000FF',
    selected: '#800080',
    negative: '#FF0000',
    user: '#FFD700',
    system: '#00FF00',
  }
};

export const cameraDistanceMultiplier = 7558;
export const pictureHeight = 2748;
export const pictureWidth = 3664;

export enum RectangleCompanionTypes {X, Y}

export const circleMeasurementsFont: string = `46px 'Open Sans', sans-serif`;

export enum RectangleVertexTypes {
  TopLeft,
  TopRight,
  BottomLeft,
  BottomRight,
  Center
}

export const edgeRectangleVertices: Array<RectangleVertexTypes> = [
  RectangleVertexTypes.TopLeft,
  RectangleVertexTypes.TopRight,
  RectangleVertexTypes.BottomLeft,
  RectangleVertexTypes.BottomRight,
];

export const scaleLines = {
  small: { start: 17, end: 37 },
  big: { start: 12, end: 42 }
};

export const initRectangleCoordinates: IRectangleCoordinates = {
  startPoint: {
    x: undefined,
    y: undefined
  },
  finishPoint: {
    x: undefined,
    y: undefined
  }
};
