import { Injectable, OnDestroy } from '@angular/core';
import { GridOptions } from 'ag-grid';
import { from as fromArray, Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { StationDataExportService } from '../export/station-data-export.service';
import { filter, map, mergeMap, takeUntil, toArray } from 'rxjs/operators';
import { ExcelExportSettings, SampleMarkers } from '../../constants';
import { isNumber } from '@turf/turf';
import { select, Store } from '@ngrx/store';
import * as fromAccount from '../../../core/reducers/account';
import { selectSettings } from '../../../core/reducers';
import { ISettings } from '../../../core/models/account';
import { IStationSampleState } from '../../../modules/station-sample/models/station-sample.model';
import { selectActiveTitle } from '../../../modules/station-sample/selectors/station-sample.selector';
import {ISampleSettings} from '../../utils/models/sample-settings';
import * as moment from 'moment';

@Injectable()
export class SampleDeviceService implements OnDestroy {

  public gridOptions: GridOptions;
  public alive$ = new Subject<void>();
  public sampleMarkers = SampleMarkers;
  public isTitleActive = true;
  public unitSystem;

  constructor(
    private translation: TranslateService,
    private exportService: StationDataExportService,
    private accountStore: Store<fromAccount.IAccount>,
    private stationSampleStore: Store<IStationSampleState>
  ) {

    this.exportService.getExportXLS()
      .pipe(takeUntil(this.alive$))
      .subscribe((data: string) => this.exportXLS(data));

    this.accountStore.pipe(
      select(selectSettings),
      takeUntil(this.alive$),
      filter((settings: ISettings) => !!settings)
    ).subscribe((settings: ISettings) => {
      this.unitSystem = settings.unit_system;
    });
  }

  public setTitleActive(value: boolean | any): void {
    this.isTitleActive = value;
  }

  public setGridOptions(data: GridOptions): void {
    this.gridOptions = data;
  }

  public groupMobilabSamples(samples: Array<any>): Array<any> {
    return samples.map(sample => {
      const { measurements } = sample;
      if (measurements && measurements.length) {
        return measurements.map(measurement => ({
          ...sample,
          ...measurement,
          measurement: null
        }));
      } else {
        return [{ ...sample }];
      }
    }).reduce((acc, val) => acc.concat(val), []);
  }

  /**
   *  * Checks the type of dates returned by the toolbar component
   *
   * @param settings
   */
  public setPeriod(settings: ISampleSettings): any {
    if (settings && settings.fromTo) {
      let {from, to} = settings.fromTo;

      if (!settings.isLastMode) {
        from = settings.fromDate;
        to = settings.toDate;
      }

      return {
        from: moment(from).unix(),
        to: moment(to).unix()
      };
    }
  }

  /**
   * Scroll navigation implementation for the cropzone-sample module
   *
   * @param source
   * @param activeDevice
   * @param tableList
   */
  public scrollInto(source, activeDevice, tableList): void {
    if (source === 'table' && window) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    } else if (tableList && activeDevice) {

      const element = tableList.find(
        _ => _.nativeElement.id === activeDevice
      );

      if (element && element.nativeElement) {

        setTimeout(() =>
          element.nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest'
          }), 0);
      }
    }
  }

  public parseTableData(table, activeMeter, source): Array<any> {
    return table.map(
      _ => this.parseDeviceData(_, activeMeter, source)
    );
  }

  /**
   * Parses coordinates from the measurements and adds the marker icon
   *
   * @param data
   * @param activeMeter
   * @param source
   */
  public parseDeviceData(data, activeMeter, source): Array<any> {
    if (data && data.length) {

      return data.map(record => {
        if (record.position) {

          if (record.position.altitude) {
            record.altitude = record.position.altitude;
          }

          if (record.position.geo && record.position.geo.coordinates) {
            const [longitude, latitude] = record.position.geo.coordinates;

            record.latitude = latitude;
            record.longitude = longitude;
          } else if (
            record.boundary &&
            record.boundary.coordinates &&
            record.boundary.coordinates[0]
          ) {

            // Caroline requested always to display only the first measurement location
            const [longitude, latitude] = record.boundary.coordinates[0][0];

            record.latitude = latitude;
            record.longitude = longitude;
          }
        }

        if (source === 'soilguard') {
          record.marker = this.getMarker(record, activeMeter);
        } else if (source === 'nutrition') {
          let iconUrl: string;
          if (record.sampleName && record.hasOwnProperty('mobiLabSerial')) {
            iconUrl = this.getMobilabMarker(record, activeMeter);

            if (record.isMixture) {
              record.sampleStatus = 'mixed';
            }
          } else {
            iconUrl = this.getDualexMarker(record, activeMeter);
          }

          record.marker = {
            url: iconUrl,
            scaledSize: {
              width: 32,
              height: 32
            }
          };
        }

        if (record.dt['$date']) {
          record.dt = new Date(Number(record.dt['$date']['$numberLong']));
        }

        return record;
      });
    }
  }

  /**
   * Exports table data in excell
   *
   * @param id
   */
  public exportXLS(id: string): void {
    const config = {
      ExcelExportSettings,
      fileName: `${id}_device_data`,
      processCellCallback: cell => {
        let data = cell.value;

        if (cell && cell.column && cell.column.colDef.cellRenderer) {
          data = cell.column.colDef.cellRenderer({ value: cell.value });
        }

        return data;
      }
    };

    this.gridOptions.api.exportDataAsExcel(config);
  }

  /**
   * Translate the table headers
   *
   * @param columns
   */
  public translate(columns: any[]): Observable<any> {
    return fromArray(columns).pipe(
      mergeMap(column => this.translation.get(column.headerName).pipe(
        map(header => (column.headerName = header) && column)
      )),
      toArray()
    );
  }

  /**
   * Generates an icon for the SoilGuard
   *
   * @param sample
   * @param interest
   */
  public getMarker(sample, interest): string {
    if (interest === 'vwc') {
      return this.checkVWC(sample.vwc);
    } else if (interest === 'temp') {
      return this.checkTemp(sample.temp);
    }
  }

  /**
   * Generates an icon for the DuaLex
   *
   * @param sample
   * @param interest
   */
  public getDualexMarker(sample, interest): string {
    if (sample.hasOwnProperty(interest)) {

      let title = '';
      const data = sample[interest];

      if (this.isTitleActive && data !== undefined && data !== null) {
        title = data.toFixed(2);
      }

      return this.getDualexVector(title);
    }
  }

  /**
   * Generates an icon for the Mobilab
   *
   * @param sample
   * @param interest
   */
  public getMobilabMarker(sample, interest): string {

    let title = '';
    const data = sample[interest];

    if (this.isTitleActive && data !== undefined && data !== null) {
      title = data.toFixed(2);
    }

    switch (true) {
      case sample.sampleType === 'plantsap' && sample.isMixture:
        return this.getMobilabMixLeafVector(title);
      case sample.sampleType === 'plantsap' && !sample.isMixture:
        return this.getMobilabLeafVector(title);
      case sample.sampleType === 'soil' && sample.isMixture:
        return this.getMobilabMixSoilVector(title);
      case sample.sampleType === 'soil' && !sample.isMixture:
        return this.getMobilabSoilVector(title);
    }
  }

  public getActiveVector(title): string {
    return this.getVector('FF00FF', title.toFixed(2));
  }

  public checkVWC(vwc): string {

    let colour: string;

    if (isNumber(vwc)) {
      switch (true) {
        case (vwc >= 35):
          colour = '53B0FE';
          break;
        case (vwc >= 30):
          colour = 'BAFFED';
          break;
        case (vwc >= 19):
          colour = '5CD700';
          break;
        case (vwc >= 11):
          colour = 'FFA300';
          break;
        default:
          colour = 'FF0001';
      }
    }

    let title = '';

    if (this.isTitleActive && vwc !== undefined && vwc !== null) {
      title = vwc.toFixed(2);
    }

    return this.getVector(colour, title);
  }

  public checkTemp(temp): string {

    const thresholds = {
      metric: [30.0, 25.0, 10.0, 8.0],
      imperial: [86.0, 77.0, 50.0, 46.4]
    };

    const items = thresholds[this.unitSystem];

    let colour: string;

    if (isNumber(temp)) {
      switch (true) {
        case (temp >= items[0]):
          colour = 'FF0001';
          break;
        case (temp >= items[1]):
          colour = 'FFA300';
          break;
        case (temp >= items[2]):
          colour = '5CD700';
          break;
        case (temp >= items[3]):
          colour = 'BAFFED';
          break;
        default:
          colour = '53B0FE';
      }
    }

    let title = '';

    if (this.isTitleActive && temp !== undefined && temp !== null) {
      title = temp.toFixed(2);
    }

    return this.getVector(colour, title);
  }

  public getVector(color: string, title = ''): string {
    if (color) {
      // todo replace pixels with alignment-baseline
      const len = title.toString().length;
      let pixels = -3;

      if (len === 1) {
        pixels = 8;
      } else if (len === 4) {
        pixels = 0;
      }

      return `data:image/svg+xml;utf8,
        <svg width="36" height="30" viewBox="0 0 25 30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g>
            <text class="play" x="${pixels}" y="11" font-family="Verdana" font-size="14" fill="%23${color}">${title}</text>
          </g>
          <circle fill="%23${color}" cx="13" cy="22" r="8" />
        </svg>`;
    }
  }

  public getDualexVector(title = ''): string {

    const len = title.toString().length;
    let pixels = 0;

    if (len === 1) {
      pixels = 8;
    } else if (len === 4) {
      pixels = 6;
    }

    return `data:image/svg+xml;utf8,
    <svg xmlns="http://www.w3.org/2000/svg"  width="100" height="100" viewBox="0 0 100 100">
      <g transform="matrix(0.100426, 0, 0, 0.100426, 49.999947, 73)">
        <g transform="matrix(1 0 0 1 0 0)" id="Layer_1">
          <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt;
            stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(177,217,151);
            fill-rule: nonzero; opacity: 1;" transform=" translate(-250, -250)"
            d="M 249.992 1.06 C 112.511 1.06 1.061 112.509 1.061 250.002 c 0 137.488 111.45 248.938 248.933 248.938
            c 137.497 0 248.946 -111.45 248.946 -248.938 C 498.939 112.509 387.49 1.06 249.992 1.06" stroke-linecap="round"/>
        </g>
        <g transform="matrix(1 0 0 1 -163.52 -0.01)" id="Layer_1">
          <circle style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0;
            stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;"
            cx="0" cy="0" r="43.216"/>
        </g>
        <g transform="matrix(1 0 0 1 -94.15 0.94)" id="Layer_1">
          <rect style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0;
            stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" x="-37.7705" y="-11.761"
            rx="0" ry="0" width="75.541" height="23.522"/>
        </g>
        <g transform="matrix(1 0 0 1 52.46 0)" id="Layer_1">
          <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0;
            stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;"
            transform=" translate(-302.46, -250)"  d="M 452.393 214.093 c -0.651 -4.021 -4.296 -8.546 -8.089 -10.045
            l -72.837 -28.861 c -3.794 -1.499 -8.111 -3.179 -9.612 -3.727 c -1.502 -0.548 -6.054 -0.997 -10.136 -0.997
            H 226.533 c -4.077 0 -7.873 3.305 -8.447 7.339 l -4.721 33.456
            c -0.569 4.04 -4.368 7.55 -8.438 7.814 l -50.409 3.216 c -4.068 0.256 -7.396 3.807 -7.396 7.884 v 39.651
            c 0 4.082 3.329 7.635 7.396 7.881 l 50.809 3.254 c 4.07 0.256 7.841 3.767 8.375 7.812 l 4.444 33.41
            c 0.535 4.039 4.311 7.357 8.387 7.357 h 125.186 c 4.07 0 9.979 -0.662 13.122 -1.459 c 3.146 -0.796 8.923 -2.395 12.845 -3.526
            l 66.388 -19.288 c 3.917 -1.144 7.577 -5.369 8.139 -9.414 l 5.215 -37.242 c 0.565 -4.039 0.48 -10.637 -0.171 -14.658
            L 452.393 214.093 z M 346.061 311.035 c 0 4.082 -3.335 7.411 -7.411 7.411 h -64.716 c -4.071 0 -7.405 -3.329 -7.405 -7.411
            V 197.842 c 0 -4.074 3.334 -7.405 7.405 -7.405 h 64.716 c 4.076 0 7.411 3.336 7.411 7.405 V 311.035 z" stroke-linecap="round"/>
        </g>
      </g>
      <g>
        <text class="play" x="${pixels}" y="40" font-family="Verdana" font-size="44" fill="rgb(177,217,151)">${title}</text>
      </g>
    </svg>`;
  }

  public getMobilabSoilVector(title): string {

    const len = title.toString().length;
    let pixels = 0;

    if (len === 1) {
      pixels = 8;
    } else if (len === 4) {
      pixels = 6;
    }

    return `data:image/svg+xml;utf8,
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100" height="100" viewBox="0 0 100 100">
      <g transform="matrix(-0.100426, 0, 0, 0.100426, 50.000046, 73.000069)">
        <g style="">
          <g transform="matrix(1 0 0 1 0 0)" id="Layer_1">
            <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0;
              stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(177,217,151); fill-rule: nonzero; opacity: 1;"
              transform=" translate(-250, -250)" d="M 249.992 1.06 C 112.511 1.06 1.061 112.509 1.061 250.002
              c 0 137.488 111.45 248.938 248.933 248.938 c 137.497 0 248.946 -111.45 248.946 -248.938 C 498.939
              112.509 387.49 1.06 249.992 1.06" stroke-linecap="round"/>
          </g>
          <g transform="matrix(1 0 0 1 0 -172.77)" id="Layer_1">
            <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0;
              stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;"
              transform=" translate(-250, -77.23)" d="M 249.987 124.375 c -19.286 0 -35.001 -15.664 -35.001 -35.001
              c 0 -19.363 35.001 -59.287 35.001 -59.287 s 35.027 39.924 35.027 59.287
              C 285.014 108.712 269.373 124.375 249.987 124.375" stroke-linecap="round"/>
          </g>
          <g transform="matrix(1 0 0 1 0 36.26)" id="Layer_1">
            <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0;
              stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;"
              transform=" translate(-250, -286.26)" d="M 308.643 110.919 H 288.75 c -1.78 4.895 -7.561 9.313 -11.06 13.031
              h 30.952 c 3.598 0 6.517 2.917 6.517 6.517 c 0 3.599 -2.918 6.515 -6.517 6.515 H 191.358 c -3.599 0 -6.517
              -2.916 -6.517 -6.515 c 0 -3.6 2.917 -6.517 6.517 -6.517 h 30.978 c -3.504 -3.718 -9.424 -8.136 -11.209 -13.031
              h -19.768 c -10.795 0 -19.548 8.752 -19.548 19.548 c 0 10.795 8.753 19.547 19.548 19.547 v 252.945 c
              0 32.388 26.255 58.642 58.643 58.642 c 32.387 0 58.643 -26.254 58.643 -58.642 V 150.014 c 10.795 0 19.548
              -8.752 19.548 -19.547 C 328.19 119.671 319.438 110.919 308.643 110.919 z M 295.611 223.35 c -2.941 -0.472
              -5.819 -1.284 -8.576 -2.419 c -12.649 -4.897 -26.674 -4.897 -39.323 0 c -9.702 3.79 -20.476 3.79 -30.177 0
              c -4.204 -1.723 -8.63 -2.85 -13.146 -3.348 v -67.568 h 91.222 L 295.611 223.35 L 295.611 223.35 z" stroke-linecap="round"/>
          </g>
        </g>
      </g>
      <g>
        <text class="play" x="${pixels}" y="40" font-family="Verdana" font-size="44" fill="rgb(177,217,151)">${title}</text>
      </g>
    </svg>`;
  }

  public getMobilabMixSoilVector(title): string {

    const len = title.toString().length;
    let pixels = 0;

    if (len === 1) {
      pixels = 8;
    } else if (len === 4) {
      pixels = 6;
    }

    return `data:image/svg+xml;utf8,
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100" height="100" viewBox="0 0 100 100">
      <g transform="matrix(0.100426, 0, 0, 0.100426, 49.999947, 73.000046)">
          <g transform="matrix(1 0 0 1 0 0)" id="Layer_1">
            <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0;
              stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(177,217,151); fill-rule: nonzero; opacity: 1;"
              transform=" translate(-250, -250)" d="M 249.992 1.06 C 112.511 1.06 1.061 112.509 1.061 250.002 c 0 137.488
              111.45 248.938 248.934 248.938 c 137.496 0 248.945 -111.45 248.945 -248.938 C 498.939 112.509 387.49 1.06 249.992 1.06"
              stroke-linecap="round"/>
          </g>
          <g transform="matrix(1 0 0 1 0 -172.77)" id="Layer_1">
            <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0;
              stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;"
              transform=" translate(-250, -77.23)" d="M 249.987 124.375 c -19.285 0 -35.001 -15.664 -35.001 -35.001 c
              0 -19.363 35.001 -59.287 35.001 -59.287 s 35.027 39.924 35.027 59.287 C 285.014 108.712 269.373 124.375
              249.987 124.375" stroke-linecap="round"/>
          </g>
          <g transform="matrix(1 0 0 1 0 36.26)" id="Layer_1">
            <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0;
              stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;"
              transform=" translate(-250, -286.26)" d="M 308.643 110.919 H 288.75 c -1.78 4.895 -7.562 9.313 -11.061 13.031
              h 30.952 c 3.598 0 6.517 2.917 6.517 6.517 c 0 3.599 -2.918 6.515 -6.517 6.515 H 191.358 c -3.599 0 -6.517
              -2.916 -6.517 -6.515 c 0 -3.6 2.917 -6.517 6.517 -6.517 h 30.979 c -3.504 -3.718 -9.424 -8.136 -11.209
              -13.031 h -19.769 c -10.795 0 -19.548 8.752 -19.548 19.548 c 0 10.795 8.753 19.547 19.548 19.547 v 252.945
              c 0 32.388 26.255 58.642 58.644 58.642 c 32.387 0 58.643 -26.254 58.643 -58.642 V 150.014 c 10.795 0 19.548
              -8.752 19.548 -19.547 C 328.19 119.671 319.438 110.919 308.643 110.919 z M 295.611 223.35 c -2.94 -0.472
              -5.818 -1.284 -8.575 -2.419 c -12.649 -4.897 -26.675 -4.897 -39.323 0 c -9.702 3.79 -20.477 3.79 -30.177 0
              c -4.204 -1.723 -8.631 -2.85 -13.146 -3.348 v -67.568 h 91.222 V 223.35 L 295.611 223.35 z" stroke-linecap="round"/>
          </g>
          <g transform="matrix(1 0 0 1 135.24 -68.12)" id="Layer_1">
            <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0;
              stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;"
              transform=" translate(-385.24, -181.88)" d="M 328.193 168.382 H 369.7 v -44.007 h 30.401 v 44.007 h 42.188
              v 27.222 h -42.188 v 43.777 H 369.7 v -43.777 h -41.508 V 168.382 z" stroke-linecap="round"/>
          </g>
      </g>
      <g>
        <text class="play" x="${pixels}" y="40" font-family="Verdana" font-size="44" fill="rgb(177,217,151)">${title}</text>
      </g>
    </svg>`;
  }

  public getMobilabMixLeafVector(title): string {

    const len = title.toString().length;
    let pixels = 0;

    if (len === 1) {
      pixels = 8;
    } else if (len === 4) {
      pixels = 6;
    }

    return `data:image/svg+xml;utf8,
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100" height="100" viewBox="0 0 100 100">
      <g transform="matrix(0.100426, 0, 0, 0.100426, 50, 72.986)">
        <g style="">
          <g transform="matrix(1 0 0 1 0 0)" id="Layer_1">
            <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0;
              stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(177,217,151); fill-rule: nonzero; opacity: 1;"
              transform=" translate(-250, -250)" d="M 249.992 1.06 C 112.511 1.06 1.06 112.509 1.06 250.002 c 0 137.488
              111.451 248.938 248.934 248.938 c 137.496 0 248.946 -111.45 248.946 -248.938 C 498.94 112.509 387.49
              1.06 249.992 1.06" stroke-linecap="round"/>
          </g>
          <g transform="matrix(1 0 0 1 111.78 31.35)" id="Layer_1">
            <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0;
              stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;"
              transform=" translate(-361.78, -281.35)" d="M 359.531 375.484 c -32.871 -0.162 -51.476 -7.408 -52.645 -10.146
              c 7.648 -1.988 14.599 -2.722 31.874 -15.441 c 28.354 -20.895 38.069 -34.875 47.138 -50.817 c 16.641 -29.287
              -1.797 -23.45 -13.653 -2.026 c -4.913 8.848 -26.029 33.596 -44.198 45.785 c -17.89 11.956 -28.049 8.669
              -28.049 8.669 c -14.888 -49.165 -2.487 -75.347 16.77 -91.375 c 21.416 -17.807 66.854 -21.528 89.807 -72.924
              C 466.789 290.934 397.006 375.661 359.531 375.484" stroke-linecap="round"/>
          </g>
          <g transform="matrix(1 0 0 1 -32.94 7.35)" id="Layer_1">
            <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0;
              stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;"
              transform=" translate(-217.06, -257.35)" d="M 274.323 60.125 h -114.52 c -10.541 0 -19.087 8.546 -19.087
              19.086 c 0 10.542 8.546 19.087 19.087 19.087 v 299.025 c 0 31.624 25.636 57.259 57.26 57.259 s 57.26
              -25.635 57.26 -57.259 V 98.298 c 10.541 0 19.089 -8.545 19.089 -19.087 C 293.41 68.67 284.864 60.125
              274.323 60.125 z M 261.599 221.947 c -2.873 -0.46 -5.684 -1.253 -8.374 -2.362 c -12.351 -4.781 -26.044
              -4.781 -38.396 0 c -9.475 3.7 -19.993 3.7 -29.467 0 c -4.104 -1.682 -8.425 -2.782 -12.835 -3.269 V 98.298
              H 261.6 L 261.599 221.947 L 261.599 221.947 z M 274.323 85.573 h -114.52 c -3.514 0 -6.363 -2.848 -6.363
              -6.362 c 0 -3.514 2.851 -6.362 6.363 -6.362 h 114.52 c 3.516 0 6.363 2.848 6.363 6.362 C 280.686 82.725
              277.836 85.573 274.323 85.573 z" stroke-linecap="round"/>
          </g>
          <g transform="matrix(1 0 0 1 99.67 -99.31)" id="Layer_1">
            <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0;
              stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;"
              transform=" translate(-349.67, -150.69)" d="M 292.618 137.192 h 41.508 V 93.185 h 30.402 v 44.007
              h 42.187 v 27.222 h -42.187 v 43.778 h -30.402 v -43.778 h -41.508 V 137.192 z" stroke-linecap="round"/>
          </g>
        </g>
      </g>
      <g>
        <text class="play" x="${pixels}" y="40" font-family="Verdana" font-size="44" fill="rgb(177,217,151)">${title}</text>
      </g>
    </svg>`;
  }

  public getMobilabLeafVector(title): string {

    const len = title.toString().length;
    let pixels = 0;

    if (len === 1) {
      pixels = 8;
    } else if (len === 4) {
      pixels = 6;
    }

    return `data:image/svg+xml;utf8,
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100" height="100" viewBox="0 0 100 100">
      <g transform="matrix(0.100426, 0, 0, 0.100426, 50, 73)">
        <g transform="matrix(1 0 0 1 0 0)" id="Layer_1">
          <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0;
            stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(177,217,151); fill-rule: nonzero; opacity: 1;"
            transform=" translate(-250, -250)" d="M 249.992 1.06 C 112.511 1.06 1.06 112.509 1.06 250.002 c 0 137.488
            111.451 248.938 248.933 248.938 c 137.497 0 248.947 -111.45 248.947 -248.938 C 498.94 112.509 387.49 1.06
            249.992 1.06" stroke-linecap="round"/>
        </g>
        <g transform="matrix(1 0 0 1 111.78 5.35)" id="Layer_1">
          <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0;
            stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;"
            transform=" translate(-361.78, -255.35)" d="M 359.531 349.484 c -32.871 -0.162 -51.476 -7.408 -52.645 -10.146
            c 7.649 -1.989 14.599 -2.722 31.874 -15.442 c 28.354 -20.894 38.07 -34.875 47.138 -50.817 c 16.641 -29.287
            -1.797 -23.45 -13.653 -2.026 c -4.913 8.848 -26.03 33.596 -44.199 45.785 c -17.889 11.956 -28.048 8.669
            -28.048 8.669 c -14.888 -49.165 -2.488 -75.347 16.769 -91.375 c 21.416 -17.807 66.854 -21.528 89.807 -72.924
            C 466.79 264.934 397.006 349.661 359.531 349.484" stroke-linecap="round"/>
        </g>
        <g transform="matrix(1 0 0 1 -32.94 7.35)" id="Layer_1">
          <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0;
            stroke-linejoin: miter; stroke-miterlimit: 4; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;"
            transform=" translate(-217.06, -257.35)" d="M 274.323 60.125 h -114.52 c -10.541 0 -19.087 8.546 -19.087
            19.086 c 0 10.542 8.546 19.087 19.087 19.087 v 299.025 c 0 31.624 25.636 57.259 57.26 57.259 s 57.26
            -25.635 57.26 -57.259 V 98.298 c 10.541 0 19.088 -8.545 19.088 -19.087 C 293.41 68.67 284.864 60.125
            274.323 60.125 z M 261.599 221.947 c -2.873 -0.46 -5.684 -1.253 -8.374 -2.362 c -12.351 -4.781 -26.044
            -4.781 -38.396 0 c -9.474 3.7 -19.993 3.7 -29.466 0 c -4.105 -1.682 -8.425 -2.782 -12.835 -3.269 V 98.298
            H 261.6 L 261.599 221.947 L 261.599 221.947 z M 274.323 85.573 h -114.52 c -3.514 0 -6.363 -2.848 -6.363
            -6.362 c 0 -3.514 2.85 -6.362 6.363 -6.362 h 114.52 c 3.515 0 6.363 2.848 6.363 6.362 C 280.686 82.725
            277.836 85.573 274.323 85.573 z" stroke-linecap="round"/>
        </g>
      </g>
      <g>
        <text class="play" x="${pixels}" y="40" font-family="Verdana" font-size="44" fill="rgb(177,217,151)">${title}</text>
      </g>
    </svg>`;
  }

  public ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }
}
