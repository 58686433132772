<div class="product-license-selector">
  <app-form-layout>
    <div class="product-license-selector__form">
      <div class="product-license-selector__title">
        <h2>{{ "Subscription" | translate }}</h2>
      </div>

      <br>

      <div class="product-license-selector__content">
        <form class="product-license-selector-form"
              [formGroup]="form"
              (ngSubmit)="handleSubmit()">

          <div class="row">
            <div class="col-md-12">
              <label>{{ 'Select a subscription' | translate }}</label>
              <app-form-select [type]="'border'"
                               [options]="getLicenseOptions()"
                               [formControlName]="'productKey'">
              </app-form-select>
            </div>
          </div>

          <br>

          <dl class="list-group" *ngIf="licenses.length === 0 && amountOfCropzones">
            <dd class="list-group-item">
              {{ "Selected cropzones" | translate }}
              <b>{{ amountOfCropzones }}</b>
            </dd>
            <dd class="list-group-item">
              {{ "Available cropzones" | translate }}
            <b>0</b>
            </dd>
          </dl>

          <dl class="list-group" *ngIf="licenses.length > 0 && getSelectedOption() as selectedLicense">
            <dd class="list-group-item"
                *ngIf="selectedLicense.nm">
              {{ "Available stations" | translate }}
              <b>{{ selectedLicense.amount - selectedLicense.nm.length }}</b>
            </dd>

            <dd class="list-group-item" *ngIf="amountOfCropzones">
              {{ "Selected cropzones" | translate }}
              <b>{{ amountOfCropzones }}</b>
            </dd>

            <dd class="list-group-item"
                *ngIf="selectedLicense.cropzones">
              {{ "Available cropzones" | translate }}
              <b>{{ selectedLicense.amount - selectedLicense.cropzones.length }}</b>
            </dd>

            <dd class="list-group-item">
              {{ "Valid until" | translate }}
              <b>{{ selectedLicense.end_date | date: 'yyyy-MM-dd HH:mm' }}</b>
            </dd>
          </dl>

          <br>

          <div>
            <app-product-license-duplication-text *ngIf="amountOfCropzones" [selectedLicense]="getSelectedOption()" [amountOfCropzones]="amountOfCropzones"></app-product-license-duplication-text>
            <br>
          </div>

          <div class="product-license-selector-form__submit">
            <app-custom-button *ngIf="!amountOfCropzones"
                               [content]="'Cancel'"
                               [type]="'button'"
                               (click)="handleCancel()"></app-custom-button>
            <app-custom-button *ngIf="!amountOfCropzones"
                               [disabled]="form.invalid"
                               [color]="'green'"
                               [content]="'Confirm'"></app-custom-button>
            <app-custom-button *ngIf="amountOfCropzones" [content]="'Cancel'"
                               [type]="'button'"
                               (click)="handleCancel()"
                               [color]="'red'"></app-custom-button>
            <app-custom-button *ngIf="amountOfCropzones && getSelectedOption() as selectedLicense"
                               [disabled]="form.invalid || selectedLicense.remaining < amountOfCropzones"
                               [color]="'green'"
                               [content]="'Confirm'"></app-custom-button>
          </div>
        </form>
      </div>
    </div>
  </app-form-layout>
</div>
