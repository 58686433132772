<app-form-layout>
  <div class="hydroponics">

    <div class="hydroponics__body">
      <div [formGroup]="form"
           class="hydroponics__form">

        <div class="hydroponics__group">
          <div class="col-sm-6">
            <label>{{ 'Flux rate - per each dripper' | translate }} (l/h)</label>
            <app-form-input [contentType]="'number'"
                            [formControl]="flowDripper">
            </app-form-input>
          </div>
          <div class="col-sm-6">
            <label>{{ 'N° drippers' | translate }}</label>
            <app-form-input [contentType]="'number'"
                            [formControl]="numberDripper">
            </app-form-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-form-layout>

<div class="hydroponics__item">
  <app-hydroponics-table *ngIf="response"
                         [response]="response">
  </app-hydroponics-table>
</div>

<ng-container class="hydroponics__item"
     *ngIf="!(isLoading$ | async) && !(isError$ | async); else loadinfo">
  <div class="hydroponics__item">
    <app-hydroponics-charts [response]="response">
    </app-hydroponics-charts>
  </div>
  <div class="hydroponics__item">
    <app-hydroponics-table-data [response]="response">
    </app-hydroponics-table-data>
  </div>
</ng-container>

<ng-template #loadinfo>
<app-station-load-info [isError]="isError$ | async"
                       [isLoading]="isLoading$ | async">
</app-station-load-info>
</ng-template>

