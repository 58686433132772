import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { YieldPredictionConfigComponent } from './yield-prediction-config.component';

const routes: Routes = [
  { path: '', component: YieldPredictionConfigComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class YieldPredictionConfigRoutingModule {
}
