import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-weather-forecast-info',
  templateUrl: './weather-forecast-info.component.html',
  styleUrls: ['./weather-forecast-info.component.scss']
})
export class WeatherForecastInfoComponent implements OnInit {
  @Input()
  public isError: boolean = false;
  @Input()
  public isLoading: boolean = false;
  @Input()
  public infoText: string = 'The lack of measured data can compromise data quality of the Weather Forecast.';

  constructor() { }

  public ngOnInit() : void {
  }

}
