<app-form-layout class="archived-settings"
                 [hidden]="ultrasonicSettings.length <= 1">
  <ng-container>
    <app-card-header class="archived-settings__header"
                     [header]="'Archived settings'">
    </app-card-header>

    <div>
      <table class="archived-settings__table">
        <tbody class="archived-settings__table__body">
        <tr>
          <th>{{ 'Time period' | translate }}</th>
          <th>{{ 'Elevation' | translate }}</th>
        </tr>
        <tr *ngFor="let setting of ultrasonicSettings | slice: 1:25">
          <td>{{ setting.from | date: 'dd-MM-yyyy' }} - {{ setting.to | date: 'dd-MM-yyyy' }}</td>
          <td>{{ setting.elevationValue }} {{ setting.unit }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</app-form-layout>
