<form [formGroup]="form" class="widget-settings-form" *ngIf="stations$ | async">
  <section class="flex-row-reverse space-between">
    <section>
      <app-custom-dropdown [formControl]="station" class="widget-settings-form__dropdown"
        [options]="stations$ | async | trackStation | stationsOptions">
      </app-custom-dropdown>

      <app-custom-dropdown [formControl]="periodScope" class="widget-settings-form__dropdown"
        [options]="periodScopeOptions">
      </app-custom-dropdown>
    </section>

    <app-custom-button (click)="submit()" [content]="'Refresh' | translate" [color]="'red'">
      <span><i class="fa fa-fw fa-refresh"></i></span>
    </app-custom-button>
  </section>
</form>