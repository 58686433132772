import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LicenseType } from '../../../../modules/product-activation/constants/constants';
import {
  addCropZone,
  addCropZoneField,
  addCropZoneFieldFarm, setExecutingAddCropzone
} from '../../../actions/cropzones';
import { addFarm } from '../../../actions/farms';
import { addField } from '../../../actions/fields';
import { setNotify } from '../../../actions/notify';
import {
  IAddCropZone, IAddCropZoneField, IAddCropZoneFieldFarm, IAddCropZoneRequest,
  IAddCropZoneType, ICropZone
} from '../../../models/cropzones';
import { IAddFarm } from '../../../models/farms';
import { IAddField } from '../../../models/fields';
import { ISelectedLicense } from '../../../models/licenses';
import { selectExecutingAddCropZone, selectFarms, selectSelectedCropZone } from '../../../reducers';
import * as fromCropzones from '../../../reducers/cropzones';
import * as fromFarms from '../../../reducers/farms';
import * as fromFields from '../../../reducers/fields';
import * as fromNotify from '../../../reducers/notify';
import { NavigationService } from '../../../services/navigation/navigation.service';

@Component({
  selector: 'app-add-cropzone',
  templateUrl: './add-cropzone.component.html',
  styleUrls: ['./add-cropzone.component.scss']
})
export class AddCropZoneComponent implements OnInit, OnDestroy {

  public licenseType = LicenseType.FARM_VIEW;

  public isAddCropzone: boolean = true;
  public isAddField: boolean = false;
  public isAddFarm: boolean = false;
  public isLicensing: boolean = false;
  public licensingPayload: IAddCropZoneRequest;
  public newAddedCropzone: ICropZone;
  public isExecuting$: Observable<boolean>;
  private alive$ = new Subject<boolean>();

  constructor(
    private CZstore: Store<fromCropzones.ICropZones>,
    private FarmStore: Store<fromFarms.IFarms>,
    private FieldStore: Store<fromFields.IFields>,
    private navigationService: NavigationService,
    private notifyStore: Store<fromNotify.INotifyState>
  ) {}

  public addCropzone(): void {
    this.isAddCropzone = true;
    this.isAddField = false;
    this.isAddFarm = false;
    this.isLicensing = false;
  }

  public addField(): void {
    this.isAddCropzone = false;
    this.isAddField = true;
    this.isAddFarm = false;
    this.isLicensing = false;
  }

  public addFarm(): void {
    this.isAddCropzone = false;
    this.isAddField = false;
    this.isAddFarm = true;
    this.isLicensing = false;
  }

  public enableLicensing(event: IAddCropZoneRequest): void {
    this.isLicensing = true;
    this.licensingPayload = event;
  }

  public disableLicensing(): void {
    this.isLicensing = false;
    this.licensingPayload = null;
  }

  public submitLicensing(event: ISelectedLicense): void {
    if (this.isLicensing && event.productKey) {
      this.isLicensing = false;
      this.submitCropzone({
        ...this.licensingPayload,
        product_key: event.productKey
      });
    } else {
      this.notifyStore.dispatch(setNotify('No license was selected'));
    }
  }

  public submitCropzone(event: IAddCropZoneRequest): void {
    this.CZstore.dispatch(setExecutingAddCropzone(true));
    if (event.type === IAddCropZoneType.ADD_CROPZONE_AND_FIELD_AND_FARM) {
      this.CZstore.dispatch(addCropZoneFieldFarm(<IAddCropZoneFieldFarm>event, true));
    } else if (event.type === IAddCropZoneType.ADD_CROPZONE_AND_FIELD) {
      this.CZstore.dispatch(addCropZoneField(<IAddCropZoneField>event, true));
    } else {
      this.CZstore.dispatch(addCropZone(<IAddCropZone>event, true));
      this.CZstore.pipe(
        select(selectSelectedCropZone),
        takeUntil(this.alive$)
      ).subscribe((cropzone) => this.newAddedCropzone = cropzone);
    }
  }

  public submitField(event: IAddField): void {
    this.FieldStore.dispatch(addField(event));
  }

  public submitFarm(event: IAddFarm): void {

    this.FarmStore.pipe(
      select(selectFarms),
      takeUntil(this.alive$)
    ).subscribe(farms => {

      const farm = farms.filter(data =>
        data.name.toLowerCase() === event.name.toLowerCase()
      );

      if (farm && farm.length) {
        this.notifyStore.dispatch(
          setNotify('Unable to create the farm. There is already a farm with the same name.')
        );
      } else {
        this.FarmStore.dispatch(addFarm(event));
      }

    });
  }

  public ngOnInit(): void {
    this.isExecuting$ = this.CZstore.pipe(
      takeUntil(this.alive$),
      select(selectExecutingAddCropZone)
    );
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }
}
