import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { YieldConfigHelpTextModalComponent } from './containers/yield-config-help-text-modal/yield-config-help-text-modal.component';
import { YieldDataComponent } from './containers/yield-data/yield-data.component';
import { YieldConfigEffect } from './effects/yield-config.effect';
import { reducers } from './reducers';
import { YieldPredictionConfigRoutingModule } from './yield-prediction-config-routing.module';
import { YieldPredictionConfigComponent } from './yield-prediction-config.component';
import { YieldSliderComponent } from './containers/yield-slider/yield-slider.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    YieldPredictionConfigRoutingModule,
    TranslateModule.forChild(),
    StoreModule.forFeature('yield-config', reducers),
    EffectsModule.forFeature([YieldConfigEffect])
  ],
  declarations: [
      YieldPredictionConfigComponent,
      YieldDataComponent,
      YieldConfigHelpTextModalComponent,
      YieldSliderComponent
  ]
})
export class YieldPredictionConfigModule {
}
