import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ISatState, ILai } from '../models';

const featureSelector = createFeatureSelector<ISatState>('cropZoneSat');

export const getVisibleLaiDatesFromCropZoneId = (id: string) => createSelector(
    featureSelector,
    (state: ISatState): any[] => {
        if (state.lai.visibleLaiDates[id]) {
            return state.lai.visibleLaiDates[id];
        } else {
            return [];
        }
    }
);

export const getVisibleNdviDatesFromCropZoneId = (id: string) => createSelector(
    featureSelector,
    (state: ISatState): any[] => {
        if (state.ndvi.visibleNdviDates[id]) {
            return state.ndvi.visibleNdviDates[id];
        } else {
            return [];
        }
    }
);

export const laiSelector = createSelector(
    featureSelector,
    (state: ISatState): {[key: string]: any[]} => {
        return state.lai.laiStats;
    }
);

export const ndviSelector = createSelector(
    featureSelector,
    (state: ISatState): {[key: string]: any[]} => {
        return state.ndvi.ndviStats;
    }
);

export const visibleLaiValSelector = createSelector(
    featureSelector,
    (state: ISatState): any => {
        if (state.lai.visibleLaiVals) {
            return state.lai.visibleLaiVals;
        } else {
            return [];
        }
    }
);

export const visibleNdviValSelector = createSelector(
    featureSelector,
    (state: ISatState): any => {
        if (state.ndvi.visibleNdviVals) {
            return state.ndvi.visibleNdviVals;
        } else {
            return [];
        }
    }
);

export const fittingCurve = createSelector(
    featureSelector,
    (state: ISatState): any => {
        return state.lai.fittingCurve;
    }
);

export const fittingCurve_ndvi = createSelector(
    featureSelector,
    (state: ISatState): any => {
        return state.ndvi.fittingCurve;
    }
);

export const laiRes10Selector = createSelector(
    featureSelector,
    (state: ISatState) => state.lai.detailedLaiVal
);

export const ndviRes10Selector = createSelector(
    featureSelector,
    (state: ISatState) => state.ndvi.detailedNdviVal
);

export const noDataErrorSelector = createSelector(
    featureSelector,
    (state: ISatState) => state.lai.noDataError
);

export const noNdviDataErrorSelector = createSelector(
    featureSelector,
    (state: ISatState) => state.ndvi.noDataError
);

export const amountOfColumnsSelector = createSelector(
    featureSelector,
    (state: ISatState) => state.amountOfColumns
);

export const selectSatWarning = createSelector(
    featureSelector,
    (state: ISatState) => state.warning
);
