import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { setNotify } from '../../../../core/actions/notify';
import { ICropZone } from '../../../../core/models/cropzones';
import { selectSelectedCropZone, selectUserData } from '../../../../core/reducers';
import { IAccount } from '../../../../core/reducers/account';
import * as fromNotify from '../../../../core/reducers/notify';
import * as fromSelectedCropzone from '../../../../core/reducers/selectedCropZone';
import { ApiCallService } from '../../../../services/api/api-call.service';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { dateToUtcUnixTimestamp } from '../../../../shared/utils/dateFormat';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { cropTypeOptions } from '../../../yield-prediction-config/constants/config';
import { yieldUnitTypes } from '../../../yield-prediction/constants/config';
import { IPreviousYieldRequest, IPreviousYieldValues } from '../../models/model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-yield-previous-data',
  templateUrl: './yield-previous-data.component.html',
  styleUrls: ['./yield-previous-data.component.scss']
})
export class YieldPreviousDataComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public cropTypeOptions = cropTypeOptions;
  public maximumDate: Date = new Date(new Date().getFullYear() + 1, 11, 31);
  public unitSystem: string = '';
  private alive$: Subject<boolean> = new Subject<boolean>();
  public cropzone: ICropZone = null;
  public previousYields: any = null;
  private translatedValues: IPreviousYieldValues;
  public yieldSource: any = null;
  public yieldUnit: any = null;
  public modalId: string = generateId();
  public chartData: any;

  constructor(
    private fb: FormBuilder,
    private userStore: Store<IAccount>,
    private apiCallService: ApiCallService,
    private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private notifyStore: Store<fromNotify.INotifyState>,
    private modalService: ModalService,
    private route: ActivatedRoute
  ) { }

  public get cropTypeControl(): AbstractControl {
    return this.form.get('cropType');
  }
  public get fromDatepickerControl(): AbstractControl {
    return this.form.get('fromDatepicker');
  }
  public get toDatepickerControl(): AbstractControl {
    return this.form.get('toDatepicker');
  }
  public get yieldControl(): AbstractControl {
    return this.form.get('yield');
  }

  public get cropzoneControl(): AbstractControl {
    return this.form.get('cropzone');
  }

  public ngOnInit() : void {
    this.translatedValues = this.route?.snapshot?.data['previousYieldResolver'];

    this.form = this.fb.group({
      'cropType': ['', [Validators.required]],
      'fromDatepicker': ['', [Validators.required]],
      'toDatepicker': ['', [Validators.required]],
      'yield': ['', [Validators.required]],
      'cropzone': [''],
    });

    this.userStore.pipe(
      select(selectUserData),
      takeUntil(this.alive$),
      filter((user: IAccount) => !!user)
    ).subscribe((user: IAccount) => {
      if (user.settings !== null) {
        this.unitSystem = user.settings.unit_system;
      }
    });

    this.selectedCropzoneStore.pipe(
      select(selectSelectedCropZone),
      takeUntil(this.alive$)
    ).subscribe((cropzone) => {
      this.yieldSource = null;
      this.cropzone = cropzone;
      this.cropzoneControl.setValue(cropzone);
      this.cropzone.data_sources.forEach(datasource => {
        if (datasource.module === 'YIELD_PREDICTION') {
          this.yieldSource = datasource;
          this.yieldUnit = this.yieldSource.yield_prediction.unit;
          if (yieldUnitTypes(this.yieldUnit) !== null) {
            this.unitSystem = yieldUnitTypes(this.yieldUnit);
          }
        }
      });
    });

    this.cropzoneControl.valueChanges.subscribe(changes => {
      if (changes.id !== this.form.value['cropzone'].id) {
        this.getPreviousYields();
      }
    });

    this.getPreviousYields();
  }

  public validateDecimal(event) : void {
    const value = event.target.value;
    this.yieldControl.setValue(Number(Math.abs(value * 100) / 100).toFixed(2));
  }

  public translateString(string: string) : string {
    return this.translatedValues[string[0].toUpperCase() + string.slice(1)];
  }

  public onSave() : void {
    if (this.form.invalid) {
      this.notifyStore.dispatch(setNotify('Previous yield could not be saved, please fill out all fields.'));
      return;
    }

    const createRequest : IPreviousYieldRequest = {
      sowing_date: dateToUtcUnixTimestamp(this.fromDatepickerControl.value),
      harvest_date: dateToUtcUnixTimestamp(this.toDatepickerControl.value),
      field_name: this.cropzone.field.name,
      crop: this.cropTypeControl.value,
      crop_id: this.cropzone.id,
      value: Number(this.yieldControl.value),
    };

    this.apiCallService.createPreviousYield(createRequest).pipe(
      takeUntil(this.alive$),
    ).subscribe(response => {
      this.notifyStore.dispatch(setNotify('Previous Yield is successfully saved.'));
      this.getPreviousYields();
    });
  }

  public getPreviousYields() : void {
    this.getPreviousYieldPredictionModel();
    this.apiCallService.getPreviousYields(this.cropzone.id).subscribe(response => {
      this.previousYields = response;
    });
  }

  public getPreviousYieldPredictionModel() : void {
    this.apiCallService.getPreviousYieldPredictionModel(this.cropzone.id).subscribe(response => {
      this.chartData = response;
    });
  }

  public openModal(): void {
    this.modalService.openModal(this.modalId);
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }

}
