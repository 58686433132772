import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IHydroponicsState } from '../models/models';

export const hydroponicsSelector = createFeatureSelector<IHydroponicsState>('hydroponics');

export const hydroponicsData = createSelector(
  hydroponicsSelector,
  (state: IHydroponicsState) => state
);

export const hydroponicsPeriod = createSelector(
  hydroponicsSelector,
  (state: IHydroponicsState) => state.period
);

export const hydroponicsTree = createSelector(
  hydroponicsSelector,
  (state: IHydroponicsState) => state.tree
);

export const hydroponicsTreeNodes = createSelector(
  hydroponicsSelector,
  (state: IHydroponicsState) => state.tree[0].nodes
);

export const hydroponicsIsDrainageVisible = createSelector(
  hydroponicsSelector,
  (state: IHydroponicsState) => state.visibility.drainage
);

export const hydroponicsIsWeightVisible = createSelector(
  hydroponicsSelector,
  (state: IHydroponicsState) => state.visibility.weight
);

export const selectHydroponicsLoadError = createSelector(
  hydroponicsSelector,
  (state: IHydroponicsState) => state.dataLoading.isError
);

export const selectHydroponicsLoadProcess = createSelector(
  hydroponicsSelector,
  (state: IHydroponicsState) => state.dataLoading.isLoading
);

export const hydroponicsActivity = createSelector(
  hydroponicsSelector,
  (state: IHydroponicsState) => state.activity
);

export const selectHydroponicsChartActive = createSelector(
  hydroponicsSelector,
  (state: IHydroponicsState) => state.activity.isChartActive
);

export const selectHydroponicsTableActive = createSelector(
  hydroponicsSelector,
  (state: IHydroponicsState) => state.activity.isTableActive
);

export const selectHydroponicsCharts = createSelector(
  hydroponicsSelector,
  (state: IHydroponicsState) => state.charts
);

export const selectHydroponicsVisibility = createSelector(
  hydroponicsSelector,
  (state: IHydroponicsState) => state.visibility
);


