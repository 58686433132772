import {Pipe, PipeTransform} from '@angular/core';
import {IStation} from '../../../../core/models/stations';
import {findDisease} from '../../utils/findStations';

@Pipe({
  name: 'diseaseStations'
})
export class DiseaseStationsPipe implements PipeTransform {

  public transform(stations: Array<IStation>): Array<IStation> {
    return Array.isArray(stations) ? findDisease(stations) : [];
  }

}
