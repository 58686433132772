import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { area, circle, kinks, polygon, rewind } from '@turf/turf';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { setNotify } from '../../../../core/actions/notify';
import { ICropZone } from '../../../../core/models/cropzones';
import { IPosition } from '../../../../core/models/stations';
import { selectSelectedCropZone } from '../../../../core/reducers';
import * as fromNotify from '../../../../core/reducers/notify';
import * as fromSelectedCropzone from '../../../../core/reducers/selectedCropZone';
import { MAX_SURFACE_AREA_POLYGON } from '../../../../shared/constants';
import { setLocationSettings } from '../../actions/timezone-location';
import { DefaultCropzonePosition } from '../../constants/constants';
import { selectLocation } from '../../reducers';
import * as fromTimezoneAndLocation from '../../reducers/timezone-location';

declare const google: any;

@Component({
  selector: 'app-cropzone-config-map',
  templateUrl: './cropzone-config-map.component.html',
  styleUrls: ['./cropzone-config-map.component.scss'],
})
export class CropzoneConfigMapComponent implements OnInit, OnDestroy {
  public map: any;
  public drawingManager: any;
  public drawfieldOnMapActivated: boolean;
  private alive$ = new Subject<boolean>();
  public center: any = DefaultCropzonePosition;
  public selectedCropzone: ICropZone;
  public zoom: number = 10;
  public savedPolygon: any;
  public shape: string;
  private previousId: any;
  private allPolygons;
  private fillColor: string = 'red';
  private strokeColor: string = 'red';
  private selectedColor: string = 'black';

  @Input()
  public set oldBoundaries(data) {
    if (data) {
      this.updateBoundaries(data);
    } else {
      this.removePolygons();
    }
  }

  @Input()
  public set buttonClicked(event: { button: string, boundary?: any }) {
    if (event) {
      if (event.button === 'delete') {
        this.removePolygons();
      } else if (event.button === 'draw') {
        this.setDrawingMode('polygon', true);
      } else if (event.button === 'edit') {
        this.editPolygon();
      } else if (event.button === 'store') {
        this.removePolygons();

        const coordinates = event.boundary.coordinates[0].map(
          (coord) => {
            return { lat: coord[1], lng: coord[0] };
          }
        );
        const createdPolygon = this.createPolygon(coordinates);
        this.savedPolygon = createdPolygon;
        createdPolygon.setMap(this.map);
      }
    }
  }

  @Input()
  public set rebound(boundary) {
    if (boundary) {
      const bounds = new google.maps.LatLngBounds();

      const coordinates = boundary.coordinates[0].map(
        (coord) => {
          return { lat: coord[1], lng: coord[0] };
        }
      );

      const createdPolygon = this.createPolygon(coordinates);

      createdPolygon.getPath().forEach(function (element): void {
        bounds.extend(element);
      });

      this.map.fitBounds(bounds);
    }
  }

  @Input()
  public set sendSelectedPolygon(data) {
    if (data) {
      const allPolygonBounds = [];
      const bounds = new google.maps.LatLngBounds();

      this.allPolygons.forEach((poly, i) => {
        const geo = rewind(this.convertToGeoJson(this.allPolygons[i]));
        if (JSON.stringify(geo) !== JSON.stringify(data)) {
          this.allPolygons[i].setMap(null);
        } else {

          this.allPolygons[i].getPath().forEach(function (element): void {
            bounds.extend(element, allPolygonBounds);
          });

          this.allPolygons[i].setMap(this.map);
        }
      });

      this.map.fitBounds(bounds);
    }
  }

  @Output()
  public coordinatesFromCreatedPolygon: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public setStoreButtonActive: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private locationStore: Store<fromTimezoneAndLocation.ITimezoneAndLocationState>,
    private notifyStore: Store<fromNotify.INotifyState>
  ) { }

  public setDrawingMode(data: string, activated: boolean): void {
    if (this.drawingManager) {
      if (activated && data === 'polygon') {
        this.drawingManager.setOptions({
          drawingControl: true,
          drawingMode: google.maps.drawing.OverlayType.POLYGON,
        });
      } else if (activated && data === 'circle') {
        this.drawingManager.setOptions({
          drawingControl: true,
          drawingMode: google.maps.drawing.OverlayType.CIRCLE,
        });
      }
    }
  }

  public onMapReady(map): void {
    this.map = map;
    this.map.mapTypeControlOptions = {
      position: google.maps.ControlPosition.LEFT_BOTTOM,
    };
    this.initDrawingManager(map);

    this.drawingManager.setOptions({
      drawingControl: false,
      drawingMode: null,
    });

    this.locationStore
      .pipe(
        select(selectLocation),
        filter((location) => !!location),
        takeUntil(this.alive$)
      )
      .subscribe((location) => {
        this.center = {
          lat: location.position.geo.coordinates[0],
          lng: location.position.geo.coordinates[1],
        };
        this.zoom = 18;
      });

    this.selectedCropzoneStore
      .pipe(
        select(selectSelectedCropZone),
        filter((cropzone) => !!cropzone),
        takeUntil(this.alive$),
        distinctUntilChanged((prev, cur) => prev.id === cur.id)
      ).subscribe((cropzone) => {
        if (JSON.stringify(cropzone) !== JSON.stringify(this.selectedCropzone)) {
          this.selectedCropzone = cropzone;
          this.removePolygons();

          if (!cropzone.boundary) {
            this.setDefaultPosition();
            if (navigator.geolocation) {
              this.getCurrentPosition();
            }
          } else {
            this.initPolygon();
          }
        }
      });
  }

  public ngOnInit(): void { }

  public getCurrentPosition(): void {
    navigator.geolocation.getCurrentPosition((position) => {
      const currentPosition: IPosition = {
        altitude: position.coords.altitude,
        geo: {
          coordinates: [position.coords.latitude, position.coords.longitude],
        },
      };
      this.center.lat = position.coords.latitude;
      this.center.lng = position.coords.longitude;
      this.locationStore.dispatch(setLocationSettings(currentPosition));
    });
  }

  public setDefaultPosition(): void {
    this.center.lat = DefaultCropzonePosition.geo[0];
    this.center.lng = DefaultCropzonePosition.geo[1];
    this.locationStore.dispatch(setLocationSettings(DefaultCropzonePosition));
  }

  public updateMapCenter(): void {
    this.center = {
      latitude: this.center.lat,
      longitude: this.center.lng,
    };
  }

  private convertToGeoJson(polygonToConvert): any {
    const geoJSON = {
      type: 'Polygon',
      coordinates: []
    };
    const paths = polygonToConvert.getPaths().getArray();

    paths.forEach(path => {
      const pathArray = [];
      const points = path.getArray();

      points.forEach(point => {
        pathArray.push([point.lng(), point.lat()]);
      });

      geoJSON.coordinates.push(pathArray);
    });

    return geoJSON;
  }

  private addListenersOnPolygon(drawnPolygon, savedPolygons): void {
    const self = this;
    google.maps.event.addListener(drawnPolygon, 'click', function (event): void {
      let allowedToSend = true;

      if (self.previousId !== undefined) {
        if (self.previousId === drawnPolygon.id) {
          if (savedPolygons[drawnPolygon.id].fillColor === self.selectedColor) {
            savedPolygons[drawnPolygon.id].setOptions({ fillColor: self.fillColor });
            self.setStoreButtonActive.emit(false);
            allowedToSend = false;
          } else if (savedPolygons[drawnPolygon.id].fillColor === self.fillColor) {
            savedPolygons[drawnPolygon.id].setOptions({ fillColor: self.selectedColor });
            self.setStoreButtonActive.emit(true);
            allowedToSend = true;
          }
        } else {
          savedPolygons[drawnPolygon.id].setOptions({ fillColor: self.selectedColor });
          savedPolygons[self.previousId].setOptions({ fillColor: self.fillColor });
          self.setStoreButtonActive.emit(true);
          allowedToSend = true;
        }
      } else {
        savedPolygons[drawnPolygon.id].setOptions({ fillColor: self.selectedColor });
        self.setStoreButtonActive.emit(true);
        allowedToSend = true;
      }

      if (allowedToSend) {
        const geoJsonData = self.convertToGeoJson(savedPolygons[drawnPolygon.id]);
        this.savedPolygon = savedPolygons[drawnPolygon.id];
        const rewindData = rewind(geoJsonData);
        self.sendCoordinates(rewindData);
      }
      self.previousId = drawnPolygon.id;
    });
  }

  public updateBoundaries(data): void {
    if (data) {
      const polygons = [];
      data.features.forEach(feature => {
        const polygonCoordinates = feature.geometry.coordinates[0].map((coord) => ({lat: coord[1], lng: coord[0]}));
        polygons.push(polygonCoordinates);
      });

      const savedPolygons = [];
      if (this.map) {
        const bounds = new google.maps.LatLngBounds();

        polygons.forEach((polygonObject, index) => {
          const savedPolygon = this.createPolygon(polygonObject, index);
          savedPolygons.push(savedPolygon);

          savedPolygon.getPath().forEach(function (element): void {
            bounds.extend(element, savedPolygons);
          });

          if (data.features.length === 1) {
            const geoJsonData = this.convertToGeoJson(savedPolygon);
            const rewindData = rewind(geoJsonData);
            this.savedPolygon = savedPolygon;

            this.savedPolygon.setOptions({fillColor: this.fillColor});
            this.sendCoordinates(rewindData);
          } else {
            this.previousId = undefined;
            this.addListenersOnPolygon(savedPolygon, savedPolygons);
          }
          savedPolygon.setMap(this.map);
        });

        this.allPolygons = savedPolygons;
        this.map.fitBounds(bounds);
      }
    }
  }

  public initPolygon(): void {
    const coords = this.selectedCropzone.boundary.coordinates[0];
    const lengthOfArray: number = coords.length;
    const startPoint = 0;
    const tirth = Math.round(lengthOfArray / 3);
    const twoTirth = Math.round((lengthOfArray / 3) * 2);
    const coordsArray = [coords[startPoint], coords[tirth], coords[twoTirth]];
    const distances = [];

    const allEqual = (arr) =>
      arr.every((val) => val.toFixed(5) === arr[0].toFixed(5));

    const coordinates = this.selectedCropzone.boundary.coordinates[0].map(
      (coord) => {
        return { lat: coord[1], lng: coord[0] };
      }
    );

    let bounds = new google.maps.LatLngBounds();
    const savedPolygons = [];
    if (!this.savedPolygon) {
      this.savedPolygon = this.createPolygon(coordinates);

      this.savedPolygon.getPath().forEach(function (element): void {
        bounds.extend(element);
      });

      coordsArray.forEach((coord) => {
        const distance = google.maps.geometry.spherical.computeDistanceBetween(
          new google.maps.LatLng({
            lat: coord[1],
            lng: coord[0],
          }),
          new google.maps.LatLng({
            lat: bounds.getCenter().lat(),
            lng: bounds.getCenter().lng(),
          })
        );

        distances.push(distance);
      });

      this.shape = 'polygon';

      if (allEqual(distances)) {
        this.savedPolygon = new google.maps.Circle({
          center: bounds.getCenter(),
          radius: distances[0],
          editable: false,
          strokeColor: this.strokeColor,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: this.fillColor,
          fillOpacity: 0.35,
        });

        this.shape = 'circle';
      }
    }

    if (allEqual(distances) && this.savedPolygon) {
      bounds = this.savedPolygon.getBounds();
    } else {
      this.savedPolygon.getPath().forEach(function (element): void {
        bounds.extend(element, savedPolygons);
      });
    }

    this.center = {
      latitude: bounds.getCenter().lat(),
      longitude: bounds.getCenter().lng(),
    };

    this.savedPolygon.setMap(this.map);
    this.map.fitBounds(bounds);
  }

  public initDrawingManager(map: any): void {
    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['polygon', 'circle'],
      },
      polygonOptions: {
        draggable: false,
        editable: true,
        fillColor: this.fillColor,
        strokeColor: this.strokeColor,
      },
      circleOptions: {
        draggable: false,
        editable: false,
        fillColor: this.fillColor,
        strokeColor: this.strokeColor,
      },
      markerOptions: {
        draggable: true,
      },
      polylineOptions: {
        editable: true,
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
    };

    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);

    google.maps.event.addListener(
      this.drawingManager,
      'polygoncomplete',
      (polygonData) => {
        const vertices = polygonData
          .getPaths()
          .getArray()
          .map((ring) =>
            ring.getArray().map((vertex) => [vertex.lng(), vertex.lat()])
          );

        vertices[0].push(vertices[0][0]);

        const polygonObject = polygon([vertices[0]]);
        const rewindData = rewind(polygonObject);
        const polygonArea = area(polygonObject);
        const kinksObject = kinks(polygonObject);

        this.savedPolygon = polygonData;

        if (kinksObject.features.length !== 0) {
          this.removePolygons();
          this.drawingManager.setOptions({
            drawingControl: true,
          });
          this.setDrawingMode('polygon', true);
          this.notifyStore.dispatch(
            setNotify(
              'Intersections are not allowed when drawing the cropzone boundaries.'
            )
          );
        } else {
          if (polygonArea / 1000000 <= MAX_SURFACE_AREA_POLYGON) {
            this.sendCoordinates(rewindData.geometry);
            this.drawingManager.setOptions({
              drawingControl: false,
              drawingMode: null,
            });
            this.shape = 'polygon';
          } else {
            this.removePolygons();
            this.notifyStore.dispatch(
              setNotify(
                'The surface area of the boundaries are too big, must be under 25km².'
              )
            );
            this.setDrawingMode('polygon', true);
          }
        }
      }
    );

    google.maps.event.addListener(
      this.drawingManager,
      'overlaycomplete',
      (event) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          const paths = event.overlay.getPaths();
          for (let p = 0; p < paths.getLength(); p++) {
            google.maps.event.addListener(paths.getAt(p), 'set_at', () => {
              if (!event.overlay.drag) {
                this.updatePointList(event.overlay.getPath());
              }
            });
            google.maps.event.addListener(paths.getAt(p), 'insert_at', () => {
              this.updatePointList(event.overlay.getPath());
            });
            google.maps.event.addListener(paths.getAt(p), 'remove_at', () => {
              this.updatePointList(event.overlay.getPath());
            });
          }
          this.updatePointList(event.overlay.getPath());
        }
      }
    );

    google.maps.event.addListener(
      this.drawingManager,
      'circlecomplete',
      (circleData) => {
        const circleObject = circle(
          [circleData.getCenter().lng(), circleData.getCenter().lat()],
          circleData.getRadius() / 1000
        );

        this.savedPolygon = circleData;

        const circleArea =
          Math.PI * (circleData.getRadius() * circleData.getRadius());

        if (circleArea / 1000000 <= MAX_SURFACE_AREA_POLYGON) {
          this.sendCoordinates(circleObject.geometry);
          this.drawingManager.setOptions({
            drawingControl: false,
            drawingMode: null,
          });

          this.shape = 'circle';
        } else {
          this.notifyStore.dispatch(
            setNotify(
              'The surface area of the boundaries are too big, must be under 25km².'
            )
          );
          this.removePolygons();
          this.setDrawingMode('circle', true);
        }
      }
    );
  }

  public updatePointList(path): any {
    const pointList = [];
    const len = path.getLength();

    for (let i = 0; i < len; i++) {
      pointList.push(path.getAt(i).toJSON());
    }

    const coords = [[]];
    for (let i = 0; i < pointList.length; i++) {
      coords[0].push([pointList[i]['lng'], pointList[i]['lat']]);
    }
    coords[0].push([pointList[0]['lng'], pointList[0]['lat']]);

    const polygonObject = polygon([coords[0]]);
    const rewindData = rewind(polygonObject);
    const polygonArea = area(polygonObject);
    const kinksObject = kinks(polygonObject);

    if (kinksObject.features.length !== 0) {
      this.removePolygons();
      this.notifyStore.dispatch(setNotify('Boundaries are not allowed to have self-intersecting points.'));
    } else if (polygonArea / 1000000 <= MAX_SURFACE_AREA_POLYGON) {
      this.sendCoordinates(rewindData.geometry);
      this.drawingManager.setOptions({
        drawingControl: false,
        drawingMode: null,
      });
    }
  }

  public sendCoordinates(array): void {
    if (array) {
      this.coordinatesFromCreatedPolygon.emit(array);
    }
  }

  public sendFromEdit(): void {
    const vertices = this.savedPolygon
      .getPaths()
      .getArray()
      .map((ring) =>
        ring.getArray().map((vertex) => [vertex.lng(), vertex.lat()])
      );

    if (vertices[0][0] !== vertices[0][vertices[0].length]) {
      vertices[0].push(vertices[0][0]);
    }

    const polygonObject = polygon([vertices[0]]);
    const rewindData = rewind(polygonObject);
    const areaPolygon = area(polygonObject);

    if (areaPolygon / 1000000 <= MAX_SURFACE_AREA_POLYGON) {
      this.sendCoordinates(rewindData.geometry);
    } else {
      this.removePolygons();
      this.notifyStore.dispatch(
        setNotify(
          'The surface area of the boundaries are too big, must be under 25km².'
        )
      );
    }
  }

  public sendEditCircle(): void {
    const numPts = 70;
    const degreeStep = 360 / 70;

    const vertices = [];
    for (let i = 0; i < numPts; i++) {
      const gpos = google.maps.geometry.spherical.computeOffset(
        this.savedPolygon.getCenter(),
        this.savedPolygon.getRadius(),
        degreeStep * i
      );
      vertices.push([gpos.lng(), gpos.lat()]);
    }
    vertices.push(vertices[0]);

    const polygonObject = polygon([vertices]);
    const rewindd = rewind(polygonObject);
    const areaPolygon = area(polygonObject);

    if (areaPolygon / 1000000 <= MAX_SURFACE_AREA_POLYGON) {
      this.sendCoordinates(rewindd.geometry);
    } else {
      this.notifyStore.dispatch(
        setNotify(
          'The surface area of the boundaries are too big, must be under 25km².'
        )
      );
    }
  }

  public editPolygon(): void {
    const me = this;
    this.savedPolygon.setEditable(true);

    let vertices = [];
    if (this.shape === 'circle') {
      this.savedPolygon.addListener('radius_changed', function (): void {
        me.sendEditCircle();
      });
      this.savedPolygon.addListener('center_changed', function (): void {
        me.sendEditCircle();
      });
    } else if (this.shape === 'polygon') {
      vertices = this.savedPolygon.getPath();
    }

    google.maps.event.addListener(vertices, 'insert_at', function (): void {
      me.sendFromEdit();
    });

    google.maps.event.addListener(vertices, 'set_at', function (): void {
      me.sendFromEdit();
    });

    google.maps.event.addListener(vertices, 'remove_at', function (): void {
      me.sendFromEdit();
    });
  }

  public removePolygons(): void {
    if (this.allPolygons) {
      this.allPolygons.forEach((data, index) => {
        this.allPolygons[index].setMap(null);
      });
    }
    if (this.savedPolygon) {
      this.savedPolygon.setMap(null);
    }
    if (this.drawingManager) {
      this.drawingManager.setDrawingMode(null);
      this.drawingManager.setOptions({
        drawingControl: false,
      });
    }
    this.allPolygons = undefined;
    this.savedPolygon = undefined;
  }

  private createPolygon(coordinates, index?): any {
    let options: any = {
      paths: coordinates,
      editable: false,
      strokeColor: this.strokeColor,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: this.fillColor,
      fillOpacity: 0.35
    };

    if (index !== null) {
      options = { ...options, id: index };
    }

    return new google.maps.Polygon(options);
  }

  public ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }
}
