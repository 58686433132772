import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainStaticComponent } from './main-static.component';
import { NavigationGuard } from '../../services/navigation/navigation.guard';
import { DataUploadComponent } from './data-upload/data-upload.component';

const cropzoneRouters: Routes = [
  {
    path: ':id/config',
    loadChildren: () => import('../../../../app/modules/cropzone-config/cropzone-config.module').then(m => m.CropzoneConfigModule)
  },
  {
    path: ':id/irrimet-config',
    loadChildren: () => import('../../../../app/modules/irrimet-config/irrimet-config.module').then(m => m.IrrimetConfigModule)
  },
  {
    path: ':id/irrimet',
    loadChildren: () => import('../../../../app/modules/irrimet/irrimet.module').then(m => m.IrrimetModule)
  },
  {
    path: ':id/cropzone-samples',
    loadChildren: () => import('../../../../app/modules/cropzone-sample/cropzone-sample.module').then(m => m.CropzoneSampleModule)
  },
  {
    path: ':id/soil-moisture-config',
    loadChildren: () =>
    import('../../../../app/modules/soil-moisture-config/soil-moisture-config.module').then(m => m.SoilMoistureConfigModule)
  },
  {
    path: ':id/soil-moisture',
    loadChildren: () =>
    import('../../../../app/modules/crop-zone-soil-moisture/crop-zone-soil-moisture.module').then(m => m.CropZoneSoilMoistureModule)
  },
  {
    path: ':id/yield-prediction-config',
    loadChildren: () =>
    import('../../../../app/modules/yield-prediction-config/yield-prediction-config.module').then(m => m.YieldPredictionConfigModule)
  },
  {
    path: ':id/yield-prediction/previous',
    loadChildren: () =>
    import('../../../../app/modules/yield-prediction-previous/yield-prediction-previous.module')
    .then(m => m.YieldPredictionPreviousModule)
  },
  {
    path: ':id/yield-prediction/prediction',
    loadChildren: () => import('../../../../app/modules/yield-prediction/yield-prediction.module').then(m => m.YieldPredictionModule)
  },
   {
     path: ':id/sat',
     loadChildren: () => import('../../../../app/modules/crop-zone-sat/crop-zone-sat.module').then(m => m.CropZoneSatModule)
   }
];

const stationRouters: Routes = [
  {
    path: ':id/data',
    loadChildren: () => import('../../../../app/modules/station-data/station-data.module').then(m => m.StationDataModule)
  },
  {
    path: ':id/config',
    loadChildren: () => import('../../../../app/modules/station-config/station-config.module').then(m => m.StationConfigModule)
  },
  {
    path: ':id/info',
    loadChildren: () =>
    import('../../../../app/modules/station-information/station-information.module').then(m => m.StationInformationModule)
  },
  {
    path: ':id/events',
    loadChildren: () => import('../../../../app/modules/station-events/station-events.module').then(m => m.StationEventsModule)
  },
  {
    path: ':id/sensor-names',
    loadChildren: () => import('../../../../app/modules/sensors-and-nodes/sensors-and-nodes.module').then(m => m.SensorsAndNodesModule)
  },
  {
    path: ':id/models',
    loadChildren: () => import('../../../../app/modules/station-models/station-models.module').then(m => m.StationModelsModule)
  },
  {
    path: ':id/sms-warnings',
    loadChildren: () => import('../../../../app/modules/warnings/warnings.module').then(m => m.WarningsModule)
  },
  {
    path: ':id/server-alerts',
    loadChildren: () => import('../../../../app/modules/server-alerts/server-alerts.module').then(m => m.ServerAlertsModule)
  },
  {
    path: ':id/machinery-settings',
    loadChildren: () => import('../../../../app/modules/machinery-settings/machinery-settings.module').then(m => m.MachinerySettingsModule)
  },
  {
    path: ':id/communication',
    loadChildren: () =>
    import('../../../../app/modules/station-communication/station-communication.module').then(m => m.StationCommunicationModule)
  },
  {
    path: ':id/correct-precipitation-data',
    loadChildren: () =>
    import('../../../../app/modules/correct-precipitation-data/correct-precipitation-data.module')
    .then(m => m.CorrectPrecipitationDataModule)
  },
  {
    path: ':id/ultrasonic-distance-settings',
    loadChildren: () =>
      import('../../../../app/modules/ultrasonic-distance-settings/ultrasonic-distance-settings.module')
        .then(m => m.UltrasonicDistanceSettingsModule)
  },
  {
    path: ':id/delete-weather-data',
    loadChildren: () =>
    import('../../../../app/modules/station-delete-weather-data/station-delete-weather-data.module')
    .then(m => m.StationDeleteWeatherDataModule)
  },
  {
    path: ':id/weather-forecast',
    loadChildren: () => import('../../../../app/modules/weather-forecast/weather-forecast.module').then(m => m.WeatherForecastModule),
    canLoad: [NavigationGuard],
    canActivate: [NavigationGuard]
  },
  {
    path: ':id/work-planning',
    loadChildren: () => import('../../../../app/modules/work-planning/work-planning.module').then(m => m.WorkPlanningModule),
    canLoad: [NavigationGuard],
    canActivate: [NavigationGuard]
  },
  {
    path: ':id/animal-production',
    loadChildren: () => import('../../../../app/modules/animal-production/animal-production.module').then(m => m.AnimalProductionModule),
    canLoad: [NavigationGuard],
    canActivate: [NavigationGuard]
  },
  {
    path: ':id/accumulator-tool',
    loadChildren: () => import('../../../../app/modules/accumulator-tool/accumulator-tool.module').then(m => m.AccumulatorToolModule)
  },
  {
    path: ':id/extreme-weather-monitoring',
    loadChildren: () => import('../../../../app/modules/frost-protection/frost-protection.module').then(m => m.FrostProtectionModule)
  },
  {
    path: ':id/disease-models',
    loadChildren: () => import('../../../../app/modules/station-disease/station-disease.module').then(m => m.StationDiseaseModule)
  },
  {
    path: ':id/soil-moisture',
    loadChildren: () => import('../../../../app/modules/soil-moisture/soil-moisture.module').then(m => m.SoilMoistureModule)
  },
  {
    path: ':id/samples',
    loadChildren: () => import('../../../../app/modules/station-sample/station-sample.module').then(m => m.StationSampleModule)
  },
  {
    path: ':id/hydroponics',
    loadChildren: () => import('../../../../app/modules/hydroponics/hydroponics.module').then(m => m.HydroponicsModule)
  },
  {
    path: ':id/cropview',
    loadChildren: () => import('../../../../app/modules/crop-view/crop-view.module').then(m => m.CropViewModule)
  },
  {
    path: ':id/iscout',
    loadChildren: () => import('../../../../app/modules/iscout/iscout.module').then(m => m.IscoutModule)
  },
  {
    path: ':id/camera-data',
    loadChildren: () => import('../../../../app/modules/camera-data/camera-data.module').then(m => m.CameraDataModule)
  },
  {
    path: ':id/camera-slideshow',
    loadChildren: () => import('../../../../app/modules/camera-slideshow/camera-slideshow.module').then(m => m.CameraSlideshowModule)
  },
  {
    path: ':id/tracker',
    loadChildren: () => import('../../../../app/modules/tracker/tracker.module').then(m => m.TrackerModule)
  },
  {
    path: 'data-upload',
    component: DataUploadComponent
  }
];

const mainRoutes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../../../app/modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'notifications-history',
    loadChildren: () =>
    import('../../../../app/modules/notifications-history/notifications-history.module').then(m => m.NotificationsHistoryModule)
  },
  {
    path: 'notifications-configuration',
    loadChildren: () =>
    import('../../../../app/modules/notifications-configuration/notifications-configuration.module')
    .then(m => m.NotificationsConfigurationModule)
  },
  {
    path: 'user-settings',
    loadChildren: () => import('../../../../app/modules/user-settings/user-settings.module').then(m => m.UserSettingsModule)
  },
  {
    path: 'user-api-services',
    loadChildren: () => import('../../../../app/modules/user-api-services/user-api-services.module').then(m => m.UserApiServicesModule)
  },
  {
    path: 'user-api-services/:term',
    loadChildren: () => import('../../../../app/modules/user-api-services/user-api-services.module').then(m => m.UserApiServicesModule)
  },
  {
    path: 'user-licenses',
    loadChildren: () => import('../../../../app/modules/user-licenses/user-licenses.module').then(m => m.UserLicensesModule)
  },
  {
    path: 'user-support',
    loadChildren: () => import('../../../../app/modules/user-support/user-support.module').then(m => m.UserSupportModule)
  },
  {
    path: 'product-activation',
    loadChildren: () => import('../../../../app/modules/product-activation/product-activation.module').then(m => m.ProductActivationModule)
  },
  {
    path: 'station',
    children: stationRouters
  },
  {
    path: 'cropzone',
    children: cropzoneRouters
  },
  {
    path: 'cropzone/management',
    loadChildren: () =>
    import('../../../../app/modules/cropzone-management/cropzone-management.module').then(m => m.CropzoneManagementModule)
  },
  { path: '**', redirectTo: 'dashboard' }
];

const routes: Routes = [
  { path: '', component: MainStaticComponent, children: mainRoutes },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class MainStaticRoutingModule { }
