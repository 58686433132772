import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import * as fromLicenses from '../../../../core/reducers/licenses';
import { Store, select } from '@ngrx/store';
import { selectAvailableLicenses } from './../../../../core/reducers/index';
import { takeUntil, filter, map } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import * as moment from 'moment';
import { ICropzoneManagementState, IManagementConfig, selectManagementConfig, selectNewCropzones } from '../../reducers/cropzoneManagement';
import { ICropZone } from '../../../../core/models/cropzones';
import { getManagementCropsLazyLoading, updateManagementConfig } from '../../actions/cropzoneManagement';
import { showInfoModal } from './../../actions/cropzoneManagement';

@Component({
  selector: 'app-renew-cropzone-modal',
  templateUrl: './renew-cropzone-modal.component.html',
  styleUrls: ['./renew-cropzone-modal.component.scss']
})
export class RenewCropzoneModalComponent implements OnInit, OnDestroy {
  @Input()
  public cropzones: any;
  @Output()
  public emitCancel: EventEmitter<void> = new EventEmitter<void>();

  public config$: Observable<IManagementConfig>;
  public newCropzones$: Observable<ICropZone[]>;
  public licenses: any;
  public selectedLicense: any;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private licenseStore: Store<fromLicenses.IProductLicenseState>,
    private managementStore: Store<ICropzoneManagementState>
  ) { }

  public ngOnInit(): void {
    this.managementStore.dispatch(getManagementCropsLazyLoading());

    this.licenseStore.pipe(
      select(selectAvailableLicenses),
      filter((licenses) => !!licenses),
      map(licenses => licenses.filter((license) => moment(license.end_date).isAfter(moment()))),
      takeUntil(this.destroy$),
    ).subscribe((licenses) => {
      this.licenses = licenses;
    });

    this.newCropzones$ = this.managementStore.pipe(
      select(selectNewCropzones),
      takeUntil(this.destroy$)
    );

    this.config$ = this.managementStore.pipe(
      select(selectManagementConfig),
      takeUntil(this.destroy$)
    );
  }

  public selectLicense(event): void {
    this.selectedLicense = this.licenses.filter((license) => license.product_item.key === event.productKey)[0];

    this.managementStore.dispatch(updateManagementConfig({
      licenseModalActive: false,
      duplicationModalActive: true,
      cropTypeModalActive: false
    }));
  }

  public cancel(): void {
    this.emitCancel.emit();
    this.selectedLicense = null;

    this.managementStore.dispatch(showInfoModal([]));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
