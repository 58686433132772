import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IStationEventsState} from './station-events';
import * as fromStationEvents from '../../station-events/reducers/station-events';

export const stationEvents = createFeatureSelector<IStationEventsState>('stationEvents');

export const reducers = fromStationEvents.reducer;

export const selectStationEvents = createSelector(
  stationEvents,
  (stationEventsState: IStationEventsState) => stationEventsState.stationEvents
);

export const selectStationEventsLoading = createSelector(
  stationEvents,
  (stationEventsState: IStationEventsState) => stationEventsState.isLoading
);

export const selectStationEventsError = createSelector(
  stationEvents,
  (stationEventsState: IStationEventsState) => stationEventsState.isError
);
