import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {WarningsListActionTypes} from '../actions/warningsList';
import {IWarningsList} from '../models/models';

export interface IWarningsListState {
  warnings: IWarningsList;
}

export const initialState: IWarningsListState = {
  warnings: null,
};

export function reducer(state: IWarningsListState = initialState, action: IActionWithPayload ): IWarningsListState {
  switch (action.type) {
    case WarningsListActionTypes.SET_WARNINGS_LIST: {
      return {
        ...state,
        warnings: action.payload
      };
    }
    case WarningsListActionTypes.UPDATE_WARNINGS_LIST_SENSOR: {
      return {
        ...state,
        warnings: {
          ...state.warnings,
          [action.payload.warningKey]: {
            ...state.warnings[action.payload.warningKey],
            settings: {
              ...action.payload.settings
            }
          }
        }
      };
    }
    default: {
      return state;
    }
  }
}
