import { AgmMap, LatLngBounds } from '@agm/core';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ITrackerPosition } from '../../models/tracker.models';
import { TrackerService } from '../../services/tracker.service';
import * as fromTracker from './../../reducers/index';

declare var google: any;

@Component({
  selector: 'app-tracker-map',
  templateUrl: './tracker-map.component.html',
  styleUrls: ['./tracker-map.component.css']
})
export class TrackerMapComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('AgmMap') public agmMap: AgmMap;

  public data  : any;
  private drawMarkers: boolean = false;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public selectedTrips = null;
  public selectedPoints: ITrackerPosition[];
  public map: any;

  public markerIcon = {
    start: './../../../../assets/img/markers/ff_red.png',
    intermediate: './../../../../assets/img/markers/circle_ff_red.png',
    end: './../../../../assets/img/markers/light-green-lg.png'
  };

  constructor(private trackerStore: Store<fromTracker.ITrackerDataState>,
              private trackerService: TrackerService) { }

  public ngOnInit(): void {
  }

  public zoomChanged(zoom: number): void {
  }

  public getIconUrl(first: boolean, last: boolean): string {
    if (first) {
      return this.markerIcon.start;
    }
    if (last) {
      return this.markerIcon.end;
    }
    return this.markerIcon.intermediate;
  }

  public showMarker(index: number, trip: any): boolean {
    return (index === 0 || index === trip.path.length - 1) && trip.selected;
  }

  public changeBounds(): void {
    const bounds: LatLngBounds = new google.maps.LatLngBounds();

    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].selected) {
        for (let j = 0; j < this.data[i].path.length; j++) {
          bounds.extend(this.data[i].path[j]);
        }
      }
    }

    if (!bounds.isEmpty()) {
      this.map.fitBounds(bounds);
    }
  }

  public ngAfterViewInit(): void {
    if (typeof(this.agmMap) !== 'undefined') {
      this.agmMap.mapReady.subscribe(map => {
        this.map = map;

        this.trackerStore.pipe(
          takeUntil(this.destroy$),
          select(fromTracker.selectTrackerData)
        ).subscribe(trackerData => {
          this.data = trackerData;
          this.changeBounds();
        });

        this.trackerService.selectedTripPoints$.pipe(
          takeUntil(this.destroy$)
        ).subscribe((tripPoints: ITrackerPosition[]) => {
          this.selectedPoints = tripPoints;
        });
      });
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
