import { IWorkPlanningRequest, IWorkPlanningSettingsModePayload, IWorkPlanningSettingsPayload } from '../models/work-planning.models';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { IWorkPlanningState } from '../models/work-planning.models';

export const enum WorkPlanningActionTypes {
  GET_WORK_PLANNING_CHARTS = '[WorkPlanning] GET_WORK_PLANNING_SERVICE',
  SET_WORK_PLANNING_CHARTS = '[WorkPlanning] SET_WORK_PLANNING_CHARTS',
  SET_WORK_PLANNING_LOADING = '[WorkPlanning] SET_WORK_PLANNING_LOADING',
  SET_WORK_PLANNING_ERROR = '[WorkPlanning] SET_WORK_PLANNING_ERROR',
  GET_STATION_WORK_PLANNING_LICENSES = '[WorkPlanning] GET_STATION_WORK_PLANNING_LICENSES',
  SET_STATION_WORK_PLANNING_LICENSES = '[WorkPlanning] SET_STATION_WORK_PLANNING_LICENSES',
  SET_WARNING_MESSAGE = '[WorkPlanning] SET_WARNING_MESSAGE',

  SET_WORK_PLANNING_SETTINGS_IS_CHART_ACTIVE = '[WorkPlanning] SET_WORK_PLANNING_SETTINGS_IS_CHART_ACTIVE',
  SET_WORK_PLANNING_SETTINGS_IS_TABLE_ACTIVE = '[WorkPlanning] SET_WORK_PLANNING_SETTINGS_IS_TABLE_ACTIVE',
  SET_WORK_PLANNING_SETTINGS = '[WorkPlanning] SET_WORK_PLANNING_SETTINGS',
  SET_WORK_PLANNING_SETTINGS_MODE = '[WorkPlanning] SET_WORK_PLANNING_SETTINGS_MODE',
  GET_WORK_PLANNING_CHART = '[WorkPlanning] GET_WORK_PLANNING_CHART',
  SET_WORK_PLANNING_IMAGE_EXPORT_IN_PROGRESS = '[WorkPlanning] SET_WORK_PLANNING_IMAGE_EXPORT_IN_PROGRESS',
}

export function getWorkPlanningCharts(data: IWorkPlanningRequest): IActionWithPayload {
  return {type: WorkPlanningActionTypes.GET_WORK_PLANNING_CHARTS, payload: data};
}

export function setWorkPlanningCharts(data: IWorkPlanningState): IActionWithPayload {
  return {type: WorkPlanningActionTypes.SET_WORK_PLANNING_CHARTS, payload: data};
}

export function setWorkPlanningLoading(status: boolean): IActionWithPayload {
  return {type: WorkPlanningActionTypes.SET_WORK_PLANNING_LOADING, payload: status};
}

export function setWorkPlanningError(status: boolean): IActionWithPayload {
  return {type: WorkPlanningActionTypes.SET_WORK_PLANNING_ERROR, payload: status};
}

export function getStationWorkPlanningLicenses(stationId: string): IActionWithPayload {
  return {type: WorkPlanningActionTypes.GET_STATION_WORK_PLANNING_LICENSES, payload: stationId};
}

export function setStationWorkPlanningLicenses(licenses: any): IActionWithPayload {
  return {type: WorkPlanningActionTypes.SET_STATION_WORK_PLANNING_LICENSES, payload: licenses};
}

export function setWarningMessage(message: string|null, icon: boolean): IActionWithPayload {
  return {type: WorkPlanningActionTypes.SET_WARNING_MESSAGE, payload: {message: message, icon: icon}};
}

export function setWorkPlanningSettingsIsChartActive(status: boolean): IActionWithPayload {
  return {type: WorkPlanningActionTypes.SET_WORK_PLANNING_SETTINGS_IS_CHART_ACTIVE, payload: status};
}

export function setWorkPlanningSettingsIsTableActive(status: boolean): IActionWithPayload {
  return {type: WorkPlanningActionTypes.SET_WORK_PLANNING_SETTINGS_IS_TABLE_ACTIVE, payload: status};
}

export function setWorkPlanningSetting(data: IWorkPlanningSettingsPayload): IActionWithPayload {
  return {type: WorkPlanningActionTypes.SET_WORK_PLANNING_SETTINGS, payload: data};
}

export function setWorkPlanningSetttingsMode(data: IWorkPlanningSettingsModePayload): IActionWithPayload {
  return {type: WorkPlanningActionTypes.SET_WORK_PLANNING_SETTINGS_MODE, payload: data};
}

export function setWorkPlanningImageExportInProgress(status: boolean): IActionWithPayload {
  return {type: WorkPlanningActionTypes.SET_WORK_PLANNING_IMAGE_EXPORT_IN_PROGRESS, payload: status};
}
