import { IFarm } from '../models/farms';
import { IActionWithPayload } from '../models/actionWithPayload';
import { FarmActionTypes } from '../actions/farms';
import { deepClone } from '../../shared/utils/deepClone';

export interface IFarms {
    farms: Array<IFarm>;
}

const initialState: IFarms = {
    farms: []
};

export function reducer(state: IFarms = initialState, action: IActionWithPayload): IFarms {
    switch (action.type) {
        case FarmActionTypes.SET_FARMS: {
            return {
                ...state,
                farms: action.payload
            };
        }
        case FarmActionTypes.SET_FARM: {
            return {
                ...state,
                farms: [
                    ...state.farms.filter(farm => farm.id !== action.payload.id),
                    action.payload
                ]
            };
        }
        case FarmActionTypes.DELETE_FARM: {
            return {
                ...state,
                farms: deleteFarmHelp(action.payload, state.farms)
            };
        }
        default: {
            return state;
        }
    }
}

function deleteFarmHelp(id: string, farms: IFarm[]): IFarm[] {
    const newFarms: IFarm[] = farms.filter((f: IFarm) => f.id !== id);
    return deepClone(newFarms);
}
