import {IActionWithPayload} from '../models/actionWithPayload';
import {INavigationStationState} from '../reducers/navigation-station';

export const enum NavigationStationActionTypes {
  SET_NAVIGATION_STATION = '[Account] SET_NAVIGATION_STATION',
  SET_NAVIGATION_STATION_BASE_NAME = '[Account] SET_NAVIGATION_STATION_BASE_NAME',
  SET_TRACKER_SETTINGS = '[Account] SET_TRACKER_SETTINGS'
}

export function setNavigationStation(data: INavigationStationState): IActionWithPayload {
  return { type: NavigationStationActionTypes.SET_NAVIGATION_STATION, payload: data };
}

export function setNavigationStationBaseName(data: string): IActionWithPayload {
  return { type: NavigationStationActionTypes.SET_NAVIGATION_STATION_BASE_NAME, payload: data };
}

export function setTrackerSettings(data: number): IActionWithPayload {
  return { type: NavigationStationActionTypes.SET_TRACKER_SETTINGS, payload: data };
}
