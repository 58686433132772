<app-form-layout [shadow]="false">
  <div class="yield-prediction">
    <section class="yield-prediction_header flex-row-direction space-between">
      <h2>{{ 'Yield History' | translate }} </h2>
      <i class="zmdi zmdi-help help-icon" (click)="openModal()"></i>
    </section>
    <ng-container *ngIf="yieldSource; else addYieldConfig">
      <section class="flex-row-direction">
        <section class="flex-row-direction padding-r30">
          <label class="yield-prediction-blabel"><b>{{ 'Field:' | translate }} </b></label>
          <label class="yield-prediction__label" *ngIf="cropzone">
            {{ cropzone.field.name }}
          </label>
        </section>
        <section class="flex-row-direction padding-r30">
          <label class="yield-prediction-blabel"><b>{{ 'Crop:' | translate }} </b></label>
          <label class="yield-prediction__label" *ngIf="yieldSource.yield_prediction">
            {{ translateString(yieldSource.yield_prediction.crop_type) | titlecase | translate }}
          </label>
        </section>
      </section>
        <app-previous-yield-graph [yieldUnit]="yieldUnit" [chartData]="chartData" ></app-previous-yield-graph>
      <section>
        <div [formGroup]="form">
          <section class="row yield-prediction_content">
            <section class="yield-prediction__control col-md-12">
              <div class="alert alert-info" style="margin: 15px; width: 80%;">
                {{ 'Enter your previously measured crop zone average yields below to benefit ' +
                'from a quick overview of your crop\'s performance over time.' | translate }}
              </div>
            </section>
          </section>
          <section class="row yield-prediction_content">
            <section class="yield-prediction__control col-md-6">
              <section class="flex-row-direction">
                <section class="col-md-6">
                  <label class="yield-prediction__label">{{ 'Crop:' | translate}}</label>
                  <app-form-select [formControl]="cropTypeControl" [options]="cropTypeOptions" [type]="'border'"
                    [size]="'small'">
                  </app-form-select>
                </section>
                <section class=" col-md-6 yield-prediction__label">
                  <label class="" *ngIf="unitSystem === 'metric' else imperialYield">
                    {{ 'Measured Yield [t/ha]:' | translate}}
                  </label>
                  <ng-template #imperialYield>
                    {{ 'Measured Yield [bu/ac]:' | translate}}
                  </ng-template>
                  <input type="number" step="0.01" min="0" class="yield-prediction-config__input margin-t5 numberInput"
                    (change)="validateDecimal($event)" [formControl]="yieldControl">
                </section>
              </section>

            </section>
            <section class="col-md-6">
              <section class="flex-row-direction">
                <section>
                  <label class="yield-prediction__label date-label">{{ 'Sowing date:' | translate}}</label>
                  <app-custom-datepicker [isSmall]="true" [isOnlyFocus]="false" [formControl]="fromDatepickerControl">
                  </app-custom-datepicker>
                </section>
                <section>
                  <label class="yield-prediction__label date-label">{{ 'Harvest date:' | translate}}</label>
                  <app-custom-datepicker [isSmall]="true" [maxDate]="maximumDate" [isOnlyFocus]="false"
                    [formControl]="toDatepickerControl">
                  </app-custom-datepicker>
                </section>
                <section>
                  <br />
                  <app-custom-button [content]="'Add Previous Yield'" [color]="'green'" (click)="onSave()">
                  </app-custom-button>
                </section>
              </section>
            </section>
          </section>
        </div>
        <div class="table-responsive yield-prediction__table-content">
          <table class="yield-prediction__table">
            <thead class="yield-prediction__table__head">
              <tr>
                <th class="yield-prediction__table__th">
                  {{ "Crop" | translate }}
                </th>
                <th class="yield-prediction__table__th">
                  {{ "Sowing date" | translate }}
                </th>
                <th class="yield-prediction__table__th">
                  {{ "Harvest date" | translate }}
                </th>
                <th class="yield-prediction__table__th">
                  <label *ngIf="unitSystem === 'metric' else imperialYield">
                    {{ 'Measured Yield [t/ha]:' | translate}}
                  </label>
                  <ng-template #imperialYield>
                    {{ 'Measured Yield [bu/ac]:' | translate}}
                  </ng-template>
                </th>
              </tr>
            </thead>
            <tbody class="yield-prediction__table__body" *ngIf="previousYields">
              <tr app-previous-yield-row *ngFor="let yield of previousYields" [yield]="yield"
                (actionClicked)="getPreviousYields()">
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </ng-container>
    <ng-template #addYieldConfig>
      <section class="centered">
        <h2>{{ 'Please add yield prediction config in cropzone settings' | translate }}</h2>
      </section>
    </ng-template>
  </div>
</app-form-layout>

<app-info-modal [modalId]="modalId" [header]="'Yield History' | translate">
  <app-previous-yield-help-text></app-previous-yield-help-text>
</app-info-modal>