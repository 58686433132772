import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {BaseNameActionTypes} from '../actions/baseName';

export interface IBaseNameState {
  name: string;
}

const initialState: IBaseNameState = {
  name: '',
};

export function reducer(state: IBaseNameState = initialState, action: IActionWithPayload ): IBaseNameState {
  switch (action.type) {
    case BaseNameActionTypes.SET_BASE_NAME: {
      return {
        ...state,
        name: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
