import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as fromNavigationStation from '../../../../core/reducers/navigation-station';
import {
  IUltrasonicDistanceSettingsState,
  IUltrasonicSensor,
  IUltrasonicSetting,
  IUltrasonicSettingsCurrentValue,
  IUltrasonicValue
} from '../../models/models';
import { selectNavigationStation } from '../../../../core/reducers';
import { IStation } from '../../../../core/models/stations';
import {
  convertDefaultValue, getUltrasonicData,
  saveUltrasonicSettings, setCurrentValue
} from '../../actions/actions';
import {
  selectUltrasonicSensors,
  selectUltrasonicSettings,
  selectUltrasonicSettingsCurrentValue
} from '../../selectors/selectors';
import { dateToUtcUnixTimestamp } from '../../../../shared/utils/dateFormat';
import { setNotify } from '../../../../core/actions/notify';
import * as fromNotify from '../../../../core/reducers/notify';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { IConversionRequest } from '../../../../shared/interfaces';
import { DEFAULT_VALUE_MM } from '../../constants/constants';
import { jsonCompare } from '../../../../shared/utils/jsonCompare';

@Component({
  selector: 'app-ultrasonic-distance-settings-current',
  templateUrl: './ultrasonic-distance-settings-current.component.html',
  styleUrls: ['./ultrasonic-distance-settings-current.component.scss']
})
export class UltrasonicDistanceSettingsCurrentComponent implements OnInit, OnDestroy {

  @Input()
  private currentValue              : string = '';
  private ultrasonicSettings        : Array<IUltrasonicSetting>;
  private dateFrom                  : number;
  private destroy$                  : Subject<boolean> = new Subject<boolean>();

  public currentSettingsForm        : FormGroup;
  public ultrasonicSensors          : Array<IUltrasonicSensor>;
  public stationId                  : string;
  public currentUnit                : string;
  public flags                      : any;
  public ULTRASONIC_HELP_MODAL_ID   : string = generateId();

  constructor(
    private fb                      : FormBuilder,
    private navigationStore         : Store<fromNavigationStation.INavigationStationState>,
    private ultrasonicStore         : Store<IUltrasonicDistanceSettingsState>,
    private notifyStore             : Store<fromNotify.INotifyState>,
    private modalService            : ModalService
  ) { }

  public get elevation(): AbstractControl {
    return this.currentSettingsForm.get('elevation');
  }

  public ngOnInit(): void {
    this.currentSettingsForm = this.fb.group({
      elevation: [this.currentValue, []]
    });

    this.navigationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((s: IStation) => !!s)
    ).subscribe((station: IStation) => {
      if (station.name.original !== this.stationId) {
        this.stationId = station.name.original;
        this.flags = station.flags;
        this.ultrasonicStore.dispatch(getUltrasonicData(station.name.original));
      }
    });

    combineLatest([
      this.ultrasonicStore.pipe(select(selectUltrasonicSensors)),
      this.ultrasonicStore.pipe(select(selectUltrasonicSettings)),
    ]).pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(jsonCompare())
    ).subscribe(([ultrasonicSensors, ultrasonicSettings]) => {
      this.ultrasonicSensors = ultrasonicSensors;
      this.ultrasonicSettings = ultrasonicSettings;

      let settings: boolean;
      let sameUnit: boolean;

      if (this.ultrasonicSensors && this.ultrasonicSettings) {      // if we already got sensors and settings - api call
        if (this.ultrasonicSensors.length) {                        // if sensor exists
          if (this.ultrasonicSettings.length) {                     // if user already saved some settings
            settings = true;
            sameUnit = this.ultrasonicSettings[0].unit === this.ultrasonicSensors[0].unit;
          } else if (!this.ultrasonicSettings.length) {             // if user hasn't saved settings yet
            settings = false;
            sameUnit = this.currentUnit === this.ultrasonicSensors[0].unit;
          }
        }
        this.getValue(settings, sameUnit);
      }
    });

    // get converted current value and display it
    this.ultrasonicStore.pipe(
      takeUntil(this.destroy$),
      select(selectUltrasonicSettingsCurrentValue)
    ).subscribe((currentValue: IUltrasonicSettingsCurrentValue) => {
      if (currentValue) {
        this.currentValue = currentValue.value;
        this.currentUnit = currentValue.unit;
        this.elevation.setValue(currentValue.value);
      }
    });

    // if user changes the elevation, save it
    this.elevation.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe((elevation: string) => {
      this.currentValue = elevation;
    });
  }

  public saveUltrasonicSettings(): void {
    const from = new Date();
    this.dateFrom = dateToUtcUnixTimestamp(from);

    const data: IUltrasonicValue = {
      stationId         : this.stationId,
      elevationValue    : this.currentValue,
      unit              : this.ultrasonicSensors[0].unit,
      from              : this.dateFrom,
      to                : this.ultrasonicSettings ? this.dateFrom : null
    };

    if (this.checkChanges(data)) {
      this.ultrasonicStore.dispatch(saveUltrasonicSettings(data));
    }
  }

  public checkChanges(data: IUltrasonicValue): boolean {
    let changes = true;
    if (this.ultrasonicSettings.length) {
      changes = !(data.elevationValue === this.ultrasonicSettings[0].elevationValue &&
                  data.unit === this.ultrasonicSettings[0].unit);
    }
    if (!changes) {
      this.notifyStore.dispatch(setNotify('No changes have been made yet. Edit the status before updating.'));
    }

    return changes;
  }

  public getValue(settings: boolean, sameUnit: boolean): void {
    if (settings) {
      if (sameUnit) {
        this.ultrasonicStore.dispatch(setCurrentValue({
          value: this.ultrasonicSettings[0].elevationValue,
          unit: this.ultrasonicSettings[0].unit
        }));
      } else {
        const conversionRequest: IConversionRequest = {
          group: this.ultrasonicSensors[0].group,
          unitFrom: this.ultrasonicSettings[0].unit,
          unitTo: this.ultrasonicSensors[0].unit,
          value: this.ultrasonicSettings[0].elevationValue
        };
        this.ultrasonicStore.dispatch(convertDefaultValue(conversionRequest));
      }
    } else {
      if (sameUnit) {
        this.ultrasonicStore.dispatch(setCurrentValue({
          value: this.currentValue ? this.currentValue : DEFAULT_VALUE_MM,
          unit: this.ultrasonicSensors[0].unit
        }));
      } else {
        const conversionRequest: IConversionRequest = {
          group: this.ultrasonicSensors[0].group,
          unitFrom: !this.currentUnit && this.ultrasonicSensors[0].unit !== 'mm' ?  // if the page was just loaded AND the
            'mm' :                                                                  // the sensor unit is not 'mm'
            this.currentUnit,
          unitTo: this.ultrasonicSensors[0].unit,
          value: this.currentValue ? this.currentValue : DEFAULT_VALUE_MM          // if the value was already displayed, we use it
        };
        this.ultrasonicStore.dispatch(convertDefaultValue(conversionRequest));
      }
    }
  }

  public openModal(): void {
    this.modalService.openModal(this.ULTRASONIC_HELP_MODAL_ID );
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
