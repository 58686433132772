import { Effect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { ApiCallService } from '../../../services/api/api-call.service';
import { Observable, of, from } from 'rxjs';
import { IActionWithPayload as IAction, IActionWithPayload } from '../../../core/models/actionWithPayload';
import {
    ActionTypes,
    setState,
    setFarmBeatsSettings,
    getSettings,
    setAssets
} from '../actions/farmbeats';
import { switchMap, catchError, tap, filter, map, withLatestFrom, retry, mergeMap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { setNotify } from '../../../core/actions/notify';
import { settings } from '../selectors/farmbeats';
import { IState } from '../models/farmbeats';

@Injectable()
export class Effects {

    constructor(
        private api: ApiCallService,
        private actions$: Actions,
        private store: Store<IState>
    ) {}

    @Effect()
    public getFarmBeatsSettings$: Observable<IAction> = this.actions$.pipe(
        ofType(ActionTypes.GET_SETTINGS),
        switchMap(() => this.api.getFarmBeatsSettings().pipe(
          map(response => setFarmBeatsSettings(response)),
          catchError(() => of(setNotify('FarmBeats API currently unavailable.')))
        ))
    );

    @Effect()
    public getFarmBeatsAssets$: Observable<IAction> = this.actions$.pipe(
        ofType(ActionTypes.GET_ASSETS),
        switchMap(() => this.api.getFarmBeatsAssets().pipe(
          map(response => setAssets(response)),
          catchError(() => of(setNotify('FarmBeats API currently unavailable.')))
        ))
    );

    @Effect()
    public saveSettings$: Observable<IActionWithPayload> = this.actions$.pipe(
      ofType(ActionTypes.SAVE_SETTINGS),
      mergeMap((action: IActionWithPayload) => {
        return this.api.saveFarmBeatsSettings(action.payload).pipe(
          mergeMap((response) => {
            if (response.returnCode === -2) {
              return of(setNotify('FarmBeats setting not valid.'));
            } else {
              return from([
                setFarmBeatsSettings(action.payload),
                setNotify('FarmBeats settings successfully saved.')
              ]);
            }
          }),
          catchError(err => of(setNotify('FarmBeats API currently unavailable.')))
        );
      })
    );

    @Effect()
    public addAsset$: Observable<IActionWithPayload> = this.actions$.pipe(
      ofType(ActionTypes.ADD_ASSET),
      mergeMap((action: IActionWithPayload) => {
        return this.api.addFarmBeatsAsset(action.payload).pipe(
          mergeMap((response) => {
            return from([
              // TO-DO: update store
              setNotify('FarmBeats asset successfully added.')
            ]);
          }),
          catchError(err => of(setNotify('FarmBeats API currently unavailable.')))
        );
      })
    );

  @Effect()
  public resetSettings$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ActionTypes.RESET_SETTINGS),
    mergeMap(() => {
      return this.api.resetFarmBeatsSettings().pipe(
        switchMap((response) => {
          if (response.returnCode === 0) {
            return of(setNotify('FarmBeats settings successfully saved.'));
          } else {
            return of(setNotify('FarmBeats API currently unavailable.'));
          }
        }),
        catchError(() => of(setNotify('FarmBeats API currently unavailable.')))
      );
    })
  );

    @Effect()
    public deleteAsset$: Observable<IActionWithPayload> = this.actions$.pipe(
      ofType(ActionTypes.DELETE_ASSET),
      mergeMap((action: IActionWithPayload) => {
        return this.api.deleteFarmBeatsAsset(action.payload).pipe(
          mergeMap((response) => {
            return from([
              // TO-DO: update store
              setNotify('FarmBeats asset successfully removed.')
            ]);
          }),
          catchError(err => of(setNotify('FarmBeats API currently unavailable.')))
        );
      })
    );
}
