<app-form-layout>
  <app-card-header
    [header]="'Cropzone location'"
    [helpButton]="true"
    (openModal)="openModal()">
  </app-card-header>
  <div class="cropzone__content">
    <div class="row equal">

      <!-- <app-leaflet-map
        class="col-md-8"
        (coordinatesFromCreatedPolygon)="coords = $event">
      </app-leaflet-map> -->

      <app-cropzone-config-map
        class="col-md-9"
        (coordinatesFromCreatedPolygon)="coords = $event"
        (setStoreButtonActive)="storeActive = $event"
        [buttonClicked]="button"
        [oldBoundaries] = "oldBoundaries"
        [rebound]="rebound">
      </app-cropzone-config-map>

      <app-cropzone-map-form
        class="col-md-3"
        [coords]="coords"
        [storeActive]="storeActive"
        (buttonClicked)="button = $event"
        (sendingOldBoundaries)="oldBoundaries = $event"
        (rebound)="rebound = $event">
      </app-cropzone-map-form>

    </div>
  </div>
</app-form-layout>

<app-info-modal [modalId]="modalId" [header]="'Cropzone location'">
  <app-cropzone-config-location-help-text-modal></app-cropzone-config-location-help-text-modal>
</app-info-modal>
