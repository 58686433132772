import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { DEFAULT_CAMM_ID } from '../../../../core/constants/camera';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import {
  IFromTo,
  IGetPhotosRequestFromTo,
  IGetPhotosRequests,
  IPestToggler,
  IPicture,
  PhotoRequestType
} from '../../../../shared/camera/models/camera';
import { IRectangle } from '../../../../shared/camera/models/rectangles';
import { READ_AND_WRITE_PERMISSION } from '../../../../shared/constants';
import { getCameraDeviceType, StationCameraTypes } from '../../../camera-data/constants/camera-data';
import { StationTopologyLinkType } from '../../../station-config/constants/constants';
import { getIscoutFirstDate, getIscoutLastDate, getIscoutPhotos } from '../../actions/iscout';
import {
  resetIscoutSettings,
  setIscoutSettingsSelectedPicture,
  updateIscoutSettingsPestToggles
} from '../../actions/iscout-settings';
import { IIscoutSettingsState, IIscoutState } from '../../models/iscout.models';
import {
  selectIscoutIsError,
  selectIscoutIsLoading,
  selectIscoutPictures,
  selectIscoutSettingsCurrentDate,
  selectIscoutSettingsIsHelpActive,
  selectIscoutSettingsSelectedPicture
} from '../../reducers';
import { IThemeConfig } from '../../../../../environments/interfaces/theme';
import { environmentToken } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/interfaces/environment';

@Component({
  selector: 'app-iscout-content',
  templateUrl: './iscout-content.component.html',
  styleUrls: ['./iscout-content.component.scss']
})
export class IscoutContentComponent implements OnInit, OnDestroy {
  public isLoading$           : Observable<boolean>;
  public isError$             : Observable<boolean>;
  public helpIsActive$        : Observable<boolean>;
  public hasWritePermission$  : Observable<boolean>;
  public pictures$            : Observable<Array<IPicture>>;
  public selectedPicture$     : Observable<IPicture>;
  public topologyLinkType     = StationTopologyLinkType;
  public stationType          : StationCameraTypes;

  private stationId           : string;
  private currentSelectedDate : string | IFromTo;
  private rectangles          : Array<IRectangle> = [];
  private destroy$            : Subject<boolean> = new Subject<boolean>();

  public subDomain            : IThemeConfig;

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    private iscoutStore               : Store<IIscoutState>,
    private iscoutSettingsStore       : Store<IIscoutSettingsState>,
    private navigationStationStore    : Store<INavigationStationState>
  ) {
    this.subDomain = environment.theme;
  }

  public ngOnInit(): void {
    this.initStatusListeners();
    this.initPictureListeners();
    this.initDataRetrieving();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initStatusListeners(): void {
    this.isLoading$ = this.iscoutStore.pipe(
      select(selectIscoutIsLoading)
    );

    this.isError$ = this.iscoutStore.pipe(
      select(selectIscoutIsError)
    );

    this.helpIsActive$ = this.iscoutSettingsStore.pipe(
      select(selectIscoutSettingsIsHelpActive)
    );
  }

  private initPictureListeners(): void {
    this.hasWritePermission$ = this.getStationChangeObservable().pipe(
      map((station: IStation): boolean => station.rights === READ_AND_WRITE_PERMISSION)
    );

    this.pictures$ = this.iscoutStore.pipe(
      select(selectIscoutPictures),
      filter((pictures: Array<IPicture>): boolean => !!pictures)
    );

    this.selectedPicture$ = this.iscoutSettingsStore.pipe(
      select(selectIscoutSettingsSelectedPicture),
      tap((picture: IPicture): void => {
        if (!!picture) {
          this.rectangles = picture.rectangles || [];
        }
      })
    );
  }

  private getStationChangeObservable(): Observable<IStation> {
    return this.navigationStationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((station: IStation): boolean => !!station),
      tap((station: IStation): void => {
        if (this.stationId !== station.name.original) {
          this.unselectPicture();
        }
        this.stationId = station.name.original;
        this.stationType = getCameraDeviceType(station.info.device_id);
      })
    );
  }

  private getFirstAndLastDates(): void {
    this.getStationChangeObservable().subscribe((): void => {
      this.iscoutSettingsStore.dispatch(getIscoutLastDate(this.stationId));
      this.iscoutSettingsStore.dispatch(getIscoutFirstDate(this.stationId));
    });
  }

  private getCurrentDateObservable(): Observable<void> {
    return this.iscoutSettingsStore.pipe(
      takeUntil(this.destroy$),
      select(selectIscoutSettingsCurrentDate),
      filter((currentDate: string | IFromTo): boolean => !!currentDate),
      map((currentDate: string | IFromTo): void => {
        this.currentSelectedDate = currentDate;
      })
    );
  }

  private initDataRetrieving(): void {
    this.getFirstAndLastDates();

    combineLatest([
      this.getStationChangeObservable(),
      this.getCurrentDateObservable()
    ]).subscribe((): void => {
      this.iscoutStore.dispatch(getIscoutPhotos(this.photosRequests));
    });
  }

  private get photosRequests(): IGetPhotosRequests | IGetPhotosRequestFromTo {
    return (typeof this.currentSelectedDate === 'string') ? {
      type: PhotoRequestType.SINGLE_DATE_INTERVAL,
      stationId: this.stationId,
      camIds: [DEFAULT_CAMM_ID],
      date: <string>this.currentSelectedDate
    } : {
      type: PhotoRequestType.MIN_MAX_INTERVAL,
      stationId: this.stationId,
      camIds: [DEFAULT_CAMM_ID],
      from: (<IFromTo>this.currentSelectedDate).from,
      to: (<IFromTo>this.currentSelectedDate).to
    };
  }

  public selectPicture(picture: IPicture): void {
    this.iscoutSettingsStore.dispatch(setIscoutSettingsSelectedPicture(picture));
  }

  public unselectPicture(): void {
    this.iscoutSettingsStore.dispatch(resetIscoutSettings());
  }

  public updatePestToggle(pestToggle: IPestToggler): void {
    const {amount, ...sanitized} = pestToggle;
    this.iscoutSettingsStore.dispatch(updateIscoutSettingsPestToggles(this.stationId, sanitized));
  }
}
