<div class="dropdown-container"
     (click)="toggleOpen()"
     appOutClick
     *ngIf="currentValue && options"
     (outClick)="close()">
  <app-custom-button [caret]="true"
                     [content]="currentValue.crop"
                     [color]="color">
  </app-custom-button>
  <div class="dropdown-container__empty">
    <div class="input-container" *ngIf="isOpen && searchable && options?.length > 10" (click)="stopPropagation($event)">
      <input
        type="text"
        (click)="stopPropagation($event)"
        (input)="filterOptions()"
        [(ngModel)]="searchTerm"
        class="form-control"
        [attr.placeholder]="placeholder | translate"
        (keydown.escape)="resetDropdown()">
      <i class="zmdi zmdi-search"></i>
    </div>
    
    <div class="custom-dropdown" *ngIf="isOpen" #item>
      <div class="custom-dropdown__item"
           *ngFor="let option of filteredOptions"
           (mouseenter)="setActiveDisease(option)"
           (mouseleave)="removeActiveDisease()">
        <p class="custom-dropdown__item__first"
           (click)="onChange(option.models[0])">
        <span class="fa fa-check check"
              *ngIf="option.group === currentValue.crop"></span>
        {{ option.group | translate }}
      </p>

        <div class="custom-dropdown__sub-items"
             *ngIf="option === activeDisease"
             [style.margin-top]="-item.scrollTop - 38 + 'px'">
          <div class="custom-dropdown__item"
               *ngFor="let subOption of option.models"
               (click)="onChange(subOption)">
            <p>
            <span class="fa fa-check check"
                  *ngIf="checkArrow(subOption.key, option.group)"></span>
              {{ subOption.name | translate }}
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

