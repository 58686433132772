<section>
  <app-form-layout>
    <app-iscout-header
      [title]="'Insects Management'"
      [enableHelpButton]="true"
      (openModal)="onToggleHelp()"
    ></app-iscout-header>

    <div class="iscout-pests-list-table">
      <div class="iscout-pests-list-table__toggle">
        <span>{{ 'Display system defined insects?' | translate }}</span>
        <app-yes-no-toggle
          noTab="true"
          [ngModel]="displaySystemPests"
          (change)="toggleDisplaySystemPests()"
        ></app-yes-no-toggle>
      </div>

      <div class="iscout-pests-list__button">
        <app-custom-button [content]="'Add insect'" (click)="onPestAdd()">
          <i class="fa fa-fw fa-plus"></i>
        </app-custom-button>
      </div>

      <br style="clear: both">
    </div>

    <div class="iscout-pests-list-table table-responsive">
      <table class="iscout-pests-list-table__table">
        <thead class="iscout-pests-list-table__table__head">
        <tr>
          <td colspan="3"></td>
          <td colspan="4">
            <form class="form-inline pull-right" style="margin-right: 10px">
              <div class="form-group">
                <app-form-input
                  class="iscout-pests-list-table__input"
                  [type]="'border'"
                  [formControl]="pestFilter"
                  [placeholder]="'Filter insects' | translate"
                ></app-form-input>
              </div>

              <div class="form-group">
                <app-custom-button [content]="'Clear filter' | translate" [color]="'blue'" (click)="pestFilter.setValue('')">
                  <i class="fa fa-fw fa-close"></i>
                </app-custom-button>
              </div>
            </form>
          </td>
        </tr>
        <tr>
          <th>{{ 'Scientific name' | translate }}</th>
          <th>{{ 'Common name' | translate }}</th>
          <th>{{ 'Genus' | translate }}</th>
          <th>{{ 'Family' | translate }}</th>
          <th>{{ 'Order' | translate }}</th>
          <th>{{ 'Color' | translate }}</th>
          <th></th>
        </tr>
        </thead>
        <tbody class="iscout-pests-list-table__table__body">
        <ng-container *ngFor="let pest of (pestList$ | async)">
          <tr *ngIf="shouldDisplayPest(pest)">
            <td>{{ pest.name }}</td>
            <td>{{ pest.common_name }}</td>
            <td>{{ pest.genus?.name }}</td>
            <td>{{ pest.family?.name }}</td>
            <td>{{ pest.order.name }}</td>
            <td>
              <div class="iscout-pests-list-table__table__body__color" [style.backgroundColor]="pest.color"></div>
            </td>
            <td class="iscout-pests-list-table__table__body__control">
              <ng-container *ngIf="!isSystemPest(pest)">
                <app-custom-button [content]="'Remove'" [color]="'red'" (click)="onPestRemove(pest)">
                  <i class="fa fa-fw fa-trash"></i>
                </app-custom-button>
                <app-custom-button [content]="'Update'" (click)="onPestEdit(pest)">
                  <i class="fa fa-fw fa-pencil"></i>
                </app-custom-button>
              </ng-container>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
  </app-form-layout>

  <app-modal [modalId]="pestsDeleteModalId" size="sm">
    <app-iscout-pests-delete-modal
      (confirmEmitter)="onPestRemoveConfirmation()"
      (cancelEmitter)="onCloseModal(pestsDeleteModalId)"
    ></app-iscout-pests-delete-modal>
  </app-modal>

  <app-info-modal [modalId]="helpModalId"
                  [header]="(subDomain?.iScoutNames ? subDomain.iScoutNames : ('iScout' | translate)) + ' ' + ('Insects' | translate)">
    <p>
      {{
      'Per default a list of different common insects is available. ' +
      'However you are able to deactivate the visibility of that insect list (click NO on ' +
      'the question "Display system defined insects?") and create your own ' +
      'insect list with ADD INSECT. Here it is obligatory to choose the ' +
      'order of the insect, on all other fields you can freely choose ' +
      'the names. That new added insect will appear on ' +
      'the insect list at the correction model on the photo.' | translate
      }}
    </p>
  </app-info-modal>
</section>
