import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  getWidgetData,
  removeWidget,
  setWidgetError,
  setWidgetLoading,
  updateWidgetEditMode,
  updateWidgetSettings
} from '../../../actions/dashboard';
import { IChartRequest, ILoadWidgetData, IUpdateWidgetEditMode, IWidget } from '../../../models/dashboard.models';
import * as fromDashboard from '../../../reducers/dashboard';
import { selectEdit } from '../../../reducers/index';

@Component({
  selector: 'app-chart-widget',
  templateUrl: './chart-widget.component.html',
  styleUrls: ['./chart-widget.component.scss']
})
export class ChartWidgetComponent implements OnInit {
  @Input()
  public widget                     : IWidget;
  @Input()
  private widgetIndex               : number;
  @Input()
  private columnIndex               : number;
  @Input()
  private rowIndex                  : number;

  public isEdit$                    : Observable<boolean>;
  private loadWidgetData            : ILoadWidgetData;

  constructor(private dashboardStore: Store<fromDashboard.IDashboard>) {}

  public ngOnInit(): void {
    this.isEdit$ = this.dashboardStore.pipe(select(selectEdit));
    if (!this.widget.loadData) {
      this.loadChartData();
    }
  }

  public removeCurrentWidget(): void {
    const path: Array<number> = [this.rowIndex, this.columnIndex, this.widgetIndex];
    this.dashboardStore.dispatch(removeWidget(path));
  }

  public loadChartData(): void {
    this.loadWidgetData = {
      url: '/chart/highchart/' +
            this.widget.requests.chart.endpoint.station + '/'
            + this.widget.requests.chart.endpoint.group + '/last/'
            + this.widget.requests.chart.endpoint.last,
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      profile: this.widget.requests.chart.profile
    };
    this.resetLoading();
    this.dashboardStore.dispatch(getWidgetData(this.loadWidgetData));
  }

  public updateRequest(updateSettings: IChartRequest): void {
    this.dashboardStore.dispatch(updateWidgetSettings({
      type: 'chart',
      updateSettings: updateSettings,
      path: [this.rowIndex, this.columnIndex, this.widgetIndex]
    }));
  }

  private resetLoading(): void {
    this.dashboardStore.dispatch(setWidgetLoading({
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      loading: true
    }));
    this.dashboardStore.dispatch(setWidgetError({
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      error: ''
    }));
  }

  public editMode(editMode: boolean): void  {
    const updateSettings: IUpdateWidgetEditMode = {
      path: [this.rowIndex, this.columnIndex, this.widgetIndex],
      type: 'chart',
      isEdit: editMode,
    };
    this.dashboardStore.dispatch(updateWidgetEditMode(updateSettings));
  }

}
