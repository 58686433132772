import { ActionTypes } from '../actions';
import { ISatWarningMessage } from '../models';
import { IActionWithPayload } from './../../../core/models/actionWithPayload';

const initialState: ISatWarningMessage = {
    message: null,
    icon: false
};

export function reducer(state: ISatWarningMessage = initialState, action: IActionWithPayload): ISatWarningMessage {
    switch (action.type) {
        case ActionTypes.SET_SAT_WARNING_MESSAGE:
            return <ISatWarningMessage>{
                ...state,
                    message: action.payload.message,
                    icon: action.payload.icon
            };
        default:
            return state;
    }
}
