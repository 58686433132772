import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { IScoutGlueBoardPeriodScope } from '../../../../core/constants/camera';
import { IStation } from '../../../../core/models/stations';
import { selectStations } from '../../../../core/reducers';
import { IStations } from '../../../../core/reducers/stations';
import { DatesTranslationsService } from '../../../../core/services/dates-translations.service';
import { ICameraRefresh, IFromTo, IToolbarSettings } from '../../../../shared/camera/models/camera';
import { StationTypes } from '../../../../shared/constants';
import { IOptions } from '../../../../shared/interfaces';
import { getDateForCameraPicture } from '../../../../shared/utils/dateFormat';
import { getIscoutGlueBoards, setIscoutGalleryGlueBoardSelection } from '../../actions/iscout-glue-boards';
import { setIscoutSettingsCurrentDateString, setIscoutSettingsToolbarSettings } from '../../actions/iscout-settings';
import { IIscoutGlueBoard, IIscoutGlueBoardState, IIscoutSettingsState, IIscoutState } from '../../models/iscout.models';
import {
  selectIscoutFirstDate, selectIscoutGlueBoardGallerySelection,
  selectIscoutGlueBoardList,
  selectIscoutLastDate,
  selectIscoutSettingsCurrentDate,
  selectIscoutSettingsForToolbar
} from '../../reducers';

@Component({
  selector: 'app-iscout-image-gallery-toolbar',
  templateUrl: './iscout-image-gallery-toolbar.component.html',
  styleUrls: ['./iscout-image-gallery-toolbar.component.scss']
})
export class IscoutImageGalleryToolbarComponent implements OnInit, OnDestroy {
  public stationMinDate$: Observable<moment.Moment>;
  public stationMaxDate$: Observable<moment.Moment>;
  public toolbarSettings$: Observable<IToolbarSettings>;
  public currentDate$: Observable<string | IFromTo>;
  public iscoutGlueBoards$: Observable<Array<IOptions>>;
  public iscoutGlueBoardSelection$: Observable<IOptions>;
  public iscoutStations: Array<IOptions> = [];
  public iscoutPeriodScopes: Array<IOptions> = [IScoutGlueBoardPeriodScope];

  private translatedMonths: object;
  private translatedForWeekDate: object;
  private translatedInterval: object;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private iscoutStore: Store<IIscoutState>,
    private iscoutSettingsStore: Store<IIscoutSettingsState>,
    private iscoutGlueBoardStore: Store<IIscoutGlueBoardState>,
    private stationsStore: Store<IStations>,
    private datesTranslationsService: DatesTranslationsService
  ) {
    this.translatedMonths = datesTranslationsService.translateMonths();
    this.translatedForWeekDate = datesTranslationsService.translateForDate();
    this.translatedInterval = datesTranslationsService.translateForInterval();
  }

  public ngOnInit(): void {
    this.initSettingsListener();
    this.initStationListListener();
    this.initDateListener();
    // Should clean the glue board filter before creating the component
    this.iscoutGlueBoardStore.dispatch(setIscoutGalleryGlueBoardSelection(null));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initSettingsListener(): void {
    this.toolbarSettings$ = this.iscoutSettingsStore.pipe(
      take(1),
      select(selectIscoutSettingsForToolbar),
    );

    this.iscoutGlueBoards$ = this.iscoutGlueBoardStore.pipe(
      select(selectIscoutGlueBoardList),
      map((glueBoards: Array<IIscoutGlueBoard>): Array<IOptions> =>
        glueBoards.map((gb) => this.mapGlueBoardToOption(gb))
      )
    );

    this.iscoutGlueBoardSelection$ = this.iscoutGlueBoardStore.pipe(
      select(selectIscoutGlueBoardGallerySelection),
      filter((gb) => !!gb),
      map((gb) => this.mapGlueBoardToOption(gb))
    );
  }

  private mapGlueBoardToOption(glueBoard: IIscoutGlueBoard): IOptions {
    return {
      value: this.formatDateInterval(glueBoard),
      content: glueBoard._id,
      data: <IFromTo>{
        from: new Date(glueBoard.from).getTime(),
        to: glueBoard.to ? new Date(glueBoard.to).getTime() : new Date().getTime(),
      }
    };
  }

  private formatDateInterval(glueBoard: IIscoutGlueBoard): string {
    return this.formatDate(glueBoard.from) + ' - ' + this.formatDate(glueBoard.to);
  }

  private formatDate(date: string | Date): string {
    const target = date || new Date();
    return moment(target).format('YYYY-MM-DD');
  }

  private initStationListListener(): void {
    this.stationsStore.pipe(
      takeUntil(this.destroy$),
      select(selectStations),
      filter((stations: Array<IStation>): boolean => !!stations && stations.length > 0),
    ).subscribe((stations: Array<IStation>): void => {
      this.iscoutStations = stations
        .filter((station: IStation): boolean =>
          StationTypes.get('IScoutType') === station.info.device_id ||
          StationTypes.get('IScoutMobileType') === station.info.device_id
        )
        .map((station: IStation): IOptions => ({
          value: station.name.original,
          content: station.name.custom ? station.name.custom + ' [' + station.name.original + ']' : station.name.original
        }));
    });
  }

  private initDateListener(): void {
    this.stationMinDate$ = this.iscoutStore.pipe(
      select(selectIscoutFirstDate)
    );

    this.stationMaxDate$ = this.iscoutStore.pipe(
      select(selectIscoutLastDate)
    );

    this.currentDate$ = this.iscoutSettingsStore.pipe(
      takeUntil(this.destroy$),
      select(selectIscoutSettingsCurrentDate),
      filter((currentDate: string | IFromTo): boolean => !!currentDate),
    );
  }

  public onRefresh(refreshFilter: ICameraRefresh): void {
    if (refreshFilter.scope !== IScoutGlueBoardPeriodScope.value) {
      this.iscoutGlueBoardStore.dispatch(setIscoutGalleryGlueBoardSelection(null));
    }
    this.iscoutSettingsStore.dispatch(setIscoutSettingsCurrentDateString(refreshFilter.value));
  }

  public onSaveToolbarSettings(settings: IToolbarSettings): void {
    this.iscoutSettingsStore.dispatch(setIscoutSettingsToolbarSettings(settings));
  }

  public onSelectGlueBoard(selection: IOptions): void {
    this.iscoutGlueBoardStore.dispatch(setIscoutGalleryGlueBoardSelection(selection.content));
  }

  public onLoadGlueBoards(stationId: string): void {
    this.iscoutGlueBoardStore.dispatch(getIscoutGlueBoards(stationId));
  }

  public formatCurrentDate(date: string | IFromTo): string {
    if (typeof date === 'string') {
      return this.formatCurrentDateAsString(date);
    }

    return this.formatCurrentDateAsInterval(date);
  }

  private formatCurrentDateAsString(date: string): string {
    const weekDate = getDateForCameraPicture(date);
    if (weekDate === date) {
      const d = moment(date).format('MMMM, YYYY');
      const year = this.datesTranslationsService.getYearMonths(d);
      const m = this.datesTranslationsService.getMonth(d);
      const month = this.translatedMonths[m];
      return `${month}, ${year}`;
    } else {
      const week = this.datesTranslationsService.getWeek(weekDate);
      const year = this.datesTranslationsService.getYearWeek(weekDate);
      return `${this.translatedForWeekDate['week']} ${week} ${this.translatedForWeekDate['in']} ${year}`;
    }
  }

  private formatCurrentDateAsInterval(date: IFromTo): string {
    const today = new Date();
    const {from = today, to = today} = date || {};
    const fromDate = moment(new Date(from)).format('YYYY-MM-DD');
    const toDate = moment(new Date(to)).format('YYYY-MM-DD');
    return `${this.translatedInterval['from']} ${fromDate} ${this.translatedInterval['to']} ${toDate}`;
  }
}
