import { map, exhaustMap, catchError, mergeMap } from 'rxjs/operators';
import { ApiCallService } from '../../../services/api/api-call.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import {
  collectConfigData,
  YieldConfigActionTypes,
  setCropsLazyLoading,
  setDatasourcesRain,
  setDatasourcesAirTemperature,
  setYieldConfigLoading
} from '../actions/yield-config.action';
import { EMPTY, from } from 'rxjs';
import { setNotify } from '../../../core/actions/notify';

@Injectable()
export class YieldConfigEffect {

  constructor(
    private actions$: Actions,
    private api: ApiCallService
  ) {

  }

  @Effect()
  public getCropsLazyLoading$ = this.actions$.pipe(
    ofType(YieldConfigActionTypes.GET_CROPS_LAZY_LOADING),
    exhaustMap(() => this.api.getCrops().pipe(
      map((crops) => setCropsLazyLoading(crops)),
      catchError(() => EMPTY)
    ))
  );

  @Effect()
  public getDatasourcesRain$ = this.actions$.pipe(
    ofType(YieldConfigActionTypes.GET_DATASOURCES_RAIN),
    exhaustMap((action: IActionWithPayload) =>
      this.api.getDataSource(action.payload).pipe(
        map(data => setDatasourcesRain(data)),
        catchError(() => EMPTY)
      )
    )
  );

  @Effect()
  public getDatasourcesAirTemperature$ = this.actions$.pipe(
    ofType(YieldConfigActionTypes.GET_DATASOURCES_AIRTEMPERATURE),
    exhaustMap((action: IActionWithPayload) =>
      this.api.getDataSource(action.payload).pipe(
        map(data => setDatasourcesAirTemperature(data)),
        catchError(() => EMPTY)
      )
    )
  );

  @Effect()
  public updateCropzone$ = this.actions$.pipe(
    ofType(YieldConfigActionTypes.UPDATE_CROPZONE),
    exhaustMap((action: IActionWithPayload) =>
      this.api.updateCropzone(action.payload).pipe(
        mergeMap(response => [
          collectConfigData(false),
          setNotify('Yield prediction settings successfully saved'),
          setYieldConfigLoading(false)
        ]),
        catchError(() => from([
          collectConfigData(true),
          setNotify('Can\'t define yield profile'),
          setYieldConfigLoading(false)
        ]))
      )
    )
  );

}
