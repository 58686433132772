<app-form-layout>
  <div class="accumulator-tool">

    <div class="accumulator-tool__body">
      <div [formGroup]="form"
           class="accumulator-tool__form">

        <div class="accumulator-tool__group">
          <label>{{ 'Asparagus sensor selection' | translate }}</label>
          <app-form-select [options]="sensorsOptions"
                           [formControl]="sensor"
                           (change)="onValueChanged()">
          </app-form-select>
        </div>

        <div class="accumulator-tool__group">
          <label>{{ 'Start date' | translate }}</label>
          <app-custom-datepicker [formControl]="from"
                                 [maxDate]="to.value"
                                 [isOnlyFocus]="false">
          </app-custom-datepicker>

          <label>{{ 'End date' | translate }}</label>
          <app-custom-datepicker [formControl]="to"
                                 [minDate]="from.value"
                                 [isOnlyFocus]="false">
          </app-custom-datepicker>
        </div>
      </div>
      <hr>

      <app-custom-button class="accumulator-tool__button"
                         id="calculateButton"
                         (click)="calculateTempAccumulation()"
                         [disabled]="isLoading || noSensors"
                         [color]="'red'"
                         [content]="'Calculate'">
      </app-custom-button>

      <div *ngIf="isLoading" class="accumulator-tool__loading">
        {{ 'Loading, please wait ...' | translate }}
      </div>
    </div>
  </div>
</app-form-layout>

<app-form-layout [shadow]="false">
  <div *ngIf="response?.text === 'no_data' && noSensors === false"
       class="accumulator-tool__no-data">
    <app-station-load-info
      [isError]=true
      [errorText]="'No data for selected period'">
    </app-station-load-info>
  </div>
</app-form-layout>

<app-form-layout [shadow]="false">
  <div *ngIf="noSensors"
       class="accumulator-tool__no-data">
    <app-station-load-info
      [isError]=true
      [errorText]="'No sensors for calculation'">
    </app-station-load-info>
  </div>
</app-form-layout>

<app-accumulator-tool-charts class="accumulator-tool__item"
                             *ngIf="!noData && !isLoading && !noSensors && response?.type === 'asparagus'"
                             [chartOptions]="chartOptions"
                             [chartData]="response.chart">
</app-accumulator-tool-charts>
