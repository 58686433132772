import { from } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { selectSelectedCropZone, selectSettings } from './../../../../core/reducers/index';
// tslint:disable-next-line
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { ISettings } from '../../../../core/models/account';
import { IState } from '../../../../core/reducers';
import * as fromSelectedCropzone from '../../../../core/reducers/selectedCropZone';
import { setNodes } from '../../actions/nodes';
import { getSensors } from '../../actions/sensors';
import { getSoilMoistureStations, setSoilMoistureStations } from '../../actions/soilMoistureStations';
import * as fromNodes from '../../reducers/nodes';
import * as fromSensors from '../../reducers/sensors';
import * as fromSoilMoistureStations from '../../reducers/soilMoistureStations';
import { createEmptyCropzone } from '../../util/soil-moisture-config-util';
import { ICropZone, IDataSources } from './../../../../core/models/cropzones';
import { IOptions } from './../../../../shared/interfaces';
import { selectFilteredSensors, selectNodes, selectSensors, selectSoilMoistureStations } from './../../reducers/index';
import { tap } from 'rxjs/internal/operators';

declare const require: any;
const turf = require('@turf/turf');

@Component({
  selector: 'app-soil-moisture-config-top',
  templateUrl: './soil-moisture-config-top.component.html',
  styleUrls: ['./soil-moisture-config-top.component.scss']
})
export class SoilMoistureConfigTopComponent implements OnInit, OnDestroy {
  @Input()
  public dataSource: IDataSources;
  @Input()
  public cropzone: ICropZone;
  @Input()
  public index: number;
  @Input()
  public fromDate: Date;
  @Input()
  public toDate: Date;

  @Output()
  public removeDatasource: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public save: EventEmitter<any> = new EventEmitter<any>();

  public nodesOptions: any[] = [];
  public nodesDisabled: boolean = true;

  public selectedNode: any;
  public filteredSensorsList: any[];

  public previousNodeVal: any = {value: ''};
  public userSettings$: Observable<ISettings>;
  public selectedSoilMoistureStation: any;
  public sensorsList: Array<any> = [];
  public nodesList: Array<any> = [];
  public nodesArray: Array<any> = [];
  public soilMoistureStations$: Observable<IOptions[]>;
  public nodes$: Observable<IOptions[]>;
  // TODO adjust any (create ISoilMoistureStation)
  public smStations: Array<any> = [];

  public previousCropzone: ICropZone = createEmptyCropzone();
  private previousVal: any;

  public form: FormGroup;

  public filteredSensors: Array<any> = [];
  public activated: boolean = false;

  public period: Date[] = [];

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private account: Store<IState>,
    private fb: FormBuilder,
    private soilMoistureStationsStore: Store<fromSoilMoistureStations.ISoilMoistureStationsState>,
    private sensorsStore: Store<fromSensors.ISensorsState>,
    private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private nodesStore: Store<fromNodes.INodesState>
  ) {
    this.form = this.fb.group({
      'soilMoistureStation': [null, [Validators.required]],
      'node': [null, Validators.required],
      'periodFrom': [null, Validators.required],
      'periodTo': [null, Validators.required]
    });
  }

  public get soilMoistureStation(): AbstractControl {
    return this.form.get('soilMoistureStation');
  }

  public get node(): AbstractControl {
    return this.form.get('node');
  }

  public get periodFrom(): AbstractControl {
    return this.form.get('periodFrom');
  }

  public get periodTo(): AbstractControl {
    return this.form.get('periodTo');
  }

  private clearValues(): void {
    this.previousVal = null;
    this.previousNodeVal = {value: ''};
    this.soilMoistureStationsStore.dispatch(setSoilMoistureStations([]));

    this.soilMoistureStation.setValue(null, { emitEvent: false });
    this.node.setValue(null, { emitEvent: false });
  }

  public ngOnInit(): void {
    this.selectedCropzoneStore.pipe(
      select(selectSelectedCropZone),
      takeUntil(this.destroy$)
    ).subscribe(cropzone => {
      this.cropzone = cropzone;

      this.setPeriod();
      this.clearValues();
      this.onLoadSoilMoistureStations();
      if (this.dataSource) {
        this.checkSoilMoistureStations(this.dataSource);
      }
    });

    this.userSettings$ = this.account.pipe(
      select(selectSettings)
    );

    this.soilMoistureStations$ = this.soilMoistureStationsStore.pipe(
      select(selectSoilMoistureStations),
      map((stations) => stations.sort((a, b) => a.id < b.id ? -1 : 1)),
      map(stations => stations.filter(item => item.info).map((item: any) =>
        ({ content: item, value: `${item.id} (${item.info.device_name})` }))
      )
    );

    this.nodesStore.pipe(
      select(selectNodes),
      takeUntil(this.destroy$)
    ).subscribe(nodes => this.nodesList = nodes);

    this.sensorsStore.pipe(
      select(selectFilteredSensors),
      takeUntil(this.destroy$)
    ).subscribe(sensors => this.filteredSensorsList = sensors);

    this.sensorsStore.pipe(
      select(selectSensors),
      takeUntil(this.destroy$)
    ).subscribe(sensors => {
      this.sensorsList = sensors;
    });

    this.soilMoistureStationsStore.pipe(
      select(selectSoilMoistureStations),
      takeUntil(this.destroy$)
    ).subscribe(soilMoistureStations => this.smStations = soilMoistureStations);

    this.onSoilMoistureStationChange();
    this.onNodeChanges();
  }

  private checkSoilMoistureStations(dataSource: any): void {
    if (this.smStations.length > 0) {
      this.setSelectedSoilMoistureStation(dataSource);
    } else {
      setTimeout(() => { this.checkSoilMoistureStations(dataSource); }, 100);
    }
  }

  private setSelectedSoilMoistureStation(dataSource): void {

    const content = this.smStations.filter(item => item.id === dataSource.device_name)[0];
    let value = dataSource.device_name;

    if (content && content.info) {
      value += ` (${content.info.device_name})`;
    }

    const smStation = { content, value };

    /* if (!deepEqual(smStation, this.previousSMStation)) { */
    this.soilMoistureStation.setValue(smStation);
    /* this.previousSMStation = smStation; */
    /*  } */

    this.checkNodes(dataSource);
  }

  private checkNodes(dataSource: IDataSources): void {
    if (this.nodesList.length > 0) {
      this.setNode(dataSource);
    } else {
      setTimeout(() => { this.checkNodes(dataSource); }, 100);
    }
  }

  private setNode(dataSource: IDataSources): void {
    let node: any;
    if (dataSource.source['layers'][0].node === '') {
      node = {
        content: this.nodesList.filter(nodex => nodex.node === dataSource.device_name)[0],
        value: dataSource.device_name
      };
    } else {
      node = {
        content: this.nodesList.filter(nodex => nodex.node === dataSource.source['layers'][0].node)[0],
        value: dataSource.source['layers'][0].node
      };
    }
    this.node.setValue(node);
  }

  private setPeriod(): void {
    if (this.dataSource) {
      this.periodFrom.setValue(new Date(this.dataSource.from));
      this.periodTo.setValue(new Date(this.dataSource.to));
    } else {
      if (this.cropzone.crop !== null) {
        this.periodFrom.setValue(new Date(this.cropzone.crop['fao56'].L_ini));
        this.periodTo.setValue(new Date(this.cropzone.crop['fao56'].L_late));
      } else {
        this.periodFrom.setValue(new Date(this.cropzone.from));
        this.periodTo.setValue(new Date(this.cropzone.to));
      }
    }
    this.period = [this.periodFrom.value, this.periodTo.value];
  }

  public onLoadSoilMoistureStations(): void {
    let isDate: boolean = <any>this.cropzone.from instanceof Date;
    let isDateTo: boolean = <any>this.cropzone.to instanceof Date;

    if (isDate) {
      const fromDate: Date = <any>this.cropzone.from;
      this.cropzone.from = fromDate.toISOString();
      isDate = false;
    }
    if (isDateTo) {
      const toDate: Date = <any>this.cropzone.to;
      this.cropzone.to = toDate.toISOString();
      isDateTo = false;
    }

    const centroid = turf.centroid(this.cropzone.boundary);
    const from2 = this.cropzone.from.split('T')[0];
    const to = this.cropzone.to.split('T')[0];

    this.soilMoistureStationsStore.dispatch(getSoilMoistureStations({ centroid, from: from2, to, groups: '25', distance: 10000 }));
  }

  public onSoilMoistureStationChange(): void {
    this.soilMoistureStation.valueChanges.subscribe(val => {
      if ((val !== this.previousVal) && val) {
        this.nodesDisabled = false;
        this.selectedNode = null;
        const nodesArray = this.createNodesArray();
        this.nodesList = this.createNodesArray();
        const array: Array<any> = [];

        nodesArray.forEach(element => {
          array.push({ content: element, value: element.node.toString() });
        });

        array.sort((a, b) => a.value < b.value ? -1 : 1);
        this.nodes$ = from([array]);
        this.node.setValue(null, {emitEvent: false});

        this.soilMoistureStationsStore.dispatch(setNodes(nodesArray, val.content.id));

        this.previousVal = val;
      }
    });
  }

  private createNodesArray(): Array<any> {
    let id: string;

    if (this.soilMoistureStation.value.content) {
      id = this.soilMoistureStation.value.content.id;
    } else {
      id = this.soilMoistureStation.value.value;
    }

    this.selectedSoilMoistureStation = this.smStations.filter(smStation => smStation.id === id)[0];

    const nodesArray: Array<any> = [];

    this.selectedSoilMoistureStation.groups[25].forEach(group => {
      if (group.hasOwnProperty('mc')) {
        const macNode = { kind: 'mac', node: group.mc };
        if (!nodesArray.some(node => node.node === macNode.node)) {
          nodesArray.push(macNode);
        }
      } else if (group.hasOwnProperty('sr')) {
        const serialNode = { kind: 'serial', node: group.sr };
        if (!nodesArray.some(node => node.node === serialNode.node)) {
          nodesArray.push(serialNode);
        }
      } else if (group.hasOwnProperty('mc') === false && group.hasOwnProperty('sr') === false) {
        const onStationNode = { kind: 'onStation', node: this.selectedSoilMoistureStation.id };
        if (!nodesArray.some(node => node.node === onStationNode.node)) {
          nodesArray.push(onStationNode);
        }
      }
      return nodesArray;
    });
    if (nodesArray.length > 0) {
      return nodesArray;
    }
  }

  public onNodeChanges(): void {
    this.node.valueChanges.subscribe(val => {
      if (val) {
        if (val !== this.previousNodeVal) {
          this.selectedNode = this.nodesList.filter(node => node.node === val.value)[0];

          if (this.selectedNode === undefined) {
            this.selectedNode = this.nodesList.filter(node => node.node === parseInt(val.value, 10))[0];
          }
          // tslint:disable-next-line
          if (!this.sensorsList.some(sensor => sensor.stationId === this.soilMoistureStation.value.content.id && sensor.node.node.toString() === this.selectedNode.node.toString())) {
            this.sensorsStore.dispatch(getSensors(this.soilMoistureStation.value.content.id, this.selectedNode));
          }

          this.previousNodeVal = val;
        }
      }
    });
  }

  public removeDataSource(event: number): void {
    this.removeDatasource.emit(event);
  }

  public saveX(event: any): void {
    this.dataSource = event.datasource;
    this.save.emit(event);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
