import { IActionWithPayload } from './../../../core/models/actionWithPayload';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import { TreeSettingsActionTypes } from './../actions/tree-settings';

export interface ITrackerReports {
  reports: any;
}

export const initialState: ITrackerReports = {
  reports: []
};

export function reducer(state: ITrackerReports = initialState, action: IActionWithPayload): ITrackerReports {
  switch (action.type) {
    case TreeSettingsActionTypes.SET_TRACKER_REPORTS:
      const newStateT = <ITrackerReports>{ ...state };
      newStateT.reports = action.payload;
      return newStateT;
    default:
      return state;
  }
}

export const trackerReportData = createFeatureSelector<ITrackerReports>('tracker');

export const selectTrackerReports = createSelector(
    trackerReportData,
  (state: ITrackerReports): any => state ? state.reports.tracker : null
);
