import { Injectable } from '@angular/core';
import { ApiCallService } from '../../services/api/api-call.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { EMPTY, from, Observable, of } from 'rxjs';
import {
  IActionWithExtraPayload,
  IActionWithPayload,
  IActionWithPayloadRedirectAfterFieldDeletion,
  IActionWithPayloadRemoveField
} from '../models/actionWithPayload';
import { catchError, exhaustMap, map, mergeMap, retry, switchMap, tap } from 'rxjs/operators';
import {
  AccountActionsTypes,
  getUser, saveUsersClosedTexts,
  setDeletedAccount,
  setUser, setUsersClosedModulesTexts, setUsersClosedTexts,
} from '../actions/account';
import {
  deleteStation,
  getStations,
  getWarnings,
  setStations,
  setUnitSystem,
  setVirtualStationElevation,
  setVirtualStationTimezone,
  setWarnings,
  StationsActionTypes
} from '../actions/stations';
import { getUserNotifications, NotificationsActionTypes, setUserNotifications } from '../actions/notifications';
import { IUserRequest } from '../models/userRequest';
import { IPosition, IStation, IWarningsResponseItem } from '../models/stations';
import { DiseasesActionTypes, getDiseases, setDiseases } from '../actions/diseases';
import { IDisease } from '../models/diseases.models';
import { setNotify } from '../actions/notify';
import { LanguageService } from '../../services/translation/language.service';
import { ModalService } from '../../shared/modal/services/modal.service';
import {
  ADD_CROPZONE,
  ADD_REMOVE_MODAL_ID,
  ADD_REMOVE_VIRTUAL_STATION_MODAL_ID,
  REMOVE_CROPZONE
} from '../../shared/constants';
import { IApiAccess } from '../models/account';
import { ISystemSensor } from '../../shared/interfaces';
import { setCalibrationSettings, setSystemSensors, SystemActionsTypes } from '../actions/system';
import { ICalibrationSetting } from '../models/system';
import { GoogleMapService } from '../../modules/station-config/services/google-map.service';
import {
  changeCropzone,
  CropZoneActionTypes,
  deleteCropzone,
  getCropZones,
  getYpmConstants,
  setCropZone,
  setCropZones,
  setExecutingAddCropzone,
  setYpmConstants
} from '../actions/cropzones';
import { ICropZone, IFarm, ILayer, ISoil } from '../models/cropzones';
import { IField } from '../models/fields';
import { deleteFarm, FarmActionTypes, getFarms, setFarm, setFarms } from '../actions/farms';
import { deleteField, FieldActionTypes, getFields, setField, setFields } from '../actions/fields';
import {
  redirectAfterDeletion,
  redirectAfterFieldDeletion,
  redirectAfterSuccessfullCreation,
  SelectedCropZoneActionTypes,
  setSelectedCropZone
} from '../actions/selectedCropZone';
import { NavigationService } from '../services/navigation/navigation.service';
import { Router } from '@angular/router';
import * as fromSelectedCropzone from '../reducers/selectedCropZone';
import * as fromNavigationCropzone from '../reducers/navigation-cropzone';
import { Action, Store } from '@ngrx/store';
import * as fromCropzones from '../reducers/cropzones';
import { INotifications } from '../reducers/notifications';
import { DatesTranslationsService } from '../services/dates-translations.service';
import { ProductLicenseActionTypes, setProductLicenses } from '../actions/licenses';
import { IProductLicense } from '../models/licenses';
import { AccumulatorToolActionTypes, getAccumulatorThresholds, setAccumulatorThresholds, updateAccumulatorThresholds } from '../actions/accumulatorTool';


@Injectable()
export class AccountService {

  constructor(
    private api: ApiCallService,
    private language: LanguageService,
    private actions$: Actions,
    private modalService: ModalService,
    private navigationService: NavigationService,
    private router: Router,
    private navigationCropzoneStore: Store<fromNavigationCropzone.INavigationCropzoneState>,
    private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private cropzoneStore: Store<fromCropzones.ICropZones>,
    private googleMapService: GoogleMapService,
    private dateService: DatesTranslationsService
  ) {

  }

  @Effect()
  public getUser$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(AccountActionsTypes.GET_USER),
    switchMap(() => this.api.getUser().pipe(
      switchMap((user: IUserRequest) => {
        this.language.setLanguage(user.settings.language);
        return from([
          setUser(user),
          getStations(),
          getCropZones(),
          getDiseases(),
          getFarms(),
          getFields(),
          getUserNotifications(),
          getYpmConstants(),
          getAccumulatorThresholds()
        ]);
      }),
      catchError(() => from([]))
      )
    )
  );

  @Effect()
  public getCropZones$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CropZoneActionTypes.GET_CROPZONES),
    switchMap(() => this.api.getCropZones().pipe(
      switchMap((cropzones: Array<ICropZone>) => of(setCropZones(cropzones))),
      catchError(() => from([]))
      )
    )
  );

  @Effect()
  public getCropzone$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CropZoneActionTypes.GET_CROPZONE),
    switchMap((action: IActionWithPayload) => this.api.getCropzone(action.payload).pipe(
      mergeMap((cropzone: ICropZone) => [
        setCropZone(cropzone),
        setSelectedCropZone(cropzone)
      ]),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public addCropZone$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CropZoneActionTypes.ADD_CROPZONE),
    switchMap((action: IActionWithPayload) => this.api.addCropZone(action.payload.add).pipe(
      switchMap((id: string) => {

        const data = this.dateService.createDateRange();

        const soil: ISoil = {
          layers: [],
          root_restriction_depth: 2,
          initial_soil_water_content: 0.4
        };

        for (let i = 10; i <= 60; i += 10) {
          soil.layers.push(<ILayer>{
            depth: i,
            field_capacity: 28,
            refill_point: 14,
            unit: '%'
          });
        }

        const new_cropzone = {
          id: id,
          name: action.payload.add.name,
          crop_name: '...',
          from: data.today,
          to: data.aYearFromNow,
          farm: action.payload.add.farm,
          field: action.payload.add.field,
          year: data.fullYear,
          boundary: null,
          crop: null,
          soil: soil,
          rain_efficacies: [],
          data_sources: [],
          irrigation_events: [],
          product_key: action.payload.add.product_key || null
        };

        this.modalService.closeModal(ADD_CROPZONE);

        if (action.payload.redirect) {
          return from([
            getCropZones(),
            setCropZone({ id, ...new_cropzone }),
            setNotify('Cropzone successfully added'),
            setSelectedCropZone(new_cropzone),
            setExecutingAddCropzone(false),
            redirectAfterSuccessfullCreation({ id: id, cropzone: new_cropzone })
          ]);
        } else {
          return from([
            getCropZones(),
            setCropZone({ id, ...new_cropzone }),
            setExecutingAddCropzone(false),
            setNotify('Cropzone successfully added'),
          ]);
        }
      }),
      catchError(({ error }) => from([
        setExecutingAddCropzone(false),
        setNotify(error)
      ]))
      )
    )
  );

  @Effect()
  public updateCropzone$ = this.actions$.pipe(
    ofType(CropZoneActionTypes.UPDATE_CROPZONE),
    exhaustMap((action: IActionWithPayload) =>
      this.api.updateCropzone(action.payload).pipe(
        switchMap(() => [
          changeCropzone(action.payload),
          setSelectedCropZone(action.payload),
          setNotify('Cropzone successfully updated')
        ]),
        catchError(() => of(setNotify('Error updating cropzone')))
      )
    )
  );

  @Effect({ dispatch: false })
  public redirectAfterCreateCropzone = this.actions$.pipe(
    ofType(SelectedCropZoneActionTypes.REDIRECT_AFTER_CREATION),
    tap((action: IActionWithPayload) => {
      this.router.navigate([`/cropzone/${action.payload.id}/config`]);
    })
  );

  @Effect({ dispatch: false })
  public redirectAfterCropzoneDeletion = this.actions$.pipe(
    ofType(SelectedCropZoneActionTypes.REDIRECT_AFTER_DELETION),
    tap((action: IActionWithExtraPayload) => {
      const splittedUrl = action.url.split('/');
      if (splittedUrl[1] === 'cropzone') {
        if (splittedUrl[2] === action.payload.id) {
          this.router.navigate(['/dashboard']);
        }
      }
    })
  );

  @Effect({ dispatch: false })
  public redirectAfterFieldDeletion = this.actions$.pipe(
    ofType(SelectedCropZoneActionTypes.REDIRECT_AFTER_FIELD_DELETION),
    tap((action: IActionWithPayloadRedirectAfterFieldDeletion) => {
      const splittedUrl = action.url.split('/');
      if (action.cropzones.length > 0) {
        if (splittedUrl[1] = 'cropzone') {
          for (let i = 0; i < action.cropzones.length; i++) {
            if (splittedUrl[2] === action.cropzones[i].id) {
              this.router.navigate(['/dashboard']);
            }
          }
        }
      }
    })
  );

  @Effect()
  public addCropZoneField$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CropZoneActionTypes.ADD_CROPZONE_FIELD),
    switchMap((action: IActionWithPayload) => this.api.addField({
      name: action.payload.add.field.name,
      farm: action.payload.add.farm
    }).pipe(
      switchMap((id: string) => {
        const field_id = id;

        const new_payload = {
          name: action.payload.add.name,
          from: action.payload.add.from,
          crop_name: action.payload.add.crop_name,
          to: action.payload.add.to,
          field: {
            name: action.payload.add.field.name,
            id: id
          },
          farm: action.payload.add.farm,
          boundary: action.payload.add.boundary,
          product_key: action.payload.add.product_key || null
        };

        const object = {
          name: action.payload.add.field.name,
          id: field_id,
          farm: action.payload.add.farm
        };

        return this.api.addCropZone(new_payload).pipe(
          switchMap((idCropzone: string) => {

            const data = this.dateService.createDateRange();

            const new_cropzone = {
              id: idCropzone,
              name: action.payload.add.name,
              crop_name: '...',
              from: data.today,
              to: data.aYearFromNow,
              field: {
                name: action.payload.add.field.name,
                id: field_id
              },
              farm: action.payload.add.farm,
              year: data.fullYear,
              boundary: null,
              crop: null,
              soil: null,
              rain_efficacies: [],
              data_sources: [],
              irrigation_events: [],
              product_key: action.payload.add.product_key || null
            };

            this.modalService.closeModal(ADD_CROPZONE);
            if (action.payload.redirect) {
              return from([
                setNotify('Field and cropzone successfully created'),
                getFields(),
                setField({ id: field_id, ...object }),
                getCropZones(),
                setCropZone({ id: idCropzone, ...new_cropzone }),
                setSelectedCropZone(new_cropzone),
                setExecutingAddCropzone(false),
                redirectAfterSuccessfullCreation({ id: idCropzone, cropzone: new_cropzone })
              ]);
            } else {
              return from([
                setNotify('Field and cropzone successfully created'),
                getFields(),
                setField({ id: field_id, ...object }),
                getCropZones(),
                setCropZone({ id: idCropzone, ...new_cropzone }),
                setExecutingAddCropzone(false),
              ]);
            }
          }),
          catchError(() => from([
            setExecutingAddCropzone(false),
            setNotify('Error creating cropzone.')
          ]))
        );
      })
    ))
  );

  @Effect()
  public addCropZoneFieldFarm$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CropZoneActionTypes.ADD_CROPZONE_FIELD_FARM),
    switchMap((action: IActionWithPayload) => this.api.addFarm({ name: action.payload.add.farm.name }).pipe(
      switchMap((farm_id: string) => {
        return this.api.addField({
          name: action.payload.add.field.name,
          farm: { name: action.payload.add.farm.name, id: farm_id }
        }).pipe(
          switchMap((field_id: string) => {
            const new_payload = {
              name: action.payload.add.name,
              from: action.payload.add.from,
              crop_name: action.payload.add.crop_name,
              to: action.payload.add.to,
              field: {
                name: action.payload.add.field.name,
                id: field_id
              },
              farm: {
                name: action.payload.add.farm.name,
                id: farm_id
              },
              boundary: action.payload.add.boundary,
              product_key: action.payload.add.product_key || null
            };

            const object = {
              name: action.payload.add.field.name,
              id: field_id,
              farm: action.payload.add.farm
            };

            return this.api.addCropZone(new_payload).pipe(
              switchMap((cropzone_id: string) => {

                const data = this.dateService.createDateRange();

                const new_cropzone: ICropZone = {
                  id: cropzone_id,
                  name: action.payload.add.name,
                  crop_name: '...',
                  from: data.today,
                  to: data.aYearFromNow,
                  field: {
                    name: action.payload.add.field.name,
                    id: field_id
                  },
                  farm: {
                    name: action.payload.add.farm.name,
                    id: farm_id
                  },
                  year: data.fullYear,
                  boundary: null,
                  crop: null,
                  soil: null,
                  rain_efficacies: [],
                  data_sources: [],
                  irrigation_events: [],
                  product_key: action.payload.add.product_key || null
                };

                this.modalService.closeModal(ADD_CROPZONE);
                if (action.payload.redirect) {
                  return from([
                    getFarms(),
                    setFarm({ farm_id, ...action.payload.add.farm }),
                    getFields(),
                    setField({ id: field_id, ...object }),
                    getCropZones(),
                    setCropZone({ id: cropzone_id, ...new_cropzone }),
                    setSelectedCropZone(new_cropzone),
                    setNotify('Farm, field and cropzone successfully created'),
                    setExecutingAddCropzone(false),
                    redirectAfterSuccessfullCreation({ id: cropzone_id, cropzone: new_cropzone })
                  ]);
                } else {
                  return from([
                    getFarms(),
                    setFarm({ farm_id, ...action.payload.add.farm }),
                    getFields(),
                    setField({ id: field_id, ...object }),
                    getCropZones(),
                    setCropZone({ id: cropzone_id, ...new_cropzone }),
                    setExecutingAddCropzone(false),
                    setNotify('Farm, field and cropzone successfully created'),
                  ]);
                }
              }),
              catchError(() => from([
                setExecutingAddCropzone(false),
                setNotify('Error creating cropzone.')
              ]))
            );
          })
        );
      })
    ))
  );

  @Effect()
  public addFarm$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(FarmActionTypes.ADD_FARM),
    switchMap((action: IActionWithPayload) => this.api.addFarm(action.payload).pipe(
      switchMap((id: string) => {
        return from([
          getFarms(),
          setFarm({ id, ...action.payload.name }),
          setNotify('Farm successfully created')
        ]);
      }),
      catchError(() => of(setNotify('Error creating farm')))
      )
    )
  );

  @Effect()
  public getFarms$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(FarmActionTypes.GET_FARMS),
    switchMap(() => this.api.getFarms().pipe(
      switchMap((farms: Array<IFarm>) => of(setFarms(farms))),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public addFarmAndField$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(FieldActionTypes.ADD_FARM_FIELD),
    switchMap((action: IActionWithPayload) => this.api.addFarm(action.payload.farm).pipe(
      switchMap((farm_id: string) => {
        setFarm({ farm_id, ...action.payload.farm.name });

        const new_payload = {
          name: action.payload.name,
          farm: {
            name: action.payload.farm.name,
            id: farm_id
          }
        };

        return this.api.addField(new_payload).pipe(
          switchMap((field_id: string) => {
            return from([
              getFields(),
              setField({ id: field_id, ...new_payload }),
              getFarms(),
              setFarm({ id: farm_id, ...action.payload.farm.name }),
              setNotify('Farm and field succesfully created.')
            ]);
          }),
          catchError(() => of(setNotify('Error creating field')))
        );
      })
    ))
  );

  @Effect()
  public addField$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(FieldActionTypes.ADD_FIELD),
    switchMap((action: IActionWithPayload) => this.api.addField(action.payload).pipe(
      switchMap((id: string) => {
        this.modalService.closeModal(ADD_CROPZONE);
        return from([
          getFields(),
          setField({ id, ...action.payload }),
          setNotify('Field successfully created')
        ]);
      }),
      catchError(() => of(setNotify('Error creating field')))
      )
    )
  );

  @Effect()
  public getFields$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(FieldActionTypes.GET_FIELDS),
    switchMap(() => this.api.getFields().pipe(
      switchMap((fields: Array<IField>) => of(setFields(fields))),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public getStations$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(StationsActionTypes.GET_STATIONS),
    switchMap(() => this.api.getStations().pipe(
      switchMap((stations: Array<IStation>) => of(setStations(stations))),
      catchError(() => from([]))
      )
    )
  );

  @Effect()
  public addStation$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(StationsActionTypes.ADD_STATION),
    switchMap((action: IActionWithPayload) => this.api.addStation(action.payload).pipe(
      switchMap(() => {
        this.modalService.closeModal(ADD_REMOVE_MODAL_ID);
        return from([
          getStations(),
          setNotify('Station successfully added')
        ]);
      }),
      catchError((error: any) => of(setNotify(error.status === 402
        ? 'This station has been disabled. Please contact your Metos support.'
        : 'Error adding station. Please check your Station ID/Station Key number'))
      )
      )
    ));

  @Effect()
  public sendUserMessage$: Observable<any> = this.actions$.pipe(
    ofType(AccountActionsTypes.SEND_SUPPORT_MESSAGE),
    switchMap((action: IActionWithPayload) => this.api.sendUserMessage(action.payload).pipe(
      switchMap(() => of(setNotify('The message was successfully sent'))),
      catchError(() => of(setNotify('Something went wrong')))
    )));

  @Effect()
  public removeStation$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(StationsActionTypes.REMOVE_STATION),
    switchMap((action: IActionWithPayload) => this.api.removeStation(action.payload).pipe(
      switchMap(() => {
        this.modalService.closeModal(ADD_REMOVE_MODAL_ID);
        return from([
          deleteStation(action.payload.id),
          setNotify('Station successfully deleted')
        ]);
      }),
      catchError(() => of(setNotify('Error deleting station. Please check your Station ID/Station Key number')))
      )
    )
  );

  @Effect()
  public removeCropzone$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CropZoneActionTypes.REMOVE_CROPZONE),
    switchMap((action: IActionWithPayload) => this.api.removeCropzone(action.payload).pipe(
      switchMap(() => {
        this.modalService.closeModal(REMOVE_CROPZONE);
        return from([
          deleteCropzone(action.payload.id),
          setNotify('Cropzone successfully deleted'),
          redirectAfterDeletion(action.payload, this.router.url)
        ]);
      }),
      catchError(() => of(setNotify('Error deleting cropzone. Please check your cropzone, field and farm name')))
    ))
  );

  @Effect()
  public getYpmConstants$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(CropZoneActionTypes.GET_YPM_CONSTANTS),
    switchMap(() => this.api.getYpmConstants().pipe(
      switchMap((constants: any) => of(setYpmConstants(constants))),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public removeField$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(FieldActionTypes.REMOVE_FIELD),
    switchMap((action: IActionWithPayloadRemoveField) => this.api.removeField(action.payload).pipe(
      switchMap(() => {
        const cropzonesToDelete = action.cropzones;
        const field = action.payload;
        return from([
          deleteField(field.id),
          getCropZones(),
          setNotify('Field successfully deleted'),
          redirectAfterFieldDeletion(field, cropzonesToDelete, this.router.url)
        ]);
      }),
      catchError(() => of(setNotify('Error deleting field. please check your field and farm name')))
    ))
  );

  @Effect()
  public removeFieldWithoutCropzones$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(FieldActionTypes.REMOVE_FIELD_WITHOUT_CROPZONES),
    switchMap((action: IActionWithPayload) => this.api.removeField(action.payload).pipe(
      switchMap(() => {
        const field = action.payload;
        return from([
          deleteField(field.id),
          setNotify('Field successfully deleted')
        ]);
      }),
      catchError(() => of(setNotify('Error deleting field. please check your field and farm name')))
    ))
  );

  @Effect()
  public removeFarm$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(FarmActionTypes.REMOVE_FARM),
    switchMap((action: IActionWithPayloadRemoveField) => this.api.removeFarm(action.payload).pipe(
      switchMap(() => {
        const cropzonesToDelete = action.cropzones;
        const farm = action.payload;
        return from([
          deleteFarm(farm.id),
          getCropZones(),
          getFields(),
          setNotify('Farm successfully deleted'),
          redirectAfterFieldDeletion(farm, cropzonesToDelete, this.router.url)
        ]);
      }),
      catchError(() => of(setNotify('Error deleting farm. please check your farm name')))
    ))
  );

  @Effect()
  public removeFarmWithoutCropzones$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(FarmActionTypes.REMOVE_FARM_WITHOUT_CROPZONES),
    switchMap((action: IActionWithPayload) => this.api.removeFarm(action.payload).pipe(
      switchMap(() => {
        const farm = action.payload;
        return from([
          deleteFarm(farm.id),
          setNotify('Farm successfully deleted')
        ]);
      })
    ))
  );

  @Effect()
  public addVirtualStation$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(StationsActionTypes.ADD_VIRTUAL_STATION),
    switchMap((action: IActionWithPayload) => this.api.addVirtualStation(action.payload).pipe(
      switchMap(() => {
        this.modalService.closeModal(ADD_REMOVE_VIRTUAL_STATION_MODAL_ID);
        return from([
          getStations(),
          setNotify('Virtual station successfully added')
        ]);
      }),
      catchError(({ error }: any) => of(setNotify(error.message)))
    ))
  );

  @Effect()
  public removeVirtualStation$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(StationsActionTypes.REMOVE_VIRTUAL_STATION),
    switchMap((action: IActionWithPayload) => this.api.removeVirtualStation(action.payload).pipe(
      switchMap((res: any) => {
        this.modalService.closeModal(ADD_REMOVE_VIRTUAL_STATION_MODAL_ID);
        return from([
          deleteStation(res.station_id),
          setNotify('Virtual station successfully deleted')
        ]);
      }),
      catchError(({ error }: any) => of(setNotify(error.message)))
    ))
  );

  @Effect()
  public changeVirtualStationElevation$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(StationsActionTypes.CHANGE_VIRTUAL_STATION_ELEVATION),
    map((action: IActionWithPayload) => action.payload),
    switchMap(({ geo: { coordinates } }: IPosition) => {
      return this.googleMapService.getElevator(coordinates[1], coordinates[0]).pipe(
        switchMap((elevation: number) => of(setVirtualStationElevation(elevation))),
        catchError(() => EMPTY)
      );
    })
  );

  @Effect()
  public changeVirtualStationTimezone$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(StationsActionTypes.CHANGE_VIRTUAL_STATION_TIMEZONE),
    map((action: IActionWithPayload) => action.payload),
    switchMap(({ geo: { coordinates } }: IPosition) => this.api.getTimezoneFromAPI(coordinates).pipe(
      switchMap((res: any) => of(setVirtualStationTimezone(res.timeZoneId))),
      catchError(() => EMPTY)
    ))
  );

  @Effect()
  public getWarnings$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(StationsActionTypes.GET_WARNINGS),
    switchMap((action: IActionWithPayload) => this.api.getStationsWarnings(action.payload).pipe(
      switchMap((warnings: IWarningsResponseItem[]) => {
        return of(setWarnings(warnings));
      }),
      catchError(() => EMPTY)
    ))
  );

  @Effect()
  public getDiseases$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(DiseasesActionTypes.GET_DISEASES),
    switchMap(() => this.api.getDiseases().pipe(
      switchMap((diseases: Array<IDisease>) => of(setDiseases(diseases))),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public savePassword$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(AccountActionsTypes.SAVE_PASSWORD),
    switchMap((action: IActionWithPayload) => this.api.savePassword(action.payload).pipe(
      switchMap(() => of(setNotify('Password was updated successfully'))),
      catchError(() => of(setNotify('Something went wrong. Please check your form data')))
    ))
  );

  @Effect()
  public saveNotifications$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(AccountActionsTypes.SAVE_NOTIFICATIONS),
    switchMap((action: IActionWithPayload) => this.api.saveNotifications(action.payload).pipe(
      switchMap((response: any) => of(
        setNotify(!Array.isArray(response)
          ? 'Notifications were updated successfully'
          : 'No changes have been made yet. Edit the status before updating.'))),
      catchError(() => of(setNotify('No changes have been made yet. Edit the status before updating.')))
    ))
  );

  @Effect()
  public saveOtherSettings$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(AccountActionsTypes.SAVE_OTHER_SETTINGS),
    switchMap((action: IActionWithPayload) => this.api.saveOtherSettings(action.payload).pipe(
      switchMap((response: any) => [
        setNotify(!Array.isArray(response) ? 'User updated successfully'
          : 'All entered data already exists. Please change form data and try again')
      ].concat(!Array.isArray(response) ? [
        getWarnings(),
        setUnitSystem(action.payload['settings.unit_system'])
      ] : [])),
      catchError((error: any) => of(setNotify(error.status === 409
        ? 'Entered Username/Email already exists'
        : 'All entered data already exists. Please change form data and try again')))
    ))
  );

  @Effect()
  public saveTerms$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(AccountActionsTypes.SAVE_TERMS),
    switchMap((action: IActionWithPayload) => this.api.saveTerms(action.payload.terms).pipe(
      switchMap(() => from([
        setUser(action.payload.account)
      ])),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public getNewApiKeys$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(AccountActionsTypes.GET_NEW_API_KEYS),
    switchMap((action: IActionWithPayload) => this.api.getNewApiKeys().pipe(
      switchMap((apiAccess: IApiAccess) => of(getUser())),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public getSystemSensors$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(SystemActionsTypes.GET_SYSTEM_SENSORS),
    switchMap((action: IActionWithPayload) => this.api.getSystemSensors().pipe(
      switchMap((response: { [index: number]: ISystemSensor }) => of(setSystemSensors(response))),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public getCalibrationSettings$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(SystemActionsTypes.GET_CALIBRATION_SETTINGS),
    switchMap(() => this.api.getCalibrationSettings().pipe(
      switchMap((response: ICalibrationSetting[]) => of(setCalibrationSettings(response))),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public getUserNotifications$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(NotificationsActionTypes.GET_USER_NOTIFICATIONS),
    switchMap(() => this.api.getUserNotifications().pipe(
      switchMap((notifications: INotifications) => of(setUserNotifications(notifications))),
      catchError(() => from([]))
      )
    )
  );

  @Effect()
  public saveToken$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(NotificationsActionTypes.SAVE_USER_WEB_TOKEN),
    switchMap((action: IActionWithPayload) => this.api.saveUserToken(action.payload).pipe(
      retry(2),
      map(() => setNotify('Web Notifications were activated successfully')),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public getProductLicenses$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ProductLicenseActionTypes.GET_PRODUCT_LICENSES),
    switchMap((action: IActionWithPayload) => this.api.getProductLicense(action.payload).pipe(
      switchMap((licenses: Array<IProductLicense>) => of(setProductLicenses(licenses))),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public closeAccount$: Observable<Action> = this.actions$.pipe(
    ofType(AccountActionsTypes.CLOSE_ACCOUNT),
    switchMap(() => this.api.closeAccount().pipe(
      switchMap(() => from([
        setDeletedAccount(true)])
      ),
      catchError(() => of(setNotify('Account was not closed.')))
    ))
  );

  @Effect()
  public getUsersClosedModulesTexts$: Observable<Action> = this.actions$.pipe(
    ofType(AccountActionsTypes.GET_USERS_CLOSED_MODULES_TEXTS),
    switchMap(() => this.api.getUsersClosedModulesTexts().pipe(
      switchMap((closedTexts: Array<string>) => {
        return from([setUsersClosedModulesTexts(closedTexts)]);
      })
    ))
  );

  @Effect()
  public updateClosedModulesTexts$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(AccountActionsTypes.UPDATE_USERS_CLOSED_MODULES_TEXTS),
    switchMap((action: IActionWithPayload) => this.api.updateClosedModulesTexts(action.payload).pipe(
      switchMap((res) => of(setNotify('Dashboard texts list was successfully saved.')))
    ))
  );

  @Effect()
  public getUsersClosedTexts$: Observable<Action> = this.actions$.pipe(
    ofType(AccountActionsTypes.GET_USERS_CLOSED_TEXTS),
    switchMap(() => this.api.getUsersClosedTexts().pipe(
      switchMap((closedTexts: Array<string>) => {
        if (closedTexts) {
          return from([
            setUsersClosedTexts(closedTexts)        // save the data to state
          ]);
        } else {
          return from([
            saveUsersClosedTexts([]),     // create a new field in the database
            setUsersClosedTexts([])       // save the empty data to state
          ]);
        }
      }
      ),
      catchError(() => from([]))
    ))
  );

  @Effect()
  public saveUsersClosedTexts$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(AccountActionsTypes.SAVE_USERS_CLOSED_TEXTS),
    switchMap((action: IActionWithPayload) => this.api.createUsersClosedTexts(action.payload).pipe(
      switchMap(() => of(setNotify('Dashboard texts list was successfully saved.'))),
      catchError(({ error }: any) => of(setNotify(error.message)))
    ))
  );

  @Effect()
  public getAccumulatorToolThresholds$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(AccumulatorToolActionTypes.GET_THRESHOLDS),
    switchMap((action: IActionWithPayload) => this.api.getAccumulatorToolThresholds().pipe(
        switchMap((result) => from([setAccumulatorThresholds(result)]))
    ))
  );

  @Effect()
  public updateAccumutlatorToolThresholds$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(AccumulatorToolActionTypes.UPDATE_THRESHOLDS),
    switchMap((action: IActionWithPayload) => this.api.updateAccumulatorThresholds(action.payload).pipe(
        switchMap((result) => from([setAccumulatorThresholds(action.payload)]))
    ))
  );

  @Effect()
  public updateStarredStation$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(AccountActionsTypes.UPDATE_STARRED_STATION),
    switchMap((action: IActionWithPayload) => this.api.saveStarredStation(
        action.payload.starred,
        action.payload.stationID
      ).pipe(
        switchMap(() => {
          const addedText = 'Station added to your favorites.';
          const removedText = 'Station removed from your favorites.';
          const status = action.payload.starred ? addedText : removedText;
          return from([
          setStations(action.payload.stations),
          setNotify(status)
        ]);
        }),
        catchError(() => of(setNotify('Error: Unsuccessfully added to favorites.')))
      )
    )
  );

  @Effect()
  public updateStarredCropzone$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(AccountActionsTypes.UPDATE_STARRED_CROPZONE),
    switchMap((action: IActionWithPayload) => this.api.updateCropzone(
      action.payload
    ).pipe(
      switchMap(() => {
        const addedText = 'Cropzone added to your favorites.';
        const removedText = 'Cropzone removed from your favorites.';
        const status = action.payload.starred ? addedText : removedText;
        return from([
          setCropZone(action.payload),
          setNotify(status)
        ]);
      }),
      catchError(() => of(setNotify('Error: Unseccessfully added to favorites.')))
    ))
  );
}
