import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserSupportComponent} from './containers/user-support/user-support.component';


const routes: Routes = [
  { path: '', component: UserSupportComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class UserSupportRoutingModule { }
