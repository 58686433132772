import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ICropZone } from '../../../../core/models/cropzones';
import * as fromCropzones from '../../../../core/reducers/cropzones';
import * as fromDashboard from '../../reducers/dashboard';
import { selectCropzones } from './../../../../core/reducers/index';
import { selectWiddgetLoadDataByPath } from './../../reducers/index';

@Component({
  selector: 'app-cropzone-list',
  templateUrl: './cropzone-list.component.html',
  styleUrls: ['./cropzone-list.component.scss']
})
export class CropzoneListComponent implements OnInit, OnDestroy {
  @Input()
  private widgetIndex: number;
  @Input()
  private columnIndex: number;
  @Input()
  private rowIndex: number;

  public cropzones: Array<ICropZone> = [];
  public loadDataLocalWithValues: Array<any>;
  public cropzoneListWidgetItems: Array<any> = [];
  public show: boolean = true;

  private alive$ = new Subject<boolean>();
  constructor(private cropzonesStore: Store<fromCropzones.ICropZones>,
    private dashboardStore: Store<fromDashboard.IDashboard>) { }

  public ngOnInit(): void {
    this.cropzonesStore.pipe(
      select(selectCropzones),
      takeUntil(this.alive$)
    ).subscribe(cropzones => {
      this.cropzones = JSON.parse(JSON.stringify(cropzones));
    });

    this.dashboardStore.pipe(
      select(selectWiddgetLoadDataByPath(this.rowIndex, this.columnIndex, this.widgetIndex)),
      filter((loadData) => !!loadData),
      takeUntil(this.alive$)
    ).subscribe(loadData => {
      const loadDataLocal: Array<any> = JSON.parse(JSON.stringify(loadData));
      this.loadDataLocalWithValues = loadDataLocal.filter((data) => !!data.sum_sm && !!data.refill_point && !!data.field_capacity);

      this.cropzones.forEach(cropzone => {
        this.loadDataLocalWithValues.forEach(data => {
          let dataForCropzoneListWidget: any;

          if (cropzone.id === data.cropzone.id) {
            if (data.unit === 'mm') {
              dataForCropzoneListWidget = {
                cropzone: cropzone,
                sum_sm: Number.parseFloat((data.sum_sm).toFixed(1)),
                refill_point: Number.parseFloat((data.refill_point).toFixed(1)),
                field_capacity: Number.parseFloat((data.field_capacity).toFixed(1)),
                unit: data.unit,
                depth_unit: data.depth_unit
              };
            } else {
              dataForCropzoneListWidget = {
                cropzone: cropzone,
                sum_sm: Number.parseFloat((data.sum_sm).toFixed(3)),
                refill_point: Number.parseFloat((data.refill_point).toFixed(3)),
                field_capacity: Number.parseFloat((data.field_capacity).toFixed(3)),
                unit: data.unit,
                depth_unit: data.depth_unit
              };
            }

            this.cropzoneListWidgetItems.push(dataForCropzoneListWidget);
          }
        });
      });

      const filteredWidgetItems = this.cropzoneListWidgetItems.filter(item => item.cropzone.to >= new Date().toISOString());

      this.cropzoneListWidgetItems = filteredWidgetItems.sort((a, b) => {
        if (((a.sum_sm - a.refill_point) / (a.field_capacity - a.refill_point)) >
          ((b.sum_sm - b.refill_point) / (b.field_capacity - b.refill_point))) {
          return 1;
        } else {
          return -1;
        }
      });
    });
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }

  public onResize(): void {
    this.show = false;
    setTimeout(() => this.show = true);
  }
}
