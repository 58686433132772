import {IActionWithPayload} from '../models/actionWithPayload';
import {NotifyActionTypes} from '../actions/notify';

export interface INotifyState {
  notify: string;
}

const initialState: INotifyState = {
  notify: null
};

export function reducer(state: INotifyState = initialState, action: IActionWithPayload ): INotifyState {
  switch (action.type) {
    case NotifyActionTypes.SET_NOTIFY: {
      return {
        ...state,
        notify: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
