import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { card4Animation } from '../../../../shared/animations/card-animations';
import { environmentToken } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../../../environments/interfaces/theme';
import { IOptions } from '../../../../shared/interfaces';
import {
  getAvailableDiseaseModels,
  getAvailableUserStations,
  getProductActivationByKey,
  setProductActivationConfiguration
} from '../../actions/product-activation';
import { FeatureType, WARNING_PURCHASE_LICENSES } from '../../constants/constants';
import {
  IAvailableUserStation,
  IProductActivationState,
  IProductConfiguration,
  IProductConfigurationItem, IValidLicense, IValidLicenseMap
} from '../../models/product-activation.models';
import {
  selectAvailableUserStations,
  selectProductConfiguration,
  selectValidLicenses
} from '../../reducers';

@Component({
  selector: 'app-product-activation',
  templateUrl: './product-activation.component.html',
  styleUrls: ['./product-activation.component.scss'],
  animations: [card4Animation()]
})
export class ProductActivationComponent implements OnInit, OnDestroy {
  public productType              = FeatureType;
  public productConfiguration$    : Observable<IProductConfiguration>;
  public availableUserStations$   : Observable<IOptions[]>;
  public validLicenses            : IValidLicenseMap = {};
  public theme                    : IThemeConfig;
  public warningPurchaseLicenses  : any;

  private destroy$                : Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(environmentToken) environment : IEnvironment,
    private productActivationStore        : Store<IProductActivationState>
  ) {
    this.theme = environment.theme;
    this.warningPurchaseLicenses = this.theme.hasOwnProperty('customWarningProductActivation') ?
      WARNING_PURCHASE_LICENSES[this.theme.subDomain] :
      WARNING_PURCHASE_LICENSES['default'];
  }

  public ngOnInit(): void {
    this.initDataListeners();
    this.initDataRetrieving();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public findProductsToActivate(activationKey: string): void {
    this.productActivationStore.dispatch(getProductActivationByKey(activationKey));
    this.productActivationStore.dispatch(getAvailableUserStations(activationKey));
  }

  public deselectProductConfiguration(): void {
    this.productActivationStore.dispatch(setProductActivationConfiguration(null));
  }

  public getProductLicenses(productPos: number): IValidLicense[] {
    return this.validLicenses[productPos] || [];
  }

  private initDataListeners(): void {
    this.productConfiguration$ = this.productActivationStore.pipe(
      takeUntil(this.destroy$),
      select(selectProductConfiguration),
      map((configuration: IProductConfiguration) => {
        if (configuration === null) {
          return null;
        }

        const products: IProductConfigurationItem[] = Object.values(configuration.products);

        const isAllActivated = products.reduce((result: boolean, current: IProductConfigurationItem): boolean => {
          return result && current.activated;
        }, true);

        return {
          ...configuration,
          products,
          isAllActivated
        };
      })
    );

    this.availableUserStations$ = this.productActivationStore.pipe(
      takeUntil(this.destroy$),
      select(selectAvailableUserStations),
      map((userStations: IAvailableUserStation[]) => userStations.map((station: IAvailableUserStation) => ({
        content: station.station_name,
        value: station.custom_name ? `${station.station_name} (${station.custom_name})` : station.station_name
      }))),
      map((userStations) => userStations.sort((a, b) => a.value < b.value ? -1 : 1))
    );

    const validLicenses$ = this.productActivationStore.pipe(
      takeUntil(this.destroy$),
      select(selectValidLicenses)
    );
    validLicenses$.subscribe((validLicenses: IValidLicenseMap) => this.validLicenses = validLicenses);
  }

  private initDataRetrieving(): void {
    this.productActivationStore.dispatch(getAvailableDiseaseModels());
  }
}
