import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { ISumsSmData } from '../models';

export const enum ActionTypes {
    SET_SUMS_SM_INITIAL_STATE = '[CropZoneSoilMoisture] SET_SUMS_SM_INITIAL_STATE',
    SET_SUMS_SM_FROM_TO = '[CropZoneSoilMoisture] SET_SUMS_SM_FROM_TO',
    SET_SUMS_SM_ONLY_ACTIVE_ROOT_ZONE = '[CropZoneSoilMoisture] SET_SUMS_SM_ONLY_ACTIVE_ROOT_ZONE',
    SET_SUMS_SM_ROW_HIDDEN = '[CropZoneSoilMoisture] SET_SUMS_SM_ROW_HIDDEN',
    SET_SUMS_SM_SCOPE_AND_PERIOD = '[CropZoneSoilMoisture] SET_SUMS_SM_SCOPE_AND_PERIOD',
    GET_SUMS_SM_DATA = '[CropZoneSoilMoisture] GET_SUMS_SM_DATA',
    SET_SUMS_SM_DATA = '[CropZoneSoilMoisture] SET_SUMS_SM_DATA',
    SET_SUMS_SM_SET_LOADING_STATE = '[CropZoneSoilMoisture] SET_SUMS_SM_SET_LOADING_STATE'
}

export function setSumsSmInitialState(sourceCount: number): IAction {
    return { type: ActionTypes.SET_SUMS_SM_INITIAL_STATE, payload: { sourceCount } };
}

export function setSumsSmFromTo(from: Date, to: Date): IAction {
    return { type: ActionTypes.SET_SUMS_SM_FROM_TO, payload: { from, to } };
}

export function setSumsSmDisplayActiveRootZone(displayActiveRootZone: boolean): IAction {
    return { type: ActionTypes.SET_SUMS_SM_ONLY_ACTIVE_ROOT_ZONE, payload: { displayActiveRootZone } };
}

export function setSumsSmRowHidden(sourceIndex: number, rowIndex: number, hidden: boolean): IAction {
    return { type: ActionTypes.SET_SUMS_SM_ROW_HIDDEN, payload: { sourceIndex, rowIndex, hidden } };
}

export function setSumsSmScopeAndPeriod(scope: string, period: string): IAction {
    return { type: ActionTypes.SET_SUMS_SM_SCOPE_AND_PERIOD, payload: { scope, period } };
}

export function getSumsSmData(id: string, params: { from: number, to: number, group: string }): IAction {
    return { type: ActionTypes.GET_SUMS_SM_DATA, payload: { id, params } };
}

export function setSumsSmData(data: ISumsSmData[]): IAction {
    return { type: ActionTypes.SET_SUMS_SM_DATA, payload: { data } };
}

export function setSumsSmLoadingState(isLoading: boolean, isError: boolean): IAction {
    return { type: ActionTypes.SET_SUMS_SM_SET_LOADING_STATE, payload: { isLoading, isError } };
}



