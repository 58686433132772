import { registerLocaleData } from '@angular/common';
import localeAf from '@angular/common/locales/af';
import localeAr from '@angular/common/locales/ar';
import localeBg from '@angular/common/locales/bg';
import localeBr from '@angular/common/locales/br';
import localeCs from '@angular/common/locales/cs';
import localeDa from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeGr from '@angular/common/locales/el';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeEt from '@angular/common/locales/et';
import localeFa from '@angular/common/locales/fa';
import localeFr from '@angular/common/locales/fr';
import localeHe from '@angular/common/locales/he';
import localeHi from '@angular/common/locales/hi';
import localeHr from '@angular/common/locales/hr';
import localeHu from '@angular/common/locales/hu';
import localeIt from '@angular/common/locales/it';
import localeJp from '@angular/common/locales/ja';
import localeLt from '@angular/common/locales/lt';
import localeLv from '@angular/common/locales/lv';
import localeMk from '@angular/common/locales/mk';
import localeMr from '@angular/common/locales/mr';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeRo from '@angular/common/locales/ro';
import localeRu from '@angular/common/locales/ru';
import localeSk from '@angular/common/locales/sk';
import localeSi from '@angular/common/locales/sl';
import localeSq from '@angular/common/locales/sq';
import localeSr from '@angular/common/locales/sr';
import localeTr from '@angular/common/locales/tr';
import localeUk from '@angular/common/locales/uk';
import localeVn from '@angular/common/locales/vi';
import localeZh from '@angular/common/locales/zh';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CalendarDateFormatter } from 'angular-calendar';
import { MonthViewDay } from 'calendar-utils';
import * as deepEqual from 'deep-equal';
import * as moment from 'moment';
import { RRule } from 'rrule';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ICropZone } from '../../../../core/models/cropzones';
import { selectSelectedCropZone } from '../../../../core/reducers';
import * as fromSelectedCropzone from '../../../../core/reducers/selectedCropZone';
import { setCalendarEvents, setViewDate } from '../../actions/irrigation.action';
import { IIrrigationCalendarEvent, IManualCalendarEvent } from '../../models/models';
import { CustomDateFormatter } from '../../providers/custom-date-formatter.provider';
import { selectCalendarEvents, selectViewDate } from '../../reducers';
import * as fromIrrigationEvents from '../../reducers/irrimet.reducer';
import { IRainEvents } from './../../../../core/models/cropzones';
import { IAccount } from './../../../../core/reducers/account';
import { selectLanguage, selectSettings } from './../../../../core/reducers/index';

@Component({
  selector: 'app-irrimet-calendar',
  templateUrl: './irrimet-calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./irrimet-calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
})
export class IrrimetCalendarComponent implements OnInit, OnDestroy {
  @Input()
  public unitSystem: string;

  @Output()
  public modal = new EventEmitter<any>();

  @Output()
  public editModal = new EventEmitter<any>();

  @Output()
  public deleteRecurringModal = new EventEmitter<any>();

  @Output()
  public deleteModal = new EventEmitter<any>();

  @Output()
  public openEventListModal = new EventEmitter<any>();

  public calendarEvents$: Observable<any>;
  public view = 'month';
  public viewDate: Date;
  public cropzone: ICropZone;
  public events: any[] = [];
  public locale: String = 'en';
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private irrigationEventsStore: Store<fromIrrigationEvents.IIrrimetEventsState>,
    private accountStore: Store<IAccount>
  ) {
    this.accountStore.pipe(
      select(selectLanguage),
      filter((lang: string) => !!lang),
      takeUntil(this.destroy$)
    ).subscribe(language => {
      if (language === 'si') {
        language = 'sl';
      } else if (language === 'jp') {
        language = 'ja';
      } else if (language === 'vn') {
        language = 'vi';
      } else if (language === 'gr') {
        language = 'el';
      } else if (language === 'br') {
        language = 'pt-br';
      }

      this.locale = language;
    });
  }

  public ngOnInit(): void {
    this.registerLocaleDatas();

    this.accountStore.pipe(
      select(selectSettings)
    ).subscribe(settings => this.unitSystem = settings.unit_system);

    this.irrigationEventsStore.pipe(
      select(selectViewDate),
      takeUntil(this.destroy$)
    ).subscribe(viewDate => this.viewDate = viewDate);

    this.selectedCropzoneStore.pipe(
      select(selectSelectedCropZone),
      takeUntil(this.destroy$)
    ).subscribe(cropzone => {
      this.cropzone = cropzone;
      this.setEventsFromBackend(cropzone);
    });

    this.calendarEvents$ = this.irrigationEventsStore.pipe(
      select(selectCalendarEvents),
      takeUntil(this.destroy$),
      map((events) => events.filter((event) => event.amount !== 0))
    );
  }

  public createOnWeekView(segment: any): void {
    this.modal.emit(segment.date);
  }

  public createOnMonthView(day: MonthViewDay): void {
    this.modal.emit(day.date);
  }

  public openEventListModalFunction(day: MonthViewDay, eventType: string, isSplitted?: boolean): void {
    const clonedDay = JSON.parse(JSON.stringify(day));
    if (isSplitted) {
      clonedDay.events = day.events.filter((event) => {
        const startDate = moment(event.start).date();
        const endDate = moment(event.end).date();
        return startDate !== endDate;
      });
    } else {
      clonedDay.events = day.events.filter((event) => {
        const startDate = moment(event.start).date();
        const endDate = moment(event.end).date();
        return startDate === endDate;
      });
    }

    this.openEventListModal.emit({day: clonedDay, eventType: eventType, isSplitted: isSplitted});
  }

  public isSingleEvent(event: IIrrigationCalendarEvent): boolean {
    if (event.eventType === 'manual') {
      return true;
    }

    if (event.eventType === 'irrigation') {
      const convertedEvent = this.calendarEventToIrrigationEvent(event);
      convertedEvent.from = this.dateToAdjustedISO(convertedEvent.from);
      convertedEvent.to = this.dateToAdjustedISO(convertedEvent.to);

      let isSingleEvent: boolean = false;

    for (let i = 0; i < this.cropzone.irrigation_events.length; i++) {
      if (deepEqual(convertedEvent, this.cropzone.irrigation_events[i])) {
        isSingleEvent = true;
      }
    }

    return isSingleEvent;
    }
  }

  public editEvent(event): void {
    this.editModal.emit({
      event: event,
      isSingleEvent: this.isSingleEvent(event)
    });
  }

  public dateToAdjustedISO(date: Date): string {
    let isoString: string = date.toISOString();
    isoString = isoString.substring(0, isoString.length - 5);
    isoString += '+00:00';
    return isoString;
  }

  public toDayView(day): void {
    this.view = 'day';
    this.irrigationEventsStore.dispatch(setViewDate(day.date));
  }

  public calendarEventToIrrigationEvent(calendarEvent: IIrrigationCalendarEvent): any {
    const manualEvent = calendarEvent.hasOwnProperty('efficacy') ? false : true;

    if (manualEvent) {
      const rainEvent: IRainEvents = {
        title: calendarEvent.title,
        date: calendarEvent.start,
        amount: calendarEvent.amount
      };
      return rainEvent;
    }

    if (!manualEvent) {
      return {
        title: calendarEvent.title,
        from: calendarEvent.start,
        to: calendarEvent.end,
        duration: calendarEvent.duration,
        efficacy: calendarEvent.efficacy,
        type: calendarEvent.type,
        amount: calendarEvent.amount,
        frequency: calendarEvent.frequency,
        exceptions: []
      };
    }
  }

  public calculateAmount(day: any): number {
    let sumAmount: number = 0;
    for (let i = 0; i < day.events.length; i++) {
      if (day.events[i].eventType === 'irrigation') {
        if (day.events[i].start.getDate() === day.date.getDate() && day.events[i].end.getDate() === day.date.getDate()) {
          // no overnight events
          sumAmount += day.events[i].amount;
        } else {
          // overnight events
          // overnight events cant be longer than 1440 minutes (24 hours) -> first day and last day of event
          const totalTimeDifference = day.events[i].end.getTime() - day.events[i].start.getTime();
          if (day.events[i].start.getDate() === day.date.getDate()) {
            // first day of overnight event
            const endOfDay = moment(day.date).endOf('day').toDate();
            const timeUntilEndOfDay = endOfDay.getTime() - day.events[i].start.getTime();
            const percentage = timeUntilEndOfDay / totalTimeDifference * 100;
            const amountOnThisDay = day.events[i].amount / 100 * percentage;

            sumAmount += Math.round(amountOnThisDay * 10) / 10;
          } else if (day.events[i].end.getDate() === day.date.getDate()) {
            // last day of overnight event
            const startOfDay = moment(day.date).startOf('day').toDate();
            const timeUntilEndOfEvent = day.events[i].end.getTime() - startOfDay.getTime();
            const percentage = timeUntilEndOfEvent / totalTimeDifference * 100;
            const amountOnThisDay = day.events[i].amount / 100 * percentage;

            sumAmount += Math.round(amountOnThisDay * 10) / 10;
          }
        }
      }
      if (day.events[i].eventType === 'manual') {
        sumAmount += day.events[i].amount;
      }
    }

    if (this.unitSystem === 'metric') {
      return parseFloat(sumAmount.toFixed(4));
    } else if (this.unitSystem === 'imperial') {
      sumAmount /= 25.4;
      return parseFloat(sumAmount.toFixed(4));
    }
  }

  private getWholePercent(percentFor, percentOf): number {
    return percentFor / percentOf * 100;
  }

  private setEventsFromBackend(cropzone: ICropZone): void {
    this.events = [];
    if (cropzone.irrigation_events.length > 0) {

      cropzone.irrigation_events.forEach((event, index) => {
        const subtractedDates = (moment(event.to).toDate().getTime() / 1000) -
          (moment(event.from).toDate().getTime() / 1000);
        const freqInSeconds = event.frequency * 60 * 60;
        const count = Math.floor(subtractedDates / freqInSeconds) + 1;

        const rule: RRule = new RRule({
          freq: RRule.HOURLY,
          dtstart: moment(event.from).toDate(),
          interval: event.frequency,
          count: count
        });

        rule.all().forEach(date => {

          let adjustedFromHour = date.getHours();
          let adjustedStart = moment(date).toDate();
          let adjustedAmount = event.amount;

          if (event.exceptions.length > 0) {
            event.exceptions.forEach((exception) => {
              if (
                moment(exception.date).toDate().getDate() === moment(date).toDate().getDate()
                && moment(exception.date).toDate().getMonth() === moment(date).toDate().getMonth()
                ) {
                  adjustedFromHour = moment(exception.date).toDate().getHours();
                  adjustedStart = moment(exception.date).toDate();
                  adjustedAmount = exception.amount;
                 /*  calendarEvent.amount = exception.amount; */
                }
            });
          }
          const calendarEvent: IIrrigationCalendarEvent = {
            title: event.title,
            start: adjustedStart,
            duration: event.duration,
            fromHour: adjustedFromHour,
            end: moment(adjustedStart).add(event.duration, 'minutes').toDate(),
            efficacy: event.efficacy,
            type: event.type,
            amount: adjustedAmount,
            frequency: event.frequency,
            index: index,
            eventType: 'irrigation'
          };

          calendarEvent.eventType = 'irrigation';
          this.events.push(calendarEvent);
        });
      });
    }

    if (cropzone.rain_events && cropzone.rain_events.length > 0) {
      for (let i = 0; i < cropzone.rain_events.length; i++) {
        const rule: RRule = new RRule({
          freq: RRule.HOURLY,
          dtstart: moment(cropzone.rain_events[i].date).toDate(),
          interval: 1,
          count: 1
        });

        rule.all().forEach(date => {
          const calendarEvent: IManualCalendarEvent = {
            start: moment(date).toDate(),
            amount: cropzone.rain_events[i].amount,
            title: cropzone.rain_events[i].title,
            eventType: 'manual'
          };

          this.events.push(calendarEvent);
        });
      }
    }

    this.irrigationEventsStore.dispatch(setCalendarEvents(this.events));
  }

  private registerLocaleDatas(): void {
    registerLocaleData(localeAf);
    registerLocaleData(localeAr);
    registerLocaleData(localeBg);
    registerLocaleData(localeBr);
    registerLocaleData(localeCs);
    registerLocaleData(localeDa);
    registerLocaleData(localeDe);
    registerLocaleData(localeEn);
    registerLocaleData(localeEs);
    registerLocaleData(localeEt);
    registerLocaleData(localeFa);
    registerLocaleData(localeFr);
    registerLocaleData(localeGr);
    registerLocaleData(localeHe);
    registerLocaleData(localeHi);
    registerLocaleData(localeHr);
    registerLocaleData(localeHu);
    registerLocaleData(localeIt);
    registerLocaleData(localeJp);
    registerLocaleData(localeLt);
    registerLocaleData(localeLv);
    registerLocaleData(localeMk);
    registerLocaleData(localeNl);
    registerLocaleData(localePl);
    registerLocaleData(localePt);
    registerLocaleData(localeRo);
    registerLocaleData(localeRu);
    registerLocaleData(localeSi);
    registerLocaleData(localeSk);
    registerLocaleData(localeSq);
    registerLocaleData(localeSr);
    registerLocaleData(localeTr);
    registerLocaleData(localeUk);
    registerLocaleData(localeVn);
    registerLocaleData(localeZh);
    registerLocaleData(localeMr);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
