<app-form-layout [shadow]="false">
  <div class="padding15">
    <div class="parent">
      <form [formGroup]="form" *ngIf="(sensors$ | async) as sensorData">
        <ng-container *ngFor="let sensors of sensorData | keyvalue as keyvalues; let i = index">
          <table class="fullWidth">
            <tr>
              <td>
                <app-sensor-settings-column [soilProfileActive]="true" [sfAllowed]="false"
                  [form]="form.get(sensors.key)" [sensors]="sensors.value"
                  [depth_unit]="(userSettings$ | async)?.unit_system === 'metric' ? 'cm' : 'in'">
                </app-sensor-settings-column>
                <app-sensor-settings-column *ngIf="i === keyvalues.length - 1 && keyvalues.length % 2">
                </app-sensor-settings-column>
              </td>
              <td class="tdRight">
                <div *ngIf="formErrors(sensors)">
                  <app-custom-button [color]="'green'" (click)="copyToRowsBelow(sensors)"
                    [customCSS]="{'margin-top': '226px'}" [content]="'Copy to rows below'">
                  </app-custom-button>
                </div>
                <div *ngIf="!formErrors(sensors)">
                  <app-custom-button [color]="'green'" (click)="copyToRowsBelow(sensors)"
                    [customCSS]="{'margin-top': '154px'}" [content]="'Copy to rows below'">
                  </app-custom-button>
                </div>
              </td>
            </tr>
          </table>

        </ng-container>
      </form>
    </div>
    <div *ngIf="(sensors$ | async)" class="buttons">
      <div class="removeSourceButton" *ngIf="indexx > 0">
        <app-custom-button class="marginTop15Px" [content]="'Remove datasource'" (click)="removeDataSource()">
        </app-custom-button>
      </div>
      <div class="saveButton">
        <app-custom-button class="marginTop15Px" [disabled]="form.invalid" [color]="'red'" (click)="saveSettings()"
          [content]="'Save'"></app-custom-button>
      </div>
    </div>
  </div>
</app-form-layout>