import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromAccount from '../../../../../core/reducers/account';
import { cardAnimation } from '../../../..//../shared/animations/card-animations';

@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  animations: [cardAnimation()]
})
export class SendMessageComponent implements OnInit {
  public success              : boolean;
  public error                : boolean;
  public username$            : Observable<string>;

  constructor(private accountStore: Store<fromAccount.IAccount>) { }

  public ngOnInit(): void {
  }

}
