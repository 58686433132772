import { IActionWithPayload } from '../models/actionWithPayload';
import { SystemActionsTypes } from '../actions/system';
import { ISystemSensor } from '../../shared/interfaces';
import { ICalibrationSetting } from '../models/system';

export interface ISystemState {
    calibrationSettings: ICalibrationSetting[];
    sensors: {
        [index: number]: ISystemSensor;
    };
}

const initialState: ISystemState = {
    calibrationSettings: [],
    sensors: {}
};

export function reducer(state: ISystemState = initialState, action: IActionWithPayload): ISystemState {
    switch (action.type) {
        case SystemActionsTypes.SET_CALIBRATION_SETTINGS:
            return {
                ...state,
                calibrationSettings: action.payload
            };
        case SystemActionsTypes.SET_SYSTEM_SENSORS: {
            return {
                ...state,
                sensors: action.payload
            };
        }
        default: {
            return state;
        }
    }
}
