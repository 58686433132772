import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ICompany } from '../../../../../../core/models/account';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss']
})
export class CompanyInfoComponent implements OnInit, OnDestroy {
  @Input()
  public companyInfo             : ICompany;
  @Output()
  private companyInfoChange      : EventEmitter<ICompany> = new EventEmitter();
  public form                    : FormGroup;
  private alive$                 : Subject<boolean> = new Subject<boolean>();

  constructor(private formBuilder: FormBuilder) { }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      'department': [this.companyInfo.department || ''],
      'name': [this.companyInfo.name || ''],
      'profession': [this.companyInfo.profession || ''],
    });

    this.companyInfoChange.emit(this.form.value);

    this.form.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this.alive$),
    ).subscribe((form: ICompany) => {
      this.companyInfoChange.emit(form);
    });
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }

}
