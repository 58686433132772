import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { selectNavigationStation } from '../../../../core/reducers';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import { SoilGuardColumns } from '../../../../shared/constants/sample-data.constant';
import { SampleDeviceService } from '../../../../shared/services/sample-device/sample-device.service';
import { deepClone } from '../../../../shared/utils/deepClone';
import { setActiveMeter, setActiveSample, setDevice } from '../../actions/station-sample.action';
import { ISampleDataPeriod, IStationSampleState } from '../../models/station-sample.model';
import { selectActiveMeter, selectActiveTitle, selectSamples } from '../../selectors/station-sample.selector';

@Component({
  templateUrl: './soilguard-data.component.html',
  styleUrls: ['./soilguard-data.component.scss'],
  providers: [SampleDeviceService]
})
export class SoilguardDataComponent implements OnInit, OnDestroy {

  public status = {
    isLoading: true,
    isError: false
  };

  public settings: ISampleDataPeriod;
  public isTableActive: boolean;
  public columnDefs;
  public data;
  public activeSample;
  public dates;
  public activeMeter;
  public station;
  private alive$ = new Subject<void>();

  public interestValues = [
    { content: 'VWC', value: 'vwc' },
    { content: 'Temperature', value: 'temp' }
  ];


  constructor(
    private stationSampleStore: Store<IStationSampleState>,
    private stationSampleService: SampleDeviceService,
    private navigationStationStore: Store<INavigationStationState>
  ) {

  }

  public ngOnInit(): void {
    this.navigationStationStore.pipe(
      select(selectNavigationStation),
      takeUntil(this.alive$),
      filter(_ => Boolean(_)),
      tap(_ => this.station = _),
      distinctUntilChanged((a, b) => a.name.original === b.name.original)
    ).subscribe(station => {

      this.dates = {
        from: moment(station.dates.min_date).toDate(),
        to: moment(station.dates.max_date).toDate()
      };

      this.stationSampleStore.dispatch(setDevice({
        name: station.name.original,
        type: 'soilGuard',
        period: {
          from: moment(this.dates.to).subtract(30, 'day').toDate(),
          to: this.dates.to
        }
      }));

      this.stationSampleStore.dispatch(setActiveSample(null));
      this.stationSampleStore.dispatch(setActiveMeter('vwc'));
      this.status.isLoading = false;
    });

    this.stationSampleStore.pipe(
      select(selectActiveMeter),
      takeUntil(this.alive$),
      filter(_ => Boolean(_)),
      distinctUntilChanged((a, b) => a === b)
    ).subscribe(_ => this.activeMeter = _);

    combineLatest([
      this.stationSampleStore.pipe(select(selectSamples)),
      this.stationSampleStore.pipe(select(selectActiveTitle))
    ])
      .pipe(takeUntil(this.alive$))
      .subscribe(([data, title]) => {

        this.stationSampleService.setTitleActive(title);

        // todo save directly in this.data?
        let samples = [];

        if (data && data.length) {
          this.updateUnits(data[0]);

          samples = data.map(record => {
            const dt = new Date(Number(record.dt['$date']['$numberLong']));

            const marker = this.stationSampleService.getMarker(record, this.activeMeter);
            const altitude = record.position.altitude;

            let longitude = null;
            let latitude = null;
            if (record.position && record.position.geo) {
              [longitude, latitude] = record.position.geo.coordinates;
            }

            return { ...record, latitude, longitude, dt, altitude, marker };
          });
        }

        this.data = samples;
      });
  }

  public updateUnits(soilGuardSample): void {

    if (soilGuardSample && soilGuardSample.temp_unit) {
      const { vwc_unit, temp_unit, ec_unit } = soilGuardSample;

      this.stationSampleService.translate(deepClone(SoilGuardColumns))
        .subscribe(columns => {
          columns[1].headerName += ` [${vwc_unit}]`;
          columns[2].headerName += ` [${ec_unit}]`;
          columns[3].headerName += ` [${temp_unit}]`;

          this.columnDefs = columns;
        });
    }
  }

  public ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }
}
