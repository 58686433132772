<div class="close-account-form">
  <hr>
  <div class="close-account-form__submit">
    <app-custom-button class="close-account-form__button"
                       [content]="'Continue'"
                       [color]="'red'"
                       [disabled]="deletedAccount$ | async"
                       (click)="onContinue()">
    </app-custom-button>
  </div>

  <div class="alert alert-info close-account-form__alert"
       *ngIf="deletedAccount$ | async">
    {{ 'A link for account deletion will be sent to your FieldClimate email address ' | translate }}
    <b>{{ (info$ |async).email }}</b>.
    {{ 'If this is not the correct email, please update it in ' +
       'FieldClimate or write to ' | translate }}
    <a href="mailto:privacy.policy@metos.at?subject=Close account"><b>privacy.policy@metos.at</b></a>.
    {{ 'Please proceed with the deletion from your email.' }}
  </div>
</div>

