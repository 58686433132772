import { LatLngLiteral } from '@agm/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { IPosition } from '../../../../../core/models/stations';
import { DefaultStationPosition } from '../../../../station-config/constants/constants';
import { IProductConfigurationItem, IVirtualStationActivation } from '../../../models/product-activation.models';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-product-activation-vws-form',
  templateUrl: './product-activation-vws-form.component.html',
  styleUrls: ['./product-activation-vws-form.component.scss'],
})
export class ProductActivationVwsFormComponent implements OnInit {
  @Input()
  public configuration: IProductConfigurationItem;
  @Output()
  public positionChanged: EventEmitter<IPosition> = new EventEmitter<IPosition>();
  @Output()
  public submit: EventEmitter<IVirtualStationActivation> = new EventEmitter<IVirtualStationActivation>();

  public form: FormGroup;
  public currentDate: Date = new Date();
  public maxDate: Date = moment().add(1, 'year').toDate();
  public position: IPosition;
  private timeout: any;

  constructor(private fb: FormBuilder) { }

  public get customName(): AbstractControl {
    return this.form.get('name');
  }

  public get startingDate(): AbstractControl {
    return this.form.get('starting_date');
  }

  public get geoPosition(): AbstractControl {
    return this.form.get('position');
  }

  public get licenseDuration(): string {
    return moment.duration(this.configuration.license_duration).humanize();
  }

  public get longitude(): AbstractControl {
    return this.form.get('longitude');
  }

  public get latitude(): AbstractControl {
    return this.form.get('latitude');
  }

  public ngOnInit(): void {
    this.resetForm();

    combineLatest([
      this.longitude.valueChanges.pipe(distinctUntilChanged()),
      this.latitude.valueChanges.pipe(distinctUntilChanged())
    ]).subscribe(([lng, lat]) => {
      if (lng !== 0 && lat !== 0) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
          this.position.geo.coordinates = [parseFloat(lng), parseFloat(lat)];
          this.form.get('position').setValue(this.position);
          this.positionChanged.emit(this.position);
        }, 3000);
      }
    });
  }

  public selectLocationFromMap($event: { coords: LatLngLiteral }): void {
    this.position.geo.coordinates = [$event.coords.lng, $event.coords.lat];
    this.form.get('position').setValue(this.position);
    this.positionChanged.emit(this.position);
  }

  public onEnterKey(): void {
    if (this.longitude.value && this.latitude.value) {
      clearTimeout(this.timeout);
      this.position.geo.coordinates = [parseFloat(this.longitude.value), parseFloat(this.latitude.value)];
      this.form.get('position').setValue(this.position);
      this.positionChanged.emit(this.position);
    }
  }

  public submitEmit(): void {
    // Will only submit the form if it has no errors
    if (this.isValidForm()) {
      this.submit.emit({
        ...this.form.value,
        product_pos: this.configuration.pos
      });
      this.resetForm();
    }
  }

  private isValidForm(): boolean {
    // Should mark all form elements as touched
    Object.keys(this.form.controls).forEach(field => {
      this.form.get(field).markAsTouched({ onlySelf: true });
    });

    return this.form.valid;
  }

  private resetForm(): void {
    this.form = this.fb.group({
      'name': ['', []],
      'starting_date': [this.currentDate, [Validators.required]],
      'position': ['', [Validators.required]],
      'longitude': [null, []],
      'latitude': [null, []]
    });
    this.resetStationPosition();
  }

  private resetStationPosition(): void {
    this.position = {
      geo: DefaultStationPosition.geo,
      altitude: DefaultStationPosition.altitude,
      timezoneCode: DefaultStationPosition.timezoneCode
    };
  }
}
