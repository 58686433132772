<div class="disease-fireblight"
     *ngIf="fireBlight">
  <div class="disease-fireblight__header">
    <button class="close disease-fireblight__header__close"
            (click)="closeModal()"><span>×</span></button>
    <h3>{{ 'Disease model Configuration' | translate }}</h3>
  </div>
  <div class="disease-fireblight__content">
    <div *ngIf="!isExportActive"
         class="alert alert-danger text-center disease-fireblight__danger
         disease-fireblight__alert disease-fireblight__alert__header">
      {{ 'No data for selected station' | translate }}
    </div>
    <div *ngIf="fireBlight.isSuccess"
         class="alert alert-success text-center
              disease-fireblight__alert disease-fireblight__alert__header">
      {{ 'You have successfully set disease models settings' | translate}}
    </div>
    <fieldset>
      <legend>{{ 'Please select Fireblight Status' | translate }}</legend>

      <div *ngFor="let item of fireBlightSettings">
        <label class="disease-fireblight__check">
          <input [attr.type]="'radio'" name="radiogroup"
                 [disabled]="fireBlight.rights === 'r'"
                 (click)="model.setValue(item.value)"
                 [checked]="item.value === model.value"/>
          {{ item.content |translate }}
        </label>
      </div>
    </fieldset>
    <hr>
    <div class="row m-b-5">
      <div class="col-md-8">
        <div *ngIf="rights"
             class="alert alert-info text-center disease-fireblight__alert">
          {{ 'NOTE: You do not have permission to edit this settings' | translate }}
        </div>
      </div>
      <div class="col-md-4">
        <app-custom-button [content]="'Submit'"
                           class="disease-fireblight__right disease-fireblight__button
                            disease-fireblight__button__last"
                           [color]="'red'"
                           [disabled]="rights"
                           (click)="saveFireBlight()"></app-custom-button>
        <app-custom-button [content]="'Cancel'"
                           class="disease-fireblight__right disease-fireblight__button"
                           (click)="closeModal()"></app-custom-button>
      </div>
    </div>
  </div>
</div>
