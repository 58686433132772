import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith, takeUntil } from 'rxjs/operators';
import { ICropZone } from '../../../core/models/cropzones';
import { addVisibleLaiVal } from '../actions';
import { addVisibleNdviVal, getVisibleNdviVals } from '../actions/ndvi';
import { ILai, ISatState } from '../models';
import { getVisibleLaiDatesFromCropZoneId, getVisibleNdviDatesFromCropZoneId, visibleNdviValSelector } from '../selectors';
import { getVisibleLaiVals } from './../actions/index';
import { visibleLaiValSelector } from './../selectors/index';
import { CropZoneSatServiceService } from './../services/crop-zone-sat-service.service';
import { LazyListItemComponent } from './lazy-list-item.component';

@Component({
  selector: 'app-lazy-list',
  templateUrl: './lazy-list.component.html',
  styleUrls: ['./lazy-list.component.scss']
})
export class LazyListComponent implements OnInit, OnDestroy {
  @Input()
  public cropZone: ICropZone;

  @Input()
  public resolution: number;

  @Input()
  public loading: boolean;

  @Input()
  public noDataError: boolean;

  @Input()
  public values$?: Observable<any[]>;

  @Input()
  public type: string;

  @Output()
  public selectedItem = new EventEmitter();

  @ViewChildren(LazyListItemComponent)
  public items: QueryList<LazyListItemComponent>;

  @ViewChild('container', {static: true})
  public containerRef: ElementRef;

  @ViewChild('legendContainer', {static: true})
  public legendContainer: ElementRef;

  private resizeObservable$: Observable<Event>;
  private destroy$: Subject<boolean> = new Subject();
  private visibleDates: string[] = [];

  public array: any[] = [];
  private previousArray: any[] = [];
  private visibleLaiVals: any[];
  private visibleNdviVals: any[];
  private amountOfColumns: number;
  public sortedValues: any[];
  public sortedValues$: Observable<any[]>;
  public values;
  private previousAmountOfColumns: number;
  private previousList: any;

  constructor(
    private satStore: Store<ISatState>,
    private cropzoneSatService: CropZoneSatServiceService) {
  }

  public ngOnInit(): void {
    this.satStore.pipe(
      select(visibleLaiValSelector),
      takeUntil(this.destroy$)
    ).subscribe(visibleLaiVals => {
      if (Object.keys(visibleLaiVals).length > 0 && visibleLaiVals.hasOwnProperty(this.cropZone.id)) {
        if (this.type === 'LAI') {
          this.visibleLaiVals = visibleLaiVals;
        }
      }
    });

    this.satStore.pipe(
      select(visibleNdviValSelector),
      takeUntil(this.destroy$)
    ).subscribe(visibleNdviVals => {
      if (Object.keys(visibleNdviVals).length > 0 && visibleNdviVals.hasOwnProperty(this.cropZone.id)) {
        if (this.type === 'NDVI') {
          this.visibleNdviVals = visibleNdviVals;
        }
      }
    });


    this.resizeObservable$ = fromEvent(window, 'resize');
    this.sortedValues$ = combineLatest([
      this.values$.pipe(debounceTime(0), filter((values) => values.length > 0)),
      this.resizeObservable$.pipe(debounceTime(0), startWith(<string>null))]
    ).pipe(takeUntil(this.destroy$), map(([values, resize]) => {
      const sortedArray = [];
      const valueArray = JSON.parse(JSON.stringify(values));
      const gridComputedStyle = window.getComputedStyle(this.containerRef.nativeElement);
      const amountOfRows = gridComputedStyle.getPropertyValue('grid-template-rows').split(' ').map(parseFloat).length;
      const amountOfColumns = gridComputedStyle.getPropertyValue('grid-template-columns').split(' ').map(parseFloat).length;

      const legendItemObject: any = {
        date: 'legend',
        lai: undefined,
        cloud: undefined
      };

      for (let i = 0; i < valueArray.length; i += amountOfColumns - 1) {
        const rowDataWithoutLegend = valueArray.slice(i, i + amountOfColumns - 1).reverse();
        rowDataWithoutLegend.push(legendItemObject);
        sortedArray.push(rowDataWithoutLegend);
      }

      if (sortedArray.length > 0) {
        const difference = amountOfColumns - sortedArray[sortedArray.length - 1].length;
        for (let index = 0; index < difference; index++) {
          sortedArray[sortedArray.length - 1].unshift({});
        }
      }

      const sortedList = [].concat(...sortedArray);
      if (JSON.stringify(sortedList) !== JSON.stringify(this.previousList)) {
        this.previousList = [].concat(...sortedArray);
        this.previousAmountOfColumns = amountOfColumns;
        return [].concat(...sortedArray);
      } else {
        return this.previousList;
      }
    }));
  }

  public onVisible(event: any): void {
    this.visibleDates.push(event);

    this.satStore.pipe(
      select(getVisibleLaiDatesFromCropZoneId(this.cropZone.id)),
      filter(dates => !!dates),
      takeUntil(this.destroy$),
      debounceTime(250),
    ).subscribe((dates) => {
      if (this.visibleDates && this.visibleDates.length && this.type === 'LAI') {
        const visibleDates = this.visibleDates.filter((date) => date !== undefined || date !== null);
        this.satStore.dispatch(getVisibleLaiVals(this.cropZone, visibleDates, this.resolution));

        visibleDates.map(date =>
          this.satStore.dispatch(addVisibleLaiVal(this.cropZone.id, date, this.resolution))
        );
        this.visibleDates = [];
      }
    });

    this.satStore.pipe(
      select(getVisibleNdviDatesFromCropZoneId(this.cropZone.id)),
      filter(dates => !!dates),
      takeUntil(this.destroy$),
      debounceTime(250),
    ).subscribe((dates) => {
      if (this.visibleDates && this.visibleDates.length && this.type === 'NDVI') {
        const visibleDates = this.visibleDates.filter((date) => date !== undefined || date !== null);
        this.satStore.dispatch(getVisibleNdviVals(this.cropZone, visibleDates, this.resolution));

        visibleDates.map(date =>
          this.satStore.dispatch(addVisibleNdviVal(this.cropZone.id, date, this.resolution))
        );

        this.visibleDates = [];
      }
    });
  }

  public onItemClicked(item: ILai): void {
    this.selectedItem.emit(item);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
