import {IOptions} from '../../../shared/interfaces';
import {IStationTreeSettings} from '../../../shared/tree/models/tree.models';

export const PeriodValuesByScope = {
  'raw': [
    { content: '24 hours', value: '24h' },
    { content: '48 hours', value: '48h' },
    { content: '7 days', value: '7d' },
    { content: '10 days', value: '10d' },
    { content: '14 days', value: '14d' },
    { content: '30 days', value: '30d' }
  ],
  'hourly': [
    { content: '24 hours', value: '24h' },
    { content: '48 hours', value: '48h' },
    { content: '7 days', value: '7d' },
    { content: '10 days', value: '10d' },
    { content: '14 days', value: '14d' },
    { content: '30 days', value: '30d' }
  ],
  'daily': [
    { content: '7 days', value: '6d' },
    { content: '10 days', value: '9d' },
    { content: '14 days', value: '13d' },
    { content: '30 days', value: '29d' },
    { content: '90 days', value: '89d' }
  ],
  'monthly': [
    { content: '6 months', value: '6m' },
    { content: '12 months', value: '12m' },
    { content: '24 months', value: '24m' },
    { content: '36 months', value: '36m' }
  ]
};

export const DefaultPeriodScope = 'hourly';

export const DefaultPeriodScopeFrostProtection = 'raw';

export const DefaultPeriodValue = { content: '48 hours', value: '48h' };

export const AccumulationTypes: Array<IOptions> = [
  { content: 'Degree days', value: 'temp' },
  { content: 'Chilling units', value: 'chilling' },
  { content: 'Rain Sum', value: 'rain' },
  { content: 'Asparagus growth model', value: 'asparagus' }
];

const DefaultProfileName = 'All sensors';

export const InitialStationTreeSetting: IStationTreeSettings = {
  profileSettings: {},
  selectedProfile: DefaultProfileName
};

export const noDataWarningMessages = {
  general: 'No data for selected station',
  monthly: 'No data for the selected station within the chosen time frame'
};
