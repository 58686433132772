import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-weather-forecast-help-modal',
  templateUrl: './weather-forecast-help-modal.component.html',
  styleUrls: ['./weather-forecast-help-modal.component.css']
})
export class WeatherForecastHelpModalComponent implements OnInit {

  @Input()
  public help: string;

  constructor() { }

  public ngOnInit(): void {
  }

}
