import { Injectable } from '@angular/core';
import { ICropZone } from './../../../core/models/cropzones';

@Injectable({
  providedIn: 'root'
})
export class CropzoneConfigService {

  constructor() { }

  public removeIrrimetSettings(cropzone: ICropZone): ICropZone {
    cropzone.data_sources = cropzone.data_sources.filter((data_source) => !(data_source.module === 'IRRIMET'));
    cropzone.rain_efficacies = [];
    cropzone.irrigation_events = [];
    cropzone.rain_events = [];

    return cropzone;
  }
}
