<app-form-layout class="server-alerts-list" *ngIf="alerts && alerts.length">

  <app-card-header header="Current alerts"
                   [helpButton]="true"
                   description="List of your current alerts">
  </app-card-header>

  <div class="server-alerts-list__content">
    <div class="alert alert-info server-alerts-list__alert">
      {{ 'Warning thresholds can have a full number or decimal values (.) and are separated with a comma (,). ' +
    'Warning thresholds can also contain negative numbers (numbers below 0).' | translate }}
      <br />
      {{ 'Input fields with errors will be marked with red and will not be saved before they are fixed.' | translate }}
      <br /><br />
      {{ 'Reminder' | translate }}:
      <br />
      <ul>
        <li><b>,</b> - <i>{{ 'separator' | translate }}</i></li>
        <li><b>.</b> - <i>{{ 'decimal value (10.5, -12.81)' | translate }}</i></li>
      </ul>
    </div>
    <div class="table-responsive server-alerts-list__table-content">
      <table class="server-alerts-list__table">
        <thead class="server-alerts-list__table__head">
          <tr>
            <th class="col-md-1 server-alerts-list__table__th">{{ 'Channel' | translate }}</th>
            <th class="col-md-1 server-alerts-list__table__th">{{ 'Code' | translate }}</th>
            <th class="col-md-1 server-alerts-list__table__th">{{ 'Aggr' | translate }}</th>
            <th class="col-md-2 server-alerts-list__table__th">{{ 'Sensor' | translate }}</th>
            <th class="col-md-2 server-alerts-list__table__th">{{ 'Type' | translate }}</th>
            <th class="col-md-1 server-alerts-list__table__th">{{ 'Alert' | translate }}</th>
            <th class="col-md-1 server-alerts-list__table__th">{{ 'Thresholds' | translate }}</th>
            <th class="col-md-1 server-alerts-list__table__th">{{ 'Unit' | translate }}</th>
            <th class="col-md-1 server-alerts-list__table__th">{{ 'Targets' | translate }}</th>
            <th class="col-md-1 server-alerts-list__table__th">{{ 'Resend period' | translate }}</th>
            <th class="col-md-1 server-alerts-list__table__th">{{ 'Active' | translate }}</th>
            <th class="col-md-1 server-alerts-list__table__th">{{ 'Action' | translate }}</th>
          </tr>
        </thead>
        <tbody class="server-alerts-list__table__body">
          <tr app-server-alerts-row [ngClass]="{'frost-mode' : alert.source==='frost'}"
              *ngFor="let alert of alerts"
              [alert]="alert"
              [sensorData]="getSensorData(alert)"
              [station]="station.name?.original">
          </tr>
        </tbody>
      </table>
    </div>
    <div class="server-alerts-list-save">
      <hr class="server-alerts-list-save__hr">
      <div class="server-alerts-list-save__content">
        <div>
          <!--  Save all server alerts button  -->
        </div>
      </div>
    </div>
  </div>
</app-form-layout>

<!--<app-info-modal [modalId]="modalId"-->
<!--                [header]="'Warnings'">-->
<!--  <app-warnings-list-modal></app-warnings-list-modal>-->
<!--</app-info-modal>-->
