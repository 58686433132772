<form [formGroup]="form"
      class="scheduler">
  <h4 class="scheduler__header">{{ 'Scheduler' | translate }}</h4>
  <!-- <div *ngIf="isAdvanced && fixedTransferInterval > 0 && !isCropView && !isIScout"
       class="alert alert-info scheduler__alert">
    {{ 'Because fixed transfer interval is set it takes precedence over the Scheduler, which is ignored by the ' +
    'station. Data will be sent every' | translate }} {{ fixedTransferInterval }} {{ 'minutes!' | translate }}
  </div> -->
  <div *ngIf="isIScout || isCropView"
       class="alert alert-info scheduler__alert">
    {{ 'To maintain the amount of data under 1GB and inside your monthly plan the maximum number of captured images ' +
    'is limited to 3 per day.' | translate }}
    <br/><br/>
    <b>{{ 'Attention!' | translate }}</b>
    {{ 'During low temperatures please make sure your station transmits only once per day due to battery saving.'
    | translate }}
  </div>

  <div class="row" [formArrayName]="'scheduler'" style="margin-left: 0px; padding: 5px;" [ngClass]="overThreeHours || isHourGapError ? 'error' : ''">
    <div *ngFor="let column of schedulerArray.controls; let i = index" [formArrayName]="i" class="clearfix">
      <div *ngFor="let option of column.controls; let j = index" class="col-md-3">
        <label class="scheduler__label">
          <input [attr.type]="'checkbox'"
                 [formControlName]="j"
                 [checked]="option.value === '1'"
                 (click)="option.setValue(option.value === '1' ? 0 : 1);">
          {{ getTime(i,j) }}
        </label>
      </div>
    </div>
  </div>

  <div class="alert alert-danger" *ngIf="overThreeHours" style="margin-top: 10px;">
    {{ 'U can only select up to 3 photo uploads per day' | translate }}
  </div>

  <div class="alert alert-danger" *ngIf="isHourGapError" style="margin-top: 10px;">
    {{ 'Photo uploads must have a minimum of 4 hours inbetween' | translate }}
  </div>

  <div class="scheduler__buttons">
    <app-custom-button *ngIf="!isIScout && !isCropView"
                       class="scheduler__first-button"
                       [color]="isSelectAllActive() ? 'green' : 'white'"
                       (click)="selectAll()"
                       [content]="'Select all'">
      <i class="fa fa-fw" [class.fa-check]="isSelectAllActive()"></i>
    </app-custom-button>
  </div>
</form>
