import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, takeWhile, map } from 'rxjs/operators';
import { IStation } from '../../../../../../core/models/stations';
import { selectStations } from '../../../../../../core/reducers/index';
import * as fromStations from '../../../../../../core/reducers/stations';
import { IOptions } from '../../../../../../shared/interfaces';
import { WeatherForecast } from '../../../../constants/constants';
import { IForecastTableRequest } from '../../../../models/dashboard.models';
import { deepClone } from '../../../../../../shared/utils/deepClone';

@Component({
  selector: 'app-forecast-table-settings',
  templateUrl: './forecast-table-settings.component.html',
  styleUrls: ['./forecast-table-settings.component.scss']
})
export class ForecastTableSettingsComponent implements OnInit, OnDestroy {
  @Input()
  private forecastRequest           : IForecastTableRequest;
  @Output()
  private updateRequest             : EventEmitter<IForecastTableRequest> = new EventEmitter();
  @Output()
  private updateWidgetData          : EventEmitter<void> = new EventEmitter();

  public forecastProfiles           : Array<IOptions> = WeatherForecast;
  public stations                   : Array<IStation>;
  public form                       : FormGroup;
  public allStations$               : Observable<Array<IStation>>;
  private alive                     : boolean = true;

  constructor(private stationsStore: Store<fromStations.IStations>,
              private formBuilder: FormBuilder) {
  }

  public get profile(): AbstractControl {
    return this.form.get('profile');
  }

  public get station(): AbstractControl {
    return this.form.get('station');
  }

  public ngOnInit(): void {
    this.allStations$ = this.stationsStore.pipe(
      select(selectStations),
      filter((stations: IStation[]) => !!stations && stations.length > 0),
      map((stations: IStation[]) => this.sortArray(stations))
    );

    this.form = this.formBuilder.group({
      'profile': new FormControl(this.forecastRequest.endpoint.profile),
      'station': new FormControl(this.forecastRequest.endpoint.station),
    });

    this.form.valueChanges.pipe(
      distinctUntilChanged(),
      takeWhile(() => this.alive)
    ).subscribe(() => {
      this.changeRequest();
    });

    this.allStations$.pipe(
      takeWhile(() => this.alive),
      filter((stations: Array<IStation>, index: number) => !!(stations && stations.length)),
      map((stations: IStation[]) => this.sortArray(stations)),
    ).subscribe((stations: Array<IStation>) => {
      this.stations = stations;
    });
  }

  private sortArray(stationArray: IStation[]): IStation[] {
    const stations = deepClone(stationArray);
    const customNamedStations = stations.filter((station) => station.name.custom !== '');
    customNamedStations.sort((a, b) => {
      const nameA = a.name.custom.toLowerCase();
      const nameB = b.name.custom.toLowerCase();

      const isNumberA = !isNaN(Number(nameA));
      const isNumberB = !isNaN(Number(nameB));

      if (isNumberA && isNumberB) {
        return Number(nameA) - Number(nameB);
      } else if (isNumberA) {
        return -1;
      } else if (isNumberB) {
        return 1;
      } else {
        return nameA.localeCompare(nameB);
      }
    });

    const otherStations = stations.filter((station) => station.name.custom === '');
    otherStations.sort((a: IStation, b: IStation) => a.name.original.toLowerCase() < b.name.original.toLowerCase() ? -1 : 1);

    return customNamedStations.concat(otherStations);
  }

  public changeRequest(): void {
    const newRequest = {
      endpoint: {
        profile: this.profile.value,
        station: this.station.value,
        stationName: this.getStationName(this.station.value)
      }
    };
    this.updateRequest.emit(newRequest);
  }

  public submit(): void {
    this.updateWidgetData.emit();
  }

  private getStationName(value: string): string {
    const station: IStation = this.stations.find((s: IStation) => s.name.original === value);
    return station.name.custom;
  }

  public ngOnDestroy(): void {
    this.alive = false;
  }

}

