import {
  InitialWeatherForecastStationSetting,
  IWeatherForecastSettingsPayload,
  IWeatherForecastSettingsState,
  IWeatherForecastStationModeSettings, IWeatherForecastStationSettings
} from '../../models/models';

export class WeatherForecastSettingsHelper {
  private state: IWeatherForecastSettingsState;

  constructor(state: IWeatherForecastSettingsState) {
    this.state = state;
  }

  public stateHasDisabledGroupId(settings: IWeatherForecastSettingsPayload): boolean {
    const stationModeSettings: IWeatherForecastStationModeSettings = this.getStationModeSettings(settings.stationId, settings.mode);
    return stationModeSettings && stationModeSettings.disabledGroupIds.hasOwnProperty(settings.groupdId);
  }

  public enableGroupId(settings: IWeatherForecastSettingsPayload): void {
    delete this.getStationModeSettings(settings.stationId, settings.mode).disabledGroupIds[settings.groupdId];
  }

  public disableGroupId(settings: IWeatherForecastSettingsPayload): void {
    const stationModeSettings = this.prepareStationModeSettings(settings.stationId, settings.mode);
    stationModeSettings.disabledGroupIds[settings.groupdId] = true;
  }

  private getStationModeSettings(stationId: string, mode: string): IWeatherForecastStationModeSettings {
    const stationSettings: IWeatherForecastStationSettings = this.getStationSettings(stationId);
    return stationSettings && (stationSettings.modes[mode] || null);
  }

  private getStationSettings(stationId: string): IWeatherForecastStationSettings {
    return this.state.settings[stationId] || null;
  }

  private prepareStationModeSettings(stationId: string, mode: string): IWeatherForecastStationModeSettings {
    this.initStationSettings(stationId);
    const stationModeSettings = this.getStationModeSettings(stationId, mode);
    if (!stationModeSettings) {
      this.state.settings[stationId].modes[mode] = {disabledGroupIds: {}};
    }
    return this.getStationModeSettings(stationId, mode);
  }

  private initStationSettings(stationId: string): void {
    this.state.settings[stationId] = this.state.settings[stationId] || InitialWeatherForecastStationSetting;
  }
}
