import { CropzonePageHeaderComponent } from './cropzone-page-header/cropzone-page-header.component';
import { IrrimetConfigPageHeaderComponent } from './irrimet-config-page-header/irrimet-config-page-header.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AlertModule,
  CarouselModule,
  PaginationComponent,
  PaginationModule,
  TimepickerModule,
  TooltipModule
} from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormLayoutComponent } from './form-layout/form-layout.component';
import { FormInputComponent } from './form-input/form-input.component';
import { FormSelectComponent } from './form-select/form-select.component';
import { CustomButtonComponent } from './custom-button/custom-button.component';
import { CustomButtonResetZoomComponent } from './custom-button-reset-zoom/custom-button-reset-zoom.component';
import { LogoComponent } from './logo/logo.component';
import { TooltipDirective } from './custom-tooltip/tooltip.directive';
import { CustomDropdownComponent } from './custom-dropdown/custom-dropdown.component';
import { OutClickDirective } from './out-click/out-click.directive';
import { TranslateModule } from '@ngx-translate/core';
import { ChartToolbarLayoutComponent } from './chart-toolbar-layout/chart-toolbar-layout.component';
import { AdDirective } from './ad/ad.directive';
import { MomentModule } from 'ngx-moment';
import { CustomDatepickerComponent } from './custom-datepicker/custom-datepicker.component';
import { DateRangeArrowComponent } from './date-range-arrow/date-range-arrow.component';
import { StationPageHeaderComponent } from './station-page-header/station-page-header.component';
import { SelectedStationComponent } from './selected-station/selected-station.component';
import { HamburgerComponent } from './hamburger/hamburger.component';
import { CustomTextareaComponent } from './custom-textarea/custom-textarea.component';
import { RouterModule } from '@angular/router';
import { TreeComponent } from './tree/tree.component';
import { TreeSensorsComponent } from './tree/containers/tree-sensors/tree-sensors.component';
import { SensorLeavesComponent } from './tree/containers/tree-sensors/sensor-leaves/sensor-leaves.component';
import { TreeLinksComponent } from './tree/containers/tree-links/tree-links.component';
import { CardHeaderComponent } from './card-header/card-header.component';
import { InfoModalComponent } from './modal/modals/info-modal/info-modal.component';
import { ModalComponent } from './modal/modals/modal/modal.component';
import { YesNoToggleComponent } from './yes-no-toggle/yes-no-toggle.component';
import { StationLoadInfoComponent } from './components/station-load-info/station-load-info.component';
import { TreeNestedLinksComponent } from './tree/containers/tree-nested-links/tree-nested-links.component';
import { CustomCheckboxComponent } from './components/custom-checkbox/custom-checkbox.component';
import { MouseWheelDirective } from './mouse-wheel/mouse-wheel.directive';
import { DetailedImageComponent } from './detailed-image/detailed-image.component';
import { DetailedImageToolbarComponent } from './detailed-image-toolbar/detailed-image-toolbar.component';
import { DetailedImageDeleteModalComponent } from './detailed-image-delete-modal/detailed-image-delete-modal.component';
import { DetailedImageClearMeasurementsModalComponent } from './detailed-image-clear-measurements-modal/detailed-image-clear-measurements-modal.component';
import { CustomCarouselComponent } from './custom-carousel/custom-carousel.component';
import { MultilevelDropdownComponent } from './multilevel-dropdown/multilevel-dropdown.component';
import { MultilevelDropdownInputComponent } from './multilevel-dropdown-input/multilevel-dropdown-input.component';
import { MultilevelGroupedDropdownComponent } from './multilevel-grouped-dropdown/multilevel-grouped-dropdown.component';
import { CustomLazyDropdownComponent } from './custom-lazy-dropdown/custom-lazy-dropdown.component';
import { LoaderComponent } from './loader/loader.component';
import { TreeCardsComponent } from './tree/containers/tree-cards/tree-cards.component';
import { SelectedCropzoneComponent } from './selected-cropzone/selected-cropzone.component';
import { CustomSimpleLazyDropdownComponent } from './custom-simple-lazy-dropdown/custom-simple-lazy-dropdown.component';
import { CustomLazyGroupedDropdownComponent } from './custom-lazy-grouped-dropdown/custom-lazy-grouped-dropdown.component';
import { CustomImageGalleryComponent } from './custom-image-gallery/custom-image-gallery.component';
import { CustomImageGalleryToolbarComponent } from './custom-image-gallery-toolbar/custom-image-gallery-toolbar.component';
import { SelectedImageToolbarComponent } from './selected-image-toolbar/selected-image-toolbar.component';
import { CameraTopologyComponent } from './camera-topology/camera-topology.component';
import { SafeUrlPipe } from './safe-url-pipe/safe-url.pipe';
import { SampleDataHeaderComponent } from './sample-data/sample-data-header/sample-data-header.component';
import { AgGridModule } from 'ag-grid-angular';
import { StationDataExportService } from './services/export/station-data-export.service';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { CustomExpiryWarningComponent } from './custom-expiry-warning/custom-expiry-warning.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
    TranslateModule.forChild(),
    TimepickerModule.forRoot(),
    MomentModule,
    RouterModule,
    CarouselModule.forRoot(),
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    ChartModule,
    AgGridModule.withComponents([]),
    NgSelectModule
  ],
  declarations: [FormLayoutComponent,
    FormInputComponent,
    FormSelectComponent,
    CustomButtonComponent,
    CustomButtonResetZoomComponent,
    LogoComponent,
    TooltipDirective,
    CustomDropdownComponent,
    CustomSimpleLazyDropdownComponent,
    OutClickDirective,
    ChartToolbarLayoutComponent,
    AdDirective,
    CustomDatepickerComponent,
    DateRangeArrowComponent,
    StationPageHeaderComponent,
    CropzonePageHeaderComponent,
    IrrimetConfigPageHeaderComponent,
    SelectedStationComponent,
    SelectedCropzoneComponent,
    HamburgerComponent,
    CustomTextareaComponent,
    TreeComponent,
    TreeSensorsComponent,
    SensorLeavesComponent,
    TreeLinksComponent,
    CardHeaderComponent,
    ModalComponent,
    InfoModalComponent,
    YesNoToggleComponent,
    StationLoadInfoComponent,
    TreeNestedLinksComponent,
    CustomCheckboxComponent,
    MouseWheelDirective,
    DetailedImageComponent,
    DetailedImageToolbarComponent,
    DetailedImageDeleteModalComponent,
    DetailedImageClearMeasurementsModalComponent,
    CustomCarouselComponent,
    MultilevelDropdownComponent,
    MultilevelDropdownInputComponent,
    MultilevelGroupedDropdownComponent,
    CustomLazyDropdownComponent,
    CustomLazyGroupedDropdownComponent,
    LoaderComponent,
    TreeCardsComponent,
    CustomImageGalleryComponent,
    CustomImageGalleryToolbarComponent,
    SelectedImageToolbarComponent,
    CameraTopologyComponent,
    SafeUrlPipe,
    SampleDataHeaderComponent,
    CustomExpiryWarningComponent
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting] },
    StationDataExportService
  ],
  exports: [
    AlertModule,
    FormsModule,
    BsDatepickerModule,
    ReactiveFormsModule,
    FormLayoutComponent,
    FormInputComponent,
    FormSelectComponent,
    CustomButtonComponent,
    LogoComponent,
    TooltipDirective,
    CustomDropdownComponent,
    CustomSimpleLazyDropdownComponent,
    OutClickDirective,
    ChartToolbarLayoutComponent,
    AdDirective,
    MomentModule,
    CustomDatepickerComponent,
    DateRangeArrowComponent,
    StationPageHeaderComponent,
    CropzonePageHeaderComponent,
    IrrimetConfigPageHeaderComponent,
    SelectedStationComponent,
    SelectedCropzoneComponent,
    HamburgerComponent,
    CustomTextareaComponent,
    HamburgerComponent,
    TreeComponent,
    TreeSensorsComponent,
    SensorLeavesComponent,
    TreeLinksComponent,
    CardHeaderComponent,
    ModalComponent,
    InfoModalComponent,
    YesNoToggleComponent,
    StationLoadInfoComponent,
    TreeNestedLinksComponent,
    CustomCheckboxComponent,
    MouseWheelDirective,
    DetailedImageComponent,
    DetailedImageToolbarComponent,
    CustomCarouselComponent,
    MultilevelDropdownComponent,
    PaginationComponent,
    CustomLazyDropdownComponent,
    TreeCardsComponent,
    CustomImageGalleryComponent,
    CustomImageGalleryToolbarComponent,
    SelectedImageToolbarComponent,
    MultilevelGroupedDropdownComponent,
    CustomButtonResetZoomComponent,
    CameraTopologyComponent,
    SafeUrlPipe,
    SampleDataHeaderComponent,
    CustomLazyGroupedDropdownComponent,
    CustomExpiryWarningComponent
  ],
  entryComponents: [TreeSensorsComponent, TreeLinksComponent, TreeNestedLinksComponent, TreeCardsComponent]
})
export class SharedModule {
}
