import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  getWeatherForecastImage,
  setWeatherForecastImageError,
  setWeatherForecastImageLoading
} from '../../actions/weather-forecast-image';
import { IWeatherForecastImageState } from '../../models/models';
import {
  selectWeatherForecastImage,
  selectWeatherForecastImageError,
  selectWeatherForecastImageLoading
} from '../../reducers';


@Component({
  selector: 'app-weather-forecast-history-climate-risk-assessment',
  templateUrl: './weather-forecast-history-climate-risk-assessment.component.html',
  styleUrls: ['./weather-forecast-history-climate-risk-assessment.component.scss']
})
export class WeatherForecastHistoryClimateRiskAssessmentComponent implements OnChanges, OnDestroy {
  @Input()
  public params                     : any;
  @Input()
  public stationID                  : string;
  public imageUrl$                  : Observable<any>;
  public isLoading$                 : Observable<boolean>;
  public isError$                   : Observable<boolean>;
  public mode                       : string = '';

  @Output() public messageEvent = new EventEmitter<string>();

  constructor(private weatherForecastImageStore: Store<IWeatherForecastImageState>) { }

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly availablePages: Array<string> = [
    'history_frostrisk',
    'history_heatrisk',
    'history_preciprisk',
    'history_watercapacity',
  ];

  public readonly tabheadings: Array<string> = [
    'Cold events',
    'Warm events',
    'Precipitation',
    'Water capacity',
  ];

 public ngOnChanges(changes: SimpleChanges): void {
    this.initImageListener();
    this.iniStatusListeners();

    if (this.mode && !changes.stationID.firstChange) {
      this.dispatchGetImage(this.mode);
    }
  }

  private initImageListener(): void {
    combineLatest([
      this.imageUrl$ = this.weatherForecastImageStore.pipe(
        select(selectWeatherForecastImage),
        takeUntil(this.destroy$)
      ),
    ]).subscribe(() => {
       if (!this.mode) {
        this.mode = this.availablePages[0];
        this.dispatchGetImage(this.mode);
       }
    });
  }

  private dispatchGetImage(mode: string): void {
    this.weatherForecastImageStore.dispatch(setWeatherForecastImageLoading(true));
    this.weatherForecastImageStore.dispatch(setWeatherForecastImageError(false));
    this.weatherForecastImageStore.dispatch(getWeatherForecastImage({ mode: mode, stationId: this.params.id }));
  }

  public selectedTab(event: any): void {
    if (event.target.innerText.split(' ').join('').toLocaleUpperCase() === 'COLDEVENTS') {
      this.mode = this.availablePages[0];
      this.dispatchGetImage(this.mode);
    }
    if (event.target.innerText.split(' ').join('').toLocaleUpperCase() === 'WARMEVENTS') {
      this.mode = this.availablePages[1];
      this.dispatchGetImage(this.mode);
    }
    if (event.target.innerText.toLocaleUpperCase() === 'PRECIPITATION') {
      this.mode = this.availablePages[2];
      this.dispatchGetImage(this.mode);
    }
    if (event.target.innerText.split(' ').join('').toLocaleUpperCase() === 'WATERCAPACITY') {
      this.mode = this.availablePages[3];
      this.dispatchGetImage(this.mode);
    }
  }

  private iniStatusListeners(): void {
    this.isLoading$ = this.weatherForecastImageStore.pipe(select(selectWeatherForecastImageLoading));
    this.isError$ = this.weatherForecastImageStore.pipe(select(selectWeatherForecastImageError));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
