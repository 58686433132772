<div class="crop-zone-soil-moisture-sums-sm-charts">
  <ng-container *ngFor="let d of data; let i = index">
    <app-card-header
      [header]=""
      [description]="">
    </app-card-header>

    <div class="datasource">
      <div class="station-source">
        <span> {{ 'Station' | translate }}:</span>
        <span> {{ d.station }}</span>
      </div>
      <div class="node-source">
        <span> {{ 'Node' | translate }}:</span>
        <span> {{ d.mac || d.serial || d.station || 'X' }}</span>
      </div>
    </div>
    <div [chart]="charts[i][0]" *ngIf="charts[i][0]"></div>
    <div [chart]="charts[i][1]"></div>
  </ng-container>
</div>
