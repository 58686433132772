import { initialState } from '../states/states';
import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { ActionTypes } from '../actions/actions';
import { IHydroponicsState } from '../models/models';

export function hydroponicsReducer(state: IHydroponicsState = initialState, action: IAction): IHydroponicsState {
  switch (action.type) {
    case ActionTypes.SET_SETTINGS:
      return {
        ...state,
        ...action.payload
      };
    case ActionTypes.SET_PERIOD:
      return {
        ...state,
        period: action.payload
      };
    case ActionTypes.SET_TREE:
      return {
        ...state,
        tree: action.payload
      };
    case ActionTypes.SET_HYDROPONICS_ERROR:
      return {
        ...state,
        dataLoading: {
          ...state.dataLoading,
          isError: action.payload
        }
      };
    case ActionTypes.SET_HYDROPONICS_LOADING:
      return {
        ...state,
        dataLoading: {
          ...state.dataLoading,
          isLoading: action.payload
        }
      };
    case ActionTypes.SET_HYDROPONICS_CHART_ACTIVE:
      return {
        ...state,
        activity: {
          ...state.activity,
          isChartActive: action.payload
        }
      };
    case ActionTypes.SET_HYDROPONICS_TABLE_ACTIVE:
      return {
        ...state,
        activity: {
          ...state.activity,
          isTableActive: action.payload
        }
      };
    case ActionTypes.SET_HYDROPONICS_CHARTS:
      return {
        ...state,
        charts: action.payload
      };
    case ActionTypes.SET_DRAINAGE_VISIBILITY:
      return {
        ...state,
        visibility: {
          ...state.visibility,
          drainage: action.payload
        }
      };
    case ActionTypes.SET_WIN_VISIBILITY:
      return {
        ...state,
        visibility: {
          ...state.visibility,
          win: action.payload
        }
      };
    case ActionTypes.SET_WOUT_VISIBILITY:
      return {
        ...state,
        visibility: {
          ...state.visibility,
          wout: action.payload
        }
      };
    case ActionTypes.SET_WEIGHT_VISIBILITY:
      return {
        ...state,
        visibility: {
          ...state.visibility,
          weight: action.payload
        }
      };
    default: {
      return state;
    }
  }
}
