<ng-container [formGroup]="form"
              *ngIf="form && phone">
  <td class="warning-phone-td warning-phone-td__first">{{ phone.num }}</td>
  <td class="warning-phone-td">{{ phone.name }}</td>
  <td class="text-center warning-phone-td">
    <div class="warning-phone-td__active">
      <app-yes-no-toggle [formControl]="active"></app-yes-no-toggle>
    </div>
  </td>
  <td class="text-right warning-phone-td warning-phone-td__last">
    <app-custom-button [content]="'Remove'"
                       (click)="openModal()"
                       [color]="'red'">
      <i class="fa fa-trash"></i>
    </app-custom-button>
  </td>
</ng-container>

<app-info-modal [hideFooter]="true"
                [styles]="{'max-width': '300px'}"
                [headSize]="17"
                [modalId]="modalId"
                [header]="'Remove?'">
  <app-warnings-phone-remove-modal [number]="phone?.num"
                                   (close)="closeModal()"
                                   (remove)="remove()"></app-warnings-phone-remove-modal>
</app-info-modal>
