import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { ISettings } from '../../../../core/models/account';
import { selectSettings } from '../../../../core/reducers';
import * as fromAccount from '../../../../core/reducers/account';
import { LegendValues, SampleMarkers } from '../../../../shared/constants';
import { SampleDeviceService } from '../../../../shared/services/sample-device/sample-device.service';
import { setActiveSample } from '../../actions/station-sample.action';
import { IStationSampleState } from '../../models/station-sample.model';
import { selectActiveSample } from '../../selectors/station-sample.selector';

@Component({
  selector: 'app-sample-map',
  templateUrl: './sample-map.component.html',
  styleUrls: ['./sample-map.component.scss']
})
export class SampleMapComponent implements OnInit, OnDestroy {

  @Input()
  public legend = false;

  @Input()
  public activeMeter;

  @Input()
  public set samples(values) {
    this.dataReady = false;

    if (!values.length) {
      this.dataReady = true;
      this.zoom = null;
    } else {

      const device = values[0].deviceID;

      if (this.device !== device) {
        // todo to be removed
        setTimeout(() => this.zoom = null, 333);
        this.device = device;
      }
    }

    this.sampleData = values;
    this.hasPosition = values.some(_ => _.latitude && _.longitude);
  }

  public device;
  public dataReady = false;
  public sampleMarkers = SampleMarkers;
  public legendValues = LegendValues;
  public activeSample;
  public lat: number = 45;
  public lng: number = 15;
  private alive$ = new Subject<void>();
  public hasPosition = false;
  public sampleData;
  public unitSystem;
  public zoom: number;

  public styles = [{
    featureType: 'all',
    elementType: 'labels',
    stylers: [
      { visibility: 'off' }
    ]
  }];


  constructor(
    private stationSampleStore: Store<IStationSampleState>,
    private sampleDeviceService: SampleDeviceService,
    private accountStore: Store<fromAccount.IAccount>
  ) {

  }

  public ngOnInit(): void {

    this.accountStore.pipe(
      select(selectSettings),
      takeUntil(this.alive$),
      filter(_ => Boolean(_))
    ).subscribe((settings: ISettings) => {
      this.unitSystem = settings.unit_system;
    });

    this.stationSampleStore.pipe(
      select(selectActiveSample),
      takeUntil(this.alive$),
      filter(_ => _ !== this.activeSample),
      distinctUntilChanged((a, b) => a === b)
    ).subscribe(activeSample => {
      this.activeSample = activeSample;
    });
  }

  public getMarker(index, sample): string {
    if (index === this.activeSample && sample.hasOwnProperty(this.activeMeter)) {
      return this.sampleDeviceService.getActiveVector(sample[this.activeMeter]);
    } else {
      return sample.marker;
    }
  }

  public navigate(id: number): void {

    window.scrollTo({
      top: 550,
      left: 0,
      behavior: 'smooth'
    });

    this.activeSample = id;
    this.stationSampleStore.dispatch(setActiveSample(id));
  }

  public updateZoom(data): void {
    this.zoom = data;
  }

  public ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }
}
