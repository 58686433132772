import { NgModule } from '@angular/core';
import { CorrectPrecipitationDataComponent } from './containers/correct-precipitation-data/correct-precipitation-data.component';
import { CorrectPrecipitationDataRoutingModule } from './correct-precipitation-data-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { PrecipitationDataTableComponent } from './containers/precipitation-data-table/precipitation-data-table.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StationDataService } from '../station-data/effects/station-data.service';
import { AgGridModule } from 'ag-grid-angular';
import { reducers } from '../station-data/reducers';
import { CleanPrecipitationModalComponent } from './containers/clean-precipitation-modal/clean-precipitation-modal.component';
import { RainCorrectorHelpModalComponent } from './containers/rain-corrector-help-modal/rain-corrector-help-modal.component';

@NgModule({
  imports: [
    CommonModule,
    CorrectPrecipitationDataRoutingModule,
    SharedModule,
    TranslateModule.forChild(),
    StoreModule.forFeature('stationData', reducers),
    EffectsModule.forFeature([StationDataService]),
    AgGridModule.withComponents([]),
  ],
  declarations: [
    CorrectPrecipitationDataComponent,
    PrecipitationDataTableComponent,
    CleanPrecipitationModalComponent,
    RainCorrectorHelpModalComponent
  ],
})
export class CorrectPrecipitationDataModule { }
