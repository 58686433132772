import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StationConfigRoutingModule } from './station-config-routing.module';
import { StationConfigComponent } from './station-config.component';
import { SharedModule } from '../../shared/shared.module';
import { AutoSyncComponent } from './components/auto-sync/auto-sync.component';
import { TranslateModule } from '@ngx-translate/core';
import { AutoSyncContainerComponent } from './containers/auto-sync-container/auto-sync-container.component';
import { StationNotesComponent} from './containers/station-notes/station-notes.component';
import { StationConfigBaseNameComponent } from './containers/station-config-base-name/station-config-base-name.component';
import { StationNotesFormComponent } from './containers/station-notes/station-notes-form/station-notes-form.component';
import { StationConfigBaseNameFormComponent }
  from './containers/station-config-base-name/station-config-base-name-form/station-config-base-name-form.component';
import { StoreModule } from '@ngrx/store';
import { reducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { StationConfigEffectsEffects } from './effects/station-config-effects.effects';
import { TimezoneAndLocationComponent } from './containers/timezone-and-location/timezone-and-location.component';
import { TimezoneMapComponent } from './containers/timezone-and-location/timezone-map/timezone-map.component';
import { TimezoneFormComponent } from './containers/timezone-and-location/timezone-form/timezone-form.component';
import { AgmCoreModule } from '@agm/core';
import { GoogleMapService } from './services/google-map.service';
import { environment } from '../../../assets/themes/default/configuration';
import { LoggingAndTransferComponent } from './containers/logging-and-transfer/logging-and-transfer.component';
import { LoggingSettingsComponent } from './containers/logging-and-transfer/logging-settings/logging-settings.component';
import { SchedulerComponent } from './containers/logging-and-transfer/scheduler/scheduler.component';
import { AdvancedOptionsComponent } from './containers/logging-and-transfer/advanced-options/advanced-options.component';
import { SaveLoggingAndTransferComponent }
  from './containers/logging-and-transfer/save-logging-and-transfer/save-logging-and-transfer.component';
import { EmergencyComponent } from './containers/emergency/emergency.component';
import { SaveEmergencyComponent } from './containers/emergency/save-emergency/save-emergency.component';
import { EmergencyFormComponent } from './containers/emergency/emergency-form/emergency-form.component';
import { PhoneNumberDirective } from './directives/phone-number/phone-number.directive';
import { CameraSettingsComponent } from './containers/camera-settings/camera-settings.component';
import { CameraSettingsSaveComponent } from './containers/camera-settings/camera-settings-save/camera-settings-save.component';
import { CameraSettingsConfirmComponent } from './containers/camera-settings/camera-settings-confirm/camera-settings-confirm.component';
import { CameraSettingsImageComponent } from './containers/camera-settings/camera-settings-image/camera-settings-image.component';
import { CameraSettingsFormComponent } from './containers/camera-settings/camera-settings-form/camera-settings-form.component';
import { CameraSettingsCamComponent } from './containers/camera-settings/camera-settings-cam/camera-settings-cam.component';
import { CustomSquareControlComponent }
  from './containers/camera-settings/camera-settings-image/custom-square-control/custom-square-control.component';
import { TimezoneAndLocationModalComponent }
  from './containers/timezone-and-location/timezone-and-location-modal/timezone-and-location-modal.component';
import { LoggingAndTransferModalComponent }
  from './containers/logging-and-transfer/logging-and-transfer-modal/logging-and-transfer-modal.component';
import { CameraSettingsModalComponent } from './containers/camera-settings/camera-settings-modal/camera-settings-modal.component';
import { EmergencyModalComponent } from './containers/emergency/emergency-modal/emergency-modal.component';
import { TrackerDefinitionComponent } from './containers/tracker-definition/tracker-definition.component';
import { MachinerySettingsModule } from '../machinery-settings/machinery-settings.module';
import { TrackerSettingsComponent } from './containers/tracker-settings/tracker-settings.component';
import { GoogleMapLibraries } from '../../shared/constants';
import { TimezoneLeafletMapComponent } from './containers/timezone-and-location/timezone-leaflet-map/timezone-leaflet-map.component';
import { LoggingSettingsNbiotComponent } from './containers/logging-settings-nbiot/logging-settings-nbiot.component';
import { NbiotOptionsComponent } from './containers/logging-settings-nbiot/nbiot-options/nbiot-options.component';
import { StationLedTrapComponent } from './containers/station-led-trap/station-led-trap.component';
import { StationLedTrapSchedulerComponent } from './containers/station-led-trap/station-led-trap-scheduler/station-led-trap-scheduler.component';
import { StationLedTrapSettingsComponent } from './containers/station-led-trap/station-led-trap-settings/station-led-trap-settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoggingAndTransferTwoComponent } from './containers/logging-and-transfer-two/logging-and-transfer-two.component';
import { AdvancedOptionsTwoComponent } from './containers/logging-and-transfer-two/advanced-options-two/advanced-options-two.component';
import { LoggingAndTransferModalTwoComponent } from './containers/logging-and-transfer-two/logging-and-transfer-modal-two/logging-and-transfer-modal-two.component';
import { LoggingSettingsTwoComponent } from './containers/logging-and-transfer-two/logging-settings-two/logging-settings-two.component';
import { SaveLoggingAndTransferTwoComponent } from './containers/logging-and-transfer-two/save-logging-and-transfer-two/save-logging-and-transfer-two.component';
import { SchedulerTwoComponent } from './containers/logging-and-transfer-two/scheduler-two/scheduler-two.component';
import { NMetosFrostTableComponent } from './containers/n-metos-frost-table/n-metos-frost-table.component';

@NgModule({
  imports: [
    CommonModule,
    StationConfigRoutingModule,
    SharedModule,
    TranslateModule.forChild(),
    StoreModule.forFeature('config', reducers),
    EffectsModule.forFeature([StationConfigEffectsEffects]),
    AgmCoreModule.forRoot({
      apiKey: environment.gMapKey,
      libraries: GoogleMapLibraries
    }),
    MachinerySettingsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    StationConfigComponent,
    AutoSyncComponent,
    StationNotesComponent,
    StationConfigBaseNameComponent,
    AutoSyncContainerComponent,
    StationNotesFormComponent,
    StationConfigBaseNameFormComponent,
    TimezoneAndLocationComponent,
    TimezoneMapComponent,
    TimezoneFormComponent,
    LoggingAndTransferComponent,
    LoggingSettingsComponent,
    SchedulerComponent,
    AdvancedOptionsComponent,
    SaveLoggingAndTransferComponent,
    EmergencyComponent,
    SaveEmergencyComponent,
    EmergencyFormComponent,
    PhoneNumberDirective,
    CameraSettingsComponent,
    CameraSettingsSaveComponent,
    CameraSettingsConfirmComponent,
    CameraSettingsImageComponent,
    CameraSettingsFormComponent,
    CameraSettingsCamComponent,
    CustomSquareControlComponent,
    TimezoneAndLocationModalComponent,
    LoggingAndTransferModalComponent,
    CameraSettingsModalComponent,
    EmergencyModalComponent,
    TrackerDefinitionComponent,
    TrackerSettingsComponent,
    TimezoneLeafletMapComponent,
    LoggingSettingsNbiotComponent,
    NbiotOptionsComponent,
    StationLedTrapComponent,
    StationLedTrapSchedulerComponent,
    StationLedTrapSettingsComponent,
    LoggingAndTransferTwoComponent,
    AdvancedOptionsTwoComponent,
    LoggingAndTransferModalTwoComponent,
    LoggingSettingsTwoComponent,
    SaveLoggingAndTransferTwoComponent,
    SchedulerTwoComponent,
    NMetosFrostTableComponent
  ],
  providers: [
    StationConfigEffectsEffects,
    GoogleMapService
  ]
})
export class StationConfigModule { }
