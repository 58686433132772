import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { IOptions } from '../../../../../shared/interfaces';
import { IForecastActivation, IProductConfigurationItem } from '../../../models/product-activation.models';

@Component({
  selector: 'app-product-activation-forecast-form',
  templateUrl: './product-activation-forecast-form.component.html',
  styleUrls: ['./product-activation-forecast-form.component.scss'],
})
export class ProductActivationForecastFormComponent implements OnInit {
  @Input()
  public configuration: IProductConfigurationItem;
  @Input()
  public userStations$: Observable<IOptions[]>;
  @Output()
  public activateProductEmitter: EventEmitter<IForecastActivation> = new EventEmitter();

  public form: FormGroup;
  public currentDate: Date = new Date();
  public minDate: Date;
  public maxDate: Date = moment().add(2, 'year').toDate();

  constructor(private formBuilder: FormBuilder) { }

  public ngOnInit(): void {
    const year = new Date().getFullYear() - 2;
    this.minDate = new Date(year, 0, 1);
    this.form = this.formBuilder.group({
      'station_name': ['', [Validators.required]],
      'starting_date': [this.currentDate, [Validators.required]]
    });
  }

  public get licenseDuration(): string {
    return moment.duration(this.configuration.license_duration).humanize();
  }

  public get stationName(): AbstractControl {
    return this.form.get('station_name');
  }

  public get startingDate(): AbstractControl {
    return this.form.get('starting_date');
  }

  public submit(): void {
    const { station_name, starting_date } = this.form.value;
    this.activateProductEmitter.emit(<IForecastActivation>{
      product_key: this.configuration.product_key,
      product_pos: this.configuration.pos,
      license_duration: this.configuration.license_duration,
      station_name: station_name.content,
      starting_date
    });
  }
}
