import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ISelectedCropZoneState } from '../../../../core/reducers/selectedCropZone';
import { ISatState, ILai, ILais } from '../../models';
import { ICropZone } from '../../../../core/models/cropzones';
import { Observable, combineLatest, Subject, fromEvent } from 'rxjs';
import { selectSelectedCropZone } from '../../../../core/reducers';
import {
  filter,
  distinctUntilChanged,
  tap,
  map,
  takeUntil,
  debounceTime,
} from 'rxjs/operators';
import { getLaiStats, getLaiValRes10 } from '../../actions';
import { fittingCurve, laiRes10Selector, laiSelector } from '../../selectors';
import { getResolution } from '../../utils';
import { stationDataContentAnimations } from './../../../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from './../../../../core/services/left-components-toggler/left-components-toggler.service';
import { TreeService } from './../../../../services/tree/tree.service';
import { ModalService } from './../../../../shared/modal/services/modal.service';
import { ILaiVal, ILegendItem } from './../../models/index';
import { CropZoneSatServiceService } from './../../services/crop-zone-sat-service.service';
import { noDataErrorSelector } from './../../selectors/index';
import * as moment from 'moment';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { legendItems, SerieNames } from '../../consts/index';
import * as turf from '@turf/turf';
import { updateAmountOfColumns } from '../../actions/amountOfColumns';
import * as html2canvas from 'html2canvas';
import { Highcharts, Chart } from 'angular-highcharts';
import { Feature, Polygon } from 'geojson';
import { ITreeStructure } from './../../../../services/tree/models';
import * as fromLicenses from '../../../../core/reducers/licenses';
import * as d3 from 'd3';
import { selectAvailableLicenses } from './../../../../core/reducers/index';
import { SatelliteWarningService } from './../../services/satellite-warning-service.service';

declare const google: any;

@Component({
  selector: 'app-crop-zone-lai',
  templateUrl: './crop-zone-lai.component.html',
  styleUrls: ['./crop-zone-lai.component.scss'],
  animations: stationDataContentAnimations,
})
export class CropZoneLaiComponent implements OnInit, OnDestroy {
  @ViewChild('chartContainer')
  public chartContainer: ElementRef;
  public state$: Observable<string>;
  public tree$: Observable<ITreeStructure>;

  public cropZone$: Observable<ICropZone>;
  public cropZone: ICropZone;
  public values$: Observable<ILai[]>;
  private resizeObservable$: Observable<Event>;

  public isLoading: boolean = false;

  public cloudThreshold = 0.05;
  private destroy$: Subject<boolean> = new Subject();

  public charts: Chart[] = [];

  public resolution = -1;
  public loading = true;
  public noDataError: boolean = false;

  public DETAIL_MODAL: string = 'DETAIL_MODAL';
  public helpTextModalId: string = generateId();

  public detailDate: string;
  public resolution10mText: string;

  public xDays: number;
  public haveToGetStats: boolean;
  private fittingCurve: any;
  private currentLais: any;
  private xValuesOfCloudData: any = [];
  public lastDateError: boolean;
  private laiMeanData: any;
  private previousLais: any = [];
  public center: any = {lat: 47.2171, lng: 15.6230};
  public zoom: number = 7;
  public map: any;
  private allLaiItems: ILegendItem[] = legendItems;
  public bounds: any = true;
  private laiVal: ILaiVal;
  private legendItemClicked: number = null;
  private itemActive: boolean = false;
  private previous: any = {active: false, legendItemClicked: null};
  public zoomControl: boolean = true;
  public lastLicenseDate: string;

  constructor(
    private selectedCropzoneStore: Store<ISelectedCropZoneState>,
    private satStore: Store<ISatState>,
    private licenseStore: Store<fromLicenses.IProductLicenseState>,
    private treeService: TreeService,
    private leftComponentsToggler: LeftComponentsTogglerService,
    private modalService: ModalService,
    private cropzoneSatService: CropZoneSatServiceService,
    private warningService: SatelliteWarningService
  ) {}

  public ngOnInit(): void {
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeObservable$.subscribe((event) => {
      if (this.chartContainer) {
        const lazyListWidth = this.chartContainer.nativeElement.offsetWidth - 32;
        const lazyListWrapperWidth = (lazyListWidth / 100) * 90;

        const gap = 10;
        const minW = 225;
        const Wc = lazyListWrapperWidth;
        const N = Math.floor((Wc + gap) / (minW + gap));

        this.satStore.dispatch(updateAmountOfColumns(N));
      }
    });

    this.cropzoneSatService.componentMethodCalled$.pipe(takeUntil(this.destroy$)).subscribe((evt) => {
      let active: boolean;

      if (this.previous.legendItemClicked === evt) {
        if (this.previous.active) {
          this.map.data.revertStyle();
          active = false;
        } else {
          this.fillSelectedLegendColor(evt);
          active = true;
        }
      } else {
        this.fillSelectedLegendColor(evt);
        active = true;
      }

    this.previous.legendItemClicked = evt;
    this.previous.active = active;
  });

    this.state$ = this.leftComponentsToggler.getStationDataContentState();
    this.tree$ = this.treeService.getCropZoneSatelliteTreeStructure();

    this.charts = this.cropzoneSatService.addLaiChart(this.charts);

    this.satStore
      .pipe(
        select(noDataErrorSelector),
        debounceTime(350),
        takeUntil(this.destroy$)
      )
      .subscribe((noDataError) => {
        if (noDataError[this.cropZone.id]) {
          this.noDataError = noDataError[this.cropZone.id];
          this.loading = false;
        }
      });

    this.satStore
      .pipe(select(laiRes10Selector), takeUntil(this.destroy$))
      .subscribe((arrayOfFullRes: any) => {
        this.previous.legendItemClicked = null;
        this.previous.active = false;

        if (this.cropZone) {
          let laiVal;
        if (Object.keys(arrayOfFullRes).length > 0) {
          laiVal = arrayOfFullRes[this.cropZone.id];
        }

        if (laiVal) {
          this.laiVal = laiVal;
          const splittedDate = laiVal.date.split('-');
          this.detailDate = splittedDate[2] + '-' + splittedDate[1] + '-' + splittedDate[0];
          this.resolution10mText = 'Resolution: 10m';
          this.isLoading = false;

          if (this.map.data) {
            this.map.data.forEach(feature => {
              this.map.data.remove(feature);
            });
          }

          this.updateMap(laiVal);
          this.styleMap();
        }
          const boundary: Feature<Polygon> = {
            type: 'Feature',
            geometry: this.cropZone.boundary,
            properties: {},
          };

          const insidePolygons = [];
          laiVal.result.features.forEach(feature => {
            const outsideOfBoundary = turf.difference(feature.geometry, boundary.geometry);

            if (outsideOfBoundary == null) {
              insidePolygons.push(feature);
            } else {
              const insideOfBoundary = turf.difference(feature.geometry, outsideOfBoundary.geometry);
              insideOfBoundary.properties.lai = feature.properties.lai < 0 ? 0 : feature.properties.lai;
              insidePolygons.push(insideOfBoundary);
            }
          });

          const featureCollection = {
            type: 'FeatureCollection',
            features: insidePolygons
          };

          const featureAreas = [];
          featureCollection.features.forEach(feature => {
            let area = 0;
            area += turf.area(feature.geometry);

            const featureArea = {
              lai: feature.properties.lai,
              area: area / 10000,
              percentage: 0,
            };
            featureAreas.push(featureArea);
          });

          const fullArea = featureAreas.reduce(
            (accumulator, current) => accumulator + current.area,
            0
          );

          featureAreas.forEach((element) => {
            element.percentage = (element.area / fullArea) * 100;
          });

          this.cropzoneSatService.renderDetailedMap(
            featureCollection,
            boundary,
            featureAreas,
            'LAI'
          );
        }
      });

      this.cropZone$ = this.selectedCropzoneStore.pipe(
        select(selectSelectedCropZone),
        filter((cropZone) => !!cropZone),
        distinctUntilChanged((a, b) => a.id === b.id),
        debounceTime(300),
        tap((cropZone) => {
          this.noDataError = false;
          this.loading = true;
          this.resolution = getResolution(cropZone.boundary);
          this.cropZone = cropZone;
          this.resolution10mText = '';
          this.detailDate = '';
          d3.select('#map').selectAll('g').remove();
          d3.select('#legend').selectAll('*').remove();
        }),
        takeUntil(this.destroy$)
      );

    this.satStore
      .pipe(select(fittingCurve), debounceTime(350), takeUntil(this.destroy$))
      .subscribe((fittingCurveX) => {
        this.fittingCurve = fittingCurveX;

        if (this.fittingCurve[this.cropZone.id]) {
          const fittingCurveFromThisCropZone = this.fittingCurve[this.cropZone.id];

          if (fittingCurveFromThisCropZone) {
            this.fitting(fittingCurveFromThisCropZone);

            this.removePlotLines();
            if (this.laiMeanData) {
              this.addPlotLines(this.getAverage(this.laiMeanData));
            }
          }
        }
      });

    this.values$ = combineLatest([
      this.satStore.pipe(select(laiSelector)),
      this.licenseStore.pipe(
        select(selectAvailableLicenses),
        filter((licenses) => !!licenses),
        takeUntil(this.destroy$)
      ),
      this.cropZone$
    ]).pipe(
      tap(([lais, licenses, cropZone]: [ILais, any, ICropZone]) => {
        const lazyListWidth = this.chartContainer.nativeElement.offsetWidth - 32;
        const lazyListWrapperWidth = (lazyListWidth / 100) * 90;

        const gap = 10;
        const minW = 225;
        const Wc = lazyListWrapperWidth;
        const N = Math.floor((Wc + gap) / (minW + gap));

        this.satStore.dispatch(updateAmountOfColumns(N));

        this.lastDateError = false;

        this.removePlotLines();
        this.removeFittedBioMassSeries();

        if (this.fittingCurve) {
          const fittingCurveFromThisCropZone = this.fittingCurve[cropZone.id];

          if (fittingCurveFromThisCropZone) {
            this.fitting(fittingCurveFromThisCropZone);
          }
        }

        if (!lais[cropZone.id]) {
          this.haveToGetStats = true;
          this.removeFittedBioMassSeries();
          this.satStore.dispatch(getLaiStats(cropZone.id, this.resolution));
          this.loading = true;
        } else {
          this.haveToGetStats = false;
        }
      }),
      map(([lais, licenses, cropZone]: [any, any, any]) => {
        const selectedLicense = licenses.filter((license: any) => license.product_item.key === cropZone.product_key)[0];
        const licenseEndDate = moment(selectedLicense.end_date);
        const satOnlyLicense = this.warningService.setSatelliteWarningMessage(selectedLicense);
        const lai = lais[cropZone.id];
        this.currentLais = lai;

        if (lai) {
          return lai.filter((l) => {
            if (satOnlyLicense) {
              return l.cloud.mean <= this.cloudThreshold && moment(l.date).isBefore(licenseEndDate);
            } else {
              return l.cloud.mean <= this.cloudThreshold;
            }
          });
        } else {
          return [];
        }
      }),
      tap((lais) => {
        if (!this.haveToGetStats && lais.length > 0) {
          this.noDataError = false;
          this.loading = false;

          if (this.lastDateOlderThan5(lais)) {
            this.lastDateError = true;
          } else {
            this.lastDateError = false;
          }
          lais = lais.filter(
            (lai) =>
              !isNaN(lai.lai.min) && !isNaN(lai.lai.mean) && !isNaN(lai.lai.max)
          );

          if (JSON.stringify(lais) !== JSON.stringify(this.previousLais)) {
            const array = this.updateChart(lais);
            this.removePlotLines();
            this.addPlotLines(array);

            this.previousLais = lais;
          }

          Highcharts.charts.forEach(chart => {
            if (chart) {
              chart.reflow();
            }
          });
        } else if (!this.haveToGetStats && lais.length === 0) {
          this.noDataError = true;
          this.loading = false;
        }
      }),
      takeUntil(this.destroy$)
    );
  }

  public updateChart(lais: any[]): number {
    if (lais && lais.length) {
      const laiChart = this.charts[0];

      const data: any = lais
        .slice()
        .reverse()
        .reduce(
          (arr, lai) => {
            const date = Date.parse(lai.date);
            const min = lai.lai.min;
            const mean = lai.lai.mean;
            const max = lai.lai.max;

            arr[0].push([date, max]);
            arr[1].push([date, mean]);
            arr[2].push([date, min]);
            return arr;
          },
          [[], [], []]
        );

      const laisAboveThreshold = this.currentLais.filter(
        (lai) => lai.cloud.mean > this.cloudThreshold
      );

      laisAboveThreshold.forEach(function (lai, index, theArray): void {
        const date = Date.parse(lai.date);
        theArray[index] = [date, lai.lai.mean];
      });

      this.xValuesOfCloudData = laisAboveThreshold.map((lai: any) => [lai[0]]);

      const laiMaxData: any[] = data[0];
      const laiMeanData: any[] = data[1];
      const laiMinData: any[] = data[2];

      if (laiChart.ref) {
        laiChart.ref.series.forEach((serie, index, theArray) => {
          if (serie.name === SerieNames.MAX_BIOMASS) {
            theArray[index].data = laiMaxData;
          } else if (serie.name === SerieNames.MEAN_BIOMASS) {
            theArray[index].data = laiMeanData;
          } else if (serie.name === SerieNames.MIN_BIOMASS) {
            theArray[index].data = laiMinData;
          }
        });
      } else {
        laiChart.options.series.forEach((serie, index, theArray) => {
          if (serie.name === SerieNames.MAX_BIOMASS) {
            theArray[index].data = laiMaxData;
          } else if (serie.name === SerieNames.MEAN_BIOMASS) {
            theArray[index].data = laiMeanData;
          } else if (serie.name === SerieNames.MIN_BIOMASS) {
            theArray[index].data = laiMinData;
          }
        });
      }

      this.laiMeanData = laiMeanData;
      return this.getAverage(laiMeanData);
    }
  }

  private getAverage(laiMeanData: any[]): number {
    const average = (array) => array.reduce((a, b) => a + b) / array.length;
    const aver = average(laiMeanData.map((dataa) => dataa[1]));
    const averageValue = this.xValuesOfCloudData.map((dataa) => [
      dataa[0],
      aver,
    ]);

    return averageValue;
  }

  private lastDateOlderThan5(lais: any[]): boolean {
    const newestLaiDate = new Date(lais[0].date);
    const now = new Date();

    const differenceInTime = now.getTime() - newestLaiDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    if (differenceInDays > 5) {
      return true;
    } else {
      return false;
    }
  }

  private fitting(fittingCurveFromThisCropZone: any[]): void {
    const data = this.getFittingCurveDataFromStore(
      fittingCurveFromThisCropZone
    );

    this.removeFittedBioMassSeries();
    this.addFittedBioMassSeries();
    if (data.length > 0) {
      if (this.charts[0].ref) {
        if (
          this.charts[0].ref.series.some(
            (serie) => serie.name === SerieNames.FITTED_BIOMASS
          )
        ) {
          const index = this.charts[0].ref.series.findIndex(
            (serie) => serie.name === SerieNames.FITTED_BIOMASS
          );
          this.charts[0].ref.series[index].setData(data);
        }
      }

      if (this.charts[0].options) {
        if (
          this.charts[0].options.series.some(
            (serie) => serie.name === SerieNames.FITTED_BIOMASS
          )
        ) {
          const index = this.charts[0].options.series.findIndex(
            (serie) => serie.name === SerieNames.FITTED_BIOMASS
          );
          this.charts[0].options.series[index].data = data;
        }
      }
    }
  }

  private getFittingCurveDataFromStore(
    fittingCurveFromThisCropZone: any[]
  ): any {
    const data = [];

    fittingCurveFromThisCropZone.forEach((fitting) => {
      data.push([
        moment(this.cropZone.from).valueOf() + fitting.x * 1000 * 60 * 60 * 24,
        fitting.y,
      ]);
    });

    return data;
  }

  private addFittedBioMassSeries(): void {
    if (this.charts[0]) {
      this.charts[0].addSerie({
        visible: true,
        type: 'spline',
        name: SerieNames.FITTED_BIOMASS,
        data: [],
        color: '#8A9A5B',
        lineWidth: 3,
        pointPadding: 0,
        groupPadding: 0,
        borderWidth: 0,
        legendIndex: 0,
        shadow: false,
        marker: {
          enabled: false,
        },
        tooltip: {
          valueDecimals: 1,
        },
      } as any);
    }
  }

  private addPlotLines(array: any): void {
    const self = this;
    const xPlotLines = [];

    this.xValuesOfCloudData.forEach((element) => {
      const value = {
        color: 'orange',
        width: 2,
        value: element,
        id: generateId(),
      };

      if (this.charts[0].options) {
        this.charts[0].options.xAxis['plotLines'].push(value);
      } else {
        this.charts[0].ref.xAxis['plotLines'].push(value);
      }

      xPlotLines.push(value);
    });

    this.charts[0].addSerie({
      color: 'orange',
      name: SerieNames.CLOUD_STATUS,
      visible: false,
      legendIndex: 3,
      marker: {
        enabled: true,
        symbol:
          'url(../../../../../assets/img/cloudicon.svg)',
        height: 16,
        width: 16,
      },
      tooltip: {
        valueDecimals: 1,
        pointFormat: '',
      },
      events: {
        legendItemClick: function (): void {
          if (this.visible) {
            xPlotLines.forEach((element) => {
              this.chart.xAxis[0].removePlotLine(element.id);
            });

            const series = this.chart.series;
            series.forEach((serie) => {
              if (serie.name === SerieNames.CLOUD_MARKERS) {
                serie.hide();
              }
            });
          } else {
            xPlotLines.forEach((element) => {
              this.chart.xAxis[0].addPlotLine(element);
            });

            array.sort((a, b) => a[0] - b[0]);
            const series = this.chart.series;
            series.forEach((serie) => {
              if (serie.name === SerieNames.CLOUD_MARKERS) {
                serie.setData(array);
                serie.show();
              }
            });
          }
        },
      },
    } as any);

    this.charts[0].options.xAxis['plotLines'] = [];
  }

  private removePlotLines(): void {
    if (this.charts[0].ref) {
      const series = this.charts[0].ref.series;

      series.forEach((serie, index) => {
        if (serie.name === SerieNames.CLOUD_STATUS) {
          this.charts[0].removeSerie(index);
        }
      });

      if (series.some((serie) => serie.name === SerieNames.CLOUD_STATUS)) {
        const index = series.findIndex(
          (serie) => serie.name === SerieNames.CLOUD_STATUS
        );
        this.charts[0].removeSerie(index);
      }

      if (series.some((serie) => serie.name === SerieNames.CLOUD_MARKERS)) {
        const index = series.findIndex(
          (serie) => serie.name === SerieNames.CLOUD_MARKERS
        );
        this.charts[0].ref.series[index].data = [];
      }
    }

    if (this.charts[0].options) {
      const series = this.charts[0].options.series;

      if (series.some((serie) => serie.name === SerieNames.CLOUD_STATUS)) {
        const index = series.findIndex(
          (serie) => serie.name === SerieNames.CLOUD_STATUS
        );
        this.charts[0].removeSerie(index);
      }
      if (series.some((serie) => serie.name === SerieNames.CLOUD_MARKERS)) {
        const index = series.findIndex(
          (serie) => serie.name === SerieNames.CLOUD_MARKERS
        );
        this.charts[0].options.series[index].data = [];
      }
    }
  }

  private removeFittedBioMassSeries(): void {
    if (this.charts[0].ref) {
      const series = this.charts[0].ref.series;

      if (series.some((serie) => serie.name === SerieNames.FITTED_BIOMASS)) {
        const index = series.findIndex(
          (serie) => serie.name === SerieNames.FITTED_BIOMASS
        );
        this.charts[0].removeSerie(index);
      }
    }

    if (this.charts[0].options) {
      const series = this.charts[0].options.series;

      if (series.some((serie) => serie.name === SerieNames.FITTED_BIOMASS)) {
        const index = series.findIndex(
          (serie) => serie.name === SerieNames.FITTED_BIOMASS
        );
        this.charts[0].removeSerie(index);
      }
    }
  }

  public onSelectedItemChanged(lai: ILai): void {
    this.isLoading = true;
    this.modalService.openModal(this.DETAIL_MODAL);

    const date: any[] = [lai.date];
    this.satStore.dispatch(getLaiValRes10(this.cropZone.id, date));
  }

  private reflow(): void {
    Highcharts.charts.forEach((chart) => {
      if (chart) {
        chart.reflow();
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public downloadChart(): void {
    Highcharts.charts.forEach((chart) => {
      if (chart !== undefined) {
        chart.exportChart({
          type: 'image/png',
          filename: 'lai-statistics ' + this.cropZone.name,
          sourceWidth: 1600,
        });
      }
    });
  }

  public downloadFullRes(): void {
    document.getElementById('download').style.display = 'none';
    this.zoomControl = false;

    setTimeout(() => {
      // @ts-ignore
      html2canvas(document.getElementById('modalContainer'), { useCORS: true}).then( canvas => {
        const image64 = canvas.toDataURL('image/jpeg');

        const link = document.createElement('a');
        link.download = 'full-resolution-' + this.laiVal.date;
        link.href = image64;
        link.click();
      });

      document.getElementById('download').style.display = 'block';
      this.zoomControl = true;
    }, 50);
  }

  public onMapReady(mapInstance): void {
    this.map = mapInstance;
    this.map.setOptions({
      zoomControl: 'true',
      zoomControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT
      }
    });
  }

  public updateMap(laiVal): void {
    const bounds = new google.maps.LatLngBounds();

    const boundary: Feature<Polygon> = {
      type: 'Feature',
      geometry: this.cropZone.boundary,
      properties: {},
    };

    const insidePolygons = [];
    laiVal.result.features.forEach(feature => {
      const outsideOfBoundary = turf.difference(feature.geometry, boundary.geometry);

      if (outsideOfBoundary == null) {
        insidePolygons.push(feature);
      } else {
        const insideOfBoundary = turf.difference(feature.geometry, outsideOfBoundary.geometry);
        insideOfBoundary.properties.lai = feature.properties.lai < 0 ? 0 : feature.properties.lai;
        insidePolygons.push(insideOfBoundary);
      }
    });

    const geoJsonObject = {
      type: 'FeatureCollection',
      features: insidePolygons
    };

    this.map.data.addGeoJson(geoJsonObject);

    const cropzoneBoundaryPath = this.cropZone.boundary.coordinates[0].map((coords) => ({lat: coords[1], lng: coords[0]}));
    for (const coordinate of cropzoneBoundaryPath) {
      bounds.extend(new google.maps.LatLng(coordinate.lat, coordinate.lng));
    }

    this.bounds = bounds;
  }

  private styleMap(): void {
    const self = this;
    let color: string;

    this.map.data.setStyle(function (feature: any): any {
      const laiValue = Object.values(feature)
        .filter((prop: any) => prop && prop.lai)
        .map((prop: any) => prop.lai)[0] || 0;

      if (laiValue !== null) {
        if (laiValue > 6) {
          color = self.allLaiItems[0].color;
        } else if (laiValue <= 3) {
          const featureLai: number = laiValue < 0 ? 0 : laiValue;
          color = self.allLaiItems.filter(legendItem => legendItem.startValue === featureLai && !legendItem.endValue)[0].color;
        } else {
          self.allLaiItems.forEach(legendItem => {
            if (laiValue > legendItem.startValue && laiValue <= legendItem.endValue) {
              color = legendItem.color;
            }
          });
        }
      }

      return {
        fillColor: color,
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: 'white',
        strokeOpacity: 0.7
      };
    });
  }

  private fillSelectedLegendColor(evt): void {
    this.map.data.revertStyle();
    this.map.data.forEach((feature) => {
      Object.keys(feature).forEach((key) => {
        if (feature[key]) {
          if (feature[key].lai > 6 && evt === '6+') {
            this.map.data.overrideStyle(feature, {
              fillColor: 'blue',
              fillOpacity: 1,
              strokeWeight: 1,
              strokeColor: 'white',
              strokeOpacity: 0.7
            });
          } else {
          const legendItem = this.allLaiItems.filter(item => item.item === evt)[0];
          if (!legendItem.endValue && legendItem.startValue !== 6) {
            if (feature[key].lai === legendItem.startValue) {
              this.map.data.overrideStyle(feature, {
                fillColor: 'blue',
                fillOpacity: 1,
                strokeWeight: 1,
                strokeColor: 'white',
                strokeOpacity: 0.7
              });
            }
          } else {
            if (feature[key].lai > legendItem.startValue && feature[key].lai <= legendItem.endValue) {
              this.map.data.overrideStyle(feature, {
                fillColor: 'blue',
                fillOpacity: 1,
                strokeWeight: 1,
                strokeColor: 'white',
                strokeOpacity: 0.7
              });
            }
          }
        }
        }
      });
    });
  }

  public openModal(): void {
    this.modalService.openModal(this.helpTextModalId);
  }
}
