<app-weather-forecast-layout>
<div *ngIf="!(!(isLoading$ | async) && !(isError$ | async));">
  <app-weather-forecast-load-info [isError]="isError$ | async" [isLoading]="isLoading$ | async"></app-weather-forecast-load-info>
</div>
  <ng-container *ngIf="!(isLoading$ | async) && !(isError$ | async);">
    <app-form-layout>
      <img src="{{ imageUrl$ | async }}">
    </app-form-layout>
  </ng-container>
</app-weather-forecast-layout>
