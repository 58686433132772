<div class="rain-corrector-help-modal">
  <p>
    {{ 'This page allows users to manually correct precipitation data from virtual or physical iMETOS weather stations.
        As for station-data-read-only rights: users who contain the K1 access key can see and correct precipitation data,
        while K2 cannot.' | translate }}
  </p>
  <p>
    {{ 'First, choose the time period from your dataset. Edit row by row and don’t forget to click “Apply” to save and 
        compute your changes. ' | translate }}
  </p>
  <p>
    {{'The “Reset to 0” button allows users to clean all precipitation data to 0 mm, for the selected time period. Once 
       applied, precipitation corrections will be automatically reflected on the Station Data page, likewise, on Irrimet 
       (Water Balance calculation in FarmView).' | translate }}
  </p>
  <!-- <p>
    {{ 'The “Clean precipitation” button allows users to clean all precipitation data to 0 mm, for the selected time period.' | translate }}
  </p>
  <p>
    {{ 'Once applied, precipitation corrections will be automatically reflected on the Station Data page, likewise,  
        on Irrimet (Water Balance calculation in FarmView).' | translate }}
  </p> -->
</div>