import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { ICropZone } from '../../core/models/cropzones';
import { selectSelectedCropZone } from '../../core/reducers';
import { LeftComponentsTogglerService } from '../../core/services/left-components-toggler/left-components-toggler.service';
import { ITreeStructure } from '../../services/tree/models';
import { TreeService } from '../../services/tree/tree.service';
import * as fromSelectedCropzone from '../../core/reducers/selectedCropZone';
import { takeUntil, filter } from 'rxjs/operators';
import { stationDataContentAnimations } from '../../core/services/left-components-toggler/animation.constants';
import * as fromLicenses from './../../core/reducers/licenses';
import { selectAvailableLicenses } from './../../core/reducers/index';
import * as moment from 'moment';

@Component({
  selector: 'app-yield-prediction-previous',
  templateUrl: './yield-prediction-previous.component.html',
  styleUrls: ['./yield-prediction-previous.component.scss'],
  animations: stationDataContentAnimations
})
export class YieldPredictionPreviousComponent implements OnInit, OnDestroy {
  public state$: Observable<string>;
  public tree$: Observable<ITreeStructure>;
  public selectedCropzone: ICropZone;
  public destroy$ = new Subject<boolean>();
  public diffDays: number;
  public activeLicense: any;

  constructor(
    private treeService: TreeService,
    private leftComponentsToggler: LeftComponentsTogglerService,
    private selectedStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private licenseStore: Store<fromLicenses.IProductLicenseState>
  ) { }

  public ngOnInit() : void {
    this.state$ = this.leftComponentsToggler.getStationDataContentState().pipe(
      takeUntil(this.destroy$)
    );
    this.tree$ = this.treeService.getCropZoneYieldPredictionTreeStructure().pipe(
      takeUntil(this.destroy$)
    );

    this.selectedStore.pipe(
      takeUntil(this.destroy$),
      select(selectSelectedCropZone)
    ).subscribe((cropzone: ICropZone) =>
      this.selectedCropzone = cropzone
    );

    combineLatest([
      this.selectedStore.pipe(
        takeUntil(this.destroy$),
        select(selectSelectedCropZone)
      ),
      this.licenseStore.pipe(
        select(selectAvailableLicenses),
        filter((licenses) => !!licenses && licenses.length > 0),
        takeUntil(this.destroy$)
      )
    ]).subscribe(([cropzone, licenses]) => {
      this.activeLicense = licenses.filter((l) => l.product_item.key === cropzone.product_key)[0];
      if (this.activeLicense) {
        const now = moment();
        const endDate = moment(this.activeLicense.end_date);
        this.diffDays = endDate.diff(now, 'days');
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
