import {IActionWithPayload} from '../models/actionWithPayload';
import {INavigationCropzoneState} from '../reducers/navigation-cropzone';

export const enum NavigationCropzoneActionTypes {
  SET_NAVIGATION_CROPZONE = '[Account] SET_NAVIGATION_CROPZONE',
  // SET_NAVIGATION_STATION_BASE_NAME = '[Account] SET_NAVIGATION_STATION_BASE_NAME',
  // SET_TRACKER_SETTINGS = '[Account] SET_TRACKER_SETTINGS'
}

export function setNavigationCropzone(data: INavigationCropzoneState): IActionWithPayload {
  return { type: NavigationCropzoneActionTypes.SET_NAVIGATION_CROPZONE, payload: data };
}
/*
export function setNavigationStationBaseName(data: string): IActionWithPayload {
  return { type: NavigationStationActionTypes.SET_NAVIGATION_STATION_BASE_NAME, payload: data };
}

export function setTrackerSettings(data: number): IActionWithPayload {
  return { type: NavigationStationActionTypes.SET_TRACKER_SETTINGS, payload: data };
}*/
