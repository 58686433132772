import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {
  ICameraPest,
  ICameraPestFamily,
  ICameraPestGenus,
  ICameraPestOrder,
  IDeleteCameraPestBody,
  IPostCameraPestBody,
  IPostCameraPestPayload
} from '../../../shared/camera/models/camera';
import {IIscoutPestFormAction} from '../models/iscout.models';

export const enum IscoutPestsActionTypes {
  GET_ISCOUT_PESTS_ORDERS = '[IscoutPests] GET_ISCOUT_PESTS_ORDERS',
  SET_ISCOUT_PESTS_ORDERS = '[IscoutPests] SET_ISCOUT_PESTS_ORDERS',
  GET_ISCOUT_PESTS_GENUS = '[IscoutPests] GET_ISCOUT_PESTS_GENUS',
  SET_ISCOUT_PESTS_GENUS = '[IscoutPests] SET_ISCOUT_PESTS_GENUS',
  GET_ISCOUT_PESTS_FAMILY = '[IscoutPests] GET_ISCOUT_PESTS_FAMILY',
  SET_ISCOUT_PESTS_FAMILY = '[IscoutPests] SET_ISCOUT_PESTS_FAMILY',
  GET_ISCOUT_USER_PESTS = '[IscoutPests] GET_ISCOUT_USER_PESTS',
  SET_ISCOUT_USER_PESTS = '[IscoutPests] SET_ISCOUT_USER_PESTS',
  SELECT_ISCOUT_PEST = '[IscoutPests] SELECT_ISCOUT_PEST',
  UNSELECT_ISCOUT_PEST = '[IscoutPests] UNSELECT_ISCOUT_PEST',
  SAVE_ISCOUT_PEST = '[IscoutPests] SAVE_ISCOUT_PEST',
  REMOVE_ISCOUT_PEST = '[IscoutPests] REMOVE_ISCOUT_PEST',
}

export function getIscoutPestsOrders(): IActionWithPayload {
  return {type: IscoutPestsActionTypes.GET_ISCOUT_PESTS_ORDERS, payload: null};
}

export function setIscoutPestsOrders(pestsOrders: Array<ICameraPestOrder>): IActionWithPayload {
  return {type: IscoutPestsActionTypes.SET_ISCOUT_PESTS_ORDERS, payload: pestsOrders};
}

export function getIscoutPestsFamily(): IActionWithPayload {
  return {type: IscoutPestsActionTypes.GET_ISCOUT_PESTS_FAMILY, payload: null};
}

export function setIscoutPestsFamily(pestsFamily: Array<ICameraPestFamily>): IActionWithPayload {
  return {type: IscoutPestsActionTypes.SET_ISCOUT_PESTS_FAMILY, payload: pestsFamily};
}

export function getIscoutPestsGenus(): IActionWithPayload {
  return {type: IscoutPestsActionTypes.GET_ISCOUT_PESTS_GENUS, payload: null};
}

export function setIscoutPestsGenus(pestsGenus: Array<ICameraPestGenus>): IActionWithPayload {
  return {type: IscoutPestsActionTypes.SET_ISCOUT_PESTS_GENUS, payload: pestsGenus};
}

export function getIscoutUserPests(stationId: string): IActionWithPayload {
  return {type: IscoutPestsActionTypes.GET_ISCOUT_USER_PESTS, payload: stationId};
}

export function setIscoutUserPests(userPests: Array<ICameraPest>): IActionWithPayload {
  return {type: IscoutPestsActionTypes.SET_ISCOUT_USER_PESTS, payload: userPests};
}

export function selectIscoutPest(selectedPest: IIscoutPestFormAction): IActionWithPayload {
  return {type: IscoutPestsActionTypes.SELECT_ISCOUT_PEST, payload: selectedPest};
}

export function unselectIscoutPest(): IActionWithPayload {
  return {type: IscoutPestsActionTypes.UNSELECT_ISCOUT_PEST, payload: null};
}

export function saveIscoutPest(stationId: string, savedPest: IPostCameraPestBody): IActionWithPayload {
  const payload: IPostCameraPestPayload = {stationId, body: savedPest};
  return {type: IscoutPestsActionTypes.SAVE_ISCOUT_PEST, payload};
}

export function removeIscoutPest(stationId: string, deletedPest: IDeleteCameraPestBody): IActionWithPayload {
  const payload: IPostCameraPestPayload = {stationId, body: deletedPest};
  return {type: IscoutPestsActionTypes.REMOVE_ISCOUT_PEST, payload};
}
