import { IActionWithPayload } from './../models/actionWithPayload';
import { IField } from '../models/fields';
import { FieldActionTypes } from '../actions/fields';
import { deepClone } from '../../shared/utils/deepClone';

export interface IFields {
    fields: Array<IField>;
}

const initialState: IFields = {
    fields: []
};

export function reducer(state: IFields = initialState, action: IActionWithPayload): IFields {
    switch (action.type) {
        case FieldActionTypes.SET_FIELDS: {
            return {
                ...state,
                fields: action.payload
            };
        }
        case FieldActionTypes.SET_FIELD: {
            return {
                ...state,
                fields: [
                    ...state.fields.filter(field => field.id !== action.payload.id),
                    action.payload
              ]
            };
        }
        case FieldActionTypes.DELETE_FIELD: {
            return {
                ...state,
                fields: deleteFieldHelp(action.payload, state.fields)
            };
        }
        default: {
            return state;
        }
    }
}

function deleteFieldHelp(id: string, fields: IField[]): IField[] {
    const newFields: IField[] = fields.filter((f: IField) => f.id !== id);
    return deepClone(newFields);
}
