import { ISensor } from '../../../shared/interfaces';
import { IServerAlert } from '../../server-alerts/interfaces/server-alert';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { ActionTypes } from '../actions/alerts';
import { ActionTypes as sensorActionTypes } from '../actions/sensors';

export interface IAlertsState {
    serverAlerts: any[];
    sensors: ISensor[];
}

export const initialState: IAlertsState = {
    serverAlerts: [],
    sensors: []
};

export function reducer(state: IAlertsState = initialState, action: IActionWithPayload): IAlertsState {
    switch (action.type) {
        case ActionTypes.SET_USER_ALERTS: {
            return {
                ...state,
                serverAlerts: action.payload
            };
        }
        case ActionTypes.UPDATE_SERVER_ALERT: {
            return {
              ...state,
              serverAlerts: state.serverAlerts.map(alert => {
                if (
                  action.payload._id &&
                  alert._id === action.payload._id
                ) {
                  return { ...action.payload };
                } else {
                  return alert;
                }
              })
            };
        }
        case ActionTypes.APPEND_SERVER_ALERT: {
          // tslint:disable-next-line:max-line-length
          const sensor = state.sensors.filter(sensorX => sensorX.ch === action.payload.sensorChannel && sensorX.code === action.payload.sensorCode)[0];
          const sensorName = sensor.name;
          const thresholdUserUnit = sensor.unit;
          const lastTimeSent = null;
          const lastThreshold = null;
          const lastDatapointProcessed = null;

          return {
            ...state,
            // tslint:disable-next-line:max-line-length
            serverAlerts: [...state.serverAlerts, { ...action.payload, sensorName, thresholdUserUnit,  lastTimeSent, lastThreshold, lastDatapointProcessed }]
          };
        }
        case sensorActionTypes.SET_STATION_SENSORS: {
          return {
              ...state,
              sensors: action.payload
          };
        }
        case ActionTypes.DELETE_SERVER_ALERT: {
            return {
              ...state,
              serverAlerts: state.serverAlerts.filter(alert =>
                alert._id !== action.payload.alert._id
              )
            };
        }
        default: return state;
    }
}
