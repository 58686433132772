import {ITrackerTripsData, IGetTrackerDataRequest, ITrackerPeriod} from './../models/tracker.models';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';

export const enum TrackerActionTypes {
  GET_TRACKER_DATA = '[Tracker] GET_DATA',
  SET_TRACKER_DATA = '[Tracker] SET_DATA',
  SET_TRACKER_ERROR = '[Tracker] SET_TRACKER_ERROR',
  SET_TRACKER_LOADING = '[Tracker] SET_TRACKER_LOADING',
  SET_TRACKER_PERIOD = '[Tracker] SET_TRACKER_PERIOD',
  GET_TRACKER_REPORTS = '[Tracker] GET_TRACKER_REPORTS',
  SET_TRACKER_REPORTS = '[Tracker] SET_TRACKER_REPORTS',
  GET_MACHINERY_REPORTS = '[Tracker] GET_MACHINERY_REPORTS',
  SET_MACHINERY_REPORTS = '[Tracker] SET_MACHINERY_REPORTS',
  GET_SELECTED_CONNECTION = '[Tracker] GET_SELECTED_CONNECTION',
  SET_SELECTED_CONNECTION = '[Tracker] SET_SELECTED_CONNECTION',
  GET_TRACKER_INFO = '[Tracker] GET_TRACKER_INFO',
  SET_TRACKER_INFO = '[Tracker] SET_TRACKER_INFO',
  GET_MACHINERY_INFO = '[Tracker] GET_MACHINERY_INFO',
  SET_MACHINERY_INFO = '[Tracker] SET_MACHINERY_INFO',
  RESET_SELECTED_CONNECTION = '[Tracker] RESET_SELECTED_CONNECTION',
  TOGGLE_TRIP_SELECTED = '[Tracker] TOGGLE_TRIP_SELECTED',
}

export function getTrackerData(request: IGetTrackerDataRequest): IActionWithPayload {
  return { type: TrackerActionTypes.GET_TRACKER_DATA, payload: request };
}

export function setTrackerData(data: Array<ITrackerTripsData>): IActionWithPayload {
  return { type: TrackerActionTypes.SET_TRACKER_DATA, payload: data };
}

export function setTrackerLoading(isLoading: boolean): IActionWithPayload {
  return {type: TrackerActionTypes.SET_TRACKER_LOADING, payload: isLoading};
}

export function setTrackerError(isError: boolean): IActionWithPayload {
  return {type: TrackerActionTypes.SET_TRACKER_ERROR, payload: isError};
}

export function setTrackerPeriod(period: ITrackerPeriod): IActionWithPayload {
  return { type: TrackerActionTypes.SET_TRACKER_PERIOD, payload: period };
}

export function getTrackerReports(request: any): IActionWithPayload {
  return { type: TrackerActionTypes.GET_TRACKER_REPORTS, payload: request };
}

export function setTrackerReports(request: any): IActionWithPayload {
  return { type: TrackerActionTypes.SET_TRACKER_REPORTS, payload: request };
}

export function getMachineryReports(request: any): IActionWithPayload {
  return { type: TrackerActionTypes.GET_MACHINERY_REPORTS, payload: request };
}

export function setMachineryReports(request: any): IActionWithPayload {
  return { type: TrackerActionTypes.SET_MACHINERY_REPORTS, payload: request };
}

export function getSelectedConnection(request: any): IActionWithPayload {
  return { type: TrackerActionTypes.GET_SELECTED_CONNECTION, payload: request };
}

export function setSelectedConnection(request: any): IActionWithPayload {
  return { type: TrackerActionTypes.SET_SELECTED_CONNECTION, payload: request };
}

export function toggleTripSelected(request: any): IActionWithPayload {
  return { type: TrackerActionTypes.TOGGLE_TRIP_SELECTED, payload: request };
}

export function getTrackerInfo(request: any): IActionWithPayload {
  return { type: TrackerActionTypes.GET_TRACKER_INFO, payload: request };
}

export function setTrackerInfo(request: any): IActionWithPayload {
  return { type: TrackerActionTypes.SET_TRACKER_INFO, payload: request };
}

export function getMachineryInfo(request: any): IActionWithPayload {
  return { type: TrackerActionTypes.GET_MACHINERY_INFO, payload: request };
}

export function setMachineryInfo(request: string): IActionWithPayload {
  return { type: TrackerActionTypes.SET_MACHINERY_INFO, payload: request };
}

export function resetSelectedConnection(): IActionWithPayload {
  return { type: TrackerActionTypes.RESET_SELECTED_CONNECTION, payload: null };
}
