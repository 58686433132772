import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  addVirtualStation, changeVirtualStationElevation, changeVirtualStationTimezone, removeVirtualStation
} from '../../../actions/stations';
import { IAddRemoveVirtualStation, IPosition } from '../../../models/stations';
import { selectVirtualStationDetails } from '../../../reducers';
import * as fromStations from '../../../reducers/stations';
import { IVirtualStationDetails } from '../../../reducers/stations';

@Component({
  selector: 'app-add-remove-virtual-station',
  templateUrl: './add-remove-virtual-station.component.html',
  styleUrls: ['./add-remove-virtual-station.component.scss']
})
export class AddRemoveVirtualStationComponent implements OnInit, OnDestroy {
  public isAddStation: boolean = true;
  private stationDetails: IVirtualStationDetails;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private store: Store<fromStations.IStations>) { }

  public ngOnInit(): void {
    this.store.pipe(
      select(selectVirtualStationDetails),
      takeUntil(this.destroy$)
    ).subscribe(virtualStationDetails => this.stationDetails = virtualStationDetails);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public setElevationAndTimezone(event: IPosition): void {
    this.store.dispatch(changeVirtualStationElevation(event));
    this.store.dispatch(changeVirtualStationTimezone(event));
  }

  public addVirtualStation(event: IAddRemoveVirtualStation): void {
    const { elevation, timezoneCode } = this.stationDetails;
    this.store.dispatch(addVirtualStation({
      ...event,
      position: {
        ...event.position,
        altitude: elevation,
        timezoneCode
      }
    }));
  }

  public removeVirtualStation(event: IAddRemoveVirtualStation): void {
    this.store.dispatch(removeVirtualStation(event));
  }

}
