import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { ISatState } from '../../models';
import { selectSatWarning } from '../../selectors';

@Component({
  selector: 'app-sat-warning-message',
  templateUrl: './sat-warning-message.component.html',
  styleUrls: ['./sat-warning-message.component.scss']
})
export class SatWarningMessageComponent implements OnInit, OnDestroy {
  public warningMessage: string|null = null;
  public icon: boolean = false;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private store: Store<ISatState>
  ) { }

  public ngOnInit(): void {
    this.store.pipe(
      select(selectSatWarning),
      takeUntil(this.destroy$)
    ).subscribe((warning: any) => {
      this.warningMessage = warning.message;
      this.icon = warning.icon;
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
