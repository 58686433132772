import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPosition } from '../../../../../core/models/stations';
import { getCoordinates } from '../../../actions/timezoneAndLocation';
import { StationsMarkers } from '../../../constants/constants';
import * as fromTimezoneAndLocation from '../../../reducers/timezoneAndLocation';

@Component({
  selector: 'app-timezone-map',
  templateUrl: './timezone-map.component.html',
  styleUrls: ['./timezone-map.component.scss']
})
export class TimezoneMapComponent {
  @Input()
  public position: IPosition;
  @Input()
  public id      : number;

  constructor(private store: Store<fromTimezoneAndLocation.ITimezoneAndLocationState>) { }

  public moveStation(event: any): void {
   this.store.dispatch(getCoordinates([event.coords.lng , event.coords.lat]));
  }

  public getDeviceIcon(id: number): string {
    const url: string = `./assets/img/markers/device/${StationsMarkers.get(id) || 'imt1.png'}`;
    return url;
  }


}
