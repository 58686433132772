<section class="selected-image-toolbar__sticky-header">
  <app-selected-image-toolbar [modalId]="'iscout-delete-image'"
                              [selectedImage]="selectedPicture"
                              [downloadFilenamePrefix]="'iScout_'"
                              [toolbarSettings$]="toolbarSettings$"
                              [hasWritePermission]="hasWritePermission"
                              [clearMeasurementsEnabled]="true"
                              [showRemovedMeasurementsEnabled]="false"
                              (saveMeasurementsEmitter)="saveMeasurements($event)"
                              (clearMeasurementsEmitter)="clearMeasurements($event)"
                              (deleteImageEmitter)="deleteImage($event)"
                              (toolbarChangeEmitter)="saveToolbarSettings($event)"
                              (nextImageEmitter)="nextPicture($event)"
                              (previousImageEmitter)="previousPicture($event)">
    <div class="selected-image-toolbar__left__item">
      <app-custom-button (click)="unselectPicture()">
        <i class="fa fa-fw fa-picture-o"></i>
        {{'Gallery' | translate}}
      </app-custom-button>
    </div>
  </app-selected-image-toolbar>

  <div class="panel panel-default iscout-image-settings__custom-panel" style="border-radius: 0">
    <div class="panel-body">
      <div class="row">
        <div class="col col-sm-3">
          <div class="dropdown-container" style="height: 34px">
            <app-multilevel-grouped-dropdown
              [groupedSet]="pestGroups"
              [formControl]="selectedRectangleLabel"
              appTooltip
              [tooltipPlacement]="'top'"
              [tooltipTitle]="'Draw a rectangle using this insect' | translate"
            ></app-multilevel-grouped-dropdown>
          </div>
        </div>

        <div class="col col-sm-9" style="text-align: right">
          <div class="btn-group">
            <ng-container *ngIf="pestToggles$ | async as pestToggles; else loadingPestToggles">
              <button *ngFor="let toggle of pestToggles"
                      type="button"
                      class="btn"
                      [class.btn-success]="toggle.is_active"
                      [class.btn-default]="!toggle.is_active"
                      [class.disabled]="toggle.disabled"
                      (click)="togglePest(toggle)">
                <i class="fa"
                   [class.fa-eye]="toggle.is_active"
                   [class.fa-eye-slash]="!toggle.is_active">
                </i>
                {{toggle.name}}
                <span class="badge">{{toggle.amount}}</span>
              </button>
            </ng-container>

            <ng-template #loadingPestToggles>
              <p>
                <i class="fa fa-cog fa-spin"></i>
                {{ 'Loading detected insects...' | translate }}
              </p>
            </ng-template>

            <button *ngIf="isMultipleSelection()"
                    type="button"
                    class="btn iscout-image-settings__button"
                    (click)="unselectMultiple()">
              <i class="fa fa-circle-o"></i>
              {{ 'Unselect all' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
