import { ILegendItem } from '../models';
export const maxLai = 7;
export const minResolution = 10;
export const maxNoPixel = 100;

export const legendItems: ILegendItem[] = [
    { item: '6+', startValue: 6, endValue: null, color:  '#276419' },
    { item: '5 - 6', startValue: 5, endValue: 6, color:  '#4d9221' },
    { item: '4 - 5', startValue: 4, endValue: 5, color:  '#7fbc41' },
    { item: '3 - 4', startValue: 3, endValue: 4, color:  '#b8e186' },
    { item: '3', startValue: 3, endValue: null, color:  '#e6f5d0' },
    { item: '2.5', startValue: 2.5, endValue: null, color:  '#f7f7f7' },
    { item: '2', startValue: 2, endValue: null, color:  '#fde0ef' },
    { item: '1.5', startValue: 1.5, endValue: null, color:  '#f1b6da' },
    { item: '1', startValue: 1, endValue: null, color:  '#de77ae' },
    { item: '0.5', startValue: 0.5, endValue: null, color:  '#c51b7d' },
    { item: '0', startValue: 0, endValue: null, color:  '#8e0152' }
];

export const ndviLegendItems: ILegendItem[] = [
    { item: '0.9 - 1', startValue: 0.9, endValue: 1, color:  '#006837' },
    { item: '0.8 - 0.9', startValue: 0.8, endValue: 0.9, color:  '#1a9850' },
    { item: '0.7 - 0.8', startValue: 0.7, endValue: 0.8, color:  '#66bd63' },
    { item: '0.6 - 0.7', startValue: 0.6, endValue: 0.7, color:  '#a6d96a' },
    { item: '0.5 - 0.6', startValue: 0.5, endValue: 0.6, color:  '#d9ef8b' },
    { item: '0.4 - 0.5', startValue: 0.4, endValue: 0.5, color:  '#ffffbf' },
    { item: '0.3 - 0.4', startValue: 0.3, endValue: 0.4, color:  '#fee08b' },
    { item: '0.2 - 0.3', startValue: 0.2, endValue: 0.3, color:  '#fdae61' },
    { item: '0.1 - 0.2', startValue: 0.1, endValue:  0.2, color:  '#f46d43' },
    { item: '0 - 0.1', startValue: 0, endValue: 0.1, color:  '#d73027' },
    { item: '-0.2 - 0', startValue: -0.2, endValue: 0, color:  '#a50026' },
    { item: '< -0.2', startValue: null, endValue: -0.2, color:  '#d7d7d7' }
];

const plotLines = [{
    'value': Date.parse(new Date().toString()),
        'width': 2,
        'color': '#666',
        'zIndex': 10,
        'dashStyle': 'Dash'
}];

export const defaultChartOptionsSatellite = {
    title: {
        text: null
    },
    chart: {
        marginLeft: 100,
        marginRight: 100,
        marginTop: 20,
        style: { fontFamily: 'Helvetica' },
        zoomType: 'x'
    },
    credits: {
        enabled: false
    },
    exporting: {
        enabled: false
    },
    tooltip: {
        useHTML: true,
        xDateFormat: '%d/%m/%Y',
        shared: true
    },
    legend: {
        useHTML: true,
        labelFormatter: function (): string {
            return this.name;
        }
    },
    xAxis: {
        crosshair: true,
        gridLineWidth: 1,
        labels: { style: { fontSize: '12px' } },
        lineWidth: 2,
        type: 'datetime',
        plotLines: plotLines
    }
};

export const enum SerieNames {
    MAX_BIOMASS = 'Max biomass',
    MEAN_BIOMASS = 'Mean biomass',
    MIN_BIOMASS = 'Min biomass',
    FITTED_BIOMASS = 'Mean biomass (fitted)',
    CLOUD_STATUS = 'Cloud status',
    CLOUD_MARKERS = 'Cloud markers',
    MAX_NDVI = 'Max NDVI',
    MEAN_NDVI = 'Mean NDVI',
    MIN_NDVI = 'Min NDVI',
    FITTED_NDVI = 'Mean NDVI (fitted)'
}
