import { IOptions } from "../../../app/shared/interfaces";

export const languageOptions: Array<IOptions> = [
  {
    value: 'hi',
    content: 'Hindi'
  },
  {
    value: 'mr',
    content: 'Marathi'
  },
  {
    value: 'en',
    content: 'English'
  }
];
