import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { leftSideMenuContentAnimations } from '../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../core/services/left-components-toggler/left-components-toggler.service';
import { ITreeStructure } from '../../services/tree/models';
import { TreeService } from '../../services/tree/tree.service';

@Component({
  selector: 'app-user-api-services',
  templateUrl: './user-api-services.component.html',
  styleUrls: ['./user-api-services.component.scss'],
  animations: leftSideMenuContentAnimations
})
export class UserApiServicesComponent implements OnInit {

  public tree$: BehaviorSubject<ITreeStructure>;
  public state$: Observable<string>;

  constructor(private treeService          : TreeService,
              private leftComponentsToggler: LeftComponentsTogglerService) { }

  public ngOnInit(): void {
    this.tree$ = this.treeService.getUserApiServicesTreeStructure();
    this.state$ = this.leftComponentsToggler.getLeftSideMenuNavigation();
  }

}
