import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { closeAccount } from '../../../../../../core/actions/account';
import { IInfo } from '../../../../../../core/models/account';
import { selectBasicInfo, selectDeletedAccount } from '../../../../../../core/reducers';
import * as fromAccount from '../../../../../../core/reducers/account';

@Component({
  selector: 'app-close-account-form',
  templateUrl: './close-account-form.component.html',
  styleUrls: ['./close-account-form.component.scss']
})
export class CloseAccountFormComponent implements OnInit {

  public deletedAccount$    : Observable<boolean>;
  public info$              : Observable<IInfo>;

  constructor(
    private accountStore    : Store<fromAccount.IAccount>
  ) { }

  public ngOnInit(): void {

    this.deletedAccount$ = this.accountStore.pipe(
      select(selectDeletedAccount)
    );

    this.info$ = this.accountStore.pipe(
      select(selectBasicInfo)
    );
  }

  public onContinue(): void {
    this.accountStore.dispatch(closeAccount());
  }
}
