<div
  class="alert alert-info server-alerts-list__alert"
  style="margin: 0px 15px 15px 15px"
>
  {{
    "Warning thresholds can have a full number or decimal values (.) and are separated with a comma (,). " +
      "Warning thresholds can also contain negative numbers (numbers below 0)."
      | translate
  }}
  <br />
  {{
    "Input fields with errors will be marked with red and will not be saved before they are fixed."
      | translate
  }}
  <br /><br />
  {{ "Reminder" | translate }}: <br />
  <ul>
    <li>
      <b>,</b> - <i>{{ "separator" | translate }}</i>
    </li>
    <li>
      <b>.</b> - <i>{{ "decimal value (10.5, -12.81)" | translate }}</i>
    </li>
  </ul>
</div>
