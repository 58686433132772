<app-tree [tree$]="tree$"></app-tree>
<div class="body-view-content" [@placeContent]="state$ | async">
  <i (click)="openHelpModal()" class="zmdi zmdi-help help-icon"></i>
  <app-cropzone-page-header [pageName]="'Water Balance'">
  </app-cropzone-page-header>
  <app-form-layout *ngIf="cropzonePartial$ | async as cropZone" [shadow]="false">
    <app-irrimet-water-balance-toolbar
      (periodChanged)="onPeriodChanged($event)"
      [minDate]="cropZone?.from"
      [maxDate]="cropZone?.to"
      [cropzoneName]="cropzoneName">
    </app-irrimet-water-balance-toolbar>
    <app-card-header
      [header]=""
      [description]="'' | translate">
    </app-card-header>

    <div class="datasource">
      <div *ngFor="let datasource of datasources$ | async"
           [class.virtual]="datasource.virtual">
        <span> {{ datasource.type | translate }}:</span>
        <span> {{ datasource.name }}</span>
        <span *ngIf="datasource.virtual"> ( {{ 'Virtual Station' | translate}} )</span>
      </div>
    </div>
    <div class="resetZoomButton" *ngIf="zoomed">
      <app-custom-button-reset-zoom
        (click)="onResetZoom()"
        [content]="'Reset zoom' | translate">
      </app-custom-button-reset-zoom>
    </div>
    <div class="waterbalance__charts-container" *ngIf="!(isLoading || isError); else loadinfo">
      <div *ngIf="dataAvailable && waterForCurrentPeriod, else noDataTemplate">
        <ng-container *ngFor="let chart of charts" >
            <div [chart]="chart"
                 (mousemove)=syncCrosshair($event)
                 (mouseout)=hideCrosshair($event)>
            </div>
        </ng-container>
      </div>
    </div>
    <div style="padding: 20px 15px; width: 100%">
      <app-irrimet-water-balance-table *ngIf="apiResponse && this.waterForCurrentPeriod"
                                       [unitSystem]="unitSystem"
                                       [fromTo]="fromTo"
                                       [apiResponse]="apiResponse"
                                       [cropzoneName]="cropzoneName">
      </app-irrimet-water-balance-table>
    </div>
  </app-form-layout>
</div>

<ng-template #loadinfo>
  <app-station-load-info
    [isError]="isError"
    [isLoading]="isLoading">
  </app-station-load-info>
</ng-template>

<ng-template #noDataTemplate>
  <app-station-load-info
    [isError]="true"
    [isLoading]="false"
    [errorText]="'No data for selected cropzone'">
  </app-station-load-info>
</ng-template>

<ng-template #noDataForCurrentPeriod>
  <app-station-load-info
    [isError]="true"
    [isLoading]="false"
    [errorText]="'No data for selected cropzone and cultivation period.'">
  </app-station-load-info>
</ng-template>

<app-info-modal [modalId]="modalId" [header]="'Water Balance'">
  <app-water-balance-help-text-modal></app-water-balance-help-text-modal>
</app-info-modal>
