import {IIscoutState} from '../models/iscout.models';
import {IscoutActionTypes} from '../actions/iscout';
import {iScoutInitialState} from '../constants/constants';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';

export function reducer(state: IIscoutState = iScoutInitialState, action: IActionWithPayload): IIscoutState {
  switch (action.type) {
    case IscoutActionTypes.SET_ISCOUT_PHOTOS:
      return <IIscoutState> {
        ...state,
        pictures: {
          ...state.pictures,
          [action.payload.camId]: action.payload.pictures
        }
      };
    case IscoutActionTypes.SET_ISCOUT_PHOTOS_LOADING:
      return <IIscoutState> {
        ...state,
        isLoading: action.payload
      };
    case IscoutActionTypes.SET_ISCOUT_PHOTOS_ERROR:
      return <IIscoutState> {
        ...state,
        isError: action.payload
      };
    case IscoutActionTypes.SET_ISCOUT_PHOTO_MEASUREMENTS:
      const cameraIndex: number = action.payload.cam_id - 1;
      return {
        ...state,
        pictures: {
          ...state.pictures,
          [cameraIndex]: state.pictures[cameraIndex].map((item) => {
            if (item.filename !== action.payload.filename) {
              return item;
            }
            return { ...item, rectangles: action.payload.rectangles};
          })
        }
      };
    case IscoutActionTypes.EMPTY_ISCOUT_PHOTO_MEASUREMENTS:
      const clearCameraIndex: number = action.payload.cam_id - 1;
      return {
        ...state,
        pictures: {
          ...state.pictures,
          [clearCameraIndex]: state.pictures[clearCameraIndex].map((item) => {
            if (item.filename !== action.payload.filename) {
              return item;
            }
            return { ...item, rectangles: [], filteredRectangles: []};
          })
        }
      };
    case IscoutActionTypes.SET_ISCOUT_USER_PESTS:
      return <IIscoutState> {
        ...state,
        userPests: action.payload
      };
    case IscoutActionTypes.SET_ISCOUT_GENERAL_PESTS:
      return <IIscoutState> {
        ...state,
        generalPests: action.payload
      };
    case IscoutActionTypes.SET_ISCOUT_FIRST_DATE:
      return <IIscoutState> {
        ...state,
        firstDate: action.payload
      };
    case IscoutActionTypes.SET_ISCOUT_LAST_DATE:
      return <IIscoutState> {
        ...state,
        lastDate: action.payload
      };
    default:
      return state;
  }
}
