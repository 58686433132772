<tr class="sensor-head-tr">
  <th [attr.colspan]="nodeHasInactiveSensors() ? (nodeHasCalibration ? 4 : 3) : (nodeHasCalibration ? 6 : 5)">
    <h4 *ngIf="checkBasicNode(); else nobasenode">{{ 'Base station' | translate }}</h4>
    <ng-template #nobasenode>
      <h4>{{ 'Node' | translate }} {{ node.name }}</h4>
    </ng-template>
  </th>
  <th [attr.colspan]="2" *ngIf="nodeHasInactiveSensors()">
    <app-custom-button
      [content]="isInactiveSensorsShown ? ('Hide inactive sensors' | translate) : ('Show inactive sensors' | translate)"
      [color]="isInactiveSensorsShown ? 'orange' : 'green'"
      (click)="toggleShowInactiveSensors()">
    </app-custom-button>
  </th>
  <th>
    <span *ngIf="!checkBasicNode()">{{ 'Custom node name' | translate }}</span>
  </th>
  <th [formGroup]="form">
    <app-form-input *ngIf="!checkBasicNode()"
                    [type]="'border'"
                    [formControl]="nameControl"
                    class="sensor-head-tr__control"
                    [size]="'small'"></app-form-input>
  </th>
  <th>
    <app-custom-button *ngIf="!checkBasicNode()"
                       [content]="'Update'"
                       (click)="saveNodeName()"></app-custom-button>
  </th>
</tr>
<tr class="sensor-head-tr">
  <th class="col-md-1">{{ 'Channel' | translate }}</th>
  <th class="col-md-1">{{ 'Code' | translate }}</th>
  <th class="col-md-2">{{ 'Name' | translate }}</th>
  <th class="col-md-1">{{ 'Active' | translate }}</th>
  <th *ngIf="nodeHasCalibration" class="col-md-2">{{ 'Calibration' | translate }}</th>
  <th class="col-md-2">{{ 'Unit' | translate }}</th>
  <th class="col-md-2"> {{ 'Custom name' | translate }}</th>
  <th class="col-md-2"> {{ 'Color' | translate }}</th>
  <th class="col-md-1"></th>
</tr>
