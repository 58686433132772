import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { FixedTransferOptionValues, LoggingOptionValues, MeasurementOptionValues } from '../constants/constants';
import { ILoggingTransferDevice, parseSchedulerString } from '../models/station-config.models';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {
  constructor() { }

  public solAntennaValidation(group: FormGroup): ValidationErrors | null {
    if (!group.get('measurementInterval') || !group.get('loggingInterval')) {
      return null;
    }
    const measurementIntervalValue = JSON.parse(group.get('measurementInterval').value);
    const loggingTransferIntervalValue = JSON.parse(group.get('loggingInterval').value);
    const measuringLargerThanLoggingTransfer: boolean = measurementIntervalValue > loggingTransferIntervalValue;
    const loggingTransferIsMultiplier: boolean = loggingTransferIntervalValue % measurementIntervalValue === 0;

    let errors: any = null;
    if (measuringLargerThanLoggingTransfer && measurementIntervalValue) {
      errors = {
        ...errors,
        measuringLargerThanLoggingTransfer: true
      };
    }
    if (!loggingTransferIsMultiplier && measurementIntervalValue && loggingTransferIntervalValue) {
      errors = {
        ...errors,
        loggingTransferNotAMultiplier: true
      };
    }
    if ((loggingTransferIntervalValue && !measurementIntervalValue)) {
      errors = {
        ...errors,
        measurementNotFilled: true
      };
    }

    if (!MeasurementOptionValues.includes(measurementIntervalValue) && measurementIntervalValue) {
      errors = {
        ...errors,
        noValidMeasuringSelection: true
      };
    }
    if (!LoggingOptionValues.includes(loggingTransferIntervalValue) && loggingTransferIntervalValue) {
      errors = {
        ...errors,
        noValidLoggingTransferSelection: true
      };
    }

    if (errors) {
      return errors;
    }
    return null;
  }

  public loggingValidation(group: FormGroup): ValidationErrors | null {
    if (!group.get('loggingInterval')) {
      return null;
    }
    let errors: any = null;
    const loggingIntervalValue = JSON.parse(group.get('loggingInterval').value);
    if (!LoggingOptionValues.includes(loggingIntervalValue) && loggingIntervalValue) {
      errors = {
        ...errors,
        noValidLoggingSelection: true
      };
    }
    if (!loggingIntervalValue) {
      errors = {
        ...errors,
        loggingNotSelected: true
      };
    }
    return errors;
  }

  public measuringLoggingValidation(group: FormGroup): ValidationErrors | null {
    if (!group.get('measurementInterval') || !group.get('loggingInterval')) {
      return null;
    }
    const measurementIntervalValue = JSON.parse(group.get('measurementInterval').value);
    const loggingIntervalValue = JSON.parse(group.get('loggingInterval').value);
    const loggingLargerThanMeasurement: boolean = loggingIntervalValue >= measurementIntervalValue;
    const loggingIsMultiplier: boolean = loggingIntervalValue % measurementIntervalValue === 0;

    let errors: any = null;
    if (!loggingLargerThanMeasurement && measurementIntervalValue) {
      errors = {
        ...errors,
        measurementLargerThanLogging: true
      };
    }
    if (!loggingIsMultiplier && measurementIntervalValue && loggingIntervalValue) {
      errors = {
        ...errors,
        loggingIsNotAMultiplier: true
      };
    }

    if (!MeasurementOptionValues.includes(measurementIntervalValue) && measurementIntervalValue) {
      errors = {
        ...errors,
        noValidMeasuringSelection: true
      };
    }
    if (!LoggingOptionValues.includes(loggingIntervalValue) && loggingIntervalValue) {
      errors = {
        ...errors,
        noValidLoggingSelection: true
      };
    }

    if (!measurementIntervalValue && loggingIntervalValue) {
      errors = {
        ...errors,
        measurementNotFilled: true
      };
    }

    if (errors) {
      return errors;
    }
    return null;
  }

  public loggingFixedTransferValidation(group: FormGroup): ValidationErrors | null {
    if (!group.get('loggingInterval') || !group.get('fixedTransferInterval')) {
      return null;
    }
    const loggingIntervalValue = JSON.parse(group.get('loggingInterval').value);
    const transferIntervalValue = JSON.parse(group.get('fixedTransferInterval').value);
    const transferLargerThanLogging: boolean = transferIntervalValue >= loggingIntervalValue;
    const transferIsMultiplier: boolean = transferIntervalValue % loggingIntervalValue === 0;

    let errors: any = null;
    if (!transferLargerThanLogging && loggingIntervalValue) {
      errors = {
        ...errors,
        loggingLargerThanTransfer: true
      };
    }
    if (!transferIsMultiplier && transferIntervalValue && loggingIntervalValue) {
      errors = {
        ...errors,
        transferIsNotAMultiplier: true
      };
    }

    if (!LoggingOptionValues.includes(loggingIntervalValue) && loggingIntervalValue) {
      errors = {
        ...errors,
        noValidLoggingSelection: true
      };
    }
    if (!FixedTransferOptionValues.includes(transferIntervalValue) && transferIntervalValue) {
      errors = {
        ...errors,
        noValidTransferSelection: true
      };
    }

    if (!loggingIntervalValue && transferIntervalValue) {
      errors = {
        ...errors,
        loggingNotFilled: true
      };
    }

    if (errors) {
      return errors;
    }
    return null;
  }

  public intervalFormValidation(group: FormGroup): ValidationErrors | null {
    if (
      !group.get('measurementInterval')
      || !group.get('loggingInterval')
      || !group.get('fixedTransferInterval')
    ) {
      return null;
    }

    const measurementIntervalValue: number = JSON.parse(group.get('measurementInterval').value);
    const loggingIntervalValue: number = JSON.parse(group.get('loggingInterval').value);
    const transferIntervalValue: number = JSON.parse(group.get('fixedTransferInterval').value);

    const loggingLargerThanMeasurement: boolean = loggingIntervalValue >= measurementIntervalValue;
    const loggingIsMultiplier: boolean = loggingIntervalValue % measurementIntervalValue === 0;
    const transferLargerThanLogging: boolean = transferIntervalValue >= loggingIntervalValue;
    const transferIsMultiplier: boolean = transferIntervalValue % loggingIntervalValue === 0;

    let errors: any = null;
    if (!measurementIntervalValue && loggingIntervalValue) {
      errors = {
        ...errors,
        measurementNotFilled: true
      };
    }
    if (!loggingLargerThanMeasurement && measurementIntervalValue) {
      errors = {
        ...errors,
        measurementLargerThanLogging: true
      };
    }
    if (!loggingIsMultiplier && measurementIntervalValue && loggingIntervalValue) {
      errors = {
        ...errors,
        loggingIsNotAMultiplier: true
      };
    }
    if (!transferLargerThanLogging && loggingIntervalValue && transferIntervalValue !== 0) {
      errors = {
        ...errors,
        loggingLargerThanTransfer: true
      };
    }
    if (!transferIsMultiplier && transferIntervalValue && loggingIntervalValue && transferIntervalValue !== 0) {
      errors = {
        ...errors,
        transferIsNotAMultiplier: true
      };
    }

    if (!MeasurementOptionValues.includes(measurementIntervalValue) && measurementIntervalValue) {
      errors = {
        ...errors,
        noValidMeasuringSelection: true
      };
    }
    if (!LoggingOptionValues.includes(loggingIntervalValue) && loggingIntervalValue) {
      errors = {
        ...errors,
        noValidLoggingSelection: true
      };
    }

    if (errors) {
      return errors;
    }

    return null;
  }

  public checkIfFormValid(
    deviceGroup: ILoggingTransferDevice,
    measuringInterval: any,
    loggingInterval: any,
    transferInterval: any,
    stationId: number,
    frostEnabled: boolean): boolean {
    const measurementOptionValues = deviceGroup.options[0] ? deviceGroup.options[0].map((o) => +o.value) : null;
    const loggingOptionValues = deviceGroup.options[1] ? deviceGroup.options[1].map((o) => +o.value) : null;
    const transferOptionValues = deviceGroup.options[2] ? deviceGroup.options[2].map((o) => +o.value) : null;
    let validMeasuring: boolean = deviceGroup.options[0] ? measurementOptionValues.includes(+measuringInterval) : false;
    let validLogging: boolean = deviceGroup.options[1] ? loggingOptionValues.includes(+loggingInterval) : false;
    let validTransfer: boolean = deviceGroup.options[2] ? transferOptionValues.includes(+transferInterval) : false;

    if (frostEnabled) {
      validMeasuring = deviceGroup.frostDefaults ? +deviceGroup.frostDefaults[0] === +measuringInterval : false;
      validLogging = deviceGroup.frostDefaults ? +deviceGroup.frostDefaults[1] === +loggingInterval : false;
      validTransfer = deviceGroup.frostDefaults ? +deviceGroup.frostDefaults[2] === +transferInterval : false;
    }

    // no measuring, logging or transfer
    if (!deviceGroup.measuring && !deviceGroup.logging && !deviceGroup.fixedTransfer) {
      return true;
    }

    // logging only
    if (!deviceGroup.measuring && deviceGroup.logging && !deviceGroup.fixedTransfer) {
      return loggingOptionValues.includes(loggingInterval);
    }

    // logging and transfer
    if (!deviceGroup.measuring && deviceGroup.logging && deviceGroup.fixedTransfer) {
      const transferLargerThanLogging: boolean = transferInterval >= loggingInterval;
      const transferIsMultiplier: boolean = transferInterval % loggingInterval === 0;
      return  validLogging
              && validTransfer
              && (transferLargerThanLogging || +transferInterval === 0)
              && (transferIsMultiplier || +transferInterval === 0);
    }

    // solAntenna
    if (deviceGroup.measuring && deviceGroup.logging && !deviceGroup.fixedTransfer && stationId === 78) {
      const loggingLargerThanMeasurement: boolean = loggingInterval >= measuringInterval;
      const loggingIsMultiplier: boolean = loggingInterval % measuringInterval === 0;
      return  validMeasuring
              && validTransfer
              && +transferInterval > +measuringInterval
              && loggingIsMultiplier;
    }

    // eccu
    if (deviceGroup.measuring && deviceGroup.logging && !deviceGroup.fixedTransfer && stationId !== 78) {
      return validMeasuring
             && validLogging
             && +loggingInterval > +measuringInterval;
    }

    // all interval settings
    if (deviceGroup.measuring && deviceGroup.logging && deviceGroup.fixedTransfer) {
      const loggingLargerThanMeasurement: boolean = loggingInterval >= measuringInterval;
      const loggingIsMultiplier: boolean = loggingInterval % measuringInterval === 0;
      const transferLargerThanLogging: boolean = transferInterval >= loggingInterval;
      const transferIsMultiplier: boolean = transferInterval % loggingInterval === 0;

      return  validMeasuring
              && validLogging
              && validTransfer
              && loggingLargerThanMeasurement
              && loggingIsMultiplier
              && (transferLargerThanLogging || +transferInterval === 0)
              && (transferIsMultiplier || transferInterval === 0);
    }
  }

  public checkIfDefault(deviceGroup: ILoggingTransferDevice, config: any): boolean {
    const hasScheduler = config.scheduler !== null;
    if (hasScheduler) {
      if (
        parseSchedulerString(config) === '001000100010001000100010' &&
        +config.measuring_interval === +deviceGroup.defaults[0] &&
        +config.logging_interval === +deviceGroup.defaults[1] &&
        +config.transfer_interval === +deviceGroup.defaults[2] &&
        +config.fixed_transfer_interval === +deviceGroup.defaults[2]
      ) {
          return true;
      } else if (parseSchedulerString(config) === '000000000000100000000000' &&
        +config.measuring_interval === +deviceGroup.defaults[0] &&
        +config.logging_interval === +deviceGroup.defaults[1] &&
        +config.transfer_interval === +deviceGroup.defaults[2] &&
        +config.fixed_transfer_interval === +deviceGroup.defaults[2] &&
        +config.led === 0) {
          return true;
      } else {
          return false;
      }
    } else {
      if (
        +config.measuring_interval === +deviceGroup.defaults[0] &&
        +config.logging_interval === +deviceGroup.defaults[1] &&
        +config.transfer_interval === +deviceGroup.defaults[2] &&
        +config.fixed_transfer_interval === +deviceGroup.defaults[2]
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  public countOnes(scheduler: any): number {
    let count = 0;
    for (let row = 0; row < scheduler.length; row++) {
      for (let col = 0; col < scheduler[row].length; col++) {
        if (+scheduler[row][col] === 1) {
          count++;
        }
      }
    }
    return count;
  }

  public isFourHoursGap(scheduler: string): boolean {
    let consecutiveZeros = 0;
    let onesCount = 0;
    let firstOneIndex = -1;
    let lastOneIndex = -1;

    for (let i = 0; i < scheduler.length; i++) {
      if (scheduler[i] === '1') {
        onesCount++;
        if (consecutiveZeros < 3 && onesCount > 1) {
          return false;
        }
        if (firstOneIndex === -1) {
          firstOneIndex = i;
        }
        lastOneIndex = i;
        consecutiveZeros = 0;
      } else if (scheduler[i] === '0') {
        consecutiveZeros++;
      }
    }

    return (
      onesCount === 0 ||
      consecutiveZeros >= 3 ||
      (onesCount >= 2 && (scheduler.length - lastOneIndex + firstOneIndex) >= 3)
    );
  }
}
