import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { IXarvioStation } from '../models/xarvio';

export const enum ActionTypes {
  GET_ASSETS = '[Xarvio] GET_ASSETS',
  SET_ASSETS = '[Xarvio] SET_ASSETS',
  GET_SHARING_KEY = '[Xarvio] GET_SHARING_KEY',
  SET_SHARING_KEY = '[Xarvio] SET_SHARING_KEY',
  ENABLE_SYNC = '[Xarvio] ENABLE_SYNC',
  DISABLE_SYNC = '[Xarvio] DISABLE_SYNC',
  SYNC_ASSETS = '[Xarvio] SYNC_ASSETS'
}

export function getAssets(): IAction {
  return { type: ActionTypes.GET_ASSETS, payload: null };
}

export function setAssets(assets: IXarvioStation[]): IAction {
  return { type: ActionTypes.SET_ASSETS, payload: assets };
}

export function getSharingKey(): IAction {
  return { type: ActionTypes.GET_SHARING_KEY, payload: null };
}

export function setSharingKey(sharingKey: string): IAction {
  return { type: ActionTypes.SET_SHARING_KEY, payload: sharingKey };
}

export function enableSync(): IAction {
  return { type: ActionTypes.ENABLE_SYNC, payload: null };
}

export function disableSync(): IAction {
  return { type: ActionTypes.DISABLE_SYNC, payload: null };
}

export function syncAssets(assetsToSync: IXarvioStation[]): IAction {
  return { type: ActionTypes.SYNC_ASSETS, payload: assetsToSync };
}
