import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';


const routes: Routes = [
    { path: '', loadChildren: () => import('../auth/containers/auth-background/auth-background.module').then(m => m.AuthBackgroundModule) }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class AuthRoutingModule { }
