import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CropzoneManagementComponent } from './cropzone-management.component';

const routes: Routes = [
    { path: '', component: CropzoneManagementComponent }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class CropzoneManagementRoutingModule {
}
