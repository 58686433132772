<div [formGroup]="form" class="add-remove-form">
  <div class="add-remove-form__fields">
    <div class="add-remove-form__fields__item">
      <h5>{{ 'Farm name' | translate }}</h5>
      <app-form-input [formControl]="farm" [type]="'border'"></app-form-input>
    </div>
  </div>
  <div class="add-remove-form__buttons">
    <app-custom-button [content]="'Cancel'" (click)="close()"></app-custom-button>
    <app-custom-button [color]="submitColor"
                       [disabled]="form.invalid"
                       (click)="submitEmit()"
                       [content]="submitContent">
    </app-custom-button>
  </div>
</div>
