import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  getWidgetData,
  removeWidget,
  setWidgetError,
  setWidgetLoading,
  updateWidgetEditMode,
  updateWidgetSettings
} from '../../../actions/dashboard';
import { IForecastRequest, ILoadWidgetData, IUpdateWidgetEditMode, IWidget } from '../../../models/dashboard.models';
import { selectEdit } from '../../../reducers';
import * as fromDashboard from '../../../reducers/dashboard';

@Component({
  selector: 'app-forecast-chart-widget',
  templateUrl: './forecast-chart-widget.component.html',
  styleUrls: ['./forecast-chart-widget.component.scss']
})
export class ForecastChartWidgetComponent implements OnInit {

  @Input()
  public widget: IWidget;
  @Input()
  public widgetIndex: number;
  @Input()
  public columnIndex: number;
  @Input()
  public rowIndex: number;

  public isEdit$: Observable<boolean>;
  public loadWidgetData: ILoadWidgetData;
  public settings: Array<number>;

  constructor(
    private dashboardStore: Store<fromDashboard.IDashboard>
  ) {

  }

  public ngOnInit(): void {
    this.settings = [this.rowIndex, this.columnIndex, this.widgetIndex];

    this.isEdit$ = this.dashboardStore.pipe(select(selectEdit));
    if (!this.widget.loadData) {
      this.loadData();
    }
  }

  public purgeWidget(): void {
    this.dashboardStore.dispatch(removeWidget(this.settings));
  }

  public loadData(): void {

    const pathway = '/chart/highchart/' +
    this.widget.requests.forecastChart.endpoint.station + '/'
      + this.widget.requests.forecastChart.endpoint.profile;

    this.loadWidgetData = {
      url: pathway,
      path: this.settings,
      profile: this.widget.requests.forecastChart.endpoint.profile
    };

    this.resetLoading();
    this.dashboardStore.dispatch(getWidgetData(this.loadWidgetData));
  }

  public updateSettings(data: IForecastRequest): void {
    this.dashboardStore.dispatch(updateWidgetSettings({
      type: 'forecastChart',
      updateSettings: data,
      path: this.settings,
    }));
  }

  private resetLoading(): void {

    this.dashboardStore.dispatch(setWidgetLoading({
      path: this.settings,
      loading: true
    }));

    this.dashboardStore.dispatch(setWidgetError({
      path: this.settings,
      error: ''
    }));
  }

  public editMode(editMode: boolean): void {
    const settings: IUpdateWidgetEditMode = {
      path: this.settings,
      type: 'forecastChart',
      isEdit: editMode
    };

    this.dashboardStore.dispatch(updateWidgetEditMode(settings));
  }

}
