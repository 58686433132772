import {IActionWithPayload} from '../../../core/models/actionWithPayload';

export const enum TreeSettingsActionTypes {
  SET_STATION_DATA_TREE_SETTINGS = '[TreeSettings] SET_STATION_DATA_TREE_SETTINGS',
  SET_SOIL_MOISTURE_TREE_SETTINGS = '[TreeSettings] SET_SOIL_MOISTURE_TREE_SETTINGS',
  SET_STATION_DATA_BATCH_TREE_SETTINGS = '[TreeSettings] SET_STATION_DATA_BATCH_TREE_SETTINGS',
  SET_SOIL_MOISTURE_BATCH_TREE_SETTINGS = '[TreeSettings] SET_SOIL_MOISTURE_BATCH_TREE_SETTINGS',
  SET_STATION_DATA_PROFILE = '[TreeSettings] SET_STATION_DATA_PROFILE',
  SET_SOIL_MOISTURE_PROFILE = '[TreeSettings] SET_SOIL_MOISTURE_PROFILE',
  SET_DISEASE_GROUP_AND_PROFILE = '[TreeSettings] SET_DISEASE_GROUP_AND_PROFILE',
  GET_TRACKER_REPORTS = '[Tracker] GET_TRACKER_REPORTS',
  SET_TRACKER_REPORTS = '[Tracker] SET_TRACKER_REPORTS',
  SET_WORK_PLANNING_GROUP_AND_PROFILE = '[TreeSettings] SET_WORK_PLANNING_GROUP_AND_PROFILE',
  SET_ANIMAL_PRODUCTION_PROFILE = '[TreeSettings] SET_ANIMAL_PRODUCTION_PROFILE',
  SET_WEATHER_FORECAST_PROFILE = '[TreeSettings] SET_WEATHER_FORECAST_PROFILE',
}

export interface IStationProfileSettingPayload {
  groupdId: string;
  profile: string;
  stationId: string;
}

export interface IStationProfileBatchSettingPayload {
  profile: string;
  stationId: string;
  groupIds: Array<string>;
  enable: boolean;
}

export interface IProfilePayload {
  profile: string;
  stationId: string;
}

export interface IGroupAndProfilePayload extends IProfilePayload {
  group: string|number;
}

export function setTreeSetting(data: IStationProfileSettingPayload, actionType: string): IActionWithPayload {
  return { type: actionType, payload: data };
}

export function setBatchTreeSetting(data: IStationProfileBatchSettingPayload, actionType: string): IActionWithPayload {
  return { type: actionType, payload: data };
}

export function setProfile(data: IProfilePayload, actionType: string): IActionWithPayload {
    return { type: actionType, payload: data };
}

export function setGroupAndProfile(data: IGroupAndProfilePayload, actionType: string): IActionWithPayload {
  return {type: actionType, payload: data};
}

export function getTrackerReports(request: any): IActionWithPayload {
  return { type: TreeSettingsActionTypes.GET_TRACKER_REPORTS, payload: request };
}

export function setTrackerReports(request: any): IActionWithPayload {
  return { type: TreeSettingsActionTypes.SET_TRACKER_REPORTS, payload: request };
}
