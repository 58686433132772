<section class="widget-container">
  <app-chart-header [color]="widget.color"
                    [title]="widget.title"
                    [description]="widget.description"
                    [isEditable]="true"
                    [isDraggable]="isEdit$ | async"
                    [isClose]="isEdit$ | async"
                    (remove) = "removeCurrentWidget()"
                    (editMode)="editMode(!widget.settings.windrose)"></app-chart-header>

  <div class="settings" *ngIf="widget.settings.windrose">
    <app-wind-rose-diagram-settings [chartRequest]="widget.requests.windrose"
                        (updateWidgetData)="loadChartData($event)"
                        [loading]="widget.isLoading"
                        (updateRequest)="updateRequest($event)"></app-wind-rose-diagram-settings>
  </div>

  <app-form-layout [size]="'dashboard'">
    <!-- <app-chart [chartsSettings]="widget.loadData"
               *ngIf="!widget.error && !widget.isLoading"
               [description]="widget.requests.chart?.profile"></app-chart> -->
    <app-wind-rose-diagram-chart *ngIf="!widget.error && !widget.isLoading" [chartsSettings]="widget">
    </app-wind-rose-diagram-chart>
    <app-widget-load-info *ngIf="widget.error || widget.isLoading"
                          [error]="widget.error"
                          [loading]="widget.isLoading">
    </app-widget-load-info>
  </app-form-layout>
</section>
