import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { removeCropzone } from '../../../actions/cropzones';
import { ICropZone } from '../../../models/cropzones';
import * as fromCropzones from '../../../reducers/cropzones';
import * as fromFarms from '../../../reducers/farms';
import * as fromFields from '../../../reducers/fields';
import { IFarm } from './../../../models/farms';
import { IField } from './../../../models/fields';

@Component({
    selector: 'app-remove-cropzone',
    templateUrl: './remove-cropzone.component.html',
    styleUrls: ['./remove-cropzone.component.scss']
})
export class RemoveCropZoneComponent {
    public isRemoveCropzone: boolean = true;
    public isRemoveField: boolean = false;
    public isRemoveFarm: boolean = false;

    public fieldToRemove: IField;
    public farmToRemove: IFarm;

    public additionalMessageWindowField: boolean = false;
    public additionalMessageFarm: boolean = false;

    constructor(private CZstore: Store<fromCropzones.ICropZones>,
                private FarmStore: Store<fromFarms.IFarms>,
                private FieldStore: Store<fromFields.IFields>) { }

    public removeCropzone(): void {
        this.isRemoveCropzone = true;
        this.isRemoveField = false;
        this.isRemoveFarm = false;

        this.additionalMessageWindowField = false;
        this.additionalMessageFarm = false;
    }

    public removeField(): void {
        this.isRemoveCropzone = false;
        this.isRemoveField = true;
        this.isRemoveFarm = false;

        this.additionalMessageWindowField = false;
        this.additionalMessageFarm = false;
    }

    public removeFarm(): void {
        this.isRemoveCropzone = false;
        this.isRemoveField = false;
        this.isRemoveFarm = true;

        this.additionalMessageWindowField = false;
        this.additionalMessageFarm = false;
    }

    public submitCropzone(event: ICropZone): void {
        this.CZstore.dispatch(removeCropzone(event));
    }

    public submitField(event: IField) : void {
        this.fieldToRemove = event;
        this.additionalMessageWindowField = true;
    }

    public submitFarm(event: IFarm) : void {
        this.farmToRemove = event;
        this.additionalMessageFarm = true;
    }

    public resetModalAfterDelete(event: string): void {
        if (event === 'cropzone') {
            this.isRemoveCropzone = true;
            this.isRemoveField = false;
            this.isRemoveFarm = false;

            this.additionalMessageWindowField = false;
            this.additionalMessageFarm = false;
        } else if (event === 'field') {
            this.isRemoveCropzone = false;
            this.isRemoveField = true;
            this.isRemoveFarm = false;

            this.additionalMessageWindowField = false;
            this.additionalMessageFarm = false;
        } else if ('farm') {
            this.isRemoveCropzone = false;
            this.isRemoveField = false;
            this.isRemoveFarm = true;

            this.additionalMessageWindowField = false;
            this.additionalMessageFarm = false;
        }
    }
}
