import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rootzone-settings-help-text-modal',
  templateUrl: './rootzone-settings-help-text-modal.component.html',
  styleUrls: ['./rootzone-settings-help-text-modal.component.scss']
})
export class RootzoneSettingsHelpTextModalComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
