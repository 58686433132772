import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ADD_CROPZONE, CROPZONE_LIST_REFRESH_TIMER, REMOVE_CROPZONE } from '../../../../shared/constants';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { getCropZones } from '../../../actions/cropzones';
import { ISearchWidgetCropZoneItem } from '../../../components/search-widget/list-cropzone-item/search-widget-list-cropzone-item.component';
import { IOrderSettingItem } from '../../../components/search-widget/models/search-widget.models';
import { ICropZone } from '../../../models/cropzones';
import { selectCropzones, selectSelectedCropZone } from '../../../reducers';
import { ICropZones } from '../../../reducers/cropzones';
import { farmsOrderSettings } from './constants/constants';

@Component({
  selector: 'app-right-cropzones',
  templateUrl: './right-cropzones.component.html',
})

export class RightCropZonesComponent implements OnInit {
  public orderSettings: Array<IOrderSettingItem> = farmsOrderSettings;
  public addCropZoneModalId                   : string = ADD_CROPZONE;
  public removeCropZoneModalId                : string = REMOVE_CROPZONE;
  public cropzones$: Observable<Array<ISearchWidgetCropZoneItem>> = this.store.pipe(
    select(selectCropzones),
    map((cropzones: Array<ICropZone>): Array<ISearchWidgetCropZoneItem> => this.getCropZoneList(cropzones))
  );

  @Input()
  public cropzonesAreOpened: boolean = false;
  @Input()
  public cropzones: ICropZone[];

  @Output()
  public closeBar         : EventEmitter<null> = new EventEmitter<null>();

  public selectCropZone$: Observable<ICropZone>;

  constructor(private store: Store<ICropZones>, private modalService : ModalService) {
    setInterval(() => {
        this.store.dispatch(getCropZones());
    }, CROPZONE_LIST_REFRESH_TIMER);
  }

  public ngOnInit(): void {
   this.subscribeToSelectedCropZone();
  }

  private subscribeToSelectedCropZone(): void {
    this.selectCropZone$ = this.store.pipe(
      select(selectSelectedCropZone),
    );
  }

  public openAddCropZoneModal(): void {
    this.modalService.openModal(this.addCropZoneModalId);
  }

  public openRemoveCropZoneModal(): void {
    this.modalService.openModal(this.removeCropZoneModalId);
  }

  private getCropZoneList(cropzones: Array<ICropZone>): Array<ISearchWidgetCropZoneItem> {
    return cropzones
      ? cropzones.map((cropzone: ICropZone): ISearchWidgetCropZoneItem => {

        return{
          name: cropzone.name,
          crop_name: cropzone.crop_name,
          id: cropzone.id,
          farm: {id: cropzone.farm.id, name: cropzone.farm.name},
          field: {id: cropzone.field.id, name: cropzone.field.name},
          year: new Date(cropzone.from).getFullYear().toString(),
          boundary: cropzone.boundary,
          to: cropzone.to,
          starred: cropzone.starred
        };

      })
      : [];
  }
}
