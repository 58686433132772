import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ApiCallService } from '../../../services/api/api-call.service';
import { Observable, of } from 'rxjs';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import {
  MachinerySettingsActionTypes,
  setMachinery,
  setAddedMachinery,
  getMachineryTypes,
  setMachineryTypes,
  getMachineryBrands,
  getAddedMachinery,
  getAddedTrackers,
  setAddedTrackers,
  setLastMachineryAdded
} from '../actions/machinery-settings';
import { catchError, switchMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { setNotify } from '../../../core/actions/notify';

@Injectable({
  providedIn: 'root'
})
export class MachinerySettingsService {

  constructor(private api: ApiCallService, private actions$: Actions) { }

  @Effect()
  public getMachinery$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(MachinerySettingsActionTypes.GET_MACHINERY),
    switchMap((action: IActionWithPayload) => this.api.getMachinery(action.payload).pipe(
      switchMap((res: any) => from([
        setMachinery(res),
      ])),
      catchError(() => from([]))
    )
    )
  );
  @Effect()
  public getAddedMachinery$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(MachinerySettingsActionTypes.GET_ADDED_MACHINERY),
    switchMap((action: IActionWithPayload) => this.api.getAddedMachinery().pipe(
      switchMap((res: any) => from([
        setAddedMachinery(res),
      ])),
      catchError(() => from([]))
    )
    )
  );

  @Effect()
  public getAddedTrackers$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(MachinerySettingsActionTypes.GET_ADDED_TRACKERS),
    switchMap((action: IActionWithPayload) => this.api.getAddedTrackers().pipe(
      switchMap((res: any) => from([
        setAddedTrackers(res),
      ])),
      catchError(() => from([]))
    )
    )
  );

  @Effect()
  public saveMachinery$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(MachinerySettingsActionTypes.SAVE_MACHINERY),
    switchMap((action: IActionWithPayload) => this.api.saveMachinery(
      action.payload.stationId,
      action.payload.body)
      .pipe(
        switchMap((response: any) => from([
          setLastMachineryAdded(response ? response[0] : response),
          setNotify(response
            ? 'Machinery information was saved successfully'
            : 'No changes have been made yet. Edit the status before updating.')
        ])
        ),
        catchError(() => of(setNotify('Something went wrong - machinery was not saved')))
      ))
  );
}
