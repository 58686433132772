import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { YieldPredictionRoutingModule } from './yield-prediction-routing.module';
import { YieldPredictionComponent } from './yield-prediction.component';
import { YieldPredictionDataComponent } from './containers/yield-prediction-data/yield-prediction-data.component';
import { StoreModule } from '@ngrx/store';
import { reducers } from '../yield-prediction-config/reducers';
import { ypReducers } from './reducers/index';
import { YieldConfigEffect } from '../yield-prediction-config/effects/yield-config.effect';
import { EffectsModule } from '@ngrx/effects';
import { YieldPredictionGraphComponent } from './containers/yield-prediction-graph/yield-prediction-graph.component';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { YieldPredictionHelpTextModalComponent } from './containers/yield-prediction-help-text-modal/yield-prediction-help-text-modal.component';
import { YpWarningMessageComponent } from './containers/yp-warning-message/yp-warning-message.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ChartModule,
    YieldPredictionRoutingModule,
    TranslateModule.forChild(),
    StoreModule.forFeature('yield-config', reducers),
    StoreModule.forFeature('yield-prediction', ypReducers),
    EffectsModule.forFeature([YieldConfigEffect])
  ],
  declarations: [
      YieldPredictionComponent,
      YieldPredictionDataComponent,
      YieldPredictionGraphComponent,
      YieldPredictionHelpTextModalComponent,
      YpWarningMessageComponent,
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [ more, exporting ] }, // add as factory to your providers
  ],
})
export class YieldPredictionModule {
}
