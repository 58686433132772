import {IActionWithPayload} from '../models/actionWithPayload';
import {SelectedStationActionTypes} from '../actions/selectedStation';
import {ISelectedSearchWidgetItem} from '../models/selectedSearchWidgetItem';

export interface ISelectedStationState {
  selectedStation: ISelectedSearchWidgetItem;
}

const initialState: ISelectedStationState = {
  selectedStation: null
};

export function reducer(state: ISelectedStationState = initialState, action: IActionWithPayload ): ISelectedStationState {
  switch (action.type) {
    case SelectedStationActionTypes.SET_SELECTED_STATION: {
      return {
        selectedStation: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
