import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ICameraPest, ICameraPestMap } from '../../../../shared/camera/models/camera';

export interface IIscoutContextMenuPosition {
  coordinateX: number;
  coordinateY: number;
}

interface IPestOptions {
  groupName: string;
  options: ICameraPest[];
}

@Component({
  selector: 'app-iscout-context-menu',
  templateUrl: './iscout-context-menu.component.html',
  styleUrls: ['./iscout-context-menu.component.scss']
})
export class IscoutContextMenuComponent implements OnInit {
  @Input()
  public isOpen: boolean;
  @Input()
  public position: IIscoutContextMenuPosition;
  @Input()
  public pestMap: ICameraPestMap;

  @Output()
  public menuOptionClickedEmitter: EventEmitter<ICameraPest> = new EventEmitter<ICameraPest>();
  @Output()
  public closeMenuEmitter: EventEmitter<void> = new EventEmitter<void>();

  public pestFilter: FormControl;
  public pestFilter$: Observable<string>;
  public pestList$: Observable<IPestOptions[]>;
  private immediatePositionX: number;
  private immediatePositionY: number;

  constructor(private ref: ElementRef) {
    this.pestFilter = new FormControl('');
    this.pestFilter$ = this.pestFilter.valueChanges.pipe(startWith(''));
  }

  public ngOnInit(): void {
    this.pestList$ = this.pestFilter$.pipe(
      map((pestFilter): IPestOptions[] => Object.keys(this.pestMap).map((groupName) => ({
        groupName: this.pestMap[groupName][0].order?.common_name ?
                   this.pestMap[groupName][0].order?.common_name + ' - ' + groupName : groupName,
        options: this.pestMap[groupName].filter(
          (pest) => pest.name.toLowerCase().indexOf(pestFilter.toLowerCase()) !== -1
        ),
      })))
    );
  }

  public handleMenuOptionClick(pest: ICameraPest): void {
    this.menuOptionClickedEmitter.emit(pest);
    this.pestFilter.setValue('');
  }

  @HostListener('document:click', ['$event'])
  public handleClickOut(event: MouseEvent): void {
    const { x, y } = this.ref.nativeElement.getBoundingClientRect();
    this.immediatePositionX = x - this.ref.nativeElement.offsetLeft;
    this.immediatePositionY = y - this.ref.nativeElement.offsetTop;
    if (!this.ref.nativeElement.contains(event.target)) {
      this.closeMenuEmitter.emit();
      this.pestFilter.setValue('');
    }
  }

  public get positionX(): string {
    const correctPosition = (this.position.coordinateX || 0) - this.immediatePositionX;
    return `${correctPosition}px`;
  }

  public get positionY(): string {
    const correctPosition = (this.position.coordinateY || 0) - this.immediatePositionY;
    return `${correctPosition}px`;
  }
}
