import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { PeriodValuesByScope } from '../../../../shared/constants/sample-data.constant';
import { StationDataExportService } from '../../../../shared/services/export/station-data-export.service';
import { setActiveMeter, setActiveSample, setActiveTitle, setDevice } from '../../actions/station-sample.action';
import { IStationSampleState } from '../../models/station-sample.model';
import { selectDevice } from '../../selectors/station-sample.selector';

@Component({
  selector: 'app-sample-toolbar',
  templateUrl: './sample-toolbar.component.html',
  styleUrls: ['./sample-toolbar.component.scss']
})
export class SampleToolbarComponent implements OnInit, OnDestroy {

  @Output()
  public activateTable = new EventEmitter<boolean>();

  @Input()
  public dates;

  @Input()
  public interestValues;

  public device;
  public periodForm: FormGroup;
  public periodValues = PeriodValuesByScope.hourly;
  public isLastMode = true;
  public isTableActive = true;
  public titleValues = [
    { content: 'Show title for all samples', value: true },
    { content: 'Show title for chosen sample', value: false },
  ];

  private alive$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private exportService: StationDataExportService,
    private stationSampleStore: Store<IStationSampleState>,
  ) {
  }

  public ngOnInit(): void {

    this.createForm();

    // on Caroline request, it resets the "arrow date" on period select
    this.periodValue.valueChanges.subscribe(period => {
      this.fromTo.patchValue({
        from: moment(this.dates.to).subtract(period, 'day').toDate(),
        to: this.dates.to
      });
    });

    this.stationSampleStore.pipe(
      select(selectDevice),
      takeUntil(this.alive$),
      filter(_ => Boolean(_)),
      distinctUntilChanged((a, b) => a.name === b.name),
    ).subscribe(device => {

      if (device && device.period) {

        this.createForm();
        this.device = device;

        this.periodValue.setValue('30d');

        this.fromTo.patchValue({
          from: device.period.from,
          to: device.period.to
        });

        this.range.setValue({
          from: device.period.from,
          to: device.period.to
        });
      }
    });
  }

  public createForm(): void {
    this.periodForm = this.formBuilder.group({
      'periodValue': ['30d', [Validators.required]],
      'fromTo': new FormGroup({
        'from': new FormControl(),
        'to': new FormControl()
      }),
      'range': [false, [Validators.required]],
      'isExportActive': [false, [Validators.required]],
      'activeMeter': [this.interestValues[0].value, [Validators.required]],
      'activeTitle': [true, [Validators.required]],
    });
  }

  public get fromTo(): AbstractControl {
    return this.periodForm.get('fromTo');
  }

  public get range(): AbstractControl {
    return this.periodForm.get('range');
  }

  public get periodValue(): AbstractControl {
    return this.periodForm.get('periodValue');
  }

  public setPeriodScope(event): void {
    this.periodValues = PeriodValuesByScope[event.value]; // setvalue?
  }

  public setLastMode(data: boolean): void {
    // todo: datepicker on purpose isn't synced with the arrow navigation
    this.isLastMode = data;
  }

  public submitData(): any {

    let period = { ...this.range.value };
    const activeMeter = this.periodForm.get('activeMeter').value;
    const activeTitle = this.periodForm.get('activeTitle').value;

    if (!this.isLastMode) {
      period = { ...this.fromTo.value };
    }

    // todo put it in one action (for 3 values)
    this.stationSampleStore.dispatch(setDevice({ ...this.device, period }));
    this.stationSampleStore.dispatch(setActiveMeter(activeMeter));
    this.stationSampleStore.dispatch(setActiveTitle(activeTitle));
  }

  public dropActiveSample(): any {
    this.stationSampleStore.dispatch(setActiveSample(null));
  }

  public toggleTable(): void {
    this.isTableActive = !this.isTableActive;
    this.activateTable.emit(this.isTableActive);
  }

  public exportXLS(): void {
    this.exportService.startExportXLS(this.device.name);
  }

  public ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }
}
