import {LocalStorageConfig, rehydrateApplicationState, syncStateUpdate} from 'ngrx-store-localstorage';

const INIT_ACTION = '@ngrx/store/init';
const UPDATE_ACTION = '@ngrx/store/update-reducers';

const validateStateKeys = (keys: any[]) => {
  return keys.map(key => {
    let attr = key;

    if (typeof key === 'object') {
      attr = Object.keys(key)[0];
    }

    if (typeof attr !== 'string') {
      throw new TypeError(
        `localStorageSync Unknown Parameter Type: ` +
        `Expected type of string, got ${typeof attr}`
      );
    }
    return key;
  });
};

export const localStorageSync = (config: LocalStorageConfig) => (
  reducer: any
) => {
  if (config.storage === undefined) {
    config.storage = localStorage || window.localStorage;
  }

  if (config.storageKeySerializer === undefined) {
    config.storageKeySerializer = key => key;
  }

  if (config.restoreDates === undefined) {
    config.restoreDates = true;
  }

  const stateKeys = validateStateKeys(config.keys);
  const rehydratedState = config.rehydrate
    ? rehydrateApplicationState(
      stateKeys,
      config.storage,
      config.storageKeySerializer,
      config.restoreDates
    )
    : undefined;

  return function(state = rehydratedState, action: any): any {
    /*
         Handle case where state is rehydrated AND initial state is supplied.
         Any additional state supplied will override rehydrated state for the given key.
         */
    if (
      (action.type === INIT_ACTION || action.type === UPDATE_ACTION) &&
      rehydratedState
    ) {
      setRehydratedState(state, rehydratedState);
    }
    const nextState = reducer(state, action);
    syncStateUpdate(
      nextState,
      stateKeys,
      config.storage,
      config.storageKeySerializer,
      config.removeOnUndefined
    );
    return nextState;
  };
};

function setRehydratedState(state: object, rehydratedState: object): void {
  state = state ? state : {};
  for (const key in rehydratedState) {
    if (rehydratedState.hasOwnProperty(key)) {
      if (state[key] === undefined) {
        state[key] = {};
      }
      if (typeof rehydratedState[key] === 'object') {
        setRehydratedState(state[key], rehydratedState[key]);
      }
      if (['number', 'string', 'boolean'].indexOf(typeof rehydratedState[key]) !== -1) {
        state[key] = rehydratedState[key];
      }
    }
  }
}

export function addToLocalStorageObject(name: string, key: string, value) : void {
  let existing = localStorage.getItem(name);
  // If no existing data, create an array Otherwise, convert the localStorage string to an array
  existing = existing ? JSON.parse(existing) : {};
  existing[key] = value;  // Add new data to localStorage Array
  localStorage.setItem(name, JSON.stringify(existing)); 	// Save back to localStorage
}
