<div class="sensor-settings-column" *ngIf="sensors.length" [formGroup]="form">
  <p class="sensor-setting-column__name">{{ sensors[0].name | translate }} </p>
  <p class="sensor-setting-column__name" *ngIf="soilProfileActive">{{ 'Load soil profile' | translate }}</p>
  <div *ngIf="soilProfileActive" class="marginBot15">
    <app-form-select [type]="'border'" [options]="soilProfileOptions" formControlName="soilProfile" [size]="'small'">
    </app-form-select>
  </div>
  <div class="alert alert-info" *ngIf="!sfAllowed && form.errors">
    <div *ngIf="form.errors.frequencyNotAllowed === true">
      {{ 'You selected Scaled Frequency. To proceed select a calibration.' | translate }}
    </div>
  </div>
  <table>
    <thead>
      <tr>
        <td>{{ 'Code / Channel' | translate }}</td>
        <td>{{ 'Refill Point' | translate }}</td>
        <td>{{ 'Field Capacity' | translate }}</td>
        <td>{{ 'Unit' | translate }}</td>
        <td>{{ 'Depth in Soil' | translate }}</td>
        <td *ngIf="sensors[0].calibrations">{{ 'Calibration' | translate }}</td>
      </tr>
    </thead>
    <tbody>
      <tr [formGroupName]="sensor.channel" *ngFor="let sensor of sensors">
        <td>{{ sensor.code }} / {{ sensor.channel }}</td>
        <td>
          <app-form-input minValue="0" [contentType]="'number'" [formControlName]="'refill_point'">
          </app-form-input>
        </td>
        <td>
          <app-form-input minValue="0" [contentType]="'number'" [formControlName]="'field_capacity'">
          </app-form-input>
        </td>
        <td>
          <app-form-select [width]="'width-auto'" [options]="sensor.units" [formControlName]="'unit'">
          </app-form-select>
        </td>
        <td>
          <app-form-input minValue="1" [suffix]="depth_unit" [contentType]="'number'" [formControlName]="'depth'">
          </app-form-input>
        </td>
        <td *ngIf="sensors[0].calibrations">
          <app-form-select [width]="'width-auto'" [options]="sensor.calibrations" [formControlName]="'calibration_id'">
          </app-form-select>
        </td>
      </tr>
    </tbody>
  </table>
</div>
