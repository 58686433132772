<ng-container>
  <div class="frost-protection-content__head">
    <app-frost-protection-header (openModal)="openModal()"></app-frost-protection-header>
    <app-frost-protection-toolbar (exportChartImg)="exportCharts($event)"></app-frost-protection-toolbar>
  </div>

  <ng-container class="frost-protection-content__item"
                *ngIf="!(isLoading$ | async) && !(isError$ | async) && !errorMessage;
                else loadinfo">

    <div class="frost-protection-content__item">
      <app-frost-protection-charts [exportChartImg]="exportChartImg"></app-frost-protection-charts>
    </div>

    <div class="frost-protection-content__item">
      <app-frost-protection-table
        [forecastRecordsLength]="forecastRecordsLength"
        [measurementRecordsLength]="measurementRecordsLength">
      </app-frost-protection-table>
    </div>
  </ng-container>

  <ng-template #loadinfo>
    <app-station-load-info [isError]="isError$ | async"
                           [isLoading]="isLoading$ | async"
                           [errorText]="errorMessage">
    </app-station-load-info>
  </ng-template>
</ng-container>

<app-info-modal [modalId]="FROST_HELP_MODAL_ID" [header]="'Extreme Temperature Monitoring' | translate">
  <app-frost-protection-help-modal></app-frost-protection-help-modal>
</app-info-modal>

