import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IOptions } from '../../../../shared/interfaces';
import { saveStationDiseaseFireBlight } from '../../actions/station-disease-settings';
import { FireBlightSettings, FIRE_BLIGHT } from '../../constants/constants';
import { IFireBlight } from '../../models/station-disease.models';
import { selectStationDiseaseFireBlight, selectStationDiseaseSettingsExportActive, selectStationDiseaseStationId } from '../../reducers';
import * as fromDiseaseSettings from '../../reducers/station-disease-settings';
import { StationDiseaseHelpService } from '../../services/station-disease-help.service';

@Component({
  selector: 'app-station-disease-fireblight',
  templateUrl: './station-disease-fireblight.component.html',
  styleUrls: ['./station-disease-fireblight.component.scss']
})

export class StationDiseaseFireblightComponent implements OnInit, OnDestroy {
  @Output()
  private close             : EventEmitter<void> = new EventEmitter<void>();

  public form               : FormGroup;
  public isExportActive     : boolean;
  public fireBlight         : IFireBlight;
  public fireBlightSettings : IOptions[] = FireBlightSettings;

  private destroy$          : Subject<boolean> = new Subject<boolean>();
  private stationId         : string = '';

  public get model(): AbstractControl {
    return this.form.get('model');
  }

  public get rights(): boolean {
    return this.fireBlight.rights === 'r';
  }

  constructor(private fb: FormBuilder,
              private helpService: StationDiseaseHelpService,
              private diseaseSettingsStore: Store<fromDiseaseSettings.IStationDiseaseSettingsState>) { }

  public ngOnInit(): void {
    this.form = this.fb.group({
      'model': ['', []]
    });

    combineLatest([
      this.diseaseSettingsStore.pipe(select(selectStationDiseaseFireBlight)),
      this.diseaseSettingsStore.pipe(select(selectStationDiseaseStationId)),
      this.diseaseSettingsStore.pipe(select(selectStationDiseaseSettingsExportActive)),
      this.helpService.getOpenFireblight()
    ])
    .pipe( takeUntil(this.destroy$))
    .subscribe( ([f, id, isExportActive]) => {
      this.model.setValue(`${f.value}`);
      this.fireBlight = f;
      this.stationId = id;
      this.isExportActive = isExportActive;
    });
  }

  public closeModal(): void {
    this.close.emit();
  }

  public saveFireBlight(): void {
    this.diseaseSettingsStore.dispatch(saveStationDiseaseFireBlight({
      stationId: this.stationId,
      request: {
        name: FIRE_BLIGHT,
        setting: 'option',
        value: this.model.value
      }
    }));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
