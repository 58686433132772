import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-play-speed-input',
  templateUrl: './play-speed-input.component.html',
  styleUrls: ['./play-speed-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PlaySpeedInputComponent),
    multi: true
  }]
})
export class PlaySpeedInputComponent implements ControlValueAccessor {
  @Input()
  public className = '';
  public _value: number = 0;

  private propagateChange: any = () => { return; };

  public get value(): number {
    return this._value;
  }

  public set value(value: number) {
    this._value = value;
  }

  public onChange(event: any): void {
    this.propagateChange(event.target.value);
  }

  public writeValue(value: any): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    return;
  }

  public setDisabledState(isDisabled: boolean): void {
    return;
  }

  public forwardClassName(): string {
    return `fa fa-forward ${this.className}`;
  }
}
