<section class="widget-container">
  <app-chart-header [color]="widget.color"
                    [title]="widget.title"
                    [description]="widget.description"
                    [isEditable]="true"
                    [isDraggable]="isEdit$ | async"
                    [isClose]="isEdit$ | async"
                    (remove)="purgeWidget()"
                    (editMode)="editMode(!widget.settings.forecastChart)">
  </app-chart-header>

  <div class="settings"*ngIf="widget.settings.forecastChart">
    <app-forecast-chart-settings [chartRequest]="widget.requests.forecastChart"
                                 (updateWidgetData)="loadData()"
                                 (updateRequest)="updateSettings($event)">
    </app-forecast-chart-settings>
  </div>

  <app-form-layout [size]="'dashboard'">
    <app-chart [chartsSettings]="widget.loadData"
               *ngIf="!widget.error && !widget.isLoading"
               [description]="widget.requests.forecastChart?.endpoint.profile">
    </app-chart>
    <app-widget-load-info *ngIf="widget.error || widget.isLoading"
                          [error]="widget.error"
                          [loading]="widget.isLoading">
    </app-widget-load-info>
  </app-form-layout>
</section>
