import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthBackgroundComponent } from './auth-background.component';
import { DeeplinkComponent } from '../deeplink/deeplink.component';

const authRoutes: Routes = [
  { path: 'login', loadChildren: () => import('../+login/login.module').then(m => m.LoginModule) },
  { path: 'registration', loadChildren: () => import('../+register/register.module').then(m => m.RegisterModule) },
  { path: 'reset-password', loadChildren: () => import('../+reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
  { path: 'password-update', loadChildren: () => import('../+update-password/update-password.module').then(m => m.UpdatePasswordModule) },
  { path: '**', redirectTo: 'login' }
];

const routes: Routes = [
  {
    path: 'deeplink/:deepLinkToken',
    component: DeeplinkComponent,
    pathMatch: 'full'
  },
  { path: '', component: AuthBackgroundComponent, children: authRoutes },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AuthBackgroundRoutingModule { }
