import { IFromTo } from '../../station-data/models/station-data.models';

export interface IHeaderData {
  title: string;
  subtitle: string;
  help: string;
  period?: {
    title: string;
    from: Date;
    to: Date;
  };
}

export interface IWorkPlanningRequest {
  stationId: string;
  service: string;
  crop?: string;
}

export interface IWorkPlanningState {
  chartsOptions: any[];
  grid: {};
  isLoading: boolean;
  isError: boolean;
  licenses: IWorkPlanningLicenses;
  warning: IWarningMessage;
}

export interface IWarningMessage {
  message: string|null;
  icon: boolean;
}

export interface IWorkPlanningLicenses {
  workPlanningLicenses: Array<IFromTo>;
  tropicalSowingLicenses: Array<IFromTo>;
  grassFireDangerLicenses: Array<IFromTo>;
}

export interface IWorkPlanningSettingsActivity {
  isChartActive: boolean;
  isTableActive: boolean;
  imageExportInProgress: boolean;
}

export interface IWorkPlanningStationModeSettings {
  disabledGroupIds: {
    [groupId: string]: boolean
  };
}
export interface IWorkPlanningStationSettings {
  selectedMode: string;
  modes: {
    [mode: string]: IWorkPlanningStationModeSettings;
  };
}

export interface IWorkPlanningSettings {
  [stationId: string]: IWorkPlanningStationSettings;
}

export interface IWorkPlanningSettingsState {
  settings: IWorkPlanningSettings;
  activity: IWorkPlanningSettingsActivity;
}

export interface IWorkPlanningSettingsPayload {
  groupdId: string;
  mode: string;
  stationId: string;
}

export const InitialWorkPlanningStationSetting: IWorkPlanningStationSettings = {
  modes: {},
  selectedMode: 'plant-protection'
};

export interface IWorkPlanningSettingsModePayload {
  mode: string;
  stationId: string;
}
