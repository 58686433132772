import { Component } from '@angular/core';

@Component({
  selector: 'app-chart-toolbar-layout',
  templateUrl: './chart-toolbar-layout.component.html',
  styleUrls: ['./chart-toolbar-layout.component.scss']
})
export class ChartToolbarLayoutComponent {

}
