<div class="emergency-modal">
  <p>
    {{ 'Please insert your phone number to which we can send emergency SMS in case of problems with the statio or if the ' +
    'battery voltage falls below 6.1V. Phone number must be in the correct format together with your country code.' |
    translate }}
  </p>
  <p>
    {{ 'When you finish the configuration, do not forget to save the new settings (click on “SAVE SMS NUMBER” button).'
    | translate }}
  </p>
</div>
