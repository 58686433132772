import { ILai } from '../models';
import { Polygon } from 'geojson';
import { area as turfArea} from '@turf/turf';
import { minResolution, maxNoPixel } from '../consts';

export function getMinMaxLai(lais: ILai[]): number[] {
    return lais.reduce((minmax, lai) => {
        minmax[0] = minmax[0] > lai.stat.lai.min ? lai.stat.lai.min : minmax[0];
        minmax[1] = minmax[1] > lai.stat.lai.max ? lai.stat.lai.max : minmax[1];
        return minmax;
    }, [Infinity, -Infinity]);
}

export function getResolution(polygon: Polygon): number {
    const area = turfArea(polygon);
    // round to 5 pixel steps -> 10, 15 ...
    const res = Math.round(Math.sqrt(area / maxNoPixel) / 5) * 5;
    return res < minResolution ? minResolution : res;
}
