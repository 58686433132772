import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { CropzoneSampleRoutingModule } from './cropzone-sample-routing.module';
import { SampleDataLayoutComponent } from './components/sample-data-layout/sample-data-layout.component';
import { SharedModule } from '../../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { featureKey, reducers } from './reducers';
import { CropzoneSampleEffects } from './effects/cropzone-sample.effects';
import { AgGridModule } from 'ag-grid-angular';
import { HIGHCHARTS_MODULES } from 'angular-highcharts';
import { StationDataExportService } from '../../shared/services/export/station-data-export.service';
import { SampleDataToolbarComponent } from './components/sample-data-toolbar/sample-data-toolbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { SampleDataTreeComponent } from './components/sample-data-tree/sample-data-tree.component';
import { SampleDataService } from './services/sample-data.service';
import { SampleSoilguardComponent } from './containers/sample-soilguard/sample-soilguard.component';
import { SampleNutritionComponent } from './containers/sample-nutrition/sample-nutrition.component';
import { SampleDataMapComponent } from './components/sample-data-map/sample-data-map.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../../assets/themes/default/configuration';
import { GoogleMapLibraries } from '../../shared/constants';
import { SampleModalComponent } from './components/sample-modal/sample-modal.component';
import { SampleDataTableComponent } from './components/sample-data-table/sample-data-table.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CropzoneSampleRoutingModule,
    StoreModule.forFeature(featureKey, reducers),
    TranslateModule.forChild(),
    EffectsModule.forFeature([CropzoneSampleEffects]),
    AgGridModule.withComponents([]),
    AgmCoreModule.forRoot({
      apiKey: environment.gMapKey,
      libraries: GoogleMapLibraries
    })
  ],
  declarations: [
    SampleNutritionComponent,
    SampleSoilguardComponent,
    SampleDataLayoutComponent,
    SampleDataToolbarComponent,
    SampleDataTreeComponent,
    SampleDataMapComponent,
    SampleDataTableComponent,
    SampleModalComponent
  ],
  providers: [
    SampleDataService,
    StationDataExportService,
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting] }
  ]
})
export class CropzoneSampleModule {
}
