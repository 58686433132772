import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as deepEqual from 'deep-equal';
import * as moment from 'moment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { setCropZones } from '../../core/actions/cropzones';
import * as fromCropzones from '../../core/reducers/cropzones';
import * as fromSelectedCropzone from '../../core/reducers/selectedCropZone';
import { stationDataContentAnimations } from '../../core/services/left-components-toggler/animation.constants';
import { LeftComponentsTogglerService } from '../../core/services/left-components-toggler/left-components-toggler.service';
import { ITreeStructure } from '../../services/tree/models';
import { TreeService } from '../../services/tree/tree.service';
import { ICropZone, IDataSources } from './../../core/models/cropzones';
import { IAccount } from './../../core/reducers/account';
import { selectAvailableLicenses, selectCropzones, selectSelectedCropZone, selectSettings } from './../../core/reducers/index';
import { updateCropzone, updateCropzoneAndSensors } from './actions/soilMoistureStations';
import * as fromSensors from './reducers/sensors';
import * as fromSMStations from './reducers/soilMoistureStations';

@Component({
    selector: 'app-soil-moisture-config',
    templateUrl: './soil-moisture-config.component.html',
    styleUrls: ['./soil-moisture-config.component.scss'],
    animations: stationDataContentAnimations
})

export class SoilMoistureConfigComponent implements OnInit, OnDestroy {
    public state$: Observable<string>;
    public tree$: Observable<ITreeStructure> = this.treeService.getCropzoneSettingsTreeStructure();
    private destroy$: Subject<boolean> = new Subject<boolean>();

    public soilMoistureDatasources: Array<IDataSources> = [];
    public cropzone: ICropZone;
    public fromDate: Date;
    public toDate: Date;
    private cropzones: Array<ICropZone> = [];
    public unitSystem: string;
    private previousCropzone: ICropZone = {
        name: '',
        crop_name: '',
        from: '',
        to: '',
        field: {
            name: '',
            id: ''
        },
        farm: {
            name: '',
            id: ''
        },
        id: '',
        year: '',
        boundary: null,
        crop: null,
        soil: null,
        rain_efficacies: [],
        data_sources: [],
        irrigation_events: []
    };

    constructor(
        private treeService: TreeService,
        private leftComponentsToggler: LeftComponentsTogglerService,
        private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
        private sensorsStore: Store<fromSensors.ISensorsState>,
        private smStationStore: Store<fromSMStations.ISoilMoistureStationsState>,
        private cropzonesStore: Store<fromCropzones.ICropZones>,
        private accountStore: Store<IAccount>
    ) {
    }

    public ngOnInit(): void {
        this.state$ = this.leftComponentsToggler.getStationDataContentState();

        const availableLicenses$ = this.accountStore.pipe(
            select(selectAvailableLicenses),
            filter(licenses => !!licenses),
            takeUntil(this.destroy$)
        );

        const selectedCropzone$ = this.selectedCropzoneStore.pipe(
            filter((cropzone) => !!cropzone),
            select(selectSelectedCropZone),
            takeUntil(this.destroy$),
            tap((cropzone) => {
                if (
                    cropzone.id !== this.previousCropzone.id ||
                    !deepEqual(cropzone.data_sources, this.previousCropzone.data_sources)
                ) {
                    /* this.sensorsStore.dispatch(resetSensors()); */
                    this.soilMoistureDatasources = cropzone.data_sources.filter(dataSource => dataSource.module === 'SOIL_MOISTURE');
                    this.cropzone = cropzone;
                    this.fromDate = new Date(cropzone.from);
                    this.toDate = new Date(cropzone.to);

                    this.previousCropzone = cropzone;
                }
            })
        );

        combineLatest([
            availableLicenses$,
            selectedCropzone$
        ]).pipe(takeUntil(this.destroy$))
        .subscribe(([licenses, cropzone]) => {
            let licenseFromCropzone;

            if (cropzone.product_key) {
                const cropzoneProductKey = cropzone.product_key;
                const now = new Date();

                licenses.forEach((license) => {
                if (license.product_item.key === cropzoneProductKey) {
                    if (now > moment(license.start_date).toDate() && now < moment(license.end_date).toDate()) {
                        licenseFromCropzone = license;
                    }
                }
                });
            }

            if (licenseFromCropzone) {
                this.treeService.getCropzoneSettingsTreeStructure(licenseFromCropzone);
            } else {
                this.treeService.getCropzoneSettingsTreeStructure();
            }
        });

        this.accountStore.pipe(
            select(selectSettings),
            filter((settings) => !!settings)
        ).subscribe(settings => this.unitSystem = settings.unit_system);

        this.cropzonesStore.pipe(
            select(selectCropzones),
            takeUntil(this.destroy$)
        ).subscribe(cropzones => this.cropzones = cropzones);
    }

    public addNewDatasource(): void {
        this.soilMoistureDatasources.push(undefined);
    }

    public removeDataSource(event: number): void {
        const smDatasource = this.soilMoistureDatasources[event];
        this.soilMoistureDatasources.splice(event, 1);

        this.cropzone.data_sources.forEach((datasource, indexx) => {
            if (deepEqual(datasource, smDatasource)) {
                this.cropzone.data_sources.splice(indexx, 1);
            }
        });

        if (smDatasource !== undefined) {
            this.smStationStore.dispatch(updateCropzone(this.cropzone));
        }

        const cropzones = JSON.parse(JSON.stringify(this.cropzones));

        let index: number;
        cropzones.forEach((cropzone, indexx) => {
            if (cropzone.id === this.cropzone.id) {
                index = indexx;
            }
        });

        cropzones[index] = this.cropzone;
        this.cropzonesStore.dispatch(setCropZones(cropzones));
    }

    public save(event): void {
        const cropzones = JSON.parse(JSON.stringify(this.cropzones));

        let index: number;
        cropzones.forEach((cropzone, indexx) => {
            if (cropzone.id === event.cropzone.id) {
                index = indexx;
            }
        });

        cropzones[index] = event.cropzone;

        if (this.unitSystem === 'imperial') {
            event.cropzone.data_sources.forEach(datasource => {
                if (datasource.module === 'SOIL_MOISTURE' ) {
                    datasource.source.layers.forEach((layer, indexx) => {
                        datasource.source.layers[indexx] = {
                            ...datasource.source.layers[indexx],
                            depth: Math.round(datasource.source.layers[indexx].depth * 2.54)
                        };
                    });
                }
            });
        }

        this.cropzonesStore.dispatch(setCropZones(cropzones));
        this.smStationStore.dispatch(updateCropzoneAndSensors(event));
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }
}
