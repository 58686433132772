import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {IStationFromToCommunication, IStationSettingsPeriod} from '../../../shared/interfaces';
import {
  IDiseaseModel,
  ISaveDiseaseModelPayload,
  IStationDiseaseSettingsActivity,
} from '../models/station-disease.models';
import {IGetStationDataFcRequest} from '../../station-data/models/station-data.models';

export const enum StationDiseaseSettingsActionTypes {
  SET_STATION_DISEASE_SETTINGS_PERIOD = '[StationDisease] SET_STATION_DISEASE_SETTINGS_PERIOD',
  SET_STATION_DISEASE_SETTINGS_FROM_TO = '[StationDisease] SET_STATION_DISEASE_SETTINGS_FROM_TO',
  SET_STATION_DISEASE_SETTINGS_ACTIVITY = '[StationDisease] SET_STATION_DISEASE_SETTINGS_ACTIVITY',
  SET_STATION_DISEASE_SETTINGS_EXPORT_ACTIVE = '[StationDisease] SET_STATION_DISEASE_SETTINGS_EXPORT_ACTIVE',
  SET_STATION_DISEASE_SETTINGS_PERIOD_SCOPE = '[StationDisease] SET_STATION_DISEASE_SETTINGS_PERIOD_SCOPE',
  SET_STATION_DISEASE_SETTINGS_PERIOD_VALUE = '[StationDisease] SET_STATION_DISEASE_SETTINGS_PERIOD_VALUE',
  SET_STATION_DISEASE_SETTINGS_EXPORT_IMAGE = '[StationDisease] SET_STATION_DISEASE_SETTINGS_EXPORT_IMAGE',
  GET_STATION_DISEASE_IMAGE = '[StationDisease] GET_STATION_DISEASE_IMAGE',
  GET_STATION_DISEASE_FIRE_BLIGHT = '[StationDisease] GET_STATION_DISEASE_FIRE_BLIGHT',
  SAVE_STATION_DISEASE_FIRE_BLIGHT = '[StationDisease] SAVE_STATION_DISEASE_FIRE_BLIGHT',
  SET_STATION_DISEASE_RIGHTS = '[StationDisease] SET_STATION_DISEASE_RIGHTS',
  SET_STATION_DISEASE_FIRE_BLIGHT = '[StationDisease] SET_STATION_DISEASE_FIRE_BLIGHT',
  SET_STATION_DISEASE_FIRE_BLIGHT_ACTIVE = '[StationDisease] SET_STATION_DISEASE_FIRE_BLIGHT_ACTIVE',
  SET_STATION_DISEASE_FIRE_BLIGHT_SUCCESS = '[StationDisease] SET_STATION_DISEASE_FIRE_BLIGHT_SUCCESS',
  OFF_STATION_DISEASE_FIRE_BLIGHT_SUCCESS = '[StationDisease] OFF_STATION_DISEASE_FIRE_BLIGHT_SUCCESS',
}

export function setStationDiseaseSettingsPeriodScope(scope: any): IActionWithPayload {
  return { type: StationDiseaseSettingsActionTypes.SET_STATION_DISEASE_SETTINGS_PERIOD_SCOPE, payload: scope };
}

export function setStationDiseaseFireBlightActive(isActive: boolean): IActionWithPayload {
  return { type: StationDiseaseSettingsActionTypes.SET_STATION_DISEASE_FIRE_BLIGHT_ACTIVE, payload: isActive };
}

export function offStationDiseaseFireBlightSuccess(): IActionWithPayload {
  return { type: StationDiseaseSettingsActionTypes.OFF_STATION_DISEASE_FIRE_BLIGHT_SUCCESS, payload: null };
}

export function setStationDiseaseFireBlightSuccess(isSuccess: boolean): IActionWithPayload {
  return { type: StationDiseaseSettingsActionTypes.SET_STATION_DISEASE_FIRE_BLIGHT_SUCCESS, payload: isSuccess };
}

export function setStationDiseaseFireBlight(value: string): IActionWithPayload {
  return { type: StationDiseaseSettingsActionTypes.SET_STATION_DISEASE_FIRE_BLIGHT, payload: value };
}

export function setStationDiseaseRights(rights: string): IActionWithPayload {
  return { type: StationDiseaseSettingsActionTypes.SET_STATION_DISEASE_RIGHTS, payload: rights };
}

export function saveStationDiseaseFireBlight(save: ISaveDiseaseModelPayload): IActionWithPayload {
  return { type: StationDiseaseSettingsActionTypes.SAVE_STATION_DISEASE_FIRE_BLIGHT, payload: save };
}

export function getStationDiseaseFireBlight(stationId: string): IActionWithPayload {
  return { type: StationDiseaseSettingsActionTypes.GET_STATION_DISEASE_FIRE_BLIGHT, payload: stationId };
}

export function getStationDiseaseImage(exportImage: IGetStationDataFcRequest): IActionWithPayload {
  return { type: StationDiseaseSettingsActionTypes.GET_STATION_DISEASE_IMAGE, payload: exportImage };
}

export function setStationDiseaseSettingsExportImage(isExportImage: boolean): IActionWithPayload {
  return { type: StationDiseaseSettingsActionTypes.SET_STATION_DISEASE_SETTINGS_EXPORT_IMAGE, payload: isExportImage };
}

export function setStationDiseaseSettingsExportActive(isExportActive: boolean): IActionWithPayload {
  return { type: StationDiseaseSettingsActionTypes.SET_STATION_DISEASE_SETTINGS_EXPORT_ACTIVE, payload: isExportActive };
}

export function setStationDiseaseSettingsPeriod(period: IStationSettingsPeriod): IActionWithPayload {
  return { type: StationDiseaseSettingsActionTypes.SET_STATION_DISEASE_SETTINGS_PERIOD, payload: period };
}

export function setStationDiseaseSettingsFromTo(fromTo: IStationFromToCommunication): IActionWithPayload {
  return { type: StationDiseaseSettingsActionTypes.SET_STATION_DISEASE_SETTINGS_FROM_TO, payload: fromTo };
}

export function setStationDiseaseSettingsActivity(activity: IStationDiseaseSettingsActivity): IActionWithPayload {
  return { type: StationDiseaseSettingsActionTypes.SET_STATION_DISEASE_SETTINGS_ACTIVITY, payload: activity };
}
