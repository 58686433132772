import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ultrasonic-distance-settings-help',
  templateUrl: './ultrasonic-distance-settings-help.component.html',
  styleUrls: ['./ultrasonic-distance-settings-help.component.css']
})
export class UltrasonicDistanceSettingsHelpComponent implements OnInit {

  @Input()
  public flags;

  constructor() { }

  public ngOnInit(): void {
  }
}
