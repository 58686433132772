import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { IAsset, IJDAsset, IJDOrganizations, IJDUser, IState, IStatus } from '../models/my-john-deere';
import { ISensor } from '../../../shared/interfaces';
import { Action } from '@ngrx/store';

export const enum ActionTypes {
  SET_STATUS = '[MyJohnDeere] SET_STATUS',
  SEND_AUTH_CODE = '[MyJohnDeere] SEND_AUTH_CODE',
  GET_AUTH_DATA = '[MyJohnDeere] GET_AUTH_DATA',
  SET_AUTH_DATA = '[MyJohnDeere] SET_AUTH_DATA',
  DROP_USER = '[MyJohnDeere] DROP_USER',
  DELETE_TOKEN = '[MyJohnDeere] DELETE_TOKEN',
  SET_STATE = '[MyJohnDeere] SET_STATE',
  GET_CATALOG = '[MyJohnDeere] GET_CATALOG',
  SET_CATALOG = '[MyJohnDeere] SET_CATALOG',
  GET_CURRENT_USER = '[MyJohnDeere] GET_CURRENT_USER',
  SET_CURRENT_USER = '[MyJohnDeere] SET_CURRENT_USER',
  GET_CURRENT_USER_ORGANIZATIONS_PAGE = '[MyJohnDeere] GET_CURRENT_USER_ORGANIZATIONS_PAGE',
  SET_CURRENT_USER_ORGANIZATIONS_PAGE = '[MyJohnDeere] SET_CURRENT_USER_ORGANIZATIONS_PAGE',
  GET_CURRENT_USER_ORGANIZATIONS = '[MyJohnDeere] GET_CURRENT_USER_ORGANIZATIONS',
  SET_CURRENT_USER_ORGANIZATIONS = '[MyJohnDeere] SET_CURRENT_USER_ORGANIZATIONS',
  GET_ASSETS = '[MyJohnDeere] GET_ASSETS',
  SET_ASSETS = '[MyJohnDeere] SET_ASSETS',
  POST_ASSET = '[MyJohnDeere] POST_ASSET',
  SET_ASSET = '[MyJohnDeere] SET_ASSET',
  DELETE_ASSET = '[MyJohnDeere] DELETE_ASSET',
  UPDATE_ASSET_SENSORS = '[MyJohnDeere] UPDATE_ASSET_SENSORS',
  REMOVE_ASSET = '[MyJohnDeere] REMOVE_ASSET',
  UPDATE_ASSET = '[MyJohnDeere] UPDATE_ASSET',
  GET_SENSORS = '[MyJohnDeere] GET_SENSORS',
  SET_SENSORS = '[MyJohnDeere] SET_SENSORS'
}

export function setStatus(status: IStatus): IAction {
  return { type: ActionTypes.SET_STATUS, payload: status };
}

export function getAuthData(): IAction {
  return { type: ActionTypes.GET_AUTH_DATA, payload: undefined };
}

export function setAuthData(authData): IAction {
  return { type: ActionTypes.SET_AUTH_DATA, payload: authData };
}

export function deleteToken(): IAction {
  return { type: ActionTypes.DELETE_TOKEN, payload: undefined };
}

export function setState(payload: IState): IAction {
  return { type: ActionTypes.SET_STATE, payload: payload };
}

export function getCatalog(): IAction {
  return { type: ActionTypes.GET_CATALOG, payload: undefined };
}

export function setCatalog(catalog: any): IAction {
  return { type: ActionTypes.SET_CATALOG, payload: catalog };
}

export function getCurrentUser(): IAction {
  return { type: ActionTypes.GET_CURRENT_USER, payload: undefined };
}

export function setCurrentUser(currentUser: IJDUser): IAction {
  return { type: ActionTypes.SET_CURRENT_USER, payload: currentUser };
}

export function getCurrentUserOrganizationsPage(pageNo: number): IAction {
  return { type: ActionTypes.GET_CURRENT_USER_ORGANIZATIONS_PAGE, payload: pageNo };
}

export function setCurrentUserOrganizationsPage(currentUserOrganizations: IJDOrganizations, pageNo: number): IAction {
  return {
    type: ActionTypes.SET_CURRENT_USER_ORGANIZATIONS_PAGE,
    payload: {
      no: pageNo,
      data: currentUserOrganizations,
    }
  };
}

export function getCurrentUserOrganizations(path: string): IAction {
  return { type: ActionTypes.GET_CURRENT_USER_ORGANIZATIONS, payload: path };
}

export function setCurrentUserOrganizations(currentUserOrganizations: IJDOrganizations): IAction {
  return {
    type: ActionTypes.SET_CURRENT_USER_ORGANIZATIONS,
    payload: currentUserOrganizations
  };
}

export function getAssets(organizationId: string): IAction {
  return { type: ActionTypes.GET_ASSETS, payload: organizationId };
}

export function setAssets(assets: IAsset[]): IAction {
  return { type: ActionTypes.SET_ASSETS, payload: assets };
}

export function postAsset(asset: { asset: IJDAsset, config: IAsset }): IAction {
  return { type: ActionTypes.POST_ASSET, payload: asset };
}

export function setAsset(asset: IAsset): IAction {
  return { type: ActionTypes.SET_ASSET, payload: asset };
}

export function deleteAsset(asset: IAsset): IAction {
  return { type: ActionTypes.DELETE_ASSET, payload: asset };
}

export function dropUser(): Action {
  return { type: ActionTypes.DROP_USER };
}

export function removeAsset(asset: IAsset): IAction {
  return { type: ActionTypes.REMOVE_ASSET, payload: asset };
}

export function updateAsset(asset: IAsset): IAction {
  return { type: ActionTypes.UPDATE_ASSET, payload: asset };
}

export function updateAssetSensors(asset: IAsset, assetConfig): IAction {
  return { type: ActionTypes.UPDATE_ASSET_SENSORS, payload: {asset: asset, assetConfig: assetConfig}};
}

export function getSensors(stationId: string): IAction {
  return { type: ActionTypes.GET_SENSORS, payload: stationId };
}

export function setSensors(stationId: string, sensors: ISensor[]): IAction {
  return { type: ActionTypes.SET_SENSORS, payload: { sensors, stationId } };
}

export function sendAuthCode(data: string): IAction {
  return { type: ActionTypes.SEND_AUTH_CODE, payload: data };
}
