import * as fromWorkPlanning from './work-planning';
import * as fromWorkPlanningSettings from './work-planning-settings';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IWorkPlanningSettingsState, IWorkPlanningState } from '../models/work-planning.models';
import { ITreeSettingsState } from '../../../shared/tree/models/tree.models';
import { workPlanningTreeReducer } from '../../../shared/tree/reducers/tree-settings';

interface IState {
  workPlanning: IWorkPlanningState;
  treeSettings: ITreeSettingsState;
  workPlanningSettings: IWorkPlanningSettingsState;
}

export const reducers = {
  workPlanning: fromWorkPlanning.reducer,
  workPlanningSettings: fromWorkPlanningSettings.reducer,
  treeSettings: workPlanningTreeReducer
};

export const workPlanning = createFeatureSelector<IState>('workPlanning');

export const selectWorkPlanningCharts = createSelector(
  workPlanning,
  (state: IState): Array<any> => state.workPlanning.chartsOptions
);

export const selectWorkPlanningIsLoading = createSelector(
  workPlanning,
  (state: IState): boolean => state.workPlanning.isLoading
);

export const selectWorkPlanningIsError = createSelector(
  workPlanning,
  (state: IState): boolean => state.workPlanning.isError
);

export const selectWorkPlanningGrid = createSelector(
  workPlanning,
  (state: IState): {} => state.workPlanning.grid
);

export const selectWorkPlanningTreeSettings = createSelector(
  workPlanning,
  (state: IState) => state.treeSettings
);

export const selectLicenses = createSelector(
  workPlanning,
  (state: IState) => state.workPlanning.licenses
);

export const selectWorkPlanningLicenses = createSelector(
  workPlanning,
  (state: IState) => state.workPlanning.licenses.workPlanningLicenses
);

export const selectWarningMessage = createSelector(
  workPlanning,
  (state: IState) => state.workPlanning.warning
);


export const selectWeatherForecastImageExportInProgress = createSelector(
  workPlanning,
  (state: IState): boolean => state.workPlanningSettings.activity.imageExportInProgress
);

export const selectWorkPlanningSettings = createSelector(
  workPlanning,
  (state: IState): IWorkPlanningSettingsState => state.workPlanningSettings
);

export const selectWorkPlanningSettingsIsChartActive = createSelector(
  workPlanning,
  (state: IState): boolean => state.workPlanningSettings.activity.isChartActive
);

export const selectWorkPlanningSettingsIsTableActive = createSelector(
  workPlanning,
  (state: IState): boolean => state.workPlanningSettings.activity.isTableActive
);
