import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { IState, IFbSettings } from '../models/farmbeats';
import { Action } from '@ngrx/store';

export const enum ActionTypes {
  SET_STATE = '[FarmBeats] SET_STATE',
  GET_SETTINGS = '[FarmBeats] GET_SETTINGS',
  SET_SETTINGS = '[FarmBeats] SET_SETTINGS',
  SAVE_SETTINGS = '[FarmBeats] SAVE_SETTINGS',
  RESET_SETTINGS = '[FarmBeats] RESET_SETTINGS',
  GET_ASSETS = '[FarmBeats] GET_ASSETS',
  SET_ASSETS = '[FarmBeats] SET_ASSETS',
  ADD_ASSET = '[FarmBeats] ADD_ASSET',
  DELETE_ASSET = '[FarmBeats] DELETE_ASSET'
}

export function setState(payload: IState): IAction {
  return { type: ActionTypes.SET_STATE, payload: payload };
}

export function setFarmBeatsSettings(payload: IFbSettings): IAction {
  return { type: ActionTypes.SET_SETTINGS, payload: payload };
}

export function getSettings(): IAction {
  return { type: ActionTypes.GET_SETTINGS, payload: undefined };
}

export function saveFarmBeatsSettings(payload: IFbSettings): IAction {
  return { type: ActionTypes.SAVE_SETTINGS, payload: payload };
}

export function resetSettings(): Action {
  return { type: ActionTypes.RESET_SETTINGS };
}

export function getAssets(): IAction {
  return { type: ActionTypes.GET_ASSETS, payload: undefined };
}

export function setAssets(payload: any): IAction {
  return { type: ActionTypes.SET_ASSETS, payload: payload };
}

export function addAsset(station): IAction {
  return { type: ActionTypes.ADD_ASSET, payload: station };
}

export function deleteAsset(station): IAction {
  return { type: ActionTypes.DELETE_ASSET, payload: station };
}
