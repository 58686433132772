import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { setNotify } from '../../../../core/actions/notify';
import { ICropZone } from '../../../../core/models/cropzones';
import { IStation } from '../../../../core/models/stations';
import {
  selectNavigationStation,
  selectSelectedCropZone, selectUserData
} from '../../../../core/reducers';
import { IAccount } from '../../../../core/reducers/account';
import { INavigationStationState } from '../../../../core/reducers/navigation-station';
import * as fromNotify from '../../../../core/reducers/notify';
import * as fromSelectedCropzone from '../../../../core/reducers/selectedCropZone';
import { IStations } from '../../../../core/reducers/stations';
import { ApiCallService } from '../../../../services/api/api-call.service';
import {
  CROP_ZONE_YIELD_PREDICTION_TREE_NODES
} from '../../../../services/tree/constants';
import { ModalService } from '../../../../shared/modal/services/modal.service';
import { dateToUtcUnixTimestamp } from '../../../../shared/utils/dateFormat';
import { generateId } from '../../../dashboard/utils/makeWidget';
import { cropTypeOptions } from '../../../yield-prediction-config/constants/config';
import { yieldUnitTypes } from '../../constants/config';
import { IPhysiologicalMaturityRequest, IYieldPredictionModelRequest } from '../../models/model';
import { selectAvailableLicenses } from './../../../../core/reducers/index';
import * as fromLicenses from './../../../../core/reducers/licenses';
import { YieldPredictionWarningsService } from './../../services/yield-prediction-warnings.service';

@Component({
  selector: 'app-yield-prediction-data',
  templateUrl: './yield-prediction-data.component.html',
  styleUrls: ['./yield-prediction-data.component.scss']
})
export class YieldPredictionDataComponent implements OnInit, OnDestroy {

  private alive$: Subject<boolean> = new Subject<boolean>();
  public unitSystem: string;
  public stationArray: any[] = [];
  public stationForecastList: any[] = [];
  public stationId: string;
  public predictedNormalYield: string;
  public predictedYield: string;
  public cropType: any;
  public currentYield: string;
  public cropzone: ICropZone;
  public yieldSource: any;
  public chartData: any;
  public form: FormGroup;
  public physiologicalMaturityDate: any = null;
  public currentDate = new Date();
  public modalId: string = generateId();
  public showYieldHelpText: string = 'true';
  public xDays: number;
  public lastLicenseDate: string;
  public activeLicense: any;

  public get cropzoneControl(): AbstractControl {
    return this.form.get('cropzone');
  }

  constructor(
    private fb: FormBuilder,
    private selectedCropzoneStore: Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private stationStore: Store<IStations>,
    private navigationStationStore: Store<INavigationStationState>,
    private userStore: Store<IAccount>,
    private licenseStore: Store<fromLicenses.IProductLicenseState>,
    private apiCallService: ApiCallService,
    private notifyStore: Store<fromNotify.INotifyState>,
    private modalService: ModalService,
    private warningService: YieldPredictionWarningsService,
    private router: Router
  ) { }

  public ngOnInit() : void {
    this.form = this.fb.group({
      'cropzone': [''],
    });
    this.userStore.pipe(
      select(selectUserData),
      takeUntil(this.alive$),
      filter((user: IAccount) => !!user)
    ).subscribe((user: IAccount) => {
      if (user.settings !== null) {
        this.unitSystem = user.settings.unit_system;
      }
    });

    this.selectedCropzoneStore.pipe(
      select(selectSelectedCropZone),
      takeUntil(this.alive$)
    ).subscribe((cropzone) => {
      this.cropzone = cropzone;
      this.form.setValue({'cropzone' : cropzone});
      this.cropzone.data_sources.forEach(datasource => {
        if (datasource.module === 'YIELD_PREDICTION') {
          this.yieldSource = datasource;
          const unit = this.yieldSource.yield_prediction.unit;
          if (yieldUnitTypes(unit) !== null) {
            this.unitSystem = yieldUnitTypes(unit);
          }
          this.cropType = cropTypeOptions.filter(item => item.value === this.yieldSource?.yield_prediction?.crop_type);
        }});
      });

    combineLatest([
      this.selectedCropzoneStore.pipe(
        select(selectSelectedCropZone),
        takeUntil(this.alive$)
      ),
      this.licenseStore.pipe(
        select(selectAvailableLicenses),
        filter((licenses) => !!licenses),
        takeUntil(this.alive$)
      )
    ]).subscribe(([cropzone, licenses]: [ICropZone, any[]]) => {
      this.activeLicense = licenses.filter((license) => license.product_item.key === cropzone.product_key)[0];
    });

    this.form.controls['cropzone'].valueChanges.subscribe(changes => {
      if (changes.id !== this.form.value['cropzone'].id) {
        let counter = 0;
        changes.data_sources.forEach(datasource => {
          if (datasource.module === 'YIELD_PREDICTION') {
            this.yieldSource = datasource;
            counter = ++counter;
            if (this.yieldSource && this.yieldSource.device_name) {
              this.stationId = this.yieldSource.device_name;
              this.stationForecastList = this.yieldSource.source.device_id;
              this.getPhysiologicalMaturity();
            }
          }
        });
        if (counter === 0) {
            this.yieldSource = null;
            this.chartData = null;
          }
      }
    });

    this.navigationStationStore.pipe(
      select(selectNavigationStation),
      takeUntil(this.alive$)
    ).subscribe((station: IStation) => {
      if (this.yieldSource && this.yieldSource.device_name && station) {
        this.stationId = this.yieldSource.device_name;
        this.stationForecastList = this.yieldSource.source.device_id;
        if (this.router?.url?.includes(CROP_ZONE_YIELD_PREDICTION_TREE_NODES[0].link)) {
          this.getPhysiologicalMaturity();
        }
      }
    });

    const showYieldDescription = localStorage.getItem('showYieldDescription');
    if (showYieldDescription !== null) {
      this.showYieldHelpText = showYieldDescription;
    }
  }

  public getPhysiologicalMaturity() : void {
    if (!this.yieldSource || !this.yieldSource.yield_prediction) {
      return;
    }
    if (!this.stationForecastList?.includes(this.stationId)) {
      this.notifyStore.dispatch(
        setNotify('Yield Prediction requires an active Weather Forecast license on at least one of the stations selected in the Yield ' +
                'Prediction settings, but no active Weather Forecast license was found. Please review your Yield Prediction settings.'));

      return;
    }
    const request : IPhysiologicalMaturityRequest = {
      type: 'crop_physiological_maturity',
      sowing_date: dateToUtcUnixTimestamp(this.yieldSource.yield_prediction.sowing_date),
      harvest_date: dateToUtcUnixTimestamp(this.yieldSource.yield_prediction.harvest_date_user_estimate),
      today: dateToUtcUnixTimestamp(this.currentDate),
      crop_species: this.yieldSource.yield_prediction.crop_type,
      crop_id: this.cropzone.id
    };

    this.chartData = null;
    this.apiCallService.calculatePhysiologicalMaturity(this.stationId, request).subscribe(response => {
      this.physiologicalMaturityDate = response.physiological_maturity_prediction;
      if ((moment(this.physiologicalMaturityDate) >
            moment(this.yieldSource.yield_prediction.harvest_date_user_estimate).add(30, 'days')) ||
          (moment(this.physiologicalMaturityDate) <
            moment(this.yieldSource.yield_prediction.harvest_date_user_estimate).subtract(30, 'days'))) {
        this.notifyStore.dispatch(
          setNotify('Attention: The predicted crop physiological maturity date deviates largely from your expected harvest date. Make sure your ' +
          'settings are correct, and check the yield prediction results.'));
      }
      if (response.notifications?.length > 0) {
        response.notifications[0].map(message => {
          /* Hide warning until data gap of 1 day is fixed */
          // if (message === 'Warning: Missing station data detected for the selected temperature and/or precipitation sensor! ' +
          //   'To calculate Yield Prediction, the data gaps were automatically filled with data from historic averages ' +
          //   '(your station data was not changed.)') {
          //   this.notifyStore.dispatch(
          //     setNotify('Warning: Missing station data detected for the selected temperature and/or precipitation sensor! ' +
          //     'To calculate Yield Prediction, the data gaps were automatically filled with data from historic averages ' +
          //     '(your station data was not changed.)')
          //   );
          // } else
          if (message === 'unable to calculate predicted physiological maturity. using harvestDate instead') {
            this.notifyStore.dispatch(
              setNotify('Warning: Unable to calculate predicted physiological maturity date. Expected harvest date was used instead.'));
          }
        });
      }
      this.getYieldPredctionModel();
    });
  }

  public getYieldPredctionModel() : void {
    const predictionRequest : IYieldPredictionModelRequest = {
      sowing_date: dateToUtcUnixTimestamp(this.yieldSource.yield_prediction.sowing_date),
      physiological_maturity_prediction: dateToUtcUnixTimestamp(this.physiologicalMaturityDate),
      today: dateToUtcUnixTimestamp(this.currentDate),
      crop: this.yieldSource.yield_prediction.crop_type,
      crop_id: this.cropzone.id,
      paw_ini: this.yieldSource.yield_prediction.paw_ini,
      yield_max_user_estimate: this.yieldSource.yield_prediction.yield_max_user_estimate
    };
    this.apiCallService.getYieldPredictionModel(this.stationId, predictionRequest).subscribe(response => {
      this.chartData = response;
      let currentYieldIndex = 0;
      let tillToday = 0;
      this.chartData.date?.map((item, index) => {
        const date = new Date(item * 1000);
        if (moment(date) <= moment(this.currentDate)) {
          tillToday = index;
        }
        if (date.toDateString() === this.currentDate.toDateString()) {
          currentYieldIndex = index;
        }
      });
      const arrayLength = response.data.yield_avg[0].length;

      const normalYieldMin = Math.round(Number(response.data.yield_min[0][arrayLength - 1]) * 10) / 10;
      const normalYieldMax = Math.round(Number(response.data.yield_max[0][arrayLength - 1]) * 10) / 10;
      const predictedYieldMin = Math.round(Number(response.data.yield_min[1][arrayLength - 1]) * 10) / 10;
      const predictedYieldMax = Math.round(Number(response.data.yield_max[1][arrayLength - 1]) * 10) / 10;
      const currentYieldMin =  Math.round(Number(response.data.yield_min[1][currentYieldIndex]) * 10) / 10;
      const currentYieldMax = Math.round(Number(response.data.yield_max[1][currentYieldIndex]) * 10) / 10;

      const predictedYieldAvg = Number((Number(response.data.yield_min[1][arrayLength - 1]) +
                                       (Number(response.data.yield_max[1][arrayLength - 1]))) / 2);

      this.predictedNormalYield =  normalYieldMin + ' to ' + normalYieldMax +
                                   ' (avg. ' + Math.round(Number((normalYieldMin + normalYieldMax) / 2) * 10) / 10 + ')';
      this.predictedYield =  predictedYieldMin + ' to ' + predictedYieldMax + ' (avg. ' +
                             Math.round(Number((predictedYieldMin + predictedYieldMax) / 2) * 10) / 10 + ')';
      this.currentYield = currentYieldMin + ' to ' + currentYieldMax +
                          ' (avg. ' + Math.round(Number((currentYieldMin + currentYieldMax) / 2) * 10) / 10 + ')';

      if (this.yieldSource?.yield_prediction?.yield_max_user_estimate === predictedYieldAvg) {
        this.notifyStore.dispatch(
          setNotify('Attention: The average predicted yield was limited by your setting of best possible average yields. ' +
          'Please review your settings.'));
      }
      if (tillToday >= arrayLength - 1) {
        this.currentYield = this.predictedYield;
      }
    },
    error => {
      this.chartData = error;
    });
  }

  public openModal(): void {
    this.modalService.openModal(this.modalId);
  }

  public closeDescription(): void {
    this.showYieldHelpText = 'false';
    localStorage.setItem('showYieldDescription', 'false');
  }

  public ngOnDestroy(): void {
    this.alive$.next(true);
    this.alive$.complete();
  }

}
