import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ICropZone } from '../../../../../core/models/cropzones';
import { IOptions } from '../../../../../shared/interfaces';
import { dateToUtcUnixTimestamp } from '../../../../../shared/utils/dateFormat';
import { dateTypeFromPeriod } from '../../../../../shared/utils/dateTypeFromPeriod';
import { getSumsSmData, setSumsSmDisplayActiveRootZone, setSumsSmFromTo, setSumsSmScopeAndPeriod } from '../../../actions';
import { DEFAULT_PERIOD, DEFAULT_SCOPE, PERIODS_BY_SCOPE } from '../../../constants';
import { ISumsSmState } from '../../../models';
import { sumsSmSelector } from '../../../selectors';

@Component({
  selector: 'app-crop-zone-soil-moisture-sums-sm-toolbar',
  templateUrl: './crop-zone-soil-moisture-sums-sm-toolbar.component.html',
  styleUrls: ['./crop-zone-soil-moisture-sums-sm-toolbar.component.scss']
})
export class CropZoneSoilMoistureSumsSmToolbarComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  public cropZone: ICropZone;

  public minDate: Date;
  public maxDate: Date;
  public form: FormGroup;
  public periods: IOptions[] = PERIODS_BY_SCOPE[DEFAULT_SCOPE];
  public scopes: IOptions[];
  public isLoading: boolean = false;

  public destroyed$: Subject<boolean> = new Subject();
  public state: any = null;

  constructor(
    private fb: FormBuilder,
    private store: Store<ISumsSmState>
  ) {}

  public ngOnInit(): void {}

  public refresh(): void {
    let toValue: Date = this.to.value;
    let fromValue: Date = this.from.value;
    if (this.isLastDataMode.value) {
      toValue = moment(this.to.value).isBefore(moment()) ? moment(this.to.value).toDate() : moment().toDate();
      fromValue = moment(toValue).clone().subtract(
        parseInt(this.period.value, 10),
        dateTypeFromPeriod(this.period.value) as any
      ).toDate();
    }

    this.store.dispatch(setSumsSmFromTo(fromValue, toValue));
    const from = dateToUtcUnixTimestamp(fromValue);
    const to = dateToUtcUnixTimestamp(toValue);
    const group = this.scope.value;

    this.store.dispatch(getSumsSmData(this.cropZone.id, { from, to, group }));
    this.store.dispatch(setSumsSmScopeAndPeriod(this.scope.value, this.period.value));
  }

  public ngOnChanges(changes): void {
    if (changes.cropZone && this.cropZone) {
      this.store.pipe(
        takeUntil(this.destroyed$),
        select(sumsSmSelector)
      ).subscribe(state => {
        this.state = state;
        this.isLoading = state.isLoading;
      });

      this.scopes = Object.keys(PERIODS_BY_SCOPE).map((scope) => ({
        content: scope,
        value: scope
      }));
      this.periods = PERIODS_BY_SCOPE[this.state.scope ? this.state.scope : DEFAULT_SCOPE];
      this.form = this.fb.group({
        'period': [this.state.period ? this.state.period : DEFAULT_PERIOD],
        'scope': [this.state.scope ? this.state.scope : DEFAULT_SCOPE],
        'from': [moment(changes.cropZone.from).toDate()],
        'to': [moment(changes.cropZone.to).toDate()],
        'isLastDataMode': [this.state.isLastDataMode ? this.state.isLastDataMode : true],
        'displayActiveRootZone': [this.state.displayActiveRootZone ? this.state.displayActiveRootZone : false],
        'range': [{
          from: moment(changes.cropZone.from).toDate(),
          to: moment(changes.cropZone.to).toDate()
        }]
      });

      this.isLastDataMode.setValue(true);
      this.minDate = moment(this.cropZone.from).toDate();
      this.maxDate = moment(this.cropZone.to).toDate();
      const toValue = moment(this.maxDate).isBefore(moment()) ? moment(this.maxDate).toDate() : moment().toDate();
      this.to.setValue(toValue);
      this.from.setValue(moment(toValue).clone().subtract(
        parseInt(this.period.value, 10),
        dateTypeFromPeriod(this.period.value) as any
      ).toDate());

      this.scope.valueChanges.subscribe(scope => {
        this.periods = PERIODS_BY_SCOPE[scope];
        this.period.setValue(this.periods[0].value);
      });

      this.range.valueChanges.subscribe(range => {
        this.from.setValue(new Date(range.from.getTime()));
        this.to.setValue(new Date(range.to.getTime()));
      });

      this.displayActiveRootZone.valueChanges.pipe(
        distinctUntilChanged()
      ).subscribe(displayActiveRootZone => {
        this.store.dispatch(setSumsSmDisplayActiveRootZone(displayActiveRootZone));
      });

      combineLatest([
        this.scope.valueChanges,
        this.period.valueChanges
      ]).pipe(
        distinctUntilChanged(([a, b], [c, d]) => a === c && b === d),
      ).subscribe(([scope, period]) => {
        this.store.dispatch(setSumsSmScopeAndPeriod(scope, period));
      });

      this.isLastDataMode.valueChanges.subscribe(() => {
        this.from.setValue(new Date(this.range.value.from.getTime()));
        this.to.setValue(new Date(this.range.value.to.getTime()));
      });

      this.refresh();
    }
  }

  public get period(): AbstractControl {
    return this.form.get('period');
  }

  public get scope(): AbstractControl {
    return this.form.get('scope');
  }

  public get from(): AbstractControl {
    return this.form.get('from');
  }

  public get to(): AbstractControl {
    return this.form.get('to');
  }

  public get isLastDataMode(): AbstractControl {
    return this.form.get('isLastDataMode');
  }

  public get range(): AbstractControl {
    return this.form.get('range');
  }

  public get displayActiveRootZone(): AbstractControl {
    return this.form.get('displayActiveRootZone');
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
