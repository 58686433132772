<div class="dropdown-container"
     appOutClick
     (outClick)="close()">
  <app-custom-button
    [ngStyle]="customCSS2"
    [caret]="true"
    [content]="(isLoading ? errorString : (currentItem?.value || buttonString)) | translate"
    (click)="toggleOpen()"
    [color]="color"
    [disabled]="isLoading"
    [customCSS]="customCSS"
    [shadow]="shadow"
  ></app-custom-button>

  <div class="custom-dropdown" *ngIf="isOpen" [ngStyle]="customCSS2">
    <div class="custom-dropdown-title">
      <span>
        {{ title | translate }}
      </span>

      <br>

      <input
        [formControl]="searchFilter"
        [placeholder]="'Search target' | translate" class="form-control"
      >
    </div>

    <div class="custom-dropdown-data">
      <ng-container *ngIf="!isLoading">
        <div *ngFor="let group of items">
          <hr>
          <div class="custom-dropdown-group-title">
            {{ group.groupName | translate }}
          </div>

          <p *ngFor="let item of group.options" (click)="onChange(item)">
            <span
              class="fa fa-check check"
              *ngIf="currentItem && item.content === currentItem.content"
            ></span>
            {{ item.value | translate }}
          </p>
        </div>
      </ng-container>
    </div>
  </div>
</div>
