<div style="padding: 10px;">
    <div>
        <div class="rowDiv">
            <div>
                <p>{{ 'CROPZONE' | translate }}</p>
            </div>
            <div class="dateHeader">
                <p>{{ 'FROM' | translate }}</p>
            </div>
            <div class="dateHeader">
                <p>{{ 'TO' | translate }}</p>
            </div>
            <div>
                <p>{{ 'CROP TYPE' | translate }}</p>
            </div>
            <div class="dateHeader">
                <p>{{ 'BEGIN OF SEASON' | translate }}</p>
            </div>
            <div class="dateHeader">
                <p>{{ '10% GROUNDCOVER' | translate }}</p>
            </div>
            <div class="dateHeader">
                <p>{{ 'FLOWERING' | translate }}</p>
            </div>
            <div class="dateHeader">
                <p>{{ 'MATURITY' | translate }}</p>
            </div>
            <div class="dateHeader">
                <p>{{ 'END OF SEASON' | translate }}</p>
            </div>
        </div>

        <form [formGroup]="form" *ngIf="form">
            <div [formArrayName]="'cropzones'">
                <div *ngFor="let cropzone of cropzoneGroups.controls; let i = index" [formGroupName]="i" class="rowDiv">
                    <div>
                        <input formControlName="name" class="form-control" [attr.disabled]="true">
                    </div>
                    <div>
                        <app-custom-datepicker
                            [formControlName]="'from'"
                            [minDate]="cropzone.controls.from.value"
                            [maxDate]="cropzone.controls.from.value"
                            [hideTime]="true">
                        </app-custom-datepicker>
                    </div>
                    <div>
                        <app-custom-datepicker
                            [formControlName]="'to'"
                            [minDate]="cropzone.controls.to.value"
                            [maxDate]="cropzone.controls.to.value"
                            [hideTime]="true">
                        </app-custom-datepicker>
                    </div>
                    <div [ngClass]="isValidControl(form.value.cropzones[i], 'cropType') ? '' : 'error'">
                        <app-custom-simple-lazy-dropdown
                            (load)="onLoadCrops()"
                            [customCSS2]="{'width': '600px'}"
                            [customCSS]="{'max-width': '25%', 'width': '25%', 'border': '1px solid #909090'}"
                            [items$]="items$"
                            [shadow]="false"
                            formControlName="cropType"
                            color="white">
                        </app-custom-simple-lazy-dropdown>
                    </div>
                    <div [ngClass]="isValidControl(form.value.cropzones[i], 'emergence') ? '' : 'error'">
                        <app-custom-datepicker
                            [formControlName]="'emergence'"
                            [minDate]="cropzone.controls.from.value"
                            [maxDate]="cropzone.controls.to.value"
                            [hideTime]="true">
                        </app-custom-datepicker>
                    </div>
                    <div [ngClass]="isValidControl(form.value.cropzones[i], 'L_ini') ? '' : 'error'">
                        <app-custom-datepicker
                            [formControlName]="'L_ini'"
                            [minDate]="cropzone.controls.emergence.value"
                            [maxDate]="cropzone.controls.to.value"
                            [hideTime]="true">
                        </app-custom-datepicker>
                    </div>
                    <div [ngClass]="isValidControl(form.value.cropzones[i], 'L_dev') ? '' : 'error'">
                        <app-custom-datepicker
                            [formControlName]="'L_dev'"
                            [minDate]="cropzone.controls.L_ini.value"
                            [maxDate]="cropzone.controls.L_mid.value"
                            [hideTime]="true">
                        </app-custom-datepicker>
                    </div>
                    <div [ngClass]="isValidControl(form.value.cropzones[i], 'L_mid') ? '' : 'error'">
                        <app-custom-datepicker
                            [formControlName]="'L_mid'"
                            [minDate]="cropzone.controls.L_dev.value"
                            [maxDate]="cropzone.controls.L_late.value"
                            [hideTime]="true">
                        </app-custom-datepicker>
                    </div>
                    <div [ngClass]="isValidControl(form.value.cropzones[i], 'L_late') ? '' : 'error'">
                        <app-custom-datepicker
                            [formControlName]="'L_late'"
                            [minDate]="cropzone.controls.L_mid.value"
                            [maxDate]="cropzone.controls.to.value"
                            [hideTime]="true">
                        </app-custom-datepicker>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="alert alert-danger" *ngIf="saveDisabled()" style="margin-top: 20px; margin-bottom: 0px;">
        <p>{{ 'To enable the \'Save\' button, please enter the crop type and the cultivation dates.' | translate }}</p>
    </div>

    <div style="margin-top: 35px;">
        <h4>{{ 'Cropzone duplication' | translate }}</h4>
        <p>{{ 'Irrimet soil settings were copied from the previous cropzone. Please, adjust them accordingly.' | translate }}</p>
    </div>

    <div class="buttonDiv">
        <div>
            <app-custom-button [content]="'SAVE'" (click)="submit()" [color]="'green'" [disabled]="form.invalid || saveDisabled()"></app-custom-button>
            <app-custom-button [content]="'BACK'" (click)="back()" [color]="'red'"></app-custom-button>
        </div>
    </div>
</div>
