import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { postAsset, updateAssetSensors } from '../../actions/my-john-deere';
import { DEFAULT_SENSOR_GROUPS } from '../../constants/my-john-deere';
import { IState as IMyJohnDeereState } from '../../models/my-john-deere';
import {
  assets as assetsSelector
} from '../../selectors/my-john-deere';
import { IStation } from './../../../../core/models/stations';
import { IAccount } from './../../../../core/reducers/account';
import { selectSystemSensors } from './../../../../core/reducers/index';
import { ApiCallService } from './../../../../services/api/api-call.service';
import { CONTRIBUTION_DEFINITION_ID } from './../../constants/my-john-deere';
import { MyJohnDeereService } from './../../services/my-john-deere.service';

@Component({
  selector: 'app-my-john-deere-map',
  templateUrl: './my-john-deere-map.component.html',
  styleUrls: ['./my-john-deere-map.component.scss']
})
export class MyJohnDeereMapComponent implements OnInit, OnDestroy {
  @Input()
  public organization: any;
  @Input()
  public station: IStation;

  @Output()
  public closeSensorMap: EventEmitter<boolean> = new EventEmitter<boolean>();

  public assetConfiguration: any;
  public selectedAsset$: Observable<any>;
  public selectedAsset: any;
  public stationSensors$: Observable<any>;
  public systemSensors$: Observable<any>;
  public sensors$: Observable<any[]>;
  public sensorMap: any;
  public defaultSensorGroups: any = DEFAULT_SENSOR_GROUPS;
  public sensorList: any;
  private draggedIndex: number;
  public hasCustomName: boolean = false;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private api: ApiCallService,
    private myJohnDeereStore: Store<IMyJohnDeereState>,
    private account: Store<IAccount>,
    private myJohnDeereService: MyJohnDeereService
  ) { }

  public ngOnInit(): void {

    this.sensorList = null;
    this.assetConfiguration = null;

    this.stationSensors$ = this.api.getStationSensors(this.station.name.original).pipe(
      takeUntil(this.destroy$)
    );

    this.selectedAsset$ = this.myJohnDeereStore.pipe(
      select(assetsSelector),
      takeUntil(this.destroy$),
      map((assets) => assets.filter((asset) => asset.station === this.station.name.original))
    );

    this.systemSensors$ = this.account.pipe(
      select(selectSystemSensors),
      takeUntil(this.destroy$)
    );

    this.sensors$ = combineLatest([
      this.stationSensors$,
      this.systemSensors$
    ]).pipe(
      takeUntil(this.destroy$),
      mergeMap(([stationSensors, systemSensors]) => {
        return this.myJohnDeereService.getAssetConfiguration(this.station, stationSensors, systemSensors, this.organization);
      }),
      tap((assetConfig) => {
        assetConfig.sensors.some((sensor) => sensor.custom_name !== '') ? this.hasCustomName = true : this.hasCustomName = false;
        return this.assetConfiguration = assetConfig;
      }),
      map((assetConfig) => assetConfig.sensors),
      map((sensors: any) => sensors.map((sensor) => ({syncThisData: false, ...sensor})))
    );

    combineLatest([
      this.selectedAsset$,
      this.sensors$
    ]).pipe(takeUntil(this.destroy$))
    .subscribe(([asset, sensorsX]) => {
      this.sensorList = sensorsX;
      this.selectedAsset = asset;

      if (asset.length > 0) {
        this.sensorList.forEach((sensor) => {
          asset[0].sensors.forEach((assetSensor) => {
            if (sensor.ch === assetSensor.ch && sensor.code === assetSensor.code) {
              sensor.syncThisData = true;
            }
          });
        });
      }
    });
  }

  public toggle(toggledSensor): void {
    this.sensorList.forEach((sensor) => {
      if (sensor.ch === toggledSensor.ch && sensor.code === toggledSensor.code) {
        sensor.syncThisData = !sensor.syncThisData;
      }
    });
  }

  public saveSensorMap(): void {
    const assetConfigToSave = JSON.parse(JSON.stringify(this.assetConfiguration));
    assetConfigToSave.sensors = this.sensorList.filter((sensor) => sensor.syncThisData === true);

    assetConfigToSave.sensors = assetConfigToSave.sensors.map(({syncThisData, ...rest}) => {
      return rest;
    });

    assetConfigToSave.sensors = assetConfigToSave.sensors.map((sensor) => {
      if (sensor.custom_name === '') {
        delete sensor.custom_name;
        return sensor;
      } else {
        const customName = sensor.custom_name;
        delete sensor.custom_name;
        return {...sensor, name: customName};
      }
    });

    if (this.selectedAsset.length) {
      this.myJohnDeereStore.dispatch(
        updateAssetSensors(this.selectedAsset[0], this.createPostAssetConfig(assetConfigToSave))
      );
      this.cancelEditing();
    } else {
      this.createAsset(assetConfigToSave);
      this.cancelEditing();
    }
  }

  public createAsset(assetConfigToSave): void {
    this.myJohnDeereStore.dispatch(postAsset(this.createPostAssetConfig(assetConfigToSave)));
  }

  private createPostAssetConfig(assetConfig): any {
    return {
      config: assetConfig,
      asset: {
        title: this.station.name.custom || assetConfig.station,
        text: this.station.info.device_name,
        assetCategory: 'DEVICE',
        assetType: 'SENSOR',
        assetSubType: 'ENVIRONMENTAL',
        links: [{
          '@type': 'Link',
          rel: 'contributionDefinition',
          uri: `/contributionDefinitions/${CONTRIBUTION_DEFINITION_ID}`
        }]
      }
    };
  }

  public allowDrop($event): void {
    $event.preventDefault();
  }

  public onDragStart(index): void {
    this.draggedIndex = index;
  }

  public onDrop($event, index): void {
    $event.preventDefault();
    const item = this.sensorList[this.draggedIndex];
    this.sensorList.splice(this.draggedIndex, 1);
    this.sensorList.splice(index, 0, item);
    this.draggedIndex = -1;
  }

  public cancelEditing(): void {
    this.closeSensorMap.emit(true);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
