<app-form-layout class="logging-and-transfer">
  <app-card-header
    [header]="getHeader()"
    [helpButton]="true"
    (openModal)="openModal()"
    [description]="'Setup how your station is sending data' | translate"
  ></app-card-header>
  <div class="logging-and-transfer__content">
    <div class="row">
      <div [class.col-md-6]="(!state?.isIScout && !state?.isCropView) && state?.showAdvanced"
           [class.col-md-12]="state?.isIScout || state?.isCropView || !state?.showAdvanced">
        <app-logging-settings-two
          *ngIf="deviceGroup?.measuring || deviceGroup?.logging || deviceGroup?.fixedTransfer || deviceGroup?.led"
          #settings
          [station]="selectedStation"
          [state]="state"
          [frostEnabled]="frostEnabled"
          (changeMeasurementInterval)="changeMeasurementInterval($event)"
          (changeLoggingInterval)="changeLoggingInterval($event)"
          (changeFixedTransferInterval)="changeFixedTransferInterval($event)"
          (changeLEDFlash)="changeLEDFlash($event)"
        ></app-logging-settings-two>
        <app-scheduler-two
          *ngIf="deviceGroup?.scheduler"
          #scheduler
          [station]="selectedStation"
          [defaults]="defaults"
          (changeScheduler)="changeScheduler($event)"
        ></app-scheduler-two>
      </div>
      <app-advanced-options-two
      *ngIf="state?.isAdvanced && state?.showAdvanced"
        [station]="selectedStation"
        [transferInterval]="state?.config.transfer_interval"
        [measuringInterval]="state?.config.measuring_interval"
        [networkRegistrationTimeout]="state?.config.network_registration_timeout"
        [fixedTransferInterval]="state?.config.fixed_transfer_interval"
        [stationActivity]="state?.config.activity_mode"
        [loggingInterval]="state?.config.logging_interval"
        [rainMonitoring]="state?.config.rain_monitor"
        [frostEnabled]="frostEnabled"
        [threshold]="state?.config.frost_monitoring_threshold"
        [sensorCode]="state?.config.frost_monitoring_sensor_code"
        [sensorChannel]="state?.config.frost_monitoring_sensor_channel"
        [frostMonitorInterval]="state?.config.frost_monitoring_interval"
        (changeAdvanced)="changeAdvanced($event)"
      ></app-advanced-options-two>
    </div>
    <app-save-logging-and-transfer-two
      [state]="state"
      [isAdvanced]="state?.isAdvanced"
      [station]="selectedStation"
      (toggleAdvanced)="toggleAdvanced()"
      (toggleDefault)="toggleDefaults($event)"
      (save)="save()"
    ></app-save-logging-and-transfer-two>
  </div>
</app-form-layout>

<app-info-modal [modalId]="modalId"
                [header]="'Scheduler settings help' | translate">
  <app-logging-and-transfer-modal-two [isIScout]="state?.isIScout"
                                  [isAdvanced]="state?.isAdvanced"
                                  [isCropView]="state?.isCropView"
  ></app-logging-and-transfer-modal-two>
</app-info-modal>
