<app-form-layout *ngIf="!configuration.activated; else productActivated">
  <div class="alert alert-info">
    <span class="glyphicon glyphicon-info-sign"></span>
    {{ 'The "Weather Forecast" service already includes "Animal Production" and "Work Planning" services' | translate }}
  </div>

  <app-product-activation-license-alert [licensesOverlapped]="licensesOverlapped"></app-product-activation-license-alert>

  <div class="product-activation-forecast-form">
    <div class="product-activation-forecast-form__title">
      <h2>{{ (configuration.name || 'Weather forecast information') | translate }}</h2>
    </div>

    <div class="product-activation-forecast-form__content">
      <app-product-activation-forecast-form
        [configuration]="configuration"
        [userStations$]="availableUserStations$"
        (activateProductEmitter)="activateProduct($event)">
      </app-product-activation-forecast-form>
    </div>
  </div>
</app-form-layout>

<ng-template #productActivated>
  <div class="alert alert-success">
    <span class="glyphicon glyphicon-ok-sign"></span>
    {{ 'The Weather Forecast license was successfully activated!' | translate }}
  </div>
</ng-template>
