import { ActionTypes } from '../actions/crozone-settings.action';
import { IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { ICropzoneSettingsState } from '../models/model';

export const initialState: ICropzoneSettingsState = {
  table: {
    dates: {
      from: null,
      to: null
    },
    isTableActive: true,
    isExportActive: false,
    isLastMode: false,
    deviceType: null,
    activeMeter: null,
    activeTitle: true
  },
  activeDevice: null,
  activeSample: null
};

export function cropzoneSettingsReducer(state = initialState, action: IAction): ICropzoneSettingsState {
  switch (action.type) {
    case ActionTypes.SET_SAMPLE_SETTINGS:
      return {
        ...state,
        table: {
          ...state.table,
          ...action.payload
        }
      };
    case ActionTypes.SET_SAMPLE_DATES:
      return {
        ...state,
        table: {
          ...state.table,
          dates: action.payload
        }
      };
    case ActionTypes.SET_SAMPLE_ACTIVE:
      return {
        ...state,
        table: {
          ...state.table,
          isTableActive: action.payload
        },
      };
    case ActionTypes.SET_SAMPLE_LASTMODE:
      return {
        ...state,
        table: {
          ...state.table,
          isLastMode: action.payload
        }
      };
    case ActionTypes.SET_DEVICE_TYPE:
      return {
        ...state,
        table: {
          ...state.table,
          deviceType: action.payload
        }
      };
    case ActionTypes.SET_ACTIVE_TITLE:
      return {
        ...state,
        table: {
          ...state.table,
          activeTitle: action.payload
        }
      };
    case ActionTypes.SET_ACTIVE_METER:
      return {
        ...state,
        table: {
          ...state.table,
          activeMeter: action.payload
        }
      };
    case ActionTypes.SET_ACTIVE_DEVICE:
      return {
        ...state,
        activeDevice: action.payload
      };
    case ActionTypes.SET_ACTIVE_SAMPLE:
      return {
        ...state,
        activeSample: action.payload
      };
    default:
      return state;
  }
}
