<section>
  <app-form-layout>
    <app-iscout-header
      *ngIf="isCreationForm()"
      [title]="'Add a new insect'"
      [subtitle]="'Create customized insect to track on iScout'"
    ></app-iscout-header>

    <app-iscout-header
      *ngIf="!isCreationForm()"
      [title]="'Update a insect'"
      [subtitle]="'Update a customized insect to track on iScout'"
    ></app-iscout-header>

    <form class="iscout-pests-form" [formGroup]="form">
      <div class="row">
        <div class="col-sm-12 col-md-6 iscout-pests-form__group">
          <label>{{ 'Insect genus' | translate }}*</label>
          <br>
          <app-custom-simple-lazy-dropdown
            class="iscout-pests-form__input"
            [formControl]="pestGenus"
            [items$]="pestGenusList$"
          ></app-custom-simple-lazy-dropdown>
        </div>

        <div class="col-sm-12 col-md-6 iscout-pests-form__group">
          <label>{{ 'Scientific name' | translate }}*</label>
          <app-form-input
            class="iscout-pests-form__input"
            [formControl]="pestScientificName"
          ></app-form-input>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-6 iscout-pests-form__group">
          <label>{{ 'Common name' | translate }}</label>
          <app-form-input
            class="iscout-pests-form__input"
            [formControl]="pestCommonName"
          ></app-form-input>
        </div>

        <div class="col-sm-12 col-md-6">
          <label>{{ 'Color' | translate }}*</label>

          <app-form-input
            class="sensor-td__control"
            [type]="'border'"
            [styles]="{'cursor': 'pointer', 'background-color': pestColor.value}"
            [isDisabled]="true"
            [formControl]="pestColor"
            [size]="'small'"
          ></app-form-input>

          <div
            class="fake-color-picker"
            [colorPicker]="pestColor.value"
            (colorPickerChange)="onColorChange($event)"
            [cpFallbackColor]="'#f5f5f5'"
            [cpOutputFormat]="'hex'"
            [cpAlphaChannel]="'disabled'"
            [cpPosition]="'bottom'"
            [cpPresetColors]="presetColors"
            [cpUseRootViewContainer]="true"
          ></div>
        </div>
      </div>

      <div class="iscout-pests-form__submit">
        <app-custom-button
          [disabled]="!form.valid"
          [color]="'red'"
          [content]="'Save'"
          (click)="submitPest()"
        ></app-custom-button>

        <app-custom-button
          [content]="'Back'"
          (click)="unselectPest()"
        ></app-custom-button>
      </div>
    </form>
  </app-form-layout>
</section>
