import { Component, EventEmitter, OnInit, Inject, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environmentToken } from '../../../../../environments/environment';
import { IEnvironment } from '../../../../../environments/interfaces/environment';
import { IThemeConfig } from '../../../../../environments/interfaces/theme';
import { AuthService } from '../../../../services/auth/auth.service';
import { selectEmail, selectFirstName, selectLastName, selectUserName } from '../../../reducers';
import * as fromAccount from '../../../reducers/account';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  @Output()
  private openAddRemoveModal                : EventEmitter<void> = new EventEmitter<void>();
  @Output()
  private openAddRemoveVirtualStationModal  : EventEmitter<void> = new EventEmitter<void>();

  public termsHref                          : string = 'https://metos.at/en/terms-and-conditions/';
  public email$             : Observable<string>;
  public firstName$         : Observable<string>;
  public lastName$          : Observable<string>;
  public userName$          : Observable<string>;
  public userMenu           : boolean;
  public themeConfig        : IThemeConfig;

  constructor(
    @Inject(environmentToken) environment: IEnvironment,
    private store: Store<fromAccount.IAccount>,
    private authService: AuthService
  ) {
    this.themeConfig = environment.theme;
    this.userMenu = environment.displayRules.userMenu;
  }

  public ngOnInit(): void {
    this.email$ = this.store.pipe(select(selectEmail));
    this.firstName$ = this.store.pipe(select(selectFirstName));
    this.lastName$ = this.store.pipe(select(selectLastName));
    this.userName$ = this.store.pipe(select(selectUserName));

    if (this.themeConfig && this.themeConfig.subDomain === 'telus') {
      this.termsHref = 'https://decisivefarming.com/privacy-policy-ca/';
    }
  }

  public logout(): void {
    this.authService.logoutUser();
  }

  public openAddRemoveModalEmit(): void {
    this.openAddRemoveModal.emit();
  }

  public openAddRemoveVirtualStationModalEmit(): void {
    this.openAddRemoveVirtualStationModal.emit();
  }

}
