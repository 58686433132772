import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { setOneCamSettings, setSpots } from '../../../actions/cameraSettings';
import { DefaultCam } from '../../../constants/constants';
import { ICamSettings } from '../../../models/station-config.models';
import * as fromCameraSettings from '../../../reducers/cameraSettings';

@Component({
  selector: 'app-camera-settings-image',
  templateUrl: './camera-settings-image.component.html',
  styleUrls: ['./camera-settings-image.component.scss']
})
export class CameraSettingsImageComponent implements OnInit, OnDestroy {
  @Input()
  public cam                    : ICamSettings;
  @Input()
  public camKey                 : string;

  public form                   : FormGroup;
  public setShow                : boolean = false;
  public tId                    : any = 0;

  private destroy$              : Subject<boolean> = new Subject<boolean>();

  constructor(private fb: FormBuilder,
              private store: Store<fromCameraSettings.ICameraSettingsState>) { }

  public get spots(): FormArray {
    return <FormArray>this.form.get('spots');
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      'spots': this.generateSpots()
    });

    this.spots.setValue(this.cam.spots);

    this.spots.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(100),
      distinctUntilChanged()
    ).subscribe((s: string[]) => {
        this.store.dispatch(setSpots({
          camKey: this.camKey,
          spots: s
        }));
    });
  }

  public reset(): void {
    this.spots.controls.forEach((c: AbstractControl) => c.setValue('1'));
    this.store.dispatch(setOneCamSettings({
      camKey: this.camKey,
      settings: DefaultCam
    }));
    clearTimeout(this.tId);
    this.setShow = true;
    this.tId = setTimeout(() => {
      this.setShow = false;
    }, 3000);
  }

  private generateSpots(): FormArray {
    const array: FormArray = this.fb.array([]);
    for (let i = 0; i < 8; i++) {
      array.push(this.fb.control('0'));
    }
    return array;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
