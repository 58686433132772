import {IOptions} from '../../../shared/interfaces';
import {ICam, IPosition} from '../../../core/models/stations';
import {ILocationExtremes, ILoggingTransferDevice} from '../models/station-config.models';

export const LocationExtremes: ILocationExtremes = {
  latitudeMax: 85,
  latitudeMin: -85,
  longitudeMax: 180,
  longitudeMin: -180
};

export const DefaultStationPosition: IPosition = {
  geo: {
    coordinates: [
      15.4395,
      47.0707
    ]
  },
  altitude: 0,
  timezoneCode: ''
};

export const StationsMarkers = new Map([
  [2, 'imt2.png'],
  [6, 'imt2.png'],
  [5, 'imetos3.3.png'],
  [7, 'imetos3.3.png'],
  [8, 'ecod3.png'],
  [28, 'ica.png'],
  [30, 'blue.png'],
  [50, 'blue.png'],
  [31, 'nbiot.png'],
  [32, 'lora.png'],
  [40, 'active_tracker.png'],
  [52, 'wan.png'],
  [70, 'cropview.png'],
  [71, 'cropview.png'],
  [72, 'iscout.png'],
  [100, 'virtual.png']
]);

export const ExposureModes: IOptions[] = [
  {
    value: '1',
    content: 'Automatic'
  },
  {
    value: '0',
    content: 'Manual'
  }
];

export const ActivityOptions: IOptions[] = [
  {
    value: '0',
    content: 'Station is gathering and sending data'
  },
  {
    value: '1',
    content: 'Station is gathering but not sending data'
  },
  {
    value: '2',
    content: 'Station is switched off'
  }
];

export const RainOptions: IOptions[] = [
  {
    value: '0',
    content: 'Disabled'
  },
  {
    value: '1',
    content: 'Enabled'
  }
];

export const FrostOptions: IOptions[] = [
  {
    value: '0',
    content: 'Disabled'
  },
  {
    value: '1',
    content: 'Enabled'
  }
];

export const MeasurementOptionValues: number[] = [
  5, 10, 15, 20, 30, 60
];

export const measurementOptions: IOptions[] = [
  {
    value: null,
    content: '-----'
  },
  {
    value: '5',
    content: '5 minutes'
  },
  {
    value: '10',
    content: '10 minutes'
  },
  {
    value: '15',
    content: '15 minutes'
  },
  {
    value: '20',
    content: '20 minutes'
  },
  {
    value: '30',
    content: '30 minutes'
  },
  {
    value: '60',
    content: '60 minutes'
  }
];

export const LoggingOptionValues: number[] = [
  5, 10, 15, 20, 30, 60
];

export const LoggingOptions: IOptions[] = [
  {
    value: null,
    content: '-----'
  },
  {
    value: '5',
    content: '5 minutes'
  },
  {
    value: '10',
    content: '10 minutes'
  },
  {
    value: '15',
    content: '15 minutes'
  },
  {
    value: '20',
    content: '20 minutes'
  },
  {
    value: '30',
    content: '30 minutes'
  },
  {
    value: '60',
    content: '60 minutes'
  }
];

export const FixedTransferOptionValues: number[] = [
  0, 5, 10, 15, 20, 30, 60
];

export const fixedTransferIntervalOptions: IOptions[] = [
  {
    value: '0',
    content: 'Fixed transfer disabled'
  },
  /* {
    value: '5',
    content: '5 minutes'
  },
  {
    value: '10',
    content: '10 minutes'
  }, */
  {
    value: '15',
    content: '15 minutes'
  },
  /* {
    value: '20',
    content: '20 minutes'
  }, */
  {
    value: '30',
    content: '30 minutes'
  },
  {
    value: '60',
    content: '60 minutes'
  }
];

export const IMetos3TransferOptions: IOptions[] = [
  {
    value: '0',
    content: 'Fixed transfer disabled'
  },
  {
    value: '10',
    content: '10 minutes'
  },
  {
    value: '15',
    content: '15 minutes'
  },
  {
    value: '20',
    content: '20 minutes'
  },
  {
    value: '30',
    content: '30 minutes'
  },
  {
    value: '60',
    content: '60 minutes'
  }
];

export const LoggingOptionsNBiot: IOptions[] = [
  {
    value: '5',
    content: '5 minutes'
  },
  {
    value: '10',
    content: '10 minutes'
  },
  {
    value: '15',
    content: '15 minutes'
  },
  {
    value: '20',
    content: '20 minutes'
  },
  {
    value: '30',
    content: '30 minutes'
  },
  {
    value: '60',
    content: '60 minutes'
  },
  {
    value: '120',
    content: '120 minutes'
  }
];

export const TransferOptionsNBiot: IOptions[] = [
  {
    value: '5',
    content: '5 minutes'
  },
  {
    value: '10',
    content: '10 minutes'
  },
  {
    value: '15',
    content: '15 minutes'
  },
  {
    value: '20',
    content: '20 minutes'
  },
  {
    value: '30',
    content: '30 minutes'
  },
  {
    value: '60',
    content: '60 minutes'
  },
  {
    value: '120',
    content: '120 minutes'
  }
];

export const NetworkRegistrationTimeoutOptions: IOptions[] = [
  {
    value: '240',
    content: '240 seconds'
  },
  {
    value: '300',
    content: '300 seconds'
  }
];

export const AdvancedDevices: number[] = [
  6,
  7,
  8,
  // Must include all uMetos NBIoT devices
  49,
  57,
  58,
  59,
  60,
  61,
  75,
  76,
  77,
  80
];

export enum StationTopologyLinkType {
  LINK_CONFIG = 'LINK_CONF',
  LINK_DATA = 'LINK_DATA',
}

export const DefaultCam: ICam = {
  active: true,
  auto_exposure: 1,
  max_integration_time: 2300,
  brightness_ref: 750,
  integration_time: 1000,
  global_gain: 6208,
  square_spots: 255
};

export const GlobalGainOptions: IOptions[] = [
  {
    value: '4160',
    content: '4160 [0x1040]'
  },
  {
    value: '6208',
    content: '6208 [0x1840]'
  },
  {
    value: '6240',
    content: '6240 [0x1860]'
  },
  {
    value: '7232',
    content: '7232 [0x1C40]'
  },
  {
    value: '7248',
    content: '7248 [0x1C50]'
  },
  {
    value: '7264',
    content: '7264 [0x1C60]'
  },
  {
    value: '7280',
    content: '7280 [0x1C70]'
  },
  {
    value: '7360',
    content: '7360 [0x1CC0]'
  },
  {
    value: '7368',
    content: '7368 [0x1CC8]'
  },
  {
    value: '7376',
    content: '7376 [0x1CD0]'
  },
  {
    value: '7384',
    content: '7384 [0x1CD8]'
  },
  {
    value: '7392',
    content: '7392 [0x1CE0]'
  },
  {
    value: '7400',
    content: '7400 [0x1CE8]'
  },
  {
    value: '7408',
    content: '7408 [0x1CF0]'
  },
  {
    value: '7416',
    content: '7416 [0x1CF8]'
  },
  {
    value: '7616',
    content: '7616 [0x1DC0]'
  },
  {
    value: '7620',
    content: '7620 [0x1DC4]'
  },
  {
    value: '7624',
    content: '7624 [0x1DC8]'
  },
  {
    value: '7628',
    content: '7628 [0x1DCC]'
  },
  {
    value: '7632',
    content: '7632 [0x1DD0]'
  },
  {
    value: '7636',
    content: '7636 [0x1DD4]'
  },
  {
    value: '7640',
    content: '7640 [0x1DD8]'
  },
  {
    value: '7644',
    content: '7644 [0x1DDC]'
  },
  {
    value: '7648',
    content: '7648 [0x1DE0]'
  },
  {
    value: '7652',
    content: '7652 [0x1DE4]'
  },
  {
    value: '7656',
    content: '7656 [0x1DE8]'
  },
  {
    value: '7660',
    content: '7660 [0x1DEC]'
  },
  {
    value: '7664',
    content: '7664 [0x1DF0]'
  },
  {
    value: '7668',
    content: '7668 [0x1DF4]'
  },
  {
    value: '7672',
    content: '7672 [0x1DF8]'
  },
  {
    value: '7676',
    content: '7676 [0x1DFC]'
  }
];

export const IngestedStations = [
  'B10', // Davis
  'F10' // Meteobot
];

export enum LedTrapConfigLimits {
  TEMPERATURE_THRESHOLD_MIN = 12,
  TEMPERATURE_THRESHOLD_MAX = 25,
  LED_RING_TIME_ON_MIN = 200,
  LED_RING_TIME_ON_MAX = 2000,
  LED_RING_TIME_OFF_MIN = 200,
  LED_RING_TIME_OFF_MAX = 2000,
}

export enum StationConfigDefaultScheduler {
  ALL_SELECTED_SCHEDULER = '111111111111111111111111',
  LOGGING_AND_TRANSFER_SCHEDULER = '001000100010001000100010',
  CAMERA_PHOTO_UPLOAD_SCHEDULER = '000000001000000000000000',
  CAMERA_LED_TRAP_SCHEDULER = '000000000000000000001110',
}

export const ledFlashOptions: Array<IOptions> = [
  {
    value: 0,
    content: 'Flash off'
  },
  {
    value: 1,
    content: 'Flash on'
  }
];

const ledFlashIntervalOptions: Array<IOptions> = [
  {
    value: 10,
    content: '10 minutes'
  },
  {
    value: 15,
    content: '15 minutes'
  },
  {
    value: 30,
    content: '30 minutes'
  },
  {
    value: 60,
    content: '60 minutes'
  },
];

const allIntervalOptions: Array<IOptions> = [
  {
    value: '5',
    content: '5 minutes'
  },
  {
    value: '10',
    content: '10 minutes'
  },
  {
    value: '15',
    content: '15 minutes'
  },
  {
    value: '20',
    content: '20 minutes'
  },
  {
    value: '30',
    content: '30 minutes'
  },
  {
    value: '60',
    content: '60 minutes'
  }
];

const intervalWithout60: Array<IOptions> = [
  {
    value: '5',
    content: '5 minutes'
  },
  {
    value: '10',
    content: '10 minutes'
  },
  {
    value: '15',
    content: '15 minutes'
  },
  {
    value: '20',
    content: '20 minutes'
  },
  {
    value: '30',
    content: '30 minutes'
  }
];

const with120Options: Array<any> = [
  {
    value: '5',
    content: '5 minutes'
  },
  {
    value: '10',
    content: '10 minutes'
  },
  {
    value: '15',
    content: '15 minutes'
  },
  {
    value: '20',
    content: '20 minutes'
  },
  {
    value: '30',
    content: '30 minutes'
  },
  {
    value: '60',
    content: '60 minutes'
  },
  {
    value: '120',
    content: '120 minutes'
  }
];

const allTransferOptions: Array<any> = [
  {
    value: '0',
    content: 'Not set'
  },
  {
    value: '15',
    content: '15 minutes'
  },
  {
    value: '30',
    content: '30 minutes'
  },
  {
    value: '60',
    content: '60 minutes'
  }
];

const allEnabledTransferOptions: Array<any> = [
  {
    value: '15',
    content: '15 minutes'
  },
  {
    value: '30',
    content: '30 minutes'
  },
  {
    value: '60',
    content: '60 minutes'
  }
];

const intervalOptions5: Array<any> = [
  {
    value: '5',
    content: '5 minutes'
  }
];

const intervalOptions10: Array<any> = [
  {
    value: '10',
    content: '10 minutes'
  }
];

const intervalOptions15: Array<any> = [
  {
    value: '15',
    content: '15 minutes'
  }
];

const intervalOptions30: Array<any> = [
  {
    value: '30',
    content: '30 minutes'
  }
];

export const LoggingTransferGroups: ILoggingTransferDevice[] = [
  {
    measuring: true,
    logging: true,
    fixedTransfer: true,
    scheduler: false,
    network: true,
    led: false,
    rain: false,
    frost: false,
    deviceIDs: [75],
    defaults: ['15', '15', '60', ''],
    options: [intervalOptions15, intervalOptions15, allEnabledTransferOptions],
    stationActivity: false
  },
  {
    measuring: false,
    logging: false,
    fixedTransfer: false,
    scheduler: false,
    network: false,
    led: false,
    rain: false,
    frost: false,
    deviceIDs: [50, 51, 52, 53, 54, 55, 56],
    defaults: ['15', '15', '60', ''],
    options: [[], [], [], []],
    stationActivity: false
  },
  {
    measuring: true,
    logging: true,
    fixedTransfer: true,
    scheduler: false,
    network: true,
    led: false,
    rain: false,
    frost: false,
    deviceIDs: [57, 58, 59, 60, 61, 63, 76, 77],
    defaults: ['5', '15', '60', ''],
    options: [allIntervalOptions, allIntervalOptions, allEnabledTransferOptions],
    stationActivity: false
  },
  {
    measuring: true,
    logging: true,
    fixedTransfer: true,
    scheduler: false,
    network: true,
    led: false,
    rain: false,
    frost: true,
    deviceIDs: [49],
    defaults: ['5', '15', '60', ''],
    frostDefaults: ['5', '5', '5'],
    options: [allIntervalOptions, allIntervalOptions, allEnabledTransferOptions],
    stationActivity: false
  },
  {
    measuring: true,
    logging: true,
    fixedTransfer: true,
    scheduler: true,
    network: false,
    led: false,
    rain: true,
    frost: true,
    deviceIDs: [7, 8],
    defaults: ['5', '15', '0', '010001000100010001000100'],
    frostDefaults: ['5', '10', '10'],
    options: [with120Options, with120Options, allTransferOptions],
    stationActivity: true
  },
  {
    measuring: true,
    logging: true,
    fixedTransfer: false,
    scheduler: false,
    network: false,
    led: false,
    rain: false,
    frost: false,
    deviceIDs: [78],
    defaults: ['5', '15', '15', ''],
    options: [intervalOptions5, intervalOptions15, intervalOptions15],
    stationActivity: false
  },
  {
    measuring: true,
    logging: true,
    fixedTransfer: false,
    scheduler: true,
    network: false,
    led: false,
    rain: false,
    frost: false,
    deviceIDs: [125],
    defaults: ['15', '30', null, '111111111111111111111111'],
    options: [intervalOptions15, intervalOptions30, []],
    stationActivity: false
  },
  {
    measuring: false,
    logging: true,
    fixedTransfer: false,
    scheduler: true,
    network: false,
    led: false,
    rain: false,
    frost: false,
    deviceIDs: [0, 1, 2, 4, 5],
    defaults: [null, '60', null, '010001000100010001000100'],
    options: [[], allIntervalOptions, []],
    stationActivity: false
  },
  {
    measuring: false,
    logging: true,
    fixedTransfer: false,
    scheduler: true,
    network: false,
    led: false,
    rain: true,
    frost: true,
    deviceIDs: [6],
    defaults: [null, '60', null, '010001000100010001000100'],
    frostDefaults: ['5', '10', '10'],
    options: [[], allIntervalOptions, []],
    stationActivity: false
  },
  {
    measuring: false,
    logging: true,
    fixedTransfer: false,
    scheduler: true,
    network: false,
    led: false,
    rain: false,
    frost: false,
    deviceIDs: [3],
    defaults: [null, '15', null, '010001000100010001000100'],
    options: [[], allIntervalOptions, []],
    stationActivity: false
  },
  {
    measuring: false,
    logging: true,
    fixedTransfer: true,
    scheduler: false,
    network: false,
    led: false,
    rain: false,
    frost: false,
    deviceIDs: [28],
    defaults: [null, '10', '30', ''],
    options: [null, intervalWithout60, intervalOptions30],
    stationActivity: false
  },
  {
    measuring: false,
    logging: false,
    fixedTransfer: false,
    scheduler: true,
    network: false,
    led: true,
    rain: false,
    frost: false,
    deviceIDs: [70, 71, 72, 73],
    defaults: [null, null, null, '000000000001000000000000'],
    options: [null, null, null],
    stationActivity: false
  }
];

export const NMetosFrostTableData: any = {
  data: [
    ['5', '10', '60', '>= 8°C'],
    ['1', '5', '15', '=4°C < 8°C'],
    ['1', '1', '5', '< 4°C > -6°C'],
    ['1', '5', '15', '<= 6°C > -8°C'],
    ['5', '10', '60', '<= -8°C']
  ],
  headers: [
    'measurement interval (in minutes)',
    'logging interval (in minutes)',
    'transmission interval (in minutes)',
    'wet bulb temperature value'
  ]
};
