import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SampleNutritionComponent } from './containers/sample-nutrition/sample-nutrition.component';
import { SampleSoilguardComponent } from './containers/sample-soilguard/sample-soilguard.component';

const routes: Routes = [
  { path: 'nutrition', component: SampleNutritionComponent },
  { path: 'soilguard', component: SampleSoilguardComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CropzoneSampleRoutingModule { }
