import { Injectable } from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {ApiCallService} from '../../../services/api/api-call.service';
import {catchError, mergeMap, switchMap, tap, map} from 'rxjs/operators';
import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {from, Observable, of, iif, forkJoin} from 'rxjs';
import {
  loadNodes,
  loadSensors,
  SensorsAndNodeActionTypes, setNodeChanged,
  setNodes,
  toggleSensorsLoading,
  toggleSensorsLoadingError, updateSensor
} from '../actions/sensors-and-nodes';
import {SensorsAndNodesHelpService} from '../services/sensors-and-nodes-help.service';
import {Store} from '@ngrx/store';
import * as fromSensorsAndNodes from '../reducers/sensors-and-nodes';
import {setNotify} from '../../../core/actions/notify';
import {IUpdateSensor} from '../models/sensors-and-nodes';
import { getWarnings } from '../../../core/actions/stations';

@Injectable()
export class SensorsAndNodesEffects {

  constructor(private actions$: Actions,
              private api: ApiCallService,
              private helpService: SensorsAndNodesHelpService,
              private store: Store<fromSensorsAndNodes.ISensorsAndNodesState>) {
  }

  @Effect()
  public loadSensors$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(SensorsAndNodeActionTypes.LOAD_SENSORS),
    switchMap((action: IActionWithPayload) => this.api.getStationSensors(action.payload).pipe(
      switchMap((res: any) => from([
        setNodes(this.helpService.prepareNodes(res)),
        toggleSensorsLoading(false),
      ])),
      catchError(() => from([
        toggleSensorsLoading(false),
        toggleSensorsLoadingError(true)
      ]))
      )
    )
  );

  @Effect()
  public loadNodes$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(SensorsAndNodeActionTypes.LOAD_NODES),
    tap((action: IActionWithPayload) => {
      this.store.dispatch(toggleSensorsLoading(true));
      this.store.dispatch(toggleSensorsLoadingError(false));
    }),
    switchMap((action: IActionWithPayload) => forkJoin([
        this.api.getStationNodes(action.payload),
        this.api.getStationSerials(action.payload)
      ])
      .pipe(
        switchMap(([nodes, serials]) => {
          this.helpService.setNodesNames(nodes, serials);
          return from([
            loadSensors(action.payload)
          ]);
        }),
        catchError(() => {
          this.helpService.setNodesNames({}, {});
          return from([
            loadSensors(action.payload)
          ]);
        })
      )
    )
  );

  @Effect()
  public resetNodes$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(SensorsAndNodeActionTypes.RESET_NODES),
    switchMap((action: IActionWithPayload) => this.api.resetUnits(action.payload).pipe(
      switchMap(() => [
        loadNodes(action.payload),
        getWarnings(action.payload.stationId)
      ]),
      catchError(() => from([]))
      )
    )
  );

  @Effect()
  public saveNodeName$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(SensorsAndNodeActionTypes.SAVE_NODE_NAME),
    tap((action: IActionWithPayload) => {
      this.store.dispatch(setNodeChanged({
        isChanged: false,
        nodeId: action.payload.nodeId
      }));
    }),
    switchMap((action: IActionWithPayload) => {
      if (action.payload.node.serial && String(action.payload.node.serial).toLowerCase() !== 'x') {
        return this.api.updateStationSerial(
          action.payload.stationId,
          { ...action.payload.node, mac: undefined }
        )
        .pipe(
          switchMap(() => from([
              setNotify('Custom name was updated successfully')
            ])
          ),
          catchError(() => from([
              setNodeChanged({
                isChanged: false,
                nodeId: action.payload.nodeId
              }),
              setNotify('The settings were not saved, due to the error')
            ])
          )
        );
      } else {
        return this.api.updateStationNode(
          action.payload.stationId,
          { ...action.payload.node, serial: undefined }
        )
          .pipe(
            switchMap(() => from([
              setNotify('Custom name was updated successfully')
            ])
            ),
            catchError(() => from([
              setNodeChanged({
                isChanged: false,
                nodeId: action.payload.nodeId
              }),
              setNotify('The settings were not saved, due to the error')
            ])
            )
          );
      }
    })
  );

  @Effect()
  public saveSensor$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(SensorsAndNodeActionTypes.SAVE_SENSOR),
    tap((action: IActionWithPayload) => {
      action.payload.sensors.update.forEach((update: IUpdateSensor) => {
        this.store.dispatch(updateSensor({
          nodeId: update.nodeId,
          sensorId: update.sensorId,
          sensor: {
            isChanged: false,
          }
        }));
      });
    }),
    mergeMap((action: IActionWithPayload) => this.api.updateStationSensors(
      action.payload.stationId,
      action.payload.sensors.save)
      .pipe(
        switchMap(() => from([
            setNotify('The sensor\'s settings were updated successfully'),
            getWarnings(action.payload.stationId)
          ])
        ),
        catchError(() => {
          action.payload.sensors.update.forEach((update: IUpdateSensor) => {
            this.store.dispatch(updateSensor({
              nodeId: update.nodeId,
              sensorId: update.sensorId,
              sensor: {
                isChanged: true,
              }
            }));
          });
          return from([
            setNotify('The settings were not saved, due to the error')
          ]);
          }
        )
      )
    )
  );

}
