import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { from, Observable, of } from 'rxjs';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import {catchError, concatMap, exhaustMap, map, switchMap, tap} from 'rxjs/operators';
import { ApiCallService } from '../../../services/api/api-call.service';
import {
  ActionTypes, getUltrasonicSensors, getUltrasonicSettings, setCurrentValue,
  setUltrasonicSensors,
  setUltrasonicSettings
} from '../actions/actions';
import { setNotify } from '../../../core/actions/notify';
import { IUltrasonicSetting } from '../models/models';

@Injectable()
export class UltrasonicDistanceSettingsEffects {

  constructor(
    private actions$    : Actions,
    private api         : ApiCallService
  ) { }

  @Effect()
  public getUltrasonicSensors$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ActionTypes.GET_ULTRASONIC_SENSORS),
    switchMap((action: IActionWithPayload) => this.api.getStationSensors(action.payload).pipe(
      map(sensors => sensors.filter(sensor => sensor.code === 24838)),
      switchMap((ultrasonicSensors: any) => from([
        setUltrasonicSensors(ultrasonicSensors
          .map(sensor => ({
            code: sensor.code,
            channel: sensor.ch,
            unit: sensor.unit,
            group: sensor.group
          }))
        )
      ])),
      catchError(() => of(setNotify('Error getting data'))),
    ))
  );

  @Effect()
  public saveUltrasonicSettings$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ActionTypes.SAVE_ULTRASONIC_SETTINGS),
    exhaustMap((action: IActionWithPayload) =>
      this.api.saveUltrasonicSettings(action.payload).pipe(
        concatMap(() => [
          setNotify('The sensor\'s settings were updated successfully'),
          getUltrasonicSettings(action.payload.stationId)
        ]),
        catchError(() => of(setNotify('Error saving settings'))),
      )
    )
  );

  @Effect()
  public getUltrasonicSettings$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ActionTypes.GET_ULTRASONIC_SETTINGS),
    switchMap((action: IActionWithPayload) => this.api.getUltrasonicSettings(action.payload).pipe(
      map((ultrasonicSettings: any) => {
        return ultrasonicSettings.map(setting => ({
          elevationValue: setting.elevationValue,
          unit: setting.unit,
          from: setting.from,
          to: setting.to
        }));
      }),
      switchMap((ultrasonicSettings: Array<IUltrasonicSetting>) => from([
        setUltrasonicSettings(ultrasonicSettings)
      ])),
      catchError(() => of(setNotify('Error getting settings'))),
    ))
  );

  @Effect()
  public convertDefaultValue$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ActionTypes.CONVERT_DEFAULT_VALUE),
    switchMap((action: IActionWithPayload) => this.api.convertValue(action.payload).pipe(
      switchMap((convertedData: any) => from([
        setCurrentValue({value: convertedData.convertedValue, unit: convertedData.unitTo})
      ])),
      catchError(() => of(setNotify('Error getting data'))),
    ))
  );

  @Effect()
  public getUltrasonicData$: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ActionTypes.GET_ULTRASONIC_DATA),
    switchMap((action: IActionWithPayload) => [
      getUltrasonicSensors(action.payload),
      getUltrasonicSettings(action.payload),
    ]),
    catchError(() => of(setNotify('Error getting data')))
  );
}
