<div class="soil-moisture-modal">
  <p>
    {{ 'In this section you can create your favourite chart views based on calculations (like AVG, SUM, PAW) applied on
    the data series of sensors that you can select. We recommend that you personalize the name of the view in the “Custom
    view name” box, so that, once saved, you can easily find it in the Views drop down menu on the left.
    In “Select calculations and sensors type” you can select the type of calculation you want to apply and on which
    type of sensors of the selected station.' | translate }}
  </p>
  <p>
    {{ 'In “Sensors” you can select the sensors on which you want to apply the calculation.
    AVG calculates and shows in the chart and table the average time serie of the measurements of the selected sensors;
    SUM calculates the summary of the selected sensors measurements.' | translate }}
  </p>
  <p>
    {{ 'The PAW (Plant Available Water) calculation applies normalization to VWC data, based on WP (Wilting Point) and FC
    (Field Capacity) that you have to set in the related boxes for every sensor data serie. The calculated outputs are
    displayed for every sensor in terms of PAW, where 100% correspond to the Full Point (or Field Capacity), whereas
    the default value for Refill Point is set at 50%, but can be modified based on different crop water requirements.' |
    translate }}
  </p>
</div>
