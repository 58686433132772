import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IOptions } from '../../../../shared/interfaces';
import { getAvailableServiceLicenseStations, saveServiceLicenseActivation } from '../../actions/product-activation';
import {
  IAvailableUserStation,
  IProductActivationState,
  IProductConfigurationItem,
  IServiceLicenseActivation,
  IValidLicense
} from '../../models/product-activation.models';
import { selectAvailableServiceLicenseStations } from '../../reducers/index';

@Component({
  selector: 'app-product-activation-service-license',
  templateUrl: './product-activation-service-license.component.html',
  styleUrls: ['./product-activation-service-license.component.scss']
})
export class ProductActivationServiceLicenseComponent implements OnInit, OnDestroy {
  @Input()
  public configuration: IProductConfigurationItem;
  @Input()
  public licensesOverlapped: IValidLicense[];

  public availableUserStations$: Observable<IOptions[]>;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public constructor(private productActivationStore: Store<IProductActivationState>) { }

  public ngOnInit(): void {
    this.availableUserStations$ = this.productActivationStore.pipe(
      select(selectAvailableServiceLicenseStations),
      map((userStations: IAvailableUserStation[]) => userStations.map((station: IAvailableUserStation) => ({
        content: station.station_name,
        value: station.custom_name ? `${station.station_name} (${station.custom_name})` : station.station_name
      })))
    );

    this.productActivationStore.dispatch(getAvailableServiceLicenseStations());
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public activateProduct(activation: IServiceLicenseActivation): void {
    this.productActivationStore.dispatch(saveServiceLicenseActivation(activation));
  }
}
