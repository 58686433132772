import {IActionWithPayload} from '../../../core/models/actionWithPayload';
import {ICamSettings} from '../models/station-config.models';
import {CameraSettingsActionType} from '../actions/cameraSettings';

export interface ICameraSettingsState {
  cam1: ICamSettings;
  cam2?: ICamSettings;
  isInvalid?: boolean;
}

const initialState: ICameraSettingsState = {
  cam1: null,
  cam2: null,
  isInvalid: false,
};

export function reducer(state: ICameraSettingsState = initialState, action: IActionWithPayload ): ICameraSettingsState {
  switch (action.type) {
    case CameraSettingsActionType.TOGGLE_CAMERA_SETTINGS_INVALID: {
      return {
        ...state,
        isInvalid: action.payload
      };
    }
    case CameraSettingsActionType.SET_CAMERA_SETTINGS: {
      return {
        ...state,
        cam1: action.payload.cam1,
        cam2: action.payload.cam2 ? action.payload.cam2 : null
      };
    }
    case CameraSettingsActionType.SET_CAMERA_IMAGE: {
      return {
        ...state,
        [action.payload.camKey]: {
          ...state[action.payload.camKey],
          img: action.payload.img
        }
      };
    }
    case CameraSettingsActionType.SET_SPOTS: {
      return {
        ...state,
        [action.payload.camKey]: {
          ...state[action.payload.camKey],
          spots: action.payload.spots
        }
      };
    }
    case CameraSettingsActionType.SET_ONE_CAM_SETTINGS: {
      return {
        ...state,
        [action.payload.camKey]: {
          ...state[action.payload.camKey],
          settings: action.payload.settings
        }
      };
    }
    default: {
      return state;
    }
  }
}
