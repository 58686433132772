import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { IStation } from '../../../../core/models/stations';
import { selectNavigationStation } from '../../../../core/reducers';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import * as fromNavigationStation from '../../../../core/reducers/navigation-station';

@Component({
  selector: 'app-ultrasonic-distance-settings-sketches',
  templateUrl: './ultrasonic-distance-settings-sketches.component.html',
  styleUrls: ['./ultrasonic-distance-settings-sketches.component.scss']
})
export class UltrasonicDistanceSettingsSketchesComponent implements OnInit, OnDestroy {

  public flag                     : string;
  private destroy$                : Subject<boolean> = new Subject<boolean>();

  constructor(
    private navigationStore       : Store<fromNavigationStation.INavigationStationState>
  ) { }

  public ngOnInit(): void {

    this.navigationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
      filter((s: IStation) => !!s)
    ).subscribe((station: IStation) => {
      if (station.flags.nmetosfwt) {
        this.flag = 'nmetosfwt';
      } else if (station.flags.waterlevel) {
        this.flag = 'waterlevel';
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
