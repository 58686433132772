<form class="company-info-form"
      [formGroup]="form">

  <div class="company-info-form__header">
    {{ 'COMPANY INFO' | translate }}
  </div>

  <div class="company-info-form__item">
    <app-form-input [placeholder]="'Enter Department'"
                    [formControlName]="'department'"></app-form-input>
  </div>

  <div class="company-info-form__item">
    <app-form-input [placeholder]="'Enter Company Name'"
                    [formControlName]="'name'"></app-form-input>
  </div>

  <div class="company-info-form__item">
    <app-form-input [placeholder]="'Enter Profession'"
                    [formControlName]="'profession'"></app-form-input>
  </div>

</form>
