import {IOptions} from '../../shared/interfaces';
import {IBaseCameraSettingsState} from '../../shared/camera/models/camera';
import {IIscoutSettingsState} from '../../modules/iscout/models/iscout.models';

export const WIDE_CAMM_ID = 1;
export const ZOOM_CAMM_ID = 2;
export const DEFAULT_CAMM_ID = 0;

export const DefaultPeriodScope: IOptions = {
  value: '1m',
  content: 'month',
};

export const WeekPeriodScope: IOptions = {
  value: '1w',
  content: 'week',
};

export const CameraPeriodScopes: Array<IOptions> = [
  DefaultPeriodScope,
  WeekPeriodScope,
];

export const IScoutGlueBoardPeriodScope: IOptions = {
  value: 'glue-board-interval',
  content: 'Glue Board',
};

export const IScoutSeasonPeriodScope: IOptions = {
  value: 'season-interval',
  content: 'Season'
};

export const IScoutCameraPeriodMonitoringScopes: Array<IOptions> = [
  IScoutGlueBoardPeriodScope,
  IScoutSeasonPeriodScope
];

export const initialIscoutPicturesState: IIscoutSettingsState = {
  selectedPicture: null,
  currentDateString: null,
  toolbarSettings: {
    activity: {
      areMeasurementsActive: true,
      isHelpActive: false,
      isSaveInfoActive: true,
      isUpdateInfoActive: true,
    },
    settings: {
      periodScope: DefaultPeriodScope.value,
      fromTo: null,
      datepicker: null,
      stationId: null,
      isLastDataMode: true
    }
  },
  pestTogglers: []
};

export const initialCameraPicturesState: IBaseCameraSettingsState = {
  selectedPicture: null,
  currentDateString: null,
  toolbarSettings: {
    activity: {
      areMeasurementsActive: true,
      isHelpActive: false,
      isSaveInfoActive: true,
      isUpdateInfoActive: true,
    },
    settings: {
      periodScope: DefaultPeriodScope.value,
      fromTo: null,
      datepicker: null,
      stationId: null,
      isLastDataMode: true
    }
  },
  drawingOptions: {
    showCircleScale: false,
    showCircleMeasurement: false,
    showUnmarked: true,
    showMarked: true
  }
};
