import { IActionWithPayload, IActionWithPayloadRemoveField } from './../models/actionWithPayload';
import { IAddField, IField, IAddFarmField } from './../models/fields';
import { ICropZone } from '../models/cropzones';

export const enum FieldActionTypes {
    ADD_FIELD = '[Account] ADD_FIELD',
    SET_FIELDS = '[Account] SET_FIELDS',
    SET_FIELD = '[Account] SET_FIELD',
    GET_FIELDS = '[Account] GET_FIELDS',
    ADD_FARM_FIELD = '[Account] ADD_FARM_FIELD',
    DELETE_FIELD = '[Account] DELETE_FIELD',
    REMOVE_FIELD = '[Account] REMOVE_FIELD',
    REMOVE_FIELD_WITHOUT_CROPZONES = '[Account] REMOVE_FIELD_WITHOUT_CROPZONES'
}

export function addFarmField(add: IAddFarmField): IActionWithPayload {
    return { type: FieldActionTypes.ADD_FARM_FIELD, payload: add};
}

export function addField(add: IAddField): IActionWithPayload {
    return { type: FieldActionTypes.ADD_FIELD, payload: add};
}

export function setFields(data: Array<IField>): IActionWithPayload {
    return { type: FieldActionTypes.SET_FIELDS, payload: data};
}
export function setField(data: IField): IActionWithPayload {
    return { type: FieldActionTypes.SET_FIELD, payload: data};
}

export function getFields(): IActionWithPayload {
    return { type: FieldActionTypes.GET_FIELDS, payload: null};
}

export function removeField(remove: IField, cropzones: ICropZone[]): IActionWithPayloadRemoveField {
    return { type: FieldActionTypes.REMOVE_FIELD, payload: remove, cropzones: cropzones};
}

export function removeFieldWithoutCropzones(remove: IField): IActionWithPayload {
    return { type: FieldActionTypes.REMOVE_FIELD_WITHOUT_CROPZONES, payload: remove };
}

export function deleteField(id: string): IActionWithPayload {
    return { type: FieldActionTypes.DELETE_FIELD, payload: id};
}

