<form class="address-info-form"
      [formGroup]="form">

  <div class="address-info-form__header">
    {{ 'ADDRESS INFO' | translate }}
  </div>

  <div class="address-info-form__item">
    <app-form-input [placeholder]="'Enter City'"
                    [formControlName]="'city'"></app-form-input>
  </div>

  <div class="address-info-form__item">
    <app-form-select [options]="countries"
                     [icon]="'country'"
                     [type]="'border'"
                    [formControlName]="'country'"></app-form-select>
  </div>

  <div class="address-info-form__item">
    <app-form-input [placeholder]="'Enter District'"
                    [formControlName]="'district'"></app-form-input>
  </div>

  <div class="address-info-form__item">
    <app-form-input [placeholder]="'Enter Street'"
                    [formControlName]="'street'"></app-form-input>
  </div>

  <div class="address-info-form__item">
    <app-form-input [placeholder]="'Enter Zip'"
                    [formControlName]="'zip'"></app-form-input>
  </div>

</form>
