import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ISettings } from '../../../../core/models/account';
import { selectSettings } from '../../../../core/reducers';
import * as fromAccount from '../../../../core/reducers/account';
import { LegendValues, SampleMarkers } from '../../../../shared/constants';
import { SampleDeviceService } from '../../../../shared/services/sample-device/sample-device.service';

@Component({
  selector: 'app-sample-data-map',
  templateUrl: './sample-data-map.component.html',
  styleUrls: ['./sample-data-map.component.scss']
})
export class SampleDataMapComponent implements OnInit, OnDestroy {

  @Output()
  public navigate = new EventEmitter<{ activeDevice: string, activeSample: number, source: string }>();

  @Input()
  public lengend;

  @Input()
  public activeMeter;

  @Input()
  public set data(values) {
    this.devices = values;
    this.dataReady = false;
    this.hasPosition = false;

    values.map(device => {
      const active = device.some(_ => _.hasOwnProperty('longitude'));
      if (active) {
        // break the cycle
        return this.hasPosition = active;
      }
    });

    if (!values.length) {
      this.dataReady = true;
      this.zoom = null;
    }
  }

  @Input()
  public set cropzone(values) {

    this.zoom = null;

    if (values) {
      this.paths = values.boundary.coordinates[0].map(([lng, lat]) => ({ lng, lat }));
    }
  }

  @Input()
  public activeSample = null;

  @Input()
  public activeDevice = null;

  public dataReady = false;
  public devices;
  public hasPosition = false;
  public sampleMarkers = SampleMarkers;
  public legendValues = LegendValues;
  private alive$ = new Subject<void>();
  public lat: number = 45;
  public lng: number = 15;
  public zoom: number;
  public unitSystem = 'metric';
  public styles = [{
    featureType: 'all',
    elementType: 'labels',
    stylers: [
      { visibility: 'off' }
    ]
  }];

  public paths;

  constructor(
    private accountStore: Store<fromAccount.IAccount>,
    private sampleDeviceService: SampleDeviceService
  ) {

  }

  public ngOnInit(): void {

    this.accountStore.pipe(
      select(selectSettings),
      takeUntil(this.alive$),
      filter(_ => Boolean(_)),
    ).subscribe((settings: ISettings) => {
      this.unitSystem = settings.unit_system;
    });
  }

  public getMarker(index, sample, device): string {
    if (
      index === this.activeSample &&
      device === this.activeDevice &&
      sample.hasOwnProperty(this.activeMeter)
    ) {
      return this.sampleDeviceService.getActiveVector(sample[this.activeMeter]);
    } else {
      return sample.marker;
    }
  }

  public setMapSelection(activeSample: number, activeDevice: string): void {
    this.activeDevice = activeDevice;
    this.activeSample = activeSample;

    this.navigate.emit({
      activeDevice,
      activeSample,
      source: null
    });
  }

  public updateZoom(data): void {
    this.zoom = data;
  }

  public ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }
}
