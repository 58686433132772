import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-iscout-header',
  templateUrl: './iscout-header.component.html',
  styleUrls: ['./iscout-header.component.scss']
})
export class IscoutHeaderComponent {
  @Input()
  public title: string;
  @Input()
  public subtitle: string = '';
  @Input()
  public enableHelpButton: boolean = false;

  @Output()
  public openModal: EventEmitter<void> = new EventEmitter<void>();

  public handleOpenModal(): void {
    this.openModal.emit();
  }
}
