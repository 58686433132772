<app-form-layout class="server-alerts-form">
  <app-card-header [header]="'New alert'"
                   [helpButton]="true"
                   [description]="'Setup a new alert'">
  </app-card-header>

  <div *ngIf="!sensors.length && !isLoading" class="server-alerts__warnings">{{ 'No sensors' | translate }}</div>
  <div *ngIf="isLoading" class="server-alerts__warnings">{{ 'Loading sensors...' | translate }}</div>

  <form [formGroup]="alertForm" *ngIf="alertForm && sensors.length">
    <div *ngIf="!isLoading" class="server-alerts-form__content" style="padding: 10px 30px 30px;">

      <div class="col-md-6">
        <table class="server-alerts__table" style="width: 100%">
          <tbody class="server-alerts__table__body">

          <tr>
            <th>{{ 'Warning at' | translate }}</th>
            <td>
              <app-form-select size="small"
                               type="border"
                               [options]="alertTypes"
                               formControlName="type">
              </app-form-select>
            </td>
          </tr>

          <tr>
            <th>{{ 'Sensor' | translate }}</th>
            <td>
              <app-form-select size="small"
                               type="border"
                               [options]="sensorOptions"
                               formControlName="sensor">
              </app-form-select>
            </td>
          </tr>

          <tr>
            <th>{{ 'Thresholds' | translate }}</th>
            <td>
              <div class="input-group">
                <input type="text"
                       appWarningSensor
                       appTooltip
                       tooltipWidth="200px"
                       tooltipColor="red"
                       [tooltipDisable]="threshold.valid || threshold.pristine"
                       [tooltipTitle]="formatError"
                       [ngStyle]="{ 'background-color': '#fffffff', 'border-color': threshold.invalid && threshold.dirty ? 'red' : '' }"
                       class="form-control server-alerts-td__control form-input"
                       [placeholder]="('Thresholds' | translate) + ' (e.g. 4, -5, 22)'"
                       formControlName="threshold">
                <span class="input-group-addon">{{ optionUnit }}</span>
              </div>
            </td>
          </tr>

          <tr>
            <th>{{ 'Resend after' | translate }}</th>
            <td>
              <app-form-select size="small"
                               type="border"
                               [options]="resendOptions"
                               formControlName="resend">
              </app-form-select>
            </td>
          </tr>
          <tr formGroupName="email">
            <th>{{ 'Email (optional)' | translate }}</th>
            <td>
              <app-form-input formControlName="destination"
                              class="server-alerts-td__control"
                              size="small"
                              appWarningSensor
                              appTooltip
                              tooltipWidth="200px"
                              tooltipColor="red"
                              [tooltipDisable]="email.valid"
                              [tooltipTitle]="emailError"
                              [styles]="{ 'background-color': '#fffffff', 'border-color': email.invalid ? 'red' : '' }"
                              placeholder="user@example.com"
                              type="border">
              </app-form-input>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="col-md-6">
        <table class="server-alerts__table">
          <thead>
          <tr style="text-transform: uppercase;">
            <th>{{ 'Alert channel' | translate }}</th>
            <th>{{ 'Active' | translate }}</th>
          </tr>
          </thead>

          <tbody class="server-alerts__table__body">
<!--          hidden web notifications-->
<!--          <tr formGroupName="push_web">-->
<!--            <td>{{ 'Web notification' | translate }}</td>-->
<!--            <td>-->
<!--              <app-yes-no-toggle formControlName="destination"></app-yes-no-toggle>-->
<!--            </td>-->
<!--          </tr>-->
          <tr formGroupName="push_mobile">
            <td>{{ 'Mobile notification' | translate }}</td>
            <td>
              <app-yes-no-toggle formControlName="destination"></app-yes-no-toggle>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <div class="alert alert-info server-alerts-list__alert" style="margin-top: 30px;">
                {{ 'We do not guarantee, represent or warrant that your use of this service will be uninterrupted, timely, secure or error-free.' | translate }}
                <br><br>
                {{ 'You expressly agree that your use of, or inability to use, the service is at your sole risk.' | translate }}
                <a *ngIf="subDomain?.subDomain !== 'telus'"
                  href="https://metos.at/terms-of-use" target="_blank" style="color: purple">{{ 'See our terms of use.' | translate}}</a>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <hr class="server-alerts-save__hr">
      <div class="server-alerts-save__content">
        <app-custom-button color="red"
                           [disabled]="alertForm.invalid"
                           (click)="save()"
                           class="server-alerts-save__save"
                           content="Save new alert">
          <i class="zmdi zmdi-refresh zmdi-hc-fw"></i>
        </app-custom-button>
      </div>
    </div>
  </form>
</app-form-layout>
