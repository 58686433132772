import { ValidatorFn, FormControl } from '@angular/forms';
import { ICropZone } from '../../../core/models/cropzones';
import { Injectable } from '@angular/core';
import { ICrop } from '../../../core/models/cropzones';

@Injectable({
  providedIn: 'root'
})
export class IrrimetService {

  constructor() {

  }

  public hydrateCrop(id: string, event: any, unitSystem: string): ICrop {
    if (unitSystem === 'metric') {
      return {
        id,
        emergence: event.kc[0][0].toISOString(),
        fao56: {
          K_ini: event.kc[0][1],
          K_mid: event.kc[2][1],
          K_end: event.kc[4][1],
          L_ini: event.kc[1][0].toISOString(),
          L_dev: event.kc[2][0].toISOString(),
          L_mid: event.kc[3][0].toISOString(),
          L_late: event.kc[4][0].toISOString(),
          R_ini: event.r[0][1] * -1,
          R_mid: event.r[2][1] * -1,
          R_end: event.r[4][1] * -1
        }
      };
    } else if (unitSystem === 'imperial') {
      return {
        id,
        emergence: event.kc[0][0].toISOString(),
        fao56: {
          K_ini: event.kc[0][1],
          K_mid: event.kc[2][1],
          K_end: event.kc[4][1],
          L_ini: event.kc[1][0].toISOString(),
          L_dev: event.kc[2][0].toISOString(),
          L_mid: event.kc[3][0].toISOString(),
          L_late: event.kc[4][0].toISOString(),
          R_ini: event.r[0][1] / 3.28084 * -1,
          R_mid: event.r[2][1] / 3.28084 * -1,
          R_end: event.r[4][1] / 3.28084 * -1
        }
      };
    }
  }

  public isDefaultCrop(event: any, selectedCrop: any): boolean {
    return event.kc[0][1] === selectedCrop.K_ini
      && event.kc[2][1] === selectedCrop.K_mid
      && event.kc[4][1] === selectedCrop.K_end
      && event.r[0][1] * -1 === selectedCrop.R_ini
      && event.r[1][1] * -1 === selectedCrop.R_ini
      && event.r[2][1] * -1 === selectedCrop.R_mid
      && event.r[4][1] * -1 === selectedCrop.R_end;
  }

  public createEmptyCropzone(): ICropZone {
    return {
      name: '',
      crop_name: '...',
      from: '',
      to: '',
      field: {
        name: '',
        id: ''
      },
      farm: {
        name: '',
        id: ''
      },
      id: '',
      year: '',
      boundary: null,
      soil: null,
      crop: null,
      rain_efficacies: [],
      data_sources: [],
      irrigation_events: []
    };
  }

  public customRefillPointValidator: ValidatorFn = (fc: FormControl) => {
    if (fc.parent) {
      const refillPoint = parseInt(fc.value, 10);
      const parent = fc.parent;
      const fieldCapacity = parseInt(parent.controls['field_capacity'].value, 10);

      if (fieldCapacity > refillPoint) {
        parent.controls['field_capacity'].setErrors(null);
        return null;
      } else {
        return {refillPointIsLargerThanFieldCapacity: true};
      }
    } else {
      return null;
    }
  }

  public customFieldCapacityValidator: ValidatorFn = (fc: FormControl) => {
    if (fc.parent) {
      const fieldCapacity = parseInt(fc.value, 10);
      const parent = fc.parent;
      const refillPoint = parseInt(parent.controls['refill_point'].value, 10);

      if (fieldCapacity > refillPoint) {
        parent.controls['refill_point'].setErrors(null);
        return null;
      } else {
        return {refillPointIsLargerThanFieldCapacity: true};
      }
    } else {
      return null;
    }
  }
}
