<div *ngIf="IsImagesAvailability;else empty" class="img-container">
  <div *ngFor="let item of data" class="img-container_item">
    <code>{{item.time}}</code>
    <img [attr.src]="item.thumb"
         (click)="selectedImage = item">
  </div>
</div>

<ng-template #empty>
  <app-widget-info>
    <h4><i class="fa fa-warning fa-fw"></i> {{ 'No images available' | translate}}</h4>
  </app-widget-info>
</ng-template>

<app-modal [modalId]="modalId"
            [size]="'lg'">
  <app-image-modal [image]="selectedImage"
                   (close)="closeModal()"></app-image-modal>
</app-modal>
