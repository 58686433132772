import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { from, Observable, EMPTY } from 'rxjs';
import { catchError, switchMap, exhaustMap, map } from 'rxjs/operators';
import { IActionWithPayload } from '../../../core/models/actionWithPayload';
import { ApiCallService } from '../../../services/api/api-call.service';
import { addDuplicatedCropzones, getCropZones, updateDuplicatedCropzones } from '../../../core/actions/cropzones';
import { setNotify } from '../../../core/actions/notify';
import { getFields } from '../../../core/actions/fields';
import { getFarms } from '../../../core/actions/farms';
import { CropzoneManagementActionTypes, setManagementCropsLazyLoading, showInfoModal } from '../actions/cropzoneManagement';


@Injectable()
export class CropzoneManagementEffects {
    constructor(
        private actions$: Actions,
        private api: ApiCallService
    ) {

    }

    @Effect()
    public renewCropzones: Observable<IActionWithPayload> = this.actions$.pipe(
        ofType(CropzoneManagementActionTypes.RENEW_CROPZONES),
        switchMap((action: IActionWithPayload) => this.api.renewCropzones(action.payload).pipe(
            switchMap((res) => from([
                addDuplicatedCropzones(res),
                showInfoModal([]),
                setNotify('Cropzones successfully duplicated')
            ])),
            catchError(() => from([]))
        ))
    );

    @Effect()
    public saveRename$: Observable<IActionWithPayload> = this.actions$.pipe(
        ofType(CropzoneManagementActionTypes.SAVE_CROPZONE_NAME),
        switchMap((action: IActionWithPayload) => this.api.saveRenamedCropzoneName(action.payload).pipe(
            switchMap(() => from([
                getCropZones(),
                setNotify('Cropzone successfully updated')
            ])),
            catchError(() => from([]))
        ))
    );

    @Effect()
    public saveFarmName$: Observable<IActionWithPayload> = this.actions$.pipe(
        ofType(CropzoneManagementActionTypes.SAVE_FARM_NAME),
        switchMap((action: IActionWithPayload) => this.api.saveRenamedFarmNameFromFarm(action.payload).pipe(
            switchMap(() => from([
                getFarms(),
                getFields(),
                getCropZones(),
                setNotify('Farm successfully updated')
            ])),
            catchError(() => from([]))
        ))
    );

    @Effect()
    public getManagementCropsLazyLoading$ = this.actions$.pipe(
        ofType(CropzoneManagementActionTypes.GET_MANAGEMENT_CROPS_LAZY_LOADING),
        exhaustMap(() => this.api.getCrops().pipe(
            map((crops) => setManagementCropsLazyLoading(crops)),
            catchError(() => EMPTY)
        ))
    );

    @Effect()
    public updateDuplicatedCropzonesAPI$ = this.actions$.pipe(
        ofType(CropzoneManagementActionTypes.UPDATE_DUPLICATED_CROPZONES),
        switchMap((action: IActionWithPayload) => this.api.updateMultiple(action.payload).pipe(
            switchMap(() => {
                return from([
                    updateDuplicatedCropzones(action.payload),
                    showInfoModal([]),
                    setNotify('Duplicated cropzones successfully updated.')
                ]);
            }),
            catchError(() => EMPTY)
        ))
    );

    @Effect()
    public saveFieldName$: Observable<IActionWithPayload> = this.actions$.pipe(
        ofType(CropzoneManagementActionTypes.SAVE_FIELD_NAME),
        switchMap((action: IActionWithPayload) => this.api.saveRenamedFieldFromField(action.payload).pipe(
            switchMap(() => {
                return from([
                    getFields(),
                    getCropZones(),
                    setNotify('Field successfully updated')
                ]);
            }),
            catchError(() => from([]))
        ))
    );
}
