import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IOptions } from '../interfaces';
import { deepClone } from '../utils/deepClone';

@Component({
  selector: 'app-custom-dropdown',
  templateUrl: './custom-dropdown.component.html',
  styleUrls: ['./custom-dropdown.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomDropdownComponent),
    multi: true
  }]
})

export class CustomDropdownComponent implements ControlValueAccessor, OnChanges {
  @Input()
  public color                      : string = 'blue';
  @Input()
  public width                      : string = 'auto';
  @Input()
  public options                    : Array<IOptions> = [];
  @Input()
  public staticContent              : string = '';
  @Input()
  public currentValue               : string = '';
  @Input()
  private isSetDefault              : boolean = true;
  @Input()
  public disabled                   : boolean = false;
  @Input()
  public placeholder                : string = 'Search..';
  @Input()
  public searchable                 : boolean = true;
  public currentContent             : string = '';
  public isOpen                     : boolean = false;

  public filteredOptions            : Array<IOptions> = [];
  public searchTerm                 : string = '';

  private propagateChange           : any = () => { return; };

  public toggleOpen(): void {
    this.isOpen = !this.isOpen;
  }

  public close(): void {
    this.isOpen = false;
    this.searchTerm = '';
    this.filteredOptions = deepClone(this.options);
  }

  public onChange(option: IOptions): void {
    this.currentValue = option.value;
    this.currentContent = option.content;
    this.propagateChange(this.currentValue);
  }

  public writeValue(value: string): void {
    this.currentValue = value;
    this.setContent();
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
    this.setContent();
  }

  public registerOnTouched(fn: any): void {
    return;
  }

  public setDisabledState(isDisabled: boolean): void {
    return;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.filteredOptions = deepClone(this.options);
    }
    this.setContent();
  }

  public stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  public filterOptions(): void {
    if (this.searchTerm === '') {
      this.filteredOptions = deepClone(this.options);
    } else {
      this.filteredOptions = deepClone(this.options)
      .filter((option: IOptions) => option.content.toLowerCase().includes(this.searchTerm.toLowerCase()));
    }
  }

  public resetDropdown(): void {
    this.searchTerm = '';
    this.filteredOptions = deepClone(this.options);
  }

  protected setContent(): void {
    if (this.options.length < 1) {
      return;
    }
    const option: IOptions = this.options.find((o) => o.value === this.currentValue);
    if (option) {
      this.currentContent = option.content;
    } else if (this.propagateChange && this.isSetDefault) {
      this.currentValue = this.options[0].value;
      this.currentContent = this.options[0].content;
      this.propagateChange(this.currentValue);
    }
  }
}
