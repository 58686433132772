<div>
  <div class="modal-header">
    <button aria-label="Close" class="close" type="button" (click)="handleCancel()">
      <span aria-hidden="true">×</span>
    </button>
    <h3 class="modal-title text-center">{{ 'Delete iScout season' | translate }}</h3>
  </div>
  <div class="modal-body">
    <div class="body-text">{{ 'Are you sure you want to delete the selected season?' | translate }}</div>
    <div class="button-wrapper">
      <div class="button">
        <app-custom-button [color]="'blue'"
                           [content]="'Cancel' | translate"
                           (click)="handleCancel()"></app-custom-button>
      </div>
      <div class="button">
        <app-custom-button [color]="'red'"
                           [content]="'Delete' | translate"
                           (click)="handleConfirm()">
          <i class="fa fa-fw fa-trash"></i>
        </app-custom-button>
      </div>
    </div>
  </div>
</div>
