<ng-container [formGroup]="alertForm" *ngIf="alertForm">
  <td class="first">{{ alert.sensorChannel }}</td>
  <td>{{ alert.sensorCode }}</td>
  <td>{{ aggrType[alert.aggr] }}</td>
  <td *ngIf="sensorData && sensorData.name">{{ sensorData.name }}</td>
  <td>{{ alert.source }}</td>
  <td>
    <app-form-select formControlName="type"
                     size="small"
                     type="border"
                     [options]="alertTypes">
    </app-form-select>
  </td>
  <td>
    <app-form-input formControlName="threshold"
                    contentType="text"
                    class="server-alerts-td__control"
                    size="small"
                    appWarningSensor
                    appTooltip
                    tooltipPlacement="top"
                    tooltipWidth="200px"
                    tooltipColor="red"
                    [tooltipDisable]="threshold.valid"
                    [tooltipTitle]="formatError"
                    [styles]="{ 'background-color': '#fffffff', 'border-color': threshold.invalid ? 'red' : '' }"
                    [placeholder]="('Thresholds' | translate) + ' (e.g. 4, -5, 22)'"
                    type="border">
    </app-form-input>
  </td>
  <td *ngIf="sensorData && sensorData.unit">{{ sensorData.unit }}</td>
  <td style="white-space: pre-line">
    <div class="flexContainer">
      <div *ngIf="emailTargetActive && this.tooltipText"
        class="targetDiv"
        appTooltip
        [tooltipPlacement]="'bottom'"
        [tooltipTitle]="this.tooltipText"
        appOutClick>
          Email,
      </div>
      <div *ngIf="emailTargetActive && !this.tooltipText" class="targetDiv">
        Email,
      </div>
      <div class="targetDiv" *ngIf="mobileTargetActive">
        Mobile
      </div>
    </div>
  </td>
  <td>
    <app-form-select formControlName="resend"
                     [options]="resendOptions"
                     type="border"
                     size="small">
    </app-form-select>
  </td>
  <td><app-yes-no-toggle formControlName="active"></app-yes-no-toggle></td>
  <td>
    <button type="button"
            [disabled]="threshold.invalid"
            class="btn btn-sm btn-warning"
            (click)="updateAlert()">
      {{ 'Update' | translate}}
    </button>
    <button type="button"
            class="btn btn-sm btn-danger"
            (click)="removeAlert()">
      {{ 'Delete' | translate }}
    </button>
  </td>
</ng-container>
