import * as fromNavigationCropzone from '../../reducers/navigation-cropzone';
import { INavigationCropzoneState } from '../../reducers/navigation-cropzone';
import { Injectable, OnDestroy } from '@angular/core';
import { ApiCallService } from '../../../services/api/api-call.service';
import { IStation } from '../../models/stations';
import * as fromSelectedStation from '../../reducers/selectedStation';
import * as fromSelectedCropzone from '../../reducers/selectedCropZone';
import * as fromNavigationStation from '../../reducers/navigation-station';
import { INavigationStationState } from '../../reducers/navigation-station';
import * as fromCropzones from '../../reducers/cropzones';
import { select, Store } from '@ngrx/store';
import {
  selectActiveCropzoneRoute,
  selectAvailableLicenses,
  selectCropzones,
  selectNavigationCropzone,
  selectNavigationStation,
  selectSelectedCropZone,
  selectSelectedStation,
  selectStations
} from '../../reducers';
import { ISelectedSearchWidgetItem } from '../../models/selectedSearchWidgetItem';
import { INavigationAccess, IStationBasicInfo } from '../../models/navigation-station';
import * as fromStations from '../../reducers/stations';
import {
  READ_AND_WRITE_PERMISSION,
  StationTypes,
  DevicesWithServerAlertSupport,
  DevicesWithSMSAlertSupport
} from '../../../shared/constants';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { setNavigationStation } from '../../actions/navigation-station';
import { setNavigationCropzone } from '../../actions/navigation-cropzone';
import { setSelectedStation } from '../../actions/selectedStation';
import { setSelectedCropZone } from '../../actions/selectedCropZone';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TreeSettingsActionTypes } from '../../../shared/tree/actions/tree-settings';
import { deepClone } from '../../../shared/utils/deepClone';
import { defaultNavigation } from '../../constants/default-navigation';
import { defaultCropzone } from '../../constants/default-cropzone';
import {
  ANIMAL_PRODUCTION_TREE_NODES,
  WORK_PLANNING_TREE_NODES,
  WP_GRASS_FIRE_DANGER_INDEX_SERVICE,
  WP_TROPICAL_SOWING_WINDOW_SERVICE,
} from '../../../services/tree/constants';
import { ICropZone } from '../../models/cropzones';
import * as fromLicenses from '../../reducers/licenses';
import * as fromSensors from '../../../modules/soil-moisture-config/reducers/sensors';
import {
  buildMenuFromTreeNodes,
  buildMenuFromTreeSubNodes
} from '../../../services/tree/models';

@Injectable({
  providedIn: 'root'
})
export class NavigationService implements OnDestroy {
  private idFromSelectedStation   : string = '';
  private idFromSelectedCropzone  : string = '';
  private isGetSimpleStation      : boolean = false;
  private isGetSimpleCropzone     : boolean = false;
  private stations                : Array<IStation> = [];
  private station                 : IStation;
  private cropzones               : Array<ICropZone> = [];
  private selectedCropzone        : ICropZone;
  private cropzone                : ICropZone;
  private notFound                : boolean = false;
  private activeCropzoneRoute     : string = '';
  private activeLicenses          : any[] = [];
  public sensorsList              : Array<any> = [];
  public accessHydroponics        : boolean = false;

  private readonly pathWithoutId  : Array<string> = [
    '/dashboard',
    '/user-settings',
    '/user-api-services',
    '/user-api-services/field-climate',
    '/user-api-services/my-john-deere',
    // '/user-api-services/farm-beats',
    '/user-api-services/xarvio',
    '/user-api-services/davis',
    '/user-licenses',
    '/notifications-history',
    '/notifications-configuration',
    '/user-support',
    '/product-activation',
    '/cropzone/management',
    '/station/data-upload'
  ];

  private cropViewAndIscoutNotAvailablePath: Array<string> = [
    '/weather-forecast',
    '/work-planning',
    '/animal-production',
    '/disease-models',
    '/soil-moisture',
    '/sensor-names',
    '/sms-warnings',
    '/server-alerts',
    '/correct-precipitation-data',
    '/ultrasonic-distance-settings'
  ];

  private readonly weatherForecastPath: Array<string> = [
    '/weather-forecast/data',
    '/weather-forecast/meteogram_one',
    '/weather-forecast/meteogram_agro',
    '/weather-forecast/pictoprint',
    '/weather-forecast/meteogram_14day',
    '/weather-forecast/satellite_image',
    '/weather-forecast/history-climate'
  ];

  private readonly workPlanningPaths: Array<string> = buildMenuFromTreeNodes(
    '/work-planning',
    WORK_PLANNING_TREE_NODES
  );

  private readonly animalProductionPaths: Array<string> = buildMenuFromTreeNodes(
    '/animal-production',
    ANIMAL_PRODUCTION_TREE_NODES
  );

  private readonly cropViewNotAvailablePath: Array<string> = [
    '/iscout',
    '/tracker',
    '/samples/dualex',
    '/samples/soil-guard',
    '/samples/mobilab'
  ].concat(this.weatherForecastPath, this.cropViewAndIscoutNotAvailablePath);

  private readonly iscoutNotAvailablePath: Array<string> = [
    '/cropview',
    '/tracker',
    '/samples/dualex',
    '/samples/soil-guard',
    '/samples/mobilab'
  ].concat(this.weatherForecastPath, this.cropViewAndIscoutNotAvailablePath);

  private readonly iMetosNotAvailablePath: Array<string> = [
    '/cropview',
    '/iscout',
    '/camera-data',
    '/camera-slideshow',
    '/tracker',
    '/samples/dualex',
    '/samples/soil-guard',
    '/samples/mobilab'
  ];

  private readonly cropzonePaths: Array<string> = [
    '/config',
    '/irrimet-config',
    '/irrimet',
    '/irrimet/paw',
    '/irrimet/available-water-mm',
    '/soil-moisture-config',
    '/soil-moisture/sums-sm',
    '/sat/lai',
    '/sat/ndvi',
    '/yield-prediction-config',
    '/yield-prediction/prediction',
    '/yield-prediction/previous',
    '/cropzone-samples/nutrition',
    '/cropzone-samples/soilguard'
  ];

  private readonly settingsPath: Array<string> = [
    '/config',
    '/irrimet-config',
    '/sensor-names',
    '/sms-warnings',
    '/info',
    '/events',
    '/communication',
    '/delete-weather-data',
    '/correct-precipitation-data',
    '/machinery-settings',
    '/ultrasonic-distance-settings',
  ];

  private readonly samplesNotAvailablePath: string[] = [
    ...this.cropViewAndIscoutNotAvailablePath,
    ...this.workPlanningPaths,
    ...this.animalProductionPaths,
    ...this.weatherForecastPath,
    '/tracker',
    '/data',
    '/cropview',
    '/iscout',
    '/degree-days',
    '/config'
  ];

  private readonly dualexNotAvailablePath: string[] = [
    ...this.samplesNotAvailablePath,
    '/samples/soil-guard',
    '/samples/mobilab',
    '/correct-precipitation-data',
    '/ultrasonic-distance-settings'
  ];

  private readonly soilGuardNotAvailablePath: string[] = [
    ...this.samplesNotAvailablePath,
    '/samples/dualex',
    '/samples/mobilab',
    '/correct-precipitation-data',
    '/ultrasonic-distance-settings'
  ];

  private readonly mobilabNotAvailablePath: string[] = [
    ...this.samplesNotAvailablePath,
    '/samples/dualex',
    '/samples/soil-guard',
    '/correct-precipitation-data',
    '/ultrasonic-distance-settings'
  ];

  private readonly trackerNotAvailablePath: string[] = [
    ...this.cropViewAndIscoutNotAvailablePath,
    ...this.workPlanningPaths,
    ...this.animalProductionPaths,
    ...this.weatherForecastPath,
    '/data',
    '/cropview',
    '/iscout',
    '/samples/dualex',
    '/samples/soil-guard',
    '/samples/mobilab',
    '/correct-precipitation-data',
    '/ultrasonic-distance-settings'
  ];

  private invalidAccessPath: Array<string> = [];
  private readonly settingsDefaultPath: string = '/config';
  private readonly iMetosDefaultPath: string = '/data';
  private readonly iScoutDefaultPath: string = '/iscout';
  private readonly cropViewDefaultPath: string = '/cropview';
  private readonly trackerDefaultPath: string = '/tracker';
  private readonly cropzoneDefaultPath: string = '/cropzone';
  private readonly soilGuardDefaultPath: string = '/samples/soil-guard';
  private readonly dualexDefaultPath: string = '/samples/dualex';
  private readonly mobilabDefaultPath: string = '/samples/mobilab';

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private api                     : ApiCallService,
    private selectedStore           : Store<fromSelectedStation.ISelectedStationState>,
    private selectedCropzoneStore   : Store<fromSelectedCropzone.ISelectedCropZoneState>,
    private stationsStore           : Store<fromStations.IStations>,
    private cropzoneStore           : Store<fromCropzones.ICropZones>,
    private sensorsStore            : Store<fromSensors.ISensorsState>,
    private router                  : Router,
    private navigationStore         : Store<fromNavigationStation.INavigationStationState>,
    private navigationCropzoneStore : Store<fromNavigationCropzone.INavigationCropzoneState>,
    private licenseStore            : Store<fromLicenses.IProductLicenseState>
  ) {
    this.licenseStore.pipe(
      select(selectAvailableLicenses),
      filter((licenses) => !!licenses),
      takeUntil(this.destroy$)
    ).subscribe(licenses => {
      this.activeLicenses = licenses;
    });

    this.navigationStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationStation),
    ).subscribe((station: IStation) => {
      this.station = station;
    });

    this.navigationCropzoneStore.pipe(
      takeUntil(this.destroy$),
      select(selectNavigationCropzone)
    ).subscribe((cropzone: ICropZone) => {
      this.cropzone = cropzone;
    });

    this.selectedStore.pipe(
      takeUntil(this.destroy$),
      select(selectSelectedStation),
      filter((station: ISelectedSearchWidgetItem): boolean => !!station)
    ).subscribe((station: ISelectedSearchWidgetItem) => {
      if (station.original_name) {
        this.idFromSelectedStation = station.original_name;

        // get sensors to show or hide hydroponics
        this.api.getStationSensors(station.original_name).pipe(
          map((sensorList: Array<any>) => sensorList.filter((s: any) => s.code === 24608 || s.code === 19459)),
        ).subscribe(
          sensorList => {
            this.accessHydroponics = sensorList.length > 0;
            if (this.router.url.indexOf('hydroponics') > 0 && !this.accessHydroponics) {
              this.router.navigate(['.']);
            }
            if (this.station) {
              this.setAccess(this.station);
            }
          }
        );
      }
    });

    this.selectedCropzoneStore.pipe(
      takeUntil(this.destroy$),
      select(selectSelectedCropZone),
      filter((cropzone: ICropZone): boolean => !!cropzone)
    ).subscribe((cropzone: ICropZone) => {
      this.idFromSelectedCropzone = cropzone.id;
      this.selectedCropzone = cropzone;
    });

    this.stationsStore.pipe(
      takeUntil(this.destroy$),
      select(selectStations),
    ).subscribe((stations: Array<IStation>) => {
      this.stations = stations ? stations : [];
      if (!this.isGetSimpleStation && stations) {
        if (this.idFromSelectedStation) {
          const selectedStation = stations.filter(station => station.name.original === this.idFromSelectedStation)[0];
          if (selectedStation) {
            this.setNavigationStation(selectedStation);
          } else {
            this.setNavigationStation(stations ? stations[0] : null);
          }
        } else {
          this.setNavigationStation(stations ? stations[0] : null);
        }
      }
    });

    this.cropzoneStore.pipe(
      takeUntil(this.destroy$),
      select(selectCropzones),
    ).subscribe((cropzones: Array<ICropZone>) => {
      this.cropzones = cropzones ? cropzones : [];
    });

    this.cropzoneStore.pipe(
      takeUntil(this.destroy$),
      select(selectActiveCropzoneRoute)
    ).subscribe((activeCropzoneRoute: string) => {
      this.activeCropzoneRoute = activeCropzoneRoute;
    });

  }

  public get treeSetProfileAction(): string {
    let actionType: string = TreeSettingsActionTypes.SET_STATION_DATA_PROFILE;
    const group: any = this.router.createUrlTree([]).root.children;
    if (group.hasOwnProperty('primary') && group['primary'].segments.length) {
      // basically the url is parsed
      // stay attentive - the bug was found in the following line, I added the array field accessor ['path]
      // accessor was missing before that
      switch (group['primary'].segments[group['primary'].segments.length - 1]['path']) {
        case 'data': {
          actionType = TreeSettingsActionTypes.SET_STATION_DATA_PROFILE;
          break;
        }
        case 'soil-moisture': {
          actionType = TreeSettingsActionTypes.SET_SOIL_MOISTURE_PROFILE;
          break;
        }
        default: {
          break;
        }
      }
    }
    return actionType;
  }

  public get treeSetSettingsAction(): string {
    let actionType: string = TreeSettingsActionTypes.SET_STATION_DATA_TREE_SETTINGS;
    const group: any = this.router.createUrlTree([]).root.children;
    if (group.hasOwnProperty('primary') && group['primary'].segments.length) {
      // basically the url is parsed
      // stay attentive - the bug was found in the following line, I added the array field accessor ['path]
      // accessor was missing before that
      switch (group['primary'].segments[group['primary'].segments.length - 1]['path']) {
        case 'data': {
          actionType = TreeSettingsActionTypes.SET_STATION_DATA_TREE_SETTINGS;
          break;
        }
        case 'soil-moisture': {
          actionType = TreeSettingsActionTypes.SET_SOIL_MOISTURE_TREE_SETTINGS;
          break;
        }
        default: {
          break;
        }
      }
    }
    return actionType;
  }

  public get treeSetBatchSettingsAction(): string {
    let actionType: string = TreeSettingsActionTypes.SET_STATION_DATA_BATCH_TREE_SETTINGS;
    const group: any = this.router.createUrlTree([]).root.children;
    if (group.hasOwnProperty('primary') && group['primary'].segments.length) {
      // basically the url is parsed
      // stay attentive - the bug was found in the following line, I added the array field accessor ['path]
      // accessor was missing before that
      switch (group['primary'].segments[group['primary'].segments.length - 1]['path']) {
        case 'data': {
          actionType = TreeSettingsActionTypes.SET_STATION_DATA_BATCH_TREE_SETTINGS;
          break;
        }
        case 'soil-moisture': {
          actionType = TreeSettingsActionTypes.SET_SOIL_MOISTURE_BATCH_TREE_SETTINGS;
          break;
        }
        default: {
          break;
        }
      }
    }
    return actionType;
  }

  public get treeSetGroupProfileAction(): string {
    let actionType: string = TreeSettingsActionTypes.SET_DISEASE_GROUP_AND_PROFILE;
    const group: any = this.router.createUrlTree([]).root.children;
    if (group.hasOwnProperty('primary') && group['primary'].segments.length > 2) {
      const feature = group['primary'].segments[2]['path'];
      switch (feature) {
        case 'disease-models': {
          actionType = TreeSettingsActionTypes.SET_DISEASE_GROUP_AND_PROFILE;
          break;
        }
        case 'work-planning': {
          actionType = TreeSettingsActionTypes.SET_WORK_PLANNING_GROUP_AND_PROFILE;
          break;
        }
        case 'animal-production': {
          actionType = TreeSettingsActionTypes.SET_ANIMAL_PRODUCTION_PROFILE;
          break;
        }
        case 'weather-forecast': {
          actionType = TreeSettingsActionTypes.SET_WEATHER_FORECAST_PROFILE;
          break;
        }
        default: {
          break;
        }
      }
    }
    return actionType;
  }

  public changeStation(id: string): void {
    if (id) {
      if (this.stations.length) {
        this.setNavigationStation(this.find(id));
      } else {
        this.isGetSimpleStation = true;
        this.api.getStation(id).pipe(
          takeUntil(this.destroy$)
        ).subscribe((station: IStation) => {
          this.setNavigationStation(station);
          this.isGetSimpleStation = false;
        }, () => {
          this.isGetSimpleStation = false;
          this.setNavigationStation(this.find(id));
        });
      }
    } else if (this.stations.length) {
      this.setNavigationStation(this.find(this.idFromSelectedStation));
    }
  }

  public changeCropzone(id: string): void {
    if (id) {
      if (this.cropzones.length) {
        this.setNavigationCropzone(this.findCropzone(id));
      } else {
        this.isGetSimpleCropzone = true;
        this.api.getCropzone(id).pipe(
          takeUntil(this.destroy$)
        ).subscribe((cropzone: ICropZone) => {
          this.setNavigationCropzone(cropzone);
          this.isGetSimpleCropzone = false;
        }, () => {
          this.isGetSimpleCropzone = false;
          this.setNavigationCropzone(this.findCropzone(id));
        });
      }
    } else if (this.cropzones.length) {
      this.setNavigationCropzone(this.findCropzone(this.idFromSelectedCropzone));
    }
  }

  public navigateTo(id: string): void {
    this.router.navigate([this.prepareNavigateToUrl(id)]);
  }

  public prepareNavigateToUrl(id: string): string {
    const station: IStation = this.find(id);
    if (!station) {
      return '';
    }
    let url: string = `/station/${id}`;
    if (this.pathWithoutId.indexOf(this.router.url) !== -1) {

      switch (station.info.device_id) {
        case StationTypes.get('CropViewType3'):
        case StationTypes.get('CropViewType2'):
        case StationTypes.get('CropViewType1'):
          url += this.cropViewDefaultPath;
          break;
        case StationTypes.get('IScoutType'):
        case StationTypes.get('IScoutMobileType'):
          url += this.iScoutDefaultPath;
          break;
        case StationTypes.get('iMetos Active Tracker'):
        case StationTypes.get('iMetos Object Tracker'):
          url += this.trackerDefaultPath;
          break;
        default:
          url += this.iMetosDefaultPath;
          break;
      }
    } else {
      url += this.checkAvailable(this.find(id).info.device_id, this.prepareUrl());
    }
    return url;
  }

  public prepareNavigateToCropzoneUrl(id: string): string {
    const cropzone: ICropZone = this.findCropzone(id);
    if (!cropzone) {
      return '';
    }
    let url: string = `/crop-zones/${id}`;
    if (this.pathWithoutId.indexOf(this.router.url) !== -1) {
      url += this.checkAvailableCropzone(this.findCropzone(id).id, this.prepareUrl(), cropzone);
    } else {
      url += this.checkAvailableCropzone(this.findCropzone(id).id, this.prepareUrl(), cropzone);
    }
    return url;
  }

  public navigateToRainCorrector(id: string): void {
    const url: string = `/station/${id}/correct-precipitation-data`;
    this.router.navigate([url]);
  }

  public navigateToUltrasonicDistanceSettings(id: string): void {
    const url: string = `/station/${id}/ultrasonic-distance-settings`;
    this.router.navigate([url]);
  }

  public navigateToIrrimet(id: string): void {
    const url: string = `/cropzone/${id}/irrimet`;
    this.router.navigate([url]);
  }

  public navigateToTracker(id: string): void {
    const url: string = `/station/${id}/tracker`;
    this.router.navigate([url]);
  }

  public hasLicenseForPath(path: string, station: IStation): boolean {

    if (path.includes('weather-forecast')) {
      return !!station.licenses.Forecast;
    }

    if (path.includes('animal-production')) {
      return !!station.licenses.Forecast && !!station.licenses.AnimalProduction;
    }

    if (path.includes('work-planning')) {
      return !!station.licenses.Forecast && !!station.licenses.WorkPlanning;
    }

    return true;

  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private setNavigationStation(station: IStation): void {
    if (!station) {
      this.navigationStore.dispatch(setNavigationStation(deepClone(defaultNavigation)));
      this.selectedStore.dispatch(setSelectedStation({
        original_name: null
      }));
      this.router.navigate(['/dashboard']);
      return;
    }

    const navigationObject: INavigationStationState = {
      station: station,
      stationBasicInfo: this.setBasicInfo(station),
      navigationAccess: this.setAccess(station),
      notFound: this.notFound
    };

    this.navigationStore.dispatch(setNavigationStation(navigationObject));
    this.selectedStore.dispatch(setSelectedStation({
      original_name: station.name.original
    }));

    // allow query params in pathWithoutId paths (e.g. JD)
    const path = this.router.url.slice(0,
      this.router.url.indexOf('?') > 0 ?
        this.router.url.indexOf('?') :
        this.router.url.length
    );
    if (this.pathWithoutId.includes(path)) {
      return;
    }

    const url: string = this.prepareUrl();
    const checkUrl: string = this.checkAvailable(station.info.device_id, url);
    const navigateUrl: string = `/station/${station.name.original}${checkUrl}`;

    if (this.checkIfCropZoneRoute() && this.cropzonePaths.includes(url)) {
      this.router.navigate([this.router.url]);
    } else if (url !== checkUrl) {
      this.router.navigate([navigateUrl]);
    } else if (this.router.url !== navigateUrl) {
      this.router.navigate(['/dashboard']);
    }
  }

  private checkIfCropZoneRoute(): boolean {
    return this.router.url.split('/')[1] === 'cropzone';
  }

  private setNavigationCropzone(cropzone: ICropZone): void {
    if (!cropzone) {
      this.navigationCropzoneStore.dispatch(setNavigationCropzone(deepClone(defaultCropzone)));
      this.selectedCropzoneStore.dispatch(setSelectedCropZone({
        id: '',
        name: '',
        crop_name: '',
        field: {
          id: '',
          name: ''
        },
        farm: {
          id: '',
          name: ''
        },
        from: '',
        to: '',
        year: '',
        boundary: null,
        crop: null,
        soil: null,
        rain_efficacies: [],
        data_sources: [],
        irrigation_events: []
      }));
      this.router.navigate(['/dashboard']);
      return;
    }

    const navigationObject: INavigationCropzoneState = {
      cropzone: cropzone,
      notFound: this.notFound
    };

    this.navigationCropzoneStore.dispatch(setNavigationCropzone(deepClone(navigationObject)));
    this.selectedCropzoneStore.dispatch(setSelectedCropZone(deepClone(cropzone)));

    const url: string = this.prepareUrl();
    const checkUrl: string = this.checkAvailableCropzone(cropzone.id, url, cropzone);
    const urlToNavigate = '/cropzone/' + cropzone.id + checkUrl;

    this.router.navigate([urlToNavigate]);
  }

  private setAccess(station: IStation): INavigationAccess {
    this.invalidAccessPath = [];
    const access: INavigationAccess = {
      cropView: false,
      iScout: false,
      stationData: true,
      weatherForecast: false,
      workPlanning: false,
      tropicalSowingWindow: false,
      grassFireDangerIndex: false,
      yieldPrediction: false,
      animalProduction: false,
      accumulatorTool: false,
      frostProtection: true,
      diseaseModels: false,
      soilMoisture: false,
      hydroponics: false,
      settings: true,
      tracker: false,
      soilGuard: false,
      dualex: false,
      mobilab: false,
      ultrasonicDistanceSettings: false,
      settingsConfiguration: {
        sensorsAndNodes: false,
        warnings: false,
        serverAlerts: false
      },
    };
    if (station
      && (station.info.device_id === StationTypes.get('CropViewType1')
        || station.info.device_id === StationTypes.get('CropViewType2')
        || station.info.device_id === StationTypes.get('CropViewType3'))) {
      access.cropView = true;
      access.settingsConfiguration.serverAlerts = true;
    } else if (station.info.device_id === StationTypes.get('IScoutType')) {
      access.iScout = true;
      access.settingsConfiguration.serverAlerts = true;
    } else if (station.info.device_id === StationTypes.get('IScoutMobileType')) {
      access.iScout = true;
      access.stationData = false;
      access.settings = false;
    } else if (station.info.device_id === StationTypes.get('iMetos Active Tracker')
      || station.info.device_id === StationTypes.get('iMetos Object Tracker')
    ) {
      access.tracker = true;
      access.stationData = false;
    } else if (station.info.device_id === StationTypes.get('CameraNewControlUnit')) {
      access.soilMoisture = true;
      access.settingsConfiguration.sensorsAndNodes = true;
      access.settingsConfiguration.serverAlerts = true;
    } else if (station.info.device_id === StationTypes.get('SoilGuard Probe')) {
      access.soilGuard = true;
      access.stationData = false;
      access.settings = false;
    } else if (station.info.device_id === StationTypes.get('Dualex Device')) {
      access.dualex = true;
      access.stationData = false;
      access.settings = false;
    } else if (station.info.device_id === StationTypes.get('MobiLab Device')) {
      access.mobilab = true;
      access.stationData = false;
      access.settings = false;
    } else {
      access.stationData = true;
      access.soilMoisture = true;
      access.settingsConfiguration.sensorsAndNodes = true;
      access.hydroponics = this.accessHydroponics;

      if (station.hasOwnProperty('flags') &&
        (station.flags.nmetosfwt === true ||
          station.flags.waterlevel === true)) {
        access.ultrasonicDistanceSettings = true;
      } else {
        this.invalidAccessPath.push('/ultrasonic-distance-settings');
      }

      if (station.rights === READ_AND_WRITE_PERMISSION) {
        if (DevicesWithSMSAlertSupport.indexOf(station.info.device_id) !== -1) {
          access.settingsConfiguration.warnings = true;
        }
        if (DevicesWithServerAlertSupport.indexOf(station.info.device_id) !== -1) {
          access.settingsConfiguration.serverAlerts = true;
        }

      } else {
        this.invalidAccessPath.push('/sms-warnings');
        this.invalidAccessPath.push('/server-alerts');
      }
    }
    if (station.licenses) {
      if (
        !!station.licenses.models
        && !!station.licenses.models.length
        && station.licenses.models.length > 0
      ) {
        access.diseaseModels = true;
      } else {
        this.invalidAccessPath.push('/disease-models');
      }

      if (station.licenses.Forecast) {
        access.weatherForecast = station.licenses.Forecast;

        if (station.licenses.AnimalProduction) {
          access.animalProduction = station.licenses.AnimalProduction;
        } else {
          this.invalidAccessPath = this.invalidAccessPath.concat(this.animalProductionPaths);
        }
        if (station.licenses.WorkPlanning) {
          access.workPlanning = station.licenses.WorkPlanning;
          if (station.licenses.TropicalSowingWindow) {
            access.tropicalSowingWindow = station.licenses.TropicalSowingWindow;
          } else {
            const invalidPath = buildMenuFromTreeSubNodes(WP_TROPICAL_SOWING_WINDOW_SERVICE);
            this.invalidAccessPath = this.invalidAccessPath.concat(invalidPath);
          }
          if (station.licenses.GrassFireDangerIndex) {
            access.grassFireDangerIndex = station.licenses.GrassFireDangerIndex;
          } else {
            const invalidPath = buildMenuFromTreeSubNodes(WP_GRASS_FIRE_DANGER_INDEX_SERVICE);
            this.invalidAccessPath = this.invalidAccessPath.concat(invalidPath);
          }
        } else {
          this.invalidAccessPath = this.invalidAccessPath.concat(this.workPlanningPaths);
        }
      } else {
        this.invalidAccessPath = this.invalidAccessPath.concat(this.weatherForecastPath);
        this.invalidAccessPath = this.invalidAccessPath.concat(this.animalProductionPaths);
        this.invalidAccessPath = this.invalidAccessPath.concat(this.workPlanningPaths);
      }
    } else {
      this.invalidAccessPath = this.invalidAccessPath.concat(this.weatherForecastPath);
      this.invalidAccessPath = this.invalidAccessPath.concat(this.workPlanningPaths);
      this.invalidAccessPath = this.invalidAccessPath.concat(this.animalProductionPaths);
      this.invalidAccessPath.push('/disease-models');
    }

    const navigationObject: INavigationStationState = {
      station: station,
      stationBasicInfo: this.setBasicInfo(station),
      navigationAccess: access,
      notFound: this.notFound
    };

    this.navigationStore.dispatch(setNavigationStation(navigationObject));
    return access;
  }

  private setBasicInfo(station: IStation): IStationBasicInfo {
    return {
      firmWare: station.info.firmware,
      timeZoneOffset: station.config ? station.config.timezone_offset : null,
      lastCommunication: moment(station.dates.last_communication).toDate(),
      maxDate: moment(station.dates.max_date).toDate(),
      minDate: moment(station.dates.max_date).toDate(),
      originalName: station.name.original,
      customName: station.name.custom,
      deviceType: station.info.device_name,
    };
  }

  private find(id: string): IStation {
    const station: IStation = this.stations.find((s: IStation) => s.name.original === id);
    this.notFound = !station;
    return station ? station : this.stations[0];
  }

  private findCropzone(id: string): ICropZone {
    let cropzone: ICropZone = this.cropzones.find((c: ICropZone) => c.id === id);
    if (cropzone) {
      if (cropzone.id === this.idFromSelectedCropzone) {
        cropzone = this.selectedCropzone;
      }
    }
    this.notFound = !cropzone;
    return cropzone ? cropzone : this.cropzones[0];
  }

  private prepareUrl(): string {
    return `/${this.router.url.split('/').splice(3).join('/')}`;
  }

  private checkAvailable(id: number, url: string): string {

    let pathway = url;
    const isSettingsPath: boolean = this.settingsPath.includes(url);

    switch (id) {
      case StationTypes.get('CropViewType3'):
      case StationTypes.get('CropViewType2'):
      case StationTypes.get('CropViewType1'):
        // tslint:disable-next-line:max-line-length
        if (this.cropViewNotAvailablePath.includes(url) || this.invalidAccessPath.includes(url) || this.cropzonePaths.includes(url)) {
          pathway = isSettingsPath ? this.settingsDefaultPath : this.cropViewDefaultPath;
        }
        break;
      case StationTypes.get('IScoutType'):
      case StationTypes.get('IScoutMobileType'):
        if (this.iscoutNotAvailablePath.includes(url) || this.invalidAccessPath.includes(url) || this.cropzonePaths.includes(url)) {
          pathway = isSettingsPath ? this.settingsDefaultPath : this.iScoutDefaultPath;
        }
        break;
      case StationTypes.get('iMetos Active Tracker'):
      case StationTypes.get('iMetos Object Tracker'):
        if (this.trackerNotAvailablePath.includes(url) || this.invalidAccessPath.includes(url) || this.cropzonePaths.includes(url)) {
          pathway = isSettingsPath ? this.settingsDefaultPath : this.trackerDefaultPath;
        }
        break;
      case StationTypes.get('SoilGuard Probe'):
        if (this.soilGuardNotAvailablePath.includes(url) || this.invalidAccessPath.includes(url) || this.cropzonePaths.includes(url)) {
          pathway = this.soilGuardDefaultPath;
        }
        break;
      case StationTypes.get('Dualex Device'):
        if (this.dualexNotAvailablePath.includes(url) || this.invalidAccessPath.includes(url) || this.cropzonePaths.includes(url)) {
          pathway = this.dualexDefaultPath;
        }
        break;
      case StationTypes.get('MobiLab Device'):
        if (this.mobilabNotAvailablePath.includes(url) || this.invalidAccessPath.includes(url) || this.cropzonePaths.includes(url)) {
          pathway = this.mobilabDefaultPath;
        }
        break;
      default:
        if (this.iMetosNotAvailablePath.includes(url) || this.invalidAccessPath.includes(url) || this.cropzonePaths.includes(url)) {
          pathway = isSettingsPath ? this.settingsDefaultPath : this.iMetosDefaultPath;
        }
        break;
    }
    return pathway;
  }

  private checkAvailableCropzone(id: string, url: string, cropzone: ICropZone): string {
    let irrimetAccess = false;
    let soilMoistureAccess = false;
    let satelliteAccess = false;
    let yieldPredictionAccess = false;

    if (cropzone.product_key) {
      this.activeLicenses
        .filter(license => license.product_item.key === cropzone.product_key)
        .forEach(license => {
          irrimetAccess = license.Irrimet;
          soilMoistureAccess = license.SoilMoisture;
          satelliteAccess = license.Satellite;
          yieldPredictionAccess = license.YieldPrediction;
        });
    }

    const isCropzonePath: boolean = this.cropzonePaths.includes(url);

    if ((isCropzonePath && url === '/irrimet-config') || <string>this.activeCropzoneRoute === 'irrimet-config') {
      if (cropzone.boundary !== null && irrimetAccess) {
        return '/irrimet-config';
      } else {
        return '/config';
      }
    } else if ((isCropzonePath && url === '/irrimet') || <string>this.activeCropzoneRoute === 'irrimet') {
      if (cropzone.boundary !== null && irrimetAccess) {
        if (cropzone.crop !== null && cropzone.data_sources.length > 0) {
          return '/irrimet';
        } else {
          return '/irrimet-config';
        }
      } else {
        return '/config';
      }
    } else if ((isCropzonePath && url === '/soil-moisture-config') || <string>this.activeCropzoneRoute === 'soil-moisture-config') {
      if (cropzone.boundary !== null && soilMoistureAccess) {
        return '/soil-moisture-config';
      } else {
        return '/config';
      }
    } else if ((isCropzonePath && url === '/yield-prediction-config') || <string>this.activeCropzoneRoute === 'yield-prediction-config') {
      if (cropzone.boundary !== null && yieldPredictionAccess) {
        return '/yield-prediction-config';
      } else {
        return '/config';
      }
    } else if ((isCropzonePath && url === '/yield-prediction/previous') || <string>this.activeCropzoneRoute === 'previous') {
      if (cropzone.boundary !== null  && yieldPredictionAccess) {
        return '/yield-prediction/previous';
      } else {
        return '/config';
      }
    } else if ((isCropzonePath && url === '/yield-prediction/prediction') || <string>this.activeCropzoneRoute === 'prediction') {
      if (cropzone.boundary !== null  && yieldPredictionAccess) {
        return '/yield-prediction/prediction';
      } else {
        return '/config';
      }
    } else if ((isCropzonePath && url === '/soil-moisture/sums-sm') || <string>this.activeCropzoneRoute === 'sums-sm') {
      if (cropzone.boundary !== null && soilMoistureAccess) {
        return '/soil-moisture/sums-sm';
      } else {
        return '/config';
      }
    } else if ((isCropzonePath && url === '/irrimet/available-water-mm') || <string>this.activeCropzoneRoute === 'available-water-mm') {
      if (cropzone.boundary !== null) {
        if (cropzone.crop !== null && cropzone.data_sources.length > 0 && irrimetAccess) {
          return '/irrimet/available-water-mm';
        } else {
          return '/irrimet-config';
        }
      } else {
        return '/config';
      }
    } else if ((isCropzonePath && url === '/irrimet/paw') || <string>this.activeCropzoneRoute === 'paw') {
      if (cropzone.boundary !== null) {
        if (cropzone.crop !== null && cropzone.data_sources.length > 0 && irrimetAccess) {
          return '/irrimet/paw';
        } else {
          return '/irrimet-config';
        }
      } else {
        return '/config';
      }
    } else if ((isCropzonePath && url === '/sat/lai') || <string>this.activeCropzoneRoute === 'lai') {
      if (cropzone.boundary !== null && satelliteAccess) {
        return '/sat/lai';
      } else {
        return '/config';
      }
    } else if ((isCropzonePath && url === '/sat/ndvi') || <string>this.activeCropzoneRoute === 'ndvi') {
      if (cropzone.boundary !== null && satelliteAccess) {
        return '/sat/ndvi';
      } else {
        return '/config';
      }
    } else if (
      (isCropzonePath && url === '/cropzone-samples/nutrition') || (<string>this.activeCropzoneRoute) === '/cropzone-samples/nutrition'
    ) {
      // todo: redirect in case of product key or missing data
      return url;
    } else if (
      (isCropzonePath && url === '/cropzone-samples/soilguard') || (<string>this.activeCropzoneRoute) === '/cropzone-samples/soilguard'
    ) {
      return url;
    } else {
      return this.settingsDefaultPath;
    }
  }
}
