import {
  IProductActivationState,
  IProductActivationWarningMessage,
} from '../models/product-activation.models';

export enum FeatureType {
  VIRTUAL_STATION = 'VWS',
  DISEASE_MODEL = 'DM',
  WEATHER_FORECAST = 'WF',
  SERVICE_LICENSE = 'SL',
  DAVIS_INGEST = 'DI',
  FARM_VIEW = 'FV',
  ISCOUT_MOBILE = 'IMD'
}

export enum LicenseType {
  WEATHER_FORECAST = 'Forecast',
  SERVICE_LICENSE = 'LicenseService',
  DAVIS_INGEST = 'DavisIngest',
  FARM_VIEW = 'FarmView',
  ISCOUT_MOBILE = 'IScoutMobileDetection'
}

export function getFeatureType(licenseType: string): string {
  switch (licenseType) {
    case LicenseType.WEATHER_FORECAST:
      return FeatureType.WEATHER_FORECAST;
    case LicenseType.SERVICE_LICENSE:
      return FeatureType.WEATHER_FORECAST;
    case LicenseType.DAVIS_INGEST:
      return FeatureType.DAVIS_INGEST;
    case LicenseType.FARM_VIEW:
      return FeatureType.FARM_VIEW;
    case LicenseType.ISCOUT_MOBILE:
      return FeatureType.ISCOUT_MOBILE;
    default:
      return null;
  }
}

export const initialProductActivationState: IProductActivationState = {
  productKey: '',
  availableDiseaseModels: [],
  availableUserStations: [],
  availableServiceLicenseStations: [],
  productConfiguration: null,
  productConfigurationItem: null,
  validLicenses: {}
};

export const WARNING_PURCHASE_LICENSES: IProductActivationWarningMessage = {
  'default': {
    text1: 'To purchase licenses online please visit our shop at ',
    link: 'https://shop.metos.at',
    linkText: 'https://shop.metos.at',
    text2: 'or contact Sales via email at ',
    mail: 'mailto:orders@metos.at?subject=Product activation',
    mailText: 'orders@metos.at',
    text3: 'with your request.'
  },
  'proglu': {
    text1: 'To purchase licenses online please contact sales via email: ',
    mail: 'mailto:marketing@procontrolweb.com?subject=Product activation',
    mailText: 'marketing@procontrolweb.com',
    text3: 'with your request.'
  }
};

