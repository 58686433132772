import { Injectable } from '@angular/core';
import { ApiCallService } from '../../../services/api/api-call.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { IActionWithPayload, IActionWithPayload as IAction } from '../../../core/models/actionWithPayload';
import { IServerAlert } from '../interfaces/server-alert';
import { Observable, of } from 'rxjs';
import { setNotify } from '../../../core/actions/notify';
import { ActionTypes, appendServerAlert, setServerAlerts, setStationSensors } from '../actions/server-alerts';
import { ISensor } from '../../../shared/interfaces';

@Injectable()
export class ServerAlertsEffects {

  constructor(
    private api: ApiCallService,
    private actions$: Actions
  ) {

  }

  @Effect()
  public getStationSensors: Observable<IActionWithPayload> = this.actions$.pipe(
    ofType(ActionTypes.GET_STATION_SENSORS),
    switchMap((action: IAction) => this.api.getStationSensors(action.payload).pipe(
      map((sensors: ISensor[]) => setStationSensors(sensors)),
      catchError(() =>
        of(setNotify('Notifications are currently unavailable'))
      )
    ))
  );

  @Effect()
  public getStationServerAlerts = this.actions$.pipe(
    ofType(ActionTypes.GET_SERVER_ALERTS),
    switchMap((action: IAction) => this.api.getStationServerAlerts(action.payload).pipe(
      map((data: IServerAlert[]) => setServerAlerts(data)),
      catchError(() =>
        of(setNotify('Notifications are currently unavailable'))
      )
    ))
  );

  @Effect()
  public addStationServerAlert = this.actions$.pipe(
    ofType(ActionTypes.ADD_SERVER_ALERT),
    switchMap((action: IAction) => this.api.addStationServerAlert(action.payload).pipe(
      mergeMap(result => [
        setNotify('The FieldClimate notification was successfully added'),
        appendServerAlert({ ...action.payload.alert, _id: result['$oid'] })
      ]),
      catchError(() =>
        of(setNotify('Failed to add a new FieldClimate notification'))
      )
    ))
  );

  @Effect()
  public updateStationServerAlert = this.actions$.pipe(
    ofType(ActionTypes.UPDATE_SERVER_ALERT),
    switchMap((action: IAction) => this.api.updateStationServerAlert(action.payload).pipe(
      map(() => setNotify('The FieldClimate notification was successfully updated')),
      catchError(() =>
        of(setNotify('Failed to update the FieldClimate notification'))
      )
    ))
  );

  @Effect()
  public deleteStationServerAlert = this.actions$.pipe(
    ofType(ActionTypes.DELETE_SERVER_ALERT),
    switchMap((action: IAction) => this.api.deleteStationServerAlert(action.payload).pipe(
      map(() => setNotify('The FieldClimate notification was successfully deleted')),
      catchError(() =>
        of(setNotify('Failed to delete the FieldClimate notification'))
      )
    ))
  );
}
